import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Segment } from 'entities/Segment/model/Segment'
import { SegmentBox } from 'entities/Segment'
import { EnrollmentFilter } from 'widgets/ContactEnrollment/ui/EnrollmentFilter'
import { EnumEnrollmentMode } from 'widgets/ContactEnrollment/store/types'
import type { FilterEditorStore } from 'widgets/FilterEditor'
import type { EnrollmentPreviewStore } from 'widgets/ContactEnrollment/store'
import { EnrollmentItemType } from 'widgets/ContactEnrollment/types'
import { EnrollmentContactPreview } from '../EnrollmentContactPreview'

type IEnrollmentAllPreviewProps = {
  store: EnrollmentPreviewStore
  filterEditorStore: FilterEditorStore
  segmentBox: SegmentBox
  mode: EnumEnrollmentMode
  onApply: (filter: Segment) => void
  onCancel: () => void
}

export const EnrollmentAllPreview: FC<IEnrollmentAllPreviewProps> = observer(
  ({ store, filterEditorStore, onApply, onCancel, mode, segmentBox }) => {
    if (!store.active) return null

    if (store.active.type === EnrollmentItemType.Contact) {
      return (
        <EnrollmentContactPreview
          contact={store.active.payload}
          onCancel={() => {
            store.resetItemActive(segmentBox)
            onCancel()
          }}
          hideActions={!store.hasActive}
        />
      )
    }

    if (
      store.active.type === EnrollmentItemType.Segment ||
      store.active.type === EnrollmentItemType.Filter
    ) {
      return (
        <EnrollmentFilter
          filterEditorStore={filterEditorStore}
          segmentBox={segmentBox}
          onApply={onApply}
          onCancel={onCancel}
          isViewOnly={mode === EnumEnrollmentMode.ViewOnly || !store.hasActive}
        />
      )
    }

    if (store.active.type === EnrollmentItemType.Tag) {
      return (
        <EnrollmentFilter
          filterEditorStore={filterEditorStore}
          segmentBox={segmentBox}
          onApply={onApply}
          onCancel={onCancel}
          isViewOnly={true}
        />
      )
    }

    return null
  }
)
