import { createContext, useContext } from 'react'
import { type BroadcastsListStore } from 'widgets/BroacastList'
export const BroadcastsListContext = createContext<BroadcastsListStore | null>(null)

export const useBroadcastsListContext = () => {
  const context = useContext(BroadcastsListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with BroadcastsListContext')

  return context
}
