import { makeAutoObservable, runInAction } from 'mobx'
import { Dayjs } from 'dayjs'
import { CustomPricesStatuses } from 'entities/Admin/customPrices/api/type'
import { Payment } from 'pages/admin/ui/AdminManagePaymentPlan/PaymentPlanSchedule/types'

export class PaymentSchedule {
  id: string
  status: CustomPricesStatuses
  name: string
  amount: number
  credits: number
  payment_date: Dayjs
  editable: boolean
  deletable: boolean
  retry: boolean

  constructor(item: Payment) {
    this.id = item.id
    this.status = item.status
    this.name = item.name
    this.amount = item.amount
    this.credits = item.credits
    this.payment_date = item.payment_date
    this.editable = item.editable
    this.deletable = item.deletable
    this.retry = item.retry || false

    makeAutoObservable(this)
  }

  handleUpdatePaymentDate = (date: Dayjs) => {
    runInAction(() => {
      this.payment_date = date
    })
  }

  handleUpdateAmount(amount: number) {
    runInAction(() => {
      this.amount = amount
    })
  }

  handleUpdateCredits(credits: number) {
    runInAction(() => {
      this.credits = credits
    })
  }

  handleUpdateName(name: string) {
    runInAction(() => {
      this.name = name
    })
  }

  handleUpdateDeletable(deletable: boolean) {
    runInAction(() => {
      this.deletable = deletable
    })
  }
}
