import { Base } from 'models'
import { IResponseTag } from 'entities/Tags/api/types'

export class Tag extends Base {
  static Create(item: IResponseTag) {
    return new Tag(item)
  }

  id: number
  owner_id?: number
  contacts_count?: number
  organization_id?: number
  label!: string
  name!: string
  type?: string
  created_at?: string
  updated_at?: string
  color?: string
  origin!: IResponseTag

  constructor(item: IResponseTag) {
    super()

    this.id = item.id
    this.syncOrigin(item)
  }

  syncOrigin = (origin: IResponseTag) => {
    this.id = origin.id
    this.label = origin.label
    this.name = origin.name
    this.created_at = origin?.created_at
    this.contacts_count = origin?.contacts_count
    this.organization_id = origin?.organization_id
    this.owner_id = origin?.owner_id
    this.type = origin?.type
    this.updated_at = origin?.updated_at
    this.color = origin.color || '#3EB786'
    this.origin = origin
  }
}
