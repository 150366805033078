import { ActionItem } from 'shared/ui'
import { History } from '../model/History'
import { contactsHistoryStore } from '../store/ContactsHistoryStore'

export const getHistoryActions = (history: History): ActionItem[] => {
  const { deleteHistory } = contactsHistoryStore
  return [
    {
      text: 'Delete',
      icon: 'delete',
      onAction: () => {
        deleteHistory(history)
      },
    },
  ]
}
