// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cMpXCfDUCQz4lZNTcTKB{margin-left:6px}.VQzy_ps5bVR7OstGUZwV{margin-left:13px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationHeaderListDropdown/ui/ConversationHeaderListDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,gBAAA","sourcesContent":[".dropdownContent {\n  margin-left: 6px;\n}\n.dropdownContentMobile {\n  margin-left: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownContent": "cMpXCfDUCQz4lZNTcTKB",
	"dropdownContentMobile": "VQzy_ps5bVR7OstGUZwV"
};
export default ___CSS_LOADER_EXPORT___;
