import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseConversation } from 'entities/Conversation/api/types'

const ConversationContactOptedOut = {
  handle: (data: IResponseConversation) => {
    conversationStore.updateItem(data)
  },
}

export const EventConversationContactOptedOut = {
  subscribe: () =>
    websocket.on(WebsocketEvent.ConversationContactOptedOut, ConversationContactOptedOut.handle),
  unsubscribe: () =>
    websocket.off(WebsocketEvent.ConversationContactOptedOut, ConversationContactOptedOut.handle),
}
