import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Dropdown,
  DropdownContent,
  DropdownTextInput,
  DropdownGroup,
  Scrollbar,
  Button,
} from 'shared/ui'
import { TextFieldPlaceholder, makeTextFieldError, makeTextFieldIcon } from 'shared/ui/TextField'
import { ChatbotField } from 'entities/Chatbot'
import { CustomField } from 'entities/CustomField'
import { getIcon } from 'widgets/constants'
import { useChatbotContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export interface IFieldCardPropertyProps {
  field: ChatbotField
  onChange: (field: CustomField) => void
}

export const FieldCardProperty = observer(({ field, onChange }: IFieldCardPropertyProps) => {
  const ref = useRef<HTMLLabelElement | null>(null)
  const { errors } = useChatbotContext()

  const [searchTerm, setSearchTerm] = useState('')
  const [dropdownWidth, setDropdownWidth] = useState<number | undefined>()

  const fieldError = errors.fields.getError(field, 'type')
  const removeFieldError = () => errors.fields.removeError(field)

  const { fieldsControl } = useChatbotContext()

  const isWithDefaultFields = !!fieldsControl.defaultFields.length
  const isWithCustomFields = !!fieldsControl.customFields.length
  const isWithoutFields = !isWithDefaultFields && !isWithCustomFields

  const handleCreate = async () => {
    const field = await fieldsControl.createField()

    if (field) onChange(field)
  }

  useEffect(() => fieldsControl.searchFields(searchTerm), [searchTerm])

  return (
    <div className={styles.cardInput}>
      <span className={styles.cardInputName}>Contact Property</span>
      <div className={styles.cardInputControl}>
        <Dropdown
          ariaLabel='TextbotFieldDropdown'
          margin={-40}
          width={dropdownWidth}
          onToggle={() => {
            setSearchTerm('')
            setDropdownWidth(ref.current?.getBoundingClientRect().width)
            fieldsControl.clearSearch()
          }}
          triggerComponent={() => (
            <TextFieldPlaceholder
              variant='outline'
              size='medium'
              ref={ref}
              className={styles.cardInputDropdown}
              placeholder={
                <div className={styles.cardInputValue}>
                  {fieldsControl.getName(field.key) ?? (
                    <span className={styles.cardInputPlaceholder}>Select</span>
                  )}
                </div>
              }
              leftActions={[
                makeTextFieldIcon({
                  icon: 'messageSmile',
                  className: styles.cardInputIcon,
                }),
              ]}
              error={fieldError}
              rightActions={[
                makeTextFieldError({ placement: 'bottom', anchor: 'container', offset: [0, 0] }),
              ]}
            />
          )}
          placement='bottom'
          customComponent={(close) => (
            <DropdownContent noPadding>
              <DropdownTextInput
                textFieldProps={{
                  value: searchTerm,
                  onChange: setSearchTerm,
                  InputProps: {
                    placeholder: 'Search',
                  },
                }}
              />
              <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={300}>
                {isWithDefaultFields && (
                  <DropdownGroup
                    title='Default fields'
                    items={fieldsControl.defaultFields.map((field) => ({
                      id: field.key,
                      label: field.name,
                      iconL: getIcon(field),
                      onClick: () => {
                        close()
                        onChange(field)
                        removeFieldError()
                      },
                    }))}
                    isScroll={false}
                  />
                )}
                {isWithCustomFields && (
                  <DropdownGroup
                    title='Custom fields'
                    items={fieldsControl.customFields.map((field) => ({
                      id: field.key,
                      label: field.name,
                      iconL: getIcon(field),
                      onClick: () => {
                        close()
                        onChange(field)
                        removeFieldError()
                      },
                    }))}
                    isScroll={false}
                  />
                )}
                {isWithoutFields && <div className={styles.noResults}>No results found</div>}
              </Scrollbar>
              <footer className={styles.cardInputFooter}>
                <Button
                  className={styles.addButton}
                  typeBtn='menuItem'
                  text='New field'
                  icon='add'
                  onClick={() => {
                    close()
                    handleCreate()
                  }}
                />
              </footer>
            </DropdownContent>
          )}
        />
      </div>
    </div>
  )
})
