import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button, Dropdown, Icon } from 'shared/ui'
import { PresetDropdownItem, PresetDropdownList } from 'shared/Preset'
import { ChatbotInstructionType } from 'entities/Chatbot'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotTemplate, ChatbotTemplateList } from 'pages/chatbot/config'
import { chatbotOperation } from 'pages/chatbot/store/chatbotOperationStore'
import styles from './styles.module.scss'

export const ChatbotType = observer(() => {
  const [active, setActive] = useState(false)
  const { model, initialized, instructions, activeTemplates, id } = useChatbotContext()

  const template =
    ChatbotTemplate.get(model.type as ChatbotInstructionType) ?? ChatbotTemplateList[1]

  return (
    <Dropdown
      placement='bottom-start'
      triggerComponent={() => (
        <Button
          className={styles.typeButton}
          active={active}
          disabled={!initialized}
          typeBtn='select'
          contained='secondary'
          text={template.name}
          icon={template.icon}
          textRight={<Icon icon={active ? 'chevronUp' : 'chevronDown'} />}
        />
      )}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: () => [0, 5],
            },
          },
        ],
      }}
      onToggle={setActive}
      width={330}
      customComponent={(closeMenu) => (
        <PresetDropdownList title='Textbot type'>
          {activeTemplates.map((template) => (
            <PresetDropdownItem
              key={template.name}
              selected={model.type === template.type}
              onClick={() => {
                if (model.type === template.type) return closeMenu()

                chatbotOperation
                  .changeInstruction(
                    model,
                    { agentId: id, type: template.type },
                    instructions.get(template.type)
                  )
                  .then(closeMenu)
              }}
              icon={template.icon}
              name={template.name}
              description={template.description}
              isNew={template.isNew}
            />
          ))}
        </PresetDropdownList>
      )}
    />
  )
})
