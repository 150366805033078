import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { ZIndex } from 'shared/constants/zIndex'
import {
  amountItemsAch,
  AutoRechargeWidgetStore,
  thresholdCreditsAchItems,
  thresholdCreditsItems,
} from 'widgets/AutoRechargeModal'
import { DropdownCards } from 'widgets/DropdownCards/ui/DropdownCards'
import styles from './styles.module.scss'

type IAutoRechargeModalContentProps = {
  autoRechargeWidgetStore: AutoRechargeWidgetStore
}

export const AutoRechargeModalContent: FC<IAutoRechargeModalContentProps> = observer(
  ({ autoRechargeWidgetStore }) => {
    const {
      threshold_in_credits,
      set_threshold_in_credits,
      amountItems,
      amount_in_credits,
      set_amount_in_credits,
      primaryDropdownCardsStore,
      backupDropdownCardsStore,
    } = autoRechargeWidgetStore
    const creditsItems = primaryDropdownCardsStore.activeItem?.isMethodAch
      ? thresholdCreditsAchItems
      : thresholdCreditsItems
    const rechargeAmountItems = primaryDropdownCardsStore.activeItem?.isMethodAch
      ? amountItemsAch
      : amountItems

    return (
      <div>
        <Alert
          item={{
            type: 'infoBlue',
            title: 'Guarantee Delivery',
            desc: 'Automatically add funds to your account when it falls below a certain threshold to ensure uninterrupted messaging.',
          }}
        />
        <div className={styles.wrapDropdowns}>
          <SelectField
            labelText={'If balance falls bellow'}
            items={creditsItems}
            value={threshold_in_credits}
            setValue={set_threshold_in_credits}
            btnProps={{ fontWeight: 400, className: styles.select, paddingInline: '11px 7px' }}
            dropdownProps={{
              withSearch: false,
              ariaLabel: 'threshold_in_credits',
              zIndex: ZIndex.OVERLAY_TOP_110,
            }}
          />
          <SelectField
            labelText={'Recharge my account'}
            items={rechargeAmountItems}
            value={amount_in_credits}
            setValue={set_amount_in_credits}
            btnProps={{ fontWeight: 400, className: styles.select, paddingInline: '11px 7px' }}
            dropdownProps={{
              withSearch: false,
              ariaLabel: 'amount_in_credits',
              zIndex: ZIndex.OVERLAY_TOP_110,
            }}
          />
        </div>
        <div className={styles.wrapCards}>
          <DropdownCards store={primaryDropdownCardsStore} />
          {!primaryDropdownCardsStore.activeItem?.isMethodAch && (
            <DropdownCards store={backupDropdownCardsStore} />
          )}
        </div>
      </div>
    )
  }
)
