export type NumbersInboxesListStoreConfig = {
  variant: EnumNumbersInboxesDropDown
  excludeAircall?: boolean
  excludeSIPTrunk?: boolean
}

export enum EnumNumbersInboxesDropDown {
  Conversation = 'conversation',
  Call = 'call',
}
