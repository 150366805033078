import { FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Icon, IDropdownItemProps } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type IDropdownCardCheckedProps = IDropdownItemProps & { isRight?: boolean }

export const DropdownCardCustom: FC<IDropdownCardCheckedProps> = observer(
  ({ onChange, item, parent, isHover, ariaLabel }) => {
    return (
      <div
        className={classnames(styles.item, item.className, styles['item--card'], {
          [styles.item__disabled]: item.disabled,
          [styles['item--hover']]: isHover,
        })}
        onClick={(e) => {
          e.stopPropagation()

          if (item.disabled) return
          if (item.children?.length) return

          item.onClick?.()
          onChange?.(item, parent)
        }}
        {...(ariaLabel && { 'aria-label': getAriaLabel(ariaLabel) })}
      >
        <div className={styles.itemWrap}>{item.renderOption?.(item)}</div>
        <div className={styles.itemWrap}>
          {item.activeValue && (
            <div className={classnames(styles.icon, styles.active, styles.activeRight)}>
              <Icon icon={'check1'} />
            </div>
          )}
        </div>
      </div>
    )
  }
)
