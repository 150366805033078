import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import {
  IParamsCreateSuggestAnswer,
  IParamsCreateSummaryAnswer,
  IResponseAIAnswer,
} from 'entities/AIAssistant/api/types'

class Api {
  createSuggestAnswer(
    params: IParamsCreateSuggestAnswer
  ): Promise<AxiosResponse<IResponseAIAnswer>> {
    return baseApi.post('ai/api/conversations/suggest-answer', params)
  }
  createSummaryConversationAnswer(
    params: IParamsCreateSummaryAnswer
  ): Promise<AxiosResponse<IResponseAIAnswer>> {
    return baseApi.post('ai/api/conversations/summary', params)
  }

  getRephrase(message: string): Promise<AxiosResponse<IResponseAIAnswer>> {
    return baseApi.get('ai/api/messages/autocorrection', {
      params: {
        message,
      },
    })
  }
  getMoreFriendly(message: string): Promise<AxiosResponse<IResponseAIAnswer>> {
    return baseApi.get('ai/api/messages/tone-improvement', {
      params: {
        message,
        type: 'friendly',
      },
    })
  }
  getMoreFormal(message: string): Promise<AxiosResponse<IResponseAIAnswer>> {
    return baseApi.get('ai/api/messages/tone-improvement', {
      params: {
        message,
        type: 'formal',
      },
    })
  }
  getExpand(message: string): Promise<AxiosResponse<IResponseAIAnswer>> {
    return baseApi.get('ai/api/messages/expand', {
      params: {
        message,
      },
    })
  }
  getShorten(message: string): Promise<AxiosResponse<IResponseAIAnswer>> {
    return baseApi.get('ai/api/messages/shorten', {
      params: {
        message,
      },
    })
  }
}

export const AIAssistantApi = new Api()
