import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import { Button, Dropdown, Icon } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { integrationsStore } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactsTableStore } from 'widgets/ContactsTable'
import styles from './styles.module.scss'

type IUnlinkActionsProps = {
  contact: Contact
  setStatus: (status: boolean) => void
  contactsTableStore: ContactsTableStore | null
}

export const UnlinkActions: FC<IUnlinkActionsProps> = observer(
  ({ contact, setStatus, contactsTableStore }) => {
    const modalId = nanoid()
    const handleUnlink = () => {
      integrationsStore.unlinkContactsIntegrations(contact.id, contact.integration_key)
      modalStore.closeModal(modalId)
    }

    const UnlinkModalContent = () => (
      <div className={styles.unlinkModalContent}>
        <div className={styles.iconWrapper}>
          <div className={styles.icon}>
            <Icon icon='alert' />
          </div>
        </div>
        <div className={styles.title}>Unlink contact?</div>
        <div className={styles.description}>
          In order to reverse, you’ll have to re-link this contact manually
        </div>
      </div>
    )

    const UnlinkModalActions = () => (
      <div className={styles.unlinkModalActions}>
        <Button
          onClick={handleUnlink}
          size={'medium'}
          contained={'primary'}
          text='Unlink contact'
          fullWidth
        />
        <Button
          onClick={() => modalStore.closeModal(modalId)}
          size={'medium'}
          contained={'tertiary'}
          text='Cancel'
          fullWidth
        />
      </div>
    )

    return (
      <div className={styles.unlink}>
        <Dropdown
          ariaLabel={'UnlinkActions'}
          icon={'moreHorizontal'}
          placement={'bottom-end'}
          tooltipProps={{
            label: 'Show actions',
            placement: 'top-end',
            disableInteractive: true,
          }}
          iconButtonProps={{
            transparentInactive: true,
            ariaLabel: 'UnlinkActions_showActions',
          }}
          items={[
            {
              id: 1,
              label: 'Unlink contact',
              iconL: 'unlink',
            },
          ]}
          onToggle={(status) => setStatus(status)}
          onChange={() => {
            contactsTableStore?.resetActiveAndCloseDetailsIsMobile()
            modalStore.addModal({
              id: modalId,
              showCloseButton: false,
              showCloseIcon: false,
              showHeader: false,
              width: 280,
              ModalActions: () => <UnlinkModalActions />,
              ModalContent: () => <UnlinkModalContent />,
            })
          }}
        />
      </div>
    )
  }
)
