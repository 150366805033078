import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { IResponseMedia } from 'entities/Attachment/api/types'

type IHandlerEvent = (data: IResponseMedia) => void

export const EventAttachmentThumbnailCreated = {
  subscribe: (handler: IHandlerEvent) =>
    websocket.on(WebsocketEvent.AttachmentThumbnailCreated, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.AttachmentThumbnailCreated, handler),
}

export const useEventAttachmentThumbnailCreated = (handler: IHandlerEvent) =>
  useEffect(() => EventAttachmentThumbnailCreated.subscribe(handler), [])
