import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'

import { CreateCustomFieldModal } from 'entities/CustomField/ui'
import { CustomFieldApi, INewCustomField } from 'entities/CustomField/api'
import { CustomField } from 'entities/CustomField//model'

export class CustomFieldOperationStore {
  private _createCustomFieldModalId = 'createCustomFieldModal'

  createField = (): Promise<CustomField | null> =>
    new Promise((resolve) => {
      const handleCancel = () => {
        modalStore.removeModal(this._createCustomFieldModalId)
        resolve(null)
      }

      const handleCreate = async (newField: INewCustomField) => {
        try {
          const {
            data: { data: fieldData },
          } = await CustomFieldApi.createCustomField(newField)

          modalStore.removeModal(this._createCustomFieldModalId)
          resolve(new CustomField(fieldData))
        } catch (error) {
          if (error instanceof AxiosError) {
            const [name] = error.response?.data?.name ?? []

            throw name
          }
        }
      }

      modalStore.addModal({
        id: this._createCustomFieldModalId,
        title: 'New field',
        ModalContent: CreateCustomFieldModal,
        ModalContentProps: {
          onCancel: handleCancel,
          onCreate: handleCreate,
        },
        width: 480,
        pureContent: true,
        onClose: handleCancel,
      })
    })
}

export const customFieldOperation = new CustomFieldOperationStore()
