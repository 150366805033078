import { IIconsVector } from 'shared/ui'
import { IResponseContact } from 'entities/Contacts/api/types'

export const contactTemplate = {
  id: -1,
  owner_id: 0,
  conversationId: null,
  color: '#F1F2F3',
  first_name: 'New',
  last_name: 'contact',
  full_name: 'New contact',
  email: null,
  timezone: '',
  icon: 'user' as IIconsVector,
  photo_url: 'https://qa.salesmessage.com/images/app/default-avatar.png', // TODO: CDN url?
  number: '',
  country: '',
  country_calling_code: '',
  national_number: '',
  formatted_number: '',
  is_blocked: false,
  opt_out: null,
  opt_out_at: '',
  opt_in_request_at: '',
  opt_in_requests: [],
  opt_in_at: '',
  shortcode_opt_outs: [],
  is_invalid: false,
  lookup_type: null,
  carrier_name: null,
  source: '',
  created_at: '',
  updated_at: '',
  integration_vendor_id: '',
  integration_vendor_type: null,
  prevent_autolink: false,
  integration: {
    id: 0,
    key: '',
    name: '',
    photo_url: '',
  },
  custom_fields: [],
  tags: [],
  notes: [],
  area_code: {
    id: 0,
    area_code: '',
    state_code: null,
    state_name: null,
    country_code: '',
    time_zone: 'Eastern',
  },
  keywords: [],
  last_messaged_on: '',
  last_message_sent_on: null,
  last_message_received_on: null,
  last_called_on: null,
  last_contacted_on: null,
  opt_out_at_shortcode: null,
  message_status: null,
  opt_in: null,
  total_received_sms: null,
  total_received_call: null,
} as IResponseContact
