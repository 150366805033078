import { makeAutoObservable } from 'mobx'
import { numberFormat } from 'shared/lib'
import { ICustomPriceForm, RolloverPeriod } from 'entities/Admin/customPrices/api/type'
import {
  MAX_ADDITIONAL_CREDIT_PRICE,
  MIN_ADDITIONAL_CREDIT_PRICE,
} from 'pages/admin/pages/customPrices/ui/CustomPriceForm/constants'
import { intervalToMonth } from 'pages/admin/pages/organizations/pages/CustomPlan/lib/intervalToMonth'

type BillingInterval = 'annual' | 'semi-annual' | 'quarterly' | 'monthly'

export class AdminCustomPricesForm {
  name: string | null = null
  contractTerm: BillingInterval | null = null
  billingFrequency: BillingInterval | null = null
  credits: number | null = null
  additionalCreditsPrice: number | null = MAX_ADDITIONAL_CREDIT_PRICE
  totalAmount: number | null = null
  seats = 0
  numbers = 0

  isRollover = false
  rolloverPeriod: RolloverPeriod | null = null
  rolloverPercent: number | null = null

  isEdit = false

  constructor() {
    makeAutoObservable(this)
  }

  preFillForm = (form: ICustomPriceForm) => {
    this.name = form.name
    this.contractTerm = form.contract_term
    this.billingFrequency = form.interval
    this.credits = form.credits
    this.additionalCreditsPrice = form.additional_credit_price
    this.seats = form.seats
    this.numbers = form.numbers
    this.isEdit = true
    this.totalAmount = form.price / 100

    const isRollover = form.type !== null && form.percent !== null

    if (isRollover) {
      this.isRollover = true
      this.rolloverPercent = Number(form.percent)
      this.rolloverPeriod = form.type
    } else {
      this.isRollover = false
      this.rolloverPercent = null
      this.rolloverPeriod = null
    }
  }

  get disableFormActions() {
    const validRollover = this.isRollover
      ? this.rolloverPercent !== null && this.rolloverPeriod !== null
      : true

    return (
      this.name === null ||
      this.contractTerm === null ||
      this.billingFrequency === null ||
      this.credits === null ||
      this.additionalCreditsPrice === null ||
      this.additionalCreditsPrice === 0 ||
      this.totalAmount === null ||
      !validRollover
    )
  }

  setName = (name: string | null) => {
    this.name = name
  }

  setContractTerm = (contractTerm: BillingInterval | null) => {
    this.contractTerm = contractTerm
    this.billingFrequency = contractTerm
  }

  setBillingFrequency = (billingFrequency: BillingInterval | null) => {
    this.billingFrequency = billingFrequency
  }

  setCredits = (credits: string | null) => {
    this.credits = Number(credits?.replace(/\D/g, ''))
  }

  setAdditionalCreditsPrice = (additionalCreditsPrice: string | null) => {
    this.additionalCreditsPrice = Number(additionalCreditsPrice) / 10000
  }

  setTotalAmount = (totalAmount: string | null) => {
    if (totalAmount === '' || totalAmount === null) {
      this.totalAmount = null
      return
    }

    this.totalAmount = Number(totalAmount)
  }

  setIsRollover = (isRollover: boolean) => {
    this.isRollover = isRollover

    if (isRollover) {
      this.rolloverPercent = 0.3
      this.rolloverPeriod = '30_days'
    } else {
      this.rolloverPercent = null
      this.rolloverPeriod = null
    }
  }

  setRolloverPeriod = (rolloverPeriod: RolloverPeriod | null) => {
    this.rolloverPeriod = rolloverPeriod
  }

  setRolloverPercent = (rolloverPercent: number | null) => {
    this.rolloverPercent = rolloverPercent
  }

  get isValidAdditionalCreditPrice() {
    return Boolean(
      this.additionalCreditsPrice &&
        this.additionalCreditsPrice >= MIN_ADDITIONAL_CREDIT_PRICE &&
        this.additionalCreditsPrice <= MAX_ADDITIONAL_CREDIT_PRICE
    )
  }

  static get contractTermsItems() {
    return [
      {
        id: 'annual',
        value: 'annual',
        label: '12 months (Annual)',
      },
      {
        id: 'monthly',
        value: 'monthly',
        label: '1 month (Month-to-month)',
      },
    ]
  }

  static get billingFrequencyItems() {
    return [
      {
        id: 'annual',
        value: 'annual',
        label: 'Annual',
      },
      {
        id: 'semi-annual',
        value: 'semi-annual',
        label: 'Semi-Annual',
      },
      {
        id: 'quarterly',
        value: 'quarterly',
        label: 'Quarterly',
      },
      {
        id: 'monthly',
        value: 'monthly',
        label: 'Monthly',
      },
    ]
  }

  static get rolloverPeriodItems() {
    return [
      {
        id: '30_days',
        value: '30_days',
        label: '30 days',
      },
      {
        id: 'billing_frequency',
        value: 'billing_frequency',
        label: 'Billing frequency',
      },
      {
        id: 'forever',
        value: 'forever',
        label: 'Indefinite',
      },
    ]
  }

  static get rolloverPercentItems() {
    return [
      {
        id: 0.3,
        label: '30%',
      },
      {
        id: 0.4,
        label: '40%',
      },
      {
        id: 0.5,
        label: '50%',
      },
      {
        id: 1,
        label: '100%',
      },
    ]
  }

  static get additionalCreditPriceItems() {
    return [
      0.04, 0.035, 0.0325, 0.0315, 0.031, 0.03, 0.029, 0.028, 0.027, 0.026, 0.025, 0.024, 0.023,
      0.022, 0.021, 0.02, 0.019, 0.018, 0.017, 0.016, 0.015, 0.014,
    ].map((item) => ({
      id: item,
      value: String(item),
      label: `$${item}`,
    }))
  }

  get isDisableBillingFrequency() {
    return this.contractTerm !== 'annual'
  }

  static getLabelRolloverPeriod(value: RolloverPeriod) {
    return AdminCustomPricesForm.rolloverPeriodItems.find(({ id }) => id === value)?.label
  }

  get contractTermName(): string {
    if (this.billingFrequency === null || this.billingFrequency === 'monthly') {
      return 'month'
    }

    if (this.billingFrequency === 'quarterly') {
      return 'quarter'
    }

    if (this.billingFrequency === 'semi-annual') {
      return '6 months'
    }

    if (this.billingFrequency === 'annual') {
      return 'year'
    }

    return this.billingFrequency
  }

  incrementSeats = () => {
    this.seats += 1
  }

  decrementSeats = () => {
    if (this.seats < 1) {
      return
    }

    this.seats -= 1
  }

  incrementNumbers = () => {
    this.numbers += 1
  }

  decrementNumbers = () => {
    if (this.numbers < 1) {
      return
    }

    this.numbers -= 1
  }

  get showSummary() {
    return Boolean(this.name && this.billingFrequency && this.totalAmount !== null)
  }

  get customPriceLabel() {
    const interval = this.billingFrequency
    const totalAmount =
      this.totalAmount !== null && numberFormat({ value: this.totalAmount, currency: 'USD' })

    const credits = this.credits !== null && `${numberFormat({ value: this.credits })} credits`

    const description = [interval, totalAmount, credits].filter((value) => !!value).join(' / ')

    return `${this.name} (${description})`
  }

  get termLabel() {
    return (
      AdminCustomPricesForm.contractTermsItems.find((item) => item.id === this.contractTerm)
        ?.label || '-'
    )
  }

  get billingFrequencyLabel() {
    return (
      AdminCustomPricesForm.billingFrequencyItems.find((item) => item.id === this.billingFrequency)
        ?.label || '-'
    )
  }

  get pricePerCredits() {
    if (!this.totalAmount || !this.credits) return '-'
    return numberFormat({
      value: this.creditPrice,
      currency: 'USD',
      maximumFractionDigits: 4,
    })
  }

  get additionalCreditsPriceLabel() {
    if (!this.additionalCreditsPrice) return '-'

    return numberFormat({
      value: this.additionalCreditsPrice,
      currency: 'USD',
      maximumFractionDigits: 3,
    })
  }

  get priceLabel() {
    if (!this.totalAmount) return '-'

    const monthMultiplier =
      !this.billingFrequency || !this.contractTerm
        ? 1
        : intervalToMonth(this.contractTerm) / intervalToMonth(this.billingFrequency)

    return numberFormat({
      value: this.totalAmount * monthMultiplier,
      currency: 'USD',
    })
  }

  get priceByBillingFrequency() {
    if (this.billingFrequency === null || this.totalAmount === null) return ''

    return numberFormat({
      value: this.totalAmount,
      currency: 'USD',
      maximumFractionDigits: 2,
    })
  }

  get billingFrequencyToMonth() {
    if (this.billingFrequency === null) return ''

    return `${intervalToMonth(this.billingFrequency)} month`
  }

  get formData() {
    return {
      name: this.name,
      price: (this.totalAmount || 0) * 100,
      credits: this.credits,
      interval: this.billingFrequency,
      contract_term: this.contractTerm,
      additional_credit_price: this.additionalCreditsPrice,
      seats: this.seats,
      numbers: this.numbers,
      percent: this.rolloverPercent,
      type: this.rolloverPeriod,
    }
  }

  get creditPrice() {
    return (this.totalAmount || 0) / (this.credits || 1)
  }

  get formToCreate() {
    const monthlyCredits = Math.floor(
      (this.credits || 0) / intervalToMonth(this.billingFrequency || 'monthly')
    )
    const creditPrice = this.creditPrice

    return {
      name: this.name,
      price: (this.totalAmount || 0) * 100,
      credits: this.credits,
      interval: this.billingFrequency,
      contract_term: this.contractTerm,
      additional_credit_price: this.additionalCreditsPrice,
      seats: this.seats,
      numbers: this.numbers,
      credit_price: creditPrice,
      monthly_credits: monthlyCredits,
      percent: this.rolloverPercent,
      type: this.rolloverPeriod,
    }
  }

  get formUpdate() {
    return {
      name: this.name,
      additional_credit_price: this.additionalCreditsPrice,
      seats: this.seats,
      numbers: this.numbers,
    }
  }

  get creditsSummary() {
    if (!this.credits || !this.totalCredits) return null

    if (!(this.billingFrequency !== null && this.billingFrequency !== this.contractTerm)) {
      return numberFormat({ value: this.credits })
    }

    const creditsTotal = numberFormat({
      value: this.totalCredits,
    })
    const creditsPerFrequency = numberFormat({ value: this.credits })

    return `${creditsTotal} (${creditsPerFrequency} every ${this.billingFrequencyToMonth})`
  }

  get totalCredits() {
    if (this.credits === null || this.billingFrequency === null) return null

    if (this.billingFrequency !== this.contractTerm) {
      return this.credits * (12 / intervalToMonth(this.billingFrequency))
    }

    return this.credits
  }
}
