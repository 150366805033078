import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Dropdown, Button, DropdownContent } from 'shared/ui'
import { useSearchDropdownContext } from 'widgets/SearchDropdown'
import { SelectedElement } from 'widgets/SearchDropdown/ui/SelectedElement/SelectedElement'
import { InitDropdownContent } from './InitDropdownContent'
import styles from './styles.module.scss'

type IFiltersDropdownContentProps = {
  parentsDropdownId?: string[]
}

export const FiltersDropdownContent: FC<IFiltersDropdownContentProps> = observer(
  ({ parentsDropdownId }) => {
    const { selectedTypes, hasAllFilters, options } = useSearchDropdownContext()
    const clearFilters = (value: boolean) => {
      options?.clearFilters?.(value)
    }

    return (
      <div className={classnames(styles.filtersWrap)}>
        <div className={styles.selectedFilters}>
          {selectedTypes.map((type) => (
            <SelectedElement key={type} type={type} parentsDropdownId={parentsDropdownId} />
          ))}
        </div>

        <div className={styles.filtersActions}>
          <Dropdown
            ariaLabel={'FiltersDropdownContent'}
            parentsDropdownId={parentsDropdownId}
            iconButtonProps={{
              size: 'medium',
              fontSize: 20,
              ariaLabel: 'FiltersDropdownContent_trigger',
            }}
            disabled={hasAllFilters}
            triggerComponent={() => (
              <Button
                disabled={hasAllFilters}
                contained={'secondary'}
                tooltipProps={{
                  label: 'Add filter',
                  disableInteractive: true,
                }}
                text={'Add filter'}
                icon={'add'}
              />
            )}
            placement={'bottom-start'}
            sizeTriggerButton={'medium'}
            variantTriggerButton={'icon'}
            customComponent={() => {
              if (hasAllFilters) return null

              return (
                <DropdownContent noPadding>
                  <InitDropdownContent />
                </DropdownContent>
              )
            }}
          />

          <Button
            contained={'secondary'}
            typeBtn={'text'}
            tooltipProps={{
              label: 'Clear',
              disableInteractive: true,
            }}
            onClick={() => clearFilters(true)}
            text={'Clear'}
          />
        </div>
      </div>
    )
  }
)
