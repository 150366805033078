import { ScheduleContext } from 'shared/ui/Schedule/context/ScheduleContext'
import { ScheduleStore } from 'shared/ui/Schedule/store/scheduleStore'
import { ScheduleDropdown } from 'shared/ui/Schedule/ui/ScheduleDropdown/ScheduleDropdown'
import { ScheduleTrigger } from 'shared/ui/Schedule/ui/ScheduleDropdown/ui/ScheduleTrigger/ScheduleTrigger'

type IScheduleProps = {
  scheduleStore: ScheduleStore
  onlyView?: boolean
}

export const Schedule = ({ scheduleStore, onlyView }: IScheduleProps) => (
  <ScheduleContext.Provider value={scheduleStore}>
    {onlyView ? <ScheduleTrigger notInteractive /> : <ScheduleDropdown />}
  </ScheduleContext.Provider>
)
