import { makeAutoObservable } from 'mobx'
import { chunk } from 'lodash'
import { errorHandler } from 'shared/api'
import { showToast } from 'shared/ui'
import { ContactsApi } from 'entities/Contacts'
import { conversationStore } from 'entities/Conversation'
import { Contact } from 'entities/Contacts/model/Contact'
import { Tag } from 'entities/Tags/model/Tag'
import {
  IParamsCreateContact,
  IResponseContact,
  IResponseContactCreateError,
} from 'entities/Contacts/api/types'
import { contactTemplate } from 'entities/Contacts/templates/contactTemplate'
import { CreateContactErrors, CreateContactHandler } from 'entities/Contacts/errorHandler'

export class ContactsStore {
  itemsMap: Map<number, Contact> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.itemsMap.clear()
  }

  addItemTags = async (id: number, tags: Tag[]) => {
    const contact = await this.getItem(id)
    if (!contact) return

    const tagsMap = new Map<number, Tag>()
    contact.tags.forEach((item) => {
      tagsMap.set(item.id, item)
    })
    tags.forEach((item) => {
      tagsMap.set(item.id, item)
    })

    contact.tags = Array.from(tagsMap.values())

    return this.itemsMap.set(id, contact)
  }

  removeItemTags = async (id: number, tagsIds: string[]) => {
    const contact = await this.getItem(id)
    if (!contact) return

    const tagsMap = new Map<number, Tag>()
    contact.tags.forEach((item) => {
      tagsMap.set(item.id, item)
    })

    tagsIds.forEach((tagId) => {
      tagsMap.delete(Number(tagId))
    })

    contact.tags = Array.from(tagsMap.values())

    return this.itemsMap.set(id, contact)
  }

  addItems = (items: IResponseContact[]) => {
    items.forEach((item) => {
      this.addItem(item)
    })

    return items.reduce<Contact[]>((state, item) => {
      const contact = this.getItem(item.id)

      return contact ? [...state, contact] : [...state]
    }, [])
  }

  private addItem = (item: IResponseContact) => {
    if (!item) return

    if (item.conversation) {
      conversationStore.updateItem(item.conversation)
    }

    this.itemsMap.set(item.id, new Contact(item))

    return this.getItem(item.id)
  }

  createEmptyContact = (contact?: Partial<IResponseContact>): IResponseContact => ({
    ...contactTemplate,
    id: 0,
    first_name: 'Name',
    last_name: '',
    formatted_number: 'Phone number',
    icon: 'personFilled',
    ...contact,
  })

  updateItem = (data: IResponseContact) => {
    const contact = this.getItem(data.id)

    if (contact) {
      contact.syncOrigin({
        ...contact.origin,
        ...data,
      })
    } else {
      this.addItem(data)
    }
  }

  hasItem = (id: number) => {
    return this.itemsMap.has(id)
  }

  getItem = (id?: number) => {
    if (typeof id !== 'number') return

    return this.itemsMap.get(id)
  }

  getById = async (id: number, update?: boolean) => {
    let contact = this.getItem(id)

    if (!contact) {
      const { data } = await ContactsApi.getContactById(id)

      contact = new Contact(data)

      if (update) {
        contact = this.addItem(data)
      }
    }

    return contact
  }

  getByIds = async (ids: number[]): Promise<Contact[] | undefined> => {
    try {
      const res = await Promise.all(chunk(ids, 100).map(ContactsApi.getContactsByIds))
      const contacts: Contact[] = []
      res.forEach((item) => {
        item.data.data.forEach((item) => {
          contacts.push(new Contact(item))
        })
      })
      return contacts
    } catch (e) {
      console.error(e)
    }
  }

  blockContactById = async (id: number) => {
    const { data } = await ContactsApi.blockContactById(id)

    this.updateItem(data)
  }

  unblockContactById = async (id: number) => {
    const { data } = await ContactsApi.unblockContactById(id)

    this.updateItem(data)
  }

  getContactByNumber = async (number: string) => {
    try {
      const {
        data: { data },
      } = await ContactsApi.getContacts({
        page: 1,
        search: number,
      })

      if (data.length === 1) {
        return new Contact(data[0])
      }

      return
    } catch (e) {
      console.error(e)

      return
    }
  }

  createContact = async (
    params: IParamsCreateContact,
    errorConfig?: {
      catchError: CreateContactErrors
      onError?: () => void
    }
  ) => {
    try {
      const { data: contact } = await ContactsApi.createContact({ ...params, forLink: true })

      this.addItem(contact)

      return new Contact(contact)
    } catch (e) {
      let contact: Contact | undefined = undefined
      const err = e as Error
      const { type, error } = await errorHandler<IResponseContactCreateError>(err)

      if (type === 'axios-error') {
        const data = error.response?.data
        const contactId = data?.contact_id

        if (contactId) {
          contact = await this.getById(contactId)
        } else {
          const error = (data?.email && data?.email[0]) || (data?.number && data?.number[0])

          if (errorConfig && errorConfig.catchError === error) {
            errorConfig.onError && errorConfig.onError()
            CreateContactHandler[error]()
          } else if (error) {
            showToast({
              title: error,
              type: 'error',
            })
          }
        }
      }

      return contact
    }
  }
}

export const contactsStore = new ContactsStore()
