// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DoIOJozTEmxtPRjFaliZ{display:flex;align-items:center;height:32px;padding:0 8px;font-size:13px;font-weight:400}.DoIOJozTEmxtPRjFaliZ.VrbMkWKJlYlTsFEvRXrE{padding:0}.DoIOJozTEmxtPRjFaliZ .dCoIahGY0ctGeU2VyU5t{overflow:hidden;color:var(--content-primary-tertiary);text-overflow:ellipsis;width:110px;line-height:normal;white-space:nowrap}.DoIOJozTEmxtPRjFaliZ .LLZKw9ZhITuumiqQlK9V{width:164px;white-space:nowrap;overflow:hidden;color:var(--content-primary-primary);text-overflow:ellipsis}.DoIOJozTEmxtPRjFaliZ .LLZKw9ZhITuumiqQlK9V .UReXgspCLVr8HE_gQnRP{border-radius:6px;padding:4px 8px !important}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ItemRow/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,aAAA,CAEA,cAAA,CACA,eAAA,CAEA,2CACE,SAAA,CAGF,4CACE,eAAA,CACA,qCAAA,CACA,sBAAA,CACA,WAAA,CACA,kBAAA,CACA,kBAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CACA,eAAA,CACA,oCAAA,CACA,sBAAA,CAEA,kEACE,iBAAA,CACA,0BAAA","sourcesContent":[".companyWrapper {\n}\n\n.itemRow {\n  display: flex;\n  align-items: center;\n  height: 32px;\n  padding: 0 8px;\n\n  font-size: 13px;\n  font-weight: 400;\n\n  &.emptyPadding {\n    padding: 0;\n  }\n\n  .label {\n    overflow: hidden;\n    color: var(--content-primary-tertiary);\n    text-overflow: ellipsis;\n    width: 110px;\n    line-height: normal;\n    white-space: nowrap;\n  }\n\n  .value {\n    width: 164px;\n    white-space: nowrap;\n    overflow: hidden;\n    color: var(--content-primary-primary);\n    text-overflow: ellipsis;\n\n    .textInput {\n      border-radius: 6px;\n      padding: 4px 8px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemRow": "DoIOJozTEmxtPRjFaliZ",
	"emptyPadding": "VrbMkWKJlYlTsFEvRXrE",
	"label": "dCoIahGY0ctGeU2VyU5t",
	"value": "LLZKw9ZhITuumiqQlK9V",
	"textInput": "UReXgspCLVr8HE_gQnRP"
};
export default ___CSS_LOADER_EXPORT___;
