import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Alert } from 'shared/ui'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'

type IModalAlertProps = {
  contactCreateModalStore: ContactCreateModalStore
}
export const ContactCreateModalAlert: FC<IModalAlertProps> = observer(
  ({ contactCreateModalStore }) => {
    if (!contactCreateModalStore?.alert) {
      return null
    }
    return (
      <Alert
        item={{
          type: contactCreateModalStore.alert.type,
          title: contactCreateModalStore.alert.title || '',
          desc: contactCreateModalStore.alert.desc || '',
        }}
        onClose={contactCreateModalStore.resetAlert}
      />
    )
  }
)
