export const checkMicrophone = async () => {
  let stream
  try {
    stream = await window.navigator.mediaDevices.getUserMedia({ audio: true })

    return true
  } catch (err) {
    return false
  } finally {
    stream?.getAudioTracks().forEach((track) => {
      track.stop()
    })
  }
}
