import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import { ContainerHeader, Typography } from 'shared/ui'
import { useUnsavedChanges } from 'shared/hooks'
import { AdminDetailPage, AdminRoutesEnum } from 'pages/admin'
import { AdminHeaderOrganizationActions } from 'pages/admin/pages/compliance'
import { adminTollFreeDetailStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails'
import { BusinessInfo } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/BusinessInfo/BusinessInfo'
import { BusinessContact } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/BusinessContact/BusinessContact'
import { Campaign } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/Campaign/Campaign'

export const TollFreeDetails = observer(() => {
  const { id } = useParams()
  const {
    loading,
    setId,
    onOpenRejectModal,
    onOpenReviewModal,
    isHasUnsavedChanges,
    organizationById,
    disabledReview,
    isActions,
    data,
  } = adminTollFreeDetailStore

  useEffect(() => {
    if (id) {
      setId(Number(id))
    }
  }, [id])

  useUnsavedChanges(isHasUnsavedChanges)

  return (
    <AdminDetailPage
      skippedCountLastRoutesForLabel={1}
      loading={loading}
      header={
        <ContainerHeader
          customTitle={
            <>
              <a
                href={`/${AdminRoutesEnum.admin}/${AdminRoutesEnum.organizations}/${organizationById?.id}`}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={'noTextDecoration'}
              >
                <Typography variant={'body-lg-medium'} link ariaLabel={'name'}>
                  {organizationById?.name}
                </Typography>
              </a>

              <Typography variant={'body-lg-medium'} ariaLabel={'Toll-free'}>
                Toll-free {data?.number.formatted_number}
              </Typography>
            </>
          }
          titleIconProps={{
            icon: 'users',
            fontSize: 20,
            tertiary: true,
          }}
          actions={
            isActions
              ? [
                  {
                    text: 'Manual reject',
                    onAction: onOpenRejectModal,
                    medium: true,
                  },
                  {
                    text: 'Review and publish',
                    icon: 'twilio',
                    onAction: onOpenReviewModal,
                    medium: true,
                    buttonProps: {
                      contained: 'primary',
                      disabled: disabledReview,
                    },
                  },
                ]
              : undefined
          }
          actionsGap={8}
        />
      }
      headerActions={<AdminHeaderOrganizationActions organizationById={organizationById} />}
      info={
        <>
          <BusinessInfo />
          <BusinessContact />
        </>
      }
      content={<Campaign />}
    ></AdminDetailPage>
  )
})
