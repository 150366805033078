// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".djjTjsdqqgrQNmfE9AjQ .PfT7PUaC2kBKMgDg5pGF{width:100%;justify-content:left}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/SavedRepliesContent/LeftContent/NewReplyBtn/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,UAAA,CACA,oBAAA","sourcesContent":[".wrapBtn{\n  .btn{\n    width: 100%;\n    justify-content: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapBtn": "djjTjsdqqgrQNmfE9AjQ",
	"btn": "PfT7PUaC2kBKMgDg5pGF"
};
export default ___CSS_LOADER_EXPORT___;
