import { createContext, useContext } from 'react'
import { type ContactsConversationOpenStore } from 'widgets/ContactsDetails/ui/ContactsConversationOpen'

export const ContactsConversationOpenContext = createContext<ContactsConversationOpenStore | null>(
  null
)

export function useContactsConversationOpenContext() {
  const context = useContext(ContactsConversationOpenContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ContactsConversationOpenStoreProvider'
    )
  }
  return context
}
