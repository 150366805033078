// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B9Tm7ZTE9pcreEiBpBvD{padding:0 24px 24px 24px}.dvVNZop7CDbq3lGLMWRm{padding-block:4px}.ST6tfrAPRKh3YeP5gApd{display:flex;align-items:center;color:var(--red-60);font-size:11px;font-weight:400;gap:4px}.YFnLwtkJzRJARfBKxvpn{display:flex;justify-content:flex-end;gap:8px;margin-top:32px}", "",{"version":3,"sources":["webpack://./src/widgets/RenameModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,iBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,cAAA,CACA,eAAA,CACA,OAAA,CAEF,sBACE,YAAA,CACA,wBAAA,CACA,OAAA,CACA,eAAA","sourcesContent":[".wrap{\n  padding: 0 24px 24px 24px;\n}\n.wrapInput{\n  padding-block: 4px;\n}\n.errorContainer {\n  display: flex;\n  align-items: center;\n  color: var(--red-60);\n  font-size: 11px;\n  font-weight: 400;\n  gap: 4px;\n}\n.actions{\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "B9Tm7ZTE9pcreEiBpBvD",
	"wrapInput": "dvVNZop7CDbq3lGLMWRm",
	"errorContainer": "ST6tfrAPRKh3YeP5gApd",
	"actions": "YFnLwtkJzRJARfBKxvpn"
};
export default ___CSS_LOADER_EXPORT___;
