import { observer } from 'mobx-react-lite'
import { IconButton, Typography } from 'shared/ui'
import { AudioPlayer } from 'shared/ui/AudioPlayer'
import { UiAudioCard } from 'widgets/MessageFormFields/RinglessMessageField/ui/UiAudioCard/UiAudioCard'
import { useRinglessMessageFieldContext } from 'widgets/MessageFormFields/RinglessMessageField/context/ringlessMessageFieldContext'

export const RinglessAudio = observer(() => {
  const { attachment, onRemoveAudio } = useRinglessMessageFieldContext()
  return (
    <UiAudioCard
      headerLeft={
        <Typography
          ariaLabel={'title'}
          tag={'div'}
          variant={'body-md-medium'}
          ellipsis
          tooltipProps={{
            label: attachment?.name,
          }}
        >
          {attachment?.name}
        </Typography>
      }
      headerRight={
        <>
          <IconButton
            ariaLabel={'delete'}
            icon={'close'}
            color={'secondary'}
            size={'small'}
            variant={'icon'}
            onClick={onRemoveAudio}
            tooltipProps={{
              label: 'Remove',
              placement: 'top',
            }}
          />
        </>
      }
      content={<AudioPlayer url={attachment?.source} fullWidth />}
    />
  )
})
