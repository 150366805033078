import { observer } from 'mobx-react-lite'
import { EmptyState } from 'shared/ui'

export const DialogEmpty = observer(() => {
  return (
    <EmptyState
      boxProps={{ sx: { textAlign: 'center' } }}
      iconProps={{ icon: 'chatBot', color: 'var(--content-neutral-primary)' }}
      title='Have a test conversation with your Textbot!'
      text='This doesn’t use any credits, and is designed to help you understand the conversation your contacts will have with your Textbot.'
    />
  )
})
