import { makeAutoObservable, runInAction, reaction } from 'mobx'
import { ConversationsApi } from 'entities/Conversation'
import { usersStore } from 'entities/Users'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { Message } from 'entities/Message/model/Message'
import { inboxesStore } from 'entities/Inbox'
import {
  IResponseConversationsFilter,
  IResponseConversationsFilterKey,
} from 'entities/Conversation/api/types'

type ISubscription = (filter: string) => void

export class FiltersStore {
  private subscriptions = new Set<ISubscription>()

  constructor() {
    makeAutoObservable(this)
  }

  loading = true
  filtersMap: Map<IResponseConversationsFilterKey, IResponseConversationsFilter> = new Map()
  filter: IResponseConversationsFilter = {
    key: 'open',
    label: 'Open',
  }
  reactionChange: ReturnType<typeof reaction> | null = null

  request: null | ReturnType<(typeof ConversationsApi)['getFiltersList']> = null

  storeActiveFilter = () => {
    if (!usersStore.user) return

    window.localStorage.setItem(this.storageKey, this.filter.key)
  }

  restoreActiveFilter = () => {
    if (!usersStore.user) return
    const activeFilterKey = window.localStorage.getItem(this.storageKey)

    if (!activeFilterKey) {
      this.setFilter('open')
      return
    }

    const activeFilter = this.getItem(activeFilterKey)
    if (!activeFilter) return

    this.filter = activeFilter
  }

  reset = () => {
    this.loading = false
  }

  loadFilters = async (): Promise<void> => {
    if (this.request) return this.request.then()

    this.loading = true
    this.request = ConversationsApi.getFiltersList()

    const { data: filters } = await this.request

    this.addItems(filters)
    this.restoreActiveFilter()

    runInAction(() => {
      this.loading = false
    })
  }

  setFilter = (id: IResponseConversationsFilterKey, silent = false) => {
    const filter = this.getItem(id)

    if (filter) {
      this.filter = filter
      this.storeActiveFilter()

      if (!silent) this.subscriptions.forEach((subscription) => subscription(filter.key))
    }
  }

  addItems = (items: IResponseConversationsFilter[]) => {
    items.forEach((item) => {
      this.filtersMap.set(item.key, item)
    })
  }

  getItem = (key: IResponseConversationsFilterKey) => {
    return this.filtersMap.get(key)
  }

  subscribeFilterChange(subscription: ISubscription) {
    this.subscriptions.add(subscription)
  }

  unsubscribeFilterChange(subscription: ISubscription) {
    this.subscriptions.delete(subscription)
  }

  isShowConversation = (conversation: Conversation, message?: Message): boolean => {
    if (this.filter.key === 'open') {
      if (message && message.isServerCommand) return true

      return conversation.hasOpenFilter
    }

    if (this.filter.key === 'unread') {
      return conversation.hasUnreadFilter
    }

    if (this.filter.key === 'assigned') {
      return conversation.hasAssignedFilter
    }

    if (this.filter.key === 'unassigned') {
      return conversation.hasUnassignedFilter
    }

    if (this.filter.key === 'pending') {
      return conversation.hasNeedResponseFilter
    }

    if (this.filter.key === 'closed') {
      return conversation.hasClosedFilter
    }

    if (this.filter.key === 'outbound') {
      return conversation.hasOutboundFilter
    }

    if (this.filter.key === 'optout') {
      return conversation.hasOptOutFilter
    }

    if (this.filter.key === 'failed') return false

    if (this.filter.key === 'blocked') {
      return conversation.hasBlockedFilter
    }

    if (this.filter.key === 'mentions') {
      return conversation.hasMentionsFilter(message)
    }

    const { currentInboxId, currentInbox } = inboxesStore

    if (currentInboxId && currentInboxId !== conversation.inbox_id && !currentInbox?.isGroupInbox) {
      return false
    }

    if (currentInbox?.isGroupInbox && !currentInbox.teamsIds.includes(conversation.inbox_id)) {
      return false
    }

    return false
  }

  get filters() {
    return Array.from(this.filtersMap.values())
  }

  get storageKey() {
    if (!usersStore.user) return ''

    return `${usersStore.user.id}_conversation_filter`
  }

  get isOpen() {
    return this.filter.key === 'open'
  }

  get isPending() {
    return this.filter.key === 'pending'
  }

  get isClosed() {
    return this.filter.key === 'closed'
  }
  get isUnread() {
    return this.filter.key === 'unread'
  }
}

export const filtersStore = new FiltersStore()
