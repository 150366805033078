import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Button, ProgressInfo } from 'shared/ui'
import { CompanyLocation } from './CompanyLocation'

import styles from './styles.module.scss'
import { useComplianceSettingsContext } from '../context/complianceSettingsContext'
import { BusinessInfo } from './BusinessInfo'
import { PeopleToContact } from './PeopleToContact'
import { GeneralInfo } from './GeneralInfo'
import { TermsAndPrivacy } from './TermsAndPrivacy'

const titleMap: Record<number, string> = {
  1: 'Company location',
  2: 'General info',
  3: 'Business info',
  4: 'People to contact',
  5: 'Terms and Privacy',
}

interface IComplianceSettingContainerProps {
  settingsStep: number
  settingsTotal: number
  increaseStep: () => void
}

export const ComplianceSettingContainer = observer(
  ({ settingsStep, settingsTotal, increaseStep }: IComplianceSettingContainerProps) => {
    const {
      companyLocation,
      einStore,
      generalInfoActiveTab,
      businessInfoStore,
      peopleToContactStore,
      isTermsAndPrivacyChecked,
      stepLoading,
      submitGeneralInfo,
      submitPointsOfContact,
      submitTermsAndPrivacy,
    } = useComplianceSettingsContext()

    const isNextDisabled = useMemo(() => {
      switch (settingsStep) {
        case 1:
          return !companyLocation
        case 2:
          if (generalInfoActiveTab === 1) {
            return !einStore.einItem
          }
          if (generalInfoActiveTab === 2) {
            return !einStore.isManuallyEinItemFulled
          }
          return false
        case 3:
          return businessInfoStore.isNextDisabled
        case 4:
          return peopleToContactStore.isNextDisabled
        case 5:
          return !isTermsAndPrivacyChecked
        default:
          return false
      }
    }, [
      settingsStep,
      companyLocation,
      einStore.einItem,
      einStore.isManuallyEinItemFulled,
      generalInfoActiveTab,
      businessInfoStore.isNextDisabled,
      peopleToContactStore.isNextDisabled,
      isTermsAndPrivacyChecked,
    ])

    return (
      <>
        <ProgressInfo
          title={titleMap[settingsStep]}
          step={settingsStep}
          total={settingsTotal}
          className={styles.progressInfo}
        />
        {settingsStep === 1 && <CompanyLocation />}
        {settingsStep === 2 && <GeneralInfo />}
        {settingsStep === 3 && <BusinessInfo />}
        {settingsStep === 4 && <PeopleToContact />}
        {settingsStep === 5 && <TermsAndPrivacy />}
        <Button
          fullWidth
          loading={stepLoading}
          disabled={isNextDisabled}
          className={styles.nextButton}
          text={settingsStep === 5 ? 'Submit for review' : 'Next'}
          onClick={() => {
            if (settingsStep === 1) {
              increaseStep()
            }
            if (settingsStep === 2) {
              submitGeneralInfo(1, increaseStep)
            }
            if (settingsStep === 3) {
              submitGeneralInfo(2, increaseStep)
            }
            if (settingsStep === 4) {
              submitPointsOfContact(increaseStep)
            }
            if (settingsStep === 5) {
              submitTermsAndPrivacy()
            }
          }}
        />
      </>
    )
  }
)
