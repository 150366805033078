import React from 'react'
import { Icon, Tooltip } from 'shared/ui'

export type ITooltipErrorProps = {
  error: string
}

export const TooltipError: React.FC<ITooltipErrorProps> = ({ error }) => (
  <Tooltip label={error} margin={16} color={'red'} placement={'top'}>
    <Icon icon={'alertTriangleSmall'} fontSize={16} color={'var(--content-negative-primary)'} />
  </Tooltip>
)
