import { useState } from 'react'
import { runInAction } from 'mobx'
import { useEventAttachmentUploadSuccessful } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { useEventAttachmentThumbnailCreated } from 'entities/Attachment/events/EventAttachmentThumbnailCreated'
import { type MediaLibraryStore } from 'widgets/MediaLibrary'

export const useMediaLibraryEventAttachmentUpload = (mediaLibraryStore: MediaLibraryStore) => {
  const [, setPendingAttachments] = useState<Attachment[]>([])

  useEventAttachmentUploadSuccessful(({ attachment, space_used }) => {
    runInAction(() => {
      mediaLibraryStore.spaceUsed = space_used
    })
    const attachmentMedia = new Attachment({ responseMedia: attachment })
    const isVideo = attachmentMedia?.type === 'video'
    const isImage =
      attachmentMedia?.type === 'image' ||
      attachmentMedia?.type === 'gif' ||
      attachmentMedia?.type === 'previewGif'

    if (isVideo || isImage) {
      setPendingAttachments((prev) => [...prev, attachmentMedia])
    } else {
      mediaLibraryStore.addAttachment(attachmentMedia, true)
    }
  })

  useEventAttachmentThumbnailCreated((data) => {
    const attachmentMedia = new Attachment({ responseMedia: data })

    setPendingAttachments((prev) => {
      if (mediaLibraryStore.allAttachmentsIds.includes(data.id)) {
        mediaLibraryStore.addAttachment(attachmentMedia, true)
      }

      return prev.filter((attachment) => attachment.id !== attachmentMedia.id)
    })
  })
}
