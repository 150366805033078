// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g2gc7UWql9JRd6Mcgu6B{display:flex;justify-content:center;flex-direction:column}.g2gc7UWql9JRd6Mcgu6B .QK_uzxVHcq6sbYlNDsZA{display:flex;justify-content:center;font-size:40px;line-height:48px}.g2gc7UWql9JRd6Mcgu6B .CxaGsIpmQ_nZMHlKuAH3{display:flex;justify-content:center;padding:16px 0 32px}.g2gc7UWql9JRd6Mcgu6B .H7TCcEHmbRW2CzDFHlgQ{display:flex;justify-content:center;margin-bottom:40px}.g2gc7UWql9JRd6Mcgu6B .JZoN4HD4k_Sb_53G2jlW{display:flex;justify-content:center;font-size:40px;line-height:53px;font-weight:500}.l3mKxlnoEYBOkIXbyeuR{margin-top:16px;width:100%;display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/widgets/getStartedModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,sBAAA,CACA,qBAAA,CAEA,4CACI,YAAA,CACA,sBAAA,CACA,cAAA,CACA,gBAAA,CAGJ,4CACI,YAAA,CACA,sBAAA,CACA,mBAAA,CAGJ,4CACI,YAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,4CACI,YAAA,CACA,sBAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CAIR,sBACI,eAAA,CACA,UAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".contentWrap {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n\n    .icon {\n        display: flex;\n        justify-content: center;\n        font-size: 40px;\n        line-height: 48px;\n    }\n\n    .title {\n        display: flex;\n        justify-content: center;\n        padding: 16px 0 32px;\n    }\n\n    .helpText {\n        display: flex;\n        justify-content: center;\n        margin-bottom: 40px;\n    }\n\n    .number {\n        display: flex;\n        justify-content: center;\n        font-size: 40px;\n        line-height: 53px;\n        font-weight: 500;\n    }\n}\n\n.actionsWrap {\n    margin-top: 16px;\n    width: 100%;\n    display: flex;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrap": "g2gc7UWql9JRd6Mcgu6B",
	"icon": "QK_uzxVHcq6sbYlNDsZA",
	"title": "CxaGsIpmQ_nZMHlKuAH3",
	"helpText": "H7TCcEHmbRW2CzDFHlgQ",
	"number": "JZoN4HD4k_Sb_53G2jlW",
	"actionsWrap": "l3mKxlnoEYBOkIXbyeuR"
};
export default ___CSS_LOADER_EXPORT___;
