import { useEffect } from 'react'
import { ITextFieldMainAction } from 'shared/ui/TextField'

type ITextFieldSelectionRange = {
  start: number | null
  end: number | null
}

export const makeTextFieldSelectionRange: (
  action: ITextFieldSelectionRange
) => ITextFieldMainAction = (action) => {
  const useAction: ITextFieldMainAction = (control, children) => {
    useEffect(() => {
      const input = control.inputRef?.current

      if (!input) return

      input.setSelectionRange(action.start, action.end)
    }, [action.start, action.end])

    return children
  }

  return useAction
}
