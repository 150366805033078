// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cQwmDegM9JYlblGnTpPj{position:relative;margin-top:24px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTagsModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,eAAA","sourcesContent":[".dropdownContainer {\n    position: relative;\n    margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownContainer": "cQwmDegM9JYlblGnTpPj"
};
export default ___CSS_LOADER_EXPORT___;
