import classnames from 'classnames'
import { Button, CopyWrapper } from 'shared/ui'
import { IDeal } from 'entities/Integrations'
import { DealsRow } from './DealsRow'

import styles from '../styles.module.scss'

export type IDealsRowType = 'amount' | 'closeDate' | 'stage' | 'owner' | 'pipeline' | 'value'

type IDealsProps = {
  deals: IDeal[]
  rows: IDealsRowType[]
  link: (id?: number | string | null) => string
  viewAll: () => void
}

const EmptyDeals = () => <div className={styles.emptyData}>No deals found</div>

const DealsTab = ({ deals, link, viewAll, rows }: IDealsProps) => {
  if (!deals.length) return <EmptyDeals />

  const hasMore = deals.length > 1

  const handleViewAll = () => {
    viewAll()
  }

  return (
    <div className={classnames(styles.contentWrapper)}>
      {deals.slice(0, 1).map((deal) => (
        <div key={deal?.id?.value}>
          <div className={styles.headerWrapper}>
            <CopyWrapper
              copyValue={deal.dealname?.value || null}
              data={{
                title: deal.dealname?.value || 'View deal',
                link: link(deal?.id?.value),
              }}
            />
          </div>
          <DealsRow deal={deal} rows={rows} />
        </div>
      ))}
      {hasMore && (
        <div className={styles.showMore}>
          <Button size='medium' typeBtn='text' text='See all' onClick={handleViewAll} />
        </div>
      )}
    </div>
  )
}

export { DealsTab }
