import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { Segment } from 'entities/Segment/model/Segment'
import { SegmentBox } from 'entities/Segment'
import { FilterEditorStore, FilterEditor } from 'widgets/FilterEditor'
import styles from './styles.module.scss'

export interface IFilterEditorProps {
  filterEditorStore: FilterEditorStore
  segmentBox: SegmentBox
  isViewOnly?: boolean
  onCancel: () => void
  onApply: (segment: Segment) => void
}

export const FilterEditorContent: FC<IFilterEditorProps> = observer(
  ({ onApply, onCancel, isViewOnly, filterEditorStore, segmentBox }) => {
    const { hasChanges } = segmentBox
    const segment = filterEditorStore.groups.toSegment({
      id: segmentBox.segment?.id,
      organization_id: segmentBox.segment?.organizationId,
    })
    const disabled = !hasChanges || !filterEditorStore.isReady

    useEffect(() => {
      filterEditorStore.setViewOnly(Boolean(isViewOnly))
    }, [isViewOnly])

    return (
      <div className={styles.filterContainer}>
        <FilterEditor store={filterEditorStore} />

        {!isViewOnly && (
          <div className={styles.filterActions}>
            <Button
              typeBtn='text'
              text='Apply'
              onClick={() => {
                if (segment) onApply(segment)
              }}
              disabled={disabled}
            />
            <div className={styles.divider} />
            <Button typeBtn='text' text='Cancel' onClick={() => onCancel()} />
          </div>
        )}
      </div>
    )
  }
)
