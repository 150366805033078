import { ContactsApi } from 'entities/Contacts'
import { IParamsContactFiltersCount } from 'entities/Contacts/api/types'
import { Contact } from 'entities/Contacts/model/Contact'
import { Segment } from 'entities/Segment/model/Segment'
import { Tag } from 'entities/Tags/model/Tag'
import { IEnrollmentFiltersParams } from '../types'

const getItemCount = (
  filtersListGroups: IParamsContactFiltersCount['filtersListGroups'],
  number_id?: IParamsContactFiltersCount['number_id'] | null
) =>
  ContactsApi.getContactsFiltersCount({ filtersListGroups, number_id: number_id || undefined })
    .then(({ data: { count, invalid_count } }) => ({
      count,
      invalid_count,
    }))
    .catch(() => ({
      count: 0,
      invalid_count: 0,
    }))

export const getTagCount = (tag: Tag, number_id?: IParamsContactFiltersCount['number_id'] | null) =>
  getItemCount({ tags: [tag.id] }, number_id)

export const getSegmentCount = (
  segment: Segment,
  number_id?: IParamsContactFiltersCount['number_id'] | null
) => getItemCount({ segments: [segment.id] }, number_id)

export const getContactCount = (
  contact: Contact,
  number_id?: IParamsContactFiltersCount['number_id'] | null
) => getItemCount({ contacts: [contact.id] }, number_id)

export const getFilterCount = (
  filter: Segment,
  number_id?: IParamsContactFiltersCount['number_id'] | null
) => getItemCount({ advanced: [filter.filtersParams] }, number_id)

export const getTotalCount = ({
  contacts,
  segments,
  filters,
  tags,
  number_id,
}: Partial<IEnrollmentFiltersParams>) =>
  getItemCount(
    {
      contacts: contacts?.map((contact) => contact.id) ?? [],
      segments: segments?.map((segment) => segment.id) ?? [],
      advanced: filters?.map((filter) => filter.filtersParams) ?? [],
      tags: tags?.map((tag) => tag.id),
    },
    number_id
  )
