import { Attachment } from 'entities/Attachment/model/Attachment'
import { IResponseMedia } from 'entities/Attachment'
import { IParsedVcard } from 'entities/VCard'
import { IResponseConversationDraftMessageAttachment } from 'entities/Conversation/api/types'

export class RequestAttachment {
  id: number | string
  media_preview?: IResponseMedia
  original_source?: string
  content_type: string
  source?: string
  source_short?: string
  created_at?: string
  duration?: number
  name?: string | null
  size?: string | null | number
  is_allowed_for_media_url?: boolean
  parsedVCard?: Array<IParsedVcard> | IParsedVcard
  url: string

  constructor(attachment: Attachment) {
    this.content_type = attachment.content_type
    this.original_source = attachment.original_source
    this.source = attachment.source
    this.source_short = attachment.source_short
    this.created_at = attachment.created_at
    this.duration = attachment.duration
    this.size = attachment.size
    this.parsedVCard = attachment.parsedVCard
    this.id = attachment.id
    this.name = attachment.name
    this.url = attachment.source || ''
  }

  get attachmentDraft(): IResponseConversationDraftMessageAttachment {
    return {
      content_type: this.content_type,
      name: this.name,
      url: this.url,
      source: this.source,
    }
  }
}
