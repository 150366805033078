import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, SpinnerLoader, Tooltip, Typography } from 'shared/ui'
import { inboxesStore } from 'entities/Inbox'
import { NumbersInboxesListStore } from 'widgets/NumbersInboxesList'
import styles from './styles.module.scss'

export type IButtonProps = {
  type: 'default' | 'transparent'
}

type INumbersInboxesButtonProps = {
  buttonProps?: IButtonProps
  store: NumbersInboxesListStore
}

const defaultButtonProps: IButtonProps = {
  type: 'default',
}

export const NumbersInboxesButton: FC<INumbersInboxesButtonProps> = observer(
  ({ buttonProps = defaultButtonProps, store }) => {
    const { type } = buttonProps
    const activeInbox = inboxesStore.getItem(store.activeInboxId)

    if (store.loading) {
      return (
        <div className={styles.button}>
          <SpinnerLoader
            size={14}
            determinatecolor={'var(--gray-70)'}
            indeterminatecolor={'var(--gray-30)'}
          />
        </div>
      )
    }

    if (!store.activeInboxId) {
      return (
        <Tooltip
          label={store.error ? 'Please select the inbox to send from' : null}
          placement={'right'}
          color={'red'}
          disableInteractive
        >
          <div
            className={classNames(styles.button, {
              [styles.button__error]: store.error,
            })}
          >
            <div className={styles.button__icon}>
              <Icon icon={'inboxOpen'} />
            </div>

            <div className={styles.button__info}>
              <Typography
                className={styles.button__placeholder}
                variant={'body-md-regular'}
                ariaLabel={'placeholder'}
              >
                Select Inbox
              </Typography>

              {store.error && <Icon className={styles.button__iconError} icon={'alertTriangle'} />}
            </div>
          </div>
        </Tooltip>
      )
    }

    return (
      <div
        className={classNames(styles.button, styles[type], {
          [styles.active]: store.active,
        })}
      >
        {activeInbox && (
          <div className={styles.button__icon}>
            <Icon icon={activeInbox.icon} />
          </div>
        )}
        {store.activeInboxName && (
          <div className={styles.button__info}>
            <div className={styles.button__name}>{store.activeInboxName}</div>
            <div className={styles.button__number}>
              <div className={styles.button__number__icon}>
                <Icon fontSize={2} icon={'ellipse_6'} />
              </div>
              <div className={styles.button__number__name}>{store.activeNumberName}</div>
            </div>
          </div>
        )}
      </div>
    )
  }
)
