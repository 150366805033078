import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotCollapse } from 'pages/chatbot/ui/ChatbotCollapse'

import { ChatbotActions } from '../ChatbotActions'

export const StepSuccessActions = observer(() => {
  const { errors, successActions } = useChatbotContext()

  const error = errors.getError('success_actions')
  const isOwnError = errors.hasOwnError('success_actions')
  const isNestedError = errors.hasError('success_actions')

  const successActionsError = isOwnError
    ? error
    : isNestedError
    ? 'Please complete all the fields'
    : null

  useEffect(() => {
    if (!!successActions.list.length && isOwnError) errors.removeError('success_actions')
  }, [successActions.list, isOwnError])

  return (
    <ChatbotCollapse
      icon='successCircle'
      title='Success actions'
      tooltip='These actions will happen once your Textbot has collected all the fields you set in your objective'
      error={successActionsError}
    >
      <ChatbotActions actions={successActions} emptyPlaceholder='Please add actions' />
    </ChatbotCollapse>
  )
})
