import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { DropdownContent, Icon, Button, Switch } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import {
  ContactsSearch,
  ContactsSearchVariantEnum,
  IntegrationsTabEnum,
} from 'widgets/ContactsSearch'
import { TargetContactCard } from '../../TargetContactCard/TargetContactCard'
import { linkContactStore } from '../../../store/linkContactStore'
import { IntegrationContactCard } from '../../IntegrationContactCard/IntegrationContactCard'

import styles from './styles.module.scss'

const LinkManualTab = observer(() => {
  const [focused, setFocused] = useState(false)

  const { search, handleSearch, selectedContact, resetManual, shouldUpdate, toggleUpdate } =
    linkContactStore

  return (
    <div className={classnames(styles.wrap)}>
      <TargetContactCard />

      <div className={styles.divider}>
        <div className={styles.icon}>
          <Icon icon='arrowDown' fontSize={16} color='var(--content-primary-tertiary)' />
        </div>
      </div>

      <div className={styles.labelWrapper}>
        <Label
          label={{
            text: 'Integration contact',
          }}
        />
        {selectedContact && (
          <Button text='Remove' onClick={resetManual} typeBtn='text' size='small' />
        )}
      </div>

      {selectedContact ? (
        <IntegrationContactCard contact={selectedContact} isSelected />
      ) : (
        <>
          <TextField
            InputProps={{
              placeholder: focused ? 'Search' : 'Select',
            }}
            size='small'
            variant='stroke'
            value={search}
            onChange={handleSearch}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            rightActions={[
              makeTextFieldClear(),
              makeTextFieldIcon({ icon: 'chevronDown', hidden: focused || search.length !== 0 }),
            ]}
          />

          {(focused || search) && (
            <div className={styles.dropdownWrapper}>
              <DropdownContent>
                <ContactsSearch
                  focusedSearch={focused}
                  search={search}
                  onChange={(contact) => linkContactStore.selectContact(contact)}
                  variant={ContactsSearchVariantEnum.default}
                  hiddenIntegrationsTabs={[IntegrationsTabEnum.salesmsg]}
                  paramsCreateContact={{
                    forLink: false,
                  }}
                />
              </DropdownContent>
            </div>
          )}
        </>
      )}

      {selectedContact && (
        <div className={styles.updateWrapper} onClick={() => toggleUpdate(!shouldUpdate)}>
          <Label
            label={{
              text: 'Update Salesmsg contact with the linked contact data',
            }}
          />
          <Switch value={shouldUpdate} onChange={toggleUpdate} size='small' />
        </div>
      )}
    </div>
  )
})

export { LinkManualTab }
