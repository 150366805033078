import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { FieldDropdown } from 'shared/ui'
import { IntegrationDropdownContext } from 'widgets/IntegrationDropdown/context/integrationDropdownContext'
import { type IntegrationDropdownStore } from 'widgets/IntegrationDropdown/store/integrationDropdownStore'
import { PhoneFieldTrigger } from 'widgets/IntegrationDropdown/ui/PhoneField/PhoneFieldTrigger'
import { PhoneFieldContent } from 'widgets/IntegrationDropdown/ui/PhoneField/PhoneFieldContent'
import { IntegrationTrigger } from './IntegrationTrigger'
import { IntegrationContent } from './IntegrationContent'
import styles from './styles.module.scss'

type IIntegrationDropdownProps = {
  store: IntegrationDropdownStore
}

export const IntegrationDropdown: FC<IIntegrationDropdownProps> = observer(({ store }) => {
  useEffect(() => {
    return () => {
      store.reset()
    }
  }, [])

  return (
    <IntegrationDropdownContext.Provider value={store}>
      <FieldDropdown
        offset={{ x: -4 }}
        labelText={'Integration'}
        triggerComponent={<IntegrationTrigger />}
        customComponent={(_, dropdownId) => <IntegrationContent dropdownId={dropdownId} />}
      />
      {store.selectedIntegrationItem && store.selectedIntegrationItem.id !== 'custom' && (
        <div className={styles.phoneFieldContainer}>
          <FieldDropdown
            offset={{ x: -4 }}
            labelText={'Phone field'}
            triggerComponent={<PhoneFieldTrigger />}
            customComponent={(_, dropdownId) => <PhoneFieldContent dropdownId={dropdownId} />}
          />
        </div>
      )}
    </IntegrationDropdownContext.Provider>
  )
})
