import { forwardRef, ReactNode, Ref } from 'react'
import classNames from 'classnames'
import { Typography, Radio } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type InputRadioProps = {
  checked: boolean
  onChecked: () => void
  label: string
  rightText?: string | ReactNode
  className?: string
  width?: number
}

export const InputRadio = forwardRef(function InputRadio(
  { checked, onChecked, label, className, rightText, width }: InputRadioProps,
  ref: Ref<HTMLDivElement>
) {
  const ariaLabel = 'InputRadiobox'

  return (
    <div
      className={classNames(styles.wrap, className, {
        [styles.checked]: checked,
      })}
      style={{ width: width || undefined }}
      onClick={onChecked}
      aria-label={getAriaLabel(ariaLabel)}
      ref={ref}
    >
      <Radio checked={checked} />
      <div className={styles.label} aria-label={getAriaLabel(ariaLabel, 'label')}>
        <Typography variant={'text-input-field'} ariaLabel={'label'}>
          {label}
        </Typography>
        {rightText && (
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'text-input-field'}
            ariaLabel={'rightText'}
          >
            {rightText}
          </Typography>
        )}
      </div>
    </div>
  )
})
