import { IDays, longShortDayMap } from 'shared/lib'

const shortenLabelForDays: { [key: string]: string } = {
  'sunday,monday,tuesday,wednesday,thursday,friday,saturday': 'on all days',
  'monday,tuesday,wednesday,thursday,friday': 'on weekdays',
  'sunday,saturday': 'on weekends',
}

export const getSendTextByDays = (days: IDays[]) => {
  const shorten = shortenLabelForDays[days.toString()]
  if (shorten) return shorten
  return `on ${days.map((day) => longShortDayMap.get(day)).join(', ')}`
}
