import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type ConversationListStore } from 'widgets/ConversationList'
import { ConversationListContext } from 'widgets/ConversationList/context/conversationListContext'
import { ConversationListContent } from 'widgets/ConversationList/ui/ConversationListContent'

type IConversationListProps = {
  store: ConversationListStore
}

export const ConversationList: FC<IConversationListProps> = observer(({ store }) => {
  return (
    <ConversationListContext.Provider value={store}>
      <ConversationListContent />
    </ConversationListContext.Provider>
  )
})
