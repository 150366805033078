import { useMemo, useState } from 'react'
import { links } from 'shared/constants/links'
import { Button } from 'shared/ui'

import { uiStore } from 'shared/store/uiStore'
import { CreateWorkflowStore } from 'widgets/CreateWorkflowModal'
import styles from './styles.module.scss'

export const WorkflowHelpGuideButton = () => (
  <Button
    className={styles.helpButton}
    text='Help guide'
    size='medium'
    contained='secondary'
    iconProps={{ icon: 'info' }}
    tag='a'
    href={links.workflowHelpGuide}
    target='_blank'
  />
)

export const WorkflowCreateButton = () => {
  const [isCreating, setCreating] = useState(false)
  const store = useMemo(() => new CreateWorkflowStore(), [])

  const handleCreate = () => {
    setCreating(true)

    store.create().then((id) => {
      if (id) uiStore.changeRoute({ path: `/workflows/view/${id}`, type: 'vue' })

      setCreating(false)
    })
  }

  return (
    <Button
      text='New workflow'
      size='medium'
      iconProps={{ icon: 'add' }}
      loading={isCreating}
      onClick={handleCreate}
    />
  )
}
