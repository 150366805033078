import achievementsImg from 'shared/assets/images/auth/achievements.png'
import { Icon, Typography } from 'shared/ui'
import { getCDNAssetURL } from 'shared/lib'
import { items } from './Items'

import styles from './styles.module.scss'

export const RightContent = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.starsWrap}>
          {[...Array(5)].map((el, idx) => (
            <Icon key={idx} icon={'starFilled1'} color={'var(--yellow-60)'} fontSize={24} />
          ))}
        </div>
        <p className={styles.title}>
          Join 3,500+ modern businesses who use Salesmsg to instantly capture, connect, convert, and
          care for their customers.
        </p>
      </div>
      <div className={styles.content}>
        {items.map((item) => (
          <div key={item.text} className={styles.item}>
            <Icon icon={'check2'} color={'var(--green-70)'} fontSize={20} />
            <Typography variant={'heading-sm'} ariaLabel={item.text}>
              {item.text}
            </Typography>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <img
          className={styles.achievementsImg}
          alt='slideLogo'
          src={getCDNAssetURL(achievementsImg)}
        />
      </div>
    </div>
  )
}
