import { AxiosError } from 'axios'

export const getSendMessageAlert = (error: unknown) => {
  if (error instanceof AxiosError) {
    const mediaUrl = error.response?.data?.media_url

    if (Array.isArray(mediaUrl) && mediaUrl[0]) {
      return mediaUrl[0]
    }
  }

  return 'Something went wrong sending your message. Please try again'
}
