import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button } from 'shared/ui'
import { adminOrganizationCustomPlanNewPrice } from '../../store/adminOrganizationCustomPlanNewPrice'

export const NewPriceModalAction = observer(() => {
  const { saveCustomPrice, newPriceLoading, publishCustomPrice, resetNewPriceModal, form } =
    adminOrganizationCustomPlanNewPrice

  return (
    <>
      <Button
        typeBtn={'contained'}
        contained={'secondary'}
        text={'Cancel'}
        onClick={resetNewPriceModal}
      />

      <Button
        typeBtn={'contained'}
        contained={'secondary'}
        text={'Save'}
        onClick={saveCustomPrice}
        disabled={form.disableFormActions}
        loading={newPriceLoading}
      />

      <Button
        typeBtn={'contained'}
        text={'Publish and add'}
        onClick={publishCustomPrice}
        disabled={form.disableFormActions}
        loading={newPriceLoading}
      />
    </>
  )
})
