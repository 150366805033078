export class Counter {
  private _current!: number

  constructor(private _min = Number.MIN_SAFE_INTEGER, private _max = Number.MAX_SAFE_INTEGER) {
    this._current = this._min
  }

  get current() {
    return this._current
  }

  get next() {
    return (this._current = this._current >= this._max ? this._min : this._current + 1)
  }
}
