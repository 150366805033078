import React from 'react'

export const tooltipText = {
  disabledCall: (phoneType = 'Toll-Free' || 'Local') => [
    'Calling is not available on the trial ',
    <br key={'br'} />,
    `number. Switch to the ${phoneType} inbox `,
    <br key={'br'} />,
    'to try-out Salesmsg Calling.',
  ],
}
