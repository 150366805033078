// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyJV9_84_4e_r0uNNwyl{display:flex;align-items:center}._9nauwCd7R5LqxsvKu3Fg .bCQPSamJ94AtzCQK8bD0{margin-left:4px}._9nauwCd7R5LqxsvKu3Fg .XjY1b80azUBaD2ivNB1C{margin-left:6px}.bCQPSamJ94AtzCQK8bD0{color:var(--content-primary-tertiary)}.XjY1b80azUBaD2ivNB1C{color:var(--content-primary-primary);max-width:150px;margin-left:8px}.gUN01XDW3yMfbqlLHyKj{color:var(--content-neutral-primary);margin-left:8px}.NH2Z0VaKdyyYZ5e0ZX0Y{background:var(--background-brand-subtle-primary);color:var(--content-primary-tertiary)}.C0cuilaAx_v3jMrHlASA{color:var(--content-primary-primary)}", "",{"version":3,"sources":["webpack://./src/entities/Inbox/ui/InboxDropdownCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGE,6CACE,eAAA,CAGF,6CACE,eAAA,CAKN,sBACE,qCAAA,CAGF,sBACE,oCAAA,CACA,eAAA,CACA,eAAA,CAGF,sBACE,oCAAA,CACA,eAAA,CAKF,sBACE,iDAAA,CACA,qCAAA,CAEA,sBACE,oCAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n\n  &--checkbox {\n    .icon {\n      margin-left: 4px;\n    }\n\n    .title {\n      margin-left: 6px;\n    }\n  }\n}\n\n.icon {\n  color: var(--content-primary-tertiary);\n}\n\n.title {\n  color: var(--content-primary-primary);\n  max-width: 150px;\n  margin-left: 8px;\n}\n\n.count {\n  color: var(--content-neutral-primary);\n  margin-left: 8px;\n}\n\n.checkbox {}\n\n.highlighter {\n  background: var(--background-brand-subtle-primary);\n  color: var(--content-primary-tertiary);\n\n  &--label {\n    color: var(--content-primary-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "MyJV9_84_4e_r0uNNwyl",
	"wrap--checkbox": "_9nauwCd7R5LqxsvKu3Fg",
	"icon": "bCQPSamJ94AtzCQK8bD0",
	"title": "XjY1b80azUBaD2ivNB1C",
	"count": "gUN01XDW3yMfbqlLHyKj",
	"highlighter": "NH2Z0VaKdyyYZ5e0ZX0Y",
	"highlighter--label": "C0cuilaAx_v3jMrHlASA"
};
export default ___CSS_LOADER_EXPORT___;
