// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N7qrv9oJEgeU2ineJGql{margin:9px 21px 0}.N7qrv9oJEgeU2ineJGql .A1YNKY7PtaO0g9baW7JP{margin-right:10px}.N7qrv9oJEgeU2ineJGql .HpZFVpWCQrfKp9VberrR input{height:16px;font-size:16px}.N7qrv9oJEgeU2ineJGql .HpZFVpWCQrfKp9VberrR input ::placeholder{color:var(--content-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/organizations/pages/Organizations/ui/OrganizationsSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,4CACE,iBAAA,CAIA,kDACE,WAAA,CACA,cAAA,CAEA,gEACE,oCAAA","sourcesContent":[".wrap {\n  margin: 9px 21px 0;\n\n  .icon {\n    margin-right: 10px;\n  }\n\n  .search {\n    & input {\n      height: 16px;\n      font-size: 16px;\n\n      ::placeholder {\n        color: var(--content-neutral-primary);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "N7qrv9oJEgeU2ineJGql",
	"icon": "A1YNKY7PtaO0g9baW7JP",
	"search": "HpZFVpWCQrfKp9VberrR"
};
export default ___CSS_LOADER_EXPORT___;
