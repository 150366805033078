import { ChangeEvent, DragEvent } from 'react'
import styles from './styles.module.scss'

export interface ILoadedData {
  contacts: Record<string, string | number>[]
  headers: string[]
}

interface IExportButtonProps {
  dragActive: boolean
  itemLimit?: number
  onHandleChange: (file: File) => void
  setDragActive: (value: boolean) => void
  onFileLoaded: (data: ILoadedData) => void
  setLoading: (value: boolean) => void
  wrongTypeHandler?: (fileName: string) => void
  limitErrorHandler?: (fileName: string) => void
}

const allowedFileTypes = ['text/csv']

export const ExportButton = ({
  dragActive,
  itemLimit,
  onHandleChange,
  setDragActive,
  onFileLoaded,
  setLoading,
  wrongTypeHandler = () => {},
  limitErrorHandler = () => {},
}: IExportButtonProps) => {
  const parseLine = (line: string) => {
    const values: string[] = []
    let current = ''
    let insideQuotes = false

    for (const char of line) {
      if (char === ',' && !insideQuotes) {
        values.push(current.trim())
        current = ''
      } else if (char === '"') {
        insideQuotes = !insideQuotes
      } else {
        current += char
      }
    }

    values.push(current.trim())

    return values
  }

  const parseCSV = (csvText: string) => {
    const lines: string[] = csvText.split('\n')
    const headers = parseLine(lines[0])

    const contacts = lines.slice(1).map((line) => {
      const values = parseLine(line)
      return headers.reduce((rowData, header, index) => {
        rowData[header] = values[index]
        return rowData
      }, {} as Record<string, string>)
    })

    return { contacts, headers }
  }

  const preventHandle = (e: DragEvent | ChangeEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleOnChange = (files: FileList) => {
    const file = Array.from(files).find((file) => allowedFileTypes.includes(file.type))
    const wrongFile = Array.from(files).find((file) => !allowedFileTypes.includes(file.type))

    if (files.length && !file && wrongFile) {
      setDragActive(false)
      wrongTypeHandler(wrongFile.name)
      return
    }

    if (file) {
      setLoading(true)
      const reader = new FileReader()

      reader.onload = (e) => {
        const csvText = e.target?.result as string

        const data = parseCSV(csvText)

        if (itemLimit && data.contacts.length >= itemLimit) {
          setLoading(false)
          limitErrorHandler(file.name)
          setDragActive(false)

          return
        }

        onFileLoaded(data)
        onHandleChange(file)
      }

      reader.readAsText(file)
    }
    setDragActive(false)
  }

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    preventHandle(event)
    event.target.files && handleOnChange(event.target.files)
  }

  const handleOnChangeDiv = (event: DragEvent<HTMLDivElement>) => {
    preventHandle(event)
    handleOnChange(event.dataTransfer.files)
  }

  return (
    <form className={styles.formFileUpload}>
      <label htmlFor='fileInput' className={styles.uploadLabel}>
        Upload file
      </label>
      <input
        id='fileInput'
        onChange={handleOnChangeInput}
        type='file'
        className={styles.uploadFile}
        accept='.csv'
      />
      {dragActive && (
        <div
          className={styles.dragFileElement}
          onDragLeave={() => {
            setDragActive(false)
          }}
          onDragOver={preventHandle}
          onDrop={handleOnChangeDiv}
        />
      )}
    </form>
  )
}
