import { createContext, useContext } from 'react'
import { type ContactsMenuStore } from 'widgets/ContactsMenu'

export const ContactsMenuContext = createContext<ContactsMenuStore | null>(null)

export function useContactsMenuContext() {
  const context = useContext(ContactsMenuContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with ContactsMenuStoreProvider')
  }
  return context
}
