import { useEffect } from 'react'
import type { IResponseEventMessagePopper } from 'entities/Message'
import { websocket, WebsocketEvent } from 'entities/WebSocket/store'
import { dataLogStore } from 'entities/DataLog/store/dataLogStore'

const MessagePopper = (data: IResponseEventMessagePopper) => {
  const { user_id, inbox_id } = data

  // TODO: SWR-14336: Will be uncommit and updated with SWR-14336
  // dataLogStore.setItem({
  //   logLevel: 'info',
  //   message: 'EventMessagePopper',
  //   details: {
  //     inboxId: inbox_id,
  //     userId: user_id,
  //   },
  // })
}

export const useEventMessagePopperLog = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.MessagePopper, MessagePopper)
    }
  }, [websocket.isConnected])
}
