import { makeAutoObservable } from 'mobx'
import type { ContactsDetailsStore } from 'widgets/ContactsDetails'

export class ContactsTagsFormStore {
  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)
  }

  get selectedContact() {
    return this._contactsDetailsStore.selectedContact
  }
}
