import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { cloneDeep } from 'lodash'
import { type IIconsVector } from 'shared/ui'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { type IResponseConversation } from 'entities/Conversation/api/types'
import { Contact } from 'entities/Contacts/model/Contact'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { type IResponseContact } from 'entities/Contacts/api/types'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import { UsersApi } from 'entities/Users'
import { inboxesStore } from 'entities/Inbox'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { IntegrationKey } from 'entities/Integrations'
import { ContactNoteStore } from 'widgets/ContactsDetails/ui/ContactsNotesForm'
import { ContactsTableStore } from 'widgets/ContactsTable'
import { ContactInfoFormStore } from 'widgets/ContactsDetails/ui/ContactsInfoForm'
import {
  EnumIntegrationTabs,
  type IContactsDetailsStoreConfig,
  type IContactsDetailsVariant,
  type IViewAllData,
  type IViewAllDataProps,
  type IViewTypes,
} from 'widgets/ContactsDetails/store/types'
import { type ConversationNewSearchStore } from 'widgets/ConversationNew'
import { ConversationsHistoryMessagesStore } from 'widgets/ConversationsHistoryMessages'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'
import { contactChoosePhoneModalStore } from 'widgets/ContactChoosePhoneModal'
import { ContactsConversationOpenStore } from 'widgets/ContactsDetails/ui/ContactsConversationOpen'
import { ContactsProfileCardStore } from 'widgets/ContactsDetails/ui/ContactsProfileCard'
import { contactsDetailsGlobalStore } from './contactsDetailsGlobalStore'

const emptyViewAllData = {
  deals: [],
  companies: [],
  opportunities: [],
}

export class ContactsDetailsStore {
  private _id = nanoid()
  private _contactId: number | null = null
  private _conversationId: number | null = null
  private _inboxId: number | null = null

  private _contact: Contact | null = null
  private _conversation: Conversation | null = null
  private _variant: IContactsDetailsVariant = 'conversation'
  private _currentContact: Contact | null = null
  private _view: IViewTypes = 'main'
  private _integrationTab: EnumIntegrationTabs = EnumIntegrationTabs.Contact
  private _viewIntegrationKey: IntegrationKey | null = null
  private _viewAllData: IViewAllData = emptyViewAllData
  private _customTitleIcon: IIconsVector | null = null
  private _reassigning = false

  private _contactsTableStore: ContactsTableStore | null = null
  private _conversationNewSearchStore: ConversationNewSearchStore | null = null
  private _conversationMessagesStore: ConversationMessagesStore | null = null
  private _contactCreateModalStore: ContactCreateModalStore | null = null

  private _contactsDetailsGlobalStore = contactsDetailsGlobalStore
  private _contactInfoFormStore: ContactInfoFormStore
  private _conversationsHistoryMessagesStore = new ConversationsHistoryMessagesStore(this)
  private _contactsConversationOpenStore = new ContactsConversationOpenStore(this)
  private _contactNoteStore: ContactNoteStore
  private _contactsProfileCardStore = new ContactsProfileCardStore(this)

  private _disposeAlert: IReactionDisposer | null = null
  private _disposeContactsDetailsToggle: IReactionDisposer | null = null
  private _disposeContactUpdated: IReactionDisposer | null = null
  private _disposeSetContactFromCreateModal: IReactionDisposer | null = null
  private _disposeConversation: IReactionDisposer | null = null
  private _disposeContacts: IReactionDisposer | null = null
  private _sub_source: IContactsDetailsStoreConfig['sub_source'] | null = null

  emitOpenConversationSignal: IContactsDetailsStoreConfig['emitOpenConversationSignal'] | null =
    null
  onDeleteContactsDetails: IContactsDetailsStoreConfig['onDeleteContactsDetails'] | null = null
  onUpdateContact: ((contact: Contact) => void) | null = null
  onCloseContactDetails: (() => void) | null = null
  onStopCreateNewContact: (() => void) | null = null
  addNewContactLocallyAfterCreate:
    | ((contact: Contact, params?: { isNew?: boolean }) => void)
    | null = null
  draggableItems: string[] = []
  defaultDraggableItems: string[] = [
    'conversation_info',
    'contacts_group',
    'contacts_info',
    'contacts_integrations',
    'contacts_tags',
    'contacts_notes',
  ]

  private _headerProps: IContactsDetailsStoreConfig['headerProps'] | null = null
  private _notesProps: IContactsDetailsStoreConfig['notesProps'] | null = null
  private _profileCardProps: IContactsDetailsStoreConfig['profileCardProps'] | null = null

  constructor(config: IContactsDetailsStoreConfig) {
    makeAutoObservable(this)

    this._contactId = config.contactId || null
    this._conversationId = config.conversationId || null
    this._inboxId = config.inboxId || null

    this._reassigning = false
    this._contact = config.contact || null
    this._conversation = config.conversation || null
    this._variant = config.variant || 'conversation'
    this._view = 'main'
    this._integrationTab = EnumIntegrationTabs.Contact
    this._viewIntegrationKey = null

    this._headerProps = config.headerProps
    this._notesProps = config.notesProps
    this._profileCardProps = config.profileCardProps

    this.onUpdateContact = config.onUpdateContact || null
    this.onCloseContactDetails = config.onCloseContactDetails || null
    this.onStopCreateNewContact = config.onStopCreateNewContact || null
    this.emitOpenConversationSignal = config.emitOpenConversationSignal || null
    this.addNewContactLocallyAfterCreate = config.addNewContactLocallyAfterCreate || null
    this.onDeleteContactsDetails = config.onDeleteContactsDetails || null

    this._sub_source = config.sub_source || null
    this._conversationNewSearchStore = config.conversationNewSearchStore || null
    this._contactsTableStore = config.contactsTableStore || null
    this._contactInfoFormStore = new ContactInfoFormStore(
      config.contact?.id || config.conversation?.contact_id
    )
    this._conversationMessagesStore = config.conversationMessagesStore || null

    if (this._conversationNewSearchStore) {
      this._conversationNewSearchStore.setChangeInbox((inbox) => {
        this._contactsProfileCardStore.setInboxId(inbox.id)
      })
    }

    this._contactsProfileCardStore.setConfig({
      contact: this._contact,
      contacts: this.contacts,
      variant: config.variant,
      contactCreateModalStore: config.contactCreateModalStore,
      contactsTableStore: config.contactsTableStore,
      openView: this.openView,
      enableChat: config.profileCardProps?.enableChat,
      enableCall: config.profileCardProps?.enableCall,
      onClickMessage: config.profileCardProps?.onClickMessage,
      onClickCall: config.profileCardProps?.onClickCall,
    })
    this._contactNoteStore = new ContactNoteStore(this)

    this._contactCreateModalStore = config.contactCreateModalStore || null

    if (this._sub_source) {
      this._contactsConversationOpenStore.setSubSource(this._sub_source)
    }

    if (this._conversation) {
      this.setConversation(this._conversation)
    }

    this.reactionConversation()
    this.reactionContacts()
    this.reactionContactsDetailsToggle()
    this.reactionContactUpdated()
    this.reactionSetContactFromCreateModal()
    this.reactionAlert()
  }

  get notesProps() {
    return this._notesProps
  }

  get headerProps() {
    return this._headerProps
  }

  get profileCardProps() {
    return this._profileCardProps
  }

  get contactsProfileCardStore() {
    return this._contactsProfileCardStore
  }

  get isVariantConversations() {
    return this._variant === 'conversation'
  }

  get isVariantContacts() {
    return this._variant === 'contacts'
  }

  get isVariantCreateContactModal() {
    return this._variant === 'create_contact_modal'
  }

  get isVariantOpenConversations() {
    return this._variant === 'open_conversation'
  }

  get isConversationNew() {
    if (!this._conversation) return true

    return this._conversation?.isNew
  }

  get contacts(): Contact[] {
    if (this._conversation) {
      return this._conversation.contactsIds.reduce<Contact[]>((state, current) => {
        const contact = contactsStore.getItem(current)

        return contact ? [...state, contact] : state
      }, [])
    }

    if (this._contact?.isNew) return [this._contact]

    const contact = contactsStore.getItem(this._contact?.id)

    return contact ? [contact] : []
  }

  get selectedContact(): Contact | null | undefined {
    if (this.contacts.length > 1) return this._currentContact || this.contacts[0]

    return this.contacts[0]
  }

  get dealsCount() {
    return this._viewAllData.deals.length
  }

  get isViewAll() {
    if (this._view === 'main') return false

    return (
      this._view === 'companies' ||
      this._view === 'deals' ||
      this._view === 'notes' ||
      this._view === 'opportunities'
    )
  }

  get viewAllCount() {
    if (this._view === 'companies') return this._viewAllData.companies.length
    if (this._view === 'deals') return this._viewAllData.deals.length
    if (this._view === 'opportunities') return this._viewAllData.opportunities.length

    return 0
  }

  get companiesCount() {
    return this._viewAllData.companies.length
  }

  get conversation() {
    return this._conversation
  }

  get integrationTab() {
    return this._integrationTab
  }

  get viewIntegrationKey() {
    return this._viewIntegrationKey
  }

  get currentContact() {
    return this._currentContact
  }

  get viewAllData() {
    return this._viewAllData
  }

  get customTitleIcon() {
    return this._customTitleIcon
  }

  get id() {
    return this._id
  }

  get contactNoteStore() {
    return this._contactNoteStore
  }

  get view() {
    return this._view
  }

  get reassigning() {
    return this._reassigning
  }

  load = () => {
    if (this._conversationId) {
      this.loadConversation()
    } else {
      this.loadContact()
    }
  }

  loadContact = async () => {
    if (!this._contactId) return

    try {
      let contact = contactsStore.getItem(this._contactId)

      if (!this._conversationId) {
        const inboxIds: number[] = []

        if (this._inboxId) {
          inboxIds.push(this._inboxId)
        }

        const conversationResponse = await ConversationsApi.getConversationsSearch({
          tags: [],
          teams: inboxIds,
          members: [],
          contacts: [this._contactId],
          page: 1,
        }).then((data) => data.data.results[0])

        if (conversationResponse) {
          this._conversationId = conversationResponse.id

          const conversation = conversationStore.addItem({
            ...conversationResponse,
            is_locked: false,
          })
          contact = contactsStore.getItem(conversationResponse.contact_id)

          this._contactsConversationOpenStore.setConversationId(conversation.id)
          this.setConversation(conversation)
          conversationStore.setCurrentItemId(conversation.id)
        } else {
          this._contactsConversationOpenStore.conversationMessagesStore.setLoading(false)

          if (inboxesStore.sharedInboxes.length === 1) {
            this._contactsConversationOpenStore.setInbox(inboxesStore.sharedInboxes[0])
          }
        }
      }

      if (!contact) {
        const response = await ContactsApi.getContactById(this._contactId).then((data) => data.data)
        contact = contactsStore.addItem(response)
      }

      if (contact) {
        this.setContact(contact)
      }
    } catch (e) {
      console.log(e)
    }
  }

  loadConversation = async () => {
    if (!this._conversationId) return

    try {
      let conversation = conversationStore.getItem(this._conversationId)

      if (!conversation) {
        const response = await ConversationsApi.getById(this._conversationId).then(
          (data) => data.data
        )
        conversation = conversationStore.addItem(response)
      }

      if (conversation) {
        this._contactId = conversation.contact_id

        const contact = contactsStore.getItem(this._contactId)

        if (contact) {
          this.setContact(contact)
        }

        this._contactsConversationOpenStore.setConversationId(conversation.id)
        this.setConversation(conversation)
        conversationStore.setCurrentItemId(conversation.id)
      }
    } catch (e) {
      console.log(e)
    }
  }

  setConversation = (conversation: Conversation | null) => {
    this._conversation = conversation
  }

  setConversationId = (conversationId?: number | string) => {
    const id =
      conversationId === 'null' || conversationId === undefined ? null : Number(conversationId)

    if (typeof id === 'number') {
      this._conversationId = id
    }
  }

  handleActiveContact = (item: Contact | null) => {
    this._currentContact = item
    if (item) {
      this._contactInfoFormStore.syncFields(item.id)
    }
  }

  handleIntegrationTab = (tab: EnumIntegrationTabs) => {
    this._integrationTab = tab
  }

  openView = (
    viewType: IViewTypes,
    viewAllData?: IViewAllDataProps,
    customTitleIcon?: IIconsVector,
    viewIntegrationKey?: IntegrationKey
  ) => {
    this._view = viewType
    this._viewAllData = { ...this._viewAllData, ...viewAllData } || emptyViewAllData
    this._customTitleIcon = customTitleIcon || null
    this._viewIntegrationKey = viewIntegrationKey || null
  }

  closeView = () => {
    this._view = 'main'
    this._viewAllData = emptyViewAllData
    this._customTitleIcon = null
    this._viewIntegrationKey = null
  }

  handleChangeContact = (item: IResponseContact) => {
    const contact = new Contact(item)

    this._contact = contact
    this._contactInfoFormStore.syncFields(contact.id)
  }

  setContact = (item: Contact) => {
    const contact = item

    this._contact = contact
    this._contactsProfileCardStore.setContact(contact)
    this._contactInfoFormStore.updateContactInfo(contact.id)
  }

  handleUpdateContact = (item: IResponseContact) => {
    const contact = new Contact(item)
    this._contact = contact
    this.onUpdateContact?.(contact)

    if (contact?.id !== -1) {
      this._contactCreateModalStore?.handleContactUpdate(contact)
    }
  }

  handleUpdateConversation = (item: IResponseConversation) => {
    const conversation = new Conversation(item)

    this._conversation = conversation
    this._contactInfoFormStore.syncFields(conversation.contact_id)
  }

  toggleReassigning = (status: boolean) => {
    this._reassigning = status
  }

  reactionAlert = () => {
    this._disposeAlert?.()
    this._disposeAlert = reaction(
      () => this.alert,
      (alert) => {
        if (alert) {
          if (this.isVariantCreateContactModal) {
            this._contactCreateModalStore?.setAlert(alert)
          }

          setTimeout(() => {
            runInAction(() => {
              this.resetAlert()
            })
          }, 5000)
        }
      }
    )
  }

  reactionContacts() {
    this._disposeContacts?.()
    this._disposeContacts = reaction(
      () => this.contacts,
      (items) => {
        if (!items) return

        this._contactsProfileCardStore.setContacts(items)
      }
    )
  }

  reactionConversation() {
    this._disposeConversation?.()
    this._disposeConversation = reaction(
      () => this._conversation,
      (item) => {
        if (item) {
          this._contactInfoFormStore.syncFields(item.contact_id)
          this._contactsProfileCardStore.setConversation(item)
          this._contactsProfileCardStore.setInboxId(item.inbox_id)

          const inbox = inboxesStore.getItem(item.inbox_id) as Inbox | undefined

          this._contactsConversationOpenStore.setConversationId(item.id)

          if (inbox) {
            this._contactsConversationOpenStore.setInbox(inbox)
          }
        } else {
          this._contactsProfileCardStore.setConversation(null)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionContactsDetailsToggle() {
    this._disposeContactsDetailsToggle?.()
    this._disposeContactsDetailsToggle = reaction(
      () => this._contactsDetailsGlobalStore?.isCollapse,
      (isCollapse) => {
        if (isCollapse) {
          this.onStopCreateNewContact?.()
          this.onCloseContactDetails?.()
        }
      }
    )
  }

  reactionContactUpdated() {
    this._disposeContactUpdated?.()
    this._disposeContactUpdated = reaction(
      () => this._contact,
      (contact) => {
        if (!contact) return

        this._contactsProfileCardStore.setContact(contact)
      }
    )
  }

  reactionSetContactFromCreateModal() {
    this._disposeSetContactFromCreateModal?.()
    this._disposeSetContactFromCreateModal = reaction(
      () => this._contactCreateModalStore?.contact,
      (contact) => {
        if (contact && this._contactCreateModalStore?.isCanUpdateExternalContactInfo) {
          this.setContact(contact)
        }
      }
    )
  }

  reorderDraggableItems = async (source: number, target: number) => {
    try {
      const items = cloneDeep(this.draggableItems)
      const [item] = items.splice(source, 1)

      items.splice(target, 0, item)

      const { data } = await UsersApi.updateUsersUiSettings({
        source_type: 'contacts-details-draggable-items',
        items: items,
      })

      if (data?.data?.items) {
        runInAction(() => {
          this.draggableItems = data?.data?.items as string[]
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  initDraggableItems = async () => {
    try {
      const { data } = await UsersApi.getUsersUiSettings('contacts-details-draggable-items')

      if (data.data?.items.length) {
        runInAction(() => {
          this.draggableItems = data?.data?.items as string[]
        })
      } else {
        runInAction(() => {
          this.draggableItems = this.defaultDraggableItems
        })
      }
    } catch (e) {
      console.error(e)

      runInAction(() => {
        this.draggableItems = this.defaultDraggableItems
      })
    }
  }
  get titleHeader() {
    if (this._view === 'notes') return 'Notes'
    if (this._view === 'deals') return 'Deals'
    if (this._view === 'opportunities') return 'Opportunities'
    if (this._view === 'companies') return 'Companies'
    if (this._view === 'conversations-history') return 'Conversations history'

    return this.isCurrentContact ? 'Contact' : 'Details'
  }

  get isViewTypeNotes() {
    return this._view === 'notes'
  }

  get isViewMain() {
    return this._view === 'main'
  }

  get isViewTypeConversationsHistory() {
    return this._view === 'conversations-history'
  }

  get isCurrentContact() {
    return Boolean(this._currentContact)
  }

  get isConversationGroup() {
    return this.contacts.length > 1
  }

  get isGroupSelectContact() {
    if (!this.isConversationGroup) return true

    return Boolean(this._currentContact)
  }

  get contactInfoFormStore() {
    return this._contactInfoFormStore
  }

  get contactsTableStore() {
    return this._contactsTableStore
  }

  get conversationsHistoryMessagesStore() {
    return this._conversationsHistoryMessagesStore
  }

  get conversationId() {
    return this._conversationId
  }

  get conversationMessagesStore() {
    return this._conversationMessagesStore
  }

  get alert() {
    return (
      this._contactInfoFormStore?.alert ||
      this._contactsTableStore?.alert ||
      this._conversationNewSearchStore?.alert ||
      contactChoosePhoneModalStore?.alert
    )
  }

  reset = () => {
    this.resetAlert()
    this.resetReactions()
    this._contactsConversationOpenStore.reset()
  }

  resetAlert = () => {
    this._contactInfoFormStore?.resetAlert()
    this._contactsTableStore?.resetAlert()
    this._conversationNewSearchStore?.resetAlert()
    contactChoosePhoneModalStore?.resetAlert()
  }

  resetReactions = () => {
    this._disposeConversation?.()
    this._disposeAlert?.()
    this._disposeContactsDetailsToggle?.()
    this._disposeContactUpdated?.()
    this._disposeSetContactFromCreateModal?.()
    this._disposeContacts?.()
  }

  get contactCreateModalStore() {
    return this._contactCreateModalStore
  }

  get contactsConversationOpenStore() {
    return this._contactsConversationOpenStore
  }

  get isFirstEditMode() {
    return (
      this._contactCreateModalStore?.isFirstEditMode ||
      this._conversationNewSearchStore?.isFirstEditMode ||
      this._contactsTableStore?.isFirstEditMode
    )
  }

  get contact() {
    return this._contact
  }

  get contactsDetailsGlobalStore() {
    return this._contactsDetailsGlobalStore
  }

  setIsFirstEditMode = (condition: boolean) => {
    this._contactCreateModalStore?.setIsFirstEditMode(condition)
    this._conversationNewSearchStore?.setIsFirstEditMode(condition)
    this._contactsTableStore?.setIsFirstEditMode(condition)
  }
}
