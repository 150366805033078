import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { SearchDropdownContext, SearchDropdownStore } from 'widgets/SearchDropdown'
import { SearchDropdownContent } from './SearchDropdownContent'

type ISearchDropdownProps = {
  store: SearchDropdownStore
}

export const SearchDropdown: FC<ISearchDropdownProps> = observer(({ store }) => {
  return useMemo(
    () => (
      <SearchDropdownContext.Provider value={store}>
        <SearchDropdownContent />
      </SearchDropdownContext.Provider>
    ),
    []
  )
})
