import { ReactNode, UIEvent, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { type IDropdownItem } from 'shared/ui/Dropdown'
import { DropdownItem } from 'shared/ui/Dropdown/ui/DropdownItem'
import { ITextFieldProps } from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'
import { SpinnerLoader } from 'shared/ui'
import styles from './styles.module.scss'
import { DropdownTextInput } from '../DropdownTextInput'

export type IDropdownListProps = {
  items: IDropdownItem[]
  parent?: IDropdownItem
  width?: number
  maxHeight?: number
  withSearch?: boolean
  onSearch?: (value: string) => void
  search?: string
  withTextInput?: boolean
  topListComponent?: ReactNode
  bottomActions?: ReactNode
  onChange?: (item: IDropdownItem, child?: IDropdownItem) => void
  onPressEnter?: (text: string) => void
  textFieldProps?: ITextFieldProps
  childContainerWidth?: number
  onLoadMore?: () => void
  loading?: boolean
  searchWrapClassName?: string
  isTopReverse?: boolean
}
export const DropdownList = ({
  items,
  onChange,
  width,
  withSearch,
  withTextInput,
  bottomActions,
  parent,
  maxHeight,
  textFieldProps,
  topListComponent,
  childContainerWidth,
  onSearch,
  search,
  onPressEnter = () => {},
  onLoadMore,
  loading,
  searchWrapClassName,
  isTopReverse,
}: IDropdownListProps) => {
  const refList = useRef<HTMLDivElement>(null)
  const [searchLocal, setSearchLocal] = useState('')
  const filterItems = searchLocal
    ? items.filter((item) => item.label.toLowerCase().includes(searchLocal.trim().toLowerCase()))
    : items

  const isSelect = useMemo(() => {
    return !!items.find((item) => item.activeValue)
  }, [items])

  useEffect(() => {
    if (refList.current) {
      refList.current.querySelector('[data-selected="true"]')?.scrollIntoView()
    }
  }, [])
  const [text, setText] = useState('')

  const onInputPressEnter = () => {
    onPressEnter(text)
  }

  const handleSearch = (value: string) => {
    if (onSearch) {
      onSearch(value)
    } else {
      setSearchLocal(value)
    }
  }

  const handleScroll = (e: UIEvent) => {
    if (onLoadMore) {
      const target = e.target as HTMLDivElement
      const scrollHeight = target.scrollHeight
      const scrollTop = target.scrollTop
      const offsetHeight = target.offsetHeight

      if (scrollHeight <= scrollTop + offsetHeight + 100) {
        onLoadMore()
      }
    }
  }

  return (
    <div className={styles.wrap} aria-label={getAriaLabel('DropdownList')}>
      <div
        className={classNames(styles.content, {
          'top-dropdown-column-reverse-content': isTopReverse,
        })}
      >
        {topListComponent}
        {withSearch && (
          <DropdownTextInput
            dropdownTextInputClassName={searchWrapClassName}
            textFieldProps={{
              value: search || searchLocal,
              onChange: handleSearch,
              ...textFieldProps,
              InputProps: {
                placeholder: 'Select',
                ...textFieldProps?.InputProps,
              },
            }}
          />
        )}
        {withTextInput && (
          <DropdownTextInput
            textFieldProps={{
              value: text,
              onChange: setText,
              ...textFieldProps,
            }}
            onPressEnter={onInputPressEnter}
          />
        )}

        {loading ? (
          <SpinnerLoader style={{ margin: 12 }} size={24} />
        ) : (
          <>
            {Boolean(filterItems.length) && (
              <div className={styles.wrapItems}>
                <div
                  className={styles.items}
                  style={{ maxWidth: width ? 'unset' : undefined, maxHeight }}
                  ref={refList}
                  aria-label={getAriaLabel('DropdownList', 'items')}
                  onScroll={handleScroll}
                >
                  {filterItems.map((item) => {
                    return (
                      <div
                        key={item.id as string}
                        data-selected={item.activeValue}
                        aria-label={getAriaLabel(
                          item.ariaLabel ? `${item.ariaLabel}List` : 'DropdownList',
                          'item'
                        )}
                      >
                        <DropdownItem
                          item={item}
                          onChange={onChange}
                          parent={parent}
                          isSelect={isSelect}
                          childContainerWidth={childContainerWidth}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            {bottomActions && Boolean(filterItems.length) && (
              <div
                className={styles.bottomActions}
                aria-label={getAriaLabel('DropdownList', 'Actions')}
              >
                {bottomActions}
              </div>
            )}
            {!filterItems.length && (
              <div
                className={styles.noResult}
                aria-label={getAriaLabel('DropdownList', 'noResult')}
              >
                No results found
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
