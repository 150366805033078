import { IDropdownItem } from 'shared/ui'
import { IInboxCombine } from 'entities/Inbox'
import { ConversationMenuStore } from 'widgets/ConversationMenu'

export const typeNewFolder = 'new_folder'

type IActionNewFolder = {
  inbox: IInboxCombine
  groupInbox?: IInboxCombine
  item: IDropdownItem
}

export const menuNewFolder = (): IDropdownItem => {
  return {
    id: typeNewFolder,
    iconL: 'add',
    label: 'New folder',
  }
}

export const actionNewFolder = (
  { inbox, groupInbox, item }: IActionNewFolder,
  store: ConversationMenuStore
) => {
  if (item.id === typeNewFolder) {
    store.handleAddTeamsGroupsCreateLocal({ inbox, groupInbox })
  }
}
