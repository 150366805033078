import { Typography } from 'shared/ui'
import step2Img from 'shared/assets/images/auth/step2.png'
import step2Avatar from 'shared/assets/images/auth/step2_avatar.png'
import { CDN_FRONT_URL } from 'shared/config'

import styles from './styles.module.scss'

export const RightContent = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <img className={styles.image} alt='step2Image' src={`${CDN_FRONT_URL}${step2Img}`} />
      </div>
      <div className={styles.footer}>
        <p className={styles.review}>
          {`"Salesmsg is extremely easy to set up and integrate with my CRM. Two-way texting with
          leads and customers has never been easier!"`}
        </p>
        <div className={styles.authorWrap}>
          <img className={styles.image} alt='step2Avatar' src={`${CDN_FRONT_URL}${step2Avatar}`} />
          <div>
            <Typography className={styles.name} ariaLabel={'name'} variant={'body-lg-medium'}>
              Amanda S.
            </Typography>
            <Typography className={styles.role} ariaLabel={'role'} variant={'body-lg-medium'}>
              — Marketing Manager, Acceptu
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}
