import { FC, ReactNode, useEffect } from 'react'
import classNames from 'classnames'
import { Icon, Typography } from 'shared/ui'
import { MessageField, type MessageFieldStore } from 'widgets/MessageField'
import styles from './styles.module.scss'

interface SMSMessageFieldProps {
  messageFieldStore: MessageFieldStore
  errorMessage: ReactNode
  setError: (error: ReactNode) => void
  setDraggingCondition?: (condition: boolean) => void
}

export const SMSMessageField: FC<SMSMessageFieldProps> = ({
  messageFieldStore,
  errorMessage,
  setError,
  setDraggingCondition,
}) => {
  useEffect(() => {
    return () => setError('')
  }, [setError])

  return (
    <div>
      <div
        className={classNames(styles.wrap, {
          [styles.activeState]: messageFieldStore.activeMessageField,
          [styles.errorState]: errorMessage,
        })}
      >
        <div className={styles.alerts} ref={messageFieldStore.setAlertElement} />
        <MessageField messageFieldStore={messageFieldStore} onDragHandler={setDraggingCondition} />
      </div>
      {errorMessage && (
        <div className={styles.error}>
          <Icon icon='alertTriangleSmall' fontSize={16} />
          <Typography variant='text-input-helper' ariaLabel='error'>
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  )
}
