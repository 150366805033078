import type { FilterItem } from 'widgets/FilterEditor/model'

import { WidgetDate, isDateWidget } from './WidgetDate'
import { WidgetDateText, isDateTextWidget } from './WidgetDateText'
import { WidgetEmpty, isEmptyWidget } from './WidgetEmpty'
import { WidgetMultiText, isMultiTextWidget } from './WidgetMultiText'
import { WidgetHubspot, isHubspotWidget } from './WidgetHubspot'
import { WidgetMultiSelect, isMultiSelectWidget } from './WidgetMultiSelect'
import { WidgetAsyncTags, isAsyncTagsWidget } from './WidgetAsyncTags'
import { WidgetBroadcastStatus, isBroadcastStatusWidget } from './WidgetBroadcastStatus'
import { WidgetMultiUsers, isMultiUsersWidget } from './WidgetMultiUsers'
import { WidgetMultiInboxes, isMultiInboxesWidget } from './WidgetMultiInboxes'
import { WidgetMultiRecurringType, isMultiRecurringTypeWidget } from './WidgetMultiRecurringType'
import { WidgetTriggerStatus, isTriggerStatusWidget } from './WidgetTriggerStatus'
import { WidgetIntegration, isIntegrationWidget } from './WidgetIntegration'

export interface IWidgetProps {
  filterItem: FilterItem
  onRemove: () => void
}

export const getWidgetType = (filterItem: FilterItem) => {
  switch (true) {
    case isEmptyWidget(filterItem):
      return 'empty'
    case isMultiRecurringTypeWidget(filterItem):
      return 'multi_recurring_type'
    case isMultiInboxesWidget(filterItem):
      return 'multi_inboxes'
    case isMultiUsersWidget(filterItem):
      return 'multi_users'
    case isBroadcastStatusWidget(filterItem):
      return 'broadcast_status'
    case isTriggerStatusWidget(filterItem):
      return 'trigger_status'
    case isIntegrationWidget(filterItem):
      return 'integration'
    case isDateTextWidget(filterItem):
      return 'date_text'
    case isMultiSelectWidget(filterItem):
      return 'multi_select'
    case isMultiTextWidget(filterItem):
      return 'multi_text'
    case isAsyncTagsWidget(filterItem):
      return 'tags'
    case isDateWidget(filterItem):
      return 'date'
    case isHubspotWidget(filterItem):
      return 'hubspot'
    default:
      return 'empty'
  }
}

export const getWidgetComponent = (filterItem: FilterItem) => {
  switch (getWidgetType(filterItem)) {
    case 'multi_recurring_type':
      return WidgetMultiRecurringType
    case 'multi_inboxes':
      return WidgetMultiInboxes
    case 'multi_users':
      return WidgetMultiUsers
    case 'broadcast_status':
      return WidgetBroadcastStatus
    case 'trigger_status':
      return WidgetTriggerStatus
    case 'integration':
      return WidgetIntegration
    case 'empty':
      return WidgetEmpty
    case 'date_text':
      return WidgetDateText
    case 'multi_select':
      return WidgetMultiSelect
    case 'multi_text':
      return WidgetMultiText
    case 'tags':
      return WidgetAsyncTags
    case 'date':
      return WidgetDate
    case 'hubspot':
      return WidgetHubspot
    default:
      return WidgetEmpty
  }
}
