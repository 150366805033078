import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { NameValueRecord } from 'shared/ui'
import { ISendFromInfo } from 'features/SendFrom'

import styles from './styles.module.scss'
import { useDetailsContext } from '../detailsContext'

export const TabEnroll = observer(() => {
  const { data, futureSendFrom, futureFutureContactsCount } = useDetailsContext()

  const [sendFrom, setSendFrom] = useState<ISendFromInfo | null>(null)
  const [sendFromReady, setSendFromReady] = useState(false)

  const [contactsCount, setContactsCount] = useState<number | null>(null)
  const [contactsCountReady, setContactsCountReady] = useState(false)

  const message = data.first_message?.message

  useEffect(() => {
    futureSendFrom.then((sendFrom) => {
      setSendFrom(sendFrom)
      setSendFromReady(true)
    })

    futureFutureContactsCount.then((count) => {
      setContactsCount(count)
      setContactsCountReady(true)
    })
  }, [])

  return (
    <div className={styles.sendCards}>
      <section className={styles.sendCard}>
        <header className={styles.sendCardHead}>First message</header>
        <div className={styles.sendCardMessage}>
          {message ?? (
            <span className={styles.sendCardPlaceholder}>
              Please provide the initial message for the textbot to send
            </span>
          )}
        </div>
      </section>
      <section className={styles.sendCard}>
        <header className={styles.sendCardHead}>Send</header>
        <div className={styles.sendCardRow}>
          <div className={styles.sendCardName}>Send from</div>
          <div className={styles.sendCardValue}>
            {sendFromReady && !!sendFrom ? (
              <NameValueRecord icon='inboxOpen' name={sendFrom.name} value={sendFrom.label} />
            ) : (
              <NameValueRecord icon='inboxOpen' value='Select inbox' />
            )}
          </div>
        </div>
        <div className={styles.sendCardRow}>
          <div className={styles.sendCardName}>Contacts</div>
          <div className={styles.sendCardValue}>
            {contactsCountReady && contactsCount != null ? (
              <NameValueRecord icon='contacts' name={`${contactsCount}`} />
            ) : (
              <NameValueRecord icon='contacts' value='Enroll contacts' />
            )}
          </div>
        </div>
      </section>
    </div>
  )
})
