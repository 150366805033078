// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dZEzSsXTxa6qw5u0vXwl{display:flex;flex-direction:column;margin-top:-4px;padding:0 11px}.dZEzSsXTxa6qw5u0vXwl._7zV3NAWfcQTLKfqtyOpA{padding:12px}.VG3JBUPDx4H9y1VZl2L3{padding:0 12px 24px 11px}.hwmcN4fzkXF8U6aI519g .VG3JBUPDx4H9y1VZl2L3{padding-top:18px}.VG3JBUPDx4H9y1VZl2L3>div{padding:0}.VG3JBUPDx4H9y1VZl2L3>div>div{padding-bottom:5px}.wBmPGGYTTkVyW03teLfW{margin-top:13px;display:flex;padding:0 8px}.wBmPGGYTTkVyW03teLfW button{position:relative;z-index:var(--zIndex-BASE_1)}.ZMmUWbD6PHKCWn9BAp_d{display:flex;flex-direction:column;gap:4px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsNotesForm/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,cAAA,CAEA,4CACE,YAAA,CAIJ,sBACE,wBAAA,CAEA,4CACE,gBAAA,CAGF,0BACE,SAAA,CAEA,8BACE,kBAAA,CAKN,sBACE,eAAA,CACA,YAAA,CACA,aAAA,CAEA,6BACE,iBAAA,CACA,4BAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  margin-top: -4px;\n  padding: 0 11px;\n\n  &.fullView {\n    padding: 12px;\n  }\n}\n\n.messageField {\n  padding: 0 12px 24px 11px;\n\n  .open & {\n    padding-top: 18px;\n  }\n\n  & > div {\n    padding: 0;\n\n    & > div {\n      padding-bottom: 5px;\n    }\n  }\n}\n\n.showMore {\n  margin-top: 13px;\n  display: flex;\n  padding: 0 8px;\n\n  button {\n    position: relative;\n    z-index: var(--zIndex-BASE_1);\n  }\n}\n\n.notes {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "dZEzSsXTxa6qw5u0vXwl",
	"fullView": "_7zV3NAWfcQTLKfqtyOpA",
	"messageField": "VG3JBUPDx4H9y1VZl2L3",
	"open": "hwmcN4fzkXF8U6aI519g",
	"showMore": "wBmPGGYTTkVyW03teLfW",
	"notes": "ZMmUWbD6PHKCWn9BAp_d"
};
export default ___CSS_LOADER_EXPORT___;
