import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'
import utcFormat from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import minMax from 'dayjs/plugin/minMax'

// Plugins
dayjs.extend(duration)

const config = {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'ss', r: 59, d: 'second' },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y', r: 1 },
    { l: 'yy', d: 'year' },
  ],
}

dayjs.extend(relativeTime, config)
dayjs.extend(advancedFormat)
dayjs.extend(updateLocale)
dayjs.extend(utcFormat)
dayjs.extend(weekday)
dayjs.extend(LocalizedFormat)
dayjs.extend(timezone)
dayjs.extend(minMax)

function getDateWithTimezone(
  date?: string | number | Date | dayjs.Dayjs | null | undefined,
  timezone?: string
) {
  try {
    return dayjs.utc(date).tz(timezone)
  } catch (error) {
    console.warn(`Invalid timezone specified: ${timezone}.`)

    return dayjs.utc(date)
  }
}

export default function localDate(
  date?: string | number | Date | dayjs.Dayjs | null | undefined,
  timezone?: string
) {
  return getDateWithTimezone(dayjs.utc(date), timezone)
}

export function utcDate(date?: string | number | Date | dayjs.Dayjs | null | undefined) {
  return dayjs.utc(date)
}

export function dateValueOf(date?: string | number | Date | dayjs.Dayjs | null | undefined) {
  return dayjs.utc(date).local().valueOf()
}

const localeConversation: ILocale = {
  name: 'en-conversations',
  relativeTime: {
    future: 'in %s',
    past: '%ss',
    s: '%ds',
    ss: '%ds',
    m: '1min',
    mm: '%dmin',
    h: '1h',
    hh: '%dh',
    d: '1d',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dd: (number: number) => {
      const day = number
      const week = Math.floor(number / 7)

      return week ? `${week}w` : `${day}d`
    },
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
}

const localeEnStrict: ILocale = {
  name: 'en-strict',
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%d seconds',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ss: '%d seconds',
    m: '%d minute',
    mm: '%d minutes',
    h: '%d hour',
    hh: '%d hours',
    d: '%d day',
    dd: '%d days',
    M: '%d month',
    MM: '%d months',
    y: '%d year',
    yy: '%d years',
  },
}

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%d seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

export function dateFromNowForConversations(
  date?: string | number | Date | dayjs.Dayjs | null | undefined
) {
  return dayjs.utc(date).locale(localeConversation).fromNow(true)
}

export function dateFromNow(date?: string | number | Date | dayjs.Dayjs | null | undefined) {
  return dayjs.utc(date).local().fromNow(true)
}

export function dateFromNowStrict(date?: string | number | Date | dayjs.Dayjs | null | undefined) {
  return dayjs.utc(date).locale(localeEnStrict).fromNow(true)
}

export function dateDiff(date?: string | number | Date | dayjs.Dayjs | null | undefined) {
  const dateNow = dayjs.utc().local()

  return dateNow.diff(date, 'seconds')
}

export function localTime(timezone?: string) {
  const dateNow = dayjs.utc().local()
  const dateWithTimezone = getDateWithTimezone(dayjs(dateNow), timezone)

  return dateWithTimezone.format(DayjsFormats.localTime)
}

export function offsetFromUTC(timezone?: string) {
  const dateNow = dayjs.utc().local()
  const dateWithTimezone = getDateWithTimezone(dayjs(dateNow), timezone)

  return dateWithTimezone.format(DayjsFormats.UTCOffset)
}

export function getMaxDate(dates: string[]): Dayjs | null {
  return dayjs.max(dates.map((date) => dayjs(date)))
}

export function getTimestamp(value: string | number | Date | dayjs.Dayjs): number {
  return dayjs.utc(value).unix()
}

export enum DayjsFormats {
  full = 'MMM D, YYYY h:mm A',
  full2 = 'dddd, MMM D, YYYY [@] h:mm A z',
  full3 = 'ddd, MMM Do, YYYY',
  full4 = 'ddd, D MMM, YYYY, h:mm A',
  full5 = 'D MMM, YYYY',
  full6 = 'MMM D, YYYY',
  full7 = 'MMM D h:mm A',
  fullDownload = 'MMM D, YYYY h-mm-s A',
  fullWithAt = 'MMM D [at] h:mm A',
  fullWithAtDash = 'D MMM, YYYY [-] h:mm A',
  fullWithAtDash2 = 'MMM D, YYYY [-] h:mm A',
  fullWithAtDash3 = 'MMM D, YYYY [-] h:mm a',
  date = 'MMM D',
  date2 = 'DD MMM YYYY',
  dateSort = 'YYYY-MM-DD',
  time = 'h:mm a',
  time2 = 'h:mm A',
  time3 = 'HH:mm:ss',
  localTime = 'LT',
  UTCOffset = 'Z',
  timezone = 'z',
  apiFormat = 'YYYY-MM-DDTHH:mm:ssZ[Z]',
  apiFormat2 = 'YYYY-MM-DD HH:mm:ss',
  slash = 'MM/DD/YYYY',
  slashWithAtDash = 'MM/DD/YYYY [-] h:mm a',
  timestampFilter = 'YYYY-MM-DD:HH:mm',
  timestampServerDate = 'YYYY-MM-DD',
  timestampServerTime = 'HH:mm',
  downloadFile = 'YYYY_MM_DD',
}

export const periodsMap = {
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
}

export type IPeriod = keyof typeof periodsMap

export const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const

export type IDays = (typeof days)[number]

// monday -> Mon
export const longShortDayMap: Map<IDays, string> = new Map(
  days.map((day, index) => [day, dayjs().startOf('week').add(index, 'day').format('ddd')])
)

// monday -> Mo
export const longShort2DayMap = new Map(
  days.map((day, index) => [day, dayjs().startOf('week').add(index, 'day').format('dd')])
)

export function getNumberWithOrdinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}
