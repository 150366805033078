import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { AxiosError, AxiosResponse } from 'axios'
import {
  InputPhone,
  makeTextFieldAutoFocus,
  makeTextFieldElement,
  makeTextFieldIcon,
  TextField,
} from 'shared/ui/TextField'
import { Button, Icon, SpinnerLoader } from 'shared/ui'
import { validPhoneNumber } from 'shared/lib'
import { Label } from 'shared/ui/Label'
import { IParamsTestSMS, IResponseTestSMS } from 'widgets/TestSMS/types'
import { TestSMSBtn } from 'widgets/TestSMS/ui/TestSMSBtn/TestSMSBtn'
import { SendTestModalStore } from 'widgets/TestSMS/ui/TestSmsWithMergeField'
import styles from './styles.module.scss'

export type ITestSMSProps = {
  disabled?: boolean
  params: Pick<IParamsTestSMS, 'message' | 'number_id' | 'media_url'>
  request: (params: IParamsTestSMS) => Promise<AxiosResponse<IResponseTestSMS>>
  onToggleShowTest?: (value: boolean) => void
  isTopError?: boolean
  sendTestModalStore: SendTestModalStore
}

export const TestSMSDefault = ({
  disabled,
  params,
  request,
  onToggleShowTest,
  isTopError,
  sendTestModalStore,
}: ITestSMSProps) => {
  const [isShowTest, setShowTest] = useState(false)
  const [number, setNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState<ReactNode>('')

  useEffect(() => {
    onToggleShowTest?.(isShowTest)
  }, [isShowTest])

  useEffect(() => {
    return () => {
      setNumber('')
      setCompleted(false)
      setError('')
      onToggleShowTest?.(false)
    }
  }, [isShowTest])

  const btnContent = useMemo(() => {
    if (completed) {
      return <Icon icon={'check1'} />
    }
    if (loading) {
      return <SpinnerLoader size={15} />
    }
    return 'Send test'
  }, [loading, completed])

  const onShowTest = () => {
    setShowTest(true)
  }

  const onCancel = () => {
    setShowTest(false)
    setCompleted(false)
  }

  const onTest = async () => {
    if (loading || completed) return
    if (!validPhoneNumber(number)) {
      setError('Please enter a valid phone number to test the message')
    } else {
      try {
        setLoading(true)
        const { data } = await request({
          ...params,
          number,
        })
        if (data?.status === 'success') {
          setCompleted(true)
          setTimeout(onCancel, 1000)
        } else {
          if (data.message === 'Insufficient balance to send this message') {
            setError(
              <>
                Not enough credits to test the message.
                <Button
                  text={'Buy credits'}
                  typeBtn={'link'}
                  size={'small'}
                  contained={'primary'}
                  onClick={() => {
                    sendTestModalStore.buyCreditsModalStore.openModal()
                  }}
                />
              </>
            )
          } else {
            setError(data.message)
          }
        }
      } catch (e) {
        if (e instanceof AxiosError) {
          setError(e.response?.data.number?.[0])
        }
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
  }

  const onFocus = () => {
    setError('')
  }

  const errorContent = error && (
    <Label
      label={{
        className: classNames(styles.error, { [styles.isTopError]: isTopError }),
        textClassName: styles.text,
        text: error,
        leftIcon: 'alertTriangleSmall',
      }}
      boxAlignItems={'center'}
      tertiary={false}
    />
  )

  return (
    <div>
      {isShowTest ? (
        <>
          {isTopError && errorContent}
          <TextField
            onFocus={onFocus}
            disabled={loading || completed}
            size={'small'}
            variant={'stroke'}
            Input={InputPhone}
            value={number}
            error={error}
            onChange={setNumber}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
            leftActions={[
              makeTextFieldIcon({
                icon: 'phone2',
                margin: 0,
              }),
            ]}
            rightActions={[
              makeTextFieldElement({
                element: completed ? null : (
                  <Button
                    typeBtn={'text'}
                    text={'Cancel'}
                    onClick={onCancel}
                    size={'small'}
                    className={styles.inputAction}
                    disabled={loading}
                  />
                ),
              }),
              makeTextFieldElement({
                element: (
                  <Button
                    text={btnContent}
                    onClick={onTest}
                    contained={'primary'}
                    size={'small'}
                    className={classNames(styles.inputAction, styles.send)}
                    disabled={disabled}
                  />
                ),
              }),
            ]}
          />
          {!isTopError && errorContent}
        </>
      ) : (
        <TestSMSBtn onClick={onShowTest} disabled={disabled} />
      )}
    </div>
  )
}
