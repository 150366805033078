import { FC } from 'react'
import { type ContactsConversationOpenStore } from 'widgets/ContactsDetails/ui/ContactsConversationOpen'
import { ContactsConversationOpenContext } from '../context/contactsConversationOpenContext'
import { ContactsConversationOpenContent } from './ContactsConversationOpenContent'

type IContactsConversationOpenProps = {
  store: ContactsConversationOpenStore
}

export const ContactsConversationOpen: FC<IContactsConversationOpenProps> = ({ store }) => {
  return (
    <ContactsConversationOpenContext.Provider value={store}>
      <ContactsConversationOpenContent />
    </ContactsConversationOpenContext.Provider>
  )
}
