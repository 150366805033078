import { ActionItem, Divider } from 'shared/ui'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { MediaLibraryStore } from 'widgets/MediaLibrary'

export const getMediaLibraryCardActions = (
  attachment: Attachment,
  mediaLibraryStore: MediaLibraryStore
): ActionItem[] => {
  const {
    onOpenPresentationModal,
    onDeleteAttachments,
    onRenameAttachment,
    onAddToConversation,
    onDownloadAttachment,
    onCopyAttachment,
  } = mediaLibraryStore
  const isAudio = attachment?.type === 'audio'
  const isVideo = attachment?.type === 'video'
  const isVCard = attachment?.type === 'vcard' || attachment?.extension === 'vcf'
  const isVCardPresentationAvailable = isVCard && !!attachment.vCard
  const isFile = attachment?.type === 'attachment' || isVCard
  const isAttachmentPresentationAvailable = !isFile || isVCardPresentationAvailable

  const previewLabel = () => {
    if (isVideo) return 'Play video'
    if (isAudio) return 'Play audio'
    return 'Preview'
  }

  const previewIcon = () => {
    if (isVideo || isAudio) return 'play'
    return 'expand2'
  }

  return [
    {
      text: previewLabel(),
      icon: previewIcon(),
      buttonProps: {
        disabled: !isAttachmentPresentationAvailable,
        paddingInline: '8px 12px',
      },
      onAction: () => {
        onOpenPresentationModal(attachment)
      },
    },
    {
      text: 'Add to conversation',
      icon: 'conversation',
      buttonProps: {
        paddingInline: '8px 12px',
      },
      onAction: () => {
        onAddToConversation([attachment])
      },
    },
    {
      dropdownItemComponent: () => <Divider variant={'dropdown'} />,
    },
    {
      text: 'Copy URL',
      icon: 'copy',
      buttonProps: {
        paddingInline: '8px 12px',
      },
      onAction: () => {
        onCopyAttachment(attachment)
      },
    },
    {
      text: 'Rename',
      icon: 'edit',
      buttonProps: {
        paddingInline: '8px 12px',
      },
      onAction: () => {
        onRenameAttachment(attachment)
      },
    },
    {
      text: 'Download',
      icon: 'download',
      buttonProps: {
        paddingInline: '8px 12px',
      },
      onAction: () => {
        void onDownloadAttachment(attachment)
      },
    },
    {
      text: 'Delete',
      icon: 'delete',
      buttonProps: {
        paddingInline: '8px 12px',
      },
      onAction: () => {
        void onDeleteAttachments([attachment.id])
      },
    },
  ]
}
