import { makeAutoObservable, reaction, runInAction, IReactionDisposer } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import {
  CannedMessagesApi,
  IParamsGetCannedMessages,
  IResponseCannedMessage,
} from 'entities/CannedMessages'

type Props = {
  filter: 'favorite' | 'unfavorite'
  setSavedReplay: (savedReplayResponse: IResponseCannedMessage) => void
}

export class ListSavedRepliesStore {
  private _disposeReactionLoadData: IReactionDisposer | null = null

  constructor({ filter, setSavedReplay }: Props) {
    this.filter = filter
    this.setSavedReplay = setSavedReplay
    this.reactionLoadData()

    makeAutoObservable(this)
  }

  filter: 'favorite' | 'unfavorite' = 'unfavorite'
  setSavedReplay: ((savedReplayResponse: IResponseCannedMessage) => void) | null

  page = 1
  last_page = 0
  length = 20
  loading = false
  parentParams: IParamsGetCannedMessages = {}

  cancelTokenSource: CancelTokenSource | null = null

  get paramsGetItems(): IParamsGetCannedMessages {
    return {
      ...this.parentParams,
      page: this.page,
      length: this.length,
      filter: this.filter,
    }
  }

  get hasMore() {
    return this.page <= this.last_page
  }

  reset = () => {
    this._disposeReactionLoadData?.()
    this.clear()
  }

  clear = () => {
    this.loading = false
    this.last_page = 0
    this.page = 1
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  loadData = async (params?: IParamsGetCannedMessages) => {
    if (params) this.parentParams = params

    if (this.page > 1 && !this.hasMore) {
      return
    }
    try {
      this.loading = true
      this.initCancelTokenSource()

      const { data } = await CannedMessagesApi.getCannedMessages(this.paramsGetItems, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })

      runInAction(() => {
        this.last_page = data.last_page
        this.loading = false
      })

      if (this.setSavedReplay && data) data.data.forEach(this.setSavedReplay)
    } catch (e) {
      if (e instanceof CanceledError) return
      console.error(e)
      this.loading = false
    }
  }

  loadMore = () => {
    if (this.loading) return

    this.page = this.page + 1
    this.loading = true
  }

  reactionLoadData = () => {
    this._disposeReactionLoadData?.()
    this._disposeReactionLoadData = reaction(
      () => this.page,
      () => this.loadData(),
      {
        delay: 500,
      }
    )
  }
}
