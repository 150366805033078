import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { upperFirst } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Icon, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import styles from './styles.module.scss'

type ISkipCountProps = {
  skippedCountLastRoutesForLabel?: number
  storyData?: string
  getLabel?: (path: string) => string | undefined
  customPath?: string
}

export const Breadcrumbs = observer(
  ({ skippedCountLastRoutesForLabel = 0, getLabel, storyData, customPath }: ISkipCountProps) => {
    const { prevPathName, customPathLabel } = uiStore
    const { pathname } = useLocation()
    let path: string
    if (customPath) {
      path = customPath
    } else if (prevPathName) {
      path = prevPathName
    } else {
      path = pathname.split('/').slice(0, -1).join('/')
    }
    const label = useMemo(() => {
      if (customPathLabel[path]) {
        return customPathLabel[path]
      }
      if (getLabel && getLabel(path)) {
        return getLabel(path)
      }
      return path.split('/').at(-1 + skippedCountLastRoutesForLabel * -1)
    }, [path, skippedCountLastRoutesForLabel])

    return (
      <div className={styles.wrap}>
        {storyData && (
          <Typography
            variant={'body-sm-regular'}
            pointer
            className={styles.link}
            ariaLabel={'story'}
          >
            {storyData}
          </Typography>
        )}
        <Link to={path} className={styles.link}>
          <Icon icon={'chevronLeft'} />
          <Typography variant={'body-sm-regular'} pointer ariaLabel={'Back'}>
            Back to {upperFirst(label?.replace(/_/g, ' '))}
          </Typography>
        </Link>
      </div>
    )
  }
)
