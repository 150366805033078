import { makeAutoObservable } from 'mobx'
import { Attachment } from 'entities/Attachment/model/Attachment'
import type { IResponseTrigger } from 'entities/Trigger/api/type'
import {
  SMSMessageFieldStore,
  type ISMSMessageFieldStoreProps,
} from 'widgets/MessageFormFields/SMSMessageField/store/SMSMessageFieldStore'

export class TriggerSMSStore {
  smsMessageFieldStore: SMSMessageFieldStore
  constructor(props: ISMSMessageFieldStoreProps) {
    this.smsMessageFieldStore = new SMSMessageFieldStore(props)
    makeAutoObservable(this)
  }

  init = (triggerResponse: IResponseTrigger) => {
    this.messageFieldStore.setMessageData({
      attachments:
        triggerResponse.media_url?.map((media) => new Attachment({ responseMediaShort: media })) ||
        [],
      message: triggerResponse.message || '',
      isFocus: false,
      isReset: false,
      isReplace: true,
    })
  }

  get messageDataParams() {
    return this.smsMessageFieldStore.messageDataParams
  }

  get messageFieldStore() {
    return this.smsMessageFieldStore.messageFieldStore
  }

  get isError() {
    return this.smsMessageFieldStore.isError
  }

  get segments_count() {
    return this.smsMessageFieldStore.segments_count
  }
}
