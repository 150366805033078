export function getCaretIndex(element: HTMLDivElement): {
  offset: number
  focusNode: Node | null | undefined
} {
  const isSupported = typeof window.getSelection !== 'undefined'
  if (isSupported) {
    const selection = window.getSelection()
    if (selection?.rangeCount !== 0) {
      const range = window.getSelection()?.getRangeAt(0)
      if (!range) {
        return {
          offset: 0,
          focusNode: null,
        }
      }
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      return {
        offset: selection?.focusOffset || 0,
        focusNode: selection?.focusNode,
      }
    }
  }
  return {
    offset: 0,
    focusNode: null,
  }
}
