import { IUser } from 'entities/Users'

export const parseNoteBody = (body: string, users: Map<number, IUser>) => {
  body = body.replaceAll(/\{tag:(\S+)\}/g, (value, data) => {
    const [type, id] = data.split(':')
    const user = users.get(Number(id))

    if (type === 'inbox') {
      return '<a class="mention" href="#">@inbox</a>'
    }

    return `<a class="mention" href="#">@${user?.full_name || ''}</a>`
  })

  return body
}
