import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import type { IAlert } from 'shared/ui/Alert/types'
import { Contact } from 'entities/Contacts/model/Contact'
import { contactTemplate } from 'entities/Contacts/templates/contactTemplate'
import {
  CreateContactErrors,
  TrialContactsLimitModal,
} from 'entities/Subscription/ui/TrialContactsLimitModal'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { contactsStore } from 'entities/Contacts'
import { openConversationByContact } from 'features/OpenConversation'
import { ContactsDetailsStore } from 'widgets/ContactsDetails'
import { type CallModalStore } from 'widgets/CallModal'
import { ContactCreateModalActions } from '../ui/ContactCreateModalActions'
import { ContactCreateModalContent } from '../ui/ContactCreateModalContent'
import { ContactCreateModalAlert } from '../ui/ContactCreateModalAlert'

type IContactCreateModalStoreConfig = {
  emitOpenConversationSignal: () => void
  onChangeContact?: (contact: Contact | null) => void
}

export class ContactCreateModalStore {
  private _idModal = 'contact_create_modal'
  private _contact: Contact | null = null
  private _alert: IAlert | null = null
  private _disposeAlert: IReactionDisposer | null = null
  private _firstEditMode = false
  private _loading = false
  private _config: IContactCreateModalStoreConfig | null = null
  private _isFirstTimeContactAdded = false
  private _contactsDetailsStore: ContactsDetailsStore | null = null

  constructor(private _callModalStore: CallModalStore) {
    makeAutoObservable(this)

    this.reactionAlert()
  }

  initContactsDetailsStore = (contact: Contact) => {
    const addNewContactLocallyAfterCreate = (
      contact: Contact,
      params: { isNew?: boolean } = {}
    ) => {
      if (contact.isDisabled) {
        new TrialContactsLimitModal().open(CreateContactErrors.trialLimit)
      } else {
        if (params?.isNew) {
          this.handleContactCreate(contact)
          const payload = {
            event_id: 'contact_created_conversations',
            action: `Contact Created '${contact.id}'`,
          }

          eventLogStore.logEvent('Contact_Created_Conversations', payload, {
            groupId: organizationStore.id,
          })
        } else {
          this.setContact(contact)
        }

        setTimeout(() => {
          this.setIsFirstTimeContactAdded()
        }, 100)
      }
    }

    this._contactsDetailsStore = new ContactsDetailsStore({
      contactId: contact.id,
      profileCardProps: {
        onClickMessage: (contact: Contact) => {
          this.setLoading(true)

          openConversationByContact(contact).then(() => {
            this.closeModal()
            this.config?.emitOpenConversationSignal()
          })
        },
        onActionCall: () => {
          if (this._callModalStore) {
            const value = this.contact?.formatted_number || this.contact?.national_number || ''
            this._callModalStore.openModal()
            this._callModalStore.handleChangeValue(value, this.contact)
            this.closeModal()

            return
          }
        },
        enableCall: true,
        enableChat: true,
      },
      addNewContactLocallyAfterCreate: addNewContactLocallyAfterCreate,
      variant: 'create_contact_modal',
      onChangeAlert: (item) => {
        if (item) {
          this.setAlert(item)
        }
      },
    })
  }

  openModal = () => {
    const contact = new Contact(contactTemplate)

    this.setContact(contact)
    this.initContactsDetailsStore(contact)

    modalStore.addModal({
      id: this._idModal,
      title: 'Create contact',
      showCloseButton: false,
      showCloseIcon: true,
      width: 444,
      ModalActions: ContactCreateModalActions,
      ModalContent: ContactCreateModalContent,
      ModalAlert: ContactCreateModalAlert,
      ModalContentProps: {
        contactCreateModalStore: this,
      },
      paddingContent: '0',
      onClose: this.closeModal,
    })
  }

  closeModal = () => {
    modalStore.removeModal(this._idModal)
    this.reset()
  }

  handleSubmit = async () => {
    this.closeModal()
  }

  handleContactCreate = (contact: Contact | null) => {
    this.setContact(contact)
    this.setAlert({
      type: 'success',
      desc: 'Contact created',
    })
    this.setIsFirstEditMode(true)
  }

  handleContactUpdate = (contact: Contact | null) => {
    this.setContact(contact)
    this.setAlert({
      type: 'infoBlue',
      desc: 'Contact updated',
    })
  }

  setContact = (contact: Contact | null) => {
    if (contact) {
      contactsStore.addItem(contact.origin)
      this._contact = contact
      this._config?.onChangeContact?.(contact)
      this._contactsDetailsStore?.setContact(contact)
    }
  }

  setIsFirstTimeContactAdded = () => {
    this._isFirstTimeContactAdded = true
  }

  setAlert = (item: IAlert) => {
    this._alert = item
  }

  resetAlert = () => {
    this._alert = null
  }

  setIsFirstEditMode = (condition: boolean) => {
    this._firstEditMode = condition
  }

  setLoading = (condition: boolean) => {
    this._loading = condition
  }

  setConfig = (config: IContactCreateModalStoreConfig) => {
    this._config = {
      ...this._config,
      ...config,
    }
  }

  reset = () => {
    this._disposeAlert?.()
    this.resetAlert()
    this.setLoading(false)
    this._firstEditMode = false
    this._isFirstTimeContactAdded = false
  }

  get config() {
    return this._config
  }

  get contactsDetailsStore() {
    return this._contactsDetailsStore
  }

  get isEmptyContact() {
    return this.contact?.id === -1
  }

  get disabledButton() {
    return this.isEmptyContact
  }
  get contact() {
    return this._contact
  }

  get alert() {
    return this._alert
  }

  get hasAlert() {
    return Boolean(this._alert)
  }

  get isFirstEditMode() {
    return this._firstEditMode
  }

  get isLoading() {
    return this._loading
  }

  get isCanUpdateExternalContactInfo() {
    return !this._isFirstTimeContactAdded
  }

  reactionAlert = () => {
    this._disposeAlert?.()
    this._disposeAlert = reaction(
      () => this._alert,
      (alert) => {
        if (alert) {
          setTimeout(() => {
            runInAction(() => {
              this._alert = null
            })
          }, 5000)
        }
      }
    )
  }
}
