import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Dropdown, EnumIconButtonShape, IconButton, IDropdownItem, Switch } from 'shared/ui'
import {
  IScheduledData,
  scheduleStore,
} from 'widgets/ConversationSchedule/store/conversationScheduleStore'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import styles from './styles.module.scss'

type IMessageSendSettingRightBtnDropdownProps = {
  contactTZ: string
  onSchedule: ({ scheduledData }: { scheduledData: IScheduledData }) => void
}

export const MessageSendSettingRightBtnDropdown = observer(
  ({ contactTZ, onSchedule }: IMessageSendSettingRightBtnDropdownProps) => {
    const conversationMessagesStore = useConversationMessagesContext()
    const messageFieldStore = useMessageFieldContext()
    const onChange = (item: IDropdownItem) => {
      if (item.id === 'schedule') {
        scheduleStore.setOpenModal({
          contactTZ,
          onSchedule,
        })
      }
    }

    const { disablesSend, loadingSend } = messageFieldStore
    const { isClickToUndo, onToggleUndoMessage } = conversationMessagesStore

    return (
      <Dropdown
        width={269}
        ariaLabel={'MessageSendSettingRightBtnDropdown'}
        triggerComponent={(status) => {
          return (
            <IconButton
              variant={disablesSend ? 'icon' : 'contained'}
              disabled={disablesSend}
              color={'primary'}
              size={'medium'}
              icon={status ? 'chevronUp' : 'chevronDown'}
              className={classNames(styles.rightBtn, {
                [styles.primary]: !disablesSend,
                [styles.loading]: loadingSend,
              })}
              shape={EnumIconButtonShape.None}
              transparentColor={messageFieldStore.loadingSend}
              ariaLabel='MessageSendSettingRightBtnDropdown'
            />
          )
        }}
        placement={'bottom-end'}
        margin={8}
        onChange={onChange}
        items={[
          {
            id: 'schedule',
            label: 'Schedule message',
            ariaLabel: 'ScheduleMessage',
            iconL: 'clock',
          },
          {
            id: 'undoMessages',
            ariaLabel: 'UndoMessage',
            label: 'Undo messages',
            labelRight: (
              <Switch
                ariaLabel='UndoMessageToggle'
                value={isClickToUndo}
                onChange={onToggleUndoMessage}
              />
            ),
            iconL: 'back',
            disabledClosedTooltip: true,
          },
        ]}
      />
    )
  }
)
