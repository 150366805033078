import classNames from 'classnames'
import { PropsWithChildren, useState } from 'react'
import { Icon, Typography } from 'shared/ui'

import styles from './styles.module.scss'

type IPaymentPlanCollapseProps = PropsWithChildren<{
  title: string
}>

export const PaymentPlanCollapse = ({ title, children }: IPaymentPlanCollapseProps) => {
  const [expanded, expand] = useState(true)

  const handleClick = () => expand(!expanded)

  return (
    <section
      className={classNames(styles.container, {
        [styles.expanded]: expanded,
      })}
    >
      <header className={styles.header} onClick={handleClick}>
        <span className={styles.title}>
          <Typography variant='body-lg-medium' ariaLabel={'title'}>
            {title}
          </Typography>
        </span>
        <Icon
          fontSize={16}
          color={'var(--content-primary-tertiary)'}
          icon={expanded ? 'chevronUp' : 'chevronDown'}
        />
      </header>
      {expanded && <div className={styles.content}>{children}</div>}
    </section>
  )
}
