import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { showToast } from 'shared/ui'
import { billingStore } from 'entities/Billing'
import { CreditPrice } from 'entities/Billing/model/CreditPrice'
import { IParamsUpdateBillingCredits } from 'entities/Billing/api/types'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { DropdownCardsStore } from 'widgets/DropdownCards'
import { AutoRechargeWidgetStore } from 'widgets/AutoRechargeModal'
import { BuyCreditsModalActions } from '../ui/BuyCreditsModalActions'
import { BuyCreditsModalContent } from '../ui/BuyCreditsModalContent'

export class BuyCreditsModalStore {
  private _zIndex = 5100
  private _idModal = 'buy_credits_modal'
  private _activeCreditPrice: CreditPrice | null = null
  private _dropdownCardsStore: DropdownCardsStore = new DropdownCardsStore()
  private _autoRechargeWidgetStore = new AutoRechargeWidgetStore({
    zIndex: this._zIndex,
  })

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this._activeCreditPrice = null
  }

  openModal = () => {
    modalStore.addModal({
      id: this._idModal,
      title: 'Buy credits',
      width: 480,
      ModalActions: BuyCreditsModalActions,
      ModalContent: BuyCreditsModalContent,
      ModalContentProps: {
        buyCreditsModalStore: this,
      },
      paddingContent: '0 22px 14px 24px',
      onClose: this.closeModal,
      zIndex: this._zIndex,
    })
  }

  closeModal = () => {
    this.reset()
    modalStore.removeModal(this._idModal)
  }

  handleActivePrice = (item: CreditPrice) => {
    this._activeCreditPrice = item
  }

  handleBuyCredits = async () => {
    if (this.disabled) return
    if (!this.activeSource) return
    if (!this._activeCreditPrice) return

    const params: IParamsUpdateBillingCredits = {
      payment_method: this.activeSource.id,
      quantity: this._activeCreditPrice.credits,
    }

    await billingStore.handleBuyCredits(params)

    showToast({
      title: `${this._activeCreditPrice.creditsLabel} credits purchased`,
      type: 'success',
    })

    this.closeModal()
  }
  get activeSource() {
    return this._dropdownCardsStore?.activeItem
  }

  get isOutOfMessage() {
    const trialCredits = organizationStore.trialCredits
    const accountCredits = organizationStore.accountCredits
    const isTrial = subscriptionStore.isTrial

    if (isTrial && trialCredits <= 0) {
      return true
    }

    if (!isTrial && accountCredits <= 0) {
      if (billingStore.autorecharge?.is_active) return false
      return true
    }

    return false
  }

  get disabled() {
    return (
      this.loadingModal ||
      billingStore.buyCreditsLoading ||
      !this.activeSource ||
      !this._activeCreditPrice
    )
  }

  get loadingModal() {
    return billingStore.sourcesLoading || billingStore.creditsPriceLoading
  }

  get textSave() {
    if (!this._activeCreditPrice) return 'Save'

    return `Buy ${this._activeCreditPrice.creditsLabel} credits for $${this._activeCreditPrice.priceLabel}`
  }

  get dropdownCardsStore() {
    return this._dropdownCardsStore
  }

  get autoRechargeWidgetStore() {
    return this._autoRechargeWidgetStore
  }

  get zIndex() {
    return this._zIndex
  }
}
