import { FC } from 'react'
import classnames from 'classnames'
import { Icon, IconButton } from 'shared/ui'
import { ZIndex } from 'shared/constants/zIndex'
import styles from './styles.module.scss'

type IHeadProps = {
  isIncoming: boolean
  wrapElement: HTMLDivElement | null
  name?: string
  phone?: string
  onClick?: () => void
}

export const Head: FC<IHeadProps> = ({ onClick, name, phone, isIncoming, wrapElement }) => {
  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <div className={styles.head}>
      <div className={classnames(styles.head__drag, 'handle')}></div>
      <div className={styles.head__box}>
        <div className={styles.head__icon}>
          <IconButton
            variant={'icon'}
            size={'origin_icon'}
            color={'transparent'}
            fontSize={16}
            ariaLabel={`CallHead_${isIncoming ? 'incomingCall' : 'outgoingCall'}`}
            iconProps={{
              icon: isIncoming ? 'arrowLeftDown' : 'arrowRightUp',
              color: 'var(--content-primary-tertiary)',
            }}
            tooltipProps={{
              label: isIncoming ? 'Incoming call' : 'Outgoing call',
              placement: 'bottom',
              zIndex: ZIndex.OVERLAY_TOP_1,
              PopperProps: {
                popperOptions: {
                  modifiers: [
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: wrapElement,
                      },
                    },
                  ],
                },
              },
            }}
          />
        </div>
        <div className={styles.head__label} onClick={handleClick}>
          {name}
        </div>
        <div className={styles.head__ellipse}>
          <Icon icon={'ellipse_6'} fontSize={2} />
        </div>
        <div className={styles.head__number} onClick={handleClick}>
          {phone}
        </div>
      </div>
      <div className={styles.head__box}>
        <div className={classnames(styles.head__dotsDrag)}>
          <Icon icon={'dotsDrag'} color={'var(--content-primary-tertiary)'} />
        </div>
      </div>
    </div>
  )
}
