import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { SpinnerLoader } from 'shared/ui'
import { ChatbotProvider, useChatbotContext } from 'pages/chatbot/context'

const AgentBookAppointmentProviderLayoutContent = observer(() => {
  const chatbotStore = useChatbotContext()
  const { agentBookAppointmentStepsStore, model, initResources, enrollment, loadChatbot, locked } =
    chatbotStore
  const { agentId } = useParams()
  const { state: payload } = useLocation()

  useEffect(() => {
    agentBookAppointmentStepsStore.init(Number(agentId))
  }, [agentId])

  useEffect(() => {
    initResources()
    enrollment.initFilters()

    if (!agentId) return
    loadChatbot(Number(agentId))
  }, [])

  useEffect(() => {
    if (payload) model.syncOrigin(payload)
  }, [payload])

  if (agentBookAppointmentStepsStore.loading || locked) {
    return <SpinnerLoader />
  }

  return (
    <>
      <Outlet />
    </>
  )
})

export const AgentBookAppointmentProviderLayout = () => (
  <ChatbotProvider>
    <AgentBookAppointmentProviderLayoutContent />
  </ChatbotProvider>
)
