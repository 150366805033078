import * as Sentry from '@sentry/react'
import { AxiosResponse, AxiosError } from 'axios'
import { setTokenLocalStorageViaConfig } from 'entities/Auth/lib/setToken'
import { rejectRefreshToken } from 'entities/Auth/lib/rejectRefreshToken'

export const interceptorsResponseSuccess = (config: AxiosResponse, isAssume: boolean) => {
  setTokenLocalStorageViaConfig(config, isAssume)

  return config
}

export const interceptorsResponseError = (error: AxiosError<{ auth_required: boolean }>) => {
  return rejectRefreshToken(error)
}

export const interceptorsResponseLogError = (error: AxiosError) => {
  Sentry.captureEvent({
    message: `Request error with status code: ${error.response?.status}`,
    level: 'error',
    tags: {
      ['request.transport']: 'axios',
      ['status.code']: error.response?.status,
    },
    fingerprint: [
      `${error.response?.config.baseURL}${error.response?.config.url}`,
      `${error.response?.config.method}`,
      `${error.response?.status}`,
    ],
    extra: {
      url: `${error.response?.config.baseURL}${error.response?.config.url}`,
      payload: error.response?.config.data,
      response: error.response?.data,
      status: error.response?.status,
      method: error.response?.config.method,
    },
  })

  return Promise.reject(error)
}
