import MuiSlider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import { SliderProps } from '@mui/material'
import { FC } from 'react'

type CustomProps = {
  noThumb?: boolean
  height?: number
  railBackgroundColor?: string
}

type SliderPropsType = SliderProps & CustomProps

export const Slider: FC<SliderPropsType> = styled(MuiSlider)(
  ({
    noThumb,
    height = 4,
    railBackgroundColor = 'var(--background-neutral-primary)',
  }: CustomProps) => ({
    height,
    display: 'flex',
    padding: '7px 0',
    '& .MuiSlider-rail': {
      backgroundColor: railBackgroundColor,
      opacity: 1,
      border: 'none',
    },
    '& .MuiSlider-track': {
      backgroundColor: 'var(--content-brand-primary)',
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      display: noThumb ? 'none' : undefined,
      outline: 'none!important',
      height: 12,
      width: 12,
      backgroundColor: 'white',
      border: '3px solid var(--content-brand-primary)',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
      '&:after': {
        height: 12,
        width: 12,
      },
    },
  })
)
