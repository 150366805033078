import { observer } from 'mobx-react-lite'
import { UiHeaderCenterContent } from 'shared/ui/Table'
import { useTableContext } from 'shared/ui/Table/context/context'

export const HeaderCenterContent = observer(() => {
  const { bulkAllMode, selectedIds, changeBulkAllMode, total, element } = useTableContext()

  return (
    <UiHeaderCenterContent
      bulkAllMode={bulkAllMode}
      selectedIds={selectedIds}
      changeBulkAllMode={changeBulkAllMode}
      total={total}
      element={element}
    />
  )
})
