import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { KnowledgeBaseListStore } from 'pages/chatbot/store/knowledgeBaseListStore'
import { KnowledgeBaseListContext } from 'pages/chatbot/context/knowledgeBaseListContext'
import { KnowledgeBaseList } from 'pages/chatbot/pages/knowledgeBaseList/ui/KnowledgeBaseList'

export const KnowledgeBaseListPage = observer(() => {
  const store = useMemo(() => new KnowledgeBaseListStore(), [])

  useEffect(() => {
    return store.clearReactions
  }, [])

  return (
    <KnowledgeBaseListContext.Provider value={store}>
      <KnowledgeBaseList />
    </KnowledgeBaseListContext.Provider>
  )
})
