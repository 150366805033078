import axios, { AxiosResponse } from 'axios'
import { GET_API_URL } from 'shared/config'
import {
  interceptorsRequestError,
  interceptorsRequestSuccess,
} from 'entities/Auth/lib/interceptorsRequest'
import {
  interceptorsResponseError,
  interceptorsResponseLogError,
  interceptorsResponseSuccess,
} from 'entities/Auth/lib/interceptorsResponse'
import { authStore } from '../store/auth'

export const baseApi = axios.create({
  baseURL: GET_API_URL(),
  headers: {
    'Content-type': 'application/json',
    'Salesmsg-App': 'react',
  },
})

const handleInterceptorsResponseSuccess = (config: AxiosResponse) => {
  return interceptorsResponseSuccess(config, authStore.isAssume)
}

baseApi.interceptors.request.use(interceptorsRequestSuccess, interceptorsRequestError)
baseApi.interceptors.response.use(null, interceptorsResponseLogError)
baseApi.interceptors.response.use(handleInterceptorsResponseSuccess, interceptorsResponseError)
