// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SsDsnPyR1s6cVBgask0C{height:22px;display:flex;gap:6px;align-items:center;border-radius:6px;background:var(--gray-a-15);padding-inline:8px}.aCrcX65FIjCMnXE2gf05{display:flex;gap:4px;align-items:center;text-transform:capitalize}", "",{"version":3,"sources":["webpack://./src/entities/Broadcast/ui/BroadcastRecurringLabel/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CACA,iBAAA,CACA,2BAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CACA,yBAAA","sourcesContent":[".wrap{\n  height: 22px;\n  display: flex;\n  gap: 6px;\n  align-items: center;\n  border-radius: 6px;\n  background: var(--gray-a-15);\n  padding-inline: 8px;\n}\n.title{\n  display: flex;\n  gap: 4px;\n  align-items: center;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "SsDsnPyR1s6cVBgask0C",
	"title": "aCrcX65FIjCMnXE2gf05"
};
export default ___CSS_LOADER_EXPORT___;
