import { observer } from 'mobx-react-lite'
import { Button, Icon, Switch, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { TrackConversionStore } from 'widgets/TrackConversions/store/trackConversionStore'
import styles from './styles.module.scss'

type ITrackConversionsProps = {
  store: TrackConversionStore
}

export const TrackConversions = observer(({ store }: ITrackConversionsProps) => {
  const {
    error,
    is_track_conversion,
    set_is_track_conversion,
    conversionItem,
    dropDownItems,
    setConversionItem,
    onChangeOpen,
    onLoadMore,
    loading,
    search,
    setSearch,
    onNewConversion,
  } = store

  const handleNewConversion = (onCloseMenu: () => void) => {
    onCloseMenu()
    onNewConversion()
  }

  return (
    <div className={styles.wrap}>
      <Icon icon={'infoCircle'} color='var(--light-blue-60)' />
      <div className={styles.center}>
        <div className={styles.info}>
          <Typography
            ariaLabel={'TrackConversions'}
            variant={'body-md-semibold'}
            color={'var(--light-blue-100)'}
          >
            Track conversions
          </Typography>
          <Typography
            ariaLabel={'TrackConversionsDesc'}
            variant={'body-md-regular'}
            color={'var(--light-blue-100)'}
          >
            Record and track the main milestones people accomplish, such as making a purchase
          </Typography>
        </div>
        {is_track_conversion && (
          <div className={styles.select}>
            <SelectField
              selectProps={{
                variant: 'stroke',
                textFieldPlaceholderProps: {
                  className: styles.textFieldPlaceholder,
                },
              }}
              error={error}
              item={conversionItem}
              items={dropDownItems}
              search={search}
              loading={loading}
              onSearch={setSearch}
              onLoadMore={onLoadMore}
              setValue={setConversionItem}
              dropdownProps={{
                placement: 'top',
                onChangeOpen,
                propsDropdownList: {
                  searchWrapClassName: styles.searchInput,
                  isTopReverse: true,
                },
                topListComponent: (onCloseMenu) => (
                  <div className={styles.newBtnWrap}>
                    <Button
                      text={'New conversion'}
                      icon={'add'}
                      fullWidth
                      typeBtn={'menuItem'}
                      onClick={() => handleNewConversion(onCloseMenu)}
                    />
                  </div>
                ),
              }}
            />
          </div>
        )}
      </div>
      <div>
        <Switch value={is_track_conversion} onChange={set_is_track_conversion} />
      </div>
    </div>
  )
})
