import { createContext, useContext } from 'react'
import { BroadcastDetailRecurringStoreStore } from 'widgets/BroadcastDetail'

export const BroadcastDetailRecurringContext =
  createContext<BroadcastDetailRecurringStoreStore | null>(null)

export function useBroadcastDetailRecurringContext() {
  const context = useContext(BroadcastDetailRecurringContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with BroadcastDetailRecurringContext'
    )
  }
  return context
}
