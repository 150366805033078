import { observer } from 'mobx-react-lite'

import { useDetailsContext } from '../detailsContext'
import { ObjectiveField } from './ObjectiveField'
import styles from './styles.module.scss'

export const TabObjective = observer(() => {
  const { fields } = useDetailsContext()

  return (
    <section className={styles.fieldCards}>
      {fields.map((field) => (
        <ObjectiveField key={field.key} field={field} />
      ))}
    </section>
  )
})
