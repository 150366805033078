import { observer } from 'mobx-react-lite'
import { SpinnerLoader, Table } from 'shared/ui'
import { useWorkflowListContext } from 'pages/workflows/context'

import { useWorkflowTable } from './useWorkflowTable'
import styles from './styles.module.scss'

export const WorkflowTable = observer(() => {
  const { page, limit, total, loading, changePaging } = useWorkflowListContext()
  const { rows, columns, columnsIds, setColumnIds } = useWorkflowTable()

  if (loading) return <SpinnerLoader />

  return (
    <div className={styles.table}>
      <Table
        columns={columns}
        rows={rows}
        initPage={page}
        limit={limit}
        visibleColumnsIds={columnsIds}
        setVisibleColumnsIds={setColumnIds}
        rowsCount={total}
        onChangePagination={changePaging}
        startPaginationSize={10}
      />
    </div>
  )
})
