// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LtH_M1BqhUSFlLvwUS3_{display:flex;align-items:center;padding-left:16px;padding-right:12px;height:48px;border-bottom:1px solid var(--border-neutral-default);color:var(--content-primary-primary)}.g2L6rXqKoTx3o8bPCw1D{width:100%;display:grid;gap:12px;grid-template-columns:32px 1fr max-content;align-items:center}.g2L6rXqKoTx3o8bPCw1D .cLBEeUDsQQab1Sn9dvGQ{display:flex;align-items:center;justify-content:center}.g2L6rXqKoTx3o8bPCw1D .tB9XVecuHoeZp3N3XXyD{display:flex}", "",{"version":3,"sources":["webpack://./src/widgets/RecentlySent/ui/RecentListHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,WAAA,CACA,qDAAA,CACA,oCAAA,CAEF,sBACE,UAAA,CACA,YAAA,CACA,QAAA,CACA,0CAAA,CACA,kBAAA,CACA,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEF,4CACE,YAAA","sourcesContent":[".wrap{\n  display: flex;\n  align-items: center;\n  padding-left: 16px;\n  padding-right: 12px;\n  height: 48px;\n  border-bottom: 1px solid var(--border-neutral-default);\n  color: var(--content-primary-primary);\n}\n.checkedContent{\n  width: 100%;\n  display: grid;\n  gap: 12px;\n  grid-template-columns: 32px 1fr max-content;\n  align-items: center;\n  .wrapCheckbox{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .right{\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "LtH_M1BqhUSFlLvwUS3_",
	"checkedContent": "g2L6rXqKoTx3o8bPCw1D",
	"wrapCheckbox": "cLBEeUDsQQab1Sn9dvGQ",
	"right": "tB9XVecuHoeZp3N3XXyD"
};
export default ___CSS_LOADER_EXPORT___;
