import { FC } from 'react'
import {
  BroadcastRinglessContext,
  BroadcastRinglessStore,
} from 'widgets/BroadcastView/ui/BroadcastRingless'
import { BroadcastRinglessContent } from 'widgets/BroadcastView/ui/BroadcastRingless/ui/BroadcastRinglessContent'

type IBroadcastRinglessProps = {
  store: BroadcastRinglessStore
}

export const BroadcastRingless: FC<IBroadcastRinglessProps> = ({ store }) => {
  return (
    <BroadcastRinglessContext.Provider value={store}>
      <BroadcastRinglessContent />
    </BroadcastRinglessContext.Provider>
  )
}
