// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e1dLp_p6526A1c_AyjQS{flex:1;display:grid;grid-template-columns:repeat(auto-fill, 120px);grid-gap:24px 16px;overflow-y:auto;margin-right:-20px;padding-top:24px}.oYPGAINBUB8InXpqaGOW{grid-column:1/-1;display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./src/widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryGrid/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CACA,YAAA,CACA,8CAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CACA,gBAAA,CAGF,sBACE,gBAAA,CACA,YAAA,CACA,sBAAA","sourcesContent":[".gridView {\n  flex: 1;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 120px);\n  grid-gap: 24px 16px;\n  overflow-y: auto;\n  margin-right: -20px;\n  padding-top: 24px;\n}\n\n.spinnerMoreContainer {\n  grid-column: 1 / -1;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridView": "e1dLp_p6526A1c_AyjQS",
	"spinnerMoreContainer": "oYPGAINBUB8InXpqaGOW"
};
export default ___CSS_LOADER_EXPORT___;
