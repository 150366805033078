// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iEDky9Z67nxhqTSln7R3{display:flex;color:var(--content-neutral-primary)}.UJS6JPsr064NVOEVoTZ0{width:240px}.CUhHFNYbzOYaoLcOHU9i,.vDY8GD5z8N23Ikxu4KhY{line-height:14.52px}.vDY8GD5z8N23Ikxu4KhY{color:rgba(255,255,255,.7)}.ZWQneYbvSNgIM8rMYL0o{margin-top:12px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageField/ui/FieldActions/MediaAction/SizeLimitsTooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oCAAA,CAEF,sBACE,WAAA,CAEF,4CACE,mBAAA,CAEF,sBACE,0BAAA,CAEF,sBACE,eAAA","sourcesContent":[".info{\n  display: flex;\n  color: var(--content-neutral-primary);\n}\n.wrap{\n  width: 240px;\n}\n.label, .desc{\n  line-height: 14.52px;\n}\n.desc{\n  color: rgba(255, 255, 255, 0.7);\n}\n.urlUpload {\n  margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "iEDky9Z67nxhqTSln7R3",
	"wrap": "UJS6JPsr064NVOEVoTZ0",
	"label": "CUhHFNYbzOYaoLcOHU9i",
	"desc": "vDY8GD5z8N23Ikxu4KhY",
	"urlUpload": "ZWQneYbvSNgIM8rMYL0o"
};
export default ___CSS_LOADER_EXPORT___;
