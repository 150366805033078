import { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Button, Error } from 'shared/ui'
import { makeTextFieldAutoFocus, makeTextFieldHighlight, TextField } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { ForgotPasswordStore } from 'widgets/ForgotPassword/store/forgotPasswordStore'

import styles from './styles.module.scss'

type IFormProps = {
  store: ForgotPasswordStore
}

export const ForgotPasswordForm = observer(({ store }: IFormProps) => {
  const {
    setEmail,
    email,
    loading,
    emailError,
    emailValidationError,
    triggerEmailValidation,
    isValidEmail,
  } = store

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerEmailValidation()
    }
  }

  return (
    <div className={styles.wrap}>
      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, {
          [styles.error]: !!emailError || !!emailValidationError,
        })}
        variant='stroke'
        disabled={loading}
        size='medium'
        error={!!emailError}
        InputProps={{ placeholder: 'name@company.com', onKeyDown }}
        onChange={setEmail}
        value={email}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldHighlight({ mode: 'error', highlight: !!emailValidationError }),
        ]}
      />
      <Error type={'text'} error={emailError || emailValidationError} />

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={triggerEmailValidation}
        loading={loading}
        disabled={loading || !isValidEmail}
        text={'Send instructions'}
        contained={'primary'}
        fullWidth
      />
    </div>
  )
})

export default ForgotPasswordForm
