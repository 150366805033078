import type { IStatusProps } from 'shared/ui/Status'
import type { ITriggerStatus } from 'entities/Trigger/api/type'

const DEFAULT_CONFIG: IStatusProps = {
  title: 'Active',
  icon: 'check2',
  emphasis: 'high',
  intent: 'positive',
}
const ACTIVE_CONFIG: IStatusProps = {
  title: 'Active',
  icon: 'check2',
  emphasis: 'high',
  intent: 'positive',
}

const DISABLED_CONFIG: IStatusProps = {
  title: 'Disabled',
  icon: 'minusCircle',
  emphasis: 'high',
  intent: 'neutral',
}

export const getConfigByStatus = (status?: ITriggerStatus) => {
  switch (status) {
    case 'active':
      return ACTIVE_CONFIG
    case 'disabled':
      return DISABLED_CONFIG
    default:
      return DEFAULT_CONFIG
  }
}

export const getConfigByKey = (key: number) => {
  switch (key) {
    case 1:
      return ACTIVE_CONFIG
    case 0:
      return DISABLED_CONFIG
    default:
      return DEFAULT_CONFIG
  }
}

export const getStatusByKey = (key: number): ITriggerStatus | undefined => {
  switch (key) {
    case 1:
      return 'active'
    case 0:
      return 'disabled'
    default:
      return undefined
  }
}
