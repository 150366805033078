// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aHDfQhb9SpvMZzFwyqh4{margin:9px 21px 26px}.aHDfQhb9SpvMZzFwyqh4 .bAnAgGUmbnYgpm9Ml2WS{margin-right:10px}.aHDfQhb9SpvMZzFwyqh4 .rGodvBN7rqLmxuCUlVVz input{height:16px;font-size:16px}.aHDfQhb9SpvMZzFwyqh4 .rGodvBN7rqLmxuCUlVVz input ::placeholder{color:var(--content-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/compliance/pages/TollFree/ui/TollFreeSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CAEA,4CACE,iBAAA,CAIA,kDACE,WAAA,CACA,cAAA,CAEA,gEACE,oCAAA","sourcesContent":[".wrap {\n  margin: 9px 21px 26px;\n\n  .icon {\n    margin-right: 10px;\n  }\n\n  .search {\n    & input {\n      height: 16px;\n      font-size: 16px;\n\n      ::placeholder {\n        color: var(--content-neutral-primary);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "aHDfQhb9SpvMZzFwyqh4",
	"icon": "bAnAgGUmbnYgpm9Ml2WS",
	"search": "rGodvBN7rqLmxuCUlVVz"
};
export default ___CSS_LOADER_EXPORT___;
