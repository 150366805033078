import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { useBroadcastRinglessContext } from 'widgets/BroadcastView/ui/BroadcastRingless'

export const RinglessTextToSpeech = observer(() => {
  const { handleTextToSpeech } = useBroadcastRinglessContext()

  return (
    <IconButton
      ariaLabel={getAriaLabel('TextToSpeech')}
      tooltipProps={{
        label: 'Text to speech',
        placement: 'top',
      }}
      icon={'lineType'}
      size={'medium'}
      color={'secondary'}
      variant={'icon'}
      onClick={handleTextToSpeech}
    />
  )
})
