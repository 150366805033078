import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, IIconButtonProps } from 'shared/ui'
import { ISavedRepliesProps } from 'entities/CannedMessages'
import { savedRepliesStore } from 'widgets/SavedReplies'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

type ISavedRepliesIconActionProps = ISavedRepliesProps & {
  iconButtonProps?: IIconButtonProps
}

export const SavedRepliesIconAction: FC<ISavedRepliesIconActionProps> = observer(
  ({ contact, onAddSavedReplies, integration, iconButtonProps }) => {
    const onOpenMergeFieldContent = () =>
      savedRepliesStore.openModal({ onAddSavedReplies, integration, contact, isCurrentAirCall })

    const messageFieldStore = useMessageFieldContext()
    const { isCurrentAirCall } = messageFieldStore

    return (
      <IconButton
        icon={'savedReplies'}
        tooltipProps={{
          label: 'Saved replies',
          placement: 'top',
        }}
        color={'tertiary'}
        size={'medium'}
        variant={'icon'}
        onClick={() => onOpenMergeFieldContent()}
        ariaLabel='SavedRepliesIconAction'
        {...iconButtonProps}
      />
    )
  }
)
