import { Box } from '@mui/material'
import {
  type IDropdownItem,
  type PhoneInputStore,
  PhoneWithLabelInput,
  TextFieldWithLabel,
} from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'

interface ContactFormProps {
  contact: {
    firstName: string
    lastName: string
    businessTitle: string
    jobPosition: number | null
    email: string
  }
  jobPositionList: IDropdownItem[]
  phoneInputStore: PhoneInputStore
  errorMessage?: string
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  setBusinessTitle: (value: string) => void
  setJobPosition: (value: number) => void
  setEmail: (value: string) => void
  checkIsEmail: () => void
}

export const ContactForm = ({
  contact,
  jobPositionList,
  phoneInputStore,
  errorMessage,
  setFirstName,
  setLastName,
  setBusinessTitle,
  setJobPosition,
  setEmail,
  checkIsEmail,
}: ContactFormProps) => (
  <>
    <Box display='flex' justifyContent='space-between' marginBottom={3} gap={3}>
      <TextFieldWithLabel
        labelProps={{
          label: {
            text: 'First name',
          },
        }}
        textFieldProps={{
          value: contact.firstName,
          onChange: setFirstName,
          InputProps: {
            placeholder: 'e.g. Adam',
          },
        }}
      />
      <TextFieldWithLabel
        labelProps={{
          label: {
            text: 'Last name',
          },
        }}
        textFieldProps={{
          value: contact.lastName,
          onChange: setLastName,
          InputProps: {
            placeholder: 'e.g. Johnson',
          },
        }}
      />
    </Box>
    <Box display='flex' justifyContent='space-between' marginBottom={3} gap={3}>
      <TextFieldWithLabel
        labelProps={{
          label: {
            text: 'Business title',
          },
        }}
        textFieldProps={{
          value: contact.businessTitle,
          onChange: setBusinessTitle,
          InputProps: {
            placeholder: 'e.g. Founder',
          },
        }}
      />
      <SelectField
        boxWidth='100%'
        labelProps={{
          label: {
            text: 'Job position',
          },
        }}
        items={jobPositionList}
        value={contact.jobPosition}
        setValue={setJobPosition}
        btnProps={{
          size: 'large',
        }}
      />
    </Box>
    <TextFieldWithLabel
      marginBottom={0}
      errorMessage={errorMessage}
      labelProps={{
        marginBottom: 8,
        label: {
          text: 'Business email',
        },
      }}
      textFieldProps={{
        value: contact.email,
        onChange: setEmail,
        onBlur: checkIsEmail,
        InputProps: {
          placeholder: 'e.g. adam.johnson@example.com',
        },
      }}
    />
    <PhoneWithLabelInput
      item={phoneInputStore.country}
      items={phoneInputStore.countries}
      number={phoneInputStore.number}
      numberError={phoneInputStore.numberError}
      isValidNumber={phoneInputStore.isValidNumber}
      numberRequestError={phoneInputStore.numberRequestError}
      setNumber={phoneInputStore.setNumber}
      setCountry={phoneInputStore.setCountry}
      triggerNumberValidation={phoneInputStore.triggerNumberValidation}
    />
  </>
)
