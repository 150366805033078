import { makeAutoObservable, runInAction } from 'mobx'

import { TagsApi } from 'entities/Tags/api/tags'
import { Tag } from 'entities/Tags/model/Tag'

export class TagsControl {
  static FromTags(tags: Tag[]) {
    const control = new TagsControl()

    tags.forEach((tag) => control._tagsMap.set(tag.id, tag))
    control._ids = Array.from(control._tagsMap.keys())
    control._synced = true

    return control
  }

  private _synced = false
  private _tagsMap = new Map<number, Tag>()

  constructor(private _ids: number[] = []) {
    this._synced = !_ids.length

    makeAutoObservable(this)
  }

  get synced() {
    return this._synced
  }

  get ids() {
    return this._ids
  }

  get tags() {
    return Array.from(this._tagsMap.values())
  }

  syncIds = async () => {
    if (this._synced) return

    const tags = await TagsApi.getTagsById(this._ids)

    runInAction(() => {
      tags.forEach((item) => {
        const tagId = item.id
        const tag = new Tag(item)

        this._tagsMap.set(tagId, tag)
      })

      this._synced = true
    })
  }

  addTag = (tag: Tag) => {
    if (this._tagsMap.has(tag.id)) return

    this._tagsMap.set(tag.id, tag)

    this._ids = Array.from(this._tagsMap.keys())
  }

  createTag = async (label: string) => {
    const { data } = await TagsApi.createTag({ label })
    const tag = new Tag(data)

    this.addTag(tag)

    return tag
  }

  removeTag = (id: number) => {
    const deleted = this._tagsMap.delete(id)

    if (deleted) this._ids = Array.from(this._tagsMap.keys())
  }

  reset = () => {
    this._ids = []
    this._synced = true

    this._tagsMap.clear()
  }
}
