import React from 'react'
import { observer } from 'mobx-react-lite'
import Picker, { IEmojiData } from 'emoji-picker-react'
import { Dropdown, IconButton, Tooltip, IIconButtonColor } from 'shared/ui'
import { MessageFieldStore } from 'widgets/MessageField'
import styles from './styles.module.scss'

type IEmojiPickerProps = {
  onTrigger?: () => void
  iconColor?: IIconButtonColor
  messageFieldStore: MessageFieldStore
}

export const EmojiPicker: React.FC<IEmojiPickerProps> = observer(
  ({ onTrigger, iconColor = 'secondary', messageFieldStore }) => {
    const { addContent, isModeNote } = messageFieldStore
    const handleEmojiClick =
      (onCloseMenu: () => void) => (event: React.MouseEvent, emojiObject: IEmojiData) => {
        onCloseMenu()
        addContent && addContent(emojiObject.emoji)
      }

    const handleTooltipOpen = () => {
      onTrigger && onTrigger()
    }

    return (
      <>
        <Dropdown
          ariaLabel={'EmojiPicker'}
          placement={'bottom'}
          typeButton={'icon-button'}
          triggerComponent={(open) => (
            <div>
              <Tooltip label={open ? '' : 'Add emoji'} title={''} placement={'top'}>
                <IconButton
                  icon={'emoji'}
                  variant={isModeNote ? 'contained' : 'icon'}
                  color={isModeNote ? 'passiveSecondary' : iconColor}
                  onClick={handleTooltipOpen}
                  active={open}
                  ariaLabel='EmojiPicker'
                />
              </Tooltip>
            </div>
          )}
          customComponent={(onCloseMenu) => (
            <div className={styles.emoji}>
              <Picker onEmojiClick={handleEmojiClick(onCloseMenu)} />
            </div>
          )}
        />
      </>
    )
  }
)
