import { makeAutoObservable } from 'mobx'
import { INewOrganizationForm } from '../type'

export class NewOrganizationForm {
  name = ''
  lastName = ''
  organizationName = ''
  email = ''
  phone = ''
  password = ''
  expirationTime = '30'
  ignoreFees = true
  paidSeats = 0
  paidNumbers = 0
  twoWeeksTrial = true

  constructor() {
    makeAutoObservable(this)
  }

  setName = (name: string) => {
    this.name = name
  }

  setLastName = (lastName: string) => {
    this.lastName = lastName
  }

  setEmail = (email: string) => {
    this.email = email
  }

  setPhone = (phone: string) => {
    this.phone = phone
  }

  setPassword = (password: string) => {
    this.password = password
  }

  setExpirationTime = (time: string) => {
    this.expirationTime = time
  }

  setIgnoreFees = () => {
    this.ignoreFees = !this.ignoreFees
  }

  setOrganizationName = (name: string) => {
    this.organizationName = name
  }

  incrementPaidSeats = () => {
    this.paidSeats += 1
  }

  decrementPaidSeats = () => {
    if (this.paidSeats === 0) return
    this.paidSeats -= 1
  }

  incrementPaidNumbers = () => {
    this.paidNumbers += 1
  }

  decrementPaidNumbers = () => {
    if (this.paidNumbers === 0) return
    this.paidNumbers -= 1
  }

  setTwoWeeksTrial = () => {
    this.twoWeeksTrial = !this.twoWeeksTrial
  }

  reset = () => {
    this.name = ''
    this.lastName = ''
    this.organizationName = ''
    this.email = ''
    this.phone = ''
    this.password = ''
    this.expirationTime = '30'
    this.ignoreFees = true
    this.paidSeats = 0
    this.paidNumbers = 0
    this.twoWeeksTrial = true
  }

  get formData(): INewOrganizationForm {
    return {
      name: this.name,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      password: this.password,
      paidSeats: this.paidSeats,
      paidNumbers: this.paidNumbers,
      expirationTime: this.expirationTime,
      ignoreFees: this.ignoreFees,
      organizationName: this.organizationName,
      twoWeeksTrial: this.twoWeeksTrial,
    }
  }

  get isDisabled() {
    return (
      !this.name ||
      !this.lastName ||
      !this.email ||
      !this.phone ||
      !this.password ||
      !this.organizationName
    )
  }
}

export const newOrganizationForm = new NewOrganizationForm()
