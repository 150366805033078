// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b83KzRZtFmlVRDNMyFE2{overflow:auto;position:absolute;top:0;right:0;bottom:0;left:0;z-index:var(--zIndex-OVERLAY_MAIN);background-color:rgba(20,22,26,.5)}.RtjuM8jEcbRraN0ZhAbC,.pUp0XYIre6QAEwsNf19g{position:fixed;top:50%;z-index:var(--zIndex-OVERLAY_MAIN_2);transform:translateY(-50%)}.RtjuM8jEcbRraN0ZhAbC{left:32px}.pUp0XYIre6QAEwsNf19g{right:32px}.ldHwla48BnsilZ3m6szQ{position:fixed;top:24px;right:24px;z-index:var(--zIndex-OVERLAY_MAIN_2)}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,kCAAA,CACA,kCAAA,CAEF,4CAEE,cAAA,CACA,OAAA,CACA,oCAAA,CACA,0BAAA,CAEF,sBACE,SAAA,CAEF,sBACE,UAAA,CAGF,sBACE,cAAA,CACA,QAAA,CACA,UAAA,CACA,oCAAA","sourcesContent":[".wrap {\n  overflow: auto;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: var(--zIndex-OVERLAY_MAIN);\n  background-color: rgba(#14161a, 0.5);\n}\n.leftArrow,\n.rightArrow {\n  position: fixed;\n  top: 50%;\n  z-index: var(--zIndex-OVERLAY_MAIN_2);\n  transform: translateY(-50%);\n}\n.leftArrow {\n  left: 32px;\n}\n.rightArrow {\n  right: 32px;\n}\n\n.closeButton {\n  position: fixed;\n  top: 24px;\n  right: 24px;\n  z-index: var(--zIndex-OVERLAY_MAIN_2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "b83KzRZtFmlVRDNMyFE2",
	"leftArrow": "RtjuM8jEcbRraN0ZhAbC",
	"rightArrow": "pUp0XYIre6QAEwsNf19g",
	"closeButton": "ldHwla48BnsilZ3m6szQ"
};
export default ___CSS_LOADER_EXPORT___;
