// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pepjkStziKHljMpZdHhL{display:flex;padding-right:38px;justify-content:flex-end;gap:8px;align-self:stretch;color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/widgets/TypingCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,OAAA,CACA,kBAAA,CACA,qCAAA","sourcesContent":[".wrap{\n  display: flex;\n  padding-right: 38px;\n  justify-content: flex-end;\n  gap: 8px;\n  align-self: stretch;\n  color: var(--content-primary-tertiary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "pepjkStziKHljMpZdHhL"
};
export default ___CSS_LOADER_EXPORT___;
