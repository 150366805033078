import dayjs, { Dayjs } from 'dayjs'
import { makeAutoObservable } from 'mobx'
import {
  IChatbotConversationMessage,
  IChatbotConversationMessageType,
} from '../api/conversationTypes'

export class ChatbotConversationMessage {
  id!: number
  type!: IChatbotConversationMessageType
  body!: string
  date!: Dayjs

  constructor(item: IChatbotConversationMessage) {
    this.syncOrigin(item)

    makeAutoObservable(this)
  }

  syncOrigin(item: IChatbotConversationMessage) {
    this.id = item.id
    this.type = item.message_type
    this.body = item.message_body
    this.date = dayjs(item.date)
  }
}

export class ChatbotConversation {
  private _messageMap = new Map<number, ChatbotConversationMessage>()

  constructor() {
    makeAutoObservable(this)
  }

  get messages() {
    return Array.from(this._messageMap.values())
  }

  addOrUpdate(messages: IChatbotConversationMessage[]) {
    messages.forEach((message) => {
      const existingMessage = this._messageMap.get(message.id)

      if (existingMessage) {
        existingMessage.syncOrigin(message)
      } else {
        this._messageMap.set(message.id, new ChatbotConversationMessage(message))
      }
    })
  }

  clear() {
    this._messageMap.clear()
  }
}
