import MicRecorderLib from 'mic-recorder-to-mp3'

export class MicRecorder {
  recorder: MicRecorderLib
  totalSamples: number
  sampleRate: number | null

  constructor(config: {
    bitRate: number
    deviceId: string | undefined
    startRecordingAt?: number | undefined
  }) {
    this.recorder = new MicRecorderLib(config)
    this.totalSamples = 0
    this.sampleRate = null
  }

  start() {
    return this.recorder.start().then(() => {
      this.sampleRate = this.recorder?.context?.sampleRate || null
      const originalProcessor = this.recorder?.processor

      if (!originalProcessor) {
        return
      }

      const originalOnAudioProcess = originalProcessor.onaudioprocess

      const handleAudioProcess = (event: AudioProcessingEvent) => {
        if (this.recorder['timerToStart']) {
          return
        }

        this.totalSamples += event.inputBuffer.length

        if (originalOnAudioProcess) {
          originalOnAudioProcess.call(originalProcessor, event)
        }
      }

      originalProcessor.onaudioprocess = handleAudioProcess
    })
  }

  stop() {
    return this.recorder.stop()
  }

  getMp3() {
    return this.recorder.getMp3()
  }

  getRecordingDuration() {
    if (this.sampleRate !== null) return this.totalSamples / this.sampleRate
    return 0
  }
}
