import { makeAutoObservable } from 'mobx'
import { MediaControllerStore } from 'widgets/PresentationMode/store/MediaControllerStore'

export class VideoControllerStore {
  constructor() {
    makeAutoObservable(this)
  }

  mediaControllerStore: MediaControllerStore = new MediaControllerStore(true)

  isFull = false

  onExpand = () => {
    this.isFull = !this.isFull
  }
}
