import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import { IntegrationKey, IResponseIntegrationHubspotDeal } from 'entities/Integrations'

import styles from '../styles.module.scss'

type IItemDealRowProps = {
  deal: IResponseIntegrationHubspotDeal
  integrationKey: IntegrationKey | null
}

const ItemDealRow = ({ deal, integrationKey }: IItemDealRowProps) => {
  const defaultRows = [
    {
      id: 1,
      label: 'Amount',
      value: deal?.amount?.value || null,
    },
    {
      id: 2,
      label: 'Close date',
      value: deal?.closedate?.value || null,
    },
    {
      id: 3,
      label: 'Stage',
      value: deal?.dealstage?.value || null,
    },
  ]

  const pipedriveRows = [
    {
      id: 1,
      label: 'Value',
      value: deal?.amount?.value || null,
    },
    {
      id: 4,
      label: 'Pipeline',
      value: deal?.pipeline?.value || null,
    },
    {
      id: 3,
      label: 'Stage',
      value: deal?.dealstage?.value || null,
    },
    {
      id: 5,
      label: 'Owner',
      value: deal?.owner?.value || null,
    },
  ]

  const activeCampaignRows = [
    {
      id: 1,
      label: 'Value',
      value: deal?.amount?.value || null,
    },
    {
      id: 2,
      label: 'Owner',
      value:
        deal?.owner?.first_name || deal?.owner?.last_name
          ? `${deal?.owner?.first_name} ${deal?.owner?.last_name}`
          : null,
    },
  ]

  let rows = defaultRows

  switch (integrationKey) {
    case 'pipedrive': {
      rows = pipedriveRows
      break
    }

    case 'activecampaign': {
      rows = activeCampaignRows
      break
    }
  }

  return (
    <div className={styles.dealWrapper}>
      {rows.map((item) => (
        <div className={styles.itemRow} key={item.id}>
          <div className={styles.label}>{item.label}</div>
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value != null ? String(item.value) : '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { ItemDealRow }
