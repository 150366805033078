import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { isEqual } from 'lodash'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { showToast } from 'shared/ui'
import {
  AdminOrganizationsApi,
  ICustomPayment,
  IItemToAdd,
  IParamsCustomPlan,
  IResponseAdminOrganizationById,
  IResponseCustomPaymentPreview,
} from 'entities/Admin'
import {
  ApplyDateOption,
  PaymentPlanTypeOption,
} from 'pages/admin/ui/AdminManagePaymentPlan/PaymentPlanSchedule/types'
import { adminOrganizationPlanStore } from 'pages/admin/pages/organizations/store/adminOrganizationPlanStore'
import { AdminRoutesEnum } from 'pages/admin'
import { PaymentScheduleTable } from '../models/paymentScheduleTable'

class AdminOrganizationCustomPlanStore {
  id: number | null = null
  loading = false
  saveLoading = false
  organization: IResponseAdminOrganizationById | null = null
  customPayments: ICustomPayment[] = []
  isEdit = false

  selectedPlan: string | undefined = undefined
  paidSeats = 0
  paidNumbers = 0

  applyDate: ApplyDateOption | null = null
  paymentPlanType: PaymentPlanTypeOption | null = null

  planPreview: IResponseCustomPaymentPreview | undefined = undefined

  previewItems: IItemToAdd[] = []
  totalPrice = 0
  totalCredits = 0

  isContract = false

  modalId = ''

  paymentScheduleTable = new PaymentScheduleTable()

  constructor() {
    makeAutoObservable(this)
    reaction(
      () => [this.selectedPlan, this.paidNumbers, this.paidSeats, this.withProRate],
      this.fetchPlanPreview,
      {
        delay: 500,
      }
    )

    reaction(() => this.paymentPlanType, this.paymentScheduleTable.fillPaymentPlan)
    reaction(() => this.totalPrice, this.paymentScheduleTable.recalculateAmountInPaymentPlan)
    reaction(() => this.selectedPlan, this.paymentScheduleTable.reset)
  }

  setId = (id: number | null) => {
    this.id = id
    this.getData()
  }

  reset = () => {
    this.loading = false
    this.saveLoading = false
    this.customPayments = []

    this.selectedPlan = undefined
    this.paidSeats = 0
    this.paidNumbers = 0

    this.applyDate = null
    this.paymentPlanType = null

    this.planPreview = undefined

    this.previewItems = []
    this.totalPrice = 0
    this.totalCredits = 0

    this.isContract = false

    this.modalId = ''
    this.isEdit = false

    this.paymentScheduleTable.reset()
  }

  selectPlan = (id: string) => {
    this.selectedPlan = id
  }

  getData = async () => {
    try {
      if (!this.id) {
        return
      }
      runInAction(() => {
        this.loading = true
      })

      const [
        {
          data: { data: customPayments },
        },
        { data },
      ] = await Promise.all([
        AdminOrganizationsApi.getCustomPayments({ id: this.id }),
        AdminOrganizationsApi.getOrganizationById(this.id),
      ])

      if (customPayments.length > 0) {
        this.isEdit = true
        this.selectedPlan = String(data.stripe_data.subscription.stripe_plan.key)
        this.totalPrice = customPayments.reduce((total, item) => total + item.amount, 0) / 100
        this.totalCredits = customPayments.reduce((total, item) => total + item.credits, 0)
        this.paymentScheduleTable.fillExistPaymentsPlan(customPayments)
      }

      this.setOrganization(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setOrganization = (organization: typeof this.organization) => {
    this.organization = organization
    if (organization) this.isContract = organization?.is_contract
  }

  incrementPaidSeats = async () => {
    this.paidSeats += 1
  }

  decrementPaidSeats = async () => {
    if (this.paidSeats === 0) return
    this.paidSeats -= 1
  }

  incrementPaidNumbers = async () => {
    this.paidNumbers += 1
  }

  decrementPaidNumbers = async () => {
    if (this.paidNumbers === 0) return
    this.paidNumbers -= 1
  }

  fetchPlanPreview = async () => {
    const planData = adminOrganizationPlanStore.selectedPlanData(this.selectedPlan)

    if (!this.organization?.id || !planData?.stripe_id || this.isEdit) {
      return
    }

    const { data } = await AdminOrganizationsApi.getPlanPreview({
      organizationId: this.organization.id,
      plan: planData.stripe_id,
      seats: this.paidSeats || 0,
      numbers: this.paidNumbers || 0,
      with_prorate: Number(this.withProRate),
    })

    if (isEqual(data, [])) {
      return
    }

    this.planPreview = data as IResponseCustomPaymentPreview
    if (!isEqual(data, [])) {
      const { new_subscription } = data as IResponseCustomPaymentPreview
      runInAction(() => {
        this.totalPrice = new_subscription.amount / 100
        this.totalCredits = new_subscription.credits
        this.previewItems = new_subscription.items_to_add
        this.isContract = !!new_subscription.plan.startsWith('custom_plan')
      })
    }
  }

  get currentSubscriptionStatus() {
    return this.organization?.stripe_data?.subscription?.status
  }

  get currentSubscriptionRenewalDate() {
    const dateString = this.organization?.stripe_data?.subscription?.renewal_date

    if (dateString) {
      return uiStore.dayjs(dateString).add(-1, 'hour')
    }

    return uiStore.dayjs()
  }

  get isTrialOrganization() {
    return this.currentSubscriptionStatus === 'trialing'
  }

  get isCanceledSubscription() {
    return this.currentSubscriptionStatus === 'canceled'
  }

  get disableNextBillingCycle() {
    return this.isCanceledSubscription || this.isTrialOrganization
  }

  get applyDateItems() {
    return [
      {
        id: 'immediately',
        value: 'immediately',
        label: 'Immediately',
      },
      {
        id: 'next-billing-cycle',
        value: 'next-billing-cycle',
        label: 'Start from next billing cycle',
        disabled: this.disableNextBillingCycle,
      },
    ]
  }

  get isApplyDateDisabled() {
    return !this.selectedPlan || this.isEdit
  }

  get isPaymentPlanDisabled() {
    const planData = adminOrganizationPlanStore.selectedPlanData(this.selectedPlan)

    return !planData || planData.interval !== 'annual' || this.isEdit
  }

  setApplyDate = (value: ApplyDateOption) => {
    this.applyDate = value
    this.paymentPlanType = 'none'
  }

  setPaymentPlanType = (value: PaymentPlanTypeOption) => {
    this.paymentPlanType = value
  }

  get showSummary() {
    return (this.applyDate !== null && Boolean(this.selectedPlan)) || !this.isEdit
  }

  cancel = () => {
    this.reset()
    uiStore.navigate && uiStore.navigate(`admin/${AdminRoutesEnum.organizations}/${this.id}`)
  }

  save = async () => {
    const selectedPlan = adminOrganizationPlanStore.selectedPlanData(this.selectedPlan)

    if (!this.organization?.id || this.totalPrice === null || !selectedPlan?.stripe_id) {
      return
    }

    const isNextBillingCycle = this.applyDate === 'next-billing-cycle'

    let customPlan: IParamsCustomPlan = {
      plan: selectedPlan.stripe_id,
      items: this.previewItems,
      payments: [],
      is_from_next_billing_cycle: isNextBillingCycle,
    }

    if (this.havePaymentPlan) {
      customPlan = {
        ...customPlan,
        payments: this.paymentScheduleTable.payments,
      }

      if (isNextBillingCycle && this.currentSubscriptionRenewalDate) {
        const firstItem = {
          payment_date: this.currentSubscriptionRenewalDate.utc().format(DayjsFormats.apiFormat2),
          credits: 0,
          amount: 0,
          type: 'next_billing_cycle',
        }

        customPlan.payments = [firstItem, ...customPlan.payments]
      }
    }

    try {
      this.saveLoading = true
      if (customPlan.payments.length === 0) {
        await AdminOrganizationsApi.createCustomPlanWithoutPaymentPlan({
          organizationId: this.organization.id,
          plan: selectedPlan.stripe_id,
          numbers: this.paidNumbers || 0,
          seats: this.paidSeats || 0,
          schedule: isNextBillingCycle ? 1 : 0,
          is_contract: this.isContract,
        })
      } else {
        await AdminOrganizationsApi.createCustomPlan({
          organizationId: this.organization?.id,
          customPlan,
          is_contract: this.isContract,
        })
      }

      this.reset()
      uiStore.navigate && uiStore.navigate(`admin/${AdminRoutesEnum.organizations}/${this.id}`)

      showToast({
        type: 'success',
        title: 'Custom plan will be successfully applied',
      })
    } catch (error) {
      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
    } finally {
      this.saveLoading = false
    }
  }

  retryPayment = async (paymentId: string) => {
    try {
      await AdminOrganizationsApi.retryPayment(paymentId)

      showToast({
        type: 'success',
        title: 'Custom payment will be retried',
      })

      await this.getData()
    } catch (error) {
      console.error(error)

      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
    }
  }

  updatePayments = async () => {
    const planData = adminOrganizationPlanStore.selectedPlanData(this.selectedPlan)
    const id = this.id

    if (!planData?.stripe_id || !id) {
      return
    }

    const data = {
      plan: planData.stripe_id,
      payments: this.paymentScheduleTable.payments,
      is_contract: this.isContract,
    }

    try {
      this.saveLoading = true
      await AdminOrganizationsApi.updatePayments(id, data)

      this.reset()
      uiStore.navigate && uiStore.navigate(`admin/${AdminRoutesEnum.organizations}/${this.id}`)
    } catch (error) {
      console.error(error)

      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
    } finally {
      this.saveLoading = false
    }
  }

  setContract = (value: boolean) => {
    this.isContract = value
  }

  get withProRate() {
    return (
      !this.havePaymentPlan &&
      (!this.isCanceledSubscription || !this.isTrialOrganization) &&
      this.applyDate === 'immediately'
    )
  }

  get remainingAmount() {
    if (this.planPreview) {
      return (
        (this.planPreview.new_subscription.remaining_amount ||
          this.planPreview.new_subscription.amount) / 100
      )
    }

    return this.totalPrice
  }

  get creditsAmount() {
    if (this.planPreview) {
      return this.planPreview.new_subscription.credit_amount / 100
    }

    return 0
  }

  get havePaymentPlan() {
    return Boolean(this.paymentPlanType && this.paymentPlanType !== 'none')
  }
}

export const adminOrganizationCustomPlanStore = new AdminOrganizationCustomPlanStore()
