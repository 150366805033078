import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import classnames from 'classnames'
import { TextField } from 'shared/ui/TextField'
import { Button, Icon, Typography, Tooltip } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { IS_CALLING_APP_LIGHT } from 'shared/config'
import { ZIndex } from 'shared/constants/zIndex'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import styles from './styles.module.scss'

export const CallVoicemailDropContent = observer(() => {
  const store = useCallPopUpDefaultContext()

  const {
    isEmptyVoicemailDropsAudioList,
    isLoadingVoicemailDrop,
    voicemailDropSearchKey,
    voicemailDropsAudioList,
    handleSearchVoicemailDrop,
    handleChoiceVoicemailDrop,
    getDuration,
  } = store

  if (isLoadingVoicemailDrop) {
    return (
      <Typography
        variant={'body-md-medium'}
        color={'var(--content-primary-primary)'}
        ariaLabel={'VoicemailDropInProgress'}
        className={styles.loadingState}
      >
        Voicemail drop in progress...
      </Typography>
    )
  }

  if (isEmptyVoicemailDropsAudioList) {
    return (
      <div className={styles.empty}>
        <div
          className={classnames(styles.label, {
            [styles.longLabel]: IS_CALLING_APP_LIGHT,
          })}
        >
          <Typography
            variant={IS_CALLING_APP_LIGHT ? 'body-rg-medium' : 'body-md-regular'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'SetUpVoicemailDrop'}
          >
            Set up voicemail drop
          </Typography>
        </div>
        <Button
          text={'Go to settings'}
          onClick={(e) => {
            e.preventDefault()

            uiStore.changeRoute({
              path: '/settings/personal/settings/voicemail-drop',
              type: 'vue',
            })
          }}
          size={'medium'}
        />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.search}>
        <Typography
          className={styles.searchLabel}
          variant={'body-md-medium'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'VoicemailDrop'}
        >
          Voicemail drop
        </Typography>
        <TextField
          className={styles.textInput}
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={voicemailDropSearchKey}
          onChange={handleSearchVoicemailDrop}
        />
      </div>
      <ul className={styles.list}>
        {voicemailDropsAudioList?.map((item) => {
          getDuration(item.url).then((duration) => {
            item.duration = dayjs.duration(duration as number, 'seconds').format('mm:ss')
          })

          return (
            <li key={item.id} className={styles.item}>
              <Tooltip
                label={'Please wait for the voicemail inbox tone before starting playback'}
                breakWord
                placement={'top'}
                zIndex={ZIndex.OVERLAY_TOP_1}
                fullWidth
              >
                <button className={styles.audio} onClick={() => handleChoiceVoicemailDrop(item)}>
                  <div className={styles.icon}>
                    <Icon icon={'audio'} tertiary fontSize={20} />
                  </div>
                  <div className={styles.info}>
                    <Typography
                      className={styles.label}
                      variant={'body-md-regular'}
                      color={'var(--content-primary-primary)'}
                      ariaLabel={`${item.name.split(' ').join('')}`}
                      ellipsis
                    >
                      {item.name}
                    </Typography>

                    <Typography
                      className={styles.time}
                      variant={'body-xs-regular'}
                      color={'var(--content-primary-disabled)'}
                      ariaLabel={`${item.name.split(' ').join('')}duration`}
                    >
                      {item.duration}
                    </Typography>
                  </div>
                </button>
              </Tooltip>
            </li>
          )
        })}
      </ul>
    </div>
  )
})
