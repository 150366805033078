import { MessageData } from 'entities/Message'
import { savedRepliesStore } from 'widgets/SavedReplies'
import { MessageFieldStore } from 'widgets/MessageField/store/messageFieldStore'
import { IInputAction } from 'widgets/MessageField/store/types'

export const makeSavedReplyInputAction = (store: MessageFieldStore): IInputAction => {
  const onAddSavedReplies = (data: MessageData) =>
    store.setMessageData({
      ...data,
      isReplace: false,
      replaceNewRow: false,
    })

  const action = () => savedRepliesStore.openModal({ onAddSavedReplies })

  return { value: '/', action }
}
