import { FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Badge, IconButton, Typography } from 'shared/ui'
import { IInboxCombine, inboxesStore, NAME_INBOX } from 'entities/Inbox'
import {
  DropdownSimpleInbox,
  DropdownGroupSimpleInbox,
} from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/Dropdown'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'
import styles from './styles.module.scss'

type MenuCardInboxProps = {
  item: IInboxCombine
  groupInbox?: IInboxCombine
  isCreate?: boolean
}

export const MenuCardInbox: FC<MenuCardInboxProps> = observer(
  ({ item: inbox, groupInbox, isCreate }) => {
    const store = useConversationMenuContext()
    const { handleUpdateTeamInbox, loadingUpdateTeam, deleteItemTree, disabledActive } = store
    const { selectCurrentInboxId } = inboxesStore
    const isGroup = Boolean(groupInbox)
    const active = selectCurrentInboxId === inbox.id
    const handleClickUpdateTeam = async () => {
      if (isCreate) return
      if (loadingUpdateTeam) return

      deleteItemTree(NAME_INBOX.Create)
      await handleUpdateTeamInbox(inbox.id)
    }

    if (inbox.type === 'inbox_group') return null

    return (
      <div
        className={classnames(styles.wrap, {
          [styles.active]: active && !disabledActive,
          [styles.disabled]: loadingUpdateTeam,
        })}
        onClick={handleClickUpdateTeam}
      >
        <div
          className={classnames(styles.boxName, {
            [styles.muted]: inbox.isMuted,
            [styles.unread]: inbox.unread,
          })}
        >
          <div className={styles.icon}>
            <IconButton
              iconProps={{
                color: !active && inbox.isMuted ? 'var(--content-neutral-primary)' : undefined,
              }}
              icon={inbox.icon}
              size={'small'}
              color={'transparent'}
              fontSize={16}
              ariaLabel='MenuCardInbox'
            />
          </div>

          <Typography
            variant={inbox.unread && !inbox.isMuted ? 'body-md-semibold' : 'body-md-regular'}
            ellipsis
            color={
              inbox.isMuted ? 'var(--content-primary-tertiary)' : 'var(--content-primary-primary)'
            }
            ariaLabel={'name'}
          >
            {inbox.name}
          </Typography>
        </div>

        <div className={styles.right}>
          <div
            className={classnames(styles.boxInfo, {
              [styles.isMuted]: inbox.isMuted,
              [styles.unread]: inbox.unread,
            })}
          >
            <div className={styles.info}>
              {Boolean(inbox.unread) && (
                <div className={styles.infoItem}>
                  <Badge content={inbox.unread} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.boxDropdownMenu}>
            {!isCreate && (
              <div className={styles.dropdownMenu}>
                {!isGroup && <DropdownSimpleInbox inbox={inbox} groupInbox={groupInbox} />}
                {isGroup && <DropdownGroupSimpleInbox inbox={inbox} groupInbox={groupInbox} />}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)
