import { FC } from 'react'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  type IntegrationKey,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IntegrationOrganizationProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationOrganizationProperty'
import { SectionTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/SectionTab/SectionTab'

type IOrganizationProps = {
  integrationLabel: string
  integrationKey: IntegrationKey
  organization?: IntegrationOrganizationProperty
  contact: Contact
  handleClear?: (key: string) => void
}

export const Organization: FC<IOrganizationProps> = ({
  organization,
  contact,
  integrationKey,
  integrationLabel,
  handleClear,
}) => {
  const integration = integrationsStore.getIntegration(integrationKey)
  const linkProps = {
    key: integrationKey,
    contact: contact,
    integration: integration,
    type: IIntegrationLinkTypes.contact,
  }
  const link = integrationLink(linkProps)
  const properties = organization?.properties || []

  return (
    <SectionTab
      item={organization}
      titleView={'View organization'}
      titleEmpty={'No organization found'}
      integrationLabel={integrationLabel}
      properties={properties}
      link={link}
      handleClear={(id) => {
        organization?.removeProperty(id)
        handleClear?.(id)
      }}
      labelType={'simple'}
    />
  )
}
