import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import { nanoid } from 'nanoid'
import { ICardFilter } from 'shared/ui'
import { TableStore } from 'shared/ui/Table'
import {
  IParamsGetBroadcastContacts,
  IResponseGetBroadcastContacts,
  IResponseOneTimeBroadcast,
} from 'entities/Broadcast/api/types'
import { BroadcastOneTime } from 'entities/Broadcast'
import { ContactStatistic } from 'entities/ContactStatistic'
import { BroadcastApi } from 'entities/Broadcast/api/broadcast'
import { IOpenContactsDetails } from 'widgets/ContactsDetails'
import { ContactsDetailsManageStore } from 'widgets/ContactsDetails/store'

export class BroadcastDetailOneTimeStore {
  tableStore = new TableStore<ContactStatistic>({
    element: 'contact',
    withoutDefaultManageColumns: true,
  })
  broadcast: BroadcastOneTime

  private _contactsDetailsManageStore = new ContactsDetailsManageStore()

  constructor(broadcastResponse: IResponseOneTimeBroadcast) {
    makeAutoObservable(this)
    this.broadcast = new BroadcastOneTime(broadcastResponse)

    this.loadData()

    reaction(
      () => this.paramsWithoutPage,
      () => {
        this.loading = true
        this.page = 1
      }
    )

    reaction(() => this.params, this.loadData, {
      delay: 500,
    })
  }

  page = 1
  limit = 10
  scrollToTOpTrigger = ''

  loading = false
  search = ''
  total = 0

  filter: IParamsGetBroadcastContacts['filter'] = 'sent_to'
  itemsMap: Map<number, ContactStatistic> = new Map<number, ContactStatistic>()

  reset = () => {
    this._contactsDetailsManageStore.reset()
  }

  init = () => {
    this._contactsDetailsManageStore.init()
  }

  get onOpenContactsDetails(): IOpenContactsDetails {
    return {
      disabled: this._contactsDetailsManageStore.disabled,
      open: (data) => {
        this._contactsDetailsManageStore.onOpenContactDetails(
          {
            contactId: data.contactId,
            inboxId: this.broadcast.inboxId,
          },
          'broadcast',
          false
        )
      },
    }
  }

  get contactsDetailsStore() {
    return this._contactsDetailsManageStore.contactsDetailsStore
  }

  get isNoData() {
    return !this.loading && !this.itemsMap.size && !this.search
  }

  get items(): ContactStatistic[] {
    return Array.from(this.itemsMap.values())
  }

  private cancelTokenSource: CancelTokenSource | null = null

  get paramsWithoutPage(): Omit<IParamsGetBroadcastContacts, 'page'> {
    return {
      search: this.search,
      length: this.limit,
      filter: this.filter,
      sortBy: this.tableStore.sortBy || 'created_at',
      sortOrder: this.tableStore.sortOrder,
    }
  }

  get params(): IParamsGetBroadcastContacts {
    return {
      page: this.page,
      ...this.paramsWithoutPage,
    }
  }

  get name() {
    return this.broadcast.name
  }
  get status() {
    return this.broadcast.status
  }
  get type() {
    return this.broadcast.type
  }
  get failed_reason() {
    return this.broadcast.failed_reason
  }

  get statistics() {
    return this.broadcast.statistics
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  onActiveFilter = (filter: ICardFilter<IParamsGetBroadcastContacts['filter']>) => {
    this.filter = filter.key
  }

  private initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  private setData = (data: IResponseGetBroadcastContacts) => {
    this.itemsMap.clear()
    data.data.forEach((item) => {
      this.itemsMap.set(item.id, new ContactStatistic(item))
    })

    this.total = data.total
  }

  private loadData = async () => {
    try {
      this.initCancelTokenSource()
      this.loading = true
      const { data } = await BroadcastApi.getBroadcastContacts(this.broadcast.id, this.params)
      this.setData(data)
      this.scrollToTOpTrigger = nanoid()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
  setSearch = (value: string) => {
    this.search = value
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }
  get filters() {
    if (!this.statistics) return []
    let filters: ICardFilter<IParamsGetBroadcastContacts['filter']>[] = this.statistics?.filters
    if (this.broadcast.type === 'ringless') {
      const ringlessColumnsIds = ['sent_to', 'success', 'skipped', 'replied', 'failed']
      filters = this.statistics?.filters?.filter((column) =>
        this.broadcast.type === 'ringless' ? ringlessColumnsIds.includes(column.key) : true
      ) as ICardFilter<IParamsGetBroadcastContacts['filter']>[]
    }

    if (this.broadcast.status === 'pause' || this.broadcast.status === 'stopped') {
      return filters
    }
    return filters.filter((filter) => filter.key !== 'skipped')
  }

  onSuccessDelete = () => {
    this.loadData()
    this.tableStore.unselectAllIds()
  }
}
