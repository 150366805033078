import { useEffect } from 'react'
import { runInAction } from 'mobx'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseEventMessage } from 'entities/Message'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { Message } from 'entities/Message/model/Message'
import { filtersStore } from 'features/DropdownFilter'
import { canAddConversationToList, ConversationListStore } from 'widgets/ConversationList'

export const EventMessageUpdatedNew = {
  subscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.on(WebsocketEvent.MessageUpdatedNew, handler),
  unsubscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.off(WebsocketEvent.MessageUpdatedNew, handler),
}

export const useEventMessageUpdatedNew = (conversationListStore: ConversationListStore) => {
  const handler = (data: IResponseEventMessage) => {
    const item = new Conversation(data.conversation)
    const message = new Message(data.message)

    runInAction(() => {
      const conversation = data.conversation
      conversation.recent_message = data.message

      if (conversationListStore.conversationSearchStore.isActive) return
      if (!filtersStore.isShowConversation(item, message)) return

      if (canAddConversationToList(data.conversation)) {
        conversationStore.updateItem(conversation)
        conversationStore.getItem(conversation.id)?.handleChecked(item.checked)
        conversationListStore.updateItemOrder(item.id)
      }
    })
  }

  useEffect(() => {
    EventMessageUpdatedNew.subscribe(handler)

    return () => {
      EventMessageUpdatedNew.unsubscribe(handler)
    }
  }, [])
}
