// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CLgXdkADtLAcEhvRGGH4{display:flex;justify-content:space-between;align-items:center;gap:12px;width:100%}.CLgXdkADtLAcEhvRGGH4 .RuAtM7a4JfMMgm_04HRU{display:flex;align-items:center}.CLgXdkADtLAcEhvRGGH4 .chwDGiJwImYC1QfSDRMA{display:flex;gap:8px}.Yk7Wa_guogulLrTTv9tf{display:none}", "",{"version":3,"sources":["webpack://./src/widgets/RecordAudio/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CACA,UAAA,CACA,4CACE,YAAA,CACA,kBAAA,CAEF,4CACE,YAAA,CACA,OAAA,CAGJ,sBACE,YAAA","sourcesContent":[".wrap{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 12px;\n  width: 100%;\n  .flex{\n    display: flex;\n    align-items: center;\n  }\n  .row8{\n    display: flex;\n    gap: 8px;\n  }\n}\n.record{\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "CLgXdkADtLAcEhvRGGH4",
	"flex": "RuAtM7a4JfMMgm_04HRU",
	"row8": "chwDGiJwImYC1QfSDRMA",
	"record": "Yk7Wa_guogulLrTTv9tf"
};
export default ___CSS_LOADER_EXPORT___;
