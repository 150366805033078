import { makeAutoObservable } from 'mobx'
import type { IIntegrationsProperty } from 'entities/Integrations/model/types'
import { IntegrationProperty } from 'entities/Integrations/model'

export class IntegrationContactProperty {
  private _propertiesMap: Map<string, IntegrationProperty> = new Map()

  constructor(items: IIntegrationsProperty[]) {
    this.addProperties(items)

    makeAutoObservable(this)
  }

  addProperties = (items: IIntegrationsProperty[]) => {
    items.forEach((item) => {
      this.addProperty(item)
    })
  }

  addProperty = (item: IIntegrationsProperty) => {
    if (item.key === 'firstname') item.key = 'first_name'
    if (item.key === 'lastname') item.key = 'last_name'

    this._propertiesMap.set(item.key, new IntegrationProperty(item))
  }

  removeProperty = (key: string) => {
    this._propertiesMap.delete(key)
  }

  get title() {
    let name = ''
    const first_name = this._propertiesMap.get('first_name')?.value
    const last_name = this._propertiesMap.get('last_name')?.value

    if (first_name) name = first_name
    if (last_name) name += ` ${last_name}`

    return name
  }

  get properties() {
    return Array.from(this._propertiesMap.values()).filter((item) => {
      return !['first_name', 'last_name'].includes(item.key)
    })
  }

  get propertiesKeys() {
    return this.properties.map((item) => item.key)
  }
}
