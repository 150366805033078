import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, Button, IconButton, Typography } from 'shared/ui'
import { usersStore } from 'entities/Users'
import { Message } from 'entities/Message/model/Message'
import { MessagePendingMention } from 'entities/Message/model/MessagePendingMention'
import { conversationStore } from 'entities/Conversation'
import styles from './styles.module.scss'

type IMentionItemProps = {
  item: MessagePendingMention
  message: Message
  canManageInbox: boolean
}

export const MentionItem: FC<IMentionItemProps> = observer(({ item, message, canManageInbox }) => {
  const user = usersStore.getItem(item.id)
  const converstion = conversationStore.currentItem

  if (!user) return null
  if (!converstion) return null

  const user_owner_id = usersStore.user_owner_id
  const isGotItMessageOwner = message.user_id === user_owner_id && !item.is_got_it

  const handleActivate = () => {
    item.handleActivateUndo(converstion, message, user)
  }
  const handleCancel = () => {
    item.handleCancel(message, user)
  }
  const handleGotIt = () => {
    item.handleGotIt(message, user)
  }

  return (
    <div className={styles.card}>
      <div className={styles.card__box}>
        <div className={styles.card__avatar}>
          <Avatar info={user.avatarInfo} size={16} />
        </div>
        <Typography
          tag={'div'}
          ellipsis
          variant={'body-md-regular'}
          className={styles.card__name}
          ariaLabel={'name'}
        >
          {user.name}
        </Typography>
      </div>

      <div className={styles.card__box}>
        {canManageInbox && (
          <>
            <div className={styles.card__button}>
              <Button
                text={'Add to inbox'}
                contained={'secondary'}
                typeBtn={'outlined'}
                size={'small'}
                onClick={handleActivate}
                disabled={item.loading}
              />
            </div>
            <div className={styles.card__delete}>
              <IconButton
                variant={'icon'}
                color={'tertiary'}
                size={'small'}
                iconProps={{
                  icon: 'close',
                }}
                onClick={handleCancel}
                disabled={item.loading}
                tooltipProps={{
                  label: 'Don’t add',
                  placement: 'top',
                }}
                ariaLabel='MentionItem_close'
              />
            </div>
          </>
        )}

        {!canManageInbox && isGotItMessageOwner && (
          <>
            <div className={styles.card__button}>
              <Button
                text={'Got it'}
                contained={'secondary'}
                typeBtn={'outlined'}
                size={'small'}
                onClick={handleGotIt}
                disabled={item.loading}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
})
