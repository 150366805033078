import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IParamsEnrollCheck,
  IResponseEnrollCheck,
  IResponseGetWorkflows,
  IWorkflowExecutionRequest,
  IWorkflowExecutionResponse,
  IWorkflowTerminateExecutionResponse,
} from './types'

class Api {
  getWorkflows(): Promise<AxiosResponse<IResponseGetWorkflows>> {
    return baseApi.get('workflows/workflows')
  }

  enrollCheck(
    params: IParamsEnrollCheck,
    id: string
  ): Promise<AxiosResponse<IResponseEnrollCheck>> {
    return baseApi.post(`workflows/workflows/${id}/enroll`, params)
  }

  getConversationExecutions(
    id: number,
    params: IWorkflowExecutionRequest,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IWorkflowExecutionResponse>> {
    return baseApi.get(`workflows/executions/conversation/${id}`, { ...options, params })
  }

  terminateExecution(
    executionId: string
  ): Promise<AxiosResponse<IWorkflowTerminateExecutionResponse>> {
    return baseApi.post(`workflows/executions/${executionId}/terminate`)
  }
}

export const WorkflowsApi = new Api()
