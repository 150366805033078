import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

export class TriggersPageStore {
  pageLayoutStore = new PageLayoutStore()
  isTriggerActionShown = false

  constructor() {
    makeAutoObservable(this)
  }

  setIsTriggerActionShown = (isTriggerActionShown: boolean) => {
    this.isTriggerActionShown = isTriggerActionShown
  }
}
