import { createContext, useContext } from 'react'
import { MessageFieldStore } from 'widgets/MessageField/store/messageFieldStore'

export const MessageFieldContext = createContext<MessageFieldStore | null>(null)

export function useMessageFieldContext() {
  const context = useContext(MessageFieldContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with MessageFieldStoreProvider')
  }
  return context
}
