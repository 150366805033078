import { observer } from 'mobx-react-lite'
import { SendFromDropdown } from 'widgets/SendFromDropdown'
import { useChatbotContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export const SendFrom = observer(() => {
  const { errors, numberId } = useChatbotContext()
  const numberError = errors.getError('send_from')

  return (
    <div className={styles.details}>
      <SendFromDropdown
        store={numberId.store}
        error={{
          message: numberError,
          placement: 'bottom',
          anchor: 'container',
          offset: [0, 0],
        }}
        onClearError={() => errors.removeError('send_from')}
      />
    </div>
  )
})
