// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zFeURXvkYFP9B21fE_Ue{margin-bottom:40px;text-align:center;color:var(--content-primary-tertiary);border-radius:8px;background-color:var(--background-neutral-subtle);padding:12px}", "",{"version":3,"sources":["webpack://./src/widgets/Register/ui/Step4/ui/Step4FormBottom/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,iBAAA,CACA,qCAAA,CACA,iBAAA,CACA,iDAAA,CACA,YAAA","sourcesContent":[".wrap {\n  margin-bottom: 40px;\n  text-align: center;\n  color: var(--content-primary-tertiary);\n  border-radius: 8px;\n  background-color: var(--background-neutral-subtle);\n  padding: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "zFeURXvkYFP9B21fE_Ue"
};
export default ___CSS_LOADER_EXPORT___;
