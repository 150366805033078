import { observer } from 'mobx-react-lite'
import { useChatbotContext } from 'pages/chatbot/context'

import { CommonTextarea } from './CommonTextarea'

export const PointHowToRespond = observer(() => {
  const {
    errors,
    model: { settings },
  } = useChatbotContext()

  const businessInformationError = errors.getError('settings.how_to_respond.value')

  const handleChange = (value: string) => {
    settings.setHowToRespond(value)
    errors.removeError('settings.how_to_respond')
  }

  return (
    <CommonTextarea
      limit={2333}
      name='Define the style and tone of your messages:'
      tooltip='Use adjectives like formal, casual, witty, funny, silly, brief, etc'
      placeholder='The Textbot should respond in a polite and professional manner, asking clear and direct questions'
      error={businessInformationError}
      value={settings.howToRespond}
      onChange={handleChange}
    />
  )
})
