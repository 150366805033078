import { Box } from '@mui/material'
import { FC } from 'react'
import { Typography } from 'shared/ui'

import { WidgetIntegrationLabel } from './WidgetIntegrationLabel'

type IWidgetIntegrationTriggerProps = {
  items: (number | string)[]
}

export const WidgetIntegrationTrigger: FC<IWidgetIntegrationTriggerProps> = ({ items }) => {
  if (!items.length) return <>select</>

  return (
    <Box display='flex' gap={1} alignItems='center' height={32}>
      <Typography ariaLabel='Integration' variant='body-md-regular'>
        {items.length > 1 ? (
          `${items.length} integrations`
        ) : (
          <WidgetIntegrationLabel id={items[0]} />
        )}
      </Typography>
    </Box>
  )
}
