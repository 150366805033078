import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Icon } from 'shared/ui'
import { type SegmentModalStore } from 'widgets/ContactsMenu/ui/SegmentModal'
import styles from './styles.module.scss'

type IModalNewSegmentErrorProps = {
  segmentModalStore: SegmentModalStore
}

export const ModalNewSegmentError: FC<IModalNewSegmentErrorProps> = observer(
  ({ segmentModalStore }) => {
    if (!segmentModalStore.isNameLengthError) {
      return null
    }
    return (
      <div className={styles.errorContainer}>
        <Icon icon='alertTriangle' color='var(--red-60)' />
        <span>Segment name can contain max 100 characters</span>
      </div>
    )
  }
)
