import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { Call } from '@twilio/voice-sdk'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { showToast } from 'shared/ui'
import { links } from 'shared/constants/links'
import { logger } from 'shared/lib'
import { ZIndex } from 'shared/constants/zIndex'
import { CallContact } from 'entities/Call/model/CallContact'
import { Contact } from 'entities/Contacts/model/Contact'
import { conversationStore } from 'entities/Conversation'
import { callStore } from 'entities/Call'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { eventLogStore } from 'entities/EventLog'
import { billingStore } from 'entities/Billing'
import { contactsStore } from 'entities/Contacts'
import { Phone } from 'entities/Phone/model/Phone'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { PowerDialer } from 'entities/PowerDialer/model/PowerDialer'
import { PowerDialerApi } from 'entities/PowerDialer/api/powerDialer'
import { ContactFinalStatus, PowerDialerStatus } from 'entities/PowerDialer/api/types'
import { BuyCreditsModalStore } from 'widgets/BuyCreditsModal'
import type { ICallPopUpPowerDialerStoreV2InitData } from 'widgets/CallPopUp/store/types'
import { CallPopUpPowerDialerSessionStoreV2 } from 'widgets/CallPopUp/store/callPopUpPowerDialerSessionStoreV2'
import { getToastConfigByPowerDialerStatus } from 'widgets/PowerDialer'

const CALL_DELAY_DEFAULT = 5_000
const KEEP_ALIVE_INTERVAL = 10_000
const CONTACTS_PER_PAGE = 10

export class CallPopUpPowerDialerStoreV2 {
  private _unprocessedContactsIds: number[] = []

  private _contactsMap: Map<number, CallContact> = new Map()
  private _processedContactsCount = 0
  private _awaitingDelayContact: CallContact | null = null

  private _callFromNumber: Phone | null = null
  private _callFromTeam: Inbox | null = null
  private _powerDialer: PowerDialer | null = null
  private _onRemovePowerDialerCallTeam: (teamId: number) => void = () => {}

  private _modalStopId = ''
  private _isSaving = false
  private _isPause = false
  private _isPreCallSetupInProgress = false
  private _isCallInProgress = false
  private _contactsLoading = false

  private _buyCreditsModalStore = new BuyCreditsModalStore()
  private _powerDialerSessionStore = new CallPopUpPowerDialerSessionStoreV2()

  private _callDelayValue = CALL_DELAY_DEFAULT
  private _callDelayInterval: ReturnType<typeof setInterval> | null = null
  private _keepAliveInterval: ReturnType<typeof setInterval> | null = null

  private _disposeCallStatus: IReactionDisposer | null = null
  private _disposePause: IReactionDisposer | null = null
  private _disposeItems: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this._reactionCallStatus()
    this._reactionPause()
    this._reactionContacts()
  }

  setPowerDialerInitData = async (options: ICallPopUpPowerDialerStoreV2InitData) => {
    const { number, team, powerDialer, removePowerDialer } = options
    this._callFromNumber = number
    this._callFromTeam = team
    this._powerDialer = powerDialer
    this._onRemovePowerDialerCallTeam = removePowerDialer
    this._startKeepAlive()

    if (this._powerDialer.status !== PowerDialerStatus.InProgress) {
      const {
        data: { data: powerDialerResponse },
      } = await PowerDialerApi.changePowerDialerStatus(powerDialer.id, PowerDialerStatus.InProgress)

      this._powerDialer.syncOrigin(powerDialerResponse)
    }

    this._powerDialerSessionStore.setPowerDialerId(options.powerDialer.id)
    this._unprocessedContactsIds = [...powerDialer.contactsIdUnprocessed]

    await this.fetchNextContacts()

    this._setAwaitingDelayContact()

    if (this.isNonPowerDialerCallActive) {
      this.setPause(true)
    }
  }

  fetchNextContacts = async () => {
    if (!this.hasUnfetchedContacts) return

    const nextChunk = this._unprocessedContactsIds.splice(0, CONTACTS_PER_PAGE)

    runInAction(() => {
      this._contactsLoading = true
    })
    try {
      const contacts = await contactsStore.getByIds(nextChunk)
      if (contacts) {
        this._addContacts(contacts)
      }
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._contactsLoading = false
      })
    }
  }

  private _startKeepAlive = () => {
    this._stopKeepAlive()
    if (!this._powerDialer) return

    this._keepAlive()
    this._keepAliveInterval = setInterval(() => {
      this._keepAlive()
    }, KEEP_ALIVE_INTERVAL)
  }

  private _stopKeepAlive() {
    if (!this._keepAliveInterval) return

    clearInterval(this._keepAliveInterval)
    this._keepAliveInterval = null
  }

  private _keepAlive = async () => {
    if (!this._powerDialer) return

    try {
      await PowerDialerApi.keepPowerDialerAlive(this._powerDialer.id)
    } catch (e) {
      logger.error(e)
    }
  }

  private _addContacts = (contacts: Contact[]) => {
    contacts.forEach((contact) => {
      this._addContact(contact)
    })
  }

  private _addContact = (contact: Contact) => {
    const item = new CallContact(
      {
        contact: {
          id: contact.id,
          first_name: contact.first_name,
          last_name: contact.last_name,
          full_name: contact.full_name,
          color: contact.color,
          formatted_number: contact.formatted_number || '',
          number: contact.number || '',
        },
        call: {
          sid: '',
        },
      },
      false
    )

    this._contactsMap.set(item.id, item)
  }

  private _deleteContact = (id: number) => {
    this._contactsMap.delete(id)
  }

  private _updatePowerDialerStatus = async (status: PowerDialerStatus, withoutToast?: boolean) => {
    if (!this._powerDialer) return
    try {
      const result = await PowerDialerApi.changePowerDialerStatus(this._powerDialer.id, status)
      if (!withoutToast) {
        showToast(getToastConfigByPowerDialerStatus(status))
      }
      return result
    } catch (e) {
      console.log(e)
    }
  }

  private _resolveContactStatus = async (contactId: number, status: ContactFinalStatus) => {
    if (!this._powerDialer) return
    try {
      runInAction(() => {
        this._processedContactsCount++
      })
      await PowerDialerApi.resolveContactInPowerDialer(this._powerDialer.id, contactId, status)
    } catch (e) {
      console.log(e)
    }
  }

  handleSaveAndExit = async () => {
    runInAction(() => {
      this._isSaving = true
    })
    try {
      await this._updatePowerDialerStatus(PowerDialerStatus.Paused)
      this._resetPowerDialer()
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._isSaving = false
      })
    }
  }

  handleSkipContact = async (item: CallContact) => {
    this._resolveContactStatus(item.id, 'skipped')
    this._deleteContact(item.id)
    if (item.id == this._awaitingDelayContact?.id) {
      this._setAwaitingDelayContact()
    }
  }

  handleCall = async (callToId: number) => {
    if (!this._callFromTeam) return
    if (!this._callFromNumber) return
    if (!this._powerDialer) return
    if (this.isPowerDialerCallingDisabled) return

    runInAction(() => {
      this._isPreCallSetupInProgress = true
    })
    try {
      if (this.handleCheckCredits()) {
        this.setPause(true)

        return
      }

      this._clearCallDelayTimer()

      callStore.setStartedCall(true)
      const isAllowed = await callStore.checkMicrophone()

      if (!isAllowed) {
        callStore.setStartedCall(false)
        this.setPause(true)
        runInAction(() => {
          this._isPreCallSetupInProgress = false
        })

        showToast({
          type: 'error',
          title: 'Microphone access required',
          desc: 'To enable calling, please allow Salesmsg to access your microphone.',
          action: {
            link: links.enableMicrophone,
            text: 'Learn more',
          },
        })

        return
      }

      const conversation = await conversationStore.createConversation({
        contact_id: callToId,
        team_id: this._callFromTeam.id,
        number_id: this._callFromNumber.id,
      })

      if (conversation) {
        runInAction(() => {
          this._isPreCallSetupInProgress = false
          this._isCallInProgress = true
        })
        await callStore.connectTwilio(conversation.id, { powerDialerId: this._powerDialer.id })
      }
    } catch (e) {
      console.log(e)

      this._restartCallDelayTimer()
    } finally {
      runInAction(() => {
        this._isPreCallSetupInProgress = false
      })
    }
  }

  togglePowerDialerPause = () => {
    this.setPause(!this._isPause)

    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: this._isPause ? 'FE Paused' : 'FE Resumed',
      },
      { groupId: organizationStore.id }
    )
  }

  handlePowerDialerStop = () => {
    this._modalStopId = nanoid()

    this._clearCallDelayTimer()

    modalStore.addModal({
      id: this._modalStopId,
      type: ModalTypeList.ALERT,
      title: 'Are you sure?',
      desc: "If stopped you won't be able to continue the session. Pause to resume later from the Power Dialer campaigns page.",
      primaryAction: {
        text: 'Stop anyway',
        onAction: async () => {
          await this._updatePowerDialerStatus(PowerDialerStatus.Finished)

          modalStore.removeModal(this._modalStopId)
          this._resetPowerDialer()
        },
      },
      additionalSecondaryAction: {
        text: 'Pause and save',
        onAction: async () => {
          await this._updatePowerDialerStatus(PowerDialerStatus.Paused)

          modalStore.removeModal(this._modalStopId)
          this._resetPowerDialer()
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          this._restartCallDelayTimer()
          modalStore.closeModal(this._modalStopId)
        },
      },
      zIndex: ZIndex.OVERLAY_TOP_1,
    })
  }

  handleCheckCredits = () => {
    const trialCredits = organizationStore.trialCredits
    const accountCredits = organizationStore.accountCredits
    const isTrial = subscriptionStore.isTrial

    if (isTrial && trialCredits <= 0) {
      showToast({
        title: 'Your organization is out of message credits',
        type: 'error',
      })

      return true
    }

    if (!isTrial && accountCredits <= 0) {
      if (billingStore.autorecharge?.is_active) return false

      this._buyCreditsModalStore.openModal()

      return true
    }

    return false
  }

  setPause = (value: boolean) => {
    this._isPause = value
  }

  private _resetAwaitingDelayContact = () => {
    this._awaitingDelayContact = null
    this._clearCallDelayTimer()
  }

  private _setAwaitingDelayContact = () => {
    this._awaitingDelayContact =
      this.hasFetchedContactsToCall && !this.isPowerDialerCallingDisabled && !this._isPause
        ? this.contacts[0]
        : null
    this._restartCallDelayTimer()
  }

  private _restartCallDelayTimer = () => {
    this._clearCallDelayTimer()
    this._callDelayValue = CALL_DELAY_DEFAULT
    const delayedActiveContactId = this._awaitingDelayContact?.id
    this._callDelayInterval = setInterval(() => {
      runInAction(() => {
        this._callDelayValue -= 1000

        if (this._callDelayValue === 0) {
          if (delayedActiveContactId) this.handleCall(delayedActiveContactId)
        }
      })
    }, 1000)
  }

  private _clearCallDelayTimer = () => {
    if (!this._callDelayInterval) return

    clearInterval(this._callDelayInterval)
    this._callDelayInterval = null
  }

  private _reactionCallStatus = () => {
    this._disposeCallStatus?.()
    this._disposeCallStatus = reaction(
      () => callStore.status,
      (status) => {
        const currentCallId = callStore.contactTo?.id
        if (!this._powerDialer) return
        if (this.isNonPowerDialerCallActive) {
          return this.setPause(true)
        }
        if (
          status === Call.State.Ringing &&
          currentCallId &&
          this._contactsMap.has(currentCallId)
        ) {
          this._resolveContactStatus(currentCallId, 'contacted')
          this._resetAwaitingDelayContact()
          this._deleteContact(currentCallId)
        }
        if (status === Call.State.Closed) {
          this._isCallInProgress &&= false
          if (this.hasContactsToCall) {
            this._setAwaitingDelayContact()
          }
        }
      }
    )
  }

  private _reactionPause = () => {
    this._disposePause?.()
    this._disposePause = reaction(
      () => this.isPause,
      (value) => {
        if (!this._powerDialer) return
        if (value) {
          this._resetAwaitingDelayContact()
        } else {
          this._setAwaitingDelayContact()
        }
      }
    )
  }

  private _reactionContacts = () => {
    this._disposeItems?.()
    this._disposeItems = reaction(
      () => this.contactsCount,
      async (contactsCount) => {
        if (contactsCount < CONTACTS_PER_PAGE && this.hasUnfetchedContacts) {
          return this.fetchNextContacts()
        }

        if (contactsCount === 0) {
          this._updatePowerDialerStatus(PowerDialerStatus.Finished, true)
          this._resetPowerDialer()
        }
      }
    )
  }

  private get _isPowerDialerCalling() {
    return this._isPreCallSetupInProgress || this._isCallInProgress
  }

  get isNonPowerDialerCallActive() {
    return callStore.isBusy && !this._isPowerDialerCalling
  }

  get isPreCallSetupInProgress() {
    return this._isPreCallSetupInProgress
  }

  get awaitingDelayContact() {
    return this._awaitingDelayContact
  }

  get currentPowerDialerName() {
    return this._powerDialer?.name || 'Power Dialer'
  }

  get contacts() {
    return Array.from(this._contactsMap.values())
  }

  get contactsLoading() {
    return this._contactsLoading
  }

  get contactsCount() {
    return this._contactsMap.size
  }

  get actualUnprocessedCount() {
    const unprocessedCount = this._powerDialer?.contactsIdUnprocessed?.length ?? 0
    return unprocessedCount - this._processedContactsCount
  }

  get callDelayValue() {
    return dayjs(this._callDelayValue).format('s')
  }

  get isPause() {
    return this._isPause
  }

  get isSaving() {
    return this._isSaving
  }

  get hasFetchedContactsToCall() {
    return this._contactsMap.size > 0
  }

  get hasUnfetchedContacts() {
    return this._unprocessedContactsIds.length > 0
  }

  get hasContactsToCall() {
    return this.hasUnfetchedContacts || this.hasFetchedContactsToCall
  }

  get isPowerDialerCallingDisabled() {
    return this._isPowerDialerCalling || callStore.isBusy
  }

  get callFromNumber() {
    return this._callFromNumber
  }

  get callFromTeam() {
    return this._callFromTeam
  }

  private _resetPowerDialer = () => {
    const teamId = this._callFromTeam?.id

    this._stopKeepAlive()
    this._clearCallDelayTimer()

    this._disposeCallStatus?.()
    this._disposePause?.()
    this._disposeItems?.()

    this._unprocessedContactsIds = []

    this._contactsMap.clear()
    this._processedContactsCount = 0
    this._awaitingDelayContact = null

    this._callFromNumber = null
    this._callFromTeam = null
    this._powerDialer = null

    this._modalStopId = ''
    this._isSaving = false
    this._isPause = false
    this._isPreCallSetupInProgress = false
    this._isCallInProgress = false
    this._contactsLoading = false

    this._powerDialerSessionStore.reset()

    this._callDelayValue = CALL_DELAY_DEFAULT
    this._callDelayInterval = null
    this._keepAliveInterval = null

    if (callStore.isBusy || !teamId) return

    this._onRemovePowerDialerCallTeam(teamId)
  }
}
