const getEnvVar = (key: string, required = true): string => {
  if (process.env[key] === undefined && required) {
    console.warn(`Env variable ${key} is required`)

    return ''
  }
  return process.env[key] || ''
}
export type IAppType = 'react' | 'vue' | 'light'
export const APP_TYPE: IAppType = getEnvVar('APP_TYPE') as IAppType

/** SENTRY */
export const SENTRY_DSN = getEnvVar('SENTRY_DSN')
export const SENTRY_REPLAYS_SESSION_SAMPLE_RATE = getEnvVar('SENTRY_REPLAYS_SESSION_SAMPLE_RATE')
export const SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE = getEnvVar('SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE')
export const SENTRY_TRACES_SAMPLE_RATE = getEnvVar('SENTRY_TRACES_SAMPLE_RATE')
export const SENTRY_PROFILES_SAMPLE_RATE = getEnvVar('SENTRY_PROFILES_SAMPLE_RATE')

/** API entrypoint */
export const API_URL = getEnvVar('REACT_APP_API_URL')
export const API_VERSION = getEnvVar('REACT_APP_API_VERSION')
export const API_PREFIX = getEnvVar('REACT_APP_API_PREFIX')
export const APP_ENV = getEnvVar('REACT_APP_ENV')

export const NODE_ENV = getEnvVar('NODE_ENV')

export const isLocalEnv = APP_ENV === 'local'

export const PUSHER_DRIVER = getEnvVar('REACT_APP_PUSHER_DRIVER')
export const SOKETI_KEY = getEnvVar('REACT_APP_SOKETI_KEY')
export const SOKETI_HOST = getEnvVar('REACT_APP_SOKETI_HOST')
export const SOKETI_PORT = getEnvVar('REACT_APP_SOKETI_PORT')
export const LOGGER_BASE_URL = getEnvVar('REACT_APP_LOGGER_BASE_URL')
export const SOKETI_CLUSTER = getEnvVar('REACT_APP_PUSHER_CLUSTER')

export const STRIPE_KEY = getEnvVar('STRIPE_KEY')

export const RETENTLY_TRACKING_ID = getEnvVar('RETENTLY_TRACKING_ID')

export const CDN_FRONT = getEnvVar('REACT_APP_CDN_FRONT')

export const APP_LIGHT_BASE_NAME = getEnvVar('APP_LIGHT_BASE_NAME', false) || '/widget-light'
export const BUILD_LIGHT_PATH = getEnvVar('BUILD_LIGHT_PATH', false) || ''
export const IS_APP_LIGHT = APP_TYPE === 'light'

export const API_URL_MICROSERVICE = `${API_URL}/${API_VERSION}/`
export const API_URL_CORE = `${API_URL}/${API_VERSION}/${API_PREFIX}/`
export const LIST_MICROSERVICES = [
  'segment',
  'ai',
  'admin',
  'billing',
  'workflows',
  'chatbot',
  'logger',
  'smart-tts',
]

export const GET_API_URL = () => {
  if (API_PREFIX) {
    return API_URL_CORE
  }

  return API_URL_MICROSERVICE
}

localStorage.setItem('API_URL_CORE', GET_API_URL())

export const LIST_CDN = [
  'salesmessage-dev.s3.us-west-2.amazonaws.com',
  'salesmessage-develop.s3.us-west-2.amazonaws.com',
  'salesmessage-release.s3.us-west-2.amazonaws.com',
  'salesmessage-stage.s3.us-west-2.amazonaws.com',
  'salesmessage-qa.s3.us-west-2.amazonaws.com',
  'salesmessage-prod.s3.us-west-2.amazonaws.com',

  'dev-media.salesmessage.com',
  'develop-media.salesmessage.com',
  'release-media.salesmessage.com',
  'stage-media.salesmessage.com',
  'qa-media.salesmessage.com',
  'media.salesmessage.com',
]
