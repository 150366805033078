export type IParamsCustomFields = {
  search?: string
  page?: number
  length?: number
}

export type ICustomFieldsResponse = {
  current_page: number
  last_page: number
  per_page: number
  total: number
  from: number
  to: number
  data: ICommonField[]
}

export const CustomFieldType = {
  Text: 'text',
  Url: 'url',
  Date: 'date',
  Number: 'number',
} as const

export type ICustomFieldType = (typeof CustomFieldType)[keyof typeof CustomFieldType]

type ICommonFieldPart = {
  name: string
  type: ICustomFieldType
}

type IRegularFieldPart = {
  visible: true | false
  key: string
  category_new: string
  editable: boolean
}

type ICustomFieldPart = {
  visible: 1 | 0
  key: `custom.${string}`
  organization_id: number
  id: number
}

export type IRegularField = ICommonFieldPart & IRegularFieldPart
export type ICustomField = ICommonFieldPart & ICustomFieldPart
export type ICommonField = ICommonFieldPart & (IRegularFieldPart | ICustomFieldPart)

export type INewCustomField = {
  name: string
  type: ICustomFieldType
  visible: boolean
}

export type IResponseCreateCustomField = {
  data: ICustomField
  status: string
}
