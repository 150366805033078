import { ChannelUser, ChannelUserConversation } from 'entities/WebSocket'

class ChannelsUser {
  constructor() {}

  subscribeChannels = (user_id: number) => {
    new ChannelUser({
      id: user_id,
    })
    new ChannelUserConversation({
      id: user_id,
    })
  }
}

export const channelsUser = new ChannelsUser()
