import { Button, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { AuthRoutesEnum } from 'entities/Auth'

export const ForgotPasswordFormBottom = () => {
  const onClick = () => {
    uiStore.changeRoute({
      path: `/${AuthRoutesEnum.login}`,
    })
  }

  return (
    <div>
      <Typography
        variant={'body-sm-medium'}
        ariaLabel={'signInLink'}
        color={'var(--content-primary-tertiary)'}
      >
        Already have a Salesmsg account?
        <Button onClick={onClick} typeBtn={'link'} text={'Sign in'} />
      </Typography>
    </div>
  )
}
