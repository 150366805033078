// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O_bzj3jpUzM8Saq11jVP{margin-block:24px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/ScheduleDropdown/ui/ScheduleDropdownContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA","sourcesContent":[".wrap{\n  margin-block: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "O_bzj3jpUzM8Saq11jVP"
};
export default ___CSS_LOADER_EXPORT___;
