import { observer } from 'mobx-react-lite'
import { uiStore } from 'shared/store/uiStore'
import { Alert, Button } from 'shared/ui'
import { contactsCleanupStore } from 'widgets/ContactsTable'

export const CleanupAlert = observer(() => {
  const { hasCleanupItems, cleanupTitle, isExistOnlyUnlinked, setIsChangeToUnlinked } =
    contactsCleanupStore

  if (!hasCleanupItems) {
    return null
  }

  return (
    <Alert
      marginBottom={16}
      marginInline={20}
      item={{
        desc: cleanupTitle,
        type: 'warning',
      }}
      rightAction={
        <Button
          typeBtn={'action'}
          size={'small'}
          contained={'primary'}
          text={'Resolve'}
          onClick={() => {
            uiStore.changeRoute({
              path: 'contacts/cleanup',
            })
            isExistOnlyUnlinked && setIsChangeToUnlinked(true)
          }}
        />
      }
    />
  )
})
