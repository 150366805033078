import { makeAutoObservable } from 'mobx'
import { CountryEnum, ICountry } from 'shared/ui'
import { IParamsRegisterAnswersStep3, IResponseCountry } from 'entities/Auth'
import { AuthApi } from 'entities/Auth/api'
import { IRegisterCookies } from 'entities/Auth/store/types'

export class Step3Store {
  private _selectedCounties: Map<CountryEnum | string, ICountry> = new Map()
  private _selectedOtherCountry = ''
  private _allCountries: IResponseCountry[] = []

  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  get payload() {
    return {
      answer_country: this.firstValidCountryCode,
      answer_countries: this.selectedCountriesCode,
      answer_otherCountry: this._selectedOtherCountry,
    }
  }

  get answers(): IParamsRegisterAnswersStep3 {
    const countries = Array.from(this._selectedCounties.values())
      .filter((item) => item.value !== CountryEnum.World)
      .map((item) => item.name)
    if (this.selectedOtherCountry) countries.push(this.selectedOtherCountry.label)

    return {
      message_countries: countries.join(', '),
    }
  }

  get notifiedCountries() {
    const countries = Array.from(this._selectedCounties.values())
      .filter(
        (item) =>
          item.value !== CountryEnum.World &&
          item.value !== CountryEnum.US &&
          item.value !== CountryEnum.CA
      )
      .map((item) => item.name)
    if (this.selectedOtherCountry) countries.push(this.selectedOtherCountry.label)

    return countries.join(', ')
  }

  get isCompletedStep() {
    return (
      this.selectedCountriesCode.includes(CountryEnum.US) ||
      this.selectedCountriesCode.includes(CountryEnum.CA)
    )
  }

  initCookies = (data: IRegisterCookies) => {
    if (Array.isArray(data.answer_countries)) {
      data.answer_countries.map((answerCountry) => {
        const country = this.countries.find((country) => country.value === answerCountry)
        if (country) this._selectedCounties.set(country.value, country)
      })
    }

    if (data.answer_otherCountry) this._selectedOtherCountry = data.answer_otherCountry
  }

  get hasSelectedOtherCountry() {
    return !!this.selectedCountries.find((item) => item.value === CountryEnum.World)
  }

  get selectedOtherCountry() {
    return this.allCountries.find((item) => this._selectedOtherCountry === item.key)
  }

  get hasSelectedCountries() {
    return this._selectedCounties.size > 0
  }

  get selectedCountries() {
    return Array.from(this._selectedCounties.values())
  }

  get selectedCountriesCode() {
    return Array.from(this._selectedCounties.values()).map((item) => item.value)
  }

  get firstValidCountryCode() {
    const validCountries = this.selectedCountries.filter(
      (item) => item.value === CountryEnum.US || item.value === CountryEnum.CA
    )

    if (validCountries.length) return validCountries[0].value
    return CountryEnum.US
  }

  get allCountries() {
    const excludedCountries = [CountryEnum.AU, CountryEnum.US, CountryEnum.CA, CountryEnum.GB]

    return this._allCountries
      .map((item) => ({ id: item.key, ...item }))
      .filter((item) => !excludedCountries.includes(item.key as CountryEnum))
  }

  get otherCountry(): ICountry {
    return {
      name: 'Other',
      value: CountryEnum.World,
      icon: 'globe',
      status: {
        title: 'Request',
        intent: 'neutral',
        emphasis: 'high',
      },
    }
  }

  get countries(): ICountry[] {
    return [
      {
        name: 'United States',
        value: CountryEnum.US,
        icon: 'flagUSA',
        status: {
          title: 'Available',
          intent: 'positive',
          emphasis: 'high',
        },
      },
      {
        name: 'Canada',
        value: CountryEnum.CA,
        icon: 'flagCanada',
        status: {
          title: 'Available',
          intent: 'positive',
          emphasis: 'high',
        },
      },
      {
        name: 'United Kingdom',
        value: CountryEnum.GB,
        icon: 'flagUK',
        status: {
          title: 'Coming soon',
          intent: 'neutral',
          emphasis: 'high',
        },
      },
      {
        name: 'Australia',
        value: CountryEnum.AU,
        icon: 'flagAU',
        status: {
          title: 'Coming soon',
          intent: 'neutral',
          emphasis: 'high',
        },
      },
      this.otherCountry,
    ]
  }

  init = async () => {
    try {
      const data = await AuthApi.getCountries()
      this._allCountries = data.data
    } catch (e) {
      console.error(e)
    }
  }

  handleNext = (handleNext: () => void, showInfoModal: () => void) => {
    if (
      this.hasSelectedCountries &&
      !(this.isSelected(CountryEnum.CA) || this.isSelected(CountryEnum.US))
    ) {
      showInfoModal()
      return
    }
    handleNext()
  }

  isSelected = (value: CountryEnum | string) => {
    return this._selectedCounties.has(value)
  }

  onToggle = (value: ICountry) => {
    if (this.isSelected(value.value)) {
      this._selectedCounties.delete(value.value)
      return
    }
    this._selectedCounties.set(value.value, value)
  }

  onSelectCountry = (value: ICountry) => {
    this._selectedCounties.set(value.value, value)
  }

  onUnselectCountry = (value: ICountry) => {
    this._selectedCounties.delete(value.value)
  }

  onSelectOtherCountry = (value: string) => {
    this._selectedOtherCountry = value
  }
}
