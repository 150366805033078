import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTriggerViewContext } from 'widgets/TriggerView/context/triggerViewContext'
import { SMSMessageField } from 'widgets/MessageFormFields'

export const TriggerSMS = observer(() => {
  const { triggerSMSStore, errorsMap, setError } = useTriggerViewContext()
  const smsMessageError = errorsMap.get('smsMessage') || ''

  return (
    <SMSMessageField
      messageFieldStore={triggerSMSStore.messageFieldStore}
      errorMessage={smsMessageError}
      setError={(error) => setError('smsMessage', error)}
    />
  )
})
