import { PropsWithChildren } from 'react'
import { Icon } from 'shared/ui'
import styles from './styles.module.scss'

export interface IPresetErrorProps {
  reason: string
}

export const PresetError = ({ reason }: IPresetErrorProps) => (
  <div className={styles.error}>
    <Icon icon='alertTriangleSmall' color='var(--action-contained-destructive-primary-normal)' />
    <span className={styles.errorText}>{reason}</span>
  </div>
)

export const PresetErrorContainer = ({ children }: PropsWithChildren) => (
  <div className={styles.errorContainer}>{children}</div>
)
