import { observer } from 'mobx-react-lite'
import { HTMLAttributes, forwardRef } from 'react'
import { Icon, IconButton } from 'shared/ui'
import { ChatbotField } from 'entities/Chatbot'
import { CustomField } from 'entities/CustomField'

import { FieldCardProperty } from './FieldCardProperty'
import { FieldCardName } from './FieldCardName'
import styles from './styles.module.scss'

export interface IFieldCardProps {
  field: ChatbotField
  onDelete: () => void
  onUpdate: (field: CustomField) => void
}

export const FieldCard = observer(
  forwardRef<HTMLDivElement, IFieldCardProps & HTMLAttributes<HTMLDivElement>>(
    function FieldCardRef({ field, onDelete, onUpdate, ...props }, ref) {
      return (
        <div {...props} ref={ref} className={styles.fieldCard}>
          <header className={styles.fieldCardHeader}>
            <Icon className={styles.fieldCardHeaderControl} icon='dotsDrag' />
            <h4 className={styles.fieldCardHeaderTitle}>Collect</h4>
            <IconButton
              className={styles.fieldCardHeaderControl}
              icon='delete'
              variant='transparent'
              onClick={onDelete}
              ariaLabel='FieldCard_delete'
            />
          </header>
          <section className={styles.fieldCardFields}>
            <FieldCardProperty field={field} onChange={onUpdate} />
            <FieldCardName field={field} />
          </section>
        </div>
      )
    }
  )
)
