import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { TagsSelect } from 'entities/Tags'
import { contactsTagsModalStore } from 'widgets/ContactsTagsModal'

import styles from './styles.module.scss'

export const ContactsTagsDropdown = observer(() => {
  const { mode, control } = contactsTagsModalStore

  const content = useMemo(() => {
    switch (mode) {
      case 'add':
        return <TagsSelect canCreate control={control} placeholder='Search or create' />
      case 'remove':
        return <TagsSelect control={control} placeholder='Search' />
      default:
        return null
    }
  }, [mode])

  return <div className={styles.dropdownContainer}>{content}</div>
})
