import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon, Switch } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { linkContactStore } from '../../../store/linkContactStore'
import { TargetContactCard } from '../../TargetContactCard/TargetContactCard'
import { MatchedContactsCards } from '../../MatchedContactsCards/MatchedContactsCards'
import styles from './styles.module.scss'

const LinkMatchingTab = observer(() => {
  const { selectedMatchedId, shouldUpdate, toggleUpdate } = linkContactStore

  return (
    <div className={classnames(styles.wrap)}>
      <TargetContactCard />

      <div className={styles.divider}>
        <div className={styles.icon}>
          <Icon icon='arrowDown' fontSize={16} color='var(--content-primary-tertiary)' />
        </div>
      </div>

      {<MatchedContactsCards />}

      {selectedMatchedId && (
        <div className={styles.updateWrapper} onClick={() => toggleUpdate(!shouldUpdate)}>
          <Label
            label={{
              text: 'Update Salesmsg contact with the linked contact data',
            }}
          />
          <Switch value={shouldUpdate} onChange={toggleUpdate} size='small' />
        </div>
      )}
    </div>
  )
})

export { LinkMatchingTab }
