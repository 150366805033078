import { makeAutoObservable } from 'mobx'

export class MediaControllerStore {
  constructor(isPlay?: boolean) {
    makeAutoObservable(this)
    this.isPlay = !!isPlay
  }
  isLoaded = false
  isPlay = false

  currentTime = 0
  toTime = 0
  duration = 0
  speed = 1
  volumeValue = 50

  mute = false

  get volume() {
    if (this.mute) {
      return 0
    }
    return this.volumeValue
  }

  get progress() {
    if (!this.isLoaded) {
      return 0
    }
    return (this.currentTime / this.duration) * 100
  }

  reset = () => {
    this.currentTime = 0
    this.toTime = 0
    this.duration = 0
    this.speed = 1
    this.volumeValue = 50
  }

  setMute = (mute: boolean) => {
    this.mute = mute
  }

  setProgress = (progress: number) => {
    const time = (progress * this.duration) / 100
    this.toTime = time
    this.currentTime = time
  }

  setCurrentTime = (currentTime: number) => {
    this.currentTime = currentTime
  }

  togglePlay = () => {
    this.isPlay = !this.isPlay
  }

  setPlaying = (isPlay: boolean) => {
    this.isPlay = isPlay
  }

  setSpeed = (speed: number) => {
    this.speed = speed
  }
  setVolume = (volume: number) => {
    this.volumeValue = volume
  }

  setDuration = (duration: number) => {
    this.duration = duration
  }
  onEnded = () => {
    this.isPlay = false
    this.currentTime = 0
  }

  setLoaded = () => {
    this.isLoaded = true
  }
}
