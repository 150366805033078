import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseConversation } from 'entities/Conversation/api/types'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { Message } from 'entities/Message/model/Message'
import { filtersStore } from 'features/DropdownFilter'
import { canAddConversationToList, ConversationListStore } from 'widgets/ConversationList'

export const EventConversationClosed = {
  subscribe: (handler: (data: IResponseConversation) => void) =>
    websocket.on(WebsocketEvent.ConversationClosed, handler),
  unsubscribe: (handler: (data: IResponseConversation) => void) =>
    websocket.off(WebsocketEvent.ConversationClosed, handler),
}

export const useEventConversationClosed = (conversationListStore: ConversationListStore) => {
  const handler = (data: IResponseConversation) => {
    const item = new Conversation(data)
    const message = new Message(data.recent_message)
    const { deleteItem, updateItem } = conversationStore
    const { deleteItemOrder, addItemOrder } = conversationListStore

    if (!canAddConversationToList(data)) return
    if (filtersStore.isShowConversation(item, message)) {
      updateItem(data)
      addItemOrder(data.id)

      return
    }

    deleteItemOrder(data.id)
    deleteItem(data.id)
  }

  useEffect(() => {
    EventConversationClosed.subscribe(handler)

    return () => {
      EventConversationClosed.unsubscribe(handler)
    }
  }, [])
}
