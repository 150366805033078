import { observer } from 'mobx-react-lite'
import { EmptyState } from 'shared/ui'
import workflowPreviewImg from 'shared/assets/images/workflow/workflow_preview.png'
import { getCDNAssetURL } from 'shared/lib'
import { WorkflowCreateButton } from 'pages/workflows/ui'

import styles from './styles.module.scss'

export const WorkflowEmpty = observer(() => (
  <div className={styles.empty}>
    <div className={styles.emptyPreview}>
      <img alt='Workflow preview' src={getCDNAssetURL(workflowPreviewImg)} />
    </div>
    <EmptyState
      noIcon
      boxProps={{
        height: 'auto',
      }}
      title='Create your first workflow'
      text='Send mass message to drive sales or for reminders'
      actions={<WorkflowCreateButton />}
    />
  </div>
))
