// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ca5qEvGaQ35hGRBPyMeE{display:flex;align-items:center;justify-content:center;margin-right:12px}.VWDffH8NtbtGQYjfKSIF{color:var(--content-primary-primary);display:flex;align-items:center;gap:8px;padding:8px 12px;cursor:pointer}.VWDffH8NtbtGQYjfKSIF *{pointer-events:none}.Rcv3DuhahnFnYf2UHlkv{display:flex;align-items:center;width:16px;height:10px}.tuRivSNMNrMM_zUl7yWq{transform:scale(0.5);transform-origin:left}.tuRivSNMNrMM_zUl7yWq.QiycBpLfE2c0xL7pUwvR{transform:scale(1)}", "",{"version":3,"sources":["webpack://./src/widgets/TriggerActions/ui/ActiveTriggerAction/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAGF,sBACE,oCAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,gBAAA,CACA,cAAA,CAEA,wBACE,mBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,oBAAA,CACA,qBAAA,CAEA,2CACE,kBAAA","sourcesContent":[".fullSizeContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 12px;\n}\n\n.activeAction {\n  color: var(--content-primary-primary);\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 12px;\n  cursor: pointer;\n\n  & * {\n    pointer-events: none;\n  }\n\n  &Control {\n    display: flex;\n    align-items: center;\n    width: 16px;\n    height: 10px;\n  }\n\n  &Switch {\n    transform: scale(0.5);\n    transform-origin: left;\n\n    &.fullSize {\n      transform: scale(1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullSizeContainer": "Ca5qEvGaQ35hGRBPyMeE",
	"activeAction": "VWDffH8NtbtGQYjfKSIF",
	"activeActionControl": "Rcv3DuhahnFnYf2UHlkv",
	"activeActionSwitch": "tuRivSNMNrMM_zUl7yWq",
	"fullSize": "QiycBpLfE2c0xL7pUwvR"
};
export default ___CSS_LOADER_EXPORT___;
