import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { BuyCreditsModalStore } from 'widgets/BuyCreditsModal'

type IBuyCreditsModalActionsProps = {
  buyCreditsModalStore: BuyCreditsModalStore
}

export const BuyCreditsModalActions: FC<IBuyCreditsModalActionsProps> = observer(
  ({ buyCreditsModalStore }) => {
    const { textSave, handleBuyCredits, disabled } = buyCreditsModalStore as BuyCreditsModalStore

    return (
      <Button
        typeBtn={'contained'}
        text={textSave}
        disabled={disabled}
        onClick={() => {
          handleBuyCredits()
        }}
      />
    )
  }
)
