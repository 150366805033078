import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Alert, DropdownPlaceholder } from 'shared/ui'
import { formatPhoneNumberNational } from 'shared/lib'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { type IContactPhone } from 'entities/Contacts'
import { type ContactChoosePhoneModalStore } from 'widgets/ContactChoosePhoneModal'
import styles from './styles.module.scss'

type IContactChoosePhoneModalContentProps = {
  store: ContactChoosePhoneModalStore
}

export const ContactChoosePhoneModalContent: FC<IContactChoosePhoneModalContentProps> = observer(
  ({ store }) => {
    const {
      options,
      initNumbers,
      activeLabel,
      activeField,
      activeType,
      numbers,
      handleChooseNumber,
      getNumberItemByType,
    } = store
    const contact = options?.contact

    const renderItem = (item: IContactPhone) => {
      return (
        <div className={styles.dropdown__item}>
          <span>{item.field}</span>: {formatPhoneNumberNational(item.number)}
        </div>
      )
    }

    useEffect(() => {
      initNumbers(renderItem)

      const phone = getNumberItemByType('phone')
      const mobilePhone = getNumberItemByType('mobilephone')

      if (phone) {
        handleChooseNumber('phone')
      } else if (mobilePhone) {
        handleChooseNumber('mobilephone')
      } else {
        handleChooseNumber('customphone')
      }
    }, [])

    useEffect(() => {
      initNumbers(renderItem)
    }, [activeType])

    if (!contact) return null

    return (
      <div className={classnames(styles.contentWrap)}>
        <Alert
          item={{
            desc: 'Select the phone number you want to use for this contact',
            type: 'infoLight',
          }}
        />
        <DropdownPlaceholder
          items={numbers}
          onChange={(item) => {
            handleChooseNumber(item.id)
          }}
          widthDropdown={440}
          placeholder={
            <div className={styles.dropdown__item}>
              <span>{activeField}</span>: {activeLabel}
            </div>
          }
          textPlaceholderProps={{
            variant: 'stroke',
            size: 'small',
            rightActions: [makeTextFieldIcon({ icon: 'chevronDown' })],
          }}
        />
      </div>
    )
  }
)
