import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IEmptyResponse,
  IParamsCreateContactReport,
  IParamsCreateCredits,
  IParamsCreateCustomPayment,
  IParamsCreateCustomPlanWithoutPaymentPlan,
  IParamsCreateLimits,
  IParamsCreateUsageReport,
  IParamsCustomPlanUpdate,
  IParamsGetCustomPayments,
  IParamsGetCustomPaymentsPreview,
  IParamsGetCustomPrices,
  IParamsGetOrganizations,
  IParamsTrialUpdate,
  IParamsUpdateOrganization,
  IResponseAdminOrganizationAssume,
  IResponseAdminOrganizationById,
  IResponseAdminOrganizations,
  IResponseCreateCustomPayment,
  IResponseCustomPayment,
  IResponseCustomPaymentPreview,
  IResponseCustomPrices,
  IParamsCreateOrganization,
  IParamsTaxFreeUpdate,
  IParamsUpdate10DLCExemptOptions,
} from 'entities/Admin/organizations/api/type'

class Api {
  getOrganizations(
    { organization_status = [], subscription_status = [], ...params }: IParamsGetOrganizations,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseAdminOrganizations>> {
    return baseApi.get('admin/core/organizations', {
      params: {
        ...organization_status.reduce<{ [key: string]: string }>((acc, status, currentIndex) => {
          acc[`organization_status[${currentIndex}]`] = status
          return acc
        }, {}),
        ...subscription_status.reduce<{ [key: string]: string }>((acc, status, currentIndex) => {
          acc[`subscription_status[${currentIndex}]`] = status
          return acc
        }, {}),
        ...params,
      },
      ...options,
    })
  }

  getOrganizationById(id: number): Promise<AxiosResponse<IResponseAdminOrganizationById>> {
    return baseApi.get(`admin/core/organizations/${id}`)
  }

  getCustomPrice(params?: IParamsGetCustomPrices): Promise<AxiosResponse<IResponseCustomPrices>> {
    return baseApi.get('admin/core/custom-price', { params })
  }

  createAssumeOrganization(id: number): Promise<AxiosResponse<IResponseAdminOrganizationAssume>> {
    return baseApi.post(`admin/core/organizations/${id}/assume`)
  }

  createAssumeUser(id: number): Promise<AxiosResponse<IResponseAdminOrganizationAssume>> {
    return baseApi.post(`admin/core/organizations/assume/${id}`)
  }

  createCredits({ id, data }: IParamsCreateCredits): Promise<AxiosResponse> {
    return baseApi.post(`admin/core/organizations/${id}/credits`, data)
  }

  ban(id: number): Promise<AxiosResponse> {
    return baseApi.get(`admin/core/organizations/${id}/ban`)
  }

  unban(id: number): Promise<AxiosResponse> {
    return baseApi.get(`admin/core/organizations/${id}/unban`)
  }

  cancelSubscription(id: number) {
    return baseApi.get(`admin/core/organizations/${id}/cancel`)
  }

  createLimits({ id, data }: IParamsCreateLimits): Promise<AxiosResponse> {
    return baseApi.post(`admin/core/organizations/${id}/limits`, data)
  }

  createContactReport({ id, data }: IParamsCreateContactReport): Promise<AxiosResponse> {
    return baseApi.post(`admin/core/organizations/${id}/contact-report`, data)
  }

  createUsageReport({ id, data }: IParamsCreateUsageReport): Promise<AxiosResponse> {
    return baseApi.post(`admin/core/organizations/${id}/usage-report`, data)
  }

  updateOrganization({
    id,
    data,
  }: IParamsUpdateOrganization): Promise<AxiosResponse<IResponseAdminOrganizationById>> {
    return baseApi.put(`admin/core/organizations/${id}/update`, data)
  }

  getCustomPayments({
    id,
  }: IParamsGetCustomPayments): Promise<AxiosResponse<IResponseCustomPayment>> {
    return baseApi.get(`admin/core/custom_payments/${id}`)
  }

  createCustomPlanWithoutPaymentPlan({
    organizationId,
    plan,
    seats,
    numbers,
    schedule,
    is_contract,
  }: IParamsCreateCustomPlanWithoutPaymentPlan): Promise<AxiosResponse<void>> {
    return baseApi.post(`admin/core/subscriptions/update/${organizationId}`, {
      plan,
      seats,
      numbers,
      schedule,
      is_contract,
    })
  }

  createOrganization(data: IParamsCreateOrganization): Promise<AxiosResponse<void>> {
    return baseApi.post('admin/core/organizations/accounts/create', data)
  }

  createCustomPlan({
    organizationId,
    customPlan,
    is_contract,
  }: IParamsCreateCustomPayment): Promise<AxiosResponse<IResponseCreateCustomPayment>> {
    return baseApi.post(`admin/core/custom_payments/${organizationId}`, {
      ...customPlan,
      is_contract,
    })
  }

  getPlanPreview({
    organizationId,
    plan,
    seats,
    numbers,
    with_prorate,
  }: IParamsGetCustomPaymentsPreview): Promise<
    AxiosResponse<IResponseCustomPaymentPreview | IEmptyResponse>
  > {
    return baseApi.get(`admin/core/custom_payments/preview/${organizationId}`, {
      params: {
        plan,
        seats,
        number_seats: numbers,
        with_prorate,
      },
    })
  }

  retryPayment(paymentId: string): Promise<AxiosResponse<void>> {
    return baseApi.post(`admin/core/custom_payments/retry/${paymentId}`)
  }

  updatePayments(
    organizationId: number,
    data: IParamsCustomPlanUpdate
  ): Promise<AxiosResponse<void>> {
    return baseApi.put(`admin/core/custom_payments/${organizationId}`, data)
  }

  updateTrial(organizationId: number, data: IParamsTrialUpdate): Promise<AxiosResponse<void>> {
    return baseApi.post(`admin/core/subscriptions/trial/manage/${organizationId}`, data)
  }

  updateTaxFree(id: number, data: IParamsTaxFreeUpdate): Promise<AxiosResponse<void>> {
    return baseApi.post(`admin/core/organizations/${id}/tax_free`, data)
  }

  update10DLCExemptOptions({
    id,
    data,
  }: IParamsUpdate10DLCExemptOptions): Promise<AxiosResponse<IResponseAdminOrganizationById>> {
    return baseApi.put(`admin/core/organizations/${id}/10_dlc_exempt_options`, data)
  }
}

export const AdminOrganizationsApi = new Api()
