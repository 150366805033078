import { makeAutoObservable, reaction, IReactionDisposer } from 'mobx'
import { Call } from '@twilio/voice-sdk'
import { callStore, IResponseCallQueueParticipant } from 'entities/Call'
import { numbersStore } from 'entities/Phone'
import { userSettingsStore } from 'entities/Settings'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { Contact } from 'entities/Contacts/model/Contact'
import { Phone } from 'entities/Phone/model/Phone'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { ICallV1BroadcastMessage } from 'widgets/CallPopUp'
import { CallTeam } from 'widgets/CallPopUp/model/CallTeam'
import { getPowerDialerSession } from 'widgets/CallPopUp/store/callPopUpPowerDialerSessionStore'

export class CallPopUpGlobalStore {
  private _itemsTeamsMap: Map<number, CallTeam> = new Map()
  private _disposeReactionSignal: IReactionDisposer | null = null
  private _disposeReactionStatus: IReactionDisposer | null = null
  private _signalTakeCall = false
  private _hasActiveV1Call = false

  constructor() {
    this.reactionSignal()
    this.reactionStatus()
    const chanel = new BroadcastChannel('v1-call-broadcast')

    chanel.onmessage = this.handleBroadcastMessage

    makeAutoObservable(this)

    getPowerDialerSession().then((data) => {
      if (!data) return

      const item = this.addPowerDialerItem(data.team, data.number, data.items)

      if (item) {
        item.store.callPopUpPowerDialerStore.setPause(true)
      }
    })
  }

  reactionSignal = () => {
    this._disposeReactionSignal?.()
    this._disposeReactionSignal = reaction(
      () => callStore.signal,
      (signal) => {
        if (!signal) return
        if (signal.name === 'incoming' || signal.name === 'outgoing') {
          this.handleSignalTakeCall(false)

          const team = callStore.contactFrom
          const contact = callStore.contactTo
          const number = numbersStore.getItem(team?.numberId)

          if (!team) return
          if (!contact) return
          if (!number) return

          const item = this.addDefaultItem(team, contact)

          if (item) {
            this.items.forEach((itemTeam) => {
              if (item.id === itemTeam.id) {
                itemTeam.setLocalStatusCall(callStore.status)
              } else {
                itemTeam.setLocalStatusCall(Call.State.Closed)
              }
            })

            item.setStatusCall(callStore.status)
          }
        }
      }
    )
  }

  reactionStatus = () => {
    this._disposeReactionStatus?.()
    this._disposeReactionStatus = reaction(
      () => callStore.status,
      (value) => {
        if (!value) return

        this.items.forEach((item) => {
          item.setStatusCall(value)
        })

        const team = callStore.contactFrom
        const item = this.getItem(team?.id)
        if (item) {
          this.items.forEach((itemTeam) => {
            if (item.id === itemTeam.id) {
              itemTeam.setLocalStatusCall(value)
            } else {
              itemTeam.setLocalStatusCall(Call.State.Closed)
            }
          })

          if (value === Call.State.Closed) {
            if (item.hasItemsPowerDialer) return
            if (item.hasItemsQueue) {
              item.setView('queue')

              return
            }

            if (team) {
              item.resetAll()
              this._itemsTeamsMap.delete(team.id)
            }
          }
        }
      }
    )
  }

  handleSignalTakeCall = (status: boolean) => {
    this._signalTakeCall = status
  }

  handleBroadcastMessage = (event: MessageEvent<ICallV1BroadcastMessage>): void => {
    if (event.data.message === 'call_start') {
      this.setActiveV1Call(true)
    }

    if (event.data.message === 'call_end') {
      this.setActiveV1Call(false)
    }
  }

  setActiveV1Call = (activeV1Call: boolean): void => {
    this._hasActiveV1Call = activeV1Call
  }

  addDefaultItem = (team: Inbox, contact: Contact) => {
    let item = this.getItem(team.id)

    if (!item) {
      const number = numbersStore.getItem(team.numberId)

      if (!number) return

      item = this.addItem(
        new CallTeam({
          id: team.id,
          name: team.name,
          number: number.formatted_number,
        })
      )
    }

    if (!item) return

    item.setContactFrom(team)
    item.setContactTo(contact)
    item.setView('default')
    item.setStatusCall(callStore.status)

    return item
  }

  addPowerDialerItem = (team: Inbox, number: Phone, items: Contact[]) => {
    let item = this.getItem(team.id)

    if (!item) {
      item = new CallTeam({
        id: team.id,
        name: team.name,
        number: number.formatted_number,
      })
    }

    item.setSyncCachePowerDialer(team, number, items)
    item.setView('default')
    item.setContactFrom(team)
    item.addItemsPowerDialer(items)
    item.addOptionsPowerDialer({
      removePowerDialer: () => {
        if (!callStore.isStatusClose) return
        if (item?.hasItemsPowerDialer) return
        if (item?.hasItemsQueue) {
          item?.setView('queue')
          return
        }

        this._itemsTeamsMap.delete(team.id)
      },
      team: team,
      number: number,
      defaultStore: item.store,
    })

    return this.addItem(item)
  }

  addQueueItem = (itemResponse: IResponseCallQueueParticipant) => {
    const item = this.getItem(itemResponse.team.id)

    if (item) {
      if (!item.hasItemQueue) {
        item.setItemQueue(itemResponse)
      }

      if (itemResponse.contact) {
        item.addItemQueue({
          contact: itemResponse.contact,
          call: itemResponse.call,
        })
      }
    } else {
      const item = new CallTeam({
        ...itemResponse.team,
      })

      item.setView('queue')
      item.setItemQueue(itemResponse)

      if (itemResponse.contact) {
        item.addItemQueue({
          contact: itemResponse.contact,
          call: itemResponse.call,
        })
      }

      this.addItem(item)
    }
  }

  addItem = (item: CallTeam) => {
    this._itemsTeamsMap.set(item.id, item)

    return item
  }

  getItem = (id?: number) => {
    if (!id) return

    return this._itemsTeamsMap.get(id)
  }

  deleteItem = (itemResponse: IResponseCallQueueParticipant) => {
    const item = this.getItem(itemResponse.team.id)

    if (!item) return

    if (itemResponse.contact) {
      item.store.callPopUpQueueStore.deleteItem(itemResponse.contact.id)
    }

    const isDesignV2 = userSettingsStore.isSalesmsgDesignV2
    const isNotDevice = isDesignV2 ? true : !callStore.device
    const isNotSignalTakeCall = isDesignV2 ? !this._signalTakeCall : true
    const isNotItems = !item.store.callPopUpQueueStore.items.length
    const isViewQueue = item.store.view === 'queue'

    if (isNotSignalTakeCall && isNotDevice && isNotItems && isViewQueue) {
      this._itemsTeamsMap.delete(itemResponse.team.id)
    }
  }

  get items() {
    return Array.from(this._itemsTeamsMap.values())
  }

  get itemsSort() {
    const sortCallState = {
      [Call.State.Connecting]: 0,
      [Call.State.Open]: 0,
      [Call.State.Pending]: 0,
      [Call.State.Reconnecting]: 0,
      [Call.State.Ringing]: 0,
      [Call.State.Closed]: 1,
    }
    const sortTypeView = {
      default: 0,
      queue: 1,
    }

    return this.items
      .sort((a, b) => {
        const aValue = sortTypeView[a.view]
        const bValue = sortTypeView[b.view]

        return aValue - bValue
      })
      .sort((a, b) => {
        const aValue = sortCallState[a.status]
        const bValue = sortCallState[b.status]

        return aValue - bValue
      })
  }

  get hasActiveV1Call() {
    return this._hasActiveV1Call
  }

  get hasPowerDialer() {
    return this.items.some((item) => item.store.callPopUpPowerDialerStore.hasItems)
  }

  get hasQueue() {
    return this.items.some((item) => item.store.callPopUpQueueStore.hasItems)
  }
}

export const callPopUpGlobalStore = new CallPopUpGlobalStore()
