import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import {
  IParamsCreateCustomVoice,
  IParamsCreateSpeech,
  IParamsGetSpeechPreview,
  IResponseCreateCustomVoice,
  IResponseCreateSpeechPreview,
  IResponseVoicePreview,
  IResponseVoices,
  IVoiceType,
} from 'entities/TextToSpeech'

class Api {
  getBasicVoices(): Promise<AxiosResponse<IResponseVoices>> {
    return baseApi.get('smart-tts/voices/basic')
  }
  getPremiumVoices(): Promise<AxiosResponse<IResponseVoices>> {
    return baseApi.get('smart-tts/voices/premium')
  }
  getCustomVoices(): Promise<AxiosResponse<IResponseVoices>> {
    return baseApi.get('smart-tts/voices/custom')
  }

  getVoicePreview(id: string, type: IVoiceType): Promise<AxiosResponse<IResponseVoicePreview>> {
    return baseApi.get(`smart-tts/voices/${type}/${id}/url`)
  }

  getSpeechPreview(params: IParamsGetSpeechPreview): Promise<AxiosResponse<File>> {
    const formData = new FormData()
    formData.append('voice_id', params.voice_id)
    formData.append('text', params.text)
    return baseApi.post('smart-tts/speeches/preview', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'audio/mpeg',
      },
      responseType: 'blob',
    })
  }
  createSpeech(params: IParamsCreateSpeech): Promise<AxiosResponse<IResponseCreateSpeechPreview>> {
    const formData = new FormData()
    formData.append('voice_id', params.voice_id)
    formData.append('text', params.text)
    formData.append('group', params.group)
    formData.append('title', params.title)
    return baseApi.post('smart-tts/speeches', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        accept: 'audio/mpeg',
      },
    })
  }

  streamSpeech(id: string): Promise<AxiosResponse<File>> {
    return baseApi.get(`smart-tts/speeches/${id}/stream`, {
      headers: {
        accept: 'audio/mpeg',
      },
      responseType: 'blob',
    })
  }

  createCustomVoice(
    params: IParamsCreateCustomVoice
  ): Promise<AxiosResponse<IResponseCreateCustomVoice>> {
    const formData = new FormData()
    formData.append('name', params.name)
    formData.append('sample', params.sample)
    return baseApi.post('smart-tts/voices/custom', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  deleteCustomVoice(voice_id: string): Promise<void> {
    return baseApi.delete(`smart-tts/voices/custom/${voice_id}`)
  }
}

export const TextToSpeechApi = new Api()
