import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { usersStore } from 'entities/Users'
import { ContactsApi } from 'entities/Contacts'
import { exportStore } from 'widgets/Export/store/ExportStore'
import { useContactsTableContext } from 'widgets/ContactsTable'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import styles from './styles.module.scss'

export const ExportButton = observer(() => {
  const { user } = usersStore
  const activeFields = user ? user.activeFields : []
  const contactsTableStore = useContactsTableContext()
  const { total, tableStore } = contactsTableStore
  const { selectedIds, bulkAllMode } = tableStore
  const maxCount = 100_000

  const showDownload =
    selectedIds.length && !bulkAllMode ? selectedIds.length < maxCount : total < maxCount
  const { headerName } = contactsFilterStore

  const getTitle = () => {
    if (!selectedIds.length || bulkAllMode) {
      return `Export -> ${headerName}`
    }

    return `Export -> ${selectedIds.length === 1 ? '1 contact' : `${selectedIds.length} contacts`}`
  }

  return (
    <Button
      icon='download'
      text='Export'
      typeBtn='contained'
      contained='tertiary'
      size='small'
      className={styles.exportButton}
      onClick={() => {
        exportStore.getExport({
          promise: ContactsApi.getExport({
            ...contactsTableStore.expandedParams,
            contact_columns: activeFields,
            contactIds: selectedIds as number[],
            ...(bulkAllMode && { contactIds: [] }),
          }),
          showDownload,
          preparingTitle: getTitle(),
          successTitle: getTitle(),
        })
      }}
    />
  )
})
