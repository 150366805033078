import { observer } from 'mobx-react-lite'
import parse from 'html-react-parser'
import React, { FC } from 'react'
import classnames from 'classnames'
import { Button, Icon, Space, TypingIndicator, Typography } from 'shared/ui'
import { textToHtml } from 'widgets/MessageField'
import { AiAssistantStore } from 'widgets/AIAssistant/store/aiAssistantStore'
import styles from './styles.module.scss'

interface IAnswerContentProps {
  aiAssistantStore: AiAssistantStore
}
export const AnswerContent: FC<IAnswerContentProps> = observer(({ aiAssistantStore }) => {
  const { answer, onLike, onTryAgain, onClose, loadingTryAgain, actionType, isColumn } =
    aiAssistantStore
  return (
    <div className={styles.wrap}>
      <div
        className={classnames(styles.header, {
          [styles['header--short']]: isColumn,
        })}
      >
        <div className={'row4'}>
          <Icon icon={'stars'} fontSize={16} color={'var(--content-primary-tertiary)'} />
          <Typography variant={'body-sm-medium'} color={'tertiary'} ariaLabel={'GeneratingText'}>
            {loadingTryAgain ? 'Generating text' : actionType}
          </Typography>
          {loadingTryAgain && <TypingIndicator marginLeft={4} />}
        </div>
        {isColumn && <Space height={10} />}
        <div className={'row4'}>
          <Button
            icon={'check1'}
            text={'I like it'}
            size={'small'}
            contained={'secondary'}
            onClick={onLike}
            disabled={loadingTryAgain}
          />
          <Button
            icon={'refresh'}
            text={'Try again'}
            size={'small'}
            contained={'secondary'}
            onClick={onTryAgain}
            disabled={loadingTryAgain}
          />
          <Button
            icon={'close'}
            text={'Cancel'}
            size={'small'}
            contained={'secondary'}
            onClick={onClose}
          />
        </div>
      </div>

      <Typography
        variant={'body-rg-regular'}
        className={styles.response}
        tag={'div'}
        color={loadingTryAgain ? 'var(--content-primary-disabled)' : undefined}
        ariaLabel={'answer'}
      >
        {parse(textToHtml({ text: answer, replaceNewRow: true }))}
      </Typography>
    </div>
  )
})
