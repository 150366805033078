import { useRef, FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Avatar } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type IConversationCardNewProps = {
  isActive?: boolean
}

export const ConversationNewCard: FC<IConversationCardNewProps> = observer(({ isActive }) => {
  const refAvatar = useRef<HTMLDivElement>(null)

  return (
    <div
      className={classnames(styles.wrap, styles['wrap--new'], {
        [styles.active]: isActive,
      })}
      aria-label={getAriaLabel('ConversationCardNew')}
    >
      <div ref={refAvatar} className={styles.avatar}>
        <div className={styles.renderAvatar}>
          <Avatar
            size={32}
            info={{
              icon: 'personFilled',
            }}
          />
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.username} aria-label={getAriaLabel('ConversationCardNew_name')}>
          New conversation
        </div>
      </div>
    </div>
  )
})
