import { useEffect, useMemo } from 'react'
import { ContainerHeader } from 'shared/ui'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'
import { ConversationSearchContent } from 'widgets/ConversationNew/ui/ConversationSearch/ConversationSearchContent'
import { ConversationChooseNumber } from 'widgets/ConversationNew/ui/ConversationChooseNumber/ConversationChooseNumber'

export const ConversationSearch = () => {
  const store = useConversationNewSearchContext()

  useEffect(() => {
    return () => {
      store.reset()
      store.clearReactions()
    }
  }, [])

  return useMemo(
    () => (
      <>
        <ContainerHeader
          type={'container'}
          container={<ConversationSearchContent />}
          isBorderBottom
        />
        <ConversationChooseNumber />
      </>
    ),
    []
  )
}
