import { useParams } from 'react-router-dom'
import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { ChatbotDialogStore } from 'pages/chatbot'

const ChatbotDialogContext = createContext<ChatbotDialogStore | null>(null)

export const useChatbotDialogContext = () => {
  const context = useContext(ChatbotDialogContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ChatbotDialogProvider')

  return context
}

export const ChatbotDialogProvider = ({ children }: PropsWithChildren) => {
  const { chatbotId } = useParams()
  const store = useMemo(() => {
    if (chatbotId == null || isNaN(+chatbotId))
      throw new Error('To test chatbot you need to provide chatbot id')

    return new ChatbotDialogStore(+chatbotId)
  }, [])

  useEffect(() => store.dispose, [])

  return <ChatbotDialogContext.Provider value={store}>{children}</ChatbotDialogContext.Provider>
}
