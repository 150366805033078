import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownContent } from 'shared/ui'
import { useSearchDropdownContext } from 'widgets/SearchDropdown'
import styles from './styles.module.scss'
import { InitDropdownContent } from './InitDropdownContent'
import { FiltersDropdownContent } from './FiltersDropdownContent'

type MainDropdownContentProps = {
  onCloseMenu?: () => void
  parentsDropdownId?: string[]
}

export const MainDropdownContent: FC<MainDropdownContentProps> = observer(
  ({ parentsDropdownId }) => {
    const { hasFilters } = useSearchDropdownContext()

    if (hasFilters) {
      return (
        <DropdownContent noPadding className={styles.filtersDropdownContent}>
          <FiltersDropdownContent parentsDropdownId={parentsDropdownId} />
        </DropdownContent>
      )
    }

    return (
      <DropdownContent noPadding>
        <InitDropdownContent />
      </DropdownContent>
    )
  }
)
