import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ITabItem, Tabs, Typography } from 'shared/ui'
import { TenDLCTab } from 'pages/admin/pages/organizations/pages/OrganizationDetails/ui/Compliance/tabs/TenDLCTab'
import { TollFreeTab } from 'pages/admin/pages/organizations/pages/OrganizationDetails/ui/Compliance/tabs/TollFreeTab'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import styles from './styles.module.scss'

export const Compliance = () => {
  const { id } = useParams()
  const [activeTabKey, setActiveTabKey] = useState('10dlc')
  const handleSelectTab = (tab: ITabItem) => {
    setActiveTabKey(tab.key)
  }

  useEffect(() => {
    adminTenDLCDetailStore.reset()
    if (id) {
      adminTenDLCDetailStore.setId(+id)
    }
  }, [id])
  return (
    <div className={styles.wrap}>
      <Typography variant={'heading-sm'} marginBottom={20} tag={'div'} ariaLabel={'Compliance'}>
        Compliance
      </Typography>
      <Tabs
        tabs={[
          {
            key: '10dlc',
            name: '10DLC',
          },
          {
            key: 'toll_free',
            name: 'Toll-free',
          },
        ]}
        activeTabKey={activeTabKey}
        handleSelectTab={handleSelectTab}
      />
      <div className={styles.wrapTab}>
        {activeTabKey === '10dlc' && <TenDLCTab />}
        {activeTabKey === 'toll_free' && <TollFreeTab />}
      </div>
    </div>
  )
}
