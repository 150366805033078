import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { capitalize } from 'lodash'
import { Button, Dropdown, DropdownContent, IDropdownItem } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { IInputNumericProps, InputNumeric, TextField } from 'shared/ui/TextField'
import { IPeriod } from 'shared/lib'
import { useRecurringContext } from 'shared/ui/Schedule/ui/Recurring/context/recurringContext'
import {
  MonthDaysSelector,
  MonthDaysSelectorStore,
  WeekDaysSelector,
  WeekDaysSelectorStore,
} from 'shared/ui/Schedule'
import styles from './styles.module.scss'

type ICustomContentProps = {
  onCloseMenu: () => void
  dropdownId: string
}

export const CustomContent = observer(({ onCloseMenu, dropdownId }: ICustomContentProps) => {
  const {
    dropdownId: dropdownScheduleId,
    customInterval,
    customPeriod,
    intervalItems,
    getPeriodLabel,
    onConfirmCustom,
    monthDaysSelectorStore,
    advancedSchedulingStore,
  } = useRecurringContext()

  const [interval, setInterval] = useState(customInterval)
  const [period, setPeriod] = useState(customPeriod)
  const [weekDaysSelectorStoreLocal] = useState(
    () => new WeekDaysSelectorStore(advancedSchedulingStore.weekDaysSelectorStore.props)
  )
  const [monthDaysSelectorStoreLocal] = useState(
    () => new MonthDaysSelectorStore(monthDaysSelectorStore.props)
  )

  const onBlur = () => {
    if (!Number(interval)) {
      setInterval('1')
    }
  }

  const handleConfirm = () => {
    onConfirmCustom({
      interval,
      period,
      weekDaysSelectorStoreProps: weekDaysSelectorStoreLocal.props,
      monthDaysSelectorStoreProps: monthDaysSelectorStoreLocal.props,
    })
    onCloseMenu()
  }

  const handleChangePeriod = (item: IDropdownItem) => {
    setPeriod(item.id as IPeriod)
  }

  return (
    <DropdownContent noPadding>
      <div className={styles.content}>
        <Label
          label={{
            text: 'Repeat every',
            className: styles.label,
            textClassName: styles.textLabel,
          }}
        />
        <TextField
          variant={'outline'}
          value={interval}
          onChange={setInterval}
          Input={InputNumeric}
          InputProps={{ decimalSeparator: '', maxLength: 12 } as IInputNumericProps}
          className={styles.input}
          onBlur={onBlur}
        />
        <Dropdown
          ariaLabel={'CustomContent'}
          parentsDropdownId={[dropdownId, dropdownScheduleId]}
          typeButton={'button'}
          width={113}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  offset: ({ reference }) => {
                    return [0, -reference.height - 8]
                  },
                },
              },
            ],
          }}
          buttonProps={{
            textClassname: styles.text,
            className: styles.btn,
            text: capitalize(getPeriodLabel(period, interval)),
            typeBtn: 'menuItem',
            fontWeight: 400,
            icon: undefined,
            iconRightProps: {
              icon: 'chevronDown',
            },
          }}
          items={intervalItems}
          onChange={handleChangePeriod}
        />
      </div>
      {period === 'weekly' && (
        <div className={styles.additionalContent}>
          <WeekDaysSelector store={weekDaysSelectorStoreLocal} />
        </div>
      )}
      {period === 'monthly' && (
        <div className={styles.additionalContent}>
          <MonthDaysSelector
            store={monthDaysSelectorStoreLocal}
            parentsDropdownId={[dropdownId, dropdownScheduleId]}
          />
        </div>
      )}
      <div className={styles.actions}>
        <Button text={'Cancel'} fullWidth contained={'secondary'} onClick={onCloseMenu} />
        <Button text={'Confirm'} fullWidth contained={'primary'} onClick={handleConfirm} />
      </div>
    </DropdownContent>
  )
})
