import { makeAutoObservable } from 'mobx'
import { IResponseNumber } from 'entities/Phone/api/types'

export class NumberTableModel {
  id: number
  origin: IResponseNumber

  constructor(item: IResponseNumber) {
    this.id = item.id
    this.origin = item
    makeAutoObservable(this)
  }

  get info() {
    const { formattedNumber, city, state, type, numberable, area_code } = this.origin
    const title = formattedNumber
    const tooltip = type === 'Short Code' && numberable?.name ? numberable.name : undefined
    let label = undefined
    if (city && state) {
      label = `${city}, ${state}`
    }
    if (!label && area_code?.state_name && area_code?.state_code) {
      label = `${area_code.state_name}, ${area_code.area_code}`
    }
    return {
      title,
      label,
      tooltip,
    }
  }

  get nationalNumber() {
    return this.origin.national_number || ''
  }

  get inboxName() {
    return this.itemInbox?.name.toLocaleLowerCase() || ''
  }

  get itemInbox() {
    if (this.origin.numberable_type === 'App\\Numbers\\Shortcode') {
      return this.origin.numberable && this.origin.numberable.team
        ? this.origin.numberable?.team
        : null
    }

    return this.origin.numberable
  }

  get status() {
    if (this.isTollFree) {
      return this.origin.verified_status?.toLowerCase()
    }

    if (this.isLandline) {
      const landlineStatus = this.origin.landline?.status
      if (landlineStatus === 'completed') {
        return 'verified'
      }
      if (landlineStatus === 'failed') {
        return 'failed'
      }
      return 'pending'
    }

    return ''
  }

  get labelType() {
    if (this.isLandline) {
      return 'Landline'
    }

    if (this.isZipwhip) {
      return 'ZipWhip'
    }

    if (this.isTollFree) {
      return 'Toll-Free'
    }

    if (this.isAircall) {
      return 'Aircall'
    }

    if (this.isShortCode) {
      return 'Short Code'
    }

    return 'Local'
  }

  get isLandline() {
    return Boolean(this.origin.is_landline && this.origin.landline && !this.origin.is_zipwhip)
  }

  get isZipwhip() {
    return this.origin.is_landline && this.origin.landline && this.origin.is_zipwhip
  }

  get isTollFree() {
    return this.origin.is_toll_free
  }

  get isAircall() {
    return Boolean(this.origin.is_aircall)
  }

  get isShortCode() {
    return Boolean(this.origin.short_code)
  }

  get isLocal() {
    return !this.isLandline && !this.isTollFree && !this.isAircall && !this.isShortCode
  }
}
