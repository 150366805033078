import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type TwoFaModalStore } from 'widgets/TwoFaModal/store/twoFaModalStore'

type IProps = {
  store: TwoFaModalStore
}

export const TwoFaCodeModalActions = observer(({ store }: IProps) => {
  const { loading, onVerifyCode, isValidTwoFaCode, disabledCodeResend } = store

  return (
    <Button
      text='Verify'
      disabled={loading || !isValidTwoFaCode || disabledCodeResend}
      onClick={onVerifyCode}
    />
  )
})
