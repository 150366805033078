import { capitalize } from 'lodash'
import { IDropdownItem } from 'shared/ui'
import { periodsMap } from 'shared/lib'

export type IRepeatPeriod = 'daily' | 'weekly' | 'monthly' | 'custom'

export const repeatItemsObj: { [key in IRepeatPeriod]: IDropdownItem } = {
  daily: {
    id: 'daily',
    label: 'Every day',
  },
  weekly: {
    id: 'weekly',
    label: 'Every week',
  },
  monthly: {
    id: 'monthly',
    label: 'Every month',
  },
  custom: {
    id: 'custom',
    label: 'Custom',
    disabledClosedTooltip: true,
  },
}

export const repeatItems = Object.values(repeatItemsObj)

export type IEndRepeat = 'never' | 'on' | 'after'

export const endRepeatItemsObj: { [key in IEndRepeat]: IDropdownItem } = {
  never: {
    id: 'never',
    label: 'Never ends',
  },
  on: {
    id: 'on',
    label: 'On date',
    disabledClosedTooltip: true,
  },
  after: {
    id: 'after',
    label: 'After',
    disabledClosedTooltip: true,
  },
}

export const endRepeatItems = Object.values(endRepeatItemsObj)

export const intervalItems: IDropdownItem[] = Object.entries(periodsMap).map(([key, value]) => ({
  label: capitalize(value),
  id: key,
}))
