import { observer } from 'mobx-react-lite'

import { useDetailsContext } from '../detailsContext'
import { ActionList } from './ActionList'
import styles from './styles.module.scss'

export const TabActions = observer(() => {
  const { data } = useDetailsContext()

  return (
    <div className={styles.actionCards}>
      <ActionList name='Success' actions={data.success_actions} />
      <ActionList name='Fallback' actions={data.fallback_actions} />
    </div>
  )
})
