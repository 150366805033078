import { Navigate, Route, Routes } from 'react-router-dom'
import {
  ChatbotEditPage,
  ChatbotNewPage,
  ChatbotListPage,
  ChatbotAnalyticPage,
} from 'pages/chatbot/pages'
import { ChatbotLayout } from 'pages/chatbot/layout'
import { ChatbotAccessLayout } from 'pages/chatbot/layout/ChatbotAccessLayout'
import { ChatbotRoutes } from './type'
import { AgentBookAppointmentStepsRouter } from './agentBookAppointmentStepsRouter'

export const ChatbotRouter = () => {
  return (
    <Routes>
      <Route element={<ChatbotAccessLayout />}>
        <Route element={<ChatbotLayout />}>
          <Route index element={<Navigate to={ChatbotRoutes.all} />} />
          <Route path={`${ChatbotRoutes.all}`} element={<ChatbotListPage />} />
          <Route
            path={`/:agentId/${ChatbotRoutes.steps}/*`}
            element={<AgentBookAppointmentStepsRouter />}
          />
          <Route
            path={`/${ChatbotRoutes.new}/${ChatbotRoutes.steps}/*`}
            element={<AgentBookAppointmentStepsRouter />}
          />
          <Route path={`${ChatbotRoutes.new}`} element={<ChatbotNewPage />} />
          <Route path=':chatbotId' element={<ChatbotEditPage />} />
          <Route path={`:chatbotId/${ChatbotRoutes.analytic}`} element={<ChatbotAnalyticPage />} />
          <Route path='*' element={<div>Page Not Found</div>} />
        </Route>
      </Route>
    </Routes>
  )
}
