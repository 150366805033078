import { makeAutoObservable } from 'mobx'

export class ErrorStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _fieldsError: Record<string, string> | null = null

  get fieldsError() {
    return this._fieldsError
  }

  get fieldsErrorCommonText() {
    if (this.fieldsError && Object.keys(this.fieldsError).length) {
      return Object.values(this.fieldsError).map((error, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>
          {error}
        </div>
      ))
    }

    return null
  }

  setFieldsError = (fieldError: Record<string, string> | null) => {
    this._fieldsError = fieldError
  }

  prepareFieldsErrorAndSave = (data: Record<string, string>) => {
    const result = {} as Record<string, string>

    Object.keys(data).forEach((key) => {
      let value = data[key]

      if (Array.isArray(value)) {
        value = value[0]
      }

      if (key.startsWith('business_information.') || key.startsWith('points_of_contact')) {
        const newKey = key.replace('business_information.', '').replace('points_of_contact.', '')
        result[newKey] = value
      } else {
        result[key] = value
      }
    })

    this.setFieldsError(result)
  }

  deleteErrorValue = (keyName: string) => {
    if (this.fieldsError) {
      delete this.fieldsError[keyName]
    }
  }

  reset = () => {
    this._fieldsError = null
  }
}
