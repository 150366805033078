import { observer } from 'mobx-react-lite'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import { CommonGeneralInfo } from 'pages/settings/pages/compliance/ui/CommonGeneralInfo/CommonGeneralInfo'

export const GeneralInfo = observer(() => {
  const {
    stepLoading,
    generalInfoStore,
    errorStore,
    errorMessage,
    complianceLayerStore,
    generalInfoNextStepClick,
  } = useBusinessProfileStepsContext()

  return (
    <CommonGeneralInfo
      stepLoading={stepLoading}
      generalInfoStore={generalInfoStore}
      fieldsError={errorStore.fieldsError}
      errorMessage={errorMessage}
      fieldsErrorCommonText={errorStore.fieldsErrorCommonText}
      nextStepClick={generalInfoNextStepClick}
      openDoNotHaveEINModal={complianceLayerStore.openDoNotHaveEINModal}
    />
  )
})
