// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HyrRtO4zyx5oTEJjJmwj{padding-left:20px;margin-bottom:32px}.psMlkfF1QClMd8Mcg8Uq{min-height:unset;padding:16px 20px 12px}", "",{"version":3,"sources":["webpack://./src/pages/powerDialer/pages/view/ui/PowerDialerViewHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CACA,sBAAA","sourcesContent":[".statusBadge {\n  padding-left: 20px;\n  margin-bottom: 32px;\n}\n\n.header {\n  min-height: unset;\n  padding: 16px 20px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusBadge": "HyrRtO4zyx5oTEJjJmwj",
	"header": "psMlkfF1QClMd8Mcg8Uq"
};
export default ___CSS_LOADER_EXPORT___;
