import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { ChatbotActionTag } from 'entities/Chatbot'
import { TagsControl } from 'entities/Tags'

export class ChatbotActionTagStore {
  store!: TagsControl

  private _disposeIds: IReactionDisposer | null = null

  constructor(private _model: ChatbotActionTag) {
    this.store = new TagsControl(_model.tagIds)

    makeAutoObservable(this)

    this._disposeIds = reaction(
      () => this.store.ids,
      (ids) => this._model.syncIds(ids)
    )
  }

  dispose = () => {
    this._disposeIds?.()
  }
}
