import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { ChatbotDetailsStore } from 'pages/chatbot'

const ChatbotDetailsContext = createContext<ChatbotDetailsStore | null>(null)

export const useChatbotDetailsContext = () => {
  const context = useContext(ChatbotDetailsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ChatbotDetailsProvider')

  return context
}

export const ChatbotDetailsProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new ChatbotDetailsStore(), [])

  return <ChatbotDetailsContext.Provider value={store}>{children}</ChatbotDetailsContext.Provider>
}
