import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Icon, IIconsVector, Typography } from 'shared/ui'

export type INameValueRecordProps = {
  icon?: IIconsVector
  name?: string
  value?: string
}

export const NameValueRecord = observer(({ icon, name, value }: INameValueRecordProps) => {
  if (!name?.trim() && !value?.trim())
    return (
      <Typography
        variant='body-md-regular'
        color='var(--content-primary-disabled'
        ariaLabel='EmptyText'
      >
        —
      </Typography>
    )

  return (
    <Box display='flex' alignItems='center' gap='8px'>
      <Icon icon={icon} fontSize={16} tertiary />
      {!!name && (
        <>
          <Typography variant='body-md-regular' ellipsis ariaLabel='name'>
            {name}
          </Typography>
        </>
      )}
      {!!name && !!value && <div className='point' />}
      {value && (
        <Typography variant='body-md-regular' color='tertiary' ariaLabel='value'>
          {value}
        </Typography>
      )}
    </Box>
  )
})
