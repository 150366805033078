import { makeAutoObservable, runInAction, reaction } from 'mobx'
import { channelsInboxes, IInboxCombine, InboxesApi } from 'entities/Inbox'
import { UsersApi, usersStore } from 'entities/Users'
import { numbersStore } from 'entities/Phone'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { IResponseOwnerUser } from 'entities/Users/api/types'
import { IResponseEventInboxCalling, IResponseInboxCombine } from 'entities/Inbox/api/types'
import { UnifiedInbox } from 'entities/Inbox/model/UnifiedInbox'
import { GroupInbox } from 'entities/Inbox/model/GroupInbox'

class InboxesStore {
  constructor() {
    makeAutoObservable(this)

    this.reactionCurrentInboxId()
  }

  loading = false
  inboxesMap: Map<number | string, IInboxCombine> = new Map()
  currentInboxId: number | string | null = null
  currentInboxType: number | string | null = null
  currentInboxTypeId: number | string | null = null
  private _disposeCurrentInboxId: ReturnType<typeof reaction> | null = null
  inboxesLoadingIds: Set<number> = new Set()

  fetchInboxes = async () => {
    if (this.inboxesList.length || this.loading) return

    runInAction(() => {
      this.loading = true
    })

    const [userData] = await Promise.all([
      UsersApi.getUser(),
      this.fetchSmartInboxes(true),
      this.fetchGroupInboxes(),
    ])

    const user = userData.data

    channelsInboxes.subscribeChannels()

    runInAction(() => {
      this.initInboxCurrent(user)
      this.loading = false
    })
  }

  fetchSmartInboxes = async (withUnified?: boolean) => {
    try {
      const { data } = await InboxesApi.getInboxesPersonal({
        withUnified: withUnified,
      })

      this.addItems(data)
    } catch (e) {
      console.log(e)
    }
  }

  fetchGroupInboxes = async () => {
    try {
      const { data } = await InboxesApi.getGroupInboxes()

      this.addItems(data)
    } catch (e) {
      console.log(e)
    }
  }

  reFetchInboxes = async () => {
    const [inboxesData] = await Promise.all([
      InboxesApi.getInboxesPersonal({
        withUnified: true,
      }),
    ])

    const inboxes = inboxesData.data

    this.addItems(inboxes)
  }

  initInboxCurrent = (user: IResponseOwnerUser) => {
    this.currentInboxId = user.currentInboxId
    this.currentInboxType = user.currentInboxType
    this.currentInboxTypeId = user.currentInboxTypeId
  }

  reset = () => {
    this.inboxesMap.clear()
  }

  isGroupChild = (id: number | string): boolean => {
    const inboxesGroups = this.inboxesList.filter((item) => item.isGroupInbox)

    let isChildInbox = false
    inboxesGroups.forEach((inboxGroup) => {
      if (inboxGroup.type === 'inbox_group') {
        inboxGroup.teamsIds.forEach((item) => {
          if (id === item) {
            isChildInbox = true
          }
        })
      }
    })

    return isChildInbox
  }

  hasActiveChildInbox = (id?: number | string): boolean => {
    if (!id) return false

    const inbox = this.inboxesMap.get(id)
    if (!inbox || !inbox.isGroupInbox || !this.currentInboxId) return false

    return inbox.teamsIds.some((teamId) => teamId === this.currentInboxId)
  }

  isChildOfActiveGroupInbox = (id?: number | string): boolean => {
    if (!this.currentInbox?.isGroupInbox || !id) return false

    return this.currentInbox.teamsIds.some((teamId) => teamId === id)
  }

  isChildOfGroupInboxWithActiveChild = (id?: number | string): boolean => {
    if (!id) return false

    const inbox = this.inboxesMap.get(id)
    if (!inbox || inbox.isGroupInbox || !this.currentInboxId) return false

    const inboxesGroups = this.inboxesList.filter((item) => item.isGroupInbox)
    const activeGroupInbox = inboxesGroups.find((item) =>
      item.teamsIds.some((teamId) => teamId === this.currentInboxId)
    )

    if (!activeGroupInbox) return false
    return activeGroupInbox.teamsIds.some((teamId) => teamId === id)
  }

  addItems = (items: IResponseInboxCombine[]) => {
    items.forEach((item) => {
      if (item.type === 'inbox') {
        numbersStore.addItems(item.numbers)
      }

      this.addItem(item)
    })

    return items.reduce<IInboxCombine[]>((state, item) => {
      const inbox = this.getItem(item.id)

      return inbox ? [...state, inbox] : [...state]
    }, [])
  }

  deleteItem = (id: number | string) => {
    this.inboxesMap.delete(id)
  }

  getItem = (id?: number | string | null) => {
    if (typeof id !== 'number') return

    return this.inboxesMap.get(id)
  }

  addItem = (data: IResponseInboxCombine) => {
    const inbox = this.inboxesMap.get(data.id)

    if (data.type === 'unified-inbox') {
      if (inbox && inbox instanceof UnifiedInbox) {
        inbox.syncOrigin({ ...inbox, ...data })
      } else {
        this.inboxesMap.set(data.id, new UnifiedInbox(data))
      }

      return this.getItem(data.id)
    }

    if (data.type === 'inbox_group') {
      if (inbox && inbox instanceof GroupInbox) {
        inbox.syncOrigin({ ...inbox, ...data })
      } else {
        this.inboxesMap.set(data.id, new GroupInbox(data))
      }
      return this.getItem(data.id)
    }

    if (inbox && inbox instanceof Inbox) {
      inbox.syncOrigin({ ...inbox, ...data })
    } else {
      this.inboxesMap.set(data.id, new Inbox(data))
      numbersStore.addItems(data.numbers)
    }

    return this.getItem(data.id)
  }

  handleUpdateTeamInbox = async (inboxId: string | number) => {
    if (this.currentInboxId === inboxId) return

    const inbox = await this.getById(Number(inboxId))

    if (inbox) {
      await UsersApi.updateUsersTeam(inbox.bodyUpdateUserTeam)

      runInAction(() => {
        this.currentInboxId = inbox.id
        this.currentInboxType = inbox.inboxType
        this.currentInboxTypeId = inbox.id
      })
    }
  }

  handleUpdateTeamsFavorite = async (inbox: IInboxCombine, status: boolean) => {
    if (inbox.is_favorite === status) return

    await InboxesApi.updateTeamsFavorite(inbox.bodyUpdateTeamsFavorite)

    inbox.is_favorite = status
  }

  handleCreateTeamsGroupsByIdAddTeams = async (inbox: IInboxCombine, groupId: number | string) => {
    const groupInbox = this.getItem(groupId)

    if (groupInbox && groupInbox.type === 'inbox_group') {
      if (groupInbox.teamsIds.find((teamId) => teamId === inbox.id)) return

      await InboxesApi.createTeamsGroupsByIdAddTeams(
        groupId,
        inbox.bodyCreateTeamsGroupsByIdAddTeams
      )

      if (!groupInbox.teamsIds.find((teamId) => teamId === inbox.id)) {
        groupInbox.teamsIds.push(inbox.id)
      }
    }
  }

  handleDeleteTeamsGroupsByIdRemoveTeamById = async (
    inbox: IInboxCombine,
    groupInbox?: IInboxCombine
  ) => {
    if (!groupInbox) return
    if (groupInbox.type !== 'inbox_group') return

    await InboxesApi.deleteTeamsGroupsByIdRemoveTeamById(groupInbox.id, inbox.id)
    groupInbox.removeTeamId(inbox.id)
  }

  handleDeleteTeamsGroupsById = async (inbox: IInboxCombine) => {
    await UsersApi.updateUsersTeam({
      id: 0,
      type: 3,
    })
    InboxesApi.deleteTeamsGroupsById(inbox.id)
  }

  handleUpdateTeamsGroupsById = async (name: string, inbox: IInboxCombine) => {
    if (inbox.name === name) {
      this.handleRenameGroup(inbox, false)

      return
    }

    InboxesApi.updateTeamsGroupsById(inbox.id, {
      ...inbox.bodyUpdateTeamsGroupsById,
      name: name,
    })

    inbox.name = name

    this.handleRenameGroup(inbox, false)
  }

  handleRenameGroup = async (inbox: IInboxCombine, status: boolean) => {
    if (inbox.type !== 'inbox_group') return

    runInAction(() => {
      inbox.is_rename = status
    })
  }

  handleUpdateTeamsMute = async (inbox: IInboxCombine, status: boolean) => {
    if (inbox.type !== 'inbox') return
    if (inbox.isMuted === status) return

    await InboxesApi.updateTeamsMute(inbox.id, {
      is_muted: inbox.isMuted ? 0 : 1,
    })

    runInAction(() => {
      inbox.handleUpdateIsMuted(status)
    })
  }

  getById = async (id: number, update?: boolean, withError?: boolean) => {
    try {
      let team = this.getItem(id)

      if (!team && !this.inboxesLoadingIds.has(id)) {
        this.inboxesLoadingIds.add(id)
        const { data } = await InboxesApi.getTeamById(id)

        team = new Inbox(data)

        if (update) {
          this.addItem(data)
        }
      }

      return team
    } catch (e) {
      console.error(e)
      if (withError) {
        throw e
      }
    } finally {
      runInAction(() => {
        this.inboxesLoadingIds.delete(id)
      })
    }
  }

  getInboxByNumber = (numberId: number) => {
    const inboxes = this.inboxesList.filter((item) => item.type === 'inbox') as Inbox[]

    return inboxes.find((item) => item.numberIds.includes(numberId))
  }

  updateInboxCalling = (data: IResponseEventInboxCalling) => {
    const inbox = this.getItem(data.team_id)

    if (inbox?.type === 'inbox') {
      const number = numbersStore.getItem(inbox?.numberId)

      if (number) {
        number.updateInboxCalling(data.number)
      }
    }
  }

  reactionCurrentInboxId = () => {
    this._disposeCurrentInboxId?.()
    this._disposeCurrentInboxId = reaction(
      () => this.currentInboxId,
      async (currentInboxId) => {
        if (!currentInboxId) return

        let input = inboxesStore.getItem(currentInboxId)

        if (input?.type === 'inbox_group') {
          input = inboxesStore.getItem(input.teamsIds[0])
        }

        if (!input) return

        try {
          const { data } = await InboxesApi.getTeamByIdMembers(input.id)
          usersStore.addItems(data)
        } catch (e) {
          console.log('ERROR getTeamByIdMembers: ', e)
        }
      }
    )
  }

  get isUnifiedInboxOnly() {
    return this.inboxesList.length === 1 && this.inboxesList[0].type === 'unified-inbox'
  }

  get currentInbox() {
    if (this.currentInboxId === null) return

    return this.getItem(this.currentInboxId)
  }

  get inboxesList() {
    return Array.from(this.inboxesMap.values())
  }

  get sharedInboxes() {
    return this.inboxesList.reduce<Inbox[]>((state, inbox) => {
      if (inbox.type === 'inbox') {
        state.push(inbox)
      }

      return state
    }, [])
  }

  get groupsInboxes() {
    return this.inboxesList.reduce<GroupInbox[]>((state, inbox) => {
      if (inbox.type === 'inbox_group') {
        state.push(inbox)
      }

      return state
    }, [])
  }

  get unifiedInboxes() {
    return this.inboxesList.reduce<UnifiedInbox[]>((state, inbox) => {
      if (inbox.type === 'unified-inbox') {
        state.push(inbox)
      }

      return state
    }, [])
  }

  get selectInboxesSort() {
    const sort = {
      unified: 0,
      group: 1,
      shared: 3,
    }

    return this.inboxesList
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => {
        const inboxA = a
        const inboxB = b
        const aValue = sort[inboxA.typeKey]
        const bValue = sort[inboxB.typeKey]

        return aValue - bValue
      })
  }

  get selectInbox() {
    return this.getItem(this.currentInboxId)
  }

  get selectCurrentInboxId() {
    return this.currentInboxId
  }

  get nonPFTInboxNumberType() {
    const inbox: Inbox | undefined = this.inboxesList.find(
      (inbox) => !inbox.isPFTInbox && inbox.type === 'inbox'
    ) as Inbox

    return inbox?.number?.type
  }

  get isCurrentAirCall() {
    if (this.currentInbox?.type !== 'inbox') return false

    return this.currentInbox?.isAircall
  }

  get firstInboxId() {
    const inboxIds = this.inboxesList.reduce((acc, data) => {
      if (data?.type === 'unified-inbox') return acc

      if (data?.type === 'inbox_group') {
        if (data.teamsIds.length) {
          const sortedTeams = data.origin.teams.sort((a, b) => a.name.localeCompare(b.name))
          const teamInbox = this.getItem(sortedTeams[0].id)

          if (!teamInbox) return acc
          if (teamInbox.isFavoriteType) return [teamInbox.id, ...acc]
          if (!teamInbox.isFavoriteType) return [...acc, teamInbox.id]

          return acc
        }
      }

      if (data?.type !== 'inbox') return acc
      if (data.isFavoriteType) return [data.id, ...acc]
      if (!data.isFavoriteType) return [...acc, data.id]

      return acc
    }, [] as number[])

    return inboxIds[0]
  }

  get sortedFirstInbox() {
    return this.getItem(this.firstInboxId)
  }
}

export const inboxesStore = new InboxesStore()
