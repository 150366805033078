import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { InputCheckbox, TextFieldWithLabel, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { useComplianceSettingsContext } from '../context/complianceSettingsContext'

import styles from './styles.module.scss'

export const BusinessInfo = observer(() => {
  const { businessInfoStore } = useComplianceSettingsContext()
  const {
    businessTypes,
    activeBusinessTypeId,
    businessIndustries,
    activeBusinessIndustryId,
    websiteUrl,
    noWebsiteUrl,
    websiteUrlError,
    regionsOfOperationId,
    regionsOfOperation,
    setBusinessType,
    setBusinessIndustry,
    loadBusinessTypes,
    loadBusinessIndustries,
    setWebsiteUrl,
    setNoWebsiteUrl,
    checkEmail,
    setRegionsOfOperationId,
    loadBusinessProfileRegionsOfOperation,
  } = businessInfoStore

  useEffect(() => {
    loadBusinessTypes()
    loadBusinessIndustries()
    loadBusinessProfileRegionsOfOperation()
  }, [])

  return (
    <>
      <Typography variant='body-md-regular' ariaLabel='Business_info_line'>
        Make sure this information is an exact match to your official paperwork
      </Typography>
      <Box
        flex={1}
        display='flex'
        justifyContent={'space-between'}
        gap={3}
        marginBottom={3}
        marginTop={5}
      >
        <SelectField
          boxWidth='100%'
          labelProps={{
            label: {
              text: 'Business type',
            },
            questionTooltipProps: { label: '??' },
          }}
          items={businessTypes}
          value={activeBusinessTypeId}
          setValue={setBusinessType}
          btnProps={{
            size: 'large',
          }}
        />
        <SelectField
          boxWidth='100%'
          labelProps={{
            label: {
              text: 'Business industry',
            },
          }}
          items={businessIndustries}
          value={activeBusinessIndustryId}
          setValue={setBusinessIndustry}
          btnProps={{
            size: 'large',
          }}
        />
      </Box>
      <TextFieldWithLabel
        marginBottom={0}
        errorMessage={websiteUrlError}
        labelProps={{
          marginBottom: 8,
          questionTooltipProps: {
            label: "Enter the URL of your company's social media profile page",
            desc: '(e.g. Facebook, Twitter, Linkedin etc)',
          },
          label: {
            text: 'Business website URL',
          },
        }}
        textFieldProps={{
          value: websiteUrl || '',
          error: !!websiteUrlError,
          onChange: setWebsiteUrl,
          onBlur: checkEmail,
          InputProps: {
            placeholder: 'e.g. https://www.example.com/',
          },
        }}
      />
      <InputCheckbox
        checked={noWebsiteUrl}
        onChecked={setNoWebsiteUrl}
        customLabel={
          <Typography
            ariaLabel='Business_info_email_label'
            variant='text-input-field-r'
            color='var(--content-primary-tertiary)'
          >
            {"I don't have a business website"}
          </Typography>
        }
        className={styles.noEmailCheckbox}
      />
      <SelectField
        boxWidth='100%'
        labelProps={{
          label: {
            text: 'Business regions of operations',
          },
          questionTooltipProps: {
            label: 'Where do you do business? ',
            desc: 'If you have customers all around the world, go ahead and select all options. Otherwise, just select the ones where your customers are primarily located. ',
          },
        }}
        items={regionsOfOperation}
        value={regionsOfOperationId}
        setValue={setRegionsOfOperationId}
        btnProps={{
          size: 'large',
        }}
      />
    </>
  )
})
