import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import {
  TextField,
  TextFieldPlaceholder,
  makeInputNumeric,
  makeTextFieldIcon,
} from 'shared/ui/TextField'
import { Dropdown, DropdownContent, DropdownItem } from 'shared/ui/Dropdown'
import { ChatbotAwaitReplyUnit, IChatbotAwaitReplyUnit, ChatbotAwaitReply } from 'entities/Chatbot'
import { useChatbotContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export interface IAwaitReplyProps {
  awaitReply: ChatbotAwaitReply
}

const InputInteger = makeInputNumeric({
  allowNegative: false,
  decimalScale: 0,
  maxLength: 4,
})

const UNIT_CONFIG = {
  [ChatbotAwaitReplyUnit.Minutes]: {
    value: ChatbotAwaitReplyUnit.Minutes,
    label: 'Minutes',
  },
  [ChatbotAwaitReplyUnit.Hours]: {
    value: ChatbotAwaitReplyUnit.Hours,
    label: 'Hours',
  },
  [ChatbotAwaitReplyUnit.Days]: {
    value: ChatbotAwaitReplyUnit.Days,
    label: 'Days',
  },
  [ChatbotAwaitReplyUnit.Weeks]: {
    value: ChatbotAwaitReplyUnit.Weeks,
    label: 'Weeks',
  },
  [ChatbotAwaitReplyUnit.Months]: {
    value: ChatbotAwaitReplyUnit.Months,
    label: 'Months',
  },
}

const getUnitLabel = (unit: IChatbotAwaitReplyUnit) => UNIT_CONFIG[unit]?.label ?? 'Unknown'

export const AwaitReply = observer(({ awaitReply }: IAwaitReplyProps) => {
  const { errors } = useChatbotContext()
  const [originValue, setOriginValue] = useState(`${awaitReply.value}`)
  const valueError = errors.getError('settings.await_reply.value')

  const handleValueChange = (value: string) => {
    const intValue = parseInt(value)
    const finalValue = isNaN(intValue) ? 0 : intValue

    if (value.charAt(0) !== '0') setOriginValue(`${finalValue}`)

    awaitReply.setValue(finalValue)
    errors.removeError('settings.await_reply')
  }

  return (
    <div className={classNames(styles.groupItem, styles.awaitReply)}>
      <span className={classNames(styles.groupLabel, styles.awaitReplyLabel)}>
        Didn’t get a reply in
      </span>
      <TextField
        className={styles.awaitReplyValue}
        size='small'
        variant='outline'
        error={valueError}
        Input={InputInteger}
        value={originValue}
        onChange={handleValueChange}
      />
      <Dropdown
        margin={-30}
        width={112}
        placement='bottom'
        triggerComponent={() => (
          <TextFieldPlaceholder
            className={styles.awaitReplyUnit}
            size='small'
            variant='outline'
            placeholder={getUnitLabel(awaitReply.unit)}
            rightActions={[makeTextFieldIcon({ icon: 'chevronDown' })]}
          />
        )}
        customComponent={(close) => (
          <DropdownContent>
            {Object.values(UNIT_CONFIG).map((item, index) => (
              <DropdownItem
                key={item.value}
                item={{
                  id: index,
                  label: item.label,
                  onClick: () => {
                    close()
                    awaitReply.setUnit(item.value)
                  },
                }}
              />
            ))}
          </DropdownContent>
        )}
      />
    </div>
  )
})
