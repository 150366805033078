// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IMmyz5CrFK88GyVSJxol{height:auto;min-height:132px;padding:20px}.IMmyz5CrFK88GyVSJxol.i0TIWILkf00a57J0qW8p{width:100%;display:flex;align-items:center;justify-content:center}.IMmyz5CrFK88GyVSJxol.QrirMWfhNBN8L6qxqUjQ{border:1px var(--border-neutral-default) solid}.IMmyz5CrFK88GyVSJxol.ftRhjOBFqelW1xoxCl8w{border-left:1px var(--border-neutral-default) solid}.IMmyz5CrFK88GyVSJxol.RLepLFQcIfcvnQbMGMqN{border-right:1px var(--border-neutral-default) solid}.IMmyz5CrFK88GyVSJxol.o0NpdZa4qELoMrCrJfu7{border-top:1px var(--border-neutral-default) solid}.IMmyz5CrFK88GyVSJxol.ILUK5GIEOC0QEaCvGWq2{border-bottom:1px var(--border-neutral-default) solid}.IMmyz5CrFK88GyVSJxol .v_aToPJFDlXnot0pOtAN{display:flex;align-items:center;justify-content:space-between;margin-top:17px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsProfileCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CACA,YAAA,CAEA,2CACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,2CACE,8CAAA,CAGF,2CACE,mDAAA,CAGF,2CACE,oDAAA,CAGF,2CACE,kDAAA,CAGF,2CACE,qDAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":[".wrap {\n  height: auto;\n  min-height: 132px;\n  padding: 20px;\n\n  &.loader {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  &.borderAll {\n    border: 1px var(--border-neutral-default) solid;\n  }\n\n  &.borderLeft {\n    border-left: 1px var(--border-neutral-default) solid;\n  }\n\n  &.borderRight {\n    border-right: 1px var(--border-neutral-default) solid;\n  }\n\n  &.borderTop {\n    border-top: 1px var(--border-neutral-default) solid;\n  }\n\n  &.borderBottom {\n    border-bottom: 1px var(--border-neutral-default) solid;\n  }\n\n  .actions {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 17px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "IMmyz5CrFK88GyVSJxol",
	"loader": "i0TIWILkf00a57J0qW8p",
	"borderAll": "QrirMWfhNBN8L6qxqUjQ",
	"borderLeft": "ftRhjOBFqelW1xoxCl8w",
	"borderRight": "RLepLFQcIfcvnQbMGMqN",
	"borderTop": "o0NpdZa4qELoMrCrJfu7",
	"borderBottom": "ILUK5GIEOC0QEaCvGWq2",
	"actions": "v_aToPJFDlXnot0pOtAN"
};
export default ___CSS_LOADER_EXPORT___;
