import classnames from 'classnames'
import React, { CSSProperties } from 'react'
import {
  Badge,
  Icon,
  IIconProps,
  IIconsVector,
  ITooltipProps,
  Tooltip,
  Typography,
} from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type ITabItem<T = string> = {
  key: T
  name: string
  count?: number
  disabled?: boolean
  icon?: IIconsVector
  iconProps?: IIconProps
  isFullCount?: boolean
  tooltipProps?: ITooltipProps
}

type ITabsProps<T = string> = {
  variant?: 'default' | 'simple' | 'divider' | 'simpleWithoutBorder' | 'dividerForFirst'
  tabs: ITabItem<T>[]
  activeTabKey: T
  handleSelectTab?: (tab: ITabItem<T>) => void
  marginInlineTab?: number
  margin?: CSSProperties['margin']
  paddingInlineWrap?: number
  withStopPropagation?: boolean
  gap?: CSSProperties['gap']
  tabHeight?: CSSProperties['height']
  isFullWidth?: boolean
}

export const Tabs = <T,>({
  tabs,
  activeTabKey,
  handleSelectTab,
  marginInlineTab,
  paddingInlineWrap,
  withStopPropagation,
  variant,
  gap,
  tabHeight,
  isFullWidth,
  margin,
}: ITabsProps<T>) => {
  return (
    <div
      className={classnames(styles.tabs, styles[`tabs--${variant}`])}
      style={{ marginInline: marginInlineTab, margin }}
      aria-label={getAriaLabel('Tabs')}
    >
      <div
        className={classnames(styles.wrap, { [styles.fullWidth]: isFullWidth })}
        style={{ paddingInline: paddingInlineWrap, gap }}
        aria-label={getAriaLabel('Tabs', 'wrap')}
      >
        {tabs.map((item) => {
          const { key, name, count, icon, isFullCount, iconProps } = item

          const title = (
            <Typography
              variant={'button-small'}
              tag={'div'}
              className={styles.tab__label}
              ariaLabel={'label'}
            >
              {name}
            </Typography>
          )
          const content = () => {
            if (item.tooltipProps) {
              return <Tooltip {...item.tooltipProps}>{title}</Tooltip>
            }

            return <>{title}</>
          }

          return (
            <div
              key={key as string}
              className={classnames(styles.tab, {
                [styles.active]: key === activeTabKey,
                [styles.disabled]: item.disabled,
              })}
              onClick={(e) => {
                if (activeTabKey === item.key) return
                if (item.disabled) return

                withStopPropagation && e.stopPropagation()

                handleSelectTab?.(item)
              }}
              style={{
                height: tabHeight,
              }}
              aria-label={getAriaLabel('tab', name)}
            >
              <div className={styles.content} aria-label={getAriaLabel('tab', 'content')}>
                {icon && (
                  <div className={styles.tab__icon} aria-label={getAriaLabel('tab', 'icon')}>
                    <Icon icon={icon} fontSize={12} {...iconProps} />
                  </div>
                )}

                {content()}

                {count !== undefined && (
                  <div className={styles.tab__count} aria-label={getAriaLabel('tab', 'count')}>
                    <span className={styles['tab__count--hover']}>
                      <Badge content={count} variant={'white'} showAll isFullCount={isFullCount} />
                    </span>
                    <span className={styles['tab__count--hoverActive']}>
                      <Badge content={count} variant={'blue'} showAll isFullCount={isFullCount} />
                    </span>
                    <span className={styles['tab__count--default']}>
                      <Badge content={count} variant={'grey'} showAll isFullCount={isFullCount} />
                    </span>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
