import { makeAutoObservable, runInAction } from 'mobx'
import { ConversationsApi } from 'entities/Conversation'
import { usersStore } from 'entities/Users'
import { FiltersList, FiltersListKey } from 'widgets/CallHistoryDropdownFilter'

export class FiltersStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = true
  filtersMap: Map<FiltersListKey, FiltersList> = new Map()
  filter: FiltersList = {
    key: 'all',
    label: 'All',
  }
  availableCallsFiltersKeys = [
    'all',
    'open',
    'unread',
    'pending',
    'assigned',
    'unassigned',
    'closed',
  ]
  request: null | ReturnType<(typeof ConversationsApi)['getFiltersList']> = null

  storeActiveFilter = () => {
    if (!usersStore.user) return

    window.localStorage.setItem(this.storageKey, this.filter.key)
  }

  restoreActiveFilter = () => {
    if (!usersStore.user) return
    const activeFilterKey = window.localStorage.getItem(this.storageKey)

    if (!activeFilterKey) {
      this.setFilter('all')
      return
    }

    const activeFilter = this.getItem(activeFilterKey)
    if (!activeFilter) return

    this.filter = activeFilter
  }

  reset = () => {
    this.loading = false
  }

  loadFilters = async (): Promise<void> => {
    if (this.request) return this.request.then()

    this.loading = true
    this.request = ConversationsApi.getFiltersList()
    const { data: filters } = await this.request
    const list = filters
    list.unshift({ key: 'all', label: 'All' })

    this.addItems(list)
    this.restoreActiveFilter()

    runInAction(() => {
      this.loading = false
    })
  }

  setFilter = (id: FiltersListKey) => {
    const filter = this.getItem(id)

    if (filter) {
      this.filter = filter
      this.storeActiveFilter()
    }
  }

  addItems = (items: FiltersList[]) => {
    items.forEach((item) => {
      this.filtersMap.set(item.key, item)
    })
  }

  getItem = (key: FiltersListKey) => {
    return this.filtersMap.get(key)
  }

  get filters() {
    return Array.from(this.filtersMap.values()).filter((item) =>
      this.availableCallsFiltersKeys.includes(item.key)
    )
  }
  get storageKey() {
    if (!usersStore.user) return ''

    return `${usersStore.user.id}_call_history_filter`
  }
}

export const filtersStore = new FiltersStore()
