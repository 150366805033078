import { observer } from 'mobx-react-lite'

import { FilterDropdown } from 'shared/ui'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { getImportContactDropdownGroups } from 'widgets/ContactsTable/helpers/helpers'
import { getIcon } from 'widgets/constants'
import { DropdownTriggerComponent } from '../../'
import { ImportContactDropdownActions } from '../ImportContactDropdownActions/ImportContactDropdownActions'

interface IImportContactDropdownProps {
  header: string
}

export const ImportContactDropdown = observer(({ header }: IImportContactDropdownProps) => {
  const { mappedFields, doNotMapNameList, fields, toggleFilter, getFieldDataByKey } =
    importContactsStore

  if (!fields) {
    return null
  }

  const filterKey = mappedFields[header]

  const filter = filterKey ? getFieldDataByKey(filterKey) : null
  const filterName = filter?.name || ''

  const mappedKeys = Object.values(mappedFields)

  const filterDropdownGroups = getImportContactDropdownGroups(fields, mappedKeys)

  const isDoNotMap = doNotMapNameList.includes(header)
  const dropdownHeader = isDoNotMap ? 'Don’t import' : filterName

  const triggerComponentIcon = isDoNotMap || !filter ? 'close' : getIcon(filter)

  return (
    <FilterDropdown
      width={264}
      margin={-34}
      filterDropdownGroups={filterDropdownGroups}
      closeAfterChange
      triggerComponent={() => (
        <DropdownTriggerComponent icon={triggerComponentIcon} text={dropdownHeader} />
      )}
      additionActions={<ImportContactDropdownActions header={header} />}
      changeActiveFields={({ id }) => {
        toggleFilter({ key: String(id), header })
      }}
    />
  )
})
