import React from 'react'
import { observer } from 'mobx-react-lite'
import { Select } from 'shared/ui/Select/Select'
import { ContainerHeader } from 'shared/ui'
import { IOrganizationStatus, organizationsStatusesDropdownItems } from 'entities/Organization'
import { ISubscriptionStatus, subscriptionsStatusDropdownItems } from 'entities/Subscription'
import { OrganizationsTable } from 'pages/admin/pages/organizations/pages/Organizations/ui/OrganizationsTable/OrganizationsTable'
import { OrganizationsSearch } from 'pages/admin/pages/organizations/pages/Organizations/ui/OrganizationsSearch/OrganizationsSearch'
import { adminOrganizationsListStore } from 'pages/admin/pages/organizations/pages/Organizations'
import { NewOrganizationButton } from './NewOrganizationButton/NewOrganizationButton'

export const Organizations = observer(() => {
  const {
    organizationStatuses,
    subscriptionStatuses,
    setOrganizationStatus,
    setSubscriptionStatuses,
  } = adminOrganizationsListStore
  return (
    <>
      <ContainerHeader
        title={'Organizations'}
        actionsGap={8}
        actions={[
          { iconButtonComponent: <NewOrganizationButton /> },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Organization status'}
                values={organizationStatuses}
                onSelectValues={(data) => {
                  const values = data as Array<IOrganizationStatus>

                  setOrganizationStatus(values)
                }}
                items={organizationsStatusesDropdownItems}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Subscription status'}
                values={subscriptionStatuses}
                onSelectValues={(data) => {
                  const values = data as Array<ISubscriptionStatus>

                  setSubscriptionStatuses(values)
                }}
                items={subscriptionsStatusDropdownItems}
              />
            ),
          },
        ]}
        titleIconProps={{
          icon: 'building',
          fontSize: 20,
          tertiary: true,
        }}
      />
      <OrganizationsSearch />
      <OrganizationsTable />
    </>
  )
})
