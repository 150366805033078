import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { EmptyState, EnumSpinnerLoaderPosition, SpinnerLoader, Table } from 'shared/ui'
import { useBroadcastDetailOneTimeContext } from 'widgets/BroadcastDetail'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { ContactStatisticBulkActions } from 'widgets/ContactStatistic'
import { useContactStatisticColumns } from './useContactStatisticColumns'
import styles from './styles.module.scss'

export const BroadcastContactStatisticsTable = observer(() => {
  const {
    items,
    isNoData,
    total,
    loading,
    onPaginationModelChange,
    page,
    scrollToTOpTrigger,
    limit,
    tableStore,
    filter,
    search,
    onSuccessDelete,
    onOpenContactsDetails,
  } = useBroadcastDetailOneTimeContext()
  const columns = useMemo(
    () => useContactStatisticColumns(filter, onOpenContactsDetails),
    [onOpenContactsDetails.disabled]
  )

  if (isNoData) {
    return (
      <EmptyState text={'No contacts found'} noIcon boxProps={{ flexGrow: 1, height: undefined }} />
    )
  }
  if (loading && page === 1) {
    return (
      <div className={styles.wrapTable}>
        <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
      </div>
    )
  }

  if (!items.length) {
    return <NoResultsFound boxProps={{ flexGrow: 1, height: undefined }} />
  }

  return (
    <div className={styles.wrapTable}>
      <Table
        columns={columns}
        rows={items}
        rowsCount={total}
        isLoading={loading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        limit={limit}
        startPaginationSize={10}
        sxTable={{ marginRight: 2, width: 'calc(100% - 16px)' }}
        scrollToTOpTrigger={scrollToTOpTrigger}
        withCheckbox
        store={tableStore}
        headerLeftContentProps={{
          actionsContent: (
            <ContactStatisticBulkActions
              selectedContactIds={tableStore.selectedIds}
              search={search}
              onSuccessDelete={onSuccessDelete}
            />
          ),
        }}
      />
    </div>
  )
})
