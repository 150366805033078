import styles from './styles.module.scss'

type ITypingIndicatorProps = {
  marginLeft?: number
}

export const TypingIndicator = ({ marginLeft }: ITypingIndicatorProps) => {
  return (
    <div className={styles.typing} style={{ marginLeft }}>
      <div className={styles.typing__dot}></div>
      <div className={styles.typing__dot}></div>
      <div className={styles.typing__dot}></div>
    </div>
  )
}
