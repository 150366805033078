import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { PageLayout } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { ContactsMenu, ContactsMenuStore } from 'widgets/ContactsMenu'

type IContactsLayoutProps = {
  contactsMenuStore: ContactsMenuStore
}

export const ContactsLayout: FC<IContactsLayoutProps> = observer(({ contactsMenuStore }) => {
  useEffect(() => {
    return () => {
      contactsStore.reset()
    }
  }, [])

  if (!contactsMenuStore.pageLayoutStore) return null

  return (
    <PageLayout
      store={contactsMenuStore.pageLayoutStore}
      leftContent={<ContactsMenu store={contactsMenuStore} />}
    />
  )
})
