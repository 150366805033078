import React, { useCallback, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Checkbox, EnumIconButtonShape, Icon, IconButton, Status, Typography } from 'shared/ui'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { AttachmentLoader } from 'shared/ui/Loader/AttachmentLoader/AttachmentLoader'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { ImageCard, VideoCard } from 'entities/Attachment'
import styles from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryCard/styles.module.scss'
import { MediaLibraryCardActions } from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryCard/MediaLibraryCardActions'
import { useMediaLibraryContext } from 'widgets/MediaLibrary/context/mediaLibraryContext'
import { getAttachmentFlags } from 'widgets/MediaLibrary/utils'

type IAttachmentCardProps = {
  attachment: Attachment
  isSelected: boolean
  isLoading?: boolean
  handleSelectedChange: () => void
}

export const MediaLibraryCard = observer(
  ({ attachment, isSelected, isLoading, handleSelectedChange }: IAttachmentCardProps) => {
    const mediaLibraryStore = useMediaLibraryContext()
    const { onOpenPresentationModal } = mediaLibraryStore
    const [isCardHovered, setIsCardHovered] = useState(false)

    const {
      isAudio,
      isVideo,
      isImage,
      isVCard,
      isAttachmentPresentationAvailable,
      isAttachmentWithContent,
    } = getAttachmentFlags(attachment)

    const handleMouseEnter = () => {
      setIsCardHovered(true)
    }

    const handleMouseLeave = () => {
      setIsCardHovered(false)
    }

    const handleStopPropagation = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
    }

    const onOpenPresentation = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onOpenPresentationModal(attachment)
    }

    const fileTypeLabel = useCallback(() => {
      if (attachment.loading) return 'Processing'
      if (isImage) return 'Image'
      if (isVideo) return 'Video'
      if (isAudio) return 'Audio'
      if (isVCard) return 'vCard'
      return 'File'
    }, [isImage, isVideo, isAudio, isVCard, attachment.loading])

    const mediaContent = () => {
      if (isAttachmentWithContent) {
        if (isImage) {
          return <ImageCard thumbnail attachment={attachment} />
        }

        if (isVideo) {
          return attachment.thumbnail ? (
            <ImageCard thumbnail attachment={attachment} />
          ) : (
            <VideoCard thumbnail attachment={attachment} />
          )
        }
      }

      return <div className={styles.mediaStub}></div>
    }

    const fileIcon = useMemo(() => {
      if (isCardHovered && !attachment.loading && isAttachmentPresentationAvailable) return
      if (isAudio)
        return <Icon fontSize={20} insideBox boxRounded padding={6} icon='audio' tertiary />
      if (isVCard)
        return <Icon fontSize={20} insideBox boxRounded padding={6} icon='personFilled' tertiary />
      if (isImage && !isAttachmentWithContent)
        return <Icon fontSize={20} insideBox boxRounded padding={6} icon='imageLine' tertiary />
      if (isVideo && !isAttachmentWithContent)
        return <Icon fontSize={20} insideBox boxRounded padding={6} icon='video' tertiary />

      switch (attachment.extension || attachment.content_type) {
        case 'pdf':
        case 'application/pdf':
          return <Icon fontSize={32} icon='filePdf' />
        case 'csv':
        case 'text/csv':
          return <Icon fontSize={32} icon='fileCsv' />
        case 'xls':
        case 'xlsx':
        case 'application/vnd.ms-excel':
          return <Icon fontSize={32} icon='fileXls' />
        case 'doc':
        case 'docx':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return <Icon fontSize={32} icon='fileDoc' />
      }

      return
    }, [isCardHovered, attachment.type, attachment.loading])

    return (
      <div className={styles.galleryCard}>
        <div
          className={classNames(styles.cardContainer, {
            [styles.selected]: isSelected,
          })}
          onClick={() => !attachment.loading && handleSelectedChange()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={styles.mediaContainer}>{mediaContent()}</div>
          {isLoading && <AttachmentLoader isLibrary />}
          <div className={styles.overlay}></div>
          <div className={styles.topLeft}>
            {!attachment.loading && (
              <Checkbox variant='bordered' size='original' checked={isSelected} />
            )}
          </div>
          <div className={styles.topRight} onClick={handleStopPropagation}>
            <Status
              title={fileTypeLabel()}
              intent={'white'}
              emphasis={'low'}
              size={'label'}
              className={styles.fileTypeLabel}
            />
          </div>
          <div className={styles.bottomLeft} onClick={handleStopPropagation}>
            {(isAudio || isVideo) && typeof attachment.duration === 'number' && (
              <div className={styles.duration}>{secondsToTime(attachment.duration)}</div>
            )}
          </div>
          <div className={styles.bottomRight} onClick={handleStopPropagation}>
            {isCardHovered && !attachment.loading && (
              <MediaLibraryCardActions attachment={attachment} />
            )}
          </div>
          <div className={styles.center}>
            {fileIcon}
            {isCardHovered && isAttachmentPresentationAvailable && !attachment.loading && (
              <IconButton
                icon={isAudio || isVideo ? 'play1' : 'expand2'}
                color={'darkPrimary'}
                shape={EnumIconButtonShape.Rounded}
                ariaLabel='ShowPresentationMode'
                fontSize={16}
                onClick={onOpenPresentation}
                className={styles.openPresentationModeButton}
              />
            )}
          </div>
        </div>

        <div className={styles.fileName}>
          <Typography variant={'body-md-regular'} ariaLabel={'filename'}>
            {attachment.name}
          </Typography>
        </div>
      </div>
    )
  }
)
