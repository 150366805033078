import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'
import { useContactsDetailsStore } from 'widgets/ContactCreateModal/hooks/useContactsDetailsStore'
import { ContactsDetails } from 'widgets/ContactsDetails'
import styles from './styles.module.scss'

type IContactCreateModalContentProps = {
  contactCreateModalStore: ContactCreateModalStore
}

export const ContactCreateModalContent: FC<IContactCreateModalContentProps> = observer(
  ({ contactCreateModalStore }) => {
    const contactsDetailsStore = useContactsDetailsStore(contactCreateModalStore)

    const isLoading = contactsDetailsStore?.contactCreateModalStore?.isLoading

    if (isLoading) return <SpinnerLoader />

    return (
      <div className={styles.wrap}>
        {contactsDetailsStore && <ContactsDetails store={contactsDetailsStore} />}
      </div>
    )
  }
)
