// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xRfB0j1XxD6QV8hLNCsG{display:flex;flex-direction:column;gap:8px}.s2VUAVDVhQb48Tsja2bP{display:grid;grid-auto-flow:column;justify-content:flex-start;gap:8px;overflow-x:auto;padding-bottom:14px}.GiCspGCg9wmjHFwcn3Qw{border-radius:6px;padding:16px;background:var(--background-neutral-subtle);overflow-y:auto;word-break:break-all}.GiCspGCg9wmjHFwcn3Qw a{font-size:14px;line-height:20px;text-decoration:underline;font-family:Inter,sans-serif;font-weight:normal;color:var(--content-brand-secondary)}.GiCspGCg9wmjHFwcn3Qw a:hover{color:var(--light-blue-90)}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastActions/ui/BroadcastDetailModal/ui/BodyContent/TypeContent/SMSContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,OAAA,CACA,eAAA,CACA,mBAAA,CAEF,sBACE,iBAAA,CACA,YAAA,CACA,2CAAA,CACA,eAAA,CACA,oBAAA,CACA,wBACE,cAAA,CACA,gBAAA,CACA,yBAAA,CACA,4BAAA,CACA,kBAAA,CACA,oCAAA,CACA,8BACE,0BAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.wrapAttachments{\n  display: grid;\n  grid-auto-flow: column;\n  justify-content: flex-start;\n  gap: 8px;\n  overflow-x: auto;\n  padding-bottom: 14px;\n}\n.message{\n  border-radius: 6px;\n  padding: 16px;\n  background: var(--background-neutral-subtle);\n  overflow-y: auto;\n  word-break: break-all;\n  a{\n    font-size: 14px;\n    line-height: 20px;\n    text-decoration: underline;\n    font-family: Inter, sans-serif;\n    font-weight: normal;\n    color: var(--content-brand-secondary);\n    &:hover{\n      color: var(--light-blue-90);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "xRfB0j1XxD6QV8hLNCsG",
	"wrapAttachments": "s2VUAVDVhQb48Tsja2bP",
	"message": "GiCspGCg9wmjHFwcn3Qw"
};
export default ___CSS_LOADER_EXPORT___;
