// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f9LOekUZVxVoBqzqLhp3{padding-inline:24px}.kKFLBs6Sk6Y5ibgW5Sqe{border-top:1px solid var(--border-neutral-default);padding-block:20px}.lEk4SMaItDAc4_GdcJDA{border-top:1px solid var(--border-neutral-default);padding-block:16px;color:var(--content-primary-tertiary);position:relative}.eaT_Gg8KqizyZAsanrIK{display:flex;align-items:center}.J8l6Y5n3aYxyqDHXrEeq{flex:1;min-width:0}.MGe52_qFDe7dKDKNelnO{display:flex;justify-content:flex-end;padding-bottom:24px;padding-top:16px;gap:8px}.YV5lC3NFsCjjITGyhzG8{padding-left:8px;min-width:122px}", "",{"version":3,"sources":["webpack://./src/entities/VCard/ui/EditVCardContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAGF,sBACE,kDAAA,CACA,kBAAA,CAEF,sBACE,kDAAA,CACA,kBAAA,CACA,qCAAA,CACA,iBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CAEF,sBACE,MAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,mBAAA,CACA,gBAAA,CACA,OAAA,CAEF,sBACE,gBAAA,CACA,eAAA","sourcesContent":[".wrap{\n  padding-inline: 24px;\n}\n\n.headerCard{\n  border-top: 1px solid var(--border-neutral-default);\n  padding-block: 20px;\n}\n.content{\n  border-top: 1px solid var(--border-neutral-default);\n  padding-block: 16px;\n  color: var(--content-primary-tertiary);\n  position: relative;\n}\n.contentLine{\n  display: flex;\n  align-items: center;\n}\n.contentFiled{\n  flex: 1;\n  min-width: 0;\n}\n\n.actions{\n  display: flex;\n  justify-content: flex-end;\n  padding-bottom: 24px;\n  padding-top: 16px;\n  gap: 8px;\n}\n.inputLabel{\n  padding-left: 8px;\n  min-width: 122px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "f9LOekUZVxVoBqzqLhp3",
	"headerCard": "kKFLBs6Sk6Y5ibgW5Sqe",
	"content": "lEk4SMaItDAc4_GdcJDA",
	"contentLine": "eaT_Gg8KqizyZAsanrIK",
	"contentFiled": "J8l6Y5n3aYxyqDHXrEeq",
	"actions": "MGe52_qFDe7dKDKNelnO",
	"inputLabel": "YV5lC3NFsCjjITGyhzG8"
};
export default ___CSS_LOADER_EXPORT___;
