import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownContent } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { Tag } from 'entities/Tags/model/Tag'
import { SearchDropdownTagsStore } from 'widgets/SearchDropdown'
import { TagsDropdownContentItems } from 'widgets/SearchDropdown/ui'
import styles from './styles.module.scss'

type ITagsDropdownContentProps = {
  store: SearchDropdownTagsStore
  onChange?: (item: Tag) => void
  width?: number
}

export const TagsDropdownContent: FC<ITagsDropdownContentProps> = observer(
  ({ store, onChange, width }) => {
    const { init, search } = store

    const handleReset = () => {
      store.resetType()
      init()
    }

    return (
      <DropdownContent noPadding className={styles.wrap} width={width}>
        <div className={styles.searchWrap}>
          <TextField
            className={styles.search}
            variant='integrated'
            InputProps={{
              placeholder: 'Search tag',
            }}
            onChange={store.handleSearch}
            value={search}
            mainActions={[makeTextFieldAutoFocus({ withFocus: !search })]}
          />
        </div>

        <TagsDropdownContentItems store={store} onChange={onChange} />

        {store.hasSelectedItems && (
          <div className={styles.clear}>
            <Button typeBtn={'text'} text={'Clear'} onClick={handleReset} />
          </div>
        )}
      </DropdownContent>
    )
  }
)
