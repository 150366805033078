import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import parse from 'html-react-parser'
import { Scrollbar, Typography } from 'shared/ui'
import { Attachments } from 'widgets/Attachments'
import { type MessageFieldStore } from 'widgets/MessageField'
import styles from './styles.module.scss'

export const MessageSMSPreview = observer(
  ({ messageFieldStore }: { messageFieldStore: MessageFieldStore }) => {
    const description = messageFieldStore.messageHtml || 'Write your message...'

    return (
      <div className={styles.preview__layerWrap}>
        <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={446}>
          <div className={styles.preview__content}>
            <Typography
              variant={'body-md-regular'}
              className={classnames(styles.preview__desc, {
                [styles['preview__desc--active']]: Boolean(messageFieldStore.messageInnerText),
              })}
              width={'max-content'}
              ariaLabel={'description'}
            >
              {parse(description)}
            </Typography>

            <Attachments
              attachments={messageFieldStore.attachments}
              fullWidth
              imgFitType={'contain'}
            />
          </div>
        </Scrollbar>
      </div>
    )
  }
)
