import { observer } from 'mobx-react-lite'
import { Avatar, Icon, Scrollbar, Typography } from 'shared/ui'
import { EnrollmentFilters } from 'widgets/ContactEnrollment'
import { EnumEnrollmentMode } from 'widgets/ContactEnrollment/store/types'
import { EnrollmentItemType, IEnrollmentItem } from 'widgets/ContactEnrollment/types'
import styles from './styles.module.scss'
import { ListItem } from './ListItem'
import { ListSection } from './List'

export type IEnrollmentListProps = {
  active: IEnrollmentItem | null
  items: EnrollmentFilters['allWithCount']
  onRemove: (item: IEnrollmentItem) => void
  onSelect: (item: IEnrollmentItem) => void
  onMouseEnter: (item: IEnrollmentItem) => void
  onMouseLeave: (item: IEnrollmentItem) => void
  mode?: EnumEnrollmentMode
}

export const EnrollmentList = observer(
  ({
    items,
    onSelect,
    onRemove,
    onMouseEnter,
    onMouseLeave,
    mode,
    active,
  }: IEnrollmentListProps) => {
    const isEmpty =
      !items.filters.length &&
      !items.tags.length &&
      !items.contacts.length &&
      !items.segments.length

    if (isEmpty) {
      return (
        <Typography
          variant={'body-rg-medium'}
          className={styles.emptyState}
          ariaLabel={'Nothing selected'}
        >
          Nothing selected
        </Typography>
      )
    }

    return (
      <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={420} className={styles.wrap}>
        <div className={styles.container}>
          <ListSection title={'Selected'}>
            {items.filters.map(({ item, count }, index) => (
              <ListItem
                active={item === active?.payload}
                type={'filters'}
                key={item.id}
                icon={<Icon icon='filter' />}
                name={`Filter ${index + 1}`}
                count={count}
                onClick={() =>
                  onSelect({
                    type: EnrollmentItemType.Filter,
                    payload: item,
                  })
                }
                onMouseLeave={() =>
                  onMouseLeave({
                    type: EnrollmentItemType.Filter,
                    payload: item,
                  })
                }
                onMouseEnter={() =>
                  onMouseEnter({
                    type: EnrollmentItemType.Filter,
                    payload: item,
                  })
                }
                onRemove={() =>
                  onRemove({
                    type: EnrollmentItemType.Filter,
                    payload: item,
                  })
                }
                isViewOnly={mode === EnumEnrollmentMode.ViewOnly}
              />
            ))}
            {items.tags.map(({ item, count }) => (
              <ListItem
                active={item === active?.payload}
                type={'tags'}
                key={item.id}
                icon={<Icon icon='colorDot' color='var(--green-60)' />}
                name={item.label || `${item.id}`}
                count={count}
                onClick={() =>
                  onSelect({
                    type: EnrollmentItemType.Tag,
                    payload: item,
                  })
                }
                onMouseLeave={() =>
                  onMouseLeave({
                    type: EnrollmentItemType.Tag,
                    payload: item,
                  })
                }
                onMouseEnter={() =>
                  onMouseEnter({
                    type: EnrollmentItemType.Tag,
                    payload: item,
                  })
                }
                onRemove={() =>
                  onRemove({
                    type: EnrollmentItemType.Tag,
                    payload: item,
                  })
                }
                isViewOnly={mode === EnumEnrollmentMode.ViewOnly}
              />
            ))}
            {items.segments.map(({ item, count }) => (
              <ListItem
                active={item === active?.payload}
                type={'segments'}
                key={item.id}
                icon={<Icon icon='segment' />}
                name={item.name}
                count={count}
                onClick={() =>
                  onSelect({
                    type: EnrollmentItemType.Segment,
                    payload: item,
                  })
                }
                onMouseLeave={() =>
                  onMouseLeave({
                    type: EnrollmentItemType.Segment,
                    payload: item,
                  })
                }
                onMouseEnter={() =>
                  onMouseEnter({
                    type: EnrollmentItemType.Segment,
                    payload: item,
                  })
                }
                onRemove={() =>
                  onRemove({
                    type: EnrollmentItemType.Segment,
                    payload: item,
                  })
                }
                isViewOnly={mode === EnumEnrollmentMode.ViewOnly}
              />
            ))}
            {items.contacts.map(({ item, count }) => (
              <ListItem
                active={item === active?.payload}
                type={'contacts'}
                key={item.id}
                icon={<Avatar info={item.avatarInfo} size={16} />}
                name={item.name}
                count={count}
                onClick={() =>
                  onSelect({
                    type: EnrollmentItemType.Contact,
                    payload: item,
                  })
                }
                onMouseLeave={() =>
                  onMouseLeave({
                    type: EnrollmentItemType.Contact,
                    payload: item,
                  })
                }
                onMouseEnter={() =>
                  onMouseEnter({
                    type: EnrollmentItemType.Contact,
                    payload: item,
                  })
                }
                onRemove={() =>
                  onRemove({
                    type: EnrollmentItemType.Contact,
                    payload: item,
                  })
                }
                isViewOnly={mode === EnumEnrollmentMode.ViewOnly}
              />
            ))}
          </ListSection>
        </div>
      </Scrollbar>
    )
  }
)
