import { useEffect, useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { debounce } from 'lodash'
import { Dropdown, SearchElement } from 'shared/ui'
import { tagsStore } from 'entities/Tags'
import { SearchTypesEnum, useSearchDropdownContext } from 'widgets/SearchDropdown'
import { TagsDropdownContent } from 'widgets/SearchDropdown/ui'
import styles from './styles.module.scss'

type TagElementProps = {
  parentsDropdownId?: string[]
}

export const TagElement: FC<TagElementProps> = observer(({ parentsDropdownId }) => {
  // TODO: Fix position of child dropdown when child and parent dropdown render in 1 time
  const [show, setShow] = useState(false)
  const { searchDropdownTagsStore, newType, isShowMore, isOpen, isDirtyFilter, deleteType } =
    useSearchDropdownContext()
  const { hasSelectedItems, selectedItemsCount, firstSelectedItemId, selectedItemsIds } =
    searchDropdownTagsStore

  const TagLabel = () => {
    const firstTag = tagsStore.getItem(firstSelectedItemId)

    if (hasSelectedItems && selectedItemsCount === 1 && firstTag) {
      return (
        <div className={styles.countLabelWrapper}>
          <span className={styles.color} style={{ backgroundColor: firstTag.color }}></span>
          <span className={styles.label}>{firstTag.label}</span>
        </div>
      )
    }

    return (
      <span className={styles.countLabelWrapper}>
        <div className={styles.countColors}>
          {selectedItemsIds.slice(0, 3).map((item, idx) => {
            const color = tagsStore.getItem(item)?.color

            return (
              <span
                key={item}
                className={styles.countColor}
                style={{ backgroundColor: color, left: idx * 6 }}
              ></span>
            )
          })}
        </div>
        <span className={styles.countLabel}>{selectedItemsCount} tags</span>
      </span>
    )
  }

  const onShow = () => {
    const showDropdown =
      (newType === SearchTypesEnum.tags && isShowMore) ||
      (newType === SearchTypesEnum.tags && isOpen && !isDirtyFilter)

    setShow(showDropdown)
  }

  const showDebounce = debounce(onShow, 300)

  useEffect(() => {
    showDebounce()
  }, [isShowMore, newType, isOpen, isDirtyFilter])

  return (
    <Dropdown
      ariaLabel={'TagElement'}
      parentsDropdownId={parentsDropdownId}
      iconButtonProps={{
        size: 'medium',
        fontSize: 20,
        ariaLabel: 'TagElement_trigger',
      }}
      show={show}
      triggerComponent={(open) => (
        <SearchElement
          icon={'tag'}
          type='Tag'
          contentDefaultValue='select'
          warningContent={!open && !hasSelectedItems}
          removeCallback={() => {
            deleteType(SearchTypesEnum.tags)
          }}
          content={hasSelectedItems && <TagLabel />}
        />
      )}
      placement={'bottom-start'}
      sizeTriggerButton={'medium'}
      variantTriggerButton={'icon'}
      customComponent={() => <TagsDropdownContent store={searchDropdownTagsStore} />}
    />
  )
})
