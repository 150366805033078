import React, { ReactNode, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useMediaQuery } from 'usehooks-ts'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { BreakpointsEnum } from 'shared/constants/breakpoints'
import { layoutStore } from 'shared/layout/Layout/store/LayoutStore'
import styles from './styles.module.scss'

type ILayoutProps = {
  sidebarSlot?: ReactNode
}

const Layout: React.FC<ILayoutProps> = observer((props) => {
  const { handleActiveBreakpoint } = layoutStore

  const breakpointXL = useMediaQuery(`(min-width: ${BreakpointsEnum.XL}px)`)
  const breakpointL = useMediaQuery(`(min-width: ${BreakpointsEnum.L}px)`)
  const breakpointM = useMediaQuery(`(min-width: ${BreakpointsEnum.M}px)`)
  const breakpointS = useMediaQuery(`(min-width: ${BreakpointsEnum.S}px)`)
  const breakpointXS = useMediaQuery(`(max-width: ${BreakpointsEnum.S}px)`)

  useEffect(() => {
    if (breakpointXL) {
      handleActiveBreakpoint(BreakpointsEnum.XL)
      return
    }
    if (breakpointL) {
      handleActiveBreakpoint(BreakpointsEnum.L)
      return
    }
    if (breakpointM) {
      handleActiveBreakpoint(BreakpointsEnum.M)
      return
    }
    if (breakpointS) {
      handleActiveBreakpoint(BreakpointsEnum.S)
      return
    }
    if (breakpointXS) {
      handleActiveBreakpoint(BreakpointsEnum.XS)
      return
    }
  }, [breakpointXL, breakpointL, breakpointM, breakpointS, breakpointXS])

  return (
    <div className={styles.wrap}>
      {props.sidebarSlot}

      <div className={styles.content}>
        <Outlet />
      </div>

      <ScrollRestoration />
    </div>
  )
})

export default Layout
