// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".i3pWkLTK2snKLBWV3QMH{display:flex;align-items:center;justify-content:center;padding:10px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/ui/ActiveCampaignIntegration/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA","sourcesContent":[".reconnectWrapper {\n}\n\n.loaderWrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 10px;\n}\n\n.wrap {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrap": "i3pWkLTK2snKLBWV3QMH"
};
export default ___CSS_LOADER_EXPORT___;
