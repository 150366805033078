import classNames from 'classnames'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import type { ITextFieldBaseProps } from '../types'

export const useControl = (props: ITextFieldBaseProps) => {
  const containerRef = useRef<HTMLLabelElement | null>(null)

  const variant = useMemo(() => props.variant, [props.variant])
  const size = useMemo(() => props.size, [props.size])

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [actionClassNames, setActionClassNames] = useState<Record<string, string>>({})
  const [actionClasses, setActionClasses] = useState<string>('')

  const error = useMemo(() => props.error ?? '', [props.error])
  const warning = useMemo(() => props.warning ?? '', [props.warning])

  const control = {
    containerRef,
    actionClasses,
    variant,
    size,
    error,
    warning,
    inputRef,
  }

  const makeControl = (id: string) => {
    const setClassNames = (classNames: string) =>
      setActionClassNames({
        ...actionClassNames,
        [id]: classNames,
      })

    const clearClassNames = () => {
      delete actionClassNames[id]

      setActionClassNames({ ...actionClassNames })
    }

    return {
      containerRef,
      inputRef,
      setClassNames,
      clearClassNames,
      error,
      variant,
      size,
    }
  }

  useLayoutEffect(() => {
    const actionClasses = classNames(Object.values(actionClassNames))

    setActionClasses(actionClasses)
  }, [actionClassNames])

  return { control, makeControl }
}
