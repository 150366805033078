import classNames from 'classnames'
import { MouseEvent, useEffect, useState } from 'react'
import { Button, IconButton } from 'shared/ui'
import type { ITextFieldAsideAction } from 'shared/ui/TextField'

import styles from './styles.module.scss'

type ITextFieldClear = {
  disabled?: boolean
  isButton?: boolean
  isVisibleWithoutFocus?: boolean
}

export const makeTextFieldClear: (action?: ITextFieldClear) => ITextFieldAsideAction = (action) => {
  const useAction: ITextFieldAsideAction = (control) => {
    const [noFocus, setNoFocus] = useState(true)

    const isHidden =
      !control.value ||
      control.readOnly ||
      control.disabled ||
      (noFocus && !action?.isVisibleWithoutFocus) ||
      action?.disabled

    const handleClick = (event: MouseEvent) => {
      event.preventDefault()
      control.inputRef.current?.focus()
      control.setValue && control.setValue('')
    }

    useEffect(() => {
      const input = control.inputRef?.current
      const label = control.containerRef?.current

      if (!input || !label) return

      const handleFocus = () => setNoFocus(false)
      const handleBlur = () => setNoFocus(true)
      input.addEventListener('focus', handleFocus)
      input.addEventListener('blur', handleBlur)

      return () => {
        input.removeEventListener('focus', handleFocus)
        input.removeEventListener('blur', handleBlur)
      }
    }, [])

    return (
      <div
        className={classNames(styles.clearAction, {
          [styles.hidden]: isHidden,
        })}
      >
        {action?.isButton ? (
          <Button
            typeBtn={'text'}
            size={'medium'}
            onClick={handleClick}
            contained={'secondary'}
            tabIndex={-1}
            text={'Clear'}
          />
        ) : (
          <IconButton
            icon='close'
            variant='icon'
            color='secondary'
            size='small'
            fontSize={16}
            onMouseDown={handleClick}
            tabIndex={-1}
            ariaLabel='TextFieldClear'
          />
        )}
      </div>
    )
  }

  return useAction
}
