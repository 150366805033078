import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import type { IIconsVector, IToastType } from 'shared/ui'
import { errorHandler } from 'shared/api'
import { RequestCancelAxios } from 'shared/api/requestCancelAxios'
import { ContactIntegrationHubspotInfo } from 'entities/Integrations/model/ContactIntegrationHubspotInfo'
import { ContactIntegrationActiveCampaignInfo } from 'entities/Integrations/model/ContactIntegrationActiveCampaignInfo'
import { ContactIntegrationPipedriveInfo } from 'entities/Integrations/model/ContactIntegrationPipedriveInfo'
import { ContactIntegrationSalesforceInfo } from 'entities/Integrations/model/ContactIntegrationSalesforceInfo'
import { ContactIntegrationInfusionsoftInfo } from 'entities/Integrations/model/ContactIntegrationInfusionsoftInfo'
import {
  type IHubspotPropertiesObjectType,
  type IIntegrationTagCreateDTO,
  IntegrationKey,
  IntegrationsApi,
  type IResponseErrorIntegrationActivecampaignTagsApply,
  type IResponseErrorIntegrationInfusionsoftTagsApply,
  type IResponseIntegrationHubspotData,
  type IResponseIntegrationsContactsActiveCampaignInfo,
  type IResponseIntegrationsContactsSalesforceInfo,
} from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'
import type { IIntegrationsProperty } from 'entities/Integrations/model/types'
import type {
  IUiSettingsIntegrationKey,
  IUiSettingsIntegrationType,
} from 'entities/Users/api/types'
import {
  type ContactsDetailsStore,
  type IViewAllData,
  type IViewAllDataProps,
  type IViewTypes,
} from 'widgets/ContactsDetails'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails/store/types'
import { DraggableIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/store/draggableIntegrationStore'

type IContactsIntegrationsStoreConfig = {
  resetActiveAndCloseDetailsIsMobile?: () => void
}

const emptyViewAllData = {
  deals: [],
  companies: [],
  opportunities: [],
  tickets: [],
}

export class ContactsIntegrationsStore {
  private _loadingIds: Map<number, number> = new Map()
  private _loading = false
  private _unlinking = false

  private _hubspotInfoMap: Map<number, ContactIntegrationHubspotInfo> = new Map()
  private _activeCampaignInfoMap: Map<number, ContactIntegrationActiveCampaignInfo> = new Map()
  private _pipedriveInfoMap: Map<number, ContactIntegrationPipedriveInfo> = new Map()
  private _salesforceInfoMap: Map<number, ContactIntegrationSalesforceInfo> = new Map()
  private _infusionsoftInfoMap: Map<number, ContactIntegrationInfusionsoftInfo> = new Map()

  private _loadedFinish: Map<IntegrationKey, { [key in string]: boolean }> = new Map()

  private _integrationTab: EnumIntegrationTabs = EnumIntegrationTabs.Contact
  private _viewIntegrationKey: IntegrationKey | null = null
  private _viewAllData: IViewAllData = emptyViewAllData
  private _customTitleIcon: IIconsVector | null = null
  private _objectType: IHubspotPropertiesObjectType = 'contact'

  private _config: IContactsIntegrationsStoreConfig | null = null

  private _dndItemsMap: Map<string, DraggableIntegrationStore> = new Map()
  private _cancelRequest = new RequestCancelAxios()

  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)

    this._integrationTab = EnumIntegrationTabs.Contact
    this._viewIntegrationKey = null
  }

  initDnD = (
    integrationKey: IUiSettingsIntegrationKey,
    integrationTypesItems: {
      type: IUiSettingsIntegrationType
      defaultItems?: string[]
    }[]
  ) => {
    integrationTypesItems.forEach(({ type, defaultItems }) => {
      this._dndItemsMap.set(
        `${integrationKey}_${type}`,
        new DraggableIntegrationStore(integrationKey, type, defaultItems || [])
      )
    })
  }

  resetDnD = () => {
    this._dndItemsMap.forEach((item) => {
      item.dispose()
    })
  }

  getDnDItem = (
    integrationKey: IUiSettingsIntegrationKey,
    integrationType: IUiSettingsIntegrationType
  ) => {
    return this._dndItemsMap.get(`${integrationKey}_${integrationType}`)
  }

  get selectedContact() {
    return this._contactsDetailsStore.contact
  }

  get view() {
    return this._contactsDetailsStore.view
  }

  get viewAllData() {
    return this._viewAllData
  }

  get customTitleIcon() {
    return this._customTitleIcon
  }

  get viewIntegrationKey() {
    return this._viewIntegrationKey
  }

  get config() {
    return this._config
  }

  get integrationTab() {
    return this._integrationTab
  }

  get viewAllCount() {
    if (this.view === 'companies') return this._viewAllData.companies.length
    if (this.view === 'deals') return this._viewAllData.deals.length
    if (this.view === 'opportunities') return this._viewAllData.opportunities.length
    if (this.view === 'tickets') return this._viewAllData.tickets.length

    return 0
  }

  get contactsDetailsStore() {
    return this._contactsDetailsStore
  }

  get loading() {
    return this._loading
  }

  get unlinking() {
    return this._unlinking
  }

  get objectType() {
    return this._objectType
  }

  setConfig = (config: IContactsIntegrationsStoreConfig) => {
    this._config = config
  }

  setObjectType = (objectType: IHubspotPropertiesObjectType) => {
    this._objectType = objectType
  }

  openView = (
    viewType: IViewTypes,
    viewAllData: IViewAllDataProps,
    customTitleIcon: IIconsVector,
    viewIntegrationKey: IntegrationKey
  ) => {
    this._contactsDetailsStore.openView(viewType)
    this._customTitleIcon = customTitleIcon
    this._viewAllData = { ...this._viewAllData, ...viewAllData }
    this._viewIntegrationKey = viewIntegrationKey
  }

  closeView = () => {
    this._contactsDetailsStore.closeView()
    this._customTitleIcon = null
    this._viewAllData = emptyViewAllData
    this._viewIntegrationKey = null
  }

  dispose = () => {
    this.resetDnD()
    this._cancelRequest.reset()
  }

  handleIntegrationTab = (tab: EnumIntegrationTabs) => {
    this._integrationTab = tab
  }

  private _deleteContactIntegrationInfo = (id: number, key?: IntegrationKey | string) => {
    if (!key) return

    switch (key) {
      case IntegrationKey.hubspot: {
        this._hubspotInfoMap.delete(id)
        return
      }
      case IntegrationKey.salesforce: {
        this._salesforceInfoMap.delete(id)
        return
      }
      case IntegrationKey.activecampaign: {
        this._activeCampaignInfoMap.delete(id)
        return
      }
      case IntegrationKey.pipedrive: {
        this._pipedriveInfoMap.delete(id)
        return
      }
      case IntegrationKey.infusionsoft: {
        this._infusionsoftInfoMap.delete(id)
        return
      }
    }
  }

  unlinkContactsIntegrations = async (id: number, key?: IntegrationKey | string) => {
    try {
      this._unlinking = true

      const { data } = await IntegrationsApi.unlinkContactsByIdIntegrations(id)
      this._deleteContactIntegrationInfo(id, key)
      contactsStore.updateItem(data)
    } catch (e) {
      console.log('ERROR: unlinkContactsIntegrations: ', e)
    } finally {
      this._unlinking = false
    }
  }

  updateLoadedFinish = (key: IntegrationKey, tab: string, status: boolean) => {
    const item = this._loadedFinish.get(key)

    if (item) {
      this._loadedFinish.set(key, {
        ...item,
        [tab]: status,
      })
    } else {
      this._loadedFinish.set(key, {
        [tab]: status,
      })
    }
  }

  getLoadedFinish = (key: IntegrationKey, tab: string) => {
    const item = this._loadedFinish.get(key)

    if (!item) return false

    return Boolean(item[tab])
  }

  createContactsIntegrationsActiveCampaignTag = async (reqData: IIntegrationTagCreateDTO) => {
    const message: {
      message: string
      status: IToastType
    } = {
      message: '',
      status: 'success',
    }

    try {
      const { data } = await IntegrationsApi.createContactsIntegrationsActiveCampaignTag(reqData)

      data.message = data?.message || ''

      return message
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<IResponseErrorIntegrationActivecampaignTagsApply>(
        err
      )

      message.message = type === 'axios-error' ? error.response?.data.tag[0] || '' : ''
      message.status = 'error'

      return message
    }
  }

  createContactsIntegrationInfusionsoftTag = async (reqData: IIntegrationTagCreateDTO) => {
    try {
      const res = await IntegrationsApi.createContactsIntegrationsInfusionsoftTag(reqData)

      const data: {
        message: string
        status: IToastType
      } = {
        message: res?.data?.message || '',
        status: 'success',
      }

      return data
    } catch (e) {
      console.log('ERROR: createContactsIntegrationInfusionsoftTag: ', e)

      const err = e as Error
      const { type, error } = await errorHandler<IResponseErrorIntegrationInfusionsoftTagsApply>(
        err
      )

      const data: {
        message: string
        status: IToastType
      } = {
        message: '',
        status: 'error',
      }

      if (type === 'axios-error') {
        data.message = error?.response?.data?.tag?.[0] || ''
      }

      return data
    }
  }

  getContactsIntegrationInfusionsoftTags = async (value: string) => {
    try {
      const res = await IntegrationsApi.getContactsIntegrationsInfusionsoftTags(
        encodeURIComponent(value)
      )

      return res.data
    } catch (e) {
      console.log('ERROR: getContactsIntegrationInfusionsoftTags: ', e)
      return []
    }
  }

  initContactIntegrationHubspotInfo = async (
    contactId: number,
    use_contact_card_properties?: boolean
  ) => {
    if (this._loadingIds.has(contactId)) return

    const emptyData: IResponseIntegrationHubspotData = {
      deals: [],
      properties: {},
      companies: [],
      tickets: [],
    }

    try {
      runInAction(() => {
        this._loading = true
        this._loadingIds.set(contactId, contactId)
      })

      this._cancelRequest.init()

      const { data } = await IntegrationsApi.getContactsIntegrationsHubspotInfo(
        {
          id: contactId,
          use_contact_card_properties: use_contact_card_properties,
        },
        {
          cancelToken: this._cancelRequest.token,
        }
      )

      if (!data) {
        const info = new ContactIntegrationHubspotInfo(emptyData)

        runInAction(() => {
          this._hubspotInfoMap.set(contactId, info)
        })

        return
      }

      const info = new ContactIntegrationHubspotInfo(data.data)

      runInAction(() => {
        this._hubspotInfoMap.set(contactId, info)
      })
    } catch (e) {
      console.log('ERROR: initContactIntegrationHubspotInfo: ', e)

      const info = new ContactIntegrationHubspotInfo(emptyData)

      runInAction(() => {
        this._hubspotInfoMap.set(contactId, info)
      })
    } finally {
      runInAction(() => {
        this._loading = false
        this._loadingIds.delete(contactId)
      })
    }
  }

  updateContactIntegrationHubspotInfoByType = async (
    contactId: number,
    property: IIntegrationsProperty
  ) => {
    if (this._loadingIds.has(contactId)) return

    runInAction(() => {
      this._loading = true
      this._loadingIds.set(contactId, contactId)
    })

    const info = this._hubspotInfoMap.get(contactId)
    const objectType = this._objectType

    if (info) {
      if (objectType === 'contact') {
        info.addContactProperty(property)
      }

      if (objectType === 'ticket') {
        info.addTicketProperty(property)
      }

      if (objectType === 'company') {
        info.addCompanyProperty(property)
      }

      if (objectType === 'deal') {
        info.addDealProperty(property)
      }
    }

    runInAction(() => {
      this._loading = false
      this._loadingIds.delete(contactId)
    })
  }

  initContactIntegrationHubspotInfoByType = async (contactId: number) => {
    if (this._loadingIds.has(contactId)) return

    const objectType = this._objectType
    const emptyData: IResponseIntegrationHubspotData = {
      deals: [],
      properties: {},
      companies: [],
      tickets: [],
    }

    try {
      runInAction(() => {
        this._loading = true
        this._loadingIds.set(contactId, contactId)
      })

      const loaded = this.getLoadedFinish(IntegrationKey.hubspot, objectType)
      let propertiesResponse = emptyData.properties
      let dealsResponse = emptyData.deals
      let companiesResponse = emptyData.companies
      let ticketsResponse = emptyData.tickets

      this._cancelRequest.init()

      if (objectType === 'contact' && !loaded) {
        const response = await IntegrationsApi.getContactsIntegrationsHubspotContactInfo(
          contactId,
          {
            cancelToken: this._cancelRequest.token,
          }
        )
        propertiesResponse = response.data
      }

      if (objectType === 'deal' && !loaded) {
        const response = await IntegrationsApi.getContactsIntegrationsHubspotDealInfo(contactId, {
          cancelToken: this._cancelRequest.token,
        })
        dealsResponse = response.data
      }

      if (objectType === 'company' && !loaded) {
        const response = await IntegrationsApi.getContactsIntegrationsHubspotCompanyInfo(
          contactId,
          {
            cancelToken: this._cancelRequest.token,
          }
        )
        companiesResponse = response.data
      }

      if (objectType === 'ticket' && !loaded) {
        const response = await IntegrationsApi.getContactsIntegrationsHubspotTicketInfo(contactId, {
          cancelToken: this._cancelRequest.token,
        })
        ticketsResponse = response.data
      }

      this.updateLoadedFinish(IntegrationKey.hubspot, objectType, true)

      const info = this._hubspotInfoMap.get(contactId)

      if (info) {
        if (objectType === 'contact') {
          info.addProperties(propertiesResponse)
        }

        if (objectType === 'deal') {
          info.addDeals(dealsResponse)
        }

        if (objectType === 'company') {
          info.addCompanies(companiesResponse)
        }

        if (objectType === 'ticket') {
          info.addTickets(ticketsResponse)
        }
      } else {
        const currentData: IResponseIntegrationHubspotData = {
          deals: dealsResponse || emptyData.deals,
          properties: propertiesResponse || emptyData.properties,
          companies: companiesResponse || emptyData.companies,
          tickets: ticketsResponse || emptyData.tickets,
        }

        const info = new ContactIntegrationHubspotInfo(currentData)

        runInAction(() => {
          this._hubspotInfoMap.set(contactId, info)
        })
      }
    } catch (e) {
      console.log('ERROR: initContactIntegrationHubspotInfo: ', e)

      const info = new ContactIntegrationHubspotInfo(emptyData)

      runInAction(() => {
        this._hubspotInfoMap.set(contactId, info)
      })
    } finally {
      runInAction(() => {
        this._loading = false
        this._loadingIds.delete(contactId)
      })
    }
  }

  initContactIntegrationActiveCampaignInfo = async (id: number) => {
    if (this._loadingIds.has(id)) return

    const emptyData: IResponseIntegrationsContactsActiveCampaignInfo = {
      deals: [],
      accounts: {
        id: '',
        name: '',
      },
      properties: {
        first_name: '',
        last_name: '',
        email: '',
        tags: [],
      },
    }

    const emptyInfo = new ContactIntegrationActiveCampaignInfo(emptyData)

    try {
      runInAction(() => {
        this._loading = true
        this._loadingIds.set(id, id)
      })

      this._cancelRequest.init()

      const { data } = await IntegrationsApi.getContactsIntegrationsActiveCampaignInfo(id, {
        cancelToken: this._cancelRequest.token,
      })

      if (!data) {
        this._activeCampaignInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationActiveCampaignInfo(data)
      this._activeCampaignInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationActiveCampaignInfo: ', e)

      this._activeCampaignInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this._loading = false
        this._loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationPipedriveInfo = async (id: number) => {
    if (this._loadingIds.has(id)) return

    const emptyData = {
      contactId: id,
      deal: [],
      properties: null,
      organization: null,
    }

    const emptyInfo = new ContactIntegrationPipedriveInfo(emptyData)

    try {
      runInAction(() => {
        this._loading = true
        this._loadingIds.set(id, id)
      })

      this._cancelRequest.init()

      const { data } = await IntegrationsApi.getContactsIntegrationsPipedriveInfo(id, {
        cancelToken: this._cancelRequest.token,
      })

      if (!data) {
        this._pipedriveInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationPipedriveInfo(data)
      this._pipedriveInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationPipedriveInfo: ', e)

      this._pipedriveInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this._loading = false
        this._loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationSalesforceInfo = async (id: number) => {
    if (this._loadingIds.has(id)) return

    const emptyData: IResponseIntegrationsContactsSalesforceInfo = {
      properties: {
        first_name: '',
        last_name: '',
        email: '',
        object_type: 'contact',
      },
      opportunities: [],
    }

    const emptyInfo = new ContactIntegrationSalesforceInfo(emptyData)

    try {
      runInAction(() => {
        this._loading = true
        this._loadingIds.set(id, id)
      })

      this._cancelRequest.init()

      const { data } = await IntegrationsApi.getContactsIntegrationsSalesforceInfo(id, {
        cancelToken: this._cancelRequest.token,
      })

      if (!data) {
        this._salesforceInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationSalesforceInfo(data)
      this._salesforceInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationSalesforceInfo: ', e)

      this._salesforceInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this._loading = false
        this._loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationInfusionsoftInfo = async (id: number) => {
    if (this._loadingIds.has(id)) return
    const emptyInfo = new ContactIntegrationInfusionsoftInfo(null)

    try {
      runInAction(() => {
        this._loading = true
        this._loadingIds.set(id, id)
      })

      this._cancelRequest.init()

      const { data } = await IntegrationsApi.getContactsIntegrationsInfusionsoftInfo(id, {
        cancelToken: this._cancelRequest.token,
      })

      if (!data) {
        this._infusionsoftInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationInfusionsoftInfo(data)
      this._infusionsoftInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationInfusionsoftInfo: ', e)

      this._infusionsoftInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this._loading = false
        this._loadingIds.delete(id)
      })
    }
  }

  getContactIntegrationHubspotInfo = (id: number) => {
    if (isNaN(id)) return

    const info = this._hubspotInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationHubspotInfoByType(id)
    }

    return info
  }

  getContactIntegrationActiveCampaignInfo = (id: number) => {
    const info = this._activeCampaignInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationActiveCampaignInfo(id)
    }

    return info
  }

  getContactIntegrationPipedriveInfo = (id: number) => {
    const info = this._pipedriveInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationPipedriveInfo(id)
    }

    return info
  }

  getContactIntegrationSalesforceInfo = (id: number) => {
    const info = this._salesforceInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationSalesforceInfo(id)
    }

    return info
  }

  getContactIntegrationInfusionsoftInfo = (id: number) => {
    const info = this._infusionsoftInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationInfusionsoftInfo(id)
    }

    return info
  }
}
