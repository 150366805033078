import { AxiosError, CancelTokenSource } from 'axios'
import { type IReactionDisposer, makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import { ComplianceApi } from 'entities/Compliance'
import type {
  ICompanyLocation,
  IResponseTwilioServiceError,
} from 'entities/Compliance/api/complianceType'
import { organizationStore } from 'entities/Organization'
import { OrganizationBusinessProfile } from 'entities/Compliance/model'
import { BusinessProfileRoutes } from 'pages/settings/pages/compliance/pages/businessProfile/route/type'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { GeneralInfoStore } from 'pages/settings/pages/compliance/ui/CommonGeneralInfo/store/generalInfoStore'
import { PeopleToContactStore } from 'pages/settings/pages/compliance/ui/CommonPeopleToContact/store/peopleToContactStore'
import { CompanyLocationStore } from 'pages/settings/pages/compliance/pages/businessProfile/pages/CompanyLocation/store/CompanyLocationStore'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { BusinessInfoStore } from 'pages/settings/pages/compliance/ui/CommonBusinessInfo/store/businessInfoStore'
import { BusinessProfileNavigationStore } from './businessProfileNavigationStore'

const titleMap = {
  [BusinessProfileRoutes.root]: '',
  [BusinessProfileRoutes.companyLocation]: 'Company location',
  [BusinessProfileRoutes.businessInfo]: 'Business info',
  [BusinessProfileRoutes.generalInfo]: 'General info',
  [BusinessProfileRoutes.peopleToContact]: 'People to contact',
  [BusinessProfileRoutes.termsAndPrivacy]: 'Terms and Privacy',
}

export class BusinessProfileStepsStore {
  private readonly _businessProfileNavigationStore: BusinessProfileNavigationStore

  private readonly _generalInfoStore: GeneralInfoStore
  private readonly _businessInfoStore: BusinessInfoStore
  private readonly _peopleToContactStore: PeopleToContactStore
  private readonly _companyLocationStore: CompanyLocationStore

  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    this._generalInfoStore = new GeneralInfoStore(this._complianceLayerStore)
    this._businessInfoStore = new BusinessInfoStore()
    this._peopleToContactStore = new PeopleToContactStore(this._complianceLayerStore)
    this._companyLocationStore = new CompanyLocationStore(this)
    this._businessProfileNavigationStore = new BusinessProfileNavigationStore(
      this._complianceLayerStore
    )
    makeAutoObservable(this)
    this.init()
  }

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _isTermsAndPrivacyChecked = false

  private _errors: IResponseTwilioServiceError[] = []

  get businessProfileNavigationStore() {
    return this._businessProfileNavigationStore
  }

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  get companyLocationStore() {
    return this._companyLocationStore
  }

  get generalInfoStore() {
    return this._generalInfoStore
  }

  get peopleToContactStore() {
    return this._peopleToContactStore
  }

  get businessInfoStore() {
    return this._businessInfoStore
  }

  get errorStore() {
    return this.complianceLayerStore.errorStore
  }

  get isTermsAndPrivacyChecked() {
    return this._isTermsAndPrivacyChecked
  }

  get stepLoading() {
    return this._complianceLayerStore.stepsStore.stepLoading
  }

  get errors() {
    return this._errors
  }

  get errorMessage() {
    return this.errors.map((error) => error.error_message).join('\n')
  }

  get allPaths() {
    return Object.values(BusinessProfileRoutes)
  }

  init = () => {
    const { businessInformation } = this.complianceLayerStore
    this._generalInfoStore.setEinSearch(businessInformation?.registration_number || '')
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
    this.complianceLayerStore.errorStore.reset()
  }

  getStep = (path: BusinessProfileRoutes, direction: -1 | 1): BusinessProfileRoutes | '' => {
    const allPaths = this.allPaths
    const currentIndex = allPaths.indexOf(path)
    const nextIndex = currentIndex + direction

    if (currentIndex !== -1 && nextIndex >= 0 && nextIndex < allPaths.length) {
      return allPaths[nextIndex]
    }

    return path
  }

  getLastStep = (path: BusinessProfileRoutes): BusinessProfileRoutes | '' => {
    return this.getStep(path, -1)
  }

  getNextStep = (path: BusinessProfileRoutes): BusinessProfileRoutes | '' => {
    return this.getStep(path, 1)
  }

  getSettingsStep = (path: BusinessProfileRoutes) => {
    const allPaths = this.allPaths

    return allPaths.indexOf(path)
  }

  setStepLoading = (value: boolean) => {
    this.complianceLayerStore.stepsStore.setStepLoading(value)
  }

  toggleIsTermsAndPrivacyChecked = () => {
    this._isTermsAndPrivacyChecked = !this._isTermsAndPrivacyChecked
  }

  getBusinessInformation = (step: number) => {
    const { einItem } = this._generalInfoStore

    const { id: organizationId } = organizationStore || {}

    if (!einItem || !organizationId || !this._companyLocationStore.companyLocation) return null

    const {
      websiteUrl,
      noWebsiteUrl,
      activeRegionOfOperationIds,
      activeBusinessTypeId,
      socialMediaUrl,
    } = this._businessInfoStore

    const getFormStep = (): string => {
      return `form_step_${step}`
    }

    return {
      business_information: {
        business_name: einItem.name,
        business_type_id: activeBusinessTypeId,
        industry_id: this._businessInfoStore.activeBusinessIndustryId || 0,
        city: einItem.city,
        country: this._companyLocationStore.companyLocation,
        organization_id: organizationId,
        regions_of_operation: activeRegionOfOperationIds,
        reg_progress_status: getFormStep(),
        registration_number: this._generalInfoStore.einFromSearch,
        irs_ein_document: this._generalInfoStore.irsEinAttachment,
        registration_type_id: this._companyLocationStore.isUsaLocation ? 2 : 1,
        state_id: einItem.state,
        street_address_1: einItem.street1,
        street_address_2: einItem.street2 || null,
        website_url: noWebsiteUrl ? null : websiteUrl,
        without_website: noWebsiteUrl,
        social_media_url: noWebsiteUrl ? socialMediaUrl : null,
        zip_code: einItem.zip,
      },
      step,
    }
  }

  submitTermsAndPrivacy = async () => {
    try {
      this.setStepLoading(true)

      const { data } = await ComplianceApi.setBpTerms({
        terms_accepted: this.isTermsAndPrivacyChecked,
        step: 5,
      })

      this.complianceLayerStore.setOrganizationBusinessProfile(
        new OrganizationBusinessProfile(data)
      )

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.root}`,
      })
    } catch (e) {
      logger.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  getProgressInfoTitle: (pathname: BusinessProfileRoutes) => string = (pathname) => {
    return titleMap[pathname]
  }

  setDataFromBusinessProfileRequest = ({
    businessInformation,
    pointsOfContact,
  }: OrganizationBusinessProfile) => {
    if (businessInformation) {
      this.complianceLayerStore.setBusinessInformation(businessInformation.origin)
      this._companyLocationStore.setCompanyLocation(businessInformation.country as ICompanyLocation)
      this._generalInfoStore.setEinItem({
        city: businessInformation.city,
        ein: businessInformation.registration_number,
        ein_formatted: businessInformation.registration_number,
        name: businessInformation.business_name,
        state: businessInformation.state_id,
        street1: businessInformation.street_address_1,
        street2: businessInformation.street_address_2,
        zip: businessInformation.zip_code,
      })

      this._generalInfoStore.setEinSearch(businessInformation.registration_number)
      this._generalInfoStore.setIrsEinAttachment(businessInformation.irs_ein_document)
      businessInformation.irs_ein_document &&
        this._generalInfoStore.setIrsEinAttachment(businessInformation.irs_ein_document)

      this._businessInfoStore.setBusinessIndustry(businessInformation.industry_id)
      this._businessInfoStore.setBusinessType(businessInformation.business_type_id)
      this._businessInfoStore.setActiveRegionOfOperationIds(
        businessInformation.regions_of_operation.map(({ id }) => id)
      )
      this._businessInfoStore.setWebsiteUrl(
        businessInformation.website_url,
        Boolean(businessInformation.website_url?.length)
      )
      this._businessInfoStore.setNoWebsiteUrl(Boolean(businessInformation.social_media_url))
      this._businessInfoStore.setSocialMediaUrl(businessInformation.social_media_url || null)
    }

    if (pointsOfContact) {
      const firstContact = pointsOfContact.firstContact
      const secondContact = pointsOfContact.secondContact

      if (firstContact) {
        this._peopleToContactStore.setPrimaryFromResponse(firstContact)
      }

      if (secondContact) {
        this._peopleToContactStore.setSecondaryFromResponse(secondContact)
      }
    }
  }

  setErrors = (errors: IResponseTwilioServiceError[] | undefined) => {
    if (errors) {
      this._errors = errors
    }
  }

  decreaseStep = (path: BusinessProfileRoutes) => {
    const lastStep = this.getLastStep(path)

    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.businessProfile.root}/${lastStep}`,
    })
  }

  closeSetting = () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.businessProfile.root}`,
    })
  }

  businessInfoNextStepClick = async () => {
    const businessInformation = this?.getBusinessInformation(3)

    if (!businessInformation) return

    try {
      this.setStepLoading(true)

      const { data } = await ComplianceApi.setBpBusinessInformation(businessInformation)
      const organizationBusinessProfile = new OrganizationBusinessProfile(data)

      this.complianceLayerStore.setOrganizationBusinessProfile(organizationBusinessProfile)
      this.setDataFromBusinessProfileRequest(organizationBusinessProfile)

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.businessProfile.root}/${BusinessProfileRoutes.peopleToContact}`,
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  generalInfoNextStepClick = async () => {
    const businessInformation = this.getBusinessInformation(2)

    if (!businessInformation) return

    try {
      this.setStepLoading(true)

      const { data } = await ComplianceApi.setBpBusinessInformation(businessInformation)
      const organizationBusinessProfile = new OrganizationBusinessProfile(data)
      this.complianceLayerStore.setOrganizationBusinessProfile(organizationBusinessProfile)
      this.setDataFromBusinessProfileRequest(organizationBusinessProfile)

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.businessProfile.root}/${BusinessProfileRoutes.businessInfo}`,
      })
    } catch (e) {
      if (e instanceof AxiosError) {
        const { data } = e.response || {}
        this.errorStore.prepareFieldsErrorAndSave(data)
      }

      console.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  nextStepClick = async () => {
    if (!this.peopleToContactStore.primaryContact.jobPosition) return

    const pointsOfContacts = [this.peopleToContactStore.firstContactToBackend]

    if (this.peopleToContactStore.isSecondContact) {
      pointsOfContacts.push(this.peopleToContactStore.secondContactToBackend)
    }

    try {
      this.complianceLayerStore?.stepsStore.setStepLoading(true)

      const { data } = await ComplianceApi.setBpPointsOfContact({
        contacts_confirmed: this.peopleToContactStore.contactsConfirmed,
        points_of_contact: pointsOfContacts,
        step: 4,
      })
      const organizationBusinessProfile = new OrganizationBusinessProfile(data)
      this.complianceLayerStore?.setOrganizationBusinessProfile(organizationBusinessProfile)

      uiStore.changeRoute({
        path: `/${SettingsPath.compliance.businessProfile.root}/${BusinessProfileRoutes.termsAndPrivacy}`,
      })
    } catch (e) {
      if (e instanceof AxiosError) {
        const { data } = e.response || {}
        this.errorStore?.prepareFieldsErrorAndSave(data)
      }

      console.error(e)
    } finally {
      this.complianceLayerStore?.stepsStore?.setStepLoading(false)
    }
  }
}
