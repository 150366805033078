import { Label } from 'shared/ui/Label'
import styles from 'widgets/BroadcastView/ui/ContactsContent/ui/styles.module.scss'
import { ContactsTrigger } from './ContactsTrigger'

export const ContactsContent = () => {
  return (
    <div className={styles.wrap}>
      <Label
        label={{
          text: 'Contacts',
          horizontal: true,
          className: styles.label,
        }}
      />
      <ContactsTrigger />
    </div>
  )
}
