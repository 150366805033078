import { createContext, useContext } from 'react'
import { TextToSpeechWidgetStore } from '../store/textToSpeechWidgetStore'

export const TextToSpeechContext = createContext<TextToSpeechWidgetStore | null>(null)

export function useTextToSpeechContext() {
  const context = useContext(TextToSpeechContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with TextToSpeechContext')
  }
  return context
}
