// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3aoGtgh0LqFTHFAUE6R{width:340px}.lySDyqa5OPde9nOYValw{height:40px;padding-left:12px;display:flex;align-items:center;border-bottom:1px solid var(--background-neutral-default)}.eTgAI0w_91rq6DkR4_1U{padding:4px}", "",{"version":3,"sources":["webpack://./src/widgets/TextToSpeech/ui/TextToSpeechVoice/VoicesContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEF,sBACE,WAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,yDAAA,CAEF,sBACE,WAAA","sourcesContent":[".wrap{\n  width: 340px;\n}\n.header{\n  height: 40px;\n  padding-left: 12px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid var(--background-neutral-default);\n}\n.content{\n  padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "_3aoGtgh0LqFTHFAUE6R",
	"header": "lySDyqa5OPde9nOYValw",
	"content": "eTgAI0w_91rq6DkR4_1U"
};
export default ___CSS_LOADER_EXPORT___;
