import { useEffect, FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Button, SpinnerLoader } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { integrationsStore } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { usersStore } from 'entities/Users'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { ContactMeetDropdown } from 'widgets/ContactMeetDropdown'
import { ContactProfileInfo } from 'widgets/ContactProfileInfo'
import { ContactActions } from './ContactActions/ContactActions'
import { ContactCallButton } from './ContactCallButton/ContactCallButton'

import styles from './styles.module.scss'

export type IContactProfileCardProps = {
  borders?: {
    left?: boolean
    right?: boolean
    top?: boolean
    bottom?: boolean
    all?: boolean
  }
}

export const ContactsProfileCard: FC<IContactProfileCardProps> = observer(({ borders }) => {
  const contactsDetailsStore = useContactsDetailsContext()
  const conversation = contactsDetailsStore.conversation
  let contact = contactsDetailsStore.selectedContact as Contact | undefined

  if (contact && contact?.id !== -1) {
    contact = contactsStore.getItem(contact.id)
  }
  const newContactDisabled = contact?.id === 0 || contact?.id === -1
  const { isShouldRequestAccess } = usersStore.user
  const disabled = newContactDisabled || isShouldRequestAccess

  useEffect(() => {
    integrationsStore.fetchIntegrations()
  }, [])

  if (!contact) {
    return (
      <div
        className={classnames(styles.wrap, styles.loader, {
          [styles.borderLeft]: borders?.left,
          [styles.borderRight]: borders?.right,
          [styles.borderTop]: borders?.top,
          [styles.borderBottom]: borders?.bottom,
          [styles.borderAll]: borders?.all,
        })}
      >
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.borderLeft]: borders?.left,
        [styles.borderRight]: borders?.right,
        [styles.borderTop]: borders?.top,
        [styles.borderBottom]: borders?.bottom,
        [styles.borderAll]: borders?.all,
      })}
    >
      <ContactProfileInfo store={contactsDetailsStore.contactProfileInfoStore} />
      <div className={styles.actions}>
        {!conversation && (
          <Button
            size='medium'
            typeBtn='contained'
            contained='secondary'
            icon='messageTextCircle02'
            text='Chat'
            disabled={disabled}
            tooltipProps={{
              label: 'Send message',
            }}
            onClick={() => {
              contact && contactsDetailsStore.onClickMessage?.(contact)
            }}
          />
        )}

        <ContactCallButton />

        <a href={contact?.email ? `mailto:${contact.email}` : '#'}>
          <Button
            size='medium'
            typeBtn='contained'
            contained='secondary'
            icon='email'
            text='Email'
            disabled={newContactDisabled || !contact?.email}
            tooltipProps={
              !contact?.email
                ? {
                    label: 'Add an email address to enable email sending.',
                    placement: 'bottom',
                    width: 169,
                  }
                : undefined
            }
          />
        </a>
        <ContactMeetDropdown contactId={contact.id} />
        <ContactActions contact={contact} />
      </div>
    </div>
  )
})
