import { DropResult } from 'react-beautiful-dnd'
import { ReactNode, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { CopyWrapper } from 'shared/ui'
import { type IntegrationProperty } from 'entities/Integrations/model'
import { EmptyState } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/EmptyState'
import {
  type IItemRowProps,
  ItemRow,
} from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ItemRow/ItemRow'
import { DraggingItem } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/DraggingItem/DraggingItem'
import { DragEndDropSection } from 'widgets/ContactsDetails/ui/DragEndDropSection/DragEndDropSection'
import styles from './styles.module.scss'

type IContactTabProps = {
  item?: {
    title: string
  }
  titleView: string
  properties: IntegrationProperty[]
  link: string
  integrationLabel: string
  titleEmpty: string
  actions?: ReactNode
  handleReorder?: (result: DropResult) => void
  handleClear?: (key: string) => void
  labelType?: IItemRowProps['labelType']
  itemsDnD?: string[]
}

export const SectionTab: FC<IContactTabProps> = observer(
  ({
    item,
    properties,
    link,
    integrationLabel,
    actions,
    titleView,
    titleEmpty,
    handleClear,
    handleReorder,
    labelType,
    itemsDnD,
  }) => {
    if (!item) return <EmptyState>{titleEmpty}</EmptyState>

    const content = () => {
      if (itemsDnD) {
        const items = itemsDnD.reduce<IntegrationProperty[]>((state, key) => {
          const property = properties.find((item) => item.key === key)

          if (property) state.push(property)

          return state
        }, [])

        return (
          <DragEndDropSection
            droppableId={'contact-integration-properties'}
            onDragEnd={(result) => {
              handleReorder?.(result)
            }}
            items={items}
            renderItem={(item, provided) => {
              return (
                <DraggingItem
                  dragHandleProps={provided.dragHandleProps}
                  hideDraggingIcon={false}
                  key={item.key}
                  content={
                    <ItemRow
                      key={item.key}
                      item={item}
                      clearAction={handleClear}
                      labelType={'field'}
                    />
                  }
                />
              )
            }}
          />
        )
      }

      return (
        <div className={styles.companyWrapper}>
          {properties.map((property) => {
            return (
              <ItemRow
                key={property.key}
                item={property}
                clearAction={handleClear}
                labelType={'simple'}
              />
            )
          })}
        </div>
      )
    }

    return (
      <div className={styles.contentWrapper}>
        <div className={styles.headerWrapper}>
          <CopyWrapper
            copyValue={item.title || titleView}
            clickableTitle
            data={{
              title: item.title || titleView,
              link: link,
              label: integrationLabel,
            }}
          />
        </div>
        {content()}
        {actions}
      </div>
    )
  }
)
