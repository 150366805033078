import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { TextField, makeTextFieldAutoFocus, makeTextFieldClear } from 'shared/ui/TextField'
import { type SegmentModalStore } from '../store/segmentModalStore'

type IModalContentProps = {
  segmentModalStore: SegmentModalStore
}

export const ModalContent: FC<IModalContentProps> = observer(({ segmentModalStore }) => {
  const { isNameLengthError, segmentName, handleChangeValue } = segmentModalStore

  const onChange = (value: string) => {
    handleChangeValue(value)
  }

  return (
    <TextField
      ariaLabel='NewSegmentModalInput'
      size='medium'
      variant='stroke'
      error={isNameLengthError ? 'Error' : undefined}
      InputProps={{ placeholder: 'Enter name' }}
      value={segmentName}
      onChange={onChange}
      rightActions={[makeTextFieldClear()]}
      mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
    />
  )
})
