// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._KCMBBFUHJ4NNirCHlGd{padding-inline:24px;border-right:1px solid var(--border-neutral-default)}._KCMBBFUHJ4NNirCHlGd .iseuZRlr1e_Z9xR2CPrn{display:flex;align-items:center;justify-content:center;height:100%}.JOaUrT0NKu9kJo83bVpl{height:320px;overflow-y:scroll;margin-right:-21px;padding-right:15px;padding-bottom:20px;display:grid;gap:8px;align-content:start}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/SavedRepliesContent/LeftContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,oDAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAIJ,sBACE,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,kBAAA,CACA,mBAAA,CACA,YAAA,CACA,OAAA,CACA,mBAAA","sourcesContent":[".left{\n  padding-inline: 24px;\n  border-right: 1px solid var(--border-neutral-default);\n\n  .empty {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n  }\n}\n\n.wrapList{\n  height: 320px;\n  overflow-y: scroll;\n  margin-right: -21px;\n  padding-right: 15px;\n  padding-bottom: 20px;\n  display: grid;\n  gap: 8px;\n  align-content: start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"left": "_KCMBBFUHJ4NNirCHlGd",
	"empty": "iseuZRlr1e_Z9xR2CPrn",
	"wrapList": "JOaUrT0NKu9kJo83bVpl"
};
export default ___CSS_LOADER_EXPORT___;
