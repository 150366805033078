import { useNavigate } from 'react-router'
import { IconButton } from 'shared/ui'
import styles from './styles.module.scss'

export const RightMenu = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.rightMenu}>
      <IconButton
        color='transparent'
        ariaLabel='close_setting'
        icon='close'
        size='medium'
        onClick={() => navigate(-1)}
      />
    </div>
  )
}
