import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { toastStore } from 'shared/ui'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'
import { AdminSendEmailUsageReportAction } from 'pages/admin/pages/organizations/modals/AdminSendEmailUsageReport/AdminSendEmailUsageReportAction'
import { AdminSendEmailUsageReportContent } from 'pages/admin/pages/organizations/modals/AdminSendEmailUsageReport/AdminSendEmailUsageReportContent'

class AdminSendEmailUsageReportStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''

  report: 'report' | 'totals-report' = 'report'
  month = uiStore.dayjs().format('MM')
  year = uiStore.dayjs().format('YYYY')
  email = ''
  emailError = ''
  isOwner = false

  organization: IResponseAdminOrganizationById | null = null

  loading = false

  get disabledSubmit() {
    return !this.email && !this.isOwner
  }

  reset = () => {
    this.modalId = ''
    this.organization = null
    this.report = 'report'
    this.month = uiStore.dayjs().format('MM')
    this.year = uiStore.dayjs().format('YYYY')
    this.email = ''
    this.isOwner = false
  }

  setReport = (value: typeof this.report) => {
    this.report = value
  }

  setMonth = (value: string) => {
    this.month = value
  }

  setYear = (value: string) => {
    this.year = value
  }

  setIsOwner = (isOwner: boolean) => {
    if (isOwner && this.emailError) {
      this.emailError = ''
    }
    this.isOwner = isOwner
  }

  setEmail = (email: string) => {
    this.email = email
  }

  setEmailError = (error: string) => {
    this.emailError = error
  }

  onCheckEmail = () => {
    if (!this.isOwner && !this.email) {
      this.emailError = 'The email field is required when not sending to Owner.'
    }
  }

  onSubmit = async () => {
    try {
      if (!this.organization?.id) return
      runInAction(() => {
        this.loading = true
      })
      await AdminOrganizationsApi.createUsageReport({
        id: this.organization.id,
        data: {
          report_name: this.report,
          cc_owner: this.isOwner,
          email: this.email || undefined,
          month: this.month,
          year: this.year,
        },
      })
      modalStore.closeModal(this.modalId)
      toastStore.add({
        id: nanoid(),
        type: 'success',
        title: 'Usage report was sent successfully',
      })
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data?.email?.length) {
            this.emailError = e.response?.data?.email[0]
          }
        }
      })
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  onOpenModal = (organization: IResponseAdminOrganizationById) => {
    this.reset()
    this.organization = organization
    this.modalId = nanoid()
    modalStore.addModal({
      id: this.modalId,
      title: 'Send email usage report',
      ModalContent: AdminSendEmailUsageReportContent,
      width: 480,
      ModalActions: AdminSendEmailUsageReportAction,
    })
  }
}

export const adminSendEmailUsageReportStore = new AdminSendEmailUsageReportStore()
