import React from 'react'
import classnames from 'classnames'
import { ActionItem, Actions } from 'shared/ui'
import styles from './styles.module.scss'

export type IContainerButtonsProps = {
  leftActions?: ActionItem[]
  rightActions?: ActionItem[]
  disabled?: boolean
  padding?: string
  gap?: number
  showLeftActionsItems?: number
  showRightActionsItems?: number
}

export const ContainerActions: React.FC<IContainerButtonsProps> = ({
  leftActions = [],
  rightActions = [],
  showLeftActionsItems,
  showRightActionsItems,
  disabled = false,
  padding,
  gap,
}) => {
  return (
    <div
      className={classnames(styles.wrap, {
        [styles.disabled]: disabled,
      })}
      style={{ padding }}
    >
      <div className={styles.box}>
        {leftActions && (
          <Actions showItems={showLeftActionsItems} actions={leftActions} gap={gap} />
        )}
      </div>
      <div className={styles.box}>
        {rightActions && <Actions showItems={showRightActionsItems} actions={rightActions} />}
      </div>
    </div>
  )
}
