// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D28kmvShWO1_kiFljLg3{display:flex;justify-content:space-between;align-items:center}.q69h4pKJnOjKRVx317SC{margin-left:8px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BusinessProfile/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,eAAA","sourcesContent":[".content {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.icon {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "D28kmvShWO1_kiFljLg3",
	"icon": "q69h4pKJnOjKRVx317SC"
};
export default ___CSS_LOADER_EXPORT___;
