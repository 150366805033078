import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { IResponseAttachmentUpload } from 'entities/Attachment/api/types'

type IHandlerEvent = (data: IResponseAttachmentUpload) => void

export const EventVideoConvert = {
  subscribe: (handler: IHandlerEvent) =>
    websocket.on(WebsocketEvent.AttachmentVideoConverted, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.AttachmentVideoConverted, handler),
}

export const useEventVideoConvert = (handler: IHandlerEvent) =>
  useEffect(() => EventVideoConvert.subscribe(handler), [])
