import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { Instance } from '@popperjs/core'
import { Slider, Tooltip } from 'shared/ui'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { MediaControllerStore } from 'widgets/PresentationMode/store/MediaControllerStore'
import styles from './styles.module.scss'

export const MediaProgressBar = observer(
  ({ mediaControllerStore }: { mediaControllerStore: MediaControllerStore }) => {
    const { progress, setProgress, duration } = mediaControllerStore
    const [moveProgress, setMoveProgress] = useState<number | null>(null)
    const handleChange = (event: Event, newValue: number | number[]) => {
      setProgress(newValue as number)
    }
    const onMouseLeave = () => {
      setMoveProgress(null)
    }

    const newTime = secondsToTime(((moveProgress || 0) * duration) / 100)

    const positionRef = useRef<{ x: number; y: number }>({
      x: 0,
      y: 0,
    })
    const popperRef = useRef<Instance>(null)
    const areaRef = useRef<HTMLDivElement>(null)

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
      positionRef.current = { x: event.clientX, y: event.clientY }

      if (popperRef.current != null) {
        // @ts
        popperRef.current.update()
      }
    }

    return (
      <>
        <Tooltip
          label={newTime}
          placement={'top'}
          PopperProps={{
            popperRef,
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  positionRef.current.x,
                  areaRef.current!.getBoundingClientRect().y,
                  0,
                  0
                )
              },
            },
          }}
        >
          <div
            ref={areaRef}
            className={styles.wrap}
            onMouseMove={(e) => {
              const leftPosition = e.clientX - e.currentTarget.getBoundingClientRect().left
              const clientWidth = e.currentTarget.clientWidth
              const newProgress = (leftPosition / clientWidth) * 100
              setMoveProgress(newProgress)
              handleMouseMove(e)
              // setProgress && setProgress(newProgress)
            }}
            onMouseLeave={onMouseLeave}
          >
            <Slider value={progress} onChange={handleChange} />
          </div>
        </Tooltip>
      </>
    )
  }
)
