import { toJS } from 'mobx'
import type { IResponseFilterSegment } from 'entities/Segment'
import type { FilterGroups } from 'widgets/FilterEditor/model'

export const groupsToJson = ({ groups }: FilterGroups): Record<string, unknown>[][] =>
  groups
    .filter((group) => !group.isEmpty)
    .map(({ filters }) =>
      filters.reduce((json, { key, type, operator, payload }) => {
        const item = {} as Record<string, unknown>

        item.key = key
        item.type = type

        if (operator != null) item.operator = operator

        if (!!payload.from) item.from = payload.from
        if (!!payload.to) item.to = payload.to
        if (!!payload.value) item.value = toJS(payload.value)

        json.push(item)

        return json
      }, [] as Record<string, unknown>[])
    )

export const segmentFiltersToJson = (filters: IResponseFilterSegment[]) =>
  filters.map((item) => {
    const records = [] as Record<string, unknown>[]

    item.custom_filters.map((customFilter) => {
      const record = {} as Record<string, unknown>

      record.key = customFilter.key
      record.type = customFilter.type
      record.operator = customFilter.operator

      if (!!customFilter.to) record.to = customFilter.to
      if (!!customFilter.from) record.from = customFilter.from
      if (!!customFilter.value) record.value = toJS(customFilter.value)

      records.push(record)
    })

    item.filters.map((filter) => {
      const record = {} as Record<string, unknown>

      record.key = filter.key
      record.type = filter.type || filter.key
      record.operator = filter.operator

      if (typeof filter.is_empty == 'number') record.is_empty = filter.is_empty
      if (!!filter.to) record.to = filter.to
      if (!!filter.from) record.from = filter.from
      if (!!filter.value) record.value = toJS(filter.value)

      records.push(record)
    })

    item.integration_lists?.map((integration) => {
      const record = {} as Record<string, unknown>

      record.key = integration.integration_key
      record.value = integration.list_id

      records.push(record)
    })

    return records
  })
