import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { messagingRegionModalStore } from 'widgets/MessagingRegionModal'

export const MessagingRegionModalActions = observer(() => {
  const { selectedRegion, handleUpdateRegion, loading } = messagingRegionModalStore

  return (
    <Button
      fullWidth
      loading={loading}
      disabled={!selectedRegion || loading}
      typeBtn={'contained'}
      text={'Next'}
      onClick={handleUpdateRegion}
    />
  )
})
