import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown } from 'shared/ui'
import { DropdownContent, IDropdownItem } from 'shared/ui/Dropdown'
import { contactsStore } from 'entities/Contacts'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { contactMeetLink } from 'widgets/ContactMeetDropdown'

import styles from './styles.module.scss'

export type IContactMeetDropdownProps = {
  contactId: number
}

export enum MeetTypes {
  GOOGLE = 'google',
  OUTLOOK = 'outlook',
  HUBSPOT = 'hubspot',
  INFUSIONSOFT = 'infusionsoft',
}

const ContactMeetDropdown: React.FC<IContactMeetDropdownProps> = observer(({ contactId }) => {
  const contact = contactsStore.getItem(contactId)

  const integration = integrationsStore.getUserIntegration(contact?.integration_key)

  const hasHubspotIntegration = integration && contact?.integration_key === IntegrationKey.hubspot
  const hasInfusionsoftIntegration =
    integration && contact?.integration_key === IntegrationKey.infusionsoft

  const handleRedirectToMeet = (type: MeetTypes) => {
    const link = contactMeetLink(type, contactId)

    if (!link) return
    window.open(link, '_blank')
  }

  const disabled = contact?.id === 0 || contact?.id === -1 || !contact

  const dropdownItems: IDropdownItem[] = [
    {
      id: 3,
      iconL: 'google',
      label: 'Google (online)',
      onClick: () => handleRedirectToMeet(MeetTypes.GOOGLE),
    },
    {
      id: 4,
      iconL: 'outlook',
      label: 'Outlook (online)',
      onClick: () => handleRedirectToMeet(MeetTypes.OUTLOOK),
    },
  ]

  if (hasHubspotIntegration) {
    dropdownItems.unshift({
      id: 1,
      iconL: 'hubspot',
      label: 'HubSpot',
      onClick: () => handleRedirectToMeet(MeetTypes.HUBSPOT),
    })
  }

  if (hasInfusionsoftIntegration) {
    dropdownItems.unshift({
      id: 2,
      iconL: 'infusionsoft',
      label: 'Keap',
      onClick: () => handleRedirectToMeet(MeetTypes.INFUSIONSOFT),
    })
  }

  useEffect(() => {
    !integrationsStore.hasIntegrations && integrationsStore.fetchIntegrations()
  }, [])

  return (
    <Dropdown
      ariaLabel={'ContactMeetDropdown'}
      width={166}
      sizeTriggerButton='medium'
      buttonProps={{
        text: 'Meet',
        icon: 'meeting',
        size: 'medium',
        typeBtn: 'contained',
        contained: 'secondary',
        disabled,
      }}
      customComponent={() => (
        <DropdownContent>
          {dropdownItems.map((item) => (
            <Button
              key={item.id}
              className={styles.button}
              iconProps={{
                insideBox: true,
                padding: 2,
              }}
              fontWeight={400}
              onClick={item.onClick}
              typeBtn='menuItem'
              icon={item.iconL}
              text={item.label}
              fullWidth
            />
          ))}
        </DropdownContent>
      )}
      typeButton='button'
      disabled={disabled}
    />
  )
})

export { ContactMeetDropdown }
