export function placeCaretAtStart(el: HTMLDivElement | Node, startOffset?: number) {
  if (
    typeof window.getSelection != 'undefined' &&
    typeof window.document.createRange != 'undefined'
  ) {
    const node = el.firstChild ? el.firstChild : el
    const range = document.createRange()
    range.selectNodeContents(node)
    if (startOffset) {
      range.setStart(node, startOffset)
    }

    range.collapse(true)
    const sel = window.getSelection()
    if (sel) {
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}
