import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { errorLogger, globalWatchError, removeGlobalWatchError } from 'entities/EventLog'
import { ErrorBoundaryFallback } from 'app/layouts/ErrorBoundaryFallback'
import { appRouterContent } from 'app/appvue/main/router/router'

window.initAppReact = () => {
  const appReact = createRoot(document.getElementById('app-react') as HTMLElement)

  globalWatchError()

  if (appReact) {
    window.resetAppReact = () => {
      appReact.unmount()

      removeGlobalWatchError()
    }

    appReact.render(
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorLogger}>
        <RouterProvider router={appRouterContent} />
      </ErrorBoundary>
    )
  }
}
