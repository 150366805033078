import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { debounce } from 'lodash'
import { SearchElement, Dropdown, Avatar } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { SearchTypesEnum, useSearchDropdownContext } from 'widgets/SearchDropdown'
import { ContactsDropdownContent } from 'widgets/SearchDropdown/ui/DropdownContent/ContactsDropdownContent/ContactsDropdownContent'
import styles from './styles.module.scss'

type ContactsElementProps = {
  parentsDropdownId?: string[]
}

export const ContactsElement: React.FC<ContactsElementProps> = observer(({ parentsDropdownId }) => {
  const {
    searchDropdownAssigneeStore,
    searchDropdownContactsStore,
    newType,
    isDirtyFilter,
    deleteType,
    isShowMore,
    isOpen,
  } = useSearchDropdownContext()
  const { hasSelectedItems, selectedItemsCount, firstSelectedItemId, selectedItems } =
    searchDropdownContactsStore
  // TODO: Fix position of child dropdown when child and parent dropdown render in 1 time
  const [show, setShow] = useState(false)

  const onShow = () => {
    const showDropdown =
      (newType === SearchTypesEnum.contacts && isShowMore) ||
      (newType === SearchTypesEnum.contacts && isOpen && !isDirtyFilter)

    setShow(showDropdown)
  }

  const showDebounce = debounce(onShow, 300)

  useEffect(() => {
    showDebounce()
  }, [isShowMore, newType, isOpen])

  const Label = () => {
    const firstItem = contactsStore.getItem(firstSelectedItemId)

    if (hasSelectedItems && selectedItemsCount === 1 && firstItem) {
      return (
        <div className={styles.container}>
          <div className={styles.avatar}>
            <Avatar size={16} info={firstItem?.avatarInfo} />
          </div>
          <span className={styles.selectedLabel}>{firstItem.name}</span>
        </div>
      )
    }

    return (
      <div className={styles.container}>
        <div
          className={styles.avatars}
          style={{ width: (selectedItemsCount < 3 ? selectedItemsCount : 3) * 12 }}
        >
          {selectedItems.slice(0, 3).map((item, idx) => (
            <div className={styles.avatar} key={item.id} style={{ left: idx * 12, zIndex: idx }}>
              <Avatar size={16} info={item.avatarInfo} />
            </div>
          ))}
        </div>
        <span className={styles.selectedLabel}>{selectedItemsCount} contacts</span>
      </div>
    )
  }

  return (
    <Dropdown
      ariaLabel={'ContactsElement'}
      parentsDropdownId={parentsDropdownId}
      onToggle={searchDropdownAssigneeStore.reset}
      iconButtonProps={{
        size: 'medium',
        fontSize: 20,
        ariaLabel: 'ContactsElement_trigger',
      }}
      show={show}
      triggerComponent={(open) => (
        <SearchElement
          icon={'users'}
          type='Contact'
          contentDefaultValue='select'
          warningContent={!open && !hasSelectedItems}
          removeCallback={() => {
            deleteType(SearchTypesEnum.contacts)
          }}
          content={hasSelectedItems && <Label />}
        />
      )}
      placement={'bottom-start'}
      sizeTriggerButton={'medium'}
      variantTriggerButton={'icon'}
      customComponent={() => <ContactsDropdownContent store={searchDropdownContactsStore} />}
    />
  )
})
