import { observer } from 'mobx-react-lite'
import { Button, Dropdown, IDropdownItem } from 'shared/ui'
import type { INewSavedReplyStore } from 'widgets/SavedReplies'
import styles from './styles.module.scss'

interface ISavedReplyActionsProps {
  newSavedReplyStore: INewSavedReplyStore
  disabledVisibility: boolean
}

export const SavedReplyActions = observer(
  ({ newSavedReplyStore, disabledVisibility }: ISavedReplyActionsProps) => {
    const {
      isPublic,
      setPublic,
      onCancel,
      onCreate,
      disabled,
      loading,
      isEdit,
      messageFieldStore,
    } = newSavedReplyStore
    const onChange = (item: IDropdownItem) => {
      setPublic(item.id === 'everyone')
    }
    if (messageFieldStore.recordAudioState) {
      return null
    }
    return (
      <div className={styles.actions}>
        <Dropdown
          ariaLabel={'SavedReplyActions'}
          triggerComponent={(open) => (
            <Button
              text={isPublic ? 'Everyone' : 'Personal'}
              active={open}
              typeBtn={'outlined'}
              contained={'secondary'}
              size={'medium'}
              icon={isPublic ? 'users' : 'userFilled'}
            />
          )}
          placement={'bottom-start'}
          items={[
            {
              id: 'personal',
              label: 'Personal',
              iconL: 'userFilled',
              activeValue: !isPublic,
              wrapLabel: 'Visibility',
            },
            {
              id: 'everyone',
              label: 'Everyone',
              iconL: 'users',
              activeValue: isPublic,
            },
          ]}
          onChange={onChange}
          disabled={disabledVisibility}
        />
        <div className={styles.right}>
          <Button
            text={'Cancel'}
            typeBtn={'contained'}
            contained={'secondary'}
            size={'medium'}
            onClick={onCancel}
          />
          <Button
            text={isEdit ? 'Save' : 'Create'}
            typeBtn={'contained'}
            contained={'primary'}
            size={'medium'}
            disabled={disabled || loading}
            onClick={onCreate}
          />
        </div>
      </div>
    )
  }
)
