import { debounce } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import { logger } from 'shared/lib'
import {
  MAX_VALID_INTERNATIONAL_NUMBER_LENGTH,
  MAX_VALID_NUMBER_LENGTH,
  MIN_VALID_NUMBER_LENGTH,
} from 'shared/constants/limits'
import { ContactsApi } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'

type IPhoneFieldSearchStoreConfig = {
  addNewContactLocallyAfterCreate?: (item: Contact, params?: { isNew?: boolean }) => void
}

export class PhoneFieldSearchStore {
  private _number = ''
  private _error: string | null = null
  private _validNumberLoading = false
  private _isValidNumber = true
  private _isValidated = false
  private _debounceIsValidNumber: ReturnType<typeof debounce>
  private _config: IPhoneFieldSearchStoreConfig | null = null

  constructor() {
    this._debounceIsValidNumber = debounce(this._validateNumber, 1000)

    makeAutoObservable(this)
  }

  setConfig = (config: IPhoneFieldSearchStoreConfig) => {
    this._config = config
  }

  setIsValidated = (value: boolean) => {
    this._isValidated = value
  }

  setNumber = (value: string) => {
    this._number = value
  }

  setError = (value: string | null) => {
    this._error = value
  }

  resetError = () => {
    this.setError(null)
    this._isValidNumber = true
  }

  private _validateNumber = async (number: string) => {
    try {
      this._validNumberLoading = true
      const { data: isValid } = await ContactsApi.validateNumber({ number })

      runInAction(() => {
        this._isValidNumber = isValid
      })

      if (!isValid) {
        this.setError('Enter a valid phone number')
      }
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._validNumberLoading = false
      })
    }
  }

  validateNumber = async (phoneNumber: string) => {
    this._validNumberLoading = true
    this._debounceIsValidNumber(phoneNumber)
  }

  onChangeField = (value: string, prevValue: string) => {
    const hasPlusSign = value.startsWith('+')

    let correctNumber = value.replace(/\D/g, '')

    if (hasPlusSign) {
      correctNumber = '+' + correctNumber
    }

    const numberLength = correctNumber.length
    const maxNumberLength = correctNumber.includes('+')
      ? MAX_VALID_INTERNATIONAL_NUMBER_LENGTH
      : MAX_VALID_NUMBER_LENGTH
    if (numberLength > maxNumberLength) {
      this.setIsValidated(true)
      this.validateNumber(prevValue)
      return prevValue
    }

    if (numberLength >= MIN_VALID_NUMBER_LENGTH && numberLength <= maxNumberLength) {
      this.setIsValidated(true)
      this.validateNumber(correctNumber)
    }

    if (numberLength < MIN_VALID_NUMBER_LENGTH && this._isValidated) {
      this.setIsValidated(false)
    }

    return correctNumber
  }

  onChangeSearch = (item: Contact, params?: { isNew?: boolean }) => {
    this._config?.addNewContactLocallyAfterCreate?.(item, params)
  }

  get isValidNumberLoading() {
    return this._validNumberLoading
  }

  get number() {
    return this._number
  }

  get isValidated() {
    return this._isValidated
  }

  get error() {
    return this._error
  }

  get maxNumberLength() {
    return this._number.includes('+')
      ? MAX_VALID_INTERNATIONAL_NUMBER_LENGTH
      : MAX_VALID_NUMBER_LENGTH
  }

  get fullyNumber() {
    return (
      this._number.length >= MIN_VALID_NUMBER_LENGTH && this._number.length <= this.maxNumberLength
    )
  }
}
