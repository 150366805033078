import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import classNames from 'classnames'
import { Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { ReviewBroadcastAction } from 'widgets/BroadcastView/ui/ReviewBroadcast/ReviewBroadcastAction'
import { useBroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'
import { broadcastActionsStore } from 'widgets/BroadcastActions'
import { TestSMS } from 'widgets/TestSMS'
import styles from './styles.module.scss'

export const BroadcastActions = observer(() => {
  const { broadcastSMSStore, type, number } = useBroadcastViewContext()
  const onCancel = () => {
    uiStore.changeRoute({
      path: 'broadcasts/all',
    })
  }
  const [isTest, showTest] = useState(false)

  const testSMSDisabled = useMemo(() => {
    const isNoNumberId = !number?.id

    if (isNoNumberId) return true

    const isMessageEmpty =
      !broadcastSMSStore.messageDataParams.message.trim().length &&
      !broadcastSMSStore.messageDataParams.media_url.length
    const isError = broadcastSMSStore.isError

    return isError || isMessageEmpty
  }, [
    broadcastSMSStore.messageFieldStore.isAttachmentsCountLimitError,
    broadcastSMSStore.isError,
    broadcastSMSStore.messageDataParams.message,
    broadcastSMSStore.messageDataParams.media_url,
    number?.id,
  ])

  return (
    <div className={classNames(styles.wrap, { [styles.isTest]: isTest })}>
      {!isTest && (
        <>
          <ReviewBroadcastAction />
          <Button text={'Cancel'} contained={'secondary'} onClick={onCancel} />
          <div />
        </>
      )}

      {type === 'sms' && (
        <TestSMS
          disabled={testSMSDisabled}
          params={{
            media_url: broadcastSMSStore.messageDataParams.media_url,
            message: broadcastSMSStore.messageDataParams.message,
            number_id: number?.id || 0,
          }}
          request={broadcastActionsStore.onTestSMSBroadcast}
          onToggleShowTest={showTest}
        />
      )}
    </div>
  )
})
