import { EnumDropdownItemVariant } from 'shared/ui'
import { IResponseFilter, IResponseCustomFilter } from 'entities/Contacts/api/filterTypes'
import { IFilterGroup } from 'widgets/FilterEditor/types'

import { getFilterIcon } from './filterIcon'

const filterCategories = [
  { category: 'default', label: '' },
  { category: 'recurring', label: '' },
  { category: 'contact-fields', label: 'Contact fields' },
  { category: 'custom', label: 'Custom fields' },
  { category: 'geo-location', label: 'Geo-location' },
  { category: 'activity', label: 'Activity' },
  { category: 'phone-checker', label: 'Phone-checker' },
  { category: 'numbers', label: 'Numbers' },
  { category: 'segments', label: 'Segments' },
  { category: 'integrations', label: 'Integrations' },
]

const getGroupedCategories = () =>
  new Map<string, IFilterGroup>(
    filterCategories.map(({ category, label }) => [category, { label, category, items: [] }])
  )

export const getFilterGroups = (...filters: Array<IResponseFilter | IResponseCustomFilter>) => {
  const groupedCategories = getGroupedCategories()

  filters.forEach((filter) => {
    const group = groupedCategories.get(filter.category_new)

    if (!group) return

    group.items.push({
      id: filter.key,
      label: filter.name,
      variant: EnumDropdownItemVariant.Default,
      isCardClick: true,
      ariaLabel: `DropdownFilterItem_${filter.key}`,
      customFields: {
        type: filter.type,
      },
      iconL: getFilterIcon(filter),
      parentTooltipProps: {
        label: filter.name,
        placement: 'left',
        fullWidth: true,
      },
    })
  })

  return [...groupedCategories.values()]
}
