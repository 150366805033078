// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xifwPB02byVUiAwtluMB{display:flex;gap:10px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/organizations/pages/OrganizationDetails/ui/Compliance/tabs/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA","sourcesContent":[".wrapStatusRender{\n  display: flex;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapStatusRender": "xifwPB02byVUiAwtluMB"
};
export default ___CSS_LOADER_EXPORT___;
