import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { BroadcastBadNumberStore } from 'widgets/BroadcastView/ui/BroadcastBadNumbers'

type IBroadcastBadNumbersModalContentProps = {
  store: BroadcastBadNumberStore
}

export const BroadcastBadNumbersModalActions: FC<IBroadcastBadNumbersModalContentProps> = observer(
  ({ store }) => {
    return (
      <>
        <Button
          typeBtn={'contained'}
          size={'medium'}
          contained={'secondary'}
          text={'Cancel'}
          onClick={store.onClose}
        />
        <Button
          typeBtn={'contained'}
          text={'Enable'}
          onClick={store.onEnable}
          disabled={store.disabled}
        />
      </>
    )
  }
)
