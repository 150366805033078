import { FC } from 'react'
import { Box } from '@mui/material'
import { Icon, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IWidgetMultiRecurringTypeCardProps = {
  label: string
}

export const WidgetMultiRecurringTypeCard: FC<IWidgetMultiRecurringTypeCardProps> = ({ label }) => {
  return (
    <div className={styles.repeatType}>
      <Box display={'flex'} gap={1} alignItems={'center'}>
        <Icon icon={'repeat'} tertiary fontSize={12} />
        <Typography ariaLabel={'User'} variant={'text-input-field-r'}>
          {label}
        </Typography>
      </Box>
    </div>
  )
}
