// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BDK22iPL0BNcQrdAqphT{padding:4px;gap:4px;display:grid;grid-template-columns:max-content 1fr max-content;justify-items:flex-start;height:32px;width:100%;align-items:center;cursor:pointer;background:none;outline:none;border:none;border-radius:6px}.BDK22iPL0BNcQrdAqphT:hover{background-color:var(--background-neutral-subtle)}.zvDdZ4OCju2lIt2YvO6p{display:flex;width:24px;align-items:center;height:24px;justify-items:center}.jvoIm8n5OZFdslGVVzg5{align-items:center;display:flex;gap:8px;color:var(--content-primary-primary)}.IYkK4p8e06yFDCJccNHE{margin-right:8px;color:var(--content-brand-primary)}.IYkK4p8e06yFDCJccNHE{margin-right:8px;color:var(--content-brand-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/TextToSpeech/ui/TextToSpeechVoice/VoicesContent/VoiceItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,OAAA,CACA,YAAA,CACA,iDAAA,CACA,wBAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,YAAA,CACA,WAAA,CACA,iBAAA,CACA,4BACE,iDAAA,CAGJ,sBACE,YAAA,CACA,UAAA,CACA,kBAAA,CACA,WAAA,CACA,oBAAA,CAEF,sBACE,kBAAA,CACA,YAAA,CACA,OAAA,CACA,oCAAA,CAGF,sBACE,gBAAA,CACA,kCAAA,CAGF,sBACE,gBAAA,CACA,kCAAA","sourcesContent":[".wrap{\n  padding: 4px;\n  gap: 4px;\n  display: grid;\n  grid-template-columns: max-content 1fr max-content;\n  justify-items: flex-start;\n  height: 32px;\n  width: 100%;\n  align-items: center;\n  cursor: pointer;\n  background: none;\n  outline: none;\n  border: none;\n  border-radius: 6px;\n  &:hover{\n    background-color: var(--background-neutral-subtle);\n  }\n}\n.wrapPlay{\n  display: flex;\n  width: 24px;\n  align-items: center;\n  height: 24px;\n  justify-items: center;\n}\n.wrapName{\n  align-items: center;\n  display: flex;\n  gap: 8px;\n  color: var(--content-primary-primary);\n}\n\n.check{\n  margin-right: 8px;\n  color: var(--content-brand-primary);\n}\n\n.check{\n  margin-right: 8px;\n  color: var(--content-brand-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "BDK22iPL0BNcQrdAqphT",
	"wrapPlay": "zvDdZ4OCju2lIt2YvO6p",
	"wrapName": "jvoIm8n5OZFdslGVVzg5",
	"check": "IYkK4p8e06yFDCJccNHE"
};
export default ___CSS_LOADER_EXPORT___;
