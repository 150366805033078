import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type PropsSectionHeader, ContainerHeader, Dropdown } from 'shared/ui'
import { type ContactsMenuStore } from 'widgets/ContactsMenu'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import { useDropdownItems } from '../../hooks/useDropdownItems'
import styles from './styles.module.scss'

type IContactsFiltersHeaderProps = {
  contactsMenuStore: ContactsMenuStore
}

export const ContactsFiltersHeader: FC<IContactsFiltersHeaderProps> = observer(
  ({ contactsMenuStore }) => {
    const { headerName } = contactsFilterStore
    const { pageLayoutStore, activeSegment, isNewSegment } = contactsMenuStore
    const noSegment = isNewSegment || activeSegment === null
    const customTitle = noSegment ? (
      <>{headerName}</>
    ) : (
      <Dropdown
        ariaLabel={'ContactsFiltersHeader'}
        items={activeSegment ? useDropdownItems(activeSegment, contactsMenuStore) : []}
        disableStopPropagation
        triggerComponent={() => (
          <div className={styles.dropdownTriggerComponent}>
            <span className={styles.headerName}>{headerName}</span>
          </div>
        )}
      />
    )

    const props: PropsSectionHeader = {
      paddingBottom: 6,
      paddingTop: 24,
      height: 65,
      customTitle: customTitle,
      titleIconProps: pageLayoutStore?.titleIconProps,
      actionIconTooltipProps: pageLayoutStore?.headerMenuTooltipIconProps,
      titleIconAction: pageLayoutStore?.headerMenuIconAction,
    }

    return <ContainerHeader {...props} />
  }
)
