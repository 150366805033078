/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/display-name */
import { forwardRef, Ref } from 'react'
import { NumericFormat } from 'react-number-format'
import { IInputComponent, IInputNumericProps } from './types'

const InputNumericRef = (props: IInputNumericProps, ref: Ref<HTMLInputElement>) => (
  <NumericFormat getInputRef={ref} {...props} />
)

export const InputNumeric: IInputComponent<IInputNumericProps> = forwardRef(InputNumericRef)

export const makeInputNumeric: (
  defaults: Partial<IInputNumericProps>
) => IInputComponent<IInputNumericProps> = (defaults) =>
  forwardRef((props, ref) => InputNumericRef({ ...defaults, ...props }, ref))
