import { ICustomFieldType } from 'entities/CustomField'
import type { IMergeFieldType } from 'entities/Integrations'

type IPropertyMappingRules = {
  [P in ICustomFieldType]: IMergeFieldType[]
}
export const PROPERTY_MAPPING_RULES: IPropertyMappingRules = {
  number: ['number', 'text', 'string'],
  text: ['text', 'string'],
  url: ['url', 'text', 'string'],
  date: ['date', 'datetime'],
}

export const PROPERTY_PHONE_TOOLTIP =
  "Salesmsg uses the first number; if it's missing, it defaults to the second."
