import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { NavigateFunction, useLocation } from 'react-router'
import { Blocker, SetURLSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

import localDate, { DayjsFormats } from 'shared/lib/dayjs'
import { IS_APP_LIGHT, IS_CALLING_APP_LIGHT, NODE_ENV } from 'shared/config'
import type { IParamsChangeRoute, IParamsChangeRouteVue } from './types'

type RouterLocation = ReturnType<typeof useLocation>

class UIStore {
  constructor() {
    makeAutoObservable(this)
    this.initScrolling()
    reaction(
      () => this.pathName,
      (pathName, prev) => {
        this.prevPathName = prev
      }
    )
  }
  customPathLabel: { [key: string]: string } = {
    '/contacts': 'All contacts',
    '/broadcasts/all': 'All broadcasts',
    '/broadcasts/sms': 'SMS broadcasts',
    '/broadcasts/ringless': 'Ringless broadcasts',
    '/broadcasts/view': 'All broadcasts',
    '/triggers/all': 'All triggers',
    '/triggers/sms': 'SMS triggers',
    '/triggers/ringless': 'Ringless triggers',
    '/triggers/view': 'All triggers',
    '/settings/integrations': 'App Marketplace',
    '/keywords/view': 'All keywords',
    '/power-dialer': 'Power Dialer campaigns',
  }
  navigate: NavigateFunction | null = null
  location: RouterLocation | null = null
  pathName = ''
  queryParams = ''
  prevPathName = ''
  playAudioId = ''
  globalAlerts = []
  _timezone: string | null = null
  routeBlocker: Blocker | null = null

  scrolling = false

  isFocusingMode = false

  idScrollingTimeout: NodeJS.Timer | null = null

  searchParams: URLSearchParams | null = null
  setSearchParams: SetURLSearchParams | null = null

  get timezone() {
    if (NODE_ENV !== 'production' && this._timezone === null) console.warn('Empty user timezone')
    return this._timezone ?? dayjs.tz.guess()
  }

  get timezoneShort() {
    return this.dayjs().format(DayjsFormats.timezone)
  }

  get timezoneUTC() {
    return this.dayjs().format(DayjsFormats.UTCOffset)
  }

  get offsetMinute() {
    return this.dayjs().utcOffset() - dayjs().utcOffset()
  }

  startFocusingMode = () => {
    this.isFocusingMode = true
  }
  endFocusingMode = () => {
    this.isFocusingMode = false
  }

  initScrolling = () => {
    const onScroll = () => {
      if (this.scrolling) {
        return
      }
      this.idScrollingTimeout && clearTimeout(this.idScrollingTimeout)
      runInAction(() => {
        this.scrolling = true
      })
      this.idScrollingTimeout = setTimeout(() => {
        runInAction(() => {
          this.scrolling = false
        })
      }, 1000)
    }
    const onScrollEnd = () => {
      this.idScrollingTimeout && clearTimeout(this.idScrollingTimeout)
      runInAction(() => {
        this.scrolling = false
      })
    }
    window.addEventListener('scroll', onScroll, true)
    window.addEventListener('scrollend', onScrollEnd, true)
  }

  dayjs(date?: string | number | Date | dayjs.Dayjs | null | undefined) {
    if (this.timezone) return localDate(date, this.timezone)

    return localDate(date)
  }

  setNavigate = (navigate: NavigateFunction) => {
    this.navigate = navigate
  }

  setPlayAudioId = (id: string) => {
    this.playAudioId = id
  }

  setTimezone = (timezone: string | null) => {
    this._timezone = timezone
  }

  checkRouteType = (page: string) => {
    if (!window.DesignV2API) return 'default'

    page = page[0] === '/' ? page.replace('/', '') : page

    return window.DesignV2API.handleCheckPage(page) ? 'default' : 'vue'
  }

  changeRoute({
    path,
    name,
    query,
    type = 'default',
    method = 'push',
    options,
  }: IParamsChangeRoute) {
    const newPath = query ? `${path}?${query}` : path

    if (type === 'default' || IS_APP_LIGHT || IS_CALLING_APP_LIGHT) {
      if (this.navigate) {
        this.navigate(newPath, {
          replace: method === 'replace',
          ...options,
        })
      }
      if (IS_APP_LIGHT || IS_CALLING_APP_LIGHT) {
        return
      }
    }

    if (type === 'vue') {
      if (this.routeBlocker) {
        if (this.navigate) {
          this.navigate(newPath, options)
        }

        return
      }

      if (window.RouterVue) {
        window.RouterVue[method]({
          name: name,
          path: newPath,
          params: {
            state: options?.state,
          },
        })
      }
    }
  }

  changeRouteViaVue = (params: IParamsChangeRoute) => {
    if (window.DesignV2API && this.location) {
      const fromType = this.checkRouteType(this.location.pathname)
      const toType = this.checkRouteType(params.path)

      this.changeRoute({
        ...params,
        type: fromType === 'vue' ? fromType : toType,
      })
    } else {
      this.changeRoute(params)
    }
  }

  changeRouteOnlyVue = (method: 'push' | 'replace', params: IParamsChangeRouteVue) => {
    if (window.RouterVue) {
      window.RouterVue[method](params)
    }
  }

  setLocation = (location: RouterLocation) => {
    this.location = location
    this.setPathName(location.pathname)
    this.setQueryParams(location.search)
  }

  setPathName = (pathName: string) => {
    this.pathName = pathName
  }

  setQueryParams = (query: string) => {
    this.queryParams = query
  }

  setRoutesBlocker = (blocker: Blocker | null) => {
    this.routeBlocker = blocker
  }

  setCustomPathLabel = (path: string, label: string) => {
    this.customPathLabel[path] = label
  }

  setSearchParamsToUIStore = (searchParams: URLSearchParams) => {
    this.searchParams = searchParams
  }
  setSearchParamsSetterToUIStore = (setSearchParams: SetURLSearchParams) => {
    this.setSearchParams = setSearchParams
  }
}

export const uiStore = new UIStore()
