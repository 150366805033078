import { makeAutoObservable } from 'mobx'
import { MediaControllerStore } from 'widgets/PresentationMode/store/MediaControllerStore'

export class AudioControllerStore {
  constructor() {
    makeAutoObservable(this)
  }

  mediaControllerStore: MediaControllerStore = new MediaControllerStore(true)
}
