import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Alert, DropdownPlaceholder, EnumDropdownItemVariant } from 'shared/ui'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import type { LinkContactStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/LinkContact/store/linkContactStore'
import type { MatchedContact } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/LinkContact/model/MatchedContact'
import styles from './styles.module.scss'

type ILinkWithPhonesModalContentProps = {
  contact: MatchedContact
  linkContactStore: LinkContactStore
}

export const LinkWithPhonesModalContent: FC<ILinkWithPhonesModalContentProps> = observer(
  ({ contact, linkContactStore }) => {
    const { selectedMatchedPhone, handleSelectedMatchedPhone } = linkContactStore
    return (
      <div className={classnames(styles.contentWrap)}>
        <Alert
          item={{
            desc: 'Select the phone number you want to use for this contact',
            type: 'infoLight',
          }}
        />
        <DropdownPlaceholder
          items={[
            {
              id: 1,
              label: `Phone: ${contact.phone || '-'}`,
              activeValue: selectedMatchedPhone === contact.phone,
              value: contact.phone,
              variant: EnumDropdownItemVariant.CheckedRight,
            },
            {
              id: 2,
              label: `Mobile Phone: ${contact.mobile_phone || '-'}`,
              activeValue: selectedMatchedPhone === contact.mobile_phone,
              value: contact.mobile_phone,
              variant: EnumDropdownItemVariant.CheckedRight,
            },
          ]}
          onChange={({ value }) => {
            if (value) {
              handleSelectedMatchedPhone(value)
            }
          }}
          widthDropdown={440}
          placeholder={
            `${
              contact.phone === selectedMatchedPhone ? 'Phone:' : 'Mobile Phone:'
            } ${selectedMatchedPhone}` || '-'
          }
          textPlaceholderProps={{
            variant: 'stroke',
            size: 'small',
            rightActions: [makeTextFieldIcon({ icon: 'chevronDown' })],
          }}
        />
      </div>
    )
  }
)
