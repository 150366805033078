import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { ContactsApi } from 'entities/Contacts'
import { IContactDuplicate, IContactDuplicatePair } from 'entities/Contacts/api/types'
import { ContactsMergeModalContent, ContactsMergeModalActions } from 'widgets/ContactsMergeModal'

export class ContactsMergeModalStore {
  idModal: string | null = null
  itemsMap: Map<number, IContactDuplicate> = new Map()
  itemsPairMap: Map<number, IContactDuplicatePair> = new Map()
  selectedItemId: number | null = null
  activeItemId = 0
  offset = 0
  updated = false
  refreshCallback: null | (() => void) = null
  firstLoading = true
  loadingDuplicate = false
  loadingDuplicateClick = false
  loadingMerge = false
  loadingMergeClick = false

  constructor() {
    makeAutoObservable(this)

    this.reactionItemUpdate()
    this.reactionClose()
  }

  get isLoading() {
    return this.loadingDuplicate || this.loadingMerge || this.firstLoading
  }

  get itemsList() {
    return Array.from(this.itemsMap.values())
  }

  get itemsPairList() {
    return Array.from(this.itemsPairMap.values())
  }

  get itemsCount() {
    return this.itemsList.length
  }

  get activeItem() {
    return this.itemsMap.get(this.activeItemId)
  }

  get lastItemId() {
    return this.itemsList[this.itemsCount - 1]?.id
  }

  get isLastItem() {
    return this.lastItemId === this.activeItemId
  }

  get conflictsCount() {
    const firstItem = this.itemsPairList?.[0]
    const secondItem = this.itemsPairList?.[1]

    if (!firstItem || !secondItem) return 0

    let conflictCounter = 0

    for (const [key, value] of Object.entries(firstItem)) {
      if (secondItem[key as keyof IContactDuplicatePair] !== value) conflictCounter++
    }

    return conflictCounter
  }

  get activeItemIdx() {
    return this.itemsList.findIndex((item) => item.id === this.activeItemId)
  }

  get nextItemId() {
    if (this.isLastItem) {
      return this.itemsList[0].id
    }

    return this.itemsList[this.activeItemIdx + 1].id
  }

  get internalCount() {
    if (!this.activeItem) return 0

    return this.activeItem.count - 2
  }

  get hasNextInternalItem() {
    return this.internalCount - this.offset > 0
  }

  get requestParams() {
    if (!this.activeItem) return null

    return {
      offset: this.offset,
      number: this.activeItem.formatted_number,
    }
  }

  get mergeParams() {
    const secondaryContactId = this.itemsPairList.find(
      (item) => item.id !== this.selectedItemId
    )?.id

    if (!this.selectedItemId || !secondaryContactId) return null

    return {
      main_contact_id: this.selectedItemId,
      secondary_contact_id: secondaryContactId,
    }
  }

  init = (items: IContactDuplicate[], id: number, refreshCallback?: () => void) => {
    this.addItems(items)
    this.activeItemId = id
    this.offset = 0
    if (refreshCallback) {
      this.refreshCallback = refreshCallback
    }

    this.openModal()
  }

  addItems = (items: IContactDuplicate[]) => {
    items.forEach((item) => {
      this.itemsMap.set(item.id, item)
    })
  }

  addItemsPair = (items: IContactDuplicatePair[]) => {
    items.forEach((item) => {
      this.itemsPairMap.set(item.id, item)
    })
  }

  openModal = () => {
    this.idModal = nanoid()

    modalStore.addModal({
      id: this.idModal,
      showCloseButton: false,
      showCloseIcon: true,
      showHeader: true,
      title: 'Duplicates',
      width: 600,
      ModalContent: ContactsMergeModalContent,
      ModalActions: ContactsMergeModalActions,
    })
  }

  closeModal = () => {
    if (!this.idModal) return
    if (this.refreshCallback && this.updated) this.refreshCallback()
    modalStore.removeModal(this.idModal)
    this.reset()
  }

  reset = () => {
    this.idModal = null
    this.itemsMap.clear()
    this.itemsPairMap.clear()
    this.activeItemId = 0
    this.offset = 0
    this.updated = false
  }

  changeFirstLoading = (value: boolean) => {
    this.firstLoading = value
  }

  changeLoadingDuplicateClick = (value: boolean) => {
    this.loadingDuplicateClick = value
  }

  changeMergeLoadingClick = (value: boolean) => {
    this.loadingMergeClick = value
  }

  nextItem = () => {
    if (this.hasNextInternalItem) {
      this.offset += 1
      return
    }
    this.offset = 0

    this.activeItemId = this.nextItemId
  }

  selectItem = (id: number) => {
    this.selectedItemId = id
  }

  load = async () => {
    if (!this.requestParams) return
    try {
      this.loadingDuplicate = true
      const { data } = await ContactsApi.getContactsDuplicatePair(this.requestParams)

      this.itemsPairMap.clear()
      this.selectedItemId = data[0].id
      this.addItemsPair(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingDuplicate = false
        this.loadingDuplicateClick = false

        if (this.firstLoading) {
          this.changeFirstLoading(false)
        }
      })
    }
  }

  merge = async (successMergeHandler: (id: number) => void, duplicatesLength: number) => {
    if (!this.mergeParams || !this.activeItem) return

    try {
      this.loadingMerge = true
      await ContactsApi.updateContactsDuplicatePair(this.mergeParams)

      toastStore.add({
        title: 'Contacts merged',
        type: 'success',
      })

      successMergeHandler(this.activeItemId)
      if (duplicatesLength === 1) {
        this.closeModal()
      }

      this.updated = true
      if (this.activeItem.count === 2) {
        const mergedItemId = this.activeItem.id
        this.nextItem()
        this.itemsMap.delete(mergedItemId)
      } else {
        this.itemsMap.set(this.activeItem.id, {
          ...this.activeItem,
          count: this.activeItem.count - 1,
        })
        this.load()
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingMerge = false
        this.loadingMergeClick = false
      })
    }
  }

  reactionClose = () => {
    reaction(
      () => this.itemsCount,
      () => {
        if (this.itemsCount === 0) {
          this.closeModal()
        }
      }
    )
  }

  reactionItemUpdate = () => {
    reaction(
      () => this.offset || this.activeItemId,
      () => {
        if (this.itemsCount !== 0) {
          this.load()
        }
      }
    )
  }
}

export const contactsMergeModalStore = new ContactsMergeModalStore()
