import classnames from 'classnames'
import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import {
  type IColumn,
  Icon,
  Table,
  IconButton,
  Typography,
  Tooltip,
  type IIconsVector,
} from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { TitledRow } from 'shared/ui/TitledRow/TitledRow'
import type { IHubspotSettingsDict } from 'entities/Integrations'
import type { SettingsProperty } from 'pages/settings/pages/integrations/pages/integrationSettings/model/SettingsProperty'
import styles from './styles.module.scss'

type IIntegrationSettingsPropertyTableProps<
  T extends string | number = string,
  D extends object = IHubspotSettingsDict
> = {
  loading: boolean
  rows: SettingsProperty<T, D>[]
  integrationName: string
  integrationIcon: IIconsVector
  iconSize?: number
}

export const IntegrationSettingsPropertyTable = observer(
  <T extends string | number = string, D extends object = IHubspotSettingsDict>({
    rows,
    loading,
    integrationName,
    integrationIcon,
    iconSize = 16,
  }: IIntegrationSettingsPropertyTableProps<T, D>) => {
    const columns: IColumn<SettingsProperty<T, D>>[] = useMemo(
      () => [
        {
          field: 'type',
          name: 'Salesmsg',
          width: '30%',
          maxWidth: '160px',
          renderHeaderCell: ({ name }) => (
            <div className={styles.headerCell}>
              <Icon fontSize={iconSize} icon={'salesmsg'} />
              {name}
            </div>
          ),
          renderRowCell: ({ fieldName, original }) => (
            <div className={styles.row}>
              <TitledRow
                title={fieldName}
                tooltip={original.tooltip}
                titleProps={{
                  ellipsis: true,
                  tooltipProps: {
                    label: fieldName,
                    placement: 'top',
                  },
                }}
              />
            </div>
          ),
        },
        {
          field: 'options',
          name: integrationName,
          renderHeaderCell: ({ name }) => (
            <div className={styles.headerCell}>
              <Icon fontSize={16} icon={integrationIcon} />
              {name}
            </div>
          ),
          renderRowCell: ({ value, options, disabled, onChange, error }) => (
            <Tooltip label={error} placement={'top-end'} open={!!error} color='red' isDisplayGrid>
              <SelectField
                disabled={disabled}
                className={classnames(styles.select, { [styles.error]: error })}
                withSearch
                value={value}
                setValue={onChange}
                items={options}
                size={'medium'}
                dropdownProps={{
                  propsDropdownList: {
                    className: styles.dropdown,
                  },
                }}
                btnProps={{
                  className: styles.selectBtn,
                }}
              />
            </Tooltip>
          ),
        },
        {
          field: 'mapped',
          name: 'Mapped',
          maxWidth: '120px',
          renderRowCell: ({ mapped }) =>
            mapped ? (
              <Icon
                fontSize={16}
                icon={'check1'}
                color={'var(--action-contained-primary-normal)'}
              />
            ) : (
              <Icon fontSize={16} icon={'close'} tertiary />
            ),
        },
        {
          field: 'control',
          name: ' ',
          maxWidth: '32px',
          renderRowCell: ({ onDelete, type }) =>
            type !== 'default' && (
              <IconButton
                className={styles.headerIcon}
                iconProps={{ fontSize: 16 }}
                tooltipProps={{
                  label: 'Delete',
                  placement: 'top',
                }}
                variant={'icon'}
                color={'tertiary'}
                size={'small'}
                icon={'delete'}
                ariaLabel='Refresh_data'
                onClick={onDelete}
              />
            ),
        },
      ],
      [integrationName, integrationIcon, iconSize]
    )

    return (
      <div className={classnames(styles.table, { [styles.loading]: loading })}>
        <Table
          columns={columns}
          rows={rows}
          noPagination
          scrollbarProps={{
            autoHeight: true,
            autoHeightMin: 0,
            autoHeightMax: 850,
            viewClassName: styles.viewScrollClassName,
          }}
        />
      </div>
    )
  }
)
