import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket/store'
import type { IResponseEventMessageDeletedDraft } from 'entities/Conversation/api/types'
import { conversationStore } from 'entities/Conversation'

const MessageDeletedDraft = (data: IResponseEventMessageDeletedDraft) => {
  conversationStore.deleteItemDraft(data.conversation.id)
}

export const useEventMessageDeletedDraft = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.MessageDeletedDraft, MessageDeletedDraft)
    }
  }, [websocket.isConnected])
}
