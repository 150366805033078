import { type IAiAssistantStoreProps } from 'widgets/AIAssistant'
import { MessageFieldStore } from 'widgets/MessageField'

export const getAiAssistantPropsFromMessageField = (
  messageFieldStore: MessageFieldStore
): Pick<
  IAiAssistantStoreProps,
  | 'currentTextObj'
  | 'replaceSelectedContentObj'
  | 'onReplaceAllContent'
  | 'triggerElementObj'
  | 'selectedContentObj'
> => ({
  currentTextObj: messageFieldStore.messageInnerTextObj,
  replaceSelectedContentObj: messageFieldStore.replaceSelectedContentObj,
  onReplaceAllContent: messageFieldStore.replaceMessageText,
  triggerElementObj: messageFieldStore.messageFieldElement,
  selectedContentObj: messageFieldStore.selectedContentObj,
})
