import { makeAutoObservable, reaction, runInAction, IReactionDisposer } from 'mobx'
import { debounce, isEqual } from 'lodash'
import axios, { CancelTokenSource } from 'axios'
import { layoutStore, PageLayoutStore } from 'shared/layout'
import { TableStore } from 'shared/ui/Table'
import { IAlert } from 'shared/ui/Alert/types'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { usersStore } from 'entities/Users'
import { Contact } from 'entities/Contacts/model/Contact'
import { contactTemplate } from 'entities/Contacts/templates/contactTemplate'
import { IParamsContacts, IResponseContact } from 'entities/Contacts/api/types'
import { subscriptionStore } from 'entities/Subscription'
import type { IResponseFilterSegment } from 'entities/Segment'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { CallModalStore } from 'widgets/CallModal'
import { ContactsMenuStore } from 'widgets/ContactsMenu'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import { sortByMapping } from '../helpers/helpers'

const defaultPagination = {
  page: 1,
  length: 10,
}

const defaultSortBy = 'created_at'

export class ContactsTableStore {
  contactsMap: Map<number, Contact> = new Map()
  contactsLoading = false
  tableWidth: null | number = null
  filtersLoading = false
  loading = false
  total = 0
  totalAmount = 0 // without filters and search
  search = ''
  paginationData = defaultPagination
  debounceLoadContacts: ReturnType<typeof debounce>
  cancelDebounceLoadContacts: () => void
  sortOrder: 'desc' | 'asc' = 'desc'
  sortDisabled = true
  sortBy = defaultSortBy
  sortFieldType = ''
  isActiveDebounce = false
  activeItem: Contact | null = null
  inActionContactId: number | null = null
  // nextPage, privPages, useNextPage are using for hubspot only
  nextPage = 1
  privPages: number[] = []
  useNextPage = true

  private _newContactMode = false
  private _paramsFiltersList: IResponseFilterSegment[] = []

  private _disposeFilters: IReactionDisposer | null = null
  private _disposeLoadContacts: IReactionDisposer | null = null
  private _disposeTotalContactsByTrial: IReactionDisposer | null = null
  private _disposeOnSelectContacts: IReactionDisposer | null = null

  private _contactsMenuStore: ContactsMenuStore | null = null
  private _callModalStore = new CallModalStore()
  private _alert: IAlert | null = null
  private _firstEditMode = false
  private _cancelTokenContactsList: CancelTokenSource | null = null

  constructor(private _pageLayoutStore: PageLayoutStore) {
    this.debounceLoadContacts = debounce(this.loadContacts, 500)
    this.cancelDebounceLoadContacts = this.debounceLoadContacts.cancel

    makeAutoObservable(this)
  }

  initReactions = () => {
    this.reactionFilters()
    this.reactionLoadContacts()
    this.reactionTotalContactsByTrial()
    this.reactionOnSelectContacts()
  }

  setContactsMenuStore = (store: ContactsMenuStore) => {
    this._contactsMenuStore = store
  }

  resetNewContactMode = () => {
    if (this._newContactMode) {
      this._newContactMode = false
      this.setActiveContact(null)
      this.deleteNewContactLocally()
      this.loadContacts()
    }
  }

  tableStore = new TableStore<Contact>({
    element: 'contact',
    withoutDefaultManageColumns: true,
    MAX_SELECT_COUNT: this.totalContactsByTrial,
    filterRowsFn: (contact: Contact) => !contact.isDisabled && !contact.isNew,
  })

  resetPagination = () => {
    this.paginationData = defaultPagination
  }

  resetSearch = () => {
    this.search = ''
  }

  resetSorting = () => {
    this.sortOrder = 'desc'
    this.sortBy = defaultSortBy
    this.sortDisabled = true
    this.sortFieldType = ''
  }

  setInActionId = (id: number | null) => {
    this.inActionContactId = id
  }

  changeSearchAndResetPaginationPage = (value: string) => {
    this.pagination.page !== 1 && this.changePaginationToFirst()
    this.changeSearch(value)
    this.setActiveContact(null)
  }

  changeSearch = (value: string) => {
    this.isActiveDebounce = true
    this.search = value
    this.debounceLoadContacts?.()
  }

  toggleSortOrder = () => {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc'
  }

  toggleSortDisabling() {
    this.sortDisabled = !this.sortDisabled
  }

  changeSortBy = (field: string) => {
    if (this.sortBy !== field) {
      this.sortBy = field
      this.sortOrder = 'desc'
    }
  }

  setSortFieldType = (type: string) => {
    this.sortFieldType = type
  }

  handleChangePagination = (page: number, length: number) => {
    if (this.tableStore.bulkAllMode) {
      this.tableStore.resetSelected()
    }

    if (this.isHubspotWithValue) {
      const nextPageClick = page > this.pagination.page
      this.useNextPage = nextPageClick

      if (!nextPageClick) {
        this.privPages.pop()
      }
      if (length !== this.pagination.length) {
        this.privPages = []
      }
    }

    this.changePagination(page, length)
  }

  changePagination = (page: number, length: number) => {
    this.paginationData = {
      page,
      length,
    }
  }

  changePaginationToFirst = () => {
    this.changePagination(1, this.paginationData.length)
  }

  handleTableWidth = (width: number | null) => {
    this.tableWidth = width
  }

  initCancelTokenContactsList = () => {
    this._cancelTokenContactsList?.cancel()

    this._cancelTokenContactsList = axios.CancelToken.source()
  }

  loadContactsList = (params?: IParamsContacts) => {
    this.initCancelTokenContactsList()

    return ContactsApi.getContactsList(params, {
      cancelToken: this._cancelTokenContactsList?.token,
    })
  }

  getTotalAmount = async () => {
    try {
      const { data } = await this.loadContactsList()

      this.totalAmount = data.total
    } catch (e) {
      console.log(e)
    }
  }

  checkIsTotalAmountSet = (total: number) => {
    if (subscriptionStore.isTrial && !this.search.length && !this.noEmptyFilters.length) {
      this.totalAmount = total
    }
  }

  handleCurrentNextPages = (currentPage: number, nextPage?: number) => {
    if (!this.isHubspotWithValue || !nextPage) return

    if (this.useNextPage) {
      this.privPages.push(currentPage)
    } else {
      this.privPages.pop()
    }
    this.nextPage = nextPage
  }

  setLoadingContacts = (value: boolean) => {
    this.contactsLoading = value
  }

  loadContacts = async () => {
    try {
      runInAction(() => {
        this.contactsLoading = true
      })

      const { data } = await this.loadContactsList(this.expandedParams)
      const results = data.data || []
      const total = data.total || 0

      this.handleCurrentNextPages(data.current_page, data.next_page)

      contactsStore.reset()
      contactsStore.addItems(results)
      this.addContacts(results)
      this.checkIsTotalAmountSet(data.total)

      runInAction(() => {
        this.total = total
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.contactsLoading = false
        this.isActiveDebounce = false
      })
    }
  }

  updateActiveFilters = async (fieldName: string) => {
    try {
      runInAction(() => {
        this.filtersLoading = true
      })

      await usersStore.updateActiveFields(fieldName)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.filtersLoading = false
      })
    }
  }

  addContact = (contact: Contact) => {
    this.contactsMap.set(contact.id, contact)
  }

  addContacts = (contacts: IResponseContact[]) => {
    this.resetContacts()

    contacts.forEach((item) => {
      this.addContact(new Contact(item))
    })
  }

  deleteNewContactLocally = () => {
    this.contactsMap.delete(-1)
  }

  updateContactLocally = (contact: Contact) => {
    this.contactsMap.set(contact.id, contact)
  }

  setActiveContact = (value: Contact | null) => {
    this.activeItem = value
  }

  resetActiveAndCloseDetailsIsMobile = () => {
    if (layoutStore.isMobileView) {
      contactsDetailsGlobalStore.handleToggleCollapse()
      this.setActiveContact(null)
    }
  }

  setActiveEmptyContact = () => {
    this.setActiveContact(new Contact(contactTemplate))
  }

  resetContacts = () => {
    this.contactsMap.clear()
  }

  setLoading = (value: boolean) => {
    this.loading = value
  }

  changeNewContactMode = (value: boolean) => {
    if (value) {
      this.tableStore.unselectAllIds()
    }

    this._newContactMode = value
  }

  addNewContactLocallyAfterCreate = async (contact: Contact, params: { isNew?: boolean } = {}) => {
    this.deleteNewContactLocally()
    this.changeNewContactMode(false)
    this.setActiveContact(contact)

    if (params.isNew) {
      contactsDetailsGlobalStore.openBlockView('contactDetails')
      this.setIsFirstEditMode(true)

      this.setAlert({
        type: 'success',
        desc: 'Contact created',
      })

      this.loadContacts()
    } else {
      const isContactOnCurrentPageExist = this.getContactsList.find(
        (item) => item.id === contact.id
      )

      const list = isContactOnCurrentPageExist
        ? this.getContactsList.filter((item) => item.id !== contact.id)
        : this.getContactsList.slice(0, this.getContactsList.length - 1)

      const actualContacts = [contact, ...list]
      this.resetContacts()

      actualContacts.forEach((item) => {
        this.addContact(item)
      })
    }
  }

  addNewContactManually = () => {
    this.changeNewContactMode(true)
    this.setActiveEmptyContact()
  }

  getContactsFromIdArray = (ids: number[]) => {
    return this.getContactsList.filter((contact) => ids.includes(contact.id))
  }

  getContactById = (id: number) => {
    return this.contactsMap.get(id)
  }

  checkClosePanelAfterDelete = () => {
    if (this.activeItem && this.tableStore.selectedIds.includes(this.activeItem.id)) {
      this.setActiveContact(null)
    }
  }

  resetHubspotPagination = () => {
    this.privPages = []
    this.nextPage = 1
  }

  get pagination() {
    return this.paginationData
  }

  get mappedSortBy() {
    return sortByMapping[this.sortBy]
  }

  get sortedBy() {
    const mappedSortBy = this.sortBy.includes('custom.') ? this.sortBy : this.mappedSortBy

    if (!mappedSortBy) {
      return [defaultSortBy]
    }

    if (this.sortDisabled) {
      return ['disabled', mappedSortBy]
    }

    return [mappedSortBy]
  }

  get sortType() {
    const geoTypes = ['state_code', 'area_code', 'country', 'time_zone']

    if (geoTypes.includes(this.sortBy)) {
      return {
        sortType: 'geo',
      }
    }

    return this.sortBy.includes('custom.')
      ? { sortType: 'custom', fieldType: this.sortFieldType }
      : {}
  }

  get isHubspotWithValue() {
    return (
      contactsFilterStore.segment?.filtersList[0]?.filters[0]?.key === 'hubspot' &&
      contactsFilterStore.segment?.filtersList[0]?.filters[0].value
    )
  }

  setParamsFiltersList = (value?: IResponseFilterSegment[]) => {
    this._paramsFiltersList = value || []
  }

  get noEmptyFilters() {
    return this._paramsFiltersList || []
  }

  get actualPaginationData() {
    return this.isHubspotWithValue
      ? {
          page: this.useNextPage ? this.nextPage : this.privPages.at(-1) || 1,
          length: this.pagination.length,
        }
      : this.pagination
  }

  get expandedParams(): IParamsContacts {
    return {
      ...this.actualPaginationData,
      search: this.search,
      filtersList: this.noEmptyFilters,
      sortOrder: this.sortOrder,
      sortBy: this.sortedBy,
      ...this.sortType,
    }
  }

  get inActionId() {
    return this.inActionContactId
  }

  get selectedContacts() {
    return this.getContactsFromIdArray(this.tableStore.selectedIds as number[])
  }

  get getContactsList() {
    return Array.from(this.contactsMap.values())
  }

  get contacts() {
    const contactsList = this.getContactsList

    if (!this._newContactMode) {
      return contactsList
    }

    return [new Contact(contactTemplate), ...contactsList]
  }

  get totalContacts() {
    return this.total
  }

  get totalContactsByTrial() {
    const { isTrial, trialActiveContactsCount } = subscriptionStore

    if (isTrial && this.totalContacts > trialActiveContactsCount) return trialActiveContactsCount

    return this.totalContacts
  }

  get isLoading() {
    return this.contactsLoading || this.filtersLoading || this.loading || this.isActiveDebounce
  }

  get activeContact() {
    return this.activeItem
  }

  get isNewContactMode() {
    return this._newContactMode
  }

  get isEmptyContactsList() {
    return !this.contacts.length && !this.isLoading && !this.isNewContactMode
  }

  get isNoContactYet() {
    return this.isEmptyContactsList && !this.search.length
  }

  reactionFilters = () => {
    this._disposeFilters?.()
    this._disposeFilters = reaction(
      () => contactsFilterStore.segmentFilter,
      () => {
        const is_equal = isEqual(
          this.expandedParams.filtersList,
          contactsFilterStore.segmentFilter.filtersList
        )

        if (is_equal) return

        this.resetHubspotPagination()
        this.changePaginationToFirst()
        this.changeSearchAndResetPaginationPage('')
        this.setParamsFiltersList(contactsFilterStore.segmentFilter.filtersList)
        this.debounceLoadContacts?.()
      }
    )
  }

  reactionTotalContactsByTrial = () => {
    this._disposeLoadContacts?.()
    this._disposeLoadContacts = reaction(
      () => this.totalContactsByTrial,
      this.tableStore.SET_MAX_SELECT_COUNT
    )
  }

  reactionLoadContacts = () => {
    this._disposeTotalContactsByTrial?.()
    this._disposeTotalContactsByTrial = reaction(
      () => this.contactsLoading,
      (isLoading) => {
        if (!subscriptionStore.isTrial || !isLoading) return

        if (this.search.length || this.noEmptyFilters.length) {
          this.getTotalAmount()
        }
      }
    )
  }

  reactionOnSelectContacts = () => {
    this._disposeOnSelectContacts?.()
    this._disposeOnSelectContacts = reaction(
      () => this.tableStore.selectedIds,
      (ids) => {
        if (ids.length) {
          this.resetNewContactMode()
        }
      }
    )
  }

  dispose = () => {
    this._disposeFilters?.()
    this._disposeLoadContacts?.()
    this._disposeTotalContactsByTrial?.()
    this._disposeOnSelectContacts?.()
    this._cancelTokenContactsList?.cancel()
    this.cancelDebounceLoadContacts()
    this._paramsFiltersList = []
    this._newContactMode = false
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get contactsMenuStore() {
    return this._contactsMenuStore
  }

  get callModalStore() {
    return this._callModalStore
  }

  get alert() {
    return this._alert
  }

  setAlert = (item: IAlert) => {
    this._alert = item
  }

  resetAlert = () => {
    this._alert = null
  }

  setIsFirstEditMode = (condition: boolean) => {
    this._firstEditMode = condition
  }

  get isFirstEditMode() {
    return this._firstEditMode
  }
}
