import { useEffect, useState, ReactNode, useCallback, useRef } from 'react'
import { Instance } from '@popperjs/core'
import { debounce } from 'lodash'
import { Tooltip } from 'shared/ui'
import type { ITextFieldMainAction } from 'shared/ui/TextField'

type ITextFieldTooltip = {
  label?: ReactNode
  interactive?: boolean
}

export const makeTextFieldTooltip: (props?: ITextFieldTooltip) => ITextFieldMainAction = (
  props
) => {
  const useAction: ITextFieldMainAction = (control, children) => {
    const popperRef = useRef<Instance | null>(null)

    const [mouseOut, setMouseOut] = useState(true)
    const [mouseOutTooltip, setMouseOutTooltip] = useState(true)
    const [hasFocus, setFocus] = useState(false)
    const [isNarrow, setNarrow] = useState(false)

    const isInteractive = !mouseOutTooltip && props?.interactive

    const handleDebounceMouseLeaveTooltip = (status: boolean) => setMouseOutTooltip(status)
    const debounceMouseLeaveTooltip = useCallback(
      debounce(handleDebounceMouseLeaveTooltip, 500),
      []
    )

    const handleMouseLeaveTooltip = () => {
      debounceMouseLeaveTooltip(true)
      setMouseOutTooltip(true)
    }

    const handleMouseEnterTooltip = () => {
      debounceMouseLeaveTooltip(false)
      setMouseOutTooltip(false)
    }

    const isHidden =
      !control.value || (mouseOut && !isInteractive) || (isNarrow && !isInteractive) || hasFocus

    const handleMouseEnter = () => {
      setMouseOut(false)
      setMouseOutTooltip(false)
      debounceMouseLeaveTooltip(false)
    }

    const handleMouseLeave = () => {
      debounceMouseLeaveTooltip(true)
      setMouseOut(true)
    }

    useEffect(() => {
      const input = control.inputRef?.current

      if (!input) return

      const handleFocus = () => setFocus(true)
      const handleBlur = () => setFocus(false)
      input.addEventListener('focus', handleFocus)
      input.addEventListener('blur', handleBlur)
      input.addEventListener('mouseenter', handleMouseEnter)
      input.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        input.removeEventListener('focus', handleFocus)
        input.removeEventListener('blur', handleBlur)
        input.removeEventListener('mouseenter', handleMouseEnter)
        input.removeEventListener('mouseleave', handleMouseLeave)
      }
    }, [])

    useEffect(() => {
      const input = control.inputRef?.current

      if (!input) {
        if (popperRef.current && isInteractive) {
          popperRef.current.state.elements.popper.onmouseenter = null
          popperRef.current.state.elements.popper.onmouseleave = null
        }
        return
      }

      setNarrow(input.offsetWidth >= input.scrollWidth)
      if (popperRef.current && isInteractive) {
        popperRef.current.state.elements.popper.onmouseenter = handleMouseEnterTooltip
        popperRef.current.state.elements.popper.onmouseleave = handleMouseLeaveTooltip
      }
    }, [mouseOut])

    return (
      <>
        <Tooltip
          open={!isHidden}
          label={props?.label || control.value}
          placement={'top'}
          PopperProps={{
            anchorEl: () => control.containerRef?.current as HTMLLabelElement,
            popperRef,
          }}
        />
        {children}
      </>
    )
  }

  return useAction
}
