import React, { RefObject, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Dropdown,
  DropdownCard,
  DropdownContent,
  EnumSpinnerLoaderPlacement,
  Scrollbar,
  SpinnerLoader,
} from 'shared/ui'
import { ZIndex } from 'shared/constants/zIndex'
import type { DropdownMentionsStore } from 'widgets/MessageField/ui/DropdownMentions'
import styles from './styles.module.scss'

type IDropdownMentionsProps = {
  store: DropdownMentionsStore
  innerRef: RefObject<HTMLDivElement>
}

export const DropdownMentions: React.FC<IDropdownMentionsProps> = observer(
  ({ innerRef, store }) => {
    const [width, setWidth] = useState<number>(0)

    useEffect(() => {
      store.handleReactions()
    }, [])

    useEffect(() => {
      store.handleSearch(store.search)
    }, [store.search])

    useEffect(() => {
      if (innerRef?.current) {
        setWidth(innerRef.current.clientWidth)
      }
    }, [innerRef?.current, innerRef?.current?.clientWidth])

    if (!innerRef?.current) return null

    return (
      <Dropdown
        zIndex={5001}
        ariaLabel={'DropdownMentions'}
        placement={'top-start'}
        show={store.show}
        triggerComponent={() => null}
        customComponent={() => {
          return (
            <DropdownContent noPadding>
              {store.loading && <SpinnerLoader placement={EnumSpinnerLoaderPlacement.Center} />}
              {!store.loading && (
                <div className={styles.content}>
                  <div className={styles.head}>
                    <DropdownCard
                      key={store.itemInbox.id}
                      item={store.itemInbox}
                      onChange={store.handleSetMentions}
                    />
                  </div>
                  {Boolean(store.items.length) && (
                    <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={160}>
                      {Boolean(store.itemsSimple.length) && (
                        <div className={styles.section}>
                          {store.itemsSimple.map((item) => (
                            <DropdownCard
                              key={item.id}
                              item={item}
                              onChange={store.handleSetMentions}
                            />
                          ))}
                        </div>
                      )}

                      {Boolean(store.itemsNotInbox.length) && (
                        <div className={styles.section}>
                          {store.itemsNotInbox.map((item) => (
                            <DropdownCard
                              key={item.id}
                              item={item}
                              onChange={store.handleSetMentions}
                            />
                          ))}
                        </div>
                      )}
                    </Scrollbar>
                  )}
                </div>
              )}
            </DropdownContent>
          )
        }}
        tooltipProps={{ zIndex: ZIndex.OVERLAY_TOP_1 }}
        PopperProps={{
          popperOptions: {
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  boundary: innerRef.current,
                  padding: 0,
                },
              },
            ],
          },
        }}
        width={width}
        margin={0}
      />
    )
  }
)
