import { ReactElement } from 'react'
import { ActionItem, IIconButtonProps } from 'shared/ui'
import { Message } from 'entities/Message/model/Message'
import { IModalCloseProps } from 'widgets/Record'
import type { MessageFieldStore } from './messageFieldStore'

export const SHORT_URL_POSTFIX_DEFAULT = '-XXXXX'

export enum EnumVariantMessageField {
  Default = 'default',
  Note = 'note',
  Conversation = 'conversation',
  Broadcast = 'broadcast',
}

export type IInputAction = {
  value: string
  action: () => void
}

export type IMessageFieldLimit = {
  maxLength: number
  message: string
  showAlert?: boolean
  showNotification?: boolean
}

export type IMessageFieldEvents = {
  onFocus?: () => void
  onClick?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  onInit?: () => void
  onCloseEdit?: (() => void) | null
}
export type IMessageFieldEventsFactory = (store: MessageFieldStore) => IMessageFieldEvents

export type IMessageFieldActions = Array<ActionItem>
export type IMessageFieldActionsFactory = (store: MessageFieldStore) => IMessageFieldActions

export type IMessageFieldInputActions = Array<IInputAction>
export type IMessageFieldInputActionsFactory = (
  store: MessageFieldStore
) => IMessageFieldInputActions

export type IMessageFieldStoreProps = {
  makeEvents?: IMessageFieldEventsFactory
  makeActions?: IMessageFieldActionsFactory
  makeInputActions?: IMessageFieldInputActionsFactory

  showActionsItems?: number
  noBorder?: boolean
  noCounter?: boolean
  placeholder?: string
  minHeight?: number
  maxHeight?: number
  fromSavedReply?: boolean
  resetOnUnmount?: boolean
  replaceMergeFieldsOnPaste?: boolean
  textLimit?: Partial<IMessageFieldLimit>
  sendActionBtnProps?: (Omit<IIconButtonProps, 'onClick'> & { onClick?: () => void }) | null
  editMessage?: Message
  variant?: EnumVariantMessageField
  isShortUrlPostfix?: boolean
  customSendAction?: ReactElement | null
  recordModalCloseProps?: IModalCloseProps | null
  withTrialText?: boolean
  disabledFields?: IDisabledFields
  isCurrentAirCall?: boolean
  sub_source?: string
}

export enum EnumMessageFieldMode {
  Sms = 'sms',
  Note = 'note',
}

enum EnumDisabledFields {
  recordVideo = 'recordVideo',
  recordAudio = 'recordAudio',
  uploadImage = 'uploadImage',
}

export type IDisabledFields = Partial<Record<EnumDisabledFields, string>>
