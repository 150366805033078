// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z1rIRea5jpbLWxZxDMvw{padding:0}.uiXZ880hN1yBkdiO3rWk{height:40px;width:40px;display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/CheckboxCell/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CAEF,sBACE,WAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".checkboxCell {\n  padding: 0;\n}\n.wrapCheckbox{\n  height: 40px;\n  width: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxCell": "Z1rIRea5jpbLWxZxDMvw",
	"wrapCheckbox": "uiXZ880hN1yBkdiO3rWk"
};
export default ___CSS_LOADER_EXPORT___;
