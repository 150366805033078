import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import {
  Avatar,
  Dropdown,
  DropdownContent,
  Icon,
  Switch,
  Typography,
  Tooltip,
  IconButton,
} from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { configStore } from 'entities/Config'
import { SettingsApi } from 'entities/Settings'
import { authStore } from 'entities/Auth'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { multiOrganizationsDropdownStore } from 'widgets/MultiOrganizationsDropdown'
import { MultiOrganizationsDropdown } from 'widgets/MultiOrganizationsDropdown/ui/MultiOrganizationsDropdown'
import { commonTooltipProps } from '../menuItems'

import styles from './styles.module.scss'
import RouterLink from './RouterLink'
import Credits from './Credits'

export const MainDropdown = observer(() => {
  const switchingToOldVersion = useRef(false)
  const { user, toggleAwayStatus, awayLoading } = usersStore
  const { isDeveloper } = configStore
  const { show_new_design_only } = featureFlagsStore
  const { activeOrganizationAvatarInfo, activeOrganisationName } = multiOrganizationsDropdownStore

  const handleAwayStatus = () => {
    if (awayLoading) return
    toggleAwayStatus()
  }

  const switchToOldVersion = () => {
    if (switchingToOldVersion.current) return
    switchingToOldVersion.current = true

    SettingsApi.updateUsersSetting({
      featureKey: 'salesmsg-design-v2',
      settingsKey: 'active',
      value: false,
    })
      .then(() => window.location.reload())
      .catch(() => (switchingToOldVersion.current = false))
  }

  type IContentProps = { onClose: () => void; dropdownId: string }

  const Content = ({ dropdownId }: IContentProps) => (
    <DropdownContent className={styles.container} noPadding>
      <div className={styles.block} aria-label={getAriaLabel('MainDropdown', 'Block')}>
        <RouterLink
          route='/settings/personal/settings/profile'
          aria-label={getAriaLabel('MainDropdown', 'Link')}
        >
          <div
            className={styles.userWrapper}
            aria-label={getAriaLabel('MainDropdown', 'userWrapper')}
          >
            <div className={styles.avatar} aria-label={getAriaLabel('MainDropdown', 'avatar')}>
              {user && <Avatar online={!user.isAway} info={user.avatarInfo} size={32} />}
            </div>
            <div className={styles.info} aria-label={getAriaLabel('MainDropdown', 'info')}>
              <Typography
                variant={'body-rg-medium'}
                color={'var(--content-primary-primary)'}
                ariaLabel={'name'}
              >
                {user?.name}
              </Typography>
              <Typography
                variant={'body-sm-regular'}
                color={'var(--content-primary-tertiary)'}
                className={styles.role}
                ariaLabel={'role'}
              >
                {user?.role}
              </Typography>
            </div>
          </div>
        </RouterLink>
      </div>
      <div className={styles.divider} aria-label={getAriaLabel('MainDropdown', 'divider')} />
      <div className={styles.block} aria-label={getAriaLabel('MainDropdown', 'Block')}>
        <div className={styles.blockWrapperHover} onClick={handleAwayStatus}>
          <div className={styles.label}>
            <div className={styles.icon}>
              <Icon icon='alarm' />
            </div>
            <Typography variant={'body-md-regular'} ariaLabel={'SetAsAway'}>
              Set as away
            </Typography>
          </div>
          <Switch value={user?.isAway} />
        </div>
      </div>

      <div className={styles.divider} aria-label={getAriaLabel('MainDropdown', 'Divider')} />
      <MultiOrganizationsDropdown
        parentsDropdownId={[dropdownId]}
        placement={'right-start'}
        triggerComponent={(open) => (
          <div
            className={styles.block}
            aria-label={getAriaLabel('MainDropdown', 'ShowOrganizationsDropdown')}
          >
            <div
              className={classNames(styles.blockWrapperHover, {
                [styles.active]: open,
              })}
            >
              <div className={styles.label}>
                <div className={styles.icon}>
                  <Avatar info={activeOrganizationAvatarInfo} size={16} />
                </div>
                <Typography variant={'body-md-regular'} ariaLabel={'ShowOrganizationsDropdown'}>
                  {activeOrganisationName}
                </Typography>
              </div>
              <div className={styles.boxArrow}>
                <IconButton
                  icon={'chevronRight'}
                  size={'small'}
                  color={'transparent'}
                  ariaLabel='MenuTitle_arrow'
                />
              </div>
            </div>
          </div>
        )}
      />

      <div className={styles.divider} aria-label={getAriaLabel('MainDropdown', 'Divider')} />
      <Credits />
      <div className={styles.divider} aria-label={getAriaLabel('MainDropdown', 'Divider')} />
      <div>
        {!show_new_design_only && (
          <div
            className={styles.block}
            onClick={switchToOldVersion}
            aria-label={getAriaLabel('MainDropdown', 'SwitchToOldSalesmsg')}
          >
            <div className={styles.blockWrapperHover}>
              <div className={styles.label}>
                <div className={styles.icon}>
                  <Icon icon='back' />
                </div>
                <Typography variant={'body-md-regular'} ariaLabel={'SwitchToOldSalesmsg'}>
                  Switch to old Salesmsg
                </Typography>
              </div>
            </div>
          </div>
        )}

        {isDeveloper && (
          <a
            href='/nova'
            className={classNames(styles.block, styles.link)}
            aria-label={getAriaLabel('MainDropdown', 'Nova')}
          >
            <div className={styles.blockWrapperHover}>
              <div className={styles.label}>
                <div className={styles.icon}>
                  <Icon icon='briefcase' />
                </div>
                <Typography variant={'body-md-regular'} ariaLabel={'Nova'}>
                  Nova
                </Typography>
              </div>
            </div>
          </a>
        )}

        <RouterLink route='/settings/personal/settings/profile'>
          <div
            className={styles.blockWrapperHover}
            aria-label={getAriaLabel('MainDropdown', 'Settings')}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <Icon icon='settings' />
              </div>
              <Typography variant={'body-md-regular'} ariaLabel={'Settings'}>
                Settings
              </Typography>
            </div>
          </div>
        </RouterLink>

        <div className={styles.block} aria-label={getAriaLabel('MainDropdown', 'logout')}>
          <div
            className={styles.blockWrapperHover}
            onClick={() => {
              authStore.logout()
            }}
          >
            <div className={styles.label}>
              <div className={styles.icon}>
                <Icon icon='logOut' />
              </div>
              <Typography variant={'body-md-regular'} ariaLabel={'LogOut'}>
                Log out
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </DropdownContent>
  )

  return (
    <Dropdown
      ariaLabel={'MainDropdown'}
      width={256}
      placement={'right-start'}
      triggerComponent={(open) =>
        user && (
          <Tooltip label={'Profile'} {...commonTooltipProps}>
            <div
              className={classNames(styles.button, {
                [styles.active]: open,
              })}
              aria-label={getAriaLabel('MainDropdown', 'Trigger')}
            >
              <Avatar online={!user.isAway} size={24} info={user.avatarInfo} />
            </div>
          </Tooltip>
        )
      }
      customComponent={(onClose, dropdownId) => (
        <Content onClose={onClose} dropdownId={dropdownId} />
      )}
    />
  )
})
