import { numberFormat } from 'shared/lib'
import { IResponseOneTimeBroadcast } from 'entities/Broadcast/api/types'
import { Statistic } from 'entities/ContactStatistic/model/Statistic'

export class BroadcastOneTimeStatistic extends Statistic {
  constructor({
    contacts_count,
    all_statistics,
    short_urls,
    analytics,
    is_track_conversion,
  }: IResponseOneTimeBroadcast) {
    const statistic = all_statistics
    const unsubscribed_count = statistic?.opt_out_count
    const reply_count = statistic?.reply_count ?? null
    const success_count = statistic?.success_count || 0
    const total_count = statistic?.total_count || 0
    const undelivered_count = total_count - success_count
    const clicks_count = short_urls?.length
      ? short_urls.reduce((acc, cur) => acc + cur.clicks, 0)
      : null

    const conversions_count =
      analytics?.users_with_conversion || (is_track_conversion ? 0 : undefined)

    let revenue_cost: string | undefined = ''

    if (analytics?.conversion_value) {
      revenue_cost = numberFormat({
        value: analytics?.conversion_value,
        minimumFractionDigits: 0,
        currency: 'USD',
      })
    } else {
      revenue_cost = is_track_conversion
        ? numberFormat({
            minimumFractionDigits: 0,
            value: 0,
            currency: 'USD',
          })
        : undefined
    }

    super({
      contacts_count,
      reply_count,
      success_count,
      total_count,
      clicks_count,
      conversions_count,
      revenue_cost,
      undelivered_count,
      unsubscribed_count,
      is_track_conversion,
    })
  }

  get filters() {
    return this.getFilters('Broadcast contacts')
  }
}
