// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YVd114GWs39WGUaXJagc{position:relative;margin:0 auto;padding:0 40px;height:100%}.knz8WYENn6lr89SQuQ3x{display:grid;height:100%;padding:0 20px;grid-template-rows:87px 70px auto}.IwmkS07_dvZHiZWHR4vX{position:relative;width:500px;margin:0 auto;padding:56px 20px 12px}.pJDiCPu0_5t8x0QdHnip{display:inline-block;background:var(--gray-a-15);padding:3.5px 8px;border-radius:4px}.EBsCu7x_EznJZpG9iCuy{width:500px;margin:0 auto;padding:0 20px}.NgyQHksXcKuAF75NE2NJ{padding:0 20px 0 50px;margin:0 0 0 -30px;width:530px;height:100%;overflow:auto}.GfusV1b0jQnHvlvhIRWP{position:absolute;top:90px;left:-27px}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/StepsCommonLayout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,aAAA,CACA,cAAA,CACA,WAAA,CAEA,sBACE,YAAA,CACA,WAAA,CACA,cAAA,CACA,iCAAA,CAIJ,sBACE,iBAAA,CACA,WAAA,CACA,aAAA,CACA,sBAAA,CAEA,sBACE,oBAAA,CACA,2BAAA,CACA,iBAAA,CACA,iBAAA,CAIJ,sBACE,WAAA,CACA,aAAA,CACA,cAAA,CAGF,sBACE,qBAAA,CACA,kBAAA,CACA,WAAA,CACA,WAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".content {\n  position: relative;\n  margin: 0 auto;\n  padding: 0 40px;\n  height: 100%;\n\n  &__sub {\n    display: grid;\n    height: 100%;\n    padding: 0 20px;\n    grid-template-rows: 87px 70px auto;\n  }\n}\n\n.titleWrap {\n  position: relative;\n  width: 500px;\n  margin: 0 auto;\n  padding: 56px 20px 12px;\n\n  &__title {\n    display: inline-block;\n    background: var(--gray-a-15);\n    padding: 3.5px 8px;\n    border-radius: 4px;\n  }\n}\n\n.localProgress {\n  width: 500px;\n  margin: 0 auto;\n  padding: 0 20px;\n}\n\n.section {\n  padding: 0 20px 0 50px;\n  margin: 0 0 0 -30px;\n  width: calc(500px + 30px);\n  height: 100%;\n  overflow: auto;\n}\n\n.backButton {\n  position: absolute;\n  top: 90px;\n  left: -27px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "YVd114GWs39WGUaXJagc",
	"content__sub": "knz8WYENn6lr89SQuQ3x",
	"titleWrap": "IwmkS07_dvZHiZWHR4vX",
	"titleWrap__title": "pJDiCPu0_5t8x0QdHnip",
	"localProgress": "EBsCu7x_EznJZpG9iCuy",
	"section": "NgyQHksXcKuAF75NE2NJ",
	"backButton": "GfusV1b0jQnHvlvhIRWP"
};
export default ___CSS_LOADER_EXPORT___;
