import { observer } from 'mobx-react-lite'
import { ContentOverlay } from 'shared/ui'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'

interface IContactDetailsContentOverlayProps {
  onClickHandler?: () => void
}

export const ContactDetailsContentOverlay = observer(
  ({ onClickHandler }: IContactDetailsContentOverlayProps) => {
    const { isShowContactDetailsOverlay, handleChangeCollapse } = contactsDetailsGlobalStore

    if (!isShowContactDetailsOverlay) return null

    const onClick = () => {
      handleChangeCollapse(true)
      onClickHandler?.()
    }

    return <ContentOverlay onClick={onClick} />
  }
)
