import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { Typography } from 'shared/ui'
import { AdminRoutesEnum } from 'pages/admin'
import styles from './styles.module.scss'

export const CustomPriceLayout = ({ children }: PropsWithChildren) => (
  <section className={styles.rootLayout}>
    <div className={styles.wrapLink}>
      <Link to={`/admin/${AdminRoutesEnum.customPrices}/`} className={styles.link}>
        <Typography variant={'body-sm-regular'} pointer ariaLabel={'Custom prices'}>
          Custom prices
        </Typography>
      </Link>
    </div>
    {children}
  </section>
)
