import { observer } from 'mobx-react-lite'
import { InputCheckbox, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { ErrorBanner } from 'pages/settings/pages/compliance/pages/businessProfile/pages/ErrorBanner/ErrorBanner'
import styles from './styles.module.scss'

export const TermsAndPrivacy = observer(() => {
  const {
    isTermsAndPrivacyChecked,
    stepLoading,
    submitTermsAndPrivacy,
    toggleIsTermsAndPrivacyChecked,
  } = useBusinessProfileStepsContext()

  return (
    <div className={styles.wrap}>
      <PageDescription>
        Salesmsg will process your personal data according to the{' '}
        <a href={links.privacyPolicy} target='_blank' rel='noreferrer' className={styles.link}>
          <Typography
            ariaLabel='TermsAndPrivacy_link'
            variant='body-md-regular'
            color='var(--content-brand-primary)'
          >
            {'Salesmsg Privacy Policy'}
          </Typography>
        </a>
      </PageDescription>

      <ErrorBanner />

      <InputCheckbox
        className={styles.declaration}
        checked={isTermsAndPrivacyChecked}
        onChecked={toggleIsTermsAndPrivacyChecked}
        color='var(--content-primary-tertiary)'
        label='I declare that the information provided is accurate. I acknowledge that Salesmsg will be processing the information provided for the purposes of identity verification, and that telecommunications service providers and registries reserve the right to retain the Business Profile information after account closure in the case of a traceback from a regulatory authority or equivalent.'
      />

      <NextButton
        loading={stepLoading}
        disabled={!isTermsAndPrivacyChecked || stepLoading}
        text='Submit for review'
        onClick={submitTermsAndPrivacy}
      />
    </div>
  )
})
