import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Actions, Avatar, Checkbox, Icon, Typography } from 'shared/ui'
import { formatBytes } from 'shared/lib'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { checkCDN } from 'shared/lib/checkCDN'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { recentStore } from 'widgets/RecentlySent'
import styles from './styles.module.scss'

export const RecentItem = observer(
  ({
    attachment,
    onSelectAttachment,
  }: {
    attachment: Attachment
    onSelectAttachment: (attachment: Attachment) => void
  }) => {
    const {
      isCheckedMode,
      checkedRecentAttachmentIds,
      setCheckedRecentAttachmentIds,
      dropdownId,
      onDelete,
    } = recentStore

    const isChecked = checkedRecentAttachmentIds.has(attachment.id)

    const [isShowMore, setShowMore] = useState(false)
    const [isShowCheckbox, setShowCheckbox] = useState(false)

    const onChecked = () => {
      setCheckedRecentAttachmentIds(attachment.id)
    }

    const onClick = () => {
      if (isCheckedMode) {
        onChecked()
      } else {
        onSelectAttachment(attachment)
      }
    }
    const url = attachment.source_short || attachment.source

    const mediaContent = useMemo(() => {
      if (attachment.type === 'video') {
        return (
          <div className={styles.video}>
            <div className={styles.play}>
              <Icon icon={'play1'} fontSize={8} />
            </div>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              src={url}
              poster={attachment.media_preview?.source}
              {...(checkCDN(url) ? { crossOrigin: 'use-credentials' } : null)}
            />
          </div>
        )
      }
      if (
        attachment.type === 'image' ||
        attachment.type === 'gif' ||
        attachment.type === 'previewGif'
      ) {
        return (
          <div
            style={{
              backgroundImage: `url(${url})`,
            }}
            className={styles.img}
          />
        )
      }
      if (attachment.type === 'voice') {
        return (
          <div className={styles.voice}>
            <Icon icon={'play1'} fontSize={12} />
          </div>
        )
      }
      if (attachment.type === 'audio') {
        return (
          <div className={styles.audio}>
            <Icon icon={'audio'} fontSize={20} />
          </div>
        )
      }
      if (attachment.type === 'vcard' && attachment?.vCard) {
        const { first_name, last_name, name } = attachment?.vCard
        return (
          <Avatar
            info={{
              firstName: first_name,
              lastName: last_name,
              icon: !name ? 'user' : undefined,
            }}
            size={32}
          />
        )
      }
      if (attachment.type === 'attachment') {
        return (
          <div className={styles.attachment}>
            <Icon icon={'attachment'} fontSize={20} />
          </div>
        )
      }

      return <div></div>
    }, [attachment])

    const title = useMemo(() => {
      if (attachment.type === 'vcard' && attachment.vCard) {
        const { phone, name } = attachment.vCard
        return name || phone
      }

      return attachment.name
    }, [attachment])

    const info = useMemo(() => {
      if (attachment.type === 'vcard' && attachment.vCard) {
        const { phone, name } = attachment.vCard
        return name ? phone : null
      }
      if (
        attachment.type === 'video' ||
        attachment.type === 'image' ||
        attachment.type === 'gif' ||
        attachment.type === 'previewGif' ||
        attachment.type === 'attachment'
      ) {
        return formatBytes(attachment.size || 0)
      }

      if (attachment.type === 'voice' || attachment.type === 'audio') {
        return secondsToTime(attachment.duration || 0)
      }

      return ''
    }, [attachment])

    const onMouseEnterCard = () => {
      if (isCheckedMode) {
        setShowCheckbox(true)
      } else {
        setShowMore(true)
      }
    }
    const onMouseLeaveCard = () => {
      if (isCheckedMode) {
        setShowCheckbox(false)
      } else {
        setShowMore(false)
      }
    }

    const onMouseEnterLeftContent = () => {
      setShowCheckbox(true)
    }
    const onMouseLeaveLeftContent = () => {
      setShowCheckbox(false)
    }

    return (
      <div
        key={attachment.id}
        className={classNames(styles.wrap, { [styles.isChecked]: isChecked })}
        onClick={onClick}
        onMouseEnter={onMouseEnterCard}
        onMouseLeave={onMouseLeaveCard}
      >
        <div
          onMouseEnter={onMouseEnterLeftContent}
          onMouseLeave={onMouseLeaveLeftContent}
          className={styles.leftContent}
        >
          {isShowCheckbox || isChecked ? (
            <Checkbox
              checked={isChecked}
              onChangeValue={onChecked}
              onClick={(e) => e.stopPropagation()}
              size={32}
            />
          ) : (
            mediaContent
          )}
        </div>
        <div className={styles.rightContent}>
          <div className={styles.body}>
            <Typography
              variant={'body-md-regular'}
              ellipsis
              title={attachment.name || ''}
              ariaLabel={'title'}
            >
              {title}
            </Typography>
            <Typography
              variant={'body-xs-regular'}
              ellipsis
              title={attachment.name || ''}
              className={styles.info}
              ariaLabel={'info'}
            >
              {info}
            </Typography>
          </div>
          {isShowMore && !isChecked && (
            <div onClick={(e) => e.stopPropagation()}>
              <Actions
                parentsDropdownId={[dropdownId]}
                noVisibleActions
                iconButtonProps={{
                  size: 'small',
                  fontSize: 16,
                }}
                actions={[
                  {
                    icon: 'delete',
                    text: 'Delete',
                    onAction: () => onDelete([attachment.id]),
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
)
