import React from 'react'
import classnames from 'classnames'
import { Avatar, IAvatarInfo } from 'shared/ui'
import styles from './styles.module.scss'

export type PropsAvatarGroup = {
  items?: (IAvatarInfo | undefined)[]
  size?: number
}

const AvatarGroup: React.FC<PropsAvatarGroup> = ({ items = [], size = 40 }) => {
  let type = ''
  let fontSize = size / 2.6

  if (items.length === 1) {
    type = 'one'
  }
  if (items.length === 2) {
    type = 'two'
    fontSize = 12
  }
  if (items.length === 3) {
    type = 'three'
    fontSize = 12
  }
  if (items.length >= 4) {
    type = 'four'
    fontSize = 12
  }

  return (
    <div
      className={classnames(styles.wrap, styles[type])}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        flex: `0 0 ${size}px`,
      }}
    >
      {items.map((item, index) => {
        if (!item) return null
        if (index > 4) return null

        return (
          <div key={index} className={styles.item}>
            <Avatar
              size={items.length === 1 ? size : fontSize}
              info={item}
              fontSize={fontSize}
              is_full_width
            />
          </div>
        )
      })}
    </div>
  )
}

export { AvatarGroup }
