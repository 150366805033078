import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket/store'
import type { IResponseEventMessage } from 'entities/Message'
import { dataLogStore } from 'entities/DataLog/store/dataLogStore'

const MessageReceivedNew = (data: IResponseEventMessage) => {
  const { message, conversation } = data

  // TODO: SWR-14336: Will be uncommit and updated with SWR-14336
  // dataLogStore.setItem({
  //   logLevel: 'info',
  //   message: 'EventMessageReceivedNewLog',
  //   details: {
  //     conversationId: conversation.id,
  //     messageId: message.id,
  //   },
  // })
}

export const useEventMessageReceivedNewLog = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.MessageReceivedNew, MessageReceivedNew)
    }
  }, [websocket.isConnected])
}
