import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'

import styles from './styles.module.scss'

export interface ICreateTagButtonProps {
  term: string
  onClick?: () => void
}

export const CreateTagButton = observer(({ term, onClick }: ICreateTagButtonProps) => {
  return (
    <Button
      icon='add'
      size='small'
      contained='secondary'
      className={styles.createButton}
      aria-label='CreateNewTagButton'
      text={
        <>
          Create tag <span className={styles.createButtonLabel}>{term}</span>
        </>
      }
      onClick={onClick}
    />
  )
})
