// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VNGSbljfZaAXKVx5NgWI{min-height:40px;align-content:center;padding:0 8px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.jK4wMNLH5tLXGde3AMY2{overflow:initial}.DjmCk0jFplPOOzNaHAVX{display:block;height:100%;width:100%;color:unset;text-decoration:none}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/TableCell/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,oBAAA,CACA,aAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,aAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,oBAAA","sourcesContent":[".tableCell {\n  min-height: 40px;\n  align-content: center;\n  padding: 0 8px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.overflowInitial {\n  overflow: initial;\n}\n\n.link {\n  display: block;\n  height: 100%;\n  width: 100%;\n  color: unset;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableCell": "VNGSbljfZaAXKVx5NgWI",
	"overflowInitial": "jK4wMNLH5tLXGde3AMY2",
	"link": "DjmCk0jFplPOOzNaHAVX"
};
export default ___CSS_LOADER_EXPORT___;
