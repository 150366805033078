import { createContext, useContext } from 'react'
import { TableStore } from 'shared/ui/Table'

export const TableContext = createContext<TableStore | null>(null)

export function useTableContext() {
  const context = useContext(TableContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with TableContext')
  }
  return context
}
