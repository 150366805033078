import { createContext, useContext } from 'react'
import { type ContactsDetailsStore } from 'widgets/ContactsDetails'

export const ContactsDetailsContext = createContext<ContactsDetailsStore | null>(null)

export function useContactsDetailsContext() {
  const context = useContext(ContactsDetailsContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ContactsDetailsStoreProvider'
    )
  }
  return context
}
