export class Deferred<T> {
  private _resolve!: (value: T) => void
  private _promise = new Promise<T>((resolve) => (this._resolve = resolve))

  get future() {
    return this._promise
  }

  complete(value: T) {
    this._resolve(value)
  }
}
