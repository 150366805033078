import { makeObservable, observable, reaction, action, computed, runInAction } from 'mobx'
import dayjs from 'dayjs'
import { IAvatarProps } from 'shared/ui'
import { Base } from 'models'
import { ICallItem, ICallItemType, ICallPeopleStatus } from 'entities/Call'

export class CallPeople extends Base {
  id: number
  name: string
  desc: string
  avatarProps: IAvatarProps
  type: ICallItemType
  status: ICallPeopleStatus = 'default'
  isHold = false
  isHoldDisabled = false
  isHoldLoading = false
  isDeleteLoading = false
  timestamp = 0
  is_participant = false
  timeInterval: ReturnType<typeof setInterval> | null = null
  disabled = false
  national_number?: string

  constructor(item: ICallItem) {
    super()

    this.id = item.id
    this.name = item.name
    this.desc = item.desc || ''
    this.avatarProps = item.avatarProps
    this.type = item.type
    this.disabled = item.disabled || false
    this.is_participant = item.is_participant || false
    this.national_number = item.national_number || ''

    this.reactionStatus()

    makeObservable(this, {
      status: observable,
      timestamp: observable,
      is_participant: observable,
      label: computed,
      handleIncrementTime: action,
      resetIncrementTime: action,
      isHold: observable,
      isHoldDisabled: observable,
      isHoldLoading: observable,
      isDeleteLoading: observable,
    })
  }

  get uniqueId() {
    if (this.type === 'inbox') {
      return `inbox_id:${this.id}`
    }

    if (this.type === 'contact') {
      return `contact_id:${this.id}`
    }

    return `number:+1${this.id}`
  }

  get label() {
    if (this.isHold) {
      return 'Hold'
    }

    if (this.status === 'calling') {
      return 'Calling...'
    }

    if (this.status === 'connected') {
      return dayjs(this.timestamp).format('mm:ss')
    }

    return this.desc
  }

  updateIsParticipant = (status: boolean) => {
    this.is_participant = status
  }

  updateStatus = (status: ICallPeopleStatus) => {
    this.status = status
  }

  updateIsDeleteLoading = (status: boolean) => {
    this.isDeleteLoading = status
  }

  updateIsHold = (status: boolean) => {
    this.isHold = status
  }

  updateIsHoldDisabled = (status: boolean) => {
    this.isHoldDisabled = status
  }

  updateIsHoldLoading = (status: boolean) => {
    this.isHoldLoading = status
  }

  handleIncrementTime = () => {
    this.timeInterval = setInterval(() => {
      runInAction(() => {
        this.timestamp += 1000
      })
    }, 1000)
  }

  resetIncrementTime = () => {
    runInAction(() => {
      this.timestamp = 0
    })

    if (this.timeInterval) {
      clearInterval(this.timeInterval)
    }
  }

  reactionStatus = () => {
    reaction(
      () => this.status,
      (value) => {
        if (value === 'connected') {
          this.handleIncrementTime()
        } else {
          this.resetIncrementTime()
        }
      }
    )
  }
}
