import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import { Divider, Switch, Typography } from 'shared/ui'
import { ITrigger } from 'entities/Trigger/model/Trigger'

import type { IResponseTrigger } from 'entities/Trigger/api/type'
import styles from './styles.module.scss'

type TriggerActionProps = {
  trigger: ITrigger
  toggleTriggerStatus: (id: number) => Promise<IResponseTrigger | null>
  fullSize?: boolean
}

export const ActiveTriggerAction = ({
  trigger,
  toggleTriggerStatus,
  fullSize = false,
}: TriggerActionProps) => {
  const [isActiveUpdating, setIsActiveUpdating] = useState(false)

  const handleToggleTriggerStatus = () => {
    setIsActiveUpdating(true)
    toggleTriggerStatus(trigger.id)
      .then((updatedTrigger) => updatedTrigger && trigger.syncOrigin(updatedTrigger))
      .finally(() => setIsActiveUpdating(false))
  }

  if (fullSize) {
    return (
      <div className={styles.fullSizeContainer}>
        {isActiveUpdating ? (
          <CircularProgress size={fullSize ? 20 : 12} />
        ) : (
          <Switch
            className={classNames(styles.activeActionSwitch, { [styles.fullSize]: fullSize })}
            onChange={handleToggleTriggerStatus}
            size='small'
            value={trigger.status === 'active'}
          />
        )}
      </div>
    )
  }

  return (
    <>
      <div
        className={styles.activeAction}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          handleToggleTriggerStatus()
        }}
      >
        <div className={styles.activeActionControl}>
          {isActiveUpdating ? (
            <CircularProgress size={12} />
          ) : (
            <Switch
              className={styles.activeActionSwitch}
              size='small'
              value={trigger.status === 'active'}
            />
          )}
        </div>
        <Typography ariaLabel={'TriggerStatus'} variant={'body-md-regular'}>
          {trigger.status === 'active' ? 'Active' : 'Disabled'}
        </Typography>
      </div>
      <Divider variant={'dropdown'} />
    </>
  )
}
