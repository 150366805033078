import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { conversationStore } from 'entities/Conversation'
import { useContactsConversationOpenContext } from 'widgets/ContactsDetails/ui/ContactsConversationOpen/context'
import { ConversationNew } from 'widgets/ConversationNew'
import {
  ConversationContent,
  ConversationMessageField,
  ConversationMessageFieldFooter,
  ConversationMessagesContext,
  TypingContent,
} from 'widgets/ConversationMessages'
import { useEventMessageUpdatedNew } from 'widgets/ContactsDetails/ui/ContactsConversationOpen/events/EventMessageUpdatedNew'
import { useEventMessageReceivedNew } from 'widgets/ContactsDetails/ui/ContactsConversationOpen/events/EventMessageReceivedNew'
import styles from './styles.module.scss'

export const ContactsConversationOpenContent = observer(() => {
  const store = useContactsConversationOpenContext()
  const conversationMessagesStore = store.conversationMessagesStore
  const { messageFieldStore } = conversationMessagesStore

  useEventMessageUpdatedNew(conversationStore, store)
  useEventMessageReceivedNew(conversationStore, store)

  useEffect(() => {
    store.initReaction()

    return () => {
      store.reset()
    }
  }, [])

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <ConversationNew store={store.conversationNewSearchStore} />
      </div>
      <div className={styles.messages}>
        <ConversationMessagesContext.Provider value={store.conversationMessagesStore}>
          <ConversationContent />
          <TypingContent />
          <ConversationMessageField />
          <ConversationMessageFieldFooter
            show={messageFieldStore.isModeNote}
            icon={'infoCircle'}
            text={'Notes are visible only to Inbox members'}
          />
        </ConversationMessagesContext.Provider>
      </div>
    </div>
  )
})
