import type { IParsedVcard } from 'entities/VCard'
import type { Attachment } from 'entities/Attachment/model/Attachment'

type Props = {
  content_type: string
  parsedVCard?: Array<IParsedVcard>
  original_source?: string
  name?: string
}

export function getAttachmentType({
  content_type,
  parsedVCard,
  original_source,
  name,
}: Props): Attachment['type'] {
  if (parsedVCard && parsedVCard.length) {
    return 'vcard'
  }
  if (content_type === 'image/gif' && original_source) {
    return 'previewGif'
  }
  if (content_type === 'image/gif') {
    return 'gif'
  }
  if (content_type.includes('image')) {
    return 'image'
  }
  if (content_type.includes('video') || name?.includes('.webm')) {
    return 'video'
  }
  if (content_type.includes('audio')) {
    return 'audio'
  }
  return 'attachment'
}
