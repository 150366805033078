import { observer } from 'mobx-react-lite'
import React from 'react'
import { usersStore } from 'entities/Users'
import { IViewingUser } from 'entities/Viewing'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { TypingCard } from 'widgets/TypingCard/TypingCard'
import { MembersTypingCard } from 'widgets/MembersTypingCard/MembersTypingCard'

import styles from './styles.module.scss'

export const TypingContent = observer(() => {
  const store = useConversationMessagesContext()

  const items: IViewingUser[] = []

  store.viewings?.forEach((item) => {
    const user = usersStore.getItem(item.userId)
    if (user) {
      items.push({
        user,
        status: item.status,
      })
    }
  })

  const typingContent = () => {
    if (store.typingsCount) {
      return (
        <>
          {store.typingsCount > 1 ? (
            <TypingCard count={store.typingsCount} />
          ) : (
            <TypingCard typing={store.typings[0]} count={store.typingsCount} />
          )}
        </>
      )
    }

    return null
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.left}>
        {items?.length ? <MembersTypingCard items={items} /> : null}
      </div>
      <div className={styles.right}>{typingContent()}</div>
    </div>
  )
})
