import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { contactsStore } from 'entities/Contacts'
import type { IResponseContact } from 'entities/Contacts/api/types'

const ContactUpdated = (data: IResponseContact) => contactsStore.updateItem(data)

export const useEventContactUpdated = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.ContactUpdated, ContactUpdated)
    }
  }, [websocket.isConnected])
}
