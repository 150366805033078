import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'shared/ui'
import { useSearchDropdownContext } from 'widgets/SearchDropdown'
import { SearchElement } from './SearchElement'
import { DropdownContent } from './DropdownContent/DropdownContent'

export const SearchDropdownContent = observer(() => {
  const store = useSearchDropdownContext()
  const triggerComponent =
    store.dropdownTriggerComponent || ((open) => <SearchElement active={open} />)
  const customComponent =
    store.dropdownCustomComponent ||
    ((onCloseMenu, dropdownId) => (
      <DropdownContent onCloseMenu={onCloseMenu} parentsDropdownId={[dropdownId]} />
    ))
  const isShow = (!store.isDefaultType && store.isShowMore) || store.isOpen

  useEffect(() => {
    return () => {
      store.reset()
    }
  }, [])

  return (
    <Dropdown
      onToggle={store.onToggle}
      iconButtonProps={{
        size: 'medium',
        fontSize: 20,
        ariaLabel: 'SearchDropdown_trigger',
      }}
      show={isShow}
      triggerComponent={triggerComponent}
      placement={'bottom-start'}
      sizeTriggerButton={'medium'}
      variantTriggerButton={'icon'}
      width={store.hasFilters ? 528 : 141}
      customComponent={customComponent}
      {...store.dropdownProps}
    />
  )
})
