import classNames from 'classnames'
import { Icon, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

import styles from '../styles.module.scss'

export const RecordAudioItem = ({
  btnClass,
  disabled,
  onCloseMenu,
}: {
  btnClass: string
  disabled?: boolean
  onCloseMenu: () => void
}) => {
  const messageFieldStore = useMessageFieldContext()

  const handleClose = () => messageFieldStore.setRecordAudioState(false)
  const onAllow = () => messageFieldStore.setRecordAudioState(true)

  const onAddRecord = (file: File) => {
    handleClose()
    messageFieldStore.addMediaFiles([file])
  }

  const onRecord = () => {
    messageFieldStore.recordAudioStore.init({ onAddRecord, onAllow, handleClose })
    onCloseMenu()
  }

  return (
    <button
      className={classNames(btnClass, styles.actionButton, disabled && styles.disabled)}
      onClick={onRecord}
      disabled={disabled}
      aria-label={getAriaLabel('RecordAudio', 'MessageField')}
    >
      <Icon disabled={disabled} icon={'microphone'} fontSize={16} tertiary />
      <Typography variant={'body-md-medium'} ariaLabel={'Record audio'}>
        Record audio
      </Typography>
    </button>
  )
}
