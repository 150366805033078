import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { EnumHelperText, HelperText } from 'shared/ui'
import { MessageField } from 'widgets/MessageField'
import { useChatbotContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export const FirstMessage = observer(() => {
  const isDirty = useRef(false)
  const { errors, message, setDraggingCondition } = useChatbotContext()

  const messageError = errors.getError('first_message.message')

  useEffect(() => {
    if (isDirty.current) errors.removeError('first_message')

    isDirty.current = true
  }, [message.field.messageText])

  useEffect(() => message.syncMessage(), [])

  return (
    <div className={styles.message}>
      <div
        className={classNames(styles.field, {
          [styles.fieldError]: !!messageError,
        })}
      >
        <div className={styles.alerts} ref={message.field.setAlertElement} />
        <MessageField messageFieldStore={message.field} onDragHandler={setDraggingCondition} />
      </div>
      {!!messageError && (
        <HelperText
          icon={'alertTriangleSmall'}
          variant={EnumHelperText.Error}
          text={messageError}
        />
      )}
    </div>
  )
})
