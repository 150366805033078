import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon, Switch } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import type { Contact } from 'entities/Contacts/model/Contact'
import { MatchedContactCard } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/LinkContact/ui/LinkContactModalContent/MatchedContactCard/MatchedContactCard'
import { IntegrationContactCard } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/LinkContact/ui/LinkContactModalContent/IntegrationContactCard/IntegrationContactCard'
import type { LinkContactStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/LinkContact/store/linkContactStore'
import styles from './styles.module.scss'

type LinkMatchingTabProps = {
  contact: Contact
  linkContactStore: LinkContactStore
}

export const LinkMatchingTab: FC<LinkMatchingTabProps> = observer(
  ({ contact, linkContactStore }) => {
    const { selectedMatchedId, shouldUpdate, toggleUpdate, getItem } = linkContactStore
    const contacts = getItem(contact.id) || []

    return (
      <div className={classnames(styles.wrap)}>
        <IntegrationContactCard contact={contact} noAction />

        <div className={styles.divider}>
          <div className={styles.icon}>
            <Icon icon='arrowDown' fontSize={16} color='var(--content-primary-tertiary)' />
          </div>
        </div>

        {<MatchedContactCard linkContactStore={linkContactStore} contacts={contacts} />}

        {selectedMatchedId && (
          <div className={styles.updateWrapper} onClick={() => toggleUpdate(!shouldUpdate)}>
            <Label
              label={{
                text: 'Update Salesmsg contact with the linked contact data',
              }}
            />
            <Switch value={shouldUpdate} onChange={toggleUpdate} size='small' />
          </div>
        )}
      </div>
    )
  }
)
