import { useState } from 'react'
import { MenuItemVue } from 'shared/ui'
import { type IMenuItemProps, MenuItem } from '../Menu/MenuItem/MenuItem'
import { MenuTitle } from '../Menu/Title/MenuTitle'

interface IMenuGroupProps {
  title: string
  items: IMenuItemProps[]
  className?: string
  marginBottom?: number
  initOpen?: boolean
  withExpand?: boolean
}

export const MenuGroup = ({
  title,
  items,
  className = '',
  initOpen = true,
  withExpand = false,
}: IMenuGroupProps) => {
  const [open, setOpen] = useState(initOpen)

  return (
    <div className={className}>
      <MenuTitle
        title={title}
        {...(withExpand && {
          open: open,
          handleToggle: () => setOpen(!open),
        })}
      />
      {(!withExpand || open) &&
        items.map((item) => {
          if (item.type === 'nav') {
            if (window.DesignV2API && !window.DesignV2API?.handleCheckPage(item.to)) {
              return <MenuItemVue key={String(item.label)} {...item} />
            }

            return <MenuItem key={String(item.label)} {...item} />
          } else {
            return <MenuItem key={item.title} {...item} />
          }
        })}
    </div>
  )
}
