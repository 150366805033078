import { observer } from 'mobx-react-lite'
import { Dropdown, EnumDropdownItemVariant, IDropdownItem, IDropdownProps } from 'shared/ui'
import { CannedMessage } from 'entities/CannedMessages/model/CannedMessage'
import { savedRepliesStore } from 'widgets/SavedReplies'

export const SavedReplyActions = observer(
  ({
    savedReply,
    sizeTriggerButton = 'small',
    placement = 'bottom-start',
    withoutAdd,
    tooltipParentElement,
    collapsed,
  }: {
    savedReply: CannedMessage
    sizeTriggerButton?: IDropdownProps['sizeTriggerButton']
    placement?: IDropdownProps['placement']
    withoutAdd?: boolean
    tooltipParentElement?: HTMLDivElement | null
    collapsed?: boolean
  }) => {
    const ownerItems: IDropdownProps['items'] = [
      {
        id: 'add',
        label: 'Add to conversation',
        iconL: 'add',
      },
      {
        id: 'favorite',
        label: savedReply.is_favorite ? 'Unfavorite' : 'Favorite',
        iconL: savedReply.is_favorite ? 'starFilled1' : 'starLine',
      },
      {
        id: 'edit',
        label: 'Edit',
        iconL: 'edit',
      },
      {
        id: 'duplicate',
        label: 'Duplicate',
        iconL: 'copy',
        separator: true,
      },

      {
        id: 'visibility',
        wrapLabel: 'Visibility',
        label: savedReply.public ? 'Everyone' : 'Personal',
        iconL: savedReply.public ? 'users' : 'user',
        iconR: savedRepliesStore.disabledVisibility ? undefined : 'chevronRight',
        disabled: savedRepliesStore.disabledVisibility,
        separator: true,
        children: savedRepliesStore.disabledVisibility
          ? undefined
          : [
              {
                id: 'personal',
                label: 'Personal',
                iconL: 'user',
                activeValue: !savedReply.public,
                variant: EnumDropdownItemVariant.CheckedRight,
              },
              {
                id: 'everyone',
                label: 'Everyone',
                iconL: 'users',
                activeValue: savedReply.public,
                variant: EnumDropdownItemVariant.CheckedRight,
              },
            ],
      },
      {
        id: 'delete',
        label: 'Delete',
        iconL: 'delete',
      },
    ]

    const teamItems: IDropdownProps['items'] = [
      {
        id: 'add',
        label: 'Add to conversation',
        iconL: 'add',
      },
      {
        id: 'favorite',
        label: savedReply.is_favorite ? 'Unfavorite' : 'Favorite',
        iconL: savedReply.is_favorite ? 'starFilled1' : 'starLine',
      },
      {
        id: 'duplicate',
        label: 'Duplicate',
        iconL: 'copy',
      },
    ]

    const onChange = (item: IDropdownItem) => {
      if (item.id === 'add') {
        savedRepliesStore.handleAddSavedReplies(savedReply)
      }
      if (item.id === 'edit') {
        savedRepliesStore.handleEditSavedReplies(savedReply)
      }
      if (item.id === 'duplicate') {
        savedRepliesStore.handleDuplicateSavedReplies(savedReply)
      }
      if (item.id === 'personal') {
        savedRepliesStore.handleChangeVisibilitySavedReplies(savedReply, false)
      }
      if (item.id === 'everyone') {
        savedRepliesStore.handleChangeVisibilitySavedReplies(savedReply, true)
      }
      if (item.id === 'delete') {
        savedRepliesStore.handleDeleteSavedReplies(savedReply)
      }
      if (item.id === 'favorite') {
        savedRepliesStore.handleFavoriteSavedReplies(savedReply)
      }
    }
    const onToggle = (value: boolean) => {
      if (!withoutAdd) {
        savedRepliesStore.setVisibleSavedRepliesActionsId(value ? savedReply.id : 0)
      }
    }

    if (collapsed) {
      return (
        <Dropdown
          ariaLabel={'SavedReplyActions'}
          icon={'moreHorizontal'}
          sizeTriggerButton={sizeTriggerButton}
          placement={placement}
          items={(savedReply.isMyReply ? ownerItems : teamItems).filter((item) =>
            withoutAdd ? item.id !== 'add' : true
          )}
          onToggle={onToggle}
          onChange={onChange}
          tooltipProps={{
            label: 'Manage',
            placement: 'top',
            PopperProps: {
              popperOptions: {
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: tooltipParentElement,
                      padding: 8,
                    },
                  },
                ],
              },
            },
          }}
        />
      )
    }

    return (
      <Dropdown
        ariaLabel={'SavedReplyActions'}
        icon={'moreHorizontal'}
        sizeTriggerButton={sizeTriggerButton}
        placement={placement}
        items={(savedReply.isMyReply ? ownerItems : teamItems).filter((item) =>
          withoutAdd ? item.id !== 'add' : true
        )}
        typeButton={'button'}
        buttonProps={{
          text: 'Manage',
          icon: 'settings',
          contained: 'secondary',
          size: 'medium',
        }}
        onToggle={onToggle}
        onChange={onChange}
        tooltipProps={{
          label: 'Manage',
          placement: 'top',
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: tooltipParentElement,
                    padding: 8,
                  },
                },
              ],
            },
          },
        }}
      />
    )
  }
)
