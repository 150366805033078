import { AudioPlayer } from 'shared/ui/AudioPlayer'
import { Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import type { Attachment } from 'entities/Attachment/model/Attachment'
import styles from './styles.module.scss'

type IRinglessContentProps = {
  attachments: Attachment[]
}

export const RinglessContent = ({ attachments }: IRinglessContentProps) => {
  const attachment = attachments[0]
  if (attachment) {
    return (
      <div className={styles.wrap}>
        <Typography
          ariaLabel={getAriaLabel('Audi title')}
          variant={'body-md-medium'}
          className={styles.name}
        >
          {attachment.name}
        </Typography>
        <AudioPlayer url={attachment.source} fullWidth />
      </div>
    )
  }
  return null
}
