import { IIconsVector } from 'shared/ui'
import { IStatusProps } from 'shared/ui/Status'

export enum CountryEnum {
  US = 'US',
  CA = 'CA',
  UK = 'UK',
  AU = 'AU',
  World = 'World',
}

export type ICountry = {
  name: string
  value: CountryEnum | string
  icon: IIconsVector
  status: IStatusProps
}
