import { observer } from 'mobx-react-lite'
import { TextField, makeTextFieldAutoFocus, makeTextFieldIcon } from 'shared/ui/TextField'
import { uiStore } from 'shared/store/uiStore'
import { Button } from 'shared/ui'
import { NewReplyBtn } from 'widgets/SavedReplies/ui/SavedRepliesContent/LeftContent/NewReplyBtn/NewReplyBtn'
import { savedRepliesStore } from 'widgets/SavedReplies'
import styles from './styles.module.scss'

export const SavedRepliesSearch = observer(() => {
  const { search, setSearch, prevItem, nextItem, handleAddSelectedSavedReplies, closeModal } =
    savedRepliesStore

  const handleHotKeys = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.code) {
      case 'Enter':
        return handleAddSelectedSavedReplies()
      case 'ArrowUp':
        return prevItem()
      case 'ArrowDown':
        return nextItem()
      default:
        return
    }
  }

  const handleManage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    closeModal()
    uiStore.changeRoute({
      path: '/settings/personal/settings/canned-messages',
      type: 'vue',
    })
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.searchWrapper}>
        <div className={styles.searchInputWrap}>
          <TextField
            className={styles.textField}
            variant='integrated'
            InputProps={{ placeholder: 'Search...', onKeyDown: handleHotKeys }}
            value={search}
            leftActions={[
              makeTextFieldIcon({
                icon: 'search',
                color: 'var(--content-primary-tertiary)',
                fontSize: 16,
                margin: '0 6px 0 0',
              }),
            ]}
            size='medium'
            onChange={setSearch}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
          />
        </div>
        <div className={styles.actions}>
          <NewReplyBtn />
          <Button
            text={'Manage'}
            icon='settings'
            contained={'secondary'}
            onClick={handleManage}
            size={'medium'}
          />
        </div>
      </div>
    </div>
  )
})
