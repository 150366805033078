import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Icon, SpinnerLoader, Typography } from 'shared/ui'
import { clampNumber, getAriaLabel } from 'shared/lib'
import { organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { UpgradeSubscriptionBtn } from 'widgets/Sidebar/ui/MainDropdown/UpgradeSubscriptionBtn'
import styles from './styles.module.scss'

const RegularCredits = observer(() => {
  const { formattedAccountCredits } = organizationStore

  return (
    <div className={styles.block}>
      <div className={styles.blockWrapper}>
        <div className={styles.label}>
          <div className={styles.icon}>
            <Icon icon='starOutline' />
          </div>
          <Typography
            variant={'body-md-regular'}
            ariaLabel={'Credits'}
            color='var(--content-primary-primary)'
          >
            Credits
          </Typography>
        </div>
        <Typography variant={'body-md-regular'} ariaLabel={'formattedAccountCredits'}>
          {formattedAccountCredits}
        </Typography>
      </div>
      <UpgradeSubscriptionBtn />
    </div>
  )
})

const TrialCredits = observer(() => {
  const isTrialEnded = organizationStore.isTrialEnded
  const organizationTrialCredits = clampNumber(organizationStore.trialCredits, 0)
  const subscriptionTrialCredits = clampNumber(subscriptionStore.trialCredits, 0)
  const progress = (organizationTrialCredits * 100) / subscriptionTrialCredits
  const normalProgress = isNaN(progress) ? 0 : Math.min(progress, 100)

  return (
    <div className={styles.block} aria-label={getAriaLabel('MainDropdown', 'Block')}>
      <div
        className={styles.blockWrapper}
        aria-label={getAriaLabel('MainDropdown', 'blockWrapper')}
      >
        <div className={styles.label} aria-label={getAriaLabel('MainDropdown', 'label')}>
          <Typography variant={'body-md-regular'} ariaLabel={'Messages'}>
            Messages
          </Typography>
        </div>
        <Typography variant={'body-md-regular'} ariaLabel={'Credits'}>
          {organizationStore.formattedTrialCredits}/{subscriptionStore.formattedTrialCredits}
        </Typography>
      </div>
      <div
        className={styles.blockWrapper}
        aria-label={getAriaLabel('MainDropdown', 'blockWrapper')}
      >
        <div
          className={classNames(styles.progress, {
            [styles.progressDisabled]: isTrialEnded,
          })}
          aria-label={getAriaLabel('MainDropdown', 'progress')}
        >
          <div
            className={styles.progressBar}
            style={{ width: `${normalProgress}%` }}
            aria-label={getAriaLabel('MainDropdown', 'progressBar')}
          />
        </div>
      </div>
      <div
        className={styles.blockWrapper}
        aria-label={getAriaLabel('MainDropdown', 'blockWrapper')}
      >
        {isTrialEnded ? (
          <Typography
            variant={'body-md-regular'}
            className={styles.warningMessage}
            ariaLabel={'Your trial'}
          >
            Your trial has ended
          </Typography>
        ) : (
          <Typography variant={'body-md-regular'} ariaLabel={'remaining'}>
            {organizationStore.strictTrialEndsAtString} remaining
          </Typography>
        )}
      </div>
      <UpgradeSubscriptionBtn />
    </div>
  )
})

const Credits = observer(() => {
  const isLoading = organizationStore.loading || subscriptionStore.loading
  const isTrialing = subscriptionStore.isTrial

  if (isLoading) return <SpinnerLoader size={20} />

  return isTrialing ? <TrialCredits /> : <RegularCredits />
})

export default Credits
