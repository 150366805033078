// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dwhso8MEXbl6phmD43RT{height:100%;overflow:auto}.bw176hLFbGHBm2xt5vDy{display:flex;justify-content:flex-end;margin-top:4px;margin-bottom:24px;gap:8px}.yukkAAdFayOAFCVnMuo6{margin-top:8px;display:flex;align-items:center}.WTNnrKL27ekXnuJZuBvO{color:var(--action-contained-destructive-primary-normal);line-height:14px;font-weight:400;font-size:11px;margin-left:4px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/newOrganization/pages/NewOrganizationTab/styles.module.scss"],"names":[],"mappings":"AACA,sBACI,WAAA,CACA,aAAA,CAEJ,sBACI,YAAA,CACA,wBAAA,CACA,cAAA,CACA,kBAAA,CACA,OAAA,CAEJ,sBACI,cAAA,CACA,YAAA,CACA,kBAAA,CAEJ,sBACI,wDAAA,CACA,gBAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA","sourcesContent":["\n.container {\n    height: 100%;\n    overflow: auto;\n}\n.wrapButtons {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 4px;\n    margin-bottom: 24px;\n    gap: 8px;\n}\n.error {\n    margin-top: 8px;\n    display: flex;\n    align-items: center;\n}\n.errorText {\n    color: var(--action-contained-destructive-primary-normal);\n    line-height: 14px;\n    font-weight: 400;\n    font-size: 11px;\n    margin-left: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "dwhso8MEXbl6phmD43RT",
	"wrapButtons": "bw176hLFbGHBm2xt5vDy",
	"error": "yukkAAdFayOAFCVnMuo6",
	"errorText": "WTNnrKL27ekXnuJZuBvO"
};
export default ___CSS_LOADER_EXPORT___;
