import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownContent, DropdownGroup, DropdownTextInput } from 'shared/ui'
import {
  IMainDropdownContentItem,
  useSearchDropdownContext,
  SearchTypesEnum,
} from 'widgets/SearchDropdown'
import {
  ContactsDropdownContent,
  DropdownAllContentSearchField,
  SegmentsDropdownContent,
  TagsDropdownContent,
} from 'widgets/SearchDropdown/ui'

export type IEnrollmentSearchContentProps = {
  onCloseMenu: () => void
  dropdownId: string
}

export const EnrollmentSearchContent: FC<IEnrollmentSearchContentProps> = observer(
  ({ onCloseMenu }) => {
    const store = useSearchDropdownContext()
    const handleState = () => {
      if (store.currentType === SearchTypesEnum.contacts) return 'contacts'
      if (store.currentType === SearchTypesEnum.tags) return 'tags'
      if (store.currentType === SearchTypesEnum.segment) return 'segment'
      if (store.search.length >= 1) return 'all'

      return 'default'
    }
    const state = handleState()

    useEffect(() => {
      return () => {
        store.resetAll()
        store.setNewType(null)
      }
    }, [])

    return (
      <>
        {state === 'default' && (
          <DropdownContent noPadding>
            <DropdownTextInput
              textFieldProps={{
                value: store.search,
                onChange: store.handleSearch,
                InputProps: {
                  placeholder: 'Search tag, segment or contact',
                },
              }}
            />
            <DropdownGroup
              title={'Search by'}
              items={store.items}
              isScroll={false}
              onChange={(dropdown) => {
                store.selectType((dropdown as IMainDropdownContentItem).type)
              }}
            />
          </DropdownContent>
        )}

        {state === 'all' && (
          <DropdownAllContentSearchField onCloseMenu={onCloseMenu} maxHeight={385} />
        )}

        {state === 'tags' && (
          <TagsDropdownContent
            store={store.searchDropdownTagsStore}
            onChange={() => {
              onCloseMenu()
            }}
            width={260}
          />
        )}

        {state === 'contacts' && (
          <ContactsDropdownContent
            store={store.searchDropdownContactsStore}
            onChange={() => {
              onCloseMenu()
            }}
            width={480}
          />
        )}

        {state === 'segment' && (
          <SegmentsDropdownContent
            store={store.searchDropdownSegmentStore}
            onChange={() => {
              onCloseMenu()
            }}
            width={260}
          />
        )}
      </>
    )
  }
)
