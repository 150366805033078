import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import { IResponseIntegrationHubspotCompany } from 'entities/Integrations'

import styles from '../styles.module.scss'

type IItemCompaniesRowProps = {
  company: IResponseIntegrationHubspotCompany
}

const ItemCompaniesRow = ({ company }: IItemCompaniesRowProps) => {
  const firstName = company?.hubspot_owner?.value?.firstName || null
  const lastName = company?.hubspot_owner?.value?.lastName || null
  const email = company?.hubspot_owner?.value?.email || null

  const rows = [
    {
      id: 1,
      label: 'HubSpot owner',
      value: firstName || lastName ? `${firstName} ${lastName}` : email || null,
    },
  ]

  return (
    <div className={styles.companyWrapper}>
      {rows.map((item) => (
        <div className={styles.itemRow} key={item.id}>
          <div className={styles.label}>{item.label}</div>
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value || '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { ItemCompaniesRow }
