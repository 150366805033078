export const getFileType = (file: File) => {
  if (file.type.includes('audio')) {
    return 'audio'
  }
  if (file.type.includes('video')) {
    return 'video'
  }
  if (file.type.includes('vcard')) {
    return 'vcard'
  }
  if (file.type.includes('image')) {
    return 'image'
  }
  if (file.type.includes('application') || file.type.includes('text')) {
    return 'docs'
  }

  return 'default'
}
