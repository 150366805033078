// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ALxr3Ac9poM84WKwEVcv{background:var(--background-neutral-default);border-radius:6px;padding:12px;display:flex;flex-direction:column;gap:12px}.ALxr3Ac9poM84WKwEVcv .SWwddn4nC2a_OnqU4jnA{color:var(--content-primary-secondary)}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastActions/ui/BroadcastDetailModal/ui/BodyContent/TypeContent/RinglessContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,iBAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,4CACE,sCAAA","sourcesContent":[".wrap{\n  background: var(--background-neutral-default);\n  border-radius: 6px;\n  padding: 12px;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  .name{\n    color: var(--content-primary-secondary);\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ALxr3Ac9poM84WKwEVcv",
	"name": "SWwddn4nC2a_OnqU4jnA"
};
export default ___CSS_LOADER_EXPORT___;
