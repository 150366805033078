import { observer } from 'mobx-react-lite'
import { TextFieldPlaceholder, makeTextFieldError } from 'shared/ui/TextField'
import { EnrollmentPreview } from 'widgets/ContactEnrollment/ui/EnrollmentList'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotTooltip } from 'pages/chatbot/ui'

import styles from './styles.module.scss'

export const StepEnrollment = observer(() => {
  const { enrollment, errors } = useChatbotContext()
  const enrollmentError = errors.getError('filters')

  const handleChange = () => {
    enrollment.editEnrollment()
    errors.removeError('filters')
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>
        Enroll Your Contacts
        <ChatbotTooltip tooltip='Choose the conditions that trigger a conversation with your Textbot' />
      </span>
      <TextFieldPlaceholder
        className={styles.placeholder}
        variant='integrated'
        size='small'
        onClick={handleChange}
        placeholder={
          <div className={styles.preview}>
            {!!enrollment.enrollmentFilters.allSize ? (
              <EnrollmentPreview filters={enrollment.enrollmentFilters} />
            ) : (
              'Enroll contacts'
            )}
          </div>
        }
        rightActions={[
          makeTextFieldError({ placement: 'bottom', anchor: 'container', offset: [0, 0] }),
        ]}
        error={enrollmentError}
      />
    </div>
  )
})
