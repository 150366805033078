import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Checkbox, Typography } from 'shared/ui'
import { makeTextFieldError, TextFieldPlaceholder } from 'shared/ui/TextField'
import { organizationStore } from 'entities/Organization'
import { BroadcastBadNumberStore } from '../store/broadcastBadNumberStore'
import { BroadcastBadNumberTooltip } from './BroadcastBadNumberTooltip/BroadcastBadNumberTooltip'
import styles from './styles.module.scss'

type IBroadcastBadNumbersProps = {
  store: BroadcastBadNumberStore
}

export const BroadcastBadNumbers: FC<IBroadcastBadNumbersProps> = observer(({ store }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.trigger}>
        <TextFieldPlaceholder
          placeholder={
            <div className={styles.wrapPlaceholder}>
              <Checkbox
                disabled={organizationStore.isPhoneCheckerEnabled}
                checked={store.checked}
                onChangeValue={(value) => {
                  store.setChecked(value, 'manual')
                }}
                size={16}
              />
              <Typography
                variant={'text-input-field'}
                color={'var(--content-primary-primary)'}
                ariaLabel={'Don’t send to bad numbers'}
              >
                Validate numbers before sending
              </Typography>
              <BroadcastBadNumberTooltip />
            </div>
          }
          variant={'outline'}
          size={'small'}
          className={styles.input}
          rightActions={[
            makeTextFieldError({
              placement: 'right',
            }),
          ]}
        />
      </div>
    </div>
  )
})
