import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { prepareSearchRegex } from 'shared/lib/prepareSearch'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { logger, SingletonRequest } from 'shared/lib'
import { RequestCancelAxios } from 'shared/api/requestCancelAxios'
import { AttachmentsApi, ILoadRecentParams } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { featureFlagsStore } from 'entities/FeatureFlags'

const ATTACHMENTS_LIMIT = 100

class RecentStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _recentAttachmentsMap: Map<string | number, Attachment> = new Map()
  private _loadingRecent = false

  private _search = ''
  private _termAttachmentsIds: Array<number | string> = []
  private _checkedRecentAttachmentIds: Set<number | string> = new Set()
  private _dropdownId = ''
  private _removedAttachmentsIds: Set<number | string> = new Set()

  private _visiblePage = 1
  private _perPage = 6
  private _cancelRequest = new RequestCancelAxios()

  get loadingRecent() {
    return this._loadingRecent
  }

  get search() {
    return this._search
  }

  get checkedRecentAttachmentIds() {
    return this._checkedRecentAttachmentIds
  }

  get dropdownId() {
    return this._dropdownId
  }

  get recentAttachments() {
    return Array.from(this._recentAttachmentsMap.values())
  }

  get recentAttachmentsIds() {
    return Array.from(this._recentAttachmentsMap.keys())
  }

  get termAttachments(): Attachment[] {
    const attachments: Attachment[] = []
    this._termAttachmentsIds.forEach((id) => {
      const attachment = this._recentAttachmentsMap.get(id)
      if (attachment) {
        attachments.push(attachment)
      }
    })
    return attachments
  }

  get visibleAttachments(): Attachment[] {
    return this.termAttachments.slice(0, this._visiblePage * this._perPage)
  }

  get isCheckedMode() {
    return Boolean(this._checkedRecentAttachmentIds.size)
  }

  clearRecentAttachments() {
    this._recentAttachmentsMap.clear()
  }

  reset = () => {
    this._visiblePage = 1
    this._checkedRecentAttachmentIds.clear()
    this.searchAttachments('')
    this._cancelRequest.reset()
  }

  addAttachmentRecent = (attachment: Attachment, isFirst?: boolean) => {
    if (isFirst) {
      this._recentAttachmentsMap = new Map([
        [attachment.id, attachment],
        ...this._recentAttachmentsMap,
      ])
    } else {
      this._recentAttachmentsMap.set(attachment.id, attachment)
    }
  }

  onShowMore = () => {
    if (this._visiblePage * this._perPage >= this._recentAttachmentsMap.size) {
      return
    }
    this._visiblePage += 1
  }

  private _loadRecent = async (params?: ILoadRecentParams) => {
    try {
      runInAction(() => {
        this._loadingRecent = true
      })

      this._cancelRequest.init()

      const { data } = await AttachmentsApi.recent(
        { limit: ATTACHMENTS_LIMIT, ...params },
        {
          cancelToken: this._cancelRequest.token,
        }
      )

      this.clearRecentAttachments()

      runInAction(() => {
        data.forEach((attachment) => {
          this.addAttachmentRecent(new Attachment({ responseUpload: attachment }))
        })
        this.searchAttachments(this._search)
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loadingRecent = false
      })
    }
  }

  loadRecent = new SingletonRequest(this._loadRecent).request

  checkParamsAndLoadRecent = (isCurrentAirCall?: boolean) => {
    isCurrentAirCall && !featureFlagsStore.aircall_new_api
      ? this.loadRecent({ params: { is_aircall: true } })
      : this.loadRecent()
  }

  firstLoadRecent = async () => {
    await this.checkParamsAndLoadRecent()
  }

  searchAttachments = (search: string) => {
    this._search = search
    this._visiblePage = 1
    const term = search.trim()

    if (!term) return this.clearSearchAttachments()

    const searchRegex = prepareSearchRegex(term)

    this._termAttachmentsIds = this.recentAttachments
      .filter((attachment) =>
        attachment.searchFragments.some((fragment) => searchRegex.test(fragment))
      )
      .map((attachment) => attachment.id)
  }

  clearSearchAttachments() {
    this._search = ''
    this._termAttachmentsIds = this.recentAttachmentsIds
  }

  get isCheckedAll() {
    return this._checkedRecentAttachmentIds.size === this._recentAttachmentsMap.size
  }

  setCheckedRecentAttachmentIds = (id: string | number) => {
    if (!this._checkedRecentAttachmentIds.has(id)) {
      this._checkedRecentAttachmentIds.add(id)
    } else {
      this._checkedRecentAttachmentIds.delete(id)
    }
  }
  onCheckAll = () => {
    if (this.isCheckedAll) {
      this._checkedRecentAttachmentIds.clear()
    } else {
      this._checkedRecentAttachmentIds = new Set([...this._recentAttachmentsMap.keys()])
    }
  }
  setDropdownId = (id: string) => {
    this._dropdownId = id
  }

  onDelete = (ids: Array<string | number>) => {
    const modalId = nanoid()
    modalStore.addModal({
      id: modalId,
      type: ModalTypeList.ALERT,
      title: ids.length > 1 ? `Delete ${ids.length} files` : 'Delete file',
      desc: 'This action cannot be undone',
      isStopPropagation: true,
      primaryAction: {
        text: 'Delete',
        onAction: async () => {
          try {
            await AttachmentsApi.delete(ids)
            toastStore.add({
              type: 'success',
              title: `File${ids.length > 1 ? 's' : ''}  deleted`,
            })
            runInAction(() => {
              this._removedAttachmentsIds = new Set([...this._removedAttachmentsIds, ...ids])
              ids.forEach((id) => {
                this._checkedRecentAttachmentIds.delete(id)
                this._recentAttachmentsMap.delete(id)
              })
              if (!this._recentAttachmentsMap.size) {
                this.checkParamsAndLoadRecent()
              }
            })

            modalStore.closeModal(modalId)
          } catch (e) {
            logger.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(modalId)
        },
      },
    })
  }

  onDeleteChecked = () => this.onDelete(Array.from(this._checkedRecentAttachmentIds))
}

export const recentStore = new RecentStore()
