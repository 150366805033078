import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { AudioVolumeLevel, Dropdown, Icon } from 'shared/ui'
import { ZIndex } from 'shared/constants/zIndex'
import { type IDeviceSettingsContentProps } from 'entities/DeviceSettings'
import styles from './styles.module.scss'

export const AudioInputContent: FC<IDeviceSettingsContentProps> = observer(
  ({ deviceSettingsStore, parentsDropdownId, dropdownProps = {} }) => {
    const { audioInputsItems, device, setSelectedAudioInput, stream } = deviceSettingsStore

    return (
      <div className={classnames(styles.section, styles['section--mic'])}>
        <div className={styles.box}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Icon icon={'microphone'} fontSize={18} />
            </div>
            <div className={styles.label}>Microphone</div>
          </div>
        </div>

        <div className={styles.dropdownWrap}>
          <div className={styles.dropdownBox}>
            <Dropdown
              zIndex={ZIndex.OVERLAY_TOP_1}
              placement={'bottom-start'}
              items={audioInputsItems}
              triggerComponent={() => (
                <div className={styles.dropdownBtn}>{device.audioInput?.label}</div>
              )}
              width={232}
              margin={-20}
              onChange={(item) => {
                setSelectedAudioInput(String(item.id))
              }}
              parentsDropdownId={parentsDropdownId}
              {...dropdownProps}
              ariaLabel={'AudioInputContent'}
            />
          </div>

          {stream && (
            <div className={classnames(styles.dropdownBox, styles.mic)}>
              <AudioVolumeLevel key={stream.id} stream={stream} />
            </div>
          )}
        </div>
      </div>
    )
  }
)
