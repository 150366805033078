import { createContext, useContext } from 'react'
import { ScheduleStore } from 'shared/ui/Schedule'

export const ScheduleContext = createContext<ScheduleStore | null>(null)

export function useScheduleContext() {
  const context = useContext(ScheduleContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with BroadcastScheduleContext')
  }
  return context
}
