import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown } from 'shared/ui'
import { DropdownContent, type IDropdownItem } from 'shared/ui/Dropdown'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import type { Contact } from 'entities/Contacts/model/Contact'
import { contactMeetLink } from 'widgets/ContactMeetDropdown/lib/contactMeetLink'
import { MeetTypes } from 'widgets/ContactMeetDropdown/types/types'
import styles from './styles.module.scss'

type IContactMeetDropdownProps = {
  contact: Contact
}

export const ContactMeetDropdown: FC<IContactMeetDropdownProps> = observer(({ contact }) => {
  const integration = integrationsStore.getIntegration(contact.integration_key)
  const hasHubspotIntegration =
    integration?.isConnected && contact?.integration_key === IntegrationKey.hubspot
  const hasInfusionsoftIntegration =
    integration?.isConnected && contact?.integration_key === IntegrationKey.infusionsoft

  const handleRedirectToMeet = (type: MeetTypes) => {
    const link = contactMeetLink(type, contact.id)

    if (!link) return
    window.open(link, '_blank')
  }

  const disabled = contact.id === 0 || contact.id === -1 || !contact
  const dropdownItems: IDropdownItem[] = [
    {
      id: 3,
      iconL: 'google',
      label: 'Google (online)',
      onClick: () => handleRedirectToMeet(MeetTypes.GOOGLE),
    },
    {
      id: 4,
      iconL: 'outlook',
      label: 'Outlook (online)',
      onClick: () => handleRedirectToMeet(MeetTypes.OUTLOOK),
    },
  ]

  if (hasHubspotIntegration) {
    dropdownItems.unshift({
      id: 1,
      iconL: 'hubspot',
      label: 'HubSpot',
      onClick: () => handleRedirectToMeet(MeetTypes.HUBSPOT),
    })
  }

  if (hasInfusionsoftIntegration) {
    dropdownItems.unshift({
      id: 2,
      iconL: 'infusionsoft',
      label: 'Keap',
      onClick: () => handleRedirectToMeet(MeetTypes.INFUSIONSOFT),
    })
  }

  return (
    <Dropdown
      ariaLabel={'ContactMeetDropdown'}
      width={166}
      sizeTriggerButton='medium'
      buttonProps={{
        text: 'Meet',
        icon: 'meeting',
        size: 'medium',
        typeBtn: 'contained',
        contained: 'secondary',
        disabled,
      }}
      placement='bottom-end'
      customComponent={() => (
        <DropdownContent>
          {dropdownItems.map((item) => (
            <Button
              key={item.id}
              className={styles.button}
              iconProps={{
                insideBox: true,
                padding: 2,
              }}
              fontWeight={400}
              onClick={item.onClick}
              typeBtn='menuItem'
              icon={item.iconL}
              text={item.label}
              fullWidth
            />
          ))}
        </DropdownContent>
      )}
      typeButton='button'
      disabled={disabled}
    />
  )
})
