// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qv5_KAJU0ETrK0gzK4cV{display:flex;padding:1px 4px 2px 4px;align-items:center;border-radius:4px;border:1px solid var(--black-10);background:var(--black-20);color:var(--gray-80)}.am6VzdqBKTFaaI41khE4{position:relative}.zuLmzkiPxlDKQeLAI7g7{position:absolute;left:0;top:-12px;width:fit-content;z-index:1}", "",{"version":3,"sources":["webpack://./src/widgets/CallHistoryTable/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,uBAAA,CACA,kBAAA,CACA,iBAAA,CACA,gCAAA,CACA,0BAAA,CACA,oBAAA,CAEF,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,MAAA,CACA,SAAA,CACA,iBAAA,CACA,SAAA","sourcesContent":[".label{\n  display: flex;\n  padding: 1px 4px 2px 4px;\n  align-items: center;\n  border-radius: 4px;\n  border: 1px solid var(--black-10);\n  background: var(--black-20);\n  color: var(--gray-80);\n}\n.audioWrap {\n  position: relative;\n\n}\n.audio {\n  position: absolute;\n  left: 0;\n  top: -12px;\n  width: fit-content;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "Qv5_KAJU0ETrK0gzK4cV",
	"audioWrap": "am6VzdqBKTFaaI41khE4",
	"audio": "zuLmzkiPxlDKQeLAI7g7"
};
export default ___CSS_LOADER_EXPORT___;
