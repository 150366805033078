import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import {
  IParamsCreateShort,
  IResponseCustomDomain,
  IResponseShortLink,
  IResponseUrlShortenerDomains,
} from 'entities/ShortLink/api/types'

class Api {
  createShort(params: IParamsCreateShort): Promise<AxiosResponse<IResponseShortLink[]>> {
    return baseApi.post('url/short', params)
  }

  getCustomDomain(): Promise<AxiosResponse<IResponseCustomDomain>> {
    return baseApi.get('url/custom-domain/get')
  }

  getUrlShortenerDomains(): Promise<AxiosResponse<IResponseUrlShortenerDomains>> {
    return baseApi.get('url/shortener/domains')
  }
}

export const ShortLinkApi = new Api()
