import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { ZIndex } from 'shared/constants/zIndex'
import { IQuickLinkPayload } from 'entities/QuickLink'
import type { MergeField } from 'widgets/MergeField/types/MergeField'
import { MergeFieldsIconAction } from 'widgets/MergeField/ui/actions/MergeFieldsIconAction/MergeFieldsIconAction'
import {
  EnumVariantMessageField,
  mergeFieldReplacer,
  MessageFieldStore,
} from 'widgets/MessageField'

export type IQuickLinkUrlStore = QuickLinkUrlStore

export class QuickLinkUrlStore {
  private _disposeUrlReaction: IReactionDisposer | null = null
  private _disposeFieldCount: IReactionDisposer | null = null
  private _disposeSetHighlight: IReactionDisposer | null = null
  private _urlField = new MessageFieldStore({
    placeholder: 'Enter URL',
    styles: { minHeight: 60, maxHeight: 80 },
    noCounter: true,
    disableLinks: true,
    disableMergeFieldsFallback: true,
    variant: EnumVariantMessageField.Default,
    resetOnUnmount: false,
    makeActions: () => [
      {
        iconButtonComponent: (
          <MergeFieldsIconAction
            onAddMergeField={(value) => this._handleMergeField(value)}
            isAllIntegration
            zIndex={ZIndex.OVERLAY_MIDDLE_100}
          />
        ),
      },
    ],
  })

  get urlField() {
    return this._urlField
  }

  get isEmpty() {
    return this._quickLink.isUrlEmpty
  }

  constructor(private _quickLink: IQuickLinkPayload) {
    makeAutoObservable(this)

    this._disposeUrlReaction = reaction(
      () => this._urlField.text,
      (url) => {
        this._quickLink.setUrl(url)
        this._quickLink.resetErrorUrlMessage()
      }
    )

    this.reactionFieldCount()
    this.reactionSetHighlight()
  }

  reactionFieldCount = () => {
    this._disposeFieldCount?.()
    this._disposeFieldCount = reaction(
      () => this._urlField.mergeFieldsCount,
      (count) => this._quickLink.setMergeFieldsCount(count)
    )
  }

  reactionSetHighlight = () => {
    this._disposeSetHighlight?.()
    this._disposeSetHighlight = reaction(
      () => this._quickLink.errorUrlMessage,
      (errorUrlMessage) =>
        errorUrlMessage ? this._urlField.setHighlight('error') : this._urlField.clearHighlight()
    )
  }

  private _handleMergeField = (field: MergeField) => {
    if (!this._urlField.addContent) return

    const fieldContent = mergeFieldReplacer(field.template)

    if (this._urlField.mergeFieldsCount > 0) {
      const element = this._urlField.messageFieldElement
      const mergeField = element?.querySelector('.mergeField-wrap')
      if (!!element && !!mergeField) {
        mergeField.outerHTML = fieldContent
        this._urlField.setMessageText(element.innerHTML, element.innerText)
      }
    } else {
      this._urlField.addContent(fieldContent)
    }
  }

  sync = () => {
    this._urlField.setMessageData({
      message: this._quickLink.url,
      attachments: [],
      isReset: false,
      isReplace: true,
      isFocus: false,
    })
  }

  dispose = () => {
    this._disposeUrlReaction?.()
    this._disposeFieldCount?.()
    this._disposeSetHighlight?.()
  }
}
