import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import { Sidebar } from 'widgets/Sidebar'
import RootLayout from 'app/layouts/rootLayout'
import AuthLayout from 'app/appvue/sidebar/layouts/authLayout'

const routersSidebar = createRoutesFromElements(
  <Route element={<RootLayout />}>
    <Route element={<AuthLayout />}>
      <Route path='/*' element={<Sidebar />} />
    </Route>
  </Route>
)

export const appRouterSidebar = createBrowserRouter(routersSidebar)
