import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { AutoRechargeWidgetStore } from 'widgets/AutoRechargeModal'

type IEnableAutoRechargeBtnProps = {
  store: AutoRechargeWidgetStore
}

export const EnableAutoRechargeBtn: FC<IEnableAutoRechargeBtnProps> = observer(({ store }) => {
  const onOpenModal = () => {
    store.openModal()
  }

  return (
    <Button
      text={'Enable auto-recharge'}
      typeBtn={'action'}
      size={'small'}
      contained={'secondary'}
      onClick={onOpenModal}
    />
  )
})
