import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import { ReactNode } from 'react'
import { IDropdownItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { numberFormat } from 'shared/lib'
import { Conversion, ConversionsApi, IParamsGetConversions } from 'entities/Conversion'
import { NewConversionStore } from 'widgets/NewConversion'

export class TrackConversionStore {
  clearError
  constructor({ clearError }: { clearError: () => void }) {
    this.clearError = clearError
    makeAutoObservable(this)
    reaction(
      () => this.params,
      () => this.loadData(),
      {
        delay: 500,
      }
    )
  }

  page = 1
  last_page = 1
  length = 20
  search = ''
  loading = false
  loadingMore = false

  is_track_conversion = false
  conversion_id: null | number = null
  conversionItem: IDropdownItem | null = null

  cancelTokenSource: CancelTokenSource | null = null

  error: ReactNode = null

  private _dropDownItems: IDropdownItem[] = []

  get dropDownItems(): IDropdownItem[] {
    return this._dropDownItems
  }

  private initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  setError = (error: ReactNode) => {
    this.error = error
  }

  loadData = async () => {
    try {
      this.initCancelTokenSource()
      runInAction(() => {
        if (this.page === 1) {
          this.loading = true
          this._dropDownItems = []
        }
      })
      const { data } = await ConversionsApi.getConversions(this.params, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })

      if (data) {
        runInAction(() => {
          if (this.page === 1) {
            this._dropDownItems = data.data.map(this.conversionToDropdownItem)
          } else {
            this._dropDownItems = [
              ...this.dropDownItems,
              ...data.data.map(this.conversionToDropdownItem),
            ]
          }

          this.last_page = data.meta.last_page
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
        this.loadingMore = false
      })
    }
  }

  get params(): IParamsGetConversions {
    return {
      timezone: uiStore.timezone,
      page: this.page,
      length: this.length,
      search: this.search || null,
    }
  }

  private conversionToDropdownItem = (conversion: Conversion): IDropdownItem => {
    return {
      id: conversion.id,
      height: 47,
      data: conversion,
      label: conversion.name,
      description: conversion.conversion_url,
      noRenderEmptyActiveValue: true,
      labelRight: conversion.default_value
        ? numberFormat({
            value: conversion.default_value,
            currency: 'USD',
          })
        : undefined,
      selectedLabelRight: conversion.default_value
        ? numberFormat({
            value: conversion.default_value,
            currency: 'USD',
          })
        : undefined,
    }
  }

  init = async ({
    is_track_conversion,
    conversion_id,
  }: {
    is_track_conversion: boolean
    conversion_id: number | null
  }) => {
    this.is_track_conversion = is_track_conversion
    this.conversion_id = conversion_id
    if (this.conversion_id) {
      const { data } = await ConversionsApi.getConversionById(this.conversion_id)
      if (data) {
        this.conversionItem = this.conversionToDropdownItem(new Conversion(data))
      }
    }
  }

  set_is_track_conversion = (value: boolean) => {
    this.is_track_conversion = value
    if (!value) {
      this.conversion_id = null
      this.conversionItem = null
    }
  }

  setConversionItem = (id: number, item: IDropdownItem) => {
    this.conversion_id = id
    this.conversionItem = item
  }

  onChangeOpen = (value: boolean) => {
    if (!value) {
      this.setSearch('')
    }

    this.clearError()
    if (value && !this._dropDownItems.length) {
      this.loadData()
    }
  }

  setSearch = (value: string) => {
    this.page = 1
    this.last_page = 1
    this.search = value
  }

  onLoadMore = () => {
    if (this.last_page <= this.page) return
    if (this.loadingMore) return
    this.loadingMore = true
    this.page += 1
  }

  onSuccessCreate = (conversion: Conversion) => {
    const newItem = this.conversionToDropdownItem(conversion)
    this._dropDownItems = [newItem, ...this.dropDownItems]
    this.setConversionItem(conversion.id, newItem)
  }

  onNewConversion = () => {
    new NewConversionStore({
      onSuccessCreate: this.onSuccessCreate,
    })
  }
}
