import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { FullPageWrapper, SpinnerLoader } from 'shared/ui'
import { ContactsDetails } from 'widgets/ContactsDetails'
import {
  ChatbotAnalyticProvider,
  ChatbotDetailsProvider,
  useChatbotAnalyticContext,
} from 'pages/chatbot/context'

import { ChatbotAnalyticHeader } from './ChatbotAnalyticHeader'
import { ChatbotAnalyticFilter } from './ChatbotAnalyticFilter'
import { ChatbotAnalyticTable } from './ChatbotAnalyticTable'

const ChatbotAnalytic = observer(() => {
  const { list, details, loading } = useChatbotAnalyticContext()
  const { contactsDetailsStore } = list

  useEffect(() => {
    details.init()
    list.init()
    list.loadData()

    return () => {
      list.reset()
    }
  }, [])

  return (
    <FullPageWrapper>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <>
          <ChatbotAnalyticHeader details={details} />
          <ChatbotAnalyticFilter list={list} details={details} />
          <ChatbotAnalyticTable list={list} />
        </>
      )}
      {contactsDetailsStore && <ContactsDetails store={contactsDetailsStore} />}
    </FullPageWrapper>
  )
})

export const ChatbotAnalyticPage = () => (
  <ChatbotAnalyticProvider>
    <ChatbotDetailsProvider>
      <ChatbotAnalytic />
    </ChatbotDetailsProvider>
  </ChatbotAnalyticProvider>
)
