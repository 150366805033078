// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HRFj1zLrYgZUe70dMtUc{display:grid;grid-template-columns:1fr 1fr;margin-top:24px;gap:12px}.B9lFvd_YgwUSbmNHUnfz{display:grid;margin-top:20px;gap:20px}.GdHC0bqILfZSnOnHMRkm{height:40px}", "",{"version":3,"sources":["webpack://./src/widgets/AutoRechargeModal/ui/AutoRechargeModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,eAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,eAAA,CACA,QAAA,CAEF,sBACE,WAAA","sourcesContent":[".wrapDropdowns{\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  margin-top: 24px;\n  gap: 12px;\n}\n.wrapCards{\n  display: grid;\n  margin-top: 20px;\n  gap: 20px;\n}\n.select{\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapDropdowns": "HRFj1zLrYgZUe70dMtUc",
	"wrapCards": "B9lFvd_YgwUSbmNHUnfz",
	"select": "GdHC0bqILfZSnOnHMRkm"
};
export default ___CSS_LOADER_EXPORT___;
