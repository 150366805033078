import { makeAutoObservable, toJS } from 'mobx'

import { Attachment } from 'entities/Attachment/model/Attachment'
import { RequestAttachment } from 'entities/Attachment/model/RequestAttachment'
import {
  ChatbotActionType,
  IChatbotActionAddTagSetting,
  IChatbotActionNote,
  IChatbotActionContactNoteSetting,
  IChatbotActionRemoveTagSetting,
  IChatbotActionSendMessage,
  IChatbotActionSendMessageSetting,
  IChatbotActionTag,
  IChatbotActionType,
  IChatbotActionSettings,
} from './type'

export interface IChatbotAction {
  type: IChatbotActionType
}

export class ChatbotActionTag implements IChatbotAction {
  static Create(type: typeof ChatbotActionType.AddTag | typeof ChatbotActionType.RemoveTag) {
    return new ChatbotActionTag({
      type,
      settings: {
        tag_ids: [],
      },
    })
  }

  type!: typeof ChatbotActionType.AddTag | typeof ChatbotActionType.RemoveTag
  tagIds: number[] = []

  constructor(item: IChatbotActionAddTagSetting | IChatbotActionRemoveTagSetting) {
    this.type = item.type

    this.syncOrigin(item.settings)

    makeAutoObservable(this)
  }

  syncIds(ids: number[]) {
    this.tagIds = ids
  }

  syncOrigin(item: IChatbotActionTag) {
    this.tagIds = item.tag_ids
  }

  toJSON(): IChatbotActionAddTagSetting | IChatbotActionRemoveTagSetting {
    return toJS({
      type: this.type,
      settings: {
        tag_ids: this.tagIds,
      },
    })
  }
}

export type IChatbotActionModel = ChatbotActionTag | ChatbotActionSendMessage | ChatbotActionNote

export class ChatbotActionSendMessage implements IChatbotAction {
  static Create() {
    return new ChatbotActionSendMessage({
      type: ChatbotActionType.SendMessage,
      settings: {
        message: '',
        media_url: [],
      },
    })
  }

  type = ChatbotActionType.SendMessage
  message = ''
  attachments: Attachment[] = []

  constructor(item: IChatbotActionSendMessageSetting) {
    this.syncOrigin(item.settings)

    makeAutoObservable(this)
  }

  sync(message: string, attachments: Attachment[]) {
    this.message = message
    this.attachments = attachments
  }

  syncOrigin(item: IChatbotActionSendMessage) {
    this.message = item?.message ?? ''
    this.attachments =
      item?.media_url?.map((item) => new Attachment({ requestAttachment: item })) ?? []
  }

  toJSON(): IChatbotActionSendMessageSetting {
    return toJS({
      type: ChatbotActionType.SendMessage,
      settings: {
        message: this.message,
        media_url: this.attachments.map((attachment) => new RequestAttachment(attachment)),
      },
    })
  }
}

export class ChatbotActionNote implements IChatbotAction {
  static Create() {
    return new ChatbotActionNote({
      type: ChatbotActionType.ContactNote,
      settings: {
        note: '',
      },
    })
  }

  type = ChatbotActionType.ContactNote
  note = ''

  constructor(item: IChatbotActionContactNoteSetting) {
    this.syncOrigin(item.settings)

    makeAutoObservable(this)
  }

  sync(note: string) {
    this.note = note
  }

  syncOrigin(item: IChatbotActionNote) {
    this.note = item?.note ?? ''
  }

  toJSON(): IChatbotActionContactNoteSetting {
    return toJS({
      type: ChatbotActionType.ContactNote,
      settings: {
        note: this.note,
      },
    })
  }
}

export class ChatbotActions {
  static _actionFactory(type: IChatbotActionType): IChatbotActionModel {
    switch (type) {
      case ChatbotActionType.AddTag:
      case ChatbotActionType.RemoveTag:
        return ChatbotActionTag.Create(type)
      case ChatbotActionType.SendMessage:
        return ChatbotActionSendMessage.Create()
      case ChatbotActionType.ContactNote:
        return ChatbotActionNote.Create()
      default:
        throw new TypeError(`Unknown chatbot actin type: "${type}"`)
    }
  }

  actions: IChatbotActionModel[] = []

  constructor(items?: IChatbotActionSettings[]) {
    if (!!items) this.syncOrigin(items)

    makeAutoObservable(this)
  }

  addAction(type: IChatbotActionType) {
    const actions = Array.from(this.actions)
    const action = ChatbotActions._actionFactory(type)

    actions.push(action)
    this.actions = actions

    return action
  }

  removeAction(index: number) {
    const actions = Array.from(this.actions)
    const [action] = actions.splice(index, 1)

    this.actions = actions

    return action
  }

  syncOrigin(items?: IChatbotActionSettings[] | null) {
    this.actions =
      (items
        ?.map((item) => {
          switch (item.type) {
            case ChatbotActionType.AddTag:
              return new ChatbotActionTag(item)
            case ChatbotActionType.RemoveTag:
              return new ChatbotActionTag(item)
            case ChatbotActionType.SendMessage:
              return new ChatbotActionSendMessage(item)
            case ChatbotActionType.ContactNote:
              return new ChatbotActionNote(item)
            default:
              return null
          }
        })
        .filter(Boolean) as ChatbotActions['actions']) ?? []
  }

  toJSON(): IChatbotActionSettings[] {
    return toJS(this.actions.map((action) => action.toJSON()))
  }

  reset() {
    this.actions = []
  }
}
