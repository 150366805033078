import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { layoutStore } from 'shared/layout'
import { BreakpointsEnum } from 'shared/constants/breakpoints'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { remindersStore } from 'widgets/Reminders'
import { MessageField } from 'widgets/MessageField'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails'
import { MessageSignatureEditor } from 'widgets/MessageField/ui/MessageSignature'
import { ConversationMessageFieldAlerts } from '../ConversationMessageFieldAlerts/ConversationMessageFieldAlerts'
import styles from './styles.module.scss'

export const ConversationMessageField = observer(() => {
  const ref = useRef<HTMLDivElement>(null)
  const store = useConversationMessagesContext()
  const { isCollapse } = contactsDetailsGlobalStore
  const { activeBreakpoint } = layoutStore
  const {
    messageFieldStore,
    contact,
    messageSignatureStore,
    isConversationNew,
    showDoubleOptInButton,
  } = store

  const {
    isStatusUnverified,
    isStatusDeclined,
    isStatusBlocked,
    isStatusPending,
    isMessageAll,
    isMessageLocal,
    currentItem,
  } = conversationStore

  const isRequestAccess = !usersStore.getUserPermissions('can_manage_conversations')
  const isOutOfMoney = remindersStore.creditsStore.noMessageSending
  const isOutOfTrialCredits = remindersStore.trialCreditsStore.noMessageSending
  const isLocked = !!currentItem?.is_locked
  const noContact = !contact?.id

  const {
    disabled,
    setMessageFieldElement,
    setDisabledMessageField,
    handleResetMode,
    isDoubleOptInEnabled,
    setSendDisabled,
    setFocusMessageFieldTrigger,
  } = messageFieldStore

  const { isActiveMessageSignature, initMessageSignature } = messageSignatureStore

  useEffect(() => {
    initMessageSignature()

    if (ref.current) {
      setMessageFieldElement(ref.current)
      handleResetMode()
    }
  }, [])

  useEffect(() => {
    const isDisabled =
      isStatusUnverified ||
      isStatusDeclined ||
      isStatusBlocked ||
      isStatusPending ||
      isMessageAll ||
      isMessageLocal ||
      isDoubleOptInEnabled ||
      isRequestAccess ||
      isOutOfMoney ||
      isOutOfTrialCredits ||
      showDoubleOptInButton

    setDisabledMessageField(isDisabled)
  }, [
    isStatusUnverified,
    isStatusDeclined,
    isStatusBlocked,
    isStatusPending,
    isMessageAll,
    isMessageLocal,
    isDoubleOptInEnabled,
    isRequestAccess,
    isOutOfMoney,
    isOutOfTrialCredits,
    showDoubleOptInButton,
    disabled,
  ])

  useEffect(() => {
    const isDisabled = isLocked || noContact

    setSendDisabled(isDisabled)
  }, [isLocked, noContact])

  useEffect(() => {
    const isOnload = new URLSearchParams(window.location.search).has('onload')

    if (contact?.id && !uiStore.isFocusingMode && !isConversationNew && !isOnload)
      setTimeout(setFocusMessageFieldTrigger)
  }, [contact?.id])

  const additionalContent = isActiveMessageSignature && (
    <div className={styles.wrapTextField}>
      <MessageSignatureEditor store={messageSignatureStore} />
    </div>
  )

  useEffect(() => {
    if (ref.current) {
      const upgradeButtons = ref.current.querySelectorAll('[data-type="upgrade-button"]')
      upgradeButtons.forEach((button, index) => {
        if (index > 0) {
          button.setAttribute('style', 'display: none;')
        }
      })
    }
  })

  return (
    <div
      className={classNames(styles.messageFieldWrap, {
        [styles.intercomWidget]:
          isCollapse &&
          (activeBreakpoint === BreakpointsEnum.S || activeBreakpoint === BreakpointsEnum.XS),
      })}
      ref={ref}
    >
      <MessageField
        messageFieldStore={messageFieldStore}
        additionalContent={additionalContent}
        alertsContent={<ConversationMessageFieldAlerts />}
      />
    </div>
  )
})
