// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kGcXUmYwzX29gYjOFS8b{width:260px;border-radius:8px;background:var(--background-primary-inverted-primary);box-shadow:var(--menu-shadow)}", "",{"version":3,"sources":["webpack://./src/widgets/MergeField/ui/actions/MergeFieldsIconAction/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,iBAAA,CACA,qDAAA,CACA,6BAAA","sourcesContent":[".dropdown{\n  width: 260px;\n  border-radius: 8px;\n  background: var(--background-primary-inverted-primary);\n  box-shadow: var(--menu-shadow);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "kGcXUmYwzX29gYjOFS8b"
};
export default ___CSS_LOADER_EXPORT___;
