import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, Tooltip } from 'shared/ui'
import { MediaLibraryStore } from 'widgets/MediaLibrary/store/mediaLibraryStore'
import {
  IMediaLibraryStoreProps,
  IOnAddAttachment,
  MediaLibrarySelectMode,
  MediaLibraryVariant,
} from 'widgets/MediaLibrary/types'

type MediaLibraryRinglessSelectButtonProps = {
  onAddAttachment: IOnAddAttachment
  onClick: () => void
}

export const MediaLibraryRinglessSelectButton: FC<MediaLibraryRinglessSelectButtonProps> = observer(
  ({ onAddAttachment, onClick }) => {
    const mediaLibraryConfiguration: IMediaLibraryStoreProps = {
      mediaLibraryVariant: MediaLibraryVariant.RinglessSelect,
      onAddAttachment: onAddAttachment,
      selectMode: MediaLibrarySelectMode.Single,
    }

    const [store] = useState(() => new MediaLibraryStore(mediaLibraryConfiguration))

    return (
      <Tooltip label={'Add media'} placement={'top'}>
        <IconButton
          icon={'addCircle'}
          variant={'icon'}
          color={'secondary'}
          onClick={() => {
            store.handleOpenMediaLibraryModal()
            onClick()
          }}
          ariaLabel='MediaAction_addMedia'
        />
      </Tooltip>
    )
  }
)
