import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { RefObject, useEffect, useRef, useState } from 'react'
import { Button, Dropdown, DropdownContent, InputRadio, Icon } from 'shared/ui'
import { makeTextFieldIcon, TextFieldPlaceholder } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { RegisterStore } from 'widgets/Register'
import { DropdownCrmContent } from './DropdownCrmContent'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterStore
}

export const Step2Form = observer(({ store }: IFormProps) => {
  const selectRef = useRef<HTMLLabelElement>(null)
  const radioRef = useRef<HTMLDivElement>(null)
  const [ref, setRef] = useState<RefObject<HTMLLabelElement> | null>(null)

  const {
    step2Store: {
      crm,
      contactsCount,
      contactsCounts,
      contactsCountsDropdown,
      isSelectedContactsCountsDropdown,
      setContactsCount,
      isNextDisabled,
    },
    handleNextStep,
  } = store

  useEffect(() => setRef(selectRef), [selectRef])

  return (
    <div className={styles.wrap}>
      <Label
        label={{
          text: 'Which CRM does your company use?',
          className: styles.inputLabel,
        }}
      />
      <Dropdown
        placement={'bottom-start'}
        width={selectRef.current?.clientWidth}
        margin={selectRef.current?.clientHeight ? -selectRef.current?.clientHeight : undefined}
        triggerComponent={() => (
          <TextFieldPlaceholder
            ref={selectRef}
            rightActions={[
              makeTextFieldIcon({
                icon: 'chevronDown',
              }),
            ]}
            placeholder={
              crm ? (
                <Button
                  className={styles.placeholderItem}
                  typeBtn='menuItem'
                  type='button'
                  icon={crm.icon}
                  text={crm.name}
                  fullWidth
                />
              ) : (
                'Select'
              )
            }
            variant={'stroke'}
            size={'small'}
            className={classNames(styles.textField, {
              [styles.placeholder]: !crm,
            })}
          />
        )}
        customComponent={(onClose) => (
          <DropdownContent noPadding>
            <DropdownCrmContent store={store} onClose={onClose} />
          </DropdownContent>
        )}
      />

      <Label
        label={{
          text: 'What is the size of your company?',
          className: styles.inputLabel,
        }}
      />
      <div className={styles.wrapSizes}>
        {contactsCounts.map((count) => (
          <InputRadio
            ref={radioRef}
            className={classNames(styles.inputSize, {
              [styles.active]: count === contactsCount,
            })}
            checked={count === contactsCount}
            onChecked={() => setContactsCount(count)}
            key={count}
            label={count}
          />
        ))}

        <Dropdown
          placement={'bottom-start'}
          width={radioRef.current?.clientWidth}
          margin={
            radioRef.current?.clientHeight ? -(radioRef.current?.clientHeight + 2) : undefined
          }
          triggerComponent={() => (
            <InputRadio
              className={classNames(styles.triggerSize, {
                [styles.active]: isSelectedContactsCountsDropdown,
              })}
              width={radioRef.current?.clientWidth}
              checked={isSelectedContactsCountsDropdown}
              onChecked={() => {}}
              rightText={<Icon icon={'chevronDown'} />}
              label={isSelectedContactsCountsDropdown ? contactsCount : contactsCountsDropdown[0]}
            />
          )}
          customComponent={(onClose) => (
            <DropdownContent>
              <div className={styles.wrap}>
                {contactsCountsDropdown.map((item) => (
                  <Button
                    key={item}
                    className={styles.selectItem}
                    typeBtn='menuItem'
                    type='button'
                    iconRightProps={
                      item === contactsCount
                        ? {
                            color: 'var(--content-brand-primary)',
                            className: styles.selectCountIcon,
                            icon: 'check1',
                          }
                        : undefined
                    }
                    onClick={() => {
                      setContactsCount(item)
                      onClose()
                    }}
                    text={item}
                    fullWidth
                  />
                ))}
              </div>
            </DropdownContent>
          )}
        />
      </div>
      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={handleNextStep}
        disabled={isNextDisabled}
        text={'Next'}
        contained={'primary'}
        fullWidth
      />
    </div>
  )
})

export default Step2Form
