import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { TextFieldPlaceholder, makeTextFieldIcon } from 'shared/ui/TextField'
import { Dropdown, DropdownContent, DropdownItem } from 'shared/ui/Dropdown'
import { Box, Icon, IIconsVector, Typography } from 'shared/ui'
import type { ChatbotSettings } from 'entities/Chatbot/model/ChatbotSettings'
import { ChatbotFollowUpAssertiveness } from 'entities/Chatbot'
import { ChatbotTooltip } from 'pages/chatbot/ui/ChatbotTooltip'

import styles from './styles.module.scss'

export interface IFollowUpWindowProps {
  settings: ChatbotSettings
}

export const AutoFollowUp = observer(({ settings }: IFollowUpWindowProps) => {
  const { followUpWindow, followUpAssertiveness, setFollowUpWindow, setFollowUpAssertiveness } =
    settings

  const followUpAssertivenessList = [
    {
      value: ChatbotFollowUpAssertiveness.Subtle,
      label: 'Subtle',
      icon: 'chart1',
    },
    {
      value: ChatbotFollowUpAssertiveness.Steady,
      label: 'Steady',
      icon: 'chart2',
    },
    {
      value: ChatbotFollowUpAssertiveness.Assertive,
      label: 'Assertive',
      icon: 'chart3',
    },
  ]

  return (
    <>
      <div className={styles.followUpWindowWrapper}>
        <span className={styles.followUpWindowLabel}>Follow-up window</span>
        <Box display={'flex'} alignItems={'center'} paddingRight={'20px'}>
          <Dropdown
            width={59}
            placement='bottom'
            triggerComponent={() => (
              <TextFieldPlaceholder
                className={styles.followUpValue}
                size='small'
                variant='outline'
                placeholder={followUpWindow}
                rightActions={[makeTextFieldIcon({ icon: 'chevronDown', fontSize: 12 })]}
              />
            )}
            customComponent={(close) => (
              <DropdownContent>
                {Object.values([
                  { value: 3, label: '3' },
                  { value: 7, label: '7' },
                  { value: 14, label: '14' },
                ]).map((item, index) => (
                  <DropdownItem
                    key={item.value}
                    item={{
                      id: index,
                      label: item.label,
                      onClick: () => {
                        close()
                        setFollowUpWindow(item.value)
                      },
                    }}
                  />
                ))}
              </DropdownContent>
            )}
          />
          <span className={styles.daysLabel}>Days</span>
        </Box>
      </div>
      <div className={styles.followUpAssertivenessWrapper}>
        <Typography
          variant='text-input-field-r'
          color='tertiary'
          ariaLabel={'followUpAssertivenessLabel'}
          className={styles.followUpAssertivenessLabel}
          marginBottom={12}
        >
          How frequently should the agent follow up?
          <ChatbotTooltip
            tooltip='The agent follows up within the set window: Light sends fewer messages, Balanced follows a steady pace, and Persistent maximizes engagement.'
            fontSize={14}
          />
        </Typography>
        <Box display={'flex'} gap={1}>
          {followUpAssertivenessList.map((item) => {
            const isSelected = item.value === followUpAssertiveness
            return (
              <div
                key={item.value}
                className={classNames(styles.followUpAssertivenessItem, {
                  [styles.selected]: isSelected,
                })}
                onClick={() => setFollowUpAssertiveness(item.value)}
              >
                <Icon
                  icon={item.icon as IIconsVector}
                  className={classNames(styles.assertivenessItemIcon, {
                    [styles.assertivenessItemIconSelected]: isSelected,
                  })}
                />
                <Typography
                  variant='body-md-medium'
                  color={
                    isSelected ? 'var(--content-brand-secondary)' : 'var(--content-primary-primary)'
                  }
                  ariaLabel={item.label}
                >
                  {item.label}
                </Typography>
              </div>
            )
          })}
        </Box>
      </div>
    </>
  )
})
