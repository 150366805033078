import { makeAutoObservable } from 'mobx'
import { ChatbotPath } from 'entities/ExportRouters'
import { AgentBookAppointmentRoutesSteps } from 'pages/chatbot/route/type'

const bookAppointmentStepMap: Record<number, string> = {
  1: AgentBookAppointmentRoutesSteps.settingAgent,
  2: AgentBookAppointmentRoutesSteps.connectCalendar,
  3: AgentBookAppointmentRoutesSteps.actionsAgent,
  4: AgentBookAppointmentRoutesSteps.activateAgent,
}

export class AgentNavigationStore {
  private _currentStep = 1
  private _agentId: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get rootPath() {
    return this._agentId
      ? ChatbotPath.viewBookAppointment(this._agentId)
      : ChatbotPath.newBookAppointment
  }

  get getCurrentStep() {
    return this.getStep(0)
  }

  get getNextStep() {
    const step = this.getStep(1)

    if (!step) return this.getCurrentStep

    return step
  }

  get getLastStep() {
    const step = this.getStep(-1)

    if (!step) return this.getCurrentStep

    return step
  }

  get getCurrentUrl() {
    return `${this.rootPath}/${this.getCurrentStep}`
  }

  get getNextUrl() {
    return `${this.rootPath}/${this.getNextStep}`
  }

  get getLastUrl() {
    return `${this.rootPath}/${this.getLastStep}`
  }

  get formStep() {
    return this._currentStep
  }

  getStep = (direction: -1 | 1 | 0) => {
    const allSteps = Object.values(bookAppointmentStepMap)
    const currentPageIndex = this._currentStep - 1

    return allSteps[currentPageIndex + direction]
  }

  getStepByPath = (agentBookAppointmentRoutes: AgentBookAppointmentRoutesSteps) => {
    const allPaths = Object.entries(bookAppointmentStepMap)
    const stepIndex = allPaths.findIndex((path) => {
      return path[1] === agentBookAppointmentRoutes
    })

    return stepIndex !== -1 ? stepIndex + 1 : 1
  }

  setCurrentStep = (step: number) => {
    this._currentStep = step
  }

  setAgentId = (id: number) => {
    this._agentId = id
  }
}
