// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nTK6RWkZHHMXV0160M8j{height:auto;min-height:132px;padding:20px 20px 19px 20px;border-bottom:1px var(--border-neutral-default) solid}.nTK6RWkZHHMXV0160M8j.fymKfGkeAfe7i_a8qCg1{width:100%;display:flex;align-items:center;justify-content:center}.nTK6RWkZHHMXV0160M8j .gUQ8hRtCuwlCE7kHTdkC{display:flex;align-items:center;justify-content:space-between;margin-top:20px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsProfileCard/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CACA,2BAAA,CACA,qDAAA,CAEA,2CACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":[".wrap {\n  height: auto;\n  min-height: 132px;\n  padding: 20px 20px 19px 20px;\n  border-bottom: 1px var(--border-neutral-default) solid;\n\n  &.loader {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .actions {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "nTK6RWkZHHMXV0160M8j",
	"loader": "fymKfGkeAfe7i_a8qCg1",
	"actions": "gUQ8hRtCuwlCE7kHTdkC"
};
export default ___CSS_LOADER_EXPORT___;
