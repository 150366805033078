import { FC } from 'react'
import { Button } from 'shared/ui'

type IChatActionProps = {
  disabled: boolean
  onClick: () => void
}

export const ChatAction: FC<IChatActionProps> = ({ disabled, onClick }) => {
  return (
    <Button
      size='medium'
      typeBtn='contained'
      contained='secondary'
      icon='messageTextCircle02'
      text='Chat'
      disabled={disabled}
      tooltipProps={{
        label: 'Send message',
      }}
      onClick={onClick}
    />
  )
}
