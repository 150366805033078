import { makeAutoObservable, toJS } from 'mobx'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { RequestAttachment } from 'entities/Attachment/model/RequestAttachment'

import { IChatbotMessage } from './type'

export class ChatbotMessage {
  message = ''
  attachments: Attachment[] = []

  constructor(item?: IChatbotMessage) {
    if (!!item) this.syncOrigin(item)

    makeAutoObservable(this)
  }

  sync(message: string, attachments: Attachment[]) {
    this.message = message
    this.attachments = attachments
  }

  syncOrigin(item?: IChatbotMessage | null) {
    this.message = item?.message ?? ''
    this.attachments =
      item?.media_url?.map((item) => new Attachment({ requestAttachment: item })) ?? []
  }

  toJSON(): IChatbotMessage {
    return toJS({
      message: this.message,
      media_url: this.attachments.map((attachment) => new RequestAttachment(attachment)),
    })
  }

  reset() {
    this.message = ''
    this.attachments = []
  }
}
