import { AxiosError, AxiosResponse } from 'axios'
import { toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import {
  ChatbotApi,
  type Chatbot,
  type IChatbotFilters,
  type IChatbotPayload,
  type IChatbotFullData,
  type IChatbotInstruction,
  ChatbotInstructionType,
} from 'entities/Chatbot'
import { ContactsApi } from 'entities/Contacts'

export class ChatbotOperation {
  private _deleteModalId = 'deleteChatbotModal'
  private _disableModalId = 'disableChatbotModal'
  private _enrollModalId = 'enrollChatbotModal'
  private _confirmTestModalId = 'confirmTestChatbotModal'
  private _confirmCreateModalId = 'confirmCreateChatbotModal'
  private _confirmUpdateModalId = 'confirmUpdateChatbotModal'
  private _confirmInstructionModalId = 'confirmInstructionChatbotModal'

  enroll = async (id: number, name: string, filter?: IChatbotFilters | null) => {
    if (!filter) return

    return ContactsApi.getContactsFiltersCount({ filtersListGroups: filter }).then(
      ({ data: { count } }) =>
        new Promise<void>((resolve) => {
          if (count === 0) return resolve()

          const handleEnroll = () => {
            modalStore.removeModal(this._enrollModalId)

            ChatbotApi.enrollChatbot(id).then(
              () => resolve(),
              () => {
                resolve()
                toastStore.add({
                  title: 'Unable to enroll contacts.',
                  type: 'error',
                })
              }
            )
          }

          const handleCancel = () => {
            resolve()
            modalStore.removeModal(this._enrollModalId)
          }

          modalStore.addModal({
            id: this._enrollModalId,
            showHeader: true,
            showCloseButton: false,
            showCloseIcon: true,
            disabledOnAllClose: true,
            zIndex: 2000,
            width: 280,
            type: ModalTypeList.INFO,
            title: `Textbot “${name}” activated`,
            desc: `Do you want to enroll your ${count} contacts in your Textbot?`,
            primaryAction: {
              text: `Enroll ${count} contacts`,
              onAction: handleEnroll,
            },
            secondaryAction: {
              text: 'Don’t enroll',
              onAction: handleCancel,
            },
            onClose: handleCancel,
          })
        }),
      (error) => {
        toastStore.add({
          title: 'Something went wrong. Please try again.',
          type: 'error',
        })
        throw error
      }
    )
  }

  delete = (id: number) =>
    new Promise<boolean>((resolve) => {
      const handleDelete = () => {
        modalStore.removeModal(this._deleteModalId)
        ChatbotApi.deleteChatbot(id).then(
          () => resolve(true),
          () => {
            toastStore.add({
              title: 'Something went wrong. Please try again.',
              type: 'error',
            })
            resolve(false)
          }
        )
      }

      const handleCancel = () => {
        modalStore.removeModal(this._deleteModalId)
        resolve(false)
      }

      modalStore.addModal({
        id: this._deleteModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        zIndex: 2000,
        width: 280,
        type: ModalTypeList.ALERT,
        title: 'Delete Textbot?',
        desc: 'This action cannot be undone',
        primaryAction: {
          text: 'Delete',
          onAction: handleDelete,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })

  showErrorToast = (message: string) => {
    toastStore.add({
      type: 'error',
      title: message,
    })
  }

  activate = (id: number) =>
    ChatbotApi.updateChatbotStatus(id, true).then(
      (response) => {
        const { data } = response

        if (data.is_active)
          return this.enroll(data.id, data.name, data.filters).then(() => response)

        return response
      },
      (error) => {
        if (error instanceof AxiosError) {
          this.showErrorToast(error.response?.data.message)
        }
        throw error
      }
    )

  disable = (id: number) =>
    new Promise<null | AxiosResponse<IChatbotFullData>>((resolve, reject) => {
      const handleDisable = () => {
        modalStore.removeModal(this._disableModalId)
        ChatbotApi.updateChatbotStatus(id, false).then(
          (response) => resolve(response),
          (error) => {
            reject(error)

            if (error instanceof AxiosError) {
              this.showErrorToast(error.response?.data.message)
            }
          }
        )
      }

      const handleCancel = () => {
        modalStore.removeModal(this._disableModalId)
        resolve(null)
      }

      modalStore.addModal({
        id: this._disableModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        zIndex: 2000,
        width: 280,
        type: ModalTypeList.ALERT,
        title: 'Disable Textbot?',
        desc: 'This will end all active conversations between the Textbot and enrolled contacts',
        primaryAction: {
          text: 'Disable',
          onAction: handleDisable,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })

  updateStatus = (id: number, active: boolean) => (active ? this.activate(id) : this.disable(id))

  load = (id: number) =>
    ChatbotApi.getChatbot(id).then(
      (response) => response,
      (error) => {
        toastStore.add({
          title: 'Something went wrong. Please try again.',
          type: 'error',
        })

        throw error
      }
    )

  create = (payload: IChatbotPayload) => ChatbotApi.createChatbot(payload)

  confirmCreate = async (payload: IChatbotPayload): Promise<IChatbotFullData | null> =>
    new Promise((resolve, reject) => {
      const handleConfirm = async () =>
        this.create(payload)
          .then(({ data }) => resolve(data), reject)
          .finally(() => modalStore.removeModal(this._confirmCreateModalId))

      const handleDeny = () => {
        modalStore.removeModal(this._confirmCreateModalId)
        resolve(null)
      }

      const handleCancel = () => {
        modalStore.removeModal(this._confirmCreateModalId)
        reject(null)
      }

      modalStore.addModal({
        id: this._confirmCreateModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        zIndex: 2000,
        width: 280,
        type: ModalTypeList.INFO,
        title: `Save Textbot ${payload.name ? `“${payload.name} ”` : ''}before closing`,
        primaryAction: {
          text: 'Save as draft',
          onAction: handleConfirm,
        },
        additionalSecondaryAction: {
          text: 'Don’t save',
          onAction: handleDeny,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })

  update = (id: number, payload: IChatbotPayload) => ChatbotApi.updateChatbot(id, payload)

  confirmUpdate = async (id: number, payload: IChatbotPayload): Promise<IChatbotFullData | null> =>
    new Promise((resolve, reject) => {
      const handleConfirm = async () =>
        this.update(id, payload)
          .then(({ data }) => resolve(data), reject)
          .finally(() => modalStore.removeModal(this._confirmUpdateModalId))

      const handleDeny = () => {
        modalStore.removeModal(this._confirmUpdateModalId)
        resolve(null)
      }

      const handleCancel = () => {
        modalStore.removeModal(this._confirmUpdateModalId)
        reject(null)
      }

      modalStore.addModal({
        id: this._confirmUpdateModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        zIndex: 2000,
        width: 280,
        type: ModalTypeList.INFO,
        title: `Save Textbot${payload.name ? ` “${payload.name}” ` : ' '}before closing`,
        primaryAction: {
          text: 'Save as draft',
          onAction: handleConfirm,
        },
        additionalSecondaryAction: {
          text: 'Don’t save',
          onAction: handleDeny,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })

  confirmTest = (name: string) =>
    new Promise<boolean>((resolve) => {
      const handleConfirm = () => {
        modalStore.removeModal(this._confirmTestModalId)
        resolve(true)
      }

      const handleCancel = () => {
        modalStore.removeModal(this._confirmTestModalId)
        resolve(false)
      }

      modalStore.addModal({
        id: this._confirmTestModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        zIndex: 2000,
        width: 280,
        type: ModalTypeList.INFO,
        title: `Save Textbot “${name}” before testing`,
        primaryAction: {
          text: 'Save & Test',
          onAction: handleConfirm,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })

  changeInstruction = (model: Chatbot, instruction?: IChatbotInstruction | null) =>
    new Promise<boolean>((resolve) => {
      const handleChange = () => {
        modalStore.removeModal(this._confirmInstructionModalId)

        if (!instruction || instruction.type === ChatbotInstructionType.LeadQualification) {
          model.setType(ChatbotInstructionType.LeadQualification)
        } else {
          model.setType(instruction.type)
          model.settings.setInstruction(instruction.instruction)
        }

        resolve(true)
      }

      const handleCancel = () => {
        modalStore.removeModal(this._confirmInstructionModalId)
        resolve(false)
      }

      modalStore.addModal({
        id: this._confirmInstructionModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        zIndex: 2000,
        width: 280,
        type: ModalTypeList.WARNING,
        title: 'Change Textbot Type?',
        desc: 'Changing the textbot type will reset "Style your conversation" section.',
        primaryAction: {
          text: 'Yes, Change',
          onAction: handleChange,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })
}

export const chatbotOperation = new ChatbotOperation()
