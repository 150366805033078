import { ActionItem } from 'shared/ui'
import { TeamMember } from 'entities/Admin'
import { adminOrganizationStore } from 'pages/admin/pages/organizations'

export function getAdminOrganizationTeamMemberActions({
  item,
}: {
  item?: TeamMember
}): ActionItem[] {
  const { onAssumeUser } = adminOrganizationStore
  if (!item) {
    return []
  }

  return [
    {
      text: 'Assume',
      icon: 'eye',
      onAction: () => onAssumeUser(item.id),
    },
  ]
}
