import React, { FC, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { TriggerTooltip, ITriggerTooltipProps } from 'shared/ui/TriggerTooltip/TriggerTooltip'
import { Button, IconButton } from 'shared/ui'
import { shortLinkStore } from 'entities/ShortLink'
import styles from './styles.module.scss'

type Props = {
  trigger?: HTMLAnchorElement
  onSuccess: () => void
  onAddShortLink: (value: string) => void
  onHideLinkTooltips: () => void
}

export const ShortenLinkTooltip: FC<ITriggerTooltipProps & Props> = observer(
  ({
    trigger,
    refVisibleTooltip,
    onLeaveTooltip,
    onSuccess,
    onAddShortLink,
    onHideLinkTooltips,
  }) => {
    useEffect(() => {
      shortLinkStore.initAllDomains()
    }, [])

    const onShorten = async () => {
      try {
        const originalLink = trigger?.textContent

        if (originalLink) {
          const shortLink = await shortLinkStore.createShortLink(originalLink)

          if (shortLink) {
            trigger.href = shortLink.shortURL
            trigger.innerText = shortLink.shortURL
            onSuccess()
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onEdit = () => {
      onHideLinkTooltips()
      shortLinkStore.onOpenModal({
        onAddLink: onAddShortLink,
        editLink: trigger,
        onSuccess,
      })
    }

    const title = useMemo(() => {
      if (trigger) {
        return (
          <div className={styles.wrap}>
            <Button
              text={'Shorten URL'}
              icon={'link'}
              size={'small'}
              typeBtn={'text'}
              onClick={onShorten}
            />
            <div className={styles.divider} />
            <IconButton
              size={'small'}
              icon={'edit'}
              color={'secondary'}
              variant={'icon'}
              iconProps={{ fontSize: 16 }}
              onClick={onEdit}
              tooltipProps={{ label: 'Edit', placement: 'top', zIndex: 2100 }}
              ariaLabel={'ShortenLinkTooltip_edit'}
            />
          </div>
        )
      }
      return <></>
    }, [trigger])

    if (!trigger || !shortLinkStore.canShortUrl(trigger.href)) {
      return <></>
    }

    return (
      <TriggerTooltip
        trigger={trigger}
        onLeaveTooltip={onLeaveTooltip}
        refVisibleTooltip={refVisibleTooltip}
        color={'white'}
        component={title}
      />
    )
  }
)
