import { EnumDropdownItemVariant, IDropdownItem, ITabItem } from 'shared/ui'
import { MessageData } from 'entities/Message'
import { Contact } from 'entities/Contacts/model/Contact'
import { Integration } from 'entities/Integrations/model/Integration'

export const savedRepliesTabs: ITabItem[] = [
  {
    key: 'personal',
    name: 'Personal',
  },
  {
    key: 'shared',
    name: 'Shared',
  },
]

export enum SavedRepliesSortEnum {
  newest = 'newest',
  oldest = 'oldest',
  letterDown = 'alphabetical_asc',
  letterUp = 'alphabetical_desc',
}

export const savedRepliesSortItems: IDropdownItem[] = [
  {
    id: SavedRepliesSortEnum.newest,
    label: 'Newest',
    iconL: 'calendarArrowDown',
    variant: EnumDropdownItemVariant.CheckedRight,
  },
  {
    id: SavedRepliesSortEnum.oldest,
    label: 'Oldest',
    iconL: 'calendarArrowUp',
    separator: true,
    variant: EnumDropdownItemVariant.CheckedRight,
  },
  {
    id: SavedRepliesSortEnum.letterDown,
    label: 'Alphabetical (A -> Z)',
    iconL: 'letterDownArrow',
    variant: EnumDropdownItemVariant.CheckedRight,
  },
  {
    id: SavedRepliesSortEnum.letterUp,
    label: 'Alphabetical (Z -> A)',
    iconL: 'letterUpArrow',
    variant: EnumDropdownItemVariant.CheckedRight,
  },
]

export type ISavedRepliesProps = {
  contact?: Contact | null
  integration?: Integration | null
  isCurrentAirCall?: boolean
  onAddSavedReplies: ((message: MessageData) => void) | null
}
