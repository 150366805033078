import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'shared/ui'
import { ordersStore, OrdersItems, OrdersAction } from 'features/DropdownOrder'

const DropdownOrder: React.FC = observer(() => {
  const [toggle, setToggle] = React.useState(false)
  const { loadOrders, orders, loading, order, reset } = ordersStore

  useEffect(() => {
    loadOrders()

    return () => {
      reset()
    }
  }, [])

  return (
    <Dropdown
      ariaLabel={'DropdownOrder'}
      icon={'chatLayout'}
      placement={'bottom-start'}
      typeButton={'button'}
      tooltipProps={{
        label: 'Chat view',
        placement: 'bottom',
      }}
      items={[...OrdersItems(orders)]}
      onChange={(item) => {
        OrdersAction(item)
      }}
      buttonProps={{
        text: order?.label,
        isRight: true,
        icon: toggle ? 'chevronUp' : 'chevronDown',
        typeBtn: 'text',
        size: 'medium',
      }}
      onToggle={(status) => {
        setToggle(status)
      }}
      disabled={loading}
    />
  )
})

export { DropdownOrder }
