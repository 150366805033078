// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aafBUsyAC0d2WJQMWPK1{color:var(--content-negative-primary)}.rnMz4q95aIU2sQ6UmQA4{border:none !important}.KE4DcwKeKnq_fp8F3GFa{padding:12px}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/Widget/WidgetDate/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,qCAAA,CAEJ,sBACI,sBAAA,CAGJ,sBACI,YAAA","sourcesContent":[".warning {\n    color: var(--content-negative-primary);\n}\n.calendarClassName {\n    border: none !important;\n}\n\n.datePickerWrap {\n    padding: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "aafBUsyAC0d2WJQMWPK1",
	"calendarClassName": "rnMz4q95aIU2sQ6UmQA4",
	"datePickerWrap": "KE4DcwKeKnq_fp8F3GFa"
};
export default ___CSS_LOADER_EXPORT___;
