import { initSentry } from 'entities/Sentry'
import './settingsMenu'
import './sidebar'
import './callPopup'
import './main'
import './common'
import './../styles/all.scss'
import './../store/index'

initSentry()
