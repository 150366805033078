import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { DeviceItemsEnum } from 'entities/DeviceSettings'
import { RecordVideoContent } from 'widgets/RecordVideo'
import { RecordStore } from 'widgets/Record'

const DEFAULT_RECORD_MIME_TYPE = 'video/webm'
const RECORD_MIME_TYPE = 'video/webm'

class RecordVideoStore {
  constructor() {
    makeAutoObservable(this)
  }

  recordStore = new RecordStore({
    deviceItems: [DeviceItemsEnum.videoInput, DeviceItemsEnum.audioInput],
    DEFAULT_RECORD_MIME_TYPE,
    RECORD_MIME_TYPE,
    permissions: ['camera', 'microphone'],
    withDelay: true,
  })

  modalId = ''

  setOpenModal = async (onAddVideoRecord: (file: File) => void) => {
    try {
      const handleClose = () => {
        modalStore.removeModal(this.modalId)
      }
      const onAllow = () => {
        const id = nanoid()
        this.modalId = id
        modalStore.addModal({
          id,
          title: 'Record video',
          pureContent: true,
          ModalContent: RecordVideoContent,
          width: 502,
          onClose: this.onCloseModal,
        })
      }
      await this.recordStore.init({
        onAddRecord: onAddVideoRecord,
        onAllow,
        handleClose,
        rejectInfo: {
          title: 'Camera and microphone access required',
          desc: 'To enable recording, please allow Salesmsg to access your camera and microphone',
        },
      })
    } catch (e) {
      console.error(e)
    }
  }
  onCloseModal = () => {
    this.recordStore.onClose()
  }
}

export default new RecordVideoStore()
