import classNames from 'classnames'
import { PropsWithChildren, useRef, useState } from 'react'
import { IIconsVector, Icon, Tooltip, Typography } from 'shared/ui'

import styles from './styles.module.scss'
import { ChatbotTooltip } from '../ChatbotTooltip'

type IChatbotCollapseProps = PropsWithChildren<{
  icon: IIconsVector
  title: string
  tooltip?: string
  error?: string | null
  onChange?: (expanded: boolean) => void
}>

export const ChatbotCollapse = ({
  icon,
  title,
  tooltip,
  error,
  children,
  onChange,
}: IChatbotCollapseProps) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLElement | null>(null)
  const [expanded, expand] = useState(false)

  const handleClick = () => {
    const isExpanded = !expanded

    expand(isExpanded)
    onChange?.(isExpanded)
  }

  return (
    <section
      className={classNames(styles.container, {
        [styles.expanded]: expanded,
      })}
    >
      <header
        ref={ref}
        className={styles.header}
        onClick={handleClick}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <span className={styles.title}>
          <span className={styles.icon}>
            <Icon icon={icon} fontSize={16} />
          </span>
          <Typography className={styles.text} variant='body-lg-medium' ariaLabel={'title'}>
            {title} {!!tooltip && <ChatbotTooltip tooltip={tooltip} />}
          </Typography>
          {!!error && (
            <Tooltip
              color='red'
              placement='bottom'
              open={open}
              label={error}
              PopperProps={{
                anchorEl: () => ref.current as HTMLElement,
              }}
            >
              <Icon icon='alertTriangle' fontSize={16} color={'var(--content-negative-primary)'} />
            </Tooltip>
          )}
        </span>

        <Icon
          fontSize={16}
          color={'var(--content-primary-tertiary)'}
          icon={expanded ? 'chevronUp' : 'chevronDown'}
        />
      </header>
      {expanded && <div className={styles.content}>{children}</div>}
    </section>
  )
}
