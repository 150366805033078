// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZjjYpzM2_Te72bt_1yYg{position:relative;display:flex;align-items:center;width:100%;padding:0 4px 0 3px;cursor:pointer}.ZjjYpzM2_Te72bt_1yYg.j006x8v2Ok0kKzJLyUhn{padding:0 46px 0 3px}.naqFjiH4afskZmFG6BE4{color:var(--content-primary-tertiary);font-size:13px;margin-right:32px}.uwKdw8EJ21myAchqXrrW{flex:1;min-width:100px;height:24px}.uwKdw8EJ21myAchqXrrW label,.uwKdw8EJ21myAchqXrrW input{width:100%;height:100%;line-height:24px}.qnN_p_Ink1olay2mzzHs{position:absolute;top:100%;left:0}.K4ugylBx1GzOqeUgZ1n0{display:flex;flex-wrap:wrap;width:100%;gap:6px}.xNnTYI_hnZ9Q4STpVfhk{height:31px}.fkOJfCAveufhN4_5Pu8n{display:flex;align-items:center;width:24px;height:24px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationNew/ui/ConversationSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA,CACA,mBAAA,CACA,cAAA,CAEA,2CACE,oBAAA,CAIJ,sBACE,qCAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,MAAA,CACA,eAAA,CACA,WAAA,CAEA,wDAEE,UAAA,CACA,WAAA,CACA,gBAAA,CAIJ,sBACE,iBAAA,CACA,QAAA,CACA,MAAA,CAGF,sBACE,YAAA,CACA,cAAA,CACA,UAAA,CACA,OAAA,CAGF,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".wrap {\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: 0 4px 0 3px;\n  cursor: pointer;\n\n  &.isCollapse {\n    padding: 0 46px 0 3px;\n  }\n}\n\n.label {\n  color: var(--content-primary-tertiary);\n  font-size: 13px;\n  margin-right: 32px;\n}\n\n.input {\n  flex: 1;\n  min-width: 100px;\n  height: 24px;\n\n  label,\n  input {\n    width: 100%;\n    height: 100%;\n    line-height: 24px;\n  }\n}\n\n.dropDown {\n  position: absolute;\n  top: 100%;\n  left: 0;\n}\n\n.items {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 6px;\n}\n\n.icon {\n  height: 31px;\n}\n\n.loader {\n  display: flex;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ZjjYpzM2_Te72bt_1yYg",
	"isCollapse": "j006x8v2Ok0kKzJLyUhn",
	"label": "naqFjiH4afskZmFG6BE4",
	"input": "uwKdw8EJ21myAchqXrrW",
	"dropDown": "qnN_p_Ink1olay2mzzHs",
	"items": "K4ugylBx1GzOqeUgZ1n0",
	"icon": "xNnTYI_hnZ9Q4STpVfhk",
	"loader": "fkOJfCAveufhN4_5Pu8n"
};
export default ___CSS_LOADER_EXPORT___;
