import React from 'react'
import classNames from 'classnames'
import { Typography } from 'shared/ui'
import { TagCard } from 'entities/Tags'
import type { Tag } from 'entities/Tags/model/Tag'
import styles from '../styles.module.scss'

type TagsContentProps = {
  tags: Tag[]
}

export const TagsContent = ({ tags }: TagsContentProps) => {
  return (
    <div className={styles.grid}>
      <Typography ariaLabel={'Send'} variant={'text-input-field'} className={styles.label}>
        Tags
      </Typography>
      <div className={classNames(styles.value, styles.triggerValue)}>
        {tags.length > 0 ? (
          tags.map((tag) => (
            <TagCard className={styles.tagsPlaceholderCard} key={tag.id} tag={tag} />
          ))
        ) : (
          <Typography
            variant='body-md-regular'
            color='var(--content-primary-disabled'
            ariaLabel='EmptyText'
          >
            —
          </Typography>
        )}
      </div>
    </div>
  )
}
