import { makeAutoObservable } from 'mobx'
import { type IResponseFilters } from 'entities/Contacts/api/filterTypes'
import { FilterEditorStore } from 'widgets/FilterEditor'

type IFiltersStoreProps = {
  getFilters: () => Promise<IResponseFilters>
}

export class FiltersAndSearchStore {
  private _filterEditorStore: FilterEditorStore

  constructor({ getFilters }: IFiltersStoreProps) {
    makeAutoObservable(this)

    this._filterEditorStore = new FilterEditorStore({
      makeRequest: () => {
        return getFilters()
      },
      showSpawned: true,
    })
  }

  private _opened = false
  private _search = ''

  loadFilters = () => this._filterEditorStore.initFilters()

  get opened() {
    return this._opened
  }

  get search() {
    return this._search
  }

  get filterEditorStore() {
    return this._filterEditorStore
  }

  get params() {
    return {
      search: this._search,
      filtersList: this.noEmptyFilters,
    }
  }

  get noEmptyFilters() {
    return this._filterEditorStore.groups.toSegment().paramsFilterSegment
  }

  get hasSearchParams() {
    return !!this._search.trim() || !!this._filterEditorStore.groups.count
  }

  handleSearch = (value: string) => {
    this._search = value
  }

  reset = () => {
    this._search = ''
    this._filterEditorStore.dispose()
  }

  onOpen = (condition: boolean) => {
    this._opened = condition
  }
}
