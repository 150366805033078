import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { EnumHelperText, HelperText, toastStore } from 'shared/ui'
import { MessageField } from 'widgets/MessageField'
import { ChatbotActionNoteStore } from 'pages/chatbot/store/chatbotActionNoteStore'

import { IChatbotActionProps } from '../type'
import styles from './styles.module.scss'

export type IActionNoteProps = IChatbotActionProps<ChatbotActionNoteStore>

export const ActionNote = observer(({ control, action, errors }: IActionNoteProps) => {
  const isDirty = useRef(false)
  const noteError = errors.getError(action)

  useEffect(() => {
    if (isDirty.current) errors.removeError(action)

    isDirty.current = true
  }, [control.note.messageText])

  useEffect(() => {
    if (isDirty.current && control.note.textLimitReached)
      toastStore.add({
        title: control.note.textLimit.message,
        type: 'error',
      })
  }, [control.note.textLimitReached])

  useEffect(() => control.syncNote(), [])

  return (
    <div className={styles.message}>
      <div
        className={classNames(styles.field, {
          [styles.fieldError]: !!noteError,
        })}
      >
        <MessageField messageFieldStore={control.note} />
      </div>
      {!!noteError && (
        <HelperText icon={'alertTriangleSmall'} variant={EnumHelperText.Error} text={noteError} />
      )}
    </div>
  )
})
