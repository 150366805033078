// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".srvdU7N0NEQ4yZLK4O8b{margin-top:8px;background:var(--background-neutral-default);border-radius:8px}.srvdU7N0NEQ4yZLK4O8b .FqaWxfjtqbvDxehqpG1x{display:grid;grid-template-columns:1fr max-content;justify-content:space-between}.srvdU7N0NEQ4yZLK4O8b .FqaWxfjtqbvDxehqpG1x .ZgiXq6w3G9MBG1s6utA_{display:grid;padding:12px 12px 8px 12px}.srvdU7N0NEQ4yZLK4O8b .FqaWxfjtqbvDxehqpG1x .SLjIIQ77a6_q2HbX733q{display:flex;margin-top:8px;margin-right:8px}.AmK2HXJNyxoD7Yr0yHvb{padding:4px 12px 12px 12px}.AmK2HXJNyxoD7Yr0yHvb .d92sytVXWww9DtQBgBoL{height:32px;align-items:center;background:var(--background-primary-inverted-primary);display:grid;grid-template-columns:24px 1fr;padding:4px;gap:4px;border-radius:4px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageFormFields/RinglessMessageField/ui/UiAudioCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,4CAAA,CACA,iBAAA,CACA,4CACE,YAAA,CACA,qCAAA,CACA,6BAAA,CACA,kEACE,YAAA,CACA,0BAAA,CAEF,kEACE,YAAA,CACA,cAAA,CACA,gBAAA,CAIN,sBACE,0BAAA,CACA,4CACE,WAAA,CACA,kBAAA,CACA,qDAAA,CACA,YAAA,CACA,8BAAA,CACA,WAAA,CACA,OAAA,CACA,iBAAA","sourcesContent":[".wrap {\n  margin-top: 8px;\n  background: var(--background-neutral-default);\n  border-radius: 8px;\n  .header {\n    display: grid;\n    grid-template-columns: 1fr max-content;\n    justify-content: space-between;\n    .left {\n      display: grid;\n      padding: 12px 12px 8px 12px;\n    }\n    .right {\n      display: flex;\n      margin-top: 8px;\n      margin-right: 8px;\n    }\n  }\n}\n.content {\n  padding: 4px 12px 12px 12px;\n  .card {\n    height: 32px;\n    align-items: center;\n    background: var(--background-primary-inverted-primary);\n    display: grid;\n    grid-template-columns: 24px 1fr;\n    padding: 4px;\n    gap: 4px;\n    border-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "srvdU7N0NEQ4yZLK4O8b",
	"header": "FqaWxfjtqbvDxehqpG1x",
	"left": "ZgiXq6w3G9MBG1s6utA_",
	"right": "SLjIIQ77a6_q2HbX733q",
	"content": "AmK2HXJNyxoD7Yr0yHvb",
	"card": "d92sytVXWww9DtQBgBoL"
};
export default ___CSS_LOADER_EXPORT___;
