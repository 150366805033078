import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { bannerStore, EnumAlertBannerVariant } from 'shared/ui'
import { storeCache } from 'shared/api/storeCache'
import { uiStore } from 'shared/store/uiStore'
import { type IParamsLogin, type IParamsRegister } from 'entities/Auth'
import { AuthApi } from 'entities/Auth/api'
import { getAssumeToken, removeTokenLocalStorage, setToken } from 'entities/Auth/lib/setToken'
import { websocket } from 'entities/WebSocket'
import { resetPosthog } from 'entities/Posthog'
import { sessionManager } from '../lib/sessionManager'
import { GoogleAuthStore } from './googleAuth'

class AuthStore {
  constructor() {
    makeAutoObservable(this)
    this.checkAssuming()
  }

  error = ''
  loading = true
  isAssume = false
  isLoggedIn = false
  isNewSession = false
  googleAuthStore: GoogleAuthStore = new GoogleAuthStore()

  async login(body: IParamsLogin) {
    const { data } = await AuthApi.login(body)

    if (data.token) {
      runInAction(() => {
        this.isLoggedIn = true
        this.isNewSession = sessionManager.syncSession(true)
      })
      this.setToken(data.token.access_token)
    }
  }

  async register(body: IParamsRegister) {
    const { data } = await AuthApi.register(body)

    if (data.token) {
      runInAction(() => {
        this.isLoggedIn = true
        this.isNewSession = sessionManager.syncSession(true)
      })
      this.setToken(data.token.access_token)
    }

    return data
  }

  checkAssuming = () => {
    this.isAssume = !!getAssumeToken()

    if (this.isAssume) {
      bannerStore.add({
        id: nanoid(),
        action: {
          text: 'Stop assuming',
          onAction: () => {
            window.close()
          },
        },
        alert: {
          title: 'You have assumed another user’s account',
          variant: EnumAlertBannerVariant.Warning,
        },
      })
    }
  }

  setToken(token: string) {
    setToken(token)
  }

  logout = async () => {
    storeCache.dispose()

    if (this.isAssume) {
      window.close()
      return
    }
    try {
      localStorage.removeItem('showedAwayStatusDate')
      localStorage.removeItem('contactDetailsOpenStatus')
      await AuthApi.logout()
      removeTokenLocalStorage(this.isAssume)

      websocket.disconnect()
      resetPosthog()
    } catch (e) {
      console.error(e)
    } finally {
      if (!window.location.href.includes('/login')) {
        uiStore.changeRoute({
          path: '/auth/login',
        })
        window.location.reload()
      }
      runInAction(() => {
        this.isLoggedIn = false
        this.isNewSession = sessionManager.syncSession(false)
      })
    }
  }

  refresh = async ({ enableLoader = true } = {}) => {
    try {
      if (enableLoader) {
        runInAction(() => {
          this.loading = true
        })
      }

      const { data } = await AuthApi.updateAuthRefresh()
      const isLogin = Boolean(data.is_login)

      runInAction(() => {
        this.isLoggedIn = isLogin

        if (enableLoader) {
          this.isNewSession = sessionManager.isNewSession()
          sessionManager.syncSession(false)
        }
      })

      return data
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.error = e.stack || 'Error'
    } finally {
      if (enableLoader) {
        runInAction(() => {
          this.loading = false
        })
      }
    }
  }
}

export const authStore = new AuthStore()
