import { observer } from 'mobx-react-lite'
import { AudioContent } from 'widgets/Record/ui/RecordActions/RecordPlayer/audio/AudioContent'
import { VideoContent } from 'widgets/Record/ui/RecordActions/RecordPlayer/video/VideoContent'
import { RecordStore } from 'widgets/Record'

export const PlayingContent = observer(({ recordStore }: { recordStore: RecordStore }) => {
  const { isAudio } = recordStore
  return isAudio ? (
    <AudioContent recordStore={recordStore} />
  ) : (
    <VideoContent recordStore={recordStore} />
  )
})
