import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type ConversationMenuStore } from 'widgets/ConversationMenu'
import { ConversationMenuContext } from 'widgets/ConversationMenu/context'
import { ConversationMenuContent } from './ConversationMenuContent'

type IConversationMenuProps = {
  store: ConversationMenuStore
}

export const ConversationMenu: FC<IConversationMenuProps> = observer(({ store }) => {
  return (
    <ConversationMenuContext.Provider value={store}>
      <ConversationMenuContent />
    </ConversationMenuContext.Provider>
  )
})
