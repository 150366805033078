import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Switch, Typography } from 'shared/ui'
import { callStore } from 'entities/Call'
import { IDeviceSettingsContentProps } from 'entities/DeviceSettings'
import styles from './styles.module.scss'

export const RecordAutoContent: FC<IDeviceSettingsContentProps> = observer(
  ({ deviceSettingsStore }) => {
    const {} = deviceSettingsStore
    return (
      <div className={classnames(styles.section, styles['section--record'])}>
        <div className={styles.dropdownWrap}>
          <div className={styles.dropdownBox}>
            <Typography
              variant={'body-md-regular'}
              color={'var(--content-primary-primary)'}
              ariaLabel={'Record automatically'}
            >
              Record automatically
            </Typography>
          </div>
          <div className={styles.dropdownBox}>
            <Switch
              value={callStore.isRecord}
              disabled={callStore.isRecordLoading}
              onChange={() => {
                callStore.handleChangeRecord()
              }}
            />
          </div>
        </div>
      </div>
    )
  }
)
