import { observer } from 'mobx-react-lite'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import React, { useEffect } from 'react'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import {
  CallHistoryHeader,
  EmptyStateCallHistory,
  useCallHistoryContext,
} from 'widgets/CallHistory'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { CallHistoryTable } from 'widgets/CallHistoryTable'
import { useEventMessageReceivedNew } from 'widgets/CallHistory/events/EventMessageReceivedNew'
import { useEventConversationUnread } from 'widgets/CallHistory/events/EventConversationUnread'
import { useEventMessageUpdatedNew } from 'widgets/CallHistory/events/EventMessageUpdatedNew'
import { useEventVoicemailReceived } from 'widgets/CallHistory/events/EventVoicemailReceived'
import styles from 'widgets/CallHistory/ui/styles.module.scss'
import { useEventInboxCalling } from 'widgets/CallHistory/events/EventInboxCalling'

export const CallHistoryContent = observer(() => {
  const store = useCallHistoryContext()
  const { isEmptyState, isEmptySearchResults, isLoading, isLoadingStatistics, reset } = store
  const { startFocusingMode, endFocusingMode } = uiStore

  useEventConversationUnread(store)
  useEventMessageReceivedNew(store)
  useEventMessageUpdatedNew(store)
  useEventVoicemailReceived(store)
  useEventInboxCalling(store)

  useEffect(() => {
    reset()
  }, [])

  if (isEmptyState) {
    return <EmptyStateCallHistory />
  }

  return (
    <ClickAwayListener onClickAway={endFocusingMode}>
      <div className={styles.wrap} onClick={startFocusingMode}>
        <CallHistoryHeader />
        {(isLoadingStatistics || isLoading) && (
          <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
        )}
        {!isLoadingStatistics &&
          !isLoading &&
          (isEmptySearchResults ? (
            <NoResultsFound />
          ) : (
            <CallHistoryTable callHistoryStore={store} />
          ))}
      </div>
    </ClickAwayListener>
  )
})
