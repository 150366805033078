import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button, Dropdown, Icon } from 'shared/ui'
import { PresetDropdownItem, PresetDropdownList } from 'shared/Preset'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotTemplate, ChatbotTemplateList } from 'pages/chatbot/config'
import { chatbotOperation } from 'pages/chatbot/store/chatbotOperationStore'

export const ChatbotType = observer(() => {
  const [active, setActive] = useState(false)
  const { model, initialized, instructions, activeTemplates } = useChatbotContext()

  const template = ChatbotTemplate.get(model.type) ?? ChatbotTemplateList[0]

  return (
    <Dropdown
      placement='bottom-start'
      triggerComponent={() => (
        <Button
          active={active}
          disabled={!initialized}
          typeBtn='select'
          contained='secondary'
          text={template.name}
          icon={template.icon}
          textRight={<Icon icon={active ? 'chevronUp' : 'chevronDown'} />}
        />
      )}
      onToggle={setActive}
      customComponent={(closeMenu) => (
        <PresetDropdownList title='Textbot type'>
          {activeTemplates.map((template) => (
            <PresetDropdownItem
              key={template.name}
              selected={model.type === template.type}
              onClick={() => {
                if (model.type === template.type) return closeMenu()

                chatbotOperation
                  .changeInstruction(model, instructions.get(template.type))
                  .then(closeMenu)
              }}
              icon={template.icon}
              name={template.name}
              description={template.description}
            />
          ))}
        </PresetDropdownList>
      )}
    />
  )
})
