import React from 'react'
import type { Attachment } from 'entities/Attachment/model/Attachment'
import { RinglessContent } from './RinglessContent/RinglessContent'
import { SMSContent } from './SMSContent/SMSContent'

type MessageContentProps = {
  type: 'sms' | 'ringless'
  attachments: Attachment[]
  message: string
}

export const MessageContent = ({ type, attachments, message }: MessageContentProps) => {
  if (type === 'sms') {
    return <SMSContent attachments={attachments} message={message} />
  }
  if (type === 'ringless') {
    return <RinglessContent attachments={attachments} />
  }
  return <></>
}
