import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { AudioVolumeLevelStore } from '../store/audioVolumeLevelStore'

type IAudioVolumeLevelProps = {
  stream: MediaStream
}

export const AudioVolumeLevel: React.FC<IAudioVolumeLevelProps> = observer(({ stream }) => {
  const [store] = useState(() => new AudioVolumeLevelStore())

  useEffect(() => {
    store.init(stream)

    return () => {
      store.handleClear()
    }
  }, [])

  return (
    <div className={styles.wrap}>
      {store.microphoneAudioLevelItems.map((item) => {
        return (
          <div
            key={item}
            className={classnames(styles.wrap__item, {
              [styles['wrap__item--active']]: (item + 1) * 10 <= store.microphoneAudioLevel,
            })}
          />
        )
      })}
    </div>
  )
})
