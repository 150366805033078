import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Avatar, IAvatarInfo, Tooltip } from 'shared/ui'

export const AvatarContent = observer(({ avatarInfo }: { avatarInfo?: IAvatarInfo }) => {
  const tooltipLabel = useMemo(() => {
    const firstName = avatarInfo?.firstName?.trim()
    const lastName = avatarInfo?.lastName?.trim()
    const phone = avatarInfo?.phone?.trim()

    const words = []

    if (avatarInfo?.isPhoneAbbr) {
      if (phone) words.push(phone)
    } else {
      if (firstName) words.push(firstName)
      if (lastName) words.push(lastName)
    }

    return !!words.length ? words.join(' ') : null
  }, [avatarInfo])

  return (
    <Tooltip placement='top' label={tooltipLabel}>
      <Avatar info={avatarInfo || {}} />
    </Tooltip>
  )
})
