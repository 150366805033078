import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { MoreDropdownProps } from 'shared/ui/Actions/Actions'
import { MessageSignatureStore } from 'widgets/MessageField/ui/MessageSignature'

type IMessageSignatureIconButtonProps = MoreDropdownProps & {
  store: MessageSignatureStore
}

export const MessageSignatureIconButton: FC<IMessageSignatureIconButtonProps> = observer(
  ({ store }) => {
    const { isActiveMessageSignature, handleUpdateMessageSignatureProperties } = store
    const onToggleMessageSignature = () => {
      handleUpdateMessageSignatureProperties({
        is_active: !isActiveMessageSignature,
      })
    }
    return (
      <IconButton
        variant={'icon'}
        color={'secondary'}
        tooltipProps={{
          label: isActiveMessageSignature ? 'Disable signature' : 'Enable signature',
        }}
        icon={'signature'}
        onClick={onToggleMessageSignature}
        ariaLabel='MessageSignatureIconButton'
      />
    )
  }
)
