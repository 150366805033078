import React, { FC } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Dropdown, DropdownContent, DropdownGroup, IIconsVector, Typography } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'
import styles from 'widgets/MergeField/ui/styles.module.scss'
import { MergeFieldsStore } from 'widgets/MergeField'

type MergeFieldUrlDropdownProps = {
  mergeFieldStore: MergeFieldsStore
}

export const MergeFieldUrlDropdown: FC<MergeFieldUrlDropdownProps> = observer(
  ({ mergeFieldStore }) => {
    const { setSearch, loading, search, filteredMergeFields, onAddMergeField } = mergeFieldStore

    const handleSearch = async (search: string) => {
      await setSearch(search)
    }

    const getMergeFieldIconByKey = (key: string): IIconsVector => {
      switch (key) {
        case 'first_name':
        case 'last_name':
        case 'user.first_name':
        case 'user.last_name':
          return 'personFilled'
        case 'formatted_number':
          return 'phone'
        case 'email':
        case 'user.email':
          return 'email'
        default:
          return 'salesmessage'
      }
    }

    return (
      <Dropdown
        ariaLabel={'MergeFieldsIconAction'}
        placement={'bottom-start'}
        tooltipProps={{
          label: 'Merge fields',
          placement: 'top',
        }}
        iconButtonProps={{
          onClick: (e) => e.preventDefault(),
          ariaLabel: getAriaLabel('Trigger'),
        }}
        sizeTriggerButton={'small'}
        variantTriggerButton={'icon'}
        icon={'code'}
        typeButton={'icon-button'}
        width={228}
        customComponent={(onCloseMenu) => (
          <DropdownContent>
            <div className={styles.header}>
              <TextField
                className={styles.textInput}
                size='small'
                variant='integrated'
                InputProps={{
                  placeholder: 'Search',
                }}
                value={search}
                onChange={handleSearch}
                mainActions={[makeTextFieldAutoFocus({ withFocus: !loading })]}
              />
            </div>
            <div className={styles.divider} />
            {!filteredMergeFields.length && (
              <div className={styles.wrapEmpty}>
                <Typography
                  variant={'body-sm-regular'}
                  color={'var(--content-primary-tertiary)'}
                  ariaLabel={'No results found'}
                >
                  No results found
                </Typography>
              </div>
            )}
            {!!filteredMergeFields.length && (
              <DropdownGroup
                title='Merge fields'
                items={filteredMergeFields
                  .filter((item) => !item.name.includes('custom'))
                  .map((field) => ({
                    id: field.name,
                    label: field.label,
                    key: field.name,
                    value: field.value,
                    onClick: () => {
                      const isSuccess = onAddMergeField && onAddMergeField(field)
                      !isSuccess && onCloseMenu()
                    },
                    paddingInline: 8,
                    iconClassName: classNames(styles.icon, styles.iconTransparent),
                    iconL: getMergeFieldIconByKey(field.name),
                    typeBtn: 'menuItem',
                  }))}
              />
            )}
          </DropdownContent>
        )}
      />
    )
  }
)
