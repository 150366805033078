import { IColumn, Table, Tooltip } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { IDuplicateReason, ISkippedReason } from 'entities/Contacts/api/types'

import styles from './styles.module.scss'

interface IHistoryModalContentProps {
  data: IDuplicateReason[] | ISkippedReason[]
}

type IReason = (IDuplicateReason | ISkippedReason) & {
  id: number
}

export const HistoryModalContent = ({ data }: IHistoryModalContentProps) => {
  const columns: IColumn<IReason>[] = [
    {
      field: 'row',
      name: '#',
      minWidth: 84,
      renderRowCell: (row) => (
        <span className={styles.rowCell}>{numberFormat({ value: row.row })}</span>
      ),
    },
    {
      field: 'number',
      name: 'Number',
      minWidth: 144,
      renderRowCell: (row) => row.number,
    },
    {
      field: 'reason',
      name: 'Reason',
      minWidth: 234,
      maxWidth: 234,
      renderRowCell: (row) => (
        <Tooltip label={row.reason} withoutPointer>
          <span>{row.reason}</span>
        </Tooltip>
      ),
    },
  ]

  const rows = data.map((row, id) => ({ ...row, id }))

  return <Table height='400px' rows={rows} columns={columns} noPagination />
}
