import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type { IResponseContact, IResponseContactsSearch } from 'entities/Contacts/api/types'

import {
  IParamsIntegrationsContactsSearch,
  IResponseIntegrations,
  IResponseMergeField,
  IResponseIntegrationsContactsHubspotInfo,
  IResponseIntegrationsContactsActiveCampaignInfo,
  IResponseIntegrationsContactsSalesforceInfo,
  IResponseIntegrationsContactsPipedriveInfo,
  IResponseInfusionsoftTags,
  IIntegrationLinkDTO,
  IResponseInfusionsoftInfo,
  IIntegrationSearchMatchingContactsParams,
  IResponseIntegrationContactsMatchingSearch,
  IResponseIntegrationActivecampaignTagsApply,
  IParamsIntegrationActivecampaignTagsApply,
  IResponseIntegrationInfusionsoftTagsApply,
  IParamsIntegrationInfusionsoftTagsApply,
  IParamsIntegrationsByKeyFields,
  IConnectTwilioParams,
  IResponseConnectTwilio,
  IResponseGetHubspotLists,
} from './types'
import { IntegrationKey } from '../model/Integration'

class Api {
  getIntegrations(): Promise<AxiosResponse<IResponseIntegrations>> {
    return baseApi.get('integrations', {
      params: {
        include_disconnected: true,
      },
    })
  }

  getIntegrationsContactsActivecampaign(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/activecampaign', { params, ...options })
  }

  getIntegrationsContactsInfusionsoft(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/infusionsoft', { params, ...options })
  }

  getIntegrationsContactsPipedrive(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/pipedrive', { params, ...options })
  }

  getIntegrationsContactsSalesforce(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/salesforce', { params, ...options })
  }

  getIntegrationsContactsHubspot(
    params: IParamsIntegrationsContactsSearch,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseContactsSearch>> {
    return baseApi.get('integrations/contacts/hubspot', { params, ...options })
  }
  getIntegrationsContactMergeFields(
    id: number,
    query?: string
  ): Promise<AxiosResponse<IResponseMergeField[]>> {
    return baseApi.get(`integrations/${id}/merge-fields`, { params: { query } })
  }

  getAllFields(): Promise<AxiosResponse<{ [key in IntegrationKey]: IResponseMergeField[] }>> {
    return baseApi.get('integrations/all-fields')
  }

  getIntegrationsByKeyFields(
    key: IParamsIntegrationsByKeyFields
  ): Promise<AxiosResponse<IResponseMergeField[]>> {
    return baseApi.get(`integrations/${key}/fields`)
  }

  getContactsIntegrationsHubspotInfo(
    id: number
  ): Promise<AxiosResponse<IResponseIntegrationsContactsHubspotInfo>> {
    return baseApi.get(`hubspot/contact/${id}/info`)
  }

  getContactsIntegrationsActiveCampaignInfo(
    id: number
  ): Promise<AxiosResponse<IResponseIntegrationsContactsActiveCampaignInfo>> {
    return baseApi.get(`activecampaign/contact/${id}/info`)
  }

  getContactsIntegrationsPipedriveInfo(
    id: number
  ): Promise<AxiosResponse<IResponseIntegrationsContactsPipedriveInfo>> {
    return baseApi.get(`pipedrive/extension/contact/${id}/info`)
  }

  getContactsIntegrationsSalesforceInfo(
    id: number
  ): Promise<AxiosResponse<IResponseIntegrationsContactsSalesforceInfo>> {
    return baseApi.get(`salesforce/contact/${id}/info`)
  }

  getContactsIntegrationsInfusionsoftInfo(
    id: number
  ): Promise<AxiosResponse<IResponseInfusionsoftInfo>> {
    return baseApi.get(`infusionsoft/contact/${id}/info`)
  }

  unlinkContactsByIdIntegrations(id: number): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.put(`integrations/contacts/${id}/unlink`)
  }

  createContactsIntegrationsActiveCampaignTag(
    data: IParamsIntegrationActivecampaignTagsApply
  ): Promise<AxiosResponse<IResponseIntegrationActivecampaignTagsApply>> {
    return baseApi.put('/integrations/activecampaign/tags/apply', data)
  }

  createContactsIntegrationsInfusionsoftTag(
    data: IParamsIntegrationInfusionsoftTagsApply
  ): Promise<AxiosResponse<IResponseIntegrationInfusionsoftTagsApply>> {
    return baseApi.put('/integrations/infusionsoft/tags/apply', data)
  }

  getContactsIntegrationsInfusionsoftTags(
    value: string
  ): Promise<AxiosResponse<IResponseInfusionsoftTags>> {
    return baseApi.get(`integrations/infusionsoft/tags?query=${value}`)
  }

  linkContactsIntegrations(data: IIntegrationLinkDTO): Promise<AxiosResponse<IResponseContact>> {
    return baseApi.post(`contacts/${data.contact_id}/link`, data)
  }

  searchMatchingContactsIntegrations(
    params: IIntegrationSearchMatchingContactsParams
  ): Promise<AxiosResponse<IResponseIntegrationContactsMatchingSearch>> {
    return baseApi.get('/integrations/contacts/search', { params })
  }

  connectTwilio(params: IConnectTwilioParams): Promise<AxiosResponse<IResponseConnectTwilio>> {
    return baseApi.post('integrations/number-vendors/twilio-integration/connect', params)
  }

  getHubspotLists(): Promise<AxiosResponse<IResponseGetHubspotLists[]>> {
    return baseApi.get('hubspot/lists/contact/processed')
  }
}

export const IntegrationsApi = new Api()
