import { FC, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { AttachmentCard } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'

type IAttachmentsProps = {
  attachments: Attachment[]
  removeAttachments?: (ids: Array<string | number>) => void
  medium?: boolean
  fullWidth?: boolean
  imgFitType?: 'cover' | 'contain'
}

export const Attachments: FC<IAttachmentsProps> = observer(
  ({ attachments, removeAttachments, fullWidth, medium, imgFitType }) => {
    const indexRef = useRef(-1)
    const allowedTypes = ['image', 'gif', 'video', 'audio']
    const attachmentsFiltered = attachments.filter((item) => allowedTypes.includes(item.type))
    const onOpenPresentation = (index: number) => () => {
      presentationStore.setAttachments(attachmentsFiltered, index)
    }

    useEffect(() => {
      indexRef.current = -1
    }, [attachmentsFiltered])

    const attachmentsRender = (attachment: Attachment) => {
      const onRemoveAttachment = () => {
        removeAttachments && attachment && removeAttachments([attachment.id])
      }

      if (allowedTypes.includes(attachment.type)) indexRef.current += 1

      return (
        <AttachmentCard
          key={attachment.id}
          attachment={attachment}
          onRemoveAttachment={removeAttachments && onRemoveAttachment}
          noSource
          isGray
          loading={attachment.loading}
          onCardClick={onOpenPresentation(indexRef.current)}
          fromAttachMedia
          medium={medium}
          fullWidth={fullWidth}
          imgFitType={imgFitType}
        />
      )
    }

    return <>{attachments.map(attachmentsRender)}</>
  }
)
