import { makeAutoObservable } from 'mobx'

export class NavLinkVueStore {
  path = ''

  constructor() {
    makeAutoObservable(this)
  }

  changeRoute = (to: string) => {
    this.path = to
  }
}

export const navLinkVueStore = new NavLinkVueStore()
