import { RefObject, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { BaseContent, Head } from 'widgets/CallPopUp'
import styles from 'widgets/CallPopUp/ui/styles.module.scss'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'

type ICallPowerDialerBaseContentProps = {
  refWrap: RefObject<HTMLDivElement>
}

export const CallPowerDialerBaseContent: FC<ICallPowerDialerBaseContentProps> = observer(
  ({ refWrap }) => {
    const { callPopUpPowerDialerStoreV2, innerRefTooltipCurrent } = useCallPopUpDefaultContext()
    const {
      awaitingDelayContact,
      callDelayValue,
      handleSkipContact,
      handleCall,
      isPowerDialerCallingDisabled,
      isPreCallSetupInProgress,
      callFromTeam,
      callFromNumber,
    } = callPopUpPowerDialerStoreV2
    if (!awaitingDelayContact) return null

    return (
      <div className={styles.section}>
        <div ref={refWrap} className={styles.tooltipWrap} />

        <Head
          isIncoming={false}
          wrapElement={innerRefTooltipCurrent}
          name={callFromTeam?.name}
          phone={callFromNumber?.formatted_number}
        />

        <BaseContent
          withPadding={true}
          avatarsInfo={[awaitingDelayContact.avatarProps.info]}
          name={awaitingDelayContact.name || awaitingDelayContact.number}
          number={awaitingDelayContact.number}
          desc={awaitingDelayContact.number}
          actionsBottom={
            <div className={styles.powerDialerActions}>
              <Typography
                className={styles.powerDialerActions__label}
                variant={'body-md-medium'}
                ariaLabel={'Next call'}
              >
                Next call in {callDelayValue} sec...
              </Typography>
              <div className={styles.powerDialerActions__buttons}>
                <Button
                  contained={'secondary'}
                  text={'Skip'}
                  onClick={() => handleSkipContact(awaitingDelayContact)}
                  disabled={isPowerDialerCallingDisabled}
                />
                <Button
                  intent={'positive'}
                  contained={'primary'}
                  icon={'phone'}
                  text={'Call'}
                  loading={isPreCallSetupInProgress}
                  onClick={() => handleCall(awaitingDelayContact.id)}
                  disabled={isPowerDialerCallingDisabled}
                />
              </div>
            </div>
          }
        />
      </div>
    )
  }
)
