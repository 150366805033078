import { observer } from 'mobx-react-lite'
import { NameValueRecord } from 'shared/ui'
import { ChatbotField } from 'entities/Chatbot'

import styles from './styles.module.scss'
import { useDetailsContext } from '../detailsContext'

interface IObjectiveFieldProps {
  field: ChatbotField
}

export const ObjectiveField = observer(({ field }: IObjectiveFieldProps) => {
  const { getFieldName } = useDetailsContext()
  const fieldName = getFieldName(field.key)

  return (
    <div className={styles.fieldCard}>
      <header className={styles.fieldCardHead}>Collect</header>
      <div className={styles.fieldCardRow}>
        <span className={styles.fieldCardName}>Contact Property</span>
        <div className={styles.fieldCardValue}>
          {!!fieldName ? (
            <NameValueRecord icon='textInput' name={fieldName} />
          ) : (
            <NameValueRecord icon='textInput' value='Select' />
          )}
        </div>
      </div>
      <div className={styles.fieldCardRow}>
        <span className={styles.fieldCardName}>Display name</span>
        <div className={styles.fieldCardValue}>
          {!!field.name ? (
            <NameValueRecord icon='messageSmile' name={field.name} />
          ) : (
            <NameValueRecord icon='messageSmile' value='Set a friendly name (optional)' />
          )}
        </div>
      </div>
    </div>
  )
})
