import { observer } from 'mobx-react-lite'
import { DownloadPreviewBtn } from 'widgets/PresentationMode/ui/info/ui/buttons/DownloadPreviewBtn'
import { InfoWrap } from 'widgets/PresentationMode/ui/info/ui/InfoWrap/InfoWrap'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { getAttachmentFlags } from 'widgets/MediaLibrary/utils'

export const InfoDocPreview = observer(() => {
  const { currentAttachment } = presentationStore

  if (!currentAttachment) return null

  const { isVCardPresentationAvailable } = getAttachmentFlags(currentAttachment)

  if (isVCardPresentationAvailable) return null

  return (
    <InfoWrap
      rightActions={
        <>
          <DownloadPreviewBtn />
        </>
      }
    />
  )
})
