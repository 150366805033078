import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InputCheckbox } from 'shared/ui'

import styles from './styles.module.scss'

type IChatbotEnrollModalFiltersProps = {
  value: boolean
  onChange: (value: boolean) => void
}

export const ChatbotEnrollModalFilters = observer(
  ({ value, onChange }: IChatbotEnrollModalFiltersProps) => {
    const [isActive, setIsActive] = useState(value)

    const handleChange = () => {
      setIsActive(!isActive)
      onChange(!isActive)
    }

    return (
      <InputCheckbox
        className={styles.checkbox}
        label='Check Textbot enrollment criteria before enrolling contacts.'
        checked={isActive}
        onChecked={handleChange}
      />
    )
  }
)
