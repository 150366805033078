import { IIconsVector } from '../Icon'
import { IStatusProps } from '../Status'

export enum CountryEnum {
  US = 'US',
  CA = 'CA',
  GB = 'GB',
  AU = 'AU',
  World = 'World',
}

export type ICountry = {
  name: string
  value: CountryEnum | string
  icon: IIconsVector
  status: IStatusProps
}
