// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QPeNPuBjfZtR9ob_8lod{text-align:left}.hPvVda3wVM5U3j4ou5_2{color:var(--content-neutral-primary)}.sF0SWqn_pAdPzaRBMmMJ{background:var(--background-primary-inverted-primary);padding-inline:12px}.iExrUAj9kBwR9ehT6AGZ{padding-right:4px;display:grid;grid-template-columns:1fr max-content;gap:8px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Select/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,oCAAA,CAEF,sBACE,qDAAA,CACA,mBAAA,CAEF,sBACE,iBAAA,CACA,YAAA,CACA,qCAAA,CACA,OAAA","sourcesContent":[".text{\n  text-align: left;\n}\n.placeholder{\n  color: var(--content-neutral-primary)\n  }\n.textFieldPlaceholderProps{\n  background: var(--background-primary-inverted-primary);\n  padding-inline: 12px;\n}\n.labelContent{\n  padding-right: 4px;\n  display: grid;\n  grid-template-columns: 1fr max-content;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "QPeNPuBjfZtR9ob_8lod",
	"placeholder": "hPvVda3wVM5U3j4ou5_2",
	"textFieldPlaceholderProps": "sF0SWqn_pAdPzaRBMmMJ",
	"labelContent": "iExrUAj9kBwR9ehT6AGZ"
};
export default ___CSS_LOADER_EXPORT___;
