import { observer } from 'mobx-react-lite'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotCollapse } from 'pages/chatbot/ui/ChatbotCollapse'

import styles from './styles.module.scss'
// import { PointAiTone } from './PointAiTone'
import { PointMaxAttempts } from './PointMaxAttempts'
import { PointAboutConversation } from './PointAboutConversation'
import { PointBusinessInformation } from './PointBusinessInformation'
import { PointHowToRespond } from './PointHowToRespond'

export const StepConfigure = observer(() => {
  const { errors } = useChatbotContext()

  const isNestedError =
    errors.hasError('settings.ai_tone') ||
    errors.hasError('settings.max_attempts.value') ||
    errors.hasError('settings.about_conversation') ||
    errors.hasError('settings.business_information') ||
    errors.hasError('settings.how_to_respond')

  const configureError = isNestedError ? 'Please complete all the fields' : null

  return (
    <ChatbotCollapse
      icon='settings'
      title='Set your conversation style'
      tooltip='Configure the length, tone, and topic of your Textbot’s conversation'
      error={configureError}
    >
      <div className={styles.container}>
        {/* <PointAiTone /> */}
        <PointMaxAttempts />
        <PointAboutConversation />
        <PointBusinessInformation />
        <PointHowToRespond />
      </div>
    </ChatbotCollapse>
  )
})
