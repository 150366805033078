import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventViewing } from 'entities/Viewing'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export const EventViewing = {
  subscribe: (handler: (data: IResponseEventViewing) => void) =>
    websocket.on(WebsocketEvent.ClientViewing, handler),
  unsubscribe: (handler: (data: IResponseEventViewing) => void) =>
    websocket.off(WebsocketEvent.ClientViewing, handler),
}

export const useEventViewing = (conversationMessageStore: ConversationMessagesStore) =>
  useEffect(
    () =>
      EventViewing.subscribe((data) => {
        conversationMessageStore.handleViewingEvent(data)
      }),
    []
  )
