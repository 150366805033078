import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'

import { Label } from 'shared/ui/Label'
import { InputNumeric, makeTextFieldClear, TextField } from 'shared/ui/TextField'
import { adminEditLimitsStore } from 'pages/admin/pages/organizations/modals/AdminEditLimits/store/adminEditLimitsStore'

export const AdminEditLimitsContent = observer(() => {
  const {
    broadcasts_long_code_limit,
    set_broadcasts_long_code_limit,
    broadcasts_short_code_limit,
    set_broadcasts_short_code_limit,
    broadcasts_toll_free_limit,
    set_broadcasts_toll_free_limit,
    contactImportLimit,
    setContactImportLimit,
  } = adminEditLimitsStore
  return (
    <>
      <Box display={'grid'} gap={'16px'}>
        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Long code daily limit',
            }}
          />
          <TextField
            Input={InputNumeric}
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'Enter',
            }}
            value={broadcasts_long_code_limit}
            onChange={set_broadcasts_long_code_limit}
            rightActions={[makeTextFieldClear()]}
          />
        </Box>
        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Short code daily limit',
            }}
          />
          <TextField
            Input={InputNumeric}
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'Enter',
            }}
            value={broadcasts_short_code_limit}
            onChange={set_broadcasts_short_code_limit}
            rightActions={[makeTextFieldClear()]}
          />
        </Box>
        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Toll-free daily limit',
            }}
          />
          <TextField
            Input={InputNumeric}
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'Enter',
            }}
            value={broadcasts_toll_free_limit}
            onChange={set_broadcasts_toll_free_limit}
            rightActions={[makeTextFieldClear()]}
          />
        </Box>
        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Contact import limit',
            }}
          />
          <TextField
            Input={InputNumeric}
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'Enter',
            }}
            value={contactImportLimit}
            onChange={setContactImportLimit}
            rightActions={[makeTextFieldClear()]}
          />
        </Box>
      </Box>
    </>
  )
})
