import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { RETENTLY_TRACKING_ID } from 'shared/config'
import { usersStore } from 'entities/Users'
import { organizationStore } from 'entities/Organization'

export const RetentlyLayout = observer(() => {
  useEffect(() => {
    if (!usersStore.user) return

    const id = 'retently-jssdk'

    const js = document.createElement('script')
    const rjs = document.getElementsByTagName('script')[0]

    js.id = id
    js.src = 'https://cdn.retently.com/public/components/embed/sdk.min.js'

    rjs?.parentNode?.insertBefore(js, rjs)
  }, [usersStore.user, RETENTLY_TRACKING_ID])

  if (!usersStore.user || !RETENTLY_TRACKING_ID || usersStore.accountExistingDays < 14) return null

  const { first_name, last_name, email, id } = usersStore.user
  const { name: organizationName, id: organizationId } = organizationStore

  return (
    <div
      id='retently-survey-embed'
      data-href={`https://app.retently.com/api/remote/tracking/${RETENTLY_TRACKING_ID}`}
      data-rel='dialog'
      data-campaign='regular'
      data-email={email}
      data-firstname={first_name}
      data-lastname={last_name}
      data-company={organizationName}
      data-prop-Organization_ID={organizationId}
      data-prop-User_ID={id}
    ></div>
  )
})
