import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import { ContainerHeader, Typography } from 'shared/ui'
import { useUnsavedChanges } from 'shared/hooks'
import { AdminDetailPage, AdminRoutesEnum } from 'pages/admin'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import { BusinessProfile } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BusinessProfile/BusinessProfile'
import { BrandInformation } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BrandInformation/BrandInformation'
import { Campaign } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/Campaign'
import { AdminHeaderOrganizationActions } from 'pages/admin/pages/compliance'

export const TenDLCDetails = observer(() => {
  const { id } = useParams()
  const {
    loading,
    setId,
    onOpenRejectModal,
    onOpenReviewModal,
    isHasUnsavedChanges,
    organizationById,
    disabledReview,
    isActions,
  } = adminTenDLCDetailStore

  useEffect(() => {
    if (id) {
      setId(Number(id))
    }
  }, [id])

  useUnsavedChanges(isHasUnsavedChanges)

  return (
    <AdminDetailPage
      skippedCountLastRoutesForLabel={1}
      loading={loading}
      header={
        <ContainerHeader
          customTitle={
            <>
              <a
                href={`/${AdminRoutesEnum.admin}/${AdminRoutesEnum.organizations}/${id}`}
                rel={'noopener noreferrer'}
                target={'_blank'}
                className={'noTextDecoration'}
              >
                <Typography variant={'body-lg-medium'} link ariaLabel={'name'}>
                  {organizationById?.name}
                </Typography>
              </a>
              <Typography variant={'body-lg-medium'} ariaLabel={'10DLC'}>
                10DLC
              </Typography>
            </>
          }
          titleIconProps={{
            icon: 'users',
            fontSize: 20,
            tertiary: true,
          }}
          actions={
            isActions
              ? [
                  {
                    text: 'Manual reject',
                    onAction: onOpenRejectModal,
                    medium: true,
                  },
                  {
                    text: 'Review and publish',
                    icon: 'twilio',
                    onAction: onOpenReviewModal,
                    medium: true,
                    buttonProps: {
                      contained: 'primary',
                      disabled: disabledReview,
                    },
                  },
                ]
              : undefined
          }
          actionsGap={8}
        />
      }
      headerActions={<AdminHeaderOrganizationActions organizationById={organizationById} />}
      info={
        <>
          <BusinessProfile />
          <BrandInformation />
        </>
      }
      content={<Campaign />}
    ></AdminDetailPage>
  )
})
