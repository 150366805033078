import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Icon } from 'shared/ui/Icon/Icon'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export interface IRemovableAttachmentProps {
  isGray?: boolean
  isNoteMode?: boolean
  onRemove: () => void
}

export const RemovableAttachment: FC<PropsWithChildren<IRemovableAttachmentProps>> = ({
  isGray,
  children,
  isNoteMode,
  onRemove,
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.gray]: isGray,
        [styles.noteMode]: isNoteMode,
      })}
      data-name='attachmentRemovable'
      aria-label={getAriaLabel('RemovableAttachment')}
    >
      <div className={styles.content} aria-label={getAriaLabel('RemovableAttachment', 'children')}>
        {children}
      </div>
      <button
        className={styles.removeButton}
        onClick={onRemove}
        aria-label={getAriaLabel('RemovableAttachment', 'removeButton')}
      >
        <Icon icon={'close'} fontSize={12} />
      </button>
    </div>
  )
}
