// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UOafiO7kqWtVmKqhNFBj{display:flex;height:100%;align-items:center}.w8NmjeTdkyUNk9TQeBUZ{margin-left:8px}.LfDouIlLiqhL1fqVrcoe{overflow:hidden;color:var(--content-primary-primary);text-overflow:ellipsis;max-width:120px;white-space:pre;line-height:normal;font-weight:400;font-size:13px;margin-left:8px}.dpXVjY8lLLmmaMtgg3HY{display:flex;align-items:center;margin:0 8px 0 8px;position:relative;width:28px}.dpXVjY8lLLmmaMtgg3HY .w8NmjeTdkyUNk9TQeBUZ{position:absolute;display:block;border-radius:8px;margin-left:0px;border:2px var(--background-primary-inverted-primary) solid}", "",{"version":3,"sources":["webpack://./src/widgets/SearchDropdown/ui/SelectedElement/ContactsElement/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,kBAAA,CAEF,sBACI,eAAA,CAGJ,sBACE,eAAA,CACA,oCAAA,CACA,sBAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,UAAA,CAEA,4CACE,iBAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA,CACA,2DAAA","sourcesContent":[".container {\n  display: flex;\n  height: 100%;\n  align-items: center;\n}\n.avatar {\n    margin-left: 8px;\n}\n\n.selectedLabel {\n  overflow: hidden;\n  color: var(--content-primary-primary);\n  text-overflow: ellipsis;\n  max-width: 120px;\n  white-space: pre;\n  line-height: normal;\n  font-weight: 400;\n  font-size: 13px;\n  margin-left: 8px;\n}\n\n.avatars {\n  display: flex;\n  align-items: center;\n  margin: 0 8px 0 8px;\n  position: relative;\n  width: 28px;\n\n  .avatar {\n    position: absolute;\n    display: block;\n    border-radius: 8px;\n    margin-left: 0px;\n    border: 2px var(--background-primary-inverted-primary) solid;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UOafiO7kqWtVmKqhNFBj",
	"avatar": "w8NmjeTdkyUNk9TQeBUZ",
	"selectedLabel": "LfDouIlLiqhL1fqVrcoe",
	"avatars": "dpXVjY8lLLmmaMtgg3HY"
};
export default ___CSS_LOADER_EXPORT___;
