import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type ContactsQuickLinksStore } from 'widgets/ContactsDetails/ui/ContactsQuickLinks'
import {
  ProviderContactsQuickLinks,
  useContactsQuickLinksContext,
} from 'widgets/ContactsDetails/ui/ContactsQuickLinks/context/contactsQuickLinksContext'
import styles from './styles.module.scss'
import { QuickLinksEmpty } from './QuickLinksEmpty'
import { QuickLinksList } from './QuickLinksList'

const ContactsQuickLinksContent = observer(() => {
  const store = useContactsQuickLinksContext()
  const contactId = store.selectedContactId

  return (
    <div className={styles.wrap}>
      {contactId != null ? <QuickLinksList contactId={contactId} /> : <QuickLinksEmpty />}
    </div>
  )
})

type IContactsQuickLinksProps = {
  store: ContactsQuickLinksStore
}

export const ContactsQuickLinks: FC<IContactsQuickLinksProps> = observer(({ store }) => {
  return (
    <ProviderContactsQuickLinks value={store}>
      <ContactsQuickLinksContent />
    </ProviderContactsQuickLinks>
  )
})
