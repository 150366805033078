import { KeyboardEvent, FC } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Label } from 'shared/ui/Label'
import { Icon, EmptyState } from 'shared/ui'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { type ConversationAssignModalStore } from 'widgets/ConversationAssignModal'
import { ConversationAssignSelectedItem } from 'widgets/ConversationAssignModal/ui/conversationAssignSelectedItem/ConversationAssignSelectedItem'
import { ConversationAssignItem } from 'widgets/ConversationAssignModal/ui/conversationAssignItem/ConversationAssignItem'
import styles from './styles.module.scss'

type IConversationAssignModalContentProps = {
  store: ConversationAssignModalStore
}

export const ConversationAssignModalContent: FC<IConversationAssignModalContentProps> = observer(
  ({ store }) => {
    const {
      openDropdown,
      closeDropdown,
      handleRemoveLastSelected,
      isOpenDropdown,
      availableList,
      hasMoreSelectedItems,
      hasAvailable,
      selectedListView,
      hasSelected,
      onSearch,
      search,
    } = store

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (search === '' && event.key === 'Backspace') {
        handleRemoveLastSelected()
      }
    }

    if (!hasSelected && !isOpenDropdown) {
      return (
        <div onClick={openDropdown}>
          <Label
            label={{
              text: 'Include',
            }}
          />
          <div className={styles.triggerInputWrapper}>
            <TextField
              variant='integrated'
              size='small'
              InputProps={{
                placeholder: 'Select members',
              }}
              className={styles.input}
            />
            <Icon icon={'chevronDown'} color={'var(--content-primary-tertiary)'} />
          </div>
        </div>
      )
    }

    return (
      <div>
        <Label
          label={{
            text: 'Include',
          }}
        />

        <ClickAwayListener onClickAway={closeDropdown}>
          <div
            className={classNames(styles.wrap, {
              [styles.active]: isOpenDropdown,
              [styles.closed]: !isOpenDropdown,
            })}
            onClick={openDropdown}
          >
            <div className={styles.inputWrapper}>
              {hasSelected &&
                selectedListView.map((item) => (
                  <ConversationAssignSelectedItem
                    key={item.id}
                    item={item}
                    count={store.moreSelectedItemsCount}
                    isOpen={store.isOpenDropdown}
                    handleDeleteItem={store.handleDeleteItem}
                  />
                ))}
              {!isOpenDropdown && hasMoreSelectedItems && <ConversationAssignSelectedItem />}
              {isOpenDropdown ? (
                <TextField
                  variant='integrated'
                  size='small'
                  onChange={onSearch}
                  InputProps={{
                    placeholder: 'Search',
                    onKeyDown: handleKeyDown,
                  }}
                  className={styles.input}
                  value={search}
                  mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
                />
              ) : (
                <div className={styles.chevronDownIcon}>
                  <Icon icon={'chevronDown'} color={'var(--content-primary-tertiary)'} />
                </div>
              )}
            </div>
            {isOpenDropdown && (
              <div className={styles.searchWrapper}>
                <div className={styles.list}>
                  {hasAvailable ? (
                    availableList.map((item) => (
                      <ConversationAssignItem
                        key={item.id}
                        item={item}
                        handleAddItem={(item) => {
                          store.handleAddItem(item)
                        }}
                      />
                    ))
                  ) : (
                    <EmptyState noIcon />
                  )}
                </div>
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
    )
  }
)
