import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { Tooltip } from 'shared/ui/Tooltip'
import { IconCopyButton, IconUrlButton } from 'shared/ui/IconButton'

import styles from './styles.module.scss'

export type ICopyWrapperProps = {
  copyValue: string | number | null
  children?: React.ReactNode
  clickableTitle?: boolean
  data?: {
    title: string
    link: string
    label?: string
  }
}

const CopyWrapper: React.FC<ICopyWrapperProps> = ({
  copyValue,
  children,
  clickableTitle,
  data,
}) => {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    const element = titleRef.current
    if (!element) return

    const show = element.scrollWidth > element.clientWidth

    setShowTooltip(show)
  }, [])

  const onOpenUrl = async () => {
    if (!clickableTitle) return

    const url = new URL(`${data?.link}`)
    window.open(url, '_blank')
  }

  if (data) {
    const dataElement = (
      <div className={classnames(styles.wrap, styles.dataWrap)}>
        <div className={styles.header}>
          <div ref={titleRef} className={styles.title} onClick={onOpenUrl}>
            {data.title}
          </div>
        </div>
        <div className={styles.action}>
          {data.link && (
            <IconUrlButton
              showTooltip
              value={data.link}
              tooltipLabel={data.label ? `Open in ${data.label}` : 'Open'}
            />
          )}
          {copyValue && <IconCopyButton show value={copyValue} showTooltip />}
        </div>
      </div>
    )

    return (
      <Tooltip fullWidth placement='left' disableInteractive label={showTooltip ? data.title : ''}>
        {dataElement}
      </Tooltip>
    )
  }

  return (
    <div className={classnames(styles.wrap)}>
      <div>{children}</div>
      <div className={styles.action}>
        {copyValue && <IconCopyButton show value={copyValue} showTooltip />}
      </div>
    </div>
  )
}

export { CopyWrapper }
