import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

export const SendAction = observer(() => {
  const messageFieldStore = useMessageFieldContext()
  const { isVariantNote, sendActionBtnProps, isModeNote, customSendAction } = messageFieldStore

  if (customSendAction) {
    return customSendAction
  }

  if (!sendActionBtnProps?.onClick) {
    return null
  }

  if (isModeNote) {
    return (
      <IconButton
        iconProps={{
          icon: 'send',
          fontSize: 16,
        }}
        color={'passivePrimary'}
        loading={messageFieldStore.loadingSend}
        variant={'contained'}
        disabled={messageFieldStore.disablesSend}
        {...sendActionBtnProps}
      />
    )
  }

  if (isVariantNote) {
    return (
      <IconButton
        icon={messageFieldStore.editId ? 'check1' : 'arrowUpCircle'}
        color={'quaternary'}
        loading={messageFieldStore.loadingSend}
        variant={messageFieldStore.disablesSend ? 'icon' : 'contained'}
        disabled={messageFieldStore.disablesSend}
        {...sendActionBtnProps}
      />
    )
  }
  return (
    <IconButton
      color={'primary'}
      loading={messageFieldStore.loadingSend}
      variant={messageFieldStore.disablesSend ? 'icon' : 'contained'}
      disabled={messageFieldStore.disablesSend}
      icon={'send'}
      {...sendActionBtnProps}
    />
  )
})
