import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { type TwoFaModalStore } from 'widgets/TwoFaModal/store/twoFaModalStore'
import { MethodItem } from './MethodItem'

import styles from './styles.module.scss'

type IProps = {
  store: TwoFaModalStore
}

export const TwoFaMethodModalContent = observer(({ store }: IProps) => {
  const { twoFaSettings } = store

  return (
    <div className={styles.wrap}>
      <Alert
        item={{
          type: 'infoLight',
          title: 'Enable two-factor authentication',
          desc: 'Add a second layer of verification to secure your account',
        }}
      />

      <div className={styles.list}>
        {twoFaSettings?.map((item) => (
          <MethodItem key={item.type} item={item} store={store} />
        ))}
      </div>
    </div>
  )
})
