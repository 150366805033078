import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { toastStore } from 'shared/ui'
import { ChatbotConversation, IChatbotConversationStatus } from 'entities/Chatbot'
import { ChatbotConversationApi } from 'entities/Chatbot/api/conversation'
import { EmojiAction, EnumVariantMessageField, MessageFieldStore } from 'widgets/MessageField'

const getErrorMessage = (error: unknown): string => {
  const commonMessage = 'Something went wrong. Please try again.'

  if (!(error instanceof AxiosError)) return commonMessage

  const payload = error.response?.data ?? {}

  if (payload.status === 'error' && typeof payload.message === 'string') return payload.message

  return commonMessage
}

export class ChatbotDialogStore {
  private _uuid: string | null = null
  private _status: IChatbotConversationStatus | null = null
  private _invalid = false
  private _started = false
  private _loading = false
  private _initialLoading = false

  conversation = new ChatbotConversation()

  message = new MessageFieldStore({
    placeholder: 'Write a message...',
    minHeight: 60,
    variant: EnumVariantMessageField.Default,
    resetOnUnmount: false,
    textLimit: {
      maxLength: 1600,
      message: 'Message should not be longer than 1.600 characters',
    },
    makeActions: () => [
      {
        iconButtonComponent: <EmojiAction />,
      },
    ],
  })

  constructor(private _chatbotId: number) {
    makeAutoObservable(this)
  }

  get started() {
    return this._started
  }

  get completed() {
    return this._status !== null && this._status !== 'in_progress'
  }

  get status() {
    return this._status
  }

  get invalid() {
    return this._invalid
  }

  get loading() {
    return this._loading
  }

  get initialLoading() {
    return this._initialLoading
  }

  invalidate = () => {
    if (!this._started) return

    this._invalid = true
  }

  start = async () => {
    this._loading = true
    this._initialLoading = true

    try {
      const { data } = await ChatbotConversationApi.start(this._chatbotId)

      return runInAction(() => {
        this._status = data.status
        this._uuid = data.session_uuid
        this.conversation.addOrUpdate(data.chat)

        this._started = true
        this._loading = false
        this._initialLoading = false

        return true
      })
    } catch (error) {
      console.log(error)

      const message = getErrorMessage(error)

      toastStore.add({
        title: message,
        type: 'error',
      })

      return runInAction(() => {
        this._started = false
        this._loading = false
        this._initialLoading = false

        return false
      })
    }
  }

  send = async (message: string) => {
    if (!this._uuid) return false

    this._loading = true

    try {
      const { data } = await ChatbotConversationApi.message(this._uuid, message)

      return runInAction(() => {
        this._status = data.status
        this._uuid = data.session_uuid
        this.conversation.addOrUpdate(data.chat)

        this._loading = false

        return true
      })
    } catch (error) {
      console.log(error)

      const message = getErrorMessage(error)

      toastStore.add({
        title: message,
        type: 'error',
      })

      return runInAction(() => {
        this._loading = false

        return false
      })
    }
  }

  restart = () => {
    this._uuid = null
    this._status = null
    this._invalid = false

    this.conversation.clear()
    this.message.clear()

    return this.start()
  }

  clear = () => {
    this._uuid = null
    this._status = null
    this._invalid = false
    this._started = false
    this._loading = false
    this._initialLoading = false

    this.conversation.clear()
    this.message.clear()
  }

  dispose = () => {}
}
