import { observer } from 'mobx-react-lite'
import { IconButton, Typography } from 'shared/ui'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { DownloadPreviewBtn } from 'widgets/PresentationMode/ui/info/ui/buttons/DownloadPreviewBtn'
import { InfoWrap } from 'widgets/PresentationMode/ui/info/ui/InfoWrap/InfoWrap'
import styles from './styles.module.scss'

export const InfoImagePreview = observer(() => {
  const { imageControllerStore } = presentationStore
  const { scalePercent, onScaleMinus, onScalePlus } = imageControllerStore

  return (
    <InfoWrap
      centerActions={
        <>
          <IconButton
            disabled={imageControllerStore.isFirst}
            icon={'minusSquare'}
            color={'secondary'}
            variant={'icon'}
            onClick={onScaleMinus}
            ariaLabel='InfoImagePreview_scaleMinus'
          />
          <div className={styles.scalePercent}>
            <Typography variant={'body-md-regular'} ariaLabel={'scalePercent'}>
              {scalePercent}
            </Typography>
          </div>
          <IconButton
            disabled={imageControllerStore.isLast}
            icon={'plusSquare'}
            color={'secondary'}
            variant={'icon'}
            onClick={onScalePlus}
            ariaLabel='InfoImagePreview_scalePlus'
          />
        </>
      }
      rightActions={
        <>
          <DownloadPreviewBtn />
        </>
      }
    />
  )
})
