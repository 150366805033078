import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Avatar, IColumn, NameValueRecord, Typography } from 'shared/ui'
import { DayjsFormats, numberFormat } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { EmptyCell, ExpandCell } from 'shared/ui/Table'
import { links } from 'shared/constants/links'
import { BroadcastStatus } from 'entities/Broadcast/ui/BroadcastStatus'
import {
  BroadcastRecurringLabel,
  BroadcastType,
  IBroadcast,
  BroadcastRecurring,
  BroadcastOneTime,
} from 'entities/Broadcast'
import { IBroadcastType } from 'entities/Broadcast/api/types'
import styles from './styles.module.scss'

const ringlessColumnsIds = [
  'name',
  'type',
  'status',
  'sent_at',
  'total_count',
  'success_count',
  'reply_count',
  'undelivered',
  'send_from',
  'created_by',
]

export const getColumns = (type: IBroadcastType): IColumn<IBroadcast>[] => {
  const allColumns: IColumn<IBroadcast>[] = [
    {
      field: 'expandedColumn',
      width: '40px',
      minWidth: '40px',
      renderRowCell: (row) => <ExpandCell row={row} />,
      renderHeaderCell: () => null,
      getIsDisableLink: (row) => !!row.toggleExpand,
      isHideForManage: true,
      padding: 0,
    },
    {
      isSortable: true,
      field: 'name',
      name: 'Name',
      width: '100%',
      minWidth: '362px',
      maxWidth: '362px',
      isDisabledChangeVisible: true,
      paddingInExpand: '32px',
      renderRowCell: (row) => <NameCell row={row} />,
    },
    {
      isSortable: true,
      field: 'type',
      name: 'Type',
      width: '72px',
      isDisabledChangeVisible: true,
      renderRowCell: (row) => <BroadcastType type={row.type} />,
    },
    {
      isSortable: true,
      field: 'status',
      name: 'Status',
      isDisabledChangeVisible: true,
      renderRowCell: (row) => row.status && <BroadcastStatus status={row.status} />,
    },
    {
      isSortable: true,
      sortKey: 'send_time',
      field: 'sent_at',
      name: 'Send time',
      renderRowCell: (row) =>
        row instanceof BroadcastOneTime ? (
          (row.send_at || row.sent_at) &&
          uiStore.dayjs(row.send_at || row.sent_at).format(DayjsFormats.fullWithAtDash2)
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      sortKey: 'total',
      field: 'total_count',
      name: 'Total',
      renderRowCell: (row) =>
        row instanceof BroadcastOneTime ? (
          row.statistics?.total_count && numberFormat({ value: row.statistics?.total_count })
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      sortKey: 'delivered',
      field: 'success_count',
      name: 'Delivered',
      renderRowCell: (row, _, { isHover }) =>
        row instanceof BroadcastOneTime ? (
          <StatisticCell
            isHover={isHover}
            value={row.statistics?.success_count}
            percent={row.statistics?.success_percent}
          />
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      sortKey: 'replies',
      field: 'reply_count',
      name: 'Replies',
      renderRowCell: (row, _, { isHover }) =>
        row instanceof BroadcastOneTime ? (
          <StatisticCell
            isHover={isHover}
            value={row.statistics?.reply_count || 0}
            percent={row.statistics?.reply_percent}
          />
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      field: 'clicks',
      name: 'Clicks',
      renderRowCell: (row, _, { isHover }) =>
        row instanceof BroadcastOneTime ? (
          <StatisticCell
            isEmpty={row.type === 'ringless' || row.statistics?.clicks_count === null}
            isHover={isHover}
            value={row.statistics?.clicks_count}
            percent={row.statistics?.clicks_percent}
          />
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      field: 'conversions',
      name: 'Conversions',
      renderRowCell: (row, _, { isHover }) =>
        row instanceof BroadcastOneTime ? (
          <StatisticCell
            isEmpty={row.type === 'ringless' || row.statistics?.conversions_count === undefined}
            isHover={isHover}
            value={row.statistics?.conversions_count}
            percent={row.statistics?.conversions_percent}
          />
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      sortKey: 'revenue',
      field: 'revenue_cost',
      name: 'Revenue',
      tooltipQuestionProps: {
        width: 221,
        placement: 'top',
        label: [
          'Use Conversion Tracking to track the revenue your Broadcasts generate. ',
          <Typography key={'link'} variant={'body-md-medium'} linkLight ariaLabel={'LearnMore'}>
            <a href={links.conversionRevenueTracking} target={'_blank'} rel='noreferrer'>
              Learn more
            </a>
          </Typography>,
        ],
      },
      renderRowCell: (row) =>
        row instanceof BroadcastOneTime ? row.statistics?.revenue_cost : undefined,
    },
    {
      isSortable: true,
      field: 'undelivered',
      name: 'Undelivered',
      isHideByDefault: type !== 'rvm',
      renderRowCell: (row, _, { isHover }) =>
        row instanceof BroadcastOneTime ? (
          <StatisticCell
            isHover={isHover}
            value={row.statistics?.undelivered_count}
            percent={row.statistics?.undelivered_percent}
          />
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      field: 'unsubscribed',
      name: 'Unsubscribed',
      isHideByDefault: true,
      renderRowCell: (row, _, { isHover }) =>
        row instanceof BroadcastOneTime ? (
          <StatisticCell
            isHover={isHover}
            isEmpty={row.type === 'ringless'}
            value={row.statistics?.unsubscribed_count || 0}
            percent={row.statistics?.unsubscribed_percent}
          />
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      field: 'send_from',
      name: 'Send from',
      isHideByDefault: true,
      renderRowCell: (row) =>
        row instanceof BroadcastOneTime ? (
          row.sendFromNumbersProps && <NameValueRecord {...row.sendFromNumbersProps[0]} />
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'created_by',
      name: 'Created by',
      isHideByDefault: true,
      renderRowCell: (row) => {
        if (!row.user?.avatarInfo && !row.user?.displayInfo) return <EmptyCell />
        return (
          <Box display={'flex'} gap={'6px'}>
            {row.user?.avatarInfo && <Avatar info={row.user?.avatarInfo} size={16} />}
            <Typography
              variant={'body-md-regular'}
              ellipsis
              tooltipProps={{ label: row.user?.displayInfo }}
              ariaLabel={'displayInfo'}
            >
              {row.user?.displayInfo}
            </Typography>
          </Box>
        )
      },
    },
  ]
  if (type === 'rvm') {
    return allColumns.filter((column) => ringlessColumnsIds.includes(column.field))
  }
  if (type === 'sms') {
    return allColumns.filter((column) => column.field !== 'expandedColumn')
  }
  if (type === 'recurring') {
    return allColumns.map((column) => ({ ...column, isSortable: column.field === 'name' }))
  }
  return allColumns
}

const NameCell = observer(({ row }: { row: IBroadcast }) => (
  <div className={styles.nameCellWrapper}>
    <Typography
      variant={'body-rg-regular'}
      ellipsis
      tooltipProps={{
        label: row.name,
      }}
      ariaLabel={'name'}
    >
      {row.name}
    </Typography>
    {row instanceof BroadcastRecurring && <BroadcastRecurringLabel broadcast={row} />}
  </div>
))

const StatisticCell = ({
  isEmpty,
  isHover,
  value,
  percent,
}: {
  isEmpty?: boolean | undefined
  isHover: boolean | undefined
  value: number | undefined | null
  percent: number | undefined
}) => {
  if (isEmpty) {
    return <EmptyCell />
  }
  return <>{isHover ? `${percent}%` : value && numberFormat({ value })}</>
}
