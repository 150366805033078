// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pcJCfvziaxKP8GfWqYuJ{width:100%}.NLYsMf1do1mNIUyTu8uD{position:relative}.ZYAmmTIEpMfJLz5ojMXd{margin:-8px -3px;height:16px}.VshA6bas_zhdypFFWZJm{position:relative}.PWYWFC5FSsLJimuJ9EZY{margin-bottom:27px}.PWYWFC5FSsLJimuJ9EZY:last-of-type{margin-bottom:0}.lTp3ZEpQyou2DrJ3CF_l{display:flex;align-items:center;color:var(--content-negative-primary);font-size:11px;font-weight:400;padding-top:5px;margin-bottom:-9px}.SU_wDx6PUkZD0Z4N_tBk{margin-right:5px}", "",{"version":3,"sources":["webpack://./src/widgets/CallSearchContacts/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,iBAAA,CAEA,sBACE,gBAAA,CACA,WAAA,CAGF,sBACE,iBAAA,CAIJ,sBACE,kBAAA,CAEA,mCACE,eAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,qCAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CAEA,sBACE,gBAAA","sourcesContent":[".wrap {\n  width: 100%;\n}\n\n.input {\n  position: relative;\n\n  &__button {\n    margin: -8px -3px;\n    height: 16px;\n  }\n\n  &__wrap {\n    position: relative;\n  }\n}\n\n.section {\n  margin-bottom: 27px;\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n}\n\n.error {\n  display: flex;\n  align-items: center;\n  color: var(--content-negative-primary);\n  font-size: 11px;\n  font-weight: 400;\n  padding-top: 5px;\n  margin-bottom: -9px;\n\n  &__icon {\n    margin-right: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "pcJCfvziaxKP8GfWqYuJ",
	"input": "NLYsMf1do1mNIUyTu8uD",
	"input__button": "ZYAmmTIEpMfJLz5ojMXd",
	"input__wrap": "VshA6bas_zhdypFFWZJm",
	"section": "PWYWFC5FSsLJimuJ9EZY",
	"error": "lTp3ZEpQyou2DrJ3CF_l",
	"error__icon": "SU_wDx6PUkZD0Z4N_tBk"
};
export default ___CSS_LOADER_EXPORT___;
