import { observer } from 'mobx-react-lite'
import { contactsStore } from 'entities/Contacts'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { ConversationsHistoryMessages } from 'widgets/ConversationsHistoryMessages'

export const ContactsConversationsHistory = observer(() => {
  const store = useContactsDetailsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)

  if (!contact) return null

  return (
    <ConversationsHistoryMessages
      isModalView={store.isVariantCreateContactModal}
      store={store.conversationsHistoryMessagesStore}
      contact={contact}
    />
  )
})
