// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o0dIxT5O6O1BqqWnXZAA{max-height:100%;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%) scale(1.25);z-index:3001;opacity:0;border-radius:4px}.fIC9XnWA7oE2kIid0ZMV{opacity:1}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/content/image/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CACA,QAAA,CACA,OAAA,CACA,2CAAA,CACA,YAAA,CACA,SAAA,CACA,iBAAA,CAGF,sBACE,SAAA","sourcesContent":[".img{\n  max-height: 100%;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%) scale(1.25);\n  z-index: 3001;\n  opacity: 0;\n  border-radius: 4px;\n}\n\n.ready {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "o0dIxT5O6O1BqqWnXZAA",
	"ready": "fIC9XnWA7oE2kIid0ZMV"
};
export default ___CSS_LOADER_EXPORT___;
