import { UIEvent, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { conversationStore } from 'entities/Conversation'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { Attachments } from 'widgets/Attachments'
import styles from './styles.module.scss'

export const AttachmentContent = observer(() => {
  const messageFieldStore = useMessageFieldContext()
  const refWrap = useRef<HTMLDivElement>(null)
  const refContent = useRef<HTMLDivElement>(null)
  const refScroll = useRef<HTMLDivElement>(null)
  const [widthContent, setWidthContent] = useState(0)
  const refHoverInner = useRef(false)

  const onScroll = (e: UIEvent) => {
    const target = e.target as HTMLDivElement

    if (refWrap.current && !refHoverInner.current && refScroll.current) {
      refWrap.current.scrollLeft =
        (target.scrollLeft * refScroll.current.scrollWidth) / target.scrollWidth
    }
  }

  const onInnerScroll = (e: UIEvent) => {
    const target = e.target as HTMLDivElement

    if (refScroll.current && refHoverInner.current && refWrap.current) {
      refScroll.current.scrollLeft = target.scrollLeft === 0 ? 0 : target.scrollLeft + 24
    }
  }
  const scrollPadding = messageFieldStore.isVariantBroadcast ? 0 : 16

  useEffect(() => {
    if (refContent.current) {
      setWidthContent(refContent.current?.scrollWidth - scrollPadding)
    }
  }, [messageFieldStore.attachments.length])

  if (!messageFieldStore.attachments.length) {
    return <></>
  }

  const removeAttachments = (ids: Array<string | number>) => {
    const { deleteItemDraft, cancelDebounceOnCreateDraft } = conversationStore
    messageFieldStore.removeAttachments(ids)
    cancelDebounceOnCreateDraft()

    ids.forEach((id) => {
      deleteItemDraft(+id)
    })
  }

  return (
    <div>
      <div
        className={classnames(styles.wrap, {
          [styles.isBroadcast]: messageFieldStore.isVariantBroadcast,
        })}
        ref={refWrap}
        onScroll={onInnerScroll}
      >
        <div
          className={styles.inner}
          ref={refContent}
          onMouseEnter={() => (refHoverInner.current = true)}
          onMouseLeave={() => (refHoverInner.current = false)}
        >
          <div className={styles.box4} />
          <Attachments
            attachments={messageFieldStore.attachments}
            removeAttachments={removeAttachments}
          />
          <div className={styles.box4} />
        </div>
      </div>
      <div
        className={classnames(styles.scroll, {
          [styles.isBroadcast]: messageFieldStore.isVariantBroadcast,
        })}
        onScroll={onScroll}
        ref={refScroll}
      >
        <div style={{ width: widthContent }} />
      </div>
    </div>
  )
})
