import { observer } from 'mobx-react-lite'
import { CommonGeneralInfo } from 'pages/settings/pages/compliance/ui/CommonGeneralInfo/CommonGeneralInfo'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'

export const GeneralInfo = observer(() => {
  const {
    stepLoading,
    generalInfoStore,
    errorStore,
    complianceLayerStore,
    generalInfoNextStepClick,
  } = useLocalNumbersStepsContext()

  return (
    <CommonGeneralInfo
      stepLoading={stepLoading}
      generalInfoStore={generalInfoStore}
      fieldsError={errorStore.fieldsError}
      errorMessage={complianceLayerStore.complianceServiceBrand?.registrationError?.message}
      fieldsErrorCommonText={errorStore.fieldsErrorCommonText}
      nextStepClick={generalInfoNextStepClick}
      openDoNotHaveEINModal={complianceLayerStore.openDoNotHaveEINModal}
    />
  )
})
