import { FC, useMemo, useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, type IIconsVector } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IntegrationKey,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import type { IntegrationDealProperty } from 'entities/Integrations/model'
import { ShowMoreAction } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction/ui/ShowMoreAction'
import { ShowMoreActionStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import { Actions } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/Actions'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { SectionTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/SectionTab/SectionTab'
import { type DraggableIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/store/draggableIntegrationStore'
import { AddPropertyAction } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/AddPropertyAction/AddPropertyAction'
import { NewPropertiesDropdownStore } from 'widgets/NewPropertiesDropdown'

type IDealsProps = {
  integrationLabel: string
  integrationKey: IntegrationKey
  integrationIcon: IIconsVector
  deals: IntegrationDealProperty[]
  contact: Contact
  action?: ReactNode
  dnd?: DraggableIntegrationStore
}

export const Deals: FC<IDealsProps> = observer(
  ({ integrationLabel, integrationKey, integrationIcon, deals, contact, action, dnd }) => {
    const showMoreActionStore = useMemo(
      () => new ShowMoreActionStore(integrationKey, EnumIntegrationTabs.Deals),
      []
    )
    const newPropertiesDropdownStore = useMemo(() => new NewPropertiesDropdownStore(), [])
    const deal = deals[0] || null
    const store = useContactsIntegrationsContext()
    const integration = integrationsStore.getIntegration(integrationKey)
    const linkProps = {
      key: integrationKey,
      contact: contact,
      integration: integration,
      type: IIntegrationLinkTypes.deal,
    }

    const link = (id?: number | string | null) => {
      return integrationLink({
        ...linkProps,
        id: id,
      })
    }

    const handleView = () => {
      store.openView('deals', { deals }, integrationIcon, integrationKey)
    }

    const propertiesKeys = deal?.propertiesKeys || []

    useEffect(() => {
      if (!deal) return

      newPropertiesDropdownStore.searchFields('', true, 'deal', deal.id)
      newPropertiesDropdownStore.setConfig({
        contactId: contact.id,
        addItem: (item) => {
          deal.addProperty(item)
          dnd?.addItem(item.key)
        },
        items: propertiesKeys,
      })
    }, [propertiesKeys.length, deal])

    const actions = () => {
      const hasMore = deals.length > 1

      return (
        <Actions
          rows={[
            {
              columns: [
                action,
                dnd && (
                  <AddPropertyAction
                    store={newPropertiesDropdownStore}
                    key={'add_property_action'}
                    objectType={'deal'}
                    objectId={deal?.id}
                  />
                ),
              ],
            },
            {
              columns: [
                !showMoreActionStore.hideShowMore && (
                  <ShowMoreAction
                    key={'show_more'}
                    disabled={!contact}
                    store={showMoreActionStore}
                  />
                ),
                hasMore && (
                  <Button size='small' typeBtn='text' text='See all deals' onClick={handleView} />
                ),
              ],
            },
          ]}
        />
      )
    }

    const properties = showMoreActionStore.modifyData(deal?.properties || [])

    return (
      <SectionTab
        item={deal}
        titleView={'View deal'}
        titleEmpty={'No deals found'}
        integrationLabel={integrationLabel}
        properties={properties}
        link={link(deal?.id)}
        actions={actions()}
        handleClear={(id) => {
          deal?.removeProperty(id)
          dnd?.removeItem(id)
        }}
        handleReorder={(result) => {
          dnd?.updateItems(result.source.index, result.destination?.index)
        }}
        itemsDnD={dnd?.itemsUI}
      />
    )
  }
)
