// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Othx8Dp6gLySDfHXDYzL{margin-top:24px;display:flex;flex-direction:column;padding-top:4px;padding-inline:16px;flex-grow:1;padding-bottom:24px}.tloG1cN6Va6LlBpIo67H{position:relative;display:flex;flex-grow:1}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/organizations/pages/OrganizationDetails/ui/TeamMembersTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CAEA,mBAAA,CACA,WAAA,CACA,mBAAA,CAEF,sBAEE,iBAAA,CACA,YAAA,CACA,WAAA","sourcesContent":[".wrap{\n  margin-top: 24px;\n  display: flex;\n  flex-direction: column;\n  padding-top: 4px;\n  //padding-bottom: 24px;\n  padding-inline: 16px;\n  flex-grow: 1;\n  padding-bottom: 24px;\n}\n.wrapTable{\n  //height: 200px;\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Othx8Dp6gLySDfHXDYzL",
	"wrapTable": "tloG1cN6Va6LlBpIo67H"
};
export default ___CSS_LOADER_EXPORT___;
