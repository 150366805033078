import { type Contact } from 'entities/Contacts/model/Contact'
import { type Segment } from 'entities/Segment/model/Segment'
import { type Tag } from 'entities/Tags/model/Tag'

export enum EnrollmentItemType {
  Tag = 'tag',
  Segment = 'segment',
  Contact = 'contact',
  Filter = 'filter',
}

export type EnrollmentItemMap = {
  [EnrollmentItemType.Tag]: Tag
  [EnrollmentItemType.Segment]: Segment
  [EnrollmentItemType.Contact]: Contact
  [EnrollmentItemType.Filter]: Segment
}

export type IEnrollmentPayload<T extends EnrollmentItemType> = {
  type: T
  payload: EnrollmentItemMap[T]
}

export type IEnrollmentPayloadContact = IEnrollmentPayload<EnrollmentItemType.Contact>
export type IEnrollmentPayloadSegment = IEnrollmentPayload<EnrollmentItemType.Segment>
export type IEnrollmentPayloadTag = IEnrollmentPayload<EnrollmentItemType.Tag>
export type IEnrollmentPayloadFilter = IEnrollmentPayload<EnrollmentItemType.Filter>

export type IEnrollmentItem =
  | IEnrollmentPayloadContact
  | IEnrollmentPayloadSegment
  | IEnrollmentPayloadTag
  | IEnrollmentPayloadFilter

export type IEnrollmentSearchProps = {
  onCancel: () => void
  onSelect: (item: IEnrollmentItem) => void
}

export type IEnrollmentFiltersParams = {
  tags: Tag[]
  filters: Segment[]
  contacts: Contact[]
  segments: Segment[]
  number_id?: number | null
}
