import React, {
  ComponentPropsWithRef,
  createElement,
  CSSProperties,
  forwardRef,
  ReactNode,
  Ref,
  useMemo,
} from 'react'
import classnames from 'classnames'
import type { Property } from 'csstype'
import type { IBadgeProps, IBadgeVariant, IIconProps, IIconsVector, ITooltipProps } from 'shared/ui'
import { Tooltip } from 'shared/ui/Tooltip'
import { Icon } from 'shared/ui/Icon'
import { Badge } from 'shared/ui/Badge'
import { SpinnerLoader } from 'shared/ui/Loader'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type IButtonProps<T extends keyof HTMLElementTagNameMap = 'button'> =
  ComponentPropsWithRef<T> & {
    tag?: T
    intent?: 'default' | 'positive' | 'destructive' | 'passive' | 'warning'
    size?: 'large' | 'medium' | 'small' | 'mini' | 'extraLarge'
    fontWeight?: number
    typeBtn?:
      | 'contained'
      | 'outlined'
      | 'select'
      | 'text'
      | 'menuItem'
      | 'switchBtn'
      | 'inbox'
      | 'filter'
      | 'link'
      | 'action'
      | 'stroke'
      | 'informative'
    contained?: 'primary' | 'secondary' | 'tertiary'
    inverted?: boolean
    disabled?: boolean
    text?: ReactNode
    textRight?: ReactNode
    textIcon?: IIconsVector
    textClassname?: string
    icon?: IIconsVector
    iconRight?: IIconsVector
    iconProps?: IIconProps
    iconRightProps?: IIconProps
    tooltipProps?: ITooltipProps
    count?: number
    hasCount?: boolean
    isRight?: boolean
    active?: boolean
    iconClassName?: string
    iconSize?: number
    paddingInline?: CSSProperties['paddingInline']
    titleContent?: ReactNode
    fullWidth?: boolean
    justifyContent?: Property.JustifyContent
    loading?: boolean
    badgeProps?: IBadgeProps
  }

export type IButton = <T extends keyof HTMLElementTagNameMap = 'button'>(
  props: IButtonProps<T>
) => JSX.Element | null

const Button = forwardRef(function ButtonForwardRef<
  T extends keyof HTMLElementTagNameMap = 'button'
>(
  {
    tag,
    intent = 'default',
    size = 'medium',
    typeBtn = 'contained',
    contained = 'primary',
    disabled = false,
    text,
    textRight,
    textIcon,
    textClassname,
    icon,
    iconRight,
    count = 0,
    hasCount = false,
    isRight = false,
    inverted,
    className,
    active,
    iconClassName,
    iconSize,
    paddingInline,
    justifyContent,
    fontWeight,
    titleContent,
    fullWidth,
    loading,
    tooltipProps,
    iconProps,
    iconRightProps,
    badgeProps,
    color,
    ...props
  }: IButtonProps<T>,
  ref: Ref<HTMLElementTagNameMap[T]>
) {
  const variantBadge: IBadgeVariant =
    typeBtn === 'filter' ? 'sky' : contained === 'primary' ? 'grey' : 'blue'

  const commonClasses = classnames(
    styles.wrap,
    styles[`intent__${intent}`],
    styles[typeBtn],
    styles[size],
    {
      [styles[contained]]:
        typeBtn !== 'menuItem' &&
        typeBtn !== 'switchBtn' &&
        typeBtn !== 'inbox' &&
        typeBtn !== 'link' &&
        typeBtn !== 'filter' &&
        typeBtn !== 'action',
      [styles.disabled]: disabled,
      [styles.inverted]: inverted,
      [styles.active]: active,
      [styles.fullWidth]: fullWidth,
      [styles.loading]: loading,
    },
    className
  )

  const ariaLabel = props['aria-label'] || `Button${text}`
  const iconContent = useMemo(() => {
    if (loading) {
      const loadingSpinnerSize = iconSize ? iconSize - 2 : 14

      const determinateColor = (() => {
        if (typeBtn === 'contained' && contained === 'primary') {
          return 'var(--white-40)'
        }
        return 'var(--gray-70)'
      })()
      const indeterminateColor = (() => {
        if (typeBtn === 'contained' && contained === 'primary') {
          return 'var(--white-100)'
        }
        return 'var(--gray-30)'
      })()

      return (
        <div
          className={classnames(styles.icon, iconClassName, styles.loadingContainer)}
          aria-label={getAriaLabel(ariaLabel, 'spinner')}
        >
          <SpinnerLoader
            size={loadingSpinnerSize}
            determinatecolor={determinateColor}
            indeterminatecolor={indeterminateColor}
          />
        </div>
      )
    }

    if (!!icon || !!iconProps) {
      return (
        <div
          className={classnames(styles.icon, iconClassName)}
          aria-label={getAriaLabel(ariaLabel, 'icon')}
        >
          <Icon icon={icon} fontSize={iconSize} {...iconProps} />
        </div>
      )
    }
    return null
  }, [loading, icon, iconProps, iconSize])

  const iconRightContent = iconRight ? (
    <div
      className={classnames(styles.icon, styles.iconRight, iconClassName)}
      aria-label={getAriaLabel(ariaLabel, 'rightIcon')}
    >
      <Icon icon={iconRight} fontSize={iconSize} {...iconProps} />
    </div>
  ) : (
    !!iconRightProps &&
    !loading && (
      <div className={classnames(styles.rightIcon)} aria-label={getAriaLabel(ariaLabel, 'icon')}>
        <Icon icon={iconRightProps.icon} fontSize={iconRightProps.fontSize} {...iconRightProps} />
      </div>
    )
  )

  let btn: React.ReactNode = null

  const btnTextClassName = classnames(styles.text, textClassname)

  if (isRight) {
    btn = createElement(
      tag ?? 'button',
      {
        ...props,
        className: classnames(commonClasses, styles.hasCount, styles.isRight),
        style: { ...props.style, justifyContent, paddingInline, fontWeight },
        'aria-label': getAriaLabel(ariaLabel),
        ref,
      },
      <>
        {titleContent}
        {text && (
          <div className={btnTextClassName} aria-label={getAriaLabel(ariaLabel, 'text')}>
            {textIcon && <Icon icon={textIcon} />}
            {text}
          </div>
        )}
        {hasCount && (
          <div className={styles.badge} aria-label={getAriaLabel(ariaLabel, 'count')}>
            <Badge content={count} showAll variant={variantBadge} {...badgeProps} />
          </div>
        )}
        <div className={styles.wrapRightContent}>
          {textRight}
          {iconContent}
        </div>
      </>
    )
  } else {
    btn = createElement(
      tag ?? 'button',
      {
        ...props,
        className: classnames(commonClasses),
        style: { ...props.style, justifyContent, paddingInline, fontWeight, color },
        'aria-label': getAriaLabel(ariaLabel),
        ref,
      },
      <>
        {iconContent}
        {titleContent}
        {text && (
          <div className={btnTextClassName} aria-label={getAriaLabel(ariaLabel, 'text')}>
            {textIcon && <Icon icon={textIcon} />}
            {text}
          </div>
        )}

        {hasCount && (
          <div className={styles.badge} aria-label={getAriaLabel(ariaLabel, 'count')}>
            <Badge content={count} showAll variant={variantBadge} {...badgeProps} />
          </div>
        )}
        {iconRightContent}

        {textRight && <div className={styles.textRight}>{textRight}</div>}
      </>
    )
  }

  if (tooltipProps) {
    return <Tooltip {...tooltipProps}>{btn}</Tooltip>
  }

  return <>{btn}</>
}) as IButton

export { Button }
