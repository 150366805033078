import { makeAutoObservable } from 'mobx'
import {
  ChatbotActionType,
  ChatbotField,
  IChatbotActionTag,
  IChatbotFullData,
} from 'entities/Chatbot'
import { CustomFieldsStore } from 'entities/CustomField'
import { ContactsApi } from 'entities/Contacts'
import { TagsApi } from 'entities/Tags'
import { Tag } from 'entities/Tags/model/Tag'
import { getSendFromInfo, ISendFromInfo } from 'features/SendFrom'

const FIELDS_LIMIT = 100

export class DetailsStore {
  private _fields!: ChatbotField[]
  private _fieldsStore = new CustomFieldsStore(FIELDS_LIMIT)

  private _futureSendFrom!: Promise<ISendFromInfo | null>
  private _futureContactsCount!: Promise<number | null>
  private _futureActionsTags = new Map<number[], Promise<Tag[]>>()

  constructor(private _data: IChatbotFullData) {
    this._initFields()
    this._initSendFrom()
    this._initContactsCount()
    this._initActionTags()

    makeAutoObservable(this as object, {
      _data: false,
    })
  }

  get data() {
    return this._data
  }

  get fields() {
    return this._fields
  }

  get futureSendFrom() {
    return this._futureSendFrom
  }

  get futureFutureContactsCount() {
    return this._futureContactsCount
  }

  getFutureActionsTags = (ids: number[]) => {
    return this._futureActionsTags.get(ids) ?? Promise.resolve([])
  }

  getFieldName = (fieldKey: string) => {
    return this._fieldsStore.fields.find(({ key }) => key === fieldKey)?.name
  }

  loadData = () => Promise.all([this._fieldsStore.loadData()])

  private _initFields = () => {
    this._fields = this._data.fields?.map(ChatbotField.Create) ?? []
    if (!this._fields.length) this._fields = [ChatbotField.Create()]
  }

  private _initSendFrom = () => {
    this._futureSendFrom = getSendFromInfo(this._data.send_from)
  }

  private _initContactsCount = () => {
    if (!this._data.filters) return (this._futureContactsCount = Promise.resolve(null))

    const filtersSize = Object.values(this._data.filters)
      .map((filter) => filter.length ?? 0)
      .reduce((prev, next) => prev + next, 0)
    if (!filtersSize) return (this._futureContactsCount = Promise.resolve(null))

    this._futureContactsCount = ContactsApi.getContactsFiltersCount({
      filtersListGroups: this._data.filters,
    }).then(
      ({ data: { count } }) => count,
      () => null
    )
  }

  private _initActionTags = () => {
    const allActions = [
      ...(this._data.success_actions ?? []),
      ...(this._data.fallback_actions ?? []),
    ]

    allActions
      .filter(
        (action) =>
          action.type === ChatbotActionType.AddTag || action.type === ChatbotActionType.RemoveTag
      )
      .map((action) => action.settings as IChatbotActionTag)
      .forEach((settings) => {
        const ids = settings.tag_ids
        const futureTags = TagsApi.getTagsById(ids).then(
          (tags) => tags.map(Tag.Create),
          () => []
        )

        this._futureActionsTags.set(ids, futureTags)
      })
  }
}
