// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u1sQ39PAoM8cN1ewYn1p{width:456px}.u1sQ39PAoM8cN1ewYn1p:hover .MuiSlider-thumb{visibility:visible}.u1sQ39PAoM8cN1ewYn1p .MuiSlider-thumb{visibility:hidden}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/info/ui/MediaProgressBar/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAII,6CACE,kBAAA,CAKL,uCACE,iBAAA","sourcesContent":[".wrap{\n  width: 456px;\n\n  &:hover{\n    :global{\n      .MuiSlider-thumb{\n        visibility: visible;\n      }\n    }\n  }\n :global{\n   .MuiSlider-thumb{\n     visibility: hidden;\n   }\n }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "u1sQ39PAoM8cN1ewYn1p"
};
export default ___CSS_LOADER_EXPORT___;
