import { links } from 'shared/constants/links'
import { Alert, Button } from 'shared/ui'
import { IAlert } from 'shared/ui/Alert/types'
import styles from './styles.module.scss'

type IBroadcastErrorAlertProps = {
  isBottomActions?: boolean
  failed_reason?: string | null
}

export const BroadcastErrorAlert = ({
  isBottomActions,
  failed_reason,
}: IBroadcastErrorAlertProps) => {
  let info: Partial<IAlert> = {
    title: 'An error with your contacts or CRM connection was encountered',
    desc: 'Please validate your contacts and try again.',
  }
  if (failed_reason === 'Selected Hubspot list does not exist') {
    info = {
      title: 'Selected Hubspot list does not exist',
    }
  }
  return (
    <Alert
      item={{
        type: 'alert',
        ...info,
      }}
      rightAction={
        !isBottomActions && (
          <a href={links.massMessagingBestPracticesGuidelines} target={'_blank'} rel='noreferrer'>
            <Button
              text='Learn more'
              typeBtn='outlined'
              size='small'
              contained='secondary'
              className={styles.link}
            />
          </a>
        )
      }
      actions={
        isBottomActions && (
          <a href={links.massMessagingBestPracticesGuidelines} target={'_blank'} rel='noreferrer'>
            <Button
              text='Learn more'
              typeBtn='outlined'
              size='small'
              contained='secondary'
              className={styles.link}
            />
          </a>
        )
      }
    />
  )
}
