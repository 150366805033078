import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { useRinglessMessageFieldContext } from 'widgets/MessageFormFields/RinglessMessageField/context/ringlessMessageFieldContext'

export const RinglessMessageFieldTextToSpeech = observer(() => {
  const { handleTextToSpeech } = useRinglessMessageFieldContext()

  return (
    <IconButton
      ariaLabel={getAriaLabel('TextToSpeech')}
      tooltipProps={{
        label: 'Text to speech',
        placement: 'top',
      }}
      icon={'lineType'}
      size={'medium'}
      color={'secondary'}
      variant={'icon'}
      onClick={handleTextToSpeech}
    />
  )
})
