// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZHRmvNUJ6b1nvyiO4ghD{padding:20px 16px;height:80px;border-top:1px solid rgba(0,0,0,0)}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/customPrices/pages/CustomPricesCreate/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CACA,kCAAA","sourcesContent":[".wrap {\n  padding: 20px 16px;\n  height: 80px;\n  border-top: 1px solid transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ZHRmvNUJ6b1nvyiO4ghD"
};
export default ___CSS_LOADER_EXPORT___;
