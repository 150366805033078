import classnames from 'classnames'
import { IconButton } from 'shared/ui'
import styles from './styles.module.scss'

export type IListItemContentProps = {
  icon: JSX.Element
  name: string
  count?: number | null
}

export const ListItemContent = ({ icon, name, count }: IListItemContentProps) => (
  <div className={styles.listItemContent}>
    <span className={styles.listItemIcon}>{icon}</span>
    <span className={styles.listItemName}>{name}</span>
    <span className={styles.listItemCount}>{count}</span>
  </div>
)

export type IListItemProps = IListItemContentProps & {
  active: boolean
  type: 'filters' | 'tags' | 'segments' | 'contacts'
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onRemove?: () => void
  isViewOnly?: boolean
}

export const ListItem = ({
  icon,
  type,
  name,
  count,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onRemove,
  isViewOnly,
  active,
}: IListItemProps) => {
  return (
    <div
      className={classnames(styles.listItem, styles[`listItem__${type}`], {
        [styles.listItem__active]: active,
      })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ListItemContent icon={icon} name={name} count={count} />

      {!isViewOnly && (
        <div className={styles.listItemAction}>
          <IconButton
            icon={'close'}
            size={'small'}
            color={'secondary'}
            variant={'icon'}
            onClick={(event) => {
              event.stopPropagation()
              onRemove?.()
            }}
            ariaLabel='ListItem_remove'
            tooltipProps={{
              label: 'Remove',
              placement: 'top',
            }}
          />
        </div>
      )}
    </div>
  )
}

export const ListItemPreview = (props: IListItemContentProps) => (
  <div className={styles.listItemPreview}>
    <ListItemContent {...props} />
  </div>
)
