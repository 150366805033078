import React from 'react'
import { IInboxCombine, INodeModel } from 'entities/Inbox'
import {
  MenuCardInbox,
  MenuCardInboxGroup,
} from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/MenuCard'

type PropsMenuItemPreviewTree = {
  node: INodeModel<IInboxCombine>
  onToggle?: () => void
  isOpen?: boolean
}

const MenuCardPreviewTree: React.FC<PropsMenuItemPreviewTree> = ({
  node,
  onToggle = () => {},
  isOpen = false,
}) => {
  const { data, droppable } = node
  const inbox = data

  if (!inbox) return null

  if (inbox.isGroupInbox) {
    return (
      <MenuCardInboxGroup item={inbox} onToggle={onToggle} isOpen={isOpen} droppable={droppable} />
    )
  }

  return <MenuCardInbox item={inbox} />
}

export { MenuCardPreviewTree }
