import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { CallDialpadStore, IDialpadItem } from 'widgets/CallDialpad'
import styles from './styles.module.scss'

type ICallDialpadProps = {
  variant?: 'default' | 'small'
  onChange?: (item: IDialpadItem) => void
}

export const CallDialpad: React.FC<ICallDialpadProps> = observer(
  ({ onChange = () => {}, variant = '' }) => {
    const [store] = useState(() => new CallDialpadStore())

    return (
      <div className={classnames(styles.dialpad, variant && styles[variant])}>
        {store.items.map((item) => {
          return (
            <div
              key={item.key}
              className={styles.dialpad__item}
              onClick={(e) => {
                e.stopPropagation()

                onChange(item)
              }}
            >
              <div className={styles.dialpad__number}>{item.value}</div>
              <div className={styles.dialpad__symbols}>{item.symbols}</div>
            </div>
          )
        })}
      </div>
    )
  }
)
