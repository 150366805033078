import { IResponseRecurringBroadcastById } from 'entities/Broadcast/api/types'
import { Statistic } from 'entities/ContactStatistic/model/Statistic'

export class BroadcastRecurringStatistic extends Statistic {
  constructor({
    contacts_count,
    total_statistics,
    total_analytics,
    is_track_conversion,
  }: IResponseRecurringBroadcastById) {
    const unsubscribed_count = total_statistics.opt_out_count
    const reply_count = total_statistics.reply_count
    const success_count = total_statistics.success_count
    const total_count = total_statistics.total_count
    const undelivered_count = total_count - success_count
    const clicks_count = total_analytics.clicks

    const conversions_count = total_analytics.clicks

    super({
      contacts_count,
      reply_count,
      success_count,
      total_count,
      clicks_count,
      conversions_count,
      revenue_cost: '',
      undelivered_count,
      unsubscribed_count,
      is_track_conversion,
    })
  }

  get filters() {
    return this.getFilters('Broadcast contacts')
  }
}
