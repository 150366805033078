import { type DraggableProvided } from 'react-beautiful-dnd'
import classnames from 'classnames'
import { FC } from 'react'
import { Icon } from 'shared/ui'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/styles.module.scss'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'

type IDraggingIconProps = {
  hideDraggingIcon?: boolean
  dragHandleProps: DraggableProvided['dragHandleProps']
}

export const DraggingIcon: FC<IDraggingIconProps> = ({ hideDraggingIcon, dragHandleProps }) => {
  return (
    <div
      className={classnames(styles.iconDotsDrag, {
        [styles.hideDraggingIcon]: hideDraggingIcon,
      })}
      {...dragHandleProps}
    >
      <Icon
        icon={'dotsDrag'}
        fontSize={12}
        color={
          contactsDetailsGlobalStore.isOpenBlock('integration')
            ? undefined
            : 'var(--content-primary-tertiary)'
        }
      />
    </div>
  )
}
