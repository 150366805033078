// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iOg1ndQdvBEGNyCnHiRk{border-bottom:1px solid var(--border-neutral-default);padding:4px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Dropdown/ui/DropdownTextInput/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qDAAA,CACA,WAAA","sourcesContent":[".wrap {\n  border-bottom: 1px solid var(--border-neutral-default);\n  padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "iOg1ndQdvBEGNyCnHiRk"
};
export default ___CSS_LOADER_EXPORT___;
