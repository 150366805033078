import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { AuthLayout } from 'shared/layout'
import { SpinnerLoader } from 'shared/ui'
import { MemberInviteStore } from 'widgets/MemberInvite/store/memberInviteStore'
import { MemberInviteForm } from 'widgets/MemberInvite/ui/MemberInviteForm/MemberInviteForm'

import styles from './styles.module.scss'

export const MemberInviteContent = observer(({ store }: { store: MemberInviteStore }) => {
  if (store.preloading) {
    return (
      <div className={styles.wrap}>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <AuthLayout
        title={'Join'}
        subtitle={
          <div className={styles.subtitle}>Fill out the form below to accept your invitation!</div>
        }
        form={<MemberInviteForm store={store} />}
      />
    </div>
  )
})

export const MemberInvite = () => {
  const { token } = useParams()

  const store = new MemberInviteStore(token || '')

  return <MemberInviteContent store={store} />
}

export default MemberInvite
