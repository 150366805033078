import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownCard, IDropdownItem } from 'shared/ui'
import { conversationStore } from 'entities/Conversation'
import { callStore } from 'entities/Call'

type INewMessageActionDropdownItemProps = {
  onClick?: () => void
}

const defaultItem: IDropdownItem = {
  id: 'new_message',
  iconL: 'messageTextSquare',
  hotKey: 'M',
  label: 'New message',
  ariaLabel: 'getNewConversationNewMessageButtonSelector',
}

export const NewMessageActionDropdownItem: FC<INewMessageActionDropdownItemProps> = observer(
  ({ onClick }) => {
    const { isActionDisabled } = conversationStore
    const { isStatusClosed } = callStore
    const [dropdownItem, setDropdownItem] = useState<IDropdownItem>(defaultItem)

    useEffect(() => {
      const updatedItem: IDropdownItem = { ...defaultItem }

      if (isActionDisabled) {
        updatedItem.disabled = true
        updatedItem.parentTooltipProps = {
          label: 'Verify your number to send messages',
          placement: 'right-end',
          fullWidth: true,
        }
      }

      setDropdownItem(updatedItem)
    }, [isActionDisabled, isStatusClosed])

    return (
      <DropdownCard
        item={dropdownItem}
        onChange={() => {
          onClick?.()
        }}
      />
    )
  }
)
