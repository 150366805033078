// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dW22lppMOBYeGTDjlZLT{display:flex;align-items:center;justify-content:flex-end}.XzuiHkqN5Ju9wYPEL4L0{min-height:32px;flex:0 0 125px;color:var(--content-primary-tertiary)}.CiYpWCjBwniTxK6loWa0{width:100%;margin-left:125px}.NpksyitXV9dwovvOXucv{display:flex;gap:8px;align-items:center}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/BroadcastBadNumbers/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,qCAAA,CAGF,sBACE,UAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\n.label {\n  min-height: 32px;\n  flex: 0 0 125px;\n  color: var(--content-primary-tertiary);\n}\n\n.trigger {\n  width: 100%;\n  margin-left: 125px;\n}\n\n.wrapPlaceholder{\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "dW22lppMOBYeGTDjlZLT",
	"label": "XzuiHkqN5Ju9wYPEL4L0",
	"trigger": "CiYpWCjBwniTxK6loWa0",
	"wrapPlaceholder": "NpksyitXV9dwovvOXucv"
};
export default ___CSS_LOADER_EXPORT___;
