import { makeAutoObservable } from 'mobx'

import { BuyCreditsModalStore } from 'widgets/BuyCreditsModal'
import { ReminderTenDLCStore } from '../TenDLC'
import { ReminderCreditsStore } from '../Credits'
import { ReminderTrialCreditsStore } from '../TrialCredits'

class RemindersStore {
  constructor() {
    makeAutoObservable(this)
  }

  tenDlcStore = new ReminderTenDLCStore()
  creditsStore = new ReminderCreditsStore()
  trialCreditsStore = new ReminderTrialCreditsStore()
  buyCreditsModalStore = new BuyCreditsModalStore()

  init = () => {
    this.tenDlcStore.init()
    this.creditsStore.init()
    this.trialCreditsStore.init()
  }
}

export const remindersStore = new RemindersStore()
