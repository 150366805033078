import { observer } from 'mobx-react-lite'
import Flags from 'country-flag-icons/react/3x2'
import { Alert, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { Step3Store } from 'widgets/Register/ui/Step3/store/step3Store'

import styles from './styles.module.scss'

type IProps = {
  store: Step3Store
}

const Flag = ({ code }: { code: string }) => {
  const FlagComponent = Flags[code as keyof typeof Flags]
  return <FlagComponent />
}

export const CountriesModalContent = observer(({ store }: IProps) => {
  const { allCountries, onSelectOtherCountry, selectedOtherCountry } = store

  const customAllCountries = allCountries.map((item) => ({
    ...item,
    labelContent: (
      <div className={styles.customCountry}>
        <Flag code={item.key} />
        <Typography variant={'body-md-regular'} ariaLabel={'selectedCountry'}>
          {item.label}
        </Typography>
      </div>
    ),
  }))

  return (
    <div className={styles.wrap}>
      <Alert
        item={{
          type: 'infoBlue',
          title: 'Thank you for your interest in Salesmsg!',
          desc: 'Salesmsg is expanding to more countries soon. Let us know where you would like to use Salesmsg.',
        }}
      />
      <SelectField
        labelText='Country'
        value={selectedOtherCountry?.key}
        items={customAllCountries}
        setValue={onSelectOtherCountry}
        selectProps={{
          dropdownProps: {
            margin: -36,
            withSearch: true,
            withTextInput: false,
            ariaLabel: 'Country',
          },
        }}
        btnProps={{
          text: selectedOtherCountry ? (
            <div className={styles.customCountry}>
              <Flag code={selectedOtherCountry.key} />
              <Typography variant={'body-md-regular'} ariaLabel={'selectedCountry'}>
                {selectedOtherCountry.label}
              </Typography>
            </div>
          ) : (
            'Select country'
          ),
        }}
      />
    </div>
  )
})
