import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { TooltipProps } from '@mui/material'
import { IconButton } from 'shared/ui'
import { TriggerTooltip } from 'shared/ui/TriggerTooltip/TriggerTooltip'
import { AIAssistantContent, AiAssistantStore } from 'widgets/AIAssistant'

export type IAIAssistantActionProps = {
  aiAssistantStore: AiAssistantStore
  placementMainContent?: TooltipProps['placement']
  isVariantContained?: { value: boolean }
  isDisabledObj?: { value: boolean }
}

export const AIAssistantAction: FC<IAIAssistantActionProps> = observer((props) => {
  const { aiAssistantStore, placementMainContent = 'top-start', isDisabledObj } = props
  const { open, setOpen, triggerElement, handleTooltipOpen, isVariantContained } = aiAssistantStore

  const onClickAway = () => {
    setOpen(false)
  }

  useEffect(() => {
    return () => {
      setOpen(false)
    }
  }, [])

  const getTooltipLabel = () => {
    if (open) {
      return ''
    }
    if (isDisabledObj?.value) {
      return 'Please enter text to enable AI'
    }
    return 'AI assistant'
  }
  return (
    <>
      <IconButton
        icon={'stars'}
        onClick={handleTooltipOpen}
        active={open}
        ariaLabel={'AIAssistant_stars'}
        variant={isVariantContained?.value ? 'contained' : 'icon'}
        color={isVariantContained?.value ? 'passiveSecondary' : 'secondary'}
        tooltipProps={{ label: getTooltipLabel(), placement: 'top' }}
        disabled={isDisabledObj?.value}
      />

      {triggerElement && (
        <TriggerTooltip
          placement={placementMainContent}
          trigger={triggerElement}
          open={open}
          component={<AIAssistantContent aiAssistantStore={aiAssistantStore} />}
          color={'white'}
          onClickAway={onClickAway}
          marginBottom={13}
        />
      )}
    </>
  )
})
