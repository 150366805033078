import { makeAutoObservable, runInAction } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { AgentBookAppointmentRoutesSteps } from 'pages/chatbot/route/type'
import { AgentNavigationStore } from './agentNavigationStore'

const titleMap: Record<string, string> = {
  [AgentBookAppointmentRoutesSteps.settingAgent]: 'Setting up your Agent',
  [AgentBookAppointmentRoutesSteps.connectCalendar]: 'Connect your calendar',
  [AgentBookAppointmentRoutesSteps.actionsAgent]: 'Agent Actions',
  [AgentBookAppointmentRoutesSteps.activateAgent]: 'Activate your Agent',
}

export class AgentBookAppointmentStepsStore {
  private readonly _agentNavigationStore: AgentNavigationStore
  private _loading = true
  private _stepLoading = false

  constructor(agentNavigationStore: AgentNavigationStore) {
    makeAutoObservable(this)
    this._agentNavigationStore = agentNavigationStore
  }

  init = async (id?: number) => {
    runInAction(() => {
      this._loading = true
    })

    if (id) this._agentNavigationStore.setAgentId(id)

    runInAction(() => {
      this._loading = false
    })
  }

  get loading() {
    return this._loading
  }

  get agentNavigationStore() {
    return this._agentNavigationStore
  }

  get stepLoading() {
    return this._stepLoading
  }

  setStepLoading = (loading: boolean) => {
    this._stepLoading = loading
  }

  getSettingsStep = (path: AgentBookAppointmentRoutesSteps) => {
    return this._agentNavigationStore.getStepByPath(path)
  }

  getProgressInfoTitle = (pathname: string) => {
    return titleMap[pathname] || ''
  }

  decreaseStep = () => {
    uiStore.changeRoute({
      path: this._agentNavigationStore.getLastUrl,
    })
  }
}
