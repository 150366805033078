/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/display-name */
import { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react'
import styles from '../styles.module.scss'
import { IInputComponent, IInputTextProps } from '../Input/types'

export const InputWithPostfix: IInputComponent<IInputTextProps> = forwardRef<
  HTMLInputElement,
  IInputTextProps
>((props, ref) => {
  const [value, postfix] = (() => {
    const splitIndex = props.value?.lastIndexOf('.') ?? -1
    if (splitIndex !== -1) {
      return [props.value?.slice(0, splitIndex) ?? '', props.value?.slice(splitIndex + 1) ?? '']
    }
    return [props.value ?? '', '']
  })()

  const [postfixValue, setPostfixValue] = useState(postfix)
  const [selection, setSelection] = useState<{ start: number; end: number } | null>(null)
  const hiddenSpanRef = useRef<HTMLSpanElement | null>(null)
  const suffixRef = useRef<HTMLSpanElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle(ref, () => inputRef.current!)

  useEffect(() => {
    if (!selection || !inputRef.current) return
    const { start, end } = selection
    inputRef.current.focus()
    inputRef.current.setSelectionRange(start, end)
  }, [selection])

  useEffect(() => {
    if (hiddenSpanRef.current && suffixRef.current) {
      const textWidth = hiddenSpanRef.current.offsetWidth
      suffixRef.current.style.left = `${textWidth}px`
    }

    !postfix && postfixValue && setPostfixValue(postfixValue)
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target
    const rawValue = input.value.split('.')[0]
    const start = input.selectionStart || 0
    const end = input.selectionEnd || 0

    input.value = `${rawValue}.${postfix || postfixValue}`

    props.onChange?.(e)

    setSelection({ start, end })
  }

  return (
    <div className={styles.postfixInputContainer}>
      <input {...props} value={value} ref={inputRef} placeholder='' onChange={handleChange} />
      <span className={styles.inputPostfix} ref={suffixRef}>
        {`.${postfix}`}
      </span>
      <span className={styles.hiddenText} ref={hiddenSpanRef}>
        {value}
      </span>
    </div>
  )
})
