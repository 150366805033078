import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { usePowerDialerPageContext } from 'pages/powerDialer/context/powerDialerPageContext'
import { PowerDialerViewStore } from 'pages/powerDialer/pages/view/store/powerDialerViewStore'

const PowerDialerViewContext = createContext<PowerDialerViewStore | null>(null)

export const usePowerDialerViewContext = () => {
  const context = useContext(PowerDialerViewContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with PowerDialerViewContext')

  return context
}

export const PowerDialerViewProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams()
  const { pageLayoutStore } = usePowerDialerPageContext()
  const store = useMemo(() => new PowerDialerViewStore(id, pageLayoutStore), [])

  useEffect(() => {
    store.init()

    return store.dispose
  }, [])

  return <PowerDialerViewContext.Provider value={store}>{children}</PowerDialerViewContext.Provider>
}
