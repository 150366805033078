import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Typography, Icon, Avatar } from 'shared/ui'
import { IUser } from 'entities/Users'
import styles from './styles.module.scss'

export type IOrganizationMemberProps = {
  member: IUser
  active: boolean
  handleOwner: (id: number) => void
}

const OrganizationMember: React.FC<IOrganizationMemberProps> = observer(
  ({ member, active, handleOwner }) => {
    return (
      <div
        className={classnames(styles.memberWrapper, {
          [styles.active]: active,
        })}
        onClick={() => handleOwner(member.id)}
      >
        <div className={classnames(styles.info)}>
          <Avatar info={member.avatarInfo} size={16} />
          <Typography
            className={styles.memberName}
            variant='body-md-regular'
            color='var(--content-primary-primary)'
            pointer
            ellipsis
            ariaLabel={'name'}
          >
            {member.name}
          </Typography>
        </div>
        {active && <Icon icon='check1' color='var(--content-brand-primary)' />}
      </div>
    )
  }
)

export { OrganizationMember }
