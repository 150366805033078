import { observer } from 'mobx-react-lite'
import { IconButton, SpinnerLoader, Typography } from 'shared/ui'
import { useBroadcastRinglessContext } from 'widgets/BroadcastView/ui/BroadcastRingless'
import { UiAudioCard } from 'widgets/BroadcastView/ui/BroadcastRingless/ui/UiAudioCard/UiAudioCard'
import styles from './styles.module.scss'

export const UploadingAudio = observer(() => {
  const { onCancelUploadingAudio } = useBroadcastRinglessContext()
  return (
    <UiAudioCard
      headerLeft={
        <Typography
          ariaLabel={'title'}
          className={styles.title}
          tag={'div'}
          variant={'body-md-medium'}
        >
          Please wait
        </Typography>
      }
      headerRight={
        <IconButton
          ariaLabel={'close'}
          icon={'close'}
          color={'secondary'}
          size={'small'}
          variant={'icon'}
          onClick={onCancelUploadingAudio}
          tooltipProps={{
            label: 'Cancel',
            placement: 'top',
          }}
        />
      }
      content={
        <div className={styles.card}>
          <SpinnerLoader size={15} />
          <Typography ariaLabel={'uploading'} color={'tertiary'} variant={'body-md-regular'}>
            Uploading audio...
          </Typography>
        </div>
      }
    />
  )
})
