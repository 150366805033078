import classNames from 'classnames'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { IDays } from 'shared/lib'
import { WeekDaysSelectorStore } from 'shared/ui/Schedule/ui/WeekDaysSelector/store/weekDaysSelectorStore'
import styles from './styles.module.scss'

type IDaysSelectorProps = {
  store: WeekDaysSelectorStore
}

export const WeekDaysSelector = observer(({ store }: IDaysSelectorProps) => {
  const { daysItems, onToggleDay } = store
  return (
    <div className={styles.wrap}>
      <Typography variant={'body-md-regular'} color={'tertiary'} ariaLabel={'Send'}>
        Send on these days only
      </Typography>
      <div className={styles.wrapDays}>
        {daysItems.map((item) => (
          <button
            key={item.id}
            className={classNames(styles.day, { [styles.active]: item.activeValue })}
            onClick={() => onToggleDay(item.id as IDays)}
          >
            <Typography variant={'body-md-regular'} ariaLabel={'label'}>
              {item.label}
            </Typography>
          </button>
        ))}
      </div>
    </div>
  )
})
