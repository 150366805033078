import { IDropdownItem } from 'shared/ui'
import { IInboxCombine } from 'entities/Inbox'
import { ConversationMenuStore } from 'widgets/ConversationMenu'

export const typeTeamsFavorite = 'favorite'
type IActionTeamsFavorite = {
  inbox: IInboxCombine
  groupInbox?: IInboxCombine
  item: IDropdownItem
}

export const menuTeamsFavorite = (inbox: IInboxCombine): IDropdownItem => {
  return {
    id: typeTeamsFavorite,
    iconL: inbox.isFavoriteType ? 'starFilled1' : 'starLine',
    label: inbox.isFavoriteType ? 'Unfavorite' : 'Favorite',
  }
}

export const actionTeamsFavorite = async (
  { inbox, groupInbox, item }: IActionTeamsFavorite,
  store: ConversationMenuStore
) => {
  if (item.id === typeTeamsFavorite) {
    store.handleUpdateTeamsFavorite({
      inbox: inbox,
      groupInbox: groupInbox,
      status: !inbox.isFavoriteType,
    })
  }
}
