import {
  type IWorkflowTemplateName,
  type IWorkflowTemplateView,
  TemplateName,
} from 'widgets/CreateWorkflowModal/types'

export const TemplateConfig = new Map<IWorkflowTemplateName, IWorkflowTemplateView>([
  [
    TemplateName.FromScratch,
    {
      icon: 'workflows',
      name: TemplateName.FromScratch,
      description: 'Build your workflow from the ground up',
    },
  ],
  [
    TemplateName.AutoResponder,
    {
      icon: 'email',
      name: TemplateName.AutoResponder,
      description: 'Automatically respond to incoming messages based on their content',
    },
  ],
  [
    TemplateName.NurtureCampaign,
    {
      icon: 'usersCheck',
      name: TemplateName.NurtureCampaign,
      description: 'Send SMS messages and follow up based on customer interest',
    },
  ],
  [
    TemplateName.Reviews,
    {
      icon: 'messageTextSquare',
      name: TemplateName.Reviews,
      description: 'Boost reviews by sending SMS requests to your customers',
    },
  ],
  [
    TemplateName.SendMessage,
    {
      icon: 'send',
      name: TemplateName.SendMessage,
      description: 'A simple one-message workflow triggered by specific events',
    },
  ],
  [
    TemplateName.AppointmentReminder,
    {
      icon: 'calendarClock',
      name: TemplateName.AppointmentReminder,
      description: 'Remind users of upcoming appointments to minimize no-shows',
      disabled: true,
    },
  ],
  [
    TemplateName.NPS,
    {
      icon: 'messageSmile',
      name: TemplateName.NPS,
      description: 'Send an SMS survey to customers and follow up based on their responses',
      disabled: true,
    },
  ],
  [
    TemplateName.Integrations,
    {
      icon: 'plug',
      name: TemplateName.Integrations,
      description: 'Connect with other apps to enhance your workflow',
      disabled: true,
    },
  ],
  [
    TemplateName.SmsReminder,
    {
      icon: 'messageBell',
      name: TemplateName.SmsReminder,
      description: 'Send SMS reminders when specific conditions are met',
      disabled: true,
    },
  ],
])

export const TemplateConfigList = [...TemplateConfig.values()]
