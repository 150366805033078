import { FC } from 'react'
import classnames from 'classnames'
import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import { Typography } from 'shared/ui'
import { usersStore } from 'entities/Users'
import type { IntegrationProperty } from 'entities/Integrations/model'
import styles from './styles.module.scss'

export type IItemRowProps = {
  item: IntegrationProperty
  clearAction?: (key: string) => void
  labelType?: 'simple' | 'field'
}

export const ItemRow: FC<IItemRowProps> = ({ item, clearAction, labelType = 'field' }) => {
  const user = usersStore.user
  const isUserViewOnly = !!user?.isViewOnlyRole

  return (
    <div
      key={item.key}
      className={classnames(styles.itemRow, {
        [styles.emptyPadding]: labelType === 'field',
      })}
    >
      {labelType === 'simple' && (
        <Typography
          width={110}
          variant={'body-md-regular'}
          color={'var(--content-primary-tertiary)'}
          tag={'div'}
          tooltipProps={{
            label: item.label,
          }}
          ellipsis
          ariaLabel={'label'}
        >
          {item.label || '-'}
        </Typography>
      )}
      {labelType === 'field' && (
        <TextField
          className={styles.label}
          size='small'
          variant='outline'
          readOnly
          value={item.label || '-'}
          rightActions={[
            makeTextFieldInteractions({
              type: 'clear',
              active: !isUserViewOnly,
              action: () => clearAction?.(item.key),
            }),
          ]}
        />
      )}

      <TextField
        className={styles.value}
        size='small'
        variant='outline'
        readOnly
        value={item.value || '-'}
        rightActions={[
          makeTextFieldInteractions(
            { type: 'copy', active: !!item.value },
            { type: 'email', active: item.key === 'email' }
          ),
        ]}
        mainActions={[makeTextFieldTooltip()]}
      />
    </div>
  )
}
