import { PropsWithChildren } from 'react'

import styles from './styles.module.scss'

export type IListSectionProps = {
  title: string
}

export const ListSection = ({ title, children }: PropsWithChildren<IListSectionProps>) => (
  <section className={styles.list}>
    <span className={styles.listTitle}>{title}</span>
    {children}
  </section>
)
