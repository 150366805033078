import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { SpinnerLoader, Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactDropdownCard } from 'entities/Contacts'
import {
  SearchDropdownContactsStore,
  SearchTypesEnum,
  useSearchDropdownContext,
} from 'widgets/SearchDropdown'
import { minSearchCountText } from 'widgets/constants'
import styles from './styles.module.scss'

type ContactsSearchDropdownContentProps = {
  store: SearchDropdownContactsStore
  onCloseMenu?: () => void
  onSelect?: (item: Contact) => void
}

export const ContactsDropdownContentSearch: FC<ContactsSearchDropdownContentProps> = observer(
  ({ onCloseMenu, store, onSelect }) => {
    const { hasContactsType, selectType, actionSearch, search, setIsShowMore } =
      useSearchDropdownContext()
    const {
      selectTypeItem,
      createContact,
      loading,
      hasItems,
      first3Items,
      total,
      canSearch,
      options,
    } = store

    const handleSelect = (item: Contact) => {
      createContact(item, (contact) => {
        actionSearch({
          value: '',
          exclude: 'contacts',
        })
        options?.handleSearch?.('')

        if (!hasContactsType) selectType(SearchTypesEnum.contacts)

        selectTypeItem(contact)
        onSelect?.(contact)
        onCloseMenu?.()
      })
    }

    const handleShowAll = () => {
      actionSearch({
        value: '',
        exclude: 'contacts',
      })
      options?.handleSearch?.('')
      selectType(SearchTypesEnum.contacts)
      setIsShowMore(true)
    }

    const noResults = !loading && !hasItems && canSearch
    const showContactsList = !loading && hasItems && canSearch

    return (
      <div
        className={classnames(styles.wrap, styles.contacts)}
        aria-label={getAriaLabel('searchContactsSelector')}
      >
        <p className={styles.searchTitle} aria-label={getAriaLabel('searchContactsHeaderSelector')}>
          Contacts ({canSearch ? total : 0})
        </p>
        {loading && (
          <div className={styles.spinner}>
            <SpinnerLoader size={24} />
          </div>
        )}
        {showContactsList && (
          <div className={styles.items}>
            {first3Items.map((item) => (
              <ContactDropdownCard
                key={item.id}
                item={item}
                highlighter={search}
                onChange={handleSelect}
                showIconIntegration
                ariaLabel={'searchContactItem'}
              />
            ))}
          </div>
        )}
        {noResults && <div className={styles.notFound}>No results found</div>}
        {!canSearch && <div className={styles.notFound}>{minSearchCountText}</div>}
        {!hasContactsType && hasItems && (
          <div className={styles.showAll}>
            <Button
              disabled={loading}
              typeBtn={'informative'}
              text={'Show all'}
              onClick={handleShowAll}
            />
          </div>
        )}
      </div>
    )
  }
)
