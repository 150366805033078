import { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type ContactsDetailsStore } from 'widgets/ContactsDetails/store'
import { ContactsDetailsContext } from '../context/contactsDetailsContext'
import { ContactsDetailsContent } from './ContactsDetailsContent'

type IContactsDetailsProps = {
  store: ContactsDetailsStore
}

export const ContactsDetails: FC<IContactsDetailsProps> = observer(({ store }) => {
  return useMemo(() => {
    return (
      <ContactsDetailsContext.Provider value={store}>
        <ContactsDetailsContent key={store.id} />
      </ContactsDetailsContext.Provider>
    )
  }, [store.contacts])
})
