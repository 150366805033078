import { PropsWithChildren } from 'react'
import { Icon, IIconsVector } from 'shared/ui'

import styles from './styles.module.scss'

export const PresetList = ({ children }: PropsWithChildren) => (
  <div className={styles.list}>{children}</div>
)

export interface IPresetListItemProps {
  icon: IIconsVector
  name: string
  description: string
  selected: boolean
  disabled?: boolean
  onClick: () => void
}

export const PresetListItem = ({
  icon,
  name,
  description,
  selected,
  disabled,
  onClick,
}: IPresetListItemProps) => (
  <section
    className={styles.listItem}
    data-disabled={!!disabled ? true : undefined}
    data-selected={!!selected ? true : undefined}
    onClick={onClick}
  >
    <div className={styles.listItemIcon}>
      <Icon icon={icon} />
    </div>
    <div className={styles.listItemContent}>
      <header className={styles.listItemContentTitle}>{name}</header>
      <p className={styles.listItemContentDescription}>{description}</p>
    </div>
  </section>
)
