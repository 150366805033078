import { Alert } from 'shared/ui'
import { MapFieldsTable } from 'widgets/ContactsTable'

export const MapFieldsModalContent = () => {
  return (
    <div>
      <Alert
        item={{
          type: 'infoBlue',
          desc: <>Match each column from the spreadsheet to a Salesmsg field</>,
        }}
      />
      <MapFieldsTable />
    </div>
  )
}
