import React, { FC, ReactNode } from 'react'
import { Icon } from 'shared/ui'
import styles from './styles.module.scss'

export const AirCollWrapper: FC<{ children: ReactNode; airCallLink?: string }> = ({
  children,
  airCallLink,
}) => {
  if (airCallLink) {
    return (
      <div className={'row4'}>
        <a href={airCallLink} target={'_blank'} rel='noreferrer' className={styles.airCollLink}>
          {children}
        </a>
        <Icon icon={'aircall'} />
      </div>
    )
  } else {
    return <>{children}</>
  }
}
