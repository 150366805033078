import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import { Dropdown, SearchElement } from 'shared/ui'
import { SearchDateEnum, SearchTypesEnum, useSearchDropdownContext } from 'widgets/SearchDropdown'
import { DateDropdownContent } from 'widgets/SearchDropdown/ui/DropdownContent/DateDropdownContent/DateDropdownContent'

type DateElementProps = {
  parentsDropdownId?: string[]
}

export const DateElement: FC<DateElementProps> = observer(({ parentsDropdownId }) => {
  const { isDirtyFilter, newType, searchDropdownDateStore, deleteType } = useSearchDropdownContext()
  const { hasDate, hasOneOfDate, dateType, from, to } = searchDropdownDateStore

  const title = () => {
    switch (dateType) {
      case SearchDateEnum.today:
        return 'Today'
      case SearchDateEnum.yesterday:
        return 'Yesterday'
      case SearchDateEnum.last30days:
        return 'Last 30 days'
      case SearchDateEnum.last90days:
        return 'Last 90 days'
      case SearchDateEnum.custom:
        const startYear = from?.getFullYear() ?? ''
        const endYear = to?.getFullYear() ?? ''

        if (startYear === endYear)
          return `${dayjs(from).format('MMM D')} - ${dayjs(to).format('MMM D, YYYY')}`
        return `${dayjs(from).format('MMM D, YYYY')} - ${dayjs(to).format('MMM D, YYYY')}`
    }
  }

  return (
    <Dropdown
      ariaLabel={'DateElement'}
      parentsDropdownId={parentsDropdownId}
      iconButtonProps={{
        size: 'medium',
        fontSize: 20,
        ariaLabel: 'DateElement_trigger',
      }}
      show={newType === SearchTypesEnum.date && !isDirtyFilter}
      triggerComponent={(open) => (
        <SearchElement
          icon={'calendar'}
          type='Created date'
          removeCallback={() => {
            deleteType(SearchTypesEnum.date)
          }}
          content={hasDate && title()}
          warningContent={!open && !hasDate}
          contentDefaultValue={hasOneOfDate ? 'Custom' : 'select'}
        />
      )}
      placement={'bottom-start'}
      sizeTriggerButton={'medium'}
      variantTriggerButton={'icon'}
      customComponent={(onCloseMenu) => (
        <DateDropdownContent store={searchDropdownDateStore} onCloseMenu={onCloseMenu} />
      )}
    />
  )
})
