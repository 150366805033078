import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'shared/ui'
import { IInboxCombine } from 'entities/Inbox'
import {
  menuRemoveFromFolder,
  actionRemoveFromFolder,
  menuNewFolder,
  actionNewFolder,
  menuTeamsMute,
  actionTeamsMute,
} from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/Dropdown'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'

type PropsDropDown = {
  inbox: IInboxCombine
  groupInbox?: IInboxCombine
}

export const DropdownGroupSimpleInbox: FC<PropsDropDown> = observer(({ inbox, groupInbox }) => {
  const store = useConversationMenuContext()

  return (
    <Dropdown
      ariaLabel={'DropdownGroupSimpleInbox'}
      icon={'moreHorizontal'}
      placement={'bottom-end'}
      tooltipProps={{
        disableInteractive: true,
        label: 'Show actions',
        placement: 'top-end',
      }}
      items={[menuRemoveFromFolder(), menuNewFolder(), menuTeamsMute(inbox)]}
      onChange={(item) => {
        actionRemoveFromFolder({ inbox, groupInbox, item }, store)
        actionNewFolder({ inbox, groupInbox, item }, store)
        actionTeamsMute({ inbox, item }, store)
      }}
      width={189}
    />
  )
})
