import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { configStore } from 'entities/Config'
import { AdminLayout } from 'pages/admin/layout/AdminLayout'
import { Imports } from 'pages/admin/pages/imports'
import { Status } from 'pages/admin/pages/status'
import { ComplianceRoutes } from 'pages/admin/pages/compliance'
import { AdminRoutesEnum } from 'pages/admin'
import { OrganizationsRoutes } from 'pages/admin/pages/organizations/route/OrganizationsRoutes'
import { CustomPricesRoutes } from 'pages/admin/pages/customPrices/route/CustomPricesRoutes'

const AdminRoutes = observer(() => {
  if (configStore.loading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }
  if (!configStore.developer) {
    return (
      <Routes>
        <Route path={'*'} element={<Navigate to={'/'} />} />
      </Routes>
    )
  }
  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route index element={<Navigate to={AdminRoutesEnum.organizations} />} />
        <Route path={`${AdminRoutesEnum.organizations}/*`} element={<OrganizationsRoutes />} />
        <Route path={`${AdminRoutesEnum.imports}/*`} element={<Imports />} />
        <Route path={`${AdminRoutesEnum.status}/*`} element={<Status />} />
        <Route path={`${AdminRoutesEnum.compliance}/*`} element={<ComplianceRoutes />} />
        <Route path={`${AdminRoutesEnum.customPrices}/*`} element={<CustomPricesRoutes />} />
      </Route>
    </Routes>
  )
})

export default AdminRoutes
