import { makeAutoObservable, runInAction } from 'mobx'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { IParamsContacts } from 'entities/Contacts/api/types'
import { IParamsFiltersList } from 'entities/Broadcast/api/types'
import { EnrollmentFilters } from 'widgets/ContactEnrollment'

export class EnrollmentContactsTableStore {
  loading = false
  itemsMap: Map<number, Contact> = new Map()

  page = 1
  length = 25
  search = ''
  sortOrder: 'desc' | 'asc' = 'desc'
  sortBy = 'created_at'
  total = 0
  filters: EnrollmentFilters | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get items() {
    return Array.from(this.itemsMap.values())
  }

  get filtersListGroups(): IParamsFiltersList {
    return {
      contacts: this.filters?.contacts.map((item) => item.id) || [],
      segments: this.filters?.segments.map((item) => item.id) || [],
      tags: this.filters?.tags.map((item) => item.id) || [],
      advanced: this.filters?.filters.map((item) => item.filtersParams) || [],
    }
  }

  get expandedParams(): IParamsContacts {
    return {
      page: this.page,
      length: this.length,
      search: this.search,
      sortOrder: this.sortOrder,
      sortBy: [this.sortBy],
      filtersListGroups: this.filtersListGroups,
    }
  }

  onChangePagination = (page: number, length: number) => {
    this.page = page
    this.length = length

    this.loadContacts()
  }

  reset = () => {
    this.loading = false
    this.page = 1
    this.length = 25
    this.search = ''
    this.sortOrder = 'desc'
    this.sortBy = 'created_at'
    this.filters = null
    this.itemsMap.clear()
  }

  loadContacts = async () => {
    try {
      this.itemsMap.clear()

      runInAction(() => {
        this.loading = true
      })

      const { data } = await ContactsApi.getContactsList(this.expandedParams)
      const results = contactsStore.addItems(data.data || [])
      const total = data.total || 0

      results.forEach((item) => {
        this.itemsMap.set(item.id, item)
      })

      runInAction(() => {
        this.total = total
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setTotal = (value: number) => {
    this.total = value
  }

  setFilter = (filters: EnrollmentFilters) => {
    this.filters = filters
  }
}
