import { makeAutoObservable } from 'mobx'
import { type ITabItem } from 'shared/ui'
import { type IScheduleType } from 'shared/ui/Schedule'
import {
  AdvancedSchedulingStore,
  type IParamsAdvancedSchedule,
} from 'shared/ui/Schedule/ui/AdvancedScheduling'
import { truthy } from 'shared/lib'
import {
  type IInitImmediatelyStoreProps,
  ImmediatelyStore,
} from 'shared/ui/Schedule/ui/Immediately'
import {
  type IInitSendLaterStoreProps,
  type ISendLaterStoreProps,
  SendLaterStore,
} from 'shared/ui/Schedule/ui/SendLater'
import {
  type IInitRecurringStoreProps,
  RecurringStore,
} from 'shared/ui/Schedule/ui/Recurring/store/recurringStore'

export type IInitScheduleStoreProps =
  | {
      type: 'immediately'
      config: IInitImmediatelyStoreProps
    }
  | {
      type: 'later'
      config: IInitSendLaterStoreProps
    }
  | {
      type: 'recurring'
      config: IInitRecurringStoreProps
      isDisabledSendLater: boolean
    }

export class ScheduleStore {
  sendLaterStore
  constructor({ sendLaterStoreProps }: { sendLaterStoreProps: ISendLaterStoreProps }) {
    this.sendLaterStore = new SendLaterStore(sendLaterStoreProps)
    makeAutoObservable(this)
  }

  immediatelyStore = new ImmediatelyStore()

  isDisabledSendLater = false

  isHideRecurring = false
  recurringStore = new RecurringStore()

  type: IScheduleType = 'immediately'

  onCloseMenu: (() => void) | null = null
  dropdownId = ''

  get send_now() {
    return this.type === 'immediately'
  }

  get send_at() {
    if (this.type === 'later') {
      return this.sendLaterStore.utcDateString
    }
    if (this.type === 'recurring') {
      return this.recurringStore.params.send_at
    }
    return ''
  }

  get advancedScheduleStore(): AdvancedSchedulingStore | undefined | null {
    if (this.type === 'immediately') {
      return this.immediatelyStore.advancedSchedulingStore
    }
    if (this.type === 'later') {
      return this.sendLaterStore.advancedSchedulingStore
    }
    if (this.type === 'recurring') {
      return this.recurringStore.advancedSchedulingStore
    }
  }

  private get allAdvancedScheduleStore(): AdvancedSchedulingStore[] {
    return [
      this.immediatelyStore.advancedSchedulingStore,
      this.sendLaterStore.advancedSchedulingStore,
      this.recurringStore.advancedSchedulingStore,
    ].filter(truthy)
  }

  get advancedSchedule(): IParamsAdvancedSchedule | undefined {
    return this.advancedScheduleStore?.advancedSchedule
  }

  get isAdvancedSchedule(): boolean {
    return Boolean(this.advancedScheduleStore?.isAdvancedScheduling)
  }

  get is_contact_timezone() {
    if (this.type === 'immediately') {
      return this.immediatelyStore.isUseContactTimezone
    }
    if (this.type === 'later') {
      return this.sendLaterStore.isUseContactTimezone
    }
    if (this.type === 'recurring') {
      return this.recurringStore.params.is_contact_timezone
    }
    return true
  }

  get scheduleText() {
    if (this.type === 'immediately') {
      return 'Immediately'
    }
    if (this.type === 'later') {
      return this.sendLaterStore.scheduleText
    }
    if (this.type === 'recurring') {
      return this.recurringStore.scheduleText
    }
    return ''
  }

  get advancedText() {
    if (this.type === 'immediately') {
      return this.immediatelyStore.advancedSchedulingStore.advancedText
    }
    if (this.type === 'later') {
      return this.sendLaterStore.advancedSchedulingStore?.advancedText
    }
    if (this.type === 'recurring') {
      return this.recurringStore.advancedSchedulingStore.advancedText
    }
    return ''
  }

  get repeatText() {
    if (this.type === 'recurring') {
      return this.recurringStore.repeatText
    }
    return ''
  }

  get advancedTextTime() {
    if (this.type === 'immediately') {
      return this.immediatelyStore.advancedSchedulingStore.advancedTextTime
    }
    if (this.type === 'later') {
      return this.sendLaterStore.advancedSchedulingStore?.advancedTextTime
    }
    if (this.type === 'recurring') {
      return this.recurringStore.advancedSchedulingStore.advancedTextTime
    }
    return ''
  }

  get isScheduleTimezoneContent() {
    return this.type === 'later' || this.type === 'recurring'
  }

  get tabs(): ITabItem<IScheduleType>[] {
    const tabs: ITabItem<IScheduleType>[] = [
      {
        key: 'immediately',
        name: 'Send immediately',
      },
      {
        key: 'later',
        name: 'Send later',
        disabled: this.isDisabledSendLater,
      },
    ]
    if (!this.isHideRecurring) {
      tabs.push({
        key: 'recurring',
        name: 'Recurring',
      })
    }
    return tabs
  }

  init = (props: IInitScheduleStoreProps) => {
    this.type = props.type
    if (props.type === 'later') {
      this.sendLaterStore.init(props.config)
    }
    if (props.type === 'recurring') {
      this.isDisabledSendLater = props.isDisabledSendLater
      this.recurringStore.init(props.config)
    }
    this.immediatelyStore.init(props.config)
  }

  setOnCloseMenu = (onCloseMenu: typeof this.onCloseMenu) => {
    this.onCloseMenu = onCloseMenu
  }
  setDropdownId = (dropdownId: string) => {
    this.dropdownId = dropdownId
    this.immediatelyStore.setDropdownId(dropdownId)
    this.sendLaterStore.setDropdownId(dropdownId)
    this.recurringStore.setDropdownId(dropdownId)
  }

  setType = (type: typeof this.type) => {
    this.type = type
  }

  handleSelectTab = (tab: ITabItem<IScheduleType>) => {
    this.setType(tab.key)
  }

  setRequiredAdvancedSchedule = (value: boolean) => {
    this.allAdvancedScheduleStore.forEach((store) => store.setRequiredAdvancedSchedule(value))
  }

  setSchedulingRecipientsMax = (value: number) => {
    this.allAdvancedScheduleStore.forEach((store) => store.setSchedulingRecipientsMax(value))
  }
  setSchedulingRecipients = (value: number) => {
    this.allAdvancedScheduleStore.forEach((store) => store.setSchedulingRecipients(value))
  }

  setHideRecurring = (value: boolean) => {
    if (value && this.type === 'recurring') {
      this.setType('immediately')
    }
    this.isHideRecurring = value
  }
}
