import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import {
  DropdownCard,
  EnumDropdownItemVariant,
  IDropdownItem,
  InputCheckbox,
  InputCheckboxProps,
  Typography,
} from 'shared/ui'
import { Tag } from 'entities/Tags/model/Tag'
import styles from './styles.module.scss'

type ITagDropdownCardProps = {
  item: Tag
  InputCheckboxProps?: InputCheckboxProps
  onChange?: (item: Tag) => void
  highlighter?: string
  ariaLabel?: string
}

export const TagDropdownCard: FC<ITagDropdownCardProps> = observer(
  ({ item, onChange, InputCheckboxProps, highlighter, ariaLabel }) => {
    const renderContent = (isCheckbox: boolean) => {
      return (
        <div
          className={classnames(styles.wrap, {
            [styles['wrap--checkbox']]: isCheckbox,
          })}
        >
          <div className={styles.color} style={{ backgroundColor: item.color }} />
          <Typography variant={'body-md-regular'} ariaLabel={'name'} className={styles.title}>
            {highlighter ? (
              <Highlighter
                highlightClassName={classnames(styles.highlighter, styles['highlighter--label'])}
                className={styles.name}
                searchWords={highlighter.split(' ')}
                autoEscape={true}
                textToHighlight={item.label}
              />
            ) : (
              item.label
            )}
          </Typography>
          <Typography variant={'body-xs-medium'} ariaLabel={'count'} className={styles.count}>
            {item.contacts_count}
          </Typography>
        </div>
      )
    }
    const dropdown: IDropdownItem = {
      id: item.id,
      label: item.label,
      variant: EnumDropdownItemVariant.Custom,
      renderOption: () => {
        return renderContent(false)
      },
      className: styles.dropdown,
    }

    if (InputCheckboxProps) {
      return (
        <InputCheckbox
          className={styles.checkbox}
          {...InputCheckboxProps}
          customLabel={renderContent(true)}
        />
      )
    }

    return (
      <DropdownCard
        item={dropdown}
        ariaLabel={ariaLabel}
        onChange={() => {
          if (onChange) onChange(item)
        }}
      />
    )
  }
)
