import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Icon, ITypographyProps, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export type IWarningProps = {
  text: ReactNode
  typographyProps?: Partial<ITypographyProps>
  size?: 'small' | 'medium'
}

export const Warning = ({ text, typographyProps, size = 'small' }: IWarningProps) => {
  if (!text) {
    return null
  }
  return (
    <div className={styles.warning}>
      <Icon icon={'infoCircle'} fontSize={16} />
      <Typography
        ariaLabel={'warning'}
        variant={size === 'small' ? 'text-input-helper' : 'body-sm-regular'}
        {...typographyProps}
        className={classNames(styles.text, typographyProps?.className)}
      >
        {text}
      </Typography>
    </div>
  )
}
