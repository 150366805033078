// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mb3S5U64dM8OsTxnvCru{padding-inline:24px;display:grid;gap:24px;grid-template-columns:1fr 1fr;max-height:calc(100vh - 108px);overflow-y:auto}.mb3S5U64dM8OsTxnvCru>div:last-child{grid-column:-1/1}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/compliance/pages/TenDLCDetails/ui/ModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,YAAA,CACA,QAAA,CACA,6BAAA,CACA,8BAAA,CACA,eAAA,CACA,qCACE,gBAAA","sourcesContent":[".wrap{\n  padding-inline: 24px;\n  display: grid;\n  gap: 24px;\n  grid-template-columns: 1fr 1fr;\n  max-height: calc(100vh - 108px);\n  overflow-y: auto;\n  & > div:last-child{\n    grid-column: -1/1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "mb3S5U64dM8OsTxnvCru"
};
export default ___CSS_LOADER_EXPORT___;
