import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'
import { conversationStore } from 'entities/Conversation'
import { ContactCreateModalStore } from 'widgets/ContactCreateModal'
import { CallHistoryStore } from 'widgets/CallHistory'
import { CallModalStore } from 'widgets/CallModal'
import { ConversationMenuStore } from 'widgets/ConversationMenu'
import { ConversationSearchStore } from 'widgets/ConversationHeaderSearch'
import { ConversationLayoutStore } from 'widgets/ConversationLayout'
import { ConversationListStore } from 'widgets/ConversationList'
import { ConversationMessagesStore } from 'widgets/ConversationMessages'
import { ConversationNewSearchStore } from 'widgets/ConversationNew'
import { SearchTypesEnum } from 'widgets/SearchDropdown'

export class ConversationsPageStore {
  private _pageLayoutStore = new PageLayoutStore()
  private _callModalStore = new CallModalStore()
  private _callHistoryStore = new CallHistoryStore(this._callModalStore)
  private _contactCreateModalStore = new ContactCreateModalStore(this._callModalStore)
  private _conversationMenuStore = new ConversationMenuStore(
    this._pageLayoutStore,
    this._callModalStore,
    this._contactCreateModalStore
  )
  private _conversationSearchStore = new ConversationSearchStore()
  private _conversationLayoutStore = new ConversationLayoutStore(this._conversationSearchStore)
  private _conversationListStore = new ConversationListStore(this._conversationSearchStore)
  private _conversationNewSearchStore = new ConversationNewSearchStore()
  private _conversationMessagesStore = new ConversationMessagesStore(
    this._callModalStore,
    this._conversationListStore,
    this._conversationSearchStore,
    this._conversationNewSearchStore
  )

  constructor() {
    makeAutoObservable(this)

    this._callModalStore.setConfig({
      onSubmit: (teamId) => {
        this._conversationMenuStore.handleUpdateTeamInbox(teamId)
      },
      setHideTrialAlertNotForOwner: this._conversationMessagesStore.setHideTrialAlertNotForOwner,
    })

    this._conversationSearchStore.setConfig({
      onHasFilters: (value) => {
        this._conversationMenuStore.setDisabledActive(value)
      },
      onResetConversations: () => {
        this._conversationListStore.reset()
      },
      onLoadConversations: () => {
        this._conversationListStore.load()
      },
      onSearchConversations: () => {
        this._conversationListStore.searchConversations()
      },
    })

    this._conversationMenuStore.setConfig({
      onOpenSearch: () => {
        this._conversationSearchStore.onOpen()
      },
      onToggleSearch: () => {
        this._conversationSearchStore.onToggle()
      },
      onShowChats: () => {
        if (this._conversationLayoutStore.isShowCalls) {
          this._conversationLayoutStore.showChats()
        }
      },
    })

    this._conversationNewSearchStore.setConfig({
      conversationMessagesStore: this._conversationMessagesStore,
    })

    this._contactCreateModalStore.setConfig({
      initSignal: this.initSignal,
    })

    this.initSignal()
  }

  initSignal = () => {
    const signal = conversationStore.signal

    if (!signal) return
    if (signal.type === 'open_new_conversation') {
      this._conversationNewSearchStore.handleAddItem(signal.payload)
    }

    if (signal.type === 'open_group_conversation') {
      this._conversationSearchStore.selectTypeItem(signal.payload.contact)
      this._conversationSearchStore.selectType(SearchTypesEnum.contacts)
      this._conversationSearchStore.onOpen()
    }

    conversationStore.clearSignal()
  }

  get conversationMenuStore() {
    return this._conversationMenuStore
  }

  get callHistoryStore() {
    return this._callHistoryStore
  }

  get callModalStore() {
    return this._callModalStore
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get conversationSearchStore() {
    return this._conversationSearchStore
  }

  get conversationLayoutStore() {
    return this._conversationLayoutStore
  }

  get conversationListStore() {
    return this._conversationListStore
  }

  get conversationMessagesStore() {
    return this._conversationMessagesStore
  }

  get conversationNewSearchStore() {
    return this._conversationNewSearchStore
  }

  get contactCreateModalStore() {
    return this._contactCreateModalStore
  }
}
