import { observer } from 'mobx-react-lite'
import { TrackConversionContent } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/ui/BodyContent/TrackConversionContent'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import { SendContent } from './SendContent'
import { ScheduleContent } from './ScheduleContent/ScheduleContent'
import { TypeContent } from './TypeContent/TypeContent'

export const BodyContent = observer(() => {
  const broadcast = useBroadcastDetailModalContext()
  return (
    <>
      <TypeContent />
      <SendContent />
      <ScheduleContent />
      {broadcast.is_track_conversion && broadcast.short_urls?.length && <TrackConversionContent />}
    </>
  )
})
