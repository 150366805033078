// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dvya5g4uFuXynsAMLarg{display:flex;align-items:center;padding:0 0 0 8px;border-radius:6px;background:var(--background-primary-inverted-primary);height:32px;cursor:pointer}.dvya5g4uFuXynsAMLarg:hover{background:var(--background-neutral-subtle)}.d04TNAEGcpd4hzpu4DJX{margin-right:8px}.WT3GhVVgDUMGmfcyFFiD{color:var(--content-primary-primary);font-size:13px;white-space:nowrap;max-width:300px;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationAssignModal/ui/conversationAssignItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CACA,qDAAA,CACA,WAAA,CACA,cAAA,CAEA,4BACE,2CAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,oCAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".item {\n  display: flex;\n  align-items: center;\n  padding: 0 0 0 8px;\n  border-radius: 6px;\n  background: var(--background-primary-inverted-primary);\n  height: 32px;\n  cursor: pointer;\n\n  &:hover {\n    background: var(--background-neutral-subtle);\n  }\n\n  &__avatar {\n    margin-right: 8px;\n  }\n\n  &__name {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    white-space: nowrap;\n    max-width: 300px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n\n  &__delete {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "dvya5g4uFuXynsAMLarg",
	"item__avatar": "d04TNAEGcpd4hzpu4DJX",
	"item__name": "WT3GhVVgDUMGmfcyFFiD"
};
export default ___CSS_LOADER_EXPORT___;
