import { createContext, useContext } from 'react'
import { BroadcastRinglessStore } from 'widgets/BroadcastView/ui/BroadcastRingless'

export const BroadcastRinglessContext = createContext<BroadcastRinglessStore | null>(null)

export function useBroadcastRinglessContext() {
  const context = useContext(BroadcastRinglessContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with BroadcastRinglessContext')
  }
  return context
}
