import { observer } from 'mobx-react-lite'
import { Dropdown, Icon, IconButton, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type Props = {
  onAddContact?: ((value: string) => void) | null
  onTrigger?: () => void
}

export const ContactPicker = observer(({ onAddContact, onTrigger }: Props) => {
  const handleContactClick = (onCloseMenu: () => void, value: string) => {
    onCloseMenu()

    const contactElement = `<span class='textarea-tag' data-readonly data-tag='@${value}' data-key='textarea-tag'>@${value}</span>`

    onAddContact && onAddContact(contactElement + '&nbsp;')
  }

  const handleTooltipOpen = () => {
    onTrigger && onTrigger()
  }

  return (
    <>
      <Dropdown
        ariaLabel={'ContactPicker'}
        placement={'bottom'}
        typeButton={'icon-button'}
        triggerComponent={(open) => (
          <div>
            <Tooltip label={open ? '' : 'Tag contact'} title={''} placement={'top'}>
              <IconButton
                icon={'at'}
                variant={'icon'}
                color={'secondary'}
                onClick={handleTooltipOpen}
                active={open}
                ariaLabel={'ContactPicker_tag_contact'}
              />
            </Tooltip>
          </div>
        )}
        customComponent={(onCloseMenu) => (
          <div className={styles.dropdown}>
            <div
              className={styles.dropdownItem}
              onClick={() => handleContactClick(onCloseMenu, 'inbox')}
            >
              <div className={styles.contact}>
                <span className={styles.icon}>
                  <Icon icon='announcement' />
                </span>
                <span className={styles.value}>
                  <Typography variant='body-rg-regular' ariaLabel={'@inbox'}>
                    @inbox
                  </Typography>
                </span>
              </div>
              <span className={styles.description}>
                <Typography variant='body-xs-regular' ariaLabel={'Notify'}>
                  Notify everyone in this inbox
                </Typography>
              </span>
            </div>
          </div>
        )}
      />
    </>
  )
})
