import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { ScheduleStore } from 'shared/ui/Schedule'
import { IParamsScheduleDataBroadcast, IResponseBroadcastById } from 'entities/Broadcast/api/types'

export class BroadcastScheduleStore {
  constructor() {
    makeAutoObservable(this)
  }
  scheduleStore = new ScheduleStore({
    sendLaterStoreProps: {
      withAdvancedScheduling: true,
    },
  })

  get recurringStore() {
    return this.scheduleStore.recurringStore
  }

  get recurringParams(): IParamsScheduleDataBroadcast['recurring'] {
    if (this.scheduleStore.type === 'recurring') {
      const custom_repeat_settings =
        this.recurringStore.params.repeat_settings.custom_repeat_settings
      return {
        toggle: true,
        generated_count: 0,
        processed_count: 0,
        future_count: 0,
        last_generated_at: '',
        duration_settings: {
          type: this.recurringStore.params.end_settings.type,
          date: this.recurringStore.params.end_settings.date || null,
          occurrences: this.recurringStore.params.end_settings.endTimes || null,
        },
        send_settings: {
          type: custom_repeat_settings?.customPeriod || null,
          interval: Number(custom_repeat_settings?.interval) || null,
          schedule: {
            days: custom_repeat_settings?.weekly
              ? custom_repeat_settings.weekly.days
              : this.scheduleStore.recurringStore.advancedSchedulingStore.advancedSchedule?.days,
          },
          recurring_dates: custom_repeat_settings?.monthly?.days || [],
          recurring_month_is_calendar: custom_repeat_settings?.monthly?.type === 'each',
          recurring_month_nth_type: custom_repeat_settings?.monthly?.onTheOrdinal || null,
          recurring_month_nth_day: custom_repeat_settings?.monthly?.onTheDayOfWeek || null,
        },
        type: this.recurringStore.params.repeat_settings.type,
      }
    }
    return null
  }

  get scheduleDataParams(): IParamsScheduleDataBroadcast {
    return {
      send_now: this.scheduleStore.send_now,
      send_at: this.scheduleStore.send_at,
      schedule: this.scheduleStore.advancedSchedule,
      is_contact_timezone: this.scheduleStore.is_contact_timezone,
      schedulingRecipientsMax: 1000000,
      recurring: this.recurringParams,
      timezone: uiStore.timezone,
    }
  }

  get advanced_package_count() {
    return this.scheduleStore.advancedScheduleStore?.schedulingRecipients
  }

  init = ({
    response,
    isEditRecurring = false,
    isEditRecurringChild = false,
  }: {
    response: IResponseBroadcastById
    isEditRecurring?: boolean
    isEditRecurringChild?: boolean
  }) => {
    if (response.recurring_template_settings) {
      this.scheduleStore.init({
        type: 'recurring',
        isDisabledSendLater: isEditRecurring,
        config: {
          repeatPeriod: response.recurring_template_settings.type,
          customInterval: response.recurring_template_settings.send_settings?.interval,
          customPeriod: response.recurring_template_settings.send_settings?.type,
          date: response.send_at,
          endRepeat: response.recurring_template_settings?.duration_settings?.type,
          endDate: response.recurring_template_settings?.duration_settings?.date,
          endTimes: response.recurring_template_settings?.duration_settings?.occurrences,
          isUseContactTimezone: response.is_contact_timezone,
          advancedDate: {
            days: response?.schedule.days,
            isAdvancedScheduling: !!response.schedule?.days.length,
            time: response.schedule.time,
          },

          isDisabledRepeat: isEditRecurringChild,
          isDisabledEnd: isEditRecurringChild,
          monthDaysSelectorStoreProps: {
            type: response.recurring_template_settings.send_settings.recurring_month_is_calendar
              ? 'each'
              : 'onThe',
            days: response.recurring_template_settings.send_settings.recurring_dates,
            onTheDayOfWeek:
              response.recurring_template_settings.send_settings.recurring_month_nth_day,
            onTheOrdinal:
              response.recurring_template_settings.send_settings.recurring_month_nth_type,
          },
        },
      })
    } else if (response.is_send_now) {
      this.scheduleStore.init({
        type: 'immediately',
        config: {
          isUseContactTimezone: response.is_contact_timezone,
          advancedDate: {
            days: response.schedule.days,
            isAdvancedScheduling: !!response.schedule.days.length,
            time: response.schedule.time,
          },
        },
      })
    } else {
      this.scheduleStore.init({
        type: 'later',
        config: {
          date: response.send_at,
          isUseContactTimezone: response.is_contact_timezone,
          advancedDate: {
            days: response.schedule.days,
            isAdvancedScheduling: !!response.schedule.days.length,
            time: response.schedule.time,
          },
        },
      })
    }
  }

  setRequiredAdvancedSchedule = (value: boolean) => {
    this.scheduleStore.setRequiredAdvancedSchedule(value)
  }

  setSchedulingRecipientsMax = (value: number) => {
    this.scheduleStore.setSchedulingRecipientsMax(value)
    this.setSchedulingRecipients(value)
  }
  setSchedulingRecipients = (value: number) => {
    this.scheduleStore.setSchedulingRecipients(value)
  }
}
