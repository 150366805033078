import { createContext, useContext } from 'react'
import { ImmediatelyStore } from 'shared/ui/Schedule'

export const ImmediatelyContext = createContext<ImmediatelyStore | null>(null)

export function useImmediatelyContext() {
  const context = useContext(ImmediatelyContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with ImmediatelyContext')
  }
  return context
}
