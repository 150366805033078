import type { TooltipProps } from '@mui/material'
import { ReactElement, ReactNode, cloneElement, isValidElement, useEffect, useState } from 'react'
import {
  Dropdown,
  IDropdownItem,
  IOrderedGroups,
  DropdownContent,
  DropdownGroup,
  Scrollbar,
  SpinnerLoader,
  EnumSpinnerLoaderPosition,
  IIconButtonProps,
  ITooltipProps,
} from 'shared/ui'
import { DropdownTextInput } from 'shared/ui/Dropdown/ui/DropdownTextInput'

import { makeTextFieldClear } from 'shared/ui/TextField'
import styles from './styles.module.scss'

export interface IFilterDropdown {
  isLoading?: boolean
  filterDropdownGroups: IOrderedGroups[]
  closeAfterChange?: boolean
  additionActions?: ReactNode
  margin?: number
  placement?: TooltipProps['placement']
  tooltipLabel?: string
  tooltipProps?: ITooltipProps
  sizeTriggerButton?: IIconButtonProps['size']
  variantTriggerButton?: IIconButtonProps['variant']
  iconButtonProps?: IIconButtonProps
  autoOpen?: boolean
  width?: number
  triggerComponent?: (open: boolean) => ReactNode
  changeActiveFields: (item: IDropdownItem) => void
  onCloseDropdown?: () => void
  parentsDropdownId?: string[]
  autoHeightMax?: number
  ariaLabel?: string
  noSearch?: boolean
}

const filterItems = (filterDropdownGroups: IOrderedGroups[], searchValue: string) => {
  const filteredDropdownGroups = [] as IOrderedGroups[]

  filterDropdownGroups.forEach(({ label, items }) => {
    const filteredItems = items.filter((item) =>
      String(item.label).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    )

    if (filteredItems.length) {
      filteredDropdownGroups.push({
        label,
        items: filteredItems,
      })
    }
  })

  return filteredDropdownGroups
}

export const FilterDropdown = ({
  isLoading = false,
  filterDropdownGroups,
  closeAfterChange,
  tooltipProps,
  additionActions,
  margin,
  placement,
  tooltipLabel = '',
  sizeTriggerButton = 'small',
  variantTriggerButton = 'contained',
  autoOpen = false,
  autoHeightMax = 300,
  width = 228,
  onCloseDropdown = () => {},
  triggerComponent,
  changeActiveFields,
  parentsDropdownId,
  iconButtonProps,
  ariaLabel,
  noSearch = false,
}: IFilterDropdown) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredItems, setFilteredItems] = useState<IOrderedGroups[]>([])

  useEffect(() => {
    const filteredItems = filterItems(filterDropdownGroups, searchValue)

    setFilteredItems(filteredItems)
  }, [filterDropdownGroups])

  const onSearch = (value: string) => {
    setSearchValue(value)

    const filteredItems = filterItems(filterDropdownGroups, value)

    setFilteredItems(filteredItems)
  }

  return (
    <Dropdown
      ariaLabel={ariaLabel || 'FilterDropdown'}
      onClose={onCloseDropdown}
      autoOpen={autoOpen}
      triggerComponent={triggerComponent}
      sizeTriggerButton={sizeTriggerButton}
      margin={margin}
      width={width}
      parentsDropdownId={parentsDropdownId}
      iconButtonProps={iconButtonProps}
      placement={placement}
      variantTriggerButton={variantTriggerButton}
      tooltipProps={
        tooltipProps || {
          label: tooltipLabel,
          placement: 'top-end',
        }
      }
      customComponent={(onClose) => {
        return (
          <DropdownContent noPadding>
            {!noSearch && (
              <DropdownTextInput
                textFieldProps={{
                  onChange: onSearch,
                  value: searchValue,
                  InputProps: {
                    placeholder: 'Search',
                  },
                  ariaLabel: 'DropdownFilterSearch',
                  rightActions: [makeTextFieldClear()],
                }}
              />
            )}

            {isLoading && <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />}
            <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={autoHeightMax}>
              {filteredItems.map((group) => {
                return (
                  <DropdownGroup
                    key={group.label}
                    title={group.label}
                    items={group.items}
                    isScroll={false}
                    onChange={(value) => {
                      closeAfterChange && onClose()
                      changeActiveFields(value)
                    }}
                  />
                )
              })}
            </Scrollbar>
            {!filteredItems.length && <div className={styles.noResult}>No results found</div>}
            {isValidElement(additionActions) &&
              cloneElement(additionActions as ReactElement, { onClose })}
          </DropdownContent>
        )
      }}
    />
  )
}
