import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownPlaceholder, EnumDropdownItemVariant, IDropdownItem, Typography } from 'shared/ui'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { billingStore } from 'entities/Billing'
import { CreditPrice } from 'entities/Billing/model/CreditPrice'
import styles from './styles.module.scss'

type IDropdownCreditsProps = {
  onChange?: (item: CreditPrice) => void
}

export const DropdownCredits: FC<IDropdownCreditsProps> = observer(({ onChange }) => {
  const [activeId, setActiveId] = useState<number | null>(null)
  const activeItem = activeId ? billingStore.getCreditsPrice(activeId) : null
  const items: IDropdownItem[] = billingStore.creditsPrice.map((item) => {
    return {
      id: item.credits,
      label: `${item.credits}`,
      labelContent: (
        <Typography
          variant={'body-md-regular'}
          className={styles.dropdownItem}
          ariaLabel={'priceLabel'}
        >
          {item.creditsLabel} credits <span>(${item.priceLabel})</span>
        </Typography>
      ),
      activeValue: item.credits === activeId,
      variant: EnumDropdownItemVariant.CheckedRight,
    }
  })

  useEffect(() => {
    billingStore.fetchBillingAdditionalCreditPrice()
  }, [])

  useEffect(() => {
    if (items.length) {
      const itemPrice = billingStore.getCreditsPrice(Number(items[0].id))

      if (itemPrice && onChange) {
        setActiveId(itemPrice.credits)
        onChange(itemPrice)
      }
    }
  }, [items.length])

  return (
    <DropdownPlaceholder
      items={items}
      onChange={(item) => {
        const itemPrice = billingStore.getCreditsPrice(Number(item.id))

        if (itemPrice && onChange) {
          setActiveId(itemPrice.credits)
          onChange(itemPrice)
        }
      }}
      widthDropdown={440}
      placeholder={
        <Typography
          tag={'div'}
          variant={'text-input-field'}
          className={styles.dropdownActive}
          ariaLabel={'priceLabel'}
        >
          {activeItem?.creditsLabel} <span>${activeItem?.priceLabel}</span>
        </Typography>
      }
      textPlaceholderProps={{
        size: 'medium',
        variant: 'stroke',
        rightActions: [makeTextFieldIcon({ icon: 'chevronDown' })],
      }}
      label={{
        text: 'Credits',
        rightText: '3¢/credit',
      }}
      zIndex={5110}
    />
  )
})
