import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { Button, showToast } from 'shared/ui'
import type { IIntegrationLinkDTO } from 'entities/Integrations'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { Integration } from 'entities/Integrations/model/Integration'
import type { LinkContactStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/LinkContact/store/linkContactStore'
import type { MatchedContact } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/LinkContact/model/MatchedContact'
import styles from './styles.module.scss'

type ILinkWithPhonesModalActionsProps = {
  parentModalId: string
  modalId: string
  contact: Contact
  matchedContact: MatchedContact
  hubspotIntegration: Integration
  linkContactStore: LinkContactStore
}

export const LinkWithPhonesModalActions: FC<ILinkWithPhonesModalActionsProps> = observer(
  ({ modalId, parentModalId, contact, matchedContact, hubspotIntegration, linkContactStore }) => {
    const { onLink, shouldUpdate, loading, selectedMatchedPhone } = linkContactStore

    const handleClosePhonesModal = () => {
      modalStore.removeModal(modalId)
    }

    const handleLinkMatching = async () => {
      const data: IIntegrationLinkDTO = {
        integration_id: hubspotIntegration.id,
        integration_key: hubspotIntegration.key,
        integration_name: hubspotIntegration.name,
        contact_id: contact.id,
        contact_integration_id: matchedContact.integrationVendorId,
        first_name: matchedContact?.first_name || '',
        last_name: matchedContact?.last_name || '',
        email: matchedContact?.email || '',
        number: selectedMatchedPhone || '',
        update_contact: shouldUpdate,
      }

      const toast = await onLink(data)

      showToast(toast)

      if (toast.type === 'success') {
        handleClosePhonesModal()
        modalStore.removeModal(parentModalId)
      }
    }

    return (
      <div className={classnames(styles.actionsWrap)}>
        <Button
          contained='secondary'
          text='Cancel'
          onClick={handleClosePhonesModal}
          disabled={loading}
        />
        <Button
          loading={loading}
          contained='primary'
          text='Confirm link'
          disabled={loading}
          onClick={handleLinkMatching}
        />
      </div>
    )
  }
)
