import type {
  IResponseFiltersListSegment,
  IResponseFilterSegment,
  IOrganizationCustomFilter,
  IOrganizationFilter,
} from 'entities/Segment'
import { FilterGroup, FilterGroups } from 'widgets/FilterEditor/model'
import { IFilterGroup } from 'widgets/FilterEditor/types'

const hubspot_keys = ['hubspot', 'hubspot-lists']

export const checkHubspotList = (groups: FilterGroups) => {
  return groups.groups.some((group) =>
    group.filters.some((filter) => hubspot_keys.includes(filter.key))
  )
}

export const filterGroupsHubspot = (
  groups: FilterGroups,
  filterGroups: IFilterGroup[],
  isHubspot = false,
  count = 0
) => {
  const is_hubspot = checkHubspotList(groups) || isHubspot
  const is_count = groups.count || count

  if (is_hubspot) {
    return filterGroups.filter((filterGroup) =>
      filterGroup.items.some((item) => hubspot_keys.includes(item.id as string))
    )
  }

  if (is_count) {
    return filterGroups.filter((filterGroup) =>
      filterGroup.items.some((item) => !hubspot_keys.includes(item.id as string))
    )
  }

  return filterGroups
}

export const groupsToSegmentFilters = (groups: FilterGroup[]): IResponseFiltersListSegment => {
  const filtersList = groups
    .filter((group) => !group.isEmpty)
    .map(({ filters }) => {
      const segment = filters.reduce(
        (segment, filter) => {
          const key = filter.key
          const payloadFilter = filter.payloadFilter

          if (key.startsWith('custom.')) {
            const customFilter = payloadFilter as IOrganizationCustomFilter | null

            if (customFilter) segment.custom_filters.push(customFilter)
          } else if (key === 'hubspot' || key === 'hubspot-lists') {
            if (payloadFilter)
              segment.integration_lists.push({
                integration_key: key,
                list_id: payloadFilter.value as string,
              })
          } else {
            const organizationFilter = payloadFilter as IOrganizationFilter | null

            if (organizationFilter) {
              delete organizationFilter.type

              segment.filters.push(organizationFilter)
            }
          }

          return segment
        },
        {
          filters: [],
          custom_filters: [],
          filter_tags: [],
          integration_lists: [],
        } as Required<IResponseFilterSegment>
      ) as IResponseFilterSegment

      if (!segment.integration_lists?.length) delete segment.integration_lists

      return segment
    })

  return { filtersList }
}
