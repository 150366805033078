import { makeAutoObservable } from 'mobx'

import { ICommonField } from '../api'

export class CustomField {
  key!: string
  type!: string
  name!: string
  visible!: boolean
  editable!: boolean

  origin!: ICommonField
  searchFragments: string[] = []

  constructor(item: ICommonField) {
    this.syncOrigin(item)

    makeAutoObservable(this)
  }

  syncOrigin = (item: ICommonField) => {
    this.key = item.key
    this.type = item.type
    this.name = item.name
    this.visible = !!item.visible
    this.editable = typeof item.visible === 'boolean' ? item.editable : true

    this.origin = item
    this.searchFragments = [item.name]
  }
}
