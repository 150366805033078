// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E7QtEXVcE3bkEMHr_0JG{font-size:13px;font-weight:500;line-height:16px;margin-right:28px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/SelectedContent/HeaderLeftContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".container {\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 16px;\n    margin-right: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "E7QtEXVcE3bkEMHr_0JG"
};
export default ___CSS_LOADER_EXPORT___;
