import styles from './styles.module.scss'

interface IKeywordItemProps {
  item: {
    name: string
  }
}

export const KeywordItem = ({ item }: IKeywordItemProps) => {
  return <div className={styles.keywordContainer}>{item.name}</div>
}
