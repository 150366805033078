import { makeAutoObservable, observable, reaction } from 'mobx'
import React from 'react'
import { IAIActionTypeEnum } from 'entities/AIAssistant'
import {
  EmojiAction,
  EnumVariantMessageField,
  makeSavedReplyInputAction,
  mergeFieldReplacer,
  MessageFieldStore,
  SavedRepliesAction,
  ShortenLinkAction,
} from 'widgets/MessageField'
import { MediaAction } from 'widgets/MessageField/ui/FieldActions/MediaAction/MediaAction'
import { MergeFieldsIconAction } from 'widgets/MergeField/ui/actions/MergeFieldsIconAction/MergeFieldsIconAction'
import { MergeField } from 'widgets/MergeField/types/MergeField'
import {
  AIAssistantAction,
  AiAssistantStore,
  getAiAssistantPropsFromMessageField,
} from 'widgets/AIAssistant'
import { IMessageDataParams } from 'widgets/MessageFormFields/SMSMessageField/store/type'

export type ISMSMessageFieldStoreProps = {
  onFocus: () => void
  isCurrentAirCall?: boolean
}

export class SMSMessageFieldStore {
  messageFieldStore
  aiAssistantStore
  isDisabledObj = observable.object({ value: true })

  constructor({ onFocus, isCurrentAirCall }: ISMSMessageFieldStoreProps) {
    this.messageFieldStore = new MessageFieldStore({
      isCurrentAirCall,
      placeholder: 'Write your message...',
      minHeight: 106,
      variant: EnumVariantMessageField.Broadcast,
      showActionsItems: 5,
      makeInputActions: (store) => [makeSavedReplyInputAction(store)],
      makeEvents: () => ({
        onFocus,
      }),
      isShortUrlPostfix: true,
    })
    this.aiAssistantStore = new AiAssistantStore({
      ...getAiAssistantPropsFromMessageField(this.messageFieldStore),
      actions: [
        IAIActionTypeEnum.Rephrase,
        IAIActionTypeEnum.MoreFriendly,
        IAIActionTypeEnum.MoreFormal,
        IAIActionTypeEnum.Expand,
        IAIActionTypeEnum.Shorten,
      ],
    })
    this._setActions()

    makeAutoObservable(this)

    reaction(
      () => this.messageFieldStore.messageInnerText,
      (text) => {
        this.isDisabledObj.value = !text
      }
    )
  }

  private _setActions = () => {
    this.messageFieldStore?.setActions([
      {
        iconButtonComponent: <MediaAction />,
      },
      {
        iconButtonComponent: <SavedRepliesAction />,
      },
      {
        iconButtonComponent: <EmojiAction />,
      },
      {
        iconButtonComponent: (
          <AIAssistantAction
            aiAssistantStore={this.aiAssistantStore}
            placementMainContent={'bottom-start'}
            isDisabledObj={this.isDisabledObj}
          />
        ),
      },
      {
        iconButtonComponent: (
          <MergeFieldsIconAction
            onAddMergeField={(value) => this.onAddMergeField(value)}
            isAllConnectedOrDisconnectedIntegration
          />
        ),
      },
      {
        iconButtonComponent: <ShortenLinkAction />,
      },
    ])
  }

  get isError() {
    return (
      this.messageFieldStore.textLimitReached ||
      this.messageFieldStore.isAttachmentsCountLimitError ||
      this.messageFieldStore.isAirCallNoSupportMms
    )
  }

  get segments_count() {
    return this.messageFieldStore.messageRemainsInfo.messageCount
  }

  onAddMergeField = (mergeField: MergeField) => {
    if (this.messageFieldStore.addContent) {
      this.messageFieldStore.addContent(mergeFieldReplacer(mergeField.template) + '&nbsp;')
    }
  }

  get messageDataParams(): IMessageDataParams {
    return {
      media_url: this.messageFieldStore.media_url.map((item) => item.attachmentDraft),
      message: this.messageFieldStore.messageText,
    }
  }
}
