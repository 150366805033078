import React, { useState } from 'react'
import {
  Button,
  Dropdown,
  EnumDropdownItemVariant,
  IButtonProps,
  IDropdownItem,
  IDropdownProps,
} from 'shared/ui'
import styles from '../styles.module.scss'

export type ISelectMultiProps<T = string[]> = {
  type: 'multi'
  text: string
  values?: T
  selectedItems?: IDropdownItem[]
  onSelect?: (items: IDropdownItem[]) => void
  onSelectValues?: (values: T) => void
  items: IDropdownItem[]
  btnProps?: IButtonProps
  dropdownProps?: IDropdownProps
  fullWidth?: boolean
}

export const SelectMulti = ({
  btnProps,
  text,
  dropdownProps,
  items,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  values,
  selectedItems,
  onSelect,
  onSelectValues,
  fullWidth,
}: ISelectMultiProps<typeof values>) => {
  const [refBtn, setRefBtn] = useState<HTMLButtonElement | null>(null)
  const ids = values || selectedItems?.map((item) => item.id)

  const dropdownItems: IDropdownItem[] = items.map((item) => ({
    variant: EnumDropdownItemVariant.Checkbox,
    activeValue: ids?.includes(item.id),
    disabledClosedTooltip: true,
    isCardClick: true,
    labelWidth: '100%',
    ...item,
  }))

  const handleChange = (item: IDropdownItem) => {
    if (ids?.includes(item.id)) {
      if (onSelectValues) {
        onSelectValues(ids?.filter((value: string) => value !== item.id))
      }

      if (onSelect && selectedItems) {
        onSelect(selectedItems?.filter((selectedItem) => selectedItem.id !== item.id))
      }
    } else {
      if (onSelectValues) {
        onSelectValues([...ids, item.id])
      }
      if (onSelect && selectedItems) {
        onSelect([...selectedItems, item])
      }
    }
  }

  return (
    <Dropdown
      ariaLabel={'SelectMulti'}
      triggerComponent={(open) => (
        <Button
          ref={setRefBtn}
          text={text}
          size={'medium'}
          contained={'secondary'}
          iconProps={{ icon: 'chevronDown' }}
          isRight
          textClassname={styles.text}
          active={open}
          count={ids.length}
          hasCount={!!ids.length}
          {...btnProps}
        />
      )}
      maxHeight={300}
      items={dropdownItems}
      onChange={handleChange}
      placement={'bottom-start'}
      width={fullWidth ? refBtn?.clientWidth : undefined}
      {...dropdownProps}
    />
  )
}
