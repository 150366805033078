import React from 'react'
import { Icon, Tooltip, Typography } from 'shared/ui'
import { links } from 'shared/constants/links'
import type { Integration } from 'entities/Integrations/model/Integration'
import styles from './styles.module.scss'

type IntegrationLabelProps = {
  integration: Integration
}

export const IntegrationLabel = ({ integration }: IntegrationLabelProps) => {
  return (
    <div className={styles.integrationLabel}>
      <Typography ariaLabel={'IntegrationLabel'} variant={'body-md-regular'}>
        {integration.name}
      </Typography>
      {!integration.connected && <Icon className={styles.iconGap} icon={'alertTriangleYellow'} />}
    </div>
  )
}

export const CustomIntegrationLabel = () => {
  return (
    <div className={styles.integrationLabel}>
      Custom
      <CustomIntegrationInfoIcon />
    </div>
  )
}

export const CustomIntegrationInfoIcon = () => (
  <Tooltip
    className={styles.iconGap}
    width={196}
    placement={'top'}
    label={
      <Typography
        className={styles.tooltipText}
        ariaLabel={'CustomIntegrationTooltip'}
        variant={'body-md-regular'}
      >
        Send messages from a webhook using dynamic data
        <br />
        <a
          href={links.useCustomTriggers}
          onClick={(event) => event.stopPropagation()}
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </Typography>
    }
  >
    <Icon fontSize={12} icon={'question'} color={'var(--content-neutral-primary)'} />
  </Tooltip>
)
