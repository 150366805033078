import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { ConversationMessagesSection } from 'widgets/ConversationMessages/ui/ConversationMessagesSection'
import { EmptyConversationsState } from 'widgets/ConversationMessages/ui/EmptryConversationsState/EmptyConversationsState'

export const ConversationMessagesContent = observer(() => {
  const store = useConversationMessagesContext()
  const { conversationId } = useParams()
  const id =
    conversationId === 'null' || conversationId === undefined ? null : Number(conversationId)
  const isNumber = typeof id === 'number'
  const isCurrentConversationNumber = typeof store.currentConversationId === 'number'

  useEffect(() => {
    if (isNumber) {
      store.setConversationId(id)
    }

    return () => {
      store.resetCurrentConversationId()
      store.reset()
    }
  }, [id])

  return useMemo(
    () =>
      isCurrentConversationNumber ? <ConversationMessagesSection /> : <EmptyConversationsState />,
    [store.currentConversationId]
  )
})
