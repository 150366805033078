import { ModalTypeList, ModalType } from 'shared/ui/Modal/store/types'
import {
  InfoModalContent,
  InfoModalContentProps,
} from 'shared/ui/Modal/ui/InfoModalContent/InfoModalContent'
import { DefaultModal } from 'shared/ui/Modal/ui/DefaultModal/DefaultModal'

export const ModalContent = ({ modal, hide }: { modal: ModalType; hide: boolean }) => {
  const isDefault = !modal.type || modal.type === ModalTypeList.DEFAULT

  if (isDefault) {
    return <DefaultModal modal={modal} hide={hide} />
  }

  return <InfoModalContent modal={modal as InfoModalContentProps['modal']} />
}
