import { observer } from 'mobx-react-lite'
import React, { FC, useMemo } from 'react'
import { Button, Icon, SpinnerLoader } from 'shared/ui'
import type { SendTestModalStore } from 'widgets/TestSMS/ui/TestSmsWithMergeField'
import styles from './styles.module.scss'

type ISendTestModalActionProps = {
  store: SendTestModalStore
}

export const SendTestModalAction: FC<ISendTestModalActionProps> = observer(({ store }) => {
  const { onSendTest, completed, loading } = store
  const btnContent = useMemo(() => {
    if (completed) {
      return <Icon icon={'check1'} />
    }
    if (loading) {
      return <SpinnerLoader size={15} />
    }
    return 'Send test'
  }, [loading, completed])
  return (
    <Button
      text={btnContent}
      onClick={onSendTest}
      contained={'primary'}
      size={'medium'}
      className={styles.send}
    />
  )
})
