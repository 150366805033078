import { createContext, useContext } from 'react'
import { TriggerListStore } from 'widgets/TriggerList/store/triggerListStore'

export const TriggerListContext = createContext<TriggerListStore | null>(null)

export const useTriggerListContext = () => {
  const context = useContext(TriggerListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with TriggerListContext')

  return context
}
