import { action, computed, makeObservable, observable } from 'mobx'
import { debounce } from 'lodash'
import { dateValueOf } from 'shared/lib'
import { Base } from 'models'
import { contactsStore } from 'entities/Contacts'
import { usersStore } from 'entities/Users'
import type {
  IResponseConversation,
  IResponseConversationDraftMessage,
  IResponseConversationDraftMessageContact,
  IResponseConversationDraftMessageData,
} from 'entities/Conversation/api/types'
import { Message } from 'entities/Message/model/Message'
import type { IResponseContact } from 'entities/Contacts/api/types'
import { websocket } from 'entities/WebSocket'
import { logger } from 'entities/EventLog'
import { conversationStore } from 'entities/Conversation/store'
import { inboxesStore } from 'entities/Inbox'

export class Conversation extends Base {
  id: number
  contact_id!: number
  inbox_id!: number
  user_id!: number
  recent_message: Message | null = null
  last_message_at!: string
  unread_count!: number
  last_manual_message_at!: string
  last_inbound_at!: string
  last_read_at!: string
  checked = false
  is_group!: boolean
  is_favorite!: boolean
  is_need_response!: boolean
  is_locked!: boolean
  group_name!: string
  closed_at!: string
  started_at!: string
  participantsIds!: number[]
  number_id!: number
  scheduled_message_ids!: number[]
  origin!: IResponseConversation
  debounceOnCreateDraft?: ReturnType<typeof debounce>

  constructor(item: IResponseConversation) {
    super()

    this.id = item.id
    this.syncOrigin(item)

    makeObservable(this, {
      contact_id: observable,
      inbox_id: observable,
      closed_at: observable,
      started_at: observable,
      number_id: observable,
      participantsIds: observable,
      is_group: observable,
      is_favorite: observable,
      is_need_response: observable,
      is_locked: observable,
      recent_message: observable,
      scheduled_message_ids: observable,
      message: computed,
      handleChecked: action,
      checked: observable,
      last_message_at: observable,
      last_manual_message_at: observable,
      last_read_at: observable,
      lastMessageAtValueOf: computed,
      isUnread: computed,
      scheduleCount: computed,
      priority: computed,
      user_id: observable,
      unread_count: observable,
    })
  }

  cancelDebounceOnCreateDraft = () => {
    this.debounceOnCreateDraft?.cancel()
  }

  syncOrigin = (origin: IResponseConversation) => {
    this.id = origin.id
    this.contact_id = origin.contact_id
    this.inbox_id = origin.inbox_id
    this.user_id = origin.user_id
    this.closed_at = origin.closed_at
    this.started_at = origin.started_at
    this.is_group = origin.is_group
    this.is_need_response = origin.is_need_response || false
    this.is_locked = origin.id === 0 ? false : origin.is_locked ?? true
    this.is_favorite =
      origin.is_favorite === null
        ? typeof usersStore.user?.id === 'number'
          ? origin.favorite_for_users.includes(usersStore.user?.id)
          : false
        : origin.is_favorite
    this.group_name = origin.group_name || ''
    const isVoid = origin.recent_message?.type === 'call' && !origin.recent_message.body
    if (origin.recent_message && !isVoid) {
      this.recent_message = new Message(origin.recent_message)
    }
    this.last_message_at = origin.last_message_at || ''
    this.last_manual_message_at = origin.last_manual_message_at || ''
    this.last_inbound_at = origin.last_inbound_at || ''
    this.last_read_at = origin.last_read_at || ''
    this.unread_count = origin.unread_count || 0
    this.participantsIds = Array.isArray(origin.participants)
      ? origin.participants.map((participant: IResponseContact) => participant.id)
      : []
    this.number_id = origin.number_id
    this.scheduled_message_ids = origin.scheduled_message_ids || []
    this.origin = origin
    this.cancelDebounceOnCreateDraft()
    this.debounceOnCreateDraft = debounce(this.onCreateDraft, 4000)
  }

  handleChecked = (value?: boolean) => {
    const isBoolean = typeof value === 'boolean'
    if (isBoolean) {
      this.checked = value
      return
    }

    this.checked = !this.checked
  }

  setIsNeedResponse = (value: boolean) => {
    this.is_need_response = value
    this.origin.is_need_response = value
  }

  get lastMessageAtValueOf() {
    return dateValueOf(this.last_message_at)
  }

  get unreadCount() {
    return this.unread_count || 0
  }
  get isUnreadManual() {
    return this.unreadCount === 0 && !this.last_read_at
  }

  get isUnread() {
    if (this.id === 0) return false
    if (this.isUnreadManual) return false
    if (!this.last_read_at) return true
    if (this.unreadCount > 0) return true

    return this.last_read_at < this.last_message_at
  }

  get isNew() {
    return this.id === 0
  }

  get message() {
    return this.recent_message || null
  }

  get isStarted() {
    return !!this.started_at
  }

  get isClosed() {
    return !!this.closed_at
  }

  get isRead() {
    return !!this.last_read_at
  }

  get title() {
    const contact = contactsStore.getItem(this.contact_id)

    if (this?.is_group) {
      if (this.group_name) return this.group_name

      const participants = this.participantsIds.map((id) => contactsStore.getItem(id))

      return [contact, ...participants]
        .map((participant) => participant?.full_name || participant?.formatted_number)
        .join(', ')
    }

    return contact?.full_name || contact?.formatted_number
  }

  get contactsIds() {
    return [this.contact_id, ...this.participantsIds]
  }

  get isGroup() {
    return Boolean(this.is_group)
  }

  get scheduleCount() {
    return this.scheduled_message_ids?.length
  }

  get priority() {
    return this.is_favorite
  }

  get contact() {
    return contactsStore.getItem(this.contact_id)
  }

  get hasOpenFilter() {
    if (this.isStarted && !this.contact?.isOptOut && !this.isClosed) return true
    if (this.isStarted && !this.contact?.isOptOutCurrentInbox && !this.isClosed) return true

    return false
  }

  get hasUnreadFilter() {
    return !this.isClosed && this.isUnread
  }

  get hasOutboundFilter() {
    return !this.isClosed
  }

  get hasAssignedFilter() {
    return this.isStarted && usersStore.user.id === this.user_id && !this.isClosed
  }

  get hasNeedResponseFilter() {
    return Boolean(this.is_need_response) && this.hasOpenFilter
  }

  get hasClosedFilter() {
    return this.isClosed
  }

  get hasUnassignedFilter() {
    return this.user_id === 0 && !this.isClosed
  }

  get hasOptOutFilter() {
    return Boolean(this.contact?.isOptOut || this.contact?.isOptOutCurrentInbox)
  }

  get hasBlockedFilter() {
    return !!this.contact?.is_blocked
  }

  hasMentionsFilter(message?: Message) {
    if (this.isClosed) return false
    if (!message) return false

    return (
      message.body.includes(`{tag:user:${usersStore.user.id}}`) ||
      message.body.includes('{tag:inbox}')
    )
  }

  onAddScheduledMessageId = (id: number) => {
    this.scheduled_message_ids.push(id)
  }

  onRemoveScheduledMessageId = (removeId: number) => {
    this.scheduled_message_ids = this.scheduled_message_ids.filter((id) => id !== removeId)
    this.origin.scheduled_message_ids = this.scheduled_message_ids
  }

  updatePriority = (priority: boolean) => {
    this.is_favorite = priority
  }

  onCreateDraft = (messageData: IResponseConversationDraftMessageData) => {
    if (!usersStore.user) return
    if (!websocket.socket) return

    logger.info('CREATE [Draft]')

    const contacts = this.contactsIds.reduce<IResponseConversationDraftMessageContact[]>(
      (state, id) => {
        const contact = contactsStore.getItem(id)

        return contact ? [contact.dataDraft, ...state] : state
      },
      []
    )
    const inbox = inboxesStore.getItem(this.inbox_id)

    const draftLocal = {
      id: usersStore.user.id,
      message: messageData,
      contacts: contacts,
      conversation_id: this.id,
      inbox_id: Number(inbox?.id),
      inbox_type: Number(inbox?.inboxType),
      socketId: String(websocket.socket.sessionID),
    }

    conversationStore.addItemDraft(draftLocal)

    websocket.sendEvent<IResponseConversationDraftMessage>(
      `private-user.${usersStore.user.id}`,
      'client-draft',
      draftLocal
    )
  }
}
