// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E98GNFQju6Pi8Q0OtPxg{display:flex;flex-direction:column;gap:8px}.jnCcMZwryqlfpL7F6rSs [aria-label=MessageField]:not(:focus-within){border-color:var(--red-80)}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotEdit/ChatbotActions/ActionNote/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,OAAA,CAKI,mEACI,0BAAA","sourcesContent":[".message {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.field {\n    &Error {\n        & [aria-label=\"MessageField\"]:not(:focus-within) {\n            border-color: var(--red-80);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "E98GNFQju6Pi8Q0OtPxg",
	"fieldError": "jnCcMZwryqlfpL7F6rSs"
};
export default ___CSS_LOADER_EXPORT___;
