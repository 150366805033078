import { BroadcastOneTime } from 'entities/Broadcast'
import { BroadcastDetailModalContext } from './context/context'
import { BroadcastDetailModalContent } from './ui/BroadcastDetailModalContent'

type IBroadcastDetailModalProps = {
  broadcast: BroadcastOneTime
}

export const BroadcastDetailModal = ({ broadcast }: IBroadcastDetailModalProps) => {
  return (
    <BroadcastDetailModalContext.Provider value={broadcast}>
      <BroadcastDetailModalContent />
    </BroadcastDetailModalContext.Provider>
  )
}
