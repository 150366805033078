import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import {
  ITenDlcBannerResponse,
  ITenDlcLimitsResponse,
  ITenDlcUseCasesRequestParams,
  ITenDlcUseCasesResponse,
} from 'entities/TenDlc/api/type'

class Api {
  getTenDlcLimits(): Promise<AxiosResponse<ITenDlcLimitsResponse>> {
    return baseApi.get('organization/10-dlc/limits')
  }
  getTenDlcUseCases(
    params?: ITenDlcUseCasesRequestParams
  ): Promise<AxiosResponse<ITenDlcUseCasesResponse[]>> {
    return baseApi.get('organization/10-dlc/use-cases', { params })
  }
  updateTenDlcBanner(): Promise<AxiosResponse<ITenDlcBannerResponse>> {
    return baseApi.post('users/dlc-banner-showed', { banner_showed: true })
  }
}

export const TenDlcApi = new Api()
