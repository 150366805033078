import { parsePhoneNumber, formatIncompletePhoneNumber } from 'libphonenumber-js'

export const validPhoneNumber = (value: string) => {
  try {
    const numberInfo = parsePhoneNumber(value, 'US')

    return numberInfo.isValid()
  } catch (e) {
    return false
  }
}

export const formatPhoneNumberNational = (value: string) => {
  try {
    const numberInfo = parsePhoneNumber(value, 'US')

    return numberInfo.formatNational()
  } catch (e) {
    return value
  }
}

export const formatPhoneNumberInternational = (value: string) => {
  try {
    const numberInfo = parsePhoneNumber(value, 'US')

    return numberInfo.nationalNumber
  } catch (e) {
    return value
  }
}

export const formatPhoneNumberIncomplete = (value: string) => {
  try {
    return formatIncompletePhoneNumber(value, 'US')
  } catch (e) {
    return value
  }
}

export const formatPhoneNumberInternationalNational = (value: string) => {
  try {
    const numberInfo = parsePhoneNumber(value, 'US')

    return Number(numberInfo.nationalNumber)
  } catch (e) {
    return Number(value)
  }
}
