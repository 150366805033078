import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import {
  useEventAttachmentUploadError,
  useEventAttachmentUploadSuccessful,
} from 'entities/Attachment'
import { useRinglessMessageFieldContext } from 'widgets/MessageFormFields/RinglessMessageField/context/ringlessMessageFieldContext'
import { RinglessMessageFieldActions } from 'widgets/MessageFormFields/RinglessMessageField/ui/RinglessMessageFieldActions/RinglessMessageFieldActions'
import { UploadingAudio } from 'widgets/MessageFormFields/RinglessMessageField/ui/UploadingAudio/UploadingAudio'
import { RinglessAudio } from 'widgets/MessageFormFields/RinglessMessageField/ui/RinglessAudio/RinglessAudio'
import { TextToSpeech } from 'widgets/TextToSpeech'
import styles from './styles.module.scss'
import { Placeholder } from './Placeholder/Placeholder'

export const RinglessMessageFieldContent = observer(() => {
  const {
    loadingAudio,
    onAttachmentUploadSuccessful,
    onAttachmentUploadError,
    attachment,
    textToSpeechStore,
    reset,
  } = useRinglessMessageFieldContext()

  useEventAttachmentUploadSuccessful(onAttachmentUploadSuccessful)
  useEventAttachmentUploadError(onAttachmentUploadError)

  useEffect(() => reset, [])

  const content = useMemo(() => {
    if (loadingAudio) {
      return <UploadingAudio />
    }
    if (attachment) {
      return <RinglessAudio />
    }
    if (textToSpeechStore) {
      return (
        <div className={styles.wrapSpeech}>
          <TextToSpeech store={textToSpeechStore} />
        </div>
      )
    }
    return (
      <>
        <Placeholder />
        <RinglessMessageFieldActions />
      </>
    )
  }, [loadingAudio, attachment, textToSpeechStore])
  return <div className={styles.wrap}>{content}</div>
})
