import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Label } from 'shared/ui/Label'
import { TextField } from 'shared/ui/TextField'
import { AdminCustomPricesForm } from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'
import { ManagePlanFormFields } from 'pages/admin/ui/ManagePlan/ManagePlanForm'

type CustomPriceFormProps = {
  adminCustomPricesForm: AdminCustomPricesForm
  edit?: boolean
}

export const CustomPriceForm = observer(({ adminCustomPricesForm, edit }: CustomPriceFormProps) => {
  const { name, setName } = adminCustomPricesForm

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          <Label
            label={{
              text: 'Name',
            }}
          />
          <TextField
            variant={'stroke'}
            size={'medium'}
            InputProps={{
              placeholder: 'Enter name',
            }}
            value={name || undefined}
            onChange={setName}
          />
        </Grid>

        <ManagePlanFormFields store={adminCustomPricesForm} edit={edit} additional />
      </Grid>
    </>
  )
})
