// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ATELI6QCWCD_7HB6TdKz{color:var(--content-primary-primary)}.ATELI6QCWCD_7HB6TdKz span{color:var(--content-primary-disabled)}.YPudyy03YLGjuD_coovw{display:flex;justify-content:space-between;color:var(--content-primary-primary)}.YPudyy03YLGjuD_coovw span{color:var(--content-primary-disabled)}", "",{"version":3,"sources":["webpack://./src/widgets/BuyCreditsModal/ui/DropdownCredits/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oCAAA,CAEA,2BACE,qCAAA,CAIJ,sBACE,YAAA,CACA,6BAAA,CACA,oCAAA,CAEA,2BACE,qCAAA","sourcesContent":[".dropdownItem {\n  color: var(--content-primary-primary);\n\n  span {\n    color: var(--content-primary-disabled);\n  }\n}\n\n.dropdownActive {\n  display: flex;\n  justify-content: space-between;\n  color: var(--content-primary-primary);\n\n  span {\n    color: var(--content-primary-disabled);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownItem": "ATELI6QCWCD_7HB6TdKz",
	"dropdownActive": "YPudyy03YLGjuD_coovw"
};
export default ___CSS_LOADER_EXPORT___;
