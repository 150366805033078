import React, { MouseEvent } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon, IconButton } from 'shared/ui'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { linkContactStore } from '../../store/linkContactStore'
import { MatchedContact } from '../../model/MatchedContact'
import styles from './styles.module.scss'

type IIntegrationContactCardProps = {
  contacts: MatchedContact[]
}

const MatchedContactCard = observer(({ contacts }: IIntegrationContactCardProps) => {
  const { selectedMatchedId, handleMatchedContactId } = linkContactStore

  const integration = integrationsStore.getUserIntegration(IntegrationKey.hubspot)
  const providerIdentifier = integration?.user_credential?.provider_identifier
  const isSelected = (id: string) => selectedMatchedId === id

  const handleViewContact = (e: MouseEvent<HTMLButtonElement>, contactId: string) => {
    if (!providerIdentifier) return

    e.stopPropagation()
    window.open(
      `https://app.hubspot.com/contacts/${providerIdentifier}/contact/${contactId}`,
      '_blank'
    )
  }

  return (
    <div className={classnames(styles.wrap)}>
      <div className={styles.header}>
        <span className={styles.icon}>
          <Icon icon={'hubspot'} fontSize={11} />
        </span>
        <span className={styles.title}>{'HubSpot'}</span>
      </div>
      {contacts.map((contact, idx) => (
        <div
          key={contact.integrationVendorId}
          className={classnames(styles.content, {
            [styles.selected]: isSelected(contact.integrationVendorId),
            [styles.last]: idx + 1 === contacts.length,
          })}
          onClick={() => handleMatchedContactId(contact.integrationVendorId)}
        >
          <div className={styles.avatar}>
            {isSelected(contact.integrationVendorId) ? (
              <div className={styles.selectedIcon}>
                <Icon
                  icon='checkboxChecked'
                  fontSize={16}
                  color='var(--content-primary-primary-inverted)'
                />
              </div>
            ) : (
              <div className={styles.defaultIcon}>
                <Icon
                  icon='radioButtonUncheckedFilled'
                  fontSize={24}
                  color='var(--interaction-neutral-hovered)'
                />
              </div>
            )}
          </div>
          <div className={styles.user}>
            <div className={styles.nameWrap}>
              <span className={styles.name}>{contact.name || 'View contact'}</span>
              <div className={styles.linkIcon}>
                <IconButton
                  size={'small'}
                  color={'tertiary'}
                  variant={'icon'}
                  icon='linkExternal'
                  tooltipProps={{
                    placement: 'top',
                    label: 'Open in HubSpot',
                    disableInteractive: true,
                  }}
                  ariaLabel='OpenInHubSpot'
                  onClick={(e) => handleViewContact(e, contact.integrationVendorId)}
                />
              </div>
            </div>
            <span className={styles.email}>{contact.email || '-'}</span>
          </div>
          <div className={styles.phonesWrapper}>
            <div className={styles.phone}>
              <span className={styles.label}>Phone</span>
              <span className={styles.value}>{contact.phone || '-'}</span>
            </div>
            <div className={styles.phone}>
              <span className={styles.label}>Mobile Phone</span>
              <span className={styles.value}>{contact.mobile_phone || '-'}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
})

export { MatchedContactCard }
