import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { CallList } from 'widgets/CallPopUp/ui/CallList/CallList'
import { CallSection } from 'widgets/CallPopUp/ui/CallSection/CallSection'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import styles from './styles.module.scss'

export const CallTransferPopUpContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const {
    isTransferWaiting,
    handleTransferCall,
    search,
    handleSearch,
    inboxesList,
    isValidNumber,
    resetTransfer,
    reactionTransferSearch,
  } = store

  useEffect(() => {
    reactionTransferSearch()

    return () => {
      resetTransfer()
    }
  }, [])

  if (isTransferWaiting) {
    return (
      <div className={styles.wait}>
        <div className={styles.wait__label}>Transferring call...</div>
      </div>
    )
  }

  return (
    <CallSection title={'Transfer call'}>
      {!isTransferWaiting && (
        <CallList
          placeholder={'Search inbox or dial a number'}
          searchValue={search}
          onChangeSearch={handleSearch}
          itemsGroups={[
            {
              name: 'Inboxes',
              items: inboxesList,
            },
          ]}
          onChangeItem={(item) => {
            handleTransferCall(item)
          }}
          isValidNumber={isValidNumber}
          isTransfer={true}
        />
      )}
    </CallSection>
  )
})
