import { makeAutoObservable, runInAction } from 'mobx'
import { IResponseFilters } from 'entities/Contacts/api/filterTypes'
import { IFilterGroup } from 'widgets/FilterEditor/types'
import { FilterGroups, FiltersConfig } from 'widgets/FilterEditor/model'
import { checkHubspotList } from 'widgets/FilterEditor/integration'

export interface IFilterStoreProps {
  makeRequest: () => Promise<IResponseFilters>
  mutateFilterGroups?: (groups: FilterGroups, filterGroups: IFilterGroup[]) => IFilterGroup[]
  showSpawned?: boolean
  viewOnly?: boolean
}

export class FilterEditorStore {
  private _loading = true
  private _viewOnly = false
  private _showSpawned = false

  private _mutateFilterGroups: NonNullable<IFilterStoreProps['mutateFilterGroups']> | null = null
  private _makeRequest: NonNullable<IFilterStoreProps['makeRequest']>

  private _futureData: Promise<IResponseFilters> | null = null
  private _blockReason: React.ReactNode = null

  private _config = new FiltersConfig()
  private _groups = new FilterGroups(this._config)

  constructor(props: IFilterStoreProps) {
    this._makeRequest = props.makeRequest
    this._mutateFilterGroups = props.mutateFilterGroups ?? null

    this._viewOnly = props.viewOnly ?? false
    this._showSpawned = props.showSpawned ?? this._showSpawned

    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get config() {
    return this._config
  }

  get filterGroups() {
    return (
      this._mutateFilterGroups?.(this._groups, this._config.filterGroups) ??
      this._config.filterGroups
    )
  }

  get filterGroupsOrigin() {
    return this._config.filterGroups
  }

  get groups() {
    return this._groups
  }

  get viewOnly() {
    return this._viewOnly
  }

  get showSpawned() {
    return this._showSpawned
  }

  get isReady() {
    return !this._groups.isEmpty && this._groups.isReady
  }

  get blockReason() {
    return this._blockReason
  }

  get isHubspotList() {
    return checkHubspotList(this._groups)
  }

  initFilters = async () => {
    if (this._futureData) return this._futureData.then()

    this._loading = true
    this._futureData = this._makeRequest()

    try {
      const data = await this._futureData
      runInAction(() => {
        this._config.populate(data)
        this._loading = false
      })
    } catch (error) {
      console.error(error)

      runInAction(() => {
        this._loading = false
      })
    }
  }

  setViewOnly(value: boolean) {
    this._viewOnly = value
  }

  setBlock(reason: React.ReactNode) {
    this._blockReason = reason
  }

  removeBlock() {
    this._blockReason = null
  }

  setShowLast(showLast: boolean) {
    this._showSpawned = showLast
  }

  clearFutureData = () => {
    this._futureData = null
  }

  dispose = () => {
    this.groups.clear()
  }
}
