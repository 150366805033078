import { Icon, Tooltip } from 'shared/ui'
import { links } from 'shared/constants/links'
import styles from './styles.module.scss'

export const BroadcastBadNumberTooltip = () => {
  return (
    <Tooltip
      type={'description'}
      label={
        <>
          Validate numbers with PhoneCheckr
          <br />
          before sending to boost visibility
          <br />
          <a href={`${links.phoneСheckr}`} target='_blank' rel='noreferrer'>
            Learn more
          </a>
        </>
      }
      placement={'top'}
      width={239}
      className={styles.tooltip}
    >
      <div className={styles.info}>
        <Icon icon={'question'} fontSize={12} />
      </div>
    </Tooltip>
  )
}
