import React, { useMemo } from 'react'
import parse from 'html-react-parser'
import { Typography } from 'shared/ui'
import type { Attachment } from 'entities/Attachment/model/Attachment'
import { Attachments } from 'widgets/Attachments'
import { textToHtml } from 'widgets/MessageField'
import styles from './styles.module.scss'

type ISMSContentProps = {
  attachments: Attachment[]
  message: string
}

export const SMSContent = ({ attachments, message }: ISMSContentProps) => {
  const messageParse = useMemo(() => {
    if (message) {
      return parse(
        textToHtml({
          text: message,
          replaceNewRow: true,
        })
      )
    }
  }, [message])
  return (
    <div className={styles.wrap}>
      {!!attachments?.length && (
        <div className={styles.wrapAttachments}>
          <Attachments attachments={attachments} />
        </div>
      )}
      {messageParse && (
        <Typography
          variant={'body-rg-regular'}
          className={styles.message}
          tag={'div'}
          ariaLabel={'message'}
        >
          {messageParse}
        </Typography>
      )}
    </div>
  )
}
