import { makeAutoObservable } from 'mobx'

import { FilterItem } from 'widgets/FilterEditor/model/FilterItem'
import type { FilterGroups } from 'widgets/FilterEditor/model/FilterGroups'
import type { FiltersConfig } from 'widgets/FilterEditor/model/FiltersConfig'

export class FilterGroup {
  private _filters = new Array<FilterItem>()

  constructor(private _parent: FilterGroups, private _config: FiltersConfig) {
    makeAutoObservable(this)
  }

  get filters() {
    return Array.from(this._filters)
  }

  get firstFilter(): FilterItem | null {
    return this._filters[0] ?? null
  }

  get lastFilter(): FilterItem | null {
    return this._filters[this._filters.length - 1] ?? null
  }

  get count() {
    return this._filters.length
  }

  get countWithValue() {
    return this._filters.filter((filter) => !filter.isEmpty).length
  }

  get countWithOutValue() {
    return this._filters.filter((filter) => filter.isEmpty).length
  }

  get isEmpty() {
    return this._filters.length === 0
  }

  get isFirst() {
    const index = this._parent.groups.indexOf(this)

    return index === 0
  }

  get isLast() {
    const index = this._parent.groups.indexOf(this)

    return this._parent.groups.length - index === 1
  }

  createFilter(key: string, silent = false) {
    const config = this._config.getAnyFilter(key)
    if (!config) return this._parent.clearSpawnedFilter()

    const operators = this._config.getOperators(config.type) ?? []
    const item = { key, type: config.type, operator: operators[0]?.key ?? null }

    const filter = new FilterItem(item, operators, config)

    if (!silent) this._parent.setSpawnedFilter(filter)

    this._filters.push(filter)

    return filter
  }

  removeFilter(filter: FilterItem) {
    filter.dispose()
    this._filters = this._filters.filter((current) => current !== filter)
  }

  delete() {
    this._parent.removeGroup(this)
  }

  clear() {
    this._filters = []
  }

  populateJson(data: Record<string, unknown>[]) {
    data.map((item) => {
      if (typeof item.key !== 'string') return
      this.createFilter(item.key, true)?.populateJson(item)
    })
  }
}
