import { BusinessProfile } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BusinessProfile/BusinessProfile'
import { BrandInformation } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BrandInformation/BrandInformation'
import { Campaign } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/Campaign'
import styles from './styles.module.scss'

export const TenDLCModalContent = () => {
  return (
    <div className={styles.wrap}>
      <BusinessProfile />
      <BrandInformation />
      <Campaign />
    </div>
  )
}
