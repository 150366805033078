// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".srQOCQkvpxN9dYrHwgDQ,.BLgB6fPkqowEfx3dTVd5{display:flex;padding-block:8px;border-bottom:1px solid var(--interaction-neutral-normal)}.srQOCQkvpxN9dYrHwgDQ:hover,.BLgB6fPkqowEfx3dTVd5:hover{border-bottom:1px solid var(--interaction-neutral-hovered)}.srQOCQkvpxN9dYrHwgDQ.rmDz2Fhv5zeo1SAk3MwZ,.BLgB6fPkqowEfx3dTVd5.rmDz2Fhv5zeo1SAk3MwZ{border-bottom:1px solid var(--interaction-brand-active);box-shadow:0px 2px 0px 0px var(--interaction-brand-focus)}.srQOCQkvpxN9dYrHwgDQ.CrVq9DrpIaaSN1qCyAcQ,.BLgB6fPkqowEfx3dTVd5.CrVq9DrpIaaSN1qCyAcQ{border-bottom:1px solid var(--red-60);box-shadow:none;margin-bottom:4px}.BLgB6fPkqowEfx3dTVd5{width:100%}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/BroadcastRingless/ui/BroadcastRinglessActions/styles.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,iBAAA,CACA,yDAAA,CACA,wDACE,0DAAA,CAEF,sFACE,uDAAA,CACA,yDAAA,CAGF,sFACE,qCAAA,CACA,eAAA,CACA,iBAAA,CAGJ,sBACE,UAAA","sourcesContent":[".wrap, .recordAudio{\n  display: flex;\n  padding-block: 8px;\n  border-bottom: 1px solid var(--interaction-neutral-normal);\n  &:hover{\n    border-bottom: 1px solid var(--interaction-neutral-hovered);\n  }\n  &.active{\n    border-bottom: 1px solid var(--interaction-brand-active);\n    box-shadow: 0px 2px 0px 0px var(--interaction-brand-focus);\n\n  }\n  &.errorState{\n    border-bottom: 1px solid var(--red-60);\n    box-shadow: none;\n    margin-bottom: 4px;\n  }\n}\n.recordAudio{\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "srQOCQkvpxN9dYrHwgDQ",
	"recordAudio": "BLgB6fPkqowEfx3dTVd5",
	"active": "rmDz2Fhv5zeo1SAk3MwZ",
	"errorState": "CrVq9DrpIaaSN1qCyAcQ"
};
export default ___CSS_LOADER_EXPORT___;
