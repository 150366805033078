import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { Step3Store } from 'widgets/Register/ui/Step3/store/step3Store'

import styles from './styles.module.scss'

type IProps = {
  store: Step3Store
}

export const InfoModalAlert = observer(({ store }: IProps) => {
  return (
    <div className={styles.wrap}>
      <Alert
        item={{
          type: 'infoBlue',
          title: 'Thank you for your interest in Salesmsg!',
          desc: `Salesmsg is coming soon to ${store.notifiedCountries}. We’ll notify you first once that happens. In the meantime, you can get a US or Canada number for your business.`,
        }}
      />
    </div>
  )
})
