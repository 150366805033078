import React from 'react'
import { observer } from 'mobx-react-lite'
import { Breadcrumbs, ContainerHeader, variantActionsProps } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { getPowerDialerActions } from 'widgets/PowerDialer/lib/getPowerDialerActions'
import { PowerDialerStatusBadge } from 'widgets/PowerDialer'
import { usePowerDialerViewContext } from 'pages/powerDialer/pages/view/context/powerDialerViewContext'
import { PowerDialerRoutes } from 'pages/powerDialer/route/type'
import styles from './styles.module.scss'

export const PowerDialerViewHeader = observer(() => {
  const { powerDialer, pageLayoutStore } = usePowerDialerViewContext()
  const { titleIconProps, headerMenuIconAction, headerMenuTooltipIconProps } = pageLayoutStore

  if (!powerDialer) return null

  const { name, status } = powerDialer

  const onSuccessDelete = () => {
    uiStore.changeRoute({ path: `${PowerDialerRoutes.root}` })
  }

  return (
    <div>
      <Breadcrumbs customPath={`/${PowerDialerRoutes.root}`} />
      <ContainerHeader
        title={name}
        titleVariant={'heading-md'}
        titleIconProps={titleIconProps}
        titleIconAction={headerMenuIconAction}
        actionIconTooltipProps={headerMenuTooltipIconProps}
        actionsProps={{
          ...variantActionsProps['headerMedium'],
          showItems: 2,
          actions: getPowerDialerActions(powerDialer, onSuccessDelete),
        }}
        ellipsis
        className={styles.header}
      />
      <div className={styles.statusBadge}>
        <PowerDialerStatusBadge status={status} />
      </div>
    </div>
  )
})
