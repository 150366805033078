import classnames from 'classnames'
import { CopyWrapper } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IResponseActiveCampaignAccounts,
  IntegrationKey,
} from 'entities/Integrations'

import { Contact } from 'entities/Contacts/model/Contact'
import styles from '../styles.module.scss'

type IAccountsProps = {
  accounts: IResponseActiveCampaignAccounts
  contact: Contact
}

const EmptyAccounts = () => <div className={styles.emptyData}>No account found</div>

const Accounts = ({ accounts, contact }: IAccountsProps) => {
  if (accounts.id === '0') return <EmptyAccounts />

  const userIntegration = integrationsStore.getUserIntegration(IntegrationKey.activecampaign)

  const linkProps = {
    key: IntegrationKey.activecampaign,
    contact,
    integration: userIntegration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = integrationLink(linkProps)

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={accounts.name || null}
          data={{
            title: accounts.name || 'View account',
            link,
          }}
        />
      </div>
    </div>
  )
}

export { Accounts }
