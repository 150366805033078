// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q00Lju8hLPYJ53mworRZ{width:2px;height:2px;border-radius:50%;background-color:var(--content-primary-disabled)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/TimezoneContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,UAAA,CACA,iBAAA,CACA,gDAAA","sourcesContent":[".dot {\n  width: 2px;\n  height: 2px;\n  border-radius: 50%;\n  background-color: var(--content-primary-disabled);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dot": "Q00Lju8hLPYJ53mworRZ"
};
export default ___CSS_LOADER_EXPORT___;
