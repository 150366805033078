import { observer } from 'mobx-react-lite'
import { useEffect, useState, FC } from 'react'
import { MenuTitle, SpinnerLoader, Typography, MenuItem } from 'shared/ui'
import { inboxesStore } from 'entities/Inbox'
import { IParamsGetCallHistoryStatistic } from 'entities/CallHistory/api/types'
import { filtersStore } from 'widgets/CallHistoryDropdownFilter'
import styles from 'widgets/ConversationLayout/ui/styles.module.scss'
import { CallHistoryStore } from 'widgets/CallHistory'

type ICallHistoryFiltersListProps = {
  store: CallHistoryStore
}

export const CallHistoryFiltersList: FC<ICallHistoryFiltersListProps> = observer(({ store }) => {
  const [isShowInboundFilters, setIsShowInboundFilters] = useState<boolean>(true)
  const [isShowOutboundFilters, setIsShowOutboundFilters] = useState<boolean>(true)
  const { callHistoryFiltersListStore, resetLoadingState } = store
  const {
    loading,
    hasCalls,
    allCallsUnreadCount,
    inboundAnsweredUnreadCount,
    inboundMissedUnreadCount,
    inboundVoicemailUnreadCount,
    outboundAnsweredUnreadCount,
    outboundNoAnsweredUnreadCount,
    isActiveAllCalls,
    isActiveInboundAnswered,
    isActiveInboundMissed,
    isActiveInboundVoicemail,
    isActiveOutboundAnswered,
    isActiveOutboundNoAnswered,
    handleSelectFilter,
    loadData,
  } = callHistoryFiltersListStore

  const params: IParamsGetCallHistoryStatistic = {
    team_id: inboxesStore.currentInboxTypeId,
    team_type: inboxesStore.currentInboxType || 3,
    group: filtersStore.filter?.key === 'all' ? null : filtersStore.filter?.key,
  }

  useEffect(() => {
    loadData(params).then((data) => {
      if (data?.data?.count === 0) {
        resetLoadingState()
      }
    })
  }, [inboxesStore.currentInboxTypeId, filtersStore.filter?.key])

  return (
    <div className={styles.callsFiltersInfo}>
      {loading && (
        <div className={styles.listStatus}>
          <SpinnerLoader />
        </div>
      )}

      {!loading && hasCalls && (
        <>
          <div className={styles.filterGroup}>
            <MenuItem
              title='All calls'
              icon='lines'
              iconColor={'var(--content-primary-tertiary)'}
              active={isActiveAllCalls}
              variantActiveState={'blue'}
              count={allCallsUnreadCount}
              onClick={() => {
                handleSelectFilter({ type: 'all-calls' })
              }}
            />
          </div>
          <div className={styles.filterGroup}>
            <MenuTitle
              title={'Inbound'}
              open={isShowInboundFilters}
              handleToggle={() => setIsShowInboundFilters(!isShowInboundFilters)}
            />
            {isShowInboundFilters && (
              <ul className={styles.filters}>
                <li>
                  <MenuItem
                    title='Answered'
                    icon='arrowLeftDown'
                    iconColor={'var(--content-primary-tertiary)'}
                    active={isActiveInboundAnswered}
                    variantActiveState={'blue'}
                    count={inboundAnsweredUnreadCount}
                    onClick={() => {
                      handleSelectFilter({ type: 'inbound', outcome: 'answered' })
                    }}
                  />
                </li>
                <li>
                  <MenuItem
                    title='Missed'
                    icon='missed'
                    iconColor={'var(--content-negative-primary)'}
                    active={isActiveInboundMissed}
                    variantActiveState={'blue'}
                    count={inboundMissedUnreadCount}
                    onClick={() => {
                      handleSelectFilter({ type: 'inbound', outcome: 'missed' })
                    }}
                  />
                </li>
                <li>
                  <MenuItem
                    title='Voicemail'
                    icon='voicemail'
                    iconColor={'var(--content-primary-tertiary)'}
                    active={isActiveInboundVoicemail}
                    variantActiveState={'blue'}
                    count={inboundVoicemailUnreadCount}
                    onClick={() => {
                      handleSelectFilter({ type: 'inbound', outcome: 'voicemail' })
                    }}
                  />
                </li>
              </ul>
            )}
          </div>
          <div className={styles.filterGroup}>
            <MenuTitle
              title={'Outbound'}
              open={isShowOutboundFilters}
              handleToggle={() => setIsShowOutboundFilters(!isShowOutboundFilters)}
            />
            {isShowOutboundFilters && (
              <ul className={styles.filters}>
                <li>
                  <MenuItem
                    title='Answered'
                    icon='arrowRightUp'
                    iconColor={'var(--content-primary-tertiary)'}
                    active={isActiveOutboundAnswered}
                    variantActiveState={'blue'}
                    count={outboundAnsweredUnreadCount}
                    onClick={() => {
                      handleSelectFilter({ type: 'outbound', outcome: 'answered' })
                    }}
                  />
                </li>
                <li>
                  <MenuItem
                    title='No answer'
                    icon='noAnswer'
                    iconColor={'var(--content-negative-primary)'}
                    active={isActiveOutboundNoAnswered}
                    variantActiveState={'blue'}
                    count={outboundNoAnsweredUnreadCount}
                    onClick={() => {
                      handleSelectFilter({ type: 'outbound', outcome: 'no_answer' })
                    }}
                  />
                </li>
              </ul>
            )}
          </div>
        </>
      )}

      {!loading && !hasCalls && (
        <div className={styles.emptyCalls}>
          <Typography variant={'body-rg-medium'} ariaLabel={'NoCalls'}>
            No calls
          </Typography>
        </div>
      )}
    </div>
  )
})
