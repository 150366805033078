// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pCXY4Ru_n3WV36LtugOj{display:flex;flex-direction:column;gap:16px}.CE25IGtizYGssvehHqx8{display:flex;flex-direction:column;gap:4px}.kmhKG8m28tk6nFNMfrZM{margin-bottom:-4px}.JgGFAp33lyqTQBeATesT{width:84px;display:flex;align-items:center}", "",{"version":3,"sources":["webpack://./src/widgets/TestSMS/ui/TestSmsWithMergeField/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,sBACE,kBAAA,CAEF,sBACE,UAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".content{\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.wrapField{\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n.label{\n  margin-bottom: -4px;\n}\n.send{\n  width: 84px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "pCXY4Ru_n3WV36LtugOj",
	"wrapField": "CE25IGtizYGssvehHqx8",
	"label": "kmhKG8m28tk6nFNMfrZM",
	"send": "JgGFAp33lyqTQBeATesT"
};
export default ___CSS_LOADER_EXPORT___;
