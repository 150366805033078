import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IParamsGetCallHistory,
  IParamsGetCallHistoryStatistic,
  IResponseCallHistory,
  IResponseGetCallHistoryStatistic,
} from 'entities/CallHistory/api/types'

class Api {
  getCallHistoryStatistic(
    params: IParamsGetCallHistoryStatistic,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetCallHistoryStatistic>> {
    return baseApi.get('call-history/statistics', { params, ...options })
  }

  getCallHistory(
    params: IParamsGetCallHistory,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseCallHistory>> {
    return baseApi.get('call-history', { params, ...options })
  }
}

export const CallHistoryApi = new Api()
