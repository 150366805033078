import React from 'react'
import { ImmediatelyStore } from 'shared/ui/Schedule'
import { ImmediatelyContext } from './context/immediatelyContext'
import { ImmediatelyContent } from './ui/ImmediatelyContent'

type IImmediatelyProps = {
  store: ImmediatelyStore
}

export const Immediately = ({ store }: IImmediatelyProps) => (
  <ImmediatelyContext.Provider value={store}>
    <ImmediatelyContent />
  </ImmediatelyContext.Provider>
)
