import { VideoContent } from 'widgets/RecordVideo/ui/video/VideoContent'
import { RecordActions } from 'widgets/Record'
import recordVideoStore from 'widgets/RecordVideo/store/recordVideoStore'
import styles from './styles.module.scss'

export const RecordVideoContent = () => {
  return (
    <div className={styles.wrap}>
      <VideoContent />
      <RecordActions recordStore={recordVideoStore.recordStore} />
    </div>
  )
}
