import { observer } from 'mobx-react-lite'
import { Icon, IIconProps, ITooltipProps, Radio, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export type IRadioBoxProps<T> = {
  id: string | number
  value: T
  text: string
  tooltip?: {
    TooltipProps?: ITooltipProps
    IconProps?: IIconProps
  }
  onChange?: (value: T) => void
  active?: boolean
}

type IRadioListProps<T> = {
  active?: T | null
  options: IRadioBoxProps<T>[]
  onChange?: (value: T) => void
}

export const RadioOption = observer(<T,>(option: IRadioBoxProps<T>) => {
  const { onChange, active, text, tooltip } = option
  const onClick = () => {
    onChange?.(option.value)
  }

  return (
    <div className={styles.item} onClick={onClick}>
      <div className={styles.item__content}>
        <Radio size={'small'} checked={active} />
        <Typography
          className={styles.item__text}
          variant={'body-md-regular'}
          ariaLabel={'Set as Primary'}
        >
          {text}
        </Typography>

        {tooltip && (
          <Tooltip className={styles.item__tooltip} {...tooltip.TooltipProps}>
            <Icon icon={'question'} fontSize={14} {...tooltip.IconProps} />
          </Tooltip>
        )}
      </div>
    </div>
  )
})

export const RadioOptions = observer(<T,>({ options, active, onChange }: IRadioListProps<T>) => {
  return (
    <div className={styles.wrap}>
      {options.map((option) => {
        return (
          <RadioOption
            key={option.id}
            active={active === option.value}
            onChange={onChange}
            {...option}
          />
        )
      })}
    </div>
  )
})
