import { observer } from 'mobx-react-lite'
import React from 'react'
import { IconButton } from 'shared/ui'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallVoicemailDropButton = observer(() => {
  const store = useCallPopUpDefaultContext()

  const { handleVoicemailDropContent } = store as CallPopUpDefaultStore

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'red'}
        variant={'custom'}
        active={true}
        icon={'voicemail'}
        onClick={() => {
          handleVoicemailDropContent()
        }}
        tooltipProps={{
          zIndex: 5001,
          label: 'Voicemail drop',
          placement: 'top',
        }}
        ariaLabel='CallVoicemailDropButton'
      />
    </div>
  )
})
