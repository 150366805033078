import { ProgressBar } from '../ProgressBar/ProgressBar'
import { Typography } from '../Typography'
import styles from './styles.module.scss'

interface IProgressInfoProps {
  title: string
  step: number
  total: number
  className?: string
}
export const ProgressInfo = ({ title, step, total, className }: IProgressInfoProps) => {
  return (
    <div className={className || ''}>
      <div className={styles.topLine}>
        <Typography ariaLabel='ProgressInfo_title' variant='heading-md'>
          {title}
        </Typography>
        <Typography
          ariaLabel='ProgressInfo_title'
          variant='body-md-regular'
          color='var(--content-primary-tertiary)'
        >
          Step {step} / {total}
        </Typography>
      </div>
      <ProgressBar percent={(step / total) * 100} withAllBorders withBg />
    </div>
  )
}
