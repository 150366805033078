import { SettingsProvider } from '../context/settingsContext'
import { SettingsRoutesContent } from './SettingsRoutesContent'

export const SettingsRoutes = () => {
  return (
    <SettingsProvider>
      <SettingsRoutesContent />
    </SettingsProvider>
  )
}
