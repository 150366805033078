// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A0ZGp4oEouRH2PHB0bmw{display:flex;align-items:center;gap:10px;padding-left:20px;padding-top:8px;padding-bottom:12px}.A0ZGp4oEouRH2PHB0bmw input{font-size:16px}", "",{"version":3,"sources":["webpack://./src/widgets/TriggerDetail/ui/ContactStatisticsSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,eAAA,CACA,mBAAA,CAEA,4BACE,cAAA","sourcesContent":[".search {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding-left: 20px;\n  padding-top: 8px;\n  padding-bottom: 12px;\n\n  input {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "A0ZGp4oEouRH2PHB0bmw"
};
export default ___CSS_LOADER_EXPORT___;
