import { makeAutoObservable, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { numbersStore, PhoneApi } from 'entities/Phone'
import { IResponseNumberPool } from 'entities/Phone/api/types'
import { GetStartedModalContent, GetStartedModalActions } from 'widgets/getStartedModal'

export class GetStartedModalStore {
  idModal = 'getStartedModal'
  number: IResponseNumberPool | null = null
  loading = false

  constructor() {
    makeAutoObservable(this)
  }

  init = () => {
    this.loadNumber()

    modalStore.addModal({
      id: this.idModal,
      showCloseIcon: false,
      showCloseButton: false,
      disabledOnAllClose: true,
      disabledOverlayClose: true,
      disabledOnEscClose: true,
      title: 'Get started',
      paddingContent: '8px 24px 0 24px',
      width: 480,
      ModalActions: GetStartedModalActions,
      ModalContent: GetStartedModalContent,
      onClose: this.closeModal,
    })
  }

  closeModal = () => {
    this.reset()
    modalStore.removeModal(this.idModal)
  }

  reset = () => {
    this.number = null
    this.loading = false
  }

  loadNumber = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })
      const { data } = await PhoneApi.getNumbersFromPool({ limit: 1 })
      this.number = data?.[0] || null
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  handleUpdatePtfNumber = async () => {
    if (!this.number) return

    try {
      runInAction(() => {
        this.loading = true
      })
      const { data } = await PhoneApi.updateNumberPtf({
        pft_number: this.number.formatted_number,
      })

      numbersStore.addItem(data.pft_number)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  handleCompliance = async () => {
    await this.handleUpdatePtfNumber()
    uiStore.changeRoute({
      path: '/settings/organization/compliance',
      type: 'vue',
    })
    this.closeModal()
  }

  handleStart = async () => {
    await this.handleUpdatePtfNumber()

    uiStore.changeRoute({
      path: '/conversations/null/?woohoo',
    })
    this.closeModal()
  }
}

export const getStartedModalStore = new GetStartedModalStore()
