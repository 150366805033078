import { observer } from 'mobx-react-lite'
import { IChatbotConversationMessageType } from 'entities/Chatbot'
import { useChatbotDialogContext } from 'pages/chatbot/context'

import {
  MessageOutgoing,
  MessageOutgoingLoading,
  MessageIncoming,
  MessageIncomingLoading,
} from './MessageView'
import styles from './styles.module.scss'

const getMessageComponent = (type: IChatbotConversationMessageType) => {
  switch (type) {
    case 'user':
      return MessageOutgoing
    case 'bot':
    case 'bot_success_action':
    case 'bot_fallback_action':
      return MessageIncoming
    default:
      return null
  }
}

export const DialogList = observer(() => {
  const dialog = useChatbotDialogContext()

  return (
    <div className={styles.messageList}>
      {dialog.initialLoading ? (
        <MessageIncomingLoading />
      ) : (
        <>
          {dialog.conversation.messages.map((message) => {
            const Component = getMessageComponent(message.type)
            if (!Component) return null

            return <Component key={message.id} message={message} />
          })}
          {dialog.loading && <MessageOutgoingLoading />}
        </>
      )}
    </div>
  )
})
