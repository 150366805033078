import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownContent, DropdownItem, Scrollbar } from 'shared/ui'
import { DropdownTextInput } from 'shared/ui/Dropdown/ui/DropdownTextInput'
import { type NumbersInboxesListStore } from 'widgets/NumbersInboxesList'
import styles from './styles.module.scss'

type INumbersInboxesListProps = {
  store: NumbersInboxesListStore
  onClose?: () => void
}

export const NumbersInboxesList: FC<INumbersInboxesListProps> = observer(({ store, onClose }) => {
  return (
    <DropdownContent noPadding>
      <DropdownTextInput
        textFieldProps={{
          value: store.searchNumbers,
          onChange: store.onSearch,
          InputProps: {
            placeholder: 'Search',
          },
        }}
      />

      <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={206}>
        <div className={styles.dropdownWrap}>
          {store.items.map((item) => {
            return (
              <DropdownItem
                key={item.id}
                item={item}
                onChange={(item, parent) => {
                  if (parent) {
                    store.setActiveNumber(+parent.id, item.id)
                  } else {
                    item.children && store.setActiveNumber(+item.id, item.children[0].id)
                  }
                  store.onSearch('')
                  onClose?.()
                }}
              />
            )
          })}
        </div>
      </Scrollbar>
    </DropdownContent>
  )
})
