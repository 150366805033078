import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { SavedRepliesSearch } from 'widgets/SavedReplies/ui/SavedRepliesContent/Header/SavedRepliesSearch/SavedRepliesSearch'
import { RightContent } from 'widgets/SavedReplies/ui/SavedRepliesContent/RightContent/RightContent'
import { LeftContent } from 'widgets/SavedReplies/ui/SavedRepliesContent/LeftContent/LeftContent'
import { airCallTooltip } from 'widgets/constants'
import styles from './styles.module.scss'

interface ISavedRepliesContentProps {
  isCurrentAirCall?: boolean
}

export const SavedRepliesContent = observer(
  ({ isCurrentAirCall = false }: ISavedRepliesContentProps) => {
    return (
      <div>
        {isCurrentAirCall && (
          <div className={styles.alerts}>
            <Alert item={{ type: 'warning', title: airCallTooltip }} />
          </div>
        )}
        <div className={styles.header}>
          <SavedRepliesSearch />
        </div>
        <div className={styles.content}>
          <LeftContent />
          <RightContent />
        </div>
      </div>
    )
  }
)
