import { useNavigate } from 'react-router-dom'
import { ActionItem, DividerCustom, IActionsProps } from 'shared/ui'
import { SwitchAction } from 'shared/ui/SwitchAction'
import { ChatbotInstructionType, ChatbotListItem } from 'entities/Chatbot'
import { ChatbotPath } from 'entities/ExportRouters'
import { useChatbotDetailsContext, useChatbotListContext } from 'pages/chatbot/context'
import { ChatbotRoutes } from 'pages/chatbot'

export type IChatbotRow = {
  id: number
  payload: ChatbotListItem
}

export type IChatbotActionRow = IChatbotRow & {
  actionsProps: IActionsProps
}

export const useRowsWithActions: () => IChatbotActionRow[] = () => {
  const { items, updateStatus, deleteRow } = useChatbotListContext()
  const { showDetails } = useChatbotDetailsContext()
  const navigate = useNavigate()

  const makeStatusAction = (item: ChatbotListItem): ActionItem | null => {
    if (item.isDraft || item.isInvalid) return null

    const dropdownItemComponent = () => (
      <>
        <SwitchAction
          size={'xSmall'}
          label={item.isActive ? 'Active' : 'Disabled'}
          active={item.isActive}
          onTrigger={() => updateStatus(item.id, !item.isActive)}
        />
        <DividerCustom variant={'dropdown'} />
      </>
    )

    return { dropdownItemComponent }
  }

  const makeEditAction = (item: ChatbotListItem): ActionItem | null => {
    if (item.isActive) return null
    const path =
      item?.type === ChatbotInstructionType.BookAppointment
        ? `/${ChatbotPath.viewBookAppointment(item.id)}`
        : `/${ChatbotRoutes.root}/${item.id}`

    return {
      icon: 'edit',
      text: 'Edit',
      onAction: () => navigate(path, { state: item }),
    }
  }

  const makeDetailsAction = (item: ChatbotListItem): ActionItem | null => {
    return {
      icon: 'eye',
      text: 'Details',
      onAction: () => showDetails(item.id),
    }
  }

  const makeDeleteAction = (item: ChatbotListItem): ActionItem | null => {
    return {
      icon: 'delete',
      text: 'Delete',
      onAction: () => deleteRow(item.id),
    }
  }

  return items.map((item) => {
    const actions = [
      makeStatusAction(item),
      makeEditAction(item),
      makeDetailsAction(item),
      makeDeleteAction(item),
    ].filter((item) => !!item) as ActionItem[]

    return { id: item.id, payload: item, actionsProps: { actions } }
  })
}
