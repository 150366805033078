import { NodeModel, DropOptions } from '@minoru/react-dnd-treeview'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { UnifiedInbox } from 'entities/Inbox/model/UnifiedInbox'
import { GroupInbox } from 'entities/Inbox/model/GroupInbox'

export type IInboxCombine = Inbox | UnifiedInbox | GroupInbox
export type IInboxKey = 'unified' | 'group' | 'shared'

export interface INodeModel<T> extends NodeModel<T> {
  groupInbox?: IInboxCombine
}

export interface IDropOptions<T> extends DropOptions<T> {
  groupInbox?: Inbox
}

export enum TreeRootInboxes {
  FAVORITE = 'favorite',
  SIMPLE = 'simple',
  SMART = 'smart',
}

export type InboxType = 'inbox_group' | 'inbox' | 'unified-inbox'
