// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qrfdF4w1VOIinDUErC_w{width:100%;height:100%;display:flex;flex-direction:column}.qrfdF4w1VOIinDUErC_w .jrCP7Iqu7AxDJ159s5Wf{width:100%;height:fit-content;padding:0 20px 24px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/ContactsCleanupContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CAEA,4CACE,UAAA,CACA,kBAAA,CACA,mBAAA","sourcesContent":[".contentContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .tabs {\n    width: 100%;\n    height: fit-content;\n    padding: 0 20px 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": "qrfdF4w1VOIinDUErC_w",
	"tabs": "jrCP7Iqu7AxDJ159s5Wf"
};
export default ___CSS_LOADER_EXPORT___;
