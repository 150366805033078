import axios, { AxiosResponse } from 'axios'
import type { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  AttachmentFetchType,
  IParamsAttachmentsUploadLink,
  IRequestAttachmentParams,
  IResponseAttachmentList,
  IResponseAttachmentsUploadLink,
  IResponseAttachmentUpload,
  IResponseDownloadLink,
  IResponseMedia,
  IResponseUploadInfo,
} from 'entities/Attachment/api/types'

class Api {
  upload(files: File[]): Promise<AxiosResponse<IResponseAttachmentUpload[]>> {
    const formData = new FormData()
    formData.append('number_of_files', String(files.length))

    files.forEach((file) => {
      formData.append('files[]', file, file.name)
    })

    return baseApi.post('api/v4/attachments/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      baseURL: '/',
    })
  }

  uploadLink(
    file: File,
    params: IParamsAttachmentsUploadLink
  ): Promise<AxiosResponse<IResponseAttachmentsUploadLink>> {
    return baseApi.post('attachments/upload/link', params).then((response) => {
      axios.put(response.data.link.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      return response
    })
  }

  convert(file: File): Promise<AxiosResponse<IResponseAttachmentUpload>> {
    const formData = new FormData()
    formData.append('file', file, file.name)

    return baseApi.post('attachments/convert', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  preview(links: string[]): Promise<AxiosResponse<Array<IResponseAttachmentUpload>>> {
    return baseApi.get('attachments/preview', {
      params: {
        ...links.reduce<{ [key: string]: string }>((acc, link, currentIndex) => {
          acc[`urls[${currentIndex}]`] = link
          return acc
        }, {}),
      },
    })
  }

  getUploadInfo(): Promise<AxiosResponse<IResponseUploadInfo>> {
    return baseApi.get('attachments/upload-info')
  }

  recent(
    params: IRequestAttachmentParams,
    options?: AxiosOptions
  ): Promise<AxiosResponse<Array<IResponseAttachmentUpload>>> {
    return baseApi.get('attachments/recently', { ...options, params })
  }

  delete(ids: Array<string | number>): Promise<AxiosResponse<{ status: 'success' }>> {
    return baseApi.delete('attachments', {
      params: {
        ...ids.reduce<{ [key: string]: string }>((acc, id, currentIndex) => {
          acc[`attachment_ids[${currentIndex}]`] = String(id)
          return acc
        }, {}),
      },
    })
  }

  list(
    page: number,
    perPage: number,
    type: AttachmentFetchType | null,
    searchTerm?: string
  ): Promise<AxiosResponse<IResponseAttachmentList>> {
    const params = {
      page: page,
      per_page: perPage,
      ...(searchTerm ? { term: searchTerm } : {}),
      ...(type ? { type } : {}),
    }

    return baseApi.get('attachments/list', { params })
  }

  rename(attachmentId: string | number, name: string): Promise<AxiosResponse<IResponseMedia>> {
    const formData = {
      name,
    }

    return baseApi.post(`attachments/${attachmentId}`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getDownloadLink(ids: Array<string | number>): Promise<AxiosResponse<IResponseDownloadLink>> {
    return baseApi.post('attachments/download/link', {
      attachment_ids: ids,
    })
  }
}
export const AttachmentsApi = new Api()
