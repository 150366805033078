// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t8YED2xJ_sABiBioZpd0{display:flex;border-radius:6px;background:var(--background-primary-inverted-primary);padding:16px 0 15px 12px;cursor:pointer}.t8YED2xJ_sABiBioZpd0:hover,.t8YED2xJ_sABiBioZpd0.c4hniJyNVFlzboiPpeCq{background:var(--background-neutral-subtle)}.pbq7vr6jGjeEIMLhqUcI{margin-right:13px}.yD8LkZ9mbwJ7aUL002jf{color:var(--content-primary-primary);font-size:13px;font-weight:500;margin-bottom:7px}.qmln5_Om7wTQWHisge6N{color:var(--content-primary-tertiary);font-size:13px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/entities/Contacts/ui/ContactCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,qDAAA,CACA,wBAAA,CACA,cAAA,CAEA,uEACE,2CAAA,CAIJ,sBACE,iBAAA,CAKF,sBACE,oCAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,qCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".wrap {\n  display: flex;\n  border-radius: 6px;\n  background: var(--background-primary-inverted-primary);\n  padding: 16px 0 15px 12px;\n  cursor: pointer;\n\n  &:hover, &.active {\n    background: var(--background-neutral-subtle);\n  }\n}\n\n.avatar {\n  margin-right: 13px;\n}\n\n.info {}\n\n.name {\n  color: var(--content-primary-primary);\n  font-size: 13px;\n  font-weight: 500;\n  margin-bottom: 7px;\n}\n\n.number {\n  color: var(--content-primary-tertiary);\n  font-size: 13px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "t8YED2xJ_sABiBioZpd0",
	"active": "c4hniJyNVFlzboiPpeCq",
	"avatar": "pbq7vr6jGjeEIMLhqUcI",
	"name": "yD8LkZ9mbwJ7aUL002jf",
	"number": "qmln5_Om7wTQWHisge6N"
};
export default ___CSS_LOADER_EXPORT___;
