import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { MessageCardContext } from 'widgets/MessageCard/context'
import { MessageCardContent } from 'widgets/MessageCard/ui/MessageCardContent'
import { IMessageCardProps } from 'widgets/MessageCard/context/messageCardContext'

export const MessageCard: FC<IMessageCardProps> = observer(
  ({
    selectedSearch,
    callContentStore,
    setScrollBottomTrigger,
    message,
    avatarInfo,
    isLast,
    hideTeam,
    conversationMessagesStore,
  }) => {
    return (
      <MessageCardContext.Provider
        value={{
          selectedSearch,
          callContentStore,
          setScrollBottomTrigger,
          message,
          avatarInfo,
          isLast,
          hideTeam,
          conversationMessagesStore,
        }}
      >
        <MessageCardContent />
      </MessageCardContext.Provider>
    )
  }
)
