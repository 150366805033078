import { uiStore } from 'shared/store/uiStore'

export const getDefaultQuarterTime = (date?: Date | null) => {
  const hours = uiStore.dayjs().get('hours')
  const minutes = uiStore.dayjs().get('minutes')
  const quarter = Math.ceil(minutes / 15)

  return uiStore
    .dayjs(date)
    .startOf('day')
    .add(hours, 'hours')
    .add(quarter * 15, 'minutes')
    .add(uiStore.offsetMinute, 'minutes')
    .toDate()
}
