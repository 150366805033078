import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { callStore } from 'entities/Call'
import {
  DeviceItemsEnum,
  DeviceSettingsContent,
  DeviceSettingsVariantEnum,
} from 'entities/DeviceSettings'
import { CallSection, useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallMicPopUpContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const { initMic, resetMic } = store as CallPopUpDefaultStore

  useEffect(() => {
    initMic()

    return () => {
      resetMic()
    }
  }, [])

  return (
    <div className={styles.wrapContent}>
      <CallSection title={'Settings'}>
        <DeviceSettingsContent
          deviceSettingsStore={callStore.deviceSettingsStore}
          testOutputDevice={() => {
            callStore.testOutputDevice()
          }}
          variant={DeviceSettingsVariantEnum.container}
          dropdownProps={{
            width: 298,
            margin: -30,
            ariaLabel: 'Settings',
          }}
          exclude={[DeviceItemsEnum.recordAuto]}
        />
      </CallSection>
    </div>
  )
})
