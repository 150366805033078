import { createContext, useContext } from 'react'
import { RecordAudioStore } from 'widgets/RecordAudio'

export const RecordAudioContext = createContext<RecordAudioStore | null>(null)

export const useRecordAudioContext = () => {
  const context = useContext(RecordAudioContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with RecordAudioContext')

  return context
}
