import axios, { CancelTokenSource } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { IntegrationsApi } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { IParamsContactsSearch, IResponseContact } from 'entities/Contacts/api/types'

export class ContactsSearchStore {
  itemsMap: Map<number, Contact> = new Map()
  loading = false
  loadingMore = false
  total = 0
  pagination: IParamsContactsSearch = {
    per_page: 10,
    page: 1,
    skip_opted_out: 0,
  }
  type: string
  cancelTokenSource: CancelTokenSource | null = null

  constructor(type: string) {
    makeAutoObservable(this)

    this.type = type
  }

  get callbackSearchContacts() {
    if (this.type === 'salesmsg') return ContactsApi.getContactsSearch
    if (this.type === 'hubspot') return IntegrationsApi.getIntegrationsContactsHubspot
    if (this.type === 'activecampaign') return IntegrationsApi.getIntegrationsContactsActivecampaign
    if (this.type === 'infusionsoft') return IntegrationsApi.getIntegrationsContactsInfusionsoft
    if (this.type === 'pipedrive') return IntegrationsApi.getIntegrationsContactsPipedrive
    if (this.type === 'salesforce') return IntegrationsApi.getIntegrationsContactsSalesforce

    return () => ({ data: { results: [], total: 0 } })
  }

  get hasItems() {
    return Boolean(this.itemsMap.size)
  }

  get hasMore() {
    const loadedCount = this.pagination.page * (this.pagination.per_page || 10)

    return loadedCount < this.total
  }

  get items() {
    return Array.from(this.itemsMap.values())
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  resetCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()
  }

  fetch = async ({ more = false, search = '' } = {}) => {
    try {
      runInAction(() => {
        if (more) this.loadingMore = true
        else this.loading = true
      })

      const params = this.pagination

      if (more) {
        params.page += 1
      }

      if (search) {
        this.resetItems()
        params.term = search
        params.page = 1
      }

      const { data } = await this.callbackSearchContacts(params, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })
      const results = (data.results || []) as IResponseContact[]
      const total = data.total || 0

      this.addItems(results)

      runInAction(() => {
        this.total = total
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingMore = false
        this.loading = false
      })
    }
  }

  addItems = (items: IResponseContact[]) => {
    items.forEach((item) => {
      this.itemsMap.set(item.id, new Contact(item))

      contactsStore.updateItem(item)
    })
  }

  resetItems = () => {
    this.itemsMap.clear()
  }
}
