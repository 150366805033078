// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mtCA0mqTXMDWBKcuo2xY{padding:16px 0 14px}.mtCA0mqTXMDWBKcuo2xY .cXWA5ivc2xyhDsN7ip4Q{display:flex;align-items:center;justify-content:flex-start;margin-bottom:16px}.mtCA0mqTXMDWBKcuo2xY .cXWA5ivc2xyhDsN7ip4Q:last-child{margin-bottom:0}.mtCA0mqTXMDWBKcuo2xY .cXWA5ivc2xyhDsN7ip4Q .xCkrgCvNOYVspqSrU2JD{margin:0 8px 0 0}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CAEA,uDACE,eAAA,CAGF,kEACE,gBAAA","sourcesContent":[".actions {\n  padding: 16px 0 14px;\n\n  .actionsRow {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    margin-bottom: 16px;\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n\n    .action {\n      margin: 0 8px 0 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "mtCA0mqTXMDWBKcuo2xY",
	"actionsRow": "cXWA5ivc2xyhDsN7ip4Q",
	"action": "xCkrgCvNOYVspqSrU2JD"
};
export default ___CSS_LOADER_EXPORT___;
