// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HUvUh0wNMq6pQineTknR{display:flex;width:28px;padding:5.5px 4px;flex-direction:column;justify-content:center;align-items:center;border-radius:6px;background:var(--action-text-tertiary-normal);border:none;margin:0;outline:none}.HUvUh0wNMq6pQineTknR:hover,.HUvUh0wNMq6pQineTknR.TqoOBcSwZcHSEeGrkjrS{background:var(--action-text-secondary-hovered)}.HUvUh0wNMq6pQineTknR:hover .D1WdcBEtmwv8BDu1yEen,.HUvUh0wNMq6pQineTknR.TqoOBcSwZcHSEeGrkjrS .D1WdcBEtmwv8BDu1yEen{color:var(--content-brand-secondary)}.D1WdcBEtmwv8BDu1yEen{color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/shared/ui/SpeedMediaBtn/Speed/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,iBAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,6CAAA,CACA,WAAA,CACA,QAAA,CACA,YAAA,CAEA,uEACE,+CAAA,CAEA,mHACE,oCAAA,CAKN,sBACE,qCAAA","sourcesContent":[".wrap {\n  display: flex;\n  width: 28px;\n  padding: 5.5px 4px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  border-radius: 6px;\n  background: var(--action-text-tertiary-normal);\n  border: none;\n  margin: 0;\n  outline: none;\n\n  &:hover, &.active {\n    background: var(--action-text-secondary-hovered);\n\n    .text {\n      color: var(--content-brand-secondary);\n    }\n  }\n}\n\n.text {\n  color: var(--content-primary-tertiary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "HUvUh0wNMq6pQineTknR",
	"active": "TqoOBcSwZcHSEeGrkjrS",
	"text": "D1WdcBEtmwv8BDu1yEen"
};
export default ___CSS_LOADER_EXPORT___;
