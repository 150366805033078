// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lAdJzyCiLrQkDfv_rWXW{color:var(--content-primary-tertiary);display:flex;align-items:center}.zYrzjndoqVeYWjhQ_JIy{display:flex;align-items:center}.IMay6J1HXwJBTu9SooAg{display:flex;width:1px;height:15px;background:var(--content-primary-tertiary);margin:0 6px}.orc3Eqnk3vJKJDKM7Rs6{white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/Widget/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CACA,YAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,SAAA,CACA,WAAA,CACA,0CAAA,CACA,YAAA,CAGF,sBACE,kBAAA","sourcesContent":[".selectTriggerComponent {\n  color: var(--content-primary-tertiary);\n  display: flex;\n  align-items: center;\n}\n\n.includeEmptyText {\n  display: flex;\n  align-items: center;\n}\n\n.deliver {\n  display: flex;\n  width: 1px;\n  height: 15px;\n  background: var(--content-primary-tertiary);\n  margin: 0 6px;\n}\n\n.nowrap {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectTriggerComponent": "lAdJzyCiLrQkDfv_rWXW",
	"includeEmptyText": "zYrzjndoqVeYWjhQ_JIy",
	"deliver": "IMay6J1HXwJBTu9SooAg",
	"nowrap": "orc3Eqnk3vJKJDKM7Rs6"
};
export default ___CSS_LOADER_EXPORT___;
