import { createContext, useContext } from 'react'
import { type TriggerViewStore } from 'widgets/TriggerView/store/triggerViewStore'

export const TriggerViewContext = createContext<TriggerViewStore | null>(null)

export function useTriggerViewContext() {
  const context = useContext(TriggerViewContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with TriggerViewContext')
  }
  return context
}
