import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { links } from 'shared/constants/links'
import { DayjsFormats } from 'shared/lib/dayjs'
import { toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { ModalType, ModalTypeList } from 'shared/ui/Modal/store/types'
import { DeviceItemsEnum, DeviceSettingsStore } from 'entities/DeviceSettings'
import { MediaControllerStore } from 'widgets/PresentationMode/store/MediaControllerStore'
import { MicRecorder } from 'widgets/Record/lib/MicRecorder'
import {
  DEFAULT_AUDIO_RECORD_LIMIT,
  DEFAULT_VIDEO_RECORD_LIMIT,
} from 'widgets/Record/store/constants'

type IPermissions = Array<'camera' | 'microphone'>

export type IRecordStoreProps = {
  deviceItems: DeviceItemsEnum[]
  permissions: IPermissions
  DEFAULT_RECORD_MIME_TYPE: string
  AUDIO_RECORD_LIMIT?: number
  RECORD_MIME_TYPE: string
  withDelay?: boolean
  isAudio?: boolean
  isPauseOnLimit?: boolean
}

export type IModalCloseConfigProps = {
  context: RecordStore
  resumeCallback: () => void
  cancelCallback: () => void
}

export type IModalCloseProps = {
  (config: IModalCloseConfigProps): {
    desc?: ModalType['desc']
    title?: ModalType['title']
    primaryAction?: ModalType['primaryAction']
    secondaryAction?: ModalType['secondaryAction']
  }
} | null

export type IInitRecordStoreProps = {
  onAddRecord?: ((file: File) => void) | null
  handleClose?: (() => void) | null
  handleCancel?: (() => void) | null
  handleFinish?: ((file: File) => void) | null
  onAllow?: () => void
  rejectInfo: {
    title: string
    desc: string
  }
}

export class RecordStore {
  deviceSettingsStore: DeviceSettingsStore
  DEFAULT_RECORD_MIME_TYPE: string
  RECORD_MIME_TYPE: string
  permissions: IPermissions
  withDelay = false
  isAudio = false
  constructor({
    deviceItems,
    DEFAULT_RECORD_MIME_TYPE,
    RECORD_MIME_TYPE,
    permissions,
    withDelay = false,
    isAudio = false,
    AUDIO_RECORD_LIMIT,
    isPauseOnLimit,
  }: IRecordStoreProps) {
    this.deviceSettingsStore = new DeviceSettingsStore({
      items: deviceItems,
    })
    this.DEFAULT_RECORD_MIME_TYPE = DEFAULT_RECORD_MIME_TYPE
    this.RECORD_MIME_TYPE = RECORD_MIME_TYPE
    this.permissions = permissions
    this.withDelay = withDelay
    this.isAudio = isAudio
    if (isPauseOnLimit) {
      this.isPauseOnLimit = isPauseOnLimit
    }
    if (AUDIO_RECORD_LIMIT) {
      this.AUDIO_RECORD_LIMIT = AUDIO_RECORD_LIMIT
    }
    makeAutoObservable(this)
  }

  mediaControllerStore: MediaControllerStore = new MediaControllerStore()
  onAddRecord: ((file: File) => void) | null = null
  handleFinish: ((file: File) => void) | null = null

  delayRecord = -1
  mediaRecorder: MediaRecorder | null = null
  mediaDataChunks: Blob[] = []

  startRecord = false
  pauseRecord = false

  AUDIO_RECORD_LIMIT = DEFAULT_AUDIO_RECORD_LIMIT
  currentAudioRecordDuration = 0

  VIDEO_RECORD_LIMIT = DEFAULT_VIDEO_RECORD_LIMIT
  currentVideoRecordDuration = 0
  videoRecordStartTimestamp = 0
  videoPauseStartTimestamp = 0
  videoTotalPauseDuration = 0

  animationFrameId: number | null = null

  src = ''

  loading = false

  handleClose: (() => void) | null = null
  handleCancel: (() => void) | null = null

  closeModalId = ''
  micRecorder: MicRecorder | null = null
  modalCloseProps: IModalCloseProps = null
  isPauseOnLimit = false

  get stream(): MediaStream | null {
    return this.deviceSettingsStore.stream || null
  }

  get startLoading() {
    return this.delayRecord >= 0
  }

  get isAudioLimit() {
    return this.currentAudioRecordDuration >= this.AUDIO_RECORD_LIMIT
  }

  initDeviceSettings = async () => {
    try {
      this.loading = true
      await this.deviceSettingsStore.syncDevicesStream()
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  onCancel = (fromCancelBtn?: boolean) => {
    this.onRestart()
    this.handleClose && this.handleClose()
    if (fromCancelBtn) {
      this.handleCancel && this.handleCancel()
    }
  }

  onClose = (finish?: boolean) => {
    if (!finish) {
      this.closeModalId = nanoid()
      let cb = () => {}
      if (this.startRecord && !this.pauseRecord) {
        this.onPause()
        cb = this.onResume
      }

      const resumeCallback = () => {
        modalStore.closeModal(this.closeModalId)
        cb()
      }

      const cancelCallback = () => {
        this.onCancel()
        modalStore.closeModal(this.closeModalId)
      }

      if (this.isAudio && !this.micRecorder?.getRecordingDuration()) {
        cancelCallback()
      } else {
        modalStore.addModal({
          id: this.closeModalId,
          type: ModalTypeList.WARNING,
          title: 'Cancel recording?',
          desc: `Your ${this.isAudio ? 'audio' : 'video'} progress will be lost`,
          primaryAction: {
            text: 'Cancel recording',
            onAction: cancelCallback,
          },
          secondaryAction: {
            text: 'Resume',
            onAction: resumeCallback,
          },
          ...(this.modalCloseProps
            ? this.modalCloseProps({
                context: this,
                resumeCallback: resumeCallback,
                cancelCallback: cancelCallback,
              })
            : null),
        })
      }

      return
    } else {
      this.onCancel()
    }
  }

  init = async ({
    onAddRecord = null,
    onAllow,
    rejectInfo,
    handleClose = null,
    handleCancel = null,
    handleFinish = null,
  }: IInitRecordStoreProps) => {
    try {
      const isAllowed = await this.deviceSettingsStore.checkPermissions(this.isAudio)
      if (isAllowed) {
        this.onRestart()
        this.initDeviceSettings()
        this.onAddRecord = onAddRecord
        this.handleFinish = handleFinish
        this.handleClose = handleClose
        this.handleCancel = handleCancel
        onAllow && onAllow()
      } else {
        toastStore.add({
          type: 'error',
          title: rejectInfo.title,
          desc: rejectInfo.desc,
          action: {
            link: links.enableMicrophone,
            text: 'Learn more',
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  setModalCloseProps = (props: IModalCloseProps) => {
    this.modalCloseProps = props
  }

  updateRecordingDuration = () => {
    runInAction(() => {
      this.currentAudioRecordDuration = this.micRecorder?.getRecordingDuration() || 0
    })

    if (this.isAudioLimit) {
      if (this.isPauseOnLimit) {
        this.onPause()
      } else {
        this.onFinish()
      }
      return
    }

    this.animationFrameId = requestAnimationFrame(this.updateRecordingDuration)
  }

  stopUpdatingRecordingDuration = () => {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId)
      this.animationFrameId = null
    }
  }

  onStartRecord = () => {
    const onStart = () => {
      this.mediaDataChunks = []
      if (this.isAudio) {
        this.micRecorder = new MicRecorder({
          bitRate: 128,
          deviceId: this.deviceSettingsStore.device.audioInputId ?? undefined,
        })
        this.startRecord = true
        this.micRecorder.start()
        this.animationFrameId = requestAnimationFrame(this.updateRecordingDuration)
        return
      }
      if (this.stream) {
        this.mediaRecorder = new MediaRecorder(this.stream, {
          mimeType: MediaRecorder.isTypeSupported(this.RECORD_MIME_TYPE)
            ? this.RECORD_MIME_TYPE
            : this.DEFAULT_RECORD_MIME_TYPE,
        })

        this.mediaRecorder.ondataavailable = ({ data, timeStamp }) => {
          this.mediaDataChunks.push(data)
          runInAction(() => {
            this.currentVideoRecordDuration =
              (timeStamp - this.videoRecordStartTimestamp - this.videoTotalPauseDuration) / 1000
          })

          if (this.currentVideoRecordDuration >= this.VIDEO_RECORD_LIMIT) {
            this.onFinish()
          }
        }

        this.mediaRecorder.onstart = ({ timeStamp }) => {
          this.videoRecordStartTimestamp = timeStamp
        }

        this.mediaRecorder.onpause = ({ timeStamp }) => {
          this.videoPauseStartTimestamp = timeStamp
        }

        this.mediaRecorder.onresume = ({ timeStamp }) => {
          this.videoTotalPauseDuration += timeStamp - this.videoPauseStartTimestamp
          this.videoPauseStartTimestamp = 0
        }

        this.mediaRecorder?.start(1000)

        this.startRecord = true
      }
    }

    if (this.withDelay) {
      this.delayRecord = 3
      const id = setInterval(() => {
        if (this.stream) {
          this.delayRecord -= 1
          if (this.delayRecord === 0) {
            onStart()
            this.delayRecord = -1
            clearInterval(id)
            return
          }
        }
      }, 1000)
    } else {
      onStart()
    }
  }

  onPause = async () => {
    if (this.isAudio) {
      try {
        const [buffer, recorderBlob] = await this.micRecorder?.stop().getMp3()
        this.stopUpdatingRecordingDuration()
        runInAction(() => {
          this.mediaDataChunks = [...this.mediaDataChunks, ...buffer]
          const blob = new Blob(this.mediaDataChunks, {
            type: recorderBlob.type,
          })

          this.src = URL.createObjectURL(blob)

          this.pauseRecord = true
        })
      } catch (e) {
        console.error(e)
      }
    } else {
      this.mediaRecorder?.pause()

      const blob = new Blob(this.mediaDataChunks, {
        type: this.RECORD_MIME_TYPE,
      })

      this.src = URL.createObjectURL(blob)

      this.pauseRecord = true
    }
  }

  onResume = () => {
    this.pauseRecord = false
    if (this.isAudio) {
      if (this.animationFrameId) {
        this.stopUpdatingRecordingDuration()
      }
      this.animationFrameId = requestAnimationFrame(this.updateRecordingDuration)
      this.micRecorder?.start()
    } else {
      this.mediaRecorder?.resume()
    }
  }

  onRestart = () => {
    this.mediaControllerStore.reset()
    this.startRecord = false
    this.pauseRecord = false
    this.loading = false
    this.delayRecord = -1
    this.mediaRecorder?.stop()
    if (this.mediaRecorder) this.mediaRecorder.ondataavailable = null
    this.mediaRecorder = null
    this.mediaDataChunks = []
    this.currentAudioRecordDuration = 0
    this.currentVideoRecordDuration = 0
    this.animationFrameId = null
    if (this.isAudio) {
      this.micRecorder?.stop()
      this.micRecorder = null
    }
  }

  onDelete = () => {
    this.onClose()
  }

  onFinish = async () => {
    await this.onPause()
    const file = this.isAudio
      ? new File(
          this.mediaDataChunks,
          `Recorded at ${uiStore.dayjs().format(DayjsFormats.full)}.mp3`,
          {
            type: 'audio/mpeg',
            lastModified: Date.now(),
          }
        )
      : new File(this.mediaDataChunks, `${Date.now()}.webm`, {
          type: this.RECORD_MIME_TYPE,
        })

    this.deviceSettingsStore.removeStream()

    if (this.handleFinish) {
      this.handleFinish && this.handleFinish(file)
    } else {
      this.onAddRecord && this.onAddRecord(file)
      this.onClose(true)
    }
  }
}
