import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { SpinnerLoader, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { contactsStore } from 'entities/Contacts'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { Tabs } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Tabs'
import { Properties } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Entities/Properties'
import { Opportunities } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Entities/Opportunities'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import styles from './styles.module.scss'

export const SalesforceIntegration = observer(() => {
  const store = useContactsIntegrationsContext()
  const [activeTab, setActiveTab] = useState<EnumIntegrationTabs>(
    store.integrationTab || EnumIntegrationTabs.Contact
  )

  const contact = contactsStore.getItem(store.selectedContact?.id)

  const handleActiveTab = (tab: EnumIntegrationTabs) => {
    setActiveTab(tab)
    store.handleIntegrationTab(tab)
  }

  useEffect(() => {
    integrationsStore.fetchIntegrations()
  }, [])

  if (!contact) return null

  const integration = integrationsStore.getIntegration(IntegrationKey.salesforce)

  if (!integration?.isConnected)
    return (
      <div className={styles.reconnectWrapper}>
        <Button
          typeBtn={'contained'}
          size={'medium'}
          contained={'primary'}
          fullWidth
          text='Reconnect'
          onClick={() => {
            uiStore.changeRoute({
              path: '/settings/integrations',
            })
          }}
        />
      </div>
    )

  const loading = store.loading || store.unlinking

  const info =
    integration.key === IntegrationKey.salesforce
      ? store.getContactIntegrationSalesforceInfo(contact.id)
      : null

  if (loading)
    return (
      <div className={classnames(styles.loaderWrap)}>
        <SpinnerLoader size={24} />
      </div>
    )

  const ActiveTab = () => {
    if (!info) return null

    if (activeTab === EnumIntegrationTabs.Contact) {
      return (
        <Properties
          integrationLabel={'Salesforce'}
          integrationKey={IntegrationKey.salesforce}
          property={info.properties}
          contact={contact}
        />
      )
    }

    if (activeTab === EnumIntegrationTabs.Opportunities) {
      return (
        <Opportunities
          integrationIcon={'salesforce'}
          integrationLabel={'Salesforce'}
          integrationKey={IntegrationKey.salesforce}
          opportunities={info.opportunities}
          contact={contact}
        />
      )
    }

    return null
  }

  return (
    <div className={classnames(styles.wrap)}>
      <Tabs
        handleTab={handleActiveTab}
        activeTab={activeTab}
        items={[
          {
            key: EnumIntegrationTabs.Contact,
            title: 'Contact',
          },
          {
            key: EnumIntegrationTabs.Opportunities,
            title: 'Opportunities',
            enableCount: true,
            count: info?.opportunities?.length || 0,
            hide: info?.isLead,
          },
        ]}
      />
      <ActiveTab />
    </div>
  )
})
