import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { organizationSettingsStore } from 'entities/Settings'
import { ITenDlcLimitsResponse } from '../api/type'
import { TenDlcApi } from '../api/tenDlc'

export class TenDlcLimitsStore {
  constructor() {
    makeAutoObservable(this)
    this.reactionTenDLC()
  }

  limits: ITenDlcLimitsResponse | null = null
  limitsLoading = false

  fetchLimits = async () => {
    try {
      this.limitsLoading = true

      const { data } = await TenDlcApi.getTenDlcLimits()

      runInAction(() => (this.limits = data))
    } catch (error) {
      console.error(error)
    } finally {
      runInAction(() => (this.limitsLoading = false))
    }
  }

  get currentAmount() {
    return this.limits?.currentAmount
  }

  get currentPlan() {
    return this.limits?.dlcPlanTypes[this.limits?.dlcPlan.planTypeId]
  }

  get isOutOfSegments() {
    if (!this.currentAmount || !this.currentPlan) return false

    return this.currentAmount.segments >= this.currentPlan.segments_to
  }

  get isPlanStarter() {
    return this.limits?.dlcPlan.planTypeId === 1
  }

  get isPlanStandard() {
    return this.limits?.dlcPlan.planTypeId === 2
  }

  get isPlanLowVolumeStandard() {
    return this.limits?.dlcPlan.planTypeId === 3
  }

  get isPlanSoleProprietorship() {
    return this.limits?.dlcPlan.planTypeId === 4
  }

  reactionTenDLC = () => {
    reaction(
      () => organizationSettingsStore.hasTenDLCLimits,
      (value) => {
        if (value) this.fetchLimits()
      }
    )
  }
}

export const tenDlcLimitsStore = new TenDlcLimitsStore()
