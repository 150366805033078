import { IRadioBoxProps } from 'shared/ui/Radio'

export type IBroadcastBadNumberOptionTypes = 'all' | 'one'

export const broadcastBadNumberOptions: IRadioBoxProps<IBroadcastBadNumberOptionTypes>[] = [
  {
    id: 'all',
    value: 'all',
    text: 'Validate all contacts (0)',
  },
  {
    id: 'one',
    value: 'one',
    text: 'Validate broadcast contacts (0)',
  },
]
