import { ReactNode } from 'react'
import classNames from 'classnames'
import { ActionItem, ContainerHeader, IMenuItemProps, MenuItem } from 'shared/ui'
import styles from './styles.module.scss'

interface IMenuProps {
  title: string
  headerButtons?: ActionItem[]
  menuSearch?: ReactNode
  menuContent?: ReactNode
  menuItems?: Array<IMenuItemProps>
}

export const Menu = ({ title, headerButtons, menuSearch, menuContent, menuItems }: IMenuProps) => {
  return (
    <div className={classNames(styles.wrap, menuSearch && styles.withSearch)}>
      <ContainerHeader
        title={title}
        actions={headerButtons}
        actionsGap={4}
        variant={'transparent'}
        paddingRight={11}
      />

      {menuSearch && <div className={styles.wrapperSearch}>{menuSearch}</div>}

      <div className={styles.wrapperContent}>
        {menuContent ||
          menuItems?.map((item) => (
            <MenuItem key={item.type === 'nav' ? item.to : item.id || item.title} {...item} />
          ))}
      </div>
    </div>
  )
}
