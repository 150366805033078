import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export const getAssumeToken = () => sessionStorage.getItem('assume_token')
export const setAssumeToken = (token: string) => sessionStorage.setItem('assume_token', token)
export const removeAssumeToken = () => sessionStorage.removeItem('assume_token')

export const getToken = () => localStorage.getItem('token')
export const setToken = (token: string) => localStorage.setItem('token', token)
export const removeToken = () => localStorage.removeItem('token')

export const setPosthogSession = (sessionId: string) =>
  localStorage.setItem('posthogSession', sessionId)
export const getPosthogSession = () => localStorage.getItem('posthogSession')

export const getTokenLocalStorage = (isAssume: boolean) => {
  if (isAssume) {
    return getAssumeToken()
  } else {
    return getToken()
  }
}

const urlPattern = /^(\/)?(core|billing|ai|smart-tts)/

export const setTokenHeaderFromViaConfig = (axiosConfig: InternalAxiosRequestConfig) => {
  const token = getAssumeToken() || getToken()

  if (token) {
    axiosConfig.headers.authorization = `Bearer ${token}`
  }

  const canAddHeader = urlPattern.test(axiosConfig.url || '')
  const sessionID = getPosthogSession()
  const posthogSessionIdKey = window.Config?.posthog_session_id_key
  const userMetadataHeader = window.Config?.user_metadata_header

  if (sessionID && posthogSessionIdKey && userMetadataHeader && canAddHeader) {
    axiosConfig.headers[userMetadataHeader] = btoa(
      JSON.stringify({ [posthogSessionIdKey]: sessionID })
    )
  }
}

export const setTokenLocalStorageViaConfig = (axiosConfig: AxiosResponse, isAssume: boolean) => {
  const config = axiosConfig?.config
  const url = config?.url
  const data = axiosConfig?.data

  if (
    url?.includes('2fa/auth/login') ||
    url?.includes('auth/register/secondary') ||
    (url?.includes('auth/switch') && data?.switched)
  ) {
    const token = data.token

    setToken(token.access_token)
  }

  if (url?.includes('auth/refresh')) {
    const isLogin = data.is_login

    if (isLogin === true) {
      const token = data.token

      setTokenLocalStorage(token.access_token, isAssume)
    }
  }
}

export const setTokenLocalStorage = (token: string, isAssume: boolean) => {
  if (token) {
    if (isAssume) {
      setAssumeToken(token)
    } else {
      setToken(token)
    }
  }
}

export const removeTokenLocalStorage = (isAssume: boolean) => {
  if (isAssume) {
    removeAssumeToken()
  } else {
    removeToken()
  }
}
