import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { makeTextFieldIcon, TextField, TextFieldPlaceholder } from 'shared/ui/TextField'
import { Scrollbar, Tabs } from 'shared/ui'
import { useTextToSpeechContext } from 'widgets/TextToSpeech'
import { VoiceItem } from 'widgets/TextToSpeech/ui/TextToSpeechVoice/VoicesContent/VoiceItem/VoiceItem'
import styles from './styles.module.scss'

type IVoicesContentProps = {
  onCloseMenu: () => void
}

export const VoicesContent: FC<IVoicesContentProps> = observer(({ onCloseMenu }) => {
  const {
    loadVoices,
    search,
    setSearch,
    tabs,
    activeTabKey,
    isCreateCustomVoice,
    handleSelectTab,
    voices,
    onCreateCustomVoice,
  } = useTextToSpeechContext()
  useEffect(() => {
    loadVoices()
    return () => setSearch('')
  }, [])

  const handleCreateCustomVoice = () => {
    onCloseMenu()
    onCreateCustomVoice()
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <TextField
          variant={'integrated'}
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={setSearch}
        />
      </div>
      <Tabs
        tabs={tabs}
        activeTabKey={activeTabKey}
        handleSelectTab={handleSelectTab}
        paddingInlineWrap={4}
      />
      <div className={styles.content}>
        <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={192}>
          {voices.map((voice) => (
            <VoiceItem key={voice.id} voice={voice} onCloseMenu={onCloseMenu} />
          ))}
          {isCreateCustomVoice && (
            <TextFieldPlaceholder
              leftActions={[
                makeTextFieldIcon({
                  icon: 'add',
                  margin: 0,
                }),
              ]}
              placeholder={'Create custom voice'}
              size={'small'}
              variant={'outline'}
              onClick={handleCreateCustomVoice}
            />
          )}
        </Scrollbar>
      </div>
    </div>
  )
})
