import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseConversationUnread } from 'entities/Conversation/api/types'
import { CallHistoryStore } from 'widgets/CallHistory'

export const EventConversationUnread = {
  subscribe: (handler: (data: IResponseConversationUnread) => void) =>
    websocket.on(WebsocketEvent.ConversationUnread, handler),
  unsubscribe: (handler: (data: IResponseConversationUnread) => void) =>
    websocket.off(WebsocketEvent.ConversationUnread, handler),
}

export const useEventConversationUnread = (callHistoryStore: CallHistoryStore) => {
  const handler = (data: IResponseConversationUnread) => {
    if (data.conversation.unread_count === 0) {
      callHistoryStore.callHistoryFiltersListStore.resetAllCount()
      callHistoryStore.markAsReadAllList()
    } else if (data.conversation.unread_count > 0) {
      if (data.read_messages.length) {
        callHistoryStore.callHistoryFiltersListStore.decreaseCount(data.read_messages)
      }
    }
  }

  useEffect(() => {
    EventConversationUnread.subscribe(handler)

    return () => {
      EventConversationUnread.unsubscribe(handler)
    }
  }, [])
}
