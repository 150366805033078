import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import {
  IParamsUpdateUserSettings,
  IResponseOrganizationSettings,
  IResponseUpdateUserSettings,
  IResponseUserSettings,
} from 'entities/Settings/api/types'

class Api {
  getUsersSettings(): Promise<AxiosResponse<IResponseUserSettings>> {
    return baseApi.get('users/settings')
  }

  getOrganizationSettings(): Promise<AxiosResponse<IResponseOrganizationSettings>> {
    return baseApi.get('organization/settings')
  }

  updateUsersSetting(
    params: IParamsUpdateUserSettings
  ): Promise<AxiosResponse<IResponseUpdateUserSettings>> {
    return baseApi.patch('users/settings', params)
  }
}

export const SettingsApi = new Api()
