import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { MainDropdownContent } from './MainDropdownContent/MainDropdownContent'

type DropdownContentProps = {
  onCloseMenu?: () => void
  parentsDropdownId?: string[]
}

export const DropdownContent: FC<DropdownContentProps> = observer(
  ({ onCloseMenu = () => {}, parentsDropdownId }) => {
    return <MainDropdownContent onCloseMenu={onCloseMenu} parentsDropdownId={parentsDropdownId} />
  }
)
