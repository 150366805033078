import type { ITextFieldBaseAsideAction } from 'shared/ui/TextField/types'
import { Loader } from './Loader'

type ITextFieldLoading = {
  loading?: boolean
}

export const makeTextFieldLoading: (action: ITextFieldLoading) => ITextFieldBaseAsideAction = (
  action
) => {
  const useAction: ITextFieldBaseAsideAction = () => <Loader show={!!action.loading} />

  return useAction
}
