// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".n44oV2HLRAVCETqCHAEA{display:flex;padding:16px;justify-content:space-between;align-items:center;align-self:stretch;cursor:pointer;user-select:none}.Gc68rBMFZtNAXV2IDQbN{display:flex;align-items:center;column-gap:9px}", "",{"version":3,"sources":["webpack://./src/widgets/CallPopUp/ui/components/AccordionSection/styles.module.scss"],"names":[],"mappings":"AAIA,sBACE,YAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":[".wrap {\n\n}\n\n.section {\n  display: flex;\n  padding: 16px;\n  justify-content: space-between;\n  align-items: center;\n  align-self: stretch;\n  cursor: pointer;\n  user-select: none;\n}\n\n.box {\n  display: flex;\n  align-items: center;\n  column-gap: 9px;\n}\n\n.title {\n\n}\n\n.button {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "n44oV2HLRAVCETqCHAEA",
	"box": "Gc68rBMFZtNAXV2IDQbN"
};
export default ___CSS_LOADER_EXPORT___;
