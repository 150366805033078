import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SwitchField } from 'shared/ui'
import { AdvancedScheduling } from 'shared/ui/Schedule/ui/AdvancedScheduling'
import { DateEndTime } from '../../DateEndTime/DateEndTime'
import { useSendLaterContext } from '../context/sendLaterContext'

export type ISendLaterContentProps = {
  dropdownId?: string
}

export const SendLaterContent = observer(({ dropdownId }: ISendLaterContentProps) => {
  const {
    initDefault,
    advancedSchedulingStore,
    toggleUseContactTimezone,
    isUseContactTimezone,
    date,
    setDate,
    dropdownId: storeDropdownId,
    defaultDate,
  } = useSendLaterContext()

  useEffect(initDefault, [])
  return (
    <>
      <Box
        padding={1}
        borderBottom={'1px solid var(--border-neutral-default)'}
        display={'grid'}
        gap={'4px'}
      >
        <DateEndTime
          date={date}
          defaultDate={defaultDate}
          setDate={setDate}
          dropdownId={dropdownId || storeDropdownId}
        />
      </Box>

      <Box padding={1} borderBottom={'1px solid var(--border-neutral-default)'}>
        <SwitchField
          value={isUseContactTimezone}
          onClick={toggleUseContactTimezone}
          labelProps={{
            label: {
              leftIcon: 'userFilled',
              text: 'Use contact timezone',
            },
          }}
        />
      </Box>
      {advancedSchedulingStore && (
        <AdvancedScheduling
          withoutTimePicker
          store={advancedSchedulingStore}
          expandedBoxProps={{
            borderRadius: '0 0 8px 8px',
          }}
        />
      )}
    </>
  )
})
