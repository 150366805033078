import { Box } from '@mui/material'
import { Typography } from 'shared/ui'
import { IEinItem } from 'entities/Compliance/api/type'

interface IEinItemProps {
  einItem: IEinItem | null
}

export const EinItem = ({ einItem }: IEinItemProps) => {
  if (!einItem) return null

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography
        ariaLabel='EinItem_line'
        variant='body-md-regular'
        color='var(--content-primary-primary)'
      >
        {einItem.name}
      </Typography>
      <Typography
        ariaLabel='EinItem_line'
        variant='body-xs-regular'
        color='var(--content-primary-tertiary)'
      >
        {einItem.street1}
      </Typography>
      <Typography
        ariaLabel='EinItem_line'
        variant='body-xs-regular'
        color='var(--content-primary-tertiary)'
      >
        EIN: {einItem.ein}
      </Typography>
    </Box>
  )
}
