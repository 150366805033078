import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { conversationStore } from 'entities/Conversation'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'
import { ConversationSearch } from 'widgets/ConversationNew/ui/ConversationSearch/ConversationSearch'
import styles from './styles.module.scss'

export const ConversationNewContent = observer(() => {
  const store = useConversationNewSearchContext()
  const storeMessages = store.conversationMessagesStore

  useEffect(() => {
    store.setVisibleUI(true)
    store.reactionDropDownOpen()
    store.reactionInbox()
    store.reactionItems()
    store.initInbox()
    if (!conversationStore.currentItem?.isNew) conversationStore.createNewConversation()

    return () => {
      store.setVisibleUI(false)
      store.reset()
      store.clearReactions()
      conversationStore.deleteItem(0)
    }
  }, [])

  useEffect(() => {
    storeMessages?.resetMessage()
    storeMessages?.loadMessagesContact({ before: storeMessages.loadingBeforeId })
  }, [store.activeInboxId, store.items.length])

  return (
    <div className={styles.wrap}>
      <ConversationSearch />
    </div>
  )
})
