import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Icon, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { conversionStore, Conversion } from 'entities/Conversion'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import styles from './styles.module.scss'

export const TrackConversionContent = observer(() => {
  const [conversion, setConversion] = useState<Conversion | null>(null)
  const broadcast = useBroadcastDetailModalContext()

  useEffect(() => {
    conversionStore.getItemById(broadcast.conversion_id).then((conversion) => {
      if (conversion) {
        setConversion(conversion)
      }
    })
  }, [])

  return (
    <div className={styles.wrap}>
      <Typography
        ariaLabel={'Send'}
        variant={'body-rg-medium'}
        className={styles.title}
        tag={'div'}
      >
        Track conversions
      </Typography>
      <div className={styles.grid}>
        {broadcast.short_urls?.map((item, index) => (
          <>
            {index === 0 ? (
              <Typography ariaLabel={'Send'} variant={'text-input-field'} className={styles.label}>
                Link
              </Typography>
            ) : (
              <div />
            )}
            <div className={styles.value}>
              <div>
                <div className={styles.linkName}>
                  <Icon icon={'link'} tertiary />
                  <Typography
                    ariaLabel={getAriaLabel('ConversionName')}
                    variant={'text-input-field-r'}
                  >
                    {conversion?.name}
                  </Typography>
                </div>
                <a
                  href={item.original_url}
                  className={styles.link}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <Typography
                    ariaLabel={getAriaLabel('Link')}
                    variant={'body-md-medium'}
                    color={'tertiary'}
                  >
                    {item.original_url}
                  </Typography>
                </a>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
})
