import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DatePicker, DropdownContent } from 'shared/ui'
import {
  dateDropdownContentItems,
  SearchDateEnum,
  SearchDropdownDateStore,
  useSearchDropdownContext,
} from 'widgets/SearchDropdown'
import styles from './styles.module.scss'

type IDateDropdownContentProps = {
  onCloseMenu: () => void
  store: SearchDropdownDateStore
}

export const DateDropdownContent: FC<IDateDropdownContentProps> = observer(
  ({ onCloseMenu = () => {}, store }) => {
    const { search } = useSearchDropdownContext()
    const { selectDate, dateType, from, to, selectStartDate, selectRangeDate, selectEndDate } =
      store

    const handleSelect = (type: SearchDateEnum) => {
      selectDate(type)

      if (type !== SearchDateEnum.custom) {
        onCloseMenu()
      }
    }

    return (
      <DropdownContent noPadding>
        <div className={styles.wrap}>
          {dateType !== SearchDateEnum.custom ? (
            <div className={styles.items}>
              {dateDropdownContentItems
                .filter((item) =>
                  item.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                )
                .map((item) => (
                  <div
                    className={styles.item}
                    key={item.id}
                    onClick={() => handleSelect(item.type)}
                  >
                    <span className={styles.label}>{item.label}</span>
                  </div>
                ))}
            </div>
          ) : (
            <DatePicker
              type='range'
              handleEndDate={selectEndDate}
              handleStartDate={selectStartDate}
              handleRangeDate={selectRangeDate}
              startDate={from}
              isClearable
              hideTimePicker
              endDate={to}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          )}
        </div>
      </DropdownContent>
    )
  }
)
