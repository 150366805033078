import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Icon, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { conversionStore, type Conversion } from 'entities/Conversion'
import type { IResponseShortUrl } from 'entities/ContactStatistic'
import styles from '../styles.module.scss'

type TrackConversionContentProps = {
  conversionId: number | null | undefined
  short_urls: IResponseShortUrl[] | null
  isTrackConversion: boolean | null | undefined
}

export const TrackConversionContent = observer(
  ({ conversionId, short_urls, isTrackConversion }: TrackConversionContentProps) => {
    const [conversion, setConversion] = useState<Conversion | null>(null)
    useEffect(() => {
      conversionStore.getItemById(conversionId).then((conversion) => {
        if (conversion) {
          setConversion(conversion)
        }
      })
    }, [])

    if (!isTrackConversion || !short_urls?.length || !conversionId) {
      return null
    }

    return (
      <div className={styles.wrap}>
        <Typography
          ariaLabel={'Send'}
          variant={'body-rg-medium'}
          className={styles.title}
          tag={'div'}
        >
          Track conversions
        </Typography>
        <div className={classNames(styles.grid, styles.gridGap)}>
          {short_urls.map((item, index) => (
            <>
              {index === 0 ? (
                <Typography
                  ariaLabel={'Send'}
                  variant={'text-input-field'}
                  className={styles.label}
                >
                  Link
                </Typography>
              ) : (
                <div />
              )}
              <div className={styles.value}>
                <div>
                  <div className={styles.linkName}>
                    <Icon icon={'link'} tertiary />
                    <Typography
                      ariaLabel={getAriaLabel('ConversionName')}
                      variant={'text-input-field-r'}
                    >
                      {conversion?.name || '-'}
                    </Typography>
                  </div>
                  <a
                    href={item.original_url}
                    className={styles.link}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    <Typography
                      ariaLabel={getAriaLabel('Link')}
                      variant={'body-md-medium'}
                      color={'tertiary'}
                    >
                      {item.original_url}
                    </Typography>
                  </a>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    )
  }
)
