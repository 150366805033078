import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { AuthLayout } from 'shared/layout'
import { RegisterStore } from 'widgets/Register'
import { Step3Form } from './Step3Form/Step3Form'
import { RightContent } from './RightContent/RightContent'

type IStep1Props = {
  store: RegisterStore
}

export const Step3: FC<IStep1Props> = observer(({ store }) => {
  return (
    <AuthLayout
      title='Which country do you want to send messages to?'
      subtitle='Salesmsg is available now in the USA and Canada'
      form={<Step3Form store={store} />}
      rightContent={<RightContent />}
      onBack={store.handlePrevStep}
    />
  )
})

export default Step3
