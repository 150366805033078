import classNames from 'classnames'
import { IIconsVector, Icon } from 'shared/ui'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import styles from './styles.module.scss'

const iconMapper = {
  [ModalTypeList.SUCCESS]: 'check1',
  [ModalTypeList.INFO]: 'info',
  [ModalTypeList.WARNING]: 'alert',
  [ModalTypeList.ALERT]: 'alert',
  [ModalTypeList.FAIL]: 'close',
} as Record<ModalTypeList, IIconsVector>

type ModalLargeIconProps = {
  type: ModalTypeList
}

export const ModalLargeIcon = ({ type = ModalTypeList.INFO }: ModalLargeIconProps) => {
  return (
    <div className={classNames(styles.wrap, styles[type])}>
      <div className={classNames(styles.wrapIcon, styles[type])}>
        <Icon icon={iconMapper[type]} />
      </div>
    </div>
  )
}
