import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { authStore } from 'entities/Auth'

export default observer(() => {
  useEffect(() => {
    if (authStore.isLoggedIn) {
      uiStore.changeRoute({
        path: '/',
      })
      window.location.reload()
    }
  }, [authStore.isLoggedIn])
  return <Outlet />
})
