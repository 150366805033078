import { MouseEvent, FC } from 'react'
import { Button } from 'shared/ui'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { useConversationListContext } from 'widgets/ConversationList/context'

type IButtonMarkAsUnreadProps = {
  conversation: Conversation
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  clickCallback?: () => void
}

export const ButtonMarkAsUnread: FC<IButtonMarkAsUnreadProps> = ({
  conversation,
  onMouseEnter,
  onMouseLeave,
  clickCallback,
}) => {
  const store = useConversationListContext()
  const { readConversations, unreadConversations } = store

  const handleClick = async (e: MouseEvent) => {
    e.stopPropagation()

    clickCallback?.()

    await (conversation.isRead ? unreadConversations : readConversations)(conversation.id)
  }

  return (
    <Button
      fontWeight={400}
      onClick={handleClick}
      typeBtn='menuItem'
      icon={conversation.isRead ? 'chatUnread' : 'chatRead'}
      text={conversation.isRead ? 'Mark as unread' : 'Mark as read'}
      fullWidth
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}
