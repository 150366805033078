import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Icon, SpinnerLoader, Typography } from 'shared/ui'
import { useSendFromDropdownContext } from '../context/sendFromDropdownContext'
import styles from './styles.module.scss'

export const SendFromTrigger = observer(() => {
  const { loading, itemsCount, selectedItem, iconField } = useSendFromDropdownContext()

  const content = () => {
    if (loading) {
      return (
        <div className={styles.loaderTrigger}>
          <SpinnerLoader size={10} indeterminatecolor={'var(--content-primary-tertiary)'} />
        </div>
      )
    }

    if (!itemsCount) {
      return (
        <Typography
          className={classnames(styles.trigger__label)}
          variant={'text-input-field'}
          ariaLabel={'No results found'}
        >
          No results found
        </Typography>
      )
    }

    if (!selectedItem) {
      return (
        <Typography
          className={classnames(styles.trigger__label, styles['trigger__label--placeholder'])}
          variant={'text-input-field'}
          ariaLabel={'Select inbox'}
        >
          Select inbox
        </Typography>
      )
    }

    return (
      <Typography className={styles.trigger__label} variant={'text-input-field'} ariaLabel={'name'}>
        <div className={styles.trigger__name}>{selectedItem.name}</div>
        <div className={styles.trigger__dot}>
          <Icon icon={'circleDot'} fontSize={2} />
        </div>
        <div className={styles.trigger__number}>{selectedItem.label}</div>
      </Typography>
    )
  }

  return (
    <div className={styles.trigger}>
      <div className={styles.trigger__icon}>
        <Icon icon={iconField} fontSize={16} />
      </div>
      {content()}
    </div>
  )
})
