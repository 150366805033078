import { ReactNode, useEffect } from 'react'
import styles from 'shared/ui/Actions/styles.module.scss'
import { Action } from 'shared/ui/Actions/Action'
import { ActionItem } from 'shared/ui/Actions/Actions'

type Props = {
  setOnCloseMoreDropdown?: (fn: () => void) => void
  onCloseMenu: () => void
  moreDropdownComponent?: ((onCloseMenu?: () => void) => ReactNode) | null
  moreActions: Array<ActionItem>
}

export const ActionsCustomComponent = ({
  setOnCloseMoreDropdown,
  onCloseMenu,
  moreDropdownComponent,
  moreActions,
}: Props) => {
  useEffect(() => {
    setOnCloseMoreDropdown &&
      setOnCloseMoreDropdown(() => {
        onCloseMenu()
      })
  }, [])
  return (
    <div className={styles.wrap}>
      {moreDropdownComponent ? (
        moreDropdownComponent(onCloseMenu)
      ) : (
        <div className={styles.content}>
          {moreActions.map((action, index) => (
            <Action key={index} fromDropdown={true} onCloseMenu={onCloseMenu} action={action} />
          ))}
        </div>
      )}
    </div>
  )
}
