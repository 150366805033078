import { FC } from 'react'
import { StripeProvider } from 'shared/ui/StripeCardInput/provider/StripeProvider'
import { StripeCardInputUi } from 'shared/ui/StripeCardInput/ui/StripeCardInputUi'

export type IStripeCardElementProps = StripeCardInputUi

export const StripeCardInput: FC<IStripeCardElementProps> = (props) => (
  <StripeProvider>
    <StripeCardInputUi {...props} />
  </StripeProvider>
)
