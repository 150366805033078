import styles from './styles.module.scss'

export interface IPresetInfoProps {
  name: string
  description?: string
}

export const PresetInfo = ({ name, description }: IPresetInfoProps) => (
  <header className={styles.info}>
    <h4 className={styles.infoTitle}>{name}</h4>
    {!!description && <p className={styles.infoDescription}>{description}</p>}
  </header>
)
