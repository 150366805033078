// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pbb4Hvmy1yO4Mfl542Ap{display:flex;flex-direction:column;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotEdit/ChatbotActions/ActionTags/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".tagContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagContainer": "pbb4Hvmy1yO4Mfl542Ap"
};
export default ___CSS_LOADER_EXPORT___;
