import { makeObservable, action, observable } from 'mobx'

export class AudioVolumeLevelStore {
  microphoneAudioContext: AudioContext = new AudioContext()
  microphoneAudioAnalyzer: AnalyserNode | null = null
  microphoneAudioStreamSource: MediaStreamAudioSourceNode | null = null
  microphoneAudioArray: Uint8Array = new Uint8Array(1)
  microphoneAudioFrame = 0
  microphoneAudioLevel = 0
  microphoneAudioLevelItems: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

  constructor() {
    makeObservable(this, {
      init: action,
      handleLoop: action,
      microphoneAudioLevel: observable,
    })
  }

  init = (stream: MediaStream) => {
    this.microphoneAudioAnalyzer = this.microphoneAudioContext.createAnalyser()
    this.microphoneAudioStreamSource = this.microphoneAudioContext.createMediaStreamSource(stream)
    this.microphoneAudioStreamSource.connect(this.microphoneAudioAnalyzer)
    this.handleLoop()
  }

  handleLoop = () => {
    this.microphoneAudioFrame = window.requestAnimationFrame(this.handleLoop)

    if (this.microphoneAudioAnalyzer) {
      this.microphoneAudioAnalyzer.getByteFrequencyData(this.microphoneAudioArray)
      this.microphoneAudioLevel = Math.ceil((this.microphoneAudioArray[0] * 100) / 255)
    }
  }

  handleClear = () => {
    window.cancelAnimationFrame(this.microphoneAudioFrame)
  }
}
