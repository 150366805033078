import { ClickAwayListener, Popper } from '@mui/material'
import React, { FC, MutableRefObject, ReactNode, useState } from 'react'
import classNames from 'classnames'
import { TooltipProps } from '@mui/material/Tooltip'
import { ZIndex } from 'shared/constants/zIndex'
import styles from './styles.module.scss'

export type ITriggerTooltipProps = {
  trigger: HTMLSpanElement | HTMLLinkElement | undefined
  onLeaveTooltip?: () => void
  refVisibleTooltip?: MutableRefObject<boolean>
  component?: ReactNode
  color?: 'black' | 'white'
  arrow?: boolean
  parent?: HTMLDivElement | null
  open?: boolean
  placement?: TooltipProps['placement']
  onClickAway?: () => void
  marginBottom?: number | string
}

export const TriggerTooltip: FC<ITriggerTooltipProps> = ({
  trigger,
  refVisibleTooltip,
  onLeaveTooltip,
  component,
  color = 'black',
  arrow,
  parent,
  open,
  placement = 'top',
  onClickAway,
  marginBottom,
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null)

  const openPopper = open ?? Boolean(trigger)

  const onMouseEnter = () => {
    setTimeout(() => {
      if (refVisibleTooltip) {
        refVisibleTooltip.current = true
      }
    })
  }

  if (!trigger) {
    return <></>
  }

  const content = (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onLeaveTooltip}
      className={classNames(styles.wrap, color && styles[color])}
      style={{ marginBottom }}
    >
      {arrow && <span className={styles.arrow} ref={setArrowRef} />}
      {component}
    </div>
  )

  return (
    <Popper
      open={openPopper}
      anchorEl={trigger}
      placement={placement}
      sx={{ zIndex: ZIndex.OVERLAY_MIDDLE }}
      disablePortal={false}
      modifiers={[
        {
          name: 'arrow',
          enabled: arrow,
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: parent,
          },
        },
      ]}
    >
      <div>
        {onClickAway ? (
          <ClickAwayListener onClickAway={onClickAway}>{content}</ClickAwayListener>
        ) : (
          content
        )}
      </div>
    </Popper>
  )
}
