import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { CopyWrapper } from 'shared/ui'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IntegrationDealProperty } from 'entities/Integrations/model'
import type { IntegrationKey } from 'entities/Integrations'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/AllView/styles.module.scss'
import {
  ShowMoreAction,
  ShowMoreActionStore,
} from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { Actions } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/Actions'
import { ItemRow } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ItemRow/ItemRow'

type IDealProps = {
  integrationKey: IntegrationKey
  deal: IntegrationDealProperty
  link: string
  contact: Contact
}

export const Deal: FC<IDealProps> = observer(({ deal, link, contact, integrationKey }) => {
  const showMoreActionStore = useMemo(
    () => new ShowMoreActionStore(integrationKey, EnumIntegrationTabs.Deals),
    []
  )
  const properties = showMoreActionStore.modifyData(deal.properties)

  return (
    <div className={styles.item}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={deal.title}
          clickableTitle
          data={{
            title: deal.title || 'View deal',
            link: link,
          }}
        />
      </div>
      {properties.map((property) => {
        return <ItemRow key={property.key} item={property} labelType={'simple'} />
      })}
      <Actions
        rows={[
          {
            columns: [
              !showMoreActionStore.hideShowMore && (
                <ShowMoreAction key={'show_more'} disabled={!contact} store={showMoreActionStore} />
              ),
            ],
          },
        ]}
      />
    </div>
  )
})
