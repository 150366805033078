import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import { Avatar, Icon, IconButton, Tooltip, Typography } from 'shared/ui'
import {
  formatPhoneNumberInternational,
  getLabelAsNumberInternationalFormat,
  isNumeric,
} from 'shared/lib'
import { ZIndex } from 'shared/constants/zIndex'
import { CallPeople } from 'entities/Call/model/CallPeople'
import { useCopyToClipboard } from 'widgets/CallPopUp/hooks/useCopyToClipboard'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import styles from './styles.module.scss'

type ICallListItemProps = {
  search?: string
  item: CallPeople
  onChange?: (item: CallPeople) => void
}

export const CallListItem: React.FC<ICallListItemProps> = observer(
  ({ search = '', item, onChange }) => {
    const [showEllipsis, setShowEllipsis] = useState(false)
    const store = useCallPopUpDefaultContext()
    const {
      innerRefTooltipCurrent,
      handleChangeHoldParticipant,
      handleDeleteParticipant,
      isHover,
    } = store

    const number =
      item.national_number &&
      getLabelAsNumberInternationalFormat(formatPhoneNumberInternational(item.national_number))

    const { onClickCopy, setOpen, open, copied } = useCopyToClipboard(number)

    const hasNumber = item.name.startsWith('+') && isNumeric(item.name)

    return (
      <div
        key={item.id}
        className={classnames(styles.listItem, {
          [styles['listItem--disabled']]: item.disabled,
        })}
        onClick={() => {
          if (item.disabled) return

          onChange?.(item)
        }}
      >
        <div className={styles.listItem__left}>
          <div
            className={classnames(styles.listItem__icon, {
              [styles['listItem__icon--rect']]: item.type === 'number',
            })}
          >
            <Avatar {...item.avatarProps} />
          </div>
          <div className={styles.listItem__info}>
            <div
              className={styles.listItem__label}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            >
              <Typography
                ellipsis
                tag={'div'}
                ariaLabel='callItemName'
                className={styles.listItem__title}
                tooltipProps={{ label: item.name, zIndex: ZIndex.OVERLAY_TOP_1 }}
                onShowEllipsis={setShowEllipsis}
              >
                <Highlighter
                  highlightClassName={styles.highlighter}
                  searchWords={[search]}
                  autoEscape={true}
                  textToHighlight={item.name || ''}
                />
              </Typography>
              {number && item.is_participant && (
                <Tooltip
                  placement='top'
                  padding={4}
                  label={
                    <>
                      {!hasNumber && showEllipsis && (
                        <Typography
                          tag={'div'}
                          ariaLabel='callPopUpTooltipTitle'
                          variant={'body-sm-regular'}
                          color={'var(--content-primary-tertiary-inverted'}
                          className={styles.listItem__tooltipTitle}
                        >
                          {item.name}
                        </Typography>
                      )}
                      <button className={styles.listItem__button} onClick={onClickCopy}>
                        <div className={styles.listItem__infoButton}>
                          <Icon
                            icon='copy'
                            fontSize={12}
                            color={'var(--content-primary-primary-inverted'}
                          />
                          <Typography
                            tag={'div'}
                            ariaLabel='callPopUpNumber'
                            variant={'body-md-regular'}
                            color={'var(--content-primary-primary-inverted'}
                            className={styles.listItem__infoNumber}
                          >
                            {copied ? 'Copied' : hasNumber ? 'Copy' : number}
                          </Typography>
                        </div>
                      </button>
                    </>
                  }
                  zIndex={ZIndex.OVERLAY_TOP_1}
                  open={open}
                >
                  <Icon
                    className={classnames(styles.listItem__infoIcon, {
                      [styles.visible]: isHover,
                    })}
                    icon='infoCircle'
                    fontSize={12}
                    color={'var(--content-neutral-primary)'}
                  />
                </Tooltip>
              )}
            </div>
            {item.label && (
              <div
                className={classnames(styles.listItem__number, {
                  [styles.listItem__hold]: item.isHold,
                })}
              >
                <Highlighter
                  highlightClassName={styles.highlighter}
                  searchWords={[search]}
                  autoEscape={true}
                  textToHighlight={
                    (item.label && getLabelAsNumberInternationalFormat(item.label)) || ''
                  }
                />
              </div>
            )}
          </div>
        </div>

        {item.is_participant && item.status !== 'calling' && (
          <div className={styles.actions}>
            <div className={styles.action}>
              <IconButton
                iconProps={{
                  fontSize: 16,
                  icon: item.isHold ? 'play1' : 'pause1',
                }}
                tooltipProps={{
                  zIndex: ZIndex.OVERLAY_TOP_1,
                  label: item.isHold ? 'Resume' : 'Put on hold',
                  placement: 'top',
                  PopperProps: {
                    popperOptions: {
                      modifiers: [
                        {
                          name: 'preventOverflow',
                          options: {
                            boundary: innerRefTooltipCurrent,
                          },
                        },
                      ],
                    },
                  },
                }}
                loading={item.isHoldLoading}
                disabled={item.isHoldLoading || item.isHoldDisabled}
                spinnerLoaderProps={{
                  determinatecolor: 'var(--gray-30)',
                  indeterminatecolor: 'var(--gray-70)',
                }}
                variant={'icon'}
                color={'secondary'}
                size={'small'}
                ariaLabel={`CallListItem_${item.isHold ? 'resume' : 'putOnHold'}`}
                onClick={() => {
                  handleChangeHoldParticipant(item)
                }}
              />
            </div>

            <div className={styles.action}>
              <IconButton
                iconProps={{
                  fontSize: 16,
                  icon: 'phoneHangUp',
                }}
                tooltipProps={{
                  label: 'Remove from call',
                  zIndex: ZIndex.OVERLAY_TOP_1,
                  placement: 'top',
                  PopperProps: {
                    popperOptions: {
                      modifiers: [
                        {
                          name: 'preventOverflow',
                          options: {
                            boundary: innerRefTooltipCurrent,
                          },
                        },
                      ],
                    },
                  },
                }}
                loading={item.isDeleteLoading}
                disabled={item.isDeleteLoading}
                spinnerLoaderProps={{
                  determinatecolor: 'var(--gray-30)',
                  indeterminatecolor: 'var(--gray-70)',
                }}
                variant={'icon'}
                color={'secondary'}
                size={'small'}
                onClick={() => {
                  handleDeleteParticipant(item)
                }}
                ariaLabel='CallListItem_removeFromCall'
              />
            </div>
          </div>
        )}
      </div>
    )
  }
)
