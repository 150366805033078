// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I5YpCHn1j5BC9yGNX0Ne{color:var(--content-primary-primary);margin:20px auto 4px;font-size:14px;font-weight:500}.JLsvKqiXyRw41FvPz31K{margin-top:4px;margin-bottom:21px;color:var(--content-primary-tertiary);font-size:13px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/AddFirstContactContent/AddFirstContactContent.module.scss"],"names":[],"mappings":"AAAA,sBACI,oCAAA,CACA,oBAAA,CACA,cAAA,CACA,eAAA,CAEJ,sBACI,cAAA,CACA,kBAAA,CACA,qCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".headerText {\n    color: var(--content-primary-primary);\n    margin: 20px auto 4px ;\n    font-size: 14px;\n    font-weight: 500;\n}\n.additionText {\n    margin-top: 4px;\n    margin-bottom: 21px;\n    color: var(--content-primary-tertiary);\n    font-size: 13px;\n    font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerText": "I5YpCHn1j5BC9yGNX0Ne",
	"additionText": "JLsvKqiXyRw41FvPz31K"
};
export default ___CSS_LOADER_EXPORT___;
