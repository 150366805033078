import { observer } from 'mobx-react-lite'
import React from 'react'
import { Select } from 'shared/ui/Select/Select'
import { ContainerHeader } from 'shared/ui'
import { tollFreeStatusesDropdownItems } from 'entities/Compliance'
import { ISubscriptionStatus, subscriptionsStatusDropdownItems } from 'entities/Subscription'
import { adminTollFreeTableStore } from 'pages/admin/pages/compliance/pages/TollFree'

export const TollFreeHeader = observer(() => {
  const { statuses, setStatuses, subscriptionStatuses, setSubscriptionStatuses } =
    adminTollFreeTableStore
  return (
    <ContainerHeader
      title={'Compliance queue'}
      titleIconProps={{
        icon: 'users',
        fontSize: 20,
        tertiary: true,
      }}
      actionsProps={{
        gap: 8,
        actions: [
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Status'}
                values={statuses}
                onSelectValues={setStatuses}
                items={tollFreeStatusesDropdownItems}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Subscription status'}
                values={subscriptionStatuses}
                onSelectValues={(data) => {
                  const values = data as Array<ISubscriptionStatus>
                  setSubscriptionStatuses(values)
                }}
                items={subscriptionsStatusDropdownItems}
              />
            ),
          },
        ],
      }}
    />
  )
})
