import { observer } from 'mobx-react-lite'
import React from 'react'
import { Select } from 'shared/ui/Select/Select'
import { ContainerHeader } from 'shared/ui'
import {
  tenDLCBarnStatusesDropdownItems,
  tenDLCBusinessProfileStatusesDropdownItems,
  tenDLCCampaignStatusesDropdownItems,
} from 'entities/Compliance/ui/TenDLCStatus'
import { ISubscriptionStatus, subscriptionsStatusDropdownItems } from 'entities/Subscription'
import { adminTenDLCTableStore } from 'pages/admin/pages/compliance/pages/TenDLC'

export const TenDLCHeader = observer(() => {
  const {
    profileStatuses,
    brandStatuses,
    campaignStatuses,
    setProfileStatus,
    setBrandStatuses,
    setCampaignStatuses,
    subscriptionStatuses,
    setSubscriptionStatuses,
  } = adminTenDLCTableStore
  return (
    <ContainerHeader
      title={'Compliance queue'}
      titleIconProps={{
        icon: 'users',
        fontSize: 20,
        tertiary: true,
      }}
      actionsProps={{
        gap: 8,
        actions: [
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Profile status'}
                values={profileStatuses}
                onSelectValues={setProfileStatus}
                items={tenDLCBusinessProfileStatusesDropdownItems}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Brand status'}
                values={brandStatuses}
                onSelectValues={setBrandStatuses}
                items={tenDLCBarnStatusesDropdownItems}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Campaign status'}
                values={campaignStatuses}
                onSelectValues={setCampaignStatuses}
                items={tenDLCCampaignStatusesDropdownItems}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Subscription status'}
                values={subscriptionStatuses}
                onSelectValues={(data) => {
                  const values = data as Array<ISubscriptionStatus>

                  setSubscriptionStatuses(values)
                }}
                items={subscriptionsStatusDropdownItems}
              />
            ),
          },
        ],
        showItems: 4,
      }}
    />
  )
})
