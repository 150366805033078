import { FC, FormEvent, useEffect } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent, TokenResult } from '@stripe/stripe-js'
import classNames from 'classnames'
import styles from './styles.module.scss'

export type StripeCardInputUi = {
  onChange: (event: StripeCardElementChangeEvent) => void
  onFocus: () => void
  setOnSubmit: (onSubmit: () => Promise<TokenResult | undefined>) => void
  isError: boolean
  disabled?: boolean
}

export const StripeCardInputUi: FC<StripeCardInputUi> = ({
  onFocus,
  onChange,
  setOnSubmit,
  isError,
  disabled,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event?: FormEvent) => {
    event?.preventDefault && event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const card = elements.getElement(CardElement)
    if (!card) {
      return
    }

    return stripe.createToken(card)
  }

  useEffect(() => {
    if (stripe && elements) {
      setOnSubmit(handleSubmit)
    }
  }, [stripe, elements])

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(styles.form, { [styles.error]: isError })}
      aria-label='StripeCardInput'
    >
      <CardElement
        id={'stripe-card-input'}
        options={{
          disabled: !!disabled,
          style: {
            base: {
              '::placeholder': {
                fontWeight: 400,
                color: '#8E979F',
              },
              color: '#121517',
              fontWeight: 400,
              fontSize: '13px',
            },
            invalid: {
              color: '#F53D43',
            },
          },
        }}
        onChange={onChange}
        onFocus={onFocus}
      />
    </form>
  )
}
