import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Button, IconButton, Tooltip } from 'shared/ui'
import { AllFilesAccept, FilesAccept, VideoAccept } from 'shared/constants/accept'
import {
  makeTextFieldAutoFocus,
  makeTextFieldElement,
  makeTextFieldIcon,
  TextField,
} from 'shared/ui/TextField'
import { useMediaLibraryContext } from 'widgets/MediaLibrary/context/mediaLibraryContext'
import { SizeLimitsTooltip } from 'widgets/MessageField'
import { MergeFieldUrlDropdown } from 'widgets/MergeField/ui/actions/MergeFieldUrlDropdown/MergeFieldUrlDropdown'
import { MergeFieldsStore } from 'widgets/MergeField'
import { MergeField } from 'widgets/MergeField/types/MergeField'
import { getPicsnippetMergeFieldLabel, isPicsnippetsUrl } from 'widgets/MediaLibrary/utils'
import styles from './styles.module.scss'

export const MediaLibraryControls: FC = observer(() => {
  const mediaLibraryStore = useMediaLibraryContext()
  const {
    toggleViewType,
    addAttachmentViaUrl,
    selectedViewType,
    searchTerm,
    setSearchTerm,
    isSearchResettingForUpload,
    isAnyFileWaitingForUpload,
    isConversationMessageField,
    conversationContact,
    isAircallInbox,
    isAttachmentInitListLoading,
    setError,
  } = mediaLibraryStore
  const ref = useRef<HTMLInputElement>(null)
  const textRef = useRef<HTMLInputElement>(null)
  const [isUploadViaUrlOpen, setIsUploadViaUrlOpen] = useState(false)
  const [uploadUrl, setUploadUrl] = useState('')
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [inputSearchTerm, setInputSearchTerm] = useState(searchTerm)
  const [mergeFieldStore] = useState(
    () =>
      new MergeFieldsStore({
        onAddMergeField: (field: MergeField) => insertMergeFields(field),
        contact: conversationContact,
        isSelectDefault: true,
      })
  )

  const isGridView = selectedViewType === 'grid'

  useEffect(() => {
    const debouncedSetSearchTerm = debounce(setSearchTerm, 300)
    debouncedSetSearchTerm(inputSearchTerm)

    return () => {
      debouncedSetSearchTerm.cancel()
    }
  }, [inputSearchTerm])

  useEffect(() => {
    if (isSearchResettingForUpload) {
      setSearchTerm('')
      setIsSearchOpen(false)
    }
  }, [isSearchResettingForUpload])

  const handleUploadClick = () => {
    if (ref.current) {
      ref.current.value = ''
      ref.current.click()
    }
  }

  const onUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files) {
      void mediaLibraryStore.addFilesToLibrary(Array.from(files))
    }
  }

  const insertMergeFields = (field: MergeField) => {
    const url = textRef?.current?.value || ''

    if (!isPicsnippetsUrl(url)) {
      setError('Please enter a valid file URL', 'Unable to upload the file')
      return false
    }

    const key = getPicsnippetMergeFieldLabel(field.name)
    const assetUrl = new URL(url)
    if (key) {
      assetUrl.searchParams.set(key, field.value)
      setUploadUrl(assetUrl.href)
      return true
    }

    return false
  }

  return (
    <div className={styles.controls}>
      <div className={styles.buttonsGroup}>
        {!isUploadViaUrlOpen && (
          <>
            <Button
              text='Upload'
              icon='upload'
              disabled={isAttachmentInitListLoading}
              onClick={handleUploadClick}
            />
            <input
              type={'file'}
              className={styles.fileInput}
              ref={ref}
              accept={
                isAircallInbox
                  ? [...VideoAccept, ...FilesAccept].join(',')
                  : AllFilesAccept.join(',')
              }
              disabled={isAttachmentInitListLoading}
              onChange={onUploadChange}
              multiple
            />
            <Button
              text='Add from URL'
              icon='link'
              contained='secondary'
              disabled={isAttachmentInitListLoading || isAircallInbox}
              onClick={() => {
                setIsUploadViaUrlOpen(true)
                setIsSearchOpen(false)
                setInputSearchTerm('')
                setSearchTerm('')
              }}
            />
          </>
        )}
        {isUploadViaUrlOpen && (
          <TextField
            size='extraSmall'
            variant='outline'
            value={uploadUrl}
            onChange={setUploadUrl}
            InputProps={{ placeholder: 'Add or paste a file URL', ref: textRef }}
            leftActions={[
              makeTextFieldIcon({
                icon: 'link',
                color: 'var(--content-primary-tertiary)',
                fontSize: 16,
                margin: '0 4px',
                hidden: isConversationMessageField,
              }),
            ]}
            rightActions={[
              makeTextFieldElement({
                element: isConversationMessageField ? (
                  <>
                    <MergeFieldUrlDropdown mergeFieldStore={mergeFieldStore} />
                    <div className={styles.divider} />
                  </>
                ) : null,
              }),
              () => (
                <Button
                  typeBtn='text'
                  size='small'
                  text='Cancel'
                  onClick={() => {
                    setUploadUrl('')
                    setIsUploadViaUrlOpen(false)
                  }}
                />
              ),
              () => (
                <Button
                  size='small'
                  text='Upload'
                  disabled={!uploadUrl}
                  onClick={() => addAttachmentViaUrl(uploadUrl)}
                />
              ),
            ]}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
            className={classNames(styles.textInput, styles.uploadViaUrl, {
              [styles.textFieldIndent]: isConversationMessageField,
            })}
          />
        )}
        {!isUploadViaUrlOpen && <SizeLimitsTooltip isCentered={true} />}
      </div>
      <div className={styles.buttonsGroup}>
        <Tooltip label={isGridView ? 'Show as list' : 'Show as grid'} placement={'top'}>
          <IconButton
            icon={isGridView ? 'listLayout' : 'dotsGrid'}
            color='secondary'
            ariaLabel='viewType'
            disabled={isAttachmentInitListLoading}
            onClick={toggleViewType}
          />
        </Tooltip>
        {isSearchOpen ? (
          <TextField
            size='extraSmall'
            variant='outline'
            value={inputSearchTerm}
            onChange={setInputSearchTerm}
            InputProps={{ placeholder: 'Search files' }}
            leftActions={[
              makeTextFieldIcon({
                icon: 'search',
                color: 'var(--content-primary-tertiary)',
                fontSize: 16,
                margin: '0 4px',
              }),
            ]}
            rightActions={[
              () => (
                <Button
                  typeBtn='text'
                  size='small'
                  text='Cancel'
                  onClick={() => {
                    setSearchTerm('')
                    setInputSearchTerm('')
                    setIsSearchOpen(false)
                  }}
                />
              ),
            ]}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
            className={classNames(styles.textInput, styles.search)}
          />
        ) : (
          <Tooltip label={'Search'} placement={'top'}>
            <IconButton
              icon='search'
              color='secondary'
              ariaLabel='search'
              disabled={isAttachmentInitListLoading || isAnyFileWaitingForUpload}
              onClick={() => {
                setIsSearchOpen(true)
                setIsUploadViaUrlOpen(false)
                setUploadUrl('')
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  )
})
