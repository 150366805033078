import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { adminEditAgencyFranchiseContractContentStore } from 'pages/admin/pages/organizations/modals/AdminEditAgencyFranciseContract/store/adminEditAgencyFranchiseContractContentStore'

export const AdminEditAgencyFranchiseContractContentAction = observer(() => (
  <Button
    typeBtn={'contained'}
    text={'Save'}
    onClick={adminEditAgencyFranchiseContractContentStore.onSave}
    disabled={adminEditAgencyFranchiseContractContentStore.disabled}
    loading={adminEditAgencyFranchiseContractContentStore.loading}
  />
))
