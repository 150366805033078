import classNames from 'classnames'
import { Icon, ITooltipProps, Tooltip, Typography } from 'shared/ui'
import {
  AdminValueContent,
  IAdminValueContentProps,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/AdminValueContent'
import styles from './styles.module.scss'

export type IAdminDetailCardRowProps = {
  isEdit?: boolean
  title?: string
  labelTooltip?: ITooltipProps
  isHidden?: boolean
} & IAdminValueContentProps

export const AdminDetailCardRow = ({
  labelTooltip,
  isHidden,
  ...props
}: IAdminDetailCardRowProps) => {
  if (isHidden) return null

  return (
    <div className={classNames(styles.row)}>
      <div className={styles.title}>
        <Typography variant={'body-md-medium'} ariaLabel={'title'}>
          {props.title}
        </Typography>
        {labelTooltip && (
          <Tooltip placement={'top'} {...labelTooltip}>
            <Icon icon={'infoCircle'} tertiary />
          </Tooltip>
        )}
      </div>
      <AdminValueContent {...props} />
    </div>
  )
}
