import { makeAutoObservable, runInAction } from 'mobx'
import { IResponseTransformOrganizationSettings } from 'entities/Settings/api/types'
import { SettingsApi } from 'entities/Settings'

export class OrganizationSettingsStore {
  constructor() {
    makeAutoObservable(this)
  }

  settings: IResponseTransformOrganizationSettings | null = null
  settingsLoading = false

  fetchOrganizationSettings = async () => {
    try {
      runInAction(() => {
        this.settingsLoading = true
      })

      const { data } = await SettingsApi.getOrganizationSettings()

      runInAction(() => {
        this.settings = data.reduce((acc, item) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          acc[item.key] = item
          return acc
        }, {} as IResponseTransformOrganizationSettings)
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.settingsLoading = false
      })
    }
  }

  get settings10DLC() {
    if (!this.settings) return

    return this.settings['10dlc_banners']
  }

  get hasTenDLCLimits() {
    return !!this.settings?.['compliance-functionality'].settings.active
  }
}

export const organizationSettingsStore = new OrganizationSettingsStore()
