import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { IReviewBroadcastProps } from './type'

export const ReviewBroadcastModalAction = observer(({ store }: IReviewBroadcastProps) => {
  const { handleCreateBroadcast, disabledCreate, loadingCreate, isImmediately } = store

  return (
    <Button
      text={isImmediately ? 'Send' : 'Schedule'}
      disabled={disabledCreate}
      loading={loadingCreate}
      onClick={handleCreateBroadcast}
    />
  )
})
