import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material'
import classNames from 'classnames'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export enum EnumSpinnerLoaderPlacement {
  BottomEnd = 'bottom-end',
  BottomStart = 'bottom-start',
  Bottom = 'bottom',
  LeftEnd = 'left-end',
  LeftStart = 'left-start',
  Left = 'left',
  RightEnd = 'right-end',
  RightStart = 'right-start',
  Right = 'right',
  TopEnd = 'top-end',
  TopStart = 'top-start',
  Top = 'top',
  Center = 'center',
}

export enum EnumSpinnerLoaderPosition {
  Absolute = 'absolute',
  Fixed = 'fixed',
}

export type ISpinnerLoaderProps = CircularProgressProps & {
  determinatecolor?: string
  indeterminatecolor?: string
  placement?: EnumSpinnerLoaderPlacement
  position?: EnumSpinnerLoaderPosition
}

export function SpinnerLoader(props: ISpinnerLoaderProps) {
  const { placement = EnumSpinnerLoaderPlacement.Center, position, className } = props
  const baseContent = (
    <Box
      className={className}
      sx={{
        position: 'relative',
        display: 'flex',
      }}
      aria-label={getAriaLabel('SpinnerLoader')}
    >
      <CircularProgress
        variant='determinate'
        sx={{
          color: () => props.determinatecolor || 'var(--action-outlined-tertiary-hovered)',
        }}
        size={props.size || 32}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: props.indeterminatecolor || 'var(--background-brand-default)',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={props.size || 32}
        thickness={4}
        {...props}
      />
    </Box>
  )

  return (
    <div
      className={classNames(
        styles.container,
        placement && styles[placement],
        position && styles[position]
      )}
    >
      {baseContent}
    </div>
  )
}
