// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M6AX20VTDVowzg8FfTAr{position:relative;display:flex;flex-grow:1;margin-left:16px}.x1tzvEh1GQOhkc7mUlUk{display:flex;grid-template-columns:max-content max-content;gap:8px}", "",{"version":3,"sources":["webpack://./src/widgets/BroacastList/ui/BroadcastTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,6CAAA,CACA,OAAA","sourcesContent":[".wrap{\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  margin-left: 16px;\n}\n\n.nameCellWrapper{\n  display: flex;\n  grid-template-columns: max-content max-content;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "M6AX20VTDVowzg8FfTAr",
	"nameCellWrapper": "x1tzvEh1GQOhkc7mUlUk"
};
export default ___CSS_LOADER_EXPORT___;
