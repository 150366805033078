import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { debounce } from 'lodash'
import { AxiosError } from 'axios'
import { nanoid } from 'nanoid'
import { isLink, checkIsEmail } from 'shared/lib'
import { toastStore } from 'shared/ui'
import { UsersApi } from 'entities/Users'
import { IParamsUpdateMessageSignature } from 'entities/Users/api/types'
import { MessageFieldStore } from 'widgets/MessageField'

export enum IMessageSignatureErrorType {
  Length = 'length',
  Link = 'link',
}

type IMessageSignatureConfig = {
  parent: MessageFieldStore
}

export class MessageSignatureStore {
  constructor({ parent }: IMessageSignatureConfig) {
    makeAutoObservable(this)
    this.debounceMessageSignature = debounce(this.updateMessageSignatureAction, 1000)
    this.parent = parent

    reaction(
      () => this.messageSignatureText,
      (text) => {
        this.parent.setAdditionalText(text)
      }
    )
    reaction(
      () => this.disablesSend,
      (disabled) => {
        this.parent.setMessageSignatureSendDisabled(disabled)
      }
    )
  }

  isLoadingMessageSignature = false
  isActiveMessageSignature = false
  isFocusMessageSignature = false
  isInitCompleted = false
  messageSignatureError: IMessageSignatureErrorType | null = null
  messageSignature = ''
  messageSignatureMaxLength = 98
  debounceMessageSignature
  parent: MessageFieldStore

  focusTrigger = ''

  initMessageSignature = async () => {
    if (this.isInitCompleted) return

    try {
      runInAction(() => {
        this.isLoadingMessageSignature = true
        this.isInitCompleted = true
      })

      const { data } = await UsersApi.getMessageSignature()

      runInAction(() => {
        if (Object.keys(data).length) {
          this.isActiveMessageSignature = data.is_active
          this.messageSignature = data.signature?.trim()
        }
      })
    } catch (e) {
      if (e instanceof AxiosError) {
        runInAction(() => {
          this.isInitCompleted = false
        })

        toastStore.add({
          type: 'error',
          title: e.response?.data.message,
        })
        console.error(e)
      }
    } finally {
      runInAction(() => {
        this.isLoadingMessageSignature = false
      })
    }
  }

  updateMessageSignatureAction = async () => {
    if (this.messageSignatureLengthError) return

    const params = {
      is_active: this.isActiveMessageSignature,
      signature: this.messageSignature,
    }
    try {
      await UsersApi.updateMessageSignature(params)
    } catch (e) {
      if (e instanceof AxiosError) {
        toastStore.add({
          type: 'error',
          title: e.response?.data.message,
        })
        console.error(e)
      }
    }
  }

  setFocus = (value: boolean) => {
    this.isFocusMessageSignature = value
  }

  setMessageSignatureError = (value: IMessageSignatureErrorType | null) => {
    this.messageSignatureError = value
  }

  disabledSignatureChange = false

  handleUpdateMessageSignatureProperties = (params: IParamsUpdateMessageSignature) => {
    if (this.disabledSignatureChange) {
      return
    }
    const { is_active, signature, is_on_blur } = params
    if (typeof is_active === 'boolean') {
      if (is_active) {
        this.focusTrigger = nanoid()
      }
      this.isActiveMessageSignature = is_active
    }
    if (typeof signature === 'string') this.messageSignature = signature
    this.handleChangeMessageSignature()
    if (is_on_blur) {
      this.focusTrigger = ''
      this.disabledSignatureChange = true
      setTimeout(() => {
        this.disabledSignatureChange = false
      }, 200)
    }
  }

  handleChangeMessageSignature = async () => {
    await this.debounceMessageSignature()
  }

  get messageSignatureLength() {
    return this.messageSignature.length ? this.messageSignatureWithExtraSpace.length : 0
  }

  get messageSignatureLengthError() {
    return this.messageSignatureMaxLength - this.messageSignatureLength <= 0
  }

  get messageSignatureLinkError() {
    return Boolean(this.links.length) && this.isEmailOnly
  }

  get messageSignatureWithExtraSpace() {
    return '\n\n' + this.messageSignature
  }

  get disablesSend() {
    if (!this.isActiveMessageSignature) {
      return false
    }
    return this.messageSignatureLengthError || this.messageSignatureLinkError
  }

  get messageSignatureText() {
    return this.isActiveMessageSignature && !this.parent.isModeNote
      ? this.messageSignatureWithExtraSpace
      : ''
  }

  get messageSignatureErrorMessage() {
    if (this.messageSignatureError === IMessageSignatureErrorType.Length) {
      return 'Your signature must be under 100 characters'
    }

    if (this.messageSignatureError === IMessageSignatureErrorType.Link) {
      return 'Your signature can’t contain links'
    }

    return ''
  }

  get links() {
    const links: string[] = []
    this.messageSignature.split(/\n/).forEach((text) =>
      text.split(' ').forEach((word) => {
        if (isLink(word)) {
          links.push(word)
        }
      })
    )
    return links
  }

  get isEmailOnly() {
    const emails: string[] = []
    this.links.forEach((link) => {
      if (checkIsEmail(link)) {
        emails.push(link)
      }
    })

    return emails.length === this.links.length
  }
}
