// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hpYP4Fi5jhhHdHzsKe86{display:flex;align-items:center;gap:4px;color:var(--content-primary-tertiary);padding-block:12px 16px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageFormFields/RinglessMessageField/ui/Placeholder/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,qCAAA,CACA,uBAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  color: var(--content-primary-tertiary);\n  padding-block: 12px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "hpYP4Fi5jhhHdHzsKe86"
};
export default ___CSS_LOADER_EXPORT___;
