import { createContext, useContext } from 'react'
import { SendLaterStore } from 'shared/ui/Schedule'

export const SendLaterContext = createContext<SendLaterStore | null>(null)

export function useSendLaterContext() {
  const context = useContext(SendLaterContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with SendLaterContext')
  }
  return context
}
