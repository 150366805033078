// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HCrzwaNqyO5CLkMaYVPM{display:flex;height:100%;align-items:center}.HCrzwaNqyO5CLkMaYVPM .ySc3eAIb_thTp69O9t5Q{margin:8px}.HCrzwaNqyO5CLkMaYVPM .LA9NqEDgV2zHZzRp9t1F{display:flex;align-items:center;margin:0 8px 0 16px;position:relative;width:28px}.HCrzwaNqyO5CLkMaYVPM .LA9NqEDgV2zHZzRp9t1F .ySc3eAIb_thTp69O9t5Q{position:absolute;display:block;border-radius:8px;margin-left:0px;border:2px var(--background-primary-inverted-primary) solid}", "",{"version":3,"sources":["webpack://./src/widgets/SearchDropdown/ui/SelectedElement/AssigneeElement/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,kBAAA,CAEA,4CACE,UAAA,CAEF,4CACE,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,iBAAA,CACA,UAAA,CAEA,kEACE,iBAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA,CACA,2DAAA","sourcesContent":[".container {\n  display: flex;\n  height: 100%;\n  align-items: center;\n\n  .avatar {\n    margin: 8px;\n  }\n  .avatars {\n    display: flex;\n    align-items: center;\n    margin: 0 8px 0 16px;\n    position: relative;\n    width: 28px;\n  \n    .avatar {\n      position: absolute;\n      display: block;\n      border-radius: 8px;\n      margin-left: 0px;\n      border: 2px var(--background-primary-inverted-primary) solid;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HCrzwaNqyO5CLkMaYVPM",
	"avatar": "ySc3eAIb_thTp69O9t5Q",
	"avatars": "LA9NqEDgV2zHZzRp9t1F"
};
export default ___CSS_LOADER_EXPORT___;
