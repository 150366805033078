import { observer } from 'mobx-react-lite'
import { adminOrganizationPlanStore } from 'pages/admin/pages/organizations/store/adminOrganizationPlanStore'
import { adminOrganizationCustomPlanStore } from 'pages/admin/pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'
import { PaymentPlanCollapse } from 'pages/admin/ui/AdminManagePaymentPlan/PaymentPlanCollapse'
import {} from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'
import { RolloverForm } from 'pages/admin/ui/RolloverForm/RolloverForm'
import { SelectPlanForm } from 'pages/admin/ui/SelectPlanForm/SelectPlanForm'

export const ManagePlan = observer(() => {
  const { selectedPlanData } = adminOrganizationPlanStore
  const { selectedPlan } = adminOrganizationCustomPlanStore

  const planData = selectedPlanData(selectedPlan)

  return (
    <PaymentPlanCollapse title='Custom Plan'>
      <SelectPlanForm store={adminOrganizationCustomPlanStore} withSetContract />

      {planData && (
        <RolloverForm
          disabled
          isRollover={Boolean(planData.type && planData.percent)}
          rolloverPercent={planData.percent || null}
          rolloverPeriod={planData.type || null}
        />
      )}
    </PaymentPlanCollapse>
  )
})
