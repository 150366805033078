import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import {
  Avatar,
  DropdownCard,
  EnumDropdownItemVariant,
  IDropdownItem,
  InputCheckbox,
  InputCheckboxProps,
  statusSizeEnum,
  Typography,
} from 'shared/ui'
import { type User } from 'entities/Users/model/User'
import styles from './styles.module.scss'

type IUserDropdownCardProps = {
  item: User
  withoutOnlineStatus?: boolean
  InputCheckboxProps?: InputCheckboxProps
  onChange?: (item: User) => void
  highlighter?: string
  ariaLabel?: string
}

type IUserDropdownCardContentProps = {
  item: User
  highlighter?: string
  isCheckbox?: boolean
  withoutOnlineStatus?: boolean
}

export const UserCardContent: FC<IUserDropdownCardContentProps> = ({
  item,
  highlighter,
  isCheckbox,
  withoutOnlineStatus,
}) => {
  return (
    <div
      className={classnames(styles.wrap, {
        [styles['wrap--checkbox']]: isCheckbox,
      })}
    >
      <div className={styles.icon}>
        <Avatar
          size={16}
          info={item?.avatarInfo}
          online={withoutOnlineStatus ? undefined : !item.isAway}
          statusSize={statusSizeEnum.xs}
        />
      </div>
      <Typography variant={'body-md-regular'} ariaLabel={'name'} className={styles.title}>
        {highlighter ? (
          <Highlighter
            highlightClassName={classnames(styles.highlighter, styles['highlighter--label'])}
            className={styles.name}
            searchWords={highlighter.split(' ')}
            autoEscape={true}
            textToHighlight={item.name || ''}
          />
        ) : (
          item.name
        )}
      </Typography>
    </div>
  )
}

export const UserDropdownCard: FC<IUserDropdownCardProps> = observer(
  ({ item, withoutOnlineStatus, onChange, InputCheckboxProps, highlighter, ariaLabel }) => {
    const renderContent = (isCheckbox: boolean) => {
      return (
        <UserCardContent
          item={item}
          highlighter={highlighter}
          isCheckbox={isCheckbox}
          withoutOnlineStatus={withoutOnlineStatus}
        />
      )
    }
    const dropdown: IDropdownItem = {
      id: item.id,
      label: item.name,
      variant: EnumDropdownItemVariant.Custom,
      renderOption: () => {
        return renderContent(false)
      },
    }

    if (InputCheckboxProps) {
      return (
        <InputCheckbox
          className={styles.checkbox}
          {...InputCheckboxProps}
          customLabel={renderContent(true)}
        />
      )
    }

    return (
      <DropdownCard
        item={dropdown}
        ariaLabel={ariaLabel}
        onChange={() => {
          if (onChange) onChange(item)
        }}
      />
    )
  }
)
