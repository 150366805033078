import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { IRow, Table, Typography } from 'shared/ui'
import { TeamMember } from 'entities/Admin'
import { adminOrganizationDetailStore } from 'pages/admin/pages/organizations/pages/OrganizationDetails/store/adminOrganizationDetailStore'
import { getAdminOrganizationTeamMemberActions } from 'pages/admin/pages/organizations/pages/OrganizationDetails/lib/getAdminOrganizationTeamMemberActions'
import { columns } from './columns'
import styles from './styles.module.scss'

export const TeamMembersTable = observer(() => {
  const { teamMembers } = adminOrganizationDetailStore
  const onPaginationModelChange = () => {}

  const rows = useMemo(
    (): IRow<TeamMember>[] =>
      teamMembers.map((member) => {
        member.setActionsProps &&
          member.setActionsProps({
            actions: getAdminOrganizationTeamMemberActions({ item: member }),
          })

        return member
      }),
    [teamMembers]
  )

  return (
    <div className={styles.wrap}>
      <Typography variant={'heading-sm'} marginBottom={20} tag={'div'} ariaLabel={'Team members'}>
        Team members
      </Typography>
      <div
        className={styles.wrapTable}
        style={{ height: (teamMembers.length > 10 ? 11 * 38 : teamMembers.length * 38) + 41 }}
      >
        <Table
          columns={columns}
          rows={rows}
          rowsCount={teamMembers.length}
          isLoading={false}
          onChangePagination={onPaginationModelChange}
          noPagination
        />
      </div>
    </div>
  )
})
