import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { TableStore } from 'shared/ui/Table'
import type {
  IParamsGetTriggers,
  IResponseGetTriggers,
  IResponseTrigger,
  ITriggerCategoryType,
} from 'entities/Trigger/api/type'
import { ITrigger } from 'entities/Trigger/model/Trigger'
import { TriggerApi } from 'entities/Trigger/api/trigger'

type ISetIsTriggerActionShown = (isShown: boolean) => void

export type ITriggerListStoreProps = {
  type: ITriggerCategoryType
  setIsTriggerActionShown: ISetIsTriggerActionShown
}

export class TriggerListStore {
  tableStore: TableStore<ITrigger>
  type: ITriggerCategoryType

  private _disposeLoadTriggers: IReactionDisposer | null = null
  private _disposeTriggerActionShown: IReactionDisposer | null = null

  constructor({ type, setIsTriggerActionShown }: ITriggerListStoreProps) {
    makeAutoObservable(this)
    this.type = type
    this.tableStore = new TableStore<ITrigger>({
      withoutDefaultManageColumns: true,
      sortBy: 'created_at',
      sortOrder: 'desc',
    })

    this.reactionLoadTriggers()
    this.reactionTriggerActionShown(setIsTriggerActionShown)
  }

  page = 1
  limit: number | null = 10
  total = 0
  isLoading = true
  isFirstLoading = true

  triggersMap: Map<number, ITrigger> = new Map()

  loadTriggers = async () => {
    try {
      runInAction(() => {
        this.isLoading = true
      })
      const { data } = await TriggerApi.getTriggers(this.paramsGetTriggers)
      this.setGetTriggersResponse(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.isLoading = false
        if (this.isFirstLoading) this.isFirstLoading = false
      })
    }
  }

  setGetTriggersResponse = ({ data, meta }: IResponseGetTriggers) => {
    this.triggersMap.clear()
    this.setTriggerMap(data)
    this.total = meta.total
  }

  setTriggerMap = (triggersResponse: IResponseTrigger[]) => {
    triggersResponse.forEach((triggerResponse) => {
      try {
        this.triggersMap.set(triggerResponse.id, new ITrigger(triggerResponse))
      } catch (e) {
        console.error(e)
      }
    })
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }

  reactionLoadTriggers = () => {
    this._disposeLoadTriggers?.()
    this._disposeLoadTriggers = reaction(
      () => this.paramsGetTriggers,
      () => this.loadTriggers()
    )
  }

  reactionTriggerActionShown = (setIsTriggerActionShown: ISetIsTriggerActionShown) => {
    this._disposeTriggerActionShown?.()
    this._disposeTriggerActionShown = reaction(
      () => this.isLoading,
      () => setIsTriggerActionShown(!this.isTriggersEmpty)
    )
  }

  clearReactions = () => {
    this._disposeLoadTriggers?.()
    this._disposeTriggerActionShown?.()
  }

  get paramsGetTriggers(): IParamsGetTriggers {
    return {
      type: this.type,
      length: this.limit || undefined,
      sortBy: this.tableStore.sortBy,
      sortOrder: this.tableStore.sortOrder,
      page: this.page,
    }
  }

  get triggers() {
    return Array.from(this.triggersMap.values())
  }

  get isTriggersEmpty() {
    return !this.triggers.length && !this.isLoading
  }
}
