// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sU0ODmLm91BMpBt3shL1{display:flex;justify-content:center;align-items:center;width:100%;height:100%;gap:16px;flex-direction:column}.sU0ODmLm91BMpBt3shL1 .AI3F4eAHHVGmQ61uDE_i{position:relative;top:-20px;display:flex;align-items:center;flex-direction:column;gap:16px}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/SavedRepliesContent/RightContent/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,qBAAA,CAEA,4CACE,iBAAA,CACA,SAAA,CACA,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":["\n.empty{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  gap: 16px;\n  flex-direction: column;\n\n  .emptyTitle {\n    position: relative;\n    top: -20px;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "sU0ODmLm91BMpBt3shL1",
	"emptyTitle": "AI3F4eAHHVGmQ61uDE_i"
};
export default ___CSS_LOADER_EXPORT___;
