import { cloneDeep } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'

import { logger, SingletonRequest } from 'shared/lib'
import { RequestCancelAxios } from 'shared/api/requestCancelAxios'
import type {
  IUiSettingsIntegrationKey,
  IUiSettingsIntegrationType,
  IUiSettingsSourceType,
} from 'entities/Users/api/types'
import { UsersApi } from 'entities/Users'

export class DraggableIntegrationStore {
  private _loading = false
  private _sourceType: IUiSettingsSourceType
  private _itemsMap: Map<string, string> = new Map()
  private _cancelRequest = new RequestCancelAxios()

  constructor(
    private _integrationKey: IUiSettingsIntegrationKey,
    private _integrationType: IUiSettingsIntegrationType,
    private _defaultItems: string[]
  ) {
    makeAutoObservable(this)

    this._sourceType = `${this._integrationKey}-${this._integrationType}-draggable-items`

    this.fetchItems()
  }

  get itemsPayload() {
    return Array.from(this._itemsMap.values())
  }

  get itemsUI() {
    return this.itemsPayload.filter((item) => !this._defaultItems.includes(item))
  }

  dispose = () => {
    this._cancelRequest.reset()
  }

  private _addItem = (key: string) => {
    this._itemsMap.set(key, key)

    return Array.from(this._itemsMap.values())
  }

  private _removeItem = (key: string) => {
    this._itemsMap.delete(key)
  }

  updateItems = async (source: number, target?: number) => {
    if (typeof target !== 'number') return

    try {
      runInAction(() => {
        this._loading = true
      })

      const items = cloneDeep(this.itemsUI)
      const [item] = items.splice(source, 1)

      items.splice(target, 0, item)

      runInAction(() => {
        this._itemsMap.clear()

        items.forEach((item) => {
          this._addItem(item)
        })
      })

      await UsersApi.updateUsersUiSettings({
        source_type: this._sourceType,
        items: [...this._defaultItems, ...items],
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  private _fetchItems = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      this._cancelRequest.init()

      const response = await UsersApi.getUsersUiSettings(this._sourceType, {
        cancelToken: this._cancelRequest.token,
      })

      this._defaultItems.forEach((item) => {
        this._addItem(item)
      })

      response.data.data?.items.forEach((item) => {
        this._addItem(item)
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  fetchItems = new SingletonRequest(this._fetchItems, { cache: true }).request

  addItem = async (key: string) => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const items = this._addItem(key)

      await UsersApi.updateUsersUiSettings({
        source_type: this._sourceType,
        items: items,
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  removeItem = async (key: string) => {
    try {
      runInAction(() => {
        this._loading = true
      })

      this._removeItem(key)

      await UsersApi.updateUsersUiSettings({
        source_type: this._sourceType,
        items: this.itemsPayload,
      })
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }
}
