import { observer } from 'mobx-react-lite'
import { FullPageWrapper } from 'shared/ui'
import {
  BroadcastContactStatistics,
  BroadcastDetailHeader,
  useBroadcastDetailRecurringContext,
} from 'widgets/BroadcastDetail'
import { RecurringTable } from 'widgets/BroadcastDetail/BroadcastDetailRecurring/ui/RecurringTable/RecurringTable'

export const Content = observer(() => {
  const store = useBroadcastDetailRecurringContext()
  return (
    <FullPageWrapper>
      <BroadcastDetailHeader store={store} />
      <BroadcastContactStatistics filters={store.filters} />
      <RecurringTable />
    </FullPageWrapper>
  )
})
