import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Tree } from '@minoru/react-dnd-treeview'
import { MenuTitle, EmptyState } from 'shared/ui'
import { IDropOptions, IInboxCombine, INodeModel, inboxesStore } from 'entities/Inbox'
import styles from './styles.module.scss'
import { MenuCardTree } from '../MenuCardTree/MenuCardTree'
import { MenuPlaceholderTree } from '../MenuPlaceholderTree/MenuPlaceholderTree'
import { MenuCardPreviewTree } from '../MenuCardPreviewTree/MenuCardPreviewTree'

type IMenuSectionTreeProps = {
  title: string
  items: INodeModel<IInboxCombine>[]
  count?: number | undefined
  root: number | string
  handleDrop: (
    items: INodeModel<IInboxCombine>[],
    options: IDropOptions<IInboxCombine>,
    root: string | number
  ) => void
  useSearch?: {
    handleSearch: (search: string) => void
    search: string
    placeholder?: string
    autofocus?: boolean
    variant?: 'simple' | 'transparent'
  }
}

const MenuSectionTree: React.FC<IMenuSectionTreeProps> = observer((props) => {
  const { title = 'Test', items = [], count, root, handleDrop, useSearch } = props
  const [open, setOpen] = useState(true)
  const {
    selectCurrentInboxId,
    isChildOfGroupInboxWithActiveChild,
    isChildOfActiveGroupInbox,
    hasActiveChildInbox,
  } = inboxesStore
  const isEmpty = !items.length
  const showList = !isEmpty && (open || useSearch?.search)

  return (
    <div className={styles.wrap}>
      <MenuTitle
        useSearch={useSearch}
        title={title}
        count={count}
        open={open}
        handleToggle={() => setOpen(!open)}
      />

      {isEmpty && (open || useSearch?.search) && (
        <div className={styles.empty}>
          <EmptyState
            noIcon
            alignItems={'flex-start'}
            typographyProps={{
              variant: 'body-md-regular',
              color: 'var(--content-primary-disabled)',
              ariaLabel: 'search',
            }}
          />
        </div>
      )}

      <Tree
        tree={items}
        rootId={root}
        render={(node, { isOpen, onToggle, hasChild }) => {
          const inboxId = node.data?.id
          const activeInbox =
            inboxId === selectCurrentInboxId ||
            hasActiveChildInbox(inboxId) ||
            isChildOfActiveGroupInbox(inboxId) ||
            isChildOfGroupInboxWithActiveChild(inboxId)

          if (!showList && activeInbox) {
            return (
              <MenuCardTree
                rootId={root}
                node={node}
                onToggle={onToggle}
                isOpen={isOpen}
                hasChild={hasChild}
              />
            )
          }

          if (!showList) {
            return <></>
          }

          return (
            <MenuCardTree
              rootId={root}
              node={node}
              onToggle={onToggle}
              isOpen={isOpen}
              hasChild={hasChild}
            />
          )
        }}
        dragPreviewRender={(monitorProps) => {
          return <MenuCardPreviewTree node={monitorProps.item} />
        }}
        onDrop={(tree, options) => handleDrop(tree, options, root)}
        classes={{
          root: styles.treeRoot,
          draggingSource: styles.draggingSource,
          placeholder: styles.placeholderContainer,
          dropTarget: styles.dropTarget,
        }}
        sort={false}
        insertDroppableFirst={false}
        canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
          if (dropTarget?.data?.isGroupInbox && dragSource?.data?.isGroupInbox) return false
          if (dragSource?.data?.isUnifiedInbox && dropTarget?.data?.isGroupInbox) return false
          if (dragSource?.parent === dropTargetId) {
            return true
          }
        }}
        dropTargetOffset={10}
        placeholderRender={(node, { depth }) => <MenuPlaceholderTree node={node} depth={depth} />}
      />
    </div>
  )
})

export { MenuSectionTree }
