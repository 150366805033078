import { makeAutoObservable } from 'mobx'
import { MessagesApi, IMessage, IParamsCreateMessage } from 'entities/Message'
import { ConversationsApi } from 'entities/Conversation'
import { Message } from 'entities/Message/model/Message'

class MessageStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = false
  items: IMessage[] = []

  createMessageByConversationId = async (conversationId: number, body: IParamsCreateMessage) => {
    try {
      const { data } = await MessagesApi.createMessage(conversationId, body)
      ConversationsApi.updateByIdRead(conversationId)
      return new Message(data)
    } catch (e) {
      return null
    }
  }
}

export const messageStore = new MessageStore()
