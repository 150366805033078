import { observer } from 'mobx-react-lite'
import { Icon } from 'shared/ui'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'

export const ConversationPriorityMark = observer(() => {
  const { priority } = useConversationMessagesContext()

  if (priority)
    return <Icon icon={'flagFilled'} fontSize={20} color={'var(--content-attention-primary)'} />

  return null
})
