// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TDyNOEnZEa7mswZCOEG8{background-color:var(--background-primary-inverted-primary)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/AdvancedScheduling/ui/styels.module.scss"],"names":[],"mappings":"AAAA,sBACE,2DAAA","sourcesContent":[".label {\n  background-color: var(--background-primary-inverted-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "TDyNOEnZEa7mswZCOEG8"
};
export default ___CSS_LOADER_EXPORT___;
