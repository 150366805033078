// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._RNirqx8yUahW2usgSPS{width:100%;height:100%;padding:0 16px;position:relative}._RNirqx8yUahW2usgSPS .b7UCRfIC7fJMCjfuM2s1{width:100%;height:100%;position:relative}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CAEA,4CACI,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".tableContainer {\n    width: 100%;\n    height: 100%;\n    padding: 0 16px;\n    position: relative;\n\n    .table {\n        width: 100%;\n        height: 100%;\n        position: relative;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "_RNirqx8yUahW2usgSPS",
	"table": "b7UCRfIC7fJMCjfuM2s1"
};
export default ___CSS_LOADER_EXPORT___;
