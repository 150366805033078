import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventMessage } from 'entities/Message'
import { type ConversationsStore } from 'entities/Conversation'
import { type ContactsStore } from 'entities/Contacts'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export const EventMessageReceivedNew = {
  subscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.on(WebsocketEvent.MessageReceivedNew, handler),
  unsubscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.off(WebsocketEvent.MessageReceivedNew, handler),
}

export const useEventMessageReceivedNew = (
  conversationStore: ConversationsStore,
  conversationMessageStore: ConversationMessagesStore,
  contactsStore: ContactsStore
) =>
  useEffect(
    () =>
      EventMessageReceivedNew.subscribe((data) => {
        conversationStore.updateItem(data.conversation)
        if (data.message.conversation_id === conversationMessageStore.conversationId) {
          conversationMessageStore.setItems([data.message])
        }
        if (data.contact) {
          contactsStore.updateItem(data.contact)
        }
      }),
    []
  )
