// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CsD6MKWyDzB36nhj5mlr{padding:7px 0 0 0}.UK9Fdt1ESPpBVkuDvST3{position:relative}.UK9Fdt1ESPpBVkuDvST3:before{position:absolute;top:0;left:0;right:0;bottom:0;background-color:var(--white-50);z-index:var(--zIndex-BASE_10);content:\"\"}.tzImlymumBs1atw7wYFM{margin-bottom:16px}.kMb3jfD4MCT2b4CpMUXB{margin-bottom:25px}.Fv61BMLsJGsqfExnVS3R{margin-bottom:15px}.kMb3jfD4MCT2b4CpMUXB:last-of-type{margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/widgets/BuyCreditsModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,sBACE,iBAAA,CAEA,6BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,gCAAA,CACA,6BAAA,CACA,UAAA,CAIJ,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAEA,sBACE,kBAAA,CAGF,mCACE,eAAA","sourcesContent":[".wrap {\n  padding: 7px 0 0 0;\n}\n\n.loading {\n  position: relative;\n\n  &:before {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: var(--white-50);\n    z-index: var(--zIndex-BASE_10);\n    content: '';\n  }\n}\n\n.alert {\n  margin-bottom: 16px;\n}\n\n.section {\n  margin-bottom: 25px;\n\n  &--mrb15 {\n    margin-bottom: 15px;\n  }\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "CsD6MKWyDzB36nhj5mlr",
	"loading": "UK9Fdt1ESPpBVkuDvST3",
	"alert": "tzImlymumBs1atw7wYFM",
	"section": "kMb3jfD4MCT2b4CpMUXB",
	"section--mrb15": "Fv61BMLsJGsqfExnVS3R"
};
export default ___CSS_LOADER_EXPORT___;
