import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'

import { ChatbotAnalyticDetailStore } from './chatbotAnalyticDetailStore'
import { ChatbotAnalyticListStore } from './chatbotAnalyticListStore'

export class ChatbotAnalyticStore {
  loading = true

  list!: ChatbotAnalyticListStore
  details!: ChatbotAnalyticDetailStore

  private _disposeLoading: IReactionDisposer | null = null

  constructor(public id: number) {
    this.list = new ChatbotAnalyticListStore(id)
    this.details = new ChatbotAnalyticDetailStore(id)

    makeAutoObservable(this)

    this._disposeLoading = reaction(
      () => [this.list.initialLoading, this.details.initialLoading],
      ([listLoading, detailsLoading]) => {
        this.loading = listLoading || detailsLoading
      }
    )
  }

  dispose = () => {
    this._disposeLoading?.()

    this.list.dispose()
    this.details.dispose()
  }
}
