import { numberFormat } from 'shared/lib'
import { Statistic } from 'entities/ContactStatistic/model/Statistic'
import type { IResponseTrigger } from 'entities/Trigger/api/type'

export class TriggerStatistic extends Statistic {
  constructor({
    all_statistics: statistic,
    short_urls,
    analytics,
    is_track_conversion,
  }: IResponseTrigger) {
    const unsubscribed_count = statistic.opt_out_count || 0
    const total_count = statistic.total_count || null
    let undelivered_count: number | null = null
    let success_count: number | null = null
    let reply_count: number | null = null

    if (total_count !== null) {
      success_count = statistic.success_count
      reply_count = statistic.reply_count
      undelivered_count = total_count - success_count
    }

    const clicks_count = short_urls?.length
      ? short_urls.reduce((acc, cur) => acc + cur.clicks, 0)
      : null

    let revenue_cost: string | null = null
    if (analytics?.conversion_value) {
      revenue_cost = numberFormat({
        value: analytics?.conversion_value,
        minimumFractionDigits: 0,
        currency: 'USD',
      })
    } else {
      revenue_cost = is_track_conversion
        ? numberFormat({
            value: 0,
            minimumFractionDigits: 0,
            currency: 'USD',
          })
        : null
    }
    const conversions_count = analytics?.users_with_conversion || (is_track_conversion ? 0 : null)

    super({
      clicks_count,
      reply_count,
      success_count,
      total_count,
      undelivered_count,
      conversions_count,
      revenue_cost,
      unsubscribed_count,
    })
  }

  get filters() {
    return this.getFilters('Trigger contacts')
  }
}
