import { observer } from 'mobx-react-lite'
import { IconButton, IIconProps } from 'shared/ui'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

type NoteSendProps = {
  variantType?: 'default' | 'noteVariant'
}

export const NoteSend = observer(({ variantType = 'default' }: NoteSendProps) => {
  const messageFieldStore = useMessageFieldContext()
  const { sendActionBtnProps, editId, loadingSend, disablesSend } = messageFieldStore

  if (!sendActionBtnProps) {
    return null
  }

  const iconProps: IIconProps =
    variantType === 'default'
      ? { icon: 'send', fontSize: 16 }
      : { icon: editId ? 'check1' : 'arrowUpCircle', fontSize: 16 }

  const color = variantType === 'default' ? 'passivePrimary' : 'quaternary'
  const variant = variantType === 'default' || !disablesSend ? 'contained' : 'icon'

  return (
    <IconButton
      iconProps={iconProps}
      color={color}
      loading={loadingSend}
      variant={variant}
      disabled={disablesSend}
      {...sendActionBtnProps}
    />
  )
})
