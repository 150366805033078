import { ReactNode, useState } from 'react'
import { Collapse as CollapseMui } from '@mui/material'
import { Icon } from '../Icon/Icon'
import styles from './styles.module.scss'

interface ICollapseProps {
  isOpen?: boolean
  children: ReactNode
  title: string
}

export const Collapse = ({ children, isOpen = true, title }: ICollapseProps) => {
  const [open, setOpen] = useState(isOpen)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={handleClick}>
        <span className={styles.title}>{title}</span>
        <Icon icon={open ? 'chevronUp' : 'chevronDown'} color='var(--gray-60)' />
      </div>

      <CollapseMui in={open}>
        <div className={styles.collapseContent}>{children}</div>
      </CollapseMui>
    </div>
  )
}
