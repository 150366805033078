import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket/store'
import type { IResponseConversationDraftMessage } from 'entities/Conversation/api/types'
import { conversationStore } from 'entities/Conversation'

const ClientDraft = (data: IResponseConversationDraftMessage) => {
  conversationStore.addItemDraft(data)
}

export const useEventClientDraft = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.ClientDraft, ClientDraft)
    }
  }, [websocket.isConnected])
}
