// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PwMV2wCyKbxg3wGrE9yP{height:100%}.vFbdWe8q0zVIqznsmrLR{height:100%;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMenu/ui/ConversationMenuInboxes/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,WAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".wrap {\n}\n\n.empty {\n  height: 100%;\n}\n\n.loader {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "PwMV2wCyKbxg3wGrE9yP",
	"loader": "vFbdWe8q0zVIqznsmrLR"
};
export default ___CSS_LOADER_EXPORT___;
