import { makeAutoObservable, runInAction } from 'mobx'
import { numberFormat } from 'shared/lib'
import { organizationStore } from 'entities/Organization'
import { IResponseSubscriptionPlan, ISubscriptionStatuses } from 'entities/Subscription/api/types'
import { SubscriptionApi } from 'entities/Subscription'

class SubscriptionStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = false
  id: number | null = null
  plan: IResponseSubscriptionPlan | null = null
  status: ISubscriptionStatuses | null = null
  trialCredits = 0
  trialActiveContactsCount = 250

  get formattedTrialCredits() {
    return numberFormat({ value: this.trialCredits })
  }

  get isTrial() {
    return this.status === 'trialing'
  }

  fetchSubscription = async () => {
    try {
      this.loading = true

      const { data: subscription } = await SubscriptionApi.getSubscription()

      runInAction(() => {
        this.id = subscription.id
        this.plan = subscription.plan
        this.status = subscription.status
        this.trialCredits = subscription.trial_credits
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  get isAgencyWithSubscription() {
    if (!this.status) return false

    const availableStatuses: ISubscriptionStatuses[] = ['active', 'trialing']

    return organizationStore.isAgency && availableStatuses.includes(this.status)
  }

  get isAgencyWithoutSubscription() {
    if (!this.status) return true

    const availableStatuses: ISubscriptionStatuses[] = ['active', 'past_due', 'trialing']

    return organizationStore.isAgency && !availableStatuses.includes(this.status)
  }

  get isCanceled() {
    return this.status === 'canceled'
  }

  get isPlanActive() {
    return Boolean(this.plan?.active)
  }

  get isPAYGPlan() {
    return this.plan?.id === 'free-plan-monthly'
  }
}

export const subscriptionStore = new SubscriptionStore()
