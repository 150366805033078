// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q9wMe01WCiUd7izTczFA{color:var(--content-primary-secondary)}.wn_rYJfkA_pEUTuJDaOB{height:32px;align-items:center;background:var(--background-primary-inverted-primary);display:grid;grid-template-columns:24px 1fr;padding:4px;gap:4px;border-radius:4px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/BroadcastRingless/ui/UploadingAudio/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CAEF,sBACE,WAAA,CACA,kBAAA,CACA,qDAAA,CACA,YAAA,CACA,8BAAA,CACA,WAAA,CACA,OAAA,CACA,iBAAA","sourcesContent":[".title{\n  color: var(--content-primary-secondary);\n}\n.card{\n  height: 32px;\n  align-items: center;\n  background: var(--background-primary-inverted-primary);\n  display: grid;\n  grid-template-columns: 24px 1fr;\n  padding: 4px;\n  gap: 4px;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Q9wMe01WCiUd7izTczFA",
	"card": "wn_rYJfkA_pEUTuJDaOB"
};
export default ___CSS_LOADER_EXPORT___;
