import { observer } from 'mobx-react-lite'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { PresentationModeContent } from 'widgets/PresentationMode/ui/PresentationModeContent'

export const PresentationMode = observer(() => {
  const { currentAttachment, openPresentationMode } = presentationStore

  if (!openPresentationMode || !currentAttachment) {
    return null
  }

  return <PresentationModeContent />
})
