import { Grid, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Label } from 'shared/ui/Label'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { Alert, Checkbox, NumberCounter, Typography, Error } from 'shared/ui'
import {
  TextField,
  IInputComponent,
  makeTextFieldElement,
  IInputNumericProps,
} from 'shared/ui/TextField'
import { InputFormatNumber } from 'shared/ui/TextField/InputPresets/InputFormanNumber'
import { InputCurrencyFormat } from 'shared/ui/TextField/InputPresets/InputCurrency'
import { InputPositiveNumber } from 'shared/ui/TextField/InputPresets/InputPositiveNumber'
import { BillingInterval } from 'entities/Admin/customPrices/api/type'
import {
  MAX_TOTAL_VALUE,
  MAX_ADDITIONAL_CREDIT_PRICE,
  MIN_ADDITIONAL_CREDIT_PRICE,
} from 'pages/admin/pages/customPrices/ui/CustomPriceForm/constants'

import styles from './styles.module.scss'

export const contractTermsItems = [
  {
    id: 'annual',
    value: 'annual',
    label: '12 months (Annual)',
  },
  {
    id: 'monthly',
    value: 'monthly',
    label: '1 month (Month-to-month)',
  },
]

export const billingFrequencyItems = [
  {
    id: 'annual',
    value: 'annual',
    label: 'Annual',
  },
  {
    id: 'semi-annual',
    value: 'semi-annual',
    label: 'Semi-Annual',
  },
  {
    id: 'quarterly',
    value: 'quarterly',
    label: 'Quarterly',
  },
  {
    id: 'monthly',
    value: 'monthly',
    label: 'Monthly',
  },
]

export const expirationTimeItems = [
  {
    id: '14',
    value: '14',
    label: '14 days',
  },
  {
    id: '30',
    value: '30',
    label: '30 days',
  },
  {
    id: '45',
    value: '45',
    label: '45 days',
  },
  {
    id: '60',
    value: '60',
    label: '60 days',
  },
  {
    id: 'indefinite',
    value: 'indefinite',
    label: 'Indefinite',
  },
]

export const additionalCreditPriceItems = [
  0.04, 0.035, 0.0325, 0.0315, 0.031, 0.03, 0.029, 0.028, 0.027, 0.026, 0.025, 0.024, 0.023, 0.022,
  0.021, 0.02, 0.019, 0.018, 0.017, 0.016, 0.015, 0.014,
].map((item) => ({
  id: item,
  value: String(item),
  label: `$${item}`,
}))

export type IManagePlanFormActions = {
  setContract?: (value: boolean) => void
  setContractTerm?: (value: BillingInterval | null) => void
  setCredits?: (value: string | null) => void
  setBillingFrequency?: (value: BillingInterval | null) => void
  setAdditionalCreditsPrice?: (value: string | null) => void
  setTotalAmount?: (value: string | null) => void
  incrementSeats?: () => void
  decrementSeats?: () => void
  incrementNumbers?: () => void
  setTwoWeeksTrial?: () => void
  decrementNumbers?: () => void
  incrementPaidSeats?: () => void
  decrementPaidSeats?: () => void
  incrementPaidNumbers?: () => void
  decrementPaidNumbers?: () => void
}

export type IManagePlanFormStoreProps = IManagePlanFormActions & {
  isContract?: boolean
  contractTerm: BillingInterval | null
  contractTermName: string
  credits: number | null
  billingFrequency: BillingInterval | null
  isDisableBillingFrequency?: boolean
  additionalCreditsPrice: number | null
  isValidAdditionalCreditPrice: boolean
  totalAmount: number | null
  seats: number
  numbers: number
  paidSeats?: number
  paidNumbers?: number
  twoWeeksTrial?: boolean
}

interface ManagePlanFormFieldsProps {
  store: IManagePlanFormStoreProps
  edit?: boolean
  withSetContract?: boolean
  additional?: boolean
  disabled?: boolean
  isTrial?: boolean
  disablesPaidNumbers?: boolean
}

export const ManagePlanFormFields = observer(
  ({
    store,
    edit,
    withSetContract,
    additional,
    disabled = false,
    isTrial,
    disablesPaidNumbers,
  }: ManagePlanFormFieldsProps) => {
    const {
      setContractTerm,
      setBillingFrequency,
      setAdditionalCreditsPrice,
      setTotalAmount,
      incrementSeats,
      setTwoWeeksTrial,
      decrementSeats,
      incrementNumbers,
      decrementNumbers,
      incrementPaidSeats,
      decrementPaidSeats,
      incrementPaidNumbers,
      decrementPaidNumbers,
    } = store
    const isAllow = ({ floatValue }: { floatValue?: number }) => {
      return !floatValue || floatValue <= MAX_TOTAL_VALUE
    }

    const isValidAdditionalCreditPrice = ({ floatValue }: { floatValue?: number }) => {
      return (
        !floatValue ||
        (floatValue <= MAX_ADDITIONAL_CREDIT_PRICE && MIN_ADDITIONAL_CREDIT_PRICE <= floatValue)
      )
    }

    return (
      <>
        <Grid item xs={6}>
          <SelectField
            labelText={'Contract term'}
            value={store.contractTerm}
            setValue={(value) => setContractTerm?.(value)}
            items={contractTermsItems}
            selectProps={{ dropdownProps: { disabled } }}
            btnProps={{
              fullWidth: true,
              typeBtn: 'stroke',
              size: 'medium',
              disabled: disabled || edit,
              justifyContent: 'space-between',
              fontWeight: !store.contractTerm ? 400 : undefined,
              style: {
                color: !store.contractTerm ? 'var(--content-neutral-primary)' : undefined,
                height: '40px',
              },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Label label={{ text: 'Credits' }} />
          <TextField
            Input={InputFormatNumber as IInputComponent}
            variant={'stroke'}
            size={'medium'}
            InputProps={{
              placeholder: 'Number of credits',
            }}
            disabled={disabled || edit}
            rightActions={[
              makeTextFieldElement({
                element: (
                  <>
                    <Divider orientation='vertical' sx={{ height: '16px !important' }} />
                    <Typography
                      tag='span'
                      variant={'text-input-field'}
                      color='var(--gray-50)'
                      ariaLabel={'contractTermName'}
                    >
                      per {store.contractTermName}
                    </Typography>
                  </>
                ),
              }),
            ]}
            value={String(store.credits)}
            onChange={store.setCredits}
          />
        </Grid>

        <Grid item xs={6}>
          <SelectField
            labelText={'Billing frequency'}
            value={store.billingFrequency}
            setValue={(value) => setBillingFrequency?.(value)}
            items={billingFrequencyItems}
            labelGap='0'
            selectProps={{ dropdownProps: { disabled } }}
            btnProps={{
              fullWidth: true,
              typeBtn: 'stroke',
              size: 'medium',
              disabled: store.isDisableBillingFrequency || edit || disabled,
              justifyContent: 'space-between',
              fontWeight: !store.billingFrequency ? 400 : undefined,
              style: {
                color: !store.billingFrequency ? 'var(--content-neutral-primary)' : undefined,
                height: '40px',
              },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Label label={{ text: 'Additional credits price' }} />
          <TextField
            Input={InputPositiveNumber as IInputComponent}
            variant={'stroke'}
            size={'medium'}
            disabled={disabled || edit}
            error={!store.isValidAdditionalCreditPrice}
            InputProps={
              {
                prefix: '$',
                placeholder: 'Additional credits price',
                decimalScale: 4,
                fixedDecimalScale: true,
                onChange: (e) => {
                  setAdditionalCreditsPrice &&
                    setAdditionalCreditsPrice(e.target.value.replace(/\D/g, ''))
                },
                isAllowed: isValidAdditionalCreditPrice,
              } as IInputNumericProps
            }
            value={String(store.additionalCreditsPrice)}
          />
          <Error
            type={'text'}
            error={
              !store.isValidAdditionalCreditPrice &&
              `The additional credit price must be between ${MIN_ADDITIONAL_CREDIT_PRICE} and ${MAX_ADDITIONAL_CREDIT_PRICE}`
            }
          />
        </Grid>

        <Grid item xs={6}>
          <Label label={{ text: 'Total' }} />
          <TextField
            Input={InputCurrencyFormat as IInputComponent}
            variant={'stroke'}
            size={'medium'}
            disabled={disabled || edit}
            InputProps={
              {
                placeholder: 'Total amount',
                onChange: (e) => {
                  setTotalAmount && setTotalAmount(e.target.value.replace(/\D/g, ''))
                },
                isAllowed: isAllow,
              } as IInputNumericProps
            }
            value={String(store.totalAmount)}
          />
        </Grid>

        {withSetContract && (
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <Checkbox
              checked={store.isContract}
              onChange={(e, value) => store.setContract && store.setContract(value)}
              sx={{ marginRight: 1 }}
            />
            <Typography
              ariaLabel={'Contract signed'}
              variant={'text-input-field'}
              color={'var(--content-primary-primary)'}
            >
              Contract signed
            </Typography>
          </Grid>
        )}

        <Alert
          marginBottom={12}
          item={{
            desc: 'Every Salesmsg plan comes with 1 free seat and 1 free number by default',
            type: 'infoBlue',
          }}
          className={styles.alert}
        />
        <Grid item xs={6}>
          <NumberCounter
            label={additional ? 'Additional free seats' : 'Free seats'}
            value={store.seats}
            disabled={disabled}
            onIncrement={incrementSeats}
            onDecrement={decrementSeats}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberCounter
            label={additional ? 'Additional free numbers' : 'Free numbers'}
            value={store.numbers}
            disabled={disabled}
            onIncrement={incrementNumbers}
            onDecrement={decrementNumbers}
          />
        </Grid>
        {!isTrial && (
          <>
            <Grid item xs={6}>
              <NumberCounter
                label='Paid seats'
                optionalLabel='(Optional)'
                value={store.paidSeats || 0}
                disabled={disablesPaidNumbers}
                onIncrement={incrementPaidSeats}
                onDecrement={decrementPaidSeats}
              />
            </Grid>
            <Grid item xs={6}>
              <NumberCounter
                label='Paid numbers'
                optionalLabel='(Optional)'
                value={store.paidNumbers || 0}
                disabled={disablesPaidNumbers}
                onIncrement={incrementPaidNumbers}
                onDecrement={decrementPaidNumbers}
              />
            </Grid>
            <Grid
              display='flex'
              alignItems='center'
              gap={1}
              marginBottom={2}
              marginLeft={2}
              marginTop={4}
              width={'100%'}
            >
              <Checkbox checked={store.twoWeeksTrial} onChange={setTwoWeeksTrial} />
              <Typography ariaLabel='twoWeeksTrial' color='var(--gray-60)'>
                14 days trial
              </Typography>
            </Grid>
          </>
        )}
      </>
    )
  }
)
