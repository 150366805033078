// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nF56NvJ6d7C5GdIzW24v{border-bottom:1px solid var(--gray-20);padding:8px;display:grid;gap:4px;grid-template-columns:153px 40px 105px;align-items:center}.nF56NvJ6d7C5GdIzW24v .Lddqrn_DxHMlFKnenVWg:hover .hoKwwPiMa81eQ_VJGvOZ,.nF56NvJ6d7C5GdIzW24v .Lddqrn_DxHMlFKnenVWg:active .hoKwwPiMa81eQ_VJGvOZ,.nF56NvJ6d7C5GdIzW24v .Lddqrn_DxHMlFKnenVWg:focus .hoKwwPiMa81eQ_VJGvOZ{width:100%}.nF56NvJ6d7C5GdIzW24v .Lddqrn_DxHMlFKnenVWg .hoKwwPiMa81eQ_VJGvOZ{margin-right:4px;width:max-content}.nF56NvJ6d7C5GdIzW24v .klcLc5vw4v6vyrdPmLUh{padding-left:8px}.nF56NvJ6d7C5GdIzW24v .klcLc5vw4v6vyrdPmLUh .XoVbxZEELAd4N05yl4i3{font-size:13px}.kTu6FepNLbWeL7plsqNc{padding:8px}.QEL05KYqQ68jCjyKQw65{padding:16px;margin-bottom:-16px}.Tie5tKoWpqSGowUlijV7{display:flex;gap:8px;padding:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/Recurring/ui/CustomContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,WAAA,CACA,YAAA,CACA,OAAA,CACA,sCAAA,CACA,kBAAA,CAII,yNACE,UAAA,CAIJ,kEACE,gBAAA,CACA,iBAAA,CAKJ,4CACE,gBAAA,CACA,kEACE,cAAA,CAKN,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,YAAA","sourcesContent":[".content{\n  border-bottom: 1px solid var(--gray-20);\n  padding: 8px;\n  display: grid;\n  gap: 4px;\n  grid-template-columns: 153px 40px 105px;\n  align-items: center;\n  .btn{\n    &:hover, &:active,  &:focus{\n\n      .text{\n        width: 100%;\n\n      }\n    }\n    .text{\n      margin-right: 4px;\n      width: max-content;\n\n    }\n\n  }\n  .label{\n    padding-left: 8px;\n    .textLabel{\n      font-size: 13px;\n    }\n  }\n}\n\n.input{\n  padding: 8px;\n}\n\n.additionalContent{\n  padding: 16px;\n  margin-bottom: -16px;\n}\n\n.actions{\n  display: flex;\n  gap: 8px;\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "nF56NvJ6d7C5GdIzW24v",
	"btn": "Lddqrn_DxHMlFKnenVWg",
	"text": "hoKwwPiMa81eQ_VJGvOZ",
	"label": "klcLc5vw4v6vyrdPmLUh",
	"textLabel": "XoVbxZEELAd4N05yl4i3",
	"input": "kTu6FepNLbWeL7plsqNc",
	"additionalContent": "QEL05KYqQ68jCjyKQw65",
	"actions": "Tie5tKoWpqSGowUlijV7"
};
export default ___CSS_LOADER_EXPORT___;
