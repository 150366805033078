import { ReactComponent as PhoneOff } from 'icons/PhoneOff.svg'
import { ReactComponent as Voicemail2 } from 'icons/Voicemail2.svg'
import { ReactComponent as LineCollapseExpandLeft } from 'icons/LineCollapseExpandLeft.svg'
import { ReactComponent as LineInfoCircle } from 'icons/line/InfoCircle.svg'
import { ReactComponent as Admin } from 'icons/Admin.svg'
import { ReactComponent as Add } from 'icons/Add.svg'
import { ReactComponent as AddCircle } from 'icons/AddCircle.svg'
import { ReactComponent as AddOn } from 'icons/AddOn.svg'
import { ReactComponent as Alert } from 'icons/Alert.svg'
import { ReactComponent as AlertTriangle } from 'icons/AlertTriangle.svg'
import { ReactComponent as AlertTriangleSmall } from 'icons/AlertTriangleSmall.svg'
import { ReactComponent as AlertTriangleYellow } from 'icons/AlertTriangleYellow.svg'
import { ReactComponent as Announcement } from 'icons/Announcement.svg'
import { ReactComponent as ArrowDown } from 'icons/ArrowDown.svg'
import { ReactComponent as ArrowLeft } from 'icons/ArrowLeft.svg'
import { ReactComponent as ArrowLeftDown } from 'icons/ArrowLeftDown.svg'
import { ReactComponent as ArrowLeftUp } from 'icons/ArrowLeftUp.svg'
import { ReactComponent as ArrowLinesDown } from 'icons/ArrowLinesDown.svg'
import { ReactComponent as ArrowLinesUp } from 'icons/ArrowLinesUp.svg'
import { ReactComponent as ArrowRight } from 'icons/ArrowRight.svg'
import { ReactComponent as ArrowRightBold } from 'icons/ArrowRightBold.svg'
import { ReactComponent as ArrowRightDown } from 'icons/ArrowRightDown.svg'
import { ReactComponent as ArrowRightUp } from 'icons/ArrowRightUp.svg'
import { ReactComponent as ArrowUp } from 'icons/ArrowUp.svg'
import { ReactComponent as ArrowUpCircle } from 'icons/ArrowUpCircle.svg'
import { ReactComponent as At } from 'icons/At.svg'
import { ReactComponent as Attachment } from 'icons/Attachment.svg'
import { ReactComponent as Audio } from 'icons/Audio.svg'
import { ReactComponent as Back } from 'icons/Back.svg'
import { ReactComponent as BookmarkArrowDown } from 'icons/BookmarkArrowDown.svg'
import { ReactComponent as BookmarkArrowUp } from 'icons/BookmarkArrowUp.svg'
import { ReactComponent as Bot } from 'icons/Bot.svg'
import { ReactComponent as Broadcasts } from 'icons/Broadcasts.svg'
import { ReactComponent as Building } from 'icons/Building.svg'
import { ReactComponent as Bulb } from 'icons/Bulb.svg'
import { ReactComponent as Calendar } from 'icons/Calendar.svg'
import { ReactComponent as CalendarArrowDown } from 'icons/CalendarArrowDown.svg'
import { ReactComponent as CalendarArrowUp } from 'icons/CalendarArrowUp.svg'
import { ReactComponent as CalendarPlus } from 'icons/CalendarPlus.svg'
import { ReactComponent as CalendarClock } from 'icons/CalendarClock.svg'
import { ReactComponent as Calendly } from 'icons/Calendly.svg'
import { ReactComponent as Campaigns } from 'icons/Campaigns.svg'
import { ReactComponent as CameraOff } from 'icons/CameraOff.svg'
import { ReactComponent as CancelFilled } from 'icons/CancelFilled.svg'
import { ReactComponent as CancelOnResponse } from 'icons/CancelOnResponse.svg'
import { ReactComponent as ChatLayout } from 'icons/ChatLayout.svg'
import { ReactComponent as ChatRead } from 'icons/ChatRead.svg'
import { ReactComponent as ChatUnread } from 'icons/ChatUnread.svg'
import { ReactComponent as Check1 } from 'icons/Check1.svg'
import { ReactComponent as Check2 } from 'icons/Check2.svg'
import { ReactComponent as Check3 } from 'icons/Check3.svg'
import { ReactComponent as Check4 } from 'icons/Check4.svg'
import { ReactComponent as CheckBoxFilled } from 'icons/CheckBoxFilled.svg'
import { ReactComponent as CheckBoxOutlineBlankOutlined } from 'icons/CheckBoxOutlineBlankOutlined.svg'
import { ReactComponent as CheckboxChecked } from 'icons/CheckboxChecked.svg'
import { ReactComponent as CheckboxMixed } from 'icons/CheckboxMixed.svg'
import { ReactComponent as Checkbox } from 'icons/Checkbox.svg'
import { ReactComponent as Checkbox_checked } from 'icons/Checkbox_checked.svg'
import { ReactComponent as Checkbox_mixed } from 'icons/Checkbox_mixed.svg'
import { ReactComponent as ChevronDown } from 'icons/ChevronDown.svg'
import { ReactComponent as ChevronLeft } from 'icons/ChevronLeft.svg'
import { ReactComponent as ChevronRight } from 'icons/ChevronRight.svg'
import { ReactComponent as ChevronUp } from 'icons/ChevronUp.svg'
import { ReactComponent as Clock } from 'icons/Clock.svg'
import { ReactComponent as ClockFastForward } from 'icons/ClockFastForward.svg'
import { ReactComponent as ClockPlus } from 'icons/ClockPlus.svg'
import { ReactComponent as ClockRecent } from 'icons/ClockRecent.svg'
import { ReactComponent as ClockRewind } from 'icons/ClockRewind.svg'
import { ReactComponent as ClockSnooze } from 'icons/ClockSnooze.svg'
import { ReactComponent as Close } from 'icons/Close.svg'
import { ReactComponent as CloseCircle } from 'icons/CloseCircle.svg'
import { ReactComponent as Code } from 'icons/Code.svg'
import { ReactComponent as Contacts } from 'icons/Contacts.svg'
import { ReactComponent as Conversation } from 'icons/Conversation.svg'
import { ReactComponent as Copy } from 'icons/Copy.svg'
import { ReactComponent as Database } from 'icons/Database.svg'
import { ReactComponent as Delete } from 'icons/Delete.svg'
import { ReactComponent as DotsGrid } from 'icons/DotsGrid.svg'
import { ReactComponent as Download } from 'icons/Download.svg'
import { ReactComponent as Draft } from 'icons/Draft.svg'
import { ReactComponent as Edit } from 'icons/Edit.svg'
import { ReactComponent as Edit02 } from 'icons/Edit02.svg'
import { ReactComponent as Email } from 'icons/Email.svg'
import { ReactComponent as Emoji } from 'icons/Emoji.svg'
import { ReactComponent as EndRepeat } from 'icons/EndRepeat.svg'
import { ReactComponent as ExclamationCircle } from 'icons/ExclamationCircle.svg'
import { ReactComponent as Eye } from 'icons/Eye.svg'
import { ReactComponent as EyeOff } from 'icons/EyeOff.svg'
import { ReactComponent as Facebook } from 'icons/Facebook.svg'
import { ReactComponent as Filter } from 'icons/Filter.svg'
import { ReactComponent as FlagFilled } from 'icons/FlagFilled.svg'
import { ReactComponent as FlagLine } from 'icons/FlagLine.svg'
import { ReactComponent as Folder } from 'icons/Folder.svg'
import { ReactComponent as Forward } from 'icons/Forward.svg'
import { ReactComponent as GIF } from 'icons/GIF.svg'
import { ReactComponent as Globe } from 'icons/Globe.svg'
import { ReactComponent as Home } from 'icons/Home.svg'
import { ReactComponent as ImageFilled } from 'icons/ImageFilled.svg'
import { ReactComponent as ImageLine } from 'icons/ImageLine.svg'
import { ReactComponent as Inbox } from 'icons/Inbox.svg'
import { ReactComponent as InboxOpen } from 'icons/InboxOpen.svg'
import { ReactComponent as IndeterminateCheckBoxFilled } from 'icons/IndeterminateCheckBoxFilled.svg'
import { ReactComponent as Info } from 'icons/Info.svg'
import { ReactComponent as InfoCircle } from 'icons/InfoCircle.svg'
import { ReactComponent as LayoutGrid } from 'icons/LayoutGrid.svg'
import { ReactComponent as LetterDownArrow } from 'icons/LetterDownArrow.svg'
import { ReactComponent as LetterUpArrow } from 'icons/LetterUpArrow.svg'
import { ReactComponent as Lightning } from 'icons/Lightning.svg'
import { ReactComponent as Limit } from 'icons/Limit.svg'
import { ReactComponent as Link } from 'icons/Link.svg'
import { ReactComponent as Linkedin } from 'icons/Linkedin.svg'
import { ReactComponent as ListLayout } from 'icons/ListLayout.svg'
import { ReactComponent as Loading1 } from 'icons/Loading1.svg'
import { ReactComponent as Loading2 } from 'icons/Loading2.svg'
import { ReactComponent as MagicWand } from 'icons/MagicWand.svg'
import { ReactComponent as Magnet } from 'icons/Magnet.svg'
import { ReactComponent as MailFilled } from 'icons/MailFilled.svg'
import { ReactComponent as Meeting } from 'icons/Meeting.svg'
import { ReactComponent as MessagePlus } from 'icons/MessagePlus.svg'
import { ReactComponent as MessageTextSquare } from 'icons/MessageTextSquare.svg'
import { ReactComponent as MessageX } from 'icons/MessageX.svg'
import { ReactComponent as MessageBell } from 'icons/MessageBell.svg'
import { ReactComponent as MessageQuestion } from 'icons/MessageQuestion.svg'
import { ReactComponent as Microphone } from 'icons/Microphone.svg'
import { ReactComponent as Missed } from 'icons/Missed.svg'
import { ReactComponent as MoreHorizontal } from 'icons/MoreHorizontal.svg'
import { ReactComponent as MoreVertical } from 'icons/MoreVertical.svg'
import { ReactComponent as MoveTo } from 'icons/MoveTo.svg'
import { ReactComponent as NoAnswer } from 'icons/NoAnswer.svg'
import { ReactComponent as Pause } from 'icons/Pause.svg'
import { ReactComponent as Pause1 } from 'icons/Pause1.svg'
import { ReactComponent as PersonFilled } from 'icons/PersonFilled.svg'
import { ReactComponent as Phone } from 'icons/Phone.svg'
import { ReactComponent as Phone2 } from 'icons/Phone2.svg'
import { ReactComponent as Pin } from 'icons/Pin.svg'
import { ReactComponent as Play } from 'icons/Play.svg'
import { ReactComponent as Play1 } from 'icons/Play1.svg'
import { ReactComponent as Plug } from 'icons/Plug.svg'
import { ReactComponent as Question } from 'icons/Question.svg'
import { ReactComponent as RadioButtonCheckedFilled } from 'icons/RadioButtonCheckedFilled.svg'
import { ReactComponent as RadioButtonUncheckedFilled } from 'icons/RadioButtonUncheckedFilled.svg'
import { ReactComponent as Number } from 'icons/Number.svg'
import { ReactComponent as Record } from 'icons/Record.svg'
import { ReactComponent as Refresh } from 'icons/Refresh.svg'
import { ReactComponent as RefreshCircle } from 'icons/RefreshCircle.svg'
import { ReactComponent as Remove } from 'icons/Remove.svg'
import { ReactComponent as SLA1 } from 'icons/SLA1.svg'
import { ReactComponent as SLA2 } from 'icons/SLA2.svg'
import { ReactComponent as SLA3 } from 'icons/SLA3.svg'
import { ReactComponent as Slack } from 'icons/Slack.svg'
import { ReactComponent as SavedReplies } from 'icons/SavedReplies.svg'
import { ReactComponent as Schedule } from 'icons/Schedule.svg'
import { ReactComponent as Search } from 'icons/Search.svg'
import { ReactComponent as Send } from 'icons/Send.svg'
import { ReactComponent as Settings } from 'icons/Settings.svg'
import { ReactComponent as Settings2 } from 'icons/Settings2.svg'
import { ReactComponent as SettingsOutline } from 'icons/SettingsOutline.svg'
import { ReactComponent as Share } from 'icons/Share.svg'
import { ReactComponent as Snooze } from 'icons/Snooze.svg'
import { ReactComponent as StarFilled1 } from 'icons/StarFilled1.svg'
import { ReactComponent as StarFilled2 } from 'icons/StarFilled2.svg'
import { ReactComponent as StarLine } from 'icons/StarLine.svg'
import { ReactComponent as Stars } from 'icons/Stars.svg'
import { ReactComponent as Tag } from 'icons/Tag.svg'
import { ReactComponent as Target } from 'icons/Target.svg'
import { ReactComponent as TestSMS } from 'icons/TestSMS.svg'
import { ReactComponent as TextInput } from 'icons/TextInput.svg'
import { ReactComponent as Twilio } from 'icons/Twilio.svg'
import { ReactComponent as Twitter } from 'icons/Twitter.svg'
import { ReactComponent as Unanswered } from 'icons/Unanswered.svg'
import { ReactComponent as Upload } from 'icons/Upload.svg'
import { ReactComponent as User } from 'icons/User.svg'
import { ReactComponent as UserFilled } from 'icons/UserFilled.svg'
import { ReactComponent as UserLine } from 'icons/UserLine.svg'
import { ReactComponent as UserPlus } from 'icons/UserPlus.svg'
import { ReactComponent as Users } from 'icons/Users.svg'
import { ReactComponent as UsersCheck } from 'icons/UsersCheck.svg'
import { ReactComponent as Video } from 'icons/Video.svg'
import { ReactComponent as Video2 } from 'icons/Video2.svg'
import { ReactComponent as VoiceToText } from 'icons/VoiceToText.svg'
import { ReactComponent as Voicemail } from 'icons/Voicemail.svg'
import { ReactComponent as VolumeMax } from 'icons/VolumeMax.svg'
import { ReactComponent as VolumeMin } from 'icons/Volume-min.svg'
import { ReactComponent as VolumeMute } from 'icons/Volume-mute.svg'
import { ReactComponent as Workflows } from 'icons/Workflows.svg'
import { ReactComponent as VCard } from 'icons/vCard.svg'
import { ReactComponent as LogoSmallBlue } from 'icons/LogoSmallBlue.svg'
import { ReactComponent as Marketing } from 'icons/Marketing.svg'
import { ReactComponent as MinusCircle } from 'icons/Minus-circle.svg'
import { ReactComponent as MinusSquare } from 'icons/Minus-square.svg'
import { ReactComponent as PlusSquare } from 'icons/Plus-square.svg'
import { ReactComponent as Expand } from 'icons/Expand.svg'
import { ReactComponent as Expand2 } from 'icons/Expand2.svg'
import { ReactComponent as Export } from 'icons/Export.svg'
import { ReactComponent as Failed } from 'icons/Failed.svg'
import { ReactComponent as Fallback } from 'icons/Fallback.svg'
import { ReactComponent as ChatEmpty } from 'icons/ChatEmpty.svg'
import { ReactComponent as Collapse } from 'icons/Collapse.svg'
import { ReactComponent as Ellipse_6 } from 'icons/Ellipse_6.svg'
import { ReactComponent as AllInboxes } from 'icons/AllInboxes.svg'
import { ReactComponent as Aircall } from 'icons/Aircall.svg'
import { ReactComponent as Salesmessage } from 'icons/Salesmessage.svg'
import { ReactComponent as Activecampaign } from 'icons/Activecampaign.svg'
import { ReactComponent as Hubspot } from 'icons/Hubspot.svg'
import { ReactComponent as Infusionsoft } from 'icons/Infusionsoft.svg'
import { ReactComponent as Pipedrive } from 'icons/Pipedrive.svg'
import { ReactComponent as Salesforce } from 'icons/Salesforce.svg'
import { ReactComponent as Intercom } from 'icons/Intercom.svg'
import { ReactComponent as Analytics } from 'icons/Analytics.svg'
import { ReactComponent as Clients } from 'icons/Clients.svg'
import { ReactComponent as Keywords } from 'icons/Keywords.svg'
import { ReactComponent as Support } from 'icons/Support.svg'
import { ReactComponent as Lighthub } from 'icons/Lighthub.svg'
import { ReactComponent as Alarm } from 'icons/Alarm.svg'
import { ReactComponent as Lightning2 } from 'icons/Lightning2.svg'
import { ReactComponent as Briefcase } from 'icons/Briefcase.svg'
import { ReactComponent as LogOut } from 'icons/LogOut.svg'
import { ReactComponent as LinkExternal } from 'icons/LinkExternal.svg'
import { ReactComponent as Unlink } from 'icons/Unlink.svg'
import { ReactComponent as Dialpad } from 'icons/Dialpad.svg'
import { ReactComponent as DotsDrag } from 'icons/DotsDrag.svg'
import { ReactComponent as PhoneHangUp } from 'icons/PhoneHangUp.svg'
import { ReactComponent as PhoneTransfer } from 'icons/PhoneTransfer.svg'
import { ReactComponent as MicrophoneMute } from 'icons/MicrophoneMute.svg'
import { ReactComponent as MessageTextCircle02 } from 'icons/MessageTextCircle02.svg'
import { ReactComponent as Segment } from 'icons/Segment.svg'
import { ReactComponent as MessageSmile } from 'icons/MessageSmile.svg'
import { ReactComponent as MessageSmileCircle } from 'icons/MessageSmileCircle.svg'
import { ReactComponent as Signature } from 'icons/Signature.svg'
import { ReactComponent as Bell } from 'icons/Bell.svg'
import { ReactComponent as BellFilled } from 'icons/BellFilled.svg'
import { ReactComponent as Toggle } from 'icons/Toggle.svg'
import { ReactComponent as Block } from 'icons/Block.svg'
import { ReactComponent as Dash } from 'icons/Dash.svg'
import { ReactComponent as TextInput2 } from 'icons/Text-input.svg'
import { ReactComponent as Hash } from 'icons/Hash.svg'
import { ReactComponent as MakerPin } from 'icons/Maker-pin.svg'
import { ReactComponent as MessageCircleChecked } from 'icons/Message-circle-checked.svg'
import { ReactComponent as Google } from 'icons/Google.svg'
import { ReactComponent as Outlook } from 'icons/Outlook.svg'
import { ReactComponent as ColorDot } from 'icons/ColorDot.svg'
import { ReactComponent as Archive } from 'icons/Archive.svg'
import { ReactComponent as Hash2 } from 'icons/Hash2.svg'
import { ReactComponent as Loading3 } from 'icons/Loading3.svg'
import { ReactComponent as MessageCircle } from 'icons/MessageCircle.svg'
import { ReactComponent as PieChart } from 'icons/PieChart.svg'
import { ReactComponent as TagCircle } from 'icons/TagCircle.svg'
import { ReactComponent as Unarchive } from 'icons/Unarchive.svg'
import { ReactComponent as PaymentCard } from 'icons/PaymentCard.svg'
import { ReactComponent as Shield } from 'icons/Shield.svg'
import { ReactComponent as Dollar } from 'icons/Dollar.svg'
import { ReactComponent as Building2 } from 'icons/Building2.svg'
import { ReactComponent as Container } from 'icons/Container.svg'
import { ReactComponent as ClockBackward } from 'icons/ClockBackward.svg'
import { ReactComponent as CircleDot } from 'icons/CircleDot.svg'
import { ReactComponent as CalendarArrowRight } from 'icons/CalendarArrowRight.svg'
import { ReactComponent as Stop } from 'icons/Stop.svg'
import { ReactComponent as SalesmsgMark } from 'icons/SalesmsgMark.svg'
import { ReactComponent as Lock } from 'icons/Lock.svg'
import { ReactComponent as Unlock } from 'icons/Unlock.svg'
import { ReactComponent as TextToSpeech } from 'icons/TextToSpeech.svg'
import { ReactComponent as Voice } from 'icons/Voice.svg'
import { ReactComponent as MediaLibrary } from 'icons/MediaLibrary.svg'
import { ReactComponent as MusicNote } from 'icons/MusicNote.svg'
import { ReactComponent as AmericanExpress } from 'icons/cards/AmericanExpress.svg'
import { ReactComponent as DinersClub } from 'icons/cards/DinersClub.svg'
import { ReactComponent as Discover } from 'icons/cards/Discover.svg'
import { ReactComponent as JCB } from 'icons/cards/JCB.svg'
import { ReactComponent as Mastercard } from 'icons/cards/Mastercard.svg'
import { ReactComponent as NoCard } from 'icons/cards/NoCard.svg'
import { ReactComponent as Union } from 'icons/Union.svg'
import { ReactComponent as UnionPay } from 'icons/cards/UnionPay.svg'
import { ReactComponent as Visa } from 'icons/cards/Visa.svg'
import { ReactComponent as CollapseLeft } from 'icons/CollapseLeft.svg'
import { ReactComponent as CollapseRight } from 'icons/CollapseRight.svg'
import { ReactComponent as ExpandLeft } from 'icons/ExpandLeft.svg'
import { ReactComponent as ExpandRight } from 'icons/ExpandRight.svg'
import { ReactComponent as Repeat } from 'icons/Repeat.svg'
import { ReactComponent as RepeatBold } from 'icons/RepeatBold.svg'
import { ReactComponent as ArrowLinesHorizontal } from 'icons/ArrowLinesHorizontal.svg'
import { ReactComponent as ChatBot } from 'icons/ChatBot.svg'
import { ReactComponent as SuccessCircle } from 'icons/SuccessCircle.svg'
import { ReactComponent as Lines } from 'icons/Lines.svg'
import { ReactComponent as Radiobox } from 'icons/Radiobox.svg'
import { ReactComponent as RadioboxChecked } from 'icons/RadioboxChecked.svg'
import { ReactComponent as FlagUK } from 'icons/flags/UK.svg'
import { ReactComponent as FlagAU } from 'icons/flags/AU.svg'
import { ReactComponent as FlagUSA } from 'icons/flags/USA.svg'
import { ReactComponent as FlagCanada } from 'icons/flags/Canada.svg'
import { ReactComponent as TwilioIntegration } from 'icons/TwilioIntegration.svg'
import { ReactComponent as PhoneRightArrows } from 'icons/PhoneRightArrows.svg'
import { ReactComponent as LineType } from 'icons/line/Type.svg'
import { ReactComponent as Bank } from 'icons/Bank.svg'
import { ReactComponent as FilePdf } from 'icons/FormatPdf.svg'
import { ReactComponent as FileDoc } from 'icons/FormatDoc.svg'
import { ReactComponent as FileDocx } from 'icons/FormatDocx.svg'
import { ReactComponent as FileXls } from 'icons/FormatXls.svg'
import { ReactComponent as FileXlsx } from 'icons/FormatXlsx.svg'
import { ReactComponent as FileCsv } from 'icons/FormatCsv.svg'
import { ReactComponent as FileTxt } from 'icons/FormatTxt.svg'
import { ReactComponent as FileHtml } from 'icons/FormatHtml.svg'
import { ReactComponent as FileMd } from 'icons/FormatMd.svg'
import { ReactComponent as Transcript } from 'icons/Transcript.svg'
import { ReactComponent as OldPhone } from 'icons/OldPhone.svg'
import { ReactComponent as QR } from 'icons/QR.svg'
import { ReactComponent as Card } from 'icons/Card.svg'
import { ReactComponent as User2 } from 'icons/User2.svg'
import { ReactComponent as TickSymbol } from 'icons/TickSymbol.svg'
import { ReactComponent as CrossMark } from 'icons/CrossMark.svg'
import { ReactComponent as Zapier } from 'icons/Zapier.svg'
import { ReactComponent as Make } from 'icons/Make.svg'
import { ReactComponent as Webhook } from 'icons/Webhook.svg'
import { ReactComponent as Monitor01 } from 'icons/Monitor01.svg'
import { ReactComponent as QrCode02 } from 'icons/QrCode02.svg'
import { ReactComponent as File02 } from 'icons/File02.svg'
import { ReactComponent as SandGlass } from 'icons/line/Sand-glass.svg'
import { ReactComponent as WorkflowOutline } from 'icons/WorkflowOutline.svg'
import { ReactComponent as WebhookOutline } from 'icons/WebhookOutline.svg'
import { ReactComponent as ArrowHorizontalOutline } from 'icons/ArrowHorizontalOutline.svg'
import { ReactComponent as IntegrationHubspot } from 'icons/integration/Hubspot.svg'
import { ReactComponent as IntegrationSalessms } from 'icons/integration/Salessms.svg'
import { ReactComponent as IntegrationSalesforce } from 'icons/integration/Salesforce.svg'
import { ReactComponent as Credits } from 'icons/Credits.svg'
import { ReactComponent as StarOutline } from 'icons/StarOutline.svg'
import { ReactComponent as Outbound } from 'icons/Outbound.svg'
import { ReactComponent as SuperAdmin } from 'icons/SuperAdmin.svg'
import { ReactComponent as ClipboardCheck } from 'icons/ClipboardCheck.svg'
import { ReactComponent as Check2Correct } from 'icons/Check2Correct.svg'
import { ReactComponent as Users2 } from 'icons/Users2.svg'
import { ReactComponent as Chart1 } from 'icons/Chart1.svg'
import { ReactComponent as Chart2 } from 'icons/Chart2.svg'
import { ReactComponent as Chart3 } from 'icons/Chart3.svg'

const icons = {
  clipboardCheck: <ClipboardCheck />,
  superAdmin: <SuperAdmin />,
  outbound: <Outbound />,
  phoneOff: <PhoneOff />,
  starOutline: <StarOutline />,
  credits: <Credits />,
  integrationHubspot: <IntegrationHubspot />,
  integrationSalessms: <IntegrationSalessms />,
  IntegrationSalesforce: <IntegrationSalesforce />,
  workflowOutline: <WorkflowOutline />,
  webhookOutline: <WebhookOutline />,
  arrowHorizontalOutline: <ArrowHorizontalOutline />,
  file02: <File02 />,
  qrCode02: <QrCode02 />,
  monitor01: <Monitor01 />,
  tickSymbol: <TickSymbol />,
  crossMark: <CrossMark />,
  qr: <QR />,
  oldPhone: <OldPhone />,
  transcript: <Transcript />,
  twilioIntegration: <TwilioIntegration />,
  radiobox: <Radiobox />,
  radioboxChecked: <RadioboxChecked />,
  flagCanada: <FlagCanada />,
  flagUSA: <FlagUSA />,
  flagUK: <FlagUK />,
  flagAU: <FlagAU />,
  musicNote: <MusicNote />,
  mediaLibrary: <MediaLibrary />,
  voice: <Voice />,
  textToSpeech: <TextToSpeech />,
  unlock: <Unlock />,
  lock: <Lock />,
  salesmsgMark: <SalesmsgMark />,
  stop: <Stop />,
  calendarArrowRight: <CalendarArrowRight />,
  circleDot: <CircleDot />,
  clockBackward: <ClockBackward />,
  container: <Container />,
  building2: <Building2 />,
  dollar: <Dollar />,
  shield: <Shield />,
  paymentCard: <PaymentCard />,
  archive: <Archive />,
  hash2: <Hash2 />,
  loading3: <Loading3 />,
  messageCircle: <MessageCircle />,
  pieChart: <PieChart />,
  tagCircle: <TagCircle />,
  unarchive: <Unarchive />,
  add: <Add />,
  addCircle: <AddCircle />,
  addOn: <AddOn />,
  admin: <Admin />,
  alert: <Alert />,
  alertTriangle: <AlertTriangle />,
  alertTriangleSmall: <AlertTriangleSmall />,
  alertTriangleYellow: <AlertTriangleYellow />,
  announcement: <Announcement />,
  arrowDown: <ArrowDown />,
  arrowLeft: <ArrowLeft />,
  arrowLeftDown: <ArrowLeftDown />,
  arrowLeftUp: <ArrowLeftUp />,
  arrowLinesDown: <ArrowLinesDown />,
  arrowLinesUp: <ArrowLinesUp />,
  arrowRight: <ArrowRight />,
  arrowRightBold: <ArrowRightBold />,
  arrowRightDown: <ArrowRightDown />,
  arrowRightUp: <ArrowRightUp />,
  arrowUp: <ArrowUp />,
  arrowUpCircle: <ArrowUpCircle />,
  at: <At />,
  attachment: <Attachment />,
  audio: <Audio />,
  back: <Back />,
  bookmarkArrowDown: <BookmarkArrowDown />,
  bookmarkArrowUp: <BookmarkArrowUp />,
  bot: <Bot />,
  broadcasts: <Broadcasts />,
  building: <Building />,
  bulb: <Bulb />,
  calendar: <Calendar />,
  calendarArrowDown: <CalendarArrowDown />,
  calendarArrowUp: <CalendarArrowUp />,
  calendarPlus: <CalendarPlus />,
  calendarClock: <CalendarClock />,
  calendly: <Calendly />,
  campaigns: <Campaigns />,
  cameraOff: <CameraOff />,
  cancelFilled: <CancelFilled />,
  cancelOnResponse: <CancelOnResponse />,
  chatLayout: <ChatLayout />,
  chatRead: <ChatRead />,
  chatBot: <ChatBot />,
  chatUnread: <ChatUnread />,
  check1: <Check1 />,
  check2: <Check2 />,
  check3: <Check3 />,
  check4: <Check4 />,
  check2Correct: <Check2Correct />,
  checkBoxFilled: <CheckBoxFilled />,
  checkBoxOutlineBlankOutlined: <CheckBoxOutlineBlankOutlined />,
  checkboxChecked: <CheckboxChecked />,
  checkboxMixed: <CheckboxMixed />,
  checkbox: <Checkbox />,
  checkbox_checked: <Checkbox_checked />,
  checkbox_mixed: <Checkbox_mixed />,
  chevronDown: <ChevronDown />,
  chevronLeft: <ChevronLeft />,
  chevronRight: <ChevronRight />,
  chevronUp: <ChevronUp />,
  clock: <Clock />,
  clockFastForward: <ClockFastForward />,
  clockPlus: <ClockPlus />,
  clockRecent: <ClockRecent />,
  clockRewind: <ClockRewind />,
  clockSnooze: <ClockSnooze />,
  close: <Close />,
  closeCircle: <CloseCircle />,
  contacts: <Contacts />,
  conversation: <Conversation />,
  copy: <Copy />,
  database: <Database />,
  delete: <Delete />,
  dotsGrid: <DotsGrid />,
  download: <Download />,
  draft: <Draft />,
  edit: <Edit />,
  edit02: <Edit02 />,
  email: <Email />,
  emoji: <Emoji />,
  endRepeat: <EndRepeat />,
  exclamationCircle: <ExclamationCircle />,
  eye: <Eye />,
  eyeOff: <EyeOff />,
  facebook: <Facebook />,
  fallback: <Fallback />,
  filter: <Filter />,
  flagFilled: <FlagFilled />,
  flagLine: <FlagLine />,
  folder: <Folder />,
  forward: <Forward />,
  gif: <GIF />,
  globe: <Globe />,
  home: <Home />,
  imageFilled: <ImageFilled />,
  imageLine: <ImageLine />,
  inbox: <Inbox />,
  inboxOpen: <InboxOpen />,
  indeterminateCheckBoxFilled: <IndeterminateCheckBoxFilled />,
  info: <Info />,
  infoCircle: <InfoCircle />,
  layoutGrid: <LayoutGrid />,
  letterDownArrow: <LetterDownArrow />,
  letterUpArrow: <LetterUpArrow />,
  lightning: <Lightning />,
  limit: <Limit />,
  link: <Link />,
  linkedin: <Linkedin />,
  listLayout: <ListLayout />,
  loading1: <Loading1 />,
  loading2: <Loading2 />,
  magicWand: <MagicWand />,
  magnet: <Magnet />,
  mailFilled: <MailFilled />,
  meeting: <Meeting />,
  messagePlus: <MessagePlus />,
  messageTextSquare: <MessageTextSquare />,
  messageX: <MessageX />,
  messageBell: <MessageBell />,
  messageQuestion: <MessageQuestion />,
  microphone: <Microphone />,
  missed: <Missed />,
  moreHorizontal: <MoreHorizontal />,
  moreVertical: <MoreVertical />,
  moveTo: <MoveTo />,
  noAnswer: <NoAnswer />,
  pause: <Pause />,
  pause1: <Pause1 />,
  personFilled: <PersonFilled />,
  phone: <Phone />,
  phone2: <Phone2 />,
  pin: <Pin />,
  play: <Play />,
  play1: <Play1 />,
  plug: <Plug />,
  question: <Question />,
  radioButtonCheckedFilled: <RadioButtonCheckedFilled />,
  radioButtonUncheckedFilled: <RadioButtonUncheckedFilled />,
  number: <Number />,
  record: <Record />,
  refresh: <Refresh />,
  refreshCircle: <RefreshCircle />,
  remove: <Remove />,
  sla1: <SLA1 />,
  sla2: <SLA2 />,
  sla3: <SLA3 />,
  slack: <Slack />,
  savedReplies: <SavedReplies />,
  schedule: <Schedule />,
  search: <Search />,
  send: <Send />,
  settings: <Settings />,
  settings2: <Settings2 />,
  settingsOutline: <SettingsOutline />,
  share: <Share />,
  snooze: <Snooze />,
  starFilled1: <StarFilled1 />,
  starFilled2: <StarFilled2 />,
  starLine: <StarLine />,
  tag: <Tag />,
  target: <Target />,
  testSMS: <TestSMS />,
  textInput: <TextInput />,
  twilio: <Twilio />,
  textInput2: <TextInput2 />,
  twitter: <Twitter />,
  unanswered: <Unanswered />,
  upload: <Upload />,
  user: <User />,
  userFilled: <UserFilled />,
  userLine: <UserLine />,
  userPlus: <UserPlus />,
  users: <Users />,
  users2: <Users2 />,
  usersCheck: <UsersCheck />,
  video: <Video />,
  video2: <Video2 />,
  voiceToText: <VoiceToText />,
  voicemail: <Voicemail />,
  voicemail2: <Voicemail2 />,
  volumeMax: <VolumeMax />,
  volumeMin: <VolumeMin />,
  volumeMute: <VolumeMute />,
  workflows: <Workflows />,
  vCard: <VCard />,
  logoSmallBlue: <LogoSmallBlue />,
  marketing: <Marketing />,
  minusCircle: <MinusCircle />,
  minusSquare: <MinusSquare />,
  plusSquare: <PlusSquare />,
  expand: <Expand />,
  expand2: <Expand2 />,
  export: <Export />,
  failed: <Failed />,
  chatEmpty: <ChatEmpty />,
  collapse: <Collapse />,
  ellipse_6: <Ellipse_6 />,
  all_inboxes: <AllInboxes />,
  aircall: <Aircall />,
  salesmessage: <Salesmessage />,
  salesmsg: <Salesmessage />,
  activecampaign: <Activecampaign />,
  hubspot: <Hubspot />,
  infusionsoft: <Infusionsoft />,
  pipedrive: <Pipedrive />,
  salesforce: <Salesforce />,
  intercom: <Intercom />,
  analytics: <Analytics />,
  clients: <Clients />,
  keywords: <Keywords />,
  support: <Support />,
  lighthub: <Lighthub />,
  alarm: <Alarm />,
  stars: <Stars />,
  lightning2: <Lightning2 />,
  briefcase: <Briefcase />,
  logOut: <LogOut />,
  linkExternal: <LinkExternal />,
  unlink: <Unlink />,
  dialpad: <Dialpad />,
  dotsDrag: <DotsDrag />,
  phoneHangUp: <PhoneHangUp />,
  phoneTransfer: <PhoneTransfer />,
  microphoneMute: <MicrophoneMute />,
  messageTextCircle02: <MessageTextCircle02 />,
  segment: <Segment />,
  messageSmile: <MessageSmile />,
  messageSmileCircle: <MessageSmileCircle />,
  signature: <Signature />,
  bell: <Bell />,
  bellFilled: <BellFilled />,
  toggle: <Toggle />,
  block: <Block />,
  dash: <Dash />,
  hash: <Hash />,
  messageCircleChecked: <MessageCircleChecked />,
  makerPin: <MakerPin />,
  google: <Google />,
  outlook: <Outlook />,
  colorDot: <ColorDot />,
  lineCollapseExpandLeft: <LineCollapseExpandLeft />,
  lineInfoCircle: <LineInfoCircle />,
  americanExpress: <AmericanExpress />,
  dinersClub: <DinersClub />,
  discover: <Discover />,
  JCB: <JCB />,
  mastercard: <Mastercard />,
  noCard: <NoCard />,
  unionPay: <UnionPay />,
  union: <Union />,
  visa: <Visa />,
  collapseLeft: <CollapseLeft />,
  collapseRight: <CollapseRight />,
  expandLeft: <ExpandLeft />,
  expandRight: <ExpandRight />,
  repeat: <Repeat />,
  repeatBold: <RepeatBold />,
  arrowLinesHorizontal: <ArrowLinesHorizontal />,
  successCircle: <SuccessCircle />,
  lines: <Lines />,
  phoneRightArrows: <PhoneRightArrows />,
  lineType: <LineType />,
  bank: <Bank />,
  filePdf: <FilePdf />,
  fileDoc: <FileDoc />,
  fileDocx: <FileDocx />,
  fileXls: <FileXls />,
  fileXlsx: <FileXlsx />,
  fileCsv: <FileCsv />,
  fileTxt: <FileTxt />,
  fileMd: <FileMd />,
  fileHtml: <FileHtml />,
  code: <Code />,
  card: <Card />,
  user2: <User2 />,
  zapier: <Zapier />,
  make: <Make />,
  webhook: <Webhook />,
  sandGlasses: <SandGlass />,
  chart1: <Chart1 />,
  chart2: <Chart2 />,
  chart3: <Chart3 />,
} as const

export type IIconsVector = keyof typeof icons

export default icons
