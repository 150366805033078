import { observer } from 'mobx-react-lite'
import { KeyboardEvent, useEffect } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { Button, Icon, Typography, Error, Tooltip, Alert, PhoneWithLabelInput } from 'shared/ui'
import { StripeCardInput } from 'shared/ui/StripeCardInput'
import { makeTextFieldAutoFocus, TextField, makeTextFieldHighlightLimit } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { Warning } from 'shared/ui/Warning'
import { MAX_LAST_NAME_LENGTH, MAX_FIRST_NAME_LENGTH } from 'shared/constants/auth'
import { RegisterStore } from 'widgets/Register'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterStore
}

export const Step4Form = observer(({ store }: IFormProps) => {
  const {
    step4Store: {
      onChangeCard,
      clearCardError,
      cardError,
      disabledSignUp,
      setFirstName,
      firstName,
      setLastName,
      lastName,
      isActiveFirstNameField,
      isActiveLastNameField,
      focusFirstNameField,
      focusLastNameField,
      blurFirstNameField,
      blurLastNameField,
      firstNameLength,
      lastNameLength,
      isMaxLastNameLength,
      isMaxFirstNameLength,
      showRetryAlert,
      setShowRetryError,
      phoneInputStore,
      openMaxMindModal,
      maxMindModalTrigger,
    },
    stepSignUpStore: { email },
    handleSignUp,
    setOnSubmit,
    loading,
  } = store

  const onKeyDownFirstName = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!!firstName && document.activeElement instanceof HTMLElement)
        document.activeElement.blur()
    }
  }

  const onKeyDownLastName = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!!lastName && document.activeElement instanceof HTMLElement) document.activeElement.blur()
    }
  }

  useEffect(() => {
    if (maxMindModalTrigger) openMaxMindModal(store)
  }, [maxMindModalTrigger])

  return (
    <div className={styles.wrap}>
      {showRetryAlert && (
        <Alert
          className={styles.retryAlert}
          item={{
            type: 'error',
            desc: 'There was an issue processing your request.',
          }}
          rightAction={
            <Button
              text={'Retry'}
              typeBtn={'outlined'}
              size={'small'}
              contained={'secondary'}
              onClick={() => {
                setShowRetryError(false)
                handleSignUp()
              }}
            />
          }
        />
      )}

      <Box display='flex' justifyContent='space-between' gap={'12px'}>
        <div className={styles.fieldWrap}>
          <Label
            marginTop={0}
            label={{
              text: 'First name',
              className: styles.inputLabel,
              rightText: isActiveFirstNameField ? (
                <Tooltip label={`Limit: ${MAX_FIRST_NAME_LENGTH} characters`} placement='top'>
                  <>{`${firstNameLength} of ${MAX_FIRST_NAME_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            className={classNames(styles.textField, {
              [styles.warning]: isMaxFirstNameLength,
            })}
            variant='stroke'
            size='medium'
            InputProps={{ placeholder: 'Enter first name', onKeyDown: onKeyDownFirstName }}
            onChange={setFirstName}
            onFocus={focusFirstNameField}
            onBlur={blurFirstNameField}
            disabled={loading}
            value={firstName}
            mainActions={[
              makeTextFieldAutoFocus({ withFocus: true }),
              makeTextFieldHighlightLimit({ limit: MAX_LAST_NAME_LENGTH }),
            ]}
          />
          <Warning
            text={
              isMaxFirstNameLength && isActiveFirstNameField
                ? `${MAX_FIRST_NAME_LENGTH}-character limit reached`
                : ''
            }
          />
        </div>
        <div className={styles.fieldWrap}>
          <Label
            marginTop={0}
            label={{
              text: 'Last name',
              className: styles.inputLabel,
              rightText: isActiveLastNameField ? (
                <Tooltip label={`Limit: ${MAX_FIRST_NAME_LENGTH} characters`} placement='top'>
                  <>{`${lastNameLength} of ${MAX_LAST_NAME_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            className={classNames(styles.textField, {
              [styles.warning]: isMaxLastNameLength,
            })}
            variant='stroke'
            size='medium'
            onFocus={focusLastNameField}
            onBlur={blurLastNameField}
            disabled={loading}
            InputProps={{ placeholder: 'Enter last name', onKeyDown: onKeyDownLastName }}
            onChange={setLastName}
            value={lastName}
            mainActions={[makeTextFieldHighlightLimit({ limit: MAX_LAST_NAME_LENGTH })]}
          />
          <Warning
            text={
              isMaxLastNameLength && isActiveLastNameField
                ? `${MAX_LAST_NAME_LENGTH}-character limit reached`
                : ''
            }
          />
        </div>
      </Box>

      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, styles.disabled)}
        variant='stroke'
        disabled={true}
        size='medium'
        value={email}
      />

      <PhoneWithLabelInput
        item={phoneInputStore.country}
        items={phoneInputStore.countries}
        loading={loading}
        number={phoneInputStore.number}
        numberError={phoneInputStore.numberError}
        isValidNumber={phoneInputStore.isValidNumber}
        numberRequestError={phoneInputStore.numberRequestError}
        setNumber={phoneInputStore.setNumber}
        setCountry={phoneInputStore.setCountry}
        triggerNumberValidation={phoneInputStore.triggerNumberValidation}
      />

      <Label
        label={{
          text: 'Credit card',
          className: styles.inputLabel,
          rightText: (
            <div className={styles.cardRightLabel}>
              <Icon icon={'lock'} color={'var(--green-70)'} fontSize={16} />
              <Typography ariaLabel={'cardRightLabel'} variant={'text-input-label-small'}>
                Safe & Secure Checkout
              </Typography>
            </div>
          ),
        }}
      />
      <StripeCardInput
        onChange={onChangeCard}
        setOnSubmit={setOnSubmit}
        onFocus={clearCardError}
        isError={!!cardError}
        disabled={loading}
      />
      <Error type={'text'} error={cardError} />

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={handleSignUp}
        disabled={disabledSignUp || loading}
        loading={loading}
        text={'Start my free trial'}
        contained={'primary'}
        fullWidth
      />
      <div className={styles.trialText}>
        <Typography variant={'body-sm-medium'} ariaLabel={'trialText'}>
          Try 14 days free, then just $49 per month. Cancel anytime.
        </Typography>
      </div>
    </div>
  )
})

export default Step4Form
