import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from 'shared/ui'
import { TitledRow } from 'shared/ui/TitledRow/TitledRow'
import { type IntegrationSalesforceSettingsTimelineStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsTimelineStore'

type IIntegrationSalesforceSettingsTimelineProps = {
  store: IntegrationSalesforceSettingsTimelineStore
}

export const IntegrationSalesforceSettingsTimeline: FC<IIntegrationSalesforceSettingsTimelineProps> =
  observer(({ store }) => {
    const { logActivity, onLogActivityToggle, dispose } = store

    useEffect(() => dispose, [])

    return (
      <TitledRow
        title={'Log activities in Salesforce'}
        label={'Create activity in Salesforce for every message, call, voicemail, note'}
      >
        <Switch size='middle' value={logActivity} onChange={onLogActivityToggle} />
      </TitledRow>
    )
  })
