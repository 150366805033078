import classNames from 'classnames'
import { PropsWithChildren, useRef } from 'react'
import { Icon, Tooltip } from 'shared/ui'

import styles from './styles.module.scss'

export interface IChatbotTooltipProps {
  tooltip: string
  className?: string
  fontSize?: number
}

export const ChatbotTooltip = ({ className, tooltip, fontSize }: IChatbotTooltipProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <Tooltip
      className={className}
      label={tooltip}
      placement='top'
      breakWord
      PopperProps={{ anchorEl: () => ref.current as HTMLElement }}
    >
      <span className={styles.chatbotTooltipLabel}>
        <Icon ref={ref} icon='question' fontSize={fontSize} />
      </span>
    </Tooltip>
  )
}

export const ChatbotTextTooltip = ({
  className,
  tooltip,
  children,
}: PropsWithChildren<IChatbotTooltipProps>) => {
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <Tooltip
      className={classNames(styles.chatbotTooltip, className)}
      sx={{ whiteSpace: 'pre-wrap' }}
      label={tooltip}
      placement='top'
      PopperProps={{
        anchorEl: () => ref.current as HTMLElement,
      }}
      breakWord
    >
      <span className={styles.chatbotTooltipLabel}>
        {children} <Icon ref={ref} icon='question' />
      </span>
    </Tooltip>
  )
}
