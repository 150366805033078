// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rkYBBA0GSUKy2a42VrGz{max-height:100%;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);z-index:3001}.rkYBBA0GSUKy2a42VrGz.VHeBFURQ447a96C7W_Iq{width:100%}.rkYBBA0GSUKy2a42VrGz video{width:100%}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/content/video/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CACA,QAAA,CACA,OAAA,CACA,+BAAA,CACA,YAAA,CACA,2CACE,UAAA,CAEF,4BACE,UAAA","sourcesContent":[".wrap{\n  max-height: 100%;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 3001;\n  &.isFull{\n    width: 100%;\n  }\n  video{\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rkYBBA0GSUKy2a42VrGz",
	"isFull": "VHeBFURQ447a96C7W_Iq"
};
export default ___CSS_LOADER_EXPORT___;
