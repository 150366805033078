import { observer } from 'mobx-react-lite'
import { ContainerHeader, Tabs } from 'shared/ui'
import { useNewOrganizationContext } from '../../context'
import { EnumTypeNewOrganization } from '../../store/newOrganizationStore'

import styles from './styles.module.scss'
import { NewOrganizationTab } from '../NewOrganizationTab/NewOrganizationTab'

export const NewOrganizationContent = observer(() => {
  const store = useNewOrganizationContext()
  return (
    <div className={styles.wrap}>
      <ContainerHeader
        title={'Organization'}
        paddingTop={30}
        paddingBottom={2}
        titleVariant={'text-input-label-small'}
      />
      <ContainerHeader
        title='New organization'
        paddingTop={32}
        paddingBottom={2}
        titleIconProps={{
          icon: 'building',
          fontSize: 20,
          tertiary: true,
        }}
      />

      <div className={styles.tabs}>
        <Tabs
          margin='0 0 16px 0'
          tabs={store.tabs}
          activeTabKey={store.type}
          handleSelectTab={store.selectTypeTab}
        />

        {store.type === EnumTypeNewOrganization.PaidOrganization && <NewOrganizationTab />}
        {store.type === EnumTypeNewOrganization.EnterpriseTrial && <NewOrganizationTab />}
      </div>
    </div>
  )
})
