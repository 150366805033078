import { makeAutoObservable, runInAction } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { PageLayoutStore } from 'shared/layout'
import { logger } from 'shared/lib'
import { PowerDialerApi } from 'entities/PowerDialer/api/powerDialer'
import { PowerDialer } from 'entities/PowerDialer/model/PowerDialer'
import { PowerDialerRoutes } from 'pages/powerDialer'

export class PowerDialerViewStore {
  private readonly _powerDialerId: number | null
  private _powerDialer: PowerDialer | null = null
  private _isLoading = true
  private _pageLayoutStore: PageLayoutStore

  constructor(powerDialerId: string | number | undefined, pageLayoutStore: PageLayoutStore) {
    this._powerDialerId = Number(powerDialerId)
    this._pageLayoutStore = pageLayoutStore

    makeAutoObservable(this)
  }

  init = async () => {
    try {
      if (!this._powerDialerId) return
      const {
        data: { data },
      } = await PowerDialerApi.getPowerDialerById(this._powerDialerId)
      this._powerDialer = new PowerDialer(data)
    } catch (e) {
      logger.error(e)
      uiStore.navigate && uiStore.navigate(`/${PowerDialerRoutes.root}`)
    } finally {
      runInAction(() => {
        this._isLoading = false
      })
    }
  }

  get isLoading() {
    return this._isLoading
  }

  get powerDialer() {
    return this._powerDialer
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  dispose() {}
}
