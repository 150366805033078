import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ContainerHeader, FullPageWrapper, SpinnerLoader } from 'shared/ui'
import { chatbotLayoutStore } from 'pages/chatbot'
import { useKnowledgeBaseListContext } from 'pages/chatbot/context/knowledgeBaseListContext'
import { KnowledgeBaseListTableHeader } from 'pages/chatbot/pages/knowledgeBaseList/ui/KnowledgeBaseListTable/KnowledgeBaseListTableHeader'
import { KnowledgeBaseListTable } from './KnowledgeBaseListTable/KnowledgeBaseListTable'
import { KnowledgeBaseListEmpty } from './KnowledgeBaseListEmpty'

export const KnowledgeBaseList = observer(() => {
  const { titleIconProps, handleToggleCollapse } = chatbotLayoutStore
  const { initialLoading, isEmpty, loadKnowledgeBaseList } = useKnowledgeBaseListContext()

  useEffect(() => {
    void loadKnowledgeBaseList()
  }, [])

  return (
    <FullPageWrapper>
      <ContainerHeader
        title={'AI knowledge base'}
        titleVariant={'heading-md'}
        titleIconProps={titleIconProps}
        titleIconAction={handleToggleCollapse}
        paddingLeft={20}
      />
      {initialLoading ? (
        <SpinnerLoader />
      ) : isEmpty ? (
        <KnowledgeBaseListEmpty />
      ) : (
        <>
          <KnowledgeBaseListTableHeader />
          <KnowledgeBaseListTable />
        </>
      )}
    </FullPageWrapper>
  )
})
