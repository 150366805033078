import { makeAutoObservable } from 'mobx'

const scales = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

const percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' })

export class ImageControllerStore {
  constructor() {
    makeAutoObservable(this)
  }

  scale = 1

  get scalePercent() {
    return percentFormatter.format(this.scale)
  }

  get isFirst() {
    return this.scale <= scales[0]
  }

  get isLast() {
    return this.scale >= scales[scales.length - 1]
  }

  onScalePlus = () => {
    const currentIndex = scales.findIndex((scale) => scale === this.scale)
    if (currentIndex === -1) {
      const plusIndex = scales.findIndex((scale) => scale > this.scale)
      this.scale = scales[plusIndex]
      return
    }
    if (currentIndex === scales.length - 1) {
      return
    }
    this.scale = scales[currentIndex + 1]
  }
  onScaleMinus = () => {
    const currentIndex = scales.findIndex((scale) => scale === this.scale)
    if (currentIndex === 0) {
      return
    }
    if (currentIndex === -1) {
      const plusIndex = scales.findIndex((scale) => scale > this.scale)
      this.scale = scales[plusIndex - 1]
      return
    }
    this.scale = scales[currentIndex - 1]
  }

  setScale = (scale: number) => {
    this.scale = scale
  }
}
