import React, { CSSProperties, useEffect, useState } from 'react'
import classnames from 'classnames'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export type ISwitchProps = {
  size?: 'small' | 'middle' | 'large'
  disabled?: boolean
  value?: boolean
  onChange?: (status: boolean) => void
  padding?: CSSProperties['padding']
  background?: CSSProperties['background']
  className?: string
  ariaLabel?: string
}

const Switch: React.FC<ISwitchProps> = ({
  size = 'small',
  disabled = false,
  value = false,
  onChange = () => {},
  padding,
  background,
  className,
  ariaLabel,
}) => {
  const [checked, setChecked] = useState(value)

  useEffect(() => {
    setChecked(value)
  }, [value])

  const onChecked = () => {
    const value = !checked

    if (disabled) return

    setChecked(value)
    onChange(value)
  }

  return (
    <div
      className={classnames(
        styles.wrap,
        styles[`size_${size}`],
        {
          [styles.disabled]: disabled,
          [styles.checked]: checked,
        },
        className
      )}
      style={{ padding }}
      onClick={onChecked}
      aria-label={getAriaLabel(ariaLabel || 'Switch')}
    >
      <div
        className={classnames(styles.switch)}
        style={{ background }}
        aria-label={getAriaLabel('Switch', 'wrap')}
      >
        <div
          className={classnames(styles.status, styles[`size_${size}`], {
            [styles.checked]: checked,
          })}
          aria-label={getAriaLabel('Switch', 'status')}
        />
      </div>
    </div>
  )
}

export { Switch }
