import { useState, FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import classnames from 'classnames'
import { TooltipEmailDomain } from 'shared/ui/Tooltip/ui/TooltipEmailDomain/TooltipEmailDomain'
import {
  Avatar,
  Button,
  Dropdown,
  DropdownContent,
  EmptyState,
  Icon,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { DatePickerTextField } from 'shared/ui/DatePicker'
import {
  InputText,
  InputNumeric,
  TextField,
  makeTextFieldActionTooltip,
  makeTextFieldInteractions,
  makeTextFieldClear,
  makeTextFieldTooltip,
  makeTextFieldBlurOnEnter,
  makeTextFieldAutoFocus,
} from 'shared/ui/TextField'
import { getLabelAsNumberInternationalFormat, removeSpacesFromNumericString } from 'shared/lib'
import { CustomFieldTypes, type IResponseCustomField } from 'entities/Contacts'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IUser, usersStore } from 'entities/Users'
import { userPermissionsStore, FeatureRestrictedModalStore } from 'entities/UsersPermissions'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { PhoneFieldSearch } from './PhoneFieldSearch'
import { ContactInfoFormStore, MainFiledTypes } from '../store/contactInfoFormStore'
import { OrganizationMember } from './OrganizationMember'
import styles from './styles.module.scss'

type IContactsInfoFormProps = {
  isNewContactMode: boolean
  store: ContactInfoFormStore
}

export const ContactsInfoForm: FC<IContactsInfoFormProps> = observer(
  ({ store, isNewContactMode }) => {
    const {
      isVariantCreateContactModal,
      setIsFirstEditMode,
      isFirstEditMode,
      selectedContact,
      hasCustomFields,
      getVisibleCustomFieldsList,
      mainFields,
      searchOwner,
      errors,
      updatingFields,
      hasMembers,
      membersList,
      membersCount,
      handleSaveOwner,
      clearSearchOwner,
      handleSearchOwner,
      handleSaveMainField,
      handleSaveCustomField,
      handleUpdateCustomField,
      handleUpdateCustomDateField,
      handleUpdateMainField,
      number,
      config,
      phoneFieldSearchStore,
    } = store
    const { toggleBlockView, isOpenBlock } = contactsDetailsGlobalStore
    const { pathname } = useLocation()
    const [firstEditInputNumber, setFirstEditInputNumber] = useState(0)
    const isContactPage = pathname.includes('contacts')
    const contact = selectedContact

    useEffect(() => {
      const contactId = config?.contactId

      if (contactId) {
        store.init(contactId)
      }

      return () => {
        store.dispose()
      }
    }, [])

    const checkFirstEditMode = (isEnterPressed: boolean, nextActiveInputNumber?: number) => {
      if (!nextActiveInputNumber || !isEnterPressed) {
        setIsFirstEditMode(false)

        return
      }

      setFirstEditInputNumber(nextActiveInputNumber)
    }

    const saveMainField = (field: MainFiledTypes, contact: Contact) => {
      handleSaveMainField(field, contact)
    }

    const customFieldIcon = (fieldType: CustomFieldTypes | undefined) => {
      switch (fieldType) {
        case CustomFieldTypes.date:
          return 'calendar'
        case CustomFieldTypes.number:
          return 'number'
        case CustomFieldTypes.url:
          return 'link'
        case CustomFieldTypes.text:
          return 'textInput'
        default:
          return 'textInput'
      }
    }

    const customFieldType = (fieldType: CustomFieldTypes | undefined) => {
      switch (fieldType) {
        case CustomFieldTypes.number:
          return InputNumeric
        default:
          return InputText
      }
    }

    const CustomFieldLabel: FC<{ customField: IResponseCustomField }> = ({ customField }) => (
      <span className={styles.label}>
        <div className={styles.icon}>
          <Icon icon={customFieldIcon(customField.type)} />
        </div>
        <Typography
          className={styles.name}
          variant='text-input-field-r'
          tag='div'
          ariaLabel={'name'}
        >
          {customField.name}
        </Typography>
      </span>
    )

    const isVisibleOnlyCustomFields = !isContactPage && !isVariantCreateContactModal

    if (!contact) return null

    return (
      <div className={styles.wrap}>
        {isNewContactMode && (
          <div className={styles.contactDetailsField}>
            <PhoneFieldSearch
              store={phoneFieldSearchStore}
              value={number}
              isVariantCreateContactModal={isVariantCreateContactModal}
              onChange={(value) => {
                handleUpdateMainField(MainFiledTypes.number, value)
              }}
            />
          </div>
        )}
        {!isNewContactMode && (
          <div className={styles.field}>
            <span className={styles.label}>
              <div className={styles.icon}>
                <Icon icon='phone' />
              </div>
              <Typography
                className={styles.name}
                variant='text-input-field-r'
                tag='div'
                ariaLabel={'Phone'}
              >
                Phone
              </Typography>
            </span>
            <TextField
              className={classnames(styles.value, {
                [styles.modalView]: isVariantCreateContactModal,
              })}
              InputProps={{
                placeholder: 'Phone',
              }}
              size='small'
              variant='outline'
              value={getLabelAsNumberInternationalFormat(number)}
              readOnly={updatingFields.has(MainFiledTypes.number)}
              disabled={updatingFields.has(MainFiledTypes.number)}
              onBlur={() => {
                saveMainField(MainFiledTypes.number, contact)
              }}
              onChange={(value) => {
                if (removeSpacesFromNumericString(value) === number) return

                const correctNumber = phoneFieldSearchStore.onChangeField(value, number)
                handleUpdateMainField(MainFiledTypes.number, correctNumber ?? '', true)
              }}
              onFocus={(_, current) => {
                if (!userPermissionsStore.getItem('can_create_contacts')) {
                  new FeatureRestrictedModalStore()
                  current?.blur()
                }
              }}
              rightActions={[
                makeTextFieldInteractions({
                  type: 'copy',
                  active: Boolean(mainFields.number),
                }),
                makeTextFieldClear(),
              ]}
              mainActions={[
                makeTextFieldBlurOnEnter(),
                makeTextFieldActionTooltip({
                  color: 'red',
                  placement: 'left',
                  active: errors.has('number'),
                  message: errors.get('number'),
                  onClick: () => errors.delete('number'),
                }),
              ]}
            />
          </div>
        )}

        <div className={styles.field}>
          <span
            className={classnames(styles.label, {
              [styles.disabled]: isNewContactMode,
            })}
          >
            <div className={styles.icon}>
              <Icon icon='user' />
            </div>
            <Typography
              className={styles.name}
              variant='text-input-field-r'
              tag='div'
              ariaLabel={'FirstName'}
            >
              First name
            </Typography>
          </span>
          <TextField
            className={classnames(styles.value, {
              [styles.modalView]: isVariantCreateContactModal,
            })}
            size='small'
            variant='outline'
            InputProps={{
              placeholder: 'First name',
              autoComplete: 'off',
            }}
            value={mainFields.first_name}
            disabled={updatingFields.has(MainFiledTypes.firstName)}
            onChange={(value) => handleUpdateMainField(MainFiledTypes.firstName, value, true)}
            onBlur={(_, { isTrusted }) => {
              checkFirstEditMode(!isTrusted, 1)
              saveMainField(MainFiledTypes.firstName, contact)
            }}
            rightActions={[
              makeTextFieldInteractions({ type: 'copy', active: Boolean(mainFields.first_name) }),
              makeTextFieldClear(),
            ]}
            mainActions={[
              makeTextFieldAutoFocus({ withFocus: isFirstEditMode && firstEditInputNumber === 0 }),
              makeTextFieldBlurOnEnter(),
              makeTextFieldTooltip(),
              makeTextFieldActionTooltip({
                color: 'red',
                placement: 'left',
                active: errors.has('first_name'),
                message: errors.get('first_name'),
                onClick: () => errors.delete('first_name'),
              }),
            ]}
          />
        </div>

        <div className={styles.field}>
          <span
            className={classnames(styles.label, {
              [styles.disabled]: isNewContactMode,
            })}
          >
            <div className={styles.icon}>
              <Icon icon='user' />
            </div>
            <Typography
              className={styles.name}
              variant='text-input-field-r'
              tag='div'
              ariaLabel={'LastName'}
            >
              Last name
            </Typography>
          </span>
          <TextField
            className={classnames(styles.value, {
              [styles.modalView]: isVariantCreateContactModal,
            })}
            size='small'
            variant='outline'
            InputProps={{
              placeholder: 'Last name',
              autoComplete: 'off',
            }}
            value={mainFields.last_name}
            disabled={updatingFields.has(MainFiledTypes.lastName)}
            onChange={(value) => handleUpdateMainField(MainFiledTypes.lastName, value, true)}
            onBlur={(_, { isTrusted }) => {
              checkFirstEditMode(!isTrusted, 2)
              saveMainField(MainFiledTypes.lastName, contact)
            }}
            rightActions={[
              makeTextFieldInteractions({ type: 'copy', active: Boolean(mainFields.last_name) }),
              makeTextFieldClear(),
            ]}
            mainActions={[
              makeTextFieldBlurOnEnter(),
              makeTextFieldAutoFocus({ withFocus: isFirstEditMode && firstEditInputNumber === 1 }),
              makeTextFieldTooltip(),
              makeTextFieldActionTooltip({
                color: 'red',
                placement: 'left',
                active: errors.has('last_name'),
                message: errors.get('last_name'),
                onClick: () => errors.delete('last_name'),
              }),
            ]}
          />
        </div>

        <div className={styles.field}>
          <span
            className={classnames(styles.label, {
              [styles.disabled]: isNewContactMode,
            })}
          >
            <div className={styles.icon}>
              <Icon icon='email' />
            </div>
            <Typography
              className={styles.name}
              variant='text-input-field-r'
              tag='div'
              ariaLabel={'Email'}
            >
              Email
            </Typography>
          </span>
          <TextField
            className={classnames(styles.value, {
              [styles.modalView]: isVariantCreateContactModal,
            })}
            size='small'
            variant='outline'
            InputProps={{
              placeholder: 'Email',
              autoComplete: 'off',
            }}
            value={mainFields.email}
            disabled={updatingFields.has(MainFiledTypes.email)}
            onChange={(value) => handleUpdateMainField(MainFiledTypes.email, value, true)}
            onBlur={(_, { isTrusted }) => {
              checkFirstEditMode(!isTrusted)
              saveMainField(MainFiledTypes.email, contact)
            }}
            rightActions={[
              makeTextFieldInteractions(
                { type: 'copy', active: Boolean(mainFields.email) },
                { type: 'email', active: Boolean(mainFields.email) }
              ),
              makeTextFieldClear(),
            ]}
            mainActions={[
              makeTextFieldBlurOnEnter(),
              makeTextFieldAutoFocus({ withFocus: isFirstEditMode && firstEditInputNumber === 2 }),
              makeTextFieldTooltip({
                label: <TooltipEmailDomain email={mainFields.email} />,
                interactive: true,
              }),
              makeTextFieldActionTooltip({
                color: 'red',
                placement: 'left',
                active: errors.has('email'),
                message: errors.get('email'),
                onClick: () => errors.delete('email'),
              }),
            ]}
          />
        </div>

        <div className={styles.field}>
          <span
            className={classnames(styles.label, {
              [styles.disabled]: isNewContactMode,
            })}
          >
            <div className={styles.icon}>
              <Icon icon='userFilled' />
            </div>
            <Typography
              className={styles.name}
              variant='text-input-field-r'
              tag='div'
              ariaLabel={'Owner'}
            >
              Owner
            </Typography>
          </span>

          <div
            className={classnames(styles.valueWrapper, {
              [styles.modalView]: isVariantCreateContactModal,
            })}
          >
            <Dropdown
              ariaLabel={'ContactsInfoForm'}
              width={192}
              margin={-32}
              placement={'bottom'}
              typeButton={'icon-button'}
              disabled={usersStore.user?.isViewOnlyRole}
              onClose={clearSearchOwner}
              triggerComponent={() => {
                if (updatingFields.has(MainFiledTypes.owner)) {
                  return (
                    <span className={classnames(styles.value)}>
                      <div className={styles.icon}>
                        <SpinnerLoader size={16} />
                      </div>
                      <Typography
                        className={styles.name}
                        variant='text-input-field-r'
                        pointer
                        ariaLabel={'Reassigning'}
                      >
                        Reassigning
                      </Typography>
                    </span>
                  )
                }

                if (!mainFields.owner) {
                  return (
                    <span
                      className={classnames(styles.value, styles.action, {
                        [styles.disabled]: isNewContactMode,
                      })}
                    >
                      <div className={styles.icon}>
                        <Icon icon='userFilled' />
                      </div>
                      <Typography
                        className={styles.name}
                        variant='text-input-field-r'
                        pointer
                        ariaLabel={'No owner'}
                      >
                        No owner
                      </Typography>
                    </span>
                  )
                }

                return (
                  <span className={classnames(styles.value, styles.action)}>
                    <div className={styles.icon}>
                      <Avatar info={mainFields.owner.avatarInfo} size={16} />
                    </div>
                    <Typography
                      className={styles.name}
                      variant='text-input-field-r'
                      ellipsis
                      pointer
                      ariaLabel={'name'}
                    >
                      {mainFields.owner.name}
                    </Typography>
                  </span>
                )
              }}
              customComponent={(onCloseMenu) => (
                <DropdownContent noPadding>
                  <div className={styles.dropdownWrapper}>
                    <div className={styles.searchWrapper}>
                      <TextField
                        size='small'
                        variant='integrated'
                        InputProps={{
                          placeholder: 'Search',
                        }}
                        value={searchOwner}
                        onChange={handleSearchOwner}
                        mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
                      />
                    </div>
                    <div
                      className={classnames(styles.membersWrapper, {
                        [styles.withScroll]: membersCount > 5,
                      })}
                    >
                      {hasMembers ? (
                        <>
                          {membersList.map((member: IUser) => (
                            <OrganizationMember
                              handleOwner={(ownerId) => {
                                onCloseMenu()
                                handleSaveOwner(ownerId, contact)
                              }}
                              active={contact.owner_id === member.id}
                              key={member.id}
                              member={member}
                            />
                          ))}
                        </>
                      ) : (
                        <EmptyState noIcon />
                      )}
                    </div>
                  </div>
                </DropdownContent>
              )}
            />
          </div>
        </div>

        {isOpenBlock('contactDetailsAllFields') &&
          getVisibleCustomFieldsList(isVisibleOnlyCustomFields).map((customField) => (
            <div className={styles.field} key={customField.field_key}>
              <CustomFieldLabel customField={customField} />
              {customField.type === CustomFieldTypes.date ? (
                <span
                  className={classnames(styles.value, {
                    [styles.modalView]: isVariantCreateContactModal,
                  })}
                >
                  <DatePickerTextField
                    popperPlacement='bottom-end'
                    placeholder={customField.name}
                    dateFormat='MMM d, yyyy'
                    date={customField.value?.value ? new Date(customField.value?.value) : null}
                    handleClose={() => handleSaveCustomField(customField, contact)}
                    handleDate={(value) => handleUpdateCustomDateField(customField, value)}
                    withCopy={Boolean(customField.value?.value)}
                    variant='outline'
                    size='small'
                  />
                </span>
              ) : (
                <TextField
                  className={classnames(styles.value, {
                    [styles.modalView]: isVariantCreateContactModal,
                  })}
                  size='small'
                  variant='outline'
                  Input={customFieldType(customField.type)}
                  InputProps={{
                    placeholder: customField.name,
                  }}
                  value={customField.value?.value || ''}
                  onChange={(value) => handleUpdateCustomField(customField, value, true)}
                  onBlur={() => {
                    handleSaveCustomField(customField, contact)
                  }}
                  readOnly={updatingFields.has(customField.field_key)}
                  rightActions={[
                    makeTextFieldInteractions(
                      { type: 'copy', active: Boolean(customField.value?.value) },
                      {
                        type: 'url',
                        active:
                          customField.type === CustomFieldTypes.url &&
                          Boolean(customField.value?.value),
                      }
                    ),
                    makeTextFieldClear(),
                  ]}
                  mainActions={[
                    makeTextFieldBlurOnEnter(),
                    makeTextFieldTooltip(),
                    makeTextFieldActionTooltip({
                      color: 'red',
                      placement: 'left',
                      active: errors.has(customField.field_key),
                      message: errors.get(customField.field_key),
                      onClick: () => errors.delete(customField.field_key),
                    }),
                  ]}
                />
              )}
            </div>
          ))}

        {hasCustomFields(isVisibleOnlyCustomFields) && (
          <div className={styles.showMore}>
            <Button
              size='small'
              typeBtn='text'
              text={isOpenBlock('contactDetailsAllFields') ? 'Hide fields' : 'See fields'}
              onClick={() => toggleBlockView('contactDetailsAllFields')}
            />
          </div>
        )}
      </div>
    )
  }
)
