import { createContext, useContext } from 'react'
import type { TriggerDetailStore } from 'widgets/TriggerDetail/store/triggerDetailStore'

export const TriggerDetailContext = createContext<TriggerDetailStore | null>(null)

export function useTriggerDetailContext() {
  const context = useContext(TriggerDetailContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with TriggerDetailContext')
  }
  return context
}
