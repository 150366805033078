import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Button, IColumn, IRow, Table } from 'shared/ui'
import { links } from 'shared/constants/links'
import { getCellForNameDefault } from 'shared/helpers/getCellForNameDefault'
import { callStore } from 'entities/Call'
import { DeviceSettings } from 'entities/DeviceSettings'
import { Contact } from 'entities/Contacts/model/Contact'
import { NumbersInboxesDropDown } from 'widgets/NumbersInboxesList'
import { getCellNumber } from 'widgets/ContactsTable/helpers/helpers'
import { type PowerDialerStore } from 'widgets/PowerDialer/store/powerDialerStore'
import styles from './styles.module.scss'

type IPowerDialerContactsModalContentProps = {
  powerDialerStore: PowerDialerStore
}

export const PowerDialerContactsModalContent: FC<IPowerDialerContactsModalContentProps> = observer(
  ({ powerDialerStore }) => {
    const rows = (): IRow<Contact>[] =>
      powerDialerStore.contacts.map((item) => {
        item.ariaLabel = 'ContactItemRow'
        item.actionsProps = {
          noVisibleActions: false,
          actions: [
            {
              iconButtonProps: {
                ariaLabel: 'PowerDialerClose',
                icon: 'close',
                variant: 'icon',
                size: 'small',
                color: 'tertiary',
                onClick: () => {
                  powerDialerStore.deleteSelectedId(item.id)
                },
              },
            },
          ],
        }

        return item
      })
    const columns: IColumn<Contact>[] = [
      {
        field: 'name',
        name: 'Name',
        width: '43%',
        renderRowCell: getCellForNameDefault,
      },
      {
        field: 'number',
        name: 'Number',
        renderRowCell: getCellNumber,
      },
    ]

    return (
      <div className={styles.wrap}>
        {powerDialerStore.showAlert && (
          <div className={styles.alert}>
            <Alert
              item={{
                type: 'infoBlue',
                desc: 'Salesmsg Power Dialer autodials numbers in sequence for faster calling.',
              }}
              action={
                <Button
                  typeBtn={'action'}
                  size={'small'}
                  contained={'primary'}
                  text={'Learn more'}
                  onClick={() => {
                    window.open(links.powerDialer, '_blank')
                  }}
                />
              }
              onClose={() => {
                powerDialerStore.onChangeShowAlert(false)
              }}
            />
          </div>
        )}

        <div className={styles.section}>
          <div className={styles.box}>
            <NumbersInboxesDropDown
              store={powerDialerStore.numbersInboxesListStore}
              onChange={(inboxId, numberId) => {
                powerDialerStore.setTeamId(inboxId)
                powerDialerStore.setNumberId(numberId)
              }}
            />
          </div>
          <div className={styles.box}>
            <DeviceSettings
              deviceSettingsStore={callStore.deviceSettingsStore}
              dropdownProps={{
                placement: 'bottom-end',
                width: 246,
                margin: 4,
                disabled: callStore.loading,
                ariaLabel: 'deviceSettings',
              }}
              variantDropdownBtn={'icon'}
              testOutputDevice={callStore.testOutputDevice}
            />
          </div>
        </div>

        <div className={styles.table}>
          <Table
            columns={columns}
            rows={rows()}
            noPagination
            isHoveredRowActions
            isCursor
            classNameScroll={styles.table__scroll}
          />
        </div>

        <div className={styles.wrapBtn}>
          <Button
            icon={'phone'}
            text={`Call ${powerDialerStore.count} contacts`}
            fullWidth
            disabled={powerDialerStore.disabled}
            onClick={powerDialerStore.handleCall}
          />
        </div>
      </div>
    )
  }
)
