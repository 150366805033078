import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { useTriggerListContext } from 'widgets/TriggerList/context/context'

export const DeleteAction = observer(() => {
  const { bulkDelete } = useTriggerListContext()

  return (
    <Button
      text='Delete'
      icon='delete'
      typeBtn='contained'
      contained='tertiary'
      size='small'
      onClick={bulkDelete}
    />
  )
})
