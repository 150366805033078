import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useGetStep, useLastPathSegment } from 'shared/hooks'
import { StepsCommonLayout } from 'pages/chatbot/ui/StepsCommonLayout/StepsCommonLayout'
import { useChatbotContext } from 'pages/chatbot/context/chatbotContext'
import { AgentBookAppointmentRoutesSteps, ChatbotRoutes } from '../route/type'

export const AgentBookAppointmentStepsLayout = observer(() => {
  const { agentBookAppointmentStepsStore, agentNavigationStore } = useChatbotContext()
  const { getSettingsStep, decreaseStep, getProgressInfoTitle } = agentBookAppointmentStepsStore
  const settingsStep = useGetStep<AgentBookAppointmentRoutesSteps>(getSettingsStep)
  const infoTitle = getProgressInfoTitle(useLastPathSegment() as ChatbotRoutes)

  useEffect(() => {
    agentNavigationStore.setCurrentStep(settingsStep)
  }, [settingsStep])

  return (
    <StepsCommonLayout
      progressTitle={infoTitle}
      totalSteps={4}
      settingsStep={settingsStep}
      decreaseStep={() => decreaseStep()}
    />
  )
})
