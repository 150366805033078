import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Tooltip } from 'shared/ui/Tooltip'
import { DropdownCardCustom } from './DropdownCardCustom/DropdownCardCustom'
import { DropdownCardDefault } from './DropdownCardDefault/DropdownCardDefault'
import { DropdownCardCheckbox } from './DropdownCardCheckbox/DropdownCardCheckbox'
import { DropdownCardCheckedRight } from './DropdownCardCheckedRight/DropdownCardCheckedRight'
import { DropdownCardDeletable } from './DropdownCardDeletable/DropdownCardDeletable'
import { DropdownCardCheckedLeft } from './DropdownCardCheckedLeft/DropdownCardCheckedLeft'
import styles from './../styles.module.scss'
import { EnumDropdownItemVariant, IDropdownItemProps } from '../types'

export const dropdownStyles = {
  icon: styles.icon,
  icon__disabled: styles.icon__disabled,
  title: styles.title,
  title__disabled: styles.title__disabled,
  tooltip: styles.tooltip,
}

export const DropdownCard: FC<IDropdownItemProps> = observer((props) => {
  const { item } = props
  const variant: EnumDropdownItemVariant = item.variant || EnumDropdownItemVariant.Default
  const renderDropdownCard = () => {
    return (
      <>
        {variant === EnumDropdownItemVariant.Default && <DropdownCardDefault {...props} />}
        {variant === EnumDropdownItemVariant.Checkbox && <DropdownCardCheckbox {...props} />}
        {variant === EnumDropdownItemVariant.CheckedLeft && <DropdownCardCheckedLeft {...props} />}
        {variant === EnumDropdownItemVariant.CheckedRight && (
          <DropdownCardCheckedRight {...props} />
        )}
        {variant === EnumDropdownItemVariant.Deletable && <DropdownCardDeletable {...props} />}
        {variant === EnumDropdownItemVariant.Custom && <DropdownCardCustom {...props} />}
      </>
    )
  }

  if (item.parentTooltipProps) {
    return <Tooltip {...item.parentTooltipProps}>{renderDropdownCard()}</Tooltip>
  }

  return renderDropdownCard()
})
