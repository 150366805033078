import { observer } from 'mobx-react-lite'
import { CallDialpad } from 'widgets/CallDialpad'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import styles from './styles.module.scss'

export const CallDialpadPopUpContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const { searchDialpad, handleDialpadSearch } = store

  return (
    <div className={styles.wrap}>
      <div className={styles.label}>{searchDialpad}</div>
      <div className={styles.content}>
        <CallDialpad
          variant={'small'}
          onChange={(item) => {
            handleDialpadSearch(item.value)
          }}
        />
      </div>
    </div>
  )
})
