// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E6Vcuv5imia3zHan4fia{margin-top:24px;padding-left:16px}.De4F6xD42oJWkKI7CeAP{margin-top:24px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/organizations/pages/OrganizationDetails/ui/Compliance/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACD,iBAAA,CAED,sBACE,eAAA","sourcesContent":[".wrap{\n  margin-top: 24px;\n padding-left: 16px;\n}\n.wrapTab{\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "E6Vcuv5imia3zHan4fia",
	"wrapTab": "De4F6xD42oJWkKI7CeAP"
};
export default ___CSS_LOADER_EXPORT___;
