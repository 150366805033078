import classNames from 'classnames'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { InputText } from '../Input/InputText'
import { IInputCommonProps, IInputTextProps } from '../Input/types'
import { ITextFieldProps } from '../types'

export const useControl = <InputProps extends IInputCommonProps = IInputTextProps>(
  props: ITextFieldProps<InputProps>
) => {
  const InputComponent = props.Input ?? InputText
  const inputProps = (props.InputProps ?? {}) as InputProps

  const inputRef = useRef<HTMLInputElement | null>(null)
  const containerRef = useRef<HTMLLabelElement | null>(null)

  const value = useMemo(() => props.value ?? '', [props.value])
  const error = useMemo(() => props.error ?? '', [props.error])
  const timedWarning = useMemo(() => props.timedWarning ?? false, [props.timedWarning])
  const timedError = useMemo(() => props.timedError ?? false, [props.timedError])
  const maxLength = useMemo(() => props.maxLength ?? null, [props.maxLength])

  const readOnly = useMemo(() => props.readOnly, [props.readOnly])
  const viewOnly = useMemo(() => props.viewOnly, [props.viewOnly])
  const disabled = useMemo(() => props.disabled, [props.disabled])
  const variant = useMemo(() => props.variant, [props.variant])
  const size = useMemo(() => props.size, [props.size])

  const [actionClassName, setActionClassName] = useState<Record<string, string>>({})
  const [actionClasses, setActionClasses] = useState<string>('')

  const control = {
    InputComponent,
    inputProps,

    inputRef,
    containerRef,
    value,
    actionClasses,
    error,
    timedWarning,
    timedError,
    maxLength,

    readOnly,
    viewOnly,
    disabled,
    variant,
    size,
  }

  const makeControl = (id: string) => {
    const setClassName = (newClassName: string) =>
      setActionClassName({
        ...actionClassName,
        [id]: newClassName,
      })

    const setValue = (value: string) => props.onChange?.(value)

    return {
      inputRef,
      containerRef,
      value,
      setValue,
      setClassName,
      error,
      timedWarning,
      timedError,
      maxLength,

      readOnly,
      viewOnly,
      disabled,
      variant,
      size,
    }
  }

  useLayoutEffect(() => {
    const actionClasses = classNames(Object.values(actionClassName))

    setActionClasses(actionClasses)
  }, [actionClassName])

  return { control, makeControl }
}
