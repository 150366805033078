import { useEventAttachmentUploadSuccessful } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { MessageFieldStore } from 'widgets/MessageField'
import { recentStore } from 'widgets/RecentlySent'

export const useMessageFiledEventAttachmentUploadSuccessful = (
  messageFieldStore: MessageFieldStore
) => {
  useEventAttachmentUploadSuccessful(({ attachment }) => {
    const attachmentMedia = new Attachment({ responseMedia: attachment })
    const attachmentUpload = new Attachment({ responseUpload: attachment })

    messageFieldStore.addAttachment(attachmentMedia, true)
    recentStore.addAttachmentRecent(attachmentUpload, true)
  })
}
