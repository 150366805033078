// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QVR4FquJP9NOLG1XoTQG a{display:flex;justify-content:center;padding:28px 0 24px;font-size:24px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Logo/Sidebar/styles.module.scss"],"names":[],"mappings":"AACE,wBACE,YAAA,CACA,sBAAA,CACA,mBAAA,CACA,cAAA","sourcesContent":[".wrap {\n  a {\n    display: flex;\n    justify-content: center;\n    padding: 28px 0 24px;\n    font-size: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "QVR4FquJP9NOLG1XoTQG"
};
export default ___CSS_LOADER_EXPORT___;
