import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import axios, { CancelTokenSource, isCancel } from 'axios'
import { groupBy } from 'lodash'
import { uiStore } from 'shared/store/uiStore'
import { IResponseMessage } from 'entities/Message'
import { attachmentStore } from 'entities/Attachment'
import { ContactsApi } from 'entities/Contacts'
import { usersStore } from 'entities/Users'
import { Activity } from 'entities/Activity/model/Activity'
import { Message } from 'entities/Message/model/Message'
import { userSettingsStore } from 'entities/Settings'
import { IConversationsHistoryItem } from 'entities/Contacts/api/types'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type ContactsDetailsStore } from 'widgets/ContactsDetails'

export class ConversationsHistoryMessagesStore {
  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)
  }

  per_page = 20
  page = 1

  itemsMap: Map<string | number, Message | Activity> = new Map()
  scrollBottomTrigger = ''

  loading = true
  loadingPrevious = false
  loadingMessages = false
  hasPrevious = true
  loadingBeforeId: string | number = 0
  handleReceiveNewScroll = false
  contact: Contact | null = null

  cancelTokenSource: CancelTokenSource | null = null

  get isShowActivity() {
    return userSettingsStore.isUserShowActivity
  }

  get conversationMessagesStore() {
    return this._contactsDetailsStore.conversationMessagesStore
  }

  get conversationId() {
    return this._contactsDetailsStore.conversation?.id
  }

  get allMessages() {
    const messages: Array<Message> = []
    Array.from(this.itemsMap.values()).forEach((item) => {
      if (item instanceof Message) {
        messages.push(item)
      }
    })
    return messages
  }
  get beforeId() {
    return this.items[0]?.id
  }
  get groupMessagesByDay() {
    return groupBy(this.items, 'day')
  }
  get items(): Array<Message | Activity> {
    return Array.from(this.itemsMap.values())
      .filter((message) => message instanceof Activity || !message.sending)
      .filter(
        (message) =>
          message instanceof Activity ||
          (message.type === 'call' ? message.record || message.body : true)
      )
      .sort((a, b) =>
        a.dateTimestamp === b.dateTimestamp
          ? Number(a.id) - Number(b.id)
          : a.dateTimestamp - b.dateTimestamp
      )
  }
  get isEmpty(): boolean {
    return this.items.length === 0
  }
  get messages(): Array<Message> {
    const messages: Array<Message> = []
    this.items.forEach((item) => {
      if (item instanceof Message) {
        messages.push(item)
      }
    })
    return messages
  }

  get loadingConversationsHistory() {
    return this.loadingMessages && this.isEmpty
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  reset = () => {
    this.loading = true
    this.loadingMessages = false
    this.page = 1
    this.loadingBeforeId = 0
    this.itemsMap.clear()
    this.contact = null
  }

  loadMessages = async (page?: number) => {
    try {
      if (this.loadingMessages || !this.contact?.id) return

      runInAction(() => {
        this.loadingMessages = true
        this.loading = true
        this.page = page || 1
      })

      this.initCancelTokenSource()

      const { data } = await ContactsApi.getConversationsHistoryByContactId(
        {
          contactId: +this.contact.id,
          page: this.page,
          per_page: this.per_page,
          with_activity_logs: this.isShowActivity ? 1 : 0,
        },
        {
          ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
        }
      )

      if (data) {
        runInAction(() => {
          this.setItems(data.data)
          this.hasPrevious = data.meta.total > this.allMessages.length
        })
      }

      runInAction(() => {
        this.loadingMessages = false
        this.loading = false
      })
    } catch (e) {
      console.error(e)

      if (isCancel(e)) {
        runInAction(() => {
          this.loadingMessages = true
          this.loading = true
        })
      } else {
        runInAction(() => {
          this.loadingMessages = false
          this.loading = false
        })
      }
    }
  }
  loadData = async () => {
    try {
      await this.loadMessages()
    } finally {
      this.setScrollBottomTrigger()
    }
  }
  async loadPreviousMessages(onSuccess: () => void) {
    if (this.loadingBeforeId === this.beforeId) return

    runInAction(() => {
      this.loadingBeforeId = this.beforeId
      this.loadingPrevious = true
    })

    try {
      await this.loadMessages(this.page + 1)

      onSuccess()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingPrevious = false
      })
    }
  }
  setItems = (items: Array<IConversationsHistoryItem>) => {
    const iItems = items.map((item) => {
      const teamName = item.team?.name || 'All inboxes'
      const teamId = item.team?.id || 0

      if (item.conversation_activity_log) {
        return new Activity(item.conversation_activity_log, {
          is_external:
            this.isContactsPage ||
            this.conversationId !== item.conversation_activity_log.conversation_id,
          teamName,
          teamId,
        })
      }

      return this.addItem(item.message, item.message.id, teamName, teamId)
    })

    let links: string[] = []

    try {
      iItems.forEach((item) => {
        if (item instanceof Message) {
          links = links.concat(item.links)
          this.itemsMap.set(item.client_id, item)
        } else {
          this.itemsMap.set(item.id, item)
        }
      })
    } catch (e) {
      console.error(e)
    }

    if (this.handleReceiveNewScroll) {
      this.setScrollBottomTrigger()
    }
    attachmentStore.checkPreviousAttachment(links)
  }
  addItem = (
    item: IResponseMessage,
    client_id: string | number,
    teamName: string,
    teamId: number
  ) => {
    const message = new Message({ ...item, client_id }, null, {
      is_external: this.isContactsPage || this.conversationId !== item.conversation_id,
      teamName,
      teamId,
    })

    if (Array.isArray(item.pending_mentions) && item.pending_mentions.length) {
      item.pending_mentions.forEach((item) => {
        usersStore.addItem(item)
      })
    }

    return message
  }

  get isContactsPage() {
    return uiStore.pathName.includes('contacts')
  }

  setScrollBottomTrigger = () => {
    this.scrollBottomTrigger = nanoid()
  }

  setContact = (contact: Contact) => {
    this.contact = contact
  }
}
