import { observer } from 'mobx-react-lite'
import { Icon, Typography } from 'shared/ui'
import { AudioPlayer } from 'shared/ui/AudioPlayer'
import { Attachment } from 'entities/Attachment/model/Attachment'
import styles from './styles.module.scss'

export const MessageRinglessPreview = observer(
  ({ attachment }: { attachment: Attachment | null }) => {
    return (
      <div className={styles.wrap}>
        <div className={styles.header}>
          <Typography
            ariaLabel={'Ringless'}
            variant={'body-md-medium'}
            className={styles.title}
            tag={'div'}
          >
            Ringless
          </Typography>
          <div className={styles.icon}>
            <Icon icon={'voicemail'} fontSize={12} />
          </div>
        </div>
        {attachment && !attachment.loading ? (
          <div className={styles.wrapPlayer}>
            <AudioPlayer url={attachment.source} fullWidth size={'small'} />
          </div>
        ) : (
          <Typography
            ariaLabel={'placeholder'}
            variant={'body-md-regular'}
            className={styles.placeholder}
            tag={'div'}
          >
            Add an audio
          </Typography>
        )}
      </div>
    )
  }
)
