import { makeAutoObservable, runInAction } from 'mobx'
import { Dayjs } from 'dayjs'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { showToast } from 'shared/ui'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'
import { AdminManageTrialsContent } from 'pages/admin/pages/organizations/modals/AdminManageTrials/AdminManageTrialsContent'
import { AdminManageTrialsAction } from 'pages/admin/pages/organizations/modals/AdminManageTrials/AdminManageTrialsAction'

class AdminManageTrialsStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''

  organization: IResponseAdminOrganizationById | null = null

  credits: number | null = null
  trialDateEnd: Dayjs | null = null

  onSuccess: (() => void) | null = null

  loadingSubmit = false

  get disabledSubmit() {
    return this.credits === null && this.trialDateEnd === null
  }

  reset = () => {
    this.modalId = ''
    this.organization = null
    this.credits = null
    this.trialDateEnd = null
  }

  setTrialDateEnd = (trialDateEnd: Date | null) => {
    this.trialDateEnd = trialDateEnd ? uiStore.dayjs(trialDateEnd) : null
  }

  setCredits = (credits: string | null) => {
    if (credits === '') {
      this.credits = null
      return
    }

    this.credits = Number(credits)
  }

  get currentCredits() {
    return this.organization?.credits.trial_credits || 0
  }

  get currentTrialEnds() {
    if (!this.endTrialDateString) {
      return uiStore.dayjs().format(DayjsFormats.full6)
    }

    return uiStore.dayjs(this.endTrialDateString).format(DayjsFormats.full6)
  }

  get endTrialDateString() {
    return this.organization?.stripe_data.subscription.renewal_date
  }

  onSubmit = async () => {
    try {
      const id = this.organization?.id

      if (!id) {
        return
      }
      runInAction(() => {
        this.loadingSubmit = true
      })

      await AdminOrganizationsApi.updateTrial(id, {
        trial_credits: this.credits || 0,
        trial_end_date:
          this.trialDateEnd?.utc().format(DayjsFormats.apiFormat2) ||
          (this.endTrialDateString as string),
      })

      showToast({
        type: 'success',
        title: 'Trial successfully applied!',
      })
      this.onSuccess && this.onSuccess()
      modalStore.closeModal(this.modalId)
    } catch (e) {
      console.error(e)
      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
    } finally {
      runInAction(() => {
        this.loadingSubmit = false
      })
    }
  }

  onOpenModal = (
    organization: IResponseAdminOrganizationById,
    onSuccess: typeof this.onSuccess
  ) => {
    this.reset()
    this.onSuccess = onSuccess
    this.organization = organization

    this.modalId = nanoid()
    modalStore.addModal({
      id: this.modalId,
      title: 'Manage trial',
      ModalContent: AdminManageTrialsContent,
      width: 328,
      ModalActions: AdminManageTrialsAction,
    })
  }
}

export const adminManageTrialsStore = new AdminManageTrialsStore()
