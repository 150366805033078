import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { FullPageWrapper, SpinnerLoader } from 'shared/ui'
import { useWorkflowListContext, WorkflowListProvider } from 'pages/workflows/context'

import { WorkflowHeader } from './WorkflowHeader'
import { WorkflowTable } from './WorkflowTable'
import { WorkflowEmpty } from './WorkflowEmpty'

const WorkflowList = observer(() => {
  const { initialLoading, isEmpty, loadData } = useWorkflowListContext()

  useEffect(() => void loadData(), [])

  return (
    <FullPageWrapper>
      <WorkflowHeader />
      {initialLoading ? <SpinnerLoader /> : isEmpty ? <WorkflowEmpty /> : <WorkflowTable />}
    </FullPageWrapper>
  )
})

export const WorkflowListPage = () => (
  <WorkflowListProvider>
    <WorkflowList />
  </WorkflowListProvider>
)
