import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Dropdown } from 'shared/ui'
import { savedRepliesSortItems } from 'entities/CannedMessages'
import { savedRepliesStore } from 'widgets/SavedReplies'
import styles from './styles.module.scss'

export const SavedRepliesOrder = observer(() => {
  const { handleSelectSort } = savedRepliesStore
  const [open, setOpen] = useState(false)

  return (
    <div className={styles.wrapOrder}>
      <Dropdown
        ariaLabel={'SavedRepliesOrder'}
        icon={'filter'}
        tooltipProps={{
          label: 'Sort by',
          placement: 'top',
        }}
        items={savedRepliesSortItems.map((item) => ({
          ...item,
          activeValue: savedRepliesStore.order === item.id,
        }))}
        typeButton={'button'}
        variantTriggerButton={'outlined'}
        onChange={handleSelectSort}
        placement={'bottom-start'}
        buttonProps={{
          text: savedRepliesSortItems.find((item) => item.id === savedRepliesStore.order)?.label,
          isRight: true,
          icon: open ? 'chevronUp' : 'chevronDown',
          typeBtn: 'text',
          size: 'medium',
          paddingInline: 8,
        }}
        onToggle={setOpen}
        sizeTriggerButton={'medium'}
      />
    </div>
  )
})
