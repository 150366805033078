import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallMicPopUpIncomingButton: React.FC = observer(() => {
  const store = useCallPopUpDefaultContext()
  const { isIncomingMute, handleIncomingMute, isIncomingMuteDisabled, innerRefTooltipCurrent } =
    store as CallPopUpDefaultStore

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'blue'}
        variant={'custom'}
        active={isIncomingMute}
        onClick={() => {
          handleIncomingMute()
        }}
        ariaLabel={`CallMicPopUpIncomingButton_${isIncomingMute ? 'unmute' : 'mute'}`}
        disabled={isIncomingMuteDisabled}
        iconProps={{
          icon: isIncomingMute ? 'volumeMute' : 'volumeMax',
        }}
        tooltipProps={{
          zIndex: 5001,
          label: isIncomingMute ? 'Unmute' : 'Mute',
          placement: 'top',
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
      />
    </div>
  )
})
