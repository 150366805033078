import React from 'react'
import { observer } from 'mobx-react-lite'
import { FullPageWrapper } from 'shared/ui'
import { TriggersHeader } from 'widgets/TriggerList/ui/TriggersHeader/TriggersHeader'
import { TriggerList } from 'widgets/TriggerList/ui/TriggerList'
import { useTriggersPageContext } from 'pages/triggers/context'

export const AllTriggers = observer(() => {
  const { pageLayoutStore, isTriggerActionShown, setIsTriggerActionShown } =
    useTriggersPageContext()

  return (
    <FullPageWrapper>
      <TriggersHeader
        title={'All triggers'}
        pageLayoutStore={pageLayoutStore}
        isTriggerActionShown={isTriggerActionShown}
      />
      <TriggerList
        storeProps={{
          type: 'all',
          setIsTriggerActionShown: setIsTriggerActionShown,
        }}
      />
    </FullPageWrapper>
  )
})
