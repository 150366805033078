import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import { IDeal } from 'entities/Integrations'
import { IDealsRowType } from './DealsTab'

import styles from '../styles.module.scss'

type IDealDataType = {
  id: string
  label: string
  value: string | number | null
}

type IDealsRowProps = {
  deal: IDeal
  rows: IDealsRowType[]
}

const DealsRow = ({ deal, rows }: IDealsRowProps) => {
  const rowsData = rows.reduce((data: IDealDataType[], type) => {
    if (type === 'amount') {
      data.push({
        id: type,
        label: 'Amount',
        value: deal?.amount?.value || null,
      })
    }

    if (type === 'value') {
      data.push({
        id: type,
        label: 'Value',
        value: deal?.amount?.value || null,
      })
    }

    if (type === 'closeDate') {
      data.push({
        id: type,
        label: 'Close date',
        value: deal?.closedate?.value || null,
      })
    }

    if (type === 'stage') {
      data.push({
        id: type,
        label: 'Stage',
        value: deal?.dealstage?.value || null,
      })
    }

    if (type === 'owner') {
      const name =
        deal?.owner?.value ||
        (deal?.owner?.first_name || deal?.owner?.last_name
          ? `${deal?.owner?.first_name} ${deal?.owner?.last_name}`
          : null)

      data.push({
        id: type,
        label: 'Owner',
        value: name,
      })
    }

    if (type === 'pipeline') {
      data.push({
        id: type,
        label: 'Pipeline',
        value: deal?.pipeline?.value || null,
      })
    }

    return data
  }, [])

  return (
    <div className={styles.dealWrapper}>
      {rowsData.map((item) => (
        <div className={styles.itemRow} key={item.id}>
          <div className={styles.label}>{item.label}</div>
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value != null ? String(item.value) : '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { DealsRow }
