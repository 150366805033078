import { Navigate, Route, Routes } from 'react-router-dom'
import React from 'react'
import { TriggerRouts } from 'entities/Trigger'
import { TriggersLayout } from 'pages/triggers/layout/TriggersLayout'
import { TriggersTableLayout } from 'pages/triggers/layout/TriggersTableLayout'
import { AllTriggers, SmsTriggers, RinglessTriggers } from 'pages/triggers/pages/triggers'
import { CreateTrigger } from 'pages/triggers/pages/createTrigger'

export const TriggersRouter = () => {
  return (
    <Routes>
      <Route element={<TriggersLayout />}>
        <Route element={<TriggersTableLayout />}>
          <Route path={TriggerRouts.all} element={<AllTriggers />} />
          <Route path={TriggerRouts.sms} element={<SmsTriggers />} />
          <Route path={TriggerRouts.ringless} element={<RinglessTriggers />} />
        </Route>
        <Route path={TriggerRouts.create} element={<CreateTrigger />} />
        <Route path={'*'} element={<Navigate to={TriggerRouts.all} />} />
      </Route>
    </Routes>
  )
}
