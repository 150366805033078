import { IMenuItemNavProps } from 'shared/ui/Menu/MenuItem/MenuItemNav/MenuItemNav'
import { EnumUserRoles } from 'entities/Users'
import { SettingsRoutesEnum } from '../route/type'

export const getWorkspaceAllItems = (
  setTitle: (title: string) => void,
  canManageMembers: boolean,
  canManageBillingSettings: boolean,
  canAddSubscription: boolean
): IMenuItemNavProps[] => {
  const items: IMenuItemNavProps[] = [
    {
      type: 'nav',
      to: SettingsRoutesEnum.general,
      label: 'General',
      icon: 'building2',
      onClick: () => setTitle('General'),
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.inboxes,
      label: 'Inboxes',
      icon: 'inboxOpen',
      onClick: () => setTitle('Inboxes'),
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.numbers,
      label: 'Numbers',
      icon: 'phone',
      onClick: () => setTitle('Numbers'),
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.teams,
      label: 'Teams',
      icon: 'user2',
      onClick: () => setTitle('Teams'),
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.integrations,
      label: 'Integrations',
      icon: 'plug',
      onClick: () => setTitle('Integrations'),
    },
    {
      type: 'nav',
      to: SettingsRoutesEnum.compliance,
      label: 'Compliance',
      icon: 'shield',
      onClick: () => setTitle('Business profile'),
    },
  ]

  if (canManageMembers) {
    items.splice(1, 0, {
      type: 'nav',
      to: SettingsRoutesEnum.members,
      label: 'Members',
      icon: 'users',
      onClick: () => setTitle('Members'),
    })
  }

  if (canManageBillingSettings) {
    items.push({
      type: 'nav',
      to: SettingsRoutesEnum.planAndBilling,
      label: 'Plan & Billing',
      icon: 'card',
      onClick: () => setTitle('Plan & Billing'),
    })
  }

  if (canAddSubscription) {
    items.push({
      type: 'nav',
      to: SettingsRoutesEnum.addSubscription,
      label: 'Add subscription',
      icon: 'card',
      onClick: () => setTitle('Add subscription'),
    })
  }

  return items
}

export const getProductAllItems = (setTitle: (title: string) => void): IMenuItemNavProps[] => [
  {
    type: 'nav',
    to: SettingsRoutesEnum.tags,
    label: 'Tags',
    icon: 'tag',
    onClick: () => setTitle('Tags'),
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.customFields,
    label: 'Custom Fields',
    icon: 'textInput2',
    onClick: () => setTitle('Custom Fields'),
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.urlShortener,
    label: 'URL Shortener',
    icon: 'link',
    onClick: () => setTitle('URL Shortener'),
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.websiteChatWidget,
    label: 'Website Chat Widget',
    icon: 'messageTextCircle02',
    onClick: () => setTitle('Website Chat Widget'),
  },
]

export const getPersonalAllItems = (setTitle: (title: string) => void): IMenuItemNavProps[] => [
  {
    type: 'nav',
    to: SettingsRoutesEnum.account,
    label: 'Account',
    icon: 'userFilled',
    onClick: () => setTitle('Account'),
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.referrals,
    label: 'Referrals',
    icon: 'dollar',
    onClick: () => setTitle('Referrals'),
  },
]

export const getDeveloperItems = (setTitle: (title: string) => void): IMenuItemNavProps[] => [
  {
    type: 'nav',
    to: SettingsRoutesEnum.oauthApplications,
    label: 'OAuth Applications',
    icon: 'container',
    onClick: () => setTitle('OAuth Applications'),
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.personalAccessTokens,
    label: 'Personal Access Tokens',
    icon: 'container',
    onClick: () => setTitle('Personal Access Tokens'),
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.publicApiTokens,
    label: 'Public API Tokens',
    icon: 'container',
    onClick: () => setTitle('Public API Tokens'),
  },
  {
    type: 'nav',
    to: SettingsRoutesEnum.authorizedApps,
    label: 'Authorized Apps',
    icon: 'container',
    onClick: () => setTitle('Authorized Apps'),
  },
]

export const workspaceRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.integrations],
  member: [SettingsRoutesEnum.inboxes, SettingsRoutesEnum.numbers, SettingsRoutesEnum.integrations],
  manager: [
    SettingsRoutesEnum.general,
    SettingsRoutesEnum.inboxes,
    SettingsRoutesEnum.numbers,
    SettingsRoutesEnum.integrations,
  ],
  admin: [],
  owner: [],
}

export const canceledWorkspaceRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.integrations],
  member: [SettingsRoutesEnum.inboxes, SettingsRoutesEnum.numbers, SettingsRoutesEnum.integrations],
  manager: [
    SettingsRoutesEnum.general,
    SettingsRoutesEnum.inboxes,
    SettingsRoutesEnum.numbers,
    SettingsRoutesEnum.integrations,
  ],
  admin: [
    SettingsRoutesEnum.general,
    SettingsRoutesEnum.compliance,
    SettingsRoutesEnum.addSubscription,
  ],
  owner: [
    SettingsRoutesEnum.general,
    SettingsRoutesEnum.compliance,
    SettingsRoutesEnum.addSubscription,
  ],
}

export const productRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  member: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  manager: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  admin: [],
  owner: [],
}

export const canceledProductRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  member: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  manager: [SettingsRoutesEnum.tags, SettingsRoutesEnum.customFields],
  admin: [SettingsRoutesEnum.urlShortener, SettingsRoutesEnum.websiteChatWidget],
  owner: [SettingsRoutesEnum.urlShortener, SettingsRoutesEnum.websiteChatWidget],
}

export const personalRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.account],
  member: [SettingsRoutesEnum.account],
  manager: [SettingsRoutesEnum.account],
  admin: [],
  owner: [],
}

export const canceledPersonalRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [SettingsRoutesEnum.account],
  member: [SettingsRoutesEnum.account],
  manager: [SettingsRoutesEnum.account],
  admin: [SettingsRoutesEnum.account, SettingsRoutesEnum.referrals],
  owner: [SettingsRoutesEnum.account, SettingsRoutesEnum.referrals],
}

export const developerRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [],
  member: [],
  manager: [],
  admin: [],
  owner: [],
}

export const canceledDeveloperRolesMap: Record<`${EnumUserRoles}`, string[]> = {
  view_only: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
  member: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
  manager: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
  admin: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
  owner: [
    SettingsRoutesEnum.oauthApplications,
    SettingsRoutesEnum.personalAccessTokens,
    SettingsRoutesEnum.publicApiTokens,
  ],
}

const reachItems = (items: IMenuItemNavProps[]) =>
  items.map((item) => ({
    ...item,
    to: `/${SettingsRoutesEnum.root}/${item.to}`,
    checkActive: (pathname: string) => pathname.includes(item.to),
  }))

export const getMenuItemsForRole = (
  role: EnumUserRoles,
  items: IMenuItemNavProps[],
  roleMapping: Record<EnumUserRoles, string[]>
): IMenuItemNavProps[] => {
  const allowedRoutes = roleMapping[role]

  const filteredItems = items.filter((item) => {
    return allowedRoutes.includes(item.to)
  })

  if (allowedRoutes.length === 0) return reachItems(items)

  return reachItems(filteredItems)
}
