import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown, IIconButtonProps, IDropdownProps, DropdownContent } from 'shared/ui'
import { DeviceSettingsContent, DeviceSettingsStore } from 'entities/DeviceSettings'
import styles from './styles.module.scss'

type IDeviceSettingsProps = {
  deviceSettingsStore: DeviceSettingsStore
  dropdownProps: IDropdownProps
  variantDropdownBtn?: IIconButtonProps['variant']
  testOutputDevice?: () => void
  iconButtonProps?: IIconButtonProps
}

export const DeviceSettings: FC<IDeviceSettingsProps> = observer(
  ({
    iconButtonProps,
    deviceSettingsStore,
    dropdownProps,
    variantDropdownBtn,
    testOutputDevice,
  }) => {
    return (
      <div className={styles.wrap}>
        <Dropdown
          iconButtonProps={{
            icon: 'settings2',
            size: 'medium',
            variant: variantDropdownBtn,
            ariaLabel: 'DeviceSettings_actionButton',
            ...iconButtonProps,
          }}
          tooltipProps={{
            label: 'Settings',
            placement: 'top',
          }}
          customComponent={(onClose, dropdownId) => (
            <DropdownContent>
              <DeviceSettingsContent
                parentsDropdownId={[dropdownId]}
                deviceSettingsStore={deviceSettingsStore}
                testOutputDevice={testOutputDevice}
              />
            </DropdownContent>
          )}
          {...dropdownProps}
        />
      </div>
    )
  }
)
