import { InputPhone } from 'shared/ui/TextField'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import { newOrganizationForm } from '../../models/newOrganizationForm'
import { INewOrganizationForm } from '../../type'

type FieldConfig = {
  label: string
  value: keyof INewOrganizationForm
  onChange: (value: string) => void
  placeholder: string
  inputType?: typeof InputPhone | typeof InputPassword
}

export const generalFields: FieldConfig[] = [
  {
    label: 'First name',
    value: 'name',
    onChange: newOrganizationForm.setName,
    placeholder: 'Enter name',
  },
  {
    label: 'Last name',
    value: 'lastName',
    onChange: newOrganizationForm.setLastName,
    placeholder: 'Enter name',
  },
  {
    label: 'Organization name',
    value: 'organizationName',
    onChange: newOrganizationForm.setOrganizationName,
    placeholder: 'Enter organization name',
  },
  {
    label: 'Email',
    value: 'email',
    onChange: newOrganizationForm.setEmail,
    placeholder: 'Enter email',
  },
  {
    label: 'Phone',
    value: 'phone',
    onChange: newOrganizationForm.setPhone,
    placeholder: '(123) 123-1234',
    inputType: InputPhone,
  },
  {
    label: 'Password',
    value: 'password',
    onChange: newOrganizationForm.setPassword,
    placeholder: '7+ characters',
    inputType: InputPassword,
  },
]

export const valueFormatter = (value: string) => {
  if (value === 'phone') {
    return 'number'
  }

  if (value === 'name') {
    return 'organization_name'
  }

  return value
}
