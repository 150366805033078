import { makeAutoObservable, reaction, runInAction, IReactionDisposer } from 'mobx'
import axios, { CancelTokenSource, CanceledError, AxiosResponse, AxiosError } from 'axios'
import { nanoid } from 'nanoid'
import { AxiosOptions } from 'shared/api'
import { toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { IResponseContact } from 'entities/Contacts/api/types'
import {
  IParamsConversationsBatchQueueClose,
  IParamsConversationsBatchQueueFavorite,
  IParamsConversationsBatchQueueOpen,
  IParamsConversationsBatchQueueRead,
  IParamsConversationsBatchQueueUnread,
  IParamsConversationsList,
  IResponseConversation,
  IResponseConversations,
} from 'entities/Conversation/api/types'
import { IResponseUser } from 'entities/Users/api/types'
import { filtersStore } from 'features/DropdownFilter'
import { ordersStore } from 'features/DropdownOrder'
import { contactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { ConversationSearchStore } from 'widgets/ConversationHeaderSearch'
import { ConversationAssignDropdownStore } from 'widgets/ConversationAssignDropdown'
import { IMarkAsNeedResponseParams } from 'widgets/ConversationList/store/types'

export class ConversationListStore {
  private _itemsOrder: number[] = []
  private _topItemsOrder: number[] = []
  private _loading = true
  private _loadingConversations = true
  private _loadingMore = false

  private _hasMore = false
  private _offset = 0
  private _limit = 25
  private _scrollPosition = 0
  private _scrollTrigger = nanoid()

  private _visibleUI = false
  private _firstLoading = true
  private _cancelTokenSourceConversation: CancelTokenSource | null = null
  private _cancelTokenSourceConversations: CancelTokenSource | null = null
  private _disposeInbox: IReactionDisposer | null = null
  private _disposeConversationNew: IReactionDisposer | null = null
  private _conversationAssignDropdownStore = new ConversationAssignDropdownStore(this)

  constructor(private _conversationSearchStore: ConversationSearchStore) {
    makeAutoObservable(this)
  }

  get hasSelected() {
    return !!this.selectedCount
  }

  get listCount() {
    return this.listOrders.length
  }

  get selectedCount() {
    return this.listOrders.filter((item) => item.checked).length
  }

  get isSelectedAll() {
    return this.selectedCount === this.listCount
  }

  get isSelectedNeedResponse() {
    return filtersStore.filter?.key === 'pending'
  }

  get listOrders() {
    return [...this._topItemsOrder, ...this._itemsOrder].reduce<Conversation[]>((state, id) => {
      const item = conversationStore.getItem(id)

      return item ? [...state, item] : state
    }, [])
  }

  get isEmpty() {
    return this.listOrders.length === 0
  }

  get firstConversationId() {
    return this.listOrders?.[0]?.id || null
  }

  get autoSelectConversationId() {
    const conversation = this.listOrders.find((item) => item.id === conversationStore.prevItemId)

    if (conversation) return conversation.id

    return this.firstConversationId
  }

  get selectedList() {
    return this.listOrders.filter((item) => item.checked)
  }

  get selectedListIds() {
    return this.listOrders.filter((item) => item.checked).map((item) => item.id)
  }

  get selectedContactsListIds() {
    const contactIdsMap = new Map<number, number>()

    this.selectedList.map((conversation) => {
      contactIdsMap.set(conversation.contact_id, conversation.contact_id)

      conversation.participantsIds.map((contactId) => {
        contactIdsMap.set(contactId, contactId)
      })
    })

    return Array.from(contactIdsMap.values())
  }

  get selectedHasOpenConversation() {
    let status = false

    this.selectedList.forEach((item) => {
      if (!item.isClosed) return (status = true)
    })

    return status
  }

  get selectedHasReadConversation() {
    return Boolean(this.selectedList.find((item) => item.isRead))
  }

  get selectedHasUnfavoriteConversation() {
    let status = false

    this.selectedList.forEach((item) => {
      if (!item.is_favorite) return (status = true)
    })

    return status
  }

  get scrollTrigger() {
    return this._scrollTrigger
  }

  get scrollPosition() {
    return this._scrollPosition
  }

  handleScrollPosition = (value: number) => {
    this._scrollPosition = value
  }

  handleScrollTrigger = () => {
    this._scrollTrigger = nanoid()
  }

  handleResetSelect = () => {
    this.selectedList.forEach((item) => {
      item.handleChecked(false)
    })
  }

  handleSelectAll = () => {
    this.listOrders.forEach((item) => {
      item.handleChecked(true)
    })
  }

  resetCancelTokenConversation = () => {
    this._cancelTokenSourceConversation?.cancel()
  }

  initCancelTokenConversation = () => {
    this.resetCancelTokenConversation()
    this._cancelTokenSourceConversation = axios.CancelToken.source()
  }

  resetCancelTokenConversations = () => {
    this._cancelTokenSourceConversations?.cancel()
  }

  initCancelTokenConversations = () => {
    this.resetCancelTokenConversations()
    this._cancelTokenSourceConversations = axios.CancelToken.source()
  }

  reset = () => {
    this._topItemsOrder = []
    this._itemsOrder = []
    this._loadingConversations = false
    this._loading = false
    this._loadingMore = false
    this._hasMore = false
    this._offset = 0
    this.resetCancelTokenConversation()
    this.resetCancelTokenConversations()
    this.handleResetSelect()

    if (this._visibleUI) {
      uiStore.changeRoute({
        path: '/conversations',
      })
      conversationStore.setCurrentItemId(null)
    }
    conversationStore.reset()
    conversationStore.resetCurrentItem()
  }

  resetReactions = () => {
    this._disposeInbox?.()
    this._disposeConversationNew?.()
  }

  handleChangeFirstLoading = (status: boolean) => {
    this._firstLoading = status
  }

  handleChangeInbox = () => {
    if (this._firstLoading) {
      this._loading = false
      this.load()
      this._firstLoading = false
    } else {
      if (this._visibleUI && !conversationStore.modeNotOpen) {
        uiStore.changeRoute({
          path: '/conversations',
        })
        conversationStore.setCurrentItemId(null)
      }

      this.reset()
      this.load()
    }
  }

  selectNewInbox = async (conversation?: Conversation) => {
    if (inboxesStore.currentInbox?.type === 'unified-inbox') return
    if (!conversation) return
    if (!inboxesStore.currentInboxId) return
    if (conversation.inbox_id === inboxesStore.currentInboxId) return

    runInAction(() => {
      this._firstLoading = true
    })

    await inboxesStore.handleUpdateTeamInbox(conversation.inbox_id)

    return true
  }

  handleChangeVisibleUI = (status: boolean) => {
    this._visibleUI = status
  }

  load = async () => {
    runInAction(() => {
      this._loadingConversations = true
    })

    try {
      const conversationResult = conversationStore.currentItem || (await this.loadConversation())

      const isChangeInbox = await this.selectNewInbox(conversationResult)

      if (isChangeInbox) return

      const conversationsResult = await this.loadConversations()

      this.applyConversations(conversationsResult)

      if (conversationResult) {
        this.applyConversation(conversationResult)
      }
    } catch (error) {
      this.handleConversationLoadError()
    }

    if (this._visibleUI && !conversationStore.modeNotOpen) {
      const { search: query } = window.location

      if (this.listOrders.length) {
        const onloadQuery = new URLSearchParams(window.location.search)

        if (!onloadQuery.has('decline_onload')) {
          onloadQuery.set('onload', '')
        }

        onloadQuery.delete('decline_onload')

        const onloadQueryString = `?${onloadQuery.toString()}`

        if (!conversationStore.currentItemId) {
          uiStore.changeRoute({
            path: `/conversations/${this.listOrders[0]?.id}`,
            query: onloadQueryString,
          })
        } else {
          uiStore.changeRoute({
            path: `/conversations/${conversationStore.currentItemId}`,
            query: onloadQueryString,
          })
        }
      } else {
        uiStore.changeRoute({
          path: '/conversations',
          query,
        })
      }
    }
    setTimeout(() => {
      runInAction(() => {
        this._loadingConversations = false
      })
    }, 100)
  }

  handleConversationLoadError = () => {
    if (this._visibleUI && !conversationStore.modeNotOpen) {
      uiStore.changeRoute({
        path: '/conversations',
      })
    }
  }

  loadConversation = async () => {
    if (!conversationStore.currentItemId) return

    this.initCancelTokenConversation()

    try {
      return await conversationStore.getById({
        id: conversationStore.currentItemId,
        update: true,
        isModalError: true,
        ...(this._cancelTokenSourceConversation
          ? { cancelToken: this._cancelTokenSourceConversation.token }
          : null),
      })
    } catch (error) {
      console.error(error)

      throw error
    }
  }

  checkFilter = (conversation?: Conversation) => {
    if (!conversation) return false

    const item = conversation
    const message = conversation.recent_message || undefined

    return !filtersStore.isShowConversation(item, message)
  }

  applyConversation = async (conversation: Conversation) => {
    if (this.checkFilter(conversation)) return

    try {
      this.addItemOrder(conversation.id, true)
      this.updateItemOrder(conversation.id)
    } catch (error) {
      this.handleConversationLoadError()
    }
  }

  loadConversations = async () => {
    if (this._hasMore) this._loadingMore = true
    this._loading = true

    this.initCancelTokenConversations()
    await filtersStore.loadFilters()

    const params: IParamsConversationsList = {
      team_id: inboxesStore.currentInboxTypeId || 0,
      team_type: inboxesStore.currentInboxType || 3,
      filter: filtersStore.filter?.key,
      limit: this._limit,
      offset: this._offset,
      sort_by:
        filtersStore.filter?.key === 'outbound' ? 'last_message_at' : 'last_manual_message_at',
      grouped_response: true,
      order: ordersStore.order?.key,
    }

    if (conversationStore.currentItem) {
      params.exclude = conversationStore.currentItemId
    }

    const options: AxiosOptions = {}

    if (this._cancelTokenSourceConversations) {
      options.cancelToken = this._cancelTokenSourceConversations.token
    }

    try {
      const { data } = await ConversationsApi.getConversations(params, options)

      runInAction(() => {
        this._loading = false
        this._loadingMore = false
      })

      return data
    } catch (error) {
      console.error(error)

      if (!(error instanceof CanceledError))
        runInAction(() => {
          this._loading = false
          this._loadingMore = false
        })

      throw error
    }
  }

  applyConversations = async (response: IResponseConversations | undefined) => {
    if (!response) return

    const contacts = Object.values(response.contacts)
    const owners = Object.values(response.owners)

    conversationStore.addItems(response.conversations)
    usersStore.addItems(owners)
    contactsStore.addItems(contacts)
    this.addItemsOrder(response.conversations)

    this._hasMore = response.conversations.length === this._limit

    if (this._hasMore) {
      this._offset = this._offset + this._limit
    }
  }

  updateConversations = async () => {
    try {
      const conversations = await this.loadConversations()
      this.applyConversations(conversations)
    } catch (error) {
      this.handleConversationLoadError()
    }
  }

  undoCloseConversations = async () => {
    const nanoId = nanoid()
    const closeConversationsTimeoutId = setTimeout(async () => {
      this.closeConversations()
      toastStore.remove(nanoId)
    }, 5000)

    toastStore.add({
      id: nanoId,
      title: 'Conversations closed',
      type: 'info',
      action: {
        text: 'Undo',
        onAction: () => {
          clearTimeout(closeConversationsTimeoutId)
          toastStore.remove(nanoId)
        },
      },
    })
  }

  closeConversations = async () => {
    const selectedIds = this.selectedListIds
    const itemsPast = this.listOrders

    try {
      const requestsParamsMap: Map<number, IParamsConversationsBatchQueueClose> = new Map()

      selectedIds.forEach((conversationId) => {
        const conversation = conversationStore.getItem(conversationId)
        if (!conversation) return

        const requestParams = requestsParamsMap.get(conversation.inbox_id)
        const ids = requestParams ? [...requestParams.ids, conversationId] : [conversationId]
        const teamId = requestParams ? requestParams.team_id : conversation.inbox_id
        const params: IParamsConversationsBatchQueueClose = {
          team_id: teamId,
          ids: ids,
          delete_all: this.isSelectedAll,
          conversation_filter: filtersStore.filter.key,
        }

        requestsParamsMap.set(conversation.inbox_id, params)
        conversationStore.deleteItem(conversationId)
      })

      const requestsParamsList = Array.from(requestsParamsMap.values())
      const requests: Promise<AxiosResponse>[] = [
        ...requestsParamsList.map((params) =>
          ConversationsApi.updateConversationsBatchQueueClose(params)
        ),
      ]

      if (this.isSelectedAll || !this.listCount) {
        conversationStore.setCurrentItemId(null)
      }

      this.setFocusItem(selectedIds, itemsPast)
      this.handleResetSelect()

      await Promise.all(requests)
    } catch (e) {
      console.error(e)
    } finally {
      if (!this.isSelectedAll && !this.listCount) {
        await this.updateConversations()
      }
    }
  }

  reopenConversations = async () => {
    const selectedIds = this.selectedListIds
    const itemsPast = this.listOrders

    try {
      const requestsParamsMap: Map<number, IParamsConversationsBatchQueueOpen> = new Map()

      selectedIds.forEach((conversationId) => {
        const conversation = conversationStore.getItem(conversationId)
        if (!conversation) return

        const requestParams = requestsParamsMap.get(conversation.inbox_id)
        const ids = requestParams ? [...requestParams.ids, conversationId] : [conversationId]
        const teamId = requestParams ? requestParams.team_id : conversation.inbox_id
        const params: IParamsConversationsBatchQueueOpen = {
          team_id: teamId,
          ids: ids,
          open_all: this.isSelectedAll,
          conversation_filter: filtersStore.filter.key,
        }

        requestsParamsMap.set(conversation.inbox_id, params)
        conversationStore.deleteItem(conversationId)
      })

      const requestsParamsList = Array.from(requestsParamsMap.values())
      const requests: Promise<AxiosResponse>[] = [
        ...requestsParamsList.map((params) =>
          ConversationsApi.updateConversationsBatchQueueOpen(params)
        ),
      ]

      if (this.isSelectedAll || !this.listCount) {
        conversationStore.setCurrentItemId(null)
      }

      this.setFocusItem(selectedIds, itemsPast)
      this.handleResetSelect()

      await Promise.all(requests)
    } catch (e) {
      console.error(e)
    } finally {
      if (!this.isSelectedAll && !this.listCount) {
        await this.updateConversations()
      }
    }
  }

  readConversations = async (id?: number) => {
    try {
      if (!id) {
        const requestsParamsMap: Map<number, IParamsConversationsBatchQueueRead> = new Map()

        this.selectedListIds.forEach((conversationId) => {
          const conversation = conversationStore.getItem(conversationId)
          if (!conversation) return

          const requestParams = requestsParamsMap.get(conversation.inbox_id)

          if (requestParams) {
            const ids = [...requestParams.ids, conversationId]
            const params: IParamsConversationsBatchQueueRead = {
              team_id: requestParams.team_id,
              ids: ids,
              read_all: this.isSelectedAll,
              conversation_filter: filtersStore.filter?.key,
            }

            requestsParamsMap.set(conversation.inbox_id, params)
            if (filtersStore.isUnread) {
              ids.forEach((id) => this.deleteItemOrder(id))
            }
          } else {
            const params: IParamsConversationsBatchQueueRead = {
              team_id: conversation.inbox_id,
              ids: [conversationId],
              read_all: this.isSelectedAll,
              conversation_filter: filtersStore.filter?.key,
            }

            requestsParamsMap.set(conversation.inbox_id, params)
            if (filtersStore.isUnread) {
              this.deleteItemOrder(conversationId)
            }
          }
        })

        const requestsParamsList = Array.from(requestsParamsMap.values())
        const requests: Promise<AxiosResponse>[] = [
          ...requestsParamsList.map((params) =>
            ConversationsApi.updateConversationsBatchQueueRead(params)
          ),
        ]

        Promise.all(requests)
      } else {
        const conversation = conversationStore.getItem(id)
        if (!conversation) return

        const params: IParamsConversationsBatchQueueRead = {
          team_id: conversation.inbox_id,
          read_all: this.isSelectedAll,
          conversation_filter: filtersStore.filter?.key,
          ids: [id],
        }

        await ConversationsApi.updateConversationsBatchQueueRead(params)
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        if (!id) this.handleResetSelect()
      })
    }
  }

  unreadConversations = async (id?: number) => {
    try {
      if (!id) {
        const requestsParamsMap: Map<number, IParamsConversationsBatchQueueUnread> = new Map()

        this.selectedListIds.forEach((conversationId) => {
          const conversation = conversationStore.getItem(conversationId)
          if (!conversation) return

          const requestParams = requestsParamsMap.get(conversation.inbox_id)

          if (requestParams) {
            const params: IParamsConversationsBatchQueueUnread = {
              team_id: requestParams.team_id,
              ids: [...requestParams.ids, conversationId],
              unread_all: false,
              conversation_filter: filtersStore.filter?.key,
            }

            requestsParamsMap.set(conversation.inbox_id, params)
          } else {
            const params: IParamsConversationsBatchQueueUnread = {
              team_id: conversation.inbox_id,
              ids: [conversationId],
              unread_all: false,
              conversation_filter: filtersStore.filter?.key,
            }

            requestsParamsMap.set(conversation.inbox_id, params)
          }
        })

        const requestsParamsList = Array.from(requestsParamsMap.values())
        const requests: Promise<AxiosResponse>[] = [
          ...requestsParamsList.map((params) =>
            ConversationsApi.updateConversationsBatchQueueUnread(params)
          ),
        ]

        Promise.all(requests)
      } else {
        const conversation = conversationStore.getItem(id)
        if (!conversation) return

        const params: IParamsConversationsBatchQueueUnread = {
          team_id: conversation.inbox_id,
          unread_all: false,
          ids: [id],
          conversation_filter: filtersStore.filter?.key,
        }

        await ConversationsApi.updateConversationsBatchQueueUnread(params)
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        if (!id) this.handleResetSelect()
      })
    }
  }

  updateConversationsFavorite = async (status?: boolean, id?: number) => {
    const hasStatus = typeof status === 'boolean'

    try {
      if (!id) {
        const requestsParamsMap: Map<number, IParamsConversationsBatchQueueFavorite> = new Map()

        this.selectedListIds.forEach((conversationId) => {
          const conversation = conversationStore.getItem(conversationId)
          if (!conversation) return

          const requestParams = requestsParamsMap.get(conversation.inbox_id)

          if (requestParams) {
            const params: IParamsConversationsBatchQueueFavorite = {
              team_id: requestParams.team_id,
              favorite: requestParams.favorite,
              ids: [...requestParams.ids, conversationId],
              all: this.isSelectedAll,
              conversation_filter: filtersStore.filter?.key,
            }

            requestsParamsMap.set(conversation.inbox_id, params)
          } else {
            const params: IParamsConversationsBatchQueueFavorite = {
              team_id: conversation.inbox_id,
              favorite: hasStatus ? status : this.selectedHasUnfavoriteConversation,
              ids: [conversationId],
              all: this.isSelectedAll,
              conversation_filter: filtersStore.filter?.key,
            }

            requestsParamsMap.set(conversation.inbox_id, params)
          }
        })

        const requestsParamsList = Array.from(requestsParamsMap.values())
        const requests: Promise<AxiosResponse>[] = [
          ...requestsParamsList.map((params) =>
            ConversationsApi.updateConversationsBatchQueueFavorite(params)
          ),
        ]

        Promise.all(requests)
      } else {
        const conversation = conversationStore.getItem(id)
        if (!conversation) return

        const params: IParamsConversationsBatchQueueFavorite = {
          team_id: conversation.inbox_id,
          favorite: hasStatus ? status : this.selectedHasUnfavoriteConversation,
          all: this.isSelectedAll,
          ids: [id],
          conversation_filter: filtersStore.filter?.key,
        }

        await ConversationsApi.updateConversationsBatchQueueFavorite(params)
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        if (!id) this.handleResetSelect()
      })
    }
  }

  updateConversationsTags = () => {
    contactsTagsModalStore.onOpen({
      ids: this.selectedContactsListIds,
      isBulkAll: this.isSelectedAll,
      callback: this.handleResetSelect,
      conversation_filter: filtersStore.filter?.key,
      teamId: inboxesStore.currentInboxId,
    })
  }

  addTopItemOrder = (conversationId: number) => {
    const isExist = this._topItemsOrder.some((orderId) => conversationId === orderId)

    if (!isExist) {
      this._topItemsOrder.push(conversationId)
    }
  }

  deleteTopItemOrder = (conversationId: number) => {
    this._topItemsOrder = this._topItemsOrder.filter((id) => id !== conversationId)
  }

  addItemsOrder = (items: IResponseConversation[]) => {
    items.forEach((item) => {
      this.addItemOrder(item.id)
    })
  }

  addItemOrder = (conversationId: number, top = false) => {
    const index = this._itemsOrder.findIndex((orderId) => conversationId === orderId)
    const isExist = index !== -1

    if (isExist) {
      if (top) {
        this._itemsOrder.splice(index, 1)
        this._itemsOrder.unshift(conversationId)
      }
    } else {
      if (top) this._itemsOrder.unshift(conversationId)
      else this._itemsOrder.push(conversationId)
    }
  }

  updateItemOrder = (conversationId: number, index = 0) => {
    const conversation = conversationStore.getItem(conversationId)
    const countItems = this._itemsOrder.length
    const countIsFavorite = this._itemsOrder.reduce<number>((state, id) => {
      const item = conversationStore.getItem(id)

      return item?.is_favorite ? state + 1 : state
    }, 0)

    const existIndex = this._itemsOrder.findIndex((orderId) => conversationId === orderId)

    if (ordersStore.order?.key === 'priority') {
      if (!conversation?.is_favorite) {
        index += countIsFavorite
      }
    }

    if (ordersStore.order?.key === 'oldest') {
      index += countItems
    }

    if (existIndex !== -1) {
      this._itemsOrder.splice(existIndex, 1)
    }

    this._itemsOrder.splice(index, 0, conversationId)

    this.handleScrollTrigger()
  }

  deleteItemOrder = (value: number) => {
    this._itemsOrder = this._itemsOrder.filter((id) => id !== value)
  }

  refreshConversations = async () => {
    try {
      await this.updateConversations()

      // Handle first conversation. Core flow
      if (this.autoSelectConversationId) {
        uiStore.changeRoute({
          path: `/conversations/${this.autoSelectConversationId}`,
          query: '?onload=',
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  searchConversations = async () => {
    try {
      runInAction(() => {
        this._loading = true
        if (this._conversationSearchStore.searchParams.page > 1) this._loadingMore = true
      })

      const { data } = await ConversationsApi.getConversationsSearch(
        this._conversationSearchStore.searchParams
      )

      if (data) {
        if (this._conversationSearchStore.searchParams.page === 1) {
          conversationStore.reset()
        }

        runInAction(() => {
          const owners: IResponseUser[] = []
          const contacts: IResponseContact[] = []
          const conversations: IResponseConversation[] = data.results

          conversationStore.addItems(conversations)
          usersStore.addItems(owners)
          contactsStore.addItems(contacts)
          this.addItemsOrder(conversations)

          this._conversationSearchStore.handleNextPage(
            this._conversationSearchStore.searchParams.page + 1
          )
          this._conversationSearchStore.handleTotal(data.total)
        })
      }

      runInAction(() => {
        this._loading = false
        this._loadingMore = false
        this._loadingConversations = false

        const firstConversationId = this.listOrders?.[0]?.id

        if (firstConversationId && this._visibleUI) {
          uiStore.changeRoute({
            path: `/conversations/${firstConversationId}`,
            query: '?onload=',
          })
        }
      })
    } catch (e) {
      console.error(e)
      if (this._visibleUI) {
        uiStore.changeRoute({
          path: '/conversations',
        })
      }

      runInAction(() => {
        this._loading = false
        this._loadingMore = false
      })
    }
  }

  setFirstItemId = () => {
    const firstItem = this.listOrders[0]

    if (firstItem) {
      conversationStore.setNextItemId(firstItem.id)
    } else {
      conversationStore.setNextItemId(null)
    }
  }

  setFocusItem = (deleteIds: number[], listItemsPast: Conversation[]) => {
    const currentFocusId = conversationStore.currentItemId || 0
    const isDeleteFocus = deleteIds.includes(currentFocusId)

    if (this.listCount === 0) {
      conversationStore.setNextItemId(null)
    } else if (isDeleteFocus) {
      if (this.listCount === 1) {
        this.setFirstItemId()
      } else {
        const currentIndexDeletedIds = deleteIds.findIndex((id) => id === currentFocusId)
        const stepsNext = deleteIds.filter((id, index) => currentIndexDeletedIds < index).length
        const stepsPrev = deleteIds.filter((id, index) => currentIndexDeletedIds > index).length
        const currentIndex = listItemsPast.findIndex((item) => item.id === currentFocusId)
        const nextItem = listItemsPast[currentIndex + stepsNext + 1]
        const prevItem = listItemsPast[currentIndex - stepsPrev - 1]

        conversationStore.setNextItemId(nextItem?.id || prevItem?.id)
      }
    } else if (!currentFocusId) {
      this.setFirstItemId()
    }

    this.nextNavigate()
  }

  nextNavigate = () => {
    if (conversationStore.nextItemId) {
      uiStore.changeRoute({
        path: `/conversations/${conversationStore.nextItemId}`,
      })
    } else {
      uiStore.changeRoute({
        path: '/conversations',
      })
    }
  }

  onOpen = async (id: number, itemUndo?: IResponseConversation) => {
    try {
      const itemsPast = this.listOrders

      await ConversationsApi.updateByIdOpen(id)

      if (itemUndo) {
        conversationStore.updateItem(itemUndo)
      } else {
        conversationStore.deleteItem(id)

        if (!this.listCount) {
          conversationStore.setCurrentItemId(null)
          await this.updateConversations()
        }
      }

      this.setFocusItem([id], itemsPast)
    } catch (error) {
      if (error instanceof AxiosError) {
        const conversationId = error.response?.data?.conversation_id
        if (conversationId) {
          this.confirmAnotherOpenConversation(conversationId)
        } else {
          console.error(error)
        }
      }
    }
  }

  onClose = async (id: number) => {
    try {
      const itemCache = conversationStore.getItem(id)?.origin
      const itemsPast = this.listOrders

      await ConversationsApi.updateByIdClose(id)

      const nanoId = nanoid()

      toastStore.add({
        id: nanoId,
        type: 'info',
        title: 'Conversation closed',
        action: {
          text: 'Undo',
          onAction: () => {
            this.onOpen(id, itemCache)
            toastStore.remove(nanoId)
          },
        },
      })

      conversationStore.deleteItem(id)

      if (!this.listCount) {
        await this.updateConversations()
      }

      this.setFocusItem([id], itemsPast)
    } catch (e) {
      console.error(e)
    }
  }

  confirmAnotherOpenConversation = (conversationId: number) => {
    const id = nanoid()
    toastStore.add({
      id: id,
      type: 'info',
      title: 'Found another open conversation',
      action: {
        text: 'Open',
        onAction: () => {
          toastStore.remove(id)

          uiStore.changeRoute({
            path: `/conversations/${conversationId}`,
          })
          conversationStore.setCurrentItemId(conversationId)
          filtersStore.setFilter('open', true)
          conversationStore.setNextItemId(0)
          this.load()
        },
      },
    })
  }

  subscribeCallBack = () => {
    conversationStore.setNextItemId(0)

    if (
      typeof inboxesStore.currentInboxId === 'number' &&
      typeof inboxesStore.currentInboxType === 'number'
    ) {
      uiStore.changeRoute({
        path: '/conversations',
      })
      this.reset()
      this.refreshConversations()
    }
  }

  handleMarkAsNeedResponse = async ({
    ids,
    isNeedResponse,
    isUndo,
    itemsUndo = [],
  }: IMarkAsNeedResponseParams) => {
    try {
      const isPendingFilter = filtersStore.isPending
      const textForTitle = ids.length > 1 ? 'Conversations' : 'Conversation'
      const itemsCache = ids.reduce<IResponseConversation[]>((state, id) => {
        const item = conversationStore.getItem(id)

        if (item) state.push(item.origin)

        return state
      }, [])
      const itemsPast = this.listOrders

      if (ids.length === 1) {
        await ConversationsApi.updateConversationsNeedResponseById(ids[0], {
          need_response: isNeedResponse,
        })
      } else {
        await ConversationsApi.updateConversationsBatchQueueNeedResponse({
          ids: ids,
          need_response: isNeedResponse,
        })
      }

      const nanoId = nanoid()

      if (isUndo) {
        toastStore.add({
          id: nanoId,
          type: 'info',
          title: isNeedResponse
            ? `${textForTitle} marked as Needs Response`
            : `${textForTitle} marked as responded`,
          action: {
            text: 'Undo',
            onAction: () => {
              this.handleMarkAsNeedResponse({
                ids: ids,
                isNeedResponse: !isNeedResponse,
                isUndo: false,
                itemsUndo: itemsCache,
              })
              toastStore.remove(nanoId)
            },
          },
        })
      }

      if (isPendingFilter) {
        if (isUndo) {
          ids.forEach((id) => {
            conversationStore.deleteItem(id)
          })

          if (this.isSelectedAll || !this.listCount) {
            conversationStore.setCurrentItemId(null)
            await this.updateConversations()
          }
        } else {
          itemsUndo.forEach((item) => {
            conversationStore.updateItem(item)
          })
        }

        this.setFocusItem(ids, itemsPast)
      } else {
        ids.forEach((id) => {
          conversationStore.getItem(id)?.setIsNeedResponse(isNeedResponse)
        })
      }

      this.handleResetSelect()
    } catch (e) {
      console.error(e)
    }
  }

  reactionFilter = () => {
    filtersStore.subscribeFilterChange(this.subscribeCallBack)
  }

  reactionOrder = () => {
    ordersStore.subscribeOrderChange(() => {
      conversationStore.setNextItemId(0)

      if (
        typeof inboxesStore.currentInboxId === 'number' &&
        typeof inboxesStore.currentInboxType === 'number'
      ) {
        uiStore.changeRoute({
          path: '/conversations',
        })
        this.reset()
        this.refreshConversations()
      }
    })
  }

  reactionInbox = () => {
    this._disposeInbox?.()
    this._disposeInbox = reaction(
      () => inboxesStore.currentInboxId,
      (value) => {
        if (value === null) return
        if (this._conversationSearchStore.hasSearchParams) return

        this.handleChangeInbox()
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionConversationNewInbox = () => {
    this._disposeConversationNew?.()
    this._disposeConversationNew = reaction(
      () => conversationStore.isNewConversation,
      (value) => {
        if (value) {
          this.addTopItemOrder(0)
        } else {
          this.deleteTopItemOrder(0)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  get hasMore() {
    return this._hasMore
  }

  get loading() {
    return this._loading
  }

  get loadingMore() {
    return this._loadingMore
  }

  get loadingConversations() {
    return this._loadingConversations
  }

  get conversationSearchStore() {
    return this._conversationSearchStore
  }

  get conversationAssignDropdownStore() {
    return this._conversationAssignDropdownStore
  }
}
