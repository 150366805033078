// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V9aIv3mPNcxrSkQRc_ok .zb0GUDooR4cHCnIbD4tw{height:44px;font-size:14;font-weight:500}.V9aIv3mPNcxrSkQRc_ok .zb0GUDooR4cHCnIbD4tw.Tro3RnHBH4nMbgUamTn9{margin-top:40px}.V9aIv3mPNcxrSkQRc_ok .o_p8z6pQrxxT4mDloTzu{margin-bottom:24px}.V9aIv3mPNcxrSkQRc_ok .iLsZQdAI8uI4z8p1tA5h{display:flex;flex-direction:column;gap:8px}.V9aIv3mPNcxrSkQRc_ok .MK93eSnEvzdY06rmLrRi{margin:32px 0;color:var(--content-primary-tertiary);font-size:14px}.V9aIv3mPNcxrSkQRc_ok .DlBA4787CGlE8n59Jl0u{padding:12px;font-weight:400;font-size:14px;line-height:20px}.V9aIv3mPNcxrSkQRc_ok .DlBA4787CGlE8n59Jl0u.o_p8z6pQrxxT4mDloTzu{margin-bottom:4px}.V9aIv3mPNcxrSkQRc_ok .Uv_pSjrkzbWtTwPo0TzA{margin-top:20px;margin-bottom:4px}", "",{"version":3,"sources":["webpack://./src/widgets/Login/ui/LoginForm/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,WAAA,CACA,YAAA,CACA,eAAA,CAEA,iEACE,eAAA,CAIJ,4CACE,kBAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,4CACE,aAAA,CACA,qCAAA,CACA,cAAA,CAGF,4CACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,iEACE,iBAAA,CAIJ,4CACE,eAAA,CACA,iBAAA","sourcesContent":[".wrap {\n  .button {\n    height: 44px;\n    font-size: 14;\n    font-weight: 500;\n\n    &.signIn {\n      margin-top: 40px;\n    }\n  }\n\n  .error {\n    margin-bottom: 24px;\n  }\n\n  .alertWrap {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n\n  .divider {\n    margin: 32px 0;\n    color: var(--content-primary-tertiary);\n    font-size: 14px;\n  }\n\n  .textField {\n    padding: 12px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.error {\n      margin-bottom: 4px;\n    }\n  }\n\n  .inputLabel {\n    margin-top: 20px;\n    margin-bottom: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "V9aIv3mPNcxrSkQRc_ok",
	"button": "zb0GUDooR4cHCnIbD4tw",
	"signIn": "Tro3RnHBH4nMbgUamTn9",
	"error": "o_p8z6pQrxxT4mDloTzu",
	"alertWrap": "iLsZQdAI8uI4z8p1tA5h",
	"divider": "MK93eSnEvzdY06rmLrRi",
	"textField": "DlBA4787CGlE8n59Jl0u",
	"inputLabel": "Uv_pSjrkzbWtTwPo0TzA"
};
export default ___CSS_LOADER_EXPORT___;
