import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { renderToString } from 'react-dom/server'
import { Typography } from 'shared/ui'
import { parserHtml } from 'shared/lib'
import { getAttachmentType } from 'entities/Attachment'
import { usersStore } from 'entities/Users'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { conversationStore } from 'entities/Conversation'
import { ConversationCardFiles } from 'widgets/ConversationCard'
import { parseNoteBody } from 'widgets/MessageCard'
import { textToHtml } from 'widgets/MessageField'
import styles from './../styles.module.scss'

type IConversationMessageProps = {
  item: Conversation
}

export const ConversationMessage: FC<IConversationMessageProps> = observer(({ item }) => {
  const { message } = item
  const draftMessage = conversationStore.getItemDraft(item.id)

  if (draftMessage) {
    if (draftMessage?.attachments_count) {
      return (
        <ConversationCardFiles
          type={getAttachmentType({
            content_type: draftMessage?.attachment_type || message?.attachment_type || '',
          })}
          count={draftMessage?.attachments_count || message?.attachments_count || 0}
          label='Draft:'
        />
      )
    }

    return (
      <Typography className={styles.body} variant={'body-md-regular'} ellipsis ariaLabel={'body'}>
        <span className={styles.body__label}>Draft:</span>
        <span
          dangerouslySetInnerHTML={{ __html: renderToString(<>{parserHtml(draftMessage.body)}</>) }}
        />
      </Typography>
    )
  }

  if (message?.isFailed) {
    if (message?.attachments_count) {
      return (
        <ConversationCardFiles
          type={getAttachmentType({
            content_type: message?.attachment_type || message?.attachment_type || '',
          })}
          count={message?.attachments_count || message?.attachments_count || 0}
          label='Failed:'
        />
      )
    }

    return (
      <Typography className={styles.body} variant={'body-md-regular'} ellipsis ariaLabel={'body'}>
        <span className={styles.body__label}>Failed:</span>
        <span
          dangerouslySetInnerHTML={{ __html: renderToString(<>{parserHtml(message.body)}</>) }}
        />
      </Typography>
    )
  }

  if (message?.attachments_count) {
    return (
      <ConversationCardFiles
        type={getAttachmentType({
          content_type: message?.attachment_type || '',
        })}
        count={message?.attachments_count || 0}
      />
    )
  }

  // if (conversation.messageType === 'call') {
  //   return (
  //     <ConversationItemCall
  //       status={conversation.messageStatus}
  //       isVoicemail={conversation.messageIsVoicemail}
  //     />
  //   )
  // }

  let bodyParse = textToHtml({
    text: message?.bodyParse || '',
    replaceNewRow: false,
    noReplaceMergeField: true,
    noEscapeHtml: message?.isAirCall,
  })

  if (message?.isNote) {
    bodyParse = parseNoteBody(bodyParse, usersStore.usersMap)
  }

  return (
    <Typography className={styles.body} variant={'body-md-regular'} ellipsis ariaLabel={'body'}>
      <span className={styles.body__label}></span>
      <span dangerouslySetInnerHTML={{ __html: renderToString(<>{parserHtml(bodyParse)}</>) }} />
    </Typography>
  )
})
