import { FC } from 'react'
import { NewCustomVoiceContext } from 'widgets/NewCustomVoiceModal/context'
import type { NewCustomVoiceStore } from 'widgets/NewCustomVoiceModal'
import { NewCustomVoice } from 'widgets/NewCustomVoiceModal/ui/NewCustomVoice'

type IModalContentProps = {
  store: NewCustomVoiceStore
}

export const ModalContent: FC<IModalContentProps> = ({ store }) => {
  return (
    <NewCustomVoiceContext.Provider value={store}>
      <NewCustomVoice />
    </NewCustomVoiceContext.Provider>
  )
}
