import { nanoid } from 'nanoid'

export async function getBlob(url: string, onBlob?: (blob: Blob) => void): Promise<Blob> {
  let isTryAgain = false

  const tryDownload = async (): Promise<Blob> => {
    try {
      const response = await fetch(`${url}${isTryAgain ? `?hash=${nanoid()}` : ''}`)
      const blob = await response.blob()

      if (onBlob) onBlob(blob)
      return blob
    } catch (error) {
      if (!isTryAgain) {
        isTryAgain = true
        return tryDownload()
      }
      throw error
    }
  }

  return tryDownload()
}
