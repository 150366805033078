import { IDropdownItem } from 'shared/ui'
import { IInboxCombine } from 'entities/Inbox'
import type { GroupInbox } from 'entities/Inbox/model/GroupInbox'
import { ConversationMenuStore } from 'widgets/ConversationMenu'

export const typeRemoveFromFolder = 'remove_from_folder'

type IActionRemoveFromFolder = {
  inbox: IInboxCombine
  groupInbox?: IInboxCombine
  item?: IDropdownItem
}

export const menuRemoveFromFolder = (): IDropdownItem => {
  return {
    id: typeRemoveFromFolder,
    iconL: 'remove',
    label: 'Remove from folder',
  }
}

export const actionRemoveFromFolder = async (
  { inbox, groupInbox, item }: IActionRemoveFromFolder,
  store: ConversationMenuStore
) => {
  if (item?.id === typeRemoveFromFolder) {
    store.handleDeleteTeamsGroupsByIdRemoveTeamById(inbox, groupInbox as GroupInbox)
  }
}
