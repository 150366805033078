import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { chatbotLayoutStore } from 'pages/chatbot/store/layoutStore'
import { ChatbotLabel, ChatbotLayoutHead, ChatbotTestButton } from 'pages/chatbot/ui'

import styles from './styles.module.scss'

export const ChatbotEditHead = observer(() => {
  const { titleIconProps, handleToggleCollapse } = chatbotLayoutStore

  return (
    <ChatbotLayoutHead>
      <IconButton
        iconProps={{
          fontSize: titleIconProps.fontSize,
          icon: titleIconProps.icon,
        }}
        variant='icon'
        size='medium'
        color='secondary'
        onClick={handleToggleCollapse}
        ariaLabel='TextobtHeaderLayout'
      />
      <ChatbotLabel className={styles.headerLabel} label='Edit Textbot' />
      <ChatbotTestButton className={styles.headerButton} />
    </ChatbotLayoutHead>
  )
})
