import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Avatar, Button, IconButton, ITableConfig, Tooltip, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { callStore } from 'entities/Call'
import { organizationStore } from 'entities/Organization'
import styles from 'widgets/CallHistory/ui/styles.module.scss'
import { type CallHistoryStore } from 'widgets/CallHistory'

type ICellContactProps = {
  info: CallHistory
  callHistoryStore: CallHistoryStore
  tableConfig?: ITableConfig
}
export const CellContact: FC<ICellContactProps> = observer(
  ({ info, tableConfig, callHistoryStore }) => {
    const { makeCall } = callHistoryStore.callHistoryTableStore
    const user = usersStore.user
    const inbox = inboxesStore.getItem(info.inbox.id)
    const isCallOutboundDisabled = !info.isNumberOutboundCalls
    const isPFTInbox = inbox?.isPFTInbox
    const isUserViewOnly = !!user?.isViewOnlyRole
    const { isOutOfCredits } = organizationStore
    const isCallDisabled =
      isUserViewOnly ||
      callStore.loading ||
      !callStore.isStatusClosed ||
      isPFTInbox ||
      isOutOfCredits

    const ownerDesc = (
      <Button
        text='Enable it here'
        typeBtn='link'
        size='small'
        paddingInline='0'
        onClick={(event) => {
          event.preventDefault()

          uiStore.changeRoute({
            path: `/settings/organization/inboxes/all-inboxes/${inboxesStore.currentInboxId}/calling`,
            type: 'vue',
          })
        }}
      />
    )

    const getTooltipLabel = () => {
      if (isOutOfCredits) {
        return {
          label: 'Your organization is out of message credits.',
          desc: 'Add message credits to continue calling.',
        }
      }
      if (!isCallDisabled && isCallOutboundDisabled) {
        if (
          user?.isAdminRole ||
          user?.isOwnerRole ||
          (inbox?.type === 'inbox' && inbox?.ownerId === user?.id)
        ) {
          return {
            label: 'Outbound calling is disabled',
            desc: ownerDesc,
          }
        }

        return { label: 'Outbound calling is disabled. Contact your admin to enable it.' }
      }

      return {
        label: 'Call',
      }
    }

    const nameContent = (
      <>
        {info.contact && <Avatar info={info.contactAvatarInfo} size={24} />}
        {!info.contact && info.participantsAvatarInfo && (
          <Avatar info={info.participantsAvatarInfo} size={24} />
        )}
        {info.contact ? (
          <Typography
            variant={info.isUnread ? 'body-md-semibold' : 'body-rg-regular'}
            ellipsis
            tooltipProps={{
              label: info.contact.name || info.contact.formatted_number,
            }}
            ariaLabel={'user'}
          >
            {info.contact.name || info.contact.formatted_number}
          </Typography>
        ) : (
          <Typography
            variant={info.isUnread ? 'body-md-semibold' : 'body-rg-regular'}
            ellipsis
            tooltipProps={{
              label: 'Participants',
            }}
            ariaLabel={'user'}
          >
            {info.participants?.map((participant, index) => {
              if (info.participants && index === info.participants.length - 1)
                return <span key={index}>{participant.name}</span>

              return <span key={index}>{participant.name}, </span>
            })}
          </Typography>
        )}
      </>
    )

    return (
      <>
        <div className={styles.contactCellWrapper}>
          <div className={styles.defaultState}>{nameContent}</div>
          {info.contact && tableConfig?.isHover && (
            <div className={classNames(styles.hoverState)}>
              {nameContent}
              <Tooltip label='Conversation' placement='top'>
                <IconButton
                  icon='chatRead'
                  variant='icon'
                  color='tertiary'
                  transparentInactive
                  className={tableConfig?.hidden || ''}
                  disabled={callHistoryStore.onOpenContactsDetails.disabled}
                  onClick={(e) => {
                    e.stopPropagation()

                    if (info) {
                      callHistoryStore.onOpenContactsDetails.open({
                        contactId: info.contact?.id,
                        inboxId: info.inbox.id,
                        conversationId: info.conversation.id,
                      })
                    }
                  }}
                  ariaLabel='CellContact_sendMessage'
                />
              </Tooltip>
              <Tooltip {...getTooltipLabel()} type='description' placement='top'>
                <IconButton
                  icon='phone'
                  variant='icon'
                  color='tertiary'
                  transparentInactive
                  className={tableConfig?.hidden || ''}
                  disabled={isCallDisabled || isCallOutboundDisabled}
                  onClick={(e) => {
                    e.stopPropagation()
                    makeCall(info.conversation.id)
                  }}
                  ariaLabel='CellContact_makeCall'
                />
              </Tooltip>
            </div>
          )}
        </div>
      </>
    )
  }
)
