import { useEffect, useRef, useState } from 'react'
import { Icon, ITooltipProps, Tooltip } from 'shared/ui'
import { ITextFieldAsideAction } from 'shared/ui/TextField'

import styles from './styles.module.scss'

export type ITextFieldErrorAnchor = 'input' | 'icon' | 'container'

export type ITextFieldErrorAction = {
  boundaryElement?: HTMLElement | null
  placement?: ITooltipProps['placement']
  anchor?: ITextFieldErrorAnchor
  offset?: [number, number]
}

export const makeTextFieldError: (action?: ITextFieldErrorAction) => ITextFieldAsideAction = (
  action
) => {
  const useAction: ITextFieldAsideAction = (control) => {
    const [noFocus, setNoFocus] = useState(true)
    const [mouseIn, setMouseIn] = useState(false)
    const iconRef = useRef<HTMLDivElement | null>(null)

    const isFieldHighlight = !!control.error && noFocus
    const showTooltip = isFieldHighlight && mouseIn

    const getAnchor = (anchor?: ITextFieldErrorAnchor) => {
      switch (anchor) {
        case 'container':
          return control.containerRef?.current
        case 'input':
          return control.inputRef?.current
        case 'icon':
          return iconRef.current
        default:
          return iconRef.current
      }
    }

    useEffect(() => {
      const input = control.inputRef?.current
      const label = control.containerRef?.current

      if (!input || !label) return

      const handleFocus = () => setNoFocus(false)
      const handleBlur = () => setNoFocus(true)
      input.addEventListener('focus', handleFocus)
      input.addEventListener('blur', handleBlur)

      const handleMouseEnter = () => setMouseIn(true)
      const handleMouseLeave = () => setMouseIn(false)
      label.addEventListener('mouseenter', handleMouseEnter)
      label.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        input.removeEventListener('focus', handleFocus)
        input.removeEventListener('blur', handleBlur)
        label.removeEventListener('mouseenter', handleMouseEnter)
        label.removeEventListener('mouseleave', handleMouseLeave)
      }
    }, [])

    if (isFieldHighlight)
      return (
        <>
          <Icon
            ref={iconRef}
            icon='alertTriangle'
            fontSize={16}
            color={'var(--content-negative-primary)'}
          />
          <Tooltip
            color='red'
            placement={action?.placement || 'top'}
            open={showTooltip}
            label={control.error}
            className={styles.tooltip}
            PopperProps={{
              anchorEl: () => getAnchor(action?.anchor) as HTMLElement,
              popperOptions: {
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      padding: 8,
                      boundary: action?.boundaryElement,
                    },
                  },
                  {
                    name: 'offset',
                    options: {
                      offset: action?.offset ?? [0, 8],
                    },
                  },
                ],
              },
            }}
          />
        </>
      )

    return null
  }

  return useAction
}
