import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Dropdown, DropdownContent } from 'shared/ui'
import { useTextToSpeechContext } from 'widgets/TextToSpeech'
import { VoicesContent } from 'widgets/TextToSpeech/ui/TextToSpeechVoice/VoicesContent/VoicesContent'
import styles from './styles.module.scss'

export const TextToSpeechVoice = observer(() => {
  const { selectedVoice } = useTextToSpeechContext()
  return (
    <div className={styles.wrap}>
      <Dropdown
        placement={'bottom-start'}
        width={340}
        customComponent={(onCloseMenu) => (
          <DropdownContent noPadding>
            <VoicesContent onCloseMenu={onCloseMenu} />
          </DropdownContent>
        )}
        triggerComponent={(open) => (
          <Button
            disabled={!selectedVoice}
            active={open}
            icon={'voice'}
            iconRight={open ? 'chevronUp' : 'chevronDown'}
            typeBtn={'text'}
            size={'small'}
            text={selectedVoice?.name}
            tooltipProps={{
              label: 'Voices',
              placement: 'top',
              visibility: open ? 'hidden' : 'visible',
            }}
          />
        )}
      />
    </div>
  )
})
