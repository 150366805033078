// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fCoa4lf0v6nxX5VqCplx{display:flex;justify-content:space-between;padding:8px 4px 0 0;width:100%}.xdoF3notlV1wn1KlQla7{position:relative}.xdoF3notlV1wn1KlQla7:before{position:absolute;top:0;left:0;right:0;bottom:0;z-index:5;background-color:var(--white-50);content:\"\"}.YgNI3yEHSikkSaxDoJqw{display:flex;align-items:center}.JCNztOmNo9Y97H3TIA8y{margin-right:2px}.JCNztOmNo9Y97H3TIA8y:last-of-type{margin-right:0}", "",{"version":3,"sources":["webpack://./src/shared/ui/ContainerActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,mBAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CAEA,6BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,SAAA,CACA,gCAAA,CACA,UAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CAEA,mCACE,cAAA","sourcesContent":[".wrap {\n  display: flex;\n  justify-content: space-between;\n  padding: 8px 4px 0 0;\n  width: 100%;\n}\n\n.disabled {\n  position: relative;\n\n  &:before {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 5;\n    background-color: var(--white-50);\n    content: \"\";\n  }\n}\n\n.box {\n  display: flex;\n  align-items: center;\n}\n\n.boxItem {\n  margin-right: 2px;\n\n  &:last-of-type {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "fCoa4lf0v6nxX5VqCplx",
	"disabled": "xdoF3notlV1wn1KlQla7",
	"box": "YgNI3yEHSikkSaxDoJqw",
	"boxItem": "JCNztOmNo9Y97H3TIA8y"
};
export default ___CSS_LOADER_EXPORT___;
