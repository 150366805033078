import dayjs from 'dayjs'
import { FC, useMemo } from 'react'
import { Typography } from 'shared/ui'
import { DayjsFormats } from 'shared/lib/dayjs'
import { Message } from 'entities/Message/model/Message'

export interface ITimezoneTooltipProps {
  message: Message
}

export const TimezoneTooltip: FC<ITimezoneTooltipProps> = ({ message }) => {
  const { contact_tz } = message
  const date = message.sent_at || message.send_at || message.date

  const content = useMemo(() => {
    const dateOffset = dayjs.utc(date).tz(contact_tz)

    const minutesOffset = dateOffset.utcOffset()
    const minutesOffsetAbs = Math.abs(minutesOffset)

    const utcOffset = dayjs.duration(minutesOffsetAbs, 'minutes').format('HH:mm')
    const utcSign = minutesOffset < 0 ? '-' : '+'

    const utc = `UTC ${utcSign}${utcOffset}`
    const time = dateOffset.format(DayjsFormats.time)
    const timezone = contact_tz?.replaceAll('_', ' ')

    return {
      utc,
      time,
      timezone,
    }
  }, [date, contact_tz])

  return (
    <div className='col4'>
      <Typography variant={'body-md-regular'} tag={'div'} ariaLabel={'time'}>
        {content.time}
      </Typography>
      <Typography
        variant={'body-md-regular'}
        tag={'div'}
        color={'var(--content-primary-tertiary-inverted)'}
        ariaLabel={'timezone'}
      >
        Based on contact timezone
        <div>
          {'('}
          {content.utc} {content.timezone}
          {')'}
        </div>
      </Typography>
    </div>
  )
}
