// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rJdCWP6EyMPV7xF_aBa6{padding-top:120px;display:flex;justify-content:center}.D6CZL052K1a3fFkVADV5{border-radius:12px;background:var(--background-primary-inverted-primary);box-shadow:var(--shadow-dropdown2)}.SZf7bezHSl9_cXWx0tVX{width:280px;display:flex;flex-direction:column;align-items:center;padding:32px 16px 16px 16px}.SZf7bezHSl9_cXWx0tVX .yY0l0mnGjR1_uodlpTZX{display:flex;flex-direction:column;align-items:center;padding-inline:8px;margin-bottom:32px;margin-top:24px;word-break:break-word}.SZf7bezHSl9_cXWx0tVX .Wzt7dMPfBaw53eoE46NZ{display:flex;width:100%;flex-direction:column;gap:8px}.vaKTj3TfFesIkhazqUev{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Modal/ui/InfoModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,sBAAA,CAEF,sBACE,kBAAA,CACA,qDAAA,CAGA,kCAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,2BAAA,CACA,4CACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,qBAAA,CAEF,4CACE,YAAA,CACA,UAAA,CACA,qBAAA,CACA,OAAA,CAIJ,sBACE,iBAAA","sourcesContent":[".wrap{\n  padding-top: 120px;\n  display: flex;\n  justify-content: center;\n}\n.card{\n  border-radius: 12px;\n  background: var(--background-primary-inverted-primary);\n\n  /* shadow-dropdown */\n  box-shadow: var(--shadow-dropdown2);\n}\n\n.info{\n  width: 280px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 32px 16px 16px 16px;\n  .content{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-inline: 8px;\n    margin-bottom: 32px;\n    margin-top: 24px;\n    word-break: break-word;\n  }\n  .actions{\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n    gap: 8px;\n  }\n}\n\n.additionalSecondaryAction{\n  margin-bottom: 8px;\n}\n\n.header{\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rJdCWP6EyMPV7xF_aBa6",
	"card": "D6CZL052K1a3fFkVADV5",
	"info": "SZf7bezHSl9_cXWx0tVX",
	"content": "yY0l0mnGjR1_uodlpTZX",
	"actions": "Wzt7dMPfBaw53eoE46NZ",
	"additionalSecondaryAction": "vaKTj3TfFesIkhazqUev"
};
export default ___CSS_LOADER_EXPORT___;
