// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".og5C1wQqXxw6bmg4qEpl{margin-bottom:20px}.PrZ8PAd3Shy0mCn1Zadl{padding:8px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsMenu/ui/SegmentMenuItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,WAAA","sourcesContent":[".segments {\n  margin-bottom: 20px;\n}\n\n.empty {\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segments": "og5C1wQqXxw6bmg4qEpl",
	"empty": "PrZ8PAd3Shy0mCn1Zadl"
};
export default ___CSS_LOADER_EXPORT___;
