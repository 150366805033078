import { createContext, useContext } from 'react'
import { type ConversationListStore } from 'widgets/ConversationList'

export const ConversationListContext = createContext<ConversationListStore | null>(null)

export function useConversationListContext() {
  const context = useContext(ConversationListContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ConversationListStoreProvider'
    )
  }
  return context
}
