import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { prepareSearchRegex } from 'shared/lib/prepareSearch'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { AttachmentsApi, ILoadRecentParams } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'

const ATTACHMENTS_LIMIT = 100

class RecentStore {
  constructor() {
    makeAutoObservable(this)
  }

  recentAttachmentsMap: Map<string | number, Attachment> = new Map()
  loadingRecent = false

  search = ''
  termAttachmentsIds: Array<number | string> = []
  checkedRecentAttachmentIds: Set<number | string> = new Set()
  dropdownId = ''
  removedAttachmentsIds: Set<number | string> = new Set()

  visiblePage = 1
  perPage = 6

  get recentAttachments() {
    return Array.from(this.recentAttachmentsMap.values())
  }

  get recentAttachmentsIds() {
    return Array.from(this.recentAttachmentsMap.keys())
  }

  get termAttachments(): Attachment[] {
    const attachments: Attachment[] = []
    this.termAttachmentsIds.forEach((id) => {
      const attachment = this.recentAttachmentsMap.get(id)
      if (attachment) {
        attachments.push(attachment)
      }
    })
    return attachments
  }

  get visibleAttachments(): Attachment[] {
    return this.termAttachments.slice(0, this.visiblePage * this.perPage)
  }

  get isCheckedMode() {
    return Boolean(this.checkedRecentAttachmentIds.size)
  }

  clearRecentAttachments() {
    this.recentAttachmentsMap.clear()
  }

  reset = () => {
    this.visiblePage = 1
    this.checkedRecentAttachmentIds.clear()
    this.searchAttachments('')
  }

  addAttachmentRecent = (attachment: Attachment, isFirst?: boolean) => {
    if (isFirst) {
      this.recentAttachmentsMap = new Map([
        [attachment.id, attachment],
        ...this.recentAttachmentsMap,
      ])
    } else {
      this.recentAttachmentsMap.set(attachment.id, attachment)
    }
  }

  onShowMore = () => {
    if (this.visiblePage * this.perPage >= this.recentAttachmentsMap.size) {
      return
    }
    this.visiblePage += 1
  }

  loadRecent = async (params?: ILoadRecentParams) => {
    try {
      runInAction(() => {
        this.loadingRecent = true
      })

      const { data } = await AttachmentsApi.recent({ limit: ATTACHMENTS_LIMIT, ...params })

      this.clearRecentAttachments()

      runInAction(() => {
        data.forEach((attachment) => {
          this.addAttachmentRecent(new Attachment({ responseUpload: attachment }))
        })
        this.searchAttachments(this.search)
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingRecent = false
      })
    }
  }

  checkParamsAndLoadRecent = (isCurrentAirCall?: boolean) => {
    isCurrentAirCall ? this.loadRecent({ is_aircall: true }) : this.loadRecent()
  }

  firstLoadRecent = async () => {
    await this.checkParamsAndLoadRecent()
  }

  searchAttachments = (search: string) => {
    this.search = search
    this.visiblePage = 1
    const term = search.trim()

    if (!term) return this.clearSearchAttachments()

    const searchRegex = prepareSearchRegex(term)

    this.termAttachmentsIds = this.recentAttachments
      .filter((attachment) =>
        attachment.searchFragments.some((fragment) => searchRegex.test(fragment))
      )
      .map((attachment) => attachment.id)
  }

  clearSearchAttachments() {
    this.search = ''
    this.termAttachmentsIds = this.recentAttachmentsIds
  }

  get isCheckedAll() {
    return this.checkedRecentAttachmentIds.size === this.recentAttachmentsMap.size
  }

  setCheckedRecentAttachmentIds = (id: string | number) => {
    if (!this.checkedRecentAttachmentIds.has(id)) {
      this.checkedRecentAttachmentIds.add(id)
    } else {
      this.checkedRecentAttachmentIds.delete(id)
    }
  }
  onCheckAll = () => {
    if (this.isCheckedAll) {
      this.checkedRecentAttachmentIds.clear()
    } else {
      this.checkedRecentAttachmentIds = new Set([...this.recentAttachmentsMap.keys()])
    }
  }
  setDropdownId = (id: string) => {
    this.dropdownId = id
  }

  onDelete = (ids: Array<string | number>) => {
    const modalId = nanoid()
    modalStore.addModal({
      id: modalId,
      type: ModalTypeList.ALERT,
      title: ids.length > 1 ? `Delete ${ids.length} files` : 'Delete file',
      desc: 'This action cannot be undone',
      isStopPropagation: true,
      primaryAction: {
        text: 'Delete',
        onAction: async () => {
          try {
            await AttachmentsApi.delete(ids)
            toastStore.add({
              type: 'success',
              title: `File${ids.length > 1 ? 's' : ''}  deleted`,
            })
            runInAction(() => {
              this.removedAttachmentsIds = new Set([...this.removedAttachmentsIds, ...ids])
              ids.forEach((id) => {
                this.checkedRecentAttachmentIds.delete(id)
                this.recentAttachmentsMap.delete(id)
              })
              if (!this.recentAttachmentsMap.size) {
                this.checkParamsAndLoadRecent()
              }
            })

            modalStore.closeModal(modalId)
          } catch (e) {
            console.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(modalId)
        },
      },
    })
  }

  onDeleteChecked = () => this.onDelete(Array.from(this.checkedRecentAttachmentIds))
}

export const recentStore = new RecentStore()
