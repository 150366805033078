import { Status } from 'shared/ui'
import { IStatusProps } from 'shared/ui/Status'
import { ITriggerStatus } from 'entities/Trigger/api/type'

import { getConfigByStatus } from './config'

export interface ITriggerStatusProps {
  status?: ITriggerStatus
  emphasis?: IStatusProps['emphasis']
  onlyIcon?: IStatusProps['onlyIcon']
}

export const TriggerStatus = ({ status, emphasis = 'high', onlyIcon }: ITriggerStatusProps) => (
  <Status {...getConfigByStatus(status)} emphasis={emphasis} onlyIcon={onlyIcon} />
)
