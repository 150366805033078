// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tDPy4fx2Zn5dZOxECfxD{padding:0 24px 24px 24px}.rWwRASoEypsGd178ET4w{height:84px;display:flex;justify-content:center;flex-direction:column}.NcbTvMFsHX_L623C2TcC{margin-block:8px;padding-right:3px;border-radius:6px;background:var(--background-neutral-subtle, #F6F7F8)}.XRRoxRq2k9a23mpAhlaF{max-height:216px;overflow:auto;padding:12px 3px 12px 12px}.uLVwDY8SG0CA24trsbD3{padding-top:16px}.QCqgw3Z0mI2WvwGy3hD7{color:var(--content-primary-disabled);text-align:left}", "",{"version":3,"sources":["webpack://./src/widgets/NewCustomVoiceModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,sBAAA,CACA,qBAAA,CAEF,sBACE,gBAAA,CACA,iBAAA,CACA,iBAAA,CACA,oDAAA,CAEF,sBACE,gBAAA,CACA,aAAA,CACA,0BAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,qCAAA,CACA,eAAA","sourcesContent":[".wrap{\n  padding: 0 24px 24px 24px ;\n}\n.container{\n  height: 84px;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n.wrapText{\n  margin-block: 8px;\n  padding-right: 3px;\n  border-radius: 6px;\n  background: var(--background-neutral-subtle, #F6F7F8);\n}\n.text{\n  max-height: 216px;\n  overflow: auto;\n  padding: 12px 3px 12px 12px;\n}\n.actions{\n  padding-top: 16px;\n}\n.infoText{\n  color: var(--content-primary-disabled);\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "tDPy4fx2Zn5dZOxECfxD",
	"container": "rWwRASoEypsGd178ET4w",
	"wrapText": "NcbTvMFsHX_L623C2TcC",
	"text": "XRRoxRq2k9a23mpAhlaF",
	"actions": "uLVwDY8SG0CA24trsbD3",
	"infoText": "QCqgw3Z0mI2WvwGy3hD7"
};
export default ___CSS_LOADER_EXPORT___;
