import { makeAutoObservable } from 'mobx'
import { type IWorkflowTemplateName } from 'widgets/CreateWorkflowModal/types'

export type IWorkflowTemplate = WorkflowTemplate

export class WorkflowTemplate {
  static Create() {
    return new WorkflowTemplate()
  }

  private _templateName: IWorkflowTemplateName | null = null
  private _name = ''

  constructor() {
    makeAutoObservable(this)
  }

  get templateName() {
    return this._templateName
  }

  setTemplateName(templateName: IWorkflowTemplateName) {
    this._templateName = templateName
  }

  get name() {
    return this._name
  }

  setName(name: string) {
    this._name = name
  }
}
