import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'
import { Alert, Icon } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { IInputComponent, makeTextFieldClear, TextField } from 'shared/ui/TextField'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import { connectTwilioModalStore } from 'widgets/connectTwilioModal'

import styles from './styles.module.scss'

export const ConnectTwilioModalContent = observer(() => {
  const { setAccountSid, setAuthToken, loading, authToken, accountSid, error, isPrefilled } =
    connectTwilioModalStore

  return (
    <div>
      <Box display={'grid'} gap={'16px'}>
        <div className={styles.iconWrapper}>
          <Icon icon='twilioIntegration' fontSize={200} />
        </div>

        {error && (
          <Alert
            item={{
              type: 'error',
              title: error,
            }}
          />
        )}

        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Twilio Account SID:',
            }}
          />
          <TextField
            Input={isPrefilled ? (InputPassword as IInputComponent) : undefined}
            variant={'stroke'}
            size={'small'}
            viewOnly={isPrefilled}
            InputProps={{
              placeholder: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
            }}
            value={accountSid}
            disabled={loading || isPrefilled}
            onChange={setAccountSid}
            rightActions={[makeTextFieldClear()]}
          />
        </Box>

        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Twilio Auth Token:',
            }}
          />
          <TextField
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
            }}
            value={authToken}
            disabled={loading}
            onChange={setAuthToken}
            rightActions={[makeTextFieldClear()]}
          />
        </Box>
      </Box>
    </div>
  )
})
