import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { ChatbotListStore } from 'pages/chatbot'

const ChatbotListContext = createContext<ChatbotListStore | null>(null)

export const useChatbotListContext = () => {
  const context = useContext(ChatbotListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ChatbotListProvider')

  return context
}

export const ChatbotListProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new ChatbotListStore(), [])

  useEffect(() => store.dispose, [])

  return <ChatbotListContext.Provider value={store}>{children}</ChatbotListContext.Provider>
}
