import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { adminManageTrialsStore } from 'pages/admin/pages/organizations/modals/AdminManageTrials/store/adminManageTrialsStore'

export const AdminManageTrialsAction = observer(() => {
  const { onSubmit, loadingSubmit, disabledSubmit } = adminManageTrialsStore
  return (
    <Button
      disabled={disabledSubmit}
      onClick={onSubmit}
      loading={loadingSubmit}
      typeBtn={'contained'}
      text={'Save'}
    />
  )
})
