// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".apQeU1MAzU8VzdNAMioK{margin-top:30px;margin-left:20px;display:flex;align-items:center;color:var(--content-primary-tertiary);gap:4px;height:24px}.LBnTz8RnyN7xUCFceLW_{height:20px;cursor:pointer;border-radius:4px;display:flex;gap:4px;padding:0 6px 0 4px;justify-content:center;align-items:center;color:var(--content-primary-tertiary);text-decoration:none}.LBnTz8RnyN7xUCFceLW_ span{line-height:15px}.LBnTz8RnyN7xUCFceLW_:hover{background:var(--background-neutral-subtle)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Breadcrumbus/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,qCAAA,CACA,OAAA,CACA,WAAA,CAEF,sBACE,WAAA,CACA,cAAA,CACA,iBAAA,CACA,YAAA,CACA,OAAA,CACA,mBAAA,CACA,sBAAA,CACA,kBAAA,CACA,qCAAA,CACA,oBAAA,CACA,2BACE,gBAAA,CAEF,4BACE,2CAAA","sourcesContent":[".wrap{\n  margin-top: 30px;\n  margin-left: 20px;\n  display: flex;\n  align-items: center;\n  color: var(--content-primary-tertiary);\n  gap: 4px;\n  height: 24px;\n}\n.link{\n  height: 20px;\n  cursor: pointer;\n  border-radius: 4px;\n  display: flex;\n  gap: 4px;\n  padding: 0 6px 0 4px;\n  justify-content: center;\n  align-items: center;\n  color: var(--content-primary-tertiary);\n  text-decoration: none;\n  span{\n    line-height: 15px;\n  }\n  &:hover{\n    background: var(--background-neutral-subtle);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "apQeU1MAzU8VzdNAMioK",
	"link": "LBnTz8RnyN7xUCFceLW_"
};
export default ___CSS_LOADER_EXPORT___;
