import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, Slider, Tooltip } from 'shared/ui'
import { MediaControllerStore } from 'widgets/PresentationMode/store/MediaControllerStore'
import styles from './styles.module.scss'

export const VolumeBtn = observer(
  ({ mediaControllerStore }: { mediaControllerStore: MediaControllerStore }) => {
    const { setVolume, mute, setMute, volume } = mediaControllerStore
    const [showVolume, setShowVolume] = useState(false)

    const onVolume = () => {
      setMute(!mute)
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
      setMute(false)
      setVolume(newValue as number)
    }

    const onMouseEnter = () => {
      setShowVolume(true)
    }

    const onMouseLeave = () => {
      setShowVolume(false)
    }

    return (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={styles.wrap}>
        <Tooltip label={mute ? 'Unmute' : 'Mute'}>
          <IconButton
            icon={mute ? 'volumeMute' : volume > 50 ? 'volumeMax' : 'volumeMin'}
            color={'secondary'}
            variant={'icon'}
            onClick={onVolume}
            ariaLabel='VolumeBtn_changeMute'
          />
        </Tooltip>
        {showVolume && (
          <div className={styles.wrapVolume}>
            <Slider value={volume} onChange={handleChange} />
          </div>
        )}
      </div>
    )
  }
)
