import { makeAutoObservable } from 'mobx'
import { MAX_COMPANY_NAME_LENGTH, MAX_ROLE_LENGTH } from 'shared/constants/auth'
import { IRegisterCookies } from 'entities/Auth/store/types'
import { IParamsRegisterAnswersStep1 } from 'entities/Auth'
import { CompanySizeEnum, FieldsEnum, ICompanySize, IRole, RolesEnum } from './type'

export class Step1Store {
  private _role: IRole | null = null
  private _companyName = ''
  private _companySize = ''
  private _activeField: FieldsEnum | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get payload() {
    return {
      organization_name: this.companyName,
      answer_team_size: this.companySize,
      answer_role: this._role?.name || '',
    }
  }

  get answers(): IParamsRegisterAnswersStep1 {
    return {
      org_name: this.companyName,
      company_size: this.companySize,
      role: this._role?.name || '',
    }
  }

  get isCompletedStep() {
    return !!this.companyName && !!this.companySize && !!this.role
  }

  initCookies = (data: IRegisterCookies) => {
    if (data.organization_name) this._companyName = data.organization_name
    if (data.answer_team_size) this._companySize = data.answer_team_size
    if (data.answer_role) {
      const role = this.roles.find((item) => item.id === data.answer_role)
      if (role) {
        this._role = role
      } else {
        this._role = {
          id: RolesEnum.other,
          name: data.answer_role,
        }
      }
    }
  }

  get isActiveCompanyNameField() {
    return this._activeField === FieldsEnum.companyName
  }

  get isActiveRoleField() {
    return this._activeField === FieldsEnum.role
  }

  get companyName() {
    return this._companyName
  }

  get companyNameLength() {
    return this._companyName.length
  }

  get isMaxCompanyLengthName() {
    return this.companyNameLength === MAX_COMPANY_NAME_LENGTH
  }

  get companySize() {
    return this._companySize
  }

  get role() {
    return this._role
  }

  get isValidRole() {
    return Boolean(
      this._role && !!this._role.name.length && this._role.name.length <= MAX_COMPANY_NAME_LENGTH
    )
  }

  get roles(): IRole[] {
    return [
      {
        id: RolesEnum.entrepreneur,
        name: 'Entrepreneur',
      },
      {
        id: RolesEnum.salesManager,
        name: 'Sales Manager',
      },
      {
        id: RolesEnum.marketingManager,
        name: 'Marketing Manager',
      },
      {
        id: RolesEnum.customerSuccessManager,
        name: 'Customer Success Manager',
      },
      {
        id: RolesEnum.operationsManager,
        name: 'Operations Manager',
      },
      {
        id: RolesEnum.ITAdministrator,
        name: 'IT Administrator',
      },
      {
        id: RolesEnum.other,
        name: 'Other',
      },
    ]
  }

  get companySizes(): ICompanySize[] {
    return [
      {
        id: CompanySizeEnum.justMe,
        name: 'Just me',
      },
      {
        id: CompanySizeEnum.size_2_10,
        name: '2 - 10',
      },
      {
        id: CompanySizeEnum.size_11_19,
        name: '11 - 20',
      },
      {
        id: CompanySizeEnum.size_20_49,
        name: '21 - 50',
      },
      {
        id: CompanySizeEnum.size_50_99,
        name: '51 - 100',
      },
      {
        id: CompanySizeEnum.size_100_more,
        name: '101+',
      },
    ]
  }

  get isNextDisabled() {
    return Boolean(
      !this._companyName.length ||
        this._companyName.length > MAX_COMPANY_NAME_LENGTH ||
        !this._role ||
        !this._companySize
    )
  }

  setCompanyName = (value: string) => {
    if (value.length > MAX_COMPANY_NAME_LENGTH) return
    this._companyName = value
  }

  setCompanySize = (value: string) => {
    this._companySize = value
  }

  setRole = (role: IRole | null) => {
    if (role && role.name.length > MAX_ROLE_LENGTH) return
    this._role = role
  }

  setActiveField = (value: FieldsEnum | null) => {
    this._activeField = value
  }

  handleBlur = () => {
    this._activeField = null
  }

  handleCompanyNameFieldFocus = () => {
    this.setActiveField(FieldsEnum.companyName)
  }

  handleRoleFieldFocus = () => {
    this.setActiveField(FieldsEnum.role)
  }
}
