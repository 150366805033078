import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { ICustomPayment, IParamsCustomPayment } from 'entities/Admin'
import { adminOrganizationCustomPlanStore } from '../store/adminOrganizationCustomPlanStore'
import { PaymentSchedule } from './PaymentSchedule'

const editableStatuses = ['draft', 'pending', 'scheduled']
const disabledStatuses = ['succeeded', 'failed']

export class PaymentScheduleTable {
  paymentsPlan = new Map<string, PaymentSchedule>()
  isEdit = false

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.paymentsPlan = new Map<string, PaymentSchedule>()
    this.isEdit = false
  }

  fillExistPaymentsPlan = (plans: ICustomPayment[]) => {
    this.paymentsPlan.clear()
    this.isEdit = true

    const haveEditableMinimum =
      plans.filter(({ status }) => editableStatuses.includes(status)).length > 1

    plans.forEach((item) => {
      const id = String(item.id)

      this.paymentsPlan.set(
        id,
        new PaymentSchedule({
          id: id,
          status: item.status,
          name: item.name,
          amount: item.amount / 100,
          credits: item.credits,
          payment_date: uiStore.dayjs(item.payment_date),
          editable: haveEditableMinimum && editableStatuses.includes(item.status),
          deletable: haveEditableMinimum && editableStatuses.includes(item.status),
          retry: item.status === 'failed',
        })
      )
    })
  }

  fillPaymentPlan = () => {
    this.isEdit = false
    if (!adminOrganizationCustomPlanStore.havePaymentPlan) {
      this.paymentsPlan.clear()
      return
    }

    const plansCount = adminOrganizationCustomPlanStore.paymentPlanType === '3-payments' ? 3 : 2
    const amount = adminOrganizationCustomPlanStore.totalPrice / plansCount
    const credits = adminOrganizationCustomPlanStore.totalCredits / plansCount
    const isNextBilling = adminOrganizationCustomPlanStore.applyDate === 'next-billing-cycle'

    runInAction(() => {
      this.paymentsPlan.clear()

      for (let i = 1; i <= plansCount; i++) {
        const editable = adminOrganizationCustomPlanStore.paymentPlanType === 'custom' && i !== 1

        const id = nanoid()
        const round = i === plansCount ? Math.ceil : Math.floor
        const startDate = isNextBilling
          ? adminOrganizationCustomPlanStore.currentSubscriptionRenewalDate || uiStore.dayjs()
          : uiStore.dayjs()

        this.paymentsPlan.set(
          id,
          new PaymentSchedule({
            id: id,
            status: 'draft',
            name: `Payment ${i}`,
            amount: amount,
            credits: round(credits),
            payment_date: startDate.add(i - 1, 'month'),
            editable,
            deletable: adminOrganizationCustomPlanStore.paymentPlanType === 'custom' && i > 2,
          })
        )
      }
    })
  }

  addPaymentPlan = () => {
    const newPaymentId = nanoid()
    const newSize = this.enabledPaymentCount + 1
    const totalSize = this.paymentsPlan.size + 1

    const amount = (adminOrganizationCustomPlanStore.totalPrice - this.paidAmount) / newSize
    const credits = (adminOrganizationCustomPlanStore.totalCredits - this.usedCredits) / newSize

    runInAction(() => {
      this.paymentsPlan.forEach((plan, id) => {
        if (!editableStatuses.includes(plan.status)) {
          return
        }

        plan.handleUpdateAmount(amount)
        plan.handleUpdateCredits(Math.floor(credits))

        if (this.isEdit && newSize > 1) {
          plan.handleUpdateDeletable(true)
        }

        this.paymentsPlan.set(id, plan)
      })

      const startDate = this.paymentsPlanArray[0].payment_date

      this.paymentsPlan.set(
        newPaymentId,
        new PaymentSchedule({
          id: newPaymentId,
          status: 'draft',
          name: `Payment ${totalSize}`,
          amount,
          credits: Math.ceil(credits),
          payment_date: startDate.add(totalSize - 1, 'month'),
          editable: adminOrganizationCustomPlanStore.paymentPlanType === 'custom' || this.isEdit,
          deletable:
            (adminOrganizationCustomPlanStore.paymentPlanType === 'custom' && newSize > 2) ||
            (this.isEdit && newSize > 1),
        })
      )
    })
  }

  handleDeletePaymentPlanItem = (id: string) => {
    const newSize = this.enabledPaymentCount - 1
    const amount =
      Math.trunc(adminOrganizationCustomPlanStore.totalPrice - this.paidAmount) / newSize
    const credits =
      Math.trunc(adminOrganizationCustomPlanStore.totalCredits - this.usedCredits) / newSize

    runInAction(() => {
      this.paymentsPlan.delete(id)

      let counter = 1
      this.paymentsPlan.forEach((plan, key) => {
        if (!editableStatuses.includes(plan.status)) {
          counter++
          return
        }

        const round = counter === newSize - 1 ? Math.ceil : Math.floor

        plan.handleUpdateAmount(amount)
        plan.handleUpdateCredits(round(credits))
        plan.handleUpdateName(`Payment ${counter}`)

        if (newSize <= 1) {
          plan.handleUpdateDeletable(false)
        }
        counter++

        this.paymentsPlan.set(key, plan)
      })
    })
  }

  recalculateAmountInPaymentPlan = () => {
    const plansCount = this.enabledPaymentCount
    const amount = adminOrganizationCustomPlanStore.totalPrice / plansCount
    const credits = adminOrganizationCustomPlanStore.totalCredits / plansCount

    runInAction(() => {
      let counter = 0

      this.paymentsPlan.forEach((plan, id) => {
        const round = counter === plansCount - 1 ? Math.ceil : Math.floor

        plan.handleUpdateAmount(amount)
        plan.handleUpdateCredits(round(credits))

        this.paymentsPlan.set(id, plan)

        counter += 1
      })
    })
  }

  handleChangeDatePaymentPlanItem = (date: Date | null, id: string) => {
    if (!date) return

    runInAction(() => {
      const plan = this.paymentsPlan.get(id)

      if (!plan) return

      plan.handleUpdatePaymentDate(uiStore.dayjs(date))
      this.paymentsPlan.set(id, new PaymentSchedule(plan))
    })
  }

  get paymentsPlanArray() {
    return Array.from(this.paymentsPlan.values())
  }

  get firstPayment() {
    return this.paymentsPlanArray[0] || null
  }

  get nextPayment() {
    return this.paymentsPlanArray.find((item) => item.status !== 'succeeded')
  }

  get enabledPaymentCount() {
    return this.paymentsPlanArray.filter(({ status }) => editableStatuses.includes(status)).length
  }

  get paidAmount() {
    return this.paymentsPlanArray.reduce(
      (total, { amount, status }) => (disabledStatuses.includes(status) ? total + amount : total),
      0
    )
  }

  get usedCredits() {
    return this.paymentsPlanArray.reduce(
      (total, { credits, status }) => (disabledStatuses.includes(status) ? total + credits : total),
      0
    )
  }

  get payments(): IParamsCustomPayment[] {
    return this.paymentsPlanArray
      .filter(({ status }) => editableStatuses.includes(status))
      .map((item) => ({
        amount: Math.floor(item.amount * 100),
        credits: item.credits,
        name: item.name,
        payment_date: item.payment_date.utc().format(DayjsFormats.apiFormat2),
      }))
  }
}
