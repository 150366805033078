import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon } from 'shared/ui'
import { useSearchDropdownContext } from 'widgets/SearchDropdown'
import styles from './styles.module.scss'

export const InitDropdownContent = observer(() => {
  const store = useSearchDropdownContext()

  return (
    <div className={classnames(styles.items, { [styles.emptyList]: store.hasAllFilters })}>
      {store.itemsList
        .filter((item) => store.selectedTypes.indexOf(item.type) === -1)
        .map((item) => (
          <div className={styles.item} key={item.id} onClick={() => store.selectType(item.type)}>
            <span className={styles.icon}>
              <Icon icon={item.icon} />
            </span>
            <span className={styles.label}>{item.label}</span>
          </div>
        ))}
    </div>
  )
})
