import { useMemo } from 'react'
import { callStore } from 'entities/Call'
import { type Conversation } from 'entities/Conversation/model/Conversation'
import { contactsStore } from 'entities/Contacts'
import { ContactsDetailsStore } from 'widgets/ContactsDetails'
import { type ConversationNewSearchStore } from 'widgets/ConversationNew'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export const useContactsDetailsStore = (
  conversation: Conversation | undefined,
  conversationNewSearchStore: ConversationNewSearchStore,
  conversationMessagesStore: ConversationMessagesStore
) =>
  useMemo(() => {
    if (!conversation) return null

    const profileCardProps = {
      onClickCall: (id: number) => callStore.connectTwilio(id),
    }

    const contact = contactsStore.getItem(conversation.contact_id)
    const variant = 'conversation'

    return new ContactsDetailsStore({
      conversation,
      profileCardProps,
      contact,
      variant,
      conversationNewSearchStore,
      conversationMessagesStore,
    })
  }, [conversation, conversation?.contact_id])
