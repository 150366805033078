import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button, IButtonProps, IconButton, IIconButtonProps } from 'shared/ui'
import { DeviceSettings } from 'entities/DeviceSettings'
import { RecordPlayer } from 'widgets/Record/ui/RecordActions/RecordPlayer/RecordPlayer'
import { RecordStore } from 'widgets/Record/store/recordStore'
import styles from './styles.module.scss'

export type IRecordActionsProps = {
  recordStore: RecordStore
  cancelBtnType?: 'icon' | 'button'
  iconButtonProps?: IIconButtonProps
  buttonProps?: IButtonProps
  cancelButtonProps?: IButtonProps
}

export const RecordActions: React.FC<IRecordActionsProps> = observer(
  ({ recordStore, buttonProps, cancelButtonProps, cancelBtnType = 'icon', iconButtonProps }) => {
    const {
      onStartRecord,
      startLoading,
      startRecord,
      onFinish,
      onPause,
      onRestart,
      onResume,
      onDelete,
      onCancel,
      pauseRecord,
      deviceSettingsStore,
      stream,
      isAudio,
      isAudioLimit,
    } = recordStore

    return (
      <div className={styles.actions}>
        {startRecord ? (
          <>
            <div className={styles.flex}>
              {pauseRecord ? (
                <IconButton
                  onClick={onResume}
                  tooltipProps={{
                    label: 'Resume',
                    placement: 'top',
                  }}
                  size={'medium'}
                  color={'secondary'}
                  variant={'icon'}
                  icon={isAudio ? 'microphone' : 'record'}
                  fontSize={20}
                  disabled={isAudioLimit}
                  ariaLabel='RecordActions_resume'
                  {...iconButtonProps}
                />
              ) : (
                <IconButton
                  onClick={onPause}
                  tooltipProps={{
                    label: 'Pause',
                    placement: 'top',
                  }}
                  size={'medium'}
                  color={'secondary'}
                  variant={'icon'}
                  icon={'pause'}
                  fontSize={20}
                  ariaLabel='RecordActions_pause'
                  {...iconButtonProps}
                />
              )}

              <IconButton
                onClick={onRestart}
                tooltipProps={{
                  label: 'Restart',
                  placement: 'top',
                }}
                size={'medium'}
                color={'secondary'}
                variant={'icon'}
                icon={'back'}
                fontSize={20}
                ariaLabel='RecordActions_restart'
                {...iconButtonProps}
              />
              <IconButton
                onClick={onDelete}
                tooltipProps={{
                  label: 'Delete',
                  placement: 'top',
                }}
                size={'medium'}
                color={'secondary'}
                variant={'icon'}
                icon={'delete'}
                fontSize={20}
                ariaLabel='RecordActions_delete'
                {...iconButtonProps}
              />
            </div>
            <RecordPlayer recordStore={recordStore} />
            <div className={styles.flex}>
              <IconButton
                onClick={onFinish}
                size={'medium'}
                color={'primary'}
                icon={'check1'}
                fontSize={20}
                ariaLabel='RecordActions_finish'
                {...iconButtonProps}
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.row8}>
              <Button
                loading={startLoading}
                icon={isAudio ? 'microphone' : 'record'}
                typeBtn={'contained'}
                contained={'secondary'}
                size={'medium'}
                text={'Start recording'}
                onClick={onStartRecord}
                disabled={!stream}
                {...buttonProps}
              />
              <DeviceSettings
                deviceSettingsStore={deviceSettingsStore}
                dropdownProps={{
                  placement: 'top-start',
                  width: 246,
                  margin: 8,
                  ariaLabel: 'Start_recording',
                }}
                variantDropdownBtn={'icon'}
                iconButtonProps={iconButtonProps}
              />
            </div>
            {isAudio &&
              (cancelBtnType === 'button' ? (
                <Button
                  text={'Cancel'}
                  typeBtn={'contained'}
                  contained={'secondary'}
                  size={'medium'}
                  onClick={() => {
                    onCancel(true)
                    deviceSettingsStore.removeStream()
                  }}
                  {...buttonProps}
                  {...cancelButtonProps}
                />
              ) : (
                <IconButton
                  icon={'close'}
                  size={'medium'}
                  color={'secondary'}
                  variant={'icon'}
                  onClick={() => {
                    onCancel(true)
                    deviceSettingsStore.removeStream()
                  }}
                  ariaLabel='RecordActions_close'
                  {...iconButtonProps}
                />
              ))}
          </>
        )}
      </div>
    )
  }
)
