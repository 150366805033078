import type { IPhones } from 'entities/Contacts'
import type { IResponseIntegrationWebhook } from 'entities/Webhook'
import { IResponseContact, IResponseContactHubspotIntegration } from 'entities/Contacts/api/types'

export type IResponseUserCredential = {
  id: number
  user_id: number
  provider: string
  provider_user_id: string
  provider_identifier: string
  is_active: boolean
  nickname: string
  name: string
  email: string
  avatar: null
  third_token: null
  meta: {
    appUrl: string
    name: string
    email: string
    lang: number
    locale: string
    timezone_name: string
    timezone_offset: string
    default_currency: string
    icon_url: null
    active_flag: boolean
    is_admin: number
    role_id: number
    created: string
    has_created_company: boolean
    is_you: boolean
    access: Array<{
      app: string
      admin: boolean
      permission_set_id: string
    }>
    phone: null
    last_login: string
    modified: string
    company_id: number
    company_name: string
    company_domain: string
    company_country: string
    language: {
      language_code: string
      country_code: string
    }
    text_activity_key_string: string
  }
  expires_at: string
  created_at: string
  updated_at: string
  deleted_at: null
}

export enum IntegrationKey {
  salesmessage = 'salesmessage',
  hubspot = 'hubspot',
  activecampaign = 'activecampaign',
  infusionsoft = 'infusionsoft',
  pipedrive = 'pipedrive',
  salesforce = 'salesforce',
  marketo = 'marketo',
  aircall = 'aircall',
  slack = 'slack',
  zipwhip = 'zipwhip',
  intercom = 'intercom',
  zapier = 'zapier',
  webhook = 'webhook',
  make = 'make',
  google = 'google',
  salesmsg = 'salesmsg',
  calendly = 'calendly',
}

export type IIntegrationKey = keyof typeof IntegrationKey
export type IResponseIntegration = {
  id: number
  key: IIntegrationKey
  is_active: boolean
  name: string
  description: string
  created_at: string
  updated_at: string
  photo_url: string
  oauth: boolean
  can_modify: boolean
  connected?: IResponseUserIntegration
  disconnected?: IResponseUserIntegration
}
export type IResponseIntegrationShort = Omit<IResponseIntegration, 'connected' | 'disconnected'>

export type ICrmUserMeta = {
  username: string
  phone: string
  signature: string
  lang: string
  localZoneid: string
  mfaEnabled: string
  links: {
    lists: string
    userGroup: string
    dealGroupTotals: string
    dealGroupUsers: string
    configs: string
    dealConnection: string
    userConversationsPermission: string
    seatUser: string
  }
  id: string
}

export type ICrmUserIntegrationMeta = {
  id: string
  type: string
  userId: number
  userIdIncludingInactive: number
  createdAt: string
  updatedAt: string
  archived: boolean
  avatar?: string
  teams:
    | null
    | {
        id: string
        name: string
        primary: boolean
      }[]
}

export type ICrmUser<Meta = ICrmUserMeta> = {
  id: number
  integration_id: number
  user_integration_id: number
  user_id: number
  crm_user_id: string
  crm_owner_id: null | string
  organization_id: number
  credential_id: number
  crm_id: string
  first_name: string
  last_name: string
  email: string
  meta: Meta | null
  created_at: string
  updated_at: string
  invitation?: {
    email: string
    id: string
    user_id: number
  }
}

export type IResponseUserIntegration = {
  id: number
  user_id: number
  organization_id: number
  integration_id: number
  user_credential_id: number
  provider_user_id: string | null
  is_active: boolean
  url: string
  api_key: string | null
  client_id: null
  client_secret: null
  token: null
  sync_token: string
  webhook_id: string
  expires_at: null
  connection_error: number
  last_check_at: string
  created_at: string
  updated_at: string
  deleted_at: null
  identity: string
  webhook_url: string
  webhook: IResponseIntegrationWebhook
  integration: IResponseIntegrationShort
  crm_users: ICrmUser[]
  user_credential?: IResponseUserCredential
}

export type IParamsIntegrations = {
  category?: string
  search?: string
}

export type IResponseIntegrations = {
  data: IResponseIntegration[]
}

export type IResponseIntegrationCategory = {
  data: string[]
}

export type IResponseCheckIntegrationConnectById =
  | {
      message: 'not_connected'
    }
  | IResponseUserIntegration

export type IResponseIntegrationDisconnected = {
  userIntegration: IResponseUserIntegration
  notifyUser: boolean
}

export type IResponseIntegrationRefreshed = {
  user: number
  integration_user: IResponseUserIntegration
}

export type IResponseIntegrationListSyncFinished = {
  list: IResponseGetHubspotLists
}

export type IConnectTwilioParams = {
  account_sid: string
  auth_token: string
}

export type IResponseConnectTwilio = {
  success: boolean
}

export type IResponseIntegrationContactMatch = {
  id: string
  first_name: string | null
  last_name: string | null
  email: string | null
  full_name: string | null
  numbers: IPhones
  hubspot_team_id: string | null
  photo_url: string | null
}

export type IResponseIntegrationContactsMatchingSearch = {
  current_page: number
  data: IResponseIntegrationContactMatch[]
  first_page_url: string
  from: number
  next_page_url: null
  path: string
  per_page: number
  prev_page_url: null
  to: number
}

type IEmptyMergeFieldType = ''

export type IMergeFieldType =
  | 'text'
  | 'number'
  | 'url'
  | 'date'
  | 'string'
  | 'enumeration'
  | 'datetime'
  | IEmptyMergeFieldType

export type IResponseMergeField = {
  updatedAt: string
  createdAt: string
  name: string
  label: string
  type: IMergeFieldType
  fieldType: string
  description: string
  groupName: string
  options: never[]
  displayOrder: number
  calculated: boolean
  externalOptions: boolean
  hasUniqueValue: boolean
  hidden: boolean
  hubspotDefined: boolean
  modificationMetadata: {
    archivable: boolean
    readOnlyDefinition: boolean
    readOnlyValue: boolean
  }
  formField: boolean
  value: string
  photo_url: string
  integrationKey: string
  key: string
  integrationId: number
  template: string
  referencedObjectType?: string
}

export type IIntegrationTagCreateDTO = {
  contact_integration_id: string | number
  tag: string
}

export type IIntegrationLinkDTO = {
  integration_id: number
  contact_id: number
  contact_integration_id: number | string
  first_name: string
  last_name: string
  email: string
  number: string
  update_contact: boolean
  integration_name?: string
  integration_key?: string
}

export type IIntegrationSearchMatchingContactsParams = {
  integration_id: number
  query: string
  field: string
}

export type IResponseInfusionsoftTags = [
  {
    text: string
    value: string
    category: string
  }
]

export type IParamsIntegrationsContactsSearch = {
  term?: string
  per_page?: number
  page: number
}

export type IDeal = {
  id: {
    value?: number | null
    label: string | null
  }
  dealname: {
    value: string | null
    label: string | null
  }
  amount?: {
    value: string | number | null
    label: string | null
  }
  closedate?: {
    value: string | null
    label: string | null
  }
  dealstage?: {
    value: string | null
    label: string | null
  }
  pipeline?: {
    value?: string | null
    label: string | null
  }
  owner?: {
    value?: string | null
    first_name?: string
    last_name?: string
    label: string | null
  }
}

// Integration Activecampaign
export type IResponseActiveCampaignProperties = {
  first_name: string
  last_name: string
  email: string
  tags: string[]
}

export type IResponseActiveCampaignAccounts = {
  id: string
  name: string
}

export type IResponseActiveCampaignDeal = {
  title: string
  value: string
  owner: {
    id: string
    username: string
    first_name: string
    last_name: string
    email: string
    last_login: string
    sourceid: string
    sourceupdated: null
    productset: string
    a_now: string
    groups: string
    phone: string
  }
}

export type IResponseIntegrationsContactsActiveCampaignInfo = {
  properties: IResponseActiveCampaignProperties
  accounts: IResponseActiveCampaignAccounts
  deals: IResponseActiveCampaignDeal[]
}

export type IResponseIntegrationActivecampaignTagsApply = {
  status: string
  message: string
}

export type IResponseErrorIntegrationActivecampaignTagsApply = {
  contact_integration_id: string[]
  tag: string[]
}

export type IParamsIntegrationActivecampaignTagsApply = {
  contact_integration_id: string | number
  tag: string
}

// Integrations Infusionsoft
export type IResponseIntegrationInfusionsoftTagsApply = {
  status: string
  message: string
}

export type IResponseErrorIntegrationInfusionsoftTagsApply = {
  contact_integration_id: string[]
  tag: string[]
}

export type IParamsIntegrationInfusionsoftTagsApply = {
  contact_integration_id: string | number
  tag: string
}

export type IResponseInfusionsoftInfoCompany =
  | {
      id: number
      company_name: string
    }
  | []

export type IResponseInfusionsoftInfoOpportunities =
  | {
      [key: string]: {
        title: string
        opportunity_notes: string
        projected_revenue_low: number
        projected_revenue_high: number
        stage: {
          id: number
          name: string
          details: {
            probability: number
            stage_order: number
            target_num_days: number
            check_list_items: []
          }
          reasons: []
        }
        owner: {
          id: number
          first_name: null
          last_name: null
        }
      }
    }
  | []

export type IResponseInfusionsoftInfoProperties = {
  first_name: string
  last_name: string
  email: [
    {
      email: string
      field: string
    }
  ]
  tags: [
    {
      tag: {
        id: number
        name: string
        category: null
      }
      date_applied: string
    }
  ]
}

export type IResponseInfusionsoftInfo = {
  company: IResponseInfusionsoftInfoCompany
  opportunities: IResponseInfusionsoftInfoOpportunities
  properties: IResponseInfusionsoftInfoProperties
}

// Integration Hubspot
export type IResponseIntegrationHubspotProperties = {
  [key: 'firstname' | 'email' | 'lastname' | string]: {
    label: string
    value: string | null
  }
}

export type IResponseIntegrationHubspotDeal = IDeal

export type IResponseIntegrationHubspotCompany =
  | {
      id: {
        label?: string
        value: string
      }
      name: {
        value: string
        label: string | null
      }
      hubspot_owner: {
        value: {
          portalId: number
          ownerId: number
          type: string
          firstName: string
          lastName: string
          email: string
          createdAt: number
          updatedAt: number
          remoteList: [
            {
              id: number
              portalId: number
              ownerId: number
              remoteId: string
              remoteType: string
              active: boolean
            }
          ]
          hasContactsAccess: boolean
          activeUserId: null | string | number
          userIdIncludingInactive: null | string | number
          activeSalesforceId: null | string | number
          isActive: boolean
        }
        label: string | null
      }
    }
  | {
      [key in string]: {
        label: string | null
        value: string | null
      }
    }

export type IResponseIntegrationHubspotTicket = {
  id: {
    value: number
    label: string | null
  }
  subject: {
    value: string
    label: string | null
  }
}

export type IIntegrationHubspotPropertyFormatted = {
  key: string
  label: string
  value: string | null
}

export type IResponseIntegrationHubspotData = {
  companies: IResponseIntegrationHubspotCompany[]
  deals: IResponseIntegrationHubspotDeal[]
  properties: IResponseIntegrationHubspotProperties
  tickets: IResponseIntegrationHubspotTicket[]
}

export type IResponseIntegrationsContactsHubspotInfo = {
  data: IResponseIntegrationHubspotData
}

// Integration Pipedrive
export type IResponseIntegrationPipedriveOrganization = {
  name: string | null
  owner:
    | {
        id: number | string
        name: string | null
        email: string | null
        value: number | string | null
      }
    | null
    | []
}

export type IResponseIntegrationPipedriveDeal = {
  title: string
  value: number
  owner: {
    id: number
    name: string
    email: string
    has_pic: number
    pic_hash: string
    active_flag: boolean
    value: number
  }
  stage: {
    id: number
    order_nr: number
    name: string
    active_flag: boolean
    deal_probability: number
    pipeline_id: number
    rotten_flag: boolean
    rotten_days: number
    add_time: string
    update_time: string
  }
  pipeline: {
    id: number
    name: string
    url_title: string
    order_nr: number
    active: boolean
    deal_probability: boolean
    add_time: string
    update_time: string
    selected: boolean
  }
}

export type IResponseIntegrationPipedriveProperty = {
  first_name: string
  last_name: string
  email: string
}

export type IResponseIntegrationsContactsPipedriveInfo = {
  organization: IResponseIntegrationPipedriveOrganization | null
  deal: IResponseIntegrationPipedriveDeal[]
  properties: IResponseIntegrationPipedriveProperty | null
}

// Integration Salesforce
export type IResponseSalesforceProperty = {
  first_name: string
  last_name: string
  email: string
  object_type: 'contact' | 'lead'
}

export type IResponseSalesforceOpportunity = {
  id: string
  name: string | null
  stage: string | null
  value: number | null
}

export type IResponseIntegrationsContactsSalesforceInfo = {
  opportunities: IResponseSalesforceOpportunity[]
  properties: IResponseSalesforceProperty | null
}

export type IResponseGetHubspotLists = {
  id: number
  list_id: number
  size: number | null
  name: string
  organization_id: number
  synced_at: string
}

export type IResponseGetHubspotContactsList = {
  name: string
  listId: number
  size: number
  type: string
  teams: number[]
}

export type IParamsSetHubspotContactsLists = {
  lists: {
    id: number
    name: string
    size: number
  }[]
}

export type IParamsIntegrationsByKeyFields = IIntegrationKey

export type IParamsIntegration = {
  integration_id: number | string | null
  service_property: string | null
}

export type IParamsIntegrationContact = {
  contact_integration_id: string | number
  external_source: string
}

type ILinkedIntegrationContact = {
  notice: 'contact_linked' | 'contact_created'
  type: 'contact'
  data: IResponseContact
}

type IUnLinkedIntegrationContact = {
  notice: 'contact_linked' | 'contact_created'
  type: 'integration_contact'
  data: Array<{
    label: string
    name: string
    value: string
  }>
}

export type IResponseIntegrationContact = ILinkedIntegrationContact | IUnLinkedIntegrationContact

export type IParamsSearchAssociatedIntegrationContact = {
  term?: string
  per_page?: number
  dealId?: string
  ticketId?: string
  companyId?: string
  showAllUsers?: boolean
  after?: string | null
}

export type ISearchAssociatedIntegrationContact =
  | IResponseContactHubspotIntegration
  | IResponseContact

export type IResponseSearchAssociatedIntegrationContact = {
  results: Array<ISearchAssociatedIntegrationContact>
  total: number
  paging: {
    next: {
      after: string
    }
  }
}

export type IParamsGetHubspotExtensionUserDetect = {
  provider_identifier: string
  userId?: string
}

export type IResponseGetHubspotExtensionUserDetect = {
  status: 'can_request' | 'no_such_integration' | 'this_is_owner'
  owner: {
    id: number
  }
}

export type IParamsCreateHubspotExtensionInvitationRequest = {
  owner_id: number
  userId: string
}

export type IResponseCreateHubspotExtensionInvitationRequest = {
  error: string | 'already_requested'
  ok: boolean
}

export type IIntegrationMeeting = {
  id: string
  slug: string
  link: string
  name: string
  type: string
  organizerUserId: string
  userIdsOfLinkMembers: string[]
  defaultLink: boolean
  createdAt: string
  updatedAt: string
}

export type IResponseGetIntegrationMeetings = {
  data: Array<IIntegrationMeeting>
  paging: null
  error?: string
}

export type IParamsConnectActiveCampaign = {
  integrationKey: IIntegrationKey
  apiUrl: string
  apiKey: string
}

type ResponseWrapper<Key extends string, Data> = {
  [key in Key]: Data
}

export type IIntegrationSettingsProperties = {
  custom_field_name: string
  integration_field_name: string
  integration_field_type: string
}

export type IntegrationSettingsHubspotCheckboxes =
  | 'update_contacts'
  | 'create_non_existing_contacts'
  | 'update_owner'

export type IntegrationSettingsTimelineLogCheckboxes =
  | 'log_calls'
  | 'log_messages'
  | 'log_notes'
  | 'log_voicemails'

export type IHubspotSettingsDict = {
  update_contacts: BinaryNumber
  create_non_existing_contacts: BinaryNumber
  phone_number_priority: string
  update_owner: BinaryNumber
  log_calls: BinaryNumber
  log_messages: BinaryNumber
  log_notes: BinaryNumber
  log_voicemails: BinaryNumber
  properties_mapping: IIntegrationSettingsProperties[]
}

export type ISalesForceSettingsDict = {
  enable_timelines: BinaryNumber
  update_contacts: BinaryNumber
  create_non_existing_contacts: BinaryNumber
  phone_number_priority: string
  new_record_type: string
  default_contact_account: string
  is_custom_phone_number_enabled: BinaryNumber
  lead_custom_phone_number: string
  contact_custom_phone_number: string
  conversation_assignment_enabled: BinaryNumber
}

export type ISettingRequestBySettingDict<T> = Array<
  {
    [K in keyof T]: { key: K; value: T[K] }
  }[keyof T]
>

export type ISettingRequestOptionsBySettingDict<T> = {
  [key: string]: {
    [K in keyof T]: Array<{ label: string; value: T[K] }>
  }
}

export type IResponseGetIntegrationSettings<T> = ResponseWrapper<
  'data',
  ISettingRequestBySettingDict<T>
>

export type IHubspotPropertiesObjectType = 'contact' | 'deal' | 'company' | 'ticket'

export type IParamsPagination = {
  page: number
  limit: number
  query?: string
  search?: string
}

export type IResponsePagination<Data, Meta = undefined> = {
  current_page: number
  data: Data
  meta: Meta
  first_page_url: string
  from: number
  last_page: number
  last_page_url: string
  links: Array<{
    url: string
    label: string
    active: boolean
  }>
  next_page_url: string
  path: string
  per_page: number
  prev_page_url: null
  to: number
  total: number
}

export type IResponseGetIntegrationUsers = IResponsePagination<
  Array<ICrmUser<ICrmUserIntegrationMeta>>,
  {
    crm_owner_id: string
    total_items: number
    mapped: number
    unmapped: number
  }
>

export type IParamsPatchIntegrationUserFieldValue = {
  attribute: keyof ICrmUser<ICrmUserIntegrationMeta>
  value: string | number
}

type HubspotChannelMeta = {
  id: string
  name: string
  active: boolean
  inboxId: string
  archived: boolean
  channelId: string
  createdAt: string
  authorized: boolean
  channelAccountId: string
  deliveryIdentifier: {
    type: string
    value: string
  }
}

export type IIntegrationChannel = {
  id: number
  name: string
  hubspot_channel_meta: HubspotChannelMeta
}

export type IResponseGetIntegrationChannels = IResponsePagination<IIntegrationChannel[]>

export type IParamsGetTwilioAccount = {
  account_sid: string
  installation_sid: string
}

export type IResponseGetTwilioAccount = {
  id: number
  organization_id: number | null
  installation_id: string
  vendor_id: string
  is_active: boolean
  owner: {
    email: string
    first_name: string | null
    last_name: string | null
  }
}

export type IIntegrationOrganizationSettingType = 'premium' | 'free' | 'sandbox'

export type IIntegrationOrganizationSetting = {
  key: 'salesforce_package_type'
  value: IIntegrationOrganizationSettingType
}

export type IIntegrationOrganizationSettingsResponse = ResponseWrapper<
  'data',
  IIntegrationOrganizationSetting[]
>

export type IGetIntegrationVersionResponse = {
  id: number
  installation_url: string
  installation_url_sandbox: string
  version: string
}
