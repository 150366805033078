import React from 'react'
import { observer } from 'mobx-react-lite'
import { Grid } from '@mui/material'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { Typography } from 'shared/ui'
import { paymentPlanTypeItems } from 'pages/admin/ui/AdminManagePaymentPlan/PaymentPlanSchedule/constants'
import { PaymentPlanCollapse } from 'pages/admin/ui/AdminManagePaymentPlan/PaymentPlanCollapse'
import { adminOrganizationCustomPlanStore } from 'pages/admin/pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'
import styles from './style.module.scss'

export const PaymentSchedule = observer(() => {
  const {
    isPaymentPlanDisabled,
    applyDateItems,
    isApplyDateDisabled,
    applyDate,
    paymentPlanType,
    setApplyDate,
    setPaymentPlanType,
  } = adminOrganizationCustomPlanStore

  return (
    <PaymentPlanCollapse title='Payment Schedule'>
      <Grid container spacing={2} mb={2}>
        <div id='calendar-portal' />
        <Grid item xs={6}>
          <SelectField
            labelText='Apply Date'
            value={applyDate}
            items={applyDateItems}
            setValue={setApplyDate}
            selectProps={{
              dropdownProps: { disabled: isApplyDateDisabled, ariaLabel: 'Apply Date' },
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <div className={styles.customLabel}>
            <Typography
              variant={'text-input-label-small'}
              color={'var(--content-primary-tertiary)'}
              ariaLabel={'name'}
            >
              Payment plan type
            </Typography>
            <Typography
              variant={'text-input-label-small'}
              color={'var(--content-neutral-primary)'}
              ariaLabel={'optional'}
            >
              (optional)
            </Typography>
          </div>
          <SelectField
            value={paymentPlanType}
            items={paymentPlanTypeItems}
            setValue={setPaymentPlanType}
            selectProps={{
              dropdownProps: { disabled: isPaymentPlanDisabled, ariaLabel: 'Payment Plan Type' },
            }}
          />
        </Grid>
      </Grid>
    </PaymentPlanCollapse>
  )
})
