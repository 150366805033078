import { FC } from 'react'
import { TextToSpeechWidgetStore } from 'widgets/TextToSpeech/store/textToSpeechWidgetStore'
import { TextToSpeechContent } from 'widgets/TextToSpeech/ui/TextToSpeechContent'
import { TextToSpeechContext } from './context/context'

type ITextToSpeechProps = {
  store: TextToSpeechWidgetStore
}

export const TextToSpeech: FC<ITextToSpeechProps> = ({ store }) => {
  return (
    <TextToSpeechContext.Provider value={store}>
      <TextToSpeechContent />
    </TextToSpeechContext.Provider>
  )
}
