import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { WorkflowListStore } from 'pages/workflows/store/listSore'

const WorkflowListContext = createContext<WorkflowListStore | null>(null)

export const useWorkflowListContext = () => {
  const context = useContext(WorkflowListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with WorkflowListProvider')

  return context
}

export const WorkflowListProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new WorkflowListStore(), [])

  useEffect(() => store.dispose, [])

  return <WorkflowListContext.Provider value={store}>{children}</WorkflowListContext.Provider>
}
