import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

class AdminStore {
  pageLayoutStore = new PageLayoutStore()
  constructor() {
    makeAutoObservable(this)
  }
}

export const adminStore = new AdminStore()
