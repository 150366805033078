import { observer } from 'mobx-react-lite'

import styles from './styles.module.scss'

interface IActionMessageProps {
  name: string
  placeholder: string
  message?: string
}

export const ActionMessage = observer(({ name, placeholder, message }: IActionMessageProps) => {
  return (
    <div className={styles.actionCardRow}>
      <div className={styles.actionCardName}>{name}</div>
      <div className={styles.actionCardValue}>
        {!!message ? message : <span className={styles.actionCardPlaceholder}>{placeholder}</span>}
      </div>
    </div>
  )
})
