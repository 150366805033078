import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { CopyWrapper } from 'shared/ui'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IntegrationCompanyProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationCompanyProperty'
import { IntegrationKey } from 'entities/Integrations'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/AllView/styles.module.scss'
import {
  ShowMoreAction,
  ShowMoreActionStore,
} from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { Actions } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/Actions'
import { ItemRow } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ItemRow/ItemRow'

type ICompanyProps = {
  integrationKey: IntegrationKey
  company: IntegrationCompanyProperty
  link: string
  contact: Contact
}

export const Company: FC<ICompanyProps> = observer(({ company, link, contact, integrationKey }) => {
  const showMoreActionStore = useMemo(
    () => new ShowMoreActionStore(integrationKey, EnumIntegrationTabs.Companies),
    []
  )
  const properties = showMoreActionStore.modifyData(company.properties)

  return (
    <div className={styles.item}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={company.title || null}
          clickableTitle
          data={{
            title: company.title || 'View company',
            link: link,
          }}
        />
      </div>
      {properties.map((property) => {
        return <ItemRow key={property.key} item={property} labelType={'simple'} />
      })}
      <Actions
        rows={[
          {
            columns: [
              !showMoreActionStore.hideShowMore && (
                <ShowMoreAction key={'show_more'} disabled={!contact} store={showMoreActionStore} />
              ),
            ],
          },
        ]}
      />
    </div>
  )
})
