// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X2CHzSbAcVfYx1e3IMcQ{padding-left:30px}.o_XI5rhhQ5cZLAyUA_82{margin-left:auto;display:none}@media(max-width: 992px){.o_XI5rhhQ5cZLAyUA_82{display:inline-flex}}.d17gwF18pRuX9SOkG5eg{margin:24px 0 0}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/pages/chatbotNew/ui/styles.module.scss"],"names":[],"mappings":"AAEA,sBACI,iBAAA,CAGJ,sBACI,gBAAA,CAEA,YAAA,CACA,yBAJJ,sBAKQ,mBAAA,CAAA,CAIR,sBACI,eAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\n.headerLabel {\n    padding-left: 30px;\n}\n\n.headerButton {\n    margin-left: auto;\n\n    display: none;\n    @media (max-width: $breakpoint-s) {\n        display: inline-flex;\n    }\n}\n\n.guidelineAlert {\n    margin: 24px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerLabel": "X2CHzSbAcVfYx1e3IMcQ",
	"headerButton": "o_XI5rhhQ5cZLAyUA_82",
	"guidelineAlert": "d17gwF18pRuX9SOkG5eg"
};
export default ___CSS_LOADER_EXPORT___;
