import React from 'react'
import { useRouteError } from 'react-router'
import styles from './styles.module.scss'

const ErrorPage: React.FC = () => {
  const error = JSON.stringify(useRouteError())

  return <div className={styles.wrap}>Error - {error}</div>
}

export { ErrorPage }
