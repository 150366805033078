import React from 'react'
import { EnumTimeMode, EnumTimeStatus, Time } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { inboxesStore } from 'entities/Inbox'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'

type ICellTimeProps = {
  info: CallHistory
}
const CellTime: React.FC<ICellTimeProps> = ({ info }) => {
  const inbox = inboxesStore.getItem(inboxesStore.currentInboxId)
  const isColorCodingEnabled = Boolean(inbox?.color_coding_enabled)

  return (
    <Time
      date={info.time}
      initial_date={uiStore.dayjs(info.time).format(DayjsFormats.slashWithAtDash)}
      status={info.isUnread ? EnumTimeStatus.Unread : EnumTimeStatus.Read}
      mode={EnumTimeMode.Live}
      is_show_initial_date={true}
      is_need_response={info.isUnread}
      color_coding_enabled={isColorCodingEnabled}
    />
  )
}

export { CellTime }
