// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qo49Rsl2PoIWfd2UMfQf{padding-inline:12px;height:40px;display:flex;justify-content:space-between;align-items:center}.mX74GbNj1Zx5nURPHqRA{padding-left:6px}.b0SUk1iGlDusrbMKi_bC{color:var(--content-primary-disabled)}.b0SUk1iGlDusrbMKi_bC.Z3ruocP4aiCMmEoz1v39{color:var(--content-negative-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/TextToSpeech/ui/TextToSpeechInfo/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,qCAAA,CACA,2CACE,qCAAA","sourcesContent":[".wrap{\n  padding-inline: 12px;\n  height: 40px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.wrapError{\n  padding-left: 6px;\n}\n.duration{\n  color: var(--content-primary-disabled);\n  &.error{\n    color: var(--content-negative-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "qo49Rsl2PoIWfd2UMfQf",
	"wrapError": "mX74GbNj1Zx5nURPHqRA",
	"duration": "b0SUk1iGlDusrbMKi_bC",
	"error": "Z3ruocP4aiCMmEoz1v39"
};
export default ___CSS_LOADER_EXPORT___;
