import { type DraggableProvided } from 'react-beautiful-dnd'
import { FC } from 'react'
import classnames from 'classnames'
import { Icon } from 'shared/ui'
import { Integration, IntegrationKey } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/styles.module.scss'
import { DraggingIcon } from './DraggingIcon'
import { StatusIcon } from './StatusIcon'

type IIntegrationTitleProps = {
  integration: Integration | null
  contact: Contact
  hideDraggingIcon?: boolean
  dragHandleProps: DraggableProvided['dragHandleProps']
  isIntegrationActive: boolean
  createNewContactMode?: boolean
}

export const IntegrationTitle: FC<IIntegrationTitleProps> = ({
  hideDraggingIcon,
  dragHandleProps,
  integration,
  contact,
  isIntegrationActive,
  createNewContactMode,
}) => {
  if (
    (!isIntegrationActive && contact.integration_key === IntegrationKey.salesmsg) ||
    createNewContactMode
  ) {
    return (
      <div className={styles.titleWrapper}>
        <DraggingIcon hideDraggingIcon={hideDraggingIcon} dragHandleProps={dragHandleProps} />

        <span className={styles.integrationIcon}>
          <Icon icon={'add'} />
        </span>
        <span className={styles.title}>Integration</span>
      </div>
    )
  }

  return (
    <div className={styles.titleWrapper}>
      <DraggingIcon hideDraggingIcon={hideDraggingIcon} dragHandleProps={dragHandleProps} />

      {integration && (
        <span className={styles.integrationIcon}>
          <Icon icon={integration.icon} />
        </span>
      )}
      <span className={styles.title}>{integration?.name}</span>
      <span
        className={classnames(styles.statusIcon, {
          [styles.active]: !!integration,
        })}
      >
        <StatusIcon isActive={isIntegrationActive} />
      </span>
    </div>
  )
}
