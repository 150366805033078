import React from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader, Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { InboxDropdownCard } from 'entities/Inbox'
import {
  SearchDropdownInboxesStore,
  SearchTypesEnum,
  useSearchDropdownContext,
} from 'widgets/SearchDropdown'
import styles from './styles.module.scss'

type InboxesSearchDropdownContentProps = {
  store: SearchDropdownInboxesStore
  onCloseMenu?: () => void
  onSelect?: (item: Inbox) => void
}

export const InboxesDropdownContentSearch: React.FC<InboxesSearchDropdownContentProps> = observer(
  ({ onCloseMenu, store, onSelect }) => {
    const { setIsShowMore, selectType, search, actionSearch, hasTeamsType } =
      useSearchDropdownContext()
    const { options, selectTypeItem } = store

    const handleSelectTag = (item: Inbox) => {
      actionSearch({
        value: '',
        exclude: 'inboxes',
      })
      options?.handleSearch?.('')

      if (!hasTeamsType) selectType(SearchTypesEnum.teams)

      selectTypeItem(item)
      onSelect?.(item)
      onCloseMenu?.()
    }

    const handleShowAll = () => {
      actionSearch({
        value: '',
        exclude: 'inboxes',
      })
      options?.handleSearch?.('')
      selectType(SearchTypesEnum.teams)
      setIsShowMore(true)
    }

    return (
      <div className={styles.wrap} aria-label={getAriaLabel('searchInboxesSelector')}>
        <p
          className={styles.searchTitle}
          aria-label={getAriaLabel('searchInboxesHeaderSelector')}
        >{`Inboxes (${store.total})`}</p>
        {store.loading && (
          <div className={styles.spinner}>
            <SpinnerLoader size={24} />
          </div>
        )}
        {!store.loading && store.hasItems && (
          <div className={styles.items}>
            {store.first3Items.map((item) => (
              <InboxDropdownCard
                key={item.id}
                highlighter={search}
                item={item}
                onChange={handleSelectTag}
                ariaLabel='searchInboxItem'
              />
            ))}
          </div>
        )}
        {!store.loading && !store.hasItems && (
          <div className={styles.notFound}>No results found</div>
        )}
        {!hasTeamsType && store.hasItems && (
          <div className={styles.showAll}>
            <Button
              disabled={store.loading}
              typeBtn={'informative'}
              text={'Show all'}
              onClick={handleShowAll}
            />
          </div>
        )}
      </div>
    )
  }
)
