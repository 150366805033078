import { useState } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { CopyWrapper, Icon, Space, Typography } from 'shared/ui'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import { contactsStore } from 'entities/Contacts'
import { integrationLink, IIntegrationLinkTypes, integrationsStore } from 'entities/Integrations'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { ItemDealRow } from './ItemDealRow'

import styles from '../styles.module.scss'

const AllDeals = observer(() => {
  const [search, setSearch] = useState('')

  const store = useContactsDetailsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)

  if (!store.viewIntegrationKey || !contact) return null

  const userIntegration = integrationsStore.getUserIntegration(store.viewIntegrationKey)

  const link = (id: number | null | undefined) => {
    const linkProps = {
      key: store.viewIntegrationKey,
      contact,
      integration: userIntegration,
      type: IIntegrationLinkTypes.deal,
      id,
    }

    return integrationLink(linkProps)
  }

  const deals = store.viewAllData.deals.filter(
    (deal) => deal?.dealname?.value?.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  const notFound = deals.length === 0

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.search}>
        <TextField
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={setSearch}
          leftActions={[makeTextFieldIcon({ icon: 'search' })]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>
      <div className={styles.list}>
        {deals.map((deal) => (
          <div key={deal?.id?.value}>
            <div className={styles.headerWrapper}>
              <CopyWrapper
                copyValue={deal.dealname?.value || null}
                data={{
                  title: deal.dealname?.value || 'View deal',
                  link: link(deal?.id?.value),
                }}
              />
            </div>
            {store.customTitleIcon && (
              <ItemDealRow deal={deal} integrationKey={store.viewIntegrationKey} />
            )}
          </div>
        ))}
        {notFound ? (
          <div className={styles.epmtyList}>
            <Icon icon='search' fontSize={24} />
            <Typography variant={'body-rg-regular'} ariaLabel={'NoResultsFound'}>
              No results found
            </Typography>
          </div>
        ) : (
          <Space height={60} />
        )}
      </div>
    </div>
  )
})

export { AllDeals }
