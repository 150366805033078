import { RefObject, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { callStore } from 'entities/Call'
import { numbersStore } from 'entities/Phone'
import { Head, useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallBaseContent } from '../CallBaseContent/CallBaseContent'
import styles from './../../styles.module.scss'

type ICallPopUpContentProps = {
  refWrap: RefObject<HTMLDivElement>
}

export const CallPopUpContent: FC<ICallPopUpContentProps> = observer(({ refWrap }) => {
  const store = useCallPopUpDefaultContext()

  if (!store.showPopUp) return null
  if (!store.isParticipants) return null
  if (!store.contactFrom) return null

  const number = numbersStore.getItem(store.contactFrom.numberId)

  return (
    <div className={styles.section}>
      <div ref={refWrap} className={styles.tooltipWrap} />

      <Head
        isIncoming={callStore.isIncoming}
        wrapElement={store.innerRefTooltipCurrent}
        name={store.contactFrom?.name}
        phone={number?.formatted_number}
        onClick={store.handleGoToConversation}
      />
      <CallBaseContent />
    </div>
  )
})
