import { createContext, useContext } from 'react'
import { type NewPropertiesDropdownStore } from 'widgets/NewPropertiesDropdown'

export const NewPropertiesDropdownContext = createContext<NewPropertiesDropdownStore | null>(null)

export function useNewPropertiesDropdownContext() {
  const context = useContext(NewPropertiesDropdownContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with NewPropertiesDropdownStoreProvider'
    )
  }
  return context
}
