import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { adminSendEmailUsageReportStore } from 'pages/admin/pages/organizations/modals/AdminSendEmailUsageReport/store/adminSendEmailUsageReportStore'

export const AdminSendEmailUsageReportAction = observer(() => (
  <Button
    typeBtn={'contained'}
    text={'Send report'}
    onClick={adminSendEmailUsageReportStore.onSubmit}
    disabled={adminSendEmailUsageReportStore.disabledSubmit}
    loading={adminSendEmailUsageReportStore.loading}
  />
))
