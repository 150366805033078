import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { BroadcastErrorAlert, BroadcastStatus, BroadcastType } from 'entities/Broadcast'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import styles from './styles.module.scss'

export const Header = observer(() => {
  const { status, type, name, failed_reason } = useBroadcastDetailModalContext()
  return (
    <div>
      <Typography ariaLabel={'broadcast.name'} variant={'heading-lg'} ellipsis>
        {name}
      </Typography>
      <div className={styles.info}>
        <BroadcastStatus status={status} />
        <BroadcastType type={type} />
      </div>
      {status === 'failed' && (
        <div className={styles.alert}>
          <BroadcastErrorAlert isBottomActions failed_reason={failed_reason} />
        </div>
      )}
    </div>
  )
})
