import { FC, useEffect, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { Typography, SpeedMediaBtn, IIconButtonSize } from 'shared/ui'
import { AudioPlayerActions } from 'shared/ui/AudioPlayer/ui/AudioPlayerActions'
import { TranscriptionContent } from 'shared/ui/AudioPlayer/ui/TranscriptionContent/TranscriptionContent'
import { AudioWave } from 'shared/ui/AudioPlayer/ui/AudioWave/AudioWave'
import { uiStore } from 'shared/store/uiStore'
import {
  IPlayerColorType,
  IPlayerSizeType,
  IResponseRecordTranscriptStatus,
} from 'shared/ui/AudioPlayer/ui/types'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'
import { PlayAttachmentButton, PlayDefaultButton } from './AudioPlayButton'

export type AudioPlayerProps = {
  url?: string
  fileName?: string
  transcriptions?: string
  transcript_status?: IResponseRecordTranscriptStatus
  is_auto_transcribe?: boolean
  id?: string
  fromAttachMedia?: boolean
  widthWave?: number
  isClosed?: boolean
  handlePlaying?: (value: boolean) => void
  colorType?: IPlayerColorType
  size?: IPlayerSizeType
  fullWidth?: boolean
  noRightActions?: boolean
  autoplay?: boolean
  togglePlaying?: (value: boolean) => void
  setScrollBottomTrigger?: () => void
  retryTranscript?: () => void
  className?: string
  canTranscript?: boolean
  disableTranscript?: boolean
  hideTranscriptionButton?: boolean
}

export const AudioPlayer: FC<AudioPlayerProps> = observer((props) => {
  const {
    url,
    id,
    transcriptions,
    fromAttachMedia,
    widthWave,
    isClosed = false,
    handlePlaying,
    colorType = 'white',
    size = 'middle',
    fullWidth,
    noRightActions,
    autoplay,
    togglePlaying,
    className,
    hideTranscriptionButton = false,
  } = props
  const [showTranscription, setShowTranscription] = useState(false)
  const [waveform, setWaveform] = useState<WaveSurfer | null>(null)
  const [speed, setSpeed] = useState(1)
  const [duration, setDuration] = useState(0)
  const [playing, setPlaying] = useState(!!autoplay)
  const showShortView = isClosed && !playing

  const onPlay = () => {
    if (waveform) {
      uiStore.setPlayAudioId(id || url || '')
      if (playing) {
        waveform.pause()
      } else {
        waveform.play()
      }
      setPlaying(!playing)
      if (handlePlaying) handlePlaying(!playing)
    }
  }

  const onSpeed = (speed: number) => {
    setSpeed(speed)
    waveform?.setPlaybackRate(speed)
  }

  const PlayButton = fromAttachMedia ? PlayAttachmentButton : PlayDefaultButton

  const iconButtonSize = (): IIconButtonSize => {
    if (size === 'small') return 'small'

    return 'medium'
  }

  useEffect(() => {
    togglePlaying && togglePlaying(playing)
  }, [playing])

  const audioWave = url && (
    <AudioWave
      url={url}
      waveform={waveform}
      setWaveform={setWaveform}
      id={id || url}
      playing={playing}
      setPlaying={setPlaying}
      setDuration={setDuration}
      width={widthWave}
      hide={showShortView}
      autoplay={autoplay}
    />
  )

  return (
    <>
      <div
        className={classNames(
          styles.wrapAudio,
          styles[`size_${size}`],
          styles[`color_${colorType}`],
          {
            [styles.fromAttachMedia]: fromAttachMedia,
            [styles.fullWidth]: fullWidth,
            [styles.noRightActions]: noRightActions,
          },
          className
        )}
        aria-label={getAriaLabel('AudioPlayer')}
      >
        <PlayButton isPlaying={playing} onClick={onPlay} size={iconButtonSize()} />

        <Typography
          variant={'body-md-regular'}
          width={duration ? 46 : 84}
          className={styles.duration}
          tag={'div'}
          ariaLabel={'time'}
        >
          {duration ? secondsToTime(duration) : 'Processing...'}
        </Typography>
        {url && fullWidth ? <div className={styles.wave}>{audioWave}</div> : audioWave}

        <div className={styles.actions}>
          {!fromAttachMedia && !showShortView && !noRightActions && (
            <>
              <div className={styles.wrapSpeed}>
                <SpeedMediaBtn fromAudioPlayer speed={speed} setSpeed={onSpeed} />
              </div>
              <AudioPlayerActions
                {...props}
                showTranscription={showTranscription}
                setShowTranscription={setShowTranscription}
                hideTranscriptionButton={hideTranscriptionButton}
              />
            </>
          )}
        </div>
      </div>
      {showTranscription && <TranscriptionContent transcriptions={transcriptions || ''} />}
    </>
  )
})
