import { IResponseInboxGroup } from 'entities/Inbox/api/types'

export const groupInboxTemplate = {
  description: null,
  is_empty: false,
  color_coding_enabled: false,
  call_via_aircall: null,
  id: 0,
  name: '',
  type: 'inbox_group',
  teams: [],
  is_favorite: false,
} as IResponseInboxGroup
