import type { IStatusProps } from 'shared/ui/Status'

type IStatusCollectionType = {
  [key: string]: IStatusProps
}

const STATUSES: IStatusCollectionType = {
  ['unverified']: {
    title: 'Unverified',
    icon: 'alertCircle',
    emphasis: 'high',
    intent: 'negative',
  },
  ['denied']: {
    title: 'Rejected',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  },
  ['blocked']: {
    title: 'Blocked',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  },
  ['verified']: {
    title: 'Approved',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  },
  ['internal review']: {
    title: 'In Carrier Review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  },
  ['under review']: {
    title: 'Pending Review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  },
  ['failed']: {
    title: 'Failed',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  },
  ['pending']: {
    title: 'Pending Review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  },
}

export const getNumberStatus = (status: string): IStatusProps | undefined => STATUSES[status]
