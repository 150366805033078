import { toastStore } from 'shared/ui'
import { ChatbotApi } from 'entities/Chatbot'

export class ChatbotEnrollModalStore {
  enrollContacts = (chatbotId: number, contactIds: number[], applyFilters: boolean) =>
    ChatbotApi.enrollContactsInChatbot({
      chatbot_id: chatbotId,
      contact_ids: contactIds,
      apply_filters: applyFilters,
    }).then(
      () => {
        toastStore.add({
          type: 'success',
          title: applyFilters
            ? `Contact${
                contactIds.length > 1 ? 's' : ''
              } will be engaged if it matches enrollment criteria`
            : `Contact${contactIds.length > 1 ? 's' : ''} will now be engaged by the Textbot`,
        })

        return true
      },
      () => {
        toastStore.add({
          type: 'error',
          title: `Unable to engage contact${contactIds.length > 1 ? 's' : ''}`,
        })

        return false
      }
    )
}
