import React from 'react'
import { observer } from 'mobx-react-lite'
import { type TriggerViewStore } from 'widgets/TriggerView/store/triggerViewStore'
import { TriggerViewContext } from 'widgets/TriggerView/context/triggerViewContext'
import { TriggerViewContent } from 'widgets/TriggerView/ui/TriggerViewContent'

type ITriggerViewProps = {
  store: TriggerViewStore
}

export const TriggerView = observer(({ store }: ITriggerViewProps) => {
  return (
    <TriggerViewContext.Provider value={store}>
      <TriggerViewContent />
    </TriggerViewContext.Provider>
  )
})
