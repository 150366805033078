// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JOZspTepKqywknm8oVIQ{cursor:pointer;width:100%;height:auto;border-radius:4px;margin:0 auto}.HPkvmFEp4sXm23zuFtg5{max-width:312px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageCard/ui/TextContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,aAAA,CAEF,sBACE,eAAA","sourcesContent":[".img {\n  cursor: pointer;\n  width: 100%;\n  height: auto;\n  border-radius: 4px;\n  margin: 0 auto;\n}\n.withPreviews {\n  max-width: 312px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "JOZspTepKqywknm8oVIQ",
	"withPreviews": "HPkvmFEp4sXm23zuFtg5"
};
export default ___CSS_LOADER_EXPORT___;
