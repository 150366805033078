// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sSErjUskcm4sJONOdiAe{margin-inline:auto;display:grid;width:100%;overflow:hidden;grid-template-rows:max-content max-content auto}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationNew/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBAEE,kBAAA,CACA,YAAA,CACA,UAAA,CACA,eAAA,CACA,+CAAA","sourcesContent":[".wrap {\n  //max-width: 760px;\n  margin-inline: auto;\n  display: grid;\n  width: 100%;\n  overflow: hidden;\n  grid-template-rows: max-content max-content auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "sSErjUskcm4sJONOdiAe"
};
export default ___CSS_LOADER_EXPORT___;
