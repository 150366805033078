import { observer } from 'mobx-react-lite'
import { Box, IDropdownItem, InputCheckbox, TextFieldWithLabel, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { SelectFieldMultiList } from 'shared/ui/Select/SelectMultiListField/SelectMultiListField'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { CommonErrorBanner } from 'pages/settings/pages/compliance/ui/CommonErrorBanner/CommonErrorBanner'
import styles from './styles.module.scss'

interface IBusinessInfoStore {
  activeBusinessTypeId: number | null
  activeBusinessIndustryId: number | null
  websiteUrl: string | null
  noWebsiteUrl: boolean
  socialMediaUrl?: string | null
  activeRegionOfOperationIds: number[]

  businessTypes: IDropdownItem[]
  businessIndustries: IDropdownItem[]
  websiteUrlLocalError?: string
  socialMediaUrlError?: string
  activeRegionsOfOperation: IDropdownItem[]
  regionsOfOperation: IDropdownItem[]
  isNextDisabled: boolean
  isCorrectUrl: boolean

  setBusinessIndustry: (industryId: number) => void
  setBusinessType: (typeId: number) => void
  setActiveRegionOfOperationIds: (ids: number[]) => void
  setWebsiteUrl: (url: string | null, fromRequest?: boolean) => void
  setNoWebsiteUrl: (value: boolean) => void
  setSocialMediaUrl: (url: string | null) => void
  handleSetNoWebsiteUrl: () => void
  checkUrl: (url: string, type: 'websiteUrl' | 'socialMediaUrl') => Promise<void>
  setRegionsOfOperationId: (regionId: number) => void
  deleteRegionsOfOperationId: (regionId: number) => void
}

type ICommonBusinessInfoProps = {
  businessInfoStore: IBusinessInfoStore
  stepLoading: boolean
  errorMessage?: string | null
  nextStepClick: () => void
}

export const CommonBusinessInfo = observer(
  ({ businessInfoStore, stepLoading, errorMessage, nextStepClick }: ICommonBusinessInfoProps) => {
    const {
      businessTypes,
      activeBusinessTypeId,
      businessIndustries,
      activeBusinessIndustryId,
      websiteUrl,
      socialMediaUrl,
      noWebsiteUrl,
      websiteUrlLocalError,
      socialMediaUrlError,
      activeRegionsOfOperation,
      regionsOfOperation,
      isNextDisabled,
      isCorrectUrl,
      deleteRegionsOfOperationId,
      setBusinessType,
      setBusinessIndustry,
      setWebsiteUrl,
      setSocialMediaUrl,
      handleSetNoWebsiteUrl,
      checkUrl,
      setRegionsOfOperationId,
    } = businessInfoStore

    return (
      <div className={styles.wrap}>
        <PageDescription>Enter your business details for carrier verification</PageDescription>

        <CommonErrorBanner errorMessage={errorMessage} />

        <Box
          flex={1}
          display='flex'
          justifyContent={'space-between'}
          gap='12px'
          marginBottom={3}
          marginTop={5}
        >
          <SelectField
            boxWidth='100%'
            labelProps={{
              label: {
                text: 'Business type',
              },
              questionTooltipProps: {
                label: 'Please select your business entity type',
                width: 140,
              },
            }}
            items={businessTypes}
            value={activeBusinessTypeId}
            setValue={setBusinessType}
            btnProps={{
              size: 'large',
              className: styles.businessSelect,
            }}
            dropdownProps={{ width: 'unset' }}
          />
          <SelectField
            boxWidth='100%'
            labelProps={{
              label: {
                text: 'Business industry',
              },
            }}
            items={businessIndustries}
            value={activeBusinessIndustryId}
            setValue={setBusinessIndustry}
            btnProps={{
              size: 'large',
              className: styles.businessSelect,
            }}
            dropdownProps={{ width: 'unset' }}
          />
        </Box>

        <TextFieldWithLabel
          marginBottom={0}
          errorMessage={websiteUrlLocalError}
          labelProps={{
            marginBottom: 4,
            questionTooltipProps: {
              label: 'You need a website to register',
              desc: 'If you don’t have an official website and are using a social media profile page, click “I don’t have a website”.',
              type: 'description',
              width: 240,
            },
            label: {
              text: 'Business website URL',
            },
          }}
          textFieldProps={{
            disabled: noWebsiteUrl,
            value: websiteUrl || '',
            error: !!websiteUrlLocalError,
            onChange: setWebsiteUrl,
            onBlur: (value: string) => checkUrl(value, 'websiteUrl'),
            InputProps: {
              placeholder: 'e.g. https://www.example.com/',
            },
            className: styles.selectPadding,
          }}
          rightActions={[
            makeTextFieldIcon({
              icon: isCorrectUrl ? 'check2' : undefined,
              fontSize: 16,
              color: 'var(--green-60)',
            }),
          ]}
        />

        <InputCheckbox
          checked={noWebsiteUrl}
          onChecked={handleSetNoWebsiteUrl}
          customLabel={
            <Typography
              ariaLabel='Business_info_email_label'
              variant='text-input-field-r'
              color='var(--content-primary-tertiary)'
            >
              {"I don't have a business website"}
            </Typography>
          }
          className={styles.noEmailCheckbox}
        />

        {noWebsiteUrl && (
          <TextFieldWithLabel
            marginBottom={3}
            errorMessage={socialMediaUrlError}
            labelProps={{
              marginBottom: 8,
              questionTooltipProps: {
                label: "Enter the URL of your company's social media profile page",
                desc: '(e.g. Facebook, Twitter, Linkedin etc)',
                type: 'description',
                width: 226,
              },
              label: {
                text: 'Social media profile',
              },
            }}
            textFieldProps={{
              value: socialMediaUrl || '',
              error: !!socialMediaUrlError,
              onChange: setSocialMediaUrl,
              onBlur: (value: string) => checkUrl(value, 'socialMediaUrl'),
              InputProps: {
                placeholder: 'e.g. https://www.facebook.com/example/',
              },
              className: styles.selectPadding,
            }}
          />
        )}

        <SelectFieldMultiList
          labelText='Regions of Operation'
          labelProps={{
            label: {
              text: 'Business regions of operations',
            },
            questionTooltipProps: {
              label: 'Where do you do business? ',
              type: 'description',
              width: 241,
              desc: 'If you have customers all around the world, go ahead and select all options. Otherwise, just select the ones where your customers are primarily located. ',
            },
          }}
          dropdownProps={{
            textFieldProps: {
              className: styles.selectRegionsPadding,
            },
          }}
          items={regionsOfOperation}
          selectedItems={activeRegionsOfOperation}
          onDelete={({ id }) => deleteRegionsOfOperationId(id as number)}
          onSelect={({ id }) => setRegionsOfOperationId(id as number)}
          fullWidth
        />

        <NextButton
          loading={stepLoading}
          disabled={isNextDisabled || stepLoading}
          text='Next'
          onClick={nextStepClick}
        />
      </div>
    )
  }
)
