import { makeAutoObservable, toJS } from 'mobx'
import { type IParamsSendFrom } from 'entities/Inbox/api/types'
import { inboxesStore } from 'entities/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { ChatbotActions } from './ChatbotActions'
import { ChatbotFields } from './ChatbotField'
import { ChatbotFilters } from './ChatbotFilters'
import { ChatbotSettings } from './ChatbotSettings'
import { ChatbotMessage } from './ChatbotMessage'
import { ChatbotInstructionType, type IChatbotPayload } from './type'

export class Chatbot {
  static DefaultType = ChatbotInstructionType.Custom

  name = ''
  type = featureFlagsStore.salesmsg_ai_agent_book_appointment_v2 ? null : Chatbot.DefaultType
  isActive = false
  sendFrom: null | IParamsSendFrom = null
  fields = new ChatbotFields()
  successActions = new ChatbotActions()
  fallbackActions = new ChatbotActions()
  firstMessage = new ChatbotMessage()
  settings = new ChatbotSettings()
  filters = new ChatbotFilters()

  constructor(item?: IChatbotPayload) {
    this.syncOrigin(item)

    makeAutoObservable(this)
  }

  setName(value: string) {
    this.name = value
  }

  setActive(isActive: boolean) {
    this.isActive = isActive
  }

  setSendFrom(params: IParamsSendFrom) {
    this.sendFrom = params
  }

  setType(type: ChatbotInstructionType) {
    this.type = type
  }

  syncOrigin(item?: IChatbotPayload | null) {
    this.type =
      item?.type ??
      (featureFlagsStore.salesmsg_ai_agent_book_appointment_v2 ? null : Chatbot.DefaultType)
    this.name = item?.name ?? ''
    this.isActive = item?.is_active ?? false
    this.sendFrom = item?.send_from ?? null

    this.firstMessage.syncOrigin(item?.first_message)
    this.settings.syncOrigin(item?.settings)
    this.filters.syncOrigin(item?.filters)
    this.fields.syncOrigin(item?.fields)
    this.successActions.syncOrigin(item?.success_actions)
    this.fallbackActions.syncOrigin(item?.fallback_actions)
  }

  toJSON(): IChatbotPayload {
    return toJS({
      type: this.type as ChatbotInstructionType,
      name: this.name.trim(),
      is_active: this.isActive,
      send_from: this.sendFrom,

      fields: this.fields.toJSON(),
      first_message: this.firstMessage.toJSON(),
      settings: this.settings.toJSON(),
      filters: this.filters.toJSON(),
      success_actions: this.successActions.toJSON(),
      fallback_actions: this.fallbackActions.toJSON(),
    })
  }

  reset() {
    this.name = ''
    this.isActive = false
    this.sendFrom = null
    this.type = Chatbot.DefaultType

    this.firstMessage.reset()
    this.settings.reset()
    this.filters.reset()
    this.fields.reset()
    this.successActions.reset()
    this.fallbackActions.reset()
  }

  get inboxId() {
    if (this.sendFrom?.options.team_id) {
      const inbox = inboxesStore.getItem(this.sendFrom?.options.team_id)

      return inbox?.id
    }

    if (this.sendFrom?.options.number_id) {
      const inbox = inboxesStore.getInboxByNumber(this.sendFrom?.options.number_id)

      return inbox?.id
    }
  }
}
