import { IDropdownItem } from 'shared/ui'
import { numberFormat } from 'shared/lib'

export type IAutoRechargeWidgetStoreOptions = {
  zIndex?: number
}

export const thresholdCredits = [
  100, 200, 400, 600, 1000, 2000, 3000, 4000, 5000, 10000, 20000, 30000, 40000,
]

export const thresholdCreditsAch = [5000, 10000, 20000, 30000, 40000]

export const rechargeItemsAch = [10000, 20000, 40000, 60000, 80000]

export const thresholdCreditsItems: IDropdownItem[] = thresholdCredits.map((value) => ({
  id: value,
  label: `${numberFormat({ value: value })} credits`,
}))

export const thresholdCreditsAchItems: IDropdownItem[] = thresholdCreditsAch.map((value) => ({
  id: value,
  label: `${numberFormat({ value: value })} credits`,
}))

export const amountItemsAch: IDropdownItem[] = rechargeItemsAch.map((value) => ({
  id: value,
  label: numberFormat({ value: value }),
  labelBrackets: numberFormat({ value: value / 25, currency: 'USD' }),
  selectedLabelRight: numberFormat({ value: value / 25, currency: 'USD' }),
}))
