import { observer } from 'mobx-react-lite'
import { PlayBtn } from 'shared/ui/Button/ui/PlayBtn/PlayBtn'
import { SpeedMediaBtn } from 'shared/ui'
import { InfoWrap } from 'widgets/PresentationMode/ui/info/ui/InfoWrap/InfoWrap'
import { DownloadPreviewBtn } from 'widgets/PresentationMode/ui/info/ui/buttons/DownloadPreviewBtn'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { VolumeBtn } from 'widgets/PresentationMode/ui/info/ui/buttons/VolumeBtn/VolumeBtn'
import { TimeMedia } from 'widgets/PresentationMode/ui/info/ui/TimeMedia/TimeMedia'
import { MediaProgressBar } from 'widgets/PresentationMode/ui/info/ui/MediaProgressBar/MediaProgressBar'

export const InfoAudioPreview = observer(() => {
  const { audioControllerStore } = presentationStore
  const { mediaControllerStore } = audioControllerStore
  const { isPlay, currentTime, duration, togglePlay, speed, setSpeed } = mediaControllerStore

  return (
    <InfoWrap
      leftActions={
        <>
          <PlayBtn isPlay={isPlay} togglePlay={togglePlay} />
          <VolumeBtn mediaControllerStore={mediaControllerStore} />
          <TimeMedia currentTime={currentTime} duration={duration} />
        </>
      }
      middleContent={<MediaProgressBar mediaControllerStore={mediaControllerStore} />}
      rightActions={
        <>
          <SpeedMediaBtn speed={speed} setSpeed={setSpeed} />
          <DownloadPreviewBtn />
        </>
      }
    />
  )
})
