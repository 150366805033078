import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, SpinnerLoader } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { Segment } from 'entities/Segment/model/Segment'
import { SegmentDropdownCard } from 'entities/Segment'
import {
  SearchDropdownSegmentStore,
  SearchTypesEnum,
  useSearchDropdownContext,
} from 'widgets/SearchDropdown'
import styles from './styles.module.scss'

type ISegmentsDropdownContentSearchProps = {
  store: SearchDropdownSegmentStore
  onCloseMenu?: () => void
  onSelect?: (item: Segment) => void
}

export const SegmentsDropdownContentSearch: FC<ISegmentsDropdownContentSearchProps> = observer(
  ({ onCloseMenu, store, onSelect }) => {
    const { setIsShowMore, selectType, search, actionSearch, hasSegmentsType } =
      useSearchDropdownContext()
    const { selectTypeItem, options } = store

    const handleSelectItem = (item: Segment) => {
      actionSearch({
        value: '',
        exclude: 'segment',
      })
      options?.handleSearch?.('')

      if (!hasSegmentsType) selectType(SearchTypesEnum.segment)

      selectTypeItem(item)
      onSelect?.(item)
      onCloseMenu?.()
    }

    const handleShowAll = () => {
      actionSearch({
        value: '',
        exclude: 'segment',
      })
      options?.handleSearch?.('')
      selectType(SearchTypesEnum.segment)
      setIsShowMore(true)
    }

    return (
      <div className={styles.wrap} aria-label={getAriaLabel('searchSegmentsSelector')}>
        <p
          className={styles.searchTitle}
          aria-label={getAriaLabel('searchSegmentsHeaderSelector')}
        >{`Segments (${store.total})`}</p>
        {store.loading && (
          <div className={styles.spinner}>
            <SpinnerLoader size={24} />
          </div>
        )}
        {!store.loading && store.hasItems && (
          <div className={styles.items}>
            {store.first3Items.map((item) => (
              <SegmentDropdownCard
                key={item.id}
                item={item}
                highlighter={search}
                onChange={handleSelectItem}
                ariaLabel='searchSegmentItem'
              />
            ))}
          </div>
        )}
        {!store.loading && !store.hasItems && (
          <div className={styles.notFound}>No results found</div>
        )}
        {!hasSegmentsType && store.hasItems && (
          <div className={styles.showAll}>
            <Button text={'Show all'} typeBtn={'informative'} onClick={handleShowAll} />
          </div>
        )}
      </div>
    )
  }
)
