import { makeAutoObservable } from 'mobx'
import { toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { WorkflowsApi, type IWorkflow } from 'entities/Workflow'

export class WorkflowOperationStore {
  private _deleteModalId = 'deleteWorkflowModal'

  constructor() {
    makeAutoObservable(this)
  }

  duplicate = async (workflow: IWorkflow) => {
    try {
      const newWorkflow = {
        ...workflow.baseData,
        name: `Copy of ${workflow.name}`,
      }

      if (newWorkflow.name.length > 100) {
        toastStore.add({
          title: 'Failed to duplicate workflow. Name should be no more than 100 characters',
          type: 'error',
        })

        return null
      }

      const {
        data: { payload },
      } = await WorkflowsApi.createWorkflow(newWorkflow)

      return payload
    } catch (error) {
      toastStore.add({
        title: 'Something went wrong. Please try again.',
        type: 'error',
      })

      console.error(error)

      return null
    }
  }

  enable = async (workflow: IWorkflow) => {
    try {
      const {
        data: { payload },
      } = await WorkflowsApi.updateWorkflow(workflow.id, workflow.baseData)
      await WorkflowsApi.enableWorkflow(workflow.id)

      toastStore.add({
        title: `${workflow.name} enabled`,
        type: 'info',
      })

      return { ...payload, enabled: true }
    } catch (error) {
      toastStore.add({
        title: 'Complete set-up of workflow actions, or remove empty actions, before publishing',
        type: 'error',
      })

      console.error(error)

      return null
    }
  }

  disable = async (workflow: IWorkflow) => {
    try {
      await WorkflowsApi.disableWorkflow(workflow.id)

      toastStore.add({
        title: `${workflow.name} disabled`,
        type: 'info',
      })

      return { ...workflow.baseData, enabled: false }
    } catch (error) {
      toastStore.add({
        title: 'Something went wrong. Please try again.',
        type: 'error',
      })

      console.error(error)

      return null
    }
  }

  delete = (workflow: IWorkflow) =>
    new Promise<boolean>((resolve) => {
      const handleDelete = () => {
        modalStore.removeModal(this._deleteModalId)
        WorkflowsApi.deleteWorkflow(workflow.id).then(
          () => {
            toastStore.add({
              title: `${workflow.name} deleted`,
              type: 'success',
            })
            resolve(true)
          },
          () => {
            toastStore.add({
              title: 'Something went wrong. Please try again.',
              type: 'error',
            })
            resolve(false)
          }
        )
      }

      const handleCancel = () => {
        modalStore.removeModal(this._deleteModalId)
        resolve(false)
      }

      modalStore.addModal({
        id: this._deleteModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        zIndex: 2000,
        width: 280,
        type: ModalTypeList.ALERT,
        title: 'Delete workflow?',
        desc: 'This action cannot be undone',
        primaryAction: {
          text: 'Delete',
          onAction: handleDelete,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })
}

export const workflowOperation = new WorkflowOperationStore()
