import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { RegisterStore } from 'widgets/Register/store/registerStore'

type IProps = {
  store: RegisterStore
  modalId: string
}

export const CountriesModalActions = observer(({ store, modalId }: IProps) => {
  const {
    step3Store: { selectedOtherCountry, onSelectCountry, otherCountry },
  } = store

  const handleNotified = () => {
    onSelectCountry(otherCountry)
    modalStore.removeModal(modalId)
  }

  return <Button text='Get notified' disabled={!selectedOtherCountry} onClick={handleNotified} />
})
