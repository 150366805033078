import { FieldDropdown } from 'shared/ui'
import { ScheduleTrigger } from './ui/ScheduleTrigger/ScheduleTrigger'
import { ScheduleDropdownContent } from './ui/ScheduleDropdownContent/ScheduleDropdownContent'

export const ScheduleDropdown = () => {
  return (
    <FieldDropdown
      offset={{ x: -4, y: -24 }}
      labelText={'Schedule'}
      customTriggerComponent={() => <ScheduleTrigger />}
      customComponent={(onCloseMenu, dropdownId) => (
        <ScheduleDropdownContent onCloseMenu={onCloseMenu} dropdownId={dropdownId} />
      )}
    />
  )
}
