import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { AuthLayout } from 'shared/layout'
import { RegisterStore } from 'widgets/Register'
import { Step2Form } from './Step2Form/Step2Form'
import { RightContent } from './RightContent/RightContent'

type IStep1Props = {
  store: RegisterStore
}

export const Step2: FC<IStep1Props> = observer(({ store }) => {
  return (
    <AuthLayout
      title='Tell us about your CRM'
      subtitle='This will help customize the app for you'
      form={<Step2Form store={store} />}
      rightContent={<RightContent />}
      onBack={store.handlePrevStep}
    />
  )
})

export default Step2
