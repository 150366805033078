export enum FieldsEnum {
  companyName = 'companyName',
  role = 'role',
}

export enum RolesEnum {
  entrepreneur = 'entrepreneur',
  salesManager = 'salesManager',
  marketingManager = 'marketingManager',
  customerSuccessManager = 'customerSuccessManager',
  operationsManager = 'operationsManager',
  ITAdministrator = 'ITAdministrator',
  other = 'other',
}

export enum CompanySizeEnum {
  justMe = 'Just me',
  size_2_10 = '2-10',
  size_11_19 = '11-19',
  size_20_49 = '20-49',
  size_50_99 = '50-99',
  size_100_more = '100+',
}

export type IRole = {
  id: RolesEnum
  name: string
}

export type ICompanySize = {
  id: CompanySizeEnum
  name: string
}
