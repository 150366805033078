import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton, Button, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type ISearchElementProps = {
  active: boolean
  count: number
}

export const SearchElement: FC<ISearchElementProps> = observer(({ active, count }) => {
  const hasFilters = Boolean(count)

  if (!hasFilters) {
    return (
      <IconButton
        active={active}
        icon={'filter'}
        variant={'icon'}
        color={'secondary'}
        size={'medium'}
        fontSize={16}
        tooltipProps={{
          label: 'Filter',
          disableInteractive: true,
          placement: 'left',
        }}
        ariaLabel={'SearchElement_filter'}
      />
    )
  }

  const buttonContent = (
    <div className={styles.filterButtonContent}>
      <span className={styles.divider} />
      <span className={styles.count}>
        <Typography variant={'body-xs-medium'} ariaLabel={'totalFiltersCount'}>
          {count}
        </Typography>
      </span>
    </div>
  )

  return (
    <Button
      active={active || hasFilters}
      size='medium'
      contained='secondary'
      typeBtn='text'
      icon='filter'
      tooltipProps={{
        label: 'Filter',
        disableInteractive: true,
        placement: 'left',
      }}
      titleContent={buttonContent}
    />
  )
})
