export class WorkflowEngine {
  static get IsAvailable() {
    return !!window.WorkflowPublicApi
  }

  static get DefaultDefinition() {
    if (!window.WorkflowPublicApi) throw new TypeError('Workflow engine is not available')

    return window.WorkflowPublicApi.DEFAULT_DEFINITION
  }

  static getUiSettingsByName(name: string) {
    if (!window.WorkflowPublicApi) throw new TypeError('Workflow engine is not available')

    return window.WorkflowPublicApi.getUiSettingsByName(name)
  }

  static uiSettingsToDefinition(settings: object[] | null) {
    if (!window.WorkflowPublicApi) throw new TypeError('Workflow engine is not available')

    return window.WorkflowPublicApi.uiSettingsToDefinition(settings)
  }
}
