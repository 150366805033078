import React from 'react'
import { Typography } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table'

type ICellDurationProps = {
  duration: number
}
const CellDuration: React.FC<ICellDurationProps> = ({ duration }) => {
  const getLabel = () => {
    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor((duration - hours * 3600) / 60)
    const seconds = duration - hours * 3600 - minutes * 60

    if (!hours && minutes && seconds) {
      return `${minutes}m ${seconds}s`
    }

    if (!hours && !minutes && seconds) {
      return `${seconds} sec`
    }

    if (!hours && minutes && !seconds) {
      return `${minutes} min`
    }

    if (hours && !minutes && !seconds) {
      return `${hours} hr`
    }

    return `${hours}hr ${minutes}m ${seconds}s`
  }

  return (
    <>
      {Boolean(duration) ? (
        <Typography variant={'body-md-regular'} color={'tertiary'} ariaLabel={'label'}>
          {getLabel()}
        </Typography>
      ) : (
        <EmptyCell />
      )}
    </>
  )
}

export { CellDuration }
