import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventMessage } from 'entities/Message'
import { type ConversationsStore } from 'entities/Conversation'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export const EventMessageUpdatedNew = {
  subscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.on(WebsocketEvent.MessageUpdatedNew, handler),
  unsubscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.off(WebsocketEvent.MessageUpdatedNew, handler),
}

export const useEventMessageUpdatedNew = (
  conversationStore: ConversationsStore,
  conversationMessageStore: ConversationMessagesStore
) =>
  useEffect(
    () =>
      EventMessageUpdatedNew.subscribe((data) => {
        conversationStore.updateItem(data.conversation)
        if (data.message.conversation_id === conversationMessageStore.conversationId) {
          conversationMessageStore.setItems([data.message])
        }
      }),
    []
  )
