import { observer } from 'mobx-react-lite'
import { FC, MouseEvent } from 'react'
import { Button } from 'shared/ui'
import { callStore } from 'entities/Call'
import { AccordionSection, callPopUpGlobalStore, ContactsList } from 'widgets/CallPopUp'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'

type ICallQueueSectionProps = {
  type?: 'default' | 'queue'
}

export const CallQueueSection: FC<ICallQueueSectionProps> = observer(({ type }) => {
  const store = useCallPopUpDefaultContext()
  const { callPopUpQueueStore, callPopUpPowerDialerStore, callPopUpPowerDialerStoreV2 } = store
  const { count, items, hasItem } = callPopUpQueueStore
  const { hasItems } = callPopUpPowerDialerStore
  const { hasContactsToCall } = callPopUpPowerDialerStoreV2

  if (!count) return null

  const disabledButton = Boolean(
    callStore.isBusy || callPopUpGlobalStore.hasActiveV1Call || !hasItem
  )

  const handleCall = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    callPopUpQueueStore.handleCallQueue()
    callPopUpGlobalStore.handleSignalTakeCall(true)
  }
  const showButton = type === 'queue' || hasItems || hasContactsToCall

  return (
    <AccordionSection
      count={count}
      title={'Call queue'}
      renderButton={
        showButton && (
          <Button
            text={'Take call'}
            contained={'primary'}
            intent={'positive'}
            size={'small'}
            disabled={disabledButton}
            onClick={handleCall}
          />
        )
      }
    >
      <ContactsList items={items} />
    </AccordionSection>
  )
})
