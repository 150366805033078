import { Base } from 'models'
import { IResponseNumberShortcodes } from 'entities/Phone/api/types'

export class PhoneShortCode extends Base {
  id: number
  name: string
  numberId: number
  owner_id: number
  formatted_number: string
  national_number: string
  origin!: IResponseNumberShortcodes

  constructor(item: IResponseNumberShortcodes) {
    super()

    this.id = item.id
    this.name = item.name
    this.numberId = item.number.id
    this.owner_id = item.owner_id
    this.formatted_number = item.number.formatted_number
    this.national_number = item.number.national_number
    this.syncOrigin(item)
  }

  syncOrigin = (origin: IResponseNumberShortcodes) => {
    this.id = origin.id
    this.name = origin.name
    this.numberId = origin.number.id
    this.origin = origin
  }
}
