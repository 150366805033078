import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type { IResponseGetExport } from 'entities/Export'
import type {
  IParamsGetContactsStatistic,
  IResponseGetContactsStatistic,
} from 'entities/ContactStatistic'
import { IResponseFilters } from 'entities/Contacts/api/filterTypes'
import type {
  IParamsGetTriggers,
  IParamsTestTrigger,
  IParamsTrigger,
  IResponseGetTriggers,
  IResponseTestTrigger,
  IResponseTrigger,
} from './type'

class Api {
  getTriggers(
    params: IParamsGetTriggers,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseGetTriggers>> {
    return baseApi.post('triggers/all', params, { ...options })
  }

  getTriggerById(id: number | string): Promise<AxiosResponse<IResponseTrigger>> {
    return baseApi.get(`triggers/${id}`)
  }

  getTriggerContactsStatistic(
    id: number,
    params: IParamsGetContactsStatistic
  ): Promise<AxiosResponse<IResponseGetContactsStatistic>> {
    return baseApi.get(`triggers/contacts/${id}`, {
      params,
    })
  }

  createTrigger(params: IParamsTrigger): Promise<AxiosResponse<IResponseTrigger>> {
    return baseApi.post('triggers', params)
  }

  updateTrigger(params: IParamsTrigger): Promise<AxiosResponse<IResponseTrigger>> {
    return baseApi.put(`triggers/${params.id}`, params)
  }

  createTest(params: IParamsTestTrigger): Promise<AxiosResponse<IResponseTestTrigger>> {
    return baseApi.post('triggers/test', params)
  }

  toggleTriggerStatus(id: number): Promise<AxiosResponse<IResponseTrigger>> {
    return baseApi.put(`triggers/toggle/${id}`)
  }

  getExport(id: number): Promise<AxiosResponse<IResponseGetExport>> {
    return baseApi.get(`/triggers/contacts/${id}/export`, {
      params: {
        filter: 'sent_to',
      },
    })
  }

  deleteTrigger(id: number): Promise<void> {
    return baseApi.delete(`triggers/${id}`)
  }

  deleteBulkTriggers(ids: number[], bulkAll: boolean): Promise<AxiosResponse> {
    return baseApi.delete('triggers', { params: { ids, bulk_all: bulkAll } })
  }

  getFilters(): Promise<AxiosResponse<IResponseFilters>> {
    return baseApi.get('triggers/filters-v2')
  }
}

export const TriggerApi = new Api()
