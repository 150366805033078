import { AxiosError, isAxiosError } from 'axios'

export type IError<T> = Error | AxiosError<T>

export interface IErrorBase<T> {
  error: IError<T>
  type: 'axios-error' | 'stock-error'
}

interface IAxiosError<T> extends IErrorBase<T> {
  error: AxiosError<T>
  type: 'axios-error'
}
interface IStockError<T> extends IErrorBase<T> {
  error: Error
  type: 'stock-error'
}

export const errorHandler = <T>(
  error: AxiosError<T> | Error
): Promise<IAxiosError<T> | IStockError<T>> => {
  return new Promise((resolve) => {
    if (isAxiosError(error)) {
      resolve({
        error: error,
        type: 'axios-error',
      })
    } else {
      resolve({
        error: error,
        type: 'stock-error',
      })
    }
  })
}
