import { makeAutoObservable } from 'mobx'
import { Contact } from 'entities/Contacts/model/Contact'

export class CallSearchContactsStore {
  private _contact: Contact | null = null
  private _search = ''
  private _showDialpad = false
  private _loading = false
  private _positionFocus = 0

  constructor() {
    makeAutoObservable(this)
  }

  get positionFocus() {
    return this._positionFocus
  }

  get search() {
    return this._search
  }

  get contact() {
    return this._contact
  }

  get showDialpad() {
    return this._showDialpad
  }

  get loading() {
    return this._loading
  }

  handleToggleShowDialpad = () => {
    this._showDialpad = !this._showDialpad
  }

  handleAddContact = (contact: Contact) => {
    this._contact = contact
    this._search = contact.national_number || ''
  }

  handleSearch = (value: string) => {
    this._contact = null
    this._search = value
  }

  handleDialpadSearch = (value: string | number) => {
    this._contact = null
    this._search = this._search + String(value)
  }

  handleChangeLoader = (status: boolean) => {
    this._loading = status
  }

  setPositionFocus = (value: number) => {
    this._positionFocus = value
  }
}
