import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button, Switch, Typography } from 'shared/ui'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import { adminTenDLCBusinessProfileStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BusinessProfile/store/adminTenDLCBusinessProfileStore'
import { adminTenDLCBrandStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BrandInformation/store/adminTenDLCBrandStore'
import { adminTenDLCCampaignStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/store/adminTenDLCCampaignStore'
import styles from './styles.module.scss'

export const RejectContent = observer(() => {
  const [reason, setReason] = useState('')
  const [businessProfile, setBusinessProfile] = useState(false)
  const [brand, setBrand] = useState(false)
  const [campaign, setCampaign] = useState(false)
  const { id, onRejectTenDLC } = adminTenDLCDetailStore

  const [loading, setLoading] = useState(false)

  const onSave = async () => {
    try {
      if (!id) {
        return
      }
      setLoading(true)
      await onRejectTenDLC({
        reason,
        organization_id: id,
        compliance_provider: 'twilio',
        brand,
        business_profile: businessProfile,
        campaign,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.wrapButtons}>
        {adminTenDLCBusinessProfileStore.isForReview && (
          <div className={styles.btn}>
            <Typography
              variant={'text-input-field'}
              color={'tertiary'}
              ariaLabel={'BusinessProfile'}
            >
              Business profile
            </Typography>
            <Switch value={businessProfile} onChange={setBusinessProfile} />
          </div>
        )}
        {adminTenDLCBrandStore.isForReview && (
          <div className={styles.btn}>
            <Typography
              variant={'text-input-field'}
              color={'tertiary'}
              ariaLabel={'BrandInformation'}
            >
              Brand information
            </Typography>
            <Switch value={brand} onChange={setBrand} />
          </div>
        )}
        {adminTenDLCCampaignStore.isForReview && (
          <div className={styles.btn}>
            <Typography variant={'text-input-field'} color={'tertiary'} ariaLabel={'Campaign'}>
              Campaign
            </Typography>
            <Switch value={campaign} onChange={setCampaign} />
          </div>
        )}
      </div>
      <Typography
        variant={'body-sm-regular'}
        color={'tertiary'}
        className={styles.label}
        ariaLabel={'Reason'}
      >
        Reason
      </Typography>
      <textarea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        className={styles.textarea}
        placeholder={'Enter reason'}
      />
      <div className={styles.actions}>
        <Button text={'Cancel'} contained={'secondary'} />
        <Button
          text={'Save'}
          contained={'primary'}
          disabled={(!businessProfile && !brand && !campaign) || !reason}
          onClick={onSave}
          loading={loading}
        />
      </div>
    </div>
  )
})
