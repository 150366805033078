// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ac71EuDSnBx_1vXl46Ex{display:flex}.Xh55vAnV9996oD7_20UU{width:2px;height:8px;border-radius:100px;background-color:var(--background-neutral-secondary);margin-right:3px}.Xh55vAnV9996oD7_20UU:last-of-type{margin-right:0}.cohbY4lJhAnrMETXj7pV{background-color:var(--background-brand-default)}", "",{"version":3,"sources":["webpack://./src/shared/ui/AudioVolumeLevel/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,sBACE,SAAA,CACA,UAAA,CACA,mBAAA,CACA,oDAAA,CACA,gBAAA,CAEA,mCACE,cAAA,CAGF,sBACE,gDAAA","sourcesContent":[".wrap {\n  display: flex;\n\n  &__item {\n    width: 2px;\n    height: 8px;\n    border-radius: 100px;\n    background-color: var(--background-neutral-secondary);\n    margin-right: 3px;\n\n    &:last-of-type {\n      margin-right: 0;\n    }\n\n    &--active {\n      background-color: var(--background-brand-default);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Ac71EuDSnBx_1vXl46Ex",
	"wrap__item": "Xh55vAnV9996oD7_20UU",
	"wrap__item--active": "cohbY4lJhAnrMETXj7pV"
};
export default ___CSS_LOADER_EXPORT___;
