import { useEffect, useState, FC, Fragment, UIEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography, SpinnerLoader, Scrollbar } from 'shared/ui'
import { TagDropdownCard, tagsStore } from 'entities/Tags'
import { Tag } from 'entities/Tags/model/Tag'
import { SearchDropdownTagsStore } from 'widgets/SearchDropdown'
import styles from './styles.module.scss'

type ITagsDropdownContentItemsProps = {
  store: SearchDropdownTagsStore
  onChange?: (item: Tag) => void
}

export const TagsDropdownContentItems: FC<ITagsDropdownContentItemsProps> = observer(
  ({ store, onChange }) => {
    const { loading, hasMore, itemsListIds, loadMore, hasItems, init, options } = store
    const [selectedIds, setSelectedIds] = useState<number[]>([])

    const handleScroll = (e: UIEvent) => {
      const target = e.target as HTMLDivElement
      const scrollHeight = target.scrollHeight
      const scrollTop = target.scrollTop
      const offsetHeight = target.offsetHeight

      if (loading) return
      if (!hasMore) return
      if (scrollHeight <= scrollTop + offsetHeight + 100) {
        loadMore()
      }
    }

    const handleSelect = (item: Tag) => {
      onChange?.(item)
      store.selectTypeItem(item)
    }

    const isSelected = (id: number | undefined) => {
      if (!id) return false

      return store.isSelected(id)
    }

    useEffect(() => {
      setSelectedIds(store.selectedItemsListIds)

      if (!loading) {
        init()
      }
    }, [])

    return (
      <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={290} onScroll={handleScroll}>
        <div className={styles.list}>
          {[...selectedIds, ...itemsListIds].map((id, index) => {
            const item = tagsStore.getItem(id)
            if (!item) return

            return (
              <Fragment key={item.id}>
                <TagDropdownCard
                  InputCheckboxProps={
                    options?.selectedMode
                      ? {
                          checked: isSelected(id),
                          onChecked: () => handleSelect(item),
                        }
                      : undefined
                  }
                  onChange={handleSelect}
                  item={item}
                />
                {index === selectedIds.length - 1 && <div className={styles.divider}></div>}
              </Fragment>
            )
          })}
          {!hasItems && !loading && (
            <div className={styles.epmtyList}>
              <Typography variant={'body-rg-regular'} ariaLabel={'Not found'}>
                Not found
              </Typography>
            </div>
          )}
          {loading && (
            <div className={styles.spinner}>
              <SpinnerLoader />
            </div>
          )}
        </div>
      </Scrollbar>
    )
  }
)
