import { Box } from '@mui/material'
import { ReactNode } from 'react'
import {
  Avatar,
  IColumn,
  IconButton,
  IDropdownItem,
  IFilterDropdownGroups,
  IIconsVector,
  IOrderedGroups,
  ITableConfig,
  ItemsWithCount,
  Tooltip,
  Typography,
} from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import { CellWithoutContent, EmptyCell } from 'shared/ui/Table'
import { uiStore } from 'shared/store/uiStore'
import { TagCard } from 'entities/Tags'
import { Contact } from 'entities/Contacts/model/Contact'
import { usersStore } from 'entities/Users'
import { IImportField } from 'entities/Contacts/api/types'
import { IResponseCustomFilter, IResponseFilter } from 'entities/Contacts/api/filterTypes'
import { getIcon } from 'widgets/constants'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'
import { tableColumnsKeys } from '../constants/tableColumnsConsts'
import { KeywordItem } from '../ui/KeywordItem/KeywordItem'
import { UpgradeButton } from '../ui/UpgradeButton/UpgradeButton'

// No all clearedField has names as in the current design. Change it manually
const nameExceptions = {
  name: 'Name',
  number: 'Number',
  email: 'Email',
  created_at: 'Created At',
} as Record<string, string>

const getInitGroups = (groupsNames: Record<string, string>): IFilterDropdownGroups => {
  const groups: IFilterDropdownGroups = {}
  const keys = Object.keys(groupsNames)

  keys.forEach((group) => {
    groups[group] = {
      label: groupsNames[group],
      items: [],
    }
  })

  return groups
}

const getOrderedGroups = (groupNames: Record<string, string>, groups: IFilterDropdownGroups) => {
  const orderedGroups: IOrderedGroups[] = []
  const keys = Object.keys(groupNames)

  keys.forEach((group) => {
    orderedGroups.push({
      label: groupNames[group],
      items: groups[group].items,
    })
  })

  return orderedGroups
}

const getCorrectDropdownItemLabel = ({
  name,
  key,
  category,
}: {
  name: string
  key: string
  category: string
}) => {
  const label = nameExceptions[key] || name
  if (category === 'recurring') {
    return `Recurring ${label.toLocaleLowerCase()}`
  }
  return label
}

const importContactGroupsName: Record<string, string> = {
  default_fields: 'Default fields',
  custom: 'Custom fields',
}

export const getImportContactDropdownGroups = (fields: IImportField[], mappedKeys: string[]) => {
  const groups: IFilterDropdownGroups = getInitGroups(importContactGroupsName)

  fields.forEach((filter) => {
    const dropdownItem: IDropdownItem = {
      id: filter.key,
      label: getCorrectDropdownItemLabel({
        category: filter.category_new,
        name: filter.name,
        key: filter.key,
      }),
      disabled: mappedKeys.includes(filter.key),
      value: filter.name,
      iconL: getIcon(filter),
      parentTooltipProps: {
        label: mappedKeys.includes(filter.key) ? 'Field is already selected' : filter.name,
        placement: 'left',
        fullWidth: true,
      },
    }

    const groupName = filter.key.includes('custom.') ? 'custom' : 'default_fields'

    groups[groupName].items.push(dropdownItem)
  })

  return getOrderedGroups(importContactGroupsName, groups)
}

const filterTransformer = ({ filters, customFilters }: IFiltersResponseList) => {
  const preparedFilters: { [key: string]: string } = {}

  filters.forEach((filter) => {
    preparedFilters[filter.key] = filter.name
  })
  customFilters.forEach((filter) => {
    const filterKey = filter.key.split('.').pop()

    if (filterKey) {
      preparedFilters[filterKey] = filter.name
    }
  })

  return preparedFilters
}

export const getTrialLimitDisabledLabel = () => {
  return usersStore.user?.isAdminOrOwnerRole
    ? 'You have reached the limit of 250 contacts on Trial plan. Upgrade to remove the limits.'
    : 'You have reached the limit of 250 contacts on Trial plan. To remove the limits, please ask one of your admins to upgrade.'
}

type IGetCellForName = {
  row: Contact
  column: IColumn<Contact>
  onOpenContactsDetails: IOpenContactsDetails
  config?: ITableConfig
}

export const getCellForName = ({ row, onOpenContactsDetails, config }: IGetCellForName) => {
  const newContact = row.id === -1
  const info = row.isDisabled ? { icon: 'lock' as IIconsVector } : row.avatarInfo
  const limitDisabledLabel = getTrialLimitDisabledLabel()
  const showSendMessageBubble = !newContact && !row.isDisabled && !config?.hideSendMessage

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box
        sx={{
          alignItems: 'center',
          width: '80%',
          cursor: row.isDisabled ? 'default' : 'pointer',
          height: '32px',
        }}
        gap={1}
        display='flex'
      >
        {row.isDisabled ? (
          <Tooltip
            placement='top'
            sx={{ width: '280px' }}
            type='description'
            label={limitDisabledLabel}
            desc={usersStore.user?.isAdminOrOwnerRole ? <UpgradeButton /> : null}
          >
            <Avatar info={info} size={24} />
          </Tooltip>
        ) : (
          <Avatar info={info} size={24} />
        )}

        {row.fullName ? (
          <Typography
            pointer={!row.isDisabled}
            ellipsis
            variant={'body-md-regular'}
            tag={'div'}
            ariaLabel={'formattedValue'}
          >
            {row.fullName}
          </Typography>
        ) : (
          <EmptyCell />
        )}
      </Box>

      {showSendMessageBubble && (
        <Tooltip label='Conversation' placement='top'>
          <IconButton
            icon='chatRead'
            variant='icon'
            color='tertiary'
            transparentInactive
            className={config?.hidden || ''}
            disabled={onOpenContactsDetails.disabled}
            onClick={(event) => {
              event.stopPropagation()
              onOpenContactsDetails.open({
                contactId: row.id,
              })
            }}
            ariaLabel='ContactRow_sendMessage'
          />
        </Tooltip>
      )}
    </Box>
  )
}

export const getCustomRowCellValue = (row: Contact, column: IColumn<Contact>) => {
  const customField = row.custom_fields.find((field) => field.field_key === column.field)

  if (!customField || !customField.value.value) {
    return null
  }

  const isDateValue = customField.value.type === 'date'

  return isDateValue
    ? uiStore.dayjs(customField.value.value).format(DayjsFormats.full5)
    : customField.value.value
}

const getNumberStatusValue = (row: Contact, column: IColumn<Contact>) => {
  return row[column.field as keyof Contact] ? 'Invalid' : 'Valid'
}

export const getCellForTags = (row: Contact) => {
  if (!row.tags.length) {
    return <EmptyCell />
  }

  return (
    <ItemsWithCount
      items={row.tags}
      itemComponent={({ item }) => <TagCard tag={item} />}
      containerWidth={362}
      withLeftIcon
      width={164}
      disabled={row.isDisabled}
    />
  )
}

const getCellForKeywords = (row: Contact) => {
  if (!row.keywords.length) {
    return <EmptyCell />
  }

  return <ItemsWithCount items={row.keywords} itemComponent={KeywordItem} containerWidth={362} />
}

const getCellForOptOut = (row: Contact, column: IColumn<Contact>) => {
  const optOut = row.shortcode_opt_outs.find(
    ({ id }) => String(id) === column.field.split('.').pop()
  )
  const correctDate =
    optOut?.timestamp &&
    uiStore.dayjs(String(optOut?.timestamp)).format(DayjsFormats.fullWithAtDash)

  return correctDate || <EmptyCell />
}

export const getCellNumber = (row: Contact): ReactNode => {
  if (row.id === -1) {
    return <CellWithoutContent />
  }

  return row.formatted_number
}

const getCellForSource = (row: Contact, column: IColumn<Contact>) => {
  const currentValue = column?.values?.find((value) => String(value.key) === row.source)

  return currentValue?.label
}

const getCellForAreaCode = (row: Contact) => {
  return row.area_code
}

const isCellWithoutContent = (row: Contact) => row.id === -1
const withAreaLabelColumns = ['name', 'number', 'email']

type IFiltersResponseList = {
  filters: IResponseFilter[]
  customFilters: IResponseCustomFilter[]
}

export const columnTransformer = (
  filtersList: IFiltersResponseList,
  activeFields: string[],
  onOpenContactsDetails: IOpenContactsDetails
): IColumn<Contact>[] => {
  const preparedFilters = filterTransformer(filtersList)

  return activeFields.map((field) => {
    const customFilter = field.includes('custom.')
    let clearedField = field

    if (customFilter) {
      // Delete 'custom.' prefix
      clearedField = field.split('.').pop() || ''
    }

    const currentFilter = filtersList.filters.find((filter) => filter.key === field)

    const getName = () => {
      if (!customFilter && clearedField === 'tag') {
        return 'Tags'
      }

      return nameExceptions[clearedField] || preparedFilters[clearedField]
    }
    const name = getName()

    const preparedColumn: IColumn<Contact> = {
      field: clearedField,
      name,
      values: (Array.isArray(currentFilter?.values) ? currentFilter?.values : []) as {
        key: string | number
        label: string
      }[],
      ariaLabel: withAreaLabelColumns.includes(clearedField)
        ? `ContactRowCell_${clearedField}`
        : undefined,
    }
    if (sortByMapping[clearedField]) {
      preparedColumn.isSortable = true
    }

    if (customFilter) {
      return {
        name,
        field,
        isSortable: true,
        renderRowCell: getCustomRowCellValue,
      }
    }

    if (clearedField === tableColumnsKeys.name) {
      preparedColumn.renderRowCell = (row, column, config) =>
        getCellForName({ row, column, config, onOpenContactsDetails })
      preparedColumn.width = '100%'
    }

    if (clearedField === tableColumnsKeys.is_invalid) {
      preparedColumn.renderRowCell = getNumberStatusValue
    }

    if (clearedField === tableColumnsKeys.tag) {
      preparedColumn.renderRowCell = getCellForTags
    }

    if (clearedField === tableColumnsKeys.source) {
      preparedColumn.renderRowCell = getCellForSource
    }

    if (clearedField === tableColumnsKeys.keyword) {
      preparedColumn.renderRowCell = getCellForKeywords
    }

    if (field.includes('opt_out_at_shortcode')) {
      preparedColumn.renderRowCell = getCellForOptOut
    }

    if (clearedField === tableColumnsKeys.number) {
      preparedColumn.renderRowCell = getCellNumber
    }

    if (clearedField === tableColumnsKeys.areaCode) {
      preparedColumn.renderRowCell = getCellForAreaCode
    }

    preparedColumn.isCellWithoutContent = isCellWithoutContent

    return preparedColumn
  })
}

export const sortByMapping = {
  name: 'first_name',
  number: 'formatted_number',
  email: 'email',
  lookup_type: 'lookup_type',
  keyword: 'keywords_count',
  tag: 'tags_count',
  created_at: 'created_at',
  is_invalid: 'is_invalid',
  carrier_name: 'carrier_name',
  updated_at: 'updated_at',
  opt_out_at: 'opt_out_at',
  source: 'source',
  area_code: 'area_codes.area_code',
  state_code: 'area_codes.state_code',
  time_zone: 'area_codes.time_zone',
  country: 'area_codes.country',
} as Record<string, string>
