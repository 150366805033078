import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { CannedMessage } from 'entities/CannedMessages/model/CannedMessage'
import { SavedReplyItem } from 'widgets/SavedReplies/ui/SavedRepliesContent/LeftContent/SavedRepliesList/SavedReplyItem/SavedReplyItem'
import styles from './styles.module.scss'

type Props = {
  list: CannedMessage[]
  title?: string
}

export const SavedRepliesList: FC<Props> = observer(({ list, title }) => {
  return (
    <div>
      {!!list.length && (
        <div>
          {title && (
            <div className={styles.wrapLabel}>
              <Typography
                variant={'body-sm-medium'}
                color={'var(--content-primary-tertiary)'}
                ariaLabel={'My replies'}
              >
                {title}
              </Typography>
            </div>
          )}
          {list.map((item) => (
            <SavedReplyItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  )
})
