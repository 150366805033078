import { type Message } from 'entities/Message/model/Message'
import { type Attachment } from 'entities/Attachment/model/Attachment'

export const MessageCardType = {
  Call: 'call',
  Conference: 'conference',
  Images: 'images',
  GifPreview: 'gif-preview',
  Video: 'video',
  Text: 'text',
  OtherAttachment: 'other-attachment',
  Attachment: 'attachment',
} as const

export type IMessageCardType = (typeof MessageCardType)[keyof typeof MessageCardType]

export type MessageProps = {
  message: Message
  attachments?: Attachment[]
}
