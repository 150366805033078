// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".enzJ7G9PWG2mREfuY3YT{display:flex;align-items:center;gap:8px;padding:0 16px 0 8px}.enzJ7G9PWG2mREfuY3YT.PPoK602X78xc7xFtTjs7{padding:0}.F_rIiwXNzPJOwPSku72A{display:grid;justify-content:start}.F_rIiwXNzPJOwPSku72A span{text-align:left}.F_rIiwXNzPJOwPSku72A .A8U459tYT68ZTSxhBOZZ{color:var(--content-primary-tertiary)}.hEryTWjox_DlZwycKoc1{width:fit-content;display:grid;grid-template-columns:auto max-content}.EQDHZ34SalR02124H07y{cursor:pointer;padding:6px;border-radius:50%;background:var(--gray-20)}.EQDHZ34SalR02124H07y.SzunpY89N5r1LzPQIxez{background:var(--background-primary-inverted-primary)}", "",{"version":3,"sources":["webpack://./src/entities/Attachment/ui/AttachmentCard/AttachmentCardContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,oBAAA,CACA,2CACE,SAAA,CAGJ,sBACE,YAAA,CACA,qBAAA,CACA,2BACE,eAAA,CAEF,4CACE,qCAAA,CAGJ,sBACE,iBAAA,CACA,YAAA,CACA,sCAAA,CAEF,sBAIE,cAAA,CACA,WAAA,CACA,iBAAA,CACA,yBAAA,CANA,2CACE,qDAAA","sourcesContent":[".content{\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 0 16px 0 8px;\n  &.fromAudioCard{\n    padding: 0;\n  }\n}\n.right{\n  display: grid;\n  justify-content: start;\n  span{\n    text-align: left;\n  }\n  .time{\n    color: var(--content-primary-tertiary);\n  }\n}\n.title{\n  width: fit-content;\n  display: grid;\n  grid-template-columns: auto max-content;\n}\n.audioBtn{\n  &.white{\n    background: var(--background-primary-inverted-primary);\n  }\n  cursor: pointer;\n  padding: 6px;\n  border-radius: 50%;\n  background: var(--gray-20);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "enzJ7G9PWG2mREfuY3YT",
	"fromAudioCard": "PPoK602X78xc7xFtTjs7",
	"right": "F_rIiwXNzPJOwPSku72A",
	"time": "A8U459tYT68ZTSxhBOZZ",
	"title": "hEryTWjox_DlZwycKoc1",
	"audioBtn": "EQDHZ34SalR02124H07y",
	"white": "SzunpY89N5r1LzPQIxez"
};
export default ___CSS_LOADER_EXPORT___;
