// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MSDfs17IHY3Z7puA74pw{display:flex;height:100%}.MSDfs17IHY3Z7puA74pw .E0WF1noiLjQIuItEesbA{width:100%;display:flex;height:100%;flex-direction:column}.MSDfs17IHY3Z7puA74pw .p9dUGRXxO87SuoKb32Lq{display:flex;height:100%;overflow:auto;position:relative}.MSDfs17IHY3Z7puA74pw .E8v_K4u9I70YC9m5Ks40{position:relative;display:flex;flex-direction:column;align-items:stretch;overflow-x:auto;width:100%}.MSDfs17IHY3Z7puA74pw .E8v_K4u9I70YC9m5Ks40.W9omiYmGIoLHpoMAQkWg{overflow:hidden}.MSDfs17IHY3Z7puA74pw .E8v_K4u9I70YC9m5Ks40 .NRoR2875qUFhTVdZo1aE{display:flex;flex-grow:1;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/pages/conversations/ui/conversations/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAEA,4CACE,UAAA,CACA,YAAA,CACA,WAAA,CACA,qBAAA,CAGF,4CACE,YAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CAGF,4CACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CACA,eAAA,CACA,UAAA,CAEA,iEACE,eAAA,CAGF,kEACE,YAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".wrap {\n  display: flex;\n  height: 100%;\n\n  .headerSearchWrap {\n    width: 100%;\n    display: flex;\n    height: 100%;\n    flex-direction: column;\n  }\n\n  .searchContentWrap {\n    display: flex;\n    height: 100%;\n    overflow: auto;\n    position: relative;\n  }\n\n  .contentWrapper {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    overflow-x: auto;\n    width: 100%;\n\n    &.disabledScroll {\n      overflow: hidden;\n    }\n\n    .content {\n      display: flex;\n      flex-grow: 1;\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "MSDfs17IHY3Z7puA74pw",
	"headerSearchWrap": "E0WF1noiLjQIuItEesbA",
	"searchContentWrap": "p9dUGRXxO87SuoKb32Lq",
	"contentWrapper": "E8v_K4u9I70YC9m5Ks40",
	"disabledScroll": "W9omiYmGIoLHpoMAQkWg",
	"content": "NRoR2875qUFhTVdZo1aE"
};
export default ___CSS_LOADER_EXPORT___;
