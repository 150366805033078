import { Base } from 'models'
import {
  IResponseActiveCampaignProperties,
  IResponseActiveCampaignAccounts,
  IResponseActiveCampaignDeal,
  IDeal,
  IResponseIntegrationsContactsActiveCampaignData,
} from 'entities/Integrations'

export class ContactIntegrationActiveCampaignInfo extends Base {
  contactId: number
  deals: IDeal[]
  accounts: IResponseActiveCampaignAccounts | null
  properties: IResponseActiveCampaignProperties | null

  constructor(item: IResponseIntegrationsContactsActiveCampaignData) {
    super()

    this.contactId = item.contactId
    this.deals = item.deals?.map((deal: IResponseActiveCampaignDeal) => {
      return {
        amount: {
          value: deal.value,
        },
        dealname: {
          value: deal.title,
        },
        closedate: {
          value: null,
        },
        dealstage: {
          value: null,
        },
        owner: deal.owner || null,
        id: {
          value: null,
        },
      }
    })
    this.accounts = item.accounts || null
    this.properties = item.properties || null
  }
}
