// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GsmnMiWbyF6yBkc5fv7W{padding-left:20px;padding-bottom:32px;display:flex;align-items:center;gap:8px}.VlSFFyMycI8a9qDpQ816{margin:-8px 20px 16px 20px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastDetail/BroadcastDetailHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,mBAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,0BAAA","sourcesContent":[".info{\n  padding-left: 20px;\n  padding-bottom: 32px;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.alert{\n  margin: -8px 20px 16px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "GsmnMiWbyF6yBkc5fv7W",
	"alert": "VlSFFyMycI8a9qDpQ816"
};
export default ___CSS_LOADER_EXPORT___;
