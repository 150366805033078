import { Attachment } from 'entities/Attachment/model/Attachment'

export const getAttachmentLabel = (attachment: Attachment) => {
  const ext = attachment.extension?.toLowerCase()

  switch (ext) {
    case 'jpg':
    case 'jpeg':
      return 'JPG image'
    case 'png':
      return 'PNG image'
    case 'gif':
      return 'GIF image'
    case 'mp4':
      return 'MP4 video'
    case 'mov':
      return 'MOV video'
    case 'mp3':
      return 'MP3 audio'
    case 'pdf':
      return 'PDF file'
    case 'doc':
    case 'docx':
      return 'DOC file'
    case 'csv':
      return 'CSV file'
    case 'xls':
    case 'xlsx':
      return 'XLS file'
    case 'vcf':
      return 'vCard'
    default:
      return 'File'
  }
}
