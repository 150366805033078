import { observer } from 'mobx-react-lite'
import { TextField, makeTextFieldError } from 'shared/ui/TextField'
import { useChatbotContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export const ChatbotHeader = observer(() => {
  const { model, errors } = useChatbotContext()
  const nameError = errors.getError('name')

  const handleNameChange = (value: string) => {
    model.setName(value)
    errors.removeError('name')
  }

  return (
    <section className={styles.header}>
      <TextField
        className={styles.textField}
        variant='integrated'
        value={model.name}
        onChange={handleNameChange}
        InputProps={{
          placeholder: 'Textbot name',
        }}
        error={nameError}
        rightActions={[makeTextFieldError({ placement: 'bottom' })]}
      />
    </section>
  )
})
