import { AttachmentFetchType } from 'entities/Attachment'
import { IMediaLibraryType } from 'widgets/MediaLibrary/types'

export const getAttachmentFetchTypeByContentType = (contentType: IMediaLibraryType) => {
  const contentTypeMap: Record<IMediaLibraryType, AttachmentFetchType | null> = {
    images: AttachmentFetchType.Image,
    videos: AttachmentFetchType.Video,
    audio: AttachmentFetchType.Audio,
    files: AttachmentFetchType.Docs,
    all: null,
  }

  return contentTypeMap[contentType]
}
