// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M9Swgst5JQinvf7lJsxg{margin:0 12px;width:12px;text-align:center}.x9OwY6Dr64eNByCYo3KN{color:var(--content-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/shared/ui/NumberCounter/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,UAAA,CACA,iBAAA,CAEF,sBACE,oCAAA","sourcesContent":[".text {\n  margin: 0 12px;\n  width: 12px;\n  text-align: center;\n}\n.optionalLabel {\n  color: var(--content-neutral-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "M9Swgst5JQinvf7lJsxg",
	"optionalLabel": "x9OwY6Dr64eNByCYo3KN"
};
export default ___CSS_LOADER_EXPORT___;
