import { type Contact } from 'entities/Contacts/model/Contact'
import { type Conversation } from 'entities/Conversation/model/Conversation'
import {
  IOpportunity,
  IResponseIntegrationHubspotCompany,
  IResponseIntegrationHubspotDeal,
} from 'entities/Integrations'
import { type ContactsTableStore } from 'widgets/ContactsTable'
import { type ConversationNewSearchStore } from 'widgets/ConversationNew'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'
import { type ContactsDetailsGlobalStore } from './contactsDetailsGlobalStore'

export type IContactProfileCard = {
  onClickMessage?: (contact: Contact) => void
  onClickCall?: (id: number) => void
}

export type IContactsDetailsVariant = 'conversation' | 'contacts' | 'create_contact_modal'

export type IContactsDetailsStoreConfig = {
  contact?: Contact | null
  contactsDetailsGlobalStore?: ContactsDetailsGlobalStore
  conversation?: Conversation | null
  profileCardProps?: IContactProfileCard
  onUpdateContact?: (contact: Contact) => void
  onUpdateConversation?: (conversation: Conversation) => void
  onCloseContactDetails?: () => void
  onStopCreateNewContact?: () => void
  reassigning?: boolean
  addNewContactLocallyAfterCreate?: (contact: Contact, params?: { isNew?: boolean }) => void
  variant: IContactsDetailsVariant
  contactsTableStore?: ContactsTableStore
  conversationNewSearchStore?: ConversationNewSearchStore
  conversationMessagesStore?: ConversationMessagesStore
  contactCreateModalStore?: ContactCreateModalStore
}

export type IViewTypes =
  | 'notes'
  | 'deals'
  | 'main'
  | 'companies'
  | 'opportunities'
  | 'conversations-history'

export enum EnumIntegrationTabs {
  Contact = 'contact',
  Account = 'account',
  Deals = 'deals',
  Companies = 'companies',
  Company = 'company',
  Opportunities = 'opportunities',
  Organization = 'organization',
}

export type IViewAllData = {
  deals: IResponseIntegrationHubspotDeal[] | []
  companies: IResponseIntegrationHubspotCompany[] | []
  opportunities: IOpportunity[] | []
}

export type IViewAllDataProps = {
  deals?: IResponseIntegrationHubspotDeal[] | []
  companies?: IResponseIntegrationHubspotCompany[] | []
  opportunities?: IOpportunity[] | []
}
