// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OZnnOa1H3xjwzSHkEaVF{display:flex;gap:22px;flex-direction:column;padding:24px 0 16px;align-items:flex-start}.MPfkdzN5oRZ9yR7h2ug9{flex:1;align-items:center;min-width:100%}.MPfkdzN5oRZ9yR7h2ug9>input{font-size:24px;font-style:normal;font-weight:500;line-height:133.3%;letter-spacing:-0.24px;height:auto}.vucBMCpy630VmIQlB8vU{padding-right:8px !important}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotEdit/ChatbotHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,qBAAA,CACA,mBAAA,CACA,sBAAA,CAGF,sBACE,MAAA,CACA,kBAAA,CACA,cAAA,CAEA,4BACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAIJ,sBACE,4BAAA","sourcesContent":[".header {\n  display: flex;\n  gap: 22px;\n  flex-direction: column;\n  padding: 24px 0 16px;\n  align-items: flex-start;\n}\n\n.textField {\n  flex: 1;\n  align-items: center;\n  min-width: 100%;\n\n  & > input {\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 133.3%;\n    letter-spacing: -0.24px;\n    height: auto;\n  }\n}\n\n.typeButton {\n  padding-right: 8px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OZnnOa1H3xjwzSHkEaVF",
	"textField": "MPfkdzN5oRZ9yR7h2ug9",
	"typeButton": "vucBMCpy630VmIQlB8vU"
};
export default ___CSS_LOADER_EXPORT___;
