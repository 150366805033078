import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { InView } from 'react-intersection-observer'
import { DropdownCard, Icon, Scrollbar, SpinnerLoader, Tooltip } from 'shared/ui'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { useClickAway } from 'shared/hooks/useClickAway'
import { CreateTagButton } from 'entities/Tags/ui/CreateTagButton'
import { TagDropdownCard } from 'entities/Tags/ui//TagDropdownCard'
import { TagCard, TagCardCount } from 'entities/Tags/ui/TagCard'
import { TagsControl } from 'entities/Tags/store/tagsControl'
import { TagsSearch } from 'entities/Tags/store/tagsSearch'
import { Tag } from 'entities/Tags/model/Tag'

import styles from './styles.module.scss'

export interface ITagAction {
  type: 'add' | 'remove' | 'create'
  tag: Tag
}

export type ITagDropdownAction = 'open' | 'close'

interface ITagSelectErrorProps {
  message?: string | null
  showTooltip?: boolean
}

export interface ITagSelectProps {
  control: TagsControl
  variant?: 'select' | 'formField'
  limit?: number
  placeholder?: string
  searchPlaceholder?: string
  canCreate?: boolean
  error?: ITagSelectErrorProps | null
  onChange?: (action: ITagAction) => void
  onDropdownChange?: (action: ITagDropdownAction) => void
}

export const TagsSelect = observer(
  ({
    control,
    variant = 'select',
    limit = 8,
    placeholder,
    searchPlaceholder,
    canCreate,
    error,
    onChange,
    onDropdownChange,
  }: ITagSelectProps) => {
    const placeholderRef = useRef<HTMLDivElement | null>(null)
    const [errorIsVisible, setErrorVisibility] = useState(false)

    const [term, setTerm] = useState('')
    const [tagCreating, setTagCreating] = useState(false)
    const [dropdownOpen, setDropdownOpenOpen] = useState(false)
    const tagsSearch = useMemo(() => new TagsSearch(), [])

    const tags = control.tags
    const limitedTags = control.tags.slice(0, limit)
    const restTagsCount = control.tags.length - limit
    const placeholderTags = dropdownOpen ? tags : limitedTags

    const showTextField = !control.synced || !control.tags.length || dropdownOpen
    const showTagsRestCount = !dropdownOpen && restTagsCount > 0

    const textFieldViewOnly = tagCreating || !control.synced || !dropdownOpen
    const placeholderByDropdown =
      dropdownOpen && searchPlaceholder ? searchPlaceholder : placeholder
    const textFieldPlaceholder = control.synced ? placeholderByDropdown ?? 'Search' : 'Loading'

    const isSearchLoading = tagsSearch.empty && tagsSearch.loading
    const isSearchHasMore = !tagsSearch.empty && tagsSearch.hasMore
    const isSearchNoResults = tagsSearch.empty && !tagsSearch.loading
    const isSearchHasResults = !tagsSearch.empty

    const isFormField = variant === 'formField'

    const isNoCreateButton =
      !canCreate ||
      tagsSearch.loading ||
      tagsSearch.hasMore ||
      !term.trim() ||
      tagsSearch.tags.some((tag) => tag.label === term) ||
      control.tags.some((tag) => tag.label === term)

    const handleLoadMore = useCallback(() => {
      const loadMore = !tagsSearch.loading && tagsSearch.hasMore

      if (loadMore) tagsSearch.loadMore()
    }, [tagsSearch.loading && tagsSearch.hasMore])

    const handleDropdownOpen = () => {
      if (dropdownOpen) return

      setTerm('')
      setDropdownOpenOpen(true)
      onDropdownChange?.('open')
    }

    const clickAwayRef = useClickAway<HTMLDivElement>(() => {
      if (!dropdownOpen) return

      setDropdownOpenOpen(false)
      onDropdownChange?.('close')
      setTerm('')
      tagsSearch.reset()
    })

    const handleCreateTag = () => {
      setTagCreating(true)
      tagsSearch.reset()

      control
        .createTag(term)
        .then((tag) => onChange?.({ type: 'create', tag }))
        .finally(() => {
          setTerm('')
          tagsSearch.searchTags('', control.ids)
          setTagCreating(false)
        })
    }

    const handleAddTag = (tag: Tag) => {
      control.addTag(tag)
      onChange?.({ type: 'add', tag })
    }

    const handleRemoveTag = (tag: Tag) => {
      control.removeTag(tag.id)
      onChange?.({ type: 'remove', tag })
    }

    useEffect(() => {
      control.syncIds()
    }, [])

    useEffect(() => {
      if (!control.synced || !dropdownOpen) return

      tagsSearch.searchTags(term, control.ids)
    }, [control.synced, dropdownOpen, control.ids, term])

    return (
      <div
        ref={clickAwayRef}
        className={classNames(styles.tags, {
          [styles.formField]: isFormField,
        })}
        {...(dropdownOpen ? { ['data-active']: '' } : {})}
      >
        <div
          ref={placeholderRef}
          className={classNames(styles.tagsPlaceholder, {
            [styles.tagsPlaceholderError]: !!error?.message,
            [styles.formField]: isFormField,
          })}
          onClick={handleDropdownOpen}
          onMouseEnter={() => setErrorVisibility(true)}
          onMouseLeave={() => setErrorVisibility(false)}
        >
          {isFormField && !dropdownOpen && !placeholderTags.length && (
            <Icon icon={'tag'} fontSize={16} tertiary className={styles.triggerIcon} />
          )}
          <div
            className={classNames(styles.tagsPlaceholderCards, {
              [styles.formField]: isFormField,
            })}
            aria-label='AddedTagsListItems'
          >
            {control.synced &&
              placeholderTags.map((tag) => (
                <TagCard
                  className={styles.tagsPlaceholderCard}
                  key={tag.id}
                  tag={tag}
                  closable={dropdownOpen}
                  onRemoveTag={handleRemoveTag}
                />
              ))}
            {showTagsRestCount && <TagCardCount count={restTagsCount} />}
            {showTextField && (
              <TextField
                variant='integrated'
                viewOnly={textFieldViewOnly}
                className={classNames(styles.tagsPlaceholderField, {
                  [styles.formField]: isFormField,
                })}
                value={term}
                onChange={setTerm}
                InputProps={{
                  placeholder: textFieldPlaceholder,
                }}
                ariaLabel='TagsTextFieldSearchInput'
                mainActions={[makeTextFieldAutoFocus({ withFocus: dropdownOpen })]}
              />
            )}
          </div>
          {!dropdownOpen && !!error?.message && !!error?.showTooltip && (
            <Tooltip
              color='red'
              placement='right'
              open={errorIsVisible}
              label={error.message}
              PopperProps={{
                anchorEl: () => placeholderRef.current as HTMLElement,
              }}
            >
              <Icon icon='alertTriangle' fontSize={16} color={'var(--content-negative-primary)'} />
            </Tooltip>
          )}
          {!isFormField && <Icon className={styles.tagsPlaceholderChevron} icon='chevronDown' />}
        </div>
        {dropdownOpen && (
          <div
            className={classNames(styles.tagsList, {
              [styles.formField]: isFormField,
            })}
            aria-label='TagsListItems'
          >
            <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={190}>
              {isSearchLoading && <SpinnerLoader className={styles.spinner} />}
              <div className={styles.tagsListContent}>
                {isSearchHasResults &&
                  tagsSearch.tags.map((tag) =>
                    isFormField ? (
                      <TagDropdownCard
                        key={tag.id}
                        item={tag}
                        onChange={() => {
                          setTerm('')
                          handleAddTag(tag)
                        }}
                      />
                    ) : (
                      <DropdownCard
                        key={tag.id}
                        item={{
                          id: tag.id,
                          label: tag.label,
                          iconL: 'colorDot',
                          ariaLabel: 'TagsListItem',
                          customFields: {
                            leftIconColor: 'var(--green-60)',
                          },
                        }}
                        onChange={() => {
                          setTerm('')
                          handleAddTag(tag)
                        }}
                      />
                    )
                  )}
                {!isNoCreateButton && <CreateTagButton term={term} onClick={handleCreateTag} />}
              </div>
              <InView onChange={(inView) => inView && handleLoadMore()}>
                {({ ref }) => (
                  <div ref={ref}>
                    {isSearchHasMore && <SpinnerLoader className={styles.spinner} />}
                    {isSearchNoResults && isNoCreateButton && (
                      <div className={styles.noResults}>No results found</div>
                    )}
                  </div>
                )}
              </InView>
            </Scrollbar>
          </div>
        )}
      </div>
    )
  }
)
