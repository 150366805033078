import { makeAutoObservable, reaction } from 'mobx'
import { IDropdownItem } from 'shared/ui'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { inboxesStore } from 'entities/Inbox'
import { MessageFieldStore } from 'widgets/MessageField'
import { mentionReplacer } from 'widgets/MessageField/ui/DropdownMentions'

type IMentionsStoreConfig = {
  parent: MessageFieldStore
}

type IMentionsStoreData = {
  origin: string
  search: string
  startOffset: number
  lastOffset: number
}

export class DropdownMentionsStore {
  loading = false
  search = ''
  itemsMap: Map<number | string, IDropdownItem> = new Map()
  itemInbox: IDropdownItem = {
    id: 'inbox',
    label: '@inbox',
    labelRight: 'Notify everyone in this inbox',
    avatarProps: {
      info: {
        icon: 'announcement',
        color: 'transparent',
      },
      size: 16,
    },
  }
  show = false
  parent: MessageFieldStore
  data: IMentionsStoreData[] = []
  selectedData: IMentionsStoreData | null = null
  range: Range | null = null
  reactionUsers: ReturnType<typeof reaction> | null = null

  constructor({ parent }: IMentionsStoreConfig) {
    makeAutoObservable(this)

    this.parent = parent
  }

  reset = () => {
    this.show = false
    this.data = []
    this.selectedData = null
    this.range = null
  }

  handleInit = async () => {
    this.itemsMap.clear()

    const conversation = conversationStore.currentItem

    if (!conversation) return

    const inbox = await inboxesStore.getById(conversation.inbox_id)

    usersStore.users.forEach((user) => {
      const existMember = inbox?.type === 'inbox' && inbox.memberIds.includes(user.id)

      const item: IDropdownItem = {
        id: user.id,
        label: user.name,
        avatarProps: {
          info: user.avatarInfo,
          size: 16,
        },
        ariaLabel: 'tagsMembersListSelector',
        labelRight: !existMember ? 'Not in inbox' : '',
      }

      this.addItem(item)
    })
  }

  handleSearch = (value: string) => {
    this.search = value
  }

  addItem = (item: IDropdownItem) => {
    this.itemsMap.set(item.id, item)
  }

  get items() {
    if (this.search) {
      return Array.from(this.itemsMap.values()).filter((item) => {
        return item.label.toUpperCase().includes(this.search.toUpperCase())
      })
    }

    return Array.from(this.itemsMap.values())
  }

  get itemsSimple() {
    return this.items.filter((item) => !item.labelRight)
  }

  get itemsNotInbox() {
    return this.items.filter((item) => item.labelRight)
  }

  checkMessageKey = (range: Range | null) => {
    if (!range) return
    if (this.parent.isModeSms) return

    this.range = range

    const messageText = range.endContainer instanceof Text ? range.endContainer?.data : ''
    const items = [...messageText.matchAll(/@([a-zA-Z0-9_]*)/g)]
    const startOffset = range.startOffset
    this.data = items.map((item) => {
      const index = item.index || 0

      return {
        origin: item[0],
        search: item[1],
        startOffset: index,
        lastOffset: item[0].length + index,
      }
    })
    this.selectedData =
      this.data.find((item) => startOffset >= item.startOffset && startOffset <= item.lastOffset) ||
      null

    if (this.selectedData) {
      const searchOrigin = this.selectedData.origin.slice(
        0,
        startOffset - this.selectedData.startOffset
      )

      this.search = searchOrigin.replace('@', '')
      this.show = Boolean(searchOrigin.length)
    } else {
      this.search = ''
      this.show = false
    }
  }

  handleSetMentions = (item: IDropdownItem) => {
    if (!this.parent.replaceContent) return
    if (!this.range) return
    if (!this.selectedData) return

    const startOffset = this.range.startOffset
    const node = this.range.endContainer instanceof Text ? this.range.endContainer : null

    if (node && this.selectedData.startOffset < startOffset) {
      const replaceString = (s: string) => {
        return s.replace(`@${this.search}`, `${mentionReplacer(item)} &nbsp;`)
      }

      const messageText = replaceString(node.data)

      this.parent.replaceContent(messageText)
      this.reset()
    }
  }

  handleReactions = () => {
    if (this.reactionUsers) this.reactionUsers()

    this.reactionUsers = reaction(
      () => [usersStore.users.length, conversationStore.currentItem],
      () => {
        this.handleInit()
      },
      {
        fireImmediately: true,
      }
    )
  }
}
