import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { DropdownContent } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { useSearchDropdownContext } from 'widgets/SearchDropdown'
import { DropdownAllContentSearchItems } from 'widgets/SearchDropdown/ui'
import styles from './styles.module.scss'

type IDropdownAllContentSearchProps = {
  onCloseMenu?: () => void
  maxHeight?: number
}

export const DropdownAllContentSearchField: FC<IDropdownAllContentSearchProps> = observer(
  ({ onCloseMenu, maxHeight }) => {
    const store = useSearchDropdownContext()
    const handleChange = (value: string) => {
      store.actionSearchAll(value)
      store.options?.handleSelectedSearch?.(value)
    }

    useEffect(() => {
      store.actionSearchAll(store.search)

      return () => {
        if (!store.isShowMore) {
          store.resetAll()
        }
      }
    }, [])

    return (
      <DropdownContent noPadding className={classnames(styles.searchWrapper, styles['width-480'])}>
        <div className={styles.searchWrap}>
          <TextField
            className={styles.searchWrap__field}
            variant='integrated'
            InputProps={{
              placeholder: store.placeholder,
            }}
            onChange={handleChange}
            value={store.search}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
          />
        </div>

        <DropdownAllContentSearchItems onCloseMenu={onCloseMenu} maxHeight={maxHeight} />
      </DropdownContent>
    )
  }
)
