import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { RecordStore } from 'widgets/Record/store/recordStore'
import { RecordContent } from 'widgets/Record/ui/RecordActions/RecordPlayer/RecordContent'
import { PlayingContent } from 'widgets/Record/ui/RecordActions/RecordPlayer/PlayingContent'
import styles from './styles.module.scss'

export const RecordPlayer = observer(({ recordStore }: { recordStore: RecordStore }) => {
  const { pauseRecord, isAudio } = recordStore

  return (
    <div
      className={classNames(
        styles.player,
        pauseRecord && styles.visible,
        isAudio && !pauseRecord && styles.recordAudio
      )}
      data-name={'record-player'}
    >
      {pauseRecord ? (
        <PlayingContent recordStore={recordStore} />
      ) : (
        <RecordContent recordStore={recordStore} />
      )}
    </div>
  )
})
