import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'shared/ui'
import { IInboxCombine, NAME_INBOX } from 'entities/Inbox'
import {
  actionTeamsFavorite,
  menuTeamsFavorite,
  actionMoveTo,
  menuMoveTo,
  actionNewFolder,
  menuNewFolder,
  actionTeamsMute,
  menuTeamsMute,
} from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/Dropdown'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'

type IDropdownSimpleInboxProps = {
  inbox: IInboxCombine
  groupInbox?: IInboxCombine
}

export const DropdownSimpleInbox: FC<IDropdownSimpleInboxProps> = observer(
  ({ inbox, groupInbox }) => {
    const store = useConversationMenuContext()
    const items = [menuTeamsFavorite(inbox)]
    const children = store.selectInboxesGroupViews
      .filter((item) => item.id !== NAME_INBOX.Create)
      .filter((item) =>
        item.type === 'inbox_group'
          ? !item.teamsIds.map((teamId) => teamId).includes(inbox.id)
          : false
      )
      .map((item) => item.dropdownData)

    if (!inbox.isUnifiedInbox) {
      items.push(menuNewFolder())

      if (children.length) {
        items.push(menuMoveTo(children))
      }
      items.push(menuTeamsMute(inbox))
    }

    return (
      <Dropdown
        ariaLabel={'DropdownSimpleInbox'}
        icon={'moreHorizontal'}
        placement={'bottom-end'}
        tooltipProps={{
          disableInteractive: true,
          label: 'Show actions',
          placement: 'top-end',
        }}
        items={items}
        onChange={(item, parent) => {
          actionTeamsFavorite({ inbox, groupInbox, item }, store)
          actionNewFolder({ inbox, groupInbox, item }, store)
          actionMoveTo({ inbox, item, parent }, store)
          actionTeamsMute({ inbox, item }, store)
        }}
        width={inbox.isUnifiedInbox ? 136 : 154}
      />
    )
  }
)
