import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type RegisterStore } from 'widgets/Register/store/registerStore'

type IProps = {
  store: RegisterStore
}

export const MaxMindModalActions = observer(({ store }: IProps) => {
  const {
    loading,
    handleSignUp,
    step4Store: { isValidSmsCode },
  } = store

  return (
    <Button
      text='Verify'
      disabled={loading || !isValidSmsCode}
      onClick={handleSignUp}
      loading={loading}
    />
  )
})
