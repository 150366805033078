import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'

export enum CreateContactErrors {
  trialLimit = 'To start a new conversation with this contact please upgrade your account here',
}

export const CreateContactHandler = {
  [CreateContactErrors.trialLimit]: () => {
    if (usersStore.user?.isOwnerRole || usersStore.user?.isAdminRole) {
      const id = nanoid()
      modalStore.addModal({
        id,
        type: ModalTypeList.INFO,
        title: 'You have reached the limit of 250 contacts on Trial plan',
        desc: 'To start a new conversation with this contact, please upgrade your account',
        primaryAction: {
          text: 'Upgrade plan',
          onAction: () =>
            uiStore.changeRoute({
              path: '/settings/billing/overview',
              type: 'vue',
            }),
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: () => modalStore.closeModal(id),
        },
      })
    }
    if (usersStore.user?.isMemberRole || usersStore.user?.isManagerRole) {
      const id = nanoid()
      modalStore.addModal({
        id,
        type: ModalTypeList.INFO,
        title: 'You have reached the limit of 250 contacts on Trial plan',
        desc: 'To start a new conversation with this contact, please ask one of your admins to upgrade',
        infoModalTextAction: 'Done',
      })
    }
  },
}
