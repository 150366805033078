import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { numbersStore } from 'entities/Phone'
import { callStore } from 'entities/Call'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallConferencePopUpButton: React.FC = observer(() => {
  const store = useCallPopUpDefaultContext()
  const {
    isConference,
    isConferenceDisabled,
    handleConferenceShow,
    isConferenceParticipants,
    innerRefTooltipCurrent,
  } = store as CallPopUpDefaultStore
  const label = () => {
    if (!number?.isConferenceCalling) return ''
    if (isConferenceParticipants) {
      if (isConference) {
        return 'Hide conference'
      }

      return 'Show conference'
    }

    return 'Add to call'
  }
  const number =
    callStore.contactFrom?.type === 'inbox'
      ? numbersStore.getItem(callStore.contactFrom?.numberId)
      : undefined
  const isDisabledButton = Boolean(isConferenceDisabled || !number?.isConferenceCalling)

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'blue'}
        variant={'custom'}
        active={isConference}
        disabled={isDisabledButton}
        ariaLabel={`CallConferencePopUpButton_${
          isConferenceParticipants ? 'contacts' : 'userPlus'
        }`}
        onClick={() => {
          handleConferenceShow()
        }}
        iconProps={{
          icon: isConferenceParticipants ? 'contacts' : 'userPlus',
        }}
        tooltipProps={{
          label: label(),
          placement: 'top',
          zIndex: 5001,
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
      />
    </div>
  )
})
