// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BdXCVlLldcQksIUs_aCj .pRiDzYictjW66sWn9HWs{height:44px;font-size:14;font-weight:500;margin-top:40px}.BdXCVlLldcQksIUs_aCj ._1V6iWAuomPQ_ISKGWQm2{margin:24px 0}.BdXCVlLldcQksIUs_aCj .sJA2F9n30H3Ey8Od4R7_{padding:12px;font-weight:400;font-size:14px;line-height:20px}.BdXCVlLldcQksIUs_aCj .sJA2F9n30H3Ey8Od4R7_.FsRded7FjrjcTfKHDfro{margin-bottom:4px}.BdXCVlLldcQksIUs_aCj .BALDDm0lMY8nVls5TlYJ{margin-top:20px;margin-bottom:4px}", "",{"version":3,"sources":["webpack://./src/widgets/ResetPassword/ui/ResetPasswordForm/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,WAAA,CACA,YAAA,CACA,eAAA,CACA,eAAA,CAGF,6CACE,aAAA,CAGF,4CACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,iEACE,iBAAA,CAIJ,4CACE,eAAA,CACA,iBAAA","sourcesContent":[".wrap {\n  .button {\n    height: 44px;\n    font-size: 14;\n    font-weight: 500;\n    margin-top: 40px;\n  }\n\n  .alertError {\n    margin: 24px 0;\n  }\n\n  .textField {\n    padding: 12px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.error {\n      margin-bottom: 4px;\n    }\n  }\n\n  .inputLabel {\n    margin-top: 20px;\n    margin-bottom: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "BdXCVlLldcQksIUs_aCj",
	"button": "pRiDzYictjW66sWn9HWs",
	"alertError": "_1V6iWAuomPQ_ISKGWQm2",
	"textField": "sJA2F9n30H3Ey8Od4R7_",
	"error": "FsRded7FjrjcTfKHDfro",
	"inputLabel": "BALDDm0lMY8nVls5TlYJ"
};
export default ___CSS_LOADER_EXPORT___;
