import classNames from 'classnames'
import { Icon, IIconsVector } from '../Icon'
import { Typography } from '../Typography'

import styles from './styles.module.scss'

interface IChoiceTileProps {
  icon: IIconsVector
  title: string
  value: string
  checked: boolean
  setChoiceTile: (value: string) => void
}

export const ChoiceTile = ({ icon, title, value, checked, setChoiceTile }: IChoiceTileProps) => {
  return (
    <button
      className={classNames(styles.wrap, checked && styles.checked)}
      onClick={() => setChoiceTile(value)}
    >
      <div className={styles.iconWrap}>
        <Icon icon={icon} />
      </div>
      <Typography ariaLabel='ChoiceTile' variant='body-md-regular'>
        {title}
      </Typography>
    </button>
  )
}
