// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TQr4UxtxIdFJ_XAUSw6L{display:flex;align-items:center}.cTwcycUMCKhp_5LH79tZ{width:72px;padding-inline:6px}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/info/ui/buttons/VolumeBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  display: flex;\n  align-items: center;\n}\n\n.wrapVolume{\n  width: 72px;\n  padding-inline: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "TQr4UxtxIdFJ_XAUSw6L",
	"wrapVolume": "cTwcycUMCKhp_5LH79tZ"
};
export default ___CSS_LOADER_EXPORT___;
