import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket/store'
import { inboxesStore } from 'entities/Inbox'
import type { IResponseInboxDeleted } from 'entities/Inbox/api/types'

const InboxDeleted = (data: IResponseInboxDeleted) => {
  inboxesStore.deleteItem(data.id)
}

export const useEventInboxDeleted = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.InboxDeleted, InboxDeleted)
    }
  }, [websocket.isConnected])
}
