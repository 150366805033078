import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import {
  ICallHistoryCall,
  ICallHistoryFilterType,
  ICallType,
  IFiltersList,
  IOutcomeType,
  IParamsGetCallHistoryStatistic,
  IResponseGetCallHistoryStatistic,
} from 'entities/CallHistory/api/types'
import { CallHistoryApi } from 'entities/CallHistory/api/callHistory'
import { IReadMessage } from 'entities/Conversation/api/types'

export class CallHistoryFiltersListStore {
  loadCallHistory
  constructor(loadCallHistory: () => Promise<void>) {
    makeAutoObservable(this)
    this.loadCallHistory = loadCallHistory
  }

  allCallsCount = 0
  allCallsUnreadCount = 0
  inboundAnsweredUnreadCount = 0
  inboundMissedUnreadCount = 0
  inboundVoicemailUnreadCount = 0
  outboundAnsweredUnreadCount = 0
  outboundNoAnsweredUnreadCount = 0
  loading = false
  type: ICallType | 'all-calls' = 'all-calls'
  outcome: IOutcomeType | null = null
  cancelTokenSource: CancelTokenSource | null = null

  get filtersList() {
    if (!this.isActiveAllCalls) {
      return [
        {
          filters: [
            { key: 'call.type', operator: 'equals', value: this.type },
            { key: 'call.outcome', operator: 'equals', value: this.outcome },
          ],
        },
      ] as IFiltersList[]
    }

    return []
  }
  get hasCalls() {
    return this.allCallsCount > 0
  }
  get isInbound() {
    return this.type === 'inbound'
  }
  get isOutbound() {
    return this.type === 'outbound'
  }
  get isActiveAllCalls() {
    return Boolean(this.type === 'all-calls')
  }
  get isActiveInboundAnswered() {
    return Boolean(this.outcome === 'answered' && this.isInbound)
  }
  get isActiveInboundMissed() {
    return Boolean(this.outcome === 'missed' && this.isInbound)
  }
  get isActiveInboundVoicemail() {
    return Boolean(this.outcome === 'voicemail' && this.isInbound)
  }
  get isActiveOutboundAnswered() {
    return Boolean(this.outcome === 'answered' && this.isOutbound)
  }
  get isActiveOutboundNoAnswered() {
    return Boolean(this.outcome === 'no_answer' && this.isOutbound)
  }
  handleSelectFilter = (data: ICallHistoryFilterType) => {
    this.type = data.type
    this.outcome = data.outcome || null
  }

  setData = ({ data }: IResponseGetCallHistoryStatistic) => {
    const { count, unread_count, groups } = data
    const { inbound, outbound } = groups

    this.allCallsCount = count
    this.allCallsUnreadCount = unread_count
    this.inboundAnsweredUnreadCount = inbound.groups.answered.unread_count
    this.inboundMissedUnreadCount = inbound.groups.missed.unread_count
    this.inboundVoicemailUnreadCount = inbound.groups.voicemail.unread_count
    this.outboundAnsweredUnreadCount = outbound.groups.answered.unread_count
    this.outboundNoAnsweredUnreadCount = outbound.groups.no_answer.unread_count
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  loadData = async (params: IParamsGetCallHistoryStatistic) => {
    this.reset()
    try {
      this.initCancelTokenSource()
      runInAction(() => {
        this.loading = true
      })

      return await CallHistoryApi.getCallHistoryStatistic(params, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })
        .then(({ data }) => {
          this.setData(data)

          if (data.data.count > 0) {
            this.loadCallHistory()
          }

          return data
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false
          })
        })
    } catch (e) {
      runInAction(() => {
        this.loading = e instanceof CanceledError
      })
    }
  }
  increaseCount = ({ type, outcome }: ICallHistoryCall) => {
    const isInbound = type === 'inbound'
    const isOutbound = type === 'outbound'

    const isInboundAnswered = isInbound && outcome === 'answered'
    const isInboundMissed = isInbound && outcome === 'missed'
    const isInboundVoicemail = isInbound && outcome === 'voicemail'

    const isOutboundAnswered = isOutbound && outcome === 'answered'
    const isOutboundNoAnswer = isOutbound && outcome === 'no_answer'

    this.allCallsUnreadCount += 1

    if (isInboundAnswered) this.inboundAnsweredUnreadCount += 1

    if (isInboundMissed) this.inboundMissedUnreadCount += 1

    if (isInboundVoicemail) this.inboundVoicemailUnreadCount += 1

    if (isOutboundAnswered) this.outboundAnsweredUnreadCount += 1

    if (isOutboundNoAnswer) this.outboundNoAnsweredUnreadCount += 1
  }
  decreaseCount = (data: IReadMessage[]) => {
    data.forEach(({ type, direction, status }) => {
      if (type === 'call') {
        const isInbound = direction === 'inbound'
        const isOutbound = direction === 'outbound'
        const isInboundAnswered = isInbound && status === 'answered'
        const isInboundMissed = isInbound && status === 'missed'
        const isInboundVoicemail = isInbound && status === 'voicemail'

        const isOutboundAnswered = isOutbound && status === 'answered'
        const isOutboundNoAnswer = isOutbound && status === 'no_answer'

        this.allCallsUnreadCount -= 1

        if (isInboundAnswered) this.inboundAnsweredUnreadCount -= 1

        if (isInboundMissed) this.inboundMissedUnreadCount -= 1

        if (isInboundVoicemail) this.inboundVoicemailUnreadCount -= 1

        if (isOutboundAnswered) this.outboundAnsweredUnreadCount -= 1

        if (isOutboundNoAnswer) this.outboundNoAnsweredUnreadCount -= 1
      }
    })
  }
  resetAllCount = () => {
    this.allCallsUnreadCount = 0
    this.inboundAnsweredUnreadCount = 0
    this.inboundMissedUnreadCount = 0
    this.inboundVoicemailUnreadCount = 0
    this.outboundAnsweredUnreadCount = 0
    this.outboundNoAnsweredUnreadCount = 0
  }
  reset = () => {
    this.allCallsCount = 0
    this.resetAllCount()
    this.type = 'all-calls'
    this.outcome = null
  }
}
