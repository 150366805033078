import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'

type IRowAction = {
  columns: ReactNode[]
}

type IWrapperActionsProps = {
  rows: IRowAction[]
}

export const Actions: FC<IWrapperActionsProps> = ({ rows }) => {
  const items = rows.filter((row) => {
    return row.columns.filter((column) => column).length
  })

  if (!items.length) return null

  return (
    <div className={styles.actions}>
      {items.map((row, rowIndex) => {
        return (
          <div key={rowIndex} className={styles.actionsRow}>
            {row.columns.map((column, columnIndex) => {
              return (
                <div key={columnIndex} className={styles.action}>
                  {column}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
