import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { exportConversationModalStore } from 'widgets/ExportConversationModal'

export const ExportConversationsModalsActions = observer(() => {
  return (
    <Button
      typeBtn={'contained'}
      text={'Export'}
      onClick={exportConversationModalStore.onExport}
      disabled={exportConversationModalStore.disabled}
    />
  )
})
