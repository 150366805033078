import axios, { CanceledError, CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import {
  type IParamsWorkflowList,
  type IFullWorkflowData,
  type IResponseWorkflowListMeta,
  type IWorkflow,
  WorkflowsApi,
  Workflow,
} from 'entities/Workflow'

export class WorkflowListStore {
  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null
  private _listMap = new Map<string, IWorkflow>()

  private _page = 1
  private _total = 0
  private _limit = 10

  private _loading = true
  private _initialLoading = true

  constructor() {
    makeAutoObservable(this)

    this._disposePageReaction = reaction(() => this._listRequestParams, this.loadData)
  }

  get items() {
    return Array.from(this._listMap.values())
  }

  get page() {
    return this._page
  }

  get limit() {
    return this._limit
  }

  get total() {
    return this._total
  }

  get loading() {
    return this._loading
  }

  get initialLoading() {
    return this._initialLoading
  }

  get isEmpty() {
    if (this._initialLoading || this._loading) return false

    return !this._listMap.size
  }

  private get _listRequestParams(): IParamsWorkflowList {
    return {
      'page[pointer]': this._page,
      'page[size]': this._limit,
    }
  }

  loadData = async () => {
    this._loading = true
    this._initCancelPageSource()

    try {
      const {
        data: { payload, meta },
      } = await WorkflowsApi.getWorkflows(this._listRequestParams, {
        cancelToken: this._cancelPageSource?.token,
      })

      this._setData(payload, meta)

      runInAction(() => {
        this._loading = false
        this._initialLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
        this._initialLoading = false
      })

      console.error(error)
    }
  }

  changePaging = (page: number, limit: number) => {
    this._page = page
    this._limit = limit
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  private _setData = (list: IFullWorkflowData[], meta: IResponseWorkflowListMeta) => {
    this._listMap.clear()
    list.forEach((item) => this._listMap.set(item.id, Workflow.Create(item)))

    this._total = meta.pagination.total
    this._page = meta.pagination.pointer.current
  }

  private _initCancelPageSource = () => {
    this._cancelPageSource?.cancel()

    this._cancelPageSource = axios.CancelToken.source()
  }
}
