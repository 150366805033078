// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rRTsxmmm4l1nStSZkABr{background-color:var(--background-primary-inverted-primary);box-shadow:0 0 0 1px rgba(18,21,23,.05),0 10px 30px -5px rgba(18,21,23,.1),0 20px 50px 0px rgba(18,21,23,.05);border-radius:8px}.R749h_7rIimYmSfe5rQo{display:flex;flex-direction:column}.c3prZzz_WQK9S_gHGFkC{padding:4px;min-width:115px;overflow-y:auto}.pCsoqG3wDPNf2BTkD_Wt{padding:4px 4px 0 4px}.oX06UKHCR2YpbulXy8Y1{display:none;position:absolute;left:100%;top:0}.q2VOpDSNB8Ga5L3q07RN{border-top:1px solid var(--border-neutral-default);padding:4px}.ZqOHAlZl3fkh_UEH15aX{color:var(--gray-60);font-size:12px;font-weight:400;padding:8.5px 0;display:flex;line-height:15px;justify-content:center}", "",{"version":3,"sources":["webpack://./src/shared/ui/Dropdown/ui/DropdownList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2DAAA,CACA,6GAAA,CACA,iBAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,WAAA,CACA,eAAA,CACA,eAAA,CAEA,sBACE,qBAAA,CAGF,sBACE,YAAA,CACA,iBAAA,CACA,SAAA,CACA,KAAA,CAGJ,sBACE,kDAAA,CACA,WAAA,CAEF,sBACE,oBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,gBAAA,CACA,sBAAA","sourcesContent":[".wrap {\n  background-color: var(--background-primary-inverted-primary);\n  box-shadow: 0 0 0 1px rgba(18, 21, 23, 0.05), 0 10px 30px -5px rgba(18, 21, 23, 0.10), 0 20px 50px 0px rgba(18, 21, 23, 0.05);\n  border-radius: 8px;\n}\n.content{\n  display: flex;\n  flex-direction: column;\n}\n\n.items {\n  padding: 4px;\n  min-width: 115px;\n  overflow-y: auto;\n\n  &--buttonActions {\n    padding: 4px 4px 0 4px;\n  }\n\n  &__sub {\n    display: none;\n    position: absolute;\n    left: 100%;\n    top: 0;\n  }\n}\n.bottomActions {\n  border-top: 1px solid var(--border-neutral-default);\n  padding: 4px;\n}\n.noResult {\n  color: var(--gray-60);\n  font-size: 12px;\n  font-weight: 400;\n  padding: 8.5px 0;\n  display: flex;\n  line-height: 15px;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rRTsxmmm4l1nStSZkABr",
	"content": "R749h_7rIimYmSfe5rQo",
	"items": "c3prZzz_WQK9S_gHGFkC",
	"items--buttonActions": "pCsoqG3wDPNf2BTkD_Wt",
	"items__sub": "oX06UKHCR2YpbulXy8Y1",
	"bottomActions": "q2VOpDSNB8Ga5L3q07RN",
	"noResult": "ZqOHAlZl3fkh_UEH15aX"
};
export default ___CSS_LOADER_EXPORT___;
