import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { FullPageWrapper } from 'shared/ui'
import { useTriggerDetailContext } from 'widgets/TriggerDetail/context/triggerDetailContext'
import { TriggerDetailHeader } from 'widgets/TriggerDetail/ui/TriggerDetailHeader/TriggerDetailHeader'
import { ContactStatisticsSearch } from 'widgets/TriggerDetail/ui/ContactStatisticsSearch/ContactStatisticsSearch'
import { ContactStatisticsFilter } from 'widgets/TriggerDetail/ui/ContactStatisticsFilter/ContactStatisticsFilter'
import { ContactStatisticsTable } from 'widgets/TriggerDetail/ui/ContactStatisticsTable/ContactStatisticsTable'
import { ContactsDetails } from 'widgets/ContactsDetails'

export const TriggerDetailContent = observer(() => {
  const store = useTriggerDetailContext()

  useEffect(() => {
    return () => {
      store.reset()
    }
  }, [])

  return (
    <FullPageWrapper>
      <TriggerDetailHeader />
      <ContactStatisticsFilter />
      <ContactStatisticsSearch />
      <ContactStatisticsTable />
      {store.contactsDetailsStore && <ContactsDetails store={store.contactsDetailsStore} />}
    </FullPageWrapper>
  )
})
