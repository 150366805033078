import React from 'react'
import { type RecurringStore } from 'shared/ui/Schedule/ui/Recurring/store/recurringStore'
import { RecurringContent } from './ui/RecurringContent'
import { RecurringContext } from './context/recurringContext'

type IRecurringProps = {
  store: RecurringStore
}

export const Recurring = ({ store }: IRecurringProps) => {
  return (
    <RecurringContext.Provider value={store}>
      <RecurringContent />
    </RecurringContext.Provider>
  )
}
