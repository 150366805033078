import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Error } from 'shared/ui'
import { RinglessRecordAudio } from 'widgets/BroadcastView/ui/BroadcastRingless/ui/BroadcastRinglessActions/actions/RinglessRecordAudio'
import { useBroadcastRinglessContext } from 'widgets/BroadcastView/ui/BroadcastRingless'
import { RecordAudio } from 'widgets/RecordAudio/RecordAudio'
import { RinglessTextToSpeech } from 'widgets/BroadcastView/ui/BroadcastRingless/ui/BroadcastRinglessActions/actions/RinglessTextToSpeech'
import styles from './styles.module.scss'
import { RinglessUploadAudio } from './actions/RinglessUploadAudio'

export const BroadcastRinglessActions = observer(() => {
  const { isRecordAudioState, recordAudioStore, error } = useBroadcastRinglessContext()

  if (isRecordAudioState) {
    return (
      <div className={classNames(styles.recordAudio, styles.active)}>
        <RecordAudio
          store={recordAudioStore}
          cancelBtnType={'button'}
          cancelButtonProps={{ typeBtn: 'text' }}
        />
      </div>
    )
  }

  return (
    <>
      <div
        className={classNames(styles.wrap, {
          [styles.errorState]: error,
        })}
      >
        <RinglessRecordAudio />
        <RinglessTextToSpeech />
        <RinglessUploadAudio />
      </div>
      {error && <Error type={'text'} error={error} />}
    </>
  )
})
