// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K4jCgAkQkWecbrPyN1I8{padding-top:12px}._EltR0VOwptU3Q7Z5fVS{margin-right:-4px}", "",{"version":3,"sources":["webpack://./src/widgets/AdvancedTrackConversions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAEF,sBACE,iBAAA","sourcesContent":[".content{\n  padding-top: 12px;\n}\n.rightIcon{\n  margin-right:-4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "K4jCgAkQkWecbrPyN1I8",
	"rightIcon": "_EltR0VOwptU3Q7Z5fVS"
};
export default ___CSS_LOADER_EXPORT___;
