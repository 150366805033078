import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button, Switch, Typography } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { adminTollFreeDetailStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails'
import styles from './styles.module.scss'

export const ReviewContent = observer(() => {
  const { onReview } = adminTollFreeDetailStore
  const [tollFree, setTollFree] = useState(false)

  const [loading, setLoading] = useState(false)

  const onPublish = async () => {
    try {
      setLoading(true)
      await onReview()
    } finally {
      setLoading(false)
    }
  }

  const onCancel = () => {
    modalStore.closeModal(adminTollFreeDetailStore.idReviewModal)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.wrapButtons}>
        <div className={styles.btn}>
          <Typography
            variant={'text-input-field'}
            color={'tertiary'}
            ariaLabel={'Publish toll-free'}
          >
            Publish toll-free
          </Typography>
          <Switch value={tollFree} onChange={setTollFree} />
        </div>
      </div>
      <div className={styles.actions}>
        <Button text={'Cancel'} contained={'secondary'} onClick={onCancel} />
        <Button
          text={'Publish'}
          contained={'primary'}
          disabled={!tollFree}
          onClick={onPublish}
          loading={loading}
        />
      </div>
    </div>
  )
})
