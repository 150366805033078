import { observer } from 'mobx-react-lite'
import { IconButton, Typography } from 'shared/ui'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import styles from './styles.module.scss'

export const EditMessageHeader = observer(() => {
  const messageFieldStore = useMessageFieldContext()
  const { editMessage, onCloseEdit } = messageFieldStore
  if (!editMessage) {
    return null
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.left}>
        <Typography
          variant={'body-xs-regular'}
          tag={'div'}
          className={styles.edit}
          color={'var(--content-primary-primary-inverted)'}
          ariaLabel={'Edit'}
        >
          Edit
        </Typography>
        <Typography variant={'body-rg-regular'} ellipsis ariaLabel={'body'}>
          {editMessage.body}
        </Typography>
      </div>
      <IconButton
        icon={'close'}
        size={'small'}
        variant={'icon'}
        color={'tertiary'}
        onClick={onCloseEdit || undefined}
        ariaLabel='EditMessageHeader_close'
      />
    </div>
  )
})
