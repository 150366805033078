import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import {
  Typography,
  IconButton,
  Icon,
  SpinnerLoader,
  CopyWrapper,
  Dropdown,
  EmptyState,
  DropdownContent,
  Avatar,
  Tooltip,
} from 'shared/ui'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'
import { inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { IUser, usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation/store/conversations'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { InboxMember } from './InboxMember'
import styles from './styles.module.scss'

const ConversationInfo = observer(() => {
  const [open, setOpen] = useState(false)
  const [searchMember, setSearchMember] = useState('')

  const store = useContactsDetailsContext()
  const conversation = conversationStore.getItem(store.conversation?.id)
  const inbox = inboxesStore.getItem(conversation?.inbox_id)
  const number = numbersStore.getItem(conversation?.number_id)

  const members =
    inbox?.type === 'inbox'
      ? inbox.memberIds.reduce<IUser[]>((state, id) => {
          const item = usersStore.getItem(id)

          return item ? [...state, item] : [...state]
        }, [])
      : []
  const filteredMembers = searchMember
    ? members
        ?.filter((member) => !member.isViewOnlyRole)
        ?.filter((member) => member?.name.toLowerCase().includes(searchMember.toLowerCase()))
    : members?.filter((member) => !member.isViewOnlyRole)
  const assignedMember = usersStore.getItem(conversation?.user_id)

  if (!inbox || !conversation)
    return (
      <div className={classnames(styles.wrap)}>
        <SpinnerLoader />
      </div>
    )

  const handleToggle = (open: boolean) => {
    setSearchMember('')
    setOpen(open)
  }

  return (
    <div className={classnames(styles.wrap)}>
      <div className={styles.info}>
        <div className={styles.field}>
          <span className={styles.label}>
            <Typography
              className={styles.name}
              variant='body-md-regular'
              tag='div'
              ariaLabel={'Inbox'}
            >
              Inbox
            </Typography>
          </span>
          <div className={styles.valueWrapper}>
            <Tooltip
              label={inbox?.name.length > 15 ? inbox?.name : ''}
              placement='top'
              disableInteractive
            >
              <span className={styles.value}>
                <IconButton
                  icon={inbox?.icon}
                  size={'small'}
                  color={'transparent'}
                  fontSize={16}
                  ariaLabel='ConversationInfo'
                />
                <Typography
                  className={styles.name}
                  variant='body-md-regular'
                  tag='div'
                  ariaLabel={getAriaLabel('conversationDetailsInboxSelector')}
                >
                  {inbox?.name}
                </Typography>
              </span>
            </Tooltip>
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>
            <Typography
              className={styles.name}
              variant='body-md-regular'
              tag='div'
              ariaLabel={'Inbox phone'}
            >
              Inbox phone
            </Typography>
          </span>
          <div className={styles.valueWrapper}>
            <CopyWrapper copyValue={number?.formatted_number || ''}>
              <span className={styles.value}>
                <div className={styles.icon}>
                  <Icon icon='phone' color='var(--content-primary-tertiary)' />
                </div>
                <Typography
                  className={styles.name}
                  variant='body-md-regular'
                  tag='div'
                  ariaLabel={getAriaLabel('conversationDetailsInboxPhoneSelector')}
                >
                  {number?.formatted_number}
                </Typography>
              </span>
            </CopyWrapper>
          </div>
        </div>
        {!conversation.isGroup && (
          <div
            className={classnames(styles.field, {
              [styles.dropdownAction]: open,
            })}
          >
            <span className={styles.label}>
              <Typography
                className={styles.name}
                variant='body-md-regular'
                tag='div'
                ariaLabel={'Assignee'}
              >
                Assignee
              </Typography>
            </span>
            <div className={styles.valueWrapper}>
              <Dropdown
                ariaLabel={getAriaLabel('conversationDetailsAssigneeSelector')}
                width={192}
                placement={'bottom'}
                show={open}
                onToggle={handleToggle}
                typeButton={'icon-button'}
                triggerComponent={(open) => {
                  if (open) return <></>

                  if (store.reassigning) {
                    return (
                      <span className={classnames(styles.value)}>
                        <div className={styles.icon}>
                          <SpinnerLoader size={16} />
                        </div>
                        <Typography
                          className={styles.name}
                          variant='body-md-regular'
                          pointer
                          ariaLabel={'Reassigning'}
                        >
                          Reassigning
                        </Typography>
                      </span>
                    )
                  }
                  return (
                    <CopyWrapper copyValue={null}>
                      <span className={classnames(styles.value, styles.action)}>
                        {assignedMember?.id ? (
                          <>
                            <div className={styles.icon}>
                              <Avatar info={assignedMember.avatarInfo} size={16} />
                            </div>
                            <Typography
                              className={styles.name}
                              variant='body-md-regular'
                              ellipsis
                              pointer
                              ariaLabel={'name'}
                            >
                              {assignedMember.name}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <div className={styles.icon}>
                              <Icon icon='userLine' color='var(--content-primary-tertiary)' />
                            </div>
                            <Typography
                              className={styles.name}
                              variant='body-md-regular'
                              pointer
                              ariaLabel={'Unassigned'}
                            >
                              Unassigned
                            </Typography>
                          </>
                        )}
                      </span>
                    </CopyWrapper>
                  )
                }}
                customComponent={(onCloseMenu) => (
                  <DropdownContent noPadding>
                    <div className={styles.dropdownWrapper}>
                      <div className={styles.searchWrapper}>
                        <TextField
                          size='small'
                          variant='integrated'
                          InputProps={{
                            placeholder: 'Search',
                          }}
                          value={searchMember}
                          onChange={setSearchMember}
                          mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
                        />
                      </div>
                      <div
                        className={classnames(styles.membersWrapper, {
                          [styles.withScroll]: filteredMembers && filteredMembers.length > 5,
                        })}
                      >
                        {filteredMembers?.length ? (
                          <>
                            <InboxMember
                              onClose={onCloseMenu}
                              active={conversation?.user_id === 0}
                              conversationId={conversation?.id}
                              key={0}
                              member={null}
                            />
                            {filteredMembers.map((member: IUser | undefined, idx: number) => (
                              <InboxMember
                                onClose={onCloseMenu}
                                active={conversation?.user_id === member?.id}
                                conversationId={conversation?.id}
                                key={idx}
                                member={member}
                                ariaLabel={'assigneeListSelector'}
                              />
                            ))}
                          </>
                        ) : (
                          <EmptyState noIcon />
                        )}
                      </div>
                    </div>
                  </DropdownContent>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

export { ConversationInfo }
