import { PropsWithChildren } from 'react'
import { Box, Icon, IIconsVector, Status } from 'shared/ui'
import styles from './styles.module.scss'

export interface IPresetDropdownListProps {
  title?: string
}

export const PresetDropdownList = ({
  title,
  children,
}: PropsWithChildren<IPresetDropdownListProps>) => (
  <div className={styles.dropdown}>
    {!!title && <header className={styles.dropdownTitle}>{title}</header>}
    {children}
  </div>
)

export interface IPresetDropdownItemProps {
  icon: IIconsVector
  name: string
  description: string
  selected: boolean
  onClick: () => void
  isNew?: boolean
}

export const PresetDropdownItem = ({
  icon,
  name,
  description,
  selected,
  onClick,
  isNew,
}: IPresetDropdownItemProps) => (
  <section className={styles.dropdownItem} onClick={onClick}>
    <Box display='flex' gap={1.5}>
      <div className={styles.dropdownItemIcon}>
        <Icon icon={icon} />
      </div>
      <div className={styles.dropdownItemContent}>
        <header className={styles.dropdownItemContentTitle}>
          {name}
          {isNew && (
            <Status
              title={'New'}
              intent={'purple'}
              emphasis={'high'}
              size={'label'}
              className={styles.listItemContentNew}
            />
          )}
        </header>
        <p className={styles.dropdownItemContentDescription}>{description}</p>
      </div>
    </Box>
    {selected && (
      <span className={styles.dropdownItemActive}>
        <Icon icon='check1' />
      </span>
    )}
  </section>
)
