import { numberFormat } from 'shared/lib'
import { IResponseBillingAdditionalCreditPrice } from 'entities/Billing/api/types'

export class CreditPrice {
  price: number
  credits: number

  constructor(item: IResponseBillingAdditionalCreditPrice) {
    this.price = item.price
    this.credits = item.credits
  }

  get creditsLabel() {
    return numberFormat({ value: this.credits })
  }

  get priceLabel() {
    return numberFormat({
      value: this.price,
      minimumFractionDigits: 2,
    })
  }
}
