import { observer } from 'mobx-react-lite'
import { EnumHelperText, HelperText } from 'shared/ui'
import { TagsSelect } from 'entities/Tags'
import { ChatbotActionTagStore } from 'pages/chatbot/store/chatbotActionTagStore'

import { IChatbotActionProps } from '../type'
import styles from './styles.module.scss'

export type IActionTagsProps = IChatbotActionProps<ChatbotActionTagStore>

export interface IMakeActionTagsConfig {
  canCreate: boolean
  placeholder: string
}

export const makeActionTags = ({ canCreate, placeholder }: IMakeActionTagsConfig) => {
  const Component = observer(({ control, action, errors }: IActionTagsProps) => {
    const tagError = errors.getError(action)

    const handleChange = () => errors.removeError(action)

    return (
      <div className={styles.tagContainer}>
        <TagsSelect
          control={control.store}
          canCreate={canCreate}
          placeholder={placeholder}
          error={{ message: tagError }}
          onChange={handleChange}
        />
        {!!tagError && (
          <HelperText icon={'alertTriangleSmall'} variant={EnumHelperText.Error} text={tagError} />
        )}
      </div>
    )
  })

  return Component
}
