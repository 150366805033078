import { IDropOptions, IInboxCombine } from 'entities/Inbox'
import { ConversationMenuStore } from 'widgets/ConversationMenu'

export const actionMoveToDragDrop = async (
  options: IDropOptions<IInboxCombine>,
  dropRoot: string | number,
  store: ConversationMenuStore
) => {
  const { dropTargetId, dragSourceId, dropTarget, dragSource } = options

  const isChild = (child: string | number | undefined) => {
    if (!child) return false

    const strChild = child.toString()
    const strRoot = dropRoot.toString()

    return strChild === strRoot || strChild.includes(strRoot)
  }

  const realDropTargetId = dropTargetId || dropTarget?.parent
  const dropTargetElement = store.getItemTree(realDropTargetId)
  const dragTargetElement = store.getItemTree(dragSourceId)

  if (!isChild(dragSourceId) && dragSource?.data && dropTargetElement) {
    await store.handleCreateTeamsGroupsByIdAddTeams(dragSource.data, dropTargetElement.data.id)

    return
  }

  if (!dropTargetElement || !dragTargetElement) return

  await store.handleCreateTeamsGroupsByIdAddTeams(dragTargetElement.data, dropTargetElement.data.id)
}
