import { type DraggableProvided } from 'react-beautiful-dnd'
import classnames from 'classnames'
import { ReactNode, FC } from 'react'
import { Icon } from 'shared/ui'
import styles from './styles.module.scss'

type IDraggingItemProps = {
  content: ReactNode
  dragHandleProps: DraggableProvided['dragHandleProps']
  hideDraggingIcon: boolean
}

export const DraggingItem: FC<IDraggingItemProps> = ({
  content,
  hideDraggingIcon,
  dragHandleProps,
}) => {
  return (
    <div
      className={classnames(styles.iconDotsDrag, {
        [styles.hideDraggingIcon]: hideDraggingIcon,
      })}
    >
      <div className={styles.icon} {...dragHandleProps}>
        <Icon icon={'dotsDrag'} fontSize={12} color={'var(--content-primary-tertiary)'} />
      </div>
      {content}
    </div>
  )
}
