import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type TwoFaModalStore } from 'widgets/TwoFaModal/store/twoFaModalStore'

type IProps = {
  store: TwoFaModalStore
}

export const TwoFaMethodModalActions = observer(({ store }: IProps) => {
  const { openCodeModal, setTwoFaCode, sendTwoFaCode, twoFaMethod, loading } = store

  const handleNext = async () => {
    setTwoFaCode('')
    await sendTwoFaCode()
    openCodeModal(store)
  }

  return (
    <Button
      text='Continue'
      disabled={!twoFaMethod || loading}
      loading={loading}
      onClick={handleNext}
    />
  )
})
