// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gymOWjjP1SKBlu87jQpf{position:relative;height:100%}.gymOWjjP1SKBlu87jQpf.KLlkU1ci5g2kkD7HmgAs{position:absolute;z-index:12}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMenu/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CAEA,2CACE,iBAAA,CACA,UAAA","sourcesContent":[".wrap {\n  position: relative;\n  height: 100%;\n\n  &.mobileView {\n    position: absolute;\n    z-index: 12;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "gymOWjjP1SKBlu87jQpf",
	"mobileView": "KLlkU1ci5g2kkD7HmgAs"
};
export default ___CSS_LOADER_EXPORT___;
