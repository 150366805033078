// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S9hcf7YrBdBIgJ9k2Yfe{display:flex;align-items:center;height:16px;margin:0 -4px}.S9hcf7YrBdBIgJ9k2Yfe.Mkzj9WOTGz_SsKLH160e{display:none}", "",{"version":3,"sources":["webpack://./src/shared/ui/TextField/ActionsAside/TextFieldClear/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,aAAA,CAEA,2CACE,YAAA","sourcesContent":[".clearAction {\n  display: flex;\n  align-items: center;\n  height: 16px;\n  margin: 0 -4px;\n\n  &.hidden {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clearAction": "S9hcf7YrBdBIgJ9k2Yfe",
	"hidden": "Mkzj9WOTGz_SsKLH160e"
};
export default ___CSS_LOADER_EXPORT___;
