// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".whC1qfL98alolBkX8T3H{background-color:var(--interaction-brand-active);height:1.5px;position:absolute;right:0;transform:translateY(-50%);top:0}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMenu/ui/ConversationMenuInboxes/MenuPlaceholderTree/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gDAAA,CACA,YAAA,CACA,iBAAA,CACA,OAAA,CACA,0BAAA,CACA,KAAA","sourcesContent":[".wrap {\n  background-color: var(--interaction-brand-active);\n  height: 1.5px;\n  position: absolute;\n  right: 0;\n  transform: translateY(-50%);\n  top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "whC1qfL98alolBkX8T3H"
};
export default ___CSS_LOADER_EXPORT___;
