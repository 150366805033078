import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { AuthLayout } from 'shared/layout'
import { RegisterStore } from 'widgets/Register'
import { Step1Form } from './Step1Form/Step1Form'
import { RightContent } from './RightContent/RightContent'

type IStep1Props = {
  store: RegisterStore
}

export const Step1: FC<IStep1Props> = observer(({ store }) => {
  return (
    <AuthLayout
      title='Tell us about your company'
      subtitle='This will help customize the app for you'
      form={<Step1Form store={store} />}
      rightContent={<RightContent />}
      onBack={store.handlePrevStep}
    />
  )
})

export default Step1
