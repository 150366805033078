import { observer } from 'mobx-react-lite'
import React from 'react'
import { TextInput } from 'shared/ui'
import { useTriggerViewContext } from 'widgets/TriggerView/context/triggerViewContext'

export const TriggerName = observer(() => {
  const { name, setName, setError, errorsMap } = useTriggerViewContext()

  const error = errorsMap.get('name')

  return (
    <TextInput
      size={'large'}
      placeholder={'Trigger name'}
      value={name}
      setValue={setName}
      error={error && 'Please enter trigger name'}
      setError={(value) => setError('name', value)}
      style={{ marginBottom: 15 }}
    />
  )
})
