import { getAriaLabel } from 'shared/lib/ariaLabel'
import { Icon, Typography } from 'shared/ui'

export const RecentlySentItem = ({
  btnClass,
  onOpenRecent,
}: {
  btnClass: string
  onOpenRecent: () => void
}) => {
  return (
    <button
      className={btnClass}
      onClick={onOpenRecent}
      aria-label={getAriaLabel('RecentlySent', 'MessageField')}
    >
      <Icon icon={'clockRecent'} fontSize={16} tertiary />
      <Typography ariaLabel='RecentlySentText' variant='body-md-medium'>
        Recently sent
      </Typography>
      <Icon icon={'chevronRight'} fontSize={16} tertiary />
    </button>
  )
}
