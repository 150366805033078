import { makeAutoObservable } from 'mobx'
import { IResponseFilterOperator } from 'entities/Contacts/api/filterTypes'
import { FilterOperator } from 'widgets/FilterEditor/model/FilterOperator'

export class FilterOperators {
  private _operator: string | null = null
  private _operatorsMap: Map<string, FilterOperator> = new Map()

  constructor(operators: IResponseFilterOperator[] = [], operator: string | null) {
    this._operator = operator
    this.setOperators(operators)

    makeAutoObservable(this)
  }

  setOperator = (operator: string | null) => {
    this._operator = operator
  }

  setOperators = (operators: IResponseFilterOperator[]) => {
    operators.forEach((item) => {
      this._operatorsMap.set(item.key, new FilterOperator(item))
    })
  }

  get operator() {
    if (!this._operator) return null

    return this._operatorsMap.get(this._operator)
  }

  get operators() {
    return Array.from(this._operatorsMap.values())
  }
}
