import { Typography, Icon } from 'shared/ui'
import { items } from './Items'

import styles from './styles.module.scss'

export const RightContent = () => {
  return (
    <div className={styles.wrap}>
      <p className={styles.title}>Text from any number</p>
      <div className={styles.content}>
        {items.map((item) => (
          <div key={item.text} className={styles.item}>
            <Icon icon={item.icon} color={item.color} fontSize={24} />
            <Typography variant={'heading-sm'} ariaLabel={item.text}>
              {item.text}
            </Typography>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <Typography className={styles.text} ariaLabel={'name'} variant={'body-lg-medium'}>
          Safe, secure, scalable, and fully compliant
        </Typography>
        <Typography className={styles.subtext} ariaLabel={'role'} variant={'body-lg-medium'}>
          Stay ahead of industry regulations by getting approved fast with our built-in SMS &
          Calling Compliance tools
        </Typography>
      </div>
    </div>
  )
}
