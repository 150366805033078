import { makeAutoObservable, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ChatbotApi, ChatbotStatus } from 'entities/Chatbot'
import type { Contact } from 'entities/Contacts/model/Contact'
import { ChatbotEnrollModal } from 'widgets/ChatbotEnroll/ui/ChatbotEnrollModal'

export class ChatbotEnrollStore {
  private _isActive = false
  private _isChecked = false
  private _chatbotEnrollModalId = 'chatbotEnrollModalId'

  constructor() {
    makeAutoObservable(this)
  }

  get isActive() {
    return this._isActive
  }

  get isChecked() {
    return this._isChecked
  }

  getBlockReason = (contacts: Contact[]) => {
    switch (true) {
      case this._isChecked && !this._isActive:
        return 'No active Textbot'
      case contacts.length === 1 && contacts[0].isOptOut:
        return 'This contact has opted-out'
      case contacts.length === 1 && contacts[0].isBlocked:
        return 'This contact has been blocked'
      case contacts.every(({ isOptOut, isBlocked }) => isOptOut || isBlocked):
        return 'Contacts has opted-out or has been blocked'
      default:
        return ''
    }
  }

  checkActivity = () => {
    ChatbotApi.getChatbotList({ limit: 1, page: 1, 'filters[status]': ChatbotStatus.Active }).then(
      ({ data }) =>
        runInAction(() => {
          this._isActive = !!data.data.length
          this._isChecked = true
        }),
      () =>
        runInAction(() => {
          this._isActive = false
          this._isChecked = true
        })
    )
  }

  enrollContacts = (contacts: Contact[]) => {
    const contactIds = contacts.map(({ id }) => id)
    const onClose = () => modalStore.removeModal(this._chatbotEnrollModalId)

    modalStore.addModal({
      id: this._chatbotEnrollModalId,
      title: 'Engage with Textbot',
      width: 560,
      showCloseButton: false,
      showCloseIcon: true,
      paddingContent: '25px',
      ModalContent: ChatbotEnrollModal,
      ModalContentProps: { contactIds, onClose },
      onClose: onClose,
    })
  }
}
