import { makeAutoObservable } from 'mobx'
import { subscriptionStore } from 'entities/Subscription'

export class NumbersStore {
  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  init() {
    subscriptionStore.init()
  }

  get usedLicense() {
    if (subscriptionStore.numbers) {
      return `${subscriptionStore.numbers.used} / ${subscriptionStore.numbers.total}`
    }
    return 0
  }
}
