import { Base } from 'models'
import { IResponseGetHubspotLists } from 'entities/Integrations'

export class HubspotList extends Base {
  id: number
  listId: string
  size: number

  constructor(item: IResponseGetHubspotLists) {
    super()

    this.id = item.id
    this.listId = String(item.list_id)
    this.size = item.size
  }
}
