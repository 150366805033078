import { makeAutoObservable, reaction } from 'mobx'
import { bannerStore, EnumAlertBannerVariant } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'

export class ReminderTrialCreditsStore {
  constructor() {
    makeAutoObservable(this)
  }

  bannerId = 'add_trial_credits'
  reactionAddTrialCredits: ReturnType<typeof reaction> | null = null

  get isOnTrial() {
    return subscriptionStore.isTrial
  }

  get isOutOfTrialCredits() {
    return organizationStore.isOutOfTrialCredits
  }

  get isOrganizationOwner() {
    return organizationStore.isOrganizationOwner
  }

  get noMessageSending() {
    return this.isOnTrial && this.isOutOfTrialCredits && organizationStore.isBillableNumberVendor
  }

  get show() {
    return this.noMessageSending
  }

  init = () => {
    this.handleReactionAddTrialCredits()
  }

  handleReactionAddTrialCredits = () => {
    if (this.reactionAddTrialCredits) this.reactionAddTrialCredits()

    this.reactionAddTrialCredits = reaction(
      () => this.show,
      (value) => {
        if (value) {
          if (this.isOrganizationOwner) {
            bannerStore.add({
              id: this.bannerId,
              action: {
                text: 'Upgrade now',
                onAction: () =>
                  uiStore.changeRoute({ path: '/settings/billing/overview', type: 'vue' }),
              },
              alert: {
                title:
                  'Your organization is out of trial credits. Upgrade now to continue sending messages.',
                variant: EnumAlertBannerVariant.Info,
              },
            })
          } else {
            bannerStore.add({
              id: this.bannerId,
              alert: {
                title:
                  'Your organization is out of trial credits. Ask your organization owner to upgrade to continue sending messages.',
                variant: EnumAlertBannerVariant.Info,
              },
            })
          }
        } else {
          bannerStore.remove(this.bannerId)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }
}
