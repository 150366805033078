// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d_JP2qJ6YaFm_zKhSNb3{width:91px;height:32px}.PyZg4hTX9cMyt0HI0C9b{visibility:hidden}.UlHUSlEaUFvI91418rJB{display:flex;justify-content:center;align-items:center;width:91px;height:32px;border-radius:6px;background:var(--action-contained-primary-normal);cursor:pointer;color:var(--content-primary-primary-inverted);font-size:13px;font-weight:500}.UlHUSlEaUFvI91418rJB:hover{background:var(--action-contained-primary-hovered)}.BvLlxEwo3KJnWxRmEKVA{position:absolute;z-index:var(--zIndex-UI_100);width:100%;height:100%;top:0px;right:0px;bottom:0px;left:0px;border-radius:6px;border:2px solid var(--action-contained-primary-normal)}", "",{"version":3,"sources":["webpack://./src/shared/ui/ExportButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CAEF,sBACE,iBAAA,CAEF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iDAAA,CACA,cAAA,CACA,6CAAA,CACA,cAAA,CACA,eAAA,CAEA,4BACE,kDAAA,CAGJ,sBACE,iBAAA,CACA,4BAAA,CACA,UAAA,CACA,WAAA,CACA,OAAA,CACA,SAAA,CACA,UAAA,CACA,QAAA,CACA,iBAAA,CACA,uDAAA","sourcesContent":[".formFileUpload {\n  width: 91px;\n  height: 32px;\n}\n.uploadFile {\n  visibility: hidden;\n}\n.uploadLabel {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 91px;\n  height: 32px;\n  border-radius: 6px;\n  background: var(--action-contained-primary-normal);\n  cursor: pointer;\n  color: var(--content-primary-primary-inverted);\n  font-size: 13px;\n  font-weight: 500;\n\n  &:hover {\n    background: var(--action-contained-primary-hovered);\n  }\n}\n.dragFileElement {\n  position: absolute;\n  z-index: var(--zIndex-UI_100);\n  width: 100%;\n  height: 100%;\n  top: 0px;\n  right: 0px;\n  bottom: 0px;\n  left: 0px;\n  border-radius: 6px;\n  border: 2px solid var(--action-contained-primary-normal);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formFileUpload": "d_JP2qJ6YaFm_zKhSNb3",
	"uploadFile": "PyZg4hTX9cMyt0HI0C9b",
	"uploadLabel": "UlHUSlEaUFvI91418rJB",
	"dragFileElement": "BvLlxEwo3KJnWxRmEKVA"
};
export default ___CSS_LOADER_EXPORT___;
