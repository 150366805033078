import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Dropdown, IconButton, Tooltip, Typography } from 'shared/ui'
import { AllFilesAccept, FilesAccept, ImageAccept, VideoAccept } from 'shared/constants/accept'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { RecentlySentItem } from 'widgets/MessageField/ui/FieldActions/MediaAction/RecentlySentItem/RecentlySentItem'
import { SizeLimitsTooltip } from 'widgets/MessageField/ui/FieldActions/MediaAction/SizeLimitsTooltip/SizeLimitsTooltip'
import { RecordVideoItem } from 'widgets/MessageField/ui/FieldActions/MediaAction/RecordVideoItem/RecordVideoItem'
import { UploadItem } from 'widgets/MessageField/ui/FieldActions/MediaAction/UploadItem/UploadItem'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { RecordAudioItem } from 'widgets/MessageField/ui/FieldActions/MediaAction/RecordAudioItem/RecordAudioItem'
import { RecentlySent, recentStore } from 'widgets/RecentlySent'
import { MediaLibraryMessageFieldButton } from 'widgets/MediaLibrary'
import styles from './styles.module.scss'

export const MediaAction = observer(() => {
  const [openRecentContent, setOpenRecentContent] = useState(false)
  const messageFieldStore = useMessageFieldContext()
  const { isModeNote, disabledFields, isCurrentAirCall } = messageFieldStore

  const handleTooltipOpen = () => {
    recentStore.firstLoadRecent()
    setOpenRecentContent(false)
  }

  const onOpenRecent = () => {
    setOpenRecentContent(true)
  }
  const onCloseRecent = () => {
    setOpenRecentContent(false)
  }

  useEffect(() => {
    recentStore.firstLoadRecent()

    return () => {
      recentStore.reset()
    }
  }, [])

  const isUploadDisabled = disabledFields.hasOwnProperty('uploadImage')
  const isRecordAudioDisabled = disabledFields.hasOwnProperty('recordAudio')
  const isRecordVideoDisabled = disabledFields.hasOwnProperty('recordVideo')

  return (
    <>
      <Dropdown
        ariaLabel={'MediaAction'}
        placement={'bottom-start'}
        typeButton={'icon-button'}
        triggerComponent={(open) => (
          <div>
            <Tooltip label={open ? '' : 'Add media'} title={''} placement={'top'}>
              <IconButton
                icon={'addCircle'}
                variant={isModeNote ? 'contained' : 'icon'}
                color={isModeNote ? 'passiveSecondary' : 'secondary'}
                onClick={handleTooltipOpen}
                active={open}
                ariaLabel='MediaAction_addMedia'
              />
            </Tooltip>
          </div>
        )}
        customComponent={(onCloseMenu, dropdownId) => (
          <div className={styles.dropdown}>
            {openRecentContent ? (
              <RecentlySent
                reverse={!messageFieldStore.fromSavedReply}
                onCloseMenu={onCloseMenu}
                onCloseRecent={onCloseRecent}
                openRecentContent={openRecentContent}
                dropdownId={dropdownId}
                isCurrentAirCall={isCurrentAirCall}
              />
            ) : (
              <>
                <div className={styles.header}>
                  <Typography variant={'body-md-medium'} ariaLabel={'Media'}>
                    Media
                  </Typography>
                  <SizeLimitsTooltip />
                  <MediaLibraryMessageFieldButton onClick={onCloseMenu} />
                </div>
                {!!recentStore.recentAttachments.length && (
                  <div className={styles.wrapRecent}>
                    <RecentlySentItem btnClass={styles.dropdownBtn} onOpenRecent={onOpenRecent} />
                  </div>
                )}

                <div className={styles.content}>
                  <Tooltip
                    label={isUploadDisabled ? disabledFields.uploadImage : ''}
                    placement='right'
                    fullWidth
                  >
                    <UploadItem
                      btnClass={styles.dropdownBtn}
                      onCloseMenu={onCloseMenu}
                      accept={ImageAccept}
                      icon='imageFilled'
                      label='Upload image'
                      disabled={isUploadDisabled}
                    />
                  </Tooltip>
                  <UploadItem
                    btnClass={styles.dropdownBtn}
                    onCloseMenu={onCloseMenu}
                    accept={
                      isCurrentAirCall && !featureFlagsStore.aircall_new_api
                        ? [...FilesAccept, ...VideoAccept]
                        : AllFilesAccept
                    }
                    icon='upload'
                    label='Upload file'
                  />
                  <RecordAudioItem
                    disabled={isRecordAudioDisabled}
                    btnClass={styles.dropdownBtn}
                    onCloseMenu={onCloseMenu}
                  />
                  <RecordVideoItem
                    disabled={isRecordVideoDisabled}
                    btnClass={styles.dropdownBtn}
                    onCloseMenu={onCloseMenu}
                  />
                </div>
              </>
            )}
          </div>
        )}
      />
    </>
  )
})
