import { MouseEvent, FC } from 'react'
import { Button } from 'shared/ui'
import { useConversationListContext } from 'widgets/ConversationList/context'

type IButtonManageTagsProps = {
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  clickCallback?: () => void
}

export const ButtonManageTags: FC<IButtonManageTagsProps> = ({
  onMouseLeave,
  onMouseEnter,
  clickCallback,
}) => {
  const store = useConversationListContext()
  const { updateConversationsTags } = store

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
    updateConversationsTags()
    clickCallback?.()
  }

  return (
    <Button
      fontWeight={400}
      onClick={handleClick}
      typeBtn='menuItem'
      icon={'tag'}
      text={'Manage tags'}
      fullWidth
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}
