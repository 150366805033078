import { useEffect } from 'react'
import { notification } from 'shared/assets/sounds'
import { inboxesStore } from 'entities/Inbox'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventMessagePopper } from 'entities/Message/api/types'
import { usersStore } from 'entities/Users'

const notificationAudio = notification.getAudio('notification')

const MessagePopper = (data: IResponseEventMessagePopper) => {
  const inbox = inboxesStore.getItem(data.inbox_id)
  const { isAwayStatus } = usersStore

  if (inbox && !inbox.isMuted && !isAwayStatus) {
    notificationAudio.play().catch(console.error)
  }
}

export const useEventMessagePopper = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.MessagePopper, MessagePopper)
    }
  }, [websocket.isConnected])
}
