// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mcxWa8pt2kjbOW3MOEB1{display:flex;align-items:center;justify-content:space-between;height:33px;border-radius:6px;padding:0 8px;cursor:pointer}.mcxWa8pt2kjbOW3MOEB1:hover{background:var(--background-neutral-subtle)}.mcxWa8pt2kjbOW3MOEB1:last-of-type{margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsOptInModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CACA,iBAAA,CACA,aAAA,CACA,cAAA,CAEA,4BACE,2CAAA,CAGF,mCACE,eAAA","sourcesContent":[".section {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 33px;\n  border-radius: 6px;\n  padding: 0 8px;\n  cursor: pointer;\n\n  &:hover {\n    background: var(--background-neutral-subtle);\n  }\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "mcxWa8pt2kjbOW3MOEB1"
};
export default ___CSS_LOADER_EXPORT___;
