import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { SearchDropdownContext, SearchDropdownStore } from 'widgets/SearchDropdown'
import { DropdownAllContentSearch } from './DropdownAllContentSearch'

type IDropdownAllSearchProps = {
  store: SearchDropdownStore
  onCloseMenu?: () => void
}

export const DropdownAllSearch: FC<IDropdownAllSearchProps> = observer(({ store, onCloseMenu }) => {
  return useMemo(
    () => (
      <SearchDropdownContext.Provider value={store}>
        <DropdownAllContentSearch onCloseMenu={onCloseMenu} />
      </SearchDropdownContext.Provider>
    ),
    []
  )
})
