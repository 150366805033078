import CircularProgress from '@mui/material/CircularProgress'
import { Fragment, useState } from 'react'
import { useLocalObservable } from 'mobx-react-lite'
import { ActionItem, IActionsProps, IColumn, Switch } from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import dayjs from 'shared/lib/dayjs'
import { uiStore } from 'shared/store/uiStore'
import { IWorkflow } from 'entities/Workflow'
import { useWorkflowListContext } from 'pages/workflows/context'
import { WorkflowStatusBadge } from 'pages/workflows/ui'
import { workflowOperation } from 'pages/workflows/store/operationStore'

import styles from './styles.module.scss'

export type IWorkflowRow = {
  id: string
  workflow: IWorkflow
}

export type IWorkflowActionRow = IWorkflowRow & {
  actionsProps: IActionsProps
}

const COLUMNS: IColumn<IWorkflowRow>[] = [
  {
    field: 'name',
    name: 'Name',
    minWidth: '145px',
    maxWidth: '245px',
    isDisabledChangeVisible: true,
    renderRowCell: ({ workflow }) => workflow.name,
  },
  {
    field: 'status',
    name: 'Status',
    minWidth: '84px',
    maxWidth: '120px',
    isDisabledChangeVisible: true,
    renderRowCell: ({ workflow }) => <WorkflowStatusBadge active={workflow.enabled} />,
  },
  {
    field: 'enrolled',
    name: 'Enrolled',
    minWidth: '84px',
    maxWidth: '120px',
    isDisabledChangeVisible: true,
    renderRowCell: ({ workflow }) => workflow.executionsCount,
  },
  {
    field: 'created_at',
    name: 'Created',
    minWidth: '120px',
    maxWidth: '260px',
    renderRowCell: ({ workflow }) => dayjs(workflow.createdAt).format(DayjsFormats.fullWithAtDash2),
  },
  {
    field: 'last_modified',
    name: 'Last modified',
    minWidth: '120px',
    maxWidth: '260px',
    renderRowCell: ({ workflow }) =>
      !!workflow.updatedAt ? (
        dayjs(workflow.updatedAt).format(DayjsFormats.fullWithAtDash2)
      ) : (
        <Fragment />
      ),
  },
]

export const useWorkflowTable = () => {
  const [columnsIds, setColumnIds] = useState(() => COLUMNS.map(({ field }) => field))
  const { items, loadData } = useWorkflowListContext()

  const duplications = useLocalObservable(() => new Set<string>())
  const activations = useLocalObservable(() => new Set<string>())
  const deletions = useLocalObservable(() => new Set<string>())

  const makeActivateAction = (workflow: IWorkflow): ActionItem => {
    let closeMenu: (() => void) | undefined

    const content = (
      <div
        className={styles.activeAction}
        onClick={() => {
          if (activations.has(workflow.id)) return

          const action = workflow.enabled ? workflowOperation.disable : workflowOperation.enable

          activations.add(workflow.id)
          action(workflow).then((payload) => {
            if (payload) {
              workflow.syncOrigin(payload)
              closeMenu?.()
            }

            activations.delete(workflow.id)
          })
        }}
      >
        <div className={styles.activeActionControl}>
          {activations.has(workflow.id) ? (
            <CircularProgress size={12} />
          ) : (
            <Switch className={styles.activeActionSwitch} size='small' value={workflow.enabled} />
          )}
        </div>
        {workflow.enabled ? 'Active' : 'Disabled'}
      </div>
    )

    return {
      dropdownItemComponent: (onCloseMenu) => ((closeMenu = onCloseMenu), content),
    }
  }

  const makeEditAction = (workflow: IWorkflow): ActionItem => ({
    icon: 'edit',
    text: 'Edit',
    onAction: () => uiStore.changeRoute({ path: `/workflows/view/${workflow.id}`, type: 'vue' }),
  })

  const makeDuplicateAction = (workflow: IWorkflow): ActionItem => ({
    icon: 'copy',
    text: 'Duplicate',
    buttonProps: {
      disabled: duplications.has(workflow.id) || deletions.has(workflow.id),
      loading: duplications.has(workflow.id),
    },
    onAction: () => {
      duplications.add(workflow.id)
      workflowOperation.duplicate(workflow).then((duplicated) => {
        duplications.delete(workflow.id)

        if (duplicated)
          uiStore.changeRoute({ path: `/workflows/view/${duplicated.id}`, type: 'vue' })
      })
    },
  })

  const makeDeleteAction = (workflow: IWorkflow): ActionItem => ({
    icon: 'delete',
    text: 'Delete',
    buttonProps: {
      disabled: deletions.has(workflow.id) || duplications.has(workflow.id),
      loading: deletions.has(workflow.id),
    },
    onAction: () => {
      deletions.add(workflow.id)
      workflowOperation.delete(workflow).then((deleted) => {
        deletions.delete(workflow.id)

        if (deleted) loadData()
      })
    },
  })

  const columns = COLUMNS
  const rows = items.map((workflow) => {
    const actions = [
      makeActivateAction(workflow),
      makeEditAction(workflow),
      makeDuplicateAction(workflow),
      makeDeleteAction(workflow),
    ] as ActionItem[]

    return { id: workflow.id, workflow, actionsProps: { actions } }
  })

  return { rows, columns, columnsIds, setColumnIds }
}
