import { action, makeObservable, observable } from 'mobx'
import { IActionsProps, IIconsVector, INameValueRecordProps } from 'shared/ui'
import { TEAM_NUMERABLE_TYPE } from 'shared/constants/strange'
import {
  IResponseOneTimeBroadcast,
  IResponseOneTimeBroadcastStatus,
} from 'entities/Broadcast/api/types'
import { IBroadcastType } from 'entities/Broadcast'
import { usersStore } from 'entities/Users'
import { User } from 'entities/Users/model/User'
import { BroadcastOneTimeStatistic } from 'entities/Broadcast/model/BroadcastOneTimeStatistic'
import { Statistic } from 'entities/ContactStatistic/model/Statistic'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { IResponseMediaShort } from 'entities/Attachment'

type ISendFromNumbersProps = INameValueRecordProps & {
  id?: number
}

export class BroadcastOneTime {
  id: number
  recurring_parent_id?: number | null
  type!: IBroadcastType
  name!: string
  owner_id!: number
  status!: IResponseOneTimeBroadcastStatus
  failed_reason!: string | null
  broadcastOwner?: User
  created_at!: string

  sent_at!: string
  send_at!: string
  is_track_conversion?: boolean
  conversion_id?: number | null = null

  statistics: Statistic | null = null

  sendFromNumbersProps: ISendFromNumbersProps[] | null = null

  message = ''
  media_url: IResponseMediaShort[] = []
  attachments: Attachment[] = []
  contacts_count: number | null = null
  invalid_contacts_count: number | null = null
  short_urls: IResponseOneTimeBroadcast['short_urls'] | null = []
  package_count = 0
  response: IResponseOneTimeBroadcast

  constructor(item: IResponseOneTimeBroadcast) {
    this.id = item.id
    this.response = item
    this.syncOrigin(item)
    makeObservable(this, {
      status: observable,
      syncOrigin: action,
    })
  }

  syncOrigin = (item: IResponseOneTimeBroadcast) => {
    this.id = item.id
    this.recurring_parent_id = item.recurring_parent_id
    this.statistics = new BroadcastOneTimeStatistic(item)
    this.type = item.is_rvm ? 'ringless' : 'sms'
    this.status = item.status
    this.failed_reason = item.failed_reason
    this.sent_at = item.sent_at
    this.send_at = item.send_at || ''

    this.broadcastOwner = new User(item.user_owner)
    this.is_track_conversion = item.is_track_conversion
    this.conversion_id = item.conversion_id
    this.sendFromNumbersProps = this.getSendFromNumbersProps(item)
    this.owner_id = item.owner_id
    this.name = item.name
    this.created_at = item.created_at
    this.message = item.message
    this.media_url = item.media_url
    this.attachments = Array.isArray(item.media_url)
      ? item.media_url.map((media) => new Attachment({ responseMediaShort: media }))
      : []
    this.contacts_count = item.contacts_count
    this.invalid_contacts_count = item.invalid_contacts_count
    this.short_urls = item.short_urls
    this.package_count = item.package_count
  }

  get user() {
    const userFormStore = usersStore.getItem(this.owner_id)
    if (userFormStore) {
      return userFormStore
    }
    if (this.broadcastOwner) {
      return this.broadcastOwner
    } else {
      return usersStore.getItem(this.owner_id, true)
    }
  }

  actionsProps: IActionsProps | null = null

  setActionsProps: (actionProps: IActionsProps) => void = (actionProps: IActionsProps) => {
    this.actionsProps = actionProps
  }

  getSendIcon = (broadcast: IResponseOneTimeBroadcast): IIconsVector | undefined => {
    const number = broadcast.number
    if (number?.is_aircall) {
      return 'aircall'
    }
    if (number?.numberable?.numberable_type === TEAM_NUMERABLE_TYPE || !number?.numberable) {
      return 'folder'
    }
    return 'inboxOpen'
  }

  getSendFromNumbersProps = (broadcast: IResponseOneTimeBroadcast): ISendFromNumbersProps[] => {
    if (broadcast.team) {
      return broadcast.team.numbers?.map((number) => ({
        id: number?.id,
        icon: this.getSendIcon(broadcast),
        name: broadcast.team?.name,
        value: number.formatted_number,
      }))
    }
    return [
      {
        id: broadcast.number?.id,
        icon: this.getSendIcon(broadcast),
        name: broadcast.number?.numberable?.name,
        value: broadcast.number?.formatted_number,
      },
    ]
  }
}
