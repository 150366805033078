import { makeAutoObservable } from 'mobx'
import { ModalType } from 'shared/ui/Modal/store/types'

class ModalStore {
  constructor() {
    makeAutoObservable(this)
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        const lastModal = this.modals[this.modals.length - 1]
        if (lastModal) {
          this.escCloseModal(lastModal.id)
        }
      }
    })
  }

  modalsMap = new Map<string, ModalType<object>>()
  isHoverModal = false
  hideModalIdsSet: Set<string> = new Set()
  disabledOnCloseAllModalIds: Set<string> = new Set()
  disabledOnEscCloseModalIds: Set<string> = new Set()

  get modals() {
    return Array.from(this.modalsMap.values())
  }

  hasModal = (id: string) => {
    return this.modalsMap.has(id)
  }

  disabledOverlayClose = (id: string) => {
    const modal = this.modalsMap.get(id)

    return modal ? Boolean(modal.disabledOverlayClose) : false
  }

  getModal = (id: string) => {
    return this.modalsMap.get(id)
  }

  addModal = <T extends object = object>(modal: ModalType<T>) => {
    if (modal.disabledOnAllClose) {
      this.disabledOnCloseAllModalIds.add(modal.id)
    }

    if (modal.disabledOnEscClose) {
      this.disabledOnEscCloseModalIds.add(modal.id)
    }

    this.modalsMap.set(modal.id, modal as ModalType<object>)
  }

  removeModal = (id: string) => {
    if (this.disabledOnCloseAllModalIds.has(id)) {
      this.disabledOnCloseAllModalIds.delete(id)
    }
    if (this.disabledOnEscCloseModalIds.has(id)) {
      this.disabledOnEscCloseModalIds.delete(id)
    }
    this.modalsMap.delete(id)
  }

  escCloseModal = (id: string) => {
    if (this.disabledOnEscCloseModalIds.has(id)) return
    this.closeModal(id)
  }

  closeModal = (id: string) => {
    const modal = this.modalsMap.get(id)
    if (modal?.onClose) {
      modal?.onClose()
    } else {
      this.removeModal(id)
    }
  }
  setIsHoverModal = (isHoverModal: typeof this.isHoverModal) => {
    this.isHoverModal = isHoverModal
  }

  setHideModalId = (id: string) => {
    this.hideModalIdsSet.add(id)
  }
  removeHideModalId = (id: string) => {
    this.hideModalIdsSet.delete(id)
  }

  closeAllModals = () => {
    this.modalsMap.forEach((modal) => {
      if (!this.disabledOnCloseAllModalIds.has(modal.id)) {
        this.closeModal(modal.id)
      }
    })
  }
}

export default new ModalStore()
