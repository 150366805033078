// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CEJRrGoJqmY_Mk8UvKtG{padding:0 24px 24px 24px}.bpsXrSzoNlGZQTKZauV8{display:flex;gap:4px;margin-top:2px;align-items:center;margin-left:8px}.KZMjdYyeoY6Og689KEO2{justify-content:flex-end;align-items:center;margin-top:12px;display:flex;gap:8px}.BVdO4XYsIMY9mUqVDAWh{font-size:14px}.BVdO4XYsIMY9mUqVDAWh input{height:18px}", "",{"version":3,"sources":["webpack://./src/entities/ShortLink/ui/ShortLinkContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CAIF,sBACE,wBAAA,CACA,kBAAA,CACA,eAAA,CACA,YAAA,CACA,OAAA,CAGF,sBACE,cAAA,CAEA,4BACE,WAAA","sourcesContent":[".wrap{\n  padding: 0 24px 24px 24px;\n}\n\n.helper{\n  display: flex;\n  gap: 4px;\n  margin-top: 2px;\n  align-items: center;\n  margin-left: 8px;\n}\n\n\n.actions{\n  justify-content: flex-end;\n  align-items: center;\n  margin-top: 12px;\n  display: flex;\n  gap: 8px;\n}\n\n.textField {\n  font-size: 14px;\n\n  input {\n    height: 18px\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "CEJRrGoJqmY_Mk8UvKtG",
	"helper": "bpsXrSzoNlGZQTKZauV8",
	"actions": "KZMjdYyeoY6Og689KEO2",
	"textField": "BVdO4XYsIMY9mUqVDAWh"
};
export default ___CSS_LOADER_EXPORT___;
