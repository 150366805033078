import { createContext, useContext } from 'react'
import type { RinglessMessageFieldStore } from 'widgets/MessageFormFields/RinglessMessageField/store/ringlessMessageFieldStore'

export const RinglessMessageFieldContext = createContext<RinglessMessageFieldStore | null>(null)

export function useRinglessMessageFieldContext() {
  const context = useContext(RinglessMessageFieldContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with RinglessMessageFieldContext'
    )
  }
  return context
}
