import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { RecordStore } from 'widgets/Record'
import styles from 'widgets/Record/ui/RecordActions/RecordPlayer/styles.module.scss'

export const RecordVideoContent = observer(({ recordStore }: { recordStore: RecordStore }) => {
  const { VIDEO_RECORD_LIMIT, currentVideoRecordDuration } = recordStore

  return (
    <Typography className={styles.time} variant={'body-md-regular'} tag={'div'} ariaLabel={'time'}>
      {secondsToTime(currentVideoRecordDuration)} / {secondsToTime(VIDEO_RECORD_LIMIT)}
    </Typography>
  )
})
