import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { IChatbotFullData } from 'entities/Chatbot'
import { DetailsStore } from './detailsStore'

const DetailsContext = createContext<DetailsStore | null>(null)

export const useDetailsContext = () => {
  const context = useContext(DetailsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with DetailsContext')

  return context
}

export const DetailsProvider = ({
  data,
  children,
}: PropsWithChildren<{ data: IChatbotFullData }>) => {
  const store = useMemo(() => new DetailsStore(data), [])

  return <DetailsContext.Provider value={store}>{children}</DetailsContext.Provider>
}
