import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { contactsTagsModalStore } from 'widgets/ContactsTagsModal'

export const ContactsTagsModalActions = observer(() => {
  const { handleSave, loading, mode: activeTabKey, hasSelected } = contactsTagsModalStore

  const btnText = activeTabKey === 'add' ? 'Add' : 'Remove'

  return (
    <Button
      typeBtn={'contained'}
      text={btnText}
      disabled={loading || !hasSelected}
      onClick={handleSave}
    />
  )
})
