import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import {
  Button,
  EmptyState,
  EnumSpinnerLoaderPosition,
  IRow,
  SpinnerLoader,
  Table,
  variantActionsProps,
} from 'shared/ui'
import { BroadcastRoutesEnum, IBroadcast } from 'entities/Broadcast'
import { useEventBroadcastCanceled } from 'entities/Broadcast/events/EventBroadcastCanceled'
import { useBroadcastDetailRecurringContext } from 'widgets/BroadcastDetail'
import { getColumns } from 'widgets/BroacastList/ui/BroadcastTable/getColumns'
import { getBroadcastActions } from 'widgets/BroadcastActions'
import styles from './styles.module.scss'

export const RecurringTable = observer(() => {
  const { broadcast, tableStore } = useBroadcastDetailRecurringContext()
  const { visibleColumnsIds, setVisibleColumnsIds } = tableStore
  const getRowLink = (row: IRow<IBroadcast>) =>
    `/${BroadcastRoutesEnum.broadcasts}/${
      row.status === 'draft' || row.status === 'scheduled'
        ? BroadcastRoutesEnum.edit
        : BroadcastRoutesEnum.view
    }/${row.id}`

  const columns = useMemo(() => getColumns('sms'), [])

  useEventBroadcastCanceled(broadcast.handleUpdate)

  useEffect(() => {
    if (!visibleColumnsIds.length) {
      setVisibleColumnsIds(
        columns.filter((column) => !column.isHideByDefault).map((column) => column.field)
      )
    }
  }, [])

  if (broadcast.loading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }

  if (!broadcast.rows.length) {
    return <EmptyState text={'No broadcasts found'} noIcon />
  }

  const rows = broadcast.rows.map((item) => {
    item.setActionsProps &&
      item.setActionsProps({
        ...variantActionsProps.table,
        actions: getBroadcastActions({
          broadcast: item,
          onSuccessDelete: () => {
            broadcast.onDelete([item.id])
          },
        }),
      })
    return item
  })

  return (
    <div className={styles.wrap}>
      <Table
        columns={columns}
        pinnedColumnNames={['expandedColumn', 'name']}
        rows={rows}
        visibleColumnsIds={visibleColumnsIds}
        setVisibleColumnsIds={setVisibleColumnsIds}
        getRowLink={getRowLink}
        noPagination
        sxTable={{ marginRight: 2, width: 'calc(100% - 16px)' }}
        bottomTableContent={
          <td className={styles.moreBtn}>
            <Button
              text={'Load more'}
              onClick={broadcast.onLoadMore}
              typeBtn={'contained'}
              contained={'secondary'}
              size={'small'}
              loading={broadcast.loadingMore}
              disabled={!broadcast.isMoreExpand}
            />
          </td>
        }
      />
    </div>
  )
})
