import { camelCase, upperFirst } from 'lodash'

export const getAriaLabel = (name: string, prefix?: string) => {
  name = upperFirst(camelCase(name))
  prefix = upperFirst(camelCase(prefix))

  if (prefix) {
    return `${name}_${prefix}`
  }

  return name
}
