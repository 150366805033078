// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JMI1_EHg2k2RdnoCZRhK{display:flex;min-height:20px;align-items:center;gap:4px;color:var(--yellow-90)}.tA341pbE9a4g_PJ1MFai{line-height:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Warning/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,kBAAA,CACA,OAAA,CACA,sBAAA,CAEF,sBACE,gBAAA","sourcesContent":[".warning {\n  display: flex;\n  min-height: 20px;\n  align-items: center;\n  gap: 4px;\n  color: var(--yellow-90);\n}\n.text {\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "JMI1_EHg2k2RdnoCZRhK",
	"text": "tA341pbE9a4g_PJ1MFai"
};
export default ___CSS_LOADER_EXPORT___;
