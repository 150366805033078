import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { BillingInterval } from 'entities/Admin/customPrices/api/type'
import { adminOrganizationCustomPlanStore } from 'pages/admin/pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'
import { adminOrganizationPlanStore } from 'pages/admin/pages/organizations/store/adminOrganizationPlanStore'
import { MAX_ADDITIONAL_CREDIT_PRICE } from 'pages/admin/pages/customPrices/ui/CustomPriceForm/constants'
import { SelectPlan } from '../SelectPlan/SelectPlan'
import { IManagePlanFormActions, ManagePlanFormFields } from '../ManagePlan/ManagePlanForm'

interface ISelectPlanFormProps {
  store: IManagePlanFormActions
  withSetContract?: boolean
  disablesPaidNumbers?: boolean
  isTrial?: boolean
}

export const SelectPlanForm = observer(
  ({ store, withSetContract, disablesPaidNumbers, isTrial }: ISelectPlanFormProps) => {
    const { selectedPlanData } = adminOrganizationPlanStore
    const { selectedPlan } = adminOrganizationCustomPlanStore

    const planData = selectedPlanData(selectedPlan)
    return (
      <>
        <Grid container spacing={2} mb={2}>
          <SelectPlan freeOnly={isTrial} />
          <ManagePlanFormFields
            store={{
              contractTerm: (planData?.contract_term as BillingInterval) || null,
              credits: planData?.credits || null,
              billingFrequency: (planData?.interval as BillingInterval) || null,
              totalAmount: planData?.total ? planData?.total / 100 : 0,
              additionalCreditsPrice:
                Number(planData?.additional_credit_amount) || MAX_ADDITIONAL_CREDIT_PRICE,
              isValidAdditionalCreditPrice: true,
              contractTermName: planData?.contractTermName || '',
              seats: planData?.free_seats || 0,
              numbers: planData?.free_numbers || 0,
              ...store,
            }}
            withSetContract={withSetContract}
            disablesPaidNumbers={disablesPaidNumbers}
            isTrial={isTrial}
            disabled
          />
        </Grid>
      </>
    )
  }
)
