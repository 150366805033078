import { makeAutoObservable } from 'mobx'
import { Contact } from 'entities/Contacts/model/Contact'
import { IContactProfileInfoStoreConfig } from 'widgets/ContactProfileInfo/store/types'

export class ContactProfileInfoStore {
  private _contact: Contact | null = null
  private _currentContact: Contact | null = null
  private _contacts: Contact[] | null = null

  constructor(config: IContactProfileInfoStoreConfig) {
    makeAutoObservable(this)

    this._contact = config.contact || null
    this._currentContact = config.currentContact || config.contact || null
    this._contacts = config.contacts || null
  }

  setContact = (item: Contact | null) => {
    this._contact = item
    this._currentContact = item
  }
  get contact() {
    return this._contact
  }

  get contactsAvatarInfo() {
    return this._contacts?.map((item) => item?.avatarInfo)
  }

  get currentContactsAvatarInfo() {
    if (this._currentContact) {
      return [this._currentContact.avatarInfo]
    }

    return this.contactsAvatarInfo
  }

  get hasContact() {
    return this.contact
  }

  get hasParticipants() {
    if (this._currentContact) {
      return false
    }

    return this._contacts && this._contacts.length > 1
  }

  get name() {
    return this._currentContact?.name || ''
  }

  get formattedNumber() {
    return this._currentContact?.formatted_number
  }

  get isNameEqualsPhone() {
    return this.name === this.formattedNumber
  }

  get timezone() {
    return this._currentContact?.timezone
  }
  get areaCodeInfo() {
    return this._currentContact?.area_code_info?.time_zone
  }
  get contactsCount() {
    return this._contacts?.length || 0
  }
}
