// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MxlugfknFsJKW9Z7E6zB{display:flex;align-items:center}.bkp_88Y50_5qYJpxPS14{color:var(--content-primary-secondary);font-size:13px;font-weight:400}.IFJRsq4gm92gqaslBmaJ{color:var(--content-primary-secondary);margin-right:4px}.IFJRsq4gm92gqaslBmaJ.OB4DYN5c7kyx5qYg5MyA{color:var(--content-negative-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationCard/ui/ConversationCardCall/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,sCAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,sCAAA,CACA,gBAAA,CAEA,2CACE,qCAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n}\n\n.label {\n  color: var(--content-primary-secondary);\n  font-size: 13px;\n  font-weight: 400;\n}\n\n.icon {\n  color: var(--content-primary-secondary);\n  margin-right: 4px;\n\n  &.faked {\n    color: var(--content-negative-primary);\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "MxlugfknFsJKW9Z7E6zB",
	"label": "bkp_88Y50_5qYJpxPS14",
	"icon": "IFJRsq4gm92gqaslBmaJ",
	"faked": "OB4DYN5c7kyx5qYg5MyA"
};
export default ___CSS_LOADER_EXPORT___;
