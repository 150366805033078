import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { AttachmentCard } from 'entities/Attachment'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { MessageCardType, MessageProps } from 'widgets/MessageCard/ui/types'
import { ConversationMessageCardItem } from 'widgets/MessageCard/ui/ConversationMessageCardItem/ConversationMessageCardItem'
import { DateContent } from 'widgets/MessageCard/ui/DateContent/DateContent'
import styles from './styles.module.scss'

export const VideoContent = observer((props: MessageProps) => {
  const { videos } = props.message

  const onOpenPresentation = (index: number) => () => {
    presentationStore.setAttachments(videos, index)
  }

  if (!videos.length) {
    return <></>
  }

  return (
    <>
      {videos.map((attachment, index) => (
        <ConversationMessageCardItem
          key={attachment.id}
          message={props.message}
          type={MessageCardType.Video}
        >
          <div className={classNames(styles.wrap)}>
            <AttachmentCard
              attachment={attachment}
              medium
              noLink
              onCardClick={onOpenPresentation(index)}
            />
          </div>
          <DateContent {...props} />
        </ConversationMessageCardItem>
      ))}
    </>
  )
})
