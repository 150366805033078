import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Icon, Space, Typography } from 'shared/ui'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import { contactsStore } from 'entities/Contacts'
import { integrationLink, IIntegrationLinkTypes, integrationsStore } from 'entities/Integrations'
import { Deal } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/AllView/AllDeals/Deal'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import styles from '../styles.module.scss'

export const AllDeals = observer(() => {
  const [search, setSearch] = useState('')
  const store = useContactsIntegrationsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)
  const integrationKey = store.viewIntegrationKey

  if (!integrationKey || !contact) return null

  const integration = integrationsStore.getIntegration(integrationKey)
  const linkProps = {
    key: integrationKey,
    contact: contact,
    integration: integration,
    type: IIntegrationLinkTypes.deal,
  }

  const link = (id: number | null | undefined) => {
    return integrationLink({
      ...linkProps,
      id: id,
    })
  }

  const deals = store.viewAllData.deals.filter(
    (deal) => deal.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  const notFound = deals.length === 0

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.search}>
        <TextField
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={setSearch}
          leftActions={[makeTextFieldIcon({ icon: 'search' })]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>

      <div className={styles.list}>
        {deals.map((deal) => (
          <Deal
            key={deal.id}
            deal={deal}
            link={link(deal.id)}
            contact={contact}
            integrationKey={integrationKey}
          />
        ))}
        {notFound ? (
          <div className={styles.epmtyList}>
            <Icon icon='search' fontSize={24} />
            <Typography variant={'body-rg-regular'} ariaLabel={'NoResultsFound'}>
              No results found
            </Typography>
          </div>
        ) : (
          <Space height={60} />
        )}
      </div>
    </div>
  )
})
