import React from 'react'
import { observer } from 'mobx-react-lite'
import { IIconButtonColor } from 'shared/ui'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { EmojiPicker } from 'widgets/MessageField/ui/EmojiPicker/EmojiPicker'

type IEmojiActionProps = {
  iconColor?: IIconButtonColor
}

export const EmojiAction: React.FC<IEmojiActionProps> = observer(({ iconColor }) => {
  const messageFieldStore = useMessageFieldContext()

  const onTrigger = () => {
    if (messageFieldStore.isVariantNote) {
      messageFieldStore.setActiveMessageField(true)
    }
  }

  return (
    <EmojiPicker
      messageFieldStore={messageFieldStore}
      iconColor={iconColor}
      onTrigger={onTrigger}
    />
  )
})
