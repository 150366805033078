import React from 'react'
import { Box } from '@mui/material'
import { IColumn, Avatar, Typography, Button } from 'shared/ui'
import { IContactDuplicate } from 'entities/Contacts/api/types'
import { contactsCleanupStore } from 'widgets/ContactsTable'
import { contactsMergeModalStore } from 'widgets/ContactsMergeModal'
import styles from './styles.module.scss'

const ContactRowCell = ({ contact }: { contact: IContactDuplicate }) => {
  const colors = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']
  const color = colors[contact.id % colors.length]

  const avatarInfo = {
    firstName: '#',
    lastName: '',
    color,
    phone: contact.formatted_number,
    image: undefined,
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        height: '32px',
      }}
      gap={1}
      display='flex'
    >
      <Avatar info={avatarInfo} size={24} />
      <Typography ellipsis variant={'body-md-regular'} tag={'div'} ariaLabel={'formatted_number'}>
        {contact.formatted_number}
      </Typography>
    </Box>
  )
}

const DuplicateRowCell = ({ contact }: { contact: IContactDuplicate }) => {
  return <div className={styles.duplicatesCount}>{contact.count - 1}</div>
}

const ActionsRowCell = ({ contact }: { contact: IContactDuplicate }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '32px',
      }}
      gap={1}
      display='flex'
    >
      <Button
        size={'medium'}
        contained={'tertiary'}
        text={'Review'}
        onClick={() => {
          contactsMergeModalStore.init(
            contactsCleanupStore.contactsDuplicateTable.list,
            contact.id,
            contactsCleanupStore.contactsDuplicateTable.reset
          )
        }}
      />
    </Box>
  )
}

export const columns: IColumn<IContactDuplicate>[] = [
  {
    field: 'name',
    name: 'Name',
    fullName: 'Name',
    renderRowCell: (row) => <ContactRowCell contact={row} />,
    maxWidth: 365,
  },
  {
    field: 'duplicates',
    name: 'Duplicates',
    renderRowCell: (row) => <DuplicateRowCell contact={row} />,
  },
  {
    field: 'actions',
    name: ' ',
    renderRowCell: (row) => <ActionsRowCell contact={row} />,
  },
]
