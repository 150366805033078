import React from 'react'
import { Status } from 'shared/ui'
import { IStatusProps } from 'shared/ui/Status'

export const statusConfig = {
  approved: {
    title: 'Approved',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
  action_required: {
    title: 'Action Required: Check Email',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
  pending: {
    title: 'Pending 2FA Email to be Sent',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
}

export type IStatus = keyof typeof statusConfig | null

export const BrandTwoFaVerificationStatus = ({ status }: { status: IStatus }) =>
  status && <Status {...statusConfig[status]} />
