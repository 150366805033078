import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Table } from 'shared/ui'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { type ContactsDuplicateTableStore } from 'pages/contacts/pages/cleanup/ui/ContactsDuplicateTable'
import { columns } from './columns'
import styles from './styles.module.scss'

type IContactsDuplicateTableProps = {
  store: ContactsDuplicateTableStore
}

export const ContactsDuplicateTable: FC<IContactsDuplicateTableProps> = observer(({ store }) => {
  const { list, total, paginationData, loading, changePagination, noResults } = store

  return (
    <div className={styles.tableContainer}>
      {noResults && !loading ? (
        <NoResultsFound label={'No duplicate contacts found'} />
      ) : (
        <Table
          sxBox={{ padding: '0 16px' }}
          columns={columns(store)}
          rows={list}
          limit={paginationData.length}
          initPage={paginationData.page}
          rowsCount={total}
          isLoading={loading}
          onChangePagination={changePagination}
        />
      )}
    </div>
  )
})
