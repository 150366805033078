import { observer } from 'mobx-react-lite'
import { Alert, SpinnerLoader, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { numbersStore } from 'entities/Phone'
import { getStartedModalStore } from 'widgets/getStartedModal'

import styles from './styles.module.scss'

export const GetStartedModalContent = observer(() => {
  const { number, loading } = getStartedModalStore
  const { organizationNumber } = numbersStore
  const ariaLabel = 'GetStartedModalContent'

  return (
    <div className={styles.contentWrap} aria-label={getAriaLabel(ariaLabel)}>
      <div className={styles.icon} aria-label={getAriaLabel(ariaLabel, 'icon')}>
        🥳
      </div>
      <div className={styles.title} aria-label={getAriaLabel(ariaLabel, 'title')}>
        <Typography variant={'heading-md'} ariaLabel={'Say hello'}>
          Say hello to your trial number!
        </Typography>
      </div>
      <div className={styles.number} aria-label={getAriaLabel(ariaLabel, 'number')}>
        {loading && !number ? <SpinnerLoader /> : number?.formatted_number}
      </div>
      <div className={styles.helpText} aria-label={getAriaLabel(ariaLabel, 'helpText')}>
        <Typography variant={'body-xs-regular'} ariaLabel={'dedicated'}>
          {"Here's your dedicated number for testing Salesmsg during your trial. Enjoy!"}
        </Typography>
      </div>
      <Alert
        item={{
          type: 'infoLight',
          title: 'Get a head start with compliance!',
          desc: (
            <div>
              <br />
              <Typography variant={'body-md-regular'} ariaLabel={'Complete'}>
                Complete the carrier’s required compliance registration to activate your permanent
                number:
              </Typography>
              <Typography variant={'body-md-semibold'} ariaLabel={'formatted_number'}>
                {` ${organizationNumber?.formatted_number}`}
              </Typography>
              <br />
              <br />
              <Typography variant={'body-md-regular'} ariaLabel={'activation'}>
                Start your compliance registration during the trial period to reduce downtime after
                activation.
              </Typography>
            </div>
          ),
        }}
      />
    </div>
  )
})
