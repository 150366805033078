// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U3yUWl9dmuK8Ju4FEEiE{margin:0 20px 16px}.nnuKLsAElIdCCiWv3ANI{max-height:336px}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotCreate/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,gBAAA","sourcesContent":[".infoAlert {\n  margin: 0 20px 16px;\n}\n\n.presetList {\n  max-height: 336px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoAlert": "U3yUWl9dmuK8Ju4FEEiE",
	"presetList": "nnuKLsAElIdCCiWv3ANI"
};
export default ___CSS_LOADER_EXPORT___;
