import React, { useState } from 'react'
import { ClickAwayListener } from '@mui/material'
import classNames from 'classnames'
import { Tooltip } from 'shared/ui'
import { Speed } from './Speed/Speed'
import styles from './styles.module.scss'

const items = [
  {
    label: '1x',
    value: 1,
  },
  {
    label: '1.2x',
    value: 1.2,
  },
  {
    label: '1.5x',
    value: 1.5,
  },
  {
    label: '1.7x',
    value: 1.7,
  },
  {
    label: '2x',
    value: 2,
  },
]

export type ITooltipSpeedProps = {
  onUpdate?: (number: number) => void
}

export const SpeedMediaBtn = ({
  speed,
  setSpeed,
  fromAudioPlayer,
}: {
  speed: number
  fromAudioPlayer?: boolean
  setSpeed: (speed: number) => void
}) => {
  const [hovered, setHovered] = useState(false)
  const [open, setOpen] = useState(false)

  const onOpen = () => {
    setHovered(true)
  }
  const onClose = () => {
    setHovered(false)
  }

  return (
    <Tooltip
      open={hovered || open}
      onOpen={onOpen}
      placement={'top'}
      onClose={onClose}
      padding={4}
      label={
        <div className={styles.wrap}>
          {items.map((item) => {
            return (
              <div
                className={classNames(styles.item, {
                  [styles.active]: item.value === speed,
                })}
                key={item.value}
                onClick={() => {
                  setSpeed(item.value)
                }}
              >
                {item.label}
              </div>
            )
          })}
        </div>
      }
    >
      <div className={classNames(styles.box32 && !fromAudioPlayer)}>
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false)
          }}
        >
          <div>
            <Speed text={`${speed}x`} active={hovered || open} onClick={() => setOpen(!open)} />
          </div>
        </ClickAwayListener>
      </div>
    </Tooltip>
  )
}
