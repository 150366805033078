import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button } from 'shared/ui'
import { useBroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'

export const ReviewBroadcastAction = observer(() => {
  const [loading, setLoading] = useState(false)
  const { isImmediately, handleReviewBroadcast } = useBroadcastViewContext()

  const handleReview = async () => {
    setLoading(true)
    await handleReviewBroadcast()
    setLoading(false)
  }

  return (
    <Button
      text={isImmediately ? 'Review & send' : 'Review & schedule'}
      onClick={handleReview}
      loading={loading}
    />
  )
})
