import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Button, SearchDropdownInput, TextFieldWithLabel } from 'shared/ui'
import { Label } from 'shared/ui/Label'

import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import styles from './styles.module.scss'
import { useComplianceSettingsContext } from '../context/complianceSettingsContext'

export const AutoBusinessFind = observer(() => {
  const { einStore, openDoNotHaveEINModal } = useComplianceSettingsContext()
  const { einItem, einSearch, setFirstEinItem } = einStore

  return (
    <Box>
      <Label
        label={{
          text: 'Employer Identification Number (EIN)',
          rightText: 'Where can I find it?',
          rightLink: 'https://d37rfzzzole5ut.cloudfront.net/images/irsExample.png',
        }}
      />
      <SearchDropdownInput
        items={einStore.einItemList}
        search={einStore.einSearch}
        tipTitle='Search by EIN, e.g. 12-3456789'
        width={485}
        errorMessageValue={einStore.errorMessage}
        onChangeSearch={einStore.setEinSearch}
        onSetValue={setFirstEinItem}
        inputProps={{
          onBlur: einStore.checkEinNumber,
        }}
      />
      <Button
        text='I don’t have a United States EIN number'
        typeBtn='informative'
        contained='secondary'
        onClick={openDoNotHaveEINModal}
      />

      {einStore.einItem && (
        <div className={styles.einFieldContainer}>
          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Employer Identification Number (EIN)',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einSearch,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Legal business name',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.name,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Business address',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.street1,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'Business address 2 (optional)',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.street2,
              className: styles.numberOfCreditsInput,
            }}
          />

          <TextFieldWithLabel
            marginBottom={3}
            labelProps={{
              label: {
                text: 'City',
              },
            }}
            textFieldProps={{
              disabled: true,
              value: einItem?.city,
              className: styles.numberOfCreditsInput,
            }}
          />

          <Box display='flex' justifyContent='space-between' gap={3}>
            <Box flex={1}>
              <SelectField
                items={[]}
                setValue={() => {}}
                value={einItem?.state}
                btnProps={{
                  size: 'large',
                  disabled: true,
                  text: einItem?.state,
                }}
                dropdownProps={{
                  disabled: true,
                }}
                labelProps={{
                  label: {
                    text: 'State',
                  },
                }}
              />
            </Box>
            <Box flex={1}>
              <TextFieldWithLabel
                marginBottom={0}
                labelProps={{
                  label: {
                    text: 'Zip code',
                  },
                }}
                textFieldProps={{
                  disabled: true,
                  value: einItem?.zip,
                  className: styles.numberOfCreditsInput,
                }}
              />
            </Box>
          </Box>
        </div>
      )}
    </Box>
  )
})
