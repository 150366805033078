import { FC, useMemo, useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import {
  integrationLink,
  IIntegrationLinkTypes,
  IntegrationKey,
  integrationsStore,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IntegrationContactProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationContactProperty'
import { ShowMoreAction } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction/ui/ShowMoreAction'
import { ShowMoreActionStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction/store/showMoreActionStore'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { Actions } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/Actions'
import { SectionTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/SectionTab/SectionTab'
import { AddPropertyAction } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/AddPropertyAction/AddPropertyAction'
import { NewPropertiesDropdownStore } from 'widgets/NewPropertiesDropdown'
import { type DraggableIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/store/draggableIntegrationStore'

type IPropertiesProps = {
  integrationLabel: string
  integrationKey: IntegrationKey
  property?: IntegrationContactProperty
  contact: Contact
  action?: ReactNode
  dnd?: DraggableIntegrationStore
}

export const Properties: FC<IPropertiesProps> = observer(
  ({ contact, integrationKey, integrationLabel, property, action, dnd }) => {
    const showMoreActionStore = useMemo(
      () => new ShowMoreActionStore(integrationKey, EnumIntegrationTabs.Contact),
      []
    )
    const newPropertiesDropdownStore = useMemo(() => new NewPropertiesDropdownStore(), [])
    const integration = integrationsStore.getIntegration(integrationKey)
    const linkProps = {
      key: integrationKey,
      contact: contact,
      integration: integration,
      type: IIntegrationLinkTypes.contact,
    }
    const link = integrationLink(linkProps)

    const propertiesKeys = property?.propertiesKeys || []

    useEffect(() => {
      if (!property) return

      newPropertiesDropdownStore.searchFields('', true, 'contact')
      newPropertiesDropdownStore.setConfig({
        contactId: contact.id,
        addItem: (item) => {
          property.addProperty(item)
          dnd?.addItem(item.key)
        },
        items: propertiesKeys,
      })
    }, [propertiesKeys.length, property])

    const actions = () => {
      return (
        <Actions
          rows={[
            {
              columns: [
                action,
                dnd && (
                  <AddPropertyAction
                    store={newPropertiesDropdownStore}
                    key={'add_property_action'}
                    objectType={'contact'}
                  />
                ),
              ],
            },
            {
              columns: [
                !showMoreActionStore.hideShowMore && (
                  <ShowMoreAction
                    key={'show_more'}
                    disabled={!contact}
                    store={showMoreActionStore}
                  />
                ),
              ],
            },
          ]}
        />
      )
    }

    const properties = showMoreActionStore.modifyData(property?.properties || [])

    return (
      <SectionTab
        item={property}
        titleView={'View contact'}
        titleEmpty={'No properties found'}
        integrationLabel={integrationLabel}
        properties={properties}
        link={link}
        actions={actions()}
        handleClear={(id) => {
          property?.removeProperty(id)
          dnd?.removeItem(id)
        }}
        handleReorder={(result) => {
          dnd?.updateItems(result.source.index, result.destination?.index)
        }}
        itemsDnD={dnd?.itemsUI}
      />
    )
  }
)
