import { IColumn } from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { IAdminTollFreeData } from 'entities/Admin/compliance'
import { TollFreeStatus } from 'entities/Compliance'
import { SubscriptionStatus } from 'entities/Subscription'

export const columns: IColumn<IAdminTollFreeData>[] = [
  {
    field: 'orgId',
    name: 'Org. id',
    fullName: 'Organization ID',
    renderRowCell: (row) => row.organization?.id,
    isDisabledChangeVisible: true,
    maxWidth: 85,
  },
  {
    field: 'name',
    name: 'Org. name',
    fullName: 'Organization name',
    renderRowCell: (row) => row.organization?.name,
    maxWidth: 260,
  },
  {
    field: 'subscription_status',
    name: 'Sub. status',
    fullName: 'Subscription status',
    renderRowCell: (row) => <SubscriptionStatus status={row.organization.subscription_status} />,
  },
  {
    field: 'email',
    name: 'Owner email',
    renderRowCell: (row) => row.owner?.email,
    maxWidth: 260,
  },
  {
    field: 'number',
    name: 'Phone number',
    renderRowCell: (row) => row.number?.formatted_number,
  },
  {
    field: 'use_case',
    name: 'Toll free campaign',
    renderRowCell: (row) => row.profile?.use_case?.title,
  },
  {
    field: 'status',
    name: 'Number status',
    renderRowCell: (row) => row.number && <TollFreeStatus status={row.number.verified_status} />,
  },
  {
    field: 'submit_date',
    name: 'Submit date',
    renderRowCell: (row) =>
      row.number?.submitted_at &&
      uiStore.dayjs(row.number?.submitted_at).format(DayjsFormats.slash),
  },
  {
    field: 'updated_at',
    name: 'Last update on',
    renderRowCell: (row) =>
      row.number?.updated_at && uiStore.dayjs(row.number?.updated_at).format(DayjsFormats.slash),
  },
]
