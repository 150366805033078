import { Base } from 'models'
import type {
  IResponseSalesforceProperty,
  IOpportunity,
  IResponseSalesforceOpportunity,
  IResponseIntegrationSalesforceData,
} from 'entities/Integrations'

export class ContactIntegrationSalesforceInfo extends Base {
  contactId: number
  properties: IResponseSalesforceProperty | null
  opportunities: IOpportunity[]
  objectType: 'contact' | 'lead' | null

  constructor(item: IResponseIntegrationSalesforceData) {
    super()

    this.contactId = item.contactId
    this.properties = item.properties || null
    this.objectType = item.properties?.object_type || null
    this.opportunities = item.opportunities?.map((item: IResponseSalesforceOpportunity) => {
      return {
        id: item.id,
        title: item.name,
        stage: item.stage,
        amount: item.value,
        owner: null,
      }
    })
  }

  get isLead() {
    return this.objectType === 'lead'
  }
}
