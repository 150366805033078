import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import classNames from 'classnames'
import { Typography, Error, Button } from 'shared/ui'
import { makeTextFieldAutoFocus, makeInputPattern, TextField } from 'shared/ui/TextField'
import { type RegisterStore } from 'widgets/Register/store/registerStore'

import styles from './styles.module.scss'

const InputCode = makeInputPattern({
  format: '######',
  placeholder: '000000',
})

type IProps = {
  store: RegisterStore
}

export const MaxMindModalContent = observer(({ store }: IProps) => {
  const {
    loading,
    handleSignUp,
    step4Store: { isValidSmsCode, setSmsCode, smsCode, smsCodeError, number, clearMaxMindError },
  } = store

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isValidSmsCode) {
      event.preventDefault()
      handleSignUp()
    }
  }

  const resendMaxMindCode = () => {
    setSmsCode('')
    clearMaxMindError()
    handleSignUp()
  }

  return (
    <div className={styles.wrap}>
      <Typography
        color={'var(--content-primary-primary)'}
        variant={'body-md-medium'}
        ariaLabel={'twoFaCodeModalTitle'}
      >
        Enter the 6-digit code
      </Typography>
      <Typography
        color={'var(--content-primary-tertiary)'}
        variant={'body-md-regular'}
        ariaLabel={'twoFaCodeModalLabel'}
      >
        {`We’ve sent it to ${number}`}
      </Typography>
      <TextField
        className={classNames(styles.textField, {
          [styles.error]: !!smsCodeError,
        })}
        Input={InputCode}
        InputProps={{ onKeyDown }}
        error={!!smsCodeError}
        variant='stroke'
        size='medium'
        value={smsCode}
        disabled={loading}
        onChange={setSmsCode}
        mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
      />
      <Error type={'text'} error={smsCodeError} />
      <div className={styles.wrapResend}>
        <Typography
          color={'var(--content-primary-tertiary)'}
          variant={'body-md-regular'}
          ariaLabel={'resendTwoFaCode'}
        >
          Haven’t received it?
        </Typography>
        <Button
          paddingInline={4}
          typeBtn='link'
          type='button'
          onClick={resendMaxMindCode}
          disabled={loading}
          text={'Resend new code'}
        />
      </div>
    </div>
  )
})
