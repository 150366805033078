import { observer } from 'mobx-react-lite'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { checkCDN } from 'shared/lib/checkCDN'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import styles from './styles.module.scss'

const X_PADDING = 152
const Y_PADDING = 20
const Y_OFFSET = 114

export const PresentationImage = observer(() => {
  const ref = useRef<HTMLImageElement>(null)
  const [isLoaded, setLoaded] = useState(false)

  const { imageControllerStore, currentAttachment, setDisabledOverlayClick } = presentationStore
  const { scale, setScale } = imageControllerStore

  const onMouseEnter = () => {
    setDisabledOverlayClick(true)
  }
  const onMouseLeave = () => {
    setDisabledOverlayClick(false)
  }
  const onLoad = () => setLoaded(true)

  useEffect(() => {
    if (ref.current && isLoaded) {
      const imgWidth = ref.current.width
      const imgHeight = ref.current.height

      const documentWidth = document.documentElement.offsetWidth
      const documentHeight = document.documentElement.offsetHeight

      const documentMaxWidth = documentWidth - X_PADDING * 2
      const documentMaxHeight = documentHeight - (Y_OFFSET + Y_PADDING * 2)

      const scaleX = documentMaxWidth / imgWidth
      const scaleY = documentMaxHeight / imgHeight
      const scaleMin = Math.min(scaleX, scaleY)

      if (scaleMin < 1) setScale(scaleMin)
    }
  }, [isLoaded])

  useLayoutEffect(() => setLoaded(false), [currentAttachment])

  const url = currentAttachment?.source || currentAttachment?.source_short || ''

  return (
    <img
      ref={ref}
      className={classNames(styles.img, {
        [styles.ready]: isLoaded,
      })}
      src={url}
      alt=''
      onLoad={onLoad}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ transform: `translate(-50%, calc(-50% - ${Y_OFFSET / 2}px)) scale(${scale})` }}
      {...(checkCDN(url) ? { crossOrigin: 'use-credentials' } : null)}
    />
  )
})
