import { makeAutoObservable } from 'mobx'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { nanoid } from 'nanoid'
import { PhoneInputStore } from 'shared/ui'
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
  MAX_MIND_SMS_CODE_LENGTH,
} from 'shared/constants/auth'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { IRegisterCookies } from 'entities/Auth/store/types'
import { type RegisterStore } from 'widgets/Register/store/registerStore'
import { FieldsEnum } from './type'
import { MaxMindModalContent } from '../ui/MaxMindModal/MaxMindModalContent'
import { MaxMindModalActions } from '../ui/MaxMindModal/MaxMindModalActions'

export class Step4Store {
  private _loading = false
  private _firstName = ''
  private _lastName = ''

  private _cardComplete = false
  private _cardError: string | null = null
  private _activeField: FieldsEnum | null = null
  private _showRetryAlert = false

  phoneInputStore = new PhoneInputStore()
  // MaxMind number verification
  private _smsCode = ''
  private _smsCodeError = ''
  private _maxMindModalId = ''
  private _maxMindModalTrigger = ''

  constructor() {
    makeAutoObservable(this)
  }

  get number() {
    return this.phoneInputStore.number
  }

  get payload() {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      number: this.number,
      country: this.phoneInputStore.country.id as string,
      smsCode: this._smsCode,
    }
  }

  initCookies = (data: IRegisterCookies) => {
    if (data.first_name) this._firstName = data.first_name
    if (data.last_name) this._lastName = data.last_name
    if (data.number) this.phoneInputStore.setNumber(data.number)
    if (data.country) {
      const country = this.phoneInputStore.countries.find((item) => item.id === data.country)
      if (country) this.phoneInputStore.setCountry(country)
    }
  }

  get showRetryAlert() {
    return this._showRetryAlert
  }

  get maxMindModalTrigger() {
    return this._maxMindModalTrigger
  }

  get smsCode() {
    return this._smsCode
  }

  get smsCodeError() {
    return this._smsCodeError
  }

  get firstName() {
    return this._firstName
  }

  get firstNameLength() {
    return this.firstName.length
  }

  get isMaxFirstNameLength() {
    return this.firstNameLength === MAX_FIRST_NAME_LENGTH
  }

  get isMaxLastNameLength() {
    return this.lastNameLength === MAX_LAST_NAME_LENGTH
  }

  get loading() {
    return this._loading
  }

  get lastName() {
    return this._lastName
  }

  get lastNameLength() {
    return this.lastName.length
  }

  get isActiveLastNameField() {
    return this._activeField === FieldsEnum.lastName
  }

  get isActiveFirstNameField() {
    return this._activeField === FieldsEnum.firstName
  }

  get cardComplete() {
    return this._cardComplete
  }

  get cardError() {
    return this._cardError
  }

  get isValidSmsCode() {
    return (
      this._smsCode.replace(/[^-.0-9]/g, '').length === MAX_MIND_SMS_CODE_LENGTH &&
      !this.smsCodeError
    )
  }

  clearMaxMindError = () => {
    this._smsCodeError = ''
  }

  closeMaxMindModal = () => {
    modalStore.removeModal(this._maxMindModalId)
    this._maxMindModalId = ''
    this.clearMaxMindError()
    this._smsCode = ''
    this._maxMindModalTrigger = ''
  }

  triggerMaxMindModal = (error: boolean) => {
    if (error)
      this._smsCodeError = 'The verification code you entered doesn’t match the one we provided.'
    this._maxMindModalTrigger = nanoid()
  }

  openMaxMindModal = async (store: RegisterStore) => {
    if (this._maxMindModalId) modalStore.removeModal(this._maxMindModalId)
    this._maxMindModalId = nanoid()
    modalStore.addModal({
      id: this._maxMindModalId,
      type: ModalTypeList.DEFAULT,
      title: 'Verification required',
      onClose: this.closeMaxMindModal,
      paddingContent: '12px 24px 0px 24px',
      ModalContent: () => <MaxMindModalContent store={store} />,
      ModalActions: () => <MaxMindModalActions store={store} />,
      width: 360,
    })
  }

  clearSmsCode = () => {
    this._smsCode = ''
  }

  setSmsCode = (value: string) => {
    this.clearMaxMindError()
    this._smsCode = value
  }

  setShowRetryError = (value: boolean) => {
    this._showRetryAlert = value
  }

  focusFirstNameField = () => {
    this.setActiveField(FieldsEnum.firstName)
  }

  focusLastNameField = () => {
    this.setActiveField(FieldsEnum.lastName)
  }

  blurFirstNameField = () => {
    this.blurActiveField(FieldsEnum.firstName)
  }

  blurLastNameField = () => {
    this.blurActiveField(FieldsEnum.lastName)
  }

  blurActiveField = (value: FieldsEnum) => {
    if (this._activeField !== value) return
    this._activeField = null
  }

  setActiveField = (value: FieldsEnum) => {
    this._activeField = value
  }

  clearCardError() {
    this._cardError = null
  }

  setCardError(value: string | null) {
    this._cardError = value
  }

  setFirstName = (value: string) => {
    if (value.length > MAX_FIRST_NAME_LENGTH) return
    this._firstName = value
  }

  setLastName = (value: string) => {
    if (value.length > MAX_LAST_NAME_LENGTH) return
    this._lastName = value
  }

  onChangeCard = (event: StripeCardElementChangeEvent) => {
    this._cardComplete = event.complete
    this._cardError = event.error?.message || null
  }

  get disabledSignUp() {
    return (
      !this._firstName ||
      !this._lastName ||
      !this.phoneInputStore.isValidNumber ||
      !this._cardComplete
    )
  }
}
