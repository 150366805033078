import { makeAutoObservable } from 'mobx'
import { type Segment } from 'entities/Segment/model/Segment'

export class SegmentBox {
  private _item: Segment | null = null
  private _hasChanges = false

  constructor() {
    makeAutoObservable(this)
  }

  get hasChanges() {
    return this._hasChanges
  }

  get segment() {
    return this._item
  }

  get hasSegment() {
    return this._item !== null
  }

  setHasChanges = (value: boolean) => {
    this._hasChanges = value
  }

  putSegment = (segment: Segment) => {
    this._item = segment
  }

  removeSegment = () => {
    this._item = null
  }
}
