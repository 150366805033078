// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b83KzRZtFmlVRDNMyFE2{overflow:auto;position:absolute;top:0;right:0;bottom:0;left:0;z-index:3000;background-color:rgba(20,22,26,.5)}.RtjuM8jEcbRraN0ZhAbC,.pUp0XYIre6QAEwsNf19g{position:fixed;top:50%;z-index:3002;transform:translateY(-50%)}.RtjuM8jEcbRraN0ZhAbC{left:32px}.pUp0XYIre6QAEwsNf19g{right:32px}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,YAAA,CACA,kCAAA,CAEF,4CACE,cAAA,CACA,OAAA,CACA,YAAA,CACA,0BAAA,CAEF,sBACE,SAAA,CAEF,sBACE,UAAA","sourcesContent":[".wrap{\n  overflow: auto;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 3000;\n  background-color: rgba(#14161A, 0.5);\n}\n.leftArrow, .rightArrow{\n  position: fixed;\n  top: 50%;\n  z-index: 3002;\n  transform: translateY(-50%);\n}\n.leftArrow{\n  left: 32px;\n}\n.rightArrow{\n  right: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "b83KzRZtFmlVRDNMyFE2",
	"leftArrow": "RtjuM8jEcbRraN0ZhAbC",
	"rightArrow": "pUp0XYIre6QAEwsNf19g"
};
export default ___CSS_LOADER_EXPORT___;
