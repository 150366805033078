import { type DraggableProvided } from 'react-beautiful-dnd'
import { useState, useEffect, FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Typography, Icon } from 'shared/ui'
import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { type ContactsIntegrationsStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations'
import {
  ProviderContactsIntegrations,
  useContactsIntegrationsContext,
} from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import { IntegrationTitle } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/IntegrationTitle/IntegrationTitle'
import { HubspotIntegration } from './HubspotIntegration/HubspotIntegration'
import { ActiveCampaignIntegration } from './ActiveCampaignIntegration/ActiveCampaignIntegration'
import { PipedriveIntegration } from './PipedriveIntegration/PipedriveIntegration'
import { InfusionsoftIntegration } from './InfusionsoftIntegration/InfusionsoftIntegration'
import { UnlinkActions } from './UnlinkActions/UnlinkActions'
import styles from './styles.module.scss'
import { AllDeals, AllCompanies, AllOpportunities, AllTickets } from './AllView'
import { LinkContact } from './LinkContact'
import { SalesforceIntegration } from './SalesforceIntegration/SalesforceIntegration'

export type IContactsIntegrationsContentProps = {
  isDragging?: boolean
  createNewContactMode?: boolean
  hideDraggingIcon?: boolean
  dragHandleProps?: DraggableProvided['dragHandleProps']
}

const ContactsIntegrationsContent: FC<IContactsIntegrationsContentProps> = observer(
  ({ isDragging, createNewContactMode, hideDraggingIcon, dragHandleProps }) => {
    const store = useContactsIntegrationsContext()
    const [isOpenActions, setIsOpenActions] = useState(false)

    const onToggle = () => {
      if (createNewContactMode) return
      contactsDetailsGlobalStore.toggleBlockView('integration')
    }

    const user = usersStore.user
    const contact = store.selectedContact?.isNew
      ? store.selectedContact
      : contactsStore.getItem(store.selectedContact?.id)

    useEffect(() => {
      integrationsStore.fetchIntegrations()

      return () => {
        store.dispose()
      }
    }, [])

    const integration = integrationsStore.getIntegration(contact?.integration_key)
    const isIntegrationActive = Boolean(integration?.isConnected)

    const Integration = useMemo(() => {
      if (!contact) return null

      if (!isIntegrationActive && contact.integration_key === IntegrationKey.salesmsg) {
        return <LinkContact contact={contact} />
      }

      if (contact.integration_key === IntegrationKey.hubspot) {
        return <HubspotIntegration />
      }

      if (contact.integration_key === IntegrationKey.activecampaign) {
        return <ActiveCampaignIntegration />
      }

      if (contact.integration_key === IntegrationKey.pipedrive) {
        return <PipedriveIntegration />
      }

      if (contact.integration_key === IntegrationKey.infusionsoft) {
        return <InfusionsoftIntegration />
      }

      if (contact.integration_key === IntegrationKey.salesforce) {
        return <SalesforceIntegration />
      }

      return (
        <Typography variant={'body-md-regular'} ariaLabel={'name'}>
          {integration?.name}
        </Typography>
      )
    }, [])

    if (!contact) return null

    if (store.view === 'deals') {
      return <AllDeals />
    }

    if (store.view === 'companies') {
      return <AllCompanies />
    }

    if (store.view === 'opportunities') {
      return <AllOpportunities />
    }

    if (store.view === 'tickets') {
      return <AllTickets />
    }

    return (
      <div
        className={classnames(styles.wrap, {
          [styles.dragging]: isDragging,
          [styles.disabled]: createNewContactMode,
        })}
      >
        <div
          className={classnames(styles.header, {
            [styles.open]: createNewContactMode
              ? false
              : contactsDetailsGlobalStore.isOpenBlock('integration'),
          })}
          onClick={onToggle}
        >
          <IntegrationTitle
            integration={integration}
            hideDraggingIcon={hideDraggingIcon}
            dragHandleProps={dragHandleProps}
            isIntegrationActive={isIntegrationActive}
            contact={contact}
            createNewContactMode={createNewContactMode}
          />
          <div className={styles.actions}>
            {!!integration && isIntegrationActive && !user?.isViewOnlyRole && (
              <div
                className={classnames(styles.actionsIcon, {
                  [styles.open]: isOpenActions,
                })}
              >
                <UnlinkActions contact={contact} setStatus={setIsOpenActions} />
              </div>
            )}
            <div className={styles.toggleIcon}>
              <Icon icon='chevronDown' />
            </div>
          </div>
        </div>
        {contactsDetailsGlobalStore.isOpenBlock('integration') && (
          <div className={classnames(styles.body, styles.open)}>{Integration}</div>
        )}
      </div>
    )
  }
)

export type IContactsIntegrationsProps = {
  store: ContactsIntegrationsStore
  isDragging?: boolean
  createNewContactMode?: boolean
  hideDraggingIcon?: boolean
  dragHandleProps?: DraggableProvided['dragHandleProps']
}

export const ContactsIntegrations: FC<IContactsIntegrationsProps> = ({
  store,
  isDragging,
  createNewContactMode,
  hideDraggingIcon,
  dragHandleProps,
}) => {
  return (
    <ProviderContactsIntegrations value={store}>
      <ContactsIntegrationsContent
        createNewContactMode={createNewContactMode}
        isDragging={isDragging}
        hideDraggingIcon={hideDraggingIcon}
        dragHandleProps={dragHandleProps}
      />
    </ProviderContactsIntegrations>
  )
}
