import { observer } from 'mobx-react-lite'
import { Icon } from 'shared/ui'
import { TextField } from 'shared/ui/TextField'
import { useKnowledgeBaseListContext } from 'pages/chatbot/context/knowledgeBaseListContext'
import styles from './styles.module.scss'

export const KnowledgeBaseListTableHeader = observer(() => {
  const { search, onSearchChange } = useKnowledgeBaseListContext()

  return (
    <header className={styles.header}>
      <Icon icon='search' color='secondary' fontSize={20} padding={6} tertiary />
      <TextField
        className={styles.headerSearch}
        variant='integrated'
        InputProps={{
          placeholder: 'Search...',
        }}
        onChange={onSearchChange}
        value={search}
      />
    </header>
  )
})
