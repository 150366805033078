import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  DropdownContent,
  DropdownGroup,
  dropDownStore,
  DropdownTextInput,
  IDropdownItem,
  Scrollbar,
  SpinnerLoader,
} from 'shared/ui'
import { IDropdownItemSendFrom } from 'widgets/SendFromDropdown/store/types'
import { useSendFromDropdownContext } from '../context/sendFromDropdownContext'
import styles from './styles.module.scss'

type ISendFromContentProps = {
  dropdownId: string
}

export const SendFromContent: FC<ISendFromContentProps> = observer(({ dropdownId }) => {
  const store = useSendFromDropdownContext()

  useEffect(() => {
    store.setDropdownId(dropdownId)

    return () => {
      store.setSearch('')
    }
  }, [])

  if (store.loading) {
    return (
      <DropdownContent>
        <div className={styles.loader}>
          <SpinnerLoader size={20} />
        </div>
      </DropdownContent>
    )
  }

  return (
    <DropdownContent noPadding>
      <DropdownTextInput
        textFieldProps={{
          value: store.search,
          onChange: store.setSearch,
          InputProps: {
            placeholder: 'Search',
          },
        }}
      />

      <Scrollbar
        autoHeight
        autoHeightMin={0}
        autoHeightMax={335}
        onScroll={() => {
          dropDownStore.allHide(dropdownId)
        }}
      >
        {store.itemsGroups.map((group) => {
          return (
            <DropdownGroup
              key={group.key}
              title={group.title}
              items={group.items}
              onChange={(item, parentItem) => {
                store.setItem({
                  item: item as IDropdownItemSendFrom,
                  parentItem: parentItem as IDropdownItem,
                  key: group.key,
                })
              }}
              isScroll={false}
            />
          )
        })}

        {!store.itemsGroups.length && <div className={styles.noResult}>No results found</div>}
      </Scrollbar>
    </DropdownContent>
  )
})
