import { useMemo, FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { ContainerHeader, Space } from 'shared/ui'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { type ContactsDetailsStore } from 'widgets/ContactsDetails'
import { ContactsContainerHeader } from 'widgets/ContactsDetails/ui/ContactsContainerHeader/ContactsContainerHeader'
import { ContactsProfileCard } from 'widgets/ContactsDetails/ui/ContactsProfileCard'
import { ContactsGroupCard } from 'widgets/ContactsDetails/ui/ContactsGroupCard/ui/ContactsGroupCard'
import { ContactsNotesForm } from 'widgets/ContactsDetails/ui/ContactsNotesForm'
import { ContactsIntegrations } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/ContactsIntegrations'
import { ContactsQuickLinksSearch } from 'widgets/ContactsDetails/ui/ContactsQuickLinks'
import { ContactsConversationsHistory } from 'widgets/ContactsDetails/ui/ContactsConversationsHistory/ContactsConversationsHistory'
import styles from 'widgets/ContactsDetails/ui/styles.module.scss'
import { ContactsContentItems } from 'widgets/ContactsDetails/ui/ContactsContentItems/ContactsContentItems'
import { ContactsConversationOpen } from 'widgets/ContactsDetails/ui/ContactsConversationOpen'
import {
  useContactsDetailsContext,
  ContactsDetailsContext,
} from 'widgets/ContactsDetails/context/contactsDetailsContext'

const ContactsDetailsContent = observer(() => {
  const { conversationId } = useParams()
  const contactsDetailsStore = useContactsDetailsContext()
  const {
    isConversationGroup,
    isViewAll,
    selectedContact,
    isViewTypeNotes,
    isViewTypeConversationsHistory,
    isViewTypeQuickLinks,
    contactNoteStore,
    setConversationId,
    isVariantCreateContactModal,
    contactInfoFormStore,
    contactsProfileCardStore,
    contactsQuickLinksStore,
    contactsGroupCardStore,
    contactIntegrationsStore,
    config,
    hasAlert,
  } = contactsDetailsStore
  const { isCollapse, isShowContactDetailsOverlay, handleToggleCollapse } =
    contactsDetailsGlobalStore

  useEffect(() => {
    return () => {
      const id = Number(conversationId)

      if (!isNaN(id)) {
        setConversationId(id)
      }
    }
  }, [conversationId])

  useEffect(() => {
    contactsDetailsStore.load()

    return () => {
      contactInfoFormStore.reset()
    }
  }, [])

  const contact = selectedContact

  const handleBackNote = () => {
    contactNoteStore.messageFieldStore.clear()
  }

  const createNewContactMode = contact?.id === -1

  const renderHeader = () => {
    if (createNewContactMode && !isVariantCreateContactModal) {
      return (
        <ContainerHeader
          title='Create'
          isBorderBottom
          paddingLeft={20}
          actions={[
            {
              icon: 'close',
              text: 'Cancel',
              onAction: () => {
                config?.onStopCreateNewContact?.()
                handleToggleCollapse()
              },
            },
          ]}
        />
      )
    }

    if (isViewTypeNotes) {
      return <ContactsContainerHeader backCallback={handleBackNote} />
    }

    if (contact?.id === 0 || isViewTypeConversationsHistory) {
      return <ContactsContainerHeader />
    }

    if (isViewAll) {
      return (
        <ContactsContainerHeader
          titleCount={contactIntegrationsStore.viewAllCount}
          customTitleIcon={contactIntegrationsStore.customTitleIcon}
          showCount
        />
      )
    }

    if (isVariantCreateContactModal) return null

    return (
      <ContactsContainerHeader
        backCallback={() => {
          config?.onCloseContactDetails?.()
        }}
      />
    )
  }
  const renderContent = () => {
    if (contact?.id === 0) {
      return (
        <>
          <ContactsProfileCard store={contactsProfileCardStore} />
          {isConversationGroup && <ContactsGroupCard store={contactsGroupCardStore} />}
          <Space height={60} />
        </>
      )
    }

    if (isViewTypeNotes) {
      return (
        <>
          <ContactsNotesForm fullView store={contactNoteStore} />
          <Space height={60} />
        </>
      )
    }

    if (isViewAll) {
      return (
        <>
          <ContactsIntegrations store={contactIntegrationsStore} />
          <Space height={60} />
        </>
      )
    }

    if (isViewTypeQuickLinks) {
      return <ContactsQuickLinksSearch store={contactsQuickLinksStore} />
    }

    if (isViewTypeConversationsHistory) {
      return <ContactsConversationsHistory />
    }

    return (
      <div className={styles.infoWrapper}>
        <ContactsProfileCard store={contactsProfileCardStore} />
        <ContactsContentItems />
        {!isVariantCreateContactModal && <Space height={60} />}
      </div>
    )
  }

  const renderLeftContent = () => {
    if (contactsDetailsStore.isVariantOpenConversations) {
      return <ContactsConversationOpen store={contactsDetailsStore.contactsConversationOpenStore} />
    }

    return null
  }
  const isMobileOpened = isShowContactDetailsOverlay && !isVariantCreateContactModal
  const isCollapseOpened = isCollapse && !isVariantCreateContactModal

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.isCollapse]: isCollapseOpened,
        [styles.isMobileOpened]: isMobileOpened,
        [styles.isConversationsHistoryView]: isViewTypeConversationsHistory,
        [styles.modalView]: isVariantCreateContactModal,
        [styles.hideModalViewBorderTop]: hasAlert,
      })}
    >
      <div className={styles.header}>{renderHeader()}</div>
      <div className={styles.content}>{renderContent()}</div>
      <div className={styles.leftContent}>{renderLeftContent()}</div>
    </div>
  )
})

type IContactsDetailsProps = {
  store: ContactsDetailsStore
}

export const ContactsDetails: FC<IContactsDetailsProps> = observer(({ store }) => {
  return useMemo(() => {
    return (
      <ContactsDetailsContext.Provider value={store}>
        <ContactsDetailsContent key={store.id} />
      </ContactsDetailsContext.Provider>
    )
  }, [store.contactsIds])
})
