// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W8wvqGaSnwf5G8HEnclg{margin:0 8px 0 17px;display:flex;align-items:center;gap:20px;min-height:32px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationList/ui/SelectAction/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".wrap {\n  margin: 0 8px 0 17px;\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  min-height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "W8wvqGaSnwf5G8HEnclg"
};
export default ___CSS_LOADER_EXPORT___;
