import { observer } from 'mobx-react-lite'
import { FilterEditorProvider } from 'widgets/FilterEditor/context'
import type { FilterEditorStore } from 'widgets/FilterEditor/store'
import { FilterGroups } from './ui'

export interface IFilterEditorProps {
  store: FilterEditorStore
  parentsDropdownId?: string[]
}

export const FilterEditor = observer(({ store, parentsDropdownId }: IFilterEditorProps) => (
  <FilterEditorProvider store={store}>
    <FilterGroups parentsDropdownId={parentsDropdownId} />
  </FilterEditorProvider>
))
