import { observer } from 'mobx-react-lite'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'

export const TollFreeTab = observer(() => {
  const data: IAdminDetailCardRowProps[] = [
    {
      title: 'TF',
      value: 'no data',
    },
  ]
  return <AdminDetailCard data={data} noHeader />
})
