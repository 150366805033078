// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EdOpXgwUv7fsT9BGCHrU{display:flex;margin:16px 20px;background:var(--border-neutral-default);height:1px;width:calc(100% - 40px)}.Q0Bi85TIw24nAmmaJicE{display:flex;align-items:center;gap:10px;padding-left:20px;padding-top:8px;padding-bottom:12px}.Q0Bi85TIw24nAmmaJicE input{font-size:16px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastDetail/BroadcastDetailOneTime/ui/BroadcastContactStatisticsSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gBAAA,CACA,wCAAA,CACA,UAAA,CACA,uBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,eAAA,CACA,mBAAA,CACA,4BACE,cAAA","sourcesContent":[".separator{\n  display: flex;\n  margin: 16px 20px;\n  background: var(--border-neutral-default);\n  height: 1px;\n  width: calc(100% - 40px);\n}\n\n.search{\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding-left: 20px;\n  padding-top: 8px;\n  padding-bottom: 12px;\n  input{\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": "EdOpXgwUv7fsT9BGCHrU",
	"search": "Q0Bi85TIw24nAmmaJicE"
};
export default ___CSS_LOADER_EXPORT___;
