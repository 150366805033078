import { observer } from 'mobx-react-lite'
import { Button, IconButton, Typography } from 'shared/ui'
import { getHref, isLink } from 'shared/lib'
import { InputPhone } from 'shared/ui/TextField'
import { complianceStore } from 'entities/Compliance/store/complianceStore'
import { TenDLCStatus } from 'entities/Compliance/ui/TenDLCStatus'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { adminTenDLCBusinessProfileStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BusinessProfile/store/adminTenDLCBusinessProfileStore'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import { getRejectReasonRow } from 'pages/admin/pages/compliance'
import styles from './styles.module.scss'

export const BusinessProfile = observer(() => {
  const { setTenDLCData } = adminTenDLCDetailStore

  const {
    status,
    isForEdit,
    isSaveLoading,
    getError,
    rejectReason,
    isRejected,

    vendor_id,
    edit_vendor_id,
    is_edit_vendor_id,
    set_edit_vendor_id,

    business_name,
    edit_business_name,
    is_edit_business_name,
    set_edit_business_name,

    business_industry,
    edit_business_industry,
    is_edit_business_industry,
    set_edit_business_industry,

    ein,
    edit_ein,
    is_edit_ein,
    set_edit_ein,

    first_name,
    edit_first_name,
    is_edit_first_name,
    set_edit_first_name,

    last_name,
    edit_last_name,
    is_edit_last_name,
    set_edit_last_name,

    phone_number,
    edit_phone_number,
    is_edit_phone_number,
    set_edit_phone_number,

    email,
    edit_email,
    is_edit_email,
    set_edit_email,

    street_address_1,
    edit_street_address_1,
    is_edit_street_address_1,
    set_edit_street_address_1,

    street_address_2,
    edit_street_address_2,
    is_edit_street_address_2,
    set_edit_street_address_2,

    city,
    edit_city,
    is_edit_city,
    set_edit_city,

    state,
    edit_state,
    is_edit_state,
    set_edit_state,

    postal_code,
    edit_postal_code,
    is_edit_postal_code,
    set_edit_postal_code,

    country,
    edit_country,
    is_edit_country,
    set_edit_country,

    business_type,
    edit_business_type,
    is_edit_business_type,
    set_edit_business_type,

    business_region,
    edit_business_region,
    is_edit_business_region,
    set_edit_edit_business_region,

    business_title,
    edit_business_title,
    is_edit_business_title,
    set_edit_business_title,

    job_position,
    edit_job_position,
    is_edit_job_position,
    set_edit_job_position,

    website_url,
    edit_website_url,
    is_edit_website_url,
    set_edit_website_url,

    social_media_url,
    edit_social_media_url,
    is_edit_social_media_url,
    set_edit_social_media_url,

    onEdit,
    isEdit,
    onSave,
    onClose,
  } = adminTenDLCBusinessProfileStore

  const data: IAdminDetailCardRowProps[] = [
    {
      typeEdit: 'input',
      title: 'Twilio id',
      value: vendor_id,
      editValue: edit_vendor_id,
      isUnsaved: is_edit_vendor_id,
      onChange: set_edit_vendor_id,
      editError: getError('vendor_id'),
    },
    ...getRejectReasonRow(isRejected, rejectReason),
    {
      title: 'Business name',
      value: business_name,
      editValue: edit_business_name,
      isUnsaved: is_edit_business_name,
      onChange: set_edit_business_name,
      editError: getError('business_name'),
    },
    {
      title: 'Business industry',
      value: business_industry,
      editValue: edit_business_industry,
      isUnsaved: is_edit_business_industry,
      onChange: set_edit_business_industry,
      typeEdit: 'select',
      options: complianceStore.businessProfileIndustries,
      loadOptions: complianceStore.loadBusinessProfileIndustries,
      editError: getError('business_industry_id'),
    },
    {
      title: 'Business EIN',
      value: ein,
      editValue: edit_ein,
      isUnsaved: is_edit_ein,
      onChange: set_edit_ein,
      editError: getError('ein'),
    },
    {
      title: 'First name',
      value: first_name,
      editValue: edit_first_name,
      isUnsaved: is_edit_first_name,
      onChange: set_edit_first_name,
      editError: getError('contact_person.first_name'),
    },
    {
      title: 'Last name',
      value: last_name,
      editValue: edit_last_name,
      isUnsaved: is_edit_last_name,
      onChange: set_edit_last_name,
      editError: getError('contact_person.last_name'),
    },
    {
      Input: InputPhone,
      title: 'Phone number',
      value: phone_number,
      editValue: edit_phone_number,
      isUnsaved: is_edit_phone_number,
      onChange: set_edit_phone_number,
      editError: getError('contact_person.phone_number'),
    },
    {
      title: 'Email',
      value: email,
      editValue: edit_email,
      isUnsaved: is_edit_email,
      onChange: set_edit_email,
      editError: getError('contact_person.email'),
    },
    {
      title: 'Street address 1',
      value: street_address_1,
      editValue: edit_street_address_1,
      isUnsaved: is_edit_street_address_1,
      onChange: set_edit_street_address_1,
      editError: getError('street_address_1'),
    },
    {
      title: 'Street address 2 (optional)',
      value: street_address_2,
      editValue: edit_street_address_2,
      isUnsaved: is_edit_street_address_2,
      onChange: set_edit_street_address_2,
      editError: getError('street_address_2'),
    },
    {
      title: 'City',
      value: city,
      editValue: edit_city,
      isUnsaved: is_edit_city,
      onChange: set_edit_city,
      editError: getError('city'),
    },
    {
      title: 'State',
      value: state,
      editValue: edit_state,
      isUnsaved: is_edit_state,
      onChange: set_edit_state,
      typeEdit: 'select',
      options: complianceStore.getStates('US'),
      loadOptions: complianceStore.loadBusinessProfileStates,
      editError: getError('state_id'),
    },
    {
      title: 'Postal code',
      value: postal_code,
      editValue: edit_postal_code,
      isUnsaved: is_edit_postal_code,
      onChange: set_edit_postal_code,
      editError: getError('postal_code'),
    },
    {
      title: 'Country',
      value: country && complianceStore.getCountryLabel(country),
      editValue: edit_country,
      isUnsaved: is_edit_country,
      onChange: set_edit_country,
      typeEdit: 'selectValue',
      options: complianceStore.complianceCountries,
      editError: getError('country'),
    },
    {
      title: 'Business type',
      value: business_type,
      editValue: edit_business_type,
      isUnsaved: is_edit_business_type,
      onChange: set_edit_business_type,
      typeEdit: 'select',
      options: complianceStore.businessProfileBusinessTypes,
      loadOptions: complianceStore.loadBusinessProfileBusinessTypes,
      editError: getError('business_registration_type_id'),
    },
    {
      title: 'Business region',
      value: business_region,
      editValue: edit_business_region,
      isUnsaved: is_edit_business_region,
      onChange: set_edit_edit_business_region,
      typeEdit: 'multiSelect',
      options: complianceStore.businessProfileRegionsOfOperation,
      loadOptions: complianceStore.loadBusinessProfileRegionsOfOperation,
      editError: getError('business_region_ids'),
    },
    {
      title: 'Job title',
      value: business_title,
      editValue: edit_business_title,
      isUnsaved: is_edit_business_title,
      onChange: set_edit_business_title,
      editError: getError('contact_person.business_title'),
    },
    {
      title: 'Job position',
      value: job_position,
      editValue: edit_job_position,
      isUnsaved: is_edit_job_position,
      onChange: set_edit_job_position,
      typeEdit: 'select',
      options: complianceStore.businessProfileJobPositions,
      loadOptions: complianceStore.loadBusinessProfileJobPositions,
      editError: getError('contact_person.job_position_id'),
    },
    {
      title: 'Website URL',
      value: website_url,
      editValue: edit_website_url,
      isUnsaved: is_edit_website_url,
      onChange: set_edit_website_url,
      editError: getError('website_url'),
      renderValue: () => {
        return (
          website_url && (
            <div className={styles.content}>
              <Typography
                variant={'body-md-regular'}
                link
                textDecoration={'none'}
                pointer
                ariaLabel={'website_url'}
              >
                <a href={getHref(website_url)} target={'_blank'} rel='noreferrer'>
                  {website_url}
                </a>
              </Typography>
              {isLink(getHref(website_url)) && (
                <IconButton
                  className={styles.icon}
                  iconProps={{ fontSize: 14 }}
                  variant={'icon'}
                  color={'transparent'}
                  size={'small'}
                  tooltipProps={{ label: 'Invalid Email' }}
                  icon={'alertTriangleYellow'}
                  ariaLabel='Invalid_email'
                />
              )}
            </div>
          )
        )
      },
    },
    {
      title: 'Social media URL (optional)',
      value: social_media_url,
      editValue: edit_social_media_url,
      isUnsaved: is_edit_social_media_url,
      onChange: set_edit_social_media_url,
      editError: getError('social_media_url'),
      renderValue: () =>
        social_media_url && (
          <Typography
            variant={'body-md-regular'}
            link
            textDecoration={'none'}
            pointer
            ariaLabel={'social_media_url'}
          >
            <a href={getHref(social_media_url)} target={'_blank'} rel='noreferrer'>
              {social_media_url}
            </a>
          </Typography>
        ),
    },
  ]

  const handleSave = async () => {
    try {
      const res = await onSave()
      if (res?.data) {
        setTenDLCData(res?.data)
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <AdminDetailCard
      title={'Business profile'}
      data={data}
      additionalTitleContent={<TenDLCStatus status={status} />}
      isEdit={isEdit}
      actions={
        isForEdit &&
        (!isEdit ? (
          <Button
            text={'Edit'}
            contained={'secondary'}
            size={'medium'}
            icon={'edit'}
            onClick={onEdit}
          />
        ) : (
          <>
            <Button text={'Cancel'} contained={'secondary'} size={'medium'} onClick={onClose} />
            <Button
              text={'Save'}
              contained={'primary'}
              size={'medium'}
              onClick={handleSave}
              disabled={isSaveLoading}
            />
          </>
        ))
      }
    />
  )
})
