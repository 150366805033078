// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OZnnOa1H3xjwzSHkEaVF{display:flex;gap:8px;padding:24px 0}.MPfkdzN5oRZ9yR7h2ug9{flex:1;align-items:center}.MPfkdzN5oRZ9yR7h2ug9>input{font-size:24px;font-style:normal;font-weight:500;line-height:133.3%;letter-spacing:-0.24px;height:auto}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotEdit/ChatbotHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,OAAA,CACA,cAAA,CAGJ,sBACI,MAAA,CACA,kBAAA,CAEA,4BACI,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":[".header {\n    display: flex;\n    gap: 8px;\n    padding: 24px 0;\n}\n\n.textField {\n    flex: 1;\n    align-items: center;\n\n    & > input {\n        font-size: 24px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 133.3%;\n        letter-spacing: -0.24px;\n        height: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OZnnOa1H3xjwzSHkEaVF",
	"textField": "MPfkdzN5oRZ9yR7h2ug9"
};
export default ___CSS_LOADER_EXPORT___;
