import classnames from 'classnames'
import { useState, forwardRef, useRef } from 'react'
import { IconButton, Tooltip } from 'shared/ui'
import styles from './styles.module.scss'

export type IListItemContentProps = {
  icon: JSX.Element
  name: string
  count?: number | null
}

export const ListItemContent = forwardRef<HTMLSpanElement, IListItemContentProps>(
  ({ icon, name, count }, ref) => (
    <div className={styles.listItemContent}>
      <span className={styles.listItemIcon}>{icon}</span>
      <span ref={ref} className={styles.listItemName}>
        {name}
      </span>
      <span className={styles.listItemCount}>{count}</span>
    </div>
  )
)
ListItemContent.displayName = 'ListItemContent'

export type IListItemProps = IListItemContentProps & {
  active: boolean
  type: 'filters' | 'tags' | 'segments' | 'contacts'
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onRemove?: () => void
  isViewOnly?: boolean
}

export const ListItem = ({
  icon,
  type,
  name,
  count,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onRemove,
  isViewOnly,
  active,
}: IListItemProps) => {
  const textRef = useRef<HTMLSpanElement>(null)
  const [hoverDropdown, setHoverDropdown] = useState(false)
  const [hoverItem, setHoverItem] = useState(false)

  const mouseEnterItem = () => {
    if (textRef.current && textRef.current.scrollWidth - textRef.current.clientWidth) {
      setHoverItem(true)
    }
    onMouseEnter?.()
  }
  const mouseLeaveItem = () => {
    setHoverItem(false)
    onMouseLeave?.()
  }
  const mouseLeaveDropdown = () => {
    setHoverDropdown(false)
  }
  const mouseEnterDropdown = () => {
    setHoverDropdown(true)
  }

  return (
    <Tooltip
      label={name}
      fullWidth
      placement={'right'}
      visibility={hoverItem && !hoverDropdown ? 'visible' : 'hidden'}
    >
      <div
        className={classnames(styles.listItem, styles[`listItem__${type}`], {
          [styles.listItem__active]: active,
        })}
        onClick={onClick}
        onMouseEnter={mouseEnterItem}
        onMouseLeave={mouseLeaveItem}
      >
        <ListItemContent ref={textRef} icon={icon} name={name} count={count} />

        {!isViewOnly && (
          <div className={styles.listItemAction}>
            <IconButton
              icon={'close'}
              size={'small'}
              color={'secondary'}
              variant={'icon'}
              onClick={(event) => {
                event.stopPropagation()
                onRemove?.()
              }}
              onMouseEnter={mouseEnterDropdown}
              onMouseLeave={mouseLeaveDropdown}
              ariaLabel='ListItem_remove'
              tooltipProps={{
                label: 'Remove',
                placement: 'top',
              }}
            />
          </div>
        )}
      </div>
    </Tooltip>
  )
}

export const ListItemPreview = (props: IListItemContentProps) => (
  <div className={styles.listItemPreview}>
    <ListItemContent {...props} />
  </div>
)
