import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IParamsCreateMessage,
  IParamsDeleteMessagesDraft,
  IParamsMessagesByConversationId,
  IParamsMessagesContacts,
  IParamsMessagesGetContactsByIdTeamsById,
  IParamsUpdateMessage,
  IResponseConversationMessages,
  IResponseConversationMessagesPaginated,
  IResponseMessage,
} from 'entities/Message'

class Api {
  getMessagesByConversationId(
    params: IParamsMessagesByConversationId,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseConversationMessagesPaginated>> {
    return baseApi.get(`messages/${params.conversationId}/paginated`, {
      params: {
        per_page: params.per_page,
        page: params.page,
        with_activity_logs: params.with_activity_logs,
        only_scheduled: params.only_scheduled,
      },
      ...options,
    })
  }

  async getMessageMediaArchive(messageId: number): Promise<AxiosResponse<Blob>> {
    return baseApi.get(`messages/${messageId}/media/download`, {
      responseType: 'blob',
    })
  }

  getScheduledMessagesCountByConversationId({
    conversationId,
  }: {
    conversationId: number
  }): Promise<AxiosResponse<{ count: number }>> {
    return baseApi.get(`messages/${conversationId}/scheduled/count`)
  }

  getMessagesContacts(params: IParamsMessagesContacts): Promise<AxiosResponse<IResponseMessage[]>> {
    return baseApi.get('messages/contacts', { params })
  }

  getMessagesContactsByIdTeamsById(
    contactId: number,
    teamId: number | string,
    params: IParamsMessagesGetContactsByIdTeamsById,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseConversationMessages>> {
    return baseApi.get(`messages/contacts/${contactId}/teams/${teamId}`, { params, ...options })
  }

  async createMessage(
    conversationId: number,
    body: IParamsCreateMessage
  ): Promise<AxiosResponse<IResponseMessage>> {
    return baseApi.post(`messages/${conversationId}`, body)
  }

  async updateMessage(
    messageId: number,
    body: IParamsUpdateMessage
  ): Promise<AxiosResponse<IResponseMessage>> {
    return baseApi.put(`messages/${messageId}`, body)
  }

  retry(id: number | string): Promise<AxiosResponse<IResponseMessage>> {
    return baseApi.post(`messages/${id}/retry`)
  }

  retryMessagesByIdTranscript(id: number | string): Promise<AxiosResponse<{ success: boolean }>> {
    return baseApi.post(`messages/${id}/transcript/retry`)
  }

  manualMessagesByIdTranscript(id: number | string): Promise<AxiosResponse<IResponseMessage>> {
    return baseApi.post(`messages/${id}/transcript/manual`)
  }

  deleteMessage(id: number) {
    return baseApi.delete(`messages/${id}`)
  }

  deleteMessagesDraft(
    params: IParamsDeleteMessagesDraft
  ): Promise<AxiosResponse<{ status: string }>> {
    return baseApi.delete('messages/draft', {
      params: params,
    })
  }

  updateMessagesByIdMentionsByIdActivate(
    messageId: number,
    userId: number
  ): Promise<AxiosResponse<IResponseMessage>> {
    return baseApi.post(`messages/${messageId}/mentions/${userId}/activate`)
  }

  updateMessagesByIdMentionsByIdCancel(
    messageId: number,
    userId: number
  ): Promise<AxiosResponse<IResponseMessage>> {
    return baseApi.post(`messages/${messageId}/mentions/${userId}/cancel`)
  }

  updateMessagesByIdMentionsByIdGotIt(
    messageId: number,
    userId: number
  ): Promise<AxiosResponse<IResponseMessage>> {
    return baseApi.post(`messages/${messageId}/mentions/${userId}/got-it`)
  }
}

export const MessagesApi = new Api()
