// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yi4ID49Xrv8jLhunbsHF{display:flex;color:var(--content-neutral-primary)}.mK1pLOmkPCxs2Dwcx0dD{width:250px}.zLvi_bL2rILgH3NshzYg,.zqKALsoPzO6eLEIbq57w{line-height:15.99px}.zqKALsoPzO6eLEIbq57w{color:var(--content-primary-primary-inverted)}.hpWJvqqIOH88umds7sNx{margin-left:-3px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/BroadcastBadNumbers/ui/BroadcastBadNumberTooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oCAAA,CAGF,sBACE,WAAA,CAGF,4CACE,mBAAA,CAGF,sBACE,6CAAA,CAGF,sBACE,gBAAA","sourcesContent":[".info {\n  display: flex;\n  color: var(--content-neutral-primary);\n}\n\n.wrap {\n  width: 250px;\n}\n\n.label, .desc {\n  line-height: 15.99px;\n}\n\n.desc {\n  color: var(--content-primary-primary-inverted);\n}\n\n.tooltip {\n  margin-left: -3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "Yi4ID49Xrv8jLhunbsHF",
	"wrap": "mK1pLOmkPCxs2Dwcx0dD",
	"label": "zLvi_bL2rILgH3NshzYg",
	"desc": "zqKALsoPzO6eLEIbq57w",
	"tooltip": "hpWJvqqIOH88umds7sNx"
};
export default ___CSS_LOADER_EXPORT___;
