import { observer } from 'mobx-react-lite'
import { KeyboardEvent, useEffect, useRef, useState, MouseEventHandler, Fragment } from 'react'
import { Button, EnumSpinnerLoaderPosition, IconButton, SpinnerLoader, toastStore } from 'shared/ui'
import { downloadFile, validPhoneNumber } from 'shared/lib'
import { Label } from 'shared/ui/Label'
import { InputPhone, TextField, makeTextFieldClear, makeTextFieldError } from 'shared/ui/TextField'
import { isEmailMessage, ContactsApi } from 'entities/Contacts'
import { VCardCardUi } from 'entities/VCard/ui/VCard/VCardCardUi'
import vCardStore from 'entities/VCard/store/vCardStore'
import { VCard } from 'entities/VCard/model/VCard'
import styles from './styles.module.scss'

export const EditVCardContent = observer(() => {
  const vCard = vCardStore.modalVCard
  const [first_name, setFirstName] = useState(vCard?.first_name)
  const [last_name, setLastName] = useState(vCard?.last_name)

  const [phone, setPhone] = useState(vCard?.phone || '')
  const [isPhoneError, setPhoneError] = useState(false)

  const [email, setEmail] = useState(vCard?.email || '')
  const [isEmailError, setEmailError] = useState(false)

  const containerRef = useRef<HTMLDivElement | null>(null)

  const [loading, setLoading] = useState(false)
  const [checkLoadingEmail, setCheckLoadingEmail] = useState(false)

  const validatePhone = () => {
    const isInvalid = !phone || !validPhoneNumber(phone)

    setPhoneError(isInvalid)

    return isInvalid
  }
  const onChangePhone = (phone: string) => {
    setPhoneError(false)
    setPhone(phone)
  }
  const onKeydownPhone = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      validatePhone()
    }
  }

  const validateEmail = async () => {
    try {
      if (email) {
        setCheckLoadingEmail(true)
        await isEmailMessage(email)

        return false
      }
    } catch (e) {
      console.error(e)
      setEmailError(true)

      return true
    } finally {
      setCheckLoadingEmail(false)
    }
  }
  const onChangeEmail = (email: string) => {
    setEmailError(false)
    setEmail(email)
  }
  const onKeydownEmail = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      validateEmail()
    }
  }

  const onDownload: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()

    if (vCardStore.modalVCard?.source) {
      downloadFile(vCardStore.modalVCard?.source, vCardStore.modalVCard?.name)
    }
  }

  const onSubmit = async () => {
    const validations = await Promise.all([validatePhone(), validateEmail()])

    if (validations.some(Boolean)) return

    try {
      setLoading(true)
      const params = {
        email,
        last_name,
        first_name,
        number: phone,
      }
      const { data: contacts } = await ContactsApi.getDuplicates(params)
      if (contacts.length) {
        vCardStore.openCompareContent(new VCard({ ...params, phone }), contacts)
      } else {
        await ContactsApi.createContact(params)
        vCardStore.onCloseModal()
        toastStore.add({
          title: 'Contact saved',
          type: 'success',
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!vCardStore.modalVCard) return

    setFirstName(vCard?.first_name)
    setLastName(vCard?.last_name)
    setPhone(vCard?.phone || '')
    setEmail(vCard?.email || '')
    setEmailError(false)
    setPhoneError(false)
  }, [vCard])

  if (!vCard) return <Fragment />

  const disabledSave =
    isPhoneError || isEmailError || loading || checkLoadingEmail || vCardStore.isLoading

  return (
    <section className={styles.wrap} ref={containerRef}>
      <div className={styles.headerCard}>
        <VCardCardUi vCard={vCard} avatarSize={40} infoGap={4} />
      </div>
      <div className={styles.content}>
        {vCardStore.isLoading && <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />}
        <div className={styles.contentLine}>
          <Label
            label={{
              text: 'Phone',
              horizontal: true,
              leftIcon: 'phone',
              className: styles.inputLabel,
            }}
          />
          <TextField
            className={styles.contentFiled}
            Input={InputPhone}
            InputProps={{
              onKeyDown: onKeydownPhone,
            }}
            variant='outline'
            size='small'
            viewOnly={!vCard.isSave}
            error={isPhoneError ? 'Please enter a valid number' : ''}
            value={phone}
            onChange={onChangePhone}
            rightActions={[
              makeTextFieldClear(),
              makeTextFieldError({ boundaryElement: containerRef.current }),
            ]}
          />
        </div>
        <div className={styles.contentLine}>
          <Label
            label={{
              text: 'First name',
              horizontal: true,
              className: styles.inputLabel,
              leftIcon: 'user',
            }}
          />
          <TextField
            className={styles.contentFiled}
            InputProps={{
              placeholder: '-',
            }}
            variant='outline'
            size='small'
            viewOnly={!vCard.isSave}
            value={first_name}
            onChange={setFirstName}
            rightActions={[makeTextFieldClear()]}
          />
        </div>
        <div className={styles.contentLine}>
          <Label
            label={{
              text: 'Last name',
              horizontal: true,
              className: styles.inputLabel,
              leftIcon: 'user',
            }}
          />
          <TextField
            className={styles.contentFiled}
            InputProps={{
              placeholder: '-',
            }}
            variant='outline'
            size='small'
            viewOnly={!vCard.isSave}
            value={last_name}
            onChange={setLastName}
            rightActions={[makeTextFieldClear()]}
          />
        </div>
        <div className={styles.contentLine}>
          <Label
            label={{
              text: 'Email',
              horizontal: true,
              className: styles.inputLabel,
              leftIcon: 'email',
            }}
          />
          <TextField
            className={styles.contentFiled}
            InputProps={{
              onKeyDown: onKeydownEmail,
              placeholder: '-',
            }}
            variant='outline'
            size='small'
            viewOnly={!vCard.isSave}
            readOnly={checkLoadingEmail}
            error={isEmailError ? 'Please enter a valid email' : ''}
            value={email}
            onChange={onChangeEmail}
            rightActions={[
              makeTextFieldClear(),
              makeTextFieldError({ boundaryElement: containerRef.current }),
            ]}
          />
        </div>
      </div>
      <div className={styles.actions}>
        {vCard.isSave ? (
          <Button
            onClick={onSubmit}
            contained={'primary'}
            size={'medium'}
            text={'Save contact'}
            disabled={disabledSave}
            loading={loading}
          />
        ) : (
          <Button
            contained={'secondary'}
            size={'medium'}
            text={'Close'}
            onClick={vCardStore.onCloseModal}
          />
        )}

        <IconButton
          variant={'contained'}
          color={'secondary'}
          size={'medium'}
          icon={'download'}
          onClick={onDownload}
          ariaLabel='EditVCardContent_download'
        />
      </div>
    </section>
  )
})
