import { useEffect } from 'react'
import { DropdownCardsStore, DropdownCarsContext } from 'widgets/DropdownCards'
import { DropdownCardsUi } from './DropdownCardsUi'

type IDropdownCardsProps = {
  store: DropdownCardsStore
}

export const DropdownCards = ({ store }: IDropdownCardsProps) => {
  useEffect(() => {
    store.loadSources()
  }, [])

  return (
    <DropdownCarsContext.Provider value={store}>
      <DropdownCardsUi />
    </DropdownCarsContext.Provider>
  )
}
