import React, { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, FullPageWrapper, type ITabItem, Tabs, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { NumbersStore } from 'pages/settings/pages/numbers/store/NumbersStore'
import { NumbersRoutes } from 'pages/settings/pages/numbers/route/NumbersRoutes'
import { SettingsRoutes } from 'pages/settings/route/type'
import styles from './styles.module.scss'

const tabs: ITabItem[] = [
  {
    name: 'Assigned',
    key: NumbersRoutes.assigned,
  },
  {
    name: 'Unassigned',
    key: NumbersRoutes.unassigned,
  },
]

export const NumbersLayout = observer(() => {
  const { pathname } = useLocation()
  const { usedLicense } = useMemo(() => new NumbersStore(), [])

  useEffect(() => {
    if (pathname.endsWith(SettingsRoutes.numbers)) {
      uiStore.changeRouteViaVue({
        path: `/${SettingsRoutes.root}/${SettingsRoutes.numbers}/${NumbersRoutes.assigned}`,
        method: 'replace',
      })
    }
  }, [pathname])

  const activeTab = pathname.split('/').filter(Boolean).at(-1) || ''
  const handleSelectTab = (tab: ITabItem) => {
    uiStore.changeRouteViaVue({
      path: `/${SettingsRoutes.root}/${SettingsRoutes.numbers}/${tab.key}`,
    })
  }

  return (
    <FullPageWrapper className={styles.container}>
      <div className={styles.content}>
        <header>
          <div className={styles.integrationDetailsHeader}>
            <Typography variant='heading-lg' ariaLabel='Numbers_title'>
              Numbers
            </Typography>
            <div className={styles.title}>
              <Typography
                variant='body-md-regular'
                ariaLabel='Numbers_subtitle_text'
                color='tertiary'
              >
                Number licenses used:
              </Typography>
              <Typography variant='body-md-regular' ariaLabel='Numbers_subtitle_value'>
                {usedLicense}
              </Typography>
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              text='Manage licenses'
              contained='secondary'
              iconProps={{ icon: 'checkVerified', color: 'tertiary' }}
              size='medium'
            />
            <Button text='Add number' iconProps={{ icon: 'add' }} size='medium' />
          </div>
        </header>
        <div className={styles.navBar}>
          <Tabs
            variant='default'
            tabs={tabs}
            activeTabKey={activeTab}
            handleSelectTab={handleSelectTab}
          ></Tabs>
        </div>
        <Outlet />
      </div>
    </FullPageWrapper>
  )
})
