import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { broadcastStore, BroadcastRoutesEnum } from 'entities/Broadcast'

export const BroadcastTableLayout = observer(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    broadcastStore.checkEmptyStateBroadcast(pathname.includes(BroadcastRoutesEnum.all))
  }, [])
  return (
    <>
      {broadcastStore.loadingEmptyState && (
        <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
      )}
      <div
        style={{
          visibility: broadcastStore.loadingEmptyState ? 'hidden' : 'visible',
          height: '100%',
        }}
      >
        <Outlet />
      </div>
    </>
  )
})
