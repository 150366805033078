import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Button } from 'shared/ui'
import { contactChoosePhoneModalStore } from 'widgets/ContactChoosePhoneModal'
import styles from './styles.module.scss'

export const ContactChoosePhoneModalActions = observer(({}) => {
  const { handleSubmit, disabled, loading } = contactChoosePhoneModalStore

  return (
    <div className={classnames(styles.actionsWrap)}>
      <Button
        loading={loading}
        contained='primary'
        text='Confirm'
        disabled={disabled}
        onClick={() => {
          handleSubmit()
        }}
      />
    </div>
  )
})
