import { observer } from 'mobx-react-lite'
import {
  EnumDropdownItemVariant,
  IDropdownItem,
  InputCheckbox,
  DropdownPlaceholder,
} from 'shared/ui'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import {
  exportConversationModalStore,
  exportFormats,
  ExportFormatType,
} from 'widgets/ExportConversationModal'

export const ExportConversationsModalsContent = observer(() => {
  const onChange = (item: IDropdownItem) => {
    const id = item.id as ExportFormatType

    exportConversationModalStore.setFormat(id)
  }
  return (
    <div className={'col8'}>
      <DropdownPlaceholder
        items={exportFormats.map((format) => ({
          id: format,
          label: format,
          activeValue: format === exportConversationModalStore.format,
          variant: EnumDropdownItemVariant.CheckedRight,
        }))}
        onChange={onChange}
        widthDropdown={440}
        label={{
          text: 'Format',
        }}
        placeholder={String(exportConversationModalStore.format ?? 'Select')}
        textPlaceholderProps={{
          variant: 'stroke',
          size: 'small',
          rightActions: [makeTextFieldIcon({ icon: 'chevronDown' })],
        }}
      />
      <InputCheckbox
        checked={exportConversationModalStore.includeNote}
        onChecked={exportConversationModalStore.toggleIncludeNote}
        label={'Include notes'}
      />
    </div>
  )
})
