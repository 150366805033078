import { observer } from 'mobx-react-lite'
import React from 'react'
import { CardsFilter } from 'shared/ui'
import { useTriggerDetailContext } from 'widgets/TriggerDetail/context/triggerDetailContext'
import styles from './styles.module.scss'

export const ContactStatisticsFilter = observer(() => {
  const { filters, onActiveFilter, filter } = useTriggerDetailContext()

  return (
    <>
      <CardsFilter
        items={filters}
        activeKey={filter}
        onActiveFilter={onActiveFilter}
        wrapperClassName={styles.wrapper}
      />
      <div className={styles.separator} />
    </>
  )
})
