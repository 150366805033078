import { makeAutoObservable, runInAction } from 'mobx'
import { logger } from 'shared/lib'
import { inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { type IUser, usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import type { ContactsDetailsStore } from 'widgets/ContactsDetails'

export class ConversationInfoStore {
  private _search = ''
  private _open = false
  private _reassigning = false
  private _loading = true

  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([inboxesStore.fetchSmartInboxes({ cache: true })])

    runInAction(() => {
      this._loading = false
    })
  }

  setSearch = (value: string) => {
    this._search = value
  }

  setOpen = (value: boolean) => {
    this._open = value
  }

  setReassigning = (status: boolean) => {
    this._reassigning = status
  }

  onReassign = async (id: number | null) => {
    if (!this.conversation) return

    try {
      runInAction(() => {
        this._reassigning = true
      })

      const data = await conversationStore.reassign(this.conversation.id, id)

      if (data) {
        this._contactsDetailsStore.handleUpdateConversation(data)
      }

      runInAction(() => {
        this._reassigning = false
      })
    } catch (e) {
      logger.error(e)
    }
  }

  get loading() {
    return this._loading
  }

  get reassigning() {
    return this._reassigning
  }

  get open() {
    return this._open
  }

  get search() {
    return this._search
  }

  get conversation() {
    return this._contactsDetailsStore.conversation
  }

  get inbox() {
    return inboxesStore.getItem(this.conversation?.inbox_id)
  }

  get number() {
    return numbersStore.getItem(this.conversation?.number_id)
  }

  get members() {
    if (this.inbox?.type !== 'inbox') return []

    return this.inbox.memberIds
      .reduce<IUser[]>((state, id) => {
        const item = usersStore.getItem(id)

        return item ? [...state, item] : [...state]
      }, [])
      .filter((member) => !member.isViewOnlyRole)
  }

  get filteredMembers() {
    if (!this._search) return this.members

    return this.members.filter((member) =>
      member.name.toLowerCase().includes(this._search.toLowerCase())
    )
  }
}
