import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Box,
  type IDropdownItem,
  type ITooltipProps,
  PhoneWithLabelInput,
  TextFieldWithLabel,
} from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import type { ContactStore } from 'pages/settings/pages/compliance/ui/CommonPeopleToContact/store/contactStore'
import styles from './styles.module.scss'

type ContactFormProps = {
  isSecond?: boolean
  contact: ContactStore
  jobPositionList: IDropdownItem[]
  emailErrorMessage?: string
  phoneErrorMessage?: string | null
  isErrorBorderEmail: boolean
  similarityDomainsWarning: string | null
  setValueCallback: () => void
}

const phoneTooltip: ITooltipProps = {
  type: 'description',
  width: 290,
  label: "Your representative's mobile phone number",
  desc: 'May also be used for verification codes',
}

export const ContactForm: FC<ContactFormProps> = observer(
  ({
    isSecond,
    contact,
    jobPositionList,
    emailErrorMessage,
    phoneErrorMessage,
    isErrorBorderEmail,
    similarityDomainsWarning,
    setValueCallback,
  }) => {
    const { phoneInputStore } = contact

    return (
      <>
        <Box display='flex' justifyContent='space-between' marginBottom={3} gap={'12px'}>
          <TextFieldWithLabel
            labelProps={{
              label: {
                text: 'First name',
              },
            }}
            textFieldProps={{
              value: contact.firstName,
              onChange: contact.setFirstName,
              InputProps: {
                placeholder: 'e.g. Adam',
              },
              className: styles.inputPadding,
            }}
          />
          <TextFieldWithLabel
            labelProps={{
              label: {
                text: 'Last name',
              },
            }}
            textFieldProps={{
              value: contact.lastName,
              onChange: contact.setLastName,
              InputProps: {
                placeholder: 'e.g. Johnson',
              },
              className: styles.inputPadding,
            }}
          />
        </Box>
        <Box display='flex' justifyContent='space-between' marginBottom={3} gap={'12px'}>
          <TextFieldWithLabel
            labelProps={{
              label: {
                text: 'Business title',
              },
              questionTooltipProps: isSecond
                ? undefined
                : {
                    type: 'description',
                    width: 247,
                    label:
                      'We’re just making sure you’re in a position to make important decisions.',
                    desc: 'So, president, founder, executive, manager, or even boss are legit options.',
                  },
            }}
            textFieldProps={{
              value: contact.businessTitle,
              onChange: contact.setBusinessTitle,
              InputProps: {
                placeholder: 'e.g. Founder',
              },
              className: styles.inputPadding,
            }}
          />
          <SelectField
            boxWidth='100%'
            labelProps={{
              label: {
                text: 'Job position',
              },
            }}
            items={jobPositionList}
            value={contact.jobPosition}
            setValue={contact.setJobPosition}
            btnProps={{
              size: 'large',
              style: {
                color: !contact.jobPosition
                  ? 'var(--content-neutral-primary)'
                  : 'var(--content-primary-primary)',
                height: '40px',
              },
              className: styles.selectPadding,
            }}
          />
        </Box>

        <Box marginBottom={3}>
          <TextFieldWithLabel
            errorBorder={isErrorBorderEmail}
            errorMessage={emailErrorMessage}
            warningText={similarityDomainsWarning || undefined}
            labelProps={{
              marginBottom: 4,
              label: {
                text: 'Business email',
              },
            }}
            textFieldProps={{
              value: contact.email,
              onChange: contact.setEmail,
              onBlur: contact.checkIsEmail,
              InputProps: {
                placeholder: 'e.g. adam.johnson@example.com',
              },
              className: styles.inputPadding,
            }}
          />
        </Box>
        <PhoneWithLabelInput
          label='Phone number'
          questionTooltipProps={isSecond ? undefined : phoneTooltip}
          item={phoneInputStore.country}
          items={phoneInputStore.countries}
          number={phoneInputStore.number}
          numberError={phoneInputStore.numberError || phoneErrorMessage || null}
          isValidNumber={phoneInputStore.isValidNumber}
          numberRequestError={phoneInputStore.numberRequestError}
          setNumber={(value) => {
            setValueCallback()
            phoneInputStore.setNumber(value)
          }}
          setCountry={phoneInputStore.setCountry}
          triggerNumberValidation={phoneInputStore.triggerNumberValidation}
        />
      </>
    )
  }
)
