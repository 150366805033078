import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IDropdownItem } from 'shared/ui'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { TEXT_TO_SPEECH_VOICE_NAME } from 'shared/constants/limits'
import { IResponseVoice, textToSpeechStore } from 'entities/TextToSpeech'
import { ModalContent } from 'widgets/NewCustomVoiceModal/ModalContent'
import { RenameModalStore } from 'widgets/RenameModal'
import { RecordAudioStore } from 'widgets/RecordAudio'

type INewCustomVoiceStoreProps = {
  onSuccessCreateVoice: (voice: IResponseVoice) => void
}

export type ITopic = 'generic' | 'sales' | 'marketing' | 'customer-support'

const topics: {
  [key in ITopic]: {
    id: ITopic
    label: string
    text: string
  }
} = {
  marketing: {
    id: 'marketing',
    label: 'Marketing',
    text: "Hello, AI, let's dive into the world of marketing together! Imagine a blank canvas where I craft compelling stories about products and services. Every word I choose serves a purpose – to create a connection, evoke curiosity, and inspire action. I play with my tone, adapting it based on whether I'm introducing a new concept, emphasizing a unique selling point, or delivering a call to action. There's a finesse to how I communicate, a balance between professionalism and relatability. As you learn from my marketing-focused voice, pay attention to how I seamlessly blend information with emotion. I want my audience to feel excited and informed. Ready to embark on this marketing journey, AI? Let's make every word count and turn listeners into loyal customers. Here's to the art of persuasion and the science of engagement!",
  },
  generic: {
    id: 'generic',
    label: 'Generic',
    text: "Hello, AI! I'm here to help you understand my voice better. I enjoy talking about a variety of topics, from technology and science to movies and hobbies. It's important for you to capture the nuances in the way I pronounce words and the natural pauses I take while speaking. This will help you sound more like me. I might share a personal story: Last summer, I went on a memorable vacation to the mountains. The crisp air, the sound of rustling leaves, and the breathtaking views all left a lasting impression on me. Remember, I tend to express emotions through my voice, so try to capture moments where I'm excited, thoughtful, or even just relaxed. Thanks for learning from my voice! Looking forward to hearing myself in the AI world.",
  },
  sales: {
    id: 'sales',
    label: 'Sales',
    text: "Welcome to my sales pitch, AI! Get ready to learn how I communicate when I'm all about sales. Picture this: a bustling marketplace, filled with eager customers and endless opportunities. As I introduce products, I emphasize their unique features that stand out from the competition. My tone shifts between enthusiastic and persuasive, highlighting the benefits that customers can't resist. There's a rhythm to my speech, a cadence that keeps listeners engaged. To truly capture my sales-focused voice, pay attention to how I emphasize certain words for emphasis and use confident pauses to build anticipation. Let's seal the deal with this training session, AI, and bring the power of my sales-savvy voice to your capabilities. Here's to a future filled with successful pitches and satisfied customers!",
  },
  'customer-support': {
    id: 'customer-support',
    label: 'Customer support',
    text: "Hello, AI, let's tackle the realm of customer support! Imagine a world where patience and understanding reign. As a customer support representative, I'm here to assist, reassure, and solve problems. My voice reflects empathy, as I listen actively and respond with care. Sometimes, I encounter frustrated customers, and my tone becomes soothing and solution-oriented. Other times, I'm sharing step-by-step instructions, making sure to enunciate clearly for easy understanding. There's a steady pace to my speech, and I often repeat important information to ensure clarity. To capture my customer support-oriented voice, focus on conveying genuine concern and offering practical solutions. Let's master the art of making customers feel heard and valued, AI. Here's to turning challenges into opportunities for exceptional service and building lasting customer relationships!",
  },
}

export const topicsItems: IDropdownItem[] = Object.values(topics)

export class NewCustomVoiceStore {
  constructor({ onSuccessCreateVoice }: INewCustomVoiceStoreProps) {
    makeAutoObservable(this)
    this.onSuccessCreateVoice = onSuccessCreateVoice
    this.open()
  }
  onSuccessCreateVoice: (voice: IResponseVoice) => void
  modalId = 'newCustomVoice'
  selectedTopic: ITopic = 'marketing'

  recordAudioStore: RecordAudioStore = new RecordAudioStore({
    AUDIO_RECORD_LIMIT: 180,
    withDelay: true,
  })

  get topicText() {
    return topics[this.selectedTopic].text
  }

  private onClose = () => {
    if (this.recordAudioStore.recordStore.micRecorder?.getRecordingDuration()) {
      if (
        this.recordAudioStore.recordStore.startRecord &&
        !this.recordAudioStore.recordStore.pauseRecord
      ) {
        this.recordAudioStore.recordStore.onPause()
      }

      this.discardChanges()
    } else {
      modalStore.removeModal(this.modalId)
    }
  }

  private discardChanges = (discardAction?: () => void) => {
    const id = nanoid()
    modalStore.addModal({
      id,
      type: ModalTypeList.WARNING,
      title: 'Leaving will discard your content',
      primaryAction: {
        text: 'Keep content',
        onAction: () => {
          modalStore.removeModal(id)
        },
      },
      secondaryAction: {
        text: 'Discard',
        onAction: () => {
          discardAction && discardAction()
          modalStore.removeModal(id)
          modalStore.removeModal(this.modalId)
        },
      },
    })
  }

  private open = () => {
    modalStore.addModal({
      id: this.modalId,
      title: 'Create custom voice',
      ModalContent: ModalContent,
      pureContent: true,
      ModalContentProps: { store: this },
      onClose: this.onClose,
    })

    const handleFinish = (file: File) => {
      modalStore.setHideModalId(this.modalId)
      const nameModalId = nanoid()
      const closeNameModal = () => {
        modalStore.removeHideModalId(this.modalId)
        modalStore.removeModal(nameModalId)
      }
      new RenameModalStore({
        id: nameModalId,
        title: 'Voice name',
        element: 'custom voice',
        maxNameLength: TEXT_TO_SPEECH_VOICE_NAME,
        secondaryAction: {
          text: 'Back',
          onAction: closeNameModal,
        },
        onClose: () => {
          this.discardChanges(closeNameModal)
        },

        onSave: async (name: string) => {
          try {
            const voice = await textToSpeechStore.createCustomVoice({
              sample: file,
              name,
            })
            if (voice) {
              this.onSuccessCreateVoice(voice)
              closeNameModal()
              modalStore.removeModal(this.modalId)
            }
          } catch (e) {
            console.error(e)
          }
        },
      })
    }
    this.recordAudioStore.init({ handleFinish, handleCancel: this.onClose })
  }

  onSelectTopic = (topic: ITopic) => {
    this.selectedTopic = topic
  }
}
