// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ION9ogjZj1hsLUn8Wwsr{display:inline-flex;padding:3.5px 8px;border-radius:4px;background-color:var(--gray-a-15)}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotLabel/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,mBAAA,CACA,iBAAA,CACA,iBAAA,CACA,iCAAA","sourcesContent":[".label {\n    display: inline-flex;\n    padding: 3.5px 8px;\n    border-radius: 4px;\n    background-color: var(--gray-a-15);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "ION9ogjZj1hsLUn8Wwsr"
};
export default ___CSS_LOADER_EXPORT___;
