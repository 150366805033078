import { type IIconsVector } from 'shared/ui'
import { ChatbotInstructionType } from 'entities/Chatbot'

export type IChatbotTemplate = {
  type: ChatbotInstructionType
  icon: IIconsVector
  name: string
  description: string
}

export const ChatbotTemplate = new Map<ChatbotInstructionType, IChatbotTemplate>([
  [
    ChatbotInstructionType.Custom,
    {
      type: ChatbotInstructionType.Custom,
      icon: 'settings',
      name: 'Custom',
      description: 'Create a custom Textbot with your own instructions',
    },
  ],
  [
    ChatbotInstructionType.FAQ,
    {
      type: ChatbotInstructionType.FAQ,
      icon: 'messageQuestion',
      name: 'FAQ / Information',
      description: 'Automatically answer questions about your business or products',
    },
  ],
  [
    ChatbotInstructionType.LeadQualification,
    {
      type: ChatbotInstructionType.LeadQualification,
      icon: 'usersCheck',
      name: 'Lead Qualification',
      description: 'Gather essential information to qualify your leads',
    },
  ],
  [
    ChatbotInstructionType.Promotion,
    {
      type: ChatbotInstructionType.Promotion,
      icon: 'announcement',
      name: 'Promotion',
      description: 'Promote your products and engage customers',
    },
  ],
  [
    ChatbotInstructionType.FollowUp,
    {
      type: ChatbotInstructionType.FollowUp,
      icon: 'bellFilled',
      name: 'Follow Up',
      description: 'Automate your follow-up routine',
    },
  ],
])

export const ChatbotTemplateList = [...ChatbotTemplate.values()]
