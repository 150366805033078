import { IStatusProps } from 'shared/ui/Status'

export const subscriptionStatusConfig = {
  active: {
    title: 'Active',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
  trialing: {
    title: 'Lead',
    icon: 'refreshCircle',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
  canceled: {
    title: 'Canceled',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  incomplete_expired: {
    title: 'Incomplete expired',
    icon: 'exclamationCircle',
    emphasis: 'high',
    intent: 'warning',
  } as IStatusProps,
  unpaid: {
    title: 'Unpaid',
    icon: 'exclamationCircle',
    emphasis: 'high',
    intent: 'warning',
  } as IStatusProps,
  past_due: {
    title: 'Past due',
    icon: 'minusCircle',
    emphasis: 'high',
    intent: 'neutral',
  } as IStatusProps,
}
