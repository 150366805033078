import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { newSavedReplyStore } from 'widgets/SavedReplies'
import styles from './styles.module.scss'

export const NewReplyBtn = observer(() => {
  const { handleNewSavedReply } = newSavedReplyStore
  return (
    <div className={styles.wrapBtn}>
      <Button
        icon={'add'}
        text={'Create'}
        contained={'secondary'}
        size={'medium'}
        className={styles.btn}
        onClick={() => handleNewSavedReply()}
      />
    </div>
  )
})
