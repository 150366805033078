// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZUIS6FM1ZQuk5UL4AQTH{margin-top:8px;position:relative;display:flex;flex-direction:column;height:max-content;flex-grow:1;margin-left:16px}.aLDsQjZOW5GXwgc5PH_r{padding:8px 8px 32px;position:sticky;left:0;white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastDetail/BroadcastDetailRecurring/ui/RecurringTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,oBAAA,CACA,eAAA,CACA,MAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  margin-top: 8px;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  height: max-content;\n  flex-grow: 1;\n  margin-left: 16px;\n}\n\n.moreBtn{\n  padding: 8px 8px 32px;\n  position: sticky;\n  left: 0;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ZUIS6FM1ZQuk5UL4AQTH",
	"moreBtn": "aLDsQjZOW5GXwgc5PH_r"
};
export default ___CSS_LOADER_EXPORT___;
