// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WEooKz_uBL_4YnqbwHdf{width:100%;display:grid;grid-template-columns:1fr var(--titled-row-action-width, 280px);gap:var(--titled-row-horizontal-gap, 20px)}.gyy81w974lykgt2s83KO{grid-template-columns:1fr}.jSYqEGxzrNUQGqaWndCu{width:100%;display:flex;flex-direction:column;justify-content:center;gap:var(--titled-row-vertical-gap, 4px);min-width:0}.lo5LUYQekwuFfgesZSOM{width:100%;display:flex;align-items:center;gap:6px;min-width:0}", "",{"version":3,"sources":["webpack://./src/shared/ui/TitledRow/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,+DAAA,CACA,0CAAA,CAGF,sBACE,yBAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,uCAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA","sourcesContent":[".root {\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr var(--titled-row-action-width, 280px);\n  gap: var(--titled-row-horizontal-gap, 20px);\n}\n\n.oneColumn {\n  grid-template-columns: 1fr;\n}\n\n.main {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: var(--titled-row-vertical-gap, 4px);\n  min-width: 0;\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "WEooKz_uBL_4YnqbwHdf",
	"oneColumn": "gyy81w974lykgt2s83KO",
	"main": "jSYqEGxzrNUQGqaWndCu",
	"header": "lo5LUYQekwuFfgesZSOM"
};
export default ___CSS_LOADER_EXPORT___;
