// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IX5GHiN0bnYSsHVzcz9M{padding:4px 0;position:relative;z-index:var(--zIndex-BASE_3)}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsTagsForm/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,iBAAA,CACA,4BAAA","sourcesContent":[".wrap {\n  padding: 4px 0;\n  position: relative;\n  z-index: var(--zIndex-BASE_3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "IX5GHiN0bnYSsHVzcz9M"
};
export default ___CSS_LOADER_EXPORT___;
