// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jFx6IhciBFKD_sBExOHP{margin-top:-30px}.YP4CvkvrQzWQKtVW9Sf1{display:flex;flex-direction:column;gap:8px}.V4oAS4nfKVUkP0adcCM9{box-shadow:0 1px 0 0 var(--interaction-neutral-normal)}.V4oAS4nfKVUkP0adcCM9:focus-within{box-shadow:0 1px 0 0 var(--interaction-brand-active),0 3px 0 0 var(--light-blue-20)}.nEP5yte31MZZUATqdIOP:not(:focus-within){box-shadow:0 1px 0 0 var(--red-80)}.selbVrCyZ1gES0X3Ohzn{display:flex;flex-direction:column;padding:30px 0 0}.Q8XmRMDmIxjowCtRPca3{display:flex;flex-direction:column;gap:4px;padding:8px 0}.Q8XmRMDmIxjowCtRPca3:empty{display:none}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotEdit/StepFirstMessage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,OAAA,CAGJ,sBACI,sDAAA,CAEA,mCACI,mFAAA,CAGJ,yCACI,kCAAA,CAIR,sBACI,YAAA,CACA,qBAAA,CACA,gBAAA,CAGJ,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,aAAA,CAEA,4BACE,YAAA","sourcesContent":[".container {\n    margin-top: -30px;\n}\n\n.message {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.field {\n    box-shadow: 0 1px 0 0 var(--interaction-neutral-normal);\n\n    &:focus-within {\n        box-shadow: 0 1px 0 0 var(--interaction-brand-active), 0 3px 0 0 var(--light-blue-20);\n    }\n\n    &Error:not(:focus-within) {\n        box-shadow: 0 1px 0 0 var(--red-80);\n    }\n}\n\n.details {\n    display: flex;\n    flex-direction: column;\n    padding: 30px 0 0;\n}\n\n.alerts {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  padding: 8px 0;\n\n  &:empty {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jFx6IhciBFKD_sBExOHP",
	"message": "YP4CvkvrQzWQKtVW9Sf1",
	"field": "V4oAS4nfKVUkP0adcCM9",
	"fieldError": "nEP5yte31MZZUATqdIOP",
	"details": "selbVrCyZ1gES0X3Ohzn",
	"alerts": "Q8XmRMDmIxjowCtRPca3"
};
export default ___CSS_LOADER_EXPORT___;
