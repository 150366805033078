// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HQIAya3Ic2G3E40cmzgr{color:var(--content-negative-secondary);display:flex;padding:4px 4px 4px 8px;align-items:center;gap:4px}.vDskB_Kko9r8cQ0bSZjG{padding:4px 6px;color:var(--content-primary-tertiary)}.QDdhetYMo4gC2Pr3Udvy{display:flex;flex-direction:column;gap:8px}.RF60dKbjpn8xmBRaMoDz{display:grid;grid-template-columns:1fr max-content max-content;padding-right:4px;padding-left:8px;align-items:center}.FDwJmeYrnCOlLl6SpsGi{padding:0 6px 6px 6px}", "",{"version":3,"sources":["webpack://./src/shared/ui/AudioPlayer/ui/TranscriptionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,uCAAA,CACA,YAAA,CACA,uBAAA,CACA,kBAAA,CACA,OAAA,CAEF,sBACE,eAAA,CACA,qCAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,sBACE,YAAA,CACA,iDAAA,CACA,iBAAA,CACA,gBAAA,CACA,kBAAA,CAEF,sBACE,qBAAA","sourcesContent":[".failed{\n  color: var(--content-negative-secondary);\n  display: flex;\n  padding: 4px 4px 4px 8px;\n  align-items: center;\n  gap: 4px;\n}\n.inProgress{\n  padding: 4px 6px;\n  color: var(--content-primary-tertiary);\n}\n.wrapContent{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.actions{\n  display: grid;\n  grid-template-columns: 1fr max-content max-content;\n  padding-right: 4px;\n  padding-left: 8px;\n  align-items: center;\n}\n.content{\n  padding: 0 6px 6px 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"failed": "HQIAya3Ic2G3E40cmzgr",
	"inProgress": "vDskB_Kko9r8cQ0bSZjG",
	"wrapContent": "QDdhetYMo4gC2Pr3Udvy",
	"actions": "RF60dKbjpn8xmBRaMoDz",
	"content": "FDwJmeYrnCOlLl6SpsGi"
};
export default ___CSS_LOADER_EXPORT___;
