import { makeAutoObservable } from 'mobx'
import type { IAPiOption } from 'shared/api'
import { IDropdownItem } from 'shared/ui'
import { logger } from 'shared/lib'
import { ComplianceApi } from 'entities/Compliance'
import type { PointsOfContactItem } from 'entities/Compliance/model'
import type { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { ContactStore } from './contactStore'

export class PeopleToContactStore {
  private _primaryContact: ContactStore
  private _secondaryContact: ContactStore

  constructor(private _complianceLayerStore: ComplianceLayerStore | null) {
    this._primaryContact = new ContactStore(this._complianceLayerStore)
    this._secondaryContact = new ContactStore(this._complianceLayerStore)

    makeAutoObservable(this)
    this.init()
  }

  private _jobPositionListValue: IAPiOption[] = []
  private _isSecondContact = false
  private _contactsConfirmed = false

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  get primaryContact() {
    return this._primaryContact
  }

  get secondaryContact() {
    return this._secondaryContact
  }

  get isPrimaryContactFilled() {
    return this.primaryContact.isFilled
  }

  get isSecondaryContactFilled() {
    return this.secondaryContact.isFilled
  }

  get errorStore() {
    return this.complianceLayerStore?.errorStore
  }

  get isNextDisabled() {
    if (!this._contactsConfirmed) return true

    return this.isSecondContact
      ? !this.isPrimaryContactFilled || !this.isSecondaryContactFilled
      : !this.isPrimaryContactFilled
  }

  get jobPositionList(): IDropdownItem[] {
    return this._jobPositionListValue.map((item) => {
      return { label: item.name || '', id: item.id }
    })
  }

  get isSecondContact() {
    return this._isSecondContact
  }

  get contactsConfirmed() {
    return this._contactsConfirmed
  }

  get firstContactToBackend() {
    return this.primaryContact.contactToBackend
  }

  get secondContactToBackend() {
    return this.secondaryContact.contactToBackend
  }

  init = () => {
    this.loadBusinessProfileJobPositions()
  }

  setSecondContact = (isSecond: boolean) => {
    if (!isSecond) {
      this.secondaryContact.reset()
    }
    this._isSecondContact = isSecond
  }

  setPrimaryFromResponse = (data: PointsOfContactItem) => {
    this.primaryContact.setContactFromResponse(data)
  }

  setSecondaryFromResponse = (data: PointsOfContactItem) => {
    this.secondaryContact.setContactFromResponse(data)
  }

  toggleContactsConfirmed = () => {
    this._contactsConfirmed = !this._contactsConfirmed
  }
  loadBusinessProfileJobPositions = async () => {
    try {
      const { data } = await ComplianceApi.getBusinessProfileJobPositions()

      this._jobPositionListValue = data
    } catch (error) {
      logger.error(error)
    }
  }
}
