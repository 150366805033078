import { Status } from 'shared/ui'
import { IKeywordStatus } from 'entities/Keywords/api/type'

import config from './config'

export interface IKeywordStatusProps {
  status: IKeywordStatus
  className?: string
  ariaLabel?: string
}

export const KeywordStatus = ({ status, className, ariaLabel }: IKeywordStatusProps) => (
  <Status {...config[status]} className={className} ariaLabel={ariaLabel} />
)
