import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  IconCopyButton,
  IconEmailButton,
  IconUrlButton,
  IconCallButton,
} from 'shared/ui/IconButton'
import type { ITextFieldAsideAction } from 'shared/ui/TextField'

import styles from './styles.module.scss'

export const TextFieldInteraction = {
  Copy: 'copy',
  Email: 'email',
  Url: 'url',
  Call: 'call',
} as const

export type ITextFieldInteractionType =
  (typeof TextFieldInteraction)[keyof typeof TextFieldInteraction]

export type ITextFieldInteractionAction = {
  type: ITextFieldInteractionType
  active?: boolean
  action?: () => void
}

export const makeTextFieldInteractions: (
  ...interactions: ITextFieldInteractionAction[]
) => ITextFieldAsideAction = (...interactions) => {
  const useTextFieldInteractions: ITextFieldAsideAction = (control) => {
    const interactionsMap = useMemo(
      () => new Map(interactions.map(({ type, action, active }) => [type, { action, active }])),
      [interactions]
    )

    const actionIsActive = useCallback(
      (type: ITextFieldInteractionType) => {
        const interaction = interactionsMap.get(type)

        return interaction != null && interaction.active
      },
      [interactionsMap]
    )

    const getActionHandler = useCallback(
      (type: ITextFieldInteractionType) => interactionsMap.get(type)?.action,
      [interactionsMap]
    )

    const [mouseOut, setMouseOut] = useState(true)
    const [hasFocus, setFocus] = useState(false)

    const isHidden = !control.value || mouseOut || (control.readOnly ? false : hasFocus)

    useEffect(() => {
      const label = control.containerRef?.current
      const input = control.inputRef?.current

      if (!label || !input) return

      const handleFocus = () => setFocus(true)
      const handleBlur = () => setFocus(false)
      input.addEventListener('focus', handleFocus)
      input.addEventListener('blur', handleBlur)

      const handleMouseEnter = () => setMouseOut(false)
      const handleMouseLeave = () => setMouseOut(true)
      label.addEventListener('mouseenter', handleMouseEnter)
      label.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        input.removeEventListener('focus', handleFocus)
        input.removeEventListener('blur', handleBlur)
        label.removeEventListener('mouseenter', handleMouseEnter)
        label.removeEventListener('mouseleave', handleMouseLeave)
      }
    }, [])

    return (
      <div
        className={classNames(styles.interactionActions, {
          [styles.hidden]: isHidden,
        })}
      >
        {actionIsActive(TextFieldInteraction.Call) && (
          <IconCallButton
            showTooltip={!isHidden}
            onClick={getActionHandler(TextFieldInteraction.Call)}
          />
        )}
        {actionIsActive(TextFieldInteraction.Email) && (
          <IconEmailButton showTooltip={!isHidden} value={control.value} />
        )}
        {actionIsActive(TextFieldInteraction.Url) && (
          <IconUrlButton showTooltip={!isHidden} value={control.value} />
        )}
        {actionIsActive(TextFieldInteraction.Copy) && (
          <IconCopyButton showTooltip={!isHidden} value={control.value} />
        )}
      </div>
    )
  }

  return useTextFieldInteractions
}
