import React from 'react'
import { observer } from 'mobx-react-lite'
import { ToggleBlock } from 'shared/ui'
import { ContactCard } from 'entities/Contacts'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import styles from './styles.module.scss'

export type IContactsGroupCardProps = {
  isDragging?: boolean
  createNewContactMode?: boolean
  hideDraggingIcon?: boolean
}
const ContactsGroupCard: React.FC<IContactsGroupCardProps> = observer(
  ({ isDragging, createNewContactMode, hideDraggingIcon }) => {
    const store = useContactsDetailsContext()

    return (
      <ToggleBlock
        borders={{ bottom: true }}
        isDragging={isDragging}
        disabled={createNewContactMode}
        hideDraggingIcon={hideDraggingIcon}
        title='Contacts'
        padding={'0 11px 23px'}
        margin={'-3px 0 0'}
      >
        <div className={styles.wrap}>
          {store.contacts.map((contact) => {
            if (!contact) return null

            return (
              <ContactCard
                key={contact.id}
                item={contact}
                active={contact.id === store.currentContact?.id}
                onChange={(item) => {
                  store.handleActiveContact(item)
                }}
              />
            )
          })}
        </div>
      </ToggleBlock>
    )
  }
)

export { ContactsGroupCard }
