import type { IParsedVcard } from 'entities/VCard'

export type IRequestAttachmentParams = {
  limit?: number
}

export type IResponseAttachmentUpload = {
  id?: number
  source: string
  thumbnail: string | null
  extension: string | null
  duration: number
  source_short: string
  content_type: string
  media_preview?: IResponseMedia
  parsedVCard: Array<IParsedVcard> | IParsedVcard
  is_allowed_for_media_url: boolean
  name: string | null
  size: string | null
  original_source?: string
}

export type IResponseMedia = {
  id: number
  content_type: string
  original_source?: string
  source: string
  thumbnail: string
  extension: string
  source_short: string
  created_at: string
  duration: number
  name: string | null
  size: string | null
  is_allowed_for_media_url: boolean
  media_preview?: IResponseMedia
  parsedVCard: Array<IParsedVcard> | IParsedVcard
  user_id: string | null
  processing: number
}

export type IResponseUploadSuccess = {
  attachment: IResponseMedia
  space_used: number
}

export type IResponseMediaShort = {
  content_type?: string
  source?: string
  name?: string | null
  url?: string
}

export interface ILoadRecentParams {
  limit?: number
  is_aircall?: boolean
}

export type IResponseUploadInfoItem = {
  mime_types: string[]
  size: number
}

export type IResponseUploadInfo = {
  audio: IResponseUploadInfoItem
  default: IResponseUploadInfoItem
  image: IResponseUploadInfoItem
  vcard: IResponseUploadInfoItem
  video: IResponseUploadInfoItem
  docs: IResponseUploadInfoItem
}

export type IResponseUploadInfoKeys = keyof IResponseUploadInfo

export type IParamsAttachmentsUploadLink = {
  name: string
  content_type: string
}

export type IResponseAttachmentsUploadLink = {
  link: {
    url: string
    headers: {
      Host: string[]
      'x-amz-acl': string[]
    }
  }
  attachment: IResponseMedia
}

export type IResponseEventAttachmentUploadError = {
  failedReason: string
  attachment: IResponseMedia
}

export enum AttachmentFetchType {
  Image = 'image',
  Audio = 'audio',
  Video = 'video',
  Docs = 'docs',
}

export type IResponseAttachmentList = {
  attachments: {
    [key in AttachmentFetchType]: IResponseMedia[]
  }
  space_used: number
  page: number
  per_page: number
  total: number
  type: AttachmentFetchType
}

export type IResponseDownloadLink = {
  link: string
}

export interface ILimitSizeError {
  label: string
  limit: number
}

export type IResponseDraftMessageAttachment = {
  content_type: string
  name?: string | null
  url?: string
  source?: string
}
