import React from 'react'
import { observer } from 'mobx-react-lite'
import { TriggersTable } from 'widgets/TriggerList/ui/TriggersTable/TriggersTable'
import styles from './styles.module.scss'

export const TriggerListContent = observer(() => {
  return (
    <div className={styles.triggerListContentContainer}>
      <TriggersTable />
    </div>
  )
})
