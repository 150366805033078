import { observer } from 'mobx-react-lite'
import { Alert, Button, IconButton } from 'shared/ui'

import styles from './styles.module.scss'
import { IGuidelineStore } from './GuidelineAlertStore'

export interface IGuidelineAlertProps {
  className?: string
  store: IGuidelineStore
  message: string
  link: string
}

export const GuidelineAlert = observer(
  ({ className, store, message, link }: IGuidelineAlertProps) => {
    if (store.hidden) return null

    return (
      <Alert
        className={className}
        item={{
          type: 'infoBlue',
          desc: message,
        }}
        rightAction={
          <>
            <Button
              tag='a'
              href={link}
              target={'_blank'}
              rel='noreferrer'
              className={styles.alertButton}
              text='Learn more'
              typeBtn='outlined'
              size='small'
              contained='secondary'
            />
            <IconButton
              icon='close'
              size='small'
              variant='outlined'
              transparentBackground
              ariaLabel='HideGuidelineAlert'
              onClick={() => store.hide()}
            />
          </>
        }
      />
    )
  }
)
