import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { IResponseConversation } from 'entities/Conversation/api/types'
import { contactsStore } from 'entities/Contacts'
import { inboxesStore } from 'entities/Inbox'
import { Inbox } from 'entities/Inbox/model/Inbox'

const openNewConversation = (contact: Contact, inbox?: Inbox) => {
  conversationStore.setSignal({
    type: 'open_new_conversation',
    payload: {
      contact: contact,
      inbox: inbox || null,
    },
  })
}

const openNewGroupConversation = (contacts: Contact[], inbox: Inbox) => {
  conversationStore.setSignal({
    type: 'open_new_group_conversation',
    payload: {
      contacts: contacts,
      inbox: inbox,
    },
  })
}

const openConversation = (conversation: IResponseConversation) =>
  conversationStore.setSignal({
    type: 'open_exist_conversation',
    payload: conversation,
  })

const openGroupConversation = (contact: Contact, conversation: IResponseConversation) =>
  conversationStore.setSignal({
    type: 'open_group_conversation',
    payload: {
      conversation: conversation,
      contact: contact,
    },
  })

export const openConversationByContact = async (contact: Contact) => {
  try {
    const {
      data: { results: conversations },
    } = await ConversationsApi.getConversationsSearch({
      tags: [],
      teams: [],
      members: [],
      contacts: [contact.id],
      page: 1,
    })

    switch (conversations.length) {
      case 0:
        return openNewConversation(contact)
      case 1:
        return await openConversation(conversations[0])
      default:
        return await openGroupConversation(contact, conversations[0])
    }
  } catch (error) {
    console.log(error)

    return true
  }
}

const handleNewConversation = (contacts: Contact[], inbox: Inbox) => {
  if (contacts.length > 1) {
    openNewGroupConversation(contacts, inbox)

    return
  }
  openNewConversation(contacts[0], inbox)
}

export const openConversationByParams = async (inbox_id: number, contact_ids: number[]) => {
  if (!inbox_id && !contact_ids) return

  try {
    const contacts = await contactsStore.getByIds(contact_ids)
    const inbox = await inboxesStore.getById(inbox_id, true)

    const {
      data: { results: conversations },
    } = await ConversationsApi.getConversationsSearch({
      tags: [],
      teams: [inbox_id],
      members: [],
      contacts: contact_ids,
      page: 1,
    })

    if (
      (inbox_id && inbox === undefined) ||
      (contact_ids.length && contact_ids.length !== contacts?.length)
    ) {
      const searchParams = new URLSearchParams(uiStore.queryParams)
      searchParams.delete('contact_id')
      searchParams.delete('inbox_id')
      uiStore.navigate?.(`${uiStore.pathName}?${searchParams}`)

      modalStore.addModal({
        disabledOnAllClose: true,
        type: ModalTypeList.INFO,
        title: 'Content unavailable',
        id: nanoid(),
        desc: "We're sorry, but you don't currently have access to this content or it was deleted. Please contact the user who shared the link with you to request access.",
      })

      return
    }

    if (contacts?.length && inbox && inbox instanceof Inbox) {
      if (conversations.length === 0 && contacts.length === 1) {
        handleNewConversation(contacts, inbox)
      } else if (conversations.length === 1) {
        // Check if there is a conversation with current contacts
        if (contacts.length > 1) {
          const conversationGroup = conversations.find((conversation) => conversation.is_group)
          let count = 0

          contacts.forEach((contact) => {
            if (
              contact.id === conversationGroup?.contact_id ||
              conversationGroup?.participants.find((participant) => participant.id === contact.id)
            ) {
              count += 1
            }
          })

          if (count !== contacts.length) {
            handleNewConversation(contacts, inbox)

            return true
          }
        }

        return await openConversation(conversations[0])
      } else {
        // Check if there is a conversation with current contacts
        if (contacts.length > 1 && conversations.length) {
          const conversationGroupList = conversations.filter(
            (conversation) => conversation.is_group
          )

          if (conversationGroupList.length) {
            let count = 0

            contacts.forEach((contact) => {
              const filteredList = conversationGroupList.filter((item) =>
                item.participants.find((participant) => participant.id === contact.id)
              )

              if (
                conversationGroupList.find((item) => item.contact_id === contact.id) ||
                Boolean(filteredList.length)
              ) {
                count += 1
              }
            })

            if (count !== contacts.length) {
              handleNewConversation(contacts, inbox)

              return true
            }
          }
        }

        if (!conversations.length) {
          handleNewConversation(contacts, inbox)

          return true
        }

        return await openGroupConversation(contacts[0], conversations[0])
      }
    }
  } catch (error) {
    console.log(error)

    return true
  }
}
