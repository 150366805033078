// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JyhXGW1fz5xNFDG8bTia{display:flex;justify-content:center;height:100%}.IqiRlhsCsOmfLeftJgMG{width:512px;position:relative}.Dr0nnFPdqHXIlwntBxpW{position:absolute;top:70px;left:-30px}.QYsAfUSTp6EYT213Pyuw{display:inline-flex;padding:3.5px 8px;border-radius:4px;background:var(--gray-a-15);margin:30px 0 12px 20px}.HV_S1zoghhVizgT26rAo{position:absolute;height:100%;right:0;padding:24px 20px 0 28px}.tKcnC72y6_9Pwm4f9nEq{margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/pages/settings/layout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,WAAA,CAEF,sBACE,WAAA,CACA,iBAAA,CAEF,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CAEF,sBACE,mBAAA,CACA,iBAAA,CACA,iBAAA,CACA,2BAAA,CACA,uBAAA,CAEF,sBACE,iBAAA,CACA,WAAA,CACA,OAAA,CACA,wBAAA,CAEF,sBACE,kBAAA","sourcesContent":[".contentContainer {\n  display: flex;\n  justify-content: center;\n  height: 100%;\n}\n.content {\n  width: 512px;\n  position: relative;\n}\n.backButton {\n  position: absolute;\n  top: 70px;\n  left: -30px;\n}\n.title {\n  display: inline-flex;\n  padding: 3.5px 8px;\n  border-radius: 4px;\n  background: var(--gray-a-15);\n  margin: 30px 0 12px 20px;\n}\n.rightMenu {\n  position: absolute;\n  height: 100%;\n  right: 0;\n  padding: 24px 20px 0 28px;\n}\n.menuGroup {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": "JyhXGW1fz5xNFDG8bTia",
	"content": "IqiRlhsCsOmfLeftJgMG",
	"backButton": "Dr0nnFPdqHXIlwntBxpW",
	"title": "QYsAfUSTp6EYT213Pyuw",
	"rightMenu": "HV_S1zoghhVizgT26rAo",
	"menuGroup": "tKcnC72y6_9Pwm4f9nEq"
};
export default ___CSS_LOADER_EXPORT___;
