import { links } from 'shared/constants/links'
import { Typography } from 'shared/ui'

import styles from './styles.module.scss'

export const Step4FormBottom = () => {
  return (
    <div className={styles.wrap}>
      <Typography variant={'body-sm-medium'} ariaLabel={'termsAndPolicy'}>
        {'I agree to receive text messages at the phone number provided, the  '}
        <a target={'_blank'} href={links.termsConditions} rel='noreferrer'>
          Terms of Service
        </a>
        <br />
        {'and '}
        <a target={'_blank'} href={links.privacyPolicy} rel='noreferrer'>
          Privacy Policy
        </a>
        . Msg & data rates may apply. Msg frequency varies. Reply HELP for
        <br />
        help and STOP to cancel.
      </Typography>
    </div>
  )
}
