import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import React from 'react'
import { ErrorPage } from 'shared/ui'
import { BroadcastRoutesEnum } from 'entities/Broadcast'
import { TriggerRouts } from 'entities/Trigger'
import { Sidebar } from 'widgets/Sidebar'
import { CallPopUp } from 'widgets/CallPopUp'
import { ConversationsRouters, ConversationsRoutes } from 'pages/conversations/router'
import { ContactsRouters } from 'pages/contacts'
import { AdminRoutesEnum } from 'pages/admin'
import AdminRoutes from 'pages/admin/route/adminRoutes'
import { BroadcastRoutes } from 'pages/broadcasts'
import { ChatbotRoute, ChatbotRoutes } from 'pages/chatbot'
import { TriggersRouter } from 'pages/triggers'
import Register from 'pages/register'
import AuthLayoutVue from 'app/layouts/authLayoutVue'
import RootLayout from 'app/layouts/rootLayout'
import AuthRefreshLayout from 'app/layouts/authRefreshLayout'
import CallPopUpLayoutLayout from 'app/layouts/callPopUpLayoutLayout'
import UnAuthLayoutVue from 'app/layouts/unAuthLayoutVue'

const routersContent = createRoutesFromElements(
  <Route element={<RootLayout />} errorElement={<ErrorPage />}>
    <Route element={<AuthRefreshLayout type={'vue'} />}>
      <Route element={<AuthLayoutVue />}>
        <Route
          path={`/${ConversationsRoutes.conversations}/*`}
          element={<ConversationsRouters />}
        />
        <Route path={`/${BroadcastRoutesEnum.broadcasts}/*`} element={<BroadcastRoutes />} />
        <Route path={`/${TriggerRouts.triggers}/*`} element={<TriggersRouter />} />;
        <Route path={`/${ChatbotRoute.root}/*`} element={<ChatbotRoutes />} />
        <Route path='/contacts/*' element={<ContactsRouters />} />
        <Route path={`/${AdminRoutesEnum.admin}/*`} element={<AdminRoutes />} />
        <Route path='/*' element={<div />} />
      </Route>
      <Route element={<UnAuthLayoutVue />}>
        <Route path={'/register'} element={<Register />} />
      </Route>
    </Route>
  </Route>
)

const routersSidebar = createRoutesFromElements(
  <Route element={<RootLayout />}>
    <Route element={<AuthLayoutVue type={'sidebar'} />}>
      <Route path='/*' element={<Sidebar />} />
    </Route>
  </Route>
)

const routersCallPopup = createRoutesFromElements(
  <Route element={<CallPopUpLayoutLayout />}>
    <Route path='/*' element={<CallPopUp />} />
  </Route>
)

export const appRouterContent = createBrowserRouter(routersContent)
export const appRouterSidebar = createBrowserRouter(routersSidebar)
export const appRouterCallPopup = createBrowserRouter(routersCallPopup)
