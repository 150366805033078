import { useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { uiStore } from 'shared/store/uiStore'
import { ChatbotRoutes } from 'entities/ExportRouters'
import { ChatbotInstructionType } from 'entities/Chatbot'
import { useChatbotContext } from 'pages/chatbot/context/chatbotContext'

export const AgentBookAppointmentAccessLayoutContent = observer(() => {
  const store = useChatbotContext()

  useLayoutEffect(() => {
    uiStore.changeRouteViaVue({
      path:
        store.model.type === ChatbotInstructionType.BookAppointment
          ? store.agentNavigationStore.getCurrentUrl
          : `/${ChatbotRoutes.root}`,
    })
  }, [store.model.type])

  return (
    <>
      <Outlet />
    </>
  )
})

export const AgentBookAppointmentAccessLayout = () => <AgentBookAppointmentAccessLayoutContent />
