import { observer } from 'mobx-react-lite'
import { Dropdown, Tooltip } from 'shared/ui'

interface IFilterChoiceProps {
  disabled?: boolean
  disabledReason?: React.ReactNode
  onChoose: (operator: 'and' | 'or') => void
  parentsDropdownId?: string[]
}

export const FilterChoice = observer(
  ({ disabled, disabledReason, onChoose, parentsDropdownId }: IFilterChoiceProps) => {
    const dropdown = (
      <Dropdown
        ariaLabel='AndOrContent'
        placement='bottom-start'
        disabled={disabled}
        parentsDropdownId={parentsDropdownId}
        iconButtonProps={{
          variant: 'contained',
          size: 'medium',
          fontSize: 16,
          ariaLabel: 'AndOrContent_trigger',
        }}
        tooltipProps={{
          label: 'Add filter',
          disableInteractive: true,
          placement: 'left',
        }}
        items={[
          {
            id: 'and',
            label: 'And',
            onClick: () => onChoose('and'),
          },
          {
            id: 'or',
            label: 'Or',
            onClick: () => onChoose('or'),
          },
        ]}
      />
    )

    if (disabled) return <Tooltip label={disabledReason}>{dropdown}</Tooltip>

    return dropdown
  }
)
