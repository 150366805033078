import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import type {
  IParamsUpdateVoiceCallBySidQualityLog,
  IParamsUpdateVoicemailDrop,
  IParamsVoiceCallBySidConference,
  IParamsVoiceCallBySidConferenceBySid,
  IParamsVoiceCallHoldUnhold,
  IParamsVoiceCallTransfer,
  IParamsVoiceConferenceBySidParticipant,
  IParamsVoiceRecordStatus,
  IResponseUpdateVoiceCallBySidQualityLog,
  IResponseUpdateVoicemailDrop,
  IResponseVoiceCallByCallSidConference,
  IResponseVoiceCallBySidConferenceBySid,
  IResponseVoiceCallCallEnd,
  IResponseVoiceCallHoldUnhold,
  IResponseVoiceCallTransfer,
  IResponseVoiceConferenceBySidEnd,
  IResponseVoiceConferenceBySidHold,
  IResponseVoiceConferenceBySidParticipant,
  IResponseVoiceConferenceBySidResume,
  IResponseVoiceToken,
} from 'entities/Call'

class Api {
  createVoiceToken(): Promise<AxiosResponse<IResponseVoiceToken>> {
    return baseApi.post('voice/token')
  }

  updateVoiceRecordStatus(params: IParamsVoiceRecordStatus): Promise<AxiosResponse<string>> {
    return baseApi.post('voice/record/status', params)
  }

  updateVoiceCallHold(
    params: IParamsVoiceCallHoldUnhold
  ): Promise<AxiosResponse<IResponseVoiceCallHoldUnhold>> {
    return baseApi.post('voice/call/hold', params)
  }

  updateVoiceCallUnhold(
    params: IParamsVoiceCallHoldUnhold
  ): Promise<AxiosResponse<IResponseVoiceCallHoldUnhold>> {
    return baseApi.post('voice/call/unhold', params)
  }

  updateVoiceCallTransfer(
    params: IParamsVoiceCallTransfer
  ): Promise<AxiosResponse<IResponseVoiceCallTransfer>> {
    return baseApi.post('voice/call/transfer', params)
  }

  updateVoiceCallByCallSidConference(
    callSid: string,
    params: IParamsVoiceCallBySidConference
  ): Promise<AxiosResponse<IResponseVoiceCallByCallSidConference>> {
    return baseApi.post(`voice/call/${callSid}/conference`, params)
  }

  updateVoiceCallBySidConferenceBySid(
    callSid: string,
    conferenceSid: string,
    params: IParamsVoiceCallBySidConferenceBySid
  ): Promise<AxiosResponse<IResponseVoiceCallBySidConferenceBySid>> {
    return baseApi.put(`voice/call/${callSid}/conference/${conferenceSid}`, params)
  }

  updateVoiceConferenceBySidParticipant(
    conferenceSid: string,
    params: IParamsVoiceConferenceBySidParticipant
  ): Promise<AxiosResponse<IResponseVoiceConferenceBySidParticipant>> {
    return baseApi.put(`voice/conference/${conferenceSid}/participant`, params)
  }

  updateVoiceConferenceBySidHold(
    conferenceSid: string
  ): Promise<AxiosResponse<IResponseVoiceConferenceBySidHold>> {
    return baseApi.put(`voice/conference/${conferenceSid}/hold`)
  }

  updateVoiceConferenceBySidResume(
    conferenceSid: string
  ): Promise<AxiosResponse<IResponseVoiceConferenceBySidResume>> {
    return baseApi.put(`voice/conference/${conferenceSid}/resume`)
  }

  updateVoiceConferenceBySidEnd(
    conferenceSid: string
  ): Promise<AxiosResponse<IResponseVoiceConferenceBySidEnd>> {
    return baseApi.put(`voice/conference/${conferenceSid}/end`)
  }

  updateVoiceCallCallEnd(callSid: string): Promise<AxiosResponse<IResponseVoiceCallCallEnd>> {
    return baseApi.post('voice/call/call-end', {
      call_sid: callSid,
    })
  }

  updateVoiceCallBySidQualityLog(
    callSid: string,
    params: IParamsUpdateVoiceCallBySidQualityLog
  ): Promise<AxiosResponse<IResponseUpdateVoiceCallBySidQualityLog>> {
    return baseApi.post(`voice/call/${callSid}/quality/log`, params)
  }

  updateVoicemailDrop(
    params: IParamsUpdateVoicemailDrop
  ): Promise<AxiosResponse<IResponseUpdateVoicemailDrop>> {
    return baseApi.post('voice/call/voicemail-drop', params)
  }
}

export const CallApi = new Api()
