import React from 'react'
import { ISelectDefaultProps, SelectDefault } from 'shared/ui/Select/SelectDefault/SelectDefault'
import { ISelectMultiProps, SelectMulti } from 'shared/ui/Select/SelectMulti/SelectMulti'

type ISelectProps<T> = ISelectDefaultProps<T> | ISelectMultiProps<T>

export const Select = <T,>(props: ISelectProps<T>) => {
  if (props.type === 'multi') {
    return <SelectMulti {...props} />
  }

  return <SelectDefault {...props} />
}
