// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l_MSz5oyyzx8zATofq9p{display:flex;align-items:center}.l_MSz5oyyzx8zATofq9p.ocDdoKwWFBN_6DASF9PC .GMr231zdWcg2pTIq8gN4,.l_MSz5oyyzx8zATofq9p.ocDdoKwWFBN_6DASF9PC .BLOtqespGXukQD7VH4QX{color:var(--content-primary-secondary)}.l_MSz5oyyzx8zATofq9p.h7jYhAvxIWcPPGsITZAi .GMr231zdWcg2pTIq8gN4,.l_MSz5oyyzx8zATofq9p.h7jYhAvxIWcPPGsITZAi .BLOtqespGXukQD7VH4QX{color:var(--content-negative-primary)}.l_MSz5oyyzx8zATofq9p.eGaVceUQ2UEhrPSMxfrW .GMr231zdWcg2pTIq8gN4,.l_MSz5oyyzx8zATofq9p.eGaVceUQ2UEhrPSMxfrW .BLOtqespGXukQD7VH4QX{color:var(--green-80)}.l_MSz5oyyzx8zATofq9p.PYmEpzbufED6nO2oURBu .GMr231zdWcg2pTIq8gN4,.l_MSz5oyyzx8zATofq9p.PYmEpzbufED6nO2oURBu .BLOtqespGXukQD7VH4QX{color:var(--red-80)}.BLOtqespGXukQD7VH4QX{font-size:16px;margin-right:4px}.GMr231zdWcg2pTIq8gN4{font-size:11px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/shared/ui/HelperText/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGE,kIACE,sCAAA,CAKF,kIACE,qCAAA,CAKF,kIACE,qBAAA,CAKF,kIACE,mBAAA,CAKN,sBACE,cAAA,CACA,gBAAA,CAGF,sBACE,cAAA,CACA,eAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n\n  &.default {\n    .text, .icon {\n      color: var(--content-primary-secondary);\n    }\n  }\n\n  &.alert {\n    .text, .icon {\n      color: var(--content-negative-primary);\n    }\n  }\n\n  &.success {\n    .text, .icon {\n      color: var(--green-80);\n    }\n  }\n\n  &.error {\n    .text, .icon {\n      color: var(--red-80);\n    }\n  }\n}\n\n.icon {\n  font-size: 16px;\n  margin-right: 4px;\n}\n\n.text {\n  font-size: 11px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "l_MSz5oyyzx8zATofq9p",
	"default": "ocDdoKwWFBN_6DASF9PC",
	"text": "GMr231zdWcg2pTIq8gN4",
	"icon": "BLOtqespGXukQD7VH4QX",
	"alert": "h7jYhAvxIWcPPGsITZAi",
	"success": "eGaVceUQ2UEhrPSMxfrW",
	"error": "PYmEpzbufED6nO2oURBu"
};
export default ___CSS_LOADER_EXPORT___;
