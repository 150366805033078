import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type AdminEdit10DLCExemptOptionsStore } from 'pages/admin/pages/organizations/modals/AdminEdit10DLCExemptOptions/store/adminEdit10DLCExemptOptionsStore'

type IActionsProps = {
  store: AdminEdit10DLCExemptOptionsStore
}

export const Admin10DLCExemptOptionsAction = observer(({ store }: IActionsProps) => (
  <Button
    typeBtn={'contained'}
    text={'Save'}
    onClick={store.onSubmit}
    loading={store.loadingSubmit}
  />
))
