import { makeAutoObservable, reaction, IReactionDisposer } from 'mobx'
import { bannerStore, EnumAlertBannerVariant } from 'shared/ui'
import { organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { BuyCreditsModalStore } from 'widgets/BuyCreditsModal'

export class ReminderCreditsStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _bannerId = 'add_credits'
  private _disposeAddCredits: IReactionDisposer | null = null
  private _buyCreditsModalStore = new BuyCreditsModalStore()

  get isOnTrial() {
    return subscriptionStore.isTrial
  }

  get isOutOfMoney() {
    const isActivePlanWithoutCredits =
      subscriptionStore.isPlanActive && !this.isOnTrial && organizationStore.accountCredits <= 0
    const isTrialPlanWithoutCredits = this.isOnTrial && organizationStore.trialCredits <= 0
    const isInactivePlanWithoutBalance =
      !subscriptionStore.isPlanActive && organizationStore.accountBalance <= 0
    return Boolean(
      isActivePlanWithoutCredits || isTrialPlanWithoutCredits || isInactivePlanWithoutBalance
    )
  }

  get noMessageSending() {
    return !this.isOnTrial && this.isOutOfMoney && organizationStore.isBillableNumberVendor
  }

  get isOrganizationOwner() {
    return organizationStore.isOrganizationOwner
  }

  get show() {
    return this.noMessageSending
  }

  init = () => {
    this.reactionAddCredits()
  }

  reactionAddCredits = () => {
    this._disposeAddCredits?.()
    this._disposeAddCredits = reaction(
      () => this.show,
      (value) => {
        if (value) {
          if (this.isOrganizationOwner) {
            bannerStore.add({
              id: this._bannerId,
              action: {
                text: 'Add message credits',
                onAction: () => {
                  this._buyCreditsModalStore.openModal()
                },
              },
              alert: {
                title:
                  'Your organization is out of message credits. Add message credits to continue sending and receiving messages.',
                variant: EnumAlertBannerVariant.Warning,
                ariaLabel: 'OutOfCredits',
              },
            })
          } else {
            bannerStore.add({
              id: this._bannerId,
              alert: {
                title:
                  'Your organization is out of message credits. Ask your organization owner to add message credits to your account.',
                variant: EnumAlertBannerVariant.Warning,
                ariaLabel: 'OutOfCredits',
              },
            })
          }
        } else {
          bannerStore.remove(this._bannerId)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }
}
