import classNames from 'classnames'
import styles from './styles.module.scss'
import { Typography } from '../Typography'
import { Checkbox } from '../Checkbox/Checkbox'
import type { TextStylesType } from '../Typography/Typography'

export type InputCheckboxProps = {
  checked: boolean
  onChecked: () => void
  label?: string
  customLabel?: JSX.Element
  className?: string
  color?: string
  variant?: TextStylesType
}

export const InputCheckbox = ({
  checked,
  onChecked,
  label = '',
  customLabel,
  className,
  color,
  variant = 'text-input-field',
}: InputCheckboxProps) => {
  return (
    <div className={classNames(styles.wrap, className)} onClick={onChecked}>
      <Checkbox checked={checked} />
      {customLabel && customLabel}
      {!customLabel && (
        <Typography variant={variant} ariaLabel={'label'} color={color}>
          {label}
        </Typography>
      )}
    </div>
  )
}
