import { MouseEvent, FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Icon, IDropdownItemProps, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export type IDropdownCardCheckedProps = IDropdownItemProps

export const DropdownCardCheckedLeft: FC<IDropdownCardCheckedProps> = observer(
  ({ onChange, item, parent, isHover }) => {
    const onClick = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()

      if (item.disabled) return
      if (item.children?.length) return

      item.onClick?.()
      onChange?.(item, parent)
    }
    const label = item.labelContent || item.label

    const renderActive = () => {
      const isActiveValueBoolean = typeof item.activeValue === 'boolean'

      if (isActiveValueBoolean && item.activeValue) {
        return (
          <div className={classnames(styles.icon, styles.active)}>
            <Icon icon={'check1'} />
          </div>
        )
      }

      if (item.noRenderEmptyActiveValue) {
        return null
      }

      return <div className={classnames(styles.icon__right, styles.leftChecked)} />
    }

    const renderRightContent = () => {
      return (
        <div
          className={classnames(styles.itemWrap, styles.rightContent, styles.rightContent__show)}
        >
          {item.labelRight && (
            <Typography
              tag={'div'}
              variant={'body-sm-regular'}
              className={styles.labelRight}
              ariaLabel={'labelRight'}
            >
              {item.labelRight}
            </Typography>
          )}

          {item.iconR && (
            <div className={styles.icon__right}>
              <Icon icon={item.iconR} />
            </div>
          )}

          {item.tooltipQuestionProps && (
            <div className={styles.tooltip}>
              <Tooltip {...item.tooltipQuestionProps}>
                <Icon icon={'question'} {...item.iconQuestionProps} />
              </Tooltip>
            </div>
          )}
        </div>
      )
    }

    return (
      <div
        className={classnames(styles.item, {
          [styles.item__disabled]: item.disabled,
          [styles['item--hover']]: isHover,
          ['hasDropdownCardChild']: parent,
        })}
        onClick={onClick}
      >
        <div className={styles.itemWrap}>
          {item.iconL && (
            <div className={styles.icon}>
              <Icon icon={item.iconL} />
            </div>
          )}
          {renderActive()}
          {label && <div className={styles.title}>{label}</div>}
        </div>
        {renderRightContent()}
      </div>
    )
  }
)
