import { computed, makeObservable } from 'mobx'
import { Base } from 'models'
import type { IResponseUser, IResponseUserNumber } from 'entities/Users/api/types'
import { EnumUserRoles } from 'entities/Users/model/types'

export class User extends Base {
  id: number
  first_name!: string
  last_name!: string
  full_name!: string
  photo_url!: string
  timezone?: string
  email!: string
  role!: EnumUserRoles
  number!: IResponseUserNumber | null
  formatted_number!: string
  item_type!: 'user' // TODO: need backend SWC-3483
  isAway = false
  createdAt = '' // TODO: need backend SWC-3483
  awayStatusLastDate = '' // TODO: need backend SWC-3483
  hasAccessibleOrganizations = false
  isRequirePftNumber = false
  isRequireNumber = false
  origin!: IResponseUser

  constructor(item: IResponseUser) {
    super()

    this.id = item.id
    this.syncOrigin(item)

    makeObservable(this, {
      isAdminOrOwnerRole: computed,
    })
  }

  syncOrigin = (origin: IResponseUser) => {
    this.id = origin.id
    this.first_name = origin.first_name
    this.last_name = origin.last_name
    this.full_name = origin.full_name
    this.photo_url = origin.photo_url
    this.timezone = origin.timezone || undefined
    this.email = origin.email
    this.role = origin.role
    this.number = origin.number || null
    this.formatted_number = origin.formatted_number || ''
    this.item_type = origin.item_type || 'user'
    this.origin = origin
  }

  get avatarInfo() {
    return {
      firstName: this.first_name,
      lastName: this.last_name,
      image: this.photo_url,
      color: this.color,
      phone: this.number?.formatted_number || this.formatted_number,
    }
  }

  get name() {
    let output = ''

    const { first_name, last_name, full_name, email } = this

    if (first_name) {
      output = first_name

      if (last_name) {
        output += ' ' + last_name
      }
    } else if (full_name) {
      output = full_name
    } else if (email) {
      output = email
    }

    return output
  }

  get color() {
    const palette = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']

    return palette[this.id % palette.length]
  }

  get activeFields() {
    return ['name', 'number', 'email', 'created_at']
  }

  get displayInfo() {
    const name = `${this.first_name || ''} ${this.last_name || ''}`.trim()
    return name || this.number?.formatted_number
  }

  get isOwnerRole() {
    return this.role === EnumUserRoles.OWNER
  }

  get isViewOnlyRole() {
    return this.role === EnumUserRoles.VIEW_ONLY
  }

  get isMemberRole() {
    return this.role === EnumUserRoles.MEMBER
  }

  get isManagerRole() {
    return this.role === EnumUserRoles.MANAGER
  }

  get isAdminRole() {
    return this.role === EnumUserRoles.ADMIN
  }

  get isAdminOrOwnerRole() {
    return this.isAdminRole || this.isOwnerRole
  }
}
