import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { AuthLayout } from 'shared/layout'
import { RegisterStore } from 'widgets/Register'
import { SignUpForm } from './SignUpForm/SignUpForm'
import { SignUpFormBottom } from './SignUpFormBottom/SignUpFormBottom'
import { RightContent } from './RightContent/RightContent'

type IStep1Props = {
  store: RegisterStore
}

export const StepSignUp: FC<IStep1Props> = observer(({ store }) => {
  return (
    <AuthLayout
      title='Start your 14-day free trial'
      subtitle='Get started with business texting and calling for free today'
      form={<SignUpForm store={store} />}
      rightContent={<RightContent />}
      formBottom={<SignUpFormBottom />}
      coupon={store.couponTitle}
    />
  )
})

export default StepSignUp
