// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TwptenW9BebVHmqBMzVj{position:relative;width:100%;display:flex;flex-direction:column;gap:4px}.TwptenW9BebVHmqBMzVj .xKbj3kE4f12KY1x5hozs{top:8px;position:absolute;left:192px}.TwptenW9BebVHmqBMzVj input{border:none;outline:none;padding:0;font-weight:500}.TwptenW9BebVHmqBMzVj input::placeholder{color:var(--content-neutral-primary);font-family:\"Inter\",sans-serif}.TwptenW9BebVHmqBMzVj.YqcUoGIWtfh5jt5EhDS_ input{height:32px;font-size:24px}.TwptenW9BebVHmqBMzVj.RIGFsPEzgnfgkC_PtD6r input{width:100%;height:40px;font-size:16px;letter-spacing:-0.24px}", "",{"version":3,"sources":["webpack://./src/shared/ui/TextInput/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,4CACE,OAAA,CACA,iBAAA,CACA,UAAA,CAEF,4BACE,WAAA,CACA,YAAA,CACA,SAAA,CACA,eAAA,CAEA,yCACE,oCAAA,CACA,8BAAA,CAKF,iDACE,WAAA,CACA,cAAA,CAIF,iDACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,sBAAA","sourcesContent":["\n.input {\n  position: relative;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  .emptyError{\n    top: 8px;\n    position: absolute;\n    left: 192px;\n  }\n  input {\n    border: none;\n    outline: none;\n    padding: 0;\n    font-weight: 500;\n\n    &::placeholder {\n      color: var(--content-neutral-primary);\n      font-family: 'Inter', sans-serif;\n    }\n  }\n\n  &.large{\n    input {\n      height: 32px;\n      font-size: 24px;\n    }\n  }\n  &.medium{\n    input {\n      width: 100%;\n      height: 40px;\n      font-size: 16px;\n      letter-spacing: -0.24px;\n    }\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "TwptenW9BebVHmqBMzVj",
	"emptyError": "xKbj3kE4f12KY1x5hozs",
	"large": "YqcUoGIWtfh5jt5EhDS_",
	"medium": "RIGFsPEzgnfgkC_PtD6r"
};
export default ___CSS_LOADER_EXPORT___;
