import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useKeyGenerator } from 'shared/hooks'
import { IIconsVector } from 'shared/ui/Icon/icons'
import { IChatbotActionModel, ChatbotActionType, IChatbotActionType } from 'entities/Chatbot'
import { ChatbotAction, IChatbotActionStores } from 'pages/chatbot/store/chatbotActionStore'

import { IChatbotActionProps } from './type'
import { ActionCard } from './ActionCard'
import { ChatbotActionAdd } from './ActionCardAdd'
import { ActionMessage } from './ActionMessage'
import { makeActionTags } from './ActionTags'
import { ActionNote } from './ActionNote'

import styles from './styles.module.scss'

export interface IChatbotActionsProps {
  actions: ChatbotAction
  emptyPlaceholder?: string
}

type ActionConfig = {
  icon: IIconsVector
  title: string
  Component: FC<IChatbotActionProps<IChatbotActionStores>>
}

const ACTIONS_CONFIG = {
  [ChatbotActionType.AddTag]: {
    icon: 'tag',
    title: 'Add tag',
    Component: makeActionTags({
      canCreate: true,
      placeholder: 'Search or create',
    }),
  },
  [ChatbotActionType.RemoveTag]: {
    icon: 'tag',
    title: 'Remove tag',
    Component: makeActionTags({
      canCreate: false,
      placeholder: 'Search',
    }),
  },
  [ChatbotActionType.ContactNote]: {
    icon: 'edit',
    title: 'Contact note',
    Component: ActionNote,
  },
  [ChatbotActionType.SendMessage]: {
    icon: 'messageTextCircle02',
    title: 'Send message',
    Component: ActionMessage,
  },
} as Record<IChatbotActionType, ActionConfig>

export const ChatbotActions = observer(({ actions, emptyPlaceholder }: IChatbotActionsProps) => {
  const getActionKey = useKeyGenerator<IChatbotActionModel>()

  const handleDelete = (index: number, action: IChatbotActionModel) => {
    actions.removeAction(index)
    actions.actionErrors.removeError(action)
  }

  return (
    <div className={styles.actionCards}>
      <div className={styles.actionCardsList}>
        {!!actions.list.length ? (
          actions.list.map((action, index) => {
            const { icon, title, Component } = ACTIONS_CONFIG[action.type]

            return (
              <ActionCard
                key={getActionKey(action)}
                icon={icon}
                title={title}
                onDelete={() => handleDelete(index, action)}
              >
                <Component
                  control={actions.getActionStore(action)}
                  errors={actions.actionErrors}
                  action={action}
                />
              </ActionCard>
            )
          })
        ) : (
          <>
            {!!emptyPlaceholder && (
              <div className={styles.actionCardsEmpty}>{emptyPlaceholder}</div>
            )}
          </>
        )}
      </div>
      <ChatbotActionAdd actions={actions} />
    </div>
  )
})
