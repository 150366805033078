import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { MouseEvent, useState } from 'react'
import { Button, IconButton, Typography } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalType } from 'shared/ui/Modal/store/types'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export const DefaultModal = observer(({ modal, hide }: { modal: ModalType; hide: boolean }) => {
  const { setIsHoverModal } = modalStore
  const {
    position,
    width = 480,
    id,
    title,
    subtitle,
    primaryAction,
    ModalError,
    ModalAlert,
    ModalBottomError,
    ModalActions,
    ModalContent,
    ModalContentProps,
    showCloseButton = true,
    closeText = 'Cancel',
    showCloseIcon = true,
    showHeader = true,
    pureContent,
    paddingTopModal,
    paddingContent,
    fullHeight,
  } = modal

  const [loadingPrimary, setLoadingPrimary] = useState(false)

  const onClose = () => {
    modalStore.closeModal(id)
  }
  const onMouseEnter = () => {
    setIsHoverModal(true)
  }
  const onMouseLeave = () => {
    setIsHoverModal(false)
  }

  const showActionsBlock = showCloseButton || primaryAction || ModalActions
  const ariaLabel = 'DefaultModal'

  const handlePrimary = async (e: MouseEvent<HTMLButtonElement>) => {
    if (primaryAction?.onAction) {
      try {
        setLoadingPrimary(true)
        await primaryAction?.onAction(e)
      } finally {
        setLoadingPrimary(false)
      }
    }
  }

  return (
    <div
      className={classNames(
        styles.wrap,
        position && styles[position],
        fullHeight && styles.fullHeight
      )}
      style={{
        ...(paddingTopModal ? { padding: paddingTopModal } : null),
      }}
      aria-label={getAriaLabel(ariaLabel, 'content')}
    >
      <div
        className={classNames(styles.card)}
        style={{ width, visibility: hide ? 'hidden' : 'unset' }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        aria-label={getAriaLabel(ariaLabel, 'card')}
      >
        {showHeader && (
          <div className={styles.header} aria-label={getAriaLabel(ariaLabel, 'header')}>
            <div className={styles.titleWrapper}>
              {title && (
                <div className={styles.title} aria-label={getAriaLabel(ariaLabel, 'header_title')}>
                  <Typography variant={'body-xs-regular'} ariaLabel={'title'}>
                    {title}
                  </Typography>
                </div>
              )}
              {subtitle && (
                <Typography
                  className={styles.subtitle}
                  variant={'body-xs-regular'}
                  ariaLabel={'subtitle'}
                  color='tertiary'
                >
                  {subtitle}
                </Typography>
              )}
            </div>
            {showCloseIcon && (
              <IconButton
                icon={'close'}
                size={'small'}
                color={'secondary'}
                variant={'icon'}
                onClick={onClose}
                ariaLabel='DefaultModal_close'
              />
            )}
          </div>
        )}
        {ModalAlert && (
          <div
            className={styles.alertContainer}
            aria-label={getAriaLabel(ariaLabel, 'alertContainer')}
          >
            <ModalAlert {...ModalContentProps} />
          </div>
        )}

        {ModalError && (
          <div
            className={styles.errorContainer}
            aria-label={getAriaLabel(ariaLabel, 'errorContainer')}
          >
            <ModalError {...ModalContentProps} />
          </div>
        )}

        {pureContent ? (
          <>
            {ModalContent && <ModalContent {...ModalContentProps} />}
            {ModalBottomError && (
              <div
                className={styles.errorContainer}
                aria-label={getAriaLabel(ariaLabel, 'errorContainer')}
              >
                <ModalBottomError {...ModalContentProps} />
              </div>
            )}
          </>
        ) : (
          <>
            <div
              className={styles.content}
              style={{
                ...(paddingContent ? { padding: paddingContent } : null),
              }}
              aria-label={getAriaLabel(ariaLabel, 'content')}
            >
              {ModalContent && <ModalContent {...ModalContentProps} />}
            </div>
            {ModalBottomError && (
              <div
                className={styles.errorContainer}
                aria-label={getAriaLabel(ariaLabel, 'errorContainer')}
              >
                <ModalBottomError {...ModalContentProps} />
              </div>
            )}
            {showActionsBlock && (
              <div className={styles.actions} aria-label={getAriaLabel(ariaLabel, 'actions')}>
                {showCloseButton && (
                  <Button
                    typeBtn={'contained'}
                    text={closeText}
                    size={'medium'}
                    contained={'secondary'}
                    onClick={onClose}
                  />
                )}
                {primaryAction && (
                  <Button
                    typeBtn={'contained'}
                    text={primaryAction.text}
                    disabled={Boolean(primaryAction.disabled)}
                    fullWidth={primaryAction.fullWidth}
                    onClick={handlePrimary}
                    loading={loadingPrimary}
                  />
                )}
                {ModalActions && <ModalActions {...ModalContentProps} />}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
})
