import { Route, Routes } from 'react-router'
import { PeopleToContact } from 'pages/settings/pages/compliance/pages/localNumbers/pages/PeopleToContact/PeopleToContact'
import { LocalNumbersStepsLayout } from 'pages/settings/pages/compliance/pages/localNumbers/layout/LocalNumbersStepsLayout'
import { LocalNumbersRoutes } from 'pages/settings/pages/compliance/pages/localNumbers/route/type'
import { CampaignUseCase } from 'pages/settings/pages/compliance/pages/localNumbers/pages/CampaignUseCase/ui/CampaignUseCase'
import { Messages } from 'pages/settings/pages/compliance/pages/localNumbers/pages/Messages/ui/Messages'
import { OptInMethod } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OptInMethod/ui/OptInMethod'
import { OptInOnlineFormContent } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OptInContent/ui/OptInOnlineFormContent'
import { OptInPaperFormContent } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OptInContent/ui/OptInPaperFormContent'
import { OptInViaTextContent } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OptInContent/ui/OptInViaTextContent'
import { OptInQrCodeFormContent } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OptInContent/ui/OptInQrCodeFormContent'
import { ForbiddenMessageTopics } from 'pages/settings/pages/compliance/pages/localNumbers/pages/ForbiddenMessageTopics/ui/ForbiddenMessageTopics'
import { Package } from 'pages/settings/pages/compliance/pages/localNumbers/pages/Package/ui/Package'
import { OrderSummary } from 'pages/settings/pages/compliance/pages/localNumbers/pages/OrderSummary/ui/OrderSummary'
import { LocalNumbersAccessLayout } from 'pages/settings/pages/compliance/pages/localNumbers/layout/LocalNumbersAccessLayout'
import { BusinessInfo } from 'pages/settings/pages/compliance/pages/localNumbers/pages/BusinessInfo/BusinessInfo'
import { GeneralInfo } from 'pages/settings/pages/compliance/pages/localNumbers/pages/GeneralInfo/GeneralInfo'

export const LocalNumbersStepsRouter = () => {
  return (
    <Routes>
      <Route element={<LocalNumbersStepsLayout />}>
        <Route element={<LocalNumbersAccessLayout />}>
          <Route path={LocalNumbersRoutes.generalInfo} element={<GeneralInfo />} />
          <Route path={LocalNumbersRoutes.businessInfo} element={<BusinessInfo />} />
          <Route path={LocalNumbersRoutes.peopleToContact} element={<PeopleToContact />} />

          <Route path={LocalNumbersRoutes.campaignUseCases} element={<CampaignUseCase />} />
          <Route path={LocalNumbersRoutes.messages} element={<Messages />} />
          <Route path={LocalNumbersRoutes.optInMethod} element={<OptInMethod />} />
          <Route
            path={LocalNumbersRoutes.optInOnlineFormContent}
            element={<OptInOnlineFormContent />}
          />
          <Route
            path={LocalNumbersRoutes.optInPaperFormContent}
            element={<OptInPaperFormContent />}
          />
          <Route path={LocalNumbersRoutes.optInViaTextContent} element={<OptInViaTextContent />} />
          <Route
            path={LocalNumbersRoutes.optInQrCodeContent}
            element={<OptInQrCodeFormContent />}
          />
          <Route
            path={LocalNumbersRoutes.forbiddenMessageTopics}
            element={<ForbiddenMessageTopics />}
          />
          <Route path={LocalNumbersRoutes.package} element={<Package />} />
          <Route path={LocalNumbersRoutes.orderSummary} element={<OrderSummary />} />

          <Route path='*' element={<div>Page Not Found</div>} />
        </Route>
      </Route>
    </Routes>
  )
}
