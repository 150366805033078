import { FilterItem } from 'widgets/FilterEditor/model'

export const CheckboxType = new Set([
  'tag',
  'country',
  'keyword',
  'opt_in',
  'segments',
  'select',
  'source',
  'time_zone',
  'shortcode',
  'state',
  'lookup_type',
  'message_status',
  'state_code',
  'is_invalid',
  'select_without_checkbox',
  'inbox',
])

export const isMultiSelectWidget = (filterItem: FilterItem) =>
  filterItem.config.hasOwnProperty('values') && CheckboxType.has(filterItem.type)
