import { injectStores } from '@mobx-devtools/tools'
import { dropDownStore } from 'shared/ui/Dropdown/store/dropdown'
import { uiStore } from 'shared/store/uiStore'
import { messageStore } from 'entities/Message'
import { usersStore } from 'entities/Users'
import { inboxesStore } from 'entities/Inbox'
import { conversationStore } from 'entities/Conversation'
import { authStore } from 'entities/Auth'
import { contactsStore } from 'entities/Contacts'
import { sidebarStore } from 'widgets/Sidebar'

injectStores({
  messageStore,
  usersStore,
  inboxesStore,
  authStore,
  sidebarStore,
  conversationStore,
  contactsStore,
  dropDownStore,
  uiStore,
})
