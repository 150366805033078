import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ITabItem, Tabs } from 'shared/ui'
import { AdminRoutesEnum } from 'pages/admin'
import { adminComplianceStore } from 'pages/admin/pages/compliance/store/adminComplianceStore'

export const ComplianceTabs = observer(() => {
  const { dlcCount, tollFreeCount, loadData } = adminComplianceStore
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    loadData()
  }, [])

  const tabs: ITabItem[] = [
    {
      name: '10DLC',
      key: `/${AdminRoutesEnum.admin}/${AdminRoutesEnum.compliance}/${AdminRoutesEnum.tenDLC}`,
      count: dlcCount,
    },
    {
      name: 'Toll-free',
      key: `/${AdminRoutesEnum.admin}/${AdminRoutesEnum.compliance}/${AdminRoutesEnum.tollFree}`,
      count: tollFreeCount,
    },
  ]

  const handleSelectTab = (tab: ITabItem) => {
    navigate(tab.key)
  }

  return (
    <Tabs
      marginInlineTab={16}
      tabs={tabs}
      activeTabKey={pathname}
      handleSelectTab={handleSelectTab}
    />
  )
})
