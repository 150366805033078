// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nWU0219EvcOV1QzUGJzb{text-decoration:underline;text-decoration-thickness:1.5px;text-underline-offset:2px;color:var(--interaction-brand-active);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsHistory/helpers/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,yBAAA,CACA,+BAAA,CACA,yBAAA,CACA,qCAAA,CACA,cAAA","sourcesContent":[".clickableValue {\n    text-decoration: underline;\n    text-decoration-thickness: 1.5px;\n    text-underline-offset: 2px;\n    color: var(--interaction-brand-active);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickableValue": "nWU0219EvcOV1QzUGJzb"
};
export default ___CSS_LOADER_EXPORT___;
