import { DetailsTriggerFooter } from 'widgets/TriggerActions/ui/TriggerDetailsModal/ui/DetailsTriggerFooter/DetailsTriggerFooter'
import { DetailsTriggerHeader } from 'widgets/TriggerActions/ui/TriggerDetailsModal/ui/DetailsTriggerHeader/DetailsTriggerHeader'
import { DetailsTriggerBody } from 'widgets/TriggerActions/ui/TriggerDetailsModal/ui/DetailsTriggerBody/DetailsTriggerBody'
import styles from './styles.module.scss'

export const DetailsTriggerModalContent = () => {
  return (
    <div className={styles.wrap}>
      <DetailsTriggerHeader />
      <DetailsTriggerBody />
      <div className={styles.footer}>
        <DetailsTriggerFooter />
      </div>
    </div>
  )
}
