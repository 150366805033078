import { makeAutoObservable, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { toastStore } from 'shared/ui'
import { inboxesStore } from 'entities/Inbox'
import { IUser, usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { filtersStore } from 'features/DropdownFilter'
import { ConversationListStore } from 'widgets/ConversationList'
import { ConversationAssignModalStore } from 'widgets/ConversationAssignModal'

export class ConversationAssignDropdownStore {
  private _search = ''
  private _conversationId: number | null = null
  private _activeUserId: number | null = null
  private _itemsMap: Map<number, IUser> = new Map()
  private _isBulk = false
  private _conversationAssignModalStore = new ConversationAssignModalStore()

  constructor(private _conversationListStore: ConversationListStore) {
    makeAutoObservable(this)

    this.reactionConversationId()
    this._conversationAssignModalStore.setConfig({
      conversationListStore: this._conversationListStore,
    })
  }

  get isSearchEmpty() {
    return this._search === ''
  }

  get list() {
    return Array.from(this._itemsMap.values())
  }

  get isEmptyFilteredList() {
    return this.filteredListCount === 0
  }

  get currentConversation() {
    if (!this._conversationId) return null

    const conversation = conversationStore.getItem(this._conversationId)
    if (!conversation) return null

    return conversation
  }

  get filteredListCount() {
    return this.filteredList.length
  }

  get filteredList() {
    if (this.isSearchEmpty) {
      return this.list
    }

    return this.list.filter((member) =>
      member.name.toLowerCase().includes(this._search.toLowerCase())
    )
  }

  onSearch = (value: string) => {
    this._search = value
  }

  onReassign = async (id: number | null) => {
    if (id === this._activeUserId || !this._conversationId) return
    await conversationStore.reassign(this._conversationId, id)
    this.refresh(this._conversationId)
  }

  onReassignBulk = async (ids: number[]) => {
    if (!this.currentConversation) return

    await conversationStore.reassignBulk(
      this._conversationListStore.selectedListIds,
      ids,
      this.currentConversation.inbox_id,
      this._conversationListStore.isSelectedAll,
      filtersStore.filter?.key
    )
    this._conversationListStore.handleResetSelect()
    if (this._conversationId) this.refresh(this._conversationId)
  }

  reset = () => {
    this._search = ''
    this._activeUserId = null
    this._itemsMap.clear()
    this._conversationId = null
  }

  refresh = (value: number) => {
    this._search = ''
    this._activeUserId = null
    this._itemsMap.clear()

    const conversation = conversationStore.getItem(value)
    if (!conversation) return null

    this._activeUserId = conversation.user_id

    const inbox = inboxesStore.getItem(conversation.inbox_id)
    if (!inbox) return null

    if (inbox.type === 'inbox') {
      inbox.memberIds.forEach((id) => {
        const member = usersStore.getItem(id)
        if (!member || member.isViewOnlyRole) return
        this._itemsMap.set(id, member)
      })
    }
  }

  setConversationId = (value: number) => {
    this._conversationId = value
  }

  setIsBulk = (value: boolean) => {
    this._isBulk = value
  }

  handleUpdateAssign = async (id: number | null) => {
    const member = usersStore.getItem(id)
    const nanoId = nanoid()

    const assignTimeoutId = setTimeout(async () => {
      if (this._isBulk) {
        this.onReassignBulk(id ? [id] : [])
      } else {
        this.onReassign(id)
      }
      toastStore.remove(nanoId)
    }, 5000)

    const title = this._isBulk
      ? 'Assignment complete'
      : id
      ? `Conversation assigned to ${member?.name}`
      : 'Conversation unassigned'

    toastStore.add({
      id: nanoId,
      title,
      type: 'info',
      action: {
        text: 'Undo',
        onAction: () => {
          clearTimeout(assignTimeoutId)
          toastStore.remove(nanoId)
        },
      },
    })
  }

  reactionConversationId = () => {
    reaction(
      () => this._conversationId,
      (value) => {
        if (!value) return
        this.refresh(value)
      }
    )
  }

  get activeUserId() {
    return this._activeUserId
  }

  get search() {
    return this._search
  }

  get conversationAssignModalStore() {
    return this._conversationAssignModalStore
  }
}
