import { AxiosResponse } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { ISortOrder } from 'shared/api'
import { RequestLoadingStatus } from 'shared/store/RequestLoadingStatus'
import { TableStore } from 'shared/ui'
import { IParamsNumbers, IResponseNumber, IResponseNumbers } from 'entities/Phone/api/types'
import { NumberTableModel } from 'pages/settings/pages/numbers/ui/NumbersTable/model/NumberTableModel'

type IFieldNames = 'nationalNumber' | 'inboxName' | 'status' | 'labelType'

export class NumberTableStore {
  private _status = new RequestLoadingStatus()
  private _numbers = new Map<number, NumberTableModel>()
  private _tableStore: TableStore<NumberTableModel>
  private _sortBy: Null<IFieldNames> = null
  private _sortOrder: ISortOrder = 'desc'

  constructor(
    private readonly _storeKey: string,
    private readonly _fetchNumbers: (
      params: IParamsNumbers
    ) => Promise<AxiosResponse<IResponseNumbers>>
  ) {
    this._tableStore = new TableStore<NumberTableModel>({
      element: 'number',
      saveColumns: this._saveColumns,
      getColumns: this._getColumns(),
    })
    this.init()
    makeAutoObservable(this)
  }

  init() {
    this._getNumbersData()
  }

  dispose = () => {}

  private _saveColumns = async (columns: string[]) => {
    localStorage.setItem(this._storeKey, JSON.stringify(columns))
  }
  private _getColumns = async () => {
    const stringColumnData = localStorage.getItem(this._storeKey)
    return stringColumnData ? JSON.parse(stringColumnData) : undefined
  }

  private _setNumber = (data: IResponseNumber) => {
    runInAction(() => {
      this._numbers.set(data.id, new NumberTableModel(data))
    })
  }

  private _getNumbersData = () =>
    this._status.loadData(async () => {
      const { data } = await this._fetchNumbers({
        page: 1,
        limit: 500,
      })

      data.data.forEach((item) => {
        if (item.id) {
          this._setNumber(item)
        }
      })
    })

  onSort = (field: string | number) => {
    this._tableStore.onSort(field)
    runInAction(() => {
      this._sortBy = this._tableStore.sortBy as IFieldNames
      this._sortOrder = this._tableStore.sortOrder
    })
  }

  get numbers() {
    const rawData = Array.from(this._numbers.values())
    const sortBy = this._sortBy
    if (sortBy) {
      return rawData.sort((a: NumberTableModel, b: NumberTableModel) => {
        if (this._sortOrder === 'desc') {
          return a[sortBy]! < b[sortBy]! ? 1 : -1
        } else {
          return a[sortBy]! > b[sortBy]! ? 1 : -1
        }
      })
    }
    return rawData
  }

  get status() {
    return this._status.status
  }

  get tableStore() {
    return this._tableStore
  }
}
