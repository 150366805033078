import React from 'react'
import { BoxIcon, Tooltip, IIconsVector } from 'shared/ui'
import { ITriggerType } from 'entities/Trigger/api/type'

const config: Record<ITriggerType, { icon: IIconsVector; label: string }> = {
  sms: {
    icon: 'messageSmile' as IIconsVector,
    label: 'SMS',
  },
  ringless: {
    icon: 'voicemail2' as IIconsVector,
    label: 'Ringless',
  },
}

export const TriggerType = ({ type }: { type: ITriggerType }) => (
  <Tooltip label={config[type]?.label} placement={'top'}>
    <BoxIcon icon={config[type]?.icon} tertiary />
  </Tooltip>
)
