import classnames from 'classnames'
import { ReactNode } from 'react'
import { CopyWrapper } from 'shared/ui'
import { ContactRow } from './ContactRow'

import styles from '../styles.module.scss'

type IRowData = {
  key: string
  value: string | null
  label: string | null
}

type IContactTabProps = {
  title: string | null
  data: IRowData[] | null
  link: string
  actions?: ReactNode
}

const EmptyData = () => <div className={styles.emptyData}>No properties found</div>

const ContactTab = ({ data, link, actions, title }: IContactTabProps) => {
  if (!data) return <EmptyData />

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={title || null}
          data={{
            title: title || 'View contact',
            link,
          }}
        />
      </div>
      {data.map(({ key, value, label }) => {
        return <ContactRow key={key} keyValue={key} value={value} label={label} />
      })}
      {actions && actions}
    </div>
  )
}

export { ContactTab }
