// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SN4xJDfeDmImjj5RXo90:not(.sguuLEx3Cp5He1UwL56V):hover>:first-child{background:var(--background-neutral-primary)}.nrLKAx19xspV2QPVQJ7p{position:relative;cursor:pointer;border:none;max-width:100%;border-radius:4px;background:var(--background-primary-inverted-primary);min-height:52px;padding:0}._1b__6um3LqB0_pGyiuA{width:100%;max-width:288px}._1b__6um3LqB0_pGyiuA.cSF5TbqC3kJ9HZD2MRJI{max-width:242px}.jx9952AfC8sKQrm4SB2F{max-width:220px;width:max-content}.xPiTsfrXtDMQIJ6bHPJA{width:max-content}.cx_F3WYTFHzbRFnQsAKX{padding-top:8px}.y1GzWEGq4v85FVE_vypH{max-width:312px;display:grid;gap:4px}", "",{"version":3,"sources":["webpack://./src/entities/Attachment/ui/AttachmentCard/styles.module.scss"],"names":[],"mappings":"AAAA,oEACE,4CAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,qDAAA,CACA,eAAA,CACA,SAAA,CAGF,sBACE,UAAA,CACA,eAAA,CAEA,2CACE,eAAA,CAIJ,sBACE,eAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,eAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".commonWrap:not(.noteMode):hover > :first-child {\n  background: var(--background-neutral-primary);\n}\n\n.card {\n  position: relative;\n  cursor: pointer;\n  border: none;\n  max-width: 100%;\n  border-radius: 4px;\n  background: var(--background-primary-inverted-primary);\n  min-height: 52px;\n  padding: 0;\n}\n\n.audio {\n  width: 100%;\n  max-width: 288px;\n\n  &.conversationsHistoryView {\n    max-width: 242px;\n  }\n}\n\n.attachment {\n  max-width: 220px;\n  width: max-content;\n}\n\n.vcard {\n  width: max-content;\n}\n\n.wrapPlayer {\n  padding-top: 8px;\n}\n.videoAttachment {\n  max-width: 312px;\n  display: grid;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commonWrap": "SN4xJDfeDmImjj5RXo90",
	"noteMode": "sguuLEx3Cp5He1UwL56V",
	"card": "nrLKAx19xspV2QPVQJ7p",
	"audio": "_1b__6um3LqB0_pGyiuA",
	"conversationsHistoryView": "cSF5TbqC3kJ9HZD2MRJI",
	"attachment": "jx9952AfC8sKQrm4SB2F",
	"vcard": "xPiTsfrXtDMQIJ6bHPJA",
	"wrapPlayer": "cx_F3WYTFHzbRFnQsAKX",
	"videoAttachment": "y1GzWEGq4v85FVE_vypH"
};
export default ___CSS_LOADER_EXPORT___;
