import { observer } from 'mobx-react-lite'

import styles from './styles.module.scss'
import { ChatbotType } from './ChatbotType'
import { ChatbotName } from './ChatbotName'

export const ChatbotHeader = observer(() => (
  <section className={styles.header}>
    <ChatbotName />
    <ChatbotType />
  </section>
))
