import React from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, IconButton } from 'shared/ui'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'
import styles from './styles.module.scss'

export const ConversationSearchItems = observer(() => {
  const { items, handleDeleteItem } = useConversationNewSearchContext()

  return (
    <>
      {items.map((item) => {
        return (
          <div key={item.id} className={styles.item}>
            <div className={styles.item__avatar}>
              <Avatar size={16} fontSize={10} info={item.avatarInfo} />
            </div>
            <div className={styles.item__name}>{item.name}</div>
            <div
              className={styles.item__delete}
              onClick={() => {
                handleDeleteItem(item)
              }}
            >
              <IconButton
                icon={'close'}
                color={'transparent'}
                size={'small'}
                fontSize={16}
                ariaLabel='ConversationSearchItems_delete'
              />
            </div>
          </div>
        )
      })}
    </>
  )
})
