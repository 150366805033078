import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'

import styles from './styles.module.scss'

export const DetailsNotFound = observer(() => (
  <section className={styles.notFoundContainer}>
    <Typography ariaLabel='textbotNotFound' variant='body-lg-regular'>
      Textbot not found
    </Typography>
  </section>
))
