import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownCard, IDropdownItem } from 'shared/ui'
import { tooltipText } from 'shared/constants/tooltipText'
import { inboxesStore } from 'entities/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'

type IMakeCallActionDropdownItemProps = {
  onClick?: () => void
}

export const MakeCallActionDropdownItem: FC<IMakeCallActionDropdownItemProps> = observer(
  ({ onClick }) => {
    const inteliquentCalling = featureFlagsStore.inteliquent_calling
    const isPFTInbox = inboxesStore.currentInbox?.isPFTInbox
    const phoneType = inboxesStore.nonPFTInboxNumberType

    const item: IDropdownItem = {
      id: 'make_call',
      iconL: 'phone',
      hotKey: 'C',
      label: 'Make a call',
      disabled: isPFTInbox && !inteliquentCalling,
      tooltipProps:
        isPFTInbox && !inteliquentCalling
          ? {
              label: tooltipText.disabledCall(phoneType),
              placement: 'right',
              fullWidth: true,
            }
          : undefined,
    }

    return (
      <DropdownCard
        item={item}
        onChange={() => {
          onClick?.()
        }}
      />
    )
  }
)
