import type { IStatusProps } from 'shared/ui/Status'

// draft
// failed
// in_progress
// pause
// recurring_template
// scheduled
// sent
// skipped
// stopped

const statuses = {
  scheduled: {
    title: 'Scheduled',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
  in_progress: {
    title: 'Sending',
    icon: 'pause',
    emphasis: 'high',
    intent: 'informative2',
  } as IStatusProps,
  pause: {
    title: 'Paused',
    icon: 'play',
    emphasis: 'high',
    intent: 'orange',
  } as IStatusProps,
  sent: {
    title: 'Finished',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
  stopped: {
    title: 'Finished',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
  draft: {
    title: 'Draft',
    icon: 'draft',
    emphasis: 'high',
    intent: 'neutral',
  } as IStatusProps,
  failed: {
    title: 'Failed',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  skipped: {
    title: 'Skipped',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,

  active: {
    title: 'Active',
    icon: 'check2',
    emphasis: 'high',
    intent: 'informative2',
  } as IStatusProps,
  inactive: {
    title: 'Inactive',
    icon: 'minusCircle',
    emphasis: 'high',
    intent: 'neutral',
  } as IStatusProps,
  finished: {
    title: 'Finished',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
}

export type IStatusesKeys = keyof typeof statuses

export default statuses
