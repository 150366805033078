import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import type { IToast, IToastShow } from 'shared/ui'

class ToastStore {
  itemsMap: Map<string, IToast> = new Map()
  timer = 5000
  limit = 5

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.itemsMap.clear()
  }

  init = () => {}

  add = (data: IToastShow) => {
    if (this.itemsMap.size >= this.limit) {
      this.remove(this.items[0].id)
    }

    const item: IToast = {
      id: nanoid(),
      timer: this.timer,
      ...data,
    }

    this.itemsMap.set(item.id, item)
  }

  remove = (id: string) => {
    this.itemsMap.delete(id)
  }

  get items() {
    return Array.from(this.itemsMap.values())
  }
}

export const toastStore = new ToastStore()
