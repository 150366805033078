import { FC } from 'react'
import { Button } from 'shared/ui'
import { type Contact } from 'entities/Contacts/model/Contact'

type IEmailActionProps = {
  contact?: Contact
  disabled: boolean
}

export const EmailAction: FC<IEmailActionProps> = ({ contact, disabled }) => {
  if (!contact) return null

  return (
    <a href={contact.email ? `mailto:${contact.email}` : '#'}>
      <Button
        size='medium'
        typeBtn='contained'
        contained='secondary'
        icon='email'
        text='Email'
        disabled={disabled || !contact.email}
        tooltipProps={
          !contact.email
            ? {
                label: 'Add an email address to enable email sending.',
                placement: 'bottom',
                width: 169,
              }
            : undefined
        }
      />
    </a>
  )
}
