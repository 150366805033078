import { FC } from 'react'
import { Radio as RadioMu, RadioProps } from '@mui/material'
import { Icon, IIconsVector } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'

const sizer = {
  small: {
    display: 'flex',
    height: '16px',
    width: '16px',
    padding: '0px',
  },
  medium: {
    display: 'flex',
    height: '20px',
    width: '20px',
    padding: '0px',
  },
  large: {
    display: 'flex',
    height: '32px',
    width: '32px',
    padding: '0px',
  },
}

type IRadioSize = 'small' | 'medium' | 'large'
type IRadioProps = Omit<RadioProps, 'size'> & {
  size?: IRadioSize
  onChangeValue?: (value: boolean) => void
}
type IIconWrapProps = {
  icon: IIconsVector
  size: number
}

const IconWrap: FC<IIconWrapProps> = ({ icon, size }) => {
  return <Icon icon={icon} fontSize={size} />
}

export const Radio = (props: IRadioProps) => {
  const { size = 'medium', onChangeValue, ...otherProps } = props
  const ariaLabel = 'Radio'
  const sizePx = size === 'small' ? 14 : 18

  return (
    <RadioMu
      disableRipple
      checkedIcon={<IconWrap icon={'radioboxChecked'} size={sizePx} />}
      icon={<IconWrap icon={'radiobox'} size={sizePx} />}
      onChange={(e) => {
        onChangeValue?.(e.target.checked)
      }}
      {...otherProps}
      sx={{
        ...sizer[size],
        '&.MuiRadio-root': {
          color: 'var(--interaction-neutral-hovered)',
          '&:hover': {
            color: 'var(--interaction-neutral-primary)',
          },
        },
        '&.Mui-checked': {
          color: 'var(--action-contained-primary-normal)',
          '&:hover': {
            color: 'var(--action-contained-primary-hovered)',
          },
        },
        '&.Mui-disabled': {
          color: 'var(--action-contained-primary-disabled)',
        },
      }}
      aria-label={getAriaLabel(ariaLabel)}
    />
  )
}
