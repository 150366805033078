import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  CallList,
  CallSection,
  ICallSectionActionsTitleProps,
  useCallPopUpDefaultContext,
} from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'

export const CallConferencePopUpContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const {
    inboxesList,
    reactionContactsSearch,
    contactsLoading,
    inboxesLoading,
    search,
    handleSearch,
    clearSearch,
    contactsList,
    handleAddParticipant,
    isConferenceParticipants,
    isConferenceParticipantsLoading,
    participants,
    handleChangeConferenceParticipantsStatus,
    checkParticipantsLimit,
    isValidNumber,
    resetConference,
  } = store as CallPopUpDefaultStore
  const loading = contactsLoading || inboxesLoading

  useEffect(() => {
    reactionContactsSearch()

    return () => {
      resetConference()
    }
  }, [])

  useEffect(() => {
    if (isConferenceParticipants) {
      clearSearch()
    }
  }, [isConferenceParticipants])

  if (isConferenceParticipants) {
    return (
      <CallSection
        title={'Conference'}
        actions={[
          {
            label: 'Add',
            icon: 'add',
            disabled: checkParticipantsLimit || isConferenceParticipantsLoading,
            tooltipProps: {
              zIndex: 5001,
              placement: 'top-end',
              label: checkParticipantsLimit ? 'Conference capacity reached' : '',
            },
            onChange: () => {
              handleChangeConferenceParticipantsStatus(false)
            },
          },
        ]}
      >
        <CallList
          isSearch={false}
          itemsGroups={[
            {
              name: '',
              items: participants,
            },
          ]}
          isValidNumber={isValidNumber}
          isTransfer={false}
        />
      </CallSection>
    )
  }

  const actionsTitle: ICallSectionActionsTitleProps[] = []

  if (participants.length > 1) {
    actionsTitle.push({
      icon: 'chevronLeft',
      onChange: () => {
        handleChangeConferenceParticipantsStatus(true)
      },
    })
  }

  return (
    <CallSection title={'Add to call'} actionsTitle={actionsTitle}>
      <CallList
        placeholder={'Search inbox, contact or dial a number'}
        itemsGroups={[
          {
            name: 'Inboxes',
            items: inboxesList,
          },
          {
            name: 'Contacts',
            items: contactsList,
          },
        ]}
        searchValue={search}
        onChangeSearch={handleSearch}
        loading={loading}
        onChangeItem={handleAddParticipant}
        isValidNumber={isValidNumber}
        isTransfer={false}
      />
    </CallSection>
  )
})
