import { nanoid } from 'nanoid'
import { IntegrationKey, IResponseMergeField } from 'entities/Integrations'

export class MergeField {
  id: string
  integrationId: number
  integrationKey: IntegrationKey
  label: string
  name: string
  photo_url: string
  template: string
  value: string

  constructor(response: IResponseMergeField) {
    this.id = nanoid()
    this.integrationId = response.integrationId
    this.value = response.value || ''

    if (response.integrationKey) {
      this.integrationKey = response.integrationKey as IntegrationKey
      this.label = response.label || ''
      this.name = response.name || ''
      this.photo_url = response.photo_url || ''
      this.template = response.template || `{ ${this.integrationKey}.${this.name}`
    } else {
      this.integrationKey = IntegrationKey.salesmessage
      this.label = response.label || response.name || ''
      this.name = response.key || response.name || ''
      this.photo_url = ''
      this.template = `{ ${this.name} }`
    }
  }
}
