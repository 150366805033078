import { Actions } from 'shared/ui'
import { IResponseAdminOrganizationById } from 'entities/Admin'
import { getComplianceAdminOrganizationsActions } from 'pages/admin/pages/organizations/lib/getComplianceAdminOrganizationsActions'

type IAdminHeaderOrganizationActionsProps = {
  organizationById?: IResponseAdminOrganizationById | null
}

export const AdminHeaderOrganizationActions = ({
  organizationById,
}: IAdminHeaderOrganizationActionsProps) => {
  if (!organizationById) {
    return null
  }

  return (
    <Actions
      gap={8}
      actions={getComplianceAdminOrganizationsActions({ organizationById })}
      showItems={4}
    />
  )
}
