import { MouseEvent, FC } from 'react'
import { Button } from 'shared/ui'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { filtersStore } from 'features/DropdownFilter'
import { useConversationListContext } from 'widgets/ConversationList/context'
import { IMarkAsNeedResponseParams } from 'widgets/ConversationList/store'

type IButtonMarkAsPendingProps = {
  conversation?: Conversation
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  clickCallback?: () => void
}

export const ButtonMarkAsPending: FC<IButtonMarkAsPendingProps> = ({
  conversation,
  onMouseLeave,
  onMouseEnter,
  clickCallback,
}) => {
  const store = useConversationListContext()
  const { handleMarkAsNeedResponse, selectedListIds } = store
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
    const id = conversation?.id
    const params: IMarkAsNeedResponseParams = {
      isNeedResponse: !filtersStore.isPending,
      ids: id ? [id] : selectedListIds,
      isUndo: true,
    }

    handleMarkAsNeedResponse(params)
    clickCallback?.()
  }

  return (
    <Button
      fontWeight={400}
      onClick={handleClick}
      typeBtn='menuItem'
      icon={'messageCircleChecked'}
      text={filtersStore.isPending ? 'Mark as responded' : 'Mark as Needs Response'}
      fullWidth
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}
