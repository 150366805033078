import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ContainerHeader, ITabItem, Tabs } from 'shared/ui'
import { Breadcrumbs } from 'shared/ui/Breadcrumbus/Breadcrumbs'
import { integrationsStore } from 'entities/Integrations'
import {
  contactsCleanupStore,
  ContactsDuplicateTable,
  ContactsTableStore,
  ContactsUnlinkedTable,
} from 'widgets/ContactsTable'
import styles from './styles.module.scss'

type IContactsCleanupContentProps = {
  contactsTableStore: ContactsTableStore
}

export const ContactsCleanupContent: FC<IContactsCleanupContentProps> = observer(
  ({ contactsTableStore }) => {
    const {
      contactsUnlinkedTable,
      activeTab,
      isChangeToUnlinked,
      setIsChangeToUnlinked,
      handleActiveTab,
      resetPaginationPage,
    } = contactsCleanupStore
    const { duplicateCount } = contactsCleanupStore
    const tabs: ITabItem[] = [
      {
        name: 'Duplicates',
        key: 'duplicate',
        count: duplicateCount,
      },
      {
        name: 'Unlinked',
        key: 'unlink',
        count: contactsUnlinkedTable.total,
      },
    ]

    useEffect(() => {
      if (!integrationsStore.hasIntegrations) {
        integrationsStore.fetchIntegrations()
      }
      if (isChangeToUnlinked) {
        handleActiveTab(tabs[1].key)
        setIsChangeToUnlinked(false)
      }
      return () => {
        handleActiveTab(tabs[0].key)
      }
    }, [])

    return (
      <div className={styles.contentContainer}>
        <Breadcrumbs />
        <ContainerHeader
          title={'Contact cleanup'}
          titleIconProps={{
            icon: 'lineCollapseExpandLeft',
            fontSize: 20,
            color: 'var(--content-primary-tertiary)',
          }}
          titleIconAction={contactsTableStore.pageLayoutStore?.handleToggleCollapse}
        />
        <div className={styles.tabs}>
          <Tabs
            tabs={tabs}
            activeTabKey={activeTab}
            handleSelectTab={(tab) => {
              handleActiveTab(tab.key)
              resetPaginationPage()
            }}
          />
        </div>
        <div style={{ display: 'flex', height: '100%' }}>
          {activeTab === 'unlink' && <ContactsUnlinkedTable />}
          {activeTab === 'duplicate' && <ContactsDuplicateTable />}
        </div>
      </div>
    )
  }
)
