import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import { isEqual } from 'lodash'
import {
  AdminComplianceApi,
  IParamsAdminGetTenDLCs,
  IResponseAdminTenDCLsData,
  IAdminTenDCLsData,
} from 'entities/Admin/compliance'
import { ITenDLCStatus } from 'entities/Compliance'
import { ISubscriptionStatus } from 'entities/Subscription'
import {
  DEFAULT_BRAND_STATUSES,
  DEFAULT_CAMPAIGN_STATUSES,
  DEFAULT_PROFILE_STATUSES,
} from 'pages/admin/pages/compliance/pages/TenDLC/store/constants'

class AdminTenDLCTableStore {
  constructor() {
    makeAutoObservable(this)
    reaction(() => this.paramsGetData, this.loadData, { delay: 500 })
  }

  loading = false

  page = 1
  limit: number | null = 50
  total = 0

  search: string | null = null
  loadingSearch = false
  visibleColumnsIds: string[] = []

  profileStatuses: ITenDLCStatus[] = DEFAULT_PROFILE_STATUSES
  brandStatuses: ITenDLCStatus[] = DEFAULT_BRAND_STATUSES
  campaignStatuses: ITenDLCStatus[] = DEFAULT_CAMPAIGN_STATUSES
  subscriptionStatuses: ISubscriptionStatus[] = []

  itemsMap: Map<number, IAdminTenDCLsData> = new Map()

  cancelTokenSource: CancelTokenSource | null = null

  get items() {
    return Array.from(this.itemsMap.values())
  }

  get paramsGetData(): IParamsAdminGetTenDLCs {
    return {
      business_profile_status_list: this.profileStatuses,
      brand_status_list: this.brandStatuses,
      campaign_status_list: this.campaignStatuses,
      page: this.page,
      search: this.search || null,
      limit: this.limit,
      subscription_statuses: this.subscriptionStatuses,
    }
  }

  get isDefaultStatuses() {
    return (
      isEqual(this.profileStatuses, DEFAULT_PROFILE_STATUSES) &&
      isEqual(this.brandStatuses, DEFAULT_BRAND_STATUSES) &&
      isEqual(this.campaignStatuses, DEFAULT_CAMPAIGN_STATUSES)
    )
  }

  loadData = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })
      this.initCancelTokenSource()

      const { data } = await AdminComplianceApi.getTenDLCs(this.paramsGetData, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })
      this.setData(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setData = ({ data, meta }: IResponseAdminTenDCLsData) => {
    this.reset()
    this.setItems(data)
    this.total = meta.total
  }

  setItems = (items: IAdminTenDCLsData[]) => {
    items.forEach((item) => this.setItem(item))
  }

  setItem = (item: IAdminTenDCLsData) => {
    this.itemsMap.set(item.id, item)
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  reset = () => {
    this.itemsMap.clear()
  }

  setSearch = (value: string) => {
    this.page = 1
    this.search = value
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }
  setProfileStatus = (values: typeof this.profileStatuses) => {
    this.page = 1
    this.profileStatuses = values
  }
  setBrandStatuses = (values: typeof this.brandStatuses) => {
    this.page = 1
    this.brandStatuses = values
  }
  setCampaignStatuses = (values: typeof this.campaignStatuses) => {
    this.page = 1
    this.campaignStatuses = values
  }
  setVisibleColumnsIds = (ids: string[]) => {
    this.visibleColumnsIds = ids
  }
  setSubscriptionStatuses = (values: ISubscriptionStatus[]) => {
    this.page = 1
    this.subscriptionStatuses = values
  }
}

export const adminTenDLCTableStore = new AdminTenDLCTableStore()
