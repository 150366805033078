// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UIEoqW78RXAc14ga8zIj{display:flex;align-Items:center;gap:4px}.NLf4VJyG_dhKTi5V6sHF{color:var(--content-primary-primary);font-weight:500}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/DefaultHeaderCell/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CAEJ,sBACI,oCAAA,CACA,eAAA","sourcesContent":[".container {\n    display: flex;\n    align-Items: center;\n    gap: 4px;\n}\n.activeNameSpan {\n    color: var(--content-primary-primary);\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UIEoqW78RXAc14ga8zIj",
	"activeNameSpan": "NLf4VJyG_dhKTi5V6sHF"
};
export default ___CSS_LOADER_EXPORT___;
