// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HHEitWgezEO3JrDJKAEp{position:relative}.HHEitWgezEO3JrDJKAEp .YsiIC3HsfmPMmSjwLj5s{display:flex;align-items:center;justify-content:center;padding:24px 0}.HHEitWgezEO3JrDJKAEp .YsiIC3HsfmPMmSjwLj5s .k5AtQeRHn8bItudS5XDQ{display:flex;align-items:center;justify-content:center;height:32px;width:32px;border-radius:100%;background-color:var(--background-neutral-default)}.HHEitWgezEO3JrDJKAEp .o9pDlQyKVO6WSQwFekYh{display:flex;align-items:center;justify-content:space-between;padding:8px;margin-top:16px;border-radius:6px}.HHEitWgezEO3JrDJKAEp .o9pDlQyKVO6WSQwFekYh:hover{background:var(--background-neutral-subtle)}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/ContactsUnlinkedTable/LinkContact/ui/LinkContactModalContent/LinkMatchingTab/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CAEA,kEACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,kDAAA,CAIJ,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,WAAA,CACA,eAAA,CACA,iBAAA,CAEA,kDACE,2CAAA","sourcesContent":[".wrap {\n  position: relative;\n\n  .divider {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 24px 0;\n\n    .icon {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      height: 32px;\n      width: 32px;\n      border-radius: 100%;\n      background-color: var(--background-neutral-default);\n    }\n  }\n\n  .updateWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    padding: 8px;\n    margin-top: 16px;\n    border-radius: 6px;\n\n    &:hover {\n      background: var(--background-neutral-subtle);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "HHEitWgezEO3JrDJKAEp",
	"divider": "YsiIC3HsfmPMmSjwLj5s",
	"icon": "k5AtQeRHn8bItudS5XDQ",
	"updateWrapper": "o9pDlQyKVO6WSQwFekYh"
};
export default ___CSS_LOADER_EXPORT___;
