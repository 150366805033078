import { observer } from 'mobx-react-lite'
import { Alert, Button, DatePicker, Icon, Switch, Tooltip, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { scheduleStore } from 'widgets/ConversationSchedule/store/conversationScheduleStore'
import styles from './styles.module.scss'

export const ScheduleContent = observer(() => {
  const {
    is_contact_timezone,
    stop_on_response,
    handleSchedule,
    toggleIsUseContactTimezone,
    toggleIsCancelOnResponse,
    scheduleText,
    scheduleDate,
    setScheduleDate,
    isAlreadyPassed,
    contactDateString,
    offsetMinutes,
  } = scheduleStore

  return (
    <div className={styles.wrap}>
      {isAlreadyPassed && (
        <Alert
          item={{
            type: 'error',
            title: 'The scheduled time for this contact has already passed',
          }}
          marginBottom={16}
        />
      )}
      <DatePicker
        type={'singleExpanded'}
        date={scheduleDate.toDate()}
        handleDate={setScheduleDate}
        excludeDateIntervals={[
          { start: new Date(0), end: uiStore.dayjs().add(-1, 'day').toDate() },
        ]}
        filteredPassedTime={uiStore.dayjs().toDate()}
        offsetMinutes={offsetMinutes}
      />
      <div className={styles.wrapSwitches}>
        <Button
          titleContent={
            <>
              <Typography
                variant={'text-input-field'}
                color={'tertiary'}
                className={'row8'}
                ariaLabel={'Use contact timezone'}
              >
                <Icon icon='userFilled' />
                Use contact timezone
              </Typography>
              <Switch value={is_contact_timezone} />
            </>
          }
          size={'medium'}
          typeBtn={'switchBtn'}
          fullWidth
          onClick={toggleIsUseContactTimezone}
        />
        <Button
          titleContent={
            <>
              <div className={'row4'}>
                <Typography
                  variant={'text-input-field'}
                  color={'tertiary'}
                  className={'row8'}
                  ariaLabel={'Cancel on response'}
                >
                  <Icon icon='messageX' />
                  Cancel on response
                </Typography>
                <Tooltip
                  label={
                    'Your scheduled message will be canceled if you receive an inbound message before the scheduled date'
                  }
                  placement={'top'}
                  width={238}
                >
                  <Icon icon={'question'} fontSize={16} color={'var(--gray-40)'} />
                </Tooltip>
              </div>

              <Switch value={stop_on_response} />
            </>
          }
          size={'medium'}
          typeBtn={'switchBtn'}
          fullWidth
          onClick={toggleIsCancelOnResponse}
        />
      </div>

      <Button
        text={scheduleText}
        size={'medium'}
        typeBtn={'contained'}
        contained={'primary'}
        disabled={isAlreadyPassed}
        fullWidth
        onClick={handleSchedule}
      />
      {!is_contact_timezone && (
        <Typography
          variant={'body-sm-regular'}
          color={'tertiary'}
          className={styles.contactTime}
          tag={'div'}
          ariaLabel={'Contact'}
        >
          Contact’s current time - {contactDateString}
        </Typography>
      )}
    </div>
  )
})
