import { observer } from 'mobx-react-lite'
import React from 'react'
import { Select } from 'shared/ui/Select/Select'
import { dayOfWeekItems, MonthDaysSelectorStore, monthOrdinalItems } from 'shared/ui/Schedule'
import styles from './styles.module.scss'

type IEachContentProps = {
  store: MonthDaysSelectorStore
  parentsDropdownId: string[]
}

export const OnTheContent = observer(({ store, parentsDropdownId }: IEachContentProps) => {
  const { onSelectOnTheDayOfWeek, onTheOrdinal, onTheDayOfWeek, onSelectOnTheOrdinal } = store
  return (
    <div className={styles.wrap}>
      <Select
        value={onTheOrdinal}
        items={monthOrdinalItems}
        variant={'stroke'}
        onSelectValue={onSelectOnTheOrdinal}
        dropdownProps={{
          ariaLabel: 'TheOrdinal',
          parentsDropdownId,
          withSearch: false,
        }}
      />
      <Select
        value={onTheDayOfWeek}
        onSelectValue={onSelectOnTheDayOfWeek}
        variant={'stroke'}
        items={dayOfWeekItems}
        dropdownProps={{
          ariaLabel: 'TheDayOfWeek',
          parentsDropdownId,
          withSearch: false,
          maxHeight: 304,
        }}
      />
    </div>
  )
})
