import { FC } from 'react'
import { Scrollbar } from 'shared/ui'
import { CallContact } from 'entities/Call/model/CallContact'
import { ContactCard } from './ContactCard'
import styles from './styles.module.scss'

type IContactsListProps = {
  items: CallContact[]
  onDelete?: (item: CallContact) => void
  innerRefCurrent?: HTMLDivElement
}

export const ContactsList: FC<IContactsListProps> = ({ items, innerRefCurrent, onDelete }) => {
  return (
    <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={258}>
      <div className={styles.list}>
        {items.map((item) => {
          return (
            <ContactCard
              key={item.id}
              item={item}
              innerRefCurrent={innerRefCurrent}
              onDelete={onDelete}
            />
          )
        })}
      </div>
    </Scrollbar>
  )
}
