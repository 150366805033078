import { makeObservable, observable } from 'mobx'

import { Base } from 'shared/api/models'
import { IResponseContactField } from 'entities/Contacts/api/types'

export class ContactFields extends Base {
  id: number
  userId: number
  organizationId: number
  active_fields: string[]

  constructor(field: IResponseContactField) {
    super()

    this.id = field.id
    this.userId = field.user_id
    this.organizationId = field.organization_id
    this.active_fields = field.active_fields

    makeObservable(this, {
      active_fields: observable,
    })
  }

  updateContactFields(field: IResponseContactField) {
    this.id = field.id
    this.userId = field.user_id
    this.organizationId = field.organization_id
    this.active_fields = field.active_fields
  }
}
