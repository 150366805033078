import classNames from 'classnames'
import { ChangeEventHandler } from 'react'
import { ImageAccept } from 'shared/constants/accept'
import { Icon } from 'shared/ui'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { Attachments } from 'widgets/MessageField'
import commonStyles from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/styles.module.scss'
import styles from './styles.module.scss'

export type IAttachmentsEditValueProps = {
  typeEdit: 'attachments'
  value: Attachment[]
  editValue?: Attachment[]
  isUnsaved?: boolean
  removeAttachments?: (ids: Array<string | number>) => void
  onAddFile?: (file: File) => void
}

export const AttachmentsEditValue = ({
  editValue,
  removeAttachments,
  isUnsaved,
  onAddFile,
}: IAttachmentsEditValueProps) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && e.target.files[0]) {
      onAddFile && onAddFile(e.target.files[0])
    }
  }
  return (
    <div
      className={classNames(commonStyles.value, styles.wrap, isUnsaved && commonStyles.isUnsaved)}
    >
      <div className={styles.imageLoader}>
        <input type='file' accept={ImageAccept.toString()} onChange={onChange} />
        <Icon icon={'addCircle'} fontSize={24} />
      </div>
      {editValue && (
        <Attachments attachments={editValue} removeAttachments={removeAttachments} medium />
      )}
    </div>
  )
}
