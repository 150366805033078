import { Modal } from 'shared/ui'

const CommonLayout = () => {
  return (
    <>
      <Modal />
    </>
  )
}

export default CommonLayout
