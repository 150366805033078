import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import classnames from 'classnames'
import { CallHistorySearch } from 'widgets/CallHistory'
import styles from 'widgets/CallHistory/ui/styles.module.scss'
import { CallHistoryDropdownFilter } from 'widgets/CallHistoryDropdownFilter'

type ICallHistoryHeaderProps = {
  isShowSearch?: boolean
}

export const CallHistoryHeader: FC<ICallHistoryHeaderProps> = observer(
  ({ isShowSearch = true }) => {
    return (
      <div
        className={classnames(styles.header, {
          [styles.showSearch]: isShowSearch,
        })}
      >
        {isShowSearch && <CallHistorySearch />}
        <CallHistoryDropdownFilter />
      </div>
    )
  }
)
