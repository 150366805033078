import React from 'react'
import { IInboxCombine, INodeModel } from 'entities/Inbox'
import styles from './styles.module.scss'

type PropsMenuPlaceholderTree = {
  node: INodeModel<IInboxCombine>
  depth: number
}

const MenuPlaceholderTree: React.FC<PropsMenuPlaceholderTree> = (props) => {
  const left = props.depth * 24
  return <div className={styles.wrap} style={{ left }}></div>
}

export { MenuPlaceholderTree }
