import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Alert, DropdownPlaceholder, EnumDropdownItemVariant } from 'shared/ui'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import { linkContactStore } from '../../../store/linkContactStore'

import styles from './styles.module.scss'
import { MatchedContact } from '../../../model/MatchedContact'

type ILinkWithPhonesModalContentProps = {
  contact: MatchedContact
}

const LinkWithPhonesModalContent = observer(({ contact }: ILinkWithPhonesModalContentProps) => {
  return (
    <div className={classnames(styles.contentWrap)}>
      <Alert
        item={{
          desc: 'Select the phone number you want to use for this contact',
          type: 'infoLight',
        }}
      />
      <DropdownPlaceholder
        items={[
          {
            id: 1,
            label: `Phone: ${contact.phone || '-'}`,
            activeValue: linkContactStore.selectedMatchedPhone === contact.phone,
            value: contact.phone,
            variant: EnumDropdownItemVariant.CheckedRight,
          },
          {
            id: 2,
            label: `Mobile Phone: ${contact.mobile_phone || '-'}`,
            activeValue: linkContactStore.selectedMatchedPhone === contact.mobile_phone,
            value: contact.mobile_phone,
            variant: EnumDropdownItemVariant.CheckedRight,
          },
        ]}
        onChange={({ value }) => {
          if (value) {
            linkContactStore.handleSelectedMatchedPhone(value)
          }
        }}
        widthDropdown={440}
        placeholder={
          `${
            contact.phone === linkContactStore.selectedMatchedPhone ? 'Phone:' : 'Mobile Phone:'
          } ${linkContactStore.selectedMatchedPhone}` || '-'
        }
        textPlaceholderProps={{
          variant: 'stroke',
          size: 'small',
          rightActions: [makeTextFieldIcon({ icon: 'chevronDown' })],
        }}
      />
    </div>
  )
})

export { LinkWithPhonesModalContent }
