// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sh94lL8ZwT0zl8STjZr1{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/widgets/CallPopUp/ui/CallVoicemailDropButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA","sourcesContent":[".wrap {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "sh94lL8ZwT0zl8STjZr1"
};
export default ___CSS_LOADER_EXPORT___;
