// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Di7yHq3GS5NCKvmiCkuN{display:flex;gap:0}.Di7yHq3GS5NCKvmiCkuN>div{background:var(--background-primary-inverted-primary)}.Di7yHq3GS5NCKvmiCkuN>:not(div:nth-child(1)){border-radius:50%;margin-left:-6px;box-shadow:0 0 0 1.5px #fff}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(2){z-index:2}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(3){z-index:3}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(4){z-index:4}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(5){z-index:5}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(6){z-index:6}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(7){z-index:7}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(8){z-index:8}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(9){z-index:9}.Di7yHq3GS5NCKvmiCkuN>div:nth-child(10){z-index:10}", "",{"version":3,"sources":["webpack://./src/shared/ui/AvatarGroupInLine/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,KAAA,CAEA,0BACE,qDAAA,CAGF,6CACE,iBAAA,CACA,gBAAA,CACA,2BAAA,CAGF,uCACE,SAAA,CAEF,uCACE,SAAA,CAEF,uCACE,SAAA,CAEF,uCACE,SAAA,CAEF,uCACE,SAAA,CAEF,uCACE,SAAA,CAEF,uCACE,SAAA,CAEF,uCACE,SAAA,CAEF,wCACE,UAAA","sourcesContent":[".group {\n  display: flex;\n  gap: 0;\n\n  & > div {\n    background: var(--background-primary-inverted-primary);\n  }\n\n  & > :not(div:nth-child(1)) {\n    border-radius: 50%;\n    margin-left: -6px;\n    box-shadow: 0 0 0 1.5px #ffffff;\n  }\n\n  & > div:nth-child(2) {\n    z-index: 2;\n  }\n  & > div:nth-child(3) {\n    z-index: 3;\n  }\n  & > div:nth-child(4) {\n    z-index: 4;\n  }\n  & > div:nth-child(5) {\n    z-index: 5;\n  }\n  & > div:nth-child(6) {\n    z-index: 6;\n  }\n  & > div:nth-child(7) {\n    z-index: 7;\n  }\n  & > div:nth-child(8) {\n    z-index: 8;\n  }\n  & > div:nth-child(9) {\n    z-index: 9;\n  }\n  & > div:nth-child(10) {\n    z-index: 10;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "Di7yHq3GS5NCKvmiCkuN"
};
export default ___CSS_LOADER_EXPORT___;
