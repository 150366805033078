// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GAZuHyLkUdAkApPjilfw{border-bottom:1px solid var(--gray-20);padding:8px;display:grid;gap:8px;grid-template-columns:1fr 1fr;align-items:center}.GAZuHyLkUdAkApPjilfw .nfdqal4vbD12H3iT1JTl{padding-left:8px}.GAZuHyLkUdAkApPjilfw .nfdqal4vbD12H3iT1JTl .VjYLBsmDJoEfyoyK9LVZ{font-size:13px}.GAZuHyLkUdAkApPjilfw .vAPy8f0dVbPeamA81V6i{display:grid;grid-template-columns:40px 1fr;align-items:center;gap:8px}.NJwuQ8snl1GzgKzpIYP4{padding:8px}.SOTEHSLsrREI1moneyew{display:flex;gap:8px;padding:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/Recurring/ui/EndRepeatDropdown/AfterContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,WAAA,CACA,YAAA,CACA,OAAA,CACA,6BAAA,CACA,kBAAA,CACA,4CACE,gBAAA,CACA,kEACE,cAAA,CAGJ,4CACE,YAAA,CACA,8BAAA,CACA,kBAAA,CACA,OAAA,CAIJ,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,YAAA","sourcesContent":[".content{\n  border-bottom: 1px solid var(--gray-20);\n  padding: 8px;\n  display: grid;\n  gap: 8px;\n  grid-template-columns: 1fr 1fr;\n  align-items: center;\n  .label{\n    padding-left: 8px;\n    .textLabel{\n      font-size: 13px;\n    }\n  }\n  .right{\n    display: grid;\n    grid-template-columns: 40px 1fr;\n    align-items: center;\n    gap: 8px;\n  }\n}\n\n.input{\n  padding: 8px;\n}\n\n.actions{\n  display: flex;\n  gap: 8px;\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "GAZuHyLkUdAkApPjilfw",
	"label": "nfdqal4vbD12H3iT1JTl",
	"textLabel": "VjYLBsmDJoEfyoyK9LVZ",
	"right": "vAPy8f0dVbPeamA81V6i",
	"input": "NJwuQ8snl1GzgKzpIYP4",
	"actions": "SOTEHSLsrREI1moneyew"
};
export default ___CSS_LOADER_EXPORT___;
