// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kDwWm9fBSU95z_0cCbT2{width:100%;border-radius:4px}.skPyoDoyuNt45bt5QUTb{display:flex;justify-content:space-between;align-items:center;padding:16px;cursor:pointer;border-radius:6px;background:var(--background-neutral-default)}.ykoYjW9pwCRj4oBo8cFK{color:var(--content-primary-primary);font-size:13px;font-weight:500;line-height:16px}._mBeXFzT18IzEhz5BqQE{padding:10px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Collapse/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,iBAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,YAAA,CACA,cAAA,CACA,iBAAA,CACA,4CAAA,CAGF,sBACE,oCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,YAAA","sourcesContent":[".container {\n    width: 100%;\n    border-radius: 4px;\n  }\n  \n  .header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 16px;\n    cursor: pointer;\n    border-radius: 6px;\n    background: var(--background-neutral-default);\n  }\n  \n  .title {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 16px;\n  }\n  \n  .content {\n    padding: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "kDwWm9fBSU95z_0cCbT2",
	"header": "skPyoDoyuNt45bt5QUTb",
	"title": "ykoYjW9pwCRj4oBo8cFK",
	"content": "_mBeXFzT18IzEhz5BqQE"
};
export default ___CSS_LOADER_EXPORT___;
