import { makeAutoObservable } from 'mobx'
import { isEmail } from 'shared/lib'
import { getEmailDomain, getUrlDomain } from 'shared/lib/getDomains'
import { PhoneInputStore } from 'shared/ui'
import type { PointsOfContactItem } from 'entities/Compliance/model'
import type { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'

export class ContactStore {
  private _firstName = ''
  private _lastName = ''
  private _businessTitle = ''
  private _jobPosition: number | null = null
  private _email = ''
  private _emailLocalError = ''
  private _similarityDomainsWarning: string | null = null

  private _phoneInputStore = new PhoneInputStore()

  constructor(private _complianceLayerStore: ComplianceLayerStore | null) {
    makeAutoObservable(this)
  }

  get firstName() {
    return this._firstName
  }

  get lastName() {
    return this._lastName
  }

  get businessTitle() {
    return this._businessTitle
  }

  get jobPosition() {
    return this._jobPosition
  }

  get email() {
    return this._email
  }

  get emailLocalError() {
    return this._emailLocalError
  }

  get similarityDomainsWarning() {
    return this._similarityDomainsWarning
  }

  get phoneInputStore() {
    return this._phoneInputStore
  }

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  get contactToBackend() {
    return {
      business_title: this.businessTitle,
      email: this.email,
      first_name: this.firstName,
      job_position_id: String(this.jobPosition),
      last_name: this.lastName,
      phone_number: this.phoneInputStore.number,
    }
  }

  get isFilled() {
    return (
      this.phoneInputStore.isValidNumber &&
      this._jobPosition !== null &&
      [
        this._firstName,
        this._lastName,
        this._email,
        this.phoneInputStore.number,
        this.businessTitle,
      ].every((field) => field.trim().length > 0)
    )
  }

  setFirstName = (name: string) => {
    this._firstName = name
  }

  setLastName = (name: string) => {
    this._lastName = name
  }

  setBusinessTitle = (title: string) => {
    this._businessTitle = title
  }

  setJobPosition = (position: number) => {
    this._jobPosition = position
  }

  setContactFromResponse = (response: PointsOfContactItem) => {
    this._firstName = response.first_name
    this._lastName = response.last_name
    this._businessTitle = response.business_title
    this._jobPosition = response.job_position_id
    this._email = response.email
    this.phoneInputStore.setNumber(response.phone_number)
  }

  setEmail = (email: string) => {
    this._emailLocalError = ''
    this._similarityDomainsWarning = null
    this._email = email
  }

  setEmailLocalError = (error: string) => {
    this._emailLocalError = error
  }

  setSimilarityDomainsWarning = (value: string | null) => {
    this._similarityDomainsWarning = value
  }

  checkDomainSimilarity = () => {
    const { website_url } = this.complianceLayerStore?.businessInformation || {}
    const emailDomain = getEmailDomain(this._email)

    if (!emailDomain || !website_url) return false

    const urlObj = new URL(website_url)
    const urlDomain = getUrlDomain(urlObj)
    if (!urlDomain) return false

    const isSimilar = emailDomain === urlDomain
    this.setSimilarityDomainsWarning(
      isSimilar
        ? null
        : "It looks like your business email domain doesn't match your business website domain"
    )
  }

  checkIsEmail = () => {
    const isCorrect = isEmail(this._email)

    if (isCorrect) {
      this.checkDomainSimilarity()
      this.setEmailLocalError('')
      return
    }

    this.setEmailLocalError('Please enter a valid email address')
    this.setSimilarityDomainsWarning(null)
  }

  reset = () => {
    this._firstName = ''
    this._lastName = ''
    this._businessTitle = ''
    this._jobPosition = null
    this._email = ''
    this.phoneInputStore.reset()
  }
}
