import { createContext, useContext } from 'react'
import { NewOrganizationStore } from './store/newOrganizationStore'

export const NewOrganizationContext = createContext<NewOrganizationStore | null>(null)

export function useNewOrganizationContext() {
  const context = useContext(NewOrganizationContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with NewOrganizationStoreProvider'
    )
  }
  return context
}
