import { IIconsVector } from 'shared/ui'

type IItem = {
  text: string
  icon: IIconsVector
  color?: string
}

export const items: IItem[] = [
  {
    text: 'Get Local & Toll-Free Numbers',
    icon: 'phone',
    color: 'var(--content-brand-primary)',
  },
  {
    text: 'Get Dedicated Short Code',
    icon: 'testSMS',
    color: 'var(--content-brand-primary)',
  },
  {
    text: 'Port Your Numbers',
    icon: 'campaigns',
    color: 'var(--content-brand-primary)',
  },
  {
    text: 'Text-Enable Your Landline',
    icon: 'oldPhone',
    color: 'var(--content-brand-primary)',
  },
  {
    text: 'Enable Your Aircall Numbers',
    icon: 'aircall',
  },
]
