import React from 'react'
import { Status } from 'shared/ui'
import { IStatusProps } from 'shared/ui/Status'
import { IBroadcastStatus } from './type'
import config from './config'

export const BroadcastStatus = ({
  status,
  emphasis = 'high',
  onlyIcon,
}: {
  status: IBroadcastStatus
  emphasis?: IStatusProps['emphasis']
  onlyIcon?: IStatusProps['onlyIcon']
}) => status && <Status {...config[status]} emphasis={emphasis} onlyIcon={onlyIcon} />
