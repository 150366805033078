// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d12BykN6mZu79mPSUkB1{padding:9px 0 0}.KEquqI4hqX0RW9_ldtmM{display:flex;align-items:center;justify-content:space-between;margin-bottom:18px}.DMOWgVENx0gebpEYiysM{display:flex;align-items:center}.neIYrCAGc_0hAD1wNkyt{color:var(--content-primary-primary);font-size:13px;font-weight:500}.MCmkgOgp4VJ1N0S0WdXW>div{margin-right:5px}", "",{"version":3,"sources":["webpack://./src/widgets/CallPopUp/ui/CallSection/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,oCAAA,CACA,cAAA,CACA,eAAA,CAaF,0BACE,gBAAA","sourcesContent":[".settings {\n  padding: 9px 0 0;\n\n  &__header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 18px;\n  }\n\n  &__titleWrap {\n    display: flex;\n    align-items: center;\n  }\n\n  &__title {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    font-weight: 500;\n  }\n\n  &__content {\n\n  }\n}\n\n.actions {\n\n}\n\n.actionTitle {\n  & > div {\n    margin-right: 5px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": "d12BykN6mZu79mPSUkB1",
	"settings__header": "KEquqI4hqX0RW9_ldtmM",
	"settings__titleWrap": "DMOWgVENx0gebpEYiysM",
	"settings__title": "neIYrCAGc_0hAD1wNkyt",
	"actionTitle": "MCmkgOgp4VJ1N0S0WdXW"
};
export default ___CSS_LOADER_EXPORT___;
