import { Icon, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export const RinglessSelectInfoTooltip = () => {
  return (
    <Tooltip
      label={
        <>
          <Typography variant={'body-sm-regular'} ariaLabel={'RinglessSelectTooltipLabel'}>
            Audio:
          </Typography>
          <Typography
            variant={'body-sm-regular'}
            className={styles.description}
            ariaLabel={'RinglessSelectTooltipDescription'}
          >
            {' up to 3 min (mp3,wav,wave)'}
          </Typography>
        </>
      }
      placement={'top'}
      width={220}
    >
      <Icon icon={'question'} color={'var(--content-neutral-primary)'} />
    </Tooltip>
  )
}
