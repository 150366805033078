import { observer } from 'mobx-react-lite'
import { useBroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'
import { MessagePreview } from 'widgets/MessagePreview'

export const BroadcastViewPreview = observer(() => {
  const { type, broadcastSMSStore, ringlessMessageFieldStore } = useBroadcastViewContext()
  const messageFieldStore = broadcastSMSStore.messageFieldStore
  const attachment = ringlessMessageFieldStore.attachment

  return <MessagePreview type={type} messageStore={messageFieldStore} attachment={attachment} />
})
