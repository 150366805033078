import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { useTextToSpeechContext } from 'widgets/TextToSpeech'
import { PlaySpeechBtn } from 'widgets/TextToSpeech/ui/TextToSpeechActions/PlaySpeechBtn/PlaySpeechBtn'
import styles from './styles.module.scss'

export const TextToSpeechActions = observer(() => {
  const { onCreate, isDisabledCreate } = useTextToSpeechContext()
  return (
    <div className={styles.wrap}>
      <PlaySpeechBtn />
      <Button
        size={'small'}
        disabled={isDisabledCreate}
        contained={'primary'}
        onClick={onCreate}
        text='Create'
      />
    </div>
  )
})
