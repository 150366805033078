import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'

import {
  ChatbotApi,
  IChatbotConversationStatus,
  IParamsGetChatbotAnalyticList,
  IResponseChatbotAnalyticList,
  ChatbotAnalyticListFilter,
  ChatbotAnalyticListItem,
} from 'entities/Chatbot'

export class ChatbotAnalyticListStore {
  page = 1
  total = 0
  limit = 10
  status: IChatbotConversationStatus | null = null
  activeStatus: IChatbotConversationStatus | null = null

  loading = true
  loadingScheduled = false
  initialLoading = true

  constructor(public id: number) {
    makeAutoObservable(this)

    reaction(() => this._requestParams, this.loadData, {
      delay: 500,
    })
  }

  private _cancelPageSource: CancelTokenSource | null = null
  private _listMap = new Map<number, ChatbotAnalyticListItem>()

  get items() {
    return Array.from(this._listMap.values())
  }

  get isEmpty() {
    if (this.initialLoading || this.loadingScheduled || this.loading) return false

    return !this._listMap.size
  }

  loadData = async () => {
    this.loading = true
    this._initCancelPageSource()

    try {
      const { data } = await ChatbotApi.getChatbotAnalyticList(this._requestParams, {
        ...(this._cancelPageSource ? { cancelToken: this._cancelPageSource.token } : null),
      })

      runInAction(() => {
        this.activeStatus = this.status
      })

      this._setData(data)

      runInAction(() => {
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.loading = error instanceof CanceledError
      })

      console.error(error)
    } finally {
      runInAction(() => {
        this.initialLoading = false
        this.loadingScheduled = false
      })
    }
  }

  changeStatus = (status: IChatbotConversationStatus | null) => {
    this.page = 1
    this.status = status
    this.loadingScheduled = true
  }

  changePaging = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
    this.loadingScheduled = true
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  dispose = () => {
    this._cancelPageSource?.cancel()
  }

  private get _requestParams(): IParamsGetChatbotAnalyticList {
    return {
      id: this.id,
      page: this.page,
      limit: this.limit,
      [ChatbotAnalyticListFilter.Status]: this.status,
    }
  }

  private _initCancelPageSource = () => {
    this._cancelPageSource?.cancel()

    this._cancelPageSource = axios.CancelToken.source()
  }

  private _setData = ({ data, meta }: IResponseChatbotAnalyticList) => {
    this._listMap.clear()
    data.forEach((item) => this._listMap.set(item.id, new ChatbotAnalyticListItem(item)))

    this.page = meta.current_page
    this.total = meta.total
  }
}
