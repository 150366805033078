import { MouseEvent, FC } from 'react'
import { Button } from 'shared/ui'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { useConversationListContext } from 'widgets/ConversationList/context'

type IButtonMakePriorityProps = {
  conversation?: Conversation
  isFavorite?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  clickCallback?: () => void
}

export const ButtonMakePriority: FC<IButtonMakePriorityProps> = ({
  conversation,
  isFavorite,
  onMouseEnter,
  onMouseLeave,
  clickCallback,
}) => {
  const store = useConversationListContext()
  const { updateConversationsFavorite } = store

  const handlePriority = (e: MouseEvent) => {
    e.stopPropagation()

    if (!conversation) return

    clickCallback?.()

    updateConversationsFavorite(!conversation.is_favorite, conversation.id)
  }

  const handlePriorityBulk = (e: MouseEvent) => {
    e.stopPropagation()

    updateConversationsFavorite()
  }

  if (conversation) {
    return (
      <Button
        fontWeight={400}
        onClick={handlePriority}
        typeBtn='menuItem'
        icon={conversation.is_favorite ? 'flagFilled' : 'flagLine'}
        text={conversation.is_favorite ? 'Remove priority' : 'Make priority'}
        fullWidth
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    )
  }

  return (
    <Button
      fontWeight={400}
      onClick={handlePriorityBulk}
      typeBtn='menuItem'
      icon={isFavorite ? 'flagFilled' : 'flagLine'}
      text={isFavorite ? 'Remove priority' : 'Make priority'}
      fullWidth
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}
