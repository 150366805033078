import { Icon, IconButton, Typography, toastStore } from 'shared/ui'
import { DayjsFormats } from 'shared/lib/dayjs'
import { createAndDownloadFileFromText, writeTextToClipboard } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import styles from './styles.module.scss'

export const TranscriptionContent = ({ transcriptions }: { transcriptions: string }) => {
  if (!transcriptions) {
    return (
      <div className={styles.failed}>
        <Icon icon={'alertTriangleSmall'} fontSize={16} color={'var(--content-negative-primary)'} />
        <Typography variant={'text-input-field'} ariaLabel={'transcript'}>
          Call transcript failed to generate
        </Typography>
      </div>
    )
  }
  if (transcriptions === 'in_progress') {
    return (
      <Typography
        variant={'text-input-field'}
        className={styles.inProgress}
        ariaLabel={'transcript'}
      >
        Transcript in progress...
      </Typography>
    )
  }

  const onCopy = () => {
    writeTextToClipboard(transcriptions).then(() => {
      toastStore.add({
        title: 'Copied',
        type: 'info',
      })
    })
  }
  const onDownload = () => {
    const fileName = `Download ${uiStore.dayjs().format(DayjsFormats.fullDownload)}.txt`
    createAndDownloadFileFromText(fileName, transcriptions)
    toastStore.add({
      title: 'File downloaded',
      type: 'info',
    })
  }
  return (
    <div className={styles.wrapContent}>
      <div className={styles.actions}>
        <Typography
          variant={'text-input-field'}
          color={'var(--content-primary-tertiary)'}
          ariaLabel={'transcript'}
        >
          Transcript
        </Typography>
        <IconButton
          onClick={onCopy}
          icon={'copy'}
          variant={'icon'}
          size={'small'}
          color={'tertiary'}
          transparentInactive
          fontSize={16}
          tooltipProps={{
            label: 'Copy',
            placement: 'top',
          }}
          ariaLabel='TranscriptionContent_copy'
        />
        <IconButton
          onClick={onDownload}
          icon={'download'}
          variant={'icon'}
          size={'small'}
          color={'tertiary'}
          transparentInactive
          fontSize={16}
          tooltipProps={{
            label: 'Download .txt',
            placement: 'top',
          }}
          ariaLabel='TranscriptionContent_download'
        />
      </div>
      <Typography
        variant={'body-rg-regular'}
        className={styles.content}
        tag={'div'}
        ariaLabel={'transcriptions'}
      >
        {transcriptions}
      </Typography>
    </div>
  )
}
