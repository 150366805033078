import { makeAutoObservable } from 'mobx'
import { IDropdownItem, ITooltipProps } from 'shared/ui'
import { billingStore } from 'entities/Billing'
import { Source } from 'entities/Billing/model/Source'
import { AddPaymentMethodStore } from 'widgets/AddPaymentMethodModal'

type IDropdownCardsStoreProps = {
  activeId?: string
  hideId?: string
  labelText?: string
  labelTooltip?: ITooltipProps
  withoutDefaultPresetCard?: boolean
  enableAch?: boolean
}

export class DropdownCardsStore {
  private _activeId: string | null = null
  private _hideId: string | null = null
  private _withoutDefaultPresetCard = false
  private _labelText = 'Card'
  private _labelTooltip: ITooltipProps | null = null
  private _enableAch = false

  constructor(props?: IDropdownCardsStoreProps) {
    makeAutoObservable(this)

    if (props?.activeId) {
      this._activeId = props.activeId
    }
    if (props?.hideId) {
      this._hideId = props.hideId
    }
    if (props?.labelText) {
      this._labelText = props.labelText
    }
    if (props?.labelTooltip) {
      this._labelTooltip = props.labelTooltip
    }
    if (props?.withoutDefaultPresetCard) {
      this._withoutDefaultPresetCard = props.withoutDefaultPresetCard
    }
    if (props?.enableAch) {
      this._enableAch = props.enableAch
    }
  }

  loadSources = async () => {
    try {
      if (billingStore.sourcesLoading) return
      if (this.sources.length) return

      await billingStore.fetchBillingSources()

      if (this._withoutDefaultPresetCard) return
      if (!this._activeId && this.sources.length) {
        const itemCart = billingStore.getSources(this.sources[0].id)
        itemCart && this.setActiveId(itemCart.id)
      }
    } catch (e) {
      console.error(e)
    }
  }

  onChange = (item: IDropdownItem) => {
    this.setActiveId(item.id as string)
  }

  setActiveId = (value: typeof this._activeId) => {
    this._activeId = value
  }

  onAddPaymentMethod = () => {
    new AddPaymentMethodStore((source) => {
      this._activeId = source.id
    }, 5120)
  }

  setHideId = (value: typeof this._hideId) => {
    if (this._activeId === value) {
      this._activeId = null
    }
    this._hideId = value
  }

  get activeItem(): Source | null {
    return (this._activeId && billingStore.getSources(this._activeId)) || null
  }

  get sources() {
    if (this._enableAch) {
      return billingStore.sources.filter((source) => source.id !== this._hideId)
    }

    return billingStore.sourcesSimple.filter((source) => source.id !== this._hideId)
  }

  get activeId() {
    return this._activeId
  }

  get labelText() {
    return this._labelText
  }

  get labelTooltip() {
    return this._labelTooltip
  }
}
