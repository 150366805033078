import { makeAutoObservable } from 'mobx'
import {
  AdvancedSchedulingStore,
  type IInitAdvancedScheduling,
} from 'shared/ui/Schedule/ui/AdvancedScheduling'

export type IInitImmediatelyStoreProps = {
  isUseContactTimezone: boolean
  advancedDate?: IInitAdvancedScheduling
}

export class ImmediatelyStore {
  constructor() {
    makeAutoObservable(this)
  }
  dropdownId = ''
  advancedSchedulingStore = new AdvancedSchedulingStore()

  isUseContactTimezone = false

  init = ({ isUseContactTimezone, advancedDate }: IInitImmediatelyStoreProps) => {
    this.isUseContactTimezone = isUseContactTimezone
    if (advancedDate) {
      this.advancedSchedulingStore.init(advancedDate)
    }
  }

  toggleUseContactTimezone = () => {
    this.isUseContactTimezone = !this.isUseContactTimezone
  }

  setDropdownId = (dropdownId: string) => {
    this.dropdownId = dropdownId
    this.advancedSchedulingStore.setDropdownId(dropdownId)
  }
}
