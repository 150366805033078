import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Error, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { useTextToSpeechContext } from 'widgets/TextToSpeech'
import { TextToSpeechVoice } from 'widgets/TextToSpeech/ui/TextToSpeechVoice/TextToSpeechVoice'
import styles from './styles.module.scss'

export const TextToSpeechInfo = observer(() => {
  const { error, duration, audioFileDuration, isErrorLength } = useTextToSpeechContext()
  return (
    <div aria-label={getAriaLabel('text')} className={styles.wrap}>
      <div>
        {error ? (
          <div className={styles.wrapError}>
            <Error type={'text'} error={error} size={'medium'} />
          </div>
        ) : (
          <TextToSpeechVoice />
        )}
      </div>
      {!!duration && (
        <Typography
          ariaLabel={'duration'}
          className={classNames(styles.duration, { [styles.error]: isErrorLength })}
        >
          {(audioFileDuration && secondsToTime(audioFileDuration)) || `~${secondsToTime(duration)}`}
        </Typography>
      )}
    </div>
  )
})
