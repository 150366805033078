import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { toastStore, Typography } from 'shared/ui'
import {
  type IInputNumericProps,
  InputNumeric,
  makeTextFieldElement,
  makeTextFieldIcon,
  TextField,
} from 'shared/ui/TextField'
import { numberFormat } from 'shared/lib'
import { useAdvancedSchedulingContext } from 'shared/ui/Schedule/ui/AdvancedScheduling'
import styles from './styels.module.scss'

export const RecipientsField = observer(() => {
  const { schedulingRecipients, schedulingRecipientsMax, setSchedulingRecipients } =
    useAdvancedSchedulingContext()
  const [value, setValue] = useState(schedulingRecipients)

  useEffect(() => {
    setValue(schedulingRecipients)
  }, [schedulingRecipients])

  const onBlur = () => {
    if (!value) {
      setValue(schedulingRecipientsMax)
      return
    }
    if (value > schedulingRecipientsMax) {
      setValue(schedulingRecipientsMax)
      toastStore.add({
        type: 'info',
        title: `You’re limited to ${numberFormat({
          value: schedulingRecipientsMax,
        })} messages / day`,
      })
      return
    } else {
      setSchedulingRecipients(value)
    }
  }
  return (
    <TextField
      Input={InputNumeric}
      InputProps={
        {
          value: value as unknown as string,
          onValueChange: (values) => setValue(values?.floatValue || 0),
          placeholder: 'Enter',
          allowLeadingZeros: false,
          allowNegative: false,
          thousandSeparator: ',',
          decimalScale: 0,
          maxLength: 12,
        } as IInputNumericProps
      }
      className={styles.label}
      onBlur={onBlur}
      size={'small'}
      variant={'stroke'}
      leftActions={[makeTextFieldIcon({ icon: 'contacts', margin: 0 })]}
      rightActions={[
        makeTextFieldElement({
          element: (
            <Typography variant={'text-input-field'} color='tertiary' ariaLabel={'contacts'}>
              contacts per day
            </Typography>
          ),
        }),
      ]}
    />
  )
})
