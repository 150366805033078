import { observer } from 'mobx-react-lite'
import { PageLayout } from 'shared/ui'

import { SettingsMenu } from './SettingsMenu'
import { Content } from './Content'
import { useSettingsContext } from '../context/settingsContext'

export const SettingsLayout = observer(() => {
  const { pageLayoutStore } = useSettingsContext()

  return (
    <PageLayout leftContent={<SettingsMenu />} store={pageLayoutStore} rightContent={<Content />} />
  )
})
