// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bdgIqJHFgJd2dKf1QddA{height:24px;display:flex;column-gap:4px;align-items:center;justify-content:space-between}.fEPnnUonlYG1N1r59X9Z{display:flex;gap:6px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Label/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CACA,6BAAA,CAGF,sBACE,YAAA,CACA,OAAA","sourcesContent":[".wrapLabel {\n  height: 24px;\n  display: flex;\n  column-gap: 4px;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.boxLabel {\n  display: flex;\n  gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapLabel": "bdgIqJHFgJd2dKf1QddA",
	"boxLabel": "fEPnnUonlYG1N1r59X9Z"
};
export default ___CSS_LOADER_EXPORT___;
