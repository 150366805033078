import { makeAutoObservable, runInAction } from 'mobx'
import { IToastType } from 'shared/ui'
import { errorHandler } from 'shared/api'
import {
  IntegrationsApi,
  IResponseUserIntegration,
  IIntegrationTagCreateDTO,
  IResponseIntegration,
  IResponseErrorIntegrationActivecampaignTagsApply,
  IResponseIntegrationsContactsActiveCampaignData,
  IResponseErrorIntegrationInfusionsoftTagsApply,
} from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'
import { ContactIntegrationActiveCampaignInfo } from 'entities/Integrations/model/ContactIntegrationActiveCampaignInfo'
import { ContactIntegrationHubspotInfo } from 'entities/Integrations/model/ContactIntegrationHubspotInfo'
import { ContactIntegrationPipedriveInfo } from 'entities/Integrations/model/ContactIntegrationPipedriveInfo'
import { ContactIntegrationSalesforceInfo } from 'entities/Integrations/model/ContactIntegrationSalesforceInfo'
import { ContactIntegrationInfusionsoftInfo } from 'entities/Integrations/model/ContactIntegrationInfusionsoftInfo'
import { Integration, IntegrationKey } from 'entities/Integrations/model/Integration'
import { UserIntegration } from 'entities/Integrations/model/UserIntegration'
import { HubspotList } from '../model/HubspotList'

type IContactIntegrationsInfo = {
  loadingIds: Map<number, number>
  loading: boolean
  unlinking: boolean
  hubspotInfoMap: Map<number, ContactIntegrationHubspotInfo>
  activeCampaignInfoMap: Map<number, ContactIntegrationActiveCampaignInfo>
  pipedriveInfoMap: Map<number, ContactIntegrationPipedriveInfo>
  salesforceInfoMap: Map<number, ContactIntegrationSalesforceInfo>
  infusionsoftInfoMap: Map<number, ContactIntegrationInfusionsoftInfo>
}
class IntegrationsStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = false
  integrationsMap: Map<string, Integration> = new Map()
  userIntegrationsMap: Map<string, UserIntegration> = new Map()
  userDisconnectedIntegrationsMap: Map<string, UserIntegration> = new Map()
  hubspotListsMap: Map<number, HubspotList> = new Map()

  contactIntegrationsInfo: IContactIntegrationsInfo = {
    loadingIds: new Map(),
    loading: false,
    unlinking: false,
    hubspotInfoMap: new Map(),
    activeCampaignInfoMap: new Map(),
    pipedriveInfoMap: new Map(),
    salesforceInfoMap: new Map(),
    infusionsoftInfoMap: new Map(),
  }

  addHubspotLists = (item: HubspotList) => {
    this.hubspotListsMap.set(+item.listId, item)
  }

  getHubspotList = (id: number) => {
    return this.hubspotListsMap.get(id)
  }

  getHubspotListByListId = (id: string) => {
    return Array.from(this.hubspotListsMap.values()).find((item) => item.listId === id)
  }

  fetchHubspotLists = async () => {
    const { data } = await IntegrationsApi.getHubspotLists()

    data.forEach((item) => this.addHubspotLists(new HubspotList(item)))
  }

  fetchIntegrations = async () => {
    if (this.hasIntegrations) return
    if (this.loading) return

    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await IntegrationsApi.getIntegrations()
      const integrations = data.all
      const userIntegrations = data.userIntegrations
      const userDisconnectedIntegrations = data.disconnected

      this.initIntegrations(integrations)
      this.initUserIntegrations(userIntegrations)
      this.initUserDisconnectedIntegrations(userDisconnectedIntegrations)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  addIntegration = (item: IResponseIntegration) => {
    const integration = this.integrationsMap.get(item.key)

    if (integration) {
      integration.syncOrigin({ ...integration.origin, ...item })
    } else {
      const integration = new Integration(item)
      this.integrationsMap.set(integration.key, integration)
    }
  }

  initIntegrations = (items: IResponseIntegration[]) => {
    items.forEach((item) => {
      this.addIntegration(item)
    })
  }

  addUserIntegration = (item: IResponseUserIntegration) => {
    const integration = this.userIntegrationsMap.get(item.integration.key)

    if (integration) {
      integration.syncOrigin({ ...integration.origin, ...item } as IResponseUserIntegration)
    } else {
      const userIntegration = new UserIntegration(item)
      this.userIntegrationsMap.set(userIntegration.key, userIntegration)
    }
  }
  addUserDisconnectIntegration = (item: IResponseUserIntegration) => {
    const integration = this.userDisconnectedIntegrationsMap.get(item.integration.key)

    if (integration) {
      integration.syncOrigin({ ...integration.origin, ...item } as IResponseUserIntegration)
    } else {
      const userIntegration = new UserIntegration(item)
      this.userDisconnectedIntegrationsMap.set(userIntegration.key, userIntegration)
    }
  }

  initUserIntegrations = (items: IResponseUserIntegration[]) => {
    items.forEach((item) => {
      this.addUserIntegration(item)
    })
  }
  initUserDisconnectedIntegrations = (items: IResponseUserIntegration[]) => {
    items.forEach((item) => {
      this.addUserDisconnectIntegration(item)
    })
  }

  connectUserIntegration = (item: IResponseUserIntegration) => {
    this.addUserIntegration(item)
    this.userDisconnectedIntegrationsMap.delete(item.integration.key)
  }

  disconnectUserIntegration = (item: IResponseUserIntegration) => {
    this.userIntegrationsMap.delete(item.integration.key)
    this.deleteUserIntegrationInfo(item.user_id, item.integration.key)
    this.addUserDisconnectIntegration(item)
  }

  deleteUserIntegrationInfo = (id: number, key?: IntegrationKey | string) => {
    if (!key) return

    switch (key) {
      case IntegrationKey.hubspot: {
        this.contactIntegrationsInfo.hubspotInfoMap.delete(id)
        return
      }
      case IntegrationKey.salesforce: {
        this.contactIntegrationsInfo.salesforceInfoMap.delete(id)
        return
      }
      case IntegrationKey.activecampaign: {
        this.contactIntegrationsInfo.activeCampaignInfoMap.delete(id)
        return
      }
      case IntegrationKey.pipedrive: {
        this.contactIntegrationsInfo.pipedriveInfoMap.delete(id)
        return
      }
      case IntegrationKey.infusionsoft: {
        this.contactIntegrationsInfo.infusionsoftInfoMap.delete(id)
        return
      }
    }
  }

  getListOfIntegrations = (keys: Array<IntegrationKey>) => {
    return keys
      .map((key) => this.integrationsMap.get(key))
      .filter((item): item is Integration => Boolean(item))
  }

  getIntegration = (key?: IntegrationKey | string) => {
    if (!key) return null

    return this.integrationsMap.get(key) || null
  }

  getUserIntegration = (key?: IntegrationKey | string) => {
    if (!key) return null

    return this.userIntegrationsMap.get(key) || null
  }

  hasUserIntegration = (key: IntegrationKey | string) => {
    return Boolean(this.getUserIntegration(key))
  }

  get hasIntegrations() {
    return Boolean(this.integrations.length)
  }

  get integrations() {
    return Array.from(this.integrationsMap.values())
  }

  get userIntegrations() {
    return Array.from(this.userIntegrationsMap.values())
  }

  unlinkContactsIntegrations = async (id: number, key?: IntegrationKey | string) => {
    try {
      this.contactIntegrationsInfo.unlinking = true

      const { data } = await IntegrationsApi.unlinkContactsByIdIntegrations(id)
      this.deleteUserIntegrationInfo(id, key)
      contactsStore.updateItem(data)
    } catch (e) {
      console.log('ERROR: unlinkContactsIntegrations: ', e)
    } finally {
      this.contactIntegrationsInfo.unlinking = false
    }
  }

  initContactIntegrationHubspotInfo = async (id: number) => {
    if (this.contactIntegrationsInfo.loadingIds.has(id)) return

    const emptyData = {
      deals: [],
      properties: {},
      companies: [],
      contactId: id,
    }

    try {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = true
        this.contactIntegrationsInfo.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsHubspotInfo(id)
      if (!data) {
        const info = new ContactIntegrationHubspotInfo({ ...emptyData, contactId: id })

        runInAction(() => {
          this.contactIntegrationsInfo.hubspotInfoMap.set(id, info)
        })

        return
      }

      const info = new ContactIntegrationHubspotInfo({ ...data.data, contactId: id })

      runInAction(() => {
        this.contactIntegrationsInfo.hubspotInfoMap.set(id, info)
      })
    } catch (e) {
      console.log('ERROR: initContactIntegrationHubspotInfo: ', e)

      const info = new ContactIntegrationHubspotInfo({ ...emptyData, contactId: id })

      runInAction(() => {
        this.contactIntegrationsInfo.hubspotInfoMap.set(id, info)
      })
    } finally {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = false
        this.contactIntegrationsInfo.loadingIds.delete(id)
      })
    }
  }

  createContactsIntegrationsActiveCampaignTag = async (reqData: IIntegrationTagCreateDTO) => {
    const message: {
      message: string
      status: IToastType
    } = {
      message: '',
      status: 'success',
    }

    try {
      const { data } = await IntegrationsApi.createContactsIntegrationsActiveCampaignTag(reqData)

      data.message = data?.message || ''

      return message
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<IResponseErrorIntegrationActivecampaignTagsApply>(
        err
      )

      message.message = type === 'axios-error' ? error.response?.data.tag[0] || '' : ''
      message.status = 'error'

      return message
    }
  }

  createContactsIntegrationInfusionsoftTag = async (reqData: IIntegrationTagCreateDTO) => {
    try {
      const res = await IntegrationsApi.createContactsIntegrationsInfusionsoftTag(reqData)

      const data: {
        message: string
        status: IToastType
      } = {
        message: res?.data?.message || '',
        status: 'success',
      }

      return data
    } catch (e) {
      console.log('ERROR: createContactsIntegrationInfusionsoftTag: ', e)

      const err = e as Error
      const { type, error } = await errorHandler<IResponseErrorIntegrationInfusionsoftTagsApply>(
        err
      )

      const data: {
        message: string
        status: IToastType
      } = {
        message: '',
        status: 'error',
      }

      if (type === 'axios-error') {
        data.message = error?.response?.data?.tag?.[0] || ''
      }

      return data
    }
  }

  getContactsIntegrationInfusionsoftTags = async (value: string) => {
    try {
      const res = await IntegrationsApi.getContactsIntegrationsInfusionsoftTags(
        encodeURIComponent(value)
      )

      return res.data
    } catch (e) {
      console.log('ERROR: getContactsIntegrationInfusionsoftTags: ', e)
      return []
    }
  }

  initContactIntegrationActiveCampaignInfo = async (id: number) => {
    if (this.contactIntegrationsInfo.loadingIds.has(id)) return

    const emptyData: IResponseIntegrationsContactsActiveCampaignData = {
      contactId: id,
      deals: [],
    }

    const emptyInfo = new ContactIntegrationActiveCampaignInfo(emptyData)

    try {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = true
        this.contactIntegrationsInfo.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsActiveCampaignInfo(id)

      if (!data) {
        this.contactIntegrationsInfo.activeCampaignInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationActiveCampaignInfo({ ...data, contactId: id })
      this.contactIntegrationsInfo.activeCampaignInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationActiveCampaignInfo: ', e)

      this.contactIntegrationsInfo.activeCampaignInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = false
        this.contactIntegrationsInfo.loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationPipedriveInfo = async (id: number) => {
    if (this.contactIntegrationsInfo.loadingIds.has(id)) return

    const emptyData = {
      contactId: id,
      deal: [],
      properties: null,
      organization: null,
    }

    const emptyInfo = new ContactIntegrationPipedriveInfo(emptyData)

    try {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = true
        this.contactIntegrationsInfo.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsPipedriveInfo(id)

      if (!data) {
        this.contactIntegrationsInfo.pipedriveInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationPipedriveInfo({ ...data, contactId: id })
      this.contactIntegrationsInfo.pipedriveInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationPipedriveInfo: ', e)

      this.contactIntegrationsInfo.pipedriveInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = false
        this.contactIntegrationsInfo.loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationSalesforceInfo = async (id: number) => {
    if (this.contactIntegrationsInfo.loadingIds.has(id)) return

    const emptyData = {
      contactId: id,
      properties: null,
      opportunities: [],
    }

    const emptyInfo = new ContactIntegrationSalesforceInfo(emptyData)

    try {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = true
        this.contactIntegrationsInfo.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsSalesforceInfo(id)

      if (!data) {
        this.contactIntegrationsInfo.salesforceInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationSalesforceInfo({ ...data, contactId: id })
      this.contactIntegrationsInfo.salesforceInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationSalesforceInfo: ', e)

      this.contactIntegrationsInfo.salesforceInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = false
        this.contactIntegrationsInfo.loadingIds.delete(id)
      })
    }
  }

  initContactIntegrationInfusionsoftInfo = async (id: number) => {
    if (this.contactIntegrationsInfo.loadingIds.has(id)) return
    const emptyInfo = new ContactIntegrationInfusionsoftInfo(null, id)

    try {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = true
        this.contactIntegrationsInfo.loadingIds.set(id, id)
      })

      const { data } = await IntegrationsApi.getContactsIntegrationsInfusionsoftInfo(id)

      if (!data) {
        this.contactIntegrationsInfo.infusionsoftInfoMap.set(id, emptyInfo)
        return
      }

      const info = new ContactIntegrationInfusionsoftInfo(data, id)
      this.contactIntegrationsInfo.infusionsoftInfoMap.set(id, info)
    } catch (e) {
      console.log('ERROR: initContactIntegrationInfusionsoftInfo: ', e)

      this.contactIntegrationsInfo.infusionsoftInfoMap.set(id, emptyInfo)
    } finally {
      runInAction(() => {
        this.contactIntegrationsInfo.loading = false
        this.contactIntegrationsInfo.loadingIds.delete(id)
      })
    }
  }

  getContactIntegrationHubspotInfo = (id: number) => {
    const info = this.contactIntegrationsInfo.hubspotInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationHubspotInfo(id)
    }

    return info
  }

  getContactIntegrationActiveCampaignInfo = (id: number) => {
    const info = this.contactIntegrationsInfo.activeCampaignInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationActiveCampaignInfo(id)
    }

    return info
  }

  getContactIntegrationPipedriveInfo = (id: number) => {
    const info = this.contactIntegrationsInfo.pipedriveInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationPipedriveInfo(id)
    }

    return info
  }

  getContactIntegrationSalesforceInfo = (id: number) => {
    const info = this.contactIntegrationsInfo.salesforceInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationSalesforceInfo(id)
    }

    return info
  }

  getContactIntegrationInfusionsoftInfo = (id: number) => {
    const info = this.contactIntegrationsInfo.infusionsoftInfoMap.get(id)

    if (!info) {
      this.initContactIntegrationInfusionsoftInfo(id)
    }

    return info
  }
}

export const integrationsStore = new IntegrationsStore()
