// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hsBC9xJhRKEkbpEUze0A{position:sticky;background-color:var(--background-primary-inverted-primary);z-index:2;left:0;top:0;display:table-cell;vertical-align:inherit;padding:0}.hsBC9xJhRKEkbpEUze0A.g5Xvhf5IpZe0CLy1DgL4:before{right:0;content:\"\";position:absolute;width:1px;height:40px;background:var(--black-20)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/PinnedCell/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,2DAAA,CACA,SAAA,CACA,MAAA,CACA,KAAA,CACA,kBAAA,CACA,sBAAA,CACA,SAAA,CAEE,kDACE,OAAA,CACA,UAAA,CACA,iBAAA,CACA,SAAA,CACA,WAAA,CACA,0BAAA","sourcesContent":[".pinnedRow {\n    position: sticky;\n    background-color: var(--background-primary-inverted-primary);\n    z-index: 2;\n    left: 0;\n    top: 0;\n    display: table-cell;\n    vertical-align: inherit;\n    padding: 0;\n    &.borderRight{\n      &:before{\n        right: 0;\n        content: '';\n        position: absolute;\n        width: 1px;\n        height: 40px;\n        background: var(--black-20);\n      }\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pinnedRow": "hsBC9xJhRKEkbpEUze0A",
	"borderRight": "g5Xvhf5IpZe0CLy1DgL4"
};
export default ___CSS_LOADER_EXPORT___;
