import React from 'react'
import { Button } from 'shared/ui'
import styles from './styles.module.scss'

type ISwitchModeProps = {
  toggle: boolean
  isMMS: boolean
  onChange: () => void
}

export const SwitchMode: React.FC<ISwitchModeProps> = ({ toggle, isMMS, onChange }) => {
  const mode = isMMS ? 'MMS' : 'SMS'
  return (
    <div className={styles.wrap}>
      <Button
        typeBtn={'contained'}
        contained={'secondary'}
        intent={toggle ? 'warning' : 'default'}
        size={'small'}
        iconProps={{
          icon: toggle ? 'edit' : 'messageSmile',
          fontSize: 12,
        }}
        text={toggle ? 'Note' : mode}
        onClick={() => {
          onChange()
        }}
      />
    </div>
  )
}
