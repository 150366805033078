import { cloneDeep } from 'lodash'
import { observer } from 'mobx-react-lite'
import { EnumDropdownItemVariant, FilterDropdown, IDropdownItem } from 'shared/ui'
import { usersStore } from 'entities/Users'
import { useContactsTableContext } from 'widgets/ContactsTable'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'

export const ContactTableFilterDropdown = observer(() => {
  const contactsTableStore = useContactsTableContext()
  const { updateActiveFilters, isLoading } = contactsTableStore
  const { filterGroupsOrigin } = contactsFilterStore.filterEditorStore
  const { user } = usersStore

  if (!user || !filterGroupsOrigin.length) {
    return null
  }
  const { activeFields } = user
  const hideFields = ['first_name', 'last_name']
  const hideCategory = ['numbers', 'segments', 'integrations']
  const disabledValues = ['name', 'number']
  const filterName = {
    id: 'name',
    label: 'Name',
    visible: true,
    type: 'text',
    editable: false,
    isCardClick: false,
    customFields: {
      type: 'text',
    },
  }

  const filterDropdownGroups = cloneDeep(filterGroupsOrigin)
    .filter((item) => !hideCategory.includes(String(item.category)))
    .map((filterGroup) => {
      if (filterGroup.category === 'contact-fields') filterGroup.items.unshift(filterName)

      filterGroup.items = filterGroup.items
        .filter((item) => !hideFields.includes(String(item.id)))
        .map((item) => {
          return {
            ...item,
            variant: EnumDropdownItemVariant.Checkbox,
            activeValue: activeFields.includes(String(item.id)),
            disabled: disabledValues.includes(String(item.id)),
            iconL: undefined,
          }
        })

      return filterGroup
    })

  const onChangeItem = (item: IDropdownItem) => {
    updateActiveFilters(String(item.id))
  }

  return (
    <FilterDropdown
      isLoading={isLoading}
      filterDropdownGroups={filterDropdownGroups}
      changeActiveFields={onChangeItem}
      margin={-30}
      iconButtonProps={{
        color: 'tertiary',
        ariaLabel: 'ContactTableFilterDropdown_trigger',
      }}
      placement='bottom-end'
      tooltipLabel='Manage columns'
    />
  )
})
