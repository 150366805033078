import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'
import React, { useRef, useState } from 'react'
import { AvatarGroupInLine, Typography, Tooltip, Avatar } from 'shared/ui'
import { IViewingUser } from 'entities/Viewing'
import styles from './styles.module.scss'

type IMembersTypingCardProps = {
  items: IViewingUser[]
}

export const MembersTypingCard = observer(({ items }: IMembersTypingCardProps) => {
  const [open, setOpen] = useState(false)
  const info = items.map((item) => item?.user?.avatarInfo)
  const ref = useRef<HTMLElement | null>(null)

  const tooltipContent = () => {
    return (
      <div className={styles.wrap}>
        {items.map((item) => {
          if (item?.user) {
            return (
              <div className={styles.card} key={item.user.id}>
                <Avatar info={item.user.avatarInfo} size={16} />
                <Typography
                  className={styles.name}
                  ariaLabel={'TooltipInbox'}
                  variant={'body-md-regular'}
                  color={'var(--content-primary-primary-inverted)'}
                >
                  {item.user.full_name}
                </Typography>
                <Typography
                  className={styles.status}
                  ariaLabel={'TooltipInboxStatus'}
                  variant={'body-md-regular'}
                  color={'var(--content-primary-tertiary-inverted)'}
                >
                  {item.status}
                </Typography>
              </div>
            )
          }

          return null
        })}
      </div>
    )
  }

  return (
    <>
      <Box
        display={'flex'}
        gap={1}
        alignItems={'center'}
        height={32}
        ref={ref}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <AvatarGroupInLine items={info} size={16} />
        <Typography
          ariaLabel={'Inbox'}
          variant={'body-xs-regular'}
          color={'var(--content-primary-tertiary)'}
        >
          {items.length > 1 ? `${items.length} members` : '1 member'} in this chat
        </Typography>
      </Box>
      <Tooltip
        fullWidth
        label={tooltipContent()}
        placement={'top'}
        open={open}
        PopperProps={{
          anchorEl: () => ref.current as HTMLElement,
        }}
      />
    </>
  )
})
