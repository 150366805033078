import { makeAutoObservable, reaction, type IReactionDisposer } from 'mobx'
import { type PageLayoutStore } from 'shared/layout'
import type { Contact } from 'entities/Contacts/model/Contact'
import {
  CreateContactErrors,
  TrialContactsLimitModal,
} from 'entities/Subscription/ui/TrialContactsLimitModal'
import type { IParamsContactsOptOut } from 'entities/Contacts/api/types'
import { successOpIinHandler } from 'entities/Contacts/lib/successOpIinHandler'
import { openConversationByContact } from 'features/OpenConversation'
import {
  contactsDetailsGlobalStore,
  ContactsDetailsManageStore,
} from 'widgets/ContactsDetails/store'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'
import { ContactsTableStore } from 'pages/contacts/ui/ContactsTable'
import { ImportContactsStore } from 'pages/contacts/ui/ImportContacts'
import { ContactsMenuStore } from 'pages/contacts/ui/ContactsMenu'
import { ContactsFilterStore } from 'pages/contacts/ui/ContactsFilters'
import { SegmentModalStore } from 'pages/contacts/ui/ContactsMenu/ui/SegmentModal'

export class ContactsPageStore {
  private _importContactsStore = new ImportContactsStore()
  private _contactsFilterStore = new ContactsFilterStore()
  private _contactsDetailsManageStore = new ContactsDetailsManageStore()
  private _contactsTableStore = new ContactsTableStore(
    this._pageLayoutStore,
    this._contactsDetailsManageStore
  )
  private _disposeContactsDetails: IReactionDisposer | null = null

  constructor(
    private _pageLayoutStore: PageLayoutStore,
    private _contactsMenuStore: ContactsMenuStore,
    private _segmentModalStore: SegmentModalStore
  ) {
    makeAutoObservable(this)

    this._contactsTableStore.setConfig({
      onChangeNoEmptyFilters: (value) => {
        this._contactsMenuStore.setNoEmptyFilters(value)
      },
      onSearch: (value: string) => {
        this._contactsMenuStore.setSearch(value)
      },
      onTableWidth: (value) => {
        this._contactsFilterStore.setTableWidth(value)
      },
      onChangeAlert: (item) => {
        this._contactsDetailsManageStore.contactsDetailsStore?.setAlert(item)
      },
    })

    this._contactsFilterStore.setConfig({
      changeSearchAndResetPaginationPage: (value: string) => {
        this._contactsTableStore.changeSearchAndResetPaginationPage(value)
      },
      changeSegmentBoxHasChanges: (value) => {
        this._contactsMenuStore.setSegmentBoxHasChanges(value)
      },
      changeSegment: (segment) => {
        this._segmentModalStore.setSegment(segment)
        this._contactsMenuStore.setActiveSegment(segment)
        this._contactsTableStore.setSegment(segment)
      },
      changeSegmentFilter: (value) => {
        this._segmentModalStore.setSegmentFilter(value.filtersList)
        this._contactsTableStore.setSegmentFilter(value.filtersList)
      },
      changeFilterGroups: (value) => {
        this._contactsTableStore.setFilterGroups(value)
      },
      changeFilterConfig: (value) => {
        this._contactsTableStore.setFilterConfig(value)
      },
      changeFilterGroupsOrigin: (value) => {
        this._contactsTableStore.setFilterGroupsOrigin(value)
      },
    })

    this._contactsMenuStore.setConfig({
      setActiveSegment: (segment) => {
        if (segment.id !== -1) {
          this._contactsFilterStore.setSegment(segment)
        }
      },
      changeActiveSegment: () => {
        this._contactsTableStore.setActiveContact(null)
        this._contactsTableStore.changeNewContactMode(false)
      },
      resetActive: () => {
        this._contactsFilterStore.clearSegment()
      },
      onActionAllContacts: () => {
        this._contactsTableStore.setLoadingContacts(true)
        this._contactsTableStore.debounceLoadContacts()
        this._contactsFilterStore.onOpen(false)
        this._contactsFilterStore.segmentBox.setHasChanges(false)
      },
      onActionChangeSegment: () => {
        this._contactsTableStore.changePaginationToFirst()
        this._contactsFilterStore.onOpen(false)
        this._contactsFilterStore.segmentBox.setHasChanges(false)
        this._contactsTableStore.tableStore.resetSelected()
        this._contactsTableStore.setLoadingContacts(true)
        this._contactsTableStore.debounceLoadContacts()
      },
      onActionFilterSegmentBoxSetHasChanges: (value) => {
        this._contactsFilterStore.segmentBox.setHasChanges(value)
      },
      onActionFilterOpen: (value) => {
        this._contactsFilterStore.onOpen(value)
      },
    })

    this._segmentModalStore.setConfig({
      setSegment: (segment) => {
        this._contactsFilterStore.setSegment(segment)
      },
      onActionEdit: () => {
        this._segmentModalStore.editItem({
          afterEdit: () => this._contactsFilterStore.segmentBox.setHasChanges(false),
        })
        this._contactsFilterStore.onOpen(false)
      },
      onActionNew: () => {
        this._segmentModalStore.createItem(this._contactsTableStore.noEmptyFilters)
        this._contactsFilterStore.onOpen(false)
        this._contactsFilterStore.segmentBox.setHasChanges(false)
      },
    })

    this.reactionContactsDetails()

    this.init()
    this._contactsMenuStore.setFinishInit?.()
  }

  reactionContactsDetails = () => {
    this._disposeContactsDetails?.()
    this._disposeContactsDetails = reaction(
      () => this._contactsTableStore.activeContact,
      (contact) => {
        if (!contact) {
          this._contactsDetailsManageStore.resetContactsDetails()

          return
        }

        const onUpdateContact = this._contactsTableStore.updateContactLocally
        const onCloseContactDetails = () => {
          this._contactsTableStore.setActiveContact(null)
          contactsDetailsGlobalStore.handleChangeCollapse(true)
        }
        const onStopCreateNewContact = this._contactsTableStore.resetNewContactMode
        const addNewContactLocallyAfterCreate = (
          contact: Contact,
          params: { isNew?: boolean } = {}
        ) => {
          this._contactsTableStore.changePaginationToFirst()

          if (contact.isDisabled) {
            new TrialContactsLimitModal().open(CreateContactErrors.trialLimit)
            this._contactsTableStore.resetNewContactMode()
            contactsDetailsGlobalStore.handleToggleCollapse()
          }

          if (!contact.isDisabled) {
            this._contactsTableStore.addNewContactLocallyAfterCreate(contact, params)
          }
        }

        this._contactsDetailsManageStore.setContactsDetails({
          contactId: contact.id,
          variant: 'contacts',
          profileCardProps: {
            onClickMessage: (contact: Contact) => {
              this._contactsTableStore.setLoading(true)
              openConversationByContact(contact).then(() =>
                this._contactsTableStore.setLoading(false)
              )
            },
            enableChat: true,
          },
          onUpdateContact: onUpdateContact,
          onCloseContactDetails: onCloseContactDetails,
          onStopCreateNewContact: onStopCreateNewContact,
          addNewContactLocallyAfterCreate: addNewContactLocallyAfterCreate,
          notesProps: {
            hideMessageFieldActions: ['ai_assistant'],
          },
          resetAlert: () => {
            this._contactsTableStore.resetAlert()
          },
          onChangeIsFirstEditMode: (value) => {
            this._contactsTableStore.setIsFirstEditMode(value)
          },
          resetActiveAndCloseDetailsIsMobile: () => {
            this._contactsTableStore.resetActiveAndCloseDetailsIsMobile()
          },
          handleOptIn: (contact: Contact) => {
            const actualContact = this._contactsTableStore.getContactById(contact.id)

            contactsOptInModalStore.handleOpen([actualContact || contact], {
              successHandler: (opt_outs: IParamsContactsOptOut['opt_outs']) => {
                successOpIinHandler(contact, opt_outs)

                if (actualContact) {
                  successOpIinHandler(actualContact, opt_outs)
                }

                if (this._contactsTableStore.activeContact) {
                  successOpIinHandler(this._contactsTableStore.activeContact, opt_outs)
                }
              },
              isBulkAll: false,
            })
          },
        })
      }
    )
  }

  init = () => {
    this._contactsDetailsManageStore.init()
  }

  reset = () => {
    this._disposeContactsDetails?.()
    this._contactsDetailsManageStore.reset()
  }

  get importContactsStore() {
    return this._importContactsStore
  }

  get contactsFilterStore() {
    return this._contactsFilterStore
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get contactsMenuStore() {
    return this._contactsMenuStore
  }

  get contactsTableStore() {
    return this._contactsTableStore
  }

  get segmentModalStore() {
    return this._segmentModalStore
  }

  get contactsDetailsStore() {
    return this._contactsDetailsManageStore.contactsDetailsStore
  }
}
