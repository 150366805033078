// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F61db3zXef9Hi6FzIegp{border-top:1px solid var(--border-neutral-default);padding:4px}.CJq7ABNLR7mMhcM2f3ve{display:flex;padding:8px 12px 4px}", "",{"version":3,"sources":["webpack://./src/widgets/MultiOrganizationsDropdown/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kDAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,oBAAA","sourcesContent":[".dropdownItemWrap {\n  border-top: 1px solid var(--border-neutral-default);\n  padding: 4px;\n}\n\n.label {\n  display: flex;\n  padding: 8px 12px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownItemWrap": "F61db3zXef9Hi6FzIegp",
	"label": "CJq7ABNLR7mMhcM2f3ve"
};
export default ___CSS_LOADER_EXPORT___;
