import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, Typography } from 'shared/ui'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { formatBytes, getAriaLabel } from 'shared/lib'
import { Attachment } from 'entities/Attachment/model/Attachment'
import styles from './styles.module.scss'

type IAttachmentCardContentProps = {
  attachment?: Attachment
  file?: File
  fromAudioCard?: boolean
}

export const AttachmentCardContent: FC<IAttachmentCardContentProps> = observer(
  ({ attachment, file, fromAudioCard }) => {
    const fileName = attachment?.name || file?.name
    const ext = fileName?.split('.').pop()
    const name = fileName?.split('.').shift()
    const firstPart = name?.slice(0, -3)
    const secondPart = name?.slice(-3)
    const rightPart = `${secondPart}.${ext}`

    return (
      <div
        className={classNames(styles.content, {
          [styles.fromAudioCard]: fromAudioCard,
        })}
        aria-label={getAriaLabel('AttachmentCardContent')}
      >
        <div
          data-card-icon
          className={classNames(styles.audioBtn, {
            [styles.white]: fromAudioCard,
          })}
        >
          <Icon
            icon={attachment?.type === 'audio' ? 'audio' : 'attachment'}
            tertiary
            fontSize={20}
          />
        </div>
        <div className={styles.right}>
          <div title={fileName} className={styles.title}>
            <Typography
              variant={'body-rg-medium'}
              ellipsis
              title={fileName}
              ariaLabel={'firstPart'}
            >
              {firstPart}
            </Typography>
            <Typography variant={'body-rg-medium'} ariaLabel={'rightPart'}>
              {rightPart}
            </Typography>
          </div>

          <Typography className={styles.time} variant={'body-sm-regular'} ariaLabel={'info'}>
            {attachment?.type === 'audio' && !fromAudioCard
              ? attachment?.duration && secondsToTime(attachment?.duration)
              : (attachment?.size && formatBytes(+attachment.size)) ||
                (file?.size && formatBytes(+file.size))}
          </Typography>
        </div>
      </div>
    )
  }
)
