import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { NumbersRoutes } from 'pages/settings/pages/numbers/route/NumbersRoutes'
import { AssignedNumbers } from 'pages/settings/pages/numbers/ui/AssignedNumbers/AssignedNumbers'
import { UnassignedNumbers } from 'pages/settings/pages/numbers/ui/UnassignedNumbers/UnassignedNumbers'
import { NumbersLayout } from 'pages/settings/pages/numbers/layout/NumbersLayout'

export const NumbersRouter = observer(() => {
  return (
    <Routes>
      <Route path='/' element={<NumbersLayout />}>
        <Route path={NumbersRoutes.assigned} element={<AssignedNumbers />} />
        <Route path={NumbersRoutes.unassigned} element={<UnassignedNumbers />} />
      </Route>
      <Route path={'*'} element={<Navigate to={'/'} />} />
    </Routes>
  )
})
