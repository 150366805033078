import { observer } from 'mobx-react-lite'
import { Icon, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IScheduleCountProps = {
  scheduleCount?: number
}

export const ScheduleCount = observer(({ scheduleCount }: IScheduleCountProps) => {
  if (!scheduleCount) {
    return null
  }

  return (
    <div className={styles.wrap}>
      <Icon icon={'schedule'} tertiary fontSize={16} />
      <Typography variant={'text-input-helper'} color={'tertiary'} ariaLabel={'scheduled'}>
        {scheduleCount} scheduled {scheduleCount === 1 ? 'message' : 'messages'}.
      </Typography>
    </div>
  )
})
