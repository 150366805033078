import { createContext, useContext } from 'react'
import type { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'

export const CallPopUpDefaultContext = createContext<CallPopUpDefaultStore | null>(null)

export function useCallPopUpDefaultContext() {
  const context = useContext(CallPopUpDefaultContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with CallPopUpDefaultStoreProvider'
    )
  }
  return context
}
