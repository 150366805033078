import { createContext, useContext } from 'react'
import { type RecurringStore } from 'shared/ui/Schedule/ui/Recurring/store/recurringStore'

export const RecurringContext = createContext<RecurringStore | null>(null)

export function useRecurringContext() {
  const context = useContext(RecurringContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with RecurringContext')
  }
  return context
}
