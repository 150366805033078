// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YV3rZjfw12lGzD5E4KTf{color:var(--content-negative-primary)}.iUtf6TCoegCRQY3OCW5G{display:block;width:8px;height:8px;border-radius:8px;margin:0 8px 0 8px;background-color:#3eb786}._Pgkb15uk89rNdGyVtn7{display:flex;align-items:center;height:100%}.I6zzP5gdYEfa2kqN_Auq{display:flex;align-items:center;margin:0 8px 0 8px;position:relative;width:18px}.I6zzP5gdYEfa2kqN_Auq .I9sSlZNwoIRspWtNrgSi{position:absolute;display:block;width:10px;height:10px;border-radius:8px;border:2px var(--background-primary-inverted-primary) solid;background-color:#3eb786}.qC8Q1vIusoZnpVws_gj3{color:var(--content-primary-primary);line-height:normal;font-weight:400;font-size:13px}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/Widget/WidgetMultiSelect/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CAGF,sBACE,aAAA,CACA,SAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA,CACA,wBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,UAAA,CAEA,4CACE,iBAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,2DAAA,CACA,wBAAA,CAIJ,sBACE,oCAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".warning {\n  color: var(--content-negative-primary);\n}\n\n.color {\n  display: block;\n  width: 8px;\n  height: 8px;\n  border-radius: 8px;\n  margin: 0 8px 0 8px;\n  background-color: rgb(62, 183, 134);\n}\n\n.countLabelWrapper {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n\n.countColors {\n  display: flex;\n  align-items: center;\n  margin: 0 8px 0 8px;\n  position: relative;\n  width: 18px;\n\n  .countColor {\n    position: absolute;\n    display: block;\n    width: 10px;\n    height: 10px;\n    border-radius: 8px;\n    border: 2px var(--background-primary-inverted-primary) solid;\n    background-color: rgb(62, 183, 134);\n  }\n}\n\n.countLabel {\n  color: var(--content-primary-primary);\n  line-height: normal;\n  font-weight: 400;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "YV3rZjfw12lGzD5E4KTf",
	"color": "iUtf6TCoegCRQY3OCW5G",
	"countLabelWrapper": "_Pgkb15uk89rNdGyVtn7",
	"countColors": "I6zzP5gdYEfa2kqN_Auq",
	"countColor": "I9sSlZNwoIRspWtNrgSi",
	"countLabel": "qC8Q1vIusoZnpVws_gj3"
};
export default ___CSS_LOADER_EXPORT___;
