import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Menu, IconButton } from 'shared/ui'
import { layoutStore } from 'shared/layout'
import { EnumIconButtonShape } from 'shared/ui/IconButton/IconButton'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { EventConversationUnread } from 'widgets/ConversationMenu/events/EventConversationUnread'
import { ConversationHeaderListDropdown } from 'widgets/ConversationHeaderListDropdown'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'
import { ConversationMenuInboxes } from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/ConversationMenuInboxes'
import styles from './styles.module.scss'

export const ConversationMenuContent = observer(() => {
  const store = useConversationMenuContext()
  const { isMobileView } = layoutStore
  const { handleChangeCollapse } = store
  const { isShouldRequestAccess } = usersStore.user

  useEffect(() => {
    EventConversationUnread.subscribe()
    store.reactionInboxSize()

    return () => {
      EventConversationUnread.unsubscribe()
      store.resetReactions()
    }
  }, [])

  if (store.isCollapse) return null

  const isMobileViewStyle = isMobileView && !store.isCollapse

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.mobileView]: isMobileViewStyle,
      })}
    >
      <Menu
        title='Conversations'
        headerButtons={[
          {
            iconButtonComponent: (
              <IconButton
                onClick={() => {
                  store.onShowChats?.()
                  isMobileView ? store.onOpenSearch?.() : store.onToggleSearch?.()
                  isMobileView && handleChangeCollapse(true)
                }}
                icon={'search'}
                disabled={isShouldRequestAccess}
                fontSize={20}
                shape={EnumIconButtonShape.Rounded}
                color={isShouldRequestAccess ? 'transparent' : 'secondary'}
                variant={'icon'}
                tooltipProps={
                  !isShouldRequestAccess
                    ? { placement: 'bottom', label: 'Search', disableInteractive: true }
                    : undefined
                }
                ariaLabel='ConversationMenu_search'
              />
            ),
          },
          {
            iconButtonComponent: (
              <ConversationHeaderListDropdown
                disabled={isShouldRequestAccess}
                onChangeCall={() => {
                  isMobileView && handleChangeCollapse(true)
                  store.callModalStore.openModal()
                }}
                onChangeNew={() => {
                  isMobileView && handleChangeCollapse(true)
                  conversationStore.handleOpenNewConversation()
                }}
                onChangeContactCreate={() => {
                  isMobileView && handleChangeCollapse(true)
                  store.contactCreateModalStore.openModal()
                }}
              />
            ),
          },
        ]}
        menuContent={<ConversationMenuInboxes />}
      />
    </div>
  )
})
