// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zMu3ZvWC9znJvTOZ3UI3 .Yzcmv2qNMV9xzdtKnhTA{border-radius:6px;border:1px solid var(--border-neutral-default)}.zMu3ZvWC9znJvTOZ3UI3 .Yzcmv2qNMV9xzdtKnhTA .Y0bKD_g9itiqBOnv90dB{height:44px;border-bottom:1px solid var(--border-neutral-default)}.zMu3ZvWC9znJvTOZ3UI3 .Yzcmv2qNMV9xzdtKnhTA .Y0bKD_g9itiqBOnv90dB .lWfTwtq3E7oKnuFeD9Qs{width:100%;display:flex;align-items:center;justify-content:space-between}.zMu3ZvWC9znJvTOZ3UI3 .Yzcmv2qNMV9xzdtKnhTA .Y0bKD_g9itiqBOnv90dB .lWfTwtq3E7oKnuFeD9Qs .igDG_bLD84z7JfFW99FG{display:flex;align-items:center;gap:8px}.zMu3ZvWC9znJvTOZ3UI3 .LLXDqgJRYxBqiEfRWPFf{height:44px;font-size:14;font-weight:500;margin-top:40px}.RDEzWzvNLBBz3jdiPSRi{display:flex;align-items:center;gap:8px}.RDEzWzvNLBBz3jdiPSRi svg{width:16px;height:auto}", "",{"version":3,"sources":["webpack://./src/widgets/Register/ui/Step3/ui/Step3Form/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,iBAAA,CACA,8CAAA,CAEA,kEACE,WAAA,CACA,qDAAA,CAEA,wFACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,8GACE,YAAA,CACA,kBAAA,CACA,OAAA,CAMR,4CACE,WAAA,CACA,YAAA,CACA,eAAA,CACA,eAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,0BACE,UAAA,CACA,WAAA","sourcesContent":[".wrap {\n  .list {\n    border-radius: 6px;\n    border: 1px solid var(--border-neutral-default);\n\n    .wrapInput {\n      height: 44px;\n      border-bottom: 1px solid var(--border-neutral-default);\n\n      .wrapLabel {\n        width: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n\n        .label {\n          display: flex;\n          align-items: center;\n          gap: 8px;\n        }\n      }\n    }\n  }\n\n  .button {\n    height: 44px;\n    font-size: 14;\n    font-weight: 500;\n    margin-top: 40px;\n  }\n}\n\n.customCountry {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  svg {\n    width: 16px;\n    height: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "zMu3ZvWC9znJvTOZ3UI3",
	"list": "Yzcmv2qNMV9xzdtKnhTA",
	"wrapInput": "Y0bKD_g9itiqBOnv90dB",
	"wrapLabel": "lWfTwtq3E7oKnuFeD9Qs",
	"label": "igDG_bLD84z7JfFW99FG",
	"button": "LLXDqgJRYxBqiEfRWPFf",
	"customCountry": "RDEzWzvNLBBz3jdiPSRi"
};
export default ___CSS_LOADER_EXPORT___;
