import { createContext, useContext } from 'react'
import { IntegrationDropdownStore } from 'widgets/IntegrationDropdown/store/integrationDropdownStore'

export const IntegrationDropdownContext = createContext<IntegrationDropdownStore | null>(null)

export function useIntegrationDropdownContext() {
  const context = useContext(IntegrationDropdownContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with IntegrationDropdownStore')
  }
  return context
}
