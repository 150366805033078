import React from 'react'
import { observer } from 'mobx-react-lite'
import { uiStore } from 'shared/store/uiStore'

import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

type ReactLinkProps = {
  children: React.ReactNode
  route: string
}

const RouterLink: React.FC<ReactLinkProps> = observer(({ route, children }) => (
  <div
    className={styles.link}
    onClick={() =>
      uiStore.changeRoute({
        path: route,
        type: 'vue',
      })
    }
    aria-label={getAriaLabel('RouterLink')}
  >
    {children}
  </div>
))

export default RouterLink
