import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { getStartedModalStore } from 'widgets/getStartedModal'

import styles from './styles.module.scss'

export const GetStartedModalActions = observer(() => {
  const { loading, handleStart, handleCompliance } = getStartedModalStore
  return (
    <div className={styles.actionsWrap}>
      <Button
        fullWidth
        disabled={loading}
        loading={loading}
        typeBtn={'contained'}
        contained={'secondary'}
        text={'Complete compliance now'}
        onClick={handleCompliance}
      />
      <Button
        disabled={loading}
        loading={loading}
        fullWidth
        typeBtn={'contained'}
        text={'Try Salesmsg first'}
        onClick={handleStart}
      />
    </div>
  )
})
