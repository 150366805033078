import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import { ContainerHeader, variantActionsProps } from 'shared/ui'
import { adminOrganizationDetailStore } from 'pages/admin/pages/organizations/pages/OrganizationDetails/store/adminOrganizationDetailStore'
import { GeneralInformation } from 'pages/admin/pages/organizations/pages/OrganizationDetails/ui/GeneralInformation/GeneralInformation'
import { StripeInformation } from 'pages/admin/pages/organizations/pages/OrganizationDetails/ui/StripeInformation/StripeInformation'
import { getAdminOrganizationsActions } from 'pages/admin/pages/organizations/lib/getAdminOrganizationsActions'
import { TeamMembersTable } from 'pages/admin/pages/organizations/pages/OrganizationDetails/ui/TeamMembersTable/TeamMembersTable'
import { AdminDetailPage, AdminRoutesEnum } from 'pages/admin'
import { Compliance } from 'pages/admin/pages/organizations/pages/OrganizationDetails/ui/Compliance/Compliance'
import { CustomPayments } from './CustomPayments/CustomPayments'

export const OrganizationDetails = observer(() => {
  const { id } = useParams()
  const { organization, loading, setId } = adminOrganizationDetailStore
  useEffect(() => {
    setId(Number(id))
  }, [id])

  return (
    <AdminDetailPage
      loading={loading}
      isEmpty={!organization}
      customPath={`/admin/${AdminRoutesEnum.organizations}`}
      header={
        <ContainerHeader
          title={organization?.name || ''}
          titleIconProps={{
            icon: 'building',
            fontSize: 20,
            tertiary: true,
          }}
          isBorderBottom
          actionsProps={{
            ...variantActionsProps['headerMedium'],
            showItems: 3,
            actions: organization
              ? getAdminOrganizationsActions({
                  organizationById: organization,
                  fromOrgDetailsPage: true,
                })
              : [],
          }}
        />
      }
      info={
        <>
          <GeneralInformation />
          <StripeInformation />
          <CustomPayments />
        </>
      }
      content={
        <>
          <Compliance />
          <TeamMembersTable />
        </>
      }
    ></AdminDetailPage>
  )
})
