import { observer } from 'mobx-react-lite'
import { makeTextFieldIcon } from 'shared/ui/TextField'
import {
  Alert,
  Button,
  ITabItem,
  Tabs,
  IIconsVector,
  IDropdownItem,
  InputRadio,
  Scrollbar,
  EmptyState,
  SpinnerLoader,
} from 'shared/ui'
import { Select } from 'shared/ui/Select/Select'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { getAriaLabel } from 'shared/lib'
import { IResponseBillingAreaCode, IResponseBillingState } from 'entities/Billing/api/types'
import { getNumberModalStore, ICountriesEnum, ITabsEnum } from 'widgets/GetNumberModal'

import styles from './styles.module.scss'

export const GetNumberModalContent = observer(() => {
  const {
    noResults,
    numbersList,
    selectedNumber,
    activeTab,
    country,
    countries,
    countryData,
    state,
    states,
    areaCode,
    areaCodes,
    canSearch,
    statePlaceholder,
    loading,
    alertDescription,
    refresh,
    setNumber,
    setActiveTab,
    setCountry,
    setState,
    setAreaCode,
  } = getNumberModalStore

  const tabs: ITabItem[] = [
    {
      name: 'Local number',
      key: ITabsEnum.LOCAL,
    },
    {
      name: 'Toll-Free number',
      key: ITabsEnum.TOLL_FREE,
    },
  ]

  const handleActiveTab = (item: ITabItem) => {
    if (loading) return
    const tabKey = item.key as ITabsEnum
    setActiveTab(tabKey)
  }

  const handleCountry = (item: ICountriesEnum) => {
    if (loading) return
    setCountry(item)
  }

  const handleState = (item: IDropdownItem) => {
    if (loading) return
    setState(item.customFields as IResponseBillingState)
  }

  const handleAreaCode = (item: IDropdownItem) => {
    if (loading) return
    setAreaCode(item.customFields as IResponseBillingAreaCode)
  }

  return (
    <div aria-label={getAriaLabel('GetNumberModalContent')}>
      <Alert
        item={{
          type: 'infoLight',
          title: 'Get your business number',
          desc: alertDescription,
        }}
        rightAction={
          <Button
            tag='a'
            text={'Learn more'}
            type={'button'}
            typeBtn={'action'}
            size={'small'}
            contained={'primary'}
            textClassname={styles.alertActionText}
            className={styles.alertAction}
            target='_blank'
            rel='noreferrer'
            href={
              'https://help.salesmessage.com/en/articles/6210336-which-number-is-right-for-my-needs'
            }
          />
        }
      />
      <div
        className={styles.contentWrap}
        aria-label={getAriaLabel('GetNumberModalContent', 'contentWrap')}
      >
        <Tabs tabs={tabs} activeTabKey={activeTab} handleSelectTab={handleActiveTab} />
        <SelectField
          labelText='Country'
          value={country}
          items={countries}
          setValue={handleCountry}
          selectProps={{
            dropdownProps: {
              margin: -36,
              withSearch: false,
              withTextInput: false,
              ariaLabel: 'Country',
            },
          }}
          btnProps={{
            text: countryData ? countryData.name : 'Select',
            textIcon: countryData ? (countryData.flag as IIconsVector) : undefined,
          }}
        />
        {activeTab === ITabsEnum.LOCAL && (
          <>
            <Select
              fullWidth
              btnProps={{
                fullWidth: true,
                typeBtn: 'stroke',
                justifyContent: 'space-between',
                fontWeight: !state ? 400 : undefined,
                style: {
                  color: !state ? 'var(--content-neutral-primary)' : undefined,
                },
                text: state ? `${state.state_code} - ${state.state_name}` : statePlaceholder,
                textIcon: 'makerPin',
              }}
              dropdownProps={{
                margin: -36,
                textFieldProps: {
                  leftActions: [
                    makeTextFieldIcon({ icon: 'makerPin', className: styles.textFieldIcon }),
                  ],
                  InputProps: {
                    placeholder: 'Search',
                  },
                },
                ariaLabel: 'state',
              }}
              text={''}
              value={state?.state_code}
              items={states}
              onSelect={handleState}
            />
            <Select
              fullWidth
              btnProps={{
                fullWidth: true,
                typeBtn: 'stroke',
                justifyContent: 'space-between',
                fontWeight: !areaCode ? 400 : undefined,
                style: {
                  color: !areaCode ? 'var(--content-neutral-primary)' : undefined,
                },
                text: areaCode
                  ? `${areaCode.state_name} - ${areaCode.area_code}`
                  : 'Enter an area code (e.g. 561)',
                textIcon: 'makerPin',
              }}
              dropdownProps={{
                margin: -36,
                textFieldProps: {
                  leftActions: [
                    makeTextFieldIcon({ icon: 'makerPin', className: styles.textFieldIcon }),
                  ],
                  InputProps: {
                    placeholder: 'Search',
                  },
                },
                ariaLabel: 'area',
              }}
              text={''}
              value={areaCode?.area_code}
              items={areaCodes}
              onSelect={handleAreaCode}
            />
          </>
        )}
        <div>
          {(!noResults || loading) && (
            <Button
              fullWidth={false}
              icon={'refresh'}
              text={'Refresh'}
              size={'small'}
              contained={'secondary'}
              onClick={refresh}
              disabled={loading || !canSearch}
            />
          )}
          {(canSearch || loading) && (
            <div
              className={styles.numbersWrap}
              aria-label={getAriaLabel('GetNumberModalContent', 'numbersWrap')}
            >
              {loading ? (
                <div
                  className={styles.loader}
                  aria-label={getAriaLabel('GetNumberModalContent', 'loader')}
                >
                  <SpinnerLoader />
                </div>
              ) : (
                <>
                  {noResults ? (
                    <div
                      className={styles.noResults}
                      aria-label={getAriaLabel('GetNumberModalContent', 'noResults')}
                    >
                      <EmptyState
                        noIcon
                        typographyProps={{ variant: 'body-sm-regular', ariaLabel: 'EmptyState' }}
                      />
                    </div>
                  ) : (
                    <Scrollbar autoHeightMax={200} autoHeightMin={200} autoHeight={true}>
                      {numbersList.map((number) => (
                        <InputRadio
                          checked={selectedNumber?.number === number.number}
                          onChecked={() => setNumber(number)}
                          key={number.number}
                          label={number.formatted}
                          rightText={state ? `${state.state_name}, ${state.state_code}` : undefined}
                        />
                      ))}
                    </Scrollbar>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
