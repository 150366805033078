import classNames from 'classnames'
import { useMemo, useState } from 'react'
import {
  Button,
  DropdownPlaceholder,
  IDropdownItem,
  IIconsVector,
  Icon,
  InputCheckbox,
} from 'shared/ui'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import { CustomFieldType, ICustomFieldType, INewCustomField } from 'entities/CustomField/api'

import styles from './styles.module.scss'

export interface ICreateCustomFieldModalProps {
  onCreate: (field: INewCustomField) => Promise<void>
  onCancel: () => void
}

const FieldTypeDropdownItems = {
  [CustomFieldType.Number]: { label: 'Number', iconL: 'number' },
  [CustomFieldType.Text]: { label: 'Text', iconL: 'textInput2' },
  [CustomFieldType.Url]: { label: 'URL', iconL: 'link' },
  [CustomFieldType.Date]: { label: 'Date', iconL: 'calendar' },
} as {
  [Key in ICustomFieldType]: { label: string; iconL: IIconsVector }
}

export const CreateCustomFieldModal = ({ onCancel, onCreate }: ICreateCustomFieldModalProps) => {
  const [fieldName, setFieldName] = useState('')
  const [fieldType, setFieldType] = useState<ICustomFieldType | null>(null)
  const [inConversation, setInConversation] = useState(false)

  const [fieldCreating, setFieldCreating] = useState(false)
  const [fieldError, setFieldError] = useState<string | null>(null)

  const canCreateField = fieldName?.length && !!fieldType

  const dropdownItems: IDropdownItem[] = useMemo(
    () =>
      [...Object.entries(FieldTypeDropdownItems)].map(([type, data]) => ({
        ...data,
        id: type,
        iconR: type === fieldType ? 'check1' : undefined,
        customFields: {
          rightIconColor: 'var(--light-blue-60)',
        },
      })),
    [fieldType]
  )

  const handleNameChange = (name: string) => (setFieldError(null), setFieldName(name))
  const handleTypeChange = (type: ICustomFieldType) => setFieldType(type)
  const handleInConversationChange = () => setInConversation(!inConversation)

  const handleChancel = () => onCancel()
  const handleCreate = () => {
    if (!canCreateField) return

    setFieldCreating(true)
    onCreate({
      name: fieldName,
      type: fieldType,
      visible: inConversation,
    })
      .catch((error) => {
        const errorMessage =
          typeof error === 'string' ? error : 'Something went wrong, please try again'

        setFieldError(errorMessage)
      })
      .finally(() => setFieldCreating(false))
  }

  return (
    <div className={styles.container}>
      <div className={styles.fieldsBlock}>
        <div className={styles.field}>
          <div className={styles.label}>Name</div>
          <TextField
            size='small'
            variant='stroke'
            InputProps={{
              placeholder: 'Enter name',
            }}
            className={classNames({
              [styles.fieldError]: !!fieldError,
            })}
            value={fieldName}
            onChange={handleNameChange}
            rightActions={[makeTextFieldClear()]}
          />
          {!!fieldError && (
            <div className={styles.errorContainer}>
              <Icon icon='alertTriangleSmall' />
              <div className={styles.errorText}>{fieldError}</div>
            </div>
          )}
        </div>
        <div className={styles.field}>
          <div className={styles.label}>Type</div>
          <DropdownPlaceholder
            onChange={(item) => handleTypeChange(item.id as ICustomFieldType)}
            items={dropdownItems}
            widthDropdown={210}
            placeholder={
              <span
                className={classNames(styles.labelDropdown, {
                  [styles.labelDropdownWithValue]: !!fieldType,
                })}
              >
                {fieldType ? FieldTypeDropdownItems[fieldType].label : 'Select'}
              </span>
            }
            textPlaceholderProps={{
              variant: 'stroke',
              size: 'small',
              rightActions: [makeTextFieldIcon({ icon: 'chevronDown' })],
              leftActions: [
                makeTextFieldIcon({
                  icon: fieldType ? FieldTypeDropdownItems[fieldType].iconL : undefined,
                }),
              ],
            }}
          />
        </div>
      </div>
      <InputCheckbox
        checked={inConversation}
        onChecked={handleInConversationChange}
        customLabel={<div className={styles.inputCheckboxLabel}>Show in conversations</div>}
      />
      <footer className={styles.containerFooter}>
        <Button text='Cancel' contained='tertiary' onClick={handleChancel} />
        <Button
          text='Create'
          disabled={!canCreateField || fieldCreating}
          loading={fieldCreating}
          onClick={handleCreate}
        />
      </footer>
    </div>
  )
}
