import { ReactNode } from 'react'
import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { debounce } from 'lodash'
import { dropDownStore, type IDropdownItem } from 'shared/ui'
import { logger } from 'shared/lib'
import { type IHubspotPropertiesObjectType, IntegrationsApi } from 'entities/Integrations'
import { IIntegrationsProperty } from 'entities/Integrations/model/types'
import { contactsStore } from 'entities/Contacts'
import { MergeField } from 'widgets/MergeField/types/MergeField'

type INewPropertiesDropdownStoreConfig = {
  contactId: number
  addItem: (item: IIntegrationsProperty) => void
  items: string[]
}

export class NewPropertiesDropdownStore {
  private _search = ''
  private _error: ReactNode = null
  private _dropdownId: string | null = null
  private _itemsMap: Map<string, IDropdownItem> = new Map()
  private _cancelTokenSource: CancelTokenSource | null = null
  private _loading = true
  private _config: INewPropertiesDropdownStoreConfig | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get contact() {
    const contactId = this._config?.contactId

    if (!contactId) return

    return contactsStore.getItem(contactId)
  }

  setConfig = (config: INewPropertiesDropdownStoreConfig) => {
    this._config = config
  }

  searchFields = (
    term: string,
    immediate = false,
    objectType?: IHubspotPropertiesObjectType,
    objectId?: number
  ) => {
    this._search = term
    this._loading = true

    const makeRequest = async () => {
      if (!this.contact) return

      runInAction(() => this._itemsMap.clear())

      const { data: fields } =
        objectType && objectId
          ? await IntegrationsApi.getHubspotFieldsByType({
              objectType,
              objectId,
              search: this._search,
            })
          : await IntegrationsApi.getHubspotFields(
              {
                contact_id: this.contact.integration_vendor_id,
                search: this._search,
              },
              {
                cancelToken: this._cancelTokenSource?.token,
              }
            )

      runInAction(() => {
        fields
          .map((field) => new MergeField(field))
          .forEach((mergeField) => {
            this._itemsMap.set(mergeField.id, {
              id: mergeField.id,
              label: mergeField.label,
              labelRight: mergeField.value,
              labelWidth: mergeField.value ? 140 : '100%',
              value: mergeField.name,
              iconL: 'hubspot',
              data: mergeField,
              tooltipLabelRightProps: {
                label: mergeField.value,
                placement: 'left',
              },
            } as IDropdownItem)
          })
      })
    }

    immediate ? this._tryMakeRequest(makeRequest) : this._tryMakeRequestDelay(makeRequest)
  }

  private _initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _tryMakeRequest = async (action: () => Promise<void>) => {
    runInAction(() => {
      this._loading = true
      this._initCancelTokenSource()
    })

    try {
      await action()
      runInAction(() => {
        this._loading = false
      })
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
      })

      logger.error(error)
    }
  }

  private _tryMakeRequestDelay = debounce(this._tryMakeRequest, 500)

  setDropdownId = (id: string) => {
    this._dropdownId = id
  }

  hideDropDown = () => {
    if (this._dropdownId) {
      dropDownStore.hide(this._dropdownId)
    }
  }

  addToSelectedFields = async (key: string | number) => {
    const field = this._itemsMap.get(key.toString())
    const { name, label, value } = field?.data as MergeField

    const property: IIntegrationsProperty = {
      key: name,
      label: label,
      value: value,
    }

    this._config?.addItem(property)

    this.hideDropDown()
  }

  reset = () => {
    this._cancelTokenSource?.cancel()
    this._itemsMap.clear()
    this._loading = true
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get properties() {
    return this.items.filter((item) => {
      const { name } = item?.data as MergeField

      return !this._config?.items.includes(name)
    })
  }

  get loading() {
    return this._loading
  }

  get disabledAddButton() {
    return this._loading
  }

  get search() {
    return this._search
  }

  get error() {
    return {
      message: this._error,
    }
  }
}
