// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bZNTK4eYlx1cpk4sux72{display:flex;align-items:center;justify-content:space-between}.xqxcknp2Y42uqTvp5kk1{min-height:32px;flex:0 0 125px;color:var(--content-primary-tertiary)}._okcmpn0gbgAV0GySxup,.jX4L33P68U56p8Ud75vR{width:100%}.tD1o8iOjaMShtziXkV0Z{color:var(--content-primary-primary)}.tD1o8iOjaMShtziXkV0Z span{color:var(--content-primary-tertiary)}.UyjVTmzP_wdb65GMe7oi{display:flex;gap:8px;align-items:center}.RiVgyCxzxyl_Ao9JSE4e{padding:0 4px}.YNxQHmejZZN4RL_eFHVJ{width:100%}.YNxQHmejZZN4RL_eFHVJ>div{width:100%}.DTPx0nrzaBbxTZj9ijvs .MuiTooltip-arrow{transform:none !important;left:10px !important}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/ContactsContent/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,qCAAA,CAGF,4CACE,UAAA,CAGF,sBACE,oCAAA,CAEA,2BACE,qCAAA,CAIJ,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CAGF,sBACE,aAAA,CAGF,sBACE,UAAA,CAEA,0BACE,UAAA,CAKF,wCACE,yBAAA,CACA,oBAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.label {\n  min-height: 32px;\n  flex: 0 0 125px;\n  color: var(--content-primary-tertiary);\n}\n\n.trigger, .input{\n  width: 100%;\n}\n\n.text {\n  color: var(--content-primary-primary);\n\n  span {\n    color: var(--content-primary-tertiary);\n  }\n}\n\n.wrapPlaceholder{\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n\n.loaderTrigger {\n  padding: 0 4px;\n}\n\n.tooltip {\n  width: 100%;\n\n  & > div {\n    width: 100%;\n  }\n}\n\n.tooltip-content {\n  :global(.MuiTooltip-arrow) {\n    transform: none!important;\n    left: 10px!important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "bZNTK4eYlx1cpk4sux72",
	"label": "xqxcknp2Y42uqTvp5kk1",
	"trigger": "_okcmpn0gbgAV0GySxup",
	"input": "jX4L33P68U56p8Ud75vR",
	"text": "tD1o8iOjaMShtziXkV0Z",
	"wrapPlaceholder": "UyjVTmzP_wdb65GMe7oi",
	"loaderTrigger": "RiVgyCxzxyl_Ao9JSE4e",
	"tooltip": "YNxQHmejZZN4RL_eFHVJ",
	"tooltip-content": "DTPx0nrzaBbxTZj9ijvs"
};
export default ___CSS_LOADER_EXPORT___;
