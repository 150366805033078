import { makeAutoObservable } from 'mobx'
import {
  IResponseSalesforceProperty,
  IResponseIntegrationsContactsSalesforceInfo,
} from 'entities/Integrations'
import type { IIntegrationsProperty } from 'entities/Integrations/model/types'
import { IntegrationContactProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationContactProperty'
import { IntegrationOpportunityProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationOpportunityProperty'

export class ContactIntegrationSalesforceInfo {
  private _properties = new IntegrationContactProperty([])
  private _opportunitiesMap: Map<number, IntegrationOpportunityProperty> = new Map()
  private _objectType: IResponseSalesforceProperty['object_type'] | null = null

  constructor(item: IResponseIntegrationsContactsSalesforceInfo) {
    this._objectType = item.properties?.object_type || null
    this.addOpportunities(item.opportunities)
    this._properties = new IntegrationContactProperty(this.addProperties(item.properties))

    makeAutoObservable(this)
  }

  get isLead() {
    return this._objectType === 'lead'
  }

  get opportunities() {
    return Array.from(this._opportunitiesMap.values())
  }

  get properties() {
    return this._properties
  }

  addProperties = (properties: IResponseSalesforceProperty | null) => {
    return [
      {
        key: 'first_name',
        label: 'First Name',
        value: properties?.first_name || '',
      },
      {
        key: 'last_name',
        label: 'Last Name',
        value: properties?.last_name || '',
      },
      {
        key: 'email',
        label: 'Email',
        value: properties?.email || '',
      },
    ]
  }

  addProperty = (item: IIntegrationsProperty) => {
    this._properties.addProperty(item)
  }

  addOpportunities = (
    opportunities: IResponseIntegrationsContactsSalesforceInfo['opportunities']
  ) => {
    const data = opportunities.map((item) => {
      return {
        id: item.id,
        title: item.name,
        stage: item.stage,
        amount: item.value,
        owner: null,
      }
    })

    data.forEach((item, index) => {
      const ID = Number(item.id)
      const id = !isNaN(ID) ? ID : index

      this._opportunitiesMap.set(id, new IntegrationOpportunityProperty(item))
    })
  }
}
