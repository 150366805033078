import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'
import { AdminEditAgencyFranchiseContractContent } from 'pages/admin/pages/organizations/modals/AdminEditAgencyFranciseContract/AdminEditAgencyFranchiseContractContent'
import { AdminEditAgencyFranchiseContractContentAction } from 'pages/admin/pages/organizations/modals/AdminEditAgencyFranciseContract/AdminEditAgencyFranchiseContractContentAction'
import { adminOrganizationPlanStore } from 'pages/admin/pages/organizations/store/adminOrganizationPlanStore'

class AdminEditAgencyFranchiseContractContentStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''

  organization: IResponseAdminOrganizationById | null = null
  is_agency = false
  is_franchise = false
  agency_plan: number | null = null
  master_plan: number | null = null
  clients_plans_custom: number[] = []
  clients_plans_pro: string[] = []

  onSuccess: ((organization: IResponseAdminOrganizationById) => void) | null = null

  loading = false

  get disabled() {
    if (this.is_franchise) {
      return !this.agency_plan || !this.master_plan
    }
    return false
  }

  reset = () => {
    this.modalId = ''
    this.organization = null
    this.is_agency = false
    this.is_franchise = false
    this.agency_plan = null
    this.master_plan = null
  }

  setAgency = (value: boolean) => {
    if (!value && this.is_franchise) {
      this.is_franchise = false
    }
    this.is_agency = value
  }

  setFranchise = (value: boolean) => {
    if (value && !this.is_agency) {
      this.is_agency = true
    }
    this.is_franchise = value
  }

  set_agency_plan = (value: typeof this.agency_plan) => {
    this.agency_plan = value
  }
  set_master_plan = (value: typeof this.master_plan) => {
    this.master_plan = value
  }
  set_clients_plans = (value: string[]) => {
    const clientsPlansPro: string[] = []
    const clientsPlansCustom: number[] = []

    value.forEach((plan) => {
      const planData = adminOrganizationPlanStore.selectedPlanData(plan)

      if (!planData) {
        return
      }

      if (planData.planType === 'custom') {
        clientsPlansCustom.push(Number(plan))
      } else {
        clientsPlansPro.push(plan)
      }
    })

    runInAction(() => {
      this.clients_plans_pro = clientsPlansPro
      this.clients_plans_custom = clientsPlansCustom
    })
  }
  get selected_plans() {
    return [...this.clients_plans_pro, ...this.clients_plans_custom.map((plan) => String(plan))]
  }
  removePlan = (id: string) => {
    this.set_clients_plans(this.selected_plans.filter((planId) => planId !== id))
  }

  onSave = async () => {
    try {
      if (!this.organization?.id) {
        return
      }
      runInAction(() => {
        this.loading = true
      })
      const { data } = await AdminOrganizationsApi.updateOrganization({
        id: this.organization?.id,
        data: {
          is_agency: this.is_agency,
          is_franchise: this.is_franchise,
          master_plan: this.master_plan || undefined,
          agency_plan: this.agency_plan || undefined,
          clients_plans: {
            pro: this.clients_plans_pro,
            custom: this.clients_plans_custom,
          },
        },
      })
      this.onSuccess && this.onSuccess(data)
      modalStore.closeModal(this.modalId)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  onOpenModal = (
    organization: IResponseAdminOrganizationById,
    onSuccess: typeof this.onSuccess
  ) => {
    this.reset()
    this.onSuccess = onSuccess
    this.organization = organization
    this.is_agency = organization.is_agency
    this.is_franchise = organization.is_franchise
    this.master_plan = organization.master_plan
    this.agency_plan = organization.agency_plan
    this.clients_plans_pro = organization.clients_plans.pro
    this.clients_plans_custom = organization.clients_plans.custom

    this.modalId = nanoid()
    modalStore.addModal({
      id: this.modalId,
      title: 'Edit agency / Franchise',
      ModalContent: AdminEditAgencyFranchiseContractContent,
      ModalActions: AdminEditAgencyFranchiseContractContentAction,
      width: 480,
    })
  }
}

export const adminEditAgencyFranchiseContractContentStore =
  new AdminEditAgencyFranchiseContractContentStore()
