import { TableRow as MuiTableRow } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { TableCell } from 'shared/ui/Table'
import { Button, IRow } from 'shared/ui'

type ILoadMoreExpandedRowProps<TRow> = {
  row: IRow<TRow>
}

export const LoadMoreExpandedRow = observer(<TRow,>({ row }: ILoadMoreExpandedRowProps<TRow>) => {
  return (
    <MuiTableRow>
      <TableCell
        sx={{
          paddingLeft: '72px',
        }}
      >
        <Button
          onClick={row.onLoadMore}
          contained={'secondary'}
          size={'small'}
          text={'Load more'}
          loading={row.loadingMore}
        />
      </TableCell>
    </MuiTableRow>
  )
})
