import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import graphicBg from 'shared/assets/images/auth/graphicBg.png'
import { getCDNAssetURL } from 'shared/lib'
import { StepFormLayout } from './StepFormLayout'

import styles from './styles.module.scss'

type IAuthLayoutProps = {
  form: ReactNode
  formClassnames?: string
  formBottom?: ReactNode
  rightContent?: ReactNode
  title?: string
  subtitle?: ReactNode | string
  onBack?: () => void
  coupon?: string
}

export const AuthLayout: React.FC<IAuthLayoutProps> = observer(
  ({ form, rightContent, title, subtitle, formBottom, onBack, coupon, formClassnames }) => {
    return (
      <div className={styles.wrap}>
        <div
          className={classNames(styles.formContent, {
            [styles.withRightContent]: rightContent,
          })}
        >
          <StepFormLayout
            form={form}
            formClassnames={formClassnames}
            title={title}
            subtitle={subtitle}
            onBack={onBack}
            centered={!rightContent}
            coupon={coupon}
          />
          {formBottom && formBottom}
        </div>

        {rightContent && (
          <div className={styles.rightContent}>
            <div className={styles.content}>{rightContent}</div>
            <img className={styles.graphicBg} alt='graphicBg' src={getCDNAssetURL(graphicBg)} />
          </div>
        )}
      </div>
    )
  }
)

export default AuthLayout
