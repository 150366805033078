import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { IDropdownItem } from 'shared/ui'
import { apiOptionToDropdownItem } from 'shared/lib'
import {
  AdminComplianceApi,
  IParamsUpdateTollFreeBusinessContact,
  IResponseAdminTollFreeByID,
} from 'entities/Admin/compliance'

class AdminTollFreeBusinessContactStore {
  constructor() {
    makeAutoObservable(this)
  }

  number_id: number | null = null

  isEdit = false

  isSaveLoading = false

  errorsObj: { [key: string]: string[] } = {}

  get paramsUpdateTollFreeBusinessContact(): IParamsUpdateTollFreeBusinessContact {
    return {
      number_id: this.number_id,
      contact_person: {
        first_name: this.edit_first_name,
        last_name: this.edit_last_name,
        email: this.edit_email,
        business_title: this.edit_business_title,
        phone_number: this.edit_phone_number,
        job_position_id: this.edit_job_position?.id as number,
      },
    }
  }

  get isForEdit() {
    return true
  }

  first_name: string | null = null
  edit_first_name: typeof this.first_name = null
  get is_edit_first_name() {
    return this.first_name !== this.edit_first_name
  }
  set_edit_first_name = (value: typeof this.edit_first_name) => {
    this.edit_first_name = value
  }

  last_name: string | null = null
  edit_last_name: typeof this.last_name = null
  get is_edit_last_name() {
    return this.last_name !== this.edit_last_name
  }
  set_edit_last_name = (value: typeof this.edit_last_name) => {
    this.edit_last_name = value
  }

  email: string | null = null
  edit_email: typeof this.email = null
  get is_edit_email() {
    return this.email !== this.edit_email
  }
  set_edit_email = (value: typeof this.edit_email) => {
    this.edit_email = value
  }

  business_title: string | null = null
  edit_business_title: typeof this.business_title = null
  get is_edit_business_title() {
    return this.business_title !== this.edit_business_title
  }
  set_edit_business_title = (value: typeof this.edit_business_title) => {
    this.edit_business_title = value
  }

  phone_number: string | null = null
  edit_phone_number: typeof this.phone_number = null
  get is_edit_phone_number() {
    return this.phone_number !== this.edit_phone_number
  }
  set_edit_phone_number = (value: typeof this.edit_phone_number) => {
    this.edit_phone_number = value
  }

  job_position: IDropdownItem | null = null
  edit_job_position: typeof this.job_position = null
  get is_edit_job_position() {
    return this.job_position?.id !== this.job_position?.id
  }
  set_edit_job_position = (value: typeof this.edit_job_position) => {
    this.edit_job_position = value
  }

  get hasChanges() {
    if (!this.isEdit) {
      return false
    }
    const changes = [
      this.is_edit_first_name,
      this.is_edit_last_name,
      this.is_edit_email,
      this.is_edit_business_title,
      this.is_edit_phone_number,
      this.is_edit_job_position,
    ]
    return changes.some((item) => item)
  }

  reset = () => {
    this.errorsObj = {}
    this.number_id = null
    this.phone_number = null
    this.email = null
    this.isEdit = false
  }

  setBrand = (number_id: number, data?: IResponseAdminTollFreeByID['contact_person_data']) => {
    this.number_id = number_id || null
    if (data) {
      this.first_name = data?.first_name
      this.last_name = data?.last_name
      this.email = data?.email
      this.business_title = data?.business_title
      this.phone_number = data?.phone_number
      this.job_position = apiOptionToDropdownItem(data?.job_position)
    }
  }

  onEdit = () => {
    this.edit_first_name = this.first_name
    this.edit_last_name = this.last_name
    this.edit_email = this.email
    this.edit_business_title = this.business_title
    this.edit_phone_number = this.phone_number
    this.edit_job_position = this.job_position
    this.isEdit = true
  }

  onClose = () => {
    this.isEdit = false
  }

  onSave = async () => {
    try {
      runInAction(() => {
        this.isSaveLoading = true
      })
      return await AdminComplianceApi.updateTollFreeBusinessInfo(
        this.paramsUpdateTollFreeBusinessContact
      )
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data) {
            this.errorsObj = e.response.data
          }
        }
      })
      throw e
    } finally {
      runInAction(() => {
        this.isSaveLoading = false
      })
    }
  }

  getError = (key: string) => {
    return this.errorsObj && this.errorsObj[key] && this.errorsObj[key][0]
  }
}

export const adminTollFreeBusinessContactStore = new AdminTollFreeBusinessContactStore()
