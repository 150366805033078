// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t_BSDxOVtquHOi1mFnrp{border-radius:6px;background:var(--gray-20);height:6px;cursor:pointer}.TyCE8Bnn2S0mYbF_y6ru{border-radius:6px;background:var(--background-brand-default);height:6px;transition:width 100ms}", "",{"version":3,"sources":["webpack://./src/shared/ui/RangeBar/styels.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,yBAAA,CACA,UAAA,CACA,cAAA,CAIF,sBACE,iBAAA,CACA,0CAAA,CACA,UAAA,CACA,sBAAA","sourcesContent":[".range{\n  border-radius: 6px;\n  background: var(--gray-20);\n  height: 6px;\n  cursor: pointer;\n\n\n}\n.bar{\n  border-radius: 6px;\n  background: var(--background-brand-default);\n  height: 6px;\n  transition: width 100ms;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"range": "t_BSDxOVtquHOi1mFnrp",
	"bar": "TyCE8Bnn2S0mYbF_y6ru"
};
export default ___CSS_LOADER_EXPORT___;
