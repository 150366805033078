export const isMediaUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url)

    const path = parsedUrl.pathname

    const validExtensions = ['png', 'jpg', 'jpeg', 'gif', 'vcf']

    return validExtensions.some((ext) => path.toLowerCase().endsWith(`.${ext}`))
  } catch {
    return false
  }
}
