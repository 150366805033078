import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import styles from 'widgets/RecordVideo/ui/styles.module.scss'
import recordVideoStore from 'widgets/RecordVideo/store/recordVideoStore'

export const StreamRecord = observer(() => {
  const { recordStore } = recordVideoStore
  const { stream, startLoading, delayRecord } = recordStore
  const ref = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    if (ref.current && stream) {
      ref.current.srcObject = stream
    }
  }, [stream])

  return (
    <div className={styles.wrapVideo}>
      {startLoading && delayRecord > 0 && <div className={styles.delay}>{delayRecord}</div>}
      <video ref={ref} autoPlay muted className={styles.record} />
    </div>
  )
})
