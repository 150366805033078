import { Icon, Typography, TypingIndicator } from 'shared/ui'
import styles from './styles.module.scss'

export const LoadingGeneration = () => {
  return (
    <div className={styles.wrap}>
      <Icon icon={'stars'} fontSize={16} color={'var(--content-primary-tertiary)'} />
      <Typography variant={'body-sm-medium'} color={'tertiary'} ariaLabel={'GeneratingText'}>
        Generating text
      </Typography>
      <TypingIndicator marginLeft={4} />
    </div>
  )
}
