import { observer } from 'mobx-react-lite'
import { Button, Dropdown } from 'shared/ui'
import { IParamsContactsOptOut } from 'entities/Contacts/api/types'
import { successOpIinHandler } from 'entities/Contacts/lib/successOpIinHandler'
import { selectedModalStore } from 'widgets/SelectContactsModal/store/SelectedModalStore'
import { contactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'
import { PowerDialerActionButton } from 'widgets/PowerDialer'
import { useContactsTableContext } from 'widgets/ContactsTable'
import { ChatbotEnrollButton } from 'widgets/ChatbotEnroll'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'

export const LeftContent = observer(() => {
  const contactsTableStore = useContactsTableContext()
  const {
    selectedContacts,
    search,
    total,
    expandedParams,
    contacts,
    activeContact,
    setInActionId,
    loadContacts,
    checkClosePanelAfterDelete,
    tableStore,
  } = contactsTableStore
  const { bulkAllMode, selectedIds, resetSelected } = tableStore
  const { openValidateModal, openDeleteModal, deleteContacts } = selectedModalStore
  const { segment } = contactsFilterStore

  const deleteCallback = () => {
    setInActionId(null)
  }

  const successDeleteCallback = () => {
    checkClosePanelAfterDelete()
    resetSelected()
    loadContacts()
  }

  const resetInActionId = () => {
    setInActionId(null)
  }

  const opIinHandler = (opt_outs: IParamsContactsOptOut['opt_outs']) => {
    activeContact && successOpIinHandler(activeContact, opt_outs)

    contacts.forEach((contact) => {
      if (bulkAllMode || selectedIds.includes(contact.id)) {
        successOpIinHandler(contact, opt_outs)
      }
    })
  }
  return (
    <>
      <Button
        text='Validate'
        icon='check1'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={() => {
          openValidateModal({
            bulkAllMode,
            selectedIds: selectedIds as number[],
            search,
            filtersList: segment?.filtersList,
          })
        }}
      />
      <Button
        text='Manage tags'
        icon='tag'
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={() => {
          contactsTagsModalStore.onOpen({
            ids: selectedIds as number[],
            isBulkAll: bulkAllMode,
            callback: loadContacts,
            searchParams: expandedParams.search,
            filtersList: expandedParams.filtersList,
          })
        }}
      />
      <ChatbotEnrollButton contacts={selectedContacts} />
      <PowerDialerActionButton
        total={contactsTableStore.total}
        contactsParams={contactsTableStore.expandedParams}
        bulkAll={bulkAllMode}
        selectedIds={selectedIds as number[]}
      />
      <Dropdown
        ariaLabel={'LeftActions'}
        items={[
          {
            id: 0,
            label: 'Opt-in status',
            iconL: 'toggle',
            onClick: () => {
              contactsOptInModalStore.handleOpen(selectedContacts, {
                reset: resetInActionId,
                successHandler: opIinHandler,
                isBulkAll: bulkAllMode,
                filtersList: expandedParams.filtersList,
                search,
              })
            },
          },
          {
            id: 1,
            label: 'Delete',
            iconL: 'delete',
            onClick: () => {
              const selectedIdsLength = bulkAllMode ? total : selectedIds.length

              openDeleteModal({
                selectedIdsLength,
                primaryCallback: () => {
                  deleteContacts({
                    bulkAllMode,
                    ids: selectedIds as number[],
                    search,
                    filtersList: expandedParams?.filtersList,
                    successCallback: successDeleteCallback,
                    finallyCallback: deleteCallback,
                  })
                },
                onCloseCallback: resetInActionId,
              })
            },
          },
        ]}
        iconButtonProps={{ icon: 'moreHorizontal', color: 'tertiary', ariaLabel: 'LeftActions' }}
        placement='bottom-start'
      />
    </>
  )
})
