import { observer } from 'mobx-react-lite'
import { Switch, Typography } from 'shared/ui'
import { adminUpdateTaxFreeModal } from '../store/AdminUpdateTaxFreeStore'

import styles from './styles.module.scss'

export const AdminUpdateTaxFreeContent = observer(() => {
  const { isTaxFree, toggleTaxFree } = adminUpdateTaxFreeModal

  return (
    <div className={styles.wrap} onClick={toggleTaxFree}>
      <Typography ariaLabel={'name'} variant={'text-input-field'}>
        Tax Free
      </Typography>
      <Switch value={isTaxFree} />
    </div>
  )
})
