import { useEffect } from 'react'

import { ComplianceSettingsProvider } from './context/complianceSettingsContext'
import { ComplianceSettingContainer } from './ui/ComplianceSettingContainer'

interface IComplianceProps {
  settingsStep: number
  settingsTotal: number
  setTotal: (total: number) => void
  increaseStep: () => void
}

export const Compliance = ({
  settingsStep,
  settingsTotal,
  setTotal,
  increaseStep,
}: IComplianceProps) => {
  useEffect(() => {
    setTotal(5)
  }, [])

  return (
    <ComplianceSettingsProvider>
      <ComplianceSettingContainer
        settingsStep={settingsStep}
        settingsTotal={settingsTotal}
        increaseStep={increaseStep}
      />
    </ComplianceSettingsProvider>
  )
}
