import { makeAutoObservable } from 'mobx'
import { IntegrationKey } from 'entities/Integrations'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'

export class ShowMoreActionStore {
  private _show = true
  private _limit = 5
  private _itemLength = 0

  constructor(
    private _integrationKey: IntegrationKey,
    private _integrationTab: EnumIntegrationTabs
  ) {
    makeAutoObservable(this)

    this._show = Boolean(
      window.localStorage.getItem(`show_more_${this._integrationKey}_${this._integrationTab}`)
    )
  }

  toggleShow = () => {
    this._show = !this._show

    if (this._show) {
      window.localStorage.setItem(`show_more_${this._integrationKey}_${this._integrationTab}`, '1')
    } else {
      window.localStorage.removeItem(`show_more_${this._integrationKey}_${this._integrationTab}`)
    }
  }

  get show() {
    return this._show
  }

  get hideShowMore() {
    return this._itemLength <= this._limit
  }

  get text() {
    return this._show ? 'Hide properties' : 'See properties'
  }

  modifyData = <T>(items: T[]): T[] => {
    this._itemLength = items.length

    if (items.length <= this._limit) return items
    if (this._show) return items

    return items.slice(0, this._limit)
  }
}
