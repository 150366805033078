import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { FC } from 'react'
import { Button, Dropdown, Icon } from 'shared/ui'
import { ZIndex } from 'shared/constants/zIndex'
import { type IDeviceSettingsContentProps } from 'entities/DeviceSettings'
import styles from './styles.module.scss'

export const AudioOutputContent: FC<IDeviceSettingsContentProps> = observer(
  ({ deviceSettingsStore, parentsDropdownId, testOutputDevice = () => {}, dropdownProps = {} }) => {
    const { audioOutputsItems, device, setSelectedAudioOutput } = deviceSettingsStore

    return (
      <div className={classnames(styles.section, styles['section--listen'])}>
        <div className={styles.box}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Icon icon={'volumeMax'} fontSize={18} />
            </div>
            <div className={styles.label}>Speaker</div>
          </div>

          <div
            className={styles.value}
            onClick={() => {
              testOutputDevice()
            }}
          >
            <Button text={'Test sound'} typeBtn={'text'} size={'small'} />
          </div>
        </div>

        <div className={styles.dropdownWrap}>
          <div className={styles.dropdownBox}>
            <Dropdown
              zIndex={ZIndex.OVERLAY_TOP_1}
              placement={'bottom-start'}
              items={audioOutputsItems}
              triggerComponent={() => (
                <div className={styles.dropdownBtn}>{device.audioOutput?.label}</div>
              )}
              width={232}
              margin={-20}
              onChange={(item) => {
                setSelectedAudioOutput(String(item.id))
              }}
              parentsDropdownId={parentsDropdownId}
              {...dropdownProps}
              ariaLabel={'AudioOutputContent'}
            />
          </div>
        </div>
      </div>
    )
  }
)
