import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { Select } from 'shared/ui/Select/Select'
import { EnumDropdownItemVariant, Button, IDropdownItem } from 'shared/ui'
import type { IFilterValue } from 'entities/Filters/types'
import type { IResponseFilter } from 'entities/Contacts/api/filterTypes'
import { BroadcastStatus, type IBroadcastStatus } from 'entities/Broadcast'
import type { IStatusesKeys } from 'entities/Broadcast/ui/BroadcastStatus/config'
import type { IWidgetProps } from 'widgets/FilterEditor/types'
import { WidgetBroadcastStatusTrigger } from './WidgetBroadcastStatusTrigger'
import styles from './styles.module.scss'

export const WidgetBroadcastStatus = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [showValuesWarning, setShowValuesWarning] = useState(false)
    const filterConfig = filterItem.config as IResponseFilter<IFilterValue>
    const values = filterConfig.values ?? []
    const payloadValue = (filterItem.payload.value ?? []) as IStatusesKeys[]
    const [primaryKeys, setPrimaryKeys] = useState<IStatusesKeys[]>([])
    const [selectedKeys, setSelectedKeys] = useState<IStatusesKeys[]>(payloadValue)
    const dropdownItems: IDropdownItem[] = useMemo(
      () =>
        values
          .reduce<[IFilterValue[], IFilterValue[]]>(
            (values, value) => {
              const isSelected = primaryKeys.includes(value.key as IBroadcastStatus)

              if (isSelected) values[0].push(value)
              else values[1].push(value)

              return values
            },
            [[], []]
          )
          .flat()
          .map((item, index) => {
            const key = item.key as IBroadcastStatus

            return {
              id: item.key,
              label: item.label,
              labelContent: <BroadcastStatus status={key} />,
              variant: EnumDropdownItemVariant.Checkbox,
              activeValue: selectedKeys.includes(key),
              separator: index === primaryKeys.length - 1 && primaryKeys.length !== values.length,
            }
          }),
      [selectedKeys, primaryKeys]
    )

    useEffect(() => {
      setPrimaryKeys(payloadValue)
    }, [])

    useEffect(() => {
      setTimeout(() => setShowDropdown(expandByDefault), 100)
    }, [expandByDefault])

    return (
      <Select
        text={filterItem.operator?.key ?? ''}
        type='multi'
        items={dropdownItems}
        dropdownProps={{
          ariaLabel: 'Select',
          parentsDropdownId: parentsDropdownId,
          show: showDropdown,
          textFieldProps: {
            InputProps: { placeholder: `Search ${filterItem.config.name}` },
          },
          bottomActions: selectedKeys.length ? (
            <Button
              text='Clear'
              contained='secondary'
              typeBtn='text'
              onClick={() => {
                setPrimaryKeys([])
                setSelectedKeys([])
                filterItem.payload.updateHard({ value: [] })
              }}
            />
          ) : null,
          className: styles.operatorDropdown,
          withSearch: true,
          onClose: () => {
            setPrimaryKeys(selectedKeys)
            setShowValuesWarning(!selectedKeys.length)
          },
          triggerComponent: () => (
            <div
              className={classNames(styles.selectTriggerComponent, {
                [styles.warning]: showValuesWarning,
              })}
            >
              <WidgetBroadcastStatusTrigger items={selectedKeys} />
            </div>
          ),
          onChange: (item) => {
            const id = item.id as IStatusesKeys
            const keys = item.activeValue
              ? selectedKeys.filter((key) => key !== id)
              : [...selectedKeys, id]

            setShowValuesWarning(false)
            setSelectedKeys(keys)
            filterItem.payload.updateHard({ value: keys })
          },
        }}
      />
    )
  }
)
