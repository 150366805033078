import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { adminUpdateTaxFreeModal } from '../store/AdminUpdateTaxFreeStore'

export const AdminUpdateTaxFreeActions = observer(() => {
  const { onCloseModal, onSaveTaxFree, loading, disabled } = adminUpdateTaxFreeModal

  return (
    <>
      <Button
        typeBtn={'contained'}
        contained={'secondary'}
        text={'Cancel'}
        onClick={onCloseModal}
      />

      <Button
        typeBtn={'contained'}
        contained={'primary'}
        text={'Save'}
        onClick={onSaveTaxFree}
        disabled={disabled}
        loading={loading}
      />
    </>
  )
})
