import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { TextField, makeTextFieldAutoFocus, makeTextFieldHighlightLimit } from 'shared/ui/TextField'
import { Warning } from 'shared/ui/Warning'
import { Label } from 'shared/ui/Label'
import {
  PresetContainer,
  PresetError,
  PresetErrorContainer,
  PresetField,
  PresetFooter,
} from 'shared/Preset'
import { CHATBOT_NAME_LIMIT } from 'shared/constants/limits'

import { ICreateChatbotProps } from './types'

export const ChatbotNameModal = observer(
  ({ chatbot, onCancel, onNext, isBookAppointment }: ICreateChatbotProps) => {
    const [limitReached, setLimitReached] = useState(false)
    const [nameError, setNameError] = useState('')
    const [focused, setFocus] = useState(false)

    const handleFocus = () => {
      setFocus(true)
      setNameError('')
    }

    const handleBlur = () => {
      setFocus(false)
    }

    const validateName = () => {
      chatbot.setName(chatbot.name.trim())

      const isValid = !!chatbot.name.length

      setNameError(isValid ? '' : 'Textbot name is required')

      return isValid
    }

    const handleSave = () => {
      const isValid = validateName()

      if (isValid) onNext()
    }

    return (
      <PresetContainer>
        <PresetField>
          <Label
            label={{
              text: isBookAppointment ? 'Agent name' : 'Textbot name',
              rightText: focused ? `${chatbot.name.length} of ${CHATBOT_NAME_LIMIT}` : null,
            }}
          />
          <TextField
            InputProps={{
              placeholder: isBookAppointment ? 'e.g. Demo Booking Agent' : 'e.g. Marketing',
            }}
            variant='stroke'
            size='medium'
            value={chatbot.name}
            error={nameError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(name) => chatbot.setName(name)}
            mainActions={[
              makeTextFieldAutoFocus({ withFocus: true }),
              makeTextFieldHighlightLimit({ limit: CHATBOT_NAME_LIMIT, onChange: setLimitReached }),
            ]}
          />
          <PresetErrorContainer>
            {limitReached && <Warning text={`${CHATBOT_NAME_LIMIT}-character limit reached`} />}
            {!!nameError && <PresetError reason={nameError} />}
          </PresetErrorContainer>
        </PresetField>
        <PresetFooter>
          <Button text='Back' size='medium' contained='secondary' onClick={onCancel} />
          <Button text='Save' size='medium' disabled={!chatbot.name.length} onClick={handleSave} />
        </PresetFooter>
      </PresetContainer>
    )
  }
)
