import { observer } from 'mobx-react-lite'
import { Switch, Typography, Tooltip } from 'shared/ui'
import { ContactOptIn } from 'entities/Contacts/model/ContactOptIn'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'
import styles from './styles.module.scss'

export const ContactsOptInModalContent = observer(() => {
  const { active } = contactsOptInModalStore

  if (!active) return null

  const { items } = active

  const getLabel = (isDisabled: boolean, isActive: boolean) => {
    if (isDisabled) return ''

    return isActive ? 'Active' : 'Unsubscribed'
  }

  const renderItem = (item: ContactOptIn) => {
    const shortCodeText = item.formatted_number
      ? `${item.formatted_number}: ${item.name}`
      : item.name

    return (
      <div
        key={item.id}
        className={styles.section}
        onClick={() => {
          if (item.disabled) return
          item.handleChangeValue()
        }}
      >
        <Typography
          variant={'text-input-field'}
          className={styles.section__label}
          color={'var(--content-primary-tertiary)'}
          ariaLabel={'name'}
          ellipsis
          tooltipProps={{
            label: shortCodeText,
            placement: 'top',
            marginLeft: 30,
          }}
        >
          {shortCodeText}
        </Typography>

        <Tooltip label={getLabel(item.disabled, item.value)} placement='right'>
          <Switch
            value={item.value}
            disabled={item.disabled}
            ariaLabel={item.value ? 'enabled' : 'disabled'}
          />
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      {items.map((item) => {
        if (item.disabled) {
          return (
            <Tooltip
              fullWidth
              key={item.id}
              label={'Contact has unsubscribed from receiving messages from this number'}
              placement={'right'}
              width={247}
              margin={6}
            >
              {renderItem(item)}
            </Tooltip>
          )
        }

        return renderItem(item)
      })}
    </div>
  )
})
