import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'

type IContactCreateModalActionsProps = {
  contactCreateModalStore: ContactCreateModalStore
}

export const ContactCreateModalActions: FC<IContactCreateModalActionsProps> = observer(
  ({ contactCreateModalStore }) => {
    return (
      <Button
        text={'Done'}
        contained={'secondary'}
        disabled={contactCreateModalStore.disabledButton}
        onClick={contactCreateModalStore.handleSubmit}
      />
    )
  }
)
