import { observer } from 'mobx-react-lite'
import { ReactNode, useEffect } from 'react'
import { DropdownContent, Tabs } from 'shared/ui'
import {
  Immediately,
  IScheduleType,
  Recurring,
  SendLater,
  useScheduleContext,
} from 'shared/ui/Schedule'
import styles from './styles.module.scss'

type IScheduleDropdownContentProps = {
  onCloseMenu: () => void
  dropdownId: string
}

export const ScheduleDropdownContent = observer(
  ({ onCloseMenu, dropdownId }: IScheduleDropdownContentProps) => {
    const {
      setOnCloseMenu,
      tabs,
      type,
      handleSelectTab,
      immediatelyStore,
      setDropdownId,
      sendLaterStore,
      recurringStore,
    } = useScheduleContext()

    useEffect(() => {
      setOnCloseMenu(onCloseMenu)
      setDropdownId(dropdownId)
    }, [])

    const content: { [key in IScheduleType]: ReactNode } = {
      immediately: <Immediately store={immediatelyStore} />,
      later: <SendLater store={sendLaterStore} />,
      recurring: <Recurring store={recurringStore} />,
    }

    return (
      <>
        <DropdownContent noPadding className={styles.wrap}>
          <Tabs
            tabs={tabs}
            activeTabKey={type}
            handleSelectTab={handleSelectTab}
            variant={'simple'}
            paddingInlineWrap={12}
            gap={4}
            tabHeight={48}
          />
          {content[type]}
        </DropdownContent>
      </>
    )
  }
)
