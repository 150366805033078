import classNames from 'classnames'
import { CSSProperties } from 'react'
import { IIconsVector, Icon } from 'shared/ui'
import type { ITextFieldBaseAsideAction } from 'shared/ui/TextField'

import styles from './styles.module.scss'

type ITextFieldIcon = {
  icon?: IIconsVector
  color?: string
  hidden?: boolean
  fontSize?: number
  className?: string
  margin?: CSSProperties['margin']
}

export const makeTextFieldIcon: (action: ITextFieldIcon) => ITextFieldBaseAsideAction = (
  action
) => {
  const useAction: ITextFieldBaseAsideAction = () => {
    if (!action?.icon) return null

    return (
      <div
        className={classNames(
          styles.iconAction,
          {
            [styles.hidden]: action?.hidden,
          },
          action.className
        )}
        style={{ margin: action.margin }}
      >
        <Icon icon={action.icon} color={action?.color} fontSize={action?.fontSize ?? 16} tertiary />
      </div>
    )
  }

  return useAction
}
