import { createContext, useContext } from 'react'
import { type TriggersPageStore } from 'pages/triggers/store/triggersPageStore'

export const TriggersPageContext = createContext<TriggersPageStore | null>(null)

export const useTriggersPageContext = () => {
  const context = useContext(TriggersPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with TriggersPageContext')

  return context
}
