import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import classNames from 'classnames'
import { getLabelAsNumberInternationalFormat, localTime, offsetFromUTC } from 'shared/lib'
import { AvatarGroup, Tooltip, Typography } from 'shared/ui'
import { useContactsProfileCardContext } from 'widgets/ContactsDetails/ui/ContactsProfileCard/context'
import styles from './styles.module.scss'

export const ContactProfileInfo = observer(() => {
  const store = useContactsProfileCardContext()
  const {
    contact,
    hasContact,
    currentContactsAvatarInfo,
    hasParticipants,
    isNameEqualsPhone,
    timezone,
    areaCodeInfo,
    contactsCount,
    name,
    number,
  } = store
  const [localTimeValue, setLocalTimeValue] = useState('')

  useEffect(() => {
    if (timezone) {
      setLocalTimeValue(localTime(timezone))

      const interval: ReturnType<typeof setInterval> = setInterval(() => {
        setLocalTimeValue(localTime(timezone))
      }, 1000 * 60)

      return () => {
        clearInterval(interval)
      }
    }
  }, [contact])

  if (!hasContact) return null

  return (
    <div className={styles.wrap}>
      <AvatarGroup items={currentContactsAvatarInfo} size={40} />
      <div
        className={classNames(styles.info, {
          [styles.withoutTime]: !timezone,
          [styles.isNameEqualsPhone]: isNameEqualsPhone,
        })}
      >
        <div className={styles.user}>
          <div className={styles.wrapName}>
            <Typography
              className={classNames(styles.name, {
                [styles.is_participants]: hasParticipants,
              })}
              variant='body-rg-medium'
              ariaLabel={'name'}
            >
              {getLabelAsNumberInternationalFormat(name)}
            </Typography>
            {hasParticipants && (
              <Typography variant='body-rg-medium' ariaLabel={'more'}>
                {hasParticipants && `+ ${contactsCount - 1} more`}
              </Typography>
            )}
          </div>

          {timezone && (
            <Tooltip
              type={'description'}
              label={`UTC ${offsetFromUTC(timezone)} ${areaCodeInfo}`}
              desc={'Timezone is defined by the contact’s area code'}
              width={170}
              placement={'top-end'}
            >
              <Typography variant='text-input-helper' tag='div' ariaLabel={'localTimeValue'}>
                {localTimeValue}
              </Typography>
            </Tooltip>
          )}
        </div>

        {!isNameEqualsPhone && (
          <Typography
            className={styles.name}
            variant='body-sm-regular'
            tag='div'
            ariaLabel={'formatted_number'}
          >
            {hasParticipants
              ? `${contactsCount} contacts`
              : number && getLabelAsNumberInternationalFormat(number)}
          </Typography>
        )}
      </div>
    </div>
  )
})
