import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Button, InputCheckbox } from 'shared/ui'
import { links } from 'shared/constants/links'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { contactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { contactsHistoryStore } from 'widgets/ContactsHistory/store/ContactsHistoryStore'
import styles from './styles.module.scss'

export const ApplyTagsModalActions = observer(() => {
  const {
    termsAgreed,
    isImportAllowed,
    getStatusImportContact,
    changeTermsAgreed,
    openMapFieldsModal,
    handleClose,
    importHandler,
  } = importContactsStore
  const { selectedListIds } = contactsTagsModalStore
  const { loadImportHistory, getImportContactProgress } = contactsHistoryStore

  const importClick = () => {
    importHandler(selectedListIds, loadImportHistory, getImportContactProgress)
  }

  useEffect(() => {
    !isImportAllowed && getStatusImportContact()
  }, [])
  return (
    <>
      <div className={styles.container}>
        <InputCheckbox
          checked={termsAgreed}
          customLabel={
            <div>
              Agree to our
              <a
                href={links.termsConditions}
                target='_blank'
                className={styles.agreedLink}
                rel='noreferrer'
              >
                Terms & Conditions
              </a>
            </div>
          }
          onChecked={changeTermsAgreed}
        />
        <div>
          <Button
            className={styles.backButton}
            text='Back'
            contained='tertiary'
            onClick={() => {
              handleClose()
              openMapFieldsModal()
            }}
          />
          <Button text='Import' disabled={!termsAgreed} onClick={importClick} />
        </div>
      </div>
    </>
  )
})
