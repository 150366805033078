// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._mCY744JdPmDzBeZwBMv:not(:hover){color:var(--light-blue-60)}", "",{"version":3,"sources":["webpack://./src/widgets/MediaLibrary/ui/Actions/styles.module.scss"],"names":[],"mappings":"AAAA,kCACE,0BAAA","sourcesContent":[".accessLibraryButton:not(:hover) {\n  color: var(--light-blue-60)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accessLibraryButton": "_mCY744JdPmDzBeZwBMv"
};
export default ___CSS_LOADER_EXPORT___;
