import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { CopyWrapper, Icon, Typography, Space } from 'shared/ui'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import { integrationLink, IIntegrationLinkTypes, integrationsStore } from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import { ItemRow } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ItemRow/ItemRow'
import styles from '../styles.module.scss'

export const AllOpportunities = observer(() => {
  const [search, setSearch] = useState('')
  const store = useContactsIntegrationsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)
  const integrationKey = store.viewIntegrationKey

  if (!integrationKey || !contact) return null

  const integration = integrationsStore.getIntegration(integrationKey)
  const linkProps = {
    key: integrationKey,
    contact: contact,
    integration: integration,
  }
  const link = (id: number | string | null) => {
    if (!id) {
      return integrationLink({
        ...linkProps,
        type: IIntegrationLinkTypes.contact,
      })
    }

    return integrationLink({
      ...linkProps,
      id: id,
      type: IIntegrationLinkTypes.opportunities,
    })
  }

  const opportunities = store.viewAllData.opportunities.filter(
    (item) => item?.title?.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  const notFound = opportunities.length === 0

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.search}>
        <TextField
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={setSearch}
          leftActions={[makeTextFieldIcon({ icon: 'search' })]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>
      <div className={styles.list}>
        {opportunities.map((item) => (
          <>
            <div className={styles.headerWrapper}>
              <CopyWrapper
                copyValue={item.title || null}
                clickableTitle
                data={{
                  title: item.title || 'View opportunity',
                  link: link(item.id),
                }}
              />
            </div>
            {store.customTitleIcon &&
              item.properties.map((property) => {
                return <ItemRow key={property.key} item={property} labelType={'simple'} />
              })}
          </>
        ))}
        {notFound ? (
          <div className={styles.epmtyList}>
            <Icon icon='search' fontSize={24} />
            <Typography variant={'body-rg-regular'} ariaLabel={'NoResultsFound'}>
              No results found
            </Typography>
          </div>
        ) : (
          <Space height={60} />
        )}
      </div>
    </div>
  )
})
