import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { TextField } from 'shared/ui/TextField'
import { useChatbotListContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export const ChatbotListHeader = observer(() => {
  const { search, setSearch } = useChatbotListContext()

  return (
    <header className={styles.header}>
      <IconButton
        icon='filter'
        size={'medium'}
        variant={'icon'}
        color={'secondary'}
        ariaLabel='TextbotListHeader_filter'
      />
      <TextField
        className={styles.headerSearch}
        variant='integrated'
        InputProps={{
          placeholder: 'Search...',
        }}
        onChange={setSearch}
        value={search}
      />
    </header>
  )
})
