import React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { errorLogger, globalWatchError, removeGlobalWatchError } from 'entities/EventLog'
import { initSentry } from 'entities/Sentry'
import { appRouterCallPopup, appRouterContent, appRouterSidebar } from 'app/appvue/router'
import { ErrorBoundaryFallback } from 'app/layouts/ErrorBoundaryFallback'
import './../styles/all.scss'
import './../store/index'

initSentry()

window.initSidebar = () => {
  const sidebar = createRoot(document.getElementById('sidebar') as HTMLElement)

  if (sidebar) {
    window.resetSidebar = () => {
      sidebar.unmount()
    }

    sidebar.render(
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorLogger}>
        <RouterProvider router={appRouterSidebar} />
      </ErrorBoundary>
    )
  }
}

window.initAppReact = () => {
  const appReact = createRoot(document.getElementById('app-react') as HTMLElement)

  globalWatchError()

  if (appReact) {
    window.resetAppReact = () => {
      appReact.unmount()

      removeGlobalWatchError()
    }

    appReact.render(
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorLogger}>
        <RouterProvider router={appRouterContent} />
      </ErrorBoundary>
    )
  }
}

window.initCallPopup = () => {
  const appReact = createRoot(document.getElementById('portal') as HTMLElement)

  if (appReact) {
    appReact.render(
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorLogger}>
        <RouterProvider router={appRouterCallPopup} />
      </ErrorBoundary>
    )
  }
}
