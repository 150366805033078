import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { IResponseEventInboxCalling } from 'entities/Inbox/api/types'
import { CallHistoryStore } from 'widgets/CallHistory'

export const EventInboxCalling = {
  subscribe: (handler: (data: IResponseEventInboxCalling) => void) =>
    websocket.on(WebsocketEvent.InboxCalling, handler),
  unsubscribe: (handler: (data: IResponseEventInboxCalling) => void) =>
    websocket.off(WebsocketEvent.InboxCalling, handler),
}

export const useEventInboxCalling = (callHistoryStore: CallHistoryStore) => {
  const handler = (data: IResponseEventInboxCalling) => {
    callHistoryStore.updateInboxCalling(data)
  }

  useEffect(() => {
    EventInboxCalling.subscribe(handler)

    return () => {
      EventInboxCalling.unsubscribe(handler)
    }
  }, [])
}
