import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useGetStep } from 'shared/hooks'
import { StepsCommonLayout } from 'pages/settings/pages/compliance/ui/StepsCommonLayout/StepsCommonLayout'
import { TollFreeRoutesSteps } from 'pages/settings/pages/compliance/pages/tollFree/route/type'
import { useTollFreeStepsContext } from 'pages/settings/pages/compliance/pages/tollFree/context/tollFreeStepsContext'
import { useProgressInfoTitle } from 'pages/settings/pages/compliance/pages/tollFree/hooks/useProgressInfoTitle'

export const TollFreeStepsLayout = observer(() => {
  const { getSettingsStep, decreaseStep, closeSetting, tollFreeNavigationStore } =
    useTollFreeStepsContext()
  const settingsStep = useGetStep<TollFreeRoutesSteps>(getSettingsStep)
  const infoTitle = useProgressInfoTitle()

  useEffect(() => {
    tollFreeNavigationStore.setCurrentStep(settingsStep)
  }, [settingsStep])

  return (
    <StepsCommonLayout
      progressTitle={infoTitle}
      totalSteps={tollFreeNavigationStore.allStepsLength}
      title='Toll-free verification'
      settingsStep={settingsStep}
      closeSetting={closeSetting}
      decreaseStep={() => decreaseStep()}
    />
  )
})
