import { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type ConversationNewSearchStore } from 'widgets/ConversationNew'
import { ConversationNewSearchContext } from 'widgets/ConversationNew/context'
import { ConversationNewContent } from './ConversationNewContent'

type IConversationNewProps = {
  store: ConversationNewSearchStore
}

export const ConversationNew: FC<IConversationNewProps> = observer(({ store }) => {
  return useMemo(
    () => (
      <ConversationNewSearchContext.Provider value={store}>
        <ConversationNewContent />
      </ConversationNewSearchContext.Provider>
    ),
    []
  )
})
