import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { AuthLayout } from 'shared/layout'
import { SpinnerLoader } from 'shared/ui'
import { ResetPasswordStore } from 'widgets/ResetPassword/store/resetPasswordStore'
import { ExpiredFormBottom } from 'widgets/ResetPassword/ui/ExpiredFormBottom/ExpiredFormBottom'
import { ExpiredForm } from 'widgets/ResetPassword/ui/ExpiredFrom/SuccessForm'
import { ResetPasswordForm } from 'widgets/ResetPassword/ui/ResetPasswordForm/ResetPasswordForm'

import styles from './styles.module.scss'

export const ResetPasswordContent = observer(({ store }: { store: ResetPasswordStore }) => {
  if (store.preloading) {
    return (
      <div className={styles.wrap}>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <AuthLayout
        title={store.isValidToken ? 'Create new password' : undefined}
        form={store.isValidToken ? <ResetPasswordForm store={store} /> : <ExpiredForm />}
        formClassnames={store.isValidToken ? styles.successWrap : undefined}
        formBottom={store.isValidToken ? undefined : <ExpiredFormBottom />}
      />
    </div>
  )
})

export const ResetPassword = () => {
  const { token } = useParams()

  const store = new ResetPasswordStore(token || '')

  return <ResetPasswordContent store={store} />
}

export default ResetPassword
