import React, { ReactNode, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { layoutStore } from 'shared/layout/Layout/store/LayoutStore'
import { PageLayoutStore } from 'shared/layout/PageLayout/store/PageLayoutStore'
import styles from './styles.module.scss'

type IPageLayoutProps = {
  leftContent: ReactNode
  rightContent?: ReactNode
  store?: PageLayoutStore
  mobileWithSizeM?: boolean
}

export const PageLayout = observer(
  ({ leftContent, rightContent, store, mobileWithSizeM = false }: IPageLayoutProps) => {
    const mobileView = mobileWithSizeM
      ? layoutStore.isMobileView
      : layoutStore.isMobileViewWithoutMSize
    const isCollapse = store?.isCollapse
    const handleToggleCollapse = store?.handleToggleCollapse
    const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
      e.stopPropagation()
      if (!mobileView) return
      handleToggleCollapse && handleToggleCollapse()
    }
    useEffect(() => {
      if (store) {
        if (mobileView && !store?.isCollapse) {
          store.handleToggleCollapse()
        }
        if (!mobileView && store?.isCollapse && !store.isManualCollapse) {
          store.handleToggleCollapse()
        }
      }
    }, [mobileView])
    return (
      <div className={styles.wrap}>
        {store && mobileView && !store?.isCollapse && (
          <div className={styles.overlay} onClick={onClick} />
        )}
        <div
          className={classNames({
            [styles.mobile]: store && mobileView,
            [styles.hiddenLeftContent]: isCollapse,
          })}
        >
          {leftContent}
        </div>
        <div className={styles.contentWrapper}>{rightContent || <Outlet />}</div>
      </div>
    )
  }
)
