import { makeAutoObservable, reaction, IReactionDisposer } from 'mobx'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { Contact } from 'entities/Contacts/model/Contact'
import { Phone } from 'entities/Phone/model/Phone'
import { IResponseInbox } from 'entities/Inbox/api/types'
import { IResponseNumber } from 'entities/Phone/api/types'
import { IResponseContact } from 'entities/Contacts/api/types'
import { inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { contactsStore } from 'entities/Contacts'

type IPowerDialerSessionResponse = {
  team?: IResponseInbox
  number?: IResponseNumber
  items: IResponseContact[]
}

type IPowerDialerSessionData = {
  team: Inbox
  number: Phone
  items: Contact[]
}

export const getPowerDialerSession = () => {
  return new Promise<false | IPowerDialerSessionData>((resolve) => {
    const cache = sessionStorage.getItem('power_dialer')

    if (!cache) return resolve(false)

    const { team, number, items } = JSON.parse(cache) as IPowerDialerSessionResponse

    if (!team || !number || !items.length) {
      sessionStorage.removeItem('power_dialer')

      return resolve(false)
    }

    const inbox = inboxesStore.addItem(team) as Inbox
    const phone = numbersStore.addItem(number) as Phone
    const contacts = contactsStore.addItems(items)

    resolve({
      team: inbox,
      number: phone,
      items: contacts,
    })
  })
}

export class CallPopUpPowerDialerSessionStore {
  private _team: Inbox | null = null
  private _number: Phone | null = null
  private _contactsMap: Map<number, Contact> = new Map()
  private _disposeSyncCache: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this.reactionSyncCache()
  }

  reset = () => {
    this._disposeSyncCache?.()
    this._team = null
    this._number = null
    this._contactsMap.clear()
    sessionStorage.removeItem('power_dialer')
  }

  setTeam = (team: Inbox) => {
    this._team = team
  }

  setNumber = (number: Phone) => {
    this._number = number
  }

  setContacts = (items: Contact[]) => {
    items.forEach((item) => {
      this._contactsMap.set(item.id, item)
    })
  }

  deleteContact = (id: number) => {
    this._contactsMap.delete(id)
  }

  reactionSyncCache = () => {
    this._disposeSyncCache?.()
    this._disposeSyncCache = reaction(
      () => ({
        team: this._team,
        number: this._number,
        contacts: this.contacts,
      }),
      ({ team, number, contacts }) => {
        const teamResponse = team?.origin
        const numberResponse = number?.origin
        const contactsResponse = contacts.map((item) => item.origin)

        if (teamResponse && numberResponse && contactsResponse.length) {
          sessionStorage.setItem(
            'power_dialer',
            JSON.stringify({
              team: teamResponse,
              number: numberResponse,
              items: contactsResponse,
            })
          )
        } else {
          sessionStorage.removeItem('power_dialer')
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  get contacts() {
    return Array.from(this._contactsMap.values())
  }
}
