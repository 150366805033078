import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, DropdownContent, Typography } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { IInputNumericProps, InputNumeric, TextField } from 'shared/ui/TextField'
import { useRecurringContext } from 'shared/ui/Schedule/ui/Recurring/context/recurringContext'
import styles from './styles.module.scss'

type IOnTheContentProps = {
  onCloseMenu: () => void
}

export const AfterContent = observer(({ onCloseMenu }: IOnTheContentProps) => {
  const { onConfirmAfterEndRepeat, endTimes } = useRecurringContext()
  const [times, setTimes] = useState(endTimes)

  const onBlur = () => {
    if (!Number(times)) {
      setTimes('1')
    }
  }
  const handleConfirm = () => {
    onConfirmAfterEndRepeat({
      times,
    })
    onCloseMenu()
  }
  return (
    <DropdownContent noPadding>
      <div className={styles.content}>
        <Label
          label={{
            text: 'Repeat ends after',
            className: styles.label,
            textClassName: styles.textLabel,
          }}
        />
        <div className={styles.right}>
          <TextField
            variant={'outline'}
            value={times}
            onChange={setTimes}
            Input={InputNumeric}
            InputProps={
              {
                decimalSeparator: '',
                maxLength: 12,
              } as IInputNumericProps
            }
            className={styles.input}
            onBlur={onBlur}
          />
          <Typography
            variant={'body-rg-regular'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'times'}
          >
            times
          </Typography>
        </div>
      </div>
      <div className={styles.actions}>
        <Button text={'Cancel'} fullWidth contained={'secondary'} onClick={onCloseMenu} />
        <Button text={'Confirm'} fullWidth contained={'primary'} onClick={handleConfirm} />
      </div>
    </DropdownContent>
  )
})
