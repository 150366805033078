import React from 'react'
import classnames from 'classnames'
import type { ITooltipProps } from 'shared/ui'
import { toastStore } from 'shared/ui/Toast'
import { IconButton } from 'shared/ui/IconButton/ui/IconButton'
import { writeTextToClipboard } from 'shared/lib'
import styles from '../styles.module.scss'

type IIconCopyButtonProps = {
  value: string | number | undefined
  showTooltip?: boolean
  show?: boolean
  onClose?: () => void
  onOpen?: () => void
}

export const IconCopyButton: React.FC<IIconCopyButtonProps> = ({
  value,
  showTooltip = false,
  show = true,
  onOpen,
  onClose,
}) => {
  const handleClose = () => {
    onClose && onClose()
  }

  const handleOpen = () => {
    onOpen && onOpen()
  }

  const tooltipProps: ITooltipProps = {
    label: 'Copy',
    placement: 'top',
    disableInteractive: true,
    onClose: handleClose,
    onOpen: handleOpen,
  }

  const onCopy = async () => {
    if (!window?.navigator?.clipboard) return

    try {
      await writeTextToClipboard(`${value}`)
      toastStore.add({
        title: 'Copied to clipboard',
        type: 'info',
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div
      className={classnames(styles.rightIcon, {
        [styles.hidden]: !show,
      })}
    >
      <IconButton
        icon={'copy'}
        variant={'icon'}
        color={'secondary'}
        size={'small'}
        fontSize={16}
        onClick={onCopy}
        tooltipProps={showTooltip ? tooltipProps : undefined}
        ariaLabel='IconCopyButton'
      />
    </div>
  )
}
