// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yPcMthQoGg2O9kkEXv1Q{visibility:hidden}.aYP9UdAhae0_ob1pPWL7{margin-bottom:2px}.aYP9UdAhae0_ob1pPWL7:hover .yPcMthQoGg2O9kkEXv1Q{visibility:visible}.SAJOQnF11t71HJmchMXA .yPcMthQoGg2O9kkEXv1Q{visibility:visible}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsMenu/ui/SegmentItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CAEJ,sBACI,iBAAA,CAEA,kDACI,kBAAA,CAIJ,4CACI,kBAAA","sourcesContent":[".moreButton {\n    visibility: hidden;\n}\n.segment {\n    margin-bottom: 2px;\n\n    &:hover .moreButton {\n        visibility: visible;\n    }\n}\n.activeDropdown {\n    .moreButton {\n        visibility: visible;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreButton": "yPcMthQoGg2O9kkEXv1Q",
	"segment": "aYP9UdAhae0_ob1pPWL7",
	"activeDropdown": "SAJOQnF11t71HJmchMXA"
};
export default ___CSS_LOADER_EXPORT___;
