import { useEffect } from 'react'
import { useBlocker, useLocation } from 'react-router'
import { observer } from 'mobx-react-lite'
import { ChatbotProvider, useChatbotContext } from 'pages/chatbot/context'
import {
  ChatbotButtonGroup,
  ChatbotCancelButton,
  ChatbotCreateButton,
  ChatbotLayout,
  ChatbotLayoutContent,
  ChatbotLayoutFooter,
  ChatbotLayoutHeader,
  RootLayout,
  DialogLayout,
  DialogPlaceholder,
  ChatbotCreateAndPublishButton,
} from 'pages/chatbot/ui'
import { ChatbotBody, ChatbotHeader } from 'pages/chatbot/ui/ChatbotEdit'
import { ChatbotNewHead } from './ChatbotNewHead'

export const ChatbotNew = observer(() => {
  const { state: payload } = useLocation()
  const {
    model,
    enrollment,
    isDraggingCondition,
    initResources,
    confirmCreateChatbot,
    checkChanges,
  } = useChatbotContext()
  const blocker = useBlocker(() => {
    const hasChanges = checkChanges()

    return hasChanges
  })

  useEffect(() => {
    if (payload) model.syncOrigin(payload)
  }, [payload])

  useEffect(() => {
    if (blocker.state === 'blocked') confirmCreateChatbot(blocker)
  }, [blocker.state])

  useEffect(() => {
    initResources()
    enrollment.initFilters()
  }, [])

  return (
    <ChatbotLayout>
      <ChatbotLayoutHeader>
        <ChatbotNewHead />
        <ChatbotHeader />
      </ChatbotLayoutHeader>
      <ChatbotLayoutContent isDraggingCondition={isDraggingCondition}>
        <ChatbotBody />
      </ChatbotLayoutContent>
      <ChatbotLayoutFooter>
        <ChatbotButtonGroup>
          <ChatbotCreateButton />
          <ChatbotCancelButton />
        </ChatbotButtonGroup>
        <ChatbotButtonGroup>
          <ChatbotCreateAndPublishButton />
        </ChatbotButtonGroup>
      </ChatbotLayoutFooter>
    </ChatbotLayout>
  )
})

const ChatbotNewDialog = () => (
  <DialogLayout>
    <DialogPlaceholder />
  </DialogLayout>
)

export const ChatbotNewPage = () => (
  <ChatbotProvider>
    <RootLayout>
      <ChatbotNew />
      <ChatbotNewDialog />
    </RootLayout>
  </ChatbotProvider>
)
