import { FC } from 'react'
import { Dropdown } from 'shared/ui'
import { IInboxCombine } from 'entities/Inbox'
import {
  actionTeamsFavorite,
  menuTeamsFavorite,
  menuUngroup,
  actionUngroup,
  menuRename,
  actionRename,
} from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/Dropdown'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'

type PropsDropDown = {
  inbox: IInboxCombine
}

export const DropdownGroupInbox: FC<PropsDropDown> = ({ inbox }) => {
  const store = useConversationMenuContext()

  return (
    <Dropdown
      ariaLabel={'DropdownGroupInbox'}
      icon={'moreHorizontal'}
      placement={'bottom-end'}
      tooltipProps={{
        disableInteractive: true,
        label: 'Show actions',
        placement: 'top-end',
      }}
      items={[menuTeamsFavorite(inbox), menuUngroup(), menuRename()]}
      onChange={(item) => {
        actionTeamsFavorite({ inbox, item }, store)
        actionUngroup({ inbox, item }, store)
        actionRename({ inbox, item })
      }}
      width={inbox.is_favorite ? 128 : 122}
    />
  )
}
