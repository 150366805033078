// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SoUbHsSPMyHyeCWIp6mm{flex:1}.Kgngx7DiLDmT6bZ4tosu{position:relative;padding-top:24px;width:100%;height:100%}.sZH19PxsMcfXlAe57IDB{width:155px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.wp2gD9c3ItehFyPjxucU{position:relative;width:32px;height:32px;min-width:32px;border-radius:6px;background-color:var(--background-neutral-subtle);overflow:hidden}.m9y7YxsV3wwpRGbAZFvg{display:flex;align-items:center;justify-content:center;width:100%;height:100%;border-radius:6px;background-color:var(--background-neutral-primary)}.QbKixroc7FZZMUPMptxv{display:flex;height:fit-content;width:100%;justify-content:center;margin-top:8px}", "",{"version":3,"sources":["webpack://./src/widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CAGF,sBACE,iBAAA,CACA,gBAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,WAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAGF,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,iDAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,kDAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".tableView {\n  flex: 1;\n}\n\n.tableContainer {\n  position: relative;\n  padding-top: 24px;\n  width: 100%;\n  height: 100%;\n}\n\n.name {\n  width: 155px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.mediaContainer {\n  position: relative;\n  width: 32px;\n  height: 32px;\n  min-width: 32px;\n  border-radius: 6px;\n  background-color: var(--background-neutral-subtle);\n  overflow: hidden;\n}\n\n.mediaStub {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  border-radius: 6px;\n  background-color: var(--background-neutral-primary);\n}\n\n.spinnerMoreContainer {\n  display: flex;\n  height: fit-content;\n  width: 100%;\n  justify-content: center;\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableView": "SoUbHsSPMyHyeCWIp6mm",
	"tableContainer": "Kgngx7DiLDmT6bZ4tosu",
	"name": "sZH19PxsMcfXlAe57IDB",
	"mediaContainer": "wp2gD9c3ItehFyPjxucU",
	"mediaStub": "m9y7YxsV3wwpRGbAZFvg",
	"spinnerMoreContainer": "QbKixroc7FZZMUPMptxv"
};
export default ___CSS_LOADER_EXPORT___;
