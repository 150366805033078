import { CSSProperties, FC } from 'react'
import classnames from 'classnames'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

type DropdownContentProps = {
  children?: React.ReactNode
  noPadding?: boolean
  noShadow?: boolean
  noStyle?: boolean
  className?: string
  width?: number
}

export const DropdownContent: FC<DropdownContentProps> = ({
  children,
  noPadding,
  noShadow,
  noStyle,
  className,
  width,
}) => {
  const style: CSSProperties = {}

  if (width) {
    style.width = `${width}px`
  }

  return (
    <div
      className={classnames(
        {
          [styles.content__noPadding]: noPadding,
          [styles.content__noShadow]: noShadow,
          [styles.content]: !noStyle,
        },
        className
      )}
      aria-label={getAriaLabel('DropdownContent')}
      style={style}
    >
      {children}
    </div>
  )
}
