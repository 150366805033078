import { observer } from 'mobx-react-lite'

import { useDetailsContext } from '../detailsContext'
import { ActionList } from './ActionList'
import styles from './styles.module.scss'

export const TabActions = observer(() => {
  const { data } = useDetailsContext()

  return (
    <div className={styles.actionCards}>
      <ActionList name='Success' actions={data.success_actions} />
      <ActionList name='Fallback' actions={data.fallback_actions} />
      {!!data.settings?.await_reply && (
        <div className={styles.actionCard}>
          <header className={styles.actionCardHead}>Fallback triggers</header>
          <ul className={styles.actionCardList}>
            <li className={styles.actionCardListItem}>{`Max number of ${
              data.settings?.max_attempts?.value ?? '0'
            } attempts is reached`}</li>
            <li
              className={styles.actionCardListItem}
            >{`Didn’t get a reply in ${data.settings.await_reply.value} ${data.settings.await_reply.unit}`}</li>
          </ul>
        </div>
      )}
    </div>
  )
})
