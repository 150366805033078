import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Status, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats, capitalizeWords, numberFormat } from 'shared/lib'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { statusesMap } from 'pages/admin/pages/organizations/pages/CustomPlan/ui/CustomPaymentsScheduleTable/constants'
import { adminOrganizationDetailStore } from '../../store/adminOrganizationDetailStore'
import styles from './styles.module.scss'

export const CustomPayments = observer(() => {
  const { customPayments, fetchCustomPayments, organization } = adminOrganizationDetailStore

  useEffect(() => {
    fetchCustomPayments()
  }, [])

  const generateData = (): IAdminDetailCardRowProps[] => {
    const planInfo = customPayments[0]
    const planName = capitalizeWords(planInfo.plan.replaceAll('-', ' '))
    const creditsTotal = numberFormat({
      value: customPayments.reduce((acc, cur) => (acc += cur.credits), 0),
    })
    const amountTotal = numberFormat({
      value: customPayments.reduce((acc, cur) => (acc += cur.amount / 100), 0),
      currency: 'USD',
    })
    const renewalDate = uiStore
      .dayjs(organization?.stripe_data.subscription.renewal_date)
      .format(DayjsFormats.full6)

    const data: IAdminDetailCardRowProps[] = [
      {
        title: 'Plan',
        value: `Custom Plan ${planName}, Credits total: ${creditsTotal}, Amount: ${amountTotal}, Renewal Date: ${renewalDate}`,
      },
    ]

    if (customPayments.length > 0) {
      customPayments.forEach((payment) => {
        data.push({
          title: payment.name,
          value: '',
          renderValue: () => (
            <div className={styles.container}>
              <Status {...statusesMap[payment.status]} className={styles.customPaymentStatus} />

              <Typography variant={'body-md-regular'} ariaLabel={'Date'}>
                -{'>'} (Date: {uiStore.dayjs(payment.payment_date).format(DayjsFormats.full6)},
                Amount: {numberFormat({ value: payment.amount / 100, currency: 'USD' })}, Credits:{' '}
                {numberFormat({ value: payment.credits })})
              </Typography>
            </div>
          ),
        })
      })
    }

    return data
  }

  return customPayments.length > 0 ? (
    <AdminDetailCard
      title={'Custom payments'}
      data={generateData()}
      cardStyles={{ gridArea: 'custom' }}
    />
  ) : null
})
