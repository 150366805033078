import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { IResponseBroadcastById } from 'entities/Broadcast/api/types'

type IHandlerEvent = (data: IResponseBroadcastById) => void

export const EventBroadcastCanceled = {
  subscribe: (handler: IHandlerEvent) =>
    websocket.on(WebsocketEvent.BroadcastCanceled, (data) => handler(data.broadcast)),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.BroadcastCanceled, (data) => handler(data.broadcast)),
}

export const useEventBroadcastCanceled = (handler: IHandlerEvent) =>
  useEffect(() => EventBroadcastCanceled.subscribe(handler), [])
