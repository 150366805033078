import { observer } from 'mobx-react-lite'
import React from 'react'
import { EnumSpinnerLoaderPosition, FullPageWrapper, SpinnerLoader } from 'shared/ui'
import {
  PowerDialerViewProvider,
  usePowerDialerViewContext,
} from 'pages/powerDialer/pages/view/context/powerDialerViewContext'
import { PowerDialerViewHeader } from 'pages/powerDialer/pages/view/ui/PowerDialerViewHeader/PowerDialerViewHeader'

const PowerDialerView = observer(() => {
  const { isLoading } = usePowerDialerViewContext()

  if (isLoading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }

  return (
    <FullPageWrapper>
      <PowerDialerViewHeader />
    </FullPageWrapper>
  )
})

export const PowerDialerViewPage = () => (
  <PowerDialerViewProvider>
    <PowerDialerView />
  </PowerDialerViewProvider>
)
