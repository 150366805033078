import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseConversation } from 'entities/Conversation/api/types'

const ConversationContactOptedIn = {
  handle: (data: IResponseConversation) => {
    conversationStore.updateItem(data)
  },
}

export const EventConversationContactOptedIn = {
  subscribe: () =>
    websocket.on(WebsocketEvent.ConversationContactOptedIn, ConversationContactOptedIn.handle),
  unsubscribe: () =>
    websocket.off(WebsocketEvent.ConversationContactOptedIn, ConversationContactOptedIn.handle),
}
