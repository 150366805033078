import { IIconsVector } from 'shared/ui'
import { IResponseBillingSource, IResponseBillingSourceAch } from 'entities/Billing/api/types'

export class Source {
  id: string
  object: 'payment_method' | 'card'
  brand: string
  last4: string
  exp_month: number
  exp_year: number
  primary: boolean

  constructor(item: IResponseBillingSource | IResponseBillingSourceAch) {
    this.id = item.id
    this.object = item.object

    if (item.object === 'card') {
      this.brand = item.brand || ''
      this.last4 = item.last4
      this.exp_month = item.exp_month
      this.exp_year = item.exp_year
      this.primary = item.primary
    } else {
      this.brand = ''
      this.last4 = item.us_bank_account.last4
      this.exp_month = 0
      this.exp_year = 0
      this.primary = false
    }
  }

  get isMethodAch() {
    return this.object === 'payment_method'
  }

  get isMethodCard() {
    return this.object === 'card'
  }

  get exp() {
    if (this.isMethodAch) return ''

    const exp_year = String(this.exp_year).slice(2)

    return `${this.exp_month}/${exp_year}`
  }

  get name() {
    return `${this.label} .... ${this.last4}`
  }

  get label() {
    if (this.isMethodAch) return 'Chase ACH'

    switch (this.brand.toLowerCase().replace(' ', '')) {
      case 'visa':
        return 'Visa'
      case 'mastercard':
        return 'Mastercard'
      case 'americanexpress':
      case 'amex':
        return 'American Express'
      case 'discover':
        return 'Discover'
      case 'jcb':
        return 'JCB'
      case 'dinersclub':
        return 'Diners Club'
      default:
        return this.brand
    }
  }

  get icon(): IIconsVector {
    switch (this.brand.toLowerCase().replace(' ', '')) {
      case 'visa':
        return 'visa'
      case 'mastercard':
        return 'mastercard'
      case 'americanexpress':
      case 'amex':
        return 'americanExpress'
      case 'discover':
        return 'discover'
      case 'jcb':
        return 'JCB'
      case 'dinersclub':
        return 'dinersClub'
      default:
        return 'bank'
    }
  }

  get isPrimary() {
    return this.primary
  }

  setPrimary = (value: boolean) => {
    this.primary = value
  }
}
