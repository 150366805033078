import { ContainerHeader } from 'shared/ui'
import { ConversationSearchHeaderContent } from 'widgets/ConversationNew/ui/ConversationSearchHeader/ConversationSearchHeaderContent'

export const ConversationSearchHeader = () => {
  return (
    <ContainerHeader
      type={'container'}
      container={<ConversationSearchHeaderContent />}
      isBorderBottom
    />
  )
}
