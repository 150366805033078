import { Status } from 'shared/ui'
import styles from './styles.module.scss'

export const SendFromScaleLabel = () => {
  return (
    <div className={styles.scaleLabel}>
      Scaler <Status title={'New'} intent={'purple'} emphasis={'high'} size={'small'} />
    </div>
  )
}
