import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { IAPiOption } from 'shared/api'
import { IDropdownItem } from 'shared/ui'
import { logger } from 'shared/lib'
import { ComplianceApi, IResponseComplianceServicesBrand } from 'entities/Compliance'
export class BusinessInfoStore {
  constructor() {
    makeAutoObservable(this)
    this.init()
  }

  private _businessTypes: IAPiOption[] = []
  private _businessIndustries: IAPiOption[] = []
  private _activeBusinessTypeId: number | null = null
  private _activeBusinessIndustryId: number | null = null
  private _websiteUrl: string | null = null
  private _socialMediaUrl: string | null = null
  private _isCorrectUrl = false
  private _noWebsiteUrl = false
  private _websiteUrlLocalError = ''
  private _socialMediaUrlError = ''
  private _regionsOfOperation: IAPiOption[] = []
  private _activeRegionOfOperationIds: number[] = []
  private _needCheckUrlAfterChange = false

  get businessTypes(): IDropdownItem[] {
    return this._businessTypes.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get activeBusinessTypeId() {
    return this._activeBusinessTypeId
  }

  get businessIndustries(): IDropdownItem[] {
    return this._businessIndustries.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get activeBusinessIndustryId() {
    return this._activeBusinessIndustryId
  }

  get websiteUrl() {
    return this._websiteUrl
  }

  get isCorrectUrl() {
    return this._isCorrectUrl
  }

  get socialMediaUrl() {
    return this._socialMediaUrl
  }

  get noWebsiteUrl() {
    return this._noWebsiteUrl
  }

  get activeRegionOfOperationIds() {
    return this._activeRegionOfOperationIds
  }

  get regionsOfOperation(): IDropdownItem[] {
    return this._regionsOfOperation.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get activeRegionsOfOperation(): IDropdownItem[] {
    return this._regionsOfOperation
      .filter((item) => this.activeRegionOfOperationIds.includes(item.id as number))
      .map((item) => ({ label: item.name || '', id: item.id }))
  }

  get websiteUrlLocalError() {
    return this._websiteUrlLocalError
  }

  get socialMediaUrlError() {
    return this._socialMediaUrlError
  }

  get isNextDisabled() {
    const socialMediaUrlRequired = this._noWebsiteUrl && !this._socialMediaUrl
    const noRequiredWebsiteUrl = !this._noWebsiteUrl && !this._websiteUrl

    if (
      this._websiteUrlLocalError ||
      this._socialMediaUrlError ||
      !this._activeBusinessTypeId ||
      !this._activeBusinessIndustryId ||
      socialMediaUrlRequired ||
      noRequiredWebsiteUrl ||
      !this._activeRegionOfOperationIds?.length ||
      this._needCheckUrlAfterChange
    ) {
      return true
    }

    return false
  }

  init = () => {
    this.loadBusinessTypes()
    this.loadBusinessIndustries()
    this.loadBusinessProfileRegionsOfOperation()
  }

  initFromBrand = (data: IResponseComplianceServicesBrand['data']) => {
    if (!data) return

    this.setBusinessType(data.business_type?.id || null)
    this.setBusinessIndustry(data.industry?.id || null)
    this.setWebsiteUrl(data.website_url, true)
    this.setSocialMediaUrl(data.socialMediaProfile)
    this.setNoWebsiteUrl(Boolean(data.socialMediaProfile))
    data.regions_of_operation &&
      this.setActiveRegionOfOperationIds(data.regions_of_operation.map(({ id }) => id))
  }

  setWebsiteUrlLocalError = (error: string) => {
    this._websiteUrlLocalError = error
  }

  setSocialMediaUrlError = (error: string) => {
    this._socialMediaUrlError = error
  }

  checkUrl = async (url: string, type: 'websiteUrl' | 'socialMediaUrl') => {
    const errorText = 'Please enter a valid url (e.g. https://www.example.com/)'

    const setErrorAction =
      type === 'websiteUrl' ? this.setWebsiteUrlLocalError : this.setSocialMediaUrlError

    try {
      const { data } = await ComplianceApi.onlineFormUrlCheck({ onlineFormUrl: url })

      if (!data.reachable && type === 'websiteUrl' && this._noWebsiteUrl) {
        // click to I don't have a website before response from backend
        return
      }

      if (!data.reachable) {
        setErrorAction(errorText)
      }

      this._isCorrectUrl = data.reachable
      this._needCheckUrlAfterChange = !data.reachable
    } catch (error) {
      if (error instanceof AxiosError) {
        const { message } = error.response?.data || {}

        if (message && type === 'websiteUrl' && this._noWebsiteUrl) {
          // click to I don't have a website before response from backend
          return
        }

        if (message) {
          setErrorAction(message)
        }
      }
      logger.error(error)
    }
  }

  setBusinessType = (businessTypeId: number | null) => {
    this._activeBusinessTypeId = businessTypeId
  }

  loadBusinessTypes = async () => {
    if (this._businessTypes.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileBusinessTypes()
      this._businessTypes = data
    } catch (e) {
      logger.error(e)
    }
  }

  setBusinessIndustry = (businessIndustryId: number | null) => {
    this._activeBusinessIndustryId = businessIndustryId
  }

  loadBusinessIndustries = async () => {
    if (this._businessIndustries.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileIndustries()
      this._businessIndustries = data
    } catch (e) {
      logger.error(e)
    }
  }

  setIsCorrectUrl = (value: boolean) => {
    this._isCorrectUrl = value
  }

  setWebsiteUrl = (url: string | null, fromRequest?: boolean) => {
    this._needCheckUrlAfterChange = !fromRequest
    this.setIsCorrectUrl(false)

    if (this._websiteUrlLocalError) {
      this.setWebsiteUrlLocalError('')
    }
    if (fromRequest && url?.length) {
      this.setIsCorrectUrl(true)
    }

    this._websiteUrl = url
  }

  setSocialMediaUrl = (url: string | null) => {
    if (this._socialMediaUrlError) {
      this.setSocialMediaUrlError('')
    }

    this._socialMediaUrl = url
  }

  setNoWebsiteUrl = (value: boolean) => {
    this._noWebsiteUrl = value
  }

  handleSetNoWebsiteUrl = () => {
    this.setWebsiteUrlLocalError('')
    this.setSocialMediaUrlError('')

    this.setWebsiteUrl(null)
    this.setSocialMediaUrl(null)
    this.setNoWebsiteUrl(!this._noWebsiteUrl)
  }

  setRegionsOfOperationId = (regionsOfOperation: number) => {
    this._activeRegionOfOperationIds = [...this.activeRegionOfOperationIds, regionsOfOperation]
  }

  setActiveRegionOfOperationIds = (ids: number[]) => {
    this._activeRegionOfOperationIds = ids
  }

  deleteRegionsOfOperationId = (regionsOfOperation: number) => {
    this._activeRegionOfOperationIds = this.activeRegionOfOperationIds.filter(
      (id) => id !== regionsOfOperation
    )
  }

  loadBusinessProfileRegionsOfOperation = async () => {
    if (this._regionsOfOperation.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileRegionsOfOperation()
      this._regionsOfOperation = data
    } catch (e) {
      logger.error(e)
    }
  }
}
