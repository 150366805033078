import React, { useEffect, useRef, useState } from 'react'
import { MergeFieldTooltip } from 'widgets/MergeField/ui/MergeFieldTooltip/MergeFieldTooltip'
import { MergeFieldConnectedTooltip } from 'widgets/MergeField/ui/MergeFieldConnectedTooltip/MergeFieldConnectedTooltip'
import { placeCaretAtEnd, placeCaretAtStart } from 'widgets/MessageField'

type IUseMergeFields<T> = {
  updateTriggerDeps: Array<T | HTMLElement | HTMLDivElement | string | null>
  trigger: HTMLDivElement | null
  withEdit?: boolean
  setUpdateTriggerContent?: () => void
}

export function useMergeFields<T>({
  trigger,
  updateTriggerDeps,
  withEdit,
  setUpdateTriggerContent,
}: IUseMergeFields<T>) {
  const refVisibleTooltip = useRef(false)
  const refVisibleIntegrationStatusTooltip = useRef(false)

  const [currentMergeField, setCurrentMergeField] = useState<HTMLSpanElement | undefined>(undefined)

  const [currentIntegrationStatus, setCurrentIntegrationStatus] = useState<
    HTMLSpanElement | undefined
  >(undefined)

  const onHoverMergeField = (span: HTMLSpanElement) => () => {
    if (!withEdit && !span?.dataset.fallback) return
    refVisibleTooltip.current = true
    setCurrentMergeField(span)
    setCurrentIntegrationStatus(undefined)
  }

  const onLeaveHoverMergeField = () => {
    refVisibleTooltip.current = false
    setTimeout(() => {
      if (!refVisibleTooltip.current) {
        setCurrentMergeField(undefined)
      }
    }, 1000)
  }

  const onHoverIntegrationStatus = (span: HTMLSpanElement) => () => {
    refVisibleIntegrationStatusTooltip.current = true
    setCurrentIntegrationStatus(span)
    setCurrentMergeField(undefined)
  }

  const onLeaveIntegrationStatus = () => {
    refVisibleIntegrationStatusTooltip.current = false
    setTimeout(() => {
      if (!refVisibleIntegrationStatusTooltip.current) {
        setCurrentIntegrationStatus(undefined)
      }
    }, 1000)
  }

  useEffect(() => {
    if (trigger) {
      const mergeFieldsWraps = trigger.querySelectorAll('.mergeField-wrap')
      mergeFieldsWraps.forEach((span) => {
        if (span instanceof HTMLSpanElement) {
          span.onclick = () => {
            if (span.nextSibling) {
              if (!span.nextSibling.textContent?.trim()) {
                placeCaretAtEnd(span.nextSibling)
              } else {
                placeCaretAtStart(span.nextSibling)
              }
            } else {
              placeCaretAtEnd(trigger)
            }
          }
        }
      })
      const mergeFields = trigger.querySelectorAll('.mergeField-content')
      mergeFields.forEach((span) => {
        if (span instanceof HTMLSpanElement) {
          span.addEventListener('mouseenter', onHoverMergeField(span))
          span.addEventListener('mouseleave', onLeaveHoverMergeField)
        }
      })

      const integrationStatuses = trigger.querySelectorAll('.mergeField-integration-status')
      integrationStatuses.forEach((span) => {
        if (span instanceof HTMLSpanElement) {
          span.addEventListener('mouseenter', onHoverIntegrationStatus(span))
          span.addEventListener('mouseleave', onLeaveIntegrationStatus)
        }
      })
    }
  }, updateTriggerDeps)

  const onHideMergeFieldsTooltips = () => {
    setCurrentMergeField(undefined)
    setCurrentIntegrationStatus(undefined)
  }

  return {
    onHideMergeFieldsTooltips,
    mergeFieldTooltipComponent: (
      <>
        <MergeFieldTooltip
          trigger={currentMergeField}
          refVisibleTooltip={refVisibleTooltip}
          onLeaveTooltip={onLeaveHoverMergeField}
          withEdit={withEdit}
          setUpdateTriggerContent={setUpdateTriggerContent}
          parent={trigger}
        />
        <MergeFieldConnectedTooltip
          trigger={currentIntegrationStatus}
          refVisibleTooltip={refVisibleIntegrationStatusTooltip}
          onLeaveTooltip={onLeaveIntegrationStatus}
          parent={trigger}
        />
      </>
    ),
  }
}
