import { ReactNode } from 'react'
import { EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { Breadcrumbs } from 'shared/ui/Breadcrumbus/Breadcrumbs'
import styles from './styles.module.scss'

type IAdminDetailPageProps = {
  loading: boolean
  header: ReactNode
  headerActions?: ReactNode
  info?: ReactNode
  content: ReactNode
  isEmpty?: boolean
  skippedCountLastRoutesForLabel?: number
  customPath?: string
}

export const AdminDetailPage = ({
  loading,
  content,
  isEmpty,
  header,
  info,
  headerActions,
  skippedCountLastRoutesForLabel,
  customPath,
}: IAdminDetailPageProps) => {
  if (loading) {
    return (
      <div className={styles.wrap}>
        <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
      </div>
    )
  }
  if (isEmpty) {
    return null
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.wrapContent}>
        <Breadcrumbs
          customPath={customPath}
          skippedCountLastRoutesForLabel={skippedCountLastRoutesForLabel}
        />
        {header}
        {headerActions && <div className={styles.wrapHeaderActions}>{headerActions}</div>}
        {info && <div className={styles.wrapInfo}>{info}</div>}
        {content}
      </div>
    </div>
  )
}
