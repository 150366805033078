import React from 'react'
import { FullPageWrapper } from 'shared/ui'
import { BroadcastList, BroadcastsHeader } from 'widgets/BroacastList'

export const RecurringBroadcasts = () => (
  <FullPageWrapper>
    <BroadcastsHeader title={'Recurring'} />
    <BroadcastList storeProps={{ type: 'recurring' }} />
  </FullPageWrapper>
)
