import { createContext, useContext } from 'react'
import { CallHistoryStore } from 'widgets/CallHistory'

export const CallHistoryContext = createContext<CallHistoryStore | null>(null)

export function useCallHistoryContext() {
  const context = useContext(CallHistoryContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with CallHistoryProvider')
  }
  return context
}
