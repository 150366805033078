import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Table } from 'shared/ui'
import {
  columns,
  pinnedColumnNames,
} from 'pages/admin/pages/customPrices/pages/CustomPrices/ui/CustomPricesTable/columns'
import { adminCustomPricesTableStore } from 'pages/admin/pages/customPrices/pages/CustomPrices/store/adminCustomPricesTableStore'

export const CustomPricesTable = observer(() => {
  const {
    loadData,
    loading,
    visibleColumnsIds,
    setVisibleColumnsIds,
    rows,
    navigateToEdit,
    page,
    length,
    total,
    onPaginationModelChange,
  } = adminCustomPricesTableStore

  useEffect(() => {
    loadData()

    if (!visibleColumnsIds.length) {
      setVisibleColumnsIds(columns.map((column) => column.field))
    }
  }, [])

  return (
    <Table
      columns={columns}
      rows={rows}
      onRowClick={(row) => navigateToEdit(row.id)}
      pinnedColumnNames={pinnedColumnNames}
      isLoading={loading}
      visibleColumnsIds={visibleColumnsIds}
      setVisibleColumnsIds={setVisibleColumnsIds}
      initPage={page}
      onChangePagination={onPaginationModelChange}
      limit={length}
      rowsCount={total}
      startPaginationSize={10}
    />
  )
})
