import { makeAutoObservable, runInAction } from 'mobx'
import { showToast } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { Tag } from 'entities/Tags/model/Tag'
import { IResponseFilterSegment } from 'entities/Segment'
import { TagsControl } from 'entities/Tags'
import { ContactsTagsModalContent, ContactsTagsModalActions } from 'widgets/ContactsTagsModal'

export type ITagsModalMode = 'add' | 'remove'

export class ContactsTagsModalStore {
  saveCallback: (() => void) | null = null
  loading = false
  contactIdsList: number[] = []
  conversation_filter: string | null = null
  teamId: string | number | null = null
  mode: ITagsModalMode = 'add'
  bulkAll = false
  filtersList: IResponseFilterSegment[] | null = null
  searchParams: string | null = null

  control = new TagsControl()

  private _contactsManageTagsModalId = 'contactsManageTagsModal'
  private _contactsTagsTabChangeModalId = 'contactsTagsTabChangeModal'

  constructor() {
    makeAutoObservable(this)
  }

  get selectedListIds() {
    return this.control.ids
  }

  get hasSelected() {
    return !!this.control.ids.length
  }

  onOpen({
    ids,
    isBulkAll = false,
    callback,
    onCloseCallback = () => {},
    conversation_filter,
    teamId,
    filtersList,
    searchParams,
  }: {
    ids: number[]
    isBulkAll?: boolean
    callback?: () => void
    onCloseCallback?: (() => void) | undefined
    conversation_filter?: string
    teamId?: string | number | null
    filtersList?: IResponseFilterSegment[]
    searchParams?: string
  }) {
    if (callback) this.saveCallback = callback
    this.bulkAll = isBulkAll
    this.contactIdsList = ids
    this.teamId = teamId || null
    this.filtersList = filtersList || null
    this.searchParams = searchParams || null
    this.conversation_filter = conversation_filter || null

    modalStore.addModal({
      id: this._contactsManageTagsModalId,
      title: 'Manage tags',
      width: 480,
      paddingContent: '0px 24px 16px 24px',
      ModalActions: ContactsTagsModalActions,
      ModalContent: ContactsTagsModalContent,
      onClose: () => {
        this._closeModal()
        onCloseCallback()
      },
    })
  }

  setMode = (mode: ITagsModalMode) => {
    if (!!this.control.ids.length) this._confirmModeChange(mode)
    else this.mode = mode
  }

  handleSave = async () => {
    if (this.mode === 'add') this._onAdd()
    if (this.mode === 'remove') this._onRemove()
  }

  private _closeModal = () => {
    this._reset()

    modalStore.removeModal(this._contactsManageTagsModalId)
  }

  private _reset = () => {
    this.control.reset()
    this.mode = 'add'
    this.contactIdsList = []
    this.saveCallback = null
    this.bulkAll = false
  }

  private _onAdd = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await ContactsApi.createContactsTags({
        team_id: this.teamId || undefined,
        tags: this.control.tags,
        contact_ids: this.contactIdsList,
        bulk_all: this.bulkAll,
        conversation_filter: this.conversation_filter || undefined,
        filtersList: this.filtersList || [],
        search: this.searchParams || '',
      })

      const tags = data.map((item) => new Tag(item))

      this.contactIdsList.forEach((id) => {
        contactsStore.addItemTags(id, tags)
      })

      showToast({
        title: 'Tags added',
        type: 'success',
      })

      if (this.saveCallback) this.saveCallback()
    } catch (e) {
      console.log('ERROR: add tags to contacts: ', e)
    } finally {
      runInAction(() => {
        this.loading = false
        this._closeModal()
      })
    }
  }

  private _onRemove = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await ContactsApi.deleteContactsTags({
        team_id: this.teamId || undefined,
        tags: this.selectedListIds,
        contact_ids: this.contactIdsList,
        remove_all: this.bulkAll,
        conversation_filter: this.conversation_filter || undefined,
        filtersList: this.filtersList || undefined,
        search: this.searchParams || undefined,
      })

      data.contacts.forEach((id) => {
        contactsStore.removeItemTags(Number(id), data.tags)
      })

      showToast({
        title: 'Tags deleted',
        type: 'success',
      })

      if (this.saveCallback) this.saveCallback()
    } catch (e) {
      console.log('ERROR: add tags to contacts: ', e)
    } finally {
      runInAction(() => {
        this.loading = false
        this._closeModal()
      })
    }
  }

  private _confirmModeChange = (mode: ITagsModalMode) => {
    const handleConfirm = () => {
      modalStore.removeModal(this._contactsTagsTabChangeModalId)
    }

    const handleCancel = () => {
      this.control.reset()
      this.mode = mode
      modalStore.removeModal(this._contactsTagsTabChangeModalId)
    }

    modalStore.addModal({
      id: this._contactsTagsTabChangeModalId,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      disabledOnAllClose: true,
      zIndex: 2000,
      width: 280,
      type: ModalTypeList.WARNING,
      title: 'Switching tabs will discard your selection',
      primaryAction: {
        text: 'Keep tags',
        onAction: handleConfirm,
      },
      secondaryAction: {
        text: 'Discard',
        onAction: handleCancel,
      },
      onClose: handleCancel,
    })
  }
}

export const contactsTagsModalStore = new ContactsTagsModalStore()
