import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Label } from 'shared/ui/Label'
import { InputPhone, TextField } from 'shared/ui/TextField'
import { Error, Typography } from 'shared/ui'
import type { SendTestModalStore } from 'widgets/TestSMS/ui/TestSmsWithMergeField'
import styles from './styles.module.scss'

type ISendTestModalContentProps = {
  store: SendTestModalStore
}

export const SendTestModalContent: FC<ISendTestModalContentProps> = observer(({ store }) => {
  const { testPhone, setTestPhone, errorMap, setError, fields, dataMap, updateFieldValue } = store
  return (
    <div className={styles.content}>
      <div className={styles.wrapField}>
        <Label
          label={{
            text: 'Test phone number',
          }}
          boxAlignItems={'center'}
        />
        <TextField
          className={styles.input}
          variant={'stroke'}
          size={'small'}
          value={testPhone}
          Input={InputPhone}
          onChange={setTestPhone}
          onFocus={() => {
            setError('testPhone', '')
          }}
          error={errorMap.get('testPhone')}
        />
        <Error type={'text'} error={errorMap.get('testPhone')} />
      </div>
      <Typography
        ariaLabel={'PersonalizationFields'}
        variant={'body-md-medium'}
        className={styles.label}
      >
        Personalization fields
      </Typography>
      {fields.map((field) => (
        <div className={styles.wrapField} key={field.id}>
          <Label
            label={{
              text: field.label,
            }}
            boxAlignItems={'center'}
          />
          <TextField
            className={styles.input}
            variant={'stroke'}
            size={'small'}
            value={dataMap.get(field.id)}
            InputProps={{
              placeholder: field.placeholder,
            }}
            onChange={(value) => {
              updateFieldValue(field.id, value)
            }}
            onFocus={() => {
              setError(field.id, '')
            }}
            error={errorMap.get(field.id)}
          />
          <Error type={'text'} error={errorMap.get(field.id)} />
        </div>
      ))}
    </div>
  )
})
