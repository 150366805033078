import { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader, Table } from 'shared/ui'
import { useKnowledgeBaseListContext } from 'pages/chatbot/context/knowledgeBaseListContext'
import { columns } from './columns'
import styles from './styles.module.scss'

export const KnowledgeBaseListTable = observer(() => {
  const { items, page, limit, total, loading, onPaginationChange } = useKnowledgeBaseListContext()

  if (loading) return <SpinnerLoader />

  return (
    <section className={styles.table}>
      <Table
        columns={columns}
        rows={items}
        initPage={page}
        limit={limit}
        rightHeaderContent={<Fragment />}
        rowsCount={total}
        onChangePagination={onPaginationChange}
        startPaginationSize={10}
      />
    </section>
  )
})
