import { useEffect, useRef, FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { ContactsApi } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'
import { ITagDropdownAction, TagsControl, TagsSelect } from 'entities/Tags'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'

import styles from './styles.module.scss'

type IContactsTagsProps = {
  contact: Contact
}

const ContactsTags: FC<IContactsTagsProps> = observer(({ contact }) => {
  const isDirtyRef = useRef<boolean>(false)
  const tagsControl = useMemo(() => TagsControl.FromTags(contact.tags), [contact.tags])

  const handleChange = () => {
    contact.handleUpdateTags(tagsControl.tags)
    isDirtyRef.current = true
  }

  const handleDropdownChange = (state: ITagDropdownAction) => {
    if (state === 'close' && isDirtyRef.current) {
      isDirtyRef.current = false
      ContactsApi.updateContactTags(contact.id, contact.tags)
    }
  }

  useEffect(
    () => () => {
      if (isDirtyRef.current) ContactsApi.updateContactTags(contact.id, contact.tags)
    },
    [contact]
  )

  return (
    <div className={styles.wrap}>
      <TagsSelect
        canCreate
        limit={5}
        placeholder='Add tags'
        control={tagsControl}
        onChange={handleChange}
        onDropdownChange={handleDropdownChange}
      />
    </div>
  )
})

export const ContactsTagsForm = observer(() => {
  const { selectedContact } = useContactsDetailsContext()
  if (!selectedContact) return null

  return <ContactsTags contact={selectedContact} />
})
