import { observer } from 'mobx-react-lite'
import { RecordAudioContent } from 'widgets/Record/ui/RecordActions/RecordPlayer/audio/RecordAudioContent'
import { RecordVideoContent } from 'widgets/Record/ui/RecordActions/RecordPlayer/video/RecordVideoContent'
import { RecordStore } from 'widgets/Record'

export const RecordContent = observer(({ recordStore }: { recordStore: RecordStore }) => {
  const { isAudio } = recordStore
  return (
    <>
      {isAudio ? (
        <RecordAudioContent recordStore={recordStore} />
      ) : (
        <RecordVideoContent recordStore={recordStore} />
      )}
    </>
  )
})
