// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K8sj4SQAh7YLfEIJ67MW{display:flex;align-items:center}.f7KUvNO_9Dt14zvBlL29{opacity:.5}.L7ILo3FPiqOBerxrd47w{border-radius:6px;border:1px solid var(--border-neutral-default);background:var(--background-primary-Inverted-primary);padding:4px 8px;color:var(--content-primary-primary);font-size:13px;font-weight:400;line-height:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/ItemsWithCount/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CAEJ,sBACI,UAAA,CAEJ,sBACI,iBAAA,CACA,8CAAA,CACA,qDAAA,CACA,eAAA,CACA,oCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".itemsContainer {\n    display: flex;\n    align-items: center;\n}\n.disabled {\n    opacity: 0.5;\n}\n.remainderCount {\n    border-radius: 6px;\n    border: 1px solid var(--border-neutral-default);\n    background: var(--background-primary-Inverted-primary);\n    padding: 4px 8px;\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsContainer": "K8sj4SQAh7YLfEIJ67MW",
	"disabled": "f7KUvNO_9Dt14zvBlL29",
	"remainderCount": "L7ILo3FPiqOBerxrd47w"
};
export default ___CSS_LOADER_EXPORT___;
