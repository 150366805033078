import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import { IResponseStatusService, IResponseStatusServiceList } from 'entities/Admin/status/api/type'

class Api {
  getStatusServices(): Promise<AxiosResponse<IResponseStatusServiceList>> {
    return baseApi.get('admin/core/status')
  }

  changeStatusService(id: number, status: boolean): Promise<AxiosResponse<IResponseStatusService>> {
    return baseApi.post(`admin/core/status/${id}`, {
      online: status,
    } as AxiosRequestConfig)
  }
}

export const AdminStatusApi = new Api()
