import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Icon, Typography } from 'shared/ui'
import { TriggerTooltip, ITriggerTooltipProps } from 'shared/ui/TriggerTooltip/TriggerTooltip'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import styles from './styles.module.scss'

type Props = {
  withEdit?: boolean
  setUpdateTriggerContent?: () => void
}

export const MergeFieldTooltip: FC<ITriggerTooltipProps & Props> = ({
  trigger,
  refVisibleTooltip,
  onLeaveTooltip,
  withEdit,
  setUpdateTriggerContent,
  parent,
}) => {
  const [fallback, setFallback] = useState(trigger?.dataset.fallback || '')
  const refValue = useRef('')

  useEffect(() => {
    setFallback(trigger?.dataset.fallback || '')
    if (trigger) {
      if (refValue) {
        refValue.current = trigger?.dataset.fallback || ''
      }
    }
  }, [trigger])

  const onChangeValue = (value: string) => {
    refValue.current = value
    setFallback(value)
  }

  const title = useMemo(() => {
    if (trigger) {
      return (
        <div className={styles.wrap} key={trigger?.dataset.fieldlabel}>
          <div className={styles.left}>
            <Icon icon={'fallback'} fontSize={14} tertiary />
            <Typography variant={'body-md-regular'} color={'tertiary'} ariaLabel={'Fallback'}>
              Fallback
            </Typography>
          </div>
          <div className={styles.divider} />
          <TextField
            size='small'
            variant='integrated'
            InputProps={{
              autoComplete: 'off',
              placeholder: `If we can’t find ${
                trigger?.dataset.fieldlabel || trigger?.dataset.fieldname
              }`,
            }}
            viewOnly={!withEdit}
            mainActions={[makeTextFieldAutoFocus({ withFocus: withEdit })]}
            value={fallback}
            onChange={onChangeValue}
          />
        </div>
      )
    }
    return <></>
  }, [trigger, fallback])

  const onBlurEdit = (trigger?: HTMLSpanElement | HTMLLinkElement) => {
    if (trigger) {
      setUpdateTriggerContent && setUpdateTriggerContent()
      trigger.dataset.fallback = refValue.current
      refValue.current = ''
    }
  }

  useEffect(() => {
    return () => {
      if (trigger) {
        withEdit && onBlurEdit(trigger)
      }
    }
  }, [trigger])

  return (
    <TriggerTooltip
      parent={parent}
      trigger={trigger}
      onLeaveTooltip={onLeaveTooltip}
      refVisibleTooltip={refVisibleTooltip}
      color={'white'}
      component={title}
      arrow
    />
  )
}
