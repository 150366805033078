import { observer } from 'mobx-react-lite'
import { IDropdownItem, Tabs } from 'shared/ui'
import type { IResponseEinItem } from 'entities/Compliance'
import type { Attachment } from 'entities/Attachment/model/Attachment'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { CommonErrorBanner } from 'pages/settings/pages/compliance/ui/CommonErrorBanner/CommonErrorBanner'
import { AutoBusinessFind } from './AutoBusinessFind'
import { ManuallyBusinessFind } from './ManuallyBusinessFind'
import styles from './styles.module.scss'

export type IGeneralInfoStore = {
  einItem: IResponseEinItem | null
  einSearch: string
  isNoEinResults?: boolean
  einItemList: IDropdownItem[]
  errorMessage?: string
  attachmentData: Attachment | null
  attachmentError: {
    title?: string
    desc?: string
  } | null
  zipCode?: string
  zipLocalErrorMessage: string | null
  isNextDisabled: boolean
  generalInfoActiveTab: number
  isUsaLocation: boolean
  states: IDropdownItem[]
  setEinSearch: (value: string) => void
  checkEinNumber: () => void
  setFirstEinItem: () => void
  clearAttachmentError: () => void
  deleteIrsEinAttachment: () => void
  addZipManually: (zip: string) => void
  checkZipLengthManually: () => void
  addCityManually: (city: string) => void
  addBusinessNameManually: (name: string) => void
  addBusinessAddressManually: (street: string) => void
  addBusinessAddress2Manually: (street: string) => void
  addBusinessStateManually: (state: string) => void
  uploadIrsEinAttachment: (file: File) => void
  changeGeneralInfoTab: (tabKey: number) => void
}

export type IGeneralInfoProps = {
  stepLoading: boolean
  generalInfoStore: IGeneralInfoStore
  fieldsError: Record<string, string> | null
  errorMessage?: string | null
  fieldsErrorCommonText: JSX.Element[] | null
  nextStepClick: () => void
  openDoNotHaveEINModal: () => void
}

export const CommonGeneralInfo = observer(
  ({
    stepLoading,
    generalInfoStore,
    fieldsError,
    errorMessage,
    fieldsErrorCommonText,
    nextStepClick,
    openDoNotHaveEINModal,
  }: IGeneralInfoProps) => {
    const { states, generalInfoActiveTab, changeGeneralInfoTab } = generalInfoStore

    const manuallyBusinessFind = (
      <ManuallyBusinessFind
        generalInfoStore={generalInfoStore}
        fieldsError={fieldsError}
        fieldsErrorCommonText={fieldsErrorCommonText}
        states={states}
        openDoNotHaveEINModal={openDoNotHaveEINModal}
      />
    )

    return (
      <div className={styles.wrap}>
        <div className={styles.bannerWrap}>
          <PageDescription>
            Make sure this information is an exact match to your official paperwork
          </PageDescription>
          <CommonErrorBanner errorMessage={errorMessage} />
        </div>

        {generalInfoStore.isUsaLocation && (
          <>
            <Tabs
              tabs={[
                { name: 'Find your business', key: 1 },
                { name: 'Enter manually', key: 2 },
              ]}
              activeTabKey={generalInfoActiveTab}
              handleSelectTab={(tab) => changeGeneralInfoTab(tab.key)}
              margin='0 0 28px 0'
            />
            {generalInfoActiveTab === 1 && (
              <AutoBusinessFind
                generalInfoStore={generalInfoStore}
                openDoNotHaveEINModal={openDoNotHaveEINModal}
                changeGeneralInfoTab={changeGeneralInfoTab}
              />
            )}
            {generalInfoActiveTab === 2 && manuallyBusinessFind}
          </>
        )}

        {!generalInfoStore.isUsaLocation && manuallyBusinessFind}

        <NextButton
          loading={stepLoading}
          disabled={generalInfoStore.isNextDisabled || stepLoading}
          text='Next'
          onClick={nextStepClick}
        />
      </div>
    )
  }
)
