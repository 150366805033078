import React from 'react'
import { capitalize } from 'lodash'
import { IColumn, Status } from 'shared/ui'
import { TeamMember } from 'entities/Admin/organizations'

export const columns: IColumn<TeamMember>[] = [
  {
    field: 'id',
    name: 'ID',
    maxWidth: 85,
  },
  {
    field: 'status',
    name: 'Status',
    renderRowCell: (row) => (
      <Status
        title={row.status}
        intent={row.status === 'Active' ? 'positive' : 'negative'}
        emphasis={'high'}
      />
    ),
  },
  {
    field: 'role',
    name: 'Role',
    renderRowCell: (row) => capitalize(row.role),
  },
  {
    field: 'name',
    name: 'Company name',
  },
  {
    field: 'email',
    name: 'Email',
  },
]
