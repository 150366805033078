import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { IChatbotField } from 'entities/Chatbot'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotCollapse } from 'pages/chatbot/ui/ChatbotCollapse'

import { FieldCards } from './FieldCards'
import styles from './styles.module.scss'

const FIELDS_LIMIT = 5

export const StepObjective = observer(() => {
  const {
    errors,
    fieldsControl,
    model: { fields },
  } = useChatbotContext()

  const isLimitReached = fields.list.length >= FIELDS_LIMIT

  const error = errors.getError('fields')
  const isOwnError = errors.hasOwnError('fields')
  const isNestedError = errors.hasError('fields')

  const objectiveError = isOwnError
    ? error
    : isNestedError
    ? 'Please complete all the fields'
    : null

  const handleRemove = (index: number) => {
    fields.removeField(index)

    if (isOwnError) errors.removeError('fields')
  }

  const handleUpdate = (index: number, field: IChatbotField) => {
    fields.updateField(index, field)

    if (isOwnError) errors.removeError('fields')
  }

  const handleCreate = () => {
    fields.createField()

    if (isOwnError) errors.removeError('fields')
  }

  const handleReorder = (source: number, target: number) => {
    fields.reorderFields(source, target)

    if (isOwnError) errors.removeError('fields')
  }

  useEffect(() => {
    fieldsControl.init()
  }, [])

  return (
    <ChatbotCollapse
      icon='target'
      title='Set your objective'
      tooltip='Decide which fields you want your Textbot to collect and how to talk about them'
      error={objectiveError}
    >
      <div className={styles.fieldsContainer}>
        {fields.list.length ? (
          <>
            <h4 className={styles.fieldsContainerTitle}>Fields to collect</h4>
            <FieldCards
              fields={fields.list}
              onReorder={handleReorder}
              onDelete={handleRemove}
              onUpdate={handleUpdate}
            />
          </>
        ) : (
          <div className={styles.fieldsContainerEmpty}>Please add fields</div>
        )}
        <Button
          icon='add'
          text={'Add field'}
          contained={'secondary'}
          size={'medium'}
          onClick={handleCreate}
          disabled={isLimitReached}
          tooltipProps={{
            label: isLimitReached ? 'You reached max number of fields to collect' : null,
            placement: 'top',
          }}
        />
      </div>
    </ChatbotCollapse>
  )
})
