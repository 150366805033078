import { observer } from 'mobx-react-lite'
import { DatePicker, DropdownContent } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { useRecurringContext } from 'shared/ui/Schedule/ui/Recurring/context/recurringContext'
import styles from './styles.module.scss'

type IOnTheContentProps = {
  onCloseMenu: () => void
}

export const OnDateContent = observer(({ onCloseMenu }: IOnTheContentProps) => {
  const { setEndDate, endDate, date } = useRecurringContext()
  const handleDate = (date: Date | null) => {
    onCloseMenu()
    setEndDate(date)
  }
  return (
    <DropdownContent>
      <DatePicker
        type={'singleInline'}
        calendarClassName={styles.wrap}
        date={endDate}
        handleDate={handleDate}
        excludeDateIntervals={[
          { start: new Date(0), end: uiStore.dayjs(date).add(-1, 'day').toDate() },
        ]}
      />
    </DropdownContent>
  )
})
