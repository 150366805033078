import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type SegmentModalStore } from 'widgets/ContactsMenu/ui/SegmentModal'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import { ModalActions } from './ModalActions'

type IEditSegmentActionsProps = {
  segmentModalStore: SegmentModalStore
}

export const EditSegmentActions: FC<IEditSegmentActionsProps> = observer(
  ({ segmentModalStore }) => {
    return (
      <ModalActions
        successText='Save'
        successAction={() => {
          segmentModalStore.editItem({
            afterEdit: () => contactsFilterStore.segmentBox.setHasChanges(false),
          })
          contactsFilterStore.onOpen(false)
        }}
        segmentModalStore={segmentModalStore}
      />
    )
  }
)
