import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { AttachmentLoader } from 'shared/ui/Loader'
import vCardStore from 'entities/VCard/store/vCardStore'
import { VCardCardUi } from 'entities/VCard/ui/VCard/VCardCardUi'
import { VCard } from 'entities/VCard/model/VCard'
import styles from './styles.module.scss'

type IVCardCardProps = {
  vCard: VCard
  loading?: boolean
  isGray?: boolean
}
export const VCardCard: FC<IVCardCardProps> = observer(({ vCard, loading, isGray }) => {
  const onCardClick = () => {
    vCardStore.openVCard(vCard)
  }

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.gray]: isGray,
      })}
      onClick={onCardClick}
      aria-hidden={true}
    >
      <VCardCardUi vCard={vCard} maxWidthInfo={156} isGray={isGray} />
      {loading && <AttachmentLoader />}
    </div>
  )
})
