// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tLShBHq8kkZ43oT44GIq{display:flex;align-items:center;justify-content:center;gap:8px}.pSx2s9STqBLPXdqIGFjK{display:inline-block;width:1px;height:16px;background:var(--border-neutral-primary)}.T5DaPD27Y1I78QrdRUni{padding:8px;min-height:32px;min-width:18px;margin:-12px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsProfileCard/ContactCallButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CAGF,sBACE,oBAAA,CACA,SAAA,CACA,WAAA,CACA,wCAAA,CAGF,sBACE,WAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n}\n\n.divider {\n  display: inline-block;\n  width: 1px;\n  height: 16px;\n  background: var(--border-neutral-primary);\n}\n\n.icon {\n  padding: 8px;\n  min-height: 32px;\n  min-width: 18px;\n  margin: -12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "tLShBHq8kkZ43oT44GIq",
	"divider": "pSx2s9STqBLPXdqIGFjK",
	"icon": "T5DaPD27Y1I78QrdRUni"
};
export default ___CSS_LOADER_EXPORT___;
