// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wh7LswdAGTFx2q8x_kux{display:flex;align-items:center;justify-content:space-between;margin-bottom:12px}.vGgFJynGtU7PV79O8ldF{color:var(--content-primary-primary)}.NiR2X_iQFcJ0GkjnhwUS{color:var(--content-primary-primary)}.mfTlJs7KsOMdEazbqWyw{margin-bottom:31px;color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/BroadcastBadNumbers/ui/BroadcastBadNumbersModal/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,sBACE,oCAAA,CAGF,sBACE,oCAAA,CAIJ,sBACE,kBAAA,CACA,qCAAA","sourcesContent":[".wrap {\n}\n\n.title {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n\n  &__text {\n    color: var(--content-primary-primary);\n  }\n\n  &__small {\n    color: var(--content-primary-primary);\n  }\n}\n\n.desc {\n  margin-bottom: 31px;\n  color: var(--content-primary-tertiary);\n}\n\n.options {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "wh7LswdAGTFx2q8x_kux",
	"title__text": "vGgFJynGtU7PV79O8ldF",
	"title__small": "NiR2X_iQFcJ0GkjnhwUS",
	"desc": "mfTlJs7KsOMdEazbqWyw"
};
export default ___CSS_LOADER_EXPORT___;
