import React from 'react'
import { Icon, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import { ScheduleConfig } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/ui/BodyContent/ScheduleContent/ScheduleConfig'
import styles from '../styles.module.scss'

export const ScheduleContent = () => {
  const broadcast = useBroadcastDetailModalContext()

  return (
    <div className={styles.wrap}>
      <Typography
        ariaLabel={'Send'}
        variant={'body-rg-medium'}
        className={styles.title}
        tag={'div'}
      >
        Schedule
      </Typography>
      <div className={styles.grid}>
        <Typography ariaLabel={'Send'} variant={'text-input-field'} className={styles.label}>
          Send time
        </Typography>
        {broadcast.status === 'scheduled' ? (
          <ScheduleConfig />
        ) : (
          <div className={styles.value}>
            <div className={styles.row}>
              <Icon icon={'schedule'} tertiary />
              <Typography ariaLabel={'Send'} variant={'text-input-field'}>
                {(broadcast.send_at || broadcast.sent_at) &&
                  uiStore
                    .dayjs(broadcast.send_at || broadcast.sent_at)
                    .format(DayjsFormats.fullWithAtDash2)}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
