import React from 'react'
import { Icon, IIconsVector, Typography } from 'shared/ui'
import { Attachment } from 'entities/Attachment/model/Attachment'
import styles from './styles.module.scss'

export type PropsConversationItemFiles = {
  type: Attachment['type']
  count: number
  label?: string
}

const ConversationCardFiles: React.FC<PropsConversationItemFiles> = ({
  label,
  type = 'image',
  count,
}) => {
  let icon: IIconsVector = 'imageLine'
  let name = 'Image'

  if (type === 'image') {
    icon = 'imageLine'
    name = 'Image'
  }
  if (type === 'gif' || type === 'previewGif') {
    icon = 'gif'
    name = 'GIF'
  }
  if (type === 'vcard') {
    icon = 'vCard'
    name = 'vCard'
  }
  if (type === 'attachment') {
    icon = 'attachment'
    name = 'Attachment'
  }
  if (type === 'video') {
    icon = 'video'
    name = 'Video'
  }
  if (type === 'audio' || type === 'voice') {
    icon = 'audio'
    name = 'Audio'
  }

  if (count > 1) {
    icon = 'attachment'
    name = 'Attachments'
  }

  return (
    <div className={styles.wrap}>
      {!!label && (
        <Typography
          className={styles.body}
          variant={'body-md-regular'}
          ellipsis
          ariaLabel={'label'}
        >
          <span className={styles.body__label}>{label}</span>
        </Typography>
      )}
      <div className={styles.icon}>
        <Icon icon={icon} />
      </div>
      <div className={styles.label}>{name}</div>
    </div>
  )
}

export { ConversationCardFiles }
