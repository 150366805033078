// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yvK1z0TAZpRlrtTuZbyW{display:flex;flex:1;overflow:auto}.DnZgSdEUa6uLbij7K6Yd{width:100%;height:100%;background-color:var(--background-primary-inverted-primary)}", "",{"version":3,"sources":["webpack://./src/shared/layout/Layout/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,MAAA,CACA,aAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,2DAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex: 1;\n  overflow: auto;\n}\n\n.content {\n  width: 100%;\n  height: 100%;\n  background-color: var(--background-primary-inverted-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "yvK1z0TAZpRlrtTuZbyW",
	"content": "DnZgSdEUa6uLbij7K6Yd"
};
export default ___CSS_LOADER_EXPORT___;
