import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { useBroadcastRinglessContext } from 'widgets/BroadcastView/ui/BroadcastRingless'

export const RinglessRecordAudio = observer(() => {
  const { handleRecordAudio } = useBroadcastRinglessContext()

  return (
    <>
      <IconButton
        ariaLabel={'Record audio'}
        tooltipProps={{
          label: 'Record audio',
          placement: 'top',
        }}
        icon={'microphone'}
        size={'medium'}
        color={'secondary'}
        variant={'icon'}
        onClick={handleRecordAudio}
      />
    </>
  )
})
