import { observer } from 'mobx-react-lite'
import React from 'react'
import { Error, TextInput } from 'shared/ui'
import { IInputNumericProps, InputNumeric, TextField } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { NewConversionStore } from 'widgets/NewConversion'
import styles from './styles.module.scss'

type INewConversationContentProps = {
  store: NewConversionStore
}

export const NewConversationContent = observer(({ store }: INewConversationContentProps) => {
  const {
    name,
    setName,
    conversion_url,
    setUrl,
    currency,
    setCurrency,
    currencyItems,
    default_value,
    setDefaultValue,
    conversion_attribution,
    conversionTypeItems,
    setConversionType,
    errorsMap,
    setError,
  } = store
  return (
    <div className={styles.content}>
      <div className={styles.wrapName}>
        <TextInput
          size={'medium'}
          placeholder={'Conversion name'}
          value={name}
          setValue={setName}
          error={errorsMap.get('name')}
          setError={(value) => setError('name', value)}
          isAutoFocus
        />
      </div>

      <div className={styles.wrapField}>
        <Label
          label={{
            text: 'URL',
          }}
          boxAlignItems={'center'}
          questionTooltipProps={{
            label: [
              'The site where conversions will be tracked.',
              <br key={'br1'} />,
              'An asterisk * may be used as a wildcard.',
              <br key={'br2'} />,
              'Example: /thank-you?id=*',
            ],
          }}
        />
        <TextField
          className={styles.input}
          variant={'stroke'}
          size={'small'}
          value={conversion_url}
          onChange={setUrl}
          InputProps={{
            placeholder: 'Enter url',
          }}
          error={errorsMap.get('conversion_url')}
        />
        <Error type={'text'} error={errorsMap.get('conversion_url')} />
      </div>
      <SelectField
        items={currencyItems}
        setValue={setCurrency}
        value={currency}
        labelText={'Currency'}
        dropdownProps={{ withSearch: false }}
        selectProps={{
          variant: 'stroke',
        }}
      />
      <div className={styles.wrapField}>
        <Label
          label={{
            text: 'Default value',
            secondaryText: '(optional)',
          }}
          boxAlignItems={'center'}
          questionTooltipProps={{
            label: 'The value recorded for each conversion',
          }}
        />
        <TextField
          className={styles.input}
          variant={'stroke'}
          size={'small'}
          Input={InputNumeric}
          InputProps={
            {
              value: default_value as unknown as string,
              onValueChange: (values) => {
                setDefaultValue(values?.floatValue || 0, values.formattedValue)
              },
              placeholder: '$0.00',
              allowNegative: false,
              prefix: '$',
              thousandSeparator: ',',
              maxLength: 12,
            } as IInputNumericProps
          }
        />
      </div>
      <SelectField
        items={conversionTypeItems}
        setValue={setConversionType}
        value={conversion_attribution}
        labelText={'Conversion type'}
        dropdownProps={{ withSearch: false }}
        selectProps={{
          variant: 'stroke',
        }}
        error={errorsMap.get('conversion_attribution')}
      />
    </div>
  )
})
