// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtSEQWWitrUyPNkWEE1Y{display:inline-flex;cursor:pointer}.CtSEQWWitrUyPNkWEE1Y.zAAoUDYaEicqKLzDuzUN{display:grid}.CtSEQWWitrUyPNkWEE1Y.zAAoUDYaEicqKLzDuzUN>div{display:grid}.CtSEQWWitrUyPNkWEE1Y>div{display:inline-flex}.CtSEQWWitrUyPNkWEE1Y.jMIGG3CY1u0uH9iQdsJy{width:100%}.CtSEQWWitrUyPNkWEE1Y.jMIGG3CY1u0uH9iQdsJy>div{width:100%}.CtSEQWWitrUyPNkWEE1Y.jMIGG3CY1u0uH9iQdsJy>div>div{width:100%}.LjwaBSL3YeqfInASx7mA{cursor:default}", "",{"version":3,"sources":["webpack://./src/shared/ui/Tooltip/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,cAAA,CAEA,2CACE,YAAA,CACA,+CACE,YAAA,CAIJ,0BACE,mBAAA,CAGF,2CACE,UAAA,CAEA,+CACE,UAAA,CAEA,mDACE,UAAA,CAKR,sBACE,cAAA","sourcesContent":[".wrap {\n  display: inline-flex;\n  cursor: pointer;\n\n  &.grid {\n    display: grid;\n    & > div {\n      display: grid;\n    }\n  }\n\n  & > div {\n    display: inline-flex;\n  }\n\n  &.fullWidth {\n    width: 100%;\n\n    & > div {\n      width: 100%;\n\n      & > div {\n        width: 100%;\n      }\n    }\n  }\n}\n.withoutPointer {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "CtSEQWWitrUyPNkWEE1Y",
	"grid": "zAAoUDYaEicqKLzDuzUN",
	"fullWidth": "jMIGG3CY1u0uH9iQdsJy",
	"withoutPointer": "LjwaBSL3YeqfInASx7mA"
};
export default ___CSS_LOADER_EXPORT___;
