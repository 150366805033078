// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hM07ORefeMBIiTMXZ0zG{display:flex;align-items:center;justify-content:center;width:44px;height:40px;border-radius:6px;margin-bottom:2px;font-size:18px;color:var(--content-primary-tertiary);cursor:pointer}.hM07ORefeMBIiTMXZ0zG.GcYgjsPtljSvfe4JwMxy,.hM07ORefeMBIiTMXZ0zG:hover{background:var(--background-primary-inverted-primary);box-shadow:0 4px 12px -2px rgba(18,19,23,.03);color:var(--content-brand-primary)}.hM07ORefeMBIiTMXZ0zG:last-of-type{margin-bottom:3px}", "",{"version":3,"sources":["webpack://./src/shared/ui/NavLinkVue/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,qCAAA,CACA,cAAA,CAEA,uEAEE,qDAAA,CACA,6CAAA,CACA,kCAAA,CAGF,mCACE,iBAAA","sourcesContent":[".button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 44px;\n  height: 40px;\n  border-radius: 6px;\n  margin-bottom: 2px;\n  font-size: 18px;\n  color: var(--content-primary-tertiary);\n  cursor: pointer;\n\n  &.active,\n  &:hover {\n    background: var(--background-primary-inverted-primary);\n    box-shadow: 0 4px 12px -2px rgba(18, 19, 23, 0.03);\n    color: var(--content-brand-primary);\n  }\n\n  &:last-of-type {\n    margin-bottom: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "hM07ORefeMBIiTMXZ0zG",
	"active": "GcYgjsPtljSvfe4JwMxy"
};
export default ___CSS_LOADER_EXPORT___;
