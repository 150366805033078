import { observer } from 'mobx-react-lite'
import { CardsFilter } from 'shared/ui'
import { ChatbotConversationStatus } from 'entities/Chatbot'
import { ChatbotAnalyticListStore, ChatbotAnalyticDetailStore } from 'pages/chatbot'

import styles from './styles.module.scss'

export interface IChatbotAnalyticFilterProps {
  details: ChatbotAnalyticDetailStore
  list: ChatbotAnalyticListStore
}

export const ChatbotAnalyticFilter = observer(({ details, list }: IChatbotAnalyticFilterProps) => {
  return (
    <section className={styles.filter}>
      <CardsFilter
        onActiveFilter={(active) => list.changeStatus(active.key)}
        items={[
          {
            key: null,
            title: 'Started',
            amount: details.totalCount,
            active: list.status === null,
            tooltipProps: {
              label: 'Contact enrolled to chat with the AI Textbot',
              placement: 'top',
            },
          },
          {
            key: ChatbotConversationStatus.InProgress,
            title: 'In Progress',
            amount: details.inProgressCount,
            percentage: details.inProgressPercent,
            active: list.status === ChatbotConversationStatus.InProgress,
            tooltipProps: {
              label: 'Active conversation with the AI Textbot',
              placement: 'top',
            },
          },
          {
            key: ChatbotConversationStatus.Abandoned,
            title: 'Abandoned',
            amount: details.fallbackCount,
            percentage: details.fallbackPercent,
            active: list.status === ChatbotConversationStatus.Abandoned,
            tooltipProps: {
              label: 'Conversation ended before reaching the goal',
              placement: 'top',
            },
          },
          {
            key: ChatbotConversationStatus.Completed,
            title: 'Completed',
            amount: details.completedCount,
            percentage: details.completedPercent,
            active: list.status === ChatbotConversationStatus.Completed,
            tooltipProps: {
              label: 'Conversation achieved the desired outcome',
              placement: 'top',
            },
          },
          {
            key: ChatbotConversationStatus.Terminated,
            title: 'Stopped by user',
            amount: details.terminatedCount,
            percentage: details.terminatedPercent,
            active: list.status === ChatbotConversationStatus.Terminated,
            tooltipProps: {
              label: 'Sessions stopped by user',
              placement: 'top',
            },
          },
        ]}
      />
    </section>
  )
})
