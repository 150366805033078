import { useState } from 'react'
import { BroadcastsListStore } from 'widgets/BroacastList/store'
import { BroadcastListContent } from 'widgets/BroacastList/ui/BroadcastListContent'
import { type IBroadcastsListStoreProps } from 'widgets/BroacastList/store/broadcastsListStore'
import { BroadcastsListContext } from '../context/context'

type IBroadcastListProps = {
  storeProps: IBroadcastsListStoreProps
}

export const BroadcastList = ({ storeProps }: IBroadcastListProps) => {
  const [store] = useState(() => new BroadcastsListStore(storeProps))

  return (
    <BroadcastsListContext.Provider value={store}>
      <BroadcastListContent />
    </BroadcastsListContext.Provider>
  )
}
