import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { apiOptionsToDropdownItems, apiOptionToDropdownItem } from 'shared/lib'
import { IDropdownItem } from 'shared/ui'
import { AdminComplianceApi, IResponseAdminTenDLCById } from 'entities/Admin/compliance'
import { ITenDLCStatus } from 'entities/Compliance'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { IOptInMethod } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/store/type'

class AdminTenDLCCampaignStore {
  constructor() {
    makeAutoObservable(this)
  }

  organization_id: number | null = null
  id: number | null = null

  isEdit = false
  isSaveLoading = false
  status: ITenDLCStatus | null = null
  rejectReason = ''

  errorsObj: { [key: string]: string[] } = {}

  get isRejected() {
    return Boolean(
      this.status &&
        ['failed', 'rejected_by_twilio', 'rejected_by_tcr', 'rejected_by_admin'].includes(
          this.status
        )
    )
  }
  get isForReview() {
    return this.isRejected || this.status === 'internal_review'
  }

  get isForEdit() {
    return this.isForReview || !this.status
  }

  vendor_id: string | null = ''
  edit_vendor_id: typeof this.vendor_id = null
  get is_edit_vendor_id() {
    return this.vendor_id !== this.edit_vendor_id
  }
  set_edit_vendor_id = (value: typeof this.vendor_id) => {
    this.edit_vendor_id = value
  }

  use_case: IDropdownItem | null = null
  edit_use_case: typeof this.use_case = null
  get is_edit_use_case() {
    return this.use_case?.id !== this.edit_use_case?.id
  }
  set_edit_use_case = (value: typeof this.edit_use_case) => {
    this.edit_use_case = value
  }

  use_case_description: string | null = ''
  edit_use_case_description: typeof this.use_case_description = null
  get is_edit_use_case_description() {
    return this.use_case_description !== this.edit_use_case_description
  }
  set_edit_use_case_description = (value: typeof this.edit_use_case_description) => {
    this.edit_use_case_description = value
  }

  sample_message_1: string | null = ''
  edit_sample_message_1: typeof this.sample_message_1 = null
  get is_edit_sample_message_1() {
    return this.sample_message_1 !== this.edit_sample_message_1
  }
  set_edit_sample_message_1 = (value: typeof this.edit_sample_message_1) => {
    this.edit_sample_message_1 = value
  }

  sample_message_2: string | null = ''
  edit_sample_message_2: typeof this.sample_message_2 = null
  get is_edit_sample_message_2() {
    return this.sample_message_2 !== this.edit_sample_message_2
  }
  set_edit_sample_message_2 = (value: typeof this.edit_sample_message_2) => {
    this.edit_sample_message_2 = value
  }

  opt_int_types: IDropdownItem[] | null = null
  get edit_opt_int_types(): IDropdownItem[] {
    return this.edit_opt_in_methods.map((method) => ({
      id: method.opt_in_method_id,
      label: method.title,
    }))
  }
  get is_edit_opt_int_types() {
    return (
      this.opt_int_types?.map((item) => item.id).toString() !==
      this.edit_opt_int_types?.map((item) => item.id).toString()
    )
  }
  set_edit_edit_opt_int_types = (value: typeof this.edit_opt_int_types) => {
    const oldIds: number[] = Array.from(this.edit_opt_in_methods_map.keys())
    const newIds: number[] = value.map((item) => Number(item.id))
    const removedMethodsIds = oldIds.filter((id) => !newIds.includes(id))
    removedMethodsIds.forEach((id) => {
      this.edit_opt_in_methods_map.delete(id)
    })
    value.forEach((item) => {
      const oldMethod = this.edit_opt_in_methods_map.get(Number(item.id))
      if (!oldMethod) {
        this.edit_opt_in_methods_map.set(Number(item.id), {
          opt_in_method_id: Number(item.id),
          title: item.label,
          attachments: [],
          description: '',
        })
      }
    })
  }

  opt_in_data: IResponseAdminTenDLCById['campaign']['opt_in_data'] = []
  opt_in_methods_map: Map<number, IOptInMethod> = new Map()
  edit_opt_in_methods_map: Map<number, IOptInMethod> = new Map()

  get opt_in_methods() {
    return Array.from(this.opt_in_methods_map.values())
  }

  get edit_opt_in_methods() {
    return Array.from(this.edit_opt_in_methods_map.values())
  }
  get is_edit_opt_in_methods() {
    const changesIds: number[] = []
    this.edit_opt_in_methods.forEach((method) => {
      const oldMethod = this.opt_in_methods_map.get(method.opt_in_method_id)
      if (
        method.attachments.map((attachment) => attachment.id).toString() !==
        oldMethod?.attachments.map((attachment) => attachment.id).toString()
      ) {
        changesIds.push(method.opt_in_method_id)
      }
    })
    return changesIds
  }
  get is_edit_opt_in_methods_descriptions() {
    const changesIds: number[] = []
    this.edit_opt_in_methods.forEach((method) => {
      const oldMethod = this.opt_in_methods_map.get(method.opt_in_method_id)
      if (method.description !== oldMethod?.description) {
        changesIds.push(method.opt_in_method_id)
      }
    })
    return changesIds
  }

  remove_opt_in_attachment = (optInId: number, attachmentId: string | number) => {
    const old_edit_method = this.edit_opt_in_methods_map.get(optInId)
    if (old_edit_method) {
      this.edit_opt_in_methods_map.set(old_edit_method.opt_in_method_id, {
        ...old_edit_method,
        attachments: [
          ...old_edit_method.attachments.filter((attachment) => attachment.id !== attachmentId),
        ],
      })
    }
  }

  add_opt_in_file = (optInId: number, file: File) => {
    const old_edit_method = this.edit_opt_in_methods_map.get(optInId)
    if (old_edit_method) {
      const attachment = new Attachment({ file })
      this.edit_opt_in_methods_map.set(old_edit_method.opt_in_method_id, {
        ...old_edit_method,
        attachments: [...old_edit_method.attachments, attachment],
      })
    }
  }

  add_opt_in_description = (optInId: number, description: string) => {
    const old_edit_method = this.edit_opt_in_methods_map.get(optInId)
    if (old_edit_method) {
      this.edit_opt_in_methods_map.set(old_edit_method.opt_in_method_id, {
        ...old_edit_method,
        description,
      })
    }
  }

  get hasChanges() {
    if (!this.isEdit) {
      return false
    }
    const changes = [
      this.is_edit_vendor_id,
      this.is_edit_use_case,
      this.is_edit_use_case_description,
      this.is_edit_sample_message_1,
      this.is_edit_sample_message_2,
      this.is_edit_opt_int_types,
      !!this.is_edit_opt_in_methods.length,
      this.is_edit_opt_in_methods_descriptions,
    ]
    return changes.some((item) => item)
  }

  reset = () => {
    this.organization_id = null
    this.rejectReason = ''
    this.errorsObj = {}
    this.id = null
    this.status = null
    this.vendor_id = null
    this.use_case = null
    this.use_case_description = null
    this.sample_message_1 = null
    this.sample_message_2 = null
    this.opt_int_types = null
    this.opt_in_data = []
    this.opt_in_methods_map.clear()
    this.isEdit = false
  }

  setCampaign(organization_id: number, campaign?: IResponseAdminTenDLCById['campaign']) {
    this.organization_id = organization_id
    if (campaign?.id) {
      this.rejectReason = campaign.rejection_reasons
        ?.map((reason) => reason.error_message)
        .join(', ')
      this.id = campaign.id
      this.status = campaign.status
      this.vendor_id = campaign.vendor_id
      this.use_case = apiOptionToDropdownItem(campaign.use_case)
      this.use_case_description = campaign.description
      this.sample_message_1 = campaign.sample_message_1
      this.sample_message_2 = campaign.sample_message_2
      this.opt_int_types = apiOptionsToDropdownItems(campaign.opt_int_types)
      this.opt_in_data = campaign.opt_in_data
      this.opt_in_methods_map = new Map(
        campaign.opt_in_data.map((data) => [
          data.opt_in_method_id,
          {
            opt_in_method_id: data.opt_in_method_id,
            title: data.opt_in_method.title,
            description: data.description,
            attachments: data.opt_in_files.map(
              (file) =>
                new Attachment({
                  imageUrl: file.source_url,
                  id: file.id,
                })
            ),
          },
        ])
      )
    }
  }

  onEdit = () => {
    this.edit_vendor_id = this.vendor_id
    this.edit_use_case = this.use_case
    this.edit_use_case_description = this.use_case_description
    this.edit_sample_message_1 = this.sample_message_1
    this.edit_sample_message_2 = this.sample_message_2
    this.edit_opt_in_methods_map = new Map(JSON.parse(JSON.stringify(this.opt_in_methods_map)))

    this.isEdit = true
  }

  onClose = () => {
    this.isEdit = false
  }

  onSave = async () => {
    try {
      runInAction(() => {
        this.isSaveLoading = true
      })
      const formData = new FormData()
      formData.append('organization_id', String(this.organization_id))
      formData.append('id', String(this.id || ''))
      formData.append('vendor_id', String(this.edit_vendor_id || ''))
      formData.append('status', String(this.status || ''))
      formData.append('use_case_id', String(this.edit_use_case?.id || ''))
      formData.append('description', String(this.edit_use_case_description || ''))
      formData.append('sample_message_1', String(this.edit_sample_message_1 || ''))
      formData.append('sample_message_2', String(this.edit_sample_message_2 || ''))
      this.edit_opt_in_methods.forEach((method, index) => {
        const opt_in_old_files_ids: number[] = []
        const newFiles: File[] = []
        method.attachments.forEach((attachment) => {
          if (attachment.file) {
            newFiles.push(attachment.file)
          } else {
            opt_in_old_files_ids.push(Number(attachment.id))
          }
        })
        formData.append(`opt_in_data[${index}][opt_in_method_id]`, String(method.opt_in_method_id))
        formData.append(`opt_in_data[${index}][relatable_service_type]`, String(1))
        formData.append(`opt_in_data[${index}][opt_in_old_files_ids]`, String(opt_in_old_files_ids))
        formData.append(`opt_in_data[${index}][description]`, String(method.description || ''))

        newFiles.forEach((file, indexFile) => {
          formData.append(`opt_in_data[${index}][opt_in_files][${indexFile}][file]`, file)
        })
      })

      return await AdminComplianceApi.updateTenDLCCampaign(formData)
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data) {
            this.errorsObj = e.response.data
          }
        }
      })
      throw e
    } finally {
      runInAction(() => {
        this.isSaveLoading = false
      })
    }
  }

  getError = (key: string) => {
    return this.errorsObj && this.errorsObj[key] && this.errorsObj[key][0]
  }
}

export const adminTenDLCCampaignStore = new AdminTenDLCCampaignStore()
