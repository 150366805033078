import React from 'react'
import classnames from 'classnames'
import { IconButton } from 'shared/ui/IconButton/ui/IconButton'
import type { ITooltipProps } from 'shared/ui/Tooltip'

import styles from '../styles.module.scss'

type IIconCallButtonProps = {
  showTooltip?: boolean
  show?: boolean
  onClick?: () => void
  onClose?: () => void
  onOpen?: () => void
}

export const IconCallButton: React.FC<IIconCallButtonProps> = ({
  onClick,
  showTooltip = false,
  show = true,
  onOpen,
  onClose,
}) => {
  const handleClose = () => {
    onClose && onClose()
  }

  const handleOpen = () => {
    onOpen && onOpen()
  }

  const tooltipProps: ITooltipProps = {
    label: 'Call',
    placement: 'top',
    disableInteractive: true,
    onClose: handleClose,
    onOpen: handleOpen,
  }

  const onCall = async () => {
    onClick && onClick()
  }

  return (
    <div
      className={classnames(styles.rightIcon, {
        [styles.hidden]: !show,
      })}
    >
      <IconButton
        icon={'phone'}
        variant={'icon'}
        color={'secondary'}
        size={'small'}
        fontSize={16}
        onClick={onCall}
        tooltipProps={showTooltip ? tooltipProps : undefined}
        ariaLabel='IconCallButton'
      />
    </div>
  )
}
