import { useState, FC } from 'react'
import { Icon } from 'shared/ui/Icon'
import { ITooltipItem } from 'shared/ui/Tooltip/ui/TooltipBilling/TooltipBilling'
import styles from './styles.module.scss'

export type ITooltipFilteredItemsProps = {
  items?: ITooltipItem[]
}

const TooltipFilteredItem: FC<ITooltipItem> = (props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div className={styles.item}>
      <div className={styles.itemWrap} onClick={handleOpen}>
        <div className={styles.item__icon}>
          <Icon icon={open ? 'chevronUp' : 'chevronDown'} />
        </div>
        <div className={styles.item__title}>{props.title}:</div>
        <div className={styles.item__total}>{props.total}</div>
      </div>
      {open && <div className={styles.dropdown}>{props.dropdown}</div>}
    </div>
  )
}

export const TooltipFilteredItems: FC<ITooltipFilteredItemsProps> = ({ items = [] }) => {
  return (
    <div className={styles.wrap}>
      {items.map((item, index) => {
        return <TooltipFilteredItem key={index} {...item} />
      })}
    </div>
  )
}
