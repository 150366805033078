import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import { Button, CountryEnum, Icon, ICountry, InputCheckbox, Status, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { RegisterStore } from 'widgets/Register'
import { CountriesModalContent } from './CountriesModalContent'
import { CountriesModalActions } from './CountriesModalActions'
import { InfoModalAlert } from './InfoModalAlert'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterStore
}

export const Step3Form = observer(({ store }: IFormProps) => {
  const {
    step3Store: {
      countries,
      onToggle,
      isSelected,
      handleNext,
      hasSelectedCountries,
      hasSelectedOtherCountry,
      onSelectOtherCountry,
    },
    handleNextStep,
  } = store

  const showCountriesModal = () => {
    const modalId = nanoid()
    modalStore.addModal({
      id: modalId,
      type: ModalTypeList.DEFAULT,
      title: 'Coming soon',
      ModalContent: () => <CountriesModalContent store={store.step3Store} />,
      ModalActions: () => <CountriesModalActions store={store} modalId={modalId} />,
      width: 360,
    })
  }

  const showInfoModal = () => {
    const modalId = nanoid()
    modalStore.addModal({
      id: modalId,
      type: ModalTypeList.DEFAULT,
      title: 'Coming soon',
      ModalContent: () => <InfoModalAlert store={store.step3Store} />,
      showCloseButton: false,
      width: 360,
      primaryAction: {
        text: 'Got it',
        fullWidth: true,
        onAction: () => modalStore.removeModal(modalId),
      },
    })
  }

  const handleToggle = (country: ICountry) => {
    if (country.value === CountryEnum.World) {
      if (hasSelectedOtherCountry) {
        onSelectOtherCountry('')
        onToggle(country)
        return
      }

      showCountriesModal()
      return
    }

    onToggle(country)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.list}>
        {countries.map((item) => (
          <InputCheckbox
            key={item.value}
            checked={isSelected(item.value)}
            className={styles.wrapInput}
            customLabel={
              <div className={styles.wrapLabel}>
                <div className={styles.label}>
                  <Icon icon={item.icon} fontSize={20} color={'var(--content-primary-tertiary)'} />
                  <Typography
                    variant={'body-rg-regular'}
                    ariaLabel={getAriaLabel(`country_${item.value}`)}
                  >
                    {item.name}
                  </Typography>
                </div>
                <Status {...item.status} />
              </div>
            }
            onChecked={() => handleToggle(item)}
          />
        ))}
      </div>

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={() => handleNext(handleNextStep, showInfoModal)}
        disabled={!hasSelectedCountries}
        text={'Next'}
        contained={'primary'}
        fullWidth
      />
    </div>
  )
})

export default Step3Form
