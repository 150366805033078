import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IIconsVector, Button, IconButton, ITooltipProps } from 'shared/ui'
import styles from './styles.module.scss'

export type ICallSectionActionsTitleProps = {
  icon: IIconsVector
  onChange: () => void
}

type ICallSectionProps = {
  title: string
  children?: React.ReactNode
  actions?: {
    label: string
    icon?: IIconsVector
    disabled?: boolean
    tooltipProps: ITooltipProps
    onChange: () => void
  }[]
  actionsTitle?: ICallSectionActionsTitleProps[]
}

export const CallSection: FC<ICallSectionProps> = observer(
  ({ title, children, actions = [], actionsTitle = [] }) => {
    return (
      <div className={styles.settings}>
        <div className={styles.settings__header}>
          <div className={styles.settings__titleWrap}>
            {!!actionsTitle.length && (
              <div className={styles.actionTitle}>
                {actionsTitle.map((action) => {
                  return (
                    <IconButton
                      key={action.icon}
                      iconProps={{
                        icon: action.icon,
                        fontSize: 16,
                      }}
                      variant={'icon'}
                      size={'small'}
                      color={'secondary'}
                      onClick={() => {
                        action.onChange()
                      }}
                      ariaLabel='CallSection_actionTitle'
                    />
                  )
                })}
              </div>
            )}
            <div className={styles.settings__title}>{title}</div>
          </div>

          {!!actions.length && (
            <div className={styles.actions}>
              {actions.map((action) => {
                return (
                  <Button
                    key={action.label}
                    text={action.label}
                    typeBtn={'outlined'}
                    contained={'secondary'}
                    size={'small'}
                    iconProps={{
                      icon: action.icon,
                    }}
                    tooltipProps={action.tooltipProps}
                    disabled={action.disabled}
                    onClick={() => {
                      action.onChange()
                    }}
                  />
                )
              })}
            </div>
          )}
        </div>

        <div className={styles.settings__content}>{children}</div>
      </div>
    )
  }
)
