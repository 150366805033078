import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, IconButton, Typography } from 'shared/ui'
import { CallContact } from 'entities/Call/model/CallContact'
import styles from './styles.module.scss'

type IContactCardProps = {
  item: CallContact
  onChange?: (item: CallContact) => void
  onDelete?: (item: CallContact) => void
  innerRefCurrent?: HTMLDivElement
}

export const ContactCard: FC<IContactCardProps> = observer(
  ({ item, onChange, innerRefCurrent, onDelete }) => {
    return (
      <div
        key={item.id}
        className={styles.item}
        onClick={() => {
          onChange?.(item)
        }}
      >
        <div className={styles.item__content}>
          <div className={styles.item__icon}>
            <Avatar {...item.avatarProps} />
          </div>
          <div className={styles.item__info}>
            <Typography
              className={styles.item__title}
              variant={'body-md-regular'}
              ariaLabel={'name'}
            >
              {item.name || item.formatted_number}
            </Typography>
            <Typography
              className={styles.item__desc}
              variant={'body-xs-regular'}
              ariaLabel={'timeWait'}
            >
              {item.timeWait}
            </Typography>
          </div>
        </div>

        {onDelete && (
          <div className={styles.item__actions}>
            <IconButton
              ariaLabel={'Remove'}
              icon={'phoneHangUp'}
              size={'small'}
              color={'tertiary'}
              variant={'icon'}
              tooltipProps={{
                label: 'Remove from call',
                placement: 'top',
                zIndex: 5001,
                PopperProps: {
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'preventOverflow',
                        options: {
                          boundary: innerRefCurrent,
                        },
                      },
                    ],
                  },
                },
              }}
              onClick={() => {
                onDelete(item)
              }}
            />
          </div>
        )}
      </div>
    )
  }
)
