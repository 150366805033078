import { ReactNode, FC } from 'react'
import { Typography } from 'shared/ui/Typography/Typography'
import styles from './styles.module.scss'

export type ITooltipDescriptionProps = {
  label?: ReactNode
  desc?: ReactNode
}

const TooltipDescription: FC<ITooltipDescriptionProps> = ({ label, desc = '' }) => {
  return (
    <div className={styles.wrap}>
      {label && (
        <Typography
          tag={'div'}
          variant={'body-md-regular'}
          className={styles.label}
          ariaLabel={'label'}
        >
          {label}
        </Typography>
      )}

      {desc && (
        <Typography
          tag={'div'}
          variant={'body-sm-regular'}
          className={styles.desc}
          ariaLabel={'desc'}
        >
          {desc}
        </Typography>
      )}
    </div>
  )
}

export { TooltipDescription }
