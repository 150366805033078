// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e7t3WVBLBE3M0ExgnzvD{border-radius:6px;background:var(--background-neutral-default);padding:8px}.zjIfBMcJH7am1mZTaLXq{display:flex;justify-content:space-between;align-items:center;margin-bottom:8px}.zjIfBMcJH7am1mZTaLXq .zQobPzF7Wx5lKwei1N2b{color:var(--content-primary-secondary);padding-left:4px}.NUgjdXf0_0dOLn1tSAMJ{border-radius:4px;background:var(--background-primary-inverted-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/TextToSpeech/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,4CAAA,CACA,WAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CAEA,4CACE,sCAAA,CACA,gBAAA,CAGJ,sBACE,iBAAA,CACA,qDAAA","sourcesContent":[".wrap{\n  border-radius: 6px;\n  background: var(--background-neutral-default);\n  padding: 8px;\n}\n.header{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n\n  .title{\n    color: var(--content-primary-secondary);\n    padding-left: 4px;\n  }\n}\n.content{\n  border-radius: 4px;\n  background: var(--background-primary-inverted-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "e7t3WVBLBE3M0ExgnzvD",
	"header": "zjIfBMcJH7am1mZTaLXq",
	"title": "zQobPzF7Wx5lKwei1N2b",
	"content": "NUgjdXf0_0dOLn1tSAMJ"
};
export default ___CSS_LOADER_EXPORT___;
