import { TooltipProps } from '@mui/material/Tooltip'
import { Tooltip, IconButton, ITooltipProps } from 'shared/ui'
import type { ITextFieldMainAction } from 'shared/ui/TextField'

import styles from './styles.module.scss'

type ITextFieldActionTooltip = {
  onClick: () => void
  active: boolean
  message?: string
  color: ITooltipProps['color']
  placement: TooltipProps['placement']
}

export const makeTextFieldActionTooltip: (
  action: ITextFieldActionTooltip
) => ITextFieldMainAction = (action) => {
  const useAction: ITextFieldMainAction = (control, children) => (
    <>
      <Tooltip
        color={action.color}
        open={action.active}
        label={
          <span className={styles.errorTooltip}>
            {action.message}
            <IconButton
              color='transparent'
              size='small'
              icon='close'
              variant='icon'
              onClick={action.onClick}
              ariaLabel='MakeTextFieldActionTooltip_close'
            />
          </span>
        }
        placement={action.placement}
        PopperProps={{ anchorEl: () => control.containerRef?.current as HTMLLabelElement }}
      />
      {children}
    </>
  )

  return useAction
}
