import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import { Button, Dropdown, DropdownCard, DropdownContent, Icon } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { contactsStore } from 'entities/Contacts'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { IParamsContactsOptOut } from 'entities/Contacts/api/types'
import { successOpIinHandler } from 'entities/Contacts/lib/successOpIinHandler'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'
import { ContactEnrollDropdownItem } from 'widgets/ChatbotEnroll'
import { useContactsProfileCardContext } from 'widgets/ContactsDetails/ui/ContactsProfileCard/context'
import styles from './styles.module.scss'

type IContactActionsProps = {
  contact: Contact
}

export const ContactActions: FC<IContactActionsProps> = observer(({ contact }) => {
  const modalId = nanoid()
  const store = useContactsProfileCardContext()

  const { contactsTableStore, isVariantContacts } = store

  const handleOptIn = () => {
    contactsTableStore?.resetActiveAndCloseDetailsIsMobile()
    const actualContact = contactsTableStore?.getContactById(contact.id)

    contactsOptInModalStore.handleOpen([actualContact || contact], {
      successHandler: (opt_outs: IParamsContactsOptOut['opt_outs']) => {
        successOpIinHandler(contact, opt_outs)
        if (isVariantContacts) {
          actualContact && successOpIinHandler(contact, opt_outs)
          contactsTableStore?.activeContact &&
            successOpIinHandler(contactsTableStore?.activeContact, opt_outs)
        }
      },
      isBulkAll: false,
    })
  }

  const handleUnlink = () => {
    contactsStore.blockContactById(contact.id)
    modalStore.closeModal(modalId)
  }

  const handleConversationsHistory = () => {
    const payload = {
      event_id: 'unified_history_opened',
    }

    eventLogStore.logEvent('Unified_History_Opened', payload, { groupId: organizationStore.id })
    store.openView?.('conversations-history')
  }

  const BlockModalContent = () => (
    <div className={styles.modalContent}>
      <div className={styles.iconWrapper}>
        <div className={styles.icon}>
          <Icon icon='alert' />
        </div>
      </div>
      <div className={styles.title}>Block contact?</div>
      <div className={styles.description}>
        You won’t be notified if any new messages come from this contact
      </div>
    </div>
  )

  const BlockModalActions = () => (
    <div className={styles.modalActions}>
      <Button
        onClick={handleUnlink}
        size={'medium'}
        contained={'primary'}
        text='Block contact'
        fullWidth
      />
      <Button
        onClick={() => modalStore.closeModal(modalId)}
        size={'medium'}
        contained={'tertiary'}
        text='Cancel'
        fullWidth
      />
    </div>
  )

  const handleBlock = () => {
    if (contact.is_blocked) {
      contactsStore.unblockContactById(contact.id)
    } else {
      contactsTableStore?.resetActiveAndCloseDetailsIsMobile()

      modalStore.addModal({
        id: modalId,
        showCloseButton: false,
        showCloseIcon: false,
        showHeader: false,
        width: 280,
        ModalActions: () => <BlockModalActions />,
        ModalContent: () => <BlockModalContent />,
      })
    }
  }

  const disabled = contact?.id === 0 || contact?.id === -1 || !contact

  return (
    <div className={styles.modalWrapper}>
      <Dropdown
        ariaLabel={'ContactActions'}
        disabled={disabled}
        icon={'moreHorizontal'}
        placement={'bottom-end'}
        tooltipProps={{
          label: 'Show actions',
          placement: 'top-end',
          disableInteractive: true,
        }}
        sizeTriggerButton='medium'
        customComponent={(onCloseMenu) => (
          <DropdownContent>
            <ContactEnrollDropdownItem contacts={[contact]} onClick={onCloseMenu} />
            <DropdownCard
              item={{
                id: 'conversationsHistory',
                label: 'Conversations history',
                iconL: 'clockBackward',
                ariaLabel: 'conversationsHistorySelector',
              }}
              onChange={() => {
                onCloseMenu()
                handleConversationsHistory()
              }}
            />
            <DropdownCard
              item={{
                id: 'optInStatus',
                label: 'Opt-in status',
                iconL: 'toggle',
                ariaLabel: 'optInStatusSelector',
              }}
              onChange={() => {
                onCloseMenu()
                handleOptIn()
              }}
            />
            <DropdownCard
              item={{
                id: 'block',
                label: contact.is_blocked ? 'Unblock' : 'Block',
                iconL: 'block',
                ariaLabel: 'blockSelector',
              }}
              onChange={() => {
                onCloseMenu()
                handleBlock()
              }}
            />
          </DropdownContent>
        )}
      />
    </div>
  )
})
