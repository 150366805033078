import { makeAutoObservable } from 'mobx'
import {
  DayjsFormats,
  getNumberWithOrdinal,
  IPeriod,
  numberCompactFormatter,
  periodsMap,
} from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { IDropdownItem } from 'shared/ui'
import {
  AdvancedSchedulingStore,
  endRepeatItems,
  getDefaultQuarterTime,
  IEndRepeat,
  IInitAdvancedScheduling,
  IMonthDaysSelectorStoreProps,
  intervalItems,
  IRepeatPeriod,
  IWeekDaysSelectorStoreProps,
  MonthDaysSelectorStore,
  repeatItems,
} from 'shared/ui/Schedule'

export type IInitRecurringStoreProps = {
  date: string | null
  repeatPeriod: IRepeatPeriod | null
  customInterval: string | number | null
  customPeriod: IPeriod | null
  endDate: string | null
  endRepeat: IEndRepeat | null
  endTimes: string | number | null
  isUseContactTimezone: boolean
  isDisabledRepeat: boolean
  isDisabledEnd: boolean
  advancedDate: IInitAdvancedScheduling
  monthDaysSelectorStoreProps: IMonthDaysSelectorStoreProps
}

export class RecurringStore {
  constructor() {
    makeAutoObservable(this)
  }

  currentDay = uiStore.dayjs().get('date')

  defaultDate = getDefaultQuarterTime()

  date: Date | null = this.defaultDate
  dropdownId = ''
  advancedSchedulingStore = new AdvancedSchedulingStore()

  repeatPeriodItem: IDropdownItem = repeatItems[0]
  isDisabledRepeat = false

  endDate: Date | null = this.defaultDate
  endRepeat: IEndRepeat = 'never'
  endTimes = '1'
  isDisabledEnd = false

  isUseContactTimezone = false

  customInterval = '1'
  customPeriod: IPeriod = 'daily'

  monthDaysSelectorStore = new MonthDaysSelectorStore({ days: [this.currentDay] })

  get custom_repeat_settings() {
    return {
      customPeriod: this.customPeriod,
      interval: this.customInterval,
      weekly:
        this.customPeriod === 'weekly'
          ? {
              days: this.advancedSchedulingStore.weekDaysSelectorStore.selectedDays,
            }
          : null,
      monthly: this.customPeriod === 'monthly' ? this.monthDaysSelectorStore.props : null,
    }
  }
  get type_end_settings() {
    if (this.endRepeat === 'on') {
      return {
        date: this.apiEndDate,
      }
    }
    if (this.endRepeat === 'after') {
      return {
        endTimes: Number(this.endTimes),
      }
    }
    return {}
  }

  get params() {
    return {
      send_at: this.utcDateString,
      advancedSchedule: this.advancedSchedulingStore.advancedSchedule,
      is_contact_timezone: this.isUseContactTimezone,
      repeat_settings: {
        type: this.repeatPeriod,
        custom_repeat_settings: this.repeatPeriod === 'custom' ? this.custom_repeat_settings : null,
      },
      end_settings: {
        type: this.endRepeat,
        ...this.type_end_settings,
      },
    }
  }

  init = ({
    date,
    repeatPeriod,
    customPeriod,
    customInterval,
    endDate,
    endRepeat,
    endTimes,
    isUseContactTimezone,
    advancedDate,
    isDisabledRepeat,
    isDisabledEnd,
    monthDaysSelectorStoreProps,
  }: IInitRecurringStoreProps) => {
    if (date) {
      this.setDate(uiStore.dayjs(date).add(uiStore.offsetMinute, 'minutes').toDate(), true)
    }
    if (endDate) {
      this.setEndDate(uiStore.dayjs(endDate).add(uiStore.offsetMinute, 'minutes').toDate())
    }
    const repeatPeriodItem = repeatItems.find((item) => item.id === repeatPeriod)

    if (repeatPeriodItem) {
      this.repeatPeriodItem = repeatPeriodItem
    }

    if (customInterval) {
      this.customInterval = String(customInterval)
    }
    if (customPeriod) {
      this.customPeriod = customPeriod
    }
    if (endRepeat) {
      this.endRepeat = endRepeat
    }
    if (endTimes) {
      this.endTimes = String(endTimes)
    }
    this.isUseContactTimezone = isUseContactTimezone
    this.advancedSchedulingStore.init(advancedDate)
    this.isDisabledRepeat = isDisabledRepeat
    this.isDisabledEnd = isDisabledEnd
    this.monthDaysSelectorStore.init(monthDaysSelectorStoreProps)
  }

  get uiEndDate() {
    return uiStore.dayjs(this.endDate || this.defaultDate).add(-uiStore.offsetMinute, 'minutes')
  }

  get apiEndDate() {
    return uiStore.dayjs(this.uiEndDate).format(DayjsFormats.dateSort)
  }

  get repeatPeriod(): IRepeatPeriod {
    return this.repeatPeriodItem.id as IRepeatPeriod
  }

  get customIntervalCompact() {
    return numberCompactFormatter.format(+this.customInterval)
  }

  get endTimesCompact() {
    return numberCompactFormatter.format(+this.endTimes)
  }

  get endText() {
    if (this.endRepeat === 'never') {
      return 'Never ends'
    }
    if (this.endRepeat === 'on') {
      return this.uiEndDate.format(DayjsFormats.full6)
    }
    if (this.endRepeat === 'after') {
      return `After ${this.endTimesCompact} times`
    }
    return ''
  }

  get forEndText() {
    if (this.endRepeat === 'on') {
      return `till ${uiStore.dayjs(this.endDate).format(DayjsFormats.full6)}`
    }
    if (this.endRepeat === 'after') {
      return `for ${this.endTimesCompact} times`
    }
    return ''
  }

  get selectedWeeklyDay() {
    return uiStore.dayjs(this.date).format('ddd')
  }
  get selectedMonthlyDay() {
    return getNumberWithOrdinal(this.date?.getDate() || this.currentDay)
  }

  get onTheText() {
    if (this.repeatPeriod === 'custom') {
      if (this.customPeriod === 'weekly') {
        return this.advancedSchedulingStore.weekDaysSelectorStore.text
      }
      if (this.customPeriod === 'monthly') {
        return this.monthDaysSelectorStore.text
      }
    } else {
      if (this.repeatPeriod === 'weekly') {
        return `on ${this.selectedWeeklyDay}`
      }
      if (this.repeatPeriod === 'monthly') {
        return `on the ${this.selectedMonthlyDay}`
      }
    }

    return ''
  }

  get repeatTextDropdown() {
    if (this.repeatPeriod === 'custom') {
      return `Every ${this.customIntervalCompact} ${this.customPeriodLabel} ${this.onTheText}`
    }
    return `${this.repeatPeriodItem.label} ${this.onTheText}`
  }

  get repeatText() {
    return `${this.repeatTextDropdown} ${this.forEndText}`
  }

  get repeatItems(): IDropdownItem[] {
    return repeatItems.map((item) => {
      const getLabelSecondary = () => {
        if (item.id === 'weekly') {
          return `${this.selectedWeeklyDay}`
        }
        if (item.id === 'monthly') {
          return `${this.selectedMonthlyDay}`
        }
        return ''
      }
      return {
        ...item,
        labelBrackets: getLabelSecondary(),
        activeValue: this.repeatPeriodItem.id === item.id,
      }
    })
  }
  get endRepeatItems(): IDropdownItem[] {
    return endRepeatItems.map((item) => ({
      ...item,
      activeValue: this.endRepeat === item.id,
    }))
  }

  get intervalItems(): IDropdownItem[] {
    return intervalItems.map((item) => ({
      ...item,
      label: this.customInterval === '1' ? item.label : item.label + 's',
    }))
  }

  get customPeriodLabel(): string {
    return this.getPeriodLabel(this.customPeriod, this.customInterval)
  }

  get laterDate() {
    return uiStore.dayjs(this.date || this.defaultDate).add(-uiStore.offsetMinute, 'minutes')
  }

  get utcDateString() {
    return this.laterDate.utc().format(DayjsFormats.apiFormat)
  }

  get scheduleText() {
    return this.laterDate.format(DayjsFormats.fullWithAtDash3)
  }

  getPeriodLabel = (period: IPeriod, interval: string) => {
    const label = periodsMap[period]
    return interval === '1' ? label : label + 's'
  }

  initDefault = () => {
    this.defaultDate = getDefaultQuarterTime()
  }

  toggleUseContactTimezone = () => {
    this.isUseContactTimezone = !this.isUseContactTimezone
  }

  setDropdownId = (dropdownId: string) => {
    this.dropdownId = dropdownId
    this.advancedSchedulingStore.setDropdownId(dropdownId)
  }

  setDate = (date: Date | null, isNoValidate?: boolean) => {
    this.defaultDate = getDefaultQuarterTime(date)
    this.date = date
    if (this.laterDate.isBefore(uiStore.dayjs()) && !isNoValidate) {
      this.defaultDate = getDefaultQuarterTime()
      this.date = this.defaultDate
    }
    if (this.date && this.endDate) {
      if (this.date > this.endDate || this.endRepeat === 'never') {
        this.endDate = this.date
        if (this.endRepeat === 'on') {
          this.endRepeat = 'never'
        }
      }
    }

    this.advancedSchedulingStore.setDate(this.date || this.defaultDate)
  }

  onChangeRepeatOption = (item: IDropdownItem) => {
    this.repeatPeriodItem = item
  }
  onChangeEndRepeatOption = (item: IDropdownItem) => {
    this.endRepeat = item.id as IEndRepeat
  }
  onConfirmCustom = ({
    interval,
    period,
    weekDaysSelectorStoreProps,
    monthDaysSelectorStoreProps,
  }: {
    interval: string
    period: IPeriod
    weekDaysSelectorStoreProps: IWeekDaysSelectorStoreProps
    monthDaysSelectorStoreProps: IMonthDaysSelectorStoreProps
  }) => {
    this.repeatPeriodItem = this.repeatItems[3]
    this.customInterval = interval
    this.customPeriod = period
    this.advancedSchedulingStore.weekDaysSelectorStore.init(weekDaysSelectorStoreProps)
    this.monthDaysSelectorStore.init(monthDaysSelectorStoreProps)
  }

  onConfirmAfterEndRepeat = ({ times }: { times: string }) => {
    this.endRepeat = 'after'
    this.endTimes = times
  }

  setEndDate = (date: Date | null) => {
    this.endDate = date
  }
}
