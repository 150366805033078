import { action, makeObservable, observable } from 'mobx'
import { IActionsProps } from 'shared/ui'

export class Base {
  id?
  constructor(id?: string | number) {
    this.id = id
    makeObservable(this, {
      actionsProps: observable,
      setActionsProps: action,
    })
  }
  actionsProps?: IActionsProps | null = null

  setActionsProps?: ((actionProps: IActionsProps) => void) | null = (
    actionProps: IActionsProps
  ) => {
    this.actionsProps = actionProps
  }
}

export default Base
