import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownCard, IDropdownItem } from 'shared/ui'

type ICreateContactActionDropdownItemProps = {
  onClick?: () => void
}

const item: IDropdownItem = {
  id: 'create_contact',
  iconL: 'userPlus',
  label: 'Create contact',
  ariaLabel: 'createContactButtonSelector',
}

export const CreateContactActionDropdownItem: FC<ICreateContactActionDropdownItemProps> = observer(
  ({ onClick }) => {
    return (
      <DropdownCard
        item={item}
        onChange={() => {
          onClick?.()
        }}
      />
    )
  }
)
