import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import { ConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { ConversationMessagesContent } from './ConversationMessagesContent'

type IConversationMessagesProps = {
  store: ConversationMessagesStore
}

export const ConversationMessages: FC<IConversationMessagesProps> = observer(({ store }) => {
  return (
    <ConversationMessagesContext.Provider value={store}>
      <ConversationMessagesContent />
    </ConversationMessagesContext.Provider>
  )
})
