import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import styles from './styles.module.scss'

type IProgressBar = {
  percent: number
  withAllBorders?: boolean
  withBg?: boolean
}

export const ProgressBar = observer(
  ({ percent, withAllBorders = false, withBg = false }: IProgressBar) => {
    return (
      <div className={classNames(styles.wrap, { [styles.withBg]: withBg })}>
        <div
          className={classNames(styles.progress, { [styles.allBorders]: withAllBorders })}
          style={{ width: `${percent}%` }}
        ></div>
      </div>
    )
  }
)
