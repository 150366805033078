// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svFO1Y4k5ttGfv9Enl7u .YE1BrGEoxEyC8M7MqIpZ{height:44px;font-size:14;font-weight:500;margin-top:40px}.svFO1Y4k5ttGfv9Enl7u .oUaZr9LTpPFnY17t6iOp{margin-bottom:24px}.svFO1Y4k5ttGfv9Enl7u .P90mHaXygKyA32uK2mao{padding:12px;font-weight:400;font-size:14px;line-height:20px}.svFO1Y4k5ttGfv9Enl7u .P90mHaXygKyA32uK2mao.oUaZr9LTpPFnY17t6iOp{margin-bottom:4px}.svFO1Y4k5ttGfv9Enl7u ._rgfQjDVW4Y2KQU1GPRm{margin-top:20px;margin-bottom:4px}", "",{"version":3,"sources":["webpack://./src/widgets/ForgotPassword/ui/ForgotPasswordForm/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,WAAA,CACA,YAAA,CACA,eAAA,CACA,eAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,iEACE,iBAAA,CAIJ,4CACE,eAAA,CACA,iBAAA","sourcesContent":[".wrap {\n  .button {\n    height: 44px;\n    font-size: 14;\n    font-weight: 500;\n    margin-top: 40px;\n  }\n\n  .error {\n    margin-bottom: 24px;\n  }\n\n  .textField {\n    padding: 12px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.error {\n      margin-bottom: 4px;\n    }\n  }\n\n  .inputLabel {\n    margin-top: 20px;\n    margin-bottom: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "svFO1Y4k5ttGfv9Enl7u",
	"button": "YE1BrGEoxEyC8M7MqIpZ",
	"error": "oUaZr9LTpPFnY17t6iOp",
	"textField": "P90mHaXygKyA32uK2mao",
	"inputLabel": "_rgfQjDVW4Y2KQU1GPRm"
};
export default ___CSS_LOADER_EXPORT___;
