import React from 'react'
import { observer } from 'mobx-react-lite'
import { Tabs, ITabItem } from 'shared/ui'
import { contactsTagsModalStore, ITagsModalMode } from 'widgets/ContactsTagsModal'

import { ContactsTagsDropdown } from './ContactsTagsDropdown'

export const contactsTagsModalContentTabs: ITabItem<ITagsModalMode>[] = [
  {
    key: 'add',
    name: 'Add tags',
  },
  {
    key: 'remove',
    name: 'Remove tags',
  },
]

export const ContactsTagsModalContent = observer(() => {
  const { mode, setMode } = contactsTagsModalStore

  return (
    <div>
      <Tabs<ITagsModalMode>
        tabs={contactsTagsModalContentTabs}
        activeTabKey={mode}
        handleSelectTab={(tab) => setMode(tab.key)}
      />
      <ContactsTagsDropdown />
    </div>
  )
})
