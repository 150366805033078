import React, { forwardRef } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import icons from 'shared/ui/Icon/icons'
import {
  IInputCommonProps,
  ITextFieldBaseProps,
  TextField,
  makeTextFieldInteractions,
} from 'shared/ui/TextField'

import './react-datepicker.css'
import styles from './styles.module.scss'

type IDatePickerTextField = {
  withCopy?: boolean
  size?: ITextFieldBaseProps['size']
  variant?: ITextFieldBaseProps['variant']
}

export type IDatePickerTextFieldProps = {
  date?: Date | null
  handleDate?: (date: Date | null) => void
  handleClose?: () => void
  handleOpen?: () => void
  dateFormat?: string
  placeholder?: string
  popperPlacement?: ReactDatePickerProps['popperPlacement']
  wrapperClassName?: ReactDatePickerProps['wrapperClassName']
  minDate?: ReactDatePickerProps['minDate']
} & IDatePickerTextField

const DatePickerTextFieldRef = forwardRef<
  HTMLInputElement,
  Omit<IInputCommonProps & IDatePickerTextField, 'ref'>
>(function DatePickerInputRef(props, ref) {
  const { value, withCopy, size, variant, ...rest } = props

  return (
    <TextField
      size={size}
      variant={variant}
      value={value}
      InputProps={{ ...rest, ref }}
      rightActions={[makeTextFieldInteractions({ type: 'copy', active: withCopy })]}
    />
  )
})

export const DatePickerTextField: React.FC<IDatePickerTextFieldProps> = ({
  date,
  handleDate,
  handleClose,
  handleOpen,
  dateFormat = 'MMM d, yyyy',
  placeholder = '',
  popperPlacement = 'auto',
  wrapperClassName,
  minDate,
  ...rest
}) => {
  const validDate = (date: Date | null | undefined) => {
    if (!date) return null
    return date
  }

  return (
    <ReactDatePicker
      wrapperClassName={wrapperClassName}
      customInput={<DatePickerTextFieldRef {...rest} />}
      placeholderText={placeholder}
      selected={validDate(date)}
      onChange={(date) => handleDate?.(date)}
      icon={icons['calendar']}
      dateFormat={dateFormat}
      onCalendarClose={() => handleClose?.()}
      onCalendarOpen={() => handleOpen?.()}
      calendarClassName={styles.calendarClassName}
      popperPlacement={popperPlacement}
      minDate={minDate}
    />
  )
}
