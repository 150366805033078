import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MessageField } from 'widgets/MessageField'
import { CallSection, useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'

export const CallMessagePopUpContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const { messageFieldStore, initMessageField } = store as CallPopUpDefaultStore

  useEffect(() => {
    initMessageField()
  }, [])

  return (
    <CallSection title={'Message'}>
      <MessageField messageFieldStore={messageFieldStore} />
    </CallSection>
  )
})
