import parser, { DOMNode, Element, Text } from 'html-react-parser'

export const htmlTags = [
  'a',
  'abbr',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'base',
  'bdi',
  'bdo',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'data',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  'embed',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hgroup',
  'hr',
  'html',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'math',
  'menu',
  'menuitem',
  'meta',
  'meter',
  'nav',
  'noscript',
  'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'picture',
  'pre',
  'progress',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'script',
  'search',
  'section',
  'select',
  'slot',
  'small',
  'source',
  'span',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'svg',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  'var',
  'video',
  'wbr',
]

type IParseHtmlOptions = {
  search?: string
}

export const parserHtml = (text: string, options?: IParseHtmlOptions) => {
  const { search } = options || {}

  return parser(text, {
    replace(domNode: DOMNode) {
      const textNode = domNode as Text
      const tagNode = domNode as Element
      const isTag = domNode.type === 'tag'
      const isText = domNode.type === 'text'
      const isValid = htmlTags.includes(tagNode.name)

      if (!isValid && isTag) {
        const htmlContent = parser(`&lt;${tagNode.name}&gt;`)
        const nextElements = tagNode.children.map((item) => {
          if (item.type === 'tag') return ''

          return (item as Text).data
        })

        return (
          <>
            {htmlContent} {nextElements}
          </>
        )
      }

      if (search && isText && textNode.data.includes(search)) {
        const textContent = textNode.data.replaceAll(
          search,
          `<span style="background-color:var(--yellow-40)">${search}</span>`
        )

        const htmlContent = parser(textContent)

        return <>{htmlContent}</>
      }
    },
  })
}
