import { HTMLAttributes, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, Icon } from 'shared/ui'
import { EnrollmentFilters } from 'widgets/ContactEnrollment'

import { ListItemPreview } from './ListItem'

export type IEnrollmentListPreviewProps = {
  filters: EnrollmentFilters
  previewPlaceholder?: ReactNode
}

export const EnrollmentPreview = observer(
  ({ filters }: Pick<IEnrollmentListPreviewProps, 'filters'> & HTMLAttributes<HTMLDivElement>) => {
    const { allWithCount } = filters

    return (
      <>
        {allWithCount.filters.map(({ item, count }, index) => (
          <ListItemPreview
            key={item.id}
            icon={<Icon icon='filter' />}
            name={`Filter ${index + 1}`}
            count={count}
          />
        ))}
        {allWithCount.tags.map(({ item, count }) => (
          <ListItemPreview
            key={item.id}
            icon={<Icon icon='colorDot' color='var(--green-60)' />}
            name={item.label || `${item.id}`}
            count={count}
          />
        ))}
        {allWithCount.segments.map(({ item, count }) => (
          <ListItemPreview
            key={item.id}
            icon={<Icon icon='segment' />}
            name={item.name}
            count={count}
          />
        ))}
        {allWithCount.contacts.map(({ item, count }) => (
          <ListItemPreview
            key={item.id}
            icon={<Avatar info={item.avatarInfo} size={16} />}
            name={item.name}
            count={count}
          />
        ))}
      </>
    )
  }
)
