import { makeAutoObservable } from 'mobx'
import { IDialpadItem } from 'widgets/CallDialpad'

export class CallDialpadStore {
  items: IDialpadItem[] = [
    {
      key: '1',
      value: '1',
      symbols: [],
    },
    {
      key: '2',
      value: '2',
      symbols: ['A', 'B', 'C'],
    },
    {
      key: '3',
      value: '3',
      symbols: ['D', 'E', 'F'],
    },

    {
      key: '4',
      value: '4',
      symbols: ['G', 'H', 'I'],
    },
    {
      key: '5',
      value: '5',
      symbols: ['J', 'K', 'L'],
    },
    {
      key: '6',
      value: '6',
      symbols: ['M', 'N', 'O'],
    },

    {
      key: '7',
      value: '7',
      symbols: ['P', 'Q', 'R', 'S'],
    },
    {
      key: '8',
      value: '8',
      symbols: ['T', 'U', 'V'],
    },
    {
      key: '9',
      value: '9',
      symbols: ['W', 'X', 'Y', 'Z'],
    },

    {
      key: '*',
      value: '*',
      symbols: [],
    },
    {
      key: '0',
      value: '0',
      symbols: ['+'],
    },
    {
      key: '#',
      value: '#',
      symbols: [],
    },
  ]

  constructor() {
    makeAutoObservable(this)
  }
}
