import { makeObservable, observable } from 'mobx'
import { IIconsVector } from 'shared/ui/Icon/icons'
import { IDropdownItem } from 'shared/ui'
import { Base } from 'models'
import { TYPES_INBOX, TreeRootInboxes, NAME_INBOX, IInboxKey } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { organizationStore } from 'entities/Organization'
import { IResponseInbox } from 'entities/Inbox/api/types'
import { subscriptionStore } from 'entities/Subscription'
import { ICallViaAircall } from 'entities/Organization/api/types'

export class Inbox extends Base {
  id: number
  organization_id!: number
  numberIds!: number[]
  numberId!: number
  memberIds!: number[]
  ownerId!: number
  is_favorite!: boolean
  is_geomatch!: boolean
  is_hubspot_assignment!: boolean
  is_round_robin!: boolean
  is_smart!: boolean
  is_aircall!: boolean
  name!: string
  type!: 'inbox'
  teamsIds!: number[]
  is_rename!: boolean
  description?: string
  root_tree!: string
  is_muted!: boolean
  unread!: number
  color_coding_enabled!: boolean
  call_via_aircall!: ICallViaAircall | null
  sending_options: IResponseInbox['sending_options'] | null = null
  origin!: IResponseInbox

  constructor(item: IResponseInbox) {
    super()

    this.id = item.id
    this.syncOrigin(item)

    makeObservable(this, {
      name: observable,
      is_rename: observable,
      is_favorite: observable,
      root_tree: observable,
      unread: observable,
      is_muted: observable,
    })
  }

  handleUpdateIsMuted = (status: boolean) => {
    this.is_muted = status
  }

  initRootTree() {
    if (this.isFavoriteType) return TreeRootInboxes.FAVORITE
    if (!this.isFavoriteType) return TreeRootInboxes.SIMPLE

    return TreeRootInboxes.SMART
  }

  syncOrigin = (origin: IResponseInbox & { root_tree?: string; is_rename?: boolean }) => {
    this.id = origin.id
    this.organization_id = origin.organization_id
    this.is_favorite = origin.is_favorite
    this.is_geomatch = origin.is_geomatch
    this.is_hubspot_assignment = origin.is_hubspot_assignment
    this.is_round_robin = origin.is_round_robin
    this.is_smart = origin.is_smart
    this.is_aircall = Boolean(origin?.number?.is_aircall)
    this.name = origin.name
    this.unread = origin.unread || 0
    this.numberId = origin.number ? origin.number.id : 0
    this.numberIds = Array.isArray(origin.numbers) ? origin.numbers.map((item) => item.id) : []
    this.memberIds = origin.member_ids
    this.ownerId = origin.owner_id
    this.teamsIds = []
    this.is_rename = Boolean(origin.is_rename)
    this.description = origin.description
    this.root_tree = origin.root_tree || this.initRootTree()
    this.is_muted = origin.is_muted
    this.type = 'inbox'
    this.color_coding_enabled = origin.color_coding_enabled
    this.origin = origin
    this.call_via_aircall = origin.call_via_aircall
    this.sending_options = origin.sending_options
  }

  get number() {
    return numbersStore.getItem(this.numberId)
  }

  get isCreateGroup() {
    return this.id === NAME_INBOX.Create
  }

  get icon(): IIconsVector {
    if (this.isUnifiedInbox) return 'all_inboxes'
    if (this.isGroupInbox) return 'folder'
    if (this.isAircall) return 'aircall'

    return 'inboxOpen'
  }

  get inboxType() {
    if (this.is_smart) return TYPES_INBOX.SMART

    return TYPES_INBOX.SHARED
  }

  get typeKey(): IInboxKey {
    return 'shared'
  }

  get currentInboxType() {
    if (this.isGroupInbox) return TYPES_INBOX.GROUP
    if (this.isSmartInbox) return TYPES_INBOX.SMART
    if (this.isSharedInbox) return TYPES_INBOX.SHARED

    return TYPES_INBOX.UNIFIED
  }

  get isSimpleType() {
    return Boolean(!this.isSmartInbox)
  }

  get isFavoriteType() {
    return Boolean(this.is_favorite)
  }

  get isGroupInbox() {
    return this.inboxType === TYPES_INBOX.GROUP
  }

  get isAircall() {
    return this.is_aircall
  }

  get isUnifiedInbox() {
    return this.inboxType === TYPES_INBOX.UNIFIED
  }

  get isSmartInbox() {
    return this.inboxType === TYPES_INBOX.SMART
  }

  get isSharedInbox() {
    return this.inboxType === TYPES_INBOX.SHARED
  }

  get dropdownData(): IDropdownItem {
    return {
      id: this.id,
      label: this.name,
      iconL: 'folder',
    }
  }

  get bodyUpdateTeamsFavorite() {
    return {
      favorite: this.is_favorite ? 0 : 1,
      inbox_id: this.id,
      inbox_type: this.isGroupInbox
        ? 'inbox_group'
        : this.isUnifiedInbox
        ? 'unified-inbox'
        : 'inbox',
    }
  }

  get bodyUpdateUserTeam() {
    return {
      id: this.id,
      type: this.currentInboxType,
    }
  }

  get bodyCreateTeamsGroupsByIdAddTeams() {
    return {
      team_ids: [this.id],
    }
  }

  get bodyUpdateTeamsGroupsById() {
    return {
      name: this.name,
      description: this.description,
      team_ids: this.teamsIds,
    }
  }

  get idTree() {
    return `${this.root_tree}_${this.id}`
  }

  get numberOrCount() {
    if (this.numberIds.length > 1) {
      return `${this.numberIds.length} numbers`
    }

    const number = numbersStore.getItem(this.numberId)

    return number ? number.formatted_number : ''
  }

  get isUseOrganizationCallSettings() {
    return numbersStore.getItem(this.numberId)?.isUseOrganizationCallSettings
  }

  get isNumberOutboundCalls() {
    if (this.isUseOrganizationCallSettings) {
      return organizationStore.organization_call?.is_active
    }

    return numbersStore.getItem(this.numberId)?.voice_outbound
  }

  get isNumberInboundCalls() {
    if (this.isUseOrganizationCallSettings) {
      return organizationStore.organization_call?.is_active_inbound
    }

    return numbersStore.getItem(this.numberId)?.voice
  }

  get isMuted() {
    return this.is_muted
  }

  get isPFTInbox() {
    return subscriptionStore.isTrial && this.number?.number_vendor?.key === 'inteliquent'
  }

  get isCallViaAircall() {
    return this.call_via_aircall?.is_active
  }

  get sendingOptions() {
    if (!this.sending_options) return []

    return this.sending_options.smart
  }

  getTypeNumber = (numberId: number) => {
    const scaleType = this.sendingOptions.find((item) => item.number_ids.includes(numberId))?.key

    if (scaleType) return scaleType

    return numberId
  }
}
