import React from 'react'
import { SendLaterStore } from 'shared/ui/Schedule'
import { SendLaterContent } from './ui/SendLaterContent'
import { SendLaterContext } from './context/sendLaterContext'

type ISendLaterProps = {
  store: SendLaterStore
}

export const SendLater = ({ store }: ISendLaterProps) => {
  return (
    <SendLaterContext.Provider value={store}>
      <SendLaterContent />
    </SendLaterContext.Provider>
  )
}
