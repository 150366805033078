import { FC } from 'react'
import classnames from 'classnames'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import styles from './styles.module.scss'

type ITabProps = {
  title: string
  id: EnumIntegrationTabs
  activeTab: EnumIntegrationTabs
  handleTab: (tab: EnumIntegrationTabs) => void
  hide?: boolean
  count?: number
  enableCount?: boolean
}

export const Tab: FC<ITabProps> = ({
  hide,
  title,
  activeTab,
  handleTab,
  id,
  count,
  enableCount,
}) => {
  if (hide) return null

  const hideCount = !enableCount || isNaN(Number(count))

  return (
    <div
      className={classnames(styles.tab, {
        [styles.active]: activeTab === id,
      })}
      onClick={() => {
        handleTab(id)
      }}
    >
      {title}
      {!hideCount && <div className={styles.count}>{count}</div>}
    </div>
  )
}
