// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LDfwNo4bSr5Ogta0XQkZ{background:var(--background-neutral-subtle);display:grid;grid-template-columns:1fr max-content}.TyCGAvjeTyK2dMncoJwh .uzx9Guswhs9fwTPISpfC{padding:16px;display:flex;flex-direction:column;gap:4px}.TyCGAvjeTyK2dMncoJwh .zqhWFqUWwiQxdoTHpLQY{position:relative;margin:0 0 16px 16px}.RtjIQdwc_HeTXTF5nCnE{padding:16px 12px 16px 4px}.unSoltbbC1JFcm57tDul{padding:4px}.f6bx_Oy4dtZgDT6O3uP0{border-top:1px solid var(--border-neutral-default)}", "",{"version":3,"sources":["webpack://./src/widgets/TrackConversions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2CAAA,CACA,YAAA,CACA,qCAAA,CAGA,4CACE,YAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,4CACE,iBAAA,CACA,oBAAA,CAGJ,sBACE,0BAAA,CAEF,sBACE,WAAA,CAEF,sBACE,kDAAA","sourcesContent":[".wrap{\n  background: var(--background-neutral-subtle);\n  display: grid;\n  grid-template-columns: 1fr max-content;\n}\n.left{\n  .info{\n    padding: 16px;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n  }\n  .select{\n    position: relative;\n    margin: 0 0 16px 16px;\n  }\n}\n.right{\n  padding: 16px 12px 16px 4px;\n}\n.newBtnWrap{\n  padding: 4px;\n}\n.searchInput{\n  border-top: 1px solid var(--border-neutral-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "LDfwNo4bSr5Ogta0XQkZ",
	"left": "TyCGAvjeTyK2dMncoJwh",
	"info": "uzx9Guswhs9fwTPISpfC",
	"select": "zqhWFqUWwiQxdoTHpLQY",
	"right": "RtjIQdwc_HeTXTF5nCnE",
	"newBtnWrap": "unSoltbbC1JFcm57tDul",
	"searchInput": "f6bx_Oy4dtZgDT6O3uP0"
};
export default ___CSS_LOADER_EXPORT___;
