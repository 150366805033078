import { observer } from 'mobx-react-lite'
import { TextField, makeTextFieldAutoFocus, makeTextFieldIcon } from 'shared/ui/TextField'
import { adminTenDLCTableStore } from 'pages/admin/pages/compliance/pages/TenDLC'

import styles from './styles.module.scss'

export const TenDLCSearch = observer(() => {
  const { search, setSearch } = adminTenDLCTableStore
  return (
    <div className={styles.wrap}>
      <TextField
        className={styles.search}
        leftActions={[
          makeTextFieldIcon({
            icon: 'search',
            fontSize: 20,
            color: 'var(--content-primary-tertiary)',
            className: styles.icon,
          }),
        ]}
        variant='integrated'
        InputProps={{
          placeholder: 'Search...',
        }}
        onChange={setSearch}
        value={search || ''}
        mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
      />
    </div>
  )
})
