export const TYPES_INBOX = {
  PERSONAL: 1,
  SHARED: 2,
  UNIFIED: 3,
  GROUP: 4,
  SMART: 5,
}

export enum NAME_INBOX {
  Create = -1,
}
