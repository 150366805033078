import { FC, useMemo, ReactNode } from 'react'
import { Button, IIconsVector } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  type IntegrationKey,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IntegrationOpportunityProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationOpportunityProperty'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import { Actions } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/Actions'
import {
  ShowMoreAction,
  ShowMoreActionStore,
} from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction'
import { SectionTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/SectionTab/SectionTab'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'

type IOpportunitiesProps = {
  integrationLabel: string
  integrationKey: IntegrationKey
  integrationIcon: IIconsVector
  opportunities: IntegrationOpportunityProperty[]
  contact: Contact
  action?: ReactNode
  handleClear?: (id: string) => void
}

export const Opportunities: FC<IOpportunitiesProps> = ({
  integrationKey,
  integrationLabel,
  integrationIcon,
  opportunities,
  contact,
  handleClear,
}) => {
  const showMoreActionStore = useMemo(
    () => new ShowMoreActionStore(integrationKey, EnumIntegrationTabs.Opportunities),
    []
  )
  const store = useContactsIntegrationsContext()
  const integration = integrationsStore.getIntegration(integrationKey)
  const linkProps = {
    key: integrationKey,
    contact: contact,
    integration: integration,
    type: IIntegrationLinkTypes.opportunities,
  }

  const link = (id: number | string | null) => integrationLink({ ...linkProps, id })
  const handleView = () => {
    store.openView('opportunities', { opportunities }, integrationIcon, integrationKey)
  }

  const actions = () => {
    const hasMore = opportunities.length > 1

    return (
      <Actions
        rows={[
          {
            columns: [
              !showMoreActionStore.hideShowMore && (
                <ShowMoreAction key={'show_more'} disabled={!contact} store={showMoreActionStore} />
              ),
              hasMore && <Button size='small' typeBtn='text' text='See all' onClick={handleView} />,
            ],
          },
        ]}
      />
    )
  }

  const opportunity = opportunities[0] || null
  const properties = showMoreActionStore.modifyData(opportunity?.properties || [])

  return (
    <>
      <SectionTab
        item={opportunity}
        titleView={'View opportunity'}
        titleEmpty={'No opportunities found'}
        integrationLabel={integrationLabel}
        properties={properties}
        link={link(opportunity?.id)}
        actions={actions()}
        handleClear={(id) => {
          opportunity.removeProperty(id)
          handleClear?.(id)
        }}
      />
    </>
  )
}
