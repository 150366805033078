import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { Button, Icon, Typography, Error, Dropdown, Tooltip, Alert } from 'shared/ui'
import {
  IInputComponent,
  makeInputPattern,
  makeTextFieldAutoFocus,
  makeTextFieldElement,
  makeTextFieldHighlight,
  makeTextFieldHighlightLimit,
  makeTextFieldIcon,
  TextField,
  TextFieldPlaceholder,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { Warning } from 'shared/ui/Warning'
import {
  MAX_LAST_NAME_LENGTH,
  MAX_FIRST_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
} from 'shared/constants/auth'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import { links } from 'shared/constants/links'
import { MemberInviteStore } from 'widgets/MemberInvite/store/memberInviteStore'

import styles from './styles.module.scss'

type IFormProps = {
  store: MemberInviteStore
}

const InputPhone = makeInputPattern({
  format: '(###) ###-####',
  placeholder: '(555) 800-8000',
})

export const MemberInviteForm = observer(({ store }: IFormProps) => {
  const {
    email,
    loading,
    disabledAccept,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    setCountry,
    country,
    countries,
    setNumber,
    number,
    isActiveFirstNameField,
    isActiveLastNameField,
    focusFirstNameField,
    focusLastNameField,
    blurFirstNameField,
    blurLastNameField,
    firstNameLength,
    lastNameLength,
    isMaxLastNameLength,
    isMaxFirstNameLength,
    triggerNumberValidation,
    numberError,
    isValidNumber,
    numberRequestError,
    showRetryAlert,
    setShowRetryError,
    acceptInvite,
    setPassword,
    setConfirmPassword,
    password,
    confirmPassword,
    triggerPasswordValidation,
    passwordError,
    confirmPasswordError,
    isProfileExist,
    prefilled,
  } = store

  const onKeyDownNumber = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerNumberValidation()
      if (isValidNumber && document.activeElement instanceof HTMLElement)
        document.activeElement.blur()
    }
  }

  const onKeyDownFirstName = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!!firstName && document.activeElement instanceof HTMLElement)
        document.activeElement.blur()
    }
  }

  const onKeyDownLastName = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!!lastName && document.activeElement instanceof HTMLElement) document.activeElement.blur()
    }
  }

  const onKeyDownPassword = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerPasswordValidation()
    }
  }

  return (
    <div className={styles.wrap}>
      {(isProfileExist || showRetryAlert) && (
        <div className={styles.alertWrap}>
          {isProfileExist && (
            <Alert
              item={{
                type: 'infoBlue',
                desc: 'Your existing password remains unchanged',
              }}
            />
          )}

          {showRetryAlert && (
            <Alert
              item={{
                type: 'error',
                desc: 'There was an issue processing your request.',
              }}
              rightAction={
                <Button
                  text={'Retry'}
                  typeBtn={'outlined'}
                  size={'small'}
                  contained={'secondary'}
                  onClick={() => {
                    setShowRetryError(false)
                    acceptInvite()
                  }}
                />
              }
            />
          )}
        </div>
      )}

      <Box display='flex' justifyContent='space-between' gap={'12px'}>
        <div className={styles.fieldWrap}>
          <Label
            marginTop={0}
            label={{
              text: 'First name',
              className: styles.inputLabel,
              rightText: isActiveFirstNameField ? (
                <Tooltip label={`Limit: ${MAX_FIRST_NAME_LENGTH} characters`} placement='top'>
                  <>{`${firstNameLength} of ${MAX_FIRST_NAME_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            className={classNames(styles.textField, {
              [styles.warning]: isMaxFirstNameLength,
            })}
            variant='stroke'
            size='medium'
            InputProps={{ placeholder: 'Enter first name', onKeyDown: onKeyDownFirstName }}
            onChange={setFirstName}
            onFocus={focusFirstNameField}
            onBlur={blurFirstNameField}
            disabled={loading || prefilled.firstName}
            value={firstName}
            mainActions={[
              makeTextFieldAutoFocus({ withFocus: true }),
              makeTextFieldHighlightLimit({ limit: MAX_LAST_NAME_LENGTH }),
            ]}
          />
          <Warning
            text={
              isMaxFirstNameLength && isActiveFirstNameField
                ? `${MAX_FIRST_NAME_LENGTH}-character limit reached`
                : ''
            }
          />
        </div>
        <div className={styles.fieldWrap}>
          <Label
            marginTop={0}
            label={{
              text: 'Last name',
              className: styles.inputLabel,
              rightText: isActiveLastNameField ? (
                <Tooltip label={`Limit: ${MAX_FIRST_NAME_LENGTH} characters`} placement='top'>
                  <>{`${lastNameLength} of ${MAX_LAST_NAME_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            className={classNames(styles.textField, {
              [styles.warning]: isMaxLastNameLength,
            })}
            variant='stroke'
            size='medium'
            onFocus={focusLastNameField}
            onBlur={blurLastNameField}
            disabled={loading || prefilled.lastName}
            InputProps={{ placeholder: 'Enter last name', onKeyDown: onKeyDownLastName }}
            onChange={setLastName}
            value={lastName}
            mainActions={[makeTextFieldHighlightLimit({ limit: MAX_LAST_NAME_LENGTH })]}
          />
          <Warning
            text={
              isMaxLastNameLength && isActiveLastNameField
                ? `${MAX_LAST_NAME_LENGTH}-character limit reached`
                : ''
            }
          />
        </div>
      </Box>

      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, styles.disabled)}
        variant='stroke'
        disabled={true}
        size='medium'
        value={email}
      />

      <Label
        label={{
          text: 'Mobile phone number',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, {
          [styles.error]: numberError || numberRequestError,
        })}
        Input={InputPhone}
        InputProps={{ onKeyDown: onKeyDownNumber }}
        error={!!numberRequestError}
        variant='stroke'
        size='medium'
        value={number}
        disabled={loading || prefilled.number}
        onChange={setNumber}
        leftActions={[
          makeTextFieldElement({
            element: (
              <div className={styles.leftContentNumber}>
                <Dropdown
                  placement={'bottom-start'}
                  width={191}
                  margin={-28}
                  marginLeft={-12}
                  items={countries}
                  onChange={setCountry}
                  triggerComponent={() => (
                    <TextFieldPlaceholder
                      rightActions={[
                        makeTextFieldIcon({
                          icon: 'chevronDown',
                        }),
                      ]}
                      placeholder={<Icon icon={country.iconL} />}
                      variant={'integrated'}
                      size={'small'}
                      className={styles.countryPicker}
                    />
                  )}
                />
                <div className={styles.verticalDivider}></div>
                <Typography variant={'text-input-field'} ariaLabel={'numberPrefix'}>
                  +1
                </Typography>
              </div>
            ),
          }),
        ]}
        mainActions={[makeTextFieldHighlight({ mode: 'error', highlight: !!numberError })]}
      />
      <Error type={'text'} error={numberError || numberRequestError} />

      {!isProfileExist && (
        <>
          <Label
            label={{
              text: 'Password',
              className: styles.inputLabel,
            }}
          />
          <TextField
            Input={InputPassword as IInputComponent}
            className={classNames(styles.textField, {
              [styles.error]: passwordError,
            })}
            variant='stroke'
            InputProps={{
              placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
              onKeyDown: onKeyDownPassword,
            }}
            disabled={loading}
            size='medium'
            onChange={setPassword}
            value={password}
            mainActions={[makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH })]}
          />
          <Error type={'text'} error={passwordError} />

          <Label
            label={{
              text: 'Confirm password',
              className: styles.inputLabel,
            }}
          />
          <TextField
            Input={InputPassword as IInputComponent}
            className={classNames(styles.textField, {
              [styles.error]: confirmPasswordError,
            })}
            variant='stroke'
            InputProps={{
              placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
              onKeyDown: onKeyDownPassword,
            }}
            disabled={loading}
            size='medium'
            onChange={setConfirmPassword}
            value={confirmPassword}
            mainActions={[makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH })]}
          />
          <Error type={'text'} error={confirmPasswordError} />
        </>
      )}

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={acceptInvite}
        disabled={disabledAccept || loading}
        loading={loading}
        text={'Accept invitation'}
        contained={'primary'}
        fullWidth
      />
      <div className={styles.termsAndPolicy}>
        <Typography variant={'body-sm-medium'} ariaLabel={'termsAndPolicy'}>
          {"By continuing, you agree to Salesmsg's "}
          <a target={'_blank'} href={links.termsConditions} rel='noreferrer'>
            Terms of Service
          </a>{' '}
          and{' '}
          <a target={'_blank'} href={links.privacyPolicy} rel='noreferrer'>
            Privacy Policy
          </a>
        </Typography>
      </div>
    </div>
  )
})

export default MemberInviteForm
