import { FC } from 'react'
import classNames from 'classnames'
import { IDropdownItemProps, IconButton, Tooltip } from 'shared/ui'
import styles from './styles.module.scss'

export const DropdownCardDeletable: FC<IDropdownItemProps> = ({ item, onChange }) => {
  const isCardClick = item.isCardClick
  const onCardClick = () => {
    if (isCardClick) {
      onChange?.(item)
    }
  }
  const content = (
    <div className={styles.contentWrapper}>
      <div className={styles.label} style={{ width: item.labelWidth }}>
        {item.labelContent || item.label}
      </div>

      <IconButton
        icon='close'
        size='small'
        variant='icon'
        iconProps={{
          color: 'var(--gray-60)',
        }}
        onClick={() => {
          item.onDelete?.()
        }}
        ariaLabel='DropdownCardDeletable_close'
      />
    </div>
  )

  return (
    <div
      className={classNames(
        styles.checkboxItemContainer,
        item.disabled && styles.disabledCheckBox,
        isCardClick && styles.pointer
      )}
      onClick={onCardClick}
    >
      {item.tooltipProps ? <Tooltip {...item.tooltipProps}>{content}</Tooltip> : content}
    </div>
  )
}
