import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { websocket } from 'entities/WebSocket'
import { userSettingsStore } from 'entities/Settings'
import { callStore } from 'entities/Call'
import { authStore } from 'entities/Auth'
import { organizationStore } from 'entities/Organization'
import { inboxesStore } from 'entities/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'

const CallPopUpLayoutLayout = observer(() => {
  const { refresh } = authStore
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    refresh({ enableLoader: false })
      .then(async (data) => {
        if (data?.is_login) {
          await Promise.all([
            organizationStore.fetchOrganization(),
            userSettingsStore.fetchUsersSettings(),
            featureFlagsStore.fetchFeatureFlags(),
            inboxesStore.fetchInboxes(),
          ])

          if (userSettingsStore.isSalesmsgDesignV2) {
            await callStore.initDevice()
          }

          await websocket.connect(localStorage.getItem('token'))
        }

        return data
      })
      .then(() => {
        setLoading(false)
      })
  }, [])

  if (loading) return null

  return <Outlet />
})

export default CallPopUpLayoutLayout
