import { observer } from 'mobx-react-lite'
import { Button, Icon } from 'shared/ui'
import { TrackConversions } from 'widgets/TrackConversions/TrackConversions'
import type { TrackConversionStore } from 'widgets/TrackConversions/store/trackConversionStore'
import styles from './styles.module.scss'

type IAdvancedProps = {
  trackConversionStore: TrackConversionStore
  isOpenAdvanced: boolean
  setOpenAdvanced: (isOpen: boolean) => void
}

export const AdvancedTrackConversions = observer(
  ({ trackConversionStore, isOpenAdvanced, setOpenAdvanced }: IAdvancedProps) => {
    const handleOpen = () => {
      setOpenAdvanced(!isOpenAdvanced)
    }
    return (
      <div>
        <Button
          typeBtn={'text'}
          contained={'secondary'}
          size={'medium'}
          text={
            <>
              Advanced
              <Icon
                icon={isOpenAdvanced ? 'chevronUp' : 'chevronDown'}
                className={styles.rightIcon}
              />
            </>
          }
          intent={'passive'}
          onClick={handleOpen}
        />
        {isOpenAdvanced && (
          <div className={styles.content}>
            <TrackConversions store={trackConversionStore} />
          </div>
        )}
      </div>
    )
  }
)
