import { observer } from 'mobx-react-lite'
import { Box, Divider } from '@mui/material'
import React from 'react'
import { DayjsFormats, numberFormat } from 'shared/lib'
import { Typography } from 'shared/ui'
import { adminOrganizationCustomPlanStore } from '../../store/adminOrganizationCustomPlanStore'

export const SummaryEditPayment = observer(() => {
  const { paymentScheduleTable, remainingAmount } = adminOrganizationCustomPlanStore

  return (
    <Box bgcolor='var(--background-neutral-subtle)' p={2} borderRadius='8px'>
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-tertiary)'}
          ariaLabel={'Next Billing'}
        >
          Next Billing Cycle Start Date
        </Typography>

        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Next Billing Date'}
        >
          {paymentScheduleTable.firstPayment?.payment_date.format(DayjsFormats.full6)}
        </Typography>
      </Box>

      <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-tertiary)'}
          ariaLabel={'Subtotal'}
        >
          Subtotal
        </Typography>

        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'SubtotalPrice'}
        >
          {numberFormat({ value: remainingAmount, currency: 'USD' })}
        </Typography>
      </Box>

      <Divider sx={{ marginY: 1.5 }} />

      <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Total due'}
        >
          Total due {paymentScheduleTable.nextPayment?.payment_date.format(DayjsFormats.full6)}
        </Typography>

        <Typography
          variant={'body-md-semibold'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Total due Value'}
        >
          {numberFormat({
            value: paymentScheduleTable.nextPayment?.amount || 0,
            currency: 'USD',
          })}
        </Typography>
      </Box>
    </Box>
  )
})
