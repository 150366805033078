import { Navigate, Route, Routes } from 'react-router-dom'
import React from 'react'
import { TenDLCDetails } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/TenDLCDetails'
import { ComplianceLayout } from 'pages/admin/pages/compliance/layout/ComplianceLayout'
import { AdminRoutesEnum } from 'pages/admin'
import { TenDLC } from 'pages/admin/pages/compliance/pages/TenDLC'
import { TollFree } from 'pages/admin/pages/compliance/pages/TollFree'
import { TollFreeDetails } from 'pages/admin/pages/compliance/pages/TollFreeDetails'

export const ComplianceRoutes = () => {
  return (
    <Routes>
      <Route path={`/${AdminRoutesEnum.tenDLC}/:id`} element={<TenDLCDetails />} />
      <Route path={`/${AdminRoutesEnum.tollFree}/:id`} element={<TollFreeDetails />} />
      <Route element={<ComplianceLayout />}>
        <Route index element={<Navigate to={`${AdminRoutesEnum.tenDLC}`} />} />
        <Route path={`/${AdminRoutesEnum.tenDLC}`} element={<TenDLC />} />
        <Route path={`/${AdminRoutesEnum.tollFree}`} element={<TollFree />} />
      </Route>
    </Routes>
  )
}
