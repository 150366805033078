import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IDropdownItem } from 'shared/ui'
import styles from './styles.module.scss'

type IWidgetMultiSelectTriggerProps = {
  selectedItems: IDropdownItem[]
  isTag: boolean
}

export const WidgetMultiSelectTrigger: FC<IWidgetMultiSelectTriggerProps> = observer(
  ({ selectedItems, isTag }) => {
    if (!selectedItems.length) return <>select</>

    if (isTag) {
      if (selectedItems.length === 1)
        return (
          <div className={styles.countLabelWrapper}>
            <span className={styles.color} />
            <span className={styles.label}>{selectedItems[0].label}</span>
          </div>
        )

      return (
        <span className={styles.countLabelWrapper}>
          {Boolean(selectedItems.slice(0, 3).length) && (
            <div className={styles.countColors}>
              {selectedItems.slice(0, 3).map((item, index) => (
                <span
                  key={String(item)}
                  className={styles.countColor}
                  style={{ left: index * 6 }}
                />
              ))}
            </div>
          )}
          <span className={styles.countLabel}>{selectedItems.length} tags</span>
        </span>
      )
    }

    return (
      <>
        {selectedItems.length === 1
          ? `${selectedItems.length} value`
          : `${selectedItems.length} values`}
      </>
    )
  }
)
