import { Base } from 'shared/api/models'
import { IResponseAdminOwner, IResponseAdminUser } from 'entities/Admin'

export class TeamMember extends Base {
  id: number
  status: 'Active' | 'Removed'
  role: string
  name: string
  email: string

  constructor({ user, owner }: { user?: IResponseAdminUser; owner?: IResponseAdminOwner }) {
    super(user?.id)

    if (user) {
      this.id = user.id
      this.status = user.is_active ? 'Active' : 'Removed'
      this.role = user.role
      this.name = user.name
      this.email = user.email
    } else {
      this.id = owner?.id || 0
      this.status = 'Active'
      this.role = 'Owner'
      this.name = owner?.name || ''
      this.email = owner?.email || ''
    }
  }
}
