import { CancelTokenSource } from 'axios'
import { type IReactionDisposer, makeAutoObservable, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { uiStore } from 'shared/store/uiStore'
import { complianceStore, type IResponseComplianceServicesBrand } from 'entities/Compliance'
import type {
  IResponseBusinessInformation,
  IResponseComplianceServicesCampaign,
} from 'entities/Compliance/api/complianceType'
import { subscriptionStore } from 'entities/Subscription'
import { OrganizationBusinessProfile, PointsOfContact, Summary } from 'entities/Compliance/model'
import { usersStore } from 'entities/Users'
import { billingStore } from 'entities/Billing'
import { numbersStore } from 'entities/Phone'
import { CompliancePath } from 'pages/settings/pages/compliance/route/compliancePath'
import { TollFreeRoutes } from 'pages/settings/pages/compliance/pages/tollFree'
import { StepsStore } from './stepsStore'
import { ErrorStore } from './errorStore'

export class ComplianceLayerStore {
  private _stepsStore = new StepsStore()
  private _errorStore = new ErrorStore()

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _loading = true
  private _modalIdDoNotHaveEI = 'modal_id_do_not_have_EIN'

  constructor() {
    makeAutoObservable(this)
  }

  get loading() {
    return this._loading
  }

  get stepsStore() {
    return this._stepsStore
  }

  get errorStore() {
    return this._errorStore
  }

  get organizationTollFree() {
    return complianceStore.organizationTollFree
  }

  get tollFreeProfileItems() {
    if (!this.organizationTollFree) return []

    return this.organizationTollFree.items
  }

  get complianceServicesList() {
    return complianceStore.complianceServicesList
  }

  get complianceServiceCampaign() {
    return this.complianceServicesList?.campaign
  }

  get complianceServiceBrand() {
    return this.complianceServicesList?.brand
  }

  get businessInformation() {
    return this.organizationBusinessProfile?.businessInformation
  }

  get pointsOfContact() {
    if (!this.organizationBusinessProfile) return new PointsOfContact([])

    return this.organizationBusinessProfile.pointsOfContact
  }

  get businessProfile() {
    if (!this.organizationBusinessProfile) return null

    return this.organizationBusinessProfile.businessProfile
  }

  get organizationBusinessProfile() {
    return complianceStore.organizationBusinessProfile
  }

  get summary() {
    return complianceStore.summary
  }

  get voiceIntegrity() {
    return complianceStore.organizationVoiceIntegrity
  }

  get shakenStir() {
    return complianceStore.organizationShakenStir
  }

  get organizationCnam() {
    return complianceStore.cnamStore.organizationCnam
  }

  get billingComplianceInfo() {
    return billingStore.billingComplianceInfo
  }

  get tollFreeMessageVolume() {
    return complianceStore.tollFreeMessageVolume
  }

  get tollFreeUseCases() {
    return complianceStore.tollFreeUseCases
  }

  get tollFreeUnverifiedNumbers() {
    return complianceStore.unverifiedTollFreeNumbers
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([
      usersStore.initPromise,
      subscriptionStore.initPromise,
      complianceStore.fetchSummary(),
      complianceStore.fetchOrganizationBusinessProfile(),
      complianceStore.fetchComplianceServicesList(),
      complianceStore.fetchOrganizationTollFree(),
      complianceStore.fetchOrganizationVoiceIntegrity(),
      complianceStore.fetchOrganizationShakenStir(),
      billingStore.fetchBillingComplianceInfo(),
      complianceStore.cnamStore.fetchOrganizationCnam(),
      numbersStore.fetchUnnassignedNumbers(),
    ])

    complianceStore.getUnverifiedTollFreeNumbers()

    runInAction(() => {
      this._loading = false
    })
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setBusinessInformation = (businessInformation: IResponseBusinessInformation | null) => {
    this.organizationBusinessProfile?.setBusinessInformation(businessInformation)
  }

  setOrganizationBusinessProfile = (data: OrganizationBusinessProfile | null) => {
    if (!data) return

    complianceStore.setOrganizationBusinessProfile(data)
  }

  setSummary = (summary: Summary | null) => {
    if (!summary) return

    complianceStore.setSummary(summary)
  }

  set10DLCServiceCampaign = (data: IResponseComplianceServicesCampaign) => {
    this.complianceServicesList?.syncCampaign(data)
  }

  set10DLCServiceBrand = (data: IResponseComplianceServicesBrand) => {
    this.complianceServiceBrand?.syncData(data)
  }

  getCampaignData = async () => {
    await complianceStore.fetchComplianceServicesList()
  }

  loadComplianceTollFreeFormFields = async () => {
    await complianceStore.loadTollFreeFormFields()
  }

  handleClose = (modalId: string) => {
    modalStore.removeModal(modalId)
  }

  openDoNotHaveEINModal = () => {
    const { isUsaLocation } = this.businessInformation || {}
    const title = isUsaLocation ? 'No United States EIN?' : 'No Canadian Business Number?'
    const desc = isUsaLocation
      ? 'If you don’t have a United States EIN, your only eligible option is to use toll-free numbers'
      : 'If you don’t have a Canadian Business Number, your only eligible option is to use toll-free numbers'

    modalStore.addModal({
      id: this._modalIdDoNotHaveEI,
      type: ModalTypeList.INFO,
      onClose: () => {
        this.handleClose(this._modalIdDoNotHaveEI)
      },
      title,
      desc,
      primaryAction: {
        text: 'Continue with toll-free',
        onAction: async () => {
          uiStore.changeRouteViaVue({
            path: `${CompliancePath.compliance.root}/${TollFreeRoutes.root}`,
          })
          modalStore.removeModal(this._modalIdDoNotHaveEI)
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => modalStore.closeModal(this._modalIdDoNotHaveEI),
      },
    })
  }
}
