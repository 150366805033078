import { INTEGRATION_PREMIUM_DOWNLOAD_LINK } from 'shared/constants/links'
import type {
  IGetIntegrationVersionResponse,
  IIntegrationOrganizationSettingType,
  ISalesForceSettingsDict,
} from 'entities/Integrations'
import { PROPERTY_PHONE_TOOLTIP } from 'pages/settings/pages/integrations/pages/integrationSettings/lib'
import type { SettingsPropertyItemType } from 'pages/settings/pages/integrations/pages/integrationSettings/model/types'

type IConnectionType = {
  id: IIntegrationOrganizationSettingType
  label: string
  downloadLink: string
  connectionLink: string
  settingsLink: string
  versionKey?: keyof IGetIntegrationVersionResponse
}

export const SALESFORCE_CONNECTION_CONFIG: IConnectionType[] = [
  {
    id: 'premium',
    label: 'Salesforce Premium',
    downloadLink: INTEGRATION_PREMIUM_DOWNLOAD_LINK,
    connectionLink: '',
    settingsLink: '',
  },
  {
    id: 'free',
    label: 'Salesforce Freemium',
    downloadLink: '',
    connectionLink: '',
    settingsLink: '',
    versionKey: 'installation_url',
  },
  {
    id: 'sandbox',
    label: 'Salesforce Sandbox',
    downloadLink: '',
    connectionLink: '',
    settingsLink: '',
    versionKey: 'installation_url_sandbox',
  },
]

export const SALESFORCE_SYNC_CHECKBOX_KEYS: (keyof ISalesForceSettingsDict)[] = [
  'update_contacts',
  'create_non_existing_contacts',
]

export const SALESFORCE_BANNED_IDS = ['firstname', 'lastname', 'email', 'phone']
const CUSTOM_PHONE_TOOLTIP =
  'This field takes priority over mobile and phone number when creating contacts'

export const SALESFORCE_PROPERTIES: SettingsPropertyItemType<string, ISalesForceSettingsDict>[] = [
  {
    id: 'number',
    fieldName: 'Phone',
    integrationName: '',
    customSettingId: 'phone_number_priority',
    value: null,
    disabled: false,
    type: 'default',
    tooltip: PROPERTY_PHONE_TOOLTIP,
  },
  {
    id: 'first_name',
    fieldName: 'First name',
    integrationName: '',
    value: 'First name',
    disabled: true,
    type: 'default',
  },
  {
    id: 'last_name',
    fieldName: 'Last name',
    integrationName: '',
    value: 'Last name',
    disabled: true,
    type: 'default',
  },
  {
    id: 'email',
    fieldName: 'Email',
    integrationName: '',
    value: 'Email',
    disabled: true,
    type: 'default',
  },
]

export const SALESFORCE_CONDITIONAL_PROPERTIES: SettingsPropertyItemType<
  string,
  ISalesForceSettingsDict
>[] = [
  {
    id: 'lead_custom_phone_number',
    fieldName: 'Lead',
    integrationName: '',
    customSettingId: 'lead_custom_phone_number',
    value: null,
    disabled: false,
    type: 'default',
    tooltip: CUSTOM_PHONE_TOOLTIP,
  },
  {
    id: 'contact_custom_phone_number',
    fieldName: 'Contact',
    integrationName: '',
    customSettingId: 'contact_custom_phone_number',
    value: null,
    disabled: false,
    type: 'default',
    tooltip: CUSTOM_PHONE_TOOLTIP,
  },
]
