import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket/store'
import { inboxesStore } from 'entities/Inbox'
import type { IResponseInboxCombine } from 'entities/Inbox/api/types'

const InboxUpdated = (data: IResponseInboxCombine) => {
  inboxesStore.addItem(data)
}

export const useEventInboxUpdate = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.InboxUpdated, InboxUpdated)
    }
  }, [websocket.isConnected])
}
