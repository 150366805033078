// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C3lDl3cn9G7c9vkBqMSL{width:100%;display:flex;justify-content:space-between;align-items:center;gap:12px}.C3lDl3cn9G7c9vkBqMSL .XDpQZXfYarp_sPnHAT6V{display:flex;align-items:center}.C3lDl3cn9G7c9vkBqMSL .YE53_tvDudUqV1n3Z6Mg{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/widgets/Record/ui/RecordActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CACA,4CACE,YAAA,CACA,kBAAA,CAEF,4CACE,YAAA,CACA,OAAA","sourcesContent":[".actions{\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 12px;\n  .flex{\n    display: flex;\n    align-items: center;\n  }\n  .row8{\n    display: flex;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "C3lDl3cn9G7c9vkBqMSL",
	"flex": "XDpQZXfYarp_sPnHAT6V",
	"row8": "YE53_tvDudUqV1n3Z6Mg"
};
export default ___CSS_LOADER_EXPORT___;
