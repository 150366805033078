// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K1ol_vscoyjtgRd0wvQ3 .VMxRDY_5H6tm0vs0PhDF{max-width:240px;padding:11px 8px 11px 12px}.K1ol_vscoyjtgRd0wvQ3 .VMxRDY_5H6tm0vs0PhDF>div{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.K1ol_vscoyjtgRd0wvQ3 .OC4haRnxBqqO7m4c8K1m{padding:12px}.K1ol_vscoyjtgRd0wvQ3 .ot2NqhwNPJVypzr2l6_f{padding:8px;padding-right:9px;min-height:40px;align-items:center}.K1ol_vscoyjtgRd0wvQ3 .p9yajUQaPUZN3LeArUTG{margin:8px 0 24px}", "",{"version":3,"sources":["webpack://./src/pages/settings/pages/compliance/ui/CommonBusinessInfo/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,eAAA,CACA,0BAAA,CAEA,gDACE,aAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAIJ,4CACE,YAAA,CAGF,4CACE,WAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAGF,4CACE,iBAAA","sourcesContent":[".wrap {\n  .businessSelect {\n    max-width: 240px;\n    padding: 11px 8px 11px 12px;\n\n    & > div {\n      display: block;\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n\n  .selectPadding {\n    padding: 12px;\n  }\n\n  .selectRegionsPadding {\n    padding: 8px;\n    padding-right: 9px;\n    min-height: 40px;\n    align-items: center;\n  }\n\n  .noEmailCheckbox {\n    margin: 8px 0 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "K1ol_vscoyjtgRd0wvQ3",
	"businessSelect": "VMxRDY_5H6tm0vs0PhDF",
	"selectPadding": "OC4haRnxBqqO7m4c8K1m",
	"selectRegionsPadding": "ot2NqhwNPJVypzr2l6_f",
	"noEmailCheckbox": "p9yajUQaPUZN3LeArUTG"
};
export default ___CSS_LOADER_EXPORT___;
