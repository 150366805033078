import { forwardRef, Ref, CSSProperties } from 'react'
import classNames from 'classnames'
import { getAriaLabel } from 'shared/lib'
import icons, { IIconsVector } from './icons'
import styles from './styles.module.scss'

export type IIconProps = {
  icon?: IIconsVector
  count?: number
  fontSize?: number
  size?: number
  insideBox?: boolean
  boxRounded?: boolean
  tertiary?: boolean
  color?: string
  animation?: boolean
  padding?: number | string
  className?: string
  disabled?: boolean
}

export const IconRef = (props: IIconProps, ref: Ref<HTMLDivElement>) => {
  const icon = props.icon || 'logoSmallBlue'
  const fontSize = props.fontSize || 16
  const count = props.count && (props.count > 99 ? '99+' : props.count)
  const color = props.color
  const padding = props.padding
  const stylesProps: CSSProperties = {
    fontSize,
    padding,
    boxSizing: 'content-box',
  }
  const ariaLabel = 'Icon'

  if (props.color) stylesProps.color = `${color}`

  return (
    <div
      ref={ref}
      className={classNames(
        styles.wrap,
        {
          [styles.tertiary]: props.tertiary,
          [styles.box]: props.insideBox,
          [styles.boxRounded]: props.boxRounded,
        },
        props.className
      )}
      style={stylesProps}
      aria-label={getAriaLabel(ariaLabel)}
    >
      {Boolean(count) && (
        <div className={styles.count} aria-label={getAriaLabel(ariaLabel, 'count')}>
          {count}
        </div>
      )}

      <div
        className={classNames(
          styles.icon,
          props.animation && styles.animation,
          props.disabled && styles.disabled
        )}
        aria-label={getAriaLabel(ariaLabel, 'content')}
      >
        {icons[icon]}
      </div>
    </div>
  )
}

export const Icon = forwardRef(IconRef)
