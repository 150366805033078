// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aoeDHGjC2CPSKzXcFhdr{height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.aoeDHGjC2CPSKzXcFhdr .RgBbDr5zjuWKuOvz5xoa{width:80px;height:80px;border-radius:100px;background:var(--red-10);display:flex;align-items:center;justify-content:center}.aoeDHGjC2CPSKzXcFhdr .RgBbDr5zjuWKuOvz5xoa .lc_MOpmYUKshOv3EY8J0{width:32px;height:32px;border-radius:12px;background:var(--red-60)}", "",{"version":3,"sources":["webpack://./src/widgets/ResetPassword/ui/ExpiredFrom/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,4CACE,UAAA,CACA,WAAA,CACA,mBAAA,CACA,wBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,kEACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,wBAAA","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  .iconWrap {\n    width: 80px;\n    height: 80px;\n    border-radius: 100px;\n    background: var(--red-10);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    .icon {\n      width: 32px;\n      height: 32px;\n      border-radius: 12px;\n      background: var(--red-60);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "aoeDHGjC2CPSKzXcFhdr",
	"iconWrap": "RgBbDr5zjuWKuOvz5xoa",
	"icon": "lc_MOpmYUKshOv3EY8J0"
};
export default ___CSS_LOADER_EXPORT___;
