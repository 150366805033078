// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b_igkr6PuVs7ND74fgHA{padding:4px;display:flex;gap:4px;align-items:center}.Ql_sVJX_PS0Kc_WHyFJM{background:var(--border-neutral-primary);width:1px;height:16px;margin-inline:2px}", "",{"version":3,"sources":["webpack://./src/entities/ShortLink/ui/ShortenLinkTooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEF,sBACE,wCAAA,CACA,SAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".wrap {\n  padding: 4px;\n  display: flex;\n  gap: 4px;\n  align-items: center;\n}\n.divider{\n  background: var(--border-neutral-primary);\n  width: 1px;\n  height: 16px;\n  margin-inline: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "b_igkr6PuVs7ND74fgHA",
	"divider": "Ql_sVJX_PS0Kc_WHyFJM"
};
export default ___CSS_LOADER_EXPORT___;
