import { observer } from 'mobx-react-lite'
import { EmptyState } from 'shared/ui'
import { links } from 'shared/constants/links'
import { ChatbotNewButton } from 'pages/chatbot/ui'

import styles from './styles.module.scss'

export const ChatbotListEmpty = observer(() => {
  return (
    <EmptyState
      iconProps={{ icon: 'chatBot', color: 'var(--content-neutral-primary)' }}
      title='Create your first Textbot'
      text={
        <>
          Review our{' '}
          <a
            className={styles.guidelineLink}
            href={links.chatbotGuidelines}
            target={'_blank'}
            rel='noreferrer'
          >
            Texbot guidelines
          </a>
        </>
      }
      actions={<ChatbotNewButton />}
    />
  )
})
