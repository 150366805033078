import React, { useMemo, useRef, useState } from 'react'
import parse from 'html-react-parser'
import { Icon, Tooltip, Typography } from 'shared/ui'
import { CannedMessage } from 'entities/CannedMessages/model/CannedMessage'
import { savedRepliesStore } from 'widgets/SavedReplies'
import { useMergeFields } from 'widgets/MergeField'
import { Attachments, textToHtml } from 'widgets/MessageField'
import styles from './styles.module.scss'

export const SavedReplySelectedItem = () => {
  const { selectedSavedReply } = savedRepliesStore
  const [trigger, setTrigger] = useState<HTMLDivElement | null>(null)
  const ref = useRef(null)

  const message = useMemo(() => {
    if (selectedSavedReply?.message) {
      return parse(
        textToHtml({
          text: selectedSavedReply.message,
          replaceNewRow: true,
        })
      )
    }
  }, [selectedSavedReply])

  const { onHideMergeFieldsTooltips, mergeFieldTooltipComponent } = useMergeFields<CannedMessage>({
    trigger,
    updateTriggerDeps: [selectedSavedReply, trigger],
  })

  const onScroll = () => {
    onHideMergeFieldsTooltips()
  }

  return (
    <div className={styles.wrap} ref={ref}>
      <div className={styles.wrapContent} ref={setTrigger}>
        {mergeFieldTooltipComponent}
        <Typography
          variant={'body-rg-medium'}
          className={styles.title}
          tag={'div'}
          ariaLabel={'title'}
        >
          {selectedSavedReply?.title}
          {selectedSavedReply?.public && selectedSavedReply?.isMyReply && (
            <Tooltip className={styles.titleIcon} placement='top' label={'Shared with everyone'}>
              <Icon icon={'forward'} tertiary fontSize={16} />
            </Tooltip>
          )}
          {!selectedSavedReply?.isMyReply && (
            <Tooltip className={styles.titleIcon} placement='top' label={'Shared by teammates'}>
              <Icon icon={'users'} tertiary fontSize={16} />
            </Tooltip>
          )}
        </Typography>

        {!!selectedSavedReply?.attachments?.length && (
          <div className={styles.wrapAttachments}>
            <Attachments attachments={selectedSavedReply.attachments} />
          </div>
        )}
        <Typography
          variant={'body-rg-regular'}
          className={styles.message}
          tag={'div'}
          onScroll={onScroll}
          ariaLabel={'message'}
        >
          {message}
        </Typography>
      </div>
    </div>
  )
}
