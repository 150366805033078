// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WscsygioKupZaOcF0NEV{padding:10px}.vUq5OwU5jXwV0nfoeX5i{color:var(--gray-60);font-size:12px;font-weight:400;padding:8.5px 0;display:flex;line-height:15px;justify-content:center}", "",{"version":3,"sources":["webpack://./src/widgets/NewPropertiesDropdown/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,oBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,gBAAA,CACA,sBAAA","sourcesContent":[".loader {\n  padding: 10px;\n}\n\n.noResult {\n  color: var(--gray-60);\n  font-size: 12px;\n  font-weight: 400;\n  padding: 8.5px 0;\n  display: flex;\n  line-height: 15px;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "WscsygioKupZaOcF0NEV",
	"noResult": "vUq5OwU5jXwV0nfoeX5i"
};
export default ___CSS_LOADER_EXPORT___;
