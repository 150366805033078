import { capitalize } from 'lodash'
import { IDropdownItem, ITabItem } from 'shared/ui'
import { days, IDays, longShortDayMap } from 'shared/lib'

export type IMonthDaySelectorType = 'each' | 'onThe'

export const monthDaySelectorTabs: ITabItem<IMonthDaySelectorType>[] = [
  {
    name: 'Each',
    key: 'each',
  },
  {
    name: 'On the',
    key: 'onThe',
  },
]

const shortOrdinal = {
  first: '1st',
  second: '2nd',
  third: '3rd',
  fourth: '4th',
  fifth: '5th',
  last: 'last',
}

const monthOrdinalIds = ['first', 'second', 'third', 'fourth', 'fifth', 'last'] as const

export const monthOrdinalItemsMap = new Map(
  monthOrdinalIds.map((id) => [
    id,
    {
      id,
      label: capitalize(id),
      separator: id === 'fifth',
      short_label: shortOrdinal[id] || capitalize(id),
    },
  ])
)

export const monthOrdinalItems: IDropdownItem[] = Array.from(monthOrdinalItemsMap.values())

export type IMonthOrdinal = (typeof monthOrdinalIds)[number]

const dayOfWeekIds = [...days, 'day', 'weekday'] as const

export const dayOfWeekItemsMap = new Map(
  dayOfWeekIds.map((id) => [
    id,
    {
      id,
      label: capitalize(id),
      separator: id === 'saturday',
      short_label: longShortDayMap.get(id as IDays) || id,
    },
  ])
)

export const dayOfWeekItems = Array.from(dayOfWeekItemsMap.values())

export type IDayOfWeek = (typeof dayOfWeekIds)[number]
