import { ReactNode, FC } from 'react'
import { Icon } from 'shared/ui/Icon'
import styles from './styles.module.scss'

export type ITooltipSharedLinkProps = {
  label?: ReactNode
}

export const TooltipSharedLink: FC<ITooltipSharedLinkProps> = ({ label }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.wrapIcon}>
        <Icon icon={'link'} />
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  )
}
