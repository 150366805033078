import { useEffect } from 'react'
import type { ITextFieldMainAction } from 'shared/ui/TextField'

type ITextFieldAutoFocus = {
  withFocus?: boolean
  focusTrigger?: string
}

export const makeTextFieldAutoFocus: (action?: ITextFieldAutoFocus) => ITextFieldMainAction = (
  action
) => {
  const useAction: ITextFieldMainAction = (control, children) => {
    useEffect(() => {
      const input = control.inputRef?.current

      if (!input) return

      if (action?.withFocus) input.focus()
    }, [action?.withFocus])

    useEffect(() => {
      const input = control.inputRef?.current

      if (!input) return

      if (action?.focusTrigger) input.focus()
    }, [action?.focusTrigger])

    return children
  }

  return useAction
}
