import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import { Icon } from 'shared/ui/Icon'
import { Typography } from 'shared/ui/Typography'
import styles from 'shared/ui/Menu/MenuItemVue/styles.module.scss'
import { uiStore } from 'shared/store/uiStore'
import { navLinkVueStore } from 'shared/ui/NavLinkVue/store/navLinkVueStore'
import { type IIconsVector } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'

export type IMenuItemVueProps = {
  type: 'nav'
  to: string
  label: JSX.Element | string
  icon: IIconsVector
  onClick?: () => void
  checkActive?: (pathname: string) => void
}

export const MenuItemVue = observer((item: IMenuItemVueProps) => {
  const { pathname } = useLocation()
  const [isActive, setIsActive] = useState(false)
  const { to, label, icon } = item
  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()

    uiStore.changeRoute({
      path: to,
      type: 'vue',
    })

    if (!uiStore.routeBlocker) {
      navLinkVueStore.changeRoute(to)
    }
  }

  useEffect(() => {
    navLinkVueStore.changeRoute(window.location.pathname)
  }, [])

  useEffect(() => {
    setIsActive(pathname.includes(to))
  }, [pathname])

  return (
    <a
      onClick={onClick}
      href={to}
      className={classNames(
        styles.itemWrap,
        {
          [styles.active]: isActive,
          // is_active class is for auto testing
          is_active: isActive,
        },
        { [styles.nav]: item.type === 'nav' },
        styles.nav
      )}
      aria-label={getAriaLabel('MenuItemVue')}
    >
      <Icon icon={icon} fontSize={16} tertiary />
      {typeof label === 'string' ? (
        <Typography
          variant={'body-md-regular'}
          pointer
          className={classNames(styles.titleWrap)}
          ariaLabel={'label'}
        >
          {label}
        </Typography>
      ) : (
        label
      )}
    </a>
  )
})
