// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QJm7HECEjd6KT1aURccd{display:flex;align-items:center;justify-content:space-between;height:40px;padding:8px 2px 8px 12px;box-sizing:border-box;border-radius:6px;background:var(--background-neutral-subtle);margin-bottom:3px}.QJm7HECEjd6KT1aURccd:last-of-type{margin-bottom:0}.VYAcUmfrvssw6enD5VtI{display:flex;align-items:center}.AuNmRHmaBOGDYT0sMttY{margin-right:7px}.jgG_wb0iyp8iOU5UdKcf{margin-left:8px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageCard/ui/Mentions/MentionItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CACA,wBAAA,CACA,qBAAA,CACA,iBAAA,CACA,2CAAA,CACA,iBAAA,CAEA,mCACE,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CAUF,sBACE,eAAA","sourcesContent":[".card {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 40px;\n  padding: 8px 2px 8px 12px;\n  box-sizing: border-box;\n  border-radius: 6px;\n  background: var(--background-neutral-subtle);\n  margin-bottom: 3px;\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n\n  &__box {\n    display: flex;\n    align-items: center;\n  }\n\n  &__avatar {\n    margin-right: 7px;\n  }\n\n  &__name {\n  }\n\n  &__button {\n\n  }\n\n  &__delete {\n    margin-left: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "QJm7HECEjd6KT1aURccd",
	"card__box": "VYAcUmfrvssw6enD5VtI",
	"card__avatar": "AuNmRHmaBOGDYT0sMttY",
	"card__delete": "jgG_wb0iyp8iOU5UdKcf"
};
export default ___CSS_LOADER_EXPORT___;
