import { useEffect } from 'react'
import { ITextFieldMainAction } from 'shared/ui/TextField'

export const makeTextFieldBlurOnEnter: () => ITextFieldMainAction = () => {
  const useAction: ITextFieldMainAction = (control, children) => {
    useEffect(() => {
      const input = control.inputRef?.current

      if (!input) return

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code !== 'Enter') return

        event.preventDefault()

        const syntheticEvent = new Event('focusout', {
          bubbles: true,
          cancelable: true,
        })

        input.dispatchEvent(syntheticEvent)
      }

      input.addEventListener('keydown', handleKeyDown, true)

      return () => input.removeEventListener('keydown', handleKeyDown, true)
    }, [])

    return children
  }

  return useAction
}
