import React, { useMemo, FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography, Table, type IColumn, type ITableProps, Icon } from 'shared/ui'
import { makeTextFieldClear, makeTextFieldIcon, TextField } from 'shared/ui/TextField'
import { TitledRow } from 'shared/ui/TitledRow/TitledRow'
import type { NumberTableStore } from 'pages/settings/pages/numbers/ui/NumbersTable/store/NumberTableStore'
import { NumbersStatus } from 'pages/settings/pages/numbers/ui/NumbersStatus/NumbersStatus'
import type { NumberTableModel } from 'pages/settings/pages/numbers/ui/NumbersTable/model/NumberTableModel'
import styles from './styles.module.scss'

type INumbersTableProps = {
  numberTableStore: NumberTableStore
  hiddenColumns?: string[]
} & Omit<ITableProps<NumberTableModel>, 'columns' | 'rows'>

export const NumbersTable: FC<INumbersTableProps> = observer(
  ({ numberTableStore, hiddenColumns, ...tableProps }) => {
    const { numbers, status, tableStore, onSort } = numberTableStore
    const [search, onSearchChange] = useState<string>('')

    const filteredRows = useMemo(() => {
      if (!search) return numbers
      const localSearch = search.toLocaleLowerCase()
      return numbers.filter(({ nationalNumber }) => nationalNumber.includes(localSearch))
    }, [numbers, search])

    useEffect(() => {
      tableStore.resetSelected()
    }, [tableStore, filteredRows.length])

    const COLUMNS = useMemo(() => {
      const rawColumns: IColumn<NumberTableModel>[] = [
        {
          isSortable: true,
          field: 'nationalNumber',
          name: 'Number',
          isDisabledChangeVisible: true,
          renderRowCell: ({ info, isAircall }) => (
            <div className={styles.row}>
              <TitledRow
                title={info.title}
                label={info.label}
                tooltip={info.tooltip}
                subtitle={isAircall ? <Icon icon={'aircall'} /> : undefined}
              />
            </div>
          ),
        },
        {
          isSortable: true,
          field: 'inboxName',
          name: 'Inbox',
          isDisabledChangeVisible: true,
          renderRowCell: ({ itemInbox }) => (
            <div className={styles.row}>
              <a
                href={`/settings/organization/inboxes/all-inboxes/${itemInbox?.id}`}
                rel='noreferrer'
                className={styles.link}
              >
                <Icon icon={'inboxOpen'} tertiary />
                <Typography
                  ariaLabel='TermsAndPrivacy_link'
                  variant='body-md-regular'
                  className={styles.learnMoreText}
                  tooltipProps={{
                    label: itemInbox?.name,
                    placement: 'top',
                  }}
                  ellipsis
                >
                  {itemInbox?.name}
                </Typography>
              </a>
            </div>
          ),
        },
        {
          isSortable: true,
          field: 'status',
          name: 'Status',
          renderRowCell: ({ status }) => (
            <div className={styles.row}>
              <NumbersStatus status={status || ''} />
            </div>
          ),
        },
        {
          isSortable: true,
          field: 'labelType',
          name: 'Type',
          renderRowCell: ({ labelType }) => (
            <div className={styles.row}>
              <div>{labelType}</div>
            </div>
          ),
        },
      ]

      return hiddenColumns
        ? rawColumns.filter((column) => !hiddenColumns.includes(column.field))
        : rawColumns
    }, [hiddenColumns])

    return (
      <div className={styles.root}>
        <TextField
          size={'medium'}
          onChange={onSearchChange}
          variant={'integrated'}
          value={search}
          InputProps={{
            placeholder: 'Search...',
            className: styles.search,
          }}
          leftActions={[
            makeTextFieldIcon({
              icon: 'search',
              fontSize: 18,
              color: 'var(--content-primary-tertiary)',
              margin: '0 0 0 4px',
            }),
          ]}
          rightActions={[makeTextFieldClear()]}
        />

        <div className={styles.table}>
          <Table
            isLoading={status.isLoading}
            store={tableStore}
            columns={COLUMNS}
            rows={filteredRows}
            noPagination
            pinnedColumnNames={['number_name']}
            rowsCount={filteredRows.length}
            bottomTableContent={
              !status.isLoading &&
              !filteredRows.length && (
                <tr>
                  <td colSpan={4}>
                    <div className={styles.emptySearch}>
                      <Typography
                        variant={'body-md-regular'}
                        ellipsis
                        ariaLabel={'empty_placeholder'}
                        color={'tertiary'}
                      >
                        No numbers found
                      </Typography>
                    </div>
                  </td>
                </tr>
              )
            }
            onSort={onSort}
            {...tableProps}
          />
        </div>
      </div>
    )
  }
)
