import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IconButton } from 'shared/ui'
import { ZIndex } from 'shared/constants/zIndex'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import styles from './styles.module.scss'

type ICallVoicemailDropButtonProps = {
  fullWidth?: boolean
}

export const CallVoicemailDropButton: FC<ICallVoicemailDropButtonProps> = observer(
  ({ fullWidth = false }) => {
    const store = useCallPopUpDefaultContext()

    const { handleVoicemailDropContent } = store

    return (
      <div className={styles.wrap}>
        <IconButton
          size={'large'}
          color={'red'}
          variant={'custom'}
          active={true}
          fullWidth={fullWidth}
          icon={'voicemail'}
          onClick={() => {
            handleVoicemailDropContent()
          }}
          tooltipProps={{
            zIndex: ZIndex.OVERLAY_TOP_1,
            label: 'Voicemail drop',
            placement: 'top',
          }}
          ariaLabel='CallVoicemailDropButton'
        />
      </div>
    )
  }
)
