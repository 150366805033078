import { type User } from 'entities/Users/model/User'
import { type UserOwner } from 'entities/Users/model/UserOwner'

export enum EnumUserRoles {
  VIEW_ONLY = 'view_only',
  MEMBER = 'member',
  MANAGER = 'manager',
  ADMIN = 'admin',
  OWNER = 'owner',
}

export type IUser = User | UserOwner
