import { FC } from 'react'
import { Outlet } from 'react-router'
import { IconButton, Typography } from 'shared/ui'
import { ProgressSteps } from 'shared/ui/ProgressSteps'
import { useLastPathSegment } from 'shared/hooks'
import { RootLayout } from 'pages/chatbot/ui'
import { AgentNewDialog } from 'pages/chatbot/ui/ChatbotDialog/DialogWrapper'
import styles from './styles.module.scss'

type IStepsCommonLayoutProps = {
  progressTitle: string
  totalSteps: number
  settingsStep: number
  decreaseStep: (lastPathSegment: string) => void
}

export const StepsCommonLayout: FC<IStepsCommonLayoutProps> = ({
  progressTitle,
  settingsStep,
  totalSteps,
  decreaseStep,
}) => {
  const lastPathSegment = useLastPathSegment()

  return (
    <RootLayout>
      <div className={styles.content}>
        <div className={styles.content__sub}>
          <div className={styles.titleWrap}>
            <Typography
              className={styles.titleWrap__title}
              ariaLabel='stepsPage_title'
              variant='body-xs-regular'
              tag={'div'}
            >
              {`Step ${settingsStep}`}
            </Typography>
            {settingsStep > 1 && (
              <div className={styles.backButton}>
                <IconButton
                  color='tertiary'
                  variant='icon'
                  ariaLabel='back_to_lastStep'
                  icon='arrowLeft'
                  size='medium'
                  onClick={() => decreaseStep(lastPathSegment)}
                />
              </div>
            )}
          </div>
          <div className={styles.localProgress}>
            <ProgressSteps title={progressTitle} total={totalSteps} step={settingsStep} />
          </div>

          <div className={styles.section}>
            <Outlet />
          </div>
        </div>
      </div>
      <AgentNewDialog />
    </RootLayout>
  )
}
