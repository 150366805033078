import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import { IResponseCustomPrices } from 'entities/Admin'
import {
  ICustomPriceCreateForm,
  ICustomPriceUpdateForm,
  IResponseCreateCustomPriceResponse,
  IResponseCustomPrice,
} from 'entities/Admin/customPrices/api/type'

class Api {
  getCustomPrices(): Promise<AxiosResponse<IResponseCustomPrices>> {
    return baseApi.get('admin/core/custom-price')
  }

  removeCustomPrices(id: number): Promise<void> {
    return baseApi.delete(`admin/core/custom-price/${id}/`)
  }

  saveCustomPrice(
    data: ICustomPriceCreateForm
  ): Promise<AxiosResponse<IResponseCreateCustomPriceResponse>> {
    return baseApi.post('admin/core/custom-price/', data)
  }

  publishCustomPrice(id: number): Promise<void> {
    return baseApi.post(`admin/core/custom-price/${id}/publish`)
  }

  getCustomPrice(id: number): Promise<AxiosResponse<IResponseCustomPrice>> {
    return baseApi.get(`admin/core/custom-price/${id}`)
  }

  updateCustomPrice(
    id: number,
    data: ICustomPriceUpdateForm | ICustomPriceCreateForm
  ): Promise<AxiosResponse<IResponseCustomPrice>> {
    return baseApi.put(`admin/core/custom-price/${id}`, data)
  }
}

export const AdminCustomPriceApi = new Api()
