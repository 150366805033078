import React from 'react'
import { IStatusConfig, Status } from 'shared/ui/Status'
import { IDropdownItem } from 'shared/ui'

export function getDropdownItemsFromStatusConfig(config: IStatusConfig): IDropdownItem[] {
  return Object.entries(config).map(([key, item]) => ({
    id: key,
    label: item.title,
    labelContent: <Status {...item} />,
  }))
}
