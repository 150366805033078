import { makeAutoObservable, toJS } from 'mobx'

import { IParamsSendFrom } from 'entities/Inbox/api/types'
import { ChatbotActions } from './ChatbotActions'
import { ChatbotFields } from './ChatbotField'
import { ChatbotFilters } from './ChatbotFilters'
import { ChatbotSettings } from './ChatbotSettings'
import { ChatbotMessage } from './ChatbotMessage'
import { IChatbotPayload } from './type'

export class Chatbot {
  name = ''
  isActive = false
  sendFrom: null | IParamsSendFrom = null
  fields = new ChatbotFields()
  successActions = new ChatbotActions()
  fallbackActions = new ChatbotActions()
  firstMessage = new ChatbotMessage()
  settings = new ChatbotSettings()
  filters = new ChatbotFilters()

  constructor(item?: IChatbotPayload) {
    this.syncOrigin(item)

    makeAutoObservable(this)
  }

  setName(value: string) {
    this.name = value
  }

  setActive(isActive: boolean) {
    this.isActive = isActive
  }

  setSendFrom(params: IParamsSendFrom) {
    this.sendFrom = params
  }

  syncOrigin(item?: IChatbotPayload | null) {
    this.name = item?.name ?? ''
    this.isActive = item?.is_active ?? false
    this.sendFrom = item?.send_from ?? null

    this.firstMessage.syncOrigin(item?.first_message)
    this.settings.syncOrigin(item?.settings)
    this.filters.syncOrigin(item?.filters)
    this.fields.syncOrigin(item?.fields)
    this.successActions.syncOrigin(item?.success_actions)
    this.fallbackActions.syncOrigin(item?.fallback_actions)
  }

  toJSON(): IChatbotPayload {
    return toJS({
      name: this.name.trim(),
      is_active: this.isActive,
      send_from: this.sendFrom,

      fields: this.fields.toJSON(),
      first_message: this.firstMessage.toJSON(),
      settings: this.settings.toJSON(),
      filters: this.filters.toJSON(),
      success_actions: this.successActions.toJSON(),
      fallback_actions: this.fallbackActions.toJSON(),
    })
  }

  reset() {
    this.name = ''
    this.isActive = false
    this.sendFrom = null

    this.firstMessage.reset()
    this.settings.reset()
    this.filters.reset()
    this.fields.reset()
    this.successActions.reset()
    this.fallbackActions.reset()
  }
}
