// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ty2h9Vm7ScLawAnE8Nz7{display:flex;align-items:center;color:var(--red-60);font-size:11px;font-weight:400;gap:4px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsMenu/ui/SegmentModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,cAAA,CACA,eAAA,CACA,OAAA","sourcesContent":[".errorContainer {\n    display: flex;\n    align-items: center;\n    color: var(--red-60);\n    font-size: 11px;\n    font-weight: 400;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContainer": "Ty2h9Vm7ScLawAnE8Nz7"
};
export default ___CSS_LOADER_EXPORT___;
