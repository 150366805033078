// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UwrcYIqzwv8H0dBAuL8E{position:fixed;top:0;right:0;left:0;bottom:0;background:var(--black-30);display:flex;flex-direction:column;overflow:auto}", "",{"version":3,"sources":["webpack://./src/shared/ui/Modal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,KAAA,CACA,OAAA,CACA,MAAA,CACA,QAAA,CACA,0BAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA","sourcesContent":[".wrap{\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  background: var(--black-30);\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "UwrcYIqzwv8H0dBAuL8E"
};
export default ___CSS_LOADER_EXPORT___;
