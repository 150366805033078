import { ReactNode, FC } from 'react'
import styles from './styles.module.scss'

type IComplianceTableWrapperProps = {
  children: ReactNode
}

export const ComplianceTableWrapper: FC<IComplianceTableWrapperProps> = (props) => (
  <div className={styles.wrap} {...props} />
)
