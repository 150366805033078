import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { ITenDLCStatus, TenDLCStatus } from 'entities/Compliance'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import styles from './styles.module.scss'

export const TenDLCTab = observer(() => {
  const { data, onOpenDetailModal } = adminTenDLCDetailStore

  const renderNameValue = (value: string) =>
    value ? (
      <>
        {value} -{' '}
        <Typography
          variant={'body-md-regular'}
          link
          onClick={onOpenDetailModal}
          ariaLabel={'View profile'}
        >
          View profile
        </Typography>
      </>
    ) : null

  const renderStatusValue = (status: ITenDLCStatus) =>
    status ? (
      <div className={styles.wrapStatusRender}>
        <TenDLCStatus status={status as ITenDLCStatus} />
        {/* <Typography variant={'body-md-regular'}> */}
        {/*   -{'>'} (Submitted: Nov 30, 2023, Last status: Nov 30, 2023) */}
        {/* </Typography> */}
      </div>
    ) : null

  const rows: IAdminDetailCardRowProps[] = [
    {
      title: 'Business profile name',
      value: data?.business_profile_info?.business_name || '',
      renderValue: (value) => renderNameValue(String(value || '')),
    },
    {
      title: 'Business profile status',
      value: data?.business_profile_info?.status || '',
      renderValue: (value) => renderStatusValue(value as ITenDLCStatus),
    },
    {
      title: '10DLC brand name',
      value: data?.brand_info?.business_name || '',
      renderValue: (value) => renderNameValue(String(value || '')),
    },
    {
      title: '10DLC brand status',
      value: data?.brand_info?.status || '',
      renderValue: (value) => renderStatusValue(value as ITenDLCStatus),
    },
    {
      title: '10DLC package',
      value: data?.plan?.current_plan_type || '',
    },
    {
      title: '10DLC trust score',
      value: '',
    },
    {
      title: '10DLC campaign',
      value: data?.campaign?.use_case?.name || '',
      renderValue: (value) => renderNameValue(String(value || '')),
    },
    {
      title: '10DLC campaign status',
      value: data?.campaign?.status || '',
      renderValue: (value) => renderStatusValue(value as ITenDLCStatus),
    },
  ]
  return <AdminDetailCard data={rows} noHeader />
})
