import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { uiStore } from 'shared/store/uiStore'
import { TableStore } from 'shared/ui/Table'
import type {
  IBroadcastType,
  IParamsGetBroadcasts,
  IResponseBroadcast,
  IResponseGetBroadcasts,
  IResponseOneTimeBroadcast,
  IResponseRecurringBroadcastById,
} from 'entities/Broadcast/api/types'
import { BroadcastApi } from 'entities/Broadcast/api/broadcast'
import {
  BroadcastOneTime,
  BroadcastRecurring,
  BroadcastRoutesEnum,
  broadcastStore,
  type IBroadcast,
} from 'entities/Broadcast'
import { inboxesStore } from 'entities/Inbox'
import { FiltersAndSearchStore } from 'widgets/FiltersAndSearch'

export type IBroadcastsListStoreProps = {
  type: IBroadcastType
}

export class BroadcastsListStore {
  private _tableStore: TableStore<IBroadcast>
  private _filtersAndSearchStore: FiltersAndSearchStore
  private _type: IBroadcastType
  private _hideExpandColumnsIds = ['type']
  private _page = 1
  private _limit: number | null = 10
  private _total = 0
  private _search: string | null = null
  private _loading = true
  private _firstLoading = true
  private _itemsMap: Map<number, IBroadcast> = new Map()
  private _cancelTokenSource: CancelTokenSource | null = null

  constructor({ type }: IBroadcastsListStoreProps) {
    makeAutoObservable(this)
    inboxesStore.fetchInboxes()

    this._type = type
    this._tableStore = new TableStore<IBroadcast>({
      saveColumns: this.saveColumns,
      getColumns: this.getColumns(),
    })
    this._filtersAndSearchStore = new FiltersAndSearchStore({
      getFilters: () => BroadcastApi.getFilters(type).then(({ data }) => data),
    })

    reaction(
      () => this.paramsWithoutPage,
      () => {
        this._loading = true
        this._page = 1
      }
    )

    reaction(
      () => this.paramsGetItems,
      () => this.loadData(),
      {
        delay: 500,
      }
    )
  }

  get filtersAndSearchStore() {
    return this._filtersAndSearchStore
  }

  get hideExpandColumnsIds() {
    return this._hideExpandColumnsIds
  }

  get limit() {
    return this._limit
  }

  get loading() {
    return this._loading
  }

  get page() {
    return this._page
  }

  get tableStore() {
    return this._tableStore
  }

  get total() {
    return this._total
  }

  get type() {
    return this._type
  }

  get isHideFilters() {
    return this.isEmptyState || this.isNoBroadcasts || this._firstLoading
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get paramsWithoutPage(): Omit<IParamsGetBroadcasts, 'page'> {
    return {
      length: this._limit || undefined,
      type: this._type,
      search: this._filtersAndSearchStore.params.search || undefined,
      filtersList: this._filtersAndSearchStore.params.filtersList,
      sortBy: this._tableStore.sortBy,
      sortOrder: this._tableStore.sortOrder,
    }
  }

  get paramsGetItems(): IParamsGetBroadcasts {
    return {
      page: this._page,
      ...this.paramsWithoutPage,
    }
  }

  get isEmptyState() {
    return broadcastStore.isEmptyState
  }

  get isNoBroadcasts() {
    return !this._loading && !this._itemsMap.size && !this._filtersAndSearchStore.hasSearchParams
  }

  saveColumns = async (columns: string[]) => {
    try {
      await BroadcastApi.saveBroadcastsColumns({
        type: this._type,
        broadcast_fields: columns,
      })
    } catch (e) {
      console.error(e)
    }
  }
  getColumns = async () => {
    try {
      const { data } = await BroadcastApi.getBroadcastsColumns(this._type)

      if (!data.data) return

      return data.data.items
    } catch (e) {
      console.error(e)
    }
  }

  loadData = async (noLoading?: boolean) => {
    try {
      this.initCancelTokenSource()
      runInAction(() => {
        if (!noLoading) {
          this._loading = true
        }
      })
      const { data } = await BroadcastApi.getBroadcasts(this.paramsGetItems, {
        cancelToken: this._cancelTokenSource?.token,
      })
      this.setData(data)
      runInAction(() => {
        this._loading = false
      })
    } catch (e) {
      runInAction(() => {
        this._loading = e instanceof CanceledError
      })
    } finally {
      runInAction(() => {
        this._firstLoading = false
      })
    }
  }

  setData = ({ data, meta }: IResponseGetBroadcasts) => {
    this._itemsMap.clear()
    this.setItems(data)
    this._total = meta.total

    if (broadcastStore.isEmptyState && data.length) {
      broadcastStore.setIsEmptyState(false)
    } else if (
      broadcastStore.loadingEmptyState &&
      uiStore.pathName.includes(BroadcastRoutesEnum.all)
    ) {
      broadcastStore.setLoadingEmptyState(false)
    }
  }

  setItems = (items: IResponseBroadcast[]) => {
    items.forEach((item) => this.setItem(item))
  }

  setItem = (item: IResponseBroadcast) => {
    try {
      this._itemsMap.set(item.id, broadcastStore.getBroadcastByResponse(item))
    } catch (e) {
      console.error(e)
    }
  }

  initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this._page = page
    this._limit = limit
  }

  onDelete = (ids: number[]) => {
    ids.forEach((id) => {
      this._itemsMap.delete(id)
    })
    this.loadData(true)
  }

  onUpdateItem = (item: IResponseOneTimeBroadcast | IResponseRecurringBroadcastById) => {
    try {
      const findBroadcast = this._itemsMap.get(item.id)
      if (findBroadcast) {
        if (findBroadcast instanceof BroadcastOneTime) {
          findBroadcast.syncOrigin(item as IResponseOneTimeBroadcast)
        }
        if (findBroadcast instanceof BroadcastRecurring) {
          findBroadcast.syncOrigin(item as IResponseRecurringBroadcastById)
        }
      } else if ('recurring_parent_id' in item && item.recurring_parent_id) {
        const findRecurringParentBroadcast = this._itemsMap.get(item.recurring_parent_id)
        if (findRecurringParentBroadcast instanceof BroadcastRecurring) {
          findRecurringParentBroadcast.handleUpdate(item)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
}
