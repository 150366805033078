// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WoGyiACjW9lD1EE6mFgP{position:relative;display:flex}.shQMNHPBa52QwShamXIg{border-top-left-radius:6px;border-bottom-left-radius:6px}.WdbuCoNHoxI3fmI_2LjQ{width:24px;border-top-right-radius:6px;border-bottom-right-radius:6px}.WdbuCoNHoxI3fmI_2LjQ.hscbafNpmkzhnpfEMua3:before{background:var(--white-50)}.WdbuCoNHoxI3fmI_2LjQ.fGcoJMAdInGUZipBUhV6:before{background:rgba(0,0,0,0)}.WdbuCoNHoxI3fmI_2LjQ:before{content:\"\";position:absolute;left:0;width:1px;height:16px;background:var(--border-neutral-primary)}.kN9JPJL9HrggrdWJnnQs{z-index:20}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/ConversationSendAction/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CAGF,sBACE,0BAAA,CACA,6BAAA,CAGF,sBACE,UAAA,CAEA,2BAAA,CACA,8BAAA,CAGE,kDACE,0BAAA,CAIF,kDACE,wBAAA,CAIJ,6BACE,UAAA,CACA,iBAAA,CACA,MAAA,CACA,SAAA,CACA,WAAA,CACA,wCAAA,CAIJ,sBACE,UAAA","sourcesContent":[".wrap{\n  position: relative;\n  display: flex;\n}\n\n.leftBtn{\n  border-top-left-radius: 6px;\n  border-bottom-left-radius: 6px;\n}\n\n.rightBtn{\n  width: 24px;\n\n  border-top-right-radius: 6px;\n  border-bottom-right-radius: 6px;\n\n  &.primary{\n    &:before{\n      background: var(--white-50);\n    }\n  }\n  &.loading{\n    &:before{\n      background: transparent;\n    }\n  }\n\n  &:before {\n    content: '';\n    position: absolute;\n    left: 0;\n    width: 1px;\n    height: 16px;\n    background: var(--border-neutral-primary);\n  }\n}\n\n.confirmationBtnWrap {\n  z-index: 20;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "WoGyiACjW9lD1EE6mFgP",
	"leftBtn": "shQMNHPBa52QwShamXIg",
	"rightBtn": "WdbuCoNHoxI3fmI_2LjQ",
	"primary": "hscbafNpmkzhnpfEMua3",
	"loading": "fGcoJMAdInGUZipBUhV6",
	"confirmationBtnWrap": "kN9JPJL9HrggrdWJnnQs"
};
export default ___CSS_LOADER_EXPORT___;
