import { makeAutoObservable } from 'mobx'
import { PhoneApi } from 'entities/Phone'
import { NumberTableStore } from 'pages/settings/pages/numbers/ui/NumbersTable'

export class UnassignedTableStore {
  private _numberTableStore = new NumberTableStore(
    'NUMBER_TABLE_STORE_KEY_UNASSIGNED',
    PhoneApi.getUnnassignedNumbers
  )

  constructor() {
    makeAutoObservable(this)
  }

  onAssign = () => {
    const selectedIds = this._numberTableStore.tableStore.selectedIds
    // TODO: action task
    console.log('selectedIds', selectedIds)
  }

  get numberTableStore() {
    return this._numberTableStore
  }
}
