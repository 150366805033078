import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Button, Typography } from 'shared/ui'
import styles from './styles.module.scss'
import { adminTollFreeDetailStore } from '../../store/adminTollFreeDetailStore'

export const RejectContent = observer(() => {
  const [reason, setReason] = useState('')
  const { onReject } = adminTollFreeDetailStore

  const [loading, setLoading] = useState(false)

  const onSave = async () => {
    try {
      setLoading(true)
      await onReject(reason)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.wrap}>
      <Typography
        variant={'body-sm-regular'}
        color={'tertiary'}
        className={styles.label}
        ariaLabel={'Reason'}
      >
        Reason
      </Typography>
      <textarea
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        className={styles.textarea}
        placeholder={'Enter reason'}
      />
      <div className={styles.actions}>
        <Button text={'Cancel'} contained={'secondary'} />
        <Button
          text={'Save'}
          contained={'primary'}
          disabled={!reason}
          onClick={onSave}
          loading={loading}
        />
      </div>
    </div>
  )
})
