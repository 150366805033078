import { ActionItem } from 'shared/ui'
import { IResponseAdminOrganizationById } from 'entities/Admin'
import { adminOrganizationStore } from 'pages/admin/pages/organizations'

export function getComplianceAdminOrganizationsActions({
  organizationById,
}: {
  organizationById?: IResponseAdminOrganizationById
}): ActionItem[] {
  const { onAssumeOrganization, getNovaOrganizationUrl } = adminOrganizationStore
  const id = organizationById?.id
  const actions: ActionItem[] = []
  if (!id) {
    return actions
  }

  actions.push({
    text: 'Nova',
    icon: 'briefcase',
    link: getNovaOrganizationUrl(id),
    medium: true,
  })
  actions.push({
    text: 'Assume',
    icon: 'eye',
    onAction: () => onAssumeOrganization(id),
    medium: true,
  })
  if (organizationById?.links.intercom?.company) {
    actions.push({
      text: 'Intercom',
      icon: 'intercom',
      link: organizationById?.links.intercom?.company,
      medium: true,
    })
  }
  if (organizationById?.links.twilio) {
    actions.push({
      text: 'Twilio',
      icon: 'twilio',
      link: organizationById?.links.twilio,
      medium: true,
    })
  }
  return actions
}
