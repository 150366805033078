import { makeAutoObservable, toJS } from 'mobx'
import { clampNumber } from 'shared/lib/number'
import {
  ChatbotFollowUpAssertiveness,
  type IChatbotFollowUpAssertiveness,
  type IChatbotSettings,
} from './type'

export class ChatbotSettings {
  static DefaultAttempts = 10
  static MaxAttempts = 20
  static MinAttempts = 1
  static DefaultFollowUpWindow = 3
  static DefaultFollowUpAssertiveness = ChatbotFollowUpAssertiveness.Steady

  knowledgeBaseUsage = false
  maxAttempts = ChatbotSettings.DefaultAttempts
  aboutConversation = ''
  businessInformation = ''
  howToRespond = ''
  instruction = ''
  private _followUpWindow = ChatbotSettings.DefaultFollowUpWindow
  private _followUpAssertiveness: IChatbotFollowUpAssertiveness =
    ChatbotSettings.DefaultFollowUpAssertiveness

  constructor(item?: IChatbotSettings) {
    this.syncOrigin(item)

    makeAutoObservable(this)
  }

  get followUpWindow() {
    return this._followUpWindow
  }

  get followUpAssertiveness() {
    return this._followUpAssertiveness
  }

  setKnowledgeBaseUsage(isEnabled: boolean) {
    this.knowledgeBaseUsage = isEnabled
  }

  setMaxAttempts(maxAttempts: number) {
    this.maxAttempts = clampNumber(
      maxAttempts,
      ChatbotSettings.MinAttempts,
      ChatbotSettings.MaxAttempts
    )
  }

  setAboutConversation(aboutConversation: string) {
    this.aboutConversation = aboutConversation
  }

  setBusinessInformation(businessInformation: string) {
    this.businessInformation = businessInformation
  }

  setHowToRespond(howToRespond: string) {
    this.howToRespond = howToRespond
  }

  setInstruction(instruction: string) {
    this.instruction = instruction
  }

  setFollowUpWindow = (value: number) => {
    this._followUpWindow = value
  }

  setFollowUpAssertiveness = (value: IChatbotFollowUpAssertiveness) => {
    this._followUpAssertiveness = value
  }

  syncOrigin(item?: IChatbotSettings | null) {
    this.maxAttempts = item?.max_attempts?.value ?? ChatbotSettings.DefaultAttempts
    this.knowledgeBaseUsage = item?.use_knowledge_base?.value ?? false
    this.aboutConversation = item?.about_conversation?.value ?? ''
    this.businessInformation = item?.business_information?.value ?? ''
    this.howToRespond = item?.how_to_respond?.value ?? ''
    this.instruction = item?.instructions?.value ?? ''
    this._followUpWindow = item?.followup_window?.value ?? ChatbotSettings.DefaultFollowUpWindow
    this._followUpAssertiveness =
      item?.followup_assertiveness?.value ?? ChatbotSettings.DefaultFollowUpAssertiveness
  }

  toJSON(): IChatbotSettings {
    const about_conversation = this.aboutConversation
      ? {
          value: this.aboutConversation,
        }
      : null

    const business_information = this.businessInformation
      ? {
          value: this.businessInformation,
        }
      : null

    const how_to_respond = this.howToRespond
      ? {
          value: this.howToRespond,
        }
      : null

    const instructions = this.instruction
      ? {
          value: this.instruction,
        }
      : null

    return toJS({
      max_attempts: { value: this.maxAttempts },
      use_knowledge_base: { value: this.knowledgeBaseUsage },
      about_conversation,
      business_information,
      how_to_respond,
      instructions,
      followup_window: { value: this._followUpWindow },
      followup_assertiveness: { value: this._followUpAssertiveness },
    })
  }

  reset() {
    this.aboutConversation = ''
    this.businessInformation = ''
    this.howToRespond = ''
    this.maxAttempts = ChatbotSettings.DefaultAttempts
    this._followUpWindow = ChatbotSettings.DefaultFollowUpWindow
    this._followUpAssertiveness = ChatbotSettings.DefaultFollowUpAssertiveness
  }
}
