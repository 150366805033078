import type { ITrigger } from 'entities/Trigger/model/Trigger'
import { DetailsTriggerModalContext } from './context/detailsTriggerModalContext'
import { DetailsTriggerModalContent } from './DetailsTriggerModalContent'

type ITriggerDetailsModalProps = {
  trigger: ITrigger
}

export const DetailsTriggerModal = ({ trigger }: ITriggerDetailsModalProps) => {
  return (
    <DetailsTriggerModalContext.Provider value={trigger}>
      <DetailsTriggerModalContent />
    </DetailsTriggerModalContext.Provider>
  )
}
