import React, { useState } from 'react'
import { Icon, Tooltip, Typography } from 'shared/ui'
import { makeTextFieldElement, TextFieldPlaceholder } from 'shared/ui/TextField'
import { writeTextToClipboard } from 'shared/lib'
import { ITrigger } from 'entities/Trigger/model/Trigger'
import styles from './styles.module.scss'

type IWebhookTriggerModalProps = {
  trigger: ITrigger
}

export const WebhookTriggerModal = ({ trigger: { webhook } }: IWebhookTriggerModalProps) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = async () => {
    try {
      if (isCopied) return
      await writeTextToClipboard(webhook?.url || '')
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 1000)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div className={styles.modalContent}>
      <div className={styles.info}>
        <Icon icon={'infoCircle'} color='var(--light-blue-60)' />
        <div className={styles.text}>
          <Typography
            ariaLabel={'UseTriggerWebhook'}
            variant={'body-md-semibold'}
            color={'var(--light-blue-100)'}
          >
            Use this Webhook URL to:
          </Typography>
          <ul>
            <li>
              <Typography
                ariaLabel={'UseTriggerWebhookCreate'}
                variant={'body-md-regular'}
                color={'var(--light-blue-100)'}
              >
                Create a contact on your account if one doesn’t exist
              </Typography>
            </li>
            <li>
              <Typography
                ariaLabel={'UseTriggerWebhookSend'}
                variant={'body-md-regular'}
                color={'var(--light-blue-100)'}
              >
                Send trigger message to contact
              </Typography>
            </li>
          </ul>
        </div>
      </div>
      {webhook && (
        <TextFieldPlaceholder
          className={styles.linkContainer}
          size={'medium'}
          variant={'stroke'}
          placeholder={
            <Tooltip placement='top' label={webhook.url} fullWidth>
              <Typography
                className={styles.linkText}
                tag='div'
                ariaLabel='WebhookUrl'
                variant='text-input-field'
                color='var(--content-primary-primary)'
              >
                {webhook.url}
              </Typography>
            </Tooltip>
          }
          rightActions={[
            makeTextFieldElement({
              element: (
                <div onClick={handleCopy}>
                  {!isCopied && <Icon tertiary key={'copy'} icon={'copy'} />}
                  <Typography tag='span' ariaLabel='TrackConversionsDesc' variant='button-small'>
                    {isCopied ? 'Copied' : 'Copy'}
                  </Typography>
                </div>
              ),
            }),
          ]}
        />
      )}
    </div>
  )
}
