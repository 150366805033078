import { IAdminDetailCardRowProps } from 'pages/admin'

export const getRejectReasonRow = (
  isShow: boolean,
  rejectReason: string
): IAdminDetailCardRowProps[] => {
  if (isShow) {
    return [
      {
        title: 'Rejection reason',
        value: rejectReason,
        isError: true,
      },
    ]
  }
  return []
}
