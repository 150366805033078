import { makeAutoObservable } from 'mobx'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type ContactsDetailsStore } from 'widgets/ContactsDetails'

export class ContactsGroupCardStore {
  private _currentContact: Contact | null = null

  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)
  }

  get isCurrentContact() {
    return Boolean(this._currentContact)
  }

  get contacts() {
    return this._contactsDetailsStore.contacts
  }

  get currentContact() {
    return this._currentContact
  }

  handleActiveContact = (item: Contact | null) => {
    this._currentContact = item

    if (item === null) {
      item = this._contactsDetailsStore.contacts[0]
    }

    if (item) {
      this._contactsDetailsStore.setContact(item)
      this._contactsDetailsStore.contactInfoFormStore.syncFields(item.id)
    }
  }
}
