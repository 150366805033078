// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SNbdkYFG134jRj07M2Qq{display:flex;align-items:center;padding:0 0 0 8px;border-radius:6px;border:1px solid var(--border-neutral-default);background:var(--background-primary-inverted-primary);height:24px;cursor:default;margin-right:6px;margin-bottom:6px}.g37a7oOe84VIOkRUAvH4{margin-right:8px}.dBHeNBLYfYg7MwrIu1YL{color:var(--content-primary-primary);font-size:13px;white-space:nowrap;max-width:65px;overflow:hidden;text-overflow:ellipsis;margin-right:8px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationAssignModal/ui/conversationAssignSelectedItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CACA,8CAAA,CACA,qDAAA,CACA,WAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CAEA,sBACE,gBAAA,CAGF,sBACE,oCAAA,CACA,cAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,sBAAA,CACA,gBAAA","sourcesContent":[".item {\n  display: flex;\n  align-items: center;\n  padding: 0 0 0 8px;\n  border-radius: 6px;\n  border: 1px solid var(--border-neutral-default);\n  background: var(--background-primary-inverted-primary);\n  height: 24px;\n  cursor: default;\n  margin-right: 6px;\n  margin-bottom: 6px;\n\n  &__avatar {\n    margin-right: 8px;\n  }\n\n  &__name {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    white-space: nowrap;\n    max-width: 65px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    margin-right: 8px;\n  }\n\n  &__delete {\n\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "SNbdkYFG134jRj07M2Qq",
	"item__avatar": "g37a7oOe84VIOkRUAvH4",
	"item__name": "dBHeNBLYfYg7MwrIu1YL"
};
export default ___CSS_LOADER_EXPORT___;
