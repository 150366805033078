import { useMemo } from 'react'
import {
  ISelectEditValueProps,
  SelectEditValue,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/SelectEditValue/SelectEditValue'
import {
  IInputEditValueProps,
  InputEditValue,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/InputEditValue/InputEditValue'
import {
  IMultiSelectEditValueProps,
  MultiSelectEditValue,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/MultiSelectEditValue/MultiSelectEditValue'
import {
  AttachmentsEditValue,
  IAttachmentsEditValueProps,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/AttachmentsEditValue/AttachmentsEditValue'
import {
  ITextareaEditValue,
  TextareaEditValue,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/TextareaEditValue/TextareaEditValue'
import {
  ISelectValueEditValueProps,
  SelectValueEditValue,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/SelectValueEditValue/SelectValueEditValue'
import {
  ISelectStatusEditStatusProps,
  SelectStatusEditStatus,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/SelectStatusEditStatus/SelectStatusEditStatus'
import { TooltipError } from './TooltipError'
import styles from './styles.module.scss'

export type IEditValueProps = (
  | IInputEditValueProps
  | ISelectEditValueProps
  | ISelectValueEditValueProps
  | IMultiSelectEditValueProps
  | IAttachmentsEditValueProps
  | ITextareaEditValue
  | ISelectStatusEditStatusProps
) & {
  editError?: string
}

export const EditValue = (props: IEditValueProps) => {
  const { typeEdit } = props
  const content = useMemo(() => {
    if (typeEdit === 'select') {
      return <SelectEditValue {...props} />
    }
    if (typeEdit === 'selectValue') {
      return <SelectValueEditValue {...props} />
    }

    if (typeEdit === 'multiSelect') {
      return <MultiSelectEditValue {...props} />
    }

    if (typeEdit === 'attachments') {
      return <AttachmentsEditValue {...props} />
    }
    if (typeEdit === 'textarea') {
      return <TextareaEditValue {...props} />
    }

    if (typeEdit === 'input' || !typeEdit) {
      return <InputEditValue {...props} />
    }

    if (typeEdit === 'selectStatus') {
      return <SelectStatusEditStatus {...props} />
    }
  }, [typeEdit, props])

  return (
    <div className={styles.valueWrap}>
      {content}
      {props.editError && (
        <div className={styles.error}>
          <TooltipError error={props.editError} />
        </div>
      )}
    </div>
  )
}
