import { FC, useMemo, useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, type IIconsVector } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  IntegrationKey,
  integrationsStore,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IntegrationCompanyProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationCompanyProperty'
import { Actions } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/Actions'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import {
  ShowMoreAction,
  ShowMoreActionStore,
} from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { SectionTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/SectionTab/SectionTab'
import { AddPropertyAction } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/AddPropertyAction/AddPropertyAction'
import { NewPropertiesDropdownStore } from 'widgets/NewPropertiesDropdown'
import { type DraggableIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/store/draggableIntegrationStore'

type ICompanyProps = {
  integrationLabel: string
  integrationKey: IntegrationKey
  integrationIcon: IIconsVector
  companies: IntegrationCompanyProperty[]
  contact: Contact
  action?: ReactNode
  dnd?: DraggableIntegrationStore
}

export const Companies: FC<ICompanyProps> = observer(
  ({ integrationLabel, integrationKey, integrationIcon, contact, companies, action, dnd }) => {
    const showMoreActionStore = useMemo(
      () => new ShowMoreActionStore(integrationKey, EnumIntegrationTabs.Companies),
      []
    )

    const newPropertiesDropdownStore = useMemo(() => new NewPropertiesDropdownStore(), [])
    const company = companies[0] || null
    const store = useContactsIntegrationsContext()
    const integration = integrationsStore.getIntegration(integrationKey)
    const linkProps = {
      key: integrationKey,
      contact: contact,
      integration: integration,
      type: IIntegrationLinkTypes.company,
    }

    const link = (id: number | null | undefined) => {
      return integrationLink({
        ...linkProps,
        id: id,
      })
    }

    const handleView = () => {
      store.openView('companies', { companies: companies }, integrationIcon, integrationKey)
    }

    const propertiesKeys = company?.propertiesKeys || []

    useEffect(() => {
      if (!company) return

      newPropertiesDropdownStore.searchFields('', true, 'deal', company.id)
      newPropertiesDropdownStore.setConfig({
        contactId: contact.id,
        addItem: (item) => {
          company.addProperty(item)
          dnd?.addItem(item.key)
        },
        items: propertiesKeys,
      })
    }, [propertiesKeys.length, company])

    const actions = () => {
      const hasMore = companies.length > 1

      return (
        <Actions
          rows={[
            {
              columns: [
                action,
                dnd && (
                  <AddPropertyAction
                    store={newPropertiesDropdownStore}
                    key={'add_property_action'}
                    objectType={'company'}
                    objectId={company?.id}
                  />
                ),
              ],
            },
            {
              columns: [
                !showMoreActionStore.hideShowMore && (
                  <ShowMoreAction
                    key={'show_more'}
                    disabled={!contact}
                    store={showMoreActionStore}
                  />
                ),
                hasMore && (
                  <Button
                    size='small'
                    typeBtn='text'
                    text='See all companies'
                    onClick={handleView}
                  />
                ),
              ],
            },
          ]}
        />
      )
    }

    const properties = showMoreActionStore.modifyData(company?.properties || [])

    return (
      <>
        <SectionTab
          item={company}
          titleView={'View company'}
          titleEmpty={'No companies found'}
          integrationLabel={integrationLabel}
          properties={properties}
          link={link(company?.id)}
          actions={actions()}
          handleClear={(id) => {
            company?.removeProperty(id)
            dnd?.removeItem(id)
          }}
          handleReorder={(result) => {
            dnd?.updateItems(result.source.index, result.destination?.index)
          }}
          itemsDnD={dnd?.itemsUI}
        />
      </>
    )
  }
)
