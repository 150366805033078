import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Icon, SpinnerLoader, Typography } from 'shared/ui'
import { useIntegrationDropdownContext } from 'widgets/IntegrationDropdown/context/integrationDropdownContext'
import styles from '../styles.module.scss'

export const PhoneFieldTrigger = observer(() => {
  const { phoneFieldLoading, selectedPhoneField } = useIntegrationDropdownContext()

  const content = () => {
    if (phoneFieldLoading) {
      return (
        <div className={styles.loaderTrigger}>
          <SpinnerLoader size={10} indeterminatecolor={'var(--content-primary-tertiary)'} />
        </div>
      )
    }

    if (!selectedPhoneField) {
      return (
        <Typography
          className={classnames(styles.trigger__label, styles['trigger__label--placeholder'])}
          variant={'text-input-field'}
          ariaLabel={'Select inbox'}
        >
          Select phone field
        </Typography>
      )
    }

    return (
      <Typography className={styles.trigger__label} variant={'text-input-field'} ariaLabel={'name'}>
        <div className={styles.trigger__name}>{selectedPhoneField.label}</div>
      </Typography>
    )
  }

  return (
    <div className={styles.trigger}>
      <div className={styles.trigger__icon}>
        <Icon icon={'phone'} fontSize={16} />
      </div>
      {content()}
    </div>
  )
})
