import { observer } from 'mobx-react-lite'

import styles from './styles.module.scss'

interface IConversationCardProps {
  name: string
  value?: string | null
  placeholder: string
}
export const ConversationCard = observer(({ name, value, placeholder }: IConversationCardProps) => (
  <div className={styles.conversationCard}>
    <div className={styles.conversationCardRow}>
      <span className={styles.conversationCardName}>{name}</span>
      <span className={styles.conversationCardValue}>
        {value ?? <span className={styles.conversationCardPlaceholder}>{placeholder}</span>}
      </span>
    </div>
  </div>
))
