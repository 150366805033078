import React, { ReactNode, useState } from 'react'
import classNames from 'classnames'
import {
  Button,
  Dropdown,
  EnumDropdownItemVariant,
  IButtonProps,
  IDropdownItem,
  IDropdownProps,
  Typography,
} from 'shared/ui'
import {
  ITextFieldPlaceholderProps,
  makeTextFieldIcon,
  TextFieldPlaceholder,
} from 'shared/ui/TextField'
import styles from '../styles.module.scss'

export type ISelectDefaultProps<T = string> = {
  type?: 'default'
  variant?: 'stroke'
  text?: string
  placeholder?: string
  btnProps?: IButtonProps
  dropdownProps?: IDropdownProps
  value?: T
  item?: IDropdownItem | null
  autoOpen?: boolean
  onSelect?: (item: IDropdownItem) => void
  onSelectValue?: (value: T, item: IDropdownItem) => void
  items: IDropdownItem[]
  fullWidth?: boolean
  disabled?: boolean
  error?: ReactNode
  textFieldPlaceholderProps?: Partial<ITextFieldPlaceholderProps>
}

export const SelectDefault = ({
  btnProps,
  text,
  dropdownProps,
  items,
  item,
  placeholder = 'Select',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  value,
  autoOpen,
  onSelect,
  onSelectValue,
  fullWidth,
  variant,
  disabled,
  error,
  textFieldPlaceholderProps,
}: ISelectDefaultProps<typeof value>) => {
  const [ref, setRef] = useState<HTMLLabelElement | null>(null)
  const [refBtn, setRefBtn] = useState<HTMLButtonElement | null>(null)
  const dropdownItems: IDropdownItem[] = items.map((dropdownItem) => ({
    variant: EnumDropdownItemVariant.CheckedRight,
    activeValue: dropdownItem.id === value || dropdownItem.id === item?.id,
    labelWidth: '100%',
    ...dropdownItem,
  }))

  const selectedItem = item || dropdownItems?.find((item) => item.activeValue)

  const handleChange = (item: IDropdownItem) => {
    onSelect && onSelect(item)
    onSelectValue && onSelectValue(item.id, item)
  }

  let variantDropdownProps: IDropdownProps = { ariaLabel: 'Default' }
  if (variant === 'stroke') {
    const content = selectedItem?.label ? (
      <div className={styles.labelContent}>
        <Typography variant={'text-input-field'} ariaLabel={'selectedLabel'} ellipsis>
          {selectedItem?.label}
        </Typography>

        <Typography
          variant={'text-input-field'}
          color={'tertiary'}
          ariaLabel={'selectedLabelRight'}
        >
          {selectedItem?.selectedLabelRight}
        </Typography>
      </div>
    ) : (
      placeholder
    )

    variantDropdownProps = {
      ariaLabel: 'SelectDefault',
      width: Number(ref?.clientWidth) + 8,
      PopperProps: {
        modifiers: [
          {
            name: 'offset',
            options: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              offset: ({ reference }) => {
                return [-4, -reference.height - 8]
              },
            },
          },
        ],
      },
      triggerComponent: () => (
        <TextFieldPlaceholder
          ref={setRef}
          rightActions={[
            makeTextFieldIcon({
              icon: 'chevronDown',
            }),
          ]}
          placeholder={text || content}
          variant={'stroke'}
          size={'small'}
          error={error}
          {...textFieldPlaceholderProps}
          className={classNames(
            styles.textFieldPlaceholderProps,
            {
              [styles.placeholder]: !selectedItem?.label,
            },
            textFieldPlaceholderProps?.className
          )}
        />
      ),
    }
  }

  return (
    <Dropdown
      triggerComponent={(open) => (
        <Button
          ref={setRefBtn}
          text={selectedItem?.label || text || placeholder}
          textRight={
            selectedItem?.selectedLabelRight && (
              <Typography
                variant={'text-input-field'}
                color={'tertiary'}
                ariaLabel={'selectedLabelRight'}
              >
                {selectedItem?.selectedLabelRight}
              </Typography>
            )
          }
          size={'medium'}
          contained={'secondary'}
          iconProps={{ icon: 'chevronDown' }}
          isRight
          textClassname={styles.text}
          active={open}
          disabled={disabled}
          {...btnProps}
        />
      )}
      autoOpen={autoOpen}
      maxHeight={300}
      placement={'bottom-start'}
      items={dropdownItems}
      onChange={handleChange}
      withSearch
      width={fullWidth ? Number(refBtn?.clientWidth) + 8 : undefined}
      disabled={disabled}
      {...variantDropdownProps}
      {...dropdownProps}
    />
  )
}
