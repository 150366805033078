import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, IAvatarInfo, Typography } from 'shared/ui'
import { VCard } from 'entities/VCard/model/VCard'
import styles from './styles.module.scss'

type IVCardCardUiProps = {
  vCard: VCard
  maxWidthInfo?: number
  isGray?: boolean
  avatarSize?: number
  infoGap?: number
}
export const VCardCardUi: FC<IVCardCardUiProps> = observer(
  ({ vCard, isGray, maxWidthInfo, avatarSize = 32, infoGap }) => {
    const { first_name, last_name, phone, name, color } = vCard

    const avatarInfo: IAvatarInfo = useMemo(() => {
      const emptyColor = isGray ? 'var(--background-primary-inverted-primary)' : 'var(--gray-a-15)'

      return {
        firstName: first_name,
        lastName: last_name,
        phone: phone,
        icon: !name ? 'user' : undefined,
        color: name ? color : emptyColor,
      }
    }, [first_name, last_name, phone, name, color, isGray])

    return (
      <div className={styles.content}>
        <Avatar info={avatarInfo} size={avatarSize} />
        <div className={styles.info} style={{ maxWidth: maxWidthInfo, gap: infoGap }}>
          {name ? (
            <>
              <Typography variant={'body-rg-medium'} ellipsis ariaLabel={'name'}>
                {name}
              </Typography>
              <Typography variant={'body-sm-regular'} ellipsis ariaLabel={'phone'}>
                {phone}
              </Typography>
            </>
          ) : (
            <Typography variant={'body-rg-medium'} ellipsis ariaLabel={'phone'}>
              {phone}
            </Typography>
          )}
        </div>
      </div>
    )
  }
)
