// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I1XOLlqEEMXBN703hW6M{word-break:break-word;width:100%;text-align:left;margin:-2px 0}", "",{"version":3,"sources":["webpack://./src/widgets/MessageCard/ui/BodyRawContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA","sourcesContent":[".text{\n  word-break: break-word;\n  width: 100%;\n  text-align: left;\n  margin: -2px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "I1XOLlqEEMXBN703hW6M"
};
export default ___CSS_LOADER_EXPORT___;
