import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type ContactsProfileCardStore } from 'widgets/ContactsDetails/ui/ContactsProfileCard'
import { ContactsProfileCardContext } from 'widgets/ContactsDetails/ui/ContactsProfileCard/context/contactsProfileCardContext'
import { ContactsProfileCardContent } from './ContactsProfileCardContent'

type IContactProfileCardProps = {
  store: ContactsProfileCardStore
}

export const ContactsProfileCard: FC<IContactProfileCardProps> = observer(({ store }) => {
  return (
    <ContactsProfileCardContext.Provider value={store}>
      <ContactsProfileCardContent />
    </ContactsProfileCardContext.Provider>
  )
})
