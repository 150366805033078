import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import axios, { CancelTokenSource } from 'axios'
import type { PageLayoutStore } from 'shared/layout'
import { TableStore } from 'shared/ui/Table'
import type { ICardFilter } from 'shared/ui'
import type { ITrigger } from 'entities/Trigger/model/Trigger'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import {
  ContactStatistic,
  type IParamsGetContactsStatistic,
  type IResponseContactStatistic,
} from 'entities/ContactStatistic'
import { ContactsDetailsManageStore } from 'widgets/ContactsDetails/store'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'

export class TriggerDetailStore {
  contactStatisticsTableStore = new TableStore<ContactStatistic>({
    element: 'contact',
    withoutDefaultManageColumns: true,
  })
  trigger: ITrigger
  pageLayoutStore: PageLayoutStore

  isLoading = false
  scrollToTopTrigger = ''

  page = 1
  limit = 10
  total = 0
  search = ''

  filter: IParamsGetContactsStatistic['filter'] = 'sent_to'

  private _contactStatisticsMap: Map<number, ContactStatistic> = new Map<number, ContactStatistic>()
  private _contactsDetailsManageStore = new ContactsDetailsManageStore()

  private _disposeChangeParams: IReactionDisposer | null = null
  private _disposeFetchTriggerContacts: IReactionDisposer | null = null
  private _cancelTokenSource: CancelTokenSource | null = null

  constructor(trigger: ITrigger, pageLayoutStore: PageLayoutStore) {
    makeAutoObservable(this)
    this.trigger = trigger
    this.pageLayoutStore = pageLayoutStore

    void this.fetchTriggerContacts()
    void this._contactsDetailsManageStore.init()

    this.reactionChangeParams()
    this.reactionFetchTriggerContacts()
  }

  reset = () => {
    this._contactsDetailsManageStore.reset()
    this.clearReactions()
  }

  private reactionChangeParams = () => {
    this._disposeChangeParams?.()
    this._disposeChangeParams = reaction(
      () => this.paramsWithoutPage,
      () => {
        this.isLoading = true
        this.page = 1
      }
    )
  }

  private reactionFetchTriggerContacts = () => {
    this._disposeFetchTriggerContacts?.()
    this._disposeFetchTriggerContacts = reaction(() => this.params, this.fetchTriggerContacts, {
      delay: 500,
    })
  }

  private clearReactions = () => {
    this._disposeChangeParams?.()
    this._disposeFetchTriggerContacts?.()
  }

  private setData = (data: IResponseContactStatistic[], total: number) => {
    this._contactStatisticsMap.clear()
    data.forEach((contactStatisticsResponse) => {
      this._contactStatisticsMap.set(
        contactStatisticsResponse.id,
        new ContactStatistic(contactStatisticsResponse)
      )
    })

    this.total = total
  }

  private initCancelTokenSource = () => {
    if (this._cancelTokenSource) this._cancelTokenSource.cancel()

    this._cancelTokenSource = axios.CancelToken.source()
  }

  private fetchTriggerContacts = async () => {
    try {
      this.initCancelTokenSource()
      this.isLoading = true
      const {
        data: { data, total },
      } = await TriggerApi.getTriggerContactsStatistic(this.trigger.id, this.params)
      this.setData(data, total)
      runInAction(() => {
        this.scrollToTopTrigger = nanoid()
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setSearch = (value: string) => {
    this.search = value
  }

  onActiveFilter = (filter: ICardFilter<IParamsGetContactsStatistic['filter']>) => {
    this.filter = filter.key
  }

  onPaginationContactsTableChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }

  get onOpenContactsDetails(): IOpenContactsDetails {
    return {
      disabled: this._contactsDetailsManageStore.disabled,
      open: (data) => {
        this._contactsDetailsManageStore.onOpenContactDetails(
          {
            contactId: data.contactId,
            inboxId: this.trigger.inboxId,
          },
          'trigger',
          true
        )
      },
    }
  }

  onSuccessDelete = () => {
    this.contactStatisticsTableStore.unselectAllIds()
    void this.fetchTriggerContacts()
  }

  get contactsDetailsStore() {
    return this._contactsDetailsManageStore.contactsDetailsStore
  }

  get filters() {
    if (!this.statistics) return []
    const filters: ICardFilter<IParamsGetContactsStatistic['filter']>[] =
      this.statistics.getFilters('Trigger contacts')

    const relevantColumnsIds =
      this.trigger.type === 'sms'
        ? [
            'sent_to',
            'success',
            'failed',
            'opted_out',
            'revenue',
            'conversions',
            'clicked',
            'replied',
          ]
        : ['sent_to', 'success', 'failed', 'opted_out', 'replied']

    return filters.filter((column) => relevantColumnsIds.includes(column.key))
  }

  get paramsWithoutPage(): Omit<IParamsGetContactsStatistic, 'page'> {
    return {
      search: this.search,
      length: this.limit,
      filter: this.filter,
      sortBy: this.contactStatisticsTableStore.sortBy || 'created_at',
      sortOrder: this.contactStatisticsTableStore.sortOrder,
    }
  }

  get params(): IParamsGetContactsStatistic {
    return {
      page: this.page,
      ...this.paramsWithoutPage,
    }
  }

  get statistics() {
    return this.trigger.statistics
  }

  get isNoData() {
    return !this.isLoading && !this._contactStatisticsMap.size && !this.search
  }

  get contactStatisticsItems(): ContactStatistic[] {
    return Array.from(this._contactStatisticsMap.values())
  }
}
