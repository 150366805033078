import { createContext, useContext, FC, ReactNode } from 'react'
import { type ContactsQuickLinksStore } from 'widgets/ContactsDetails/ui/ContactsQuickLinks'

export const ContactsQuickLinksContext = createContext<ContactsQuickLinksStore | null>(null)

export const useContactsQuickLinksContext = () => {
  const context = useContext(ContactsQuickLinksContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ContactsQuickLinksContext')

  return context
}

type IProviderContactsQuickLinksProps = {
  value: ContactsQuickLinksStore
  children: ReactNode
}

export const ProviderContactsQuickLinks: FC<IProviderContactsQuickLinksProps> = ({
  value,
  children,
}) => {
  return (
    <ContactsQuickLinksContext.Provider value={value}>
      {children}
    </ContactsQuickLinksContext.Provider>
  )
}
