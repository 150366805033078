import React, { FC, ReactNode } from 'react'
import { Icon, ITooltipProps, Tooltip } from 'shared/ui'

export type ITooltipErrorProps = {
  error: ReactNode
  tooltipProps?: ITooltipProps
}

export const TooltipError: FC<ITooltipErrorProps> = ({ tooltipProps, error }) => {
  if (!error) {
    return null
  }
  return (
    <Tooltip label={error} color={'red'} placement={'bottom'} {...tooltipProps}>
      <Icon icon={'alertTriangle'} fontSize={16} color={'var(--content-negative-primary)'} />
    </Tooltip>
  )
}
