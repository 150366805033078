import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import styles from 'widgets/CallPopUp/ui/styles.module.scss'
import { AccordionSection, ContactsList } from 'widgets/CallPopUp'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'

export const CallPowerDialerSection = observer(() => {
  const { callPopUpPowerDialerStore, innerRefTooltipCurrent } = useCallPopUpDefaultContext()
  const store = callPopUpPowerDialerStore

  return (
    <AccordionSection show count={store.count} title={'Power Dialer'}>
      <div className={styles.wrapBtn}>
        <Button
          contained={'secondary'}
          iconProps={{
            icon: store.isPause ? 'play' : 'pause',
          }}
          text={store.isPause ? 'Resume' : 'Pause'}
          fullWidth
          onClick={() => {
            if (store.handleCheckCredits()) return

            store.handlePause()
          }}
        />
        <Button
          contained={'primary'}
          intent={'destructive'}
          iconProps={{
            icon: 'stop',
          }}
          text={'Stop'}
          fullWidth
          onClick={store.handleStop}
          disabled={store.loading}
        />
      </div>

      <ContactsList
        items={store.items}
        innerRefCurrent={innerRefTooltipCurrent || undefined}
        onDelete={(item) => {
          store.handleDeleteItem(item)
        }}
      />
    </AccordionSection>
  )
})
