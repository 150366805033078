import { makeAutoObservable, reaction } from 'mobx'
import { segmentsStore } from 'entities/Segment'

export class MenuSegmentsStore {
  private inactiveIds = new Set<number>()

  constructor() {
    makeAutoObservable(this)

    reaction(
      () => segmentsStore.items,
      () => {
        this.inactiveIds.forEach((id) => {
          if (!segmentsStore.hasItem(id)) this.inactiveIds.delete(id)
        })
      }
    )
  }

  get items() {
    return segmentsStore.items.filter((item) => !this.isHidden(item.id))
  }

  showItem = (id: number) => {
    this.inactiveIds.delete(id)
  }

  hideItem = (id: number) => {
    this.inactiveIds.add(id)
  }

  isHidden = (id: number) => {
    return this.inactiveIds.has(id)
  }
}

export const menuSegmentsStore = new MenuSegmentsStore()
