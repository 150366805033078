import { observer } from 'mobx-react-lite'
import { IChatbotActionSettings, ChatbotActionType } from 'entities/Chatbot'

import { useDetailsContext } from '../detailsContext'
import { ActionMessage } from './ActionMessage'
import { ActionTags } from './ActionTags'
import styles from './styles.module.scss'

interface IActionListProps {
  name: string
  actions: IChatbotActionSettings[] | null
}

export const ActionList = observer(({ name, actions }: IActionListProps) => {
  const { getFutureActionsTags } = useDetailsContext()

  return (
    <div className={styles.actionCard}>
      <header className={styles.actionCardHead}>{name}</header>
      {!actions?.length ? (
        <span className={styles.actionCardsPlaceholder}>Please add actions</span>
      ) : (
        actions.map((action, index) => {
          switch (action.type) {
            case ChatbotActionType.AddTag:
              return (
                <ActionTags
                  key={index}
                  name='Add tags'
                  placeholder='Please add tags'
                  futureTags={getFutureActionsTags(action.settings.tag_ids)}
                />
              )
            case ChatbotActionType.RemoveTag:
              return (
                <ActionTags
                  key={index}
                  name='Remove tags'
                  placeholder='Please add tags'
                  futureTags={getFutureActionsTags(action.settings.tag_ids)}
                />
              )
            case ChatbotActionType.SendMessage:
              return (
                <ActionMessage
                  key={index}
                  name='Send message'
                  message={action.settings.message}
                  placeholder='Please add message'
                />
              )
            case ChatbotActionType.ContactNote:
              return (
                <ActionMessage
                  key={index}
                  name='Add contact note'
                  message={action.settings.note}
                  placeholder='Please add note'
                />
              )
            default:
              return null
          }
        })
      )}
    </div>
  )
})
