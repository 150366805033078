import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { SavedRepliesSortEnum } from 'entities/CannedMessages'
import { usersStore } from 'entities/Users'

class CannesMessageStore {
  private _disposeReactionOrderUpdate: IReactionDisposer | null = null
  private _disposeReactionOrderRestore: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)
    this.reactionOrderRestore()
    this.reactionOrderUpdate()
  }

  order: SavedRepliesSortEnum = SavedRepliesSortEnum.newest

  get storageKey() {
    if (!usersStore.user) return ''

    return `${usersStore.user.id}_cannedMessage_order`
  }

  setOrder = (order: SavedRepliesSortEnum) => {
    this.order = order
  }

  storeOrder = () => {
    if (!usersStore.user) return

    window.localStorage.setItem(this.storageKey, this.order)
  }

  restoreOrder = () => {
    if (!usersStore.user) return
    const storedOrder = window.localStorage.getItem(this.storageKey)

    if (!storedOrder) return
    this.order = storedOrder as SavedRepliesSortEnum
  }

  reactionOrderUpdate = () => {
    this._disposeReactionOrderUpdate?.()
    this._disposeReactionOrderUpdate = reaction(() => this.order, this.storeOrder)
  }

  reactionOrderRestore = () => {
    this._disposeReactionOrderRestore?.()
    this._disposeReactionOrderRestore = reaction(() => usersStore.user, this.restoreOrder)
  }
}

export const cannesMessageStore = new CannesMessageStore()
