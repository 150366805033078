import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { ZIndex } from 'shared/constants/zIndex'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import styles from './styles.module.scss'

export const CallMessagePopUpButton: React.FC = observer(() => {
  const store = useCallPopUpDefaultContext()
  const { isMessage, isMessageDisabled, handleMessageShow, innerRefTooltipCurrent } = store

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'blue'}
        variant={'custom'}
        active={isMessage}
        disabled={isMessageDisabled}
        ariaLabel={`CallMessagePopUpButton_${isMessage ? 'hideMessage' : 'message'}`}
        onClick={() => {
          handleMessageShow()
        }}
        iconProps={{
          icon: 'messageTextCircle02',
        }}
        tooltipProps={{
          label: isMessage ? 'Hide message' : 'Message',
          placement: 'top',
          zIndex: ZIndex.OVERLAY_TOP_1,
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
      />
    </div>
  )
})
