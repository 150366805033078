// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Iy8bNG67xsSTfjufIOx2{color:var(--light-blue-50);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/UpgradeButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,0BAAA,CACA,cAAA","sourcesContent":[".upgradeButton {\n    color: var(--light-blue-50);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upgradeButton": "Iy8bNG67xsSTfjufIOx2"
};
export default ___CSS_LOADER_EXPORT___;
