import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { RadioOptions } from 'shared/ui/Radio'
import { StripeCardInput } from 'shared/ui/StripeCardInput'
import { Error } from 'shared/ui'
import { AddPaymentMethodStore } from 'widgets/AddPaymentMethodModal'
import styles from './styles.module.scss'

type IAddPaymentMethodContentProps = {
  addPaymentMethodStore: AddPaymentMethodStore
}

export const AddPaymentMethodContent: FC<IAddPaymentMethodContentProps> = observer(
  ({ addPaymentMethodStore }) => {
    const {
      nameError,
      cardError,
      activePaymentMethodType,
      name,
      setName,
      setPaymentMethodType,
      paymentMethodOptions,
      onChangeCard,
      setOnSubmit,
      clearCardError,
    } = addPaymentMethodStore

    return (
      <div className={styles.wrap}>
        <div className={styles.section}>
          <Label
            label={{
              text: 'Cardholder name',
            }}
          />
          <TextField
            value={name}
            onChange={(value) => {
              setName(value)
            }}
            InputProps={{
              placeholder: 'e.g. Adam Johnson',
            }}
            variant={'stroke'}
            size={'medium'}
            error={nameError}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
          />
          <Error type={'text'} error={nameError} />
        </div>

        <div className={styles.section}>
          <Label
            label={{
              text: 'Card',
            }}
          />
          <StripeCardInput
            onChange={onChangeCard}
            setOnSubmit={setOnSubmit}
            onFocus={clearCardError}
            isError={!!cardError}
          />
          <Error type={'text'} error={cardError} />
        </div>

        <div className={styles.section}>
          <Label
            label={{
              text: 'Payment method type',
              secondaryText: '(optional)',
            }}
            marginBottom={9}
          />

          <RadioOptions
            options={paymentMethodOptions}
            active={activePaymentMethodType}
            onChange={(value) => {
              setPaymentMethodType(value)
            }}
          />
        </div>
      </div>
    )
  }
)
