import { useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Button,
  Dropdown,
  DropdownContent,
  DropdownGroup,
  DropdownTextInput,
  IDropdownItem,
  Scrollbar,
} from 'shared/ui'
import { prepareSearchRegex } from 'shared/lib/prepareSearch'
import { ChatbotActionType, IChatbotActionType } from 'entities/Chatbot'
import { ChatbotAction } from 'pages/chatbot/store/chatbotActionStore'

import styles from './styles.module.scss'

export interface IChatbotActionAddProps {
  actions: ChatbotAction
}

const ACTION_ITEMS = [
  {
    id: ChatbotActionType.AddTag,
    label: 'Add tag',
    iconL: 'tag',
  },
  {
    id: ChatbotActionType.RemoveTag,
    label: 'Remove tag',
    iconL: 'tag',
  },
  {
    id: ChatbotActionType.SendMessage,
    label: 'Send message',
    iconL: 'messageTextCircle02',
  },
  {
    id: ChatbotActionType.ContactNote,
    label: 'Add contact note',
    iconL: 'edit',
  },
] as IDropdownItem[]

export const ChatbotActionAdd = observer(({ actions }: IChatbotActionAddProps) => {
  const ref = useRef<HTMLButtonElement | null>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [dropdownWidth, setDropdownWidth] = useState<number | undefined>()

  const termActions = useMemo(() => {
    const term = searchTerm.trim()

    if (!term) return ACTION_ITEMS

    const searchRegex = prepareSearchRegex(term)

    return ACTION_ITEMS.filter((action) => searchRegex.test(action.label))
  }, [searchTerm])

  const handleAddAction = (type: IChatbotActionType) => actions.createAction(type)

  const handleToggleDropdown = (open: boolean) => {
    if (open) setSearchTerm('')
    setDropdownWidth(ref.current?.getBoundingClientRect().width)
  }

  return (
    <div>
      <Dropdown
        ariaLabel='TextbotAddAction'
        width={dropdownWidth}
        onToggle={handleToggleDropdown}
        triggerComponent={() => (
          <Button
            className={styles.actionCardsControl}
            icon='add'
            text={'Add action'}
            contained={'secondary'}
            size={'medium'}
            ref={ref}
          />
        )}
        placement='bottom'
        customComponent={(close) => (
          <DropdownContent noPadding>
            <DropdownTextInput
              textFieldProps={{
                value: searchTerm,
                onChange: setSearchTerm,
                InputProps: {
                  placeholder: 'Search',
                },
              }}
            />
            <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={300}>
              {!!termActions.length ? (
                <DropdownGroup
                  title='Action'
                  items={termActions}
                  isScroll={false}
                  onChange={({ id }) => {
                    close()
                    handleAddAction(id as IChatbotActionType)
                  }}
                  stylesWrap={{ borderBottom: 'none' }}
                />
              ) : (
                <div className={styles.noResults}>No results found</div>
              )}
            </Scrollbar>
          </DropdownContent>
        )}
      />
    </div>
  )
})
