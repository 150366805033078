import React, { useRef, useEffect } from 'react'
import styles from './../MenuCardInboxGroup/styles.module.scss'

type MenuItemInputProps = {
  value?: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const MenuCardInput: React.FC<MenuItemInputProps> = ({ value, placeholder, onChange, onKeyUp }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <input
      ref={inputRef}
      type='text'
      className={styles.input}
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange && onChange(e)
      }}
      onKeyUp={(e) => {
        onKeyUp && onKeyUp(e)
      }}
    />
  )
}

export { MenuCardInput }
