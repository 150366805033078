import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Button } from 'shared/ui'
import { type ConversationAssignModalStore } from 'widgets/ConversationAssignModal'

type IConversationAssignModalActionsProps = {
  store: ConversationAssignModalStore
}

export const ConversationAssignModalActions: FC<IConversationAssignModalActionsProps> = observer(
  ({ store }) => {
    const { hasSelected, handleUpdateAssign } = store

    return (
      <Button
        disabled={!hasSelected}
        typeBtn={'contained'}
        text={'Assign'}
        onClick={handleUpdateAssign}
      />
    )
  }
)
