import { makeAutoObservable } from 'mobx'
import type { IResponseUserCredential, IResponseUserIntegration } from 'entities/Integrations'
import type { Integration } from './Integration'

export class UserIntegration {
  id!: number
  connection_error!: number
  is_active = false
  url!: string
  user_credential?: IResponseUserCredential
  origin!: IResponseUserIntegration
  integration!: Integration

  constructor(item: IResponseUserIntegration, integration: Integration) {
    makeAutoObservable(this)

    this.integration = integration
    this.syncOrigin(item)
  }

  get icon() {
    return this.integration.icon
  }

  get key() {
    return this.integration.key
  }

  get integrationId() {
    return this.integration.id
  }

  syncOrigin = (origin: IResponseUserIntegration) => {
    this.id = origin.id
    this.url = origin.url
    this.connection_error = origin.connection_error
    this.user_credential = origin.user_credential
    this.is_active = origin.is_active
    this.origin = origin

    this.integration.syncOrigin(origin.integration)
    this.integration.syncConnection(origin.is_active)
  }
}
