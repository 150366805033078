// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rO_UeHsMwgDQva69UKFw{display:none}.rO_UeHsMwgDQva69UKFw *{display:none;pointer-events:none}.XaPiXiKkg9VridEcxRCl{display:flex;align-items:center;height:16px;margin:0 -4px}", "",{"version":3,"sources":["webpack://./src/shared/ui/TextField/ActionsAside/TextFieldInteraction/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CAEA,wBACI,YAAA,CACA,mBAAA,CAIR,sBACI,YAAA,CACA,kBAAA,CACA,WAAA,CACA,aAAA","sourcesContent":[".hidden {\n    display: none;\n\n    & * {\n        display: none;\n        pointer-events: none;\n    }\n}\n\n.interactionActions {\n    display: flex;\n    align-items: center;\n    height: 16px;\n    margin: 0 -4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "rO_UeHsMwgDQva69UKFw",
	"interactionActions": "XaPiXiKkg9VridEcxRCl"
};
export default ___CSS_LOADER_EXPORT___;
