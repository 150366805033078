// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P_mSvMqG1dKxdfxqNLwf{position:relative;padding:3px 22px 3px 3px}.P_mSvMqG1dKxdfxqNLwf .dBoRfRIJSKcU1DX4Mjc4{margin:22px 0 18px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/LinkContact/ui/LinkContactModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,wBAAA,CAEA,4CACE,kBAAA","sourcesContent":[".wrap {\n  position: relative;\n  padding: 3px 22px 3px 3px;\n\n  .tabs {\n    margin: 22px 0 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "P_mSvMqG1dKxdfxqNLwf",
	"tabs": "dBoRfRIJSKcU1DX4Mjc4"
};
export default ___CSS_LOADER_EXPORT___;
