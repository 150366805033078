import { makeAutoObservable } from 'mobx'
import type { IIntegrationsProperty } from 'entities/Integrations/model/types'

export class IntegrationProperty {
  private _key: IIntegrationsProperty['key'] = ''
  private _label: IIntegrationsProperty['label'] = ''
  private _value: IIntegrationsProperty['value'] = ''
  private _first_name = ''
  private _last_name = ''

  constructor(item: IIntegrationsProperty) {
    this._key = item.key
    this._label = item.label
    this._value = item.value
    this._first_name = item.first_name || ''
    this._last_name = item.last_name || ''

    makeAutoObservable(this)
  }

  get key() {
    return this._key
  }

  get label() {
    return this._label
  }

  get value() {
    return this._value || ''
  }

  get first_name() {
    return this._first_name || ''
  }

  get last_name() {
    return this._last_name || ''
  }
}
