import React, { RefObject } from 'react'
import { observer } from 'mobx-react-lite'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { CallQueueSection } from 'widgets/CallPopUp/ui/CallQueue/CallQueueSection/CallQueueSection'
import { Head } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

type ICallPopUpViewDefaultProps = {
  refWrap: RefObject<HTMLDivElement>
}

export const CallPopUpViewQueue: React.FC<ICallPopUpViewDefaultProps> = observer(({ refWrap }) => {
  const store = useCallPopUpDefaultContext()
  const { innerRefTooltipCurrent, callPopUpQueueStore } = store as CallPopUpDefaultStore
  const name = callPopUpQueueStore.item?.team.name
  const number = callPopUpQueueStore.item?.team.number

  return (
    <div className={styles.section}>
      <div ref={refWrap} className={styles.tooltipWrap} />

      <Head
        isIncoming={true}
        wrapElement={innerRefTooltipCurrent}
        name={name}
        phone={number}
        onClick={store.handleGoToConversation}
      />

      <CallQueueSection type={'queue'} />
    </div>
  )
})
