import { makeAutoObservable } from 'mobx'
import type { ContactsDetailsStore, IViewTypes } from 'widgets/ContactsDetails'

export class ContactsQuickLinksStore {
  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)
  }

  get selectedContactId() {
    return this._contactsDetailsStore.contactId
  }

  openView = (value: IViewTypes) => {
    this._contactsDetailsStore.openView(value)
  }
}
