import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SwitchField } from 'shared/ui'
import { AdvancedScheduling } from 'shared/ui/Schedule/ui/AdvancedScheduling'
import { RepeatDropdown } from 'shared/ui/Schedule/ui/Recurring/ui/RepeatDropdown/RepeatDropdown'
import { EndRepeatDropdown } from 'shared/ui/Schedule/ui/Recurring/ui/EndRepeatDropdown/EndRepeatDropdown'
import { useRecurringContext } from 'shared/ui/Schedule/ui/Recurring/context/recurringContext'
import { DateEndTime } from '../../DateEndTime/DateEndTime'

export const RecurringContent = observer(() => {
  const {
    initDefault,
    advancedSchedulingStore,
    toggleUseContactTimezone,
    isUseContactTimezone,
    date,
    setDate,
    dropdownId,
    defaultDate,
  } = useRecurringContext()

  useEffect(initDefault, [])

  return (
    <>
      <Box
        padding={1}
        borderBottom={'1px solid var(--border-neutral-default)'}
        display={'grid'}
        gap={'4px'}
      >
        <DateEndTime
          date={date}
          defaultDate={defaultDate}
          setDate={setDate}
          dropdownId={dropdownId}
        />
        <RepeatDropdown />
        <EndRepeatDropdown />
      </Box>

      <Box padding={1} borderBottom={'1px solid var(--border-neutral-default)'}>
        <SwitchField
          value={isUseContactTimezone}
          onClick={toggleUseContactTimezone}
          labelProps={{
            label: {
              leftIcon: 'userFilled',
              text: 'Use contact timezone',
            },
          }}
        />
      </Box>
      <AdvancedScheduling
        withoutTimePicker
        store={advancedSchedulingStore}
        expandedBoxProps={{
          borderRadius: '0 0 8px 8px',
        }}
      />
    </>
  )
})
