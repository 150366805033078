import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { Dropdown, IDropdownItem, IDropdownProps } from 'shared/ui'
import { useRecurringContext } from 'shared/ui/Schedule'
import { OnDateContent } from 'shared/ui/Schedule/ui/Recurring/ui/EndRepeatDropdown/OnDateContent/OnDateContent'
import { AfterContent } from 'shared/ui/Schedule/ui/Recurring/ui/EndRepeatDropdown/AfterContent/AfterContent'

export const EndRepeatDropdown = observer(() => {
  const { dropdownId, endRepeatItems, onChangeEndRepeatOption, endText, isDisabledEnd } =
    useRecurringContext()

  const [isOnDateContent, setOpenOnDateContent] = useState(false)
  const [isAfterContent, setAfterContent] = useState(false)

  const [isOpen, setOpen] = useState(false)

  const onCloseDropdown = () => {
    setOpenOnDateContent(false)
    setAfterContent(false)
  }

  const handleChangeRepeatOption = (item: IDropdownItem) => {
    if (item.id === 'on') {
      setOpenOnDateContent(true)
    }
    if (item.id === 'after') {
      setAfterContent(true)
      return
    }
    onChangeEndRepeatOption(item)
  }

  const customComponent: IDropdownProps['customComponent'] = useMemo(() => {
    if (isOnDateContent) {
      // eslint-disable-next-line react/display-name
      return (onCloseMenu) => <OnDateContent onCloseMenu={onCloseMenu} />
    }
    if (isAfterContent) {
      // eslint-disable-next-line react/display-name
      return (onCloseMenu) => <AfterContent onCloseMenu={onCloseMenu} />
    }
    return undefined
  }, [isOnDateContent, isAfterContent])

  return (
    <Dropdown
      disabled={isDisabledEnd}
      ariaLabel={'EndRepeatDropdown'}
      width={322}
      customComponent={customComponent}
      onClose={onCloseDropdown}
      typeButton={'button'}
      buttonProps={{
        text: endText,
        paddingInline: 8,
        typeBtn: 'menuItem',
        fontWeight: 400,
        iconProps: {
          icon: 'endRepeat',
        },
        tooltipProps: {
          fullWidth: true,
          label: 'End repeat',
          placement: 'left',
          visibility: isOpen ? 'hidden' : 'visible',
        },
      }}
      items={endRepeatItems}
      onChange={handleChangeRepeatOption}
      parentsDropdownId={[dropdownId]}
      placement={'bottom-start'}
      onChangeOpen={setOpen}
    />
  )
})
