import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Button, InputCheckbox } from 'shared/ui'
import styles from './styles.module.scss'
import { selectedModalStore } from '../store/SelectedModalStore'

const getContactText = (count: number) => (count > 1 ? 'contacts' : 'contact')

export const ValidateActions = observer(() => {
  const {
    allCount,
    isRevalidateCheckboxActive,
    checkedCount,
    handleClose,
    toggleRevalidate,
    validateContacts,
    filtersList,
    search,
    bulkAllMode,
    selectedIds,
  } = selectedModalStore

  useEffect(() => {
    if (allCount === checkedCount) {
      toggleRevalidate()
    }
  }, [])

  const getValidateButtonText = () => {
    if (allCount === checkedCount) {
      return `Revalidate ${allCount} ${getContactText(allCount)}`
    }

    return isRevalidateCheckboxActive
      ? `Validate ${allCount} ${getContactText(allCount)}`
      : `Validate ${allCount - checkedCount} ${getContactText(allCount - checkedCount)}`
  }

  return (
    <div className={styles.container}>
      {allCount - checkedCount !== 0 && checkedCount !== 0 && (
        <div className={styles.leftContent}>
          <InputCheckbox
            checked={isRevalidateCheckboxActive}
            label={`Revalidate ${checkedCount} ${getContactText(checkedCount)}`}
            onChecked={() => {
              toggleRevalidate()
            }}
          />
        </div>
      )}
      <div className={styles.rightContent}>
        <Button
          className={styles.cancelButton}
          text='Cancel'
          contained='tertiary'
          onClick={handleClose}
        />
        <Button
          text={getValidateButtonText()}
          onClick={() => {
            validateContacts(bulkAllMode, selectedIds as number[], search, filtersList)
          }}
        />
      </div>
    </div>
  )
})
