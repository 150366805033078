import classnames from 'classnames'
import { Button, CopyWrapper } from 'shared/ui'
import { IOpportunity } from 'entities/Integrations'
import { OpportunitiesRow } from './OpportunitiesRow'

import styles from '../styles.module.scss'

export type IOpportunitiesRowType = 'amount' | 'stage' | 'owner'

type IOpportunitiesProps = {
  opportunities: IOpportunity[]
  rows: IOpportunitiesRowType[]
  link: (id: string | number | null) => string
  viewAll: () => void
}

const EmptyList = () => <div className={styles.emptyData}>No opportunities found</div>

const OpportunitiesTab = ({ opportunities, rows, link, viewAll }: IOpportunitiesProps) => {
  if (!opportunities.length) return <EmptyList />

  const hasMore = opportunities.length > 1

  return (
    <div className={classnames(styles.contentWrapper)}>
      {opportunities.slice(0, 1).map((opportunity, idx: number) => (
        <div key={idx}>
          <div className={styles.headerWrapper}>
            <CopyWrapper
              copyValue={opportunity.title || null}
              data={{
                title: opportunity.title || 'View opportunity',
                link: link(opportunity.id),
              }}
            />
          </div>
          <OpportunitiesRow opportunity={opportunity} rows={rows} />
        </div>
      ))}
      {hasMore && (
        <div className={styles.showMore}>
          <Button size='medium' typeBtn='text' text='See all' onClick={viewAll} />
        </div>
      )}
    </div>
  )
}

export { OpportunitiesTab }
