// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q8McibKJoCiYa154PpOc{width:100%;justify-content:flex-start;display:grid;grid-template-columns:auto max-content;gap:4px;align-items:center;position:relative}.TG9u_ZX7oHCFcVSUpdh2{position:absolute;right:-4px}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/SavedRepliesContent/LeftContent/SavedRepliesList/SavedReplyItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,0BAAA,CACA,YAAA,CACA,sCAAA,CACA,OAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,UAAA","sourcesContent":[".wrap{\n  width: 100%;\n  justify-content: flex-start;\n  display: grid;\n  grid-template-columns: auto max-content;\n  gap: 4px;\n  align-items: center;\n  position: relative;\n}\n\n.actions{\n  position: absolute;\n  right: -4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Q8McibKJoCiYa154PpOc",
	"actions": "TG9u_ZX7oHCFcVSUpdh2"
};
export default ___CSS_LOADER_EXPORT___;
