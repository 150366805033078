import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { SpinnerLoader } from 'shared/ui'
import { usersStore } from 'entities/Users'
import styles from 'widgets/ContactsDetails/ui/ContactsProfileCard/ui/styles.module.scss'
import {
  ContactsProfileCardContext,
  useContactsProfileCardContext,
} from 'widgets/ContactsDetails/ui/ContactsProfileCard/context/contactsProfileCardContext'
import { type ContactsProfileCardStore } from 'widgets/ContactsDetails/ui/ContactsProfileCard'
import { ContactProfileInfo } from 'widgets/ContactsDetails/ui/ContactsProfileCard/ui/ContactProfileInfo/ContactProfileInfo'
import { ChatAction } from 'widgets/ContactsDetails/ui/ContactsProfileCard/ui/ChatAction/ChatAction'
import { CallButtonAction } from 'widgets/ContactsDetails/ui/ContactsProfileCard/ui/CallButtonAction/CallButtonAction'
import { EmailAction } from 'widgets/ContactsDetails/ui/ContactsProfileCard/ui/EmailAction/EmailAction'
import { ContactMeetDropdown } from 'widgets/ContactMeetDropdown'
import { ContactActions } from 'widgets/ContactsDetails/ui/ContactsProfileCard/ui/ContactActions/ContactActions'

const ContactsProfileCardContent = observer(() => {
  const store = useContactsProfileCardContext()

  useEffect(() => {
    store.init()
  }, [])

  if (store.loading) {
    return (
      <div className={classnames(styles.wrap, styles.loader)}>
        <SpinnerLoader />
      </div>
    )
  }

  const contact = store.contact

  if (!contact) return null

  const newContactDisabled = contact.id === -1 || contact.id === 0
  const isShouldRequestAccess = Boolean(usersStore.user?.isShouldRequestAccess)
  const disabled = newContactDisabled || isShouldRequestAccess

  return (
    <div className={styles.wrap}>
      <ContactProfileInfo />

      <div className={styles.actions}>
        {store.enableChat && <ChatAction disabled={disabled} onClick={store.onClickMessage} />}
        {store.enableCall && <CallButtonAction />}

        <EmailAction contact={contact} disabled={newContactDisabled} />
        <ContactMeetDropdown contact={contact} />
        <ContactActions contact={contact} />
      </div>
    </div>
  )
})

type IContactProfileCardProps = {
  store: ContactsProfileCardStore
}

export const ContactsProfileCard: FC<IContactProfileCardProps> = observer(({ store }) => {
  return (
    <ContactsProfileCardContext.Provider value={store}>
      <ContactsProfileCardContent />
    </ContactsProfileCardContext.Provider>
  )
})
