import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

export const Placeholder = () => {
  return (
    <Typography
      ariaLabel={'Placeholder'}
      className={styles.wrap}
      variant={'body-md-regular'}
      tag={'div'}
    >
      Select the message that will go straight to voicemail without calling
    </Typography>
  )
}
