import { Attachment } from 'entities/Attachment/model/Attachment'
import { IMediaLibraryType } from 'widgets/MediaLibrary/types'

export const getMediaLibraryTypeByAttachmentType = (
  attachmentType: Attachment['type']
): IMediaLibraryType => {
  switch (attachmentType) {
    case 'image':
    case 'gif':
    case 'previewGif':
      return 'images'

    case 'video':
      return 'videos'

    case 'audio':
    case 'voice':
      return 'audio'

    case 'attachment':
    case 'vcard':
      return 'files'

    default:
      return 'all'
  }
}
