// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pPNvAtTc1L3WkG7ixmIu{display:flex;gap:8px;align-items:center;height:24px}.oNi0unJMkXU3xBgUniCT{margin-left:-4px;color:var(--content-brand-primary)}.HNZo54sU_0tjqShzw83C{margin-left:-4px;color:var(--content-brand-primary)}.BA0HnpcI5deeljdLEcAr{pointer-events:none}.UfKeYBMqUlueeHGlqBYt{padding-left:0}", "",{"version":3,"sources":["webpack://./src/widgets/TextToSpeech/ui/TextToSpeechActions/PlaySpeechBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CACA,WAAA,CAEF,sBACE,gBAAA,CACA,kCAAA,CAEF,sBACE,gBAAA,CACA,kCAAA,CAEF,sBACE,mBAAA,CAEF,sBACE,cAAA","sourcesContent":[".wrap{\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  height: 24px;\n}\n.wrapIcon{\n  margin-left: -4px;\n  color: var(--content-brand-primary);\n}\n.icon{\n  margin-left: -4px;\n  color: var(--content-brand-primary);\n}\n.disabled{\n  pointer-events: none;\n}\n.player{\n  padding-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "pPNvAtTc1L3WkG7ixmIu",
	"wrapIcon": "oNi0unJMkXU3xBgUniCT",
	"icon": "HNZo54sU_0tjqShzw83C",
	"disabled": "BA0HnpcI5deeljdLEcAr",
	"player": "UfKeYBMqUlueeHGlqBYt"
};
export default ___CSS_LOADER_EXPORT___;
