import { observer } from 'mobx-react-lite'
import { CommonPeopleToContact } from 'pages/settings/pages/compliance/ui/CommonPeopleToContact/ui/CommonPeopleToContact'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'

export const PeopleToContact = observer(() => {
  const { stepLoading, peopleToContactStore, complianceLayerStore, nextStepClick } =
    useLocalNumbersStepsContext()

  return (
    <CommonPeopleToContact
      peopleToContactStore={peopleToContactStore}
      stepLoading={stepLoading}
      errorMessage={complianceLayerStore.complianceServiceBrand?.registrationError?.message}
      nextStepClick={nextStepClick}
    />
  )
})
