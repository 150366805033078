import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { makeTextFieldIcon, TextFieldPlaceholder } from 'shared/ui/TextField'
import { Typography } from 'shared/ui'

export type IEnrollmentSearchTriggerProps = {
  open?: boolean
}

export const EnrollmentSearchTrigger: FC<IEnrollmentSearchTriggerProps> = observer(() => {
  return (
    <TextFieldPlaceholder
      variant='stroke'
      size='small'
      leftActions={[
        makeTextFieldIcon({
          icon: 'search',
        }),
      ]}
      placeholder={
        <Typography
          variant={'text-input-field-r'}
          color={'var(--content-neutral-primary)'}
          ariaLabel={'placeholder'}
          ellipsis
          tag={'div'}
        >
          Search tag, segment or contact
        </Typography>
      }
    />
  )
})
