import { createContext, useContext } from 'react'
import { DropdownCardsStore } from 'widgets/DropdownCards'

export const DropdownCarsContext = createContext<DropdownCardsStore | null>(null)

export function useDropdownCarsContext() {
  const context = useContext(DropdownCarsContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with DropdownCarsContext')
  }
  return context
}
