import type { IUploadInfoKeys } from '../store/type'

export const fileTypeToLabelConverter: Record<IUploadInfoKeys, string> = {
  audio: 'audio',
  video: 'video',
  image: 'image',
  docs: 'file',
  vcard: 'vCard',
  default: 'file',
}
