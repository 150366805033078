import React from 'react'
import classNames from 'classnames'
import { Icon, Tooltip } from 'shared/ui'
import styles from './styles.module.scss'

type IAutomatedAvatarProps = {
  size?: 'small'
  withTooltip?: boolean
}

export const AutomatedAvatar = ({ size, withTooltip }: IAutomatedAvatarProps) => {
  const content = (
    <div className={classNames(styles.wrap, size && styles[size])}>
      <Icon icon={'bot'} fontSize={size === 'small' ? 12 : 16} />
    </div>
  )
  if (withTooltip) {
    return (
      <Tooltip label={'Salesmsg bot'} placement={'top'}>
        {content}
      </Tooltip>
    )
  }
  return content
}
