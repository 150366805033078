import { observer } from 'mobx-react-lite'
import React, { FC, useEffect, useState } from 'react'
import { SpinnerLoader } from 'shared/ui'
import { initPosthog } from 'entities/Posthog'
import { initCanny } from 'entities/Canny'
import { eventHeartbeat } from 'entities/DataLog/events/EventHeartbeatLog'
import { authStore } from 'entities/Auth'
import { remindersStore } from 'widgets/Reminders'
import { Sidebar } from 'widgets/Sidebar'
import { getNumberModalStore } from 'widgets/GetNumberModal'
import { messagingRegionModalStore } from 'widgets/MessagingRegionModal'
import { connectTwilioModalStore } from 'widgets/connectTwilioModal'
import { getAppData } from 'app/lib/loader'
import LayoutWithSidebar from 'app/layouts/layoutWithSidebar'

type IAuthLayoutVueProps = {
  type?: 'default' | 'sidebar'
}

const AuthLayoutVue: FC<IAuthLayoutVueProps> = observer((props) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!authStore.isLoggedIn) {
      return
    }

    getAppData()
      .catch(console.error)
      .finally(() => {
        initPosthog()
        initCanny()
        getNumberModalStore.init()
        messagingRegionModalStore.init()
        connectTwilioModalStore.init()
        remindersStore.init()
        setLoading(false)
        eventHeartbeat()
      })
  }, [authStore.isLoggedIn])

  if (!authStore.isLoggedIn) return null
  if (props.type === 'sidebar') {
    return <Sidebar />
  }

  if (loading) {
    return (
      <div className={'spinner'}>
        <SpinnerLoader />
      </div>
    )
  }

  return <LayoutWithSidebar />
})

export default AuthLayoutVue
