// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XS7H4tg1CK53AaWZbQl4{display:flex;align-items:center;gap:10px;padding-left:20px;padding-top:8px;padding-bottom:12px;margin-top:-8px}.qNDVeg1_Asso4WC12n3j{margin-top:24px;display:flex;flex-grow:1;margin-inline:16px;height:inherit}.uYrACCwaSqALqJd1ADew{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/customPrices/pages/CustomPrices/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,eAAA,CACA,mBAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,qBAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding-left: 20px;\n  padding-top: 8px;\n  padding-bottom: 12px;\n  margin-top: -8px;\n}\n\n.table {\n  margin-top: 24px;\n  display: flex;\n  flex-grow: 1;\n  margin-inline: 16px;\n  height: inherit;\n}\n\n.page-wrap {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "XS7H4tg1CK53AaWZbQl4",
	"table": "qNDVeg1_Asso4WC12n3j",
	"page-wrap": "uYrACCwaSqALqJd1ADew"
};
export default ___CSS_LOADER_EXPORT___;
