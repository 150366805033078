import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { useState } from 'react'
import { Divider } from '@mui/material'
import { Button, Tooltip, Typography } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'
import { Warning } from 'shared/ui/Warning'
import { MAX_ROLE_LENGTH } from 'shared/constants/auth'
import { RegisterStore } from 'widgets/Register'
import { RolesEnum } from 'widgets/Register/ui/Step1/store/type'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterStore
  onClose: () => void
}

export const DropdownRolesContent = observer(({ store, onClose }: IFormProps) => {
  const {
    step1Store: { role, setRole, roles, handleBlur, handleRoleFieldFocus, isActiveRoleField },
  } = store

  const [customRoleView, setCustomRoleView] = useState(role?.id === RolesEnum.other)
  const [customRole, setCustomRole] = useState(role?.id === RolesEnum.other ? role.name : '')

  const handleCustomRole = (value: string) => {
    if (value.length > MAX_ROLE_LENGTH) return
    setCustomRole(value)
  }

  const handleBack = () => setCustomRoleView(false)
  const handleConfirm = () => {
    setRole({ id: RolesEnum.other, name: customRole })
    onClose()
  }

  if (customRoleView) {
    return (
      <div className={styles.wrap}>
        <Button
          className={styles.backButton}
          icon={'chevronLeft'}
          typeBtn='menuItem'
          type='button'
          onClick={handleBack}
          text={'Other'}
        />
        <Divider className={styles.divider} />
        <TextField
          className={classNames(styles.textField, styles.dropdown)}
          variant='integrated'
          size='medium'
          onFocus={handleRoleFieldFocus}
          onBlur={handleBlur}
          InputProps={{ placeholder: 'Your role' }}
          onChange={handleCustomRole}
          value={customRole}
          mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
        />
        <div className={styles.dropdownFooter}>
          <Tooltip label={`Limit: ${MAX_ROLE_LENGTH} characters`} placement='top'>
            <>
              {customRole.length == MAX_ROLE_LENGTH && isActiveRoleField ? (
                <Warning
                  text={
                    customRole.length === MAX_ROLE_LENGTH
                      ? `${MAX_ROLE_LENGTH}-character limit reached`
                      : ''
                  }
                />
              ) : (
                <Typography
                  variant={'text-input-field-r'}
                  ariaLabel={getAriaLabel('customRoleLength')}
                >{`${customRole.length} of ${MAX_ROLE_LENGTH}`}</Typography>
              )}
            </>
          </Tooltip>
          <Button
            className={styles.confirmButton}
            typeBtn='contained'
            type='button'
            onClick={handleConfirm}
            disabled={!customRole.length || !(customRole.length <= MAX_ROLE_LENGTH)}
            text={'Confirm'}
            contained={'primary'}
            fullWidth
          />
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(styles.wrap, styles.list)}>
      {roles.map((listRole) => (
        <Button
          key={listRole.id}
          className={styles.selectItem}
          typeBtn='menuItem'
          type='button'
          active={listRole.id === role?.id}
          iconRightProps={
            listRole.id === role?.id
              ? {
                  color: 'var(--content-brand-primary)',
                  className: styles.selectItemIcon,
                  icon: 'check1',
                }
              : undefined
          }
          onClick={() => {
            if (listRole.id !== RolesEnum.other) {
              setRole(listRole)
              onClose()
              return
            }
            setCustomRoleView(true)
          }}
          text={listRole.name}
          fullWidth
        />
      ))}
    </div>
  )
})

export default DropdownRolesContent
