import { CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { IDropdownItem } from 'shared/ui'
import { ComplianceApi } from 'entities/Compliance'
import type { ICountryState } from 'entities/Compliance/api/type'
import { organizationStore } from 'entities/Organization'
import { EinStore } from './einStore'
import { BusinessInfoStore } from './BusinessInfoStore'
import { PeopleToContactStore } from './peopleToContactStore'

export type ICompanyLocation = 'US' | 'CA'

export class ComplianceSettingsStore {
  constructor() {
    makeAutoObservable(this)
  }

  einStore = new EinStore()
  businessInfoStore = new BusinessInfoStore()
  peopleToContactStore = new PeopleToContactStore()

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _companyLocation: ICompanyLocation | null = null
  private _states: ICountryState[] = []
  private _generalInfoActiveTab = 1
  private _isTermsAndPrivacyChecked = false

  private _modalIdDoNotHaveEI = 'modal_id_do_not_have_EIN'

  private _stepLoading = false

  get generalInfoActiveTab() {
    return this._generalInfoActiveTab
  }

  get companyLocation() {
    return this._companyLocation
  }

  get states(): IDropdownItem[] {
    return this._states.map((item) => ({ label: item.name, id: item.id }))
  }

  get isTermsAndPrivacyChecked() {
    return this._isTermsAndPrivacyChecked
  }

  get stepLoading() {
    return this._stepLoading
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setStepLoading = (value: boolean) => {
    this._stepLoading = value
  }

  handleClose = (modalId: string) => {
    modalStore.removeModal(modalId)
  }

  openDoNotHaveEINModal = () => {
    modalStore.addModal({
      id: this._modalIdDoNotHaveEI,
      type: ModalTypeList.INFO,
      onClose: () => {
        this.handleClose(this._modalIdDoNotHaveEI)
      },
      title: 'No United States EIN?',
      desc: 'If you don’t have a United States EIN, your only eligible option is to use toll-free numbers',
      primaryAction: {
        text: 'Continue with toll-free',
        onAction: async () => {
          modalStore.removeModal(this._modalIdDoNotHaveEI)
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => modalStore.closeModal(this._modalIdDoNotHaveEI),
      },
    })
  }

  setCompanyLocation = (value: ICompanyLocation) => {
    this._companyLocation = value
  }

  setStates = (states: ICountryState[]) => {
    this._states = states
  }

  loadBusinessProfileStates = async () => {
    try {
      const { data } = await ComplianceApi.getBpStates()

      const states = data.filter((item) => item.country_id === this._companyLocation)

      this.setStates(states)
    } catch (e) {
      console.error(e)
    }
  }

  setGeneralInfoActiveTab = (value: number) => {
    this._generalInfoActiveTab = value
  }

  changeGeneralInfoActiveTab = (value: number) => {
    this.setGeneralInfoActiveTab(value)
    this.einStore.setEinItem(null)
    this.einStore.setEinSearch('')
  }

  toggleIsTermsAndPrivacyChecked = () => {
    this._isTermsAndPrivacyChecked = !this._isTermsAndPrivacyChecked
  }

  getBusinessInformation = (step: number) => {
    const { einItem } = this.einStore

    const { id: organizationId } = organizationStore || {}

    if (!einItem || !organizationId || !this.companyLocation) return null
    const { websiteUrl, noWebsiteUrl, regionsOfOperationId, activeBusinessTypeId } =
      this.businessInfoStore

    return {
      business_information: {
        business_name: einItem.name,
        business_type_id: activeBusinessTypeId,
        industry_id: this.businessInfoStore.activeBusinessIndustryId,
        city: einItem.city,
        country: this.companyLocation,
        organization_id: organizationId,
        regions_of_operation: regionsOfOperationId ? [regionsOfOperationId] : [],
        reg_progress_status: 'form_step_1',
        registration_number: einItem.ein,
        irs_ein_document: this.einStore.irsEinAttachment,
        registration_type_id: 2,
        state_id: einItem.state,
        street_address_1: einItem.street1,
        street_address_2: einItem.street2 || null,
        website_url: noWebsiteUrl ? null : websiteUrl,
        zip_code: einItem.zip,
      },
      step,
    }
  }

  submitGeneralInfo = async (step: number, callBack: () => void) => {
    const businessInformation = this.getBusinessInformation(step)

    if (!businessInformation) return

    try {
      this.setStepLoading(true)

      await ComplianceApi.setBpBusinessInformation(businessInformation)

      callBack()
    } catch (e) {
      console.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  submitPointsOfContact = async (callBack: () => void) => {
    const { primaryContact, secondaryContact, contactsConfirmed } = this.peopleToContactStore

    if (!primaryContact.jobPosition) return

    const pointsOfContacts = [
      {
        business_title: primaryContact.businessTitle,
        email: primaryContact.email,
        first_name: primaryContact.firstName,
        job_position_id: String(primaryContact.jobPosition),
        last_name: primaryContact.lastName,
        phone_number: primaryContact.phoneNumber,
      },
    ]

    if (this.peopleToContactStore.isSecondContact) {
      pointsOfContacts.push({
        business_title: secondaryContact.businessTitle,
        email: secondaryContact.email,
        first_name: secondaryContact.firstName,
        job_position_id: String(secondaryContact.jobPosition),
        last_name: secondaryContact.lastName,
        phone_number: secondaryContact.phoneNumber,
      })
    }

    try {
      this.setStepLoading(true)

      await ComplianceApi.setBpPointsOfContact({
        contacts_confirmed: contactsConfirmed,
        points_of_contact: pointsOfContacts,
        step: 3,
      })

      callBack()
    } catch (e) {
      console.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }

  submitTermsAndPrivacy = async () => {
    try {
      this.setStepLoading(true)

      await ComplianceApi.setBpTerms({
        terms_accepted: this.isTermsAndPrivacyChecked,
        step: 4,
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setStepLoading(false)
    }
  }
}
