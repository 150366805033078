// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uX_Je9Pm1PsKjOvIwOuX{margin-bottom:20px}.DwERYnEb9mmp9e3DskXe{margin-left:4px;color:var(--content-primary-primary);font-size:13px;font-weight:400}.Akv20JSfEaIjGdXna_kB{padding:8px;color:var(--content-primary-tertiary);font-size:12px;font-weight:500}._i_KBWYZ99JdXreMvoMe{overflow:auto}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsMenu/ui/SegmentMenu/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEF,sBACE,eAAA,CACA,oCAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,WAAA,CACA,qCAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,aAAA","sourcesContent":[".allContacts {\n  margin-bottom: 20px;\n}\n.segmentText {\n  margin-left: 4px;\n  color: var(--content-primary-primary);\n  font-size: 13px;\n  font-weight: 400;\n}\n\n.segmentsHeader {\n  padding: 8px;\n  color: var(--content-primary-tertiary);\n  font-size: 12px;\n  font-weight: 500;\n}\n\n.folders {\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allContacts": "uX_Je9Pm1PsKjOvIwOuX",
	"segmentText": "DwERYnEb9mmp9e3DskXe",
	"segmentsHeader": "Akv20JSfEaIjGdXna_kB",
	"folders": "_i_KBWYZ99JdXreMvoMe"
};
export default ___CSS_LOADER_EXPORT___;
