import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { Dropdown, DropdownContent, IconButton, Typography } from 'shared/ui'
import {
  TextField,
  makeTextFieldAutoFocus,
  makeTextFieldBlurOnEnter,
  makeTextFieldClear,
  makeTextFieldElement,
} from 'shared/ui/TextField'
import type { IWidgetProps } from 'widgets/FilterEditor/types'

import { NumberOperators } from './definition'
import styles from './styles.module.scss'

export const WidgetDateText = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const [isFirstUpdate, setIsFirstUpdate] = useState(true)
    const [inputValue, setInputValue] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const isNumberOperator = useMemo(
      () => !!filterItem.operator && NumberOperators.has(filterItem.operator.key),
      [filterItem.operator]
    )

    const filterValue = (filterItem.payload.value as string) || ''

    const isNumberValue =
      filterItem.key === 'number' ||
      filterItem.key === 'area_code' ||
      filterItem.type === 'number' ||
      isNumberOperator

    const onDelete = (removeIndex: number) => {
      const value = Array.isArray(filterItem.payload.value)
        ? filterItem.payload.value.filter((_, index) => index !== removeIndex)
        : []

      filterItem.payload.updateHard({ value })
    }

    const handleSave = () => {
      setIsFirstUpdate(false)
      if (inputValue !== filterValue) filterItem.payload.updateHard({ value: inputValue })

      setIsOpen(false)
    }

    const checkInputValue = (value: string) => {
      if (isNumberValue) {
        const cleanedValue = value.replace(/\D/g, '')
        setInputValue(cleanedValue)

        return
      }

      setInputValue(value)
    }

    const triggerText = () => {
      if (isNumberOperator) {
        const pluralDays = +filterValue === 1 ? 'day' : 'days'
        return filterValue ? `${filterValue} ${pluralDays}` : 'enter value'
      }

      return filterValue.length ? filterValue : 'enter value'
    }

    const isValidValue = () => {
      if (isFirstUpdate) return true

      return !filterItem.isEmpty
    }

    useEffect(() => setInputValue(filterValue), [])

    useEffect(() => {
      setTimeout(() => setIsOpen(expandByDefault), 100)
    }, [expandByDefault])

    return (
      <Dropdown
        ariaLabel='DateTextDropdown'
        placement='bottom-start'
        margin={10}
        width={240}
        withTextInput
        parentsDropdownId={parentsDropdownId}
        show={isOpen}
        onClose={handleSave}
        onToggle={(value) => {
          if (value) setIsOpen(true)
        }}
        triggerComponent={() => (
          <span className={classNames(!isValidValue() && styles.warning)}>{triggerText()}</span>
        )}
        customComponent={() => (
          <DropdownContent noPadding>
            <TextField
              size='medium'
              variant='integrated'
              ariaLabel='DateTextField'
              className={styles.textField}
              value={inputValue}
              onChange={(value) => checkInputValue(value)}
              onBlur={handleSave}
              InputProps={{ placeholder: `Enter ${isNumberValue ? 'number' : 'text'} value` }}
              mainActions={[
                makeTextFieldAutoFocus({ withFocus: true }),
                makeTextFieldBlurOnEnter(),
              ]}
              rightActions={[
                makeTextFieldElement({
                  element: (
                    <span className={styles.rightText}>{isNumberOperator ? 'days ago' : ''}</span>
                  ),
                }),
                makeTextFieldClear(),
              ]}
            />
            {Array.isArray(filterItem.payload.value) && (
              <div
                className={classNames(styles.textValues, {
                  [styles.emptyList]: filterItem.payload.value.length === 0,
                })}
              >
                {filterItem.payload.value.map((item, index) => (
                  <div key={item} className={styles.value}>
                    <Typography
                      ellipsis
                      color='var(--content-primary-primary)'
                      variant='body-rg-regular'
                      ariaLabel='DateTextDropdownItem'
                    >
                      {item}
                    </Typography>
                    <IconButton
                      icon='close'
                      variant='icon'
                      color='secondary'
                      size='small'
                      ariaLabel='DateTextRemoveButton'
                      fontSize={16}
                      tooltipProps={{
                        label: 'Remove',
                        placement: 'top',
                      }}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        onDelete(index)
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </DropdownContent>
        )}
      />
    )
  }
)
