import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { ChatbotLabel, ChatbotLayoutHead, ChatbotTestButton } from 'pages/chatbot/ui'

import { useChatbotLayoutContext } from 'pages/chatbot/context/chatbotLayoutContext'
import styles from './styles.module.scss'

export const ChatbotEditHead = observer(() => {
  const chatbotLayoutStore = useChatbotLayoutContext()
  const { titleIconProps, handleToggleCollapse } = chatbotLayoutStore.pageLayoutStore

  return (
    <ChatbotLayoutHead>
      <div className={styles.wrapCollapseBtn}>
        <IconButton
          iconProps={{
            fontSize: titleIconProps.fontSize,
            icon: titleIconProps.icon,
          }}
          variant='icon'
          size='medium'
          color='secondary'
          onClick={handleToggleCollapse}
          ariaLabel='TextobtHeaderLayout'
        />
      </div>
      <ChatbotLabel className={styles.headerLabel} label='Edit Textbot' />
      <ChatbotTestButton className={styles.headerButton} />
    </ChatbotLayoutHead>
  )
})
