import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket/store'
import type { IResponseConversationUnread } from 'entities/Conversation/api/types'

const ConversationUnread = (data: IResponseConversationUnread) => {
  const { conversation } = data

  // TODO: SWR-14336: Will be uncommit and updated with SWR-14336
  // dataLogStore.setItem({
  //   logLevel: 'info',
  //   message: 'EventConversationUnread',
  //   details: {
  //     conversationId: conversation.id,
  //     totalUnread: conversation.unread_count,
  //     conversationUnread: Boolean(conversation.unread_count),
  //     lastReadAt: conversation.last_read_at,
  //     lastMessageAt: conversation.last_message_at,
  //   },
  // })
}

export const useEventConversationUnreadLog = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.ConversationUnread, ConversationUnread)
    }
  }, [websocket.isConnected])
}
