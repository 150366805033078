import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { getLabelAsNumberInternationalFormat, removeSpacesFromNumericString } from 'shared/lib'
import { type PhoneFieldSearchStore } from 'widgets/ContactsDetails/ui/ContactsInfoForm/ui/PhoneFieldSearch'
import { PhoneInput } from 'widgets/ContactsDetails/ui/ContactsInfoForm/ui/PhoneInput'
import styles from 'widgets/ContactsDetails/ui/ContactsInfoForm/ui/styles.module.scss'
import { ContactsSearch } from 'widgets/ContactsSearch'

type IPhoneFieldProps = {
  store: PhoneFieldSearchStore
  value: string
  onChange: (value: string) => void
  isVariantCreateContactModal: boolean
}

export const PhoneFieldSearch: FC<IPhoneFieldProps> = observer(
  ({ store, value, isVariantCreateContactModal, onChange }) => {
    const {
      fullyNumber,
      error,
      isValidNumberLoading,
      isValidated,
      validateNumber,
      onChangeField,
      onChangeSearch,
      number,
    } = store
    const showContactSearch = !error && fullyNumber && !isValidNumberLoading

    useEffect(() => {
      store.setNumber(value)
    }, [value])

    return (
      <>
        <PhoneInput
          value={getLabelAsNumberInternationalFormat(number)}
          errorText={error}
          isLoading={isValidNumberLoading}
          modalView={isVariantCreateContactModal}
          onBlurField={() => {
            if (!isValidated) validateNumber(number)
          }}
          onChangeValue={(value) => {
            if (removeSpacesFromNumericString(value) === number) return

            const correctNumber = onChangeField(value, number)

            onChange(correctNumber)
          }}
        />
        {showContactSearch && (
          <div
            className={classnames(styles.contactsSearch, {
              [styles.modalView]: isVariantCreateContactModal,
            })}
          >
            <ContactsSearch
              withBorder
              search={number}
              onChange={onChangeSearch}
              paramsCreateContact={{ fromConversation: false }}
            />
          </div>
        )}
      </>
    )
  }
)
