import dayjs from 'dayjs'
import { DayjsFormats } from 'shared/lib'
import { Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import styles from 'widgets/DayContent/styles.module.scss'

export const DateContent = ({ day, isScheduled }: { day: string; isScheduled?: boolean }) => {
  const currentDay = uiStore.dayjs().format(DayjsFormats.dateSort)
  const date = dayjs(day).format(DayjsFormats.full6)

  return (
    <Typography className={styles.date} variant={'body-sm-regular'} ariaLabel={'Scheduled'}>
      {isScheduled && 'Scheduled on '}
      {currentDay === day ? 'Today' : date}
    </Typography>
  )
}
