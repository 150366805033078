import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { ChatbotActionNote } from 'entities/Chatbot'
import { EmojiAction, EnumVariantMessageField, MessageFieldStore } from 'widgets/MessageField'

export class ChatbotActionNoteStore {
  private _disposeNoteReaction: IReactionDisposer | null = null

  note = new MessageFieldStore({
    placeholder: 'Type @ to mention...',
    minHeight: 20,
    noCounter: true,
    showActionsItems: 2,
    variant: EnumVariantMessageField.Note,
    resetOnUnmount: false,
    noBorder: false,
    textLimit: {
      maxLength: 255,
      message: 'Note can contain max 255 characters',
    },
    makeActions: () => [
      {
        iconButtonComponent: <EmojiAction />,
      },
    ],
  })

  constructor(private _model: ChatbotActionNote) {
    makeAutoObservable(this)

    this._disposeNoteReaction = reaction(
      () => this.note.text,
      (text) => {
        const prevText = this._model.note
        const textIsSame = prevText === text

        if (textIsSame) return

        this._model.sync(text)
      }
    )
  }

  syncNote = () => {
    this.note.setMessageData({
      message: this._model.note,
      attachments: [],
      isReset: false,
      isReplace: true,
      isFocus: false,
    })
  }

  dispose = () => {
    this._disposeNoteReaction?.()
  }
}
