// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y99b3GoqpzJEwnBsapia{height:22px;display:flex;justify-content:flex-end;align-items:center;max-width:720px;width:100%;margin:0 auto;padding:0 30px 0}.EL5lkGrlIT4raqvuqGCb{margin-left:4px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/ConversationMessageFieldFooter/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,wBAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA,CACA,aAAA,CACA,gBAAA,CAGF,sBACE,eAAA","sourcesContent":[".wrap {\n  height: 22px;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  max-width: 720px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 0 30px 0;\n}\n\n.text {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "y99b3GoqpzJEwnBsapia",
	"text": "EL5lkGrlIT4raqvuqGCb"
};
export default ___CSS_LOADER_EXPORT___;
