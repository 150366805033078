// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RZ3DQmUJqQJ_o_jQtriL{display:flex;flex-direction:column;gap:8px}.kImCJJ52ykPtGeXqdNQr{display:grid;grid-auto-flow:column;justify-content:flex-start;gap:8px;overflow-x:auto;padding-bottom:14px}.foN2Ew6bibAV4zXJ5bko{border-radius:6px;padding:16px;background:var(--background-neutral-subtle);overflow-y:auto;word-break:break-all}.foN2Ew6bibAV4zXJ5bko a{font-size:14px;line-height:20px;text-decoration:underline;font-family:Inter,sans-serif;font-weight:normal;color:var(--content-brand-secondary)}.foN2Ew6bibAV4zXJ5bko a:hover{color:var(--light-blue-90)}", "",{"version":3,"sources":["webpack://./src/widgets/DetailsContent/MessageContent/SMSContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,OAAA,CACA,eAAA,CACA,mBAAA,CAGF,sBACE,iBAAA,CACA,YAAA,CACA,2CAAA,CACA,eAAA,CACA,oBAAA,CAEA,wBACE,cAAA,CACA,gBAAA,CACA,yBAAA,CACA,4BAAA,CACA,kBAAA,CACA,oCAAA,CAEA,8BACE,0BAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.wrapAttachments {\n  display: grid;\n  grid-auto-flow: column;\n  justify-content: flex-start;\n  gap: 8px;\n  overflow-x: auto;\n  padding-bottom: 14px;\n}\n\n.message {\n  border-radius: 6px;\n  padding: 16px;\n  background: var(--background-neutral-subtle);\n  overflow-y: auto;\n  word-break: break-all;\n\n  a {\n    font-size: 14px;\n    line-height: 20px;\n    text-decoration: underline;\n    font-family: Inter, sans-serif;\n    font-weight: normal;\n    color: var(--content-brand-secondary);\n\n    &:hover {\n      color: var(--light-blue-90);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "RZ3DQmUJqQJ_o_jQtriL",
	"wrapAttachments": "kImCJJ52ykPtGeXqdNQr",
	"message": "foN2Ew6bibAV4zXJ5bko"
};
export default ___CSS_LOADER_EXPORT___;
