import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Button } from 'shared/ui'
import { type ContactChoosePhoneModalStore } from 'widgets/ContactChoosePhoneModal'
import styles from './styles.module.scss'

type IContactChoosePhoneModalActionsProps = {
  store: ContactChoosePhoneModalStore
}

export const ContactChoosePhoneModalActions: FC<IContactChoosePhoneModalActionsProps> = observer(
  ({ store }) => {
    const { handleSubmit, disabled, loading } = store

    return (
      <div className={classnames(styles.actionsWrap)}>
        <Button
          loading={loading}
          contained='primary'
          text='Confirm'
          disabled={disabled}
          onClick={() => {
            handleSubmit()
          }}
        />
      </div>
    )
  }
)
