import { makeAutoObservable } from 'mobx'
import type { IResponseTrigger, ITriggerStatus, ITriggerType } from 'entities/Trigger/api/type'
import type { Statistic } from 'entities/ContactStatistic/model/Statistic'
import { TriggerStatistic } from 'entities/Trigger/model/TriggerStatistic'
import { Integration } from 'entities/Integrations/model/Integration'

export class ITrigger {
  id: number
  type: ITriggerType
  name: string
  ownerId: number
  status: ITriggerStatus
  statistics: Statistic | null = null
  createdAt: string
  integration: Integration | null = null

  constructor(item: IResponseTrigger) {
    this.id = item.id
    this.type = item.is_rvm ? 'ringless' : 'sms'
    this.name = item.name
    this.ownerId = item.owner_id
    this.status = item.is_active ? 'active' : 'disabled'
    this.statistics = new TriggerStatistic(item)
    this.createdAt = item.created_at
    this.integration = item.integration ? new Integration(item.integration) : null

    makeAutoObservable(this)
  }
}
