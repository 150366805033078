import { observer } from 'mobx-react-lite'
import { AlertBanner, EnumAlertBannerVariant } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'

export const TrialSubscriptionBanner = observer(() => {
  if (!subscriptionStore.isTrial || !organizationStore.trialEndsAtString) {
    return null
  }
  return (
    <AlertBanner
      title={`Your trial will expire in ${organizationStore.trialEndsAtString}`}
      variant={EnumAlertBannerVariant.Warning}
      action={
        usersStore.user?.isAdminOrOwnerRole
          ? {
              text: 'Upgrade now',
              onAction: () => {
                uiStore.changeRoute({
                  path: '/settings/billing/overview',
                  type: 'vue',
                })
              },
            }
          : undefined
      }
    />
  )
})
