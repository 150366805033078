// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YMdfYDnwKFxYWCoYEBEl{padding-top:4px;padding-inline:12px}.YMdfYDnwKFxYWCoYEBEl .react-datepicker__month-container{width:100%}", "",{"version":3,"sources":["webpack://./src/shared/ui/DatePicker/ui/DatePickerDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,mBAAA,CAEE,yDACE,UAAA","sourcesContent":[".wrapCalendarClassNameDropdown {\n  padding-top: 4px;\n  padding-inline: 12px;\n  :global {\n    .react-datepicker__month-container {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapCalendarClassNameDropdown": "YMdfYDnwKFxYWCoYEBEl"
};
export default ___CSS_LOADER_EXPORT___;
