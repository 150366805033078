import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IMenuItemProps, Menu, PageLayout } from 'shared/ui'
import { TriggerRouts } from 'entities/Trigger'
import { TriggersPageStore } from 'pages/triggers/store/triggersPageStore'
import { TriggersPageContext } from 'pages/triggers/context'

const items: IMenuItemProps[] = [
  {
    type: 'nav',
    to: TriggerRouts.all,
    label: 'All triggers',
    icon: 'lightning2',
  },
  {
    type: 'nav',
    to: TriggerRouts.sms,
    label: 'SMS',
    icon: 'messageSmile',
  },
  {
    type: 'nav',
    to: TriggerRouts.ringless,
    label: 'Ringless',
    icon: 'voicemail',
  },
]

export const TriggersLayout = observer(() => {
  const [triggerPageStore] = useState(() => new TriggersPageStore())
  return (
    <TriggersPageContext.Provider value={triggerPageStore}>
      <PageLayout
        leftContent={<Menu title='Triggers' menuItems={items} />}
        store={triggerPageStore.pageLayoutStore}
      />
    </TriggersPageContext.Provider>
  )
})
