import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'

import { IResponseUpdateSegmentEvent, IParamsUpdateSegmentEvent } from './types'

class EventLogApi {
  updateSegmentEvents<P, C, T>(
    payload: IParamsUpdateSegmentEvent<P, C, T>
  ): Promise<AxiosResponse<IResponseUpdateSegmentEvent>> {
    return baseApi.post('segment/v1/events', payload)
  }
}

export const eventLogApi = new EventLogApi()
