import { createContext, useContext } from 'react'
import { BroadcastViewStore } from '../store/broadcastViewStore'

export const BroadcastViewContext = createContext<BroadcastViewStore | null>(null)

export function useBroadcastViewContext() {
  const context = useContext(BroadcastViewContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with BroadcastViewStoreProvider'
    )
  }
  return context
}
