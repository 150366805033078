import { makeAutoObservable } from 'mobx'
import { IResponseFilterOperator } from 'entities/Contacts/api/filterTypes'
import { EmptyOperators } from 'widgets/FilterEditor/model/definition'

export class FilterOperator {
  private _key = ''
  private _name = ''
  private _allow_empty_checkbox = false
  private _is_empty: number | null = null

  constructor(item: IResponseFilterOperator) {
    this._key = item.key
    this._name = item.name
    this._allow_empty_checkbox = item.allow_empty_checkbox ?? false

    makeAutoObservable(this)
  }

  setIsEmpty = (value: number | null) => {
    this._is_empty = value
  }

  get key() {
    return this._key
  }

  get name() {
    return this._name
  }

  get allow_empty_checkbox() {
    return this._allow_empty_checkbox
  }

  get is_empty() {
    return this._is_empty
  }

  get child() {
    if (this._allow_empty_checkbox) {
      return [
        {
          key: 0,
          name: 'Don’t include empty records',
        },
        {
          key: 1,
          name: 'Include empty records',
        },
      ]
    }

    return []
  }

  get payloadIsEmptyOperator() {
    if (EmptyOperators.has(this.key)) {
      return {
        value: [],
      }
    }

    return null
  }

  get payload() {
    if (this._is_empty === null) {
      return {
        operator: this._key,
        ...this.payloadIsEmptyOperator,
      }
    }

    return {
      operator: this._key,
      is_empty: this._is_empty,
      ...this.payloadIsEmptyOperator,
    }
  }
}
