import { makeAutoObservable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { AdminOrganizationsApi, IResponseAdminOrganizationById } from 'entities/Admin'
import { Admin10DLCExemptOptionsAction } from 'pages/admin/pages/organizations/modals/AdminEdit10DLCExemptOptions/Admin10DLCExemptOptionsAction'
import { Admin10DLCExemptOptionsContent } from 'pages/admin/pages/organizations/modals/AdminEdit10DLCExemptOptions/Admin10DLCExemptOptionsContent'

export class AdminEdit10DLCExemptOptionsStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''
  organization: IResponseAdminOrganizationById | null = null
  loadingSubmit = false
  isCanadaOnlyStatus = false
  is10DLCExempt = false

  onSuccess: ((organization: IResponseAdminOrganizationById) => void) | null = null

  reset = () => {
    this.modalId = ''
    this.organization = null
    this.isCanadaOnlyStatus = false
    this.is10DLCExempt = false
  }

  setIsCanadaOnlyStatus = (status: boolean) => {
    this.isCanadaOnlyStatus = status
  }

  setIs10DLCExempt = (status: boolean) => {
    this.is10DLCExempt = status
  }

  onSubmit = async () => {
    try {
      if (!this.organization?.id) {
        return
      }
      runInAction(() => {
        this.loadingSubmit = true
      })
      const { data } = await AdminOrganizationsApi.update10DLCExemptOptions({
        id: this.organization?.id,
        data: {
          is_messaging_canada_only: this.isCanadaOnlyStatus,
          is_10_dlc_exempt: this.is10DLCExempt,
        },
      })
      this.onSuccess && this.onSuccess(data)
      modalStore.closeModal(this.modalId)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loadingSubmit = false
      })
    }
  }

  onOpenModal = (
    organization: IResponseAdminOrganizationById,
    onSuccess: typeof this.onSuccess
  ) => {
    this.reset()
    this.onSuccess = onSuccess
    this.isCanadaOnlyStatus = organization.is_messaging_canada_only
    this.is10DLCExempt = organization.is_10_dlc_exempt
    this.organization = organization
    this.modalId = nanoid()
    modalStore.addModal({
      id: this.modalId,
      title: 'Edit 10DLC exempt options',
      ModalContent: () => (
        <Admin10DLCExemptOptionsContent store={adminEdit10DLCExemptOptionsStore} />
      ),
      width: 480,
      ModalActions: () => (
        <Admin10DLCExemptOptionsAction store={adminEdit10DLCExemptOptionsStore} />
      ),
    })
  }
}

export const adminEdit10DLCExemptOptionsStore = new AdminEdit10DLCExemptOptionsStore()
