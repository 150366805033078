import { makeAutoObservable, runInAction } from 'mobx'
import {
  ChatbotActions,
  IChatbotActionModel,
  ChatbotActionType,
  IChatbotActionType,
  ChatbotErrorsList,
  IChatbotActionSettings,
} from 'entities/Chatbot'
import { ChatbotActionMessageStore } from './chatbotActionMessageStore'
import { ChatbotActionTagStore } from './chatbotActionTagStore'
import { ChatbotActionNoteStore } from './chatbotActionNoteStore'

export type IChatbotActionStores =
  | ChatbotActionMessageStore
  | ChatbotActionNoteStore
  | ChatbotActionTagStore

export type ChatbotStoreTypeMap = {
  [ChatbotActionType.AddTag]: ChatbotActionTagStore
  [ChatbotActionType.RemoveTag]: ChatbotActionTagStore
  [ChatbotActionType.ContactNote]: ChatbotActionNoteStore
  [ChatbotActionType.SendMessage]: ChatbotActionMessageStore
}

export class ChatbotAction {
  private static _storeFactory(model: IChatbotActionModel): IChatbotActionStores {
    switch (model.type) {
      case ChatbotActionType.RemoveTag:
      case ChatbotActionType.AddTag:
        return new ChatbotActionTagStore(model)
      case ChatbotActionType.SendMessage:
        return new ChatbotActionMessageStore(model)
      case ChatbotActionType.ContactNote:
        return new ChatbotActionNoteStore(model)
    }
  }

  private _storesMap = new Map<IChatbotActionModel, IChatbotActionStores>()

  constructor(
    private _actionsModel: ChatbotActions,
    public actionErrors: ChatbotErrorsList<IChatbotActionModel, IChatbotActionSettings>
  ) {
    makeAutoObservable(this)
  }

  get list() {
    return this._actionsModel.actions
  }

  getActionStore<T extends IChatbotActionModel>(model: T): ChatbotStoreTypeMap[T['type']] {
    const store = this._storesMap.get(model) ?? ChatbotAction._storeFactory(model)

    runInAction(() => this._storesMap.set(model, store))

    return store as ChatbotStoreTypeMap[T['type']]
  }

  createAction = (type: IChatbotActionType) => {
    const model = this._actionsModel.addAction(type)
    const store = ChatbotAction._storeFactory(model)

    this._storesMap.set(model, store)
  }

  removeAction = (index: number) => {
    const model = this._actionsModel.removeAction(index)
    const store = this._storesMap.get(model)

    if (!store) return

    this._storesMap.delete(model)
    store?.dispose()
  }
}
