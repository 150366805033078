// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FOTwoxIn9lBHTzCUPaeX{display:flex;padding:2px 8px;align-items:center;width:fit-content;margin-top:8px;border-radius:4px;border:1px solid var(--border-neutral-default);background:var(--background-primary-inverted-primary)}.FOTwoxIn9lBHTzCUPaeX .L1YWAHAuV1VmEnQACRbp{color:var(--content-primary-primary);font-size:11px;font-weight:400}.FOTwoxIn9lBHTzCUPaeX .SuUEINZGIZfs7gHeQWHG{margin-right:4px;background-color:rgba(0,0,0,0);color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/entities/Inbox/ui/InboxTag/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CAEA,iBAAA,CACA,8CAAA,CACA,qDAAA,CAEA,4CACE,oCAAA,CACA,cAAA,CACA,eAAA,CAIF,4CACE,gBAAA,CACA,8BAAA,CACA,qCAAA","sourcesContent":[".wrap {\n  display: flex;\n  padding: 2px 8px;\n  align-items: center;\n  width: fit-content;\n  margin-top: 8px;\n\n  border-radius: 4px;\n  border: 1px solid var(--border-neutral-default);\n  background: var(--background-primary-inverted-primary);\n\n  .name {\n    color: var(--content-primary-primary);\n    font-size: 11px;\n    font-weight: 400;\n  \n  }\n\n  .icon {\n    margin-right: 4px;\n    background-color: transparent;\n    color: var(--content-primary-tertiary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "FOTwoxIn9lBHTzCUPaeX",
	"name": "L1YWAHAuV1VmEnQACRbp",
	"icon": "SuUEINZGIZfs7gHeQWHG"
};
export default ___CSS_LOADER_EXPORT___;
