// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NK3gmsYiTcRjVlrdpx8o{padding:16px 24px 24px 24px;display:flex;flex-direction:column;flex-grow:1;gap:32px}.HksiNhALoEEO9TMHeSAW{flex-grow:1;align-content:flex-end;padding-right:24px}", "",{"version":3,"sources":["webpack://./src/widgets/TriggerActions/ui/TriggerDetailsModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CACA,QAAA,CAGF,sBACE,WAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".wrap {\n  padding: 16px 24px 24px 24px;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  gap: 32px;\n}\n\n.footer {\n  flex-grow: 1;\n  align-content: flex-end;\n  padding-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "NK3gmsYiTcRjVlrdpx8o",
	"footer": "HksiNhALoEEO9TMHeSAW"
};
export default ___CSS_LOADER_EXPORT___;
