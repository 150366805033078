import React, { FC } from 'react'
import { Banner, Modal, Toast } from 'shared/ui'
import { GlobalEvents } from 'entities/WebSocket'
import { PresentationMode } from 'widgets/PresentationMode/ui/PresentationMode'
import { CallPopUp } from 'widgets/CallPopUp'
import { GlobalBanners } from 'widgets/GlobalBanners/GlobalBanners'
import { RetentlyLayout } from './RetentlyLayout'

type ICommonComponentsProps = {
  type: 'react' | 'vue'
}

export const CommonComponents: FC<ICommonComponentsProps> = ({ type }) => {
  return (
    <>
      <Banner globalBanners={<GlobalBanners />} />
      <GlobalEvents />
      <Toast />
      <PresentationMode />
      <RetentlyLayout />
      {type === 'react' && <Modal />}
      {type === 'react' && <CallPopUp />}
    </>
  )
}
