import { makeAutoObservable } from 'mobx'
import { IRow } from 'shared/ui'
import { ISortOrder } from 'shared/api'

type ITableStoreProps<TRow> = {
  customSelectAllOnPageIds?: () => void
  element?: string
  MAX_SELECT_COUNT?: number
  filterRowsFn?: (row: IRow<TRow>) => boolean
  withoutDefaultManageColumns?: boolean
  saveColumns?: (columns: string[]) => Promise<void>
  getColumns?: Promise<string[] | undefined>
  sortBy?: string
  sortOrder?: ISortOrder
}

export class TableStore<TRow = never> {
  MAX_SELECT_COUNT: number | null = 0
  customSelectAllOnPageIds: (() => void) | null = null
  filterRowsFn: ((row: IRow<TRow>) => boolean) | null = null
  withoutDefaultManageColumns = false
  saveColumns: ((columns: string[]) => Promise<void>) | null = null
  getColumns: Promise<string[] | undefined> | null = null
  constructor(props?: ITableStoreProps<TRow>) {
    makeAutoObservable(this)
    this.customSelectAllOnPageIds = props?.customSelectAllOnPageIds || null
    this.element = props?.element || ''
    this.MAX_SELECT_COUNT = props?.MAX_SELECT_COUNT || null
    this.filterRowsFn = props?.filterRowsFn || null
    this.withoutDefaultManageColumns = props?.withoutDefaultManageColumns || false
    this.saveColumns = props?.saveColumns || null
    this.getColumns = props?.getColumns || null
    this.sortBy = props?.sortBy || ''
    this.sortBy = props?.sortBy || ''
    this.sortOrder = props?.sortOrder || 'desc'
  }
  element = ''
  private _total = 0
  get total() {
    if (this.MAX_SELECT_COUNT) {
      return this._total > this.MAX_SELECT_COUNT ? this.MAX_SELECT_COUNT : this._total
    }
    return this._total
  }

  rows: IRow<TRow>[] = []
  get rowsIds() {
    return this.rows.map((row) => row.id)
  }
  setRows = (rows: typeof this.rows) => {
    if (this.filterRowsFn) {
      this.rows = rows.filter(this.filterRowsFn)
    } else {
      this.rows = rows.filter((row) => !row.isDisabled)
    }
  }

  // Sorting
  sortOrder: ISortOrder = 'desc'
  sortBy = ''

  private toggleSortOrder = () => {
    this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc'
  }

  private changeSortBy = (field: string) => {
    if (this.sortBy !== field) {
      this.sortBy = field
      this.sortOrder = 'desc'
    }
  }

  onSort = (field: string | number) => {
    this.toggleSortOrder()
    this.changeSortBy(field as string)
  }

  // Manage columns
  private _visibleColumnsIds: string[] = []
  get visibleColumnsIds() {
    return this._visibleColumnsIds
  }
  setVisibleColumnsIds = async (ids: string[]) => {
    if (this.saveColumns) {
      if (ids.toString() !== this.visibleColumnsIds.toString()) this.saveColumns(ids)
    }
    this._visibleColumnsIds = ids
  }
  initVisibleColumnsIds = async (ids: string[]) => {
    if (this.getColumns) {
      const columns = await this.getColumns
      if (columns) {
        this._visibleColumnsIds = columns
        return
      }
    }
    this._visibleColumnsIds = ids
  }

  // Bulk action
  private selectedRowsMap: Map<string | number, IRow<TRow>> = new Map()
  get selectedRows() {
    return Array.from(this.selectedRowsMap.values())
  }
  get selectedIds() {
    return Array.from(this.selectedRowsMap.keys())
  }
  private bulkAll = false

  get bulkAllMode() {
    return this.bulkAll
  }

  get isSelectedAllOnPages() {
    return this.rowsIds.every((id) => this.selectedRowsMap.has(id))
  }

  resetSelected = () => {
    this.selectedRowsMap.clear()
    this.bulkAll = false
  }

  toggleSelected = (row: IRow<TRow>, isSelected: boolean) => {
    this.bulkAll = false
    if (isSelected) {
      this.selectedRowsMap.set(row.id, row)
    } else {
      this.selectedRowsMap.delete(row.id)
    }
  }
  selectAllOnPageIds = () => {
    this.rows.forEach((row) => {
      this.selectedRowsMap.set(row.id, row)
    })
  }
  unselectAllIds = () => {
    this.selectedRowsMap.clear()
    this.bulkAll = false
  }

  toggleSelectAll = () => {
    this.isSelectedAllOnPages ? this.unselectAllIds() : this.selectAllOnPageIds()
  }
  changeBulkAllMode = (condition: boolean) => {
    if (condition) {
      this.selectAllOnPageIds()
    } else {
      this.unselectAllIds()
    }
    this.bulkAll = condition
  }
  setTotal = (value: number) => {
    this._total = value
  }
  SET_MAX_SELECT_COUNT = (value: number) => {
    this.MAX_SELECT_COUNT = value
  }
}
