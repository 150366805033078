import { useEffect } from 'react'
import { runInAction } from 'mobx'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseEventMessage } from 'entities/Message'
import { Message } from 'entities/Message/model/Message'
import { filtersStore } from 'features/DropdownFilter'
import { canAddConversationToList, ConversationListStore } from 'widgets/ConversationList'

export const EventVoicemailReceived = {
  subscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.on(WebsocketEvent.VoicemailReceived, handler),
  unsubscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.off(WebsocketEvent.VoicemailReceived, handler),
}

export const useEventVoicemailReceived = (conversationListStore: ConversationListStore) => {
  const handler = (data: IResponseEventMessage) => {
    const conversation = conversationStore.getItem(data.conversation.id)
    const message = new Message(data.message)

    if (conversation) {
      runInAction(() => {
        if (conversationListStore.conversationSearchStore.isActive) return
        if (!filtersStore.isShowConversation(conversation, message)) return

        if (canAddConversationToList(data.conversation)) {
          conversationStore.updateItem(data.conversation)
          conversationListStore.updateItemOrder(conversation.id)
        }
      })
    }
  }

  useEffect(() => {
    EventVoicemailReceived.subscribe(handler)

    return () => {
      EventVoicemailReceived.unsubscribe(handler)
    }
  }, [])
}
