import { createContext, useContext } from 'react'
import type { KnowledgeBaseListStore } from 'pages/chatbot/store/knowledgeBaseListStore'

export const KnowledgeBaseListContext = createContext<KnowledgeBaseListStore | null>(null)

export const useKnowledgeBaseListContext = () => {
  const context = useContext(KnowledgeBaseListContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with KnowledgeBaseListContext')

  return context
}
