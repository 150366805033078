// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xMtoBc1er_FHhzd2Cjqm{position:relative;padding:4px}.xMtoBc1er_FHhzd2Cjqm:before{position:absolute;left:0;right:0;bottom:0;z-index:10;height:1px;background-color:var(--border-neutral-default);content:\"\"}.sPRO8m9lsEEIni5ZMsBQ{position:relative;padding:4px}.sPRO8m9lsEEIni5ZMsBQ:before{position:absolute;left:0;right:0;bottom:0;z-index:10;height:1px;background-color:var(--border-neutral-default);content:\"\"}.sPRO8m9lsEEIni5ZMsBQ:last-of-type:before{display:none}", "",{"version":3,"sources":["webpack://./src/widgets/MessageField/ui/DropdownMentions/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CAEA,6BACE,iBAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,UAAA,CACA,8CAAA,CACA,UAAA,CAIJ,sBACE,iBAAA,CACA,WAAA,CAEA,6BACE,iBAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,UAAA,CACA,8CAAA,CACA,UAAA,CAIA,0CACE,YAAA","sourcesContent":[".head {\n  position: relative;\n  padding: 4px;\n\n  &:before {\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 10;\n    height: 1px;\n    background-color: var(--border-neutral-default);\n    content: \"\";\n  }\n}\n\n.section {\n  position: relative;\n  padding: 4px;\n\n  &:before {\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 10;\n    height: 1px;\n    background-color: var(--border-neutral-default);\n    content: \"\";\n  }\n\n  &:last-of-type {\n    &:before {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": "xMtoBc1er_FHhzd2Cjqm",
	"section": "sPRO8m9lsEEIni5ZMsBQ"
};
export default ___CSS_LOADER_EXPORT___;
