import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Alert, Icon, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { messagingRegionModalStore } from 'widgets/MessagingRegionModal'

import styles from './styles.module.scss'

export const MessagingRegionModalContent = observer(() => {
  const { setRegion, regions, selectedRegion } = messagingRegionModalStore
  const ariaLabel = 'MessagingRegionModalContent'

  return (
    <div aria-label={getAriaLabel(ariaLabel)}>
      <Alert
        item={{
          type: 'infoLight',
          title: 'Choose the region of your text message recipients',
          desc: 'Messaging to United States numbers requires carrier compliance registration',
        }}
      />
      <div className={styles.contentWrap} aria-label={getAriaLabel(ariaLabel, 'contentWrap')}>
        {regions.map((region) => (
          <div
            onClick={() => setRegion(region.key)}
            key={region.key}
            className={classNames(styles.region, {
              [styles.selected]: selectedRegion === region.key,
            })}
            aria-label={getAriaLabel(ariaLabel, 'region')}
          >
            <div className={styles.icons} aria-label={getAriaLabel(ariaLabel, 'icons}')}>
              {region.icon ? (
                <Icon icon={region.icon} />
              ) : (
                <>
                  <Icon icon={'flagUSA'} />
                  <div className={styles.divider} aria-label={getAriaLabel(ariaLabel, 'divider')} />
                  <Icon icon={'flagCanada'} />
                </>
              )}
            </div>
            <Typography variant='body-md-regular' ariaLabel={'label'}>
              {region.label}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
})
