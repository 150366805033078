// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RVgNc0oU66PE_Nu32HRE{position:absolute;top:0;left:0;width:100%;height:100%;pointer-events:none;z-index:var(--zIndex-UI_100)}.HmINEV1VqGuDT16dlGGY{pointer-events:auto}.puzFl8y3VMR6t0XHQnv0{display:flex;justify-content:center;align-items:center;pointer-events:auto;border-radius:6px;border:1px solid var(--border-neutral-default)}.gw70dK_fhRgSJnJE5ZTr{position:relative;display:flex;justify-content:center;align-items:center;top:0;left:0;width:100%;height:100%;border-radius:6px;z-index:var(--zIndex-BASE_99)}.Q0fbQaCoag3qY1cFReDA{background:var(--blue-10);border:2px solid var(--action-contained-primary-normal)}", "",{"version":3,"sources":["webpack://./src/shared/ui/DragAndDropFile/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CACA,4BAAA,CAGF,sBACE,mBAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,mBAAA,CACA,iBAAA,CACA,8CAAA,CAGF,sBACE,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,6BAAA,CAGF,sBACE,yBAAA,CACA,uDAAA","sourcesContent":[".dropZoneWrapper {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n  z-index: var(--zIndex-UI_100);\n}\n\n.dragging {\n  pointer-events: auto;\n}\n\n.dropZoneVisible {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  pointer-events: auto;\n  border-radius: 6px;\n  border: 1px solid var(--border-neutral-default);\n}\n\n.dropZone {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border-radius: 6px;\n  z-index: var(--zIndex-BASE_99);\n}\n\n.overlaying {\n  background: var(--blue-10);\n  border: 2px solid var(--action-contained-primary-normal);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropZoneWrapper": "RVgNc0oU66PE_Nu32HRE",
	"dragging": "HmINEV1VqGuDT16dlGGY",
	"dropZoneVisible": "puzFl8y3VMR6t0XHQnv0",
	"dropZone": "gw70dK_fhRgSJnJE5ZTr",
	"overlaying": "Q0fbQaCoag3qY1cFReDA"
};
export default ___CSS_LOADER_EXPORT___;
