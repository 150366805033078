import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownContent, DropdownItem, dropDownStore, Scrollbar, SpinnerLoader } from 'shared/ui'
import { useIntegrationDropdownContext } from 'widgets/IntegrationDropdown/context/integrationDropdownContext'
import styles from '../styles.module.scss'

type IPhoneFieldContentProps = {
  dropdownId: string
}

export const PhoneFieldContent: FC<IPhoneFieldContentProps> = observer(({ dropdownId }) => {
  const { setDropdownId, phoneFieldLoading, phoneFieldsDropdownItems, setPhoneField } =
    useIntegrationDropdownContext()

  useEffect(() => {
    setDropdownId(dropdownId)
  }, [])

  if (phoneFieldLoading) {
    return (
      <DropdownContent>
        <div className={styles.loader}>
          <SpinnerLoader size={20} />
        </div>
      </DropdownContent>
    )
  }

  return (
    <DropdownContent>
      <Scrollbar
        autoHeight
        autoHeightMin={0}
        autoHeightMax={335}
        onScroll={() => {
          dropDownStore.allHide(dropdownId)
        }}
      >
        {phoneFieldsDropdownItems.map((phoneField) => (
          <DropdownItem
            key={phoneField.id}
            item={{ ...phoneField }}
            onChange={(phoneField) => {
              setPhoneField(phoneField)
            }}
          />
        ))}
        {!phoneFieldsDropdownItems.length && <div className={styles.noResult}>No phone fields</div>}
      </Scrollbar>
    </DropdownContent>
  )
})
