import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, IconButton } from 'shared/ui'
import { IUser } from 'entities/Users'
import styles from './styles.module.scss'

type IConversationAssignSelectedItemProps = {
  item?: IUser
  count?: number
  isOpen?: boolean
  handleDeleteItem?: (id: number) => void
}

export const ConversationAssignSelectedItem: FC<IConversationAssignSelectedItemProps> = observer(
  ({ item, count, isOpen, handleDeleteItem }) => {
    if (!item) {
      return (
        <div className={styles.item}>
          <div className={styles.item__name}>+{count}</div>
        </div>
      )
    }

    return (
      <div className={styles.item}>
        <div className={styles.item__avatar}>
          <Avatar size={16} fontSize={10} info={item.avatarInfo} />
        </div>
        <div className={styles.item__name}>{item.name}</div>
        {isOpen && (
          <div
            className={styles.item__delete}
            onClick={() => {
              handleDeleteItem?.(item.id)
            }}
          >
            <IconButton
              icon={'close'}
              color={'transparent'}
              size={'small'}
              fontSize={16}
              ariaLabel='ConversationAssignSelectedItem_delete'
            />
          </div>
        )}
      </div>
    )
  }
)
