// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pdSBuR_0i3PLStdUZoqB{padding:8px 8px 12px 8px}.nUOxXK4_tyHhBMDG70hr{display:flex;justify-content:space-between;margin-bottom:12px}.eM_b9hxgxNO2yTZoltoS{flex-direction:column}.WxaWTkroBYfe58DD4LrI{padding-inline:4px;max-height:300px;overflow-y:auto}", "",{"version":3,"sources":["webpack://./src/widgets/AIAssistant/ui/AIAssistantContent/AnswerContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAEF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,sBACE,qBAAA,CAGJ,sBACE,kBAAA,CACA,gBAAA,CACA,eAAA","sourcesContent":[".wrap{\n  padding: 8px 8px 12px 8px;\n}\n.header{\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 12px;\n  &--short {\n    flex-direction: column;\n  }\n}\n.response{\n  padding-inline: 4px;\n  max-height: 300px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "pdSBuR_0i3PLStdUZoqB",
	"header": "nUOxXK4_tyHhBMDG70hr",
	"header--short": "eM_b9hxgxNO2yTZoltoS",
	"response": "WxaWTkroBYfe58DD4LrI"
};
export default ___CSS_LOADER_EXPORT___;
