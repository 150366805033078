import React, { ChangeEvent, useRef } from 'react'
import classNames from 'classnames'
import { Typography, Icon, IIconsVector } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import styles from '../styles.module.scss'

interface UploadItemProps {
  btnClass: string
  onCloseMenu: () => void
  accept: string[]
  icon: IIconsVector
  label: string
  disabled?: boolean
}

export const UploadItem = ({
  btnClass,
  onCloseMenu,
  accept,
  icon,
  label,
  disabled = false,
}: UploadItemProps) => {
  const messageFieldStore = useMessageFieldContext()
  const ref = useRef<HTMLInputElement>(null)

  const onClick = () => {
    if (ref.current) {
      ref.current.click()
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files) {
      messageFieldStore.addMediaFiles(Array.from(files))
      onCloseMenu()
    }
  }

  return (
    <button
      className={classNames(btnClass, disabled && styles.disabled)}
      onClick={onClick}
      disabled={disabled}
      aria-label={getAriaLabel(label, 'MessageField')}
    >
      <input
        type={'file'}
        className={styles.fileInput}
        ref={ref}
        accept={accept.join(',')}
        onChange={onChange}
        multiple
      />
      <Icon disabled={disabled} icon={icon} fontSize={16} tertiary />
      <Typography variant={'body-md-medium'} ariaLabel={label}>
        {label}
      </Typography>
    </button>
  )
}
