import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { FiltersAndSearchStore } from 'widgets/FiltersAndSearch/store/filtersAndSearchStore'
import { FiltersAndSearchContent } from 'widgets/FiltersAndSearch/ui/FiltersAndSearchContent'
import { FiltersAndSearchContext } from 'widgets/FiltersAndSearch/context/context'
import { FilterEditorProvider } from 'widgets/FilterEditor/context'

type IFiltersProps = {
  store: FiltersAndSearchStore
  parent: HTMLDivElement | null
}

export const FiltersAndSearch: FC<IFiltersProps> = observer(({ store, parent }) => {
  return (
    <FiltersAndSearchContext.Provider value={store}>
      <FilterEditorProvider store={store.filterEditorStore}>
        <FiltersAndSearchContent parent={parent} />
      </FilterEditorProvider>
    </FiltersAndSearchContext.Provider>
  )
})
