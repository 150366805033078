// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qyknYF2CI3fn77fiX3rk{padding:4px}.CqphthC61k0bonsSJepd{color:var(--content-neutral-primary);font-weight:400;display:flex;align-items:center;gap:6px}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotTooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CAGJ,sBACI,oCAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".chatbotTooltip {\n    padding: 4px;\n}\n\n.chatbotTooltipLabel {\n    color: var(--content-neutral-primary);\n    font-weight: 400;\n    display: flex;\n    align-items: center;\n    gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatbotTooltip": "qyknYF2CI3fn77fiX3rk",
	"chatbotTooltipLabel": "CqphthC61k0bonsSJepd"
};
export default ___CSS_LOADER_EXPORT___;
