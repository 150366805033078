import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IParamsCreateCannedMessages,
  IParamsGetCannedMessages,
  IParamsUpdateCannedMessages,
  IResponseCannedMessages,
  IResponseCannedMessage,
  SavedRepliesSortEnum,
  IParamsFavoriteCannedMessages,
} from 'entities/CannedMessages'

class Api {
  getCannedMessages(
    {
      contact_id,
      integration_id,
      page,
      length,
      order = SavedRepliesSortEnum.newest,
      search = '',
      filter = 'personal',
    }: IParamsGetCannedMessages,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseCannedMessages>> {
    return baseApi.get('canned-messages', {
      params: {
        length,
        contact_id,
        integration_id,
        page,
        search: search || undefined,
        filter,
        order,
      },
      ...options,
    })
  }

  createCannedMessages(
    data: IParamsCreateCannedMessages
  ): Promise<AxiosResponse<IResponseCannedMessage>> {
    return baseApi.post('canned-messages/', data)
  }

  updateCannedMessagesById(
    data: IParamsUpdateCannedMessages
  ): Promise<AxiosResponse<IResponseCannedMessage>> {
    return baseApi.put(`canned-messages/${data.id}`, data)
  }

  favoriteCannedMessagesById(
    data: IParamsFavoriteCannedMessages
  ): Promise<AxiosResponse<IResponseCannedMessage>> {
    return baseApi.patch(`canned-messages/${data.id}/favorite`, data)
  }

  deleteCannedMessages(id: number) {
    return baseApi.delete(`canned-messages/${id}`)
  }
}

export const CannedMessagesApi = new Api()
