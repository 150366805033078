import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState, MouseEvent } from 'react'
import { Button, Typography } from 'shared/ui'
import { ModalLargeIcon } from 'shared/ui/Icon/ModalLargeIcon/ModalLargeIcon'
import { ModalType, ModalTypeList } from 'shared/ui/Modal/store/types'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export interface InfoModalContentProps {
  modal: ModalType & { type: ModalTypeList }
}

export const InfoModalContent = observer(({ modal }: InfoModalContentProps) => {
  const { setIsHoverModal } = modalStore
  const {
    id,
    primaryAction,
    secondaryAction,
    ModalContentProps,
    ModalActions,
    additionalSecondaryAction,
    type,
    title,
    desc,
  } = modal

  const [loadingPrimary, setLoadingPrimary] = useState(false)
  const [loadingSecondary, setLoadingSecondary] = useState(false)
  const [loadingAdditionalSecondary, setLoadingAdditionalSecondary] = useState(false)

  const onClose = () => {
    modalStore.closeModal(id)
  }
  const onMouseEnter = () => {
    setIsHoverModal(true)
  }
  const onMouseLeave = () => {
    setIsHoverModal(false)
  }

  const handlePrimary = async (e: MouseEvent<HTMLButtonElement>) => {
    if (primaryAction?.onAction) {
      try {
        setLoadingPrimary(true)
        await primaryAction?.onAction(e)
      } finally {
        setLoadingPrimary(false)
      }
    }
  }

  const handleSecondary = async (e: MouseEvent<HTMLButtonElement>) => {
    if (primaryAction?.onAction) {
      try {
        setLoadingSecondary(true)
        await secondaryAction?.onAction(e)
      } finally {
        setLoadingSecondary(false)
      }
    }
  }

  const handleAdditionalSecondary = async (e: MouseEvent<HTMLButtonElement>) => {
    if (primaryAction?.onAction) {
      try {
        setLoadingAdditionalSecondary(true)
        await additionalSecondaryAction?.onAction(e)
      } finally {
        setLoadingAdditionalSecondary(false)
      }
    }
  }

  const ariaLabel = 'InfoModalContent'

  return (
    <div
      className={styles.wrap}
      aria-label={getAriaLabel(ariaLabel, 'wrap')}
      onClick={(event) => {
        if (modal.isStopPropagation) {
          event.stopPropagation()
        }
      }}
    >
      <div
        className={classNames(styles.card, styles.info)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        aria-label={getAriaLabel(ariaLabel, 'card')}
      >
        <ModalLargeIcon type={type} />
        <div className={styles.content} aria-label={getAriaLabel(ariaLabel, 'content')}>
          <Typography variant={'heading-md'} className={'mb12'} ariaLabel={'title'}>
            {title}
          </Typography>
          <Typography
            variant={'body-rg-regular'}
            className={'textCenter'}
            ariaLabel={'desc'}
            color='var(--content-primary-tertiary)'
          >
            {desc}
          </Typography>
        </div>
        <div className={styles.actions} aria-label={getAriaLabel(ariaLabel, 'actions')}>
          {ModalActions ? (
            <ModalActions {...ModalContentProps} />
          ) : (
            <>
              {primaryAction && (
                <Button
                  typeBtn={'contained'}
                  className={'fullWidth'}
                  text={primaryAction.text}
                  disabled={Boolean(primaryAction.disabled)}
                  onClick={handlePrimary}
                  contained={'primary'}
                  loading={loadingPrimary}
                />
              )}
              {additionalSecondaryAction && (
                <Button
                  typeBtn={'contained'}
                  className={classNames('fullWidth', styles.additionalSecondaryAction)}
                  text={additionalSecondaryAction.text}
                  disabled={Boolean(additionalSecondaryAction.disabled)}
                  onClick={handleAdditionalSecondary}
                  contained={'secondary'}
                  loading={loadingAdditionalSecondary}
                />
              )}
              {secondaryAction && (
                <Button
                  typeBtn={'contained'}
                  className={'fullWidth'}
                  text={secondaryAction.text}
                  disabled={Boolean(secondaryAction.disabled)}
                  onClick={handleSecondary}
                  contained={'secondary'}
                  loading={loadingSecondary}
                />
              )}
              {!secondaryAction && (
                <Button
                  typeBtn={'contained'}
                  className={'fullWidth'}
                  text={modal.infoModalTextAction || 'Close'}
                  onClick={onClose}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
})
