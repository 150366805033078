// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a3rkqbVviwVNOCO7kcS1{display:flex;gap:4px}.Tym531yReMguuV1AUyAT{width:32px;height:32px}.U60UzB4Z5HK7fXzqObz_{display:flex;padding:0}.FNINERn3G3j8t3MbCK5c{color:var(--content-primary-primary-inverted);text-align:center;font-size:11px;font-weight:500;margin-right:4px;cursor:pointer;padding:4px;min-width:28px;border-radius:6px;box-sizing:border-box}.FNINERn3G3j8t3MbCK5c.yRiYqSGKxas5flWme5Ge,.FNINERn3G3j8t3MbCK5c:hover{background:var(--background-primary-inverted-secondary)}.FNINERn3G3j8t3MbCK5c:last-of-type{margin-right:0}", "",{"version":3,"sources":["webpack://./src/shared/ui/SpeedMediaBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CAEF,sBACE,UAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,SAAA,CAGF,sBACE,6CAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,cAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,qBAAA,CAEA,uEAEE,uDAAA,CAGF,mCACE,cAAA","sourcesContent":[".flex4{\n  display: flex;\n  gap: 4px;\n}\n.box32{\n  width: 32px;\n  height: 32px;\n}\n\n.wrap {\n  display: flex;\n  padding: 0;\n}\n\n.item {\n  color: var(--content-primary-primary-inverted);\n  text-align: center;\n  font-size: 11px;\n  font-weight: 500;\n  margin-right: 4px;\n  cursor: pointer;\n  padding: 4px;\n  min-width: 28px;\n  border-radius: 6px;\n  box-sizing: border-box;\n\n  &.active,\n  &:hover {\n    background: var(--background-primary-inverted-secondary);\n  }\n\n  &:last-of-type {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex4": "a3rkqbVviwVNOCO7kcS1",
	"box32": "Tym531yReMguuV1AUyAT",
	"wrap": "U60UzB4Z5HK7fXzqObz_",
	"item": "FNINERn3G3j8t3MbCK5c",
	"active": "yRiYqSGKxas5flWme5Ge"
};
export default ___CSS_LOADER_EXPORT___;
