// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".McRot5iBSIR7buqEVtaQ{text-decoration:none}.McRot5iBSIR7buqEVtaQ .VWyFfGva2ct7zJJkSGI2{white-space:nowrap}.k2GPClwBSdMghwPiSGPF{margin:0}.eYNA420KqdSq3tuTJwIW{display:flex;flex-direction:column;gap:24px;margin-top:32px}.IGl4lq9fOiBySPLmtkg4{display:flex;flex-direction:column;gap:12px}.IGl4lq9fOiBySPLmtkg4 .vWYqILSrqQW26qtQGnpA{text-align:center}.UemZ8suoU96N8S91yGTY{margin-top:16px}.UemZ8suoU96N8S91yGTY .Z0p5M9w1R7rpaVWLpm50{min-height:200px;display:flex;align-items:center;justify-content:center}.UemZ8suoU96N8S91yGTY .LqOIyLnMPPfz8kzxE6Zw{min-height:40px}", "",{"version":3,"sources":["webpack://./src/widgets/GetNumberModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CAEA,4CACI,kBAAA,CAIR,sBACI,QAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,4CACI,iBAAA,CAIR,sBACI,eAAA,CAEA,4CACI,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,4CACI,eAAA","sourcesContent":[".alertAction {\n    text-decoration: none;\n\n    .alertActionText {\n        white-space: nowrap;\n    }\n}\n\n.textFieldIcon {\n    margin: 0;\n}\n\n.contentWrap {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n    margin-top: 32px;\n}\n\n.actionsWrap {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n\n    .text {\n        text-align: center;\n    }\n}\n\n.numbersWrap {\n    margin-top: 16px;\n\n    .loader {\n        min-height: 200px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n\n    .noResults {\n        min-height: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertAction": "McRot5iBSIR7buqEVtaQ",
	"alertActionText": "VWyFfGva2ct7zJJkSGI2",
	"textFieldIcon": "k2GPClwBSdMghwPiSGPF",
	"contentWrap": "eYNA420KqdSq3tuTJwIW",
	"actionsWrap": "IGl4lq9fOiBySPLmtkg4",
	"text": "vWYqILSrqQW26qtQGnpA",
	"numbersWrap": "UemZ8suoU96N8S91yGTY",
	"loader": "Z0p5M9w1R7rpaVWLpm50",
	"noResults": "LqOIyLnMPPfz8kzxE6Zw"
};
export default ___CSS_LOADER_EXPORT___;
