import { formatPhoneNumberNational } from 'shared/lib'
import { Base } from 'models'
import { IParsedVcard } from 'entities/VCard'

export class VCard extends Base {
  first_name: string
  last_name: string
  name: string
  phone: string
  email: string
  color: string
  source?: string
  fileName?: string
  isSave?: boolean
  constructor(
    props: IParsedVcard & {
      source?: string
      fileName?: string | null
      isSave?: boolean
      color?: string
    }
  ) {
    super()
    this.first_name = props.first_name || ''
    this.last_name = props.last_name || ''
    this.phone = formatPhoneNumberNational(props.phone || '')
    this.email = props.email || ''
    this.name = `${this.first_name} ${this.last_name}`.trim() || ''
    this.color = props.color || '#646464'
    this.source = props.source
    this.fileName = props.fileName || ''
    this.isSave = props.isSave || false
  }
}
