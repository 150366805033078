import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { Button, showToast } from 'shared/ui'
import { IIntegrationLinkDTO, integrationsStore } from 'entities/Integrations'
import { contactsCleanupStore } from 'widgets/ContactsTable/store/ContactsCleanupStore'
import { LinkWithPhonesModalActions } from './LinkWithPhones/LinkWithPhonesModalActions'
import { LinkWithPhonesModalContent } from './LinkWithPhones/LinkWithPhonesModalContent'
import { linkContactStore } from '../../store/linkContactStore'

import styles from './styles.module.scss'

const LinkContactModalActions = observer(() => {
  const {
    reset,
    selectedContact,
    targetContact,
    onLink,
    shouldUpdate,
    loading,
    activeTab,
    selectedMatchedId,
    matchedContactsList,
    modalId,
    integrationKey,
    handleSelectedMatchedPhone,
  } = linkContactStore

  const key = selectedContact?.integration_key || selectedContact?.integration_type

  const integration = integrationsStore.getIntegration(key)
  const phoneModalId = nanoid()

  const handleClose = () => {
    reset()
    modalId && modalStore.removeModal(modalId)
  }

  const disabled =
    (activeTab === 'manually' && !selectedContact) ||
    (activeTab === 'matching' && !selectedMatchedId)

  const handleLinkManually = async () => {
    if (!integration || !targetContact) return

    const data: IIntegrationLinkDTO = {
      integration_id: integration.id,
      integration_name: integration.name,
      integration_key: integration.key,
      contact_id: targetContact.id,
      contact_integration_id: selectedContact?.integration_vendor_id || selectedContact?.id || 0,
      first_name: selectedContact?.first_name || '',
      last_name: selectedContact?.last_name || '',
      email: selectedContact?.email || '',
      number: selectedContact?.phoneInfo || '',
      update_contact: shouldUpdate,
    }

    try {
      const toast = await onLink(data)

      showToast(toast)
      if (toast.type === 'success') {
        handleClose()
      }
    } catch (e) {
      console.log(e)
    } finally {
      contactsCleanupStore.contactsUnlinkedTable.load()
    }
  }

  const handleClosePhonesModal = () => {
    modalStore.removeModal(phoneModalId)
  }

  const handleLinkMatching = async () => {
    if (!integrationKey) return

    const integration = integrationsStore.getIntegration(integrationKey)
    if (!integration) return

    const matchedContact = matchedContactsList.find((item) => item.id === selectedMatchedId)
    if (!matchedContact) return

    if (matchedContact.mobile_phone && matchedContact.phone) {
      modalStore.addModal({
        id: phoneModalId,
        showCloseButton: false,
        showCloseIcon: true,
        showHeader: true,
        title: 'Phone number',
        width: 480,
        paddingTopModal: 120,
        onClose: handleClosePhonesModal,
        ModalContent: () => <LinkWithPhonesModalContent contact={matchedContact} />,
        ModalActions: () => (
          <LinkWithPhonesModalActions
            modalId={phoneModalId}
            parentModalId={modalId || ''}
            matchedContact={matchedContact}
            hubspotIntegration={integration}
          />
        ),
      })

      handleSelectedMatchedPhone(matchedContact.phone)

      return
    }

    if (!targetContact) return

    const data: IIntegrationLinkDTO = {
      integration_id: integration.id,
      integration_name: integration.name,
      integration_key: integration.key,
      contact_id: targetContact.id,
      contact_integration_id: matchedContact?.id || '',
      first_name: matchedContact?.first_name || '',
      last_name: matchedContact?.last_name || '',
      email: matchedContact?.email || '',
      number: matchedContact?.phone || matchedContact?.mobile_phone || '',
      update_contact: shouldUpdate,
    }

    try {
      const toast = await onLink(data)

      showToast(toast)
      if (toast.type === 'success') {
        handleClose()
      }
    } catch (e) {
      console.log(e)
    } finally {
      contactsCleanupStore.contactsUnlinkedTable.load()
    }
  }

  const handleLink = () => {
    if (activeTab === 'manually') handleLinkManually()
    if (activeTab === 'matching') handleLinkMatching()
  }

  return (
    <div className={classnames(styles.wrap)}>
      <Button contained='secondary' text='Cancel' onClick={handleClose} disabled={loading} />
      <Button
        loading={loading}
        contained='primary'
        text='Link contact'
        disabled={disabled || loading}
        onClick={handleLink}
      />
    </div>
  )
})

export { LinkContactModalActions }
