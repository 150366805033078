// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PuAl15z9Bj9ucGccdtCF{width:100%;height:1px;background-color:var(--border-neutral-default)}.lZjxbwY1RFykJIhqL3jv{margin:4px 0 4px -4px;width:calc(100% + 8px)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Divider/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,UAAA,CACA,8CAAA,CAEF,sBACE,qBAAA,CACA,sBAAA","sourcesContent":[".divider {\n  width: 100%;\n  height: 1px;\n  background-color: var(--border-neutral-default);\n}\n.dropdown {\n  margin: 4px 0 4px -4px;\n  width: calc(100% + 8px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "PuAl15z9Bj9ucGccdtCF",
	"dropdown": "lZjxbwY1RFykJIhqL3jv"
};
export default ___CSS_LOADER_EXPORT___;
