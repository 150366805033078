// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VCO7hAATKbQeQ58M6aGk{display:flex;min-height:20px;align-items:center;gap:4px;color:var(--content-negative-primary)}.g0KYmmjb6xjW0Ct5JAL1{line-height:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Error/ui/TextError/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,kBAAA,CACA,OAAA,CACA,qCAAA,CAEF,sBACE,gBAAA","sourcesContent":[".error{\n  display: flex;\n  min-height: 20px;\n  align-items: center;\n  gap: 4px;\n  color: var(--content-negative-primary);\n}\n.text{\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "VCO7hAATKbQeQ58M6aGk",
	"text": "g0KYmmjb6xjW0Ct5JAL1"
};
export default ___CSS_LOADER_EXPORT___;
