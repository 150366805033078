import React from 'react'
import { Tooltip, Icon } from 'shared/ui'
import type { Integration } from 'entities/Integrations/model/Integration'

export const TriggerIntegration = ({ integration }: { integration: Integration | null }) => {
  return (
    <Tooltip placement='top' label={integration?.name || 'Custom'}>
      <Icon icon={integration?.icon || 'lightning2'} padding={3} fontSize={14} tertiary insideBox />
    </Tooltip>
  )
}
