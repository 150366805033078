import { Icon } from 'shared/ui'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import styles from './styles.module.scss'

interface IImportContactDropdownActions {
  header: string
  onClose?: () => void
}

export const ImportContactDropdownActions = ({
  header,
  onClose,
}: IImportContactDropdownActions) => {
  const { createField, addDoNotImport, setNewFieldHeader } = importContactsStore

  return (
    <div className={styles.container}>
      <div
        className={styles.dropdownAction}
        onClick={() => {
          onClose?.()
          createField()
          setNewFieldHeader(header)
        }}
      >
        <Icon icon='add' />
        <span className={styles.dropdownActionText}>New field</span>
      </div>
      <div
        className={styles.dropdownAction}
        onClick={() => {
          onClose && onClose()
          addDoNotImport(header)
        }}
      >
        <Icon icon='close' />
        <span className={styles.dropdownActionText}>Don’t import</span>
      </div>
    </div>
  )
}
