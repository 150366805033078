import classNames from 'classnames'
import { useState } from 'react'
import { Icon, IDropdownItem, Typography } from 'shared/ui'
import { Select } from 'shared/ui/Select/Select'
import styles from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/styles.module.scss'
import {
  BrandTwoFaVerificationStatus,
  IStatus,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/SelectStatusEditStatus/VerificationStatus'

export type ISelectStatusEditStatusProps = {
  typeEdit: 'selectStatus'
  options: IDropdownItem[]
  loadOptions?: () => Promise<void>
  value: string | null
  isUnsaved?: boolean
  onChange: (value: string) => void
  editValue: string | null
  editError?: string
}

export const SelectStatusEditStatus = ({
  options,
  onChange,
  editValue,
  isUnsaved,
  loadOptions,
  editError,
}: ISelectStatusEditStatusProps) => {
  const [selectRef, setSelectRef] = useState<HTMLDivElement | null>(null)

  const onSelect = (value: string) => {
    onChange(value)
  }

  const onToggle = async () => {
    if (loadOptions) {
      await loadOptions()
    }
  }

  const selectedItem = options?.find((item) => item.id === editValue)

  return (
    <Select
      type={'default'}
      text={'Select'}
      value={editValue || undefined}
      onSelectValue={onSelect}
      items={options || []}
      dropdownProps={{
        ariaLabel: 'SelectStatusEditStatus',
        onToggle: onToggle,
        width: selectRef?.clientWidth,
        triggerComponent: (open) => (
          <div
            className={classNames(
              styles.value,
              styles.select,
              open && styles.open,
              isUnsaved && styles.isUnsaved,
              editError && styles.editError
            )}
            ref={setSelectRef}
          >
            {selectedItem ? (
              <BrandTwoFaVerificationStatus status={selectedItem?.id as IStatus} />
            ) : (
              <Typography
                variant={'body-md-regular'}
                color={'var(--content-neutral-primary)'}
                ariaLabel={'Select'}
              >
                Select
              </Typography>
            )}
            <Icon icon={'chevronDown'} />
          </div>
        ),
      }}
    />
  )
}
