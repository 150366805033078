import { observer } from 'mobx-react-lite'
import { EnumHelperText, HelperText } from 'shared/ui'
import { useChatbotContext } from 'pages/chatbot/context'

import { AwaitReply } from './AwaitReply'
import styles from './styles.module.scss'

export const FallbackSettings = observer(() => {
  const {
    model: { settings },
    errors,
  } = useChatbotContext()

  const awaitReplyError = errors.getError('settings.await_reply.value')

  return (
    <section className={styles.container}>
      <h4 className={styles.containerTitle}>Trigger the fallback if:</h4>
      <div className={styles.group}>
        <AwaitReply awaitReply={settings.awaitReply} />
      </div>
      {!!awaitReplyError && (
        <HelperText
          icon={'alertTriangleSmall'}
          variant={EnumHelperText.Error}
          text={awaitReplyError}
        />
      )}
    </section>
  )
})
