import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import type {
  IParamsGetTriggers,
  IParamsTestTrigger,
  IResponseGetTriggers,
  IResponseTestTrigger,
} from './type'

class Api {
  getTriggers(params: IParamsGetTriggers): Promise<AxiosResponse<IResponseGetTriggers>> {
    return baseApi.post('triggers/all', params)
  }

  createTest(params: IParamsTestTrigger): Promise<AxiosResponse<IResponseTestTrigger>> {
    console.log(params)
    return baseApi.post('triggers/test', params)
  }
}

export const TriggerApi = new Api()
