import { observer } from 'mobx-react-lite'
import { useMemo, FC } from 'react'
import { CallHistoryContext, CallHistoryStore } from 'widgets/CallHistory'
import { CallHistoryContent } from './CallHistoryContent'

type ICallHistoryProps = {
  store: CallHistoryStore
}

export const CallHistory: FC<ICallHistoryProps> = observer(({ store }) => {
  return useMemo(
    () => (
      <CallHistoryContext.Provider value={store}>
        <CallHistoryContent />
      </CallHistoryContext.Provider>
    ),
    []
  )
})
