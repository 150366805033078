import parse from 'html-react-parser'

export function htmlToText(text: string): string {
  let parse1 = parse(text)
  const customParse = (el: JSX.Element | string | JSX.Element[]): string => {
    if (Array.isArray(el)) {
      return el.map(customParse).join('')
    }
    if (typeof el === 'string') {
      return el
    }
    if (el.type === 'span' && el.props.className?.includes('mergeField')) {
      const content = Array.isArray(el.props.children) ? el.props.children[0] : el.props.children
      return `{ ${content.props['data-key']}${
        content.props['data-fallback'] ? ` | '${content.props['data-fallback']}'` : ''
      } }`
    }
    if (el.type === 'span' && el.props.className?.includes('textarea-tag')) {
      return `[ ${el.props?.['data-key']}${
        el.props?.['data-tag'] ? ` '${el.props['data-tag']}'` : ''
      } ]`
    }
    // mentions
    if (el.type === 'span' && el.props.className?.includes('mentions-wrap')) {
      return el.props?.['data-template'] || ''
    }
    if (el.type === 'a') {
      return `${el.props.children} `
    }
    if (el.type === 'br') {
      return '\n'
    }
    if (!el.props?.children) {
      return ''
    }
    if (el.type === 'div' && el.props.children?.type === 'br') {
      return '\n'
    }
    if (el.type === 'div') {
      return '\n' + customParse(el.props.children)
    }
    return customParse(el.props.children)
  }
  if (Array.isArray(parse1)) {
    parse1 = parse1.map(customParse).join('')
  }
  return customParse(parse1)
}
