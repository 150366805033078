import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import {
  DropdownCard,
  EnumDropdownItemVariant,
  Icon,
  IDropdownItem,
  InputCheckbox,
  InputCheckboxProps,
  Typography,
} from 'shared/ui'
import { type Inbox } from 'entities/Inbox/model/Inbox'
import styles from './styles.module.scss'

type IInboxDropdownCardProps = {
  item: Inbox
  InputCheckboxProps?: InputCheckboxProps
  onChange?: (item: Inbox) => void
  highlighter?: string
  ariaLabel?: string
}

type IInboxCardContentProps = {
  item: Inbox
  highlighter?: string
  isCheckbox?: boolean
}

export const InboxCardContent: FC<IInboxCardContentProps> = ({ item, highlighter, isCheckbox }) => {
  return (
    <div
      className={classnames(styles.wrap, {
        [styles['wrap--checkbox']]: isCheckbox,
      })}
    >
      <div className={styles.icon}>
        <Icon icon={'inboxOpen'} />
      </div>
      <Typography variant={'body-md-regular'} ariaLabel={'name'} className={styles.title}>
        {highlighter ? (
          <Highlighter
            highlightClassName={classnames(styles.highlighter, styles['highlighter--label'])}
            className={styles.name}
            searchWords={highlighter.split(' ')}
            autoEscape={true}
            textToHighlight={item.name}
          />
        ) : (
          item.name
        )}
      </Typography>
    </div>
  )
}

export const InboxDropdownCard: FC<IInboxDropdownCardProps> = observer(
  ({ item, onChange, InputCheckboxProps, highlighter, ariaLabel }) => {
    const renderContent = (isCheckbox: boolean) => {
      return <InboxCardContent item={item} highlighter={highlighter} isCheckbox={isCheckbox} />
    }
    const dropdown: IDropdownItem = {
      id: item.id,
      label: item.name,
      variant: EnumDropdownItemVariant.Custom,
      renderOption: () => {
        return renderContent(false)
      },
    }

    if (InputCheckboxProps) {
      return (
        <InputCheckbox
          className={styles.checkbox}
          {...InputCheckboxProps}
          customLabel={renderContent(true)}
        />
      )
    }

    return (
      <DropdownCard
        item={dropdown}
        ariaLabel={ariaLabel}
        onChange={() => {
          if (onChange) onChange(item)
        }}
      />
    )
  }
)
