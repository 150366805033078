import React, { useState } from 'react'
import { ILabelProps, Label } from 'shared/ui/Label'
import { Button, IButtonProps, ISwitchProps, Switch } from 'shared/ui'

type ISwitchButtonFieldProps = {
  value: boolean
  onClick: () => void
  variant?: 'gray'
  buttonProps?: IButtonProps
  switchProps?: ISwitchProps
  labelProps?: ILabelProps
  isHideSwitch?: boolean
}

export const SwitchField = ({
  value,
  onClick,
  buttonProps,
  switchProps,
  labelProps,
  variant,
  isHideSwitch,
}: ISwitchButtonFieldProps) => {
  const [hover, setHover] = useState(false)

  const getSwitchBackground = () => {
    if (variant === 'gray' && hover && !value) {
      return 'var(--black-40)'
    }
    return
  }
  const getButtonBackground = () => {
    if (variant === 'gray' && hover) {
      return 'var(--background-neutral-primary)'
    }
    return
  }
  return (
    <Button
      titleContent={
        <>
          <Label boxAlignItems={'center'} height={16} {...labelProps} />
          {!isHideSwitch && (
            <Switch value={value} padding={0} background={getSwitchBackground()} {...switchProps} />
          )}
        </>
      }
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      size={'small'}
      typeBtn={'switchBtn'}
      style={{
        background: getButtonBackground(),
      }}
      fullWidth
      onClick={onClick}
      {...buttonProps}
    />
  )
}
