import { IOptInMethodTypes } from 'entities/Compliance'

export enum LocalNumbersRoutes {
  // from business profile steps. Show only in rejected Brand status
  generalInfo = 'general-info',
  businessInfo = 'business-info',
  peopleToContact = 'people-to-contact',

  root = 'local-numbers',
  campaignUseCases = 'campaign-use-cases',
  messages = 'messages',
  optInMethod = 'opt-in-method',
  optInOnlineFormContent = 'opt-in-online-form-consent',
  optInPaperFormContent = 'opt-in-paper-form-consent',
  optInViaTextContent = 'opt-in-via-text-consent',
  optInQrCodeContent = 'opt-in-qr-code-consent',
  forbiddenMessageTopics = 'forbidden-message-topics',
  package = 'package',
  orderSummary = 'order-summary',
  tollFreeNumbers = 'toll-free-numbers',
}

export const IOptInMethodsMap: Partial<Record<LocalNumbersRoutes, IOptInMethodTypes>> = {
  [LocalNumbersRoutes.optInOnlineFormContent]: IOptInMethodTypes.onlineForm,
  [LocalNumbersRoutes.optInPaperFormContent]: IOptInMethodTypes.paperForm,
  [LocalNumbersRoutes.optInViaTextContent]: IOptInMethodTypes.text,
  [LocalNumbersRoutes.optInQrCodeContent]: IOptInMethodTypes.qrCode,
}

export const IOptInYTypeMap: Partial<Record<IOptInMethodTypes, LocalNumbersRoutes>> = {
  [IOptInMethodTypes.onlineForm]: LocalNumbersRoutes.optInOnlineFormContent,
  [IOptInMethodTypes.paperForm]: LocalNumbersRoutes.optInPaperFormContent,
  [IOptInMethodTypes.text]: LocalNumbersRoutes.optInViaTextContent,
  [IOptInMethodTypes.qrCode]: LocalNumbersRoutes.optInQrCodeContent,
}
