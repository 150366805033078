import { makeAutoObservable, toJS } from 'mobx'
import { Segment } from 'entities/Segment/model/Segment'

import { Tag } from 'entities/Tags/model/Tag'
import { Contact } from 'entities/Contacts/model/Contact'
import { IResponseFiltersListSegment } from 'entities/Segment'
import { IChatbotFilters } from './type'

export class ChatbotFilters {
  tagIds: number[] = []
  segmentIds: number[] = []
  contactIds: number[] = []
  advanced: IResponseFiltersListSegment[] = []

  constructor() {
    makeAutoObservable(this)
  }

  syncOrigin(filters?: IChatbotFilters | null) {
    this.tagIds = filters?.tags ?? []
    this.segmentIds = filters?.segments ?? []
    this.contactIds = filters?.contacts ?? []
    this.advanced = filters?.advanced ?? []
  }

  syncTagIds(tags?: Tag[]) {
    this.tagIds = tags?.map(({ id }) => id) ?? []
  }

  syncSegmentIds(segments?: Segment[]) {
    this.segmentIds = segments?.map(({ id }) => id) ?? []
  }

  syncContactIds(contacts?: Contact[]) {
    this.contactIds = contacts?.map(({ id }) => id) ?? []
  }

  syncAdvanced(filters?: Segment[]) {
    this.advanced = filters?.map(({ filtersParams }) => filtersParams) ?? []
  }

  toJSON(): IChatbotFilters {
    return toJS({
      contacts: toJS(this.contactIds),
      segments: toJS(this.segmentIds),
      tags: toJS(this.tagIds),
      advanced: toJS(this.advanced),
    })
  }

  reset() {
    this.tagIds = []
    this.segmentIds = []
    this.contactIds = []
    this.advanced = []
  }
}
