import { IDropdownItem } from 'shared/ui'
import { IInboxCombine, inboxesStore } from 'entities/Inbox'
import { ConversationMenuStore } from 'widgets/ConversationMenu/store/conversationMenuStore'

export const typeTeamsMute = 'mute'

type IActionTeamsMute = {
  inbox: IInboxCombine
  item: IDropdownItem
}

export const menuTeamsMute = (inbox: IInboxCombine): IDropdownItem => {
  if (inbox.type !== 'inbox') {
    return {
      id: typeTeamsMute,
      label: '',
    }
  }

  return {
    id: typeTeamsMute,
    iconL: inbox.isMuted ? 'bellFilled' : 'bell',
    label: inbox.isMuted ? 'Unmute inbox' : 'Mute inbox',
  }
}

export const actionTeamsMute = async (
  { inbox, item }: IActionTeamsMute,
  store: ConversationMenuStore
) => {
  if (inbox.type !== 'inbox') return
  if (item.id === typeTeamsMute) {
    await inboxesStore.handleUpdateTeamsMute(inbox, !inbox.isMuted)
    store.initInboxesTree()
  }
}
