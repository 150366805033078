import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventActivityCreated } from 'entities/Activity/api/types'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export const EventActivityCreated = {
  subscribe: (handler: (data: IResponseEventActivityCreated) => void) =>
    websocket.on(WebsocketEvent.ConversationsActivityCreated, handler),
  unsubscribe: (handler: (data: IResponseEventActivityCreated) => void) =>
    websocket.off(WebsocketEvent.ConversationsActivityCreated, handler),
}

export const useEventActivityCreated = (conversationMessageStore: ConversationMessagesStore) =>
  useEffect(
    () =>
      EventActivityCreated.subscribe((data) => {
        if (
          data.activity_log.conversation_id === conversationMessageStore.conversationId &&
          conversationMessageStore.isShowActivity
        ) {
          conversationMessageStore.setItems([data.activity_log])
        }
      }),
    []
  )
