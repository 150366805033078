// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v4praGzdFLuhLuBQpphz{margin:16px 0 8px 0}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/SavedRepliesContent/LeftContent/SavedRepliesOrder/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA","sourcesContent":[".wrapOrder{\n  margin: 16px 0 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapOrder": "v4praGzdFLuhLuBQpphz"
};
export default ___CSS_LOADER_EXPORT___;
