// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aVNuMks0AHi7FOwBO8Ob,.CeilhNypxrUyy1m9XfNa{display:flex;padding-block:8px;border-bottom:1px solid var(--interaction-neutral-normal)}.aVNuMks0AHi7FOwBO8Ob:hover,.CeilhNypxrUyy1m9XfNa:hover{border-bottom:1px solid var(--interaction-neutral-hovered)}.aVNuMks0AHi7FOwBO8Ob.rrOI8HZaKNyY9CdcJzPb,.CeilhNypxrUyy1m9XfNa.rrOI8HZaKNyY9CdcJzPb{border-bottom:1px solid var(--interaction-brand-active);box-shadow:0 2px 0 0 var(--interaction-brand-focus)}.aVNuMks0AHi7FOwBO8Ob.vCdQ_4LpZ1vzxstWfHK8,.CeilhNypxrUyy1m9XfNa.vCdQ_4LpZ1vzxstWfHK8{border-bottom:1px solid var(--red-60);box-shadow:none;margin-bottom:4px}.CeilhNypxrUyy1m9XfNa{width:100%}", "",{"version":3,"sources":["webpack://./src/widgets/MessageFormFields/RinglessMessageField/ui/RinglessMessageFieldActions/styles.module.scss"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,iBAAA,CACA,yDAAA,CACA,wDACE,0DAAA,CAEF,sFACE,uDAAA,CACA,mDAAA,CAEF,sFACE,qCAAA,CACA,eAAA,CACA,iBAAA,CAGJ,sBACE,UAAA","sourcesContent":[".wrap,\n.recordAudio {\n  display: flex;\n  padding-block: 8px;\n  border-bottom: 1px solid var(--interaction-neutral-normal);\n  &:hover {\n    border-bottom: 1px solid var(--interaction-neutral-hovered);\n  }\n  &.active {\n    border-bottom: 1px solid var(--interaction-brand-active);\n    box-shadow: 0 2px 0 0 var(--interaction-brand-focus);\n  }\n  &.errorState {\n    border-bottom: 1px solid var(--red-60);\n    box-shadow: none;\n    margin-bottom: 4px;\n  }\n}\n.recordAudio {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "aVNuMks0AHi7FOwBO8Ob",
	"recordAudio": "CeilhNypxrUyy1m9XfNa",
	"active": "rrOI8HZaKNyY9CdcJzPb",
	"errorState": "vCdQ_4LpZ1vzxstWfHK8"
};
export default ___CSS_LOADER_EXPORT___;
