// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R8o2paZW34FBVQUxQbOK{background:linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 14.34%);padding-top:40px;padding-bottom:64px;position:sticky;bottom:0px;display:grid;grid-template-columns:auto auto 1fr auto;gap:8px}.R8o2paZW34FBVQUxQbOK.ZtPCqngdSCf6f2kSZx6G{grid-template-columns:1fr}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/BroadcastActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,0EAAA,CACA,gBAAA,CACA,mBAAA,CACA,eAAA,CACA,UAAA,CACA,YAAA,CACA,wCAAA,CACA,OAAA,CACA,2CACE,yBAAA","sourcesContent":[".wrap{\n  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 14.34%);\n  padding-top: 40px;\n  padding-bottom: 64px;\n  position: sticky;\n  bottom: 0px;\n  display: grid;\n  grid-template-columns: auto auto 1fr auto;\n  gap: 8px;\n  &.isTest{\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "R8o2paZW34FBVQUxQbOK",
	"isTest": "ZtPCqngdSCf6f2kSZx6G"
};
export default ___CSS_LOADER_EXPORT___;
