import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionItem, Button, ContainerHeader } from 'shared/ui'
import { broadcastStore, BroadcastRoutesEnum } from 'entities/Broadcast'

type IBroadcastsHeaderProps = {
  title: string
}

export const BroadcastsHeader = observer(({ title }: IBroadcastsHeaderProps) => {
  const { pageLayoutStore, isEmptyState } = broadcastStore
  const { titleIconProps, headerMenuTooltipIconProps, headerMenuIconAction } = pageLayoutStore

  const navigate = useNavigate()
  const onClick = () => {
    navigate(`/${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.create}`)
  }

  const actions: ActionItem[] = useMemo(() => {
    if (isEmptyState) {
      return []
    }
    return [
      {
        iconButtonComponent: (
          <Button
            text={'New broadcast'}
            onClick={onClick}
            iconProps={{ icon: 'add' }}
            size={'medium'}
          />
        ),
      },
    ]
  }, [isEmptyState])
  return (
    <ContainerHeader
      height={72}
      title={title}
      titleVariant={'heading-md'}
      actions={actions}
      titleIconProps={titleIconProps}
      actionIconTooltipProps={headerMenuTooltipIconProps}
      titleIconAction={headerMenuIconAction}
      paddingLeft={20}
      paddingRight={20}
    />
  )
})
