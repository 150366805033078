// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hmGs8qJXYuiNwUAxEpPa{width:100%}.hmGs8qJXYuiNwUAxEpPa.QW_jGLAxuRUjmctQYV7O{background:var(--black-20);border-radius:6px}.hmGs8qJXYuiNwUAxEpPa .xzFy9lFegEvcp_Alxijo{height:6px;background-color:var(--content-brand-primary);border-top-right-radius:6px;border-bottom-right-radius:6px;transition:.5s}.hmGs8qJXYuiNwUAxEpPa .BuLTeSsJq9Kq4l2j7rxm{border-radius:6px}", "",{"version":3,"sources":["webpack://./src/shared/ui/ProgressBar/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,2CACE,0BAAA,CACA,iBAAA,CAGF,4CACE,UAAA,CACA,6CAAA,CACA,2BAAA,CACA,8BAAA,CACA,cAAA,CAEF,4CACE,iBAAA","sourcesContent":[".wrap {\n  width: 100%;\n\n  &.withBg {\n    background: var(--black-20);\n    border-radius: 6px;\n  }\n\n  .progress {\n    height: 6px;\n    background-color: var(--content-brand-primary);\n    border-top-right-radius: 6px;\n    border-bottom-right-radius: 6px;\n    transition: 0.5s;\n  }\n  .allBorders {\n    border-radius: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "hmGs8qJXYuiNwUAxEpPa",
	"withBg": "QW_jGLAxuRUjmctQYV7O",
	"progress": "xzFy9lFegEvcp_Alxijo",
	"allBorders": "BuLTeSsJq9Kq4l2j7rxm"
};
export default ___CSS_LOADER_EXPORT___;
