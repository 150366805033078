import { Outlet } from 'react-router'
import { observer } from 'mobx-react-lite'
import { IconButton, Typography } from 'shared/ui'
import { RightMenu } from './RightMenu'

import styles from './styles.module.scss'
import { useSettingsContext } from '../context/settingsContext'

export const Content = observer(() => {
  const { settingsTitle, settingsStep, decreaseStep } = useSettingsContext()

  return (
    <div className={styles.contentContainer}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Typography variant='body-xs-regular' ariaLabel='Settings_title'>
            {settingsTitle}
          </Typography>
        </div>
        {settingsStep > 1 && (
          <div className={styles.backButton}>
            <IconButton
              color='transparent'
              ariaLabel='back'
              icon='arrowLeft'
              size='medium'
              onClick={decreaseStep}
            />
          </div>
        )}
        <Outlet />
      </div>
      <RightMenu />
    </div>
  )
})
