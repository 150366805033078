import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useKeyGenerator } from 'shared/hooks'
import { useFilterEditorContext } from 'widgets/FilterEditor/context'
import type { FilterGroup as Group } from 'widgets/FilterEditor/model'

import styles from './styles.module.scss'
import { FilterGroup } from './FilterGroup'

type IFilterGroupsProps = {
  parentsDropdownId?: string[]
}

export const FilterGroups: FC<IFilterGroupsProps> = observer(({ parentsDropdownId }) => {
  const {
    groups: { groups },
  } = useFilterEditorContext()
  const getGroupKey = useKeyGenerator<Group>()

  return (
    <div className={styles.filterGroupList}>
      {groups.map((group) => (
        <FilterGroup key={getGroupKey(group)} group={group} parentsDropdownId={parentsDropdownId} />
      ))}
    </div>
  )
})
