import { uiStore } from 'shared/store/uiStore'

export const createDeepLinkByContactIds = (ids: number[]) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete('contact_id')
  ids.forEach((id) => {
    searchParams.append('contact_id', id.toString())
  })
  uiStore.navigate?.(`${window.location.pathname}?${searchParams}`)
}

export const createDeepLinkByInboxId = (id: number) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete('inbox_id')
  searchParams.append('inbox_id', id.toString())
  uiStore.navigate?.(`${window.location.pathname}?${searchParams}`)
}
