import { CSSProperties, FC } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

type IDividerProps = {
  style?: CSSProperties
  className?: string
  variant?: 'dropdown'
}

export const Divider: FC<IDividerProps> = ({ style, className, variant }) => {
  return (
    <div
      className={classNames(styles.divider, variant && styles[variant], className)}
      style={style}
    />
  )
}
