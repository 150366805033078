// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hHAwVokS5epynGoE3MfI{width:100%;height:1px;background-color:var(--border-neutral-default);margin:4px 0}", "",{"version":3,"sources":["webpack://./src/widgets/MediaLibrary/helpers/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,UAAA,CACA,8CAAA,CACA,YAAA","sourcesContent":[".divider {\n  width: 100%;\n  height: 1px;\n  background-color: var(--border-neutral-default);\n  margin: 4px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "hHAwVokS5epynGoE3MfI"
};
export default ___CSS_LOADER_EXPORT___;
