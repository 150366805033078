// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yG1bCPuEU8Y3iWo88Kwh,.zdZoUSTarb7AUTkRER4x{display:flex;flex-direction:column;gap:16px;padding-top:16px}.ZwfJ9px2nYfGwk3Ys1mF{height:23px;display:flex;align-items:center;justify-content:center;width:100%;color:var(--content-primary-disabled)}", "",{"version":3,"sources":["webpack://./src/widgets/DayContent/styles.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,gBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,qCAAA","sourcesContent":[".wrap, .messagesWrap{\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding-top: 16px;\n}\n\n.date{\n  height: 23px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  color: var(--content-primary-disabled);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "yG1bCPuEU8Y3iWo88Kwh",
	"messagesWrap": "zdZoUSTarb7AUTkRER4x",
	"date": "ZwfJ9px2nYfGwk3Ys1mF"
};
export default ___CSS_LOADER_EXPORT___;
