import { observer } from 'mobx-react-lite'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { useKeyGenerator } from 'shared/hooks'
import { ChatbotField } from 'entities/Chatbot'
import { CustomField } from 'entities/CustomField'
import { useChatbotContext } from 'pages/chatbot/context'

import { FieldCard } from './FieldCard'

export interface IFieldCardsProps {
  fields: ChatbotField[]
  onDelete: (index: number) => void
  onReorder: (source: number, target: number) => void
  onUpdate: (index: number, payload: CustomField) => void
}

export const FieldCards = observer(
  ({ fields, onDelete, onReorder, onUpdate }: IFieldCardsProps) => {
    const { errors } = useChatbotContext()
    const getFieldKey = useKeyGenerator<ChatbotField>()

    const handleUpdate = (index: number, field: CustomField) => onUpdate(index, field)
    const handleDelete = (index: number, field: ChatbotField) => {
      onDelete(index)
      errors.fields.removeError(field)
    }
    const handleReorder = (result: DropResult) => {
      if (result.destination) onReorder(result.source.index, result.destination.index)
    }

    return (
      <DragDropContext onDragEnd={handleReorder}>
        <Droppable droppableId='textbot-fields'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => (
                <Draggable key={getFieldKey(field)} draggableId={getFieldKey(field)} index={index}>
                  {({ innerRef, draggableProps, dragHandleProps }) => (
                    <FieldCard
                      {...draggableProps}
                      {...dragHandleProps}
                      ref={innerRef}
                      field={field}
                      onDelete={() => handleDelete(index, field)}
                      onUpdate={(field) => handleUpdate(index, field)}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
)
