import { makeAutoObservable, reaction, IReactionDisposer } from 'mobx'
import { IconButton } from 'shared/ui'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { Inbox } from 'entities/Inbox/model/Inbox'
import { inboxesStore } from 'entities/Inbox'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import { ConversationMessagesStore } from 'widgets/ConversationMessages'
import { ConversationNewSearchStore } from 'widgets/ConversationNew'
import type { ContactsDetailsStore } from 'widgets/ContactsDetails'

export class ContactsConversationOpenStore {
  private _conversationNewSearchStore = new ConversationNewSearchStore()
  private _conversationMessagesStore = new ConversationMessagesStore()
  private _contact: Contact | null = null
  private _inbox: Inbox | null = null
  private _disposeInit: IReactionDisposer | null = null
  private _disposeContact: IReactionDisposer | null = null
  private _disposeInbox: IReactionDisposer | null = null
  private _disposeConversation: IReactionDisposer | null = null

  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    this._conversationNewSearchStore.setConfig({
      headerActions: (
        <IconButton
          icon={'linkExternal'}
          color={'secondary'}
          variant={'icon'}
          ariaLabel='LinkExternal'
          onClick={this.openNewConversation}
          tooltipProps={{
            label: 'Open in Conversations',
            placement: 'top',
          }}
        />
      ),
      readOnly: true,
      autoSelectInbox: false,
      changeInbox: async (inbox) => {
        this.setInbox(inbox)
        await this.initConversation()
      },
    })

    this._conversationMessagesStore.setConfig({
      conversationNewSearchStore: this._conversationNewSearchStore,
      startSendMessage: this.createConversation,
      enableTrialAlertLimitConversations: true,
      isCheckInbox: true,
    })

    makeAutoObservable(this)
  }

  setSubSource = (value: string) => {
    this._conversationMessagesStore.messageFieldStore.setSubSource(value)
  }

  initReaction = () => {
    this._conversationMessagesStore.initReactions()
    this.reactionInit()
    this.reactionInbox()
    this.reactionContact()
    this.reactionConversation()
  }

  initConversation = async () => {
    if (!this._inbox) return
    if (!this._contact) return

    try {
      this._conversationMessagesStore.messageFieldStore.setDisabledMessageField(true)

      const conversationResponse = await ConversationsApi.getConversationsSearch({
        tags: [],
        teams: [this._inbox.id],
        members: [],
        contacts: [this._contact.id],
        page: 1,
      }).then((data) => data.data.results[0])

      if (conversationResponse) {
        const conversation = conversationStore.addItem({
          ...conversationResponse,
          is_locked: false,
        })

        this.setConversationId(conversation.id)
        this._contactsDetailsStore.setConversation(conversation)
      } else {
        this._contactsDetailsStore.setConversation(null)
      }

      this._conversationMessagesStore.messageFieldStore.setDisabledMessageField(false)
      this._conversationMessagesStore.resetMessage()

      if (conversationResponse?.id) {
        this._conversationMessagesStore.setLoading(true)
        await this._conversationMessagesStore.loadMessages()
      } else {
        await this._conversationMessagesStore.loadMessagesContact({
          before: this._conversationMessagesStore.loadingBeforeId,
        })
      }

      this._conversationMessagesStore.setScrollBottomTrigger()
    } catch (e) {
      console.log(e)
    } finally {
      this._conversationMessagesStore.messageFieldStore.setDisabledMessageField(false)
    }
  }

  reactionConversation = () => {
    this._disposeConversation?.()
    this._disposeConversation = reaction(
      () => this._contactsDetailsStore.conversation,
      (conversation) => {
        if (conversation) {
          this._contactsDetailsStore.setConversation(conversation)
          this._conversationMessagesStore.setConversationId(conversation.id, false)
        } else {
          this._contactsDetailsStore.setConversation(null)
          this._conversationMessagesStore.resetCurrentConversationId()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionInit = () => {
    this._disposeInit?.()
    this._disposeInit = reaction(
      () => this._contactsDetailsStore.contact,
      (contact) => {
        if (contact) {
          this.setContact(contact)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionInbox = () => {
    this._disposeInbox?.()
    this._disposeInbox = reaction(
      () => this._inbox,
      (inbox) => {
        if (!inbox) return

        const inboxId = inbox.id
        const numberId = inbox.numberId

        this._contactsDetailsStore.contactsProfileCardStore.setInboxId(inbox.id)
        this._conversationNewSearchStore.handleSetActiveNumber(inboxId, numberId)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionContact = () => {
    this._disposeContact?.()
    this._disposeContact = reaction(
      () => this._contact,
      (value) => {
        if (!value) return

        this._conversationNewSearchStore.handleAddItem(value)
      },
      {
        fireImmediately: true,
      }
    )
  }

  setContact = (item: Contact) => {
    this._contact = item
  }

  setInbox = (item: Inbox) => {
    this._inbox = item
    this._contactsDetailsStore.contactsProfileCardStore.setInboxId(item.id)
  }

  open = (inboxId?: number) => {
    const inbox = inboxesStore.getItem(inboxId) as Inbox | undefined

    this._contactsDetailsStore.contactsDetailsGlobalStore?.handleChangeCollapse(false)
    this._contactsDetailsStore.contactsDetailsGlobalStore?.setOverlay(true)

    if (inbox) this.setInbox(inbox)
  }

  createConversation = async () => {
    if (this._conversationMessagesStore.conversationId) return
    if (!this._contact) return
    if (!this._inbox) return

    try {
      this._conversationMessagesStore.messageFieldStore.setLoading(true)

      const conversationResponse = await ConversationsApi.create({
        contact_id: this._contact.id,
        team_id: this._inbox.id,
      }).then((data) => data.data)

      const conversation = conversationStore.addItem(conversationResponse)
      this._contactsDetailsStore.setConversation(conversation)
    } catch (e) {
      console.log(e)
    }
  }

  setConversationId = (conversationId: number) => {
    this._conversationMessagesStore.setConversationId(conversationId, false)
  }

  openNewConversation = () => {
    if (!this._contact) return

    this._contactsDetailsStore.contactsDetailsGlobalStore?.handleChangeCollapse(false)
    this._contactsDetailsStore.contactsDetailsGlobalStore?.setOverlay(false)

    if (this._contactsDetailsStore.conversation) {
      conversationStore.setSignal({
        type: 'open_exist_conversation',
        payload: this._contactsDetailsStore.conversation.origin,
      })
    } else {
      conversationStore.setSignal({
        type: 'open_new_conversation',
        payload: {
          contact: this._contact,
          inbox: this._inbox,
        },
      })
    }

    this._contactsDetailsStore.emitOpenConversationSignal?.()
    this._contactsDetailsStore.onDeleteContactsDetails?.()
  }

  reset = () => {
    this._conversationMessagesStore.resetReactions()
    this._conversationMessagesStore.resetCurrentConversationId()
    this._inbox = null
    this._contact = null
    this._disposeInit?.()
    this._disposeInbox?.()
    this._disposeContact?.()
    this._disposeConversation?.()
  }

  get conversationNewSearchStore() {
    return this._conversationNewSearchStore
  }

  get conversationMessagesStore() {
    return this._conversationMessagesStore
  }

  get contactsDetailsStore() {
    return this._contactsDetailsStore
  }

  get inbox() {
    return this._inbox
  }
}
