import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import { IResponseSubscription } from 'entities/Subscription/api/types'

class Api {
  getSubscription(): Promise<AxiosResponse<IResponseSubscription>> {
    return baseApi.get('organization/subscription')
  }
}

export const SubscriptionApi = new Api()
