import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'
import React from 'react'
import { DayjsFormats, numberFormat } from 'shared/lib'
import { Status } from 'shared/ui/Status'
import { BooleanStatus, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { SubscriptionStatus } from 'entities/Subscription'
import { adminOrganizationDetailStore } from 'pages/admin/pages/organizations/pages/OrganizationDetails/store/adminOrganizationDetailStore'
import { AdminDetailCard, IAdminDetailCardRowProps, AdminRoutesEnum } from 'pages/admin'

export const StripeInformation = observer(() => {
  const { organization, autoRechargeInfo, stripeInfo } = adminOrganizationDetailStore
  if (!organization) {
    return null
  }

  const { is_tax_free } = organization

  const {
    created_at,
    status,
    renewal_date,
    active_for,
    mrr,
    arr,
    total_spend,
    auto_recharge,
    plan,
    plan_interval,
    churned_date,
    scheduled_plan,
  } = stripeInfo

  const data: IAdminDetailCardRowProps[] = [
    {
      title: 'Signup date',
      value: created_at,
      renderValue: (value) => uiStore.dayjs(value as string).format(DayjsFormats.full2),
    },
    {
      title: 'Subscription status',
      value: status || '',
      renderValue: () => (
        <Box display={'flex'} flexWrap={'wrap'} gap={1} alignItems={'center'}>
          {status && <SubscriptionStatus status={status} />}
          {renewal_date && (
            <Typography variant={'body-md-regular'} ariaLabel={'renews'}>
              -{'>'} (renews {uiStore.dayjs(renewal_date).format(DayjsFormats.full6)})
            </Typography>
          )}
        </Box>
      ),
    },
    {
      title: 'Active for',
      value: active_for,
    },
    {
      title: 'MRR',
      value: mrr,
      renderValue: (value) =>
        numberFormat({
          value: value ? Number(value) / 100 : 0,
          currency: 'USD',
        }),
    },
    {
      title: 'ARR',
      value: arr,
      renderValue: (value) =>
        numberFormat({
          value: value ? Number(value) / 100 : 0,
          currency: 'USD',
        }),
    },
    {
      title: 'Total spend',
      value: total_spend,
      renderValue: (value) =>
        numberFormat({
          value: value ? Number(value) / 100 : 0,
          currency: 'USD',
        }),
    },
    {
      title: 'Auto-recharge',
      value: '',
      renderValue: () => (
        <Box display={'flex'} flexWrap={'wrap'} gap={1} alignItems={'center'}>
          <>
            {auto_recharge?.active ? (
              <Status title={'Active'} intent={'positive'} emphasis={'high'} icon={'check2'} />
            ) : (
              <Status
                title={'Disabled'}
                intent={'neutral'}
                emphasis={'high'}
                icon={'minusCircle'}
              />
            )}
            <Typography variant={'body-md-regular'} ariaLabel={'autoRechargeInfo'}>
              {autoRechargeInfo}
            </Typography>
          </>
        </Box>
      ),
    },
    {
      title: 'Plan',
      value: plan,
      renderValue: () => (
        <Box display={'flex'} gap={'8px'}>
          <Typography variant={'body-md-regular'} ariaLabel={'planInfo'}>
            {plan}
          </Typography>
          <a
            href={`/${AdminRoutesEnum.admin}/${AdminRoutesEnum.customPrices}`}
            rel={'noopener noreferrer'}
            target={'_blank'}
            className={'noTextDecoration'}
          >
            <Typography variant={'body-md-regular'} link ariaLabel={'customPriceLink'}>
              Go to Custom Price
            </Typography>
          </a>
        </Box>
      ),
    },
    {
      title: 'Plan interval',
      value: plan_interval,
    },
    {
      isHidden: !scheduled_plan?.label,
      title: 'Scheduled Plan',
      value: scheduled_plan?.label || null,
    },
    {
      isHidden: !scheduled_plan?.interval,
      title: 'Scheduled Plan Interval',
      value: scheduled_plan?.interval || null,
    },
    {
      title: 'Churned date',
      value: churned_date,
      renderValue: (value) =>
        churned_date ? uiStore.dayjs(value as string).format(DayjsFormats.full6) : 'N/A',
    },
    {
      title: 'Tax Free',
      value: is_tax_free ? 'Yes' : 'No',
      renderValue: () => <BooleanStatus value={is_tax_free} />,
    },
  ]
  return (
    <AdminDetailCard title={'Stripe information'} data={data} cardStyles={{ gridArea: 'stripe' }} />
  )
})
