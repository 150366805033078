// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q5yKFC54HJNMu0DdatG4{display:flex;flex-direction:column;gap:4px}.UWikii9arRWbG0WwQSdm{color:var(--content-primary-primary-inverted)}.UWikii9arRWbG0WwQSdm a{color:var(--light-blue-50);text-decoration:none}.d1J9cLYr8OVhhDbdYGk0{line-height:15px;letter-spacing:-0.07px;color:var(--content-primary-tertiary-inverted)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Tooltip/ui/TooltipDescription/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,6CAAA,CAEA,wBACE,0BAAA,CACA,oBAAA,CAIJ,sBACE,gBAAA,CACA,sBAAA,CACA,8CAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.label {\n  color: var(--content-primary-primary-inverted);\n\n  a {\n    color: var(--light-blue-50);\n    text-decoration: none;\n  }\n}\n\n.desc {\n  line-height: 15px;\n  letter-spacing: -0.07px;\n  color: var(--content-primary-tertiary-inverted);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Q5yKFC54HJNMu0DdatG4",
	"label": "UWikii9arRWbG0WwQSdm",
	"desc": "d1J9cLYr8OVhhDbdYGk0"
};
export default ___CSS_LOADER_EXPORT___;
