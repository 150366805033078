import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { Chatbot } from 'entities/Chatbot'
import { Attachment } from 'entities/Attachment/model/Attachment'
import {
  EmojiAction,
  EnumVariantMessageField,
  MessageFieldStore,
  SavedRepliesAction,
  ShortenLinkAction,
  makeSavedReplyInputAction,
  mergeFieldReplacer,
} from 'widgets/MessageField'
import { MergeFieldsIconAction } from 'widgets/MergeField/ui/actions/MergeFieldsIconAction/MergeFieldsIconAction'
import { MediaAction } from 'widgets/MessageField/ui/FieldActions/MediaAction/MediaAction'
import { MergeField } from 'widgets/MergeField/types/MergeField'

export class ChatbotMessage {
  private _disposeFieldReaction: IReactionDisposer | null = null

  isAirCall = false

  field = new MessageFieldStore({
    isCurrentAirCall: this.isAirCall,
    placeholder: 'Please provide the initial message for the textbot to send',
    minHeight: 106,
    variant: EnumVariantMessageField.Broadcast,
    showActionsItems: 5,
    resetOnUnmount: false,
    textLimit: {
      maxLength: 1600,
      message: 'Message should not be longer than 1.600 characters',
    },
    makeInputActions: (store) => [makeSavedReplyInputAction(store)],
    makeActions: () => [
      { iconButtonComponent: <MediaAction /> },
      { iconButtonComponent: <SavedRepliesAction /> },
      { iconButtonComponent: <EmojiAction /> },
      {
        iconButtonComponent: (
          <MergeFieldsIconAction
            onAddMergeField={(value) => this._handleMergeField(value)}
            isAllIntegration
          />
        ),
      },
      { iconButtonComponent: <ShortenLinkAction /> },
    ],
  })

  constructor(private _chatbot: Chatbot, isAirCall: boolean) {
    makeAutoObservable(this)

    this.isAirCall = isAirCall

    this._disposeFieldReaction = reaction(
      () => [this.field.text, this.field.attachments] as [string, Attachment[]],
      ([text, attachments]) => {
        const prevAttachments = this._chatbot.firstMessage.attachments
        const attachmentsAreSame =
          prevAttachments.length === attachments.length &&
          prevAttachments.every((attachment) => attachments.includes(attachment))

        const prevText = this._chatbot.firstMessage.message
        const textIsSame = prevText === text

        if (attachmentsAreSame && textIsSame) return

        this._chatbot.firstMessage.sync(text, attachments)
      }
    )
  }

  syncMessage = () => {
    const message = this._chatbot.firstMessage

    this.field.setMessageData({
      message: message.message,
      attachments: message.attachments,
      isReset: false,
      isReplace: true,
    })
  }

  dispose = () => {
    this._disposeFieldReaction?.()
  }

  private _handleMergeField = (field: MergeField) => {
    if (!this.field.addContent) return

    const fieldContent = mergeFieldReplacer(field.template) + '&nbsp;'

    this.field.addContent(fieldContent)
  }
}
