import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button } from 'shared/ui'
import { AudioPlayer } from 'shared/ui/AudioPlayer'
import { useTextToSpeechContext } from 'widgets/TextToSpeech'
import styles from './styles.module.scss'

export const PlaySpeechBtn = observer(() => {
  const { loadAudioSpeech, isDisabledPreview, loadingSpeech, audioSpeechSrc, selectedVoice } =
    useTextToSpeechContext()

  const [playing, setPlaying] = useState(false)

  const handlePlay = async () => {
    await loadAudioSpeech()
    setPlaying(true)
  }

  useEffect(() => {
    if (playing) {
      setPlaying(false)
    }
  }, [selectedVoice])

  const togglePlaying = (value: boolean) => {
    if (!value) {
      setPlaying(false)
    }
  }

  return (
    <div className={styles.wrap}>
      {loadingSpeech && (
        <Button
          icon={'play'}
          iconProps={{
            className: styles.icon,
            disabled: true,
          }}
          iconClassName={styles.wrapIcon}
          size={'small'}
          contained={'secondary'}
          text={'Generating audio...'}
          className={styles.disabled}
        />
      )}
      {!loadingSpeech &&
        (playing ? (
          <AudioPlayer
            url={audioSpeechSrc}
            widthWave={140}
            size={'small'}
            colorType={'gray'}
            noRightActions
            autoplay
            togglePlaying={togglePlaying}
            className={styles.player}
          />
        ) : (
          <Button
            icon={'play'}
            iconProps={{
              className: styles.icon,
              disabled: isDisabledPreview,
            }}
            iconClassName={styles.wrapIcon}
            size={'small'}
            contained={'secondary'}
            onClick={handlePlay}
            text={'Play'}
            className={classNames(isDisabledPreview && styles.disabled)}
          />
        ))}
    </div>
  )
})
