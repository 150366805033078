import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SearchElement } from 'shared/ui'
import { getFilterIcon } from 'widgets/FilterEditor/utils'
import { useFilterEditorContext } from 'widgets/FilterEditor/context'
import type { FilterItem } from 'widgets/FilterEditor/model'

import { WidgetOperators } from './WidgetOperators'
import { getWidgetComponent } from './widgetComponent'

export interface IWidgetProps {
  filterItem: FilterItem
  onRemove: () => void
  parentsDropdownId?: string[]
}

export const Widget = observer(({ filterItem, onRemove, parentsDropdownId }: IWidgetProps) => {
  const { viewOnly, showSpawned, groups } = useFilterEditorContext()
  const Component = getWidgetComponent(filterItem)
  const expandByDefault =
    showSpawned && filterItem.showSpawned && filterItem === groups.spawnedFilter

  useEffect(() => {
    return () => {
      showSpawned && filterItem.setShowSpawned(false)
    }
  }, [])

  return (
    <SearchElement
      icon={getFilterIcon(filterItem.config)}
      type={filterItem.config.name}
      isViewOnly={viewOnly}
      paramContent={
        <WidgetOperators filterItem={filterItem} parentsDropdownId={parentsDropdownId} />
      }
      content={
        <Component
          filterItem={filterItem}
          expandByDefault={expandByDefault}
          parentsDropdownId={parentsDropdownId}
        />
      }
      removeCallback={onRemove}
    />
  )
})
