import { observer } from 'mobx-react-lite'
import { TextField, makeTextFieldError, makeTextFieldIcon } from 'shared/ui/TextField'
import { ChatbotField } from 'entities/Chatbot'
import { ChatbotTextTooltip } from 'pages/chatbot/ui/ChatbotTooltip'
import { useChatbotContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export interface IFieldCardNameProps {
  field: ChatbotField
}

export const FieldCardName = observer(({ field }: IFieldCardNameProps) => {
  const { errors } = useChatbotContext()
  const fieldError = errors.fields.getError(field, 'name')

  const handleChange = (value: string) => {
    field.setName(value)
    errors.fields.removeError(field, 'name')
  }

  return (
    <div className={styles.cardInput}>
      <span className={styles.cardInputName}>
        <ChatbotTextTooltip
          className={styles.cardInputTooltip}
          tooltip='This is how your Textbot will reference the property in conversation'
        >
          <span className={styles.cardInputTooltipText}>Display Name</span>
        </ChatbotTextTooltip>
      </span>
      <div className={styles.cardInputControl}>
        <TextField
          variant='outline'
          size='medium'
          value={field.name}
          className={styles.cardInputText}
          onChange={handleChange}
          leftActions={[
            makeTextFieldIcon({
              icon: 'hash',
              className: styles.cardInputIcon,
            }),
          ]}
          error={fieldError}
          rightActions={[
            makeTextFieldError({ placement: 'bottom', anchor: 'container', offset: [0, 0] }),
          ]}
          InputProps={{
            placeholder: 'Set a friendly name (optional)',
          }}
        />
      </div>
    </div>
  )
})
