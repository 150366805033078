import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import dayjs, { Dayjs } from 'dayjs'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { DayjsFormats } from 'shared/lib/dayjs'
import { uiStore } from 'shared/store/uiStore'
import { ScheduleContent } from 'widgets/ConversationSchedule'

export type IScheduledData = {
  send_at?: string
  is_contact_timezone?: boolean
  stop_on_response?: boolean
}

type IOnScheduleProps = ({ scheduledData }: { scheduledData: IScheduledData }) => void

class ConversationScheduleStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''
  contactTZ = ''
  scheduleDate: Dayjs = dayjs(0)
  is_contact_timezone = true
  stop_on_response = false

  onSchedule: IOnScheduleProps | null = null

  get contactDateString() {
    return this.scheduleDate
      .tz(uiStore.timezone, true)
      .tz(this.contactTZ)
      .format(DayjsFormats.fullWithAt)
  }

  get offsetMinutes() {
    return (
      dayjs.tz(dayjs(), this.is_contact_timezone ? this.contactTZ : uiStore.timezone).utcOffset() -
      dayjs().utcOffset()
    )
  }

  get isAlreadyPassed() {
    const sendDate = dayjs(this.scheduleDate)
      .tz(this.is_contact_timezone ? this.contactTZ : uiStore.timezone, true)
      .utc()
      .valueOf()
    const nowDate = dayjs().utc().valueOf()

    return sendDate < nowDate
  }

  get scheduleText() {
    const todayDay = uiStore.dayjs().format(DayjsFormats.dateSort)
    const scheduleDay = this.scheduleDate.format(DayjsFormats.dateSort)

    const isToday = todayDay === scheduleDay

    return isToday
      ? `Schedule today at ${this.scheduleDate.format(DayjsFormats.time2)}`
      : `Schedule on ${this.scheduleDate.format(DayjsFormats.fullWithAt)}`
  }

  reset = () => {
    this.contactTZ = ''
    this.scheduleDate = dayjs(0)
    this.is_contact_timezone = true
    this.stop_on_response = false
  }

  setOpenModal = ({
    contactTZ,
    send_at,
    is_contact_timezone,
    stop_on_response,
    onSchedule,
  }: {
    contactTZ: string
    onSchedule: IOnScheduleProps
    send_at?: Dayjs | Date | null
  } & Omit<IScheduledData, 'send_at'>) => {
    if (send_at instanceof Date) {
      const tzDate = dayjs(send_at)
        .utc(true)
        .tz(is_contact_timezone ? contactTZ : uiStore.timezone)
      const localDate = tzDate.utcOffset(0, true).tz(dayjs.tz.guess(), true)

      send_at = localDate
    }

    const scheduleDate = send_at ?? dayjs().add(1, 'hour').startOf('hour')

    this.modalId = nanoid()
    this.contactTZ = contactTZ
    this.scheduleDate = scheduleDate
    this.is_contact_timezone = is_contact_timezone ?? true
    this.stop_on_response = stop_on_response ?? false
    this.onSchedule = onSchedule
    modalStore.addModal({
      id: this.modalId,
      pureContent: true,
      ModalContent: ScheduleContent,
      title: 'Schedule',
      width: 344,
    })
  }

  onCloseModal = () => {
    modalStore.closeModal(this.modalId)
    this.reset()
  }

  checkAlreadyPassed() {
    return this.isAlreadyPassed
  }

  handleSchedule = () => {
    const isAlreadyPassed = this.checkAlreadyPassed()

    if (!isAlreadyPassed && this.onSchedule) {
      const sendDate = dayjs(this.scheduleDate)
        .tz(this.is_contact_timezone ? this.contactTZ : uiStore.timezone, true)
        .utc()

      this.onSchedule({
        scheduledData: {
          is_contact_timezone: this.is_contact_timezone,
          stop_on_response: this.stop_on_response,
          send_at: sendDate.format(DayjsFormats.apiFormat),
        },
      })
      this.onCloseModal()
    }
  }

  toggleIsUseContactTimezone = () => {
    this.is_contact_timezone = !this.is_contact_timezone
    this.checkAlreadyPassed()
  }
  toggleIsCancelOnResponse = () => {
    this.stop_on_response = !this.stop_on_response
  }

  setScheduleDate = (date: Date | null) => {
    this.scheduleDate = dayjs(date || 0)
    this.checkAlreadyPassed()
  }
}

export const scheduleStore = new ConversationScheduleStore()
