import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotCollapse } from 'pages/chatbot/ui/ChatbotCollapse'

import { SendFrom } from './SendFrom'
import { FirstMessage } from './FirstMessage'
import styles from './styles.module.scss'

export const StepFirstMessage = observer(() => {
  const { errors, numberId } = useChatbotContext()

  const isNestedError = errors.hasError('send_from') || errors.hasError('first_message')
  const firstMessageError = isNestedError ? 'Please complete all the fields' : null

  useEffect(() => {
    numberId.store.init()
  }, [])

  return (
    <ChatbotCollapse
      icon='messageSmile'
      title='Create your first message'
      tooltip='Write the initial message a contact will receive from your Textbot'
      error={firstMessageError}
    >
      <div className={styles.container}>
        <FirstMessage />
        <SendFrom />
      </div>
    </ChatbotCollapse>
  )
})
