import { FilterItem } from 'widgets/FilterEditor/model'

export const NumberOperators = new Set([
  'is_more_than',
  'is_less_than',
  'updated_in_last',
  'not_updated_in_last',
])

export const DateTypes = new Set(['created_at', 'date', 'date_without_checkbox', 'updated_created'])
export const DateTimeTypes = new Set(['date_time', 'date_time_shortcode'])
export const DatePickerTypes = new Set([...DateTypes.values(), ...DateTimeTypes.values()])

export const isDateTextWidget = (filterItem: FilterItem) =>
  !!filterItem.operator &&
  DatePickerTypes.has(filterItem.type) &&
  NumberOperators.has(filterItem.operator.key)
