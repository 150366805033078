import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { ChatbotStore } from 'pages/chatbot'

const ChatbotContext = createContext<ChatbotStore | null>(null)

export const useChatbotContext = () => {
  const context = useContext(ChatbotContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ChatbotContextProvider')

  return context
}

export const ChatbotProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new ChatbotStore(), [])

  useEffect(() => store.dispose, [])

  return <ChatbotContext.Provider value={store}>{children}</ChatbotContext.Provider>
}
