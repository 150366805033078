import { ReactNode, useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, IIconsVector, Typography } from 'shared/ui'
import { AudioPlayer } from 'shared/ui/AudioPlayer/ui/AudioPlayer'
import { Message } from 'entities/Message/model/Message'
import { usersStore } from 'entities/Users'
import { MessageCardType } from 'widgets/MessageCard/ui/types'
import { CallContentStore } from 'widgets/MessageCard/store/callContentStore'
import { ConversationMessageCardItem } from 'widgets/MessageCard/ui/ConversationMessageCardItem/ConversationMessageCardItem'
import { DateContent } from 'widgets/MessageCard/ui/DateContent/DateContent'
import { CallConference } from 'widgets/MessageCard/ui/CallContent/CallConference/CallConference'
import { AirCollWrapper } from 'widgets/MessageCard/ui/CallContent/AirCollWrapper'
import styles from './styles.module.scss'

type ICallContentProps = {
  message: Message
  isLast?: boolean
  setScrollBottomTrigger: () => void
  callContentStore?: CallContentStore
}

export const CallContent: FC<ICallContentProps> = observer(
  ({ callContentStore, message, isLast, setScrollBottomTrigger }) => {
    const {
      isOutbound,
      icon,
      record,
      id,
      transcriptions,
      forwardInfo,
      isOnlyRecord,
      airCallLink,
      isCallConference,
      isConversationsHistory,
      status,
    } = message

    const iconVector: IIconsVector | null = useMemo(() => {
      if (forwardInfo) {
        return 'arrowRight'
      }
      if (icon === 'icon_inbound_call') {
        return 'arrowLeftDown'
      }
      if (icon === 'icon_outbound_call') {
        return 'arrowRightUp'
      }
      if (icon === 'icon_missed_call') {
        return 'missed'
      }
      if (icon === 'icon_voicemail') {
        return 'voicemail'
      }
      if (icon === 'icon_conference_call') {
        return isOutbound ? 'arrowRightUp' : 'arrowLeftDown'
      }
      return null
    }, [isOutbound])

    const text: ReactNode = useMemo(() => {
      if (forwardInfo) {
        return `Forwarded to ${forwardInfo.phoneNumber}`
      }
      if (icon === 'icon_inbound_call') {
        return 'Incoming call'
      }
      if (icon === 'icon_outbound_call') {
        return 'Outgoing call'
      }
      if (icon === 'icon_missed_call') {
        return 'Missed call'
      }
      if (icon === 'icon_voicemail') {
        return 'Voicemail'
      }
      if (icon === 'icon_conference_call') {
        return 'Conference call'
      }
      return ''
    }, [isOutbound])

    if (isCallConference) {
      return <CallConference message={message} iconVector={iconVector} />
    }

    const retryTranscript = () => {
      if (status === 'transcript') return
      callContentStore?.retryTranscript(id)
    }

    return (
      <>
        {!isOnlyRecord && (
          <ConversationMessageCardItem message={message} type={MessageCardType.Call}>
            <div
              className={classNames(styles.wrapCallContent, {
                [styles.conversationsHistoryView]: message.isConversationsHistory,
              })}
            >
              <div className={styles.wrap}>
                {iconVector && (
                  <div
                    className={classNames(
                      styles.wrapIcon,
                      iconVector === 'missed' && styles.missed
                    )}
                  >
                    <Icon icon={iconVector} tertiary={iconVector !== 'missed'} fontSize={20} />
                  </div>
                )}
                <div className={styles.content}>
                  <Typography variant={'body-rg-medium'} ariaLabel={'text'}>
                    <AirCollWrapper airCallLink={airCallLink}>{text}</AirCollWrapper>
                  </Typography>
                  {
                    <div className={'row4'}>
                      <DateContent message={message} />
                      {forwardInfo?.duration && (
                        <>
                          <div className={'point'} />
                          <Typography variant={'text-input-helper'} ariaLabel={'duration'}>
                            {forwardInfo?.duration}
                          </Typography>
                        </>
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </ConversationMessageCardItem>
        )}
        {record && (
          <ConversationMessageCardItem message={message} type={MessageCardType.Call}>
            <div
              className={classNames(
                styles.wrapCallContent,
                isConversationsHistory && styles.conversationsHistoryView
              )}
            >
              {record && (
                <AudioPlayer
                  url={record.url}
                  is_auto_transcribe={record.is_auto_transcribe}
                  transcript_status={record.transcript_status}
                  id={`${id}${record.id}`}
                  transcriptions={transcriptions}
                  widthWave={134}
                  retryTranscript={retryTranscript}
                  canTranscript={!callContentStore?.isOrganizationOutOfMessage}
                  disableTranscript={
                    usersStore.user?.isViewOnlyRole &&
                    record.transcript_status === null &&
                    !record.is_auto_transcribe
                  }
                  hideTranscriptionButton={isConversationsHistory}
                  setScrollBottomTrigger={isLast ? setScrollBottomTrigger : undefined}
                />
              )}
            </div>
          </ConversationMessageCardItem>
        )}
      </>
    )
  }
)
