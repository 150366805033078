import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  ProviderContactsQuickLinks,
  useContactsQuickLinksContext,
} from 'widgets/ContactsDetails/ui/ContactsQuickLinks/context/contactsQuickLinksContext'
import type { ContactsQuickLinksStore } from 'widgets/ContactsDetails/ui/ContactsQuickLinks'
import { QuickLinksNoResults } from './QuickLinksNoResults'
import { QuickLinksSearch } from './QuickLinksSearch'

export const ContactsQuickLinksSearchContent = observer(() => {
  const store = useContactsQuickLinksContext()
  const contactId = store.selectedContactId

  if (contactId == null) return <QuickLinksNoResults />

  return <QuickLinksSearch contactId={contactId} />
})

type IContactsQuickLinksSearchProps = {
  store: ContactsQuickLinksStore
}

export const ContactsQuickLinksSearch: FC<IContactsQuickLinksSearchProps> = observer(
  ({ store }) => {
    return (
      <ProviderContactsQuickLinks value={store}>
        <ContactsQuickLinksSearchContent />
      </ProviderContactsQuickLinks>
    )
  }
)
