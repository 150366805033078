import { Typography, Icon } from 'shared/ui'
import step1Img from 'shared/assets/images/auth/step1.png'
import appStoreBadge from 'shared/assets/images/auth/appStore_badge.png'
import googlePlayBadge from 'shared/assets/images/auth/googlePlay_badge.png'
import { CDN_FRONT_URL } from 'shared/config'

import { links } from 'shared/constants/links'
import styles from './styles.module.scss'

export const RightContent = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <p className={styles.title}>One SMS platform for your entire company</p>
        <Typography
          className={styles.subtitle}
          ariaLabel={'step1Subtitle'}
          variant={'body-lg-regular'}
        >
          ‍2-way texting, SMS marketing, calling, & more
        </Typography>
      </div>
      <div className={styles.imageWrap}>
        <img className={styles.image} alt='step1Image' src={`${CDN_FRONT_URL}${step1Img}`} />
      </div>
      <div className={styles.footer}>
        <div className={styles.badgesWrap}>
          <a href={links.appStore} target='_blank' rel='noreferrer'>
            <img className={styles.badge} alt='appStore' src={`${CDN_FRONT_URL}${appStoreBadge}`} />
          </a>
          <a href={links.googlePlay} target='_blank' rel='noreferrer'>
            <img
              className={styles.badge}
              alt='googlePlay'
              src={`${CDN_FRONT_URL}${googlePlayBadge}`}
            />
          </a>
        </div>
        <div className={styles.starsWrap}>
          {[...Array(5)].map((el, idx) => (
            <Icon key={idx} icon={'starFilled1'} color={'var(--yellow-60)'} fontSize={24} />
          ))}
          <p className={styles.text}>4.9 rating of 1,465 reviews</p>
        </div>
      </div>
    </div>
  )
}
