import { useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown, DropdownContent, Button } from 'shared/ui'
import { inboxesStore, TYPES_INBOX } from 'entities/Inbox'
import { conversationStore } from 'entities/Conversation'
import {
  ButtonMakePriority,
  ButtonOptInStatus,
  ButtonManageTags,
  ButtonMarkAsPending,
} from 'widgets/ConversationCard'
import { ConversationAssignDropdownContent } from 'widgets/ConversationAssignDropdown'
import { useConversationListContext } from 'widgets/ConversationList/context'

type PropsDropDownChildren = {
  callback?: () => void
  onCloseMenu: () => void
  parentDropdownId: string
}

export const DropdownActionsChildren: FC<PropsDropDownChildren> = observer(
  ({ parentDropdownId, onCloseMenu }) => {
    const store = useConversationListContext()
    const { selectedHasUnfavoriteConversation, selectedContactsListIds } = store
    const { currentItem } = conversationStore
    const { currentInboxType } = inboxesStore

    const [showAssignDropdown, setShowAssignDropdown] = useState(false)
    const onCloseAssignDropdown = () => setShowAssignDropdown(false)

    if (!currentItem) return null

    return (
      <DropdownContent>
        {currentInboxType !== TYPES_INBOX.GROUP && currentInboxType !== TYPES_INBOX.UNIFIED && (
          <Dropdown
            ariaLabel={'DropdownActionsChildren'}
            show={showAssignDropdown}
            onChangeOpen={setShowAssignDropdown}
            parentsDropdownId={[parentDropdownId]}
            width={192}
            margin={-2}
            placement={'right-start'}
            triggerComponent={() => (
              <Button
                fontWeight={400}
                typeBtn='menuItem'
                icon='userLine'
                text='Assign'
                iconRightProps={{
                  icon: 'chevronRight',
                }}
                fullWidth
                onMouseEnter={() => setShowAssignDropdown(true)}
              />
            )}
            customComponent={(onCloseMenuChild) => (
              <ConversationAssignDropdownContent
                store={store.conversationAssignDropdownStore}
                conversationId={currentItem.id}
                isBulk={true}
                show={showAssignDropdown}
                onCloseMenu={() => {
                  onCloseMenuChild()
                  onCloseMenu()
                }}
              />
            )}
          />
        )}
        <ButtonMakePriority
          isFavorite={!selectedHasUnfavoriteConversation}
          clickCallback={onCloseMenu}
          onMouseEnter={onCloseAssignDropdown}
        />
        <ButtonManageTags clickCallback={onCloseMenu} onMouseEnter={onCloseAssignDropdown} />
        <ButtonOptInStatus
          contactsIds={selectedContactsListIds}
          clickCallback={onCloseMenu}
          onMouseEnter={onCloseAssignDropdown}
        />
        <ButtonMarkAsPending clickCallback={onCloseMenu} onMouseEnter={onCloseAssignDropdown} />
      </DropdownContent>
    )
  }
)
