import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { useMediaController } from 'widgets/PresentationMode/hooks/useMediaController'
import { useRecordAudioContext } from 'widgets/RecordAudio/context'
import styles from './styles.module.scss'

export const AudioRecord = observer(() => {
  const { recordStore } = useRecordAudioContext()
  const { mediaControllerStore, src } = recordStore

  const ref = useRef<HTMLVideoElement>(null)

  const { handelEnded, onLoadedData } = useMediaController({
    mediaControllerStore,
    ref,
  })

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio
      onLoadedData={onLoadedData}
      onEnded={handelEnded}
      ref={ref}
      src={src}
      className={styles.record}
    />
  )
})
