import { observer } from 'mobx-react-lite'
import React from 'react'
import { Label } from 'shared/ui/Label'
import { TagsControl, TagsSelect } from 'entities/Tags'
import styles from './styles.module.scss'

type TagsDropdownProps = {
  store: TagsControl
}

export const TagsDropdown = observer(({ store }: TagsDropdownProps) => {
  return (
    <div className={styles.wrap}>
      <Label
        label={{
          text: 'Tags',
          horizontal: true,
          className: styles.label,
        }}
      />
      <TagsSelect
        control={store}
        canCreate
        placeholder='Select tags'
        searchPlaceholder='Search tag'
        variant='formField'
      />
    </div>
  )
})
