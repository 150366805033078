import { makeAutoObservable } from 'mobx'
import type { IResponseIntegrationHubspotDeal } from 'entities/Integrations'
import type { IIntegrationsDeal, IIntegrationsProperty } from 'entities/Integrations/model/types'
import { IntegrationProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationProperty'

export class IntegrationDealProperty {
  private _id: number | null = null
  private _name: string | null = null
  private _propertiesMap: Map<string, IntegrationProperty> = new Map()

  constructor(item: IIntegrationsDeal) {
    this._id = !isNaN(Number(item.id.value)) ? Number(item.id.value) : 0
    this._name = item.dealname.value
    this.addProperties(item)

    makeAutoObservable(this)
  }

  get id() {
    return this._id || 0
  }

  get title() {
    return this._name || ''
  }

  get amount() {
    return this.getProperty('amount')?.value || ''
  }

  get closedate() {
    return this.getProperty('closedate')?.value || ''
  }

  get dealstage() {
    return this.getProperty('dealstage')?.value || ''
  }

  get pipeline() {
    return this.getProperty('pipeline')?.value || ''
  }

  get properties() {
    return Array.from(this._propertiesMap.values())
  }

  get propertiesKeys() {
    return this.properties.map((item) => item.key)
  }

  get owner() {
    const value = this.getProperty('owner')?.value
    const first_name = this.getProperty('owner')?.first_name
    const last_name = this.getProperty('owner')?.last_name

    return {
      value: value || '',
      first_name: first_name || '',
      last_name: last_name || '',
    }
  }

  addProperties = (item: IIntegrationsDeal) => {
    Object.entries(item).forEach(([k, item]) => {
      const key = k as keyof IIntegrationsDeal

      if (key === 'dealname') return
      if (key === 'id') return
      if (key === 'amount') item.label = 'Amount'
      if (key === 'closedate') item.label = 'Close date'
      if (key === 'dealstage') item.label = 'Stage'
      if (key === 'pipeline') item.label = 'Pipeline'

      if (key === 'owner') {
        const owner = item as IIntegrationsDeal['owner']

        item.label = 'Owner'
        item.value =
          owner?.value ||
          (owner?.first_name || owner?.last_name ? `${owner.first_name} ${owner.last_name}` : null)
      }

      this.addProperty({
        key: key,
        label: item.label || '',
        value: item.value ? String(item.value) : '',
      })
    })
  }

  addProperty = (item: IIntegrationsProperty) => {
    this._propertiesMap.set(item.key, new IntegrationProperty(item))
  }

  removeProperty = (key: string) => {
    this._propertiesMap.delete(key)
  }

  getProperty = (key: keyof IResponseIntegrationHubspotDeal) => {
    return this._propertiesMap.get(key)
  }
}
