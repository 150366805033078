import { observer } from 'mobx-react-lite'
import React, { FC, useMemo } from 'react'
import { LoadingGeneration } from 'widgets/AIAssistant/ui/AIAssistantContent/LoadingGeneration/LoadingGeneration'
import { AnswerContent } from 'widgets/AIAssistant/ui/AIAssistantContent/AnswerContent/AnswerContent'
import { InitContent } from 'widgets/AIAssistant/ui/AIAssistantContent/InitContent/InitContent'
import { AiAssistantStore } from 'widgets/AIAssistant'

interface IAIAssistantContentProps {
  aiAssistantStore: AiAssistantStore
}
export const AIAssistantContent: FC<IAIAssistantContentProps> = observer(({ aiAssistantStore }) => {
  const { triggerElement, loadingGeneration, answer, isColumn } = aiAssistantStore

  const content = useMemo(() => {
    if (loadingGeneration) {
      return <LoadingGeneration />
    }
    if (answer) {
      return <AnswerContent aiAssistantStore={aiAssistantStore} />
    }
    return <InitContent aiAssistantStore={aiAssistantStore} />
  }, [loadingGeneration, answer])

  return (
    <div
      style={{
        width: isColumn
          ? 282
          : triggerElement?.clientWidth && triggerElement?.clientWidth < 400
          ? 400
          : triggerElement?.clientWidth,
      }}
    >
      {content}
    </div>
  )
})
