import { observer } from 'mobx-react-lite'
import {
  Alert,
  Box,
  Button,
  IconButton,
  IDropdownItem,
  TextFieldWithLabel,
  Typography,
  UploadDoc,
} from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { formatBytesToNumberWithType, getCDNAssetURL } from 'shared/lib'
import einExample from 'shared/assets/images/einExample.png'
import { AttachmentCard, AttachmentPdf } from 'entities/Attachment'
import { LinkButton } from 'pages/settings/pages/compliance/ui/LinkButton/LinkButton'
import styles from './styles.module.scss'
import type { IGeneralInfoStore } from './CommonGeneralInfo'

type IManuallyBusinessFindProps = {
  generalInfoStore: IGeneralInfoStore
  states: IDropdownItem[]
  fieldsError: Record<string, string> | null
  fieldsErrorCommonText: JSX.Element[] | null
  openDoNotHaveEINModal: () => void
}

export const ManuallyBusinessFind = observer(
  ({
    generalInfoStore,
    states,
    fieldsError,
    fieldsErrorCommonText,
    openDoNotHaveEINModal,
  }: IManuallyBusinessFindProps) => {
    const {
      einItem,
      einSearch,
      attachmentData,
      attachmentError,
      zipCode,
      isUsaLocation,
      zipLocalErrorMessage,
      clearAttachmentError,
      deleteIrsEinAttachment,
      setEinSearch,
      addZipManually,
      checkZipLengthManually,
      addCityManually,
      addBusinessNameManually,
      addBusinessAddressManually,
      addBusinessAddress2Manually,
      addBusinessStateManually,
      uploadIrsEinAttachment,
    } = generalInfoStore

    const einText = isUsaLocation
      ? 'Employer Identification Number (EIN)'
      : 'Canadian Business Number (BN)'

    const doNotHaveText = isUsaLocation
      ? 'I don’t have a United States EIN number'
      : 'I don’t have a Canadian Business Number'

    const numberType = isUsaLocation ? 'EIN' : 'BN'

    const cityPlaceholder = isUsaLocation ? 'e.g. Los Angeles' : 'e.g. Toronto'
    const linkToInfo = getCDNAssetURL(einExample)

    const whereCanIFindButton = isUsaLocation && (
      <LinkButton text={'Where can I find it?'} link={linkToInfo} />
    )

    return (
      <Box>
        <Box marginBottom={'26px'}>
          <TextFieldWithLabel
            labelProps={{
              label: {
                text: einText,
                rightNode: whereCanIFindButton,
              },
            }}
            textFieldProps={{
              className: styles.textInput,
              value: einSearch,
              onChange: setEinSearch,
              InputProps: {
                placeholder: 'e.g. 12-3456789',
              },
            }}
          />
          <Button
            text={doNotHaveText}
            size={'small'}
            typeBtn='informative'
            contained='secondary'
            onClick={openDoNotHaveEINModal}
            className={styles.noHaveEinButton}
          />
        </Box>

        <Box marginBottom={3}>
          <TextFieldWithLabel
            errorBorder={Boolean(fieldsError?.business_name)}
            labelProps={{
              label: {
                text: 'Legal business name',
                rightNode: whereCanIFindButton,
              },
            }}
            textFieldProps={{
              className: styles.textInput,
              value: einItem?.name || '',
              onChange: addBusinessNameManually,
              InputProps: {
                placeholder: 'e.g. Example LLC',
              },
            }}
            bottomLabelProps={{
              label: {
                text: (
                  <Typography variant='text-input-helper' ariaLabel='legal_business_name'>
                    {`Enter the exact legal business name, as registered with the ${numberType}`}
                  </Typography>
                ),
              },
            }}
          />

          {fieldsErrorCommonText?.length && (
            <Alert
              item={{ desc: fieldsErrorCommonText, type: 'error' }}
              marginBottom={32}
              marginTop={'26px'}
            />
          )}
        </Box>

        <TextFieldWithLabel
          marginBottom={3}
          errorBorder={Boolean(fieldsError?.street_address_1)}
          labelProps={{
            label: {
              text: 'Business address',
              rightNode: whereCanIFindButton,
            },
            marginBottom: 4,
          }}
          textFieldProps={{
            className: styles.textInput,
            value: einItem?.street1 || '',
            onChange: addBusinessAddressManually,
            InputProps: {
              placeholder: 'Search or enter manually your address',
            },
          }}
          bottomLabelProps={{
            label: {
              text: (
                <Typography variant='text-input-helper' ariaLabel='business_address'>
                  {` Enter the exact business address, as registered with the ${numberType}`}
                </Typography>
              ),
            },
          }}
        />

        <TextFieldWithLabel
          marginBottom={3}
          errorBorder={Boolean(fieldsError?.street_address_2)}
          labelProps={{
            label: {
              text: 'Business address 2 (optional)',
            },
          }}
          textFieldProps={{
            className: styles.textInput,
            value: einItem?.street2 || '',
            onChange: addBusinessAddress2Manually,
            InputProps: {
              placeholder: 'e.g. Building name',
            },
          }}
        />

        <TextFieldWithLabel
          marginBottom={3}
          errorBorder={Boolean(fieldsError?.city)}
          labelProps={{
            label: {
              text: 'City',
            },
          }}
          textFieldProps={{
            className: styles.textInput,
            value: einItem?.city || '',
            onChange: addCityManually,
            InputProps: {
              placeholder: cityPlaceholder,
            },
          }}
        />

        <Box marginBottom={'16px'} display='flex' justifyContent='space-between' gap={'12px'}>
          <Box flex={1}>
            <SelectField
              value={einItem?.state}
              items={states}
              setValue={addBusinessStateManually}
              btnProps={{
                size: 'large',
                style: {
                  height: '40px',
                },
              }}
              labelProps={{
                label: {
                  text: isUsaLocation ? 'State' : 'Province',
                },
              }}
            />
          </Box>
          <Box flex={1}>
            <TextFieldWithLabel
              errorBorder={Boolean(fieldsError?.zip_code)}
              errorMessage={zipLocalErrorMessage || undefined}
              marginBottom={0}
              labelProps={{
                label: {
                  text: isUsaLocation ? 'Zip code' : 'Postal code',
                },
              }}
              textFieldProps={{
                value: zipCode,
                className: styles.numberOfCreditsInput,
                onChange: addZipManually,
                onBlur: checkZipLengthManually,
                InputProps: {
                  placeholder: isUsaLocation ? 'e.g. 90001' : 'e.g. A1A 1A1',
                },
              }}
            />
          </Box>
        </Box>

        {isUsaLocation && (
          <>
            {attachmentError && (
              <Alert
                item={{ title: attachmentError.title, desc: attachmentError.desc, type: 'error' }}
                onClose={clearAttachmentError}
                marginBottom={16}
              />
            )}
            <Label
              marginBottom={8}
              label={{
                text: 'IRS EIN document',
                secondaryText: '(optional)',
                rightText: <div className={styles.recommendedLabel}>Recommended</div>,
              }}
            />
            <UploadDoc
              uploadTypeText='Acceptable file types: pdf, jpg, png'
              onChange={(files) => uploadIrsEinAttachment(files[0])}
            />
            <Typography
              variant='text-input-helper'
              ariaLabel='textInputHelper'
              color='var(--content-primary-tertiary)'
              tag='div'
              marginTop={'8px'}
            >
              Upload your company&apos;s IRS EIN document to increase your chances of getting
              approved
            </Typography>

            {attachmentData && (
              <Box
                display='flex'
                justifyContent={'space-between'}
                alignItems={'center'}
                marginTop={4}
              >
                <Box display='flex' gap={'12px'} alignItems={'center'}>
                  {attachmentData.type === 'attachment' ? (
                    <AttachmentPdf loading={attachmentData.loading} />
                  ) : (
                    <AttachmentCard attachment={attachmentData} loading={attachmentData.loading} />
                  )}

                  <Box display={'flex'} flexDirection={'column'} gap={1} justifyContent={'center'}>
                    <Typography
                      ariaLabel='attachmentName'
                      variant='body-md-regular'
                      color='var(--content-primary-primary)'
                    >
                      {attachmentData.name}
                    </Typography>
                    <Typography
                      ariaLabel='attachmentName'
                      variant='body-sm-regular'
                      color='var(--content-primary-tertiary)'
                    >
                      {formatBytesToNumberWithType(attachmentData.size || 0)}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  icon='close'
                  ariaLabel='delete_document'
                  size='small'
                  variant='icon'
                  color='tertiary'
                  transparentBackground={true}
                  onClick={() => {
                    deleteIrsEinAttachment()
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    )
  }
)
