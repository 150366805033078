import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { getNumberModalStore } from 'widgets/GetNumberModal'

import styles from './styles.module.scss'

export const GetNumberModalActions = observer(() => {
  const { handleAddNumber, selectedNumber, loading } = getNumberModalStore

  return (
    <div className={styles.actionsWrap} aria-label={getAriaLabel('GetNumberModalActions')}>
      <Button
        disabled={!selectedNumber || loading}
        fullWidth
        typeBtn={'contained'}
        text={'Next'}
        onClick={handleAddNumber}
      />
      <Typography
        variant={'body-xs-regular'}
        color={'var(--content-primary-tertiary)'}
        className={styles.text}
        ariaLabel={'Aircall number'}
      >
        You can always change this number later or replace with your existing number, landline, or
        Aircall number.
      </Typography>
    </div>
  )
})
