import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, IconButton, IIconsVector, IToast, SpinnerLoader, toastStore } from 'shared/ui'
import styles from './styles.module.scss'

type IToastItemProps = {
  item: IToast
  isTimeout: boolean
  withCountdown?: boolean
}

const ToastItem: React.FC<IToastItemProps> = observer(({ item, isTimeout, withCountdown }) => {
  const { type, action, secondAction, noTimer, noCloseButton } = item
  const [timeout, setTimeoutAction] = useState(setTimeout(() => {}, item.timer))
  const [autoHideTime, setAutoHideTime] = useState(item.timer / 1000)

  useEffect(() => {
    if (withCountdown) {
      setInterval(() => {
        setAutoHideTime((prev) => prev - 1)
      }, 1000)
    }
  }, [])

  let icon: IIconsVector = 'infoCircle'

  const isLoading = type === 'loading'

  if (type === 'error') icon = 'failed'
  if (type === 'warning') icon = 'alertTriangleSmall'
  if (type === 'success') icon = 'check2'
  if (type === 'info') icon = 'infoCircle'

  useEffect(() => {
    if (noTimer) return
    if (isTimeout) {
      setTimeoutAction(
        setTimeout(() => {
          toastStore.remove(item.id)
        }, item.timer)
      )
    } else {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isTimeout])

  return (
    <div className={classNames(styles.item, styles[type])}>
      <div className={styles.wrapContent}>
        <div className={styles.iconType}>
          {isLoading ? <SpinnerLoader size={16} /> : <Icon icon={icon} />}
        </div>
        <div className={styles.body}>
          <div className={styles.title}>
            {item.title} {withCountdown && `${autoHideTime}s`}
          </div>
          {!!item.desc && <div className={styles.desc}>{item.desc}</div>}
        </div>
        {action && (
          <>
            {action.link && (
              <a href={action.link} target={'_blank'} rel='noreferrer' className={styles.action}>
                {action.text}
              </a>
            )}
            {action.onAction && (
              <button className={styles.action} onClick={action.onAction}>
                {action.text}
              </button>
            )}
            {secondAction?.onAction && (
              <button
                className={classNames(styles.action, styles.secondAction)}
                onClick={secondAction.onAction}
              >
                {secondAction.text}
              </button>
            )}
          </>
        )}
      </div>

      {!noCloseButton && (
        <div className={styles.iconClose}>
          <IconButton
            icon={'close'}
            size={'small'}
            variant={'icon'}
            color={'transparent'}
            fontSize={16}
            onClick={() => {
              toastStore.remove(item.id)
            }}
            ariaLabel='Toast_close'
          />
        </div>
      )}
    </div>
  )
})

export const Toast: React.FC = observer(() => {
  const [timeout, setTimeout] = useState(true)
  useEffect(() => {
    return () => {
      toastStore.reset()
    }
  }, [])

  const onMouseLeave = () => {
    setTimeout(true)
  }

  const onMouseEnter = () => {
    setTimeout(false)
  }

  if (!toastStore.items.length) return null

  return (
    <div className={styles.wrap} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
      <div className={styles.items}>
        {toastStore.items.map((item) => {
          return (
            <ToastItem
              key={item.id}
              item={item}
              isTimeout={timeout}
              withCountdown={item.withCountdown}
            />
          )
        })}
      </div>
    </div>
  )
})
