import { observer } from 'mobx-react-lite'
import { ChangeEventHandler, useRef } from 'react'
import { IconButton } from 'shared/ui'
import { AudioAccept } from 'shared/constants/accept'
import { useBroadcastRinglessContext } from 'widgets/BroadcastView/ui/BroadcastRingless'

export const RinglessUploadAudio = observer(() => {
  const { onAddFiles } = useBroadcastRinglessContext()
  const ref = useRef<HTMLInputElement>(null)
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files) {
      onAddFiles(Array.from(e.target.files))
    }
  }
  const handleClickUpload = () => {
    ref.current?.click()
  }
  return (
    <>
      <IconButton
        ariaLabel={'Upload audio'}
        tooltipProps={{
          label: 'Upload audio',
          placement: 'top',
        }}
        icon={'upload'}
        size={'medium'}
        color={'secondary'}
        variant={'icon'}
        onClick={handleClickUpload}
      />
      <input
        ref={ref}
        type='file'
        style={{ visibility: 'hidden' }}
        accept={AudioAccept.toString()}
        onChange={onChange}
      />
    </>
  )
})
