// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MYntn5v2vH2av4_lhXcX{display:flex;gap:8px}._KjQq8wSmCx2THE886NG{position:relative;width:128px;height:92px;background:#f3f5f8;border-radius:12px;display:flex;align-items:center;justify-content:center}._KjQq8wSmCx2THE886NG svg{color:var(--action-contained-primary-normal)}._KjQq8wSmCx2THE886NG:hover{background:var(--background-brand-subtle-primary)}._KjQq8wSmCx2THE886NG:hover svg{color:var(--action-contained-primary-hovered)}._KjQq8wSmCx2THE886NG input{z-index:var(--zIndex-BASE_2);cursor:pointer;opacity:0;position:absolute;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/pages/admin/ui/AdminDetailCard/AdminDetailCardRow/AttachmentsEditValue/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CAEF,sBACE,iBAAA,CACA,WAAA,CACA,WAAA,CACA,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,0BACE,4CAAA,CAEF,4BACE,iDAAA,CACA,gCACE,6CAAA,CAIJ,4BACE,4BAAA,CACA,cAAA,CACA,SAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".wrap {\n  display: flex;\n  gap: 8px;\n}\n.imageLoader {\n  position: relative;\n  width: 128px;\n  height: 92px;\n  background: #f3f5f8;\n  border-radius: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  svg {\n    color: var(--action-contained-primary-normal);\n  }\n  &:hover {\n    background: var(--background-brand-subtle-primary);\n    svg {\n      color: var(--action-contained-primary-hovered);\n    }\n  }\n\n  input {\n    z-index: var(--zIndex-BASE_2);\n    cursor: pointer;\n    opacity: 0;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "MYntn5v2vH2av4_lhXcX",
	"imageLoader": "_KjQq8wSmCx2THE886NG"
};
export default ___CSS_LOADER_EXPORT___;
