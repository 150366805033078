import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, SpinnerLoader, Switch, Typography } from 'shared/ui'
import { KnowledgeBaseStatus } from 'entities/KnowledgeBase'
import { KnowledgeBaseIndexationStatusAlert } from 'widgets/KnowledgeBase'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotTextTooltip } from 'pages/chatbot/ui/ChatbotTooltip'
import { SettingsRoutes } from 'pages/settings/route/type'
import { KnowledgeBaseRoutes } from 'pages/settings/pages/knowledgeBase'
import styles from './styles.module.scss'

interface KnowledgeBaseAlertProps {
  isEmpty: boolean
  error?: string | null
}

const KnowledgeBaseAlert = observer(({ isEmpty, error }: KnowledgeBaseAlertProps) => {
  const navigate = useNavigate()
  if (!isEmpty && !error) return null

  const alertType = error ? 'alert' : 'warning'
  const alertDescription = isEmpty ? (
    <div className={styles.alert}>
      <span>Your knowledge base is empty, add documents to get started</span>
      <Button
        text='Add documents'
        typeBtn='outlined'
        size='small'
        contained='secondary'
        onClick={() => navigate(`/${SettingsRoutes.root}/${KnowledgeBaseRoutes.root}`)}
        className={styles.alertButton}
      />
    </div>
  ) : (
    error
  )

  return <Alert item={{ type: alertType, desc: alertDescription }} />
})

export const PointKnowledgeBaseUsage = observer(
  ({ title = 'Use knowledge base?' }: { title?: string }) => {
    const {
      errors,
      model: { settings },
      knowledgeBaseStore: { knowledgeBase, isKnowledgeBaseInitLoading },
    } = useChatbotContext()
    const knowledgeBaseUsageError = errors.getError('settings.use_knowledge_base.value')
    const handleKnowledgeBaseUsageChange = (knowledgeBaseUsage: boolean) => {
      settings.setKnowledgeBaseUsage(knowledgeBaseUsage)
      errors.removeError('settings.use_knowledge_base')
    }
    const isKnowledgeBaseEmpty =
      knowledgeBase?.documentsCount === 0 && knowledgeBase.status !== KnowledgeBaseStatus.Processing

    useEffect(() => {
      if (settings.knowledgeBaseUsage && knowledgeBase?.isActiveStatus) {
        errors.removeError('settings.use_knowledge_base')
      }
    }, [settings.knowledgeBaseUsage, knowledgeBase?.isActiveStatus])

    if (isKnowledgeBaseInitLoading) return <SpinnerLoader />

    return (
      <div className={styles.knowledgeBaseContainer}>
        <div className={classNames(styles.section, styles.knowledgeBase)}>
          <div className={styles.knowledgeBaseLabelContainer}>
            <ChatbotTextTooltip tooltip='Turn on to add your business info from AI knowledge base for more accurate responses and easier setup'>
              <Typography
                variant='text-input-field-r'
                color='tertiary'
                ariaLabel={'KnowledgeBaseLabel'}
              >
                {title}
              </Typography>
            </ChatbotTextTooltip>
            <Typography
              variant='body-xs-medium'
              ariaLabel='BetaLabel'
              className={styles.knowledgeBaseBadge}
            >
              Beta
            </Typography>
          </div>
          <Switch value={settings.knowledgeBaseUsage} onChange={handleKnowledgeBaseUsageChange} />
        </div>
        {settings.knowledgeBaseUsage && (
          <>
            {knowledgeBase && !knowledgeBase.isInitStatus && (
              <KnowledgeBaseIndexationStatusAlert knowledgeBase={knowledgeBase} />
            )}
            <KnowledgeBaseAlert isEmpty={isKnowledgeBaseEmpty} error={knowledgeBaseUsageError} />
          </>
        )}
      </div>
    )
  }
)
