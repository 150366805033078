import { type IIconsVector } from 'shared/ui'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type Conversation } from 'entities/Conversation/model/Conversation'
import {
  IOpportunity,
  IResponseIntegrationHubspotCompany,
  IResponseIntegrationHubspotDeal,
} from 'entities/Integrations'
import { type ContactsTableStore } from 'widgets/ContactsTable'
import { type ConversationNewSearchStore } from 'widgets/ConversationNew'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'

export type IContactProfileCard = {
  onClickMessage?: (contact: Contact) => void
  onClickCall?: () => void
  enableChat?: boolean
  enableCall?: boolean
}

export type IContactsDetailsVariant =
  | 'conversation'
  | 'contacts'
  | 'create_contact_modal'
  | 'open_conversation'

export type IContactsDetailsStoreConfig = {
  contactId?: number | null
  conversationId?: number | null
  inboxId?: number | null

  contact?: Contact | null
  conversation?: Conversation | null
  profileCardProps?: IContactProfileCard
  headerProps?: {
    iconShow?: IIconsVector
    iconHide?: IIconsVector
    textHide?: string
    textShow?: string
  }
  notesProps?: {
    hideMessageFieldActions: ('ai_assistant' | 'emoji')[]
  }
  onUpdateContact?: (contact: Contact) => void
  onCloseContactDetails?: () => void
  onStopCreateNewContact?: () => void
  emitOpenConversationSignal?: () => void
  onDeleteContactsDetails?: () => void
  reassigning?: boolean
  addNewContactLocallyAfterCreate?: (contact: Contact, params?: { isNew?: boolean }) => void
  variant: IContactsDetailsVariant
  contactsTableStore?: ContactsTableStore
  conversationNewSearchStore?: ConversationNewSearchStore
  conversationMessagesStore?: ConversationMessagesStore
  contactCreateModalStore?: ContactCreateModalStore
  sub_source?: string
}

export type IViewTypes =
  | 'notes'
  | 'deals'
  | 'main'
  | 'companies'
  | 'opportunities'
  | 'conversations-history'

export enum EnumIntegrationTabs {
  Contact = 'contact',
  Account = 'account',
  Deals = 'deals',
  Companies = 'companies',
  Company = 'company',
  Opportunities = 'opportunities',
  Organization = 'organization',
}

export type IViewAllData = {
  deals: IResponseIntegrationHubspotDeal[] | []
  companies: IResponseIntegrationHubspotCompany[] | []
  opportunities: IOpportunity[] | []
}

export type IViewAllDataProps = {
  deals?: IResponseIntegrationHubspotDeal[] | []
  companies?: IResponseIntegrationHubspotCompany[] | []
  opportunities?: IOpportunity[] | []
}

export type IOpenContactsDetailsSource =
  | 'contacts'
  | 'call history'
  | 'textbot'
  | 'broadcast'
  | 'trigger'

export type IOpenContactsDetailsData = {
  contactId: number
  inboxId?: number
}

export type IOpenConversationsDetailsData = {
  contactId?: number
  inboxId?: number
  conversationId?: number
}

export type IOpenContactsDetails = {
  disabled: boolean
  open: (data: IOpenContactsDetailsData) => void
}

export type IOpenConversationDetails = {
  disabled: boolean
  open: (data: IOpenConversationsDetailsData) => void
}

export type IContactsDetailsManageStoreConfig = {
  emitOpenConversationSignal?: () => void
}
