import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Button } from 'shared/ui'
import { type ShowMoreActionStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction/store/showMoreActionStore'

type IShowMoreActionProps = {
  store: ShowMoreActionStore
  disabled: boolean
}

export const ShowMoreAction: FC<IShowMoreActionProps> = observer(({ store, disabled }) => {
  if (store.hideShowMore) return null

  return (
    <Button
      size='small'
      typeBtn='text'
      disabled={disabled}
      text={store.text}
      onClick={() => {
        store.toggleShow()
      }}
    />
  )
})
