import { useEffect, useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownCard } from 'shared/ui'
import type { Contact } from 'entities/Contacts/model/Contact'
import { WorkflowEnrollStore } from 'widgets/WorkflowEnroll/store/WorkflowEnrollStore'

import styles from './styles.module.scss'

type IWorkflowEnrollDropdownItemProps = {
  contact: Contact
  onClick: () => void
}

export const WorkflowEnrollDropdownItem: FC<IWorkflowEnrollDropdownItemProps> = observer(
  ({ contact, onClick }) => {
    const store = useMemo(() => new WorkflowEnrollStore(), [])
    const blockReason = store.getBlockReason(contact)

    useEffect(() => store.checkActivity(), [])

    if (!store.isOperationEnabled) return null

    return (
      <DropdownCard
        item={{
          id: 'workflow',
          label: 'Enroll in workflow',
          iconL: 'workflows',
          disabled: !!blockReason,
          tooltipProps: {
            placement: 'left-start',
            label: blockReason,
            className: styles.tooltip,
          },
        }}
        onChange={() => {
          onClick()
          store.enrollContacts([contact.id])
        }}
      />
    )
  }
)
