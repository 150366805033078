import React from 'react'
import { IColumn } from 'shared/ui'
import { DayjsFormats, numberFormat } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { IAdminOrganization } from 'entities/Admin/organizations'
import { OrganizationStatus } from 'entities/Organization'
import { SubscriptionStatus } from 'entities/Subscription'

export const columns: IColumn<IAdminOrganization>[] = [
  {
    field: 'id',
    name: 'ID',
    isDisabledChangeVisible: true,
    maxWidth: 85,
  },
  {
    field: 'created_at',
    name: 'Signup date',
    renderRowCell: (row) =>
      row.created_at && uiStore.dayjs(row.created_at).format(DayjsFormats.date2),
  },
  {
    field: 'org_status',
    name: 'Org. status',
    fullName: 'Organization status',
    renderRowCell: (row) => <OrganizationStatus status={row.status} />,
  },
  {
    field: 'subscription_status',
    name: 'Sub. status',
    fullName: 'Subscription status',
    renderRowCell: (row) => <SubscriptionStatus status={row.subscription.status} />,
  },
  {
    field: 'email',
    name: 'Email',
    renderRowCell: (row) => row.owner?.email,
    maxWidth: 260,
  },
  {
    field: 'name',
    name: 'Company name',
    maxWidth: 260,
  },
  {
    field: 'owner_name',
    name: 'Owner name',
    renderRowCell: (row) => row.owner?.name,
    maxWidth: 260,
  },
  {
    field: 'account_credits',
    name: 'Credits',
    renderRowCell: (row) =>
      numberFormat({
        value: row.subscription?.status === 'trialing' ? row.trial_credits : row.account_credits,
      }),
  },
]
