import { KeyboardEvent, useRef } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import {
  TextField,
  makeTextFieldClear,
  makeTextFieldAutoFocus,
  makeTextFieldIcon,
} from 'shared/ui/TextField'
import styles from './styles.module.scss'

interface IMenuSearchProps {
  onSearch: (value: string) => void
  onBlur?: () => void
  search: string
  placeholder?: string
  autofocus?: boolean
  variant?: 'simple' | 'transparent'
}

export const MenuSearch = observer(
  ({ onSearch, search, placeholder = 'Search', onBlur, autofocus, variant }: IMenuSearchProps) => {
    const searchRef = useRef<HTMLInputElement>(null)

    const handleEscape = () => {
      searchRef?.current?.blur()
      onBlur && onBlur()
      onSearch('')
    }

    const handleEnter = () => {
      searchRef?.current?.blur()
      onBlur && onBlur()
    }

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleEnter()
      }
      if (event.key === 'Escape') {
        event.preventDefault()
        handleEscape()
      }
    }

    if (variant === 'transparent') {
      return (
        <TextField
          className={classNames(styles.wrap, styles.transparent)}
          InputProps={{
            placeholder,
            onKeyDown,
            ref: searchRef,
          }}
          leftActions={[
            makeTextFieldIcon({
              icon: 'search',
              color: 'var(--content-primary-tertiary)',
              fontSize: 16,
            }),
          ]}
          mainActions={[makeTextFieldAutoFocus({ withFocus: Boolean(autofocus) })]}
          variant='stroke'
          onBlur={onBlur}
          size='small'
          value={search}
          onChange={onSearch}
          rightActions={[makeTextFieldClear()]}
        />
      )
    }

    return (
      <TextField
        className={classNames(styles.wrap, styles.simple)}
        InputProps={{
          placeholder,
          onKeyDown,
          ref: searchRef,
        }}
        variant='stroke'
        size='small'
        value={search}
        onChange={onSearch}
        rightActions={[makeTextFieldClear()]}
      />
    )
  }
)
