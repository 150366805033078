// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oCIJuOH4OCydtESyCS7X .m1N4YI3T1iqU7Fk7iwv7{margin:20px 0 8px}.oCIJuOH4OCydtESyCS7X .m1N4YI3T1iqU7Fk7iwv7 .TxqLjQzcWHtpLtuZ7Jfe{display:flex;align-items:center;padding:4px 8px}.oCIJuOH4OCydtESyCS7X .m1N4YI3T1iqU7Fk7iwv7 .TxqLjQzcWHtpLtuZ7Jfe .jNFnFK3hAOpuPW25aLqZ{overflow:hidden;line-height:normal;color:var(--content-primary-primary);text-overflow:ellipsis;font-size:13px;font-weight:500;max-width:250px;padding-right:8px}.oCIJuOH4OCydtESyCS7X .m1N4YI3T1iqU7Fk7iwv7 .TxqLjQzcWHtpLtuZ7Jfe .iQvoekNihD18joX0xKjy{color:var(--content-primary-tertiary);cursor:pointer}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/SectionTab/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,kBAAA,CACA,eAAA,CAEA,wFACE,eAAA,CACA,kBAAA,CACA,oCAAA,CACA,sBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CAGF,wFACE,qCAAA,CACA,cAAA","sourcesContent":[".contentWrapper {\n  .headerWrapper {\n    margin: 20px 0 8px;\n\n    .header {\n      display: flex;\n      align-items: center;\n      padding: 4px 8px;\n\n      .title {\n        overflow: hidden;\n        line-height: normal;\n        color: var(--content-primary-primary);\n        text-overflow: ellipsis;\n        font-size: 13px;\n        font-weight: 500;\n        max-width: 250px;\n        padding-right: 8px;\n      }\n\n      .icon {\n        color: var(--content-primary-tertiary);\n        cursor: pointer;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": "oCIJuOH4OCydtESyCS7X",
	"headerWrapper": "m1N4YI3T1iqU7Fk7iwv7",
	"header": "TxqLjQzcWHtpLtuZ7Jfe",
	"title": "jNFnFK3hAOpuPW25aLqZ",
	"icon": "iQvoekNihD18joX0xKjy"
};
export default ___CSS_LOADER_EXPORT___;
