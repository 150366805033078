// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._eqVq1z06SvSpGY9jaXm{margin-top:-8px;margin-bottom:32px}.uLK_0IUgC49FNc3Cf1gd{margin-top:-4px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/ReviewBroadcast/alerts/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,kBAAA,CAEF,sBACE,eAAA","sourcesContent":[".wrap{\n  margin-top: -8px;\n  margin-bottom: 32px;\n}\n.wrapAutoRechargeBtn{\n  margin-top: -4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "_eqVq1z06SvSpGY9jaXm",
	"wrapAutoRechargeBtn": "uLK_0IUgC49FNc3Cf1gd"
};
export default ___CSS_LOADER_EXPORT___;
