// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S4_xWihBsYetpmiCqGSS{display:flex;align-items:center;padding:0 0 0 8px;border-radius:6px;border:1px solid var(--border-neutral-default);background:var(--background-primary-inverted-primary);height:24px;cursor:default}.jm2Ipm02jP6keNIIDIty{margin-right:8px}.tSrokGpBqWWqgA8oAalo{color:var(--content-primary-primary);font-size:13px;white-space:nowrap;max-width:100px;overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationNew/ui/ConversationSearchItems/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CACA,8CAAA,CACA,qDAAA,CACA,WAAA,CACA,cAAA,CAEA,sBACE,gBAAA,CAGF,sBACE,oCAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".item {\n  display: flex;\n  align-items: center;\n  padding: 0 0 0 8px;\n  border-radius: 6px;\n  border: 1px solid var(--border-neutral-default);\n  background: var(--background-primary-inverted-primary);\n  height: 24px;\n  cursor: default;\n\n  &__avatar {\n    margin-right: 8px;\n  }\n\n  &__name {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    white-space: nowrap;\n    max-width: 100px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "S4_xWihBsYetpmiCqGSS",
	"item__avatar": "jm2Ipm02jP6keNIIDIty",
	"item__name": "tSrokGpBqWWqgA8oAalo"
};
export default ___CSS_LOADER_EXPORT___;
