import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseEventConversationBulkFavorite } from 'entities/Call'

export const EventConversationBulkFavorite = {
  subscribe: (handler: (data: IResponseEventConversationBulkFavorite) => void) =>
    websocket.on(WebsocketEvent.ConversationsBulkFavorite, handler),
  unsubscribe: (handler: (data: IResponseEventConversationBulkFavorite) => void) =>
    websocket.off(WebsocketEvent.ConversationsBulkFavorite, handler),
}

export const useEventConversationBulkFavorite = () => {
  const handler = (data: IResponseEventConversationBulkFavorite) => {
    const { getItem } = conversationStore

    data.ids.forEach((conversationId) => {
      const conversation = getItem(conversationId)
      if (!conversation) return

      conversation.updatePriority(data.isFavorite)
    })
  }

  useEffect(() => {
    EventConversationBulkFavorite.subscribe(handler)

    return () => {
      EventConversationBulkFavorite.unsubscribe(handler)
    }
  }, [])
}
