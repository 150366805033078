import { IResponseConversion } from 'entities/Conversion'

export class Conversion implements IResponseConversion {
  id: number
  analytics: {
    users_with_conversion: number
    conversions_number: number
    sms_count: number
    conversion_rate: number
    conversion_value: number
  }
  conversion_attribution: number
  conversion_url: string
  currency: string
  default_value: number
  name: string
  verified_at: string | null

  constructor(response: IResponseConversion) {
    this.id = response.id
    this.analytics = response.analytics
    this.conversion_attribution = response.conversion_attribution
    this.conversion_url = response.conversion_url
    this.currency = response.currency
    this.default_value = response.default_value
    this.name = response.name
    this.verified_at = response.verified_at
  }
}
