// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pvDMh6XDnXDswmUzOaoq{width:100%;display:flex;justify-content:space-between;align-items:center}.VuWnvU1bu3pElaD_KyhW{display:flex}.VuWnvU1bu3pElaD_KyhW:hover{background:var(--gray-10)}.B850rKeqHc_RarFIIZEA{font-size:13px;font-weight:400;color:var(--content-primary-tertiary);line-height:18px;margin-left:8px;cursor:pointer}.sYI7CirVn7PItkOJlOtw{margin-right:8px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/MapFieldsModalActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAEJ,sBACI,YAAA,CAEA,4BACI,yBAAA,CAGR,sBACI,cAAA,CACA,eAAA,CACA,qCAAA,CACA,gBAAA,CACA,eAAA,CACA,cAAA,CAEJ,sBACI,gBAAA","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n.leftContent {\n    display: flex;\n\n    &:hover {\n        background: var(--gray-10);\n    }\n}\n.leftContentText {\n    font-size: 13px;\n    font-weight: 400;\n    color: var(--content-primary-tertiary);\n    line-height: 18px;\n    margin-left: 8px;\n    cursor: pointer;\n}\n.cancelButton {\n    margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pvDMh6XDnXDswmUzOaoq",
	"leftContent": "VuWnvU1bu3pElaD_KyhW",
	"leftContentText": "B850rKeqHc_RarFIIZEA",
	"cancelButton": "sYI7CirVn7PItkOJlOtw"
};
export default ___CSS_LOADER_EXPORT___;
