// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c6MAoKyW6wQZHcILkeHO{padding-top:4px}.CfNSn0umhfp_7rGLCqai{display:flex;align-items:center;justify-content:center;padding:40px 0}.wxJaN0E7SF_CpEOhyPsB{display:flex;align-items:center;justify-content:center}.rzh84HsNgxSeIZs6lIhe{color:var(--content-primary-tertiary);font-size:12px;font-weight:400;display:flex;align-items:center;justify-content:center;padding:20px 0}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsSearch/ui/ContactsSearchList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,qCAAA,CACA,cAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".wrap {\n  padding-top: 4px;\n}\n\n.loading {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 40px 0;\n}\n\n.loadingMore {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.empty {\n  color: var(--content-primary-tertiary);\n  font-size: 12px;\n  font-weight: 400;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 20px 0;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "c6MAoKyW6wQZHcILkeHO",
	"loading": "CfNSn0umhfp_7rGLCqai",
	"loadingMore": "wxJaN0E7SF_CpEOhyPsB",
	"empty": "rzh84HsNgxSeIZs6lIhe"
};
export default ___CSS_LOADER_EXPORT___;
