import { makeAutoObservable, runInAction } from 'mobx'
import { Counter } from 'shared/lib'
import { Contact } from 'entities/Contacts/model/Contact'
import { Segment } from 'entities/Segment/model/Segment'
import { Tag } from 'entities/Tags/model/Tag'
import { WithCount } from './WithCount'
import {
  getContactCount,
  getFilterCount,
  getSegmentCount,
  getTagCount,
} from '../utils/contactsCount'
import { IEnrollmentFiltersParams } from '../types'

export class EnrollmentFilters {
  static Empty = () => new EnrollmentFilters()

  private _filterIdCounter = new Counter(0)

  private _tagsMap = new Map<number, WithCount<Tag>>()
  private _filtersMap = new Map<number, WithCount<Segment>>()
  private _contactsMap = new Map<number, WithCount<Contact>>()
  private _segmentsMap = new Map<number, WithCount<Segment>>()

  private _number_id: number | null = null

  constructor(params?: Partial<IEnrollmentFiltersParams>) {
    makeAutoObservable(this)

    this.sync(params ?? {})
  }

  get tagsWithCount() {
    return [...this._tagsMap.values()]
  }

  get tags() {
    return this.tagsWithCount.map(({ item }) => item)
  }

  get tagsSize() {
    return this._tagsMap.size
  }

  get filtersWithCount() {
    return [...this._filtersMap.values()]
  }

  get filters() {
    return this.filtersWithCount.map(({ item }) => item)
  }

  get filtersSize() {
    return this._filtersMap.size
  }

  get contactsWithCount() {
    return [...this._contactsMap.values()]
  }

  get contacts() {
    return this.contactsWithCount.map(({ item }) => item)
  }

  get contactsSize() {
    return this._contactsMap.size
  }

  get segmentsWithCount() {
    return [...this._segmentsMap.values()]
  }

  get segments() {
    return this.segmentsWithCount.map(({ item }) => item)
  }

  get segmentsSize() {
    return this._segmentsMap.size
  }

  get allWithCount() {
    return {
      tags: this.tagsWithCount,
      filters: this.filtersWithCount,
      contacts: this.contactsWithCount,
      segments: this.segmentsWithCount,
    }
  }

  get all() {
    return {
      tags: this.tags,
      filters: this.filters,
      contacts: this.contacts,
      segments: this.segments,
    }
  }

  get allSize() {
    return this.tagsSize + this.filtersSize + this.contactsSize + this.segmentsSize
  }

  reset = () => {
    this._filterIdCounter = new Counter(0)

    this._tagsMap.clear()
    this._filtersMap.clear()
    this._contactsMap.clear()
    this._segmentsMap.clear()
  }

  sync = ({ tags, filters, contacts, segments, number_id }: Partial<IEnrollmentFiltersParams>) => {
    this._number_id = number_id || null
    tags?.forEach((tag) => {
      const newTag = new Tag(tag.origin)
      const newTagWithCount = new WithCount(newTag)
      this._tagsMap.set(newTag.id, newTagWithCount)

      getTagCount(tag, this._number_id).then(({ count, invalid_count }) =>
        runInAction(() => newTagWithCount.setCount(count + invalid_count))
      )
    })

    filters?.forEach((filter) => {
      const newFilter = new Segment({
        ...filter.origin,
        id: filter.id < 0 ? this._filterIdCounter.next : filter.id,
      })
      const newFilterWithCount = new WithCount(newFilter)
      this._filtersMap.set(newFilter.id, newFilterWithCount)

      getFilterCount(newFilter, this._number_id).then(({ count, invalid_count }) =>
        runInAction(() => newFilterWithCount.setCount(count + invalid_count))
      )
    })

    contacts?.forEach((contact) => {
      const newContact = new Contact(contact.origin)
      const newContactWithCount = new WithCount(newContact)
      this._contactsMap.set(newContact.id, newContactWithCount)

      getContactCount(newContact, this._number_id).then(({ count, invalid_count }) =>
        runInAction(() => newContactWithCount.setCount(count + invalid_count))
      )
    })

    segments?.forEach((segment) => {
      const newSegment = new Segment(segment.origin)
      const newSegmentWithCount = new WithCount(newSegment)
      this._segmentsMap.set(newSegment.id, newSegmentWithCount)

      getSegmentCount(newSegment, this._number_id).then(({ count, invalid_count }) =>
        runInAction(() => newSegmentWithCount.setCount(count + invalid_count))
      )
    })
  }

  clone = () => {
    const clone = new EnrollmentFilters({
      number_id: this._number_id,
    })
    clone._filterIdCounter = new Counter(this._filterIdCounter.current)

    const newTags: [number, WithCount<Tag>][] = [...this._tagsMap.entries()].map(([key, tag]) => {
      const tagCount = new WithCount(tag.item)
      tagCount.setCount(tag.count)

      return [key, tagCount]
    })
    clone._tagsMap = new Map(newTags)

    const newContacts: [number, WithCount<Contact>][] = [...this._contactsMap.entries()].map(
      ([key, contact]) => {
        const contactCount = new WithCount(contact.item)
        contactCount.setCount(contact.count)

        return [key, contactCount]
      }
    )
    clone._contactsMap = new Map(newContacts)

    const newSegments: [number, WithCount<Segment>][] = [...this._segmentsMap.entries()].map(
      ([key, segment]) => {
        const segmentCount = new WithCount(segment.item)
        segmentCount.setCount(segment.count)

        return [key, segmentCount]
      }
    )
    clone._segmentsMap = new Map(newSegments)

    const newFilters: [number, WithCount<Segment>][] = [...this._filtersMap.entries()].map(
      ([key, filter]) => {
        const filterCount = new WithCount(filter.item)
        filterCount.setCount(filter.count)

        return [key, filterCount]
      }
    )
    clone._filtersMap = new Map(newFilters)

    this._filtersMap.forEach((filter) => {
      if (!filter.hasCount) return
      clone._filtersMap.get(filter.item.id)?.setCount(filter.count as number)
    })

    return clone
  }

  addOrUpdateContact = (contact: Contact) => {
    const contactCount = this._contactsMap.has(contact.id)
      ? (this._contactsMap.get(contact.id) as WithCount<Contact>)
      : new WithCount(contact)

    this._contactsMap.set(contact.id, contactCount)
    getContactCount(contactCount.item, this._number_id).then(({ count, invalid_count }) =>
      runInAction(() => contactCount.setCount(count + invalid_count))
    )
  }

  deleteContact = (id: number) => this._contactsMap.delete(id)

  addOrUpdateTag = (tag: Tag) => {
    const tagCount = this._tagsMap.has(tag.id)
      ? (this._tagsMap.get(tag.id) as WithCount<Tag>)
      : new WithCount(tag)

    this._tagsMap.set(tag.id, tagCount)
    getTagCount(tagCount.item, this._number_id).then(({ count, invalid_count }) =>
      runInAction(() => tagCount.setCount(count + invalid_count))
    )
  }

  deleteTag = (id: number) => this._tagsMap.delete(id)

  addOrUpdateSegment = (segment: Segment) => {
    const segmentCount = this._segmentsMap.has(segment.id)
      ? (this._segmentsMap.get(segment.id) as WithCount<Segment>)
      : new WithCount(segment)

    this._segmentsMap.set(segment.id, segmentCount)
    getSegmentCount(segmentCount.item, this._number_id).then(({ count, invalid_count }) =>
      runInAction(() => segmentCount.setCount(count + invalid_count))
    )
  }

  deleteSegment = (id: number) => this._segmentsMap.delete(id)

  addOrUpdateFilter = (filter: Segment) => {
    if (filter.name) {
      this.deleteSegment(filter.id)
    }

    filter.syncOrigin({
      ...filter.origin,
      id: filter.id < 0 ? this._filterIdCounter.next : filter.id,
    })

    const filterCount = new WithCount(filter)

    this._filtersMap.set(filter.id, filterCount)

    getFilterCount(filterCount.item, this._number_id).then(({ count, invalid_count }) =>
      runInAction(() => filterCount.setCount(count + invalid_count))
    )
  }

  deleteFilter = (id: number) => this._filtersMap.delete(id)
}
