import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { adminSendOptOutContactReportStore } from 'pages/admin/pages/organizations/modals/AdminSendOptOutContactReport/store/adminSendOptOutContactReportStore'

export const AdminSendOptOutContactReportAction = observer(() => (
  <Button
    typeBtn={'contained'}
    text={'Send report'}
    onClick={adminSendOptOutContactReportStore.onSubmit}
    disabled={adminSendOptOutContactReportStore.disabledSubmit}
    loading={adminSendOptOutContactReportStore.loading}
  />
))
