import React, { useEffect } from 'react'
import { Typography, Box } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { ChatbotHeader } from 'pages/chatbot/ui/ChatbotEdit/ChatbotHeader/ChatbotHeader'
import { PointKnowledgeBaseUsage } from 'pages/chatbot/ui/ChatbotEdit/StepConfigure/PointKnowledgeBaseUsage'
import { useChatbotContext } from 'pages/chatbot/context'
import { useChatbotKnowledgeBaseUpdate } from 'pages/chatbot/events/useChatbotKnowledgeBaseUpdate'

export const SettingAgent = () => {
  const { rag } = featureFlagsStore
  const chatbotStore = useChatbotContext()
  const {
    knowledgeBaseStore: { initKnowledgeBase, updateKnowledgeBase },
  } = chatbotStore

  useEffect(() => {
    if (rag) initKnowledgeBase()
  }, [])

  useChatbotKnowledgeBaseUpdate(updateKnowledgeBase, rag)

  return (
    <div>
      <ChatbotHeader />
      <Box display={'flex'} flexDirection={'column'} marginTop={1.5} gap={1}>
        <Typography
          variant={'body-sm-regular'}
          ariaLabel={'Data sources'}
          color={'var(--content-primary-tertiary)'}
        >
          Data sources
        </Typography>
        {rag && <PointKnowledgeBaseUsage title='AI Knowledge Base' />}
      </Box>
    </div>
  )
}
