// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ozoW8mJaiduW2ayvFCsp{flex:1;display:flex;flex-direction:column;gap:28px;max-width:100%;padding:16px 24px 24px}.beewuUwVOsQJV58zF75Y{flex:1;display:flex;align-items:center;justify-content:center}.F1qbnvVIFWICenB11bax{display:flex;flex-direction:column;gap:12px}.c8gDyroBaLE_88ysJqNU{display:flex;flex-direction:column;flex:1;gap:24px}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotDetails/DetailsContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,MAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA,CACA,sBAAA,CAGJ,sBACI,MAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAGJ,sBACI,YAAA,CACA,qBAAA,CACA,MAAA,CACA,QAAA","sourcesContent":[".contentContainer {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 28px;\n    max-width: 100%;\n    padding: 16px 24px 24px;\n}\n\n.notFoundContainer {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.detailsHeader {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n}\n\n.detailsTabs {\n    display: flex;\n    flex-direction: column;\n    flex: 1;\n    gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": "ozoW8mJaiduW2ayvFCsp",
	"notFoundContainer": "beewuUwVOsQJV58zF75Y",
	"detailsHeader": "F1qbnvVIFWICenB11bax",
	"detailsTabs": "c8gDyroBaLE_88ysJqNU"
};
export default ___CSS_LOADER_EXPORT___;
