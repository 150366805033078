import { observer } from 'mobx-react-lite'
import { Box, Divider } from '@mui/material'
import React from 'react'
import { DayjsFormats, numberFormat } from 'shared/lib'
import { Icon, Tooltip, Typography } from 'shared/ui'
import { adminOrganizationCustomPlanStore } from '../../store/adminOrganizationCustomPlanStore'

export const SummaryPayment = observer(() => {
  const {
    applyDate,
    totalPrice,
    creditsAmount,
    currentSubscriptionRenewalDate,
    paymentScheduleTable,
    remainingAmount,
  } = adminOrganizationCustomPlanStore

  const totalDueToday = numberFormat({
    value: applyDate === 'next-billing-cycle' ? 0 : totalPrice,
    currency: 'USD',
  })

  const nextPayment = numberFormat({
    value: paymentScheduleTable.firstPayment
      ? paymentScheduleTable.firstPayment.amount
      : totalPrice,
    currency: 'USD',
  })

  return (
    <Box bgcolor='var(--background-neutral-subtle)' p={2} borderRadius='8px'>
      {applyDate === 'next-billing-cycle' && (
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-tertiary)'}
            ariaLabel={'Next Billing'}
          >
            Next Billing Cycle Start Date
          </Typography>

          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'Next Billing Date'}
          >
            {currentSubscriptionRenewalDate.format(DayjsFormats.full6)}
          </Typography>
        </Box>
      )}

      <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-tertiary)'}
          ariaLabel={'Subtotal'}
        >
          Subtotal
        </Typography>

        <Typography
          variant={'body-md-regular'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'SubtotalPrice'}
        >
          {numberFormat({ value: remainingAmount, currency: 'USD' })}
        </Typography>
      </Box>

      {Boolean(creditsAmount) && (
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-tertiary)'}
            ariaLabel={'Subtotal'}
          >
            Unused time
          </Typography>

          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'SubtotalPrice'}
          >
            {numberFormat({ value: creditsAmount, currency: 'USD' })}
          </Typography>
        </Box>
      )}

      <Divider sx={{ marginY: 1.5 }} />

      {applyDate === 'next-billing-cycle' && (
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'Total due'}
          >
            Total due{' '}
            {paymentScheduleTable.firstPayment
              ? paymentScheduleTable.firstPayment.payment_date.format(DayjsFormats.full6)
              : currentSubscriptionRenewalDate.format(DayjsFormats.full6)}
          </Typography>

          <Typography
            variant={'body-md-semibold'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'Total due Value'}
          >
            {numberFormat({
              value: paymentScheduleTable.firstPayment
                ? paymentScheduleTable.firstPayment.amount
                : totalPrice,
              currency: 'USD',
            })}
          </Typography>
        </Box>
      )}

      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' alignItems='center' gap={1}>
          <Typography
            variant={'body-md-regular'}
            color={'var(--content-primary-primary)'}
            ariaLabel={'Total due today'}
          >
            Total due today
          </Typography>
          {applyDate === 'next-billing-cycle' && (
            <Tooltip
              type={'description'}
              label={`The next charge will be ${nextPayment}`}
              desc={
                <>
                  Changes to your plan will be reflected in the next billing cycle:
                  <Typography
                    ariaLabel={'date'}
                    variant={'body-sm-regular'}
                    color={'var(--content-primary-primary-inverted'}
                  >
                    {' '}
                    {currentSubscriptionRenewalDate.format(DayjsFormats.full6)}
                  </Typography>
                </>
              }
              placement={'top'}
            >
              <Icon icon={'question'} tertiary />
            </Tooltip>
          )}
        </Box>

        <Typography
          variant={'body-md-semibold'}
          color={'var(--content-primary-primary)'}
          ariaLabel={'Total due today value'}
        >
          {totalDueToday}
        </Typography>
      </Box>
    </Box>
  )
})
