import { makeAutoObservable } from 'mobx'
import { IAPiOption } from 'shared/api'
import { IDropdownItem } from 'shared/ui'
import { isLink } from 'shared/lib'
import { ComplianceApi } from 'entities/Compliance'

export class BusinessInfoStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _businessTypes: IAPiOption[] = []
  private _businessIndustries: IAPiOption[] = []
  private _activeBusinessTypeId: number | null = null
  private _activeBusinessIndustryId: string | null = null
  private _websiteUrl: string | null = null
  private _noWebsiteUrl = false
  private _websiteUrlError = ''
  private _regionsOfOperation: IAPiOption[] = []
  private _activeRegionOfOperationId: string | null = null

  get businessTypes(): IDropdownItem[] {
    return this._businessTypes.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get activeBusinessTypeId() {
    return this._activeBusinessTypeId
  }

  get businessIndustries(): IDropdownItem[] {
    return this._businessIndustries.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get activeBusinessIndustryId() {
    return this._activeBusinessIndustryId
  }

  get websiteUrl() {
    return this._websiteUrl
  }

  get noWebsiteUrl() {
    return this._noWebsiteUrl
  }

  get regionsOfOperationId() {
    return this._activeRegionOfOperationId
  }

  get regionsOfOperation(): IDropdownItem[] {
    return this._regionsOfOperation.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get websiteUrlError() {
    return this._websiteUrlError
  }

  get isNextDisabled() {
    return !(
      !this._websiteUrlError &&
      this._activeBusinessTypeId &&
      this._activeBusinessIndustryId &&
      (this._websiteUrl || this._noWebsiteUrl) &&
      this._activeRegionOfOperationId
    )
  }

  setWebsiteUrlError = (error: string) => {
    this._websiteUrlError = error
  }

  checkEmail = (url: string) => {
    const isWebsite = isLink(url)

    if (!isWebsite) {
      this._websiteUrlError = 'Please enter a valid url (e.g. https://www.example.com/)'
    }
  }

  setBusinessType = (businessTypeId: number | null) => {
    this._activeBusinessTypeId = businessTypeId
  }

  loadBusinessTypes = async () => {
    if (this._businessTypes.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileBusinessTypes()
      this._businessTypes = data
    } catch (e) {
      console.error(e)
    }
  }

  setBusinessIndustry = (businessIndustryId: string | null) => {
    this._activeBusinessIndustryId = businessIndustryId
  }

  loadBusinessIndustries = async () => {
    if (this._businessIndustries.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileIndustries()
      this._businessIndustries = data
    } catch (e) {
      console.error(e)
    }
  }

  setWebsiteUrl = (url: string) => {
    if (this._websiteUrlError) {
      this.setWebsiteUrlError('')
    }

    this._websiteUrl = url
  }

  setNoWebsiteUrl = () => {
    if (this._websiteUrlError) {
      this.setWebsiteUrlError('')
    }

    this._noWebsiteUrl = !this._noWebsiteUrl
  }

  setRegionsOfOperationId = (regionsOfOperation: string | null) => {
    this._activeRegionOfOperationId = regionsOfOperation
  }

  loadBusinessProfileRegionsOfOperation = async () => {
    if (this._regionsOfOperation.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileRegionsOfOperation()
      this._regionsOfOperation = data
    } catch (e) {
      console.error(e)
    }
  }
}
