import { makeAutoObservable } from 'mobx'
import { DayjsFormats } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import {
  AdvancedSchedulingStore,
  getDefaultQuarterTime,
  IInitAdvancedScheduling,
} from 'shared/ui/Schedule'

export type IInitSendLaterStoreProps = {
  date: string | null
  isUseContactTimezone: boolean
  advancedDate: IInitAdvancedScheduling
}

export class SendLaterStore {
  constructor() {
    makeAutoObservable(this)
  }

  defaultDate = getDefaultQuarterTime()

  date: Date | null = this.defaultDate
  dropdownId = ''
  advancedSchedulingStore = new AdvancedSchedulingStore()

  isUseContactTimezone = false

  get laterDate() {
    return uiStore.dayjs(this.date || this.defaultDate).add(-uiStore.offsetMinute, 'minutes')
  }

  get utcDateString() {
    return this.laterDate.utc().format(DayjsFormats.apiFormat)
  }

  get scheduleText() {
    return this.laterDate.format(DayjsFormats.fullWithAtDash3)
  }

  init = ({ date, isUseContactTimezone, advancedDate }: IInitSendLaterStoreProps) => {
    if (date) {
      this.setDate(uiStore.dayjs(date).add(uiStore.offsetMinute, 'minutes').toDate(), true)
    }
    this.isUseContactTimezone = isUseContactTimezone
    this.advancedSchedulingStore.init(advancedDate)
  }

  initDefault = () => {
    this.defaultDate = getDefaultQuarterTime()
  }

  toggleUseContactTimezone = () => {
    this.isUseContactTimezone = !this.isUseContactTimezone
  }

  setDropdownId = (dropdownId: string) => {
    this.dropdownId = dropdownId
    this.advancedSchedulingStore.setDropdownId(dropdownId)
  }

  setDate = (date: Date | null, isNoValidate?: boolean) => {
    this.defaultDate = getDefaultQuarterTime(date)
    this.date = date
    if (this.laterDate.isBefore(uiStore.dayjs()) && !isNoValidate) {
      this.defaultDate = getDefaultQuarterTime()
      this.date = this.defaultDate
    }

    this.advancedSchedulingStore.setDate(this.date || this.defaultDate)
  }
}
