import { makeAutoObservable, runInAction } from 'mobx'
import { EnumDropdownItemVariant, IDropdownItem } from 'shared/ui'
import { deviceStore, IDeviceStore } from './deviceStore'

export enum DeviceItemsEnum {
  audioInput = 'audioInput',
  videoInput = 'videoInput',
  audioOutput = 'audioOutput',
  recordAuto = 'recordAuto',
}

type IDeviceSettingsStoreProps = {
  items: Array<DeviceItemsEnum>
}

export class DeviceSettingsStore {
  items: IDeviceSettingsStoreProps['items'] = []

  constructor({ items }: IDeviceSettingsStoreProps) {
    this.items = items
    makeAutoObservable(this)
  }

  loading = false

  stream: MediaStream | null = null

  get device(): IDeviceStore {
    return deviceStore
  }

  get audioOutputsItems(): IDropdownItem[] {
    return this.device.audioOutputs.map((device) => ({
      id: device.deviceId,
      label: device.label,
      activeValue: this.device.audioOutputId === device.deviceId,
      variant: EnumDropdownItemVariant.CheckedRight,
    }))
  }

  get audioInputsItems(): IDropdownItem[] {
    return this.device.audioInputs.map((device) => ({
      id: device.deviceId,
      label: device.label,
      activeValue: this.device.audioInputId === device.deviceId,
      variant: EnumDropdownItemVariant.CheckedRight,
    }))
  }

  get videoInputsItems(): IDropdownItem[] {
    return this.device.videoInputs.map((device) => ({
      id: device.deviceId,
      label: device.label,
      activeValue: this.device.videoInputId === device.deviceId,
      variant: EnumDropdownItemVariant.CheckedRight,
    }))
  }

  initStream = async () => {
    try {
      this.removeStream()

      const isAudio =
        this.items.includes(DeviceItemsEnum.audioOutput) ||
        this.items.includes(DeviceItemsEnum.audioInput)
      const isVideo = this.items.includes(DeviceItemsEnum.videoInput)

      const videoInput = this.device.videoInput
      const videoOptions = {
        video: {
          deviceId: videoInput?.deviceId,
          groupId: videoInput?.groupId,
          aspectRatio: 4 / 3,
        },
      }

      const audioInput = this.device.audioInput
      const audioOptions = {
        audio: {
          deviceId: audioInput?.deviceId,
          groupId: audioInput?.groupId,
        },
      }

      const stream = await window.navigator.mediaDevices.getUserMedia({
        ...(isVideo ? videoOptions : null),
        ...(isAudio ? audioOptions : null),
      })

      runInAction(() => {
        this.addStream(stream)
      })
    } catch (e) {
      console.error(e)
    }
  }

  syncDevicesStream = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      await this.device.syncDevices()
      await this.initStream()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setSelectedAudioOutput = (deviceId: string) => {
    this.device.setAudioOutput(deviceId)
    this.initStream()
  }

  setSelectedAudioInput = (deviceId: string) => {
    this.device.setAudioInput(deviceId)
    this.initStream()
  }

  setSelectedVideoInput = (deviceId: string) => {
    this.device.setVideoInput(deviceId)
    this.initStream()
  }

  stopTracks() {
    this.stream?.getTracks().forEach((track) => track.stop())
  }

  removeStream = () => {
    this.stopTracks()

    runInAction(async () => {
      this.stream = null
    })
  }

  addStream = (stream: MediaStream) => {
    this.stream = stream
  }

  checkPermissions = async (isAudio: boolean) => {
    try {
      const config: MediaStreamConstraints = {}

      if (isAudio) config.audio = true
      if (!isAudio) config.video = true

      const stream = await window.navigator.mediaDevices.getUserMedia(config)

      this.addStream(stream)

      return true
    } catch (e) {
      console.error(e)

      return false
    }
  }
}
