import { useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown, DropdownContent, showToast } from 'shared/ui'
import { TextField, makeTextFieldAutoFocus, makeTextFieldLoading } from 'shared/ui/TextField'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'

type IActionsProps = {
  id: string | number
}

export const Actions: FC<IActionsProps> = observer(({ id }) => {
  const store = useContactsIntegrationsContext()
  const createContactsIntegrationsActiveCampaignTag =
    store.createContactsIntegrationsActiveCampaignTag
  const [open, setOpen] = useState(false)
  const [tag, setTag] = useState('')
  const [creating, setCreating] = useState(false)

  const onCreate = async () => {
    const data = {
      tag,
      contact_integration_id: id,
    }

    try {
      setCreating(true)
      const toast = await createContactsIntegrationsActiveCampaignTag(data)

      showToast({
        type: toast.status,
        title: 'Tag applied in ActiveCampaign',
      })
    } catch (e) {
      console.log(e)
    } finally {
      setTag('')
      setOpen(false)
      setCreating(false)
    }
  }

  return (
    <Dropdown
      ariaLabel={'Actions'}
      show={open}
      onToggle={setOpen}
      margin={-36}
      typeButton='button'
      buttonProps={{
        size: 'small',
        text: 'Apply tag',
        typeBtn: 'text',
        contained: 'secondary',
        icon: undefined,
      }}
      placement={'bottom-start'}
      customComponent={() => (
        <DropdownContent>
          <TextField
            size='small'
            variant='integrated'
            value={tag}
            onChange={setTag}
            InputProps={{
              placeholder: 'Add tag',
            }}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
            rightActions={[makeTextFieldLoading({ loading: creating })]}
            disabled={creating}
          />
          <Button
            onClick={onCreate}
            disabled={!tag.length || creating}
            size={'medium'}
            text={'Apply tag'}
            typeBtn={'contained'}
            contained={'primary'}
            loading={creating}
            fullWidth
          />
        </DropdownContent>
      )}
    />
  )
})
