import { BUILD_LIGHT_PATH, CDN_FRONT, IS_APP_LIGHT } from 'shared/config'

export const getCDNAssetURL = (url: string) => {
  const normalUrl =
    IS_APP_LIGHT && BUILD_LIGHT_PATH && url.startsWith(BUILD_LIGHT_PATH)
      ? url.replace(BUILD_LIGHT_PATH, '/')
      : url

  const urlWithoutSlash = normalUrl.startsWith('/') ? normalUrl.replace('/', '') : normalUrl

  return CDN_FRONT ? `https://${CDN_FRONT}/ui/v2/${urlWithoutSlash}` : url
}
