import { IStatusProps } from 'shared/ui/Status'

export const organizationStatusConfig = {
  active: {
    title: 'Active',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
  banned: {
    title: 'Banned',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
}
