import React, { FC, useMemo, useState } from 'react'
import { AxiosResponse } from 'axios'
import { observer } from 'mobx-react-lite'
import { IParamsTestSMS, IResponseTestSMS } from 'widgets/TestSMS/types'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { SendTestModalStore, TestSmsWithMergeField } from 'widgets/TestSMS/ui/TestSmsWithMergeField'
import { TestSMSDefault } from 'widgets/TestSMS/ui/TestSmsDefault/TestSMSDefault'

export type ITestSMSProps = {
  disabled?: boolean
  params: IParamsTestSMS
  request: (params: IParamsTestSMS) => Promise<AxiosResponse<IResponseTestSMS>>
  onToggleShowTest?: (value: boolean) => void
  isTopError?: boolean
}

export const TestSMS: FC<ITestSMSProps> = observer((props) => {
  const { disabled, params } = props
  const [sendTestModalStore] = useState(() => new SendTestModalStore())
  const mergeFieldsTemplates = useMemo(
    () =>
      params.message.match(/{.*?}/g)?.filter((field) => allMergeFieldsStore.isMergeField(field)),
    [params.message]
  )

  const withMergeWields = Boolean(mergeFieldsTemplates?.length)

  if (withMergeWields) {
    return (
      <TestSmsWithMergeField
        {...props}
        mergeFieldsTemplates={mergeFieldsTemplates || []}
        disabled={disabled}
        sendTestModalStore={sendTestModalStore}
      />
    )
  }
  return <TestSMSDefault sendTestModalStore={sendTestModalStore} {...props} />
})
