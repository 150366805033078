import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import React, { FC } from 'react'
import { Button, Icon, Status, Typography } from 'shared/ui'
import { IAIActionTypeEnum } from 'entities/AIAssistant'
import { AiAssistantStore } from 'widgets/AIAssistant/store/aiAssistantStore'
import styles from './styles.module.scss'

interface IInitContentProps {
  aiAssistantStore: AiAssistantStore
}
export const InitContent: FC<IInitContentProps> = observer(({ aiAssistantStore }) => {
  const {
    isEmpty,
    disabledSummarizeConversation,
    onCreateSuggestAnswer,
    onRephrase,
    onMoreFriendly,
    onMoreFormal,
    onExpand,
    onShorten,
    onCreateSummaryAnswer,
    disabledSuggested,
    showSuggested,
    showSummarizeConversation,
    showRephrase,
    showMoreFriendly,
    showMoreFormal,
    showExpand,
    showShorten,
    isContactsPage,
  } = aiAssistantStore
  return (
    <div className={styles.wrap}>
      <div className={classNames('row4', styles.title)}>
        <Icon icon={'stars'} fontSize={16} color={'var(--content-primary-tertiary)'} />
        <Typography tag='div' variant='body-sm-medium' color='tertiary' ariaLabel={'SalesmsgAI'}>
          Salesmsg AI
        </Typography>
      </div>
      {showSuggested && (
        <Button
          text={IAIActionTypeEnum.SuggestResponse}
          typeBtn={'menuItem'}
          onClick={onCreateSuggestAnswer}
          disabled={disabledSuggested}
          fullWidth
          tooltipProps={{
            label: disabledSuggested && (
              <>
                A longer conversation is needed for better
                <br />
                suggestions
              </>
            ),
            placement: 'top',
            zIndex: 2000,
          }}
        />
      )}
      {showSummarizeConversation && (
        <Button
          text={
            <>
              {IAIActionTypeEnum.SummarizeConversation}
              <Status title={'New'} emphasis={'high'} intent={'purple'} size={'small'} />
            </>
          }
          typeBtn={'menuItem'}
          onClick={onCreateSummaryAnswer}
          disabled={disabledSummarizeConversation}
          fullWidth
          tooltipProps={{
            label: disabledSummarizeConversation && !isContactsPage && (
              <>
                A longer conversation is needed for better
                <br />
                Summary
              </>
            ),
            placement: 'top',
            zIndex: 2000,
          }}
        />
      )}
      {showRephrase && (
        <Button
          text={IAIActionTypeEnum.Rephrase}
          typeBtn={'menuItem'}
          onClick={onRephrase}
          disabled={isEmpty}
        />
      )}
      {showMoreFriendly && (
        <Button
          text={IAIActionTypeEnum.MoreFriendly}
          typeBtn={'menuItem'}
          onClick={onMoreFriendly}
          disabled={isEmpty}
        />
      )}
      {showMoreFormal && (
        <Button
          text={IAIActionTypeEnum.MoreFormal}
          typeBtn={'menuItem'}
          onClick={onMoreFormal}
          disabled={isEmpty}
        />
      )}
      {showExpand && (
        <Button
          text={IAIActionTypeEnum.Expand}
          typeBtn={'menuItem'}
          onClick={onExpand}
          disabled={isEmpty}
        />
      )}
      {showShorten && (
        <Button
          text={IAIActionTypeEnum.Shorten}
          typeBtn={'menuItem'}
          onClick={onShorten}
          disabled={isEmpty}
        />
      )}
    </div>
  )
})
