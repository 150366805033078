import { observer } from 'mobx-react-lite'
import { EnumHelperText, HelperText, Typography } from 'shared/ui'
import { useChatbotContext } from 'pages/chatbot/context'

import { AutoFollowUp } from './AutoFollowUp'
import styles from './styles.module.scss'

export const FallbackSettings = observer(() => {
  const {
    model: { settings },
    errors,
  } = useChatbotContext()

  const followUpWindowError = errors.getError('settings.followup_window')

  return (
    <section className={styles.container}>
      <Typography variant={'body-lg-medium'} ariaLabel={'AutoFollowUp'}>
        Auto FollowUp™
      </Typography>
      <Typography
        variant={'body-md-regular'}
        ariaLabel={'AutoFollowUpDesc'}
        color={'var(--content-primary-secondary)'}
        marginTop={4}
      >
        AI-powered follow-ups to maximize engagement
      </Typography>
      <AutoFollowUp settings={settings} />
      {!!followUpWindowError && (
        <HelperText
          icon={'alertTriangleSmall'}
          variant={EnumHelperText.Error}
          text={followUpWindowError}
        />
      )}
    </section>
  )
})
