import React from 'react'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import { RinglessContent } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/ui/BodyContent/TypeContent/RinglessContent/RinglessContent'
import { SMSContent } from './SMSContent/SMSContent'

export const TypeContent = () => {
  const broadcast = useBroadcastDetailModalContext()
  if (broadcast.type === 'sms') {
    return <SMSContent attachments={broadcast.attachments} message={broadcast.message} />
  }
  if (broadcast.type === 'ringless') {
    return <RinglessContent attachments={broadcast.attachments} />
  }
  return <></>
}
