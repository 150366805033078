import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { complianceStore } from 'entities/Compliance/store/complianceStore'
import { TollFreeStatus } from 'entities/Compliance'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { adminTollFreeDetailStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails'
import { adminTollFreeCampaignStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/Campaign/store/adminTollFreeCampaignStore'
import { getRejectReasonRow } from 'pages/admin/pages/compliance'

export const Campaign = observer(() => {
  const { setData } = adminTollFreeDetailStore
  const {
    status,
    isForEdit,
    isSaveLoading,
    getError,
    rejectReason,

    vendor_id,
    edit_vendor_id,
    is_edit_vendor_id,
    set_edit_vendor_id,

    use_case,
    edit_use_case,
    is_edit_use_case,
    set_edit_use_case,

    explanation_description,
    edit_explanation_description,
    is_edit_explanation_description,
    set_edit_explanation_description,

    use_case_description,
    edit_use_case_description,
    is_edit_use_case_description,
    set_edit_use_case_description,

    sample_message_1,
    edit_sample_message_1,
    is_edit_sample_message_1,
    set_edit_sample_message_1,

    sample_message_2,
    edit_sample_message_2,
    is_edit_sample_message_2,
    set_edit_sample_message_2,

    opt_int_type,
    edit_opt_int_type,
    is_edit_opt_int_type,
    set_edit_edit_opt_int_type,

    opt_in_method,
    edit_opt_in_method,
    is_edit_opt_in_method,
    remove_opt_in_attachment,
    add_opt_in_file,
    is_edit_opt_in_method_description,
    add_opt_in_description,

    message_volume,
    edit_message_volume,
    is_edit_message_volume,
    set_edit_message_volume,

    onEdit,
    isEdit,
    onSave,
    onClose,
  } = adminTollFreeCampaignStore

  const getOptInRows = (): IAdminDetailCardRowProps[] => {
    if (isEdit) {
      if (edit_opt_in_method?.title) {
        return [
          {
            typeEdit: 'textarea',
            title: `Opt-in description via ${edit_opt_in_method?.title}`,
            editValue: edit_opt_in_method?.description,
            isUnsaved: is_edit_opt_in_method_description,
            onChange: add_opt_in_description,
            value: edit_opt_in_method?.description || '',
            editError: getError('opt_in_data.0.description'),
          },
          {
            typeEdit: 'attachments',
            title: edit_opt_in_method?.title,
            editValue: edit_opt_in_method?.attachments,
            isUnsaved: is_edit_opt_in_method,
            removeAttachments: (ids: Array<string | number>) => {
              remove_opt_in_attachment(ids[0])
            },
            onAddFile: (file: File) => {
              add_opt_in_file(file)
            },
            value: edit_opt_in_method?.attachments || [],
          },
        ]
      }
    } else {
      if (opt_in_method?.title) {
        return [
          {
            title: `Opt-in description via ${opt_in_method?.title}`,
            value: opt_in_method?.description || '',
          },
          {
            typeEdit: 'attachments',
            title: opt_in_method?.title,
            value: opt_in_method?.attachments || [],
          },
        ]
      }
    }

    return []
  }

  const data: IAdminDetailCardRowProps[] = [
    {
      title: 'Twilio id',
      value: vendor_id,
      editValue: edit_vendor_id,
      isUnsaved: is_edit_vendor_id,
      onChange: set_edit_vendor_id,
      editError: getError('vendor_id'),
      isNoEdit: true,
    },
    ...getRejectReasonRow(status === 'Denied' || status === 'Blocked', rejectReason),
    {
      title: 'Campaign category',
      value: use_case,
      editValue: edit_use_case,
      isUnsaved: is_edit_use_case,
      onChange: set_edit_use_case,
      typeEdit: 'select',
      options: complianceStore.tollFreeUseCases,
      loadOptions: complianceStore.loadTollFreeFormFields,
      editError: getError('use_case_id'),
    },

    {
      typeEdit: 'textarea',
      title: 'Explanation',
      labelTooltip: {
        label: 'The customer’s reason for adding multiple toll free numbers to one use case',
      },
      value: explanation_description,
      editValue: edit_explanation_description,
      isUnsaved: is_edit_explanation_description,
      onChange: set_edit_explanation_description,
      editError: getError('explanation_description'),
    },
    {
      typeEdit: 'textarea',
      title: 'Campaign description',
      value: use_case_description,
      editValue: edit_use_case_description,
      isUnsaved: is_edit_use_case_description,
      onChange: set_edit_use_case_description,
      editError: getError('use_case_description'),
    },
    {
      typeEdit: 'textarea',
      title: 'Sample message 1',
      value: sample_message_1,
      editValue: edit_sample_message_1,
      isUnsaved: is_edit_sample_message_1,
      onChange: set_edit_sample_message_1,
      editError: getError('sample_message_1'),
    },
    {
      typeEdit: 'textarea',
      title: 'Sample message 2',
      value: sample_message_2,
      editValue: edit_sample_message_2,
      isUnsaved: is_edit_sample_message_2,
      onChange: set_edit_sample_message_2,
      editError: getError('sample_message_2'),
    },
    {
      title: 'Opt-in type',
      value: opt_int_type,
      editValue: edit_opt_int_type,
      isUnsaved: is_edit_opt_int_type,
      onChange: set_edit_edit_opt_int_type,
      typeEdit: 'select',
      options: complianceStore.optInTollFreeTypes,
      loadOptions: complianceStore.loadOptInTollFreeTypes,
      editError: getError('opt_int_types'),
    },
    ...getOptInRows(),
    {
      title: 'Message volume',
      value: message_volume,
      editValue: edit_message_volume,
      isUnsaved: is_edit_message_volume,
      onChange: set_edit_message_volume,
      typeEdit: 'select',
      options: complianceStore.tollFreeMessageVolume,
      loadOptions: complianceStore.loadTollFreeFormFields,
      editError: getError('use_case_id'),
    },
  ]

  const handleSave = async () => {
    try {
      const res = await onSave()
      if (res?.data) {
        setData(res?.data)
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <AdminDetailCard
      title={'Campaign'}
      data={data}
      additionalTitleContent={status && <TollFreeStatus status={status} />}
      padding={'24px 16px'}
      isEdit={isEdit}
      actions={
        isForEdit &&
        (!isEdit ? (
          <Button
            text={'Edit'}
            contained={'secondary'}
            size={'medium'}
            icon={'edit'}
            onClick={onEdit}
          />
        ) : (
          <>
            <Button text={'Cancel'} contained={'secondary'} size={'medium'} onClick={onClose} />
            <Button
              text={'Save'}
              contained={'primary'}
              size={'medium'}
              onClick={handleSave}
              disabled={isSaveLoading}
            />
          </>
        ))
      }
    />
  )
})
