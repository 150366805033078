import { observer } from 'mobx-react-lite'
import { useState, FC } from 'react'
import { EmptyState, MenuTitle } from 'shared/ui'
import { Segment } from 'entities/Segment/model/Segment'
import { SegmentItem } from '../SegmentItem/SegmentItem'

import styles from './styles.module.scss'

type ISegmentMenuItemProps = {
  segments: Segment[]
  search: string
  setSearch: (value: string) => void
  placeholder: string
  title: string
}

export const SegmentMenuItem: FC<ISegmentMenuItemProps> = observer(
  ({ segments, search, setSearch, placeholder, title }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true)

    const handleToggle = () => {
      setIsOpen(!isOpen)
    }

    const renderEmptyState = () => {
      return (
        <div className={styles.empty}>
          <EmptyState
            noIcon
            alignItems={'flex-start'}
            typographyProps={{
              variant: 'body-md-regular',
              color: 'var(--content-primary-disabled)',
              ariaLabel: 'EmptyState',
            }}
          />
        </div>
      )
    }

    return (
      <>
        {(!!segments.length || search) && (
          <MenuTitle
            useSearch={{
              handleSearch: setSearch,
              search: search,
              placeholder: placeholder,
              autofocus: true,
              variant: 'transparent',
            }}
            title={title}
            count={0}
            open={isOpen}
            handleToggle={handleToggle}
          />
        )}
        {Boolean(!segments.length && search.length) && renderEmptyState()}
        {isOpen && (
          <div className={styles.segments}>
            {segments.map((segment) => (
              <SegmentItem key={segment.id} segment={segment} />
            ))}
          </div>
        )}
      </>
    )
  }
)
