import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import type { IResponseUserIntegration } from 'entities/Integrations'
import { integrationsStore } from 'entities/Integrations/store/integrations'

const IntegrationConnected = (data: IResponseUserIntegration) => {
  integrationsStore.syncUserIntegration(data)
}

export const useEventIntegrationConnected = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.IntegrationConnected, IntegrationConnected)
    }
  }, [websocket.isConnected])
}
