import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IResponseIntegrationHubspotPropertyFormatted,
  IntegrationKey,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactTab } from '../../TabsView/ContactTab/ContactTab'

type IPropertiesProps = {
  properties: IResponseIntegrationHubspotPropertyFormatted[]
  contact: Contact
}

const Properties = ({ properties, contact }: IPropertiesProps) => {
  const userIntegration = integrationsStore.getUserIntegration(IntegrationKey.hubspot)

  const linkProps = {
    key: IntegrationKey.hubspot,
    contact,
    integration: userIntegration,
    type: IIntegrationLinkTypes.contact,
  }

  const link = integrationLink(linkProps)

  const firstName = properties.find((item) => item.key === 'firstname')?.value
  const lastName = properties.find((item) => item.key === 'lastname')?.value

  let name = null
  if (firstName) name = `${firstName} `
  if (lastName) name += `${lastName}`

  const data = Array.isArray(properties)
    ? properties.filter((item) => item.key !== 'firstname' && item.key !== 'lastname')
    : null

  return <ContactTab title={name} link={link} data={data} />
}

export { Properties }
