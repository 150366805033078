import { FC, useEffect, useState } from 'react'
import classnames from 'classnames'
import Highlighter from 'react-highlight-words'
import {
  DropdownCard,
  EnumDropdownItemVariant,
  Icon,
  IDropdownItem,
  InputCheckbox,
  InputCheckboxProps,
  Typography,
} from 'shared/ui'
import { Segment } from 'entities/Segment/model/Segment'
import { ContactsApi } from 'entities/Contacts'
import { IParamsContactFiltersCount } from 'entities/Contacts/api/types'
import styles from './styles.module.scss'

type ISegmentDropdownCardProps = {
  item: Segment
  InputCheckboxProps?: InputCheckboxProps
  isCount?: boolean
  onChange?: (item: Segment) => void
  highlighter?: string
  ariaLabel?: string
}

const getItemCount = (filtersListGroups: IParamsContactFiltersCount['filtersListGroups']) =>
  ContactsApi.getContactsFiltersCount({ filtersListGroups })
    .then(({ data: { count, invalid_count } }) => ({ count, invalid_count }))
    .catch(() => ({ count: 0, invalid_count: 0 }))

export const SegmentDropdownCard: FC<ISegmentDropdownCardProps> = ({
  item,
  InputCheckboxProps,
  onChange,
  isCount,
  highlighter,
  ariaLabel,
}) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (isCount) {
      getItemCount({
        segments: [item.id],
      }).then(({ count, invalid_count }) => {
        setCount(count + invalid_count)
      })
    }
  }, [])

  const renderContent = (isCheckbox: boolean) => {
    return (
      <div
        className={classnames(styles.wrap, {
          [styles['wrap--checkbox']]: isCheckbox,
        })}
      >
        <div className={styles.icon}>
          <Icon icon={'segment'} />
        </div>
        <Typography
          ellipsis
          ariaLabel={'count'}
          variant={'text-input-field-r'}
          className={styles.title}
        >
          {highlighter ? (
            <Highlighter
              highlightClassName={classnames(styles.highlighter, styles['highlighter--label'])}
              className={styles.name}
              searchWords={highlighter.split(' ')}
              autoEscape={true}
              textToHighlight={item.name}
            />
          ) : (
            item.name
          )}
        </Typography>
        {isCount && (
          <Typography ariaLabel={'count'} variant={'body-xs-medium'} className={styles.count}>
            {count}
          </Typography>
        )}
      </div>
    )
  }
  const dropdown: IDropdownItem = {
    id: item.id,
    iconL: 'segment',
    label: item.name,
    variant: EnumDropdownItemVariant.Custom,
    renderOption: () => renderContent(false),
  }

  if (InputCheckboxProps) {
    return (
      <InputCheckbox
        className={styles.checkbox}
        {...InputCheckboxProps}
        customLabel={renderContent(true)}
      />
    )
  }

  return (
    <DropdownCard
      item={dropdown}
      ariaLabel={ariaLabel}
      onChange={() => {
        if (onChange) onChange(item)
      }}
    />
  )
}
