import { createContext, useContext } from 'react'
import { type ConversationMenuStore } from 'widgets/ConversationMenu'

export const ConversationMenuContext = createContext<ConversationMenuStore | null>(null)

export const useConversationMenuContext = () => {
  const context = useContext(ConversationMenuContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ConversationMenuContext')

  return context
}
