import { Box } from '@mui/material'
import { type ILabelProps, Label } from '../Label'
import { TextField } from '../TextField/TextField'
import type { ITextFieldProps } from '../TextField/types'
import { Error } from '../Error'

import styles from './styles.module.scss'

interface ITextFieldWithLabelProps {
  marginBottom?: number
  labelProps: ILabelProps
  textFieldProps: ITextFieldProps
  bottomLabelProps?: ILabelProps
  errorMessage?: string
}

export const TextFieldWithLabel = ({
  marginBottom = 0,
  labelProps,
  textFieldProps,
  bottomLabelProps,
  errorMessage,
}: ITextFieldWithLabelProps) => {
  return (
    <Box marginBottom={marginBottom} width={'100%'}>
      {labelProps.label && <Label {...labelProps} />}
      <TextField variant={'stroke'} size={'medium'} {...textFieldProps} error={errorMessage} />
      {bottomLabelProps && <Label {...bottomLabelProps} />}
      <Error type='text' error={errorMessage} size='small' wrapClassName={styles.error} />
    </Box>
  )
}
