import { RefObject, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { CallPowerDialerSection } from 'widgets/CallPopUp/ui/CallPowerDialer/CallPowerDialerSection/CallPowerDialerSection'
import { CallQueueSection } from 'widgets/CallPopUp/ui/CallQueue/CallQueueSection/CallQueueSection'
import { CallPopUpContent } from 'widgets/CallPopUp/ui/CallPopUpBase/CallPopUpContent/CallPopUpContent'
import { CallPowerDialerBaseContent } from 'widgets/CallPopUp/ui/CallPowerDialer/CallPowerDialerBaseContent/CallPowerDialerBaseContent'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

type ICallPopUpViewDefaultProps = {
  refWrap: RefObject<HTMLDivElement>
}

export const CallPopUpViewDefault: FC<ICallPopUpViewDefaultProps> = observer(({ refWrap }) => {
  const store = useCallPopUpDefaultContext()
  const { callPopUpQueueStore, callPopUpPowerDialerStore, isStatusClose } =
    store as CallPopUpDefaultStore

  const renderContent = () => {
    if (isStatusClose) {
      return <CallPowerDialerBaseContent refWrap={refWrap} />
    }

    return <CallPopUpContent refWrap={refWrap} />
  }

  return (
    <>
      {renderContent()}

      {callPopUpPowerDialerStore.hasItems && (
        <div className={classnames(styles.section, styles.section__indent)}>
          <CallPowerDialerSection />
        </div>
      )}

      {callPopUpQueueStore.hasItems && (
        <div className={classnames(styles.section, styles.section__indent)}>
          <CallQueueSection type={'default'} />
        </div>
      )}
    </>
  )
})
