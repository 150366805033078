import { IResponseEventViewing, IViewingStatus } from 'entities/Viewing'

export class Viewing {
  userId: number
  first_name: string
  last_name: string
  conversation_id: number
  timerId: NodeJS.Timer
  status: IViewingStatus

  constructor(item: IResponseEventViewing, timerId: NodeJS.Timer, status: IViewingStatus) {
    this.userId = item.id
    this.first_name = item.first_name
    this.last_name = item.last_name
    this.conversation_id = item.conversation_id
    this.timerId = timerId
    this.status = status
  }
}
