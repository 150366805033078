// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GOW7DX0NgZ1jfGznRl_R{position:relative;padding:0 24px 24px 24px}.FiGgTNlfBTJT6OfkkllA:not(:empty){padding-bottom:20px}.ZY3NzcpcJGNIWGg_mZvT{padding:0 12px}.ZY3NzcpcJGNIWGg_mZvT input{font-size:14px;height:18px}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/NewSavedReplyContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,wBAAA,CAIA,kCACE,mBAAA,CAIJ,sBACE,cAAA,CAEA,4BACE,cAAA,CACA,WAAA","sourcesContent":[".wrap {\n  position: relative;\n  padding: 0 24px 24px 24px;\n}\n\n.alertContainer {\n  &:not(:empty) {\n    padding-bottom: 20px;\n  }\n}\n\n.textField {\n  padding: 0 12px;\n\n  & input {\n    font-size: 14px;\n    height: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "GOW7DX0NgZ1jfGznRl_R",
	"alertContainer": "FiGgTNlfBTJT6OfkkllA",
	"textField": "ZY3NzcpcJGNIWGg_mZvT"
};
export default ___CSS_LOADER_EXPORT___;
