import { ChannelOrganization } from 'entities/WebSocket'
import { organizationStore } from 'entities/Organization'

class ChannelsOrganization {
  constructor() {}

  subscribeChannels = () => {
    if (!organizationStore.id) return

    new ChannelOrganization({
      id: organizationStore.id,
    })
  }
}

const channelsOrganization = new ChannelsOrganization()

export { channelsOrganization }
