import { makeAutoObservable, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { cloneDeep } from 'lodash'
import { APP_ENV } from 'shared/config'
import { dataLogApi } from 'entities/DataLog/api/dataLogApi'
import { userSettingsStore } from 'entities/Settings'
import { ILog, IParamSendLogs } from 'entities/DataLog/api/types'

class DataLogStore<V> {
  constructor() {
    makeAutoObservable(this)
    reaction(() => this.isFull, this.sendLogs)
    reaction(() => this.hasItems, this.sendLogs, {
      delay: 5000,
    })
  }

  itemsMap: Map<string, IParamSendLogs<V>> = new Map()
  limit = 50

  get versionOfDesign() {
    return userSettingsStore.isSalesmsgDesignV2 ? 'v2' : 'v1'
  }

  get source() {
    return `${APP_ENV}-web-app-${this.versionOfDesign}`
  }

  get items() {
    return Array.from(this.itemsMap.values())
  }

  get hasItems() {
    return this.items.length
  }
  get isFull() {
    return this.items.length === this.limit
  }

  setItem = ({ logLevel, details, message }: ILog<V>) => {
    const data: IParamSendLogs<V> = {
      timestamp: new Date().toISOString(),
      source: this.source,
      message,
      logLevel,
      details,
    }

    this.itemsMap.set(nanoid(), data)
  }

  sendLogs = async () => {
    if (!this.items.length) return
    const storedItems = cloneDeep(this.items)
    this.itemsMap.clear()

    try {
      await dataLogApi.sendLogs({ data: storedItems })
    } catch (e) {
      console.error(e)
    }
  }
}

export const dataLogStore = new DataLogStore()
