// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W8DYZ9y0VRIw_ZW97U4Q{display:flex;align-items:center;gap:4px;height:24px;margin-bottom:4px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/organizations/pages/CustomPlan/ui/PaymentSchedule/style.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".customLabel {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  height: 24px;\n  margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customLabel": "W8DYZ9y0VRIw_ZW97U4Q"
};
export default ___CSS_LOADER_EXPORT___;
