import axios, { CancelTokenSource, AxiosResponse, CanceledError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { debounce } from 'lodash'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { IntegrationsApi, integrationsStore } from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { UserIntegration } from 'entities/Integrations/model/UserIntegration'
import {
  IParamsContactsSearch,
  IParamsCreateContact,
  IResponseContact,
} from 'entities/Contacts/api/types'
import { ISearchDropdownStoreOptions } from 'widgets/SearchDropdown'
import { contactChoosePhoneModalStore } from 'widgets/ContactChoosePhoneModal'

export class SearchDropdownContactsStore {
  private _data: Contact[] = []
  private _loading = false
  private _search = ''
  private _total = 0
  private _page = 1
  private _perPage = 3
  private _debounceFetch
  private _selectedItemsMap: Map<number, Contact> = new Map()
  private _options: ISearchDropdownStoreOptions | null = null
  private _cancelTokenSource: CancelTokenSource | null = null
  private _creating = false

  constructor() {
    makeAutoObservable(this)
    this._debounceFetch = debounce(this.init, 500)
  }

  get firstSelectedItemId() {
    const id = this._selectedItemsMap.entries().next().value?.[0]

    if (!id) return null

    return id
  }

  get selectedItems() {
    return Array.from(this._selectedItemsMap.values())
  }

  get hasSelectedItems() {
    return !!this.selectedItemsCount
  }

  get selectedItemsListIds() {
    return Array.from(this._selectedItemsMap).map((item) => item[1].id)
  }

  get selectedItemsCount() {
    return this._selectedItemsMap.size
  }

  get canSearch() {
    // Start search from 3 or more characters
    return this._search.length > 2
  }

  get hasItems() {
    return !!this._data.length
  }

  get itemsList() {
    return this._data
  }

  get first3Items() {
    return this._data.slice(0, 3)
  }

  initOptions = (options: ISearchDropdownStoreOptions) => {
    this._options = options
  }

  initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  resetCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
  }

  isSelected = (id: number) => {
    return !!this._selectedItemsMap.get(id)
  }

  selectTypeItem = (item: Contact) => {
    this.toggleTypeItem(item)
    this._options?.resetSearch?.()
  }

  createContact = async (item: Contact, callback: (item: Contact) => void) => {
    if (this._creating) return
    if (item.hasIntegrationType) {
      runInAction(() => {
        this._creating = true
      })
      const info = item.integrationInfo
      const email = Array.isArray(info?.email) ? info?.email?.[0] || null : info?.email || null
      const paramsCreateContact = {
        forLink: true,
        fromConversation: true,
      }

      if (item.phoneCount > 1) {
        contactChoosePhoneModalStore.openModal({
          contact: item,
          callback: callback,
          params: paramsCreateContact,
        })

        runInAction(() => {
          this._creating = false
        })
        return
      }

      const params: IParamsCreateContact = {
        email,
        first_name: item.integrationInfo?.first_name,
        last_name: item.integrationInfo?.last_name,
        number: item.integrationInfo?.phone || item.integrationInfo?.mobile_phone,
        ...paramsCreateContact,
      }

      if (item.hasIntegrationType) {
        const integrration = integrationsStore.getIntegration(item.integrationType)

        params.integration_id = integrration?.id
        params.contact_integration_id = `${item?.id}`
      }

      const contact = await contactsStore.createContact(params)

      if (contact) callback(contact)

      runInAction(() => {
        this._creating = false
      })

      return
    }

    callback(item)
  }

  resetType = () => {
    this._selectedItemsMap.clear()
    this.reset()
  }

  toggleTypeItem = (item: Contact) => {
    if (this._options?.selectedMode) {
      if (this._selectedItemsMap.has(item.id)) {
        this._selectedItemsMap.delete(item.id)
      } else {
        this._selectedItemsMap.set(item.id, item)
      }
    }

    this._options?.toggleTypeItem?.(item)
  }

  reset = () => {
    this._data = []
    this._loading = false
    this._search = ''
    this._total = 0
    this._page = 1
    this._cancelTokenSource?.cancel()
    this._debounceFetch.cancel()
  }

  handleSearch = (value: string, fetch = true) => {
    this._search = value
    this.resetCancelTokenSource()
    fetch && this._debounceFetch()
  }

  init = async () => {
    if (!this.canSearch) return

    try {
      runInAction(() => {
        this._loading = true
        this._data = []
        this._total = 0
        this.initCancelTokenSource()
      })

      const params: IParamsContactsSearch = {
        page: this._page,
        per_page: this._perPage,
        skip_opted_out: 0,
        term: this._search || '*',
        exclude: this.selectedItemsListIds,
      }

      if (this._search) params.term = this._search

      const requests: Promise<AxiosResponse>[] = [
        ContactsApi.getContactsSearch(params, {
          cancelToken: this._cancelTokenSource?.token,
        }),
      ]

      integrationsStore.userConnectedIntegrations.forEach((item: UserIntegration) => {
        if (item.key === 'activecampaign') {
          requests.push(
            IntegrationsApi.getIntegrationsContactsActivecampaign(params, {
              cancelToken: this._cancelTokenSource?.token,
            })
          )
        }
        if (item.key === 'hubspot') {
          requests.push(
            IntegrationsApi.getIntegrationsContactsHubspot(params, {
              cancelToken: this._cancelTokenSource?.token,
            })
          )
        }
        if (item.key === 'infusionsoft') {
          requests.push(
            IntegrationsApi.getIntegrationsContactsInfusionsoft(params, {
              cancelToken: this._cancelTokenSource?.token,
            })
          )
        }
        if (item.key === 'pipedrive') {
          requests.push(
            IntegrationsApi.getIntegrationsContactsPipedrive(params, {
              cancelToken: this._cancelTokenSource?.token,
            })
          )
        }
        if (item.key === 'salesforce') {
          requests.push(
            IntegrationsApi.getIntegrationsContactsSalesforce(params, {
              cancelToken: this._cancelTokenSource?.token,
            })
          )
        }
      })

      const allData = await Promise.allSettled(requests)

      let total = 0
      let items: Contact[] = []

      allData.forEach((res) => {
        if (res.status === 'fulfilled') {
          const data = res.value.data

          if (!data.total || !data.results) return

          total += data.total
          items = [
            ...items,
            ...data.results.map((item: IResponseContact) => {
              const contact = new Contact(item)

              if (!contactsStore.getItem(contact.id)) contactsStore.updateItem(item)

              return contact
            }),
          ]
        }

        if (res.status === 'rejected') console.error(res.reason)
      })

      runInAction(() => {
        this._data = items
        this._total = total
      })
    } catch (e) {
      if (e instanceof CanceledError) return

      console.error(e)
    } finally {
      this._loading = false
    }
  }

  get search() {
    return this._search
  }

  get loading() {
    return this._loading
  }

  get options() {
    return this._options
  }

  get total() {
    return this._total
  }

  get selectedItemsMap() {
    return this._selectedItemsMap
  }

  get creating() {
    return this._creating
  }
}
