import React, { useEffect, useState } from 'react'
import { uiStore } from 'shared/store/uiStore'
import { IResponseOneTimeBroadcast } from 'entities/Broadcast/api/types'

import { BroadcastRoutesEnum } from 'entities/Broadcast'
import { BroadcastDetailOneTimeStore } from 'widgets/BroadcastDetail'
import { Content } from './Content'
import { BroadcastDetailOneTimeContext } from '../context/context'

type IBroadcastDetailOneTimeProps = {
  broadcastResponse: IResponseOneTimeBroadcast
}

export const BroadcastDetailOneTime = ({ broadcastResponse }: IBroadcastDetailOneTimeProps) => {
  const [store] = useState(() => new BroadcastDetailOneTimeStore(broadcastResponse))
  useEffect(() => {
    uiStore.setCustomPathLabel(
      `/${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.view}/${broadcastResponse.id}`,
      broadcastResponse.name
    )
  }, [])
  return (
    <BroadcastDetailOneTimeContext.Provider value={store}>
      <Content />
    </BroadcastDetailOneTimeContext.Provider>
  )
}
