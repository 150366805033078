export enum CustomFieldTypes {
  text = 'text',
  date = 'date',
  number = 'number',
  url = 'url',
}

export type IResponseCustomField = {
  id: number
  name: string
  field_key: string
  type?: CustomFieldTypes
  value: {
    type: CustomFieldTypes
    value: string | null
  }
  visible: number
}

export type IResponseNote = {
  id: number
  text: string
  created_at: string
  updated_at: string
  contact_id?: number
  created_by: {
    id: number
    first_name: string
    last_name: string
    photo_url: string
    color?: string
  } | null
}

export type INote = {
  id: number
  text: string
  created_at: string
  updated_at: string
  contact_id?: number
  avatarInfo: {
    id?: number
    firstName?: string
    lastName?: string
    image?: string
    color?: string
  }
}

export type INoteCreate = {
  text: string
}

export type INoteUpdate = {
  text: string
}

export type IAreaCode = {
  area_code: string
  country_code: string
  id: number
  state_code: string | null
  state_name: string | null
  time_zone: string
}

export type IContactPhone = { field: string; type: string; number: string }

export type IPhones = { Phone: string; 'Custom Phone'?: string; 'Mobile Phone'?: string }
