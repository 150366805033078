import { useState } from 'react'
import { Counter } from 'shared/lib'

export const useKeyGenerator = <T>(_min?: number, _max?: number) => {
  const [getKey] = useState(() => {
    const counter = new Counter(_min, _max)
    const itemsMap = new Map<T, string>()

    return (item: T) => {
      const id = itemsMap.get(item)

      if (id != null) return id

      const newId = `${counter.next}`
      itemsMap.set(item, newId)

      return newId
    }
  })

  return getKey
}
