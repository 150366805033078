import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { layoutStore } from 'shared/layout'
import { BreakpointsEnum } from 'shared/constants/breakpoints'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { ConversationMessages } from 'widgets/ConversationMessages'
import { ConversationHeaderSearch } from 'widgets/ConversationHeaderSearch'
import { ConversationLayout } from 'widgets/ConversationLayout'
import { ContactsDetails } from 'widgets/ContactsDetails'
import { CallHistory } from 'widgets/CallHistory'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { useConversationsPageContext } from 'pages/conversations/context'
import styles from './styles.module.scss'

export const Conversations = observer(() => {
  const conversation = conversationStore.currentItem
  const {
    callHistoryStore,
    conversationMenuStore,
    callModalStore,
    conversationSearchStore,
    conversationLayoutStore,
    conversationListStore,
    conversationMessagesStore,
    contactCreateModalStore,
    contactsDetailsStore,
  } = useConversationsPageContext()

  useEffect(() => {
    if (conversation) {
      contactsStore.getById(conversation.contact_id, true)
    }
  }, [conversation])

  const { activeBreakpoint } = layoutStore
  const { isShowChats, isShowCalls } = conversationLayoutStore
  const { isShowContactDetailsOverlay, handleSetVariant } = contactsDetailsGlobalStore
  const isOpenConversations = Boolean(contactsDetailsStore?.isVariantOpenConversations)

  handleSetVariant('conversation')

  useEffect(() => {
    if (isOpenConversations) return

    if (activeBreakpoint === BreakpointsEnum.XL || activeBreakpoint === BreakpointsEnum.L) {
      contactsDetailsGlobalStore.handleChangeCollapse(false)
      conversationMenuStore.handleChangeCollapse(false)

      return
    }

    if (activeBreakpoint === BreakpointsEnum.M) {
      contactsDetailsGlobalStore.handleChangeCollapse(false)
      conversationMenuStore.handleChangeCollapse(true)

      return
    }

    if (activeBreakpoint === BreakpointsEnum.XS || activeBreakpoint === BreakpointsEnum.S) {
      contactsDetailsGlobalStore.handleChangeCollapse(true)
      conversationMenuStore.handleChangeCollapse(true)

      return
    }
  }, [activeBreakpoint, isOpenConversations])

  useEffect(() => {
    return () => {
      conversationListStore.handleChangeVisibleUI(false)
      conversationSearchStore.clear(false)
      conversationSearchStore.onClose()
    }
  }, [])

  const showConversationsMenuOverlay =
    (activeBreakpoint === BreakpointsEnum.XS || activeBreakpoint === BreakpointsEnum.S) &&
    !conversationMenuStore.isCollapse

  const showOverlay = isShowContactDetailsOverlay || showConversationsMenuOverlay

  return (
    <div className={styles.wrap}>
      <div className={styles.headerSearchWrap}>
        {conversationSearchStore.isOpened && (
          <ConversationHeaderSearch store={conversationSearchStore} />
        )}
        <div className={styles.searchContentWrap}>
          <div
            className={classNames(styles.contentWrapper, {
              [styles.disabledScroll]: showOverlay,
            })}
          >
            <div className={styles.content}>
              <ConversationLayout
                callHistoryStore={callHistoryStore}
                callModalStore={callModalStore}
                conversationMenuStore={conversationMenuStore}
                conversationSearchStore={conversationSearchStore}
                conversationLayoutStore={conversationLayoutStore}
                conversationListStore={conversationListStore}
                contactCreateModalStore={contactCreateModalStore}
              />

              {isShowChats && <ConversationMessages store={conversationMessagesStore} />}
              {isShowCalls && <CallHistory store={callHistoryStore} />}
            </div>
          </div>
          {contactsDetailsStore && <ContactsDetails store={contactsDetailsStore} />}
        </div>
      </div>
    </div>
  )
})

export default Conversations
