// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a7rjudv5nmXWPzIVR2vr{width:100%;overflow-y:auto;overflow-x:hidden;max-height:250px}.fVTZLARDhI83eYJjQb7Q{width:100%;display:flex;align-items:center;justify-content:flex-start;padding:4px}.iryMxGQGmeTNlz4LG3UE{margin-left:8px;margin-right:8px}.OUyWUL4tdHmr2sZaidzF{margin-left:auto}", "",{"version":3,"sources":["webpack://./src/widgets/MembersTypingCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,WAAA,CAGF,sBACE,eAAA,CACA,gBAAA,CAGF,sBACE,gBAAA","sourcesContent":[".wrap {\n  width: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n  max-height: 250px;\n}\n\n.card {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 4px;\n}\n\n.name {\n  margin-left: 8px;\n  margin-right: 8px;\n}\n\n.status {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "a7rjudv5nmXWPzIVR2vr",
	"card": "fVTZLARDhI83eYJjQb7Q",
	"name": "iryMxGQGmeTNlz4LG3UE",
	"status": "OUyWUL4tdHmr2sZaidzF"
};
export default ___CSS_LOADER_EXPORT___;
