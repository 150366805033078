import { useEffect, useRef } from 'react'
import type { ITextFieldMainAction } from 'shared/ui/TextField'

import coreStyles from 'shared/ui/TextField/styles.module.scss'

type ITextFieldHighlight = {
  mode: 'error' | 'warning'
  highlight: boolean
  timeout?: number
}

const DEFAULT_TIMEOUT = 1500

const getCoreClassName = (mode: ITextFieldHighlight['mode']) => {
  switch (mode) {
    case 'error':
      return coreStyles.textFieldLabel_error
    case 'warning':
      return coreStyles.textFieldLabel_warning
    default:
      return '__unknown'
  }
}

export const makeTextFieldHighlight: (action: ITextFieldHighlight) => ITextFieldMainAction = (
  action
) => {
  const useAction: ITextFieldMainAction = (control, children) => {
    const timeout = action.timeout ?? DEFAULT_TIMEOUT
    const timer = useRef(-1)

    useEffect(() => {
      control.clearClassNames()
      window.clearTimeout(timer.current)
    }, [timeout, action.mode])

    useEffect(() => {
      const container = control.containerRef?.current
      if (!container) return

      const cleanup = () => {
        control.clearClassNames()
        window.clearTimeout(timer.current)
      }

      if (!action.highlight) return cleanup()

      const className = getCoreClassName(action.mode)

      control.setClassNames(className)

      timer.current = window.setTimeout(() => control.clearClassNames(), timeout)

      container.addEventListener('focusout', cleanup)

      return () => {
        container.removeEventListener('focusout', cleanup)

        cleanup()
      }
    }, [timeout, action.highlight, action.mode])

    return children
  }

  return useAction
}
