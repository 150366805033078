// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QANwnS387OZ9gs1egcGt{margin-bottom:20px}.QANwnS387OZ9gs1egcGt:last-of-type{margin-bottom:0}.QANwnS387OZ9gs1egcGt .hm9ml1qOqJ6fuckgjU6q{padding:8px}.V47ftnQbjvik3EEL1LgS{margin:0;padding:0;list-style:none}.V47ftnQbjvik3EEL1LgS ul{margin:0;padding:0 0 0 20px;list-style:none}.DhB2ceD77Cfrhy1JvRcT{position:relative}.QANwnS387OZ9gs1egcGt ul .gSrSp3XLLl7FgEIBt15S{border-radius:6px;box-shadow:0 0 0 1.5px var(--interaction-brand-active) inset}.PVMk7mPnofX0vmC3kGaS{position:relative}.PVMk7mPnofX0vmC3kGaS:before{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(255,255,255,.8);z-index:10;content:\"\"}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMenu/ui/ConversationMenuInboxes/MenuSectionTree/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,mCACE,eAAA,CAGF,4CACE,WAAA,CAIJ,sBACE,QAAA,CACA,SAAA,CACA,eAAA,CAEA,yBACE,QAAA,CACA,kBAAA,CACA,eAAA,CAIJ,sBACE,iBAAA,CAIA,+CACE,iBAAA,CACA,4DAAA,CAIJ,sBACE,iBAAA,CAEA,6BACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,qCAAA,CACA,UAAA,CACA,UAAA","sourcesContent":[".wrap {\n  margin-bottom: 20px;\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n\n  .empty {\n    padding: 8px;\n  }\n}\n\n.treeRoot {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n\n  ul {\n    margin: 0;\n    padding: 0 0 0 20px;\n    list-style: none;\n  }\n}\n\n.placeholderContainer {\n  position: relative;\n}\n\n.dropTarget {\n  .wrap ul & {\n    border-radius: 6px;\n    box-shadow: 0 0 0 1.5px var(--interaction-brand-active) inset;\n  }\n}\n\n.draggingSource {\n  position: relative;\n\n  &:before {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(#ffffff, 0.8);\n    z-index: 10;\n    content: \"\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "QANwnS387OZ9gs1egcGt",
	"empty": "hm9ml1qOqJ6fuckgjU6q",
	"treeRoot": "V47ftnQbjvik3EEL1LgS",
	"placeholderContainer": "DhB2ceD77Cfrhy1JvRcT",
	"dropTarget": "gSrSp3XLLl7FgEIBt15S",
	"draggingSource": "PVMk7mPnofX0vmC3kGaS"
};
export default ___CSS_LOADER_EXPORT___;
