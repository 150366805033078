import { useEffect, FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { useOutsideAlerter } from 'shared/hooks'
import { IInboxCombine, inboxesStore, NAME_INBOX } from 'entities/Inbox'
import { MenuCardInbox } from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/MenuCard'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'
import styles from './../MenuCardInboxGroup/styles.module.scss'
import { MenuCardInput } from './../MenuCardInput/MenuCardInput'

type IMenuCardInboxGroupCreateProps = {
  item: IInboxCombine
}

export const MenuCardInboxGroupCreate: FC<IMenuCardInboxGroupCreateProps> = observer(
  ({ item: groupInbox }) => {
    const store = useConversationMenuContext()
    const { handleUpdateTeamsGroupsCreate, loadingCreateGroupTeam, deleteItemTree } = store

    if (groupInbox.type !== 'inbox_group') return null

    const groupInboxes = () => {
      return groupInbox.teamsIds
        .map((teamId) => {
          const inbox = inboxesStore.getItem(teamId)

          if (!inbox) return null
          if (groupInbox.isFavoriteType !== inbox.isFavoriteType) return null

          return inbox
        })
        .filter((item) => item)
    }
    const groupTeams = groupInboxes()
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        deleteItemTree(NAME_INBOX.Create)
      }
    }

    const { ref } = useOutsideAlerter(() => {
      deleteItemTree(NAME_INBOX.Create)
    })

    useEffect(() => {
      window.addEventListener('keyup', onKeyUp)

      return () => {
        window.removeEventListener('keyup', onKeyUp)
      }
    }, [])

    return (
      <div ref={ref} className={classnames(styles.wrap)}>
        <div
          className={classnames(styles.wrapGroup, {
            [styles.disabled]: loadingCreateGroupTeam,
          })}
        >
          <div className={classnames(styles.boxName, styles.boxName__input)}>
            <div className={styles.icon}>
              <IconButton
                icon={groupInbox.icon}
                size={'small'}
                color={'transparent'}
                fontSize={16}
                ariaLabel='MenuCardInbox_create'
              />
            </div>

            <MenuCardInput
              onKeyUp={(e) => {
                const target = e.target as HTMLInputElement

                if (e.key === 'Enter' && target.value.length) {
                  handleUpdateTeamsGroupsCreate(target.value, groupInbox)
                }
              }}
            />
          </div>
        </div>

        <div className={styles.wrapperList}>
          {groupTeams.map((inbox) => {
            if (!inbox) return null

            return <MenuCardInbox key={inbox.id} item={inbox} groupInbox={groupInbox} isCreate />
          })}
        </div>
      </div>
    )
  }
)
