import React, { FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Button, Icon, IconButton, IIconsVector, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export enum EnumAlertBannerVariant {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  InfoLight = 'info-light',
}

export type IAlertBannerProps = {
  variant?: EnumAlertBannerVariant
  title?: string
  action?: {
    text: string
    onAction?: () => void
  }
  Action?: React.FC
  Title?: React.FC
  onClose?: () => void
  className?: string
}

export const AlertBanner: FC<IAlertBannerProps> = observer(
  ({ title, variant, action, onClose, className, Action, Title }) => {
    variant = variant || EnumAlertBannerVariant.Info
    let icon: IIconsVector = 'infoCircle'
    let color = 'var(--content-primary-primary-inverted)'
    title = title || ''

    if (variant === EnumAlertBannerVariant.Info) {
      icon = 'infoCircle'
      color = 'var(--content-primary-primary-inverted)'
    }
    if (variant === EnumAlertBannerVariant.Warning) {
      icon = 'alertTriangleSmall'
      color = 'var(--yellow-100)'
    }
    if (variant === EnumAlertBannerVariant.Error) {
      icon = 'failed'
      color = 'var(--content-primary-primary-inverted)'
    }
    if (variant === EnumAlertBannerVariant.InfoLight) {
      icon = 'infoCircle'
      color = 'var(--content-brand-primary)'
    }

    return (
      <div className={classnames(styles.wrap, styles[`wrap--${variant}`], className)}>
        <div className={styles.box}>
          <div className={styles.icon}>
            <Icon icon={icon} />
          </div>
          <Typography variant={'body-md-medium'} className={styles.label} ariaLabel={'title'}>
            {Title && <Title />}
            {title}
          </Typography>
        </div>
        <div className={classnames(styles.box, styles['box--right'])}>
          {(action || Action) && (
            <div className={styles.actions}>
              {Action && <Action />}
              {action && (
                <Button
                  text={action.text}
                  typeBtn={'outlined'}
                  contained={'secondary'}
                  size={'small'}
                  onClick={() => {
                    action.onAction && action.onAction()
                  }}
                />
              )}
            </div>
          )}

          {onClose && (
            <div className={styles.close}>
              <IconButton
                iconProps={{
                  icon: 'close',
                  fontSize: 16,
                  color: color,
                }}
                variant={'icon'}
                size={'mini'}
                color={'transparent'}
                ariaLabel='AlertBanner_close'
              />
            </div>
          )}
        </div>
      </div>
    )
  }
)
