import { makeAutoObservable } from 'mobx'

import { ChatbotStatus, IChatbotBase } from './type'

export class ChatbotListItem {
  id = 0
  name = ''
  status = ChatbotStatus.Draft
  totalContacts = 0
  fallbackContacts = 0
  completedContacts = 0
  terminatedContacts = 0

  constructor(item: IChatbotBase) {
    this.syncOrigin(item)
    makeAutoObservable(this)
  }

  get isDraft() {
    return this.status === ChatbotStatus.Draft
  }

  get isDisabled() {
    return this.status === ChatbotStatus.Disabled
  }

  get isActive() {
    return this.status === ChatbotStatus.Active
  }

  syncOrigin(item: IChatbotBase) {
    this.id = item.id
    this.name = item.name
    this.status = item.status
    this.totalContacts = item.total_contacts_count
    this.fallbackContacts = item.fallback_contacts_count
    this.completedContacts = item.completed_contacts_count
    this.terminatedContacts = item.terminated_contacts_count
  }
}
