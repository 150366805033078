import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { MoreDropdownProps } from 'shared/ui/Actions/Actions'
import { MessageSignatureStore } from 'widgets/MessageField/ui/MessageSignature'

type IMessageSignatureDropdownItemProps = MoreDropdownProps & {
  store: MessageSignatureStore
}

export const MessageSignatureDropdownItem: FC<IMessageSignatureDropdownItemProps> = observer(
  ({ onCloseMenu, store }) => {
    const { isActiveMessageSignature, handleUpdateMessageSignatureProperties } = store
    const onToggleMessageSignature = () => {
      onCloseMenu && onCloseMenu()
      handleUpdateMessageSignatureProperties({
        is_active: !isActiveMessageSignature,
      })
    }
    return (
      <Button
        typeBtn={'menuItem'}
        text={isActiveMessageSignature ? 'Disable signature' : 'Enable signature'}
        icon={'signature'}
        onClick={onToggleMessageSignature}
      />
    )
  }
)
