import { makeAutoObservable } from 'mobx'
import type {
  IResponseInfusionsoftInfo,
  IResponseInfusionsoftInfoProperties,
} from 'entities/Integrations'
import type { IIntegrationsProperty } from 'entities/Integrations/model/types'
import { IntegrationContactProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationContactProperty'
import { IntegrationCompanyProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationCompanyProperty'
import { IntegrationOpportunityProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationOpportunityProperty'

export class ContactIntegrationInfusionsoftInfo {
  private _properties = new IntegrationContactProperty([])
  private _opportunitiesMap: Map<number, IntegrationOpportunityProperty> = new Map()
  private _companiesMap: Map<number, IntegrationCompanyProperty> = new Map()

  constructor(item: IResponseInfusionsoftInfo | null) {
    if (item) {
      this._properties = new IntegrationContactProperty(this.addProperties(item.properties))
      this.addOpportunities(item.opportunities)
      this.addCompanies(item.company)
    }

    makeAutoObservable(this)
  }

  get companies() {
    return Array.from(this._companiesMap.values())
  }

  get opportunities() {
    return Array.from(this._opportunitiesMap.values())
  }

  get properties() {
    return this._properties
  }

  addProperties = (properties: IResponseInfusionsoftInfoProperties) => {
    return [
      {
        key: 'first_name',
        label: 'First Name',
        value: properties.first_name || '',
      },
      {
        key: 'last_name',
        label: 'Last Name',
        value: properties.last_name || '',
      },
      {
        key: 'email',
        label: 'Email',
        value: properties.email?.[0]?.email || '',
      },
    ]
  }

  addProperty = (item: IIntegrationsProperty) => {
    this._properties.addProperty(item)
  }

  addCompanies = (company?: IResponseInfusionsoftInfo['company']) => {
    if (!company || Array.isArray(company)) return

    const ID = Number(company?.id)
    const id = !isNaN(ID) ? ID : 0

    this._companiesMap.set(
      id,
      new IntegrationCompanyProperty({
        id: {
          value: String(id),
          label: '',
        },
        name: {
          value: company.company_name || '',
          label: '',
        },
      })
    )
  }

  addOpportunities = (opportunities: IResponseInfusionsoftInfo['opportunities']) => {
    const data = Object.keys(opportunities).map((key) => {
      const opportunity = Array.isArray(opportunities) ? null : opportunities[key]
      const firstName = opportunity?.owner?.first_name
      const lastName = opportunity?.owner?.last_name
      const owner = firstName || lastName ? `${firstName || ''} ${lastName || ''}` : null

      return {
        id: key,
        title: opportunity?.title || null,
        stage: opportunity?.stage?.name || null,
        amount: null,
        owner: owner,
      }
    })

    data.forEach((item, index) => {
      const ID = Number(item.id)
      const id = !isNaN(ID) ? ID : index

      this._opportunitiesMap.set(id, new IntegrationOpportunityProperty(item))
    })
  }
}
