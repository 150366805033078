import classNames from 'classnames'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'

import type { IInputCommonProps, IInputTextProps } from '../Input/types'
import type { ITextFieldProps } from '../types'
import { InputText } from '../Input/InputText'

export const useControl = <InputProps extends IInputCommonProps = IInputTextProps>(
  props: ITextFieldProps<InputProps>
) => {
  const InputComponent = props.Input ?? InputText
  const inputProps = (props.InputProps ?? {}) as InputProps

  const inputRef = useRef<HTMLInputElement | null>(null)
  const containerRef = useRef<HTMLLabelElement | null>(null)

  const value = useMemo(() => props.value ?? '', [props.value])
  const error = useMemo(() => props.error ?? '', [props.error])
  const warning = useMemo(() => props.warning ?? '', [props.warning])

  const readOnly = useMemo(() => props.readOnly, [props.readOnly])
  const viewOnly = useMemo(() => props.viewOnly, [props.viewOnly])
  const disabled = useMemo(() => props.disabled, [props.disabled])
  const variant = useMemo(() => props.variant, [props.variant])
  const size = useMemo(() => props.size, [props.size])

  const [actionClassNames, setActionClassNames] = useState<Record<string, string>>({})
  const [actionClasses, setActionClasses] = useState<string>('')

  const control = {
    InputComponent,
    inputProps,

    inputRef,
    containerRef,
    value,
    actionClasses,
    error,
    warning,

    readOnly,
    viewOnly,
    disabled,
    variant,
    size,
  }

  const makeControl = (id: string) => {
    const setClassNames = (classNames: string) =>
      setActionClassNames({
        ...actionClassNames,
        [id]: classNames,
      })

    const clearClassNames = () => {
      delete actionClassNames[id]

      setActionClassNames({ ...actionClassNames })
    }

    const setValue = (value: string) => props.onChange?.(value)

    return {
      inputRef,
      containerRef,
      value,
      setValue,
      setClassNames,
      clearClassNames,
      error,
      warning,
      readOnly,
      viewOnly,
      disabled,
      variant,
      size,
    }
  }

  useLayoutEffect(() => {
    const actionClasses = classNames(Object.values(actionClassNames))

    setActionClasses(actionClasses)
  }, [actionClassNames])

  return { control, makeControl }
}
