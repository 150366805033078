import { FC } from 'react'
import classNames from 'classnames'
import { Checkbox, IDropdownItemProps, Icon, Tooltip } from 'shared/ui'
import styles from './styles.module.scss'

export const DropdownCardCheckbox: FC<IDropdownItemProps> = ({
  item,
  onChange,
  onChangeCheckbox,
}) => {
  const isCardClick = item.isCardClick
  const onCardClick = () => {
    if (isCardClick) {
      onChange?.(item)
    }
  }
  const content = (
    <div className={styles.contentWrapper}>
      <Checkbox
        size={16}
        disabled={item.disabled}
        checked={!!item.activeValue}
        onChangeValue={(value) => {
          !isCardClick && onChange?.(item)
          onChangeCheckbox?.(value, item)
        }}
      />
      {item.iconL && (
        <div
          className={styles.icon}
          style={{ color: (item.customFields?.leftIconColor as string) || 'inherit' }}
        >
          <Icon icon={item.iconL} />
        </div>
      )}
      <div className={styles.checkboxLabel} style={{ width: item.labelWidth }}>
        {item.labelContent || item.label}
      </div>
    </div>
  )

  return (
    <div
      className={classNames(
        styles.checkboxItemContainer,
        item.disabled && styles.disabledCheckBox,
        !item.disabled && isCardClick && styles.pointer
      )}
      onClick={onCardClick}
    >
      {item.tooltipProps ? <Tooltip {...item.tooltipProps}>{content}</Tooltip> : content}
    </div>
  )
}
