import { omitBy, isNull } from 'lodash'
import { makeAutoObservable } from 'mobx'

export type IFilterPayload = {
  key: string
  type: string
  operator?: string | null
  to?: Date | string | null
  from?: Date | string | null
  value?: string | string[] | Date | null
  is_empty?: number | null
}

export class FilterPayload {
  private _to: IFilterPayload['to'] = null
  private _from: IFilterPayload['from'] = null
  private _value: IFilterPayload['value'] = null

  constructor(payload: Partial<IFilterPayload> = {}) {
    this._to = payload.to ?? null
    this._from = payload.from ?? null
    this._value = payload.value ?? null

    makeAutoObservable(this)
  }

  get to() {
    return this._to
  }

  get from() {
    return this._from
  }

  get value() {
    return this._value
  }

  get isEmpty() {
    return (
      this._to === null &&
      this._from === null &&
      (Array.isArray(this._value) ? !this._value.length : this._value === null)
    )
  }

  get payload() {
    return omitBy(
      {
        to: this._to,
        from: this._from,
        value: this._value,
      },
      isNull
    )
  }

  setValue = (value: IFilterPayload['value']) => {
    this._value = value
  }

  clear() {
    this._to = null
    this._from = null
    this._value = null
  }

  update(payload: Partial<IFilterPayload>) {
    this._to = payload.to ?? this._to
    this._from = payload.from ?? this._from
    this._value = payload.value ?? this._value
  }

  updateHard(payload: Partial<IFilterPayload>) {
    if (payload.hasOwnProperty('to')) this._to = payload.to ?? null
    if (payload.hasOwnProperty('from')) this._from = payload.from ?? null
    if (payload.hasOwnProperty('value')) this._value = payload.value ?? null
  }

  populateJson(data: Record<string, unknown>) {
    this._to = (data.to as IFilterPayload['to']) ?? null
    this._from = (data.from as IFilterPayload['from']) ?? null
    this._value = (data.value as IFilterPayload['value']) ?? null
  }
}
