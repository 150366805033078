import { Navigate, Route, Routes } from 'react-router-dom'
import React from 'react'
import { BroadcastRoutesEnum } from 'entities/Broadcast'
import { BroadcastLayout } from 'pages/broadcasts/layout/BroadcastLayout'
import { CreateBroadcast } from 'pages/broadcasts/pages/createBroadcast'
import { DetailBroadcast } from 'pages/broadcasts/pages/detailBroadcast'
import {
  AllBroadcasts,
  OneTimeBroadcasts,
  RecurringBroadcasts,
} from 'pages/broadcasts/pages/broadcasts'
import { BroadcastTableLayout } from 'pages/broadcasts/layout/BroadcastTableLayout'
import { EditBroadcast } from 'pages/broadcasts/pages/editBroadcast'

export const BroadcastRoutes = () => {
  return (
    <Routes>
      <Route element={<BroadcastLayout />}>
        <Route element={<BroadcastTableLayout />}>
          <Route path={BroadcastRoutesEnum.all} element={<AllBroadcasts />} />
          <Route path={`${BroadcastRoutesEnum.oneTime}/*`} element={<OneTimeBroadcasts />} />
          <Route path={BroadcastRoutesEnum.recurring} element={<RecurringBroadcasts />} />
        </Route>
        <Route path={BroadcastRoutesEnum.create} element={<CreateBroadcast />} />
        <Route path={`${BroadcastRoutesEnum.edit}/:id`} element={<EditBroadcast />} />
        <Route
          path={`${BroadcastRoutesEnum.clone}/:id`}
          element={<EditBroadcast isDuplicate={true} />}
        />
        <Route path={`${BroadcastRoutesEnum.view}/:id`} element={<DetailBroadcast />} />
        <Route path={'*'} element={<Navigate to={BroadcastRoutesEnum.all} />} />
      </Route>
    </Routes>
  )
}
