import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { ChatbotExecutionStore } from './ChatbotExecutionStore'

const ChatbotExecutionContext = createContext<ChatbotExecutionStore | null>(null)

export const useChatbotExecutionContext = () => {
  const store = useContext(ChatbotExecutionContext)

  if (store === null)
    throw new Error('You have forgotten to wrap your component with ChatbotExecutionProvider')

  return store
}

export const ChatbotExecutionProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new ChatbotExecutionStore(), [])

  useEffect(() => store.dispose, [])

  return (
    <ChatbotExecutionContext.Provider value={store}>{children}</ChatbotExecutionContext.Provider>
  )
}
