// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XSJyxoJY0rakomxy5v7Q{height:100%;overflow:auto}.XSJyxoJY0rakomxy5v7Q .d5wQXg4n01Tjip0x4K4j{padding:30px 20px 0 20px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/newOrganization/pages/NewOrganizationContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,aAAA,CAEA,4CACI,wBAAA","sourcesContent":[".wrap {\n    height: 100%;\n    overflow: auto;\n    \n    .tabs {\n        padding: 30px 20px 0 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "XSJyxoJY0rakomxy5v7Q",
	"tabs": "d5wQXg4n01Tjip0x4K4j"
};
export default ___CSS_LOADER_EXPORT___;
