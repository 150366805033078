import { makeAutoObservable, runInAction } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import type { OrganizationBusinessProfile, TollFreeProfile } from 'entities/Compliance/model'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import { NumbersStore } from 'pages/settings/pages/compliance/pages/tollFree/pages/Numbers/store/numbersStore'
import { BusinessInfoStore } from 'pages/settings/pages/compliance/pages/tollFree/pages/BusinessInfo/store/businessInfoStore'
import { ContactInfoStore } from 'pages/settings/pages/compliance/pages/tollFree/pages/ContactInfo/store/contactInfoStore'
import { UseCaseStore } from 'pages/settings/pages/compliance/pages/tollFree/pages/UseCase/store/useCaseStore'
import { OptInMethodStore } from 'pages/settings/pages/compliance/pages/tollFree/pages/OptInMethod/store/optInMethodStore'
import { OptInConsentStore } from 'pages/settings/pages/compliance/pages/tollFree/pages/OptInConsent/store/optInConsentStore'
import { ForbiddenMessageStore } from 'pages/settings/pages/compliance/pages/tollFree/pages/ForbiddenMessageTopics/store/forbiddenMessageStore'
import { TollFreeRoutesSteps } from 'pages/settings/pages/compliance/pages/tollFree/route/type'
import { SettingsPath } from 'pages/settings/route/settingsPath'
import { TollFreeNavigationStore } from './tollFreeNavigationStore'

const titleMap: Record<string, string> = {
  [TollFreeRoutesSteps.numbers]: 'Numbers',
  [TollFreeRoutesSteps.businessInfo]: 'Business info',
  [TollFreeRoutesSteps.contactInfo]: 'Contact info',
  [TollFreeRoutesSteps.optInMethod]: 'Opt-in method',
  [TollFreeRoutesSteps.useCase]: 'Use case',
  [TollFreeRoutesSteps.optInOnlineFormContent]: 'Opt-in consent: Online Form',
  [TollFreeRoutesSteps.optInPaperFormContent]: 'Opt-in consent: Paper Form',
  [TollFreeRoutesSteps.optInViaTextContent]: 'Opt-in consent: via Text',
  [TollFreeRoutesSteps.optInQrCodeContent]: 'Opt-in consent: QR Code',
  [TollFreeRoutesSteps.forbiddenMessageTopics]: 'Forbidden message topics',
}

export class TollFreeStepsStore {
  private _tollFreeProfile: TollFreeProfile | null = null
  private readonly _tollFreeNavigationStore: TollFreeNavigationStore
  private readonly _numbersStore: NumbersStore
  private readonly _businessInfoStore: BusinessInfoStore
  private readonly _contactInfoStore: ContactInfoStore
  private readonly _useCaseStore: UseCaseStore
  private readonly _optInMethodStore: OptInMethodStore
  private readonly _optInConsentStore: OptInConsentStore
  private readonly _forbiddenMessageStore: ForbiddenMessageStore
  private _loading = true

  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)
    this._numbersStore = new NumbersStore(this)
    this._businessInfoStore = new BusinessInfoStore(this)
    this._contactInfoStore = new ContactInfoStore(this)
    this._useCaseStore = new UseCaseStore(this)
    this._optInMethodStore = new OptInMethodStore(this)
    this._optInConsentStore = new OptInConsentStore(this)
    this._tollFreeNavigationStore = new TollFreeNavigationStore(this._optInMethodStore)
    this._forbiddenMessageStore = new ForbiddenMessageStore()
  }

  init = async (id?: number) => {
    runInAction(() => {
      this._loading = true
    })

    if (id) this.setTollFreeProfile(id)
    await this._numbersStore.init()
    this.setDataFromBusinessProfileRequest(this._complianceLayerStore.organizationBusinessProfile)

    runInAction(() => {
      this._loading = false
    })
  }

  setTollFreeProfile = (id: number) => {
    const item = this._complianceLayerStore.organizationTollFree?.getItem(id)

    if (item) {
      this._tollFreeProfile = item
      this._tollFreeNavigationStore.setProfileId(id)
    }
  }

  get loading() {
    return this._complianceLayerStore.loading || this._loading
  }

  get numbersStore() {
    return this._numbersStore
  }

  get tollFreeNavigationStore() {
    return this._tollFreeNavigationStore
  }

  get complianceLayerStore() {
    return this._complianceLayerStore
  }

  get stepsStore() {
    return this._complianceLayerStore.stepsStore
  }

  get businessInfoStore() {
    return this._businessInfoStore
  }

  get contactInfoStore() {
    return this._contactInfoStore
  }

  get useCaseStore() {
    return this._useCaseStore
  }

  get optInMethodStore() {
    return this._optInMethodStore
  }

  get optInConsentStore() {
    return this._optInConsentStore
  }

  get forbiddenMessageStore() {
    return this._forbiddenMessageStore
  }

  get stepLoading() {
    return this.complianceLayerStore.stepsStore.stepLoading
  }

  get fieldsError() {
    return this.complianceLayerStore.errorStore.fieldsError
  }

  get fieldsErrorCommonText() {
    return this.complianceLayerStore.errorStore.fieldsErrorCommonText
  }

  getSettingsStep = (path: TollFreeRoutesSteps) => {
    return this._tollFreeNavigationStore.getStepByPath(path)
  }

  getProgressInfoTitle = (pathname: string) => {
    return titleMap[pathname] || ''
  }

  decreaseStep = () => {
    uiStore.changeRoute({
      path: this._tollFreeNavigationStore.getLastUrl,
    })
  }

  deleteErrorValue = (keyName: string) => {
    this.complianceLayerStore.errorStore.deleteErrorValue(keyName)
  }

  setFieldsError = (fieldError: Record<string, string> | null) => {
    this.complianceLayerStore.errorStore.setFieldsError(fieldError)
  }

  setDataFromBusinessProfileRequest = (
    organizationBusinessProfile?: OrganizationBusinessProfile | null
  ) => {
    const businessInformation = organizationBusinessProfile?.businessInformation

    if (businessInformation) {
      this._businessInfoStore.setEinItem({
        city: businessInformation.city,
        ein: businessInformation.registration_number,
        ein_formatted: businessInformation.registration_number,
        name: businessInformation.business_name,
        state: businessInformation.state_id,
        street1: businessInformation.street_address_1,
        street2: businessInformation.street_address_2,
        zip: businessInformation.zip_code,
      })

      this._businessInfoStore.setEinSearch(businessInformation.registration_number)
    }
  }

  closeSetting = () => {
    uiStore.changeRoute({
      path: `/${SettingsPath.compliance.tollFree.root}`,
    })
  }

  dispose = () => {}
}
