import classNames from 'classnames'
import React, { useMemo } from 'react'
import { IAvatarInfo, Icon, Typography } from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { Activity } from 'entities/Activity/model/Activity'
import { AvatarContent } from 'widgets/MessageCard/ui'
import styles from './styles.module.scss'

type IActivityCardProps = {
  activity: Activity
  avatarInfo?: IAvatarInfo
  hideTeam?: boolean
}

export const ActivityCard = ({ activity, avatarInfo, hideTeam }: IActivityCardProps) => {
  const { text, date, isConversationsHistory, teamName } = activity

  const avatarContent = useMemo(() => {
    if (hideTeam) {
      return null
    }

    if (isConversationsHistory) {
      return (
        <div className={styles.avatarWrap}>
          <div className={styles.icon}>
            <Icon icon={'inboxOpen'} />
          </div>
          <Typography variant={'body-sm-regular'} ariaLabel={'name'} className={styles.name}>
            {teamName}
          </Typography>
          <AvatarContent avatarInfo={avatarInfo} />
        </div>
      )
    }

    return null
  }, [avatarInfo, isConversationsHistory, hideTeam])

  return (
    <div className={classNames(styles.wrap, isConversationsHistory && styles.conversationHistory)}>
      {isConversationsHistory && avatarContent}
      <div className={styles.content}>
        <Typography variant={'body-xs-regular'} className={styles.text} ariaLabel={'text'}>
          {text}
        </Typography>
        <Typography variant={'body-xs-regular'} ariaLabel={'dot'}>
          ·
        </Typography>
        <Typography variant={'body-xs-regular'} className={styles.date} ariaLabel={'date'}>
          {uiStore.dayjs(date).format(DayjsFormats.time)}
        </Typography>
      </div>
    </div>
  )
}
