import { makeAutoObservable } from 'mobx'
import {
  type IResponseIntegrationHubspotDeal,
  IResponseIntegrationPipedriveDeal,
  IResponseIntegrationPipedriveProperty,
  IResponseIntegrationsContactsPipedriveInfo,
} from 'entities/Integrations'
import type { IIntegrationsProperty } from 'entities/Integrations/model/types'
import { IntegrationDealProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationDealProperty'
import { IntegrationContactProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationContactProperty'
import { IntegrationOrganizationProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationOrganizationProperty'

export class ContactIntegrationPipedriveInfo {
  private _dealsMap: Map<number, IntegrationDealProperty> = new Map()
  private _properties: IntegrationContactProperty | null = null
  private _organization: IntegrationOrganizationProperty | null = null

  constructor(item: IResponseIntegrationsContactsPipedriveInfo) {
    this.addDeals(item.deal)
    this.addOrganization(item.organization || null)
    this.addProperties(item.properties || null)

    makeAutoObservable(this)
  }

  get properties() {
    return this._properties
  }

  get organization() {
    return this._organization
  }

  get deals() {
    return Array.from(this._dealsMap.values())
  }

  addProperties = (properties: IResponseIntegrationPipedriveProperty | null) => {
    if (!properties) return

    const data = [
      {
        key: 'first_name',
        label: 'First Name',
        value: properties.first_name,
      },
      {
        key: 'last_name',
        label: 'Last Name',
        value: properties.last_name,
      },
      {
        key: 'email',
        label: 'Email',
        value: properties.email,
      },
    ]

    this._properties = new IntegrationContactProperty(data)
  }

  addProperty = (item: IIntegrationsProperty) => {
    this._properties?.addProperty(item)
  }

  addDeals = (deals: IResponseIntegrationPipedriveDeal[]) => {
    const items = deals.map((deal) => {
      return {
        id: {
          value: deal.value,
          label: null,
        },
        dealname: {
          value: deal.title,
          label: null,
        },
        amount: {
          value: deal.value,
          label: null,
        },
        pipeline: {
          value: deal.pipeline.name,
          label: null,
        },
        dealstage: {
          value: deal.stage.name,
          label: null,
        },
        owner: {
          value: deal.owner.name,
          label: null,
        },
      }
    })

    items.forEach((deal) => {
      this.addDeal(deal)
    })
  }

  addDeal = (item: IResponseIntegrationHubspotDeal) => {
    const id = item.id.value || 0

    this._dealsMap.set(id, new IntegrationDealProperty(item))
  }

  addOrganization = (
    organization: IResponseIntegrationsContactsPipedriveInfo['organization'] | null
  ) => {
    if (!organization) return
    if (Array.isArray(organization.owner)) return

    const owner = organization.owner

    if (!owner || !organization.name) return

    this._organization = new IntegrationOrganizationProperty([
      {
        key: 'organization_name',
        label: 'Organization Name',
        value: organization.name,
      },
      {
        key: 'owner_name',
        label: 'Owner',
        value: owner.name,
      },
    ])
  }
}
