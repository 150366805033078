import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { uiStore } from 'shared/store/uiStore'
import { CAMPAIGN_NAME_LIMIT } from 'shared/constants/limits'
import { inboxesStore } from 'entities/Inbox'
import {
  CampaignApi,
  type IFullResponseCampaign,
  type IParamsCreateCampaign,
} from 'entities/Campaign'
import { NameInputStore } from 'features/createOrEdit/NameInput'
import { CampaignBlockerStore } from 'pages/campaigns/ui/CampaignBlocker'
import { CampaignEnrollContactsStore } from 'pages/campaigns/ui/CampaignEnrollContacts'
import { CampaignMessagesStore } from 'pages/campaigns/ui/CampaignMessages'
import { CampaignSettingsStore } from 'pages/campaigns/ui/CampaignSettings'
import { CampaignRoutes } from 'pages/campaigns/types'
import { CampaignEnrolmentStore } from 'pages/campaigns/ui/CampaignEnrolment'

export class CampaignViewStore {
  nameInputStore
  campaignSettingsStore
  campaignBlockerStore
  campaignEnrollContactsStore
  campaignMessagesStore
  constructor(private response?: IFullResponseCampaign, public isDuplicate = false) {
    this.nameInputStore = new NameInputStore({
      focusOnMount: !response || isDuplicate,
      limit: CAMPAIGN_NAME_LIMIT,
    })
    this.campaignSettingsStore = new CampaignSettingsStore()
    this.campaignEnrollContactsStore = new CampaignEnrollContactsStore()
    this.campaignMessagesStore = new CampaignMessagesStore()
    this.campaignBlockerStore = new CampaignBlockerStore(this)
    inboxesStore.fetchInboxes()

    if (response) {
      this.init(response)
    }

    makeAutoObservable(this)
  }

  get params(): IParamsCreateCampaign {
    return {
      name: this.nameInputStore.name,
      filters: this.campaignEnrollContactsStore.paramsFilters,
      settings: this.campaignSettingsStore.params,
      steps: this.campaignMessagesStore.params,
      status: this.isPublish ? 'active' : 'draft',
      default_timezone: uiStore.timezone,
    }
  }

  init = async (response: IFullResponseCampaign) => {
    if (this.isDuplicate) {
      this.nameInputStore.init(`Copy of: ${response.name}`)
    } else {
      this.nameInputStore.init(response.name)
    }

    this.campaignSettingsStore.init(response.settings)
    await Promise.all([
      this.campaignMessagesStore.init(response.steps),
      this.campaignEnrollContactsStore.init(response.filters),
    ])
    this.campaignBlockerStore.updateCash()
  }

  get isEdit() {
    return !!this.response && !this.isDuplicate
  }

  get disabledSave() {
    return this.isEdit && !this.campaignBlockerStore.isExistChanges
  }

  isPublish = false
  onPublish = async () => {
    const isValid = this._checkValidate()
    if (isValid) {
      const data = await this._onSave({ ...this.params, status: 'active' })
      if (!data) {
        return
      }
      runInAction(() => {
        this.isPublish = data.data.status === 'active'
        this.campaignBlockerStore.updateCash()
      })
      const campaignEnrolmentStore = new CampaignEnrolmentStore()
      await campaignEnrolmentStore.init({
        data: {
          name: data.data.name,
          id: data.data.id,
          filters: data.data.filters,
        },
        onNext: () => {
          setTimeout(() => {
            uiStore.changeRoute({
              path: `${CampaignRoutes.root}/${CampaignRoutes.all}`,
            })
          })
        },
      })
    }
  }

  loadingSave = false
  private _onSave = async (params: IParamsCreateCampaign, withError?: boolean) => {
    try {
      this.loadingSave = true
      const { data } =
        this.isEdit && this.response?.id
          ? await CampaignApi.update(this.response?.id, params)
          : await CampaignApi.create(params)

      this.campaignBlockerStore.updateCash()
      return data
    } catch (e) {
      if (e instanceof AxiosError) {
        if (Array.isArray(e.response?.data?.error?.details)) {
          e.response?.data?.error?.details?.forEach((detail: { field: string; error: string }) => {
            if (detail.field === 'name') {
              this.nameInputStore.setError(detail.error)
            }
            // steps.0.payload.message, steps.0.send_from
            if (detail.field.startsWith('steps')) {
              const path = detail.field.split('.')
              const stepIndex = Number(path[1])
              if (!isNaN(stepIndex)) {
                const message = this.campaignMessagesStore.messages[stepIndex]
                if (message) {
                  message.setError(path.slice(2), detail.error)
                }
              }
            }
          })
        }

        if (withError) {
          throw e
        }
      }
    } finally {
      runInAction(() => {
        this.loadingSave = false
      })
    }
  }

  onSave = async () => {
    const isValid = this.isPublish ? this._checkValidate() : this._checkValidateDraft()
    if (isValid) {
      const data = await this._onSave(this.params)
      if (!data) {
        return
      }
      if (!this.isEdit) {
        uiStore.changeRoute({
          path: `${CampaignRoutes.root}/${CampaignRoutes.edit}/${data.data.id}`,
        })
      }
    }
  }

  onSaveDraft = async () => {
    const isValid = this._checkValidateDraft()
    if (isValid) {
      await this._onSave({ ...this.params, status: 'draft' }, true)
    }
  }

  private _checkValidate = () => {
    let validate = true
    if (!this.nameInputStore.name) {
      validate = false
      this.nameInputStore.setError('Please enter campaign name')
    }
    if (this.campaignEnrollContactsStore.isEmpty) {
      validate = false
      this.campaignEnrollContactsStore.setError('Please specify enrollment criteria')
    }
    if (!this.campaignMessagesStore.checkValidate()) {
      validate = false
    }
    return validate
  }
  private _checkValidateDraft = () => {
    let validate = true
    if (!this.nameInputStore.name) {
      validate = false
      this.nameInputStore.setError('Please enter campaign name')
    }
    return validate
  }

  onSettings = () => {
    this.campaignSettingsStore.openModal()
  }
}
