import { makeObservable } from 'mobx'
import { IResponseRecurringChildBroadcast } from 'entities/Broadcast/api/types'
import { BroadcastOneTime } from 'entities/Broadcast/model/BroadcastOneTime'

export class BroadcastRecurringChild extends BroadcastOneTime {
  parentId: number
  recurring_template_settings: IResponseRecurringChildBroadcast['recurring_template_settings']

  constructor(item: IResponseRecurringChildBroadcast, parentId: number) {
    super({ ...item, recurring_template_settings: null })
    this.parentId = parentId
    this.recurring_template_settings = item.recurring_template_settings
    makeObservable(this, {})
  }
}
