import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Alert } from 'shared/ui'
import { useBroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'

export const ScheduleRecurringAlert = observer(() => {
  const { isEditRecurring, isEditRecurringChild } = useBroadcastViewContext()
  const [hidden, setHidden] = useState(false)

  const onClose = () => setHidden(true)
  const description = isEditRecurringChild
    ? 'Updates will apply only for this occurrence'
    : 'Updates will apply for all future occurrences'

  if (!isEditRecurring) return null
  if (hidden) return null

  return (
    <Alert
      marginTop={16}
      item={{
        type: 'infoBlue',
        desc: description,
      }}
      onClose={onClose}
    />
  )
})
