import { ITabItem } from 'shared/ui'

export type IMediaLibraryViewType = 'grid' | 'list'

export type IMediaLibraryType = 'all' | 'images' | 'videos' | 'audio' | 'files'

export const mediaLibraryTabs: ITabItem<IMediaLibraryType>[] = [
  {
    name: 'All',
    key: 'all',
  },
  {
    name: 'Images',
    key: 'images',
  },
  {
    name: 'Videos',
    key: 'videos',
  },
  {
    name: 'Audio',
    key: 'audio',
  },
  {
    name: 'Files',
    key: 'files',
  },
]
