// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XDuct3XzrpH9hFgphme5{display:flex;flex-direction:column;gap:16px}._EGGQhEjUuWpGnmK4gtj{display:grid;grid-template-columns:repeat(7, 1fr);gap:4px}.B0okrzBsRblyh4yCimsm{border:none;outline:none;cursor:pointer;display:flex;padding:8px 0;flex-direction:column;align-items:center;border-radius:6px;background:var(--gray-a-25)}.B0okrzBsRblyh4yCimsm:hover{background:var(--gray-30)}.B0okrzBsRblyh4yCimsm.txr7zuyy8tRp_TKo8k4H{color:var(--content-primary-primary-inverted);background:var(--background-brand-default)}.B0okrzBsRblyh4yCimsm.txr7zuyy8tRp_TKo8k4H:hover{color:var(--content-primary-primary-inverted);background:var(--light-blue-70)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/WeekDaysSelector/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,oCAAA,CACA,OAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,cAAA,CACA,YAAA,CACA,aAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CACA,2BAAA,CACA,4BACE,yBAAA,CAGF,2CACE,6CAAA,CACA,0CAAA,CACA,iDACE,6CAAA,CACA,+BAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.wrapDays{\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  gap: 4px;\n}\n.day{\n  border: none;\n  outline: none;\n  cursor: pointer;\n  display: flex;\n  padding: 8px 0;\n  flex-direction: column;\n  align-items: center;\n  border-radius: 6px;\n  background: var(--gray-a-25);\n  &:hover{\n    background: var(--gray-30);\n  }\n\n  &.active{\n    color: var(--content-primary-primary-inverted);\n    background: var(--background-brand-default);\n    &:hover{\n      color: var(--content-primary-primary-inverted);\n      background: var(--light-blue-70);\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "XDuct3XzrpH9hFgphme5",
	"wrapDays": "_EGGQhEjUuWpGnmK4gtj",
	"day": "B0okrzBsRblyh4yCimsm",
	"active": "txr7zuyy8tRp_TKo8k4H"
};
export default ___CSS_LOADER_EXPORT___;
