// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8_g83GnBkf0wDh8InfrQ{color:var(--content-primary-tertiary);margin-right:-4px}", "",{"version":3,"sources":["webpack://./src/widgets/TextToSpeech/ui/TextToSpeechVoice/styles.module.scss"],"names":[],"mappings":"AAGA,uBACE,qCAAA,CACA,iBAAA","sourcesContent":[".wrap{\n\n}\n.icon{\n  color: var(--content-primary-tertiary);\n  margin-right: -4px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_8_g83GnBkf0wDh8InfrQ"
};
export default ___CSS_LOADER_EXPORT___;
