import { useMemo } from 'react'
import { EnumDropdownItemVariant, IDropdownItem } from 'shared/ui/Dropdown'
import { FilterDropdown } from 'shared/ui'
import { ITableProps } from '../Table'
import { IOrderedGroups } from '../types'

interface ISimpleFilterDropdownProps<TRow> {
  columns: ITableProps<TRow>['columns']
  visibleColumnsIds?: ITableProps<TRow>['visibleColumnsIds']
  setVisibleColumnsIds: ITableProps<TRow>['setVisibleColumnsIds']
  noSearch?: boolean
}

export const SimpleFilterDropdown = <TRow,>({
  columns,
  visibleColumnsIds,
  setVisibleColumnsIds,
  noSearch,
}: ISimpleFilterDropdownProps<TRow>) => {
  const filterDropdownGroups: IOrderedGroups[] = useMemo(() => {
    return [
      {
        label: '',
        items: columns
          .map(
            (column) =>
              !column.isHideForManage && {
                id: column.field,
                label: column.fullName || column.name,
                variant: EnumDropdownItemVariant.Checkbox,
                activeValue:
                  visibleColumnsIds && visibleColumnsIds.includes(column.field as string),
                isCardClick: true,
                disabled: column.isDisabledChangeVisible,
              }
          )
          .filter(Boolean),
      },
    ] as IOrderedGroups[]
  }, [columns, visibleColumnsIds])

  const onChangeItem = (item: IDropdownItem) => {
    if (visibleColumnsIds?.includes(item.id as string)) {
      setVisibleColumnsIds && setVisibleColumnsIds(visibleColumnsIds.filter((id) => id !== item.id))
    } else if (visibleColumnsIds) {
      setVisibleColumnsIds && setVisibleColumnsIds([...visibleColumnsIds, item.id as string])
    }
  }
  return (
    <FilterDropdown
      filterDropdownGroups={filterDropdownGroups}
      changeActiveFields={onChangeItem}
      autoHeightMax={350}
      iconButtonProps={{
        transparentInactive: true,
        ariaLabel: 'SimpleFilterDropdown_manageColumns',
      }}
      placement='bottom-end'
      tooltipLabel='Manage columns'
      noSearch={noSearch}
    />
  )
}
