// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oIrsfGhScm1qDAZzk1_a{background:linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 14.34%);padding-top:40px;padding-bottom:64px;position:sticky;bottom:0;display:grid;grid-template-columns:auto auto 1fr auto;gap:8px}.oIrsfGhScm1qDAZzk1_a.EtwUtnL9yEysrUwIv7Nw{grid-template-columns:1fr}", "",{"version":3,"sources":["webpack://./src/widgets/TriggerView/ui/TriggerActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,0EAAA,CACA,gBAAA,CACA,mBAAA,CACA,eAAA,CACA,QAAA,CACA,YAAA,CACA,wCAAA,CACA,OAAA,CACA,2CACE,yBAAA","sourcesContent":[".wrap {\n  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 14.34%);\n  padding-top: 40px;\n  padding-bottom: 64px;\n  position: sticky;\n  bottom: 0;\n  display: grid;\n  grid-template-columns: auto auto 1fr auto;\n  gap: 8px;\n  &.isTest {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "oIrsfGhScm1qDAZzk1_a",
	"isTest": "EtwUtnL9yEysrUwIv7Nw"
};
export default ___CSS_LOADER_EXPORT___;
