import React from 'react'
import ReactDOM from 'react-dom'
import { ZIndex } from 'shared/constants/zIndex'
import { getAriaLabel } from 'shared/lib/ariaLabel'

const modalRoot = document.getElementById('portal')

type IPortalProps = {
  children: React.ReactNode
  zIndex?: ZIndex
}

export class Portal extends React.Component<IPortalProps> {
  el
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  constructor(props) {
    super(props)
    // Create a div that we'll render the modal into. Because each
    // Modal component has its own element, we can render multiple
    // modal components into the modal container.
    this.el = document.createElement('div')
    this.el.setAttribute('aria-label', getAriaLabel('Portal'))
  }
  componentDidMount() {
    // Append the element into the DOM on mount. We'll render
    // into the modal container element (see the HTML tab).
    if (modalRoot) {
      if (this.el.children[0]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.el.children[0].style.zIndex = (
          (this.props.zIndex || ZIndex.OVERLAY_BASE) + modalRoot.children.length
        ).toString()
      }
      modalRoot.appendChild(this.el)
    }
  }

  componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    if (modalRoot) {
      modalRoot.removeChild(this.el)
    }
  }

  render() {
    // Use a portal to render the children into the element

    return ReactDOM.createPortal(
      // Any valid React child: JSX, strings, arrays, etc.
      this.props.children,
      // A DOM element
      this.el
    )
  }
}
