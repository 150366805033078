import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { logger } from 'shared/lib'
import { ZIndex } from 'shared/constants/zIndex'
import {
  IQuickLinkTemplate,
  QuickLinkPayload,
  QuickLinkApi,
  QuickLinkTemplate,
} from 'entities/QuickLink'
import { ComplianceApi } from 'entities/Compliance'
import { QuickLinkModal } from 'pages/settings/pages/quickLinks/ui/QuickLinkModal'

const ERROR_MESSAGE = 'The URL you entered could not be reached.'
export class QuickLinkOperationStore {
  private _createQuickLinkModalId = 'createQuickLinkTemplateModalId'
  private _editQuickLinkModalId = 'editQuickLinkTemplateModalId'
  private _deleteQuickLinkModalId = 'deleteQuickLinkTemplateModalId'

  constructor() {
    makeAutoObservable(this)
  }

  create = (): Promise<IQuickLinkTemplate | null> =>
    new Promise((resolve) => {
      const payload = new QuickLinkPayload()

      const onClose = () => {
        modalStore.removeModal(this._createQuickLinkModalId)
        resolve(null)
      }

      const onComplete = async () => {
        if (!payload.mergeFieldsCount) {
          const isReachable = await this.checkUrl(payload)

          if (!isReachable) return
        }
        try {
          const { data } = await QuickLinkApi.createLinkTemplate(payload.toJSON())

          modalStore.removeModal(this._createQuickLinkModalId)

          return resolve(new QuickLinkTemplate(data))
        } catch (error) {
          logger.error(error)
        }
      }

      modalStore.addModal({
        id: this._createQuickLinkModalId,
        title: 'Add link',
        pureContent: true,
        ModalContent: QuickLinkModal,
        zIndex: ZIndex.OVERLAY_MIDDLE,
        ModalContentProps: { completeText: 'Add', payload, onComplete, onClose },
        width: 480,
        disabledOverlayClose: true,
        onClose,
      })
    })

  edit = (template: IQuickLinkTemplate): Promise<IQuickLinkTemplate | null> =>
    new Promise((resolve) => {
      const payload = template.payload

      const onClose = () => {
        modalStore.removeModal(this._editQuickLinkModalId)
        resolve(null)
      }

      const onComplete = async () => {
        if (!payload.mergeFieldsCount) {
          const isReachable = await this.checkUrl(payload)

          if (!isReachable) return
        }
        try {
          const { data } = await QuickLinkApi.updateLinkTemplate(template.id, payload.toJSON())
          template.syncOrigin(data)

          modalStore.removeModal(this._editQuickLinkModalId)

          return resolve(template)
        } catch (error) {
          logger.error(error)
        }
      }

      modalStore.addModal({
        id: this._editQuickLinkModalId,
        title: 'Edit link',
        pureContent: true,
        ModalContent: QuickLinkModal,
        zIndex: ZIndex.OVERLAY_MIDDLE,
        ModalContentProps: { completeText: 'Update', payload, onComplete, onClose },
        width: 480,
        disabledOverlayClose: true,
        onClose,
      })
    })

  delete = (id: number): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      const handleDelete = () => {
        modalStore.removeModal(this._deleteQuickLinkModalId)
        QuickLinkApi.deleteLinkTemplate(id).then(
          () => resolve(true),
          () => resolve(false)
        )
      }

      const handleCancel = () => {
        modalStore.removeModal(this._deleteQuickLinkModalId)
        resolve(false)
      }

      modalStore.addModal({
        id: this._deleteQuickLinkModalId,
        showHeader: true,
        showCloseButton: false,
        showCloseIcon: true,
        width: 280,
        type: ModalTypeList.ALERT,
        zIndex: ZIndex.OVERLAY_MIDDLE,
        title: 'Delete link?',
        desc: 'This action cannot be undone',
        primaryAction: {
          text: 'Delete',
          onAction: handleDelete,
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: handleCancel,
        },
        onClose: handleCancel,
      })
    })

  dispose = () => {
    modalStore.removeModal(this._deleteQuickLinkModalId)
    modalStore.removeModal(this._editQuickLinkModalId)
    modalStore.removeModal(this._createQuickLinkModalId)
  }

  checkSingleUrl = async (url: string) => {
    try {
      const { data } = await ComplianceApi.onlineFormUrlCheck({ onlineFormUrl: url })
      return { url: url, reachable: data.reachable }
    } catch (error) {
      logger.error(error)
      return { url: url, reachable: false }
    }
  }

  checkUrl = async (payload: QuickLinkPayload) => {
    const isBaseUrlHasProtocol =
      payload.url.startsWith('http://') || payload.url.startsWith('https://')

    try {
      if (isBaseUrlHasProtocol) {
        const result = await this.checkSingleUrl(payload.url)
        if (result.reachable) {
          return true
        }
      } else {
        const httpUrl = `http://${payload.url}`
        const httpsUrl = `https://${payload.url}`

        const [httpsResult, httpResult] = await Promise.allSettled([
          this.checkSingleUrl(httpsUrl),
          this.checkSingleUrl(httpUrl),
        ])

        const httpsReachable = httpsResult.status === 'fulfilled' && httpsResult.value.reachable
        const httpReachable = httpResult.status === 'fulfilled' && httpResult.value.reachable

        if (httpsReachable) {
          payload.setUrl(httpsUrl)
          return true
        } else if (httpReachable) {
          payload.setUrl(httpUrl)
          return true
        }
      }

      payload.setErrorUrlMessage(ERROR_MESSAGE)
      return false
    } catch (error) {
      logger.error(error)
    }
  }
}
