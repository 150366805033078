// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EKBHhGb63tpvXb7wgvzL{display:flex;align-items:center;justify-content:flex-start;position:relative}.EKBHhGb63tpvXb7wgvzL .AVkb8Q6OyhERYHNOBOhV{position:absolute;left:-19px;opacity:0}.EKBHhGb63tpvXb7wgvzL:hover .AVkb8Q6OyhERYHNOBOhV{opacity:1}.EKBHhGb63tpvXb7wgvzL:hover.sq0R5h43iF9G1MSUo9mg .AVkb8Q6OyhERYHNOBOhV{opacity:0}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/ui/DraggingItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,iBAAA,CAEA,4CACE,iBAAA,CACA,UAAA,CACA,SAAA,CAIA,kDACE,SAAA,CAIA,uEACE,SAAA","sourcesContent":[".iconDotsDrag {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  position: relative;\n\n  .icon {\n    position: absolute;\n    left: -19px;\n    opacity: 0;\n  }\n\n  &:hover {\n    .icon {\n      opacity: 1;\n    }\n\n    &.hideDraggingIcon {\n      .icon {\n        opacity: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconDotsDrag": "EKBHhGb63tpvXb7wgvzL",
	"icon": "AVkb8Q6OyhERYHNOBOhV",
	"hideDraggingIcon": "sq0R5h43iF9G1MSUo9mg"
};
export default ___CSS_LOADER_EXPORT___;
