import { makeAutoObservable } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { Contact } from 'entities/Contacts/model/Contact'

import { ChatbotConversationStatus, IChatbotAnalyticBase, IChatbotConversationStatus } from './type'

const TIME_FORMAT = 'MMM D, YYYY - h:mm A'

export class ChatbotAnalyticListItem {
  id!: number
  targetTime!: string
  createdTime!: string
  contact: Contact | null = null
  status!: IChatbotConversationStatus

  constructor(item: IChatbotAnalyticBase) {
    this.syncOrigin(item)
    makeAutoObservable(this)
  }

  syncOrigin(item: IChatbotAnalyticBase) {
    this.id = item.id
    this.createdTime = uiStore.dayjs(item.created_at).format(TIME_FORMAT)
    this.targetTime = uiStore.dayjs(this._getTargetTime(item)).format(TIME_FORMAT)
    this.contact = item.contact ? new Contact(item.contact) : null
    this.status = item.status
  }

  private _getTargetTime(item: IChatbotAnalyticBase) {
    switch (item.status) {
      case ChatbotConversationStatus.Terminated:
      case ChatbotConversationStatus.InProgress:
        return item.created_at
      case ChatbotConversationStatus.Abandoned:
        return item.fallback_at
      case ChatbotConversationStatus.Completed:
        return item.success_at
    }
  }
}
