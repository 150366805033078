import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { TitledRow } from 'shared/ui/TitledRow/TitledRow'
import { type IntegrationSalesforceSettingsSyncStore } from 'pages/settings/pages/integrations/pages/integrationSettings/IntegrationSalesforceSettings/store/IntegrationSalesforceSettingsSyncStore'
import { IntegrationSyncDropdown } from 'pages/settings/pages/integrations/pages/integrationSettings/ui/IntegrationSyncDropdown/IntegrationSyncDropdown'
import styles from './styles.module.scss'

type IIntegrationSalesforceSettingsSyncProps = {
  store: IntegrationSalesforceSettingsSyncStore
}

export const IntegrationSalesforceSettingsSync: React.FC<IIntegrationSalesforceSettingsSyncProps> =
  observer(({ store }) => {
    const {
      showOwnerAssigmentCheckbox,
      isTwoWaySync,
      setSyncType,
      syncType,
      setCheckbox,
      getCheckbox,
      selectItems,
      getValue,
      setValue,
      getSelectItems,
    } = store

    return (
      <div className={styles.root}>
        <IntegrationSyncDropdown
          integrationIcon={'IntegrationSalesforce'}
          iconSize={68}
          isTwoWaySync={isTwoWaySync}
          syncType={syncType}
          setSyncType={setSyncType}
          selectItems={selectItems}
        />
        {showOwnerAssigmentCheckbox && (
          <TitledRow
            title={'Salesforce Owner Assignment'}
            label={'Automatically reassign conversations when the owner updates in Salesforce.'}
            verticalAlign={'start'}
          >
            <Switch
              size='middle'
              value={getCheckbox('conversation_assignment_enabled')}
              onChange={setCheckbox('conversation_assignment_enabled')}
            />
          </TitledRow>
        )}
        {isTwoWaySync && (
          <>
            <TitledRow
              title={'Contact property sync'}
              label={'If contact is updated in Salesmsg, then update contact in Salesforce'}
              verticalAlign={'start'}
            >
              <Switch
                size='middle'
                value={getCheckbox('update_contacts')}
                onChange={setCheckbox('update_contacts')}
              />
            </TitledRow>
            <TitledRow
              title={'Contact creation'}
              label={
                "If contact doesn't exist in Salesforce, then create a new contact if contact is added to Salesmsg"
              }
              verticalAlign={'start'}
            >
              <Switch
                size='middle'
                value={getCheckbox('create_non_existing_contacts')}
                onChange={setCheckbox('create_non_existing_contacts')}
              />
            </TitledRow>
            <TitledRow
              title={'Create new Salesforce record as'}
              label={'Select the Salesforce object you want to sync with'}
              align={'normal'}
            >
              <SelectField
                size={'medium'}
                withSearch={false}
                items={getSelectItems('new_record_type')}
                value={getValue('new_record_type')}
                setValue={setValue('new_record_type')}
                selectProps={{
                  placeholder: getValue('new_record_type') as string,
                }}
              />
            </TitledRow>
            <TitledRow
              title={'Salesforce default account'}
              label={'Select a default Salesforce account to map new contacts to'}
              align={'normal'}
            >
              <SelectField
                disabled={getValue('new_record_type') !== 'contact'}
                size={'medium'}
                withSearch={false}
                items={getSelectItems('default_contact_account')}
                value={getValue('default_contact_account')}
                setValue={setValue('default_contact_account')}
                selectProps={{
                  placeholder: getValue('default_contact_account') as string,
                }}
              />
            </TitledRow>
          </>
        )}
      </div>
    )
  })
