// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MXVuB1mTdtAaxt4bDb2H{display:flex;align-items:center}.A1WEOdGWYCVje38QFKmu .CJ0D7z76vqWrt1HAr5Yj{margin-left:4px}.A1WEOdGWYCVje38QFKmu .xzVrHzxk97OdI4eitHGg{margin-left:6px}.xzVrHzxk97OdI4eitHGg{color:var(--content-primary-primary);max-width:220px;margin-left:8px}.ZeUcIX9iPd3uhbQoJ4_H{color:var(--content-neutral-primary);margin-left:8px}.FRGPZKOLUXC3JJhHkkiS{background:var(--background-brand-subtle-primary);color:var(--content-primary-tertiary)}.uia93nOb64n8GtOgTf9b{color:var(--content-primary-primary)}", "",{"version":3,"sources":["webpack://./src/entities/Users/ui/UserDropdownCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGE,4CACE,eAAA,CAGF,4CACE,eAAA,CAON,sBACE,oCAAA,CACA,eAAA,CACA,eAAA,CAGF,sBACE,oCAAA,CACA,eAAA,CAKF,sBACE,iDAAA,CACA,qCAAA,CAEA,sBACE,oCAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n\n  &--checkbox {\n    .icon {\n      margin-left: 4px;\n    }\n\n    .title {\n      margin-left: 6px;\n    }\n  }\n}\n\n.icon {}\n\n.title {\n  color: var(--content-primary-primary);\n  max-width: 220px;\n  margin-left: 8px;\n}\n\n.count {\n  color: var(--content-neutral-primary);\n  margin-left: 8px;\n}\n\n.checkbox {}\n\n.highlighter {\n  background: var(--background-brand-subtle-primary);\n  color: var(--content-primary-tertiary);\n\n  &--label {\n    color: var(--content-primary-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "MXVuB1mTdtAaxt4bDb2H",
	"wrap--checkbox": "A1WEOdGWYCVje38QFKmu",
	"icon": "CJ0D7z76vqWrt1HAr5Yj",
	"title": "xzVrHzxk97OdI4eitHGg",
	"count": "ZeUcIX9iPd3uhbQoJ4_H",
	"highlighter": "FRGPZKOLUXC3JJhHkkiS",
	"highlighter--label": "uia93nOb64n8GtOgTf9b"
};
export default ___CSS_LOADER_EXPORT___;
