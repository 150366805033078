import { useEffect, KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Button, Dropdown } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { uiStore } from 'shared/store/uiStore'
import { DropdownAllSearch } from 'widgets/SearchDropdown/ui/DropdownAllSearch/DropdownAllSearch'
import { SearchDropdown } from 'widgets/SearchDropdown/ui'
import { useConversationSearchContext } from 'widgets/ConversationHeaderSearch/context'
import styles from './styles.module.scss'

export const ConversationHeaderSearchContent = observer(() => {
  const { reset, hasSelectedSearch, handleSelectedSearch, searchDropdownStore, handleSearch } =
    useConversationSearchContext()
  const { actionSearch, search } = searchDropdownStore
  const { startFocusingMode, endFocusingMode } = uiStore
  const wrapperId = 'react__conversation-header-search__wrapper'

  const onKeydownSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSelectedSearch(search)
    }
  }

  useEffect(() => uiStore.endFocusingMode, [])

  return (
    <ClickAwayListener onClickAway={endFocusingMode}>
      <div className={styles.wrap} id={wrapperId} onClick={startFocusingMode}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInputWrap}>
            <SearchDropdown store={searchDropdownStore} />
            <Dropdown
              ariaLabel={'ConversationHeaderSearch'}
              show={search.length > 2}
              triggerComponent={() => (
                <TextField
                  className={styles.textField}
                  variant='integrated'
                  InputProps={{
                    onKeyDown: onKeydownSearch,
                    placeholder: 'Search conversation',
                    onFocus: startFocusingMode,
                    onBlur: endFocusingMode,
                  }}
                  onChange={(value) => {
                    actionSearch({
                      value: value,
                    })
                    handleSearch(value)
                  }}
                  value={search}
                  mainActions={[makeTextFieldAutoFocus({ withFocus: !search })]}
                />
              )}
              placement={'bottom-start'}
              customComponent={(onCloseMenu) => {
                if (search.length < 3 || hasSelectedSearch) return <></>

                return <DropdownAllSearch store={searchDropdownStore} onCloseMenu={onCloseMenu} />
              }}
            />
          </div>

          <Button
            contained={'secondary'}
            tooltipProps={{
              label: 'Cancel',
              disableInteractive: true,
            }}
            typeBtn={'text'}
            text={'Cancel'}
            onClick={reset}
          />
        </div>
      </div>
    </ClickAwayListener>
  )
})
