import { Navigate, Route, Routes } from 'react-router-dom'
import { ContactsCleanupContent } from 'widgets/ContactsTable'
import { ContactsHistory } from 'widgets/ContactsHistory'
import { contactsPageStore } from 'pages/contacts'
import { Contacts } from '../ui/contacts'
import { ContactsLayout } from '../layout/ContactsLayout'
import { ContactRouts } from './types'

export const ContactsRouters = () => {
  const { contactsMenuStore, contactsTableStore, segmentModalStore } = contactsPageStore

  return (
    <Routes>
      <Route element={<ContactsLayout contactsMenuStore={contactsMenuStore} />}>
        <Route
          path={'/'}
          element={
            <Contacts
              contactsMenuStore={contactsMenuStore}
              contactsTableStore={contactsTableStore}
              segmentModalStore={segmentModalStore}
            />
          }
        />
        <Route
          path={'/:id'}
          element={
            <Contacts
              contactsMenuStore={contactsMenuStore}
              contactsTableStore={contactsTableStore}
              segmentModalStore={segmentModalStore}
            />
          }
        />
        <Route
          path={`${ContactRouts.ALL}`}
          element={
            <Contacts
              contactsMenuStore={contactsMenuStore}
              contactsTableStore={contactsTableStore}
              segmentModalStore={segmentModalStore}
            />
          }
        />
        <Route
          path={`${ContactRouts.HISTORY}`}
          element={<ContactsHistory contactsMenuStore={contactsMenuStore} />}
        />
        <Route
          path={`${ContactRouts.CLEANUP}`}
          element={<ContactsCleanupContent contactsTableStore={contactsTableStore} />}
        />
        {/* ContactRouts.DUPLICATE and ContactRouts.UNLINKED are the old routs for cleanup in 1.0 */}
        <Route
          path={`${ContactRouts.DUPLICATE}`}
          element={<Navigate to={`/${ContactRouts.CONTACTS}/${ContactRouts.CLEANUP}`} />}
        />
        <Route
          path={`${ContactRouts.UNLINKED}`}
          element={<Navigate to={`/${ContactRouts.CONTACTS}/${ContactRouts.CLEANUP}`} />}
        />
        <Route
          path={'*'}
          element={<Navigate to={`/${ContactRouts.CONTACTS}/${ContactRouts.ALL}`} />}
        />
      </Route>
    </Routes>
  )
}
