// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oAT__UXPwR3bu3mx4cUH .NWg6UYfUgNHV5bWQ9eMt{height:44px;font-size:14;font-weight:500;margin-top:40px}.oAT__UXPwR3bu3mx4cUH .i_jmBMI3W7Idbqifzhv7{width:100%}.oAT__UXPwR3bu3mx4cUH .S56xnR3YPT6NlAEkrxAe{padding:12px;font-weight:400;font-size:14px;line-height:20px}.oAT__UXPwR3bu3mx4cUH .S56xnR3YPT6NlAEkrxAe.FCVVZ2eQ2MqZGj8gThxa,.oAT__UXPwR3bu3mx4cUH .S56xnR3YPT6NlAEkrxAe.jZNrXqNC4AeZF9J134ej{margin-bottom:4px}.oAT__UXPwR3bu3mx4cUH .S56xnR3YPT6NlAEkrxAe.Z2MrvtdtoowLKR0jKKwN{cursor:auto}.oAT__UXPwR3bu3mx4cUH .ePUUaq3dOgULLwwu0J5O{margin-top:20px;margin-bottom:4px}.oAT__UXPwR3bu3mx4cUH .GFsflc02qpLUJBtSg6MS{display:flex;align-items:center;gap:4px}.oAT__UXPwR3bu3mx4cUH .tGPBoSisVa5QzSWNmE2g{padding-top:12px;text-align:center;color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/widgets/Register/ui/Step4/ui/Step4Form/styles.module.scss"],"names":[],"mappings":"AACE,4CACE,WAAA,CACA,YAAA,CACA,eAAA,CACA,eAAA,CAGF,4CACE,UAAA,CAGF,4CACE,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,kIAEE,iBAAA,CAGF,iEACE,WAAA,CAIJ,4CACE,eAAA,CACA,iBAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,4CACE,gBAAA,CACA,iBAAA,CACA,qCAAA","sourcesContent":[".wrap {\n  .button {\n    height: 44px;\n    font-size: 14;\n    font-weight: 500;\n    margin-top: 40px;\n  }\n\n  .fieldWrap {\n    width: 100%;\n  }\n\n  .textField {\n    padding: 12px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.warning,\n    &.error {\n      margin-bottom: 4px;\n    }\n\n    &.disabled {\n      cursor: auto;\n    }\n  }\n\n  .inputLabel {\n    margin-top: 20px;\n    margin-bottom: 4px;\n  }\n\n  .cardRightLabel {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n  }\n\n  .trialText {\n    padding-top: 12px;\n    text-align: center;\n    color: var(--content-primary-tertiary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "oAT__UXPwR3bu3mx4cUH",
	"button": "NWg6UYfUgNHV5bWQ9eMt",
	"fieldWrap": "i_jmBMI3W7Idbqifzhv7",
	"textField": "S56xnR3YPT6NlAEkrxAe",
	"warning": "FCVVZ2eQ2MqZGj8gThxa",
	"error": "jZNrXqNC4AeZF9J134ej",
	"disabled": "Z2MrvtdtoowLKR0jKKwN",
	"inputLabel": "ePUUaq3dOgULLwwu0J5O",
	"cardRightLabel": "GFsflc02qpLUJBtSg6MS",
	"trialText": "tGPBoSisVa5QzSWNmE2g"
};
export default ___CSS_LOADER_EXPORT___;
