import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { RouterProvider } from 'react-router-dom'
import { errorLogger } from 'entities/EventLog'
import { ErrorBoundaryFallback } from 'app/layouts/ErrorBoundaryFallback'
import { appRouterSidebar } from 'app/appvue/sidebar/router/router'

window.initSidebar = () => {
  const sidebar = createRoot(document.getElementById('sidebar') as HTMLElement)

  if (sidebar) {
    window.resetSidebar = () => {
      sidebar.unmount()
    }

    sidebar.render(
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorLogger}>
        <RouterProvider router={appRouterSidebar} />
      </ErrorBoundary>
    )
  }
}
