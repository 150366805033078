import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import classNames from 'classnames'
import { InputCheckbox, Typography } from 'shared/ui'
import { useComplianceSettingsContext } from '../context/complianceSettingsContext'
import { ContactForm } from './ContactForm'

import styles from './styles.module.scss'

export const PeopleToContact = observer(() => {
  const {
    peopleToContactStore: {
      primaryContact,
      secondaryContact,
      jobPositionList,
      isSecondContact,
      contactsConfirmed,
      phoneInputStore,
      emailError,
      emailError2,
      secondPhoneInputStore,
      checkIsEmail,
      setPrimaryFirstName,
      setPrimaryLastName,
      setPrimaryBusinessTitle,
      setPrimaryEmail,
      setPrimaryJobPosition,
      setSecondaryFirstName,
      setSecondaryLastName,
      setSecondaryBusinessTitle,
      setSecondaryEmail,
      setSecondaryJobPosition,
      toggleContactsConfirmed,
      toggleSecondContact,
      loadBusinessProfileJobPositions,
    },
  } = useComplianceSettingsContext()

  useEffect(() => {
    loadBusinessProfileJobPositions()
  }, [])

  return (
    <Box display='flex' flexDirection='column'>
      <Typography variant='body-md-regular' ariaLabel='PeopleToContact'>
        We will contact your authorized representative to verify your identities. Please ensure that
        they are contactable via email and phone.
      </Typography>

      <Typography
        ariaLabel='AuthorizedUser_1_line'
        variant='body-lg-medium'
        marginBottom={24}
        marginTop={40}
      >
        Authorized user #1
      </Typography>

      <ContactForm
        errorMessage={emailError}
        contact={primaryContact}
        jobPositionList={jobPositionList}
        phoneInputStore={phoneInputStore}
        setFirstName={setPrimaryFirstName}
        setLastName={setPrimaryLastName}
        setBusinessTitle={setPrimaryBusinessTitle}
        setJobPosition={setPrimaryJobPosition}
        setEmail={setPrimaryEmail}
        checkIsEmail={() => checkIsEmail(true)}
      />

      <InputCheckbox
        checked={isSecondContact}
        onChecked={toggleSecondContact}
        color='var(--content-primary-tertiary)'
        label='I have a second authorized representative'
        variant='text-input-field-r'
        className={styles.secondContactCheckbox}
      />

      {isSecondContact && (
        <>
          <Typography
            ariaLabel='AuthorizedUser_2_line'
            variant='body-lg-medium'
            marginBottom={24}
            marginTop={40}
          >
            Authorized user #2
          </Typography>
          <ContactForm
            errorMessage={emailError2}
            contact={secondaryContact}
            jobPositionList={jobPositionList}
            phoneInputStore={secondPhoneInputStore}
            setFirstName={setSecondaryFirstName}
            setLastName={setSecondaryLastName}
            setBusinessTitle={setSecondaryBusinessTitle}
            setJobPosition={setSecondaryJobPosition}
            setEmail={setSecondaryEmail}
            checkIsEmail={() => checkIsEmail(false)}
          />
        </>
      )}

      <InputCheckbox
        className={classNames({ [styles.confirmed]: isSecondContact })}
        checked={contactsConfirmed}
        onChecked={toggleContactsConfirmed}
        variant='text-input-field-r'
        color='var(--content-primary-tertiary)'
        label='I confirm that my nominated authorized representative agree to be contacted by Salesmsg'
      />
    </Box>
  )
})
