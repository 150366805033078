export enum BroadcastRoutesEnum {
  broadcasts = 'broadcasts',
  create = 'create',
  edit = 'edit',
  clone = 'clone',
  view = 'view',
  all = 'all',
  oneTime = 'one-time',
  sms = 'sms',
  ringless = 'ringless',
  recurring = 'recurring',
}
