import { makeAutoObservable } from 'mobx'
import { IActionsProps, IAvatarInfo, IIconsVector } from 'shared/ui'
import type {
  IAreaCode,
  IContactPhone,
  INote,
  IPhones,
  IResponseCustomField,
  IResponseNote,
} from 'entities/Contacts'
import {
  IResponseContact,
  IResponseContactActivecampaignIntegration,
  IResponseContactHubspotIntegration,
  IResponseContactInfusionsoftIntegration,
  IResponseContactIntegration,
  IResponseContactPipedriveIntegration,
  IResponseContactSalesforceIntegration,
  IResponseOptInRequests,
  IResponseShortcodeOptOut,
  IShortCodeOptOut,
} from 'entities/Contacts/api/types'
import { numbersStore } from 'entities/Phone'
import { inboxesStore } from 'entities/Inbox'
import { Tag } from 'entities/Tags/model/Tag'
import { conversationStore } from 'entities/Conversation'
import { IResponseConversationDraftMessageContact } from 'entities/Conversation/api/types'

export class Contact {
  id: number
  first_name: string
  last_name: string
  full_name: string
  number?: string
  formatted_number?: string
  photo_url: string
  color: string
  email: string
  timezone?: string
  hubspot?: IResponseContactHubspotIntegration
  activecampaign?: IResponseContactActivecampaignIntegration
  infusionsoft?: IResponseContactInfusionsoftIntegration
  pipedrive?: IResponseContactPipedriveIntegration
  salesforce?: IResponseContactSalesforceIntegration
  phone?: string | null
  is_blocked?: boolean
  phones?: string[] | IPhones
  custom_fields: IResponseCustomField[]
  national_number?: string
  mobile_phone?: string | null
  custom_phone?: string | null
  conversationId: number | null = null
  icon?: IIconsVector
  notes: INote[]
  tags: Tag[]
  integration_type?: string
  integration_key?: string
  integration_vendor_id: string
  integration_vendor_type: string | null
  created_at: string
  area_code_info: IAreaCode | null
  updated_at: string
  is_invalid: boolean
  lookup_type: string | null
  carrier_name: string | null
  opt_out: string | null
  opt_out_at: string | null
  opt_out_at_shortcode: string | null
  opt_in_at: string
  opt_in_request_at: string
  shortcode_opt_outs: IResponseShortcodeOptOut[]
  last_messaged_on: string | null
  last_message_sent_on: string | null
  last_message_received_on: string | null
  last_called_on: string | null
  last_contacted_on: string | null
  message_status: string | null
  opt_in: string | null
  keywords: IResponseContact['keywords'] | []
  total_received_sms: string | null
  total_received_call: string | null
  origin: IResponseContact
  source: string
  opt_in_requests: IResponseOptInRequests[] = []
  country_calling_code: string
  owner_id: number | null
  prevent_autolink: boolean
  isDisabled = false
  ariaLabel: string | undefined
  actionsProps?: IActionsProps | null = null

  constructor(item: IResponseContact) {
    this.id = item.id
    this.first_name = item.first_name
    this.last_name = item.last_name
    this.full_name = item.full_name
    this.photo_url = item.photo_url
    this.color = item.color ? item.color : this.generateColor
    this.number = item.number
    this.formatted_number = item.formatted_number
    this.email = item.email || ''
    this.integration_vendor_id = item.integration_vendor_id
    this.integration_vendor_type = item.integration_vendor_type || null
    this.hubspot = item.hubspot
    this.activecampaign = item.activecampaign
    this.infusionsoft = item.infusionsoft
    this.pipedrive = item.pipedrive
    this.salesforce = item.salesforce
    this.national_number = item.national_number
    this.mobile_phone = item.mobile_phone
    this.phone = item.phone
    this.timezone = item.timezone
    this.phones = item.phones
    this.is_blocked = item?.is_blocked || false
    this.custom_fields = item.custom_fields || []
    this.integration_type = item.integration_type || 'salesmsg'
    this.conversationId = item.conversationId || item.conversation?.id || null
    this.icon = item.icon
    this.notes = this.getEnrichNotes(item.notes) || []
    this.tags = Array.isArray(item.tags) ? item.tags.map((item) => new Tag(item)) : []
    this.integration_key = item.integration_key || item.integration?.key
    this.created_at = item.created_at
    this.area_code_info = item.area_code || null
    this.updated_at = item.updated_at
    this.is_invalid = item.is_invalid
    this.lookup_type = item.lookup_type
    this.carrier_name = item.carrier_name
    this.opt_out = item.opt_out || null
    this.opt_out_at = item.opt_out_at || null
    this.opt_out_at_shortcode = item.opt_out_at_shortcode
    this.opt_in_at = item.opt_in_at || ''
    this.opt_in_request_at = item.opt_in_request_at || ''
    this.shortcode_opt_outs = item.shortcode_opt_outs || []
    this.last_messaged_on = item.last_messaged_on
    this.last_message_sent_on = item.last_message_sent_on
    this.last_message_received_on = item.last_message_received_on
    this.last_called_on = item.last_called_on
    this.last_contacted_on = item.last_contacted_on
    this.message_status = item.message_status
    this.opt_in = item.opt_in
    this.keywords = item.keywords
    this.total_received_sms = item.total_received_sms
    this.total_received_call = item.total_received_call
    this.origin = item
    this.source = item.source
    this.opt_in_requests = item.opt_in_requests || []
    this.country_calling_code = item.country_calling_code || ''
    this.owner_id = item.owner_id || null
    this.prevent_autolink = item.prevent_autolink || false
    this.isDisabled = !!item.disabled

    if (this.integrationType === 'pipedrive') {
      const phones = Array.isArray(this.integrationInfo.phones) ? this.integrationInfo.phones : []
      const email = Array.isArray(this.integrationInfo.email) ? this.integrationInfo.email : []
      const full_name = this.integrationInfo.full_name

      this.phone = phones.length ? phones[0] : this.integrationInfo.phone || ''
      this.email = email.length ? email[0] : ''
      this.first_name = this.integrationInfo.first_name || ''
      this.last_name = this.integrationInfo.last_name || ''
      this.full_name = full_name || ''
    }

    if (this.integrationType === 'salesforce') {
      this.handleSalesforcePhones()
    }

    makeAutoObservable(this)
  }

  get keyword() {
    // We have key in filters === 'keyword' but in Contact there is a 'keywords' field
    // There is no opportunity to synchronize it on the Backend side
    return this.keywords
  }

  get area_code() {
    return this.area_code_info?.area_code || ''
  }

  get country() {
    return this.area_code_info?.country_code || ''
  }

  get state_code() {
    return this.area_code_info?.state_code || ''
  }

  get state_name() {
    return this.area_code_info?.state_name || ''
  }

  get time_zone() {
    return this.area_code_info?.time_zone || ''
  }

  get avatarInfo(): IAvatarInfo {
    const isPhoneAbbr = !this.first_name && !this.last_name

    return {
      isPhoneAbbr,
      firstName: this.first_name,
      lastName: this.last_name,
      image: this.photo_url,
      color: this.color,
      icon: this.icon,
      phone: this.formatted_number,
    }
  }

  get customFields() {
    return this.custom_fields || []
  }

  get hasCustomFields() {
    return !!this.custom_fields.length
  }

  get hasTags() {
    return !!this.tags.length
  }

  get tagsCount() {
    return this.hasTags ? this.tags.length : 0
  }

  get tagsMoreCount() {
    return this.tagsCount - 5
  }

  get hasMoreTags() {
    return this.tagsCount > 5
  }

  get first5Tags() {
    if (this.tagsCount < 6) return this.tags
    return this.tags.slice(0, 5)
  }

  get hasNotes() {
    return !!this.notes.length
  }

  get notesCount() {
    return this.hasNotes ? this.notes.length : 0
  }

  get sortedNotes() {
    return this.notes.slice().sort(function (a: INote, b: INote) {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    })
  }

  get hasMoreNotes() {
    return this.notesCount > 3
  }

  get last3Notes() {
    if (this.notesCount < 4) return this.sortedNotes
    return this.sortedNotes.slice(0, 3)
  }

  get fullName() {
    let output = ''
    const first_name = this.first_name
    const last_name = this.last_name
    const full_name = this.full_name

    if (first_name) {
      output = first_name

      if (last_name) {
        output += ' ' + last_name
      }
    } else if (full_name) {
      output = full_name
    }

    return output
  }

  get name() {
    let output = ''
    const full_name = this.fullName.trim()

    if (full_name) {
      output = full_name
    } else if (this.email) {
      output = this.email
    } else if (this.formatted_number) {
      output = this.formatted_number
    } else if (this.phone) {
      output = this.phone
    }

    return output ? output : ''
  }

  get phoneInfo() {
    return (
      this.phone ||
      this.mobile_phone ||
      this.formatted_number ||
      this.national_number ||
      this.number
    )
  }

  get defaultPhoneInfo() {
    return this.phone || this.formatted_number || this.national_number || this.number
  }

  get responseContactIntegration(): IResponseContactIntegration | null {
    return (
      this.hubspot ||
      this.activecampaign ||
      this.infusionsoft ||
      this.pipedrive ||
      this.salesforce ||
      null
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  get integrationInfo() {
    if (!this.hasIntegration) return this

    return (
      this.hubspot || this.activecampaign || this.infusionsoft || this.pipedrive || this.salesforce
    )
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  get integrationType() {
    if (!this.integrationInfo?.integration_type) return 'salesmsg'

    return this.integrationInfo.integration_type
  }

  get phoneNumbers(): IContactPhone[] {
    const phones: IContactPhone[] = []

    const phoneTypeMapping = {
      phone: { field: 'Phone', type: 'phone' },
      mobile_phone: { field: 'Mobile phone', type: 'mobilephone' },
      custom_phone: { field: 'Custom phone', type: 'customphone' },
    }

    for (const [key, value] of Object.entries(phoneTypeMapping)) {
      if (this.integrationInfo[key]) {
        phones.push({
          field: value.field,
          type: value.type,
          number: this.integrationInfo[key],
        })
      }
    }

    return phones
  }

  get hasPhoneNumbers() {
    return Boolean(this.phoneNumbers.length)
  }

  get phoneCount() {
    const phones = []

    if (this.phone) phones.push(this.phone)
    if (this.mobile_phone) phones.push(this.mobile_phone)
    if (this.custom_phone) phones.push(this.custom_phone)

    return phones.length
  }

  get hasIntegration() {
    return Boolean(
      this.hubspot || this.activecampaign || this.infusionsoft || this.pipedrive || this.salesforce
    )
  }

  get hasIntegrationType() {
    if (this.integration_type === 'salesmsg') return false

    return Boolean(this.integration_type)
  }

  get generateColor() {
    const colors = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']

    return colors[+this.id % colors.length]
  }

  get optInLocal(): IResponseShortcodeOptOut {
    return {
      id: 'local',
      disabled: this.opt_out === 'hard',
      name: 'Local and Toll-Free numbers',
      value: this.opt_out === null,
      number_id: 0,
      timestamp: null,
      formatted_number: '',
    }
  }

  get optInShortCode(): IResponseShortcodeOptOut[] {
    if (!Array.isArray(this.shortcode_opt_outs)) return []

    return this.shortcode_opt_outs
  }

  get shortCodeOptOutesList() {
    const defaultOptOut: IShortCodeOptOut = {
      disabled: this.opt_out === 'hard',
      id: 'local',
      name: 'Local and Toll-free Numbers',
      value: this.opt_out === null,
    }

    return [defaultOptOut, ...this.shortcode_opt_outs]
  }

  get optItems() {
    return [this.optInLocal, ...this.optInShortCode]
  }

  get isOptOut() {
    return !this.optInLocal.value
  }

  get isBlocked() {
    return !!this.is_blocked
  }

  get isOptOutHard() {
    return this.opt_out === 'hard'
  }

  get isNew() {
    return this.id === -1 || this.id === 0
  }

  get isOptOutCurrentInbox() {
    const conversation = conversationStore.currentItem
    let inbox = inboxesStore.getItem(conversation?.inbox_id)
    if (conversationStore.isNewConversation) {
      inbox = inboxesStore.currentInbox
    }
    const number = inbox?.type === 'inbox' ? numbersStore.getItem(inbox?.numberId) : undefined

    if (!this.optInShortCode.length) return false
    if (!number?.isShortCode) return false

    const numberId = number.id

    const optInShortCode = this.optInShortCode.find((item) => item.number_id === numberId)

    if (!optInShortCode) return false

    return !optInShortCode.value
  }

  get isOptedIn() {
    return this.opt_in_at && (this.opt_in_request_at || this.opt_in_requests.length > 0)
  }

  handleSalesforcePhones = () => {
    const isPhonesObject = typeof this.phones === 'object' && !Array.isArray(this.phones)

    if (!isPhonesObject || !this.phones) return

    const phones = this.phones as IPhones

    this.phone = phones['Phone']
    this.mobile_phone = phones['Mobile Phone']
    this.custom_phone = phones['Custom Phone']
  }

  getEnrichNotes = (notes?: IResponseNote[]) =>
    notes?.map((note) => {
      const { created_by } = note

      if (!created_by) return { ...note, avatarInfo: {} }

      return {
        ...note,
        avatarInfo: {
          id: created_by?.id,
          firstName: created_by?.first_name,
          lastName: created_by?.last_name,
          image: created_by?.photo_url,
          color: created_by?.color,
        },
      }
    }) ?? []

  setActionsProps?: ((actionProps: IActionsProps) => void) | null = (
    actionProps: IActionsProps
  ) => {
    this.actionsProps = actionProps
  }

  handleUpdateNotes = (items: INote[] = [], responseNotes?: IResponseNote[]) => {
    const newNotes = items

    if (responseNotes) {
      newNotes.push(...this.getEnrichNotes(responseNotes))
    }

    this.notes = items
  }

  handleUpdateTags = (items: Tag[]) => {
    this.tags = items
  }

  getReceivedOptInRequestFromNumber(numberId?: number) {
    if (!numberId) return false

    return this.opt_in_requests.some((oir) => numberId === oir.number_id)
  }

  syncOrigin = (origin: IResponseContact) => {
    this.id = origin.id
    this.first_name = origin.first_name
    this.last_name = origin.last_name
    this.full_name = origin.full_name
    this.photo_url = origin.photo_url
    this.color = origin.color ? origin.color : this.generateColor
    this.number = origin.number
    this.formatted_number = origin.formatted_number
    this.email = origin.email || ''
    this.integration_vendor_id = origin.integration_vendor_id
    this.integration_vendor_type = origin.integration_vendor_type || null
    this.hubspot = origin.hubspot
    this.activecampaign = origin.activecampaign
    this.infusionsoft = origin.infusionsoft
    this.pipedrive = origin.pipedrive
    this.salesforce = origin.salesforce
    this.national_number = origin.national_number
    this.mobile_phone = origin.mobile_phone
    this.phone = origin.phone
    this.timezone = origin.timezone
    this.phones = origin.phones
    this.is_blocked = origin?.is_blocked || false
    this.custom_fields = origin.custom_fields || []
    this.integration_type = origin.integration_type || 'salesmsg'
    this.conversationId = origin.conversationId || origin.conversation?.id || null
    this.icon = origin.icon
    this.notes = this.getEnrichNotes(origin.notes)
    this.tags = Array.isArray(origin.tags) ? origin.tags.map((item) => new Tag(item)) : []
    this.integration_key = origin.integration_key || origin.integration?.key
    this.created_at = origin.created_at
    this.area_code_info = origin.area_code || null
    this.updated_at = origin.updated_at
    this.is_invalid = origin.is_invalid
    this.lookup_type = origin.lookup_type
    this.carrier_name = origin.carrier_name
    this.opt_out = origin.opt_out || null
    this.opt_out_at = origin.opt_out_at || null
    this.opt_out_at_shortcode = origin.opt_out_at_shortcode
    this.opt_in_at = origin.opt_in_at || ''
    this.opt_in_request_at = origin.opt_in_request_at || ''
    this.shortcode_opt_outs = origin.shortcode_opt_outs || []
    this.last_messaged_on = origin.last_messaged_on
    this.last_message_sent_on = origin.last_message_sent_on
    this.last_message_received_on = origin.last_message_received_on
    this.last_called_on = origin.last_called_on
    this.last_contacted_on = origin.last_contacted_on
    this.message_status = origin.message_status
    this.opt_in = origin.opt_in
    this.keywords = origin.keywords
    this.total_received_sms = origin.total_received_sms
    this.total_received_call = origin.total_received_call
    this.origin = origin
    this.source = origin.source
    this.opt_in_requests = origin.opt_in_requests || []
    this.country_calling_code = origin.country_calling_code || ''
    this.owner_id = origin.owner_id || null
    this.prevent_autolink = origin.prevent_autolink || false

    const integrationInfo =
      origin.hubspot ||
      origin.activecampaign ||
      origin.infusionsoft ||
      origin.pipedrive ||
      origin.salesforce ||
      null

    const integrationType = integrationInfo?.integration_type || 'salesmsg'

    if (integrationType === 'pipedrive' && origin.pipedrive) {
      const phones = Array.isArray(origin.pipedrive.phones) ? origin.pipedrive.phones : []
      const email = Array.isArray(origin.pipedrive.email) ? origin.pipedrive.email : []
      const full_name = origin.pipedrive.full_name

      this.phone = phones.length ? phones[0] : this.integrationInfo.phone || ''
      this.email = email.length ? email[0] : ''
      this.first_name = origin.pipedrive.first_name || ''
      this.last_name = origin.pipedrive.last_name || ''
      this.full_name = full_name || ''
    }

    if (integrationType === 'salesforce' && origin.salesforce) {
      this.handleSalesforcePhones()
    }
  }

  get dataDraft(): IResponseConversationDraftMessageContact {
    return {
      color: this.color,
      country: this.country,
      country_calling_code: this.country_calling_code,
      created_at: this.created_at,
      custom_fields: this.custom_fields,
      email: this.email,
      first_name: this.first_name,
      formatted_number: this.formatted_number,
      full_name: this.full_name,
      id: this.id,
      integration_vendor_id: this.integration_vendor_id,
      integration_vendor_type: this.integration_vendor_type,
      last_name: this.last_name,
      national_number: this.national_number,
      number: this.number,
      opt_in_at: this.opt_in_at,
      opt_in_request_at: this.opt_in_request_at,
      opt_in_requests: this.opt_in_requests,
      opt_out: this.opt_out,
      opt_out_at: this.opt_out_at,
      owner_id: this.owner_id,
      photo_url: this.photo_url,
      prevent_autolink: this.prevent_autolink,
      tags: this.tags,
      updated_at: this.updated_at,
    }
  }
}
