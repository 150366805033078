import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import {
  PresetContainer,
  PresetFooter,
  PresetInfo,
  PresetList,
  PresetListItem,
} from 'shared/Preset'
import { ChatbotInstructionType } from 'entities/Chatbot'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { ChatbotTemplateList } from 'pages/chatbot/config'

import { type ICreateChatbotProps } from './types'
import styles from './styles.module.scss'

export const ChatbotTemplateModal = observer(
  ({ chatbot, instructions, onCancel, onNext }: ICreateChatbotProps) => {
    const handleChangeType = (type: ChatbotInstructionType) => {
      const instruction = instructions.get(type)
      if (!!instruction) chatbot.settings.setInstruction(instruction)

      chatbot.setType(type)
    }

    return (
      <PresetContainer>
        {featureFlagsStore.salesmsg_ai_agent_book_appointment_v2 ? (
          <Alert
            className={styles.infoAlert}
            item={{
              type: 'infoLight',
              desc: 'Pick a pre-built agent for your task.',
            }}
          />
        ) : (
          <PresetInfo name='You can select one template' />
        )}
        <PresetList className={styles.presetList}>
          {ChatbotTemplateList.filter((template) => {
            if (!featureFlagsStore.salesmsg_ai_agent_book_appointment_v2) {
              return template.type !== ChatbotInstructionType.BookAppointment
            }
            return true
          }).map((template) => (
            <PresetListItem
              key={template.name}
              disabled={
                !instructions.has(template.type) &&
                template.type !== ChatbotInstructionType.LeadQualification &&
                template.type !== ChatbotInstructionType.BookAppointment
              }
              selected={chatbot.type === template.type}
              onClick={() => handleChangeType(template.type)}
              icon={template.icon}
              name={template.name}
              description={template.description}
              isNew={template.isNew}
            />
          ))}
        </PresetList>
        <PresetFooter>
          <Button text='Cancel' size='medium' contained='secondary' onClick={onCancel} />
          <Button text='Next' size='medium' disabled={!chatbot.type} onClick={onNext} />
        </PresetFooter>
      </PresetContainer>
    )
  }
)
