import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { getAriaLabel } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { Activity } from 'entities/Activity/model/Activity'
import { Message } from 'entities/Message/model/Message'
import { DateContent } from 'widgets/DayContent/DateContent'
import { ActivityCard } from 'widgets/ActivityCard/ActivityCard'
import { MessageCard } from 'widgets/MessageCard'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import styles from './styles.module.scss'

type IDayContentProps = {
  day: string
  items?: Array<Message | Activity>
  item?: Message | Activity
  isScheduled?: boolean
  isLastDay?: boolean
  isHideDay?: boolean
  getIsHideTeams?: (
    item: Message | Activity,
    index: number,
    items: Array<Message | Activity>
  ) => boolean
  conversationMessagesStore?: ConversationMessagesStore | null
}

export const DayContent: FC<IDayContentProps> = observer(
  ({
    conversationMessagesStore,
    day,
    items,
    item,
    isScheduled,
    isLastDay,
    isHideDay,
    getIsHideTeams,
  }) => {
    const renderItem = (
      item: Message | Activity,
      options?: {
        isLast?: boolean
        isHideTeam?: boolean
      }
    ) => {
      const isLast = options?.isLast
      const isHideTeam = options?.isHideTeam
      if (item instanceof Message) {
        return (
          <MessageCard
            key={item.client_id}
            message={item}
            isLast={isLast}
            avatarInfo={
              item.isOutbound
                ? usersStore.usersMap.get(item.user_id || 0)?.avatarInfo
                : contactsStore.getItem(item.contact_id)?.avatarInfo
            }
            hideTeam={isHideTeam}
            conversationMessagesStore={conversationMessagesStore}
            callContentStore={conversationMessagesStore?.callContentStore}
          />
        )
      }

      return (
        <ActivityCard
          key={item.id}
          activity={item}
          avatarInfo={usersStore.usersMap.get(item.user_id || 0)?.avatarInfo}
          hideTeam={isHideTeam}
        />
      )
    }

    return (
      <div className={styles.wrap} aria-label={getAriaLabel('DayContent')}>
        {!isHideDay && <DateContent day={day} isScheduled={isScheduled} />}
        {item && (
          <div className={styles.messagesWrap} aria-label={getAriaLabel('Message')}>
            {renderItem(item)}
          </div>
        )}
        {items && (
          <div className={styles.messagesWrap} aria-label={getAriaLabel('MessagesList')}>
            {items.map((item, index) => {
              const isHideTeam = getIsHideTeams?.(item, index, items)
              return renderItem(item, {
                isLast: index === items.length - 1 && isLastDay,
                isHideTeam,
              })
            })}
          </div>
        )}
      </div>
    )
  }
)
