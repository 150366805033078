import React from 'react'
import { ComplianceTabs } from 'pages/admin/pages/compliance/ui/ComplianceTabs/ComplianceTabs'
import { TenDLCTable } from 'pages/admin/pages/compliance/pages/TenDLC/ui/TenDLCTable/TenDLCTable'
import { TenDLCSearch } from 'pages/admin/pages/compliance/pages/TenDLC/ui/TenDLCSearch/TenDLCSearch'
import { TenDLCHeader } from 'pages/admin/pages/compliance/pages/TenDLC/ui/TenDLCHeader/TenDLCHeader'

export const TenDLC = () => {
  return (
    <>
      <TenDLCHeader />
      <TenDLCSearch />
      <ComplianceTabs />
      <TenDLCTable />
    </>
  )
}
