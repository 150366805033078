import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Icon, SpinnerLoader, Typography } from 'shared/ui'
import { CustomIntegrationInfoIcon } from 'widgets/IntegrationDropdown/ui/IntegrationLabel'
import { useIntegrationDropdownContext } from '../context/integrationDropdownContext'
import styles from './styles.module.scss'

export const IntegrationTrigger = observer(() => {
  const { loading, integrationItemsCount, selectedIntegrationItem } =
    useIntegrationDropdownContext()

  const iconContent = () => {
    if (!selectedIntegrationItem) return <Icon icon={'plug'} fontSize={16} />
    return <Icon icon={selectedIntegrationItem.iconL} fontSize={16} />
  }

  const content = () => {
    if (loading) {
      return (
        <div className={styles.loaderTrigger}>
          <SpinnerLoader size={10} indeterminatecolor={'var(--content-primary-tertiary)'} />
        </div>
      )
    }

    if (!integrationItemsCount) {
      return (
        <Typography
          className={classnames(styles.trigger__label)}
          variant={'text-input-field'}
          ariaLabel={'No results found'}
        >
          No results found
        </Typography>
      )
    }

    if (!selectedIntegrationItem) {
      return (
        <Typography
          className={classnames(styles.trigger__label, styles['trigger__label--placeholder'])}
          variant={'text-input-field'}
          ariaLabel={'Select inbox'}
        >
          Select integration
        </Typography>
      )
    }

    return (
      <Typography className={styles.trigger__label} variant={'text-input-field'} ariaLabel={'name'}>
        <div className={styles.trigger__name}>{selectedIntegrationItem.label}</div>
      </Typography>
    )
  }

  return (
    <div className={styles.trigger}>
      <div className={styles.trigger__icon}>{iconContent()}</div>
      {content()}
      {selectedIntegrationItem?.id === 'custom' && <CustomIntegrationInfoIcon />}
    </div>
  )
})
