import { ReactNode, FC } from 'react'
import classNames from 'classnames'
import { Icon, SpinnerLoader, Tooltip, Typography } from 'shared/ui'
import { makeTextFieldError, TextFieldPlaceholder } from 'shared/ui/TextField'
import { numberFormat } from 'shared/lib'
import styles from './styles.module.scss'

type IUiContactsTriggerProps = {
  total: number | null
  skipped: number
  loading?: boolean
  onClick?: () => void
  error?: ReactNode
  viewOnly?: boolean
  wrapPlaceholderClassName?: string
}

type ITooltipTriggerProps = {
  children: ReactNode
  total: number | null
  skipped: number
}

const TooltipTrigger: FC<ITooltipTriggerProps> = ({ children, total, skipped }) => {
  if (!total) return <>{children}</>

  const all_count = numberFormat({ value: total })
  const invalid_count = numberFormat({ value: skipped })

  return (
    <Tooltip
      type={'filtered_items'}
      placement={'top-start'}
      label={'Total contacts that meet the filters criteria'}
      items={[
        {
          title: 'Total',
          total: all_count,
          dropdown: 'Total contacts that meet the filters criteria.',
        },
        {
          title: 'Skipped',
          total: invalid_count,
          dropdown:
            'Filtered contacts who are invalid or unsubscribed. Contacts will be checked for invalid numbers before sending.',
        },
      ]}
      className={styles.tooltip}
      PopperProps={{ className: styles['tooltip-content'] }}
      marginLeft={-3}
      padding={'12px 8px'}
    >
      <>{children}</>
    </Tooltip>
  )
}

export const UiContactsTrigger = ({
  total,
  skipped,
  onClick,
  error,
  loading,
  viewOnly,
  wrapPlaceholderClassName,
}: IUiContactsTriggerProps) => {
  const content = () => {
    if (loading) {
      return (
        <div className={styles.loaderTrigger}>
          <SpinnerLoader size={10} indeterminatecolor={'var(--content-primary-tertiary)'} />
        </div>
      )
    }

    if (total) {
      const all_count = numberFormat({ value: total })
      const count = numberFormat({ value: total - skipped })

      return (
        <Typography className={styles.text} variant={'text-input-field'} ariaLabel={'number'}>
          {count} <span>of {all_count}</span>
        </Typography>
      )
    }

    return (
      <Typography
        variant={'text-input-field'}
        color={'var(--content-neutral-primary)'}
        ariaLabel={'SelectContacts'}
      >
        Select contacts
      </Typography>
    )
  }

  return (
    <TooltipTrigger total={total} skipped={skipped}>
      <div onClick={onClick} className={styles.trigger}>
        <TextFieldPlaceholder
          placeholder={
            <div className={classNames(styles.wrapPlaceholder, wrapPlaceholderClassName)}>
              <div className={styles.trigger__icon}>
                <Icon icon={'contacts'} tertiary />
              </div>

              {content()}
            </div>
          }
          variant={'outline'}
          size={'small'}
          className={styles.input}
          rightActions={[
            makeTextFieldError({
              placement: 'right',
            }),
          ]}
          error={error}
          viewOnly={viewOnly}
        />
      </div>
    </TooltipTrigger>
  )
}
