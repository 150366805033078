import { AxiosResponse } from 'axios'
import { IResponseFeatureFlags } from 'entities/FeatureFlags/api/types'
import { baseApi } from 'entities/Auth'

class Api {
  getFeatureFlags(): Promise<AxiosResponse<IResponseFeatureFlags>> {
    return baseApi.get('users/feature-flags')
  }
}

export const FeaturesFlagsApi = new Api()
