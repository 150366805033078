// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MGKcDpmSyR0OQVQ0iB2m{height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.MGKcDpmSyR0OQVQ0iB2m .dXyvVIi0bX6D9oueq0lA{width:80px;height:80px;border-radius:100px;background:var(--green-10);display:flex;align-items:center;justify-content:center}.MGKcDpmSyR0OQVQ0iB2m .dXyvVIi0bX6D9oueq0lA .ouI9oVFeEL1TxkeKgW_Y{width:32px;height:32px;border-radius:12px;background:var(--green-70)}", "",{"version":3,"sources":["webpack://./src/widgets/ForgotPassword/ui/SuccessFrom/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,4CACE,UAAA,CACA,WAAA,CACA,mBAAA,CACA,0BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,kEACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,0BAAA","sourcesContent":[".wrap {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  .iconWrap {\n    width: 80px;\n    height: 80px;\n    border-radius: 100px;\n    background: var(--green-10);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    .icon {\n      width: 32px;\n      height: 32px;\n      border-radius: 12px;\n      background: var(--green-70);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "MGKcDpmSyR0OQVQ0iB2m",
	"iconWrap": "dXyvVIi0bX6D9oueq0lA",
	"icon": "ouI9oVFeEL1TxkeKgW_Y"
};
export default ___CSS_LOADER_EXPORT___;
