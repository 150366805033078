import React from 'react'
import { AdvancedSchedulingStore } from 'shared/ui/Schedule/ui/AdvancedScheduling'
import { AdvancedSchedulingContext } from 'shared/ui/Schedule'
import {
  AdvancedSchedulingContent,
  AdvancedSchedulingContentProps,
} from './ui/AdvancedSchedulingContent'

type IAdvancedSchedulingProps = {
  store: AdvancedSchedulingStore
} & AdvancedSchedulingContentProps

export const AdvancedScheduling = ({ store, ...props }: IAdvancedSchedulingProps) => (
  <AdvancedSchedulingContext.Provider value={store}>
    <AdvancedSchedulingContent {...props} />
  </AdvancedSchedulingContext.Provider>
)
