import React from 'react'
import {
  AdvancedSchedulingContent,
  type AdvancedSchedulingContentProps,
} from './ui/AdvancedSchedulingContent'
import { type AdvancedSchedulingStore } from './store/advancedSchedulingStore'
import { AdvancedSchedulingContext } from './context/AdvancedSchedulingContext'

type IAdvancedSchedulingProps = {
  store: AdvancedSchedulingStore
} & AdvancedSchedulingContentProps

export const AdvancedScheduling = ({ store, ...props }: IAdvancedSchedulingProps) => (
  <AdvancedSchedulingContext.Provider value={store}>
    <AdvancedSchedulingContent {...props} />
  </AdvancedSchedulingContext.Provider>
)
