import { makeAutoObservable, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { eventEmitterStore } from 'entities/EventEmitter'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { Contact } from 'entities/Contacts/model/Contact'
import {
  ContactsDetailsStore,
  type IContactsDetailsManageStoreConfig,
  type IContactsDetailsStoreConfig,
  type IOpenContactsDetailsData,
  type IOpenContactsDetailsSource,
  type IOpenConversationsDetailsData,
} from 'widgets/ContactsDetails'

export class ContactsDetailsManageStore {
  private _contactsDetailsStore: ContactsDetailsStore | null = null
  private _disabled = false
  private _config: IContactsDetailsManageStoreConfig | null = null
  public emitOpenConversationSignal: (() => void) | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setConfig = (config: IContactsDetailsManageStoreConfig) => {
    this._config = config
    this.emitOpenConversationSignal = config.emitOpenConversationSignal || null
  }

  init = () => {
    this.initEvents()
  }

  reset = () => {
    this.resetContactsDetails()
    eventEmitterStore.unsubscribe('deleteContactsDetails', this.onManageContactsDetails)
  }

  resetContactsDetails = () => {
    this._contactsDetailsStore?.reset()
    this._contactsDetailsStore = null
  }

  initEvents = () => {
    eventEmitterStore.subscribe('deleteContactsDetails', this.onManageContactsDetails)
  }

  setContactsDetails = (config: IContactsDetailsStoreConfig) => {
    this._contactsDetailsStore = new ContactsDetailsStore(config)
  }

  onManageContactsDetails = () => {
    if (this._contactsDetailsStore?.isVariantConversations) {
      this._contactsDetailsStore?.contactsDetailsGlobalStore.handleChangeCollapse(true)
      return
    }
    this.onDeleteContactsDetails()
  }

  onDeleteContactsDetails = () => {
    this._contactsDetailsStore?.reset()
    this._contactsDetailsStore?.contactsDetailsGlobalStore.dispose()
    this._contactsDetailsStore = null
  }

  setDisabled = (value: boolean) => {
    this._disabled = value
  }

  existContact = async (contactId?: number, conversationId?: number) => {
    try {
      if (!contactId && conversationId) {
        const conversation = await conversationStore.getById({
          id: conversationId,
        })

        contactId = conversation?.contact_id
      }

      const contact = contactId ? await contactsStore.getById(contactId, true) : undefined

      return contact
    } catch (e) {
      console.log(e)
    }
  }

  // Conversations Call History
  onOpenConversationDetails = async (
    data: IOpenConversationsDetailsData,
    source: IOpenContactsDetailsSource
  ) => {
    runInAction(() => {
      this._disabled = true
    })

    await usersStore.initPromise

    const contact = await this.existContact(data.contactId, data.conversationId)

    if (!contact || !inboxesStore.sharedInboxes.length || usersStore.user?.isShouldRequestAccess) {
      modalStore.addModal({
        disabledOnAllClose: true,
        type: ModalTypeList.INFO,
        title: 'Content unavailable',
        id: 'content_unavailable',
        desc: "We're sorry, but you don't currently have access to this content or it was deleted. Please contact the user who shared the link with you to request access.",
      })

      runInAction(() => {
        this._disabled = false
      })

      return
    }

    const contactId = data.contactId
    const inboxId = data.inboxId
    const conversationId = data.conversationId

    eventLogStore.logEvent(
      'ConvSlideout__opened',
      {
        event_id: 'convslideout_opened',
        from: source,
      },
      {
        groupId: organizationStore.id,
      }
    )

    const sub_source = `conv_slideout_${source.replace(' ', '_')}`

    this._contactsDetailsStore = new ContactsDetailsStore({
      conversationId: conversationId,
      contactId: contactId,
      variant: 'open_conversation',
      profileCardProps: {
        onClickCall: () => {
          eventLogStore.logEvent(
            'ConvSlideout_Call_Made',
            {
              event_id: 'convslideout_call_made',
              from: source,
            },
            {
              groupId: organizationStore.id,
            }
          )
        },
        enableCall: true,
      },
      onDeleteContactsDetails: this.onDeleteContactsDetails,
      emitOpenConversationSignal: () => {
        this.emitOpenConversationSignal?.()
      },
      onCloseContactDetails: this.onDeleteContactsDetails,
      sub_source: sub_source,
      headerProps: {
        iconHide: 'close',
        textHide: 'Close details',
      },
      notesProps: {
        hideMessageFieldActions: ['ai_assistant'],
      },
    })

    if (conversationId) {
      this._contactsDetailsStore.contactsConversationOpenStore.setConversationId(conversationId)
    }

    if (inboxId) {
      this._contactsDetailsStore.contactsConversationOpenStore.open(inboxId)
    }

    runInAction(() => {
      this._disabled = false
    })
  }

  // Contacts Table
  onOpenContactDetails = async (
    data: IOpenContactsDetailsData,
    source: IOpenContactsDetailsSource,
    checkInbox?: boolean,
    callback?: (contact: Contact) => void
  ) => {
    runInAction(() => {
      this._disabled = true
    })

    await Promise.all([usersStore.initPromise, inboxesStore.fetchInboxes()])
    const contact = await this.existContact(data.contactId)

    if (
      !contact ||
      (checkInbox && !data.inboxId) ||
      !inboxesStore.sharedInboxes.length ||
      usersStore.user?.isShouldRequestAccess
    ) {
      modalStore.addModal({
        disabledOnAllClose: true,
        type: ModalTypeList.INFO,
        title: 'Content unavailable',
        id: 'content_unavailable',
        desc: "We're sorry, but you don't currently have access to this content or it was deleted. Please contact the user who shared the link with you to request access.",
      })

      runInAction(() => {
        this._disabled = false
      })

      return
    }

    const contactId = data.contactId
    const inboxId = data.inboxId

    eventLogStore.logEvent(
      'ConvSlideout__opened',
      {
        event_id: 'convslideout_opened',
        from: source,
      },
      {
        groupId: organizationStore.id,
      }
    )

    const sub_source = `conv_slideout_${source.replace(' ', '_')}`

    this._contactsDetailsStore = new ContactsDetailsStore({
      contactId: contactId,
      inboxId: inboxId,
      variant: 'open_conversation',
      profileCardProps: {
        onClickCall: () => {
          eventLogStore.logEvent(
            'ConvSlideout_Call_Made',
            {
              event_id: 'convslideout_call_made',
              from: source,
            },
            {
              groupId: organizationStore.id,
            }
          )
        },
        enableCall: true,
      },
      onDeleteContactsDetails: this.onDeleteContactsDetails,
      onCloseContactDetails: this.onDeleteContactsDetails,
      onUpdateContact: callback,
      sub_source: sub_source,
      headerProps: {
        iconHide: 'close',
        textHide: 'Close details',
      },
      notesProps: {
        hideMessageFieldActions: ['ai_assistant'],
      },
    })

    this._contactsDetailsStore.contactsConversationOpenStore.open()

    runInAction(() => {
      this._disabled = false
    })
  }

  get contactsDetailsStore() {
    return this._contactsDetailsStore
  }

  get disabled() {
    return this._disabled
  }
}
