import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { ComplianceSettingsStore } from '../store/ComplianceSettingsStore'

const ComplianceSettingsContext = createContext<ComplianceSettingsStore | null>(null)

export const useComplianceSettingsContext = () => {
  const context = useContext(ComplianceSettingsContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with ComplianceSettingsProvider'
    )

  return context
}

export const ComplianceSettingsProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new ComplianceSettingsStore(), [])

  useEffect(() => store.dispose, [])

  return (
    <ComplianceSettingsContext.Provider value={store}>
      {children}
    </ComplianceSettingsContext.Provider>
  )
}
