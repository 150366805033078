import { createContext, useContext, FC, ReactNode } from 'react'
import { type ContactsIntegrationsStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations'

export const ContactsIntegrationsContext = createContext<ContactsIntegrationsStore | null>(null)

export const useContactsIntegrationsContext = () => {
  const context = useContext(ContactsIntegrationsContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with ContactsIntegrationsContext'
    )

  return context
}

type IProviderContactsIntegrationsProps = {
  value: ContactsIntegrationsStore
  children: ReactNode
}

export const ProviderContactsIntegrations: FC<IProviderContactsIntegrationsProps> = ({
  value,
  children,
}) => {
  return (
    <ContactsIntegrationsContext.Provider value={value}>
      {children}
    </ContactsIntegrationsContext.Provider>
  )
}
