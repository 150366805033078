// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xMB0KZXimX8LG_962oT9{margin:9px 21px 26px}.xMB0KZXimX8LG_962oT9 .daioWJW1oKF5AWr8phbD{margin-right:10px}.xMB0KZXimX8LG_962oT9 .BBJYPHl2m72eiwynLYS5 input{height:16px;font-size:16px}.xMB0KZXimX8LG_962oT9 .BBJYPHl2m72eiwynLYS5 input ::placeholder{color:var(--content-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/compliance/pages/TenDLC/ui/TenDLCSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CAEA,4CACE,iBAAA,CAIA,kDACE,WAAA,CACA,cAAA,CAEA,gEACE,oCAAA","sourcesContent":[".wrap {\n  margin: 9px 21px 26px;\n\n  .icon {\n    margin-right: 10px;\n  }\n\n  .search {\n    & input {\n      height: 16px;\n      font-size: 16px;\n\n      ::placeholder {\n        color: var(--content-neutral-primary);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "xMB0KZXimX8LG_962oT9",
	"icon": "daioWJW1oKF5AWr8phbD",
	"search": "BBJYPHl2m72eiwynLYS5"
};
export default ___CSS_LOADER_EXPORT___;
