import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Contact } from 'entities/Contacts/model/Contact'
import { Integration } from 'entities/Integrations/model/Integration'
import { SavedRepliesIconAction } from 'widgets/SavedReplies'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

type ISavedRepliesActionProps = {
  contact?: Contact | null
  integration?: Integration | null
  noMergeField?: boolean
}

export const SavedRepliesAction: FC<ISavedRepliesActionProps> = observer(
  ({ contact, integration, noMergeField }) => {
    const messageFieldStore = useMessageFieldContext()

    return (
      <SavedRepliesIconAction
        contact={contact}
        integration={integration}
        onAddSavedReplies={(data) =>
          messageFieldStore?.setMessageData({
            ...data,
            noMergeField: noMergeField,
            isReplace: false,
            replaceNewRow: false,
          })
        }
        iconButtonProps={messageFieldStore.iconButtonProps}
      />
    )
  }
)
