import { makeAutoObservable, runInAction } from 'mobx'
import { numberFormat } from 'shared/lib'
import { organizationStore } from 'entities/Organization'
import type {
  IResponseSubscription,
  IResponseSubscriptionPlan,
  IResponseSubscriptionSuggestion,
  ISubscriptionStatuses,
} from 'entities/Subscription/api/types'
import { SubscriptionApi } from 'entities/Subscription/api/subscription'
import { TrialSubscriptionStore } from 'entities/Subscription/store/trialSubscriptionStore'

export class SubscriptionStore {
  initPromise
  private _setFinishInit: ((value?: unknown) => void) | null = null
  private _loading = false
  private _trialActiveContactsCount = 250

  private _id: number | null = null
  private _plan: IResponseSubscriptionPlan | null = null
  private _status: ISubscriptionStatuses | null = null
  private _subscriptionSuggestion: IResponseSubscriptionSuggestion | null = null
  private _numbers: Null<IResponseSubscription['numbers']> = null
  private _trialCredits = 0
  private _trialSubscriptionStore = new TrialSubscriptionStore(this)

  constructor() {
    makeAutoObservable(this)
    this.initPromise = new Promise((resolve) => {
      this._setFinishInit = resolve
    })
  }

  get trialSubscriptionStore() {
    return this._trialSubscriptionStore
  }

  get id() {
    return this._id
  }

  get plan() {
    return this._plan
  }

  get status() {
    return this._status
  }

  get loading() {
    return this._loading
  }

  get trialCredits() {
    return this._trialCredits
  }

  get subscriptionSuggestion() {
    return this._subscriptionSuggestion
  }

  get trialActiveContactsCount() {
    return this._trialActiveContactsCount
  }

  get formattedTrialCredits() {
    return numberFormat({ value: this._trialCredits })
  }

  get isTrial() {
    return this._status === 'trialing'
  }

  get isActive() {
    return this._status === 'active'
  }

  get isPastDue() {
    return this._status === 'past_due'
  }

  private fetchSubscription = async () => {
    try {
      this._loading = true

      const { data: subscription } = await SubscriptionApi.getSubscription()

      runInAction(() => {
        this._id = subscription.id
        this._plan = subscription.plan
        this._status = subscription.status
        this._trialCredits = subscription.trial_credits
        this._subscriptionSuggestion = subscription.subscription_suggestion
        this._numbers = subscription.numbers
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  init = async () => {
    await this.fetchSubscription()
    this._setFinishInit?.()
  }

  get isAgencyWithSubscription() {
    if (!this._status) return false

    const availableStatuses: ISubscriptionStatuses[] = ['active', 'trialing']

    return organizationStore.isAgency && availableStatuses.includes(this._status)
  }

  get isAgencyWithoutSubscription() {
    if (!this._status) return true

    const availableStatuses: ISubscriptionStatuses[] = ['active', 'past_due', 'trialing']

    return organizationStore.isAgency && !availableStatuses.includes(this._status)
  }

  get isCanceled() {
    return this._status === 'canceled'
  }

  get isPlanActive() {
    return Boolean(this._plan?.active)
  }

  get isPAYGPlan() {
    return this._plan?.id === 'free-plan-monthly'
  }

  get numbers() {
    return this._numbers
  }
}

export const subscriptionStore = new SubscriptionStore()
