import dayjs from 'shared/lib/dayjs'
import { contactsStore } from 'entities/Contacts'
import { integrationsStore } from 'entities/Integrations'
import { MeetTypes } from 'widgets/ContactMeetDropdown/types/types'

export const contactMeetLink = (type: MeetTypes, contactId: number): string | null => {
  const contact = contactsStore.getItem(contactId)
  if (!contact) return null

  const title = `Meeting with ${contact.name}`
  const details = `Phone ${contact.formatted_number} | Scheduled from https://www.salesmessage.com/`
  const date = `${dayjs().utc().format('YYYYMMDDT120000Z')}`
  const integration = integrationsStore.getIntegration(contact?.integration_key)

  if ((type === MeetTypes.HUBSPOT || type === MeetTypes.INFUSIONSOFT) && !integration?.isConnected)
    return null

  const vendorId = contact.integration_vendor_id
  const providerIdentifier = integration?.user_credential?.provider_identifier
  const defaultURL = integration?.user_credential?.meta?.appUrl

  switch (type) {
    case MeetTypes.GOOGLE:
      return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${details}&add=${contact.email}`
    case MeetTypes.OUTLOOK:
      return `https://outlook.live.com/owa/?path=/calendar/action/compose&startdt=${date}&subject=${title}&body=${details}&allday=true`
    case MeetTypes.HUBSPOT:
      return `https://app.hubspot.com/contacts/${providerIdentifier}/contact/${vendorId}/?interaction=schedule`
    case MeetTypes.INFUSIONSOFT:
      return `${defaultURL}/Appointment/manageAppointment.jsp?view=add&Appointment0ContactId=${vendorId}&Appointment0CreationNotes=${' Scheduled from https://www.SalesMessage.com/'}`
    default:
      return null
  }
}
