import {
  DropResult,
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { ReactNode } from 'react'
import { useKeyGenerator } from 'shared/hooks'

type IDragEndDropSectionProps<T> = {
  droppableId: string
  onDragEnd?: (result: DropResult) => void
  items: T[]
  renderItem: (item: T, provided: DraggableProvided, snapshot: DraggableStateSnapshot) => ReactNode
}

export const DragEndDropSection = <T extends { key: string }>({
  droppableId,
  onDragEnd,
  items,
  renderItem,
}: IDragEndDropSectionProps<T>) => {
  const getFieldKey = useKeyGenerator<string>()

  return (
    <DragDropContext
      onDragEnd={(result) => {
        onDragEnd?.(result)
      }}
    >
      <Droppable droppableId={droppableId}>
        {(provided) => {
          return (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => {
                const key = getFieldKey(item.key)

                return (
                  <Draggable key={key} draggableId={key} index={index}>
                    {(provided, snapshot) => {
                      return (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          {renderItem(item, provided, snapshot)}
                        </div>
                      )
                    }}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}
