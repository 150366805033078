// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l4RB8UBoHWLd5t5Y0i3O{width:56px;display:flex;align-items:center;justify-content:center;color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/info/image/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qCAAA","sourcesContent":[".scalePercent{\n  width: 56px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: var(--content-primary-tertiary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scalePercent": "l4RB8UBoHWLd5t5Y0i3O"
};
export default ___CSS_LOADER_EXPORT___;
