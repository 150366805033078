import { FC, useMemo, useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, type IIconsVector } from 'shared/ui'
import {
  integrationLink,
  IIntegrationLinkTypes,
  IntegrationKey,
  integrationsStore,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IntegrationTicketProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationTicketProperty'
import { Actions } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/Actions'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import {
  ShowMoreAction,
  ShowMoreActionStore,
} from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { SectionTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/SectionTab/SectionTab'
import { AddPropertyAction } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/AddPropertyAction/AddPropertyAction'
import { NewPropertiesDropdownStore } from 'widgets/NewPropertiesDropdown'
import { type DraggableIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/store/draggableIntegrationStore'

type ITicketsProps = {
  integrationLabel: string
  integrationKey: IntegrationKey
  integrationIcon: IIconsVector
  tickets: IntegrationTicketProperty[]
  contact: Contact
  action?: ReactNode
  dnd?: DraggableIntegrationStore
}

export const Tickets: FC<ITicketsProps> = observer(
  ({ integrationLabel, integrationKey, integrationIcon, tickets, contact, action, dnd }) => {
    const showMoreActionStore = useMemo(
      () => new ShowMoreActionStore(integrationKey, EnumIntegrationTabs.Tickets),
      []
    )
    const newPropertiesDropdownStore = useMemo(() => new NewPropertiesDropdownStore(), [])
    const ticket = tickets[0]
    const store = useContactsIntegrationsContext()
    const integration = integrationsStore.getIntegration(integrationKey)
    const linkProps = {
      key: integrationKey,
      contact: contact,
      integration: integration,
      type: IIntegrationLinkTypes.ticket,
    }

    const link = (id: number | null | undefined) => {
      return integrationLink({
        ...linkProps,
        id: id,
      })
    }

    const handleView = () => {
      store.openView('tickets', { tickets }, integrationIcon, integrationKey)
    }

    const propertiesKeys = ticket?.propertiesKeys || []

    useEffect(() => {
      if (!ticket) return

      newPropertiesDropdownStore.searchFields('', true, 'ticket', ticket.id)
      newPropertiesDropdownStore.setConfig({
        contactId: contact.id,
        addItem: (item) => {
          ticket.addProperty(item)
          dnd?.addItem(item.key)
        },
        items: propertiesKeys,
      })
    }, [propertiesKeys.length, ticket])

    const actions = () => {
      const hasMore = tickets.length > 1

      return (
        <Actions
          rows={[
            {
              columns: [
                action,
                dnd && (
                  <AddPropertyAction
                    store={newPropertiesDropdownStore}
                    key={'add_property_action'}
                    objectType={'ticket'}
                    objectId={ticket?.id}
                  />
                ),
              ],
            },
            {
              columns: [
                !showMoreActionStore.hideShowMore && (
                  <ShowMoreAction
                    key={'show_more'}
                    disabled={!contact}
                    store={showMoreActionStore}
                  />
                ),
                hasMore && (
                  <Button size='small' typeBtn='text' text='See all tickets' onClick={handleView} />
                ),
              ],
            },
          ]}
        />
      )
    }

    const properties = showMoreActionStore.modifyData(ticket?.properties || [])

    return (
      <SectionTab
        item={ticket}
        titleView={'View company'}
        titleEmpty={'No tickets found'}
        integrationLabel={integrationLabel}
        properties={properties}
        link={link(ticket?.id)}
        actions={actions()}
        handleClear={(id) => {
          ticket?.removeProperty(id)
          dnd?.removeItem(id)
        }}
        handleReorder={(result) => {
          dnd?.updateItems(result.source.index, result.destination?.index)
        }}
        itemsDnD={dnd?.itemsUI}
      />
    )
  }
)
