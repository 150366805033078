import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'

import { IChatbotConversationResponse } from './conversationTypes'

class Api {
  start(id: number): Promise<AxiosResponse<IChatbotConversationResponse>> {
    return baseApi.post(`chatbot/api/chatbots/conversation/start/${id}/0`)
  }

  message(uuid: string, message: string): Promise<AxiosResponse<IChatbotConversationResponse>> {
    return baseApi.post(`chatbot/api/chatbots/conversation/message/${uuid}`, {
      message: message,
      core_message_id: 0,
    })
  }
}

export const ChatbotConversationApi = new Api()
