import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import { SettingsMenu } from 'pages/settings/layout/SettingsMenu'
import { SettingsProvider } from 'pages/settings/context/settingsContext'
import RootLayout from 'app/layouts/rootLayout'
import AuthLayout from 'app/appvue/settingsMenu/layouts/authLayout'

const routersSettingsMenu = createRoutesFromElements(
  <Route element={<RootLayout />}>
    <Route element={<AuthLayout />}>
      <Route
        path='/*'
        element={
          <SettingsProvider>
            <SettingsMenu />
          </SettingsProvider>
        }
      />
    </Route>
  </Route>
)

export const appSettingsMenu = createBrowserRouter(routersSettingsMenu)
