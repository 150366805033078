import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import { DayjsFormats, IDays } from 'shared/lib'
import { getDefaultQuarterTime, getSendTextByDays, WeekDaysSelectorStore } from 'shared/ui/Schedule'
import { IParamsAdvancedSchedule } from 'shared/ui/Schedule/ui/AdvancedScheduling'

export type IInitAdvancedScheduling = {
  days: IDays[]
  time: string
  isAdvancedScheduling: boolean
}

export class AdvancedSchedulingStore {
  constructor() {
    makeAutoObservable(this)
  }

  isRequiredAdvancedSchedule = false

  isAdvancedScheduling = false

  dropdownId = ''
  defaultTime = getDefaultQuarterTime()

  weekDaysSelectorStore = new WeekDaysSelectorStore()

  date: Date | null = this.defaultTime

  schedulingRecipientsMax = 0
  schedulingRecipients = 0

  get isEnabled() {
    return this.isRequiredAdvancedSchedule || this.isAdvancedScheduling
  }

  get time() {
    return this.date || this.defaultTime
  }

  get advancedSchedule(): IParamsAdvancedSchedule {
    if (this.isEnabled) {
      return {
        days: this.weekDaysSelectorStore.selectedDays,
        time: dayjs(this.time).format(DayjsFormats.time3),
      }
    }
    return {
      days: [],
      time: dayjs(this.defaultTime).format(DayjsFormats.time3),
    }
  }

  get advancedText() {
    if (this.isEnabled) {
      return `Send only ${getSendTextByDays(this.weekDaysSelectorStore.selectedDays)}`
    }
    return ''
  }

  get advancedTextTime() {
    if (this.isEnabled) {
      return `At ${dayjs(this.time).format(DayjsFormats.time)}`
    }
    return ''
  }

  init = ({ days, isAdvancedScheduling }: IInitAdvancedScheduling) => {
    if (isAdvancedScheduling) {
      this.weekDaysSelectorStore.init({ days })
      this.isAdvancedScheduling = isAdvancedScheduling
    }
  }

  initDefault = () => {
    this.defaultTime = getDefaultQuarterTime()
  }

  toggleAdvancedScheduling = () => {
    if (this.isRequiredAdvancedSchedule) {
      return
    }
    this.isAdvancedScheduling = !this.isAdvancedScheduling
  }

  setDate = (date: Date | null) => {
    this.defaultTime = getDefaultQuarterTime(date)
    this.date = date
  }

  setDropdownId = (dropdownId: string) => {
    this.dropdownId = dropdownId
  }
  setRequiredAdvancedSchedule = (value: boolean) => {
    this.isRequiredAdvancedSchedule = value
  }
  setSchedulingRecipientsMax = (value: number) => {
    this.schedulingRecipientsMax = value
  }
  setSchedulingRecipients = (value: number) => {
    this.schedulingRecipients = value
  }
}
