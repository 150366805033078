import { makeAutoObservable, reaction } from 'mobx'
import { debounce } from 'lodash'
import { conversationStore } from 'entities/Conversation'
import { inboxesStore } from 'entities/Inbox'
import { Contact } from 'entities/Contacts/model/Contact'
import { IParamsConversationsSearch } from 'entities/Conversation/api/types'
import { Tag } from 'entities/Tags/model/Tag'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { User } from 'entities/Users/model/User'
import { SearchDropdownStore, SearchTypesEnum } from 'widgets/SearchDropdown'
import { IConversationSearchStoreConfig } from './types'

export class ConversationSearchStore {
  private _nextPage = 1
  private _perPage = 25
  private _total = 0

  private _opened = false
  private _selectedTypes: SearchTypesEnum[] = []
  private _selectedSearch = ''
  private _newType: SearchTypesEnum | null = null
  private _isDirtyFilter = false
  private _debounceFetch
  private _onHasFilters: IConversationSearchStoreConfig['onHasFilters'] | null = null
  private _onResetConversations: IConversationSearchStoreConfig['onResetConversations'] | null =
    null
  private _onLoadConversations: IConversationSearchStoreConfig['onLoadConversations'] | null = null
  private _onSearchConversations: IConversationSearchStoreConfig['onSearchConversations'] | null =
    null

  private _searchDropdownStore = new SearchDropdownStore({
    includeItemsType: [
      SearchTypesEnum.contacts,
      SearchTypesEnum.tags,
      SearchTypesEnum.teams,
      SearchTypesEnum.members,
      SearchTypesEnum.date,
    ],
  })

  constructor(config?: IConversationSearchStoreConfig) {
    makeAutoObservable(this)

    this.setConfig(config)
    this._debounceFetch = debounce(this.resetSearch, 500)
    this._searchDropdownStore.initOptions({
      selectType: this.selectType,
      resetSearch: this.resetSearch,
      clearFilters: this.clearFilters,
      handleSearch: this.handleSearch,
      handleSelectedSearch: this.handleSelectedSearch,
      selectedMode: true,
    })

    this.reactionOpened()
    this.reactionCurrentInboxId()
    this.reactionCurrentConversationId()
    this.reactionHasFilters()
    this.reactionCurrentType()
    this.reactionSelectedTypes()
    this.reactionNewType()
    this.reactionIsDirtyFilter()
  }

  setConfig = (config?: IConversationSearchStoreConfig) => {
    this._onHasFilters = config?.onHasFilters
    this._onResetConversations = config?.onResetConversations
    this._onLoadConversations = config?.onLoadConversations
    this._onSearchConversations = config?.onSearchConversations
  }

  get isOpened() {
    return this._opened
  }

  get selectedSearch() {
    return this._selectedSearch
  }

  get isActive() {
    return this.isOpened || this.hasSearchParams
  }

  get hasSearchParams() {
    return this._searchDropdownStore.hasSearchParams
  }

  get searchParams() {
    const { searchParams } = this._searchDropdownStore
    const params: IParamsConversationsSearch = {
      ...searchParams,
      page: this._nextPage,
      per_page: this._perPage,
    }

    if (this._searchDropdownStore.search || this._selectedSearch) {
      params.term = this._searchDropdownStore.search || this._selectedSearch
    }

    return params
  }

  get hasMore() {
    return this._total > (this._nextPage - 1) * this._perPage
  }

  get hasSelectedSearch() {
    return !!this._selectedSearch
  }

  handleSelectedSearch = (value: string) => {
    this._selectedSearch = value
    this.resetSearch()
  }

  handleSearch = (value: string) => {
    this._searchDropdownStore.handleSearch(value)
    this._selectedSearch = ''
  }

  clear = (isResearch = true) => {
    this._searchDropdownStore.clearFilters()
    this._searchDropdownStore.handleSearch('')
    this.resetNextPage()
    this._onResetConversations?.()

    if (isResearch) {
      this.resetSearch()
    }
  }

  clearFilters = (isResearch = true) => {
    this._searchDropdownStore.clearFilters()
    this.resetNextPage()
    this._onResetConversations?.()

    if (isResearch) {
      this.resetSearch()
    }
  }

  reset = () => {
    this.clear()
    this._opened = false
  }

  onOpen = () => {
    this._opened = true
  }

  onClose = () => {
    this._opened = false
  }

  onToggle = () => {
    this._opened = !this._opened
  }

  resetNextPage = () => {
    this._nextPage = 1
    this._total = 0
  }

  reactionOpened = () => {
    reaction(
      () => this._opened,
      () => {
        this._searchDropdownStore.setNewType(null)
      }
    )
  }

  reactionCurrentInboxId = () => {
    reaction(
      () => inboxesStore.currentInboxId,
      () => {
        if (this._searchDropdownStore.filtersCount) {
          this.reset()
        }
      }
    )
  }

  reactionCurrentType = () => {
    reaction(
      () => this._searchDropdownStore.currentType,
      (value) => {
        const status =
          this._searchDropdownStore.hasFilters &&
          this._searchDropdownStore.isOpen &&
          value === SearchTypesEnum.main

        this.handleDirtyFilter(status)
      }
    )
  }

  reactionSelectedTypes = () => {
    reaction(
      () => this._searchDropdownStore.selectedTypes,
      (value) => {
        this._selectedTypes = value
      }
    )
  }

  reactionCurrentConversationId = () => {
    reaction(
      () => conversationStore.isNewConversation,
      (value) => {
        if (value && this._searchDropdownStore.filtersCount) this.reset()
      }
    )
  }

  reactionHasFilters = () => {
    reaction(
      () => this._searchDropdownStore.hasFilters,
      (value) => {
        this._onHasFilters?.(value)
      }
    )
  }

  reactionNewType = () => {
    reaction(
      () => this._searchDropdownStore.newType,
      (value) => {
        this._newType = value
      }
    )
  }

  reactionIsDirtyFilter = () => {
    reaction(
      () => this._isDirtyFilter,
      (value) => {
        this._searchDropdownStore.setIsDirtyFilter(value)
      }
    )
  }

  handleDirtyFilter = (value: boolean) => {
    this._isDirtyFilter = value
  }

  selectType = (type: SearchTypesEnum) => {
    this.resetNextPage()
    this._searchDropdownStore.selectTypeParent(type)
  }

  selectTypeItem = (item: Contact | Tag | Inbox | User) => {
    this._searchDropdownStore.toggleTypeItem(item)
    this.resetSearch()
  }

  resetSearch = () => {
    this.resetNextPage()

    if (this.hasSearchParams) {
      this._onSearchConversations?.()
    } else {
      this._onResetConversations?.()
      this._onLoadConversations?.()
    }
  }

  handleTotal(total: number) {
    this._total = total
  }

  handleNextPage(nextPage: number) {
    this._nextPage = nextPage
  }

  resetType() {
    this.resetSearch()
  }

  get searchDropdownStore() {
    return this._searchDropdownStore
  }

  get opened() {
    return this._opened
  }

  get total() {
    return this._total
  }
}
