import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Tabs, ITabItem, Alert, Scrollbar } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { linkContactStore } from '../../store/linkContactStore'
import { LinkManualTab } from './LinkManualTab/LinkManualTab'
import { LinkMatchingTab } from './LinkMatchingTab/LinkMatchingTab'

import styles from './styles.module.scss'

const LinkContactModalContent = observer(() => {
  const { handleActiveTab, activeTab, matchedCount, integrationName } = linkContactStore
  const { isHoverModal } = modalStore

  const tabs: ITabItem[] = [
    {
      name: 'Matching',
      key: 'matching',
    },
    {
      name: 'Link manually',
      key: 'manually',
    },
  ]

  useEffect(() => {
    return () => {
      handleActiveTab('matching')
    }
  }, [])

  return (
    <Scrollbar
      hideTrack={!isHoverModal}
      autoHide={false}
      autoHeight
      autoHeightMin={0}
      autoHeightMax={632}
    >
      <div className={classnames(styles.wrap)}>
        <Alert
          item={{
            desc: `We found ${matchedCount} matching ${
              matchedCount === 1 ? 'contact' : 'contacts'
            } in ${integrationName} with the same phone number`,
            type: 'infoLight',
          }}
        />
        <div className={styles.tabs}>
          <Tabs
            tabs={tabs}
            activeTabKey={activeTab}
            handleSelectTab={(tab) => handleActiveTab(tab.key)}
          />
        </div>
        {activeTab === 'manually' && <LinkManualTab />}
        {activeTab === 'matching' && <LinkMatchingTab />}
      </div>
    </Scrollbar>
  )
})

export { LinkContactModalContent }
