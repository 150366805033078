import { observer } from 'mobx-react-lite'
import { Button, EmptyState } from 'shared/ui'
import { tooltipText } from 'shared/constants/tooltipText'
import { inboxesStore } from 'entities/Inbox'
import { callStore } from 'entities/Call'
import { featureFlagsStore } from 'entities/FeatureFlags'
import styles from 'widgets/CallHistory/ui/styles.module.scss'
import { CallHistoryHeader, useCallHistoryContext } from 'widgets/CallHistory'

export const EmptyStateCallHistory = observer(() => {
  const { callModalStore } = useCallHistoryContext()
  const isPFTInbox = inboxesStore.currentInbox?.isPFTInbox
  const inteliquentCalling = featureFlagsStore.inteliquent_calling
  const phoneType = inboxesStore.nonPFTInboxNumberType
  const onClick = () => {
    callModalStore.openModal()
  }

  return (
    <div className={styles.wrap}>
      <CallHistoryHeader isShowSearch={false} />
      <EmptyState
        iconProps={{ icon: 'phone', color: 'var(--content-neutral-primary)' }}
        title={'Make your first call'}
        text={'Engage with your contacts'}
        actions={
          <Button
            text={'Make a call'}
            onClick={onClick}
            iconProps={{ icon: 'phone' }}
            size={'medium'}
            disabled={(isPFTInbox && !inteliquentCalling) || !callStore.isStatusClosed}
            tooltipProps={
              isPFTInbox && !inteliquentCalling
                ? {
                    label: tooltipText.disabledCall(phoneType),
                    placement: 'right',
                    fullWidth: true,
                  }
                : undefined
            }
          />
        }
      />
    </div>
  )
})
