import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { SpinnerLoader } from 'shared/ui'
import { authStore } from 'entities/Auth'
import { websocket } from 'entities/WebSocket'
import { CommonComponents } from 'app/layouts/CommonComponents'

type IAuthRefreshLayoutProps = {
  type: 'react' | 'vue'
}

const AuthRefreshLayout: FC<IAuthRefreshLayoutProps> = observer(({ type }) => {
  const { refresh, error } = authStore
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    refresh()
      .then((data) => {
        if (data?.is_login) {
          return websocket.connect(localStorage.getItem('token'))
        }
      })
      .then(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className={'spinner'}>
        <SpinnerLoader />
      </div>
    )
  }

  if (error) {
    return <>{error}</>
  }

  return (
    <>
      <CommonComponents type={type} />
      <Outlet />
    </>
  )
})

export default AuthRefreshLayout
