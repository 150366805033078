// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HfpjbzDGHlQLPBSRbP7S{position:relative}.kFfTvd6PjiDRTT4vrXug{border-bottom:none}.w0OOPIroi8RCyYcs1A6a{margin-top:4px}", "",{"version":3,"sources":["webpack://./src/shared/ui/SearchDropdownInput/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAEF,sBACE,kBAAA,CAEF,sBACE,cAAA","sourcesContent":[".dropdownContent {\n  position: relative;\n}\n.dropdownTextInput {\n  border-bottom: none;\n}\n.error {\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownContent": "HfpjbzDGHlQLPBSRbP7S",
	"dropdownTextInput": "kFfTvd6PjiDRTT4vrXug",
	"error": "w0OOPIroi8RCyYcs1A6a"
};
export default ___CSS_LOADER_EXPORT___;
