import { PropsWithChildren, createContext, useContext } from 'react'
import { FilterEditorStore } from 'widgets/FilterEditor/store'

const FilterEditorContext = createContext<FilterEditorStore | null>(null)

export const useFilterEditorContext = () => {
  const context = useContext(FilterEditorContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with FilterEditorProvider')

  return context
}

export const FilterEditorProvider = ({
  store,
  children,
}: PropsWithChildren<{ store: FilterEditorStore }>) => {
  return <FilterEditorContext.Provider value={store}>{children}</FilterEditorContext.Provider>
}
