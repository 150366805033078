import { makeAutoObservable } from 'mobx'
import {
  SMSMessageFieldStore,
  type ISMSMessageFieldStoreProps,
} from 'widgets/MessageFormFields/SMSMessageField/store/SMSMessageFieldStore'

export class TriggerSMSStore {
  smsMessageFieldStore: SMSMessageFieldStore
  constructor(props: ISMSMessageFieldStoreProps) {
    this.smsMessageFieldStore = new SMSMessageFieldStore(props)
    makeAutoObservable(this)
  }

  get messageDataParams() {
    return this.smsMessageFieldStore.messageDataParams
  }

  get messageFieldStore() {
    return this.smsMessageFieldStore.messageFieldStore
  }

  get isError() {
    return this.smsMessageFieldStore.isError
  }

  get segments_count() {
    return this.smsMessageFieldStore.segments_count
  }
}
