import { Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { WorkflowListPage } from 'pages/workflows/pages'
import { WorkflowLayout } from 'pages/workflows/layout'

export const WorkflowsRoutes = observer(() => (
  <Routes>
    <Route element={<WorkflowLayout />}>
      <Route index element={<WorkflowListPage />} />
    </Route>
  </Routes>
))
