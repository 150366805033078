import {
  IMainDropdownContentItem,
  IDateDropdownContentItem,
  SearchTypesEnum,
  SearchDateEnum,
} from '../../SearchDropdown'

export const mainDropdownContentItems: IMainDropdownContentItem[] = [
  {
    id: 1,
    label: 'Contact',
    icon: 'users',
    type: SearchTypesEnum.contacts,
  },
  {
    id: 2,
    label: 'Tag',
    icon: 'tag',
    type: SearchTypesEnum.tags,
  },
  {
    id: 3,
    label: 'Inbox',
    icon: 'all_inboxes',
    type: SearchTypesEnum.teams,
  },
  {
    id: 4,
    label: 'Assignee',
    icon: 'userFilled',
    type: SearchTypesEnum.members,
  },
  {
    id: 5,
    label: 'Created date',
    icon: 'calendar',
    type: SearchTypesEnum.date,
  },
  {
    id: 6,
    label: 'Segment',
    icon: 'segment',
    type: SearchTypesEnum.segment,
  },
]

export const dateDropdownContentItems: IDateDropdownContentItem[] = [
  {
    id: 1,
    label: 'Today',
    type: SearchDateEnum.today,
  },
  {
    id: 2,
    label: 'Yesterday',
    type: SearchDateEnum.yesterday,
  },
  {
    id: 3,
    label: 'Last 30 days',
    type: SearchDateEnum.last30days,
  },
  {
    id: 4,
    label: 'Last 90 days',
    type: SearchDateEnum.last90days,
  },
  {
    id: 5,
    label: 'Custom',
    type: SearchDateEnum.custom,
  },
]
