// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yzl68546Rh3WQVo3T0DU{display:flex;flex-direction:column;gap:16px}.N6LjT2NFqe1SiqVDrq97{padding-top:8px}.P3eWCpDER3nINayM9K9G{display:flex;flex-direction:column;gap:4px}.INVuCS7PdDZBReTkH0Hv{padding-left:12px}", "",{"version":3,"sources":["webpack://./src/widgets/NewConversion/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEF,sBACE,eAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,sBACE,iBAAA","sourcesContent":[".content{\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.wrapName{\n  padding-top: 8px;\n}\n.wrapField{\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n.input{\n  padding-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Yzl68546Rh3WQVo3T0DU",
	"wrapName": "N6LjT2NFqe1SiqVDrq97",
	"wrapField": "P3eWCpDER3nINayM9K9G",
	"input": "INVuCS7PdDZBReTkH0Hv"
};
export default ___CSS_LOADER_EXPORT___;
