import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventTyping } from 'entities/Typing'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export const EventTyping = {
  subscribe: (handler: (data: IResponseEventTyping) => void) =>
    websocket.on(WebsocketEvent.ClientTyping, handler),
  unsubscribe: (handler: (data: IResponseEventTyping) => void) =>
    websocket.off(WebsocketEvent.ClientTyping, handler),
}

export const useEventTyping = (conversationMessageStore: ConversationMessagesStore) =>
  useEffect(
    () =>
      EventTyping.subscribe((data) => {
        conversationMessageStore.handleTypingEvent(data)
      }),
    []
  )
