import { makeAutoObservable, runInAction } from 'mobx'
import { logger } from 'shared/lib'
import type { IResponseIntegration, IResponseUserIntegration } from 'entities/Integrations'
import { IntegrationsApi } from 'entities/Integrations/api/integrations'
import { IntegrationKey } from 'entities/Integrations/api/types'
import { Integration } from 'entities/Integrations/model/Integration'

export class IntegrationsStore {
  private _integrationsMap: Map<string, Integration> = new Map()
  private _loading = false
  private _requestGetIntegrations: null | ReturnType<(typeof IntegrationsApi)['getIntegrations']> =
    null

  constructor() {
    makeAutoObservable(this)
  }

  fetchIntegrations = async () => {
    if (this.hasIntegrations) return
    if (this._requestGetIntegrations) return this._requestGetIntegrations.then()

    try {
      runInAction(() => {
        this._loading = true
      })

      this._requestGetIntegrations = IntegrationsApi.getIntegrations()

      const {
        data: { data: integrations },
      } = await this._requestGetIntegrations

      integrations.map(this._addOrUpdateIntegration)
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  private _addOrUpdateIntegration = (item: IResponseIntegration) => {
    const integration = this._integrationsMap.get(item.key)

    if (integration) {
      integration.syncOrigin(item)

      return integration
    } else {
      const integration = new Integration(item)
      this._integrationsMap.set(integration.key, integration)

      return integration
    }
  }

  syncUserIntegration = (item: IResponseUserIntegration) => {
    this._integrationsMap.get(item.integration.key)?.syncUserIntegration(item)
  }

  getIntegration = (key?: IntegrationKey | string | null) => {
    if (!key) return null

    return this._integrationsMap.get(key) || null
  }

  getIntegrationById = (id: number) => {
    return this.integrations.find((integration) => integration.id === id)
  }

  getIntegrations = (keys: Array<IntegrationKey>) => {
    return keys
      .map((key) => this._integrationsMap.get(key))
      .filter((item) => Boolean(item)) as Integration[]
  }

  get loading() {
    return this._loading
  }

  get hasIntegrations() {
    return Boolean(this.integrations.length)
  }

  get integrations() {
    return Array.from(this._integrationsMap.values())
  }

  get connectedIntegrations() {
    return Array.from(this._integrationsMap.values()).filter(({ isConnected }) => isConnected)
  }
}

export const integrationsStore = new IntegrationsStore()
