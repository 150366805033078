import { observer } from 'mobx-react-lite'

import { conversationStore } from 'entities/Conversation'
import { NumbersInboxesDropDown } from 'widgets/NumbersInboxesList'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'

import styles from './styles.module.scss'

export const ConversationChooseNumber = observer(() => {
  const { items, handleSetActiveNumber, numbersInboxesListStore } =
    useConversationNewSearchContext()
  const { updateItem, getItem } = conversationStore

  return (
    <div className={styles.wrap}>
      <div className={styles.label}>From:</div>
      <div className={styles.dropdown}>
        <NumbersInboxesDropDown
          store={numbersInboxesListStore}
          contacts={items}
          onChange={(inboxId, numberId) => {
            handleSetActiveNumber(inboxId, numberId)
            const current = getItem(0)

            if (current?.origin) {
              updateItem({ ...current?.origin, inbox_id: inboxId, number_id: numberId })
            }
          }}
        />
      </div>
    </div>
  )
})
