import { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ContactsTableContext, ContactsTableStore } from 'widgets/ContactsTable'
import { ContactsTableContent } from './ContactsTableContent'

type IContactsTableProps = {
  store: ContactsTableStore
}

export const ContactsTable: FC<IContactsTableProps> = observer(({ store }) => {
  return useMemo(() => {
    return (
      <ContactsTableContext.Provider value={store}>
        <ContactsTableContent />
      </ContactsTableContext.Provider>
    )
  }, [])
})
