import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallRecordPopUpButton: React.FC = observer(() => {
  const store = useCallPopUpDefaultContext()
  const { isRecord, isRecordDisabled, handleRecord, innerRefTooltipCurrent } =
    store as CallPopUpDefaultStore

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'red'}
        variant={'custom'}
        active={isRecord}
        disabled={isRecordDisabled}
        ariaLabel={`CallRecordPopUpButton_${isRecord ? 'stop' : 'record'}`}
        onClick={() => {
          handleRecord('toggle')
        }}
        iconProps={{
          icon: 'record',
          animation: isRecord,
        }}
        tooltipProps={{
          zIndex: 5001,
          label: isRecord ? 'Stop call recording' : 'Record call',
          placement: 'top',
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
      />
    </div>
  )
})
