import { makeAutoObservable, runInAction } from 'mobx'
import { AdminComplianceApi } from 'entities/Admin/compliance'
import { adminTenDLCTableStore } from 'pages/admin/pages/compliance/pages/TenDLC'
import { adminTollFreeTableStore } from 'pages/admin/pages/compliance/pages/TollFree'

class AdminComplianceStore {
  constructor() {
    makeAutoObservable(this)
  }

  dlc_services_count = 0
  toll_free_profile_numbers_count = 0

  loadData = () => {
    AdminComplianceApi.getComplianceInReviewCount()
      .then((res) => {
        runInAction(() => {
          this.dlc_services_count = res.data.dlc_services_count
          this.toll_free_profile_numbers_count = res.data.toll_free_profile_numbers_count
        })
      })
      .catch(console.error)
  }

  get dlcCount() {
    return adminTenDLCTableStore.isDefaultStatuses
      ? this.dlc_services_count
      : adminTenDLCTableStore.total
  }

  get tollFreeCount() {
    return adminTollFreeTableStore.isDefaultStatuses
      ? this.toll_free_profile_numbers_count
      : adminTollFreeTableStore.total
  }

  get inReviewCount() {
    return this.dlc_services_count + this.toll_free_profile_numbers_count
  }
}

export const adminComplianceStore = new AdminComplianceStore()
