import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'

export const ImportContactsModalError = observer(() => {
  const { importModalError } = importContactsStore

  if (!importModalError.title && !importModalError.desc) {
    return null
  }
  return (
    <Alert
      item={{
        type: 'alert',
        title: importModalError.title || '',
        desc: importModalError.desc || '',
      }}
    />
  )
})
