/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/display-name */
import { Ref, forwardRef } from 'react'
import { IInputComponent, IInputTextProps } from './types'

const InputTextRef = (props: IInputTextProps, ref: Ref<HTMLInputElement>) => (
  <input ref={ref} {...props} />
)

export const InputText: IInputComponent<IInputTextProps> = forwardRef(InputTextRef)

export const makeInputText: (
  defaults: Partial<IInputTextProps>
) => IInputComponent<IInputTextProps> = (defaults) =>
  forwardRef((props, ref) => InputTextRef({ ...defaults, ...props }, ref))
