// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RUFIV7XnXxnixR2k2Frh{display:flex;gap:24px;flex-direction:column}.MiD2_CBNLIj6FRLhxHoC{display:flex;gap:8px}.z89nXi9tvLOCZM4aR_bI{color:var(--content-primary-primary)}.z89nXi9tvLOCZM4aR_bI span{color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/widgets/ContactChoosePhoneModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CAGF,sBACE,oCAAA,CAEA,2BACE,qCAAA","sourcesContent":[".contentWrap {\n  display: flex;\n  gap: 24px;\n  flex-direction: column;\n}\n\n.actionsWrap {\n  display: flex;\n  gap: 8px;\n}\n\n.dropdown__item {\n  color: var(--content-primary-primary);\n\n  span {\n    color: var(--content-primary-tertiary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrap": "RUFIV7XnXxnixR2k2Frh",
	"actionsWrap": "MiD2_CBNLIj6FRLhxHoC",
	"dropdown__item": "z89nXi9tvLOCZM4aR_bI"
};
export default ___CSS_LOADER_EXPORT___;
