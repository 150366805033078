import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Button } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { adminOrganizationPlanStore } from 'pages/admin/pages/organizations/store/adminOrganizationPlanStore'
import { adminOrganizationCustomPlanStore } from '../../pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanStore'
import { adminOrganizationCustomPlanNewPrice } from '../../pages/organizations/pages/CustomPlan/store/adminOrganizationCustomPlanNewPrice'

interface ISelectPlanProps {
  freeOnly?: boolean
}

export const SelectPlan = observer(({ freeOnly }: ISelectPlanProps) => {
  const { convertedPlansToOptions, fetchPlans } = adminOrganizationPlanStore
  const { selectedPlan, selectPlan, isEdit } = adminOrganizationCustomPlanStore

  const { addNewPrice } = adminOrganizationCustomPlanNewPrice

  useEffect(() => {
    fetchPlans()
  }, [])

  const freeItems = convertedPlansToOptions.filter((item) => item.price === 0)

  return (
    <Grid item xs={6}>
      <SelectField
        labelText={'Selected Plan'}
        value={selectedPlan}
        setValue={selectPlan}
        items={freeOnly ? freeItems : convertedPlansToOptions}
        btnProps={{
          fullWidth: true,
          typeBtn: 'stroke',
          justifyContent: 'space-between',
          fontWeight: !selectedPlan ? 400 : undefined,
          disabled: isEdit,
          style: {
            color: !selectedPlan ? 'var(--content-neutral-primary)' : undefined,
          },
        }}
        dropdownProps={{
          width: 712,
          disabled: isEdit,
          bottomActions: (
            <Button
              text='New custom price'
              typeBtn={'text'}
              fullWidth
              icon='add'
              justifyContent='left'
              onClick={() => {
                addNewPrice()
                const modal = document.querySelector('#portal') as HTMLElement
                modal.click()
              }}
            />
          ),
          ariaLabel: 'SelectPlan',
        }}
      />
    </Grid>
  )
})
