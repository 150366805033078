// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClhN1DQdgMF4Rj1FNSS7{display:flex;padding:6px 8px;white-space:nowrap;gap:4px}.MSY_eLnDaxmKYSG859CV{color:var(--light-blue-50);cursor:pointer}.MSY_eLnDaxmKYSG859CV:hover{color:var(--content-brand-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/MergeField/ui/MergeFieldConnectedTooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,0BAAA,CACA,cAAA,CACA,4BACE,kCAAA","sourcesContent":[".wrap {\n  display: flex;\n  padding: 6px 8px;\n  white-space: nowrap;\n  gap: 4px;\n}\n\n.link{\n  color: var(--light-blue-50);\n  cursor: pointer;\n  &:hover{\n    color: var(--content-brand-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ClhN1DQdgMF4Rj1FNSS7",
	"link": "MSY_eLnDaxmKYSG859CV"
};
export default ___CSS_LOADER_EXPORT___;
