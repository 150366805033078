import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { NameValueRecord, Typography } from 'shared/ui'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import { UiContactsTrigger } from 'widgets/BroadcastView/ui/ContactsContent/ui/UiContactsTrigger'
import { BroadcastContactsStore } from 'widgets/BroadcastView'
import { EnumEnrollmentMode } from 'widgets/ContactEnrollment/store/types'
import styles from './styles.module.scss'

export const SendContent = observer(() => {
  const broadcast = useBroadcastDetailModalContext()
  const [broadcastContactsStore] = useState(() => new BroadcastContactsStore())

  const handleClick = () => {
    broadcastContactsStore.editEnrollment()
  }

  useEffect(() => {
    broadcastContactsStore.loadFilters()
    broadcastContactsStore.init(broadcast.response)
    broadcastContactsStore.enrollmentStore.setMode(EnumEnrollmentMode.ViewOnly)
  }, [])

  return (
    <div className={styles.wrap}>
      <Typography
        ariaLabel={'Send'}
        variant={'body-rg-medium'}
        className={styles.title}
        tag={'div'}
      >
        Send
      </Typography>
      <div className={styles.grid}>
        {broadcast.sendFromNumbersProps?.map((item, index) => (
          <>
            {index === 0 ? (
              <Typography ariaLabel={'Send'} variant={'text-input-field'} className={styles.label}>
                Send from
              </Typography>
            ) : (
              <div />
            )}
            <div className={styles.value}>
              <NameValueRecord {...item} />
            </div>
          </>
        ))}
      </div>
      <div className={styles.grid}>
        <Typography ariaLabel={'Send'} variant={'text-input-field'} className={styles.label}>
          Contacts
        </Typography>
        <UiContactsTrigger
          total={broadcastContactsStore.allTotal}
          skipped={broadcastContactsStore.skipped}
          error={''}
          wrapPlaceholderClassName={styles.wrapPlaceholderClassName}
          onClick={handleClick}
        />
      </div>
    </div>
  )
})
