import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import {
  PresetContainer,
  PresetFooter,
  PresetInfo,
  PresetList,
  PresetListItem,
} from 'shared/Preset'
import { TemplateConfigList } from 'widgets/CreateWorkflowModal/config'
import { type ICreateWorkflowProps } from 'widgets/CreateWorkflowModal/types'

export const WorkflowTemplateModal = observer(
  ({ workflow, onCancel, onNext }: ICreateWorkflowProps) => (
    <PresetContainer>
      <PresetInfo name='Select template' description='You can select only one template' />
      <PresetList>
        {TemplateConfigList.map((template) => (
          <PresetListItem
            key={template.name}
            disabled={template.disabled}
            selected={workflow.templateName === template.name}
            onClick={() => workflow.setTemplateName(template.name)}
            icon={template.icon}
            name={template.name}
            description={template.description}
          />
        ))}
      </PresetList>
      <PresetFooter>
        <Button text='Cancel' size='medium' contained='secondary' onClick={onCancel} />
        <Button text='Next' size='medium' disabled={!workflow.templateName} onClick={onNext} />
      </PresetFooter>
    </PresetContainer>
  )
)
