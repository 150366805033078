export const secondsToTime = (seconds: number, isExpand?: boolean) => {
  if (seconds === Infinity) return ''
  if (isExpand) {
    const minutes = Math.floor(seconds / 60)
    const minutesString = minutes ? `${minutes}m` : ''
    const sec = Math.round(seconds % 60)
    const secondsString = sec ? `${sec}s` : ''
    return `${minutesString} ${secondsString}`.trim()
  }

  const secondsInt = Math.round(seconds)
  const firstPart = Math.floor(secondsInt / 60)
  const reminder = Math.round(secondsInt % 60)
  const secondPart = reminder > 9 ? reminder : `0${reminder}`
  return `${firstPart}:${secondPart}`
}
