import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { errorLogger, globalWatchError } from 'entities/EventLog'
import { ErrorBoundaryFallback } from 'app/layouts/ErrorBoundaryFallback'
import { commonRouterContent } from 'app/appvue/common/router/router'

window.initCommonReact = () => {
  const commonReact = createRoot(document.getElementById('app-common') as HTMLElement)

  globalWatchError()

  if (commonReact) {
    commonReact.render(
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorLogger}>
        <RouterProvider router={commonRouterContent} />
      </ErrorBoundary>
    )
  }
}
