// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lWSsdPxz7ZeEK5dZVwuq{margin-inline:20px}.FM8Q9ILIKXaJVKrLd2Op{display:flex;margin:16px 20px;background:var(--border-neutral-default);height:1px;width:calc(100% - 40px)}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastDetail/BroadcastContactStatistics/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEF,sBACE,YAAA,CACA,gBAAA,CACA,wCAAA,CACA,UAAA,CACA,uBAAA","sourcesContent":[".wrapper{\n  margin-inline: 20px;\n}\n.separator{\n  display: flex;\n  margin: 16px 20px;\n  background: var(--border-neutral-default);\n  height: 1px;\n  width: calc(100% - 40px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "lWSsdPxz7ZeEK5dZVwuq",
	"separator": "FM8Q9ILIKXaJVKrLd2Op"
};
export default ___CSS_LOADER_EXPORT___;
