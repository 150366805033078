import { ReactNode } from 'react'
import { Typography } from 'shared/ui'
import {
  AdminDetailCardRow,
  IAdminDetailCardRowProps,
} from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/AdminDetailCardRow'
import styles from './styles.module.scss'

export type IOrganizationCardProps = {
  title?: string
  padding?: string
  noHeader?: boolean
  data: Array<IAdminDetailCardRowProps>
  additionalTitleContent?: ReactNode
  actions?: ReactNode
  isEdit?: boolean
  cardStyles?: Record<string, string>
}

export const AdminDetailCard = ({
  title,
  data,
  padding,
  additionalTitleContent,
  actions,
  isEdit,
  noHeader,
  cardStyles,
}: IOrganizationCardProps) => {
  return (
    <div style={{ padding, ...cardStyles }}>
      {!noHeader && (
        <div className={styles.header}>
          <div className={styles.wrapTitle}>
            <Typography variant={'heading-sm'} tag={'div'} ariaLabel={'title'}>
              {title}
            </Typography>
            {additionalTitleContent}
          </div>
          {actions && <div className={styles.actions}>{actions}</div>}
        </div>
      )}

      <div className={styles.grid}>
        {data.map((item) => (
          <AdminDetailCardRow key={item.title} {...item} isEdit={isEdit} />
        ))}
      </div>
    </div>
  )
}
