import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import { IResponseTag, IParamsTagsPerPage, IResponseTagsPerPage } from './types'

class Api {
  getAllTags(): Promise<AxiosResponse<IResponseTagsPerPage>> {
    const params = {
      page: 1,
      sortBy: 'created_at',
      sortOrder: 'desc',
      length: 1000,
    }

    return baseApi.get('tags', { params })
  }

  getTagById(id: number): Promise<AxiosResponse<IResponseTag>> {
    return baseApi.get(`tags/${id}`)
  }

  getTagsPerPage(
    params: IParamsTagsPerPage,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseTagsPerPage>> {
    return baseApi.get('tags/paginated', { ...options, params })
  }

  async getTagsById(ids: number[]): Promise<IResponseTag[]> {
    const responses = await Promise.all(ids.map((id) => this.getTagById(id)))

    return responses.map((response) => response.data)
  }

  createTag({ label }: { label: string }): Promise<AxiosResponse<IResponseTag>> {
    return baseApi.post('tags', { label })
  }
}

export const TagsApi = new Api()
