import { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import type { IChatbotFullData } from 'entities/Chatbot'
import { ChatbotEnrollModalStore } from 'widgets/ChatbotEnroll/store/ChatbotEnrollModalStore'

import { ChatbotEnrollModalFilters } from './ChatbotEnrollModalFilters'
import { ChatbotEnrollModalSearch } from './ChatbotEnrollModalSearch'
import styles from './styles.module.scss'

type IChatbotEnrollModalProps = {
  contactIds: number[]
  onClose: () => void
}

export const ChatbotEnrollModal = observer(({ contactIds, onClose }: IChatbotEnrollModalProps) => {
  const store = useMemo(() => new ChatbotEnrollModalStore(), [])
  const [chatbot, setChatbot] = useState<IChatbotFullData | null>(null)
  const [applyFilters, setApplyFilters] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const enrollDisabled = !chatbot

  const handleEnroll = () => {
    if (!chatbot) return

    setLoading(true)
    store.enrollContacts(chatbot.id, contactIds, applyFilters).then((success) => {
      setLoading(false)
      if (success) onClose()
    })
  }

  return (
    <div className={styles.content}>
      <div className={styles.contentRows}>
        <div className={styles.contentRow}>
          <span className={styles.contentRowTitle}>Textbot</span>
          <ChatbotEnrollModalSearch onSelect={setChatbot} />
        </div>
        <div className={styles.contentRow}>
          <ChatbotEnrollModalFilters value={applyFilters} onChange={setApplyFilters} />
        </div>
      </div>
      <div className={styles.contentFooter}>
        <Button
          size='medium'
          text='Cancel'
          contained='secondary'
          disabled={loading}
          onClick={onClose}
        />
        <Button
          size='medium'
          text='Enroll'
          loading={loading}
          disabled={enrollDisabled}
          onClick={handleEnroll}
        />
      </div>
    </div>
  )
})
