import { MenuItem, Select } from '@mui/material'
import styled from '@emotion/styled'
import { Icon } from 'shared/ui'
import styles from './styles.module.scss'

interface PaginationSelectProps {
  fromToValue: string
  pageSize: number
  setPageSize: (newSize: number) => void
  paginationCountList: number[]
}

const StyledSelect = styled(Select)(() => ({
  marginLeft: '8px',
  marginRight: '6px',
  boxShadow: 'none',
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '4.5px 8px',
  },
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
}))

const StyledMenuItem = styled(MenuItem)(() => ({
  margin: '0 4px',
  width: '56px',
  fontSize: '13px',
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'space-between',
  boxSizing: 'content-box',
  padding: '8px',
}))

export const PaginationSelect = ({
  fromToValue,
  pageSize,
  setPageSize,
  paginationCountList,
}: PaginationSelectProps) => {
  return (
    <StyledSelect
      classes={{
        select: styles.select,
      }}
      IconComponent={() => null}
      value={1}
      onChange={({ target }) => setPageSize(Number(target.value))}
    >
      <StyledMenuItem disabled>Rows</StyledMenuItem>
      <StyledMenuItem style={{ display: 'none' }} value={1}>
        {fromToValue}
      </StyledMenuItem>

      {paginationCountList.map((count) => (
        <StyledMenuItem key={count} value={count}>
          {count} {pageSize === count && <Icon icon='check1' color='var(--light-blue-60)' />}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  )
}
