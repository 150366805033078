import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { authStore } from 'entities/Auth'

export default observer(() => {
  useEffect(() => {
    if (authStore.isLoggedIn) {
      if (authStore.closeAfterLogin) {
        window.close()
      } else {
        const searchParams = new URLSearchParams(window.location.search)
        const redirectUrl = searchParams.get('redirect')
        const decodedRedirectUrl = redirectUrl ? decodeURIComponent(redirectUrl) : '/conversations'

        window.location.href = decodedRedirectUrl
      }
    }
  }, [authStore.isLoggedIn])

  return <Outlet />
})
