import { IStatusConfig, IStatusProps } from 'shared/ui/Status'
import { ITenDLCStatus } from 'entities/Compliance'

export const tenDLCStatusConfig = {
  empty: {
    title: 'Empty',
    icon: 'minusCircle',
    emphasis: 'high',
    intent: 'neutral',
  } as IStatusProps,
  approved: {
    title: 'Approved',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
  failed: {
    title: 'Rejected',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  rejected_by_twilio: {
    title: 'Rejection (Twilio)',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  rejected_by_tcr: {
    title: 'Rejection (TCR)',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  rejected_by_admin: {
    title: 'Rejection (internal)',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  expired: {
    title: 'Expired',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  internal_review: {
    title: 'Internal review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'purple',
  } as IStatusProps,
  pending: {
    title: 'Pending',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
  under_review: {
    title: 'Carrier review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
  twilio_review: {
    title: 'Twilio review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
  tcr_review: {
    title: 'TCR review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
  share_added: {
    title: 'Share request',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
  vendor_import_started: {
    title: 'Twilio import review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
}

const tenDLCBusinessProfileStatuses: Array<ITenDLCStatus> = [
  'empty',
  'internal_review',
  'pending',
  'twilio_review',
  'approved',
  'failed',
  'rejected_by_twilio',
  'rejected_by_admin',
]
const tenDLCBrandStatuses: Array<ITenDLCStatus> = [
  'empty',
  'internal_review',
  'pending',
  'twilio_review',
  'tcr_review',
  'approved',
  'failed',
  'rejected_by_twilio',
  'rejected_by_tcr',
  'rejected_by_admin',
]
const tenDLCCampaignStatuses: Array<ITenDLCStatus> = [
  'empty',
  'internal_review',
  'pending',
  'twilio_review',
  'tcr_review',
  'share_added',
  'vendor_import_started',
  'approved',
  'failed',
  'rejected_by_twilio',
  'rejected_by_tcr',
  'rejected_by_admin',
]

const getConfig = (statuses: Array<ITenDLCStatus>) =>
  statuses.reduce((acc, item) => {
    if (item && tenDLCStatusConfig[item]) {
      return { ...acc, [item]: tenDLCStatusConfig[item] }
    }
    return acc
  }, {} as IStatusConfig)

export const tenDLCBusinessProfileStatusConfig = getConfig(tenDLCBusinessProfileStatuses)
export const tenDLCBrandStatusConfig = getConfig(tenDLCBrandStatuses)
export const tenDLCCampaignStatusConfig = getConfig(tenDLCCampaignStatuses)
