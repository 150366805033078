// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aOjtJYJ03f9W7veu0NnB{margin-inline:8px;padding:2px 2px 2px 4px;border-radius:6px;background:var(--border-neutral-tertiary);display:flex;align-items:center;justify-content:space-between}.PLK1iSZiMf1YNbaWLtnb{display:grid;grid-template-columns:max-content auto;gap:8px;margin-right:10px}.gQ5s5Zbap7dRnmOJsqaY{border-radius:4px;background:var(--gray-50);display:flex;padding:3.5px 8px;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/widgets/MessageField/ui/EditMessageHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,uBAAA,CACA,iBAAA,CACA,yCAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAEF,sBACE,YAAA,CACA,sCAAA,CACA,OAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,yBAAA,CACA,YAAA,CACA,iBAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  margin-inline: 8px;\n  padding: 2px 2px 2px 4px;\n  border-radius: 6px;\n  background: var(--border-neutral-tertiary);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.left{\n  display: grid;\n  grid-template-columns: max-content auto;\n  gap: 8px;\n  margin-right: 10px;\n}\n\n.edit{\n  border-radius: 4px;\n  background: var(--gray-50);\n  display: flex;\n  padding: 3.5px 8px;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "aOjtJYJ03f9W7veu0NnB",
	"left": "PLK1iSZiMf1YNbaWLtnb",
	"edit": "gQ5s5Zbap7dRnmOJsqaY"
};
export default ___CSS_LOADER_EXPORT___;
