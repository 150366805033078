import { TextField, makeTextFieldInteractions, makeTextFieldTooltip } from 'shared/ui/TextField'
import { IOpportunity } from 'entities/Integrations'
import { IOpportunitiesRowType } from './OpportunitiesTab'

import styles from '../styles.module.scss'

type IDataType = {
  id: string
  label: string
  value: string | number | null
}

type IDealsRowProps = {
  opportunity: IOpportunity
  rows: IOpportunitiesRowType[]
}

const OpportunitiesRow = ({ opportunity, rows }: IDealsRowProps) => {
  const rowsData = rows.reduce((data: IDataType[], type) => {
    if (type === 'amount') {
      data.push({
        id: type,
        label: 'Amount',
        value: opportunity.amount || null,
      })
    }

    if (type === 'owner') {
      data.push({
        id: type,
        label: 'Owner',
        value: opportunity.owner || null,
      })
    }

    if (type === 'stage') {
      data.push({
        id: type,
        label: 'Stage',
        value: opportunity.stage || null,
      })
    }

    return data
  }, [])

  return (
    <div className={styles.opportunitiesWrapper}>
      {rowsData.map((item) => (
        <div className={styles.itemRow} key={item.id}>
          <div className={styles.label}>{item.label}</div>
          <TextField
            className={styles.value}
            size='small'
            variant='outline'
            readOnly
            value={item.value != null ? String(item.value) : '-'}
            rightActions={[makeTextFieldInteractions({ type: 'copy', active: !!item.value })]}
            mainActions={[makeTextFieldTooltip()]}
          />
        </div>
      ))}
    </div>
  )
}

export { OpportunitiesRow }
