import { observer } from 'mobx-react-lite'
import { Dropdown } from 'shared/ui'
import { conversationStore } from 'entities/Conversation'
import { useConversationListContext } from 'widgets/ConversationList/context'
import { DropdownActionsChildren } from './DropdownActionsChildren'

export const DropdownActions = observer(() => {
  const { currentItem } = conversationStore
  const store = useConversationListContext()
  const { isSelectedNeedResponse } = store

  if (!currentItem) return null

  return (
    <Dropdown
      ariaLabel={'DropdownActions'}
      width={isSelectedNeedResponse ? 179 : 224}
      icon={'moreHorizontal'}
      sizeTriggerButton='medium'
      placement={'bottom-start'}
      iconButtonProps={{
        transparentInactive: true,
        ariaLabel: 'DropdownActions_trigger',
      }}
      tooltipProps={{
        disableInteractive: true,
        label: 'Show actions',
        placement: 'top-end',
      }}
      customComponent={(onCloseMenu, dropdownId) => (
        <DropdownActionsChildren parentDropdownId={dropdownId} onCloseMenu={onCloseMenu} />
      )}
    />
  )
})
