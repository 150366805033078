import { makeAutoObservable, runInAction } from 'mobx'
import { FeaturesFlagsApi } from 'entities/FeatureFlags'

class FeatureFlagsStore {
  constructor() {
    makeAutoObservable(this)
  }

  is_init = false

  loading = false
  groups_flow = false
  workflows = false
  call_history = false
  message_deduplication = false
  call_distribution = false
  allNumbersCalls = false
  chatbot = false
  show_new_design_only = false
  call_via_aircall = false
  media_library_v2 = false
  inteliquent_calling = false
  rag = false
  hubspot_pin_list = false

  request: null | ReturnType<(typeof FeaturesFlagsApi)['getFeatureFlags']> = null

  fetchFeatureFlags = async () => {
    try {
      if (this.is_init) return
      if (this.request) return this.request.then()
      this.loading = true
      this.request = FeaturesFlagsApi.getFeatureFlags()

      const { data: featureFlags } = await this.request

      runInAction(() => {
        this.is_init = true
        this.groups_flow = featureFlags.groups_flow
        this.workflows = featureFlags.workflows
        this.call_history = featureFlags.call_history
        this.message_deduplication = featureFlags.message_deduplication
        this.call_distribution = featureFlags.call_distribution
        this.allNumbersCalls = featureFlags.allNumbersCalls
        this.chatbot = featureFlags.chatbot
        this.show_new_design_only = featureFlags.show_new_design_only
        this.call_via_aircall = featureFlags.call_via_aircall
        this.media_library_v2 = featureFlags.media_library_v2
        this.inteliquent_calling = featureFlags.inteliquent_calling
        this.rag = featureFlags.rag
        this.hubspot_pin_list = featureFlags.hubspot_pin_list
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = true
      })
    }
  }

  get canOpenWorkflow() {
    return this.workflows
  }
}

export const featureFlagsStore = new FeatureFlagsStore()
