import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventAttachmentUploadError } from 'entities/Attachment/api/types'

type IHandlerEvent = (data: IResponseEventAttachmentUploadError) => void

export const EventAttachmentUploadError = {
  subscribe: (handler: IHandlerEvent) =>
    websocket.on(WebsocketEvent.AttachmentUploadError, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.AttachmentUploadError, handler),
}

export const useEventAttachmentUploadError = (handler: IHandlerEvent) =>
  useEffect(() => EventAttachmentUploadError.subscribe(handler), [])
