import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { EnumDropdownItemVariant, toastStore, type IDropdownItem } from 'shared/ui'
import { logger } from 'shared/lib'
import type {
  IIrsEinDocument,
  IResponseCountryState,
  IResponseEinItem,
} from 'entities/Compliance/api/complianceType'
import { ComplianceApi } from 'entities/Compliance'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { type IResponseMedia } from 'entities/Attachment'
import { EinItem } from 'pages/settings/pages/compliance/ui/EinItem/EinItem'
import { ComplianceLayerStore } from 'pages/settings/pages/compliance/store/complianceLayerStore'
import styles from './styles.module.scss'

const maxZipLength = 5
const maxPostCodeLength = 6

export class GeneralInfoStore {
  constructor(private _complianceLayerStore: ComplianceLayerStore) {
    makeAutoObservable(this)

    this.init()
  }

  private _errorMessage = ''
  private _zipLocalErrorMessage: string | null = null
  private _einItem: IResponseEinItem | null = null
  private _einItems: IResponseEinItem[] = []
  private _einSearch = ''
  private _irsEinAttachment: IIrsEinDocument | null = null
  private _attachmentError: {
    title?: string
    desc?: string
  } | null = null
  private _attachmentErrorTimeout: number | null = null
  private _filePreview: File | null = null
  private _isNoEinResults = false
  private _activeTab = 1
  private _states: IResponseCountryState[] = []

  get maxEinLength() {
    return this.isUsaLocation ? 9 : 10
  }

  get einItem() {
    return this._einItem
  }

  get isAutoModeFullEin() {
    return this._einSearch.length === 9
  }

  get errorMessage() {
    return this._errorMessage
  }

  get zipLocalErrorMessage() {
    return this._zipLocalErrorMessage
  }

  get einItemList(): IDropdownItem[] {
    if (this._einItems.length) {
      return [
        {
          id: 0,
          label: this._einItems[0].name,
          className: styles.einItem,
          variant: EnumDropdownItemVariant.Custom,
          renderOption: () => <EinItem einItem={this._einItems[0]} />,
        },
      ]
    }

    return []
  }

  get einSearch() {
    if (this._einSearch.length <= 2) {
      return this._einSearch
    }

    return `${this._einSearch.slice(0, 2)}-${this._einSearch.slice(2)}`
  }

  get einFromSearch() {
    return this._einSearch
  }

  get irsEinAttachment() {
    return this._irsEinAttachment
  }

  get attachmentData() {
    if (this._filePreview) return new Attachment({ file: this._filePreview, loading: true })
    if (!this._irsEinAttachment) return null

    return new Attachment({
      responseMedia: {
        id: this._irsEinAttachment.id,
        size: this._irsEinAttachment.size,
        source: this._irsEinAttachment.source_url,
        name: this._irsEinAttachment.title,
        content_type: this._irsEinAttachment.extension,
      } as IResponseMedia,
    })
  }

  get isUsaLocation() {
    return this._complianceLayerStore.businessInformation?.isUsaLocation || false
  }

  get generalInfoActiveTab() {
    return this._activeTab
  }

  get isZipFulled() {
    return this.isUsaLocation
      ? this._einItem?.zip?.length === maxZipLength
      : this._einItem?.zip?.length === maxPostCodeLength
  }

  get isManuallyEinItemFulled() {
    const keys = ['city', 'name', 'state', 'street1'] as const
    const isEinFulled = this.isUsaLocation
      ? this._einSearch.length === 9
      : this._einSearch.length >= 9

    if (!isEinFulled || !this.isZipFulled) return false

    return keys.every((key) => this._einItem?.[key]?.length)
  }

  get zipCode() {
    const zip = this._einItem?.zip

    if (this.isUsaLocation || !zip) {
      return zip
    }

    return zip.length < 4 ? zip : `${zip.slice(0, 3)} ${zip.slice(3)}`
  }

  get attachmentError() {
    return this._attachmentError
  }

  get isNextDisabled() {
    if (this.generalInfoActiveTab === 2 || !this.isUsaLocation) {
      return !this.isManuallyEinItemFulled
    }
    if (this.generalInfoActiveTab === 1) {
      return !this.isAutoModeFullEin || !this._einItem
    }
    return false
  }

  get isNoEinResults() {
    return this._isNoEinResults
  }

  get states(): IDropdownItem[] {
    return this._states.map((item) => ({ label: item.name, id: item.id }))
  }

  init = () => {
    this.loadBusinessProfileStates()
  }

  setStates = (states: IResponseCountryState[]) => {
    this._states = states
  }

  loadBusinessProfileStates = async () => {
    try {
      const { data } = await ComplianceApi.getBpStates()

      const states = data.filter(
        (item) => item.country_id === this._complianceLayerStore.businessInformation?.country
      )

      this.setStates(states)
    } catch (e) {
      console.error(e)
    }
  }

  setGeneralInfoActiveTab = (value: number) => {
    this._activeTab = value
  }

  changeGeneralInfoTab = (value: number) => {
    const isEinNumberExist = this._complianceLayerStore.businessInformation?.registration_number

    if (!isEinNumberExist && value === 1) {
      this.resetData()
    }
    this.setGeneralInfoActiveTab(value)
  }

  setEinItem = (value: IResponseEinItem | null) => {
    this._einItem = value
  }

  setFirstEinItem = () => {
    if (this._einItems.length) {
      this.setEinItem(this._einItems[0])
    }
  }

  addEinManually = (ein: string) => {
    this._einSearch = ein
  }

  deleteErrorValue = (key: string) => {
    this._complianceLayerStore.errorStore.deleteErrorValue(key)
  }
  addBusinessNameManually = (name: string) => {
    this.deleteErrorValue('business_name')

    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }
    this._einItem.name = name
  }

  addBusinessAddressManually = (street: string) => {
    this.deleteErrorValue('street_address_1')

    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }
    this._einItem.street1 = street
  }

  addBusinessAddress2Manually = (street: string) => {
    this.deleteErrorValue('street_address_2')

    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }
    this._einItem.street2 = street
  }

  addBusinessStateManually = (state: string) => {
    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }

    this._einItem.state = state
  }

  addCityManually = (city: string) => {
    this.deleteErrorValue('city')

    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }

    this._einItem.city = city
  }

  addZipManually = (zip: string) => {
    if (!this._einItem) {
      this._einItem = {} as IResponseEinItem
    }

    this.deleteErrorValue('zip_code')
    this.setZipLocalErrorMessage(null)

    if (this.isUsaLocation) {
      const clearedZip = zip.replace(/[^0-9]/g, '')

      if (clearedZip.length > maxZipLength) return

      this._einItem.zip = clearedZip
    } else {
      const clearedZip = zip.replace(/\s+/g, '')

      if (clearedZip.length > maxPostCodeLength) return

      this._einItem.zip = clearedZip
    }
  }

  setZipLocalErrorMessage = (message: string | null) => {
    this._zipLocalErrorMessage = message
  }

  checkZipLengthManually = () => {
    const isUsaLocation = this.isUsaLocation
    const zipLength = this._einItem?.zip?.length

    const validLength = isUsaLocation ? maxZipLength : maxPostCodeLength

    if (zipLength !== validLength) {
      this.setZipLocalErrorMessage('Please enter a valid zip code')
    }
  }

  setIrsEinAttachment = (data: IIrsEinDocument | null) => {
    this._irsEinAttachment = data
  }

  deleteIrsEinAttachment = async () => {
    try {
      await ComplianceApi.deleteBpBusinessInformationEinDocument()

      this.setIrsEinAttachment(null)
    } catch (error) {
      console.log(error)
    }
  }

  setAttachmentError = (error: { title?: string; desc?: string } | null) => {
    this.clearAttachmentError()
    this._attachmentError = error

    if (error === null) return

    const timer = window.setTimeout(() => {
      this._attachmentError = null
    }, 5000)

    this._attachmentErrorTimeout = timer
  }

  clearAttachmentError = () => {
    this._attachmentError = null

    if (this._attachmentErrorTimeout) {
      clearTimeout(this._attachmentErrorTimeout)
    }
  }

  uploadIrsEinAttachment = async (file: File | null) => {
    if (!file) return

    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg']
    const sizeLimits = {
      'image/png': 10 * 1024 * 1024, // 10 MB
      'image/jpeg': 10 * 1024 * 1024, // 10 MB
      'application/pdf': 10 * 1024 * 1024, // 10 MB
    }

    if (!allowedTypes.includes(file.type)) {
      this.setAttachmentError({
        title: 'The file is not supported for upload',
        desc: 'Please upload only: pdf, jpg, png',
      })
      return
    }

    const maxSize = sizeLimits[file.type as keyof typeof sizeLimits]
    if (file.size > maxSize) {
      this.setAttachmentError({
        desc: file.type.includes('image')
          ? 'The image file is too large. It must not exceed 10 MB'
          : 'The file is too large. It must not exceed 10 MB',
      })
      return
    }

    if (this._irsEinAttachment) {
      await this.deleteIrsEinAttachment()

      this.setIrsEinAttachment(null)
    }

    this._filePreview = file

    try {
      const { data } = await ComplianceApi.setBpBusinessInformationEinDocument(file)

      this.setIrsEinAttachment(data)

      toastStore.add({
        title: '1 file uploaded',
        type: 'success',
      })
    } catch (error) {
      logger.error(error)
    } finally {
      this._filePreview = null
    }
  }

  isNeedCheckEin = (stringWithoutDashes: string) => {
    const isUsaLocationAuto = this.isUsaLocation && this.generalInfoActiveTab === 1

    return isUsaLocationAuto && stringWithoutDashes.length === this.maxEinLength
  }

  checkEinNumber = () => {
    if (this._einSearch.length && !this._einItem?.ein) this._fetchEinData(this._einSearch)
  }

  setEinSearch = (search: string) => {
    const stringWithoutDashes = search.replace(/[^0-9]/g, '')

    const isUsaLocationAuto = this.isUsaLocation && this.generalInfoActiveTab === 1
    const isNeedClear = stringWithoutDashes.length < this.maxEinLength && isUsaLocationAuto
    if (isNeedClear) {
      this._einItems = []
      this.setEinItem(null)
    }

    this.setErrorMessage('')
    this._isNoEinResults = false

    if (stringWithoutDashes.length > this.maxEinLength) return

    this._einSearch = stringWithoutDashes

    if (this.isNeedCheckEin(stringWithoutDashes)) {
      this.checkEinNumber()
    }
  }

  setErrorMessage = (message: string) => {
    this._errorMessage = message
  }

  resetData = () => {
    this._einItems = []
    this._einItem = null
    this._einSearch = ''
    this._isNoEinResults = false
    this._errorMessage = ''
    this._zipLocalErrorMessage = null
  }

  private _fetchEinData = async (search: string) => {
    try {
      const { data } = await ComplianceApi.getEinData({ ein: search })

      this._addEin(data.data)
      this.setErrorMessage('')
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          this._isNoEinResults = true
        } else {
          this.setErrorMessage(error.response?.data.message || '')
        }
      }
    }
  }

  private _addEin = (einList: IResponseEinItem[]) => {
    this._einItems = einList
  }
}
