import { IDropdownItem, IIconsVector } from 'shared/ui'
import {
  IResponseConversationsFilter,
  IResponseConversationsFilterKey,
} from 'entities/Conversation/api/types'
import { filtersStore } from 'features/DropdownFilter'

export const FiltersItems = (items: IResponseConversationsFilter[]): IDropdownItem[] => {
  return items.map((item) => {
    let icon: IIconsVector = 'inboxOpen'

    if (item.key === 'open') icon = 'inboxOpen'
    if (item.key === 'unread') icon = 'chatUnread'
    if (item.key === 'assigned') icon = 'userFilled'
    if (item.key === 'mentions') icon = 'users'
    if (item.key === 'unassigned') icon = 'userLine'
    if (item.key === 'scheduled') icon = 'clock'
    if (item.key === 'draft') icon = 'edit'
    if (item.key === 'outbound') icon = 'broadcasts'
    if (item.key === 'closed') icon = 'check2'
    if (item.key === 'pending') icon = 'unanswered'

    return {
      id: item.key,
      label: item.label,
      iconL: icon,
      active: filtersStore.filter?.key === item.key,
    }
  })
}
export const FiltersAction = (item: IDropdownItem) => {
  const id = item.id as IResponseConversationsFilterKey

  if (item.id === filtersStore.filter?.key) return

  filtersStore.setFilter(id)
}
