// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yDUiJy9RjLi9NEHlDY9p{display:flex;align-items:center;gap:8px}.yDUiJy9RjLi9NEHlDY9p .g_DH_9JG2iGGY4q5O4NA{display:flex;align-items:center;justify-content:center;width:16px;height:16px;border-radius:16px;background-color:var(--background-primary-inverted-primary)}.yDUiJy9RjLi9NEHlDY9p .XAveeg56_Nu42aC2FmRN{display:inline-block;width:1px;height:16px;background:var(--border-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/ui/SearchElement/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,4CACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,UAAA,CACA,WAAA,CACA,kBAAA,CAEA,2DAAA,CAGF,4CACE,oBAAA,CACA,SAAA,CACA,WAAA,CACA,wCAAA","sourcesContent":[".filterButtonContent {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  .count {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    width: 16px;\n    height: 16px;\n    border-radius: 16px;\n\n    background-color: var(--background-primary-inverted-primary);\n  }\n\n  .divider {\n    display: inline-block;\n    width: 1px;\n    height: 16px;\n    background: var(--border-neutral-primary);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterButtonContent": "yDUiJy9RjLi9NEHlDY9p",
	"count": "g_DH_9JG2iGGY4q5O4NA",
	"divider": "XAveeg56_Nu42aC2FmRN"
};
export default ___CSS_LOADER_EXPORT___;
