import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'

export const ContactsOptInModalActions = observer(() => {
  const { active } = contactsOptInModalStore

  if (!active) return null

  const { handleSaveUndo, disabled } = active

  return (
    <Button
      typeBtn={'contained'}
      text={'Save'}
      disabled={disabled}
      onClick={() => {
        handleSaveUndo()
      }}
    />
  )
})
