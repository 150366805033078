import { ActionItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { ITrigger } from 'entities/Trigger/model/Trigger'
import { TriggerRouts } from 'entities/Trigger'
import { TriggerActionsStore } from 'widgets/TriggerActions/store/triggerActionsStore'
import { ActiveTriggerAction } from 'widgets/TriggerActions/ui/ActiveTriggerAction/ActiveTriggerAction'

export const triggerActionsStore = new TriggerActionsStore()

const triggerActions = {
  active: (trigger: ITrigger): ActionItem => ({
    dropdownItemComponent: () => (
      <ActiveTriggerAction
        trigger={trigger}
        toggleTriggerStatus={triggerActionsStore.updateStatus}
      />
    ),
    buttonComponent: (
      <ActiveTriggerAction
        trigger={trigger}
        toggleTriggerStatus={triggerActionsStore.updateStatus}
        fullSize
      />
    ),
  }),
  edit: (trigger: ITrigger): ActionItem => ({
    icon: 'edit',
    onAction: () => {
      uiStore.changeRoute({
        path: `/${TriggerRouts.triggers}/${TriggerRouts.edit}/${trigger.id}`,
      })
    },
    text: 'Edit',
  }),
  detail: (trigger: ITrigger): ActionItem => ({
    icon: 'eye',
    onAction: () => triggerActionsStore.onDetails(trigger),
    text: 'Details',
  }),
  webhook: (trigger: ITrigger): ActionItem => ({
    icon: 'link',
    onAction: () => triggerActionsStore.onWebhook(trigger),
    text: 'Webhook',
  }),
  export: (trigger: ITrigger): ActionItem => ({
    icon: 'download',
    onAction: () => triggerActionsStore.onExport(trigger),
    text: 'Export report',
  }),
  duplicate: (trigger: ITrigger): ActionItem => ({
    icon: 'copy',
    onAction: () => {
      uiStore.changeRoute({
        path: `/${TriggerRouts.triggers}/${TriggerRouts.clone}/${trigger.id}`,
      })
    },
    text: 'Duplicate',
  }),
  delete: (trigger: ITrigger, onSuccess?: () => void): ActionItem => ({
    icon: 'delete',
    onAction: () => triggerActionsStore.onDelete(trigger, onSuccess),
    text: 'Delete',
  }),
}

export const getTriggerActions = ({
  trigger,
  onSuccessDelete,
}: {
  trigger: ITrigger
  onSuccessDelete: () => void
}): ActionItem[] => {
  return [
    triggerActions.active(trigger),
    triggerActions.edit(trigger),
    triggerActions.detail(trigger),
    triggerActions.webhook(trigger),
    triggerActions.export(trigger),
    triggerActions.duplicate(trigger),
    triggerActions.delete(trigger, onSuccessDelete),
  ]
}
