import React, { useCallback, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { useBlocker } from 'react-router-dom'
import { Box } from '@mui/material'
import { useLocation } from 'react-router'
import { SpinnerLoader, Tabs, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { links } from 'shared/constants/links'
import { ContactsContent } from 'widgets/BroadcastView'
import { BroadcastSchedule } from 'widgets/BroadcastView/ui/BrooadcastSchedule'
import { BroadcastName } from 'widgets/BroadcastView/ui/BroadastName/BroadcastName'
import { BroadcastSMS } from 'widgets/BroadcastView/ui/BroadcastSMS/BroadcastSMS'
import { SendFromDropdown } from 'widgets/SendFromDropdown'
import { BroadcastActions } from 'widgets/BroadcastView/ui/BroadcastActions/BroadcastActions'
import { AdvancedTrackConversions } from 'widgets/AdvancedTrackConversions/AdvancedTrackConversions'
import { BroadcastRingless } from 'widgets/BroadcastView/ui/BroadcastRingless'
import { BroadcastBadNumbers } from 'widgets/BroadcastView/ui/BroadcastBadNumbers'
import { ScheduleRecurringAlert } from 'widgets/BroadcastView/ui/Alerts/ScheduleRecurringAlert/ScheduleRecurringAlert'
import { DailyLimitAlert } from 'widgets/BroadcastView/ui/Alerts/DailyLimitAlert/DailyLimitAlert'
import { GuidelineAlert, GuidelineMemoryStore } from 'widgets/GuidelineAlert'
import { BroadcastViewPreview } from './BroadcastViewPreview/BroadcastViewPreview'
import { useBroadcastViewContext } from '../context/broadcastViewContext'
import styles from './styles.module.scss'

export const BroadcastViewContent = observer(() => {
  const { state } = useLocation()
  const store = useBroadcastViewContext()
  const blocker = useBlocker(store.isExistChanges)
  const { trackConversionStore, isOpenAdvanced, setOpenAdvanced } = store
  const beforeUnloadHandler = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault()
    event.returnValue = true
  }, [])

  const guidelineStore = useMemo(() => new GuidelineMemoryStore(), [])

  useEffect(() => {
    if (state?.contact_ids) {
      store.broadcastContactsStore.setInitContactIds(state?.contact_ids)
    }
  }, [state])

  useEffect(() => {
    store.broadcastContactsStore.loadFilters()
  }, [])

  useEffect(() => {
    return () => {
      store.reset()
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [])

  useEffect(() => {
    uiStore.setRoutesBlocker(blocker)
    store.handleShowUnsavedModal(blocker)
  }, [blocker])

  useEffect(() => {
    uiStore.setRoutesBlocker(store.isExistChanges ? blocker : null)

    if (store.isExistChanges) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [store.isExistChanges])

  useEffect(() => {
    store.sendFromDropdownStore.handleChange((params) => {
      store.setSendFrom(params)
    })
  }, [])

  useEffect(() => {
    store.sendFromDropdownStore.setSendFrom(store.sendFrom)
  }, [store.sendFrom])

  if (store.loading) {
    return (
      <div className={styles.loading}>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <div
        className={classnames(styles.box, styles.box__main, {
          [styles.box__isRelative]: store.isDraggingCondition,
        })}
      >
        <Typography variant={'body-xs-regular'} className={styles.label} ariaLabel={'NewBroadcast'}>
          {`${store.mode === 'edit' ? 'Edit' : 'New'} broadcast`}
        </Typography>
        {store.mode === 'create' && (
          <GuidelineAlert
            className={styles.guidelineAlert}
            link={links.broadcastGuidelines}
            message='Mass messaging best practices'
            store={guidelineStore}
          />
        )}
        <BroadcastName />
        <div className={styles.tabs}>
          <Tabs
            variant={'simple'}
            tabs={store.tabs}
            activeTabKey={store.type}
            handleSelectTab={store.selectTypeTab}
          />
        </div>
        {store.type === 'sms' && <BroadcastSMS />}
        {store.type === 'ringless' && <BroadcastRingless store={store.broadcastRinglessStore} />}
        <ScheduleRecurringAlert />
        <DailyLimitAlert />
        <div className={styles.section}>
          <div className={styles.section__box}>
            <SendFromDropdown
              store={store.sendFromDropdownStore}
              error={{ message: store.errorsMap.get('sendFrom') }}
              onClearError={() => store.setError('sendFrom', '')}
            />
          </div>

          <ContactsContent />
          <BroadcastBadNumbers store={store.badNumberStore} />
          <Box height={24} />

          <div className={styles.section__box}>
            <BroadcastSchedule />
          </div>

          {store.type === 'sms' && (
            <>
              <Box height={26} />
              <AdvancedTrackConversions
                trackConversionStore={trackConversionStore}
                isOpenAdvanced={isOpenAdvanced}
                setOpenAdvanced={setOpenAdvanced}
              />
            </>
          )}

          <BroadcastActions />
        </div>
      </div>

      <div className={classnames(styles.box, styles.box__preview)}>
        <BroadcastViewPreview />
      </div>
    </div>
  )
})
