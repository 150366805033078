import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { ITabItem, Tabs } from 'shared/ui'

import { TabEnroll } from '../TabEnroll'
import { TabConversation } from '../TabConversation'
import { TabObjective } from '../TabObjective'
import { TabActions } from '../TabActions'
import styles from './styles.module.scss'

enum DetailsTabEnum {
  Enroll = 'enroll',
  Conversation = 'conversation',
  Objective = 'objective',
  Actions = 'actions',
}

const TABS = [
  {
    key: DetailsTabEnum.Enroll,
    name: 'Enroll your contacts',
  },
  {
    key: DetailsTabEnum.Conversation,
    name: 'Style your conversation',
  },
  {
    key: DetailsTabEnum.Objective,
    name: 'Set your objective',
  },
  {
    key: DetailsTabEnum.Actions,
    name: 'Actions',
  },
] as ITabItem<DetailsTabEnum>[]

const getBabContent = (tab: DetailsTabEnum) => {
  switch (tab) {
    case DetailsTabEnum.Enroll:
      return TabEnroll
    case DetailsTabEnum.Conversation:
      return TabConversation
    case DetailsTabEnum.Objective:
      return TabObjective
    case DetailsTabEnum.Actions:
      return TabActions
    default:
      return () => null
  }
}

export const DetailsTabs = observer(() => {
  const [activeTab, setActiveTab] = useState(DetailsTabEnum.Enroll)
  const TabContent = useMemo(() => getBabContent(activeTab), [activeTab])

  const activateTab = (tab: ITabItem<DetailsTabEnum>) => setActiveTab(tab.key)

  return (
    <div className={styles.detailsTabs}>
      <Tabs tabs={TABS} activeTabKey={activeTab} handleSelectTab={activateTab} />
      <TabContent />
    </div>
  )
})
