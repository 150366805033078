import SwiperCore from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react'
import 'swiper/swiper.scss'
import { useState } from 'react'
import { Typography, Icon, IconButton, Status } from 'shared/ui'
import achievementsImg from 'shared/assets/images/auth/achievements.png'
import { CDN_FRONT_URL } from 'shared/config'
import { slides } from './Slides'

import styles from './styles.module.scss'

export const RightContent = () => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)

  const slideNext = () => swiper?.slideNext()
  const slidePrev = () => swiper?.slidePrev()

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.starsWrap}>
          {[...Array(5)].map((el, idx) => (
            <Icon key={idx} icon={'starFilled1'} color={'var(--yellow-60)'} fontSize={24} />
          ))}
        </div>
        <div className={styles.titleWrap}>
          <div>
            <p className={styles.title}>Trusted by growing business</p>
            <Typography ariaLabel={'stepSignUpSubtitle'} variant={'body-lg-regular'}>
              1,694 ratings and reviews with an average of 4.7 stars
            </Typography>
          </div>
          <div className={styles.navigationWrap}>
            <IconButton
              icon={'arrowLeft'}
              color={'secondary'}
              variant={'icon'}
              iconProps={{ fontSize: 20 }}
              className={styles.sliderNavigate}
              onClick={slidePrev}
              ariaLabel={'sliderLeftAction'}
            />
            <IconButton
              icon={'arrowRight'}
              color={'secondary'}
              variant={'icon'}
              iconProps={{ fontSize: 20 }}
              className={styles.sliderNavigate}
              onClick={slideNext}
              ariaLabel={'sliderLeftAction'}
            />
          </div>
        </div>
        <div className={styles.sliderWrap}>
          <Swiper
            spaceBetween={30}
            slidesPerView={'auto'}
            centeredSlides={true}
            loop={true}
            onInit={(swiper) => setSwiper(swiper)}
            pagination={{
              clickable: true,
            }}
            className={styles.slider}
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide.name} className={styles.slide}>
                <>
                  <div>
                    <div className={styles.slideSubtitle}>
                      <Status title={slide.status} intent={'positive'} emphasis={'high'} />
                      <Typography ariaLabel={'slideSubtitle'} variant={'body-rg-regular'}>
                        {slide.subtitle}
                      </Typography>
                    </div>
                    <div className={styles.slideLogoWrap}>
                      <img className={styles.slideLogo} alt='slideLogo' src={`${slide.logo}`} />
                    </div>

                    <Typography
                      className={styles.slideTitle}
                      ariaLabel={'slideTitle'}
                      variant={'heading-lg'}
                    >
                      {slide.title}
                    </Typography>
                  </div>
                  <div className={styles.slideAuthor}>
                    <img className={styles.avatar} alt='slideLogo' src={`${slide.avatar}`} />
                    <div className={styles.name}>
                      <Typography ariaLabel={'slideAuthorName'} variant={'body-rg-medium'}>
                        {slide.name}
                      </Typography>
                      <Typography
                        ariaLabel={'slideAuthorName'}
                        variant={'body-rg-regular'}
                        color={'var(--content-primary-secondary)'}
                      >
                        {slide.role}
                      </Typography>
                    </div>
                  </div>
                </>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={styles.footer}>
        <img
          className={styles.achievementsImg}
          alt='slideLogo'
          src={`${CDN_FRONT_URL}${achievementsImg}`}
        />
      </div>
    </div>
  )
}
