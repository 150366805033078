import { Navigate, Route, Routes } from 'react-router-dom'
import { featureFlagsStore } from 'entities/FeatureFlags'
import {
  ChatbotEditPage,
  ChatbotNewPage,
  ChatbotListPage,
  ChatbotAnalyticPage,
  KnowledgeBaseListPage,
} from 'pages/chatbot/pages'
import { ChatbotLayout } from 'pages/chatbot/layout'
import { ChatbotRoute } from './type'

export const ChatbotRoutes = () => {
  const { rag } = featureFlagsStore

  return (
    <Routes>
      <Route element={<ChatbotLayout />}>
        <Route index element={<Navigate to={ChatbotRoute.all} />} />
        <Route path={`${ChatbotRoute.all}`} element={<ChatbotListPage />} />
        {rag ? (
          <Route path={`${ChatbotRoute.knowledgeBase}`} element={<KnowledgeBaseListPage />} />
        ) : (
          <Route
            path={ChatbotRoute.knowledgeBase}
            element={<Navigate to={`/${ChatbotRoute.root}/${ChatbotRoute.all}`} />}
          />
        )}
        <Route path={`${ChatbotRoute.new}`} element={<ChatbotNewPage />} />
        <Route path=':chatbotId' element={<ChatbotEditPage />} />
        <Route path={`:chatbotId/${ChatbotRoute.analytic}`} element={<ChatbotAnalyticPage />} />
      </Route>
    </Routes>
  )
}
