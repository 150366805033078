import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { remindersStore } from 'widgets/Reminders'

export const AlertAddCredits = observer(() => {
  const { creditsStore: buyCreditsStore, buyCreditsModalStore } = remindersStore

  if (!buyCreditsStore.show) return null
  if (!buyCreditsStore.isOrganizationOwner) {
    return (
      <Alert
        item={{
          type: 'warning',
          title: 'Your organization is out of message credits',
          desc: 'Ask your organization owner to add message credits to your account',
        }}
      />
    )
  }

  return (
    <Alert
      item={{
        type: 'warning',
        title: 'Your organization is out of message credits',
        desc: 'Add credits to continue sending and receiving messages.',
      }}
      actions={
        <Button
          text={'Add message credits'}
          typeBtn={'outlined'}
          size={'small'}
          contained={'secondary'}
          onClick={() => {
            buyCreditsModalStore.openModal()
          }}
        />
      }
    />
  )
})
