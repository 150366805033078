import React from 'react'
import { FullPageWrapper } from 'shared/ui'
import { BroadcastList, BroadcastsHeader } from 'widgets/BroacastList'

export const AllBroadcasts = () => (
  <FullPageWrapper>
    <BroadcastsHeader title={'All broadcasts'} />
    <BroadcastList storeProps={{ type: 'all' }} />
  </FullPageWrapper>
)
