import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { IRow, Table } from 'shared/ui'
import { IAdminOrganization } from 'entities/Admin/organizations'
import { ComplianceTableWrapper } from 'pages/admin/pages/compliance'
import { adminOrganizationsListStore } from 'pages/admin/pages/organizations'
import { columns } from 'pages/admin/pages/organizations/pages/Organizations/ui/OrganizationsTable/column'
import { getAdminOrganizationsActions } from 'pages/admin/pages/organizations/lib/getAdminOrganizationsActions'

export const OrganizationsTable = observer(() => {
  const {
    total,
    organizations,
    loadData,
    onPaginationModelChange,
    loading,
    limit,
    page,
    tableStore,
  } = adminOrganizationsListStore
  const { visibleColumnsIds, setVisibleColumnsIds } = tableStore
  const getRowLink = (row: IRow<IAdminOrganization>) => String(row.id)
  useEffect(() => {
    loadData()
    if (!visibleColumnsIds.length) {
      setVisibleColumnsIds(columns.map((column) => column.field))
    }
  }, [])

  const rows = useMemo(
    (): IRow<IAdminOrganization>[] =>
      organizations.map((organization) => ({
        ...organization,
        actionsProps: {
          showItems: 0,
          actions: getAdminOrganizationsActions({ organization }),
        },
      })),
    [organizations]
  )
  return (
    <ComplianceTableWrapper>
      <Table
        columns={columns}
        rows={rows}
        getRowLink={getRowLink}
        rowsCount={total}
        isLoading={loading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        visibleColumnsIds={visibleColumnsIds}
        setVisibleColumnsIds={setVisibleColumnsIds}
        limit={limit}
        startPaginationSize={25}
      />
    </ComplianceTableWrapper>
  )
})
