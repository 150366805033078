import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { Portal } from 'shared/lib'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { InfoPreview } from 'widgets/PresentationMode/ui/info/InfoPreview'
import { PresentationContent } from 'widgets/PresentationMode/ui/content/PresentationContent'
import styles from './styles.module.scss'

export const PresentationMode = observer(() => {
  const {
    disabledOverlayClick,
    currentAttachment,
    openPresentationMode,
    isPrev,
    isNext,
    onClose,
    setDisabledOverlayClick,
    onPrev,
    onNext,
  } = presentationStore

  const onWrapClick = () => {
    if (!disabledOverlayClick) {
      onClose()
    }
  }
  const onMouseEnter = () => {
    setDisabledOverlayClick(true)
  }
  const onMouseLeave = () => {
    setDisabledOverlayClick(false)
  }

  if (!openPresentationMode || !currentAttachment) {
    return <></>
  }
  return (
    <Portal>
      <div className={styles.wrap} onClick={onWrapClick} aria-hidden={true}>
        <PresentationContent />
        {isPrev && (
          <div className={styles.leftArrow} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <IconButton
              onClick={onPrev}
              icon={'arrowLeft'}
              size={'medium'}
              color={'tertiary'}
              variant={'contained'}
              onDestroy={onMouseLeave}
              ariaLabel='PresentationMode_prev'
            />
          </div>
        )}
        {isNext && (
          <div
            className={styles.rightArrow}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <IconButton
              onClick={onNext}
              icon={'arrowRight'}
              size={'medium'}
              color={'tertiary'}
              variant={'contained'}
              onDestroy={onMouseLeave}
              ariaLabel='PresentationMode_next'
            />
          </div>
        )}
        <InfoPreview />
      </div>
    </Portal>
  )
})
