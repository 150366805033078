import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { callStore } from 'entities/Call'
import { DeviceSettingsContent, DeviceSettingsVariantEnum } from 'entities/DeviceSettings'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallRecordPopUpContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const { initMic, resetMic } = store as CallPopUpDefaultStore

  useEffect(() => {
    initMic()

    return () => {
      resetMic()
    }
  }, [])

  return (
    <div className={styles.settings}>
      <div className={styles.settings__title}>Settings</div>
      <div className={styles.settings__content}>
        <DeviceSettingsContent
          deviceSettingsStore={callStore.deviceSettingsStore}
          testOutputDevice={() => {
            callStore.testOutputDevice()
          }}
          variant={DeviceSettingsVariantEnum.container}
          dropdownProps={{
            width: 298,
            margin: -30,
            ariaLabel: 'CallRecord',
          }}
        />
      </div>
    </div>
  )
})
