import { PropsWithChildren } from 'react'
import { Dropdown, IIconsVector, Icon } from 'shared/ui'

import styles from './styles.module.scss'

export interface IActionCard {
  icon: IIconsVector
  title: string
  onDelete: () => void
}

export const ActionCard = ({ icon, title, onDelete, children }: PropsWithChildren<IActionCard>) => {
  return (
    <section className={styles.actionCard}>
      <header className={styles.actionCardHead}>
        <div className={styles.actionCardHeadTitle}>
          <Icon icon={icon} />
          <h5>{title}</h5>
        </div>
        <Dropdown
          ariaLabel='TextbotAction'
          icon='moreHorizontal'
          placement='bottom-end'
          tooltipProps={{
            label: 'Show actions',
            placement: 'top',
            disableInteractive: true,
          }}
          items={[
            {
              id: 1,
              label: 'Delete',
              iconL: 'delete',
              onClick: onDelete,
            },
          ]}
        />
      </header>
      <div className={styles.actionCardContent}>{children}</div>
    </section>
  )
}
