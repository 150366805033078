// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wayFA1P8bXdxXztzHyRB{display:flex;align-items:center;justify-content:space-between;padding:8px}.xPVOKdnW2coFH8rx4Aql{color:var(--content-primary-primary);font-size:13px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/shared/ui/Dropdown/ui/DropdownCard/DropdownCardDeletable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CAEJ,sBACI,oCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".contentWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 8px;\n}\n.label {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": "wayFA1P8bXdxXztzHyRB",
	"label": "xPVOKdnW2coFH8rx4Aql"
};
export default ___CSS_LOADER_EXPORT___;
