import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { CustomPrices } from 'pages/admin/pages/customPrices/pages/CustomPrices'
import { CustomPriceCreate } from 'pages/admin/pages/customPrices/pages/CustomPricesCreate'
import { CustomPriceUpdate } from 'pages/admin/pages/customPrices/pages/CustomPricesUpdate'

export const CustomPricesRoutes = () => {
  return (
    <Routes>
      <Route path={'/create'} element={<CustomPriceCreate />} />
      <Route path={'/'} element={<CustomPrices />} />
      <Route path={'/:id'} element={<CustomPriceUpdate />} />
    </Routes>
  )
}
