import { useEffect, useMemo, useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownCard, type IDropdownItem } from 'shared/ui'
import { type IContactsDataParams } from 'entities/Contacts/api/types'
import { WorkflowEnrollStore } from 'widgets/WorkflowEnroll/store/WorkflowEnrollStore'

type IWorkflowEnrollButtonProps = {
  contactsData: IContactsDataParams
  variant: 'dropdownItem' | 'button'
  onClick?: () => void
}

export const WorkflowEnrollAction: FC<IWorkflowEnrollButtonProps> = observer(
  ({ contactsData, variant, onClick }) => {
    const text = 'Enroll in Workflow'
    const icon = 'workflows'
    const [loading, setLoading] = useState(false)
    const store = useMemo(() => new WorkflowEnrollStore(), [])
    const { selectedIds } = contactsData

    const handleClick = () => {
      setLoading(true)
      store.getValidContactIds(selectedIds).then((validContactIds) => {
        setLoading(false)
        onClick?.()

        if (validContactIds) store.enrollContacts(validContactIds)
      })
    }

    useEffect(() => store.checkActivity(), [])

    if (!store.isOperationEnabled) return null

    if (variant === 'dropdownItem') {
      const item: IDropdownItem = {
        id: 'enroll_in_workflow',
        iconL: icon,
        label: text,
        disabled: loading,
      }
      return <DropdownCard item={item} onChange={handleClick} />
    }

    return (
      <Button
        text={text}
        icon={icon}
        typeBtn='contained'
        contained='tertiary'
        size='small'
        onClick={handleClick}
        loading={loading}
      />
    )
  }
)
