import { useEffect, useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { debounce } from 'lodash'
import { Dropdown, SearchElement } from 'shared/ui'
import { inboxesStore } from 'entities/Inbox'
import { SearchTypesEnum, useSearchDropdownContext } from 'widgets/SearchDropdown'
import { InboxesDropdownContent } from 'widgets/SearchDropdown/ui/DropdownContent/InboxesDropdownContent/InboxesDropdownContent'

type TeamElementProps = {
  parentsDropdownId?: string[]
}

export const TeamElement: FC<TeamElementProps> = observer(({ parentsDropdownId }) => {
  // TODO: Fix position of child dropdown when child and parent dropdown render in 1 time
  const [show, setShow] = useState(false)
  const { searchDropdownInboxesStore, isShowMore, isOpen, newType, isDirtyFilter, deleteType } =
    useSearchDropdownContext()
  const { hasSelectedItems, selectedItemsCount, firstSelectedItemId } = searchDropdownInboxesStore

  const getLabel = () => {
    const firstItem = inboxesStore.getItem(firstSelectedItemId)

    if (hasSelectedItems && selectedItemsCount === 1 && firstItem) {
      return firstItem.name
    }

    return `${selectedItemsCount} inboxes`
  }

  const onShow = () => {
    const showDropdown =
      (newType === SearchTypesEnum.teams && isShowMore) ||
      (newType === SearchTypesEnum.teams && isOpen && !isDirtyFilter)

    setShow(showDropdown)
  }

  const showDebounce = debounce(onShow, 300)

  useEffect(() => {
    showDebounce()
  }, [isShowMore, newType, isOpen, isDirtyFilter])

  return (
    <Dropdown
      ariaLabel={'TeamElement'}
      parentsDropdownId={parentsDropdownId}
      iconButtonProps={{
        size: 'medium',
        fontSize: 20,
        ariaLabel: 'TeamElement_trigger',
      }}
      show={show}
      triggerComponent={(open) => (
        <SearchElement
          icon={'all_inboxes'}
          type='Inbox'
          removeCallback={() => {
            deleteType(SearchTypesEnum.teams)
          }}
          content={hasSelectedItems && getLabel()}
          warningContent={!open && !hasSelectedItems}
          contentDefaultValue='select'
        />
      )}
      placement={'bottom-start'}
      sizeTriggerButton={'medium'}
      variantTriggerButton={'icon'}
      customComponent={() => <InboxesDropdownContent store={searchDropdownInboxesStore} />}
    />
  )
})
