import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { TableStore } from 'shared/ui/Table'
import type { Attachment } from 'entities/Attachment/model/Attachment'
import {
  ChatbotApi,
  type IParamsKnowledgeBaseList,
  type IResponseKnowledgeBaseList,
} from 'entities/Chatbot'

export class KnowledgeBaseListStore {
  page = 1
  total = 0
  limit = 10
  search = ''
  loading = true
  initialLoading = true

  private _disposeLoadKnowledgeBaseList: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this._reactionLoadKnowledgeBaseList()
  }

  private _cancelTokenSource: CancelTokenSource | null = null

  private _listMap = new Map<number, Attachment>()
  tableStore = new TableStore<Attachment>({
    element: 'knowledgeBase',
  })

  private _reactionLoadKnowledgeBaseList = () => {
    this._disposeLoadKnowledgeBaseList?.()
    this._disposeLoadKnowledgeBaseList = reaction(
      () => this._knowledgeBaseListRequestParams,
      () => this.loadKnowledgeBaseList(),
      { delay: 500 }
    )
  }

  private _initCancelPageSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _setData = ({ meta }: IResponseKnowledgeBaseList) => {
    // TODO: Update data setting after the backend is implemented
    this._listMap.clear()

    this.page = meta.current_page
    this.total = meta.total
  }

  loadKnowledgeBaseList = async () => {
    this.loading = true
    this._initCancelPageSource()

    try {
      const { data } = await ChatbotApi.getKnowledgeBaseList(this._knowledgeBaseListRequestParams, {
        ...(this._cancelTokenSource ? { cancelToken: this._cancelTokenSource.token } : null),
      })

      this._setData(data)

      runInAction(() => {
        this.loading = false
      })
    } catch (error) {
      runInAction(() => {
        this.loading = error instanceof CanceledError
      })
      console.error(error)
    } finally {
      runInAction(() => {
        this.initialLoading = false
      })
    }
  }

  onSearchChange = (value: string) => {
    this.page = 1
    this.search = value
  }

  onPaginationChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }

  clearReactions = () => {
    this._disposeLoadKnowledgeBaseList?.()
  }

  private get _knowledgeBaseListRequestParams(): IParamsKnowledgeBaseList {
    return {
      page: this.page,
      limit: this.limit,
      search: this.search,
    }
  }

  get isEmpty() {
    if (this.initialLoading || this.loading || !!this.search.length) return false

    return !this._listMap.size
  }

  get items() {
    return Array.from(this._listMap.values())
  }
}
