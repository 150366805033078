import { createContext, useContext } from 'react'
import { type ConversationSearchStore } from 'widgets/ConversationHeaderSearch/store'

export const ConversationSearchContext = createContext<ConversationSearchStore | null>(null)

export function useConversationSearchContext() {
  const context = useContext(ConversationSearchContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ConversationSearchStoreProvider'
    )
  }
  return context
}
