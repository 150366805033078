import { createContext, useContext } from 'react'
import { type IAvatarInfo } from 'shared/ui'
import { type Message } from 'entities/Message/model/Message'
import { type CallContentStore } from 'widgets/MessageCard'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export type IMessageCardProps = {
  message: Message
  avatarInfo?: IAvatarInfo
  sending?: boolean
  isLast?: boolean
  hideTeam?: boolean
  selectedSearch?: string
  setScrollBottomTrigger?: () => void
  callContentStore?: CallContentStore
  conversationMessagesStore?: ConversationMessagesStore | null
}

export const MessageCardContext = createContext<IMessageCardProps | null>(null)

export function useMessageCardContext() {
  const context = useContext(MessageCardContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with MessageCardStoreProvider')
  }
  return context
}
