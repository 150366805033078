// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jPZ9mDBImW3lxfeFz2vL{padding:0 24px 24px 24px}.MCaxfmlgjunuJu5bhSmX{margin-block:24px}.MlkoK_yXqt7hl1ogAs1y{height:32px;display:flex;justify-content:space-between;align-items:center}.ScWPSpU0JOYA_8UVoSxy{display:flex;justify-content:flex-end;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/compliance/pages/TollFreeDetails/ui/ReviewContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,OAAA","sourcesContent":[".wrap{\n  padding: 0 24px 24px 24px;\n}\n\n.wrapButtons{\n  margin-block: 24px;\n}\n\n.btn{\n  height: 32px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.actions{\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "jPZ9mDBImW3lxfeFz2vL",
	"wrapButtons": "MCaxfmlgjunuJu5bhSmX",
	"btn": "MlkoK_yXqt7hl1ogAs1y",
	"actions": "ScWPSpU0JOYA_8UVoSxy"
};
export default ___CSS_LOADER_EXPORT___;
