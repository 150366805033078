// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rMAYQxsx4B2bun0VD4zb{background:var(--content-primary-primary-inverted);width:100%;max-width:760px;padding-inline:20px;margin:-8px auto -20px;display:flex;align-items:center;justify-content:space-between;gap:16px}.KRzDbe7tmqZAG5nZ3PQ5{margin-right:auto}.eOEfHe2FLBEUmoZs3bDv{margin-left:auto;width:max-content;display:flex;flex-direction:column;align-items:flex-end;gap:16px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/TypingContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kDAAA,CACA,UAAA,CACA,eAAA,CACA,mBAAA,CACA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CAEF,sBACE,iBAAA,CAEF,sBACE,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,oBAAA,CACA,QAAA","sourcesContent":[".wrap {\n  background: var(--content-primary-primary-inverted);\n  width: 100%;\n  max-width: 760px;\n  padding-inline: 20px;\n  margin: -8px auto -20px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 16px;\n}\n.left {\n  margin-right: auto;\n}\n.right {\n  margin-left: auto;\n  width: max-content;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rMAYQxsx4B2bun0VD4zb",
	"left": "KRzDbe7tmqZAG5nZ3PQ5",
	"right": "eOEfHe2FLBEUmoZs3bDv"
};
export default ___CSS_LOADER_EXPORT___;
