import classNames from 'classnames'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { ITextFieldBaseProps } from '../types'

export const useControl = (props: ITextFieldBaseProps) => {
  const containerRef = useRef<HTMLLabelElement | null>(null)

  const variant = useMemo(() => props.variant, [props.variant])
  const size = useMemo(() => props.size, [props.size])

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [actionClassName, setActionClassName] = useState<Record<string, string>>({})
  const [actionClasses, setActionClasses] = useState<string>('')
  const error = useMemo(() => props.error ?? '', [props.error])

  const control = {
    containerRef,
    actionClasses,
    variant,
    size,
    error,
    inputRef,
  }

  const makeControl = (id: string) => {
    const setClassName = (newClassName: string) =>
      setActionClassName({
        ...actionClassName,
        [id]: newClassName,
      })

    return {
      containerRef,
      inputRef,
      setClassName,
      error,
      variant,
      size,
    }
  }

  useLayoutEffect(() => {
    const actionClasses = classNames(Object.values(actionClassName))

    setActionClasses(actionClasses)
  }, [actionClassName])

  return { control, makeControl }
}
