import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { createElementFromHTML } from 'shared/lib/createElementFromHTML'
import { showToast } from 'shared/ui'
import { IconButton } from 'shared/ui/IconButton/IconButton'
import { isMac } from 'shared/lib/deviceDetector'
import { shortLinkStore, useShortenLink } from 'entities/ShortLink'
import { getCaretIndex, placeCaretAtEnd, placeCaretAtStart } from 'widgets/MessageField/lib'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { textToHtml } from 'widgets/MessageField/lib/textToHtml'
import { useMergeFields } from 'widgets/MergeField'
import styles from './styles.module.scss'

export const TextField = observer(() => {
  const messageFieldStore = useMessageFieldContext()
  const {
    inputActions,
    messageInnerText,
    replaceTriggerContent,
    updateTriggerContent,
    messageHtml,
    disablesSend,
    disabled,
    focusMessageFieldTrigger,
    blurMessageFieldTrigger,
    setFocusMessageFieldTrigger,
    setMessageText,
    setAddContentFn,
    setReplaceContentFn,
    setReplaceSelectedContentFn,
    sendActionBtnProps,
    setUpdateTriggerContent,
    setSelectedContent,
    mentionsStore,
  } = messageFieldStore
  const ref = useRef<HTMLDivElement>(null)
  const refInputKey = useRef('')
  const offsetRef = useRef(0)
  const focusNodeRef = useRef<Node | null>(null)
  const selectedRange = useRef<Range | null>(null)

  const updateCaretPosition = () => {
    if (ref.current) {
      const { offset, focusNode } = getCaretIndex(ref.current)
      offsetRef.current = offset
      focusNodeRef.current = !ref.current.innerHTML ? null : focusNode || null
    }
  }

  const addContent = (content: string) => {
    if (!content || disabled) {
      return
    }

    const node = createElementFromHTML(content, true)
    new Set(node.querySelectorAll('[data-readonly]')).forEach((element) => {
      element.removeAttribute('data-readonly')
      element.setAttribute('contenteditable', 'false')
    })

    let focusNodeLocal = focusNodeRef.current
    let offsetLocal = offsetRef.current

    if (ref.current) {
      if (!focusNodeLocal) {
        ref.current.focus()
        focusNodeLocal = getCaretIndex(ref.current).focusNode || ref.current || null
        offsetLocal = getCaretIndex(ref.current).offset || 0
      }
      if (focusNodeLocal) {
        if (focusNodeLocal.nodeName === '#text') {
          if (focusNodeLocal.textContent) {
            const firstPart = focusNodeLocal.textContent.slice(0, offsetLocal)
            const firstNode = createElementFromHTML(firstPart, true)
            const secondPart = focusNodeLocal.textContent.slice(offsetLocal)
            const secondNode = createElementFromHTML(secondPart, true)
            const newNode = document.createElement('span')

            if (firstPart) {
              newNode.appendChild(firstNode)
            }
            newNode.appendChild(node)
            if (secondPart) {
              newNode.appendChild(secondNode)
            }
            if (newNode) {
              const parentNode = focusNodeLocal.parentNode
              if (parentNode) {
                parentNode.replaceChild(newNode, focusNodeLocal)
              }
            }
            if (ref.current) {
              setMessageText(ref.current.innerHTML, ref.current.innerText)
              try {
                if (secondPart) {
                  placeCaretAtStart(secondNode)
                } else {
                  placeCaretAtEnd(newNode)
                }
              } catch (e) {
                console.error(e)
              }
            }
          } else {
            const parentNode = focusNodeLocal.parentNode
            const newNode = createElementFromHTML(content, true)

            if (parentNode) {
              parentNode.replaceChild(newNode, focusNodeLocal)
            }
            setMessageText(ref.current.innerHTML, ref.current.innerText)
            if (focusNodeLocal) {
              placeCaretAtEnd(focusNodeLocal)
            } else {
              placeCaretAtEnd(ref.current)
            }
          }
        } else {
          if (focusNodeLocal.firstChild?.nodeName === 'BR') {
            focusNodeLocal.removeChild(focusNodeLocal.firstChild)
          }
          focusNodeLocal.appendChild(node)
          setMessageText(ref.current.innerHTML, ref.current.innerText)
          if (focusNodeLocal) {
            placeCaretAtEnd(focusNodeLocal)
          } else {
            placeCaretAtEnd(ref.current)
          }
        }

        const testFocusNodeLocal = getCaretIndex(ref.current).focusNode || null
        if (!testFocusNodeLocal) {
          placeCaretAtEnd(ref.current)
        }
      }
    }
    updateCaretPosition()
    messageFieldStore.setUpdateTriggerContent()
  }

  const { onHideMergeFieldsTooltips, mergeFieldTooltipComponent } = useMergeFields({
    trigger: ref.current,
    updateTriggerDeps: [ref.current, updateTriggerContent, replaceTriggerContent],
    withEdit: true,
    setUpdateTriggerContent,
  })
  const { onHideLinkTooltips, linkTooltipComponent } = useShortenLink({
    trigger: ref.current,
    updateTriggerDeps: [ref.current, updateTriggerContent, replaceTriggerContent],
    setUpdateTriggerContent,
    addContent,
  })

  const onFocus = () => {
    if (disabled) return
    messageFieldStore.setActiveMessageField(true)
    updateCaretPosition()
    messageFieldStore.onFocus?.()
    shortLinkStore.setIsMarketing(false)
    shortLinkStore.setMessageText(messageInnerText)
  }

  const onClick = () => {
    if (ref.current) {
      messageFieldStore.onClick?.()
      mentionsStore.checkMessageKey(selectedRange.current)
    }
  }

  const onBlur = () => {
    if (!messageFieldStore.isVariantNote) {
      messageFieldStore.setActiveMessageField(false)
    }
    updateCaretPosition()
  }

  const replaceSelectedContent = (content: string) => {
    selectedRange.current?.startContainer &&
      placeCaretAtStart(selectedRange.current?.startContainer, selectedRange.current?.startOffset)
    updateCaretPosition()
    selectedRange.current?.deleteContents()

    addContent(content)
  }

  const replaceContent = (content: string) => {
    if (!ref.current) return

    const focusNodeLocal = focusNodeRef.current

    if (focusNodeLocal) {
      const newNode = createElementFromHTML(content, true)

      if (newNode) {
        const parentNode = focusNodeLocal.parentNode
        if (parentNode) {
          parentNode.replaceChild(newNode, focusNodeLocal)
        }
      }
      if (ref.current) {
        setMessageText(ref.current.innerHTML, ref.current.innerText)
        try {
          placeCaretAtEnd(newNode)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }

  const onPaste: React.ClipboardEventHandler<HTMLDivElement> = (e) => {
    if (disabled) return

    onClick()
    e.preventDefault()

    const length = e.clipboardData.getData('text/plain').length
    const totalLength = messageInnerText.length + length
    if (
      ref.current &&
      messageFieldStore.textLimit.showNotification &&
      totalLength > messageFieldStore.textLimit.maxLength
    ) {
      ref.current.innerHTML = messageHtml
      placeCaretAtEnd(ref.current)

      showToast({
        type: 'error',
        title: messageFieldStore.textLimit.message,
      })
      return
    }

    const text = e.clipboardData.getData('text/plain')
    window.getSelection()?.getRangeAt(0)?.deleteContents()
    updateCaretPosition()

    addContent(
      textToHtml({
        text,
        extraSpace: true,
        isClearMergeField: messageFieldStore.replaceMergeFieldsOnPaste,
      })
    )
  }

  const onInput = () => {
    if (disabled) return

    if (ref.current) {
      if (
        refInputKey.current !== 'Backspace' &&
        messageFieldStore.textLimit.showNotification &&
        ref.current.innerText.length > messageFieldStore.textLimit.maxLength
      ) {
        ref.current.innerHTML = messageHtml
        placeCaretAtEnd(ref.current)

        showToast({
          type: 'error',
          title: messageFieldStore.textLimit.message,
        })
        return
      }

      if (ref.current.innerHTML === '<br>') {
        setMessageText('', '')
      } else {
        if (inputActions) {
          const inputAction = inputActions.get(ref.current?.innerText)

          if (inputAction) {
            inputAction.action()
            setMessageText('', '')
            ref.current.innerHTML = ''
            ref.current.innerText = ''

            return
          }
        }

        setMessageText(ref.current.innerHTML, ref.current.innerText)
      }
    }
  }
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (messageFieldStore.onKeyDown) {
      messageFieldStore.onKeyDown(e)
    }
    if (disabled) return
    if (refInputKey) refInputKey.current = e.code

    if ((e.ctrlKey || (isMac && e.metaKey)) && e.code === 'Enter' && !disablesSend && !disabled) {
      sendActionBtnProps?.onClick?.()
      setFocusMessageFieldTrigger()
    }
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (messageFieldStore.onKeyUp) {
      messageFieldStore.onKeyUp(e)
    }
    if (ref.current) {
      mentionsStore.checkMessageKey(selectedRange.current)
    }
  }

  const onScroll = () => {
    onHideMergeFieldsTooltips()
    onHideLinkTooltips()
  }

  const onSelect = () => {
    if (!window.getSelection()?.rangeCount) return
    const range = window.getSelection()?.getRangeAt(0)
    setSelectedContent(range?.toString() || '')
    if (selectedRange && range) {
      selectedRange.current = range
    }
  }

  useEffect(() => {
    shortLinkStore.init(false)
    if (messageFieldStore.onInit) messageFieldStore.onInit()

    return () => {
      shortLinkStore.reset()
    }
  }, [])

  useEffect(() => {
    if (ref.current && !messageInnerText) {
      ref.current.innerHTML = ''
    }

    shortLinkStore.setIsMarketing(false)
    shortLinkStore.setMessageText(messageInnerText)
  }, [messageInnerText])

  useEffect(() => {
    if (ref.current && replaceTriggerContent) {
      ref.current.innerHTML = messageHtml
    }
  }, [replaceTriggerContent])

  useEffect(() => {
    if (ref.current && focusMessageFieldTrigger) {
      messageFieldStore.setActiveMessageField(true)
      placeCaretAtEnd(ref.current)
    }
  }, [focusMessageFieldTrigger, ref.current])

  useEffect(() => {
    if (ref.current && blurMessageFieldTrigger && messageFieldStore.activeMessageField) {
      messageFieldStore.setActiveMessageField(false)
      ref.current.blur()
    }
  }, [blurMessageFieldTrigger])

  useEffect(() => {
    setAddContentFn(addContent)
    setReplaceContentFn(replaceContent)
    setReplaceSelectedContentFn(replaceSelectedContent)
  }, [messageFieldStore])

  useEffect(() => {
    if (updateTriggerContent) {
      onInput()
    }
  }, [updateTriggerContent])

  return (
    <div className={classNames(messageFieldStore.isVariantNote && styles.textareaNoteWrapper)}>
      {mergeFieldTooltipComponent}
      {linkTooltipComponent}
      <div className={styles.textareaContent}>
        <div
          ref={ref}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onInput={onInput}
          contentEditable={true}
          className={classNames(styles.textarea, {
            [styles.withContent]: messageHtml,
            [styles.active]: messageFieldStore.activeMessageField,
            [styles.isNote]: messageFieldStore.isVariantNote || messageFieldStore.isModeNote,
          })}
          style={messageFieldStore.styles}
          placeholder={messageFieldStore.placeholder}
          aria-hidden={true}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onClick}
          onScroll={onScroll}
          onSelect={onSelect}
        />
      </div>
      {messageFieldStore.isVariantNote && !messageFieldStore.activeMessageField && !messageHtml && (
        <IconButton
          icon={'arrowUpCircle'}
          color={'quaternary'}
          variant={'icon'}
          disabled
          ariaLabel='TextField'
        />
      )}
    </div>
  )
})
