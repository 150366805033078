import { observer } from 'mobx-react-lite'
import { ITableRowProps, TableRow } from 'shared/ui/Table/TableRow/TableRow'

export const ExpandedTableRow = observer(<TRow,>(props: ITableRowProps<TRow>) => {
  return (
    <>
      <TableRow {...props} />
      {props.row.expanded && (
        <>
          {props.row.rows?.map((row) => (
            <TableRow key={row.id} {...props} row={row} isExpandedChild />
          ))}
          {props.row.isMoreExpand && <TableRow {...props} isMoreExpand />}
        </>
      )}
    </>
  )
})
