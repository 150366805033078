import { makeAutoObservable } from 'mobx'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { IResponseBroadcastById } from 'entities/Broadcast/api/types'
import {
  type ISMSMessageFieldStoreProps,
  SMSMessageFieldStore,
} from 'widgets/MessageFormFields/SMSMessageField/store/SMSMessageFieldStore'

export class BroadcastSMSStore {
  smsMessageFieldStore: SMSMessageFieldStore
  constructor(props: ISMSMessageFieldStoreProps) {
    this.smsMessageFieldStore = new SMSMessageFieldStore(props)
    makeAutoObservable(this)
  }

  init = (response: IResponseBroadcastById) => {
    this.messageFieldStore.setMessageData({
      attachments:
        response.media_url?.map((media) => new Attachment({ responseMediaShort: media })) || [],
      message: response.message || '',
      isFocus: false,
      isReset: false,
      isReplace: true,
    })
  }

  get messageDataParams() {
    return this.smsMessageFieldStore.messageDataParams
  }

  get messageFieldStore() {
    return this.smsMessageFieldStore.messageFieldStore
  }

  get isError() {
    return this.smsMessageFieldStore.isError
  }

  get segments_count() {
    return this.smsMessageFieldStore.segments_count
  }
}
