import classNames from 'classnames'
import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  Fragment,
  Ref,
  useEffect,
  useId as makeId,
  useImperativeHandle,
  useState,
} from 'react'
import { getAriaLabel } from 'shared/lib'

import type { IInputCommonProps, IInputTextProps } from '../Input/types'
import type { ITextFieldProps } from '../types'

import { useControl } from './useControl'
import styles from '../styles.module.scss'

const TextFieldRef = <InputProps extends IInputCommonProps = IInputTextProps>(
  props: ITextFieldProps<InputProps>,
  ref: Ref<HTMLLabelElement>
) => {
  const { control, makeControl } = useControl<InputProps>(props)

  const [isFocused, setFocused] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    control.inputProps.onChange?.(event)
    props.onChange?.(event.target.value)
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(true)
    control.inputProps.onFocus?.(event)
    props.onFocus?.(event.target.value)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(false)
    control.inputProps.onBlur?.(event)
    props.onBlur?.(event.target.value, { isTrusted: event.isTrusted })
  }

  useEffect(() => props.onToggleFocus?.(isFocused), [isFocused])

  useImperativeHandle<HTMLLabelElement | null, HTMLLabelElement | null>(
    ref,
    () => control.containerRef.current,
    []
  )
  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    control.inputProps.ref,
    () => control.inputRef.current,
    [control.inputProps.ref]
  )

  const content = (
    <label
      className={classNames(
        styles.textFieldLabel,
        {
          [styles.textFieldLabel_sizeExtraSmall]: control.size === 'extraSmall',
          [styles.textFieldLabel_sizeSmall]: control.size === 'small',
          [styles.textFieldLabel_sizeMedium]: control.size === 'medium',
          [styles.textFieldLabel_variantStroke]: control.variant === 'stroke',
          [styles.textFieldLabel_variantOutline]: control.variant === 'outline',
          [styles.textFieldLabel_variantIntegrated]: control.variant === 'integrated',
          [styles.textFieldLabel_readOnly]: control.readOnly,
          [styles.textFieldLabel_disabled]: control.disabled,
          [styles.textFieldLabel_viewOnly]: control.viewOnly,
          [styles.textFieldLabel_error]: !!control.error,
          [styles.textFieldLabel_warning]: !!control.warning,
        },
        control.actionClasses,
        props.className
      )}
      ref={control.containerRef}
      htmlFor={control.inputProps.id}
      aria-label={getAriaLabel(props.ariaLabel || '')}
    >
      {(props.leftActions ?? []).map((action, index) => {
        const id = makeId()
        const control = makeControl(id)

        return <Fragment key={index}>{action(control)}</Fragment>
      })}
      <control.InputComponent
        {...control.inputProps}
        value={(control.inputProps.value || props.value) ?? ''}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={control.inputRef}
        readOnly={control.readOnly}
        disabled={control.disabled || control.readOnly}
        className={styles.textFieldInput}
      />

      {(props.rightActions ?? []).map((action, index) => {
        const id = makeId()
        const control = makeControl(id)

        return <Fragment key={index}>{action(control)}</Fragment>
      })}
    </label>
  )

  return (props.mainActions ?? []).reduceRight((content, action) => {
    const id = makeId()
    const control = makeControl(id)

    return action(control, content)
  }, content)
}

export const TextField = forwardRef(TextFieldRef)
