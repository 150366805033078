import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { Select } from 'shared/ui/Select/Select'
import { EnumDropdownItemVariant, Button, IDropdownItem } from 'shared/ui'
import { integrationsStore } from 'entities/Integrations'
import type { IFilterValue } from 'entities/Filters/types'
import type { IResponseFilter } from 'entities/Contacts/api/filterTypes'
import type { IWidgetProps } from 'widgets/FilterEditor/types'

import { WidgetIntegrationTrigger } from './WidgetIntegrationTrigger'
import { WidgetIntegrationLabel } from './WidgetIntegrationLabel'
import styles from './styles.module.scss'

export const WidgetIntegration = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [showValuesWarning, setShowValuesWarning] = useState(false)
    const filterConfig = filterItem.config as IResponseFilter<IFilterValue>
    const values = filterConfig.values ?? []
    const payloadValue = useMemo(
      () => (Array.isArray(filterItem.payload.value) ? filterItem.payload.value : []),
      [filterItem.payload.value]
    )
    const [primaryKeys, setPrimaryKeys] = useState<(number | string)[]>([])
    const [selectedKeys, setSelectedKeys] = useState<(number | string)[]>(payloadValue)
    const dropdownItems: IDropdownItem[] = useMemo(
      () =>
        values
          .reduce<[IFilterValue[], IFilterValue[]]>(
            (values, value) => {
              const isSelected = primaryKeys.includes(value.key)

              if (isSelected) values[0].push(value)
              else values[1].push(value)

              return values
            },
            [[], []]
          )
          .flat()
          .map((item, index) => {
            const integrationId = Number(item.key)
            const integration = integrationsStore.getIntegrationById(integrationId)

            return {
              id: item.key,
              label: integration?.name ?? 'Custom',
              labelContent: <WidgetIntegrationLabel insideBox id={item.key} />,
              variant: EnumDropdownItemVariant.Checkbox,
              activeValue: selectedKeys.includes(item.key),
              separator: index === primaryKeys.length - 1 && primaryKeys.length !== values.length,
            }
          }),
      [selectedKeys, primaryKeys]
    )

    useEffect(() => {
      setPrimaryKeys(payloadValue)
    }, [])

    useEffect(() => {
      setTimeout(() => setShowDropdown(expandByDefault), 100)
    }, [expandByDefault])

    return (
      <Select
        text={filterItem.operator?.key ?? ''}
        type='multi'
        items={dropdownItems}
        dropdownProps={{
          ariaLabel: 'Select',
          parentsDropdownId: parentsDropdownId,
          show: showDropdown,
          textFieldProps: {
            InputProps: { placeholder: `Search ${filterItem.config.name}` },
          },
          bottomActions: selectedKeys.length ? (
            <Button
              text='Clear'
              contained='secondary'
              typeBtn='text'
              onClick={() => {
                setPrimaryKeys([])
                setSelectedKeys([])
                filterItem.payload.updateHard({ value: [] })
              }}
            />
          ) : null,
          withSearch: true,
          onClose: () => {
            setPrimaryKeys(selectedKeys)
            setShowValuesWarning(!selectedKeys.length)
          },
          triggerComponent: () => (
            <div className={classNames({ [styles.warning]: showValuesWarning })}>
              <WidgetIntegrationTrigger items={selectedKeys} />
            </div>
          ),
          onChange: (item) => {
            const keys = item.activeValue
              ? selectedKeys.filter((key) => key !== item.id)
              : [...selectedKeys, item.id]

            setShowValuesWarning(false)
            setSelectedKeys(keys)
            filterItem.payload.updateHard({ value: keys })
          },
        }}
      />
    )
  }
)
