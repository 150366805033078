import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Switch } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { billingStore } from 'entities/Billing'

import styles from './styles.module.scss'

type IAutoRechargeProps = {
  openModal?: () => void
}

export const AutoRecharge: FC<IAutoRechargeProps> = observer(({ openModal }) => {
  const { autorecharge } = billingStore

  if (!autorecharge) return null

  const price = numberFormat({ value: 40, minimumFractionDigits: 2 })
  const credit = numberFormat({ value: 1000 })
  const onOpenModal = () => {
    openModal?.()
  }

  return (
    <div className={styles.wrap} onClick={onOpenModal}>
      <Alert
        item={{
          type: 'infoLight',
          title: 'Auto-recharge',
          desc: `Automatically add ${credit} credits ($${price}) to your account when your balance goes below 100 credits.`,
        }}
        rightAction={<Switch value={false} />}
      />
    </div>
  )
})
