// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lhZO9MQNVi4g4rAwZ_fU{width:100%;height:100vh;display:flex;flex-direction:column;background-color:var(--background-primary-inverted-primary)}.lhZO9MQNVi4g4rAwZ_fU .DGC_fB8yzdqWHRW6WzGT{text-align:center}.x1ReZCYbNEw1KGdkOWD4{height:100%}", "",{"version":3,"sources":["webpack://./src/widgets/MemberInvite/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,2DAAA,CAEA,4CACE,iBAAA,CAIJ,sBACE,WAAA","sourcesContent":[".wrap {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  background-color: var(--background-primary-inverted-primary);\n\n  .subtitle {\n    text-align: center;\n  }\n}\n\n.successWrap {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "lhZO9MQNVi4g4rAwZ_fU",
	"subtitle": "DGC_fB8yzdqWHRW6WzGT",
	"successWrap": "x1ReZCYbNEw1KGdkOWD4"
};
export default ___CSS_LOADER_EXPORT___;
