import React from 'react'
import { observer } from 'mobx-react-lite'
import { Icon, Scrollbar, layoutStore } from 'shared/ui'
import { TableCell } from 'shared/ui/Table'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { ImportContactDropdown } from 'widgets/ContactsTable'
import styles from './styles.module.scss'

export const MapFieldsTable = observer(() => {
  const { previewContacts, headersFromFile, doNotMapNameList, mappedFields } = importContactsStore

  const renderPreviewCell = (header: string | number) => {
    const { isViewXs } = layoutStore

    return (
      <TableCell className={styles.previewCell} width={isViewXs ? 160 : 220}>
        {previewContacts.map((contact) => {
          if (!contact[header]) return null

          return (
            <div key={contact[header]} className={styles.previewCellValue}>
              {contact[header]}
            </div>
          )
        })}
      </TableCell>
    )
  }

  const renderMappedCell = (header: string) => {
    const isDoNotMap = doNotMapNameList.includes(header)
    const showIcon = mappedFields[header] || isDoNotMap

    return (
      <TableCell
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          color: isDoNotMap ? 'var(--gray-40)' : 'var(--green-60)',
        }}
        width='100px'
        minWidth='100px'
      >
        {showIcon && <Icon icon={isDoNotMap ? 'minusCircle' : 'check2'} />}
      </TableCell>
    )
  }

  const renderFieldCell = (header: string) => (
    <TableCell
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      }}
      width={isViewXs ? '240px' : '272px'}
    >
      <ImportContactDropdown header={header} />
    </TableCell>
  )

  const { isViewXs } = layoutStore

  const columns = [
    {
      field: 'columnHeader',
      name: 'Column header',
      width: isViewXs ? 160 : 160,
      renderRowCell: (header: string | number) => (
        <TableCell
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
            lineHeight: '16px',
          }}
          className={styles.columnHeaderCell}
          width='160px'
        >
          {header}
        </TableCell>
      ),
    },
    {
      field: 'preview',
      name: 'Preview',
      width: isViewXs ? 160 : 220,
      renderRowCell: renderPreviewCell,
    },
    {
      field: 'mapped',
      name: 'Mapped',
      width: 100,
      renderRowCell: renderMappedCell,
    },
    {
      field: 'field',
      name: 'Field',
      width: 272,
      renderRowCell: renderFieldCell,
    },
  ]

  return (
    <div className={styles.tableContainer}>
      <div className={styles.header}>
        {columns.map((column) => (
          <TableCell key={column.name} minWidth={`${column.width}px`}>
            {column.name}
          </TableCell>
        ))}
      </div>

      <div className={styles.bodyContainer}>
        <Scrollbar>
          {headersFromFile.map((header) => (
            <div key={header} className={styles.row}>
              {columns.map((column) => (
                <React.Fragment key={column.field}>{column.renderRowCell(header)}</React.Fragment>
              ))}
            </div>
          ))}
        </Scrollbar>
      </div>
    </div>
  )
})
