import { makeAutoObservable, runInAction } from 'mobx'
import { StripeCardElementChangeEvent, TokenResult } from '@stripe/stripe-js'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IRadioBoxProps } from 'shared/ui/Radio'
import { billingStore } from 'entities/Billing'
import { Source } from 'entities/Billing/model/Source'
import { IPaymentMethodTypes, getPaymentMethodOptions } from 'widgets/AddPaymentMethodModal'
import { AddPaymentMethodContent } from './../ui/AddPaymentMethodContent'

export class AddPaymentMethodStore {
  private _modalId = 'add_payment_method_id'
  private _activePaymentMethodType: IPaymentMethodTypes | null = null
  private _paymentMethodOptions: IRadioBoxProps<IPaymentMethodTypes>[]
  private _name = ''

  loading = false

  nameError = ''
  cardError = ''
  cardReady = false

  constructor(private _onAddPaymentMethod: (source: Source) => void, zIndex?: number) {
    this._openModal(zIndex)
    this._paymentMethodOptions = getPaymentMethodOptions(zIndex ? zIndex + 100 : undefined)
    makeAutoObservable(this)
  }

  private _closeModal = () => {
    modalStore.removeModal(this._modalId)
  }

  private _openModal = (zIndex?: number) => {
    modalStore.addModal({
      id: this._modalId,
      title: 'Add payment method',
      ModalContent: AddPaymentMethodContent,
      ModalContentProps: {
        addPaymentMethodStore: this,
      },
      primaryAction: {
        text: 'Add card',
        onAction: this._onAddCard,
      },
      width: 480,
      zIndex: zIndex,
    })
  }

  setPaymentMethodType = (value: IPaymentMethodTypes) => {
    this._activePaymentMethodType = value
  }

  setName = (value: string) => {
    this._name = value
  }

  onChangeCard = (event: StripeCardElementChangeEvent) => {
    this.cardReady = event.complete
    this.cardError = event.error?.message || ''
  }

  private _onSubmitCard: (() => Promise<TokenResult | undefined>) | null = null

  setOnSubmit = (onSubmit: () => Promise<TokenResult | undefined>) => {
    this._onSubmitCard = onSubmit
  }

  clearCardError = () => {
    this.cardError = ''
  }
  private _checkError = () => {
    this.nameError = ''
    this.cardError = ''
    if (!this.name.trim().length) {
      this.nameError = 'Cardholder name is required'
    } else {
      this.nameError = ''
    }
    if (!this.cardReady && !this.cardError) {
      this.cardError = 'Credit card is required'
    } else if (this.cardReady) {
      this.cardError = ''
    }
    return !!this.nameError || !!this.cardError
  }
  private _onAddCard = async () => {
    if (this.loading) return
    const isError = this._checkError()
    if (isError) return
    this.loading = true
    if (this._onSubmitCard) {
      const res = await this._onSubmitCard()
      if (!res || res?.error) {
        runInAction(() => {
          this.cardError = res?.error.message || 'Please provide another credit card'
        })
        return
      }
      try {
        const source = await billingStore.createSource({
          card_token: res.token.id,
          name: this._name,
          backup: this.activePaymentMethodType === 'backup',
          primary: this.activePaymentMethodType === 'primary',
          type: 'default',
          confirm: false,
          email: '',
        })
        if (source) {
          this._onAddPaymentMethod(source)
          this._closeModal()
        }
      } catch (e) {
        runInAction(() => {
          this.cardError = 'Please provide another credit card'
        })
        console.error(e)
      }
    }
    runInAction(() => {
      this.loading = false
    })
  }

  get activePaymentMethodType() {
    return this._activePaymentMethodType
  }

  get paymentMethodOptions() {
    return this._paymentMethodOptions
  }

  get name() {
    return this._name
  }
}
