import { observer } from 'mobx-react-lite'
import React from 'react'
import { uiStore } from 'shared/store/uiStore'
import { Actions, variantActionsProps } from 'shared/ui'
import { BroadcastRoutesEnum } from 'entities/Broadcast'
import { contactsStore } from 'entities/Contacts'
import { contactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { useBroadcastDetailOneTimeContext } from 'widgets/BroadcastDetail'
import { selectedModalStore } from 'widgets/SelectContactsModal/store/SelectedModalStore'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'

export const BulkActions = observer(() => {
  const { search, tableStore, onSuccessDelete } = useBroadcastDetailOneTimeContext()
  const { openValidateModal, openDeleteModal, deleteContacts } = selectedModalStore

  const { selectedIds } = tableStore

  const onManageTags = () => {
    contactsTagsModalStore.onOpen({
      ids: selectedIds as number[],
      searchParams: search,
    })
  }

  const onSendBroadcast = () => {
    uiStore.changeRoute({
      path: `${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.create}`,
      options: {
        state: {
          contact_ids: selectedIds,
        },
      },
    })
  }

  const onValidate = () => openValidateModal({ selectedIds: selectedIds as number[], search })

  const onOptIn = async () => {
    const contacts = await contactsStore.getByIds(selectedIds as number[])
    if (contacts) {
      contactsOptInModalStore.handleOpen(contacts, {
        isBulkAll: false,
        search,
      })
    }
  }

  const onDelete = () => {
    const selectedIdsLength = selectedIds.length

    openDeleteModal({
      selectedIdsLength,
      primaryCallback: () =>
        deleteContacts({
          bulkAllMode: false,
          ids: selectedIds as number[],
          search,
          successCallback: onSuccessDelete,
        }),
    })
  }

  return (
    <Actions
      {...variantActionsProps.headerSmall}
      actions={[
        {
          icon: 'tag',
          text: 'Manage tags',
          onAction: onManageTags,
        },
        {
          icon: 'broadcasts',
          text: 'Send broadcast',
          onAction: onSendBroadcast,
        },
        {
          icon: 'check1',
          text: 'Validate',
          onAction: onValidate,
        },
        {
          icon: 'toggle',
          text: 'Opt-in status',
          onAction: onOptIn,
        },
        {
          icon: 'delete',
          text: 'Delete',
          onAction: onDelete,
        },
      ]}
    />
  )
})
