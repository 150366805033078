import React, { forwardRef } from 'react'
import { DayjsFormats, numberFormat } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { Button, DatePicker, IColumn, IconButton, Status } from 'shared/ui'
import { IStatusProps } from 'shared/ui/Status'
import { CustomPricesStatuses } from 'entities/Admin/customPrices/api/type'
import { Payment } from 'pages/admin/ui/AdminManagePaymentPlan/PaymentPlanSchedule/types'

const CustomInputDatePicker = forwardRef(function customInputDatePicker(
  {
    value,
    onClick,
  }: {
    onClick?: () => void
    value?: React.ReactNode
  },
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <Button
      text={value}
      fullWidth
      isRight
      icon={'chevronDown'}
      typeBtn={'outlined'}
      contained={'secondary'}
      size={'medium'}
      onClick={onClick}
      ref={ref}
    />
  )
})

export const statusesMap: Record<CustomPricesStatuses, IStatusProps> = {
  pending: {
    title: 'Pending',
    intent: 'informative',
    emphasis: 'high',
  },
  succeeded: {
    title: 'Success',
    intent: 'positive',
    emphasis: 'high',
  },
  scheduled: {
    title: 'Scheduled',
    intent: 'informative',
    emphasis: 'high',
  },
  draft: {
    title: 'Draft',
    intent: 'neutral',
    emphasis: 'high',
  },
  failed: {
    title: 'Failed',
    intent: 'negative',
    emphasis: 'high',
  },
}

export function getColumns(
  handleDeletePaymentPlanItem: (id: string) => void,
  handleChangeDatePaymentPlanItem: (date: Date | null, id: string) => void,
  handleRetryPaymentPlanItem: (id: string) => void
): IColumn<Payment>[] {
  return [
    {
      field: 'name',
      name: 'Name',
    },
    {
      field: 'status',
      name: 'Status',
      renderRowCell: (row) => <Status {...statusesMap[row.status]} />,
    },
    {
      field: 'date',
      name: 'Date',
      renderRowCell: (row) => {
        if (row.editable) {
          return (
            <DatePicker
              date={row.payment_date.toDate()}
              handleDate={(date) => handleChangeDatePaymentPlanItem(date, row.id)}
              portalId='calendar-portal'
              showIcon={false}
              withBorder
              customInput={<CustomInputDatePicker />}
            />
          )
        }

        return (
          (row.payment_date || row.payment_date) &&
          uiStore.dayjs(row.payment_date).format(DayjsFormats.full6)
        )
      },
    },
    {
      field: 'amount',
      name: 'Amount',
      renderRowCell: (row) => numberFormat({ value: row.amount, currency: 'USD' }),
    },
    {
      field: 'credits',
      name: 'Credits',
      renderRowCell: (row) => numberFormat({ value: row.credits }),
    },
    {
      field: 'id',
      renderHeaderCell: () => null,
      renderRowCell: (row) => {
        if (row.deletable) {
          return (
            <IconButton
              onClick={() => handleDeletePaymentPlanItem(row.id)}
              color={'tertiary'}
              icon={'delete'}
              variant={'icon'}
              ariaLabel='CustomPaymentsScheduleTable_delete'
            />
          )
        }

        if (row.retry) {
          return (
            <IconButton
              onClick={() => handleRetryPaymentPlanItem(row.id)}
              color={'tertiary'}
              icon={'refresh'}
              variant={'icon'}
              ariaLabel='CustomPaymentsScheduleTable_retry'
            />
          )
        }

        return ' '
      },
    },
  ]
}
