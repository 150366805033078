// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rg0QRehDpLHV6I5uGSym{padding:12px 8px 4px 12px;margin-right:4px;height:116px;overflow:auto;outline:none;font-size:14px;line-height:142.8%}.Rg0QRehDpLHV6I5uGSym[placeholder]:empty::after{color:var(--content-neutral-primary);content:attr(placeholder);cursor:text}", "",{"version":3,"sources":["webpack://./src/widgets/TextToSpeech/ui/TextToSpeechText/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,gBAAA,CACA,YAAA,CACA,aAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CACA,gDACE,oCAAA,CACA,yBAAA,CACA,WAAA","sourcesContent":[".wrap{\n  padding: 12px 8px 4px 12px;\n  margin-right: 4px;\n  height: 116px;\n  overflow: auto;\n  outline: none;\n  font-size: 14px;\n  line-height: 142.8%;\n  &[placeholder]:empty::after {\n    color: var(--content-neutral-primary);\n    content: attr(placeholder);\n    cursor: text;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Rg0QRehDpLHV6I5uGSym"
};
export default ___CSS_LOADER_EXPORT___;
