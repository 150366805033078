import { Box } from '@mui/material'
import { FC } from 'react'
import { Icon, Typography } from 'shared/ui'
import { type IFilterValue } from 'entities/Filters/types'
import styles from './styles.module.scss'

type IWidgetMultiStatusTriggerProps = {
  selectedKeys: string[]
  items: IFilterValue[]
}

export const WidgetMultiRecurringTypeTrigger: FC<IWidgetMultiStatusTriggerProps> = ({
  selectedKeys,
  items,
}) => {
  const selectedItems = items.filter((item) => selectedKeys.some((key) => key === item.key))

  if (!selectedItems.length) return <>select</>

  return (
    <Box display={'flex'} gap={1} alignItems={'center'} height={32}>
      <div className={styles.group}>
        <Icon icon={'repeat'} tertiary fontSize={12} />
      </div>
      <Typography ariaLabel={'Inbox'} variant={'body-md-regular'}>
        {selectedItems.length > 1 ? `${selectedItems.length} statuses` : selectedItems[0].label}
      </Typography>
    </Box>
  )
}
