import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Status } from 'shared/ui'
import type { Contact } from 'entities/Contacts/model/Contact'
import { ChatbotEnrollStore } from 'widgets/ChatbotEnroll/store/ChatbotEnrollStore'

type IChatbotEnrollButtonProps = {
  contacts: Contact[]
}

export const ChatbotEnrollButton = observer(({ contacts }: IChatbotEnrollButtonProps) => {
  const store = useMemo(() => new ChatbotEnrollStore(), [])

  const blockReason = store.getBlockReason(contacts)

  useEffect(() => store.checkActivity(), [])

  return (
    <Button
      text='Engage with Textbot'
      icon='chatBot'
      typeBtn='contained'
      contained='tertiary'
      size='small'
      textRight={<Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />}
      onClick={() => store.enrollContacts(contacts)}
      disabled={!!blockReason}
      tooltipProps={{
        placement: 'bottom-start',
        label: blockReason,
      }}
    />
  )
})
