// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H7gXgcrGeCEwI2kXk91w{display:flex;align-items:center;justify-content:center;width:44px;height:40px;border-radius:6px;margin-bottom:2px;font-size:18px;color:var(--content-primary-tertiary);cursor:pointer}.H7gXgcrGeCEwI2kXk91w.fpxskVH_wxazYgsvCtdQ,.H7gXgcrGeCEwI2kXk91w:hover{background:var(--background-neutral-default);color:var(--content-primary-tertiary)}.H7gXgcrGeCEwI2kXk91w.fpxskVH_wxazYgsvCtdQ{box-shadow:0 4px 12px -2px rgba(18,19,23,.03)}.fBuhpcA1FWmYbyGO8JoU{height:423px}.fBuhpcA1FWmYbyGO8JoU .e1MhTz9GAmV2C_1q1GsQ{border:none;height:100%}", "",{"version":3,"sources":["webpack://./src/widgets/Sidebar/ui/SettingsDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,qCAAA,CACA,cAAA,CAEA,uEAEE,4CAAA,CACA,qCAAA,CAGF,2CACE,6CAAA,CAIJ,sBACE,YAAA,CAEA,4CACE,WAAA,CACA,WAAA","sourcesContent":[".button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 44px;\n  height: 40px;\n  border-radius: 6px;\n  margin-bottom: 2px;\n  font-size: 18px;\n  color: var(--content-primary-tertiary);\n  cursor: pointer;\n\n  &.active,\n  &:hover {\n    background: var(--background-neutral-default);\n    color: var(--content-primary-tertiary);\n  }\n\n  &.active {\n    box-shadow: 0 4px 12px -2px rgba(18, 19, 23, 0.03);\n  }\n}\n\n.iframeWrapper {\n  height: 423px;\n\n  .iframe {\n    border: none;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "H7gXgcrGeCEwI2kXk91w",
	"active": "fpxskVH_wxazYgsvCtdQ",
	"iframeWrapper": "fBuhpcA1FWmYbyGO8JoU",
	"iframe": "e1MhTz9GAmV2C_1q1GsQ"
};
export default ___CSS_LOADER_EXPORT___;
