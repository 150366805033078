// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k3hy1F058daVqk8IGGNH{display:flex;flex-direction:column;gap:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/MonthDaysSelector/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "k3hy1F058daVqk8IGGNH"
};
export default ___CSS_LOADER_EXPORT___;
