import { Icon } from 'shared/ui'
import styles from './styles.module.scss'

export const RequestAccessItem = () => {
  return (
    <div className={styles.item}>
      <Icon icon='lock' color='var(--content-primary-tertiary)' /> <p>Request access</p>
    </div>
  )
}
