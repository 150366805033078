import { Icon, Typography } from 'shared/ui'
import DragAndDropFile from 'shared/ui/DragAndDropFile/DragAndDropFile'

import styles from './styles.module.scss'
import { useComplianceSettingsContext } from '../context/complianceSettingsContext'

export const UploadDoc = () => {
  const {
    einStore: { uploadIrsEinAttachment },
  } = useComplianceSettingsContext()

  return (
    <div className={styles.dragAndDropContainer}>
      <Icon fontSize={20} icon='upload' color='var(--gray-60)' className={styles.uploadIcon} />
      <Typography
        ariaLabel='DragAndDrop_line'
        variant='body-sm-medium'
        color='var(--content-primary-primary)'
      >
        Upload or drag & drop the file here
      </Typography>
      <Typography
        ariaLabel='DragAndDrop_line'
        variant='body-sm-medium'
        color='var(--content-primary-tertiary)'
      >
        Acceptable file types: pdf, jpg, png
      </Typography>
      <DragAndDropFile onDrop={(files) => uploadIrsEinAttachment(files[0])} />
    </div>
  )
}
