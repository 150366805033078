// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ss9UeX_wdNOrhiFi42JK{display:flex;align-items:center}.gRv5EO2Fq7semVzBV1gY{color:var(--content-primary-primary-inverted);font-size:13px;font-weight:400}.gRv5EO2Fq7semVzBV1gY .y7IQ5IpMCchXVFqvyfhC{color:var(--content-primary-primary-inverted);text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/shared/ui/Tooltip/ui/TooltipEmailDomain/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,6CAAA,CACA,cAAA,CACA,eAAA,CAEA,4CACE,6CAAA,CACA,yBAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n}\n\n.label {\n  color: var(--content-primary-primary-inverted);\n  font-size: 13px;\n  font-weight: 400;\n\n  .domain {\n    color: var(--content-primary-primary-inverted);\n    text-decoration: underline;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ss9UeX_wdNOrhiFi42JK",
	"label": "gRv5EO2Fq7semVzBV1gY",
	"domain": "y7IQ5IpMCchXVFqvyfhC"
};
export default ___CSS_LOADER_EXPORT___;
