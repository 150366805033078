import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CancelTokenSource, CanceledError } from 'axios'
import { debounce } from 'lodash'
import { TagsApi } from 'entities/Tags/api/tags'
import { Tag } from 'entities/Tags/model/Tag'

// PAGE_LENGTH must be multiple of FIRST_PAGE_LENGTH
const FIRST_PAGE_LENGTH = 100
const PAGE_LENGTH = 20

export class TagsSearch {
  private _page = 1
  private _perPage = FIRST_PAGE_LENGTH
  private _total = Number.POSITIVE_INFINITY
  private _loading = true

  private _term = ''
  private _excluded: number[] = []

  private _tagsMap = new Map<number, Tag>()
  private _cancelTokenSource: CancelTokenSource | null = null

  constructor() {
    makeAutoObservable(this)
  }

  private _initCancelTokenSource = () => {
    if (this._cancelTokenSource) this._cancelTokenSource.cancel()

    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _tryMakeRequest = debounce(async (action: () => Promise<void>) => {
    runInAction(() => {
      this._loading = true
      this._initCancelTokenSource()
    })

    try {
      await action()
      runInAction(() => {
        this._loading = false
      })
    } catch (error) {
      runInAction(() => {
        this._loading = error instanceof CanceledError
      })

      console.error(error)
    }
  }, 500)

  get hasMore() {
    return this._loading || this._page * this._perPage <= this._total
  }

  get loading() {
    return this._loading
  }

  get tags() {
    return Array.from(this._tagsMap.values())
  }

  get empty() {
    return !this._tagsMap.size
  }

  get term() {
    return this._term
  }

  getTag = (id: number) => this._tagsMap.get(id)

  loadMore = () =>
    this._tryMakeRequest(async () => {
      const nextPage =
        this._page === 1 ? this._page + FIRST_PAGE_LENGTH / PAGE_LENGTH : this._page + 1

      const {
        data: { data, total, current_page, per_page },
      } = await TagsApi.getTagsPerPage(
        { search: this._term, exclude: this._excluded, page: nextPage, length: PAGE_LENGTH },
        { cancelToken: this._cancelTokenSource?.token }
      )

      runInAction(() => {
        data.forEach((result) => {
          const newTag = new Tag(result)

          this._tagsMap.set(newTag.id, newTag)
        })
        this._total = total
        this._page = current_page
        this._perPage = per_page
      })
    })

  searchTags = (term: string, exclude: number[] = []) =>
    this._tryMakeRequest(async () => {
      runInAction(() => this._tagsMap.clear())

      const {
        data: { data, total, current_page, per_page },
      } = await TagsApi.getTagsPerPage(
        { search: term, exclude, page: 1, length: FIRST_PAGE_LENGTH },
        { cancelToken: this._cancelTokenSource?.token }
      )

      runInAction(() => {
        data.forEach((tag) => {
          const newTag = new Tag(tag)

          this._tagsMap.set(newTag.id, newTag)
        })

        this._term = term
        this._excluded = exclude

        this._total = total
        this._page = current_page
        this._perPage = per_page
      })
    })

  reset = () => {
    this._cancelTokenSource?.cancel()
    this._tagsMap.clear()

    this._page = 1
    this._perPage = FIRST_PAGE_LENGTH
    this._total = Number.POSITIVE_INFINITY
    this._loading = true

    this._term = ''
    this._excluded = []
  }
}
