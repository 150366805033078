import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import { uiStore } from 'shared/store/uiStore'
import { makeTextFieldLoading, TextField } from 'shared/ui/TextField'
import styles from 'widgets/CallHistory/ui/styles.module.scss'
import { useCallHistoryContext } from 'widgets/CallHistory'

export const CallHistorySearch = observer(() => {
  const { startFocusingMode, endFocusingMode } = uiStore
  const { search, handleSearch, loadingSearch } = useCallHistoryContext()
  const ref = useRef<HTMLInputElement | null>(null)

  return (
    <div className={styles.searchInputWrap}>
      <TextField
        className={styles.textField}
        variant='integrated'
        InputProps={{
          placeholder: 'Search...',
          onFocus: startFocusingMode,
          onBlur: endFocusingMode,
          ref: ref,
        }}
        onChange={handleSearch}
        value={search || ''}
        rightActions={[makeTextFieldLoading({ loading: loadingSearch })]}
      />
    </div>
  )
})
