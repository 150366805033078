import { memo } from 'react'

import { StepEnrollment } from '../StepEnrollment'
import { StepFirstMessage } from '../StepFirstMessage'
import { StepConfigure } from '../StepConfigure'
import { StepObjective } from '../StepObjective'
import { StepSuccessActions } from '../StepSuccessActions'
import { StepFallbackActions } from '../StepFallbackActions'

import styles from './styles.module.scss'

const ChatbotBodyContent = () => (
  <div className={styles.body}>
    <StepEnrollment />
    <StepFirstMessage />
    <StepConfigure />
    <StepObjective />
    <StepSuccessActions />
    <StepFallbackActions />
  </div>
)

export const ChatbotBody = memo(ChatbotBodyContent)
