import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { CustomField, customFieldOperation, CustomFieldsStore } from 'entities/CustomField'
import { Chatbot, ChatbotField } from 'entities/Chatbot'

const FIELDS_LIMIT = 100
const FIELDS_TO_EXCLUDE = ['number', 'national_number', 'formatted_number']

export class ChatbotFieldsControl {
  termFields: CustomField[] = []
  defaultFields: CustomField[] = []
  customFields: CustomField[] = []

  private _store = new CustomFieldsStore(FIELDS_LIMIT)
  private _disposeFields: IReactionDisposer | null = null
  private _disposeTermFields: IReactionDisposer | null = null
  private _request: null | Promise<void> = null

  constructor(private _chatbot: Chatbot) {
    makeAutoObservable(this)

    // TODO: must be moved to back-end on next iteration
    this._disposeTermFields = reaction(
      () => this._store.termFields,
      (termFields) => {
        this.termFields = termFields.filter((field) => !FIELDS_TO_EXCLUDE.includes(field.key))
      }
    )

    this._disposeFields = reaction(
      () => [this.termFields, this._chatbot.fields.list] as [CustomField[], ChatbotField[]],
      ([searchFields, chatbotFields]) => {
        const [defaultFields, customFields] = searchFields.reduce(
          ([defaultFields, customFields], field) => {
            const targetFields = field.key.startsWith('custom.') ? customFields : defaultFields
            const isNew = chatbotFields.findIndex(({ key }) => key === field.key) < 0

            if (isNew) targetFields.push(field)

            return [defaultFields, customFields]
          },
          [[] as CustomField[], [] as CustomField[]]
        )

        this.defaultFields = defaultFields
        this.customFields = customFields
      }
    )
  }

  init = () => {
    if (this._request) return this._request

    this._request = this._store.loadData()

    return this._request
  }

  getName = (fieldKey: string) => {
    return this._store.fields.find(({ key }) => key === fieldKey)?.name
  }

  createField = async () => {
    const field = await customFieldOperation.createField()
    if (!field) return null

    await this._store.reloadData()

    return field
  }

  searchFields = (term = '') => {
    this._store.searchFields(term)
  }

  clearSearch = () => {
    this._store.clearSearch()
  }

  dispose = () => {
    this._disposeFields?.()
    this._disposeTermFields?.()
  }
}
