// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mPHqBn1V_VxF0BaqxHV3{position:relative;display:flex;flex-grow:1;margin-left:16px}.gZoaMu2_Dy1TL_4894fg{display:flex;align-items:center;justify-content:space-between;gap:12px}.vaDpyyPOXYwAOOdAcqrh{display:flex;align-items:center;gap:12px}", "",{"version":3,"sources":["webpack://./src/widgets/TriggerDetail/ui/ContactStatisticsTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":[".wrapTable {\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  margin-left: 16px;\n}\n\n.contactColumn {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 12px;\n\n  &Name {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTable": "mPHqBn1V_VxF0BaqxHV3",
	"contactColumn": "gZoaMu2_Dy1TL_4894fg",
	"contactColumnName": "vaDpyyPOXYwAOOdAcqrh"
};
export default ___CSS_LOADER_EXPORT___;
