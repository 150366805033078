import { observer } from 'mobx-react-lite'
import { useChatbotContext } from 'pages/chatbot/context'

import { CommonTextarea } from './CommonTextarea'

export const PointInstructions = observer(() => {
  const {
    errors,
    model: { settings },
  } = useChatbotContext()

  const instructionsError = errors.getError('settings.instructions.value')

  const handleChange = (value: string) => {
    settings.setInstruction(value)
    errors.removeError('settings.instructions')
  }

  return (
    <CommonTextarea
      limit={7000}
      rows={8}
      name='Add instructions for the Textbot to follow:'
      tooltip={`Instructions can include Textbot name, 
relevant information about the task at 
hand, tone of voice and other instructions`}
      placeholder='Answer customer questions about store hours, return policies, or product specifications.'
      error={instructionsError}
      value={settings.instruction}
      onChange={handleChange}
    />
  )
})
