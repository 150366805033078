import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import classNames from 'classnames'
import fullLogoImg from 'shared/assets/images/fullLogo.png'
import { Button, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { CDN_FRONT_URL } from 'shared/config'

import { links } from 'shared/constants/links'
import styles from './styles.module.scss'

type IStepFormLayout = {
  form: ReactNode
  title: string
  subtitle: ReactNode | string
  onBack?: () => void
}

export const StepFormLayout = observer(({ form, title, subtitle, onBack }: IStepFormLayout) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.logoWrap}>
          <a href={links.website} target='_blank' rel='noreferrer'>
            <img className={styles.logo} alt='logo' src={`${CDN_FRONT_URL}${fullLogoImg}`} />
          </a>
        </div>
        {onBack && (
          <Button
            className={styles.backButton}
            icon={'chevronLeft'}
            typeBtn={'stroke'}
            text={'Back'}
            onClick={onBack}
          />
        )}
        <Typography
          className={classNames(styles.title, {
            [styles.withBackButton]: !!onBack,
          })}
          ariaLabel={getAriaLabel('signUp-step-subtitle')}
          variant={'body-lg-regular'}
          color={'var(--content-primary-primary)'}
        >
          {title}
        </Typography>
        <Typography
          className={styles.subtitle}
          ariaLabel={getAriaLabel('signUp-step-subtitle')}
          variant={'body-lg-regular'}
          color={'var(--content-primary-tertiary)'}
        >
          {subtitle}
        </Typography>
        <div className={styles.form}>{form}</div>
      </div>
    </div>
  )
})

export default StepFormLayout
