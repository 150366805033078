import { Icon, Typography } from 'shared/ui'

import styles from './styles.module.scss'

export const ExpiredForm = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.iconWrap}>
        <Icon
          className={styles.icon}
          icon={'close'}
          color={'var(--content-primary-primary-inverted)'}
        />
      </div>
      <Typography
        marginTop={24}
        variant={'heading-lg'}
        ariaLabel={'title'}
        color={'var(--content-primary-primary)'}
      >
        Your link has expired
      </Typography>
      <Typography
        marginTop={8}
        variant={'body-lg-regular'}
        ariaLabel={'subtitle'}
        color={'var(--content-primary-tertiary)'}
      >
        This password reset token is invalid
      </Typography>
    </div>
  )
}

export default ExpiredForm
