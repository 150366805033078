import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import type { IActionsProps } from 'shared/ui'
import { ScheduleStore } from 'shared/ui/Schedule'
import { logger } from 'shared/lib'
import type {
  IResponseBroadcastById,
  IResponseOneTimeBroadcast,
  IResponseRecurringBroadcast,
  IResponseRecurringBroadcastById,
  IResponseRecurringBroadcastStatus,
} from 'entities/Broadcast/api/types'
import type { IBroadcastActionTypes, IBroadcastType } from 'entities/Broadcast'
import { BroadcastRecurringChild } from 'entities/Broadcast/model/BroadcastRecurringChild'
import { BroadcastApi } from 'entities/Broadcast/api/broadcast'
import { usersStore } from 'entities/Users'
import { BroadcastRecurringStatistic } from 'entities/Broadcast/model/BroadcastRecurringStatistic'

export class BroadcastRecurring {
  id: number
  type!: IBroadcastType
  name!: string
  owner_id!: number
  status!: IResponseRecurringBroadcastStatus
  created_at!: string
  failed_reason!: string | null
  statistics: BroadcastRecurringStatistic | null = null

  is_can_expanded = true
  fromDetailPage = false

  settings?: IResponseRecurringBroadcast['settings'] | null = null
  scheduleStore = new ScheduleStore({
    sendLaterStoreProps: {
      withAdvancedScheduling: true,
    },
  })

  constructor(
    item: IResponseRecurringBroadcast | IResponseRecurringBroadcastById,
    fromDetailPage?: boolean
  ) {
    this.id = item.id
    this.fromDetailPage = !!fromDetailPage
    this.syncOrigin(item)

    if (fromDetailPage) {
      this.onLoadExpandedData()
    }

    makeAutoObservable(this)
  }

  syncOrigin = (item: IResponseRecurringBroadcast | IResponseRecurringBroadcastById) => {
    this.owner_id = item.owner_id
    this.name = item.name
    this.created_at = item.created_at
    this.type = item.is_rvm ? 'ringless' : 'sms'

    if ('recurring_template_settings' in item) {
      if (this.status && item.recurring_template_settings.status !== this.status) {
        this.updateChildren()
      }
      this.status = item.recurring_template_settings.status
      this.settings = item.recurring_template_settings
      this.statistics = new BroadcastRecurringStatistic(item)
      this.failed_reason = item.failed_reason
    } else {
      if (this.status && item.settings.status !== this.status) {
        this.updateChildren()
      }
      this.status = item.settings.status
      this.settings = item.settings
      this.failed_reason = null
    }
    this.scheduleStore.init({
      type: 'recurring',
      isDisabledSendLater: false,
      config: {
        repeatPeriod: this.settings.type,
        customInterval: this.settings.send_settings?.interval,
        customPeriod: this.settings.send_settings?.type,
        date: this.created_at,
        endRepeat: this.settings?.duration_settings?.type,
        endDate: this.settings?.duration_settings?.date,
        endTimes: this.settings?.duration_settings?.occurrences,
        isUseContactTimezone: false,
        advancedDate: {
          days: this?.settings.send_settings?.schedule.days,
          isAdvancedScheduling: !!this?.settings.send_settings?.schedule?.days.length,
          time: '9:00',
        },
        isDisabledRepeat: false,
        isDisabledEnd: false,
        monthDaysSelectorStoreProps: {
          type: this.settings.send_settings?.recurring_month_is_calendar ? 'each' : 'onThe',
          days: this.settings.send_settings?.recurring_dates,
          onTheDayOfWeek: this.settings.send_settings?.recurring_month_nth_day,
          onTheOrdinal: this.settings.send_settings?.recurring_month_nth_type,
        },
      },
    })
  }

  expanded = false
  loading = true
  loadingMore = false
  page = 1
  is_last_page = true

  cancelTokenSource: CancelTokenSource | null = null

  childrenMap: Map<number, BroadcastRecurringChild> = new Map<number, BroadcastRecurringChild>()

  get rows() {
    return Array.from(this.childrenMap.values())
  }

  actionsProps: IActionsProps | null = null

  setActionsProps: (actionProps: IActionsProps) => void = (actionProps: IActionsProps) => {
    this.actionsProps = actionProps
  }

  get actionTypes(): IBroadcastActionTypes[] {
    return ['edit']
  }

  get user() {
    const userFormStore = usersStore.getItem(this.owner_id)
    if (userFormStore) {
      return userFormStore
    } else {
      return usersStore.getItem(this.owner_id, true)
    }
  }

  handleUpdate = (response: IResponseBroadcastById) => {
    if (response.status === 'recurring_template') {
      this.syncOrigin(response)
    } else {
      this.updateChildren(response)
    }
  }

  private updateChildren = (response?: IResponseOneTimeBroadcast) => {
    if (response) {
      const children = this.childrenMap.get(response.id)
      if (children) {
        children.syncOrigin(response)
      }
    } else if (this.fromDetailPage || this.expanded) {
      this.loading = true
      this.is_last_page = true
      this.page = 1
      this.onLoadExpandedData(true)
    }
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  onLoadExpandedData = async (fromUpdate?: boolean) => {
    try {
      this.initCancelTokenSource()
      const { data } = await BroadcastApi.getRecurringBroadcasts(
        this.id,
        {
          page: this.page,
        },
        { ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null) }
      )
      if (fromUpdate) {
        this.childrenMap = new Map<number, BroadcastRecurringChild>(
          data.data.map((item) => [item.id, new BroadcastRecurringChild(item, this.id)])
        )
      } else {
        data.data.forEach((item) => {
          this.childrenMap.set(item.id, new BroadcastRecurringChild(item, this.id))
        })
      }

      this.is_last_page = data.meta.is_last_page
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  toggleExpand = async () => {
    this.childrenMap.clear()
    this.is_last_page = true
    this.page = 1
    this.expanded = !this.expanded
    try {
      if (this.expanded) {
        await this.onLoadExpandedData()
      }
    } catch (e) {
      logger.error(e)
    }
  }

  get isMoreExpand() {
    return !this.is_last_page
  }

  onLoadMore = async () => {
    try {
      this.page = this.page + 1
      this.loadingMore = true
      await this.onLoadExpandedData()
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loadingMore = false
      })
    }
  }

  onDelete = (ids: number[]) => {
    ids.forEach((id) => {
      this.childrenMap.delete(id)
    })
  }

  get recurringTitle() {
    return this.scheduleStore.repeatText
  }

  get isInvalid() {
    return this.status === 'invalid'
  }
}
