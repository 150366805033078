import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { ReactNode } from 'react'
import { ITabItem } from 'shared/ui'
import type { IParamsTestTrigger, IParamsTrigger } from 'entities/Trigger/api/type'
import type { Phone } from 'entities/Phone/model/Phone'
import { numbersStore } from 'entities/Phone'
import { inboxesStore } from 'entities/Inbox'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import {
  EnumModeTrigger,
  EnumTypeTrigger,
  ITriggerCreateField,
} from 'widgets/TriggerView/store/types'
import { TriggerSMSStore } from 'widgets/TriggerView/ui/TriggerSMS/store/TriggerSMSStore'
import { IntegrationDropdownStore } from 'widgets/IntegrationDropdown'
import { SendFromDropdownStore } from 'widgets/SendFromDropdown'
import { TrackConversionStore } from 'widgets/TrackConversions/store/trackConversionStore'

export class TriggerViewStore {
  loading = true
  name = ''
  mode: EnumModeTrigger = EnumModeTrigger.Create

  private _type: EnumTypeTrigger = EnumTypeTrigger.Sms
  private _disposeIsAircallReaction: IReactionDisposer | null = null
  private _isOpenAdvanced = false

  triggerSMSStore = new TriggerSMSStore({
    onFocus: () => {
      this.setError('smsMessage', '')
    },
  })
  errorsMap: Map<ITriggerCreateField, ReactNode> = new Map()
  integrationDropdownStore = new IntegrationDropdownStore()
  sendFromDropdownStore = new SendFromDropdownStore()
  trackConversionStore = new TrackConversionStore({
    clearError: () => {},
  })

  constructor() {
    makeAutoObservable(this)

    void this.init()
  }

  init = async () => {
    runInAction(() => {
      this.loading = true
    })
    void this.integrationDropdownStore.init()
    void this.sendFromDropdownStore.init()

    this.reactionIsAircallInbox()

    runInAction(() => {
      this.loading = false
    })
  }

  reset = () => {
    this._disposeIsAircallReaction?.()
  }

  reactionIsAircallInbox = () => {
    this._disposeIsAircallReaction?.()
    this._disposeIsAircallReaction = reaction(
      () => this.sendFromDropdownStore.isAircall,
      (isAircall) => {
        this.triggerSMSStore.messageFieldStore.setIsCurrentAirCall(isAircall)
      }
    )
  }

  onTestSMSTrigger = (params: IParamsTestTrigger) => TriggerApi.createTest(params)

  selectTypeTab = (tab: ITabItem<EnumTypeTrigger>) => {
    this._type = tab.key
  }

  setName = (name: string) => {
    this.name = name
  }

  setOpenAdvanced = (value: boolean) => {
    this._isOpenAdvanced = value
  }

  setError = (key: ITriggerCreateField, error: ReactNode) => {
    if (error) this.errorsMap.set(key, error)
    else this.errorsMap.delete(key)
  }

  get tabs(): ITabItem<EnumTypeTrigger>[] {
    return [
      {
        key: EnumTypeTrigger.Sms,
        name: 'SMS',
      },
      {
        key: EnumTypeTrigger.Ringless,
        name: 'Ringless',
        disabled: true,
      },
    ]
  }

  get type() {
    return this._type
  }

  get messageTypeParams() {
    return this.triggerSMSStore.messageDataParams
  }

  get isOpenAdvanced() {
    return this._isOpenAdvanced
  }

  get number(): Phone | undefined | null {
    const sendFrom = this.sendFromDropdownStore.params
    if (!sendFrom) return

    if (sendFrom.options.number_id) {
      return numbersStore.getItem(sendFrom?.options.number_id)
    }

    if (sendFrom.options.team_id) {
      const inbox = inboxesStore.getItem(sendFrom.options.team_id)

      if (inbox && inbox.type === 'inbox') {
        const numberId = inbox?.sendingOptions?.find(
          (item) => item.key === sendFrom?.options?.smart_option
        )?.number_ids[0]

        return numbersStore.getItem(numberId)
      }
    }
  }

  get triggerParams(): IParamsTrigger {
    return {
      id: null,
      name: this.name,

      sendFrom: this.sendFromDropdownStore.params,

      is_track_conversion: this.trackConversionStore.is_track_conversion,
      conversion_id: this.trackConversionStore.conversion_id,

      ...this.integrationDropdownStore.params,
      ...this.messageTypeParams,
    }
  }
}
