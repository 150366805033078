import { ConversationsApi, conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { IResponseConversation } from 'entities/Conversation/api/types'

const openNewConversation = (contact: Contact) => {
  conversationStore.setSignal({
    type: 'open_new_conversation',
    payload: contact,
  })
}

const openConversation = (conversation: IResponseConversation) =>
  conversationStore.setSignal({
    type: 'open_exist_conversation',
    payload: conversation,
  })

const openGroupConversation = (contact: Contact, conversation: IResponseConversation) =>
  conversationStore.setSignal({
    type: 'open_group_conversation',
    payload: {
      conversation: conversation,
      contact: contact,
    },
  })

export const openConversationByContact = async (contact: Contact) => {
  try {
    const {
      data: { results: conversations },
    } = await ConversationsApi.getConversationsSearch({
      tags: [],
      teams: [],
      members: [],
      contacts: [contact.id],
      page: 1,
    })

    switch (conversations.length) {
      case 0:
        return openNewConversation(contact)
      case 1:
        return await openConversation(conversations[0])
      default:
        return await openGroupConversation(contact, conversations[0])
    }
  } catch (error) {
    console.log(error)

    return true
  }
}

export const openConversationByContactId = async (contactId: number) => {
  try {
    const contact = await contactsStore.getById(contactId)

    if (contact) return openConversationByContact(contact)
  } catch (error) {
    console.log(error)

    return true
  }
}

export const openConversationById = async (conversationId: number) => {
  try {
    const { data } = await ConversationsApi.getById(conversationId)

    return openConversation(data)
  } catch (error) {
    console.log(error)

    return true
  }
}
