import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Button, Switch, Typography } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { complianceStore } from 'entities/Compliance'
import { adminTenDLCDetailStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/store/adminTenDLCDetailStore'
import { adminTenDLCBusinessProfileStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BusinessProfile/store/adminTenDLCBusinessProfileStore'
import { adminTenDLCBrandStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/BrandInformation/store/adminTenDLCBrandStore'
import { adminTenDLCCampaignStore } from 'pages/admin/pages/compliance/pages/TenDLCDetails/ui/cards/Campaign/store/adminTenDLCCampaignStore'
import styles from './styles.module.scss'

// const mockProviders: IDropdownItem[] = [
//   {
//     id: 'twilio',
//     label: 'Twilio',
//   },
// ]

export const ReviewContent = observer(() => {
  // const [provider, setProvider] = useState<'twilio'>('twilio')
  const [businessProfile, setBusinessProfile] = useState(false)
  const [brand_and_campaign, set_brand_and_campaign] = useState(false)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    complianceStore.loadProviders()
  }, [])

  const onPublish = async () => {
    try {
      if (!adminTenDLCDetailStore.id) {
        return
      }
      setLoading(true)
      await adminTenDLCDetailStore.onReviewTenDLC({
        brand_and_campaign,
        business_profile: businessProfile,
        // compliance_provider: 'twilio',
        organization_id: adminTenDLCDetailStore.id,
      })
    } finally {
      setLoading(false)
    }
  }

  const onCancel = () => {
    modalStore.closeModal(adminTenDLCDetailStore.idReviewModal)
  }

  return (
    <div className={styles.wrap}>
      {/* <Select */}
      {/*   type={'default'} */}
      {/*   text={'Select'} */}
      {/*   value={provider} */}
      {/*   items={(complianceStore.providers.length && complianceStore.providers) || mockProviders} */}
      {/*   onSelectValue={setProvider} */}
      {/*   fullWidth */}
      {/*   btnProps={{ */}
      {/*     size: 'extraLarge', */}
      {/*     fullWidth: true, */}
      {/*     typeBtn: 'outlined', */}
      {/*     style: { */}
      {/*       justifyContent: 'space-between', */}
      {/*       color: 'var(--content-primary-primary)', */}
      {/*       fontWeight: 400, */}
      {/*       fontSize: '13px', */}
      {/*       height: '40px', */}
      {/*     }, */}
      {/*   }} */}
      {/* /> */}
      <div className={styles.wrapButtons}>
        {adminTenDLCBusinessProfileStore.isForReview && (
          <div className={styles.btn}>
            <Typography
              variant={'text-input-field'}
              color={'tertiary'}
              ariaLabel={'PublishBusinessProfile'}
            >
              Publish business profile
            </Typography>
            <Switch value={businessProfile} onChange={setBusinessProfile} />
          </div>
        )}
        {(adminTenDLCBrandStore.isForReview || adminTenDLCCampaignStore.isForReview) && (
          <div className={styles.btn}>
            <Typography
              variant={'text-input-field'}
              color={'tertiary'}
              ariaLabel={'PublishBrandAndCampaign'}
            >
              Publish brand and campaign
            </Typography>
            <Switch value={brand_and_campaign} onChange={set_brand_and_campaign} />
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <Button text={'Cancel'} contained={'secondary'} onClick={onCancel} />
        <Button
          text={'Publish'}
          contained={'primary'}
          disabled={!businessProfile && !brand_and_campaign}
          onClick={onPublish}
          loading={loading}
        />
      </div>
    </div>
  )
})
