import { getDropdownItemsFromStatusConfig } from 'shared/ui/Status'
import {
  tenDLCBusinessProfileStatusConfig,
  tenDLCBrandStatusConfig,
  tenDLCCampaignStatusConfig,
} from 'entities/Compliance/ui/TenDLCStatus/config'

export const tenDLCBusinessProfileStatusesDropdownItems = getDropdownItemsFromStatusConfig(
  tenDLCBusinessProfileStatusConfig
)
export const tenDLCBarnStatusesDropdownItems =
  getDropdownItemsFromStatusConfig(tenDLCBrandStatusConfig)
export const tenDLCCampaignStatusesDropdownItems = getDropdownItemsFromStatusConfig(
  tenDLCCampaignStatusConfig
)
