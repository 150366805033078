import { observer } from 'mobx-react-lite'
import { CommonPeopleToContact } from 'pages/settings/pages/compliance/ui/CommonPeopleToContact/ui/CommonPeopleToContact'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'

export const PeopleToContact = observer(() => {
  const { stepLoading, peopleToContactStore, errorMessage, nextStepClick } =
    useBusinessProfileStepsContext()

  return (
    <CommonPeopleToContact
      peopleToContactStore={peopleToContactStore}
      stepLoading={stepLoading}
      errorMessage={errorMessage}
      nextStepClick={nextStepClick}
    />
  )
})
