import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Typography, Icon, Avatar } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { conversationStore } from 'entities/Conversation/store/conversations'
import { IUser } from 'entities/Users'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import styles from './styles.module.scss'

export type IConversationInfoProps = {
  member: IUser | null | undefined
  active: boolean
  conversationId: number
  ariaLabel?: string
  onClose: () => void
}

const InboxMember: React.FC<IConversationInfoProps> = observer(
  ({ member, active, ariaLabel, onClose, conversationId }) => {
    const store = useContactsDetailsContext()

    if (!member === undefined) return null

    const handleReassign = async (id: number | null) => {
      if (active) return
      onClose()
      store.toggleReassigning(true)
      const data = await conversationStore.reassign(conversationId, id)
      if (data) {
        store.handleUpdateConversation(data)
      }
      store.toggleReassigning(false)
    }

    if (!member) {
      return (
        <div
          className={classnames(styles.memberWrapper, {
            [styles.active]: active,
          })}
          {...(ariaLabel && { 'aria-label': getAriaLabel(ariaLabel) })}
          onClick={() => handleReassign(null)}
        >
          <div className={classnames(styles.info)}>
            <div className={styles.icon}>
              <Icon icon='userLine' color='var(--content-primary-tertiary)' />
            </div>
            <Typography
              className={styles.name}
              variant='body-md-regular'
              color='var(--content-primary-primary)'
              pointer
              ariaLabel={'Unassigned'}
            >
              Unassigned
            </Typography>
          </div>
          {active && <Icon icon='check1' color='var(--content-brand-primary)' />}
        </div>
      )
    }

    return (
      <div
        className={classnames(styles.memberWrapper, {
          [styles.active]: active,
        })}
        {...(ariaLabel && { 'aria-label': getAriaLabel(ariaLabel) })}
        onClick={() => handleReassign(member.id)}
      >
        <div className={classnames(styles.info)}>
          <Avatar info={member.avatarInfo} size={16} />
          <Typography
            className={styles.memberName}
            variant='body-md-regular'
            color='var(--content-primary-primary)'
            pointer
            ellipsis
            ariaLabel={'name'}
          >
            {member.name}
          </Typography>
        </div>
        {active && <Icon icon='check1' color='var(--content-brand-primary)' />}
      </div>
    )
  }
)

export { InboxMember }
