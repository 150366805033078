import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { IResponseEventMessage } from 'entities/Message'
import { type ConversationsStore } from 'entities/Conversation'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export const EventMessageTranscriptCompleted = {
  subscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.on(WebsocketEvent.MessageTranscriptCompleted, handler),
  unsubscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.off(WebsocketEvent.MessageTranscriptCompleted, handler),
}

export const useEventMessageTranscriptCompleted = (
  conversationStore: ConversationsStore,
  conversationMessageStore: ConversationMessagesStore
) =>
  useEffect(
    () =>
      EventMessageTranscriptCompleted.subscribe((data) => {
        conversationStore.updateItem(data.conversation)
        if (data.message.conversation_id === conversationMessageStore.conversationId) {
          conversationMessageStore.updateTranscript(data.message)
        }
      }),
    []
  )
