import { useEffect, useRef, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { parserHtml } from 'shared/lib'
import { Typography } from 'shared/ui'
import { attachmentStore } from 'entities/Attachment'
import { usersStore } from 'entities/Users'
import { Message } from 'entities/Message/model/Message'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { textToHtml } from 'widgets/MessageField/lib/textToHtml'
import { parseNoteBody } from 'widgets/MessageCard'
import styles from './styles.module.scss'

type IBodyRawContentProps = {
  selectedSearch: string
  message: Message
}

export const BodyRawContent: FC<IBodyRawContentProps> = observer(({ message, selectedSearch }) => {
  const { body, isNote } = message
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        const links = ref.current.querySelectorAll('a')
        links.forEach((link) => {
          const attachment = attachmentStore.previousAttachmentMap.get(link.href)
          if (attachment && attachment?.type !== 'attachment') {
            link.onclick = (e) => {
              e.preventDefault()
              presentationStore.setAttachments([attachment])
            }
          }
        })
      }
    }, 100)
  }, [attachmentStore.previousAttachmentMap.size, body])

  if (!body) return null

  let bodyParse = textToHtml({ text: body, replaceNewRow: true, noReplaceMergeField: true })

  if (isNote) {
    bodyParse = parseNoteBody(bodyParse, usersStore.usersMap)
  }

  return (
    <div ref={ref}>
      <Typography
        tag={'div'}
        variant={'body-rg-regular'}
        className={styles.text}
        ariaLabel={'body'}
      >
        {parserHtml(bodyParse, {
          search: selectedSearch,
        })}
      </Typography>
    </div>
  )
})
