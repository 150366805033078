import { observer } from 'mobx-react-lite'
import { Icon, Typography } from 'shared/ui'
import { ForgotPasswordStore } from 'widgets/ForgotPassword/store/forgotPasswordStore'

import styles from './styles.module.scss'

type IFormProps = {
  store: ForgotPasswordStore
}

export const SuccessForm = observer(({ store }: IFormProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.iconWrap}>
        <Icon
          className={styles.icon}
          icon={'check1'}
          color={'var(--content-primary-primary-inverted)'}
        />
      </div>
      <Typography
        marginTop={24}
        variant={'heading-lg'}
        ariaLabel={'title'}
        color={'var(--content-primary-primary)'}
      >
        Reset link sent!
      </Typography>
      <Typography
        marginTop={8}
        variant={'body-lg-regular'}
        ariaLabel={'subtitle'}
        color={'var(--content-primary-tertiary)'}
      >
        Instructions to reset your password have been sent to:
      </Typography>
      <Typography
        variant={'body-lg-regular'}
        ariaLabel={'email'}
        color={'var(--content-primary-primary)'}
      >
        {store.email}
      </Typography>
    </div>
  )
})

export default SuccessForm
