import { ActionItem } from 'shared/ui'
import { type Contact } from 'entities/Contacts/model/Contact'
import { IParamsContactsOptOut } from 'entities/Contacts/api/types'
import { successOpIinHandler } from 'entities/Contacts/lib/successOpIinHandler'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { contactsTagsModalStore } from 'widgets/ContactsTagsModal'
import { selectedModalStore } from 'widgets/SelectContactsModal/store/SelectedModalStore'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'
import { type ContactsTableStore } from 'widgets/ContactsTable'

export const getContactsActions = (
  contact: Contact,
  contactsTableStore: ContactsTableStore
): ActionItem[] => {
  const { search, expandedParams, setActiveContact, setInActionId, loadContacts, tableStore } =
    contactsTableStore
  const { toggleSelected } = tableStore
  const { openDeleteModal, deleteContacts } = selectedModalStore

  const resetInActionId = () => {
    setInActionId(null)
  }

  const deleteCallback = () => {
    setInActionId(null)
  }

  const successDeleteCallback = () => {
    setActiveContact(null)
    toggleSelected(contact, false)
    loadContacts()
  }

  const opIinHandler = (opt_outs: IParamsContactsOptOut['opt_outs']) => {
    successOpIinHandler(contact, opt_outs)
  }

  return [
    {
      text: 'Edit',
      icon: 'edit',
      onAction: () => {
        setActiveContact(contact)
        contactsDetailsGlobalStore.openBlockView('contactDetails')
        contactsDetailsGlobalStore.handleChangeCollapse(false)
      },
    },
    {
      text: 'Manage tags',
      icon: 'tag',
      onAction: () => {
        setInActionId(contact.id)
        contactsTagsModalStore.onOpen({
          ids: [contact.id],
          isBulkAll: false,
          filtersList: expandedParams.filtersList,
          searchParams: expandedParams.search,
          callback: () => {
            resetInActionId()
            loadContacts()
          },
          onCloseCallback: resetInActionId,
        })
      },
    },
    {
      text: 'Opt-in status',
      icon: 'toggle',
      onAction: () => {
        setInActionId(contact.id)
        contactsOptInModalStore.handleOpen([contact], {
          successHandler: opIinHandler,
          reset: resetInActionId,
          isBulkAll: false,
          filtersList: expandedParams.filtersList,
          search: expandedParams.search,
        })
      },
    },
    {
      text: 'Delete',
      icon: 'delete',
      onAction: () => {
        setInActionId(contact.id)
        openDeleteModal({
          selectedIdsLength: 1,
          primaryCallback: () => {
            deleteContacts({
              ids: [contact.id],
              search,
              finallyCallback: deleteCallback,
              successCallback: successDeleteCallback,
            })
          },
          onCloseCallback: resetInActionId,
        })
      },
    },
  ]
}
