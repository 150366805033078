import React from 'react'
import classnames from 'classnames'
import { type ITooltipProps, Tooltip } from 'shared/ui/Tooltip'
import { Icon, type IIconProps, type IIconsVector } from 'shared/ui/Icon'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

export enum statusSizeEnum {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export type IAvatarInfo = {
  isPhoneAbbr?: boolean
  firstName?: string
  lastName?: string
  image?: string
  color?: string
  icon?: IIconsVector
  phone?: string
  tooltipProps?: ITooltipProps
}

export type IAvatarProps = {
  size?: number
  fontSize?: number
  color?: string
  info: IAvatarInfo
  is_full_width?: boolean
  online?: boolean
  iconProps?: IIconProps
  statusSize?: statusSizeEnum
}

export const Avatar: React.FC<IAvatarProps> = ({
  info = {
    firstName: '',
    lastName: '',
    image: '',
    color: '#646464',
  },
  fontSize,
  iconProps,
  online,
  is_full_width = false,
  size = 24,
  statusSize = statusSizeEnum.md,
}) => {
  const image = info.image && !info.image.includes('default-avatar.png') ? info.image : ''
  const color = image ? 'transparent' : info.color || '#646464'
  const firstName = info.firstName || ''
  const lastName = info.lastName || ''
  const phone = info.phone || ''
  const icon = info.icon || ''
  const sizeW = is_full_width ? '100%' : `${size}px`
  const tooltipProps = info.tooltipProps

  const fullName = `${firstName[0] || ''}${lastName[0] || ''}`
  const phoneNumber = `${phone[0] || ''}`
  const name = size < 32 ? fullName[0] || phoneNumber[0] : fullName || phoneNumber
  const is_online = typeof online === 'boolean'

  const content = icon ? (
    <div
      className={styles.avatarWrapper}
      style={{
        width: sizeW,
        height: sizeW,
        flex: `0 0 ${sizeW}`,
      }}
      aria-label={getAriaLabel('Avatar')}
    >
      <div
        className={classnames(styles.wrap, styles.wrap__icon)}
        style={{
          backgroundColor: info.color,
        }}
        aria-label={getAriaLabel('Avatar', 'Icon')}
      >
        <Icon icon={icon} {...iconProps} />
      </div>
      {is_online && (
        <div
          className={classnames(styles.status, {
            [styles.online]: online,
            [styles.offline]: !online,
          })}
          aria-label={getAriaLabel('Avatar', 'Status')}
        />
      )}
    </div>
  ) : (
    <div
      className={styles.avatarWrapper}
      style={{
        width: sizeW,
        height: sizeW,
      }}
      aria-label={getAriaLabel('Avatar')}
    >
      <div
        className={styles.wrap}
        style={{
          backgroundColor: color,
        }}
        aria-label={getAriaLabel('Avatar', 'wrap')}
      >
        {image ? (
          <div
            className={styles.image}
            style={{
              backgroundImage: `url('${image}')`,
            }}
            aria-label={getAriaLabel('Avatar', 'image')}
          />
        ) : (
          name && (
            <div
              className={styles.label}
              style={{
                fontSize: `${fontSize ? fontSize : size / 2.6}px`,
              }}
              aria-label={getAriaLabel('Avatar', 'name')}
            >
              {name}
            </div>
          )
        )}
      </div>
      {is_online && (
        <div
          className={classnames(styles.status, styles[`${statusSize}`], {
            [styles.online]: online,
            [styles.offline]: !online,
          })}
          aria-label={getAriaLabel('Avatar', 'status')}
        />
      )}
    </div>
  )

  if (tooltipProps) {
    return <Tooltip {...tooltipProps}>{content}</Tooltip>
  }

  return content
}
