import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

interface IContentOverlayProps {
  onClick: () => void
}

export const ContentOverlay = ({ onClick }: IContentOverlayProps) => {
  return (
    <div
      className={styles.contentOverlay}
      onClick={onClick}
      aria-label={getAriaLabel('ContentOverlay')}
    />
  )
}
