// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E6cYzTUXd3aRtalqzc7g{display:flex;flex-direction:column;gap:4px}.RkhN3nwaw_T1kW4EMA3N{margin-block:8px}.XVMmkY5aSkyLFZo0h12b{display:flex;flex-direction:column;gap:4px}.CLqKmjQqNjFcXFoxry__{display:grid;grid-template-columns:130px auto;align-items:flex-start}.CLqKmjQqNjFcXFoxry__ .VB5wIqhc23fBQYUGoATs{padding-left:12px;min-height:32px;display:flex;align-items:center}.CLqKmjQqNjFcXFoxry__ .VB5wIqhc23fBQYUGoATs .nkVpZXeedSnDO4744ryE{display:flex;gap:8px}.i_Qa1UR8U5vjh8520wJv{min-height:32px;display:flex;align-items:center;color:var(--content-primary-tertiary)}.YgButahdDEiA8FRvdUjZ{padding-left:4px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastActions/ui/BroadcastDetailModal/ui/BodyContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,gCAAA,CACA,sBAAA,CAEA,4CACE,iBAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CAEA,kEACE,YAAA,CACA,OAAA,CAKN,sBACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,qCAAA,CAGF,sBACE,gBAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.title {\n  margin-block: 8px;\n}\n\n.sendParamsWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: 130px auto;\n  align-items: flex-start;\n\n  .value {\n    padding-left: 12px;\n    min-height: 32px;\n    display: flex;\n    align-items: center;\n\n    .row {\n      display: flex;\n      gap: 8px;\n    }\n  }\n}\n\n.label {\n  min-height: 32px;\n  display: flex;\n  align-items: center;\n  color: var(--content-primary-tertiary);\n}\n\n.wrapPlaceholderClassName {\n  padding-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "E6cYzTUXd3aRtalqzc7g",
	"title": "RkhN3nwaw_T1kW4EMA3N",
	"sendParamsWrapper": "XVMmkY5aSkyLFZo0h12b",
	"grid": "CLqKmjQqNjFcXFoxry__",
	"value": "VB5wIqhc23fBQYUGoATs",
	"row": "nkVpZXeedSnDO4744ryE",
	"label": "i_Qa1UR8U5vjh8520wJv",
	"wrapPlaceholderClassName": "YgButahdDEiA8FRvdUjZ"
};
export default ___CSS_LOADER_EXPORT___;
