import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BroadcastRoutesEnum } from 'entities/Broadcast'
import { RinglessBroadcasts, SMSBroadcasts } from 'pages/broadcasts'
import { OneTimeBroadcastLayout } from './layout/OneTimeBroadcastLayout'

export const OneTimeBroadcasts = () => {
  return (
    <Routes>
      <Route element={<OneTimeBroadcastLayout />}>
        <Route path={BroadcastRoutesEnum.sms} element={<SMSBroadcasts />} />
        <Route path={BroadcastRoutesEnum.ringless} element={<RinglessBroadcasts />} />
        <Route path={'*'} element={<Navigate to={BroadcastRoutesEnum.sms} />} />
      </Route>
    </Routes>
  )
}
