import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { CopyWrapper } from 'shared/ui'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type IntegrationTicketProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationTicketProperty'
import { type IntegrationKey } from 'entities/Integrations'
import styles from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/AllView/styles.module.scss'
import {
  ShowMoreAction,
  ShowMoreActionStore,
} from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ShowMoreAction'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { Actions } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Actions/Actions'
import { ItemRow } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/ItemRow/ItemRow'

type ITicketProps = {
  integrationKey: IntegrationKey
  ticket: IntegrationTicketProperty
  link: string
  contact: Contact
}
export const Ticket: FC<ITicketProps> = observer(({ ticket, link, contact, integrationKey }) => {
  const showMoreActionStore = useMemo(
    () => new ShowMoreActionStore(integrationKey, EnumIntegrationTabs.Tickets),
    []
  )
  const properties = showMoreActionStore.modifyData(ticket.properties)

  return (
    <div className={styles.item}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={ticket.title || null}
          clickableTitle
          data={{
            title: ticket.title || 'View ticket',
            link: link,
          }}
        />
      </div>
      {properties.map((property) => {
        return <ItemRow key={property.key} item={property} labelType={'simple'} />
      })}
      <Actions
        rows={[
          {
            columns: [
              !showMoreActionStore.hideShowMore && (
                <ShowMoreAction key={'show_more'} disabled={!contact} store={showMoreActionStore} />
              ),
            ],
          },
        ]}
      />
    </div>
  )
})
