import { createContext, useContext } from 'react'
import { NewCustomVoiceStore } from '../store/newCustomVoiceStore'

export const NewCustomVoiceContext = createContext<NewCustomVoiceStore | null>(null)

export function useNewCustomVoiceContext() {
  const context = useContext(NewCustomVoiceContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with NewCustomVoiceContext')
  }
  return context
}
