import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Error } from 'shared/ui'
import styles from './styles.module.scss'

type ITextInputProps = {
  size?: 'medium' | 'large'
  placeholder: string
  value: string
  setValue: (value: string) => void
  error?: ReactNode
  setError?: (error: string) => void
  style?: CSSProperties
  isAutoFocus?: boolean
}

export const TextInput = ({
  size = 'medium',
  value,
  setValue,
  placeholder,
  error,
  setError,
  style,
  isAutoFocus,
}: ITextInputProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [hover, setHover] = useState(false)

  const onMouseEnter = () => {
    setHover(true)
  }
  const onMouseLeave = () => {
    setHover(false)
  }
  const onFocus = () => {
    setError && setError('')
  }

  useEffect(() => {
    if (isAutoFocus) {
      ref.current?.focus()
    }
  }, [])

  return (
    <div
      className={classNames(styles.input, styles[size])}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
    >
      <input
        ref={ref}
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onFocus={onFocus}
      />
      {error && size === 'large' && (
        <div className={styles.emptyError}>
          <Error
            type={'tooltip'}
            error={error}
            tooltipProps={{
              margin: 16,
              open: hover,
            }}
          />
        </div>
      )}
      {error && size === 'medium' && <Error type={'text'} error={error} />}
    </div>
  )
}
