import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useTextToSpeechContext } from 'widgets/TextToSpeech'
import styles from './styles.module.scss'

export const TextToSpeechText = observer(() => {
  const { setText, clearError } = useTextToSpeechContext()
  const ref = useRef<HTMLDivElement>(null)
  const handleChange = () => {
    setText(ref.current?.innerText || '')
  }
  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
      ref.current.addEventListener('paste', function (e) {
        // cancel paste
        e.preventDefault()

        // get text representation of clipboard
        const text = e.clipboardData?.getData('text/plain')

        // insert text manually
        document.execCommand('insertHTML', false, text)
      })
    }
  }, [])
  return (
    <div
      ref={ref}
      className={styles.wrap}
      placeholder={'Write your message...'}
      contentEditable={true}
      onInput={handleChange}
      onFocus={clearError}
    />
  )
})
