// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ypdNuYMzBT5TTJ4Kyizg{background:var(--background-neutral-default);border-radius:6px;padding:12px;display:flex;flex-direction:column;gap:12px}.ypdNuYMzBT5TTJ4Kyizg .p_nC1PBEoNMXE48NRBfw{color:var(--content-primary-secondary)}", "",{"version":3,"sources":["webpack://./src/widgets/DetailsContent/MessageContent/RinglessContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,iBAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,4CACE,sCAAA","sourcesContent":[".wrap {\n  background: var(--background-neutral-default);\n  border-radius: 6px;\n  padding: 12px;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  .name {\n    color: var(--content-primary-secondary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ypdNuYMzBT5TTJ4Kyizg",
	"name": "p_nC1PBEoNMXE48NRBfw"
};
export default ___CSS_LOADER_EXPORT___;
