import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown, DropdownContent, IconButton } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { tooltipText } from 'shared/constants/tooltipText'
import { callStore } from 'entities/Call'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { DeviceSettingsContent } from 'entities/DeviceSettings'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { remindersStore } from 'widgets/Reminders'
import styles from './styles.module.scss'

export const ContactCallButton = observer(() => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const contactsDetailsStore = useContactsDetailsContext()

  const {
    conversation,
    isMultipleContacts,
    disabledNewConversationCall,
    creatingConversation,
    handleNewConversationCall,
    onClickCall,
    isVariantCreateContactModal,
    contactCreateModalStore,
  } = contactsDetailsStore

  const inteliquentCalling = featureFlagsStore.inteliquent_calling
  const contact = contactsStore.getItem(conversation?.contact_id)
  const inbox = isVariantCreateContactModal
    ? inboxesStore.currentInbox
    : inboxesStore.getItem(conversation?.inbox_id)
  const user = usersStore.user
  const isAirCallInbox = inbox?.type === 'inbox' && inbox?.isAircall
  const isCallViaAirCall =
    inbox?.type === 'inbox' && inbox?.isCallViaAircall && featureFlagsStore.call_via_aircall
  const isOutOfMoney = remindersStore.creditsStore.isOutOfMoney

  const isCallOutboundDisabled = isAirCallInbox
    ? false
    : inbox?.type === 'inbox'
    ? !inbox?.isNumberOutboundCalls
    : true
  const isPFTInbox = inbox?.isPFTInbox

  const isUserViewOnly = !!user?.isViewOnlyRole
  const isContactBlocked = contact?.is_blocked === true

  const isCallDisabled =
    isUserViewOnly ||
    isContactBlocked ||
    isMultipleContacts ||
    disabledNewConversationCall ||
    callStore.loading ||
    !callStore.isStatusClosed ||
    (isPFTInbox && !inteliquentCalling) ||
    (isOutOfMoney && !isAirCallInbox)

  const isCallButtonDisabled = isVariantCreateContactModal
    ? disabledNewConversationCall
    : isCallDisabled || isCallOutboundDisabled

  const isCallDropdownShow = isVariantCreateContactModal
    ? !callStore.disabled
    : !callStore.disabled && inbox?.type === 'inbox' && !inbox?.isAircall && !isCallViaAirCall

  const titleContent = (
    <div className={styles.wrap}>
      Call
      {isCallDropdownShow && (
        <>
          <span className={styles.divider} />
          <Dropdown
            ariaLabel={'ContactCallButton'}
            show={isDropdownOpen}
            PopperProps={{
              anchorEl: buttonRef.current,
              onClick: (event) => {
                event.stopPropagation()
                event.preventDefault()
              },
            }}
            triggerComponent={(isExpanded) => (
              <IconButton
                className={styles.icon}
                tag='span'
                variant={'icon'}
                color={'transparent'}
                size={'origin_icon'}
                onClick={() => setDropdownOpen(!isExpanded)}
                iconProps={{
                  icon: isExpanded ? 'chevronUp' : 'chevronDown',
                  fontSize: 14,
                }}
                ariaLabel='ContactCallButton_setDropdownOpen'
              />
            )}
            placement={'bottom-end'}
            width={246}
            margin={8}
            customComponent={(_, dropdownId) => (
              <DropdownContent>
                <DeviceSettingsContent
                  parentsDropdownId={[dropdownId]}
                  deviceSettingsStore={callStore.deviceSettingsStore}
                  testOutputDevice={callStore.testOutputDevice}
                />
              </DropdownContent>
            )}
          />
        </>
      )}
    </div>
  )

  const getTooltipLabel = () => {
    if (isPFTInbox && !inteliquentCalling)
      return tooltipText.disabledCall(inboxesStore.nonPFTInboxNumberType)
    if (isOutOfMoney && !isAirCallInbox) return 'Your organization is out of message credits'
    if (isMultipleContacts) return 'Calling is disabled for group chats'
    if (isAirCallInbox || isCallViaAirCall) return 'Copy contact number'
    if (!isCallDisabled && isCallOutboundDisabled && !isVariantCreateContactModal) {
      if (
        user?.isAdminRole ||
        user?.isOwnerRole ||
        (inbox?.type === 'inbox' && inbox?.ownerId === user?.id)
      ) {
        const inboxId = conversation?.inbox_id

        return (
          <>
            Outbound calling is disabled
            <br />
            <button
              onClick={(event) => {
                event.preventDefault()

                uiStore.changeRoute({
                  path: `/settings/organization/inboxes/all-inboxes/${inboxId}/calling`,
                  type: 'vue',
                })
              }}
            >
              Enable it here
            </button>
          </>
        )
      }

      return 'Outbound calling is disabled. Contact your admin to enable it.'
    }

    return ''
  }

  const handleCallClick = async () => {
    if (isVariantCreateContactModal && contactCreateModalStore?.callModalStore) {
      const value =
        contactCreateModalStore.contact?.formatted_number ||
        contactCreateModalStore.contact?.national_number ||
        ''
      contactCreateModalStore.callModalStore.openModal()
      contactCreateModalStore.callModalStore.handleChangeValue(
        value,
        contactCreateModalStore.contact
      )
      contactCreateModalStore.closeModal()

      return
    }

    if (!conversation) return

    if (conversation.isNew) {
      handleNewConversationCall()
      return
    }

    onClickCall?.(conversation.id)
  }

  useEffect(() => {
    if (isDropdownOpen) callStore.deviceSettingsStore.syncDevicesStream()

    return () => callStore.deviceSettingsStore.removeStream()
  }, [isDropdownOpen])

  if (!conversation && !isVariantCreateContactModal) return null

  return (
    <Button
      ref={buttonRef}
      size='medium'
      typeBtn='contained'
      contained='secondary'
      icon='phone'
      disabled={isCallButtonDisabled}
      tooltipProps={{
        placement: 'bottom-start',
        label: getTooltipLabel(),
      }}
      loading={creatingConversation}
      titleContent={titleContent}
      onClick={handleCallClick}
    />
  )
})
