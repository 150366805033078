import { get as _get, set as _set, unset as _unset, isEmpty as _isEmpty } from 'lodash'
import { makeAutoObservable } from 'mobx'
import { Chatbot, ChatbotField, IChatbotActionModel } from 'entities/Chatbot'

import {
  IChatbotActionSettings,
  IChatbotField,
  IChatbotPayloadError,
  IChatbotPayloadPath,
} from './type'
import { Paths } from '../utils'

const makeCleanCopy = <T extends object = object>(payload: T): T => {
  const isEmpty = (value: unknown) => value == null || value === ''

  const transform = (_: string, value: unknown) => {
    if (Array.isArray(value) && value.every(isEmpty)) return undefined

    if (Object(value) === value && Object.values(value as object).every(isEmpty)) return undefined

    if (isEmpty(value)) return undefined

    return value
  }

  const stringPayload = JSON.stringify(payload)
  const cleanPayload = JSON.parse(stringPayload, transform)

  return cleanPayload ?? {}
}

export class ChatbotErrorsList<TSource, TTarget> {
  private _errorMap = new Map<TSource, IChatbotPayloadPath>()

  constructor(private _control: ChatbotErrors) {
    makeAutoObservable(this)
  }

  sync(callback: (errorMap: Map<TSource, IChatbotPayloadPath>) => void) {
    callback(this._errorMap)
  }

  clear() {
    this._errorMap.clear()
  }

  getPath(link: TSource, subPath?: Paths<TTarget>) {
    const targetPath = this._errorMap.get(link)

    if (!targetPath) return

    return (subPath ? `${targetPath}.${subPath}` : targetPath) as IChatbotPayloadPath
  }

  getError(link: TSource, subPath?: Paths<TTarget>) {
    const path = this.getPath(link, subPath)
    if (!path) return null

    return this._control.getError(path)
  }

  hasError(link: TSource, subPath?: Paths<TTarget>) {
    const path = this.getPath(link, subPath)
    if (!path) return false

    return this._control.hasError(path)
  }

  hasOwnError(link: TSource) {
    const path = this.getPath(link)
    if (!path) return false

    return this._control.hasOwnError(path)
  }

  removeError(link: TSource, subPath?: Paths<TTarget>) {
    const path = this.getPath(link, subPath)
    if (!!path) this._control.removeError(path)
  }
}

export class ChatbotErrors {
  private _error: IChatbotPayloadError = {}

  fields = new ChatbotErrorsList<ChatbotField, IChatbotField>(this)
  successActions = new ChatbotErrorsList<IChatbotActionModel, IChatbotActionSettings>(this)
  fallbackActions = new ChatbotErrorsList<IChatbotActionModel, IChatbotActionSettings>(this)

  constructor(private _chatbot: Chatbot) {
    makeAutoObservable(this)
  }

  sync(error?: IChatbotPayloadError | null) {
    this.clear()

    if (!error) return

    Object.entries(error).forEach(([path, errors]) => _set(this._error, path, errors))

    this.fields.sync((errors) =>
      this._error.fields?.forEach((_, index) =>
        errors.set(this._chatbot.fields.list[index], `fields.${index}`)
      )
    )

    this.successActions.sync((errors) =>
      this._error.success_actions?.forEach((_, index) =>
        errors.set(this._chatbot.successActions.actions[index], `success_actions.${index}`)
      )
    )

    this.fallbackActions.sync((errors) =>
      this._error.fallback_actions?.forEach((_, index) =>
        errors.set(this._chatbot.fallbackActions.actions[index], `fallback_actions.${index}`)
      )
    )
  }

  clear() {
    this._error = {}
    this.fields.clear()
    this.successActions.clear()
    this.fallbackActions.clear()
  }

  getError(path: IChatbotPayloadPath) {
    const error = _get(this._error, path, null)

    if (Array.isArray(error) && typeof error[0] === 'string') {
      return error[0]
    }

    return null
  }

  hasError(path: IChatbotPayloadPath) {
    const error = _get(this._error, path, null)

    return !_isEmpty(error)
  }

  hasOwnError(path: IChatbotPayloadPath) {
    const error = _get(this._error, path, null)

    return (
      Array.isArray(error) &&
      !!error.length &&
      (error as unknown[]).every((item) => typeof item === 'string')
    )
  }

  removeError(path: IChatbotPayloadPath) {
    const deleted = _unset(this._error, path)

    if (deleted) this._error = makeCleanCopy(this._error)
  }
}
