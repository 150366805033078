import dayjs from 'dayjs'
import type { IDropdownItem } from 'shared/ui'

export const yearsItems: IDropdownItem[] = Array(8)
  .fill(0)
  .map((_, index) => ({
    id: dayjs()
      .add(index - 7, 'year')
      .format('YYYY'),
    label: dayjs()
      .add(index - 7, 'year')
      .format('YYYY'),
  }))
