// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uMqdJZ0aZUf33m_hw99i{max-width:282px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Schedule/ui/Recurring/ui/RepeatDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".textBtn{\n  max-width: 282px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBtn": "uMqdJZ0aZUf33m_hw99i"
};
export default ___CSS_LOADER_EXPORT___;
