import { observer } from 'mobx-react-lite'
import { IconButton, Tooltip } from 'shared/ui'
import { downloadFile } from 'shared/lib'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'

export const DownloadPreviewBtn = observer(() => {
  const onDownload = () => {
    if (presentationStore.currentAttachment?.source) {
      downloadFile(presentationStore.currentAttachment?.source)
    }
  }
  return (
    <Tooltip label={'Download'}>
      <IconButton
        icon={'download'}
        color={'secondary'}
        variant={'icon'}
        onClick={onDownload}
        ariaLabel='DownloadPreviewBtn'
      />
    </Tooltip>
  )
})
