export enum IBroadcastCreateFieldEnum {
  name = 'name',
  smsMessage = 'smsMessage',
  ringlessMessage = 'ringlessMessage',
  sendFrom = 'sendFrom',
  contacts = 'contacts',
  conversion_id = 'conversion_id',
  attachment_limit = 'attachment_limit',
  file_size_limit = 'file_size_limit',
}

export type IBroadcastCreateField = `${IBroadcastCreateFieldEnum}`

export type IEstimatedDateProps = {
  hubspotList: number
  integrationMergeFields: number
  general: number
  total: number
} | null

export enum EnumModeBroadcast {
  Create = 'create',
  Clone = 'clone',
  Edit = 'edit',
}

export enum EnumTypeBroadcast {
  Sms = 'sms',
  Ringless = 'ringless',
}
