import { makeAutoObservable, runInAction } from 'mobx'
import { IToastShow } from 'shared/ui'
import { errorHandler } from 'shared/api'
import { contactsStore } from 'entities/Contacts'
import {
  IIntegrationLinkDTO,
  IntegrationKey,
  IntegrationsApi,
  integrationsStore,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactsIntegrationsStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/store/contactsIntegrationsStore'
import { MatchedContact } from '../model/MatchedContact'

export type ContactLinkTabsType = 'matching' | 'manually' | string

export class LinkContactStore {
  private _selectedContact: Contact | null = null
  private _selectedMatchedId: string | null = null
  private _selectedMatchedPhone: string | null = null
  private _loading = false
  private _search = ''
  private _shouldUpdate = true
  private _activeTab: ContactLinkTabsType = 'matching'
  private _matchedContacts: Map<number, MatchedContact[]> = new Map()

  constructor(private _contactIntegrationsStore: ContactsIntegrationsStore) {
    makeAutoObservable(this)
  }

  get search() {
    return this._search
  }

  get selectedMatchedPhone() {
    return this._selectedMatchedPhone
  }

  get selectedContact() {
    return this._selectedContact
  }

  get shouldUpdate() {
    return this._shouldUpdate
  }

  get loading() {
    return this._loading
  }

  get activeTab() {
    return this._activeTab
  }

  get selectedMatchedId() {
    return this._selectedMatchedId
  }

  hasItem = (id: number) => {
    return this._matchedContacts.has(id)
  }

  getItem = (id: number) => {
    return this._matchedContacts.get(id)
  }

  selectContact = (contact: Contact) => {
    this._selectedContact = contact
  }

  handleSelectedMatchedPhone = (value: string) => {
    this._selectedMatchedPhone = value
  }

  handleMatchedContactId = (id: string | null) => {
    this._selectedMatchedId = id
  }

  handleActiveTab = (tab: ContactLinkTabsType) => {
    this._activeTab = tab
  }

  toggleUpdate = (value: boolean) => {
    this._shouldUpdate = value
  }

  handleSearch = (search: string) => {
    this._search = search
  }

  reset = () => {
    this._loading = false
    this._selectedContact = null
    this._selectedMatchedId = null
    this._search = ''
  }

  resetManual = () => {
    this._loading = false
    this._selectedContact = null
    this._search = ''
  }

  referchIntegrationsInfo = async (key: string | undefined, id: number) => {
    if (!key) return

    switch (key) {
      case IntegrationKey.hubspot: {
        this._contactIntegrationsStore.initContactIntegrationHubspotInfo(id)
        return
      }
      case IntegrationKey.salesforce: {
        this._contactIntegrationsStore.initContactIntegrationSalesforceInfo(id)
        return
      }
      case IntegrationKey.activecampaign: {
        this._contactIntegrationsStore.initContactIntegrationActiveCampaignInfo(id)
        return
      }
      case IntegrationKey.pipedrive: {
        this._contactIntegrationsStore.initContactIntegrationPipedriveInfo(id)
        return
      }
      case IntegrationKey.infusionsoft: {
        this._contactIntegrationsStore.initContactIntegrationInfusionsoftInfo(id)
        return
      }
    }
  }

  onLink = async (reqData: IIntegrationLinkDTO) => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data } = await IntegrationsApi.linkContactsIntegrations(reqData)

      contactsStore.updateItem(data)
      this.referchIntegrationsInfo(reqData.integration_key, data.id)

      const status: IToastShow = {
        title: `${reqData?.integration_name || ''} contact linked`,
        type: 'success',
      }

      return status
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<{
        message: string[]
        contact_integration_id: string[]
      }>(err)
      const status: IToastShow = {
        title: '',
        type: 'error',
      }

      if (type === 'axios-error') {
        status.title =
          error?.response?.data?.message?.[0] ||
          error?.response?.data?.contact_integration_id?.[0] ||
          'link contact error'
      }

      return status
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  searchMatchedContacts = async (id: number, phone: string | undefined) => {
    if (!phone) return

    if (this._matchedContacts.has(id)) {
      const firstMatchedContactId = this._matchedContacts.get(id)?.[0]?.integrationVendorId

      if (firstMatchedContactId) this._selectedMatchedId = firstMatchedContactId

      return
    }

    try {
      const hubspotIntegration = integrationsStore.getIntegration(IntegrationKey.hubspot)
      if (!hubspotIntegration) return

      runInAction(() => {
        this._loading = true
      })

      const params = {
        integration_id: hubspotIntegration.id,
        query: phone,
        field: 'number',
      }

      const { data } = await IntegrationsApi.searchMatchingContactsIntegrations(params)

      if (!data.data.length) {
        this._activeTab = 'manually'
        return
      }

      this._activeTab = 'matching'
      const matchedContacts = data.data.map((item) => {
        return new MatchedContact(item)
      })

      this._matchedContacts.set(id, matchedContacts)
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }
}
