import { FC, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { CallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import { CallPopUpDefaultContent } from 'widgets/CallPopUp/ui/CallPopUpDefaultContent'

type ICallPopUpDefaultProps = {
  store: CallPopUpDefaultStore
}

export const CallPopUpDefault: FC<ICallPopUpDefaultProps> = observer(({ store }) => {
  return useMemo(
    () => (
      <CallPopUpDefaultContext.Provider value={store}>
        <CallPopUpDefaultContent />
      </CallPopUpDefaultContext.Provider>
    ),
    []
  )
})
