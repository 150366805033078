// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wjjElx0Nk6HoCt2YyuCX .qfW_CnzSO1beROxG56fa{display:flex;align-items:center;justify-content:space-between;gap:8px;margin-bottom:24px}.wjjElx0Nk6HoCt2YyuCX .NXLg5Tip1OELAaxjHEhI{cursor:pointer;color:var(--action-contained-primary-normal);text-decoration:none}", "",{"version":3,"sources":["webpack://./src/widgets/Reminders/TenDLC/Modals/ui/styles.module.scss"],"names":[],"mappings":"AAEE,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,OAAA,CACA,kBAAA,CAGF,4CACE,cAAA,CACA,4CAAA,CACA,oBAAA","sourcesContent":[".wrap {\n \n  .info {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 8px;\n    margin-bottom: 24px;\n  }\n\n  .link{\n    cursor: pointer;\n    color: var(--action-contained-primary-normal);\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wjjElx0Nk6HoCt2YyuCX",
	"info": "qfW_CnzSO1beROxG56fa",
	"link": "NXLg5Tip1OELAaxjHEhI"
};
export default ___CSS_LOADER_EXPORT___;
