import { useRef, useEffect } from 'react'

function useOutsideAlerter(callback = () => {}) {
  const ref = useRef<HTMLDivElement>(null)

  const handleCallback = (e: MouseEvent) => {
    e.stopPropagation()

    if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleCallback)

    return () => {
      document.removeEventListener('click', handleCallback)
    }
  }, [ref])

  return {
    ref,
  }
}

export { useOutsideAlerter }
