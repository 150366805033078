import React from 'react'
import { observer } from 'mobx-react-lite'
import { replaceLink } from 'shared/lib'
import { ShortenLinkIconAction } from 'entities/ShortLink'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

export const ShortenLinkAction = observer(() => {
  const messageFieldStore = useMessageFieldContext()

  return (
    <ShortenLinkIconAction
      onAddShortLink={(link: string) =>
        messageFieldStore.addContent && messageFieldStore.addContent(replaceLink(link) + '&nbsp;')
      }
      iconButtonProps={messageFieldStore.iconButtonProps}
    />
  )
})
