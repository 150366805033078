import { useMemo } from 'react'
import { Contact } from 'entities/Contacts/model/Contact'
import { CreateContactErrors, CreateContactHandler } from 'entities/Contacts/errorHandler'
import { openConversationByContact } from 'features/OpenConversation'
import { ContactsDetailsStore, contactsDetailsGlobalStore } from 'widgets/ContactsDetails'
import { ContactsTableStore } from 'widgets/ContactsTable'

export const useContactsDetailsStore = (
  contact: Contact | null,
  contactsTableStore: ContactsTableStore
) =>
  useMemo(() => {
    if (!contact) return null

    const variant = 'contacts'
    const profileCardProps = {
      onClickMessage: (contact: Contact) => {
        contactsTableStore.setLoading(true)
        openConversationByContact(contact).then(() => contactsTableStore.setLoading(false))
      },
    }
    const onUpdateContact = contactsTableStore.updateContactLocally
    const onCloseContactDetails = () => {
      contactsTableStore.setActiveContact(null)
      contactsDetailsGlobalStore.onCloseContactDetails()
    }
    const onStopCreateNewContact = contactsTableStore.resetNewContactMode
    const addNewContactLocallyAfterCreate = (
      contact: Contact,
      params: { isNew?: boolean } = {}
    ) => {
      contactsTableStore.changePaginationToFirst()

      if (contact.isDisabled) {
        CreateContactHandler[CreateContactErrors.trialLimit]()
        contactsTableStore.resetNewContactMode()
        contactsDetailsGlobalStore.handleToggleCollapse()
      }

      if (!contact.isDisabled) {
        contactsTableStore.addNewContactLocallyAfterCreate(contact, params)
      }
    }

    return new ContactsDetailsStore({
      contact,
      variant,
      profileCardProps,
      onUpdateContact,
      onCloseContactDetails,
      onStopCreateNewContact,
      addNewContactLocallyAfterCreate,
      contactsDetailsGlobalStore: contactsDetailsGlobalStore,
      contactsTableStore,
    })
  }, [contact])
