import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { NewOrganizationStore } from './store/newOrganizationStore'
import { NewOrganizationContext } from './context'
import { NewOrganizationContent } from './pages/NewOrganizationContent/NewOrganizationContent'
type NewOrganizationViewProps = {
  store: NewOrganizationStore
}

export const NewOrganizationView = observer(({ store }: NewOrganizationViewProps) => {
  return useMemo(
    () => (
      <NewOrganizationContext.Provider value={store}>
        <NewOrganizationContent />
      </NewOrganizationContext.Provider>
    ),
    []
  )
})
