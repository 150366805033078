import { observer } from 'mobx-react-lite'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { ConfirmationSend } from './actions/ConfirmationSend'
import { DefaultSend } from './actions/DefaultSend'
import { SaveEditSend } from './actions/SaveEditSend'
import { NoteSend } from './actions/NoteSend'

export const ConversationSendAction = observer(() => {
  const { showDoubleOptInButton, isEditMessage } = useConversationMessagesContext()
  const messageFieldStore = useMessageFieldContext()

  const { isModeNote } = messageFieldStore

  if (showDoubleOptInButton) {
    return <ConfirmationSend />
  }

  if (isEditMessage) {
    return <SaveEditSend />
  }

  if (isModeNote) {
    return <NoteSend />
  }

  return <DefaultSend />
})
