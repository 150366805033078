import { Base } from 'shared/api/models'

export interface IContactFromFile {
  [key: string]: string | number
}

export class ContactFromFile extends Base {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  [key: string]: string | number | undefined | null

  constructor(item: IContactFromFile) {
    super()

    Object.keys(item).forEach((key) => {
      this[key] = item[key]
    })
  }
}
