import React from 'react'
import { IColumn, Typography } from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { TenDLCStatus } from 'entities/Compliance/ui/TenDLCStatus'
import { IAdminTenDCLsData } from 'entities/Admin/compliance'
import { SubscriptionStatus } from 'entities/Subscription'

export const columns: IColumn<IAdminTenDCLsData>[] = [
  {
    field: 'id',
    name: 'Org. id',
    fullName: 'Organization ID',
    isDisabledChangeVisible: true,
    maxWidth: 85,
  },
  {
    field: 'name',
    name: 'Org. name',
    fullName: 'Organization name',
    maxWidth: 260,
  },
  {
    field: 'subscription_status',
    name: 'Sub. status',
    fullName: 'Subscription status',
    renderRowCell: (row) => <SubscriptionStatus status={row.subscription_status} />,
  },
  {
    field: 'email',
    name: 'Owner email',
    renderRowCell: (row) => row.owner?.email,
    maxWidth: 260,
  },
  {
    field: 'twilio_SID',
    name: 'Twilio SID',
    onCellClick: () => {},
    renderRowCell: (row) =>
      row.twilio_url && (
        <a href={row.twilio_url} target={'_blank'} rel='noreferrer' className={'noTextDecoration'}>
          <Typography variant={'body-md-regular'} link ariaLabel={'RowCell'}>
            {row.twilio_url.split('/').pop()}
          </Typography>
        </a>
      ),
  },
  {
    field: 'current_plan',
    name: 'Current plan',
    renderRowCell: (row) => row.current_plan_type,
  },
  {
    field: 'applied_plan',
    name: 'Applied plan',
    renderRowCell: (row) => row.planing_plan_type,
  },
  {
    field: 'profile_status',
    name: 'Profile status',
    renderRowCell: (row) => row.profile?.status && <TenDLCStatus status={row.profile?.status} />,
  },
  {
    field: 'brand_status',
    name: 'Brand status',
    renderRowCell: (row) => row.brand?.status && <TenDLCStatus status={row.brand?.status} />,
  },
  {
    field: 'campaign_status',
    name: 'Campaign status',
    renderRowCell: (row) => row.campaign?.status && <TenDLCStatus status={row.campaign?.status} />,
  },
  {
    field: 'submit_date',
    name: 'Submit date',
    renderRowCell: (row) =>
      row.date_fields?.submit_date &&
      uiStore.dayjs(row.date_fields.submit_date).format(DayjsFormats.slash),
  },
  {
    field: 'updated_at',
    name: 'Last update on',
    renderRowCell: (row) =>
      row.date_fields?.last_updated_on &&
      uiStore.dayjs(row.date_fields.last_updated_on).format(DayjsFormats.slash),
  },
  {
    field: 'last_error_message',
    name: 'Last error message',
    renderRowCell: (row) => row.date_fields?.last_error_message,
  },
]
