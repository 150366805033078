import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type CallModalStore } from 'widgets/CallModal'

type ICallModalActionsProps = {
  callModalStore: CallModalStore
}

export const CallModalActions: FC<ICallModalActionsProps> = observer(({ callModalStore }) => {
  return (
    <Button
      fullWidth
      typeBtn={'contained'}
      text={'Call'}
      icon={'phone'}
      disabled={callModalStore.disabledButton}
      onClick={callModalStore.handleSubmit}
      tooltipProps={callModalStore.callButtonTooltip}
    />
  )
})
