class SessionManager {
  name = 'newSession'

  constructor(name?: string) {
    this.name = name ?? this.name
  }

  syncSession = (isNew: boolean) => {
    if (isNew) window.sessionStorage.setItem(this.name, 'true')
    else window.sessionStorage.removeItem(this.name)

    return isNew
  }

  isNewSession = () => {
    return !!window.sessionStorage.getItem(this.name)
  }
}

export const sessionManager = new SessionManager()
