import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventMessage } from 'entities/Message'
import { inboxesStore } from 'entities/Inbox'
import { CallHistoryStore } from 'widgets/CallHistory'

export const EventVoicemailReceived = {
  subscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.on(WebsocketEvent.VoicemailReceived, handler),
  unsubscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.off(WebsocketEvent.VoicemailReceived, handler),
}

export const useEventVoicemailReceived = (callHistoryStore: CallHistoryStore) => {
  const handler = (data: IResponseEventMessage) => {
    const { call_history, conversation } = data
    if (!call_history || inboxesStore.currentInboxTypeId !== conversation.inbox_id) return

    const { type, outcome } = call_history.call

    if (type && outcome) {
      callHistoryStore.updateCallHistoryByVoicemailEvent(call_history)
    }
  }

  useEffect(() => {
    EventVoicemailReceived.subscribe(handler)

    return () => {
      EventVoicemailReceived.unsubscribe(handler)
    }
  }, [])
}
