import { createContext, useContext } from 'react'
import { SearchDropdownStore } from 'widgets/SearchDropdown'

export const SearchDropdownContext = createContext<SearchDropdownStore | null>(null)

export function useSearchDropdownContext() {
  const context = useContext(SearchDropdownContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with SearchDropdownStoreProvider'
    )
  }
  return context
}
