import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { BroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'
import { BroadcastViewContent } from 'widgets/BroadcastView/ui/BroadcastViewContent'
import { BroadcastViewStore } from 'widgets/BroadcastView/store/broadcastViewStore'

type IBroadcastViewProps = {
  store: BroadcastViewStore
}

export const BroadcastView = observer(({ store }: IBroadcastViewProps) => {
  return useMemo(
    () => (
      <BroadcastViewContext.Provider value={store}>
        <BroadcastViewContent />
      </BroadcastViewContext.Provider>
    ),
    []
  )
})
