import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'

import styles from './styles.module.scss'
import { DetailsHeader } from './DetailsHeader'
import { DetailsTabs } from './DetailsTabs'
import { useDetailsContext } from '../detailsContext'

export const DetailsContent = observer(() => {
  const [loading, setLoading] = useState(true)
  const { loadData: init } = useDetailsContext()

  useEffect(() => {
    init().then(() => setLoading(false))
  }, [])

  if (loading) return <SpinnerLoader />

  return (
    <section className={styles.contentContainer}>
      <DetailsHeader />
      <DetailsTabs />
    </section>
  )
})
