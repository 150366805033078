export type IParamsCreateSuggestAnswer = {
  messages: [
    {
      direction: 'outbound' | 'inbound'
      first_name?: string
      message: string
    }
  ]
}

export type IParamsCreateSummaryAnswer = IParamsCreateSuggestAnswer

export type IResponseAIAnswer = {
  result_message: string
}

export enum IAIActionTypeEnum {
  SuggestResponse = 'Suggest response',
  SummarizeConversation = 'Summarize conversation',
  Rephrase = 'Rephrase',
  MoreFriendly = 'More friendly',
  MoreFormal = 'More formal',
  Expand = 'Expand',
  Shorten = 'Shorten',
}

export enum IAILayoutEnum {
  Column = 'Column',
  Row = 'Row',
}
