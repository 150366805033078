export const totalsToPercents = (values: number[], total: number) => {
  const data = values.map((value, index) => ({
    index,
    raw: (value * 100) / total,
  }))

  const accuracy = 100 - data.reduce((sum, { raw }) => sum + Math.round(raw), 0)

  const calculation = data
    .sort(({ raw }) => Math.round(raw) - raw)
    .map((item, index) => ({
      ...item,
      percent: Math.round(item.raw) + +(accuracy > index) - +(index >= data.length + accuracy),
    }))

  const percents = calculation
    .sort((prev, next) => prev.index - next.index)
    .map(({ percent }) => (isNaN(percent) ? 0 : percent))

  return percents
}
