import { Base } from 'models'
import {
  IResponseInfusionsoftInfoProperties,
  IResponseInfusionsoftInfoCompany,
  IOpportunity,
  IResponseInfusionsoftInfo,
} from 'entities/Integrations'

export class ContactIntegrationInfusionsoftInfo extends Base {
  contactId: number
  properties: IResponseInfusionsoftInfoProperties | null
  opportunities: IOpportunity[] | null
  company: IResponseInfusionsoftInfoCompany | null

  constructor(item: IResponseInfusionsoftInfo | null, id: number) {
    super()

    this.contactId = id
    this.properties = item?.properties || null
    this.opportunities = item?.opportunities
      ? Object.keys(item.opportunities).map((key: string) => {
          const opportunity = item.opportunities[key]

          const firstName = opportunity?.owner?.first_name
          const lastName = opportunity?.owner?.last_name

          const owner = firstName || lastName ? `${firstName || ''} ${lastName || ''}` : null

          return {
            id: null,
            title: opportunity?.title || null,
            stage: opportunity?.stage?.name || null,
            amount: null,
            owner,
          }
        })
      : null
    this.company = item?.company || null
  }
}
