import { nanoid } from 'nanoid'
import { isEqual, cloneDeep } from 'lodash'
import { makeAutoObservable, reaction, IReactionDisposer } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { SegmentBox } from 'entities/Segment'
import {
  EnumEnrollmentMode,
  IEnrollmentInitProps,
  IEnrollmentModalConfig,
} from 'widgets/ContactEnrollment/store/types'
import { FilterEditorStore, IFilterStoreProps } from 'widgets/FilterEditor'
import { EnrollmentItemType } from 'widgets/ContactEnrollment/types'
import { filterGroupsHubspot } from 'widgets/FilterEditor/integration'
import { EnrollmentListStore } from './enrollmentList'
import { EnrollmentModal } from '../ui/EnrollmentModal'

type IEnrollmentStoreConfig = {
  filterConfig: IFilterStoreProps
}

export class EnrollmentStore {
  private _modalId: string = nanoid()
  private _modalApplyChangeId: string = nanoid()
  private _enrollmentListStore = new EnrollmentListStore()
  private _mode: EnumEnrollmentMode = EnumEnrollmentMode.Edit
  private _segmentBox = new SegmentBox()
  private _disposerHasChangesSegment: IReactionDisposer | null = null
  private _filterEditorStore: FilterEditorStore

  constructor({ filterConfig }: IEnrollmentStoreConfig) {
    makeAutoObservable(this)

    this._filterEditorStore = new FilterEditorStore({
      ...filterConfig,
      showSpawned: true,
      mutateFilterGroups: (groups, filterGroups) => {
        return filterGroupsHubspot(
          groups,
          filterGroups,
          this._enrollmentListStore.existHubspotFilter,
          this._enrollmentListStore.filters.allSize
        )
      },
    })
  }

  reactionHasChangesSegment = () => {
    this._disposerHasChangesSegment?.()
    this._disposerHasChangesSegment = reaction(
      () => this._filterEditorStore.groups.toSegmentFilters(),
      (value) => {
        if (this._segmentBox.segment) {
          const filtersParams = cloneDeep(this._segmentBox.segment.filtersParams)

          this._segmentBox.setHasChanges(!isEqual(value, filtersParams))
        } else {
          const countWithValue = !!this._filterEditorStore.groups.countWithValue
          const countWithOutValue = !!this._filterEditorStore.groups.countWithOutValue

          if (countWithOutValue) return this._segmentBox.setHasChanges(false)

          this._segmentBox.setHasChanges(countWithValue)
        }
      }
    )
  }

  initReactions = () => {
    this.reactionHasChangesSegment()
  }

  initEnrollmentList = ({ filters, number_id, confirmEnrollment }: IEnrollmentInitProps) => {
    this._enrollmentListStore.init(filters, number_id, confirmEnrollment)
  }

  openModal = ({ title, allowEmptyCount, filters, action, number_id }: IEnrollmentModalConfig) => {
    this.initEnrollmentList({ filters, number_id })
    this.initReactions()

    const dispose = (isAllowed: boolean) => {
      if (!isAllowed) return

      this._disposerHasChangesSegment?.()
      this._enrollmentListStore.reset()
      this._segmentBox.removeSegment()
      this._filterEditorStore.dispose()

      modalStore.removeModal(this._modalId)
    }

    const onCancel = () => {
      action.cancel().then(dispose)
    }

    const onConfirm = () => {
      action
        .confirm(
          filters,
          this._enrollmentListStore.totalCount,
          this._enrollmentListStore.totalInvalidCount
        )
        .then(dispose)
    }

    modalStore.addModal({
      id: this._modalId,
      title: title ?? 'Contact enrollment',
      ModalContent: EnrollmentModal,
      ModalContentProps: {
        onCancel,
        onConfirm,
        allowEmptyCount,
        enrollmentListStore: this._enrollmentListStore,
        filterEditorStore: this._filterEditorStore,
        segmentBox: this._segmentBox,
        applyChangeModal: this.applyChangeModal,
        mode: this.mode,
      },
      width: 1000,
      pureContent: true,
      onClose: () => {
        this.applyChangeModal(() => {
          dispose(true)
        }, true)
      },
    })
  }

  applyChangeModal = (onCallback?: () => void, notUseApplyCallback?: boolean) => {
    const segmentHasChanges = this._segmentBox.hasChanges
    const segment = this._segmentBox.segment

    if (!segmentHasChanges || !segment) {
      onCallback?.()

      return
    }

    const index = this._enrollmentListStore.filters.filters.findIndex(
      (filter) => filter.id === segment.id
    )
    const name = index === -1 ? this._enrollmentListStore.filters.filters.length + 1 : index + 1
    this._modalApplyChangeId = nanoid()

    modalStore.addModal({
      id: this._modalApplyChangeId,
      type: ModalTypeList.WARNING,
      title: (
        <>
          Apply changes to <br /> “Filter {name}”?
        </>
      ),
      primaryAction: {
        text: 'Apply changes',
        onAction: () => {
          this._enrollmentListStore.addOrUpdateItem({
            type: EnrollmentItemType.Filter,
            payload: segment,
          })
          this._segmentBox.setHasChanges(false)
          this._enrollmentListStore.preview.resetItemActive(this._segmentBox)
          modalStore.closeModal(this._modalApplyChangeId)
          !notUseApplyCallback && onCallback?.()
        },
      },
      secondaryAction: {
        text: 'Keep editing',
        onAction: () => {
          modalStore.closeModal(this._modalApplyChangeId)
        },
      },
      ariaLabel: 'ApplyChange',
    })
  }

  setTitleModal = (title: string) => {
    const modal = modalStore.getModal(this._modalId)

    if (modal) {
      modal.title = title
    }
  }

  setMode = (mode: EnumEnrollmentMode) => {
    this._mode = mode
  }

  setLoading = (status: boolean) => {
    this._enrollmentListStore.setLoading(status)
  }

  get total() {
    return this._enrollmentListStore.totalCount
  }

  get skipped() {
    return this._enrollmentListStore.totalInvalidCount
  }

  get mode() {
    return this._mode
  }

  get filterEditorStore() {
    return this._filterEditorStore
  }
}
