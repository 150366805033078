import { Box } from '@mui/material'
import React from 'react'
import { Avatar, IColumn, layoutStore, Typography } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import styles from 'widgets/CallHistory/ui/styles.module.scss'
import { CellDuration } from 'widgets/CallHistoryTable/ui/CellDuration/CellDuration'
import { CellOutcome } from 'widgets/CallHistoryTable/ui/CellOutcome/CellOutcome'
import { CellContact } from 'widgets/CallHistoryTable/ui/CellContact/CellContact'
import { CellTime } from 'widgets/CallHistoryTable/ui/CellTime/CellTime'
import { CellAudio } from 'widgets/CallHistoryTable/ui/CellAudio/CellAudio'
import { type CallHistoryStore } from 'widgets/CallHistory'

export const getColumns = (
  columnIds: string[],
  callHistoryStore: CallHistoryStore
): IColumn<CallHistory>[] => {
  const hasLastColumn = !(columnIds?.includes('created_at') || columnIds?.includes('user'))

  const { isViewXXsGlobal } = layoutStore

  return [
    {
      field: 'contact',
      name: 'Contact',
      width: '100%',
      minWidth: isViewXXsGlobal ? 180 : 192,
      maxWidth: '362px',
      isDisabledChangeVisible: true,
      disableLink: true,
      renderRowCell: (row, column, config) => (
        <CellContact info={row} tableConfig={config} callHistoryStore={callHistoryStore} />
      ),
    },
    {
      field: 'number',
      name: 'Number',
      minWidth: 128,
      isDisabledChangeVisible: true,
      disableLink: true,
      renderRowCell: (row) => row.formattedNumber,
    },
    {
      field: 'outcome',
      name: 'Outcome',
      minWidth: 160,
      isDisabledChangeVisible: true,
      disableLink: true,
      renderRowCell: (row) => {
        return (
          <CellOutcome
            type={row.callType}
            isForwarded={row.isForwarded}
            isConferenceCall={!!row.call_conference?.participants?.length}
            label={row.outcomeType}
          />
        )
      },
    },
    {
      field: 'record_source',
      name: 'Record source',
      isHideByDefault: true,
      disableLink: true,
      renderRowCell: (row) => {
        if (!row.call) {
          return <EmptyCell />
        }

        return (
          <Box display={'flex'} gap={'6px'}>
            <div className={styles.recordSource}>{row.call.record_source}</div>
          </Box>
        )
      },
    },
    {
      field: 'duration',
      name: 'Duration',
      minWidth: 110,
      isHideByDefault: true,
      disableLink: true,
      renderRowCell: (row) => {
        return <CellDuration duration={row.callDuration} />
      },
    },
    {
      field: 'audio',
      name: 'Audio',
      minWidth: hasLastColumn ? 260 : 94,
      isHideByDefault: true,
      isOverflowInitial: true,
      disableLink: true,
      renderRowCell: (row, column, config) => {
        if (row.audioUrl) {
          return (
            <CellAudio
              url={row.audioUrl}
              isUnread={row.isUnread}
              callItem={row}
              tableConfig={config}
              callHistoryStore={callHistoryStore}
            />
          )
        }

        return <EmptyCell />
      },
    },
    {
      field: 'created_at',
      name: 'Time',
      minWidth: 164,
      isHideByDefault: true,
      disableLink: true,
      isSortable: true,
      renderRowCell: (row) => {
        return <CellTime info={row} />
      },
    },
    {
      field: 'user',
      name: 'User',
      isHideByDefault: true,
      disableLink: true,
      renderRowCell: (row) => {
        if (!row.contacted_user) {
          return <EmptyCell />
        }

        return (
          <Box display={'flex'} gap={'6px'}>
            {row.avatarInfo && <Avatar info={row.avatarInfo} size={16} />}

            <Typography
              variant={'body-md-regular'}
              ellipsis
              tooltipProps={{ label: row.contactedUserName }}
              ariaLabel={'name'}
            >
              <div className={styles.userWrap}>
                <div className={styles.user}>{row.contactedUserName}</div>
              </div>
            </Typography>
          </Box>
        )
      },
    },
  ]
}
