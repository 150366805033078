import React from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Typography } from 'shared/ui'
import { configStore } from 'entities/Config'
import { remindersStore } from 'widgets/Reminders'

import styles from './styles.module.scss'

export const ModalTenDLCContent = observer(() => {
  const { banner10DLC } = configStore

  const description = remindersStore.tenDlcStore.isCommonRole
    ? 'Due to carrier compliance regulations in the US, please request your Admin to register your business for 10DLC. Learn more'
    : 'Due to carrier compliance regulations in the US, you are required to register your business for 10DLC. Learn more'

  const link = (
    <a
      className={styles.link}
      href=' https://help.salesmessage.com/en/articles/6650461-changes-to-10dlc-sms-compliance-fall-2022'
      target='_blank'
    >
      here.
    </a>
  )

  return (
    <div className={styles.wrap}>
      <div className={styles.info}>
        <Typography variant={'body-lg-regular'} ariaLabel={'Messaging'}>
          Messaging from local numbers disabled in
        </Typography>
        <Typography variant={'body-lg-medium'} ariaLabel={'Time'}>
          {`${banner10DLC?.grace_period.days_left} day(s) `}
          {`${banner10DLC?.grace_period.hours_left} hour(s)`}
        </Typography>
      </div>
      <Alert
        item={{
          type: 'error',
          desc: (
            <>
              {description} {link}
            </>
          ),
        }}
      />
    </div>
  )
})
