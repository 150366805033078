import { IDropdownItem } from 'shared/ui'
import { IInboxCombine } from 'entities/Inbox'
import { ConversationMenuStore } from 'widgets/ConversationMenu'

export const typeUngroup = 'ungroup'

type IActionUngroup = {
  inbox: IInboxCombine
  item: IDropdownItem
}

export const menuUngroup = (): IDropdownItem => {
  return {
    id: typeUngroup,
    iconL: 'remove',
    label: 'Ungroup',
  }
}

export const actionUngroup = async (
  { inbox, item }: IActionUngroup,
  store: ConversationMenuStore
) => {
  if (item.id === typeUngroup) {
    store.handleDeleteTeamsGroupsById(inbox)
  }
}
