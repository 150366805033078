import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { Button, Icon, Typography, Error, Dropdown, Tooltip } from 'shared/ui'
import { StripeCardInput } from 'shared/ui/StripeCardInput'
import {
  makeInputPattern,
  makeTextFieldAutoFocus,
  makeTextFieldElement,
  makeTextFieldIcon,
  TextField,
  TextFieldPlaceholder,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { Warning } from 'shared/ui/Warning'
import { RegisterStore } from 'widgets/Register'
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
} from 'widgets/Register/ui/Step4/store/step4Store'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterStore
}

const InputPhone = makeInputPattern({
  format: '(###) ###-####',
  placeholder: '(555) 800-8000',
})

export const Step4Form = observer(({ store }: IFormProps) => {
  const {
    step4Store: {
      onChangeCard,
      clearCardError,
      cardError,
      disabledSignUp,
      setFirstName,
      firstName,
      setLastName,
      lastName,
      setCountry,
      country,
      countries,
      setNumber,
      number,
      isActiveFirstNameField,
      isActiveLastNameField,
      focusFirstNameField,
      focusLastNameField,
      blurFirstNameField,
      blurLastNameField,
      firstNameLength,
      lastNameLength,
      isMaxLastNameLength,
      isMaxFirstNameLength,
      triggerNumberValidation,
      numberError,
    },
    stepSignUpStore: { email },
    handleSignUp,
    setOnSubmit,
    loading,
  } = store

  const onKeyDownNumber = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerNumberValidation()
    }
  }

  return (
    <div className={styles.wrap}>
      <Box display='flex' justifyContent='space-between' gap={'12px'}>
        <div className={styles.fieldWrap}>
          <Label
            label={{
              text: 'First name',
              className: styles.inputLabel,
              rightText: isActiveFirstNameField ? (
                <Tooltip label={`Limit: ${MAX_FIRST_NAME_LENGTH} characters`} placement='top'>
                  <>{`${firstNameLength} of ${MAX_FIRST_NAME_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            className={classNames(styles.textField, {
              [styles.warning]: isMaxFirstNameLength,
            })}
            variant='stroke'
            size='medium'
            InputProps={{ placeholder: 'Enter first name' }}
            onChange={setFirstName}
            onFocus={focusFirstNameField}
            onBlur={blurFirstNameField}
            maxLength={MAX_FIRST_NAME_LENGTH}
            disabled={loading}
            value={firstName}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
          />
          <Warning
            text={isMaxFirstNameLength ? `${MAX_FIRST_NAME_LENGTH}-character limit reached` : ''}
          />
        </div>
        <div className={styles.fieldWrap}>
          <Label
            label={{
              text: 'Last name',
              className: styles.inputLabel,
              rightText: isActiveLastNameField ? (
                <Tooltip label={`Limit: ${MAX_FIRST_NAME_LENGTH} characters`} placement='top'>
                  <>{`${lastNameLength} of ${MAX_LAST_NAME_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            className={classNames(styles.textField, {
              [styles.warning]: isMaxLastNameLength,
            })}
            variant='stroke'
            size='medium'
            onFocus={focusLastNameField}
            onBlur={blurLastNameField}
            maxLength={MAX_LAST_NAME_LENGTH}
            disabled={loading}
            InputProps={{ placeholder: 'Enter last name' }}
            onChange={setLastName}
            value={lastName}
          />
          <Warning
            text={isMaxLastNameLength ? `${MAX_LAST_NAME_LENGTH}-character limit reached` : ''}
          />
        </div>
      </Box>

      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={styles.textField}
        variant='stroke'
        disabled={true}
        size='medium'
        value={email}
      />

      <Label
        label={{
          text: 'Mobile phone number2',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, {
          [styles.error]: numberError,
        })}
        Input={InputPhone}
        InputProps={{ onKeyDown: onKeyDownNumber }}
        timedError={!!numberError}
        variant='stroke'
        size='medium'
        value={number}
        disabled={loading}
        onChange={setNumber}
        leftActions={[
          makeTextFieldElement({
            element: (
              <div className={styles.leftContentNumber}>
                <Dropdown
                  placement={'bottom-start'}
                  width={191}
                  margin={-28}
                  marginLeft={-12}
                  items={countries}
                  onChange={setCountry}
                  triggerComponent={() => (
                    <TextFieldPlaceholder
                      rightActions={[
                        makeTextFieldIcon({
                          icon: 'chevronDown',
                        }),
                      ]}
                      placeholder={<Icon icon={country.iconL} />}
                      variant={'integrated'}
                      size={'small'}
                      className={styles.countryPicker}
                    />
                  )}
                />
                <div className={styles.verticalDivider}></div>
                <Typography variant={'text-input-field'} ariaLabel={'numberPrefix'}>
                  +1
                </Typography>
              </div>
            ),
          }),
        ]}
      />
      <Error type={'text'} error={numberError} />

      <Label
        label={{
          text: 'Credit card',
          className: styles.inputLabel,
          rightText: (
            <div className={styles.cardRightLabel}>
              <Icon icon={'lock'} color={'var(--green-70)'} fontSize={16} />
              <Typography ariaLabel={'cardRightLabel'} variant={'text-input-label-small'}>
                Safe & Secure Checkout
              </Typography>
            </div>
          ),
        }}
      />
      <StripeCardInput
        onChange={onChangeCard}
        setOnSubmit={setOnSubmit}
        onFocus={clearCardError}
        isError={!!cardError}
      />
      <Error type={'text'} error={cardError} />

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={handleSignUp}
        disabled={disabledSignUp || loading}
        loading={loading}
        text={'Start my free trial'}
        contained={'primary'}
        fullWidth
      />
      <div className={styles.trialText}>
        <Typography variant={'body-sm-medium'} ariaLabel={'trialText'}>
          Try 14 days free, then just $49 per month. Cancel anytime.
        </Typography>
      </div>
    </div>
  )
})

export default Step4Form
