import React, { useEffect, useState } from 'react'
import { inboxesStore } from 'entities/Inbox'
import { TriggerListContent } from 'widgets/TriggerList/ui/TriggerListContent'
import {
  ITriggerListStoreProps,
  TriggerListStore,
} from 'widgets/TriggerList/store/triggerListStore'
import { TriggerListContext } from 'widgets/TriggerList/context/context'

type ITriggerListProps = {
  storeProps: ITriggerListStoreProps
}

export const TriggerList = ({ storeProps }: ITriggerListProps) => {
  const [store] = useState(() => new TriggerListStore(storeProps))
  useEffect(() => {
    inboxesStore.fetchInboxes()

    return store.clearReactions
  }, [])

  return (
    <TriggerListContext.Provider value={store}>
      <TriggerListContent />
    </TriggerListContext.Provider>
  )
}
