import { ReactNode, useEffect, useRef, FC } from 'react'
import { createPortal } from 'react-dom'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { ClickAwayListener } from '@mui/material'
import DragAndDropFile from 'shared/ui/DragAndDropFile/DragAndDropFile'
import { TextField } from 'widgets/MessageField/ui/TextField/TextField'
import { FieldActions } from 'widgets/MessageField/ui/FieldActions/FieldActions'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { AttachmentContent } from 'widgets/MessageField/ui/AttachmentContent/AttachmentContent'
import { EditMessageHeader } from 'widgets/MessageField/ui/EditMessageHeader/EditMessageHeader'
import { SwitchMode } from 'widgets/MessageField/ui/SwitchMode/SwitchMode'
import { Alerts } from 'widgets/MessageField/ui/Alerts'
import { DropdownMentions } from 'widgets/MessageField/ui/DropdownMentions/ui/DropdownMentions'
import styles from 'widgets/MessageField/ui/styles.module.scss'
import { useMessageFiledEventAttachmentUploadError } from 'widgets/MessageField/events/useMessageFiledEventAttachmentUploadError'
import { useMessageFiledEventAttachmentUploadSuccessful } from 'widgets/MessageField/events/useMessageFiledEventAttachmentUploadSuccessful'
import { useMessageFieldEventVideoConvert } from 'widgets/MessageField/events/useMessageFieldEventVideoConvert'
import { RecordAudio } from 'widgets/RecordAudio'

export type IMessageFieldContentProps = {
  additionalContent?: ReactNode
  alertsContent?: ReactNode
  onDragHandler?: (condition: boolean) => void
}

export const MessageFieldContent: FC<IMessageFieldContentProps> = observer(
  ({ additionalContent, alertsContent, onDragHandler }) => {
    const messageFieldStore = useMessageFieldContext()
    const {
      fromSavedReply,
      recordAudioState,
      isVariantNote,
      isVariantConversation,
      isVariantBroadcast,
      isHideSwitchMode,
      closeHideSwitchMode,
      isMMSWithAirCallCheck,
      editId,
      noBorder,
      disabled,
      activeMessageField,
      hasText,
      alertElement,
      setMessageFieldElement,
      setBlurMessageFieldTrigger,
      isModeNote,
      mentionsStore,
      reactionParentMode,
      loadingSend,
      reset,
      resetOnUnmount,
      recordModalCloseProps,
      resetReactions,
    } = messageFieldStore

    const ref = useRef<HTMLDivElement>(null)
    const showMessageField = Boolean(isVariantBroadcast || !recordAudioState || fromSavedReply)
    const showFieldActions = () => {
      if (isVariantNote) {
        return activeMessageField || editId || hasText
      }

      if (isVariantBroadcast) {
        return !recordAudioState
      }

      return true
    }
    const isDragAndDropEnabled = isVariantConversation || fromSavedReply || isVariantBroadcast

    useMessageFieldEventVideoConvert(messageFieldStore)
    useMessageFiledEventAttachmentUploadSuccessful(messageFieldStore)
    useMessageFiledEventAttachmentUploadError(messageFieldStore)

    useEffect(() => {
      reactionParentMode()

      setTimeout(() => {
        if (ref.current) {
          setMessageFieldElement(ref.current)
        }
      })

      return () => {
        closeHideSwitchMode()
        resetReactions()
        if (resetOnUnmount) reset()
      }
    }, [])

    const alert = (
      <>
        {alertsContent}
        <Alerts />
      </>
    )

    const handleDrop = (files: File[]) => {
      messageFieldStore.addMediaFiles(files)
    }

    return (
      <ClickAwayListener onClickAway={setBlurMessageFieldTrigger}>
        <div className={styles.flex8}>
          {alertElement ? createPortal(alert, alertElement) : isVariantConversation && alert}
          {isVariantConversation && <DropdownMentions store={mentionsStore} innerRef={ref} />}

          <div
            className={classNames(styles.wrap, {
              [styles.active]: activeMessageField || (isVariantNote && (editId || hasText)),
              [styles.disabled]: disabled,
              [styles.noBorder]: noBorder,
              [styles.isModeNote]: isVariantConversation && isModeNote,
              [styles.isNote]: isVariantNote,
              [styles.isBroadcast]: isVariantBroadcast,
            })}
            ref={ref}
            aria-label={disabled ? 'MessageField_disabled' : 'MessageField'}
          >
            {disabled && <div className={classNames(styles.cover, disabled && styles.disabled)} />}
            <EditMessageHeader />

            {showMessageField && (
              <>
                <div
                  className={classNames(
                    styles.wrapFieldContent,
                    recordAudioState && styles.disabled2,
                    {
                      [styles.disabledEvents]: loadingSend,
                    }
                  )}
                >
                  {isVariantConversation && !isHideSwitchMode && (
                    <SwitchMode
                      toggle={isModeNote}
                      isMMS={isMMSWithAirCallCheck}
                      onChange={messageFieldStore.handleToggleMode}
                    />
                  )}
                  <AttachmentContent />

                  <div className={styles.wrapTextField}>
                    {isDragAndDropEnabled && (
                      <DragAndDropFile
                        className={classNames({
                          [styles.conversationDragAndDrop]: isVariantConversation,
                          [styles.savedReplyDragAndDrop]: fromSavedReply,
                        })}
                        onDrop={handleDrop}
                        onDragHandler={onDragHandler}
                      />
                    )}
                    <TextField />
                  </div>
                  {additionalContent}
                </div>
                {showFieldActions() && (
                  <div className={classNames(recordAudioState && styles.disabled2)}>
                    <FieldActions />
                  </div>
                )}
              </>
            )}
            {recordAudioState && (
              <div
                className={classNames(styles.wrapAudio, {
                  [styles.fromSavedReply]: fromSavedReply,
                })}
              >
                <RecordAudio
                  store={messageFieldStore.recordAudioStore}
                  cancelBtnType={fromSavedReply ? 'button' : 'icon'}
                  iconButtonProps={messageFieldStore.iconButtonProps}
                  buttonProps={messageFieldStore.buttonProps}
                  modalCloseProps={recordModalCloseProps}
                />
              </div>
            )}
          </div>
        </div>
      </ClickAwayListener>
    )
  }
)
