import React, { useEffect, useState } from 'react'
import { uiStore } from 'shared/store/uiStore'
import { IResponseRecurringBroadcastById } from 'entities/Broadcast/api/types'

import { BroadcastRoutesEnum } from 'entities/Broadcast'
import {
  BroadcastDetailRecurringContext,
  BroadcastDetailRecurringStoreStore,
} from 'widgets/BroadcastDetail'
import { Content } from './Content'

type IBroadcastDetailOneTimeProps = {
  broadcastResponse: IResponseRecurringBroadcastById
}

export const BroadcastDetailRecurring = ({ broadcastResponse }: IBroadcastDetailOneTimeProps) => {
  const [store] = useState(() => new BroadcastDetailRecurringStoreStore(broadcastResponse))
  useEffect(() => {
    uiStore.setCustomPathLabel(
      `/${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.view}/${broadcastResponse.id}`,
      broadcastResponse.name
    )
  }, [])
  return (
    <BroadcastDetailRecurringContext.Provider value={store}>
      <Content />
    </BroadcastDetailRecurringContext.Provider>
  )
}
