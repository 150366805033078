import { makeAutoObservable, runInAction } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import type { Conversation } from 'entities/Conversation/model/Conversation'
import type { Contact } from 'entities/Contacts/model/Contact'
import { callStore } from 'entities/Call'
import type { Inbox } from 'entities/Inbox/model/Inbox'
import { inboxesStore } from 'entities/Inbox'
import { conversationStore } from 'entities/Conversation'
import type { IContactsProfileCardStoreConfig } from 'widgets/ContactsDetails/ui/ContactsProfileCard'
import type { ContactsDetailsStore, IContactsDetailsVariant } from 'widgets/ContactsDetails'

export class ContactsProfileCardStore {
  private _conversation: Conversation | null = null
  private _contact: Contact | null = null
  private _inboxId: number | null = null
  private _contacts: Contact[] = []
  private _contactCreateModalStore:
    | IContactsProfileCardStoreConfig['contactCreateModalStore']
    | null = null
  private _contactsTableStore: IContactsProfileCardStoreConfig['contactsTableStore'] | null = null
  private _creatingConversation = false
  private _variant: IContactsDetailsVariant = 'conversation'
  private _openView: IContactsProfileCardStoreConfig['openView'] | null = null
  private _enableChat: IContactsProfileCardStoreConfig['enableChat'] = false
  private _enableCall: IContactsProfileCardStoreConfig['enableCall'] = false
  public onClickMessage: IContactsProfileCardStoreConfig['onClickMessage'] | null = null
  public onClickCall: IContactsProfileCardStoreConfig['onClickCall'] | null = null

  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)
  }

  setConfig = (config: IContactsProfileCardStoreConfig) => {
    this._contact = config.contact || config.currentContact || null
    this._contacts = config.contacts || []
    this._contactCreateModalStore = config.contactCreateModalStore || null
    this._contactsTableStore = config.contactsTableStore || null
    this._variant = config.variant || 'conversation'
    this._openView = config.openView
    this._enableChat = config.enableChat
    this._enableCall = config.enableCall
    this.onClickMessage = config.onClickMessage
    this.onClickCall = config.onClickCall
  }

  setConversation = (item: Conversation | null) => {
    this._conversation = item
  }

  setInboxId = (id: number | null) => {
    this._inboxId = id
  }

  setContact = (item: Contact) => {
    this._contact = item
  }

  setContacts = (items: Contact[]) => {
    this._contacts = items
  }

  handleConversationCall = async () => {
    if (this._conversation && !this._conversation.isNew) {
      callStore.connectTwilio(this._conversation.id)
      this.onClickCall?.()

      return
    }

    if (!this._contact || !this._inboxId) return

    try {
      runInAction(() => {
        this._creatingConversation = true
      })

      const [conversation] = await Promise.all([
        conversationStore.createConversation({
          contact_id: this._contact.id,
          team_id: this._inboxId,
        }),
      ])

      if (conversationStore.isNewConversation) {
        await inboxesStore.handleUpdateTeamInbox(this._inboxId)
      }

      runInAction(() => {
        this._creatingConversation = false
      })

      if (conversation) {
        if (conversationStore.isNewConversation) {
          uiStore.changeRoute({
            path: `/conversations/${conversation.id}`,
          })
        }

        this._contactsDetailsStore.setConversation(conversation)

        callStore.connectTwilio(conversation.id)
        this.onClickCall?.()
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._creatingConversation = false
      })
    }
  }

  get inbox() {
    if (this.isVariantCreateContactModal) {
      if (inboxesStore.currentInbox?.type !== 'inbox') return null

      return inboxesStore.currentInbox
    }

    return inboxesStore.getItem(this._inboxId) as Inbox | null
  }

  get isMultipleContacts() {
    return this._contacts.length > 1
  }

  get contactCreateModalStore() {
    return this._contactCreateModalStore
  }

  get contactsTableStore() {
    return this._contactsTableStore
  }

  get isVariantCreateContactModal() {
    return this._variant === 'create_contact_modal'
  }

  get isVariantContacts() {
    return this._variant === 'contacts'
  }

  get disabledNewConversationCall(): boolean {
    if (this._conversation && !this._conversation.isNew) return false

    if (this.isVariantCreateContactModal) {
      return !this._contact || this._contact.isNew
    }

    return !this._contact || this._contact.isNew || !this._inboxId
  }

  get creatingConversation() {
    return this._creatingConversation
  }

  get contact() {
    return this._contact
  }

  get conversation() {
    return this._conversation
  }

  get contactsAvatarInfo() {
    return this._contacts.map((item) => item?.avatarInfo)
  }

  get currentContactsAvatarInfo() {
    if (this._contact) {
      return [this._contact.avatarInfo]
    }

    return this.contactsAvatarInfo
  }

  get hasContact() {
    return Boolean(this.contact)
  }

  get hasParticipants() {
    if (this._contact) return false

    return this._contacts.length > 1
  }

  get name() {
    return this._contact?.name || ''
  }

  get formattedNumber() {
    return this._contact?.formatted_number
  }

  get isNameEqualsPhone() {
    return this.name === this.formattedNumber
  }

  get timezone() {
    return this._contact?.timezone
  }

  get areaCodeInfo() {
    return this._contact?.area_code_info?.time_zone
  }

  get contactsCount() {
    return this._contacts?.length || 0
  }

  get openView() {
    return this._openView
  }

  get enableChat() {
    return this._enableChat
  }

  get enableCall() {
    return this._enableCall
  }
}
