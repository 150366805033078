import { makeAutoObservable, runInAction } from 'mobx'
import {
  IAudioFileSetting,
  IParamsUpdateUserSettings,
  IResponseTransformUserSettings,
  ISetting,
} from 'entities/Settings/api/types'
import { SettingsApi } from 'entities/Settings'
import { featureFlagsStore } from 'entities/FeatureFlags'

export class UserSettingsStore {
  constructor() {
    makeAutoObservable(this)
  }

  settings: IResponseTransformUserSettings | null = null
  settingsLoading = false

  fetchUsersSettings = async () => {
    try {
      runInAction(() => {
        this.settingsLoading = true
      })

      const { data } = await SettingsApi.getUsersSettings()

      runInAction(() => {
        this.settings = data.reduce((settings, setting) => {
          settings[setting.key] = setting
          return settings
        }, {} as IResponseTransformUserSettings)
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.settingsLoading = false
      })
    }
  }

  updateSetting = async (params: IParamsUpdateUserSettings) => {
    try {
      runInAction(() => {
        this.settingsLoading = true
      })

      const { data } = await SettingsApi.updateUsersSetting(params)

      runInAction(() => {
        if (this.settings && data) {
          const { featureKey, settingsKey, value } = data
          this.settings = {
            ...this.settings,
            [featureKey]: {
              ...this.settings[featureKey],
              settings: {
                ...this.settings[featureKey]?.settings,
                [settingsKey]: value,
              },
            },
          }
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.settingsLoading = false
      })
    }
  }

  get isUserShowActivity() {
    if (!this.settings) return false

    return !!this.settings['conversation'].settings.show_activity
  }

  get isSalesmsgDesignV2() {
    if (featureFlagsStore.show_new_design_only) {
      return true
    }
    if (!this.settings) return false

    return !!this.settings['salesmsg-design-v2'].settings.active
  }

  get isClickToUndo() {
    return !!this.settings?.['message-form']?.settings?.click_to_undo
  }

  get settingVoicemailDrop() {
    if (!this.settings) return

    return this.settings['voicemail-drop'] as ISetting<{
      audiofiles: IAudioFileSetting[]
    }>
  }
}

export const userSettingsStore = new UserSettingsStore()
