import { observer } from 'mobx-react-lite'
import { AdminCustomPricesForm } from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'
import { RolloverForm } from 'pages/admin/ui/RolloverForm/RolloverForm'

type RolloverCreditsProps = {
  adminCustomPricesForm: AdminCustomPricesForm
  disabled?: boolean
}

export const RolloverCredits = observer(
  ({ adminCustomPricesForm, disabled = false }: RolloverCreditsProps) => {
    const {
      isRollover,
      rolloverPeriod,
      setRolloverPeriod,
      setIsRollover,
      setRolloverPercent,
      rolloverPercent,
    } = adminCustomPricesForm

    return (
      <RolloverForm
        disabled={disabled}
        isRollover={isRollover}
        rolloverPeriod={rolloverPeriod}
        setRolloverPeriod={setRolloverPeriod}
        setIsRollover={setIsRollover}
        setRolloverPercent={setRolloverPercent}
        rolloverPercent={rolloverPercent}
      />
    )
  }
)
