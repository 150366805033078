import { FC } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { IBadgeVariant, IBadgeType } from './types'

export type IBadgeProps = {
  type?: IBadgeType
  variant?: IBadgeVariant
  content?: number
  showAll?: boolean
  size?: 'medium' | 'small' | 'large'
  isFullCount?: boolean
  isEmpty?: boolean
}

export const Badge: FC<IBadgeProps> = (props) => {
  const type = props.type || 'standard'
  const variant = props.variant || 'blue'
  const content = () => {
    if (!props.content) return 0
    if (props.isFullCount) return props.content
    if (props.content > 99) return '99+'

    return props.content
  }
  const showAll = props.showAll
  const size = props.size || 'medium'

  if (!content() && !showAll && !props.isEmpty) return null

  return (
    <div className={classNames(styles.wrap, styles[size])}>
      <div className={classNames(styles.count, styles[variant], styles[type])}>
        {props.isEmpty ? null : content()}
      </div>
    </div>
  )
}
