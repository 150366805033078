import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { connectTwilioModalStore } from 'widgets/connectTwilioModal'

export const ConnectTwilioModalActions = observer(() => {
  const { handleSubmit, loading, disabled } = connectTwilioModalStore

  return (
    <Button
      fullWidth
      loading={loading}
      disabled={loading || disabled}
      typeBtn={'contained'}
      text={'Submit'}
      onClick={handleSubmit}
    />
  )
})
