import { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ConversationSearchContext } from 'widgets/ConversationHeaderSearch/context/conversationSearchContext'
import { ConversationHeaderSearchContent } from 'widgets/ConversationHeaderSearch/ui/ConversationHeaderSearchContent'
import { type ConversationSearchStore } from 'widgets/ConversationHeaderSearch/store'

type IConversationHeaderSearchProps = {
  store: ConversationSearchStore
}

export const ConversationHeaderSearch: FC<IConversationHeaderSearchProps> = observer(
  ({ store }) => {
    return useMemo(
      () => (
        <ConversationSearchContext.Provider value={store}>
          <ConversationHeaderSearchContent />
        </ConversationSearchContext.Provider>
      ),
      []
    )
  }
)
