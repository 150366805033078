// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PpTQmNlrUCbNdgMBfb2T{display:flex;align-items:center;justify-content:space-between}.C08vg7VXsadk9murfDxI{color:var(--content-primary-primary);font-size:13px;font-weight:500}", "",{"version":3,"sources":["webpack://./src/widgets/CallPopUp/ui/CallTransferPopUp/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,sBACE,oCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".wait {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &__label {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wait": "PpTQmNlrUCbNdgMBfb2T",
	"wait__label": "C08vg7VXsadk9murfDxI"
};
export default ___CSS_LOADER_EXPORT___;
