import { useMemo, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ContactsMenuContext, ContactsMenuStore } from 'widgets/ContactsMenu'
import { ContactsMenuContent } from './ContactsMenuContent'

type IContactsDetailsProps = {
  store: ContactsMenuStore
}

export const ContactsMenu: FC<IContactsDetailsProps> = observer(({ store }) => {
  return useMemo(() => {
    return (
      <ContactsMenuContext.Provider value={store}>
        <ContactsMenuContent />
      </ContactsMenuContext.Provider>
    )
  }, [])
})
