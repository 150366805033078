import React from 'react'
import { ISendLaterContentProps, SendLaterContent } from './ui/SendLaterContent'
import { SendLaterContext } from './context/sendLaterContext'
import { type SendLaterStore } from './store/sendLaterStore'

type ISendLaterProps = {
  store: SendLaterStore
} & ISendLaterContentProps

export const SendLater = ({ store, ...props }: ISendLaterProps) => {
  return (
    <SendLaterContext.Provider value={store}>
      <SendLaterContent {...props} />
    </SendLaterContext.Provider>
  )
}
