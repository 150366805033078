import { observer } from 'mobx-react-lite'
import { CommonBusinessInfo } from 'pages/settings/pages/compliance/ui/CommonBusinessInfo/CommonBusinessInfo'
import { useLocalNumbersStepsContext } from 'pages/settings/pages/compliance/pages/localNumbers/context/localNumbersStepsContext'

export const BusinessInfo = observer(() => {
  const { stepLoading, businessInfoStore, complianceLayerStore, businessInfoNextStepClick } =
    useLocalNumbersStepsContext()

  return (
    <CommonBusinessInfo
      errorMessage={complianceLayerStore.complianceServiceBrand?.registrationError?.message}
      businessInfoStore={businessInfoStore}
      stepLoading={stepLoading}
      nextStepClick={businessInfoNextStepClick}
    />
  )
})
