import { Icon, Tooltip, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import styles from './styles.module.scss'

type ITimezoneContentProps = {
  is_contact_timezone: boolean
}

export const TimezoneContent = ({ is_contact_timezone }: ITimezoneContentProps) => (
  <>
    <div className={styles.dot} />
    {is_contact_timezone ? (
      <Tooltip label={'Contact time zone'} placement={'top'}>
        <Icon icon={'userFilled'} fontSize={16} tertiary />
      </Tooltip>
    ) : (
      <Tooltip
        label={
          <>
            <Typography marginBottom={2} ariaLabel={'timezone'}>
              (UTC{uiStore.timezoneUTC}) {uiStore.timezone}
            </Typography>
            <br />
            <Typography
              variant={'body-sm-regular'}
              color={'var(--content-primary-tertiary-inverted)'}
              ariaLabel={'personalSettings'}
            >
              You can change your local time zone <br />
              in your personal settings
            </Typography>
          </>
        }
        placement={'top'}
      >
        <Typography variant={'text-input-field'} color={'tertiary'} ariaLabel={'timezoneShort'}>
          {uiStore.timezoneShort}
        </Typography>
      </Tooltip>
    )}
  </>
)
