// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yXX4XZxwr7QNAd_SfQz3{width:228px}._babX7NC2ZAZVZO2Da1A{padding:4px}.SZdXEtu8RmG_0pHrlAGp{display:flex;padding:8px 8px 8px 12px;justify-content:flex-start;align-items:center;gap:8px;border-radius:4px;cursor:pointer;font-size:13px;font-weight:400}.SZdXEtu8RmG_0pHrlAGp .fpmnu8wN1EIcB6JLn0v4{color:var(--content-primary-primary)}.SZdXEtu8RmG_0pHrlAGp:hover{background:var(--background-neutral-subtle)}", "",{"version":3,"sources":["webpack://./src/widgets/SearchDropdown/ui/DropdownContent/DateDropdownContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CACA,0BAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA,CACA,cAAA,CAEA,cAAA,CACA,eAAA,CAEA,4CACE,oCAAA,CAGF,4BACE,2CAAA","sourcesContent":[".wrap {\n  width: 228px;\n}\n\n.items {\n  padding: 4px;\n}\n\n.item {\n  display: flex;\n  padding: 8px 8px 8px 12px;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px;\n  border-radius: 4px;\n  cursor: pointer;\n\n  font-size: 13px;\n  font-weight: 400;\n\n  .label {\n    color: var(--content-primary-primary);\n  }\n\n  &:hover {\n    background: var(--background-neutral-subtle);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "yXX4XZxwr7QNAd_SfQz3",
	"items": "_babX7NC2ZAZVZO2Da1A",
	"item": "SZdXEtu8RmG_0pHrlAGp",
	"label": "fpmnu8wN1EIcB6JLn0v4"
};
export default ___CSS_LOADER_EXPORT___;
