import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import { IResponseUpdateSegmentEvent } from 'entities/EventLog/api/types'
import { IParamsSendLogs } from 'entities/DataLog/api/types'

class DataLogApi {
  sendLogs<V>(payload: IParamsSendLogs<V>): Promise<AxiosResponse<IResponseUpdateSegmentEvent>> {
    return baseApi.post('logger/v1/logs', payload)
  }
}

export const dataLogApi = new DataLogApi()
