import { makeAutoObservable, runInAction } from 'mobx'
import { IResponseConfig, ConfigApi } from 'entities/Config'

class ConfigStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = true
  config: IResponseConfig | null = null

  fetchConfig = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await ConfigApi.getUsersConfig()

      runInAction(() => {
        this.config = data
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  get developer() {
    return this.config?.developer
  }

  get banner10DLC() {
    return this.config?.['10dlc_banners_info']
  }

  get intercom() {
    return this.config?.intercom
  }

  get isDeveloper() {
    return this.developer
  }
}

export const configStore = new ConfigStore()
