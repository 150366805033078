import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader, Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { Tag } from 'entities/Tags/model/Tag'
import { TagDropdownCard } from 'entities/Tags'
import {
  SearchDropdownTagsStore,
  SearchTypesEnum,
  useSearchDropdownContext,
} from 'widgets/SearchDropdown'
import styles from './styles.module.scss'

type TagsSearchDropdownContentProps = {
  store: SearchDropdownTagsStore
  onCloseMenu?: () => void
  onSelect?: (item: Tag) => void
}

export const TagsDropdownContentSearch: FC<TagsSearchDropdownContentProps> = observer(
  ({ onCloseMenu, store, onSelect }) => {
    const { setIsShowMore, selectType, search, actionSearch, hasTagsType } =
      useSearchDropdownContext()
    const { selectTypeItem, options } = store

    const handleSelectTag = (item: Tag) => {
      actionSearch({
        value: '',
        exclude: 'tags',
      })
      options?.handleSearch?.('')

      if (!hasTagsType) selectType(SearchTypesEnum.tags)

      selectTypeItem(item)
      onSelect?.(item)
      onCloseMenu?.()
    }

    const handleShowAll = () => {
      actionSearch({
        value: '',
        exclude: 'tags',
      })
      options?.handleSearch?.('')
      selectType(SearchTypesEnum.tags)
      setIsShowMore(true)
    }

    return (
      <div className={styles.wrap} aria-label={getAriaLabel('searchTagsSelector')}>
        <p
          className={styles.searchTitle}
          aria-label={getAriaLabel('searchTagsHeaderSelector')}
        >{`Tags (${store.total})`}</p>
        {store.loading && (
          <div className={styles.spinner}>
            <SpinnerLoader size={24} />
          </div>
        )}
        {!store.loading && store.hasItems && (
          <div className={styles.items}>
            {store.first3Items.map((item) => (
              <TagDropdownCard
                key={item.id}
                item={item}
                highlighter={search}
                onChange={handleSelectTag}
                ariaLabel='searchTagItem'
              />
            ))}
          </div>
        )}
        {!store.loading && !store.hasItems && (
          <div className={styles.notFound}>No results found</div>
        )}
        {!hasTagsType && store.hasItems && (
          <div className={styles.showAll}>
            <Button
              disabled={store.loading}
              typeBtn={'informative'}
              text={'Show all'}
              onClick={handleShowAll}
            />
          </div>
        )}
      </div>
    )
  }
)
