import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { PresentationImage } from 'widgets/PresentationMode/ui/content/image/PresentationImage'
import { PresentationVideo } from 'widgets/PresentationMode/ui/content/video/PresentationVideo'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { PresentationAudio } from 'widgets/PresentationMode/ui/content/audio/PresentationAudio'

export const PresentationContent = observer(() => {
  const { currentAttachment } = presentationStore
  return useMemo(() => {
    if (
      currentAttachment?.type === 'image' ||
      currentAttachment?.type === 'gif' ||
      currentAttachment?.type === 'previewGif'
    ) {
      return <PresentationImage />
    }
    if (currentAttachment?.type === 'video') {
      return <PresentationVideo />
    }
    if (currentAttachment?.type === 'audio') {
      return <PresentationAudio />
    }
    return <></>
  }, [currentAttachment])
})
