import { createContext, useContext } from 'react'
import { type AdvancedSchedulingStore } from 'shared/ui/Schedule/ui/AdvancedScheduling'

export const AdvancedSchedulingContext = createContext<AdvancedSchedulingStore | null>(null)

export function useAdvancedSchedulingContext() {
  const context = useContext(AdvancedSchedulingContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with ImmediatelyContext')
  }
  return context
}
