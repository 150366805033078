import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Typography, Icon, Avatar } from 'shared/ui'
import { IUser } from 'entities/Users'
import { ConversationAssignDropdownStore } from 'widgets/ConversationAssignDropdown'
import styles from './styles.module.scss'

export type IConversationInfoProps = {
  member: IUser | null
  onCloseMenu?: () => void
  isBulk?: boolean
  isRoundRobin?: boolean
  store: ConversationAssignDropdownStore
}

export const InboxMember: FC<IConversationInfoProps> = observer(
  ({ store, member, onCloseMenu, isBulk, isRoundRobin }) => {
    const { handleUpdateAssign, activeUserId } = store

    if (isRoundRobin) {
      return (
        <div
          className={classnames(styles.memberWrapper)}
          onClick={() => {
            onCloseMenu && onCloseMenu()
            store.conversationAssignModalStore.openModal()
          }}
        >
          <div className={classnames(styles.info)}>
            <div className={styles.icon}>
              <Icon icon='repeat' color='var(--content-primary-tertiary)' />
            </div>
            <Typography
              className={styles.name}
              variant='body-md-regular'
              color='var(--content-primary-primary)'
              pointer
              ariaLabel={'RoundRobin'}
            >
              Round Robin
            </Typography>
          </div>
        </div>
      )
    }

    if (!member) {
      return (
        <div
          className={classnames(styles.memberWrapper, {
            [styles.active]: activeUserId === 0 && !isBulk,
          })}
          onClick={() => {
            onCloseMenu && onCloseMenu()
            handleUpdateAssign(null)
          }}
        >
          <div className={classnames(styles.info)}>
            <div className={styles.icon}>
              <Icon icon='userLine' color='var(--content-primary-tertiary)' />
            </div>
            <Typography
              className={styles.name}
              variant='body-md-regular'
              color='var(--content-primary-primary)'
              pointer
              ariaLabel={'Unassigned'}
            >
              Unassigned
            </Typography>
          </div>
          {activeUserId === 0 && !isBulk && (
            <Icon icon='check1' color='var(--content-brand-primary)' />
          )}
        </div>
      )
    }

    return (
      <div
        className={classnames(styles.memberWrapper, {
          [styles.active]: activeUserId === member.id && !isBulk,
        })}
        onClick={() => {
          onCloseMenu && onCloseMenu()
          handleUpdateAssign(member.id)
        }}
      >
        <div className={classnames(styles.info)}>
          <Avatar info={member.avatarInfo} size={16} />
          <Typography
            className={styles.memberName}
            variant='body-md-regular'
            color='var(--content-primary-primary)'
            pointer
            ellipsis
            ariaLabel={'name'}
          >
            {member.name}
          </Typography>
        </div>
        {activeUserId === member.id && !isBulk && (
          <Icon icon='check1' color='var(--content-brand-primary)' />
        )}
      </div>
    )
  }
)
