import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { organizationStore } from 'entities/Organization'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallRecordPopUpButton: React.FC = observer(() => {
  const store = useCallPopUpDefaultContext()
  const {
    isRecord,
    isRecordDisabled,
    handleRecord,
    innerRefTooltipCurrent,
    callRecordPopUpButtonTooltipLabel,
  } = store as CallPopUpDefaultStore

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'red'}
        variant={'custom'}
        active={isRecord}
        disabled={isRecordDisabled}
        ariaLabel={`CallRecordPopUpButton_${isRecord ? 'stop' : 'record'}`}
        onClick={() => {
          if (organizationStore.isEnabledOrganisationCallAutoRecord) return
          handleRecord('toggle')
        }}
        iconProps={{
          icon: 'record',
          animation: isRecord,
        }}
        tooltipProps={{
          zIndex: 5001,
          label: callRecordPopUpButtonTooltipLabel,
          placement: 'top',
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
      />
    </div>
  )
})
