import { createContext, useContext } from 'react'
import { type ConversationNewSearchStore } from 'widgets/ConversationNew'

export const ConversationNewSearchContext = createContext<ConversationNewSearchStore | null>(null)

export function useConversationNewSearchContext() {
  const context = useContext(ConversationNewSearchContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ConversationNewSearchStoreProvider'
    )
  }
  return context
}
