import { makeObservable, observable } from 'mobx'
import { Base } from 'models'
import { IResponseNumber } from 'entities/Phone/api/types'
import { IResponseEventInboxCallingNumber } from 'entities/Inbox/api/types'

export class Phone extends Base {
  id: number
  formatted_number!: string
  national_number!: string
  number!: string
  is_toll_free!: boolean
  use_organization_call_settings!: boolean
  voice_outbound!: boolean
  voice!: boolean
  conference_calling!: boolean
  verified_status!: string
  short_code!: number
  is_aircall!: boolean
  origin!: IResponseNumber
  type!: IResponseNumber['type']
  number_vendor!: IResponseNumber['number_vendor']

  constructor(item: IResponseNumber) {
    super()

    this.id = item.id
    this.syncOrigin(item)

    makeObservable(this, {
      use_organization_call_settings: observable,
      voice_outbound: observable,
      voice: observable,
      conference_calling: observable,
    })
  }

  syncOrigin = (origin: IResponseNumber) => {
    this.id = origin.id
    this.formatted_number = origin.formatted_number
    this.national_number = origin.national_number
    this.number = origin.number
    this.is_toll_free = origin.is_toll_free
    this.use_organization_call_settings = origin.use_organization_call_settings
    this.voice_outbound = origin.voice_outbound
    this.voice = origin.voice
    this.conference_calling = origin.conference_calling
    this.verified_status = origin.verified_status
    this.short_code = origin.short_code
    this.is_aircall = origin.is_aircall
    this.origin = origin
    this.number_vendor = origin.number_vendor
    this.type = origin.type
  }

  get isTollFree() {
    return this.is_toll_free
  }

  get isLocal() {
    return this.type === 'Local'
  }

  get isStatusUnverified() {
    return this.verified_status === 'Unverified'
  }

  get isStatusDeclined() {
    return this.verified_status === 'Denied'
  }

  get isStatusBlocked() {
    return this.verified_status === 'Blocked'
  }

  get isStatusVerified() {
    return this.verified_status === 'Verified'
  }

  get isStatusUnderVerification() {
    return this.verified_status === 'Under Review'
  }

  get isStatusInternalReview() {
    return this.verified_status === 'Internal Review'
  }

  get isConferenceCalling() {
    return this.conference_calling
  }

  get isUseOrganizationCallSettings() {
    return this.use_organization_call_settings
  }

  get isShortCode() {
    return Boolean(this.short_code)
  }

  updateInboxCalling = (data: IResponseEventInboxCallingNumber) => {
    this.use_organization_call_settings = data.use_organization_call_settings
    this.voice_outbound = data.voice_outbound
    this.voice = data.voice
  }
}
