// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a8KQNgwp5FM3oWFpabfS{color:var(--content-negative-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/Widget/WidgetIntegration/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA","sourcesContent":[".warning {\n  color: var(--content-negative-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "a8KQNgwp5FM3oWFpabfS"
};
export default ___CSS_LOADER_EXPORT___;
