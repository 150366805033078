// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RlO9z9rvvhdRb0p3w7rJ{display:flex;justify-content:space-between;margin-bottom:24px}.TsP0lO3SasROzoXU51_k{display:flex;justify-content:center;align-items:center;gap:8px}.oRv6bAQ1Q0cf7axwu7hO{display:none}.VL_4zzY8maWJotLV7TZw{display:flex;align-items:center}.ztxovBxUDINTG3yyYYog{width:400px}.W6gyzJ1SfSsxgbkFIm76{padding-left:12px}.eHTlvxUrLyxS7c8YRPKA{width:296px}.PpxhGFlvpPlMcLxOUcAo{height:12px;width:1px;background-color:var(--gray-30)}", "",{"version":3,"sources":["webpack://./src/widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryControls/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,WAAA,CACA,SAAA,CACA,+BAAA","sourcesContent":[".controls {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 24px;\n}\n\n.buttonsGroup {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n}\n\n.fileInput {\n  display: none;\n}\n\n.textInput {\n  display: flex;\n  align-items: center;\n}\n\n.uploadViaUrl {\n  width: 400px;\n}\n\n.textFieldIndent {\n  padding-left: 12px;\n}\n\n.search {\n  width: 296px;\n}\n\n.divider {\n  height: 12px;\n  width: 1px;\n  background-color: var(--gray-30);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controls": "RlO9z9rvvhdRb0p3w7rJ",
	"buttonsGroup": "TsP0lO3SasROzoXU51_k",
	"fileInput": "oRv6bAQ1Q0cf7axwu7hO",
	"textInput": "VL_4zzY8maWJotLV7TZw",
	"uploadViaUrl": "ztxovBxUDINTG3yyYYog",
	"textFieldIndent": "W6gyzJ1SfSsxgbkFIm76",
	"search": "eHTlvxUrLyxS7c8YRPKA",
	"divider": "PpxhGFlvpPlMcLxOUcAo"
};
export default ___CSS_LOADER_EXPORT___;
