import { makeAutoObservable, runInAction } from 'mobx'
import { Phone } from 'entities/Phone/model/Phone'
import {
  IResponseNumber,
  IResponseNumberShortcodes,
  IResponseNumberVendorIntegrations,
} from 'entities/Phone/api/types'
import { PhoneApi } from 'entities/Phone'
import { PhoneShortCode } from 'entities/Phone/model/PhoneShortCode'

class NumbersStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = true
  numbersMap: Map<number, Phone> = new Map()
  numbersUserMap: Map<number, Phone> = new Map()
  numbersShortCodesMap: Map<number, PhoneShortCode> = new Map()
  numberOrganizationId: number | null = null
  numberVendorIntegrations: IResponseNumberVendorIntegrations | null = null
  currentNumberId: number | null = null

  get numbers() {
    return Array.from(this.numbersMap.values())
  }

  get hasNumbersUser() {
    return Array.from(this.numbersUserMap.values()).length !== 0
  }

  get hasNumberVendorIntegrations() {
    return this.numberVendorIntegrations
  }

  get hasTwilioNumberVendorIntegration() {
    if (!this.hasNumberVendorIntegrations) return false

    return this.numberVendorIntegrations?.hasOwnProperty('twilio-integration')
  }

  get organizationNumber() {
    if (!this.numberOrganizationId) return

    return this.getItem(this.numberOrganizationId)
  }

  fetchOrganizationNumber = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const { data: organizationNumber } = await PhoneApi.getOrganizationNumber()

      runInAction(() => {
        this.numberOrganizationId = organizationNumber.id
        this.addItem(organizationNumber)
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  fetchNumberVendorIntegrations = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await PhoneApi.getNumberVendorIntegrations()

      runInAction(() => {
        this.numberVendorIntegrations = data
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  addItems = (items: IResponseNumber[]) => {
    if (!Array.isArray(items)) return

    items.forEach((item) => {
      this.addItem(item)
    })

    return this.numbersMap
  }

  hasItem = (id: number) => {
    return this.numbersMap.has(id)
  }

  deleteItem = (id: number) => {
    this.numbersMap.delete(id)
  }

  getItem = (id?: number | null) => {
    if (typeof id !== 'number') return

    return this.numbersMap.get(id)
  }

  getItems = (ids?: number[]) => ids?.map(this.getItem).filter(Boolean) as Phone[]

  addItem = (item: IResponseNumber) => {
    const number = this.numbersMap.get(item.id)

    if (number) {
      number.syncOrigin({ ...number.origin, ...item })
    } else {
      this.numbersMap.set(item.id, new Phone(item))
    }

    return this.getItem(item.id)
  }

  fetchNumbersShortCodes = async () => {
    try {
      const { data } = await PhoneApi.getNumbersShortcodes()

      data.forEach((item) => {
        this.setItemShortCode(item)
      })
    } catch (e) {
      console.log(e)
    }
  }

  fetchNumbers = async () => {
    try {
      // default params for core
      const params = {
        page: 1,
        limit: 500,
      }

      const { data } = await PhoneApi.getNumbers(params)

      data.data.forEach((item) => {
        this.numbersUserMap.set(item.id, new Phone(item))
      })
    } catch (e) {
      console.log(e)
    }
  }

  setItemShortCode = (item: IResponseNumberShortcodes) => {
    if (item.number) {
      this.addItem(item.number)
    }

    this.numbersShortCodesMap.set(item.id, new PhoneShortCode(item))
  }

  getItemShortCode = (id: number) => {
    return this.numbersShortCodesMap.get(id)
  }

  getShortCodeByNumber = (numberId: number) => {
    return this.itemsShortCodes.find((item) => item.numberId === numberId)
  }

  get itemsShortCodes() {
    return Array.from(this.numbersShortCodesMap.values())
  }

  setNumberId = (id: number | null) => {
    this.currentNumberId = id
  }

  get currentNumber() {
    return this.getItem(this.currentNumberId)
  }
}

export const numbersStore = new NumbersStore()
