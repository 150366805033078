import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { RadioOptions } from 'shared/ui/Radio'
import { BroadcastBadNumberStore } from 'widgets/BroadcastView/ui/BroadcastBadNumbers'
import styles from './styles.module.scss'

type IBroadcastBadNumbersModalContentProps = {
  store: BroadcastBadNumberStore
}

export const BroadcastBadNumbersModalContent: FC<IBroadcastBadNumbersModalContentProps> = observer(
  ({ store }) => {
    return (
      <div className={styles.wrap}>
        <div className={styles.title}>
          <Typography
            variant={'heading-lg'}
            className={styles.title__text}
            ariaLabel={'Enable PhoneCheckr'}
          >
            Enable PhoneCheckr
          </Typography>
          <Typography
            className={styles.title__small}
            variant={'body-rg-regular'}
            ariaLabel={'$0.01 per validation'}
          >
            $0.01 per validation
          </Typography>
        </div>

        <Typography
          tag={'div'}
          className={styles.desc}
          variant={'body-rg-regular'}
          ariaLabel={'desc'}
        >
          When enabled, PhoneChecker will automatically check every contact you create to ensure you
          only send messages to valid numbers. You have the option to validate existing contacts
          that have not been checked.
        </Typography>

        <div className={styles.options}>
          <RadioOptions
            active={store.activeOption}
            options={store.options}
            onChange={(value) => {
              store.setActiveOption(value)
            }}
          />
        </div>
      </div>
    )
  }
)
