import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import { MessageContent, SendFromContent, TrackConversionContent } from 'widgets/DetailsContent'
import { ContactsContent } from './ContactsContent/ContactsContent'
import { ScheduleContent } from './ScheduleContent/ScheduleContent'
import styles from './styles.module.scss'

export const BodyContent = observer(() => {
  const {
    message,
    type,
    attachments,
    is_track_conversion,
    short_urls,
    conversion_id,
    sendFromNumbersProps,
  } = useBroadcastDetailModalContext()
  return (
    <>
      <MessageContent message={message} attachments={attachments} type={type} />
      <div className={styles.sendParamsWrapper}>
        <Typography ariaLabel={'Send'} variant={'body-rg-medium'} className={styles.title}>
          Send
        </Typography>
        <SendFromContent sendFromNumbersProps={sendFromNumbersProps} />
        <ContactsContent />
      </div>
      <ScheduleContent />
      <TrackConversionContent
        conversionId={conversion_id}
        short_urls={short_urls}
        isTrackConversion={is_track_conversion}
      />
    </>
  )
})
