import { observer } from 'mobx-react-lite'
import { FilterDropdown as Dropdown, IFilterDropdown as IDropdownProps } from 'shared/ui'
import type { FilterEditorStore } from 'widgets/FilterEditor/store'

export type IFilterDropdownProps = Pick<
  IDropdownProps,
  'autoOpen' | 'parentsDropdownId' | 'triggerComponent' | 'onCloseDropdown'
> & {
  store: FilterEditorStore
  onSelect: (key: string) => void
}

export const FilterDropdown = observer(({ store, onSelect, ...props }: IFilterDropdownProps) => (
  <Dropdown
    {...props}
    placement='bottom-start'
    sizeTriggerButton='medium'
    ariaLabel='FilterButton'
    closeAfterChange={true}
    tooltipProps={{
      label: 'Filter',
      placement: 'left',
      disableInteractive: true,
    }}
    filterDropdownGroups={store.filterGroups}
    changeActiveFields={(item) => onSelect(item.id as string)}
  />
))
