import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IAlert } from 'shared/ui/Alert/types'
import { Contact } from 'entities/Contacts/model/Contact'
import { contactTemplate } from 'entities/Contacts/templates/contactTemplate'
import { type CallModalStore } from 'widgets/CallModal'
import { ContactCreateModalActions } from '../ui/ContactCreateModalActions'
import { ContactCreateModalContent } from '../ui/ContactCreateModalContent'
import { ContactCreateModalAlert } from '../ui/ContactCreateModalAlert'

type IContactCreateModalStoreConfig = {
  initSignal: () => void
}

export class ContactCreateModalStore {
  private _idModal = 'contact_create_modal'
  private _contact: Contact | null = null
  private _alert: IAlert | null = null
  private _disposeAlert: IReactionDisposer | null = null
  private _firstEditMode = false
  private _loading = false
  private _isFirstTimeContactAdded = false

  initSignal: IContactCreateModalStoreConfig['initSignal'] | null = null

  constructor(private _callModalStore: CallModalStore) {
    makeAutoObservable(this)

    this.reactionAlert()
  }

  openModal = () => {
    this.setContact(new Contact(contactTemplate))

    modalStore.addModal({
      id: this._idModal,
      title: 'Create contact',
      showCloseButton: false,
      showCloseIcon: true,
      width: 444,
      ModalActions: ContactCreateModalActions,
      ModalContent: ContactCreateModalContent,
      ModalAlert: ContactCreateModalAlert,
      ModalContentProps: {
        contactCreateModalStore: this,
      },
      paddingContent: '0',
      onClose: this.closeModal,
    })
  }

  closeModal = () => {
    modalStore.removeModal(this._idModal)
    this.reset()
  }

  handleSubmit = async () => {
    this.closeModal()
  }

  handleContactCreate = (contact: Contact | null) => {
    this.setContact(contact)
    this.setAlert({
      type: 'success',
      desc: 'Contact created',
    })
    this.setIsFirstEditMode(true)
  }

  handleContactUpdate = (contact: Contact | null) => {
    this.setContact(contact)
    this.setAlert({
      type: 'infoBlue',
      desc: 'Contact updated',
    })
  }

  setContact = (contact: Contact | null) => {
    this._contact = contact
  }

  setIsFirstTimeContactAdded = () => {
    this._isFirstTimeContactAdded = true
  }

  setAlert = (item: IAlert) => {
    this._alert = item
  }

  resetAlert = () => {
    this._alert = null
  }

  setIsFirstEditMode = (condition: boolean) => {
    this._firstEditMode = condition
  }

  setLoading = (condition: boolean) => {
    this._loading = condition
  }

  setConfig = (config: IContactCreateModalStoreConfig) => {
    this.initSignal = config.initSignal
  }

  reset = () => {
    this._disposeAlert = null
    this.resetAlert()
    this.setLoading(false)
    this._firstEditMode = false
    this._isFirstTimeContactAdded = false
  }

  get isEmptyContact() {
    return this.contact?.id === -1
  }

  get disabledButton() {
    return this.isEmptyContact
  }
  get contact() {
    return this._contact
  }

  get alert() {
    return this._alert
  }

  get hasAlert() {
    return Boolean(this._alert)
  }

  get callModalStore() {
    return this._callModalStore
  }

  get isFirstEditMode() {
    return this._firstEditMode
  }

  get isLoading() {
    return this._loading
  }

  get isCanUpdateExternalContactInfo() {
    return !this._isFirstTimeContactAdded
  }

  reactionAlert = () => {
    this._disposeAlert?.()
    this._disposeAlert = reaction(
      () => this._alert,
      (alert) => {
        if (alert) {
          setTimeout(() => {
            runInAction(() => {
              this._alert = null
            })
          }, 5000)
        }
      }
    )
  }
}
