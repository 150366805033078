// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".leSTI0ucp8Q3awAaZwgk{width:100%;max-width:220px;height:52px;position:relative;cursor:pointer;border-radius:4px;background:var(--background-primary-inverted-primary);display:flex;align-items:center;padding-left:8px;padding-right:16px}.leSTI0ucp8Q3awAaZwgk.gyu1JrhJme5pBG89lWoo{background:var(--background-neutral-default)}.leSTI0ucp8Q3awAaZwgk.gyu1JrhJme5pBG89lWoo:hover{background:var(--background-neutral-primary)}.to07RJYP4om1Vz2efPym{display:grid;grid-template-columns:max-content max-content;gap:8px;align-items:center}.sUBujOQ8kZnFjaz7Dj5u{display:grid}", "",{"version":3,"sources":["webpack://./src/entities/VCard/ui/VCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,eAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,qDAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CAEA,2CACE,4CAAA,CAEA,iDACE,4CAAA,CAIN,sBACE,YAAA,CACA,6CAAA,CACA,OAAA,CACA,kBAAA,CAEF,sBACE,YAAA","sourcesContent":[".wrap{\n  width: 100%;\n  max-width: 220px;\n  height: 52px;\n  position: relative;\n  cursor: pointer;\n  border-radius: 4px;\n  background: var(--background-primary-inverted-primary);\n  display: flex;\n  align-items: center;\n  padding-left: 8px;\n  padding-right: 16px;\n\n  &.gray{\n    background: var(--background-neutral-default);\n\n    &:hover{\n      background: var(--background-neutral-primary);\n    }\n  }\n}\n.content{\n  display: grid;\n  grid-template-columns: max-content max-content;\n  gap: 8px;\n  align-items: center;\n}\n.info{\n  display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "leSTI0ucp8Q3awAaZwgk",
	"gray": "gyu1JrhJme5pBG89lWoo",
	"content": "to07RJYP4om1Vz2efPym",
	"info": "sUBujOQ8kZnFjaz7Dj5u"
};
export default ___CSS_LOADER_EXPORT___;
