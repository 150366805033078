import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { SettingsStore } from '../store/SettingsStore'

const SettingsContext = createContext<SettingsStore | null>(null)

export const useSettingsContext = () => {
  const context = useContext(SettingsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with SettingsProvider')

  return context
}

export const SettingsProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new SettingsStore(), [])

  useEffect(() => store.dispose, [])

  return <SettingsContext.Provider value={store}>{children}</SettingsContext.Provider>
}
