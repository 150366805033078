import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { useEnrollmentActionsContext } from './EnrollmentActionsProvider'

export const EnrollmentActions = observer(() => {
  const { onCancel, onConfirm, selectDisabled, selectTitle } = useEnrollmentActionsContext()

  return (
    <>
      <Button text={'Cancel'} contained={'secondary'} size={'medium'} onClick={onCancel} />
      <Button disabled={selectDisabled} text={selectTitle} size={'medium'} onClick={onConfirm} />
    </>
  )
})
