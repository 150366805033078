import { MouseEvent, FC } from 'react'
import { Button } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'
import { IParamsContactsOptOut } from 'entities/Contacts/api/types'
import { successOpIinHandler } from 'entities/Contacts/lib/successOpIinHandler'
import { contactsOptInModalStore } from 'widgets/ContactsOptInModal'
import { useConversationListContext } from 'widgets/ConversationList/context'

type IButtonOptInStatusProps = {
  contactsIds: number[]
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  clickCallback?: () => void
}

export const ButtonOptInStatus: FC<IButtonOptInStatusProps> = ({
  contactsIds,
  onMouseEnter,
  onMouseLeave,
  clickCallback,
}) => {
  const store = useConversationListContext()
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()

    const contacts = contactsIds.reduce<Contact[]>((state, id) => {
      const item = contactsStore.getItem(id)

      return item ? [...state, item] : state
    }, [])

    const successHandler = (opt_outs: IParamsContactsOptOut['opt_outs']) => {
      contacts.forEach((contact) => {
        successOpIinHandler(contact, opt_outs)
      })
    }

    contactsOptInModalStore.handleOpen(contacts, {
      reset: () => {
        store.handleResetSelect()
      },
      successHandler,
      isBulkAll: false,
    })

    clickCallback?.()
  }

  return (
    <Button
      fontWeight={400}
      onClick={handleClick}
      typeBtn='menuItem'
      icon={'toggle'}
      text={'Opt-in status'}
      fullWidth
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}
