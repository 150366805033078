import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { TriggersTable } from 'widgets/TriggerList/ui/TriggersTable/TriggersTable'
import { FiltersAndSearch } from 'widgets/FiltersAndSearch'
import { useTriggerListContext } from 'widgets/TriggerList/context/context'

import styles from './styles.module.scss'

export const TriggerListContent = observer(() => {
  const [parent, setParent] = useState<HTMLDivElement | null>(null)
  const { filtersAndSearchStore } = useTriggerListContext()

  return (
    <div ref={setParent} className={styles.triggerListContentContainer}>
      <FiltersAndSearch store={filtersAndSearchStore} parent={parent} />
      <TriggersTable />
    </div>
  )
})
