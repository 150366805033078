import { IResponseEventTyping } from 'entities/Typing'

export class Typing {
  userId: number
  first_name: string
  last_name: string
  conversation_id: number
  timerId: NodeJS.Timer

  constructor(item: IResponseEventTyping, timerId: NodeJS.Timer) {
    this.userId = item.id
    this.first_name = item.first_name
    this.last_name = item.last_name
    this.conversation_id = item.conversation_id
    this.timerId = timerId
  }
}
