import { notification } from 'sounds/index'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { checkMicrophone, IResponseCallQueueParticipant } from 'entities/Call'
import { channelsInboxes } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { callPopUpGlobalStore } from 'widgets/CallPopUp'

const notificationAudio = notification.getAudio('notificationCallQueue')

const CallQueueParticipantJoin = {
  handle: async (data: IResponseCallQueueParticipant) => {
    const enable_microphone = await checkMicrophone()
    const isAwait = usersStore.isAwayStatus

    if (!enable_microphone) return

    if (!isAwait) {
      notificationAudio.play()
    }
    channelsInboxes.subscribeChannel(data.team.id)
    callPopUpGlobalStore.addQueueItem(data)
  },
}

export const EventCallQueueParticipantJoin = {
  subscribe: () =>
    websocket.on(WebsocketEvent.CallQueueParticipantJoin, CallQueueParticipantJoin.handle),
  unsubscribe: () =>
    websocket.off(WebsocketEvent.CallQueueParticipantJoin, CallQueueParticipantJoin.handle),
}
