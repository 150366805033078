import { IDropdownItem } from 'shared/ui'
import { IInboxCombine, inboxesStore } from 'entities/Inbox'
import { ConversationMenuStore } from 'widgets/ConversationMenu'

export const typeMoveTo = 'move_to'

type IActionMoveTo = {
  inbox: IInboxCombine
  item: IDropdownItem
  parent?: IDropdownItem
}

export const menuMoveTo = (items: IDropdownItem[]): IDropdownItem => {
  return {
    id: typeMoveTo,
    iconL: 'moveTo',
    iconR: 'chevronRight',
    label: 'Move to',
    children: items,
  }
}

export const actionMoveTo = async (
  { inbox, item, parent }: IActionMoveTo,
  store: ConversationMenuStore
) => {
  if (parent?.id === typeMoveTo) {
    const groupInbox = inboxesStore.getItem(item.id)

    if (!groupInbox) return

    if (groupInbox.is_favorite !== inbox.is_favorite) {
      await store.handleUpdateTeamsFavorite({
        inbox: inbox,
        status: groupInbox.is_favorite,
      })
    }

    await store.handleCreateTeamsGroupsByIdAddTeams(inbox, item.id)
  }
}
