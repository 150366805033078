import dayjs from 'dayjs'
import { DayjsFormats } from 'shared/lib'

export const toLocalISOString = (value: Date | null) => {
  if (!value) return value

  const year = value.getFullYear()
  const month = value.getMonth() + 1
  const day = value.getDate()
  const hours = value.getHours()
  const minutes = value.getMinutes()
  const fullDate = `${year}-${month}-${day}T${hours}:${minutes}`
  const correctDate = dayjs(fullDate).format(DayjsFormats.timestampServerDate)
  const correctTime = dayjs(fullDate).format(DayjsFormats.timestampServerTime)

  return `${correctDate}T${correctTime}:00.000Z`
}
