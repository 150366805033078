import { observer } from 'mobx-react-lite'
import { FC, useState, useRef, useEffect } from 'react'
import { Button, Icon, Typography } from 'shared/ui'
import { CannedMessage } from 'entities/CannedMessages/model/CannedMessage'
import { savedRepliesStore } from 'widgets/SavedReplies'
import { SavedReplyActions } from 'widgets/SavedReplies/ui/SavedRepliesContent/SavedReplyActions/SavedReplyActions'
import styles from './styles.module.scss'

type Props = {
  item: CannedMessage
}

export const SavedReplyItem: FC<Props> = observer(({ item }) => {
  const ref = useRef<HTMLButtonElement>(null)
  const [hover, setHover] = useState(false)
  const {
    selectedSavedReply,
    setSelectSavedReply,
    visibleSavedRepliesActionsId,
    handleAddSavedReplies,
    setSelectedSavedReplyRef,
    setUseScrollToElement,
  } = savedRepliesStore
  const onClick = () => {
    setSelectSavedReply(item)
    handleAddSavedReplies(item)
  }
  const onMouseEnter = () => {
    setHover(true)
    setSelectSavedReply(item)
    setUseScrollToElement(false)
  }
  const onMouseLeave = () => {
    setHover(false)
  }

  useEffect(() => {
    if (selectedSavedReply?.id === item.id) {
      setSelectedSavedReplyRef(ref)
    }
  }, [selectedSavedReply])

  return (
    <Button
      ref={ref}
      typeBtn={'inbox'}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      titleContent={
        <div className={styles.wrap}>
          <Typography variant={'body-md-regular'} ellipsis ariaLabel={'title'}>
            {item.title}
          </Typography>
          {item.public && item.isMyReply && <Icon icon={'forward'} tertiary fontSize={12} />}
          {!item.isMyReply && <Icon icon={'users'} tertiary fontSize={12} />}
          {(hover || visibleSavedRepliesActionsId === item.id) && (
            <div className={styles.actions}>
              <SavedReplyActions
                savedReply={item}
                tooltipParentElement={savedRepliesStore.leftContentElement}
                collapsed
              />
            </div>
          )}
        </div>
      }
      active={selectedSavedReply?.id === item.id}
    />
  )
})
