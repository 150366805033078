import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { uiStore } from 'shared/store/uiStore'
import { callStore } from 'entities/Call'
import { DeviceSettings } from 'entities/DeviceSettings'
import { type CallModalStore } from 'widgets/CallModal'
import { NumbersInboxesDropDown } from 'widgets/NumbersInboxesList'
import { CallSearchContacts } from 'widgets/CallSearchContacts'
import { TrialAlertTenLimitConversations } from 'widgets/ConversationMessages/ui/ConversationMessageFieldAlerts/TrialAlertTenLimitConversations'
import { CallModalActiveCard } from './CallModalActiveCard/CallModalActiveCard'
import styles from './styles.module.scss'

type ICallModalActionsProps = {
  callModalStore: CallModalStore
}

export const CallModalContent: FC<ICallModalActionsProps> = observer(({ callModalStore }) => {
  const fromConversation = uiStore.pathName.includes('conversations')
  useEffect(() => {
    callModalStore.init()
  }, [])
  const { numbersInboxesListStore } = callModalStore

  return (
    <div className={styles.wrap}>
      {fromConversation && (
        <TrialAlertTenLimitConversations
          onCloseTrialAlert={() => {
            callModalStore?.setHideTrialAlertNotForOwner?.()
          }}
          marginBottom={24}
        />
      )}
      <div className={styles.section}>
        <div className={styles.box}>
          <NumbersInboxesDropDown
            store={numbersInboxesListStore}
            onChange={(inboxId, numberId) => {
              callModalStore.handleChangeInbox(inboxId, numberId)
            }}
          />
        </div>
        <div className={styles.box}>
          <DeviceSettings
            deviceSettingsStore={callStore.deviceSettingsStore}
            dropdownProps={{
              placement: 'bottom-end',
              width: 246,
              margin: 4,
              disabled: callStore.loading,
              ariaLabel: 'deviceSettings',
            }}
            variantDropdownBtn={'icon'}
            testOutputDevice={callStore.testOutputDevice}
          />
        </div>
      </div>

      <div className={styles.section}>
        {callModalStore.contact && (
          <CallModalActiveCard
            item={callModalStore.contact}
            onDelete={callModalStore.handleClearValue}
          />
        )}

        {!callModalStore.contact && (
          <CallSearchContacts
            onChange={callModalStore.handleChangeValue}
            error={callModalStore.numberError}
          />
        )}
      </div>
    </div>
  )
})
