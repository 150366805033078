import { observer } from 'mobx-react-lite'

import styles from './styles.module.scss'
import { DialogEmpty } from './DialogEmpty'
import { ChatbotTestNewButton } from '../ChatbotButtons'

export const DialogPlaceholder = observer(() => (
  <div className={styles.dialog}>
    <header className={styles.dialogHeader}>
      <ChatbotTestNewButton />
    </header>
    <DialogEmpty />
  </div>
))
