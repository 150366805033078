import { makeAutoObservable, runInAction } from 'mobx'
import { IToastShow } from 'shared/ui'
import { errorHandler } from 'shared/api'
import { contactsStore } from 'entities/Contacts'
import {
  IIntegrationLinkDTO,
  IntegrationKey,
  IntegrationsApi,
  integrationsStore,
} from 'entities/Integrations'
import { Contact } from 'entities/Contacts/model/Contact'
import { MatchedContact } from '../model/MatchedContact'

export type ContactLinkTabsType = 'matching' | 'manually' | string

export class LinkContactStore {
  selectedContact: Contact | null = null
  selectedMatchedId: string | null = null
  selectedMatchedPhone: string | null = null
  loading = false
  search = ''
  shouldUpdate = true
  activeTab: ContactLinkTabsType = 'matching'
  matchedContacts: Map<number, MatchedContact[]> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  selectContact = (contact: Contact) => {
    this.selectedContact = contact
  }

  handleSelectedMatchedPhone = (value: string) => {
    this.selectedMatchedPhone = value
  }

  handleMatchedContactId = (id: string | null) => {
    this.selectedMatchedId = id
  }

  handleActiveTab = (tab: ContactLinkTabsType) => {
    this.activeTab = tab
  }

  toggleUpdate = (value: boolean) => {
    this.shouldUpdate = value
  }

  handleSearch = (search: string) => {
    this.search = search
  }

  reset = () => {
    this.loading = false
    this.selectedContact = null
    this.selectedMatchedId = null
    this.search = ''
  }

  resetManual = () => {
    this.loading = false
    this.selectedContact = null
    this.search = ''
  }

  referchIntegrationsInfo = async (key: string | undefined, id: number) => {
    if (!key) return

    switch (key) {
      case IntegrationKey.hubspot: {
        integrationsStore.initContactIntegrationHubspotInfo(id)
        return
      }
      case IntegrationKey.salesforce: {
        integrationsStore.initContactIntegrationSalesforceInfo(id)
        return
      }
      case IntegrationKey.activecampaign: {
        integrationsStore.initContactIntegrationActiveCampaignInfo(id)
        return
      }
      case IntegrationKey.pipedrive: {
        integrationsStore.initContactIntegrationPipedriveInfo(id)
        return
      }
      case IntegrationKey.infusionsoft: {
        integrationsStore.initContactIntegrationInfusionsoftInfo(id)
        return
      }
    }
  }

  onLink = async (reqData: IIntegrationLinkDTO) => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await IntegrationsApi.linkContactsIntegrations(reqData)

      contactsStore.updateItem(data)
      this.referchIntegrationsInfo(reqData.integration_key, data.id)

      const status: IToastShow = {
        title: `${reqData?.integration_name || ''} contact linked`,
        type: 'success',
      }

      return status
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<{
        message: string[]
        contact_integration_id: string[]
      }>(err)
      const status: IToastShow = {
        title: '',
        type: 'error',
      }

      if (type === 'axios-error') {
        status.title =
          error?.response?.data?.message?.[0] ||
          error?.response?.data?.contact_integration_id?.[0] ||
          'link contact error'
      }

      return status
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  searchMatchedContacts = async (id: number, phone: string | undefined) => {
    if (!phone) return

    if (this.matchedContacts.has(id)) {
      const firstMatchedContactId = this.matchedContacts.get(id)?.[0]?.integrationVendorId

      if (firstMatchedContactId) this.selectedMatchedId = firstMatchedContactId

      return
    }

    try {
      const hubspotIntegration = integrationsStore.getIntegration(IntegrationKey.hubspot)
      if (!hubspotIntegration) return

      runInAction(() => {
        this.loading = true
      })

      const params = {
        integration_id: hubspotIntegration.id,
        query: phone,
        field: 'number',
      }

      const { data } = await IntegrationsApi.searchMatchingContactsIntegrations(params)

      if (!data.data.length) {
        this.activeTab = 'manually'
        return
      }

      this.activeTab = 'matching'
      const matchedContacts = data.data.map((item) => {
        return new MatchedContact(item)
      })

      this.matchedContacts.set(id, matchedContacts)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}

export const linkContactStore = new LinkContactStore()
