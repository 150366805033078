import { observer } from 'mobx-react-lite'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import { subscriptionStore } from 'entities/Subscription'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { IntegrationsRouter } from 'pages/settings/pages/integrations'
import { SettingsProvider } from 'pages/settings/context/settingsContext'
import { QuickLinksPage } from 'pages/settings/pages/quickLinks'
import { KnowledgeBaseRouter, KnowledgeBaseRoutes } from 'pages/settings/pages/knowledgeBase'
import { ComplianceRouter, ComplianceRoutes } from 'pages/settings/pages/compliance'
import { NumbersRouter } from 'pages/settings/pages/numbers'
import { SettingsLayout } from '../layout/SettingsLayout'
import { SettingsRoutes } from './type'

const SettingsRoutesContent = observer(() => {
  const { isCanceled } = subscriptionStore
  const { pathname } = useLocation()
  const { salesmsg_design_v2_new_sidebar_menu } = featureFlagsStore

  if (isCanceled && pathname === '/settings/billing/overview') {
    return <Navigate to='/settings/personal/settings/profile' />
  }

  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route index element={<div>Settings Home</div>} />
        <Route path={`${ComplianceRoutes.root}/*`} element={<ComplianceRouter />} />
        <Route path={`${SettingsRoutes.integrations}/*`} element={<IntegrationsRouter />} />
        <Route path={`${SettingsRoutes.numbers}/*`} element={<NumbersRouter />} />

        {!salesmsg_design_v2_new_sidebar_menu && (
          <Route path={`${KnowledgeBaseRoutes.root}`} element={<KnowledgeBaseRouter />} />
        )}

        <Route path={`${SettingsRoutes.quickLinks}`} element={<QuickLinksPage />} />
        <Route path='*' element={<div>Page Not Found</div>} />
      </Route>
    </Routes>
  )
})

export const SettingsRouter = () => {
  return (
    <SettingsProvider>
      <SettingsRoutesContent />
    </SettingsProvider>
  )
}
