import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { ChatbotTestButton } from 'pages/chatbot/ui'
import { useChatbotContext, useChatbotDialogContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

export const DialogHeader = observer(() => {
  const chatbot = useChatbotContext()
  const dialog = useChatbotDialogContext()

  const buttonDisabled = dialog.loading || chatbot.locked
  const handleSessionClose = () => dialog.clear()

  return (
    <header className={styles.dialogHeader}>
      <ChatbotTestButton />
      <IconButton
        icon='close'
        size='medium'
        variant='icon'
        color='tertiary'
        ariaLabel='terminateTextbotSession'
        disabled={buttonDisabled}
        className={styles.dialogHeaderClose}
        onClick={handleSessionClose}
      />
    </header>
  )
})
