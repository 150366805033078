import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket/store'
import { conversationStore } from 'entities/Conversation'
import type { IResponseConversation } from 'entities/Conversation/api/types'

const ConversationStateChanged = (data: IResponseConversation) => {
  conversationStore.updateItem(data)
}

export const useEventConversationStateChanged = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.ConversationStateChanged, ConversationStateChanged)
    }
  }, [websocket.isConnected])
}

interface IConversationState {
  id: number
  locked: boolean
}

export const subscribeConversationStateChange = (callback: (state: IConversationState) => void) =>
  websocket.on(WebsocketEvent.ConversationStateChanged, (conversation) => {
    const id = conversation.id
    const locked = conversation.is_locked

    if (locked != null) callback({ id, locked })
  })
