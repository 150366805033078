import { nanoid } from 'nanoid'
import { showToast } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { AdminCustomPriceApi } from 'entities/Admin'
import { ICustomPriceCreateForm } from 'entities/Admin/customPrices/api/type'
import { AdminCustomPricesForm } from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'
import { adminOrganizationPlanStore } from 'pages/admin/pages/organizations/store/adminOrganizationPlanStore'
import { NewPriceModalContent } from '../ui/NewPriceModal/NewPriceModalContent'
import { NewPriceModalAction } from '../ui/NewPriceModal/NewPriceModalAction'

class AdminOrganizationCustomPlanNewPrice {
  form = new AdminCustomPricesForm()
  newPriceLoading = false
  modalId = ''

  resetNewPriceModal = () => {
    modalStore.removeModal(this.modalId)

    this.modalId = ''
    this.newPriceLoading = false
    this.form = new AdminCustomPricesForm()
  }

  addNewPrice = () => {
    this.modalId = nanoid()

    modalStore.addModal({
      id: this.modalId,
      title: 'New custom price',
      ModalContent: NewPriceModalContent,
      width: 760,
      ModalActions: NewPriceModalAction,
      showCloseButton: false,
      onClose: this.resetNewPriceModal,
    })
  }

  saveCustomPrice = async () => {
    if (this.form.disableFormActions) {
      return
    }

    this.newPriceLoading = true
    const data = this.form.formToCreate as ICustomPriceCreateForm

    try {
      await AdminCustomPriceApi.saveCustomPrice(data)

      showToast({
        type: 'success',
        title: 'Custom plan successfully saved',
      })

      await adminOrganizationPlanStore.fetchPlans()
      this.resetNewPriceModal()
    } catch (e) {
      console.error(e)
      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
    } finally {
      this.newPriceLoading = false
    }
  }

  publishCustomPrice = async () => {
    this.newPriceLoading = true

    const data = this.form.formToCreate as ICustomPriceCreateForm

    try {
      const {
        data: { id },
      } = await AdminCustomPriceApi.saveCustomPrice(data)

      await AdminCustomPriceApi.publishCustomPrice(id)

      showToast({
        type: 'success',
        title: 'Custom plan successfully publish',
      })

      await adminOrganizationPlanStore.fetchPlans()
      this.resetNewPriceModal()
    } catch (e) {
      console.error(e)
      showToast({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })
    } finally {
      this.newPriceLoading = false
    }
  }
}

export const adminOrganizationCustomPlanNewPrice = new AdminOrganizationCustomPlanNewPrice()
