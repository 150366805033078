import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownContent } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { User } from 'entities/Users/model/User'
import { SearchDropdownAssigneeStore } from 'widgets/SearchDropdown'
import { AssigneeDropdownContentItems } from 'widgets/SearchDropdown/ui'
import { MIN_COUNT_CHARTERS_FOR_SEARCH, minSearchCountText } from 'widgets/constants'
import styles from './styles.module.scss'

type IAssigneeDropdownContentProps = {
  store: SearchDropdownAssigneeStore
  onChange?: (item: User) => void
  width?: number
}

export const AssigneeDropdownContent: FC<IAssigneeDropdownContentProps> = observer(
  ({ store, onChange, width }) => {
    const { search } = store

    const handleReset = () => {
      return store.resetType()
    }

    const showSearchHelpText = search.length < MIN_COUNT_CHARTERS_FOR_SEARCH

    return (
      <DropdownContent noPadding className={styles.dropdownContent} width={width}>
        <div className={styles.searchWrap}>
          <TextField
            className={styles.search}
            variant='integrated'
            InputProps={{
              placeholder: 'Search assignee',
            }}
            onChange={store.handleSearch}
            value={search}
            mainActions={[makeTextFieldAutoFocus({ withFocus: !search })]}
          />
        </div>
        {showSearchHelpText && <div className={styles.helpText}>{minSearchCountText}</div>}

        <AssigneeDropdownContentItems store={store} onChange={onChange} />

        {store.hasSelectedItems && (
          <div className={styles.clear}>
            <Button typeBtn={'text'} text={'Clear'} onClick={handleReset} />
          </div>
        )}
      </DropdownContent>
    )
  }
)
