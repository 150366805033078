import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { IModalCloseProps, IRecordActionsProps, RecordActions } from 'widgets/Record'
import { AudioRecord } from 'widgets/RecordAudio/ui/AudioRecord'
import { useRecordAudioContext } from 'widgets/RecordAudio'
import styles from './styles.module.scss'

export type IRecordAudioContentProps = {
  cancelBtnType?: IRecordActionsProps['cancelBtnType']
  iconButtonProps?: IRecordActionsProps['iconButtonProps']
  buttonProps?: IRecordActionsProps['buttonProps']
  cancelButtonProps?: IRecordActionsProps['cancelButtonProps']
  modalCloseProps?: IModalCloseProps
}

export const RecordAudioContent: FC<IRecordAudioContentProps> = observer(
  ({ cancelBtnType, modalCloseProps, iconButtonProps, buttonProps, cancelButtonProps }) => {
    const { recordStore, onUnmount } = useRecordAudioContext()

    useEffect(() => {
      if (modalCloseProps) {
        recordStore.setModalCloseProps(modalCloseProps)
      }
    }, [modalCloseProps])

    useEffect(() => onUnmount, [])

    return (
      <div className={styles.wrap}>
        <AudioRecord />
        <RecordActions
          iconButtonProps={iconButtonProps}
          buttonProps={buttonProps}
          cancelButtonProps={cancelButtonProps}
          recordStore={recordStore}
          cancelBtnType={cancelBtnType}
        />
      </div>
    )
  }
)
