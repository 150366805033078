import { makeAutoObservable, runInAction, reaction, IReactionDisposer } from 'mobx'
import { Call } from '@twilio/voice-sdk'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { links } from 'shared/constants/links'
import { ZIndex } from 'shared/constants/zIndex'
import { CallContact } from 'entities/Call/model/CallContact'
import { Contact } from 'entities/Contacts/model/Contact'
import { conversationStore } from 'entities/Conversation'
import { callStore } from 'entities/Call'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { eventLogStore } from 'entities/EventLog'
import { billingStore } from 'entities/Billing'
import { BuyCreditsModalStore } from 'widgets/BuyCreditsModal'
import { ICallPopUpPowerDialerStoreOptions } from 'widgets/CallPopUp'
import { CallPopUpPowerDialerSessionStore } from './callPopUpPowerDialerSessionStore'

const timer = 5_000

export class CallPopUpPowerDialerStore {
  private _itemsMap: Map<number, CallContact> = new Map()
  private _timer = timer
  private _timerInterval: ReturnType<typeof setInterval> | null = null
  private _options: ICallPopUpPowerDialerStoreOptions | null = null
  private _modalStopId = ''
  private _isPause = false
  private _item: CallContact | null = null
  private _disposeCallStatus: IReactionDisposer | null = null
  private _disposePause: IReactionDisposer | null = null
  private _disposeItems: IReactionDisposer | null = null
  private _disposeDisabledCall: IReactionDisposer | null = null
  private _loading = false
  private _isCallStarted = false
  private _buyCreditsModalStore = new BuyCreditsModalStore()
  private _callPopUpPowerDialerSessionStore = new CallPopUpPowerDialerSessionStore()

  constructor() {
    makeAutoObservable(this)

    this.reactionCallStatus()
    this.reactionPause()
    this.reactionItems()
    this.reactionDisabledCall()
  }

  addItems = (items: Contact[]) => {
    items.forEach((item) => {
      this.addItem(item)
    })

    if (this.items.length) {
      this._item = this.items[0]
    }
  }

  addItem = (contact: Contact) => {
    const item = new CallContact(
      {
        contact: {
          id: contact.id,
          first_name: contact.first_name,
          last_name: contact.last_name,
          full_name: contact.full_name,
          color: contact.color,
          formatted_number: contact.formatted_number || '',
          number: contact.number || '',
        },
        call: {
          sid: '',
        },
      },
      false
    )

    this._itemsMap.set(item.id, item)
  }

  addOptions = (options: ICallPopUpPowerDialerStoreOptions) => {
    this._options = options
  }

  getItem = (id: number) => {
    return this._itemsMap.get(id)
  }

  deleteItem = (id: number) => {
    this._itemsMap.delete(id)
    this.sessionCacheStore.deleteContact(id)
  }

  startTimer = () => {
    this._timerInterval = setInterval(() => {
      runInAction(() => {
        this._timer -= 1000

        if (this._timer === 0) {
          this.clearTimer()
          this.handleCall()
        }
      })
    }, 1000)
  }

  clearTimer = () => {
    if (!this._timerInterval) return

    clearInterval(this._timerInterval)
  }

  restartTimer = () => {
    this._timer = timer
    this.clearTimer()
    this.startTimer()
  }

  reset = () => {
    this._disposeCallStatus?.()
    this._disposePause?.()
    this._disposeItems?.()
    this._disposeDisabledCall?.()
    this._item = null
    this._itemsMap.clear()
    this.sessionCacheStore.reset()
    this.clearTimer()
  }

  handleSkip = () => {
    if (!this._item) return

    this.deleteItem(this._item.id)

    if (this.items.length) {
      this._item = this.items[0]
    } else {
      this._item = null

      this._options?.removePowerDialer()
    }
  }

  handleCall = async () => {
    if (!this._item) return
    if (!this._options?.team) return
    if (!this._options?.number) return
    if (this.disabled) return

    try {
      if (this.handleCheckCredits()) {
        this.setPause(true)

        return
      }

      this.clearTimer()

      runInAction(() => {
        this._loading = true
      })

      callStore.setStartedCall(true)
      const isAllowed = await callStore.checkMicrophone()

      if (!isAllowed) {
        callStore.setStartedCall(false)
        this.setPause(true)

        toastStore.add({
          type: 'error',
          title: 'Microphone access required',
          desc: 'To enable calling, please allow Salesmsg to access your microphone.',
          action: {
            link: links.enableMicrophone,
            text: 'Learn more',
          },
        })

        return
      }

      const item = this._item
      const conversation = await conversationStore.createConversation({
        contact_id: item.id,
        team_id: this._options?.team.id,
        number_id: this._options?.number.id,
      })

      if (conversation) {
        this._isCallStarted = true
        await callStore.connectTwilio(conversation.id)

        runInAction(() => {
          if (item) {
            this.deleteItem(item.id)
          }
        })
      }
    } catch (e) {
      console.log(e)

      this.restartTimer()
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  handlePause = () => {
    this.setPause(!this._isPause)

    eventLogStore.logEvent(
      'Power Dialer Used',
      {
        event_id: 'power_dialer_used',
        action: this._isPause ? 'paused' : 'resumed',
      },
      { groupId: organizationStore.id }
    )
  }

  handleStop = () => {
    this._modalStopId = nanoid()

    this.clearTimer()

    modalStore.addModal({
      id: this._modalStopId,
      type: ModalTypeList.ALERT,
      title: 'Stop Power Dialer?',
      primaryAction: {
        text: 'Stop',
        onAction: async () => {
          eventLogStore.logEvent(
            'Power Dialer Used',
            {
              event_id: 'power_dialer_used',
              action: 'stopped',
            },
            { groupId: organizationStore.id }
          )

          this.reset()
          this._options?.removePowerDialer()
          modalStore.removeModal(this._modalStopId)
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          this.startTimer()
          modalStore.closeModal(this._modalStopId)
        },
      },
      zIndex: ZIndex.OVERLAY_TOP_1,
    })
  }

  handleDeleteItem = (item: CallContact) => {
    this.deleteItem(item.id)

    this._options?.removePowerDialer()
  }

  handleCheckCredits = () => {
    const trialCredits = organizationStore.trialCredits
    const accountCredits = organizationStore.accountCredits
    const isTrial = subscriptionStore.isTrial

    if (isTrial && trialCredits <= 0) {
      toastStore.add({
        title: 'Your organization is out of message credits',
        type: 'error',
      })

      return true
    }

    if (!isTrial && accountCredits <= 0) {
      if (billingStore.autorecharge?.is_active) return false

      this._buyCreditsModalStore.openModal()

      return true
    }

    return false
  }

  setPause = (value: boolean) => {
    this._isPause = value
  }

  reactionCallStatus = () => {
    this._disposeCallStatus?.()
    this._disposeCallStatus = reaction(
      () => callStore.status,
      (status) => {
        if (status !== Call.State.Closed) {
          if (this._isCallStarted) {
            this._item = null
          } else {
            this.setPause(true)
          }
        }

        if (status === Call.State.Closed) {
          if (!this._isPause) {
            if (this.items.length) {
              this._item = this.items[0]
            }
          }

          this._isCallStarted = false
        }
      }
    )
  }

  reactionPause = () => {
    this._disposePause?.()
    this._disposePause = reaction(
      () => this.isPause,
      (value) => {
        if (value) {
          this._item = null
        } else if (this.items.length) {
          this._item = this.items[0]
        }
      }
    )
  }

  reactionItems = () => {
    this._disposeItems?.()
    this._disposeItems = reaction(
      () => this._itemsMap.size,
      (length) => {
        if (!length) {
          this.reset()
        }

        if (this.isPause) return

        this._item = length ? this.items[0] : null
      }
    )
  }

  reactionDisabledCall = () => {
    this._disposeDisabledCall?.()
    this._disposeDisabledCall = reaction(
      () => callStore.isBusy,
      (value) => {
        if (value) {
          this.clearTimer()
        }
      }
    )
  }

  get item() {
    return this._item
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get count() {
    return this.items.length
  }

  get timer() {
    return dayjs(this._timer).format('s')
  }

  get isPause() {
    return this._isPause
  }

  get hasItems() {
    return Boolean(this._itemsMap.size)
  }

  get loading() {
    return this._loading
  }

  get disabled() {
    return this._loading || callStore.isBusy
  }

  get sessionCacheStore() {
    return this._callPopUpPowerDialerSessionStore
  }
}
