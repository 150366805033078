import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { adminEditLimitsStore } from 'pages/admin/pages/organizations/modals/AdminEditLimits/store/adminEditLimitsStore'

export const AdminEditLimitsAction = observer(() => (
  <Button
    typeBtn={'contained'}
    text={'Save'}
    onClick={adminEditLimitsStore.onSubmit}
    disabled={adminEditLimitsStore.disabledSubmit}
    loading={adminEditLimitsStore.loadingSubmit}
  />
))
