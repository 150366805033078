import { makeObservable, observable } from 'mobx'
import { IIconsVector } from 'shared/ui/Icon/icons'
import { IDropdownItem } from 'shared/ui'
import { Base } from 'models'
import { TYPES_INBOX, TreeRootInboxes, IInboxKey } from 'entities/Inbox'
import { IResponseUnifiedInbox } from 'entities/Inbox/api/types'
import { ICallViaAircall } from 'entities/Organization/api/types'

export class UnifiedInbox extends Base {
  id: number
  is_favorite!: boolean
  type!: 'unified-inbox'
  root_tree!: string
  name = 'All inboxes'
  teamsIds!: number[]
  unread = 0
  isMuted = false
  color_coding_enabled = false
  origin!: IResponseUnifiedInbox
  isPFTInbox = false
  call_via_aircall!: ICallViaAircall | null

  constructor(item: IResponseUnifiedInbox) {
    super()

    this.id = item.id
    this.syncOrigin(item)

    makeObservable(this, {
      is_favorite: observable,
      root_tree: observable,
    })
  }

  initRootTree() {
    if (this.isFavoriteType) return TreeRootInboxes.FAVORITE
    if (!this.isFavoriteType) return TreeRootInboxes.SIMPLE

    return TreeRootInboxes.SMART
  }

  syncOrigin = (origin: IResponseUnifiedInbox & { root_tree?: string }) => {
    this.id = origin.id
    this.is_favorite = origin.is_favorite
    this.root_tree = origin.root_tree || this.initRootTree()
    this.type = 'unified-inbox'
    this.teamsIds = []
    this.color_coding_enabled = origin.color_coding_enabled
    this.origin = origin
    this.call_via_aircall = origin.call_via_aircall
  }

  get isCreateGroup() {
    return false
  }

  get icon(): IIconsVector {
    return 'all_inboxes'
  }

  get inboxType() {
    return TYPES_INBOX.UNIFIED
  }

  get typeKey(): IInboxKey {
    return 'unified'
  }

  get isSimpleType() {
    return Boolean(!this.isSmartInbox)
  }

  get isFavoriteType() {
    return Boolean(this.is_favorite)
  }

  get isGroupInbox() {
    return this.inboxType === TYPES_INBOX.GROUP
  }

  get isUnifiedInbox() {
    return this.inboxType === TYPES_INBOX.UNIFIED
  }

  get isSmartInbox() {
    return this.inboxType === TYPES_INBOX.SMART
  }

  get isSharedInbox() {
    return this.inboxType === TYPES_INBOX.SHARED
  }

  get dropdownData(): IDropdownItem {
    return {
      id: this.id,
      label: this.name,
      iconL: 'folder',
    }
  }

  get bodyUpdateTeamsFavorite() {
    return {
      favorite: this.is_favorite ? 0 : 1,
      inbox_id: this.id,
      inbox_type: this.isGroupInbox
        ? 'inbox_group'
        : this.isUnifiedInbox
        ? 'unified-inbox'
        : 'inbox',
    }
  }

  get bodyUpdateUserTeam() {
    return {
      id: this.id,
      type: this.inboxType,
    }
  }

  get bodyCreateTeamsGroupsByIdAddTeams() {
    return {
      team_ids: [this.id],
    }
  }

  get bodyUpdateTeamsGroupsById() {
    return {
      name: this.name,
      description: '',
      team_ids: [],
    }
  }

  get idTree() {
    return `${this.root_tree}_${this.id}`
  }

  get isCallViaAircall() {
    return this.call_via_aircall?.is_active
  }
}
