import { observer } from 'mobx-react-lite'
import React from 'react'
import { useDetailsTriggerModalContext } from 'widgets/TriggerActions/ui/TriggerDetailsModal/context/detailsTriggerModalContext'
import {
  MessageContent,
  SendFromContent,
  TagsContent,
  TrackConversionContent,
} from 'widgets/DetailsContent'
import styles from './styles.module.scss'

export const DetailsTriggerBody = observer(() => {
  const trigger = useDetailsTriggerModalContext()
  const {
    message,
    type,
    attachments,
    is_track_conversion,
    short_urls,
    conversion_id,
    sendFromNumbersProps,
    tags,
  } = trigger

  return (
    <>
      <MessageContent message={message} attachments={attachments} type={type} />
      <div className={styles.triggerParamsWrapper}>
        <SendFromContent sendFromNumbersProps={sendFromNumbersProps} />
        <TagsContent tags={tags} />
      </div>
      <TrackConversionContent
        conversionId={conversion_id}
        short_urls={short_urls}
        isTrackConversion={is_track_conversion}
      />
    </>
  )
})
