import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon, Switch } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { Contact } from 'entities/Contacts/model/Contact'
import { linkContactStore } from '../../../store/linkContactStore'
import { IntegrationContactCard } from '../../IntegrationContactCard/IntegrationContactCard'
import { MatchedContactCard } from '../../MatchedContactCard/MatchedContactCard'
import styles from './styles.module.scss'

const LinkMatchingTab = observer(({ contact }: { contact: Contact }) => {
  const { selectedMatchedId, shouldUpdate, toggleUpdate, matchedContacts } = linkContactStore

  const contacts = matchedContacts.get(contact.id) || []

  return (
    <div className={classnames(styles.wrap)}>
      <IntegrationContactCard contact={contact} noAction />

      <div className={styles.divider}>
        <div className={styles.icon}>
          <Icon icon='arrowDown' fontSize={16} color='var(--content-primary-tertiary)' />
        </div>
      </div>

      {<MatchedContactCard contacts={contacts} />}

      {selectedMatchedId && (
        <div className={styles.updateWrapper} onClick={() => toggleUpdate(!shouldUpdate)}>
          <Label
            label={{
              text: 'Update Salesmsg contact with the linked contact data',
            }}
          />
          <Switch value={shouldUpdate} onChange={toggleUpdate} size='small' />
        </div>
      )}
    </div>
  )
})

export { LinkMatchingTab }
