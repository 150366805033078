// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vc0Av18SMfwKU51nHNm_{display:flex;align-items:center;gap:8px;padding:8px 4px;border-radius:6px;background:var(--background-primary-inverted-primary);box-shadow:0px 4px 12px -2px rgba(18,19,23,.03);color:var(--content-primary-primary);font-size:13px;font-style:normal;font-weight:400;line-height:16px}.vc0Av18SMfwKU51nHNm_ p{margin:0}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMenu/ui/ConversationMenuInboxes/RequestAccessItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,eAAA,CACA,iBAAA,CACA,qDAAA,CACA,+CAAA,CACA,oCAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,wBACE,QAAA","sourcesContent":[".item {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 4px;\n  border-radius: 6px;\n  background: var(--background-primary-inverted-primary);\n  box-shadow: 0px 4px 12px -2px rgba(18, 19, 23, 0.03);\n  color: var(--content-primary-primary);\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px;\n\n  p {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "vc0Av18SMfwKU51nHNm_"
};
export default ___CSS_LOADER_EXPORT___;
