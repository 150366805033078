export const items = [
  {
    text: 'Risk Free 14 Day Trial',
  },
  {
    text: 'Cancel Anytime',
  },
  {
    text: '30 Day Money Back Guarantee',
  },
  {
    text: 'Secured and Encrypted',
  },
  {
    text: 'Your Information is Safe',
  },
]
