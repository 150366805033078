import { Alert } from 'shared/ui'

import validateContactImage from 'shared/assets/images/validateContact.png'

import { getCDNAssetURL } from 'shared/lib'
import styles from './styles.module.scss'

export const ValidateContent = () => {
  return (
    <>
      <Alert
        item={{
          type: 'infoLight',
          title: 'PhoneCheckr is a lookup tool that validates contacts’ phone numbers',
          desc: 'It prevents sending messages to invalid numbers, thus losing credits and getting blocked by carriers. ($0.01 per lookup)',
        }}
      />
      <div
        className={styles.validateContentImg}
        style={{ backgroundImage: `url(${getCDNAssetURL(validateContactImage)})` }}
      ></div>
    </>
  )
}
