import { makeAutoObservable, runInAction } from 'mobx'
import { IDropdownItem, toastStore } from 'shared/ui'
import { apiOptionsToDropdownItems } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import {
  AdminOrganizationsApi,
  ICustomPrice,
  IResponseAdminOrganizationAssume,
  IResponseAdminOrganizationById,
} from 'entities/Admin'
import { IPlan } from 'entities/Billing/api/types'
import { adminOrganizationsListStore, manageCreditsStore } from 'pages/admin/pages/organizations'
import { adminToggleBanStore } from 'pages/admin/pages/organizations/modals/AdminToggleBan/store/adminToggleBanStore'
import { adminCancelSubscriptionStore } from 'pages/admin/pages/organizations/modals/AdminCancelSubscription/store/adminCancelSubscriptionStore'
import { adminEditLimitsStore } from 'pages/admin/pages/organizations/modals/AdminEditLimits/store/adminEditLimitsStore'
import { adminSendOptOutContactReportStore } from 'pages/admin/pages/organizations/modals/AdminSendOptOutContactReport/store/adminSendOptOutContactReportStore'
import { adminSendEmailUsageReportStore } from 'pages/admin/pages/organizations/modals/AdminSendEmailUsageReport/store/adminSendEmailUsageReportStore'
import { adminOrganizationDetailStore } from 'pages/admin/pages/organizations/pages/OrganizationDetails/store/adminOrganizationDetailStore'
import { adminEditAgencyFranchiseContractContentStore } from 'pages/admin/pages/organizations/modals/AdminEditAgencyFranciseContract/store/adminEditAgencyFranchiseContractContentStore'
import { AdminRoutesEnum } from 'pages/admin'
import { adminManageTrialsStore } from 'pages/admin/pages/organizations/modals/AdminManageTrials/store/adminManageTrialsStore'
import { adminEdit10DLCExemptOptionsStore } from 'pages/admin/pages/organizations/modals/AdminEdit10DLCExemptOptions/store/adminEdit10DLCExemptOptionsStore'
import { adminUpdateTaxFreeModal } from 'pages/admin/pages/organizations/modals/AdminUpdateTaxFree/store/AdminUpdateTaxFreeStore'

class AdminOrganizationStore {
  constructor() {
    makeAutoObservable(this)
  }

  customPricesMap: Map<number, ICustomPrice> = new Map()
  proPlanMap: Map<string, IPlan> = new Map()

  customPricesItems: IDropdownItem[] = []

  loadCustomPrice = async () => {
    try {
      if (this.customPricesMap.size) return
      const { data } = await AdminOrganizationsApi.getCustomPrice()
      runInAction(() => {
        this.customPricesItems = apiOptionsToDropdownItems(data?.data)
        this.customPricesMap = new Map(data?.data.map((item) => [item.id, item]))
      })
    } catch (e) {
      console.error(e)
    }
  }

  getCustomPriceLabelById = (id?: number) => {
    if (!id) return '–'
    return this.customPricesMap.get(id)?.label || '–'
  }

  _windowAssume = (data: IResponseAdminOrganizationAssume) => {
    const windowAssume = window.open(`${window.location.origin}/conversations/null`)

    if (windowAssume) {
      windowAssume.sessionStorage.setItem('assume_token', data.assume_token)
    }
  }

  getNovaOrganizationUrl = (id: number) => {
    const url = `${window.location.origin}/nova/resources/organizations/${id}`
    return url
  }

  getSuccessAction = (fromOrgPage: boolean) =>
    fromOrgPage
      ? adminOrganizationDetailStore.updateOrganization
      : adminOrganizationsListStore.loadData

  onManageCredits = (organization: IResponseAdminOrganizationById, fromOrgPage = true) => {
    manageCreditsStore.onOpenModal(organization, this.getSuccessAction(fromOrgPage))
  }

  onToggleBan = (organization: IResponseAdminOrganizationById, fromOrgPage = true) => {
    adminToggleBanStore.onOpenModal(organization, this.getSuccessAction(fromOrgPage))
  }

  onCancelSubscription = (organization: IResponseAdminOrganizationById, fromOrgPage = true) => {
    adminCancelSubscriptionStore.onOpenModal(organization, this.getSuccessAction(fromOrgPage))
  }

  onEditLimit = (organization: IResponseAdminOrganizationById, fromOrgPage = true) => {
    adminEditLimitsStore.onOpenModal(organization, this.getSuccessAction(fromOrgPage))
  }

  onUpdateTaxFree = (organization: IResponseAdminOrganizationById, fromOrgPage = true) => {
    adminUpdateTaxFreeModal.onOpenModal(organization, this.getSuccessAction(fromOrgPage))
  }

  onSendOptOutContactReport = (organization: IResponseAdminOrganizationById) => {
    adminSendOptOutContactReportStore.onOpenModal(organization)
  }

  onSendEmailUsageReport = (organization: IResponseAdminOrganizationById) => {
    adminSendEmailUsageReportStore.onOpenModal(organization)
  }

  onEditAgencyFranchiseContract = (organization: IResponseAdminOrganizationById) => {
    adminEditAgencyFranchiseContractContentStore.onOpenModal(organization, () =>
      adminOrganizationDetailStore.getData()
    )
  }

  onManageTrials = (organization: IResponseAdminOrganizationById) => {
    adminManageTrialsStore.onOpenModal(
      organization,
      adminOrganizationDetailStore.updateOrganization
    )
  }

  onManagePaymentPlan = (organization: IResponseAdminOrganizationById) => {
    uiStore.navigate &&
      uiStore.navigate(
        `/${AdminRoutesEnum.admin}/${AdminRoutesEnum.organizations}/${organization.id}/${AdminRoutesEnum.customPayments}`
      )
  }

  onAssumeOrganization = async (id: number) => {
    try {
      const { data } = await AdminOrganizationsApi.createAssumeOrganization(id)
      this._windowAssume(data)
    } catch (e) {
      if (e instanceof Error) {
        toastStore.add({
          title: e.message,
          type: 'error',
        })
      }
      console.error(e)
    }
  }
  onAssumeUser = async (id: number) => {
    try {
      const { data } = await AdminOrganizationsApi.createAssumeUser(id)
      this._windowAssume(data)
    } catch (e) {
      if (e instanceof Error) {
        toastStore.add({
          title: e.message,
          type: 'error',
        })
      }
      console.error(e)
    }
  }

  onEdit10DLCExemptOptions = (organization: IResponseAdminOrganizationById) => {
    adminEdit10DLCExemptOptionsStore.onOpenModal(organization, () =>
      adminOrganizationDetailStore.getData()
    )
  }
}

export const adminOrganizationStore = new AdminOrganizationStore()
