import { makeAutoObservable } from 'mobx'
import {
  IResponseFilter,
  IResponseFilters,
  IResponseCustomFilter,
  IResponseFilterOperator,
} from 'entities/Contacts/api/filterTypes'
import { IFilterGroup } from 'widgets/FilterEditor/types'

import { getFilterGroups } from 'widgets/FilterEditor/utils'

export class FiltersConfig {
  private _filtersMap = new Map<string, IResponseFilter>()
  private _customFiltersMap = new Map<string, IResponseCustomFilter>()
  private _operatorsMap = new Map<string, IResponseFilterOperator[]>()
  private _filterGroups = new Array<IFilterGroup>()

  constructor() {
    makeAutoObservable(this)
  }

  get filterGroups() {
    return this._filterGroups
  }

  populate({ filters, custom_filters, operators }: Partial<IResponseFilters>) {
    filters?.forEach((filter) => this._filtersMap.set(filter.key, filter))

    custom_filters?.forEach((filter) => this._customFiltersMap.set(filter.key, filter))

    Object.entries(operators ?? {}).forEach(([key, operator]) =>
      this._operatorsMap.set(key, operator)
    )

    this._filterGroups = getFilterGroups(...(filters ?? []), ...(custom_filters ?? []))
  }

  getFilter(key: string) {
    return this._filtersMap.get(key) ?? null
  }

  getCustomFilter(key: string) {
    return this._customFiltersMap.get(key) ?? null
  }

  getAnyFilter(key: string) {
    return this._filtersMap.get(key) ?? this._customFiltersMap.get(key) ?? null
  }

  getOperators(type: string) {
    return this._operatorsMap.get(type) ?? null
  }
}
