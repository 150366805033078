import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import classNames from 'classnames'
import { checkCDN } from 'shared/lib/checkCDN'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { MessageCardType, MessageProps } from 'widgets/MessageCard/ui/types'
import { ConversationMessageCardItem } from 'widgets/MessageCard/ui/ConversationMessageCardItem/ConversationMessageCardItem'
import { DateContent } from 'widgets/MessageCard/ui/DateContent/DateContent'
import { BodyRawContent } from 'widgets/MessageCard/ui/BodyRawContent/BodyRawContent'
import styles from './styles.module.scss'

type ITextContentProps = MessageProps & {
  selectedSearch: string
}

export const TextContent: FC<ITextContentProps> = observer((props) => {
  const { body, previewGifs, undoPending } = props.message
  const noPreviews = !previewGifs.length
  const noBody = !body

  const onOpenPresentationOtherAttachments = (index: number) => () => {
    presentationStore.setAttachments(previewGifs, index)
  }

  if (noBody && noPreviews) return null

  return (
    <ConversationMessageCardItem
      className={classNames({
        [styles.withPreviews]: noPreviews,
        [styles.undoPendingMessage]: undoPending,
      })}
      message={props.message}
      type={previewGifs.length ? MessageCardType.GifPreview : MessageCardType.Text}
    >
      {previewGifs.map((gif, index) => (
        <img
          key={gif.source}
          src={gif.source}
          alt=''
          className={styles.img}
          onClick={onOpenPresentationOtherAttachments(index)}
          aria-hidden={true}
          {...(checkCDN(gif.source) ? { crossOrigin: 'use-credentials' } : null)}
        />
      ))}
      <BodyRawContent message={props.message} selectedSearch={props.selectedSearch} />
      <DateContent {...props} />
    </ConversationMessageCardItem>
  )
})
