import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'

type IEmptyStateProps = {
  children: ReactNode
}

export const EmptyState: FC<IEmptyStateProps> = ({ children }) => {
  return <div className={styles.emptyData}>{children}</div>
}
