import { observer } from 'mobx-react-lite'
import React, { ReactNode } from 'react'
import { AlertBanner } from 'shared/ui'
import { bannerStore } from 'shared/ui/Banner'

type IBannerProps = {
  globalBanners: ReactNode
}

export const Banner = observer(({ globalBanners }: IBannerProps) => {
  const { items } = bannerStore
  return (
    <div>
      {items.map((item) => (
        <AlertBanner key={item.id} {...item.alert} action={item.action} />
      ))}
      {globalBanners}
    </div>
  )
})
