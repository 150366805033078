import { Blocker, useBlocker } from 'react-router'
import { useEffect } from 'react'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalType, ModalTypeList } from 'shared/ui/Modal/store/types'

export function useUnsavedChanges(
  isHasUnsavedChanges: boolean,
  action?: (blocker: Blocker) => ModalType
) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isHasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname
  )

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const id = nanoid()
      action
        ? modalStore.addModal(action(blocker))
        : modalStore.addModal({
            id,
            type: ModalTypeList.INFO,
            title: 'Information',
            desc: 'You have unsaved changed do you want to continue?',
            primaryAction: {
              text: 'Yes',
              onAction: () => blocker?.proceed && blocker?.proceed(),
            },
            secondaryAction: {
              text: 'Cancel',
              onAction: () => {
                blocker?.reset && blocker?.reset()
                modalStore.closeModal(id)
              },
            },
          })
    }
  }, [blocker.state])
}
