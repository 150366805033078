import { makeAutoObservable } from 'mobx'
import type {
  IIntegrationsOpportunity,
  IIntegrationsProperty,
} from 'entities/Integrations/model/types'
import { IntegrationProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationProperty'

export class IntegrationOpportunityProperty {
  private _id: number | null = null
  private _title: string | null = null
  private _propertiesMap: Map<string, IntegrationProperty> = new Map()

  constructor(item: IIntegrationsOpportunity) {
    this._id = Number(item.id)
    this._title = item.title
    this.addProperties(item)

    makeAutoObservable(this)
  }

  get id() {
    return this._id || 0
  }

  get title() {
    return this._title || ''
  }

  get amount() {
    return this.getProperty('amount')?.value || ''
  }

  get owner() {
    return this.getProperty('owner')?.value || ''
  }

  get stage() {
    return this.getProperty('stage')?.value || ''
  }

  get properties() {
    return Array.from(this._propertiesMap.values())
  }

  get propertiesKeys() {
    return this.properties.map((item) => item.key)
  }

  addProperties = (item: IIntegrationsOpportunity) => {
    Object.entries(item).forEach(([k, v]) => {
      const key = k as keyof IIntegrationsOpportunity
      let label = String(v) || ''
      const value = v ? String(v) : ''

      if (key === 'id') return
      if (key === 'title') return
      if (key === 'amount') label = 'Amount'
      if (key === 'owner') label = 'Owner'
      if (key === 'stage') label = 'Stage'

      this.addProperty({
        key: key,
        label: label,
        value: value,
      })
    })
  }

  addProperty = (item: IIntegrationsProperty) => {
    this._propertiesMap.set(item.key, new IntegrationProperty(item))
  }

  getProperty = (key: keyof IIntegrationsOpportunity) => {
    return this._propertiesMap.get(key)
  }

  removeProperty = (key: string) => {
    this._propertiesMap.delete(key)
  }
}
