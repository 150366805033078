// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g4WgsnwpU4o6GxdERKdU{position:relative;padding:3px 22px 3px 3px}.g4WgsnwpU4o6GxdERKdU .RvyCnJul8CVDKgcf9JYL{margin:22px 0 18px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/ContactsUnlinkedTable/LinkContact/ui/LinkContactModalContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,wBAAA,CAEA,4CACE,kBAAA","sourcesContent":[".wrap {\n  position: relative;\n  padding: 3px 22px 3px 3px;\n\n  .tabs {\n    margin: 22px 0 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "g4WgsnwpU4o6GxdERKdU",
	"tabs": "RvyCnJul8CVDKgcf9JYL"
};
export default ___CSS_LOADER_EXPORT___;
