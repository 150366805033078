// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YO8jdQoe8agTGP1M4c70{display:flex;align-items:center}.qd90jEvKEOQkvwwEKwIQ{margin-right:4px}.qd90jEvKEOQkvwwEKwIQ>div{color:var(--content-primary-primary-inverted)}.TntKx7aplb_2MN64B2CQ{color:var(--content-primary-primary-inverted);font-size:13px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/shared/ui/Tooltip/ui/TooltipSharedLink/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CAEA,0BACE,6CAAA,CAIJ,sBACE,6CAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n}\n\n.wrapIcon {\n  margin-right: 4px;\n\n  & > div {\n    color: var(--content-primary-primary-inverted)\n  }\n}\n\n.label {\n  color: var(--content-primary-primary-inverted);\n  font-size: 13px;\n  font-weight: 400;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "YO8jdQoe8agTGP1M4c70",
	"wrapIcon": "qd90jEvKEOQkvwwEKwIQ",
	"label": "TntKx7aplb_2MN64B2CQ"
};
export default ___CSS_LOADER_EXPORT___;
