import { FC, useState } from 'react'
import classnames from 'classnames'
import { Icon } from 'shared/ui/Icon'
import styles from './styles.module.scss'

export type ITooltipItem = {
  label?: React.ReactNode
  total?: string
  desc?: string
  dropdown?: string
  title?: string
}

export type ITooltipBillingProps = {
  items?: ITooltipItem[]
}

const TooltipBillingItem: FC<ITooltipItem> = (props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    if (!props.dropdown) return

    setOpen(!open)
  }

  return (
    <div className={styles.item}>
      <div className={styles.item__title}>{props.label}</div>
      <div
        className={classnames(styles.item__desc, {
          [styles.item_hasDropDown]: props.dropdown,
        })}
        onClick={handleOpen}
      >
        <span>{props.desc}</span>
        {Boolean(props.dropdown) && (
          <span>
            <Icon icon={open ? 'chevronUp' : 'chevronDown'} />
          </span>
        )}
      </div>
      {open && <div className={styles.dropdown}>{props.dropdown}</div>}
    </div>
  )
}

export const TooltipBilling: FC<ITooltipBillingProps> = ({ items = [] }) => {
  return (
    <div className={styles.wrap}>
      {items.map((item, index) => {
        return <TooltipBillingItem key={index} {...item} />
      })}
    </div>
  )
}
