import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import type { IResponseEventMessage } from 'entities/Message'
import type { ConversationsStore } from 'entities/Conversation'
import type { ContactsConversationOpenStore } from 'widgets/ContactsDetails/ui/ContactsConversationOpen'

export const EventMessageUpdatedNew = {
  subscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.on(WebsocketEvent.MessageUpdatedNew, handler),
  unsubscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.off(WebsocketEvent.MessageUpdatedNew, handler),
}

export const useEventMessageUpdatedNew = (
  conversationStore: ConversationsStore,
  contactsConversationOpenStore: ContactsConversationOpenStore
) =>
  useEffect(
    () =>
      EventMessageUpdatedNew.subscribe((data) => {
        const conversation = conversationStore.addItem({
          ...data.conversation,
          is_locked: false,
        })
        const conversationMessageStore = contactsConversationOpenStore.conversationMessagesStore

        if (contactsConversationOpenStore.inbox?.id !== data.conversation.inbox_id) return
        if (conversationMessageStore.conversationId) return

        contactsConversationOpenStore.contactsDetailsStore.setConversationId(conversation.id)

        if (data.message.conversation_id === conversationMessageStore.conversationId) {
          conversationMessageStore.setItems([data.message])
        }
      }),
    []
  )
