import Box from '@mui/material/Box'
import { SxProps, TableBody, TableContainer, TableHead } from '@mui/material'
import TableMU from '@mui/material/Table'

import React, { ReactNode, useEffect, useState, UIEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { EnumSpinnerLoaderPosition, IRow, Scrollbar, SpinnerLoader } from 'shared/ui'
import { TableRowHead } from 'shared/ui/Table/TableRowHead/TableRowHead'
import { ExpandedTableRow } from 'shared/ui/Table/ExpandedTableRow/ExpandedTableRow'
import { TableContext } from 'shared/ui/Table/context/context'
import { TableStore } from 'shared/ui/Table/store/tableStore'
import { IHeaderLeftContentProps } from 'shared/ui/Table/SelectedContent/HeaderLeftContent/HeaderLeftContent'
import { Base } from 'models'
import { Pagination } from './Pagination/Pagination'

import styles from './Table.module.scss'

import { IColumn } from './types'

export interface ITableProps<TRow> {
  columns: IColumn<TRow>[]
  hideExpandColumnsIds?: Array<keyof IRow<TRow> | string>
  rows: IRow<TRow>[]
  rowsCount?: number
  isLoading?: boolean
  withCheckbox?: boolean
  withSelectAll?: boolean
  withoutSelectedCount?: boolean
  activeRowId?: number
  rightHeaderContent?: ReactNode
  actionsCenter?: ReactNode
  pinnedColumnNames?: Array<keyof IRow<TRow> | string>
  inActionId?: number | null
  initPage?: number
  limit?: number | null
  startPaginationSize?: number | null
  noPagination?: boolean
  isCheckDate?: boolean
  visibleColumnsIds?: string[]
  noMinWidthColumns?: boolean
  height?: string
  defaultWidthColumn?: number | string
  defaultMinWidthColumn?: number | string
  defaultMaxWidthColumn?: number | string
  onChangePagination?: (page: number, pageSize: number) => void
  onRowClick?: (row: IRow<TRow>) => void
  getRowLink?: (row: IRow<TRow>) => string
  setVisibleColumnsIds?: (ids: string[]) => void
  initVisibleColumnsIds?: (ids: string[]) => void
  onSort?: (field: string | number) => void
  sxTable?: SxProps
  sxBox?: SxProps
  sortOrder?: 'asc' | 'desc'
  sortBy?: string
  bottomTableContent?: ReactNode
  scrollToTOpTrigger?: string
  isHoveredRowActions?: boolean
  isCursor?: boolean
  classNameScroll?: string
  customScrollEvent?: (e: UIEvent) => void
  setRows?: TableStore<IRow<TRow>>['setRows']
  setTotal?: TableStore<IRow<TRow>>['setTotal']
  headerLeftContentProps?: IHeaderLeftContentProps
}

export type ITablePropsContent<TRow> = ITableProps<TRow> & {
  onRowSelect?: (row: IRow<TRow>, isSelect: boolean) => void
  selectedIds?: Array<string | number>
  bulkAllMode?: boolean
  toggleSelectAll?: (isSelected: boolean) => void
}

const TableContent = <TRow extends Base>({
  columns,
  rows,
  activeRowId,
  rowsCount = 0,
  isLoading = false,
  rightHeaderContent,
  pinnedColumnNames = [],
  selectedIds = [],
  inActionId,
  initPage,
  withCheckbox,
  withSelectAll,
  withoutSelectedCount,
  hideExpandColumnsIds,
  noPagination,
  isCheckDate,
  defaultWidthColumn,
  defaultMinWidthColumn,
  defaultMaxWidthColumn,
  visibleColumnsIds,
  startPaginationSize,
  limit,
  height = '100%',
  onRowSelect,
  onRowClick,
  onChangePagination = () => {},
  setVisibleColumnsIds,
  initVisibleColumnsIds,
  getRowLink,
  toggleSelectAll,
  onSort,
  sortOrder,
  sxTable,
  sxBox,
  sortBy,
  bottomTableContent,
  scrollToTOpTrigger,
  customScrollEvent,
  isHoveredRowActions,
  isCursor,
  classNameScroll,
  setRows,
  setTotal,
  headerLeftContentProps,
}: ITablePropsContent<TRow>) => {
  const [isScrollLeft, setIsScrollLeft] = useState(false)
  const visibleColumns = visibleColumnsIds
    ? columns.filter((column) => visibleColumnsIds.includes(column.field as string))
    : columns

  useEffect(() => {
    if (!visibleColumnsIds?.length && initVisibleColumnsIds) {
      initVisibleColumnsIds(
        columns.filter((column) => !column.isHideByDefault).map((column) => column.field as string)
      )
    }
  }, [columns])

  const pinnedColumns = visibleColumns.filter((column) => pinnedColumnNames.includes(column.field))
  const unpinnedColumns = visibleColumns.filter(
    (column) => !pinnedColumnNames.includes(column.field)
  )
  const withActions = rows.some((item) => item.actionsProps || item.actions)

  const onScroll = (e: React.UIEvent) => {
    const target = e.target as HTMLDivElement
    setIsScrollLeft(!!target.scrollLeft)
  }

  const isSelected = (row: IRow<TRow>) => {
    return selectedIds.includes(Number(row.id))
  }

  useEffect(() => {
    setRows && setRows(rows)
  }, [rows])

  useEffect(() => {
    setTotal && setTotal(rowsCount)
  }, [rowsCount])

  return (
    <>
      {isLoading && <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />}
      <Box
        sx={sxBox}
        height={height}
        width='100%'
        display='flex'
        flexDirection='column'
        position='relative'
      >
        <TableContainer style={{ flex: 1 }}>
          <Scrollbar
            onScroll={(event: React.UIEvent) => {
              onScroll(event)
              customScrollEvent && customScrollEvent(event)
            }}
            scrollToTOpTrigger={scrollToTOpTrigger}
            viewClassName={styles.viewScrollClassName}
            className={classNameScroll}
          >
            <TableMU sx={sxTable}>
              <TableHead>
                <TableRowHead
                  toggleSelectAll={toggleSelectAll}
                  selected={Boolean(selectedIds.length)}
                  unpinnedColumns={unpinnedColumns}
                  pinnedColumns={pinnedColumns}
                  withCheckbox={withCheckbox}
                  withSelectAll={withSelectAll}
                  columns={columns}
                  visibleColumnsIds={visibleColumnsIds}
                  setVisibleColumnsIds={setVisibleColumnsIds}
                  withActions={withActions}
                  rightHeaderContent={rightHeaderContent}
                  isScrollLeft={isScrollLeft}
                  onSort={onSort}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  headerLeftContentProps={headerLeftContentProps}
                  withoutSelectedCount={withoutSelectedCount}
                />
              </TableHead>

              <TableBody>
                {rows.map((row) => (
                  <ExpandedTableRow
                    key={row.id}
                    row={row}
                    selected={isSelected(row)}
                    inAction={inActionId === row.id}
                    withCheckbox={withCheckbox}
                    pinnedColumns={pinnedColumns}
                    unpinnedColumns={unpinnedColumns}
                    onRowSelect={onRowSelect}
                    onRowClick={onRowClick}
                    activeRowId={activeRowId}
                    isCheckDate={isCheckDate}
                    getRowLink={getRowLink}
                    hideExpandColumnsIds={hideExpandColumnsIds}
                    defaultWidthColumn={defaultWidthColumn}
                    defaultMaxWidthColumn={defaultMaxWidthColumn}
                    defaultMinWidthColumn={defaultMinWidthColumn}
                    isScrollLeft={isScrollLeft}
                    withActions={withActions}
                    isHoveredRowActions={isHoveredRowActions}
                    isCursor={isCursor}
                  />
                ))}
                {bottomTableContent}
              </TableBody>
            </TableMU>
          </Scrollbar>
        </TableContainer>
        {!noPagination && (
          <Pagination
            rowsCount={rowsCount}
            onChange={onChangePagination}
            initPage={initPage}
            limit={limit}
            startSize={startPaginationSize}
          />
        )}
      </Box>
    </>
  )
}

export const Table = observer(
  <TRow extends Base>({
    store,
    ...props
  }: {
    store?: TableStore<TRow>
  } & ITableProps<TRow>) => {
    return (
      <TableContext.Provider value={(store as unknown as TableStore) || null}>
        <TableContent
          setRows={store?.setRows}
          setTotal={store?.setTotal}
          onSort={store?.onSort}
          sortOrder={store?.sortOrder}
          sortBy={store?.sortBy}
          onRowSelect={store?.toggleSelected}
          selectedIds={store?.selectedIds}
          bulkAllMode={store?.bulkAllMode}
          toggleSelectAll={store?.toggleSelectAll}
          visibleColumnsIds={
            store?.withoutDefaultManageColumns ? undefined : store?.visibleColumnsIds
          }
          setVisibleColumnsIds={
            store?.withoutDefaultManageColumns ? undefined : store?.setVisibleColumnsIds
          }
          initVisibleColumnsIds={
            store?.withoutDefaultManageColumns ? undefined : store?.initVisibleColumnsIds
          }
          {...props}
        />
      </TableContext.Provider>
    )
  }
)
