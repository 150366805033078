import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { MediaLibraryStore } from 'widgets/MediaLibrary/store/mediaLibraryStore'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import {
  IMediaLibraryStoreProps,
  MediaLibrarySelectMode,
  MediaLibraryVariant,
} from 'widgets/MediaLibrary/types'
import styles from './styles.module.scss'

type MediaLibraryMessageFieldButtonProps = {
  onClick: () => void
}

export const MediaLibraryMessageFieldButton: FC<MediaLibraryMessageFieldButtonProps> = observer(
  ({ onClick }) => {
    const messageFieldStore = useMessageFieldContext()

    const mediaLibraryConfiguration: IMediaLibraryStoreProps = {
      mediaLibraryVariant: MediaLibraryVariant.MessageField,
      onAddAttachment: messageFieldStore.addAttachment,
      selectMode: MediaLibrarySelectMode.Multiple,
      messageFieldStore,
    }

    const [store] = useState(() => new MediaLibraryStore(mediaLibraryConfiguration))

    if (!featureFlagsStore.media_library_v2) return null

    return (
      <Button
        text='View all'
        typeBtn='text'
        size='small'
        className={styles.accessLibraryButton}
        onClick={() => {
          store.handleOpenMediaLibraryModal()
          onClick()
        }}
      />
    )
  }
)
