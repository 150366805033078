// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QbSTa_YbhDmmGqGbXeT_{padding-top:4px}.skKEP8NLN1JSPbl9W3OB{padding-top:8px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageFormFields/RinglessMessageField/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,eAAA","sourcesContent":[".wrap {\n  padding-top: 4px;\n}\n.wrapSpeech {\n  padding-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "QbSTa_YbhDmmGqGbXeT_",
	"wrapSpeech": "skKEP8NLN1JSPbl9W3OB"
};
export default ___CSS_LOADER_EXPORT___;
