import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { AxiosError, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { toastStore } from 'shared/ui'
import { DayjsFormats } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import {
  AIAssistantApi,
  IAIActionTypeEnum,
  IAILayoutEnum,
  IParamsCreateSuggestAnswer,
  IParamsCreateSummaryAnswer,
  IResponseAIAnswer,
} from 'entities/AIAssistant'
import { inboxesStore } from 'entities/Inbox'

type IAiAssistantInitProps = {
  text: string
  handleOnLike: ((answer: string) => void) | null
}

export type IAiAssistantStoreProps = {
  currentTextObj: { value: string }
  selectedContentObj: { value: string }
  actions: Array<IAIActionTypeEnum>
  triggerElementObj: { value: HTMLDivElement | null }
  getParamsCreateSuggestAnswer?: () => IParamsCreateSuggestAnswer
  getParamsCreateSummaryAnswer?: () => IParamsCreateSummaryAnswer
  additionalCreateSummaryAnswerAction?: () => void
  onReplaceAllContent: (value: string) => void
  replaceSelectedContentObj: { fn: ((value: string) => void) | null }
  isVariantContained?: { value: boolean }
}

export class AiAssistantStore {
  currentTextObj: IAiAssistantStoreProps['currentTextObj']
  selectedContentObj: IAiAssistantStoreProps['selectedContentObj']
  getParamsCreateSuggestAnswer: IAiAssistantStoreProps['getParamsCreateSuggestAnswer'] | null = null
  getParamsCreateSummaryAnswer: IAiAssistantStoreProps['getParamsCreateSummaryAnswer'] | null = null
  actions: IAiAssistantStoreProps['actions']
  triggerElementObj: IAiAssistantStoreProps['triggerElementObj']
  onReplaceAllContent: IAiAssistantStoreProps['onReplaceAllContent']
  replaceSelectedContentObj: IAiAssistantStoreProps['replaceSelectedContentObj']
  additionalCreateSummaryAnswerAction: IAiAssistantStoreProps['additionalCreateSummaryAnswerAction']
  isVariantContained: IAiAssistantStoreProps['isVariantContained']

  constructor({
    currentTextObj,
    actions,
    getParamsCreateSummaryAnswer,
    getParamsCreateSuggestAnswer,
    triggerElementObj,
    selectedContentObj,
    onReplaceAllContent,
    replaceSelectedContentObj,
    additionalCreateSummaryAnswerAction,
    isVariantContained,
  }: IAiAssistantStoreProps) {
    makeAutoObservable(this)

    this.currentTextObj = currentTextObj
    this.actions = actions
    this.getParamsCreateSummaryAnswer = getParamsCreateSummaryAnswer
    this.getParamsCreateSuggestAnswer = getParamsCreateSuggestAnswer
    this.triggerElementObj = triggerElementObj
    this.selectedContentObj = selectedContentObj
    this.onReplaceAllContent = onReplaceAllContent
    this.replaceSelectedContentObj = replaceSelectedContentObj
    this.additionalCreateSummaryAnswerAction = additionalCreateSummaryAnswerAction
    this.isVariantContained = isVariantContained

    this.reactionSelectedContentObj()
    this.reactionCurrentTextObj()
  }

  private _disposeSelectedContentObj: IReactionDisposer | null = null

  reactionSelectedContentObj = () => {
    this._disposeSelectedContentObj?.()
    this._disposeSelectedContentObj = reaction(
      () => this.selectedContentObj.value,
      (value) => {
        this.text = value
      }
    )
  }
  private _disposeCurrentTextObj: IReactionDisposer | null = null

  reactionCurrentTextObj = () => {
    this._disposeCurrentTextObj?.()
    this._disposeCurrentTextObj = reaction(
      () => this.currentTextObj.value,
      (value) => {
        if (this.text.length) {
          this.text = ''
        }

        if (value && this.open) {
          this.setOpen(false)
        }
      }
    )
  }

  loadingGeneration = false
  loadingTryAgain = false
  private _open = false
  get open() {
    return this._open
  }
  answer = ''
  actionType: IAIActionTypeEnum | null = null
  actionRequest: (() => void | Promise<AxiosResponse<IResponseAIAnswer>>) | null = null
  paramsCreateSuggestAnswer: IParamsCreateSuggestAnswer | null = null
  paramsCreateSummaryAnswer: IParamsCreateSummaryAnswer | null = null
  text = ''
  handleOnLike: ((answer: string) => void) | null = null
  template: string | null = null

  layout: IAILayoutEnum = IAILayoutEnum.Row

  get triggerElement() {
    return this.triggerElementObj.value
  }

  get isEmpty() {
    return !this.text.length
  }

  get disabledSuggested() {
    return Boolean(!this.paramsCreateSuggestAnswer?.messages.length)
  }

  get disabledSummarizeConversation() {
    return this.isContactsPage || Boolean(!this.paramsCreateSummaryAnswer?.messages.length)
  }

  get showSuggested() {
    return Boolean(this.actions?.find((action) => action === IAIActionTypeEnum.SuggestResponse))
  }

  get showSummarizeConversation() {
    return Boolean(
      this.actions?.find((action) => action === IAIActionTypeEnum.SummarizeConversation)
    )
  }

  get showRephrase() {
    return Boolean(this.actions?.find((action) => action === IAIActionTypeEnum.Rephrase))
  }

  get showMoreFriendly() {
    return Boolean(this.actions?.find((action) => action === IAIActionTypeEnum.MoreFriendly))
  }

  get showMoreFormal() {
    return Boolean(this.actions?.find((action) => action === IAIActionTypeEnum.MoreFormal))
  }

  get showExpand() {
    return Boolean(this.actions?.find((action) => action === IAIActionTypeEnum.Expand))
  }

  get showShorten() {
    return Boolean(this.actions?.find((action) => action === IAIActionTypeEnum.Shorten))
  }

  get isColumn() {
    return this.layout === IAILayoutEnum.Column
  }

  get isContactsPage() {
    return uiStore.pathName.includes('contacts')
  }

  _init = ({ text, handleOnLike }: IAiAssistantInitProps) => {
    this.text = text
    this.handleOnLike = handleOnLike
    this.paramsCreateSuggestAnswer = this.getParamsCreateSuggestAnswer?.() || null
    this.paramsCreateSummaryAnswer = this.getParamsCreateSummaryAnswer?.() || null
    this.setOpen(true)
  }
  reset = () => {
    this.answer = ''
    this.loadingGeneration = false
    this.loadingTryAgain = false
    this.paramsCreateSuggestAnswer = null
    this.paramsCreateSummaryAnswer = null
    this.template = null
  }

  handleTooltipOpen = () => {
    this._init({
      text: this.text || this.currentTextObj.value,
      handleOnLike: this.onReplaceAllContent,
    })
  }

  setOpen = (value: boolean) => {
    this._open = value
    if (!value) {
      this.reset()
    }
  }

  onAction = async (request: () => void | Promise<AxiosResponse<IResponseAIAnswer>>) => {
    try {
      this.loadingGeneration = true
      this.actionRequest = request

      const res = await request()

      if (!res?.data?.result_message) {
        this.answer = 'The AI texting assistant ran into issues. Please update your text and retry.'
      } else {
        this.answer = this.template
          ? `${this.template}\n ${res.data.result_message}`
          : res.data.result_message
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        toastStore.add({
          type: 'error',
          title: e.response?.data.message,
        })
        console.error(e)
      }
    } finally {
      this.loadingGeneration = false
    }
  }

  onTryAgain = async () => {
    try {
      this.loadingTryAgain = true
      if (this.actionRequest) {
        const res = await this.actionRequest()
        this.answer = res?.data.result_message
          ? this.template
            ? `${this.template}\n ${res?.data?.result_message}`
            : res?.data?.result_message
          : ''
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        toastStore.add({
          type: 'error',
          title: e.response?.data.message,
        })
        console.error(e)
      }
      console.error(e)
    } finally {
      this.loadingTryAgain = false
    }
  }

  onCreateSuggestAnswer = () => {
    this.actionType = IAIActionTypeEnum.SuggestResponse
    const request = () => {
      if (this.getParamsCreateSuggestAnswer) {
        return AIAssistantApi.createSuggestAnswer(this.getParamsCreateSuggestAnswer())
      }
    }

    this.onAction(request)
  }

  onCreateSummaryAnswer = () => {
    this.actionType = IAIActionTypeEnum.SummarizeConversation
    const request = () => {
      if (this.getParamsCreateSummaryAnswer) {
        return AIAssistantApi.createSummaryConversationAnswer(this.getParamsCreateSummaryAnswer())
      }
    }

    if (this.additionalCreateSummaryAnswerAction) {
      this.additionalCreateSummaryAnswerAction()
    }
    this.setTemplate()
    this.onAction(request)
  }
  onRephrase = () => {
    this.actionType = IAIActionTypeEnum.Rephrase
    const request = () => AIAssistantApi.getRephrase(this.text)

    this.onAction(request)
  }
  onMoreFriendly = () => {
    this.actionType = IAIActionTypeEnum.MoreFriendly
    const request = () => AIAssistantApi.getMoreFriendly(this.text)

    this.onAction(request)
  }
  onMoreFormal = () => {
    this.actionType = IAIActionTypeEnum.MoreFormal
    const request = () => AIAssistantApi.getMoreFormal(this.text)

    this.onAction(request)
  }
  onExpand = () => {
    this.actionType = IAIActionTypeEnum.Expand
    const request = () => AIAssistantApi.getExpand(this.text)

    this.onAction(request)
  }
  onShorten = () => {
    this.actionType = IAIActionTypeEnum.Shorten
    const request = () => AIAssistantApi.getShorten(this.text)

    this.onAction(request)
  }

  onLike = () => {
    if (this.answer && this.handleOnLike) {
      this.handleOnLike(this.answer)
    }
    this.setOpen(false)
  }
  onClose = () => {
    this.setOpen(false)
  }

  setTemplate = () => {
    const dateNow = dayjs.utc().local()
    this.template = `Conversation summary from ${inboxesStore.currentInbox?.name} ${dateNow.format(
      DayjsFormats.slash
    )}`
  }

  setLayout = (key: IAILayoutEnum) => {
    this.layout = key
  }
}
