import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import type { IDropdownItem } from '../../Dropdown'
import { CountryEnum } from '../types'

export class PhoneInputStore {
  private _disposeNumberValidation: IReactionDisposer | null = null
  private _numberError: string | null = null
  private _numberRequestError: string | null = null
  private _numberValidationTrigger: string | null = null
  private _number = ''
  private _country = this.countries[0]

  constructor() {
    makeAutoObservable(this)
    this.reactionNumberValidation()
  }

  get number() {
    return this._number
  }

  get country() {
    return this._country
  }

  get isValidNumber() {
    return this.number.replace(/[^-.0-9]/g, '').length === 11
  }

  get numberError() {
    return this._numberError
  }

  get numberRequestError() {
    return this._numberRequestError
  }

  get countries(): IDropdownItem[] {
    return [
      {
        label: 'United States',
        id: CountryEnum.US,
        iconL: 'flagUSA',
        labelRight: '+1',
      },
      {
        label: 'Canada',
        id: CountryEnum.CA,
        iconL: 'flagCanada',
        labelRight: '+1',
      },
    ]
  }

  triggerNumberValidation = () => {
    this._numberValidationTrigger = nanoid()
  }

  setNumber = (value: string) => {
    if (this._numberRequestError) this.setNumberRequestError('')
    this._number = value
  }

  setNumberError = (value: string) => {
    this._numberError = value
  }

  setNumberRequestError = (value: string) => {
    this._numberRequestError = value
  }

  setCountry = (value: IDropdownItem) => {
    this._country = value
  }

  reset = () => {
    this._number = ''
    this._numberError = null
    this._numberRequestError = null
    this._numberValidationTrigger = null
    this._country = this.countries[0]
  }

  reactionNumberValidation = () => {
    this._disposeNumberValidation?.()
    this._disposeNumberValidation = reaction(
      () => [this._numberValidationTrigger, this.isValidNumber],
      ([trigger, isValid]) => {
        this._numberError = trigger && !isValid ? 'Please enter a valid phone number' : null
        if (isValid) this._numberValidationTrigger = null
      },
      {
        fireImmediately: true,
      }
    )
  }
}
