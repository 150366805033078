// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GsMrahCUIIl0IRiWoxRw{padding:0 12px;z-index:3002;position:fixed;left:50%;transform:translateX(-50%);bottom:32px;width:480px;background-color:var(--background-primary-inverted-primary);box-shadow:var(--shadow-dropdown);border-radius:12px}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/info/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,YAAA,CACA,cAAA,CACA,QAAA,CACA,0BAAA,CACA,WAAA,CACA,WAAA,CACA,2DAAA,CACA,iCAAA,CACA,kBAAA","sourcesContent":[".imageInfo{\n  padding: 0 12px;\n  z-index: 3002;\n  position: fixed;\n  left: 50%;\n  transform: translateX(-50%);\n  bottom: 32px;\n  width: 480px;\n  background-color: var(--background-primary-inverted-primary);\n  box-shadow: var(--shadow-dropdown);\n  border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageInfo": "GsMrahCUIIl0IRiWoxRw"
};
export default ___CSS_LOADER_EXPORT___;
