export const getPicsnippetMergeFieldLabel = (fieldName: string) => {
  switch (fieldName) {
    case 'first_name':
      return 'first_name'
    case 'last_name':
      return 'last_name'
    case 'user.first_name':
      return 'user_first_name'
    case 'user.last_name':
      return 'user_last_name'
    case 'formatted_number':
      return 'phone'
    case 'email':
      return 'email'
    case 'user.email':
      return 'user_email'
    default:
      return ''
  }
}
