import { once } from 'lodash'
import { makeAutoObservable } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { Deferred } from 'shared/lib'
import { Chatbot, ChatbotApi, ChatbotInstructionType } from 'entities/Chatbot'
import { ChatbotNameModal, ChatbotTemplateModal } from 'pages/chatbot/ui/ChatbotCreate'

export class CreateChatbotStore {
  private _nameModalId = 'createChatbotNameModal'
  private _templateModalId = 'createChatbotTemplateModal'
  private _instructions = new Map<ChatbotInstructionType, string>()

  constructor() {
    makeAutoObservable(this)
  }

  init = once(async () => {
    const { data: instructions } = await ChatbotApi.getInstructions()
    instructions.forEach(({ type, instruction }) => this._instructions.set(type, instruction))
  })

  create = async (chatbot = new Chatbot()) => {
    await this.init()

    const instruction = this._instructions.get(chatbot.type)
    if (instruction) chatbot.settings.setInstruction(instruction)
    else chatbot.setType(ChatbotInstructionType.LeadQualification)

    const deferred = new Deferred<boolean>()
    this._showTemplateStep(chatbot, deferred)

    const completed = await deferred.future
    if (!completed || !chatbot.type) return null

    return chatbot.toJSON()
  }

  private _showTemplateStep = (chatbot: Chatbot, deferred: Deferred<boolean>) => {
    const onNext = () => {
      modalStore.removeModal(this._templateModalId)
      this._showNameStep(chatbot, deferred)
    }

    const onCancel = () => {
      deferred.complete(false)
      modalStore.removeModal(this._templateModalId)
    }

    const onClose = () => {
      deferred.complete(false)
      modalStore.removeModal(this._templateModalId)
    }

    modalStore.addModal({
      id: this._templateModalId,
      title: 'Choose template',
      pureContent: true,
      ModalContent: ChatbotTemplateModal,
      ModalContentProps: { instructions: this._instructions, chatbot, onNext, onCancel },
      width: 480,
      onClose,
    })
  }

  private _showNameStep = (chatbot: Chatbot, deferred: Deferred<boolean>) => {
    const onNext = () => {
      modalStore.removeModal(this._nameModalId)
      deferred.complete(true)
    }

    const onCancel = () => {
      modalStore.removeModal(this._nameModalId)
      this._showTemplateStep(chatbot, deferred)
    }

    const onClose = () => {
      deferred.complete(false)
      modalStore.removeModal(this._nameModalId)
    }

    modalStore.addModal({
      id: this._nameModalId,
      title: 'Create Textbot',
      pureContent: true,
      ModalContent: ChatbotNameModal,
      ModalContentProps: { instructions: this._instructions, chatbot, onCancel, onNext },
      width: 480,
      onClose,
    })
  }
}
