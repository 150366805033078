// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wnI46IQgbLx_UawDiNuy{color:var(--gray-60);font-size:12px;font-weight:400;padding:8.5px 0;display:flex;line-height:15px;justify-content:center}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/FilterDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,gBAAA,CACA,sBAAA","sourcesContent":[".noResult {\n    color: var(--gray-60);\n    font-size: 12px;\n    font-weight: 400;\n    padding: 8.5px 0;\n    display: flex;\n    line-height: 15px;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noResult": "wnI46IQgbLx_UawDiNuy"
};
export default ___CSS_LOADER_EXPORT___;
