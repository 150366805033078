import React, { FC } from 'react'
import { Status } from 'shared/ui'
import type { IStatusProps } from 'shared/ui/Status'
import { EmptyCell } from 'shared/ui/Table'
import type { IResponseNumberVerifiedStatus } from 'entities/Phone/api/types'
import { getNumberStatus } from './config'

type INumbersStatusProps = {
  status: IResponseNumberVerifiedStatus | string
  emphasis?: IStatusProps['emphasis']
  onlyIcon?: IStatusProps['onlyIcon']
}

export const NumbersStatus: FC<INumbersStatusProps> = ({ status, emphasis = 'high', onlyIcon }) => {
  const statusProps = getNumberStatus(status)

  return statusProps ? (
    <Status {...statusProps} emphasis={emphasis} onlyIcon={onlyIcon} />
  ) : (
    <EmptyCell />
  )
}
