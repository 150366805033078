import { createContext, useContext } from 'react'
import { ContactsTableStore } from 'widgets/ContactsTable'

export const ContactsTableContext = createContext<ContactsTableStore | null>(null)

export function useContactsTableContext() {
  const context = useContext(ContactsTableContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ContactsTableStoreProvider'
    )
  }
  return context
}
