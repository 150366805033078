import classNames from 'classnames'
import { useState } from 'react'
import { Icon, IDropdownItem, Typography } from 'shared/ui'
import { Select } from 'shared/ui/Select/Select'
import styles from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/styles.module.scss'

export type IMultiSelectEditValueProps = {
  typeEdit: 'multiSelect'
  options: IDropdownItem[]
  loadOptions: () => Promise<void>
  value: Array<IDropdownItem> | null
  isUnsaved?: boolean
  onChange: (values: Array<IDropdownItem>) => void
  editValue: Array<IDropdownItem> | null
  editError?: string
}

export const MultiSelectEditValue = ({
  options,
  onChange,
  editValue,
  isUnsaved,
  loadOptions,
  editError,
}: IMultiSelectEditValueProps) => {
  const [selectRef, setSelectRef] = useState<HTMLDivElement | null>(null)

  const onSelectItems = (items: IDropdownItem[]) => {
    onChange(items)
  }

  const onToggle = async () => {
    await loadOptions()
  }

  return (
    <Select
      type={'multi'}
      text={'Select'}
      selectedItems={editValue || []}
      onSelect={onSelectItems}
      items={options || []}
      dropdownProps={{
        ariaLabel: 'MultiSelectEditValue',
        width: selectRef?.clientWidth,
        onToggle,
        triggerComponent: (open) => (
          <div
            className={classNames(
              styles.value,
              styles.select,
              open && styles.open,
              isUnsaved && styles.isUnsaved,
              editError && styles.editError
            )}
            ref={setSelectRef}
          >
            {editValue ? (
              <Typography variant={'body-md-regular'} ariaLabel={'Edit'}>
                {editValue.map((value) => value.label).join(', ')}
              </Typography>
            ) : (
              <Typography
                variant={'body-md-regular'}
                color={'var(--content-neutral-primary)'}
                ariaLabel={'Select'}
              >
                Select
              </Typography>
            )}
            <Icon icon={'chevronDown'} />
          </div>
        ),
      }}
    />
  )
}
