import { Attachment } from 'entities/Attachment/model/Attachment'

export const getAttachmentFlags = (attachment: Attachment) => {
  const isAudio = attachment?.type === 'audio'
  const isVideo = attachment?.type === 'video'
  const isImage =
    attachment?.type === 'image' || attachment?.type === 'gif' || attachment?.type === 'previewGif'
  const isVCard =
    attachment?.type === 'vcard' ||
    attachment?.extension === 'vcf' ||
    attachment?.content_type === 'text/x-vcard'
  const isFile = attachment?.type === 'attachment' || isVCard
  const isVCardPresentationAvailable = isVCard && !!attachment.vCard
  const isAttachmentPresentationAvailable = !isFile || isVCardPresentationAvailable
  const isAttachmentWithContent = Boolean(attachment.source || attachment.thumbnail)

  return {
    isAudio,
    isVideo,
    isImage,
    isVCard,
    isFile,
    isVCardPresentationAvailable,
    isAttachmentPresentationAvailable,
    isAttachmentWithContent,
  }
}
