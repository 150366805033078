import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { type IContainerHeaderProps, ContainerHeader, Dropdown } from 'shared/ui'
import { type ContactsMenuStore } from 'pages/contacts/ui/ContactsMenu'
import { useDropdownItems } from '../../hooks/useDropdownItems'
import styles from './styles.module.scss'

type IContactsFiltersHeaderProps = {
  contactsMenuStore: ContactsMenuStore
}

export const ContactsFiltersHeader: FC<IContactsFiltersHeaderProps> = observer(
  ({ contactsMenuStore }) => {
    const { pageLayoutStore, activeSegment, isNewSegment, title } = contactsMenuStore
    const noSegment = isNewSegment || activeSegment === null
    const customTitle = noSegment ? (
      title
    ) : (
      <Dropdown
        ariaLabel={'ContactsFiltersHeader'}
        items={activeSegment ? useDropdownItems(activeSegment, contactsMenuStore) : []}
        disableStopPropagation
        triggerComponent={() => (
          <div className={styles.dropdownTriggerComponent}>
            <span className={styles.headerName}>{title}</span>
          </div>
        )}
      />
    )

    const props: IContainerHeaderProps = {
      paddingBottom: 6,
      paddingTop: 24,
      height: 65,
      customTitle: customTitle,
      titleIconProps: pageLayoutStore?.titleIconProps,
      actionIconTooltipProps: pageLayoutStore?.headerMenuTooltipIconProps,
      titleIconAction: pageLayoutStore?.headerMenuIconAction,
    }

    return <ContainerHeader {...props} />
  }
)
