import { PropsWithChildren } from 'react'
import { Icon, IIconsVector } from 'shared/ui'
import styles from './styles.module.scss'

export interface IPresetDropdownListProps {
  title?: string
}

export const PresetDropdownList = ({
  title,
  children,
}: PropsWithChildren<IPresetDropdownListProps>) => (
  <div className={styles.dropdown}>
    {!!title && <header className={styles.dropdownTitle}>{title}</header>}
    {children}
  </div>
)

export interface IPresetDropdownItemProps {
  icon: IIconsVector
  name: string
  description: string
  selected: boolean
  onClick: () => void
}

export const PresetDropdownItem = ({
  icon,
  name,
  description,
  selected,
  onClick,
}: IPresetDropdownItemProps) => (
  <section className={styles.dropdownItem} onClick={onClick}>
    <div className={styles.dropdownItemIcon}>
      <Icon icon={icon} />
    </div>
    <div className={styles.dropdownItemContent}>
      <header className={styles.dropdownItemContentTitle}>{name}</header>
      <p className={styles.dropdownItemContentDescription}>{description}</p>
    </div>
    {selected && (
      <span className={styles.dropdownItemActive}>
        <Icon icon='check1' />
      </span>
    )}
  </section>
)
