import { Box } from '@mui/material'
import React from 'react'
import { IconButton, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type NumberCounterProps = {
  onIncrement?: () => void
  onDecrement?: () => void
  value: number
  label: string
  optionalLabel?: string
  disabled?: boolean
}

export const NumberCounter = ({
  onIncrement,
  onDecrement,
  value,
  label,
  optionalLabel,
  disabled,
}: NumberCounterProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid var(--gray-20)',
        borderColor: disabled ? 'var(--border-neutral-default)' : 'var(--gray-20)',
        borderRadius: '6px',
        padding: '7px',
        pointerEvents: disabled ? 'none' : undefined,
      }}
    >
      <Typography
        variant={'text-input-field'}
        color={disabled ? 'var(--content-primary-disabled)' : 'var(--content-primary-primary)'}
        ariaLabel={'label'}
      >
        {label}
        <span className={styles.optionalLabel}> {optionalLabel}</span>
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          variant='icon'
          color='tertiary'
          size='small'
          icon='dash'
          onClick={onDecrement}
          ariaLabel='NumberCounter_decrement'
        />

        <Typography
          variant={'text-input-field'}
          color={disabled ? 'var(--content-primary-disabled)' : 'var(--gray-100)'}
          className={styles.text}
          ariaLabel={'value'}
        >
          {value}
        </Typography>

        <IconButton
          variant='icon'
          color='tertiary'
          size='small'
          icon='add'
          onClick={onIncrement}
          ariaLabel='NumberCounter_increment'
        />
      </Box>
    </Box>
  )
}
