// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oUvoUcjozp5g04wN7NgY{width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.KGp_RfYO3HXZAClrobow{color:var(--content-neutral-primary);margin-bottom:16px}.l_Kwbm1hYXfEfp6nRO6a{display:flex;gap:8px;margin-top:20px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/EmptryConversationsState/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEF,sBACE,oCAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,OAAA,CACA,eAAA","sourcesContent":[".wrap{\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n.icon{\n  color: var(--content-neutral-primary);\n  margin-bottom: 16px;\n}\n.actions{\n  display: flex;\n  gap: 8px;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "oUvoUcjozp5g04wN7NgY",
	"icon": "KGp_RfYO3HXZAClrobow",
	"actions": "l_Kwbm1hYXfEfp6nRO6a"
};
export default ___CSS_LOADER_EXPORT___;
