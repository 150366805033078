// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dDpvep_MkergA0LTssrg{position:relative;display:flex;flex-grow:1;margin-left:16px}.OkfQ5eIk2PReuQay4hAD a{color:var(--content-brand-primary);text-decoration:none}.OkfQ5eIk2PReuQay4hAD a:hover{color:var(--content-brand-secondary);text-decoration:underline}.SMWSImmC_s88cWglQf55{display:flex;align-items:center;justify-content:space-between;gap:12px}.mQTFGRITblaU7x6AhOZs{display:flex;align-items:center;gap:12px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastDetail/BroadcastDetailOneTime/ui/BroadcastContactStatisticsTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,gBAAA,CAGA,wBACE,kCAAA,CACA,oBAAA,CACA,8BACE,oCAAA,CACA,yBAAA,CAKN,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,QAAA,CAEA,sBACI,YAAA,CACA,kBAAA,CACA,QAAA","sourcesContent":[".wrapTable{\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  margin-left: 16px;\n}\n.reason{\n  a{\n    color: var(--content-brand-primary);\n    text-decoration: none;\n    &:hover{\n      color: var(--content-brand-secondary);\n      text-decoration: underline;\n    }\n  }\n}\n\n.contactColumn {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 12px;\n\n  &Name {\n      display: flex;\n      align-items: center;\n      gap: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTable": "dDpvep_MkergA0LTssrg",
	"reason": "OkfQ5eIk2PReuQay4hAD",
	"contactColumn": "SMWSImmC_s88cWglQf55",
	"contactColumnName": "mQTFGRITblaU7x6AhOZs"
};
export default ___CSS_LOADER_EXPORT___;
