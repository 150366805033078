import React from 'react'
import classnames from 'classnames'
import { IconButton } from 'shared/ui/IconButton/ui/IconButton'
import type { ITooltipProps } from 'shared/ui/Tooltip'

import styles from '../styles.module.scss'

type IIconEmailButtonProps = {
  value: string | number | undefined
  showTooltip?: boolean
  show?: boolean
  onClose?: () => void
  onOpen?: () => void
}

export const IconEmailButton: React.FC<IIconEmailButtonProps> = ({
  value,
  showTooltip = false,
  show = true,
  onOpen,
  onClose,
}) => {
  if (value === '-') return null

  const handleClose = () => {
    onClose && onClose()
  }

  const handleOpen = () => {
    onOpen && onOpen()
  }

  const tooltipProps: ITooltipProps = {
    label: 'Email',
    placement: 'top',
    disableInteractive: true,
    onClose: handleClose,
    onOpen: handleOpen,
  }

  return (
    <div
      className={classnames(styles.rightIcon, {
        [styles.hidden]: !show,
      })}
    >
      <a href={`mailto:${value}`}>
        <IconButton
          icon={'email'}
          variant={'icon'}
          color={'secondary'}
          size={'small'}
          fontSize={16}
          tooltipProps={showTooltip ? tooltipProps : undefined}
          ariaLabel='IconEmailButton'
        />
      </a>
    </div>
  )
}
