// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hKF_Y5IRUyL4N3MFl6SF{display:flex;justify-content:space-between}.yBO3rfRi0tjKJXc6W0bG{min-height:32px;flex:0 0 125px;color:var(--content-primary-tertiary)}.QuoQtZrtAbJcgaxbBJg3{width:100%}", "",{"version":3,"sources":["webpack://./src/shared/ui/FieldDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,eAAA,CACA,cAAA,CACA,qCAAA,CAGF,sBACE,UAAA","sourcesContent":[".wrap {\n  display: flex;\n  justify-content: space-between;\n}\n\n.label {\n  min-height: 32px;\n  flex: 0 0 125px;\n  color: var(--content-primary-tertiary);\n}\n\n.dropdown {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "hKF_Y5IRUyL4N3MFl6SF",
	"label": "yBO3rfRi0tjKJXc6W0bG",
	"dropdown": "QuoQtZrtAbJcgaxbBJg3"
};
export default ___CSS_LOADER_EXPORT___;
