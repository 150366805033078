import { observer } from 'mobx-react-lite'
import { useTriggerViewContext } from 'widgets/TriggerView/context/triggerViewContext'
import { MessagePreview } from 'widgets/MessagePreview'

export const TriggerViewPreview = observer(() => {
  const {
    type,
    triggerSMSStore: { messageFieldStore },
    ringlessMessageFieldStore: { attachment },
  } = useTriggerViewContext()

  return <MessagePreview type={type} messageStore={messageFieldStore} attachment={attachment} />
})
