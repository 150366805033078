import { makeAutoObservable } from 'mobx'
import { IResponseTag } from 'entities/Tags/api/types'
import { Tag } from 'entities/Tags/model/Tag'

class TagsStore {
  constructor() {
    makeAutoObservable(this)
  }

  tagsMap: Map<number, Tag> = new Map()

  getItem = (id: number) => {
    return this.tagsMap.get(id)
  }

  addItems = (tags: IResponseTag[]) => {
    tags.forEach((item) => {
      this.addItem(item)
    })

    return tags.reduce<Tag[]>((state, item) => {
      const tagId = item.id
      const tag = this.getItem(tagId)

      return tag ? [...state, tag] : [...state]
    }, [])
  }

  addItem = (item: IResponseTag) => {
    const id = item.id
    const tag = this.tagsMap.get(id)

    if (tag) {
      tag.syncOrigin({ ...tag.origin, ...item })
    } else {
      const newTag = new Tag(item)
      this.tagsMap.set(newTag.id, newTag)
    }
  }
}

export const tagsStore = new TagsStore()
