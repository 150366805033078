import { secondsToTime } from 'shared/lib/secondsToTime'
import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

export const TimeMedia = ({ currentTime, duration }: { currentTime: number; duration: number }) => {
  return (
    <Typography variant={'body-md-regular'} ellipsis className={styles.time} ariaLabel={'time'}>
      {secondsToTime(currentTime)} / {secondsToTime(duration)}
    </Typography>
  )
}
