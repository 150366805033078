// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wiHD44f6jURmnjPD3V3U{margin:9px 21px 0px}.wiHD44f6jURmnjPD3V3U .KrH0CFj2I6QiEKkhMLhw{margin-right:10px}.wiHD44f6jURmnjPD3V3U .H50bqci_vPmr5k5ZHf3q input{height:16px;font-size:16px}.wiHD44f6jURmnjPD3V3U .H50bqci_vPmr5k5ZHf3q input ::placeholder{color:var(--content-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/customPrices/pages/CustomPrices/ui/CustomPricesSearch/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAEA,4CACE,iBAAA,CAIA,kDACE,WAAA,CACA,cAAA,CAEA,gEACE,oCAAA","sourcesContent":[".wrap {\n  margin: 9px 21px 0px;\n\n  .icon {\n    margin-right: 10px;\n  }\n\n  .search {\n    & input {\n      height: 16px;\n      font-size: 16px;\n    \n      ::placeholder {\n        color: var(--content-neutral-primary);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wiHD44f6jURmnjPD3V3U",
	"icon": "KrH0CFj2I6QiEKkhMLhw",
	"search": "H50bqci_vPmr5k5ZHf3q"
};
export default ___CSS_LOADER_EXPORT___;
