export const tagReplacer = (value: string) => {
  if (!value) {
    return ''
  }
  const noteValue = value.match(/'.*?'/g)?.[0].slice(1, -1)

  if (!noteValue) return value

  return `<span class='textarea-tag' data-readonly data-tag='${noteValue}' data-key='textarea-tag'>${noteValue}</span>`
}
