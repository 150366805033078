import { makeObservable, observable, runInAction, action } from 'mobx'
import { nanoid } from 'nanoid'
import { showToast, toastStore } from 'shared/ui'
import { Base } from 'models'
import { IResponseUser } from 'entities/Users/api/types'
import { InboxesApi, inboxesStore } from 'entities/Inbox'
import { MessagesApi } from 'entities/Message'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { Message } from 'entities/Message/model/Message'
import { IUser } from 'entities/Users'

export class MessagePendingMention extends Base {
  id: number
  is_got_it: boolean
  loading: boolean

  constructor(item: IResponseUser) {
    super()

    this.id = item.id
    this.is_got_it = item.is_got_it || false
    this.loading = false

    makeObservable(this, {
      loading: observable,
      is_got_it: observable,
      handleActivateUndo: action,
      handleActivate: action,
      handleCancel: action,
      handleGotIt: action,
    })
  }

  handleActivateUndo = async (converstion: Conversation, message: Message, user: IUser) => {
    const inbox = await inboxesStore.getById(converstion.inbox_id)
    const id = setTimeout(() => {
      this.handleActivate(converstion, message, user)
    }, 5000)

    const nanoId = nanoid()

    showToast({
      id: nanoId,
      title: `${user.name} added to ${inbox?.name}`,
      type: 'info',
      action: {
        text: 'Undo',
        onAction: () => {
          clearTimeout(id)
          toastStore.remove(nanoId)
        },
      },
    })
  }

  handleActivate = async (converstion: Conversation, message: Message, user: IUser) => {
    try {
      runInAction(() => {
        this.loading = true
      })

      await Promise.all([
        InboxesApi.updateTeamsByIdMembers(converstion.inbox_id, {
          members: [
            {
              id: user.id,
            },
          ],
        }),
        MessagesApi.updateMessagesByIdMentionsByIdActivate(message.id, user.id),
      ])

      message.pending_mentions_map.delete(user.id)
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  handleCancel = async (message: Message, user: IUser) => {
    try {
      runInAction(() => {
        this.loading = true
      })

      await MessagesApi.updateMessagesByIdMentionsByIdCancel(message.id, user.id)

      message.pending_mentions_map.delete(user.id)
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  handleGotIt = async (message: Message, user: IUser) => {
    try {
      runInAction(() => {
        this.loading = true
      })

      await MessagesApi.updateMessagesByIdMentionsByIdGotIt(message.id, user.id)

      runInAction(() => {
        this.is_got_it = true
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
}
