import { makeAutoObservable, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { inboxesStore } from 'entities/Inbox'
import { IUser, usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { filtersStore } from 'features/DropdownFilter'
import { IConversationAssignModalStoreConfig } from 'widgets/ConversationAssignModal/store/types'
import { type ConversationListStore } from 'widgets/ConversationList'
import { ConversationAssignModalContent } from 'widgets/ConversationAssignModal/ui/conversationAssignModalContent/ConversationAssignModalContent'
import { ConversationAssignModalActions } from 'widgets/ConversationAssignModal/ui/conversationAssignModalActions/ConversationAssignModalActions'

const SELECTED_VIEW_LIMIT = 3

export class ConversationAssignModalStore {
  private _modalId = 'conversation_assign_modal'
  private _search = ''
  private _isOpenDropdown = false
  private _selected: Map<number, IUser> = new Map()
  private _itemsMap: Map<number, IUser> = new Map()
  private _conversationListStore: ConversationListStore | null = null

  constructor() {
    makeAutoObservable(this)

    this.reactionInboxId()
  }

  setConfig = (config: IConversationAssignModalStoreConfig) => {
    this._conversationListStore = config.conversationListStore
  }

  get isSearchEmpty() {
    return this._search === ''
  }

  get list() {
    return Array.from(this._itemsMap.values())
  }

  get hasSelected() {
    return this.selectedList.length !== 0
  }

  get hasAvailable() {
    return this.availableList.length !== 0
  }

  get availableList() {
    return this.filteredList.filter((item) => !this._selected.has(item.id))
  }

  get selectedListView() {
    return this._isOpenDropdown ? this.selectedList : this.limitSelectedItems
  }

  get selectedList() {
    return Array.from(this._selected.values())
  }

  get hasMoreSelectedItems() {
    return this.selectedList.length > SELECTED_VIEW_LIMIT
  }

  get limitSelectedItems() {
    return this.selectedList.slice(0, SELECTED_VIEW_LIMIT)
  }

  get moreSelectedItemsCount() {
    return this.selectedList.length - SELECTED_VIEW_LIMIT
  }

  get selectedListIds() {
    return this.selectedList.map((item) => item.id)
  }

  get isEmptyFilteredList() {
    return this.filteredListCount === 0
  }

  get filteredListCount() {
    return this.filteredList.length
  }

  get filteredList() {
    if (this.isSearchEmpty) {
      return this.list
    }

    return this.list.filter((member) =>
      member.name.toLowerCase().includes(this._search.toLowerCase())
    )
  }

  toggleDropdown = (status: boolean) => {
    this._isOpenDropdown = status
  }

  closeDropdown = () => {
    this._isOpenDropdown = false
  }

  openDropdown = () => {
    this._isOpenDropdown = true
  }

  handleDeleteItem = (id: number) => {
    this._selected.delete(id)
  }

  handleAddItem = (item: IUser) => {
    this._selected.set(item.id, item)
  }

  openModal = () => {
    this.refresh()
    modalStore.addModal({
      id: this._modalId,
      title: 'Round robin assignment',
      width: 480,
      ModalContent: ConversationAssignModalContent,
      ModalActions: ConversationAssignModalActions,
      ModalContentProps: {
        store: this,
      },
      paddingContent: '0 22px 14px 24px',
      onClose: this.closeModal,
    })
  }

  handleRemoveLastSelected = () => {
    const lastItem = Array.from(this._selected.values()).pop()
    lastItem?.id && this._selected.delete(lastItem.id)
  }

  closeModal = () => {
    this.reset()
    modalStore.removeModal(this._modalId)
  }

  onSearch = (value: string) => {
    this._search = value
  }

  onReassignBulk = async () => {
    if (!conversationStore.currentItem) return
    if (!this._conversationListStore) return

    await conversationStore.reassignBulk(
      this._conversationListStore.selectedListIds,
      this.selectedListIds,
      conversationStore.currentItem.inbox_id,
      this._conversationListStore.isSelectedAll,
      filtersStore.filter?.key
    )
    this._conversationListStore.handleResetSelect()
  }

  reset = () => {
    this._search = ''
    this._isOpenDropdown = false
    this._selected.clear()
    this._itemsMap.clear()
  }

  refresh = () => {
    this.reset()

    const conversation = conversationStore.currentItem
    if (!conversation) return null

    const inbox = inboxesStore.getItem(conversation.inbox_id)
    if (!inbox) return null

    if (inbox.type === 'inbox') {
      inbox.memberIds.forEach((id) => {
        const member = usersStore.getItem(id)
        if (!member || member.isViewOnlyRole) return
        this._itemsMap.set(id, member)
      })
    }
  }

  handleUpdateAssign = async () => {
    modalStore.removeModal(this._modalId)
    const nanoId = nanoid()

    const assignTimeoutId = setTimeout(async () => {
      this.onReassignBulk()
      toastStore.remove(nanoId)
    }, 5000)

    toastStore.add({
      id: nanoId,
      title: 'Assignment complete',
      type: 'info',
      action: {
        text: 'Undo',
        onAction: () => {
          clearTimeout(assignTimeoutId)
          toastStore.remove(nanoId)
        },
      },
    })
  }

  reactionInboxId = () => {
    reaction(
      () => inboxesStore.currentInboxId,
      (value) => {
        if (!value) return
        this.refresh()
      }
    )
  }

  get isOpenDropdown() {
    return this._isOpenDropdown
  }

  get search() {
    return this._search
  }
}
