import { observer } from 'mobx-react-lite'
import React from 'react'
import classNames from 'classnames'
import { Typography } from 'shared/ui'
import { EditVCardContent } from 'entities/VCard/ui/EditVCardContent/EditVCardContent'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { FileIcon } from 'widgets/FileIcon/FileIcon'
import { getAttachmentFlags } from 'widgets/MediaLibrary/utils'
import styles from './styles.module.scss'

export const PresentationDoc = observer(() => {
  const { currentAttachment, setDisabledOverlayClick, onClose } = presentationStore

  const onMouseEnter = () => {
    setDisabledOverlayClick && setDisabledOverlayClick(true)
  }
  const onMouseLeave = () => {
    setDisabledOverlayClick && setDisabledOverlayClick(false)
  }

  if (!currentAttachment) return null
  const { isVCard, isVCardPresentationAvailable, isFile } = getAttachmentFlags(currentAttachment)

  if (isFile) {
    if (isVCard && isVCardPresentationAvailable) {
      return (
        <div
          className={classNames(styles.wrap, styles.vcard)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div className={styles.titleWrapper}>
            <div className={styles.title}>
              <Typography variant={'body-xs-regular'} ariaLabel={'title'}>
                vCard
              </Typography>
            </div>
          </div>
          <EditVCardContent presentationMode onClose={onClose} />
        </div>
      )
    }

    return (
      <div
        className={classNames(styles.wrap)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {isVCard && !isVCardPresentationAvailable && (
          <FileIcon isVCard={isVCard} fontSize={20} insideBox={true} boxRounded={true} />
        )}
        {!isVCard && (
          <FileIcon
            docType={currentAttachment?.extension || currentAttachment?.content_type}
            fontSize={32}
            insideBox={true}
            boxRounded={true}
          />
        )}
      </div>
    )
  }

  return null
})
