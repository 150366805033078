import { TestContent } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/ui/FooterContent/TestContent'
import styles from './styles.module.scss'
import { Header } from './Header/Header'
import { BodyContent } from './BodyContent/BodyContent'

export const BroadcastDetailModalContent = () => {
  return (
    <div className={styles.wrap}>
      <Header />
      <BodyContent />
      <div className={styles.footer}>
        <TestContent />
      </div>
    </div>
  )
}
