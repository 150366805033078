import { uiStore } from 'shared/store/uiStore'
import { Button, Typography } from 'shared/ui'
import { AuthRoutesEnum } from 'entities/Auth'
import styles from './styles.module.scss'

export const SignUpFormBottom = () => {
  const onClick = () => {
    uiStore.changeRoute({
      path: `/${AuthRoutesEnum.login}`,
    })
  }

  return (
    <div className={styles.wrap}>
      <Typography variant={'body-sm-medium'} ariaLabel={'signInLink'}>
        Already have a Salesmsg account?
        <Button onClick={onClick} typeBtn={'link'} text={'Sign in'} />
      </Typography>
    </div>
  )
}
