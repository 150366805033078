import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu } from 'shared/ui'
import { MenuGroup } from 'shared/ui/Menu/ui/MenuGroup'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { TrialProgress } from 'entities/Subscription/ui/TrialProgress'
import { MediaLibraryStore } from 'widgets/MediaLibrary'
import {
  type IMediaLibraryStoreProps,
  MediaLibrarySelectMode,
  MediaLibraryVariant,
} from 'widgets/MediaLibrary/types'
import { SettingsRoutes } from 'pages/settings/route/type'
import { KnowledgeBaseRoutes } from 'pages/settings/pages/knowledgeBase'
import {
  getMenuItemsForRole,
  workspaceRolesMap,
  productRolesMap,
  personalRolesMap,
  getWorkspaceAllItems,
  getProductAllItems,
  getPersonalAllItems,
  getDeveloperItems,
  developerRolesMap,
  canceledWorkspaceRolesMap,
  canceledProductRolesMap,
  canceledPersonalRolesMap,
  canceledDeveloperRolesMap,
} from './menuItemLists'
import styles from './styles.module.scss'

export const SettingsMenu = observer(() => {
  const { isCanceled } = subscriptionStore
  const { rag, salesmsg_design_v2_new_sidebar_menu, media_library_v2 } = featureFlagsStore

  const role = usersStore.user?.role

  const canManageMembers = userPermissionsStore.getItem('can_manage_members')
  const canManageBillingSettings = userPermissionsStore.getItem('can_manage_billing_settings')
  const canManageIntegrations = userPermissionsStore.getItem('can_manage_integrations')
  const canAddSubscription = isCanceled && canManageBillingSettings

  useEffect(() => {
    if (window.RouterVue) {
      const unsubscribe = window.RouterVue.afterEach((to) => {
        uiStore.changeRoute({
          path: to.path,
          options: {
            state: to.params,
          },
        })
      })

      return () => {
        if (unsubscribe) {
          unsubscribe()
        }
      }
    }
  }, [])

  const mediaLibraryConfiguration: IMediaLibraryStoreProps = {
    mediaLibraryVariant: MediaLibraryVariant.Default,
    onAddAttachment: () => {},
    selectMode: MediaLibrarySelectMode.Multiple,
  }

  const [mediaLibraryStore] = useState(() => new MediaLibraryStore(mediaLibraryConfiguration))

  const workspaceAllItems = getWorkspaceAllItems({
    canManageMembers,
    canManageBillingSettings,
    canAddSubscription,
    canManageIntegrations,
  })
  const productAllItems = getProductAllItems(mediaLibraryStore).filter((item) => {
    if (item.type === 'nav' && item.to === KnowledgeBaseRoutes.root)
      return rag && !salesmsg_design_v2_new_sidebar_menu
    if (item.key === 'media_library') return media_library_v2

    return true
  })

  const personalAllItems = getPersonalAllItems()
  const developerAllItems = getDeveloperItems()

  const actualWorkspaceRolesMap = isCanceled ? canceledWorkspaceRolesMap : workspaceRolesMap
  const actualProductRolesMap = isCanceled ? canceledProductRolesMap : productRolesMap
  const actualPersonalRolesMap = isCanceled ? canceledPersonalRolesMap : personalRolesMap
  const actualDeveloperRolesMap = isCanceled ? canceledDeveloperRolesMap : developerRolesMap

  const workspaceItems = role
    ? getMenuItemsForRole(role, workspaceAllItems, actualWorkspaceRolesMap)
    : []
  const productItems = role ? getMenuItemsForRole(role, productAllItems, actualProductRolesMap) : []
  const personalItems = role
    ? getMenuItemsForRole(role, personalAllItems, actualPersonalRolesMap)
    : []
  const developerItems = role
    ? getMenuItemsForRole(role, developerAllItems, actualDeveloperRolesMap)
    : []

  return (
    <Menu
      title='Settings'
      menuContent={
        <div>
          <MenuGroup title='Workspace' items={workspaceItems} className={styles.menuGroup} />
          <MenuGroup title='Product' items={productItems} className={styles.menuGroup} />
          <MenuGroup title='Personal' items={personalItems} className={styles.menuGroup} />
          <MenuGroup
            title='Developer'
            items={developerItems}
            className={styles.menuGroup}
            initOpen={false}
            withExpand
          />
        </div>
      }
      bottomNode={<TrialProgress />}
    />
  )
})
