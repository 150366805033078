import { ReactNode, FC } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

export type ITooltipDefaultProps = {
  label?: ReactNode
  color?: 'white' | 'red' | 'yellow'
  icons?: string[]
  breakWord?: boolean
}

export const TooltipDefault: FC<ITooltipDefaultProps> = ({
  label,
  icons = [],
  color,
  breakWord = false,
}) => {
  const hasIcons = Boolean(icons.length)

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.breakWord]: breakWord,
      })}
    >
      <div className={classNames(styles.label, color && styles[color])}>{label}</div>

      {hasIcons && (
        <div className={styles.icons}>
          {icons.map((icon, index) => {
            return <div key={index}>{icon}</div>
          })}
        </div>
      )}
    </div>
  )
}
