import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon, IIconsVector, Typography } from 'shared/ui'
import { AudioPlayer } from 'shared/ui/AudioPlayer'
import { Message } from 'entities/Message/model/Message'
import { ParticipantsDropDown } from 'widgets/MessageCard/ui/CallContent/CallConference/ParticipantsDropDown/ParticipantsDropDown'
import { MessageCardType } from 'widgets/MessageCard/ui/types'
import { ConversationMessageCardItem } from 'widgets/MessageCard/ui/ConversationMessageCardItem/ConversationMessageCardItem'
import { DateContent } from 'widgets/MessageCard/ui/DateContent/DateContent'
import styles from './styles.module.scss'

type ICallConferenceProps = {
  message: Message
  iconVector: IIconsVector | null
}

export const CallConference: React.FC<ICallConferenceProps> = observer(
  ({ message, iconVector }) => {
    const { isOutbound, record, transcriptions, id } = message
    const title = isOutbound ? 'Outgoing conference call' : 'Incoming conference call'

    return (
      <>
        <ConversationMessageCardItem message={message} type={MessageCardType.Conference}>
          <div
            className={classnames(styles.wrapCallContent, {
              [styles.conversationsHistoryView]: message.isConversationsHistory,
            })}
          >
            <div className={styles.wrap}>
              <div className={styles.box}>
                {iconVector && (
                  <div
                    className={classnames(
                      styles.wrapIcon,
                      iconVector === 'missed' && styles.missed
                    )}
                  >
                    <Icon icon={iconVector} tertiary={iconVector !== 'missed'} fontSize={20} />
                  </div>
                )}
                <div className={styles.content}>
                  <Typography variant={'body-rg-medium'} ariaLabel={'title'}>
                    {title}
                  </Typography>
                  {
                    <div className={'row4'}>
                      <DateContent message={message} />
                    </div>
                  }
                </div>
              </div>
              <ParticipantsDropDown message={message} />
            </div>

            {record && (
              <AudioPlayer
                url={record.url}
                transcript_status={record.transcript_status}
                is_auto_transcribe={record.is_auto_transcribe}
                id={`${id}${record.id}`}
                transcriptions={transcriptions}
                widthWave={134}
              />
            )}
          </div>
        </ConversationMessageCardItem>
      </>
    )
  }
)
