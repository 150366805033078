import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { SearchTypesEnum } from 'widgets/SearchDropdown'
import { TagElement } from './TagElement/TagElement'
import { TeamElement } from './TeamElement/TeamElement'
import { AssigneeElement } from './AssigneeElement/AssigneeElement'
import { ContactsElement } from './ContactsElement/ContactsElement'
import { DateElement } from './DateElement/DateElement'

type ISearchElementProps = {
  type: SearchTypesEnum
  parentsDropdownId?: string[]
}

export const SelectedElement: FC<ISearchElementProps> = observer(({ type, parentsDropdownId }) => {
  switch (type) {
    case SearchTypesEnum.tags:
      return <TagElement parentsDropdownId={parentsDropdownId} />
    case SearchTypesEnum.teams:
      return <TeamElement parentsDropdownId={parentsDropdownId} />
    case SearchTypesEnum.members:
      return <AssigneeElement parentsDropdownId={parentsDropdownId} />
    case SearchTypesEnum.contacts:
      return <ContactsElement parentsDropdownId={parentsDropdownId} />
    case SearchTypesEnum.date:
      return <DateElement parentsDropdownId={parentsDropdownId} />
    default:
      return null
  }
})
