import {
  IMenuItemDefaultProps,
  MenuItemDefault,
} from 'shared/ui/Menu/MenuItem/MenuItemDefault/MenuItemDefault'
import { IMenuItemNavProps, MenuItemNav } from 'shared/ui/Menu/MenuItem/MenuItemNav/MenuItemNav'

export type IMenuItemProps = IMenuItemNavProps | IMenuItemDefaultProps

export const MenuItem = (props: IMenuItemProps) => {
  if (props.type === 'nav') {
    return <MenuItemNav {...props} />
  }

  return <MenuItemDefault {...props} />
}
