import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { CallPopUpViewDefault } from 'widgets/CallPopUp/ui/CallPopUpViewDefault'
import { CallPopUpViewQueue } from 'widgets/CallPopUp/ui/CallPopUpViewQueue'
import styles from './styles.module.scss'

export const CallPopUpDefaultContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const refWrap = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (refWrap.current) {
      setTimeout(() => {
        store.initRefWrap(refWrap.current)
      })
    }
  }, [refWrap.current])

  return (
    <div className={styles.item}>
      {store.view === 'default' && <CallPopUpViewDefault refWrap={refWrap} />}
      {store.view === 'queue' && <CallPopUpViewQueue refWrap={refWrap} />}
    </div>
  )
})
