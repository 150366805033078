import { Icon, Tooltip, Typography } from 'shared/ui'
import { BroadcastRecurring } from 'entities/Broadcast'
import styles from './styles.module.scss'

type IBroadcastRecurringLabelProps = {
  broadcast: BroadcastRecurring
}
export const BroadcastRecurringLabel = ({ broadcast }: IBroadcastRecurringLabelProps) => {
  if (!broadcast.settings) {
    return null
  }
  return (
    <Tooltip label={broadcast.recurringTitle} placement={'top'}>
      <div className={styles.wrap}>
        <Icon icon={'repeat'} tertiary fontSize={12} />
        <div className={styles.title}>
          <Typography variant={'body-xs-regular'} ariaLabel={'type'}>
            {broadcast.settings.type}
          </Typography>
        </div>
      </div>
    </Tooltip>
  )
}
