import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { Contact } from 'entities/Contacts/model/Contact'
import {
  ContactsOptInModalLocalStore,
  IContactsOptInModalLocalConfigOptionsStore,
  IContactsOptInModalLocalConfigStore,
} from 'widgets/ContactsOptInModal'

export class ContactsOptInModalStore {
  modalsMap: Map<string, ContactsOptInModalLocalStore> = new Map()
  modalActive = ''

  constructor() {
    makeAutoObservable(this)
  }

  addModal = (id: string, config: IContactsOptInModalLocalConfigStore) => {
    this.modalsMap.set(id, new ContactsOptInModalLocalStore(config))
  }

  deleteModal = (id: string) => {
    this.modalsMap.delete(id)
  }

  handleOpen = (contacts: Contact[], options?: IContactsOptInModalLocalConfigOptionsStore) => {
    const id = `contacts_opt_in_modal_${nanoid()}`

    this.modalActive = id

    this.addModal(this.modalActive, {
      contacts: contacts,
      id: id,
      deleteModal: this.deleteModal,
      options: options,
    })
  }

  get active() {
    if (!this.modalActive) return

    return this.modalsMap.get(this.modalActive)
  }
}

export const contactsOptInModalStore = new ContactsOptInModalStore()
