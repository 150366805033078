import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useRef } from 'react'
import { AutomatedAvatar, Icon, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { attachmentStore } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { CallContent } from 'widgets/MessageCard/ui/CallContent/CallContent'
import { ImagesContent } from 'widgets/MessageCard/ui/ImagesContent/ImagesContent'
import { VideoContent } from 'widgets/MessageCard/ui/VideoContent/VideoContent'
import { TextContent } from 'widgets/MessageCard/ui/TextContent/TextContent'
import { OtherAttachmentContentContent } from 'widgets/MessageCard/ui/OtherAttachmentContentContent/OtherAttachmentContentContent'
import { AttachmentContent } from 'widgets/MessageCard/ui/AttachmentContent'
import { AvatarContent } from 'widgets/MessageCard/ui/AvatarContent'
import { useMessageCardContext } from 'widgets/MessageCard/context'
import styles from './styles.module.scss'

export const MessageCardContent = observer(() => {
  const {
    message,
    selectedSearch,
    callContentStore,
    setScrollBottomTrigger,
    avatarInfo,
    isLast,
    hideTeam,
    conversationMessagesStore,
  } = useMessageCardContext()

  const isHideAvatars = conversationMessagesStore?.isHideAvatars
  const refContent = useRef<HTMLDivElement>(null)

  const {
    isOutbound,
    type,
    isHideAvatar,
    isAirCall,
    links,
    isAutomated,
    isConversationsHistory,
    teamName,
  } = message

  const attachments: Attachment[] = []
  links.forEach((link) => {
    const attachment = attachmentStore.previousAttachmentMap.get(link)
    if (attachment) {
      attachments.push(attachment)
    }
  })

  useEffect(() => {
    if (refContent.current) {
      const dates: NodeListOf<HTMLDivElement> = refContent.current.querySelectorAll(
        '[data-name="message-time"]'
      ) // message-time
      dates.forEach((date, index) => {
        if (index !== dates.length - 1) {
          date.setAttribute('style', 'display: none;')
        }
      })
      const messages: NodeListOf<HTMLDivElement> = refContent.current.querySelectorAll(
        '[data-name="message-sender"]'
      ) // message-sender
      messages.forEach((element, index) => {
        if (index !== 0) {
          element.setAttribute('style', 'display: none;')
        }
      })
    }
  }, [attachments, message])

  const content = useMemo(() => {
    if (type === 'call' || isAirCall)
      return (
        <CallContent
          message={message}
          isLast={isLast}
          setScrollBottomTrigger={() => {
            setScrollBottomTrigger?.()
          }}
          callContentStore={callContentStore}
        />
      )

    return (
      <>
        <ImagesContent message={message} />
        <VideoContent message={message} />
        <TextContent message={message} selectedSearch={selectedSearch || ''} />
        <OtherAttachmentContentContent message={message} />
        <AttachmentContent message={message} attachments={attachments} />
      </>
    )
  }, [message, attachments])

  const avatar = useMemo(() => {
    if (isAutomated) {
      return <AutomatedAvatar withTooltip />
    }

    return <AvatarContent avatarInfo={avatarInfo} />
  }, [isAutomated])

  const avatarContent = useMemo(() => {
    if (hideTeam) {
      return null
    }

    if (isConversationsHistory) {
      return (
        <div className={styles.avatarWrap}>
          {!isOutbound && <>{avatar}</>}
          <div className={styles.icon}>
            <Icon icon={'inboxOpen'} />
          </div>
          <Typography variant={'body-sm-regular'} ariaLabel={'name'} className={styles.name}>
            {teamName}
          </Typography>
          {isOutbound && <>{avatar}</>}
        </div>
      )
    }

    if (isHideAvatar) {
      return <div style={{ width: 24 }} />
    }

    return <>{avatar}</>
  }, [isHideAvatar, avatarInfo, isConversationsHistory, hideTeam])

  if (isConversationsHistory) {
    return (
      <div
        id={String(message.client_id)}
        key={message.client_id}
        ref={refContent}
        aria-label={getAriaLabel('MessageCard')}
      >
        <div
          className={classNames(
            styles.wrap,
            styles.unifiedConversationHistory,
            isOutbound && styles.outbox
          )}
        >
          {avatarContent}
          <div className={styles.contentWrap}>{content}</div>
        </div>
      </div>
    )
  }

  return (
    <div
      id={String(message.client_id)}
      key={message.client_id}
      ref={refContent}
      aria-label={getAriaLabel('MessageCard')}
    >
      <div
        className={classNames(
          styles.wrap,
          { [styles.isHideAvatars]: isHideAvatars },
          isOutbound && styles.outbox
        )}
      >
        {!isHideAvatars && !isOutbound && avatarContent}
        <div className={styles.contentWrap}>{content}</div>
        {!isHideAvatars && isOutbound && avatarContent}
      </div>
    </div>
  )
})
