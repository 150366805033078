import type { IColumn } from 'shared/ui'
import { DayjsFormats, formatBytes } from 'shared/lib'
import { uiStore } from 'shared/store/uiStore'
import { EmptyCell } from 'shared/ui/Table'
import type { Attachment } from 'entities/Attachment/model/Attachment'
import { getAttachmentLabel } from 'widgets/MediaLibrary/utils'

export const columns: IColumn<Attachment>[] = [
  {
    field: 'name',
    name: 'Name',
    minWidth: '145px',
    maxWidth: '245px',
    renderRowCell: (attachment) => attachment.name,
  },
  {
    field: 'type',
    name: 'Type',
    fullName: 'Type',
    renderRowCell: (row) => getAttachmentLabel(row),
    maxWidth: 120,
  },
  {
    field: 'size',
    name: 'Size',
    fullName: 'Size',
    renderRowCell: (row) => (row.size ? formatBytes(row.size) : <EmptyCell />),
    maxWidth: 80,
  },
  {
    field: 'uploadedTime',
    name: 'Uploaded time',
    fullName: 'Uploaded time',
    renderRowCell: (row) =>
      row.created_at ? (
        uiStore.dayjs(row.created_at).format(DayjsFormats.fullWithAtDash2)
      ) : (
        <EmptyCell />
      ),
    maxWidth: 180,
  },
]
