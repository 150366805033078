import { Icon, type IIconProps } from 'shared/ui'
import styles from './styles.module.scss'

export const BoxIcon = (props: IIconProps) => {
  return (
    <div className={styles.wrap}>
      <Icon fontSize={14} {...props} />
    </div>
  )
}
