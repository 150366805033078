import { makeAutoObservable } from 'mobx'
import { DeviceItemsEnum } from 'entities/DeviceSettings'
import { IInitRecordStoreProps, IRecordStoreProps, RecordStore } from 'widgets/Record'

const DEFAULT_RECORD_MIME_TYPE = 'audio/webm;codecs=opus'
const RECORD_MIME_TYPE = 'audio/webm'

export class RecordAudioStore {
  recordStore: RecordStore
  constructor(
    props?: Pick<IRecordStoreProps, 'AUDIO_RECORD_LIMIT' | 'isPauseOnLimit' | 'withDelay'>
  ) {
    this.recordStore = new RecordStore({
      deviceItems: [DeviceItemsEnum.audioInput],
      AUDIO_RECORD_LIMIT: props?.AUDIO_RECORD_LIMIT,
      DEFAULT_RECORD_MIME_TYPE,
      RECORD_MIME_TYPE,
      permissions: ['microphone'],
      isAudio: true,
      isPauseOnLimit: props?.isPauseOnLimit,
      withDelay: props?.withDelay,
    })
    makeAutoObservable(this)
  }

  onUnmount = () => {
    this.recordStore.deviceSettingsStore.removeStream()
    this.recordStore.onRestart()
  }

  init = async (
    props: Pick<
      IInitRecordStoreProps,
      'onAddRecord' | 'handleFinish' | 'handleClose' | 'onAllow' | 'handleCancel'
    >
  ) => {
    try {
      await this.recordStore.init({
        rejectInfo: {
          title: 'Microphone access required',
          desc: 'To enable recording, please allow Salesmsg to access your microphone',
        },
        ...props,
      })
    } catch (e) {
      console.error(e)
    }
  }
}
