import React, { useState, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { dateFromNowForConversations, dateDiff } from 'shared/lib/dayjs'
import { Icon } from 'shared/ui/Icon'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'

export enum EnumTimeColors {
  blue = 'blue',
  yellow = 'yellow',
  red = 'red',
}

export enum EnumTimeStatus {
  Unread = 'unread',
  Read = 'read',
  Pending = 'pending',
}

export enum EnumTimeMode {
  Default = 'default',
  Live = 'live',
}

export type ITimeProps = {
  date: string | number
  initial_date?: string | number
  status?: EnumTimeStatus
  position?: 'relative' | 'absolute'
  mode?: EnumTimeMode
  color_coding_enabled?: boolean
  is_need_response?: boolean
  is_show_initial_date?: boolean
}

export const Time: React.FC<ITimeProps> = ({
  date = new Date().valueOf(),
  initial_date,
  position = 'relative',
  status = EnumTimeStatus.Read,
  mode = EnumTimeMode.Default,
  color_coding_enabled,
  is_need_response,
  is_show_initial_date,
}) => {
  const [fromNow, setFromNow] = useState(dateFromNowForConversations(date))
  const [seconds, setSeconds] = useState(dateDiff(date))

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>

    if (mode === EnumTimeMode.Live) {
      setFromNow(dateFromNowForConversations(date))
      setSeconds(dateDiff(date))

      interval = setInterval(() => {
        setFromNow(dateFromNowForConversations(date))
        setSeconds(dateDiff(date))
      }, 1000)
    } else {
      setFromNow(dateFromNowForConversations(date))
      setSeconds(dateDiff(date))
    }

    return () => {
      clearInterval(interval)
    }
  }, [date, mode])

  const color = useMemo(() => {
    if ((!color_coding_enabled || !is_need_response) && status !== EnumTimeStatus.Unread) {
      return ''
    }

    const maxBlueTagDiffSeconds = 8 * 60 * 60 // max 8h for a yellow tag
    const maxYellowTagDiffSeconds = 24 * 60 * 60 // max 24h for a yellow tag

    if (Math.abs(seconds) < maxBlueTagDiffSeconds) return EnumTimeColors.blue
    if (Math.abs(seconds) < maxYellowTagDiffSeconds) return EnumTimeColors.yellow

    return EnumTimeColors.red
  }, [seconds, color_coding_enabled, is_need_response, status])

  return (
    <div
      className={classnames(styles.wrap, styles[position || 'relative'], styles[status], {
        [styles.colorCoding]: color_coding_enabled,
        [styles.needResponse]: is_need_response,
        [styles[color]]: color,
      })}
      data-name={'time'}
    >
      <Typography className={styles.tag} ariaLabel={'time'} variant={'body-sm-regular'}>
        {is_show_initial_date ? initial_date : fromNow}
      </Typography>

      {status === EnumTimeStatus.Unread && (
        <div className={styles.unreadEllipse}>
          <Icon icon={'ellipse_6'} fontSize={6} />
        </div>
      )}
    </div>
  )
}
