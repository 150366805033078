import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import { cloneDeep } from 'lodash'
import {
  ICallHistory,
  IParamsGetCallHistory,
  IParamsGetCallHistoryStatistic,
  IResponseCallHistory,
} from 'entities/CallHistory/api/types'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { CallHistoryApi } from 'entities/CallHistory/api/callHistory'
import { inboxesStore } from 'entities/Inbox'
import { IReadMessage } from 'entities/Conversation/api/types'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { IResponseEventInboxCalling } from 'entities/Inbox/api/types'
import { filtersStore } from 'widgets/CallHistoryDropdownFilter'
import { CallHistoryFiltersListStore } from 'widgets/CallHistoryFiltersList'
import { CallHistoryTableStore } from 'widgets/CallHistoryTable'
import { CallModalStore } from 'widgets/CallModal'

export class CallHistoryStore {
  callHistoryFiltersListStore
  callHistoryTableStore

  constructor(private _callModalStore: CallModalStore) {
    makeAutoObservable(this)
    this.callHistoryFiltersListStore = new CallHistoryFiltersListStore(this.loadCallHistory)
    this.callHistoryTableStore = new CallHistoryTableStore(this)
    reaction(() => this.paramsGetItems, this.loadCallHistory, {
      delay: 500,
    })

    reaction(() => this.filtersList, this.reset)
    reaction(() => this.group, this.reset)
    reaction(() => this.eventFilters, this.sendLog)
  }

  page = 1
  limit = 50
  total = 0
  search: string | null = null
  loading = true
  loadingSearch = false
  filter = []
  itemsMap: Map<number, CallHistory> = new Map()
  cancelTokenSource: CancelTokenSource | null = null

  get callModalStore() {
    return this._callModalStore
  }

  get isLoading() {
    return this.loadingSearch || this.loading
  }

  get isLoadingStatistics() {
    return this.callHistoryFiltersListStore.loading
  }

  get isEmptySearchResults() {
    return (
      (Boolean(this.search) ||
        Boolean(this.callHistoryFiltersListStore.filtersList.length) ||
        this.group !== 'open') &&
      !this.items.length &&
      !this.loadingSearch &&
      !this.isLoadingStatistics
    )
  }

  get isEmptyState() {
    return (
      !this.loading &&
      !this.isLoadingStatistics &&
      !this.itemsMap.size &&
      !this.search &&
      !Boolean(this.callHistoryFiltersListStore.filtersList.length)
    )
  }

  get items() {
    return Array.from(this.itemsMap.values())
  }

  get filtersList() {
    return this.callHistoryFiltersListStore.filtersList
  }

  get group() {
    return filtersStore.filter?.key
  }

  get groupLabel() {
    return filtersStore.filter?.label
  }

  get paramsGetItems(): IParamsGetCallHistory {
    return {
      page: this.page,
      search: this.search || null,
      limit: this.limit,
      team_id: inboxesStore.currentInboxTypeId,
      team_type: inboxesStore.currentInboxType || 3,
      group: this.group === 'all' ? null : this.group,
      filtersList: this.filtersList,
      sorting: [this.callHistoryTableStore.getSortingParams()],
    }
  }

  get eventFilters() {
    return {
      outcome: this.callHistoryFiltersListStore.outcome,
      conversation_type: this.group,
    }
  }

  setData = ({ data, meta }: IResponseCallHistory) => {
    this.itemsMap.clear()
    this.setItems(data)
    this.total = meta.total
  }

  setItems = (items: ICallHistory[]) => {
    items.forEach((item) => this.setItem(item))
  }

  setItem = (item: ICallHistory) => {
    this.itemsMap.set(item.message.id, new CallHistory(item))
  }

  updateItem = (item: CallHistory) => {
    this.itemsMap.set(item.message.id, item)
  }

  getItem = (id: number) => {
    return this.itemsMap.get(id)
  }

  updateInboxCalling = (data: IResponseEventInboxCalling) => {
    this.items.forEach((item) => {
      if (item.inbox.id === data.team_id) {
        item.updateInboxCalling(data.number)
      }
    })
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  loadCallHistory = async () => {
    if (!this.callHistoryFiltersListStore.hasCalls) {
      this.reset()

      return
    }

    try {
      this.initCancelTokenSource()
      runInAction(() => {
        this.loading = true
      })
      const { data } = await CallHistoryApi.getCallHistory(this.paramsGetItems, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })
      this.setData(data)
      runInAction(() => {
        this.loading = false
        if (this.loadingSearch) this.loadingSearch = false
      })
    } catch (e) {
      runInAction(() => {
        this.loading = e instanceof CanceledError
        if (this.loadingSearch) this.loadingSearch = e instanceof CanceledError
      })
    }
  }

  handleSearch = (key: string) => {
    this.page = 1
    this.search = key
    this.loadingSearch = true
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }

  markAsReadAllList = () => {
    this.items.forEach((item) => item.makeAsRead())
  }

  setItemToBegin = (item: ICallHistory) => {
    const currentItems = cloneDeep(this.items)
    this.itemsMap.clear()
    this.setItem(item)
    this.setItems(currentItems)
  }

  setAdditionItem = (item: ICallHistory) => {
    if (!this.callHistoryFiltersListStore.hasCalls) {
      const params: IParamsGetCallHistoryStatistic = {
        team_id: inboxesStore.currentInboxTypeId,
        team_type: inboxesStore.currentInboxType || 3,
        group: filtersStore.filter?.key === 'all' ? null : filtersStore.filter?.key,
      }

      this.callHistoryFiltersListStore.loadData(params)

      return
    }

    if (this.callHistoryFiltersListStore.isActiveAllCalls) {
      this.setItemToBegin(item)

      return
    }

    const { type, outcome } = item.call

    const isInbound = type === 'inbound'
    const isOutbound = type === 'outbound'

    const isInboundAnswered = isInbound && outcome === 'answered'
    const isInboundMissed = isInbound && outcome === 'missed'
    const isInboundVoicemail = isInbound && outcome === 'voicemail'

    const isOutboundAnswered = isOutbound && outcome === 'answered'
    const isOutboundNoAnswer = isOutbound && outcome === 'no_answer'

    if (
      (isInboundAnswered && this.callHistoryFiltersListStore.isActiveInboundAnswered) ||
      (isInboundMissed && this.callHistoryFiltersListStore.isActiveInboundMissed) ||
      (isInboundVoicemail && this.callHistoryFiltersListStore.isActiveInboundVoicemail) ||
      (isOutboundAnswered && this.callHistoryFiltersListStore.isActiveOutboundAnswered) ||
      (isOutboundNoAnswer && this.callHistoryFiltersListStore.isActiveOutboundNoAnswered)
    ) {
      this.setItemToBegin(item)
    }
  }

  updateCallHistoryByMessageReceivedEvent = (data: ICallHistory) => {
    const { call, message } = data
    const { id, unread } = message

    if (this.getItem(id)) {
      this.setItem(data)
    } else {
      if (unread) {
        this.callHistoryFiltersListStore.increaseCount(call)
      }

      this.setAdditionItem(data)
    }
  }

  updateCallHistoryByVoicemailEvent = (data: ICallHistory) => {
    const { call, message } = data
    const { id, unread } = message
    const existingItem = this.getItem(id)

    if (existingItem) {
      if (existingItem.message.unread && existingItem.call.outcome && existingItem.call.type) {
        const item: IReadMessage = {
          id: existingItem.message.id,
          type: 'call',
          direction: existingItem.call.type,
          status: existingItem.call.outcome,
        }

        this.callHistoryFiltersListStore.decreaseCount([item])
      }

      this.setItem(data)
    } else {
      this.setAdditionItem(data)
    }

    if (unread) {
      this.callHistoryFiltersListStore.increaseCount(call)
    }
  }

  sendLog = () => {
    const outcome =
      this.callHistoryFiltersListStore.type === 'all-calls'
        ? 'all-calls'
        : `${this.callHistoryFiltersListStore.type} - ${this.eventFilters.outcome}`

    const payload = {
      event_id: 'call_history_filter_used',
      outcome,
      conversation_type: this.groupLabel,
    }

    eventLogStore.logEvent('Call History Filter Used', payload, { groupId: organizationStore.id })
  }

  resetLoadingState = () => {
    this.loading = false
  }

  reset = () => {
    this.itemsMap.clear()
    this.loading = true
    this.search = null
    this.page = 1
    this.total = 0
  }
}
