// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OPfvFS94Amv6r8VfhMlg{padding:16px 0}.YnNlrFExwPDxJW1yBKcH{height:calc(100vh - 450px)}.nF6VRs9gYUXFLw_VuQwI{border-bottom:1px solid var(--border-neutral-default)}._LdTPt_54spPAr_AOZLG{display:flex;border-bottom:1px solid var(--border-neutral-default)}.HSkTzN0iy2ObrLKWzUD4{flex-direction:column;justify-content:center}.leDvf07eVlFG4pIkqzSC{padding:16px 8px;margin-bottom:0}.lwGtwTuRpYWotmUD5u0w{line-height:16px;margin-bottom:6px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.lwGtwTuRpYWotmUD5u0w:last-child{margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/MapFieldsTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,cAAA,CAEJ,sBACI,0BAAA,CAEJ,sBACI,qDAAA,CAEJ,sBACI,YAAA,CACA,qDAAA,CAEJ,sBACI,qBAAA,CACA,sBAAA,CAEJ,sBACI,gBAAA,CACA,eAAA,CAEJ,sBACI,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CAEJ,iCACI,eAAA","sourcesContent":[".tableContainer {\n    padding: 16px 0;\n}\n.bodyContainer {\n    height: calc(100vh - 450px);\n}\n.header {\n    border-bottom: 1px solid var(--border-neutral-default);\n}\n.row {\n    display: flex;\n    border-bottom: 1px solid var(--border-neutral-default);\n}\n.columnHeaderCell {\n    flex-direction: column;\n    justify-content: center;\n}\n.previewCell {\n    padding: 16px 8px;\n    margin-bottom: 0;\n}\n.previewCellValue {\n    line-height: 16px;\n    margin-bottom: 6px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.previewCellValue:last-child {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "OPfvFS94Amv6r8VfhMlg",
	"bodyContainer": "YnNlrFExwPDxJW1yBKcH",
	"header": "nF6VRs9gYUXFLw_VuQwI",
	"row": "_LdTPt_54spPAr_AOZLG",
	"columnHeaderCell": "HSkTzN0iy2ObrLKWzUD4",
	"previewCell": "leDvf07eVlFG4pIkqzSC",
	"previewCellValue": "lwGtwTuRpYWotmUD5u0w"
};
export default ___CSS_LOADER_EXPORT___;
