import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CancelTokenSource, CanceledError } from 'axios'
import { debounce } from 'lodash'
import { Segment } from 'entities/Segment/model/Segment'
import { SegmentApi, segmentsStore } from 'entities/Segment'
import { ISearchDropdownStoreOptions } from 'widgets/SearchDropdown'

export class SearchDropdownSegmentStore {
  private _data: Segment[] = []
  private _loading = false
  private _search = ''
  private _total = 0
  private _page = 1
  private _perPage = 10
  private _debounceFetch
  private _selectedItemsMapIds: Map<number, number> = new Map()
  private _options: ISearchDropdownStoreOptions | null = null
  private _isShowMore = false
  private _isOpen = false
  private _cancelTokenSource: CancelTokenSource | null = null

  constructor() {
    makeAutoObservable(this)
    this._debounceFetch = debounce(this.init, 500)
  }

  get selectedItemsCount() {
    return this._selectedItemsMapIds.size
  }

  get selectedItemsListIds() {
    return Array.from(this._selectedItemsMapIds.entries()).map((item) => item[1])
  }

  get hasSelectedItems() {
    return !!this.selectedItemsCount
  }

  get firstSelectedItemId() {
    const id = this._selectedItemsMapIds.entries().next().value?.[0]

    if (!id) return null

    return id
  }

  get hasItems() {
    return !!this._data.length
  }

  get first3Items() {
    return this._data.slice(0, 3)
  }

  get itemsList() {
    return this._data
  }

  get itemsListIds() {
    return this._data.map((item) => item.id)
  }

  get hasMore() {
    const loadedCount = this._page * this._perPage

    return loadedCount < this._total
  }

  setIsShowMore = (value: boolean) => {
    this._isShowMore = value
  }

  setIsOpen = (value: boolean) => {
    this._isOpen = value
  }

  initOptions = (options: ISearchDropdownStoreOptions) => {
    this._options = options
  }

  initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  resetCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
  }

  isSelected = (id: number) => {
    return !!this._selectedItemsMapIds.get(id)
  }

  selectTypeItem = (item: Segment) => {
    this.toggleTypeItem(item)
    this._options?.resetSearch?.()
  }

  resetType = () => {
    this._selectedItemsMapIds.clear()
    this.reset()
  }

  toggleTypeItem = (item: Segment) => {
    const id = item.id

    if (this._options?.selectedMode) {
      if (this._selectedItemsMapIds.has(id)) {
        this._selectedItemsMapIds.delete(id)
      } else {
        this._selectedItemsMapIds.set(id, id)
      }
    }

    this._options?.toggleTypeItem?.(item)
  }

  reset = () => {
    this._data = []
    this._loading = false
    this._search = ''
    this._total = 0
    this._page = 1
    this._cancelTokenSource?.cancel()
    this._debounceFetch.cancel()
  }

  handleSearch = (value: string) => {
    this._search = value
    this.resetCancelTokenSource()
    this._debounceFetch()
  }

  loadMore = async () => {
    try {
      runInAction(() => {
        this._loading = true
        this.initCancelTokenSource()
      })

      const { data } = await SegmentApi.getSegmentsSearch(
        {
          term: this._search,
          page: this._page + 1,
          per_page: this._perPage,
        },
        {
          cancelToken: this._cancelTokenSource?.token,
        }
      )

      const items = segmentsStore.addItems(data.results)

      runInAction(() => {
        this._data = [...this._data, ...items]
        this._total = data.total
        this._page += 1
      })
    } catch (e) {
      if (e instanceof CanceledError) return

      console.error(e)
    } finally {
      this._loading = false
    }
  }

  init = async () => {
    try {
      runInAction(() => {
        this._loading = true
        this._data = []
        this._page = 1
        this.initCancelTokenSource()
      })

      const { data } = await SegmentApi.getSegmentsSearch(
        {
          term: this._search,
          page: this._page,
          per_page: this._perPage,
          exclude: this.selectedItemsListIds,
        },
        {
          cancelToken: this._cancelTokenSource?.token,
        }
      )

      const items = segmentsStore.addItems(data.results)

      runInAction(() => {
        this._data = items
        this._total = data.total
      })
    } catch (e) {
      if (e instanceof CanceledError) return

      console.error(e)
    } finally {
      this._loading = false
    }
  }

  get selectedItemsMapIds() {
    return this._selectedItemsMapIds
  }

  get search() {
    return this._search
  }

  get loading() {
    return this._loading
  }

  get options() {
    return this._options
  }

  get total() {
    return this._total
  }
}
