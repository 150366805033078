import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Dropdown, IDropdownItem, Typography } from 'shared/ui'
import { CustomContent } from 'shared/ui/Schedule/ui/Recurring/ui/CustomContent/CustomContent'
import { useRecurringContext } from 'shared/ui/Schedule/ui/Recurring/context/recurringContext'
import styles from './styles.module.scss'

export const RepeatDropdown = observer(() => {
  const { repeatTextDropdown, dropdownId, repeatItems, onChangeRepeatOption, isDisabledRepeat } =
    useRecurringContext()

  const [isOpenCustomContent, setOpenCustomContent] = useState(false)

  const [isOpen, setOpen] = useState(false)

  const onCloseDropdown = () => {
    setOpenCustomContent(false)
  }

  const handleChangeRepeatOption = (item: IDropdownItem) => {
    if (item.id === 'custom') {
      setOpenCustomContent(true)
    } else {
      onChangeRepeatOption(item)
    }
  }

  return (
    <Dropdown
      disabled={isDisabledRepeat}
      ariaLabel={'RepeatDropdown'}
      width={322}
      customComponent={
        isOpenCustomContent
          ? (onCloseMenu, dropdownId) => (
              <CustomContent onCloseMenu={onCloseMenu} dropdownId={dropdownId} />
            )
          : undefined
      }
      onClose={onCloseDropdown}
      typeButton={'button'}
      buttonProps={{
        text: (
          <Typography
            ellipsis
            title={repeatTextDropdown}
            className={styles.textBtn}
            ariaLabel={'repeatTextDropdown'}
          >
            {repeatTextDropdown}
          </Typography>
        ),
        paddingInline: 8,
        textClassname: styles.textBtn,
        typeBtn: 'menuItem',
        fontWeight: 400,
        iconProps: {
          icon: 'repeat',
        },
        tooltipProps: {
          fullWidth: true,
          label: 'Repeat',
          placement: 'left',
          visibility: isOpen ? 'hidden' : 'visible',
        },
      }}
      items={repeatItems}
      onChange={handleChangeRepeatOption}
      parentsDropdownId={[dropdownId]}
      placement={'bottom-start'}
      onChangeOpen={setOpen}
    />
  )
})
