import type { IntegrationSettingsHubspotCheckboxes } from 'entities/Integrations'
import { PROPERTY_PHONE_TOOLTIP } from 'pages/settings/pages/integrations/pages/integrationSettings/lib'
import type { SettingsPropertyItemType } from 'pages/settings/pages/integrations/pages/integrationSettings/model/types'

export const HUBSPOT_SYNC_CHECKBOX_KEYS: IntegrationSettingsHubspotCheckboxes[] = [
  'update_contacts',
  'create_non_existing_contacts',
  'update_owner',
]

export const HUBSPOT_BANNED_IDS = ['firstname', 'lastname', 'email', 'phone']

export const HUBSPOT_PROPERTIES: SettingsPropertyItemType[] = [
  {
    id: 'number',
    fieldName: 'Phone',
    integrationName: 'HubSpot',
    customSettingId: 'phone_number_priority',
    value: null,
    disabled: false,
    type: 'default',
    tooltip: PROPERTY_PHONE_TOOLTIP,
  },
  {
    id: 'first_name',
    fieldName: 'First name',
    integrationName: 'HubSpot',
    value: 'First name',
    disabled: true,
    type: 'default',
  },
  {
    id: 'last_name',
    fieldName: 'Last name',
    integrationName: 'HubSpot',
    value: 'Last name',
    disabled: true,
    type: 'default',
  },
  {
    id: 'email',
    fieldName: 'Email',
    integrationName: 'HubSpot',
    value: 'Email',
    disabled: true,
    type: 'default',
  },
]
