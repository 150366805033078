export const isLink = (link: string, minDomainLevel = 2) => {
  try {
    const url = new URL(link)

    const isHTTP = url.protocol.startsWith('http')
    if (!isHTTP) return false

    const domains = url.hostname.split('.').filter(Boolean)

    return domains.length >= minDomainLevel
  } catch (_) {
    return false
  }
}
