import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownContent } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { SearchDropdownInboxesStore } from 'widgets/SearchDropdown'
import { InboxesDropdownContentItems } from 'widgets/SearchDropdown/ui'
import styles from './styles.module.scss'

type IInboxesDropdownContentProps = {
  store: SearchDropdownInboxesStore
  onChange?: (item: Inbox) => void
  width?: number
}

export const InboxesDropdownContent: FC<IInboxesDropdownContentProps> = observer(
  ({ store, onChange, width }) => {
    const { search } = store

    const handleReset = () => {
      return store.resetType()
    }

    return (
      <DropdownContent noPadding className={styles.wrap} width={width}>
        <div className={styles.searchWrap}>
          <TextField
            className={styles.search}
            variant='integrated'
            InputProps={{
              placeholder: 'Search inbox',
            }}
            onChange={store.handleSearch}
            value={search}
            mainActions={[makeTextFieldAutoFocus({ withFocus: !search })]}
          />
        </div>

        <InboxesDropdownContentItems store={store} onChange={onChange} />

        {store.hasSelectedItems && (
          <div className={styles.clear}>
            <Button typeBtn={'text'} text={'Clear'} onClick={handleReset} />
          </div>
        )}
      </DropdownContent>
    )
  }
)
