import { makeAutoObservable } from 'mobx'
import axios, { type CancelTokenSource } from 'axios'

export class RequestCancelAxios {
  private _cancelTokenSource: CancelTokenSource | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get token() {
    return this._cancelTokenSource?.token
  }

  init = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  reset = () => {
    this._cancelTokenSource?.cancel()
  }
}
