import { makeAutoObservable, runInAction } from 'mobx'
import { storeCache } from 'shared/api/storeCache'

const makeDeviceCache = storeCache.create('mediaDevice', window.localStorage)

export type IDeviceStore = DeviceStore

class DeviceStore {
  constructor() {
    makeAutoObservable(this)
    makeDeviceCache(this, ['audioOutputId', 'audioInputId', 'videoInputId'])
    this.syncDevices()
  }

  devices: MediaDeviceInfo[] = []

  audioOutputId: string | null = null
  audioInputId: string | null = null
  videoInputId: string | null = null

  get audioOutputs(): MediaDeviceInfo[] {
    return this.devices.filter((device) => device.kind === 'audiooutput')
  }

  get audioInputs(): MediaDeviceInfo[] {
    return this.devices.filter((device) => device.kind === 'audioinput')
  }

  get videoInputs(): MediaDeviceInfo[] {
    return this.devices.filter((device) => device.kind === 'videoinput')
  }

  get audioOutput(): MediaDeviceInfo | null {
    return this.audioOutputs.find(({ deviceId }) => this.audioOutputId === deviceId) ?? null
  }

  get audioInput(): MediaDeviceInfo | null {
    return this.audioInputs.find(({ deviceId }) => this.audioInputId === deviceId) ?? null
  }

  get videoInput(): MediaDeviceInfo | null {
    return this.videoInputs.find(({ deviceId }) => this.videoInputId === deviceId) ?? null
  }

  syncDevices = async () => {
    try {
      const devices = await window.navigator.mediaDevices.enumerateDevices()

      runInAction(() => {
        this.devices = devices

        const isAudioOutputNotSync =
          !this.audioOutputId ||
          !this.audioOutputs.some(({ deviceId }) => deviceId === this.audioOutputId)

        if (isAudioOutputNotSync)
          this.audioOutputId =
            this.audioOutputs.find((device) => device.deviceId === 'default')?.deviceId ??
            this.audioOutputs[0]?.deviceId ??
            null

        const isAudioInputNotSync =
          !this.audioInput ||
          !this.audioInputs.some(({ deviceId }) => deviceId === this.audioInputId)

        if (isAudioInputNotSync)
          this.audioInputId =
            this.audioInputs.find((device) => device.deviceId === 'default')?.deviceId ??
            this.audioInputs[0]?.deviceId ??
            null

        const isVideoInputNotSync =
          !this.videoInputId ||
          !this.videoInputs.some(({ deviceId }) => deviceId === this.videoInputId)

        if (isVideoInputNotSync)
          this.videoInputId =
            this.videoInputs.find((device) => device.deviceId === 'default')?.deviceId ??
            this.videoInputs[0]?.deviceId ??
            null
      })
    } catch (e) {
      console.error(e)
    }
  }

  setAudioOutput = (deviceId: string) => {
    this.audioOutputId = this.audioOutputs.some((device) => device.deviceId === deviceId)
      ? deviceId
      : null
  }

  setAudioInput = (deviceId: string) => {
    this.audioInputId = this.audioInputs.some((device) => device.deviceId === deviceId)
      ? deviceId
      : null
  }

  setVideoInput = (deviceId: string) => {
    this.videoInputId = this.videoInputs.some((device) => device.deviceId === deviceId)
      ? deviceId
      : null
  }
}

export const deviceStore = new DeviceStore()
