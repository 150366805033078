import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FullPageWrapper, ITabItem, Tabs } from 'shared/ui'
import { BroadcastRoutesEnum, broadcastStore } from 'entities/Broadcast'
import { BroadcastsHeader } from 'widgets/BroacastList'

export const OneTimeBroadcastLayout = observer(() => {
  const { isEmptyState } = broadcastStore
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const tabs: ITabItem[] = [
    {
      name: 'SMS',
      key: BroadcastRoutesEnum.sms,
    },
    {
      name: 'Ringless',
      key: BroadcastRoutesEnum.ringless,
    },
  ]

  const handleSelectTab = (tab: ITabItem) => {
    navigate(tab.key)
  }

  const activeTabKey = pathname.split('/').pop() || ''

  return (
    <FullPageWrapper>
      <BroadcastsHeader title={'One time'} />
      {!isEmptyState && (
        <Box padding={'0 20px 12px 20px'}>
          <Tabs tabs={tabs} activeTabKey={activeTabKey} handleSelectTab={handleSelectTab} />
        </Box>
      )}
      <Outlet />
    </FullPageWrapper>
  )
})
