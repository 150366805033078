// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K1uA4dlPCB4PppYzzL0I{margin-block:-4px;height:24px}.qnqPmG1vPzV_GA7cothz{color:var(--content-negative-primary);height:20px;display:flex;align-content:center;margin-top:8px}.qnqPmG1vPzV_GA7cothz.wZChxG1b5gT4Jhnc3ITQ{margin-top:0;margin-bottom:8px}.Q0MCn6hXvPdOVXFQ5zx8{width:71px;display:flex;align-items:center}", "",{"version":3,"sources":["webpack://./src/widgets/TestSMS/ui/TestSmsDefault/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,WAAA,CAEF,sBACE,qCAAA,CACA,WAAA,CACA,YAAA,CACA,oBAAA,CACA,cAAA,CACA,2CACE,YAAA,CACA,iBAAA,CAIJ,sBACE,UAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".inputAction{\n  margin-block: -4px;\n  height: 24px;\n}\n.error{\n  color: var(--content-negative-primary);\n  height: 20px;\n  display: flex;\n  align-content: center;\n  margin-top: 8px;\n  &.isTopError{\n    margin-top: 0;\n    margin-bottom: 8px;\n  }\n\n}\n.send{\n  width: 71px;\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputAction": "K1uA4dlPCB4PppYzzL0I",
	"error": "qnqPmG1vPzV_GA7cothz",
	"isTopError": "wZChxG1b5gT4Jhnc3ITQ",
	"send": "Q0MCn6hXvPdOVXFQ5zx8"
};
export default ___CSS_LOADER_EXPORT___;
