import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CancelTokenSource } from 'axios'
import { TableStore } from 'shared/ui/Table'
import {
  AdminOrganizationsApi,
  IAdminOrganization,
  IParamsGetOrganizations,
  IResponseAdminOrganizations,
} from 'entities/Admin/organizations'
import { IOrganizationStatus } from 'entities/Organization'
import { ISubscriptionStatus } from 'entities/Subscription'

class AdminOrganizationsListStore {
  tableStore = new TableStore()
  constructor() {
    makeAutoObservable(this)
    reaction(() => this.paramsGetOrganizations, this.loadData, {
      delay: 500,
    })
  }

  page = 1
  limit: number | null = 50
  total = 0
  search: string | null = null
  loading = false
  loadingSearch = false

  organizationStatuses: Array<IOrganizationStatus> = []
  subscriptionStatuses: Array<ISubscriptionStatus> = []

  organizationsMap: Map<number, IAdminOrganization> = new Map()

  cancelTokenSource: CancelTokenSource | null = null

  get organizations() {
    return Array.from(this.organizationsMap.values())
  }

  get paramsGetOrganizations(): IParamsGetOrganizations {
    return {
      organization_status: this.organizationStatuses,
      subscription_status: this.subscriptionStatuses,
      page: this.page,
      search: this.search || null,
      limit: this.limit,
    }
  }

  loadData = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })
      this.initCancelTokenSource()
      const { data } = await AdminOrganizationsApi.getOrganizations(this.paramsGetOrganizations, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })
      this.setData(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  setData = ({ data, meta }: IResponseAdminOrganizations) => {
    this.reset()
    this.setItems(data)
    this.total = meta.total
  }

  setItems = (organizations: IAdminOrganization[]) => {
    organizations.forEach((organization) => this.setItem(organization))
  }

  setItem = (organization: IAdminOrganization) => {
    this.organizationsMap.set(organization.id, organization)
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  reset = () => {
    this.organizationsMap.clear()
  }

  setSearch = (value: string) => {
    this.page = 1
    this.search = value
  }

  setOrganizationStatus = (values: typeof this.organizationStatuses) => {
    this.page = 1
    this.organizationStatuses = values
  }
  setSubscriptionStatuses = (values: typeof this.subscriptionStatuses) => {
    this.page = 1
    this.subscriptionStatuses = values
  }

  onPaginationModelChange = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
  }
}

export const adminOrganizationsListStore = new AdminOrganizationsListStore()
