import { useEffect, useRef } from 'react'

export const useClickAway = <E extends HTMLElement>(callback: () => void) => {
  const ref = useRef<E | null>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref.current) return

      const isInside = event.composedPath().includes(ref.current)
      if (isInside) return

      callback()
    }

    document.addEventListener('click', handleClick, true)

    return () => document.removeEventListener('click', handleClick, true)
  }, [callback])

  return ref
}
