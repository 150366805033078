// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ginuPtv3tv5iQgttIMJg{transition:width .5s ease}.ginuPtv3tv5iQgttIMJg .qYXNHc93KVQRCajPVqPq{gap:12px;padding-left:12px;padding-right:12px}.ginuPtv3tv5iQgttIMJg .OGJZgE48ocfK_fJ59fiN{width:38px;background-color:var(--action-contained-secondary-normal);box-shadow:none}.ginuPtv3tv5iQgttIMJg .OGJZgE48ocfK_fJ59fiN:hover{background:var(--action-contained-secondary-hovered);color:var(--content-brand-secondary);box-shadow:none;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/shared/ui/StretchInput/styles.module.scss"],"names":[],"mappings":"AACA,sBACI,yBAAA,CAEA,4CACI,QAAA,CACA,iBAAA,CACA,kBAAA,CAGJ,4CACI,UAAA,CACA,yDAAA,CACA,eAAA,CAEA,kDACI,oDAAA,CACA,oCAAA,CACA,eAAA,CACA,cAAA","sourcesContent":["\n.container {\n    transition: width 0.5s ease;\n\n    & .inputStyles {\n        gap: 12px;\n        padding-left: 12px;\n        padding-right: 12px;\n    }\n\n    & .collapseInputStyles {\n        width: 38px;\n        background-color: var(--action-contained-secondary-normal);\n        box-shadow: none;\n\n        &:hover {\n            background: var(--action-contained-secondary-hovered);\n            color: var(--content-brand-secondary);\n            box-shadow: none;\n            cursor: pointer;\n        }\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ginuPtv3tv5iQgttIMJg",
	"inputStyles": "qYXNHc93KVQRCajPVqPq",
	"collapseInputStyles": "OGJZgE48ocfK_fJ59fiN"
};
export default ___CSS_LOADER_EXPORT___;
