import { makeAutoObservable, runInAction } from 'mobx'
import { toastStore } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { logger } from 'shared/lib'
import { contactsStore } from 'entities/Contacts'
import { WorkflowsApi, WorkflowStatus } from 'entities/Workflow'
import type { Contact } from 'entities/Contacts/model/Contact'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { WorkflowEnrollModal } from 'widgets/WorkflowEnroll/ui/WorkflowEnrollModal'

export class WorkflowEnrollStore {
  private _isActive = false
  private _isChecked = false
  private _workflowEnrollModalId = 'workflowEnrollModalId'

  constructor() {
    makeAutoObservable(this)
  }

  get isActive() {
    return this._isActive
  }

  get isChecked() {
    return this._isChecked
  }

  get isOperationEnabled() {
    return featureFlagsStore.workflows
  }

  getValidContactIds = async (contactIds: number[]) => {
    if (!this.isOperationEnabled) return null

    try {
      const contacts = (await contactsStore.getByIds(contactIds)) ?? []
      const blockReason = this.getBlockReason(...contacts)

      if (blockReason) {
        toastStore.add({
          type: 'error',
          title: blockReason,
        })

        return null
      }

      return contacts
        .filter((contact) => !contact.isOptOut && !contact.isBlocked)
        .map((contact) => contact.id)
    } catch (error) {
      logger.error(error)

      toastStore.add({
        type: 'error',
        title: 'Something went wrong. Please try again',
      })

      return null
    }
  }

  getBlockReason = (...contacts: Contact[]) => {
    switch (true) {
      case this._isChecked && !this._isActive:
        return 'No active Workflow'
      case !contacts.length:
        return 'No selected contact'
      case contacts.length === 1 && contacts[0].isOptOut:
        return 'This contact has opted-out'
      case contacts.length === 1 && contacts[0].isBlocked:
        return 'This contact has been blocked'
      case contacts.every(({ isOptOut, isBlocked }) => isOptOut || isBlocked):
        return 'Contacts has opted-out or has been blocked'
      default:
        return null
    }
  }

  checkActivity = () => {
    if (!this.isOperationEnabled) return

    WorkflowsApi.getWorkflows({
      preview: true,
      'page[size]': 1,
      'page[pointer]': 1,
      'filters[status]': WorkflowStatus.Active,
    }).then(
      ({ data }) =>
        runInAction(() => {
          this._isActive = !!data.payload.length
          this._isChecked = true
        }),
      () =>
        runInAction(() => {
          this._isActive = false
          this._isChecked = true
        })
    )
  }

  enrollContacts = (contactIds: number[]) => {
    const onClose = () => modalStore.removeModal(this._workflowEnrollModalId)

    modalStore.addModal({
      id: this._workflowEnrollModalId,
      title: 'Enroll in workflow',
      width: 480,
      showCloseButton: false,
      showCloseIcon: true,
      paddingContent: '12px 24px 24px',
      ModalContent: WorkflowEnrollModal,
      ModalContentProps: { contactIds, onClose },
      onClose: onClose,
    })
  }
}
