// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Th3o2BMC0iutAccBIn4C{display:flex;align-items:center}.PSeNPUR2spqfVOoUgH4A{color:var(--content-primary-secondary);font-size:13px;font-weight:400}.GoVSbTQ6sqIZ5ptCKe98{color:var(--content-primary-secondary);margin-right:4px}.IJCLlO8BC_mYLa3uChuZ{color:var(--content-primary-secondary);margin-right:3px}.IJCLlO8BC_mYLa3uChuZ a[class*=mention]{border-radius:6px;padding:0 4px;background:var(--background-warning-primary);color:var(--content-warning-secondary);font-size:13px;font-weight:400;line-height:123%;text-decoration:none;margin-right:-3px}.B2dSnA0DLWpgIbY60AuD{color:var(--content-negative-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationCard/ui/ConversationCardFiles/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,sCAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,sCAAA,CACA,gBAAA,CAGF,sBACE,sCAAA,CACA,gBAAA,CAEA,wCACE,iBAAA,CACA,aAAA,CACA,4CAAA,CACA,sCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CACA,iBAAA,CAGF,sBACE,qCAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n}\n\n.label {\n  color: var(--content-primary-secondary);\n  font-size: 13px;\n  font-weight: 400;\n}\n\n.icon {\n  color: var(--content-primary-secondary);\n  margin-right: 4px;\n}\n\n.body {\n  color: var(--content-primary-secondary);\n  margin-right: 3px;\n\n  a[class*=\"mention\"] {\n    border-radius: 6px;\n    padding: 0 4px;\n    background: var(--background-warning-primary);\n    color: var(--content-warning-secondary);\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 123%;\n    text-decoration: none;\n    margin-right: -3px;\n  }\n\n  &__label {\n    color: var(--content-negative-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Th3o2BMC0iutAccBIn4C",
	"label": "PSeNPUR2spqfVOoUgH4A",
	"icon": "GoVSbTQ6sqIZ5ptCKe98",
	"body": "IJCLlO8BC_mYLa3uChuZ",
	"body__label": "B2dSnA0DLWpgIbY60AuD"
};
export default ___CSS_LOADER_EXPORT___;
