import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import {
  PresetContainer,
  PresetFooter,
  PresetInfo,
  PresetList,
  PresetListItem,
} from 'shared/Preset'
import { ChatbotInstructionType } from 'entities/Chatbot'
import { ChatbotTemplateList } from 'pages/chatbot/config'

import { type ICreateChatbotProps } from './types'

export const ChatbotTemplateModal = observer(
  ({ chatbot, instructions, onCancel, onNext }: ICreateChatbotProps) => {
    const handleChangeType = (type: ChatbotInstructionType) => {
      const instruction = instructions.get(type)
      if (!!instruction) chatbot.settings.setInstruction(instruction)

      chatbot.setType(type)
    }

    return (
      <PresetContainer>
        <PresetInfo name='You can select one template' />
        <PresetList>
          {ChatbotTemplateList.map((template) => (
            <PresetListItem
              key={template.name}
              disabled={
                !instructions.has(template.type) &&
                template.type !== ChatbotInstructionType.LeadQualification
              }
              selected={chatbot.type === template.type}
              onClick={() => handleChangeType(template.type)}
              icon={template.icon}
              name={template.name}
              description={template.description}
            />
          ))}
        </PresetList>
        <PresetFooter>
          <Button text='Cancel' size='medium' contained='secondary' onClick={onCancel} />
          <Button text='Next' size='medium' disabled={!chatbot.type} onClick={onNext} />
        </PresetFooter>
      </PresetContainer>
    )
  }
)
