import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { Breadcrumbs } from 'shared/ui/Breadcrumbus/Breadcrumbs'
import { ContainerHeader, variantActionsProps } from 'shared/ui'
import {
  BroadcastErrorAlert,
  BroadcastRecurring,
  BroadcastRecurringLabel,
  BroadcastRoutesEnum,
  BroadcastStatus,
  broadcastStore,
  BroadcastType,
} from 'entities/Broadcast'
import {
  type BroadcastDetailOneTimeStore,
  type BroadcastDetailRecurringStoreStore,
} from 'widgets/BroadcastDetail'
import { getBroadcastActions } from 'widgets/BroadcastActions'
import styles from './styles.module.scss'

type IBroadcastDetailHeaderProps = {
  store: BroadcastDetailOneTimeStore | BroadcastDetailRecurringStoreStore
}

export const BroadcastDetailHeader: FC<IBroadcastDetailHeaderProps> = observer(({ store }) => {
  const { pageLayoutStore } = broadcastStore
  const { titleIconProps, headerMenuTooltipIconProps, headerMenuIconAction } = pageLayoutStore
  const { name, status, type, broadcast, failed_reason } = store

  const navigate = useNavigate()

  const onSuccessDelete = () => {
    navigate(`${BroadcastRoutesEnum.broadcasts}/${BroadcastRoutesEnum.all}`)
  }

  return (
    <div>
      <Breadcrumbs />
      <ContainerHeader
        paddingTop={16}
        paddingBottom={12}
        title={name}
        titleVariant={'heading-md'}
        actionsProps={{
          ...variantActionsProps['headerMedium'],
          actions: getBroadcastActions({ broadcast, onSuccessDelete }),
        }}
        titleIconProps={titleIconProps}
        actionIconTooltipProps={headerMenuTooltipIconProps}
        titleIconAction={headerMenuIconAction}
        paddingLeft={20}
      />
      <div className={styles.info}>
        {broadcast instanceof BroadcastRecurring && (
          <BroadcastRecurringLabel broadcast={broadcast} />
        )}
        <BroadcastStatus status={status} />
        <BroadcastType type={type} />
      </div>
      {status === 'failed' && (
        <div className={styles.alert}>
          <BroadcastErrorAlert failed_reason={failed_reason} />
        </div>
      )}
    </div>
  )
})
