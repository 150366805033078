// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e_BJps4u8eRmA7hx0rli{padding-left:30px}.QOgWkpIrhQfU5SbWAlVa{margin-left:auto;display:none}@media(max-width: 992px){.QOgWkpIrhQfU5SbWAlVa{display:inline-flex}}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/pages/chatbotEdit/ui/styles.module.scss"],"names":[],"mappings":"AAEA,sBACI,iBAAA,CAGJ,sBACI,gBAAA,CAEA,YAAA,CACA,yBAJJ,sBAKQ,mBAAA,CAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\n.headerLabel {\n    padding-left: 30px;\n}\n\n.headerButton {\n    margin-left: auto;\n\n    display: none;\n    @media (max-width: $breakpoint-s) {\n        display: inline-flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerLabel": "e_BJps4u8eRmA7hx0rli",
	"headerButton": "QOgWkpIrhQfU5SbWAlVa"
};
export default ___CSS_LOADER_EXPORT___;
