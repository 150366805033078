import { observer } from 'mobx-react-lite'
import { FC, ReactNode } from 'react'
import { useTableContext } from 'shared/ui/Table/context/context'
import { numberFormat } from 'shared/lib'
import styles from './styles.module.scss'

export type IHeaderLeftContentProps = { actionsContent?: ReactNode }

export const HeaderLeftContent: FC<IHeaderLeftContentProps> = observer(({ actionsContent }) => {
  const { selectedIds, bulkAllMode, total } = useTableContext()
  const selectedCount = bulkAllMode ? total : selectedIds.length
  return (
    <>
      <div className={styles.container}>
        {`${numberFormat({ value: selectedCount })} selected`}{' '}
      </div>
      {actionsContent}
    </>
  )
})
