import { IDropdownItem } from 'shared/ui'
import {
  IResponseConversationsOrder,
  IResponseConversationsOrderKey,
} from 'entities/Conversation/api/types'
import { ordersStore } from 'features/DropdownOrder'

export const OrdersItems = (items: IResponseConversationsOrder[]): IDropdownItem[] => {
  return items.map((item) => {
    return {
      id: item.key,
      label: item.label,
      active: ordersStore.order?.key === item.key,
    }
  })
}
export const OrdersAction = (item: IDropdownItem) => {
  const id = item.id as IResponseConversationsOrderKey
  if (id === ordersStore.order?.key) return

  ordersStore.setItem(id)
}
