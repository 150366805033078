import { observer } from 'mobx-react-lite'
import { Icon, SpinnerLoader, Typography } from 'shared/ui'
import styles from 'widgets/RecordVideo/ui/styles.module.scss'
import recordVideoStore from 'widgets/RecordVideo/store/recordVideoStore'
import { VideoRecord } from 'widgets/RecordVideo/ui/video/VideoRecord'
import { StreamRecord } from 'widgets/RecordVideo/ui/video/StreamRecord'

export const VideoContent = observer(() => {
  const { recordStore } = recordVideoStore
  const { stream, loading, pauseRecord } = recordStore
  if (loading) {
    return (
      <div className={styles.loading}>
        <SpinnerLoader size={24} />
      </div>
    )
  }
  if (!stream) {
    return (
      <div className={styles.empty}>
        <Icon icon={'cameraOff'} fontSize={24} />
        <Typography variant={'body-lg-medium'} ariaLabel={'camera'}>
          No camera found
        </Typography>
      </div>
    )
  }
  return pauseRecord ? <VideoRecord /> : <StreamRecord />
})
