import { makeAutoObservable } from 'mobx'

type IDropDown = {
  setOpen: (boolean: boolean) => void
  open: boolean
}

class DropDownStore {
  mapItems: Map<string, IDropDown>

  constructor() {
    makeAutoObservable(this)

    this.mapItems = new Map()
  }

  getItem = (id: string) => {
    return this.mapItems.get(id)
  }

  setItem = (id: string, value: IDropDown) => {
    this.mapItems.set(id, value)
  }

  deleteItem = (id: string) => {
    this.mapItems.delete(id)
  }

  allHide(dropdownId?: string, parentsId?: string[]) {
    Array.from(this.mapItems.keys()).forEach((id) => {
      if (dropdownId === id) return
      if (parentsId?.includes(id)) return
      this.getItem(id)?.setOpen(false)
    })
  }

  hide(id: string) {
    this.getItem(id)?.setOpen(false)
  }
}

export const dropDownStore = new DropDownStore()
