import { AxiosResponse } from 'axios'
import { IResponseConfig } from 'entities/Config'
import { baseApi } from 'entities/Auth'

class Api {
  getUsersConfig(): Promise<AxiosResponse<IResponseConfig>> {
    return baseApi.get('users/config')
  }
}

export const ConfigApi = new Api()
