import { IEventEmitterAction } from 'entities/EventEmitter/store/types'

export class EventEmitterStore {
  private _events: Map<
    keyof IEventEmitterAction,
    ((args: IEventEmitterAction[keyof IEventEmitterAction]) => void)[]
  > = new Map()

  subscribe<E extends keyof IEventEmitterAction>(
    eventName: E,
    callback: (data?: IEventEmitterAction[E]) => void
  ) {
    if (!this._events.has(eventName)) {
      this._events.set(eventName, [])
    }

    const callbacks = this._events.get(eventName)

    if (callbacks) {
      callbacks.push(callback)
      this._events.set(eventName, callbacks)
    }
  }

  unsubscribe<E extends keyof IEventEmitterAction>(
    eventName: E,
    callback: (data?: IEventEmitterAction[E]) => void
  ) {
    if (!this._events.has(eventName)) return

    let callbacks = this._events.get(eventName)

    if (callbacks) {
      callbacks = callbacks.filter((eventCallback) => callback !== eventCallback)
      this._events.set(eventName, callbacks)
    }
  }

  emit<E extends keyof IEventEmitterAction>(eventName: E, args?: IEventEmitterAction[E]) {
    if (!this._events.has(eventName)) return

    const callbacks = this._events.get(eventName)

    if (callbacks) {
      callbacks.forEach((callback) => callback.call(null, args))
    }
  }
}

export const eventEmitterStore = new EventEmitterStore()
