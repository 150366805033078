import { observer } from 'mobx-react-lite'
import { useState, useEffect, useRef, ReactNode, FC, RefObject } from 'react'
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable'
import { useWindowSize } from 'usehooks-ts'

type IDraggableWrapperProps = {
  handle?: string
  children: (ref: RefObject<HTMLDivElement>) => ReactNode
  defaultPosition?: {
    x: number
    y: number
  }
  onChangeRef?: (element: HTMLDivElement) => void
}

export const DraggableWrapper: FC<IDraggableWrapperProps> = observer(
  ({ onChangeRef, handle = 'handle', children, defaultPosition = { x: 0, y: 0 } }) => {
    const [bounds, setBounds] = useState({
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
    })
    const [position, setPosition] = useState<{ x: number; y: number }>(defaultPosition)
    const [cachePosition, setCachePosition] = useState<{ x: number; y: number }>(defaultPosition)
    const draggleRef = useRef<HTMLDivElement>(null)
    const { width = 0, height = 0 } = useWindowSize()

    useEffect(() => {
      if (!draggleRef.current) return
      if (!onChangeRef) return

      const mutationObserver = new MutationObserver((mutationList) => {
        if (!draggleRef.current) return

        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            onChangeRef(draggleRef.current)
          }
        }
      })

      mutationObserver.observe(draggleRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      })

      return () => {
        if (!onChangeRef) return

        mutationObserver.disconnect()
      }
    }, [])

    useEffect(() => {
      const targetRect = draggleRef?.current?.getBoundingClientRect()

      if (!targetRect) return

      setBounds({
        left: defaultPosition.x,
        top: defaultPosition.y,
        bottom: 0,
        right: 0,
      })
    }, [])

    useEffect(() => {
      if (!draggleRef.current) return

      const {
        width: elementWidth,
        height: elementHeight,
        x,
        y,
      } = draggleRef.current.getBoundingClientRect()

      const elementPositionX = x + elementWidth
      const elementPositionY = y + elementHeight
      const windowWidth = width
      const windowHeight = height
      const indent = 20

      if (cachePosition.x > Math.abs(windowWidth - elementWidth)) {
        setPosition({
          x: Math.abs(windowWidth - elementWidth - indent),
          y: position.y,
        })
      }

      if (cachePosition.y > Math.abs(windowHeight - elementHeight)) {
        setPosition({
          x: position.x,
          y: Math.abs(windowHeight - elementHeight - indent),
        })
      }

      if (elementPositionX > windowWidth) {
        setPosition({
          y: position.y,
          x: Math.abs(windowWidth - elementWidth - indent),
        })
      }

      if (elementPositionY > windowHeight) {
        setPosition({
          y: Math.abs(windowHeight - elementHeight - indent),
          x: position.x,
        })
      }
    }, [width, height])

    const onStart = (event: DraggableEvent, uiData: DraggableData) => {
      if (!draggleRef) return

      const { clientWidth, clientHeight } = window?.document?.documentElement
      const targetRect = draggleRef?.current?.getBoundingClientRect()

      if (targetRect) {
        setBounds({
          left: -targetRect?.left + uiData?.x,
          top: -targetRect?.top + uiData?.y,
          bottom: clientHeight - (targetRect?.bottom - uiData?.y),
          right: clientWidth - (targetRect?.right - uiData?.x),
        })

        setCachePosition({
          x: -targetRect?.left + uiData?.x,
          y: -targetRect?.top + uiData?.y,
        })
      }
    }

    const onStop = (event: DraggableEvent, uiData: DraggableData) => {
      setPosition({
        x: uiData.x,
        y: uiData.y,
      })

      setCachePosition({
        x: uiData.x,
        y: uiData.y,
      })
    }

    return (
      <Draggable
        handle={`.${handle}`}
        bounds={bounds}
        onStart={onStart}
        onStop={onStop}
        defaultPosition={defaultPosition}
        position={position}
      >
        {children(draggleRef)}
      </Draggable>
    )
  }
)
