import { observer } from 'mobx-react-lite'
import { usersStore } from 'entities/Users'
import { CloseAction } from 'widgets/ConversationList/ui/BulkActions/CloseAction/CloseAction'
import { ReadAction } from 'widgets/ConversationList/ui/BulkActions/ReadAction/ReadAction'
import { DropdownActions } from 'widgets/ConversationList/ui/BulkActions/DropdownActions/DropdownActions'
import styles from './styles.module.scss'

export const BulkActions = observer(() => {
  const user = usersStore.user

  if (user?.isViewOnlyRole) return null

  return (
    <div className={styles.wrap}>
      <CloseAction />
      <ReadAction />
      <DropdownActions />
    </div>
  )
})
