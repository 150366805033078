import React from 'react'
import { Button, type IColumn, Icon, Tooltip, Typography } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table'
import { numberFormat } from 'shared/lib'
import { links } from 'shared/constants/links'
import { uiStore } from 'shared/store/uiStore'
import type { ITrigger } from 'entities/Trigger/model/Trigger'
import { TriggerType } from 'entities/Trigger/ui/TriggerType/TriggerType'
import { TriggerStatus } from 'entities/Trigger/ui/TriggerStatus/TriggerStatus'
import type { ITriggerCategoryType } from 'entities/Trigger/api/type'
import styles from './styles.module.scss'

type IColumnsToExclude = Record<ITriggerCategoryType, string[]>

const columnsToExclude: IColumnsToExclude = {
  sms: [],
  ringless: ['replies', 'clicks', 'conversions', 'revenue'],
  all: [],
}

export const getColumns = (type: ITriggerCategoryType): IColumn<ITrigger>[] => {
  const excludedFields = columnsToExclude[type] || []

  const allColumns: IColumn<ITrigger>[] = [
    {
      field: 'name',
      name: 'Name',
      width: '100%',
      minWidth: '362px',
      maxWidth: '362px',
      paddingInExpand: '32px',
      renderRowCell: (row) => <NameCell row={row} />,
    },
    {
      field: 'type',
      name: 'Type',
      width: '72px',
      renderRowCell: (row) => <TriggerType type={row.type} />,
    },
    {
      field: 'status',
      name: 'Status',
      renderRowCell: (row) => row.status && <TriggerStatus status={row.status} />,
    },
    {
      field: 'totalCount',
      name: 'Total',
      renderRowCell: (row) =>
        row.statistics?.total_count ? (
          numberFormat({ value: row.statistics?.total_count })
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'delivered',
      name: 'Delivered',
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.success_count === null}
          isHover={isHover}
          value={row.statistics?.success_count}
          percent={row.statistics?.success_percent}
        />
      ),
    },
    {
      field: 'replies',
      name: 'Replies',
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.reply_count === null}
          isHover={isHover}
          value={row.statistics?.reply_count || 0}
          percent={row.statistics?.reply_percent}
        />
      ),
    },
    {
      field: 'clicks',
      name: 'Clicks',
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.type === 'ringless' || row.statistics?.clicks_count === null}
          isHover={isHover}
          value={row.statistics?.clicks_count}
          percent={row.statistics?.clicks_percent}
        />
      ),
    },
    {
      field: 'conversions',
      name: 'Conversions',
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.type === 'ringless' || row.statistics?.conversions_count === null}
          isHover={isHover}
          value={row.statistics?.conversions_count}
          percent={row.statistics?.conversions_percent}
        />
      ),
    },
    {
      field: 'revenue',
      name: 'Revenue',
      tooltipQuestionProps: {
        width: 221,
        placement: 'top',
        label: [
          'Use Conversion Tracking to track the revenue your Triggers generate. ',
          <Typography key={'link'} variant={'body-md-medium'} linkLight ariaLabel={'LearnMore'}>
            <a href={links.conversionRevenueTracking} target={'_blank'} rel='noreferrer'>
              Learn more
            </a>
          </Typography>,
        ],
      },
      renderRowCell: (row) => row.statistics?.revenue_cost || <EmptyCell />,
    },
    {
      field: 'undelivered',
      name: 'Undelivered',
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.success_count === null}
          isHover={isHover}
          value={row.statistics?.undelivered_count}
          percent={row.statistics?.undelivered_percent}
        />
      ),
    },
  ]

  return allColumns.filter((column) => !excludedFields.includes(column.field))
}

const NameCell = ({ row }: { row: ITrigger }) => {
  const onReconnectClick = () => {
    uiStore.changeRoute({
      path: '/settings/integrations',
      type: 'vue',
    })
  }

  return (
    <div className={styles.nameCell}>
      <Tooltip placement='top' label={row.integration?.name || 'Custom'}>
        <Icon
          icon={row.integration?.icon || 'lightning2'}
          padding={3}
          fontSize={14}
          tertiary
          insideBox
        />
      </Tooltip>
      <Typography
        variant={'body-md-regular'}
        ellipsis
        tooltipProps={{
          label: row.name,
        }}
        ariaLabel={'name'}
        className={styles.triggerName}
      >
        {row.name}
      </Typography>
      {row.integration && !row.integration.is_connected && (
        <Tooltip
          placement={'top'}
          label={
            <Typography ariaLabel={'IntegrationDisconnected'} variant={'body-md-regular'}>
              Integration disconnected.
              <br />
              <Button
                tag='span'
                text={'Reconnect'}
                typeBtn='link'
                size={'medium'}
                onClick={onReconnectClick}
                className={styles.reconnectButton}
              />
            </Typography>
          }
        >
          <Icon icon={'alertTriangleYellow'} />
        </Tooltip>
      )}
    </div>
  )
}

const StatisticCell = ({
  isEmpty,
  isHover,
  value,
  percent,
}: {
  isEmpty?: boolean | undefined
  isHover: boolean | undefined
  value: number | undefined | null
  percent: number | undefined
}) => {
  if (isEmpty) {
    return <EmptyCell />
  }
  return <>{isHover ? `${percent}%` : value && numberFormat({ value })}</>
}
