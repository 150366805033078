import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import { AxiosError } from 'axios'
import { nanoid } from 'nanoid'
import { isEmail } from 'shared/lib'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'shared/constants/auth'
import { authStore } from 'entities/Auth'
import { AuthApi } from 'entities/Auth/api'
import { TwoFaModalStore } from 'widgets/TwoFaModal'
import { FieldsEnum } from './type'

export class StepSignUpStore {
  private _loading = false
  private _password = ''
  private _email = ''
  private _error: null | string = null
  private _handleNextStep: null | (() => void) = null
  private _activeField: FieldsEnum | null = null

  // Email validation
  private _disposeEmailValidation: IReactionDisposer | null = null
  private _emailError: string | null = null
  private _emailValidationTrigger: string | null = null
  private _showConstantEmailError = false

  // Password validation
  private _disposePasswordValidation: IReactionDisposer | null = null
  private _passwordError: string | null = null
  private _passwordValidationTrigger: string | null = null
  private _showConstantPasswordError = false

  private _twoFaModalStore = new TwoFaModalStore()

  constructor() {
    makeAutoObservable(this)
    this.reactionEmailValidation()
    this.reactionPasswordValidation()
  }

  get twoFaModalStore() {
    return this._twoFaModalStore
  }

  get checkDomain() {
    const searchParams = new URLSearchParams(window.location.search)
    return !searchParams.has('anydomain')
  }

  get showConstantEmailError() {
    return this._showConstantEmailError
  }

  get showConstantPasswordError() {
    return this._showConstantPasswordError
  }

  get emailError() {
    return this._emailError
  }

  get hasEmail() {
    return !!this.email
  }

  get hasPassword() {
    return !!this.password
  }

  get isMaxPassword() {
    return this.password.length === MAX_PASSWORD_LENGTH
  }

  get passwordError() {
    return this._passwordError
  }

  get email() {
    return this._email
  }

  get password() {
    return this._password
  }

  get emailLength() {
    return this.email.length
  }

  get passwordLength() {
    return this.password.length
  }

  get loading() {
    return this._loading
  }

  get error() {
    return this._error
  }

  get isValidEmail() {
    return isEmail(this._email)
  }

  get isValidPassword() {
    return (
      this._password.length >= MIN_PASSWORD_LENGTH && this._password.length <= MAX_PASSWORD_LENGTH
    )
  }

  get isActiveEmailField() {
    return this._activeField === FieldsEnum.email
  }

  get isActivePasswordField() {
    return this._activeField === FieldsEnum.password
  }

  reset = () => {
    this._email = ''
    this._password = ''
    this._activeField = null
  }

  signUp = async (store: TwoFaModalStore) => {
    this._showConstantEmailError = true
    this._showConstantPasswordError = true
    this.triggerEmailValidation()
    this.triggerPasswordValidation()
    if (!this.isValidEmail || !this.isValidPassword) return

    try {
      this._loading = true

      await AuthApi.validateEmail({
        email: this._email,
        checkDomain: this.checkDomain,
      })

      await authStore.signUpRegister({
        email: this._email,
        password: this._password,
      })
      this._loading = false
      if (this._handleNextStep) this._handleNextStep()
    } catch (error) {
      if (error instanceof AxiosError) {
        const existError = 'This email is already in use.'
        const isEmailExist = error.response?.data?.email?.[0] === existError

        if (isEmailExist) {
          this.trySignIn(existError, store)
          return
        }

        this._error = error.response?.data?.email?.[0] || error.response?.data?.error || null
        this._loading = false
      }
    }
  }

  trySignIn = async (error: string, store: TwoFaModalStore) => {
    try {
      this._loading = true

      const data = await authStore.login({
        email: this.email,
        password: this.password,
        remember: false,
      })

      if (data && store) {
        this._twoFaModalStore.openTwoFaModal(data, store)
      }
    } catch (e) {
      this._error = error
    } finally {
      this._loading = false
    }
  }

  focusEmailField = () => {
    this.setActiveField(FieldsEnum.email)
  }

  focusPasswordField = () => {
    this.setActiveField(FieldsEnum.password)
  }

  blurPasswordField = () => {
    this.blurActiveField(FieldsEnum.password)
  }

  blurEmailField = () => {
    this.blurActiveField(FieldsEnum.email)
  }

  blurActiveField = (value: FieldsEnum) => {
    if (this._activeField !== value) return
    this._activeField = null
  }

  setActiveField = (value: FieldsEnum) => {
    this._activeField = value
  }

  triggerEmailValidation = () => {
    this._emailValidationTrigger = nanoid()
  }

  triggerPasswordValidation = () => {
    this._passwordValidationTrigger = nanoid()
  }

  setEmail = (value: string) => {
    if (this._error) this._error = null
    if (this._showConstantEmailError) this._showConstantEmailError = false
    this._email = value.trim()
  }

  setPassword = (value: string) => {
    if (value.length > MAX_PASSWORD_LENGTH) return
    if (this._error) this._error = null
    if (this._showConstantPasswordError) this._showConstantPasswordError = false
    this._password = value
  }

  setHandleNextStep = (handleNextStep: () => void) => {
    this._handleNextStep = handleNextStep
  }

  reactionEmailValidation = () => {
    this._disposeEmailValidation?.()
    this._disposeEmailValidation = reaction(
      () => [this._emailValidationTrigger, this.isValidEmail],
      ([trigger, isValid]) => {
        this._emailError =
          trigger && !isValid
            ? this.hasEmail
              ? 'Please enter a valid email address.'
              : 'Please enter email address.'
            : null

        if (isValid) this._emailValidationTrigger = null
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPasswordValidation = () => {
    this._disposePasswordValidation?.()
    this._disposePasswordValidation = reaction(
      () => [this._passwordValidationTrigger, this.isValidPassword],
      ([trigger, isValid]) => {
        this._passwordError =
          trigger && !isValid
            ? this.hasPassword
              ? `Password must contain at least ${MIN_PASSWORD_LENGTH} characters.`
              : 'Please enter password.'
            : null
        if (isValid) this._passwordValidationTrigger = null
      },
      {
        fireImmediately: true,
      }
    )
  }
}
