import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, LogoSidebar, NavLink, NavLinkVue, Tooltip } from 'shared/ui'
import { actionWakeMode, getAriaLabel } from 'shared/lib'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { configStore } from 'entities/Config'
import { usersStore } from 'entities/Users'
import { organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { EventConversationUnread, sidebarStore } from 'widgets/Sidebar'
import { ContactDetailsContentOverlay } from 'widgets/ContactDetailsContentOverlay/ContactDetailsContentOverlay'
import { SettingsDropdown } from './SettingsDropdown/SettingsDropdown'
import { ProductUpdatesDropdown } from './ProductUpdatesDropdown/ProductUpdatesDropdown'
import { MainDropdown } from './MainDropdown/MainDropdown'
import { commonTooltipProps, IMenuItem, menuItems } from './menuItems'

import styles from './styles.module.scss'

const MenuItem = observer(({ icon, route, label, itemKey }: IMenuItem) => {
  const user = usersStore.user

  const navRoute = useMemo(() => {
    if (subscriptionStore?.isTrial && route === 'clients') {
      return 'settings/billing/overview'
    }
    return route
  }, [route, subscriptionStore?.isTrial])

  if (itemKey === 'workflows' && (!featureFlagsStore.canOpenWorkflow || user?.isViewOnlyRole))
    return null

  const canManageClients = usersStore.getUserPermissions('can_manage_clients')
  const canAccessTextBot = usersStore.getUserPermissions('can_access_text_bot')

  if (
    itemKey === 'clients' &&
    !((canManageClients && organizationStore.isAgency) || user?.hasAccessibleOrganizations)
  )
    return null

  if (itemKey !== 'clients' && subscriptionStore.isAgencyWithoutSubscription) {
    return null
  }

  if (itemKey === 'chatbot' && (!featureFlagsStore.chatbot || !canAccessTextBot)) {
    return null
  }

  if (window.DesignV2API && !window.DesignV2API?.handleCheckPage(navRoute)) {
    return (
      <Tooltip label={label} {...commonTooltipProps}>
        <NavLinkVue to={`/${navRoute}`} originalPath={route}>
          <Icon
            icon={icon}
            count={itemKey === 'conversation' ? sidebarStore.unread : undefined}
            fontSize={20}
          />
        </NavLinkVue>
      </Tooltip>
    )
  }

  return (
    <Tooltip label={label} {...commonTooltipProps}>
      <NavLink
        to={`/${navRoute}`}
        className={({ isActive }) =>
          isActive ? `${styles.button} ${styles.active}` : styles.button
        }
      >
        <Icon
          icon={icon}
          count={itemKey === 'conversation' ? sidebarStore.unread : undefined}
          fontSize={20}
        />
      </NavLink>
    </Tooltip>
  )
})

const Sidebar: React.FC = observer(() => {
  useEffect(() => {
    sidebarStore.handleReactionInboxes()
    EventConversationUnread.subscribe()

    return () => {
      EventConversationUnread.unsubscribe()
      sidebarStore.reset()
    }
  }, [])

  useEffect(() => {
    actionWakeMode(() => {
      sidebarStore.reInitInboxes()
    })
  }, [])

  return (
    <div className={styles.wrap} aria-label={getAriaLabel('Sidebar')}>
      <ContactDetailsContentOverlay />
      <div className={styles.wrapContent} aria-label={getAriaLabel('Sidebar', 'Content')}>
        <div className={styles.box} aria-label={getAriaLabel('Sidebar', 'BoxTop')}>
          <LogoSidebar />

          <div
            className={classNames(styles.buttons)}
            aria-label={getAriaLabel('Sidebar', 'buttons')}
          >
            {menuItems.map((item) => (
              <MenuItem key={item.itemKey} {...item} />
            ))}
          </div>
        </div>

        <div className={styles.box} aria-label={getAriaLabel('Sidebar', 'BoxBottom')}>
          {configStore.developer && (
            <MenuItem itemKey={'admin'} route={'admin'} label={'Admin'} icon={'admin'} />
          )}
          <ProductUpdatesDropdown />
          <SettingsDropdown />
          <MainDropdown />
        </div>
      </div>
    </div>
  )
})

export { Sidebar }
