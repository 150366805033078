import classNames from 'classnames'
import { ChangeEventHandler } from 'react'
import { IInputComponent, TextField } from 'shared/ui/TextField'
import styles from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/styles.module.scss'

export type IInputEditValueProps = {
  typeEdit?: 'input'
  value: string | number | null
  isUnsaved?: boolean
  editValue?: string | number | null
  onChange?: (value: string) => void
  Input?: IInputComponent
  editError?: string
}

export const InputEditValue = ({
  isUnsaved,
  editValue,
  onChange,
  Input,
  editError,
}: IInputEditValueProps) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange && onChange(e.target.value)
  }
  if (Input) {
    return (
      <TextField
        className={classNames(
          styles.value,
          styles.editInput,
          isUnsaved && styles.isUnsaved,
          editError && styles.editError
        )}
        Input={Input}
        InputProps={{ value: editValue ? `${editValue}` : '' }}
        onChange={onChange}
      />
    )
  }
  return (
    <input
      type='text'
      className={classNames(
        styles.value,
        styles.editInput,
        isUnsaved && styles.isUnsaved,
        editError && styles.editError
      )}
      value={editValue || ''}
      onChange={handleChange}
    />
  )
}
