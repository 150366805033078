import { createContext, useContext } from 'react'
import { type ConversationsPageStore } from 'pages/conversations/store'

export const ConversationsPageContext = createContext<ConversationsPageStore | null>(null)

export const useConversationsPageContext = () => {
  const context = useContext(ConversationsPageContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ConversationsPageContext')

  return context
}
