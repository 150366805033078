import { FC } from 'react'
import { EnumIconButtonShape, IconButton, IIconButtonSize } from 'shared/ui/IconButton'
import styles from './styles.module.scss'

export interface IAudionPlayButton {
  isPlaying: boolean
  onClick: () => void
  size?: IIconButtonSize
}

export const PlayAttachmentButton: FC<IAudionPlayButton> = ({ isPlaying, onClick, size }) => (
  <IconButton
    className={styles.attachmentPlayButton}
    icon={isPlaying ? 'pause1' : 'play1'}
    tooltipProps={{ label: isPlaying ? 'Pause' : 'Play', placement: 'top' }}
    fontSize={12}
    shape={EnumIconButtonShape.Rounded}
    variant='icon'
    color='noop'
    size={size}
    onClick={onClick}
    ariaLabel={`PlayAttachmentButton_${isPlaying ? 'pause' : 'play'}`}
  />
)

export const PlayDefaultButton: FC<IAudionPlayButton> = ({ isPlaying, onClick, size }) => (
  <IconButton
    icon={isPlaying ? 'pause' : 'play'}
    tooltipProps={{ label: isPlaying ? 'Pause' : 'Play', placement: 'top' }}
    transparentBackground
    shape={EnumIconButtonShape.Rounded}
    variant={'icon'}
    size={size}
    onClick={onClick}
    ariaLabel={`PlayDefaultButton_${isPlaying ? 'pause' : 'play'}`}
  />
)
