import { Route, Routes } from 'react-router'
import { BusinessProfileStepsLayout } from 'pages/settings/pages/compliance/pages/businessProfile/layout/BusinessProfileStepsLayout'
import { CompanyLocation } from 'pages/settings/pages/compliance/pages/businessProfile/pages/CompanyLocation/ui/CompanyLocation'
import { GeneralInfo } from 'pages/settings/pages/compliance/pages/businessProfile/pages/GeneralInfo/GeneralInfo'
import { BusinessInfo } from 'pages/settings/pages/compliance/pages/businessProfile/pages/BusinessInfo/BusinessInfo'
import { PeopleToContact } from 'pages/settings/pages/compliance/pages/businessProfile/pages/PeopleToContact/PeopleToContact'
import { TermsAndPrivacy } from 'pages/settings/pages/compliance/pages/businessProfile/pages/TermsAndPrivacy/ui/TermsAndPrivacy'
import { BusinessProfileAccessLayout } from 'pages/settings/pages/compliance/pages/businessProfile/layout/BusinessProfileAccessLayout'
import { BusinessProfileRoutes } from './type'

export const BusinessProfileStepsRouter = () => {
  return (
    <Routes>
      <Route element={<BusinessProfileStepsLayout />}>
        <Route element={<BusinessProfileAccessLayout />}>
          <Route path={BusinessProfileRoutes.companyLocation} element={<CompanyLocation />} />
          <Route path={BusinessProfileRoutes.generalInfo} element={<GeneralInfo />} />
          <Route path={BusinessProfileRoutes.businessInfo} element={<BusinessInfo />} />
          <Route path={BusinessProfileRoutes.peopleToContact} element={<PeopleToContact />} />
          <Route path={BusinessProfileRoutes.termsAndPrivacy} element={<TermsAndPrivacy />} />
          <Route path='*' element={<div>Page Not Found</div>} />
        </Route>
      </Route>
    </Routes>
  )
}
