import { makeAutoObservable } from 'mobx'
import { debounce } from 'lodash'
import { showToast } from 'shared/ui'
import type { IResponseGoogleAuth } from './types'

export class GoogleAuthStore {
  private _debounceError: ReturnType<typeof debounce>
  private _authTimer: NodeJS.Timer | null = null
  private _loading = false
  private _authCallback: ((payload: IResponseGoogleAuth) => void) | null = null

  constructor(authCallback: (payload: IResponseGoogleAuth) => void) {
    makeAutoObservable(this)
    this._authCallback = authCallback
    this.initAuthProcess()
    this._debounceError = debounce(this.handleError, 2000)
  }

  get loading() {
    return this._loading
  }

  handleAuthProcess = (payload: IResponseGoogleAuth) => {
    this._authCallback && this._authCallback(payload)
    this._loading = false
    this._debounceError.cancel()
    this.reset()
  }

  initAuthProcess = () => {
    window.processIncompleteRegister = async (payload: IResponseGoogleAuth) => {
      this.handleAuthProcess(payload)
    }
    window.processSocialLogin = async (payload: IResponseGoogleAuth) => {
      this.handleAuthProcess(payload)
    }
  }

  handleError = () =>
    showToast({
      type: 'warning',
      title: 'Something went wrong. Please try again...',
    })

  onAuth = async () => {
    this._loading = true

    const authWindow = window.open(
      '/app/auth/google/login?sign_up_v2=true',
      '_blank',
      'menubar=yes,location=no,toolbar=yes,scrollbars=yes,resizable=yes,directories=no,status=yes'
    )

    this._authTimer = setInterval(() => {
      if ((authWindow?.closed && this.loading) || (authWindow === null && this.loading)) {
        this.reset()
        this._debounceError()
      }
    }, 1000)
  }

  reset = () => {
    this._loading = false
    if (this._authTimer) {
      clearInterval(this._authTimer)
      this._authTimer = null
    }
  }
}
