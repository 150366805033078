import { observer } from 'mobx-react-lite'
import { Box } from 'shared/ui/Box'
import { Typography } from 'shared/ui/Typography'
import { Icon, type IIconsVector } from 'shared/ui/Icon'

export type INameValueRecordProps = {
  icon?: IIconsVector
  name?: string
  value?: string
  iconR?: IIconsVector
  ariaLabel?: string
}

export const NameValueRecord = observer(
  ({ icon, name, value, iconR, ariaLabel = 'NameValueRecord' }: INameValueRecordProps) => {
    if (!name?.trim() && !value?.trim())
      return (
        <Typography
          variant='body-md-regular'
          color='var(--content-primary-disabled'
          ariaLabel='EmptyText'
        >
          —
        </Typography>
      )

    return (
      <Box display='flex' alignItems='center' gap='8px' aria-label={ariaLabel}>
        <Icon icon={icon} fontSize={16} tertiary />
        {!!name && (
          <>
            <Typography variant='body-md-regular' ellipsis ariaLabel='name'>
              {name}
            </Typography>
          </>
        )}
        {!!name && !!value && <div className='point' />}
        {iconR && <Icon icon={iconR} fontSize={16} tertiary />}
        {value && (
          <Typography variant='body-md-regular' color='tertiary' ariaLabel='value'>
            {value}
          </Typography>
        )}
      </Box>
    )
  }
)
