import { createContext, useContext } from 'react'
import { BroadcastOneTime } from 'entities/Broadcast'

export const BroadcastDetailModalContext = createContext<BroadcastOneTime | null>(null)

export function useBroadcastDetailModalContext() {
  const context = useContext(BroadcastDetailModalContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with BroadcastDetailModalContext'
    )
  }
  return context
}
