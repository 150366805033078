import { FilterItem } from 'widgets/FilterEditor/model'

export const MultiTextTypes = new Set([
  'area_code',
  'message_received_body',
  'total_received_sms',
  'total_received_call',
  'number',
  'text',
  'url',
  'first_name',
  'last_name',
  'carrier_name',
  'email',
])

export const NumberOperators = new Set([
  'is_more_than',
  'is_less_than',
  'updated_in_last',
  'not_updated_in_last',
])

export const isMultiTextWidget = (filterItem: FilterItem) => MultiTextTypes.has(filterItem.type)
