import { makeAutoObservable } from 'mobx'

export class WithCount<T> {
  private _count: number | null = null

  constructor(private _item: T) {
    makeAutoObservable(this)
  }

  get item(): T {
    return this._item
  }

  get count() {
    return this._count
  }

  get hasCount() {
    return this._count != null
  }

  setCount = (count: number | null) => {
    this._count = count
  }
}
