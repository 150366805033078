import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router'
import { PageLayout } from 'shared/layout'
import { IMenuItemProps, Menu, Typography } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { workflowLayoutStore } from 'pages/workflows'

import styles from './styles.module.scss'

const menuItems: IMenuItemProps[] = [
  {
    type: 'nav',
    icon: 'workflows',
    to: '/workflows',
    label: (
      <Typography variant={'body-md-regular'} className={styles.label} ariaLabel={'All workflows'}>
        All workflows <span className={styles.badge}>Beta</span>
      </Typography>
    ),
  },
]

export const WorkflowLayout = observer(() => {
  const { workflows } = featureFlagsStore

  if (!workflows) return <Navigate to={'/'} />

  return (
    <PageLayout
      leftContent={<Menu title='Workflows' menuItems={menuItems} />}
      store={workflowLayoutStore}
    />
  )
})
