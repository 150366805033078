import { useEffect, useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { makeTextFieldAutoFocus, makeTextFieldClear, TextField } from 'shared/ui/TextField'
import { DropdownContent } from 'shared/ui'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactsSearch, ContactsSearchVariantEnum } from 'widgets/ContactsSearch'
import { SearchDropdownContactsStore } from 'widgets/SearchDropdown'
import styles from './styles.module.scss'

type IContactsDropdownContentProps = {
  store: SearchDropdownContactsStore
  onChange?: (item: Contact) => void
  width?: number
}

const ContactsDropdownContent: FC<IContactsDropdownContentProps> = observer(
  ({ store, width, onChange }) => {
    const [selectedTab, setSelectedTab] = useState(false)

    const handleAddItem = (item: Contact) => {
      store.selectTypeItem(item)
      onChange?.(item)
    }

    const handleSearch = (value: string) => {
      store.handleSearch(value, false)
    }

    const handleReset = () => {
      return store.resetType()
    }

    useEffect(() => {
      if (!store.hasSelectedItems) setSelectedTab(false)
    }, [store.hasSelectedItems])

    return (
      <DropdownContent noPadding className={styles.wrap} width={width}>
        <TextField
          className={classnames(styles.search, {
            [styles.closed]: !selectedTab,
          })}
          variant='integrated'
          InputProps={{
            placeholder: 'Search contact',
          }}
          onChange={handleSearch}
          value={store.search}
          rightActions={[makeTextFieldClear()]}
          mainActions={[makeTextFieldAutoFocus({ withFocus: !store.search })]}
        />
        <ContactsSearch
          search={store.search}
          onChange={handleAddItem}
          onClearSelected={handleReset}
          variant={
            store.options?.selectedMode
              ? ContactsSearchVariantEnum.checkbox
              : ContactsSearchVariantEnum.default
          }
          selectedMap={store.selectedItemsMap}
          focusedSearch
        />
      </DropdownContent>
    )
  }
)

export { ContactsDropdownContent }
