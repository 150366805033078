import { ActionItem } from 'shared/ui'
import { IAdminOrganization, IResponseAdminOrganizationById } from 'entities/Admin'
import { adminOrganizationStore } from 'pages/admin/pages/organizations'

export function getAdminOrganizationsActions({
  organization,
  organizationById,
  fromOrgDetailsPage,
}: {
  organization?: IAdminOrganization
  organizationById?: IResponseAdminOrganizationById
  fromOrgDetailsPage?: boolean
}): ActionItem[] {
  const {
    onAssumeOrganization,
    onManageCredits,
    onEditLimit,
    onToggleBan,
    onCancelSubscription,
    onSendOptOutContactReport,
    onSendEmailUsageReport,
    onEditAgencyFranchiseContract,
    onManagePaymentPlan,
    onManageTrials,
    onEdit10DLCExemptOptions,
    onUpdateTaxFree,
    getNovaOrganizationUrl,
  } = adminOrganizationStore
  const id = organization?.id || organizationById?.id
  const actions: ActionItem[] = []
  if (!id) {
    return actions
  }

  const isBanned = organizationById?.status === 'banned' || organization?.status === 'banned'
  const isTrial =
    organizationById?.stripe_data.subscription.status === 'trialing' ||
    organization?.subscription.status === 'trialing'

  if (fromOrgDetailsPage && !isBanned) {
    actions.push({
      text: 'Nova',
      icon: 'briefcase',
      link: getNovaOrganizationUrl(id),
    })
  }

  if (!isBanned) {
    actions.push({
      text: 'Assume',
      icon: 'eye',
      onAction: () => onAssumeOrganization(id),
      iconButtonProps: {
        size: 'small',
        fontSize: 16,
        ariaLabel: 'AdminOrganizationsActions_assume',
      },
    })
  }

  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Manage credits',
        icon: 'database',
        onAction: () => onManageCredits(organizationById, fromOrgDetailsPage),
      })
    }
  }

  if (fromOrgDetailsPage) {
    if (organizationById) {
      actions.push({
        text: `${organizationById.status === 'banned' ? 'Unban' : 'Ban'} organization`,
        onAction: () => onToggleBan(organizationById),
      })
    }
  }

  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Cancel subscription',
        onAction: () => onCancelSubscription(organizationById),
      })
    }
  }

  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Edit limits',
        onAction: () => onEditLimit(organizationById),
      })
    }
  }

  if (fromOrgDetailsPage && isTrial && organizationById) {
    actions.push({
      text: 'Manage trial',
      onAction: () => onManageTrials(organizationById),
    })
  }

  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Send opt out contact report',
        onAction: () => onSendOptOutContactReport(organizationById),
      })
    }
  }
  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Send email usage report',
        onAction: () => onSendEmailUsageReport(organizationById),
      })
    }
  }
  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Edit agency / Franchise',
        onAction: () => onEditAgencyFranchiseContract(organizationById),
      })
    }
  }
  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Edit 10DLC exempt options',
        onAction: () => onEdit10DLCExemptOptions(organizationById),
      })
    }
  }
  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Manage subscription',
        onAction: () => onManagePaymentPlan(organizationById),
      })
    }
  }
  if (fromOrgDetailsPage && !isBanned) {
    if (organizationById) {
      actions.push({
        text: 'Set Tax Free',
        onAction: () => onUpdateTaxFree(organizationById),
      })
    }
  }
  return actions
}
