import { ReactNode } from 'react'
import { makeAutoObservable, reaction, IReactionDisposer } from 'mobx'
import { IDropdownItem, IDropdownProps } from 'shared/ui'
import { Contact } from 'entities/Contacts/model/Contact'
import { Tag } from 'entities/Tags/model/Tag'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { User } from 'entities/Users/model/User'
import { Segment } from 'entities/Segment/model/Segment'
import {
  IActionSearch,
  IMainDropdownContentItem,
  ISearchDropdownStoreConfig,
  ISearchDropdownStoreOptions,
  ISearchTypeItems,
  mainDropdownContentItems,
  SearchDropdownAssigneeStore,
  SearchDropdownContactsStore,
  SearchDropdownDateStore,
  SearchDropdownInboxesStore,
  SearchDropdownSegmentStore,
  SearchDropdownTagsStore,
  SearchTypesEnum,
} from 'widgets/SearchDropdown'

export class SearchDropdownStore {
  private _isOpen = false
  private _currentType: SearchTypesEnum = SearchTypesEnum.main
  private _isShowMore = false
  private _search = ''

  private _searchDropdownTagsStore = new SearchDropdownTagsStore()
  private _searchDropdownAssigneeStore = new SearchDropdownAssigneeStore()
  private _searchDropdownInboxesStore = new SearchDropdownInboxesStore()
  private _searchDropdownContactsStore = new SearchDropdownContactsStore()
  private _searchDropdownDateStore = new SearchDropdownDateStore()
  private _searchDropdownSegmentStore = new SearchDropdownSegmentStore()

  private _options: ISearchDropdownStoreOptions | null = null
  private _selectedTypesMap: Map<string, SearchTypesEnum> = new Map()
  private _newType: SearchTypesEnum | null = null
  private _isDirtyFilter = false

  private _includeItemsType: SearchTypesEnum[] = []
  private _dropdownTriggerComponent: ((open: boolean) => ReactNode) | null = null
  private _dropdownCustomComponent:
    | ((onCloseMenu: () => void, dropdownId: string) => ReactNode)
    | null = null
  private _dropdownProps: IDropdownProps | null = null
  private _placeholder = ''

  private _disposeIsShowMore: IReactionDisposer | null = null
  private _disposeIsOpen: IReactionDisposer | null = null

  constructor(config: ISearchDropdownStoreConfig) {
    makeAutoObservable(this)

    this._includeItemsType = config.includeItemsType
    this._dropdownTriggerComponent = config.dropdownTriggerComponent || null
    this._dropdownCustomComponent = config.dropdownCustomComponent || null
    this._dropdownProps = config.dropdownProps || null
    this._placeholder = config.placeholder || 'Search'

    this.reactionIsShowMore()
    this.reactionIsOpen()
  }

  initOptions = (options: ISearchDropdownStoreOptions) => {
    this._options = options
    this._searchDropdownDateStore.initOptions(options)
    this._searchDropdownAssigneeStore.initOptions(options)
    this._searchDropdownTagsStore.initOptions(options)
    this._searchDropdownInboxesStore.initOptions(options)
    this._searchDropdownContactsStore.initOptions(options)
    this._searchDropdownSegmentStore.initOptions(options)
  }

  get selectedTypes() {
    return Array.from(this._selectedTypesMap.values())
  }

  get filtersCount() {
    return this.selectedTypes.length
  }

  get hasFilters() {
    return !!this.filtersCount
  }

  get hasAllFilters() {
    return this.filtersCount === 5
  }

  get hasAllSearchFilters() {
    return this.filtersCount === 4
  }

  get hasSearch() {
    return this._search.length > 0
  }

  get hasTagsType() {
    return this.selectedTypes.indexOf(SearchTypesEnum.tags) !== -1
  }

  get hasAssigneeType() {
    return this.selectedTypes.indexOf(SearchTypesEnum.members) !== -1
  }

  get hasContactsType() {
    return this.selectedTypes.indexOf(SearchTypesEnum.contacts) !== -1
  }

  get hasTeamsType() {
    return this.selectedTypes.indexOf(SearchTypesEnum.teams) !== -1
  }

  get hasSegmentsType() {
    return this.selectedTypes.indexOf(SearchTypesEnum.segment) !== -1
  }

  get isDefaultType() {
    return this._currentType === SearchTypesEnum.main
  }

  get searchParams() {
    const tags = Array.from(this._searchDropdownTagsStore.selectedItemsMapIds).map(
      (item) => item[1]
    )
    const teams = Array.from(this._searchDropdownInboxesStore.selectedItemsMapIds).map(
      (item) => item[1]
    )
    const members = Array.from(this._searchDropdownAssigneeStore.selectedItemsMapIds).map(
      (item) => item[1]
    )
    const contacts = this._searchDropdownContactsStore.selectedItems.map((contact) => contact.id)
    const { searchParams } = this._searchDropdownDateStore
    let params = {
      tags,
      teams,
      members,
      contacts,
    }

    if (searchParams) {
      params = {
        ...params,
        ...searchParams,
      }
    }

    return params
  }

  get hasSearchParams() {
    if (this._searchDropdownTagsStore.selectedItemsMapIds.size !== 0) return true
    if (this._searchDropdownContactsStore.selectedItemsMap.size !== 0) return true
    if (this._searchDropdownInboxesStore.selectedItemsMapIds.size !== 0) return true
    if (this._searchDropdownAssigneeStore.selectedItemsMapIds.size !== 0) return true
    if (this._searchDropdownDateStore.hasDate) return true

    return !!this._search
  }

  get itemsList(): IMainDropdownContentItem[] {
    return this._includeItemsType.reduce<IMainDropdownContentItem[]>((state, type) => {
      const item = mainDropdownContentItems.find((item) => item.type === type)

      if (item) {
        state.push(item)
      }

      return state
    }, [])
  }

  get items(): IDropdownItem[] {
    return this.itemsList.map((item) => ({
      ...item,
      iconL: item.icon,
    }))
  }

  deleteType = (type: SearchTypesEnum) => {
    this.resetType(type)
    this._selectedTypesMap.delete(type)
  }

  isSelected = (type: SearchTypesEnum, id: number | undefined) => {
    if (!id) return false

    switch (type) {
      case SearchTypesEnum.tags:
        return this._searchDropdownTagsStore.isSelected(id)
      case SearchTypesEnum.teams:
        return this._searchDropdownInboxesStore.isSelected(id)
      case SearchTypesEnum.members:
        return this._searchDropdownAssigneeStore.isSelected(id)
      case SearchTypesEnum.contacts:
        return this._searchDropdownContactsStore.isSelected(id)
      default:
        return false
    }
  }

  resetType(type: SearchTypesEnum) {
    switch (type) {
      case SearchTypesEnum.tags:
        this._searchDropdownTagsStore.resetType()
        break
      case SearchTypesEnum.teams:
        this._searchDropdownInboxesStore.resetType()
        break
      case SearchTypesEnum.members:
        this._searchDropdownAssigneeStore.resetType()
        break
      case SearchTypesEnum.contacts:
        this._searchDropdownContactsStore.selectedItemsMap.clear()
        break
      case SearchTypesEnum.date:
        this._searchDropdownDateStore.reset()
        break
    }

    this._options?.resetSearch?.()
  }

  toggleTypeItem = (item: ISearchTypeItems) => {
    if (item instanceof Contact) {
      this._searchDropdownContactsStore.toggleTypeItem(item)
    }

    if (item instanceof Tag) {
      this._searchDropdownTagsStore.toggleTypeItem(item)
    }

    if (item instanceof Inbox) {
      this._searchDropdownInboxesStore.toggleTypeItem(item)
    }

    if (item instanceof User) {
      this._searchDropdownAssigneeStore.toggleTypeItem(item)
    }

    if (item instanceof Segment) {
      this._searchDropdownSegmentStore.toggleTypeItem(item)
    }

    this._options?.toggleTypeItem?.(item)
  }

  setIsDirtyFilter = (value: boolean) => {
    this._isDirtyFilter = value
  }

  setPlaceholder = (value: string) => {
    this._placeholder = value
  }

  actionSearch = ({ value, exclude }: IActionSearch) => {
    if (this._includeItemsType.includes(SearchTypesEnum.tags)) {
      exclude !== 'tags' && this._searchDropdownTagsStore.handleSearch(value)
    }

    if (this._includeItemsType.includes(SearchTypesEnum.teams)) {
      exclude !== 'inboxes' && this._searchDropdownInboxesStore.handleSearch(value)
    }

    if (this._includeItemsType.includes(SearchTypesEnum.members)) {
      exclude !== 'assignee' && this._searchDropdownAssigneeStore.handleSearch(value)
    }

    if (this._includeItemsType.includes(SearchTypesEnum.contacts)) {
      exclude !== 'contacts' && this._searchDropdownContactsStore.handleSearch(value)
    }

    if (this._includeItemsType.includes(SearchTypesEnum.segment)) {
      exclude !== 'segment' && this._searchDropdownSegmentStore.handleSearch(value)
    }

    this.handleSearch(value)
  }

  actionSearchAll = (value: string) => {
    if (this._includeItemsType.includes(SearchTypesEnum.tags)) {
      this._searchDropdownTagsStore.handleSearch(value)
    }

    if (this._includeItemsType.includes(SearchTypesEnum.teams)) {
      this._searchDropdownInboxesStore.handleSearch(value)
    }

    if (this._includeItemsType.includes(SearchTypesEnum.members)) {
      this._searchDropdownAssigneeStore.handleSearch(value)
    }

    if (this._includeItemsType.includes(SearchTypesEnum.contacts)) {
      this._searchDropdownContactsStore.handleSearch(value)
    }

    if (this._includeItemsType.includes(SearchTypesEnum.segment)) {
      this._searchDropdownSegmentStore.handleSearch(value)
    }

    this.handleSearch(value)
  }

  onToggle = (open: boolean) => {
    if (open) this.reset()

    this._isOpen = open
  }

  setOpen = (open: boolean) => {
    this._isOpen = open
  }

  clearFilters = () => {
    this._searchDropdownTagsStore.selectedItemsMapIds.clear()
    this._searchDropdownContactsStore.selectedItemsMap.clear()
    this._searchDropdownInboxesStore.selectedItemsMapIds.clear()
    this._searchDropdownAssigneeStore.selectedItemsMapIds.clear()
    this._searchDropdownSegmentStore.selectedItemsMapIds.clear()
    this._selectedTypesMap.clear()
    this.resetAll()
  }

  resetReactions = () => {
    this._disposeIsOpen?.()
    this._disposeIsShowMore?.()
  }

  resetAll = () => {
    this.resetReactions()
    this._searchDropdownTagsStore.reset()
    this._searchDropdownAssigneeStore.reset()
    this._searchDropdownInboxesStore.reset()
    this._searchDropdownContactsStore.reset()
    this._searchDropdownSegmentStore.reset()
    this._searchDropdownDateStore.reset()
    this.reset()
  }

  reset = () => {
    this._search = ''
    this._currentType = SearchTypesEnum.main
    this._isOpen = false
  }

  init = () => {
    this._options?.resetSearch?.()
  }

  setNewType = (type: SearchTypesEnum | null) => {
    this._newType = type
  }

  setIsShowMore = (value: boolean) => {
    if (this._options?.setIsShowMore) {
      this._options?.setIsShowMore(value)
    } else {
      this._isShowMore = value
    }
  }

  selectTypeParent = (type: SearchTypesEnum) => {
    this.setNewType(type)
    this._selectedTypesMap.set(type, type)
  }

  selectType = (type: SearchTypesEnum) => {
    this._search = ''
    this._currentType = type
    this._options?.selectType?.(type)
  }

  handleSearch = (value: string) => {
    this._search = value
  }

  reactionIsShowMore = () => {
    this._disposeIsShowMore?.()
    this._disposeIsShowMore = reaction(
      () => this._isShowMore,
      (value) => {
        this._searchDropdownInboxesStore.setIsShowMore(value)
      }
    )
  }

  reactionIsOpen = () => {
    this._disposeIsOpen?.()
    this._disposeIsOpen = reaction(
      () => this._isOpen,
      (value) => {
        this._searchDropdownInboxesStore.setIsOpen(value)
      }
    )
  }

  get options() {
    return this._options
  }

  get isOpen() {
    return this._isOpen
  }

  get isShowMore() {
    return this._isShowMore
  }

  get search() {
    return this._search
  }

  get currentType() {
    return this._currentType
  }

  get placeholder() {
    return this._placeholder
  }

  get dropdownProps() {
    return this._dropdownProps
  }

  get dropdownTriggerComponent() {
    return this._dropdownTriggerComponent
  }

  get dropdownCustomComponent() {
    return this._dropdownCustomComponent
  }

  get isDirtyFilter() {
    return this._isDirtyFilter
  }

  get newType() {
    return this._newType
  }

  get searchDropdownContactsStore() {
    return this._searchDropdownContactsStore
  }

  get searchDropdownSegmentStore() {
    return this._searchDropdownSegmentStore
  }

  get searchDropdownAssigneeStore() {
    return this._searchDropdownAssigneeStore
  }

  get searchDropdownDateStore() {
    return this._searchDropdownDateStore
  }

  get searchDropdownTagsStore() {
    return this._searchDropdownTagsStore
  }

  get searchDropdownInboxesStore() {
    return this._searchDropdownInboxesStore
  }

  get includeItemsType() {
    return this._includeItemsType
  }
}
