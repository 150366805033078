import React from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown, DropdownContent } from 'shared/ui'
import { ContactsSearch } from 'widgets/ContactsSearch'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'
import styles from './styles.module.scss'

export const ConversationSearchDropDown = observer(() => {
  const {
    handleCloseDropDown,
    openDropDown,
    search,
    handleAddItem,
    handleAutomaticallyCreateNewContact,
    automaticallyCreateNewContact,
    setAlert,
    setIsFirstEditMode,
  } = useConversationNewSearchContext()

  const onErrorCreate = (onCloseMenu: () => void) => () => {
    onCloseMenu()
  }

  return (
    <div className={styles.wrap}>
      <Dropdown
        ariaLabel={'ConversationSearchDropDown'}
        show={openDropDown}
        placement={'bottom-start'}
        triggerComponent={() => null}
        customComponent={(onCloseMenu) => (
          <DropdownContent>
            <ContactsSearch
              search={search}
              onChange={(contact, params) => {
                if (params?.isNew) {
                  setAlert({
                    type: 'success',
                    desc: 'Contact created',
                  })
                  setIsFirstEditMode(true)
                }
                handleAddItem(contact)
              }}
              onErrorCreate={onErrorCreate(onCloseMenu)}
              isAutomaticallyCreateNewContact={automaticallyCreateNewContact}
            />
          </DropdownContent>
        )}
        onClose={() => {
          handleCloseDropDown()
          handleAutomaticallyCreateNewContact()
        }}
        width={480}
        margin={'7px'}
      />
    </div>
  )
})
