import { useEffect, useLayoutEffect } from 'react'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { PageLayout } from 'shared/layout'
import { conversationStore } from 'entities/Conversation'
import { openConversationByParams } from 'features/OpenConversation/openConversation'
import { ConversationMenu } from 'widgets/ConversationMenu'
import { useConversationsPageContext } from 'pages/conversations/context'

export const ConversationsLayoutContent = () => {
  const { conversationId } = useParams()
  const store = useConversationsPageContext()
  const { pageLayoutStore, conversationMenuStore } = store

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const contact_ids = params.getAll('contact_id')?.map((id) => Number(id))
  const inbox_id = Number(params.get('inbox_id'))

  useEffect(() => {
    if (contact_ids && inbox_id) {
      openConversationByParams(inbox_id, contact_ids).then(() => {
        store.emitOpenConversationSignal()
      })
    }
  }, [])

  useLayoutEffect(() => {
    conversationStore.setModeNotOpen(conversationId === 'null')

    return () => {
      conversationStore.setCurrentItemId(null)
    }
  }, [])

  useLayoutEffect(() => {
    const id = conversationId !== 'null' ? Number(conversationId) : null

    conversationStore.setCurrentItemId(id)
  }, [conversationId])

  return (
    <PageLayout
      store={pageLayoutStore}
      leftContent={<ConversationMenu store={conversationMenuStore} />}
      mobileWithSizeM
    />
  )
}
