import { createContext, useContext } from 'react'
import { type ContactsProfileCardStore } from 'widgets/ContactsDetails/ui/ContactsProfileCard'

export const ContactsProfileCardContext = createContext<ContactsProfileCardStore | null>(null)

export function useContactsProfileCardContext() {
  const context = useContext(ContactsProfileCardContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ContactsProfileCardStoreProvider'
    )
  }
  return context
}
