import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.scss'

export type ISpaceProps = {
  height?: number
}

const Space: React.FC<ISpaceProps> = ({ height = 16 }) => {
  return <div className={classnames(styles.wrap)} style={{ height: `${height}px` }} />
}

export { Space }
