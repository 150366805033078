import { observer } from 'mobx-react-lite'
import {
  useEventIntegrationConnected,
  useEventIntegrationDisconnected,
} from 'entities/Integrations'
import { useEventOrganizationCalling, useEventUpdateBalance } from 'entities/Organization'
import { useEventInboxCalling } from 'entities/Inbox'
import { useEventMessagePopper } from 'entities/Message'
import {
  useEventConversationContactOptedIn,
  useEventConversationContactOptedOut,
  useEventConversationReassigned,
} from 'entities/Conversation'
import { useEventContactUpdated } from 'entities/Contacts'
import { useEventClientDraft } from 'entities/Conversation/events/EventClientDraft'
import { useEventMessageDeletedDraft } from 'entities/Conversation/events/EventMessageDeletedDraft'
import { useEventInboxUpdate } from 'entities/Inbox/events/EventInboxUpdate'
import { useEventInboxDeleted } from 'entities/Inbox/events/EventInboxDeleted'
import { useEventInboxCreated } from 'entities/Inbox/events/EventInboxCreated'
import { useEventMessagePopperLog } from 'entities/DataLog/events/EventMessagePopperLog'
import { useEventMessageReceivedNewLog } from 'entities/DataLog/events/EventMessageReceivedNewLog'
import { useEventConversationUnreadLog } from 'entities/DataLog/events/EventConversationUnreadLog'
import { useEventConversationStateChanged } from 'entities/Conversation/events/EventConversationStateChanged'

export const GlobalEvents = observer(() => {
  useEventClientDraft()
  useEventConversationStateChanged()
  useEventMessageDeletedDraft()
  useEventConversationUnreadLog()
  useEventMessagePopperLog()
  useEventMessageReceivedNewLog()
  useEventInboxCreated()
  useEventInboxDeleted()
  useEventInboxUpdate()
  useEventIntegrationConnected()
  useEventIntegrationDisconnected()
  useEventUpdateBalance()
  useEventOrganizationCalling()
  useEventInboxCalling()
  useEventConversationContactOptedOut()
  useEventConversationContactOptedIn()
  useEventConversationReassigned()
  useEventMessagePopper()
  useEventContactUpdated()

  return null
})
