// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MNNgvz7aYCb2Er_OgjRR{height:32px;display:flex;justify-content:space-between;align-items:center;padding:8px;border-radius:6px;cursor:pointer}.MNNgvz7aYCb2Er_OgjRR:hover{background-color:var(--background-neutral-subtle)}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/organizations/modals/AdminUpdateTaxFree/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CAEA,4BACE,iDAAA","sourcesContent":[".wrap {\n  height: 32px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px;\n  border-radius: 6px;\n  cursor: pointer;\n\n  &:hover {\n    background-color: var(--background-neutral-subtle);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "MNNgvz7aYCb2Er_OgjRR"
};
export default ___CSS_LOADER_EXPORT___;
