// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cScwEb2niUlR4o8LQ1jr{display:block;width:8px;height:8px;border-radius:8px;margin:0 8px 0 8px}.cKBB56_KZArRaYywqa4v{height:32px;display:flex;align-items:center}.S4yTFBJVJx_3QDkoED5S{display:flex;align-items:center;margin:0 8px 0 8px;position:relative;width:18px}.S4yTFBJVJx_3QDkoED5S .rtO8csvAHdjoChloCiZ2{position:absolute;display:block;width:10px;height:10px;border-radius:8px;border:2px var(--background-primary-inverted-primary) solid}.cODCyjFmU1MhJFVXTeKJ{color:var(--content-primary-primary);line-height:normal;font-weight:400;font-size:13px}", "",{"version":3,"sources":["webpack://./src/widgets/SearchDropdown/ui/SelectedElement/TagElement/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,SAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,UAAA,CAEA,4CACE,iBAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,2DAAA,CAIJ,sBACE,oCAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".color {\n  display: block;\n  width: 8px;\n  height: 8px;\n  border-radius: 8px;\n  margin: 0 8px 0 8px;\n}\n.countLabelWrapper {\n  height: 32px;\n  display: flex;\n  align-items: center;\n}\n.countColors {\n  display: flex;\n  align-items: center;\n  margin: 0 8px 0 8px;\n  position: relative;\n  width: 18px;\n\n  .countColor {\n    position: absolute;\n    display: block;\n    width: 10px;\n    height: 10px;\n    border-radius: 8px;\n    border: 2px var(--background-primary-inverted-primary) solid;\n  }\n}\n\n.countLabel {\n  color: var(--content-primary-primary);\n  line-height: normal;\n  font-weight: 400;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color": "cScwEb2niUlR4o8LQ1jr",
	"countLabelWrapper": "cKBB56_KZArRaYywqa4v",
	"countColors": "S4yTFBJVJx_3QDkoED5S",
	"countColor": "rtO8csvAHdjoChloCiZ2",
	"countLabel": "cODCyjFmU1MhJFVXTeKJ"
};
export default ___CSS_LOADER_EXPORT___;
