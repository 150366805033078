import { makeAutoObservable, reaction } from 'mobx'
import { nanoid } from 'nanoid'

import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalType, ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import { ContactsApi } from 'entities/Contacts'
import { History } from '../model/History'
import { HistoryModalContent } from '../ui/HistoryModalContent'

interface IIModalProps {
  title: string
  width: number
  desc?: ModalType['desc']
  ModalContent?: ModalType['ModalContent']
}

const defaultPagination = {
  page: 1,
  limit: 10,
}

class ContactsHistoryStore {
  histories: Map<number, History> = new Map()
  deletedHistories: Map<number, History> = new Map()
  total = 0
  modalId = ''
  paginationData = defaultPagination
  loading = false

  constructor() {
    makeAutoObservable(this)

    this.reactionChangePagination()
  }

  get historyData() {
    return Array.from(this.histories.values())
  }

  get totalHistory() {
    return this.total
  }

  get pagination() {
    return this.paginationData
  }

  get isLoading() {
    return this.loading
  }

  get isNoImportsYet() {
    return !this.loading && !this.histories.size
  }

  get pendingId() {
    const pendingHistory = this.historyData.find(
      (history) => history.createdAt && history.finishedAt === null
    )

    return this.historyData.length === 1 && pendingHistory ? pendingHistory.id : null
  }

  setIsLoading = (status: boolean) => {
    this.loading = status
  }

  resetHistory = () => {
    this.paginationData = defaultPagination
    this.histories = new Map()
    this.total = 0
    this.deletedHistories.clear()
  }

  getDuplicateReasons = (id: number) => {
    return this.histories.get(id)?.duplicateReasons || []
  }

  getSkippedReasons = (id: number) => {
    return this.histories.get(id)?.skippedReasons || []
  }

  addHistory = (history: History) => {
    this.histories.set(history.id, history)
  }

  getImportContactProgress = async (id: number) => {
    try {
      const { data: history } = await ContactsApi.getImportContactProgress(id)
      const newHistory = new History(history)

      this.addHistory(newHistory)

      if (newHistory.startedAt && !newHistory.finishedAt) {
        setTimeout(() => {
          this.getImportContactProgress(id)
        }, 60 * 1000)
      }
    } catch (e) {
      console.error(e)
    }
  }

  resetHistories = () => {
    this.histories.clear()
  }

  loadImportHistory = async () => {
    try {
      this.setIsLoading(true)
      const { data } = await ContactsApi.loadImportHistory(this.paginationData)

      this.total = data.meta.total

      this.resetHistories()

      data.data.forEach((history) => {
        this.addHistory(new History(history))
      })
    } finally {
      this.setIsLoading(false)
    }
  }

  deleteHistoryLocal = (id: number) => {
    this.histories.delete(id)
  }

  deleteHistory = (history: History) => {
    this.deletedHistories.set(history.id, history)

    const timeoutId = setTimeout(async () => {
      await ContactsApi.deleteImportHistory(history.id)
      this.deletedHistories.delete(history.id)
      this.total = this.total - 1
    }, 5000)

    this.deleteHistoryLocal(history.id)

    const toastId = nanoid()

    toastStore.add({
      id: toastId,
      title: 'Import record deleted',
      type: 'info',
      action: {
        text: 'Undo',
        onAction: () => {
          clearTimeout(timeoutId)
          const deletedHistory = this.deletedHistories.get(history.id)
          if (deletedHistory) {
            this.addHistory(deletedHistory)

            const sortedHistories = this.historyData.sort((a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            })

            this.histories = new Map(sortedHistories.map((history) => [history.id, history]))
            this.deletedHistories.delete(history.id)
            toastStore.remove(toastId)
          }
        },
      },
    })
  }

  handleClose = () => {
    modalStore.removeModal(this.modalId)
  }

  openModal = (props: IIModalProps) => {
    modalStore.addModal({
      id: this.modalId,
      type: ModalTypeList.DEFAULT,
      showHeader: true,
      showCloseButton: false,
      showCloseIcon: true,
      ...props,
    })
  }

  openDuplicateModal = async (id: number) => {
    this.modalId = 'duplicate_modal'

    this.openModal({
      title: 'Duplicate numbers',
      width: 520,
      ModalContent: () => <HistoryModalContent data={this.getDuplicateReasons(id)} />,
    })
  }

  openSkippedModal = async (id: number) => {
    this.modalId = 'skipped_modal'

    this.openModal({
      title: 'Skipped numbers',
      width: 520,
      ModalContent: () => <HistoryModalContent data={this.getSkippedReasons(id)} />,
    })
  }

  changePagination = (page: number, limit: number) => {
    this.paginationData = {
      page,
      limit,
    }
  }

  reactionChangePagination = () => {
    reaction(
      () => this.paginationData,
      () => {
        this.loadImportHistory()
      }
    )
  }
}

export const contactsHistoryStore = new ContactsHistoryStore()
