import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { IChatbotFullData } from 'entities/Chatbot'

import { DetailsProvider } from './detailsContext'
import { DetailsContent, DetailsNotFound } from './DetailsContent'

export interface IChatbotDetailsProps {
  futureData: Promise<IChatbotFullData | null>
  cleanup: () => void
}

export const ChatbotDetails = observer(({ futureData, cleanup }: IChatbotDetailsProps) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IChatbotFullData | null>(null)

  useEffect(() => {
    futureData.then((data) => {
      setData(data)
      setLoading(false)
    })

    return cleanup
  }, [])

  if (loading) return <SpinnerLoader />
  if (!data) return <DetailsNotFound />

  return (
    <DetailsProvider data={data}>
      <DetailsContent />
    </DetailsProvider>
  )
})
