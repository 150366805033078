import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown, DropdownContent, SpinnerLoader } from 'shared/ui'
import { makeTextFieldAutoFocus, makeTextFieldClear, TextField } from 'shared/ui/TextField'
import { CreateTagButton } from 'entities/Tags'
import type { IWidgetProps } from 'widgets/FilterEditor/types'
import { TagItem } from './TagItem'
import { TagControl } from './TagControl'
import styles from './styles.module.scss'

export const WidgetAsyncTags = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const control = useMemo(() => new TagControl(), [])

    const [term, setTerm] = useState('')
    const [isActive, setActive] = useState(false)
    const [showValuesWarning, setShowValuesWarning] = useState(false)

    const filterValuesIds = useMemo(
      () => (Array.isArray(filterItem.payload.value) ? filterItem.payload.value.map(Number) : []),
      [filterItem.payload.value]
    )

    const isSearchLoading = control.termItemsEmpty && control.loading
    const isSearchHasMore = !control.termItemsEmpty && control.hasMore
    const isSearchNoResults = control.termItemsEmpty && !control.loading

    const handleClearAll = () => {
      filterItem.payload.clear()
      control.clearSelection()
      setTerm('')
    }

    const handleLoadMore = useCallback(() => {
      const loadMore = isActive && !control.loading && control.hasMore

      if (loadMore) control.loadMore(true)
    }, [isActive, control.loading, control.hasMore])

    const handleToggleTag = useCallback((key: number) => {
      control.toggleTag(key)
      filterItem.payload.updateHard({ value: control.selectedItems.map(({ key }) => String(key)) })
    }, [])

    const handleCreateTag = useCallback(
      (term: string) =>
        control.createTag(term).then((tag) => {
          if (!tag) return

          setTerm('')
          filterItem.payload.updateHard({
            value: [...filterValuesIds, tag.key].map(String),
          })
        }),
      [filterValuesIds]
    )

    useEffect(() => {
      if (isActive) {
        control.syncTags(filterValuesIds).then(() => control.loadMore(true))
      } else {
        setTimeout(() => {
          control.reset()
          setTerm('')
        }, 100)
      }
    }, [isActive])

    useEffect(() => {
      setTimeout(() => setActive(expandByDefault), 100)
    }, [expandByDefault])

    useEffect(() => {
      if (!isActive && !filterValuesIds.length) return setShowValuesWarning(true)
      if (isActive) return setShowValuesWarning(false)
    }, [isActive, filterValuesIds])

    useEffect(() => {
      if (!isActive) return
      control.searchTags(term, !term)
    }, [term])

    const getTriggerText = () => {
      if (!filterValuesIds?.length) return 'select'

      return (
        <span className={styles.countLabelWrapper}>
          {Boolean(filterValuesIds.slice(0, 3).length) && (
            <div className={styles.countColors}>
              {filterValuesIds.slice(0, 3).map((item, index) => (
                <span key={item} className={styles.countColor} style={{ left: index * 6 }}></span>
              ))}
            </div>
          )}
          <span className={styles.countLabel}>
            {filterValuesIds.length} {filterValuesIds.length === 1 ? 'tag' : 'tags'}
          </span>
        </span>
      )
    }

    return (
      <Dropdown
        ariaLabel={`AsyncDropdown_${filterItem.config.name}`}
        placement='bottom-start'
        show={isActive}
        onChangeOpen={setActive}
        className={styles.operatorDropdown}
        parentsDropdownId={parentsDropdownId}
        triggerComponent={() => (
          <div
            className={classNames(
              styles.selectTriggerComponent,
              showValuesWarning && styles.warning
            )}
          >
            {getTriggerText()}
          </div>
        )}
        customComponent={() => (
          <DropdownContent className={styles.dropdownContent}>
            {control.initialLoading ? (
              <SpinnerLoader className={styles.spinner} />
            ) : (
              <>
                <div className={styles.asyncDropdownHead}>
                  <TextField
                    variant='integrated'
                    size='small'
                    value={term}
                    onChange={setTerm}
                    InputProps={{ placeholder: `Search ${filterItem.config.name}` }}
                    rightActions={[makeTextFieldClear()]}
                    mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
                  />
                </div>
                <div className={styles.asyncContent}>
                  <div className={styles.asyncContentHighlight}>
                    {control.syncedTags.map((tag) => (
                      <TagItem
                        tag={tag}
                        key={tag.key}
                        control={control}
                        onChange={handleToggleTag}
                      />
                    ))}
                  </div>
                  {control.termTags.map((tag) => (
                    <TagItem tag={tag} key={tag.key} control={control} onChange={handleToggleTag} />
                  ))}
                  {isSearchLoading && <SpinnerLoader className={styles.spinner} />}
                  <InView onChange={(inView) => inView && handleLoadMore()}>
                    {({ ref }) => (
                      <div ref={ref}>
                        {isSearchHasMore && <SpinnerLoader className={styles.spinner} />}
                        {isSearchNoResults &&
                          (!!term ? (
                            <CreateTagButton term={term} onClick={() => handleCreateTag(term)} />
                          ) : (
                            <div className={styles.notFound}>No results found Create tag!</div>
                          ))}
                      </div>
                    )}
                  </InView>
                </div>
                {!!filterValuesIds.length && (
                  <div className={styles.asyncDropdownFooter}>
                    <Button
                      text='Clear'
                      contained='secondary'
                      typeBtn='text'
                      disabled={control.loading}
                      onClick={handleClearAll}
                    />
                  </div>
                )}
              </>
            )}
          </DropdownContent>
        )}
      />
    )
  }
)
