// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sfiq54E_Itc1WUITHCLG{max-width:312px;display:grid;gap:4px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageCard/ui/VideoContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".wrap{\n  max-width: 312px;\n  display: grid;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Sfiq54E_Itc1WUITHCLG"
};
export default ___CSS_LOADER_EXPORT___;
