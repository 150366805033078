import classNames from 'classnames'
import { type ITypographyProps } from 'shared/ui'
import { Typography } from 'shared/ui/Typography'
import { Icon } from 'shared/ui/Icon'
import { type IStatusProps } from 'shared/ui/Status/type'
import styles from './styles.module.scss'

export const Status = ({
  title,
  intent,
  emphasis,
  icon,
  size,
  className = '',
  onlyIcon,
}: IStatusProps) => {
  const typographyVariant = (): ITypographyProps['variant'] => {
    if (size === 'small') return 'body-xs-medium'
    if (size === 'label') return 'body-xs-medium'

    return 'body-md-regular'
  }

  return (
    <div
      className={classNames(
        styles.wrap,
        styles[`intent--${intent}`],
        styles[`emphasis--${emphasis}`],
        styles[`size--${size}`],
        className,
        {
          [styles['not-icon']]: !Boolean(icon),
        }
      )}
    >
      {icon && <Icon icon={icon} fontSize={14} />}
      {!onlyIcon && (
        <Typography
          variant={typographyVariant()}
          className={styles.title}
          pointer
          ariaLabel={'title'}
        >
          {title}
        </Typography>
      )}
    </div>
  )
}
