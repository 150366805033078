// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gXMnxHR7ATRKut_utYKQ{margin-top:4px}", "",{"version":3,"sources":["webpack://./src/shared/ui/TextFieldWithLabel/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".error {\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "gXMnxHR7ATRKut_utYKQ"
};
export default ___CSS_LOADER_EXPORT___;
