// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yBaEZN3FZSP_XNx4Bgu_{position:relative;display:flex;flex-grow:1;margin-left:16px}.OXu8uyEw15IThNiyhbpJ{display:flex;align-items:center}.aYLslTGhZNvrYgF2V_Jk{margin:0 4px 0 12px}.f9Yq3q5XebJ49nkHzhM5{padding:0;color:var(--blue-50)}.iTg3jbmN3UMzrPYrEV1T{word-break:break-word}", "",{"version":3,"sources":["webpack://./src/widgets/TriggerList/ui/TriggersTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,mBAAA,CAGF,sBACE,SAAA,CACA,oBAAA,CAGF,sBACE,qBAAA","sourcesContent":[".wrap {\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  margin-left: 16px;\n}\n\n.nameCell {\n  display: flex;\n  align-items: center;\n}\n\n.triggerName {\n  margin: 0 4px 0 12px;\n}\n\n.reconnectButton {\n  padding: 0;\n  color: var(--blue-50);\n}\n\n.tooltipText {\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "yBaEZN3FZSP_XNx4Bgu_",
	"nameCell": "OXu8uyEw15IThNiyhbpJ",
	"triggerName": "aYLslTGhZNvrYgF2V_Jk",
	"reconnectButton": "f9Yq3q5XebJ49nkHzhM5",
	"tooltipText": "iTg3jbmN3UMzrPYrEV1T"
};
export default ___CSS_LOADER_EXPORT___;
