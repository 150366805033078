import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { showToast } from 'shared/ui'
import { errorHandler } from 'shared/api'
import type { IAlert } from 'shared/ui/Alert/types'
import { logger } from 'shared/lib'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { type IUser, usersStore } from 'entities/Users'
import {
  ContactsApi,
  contactsStore,
  type IResponseCustomField,
  CustomFieldTypes,
} from 'entities/Contacts'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { ContactsDetailsStore } from 'widgets/ContactsDetails'
import { PhoneFieldSearchStore } from 'widgets/ContactsDetails/ui/ContactsInfoForm/ui/PhoneFieldSearch/store/phoneFieldSearchStore'

export enum MainFiledTypes {
  firstName = 'first_name',
  lastName = 'last_name',
  email = 'email',
  number = 'number',
  owner = 'owner',
}

export type IMainFields = {
  first_name: string
  last_name: string
  email: string
  number: string
  owner: IUser | null
}

type IContactInfoFormStoreConfig = {
  onChangeAlert: (item: IAlert | null) => void
  contactId: number
  addNewContactLocallyAfterCreate?: (contact: Contact, params?: { isNew?: boolean }) => void
}

export class ContactInfoFormStore {
  private _contactId: number | null = null
  private _updatingFields = new Map<string, string>()
  private _searchOwner = ''
  private _loadingCustomFields = false
  private _mainFields: IMainFields = {
    first_name: '',
    last_name: '',
    email: '',
    number: '',
    owner: null,
  }
  private _errors = new Map<string, string>()
  private _customFields = new Map<string, IResponseCustomField>()
  private _initialCustomFields = new Map<string, IResponseCustomField>()
  private _alert: IAlert | null = null
  private _disposeAlert: IReactionDisposer | null = null
  private _disposeOrganizationMembers: IReactionDisposer | null = null
  private _debounceLoadCustomFields: ReturnType<typeof debounce>
  private _config: IContactInfoFormStoreConfig | null = null
  private _isFirstEditMode = false
  private _phoneFieldSearchStore = new PhoneFieldSearchStore()

  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)

    this._debounceLoadCustomFields = debounce(this.loadCustomFields, 1000)

    this.reactionOrganizationMembers()
    this.reactionAlert()
  }

  setConfig = (config: IContactInfoFormStoreConfig) => {
    this._config = config

    this._phoneFieldSearchStore.setConfig({
      addNewContactLocallyAfterCreate: config?.addNewContactLocallyAfterCreate,
    })
  }

  setIsFirstEditMode = (value: boolean) => {
    this._isFirstEditMode = value
    this._contactsDetailsStore.config?.onChangeIsFirstEditMode?.(value)
  }

  get config() {
    return this._config
  }

  get isVariantCreateContactModal() {
    return this._contactsDetailsStore.isVariantCreateContactModal
  }

  get phoneFieldSearchStore() {
    return this._phoneFieldSearchStore
  }

  get isFirstEditMode() {
    return this._isFirstEditMode
  }

  get selectedContact() {
    return this._contactsDetailsStore.selectedContact
  }

  get updatingFields() {
    return this._updatingFields
  }

  get errors() {
    return this._errors
  }

  get searchOwner() {
    return this._searchOwner
  }

  get mainFields() {
    return this._mainFields
  }

  dispose = () => {
    this._disposeAlert?.()
    this._disposeOrganizationMembers?.()
  }

  get number() {
    return this._mainFields.number
  }

  get customFieldsList() {
    return Array.from(this._customFields.values())
  }

  get visibleCustomFieldsList() {
    return this.customFieldsList.filter((customField) => customField.visible)
  }

  get membersList() {
    const members = usersStore.membersList.filter((member) => !member.isViewOnlyRole)

    if (this._searchOwner) {
      return members.filter((member) =>
        member?.name.toLowerCase().includes(this._searchOwner.toLowerCase())
      )
    }

    return members
  }

  get membersCount() {
    return this.membersList.length
  }

  get hasMembers() {
    return this.membersList.length !== 0
  }

  clearSearchOwner = () => {
    this._searchOwner = ''
  }

  handleSearchOwner = (value: string) => {
    this._searchOwner = value
  }

  hasCustomFields = (isVisibleOnly: boolean) => {
    return isVisibleOnly
      ? this.visibleCustomFieldsList.length !== 0
      : Boolean(this.customFieldsList.length)
  }

  getVisibleCustomFieldsList = (isVisibleOnly: boolean) => {
    return isVisibleOnly ? this.visibleCustomFieldsList : this.customFieldsList
  }

  syncFields = (id: number) => {
    this._contactId = id
    this.refreshContactOwner()
    this.setMainFields()
  }

  refreshContactOwner = async () => {
    if (!this._contactId) return

    const contact = await contactsStore.getById(this._contactId)
    if (!contact) return
    const owner = usersStore.getItem(contact.owner_id || undefined)
    if (!owner) return

    runInAction(() => {
      this._mainFields = {
        ...this._mainFields,
        owner: owner,
      }
    })
  }

  setMainFields = async () => {
    if (!this._contactId) return

    const contact = await contactsStore.getById(this._contactId)
    const owner = usersStore.getItem(contact?.owner_id)

    runInAction(() => {
      this._mainFields = {
        first_name: contact?.first_name || '',
        last_name: contact?.last_name || '',
        number: contact?.number || '',
        email: contact?.email || '',
        owner: owner || null,
      }
    })
  }

  getInitialValue = async (field: MainFiledTypes) => {
    if (field === MainFiledTypes.owner) return null

    if (!this._contactId) return null
    const contact = await contactsStore.getById(this._contactId)
    if (!contact) return null

    return contact[field]
  }

  loadCustomFields = async (id: number) => {
    try {
      const { data } = await ContactsApi.getContactsCustomFields(id)

      runInAction(() => {
        data.data.forEach((field) => {
          this._customFields.set(field.field_key, field)
          this._initialCustomFields.set(field.field_key, field)
        })
      })
    } catch (e) {
      console.log(e)
    }
  }

  init = async (contactId: number) => {
    if (isNaN(contactId)) return
    if (contactId === -1) return

    const contact = await contactsStore.getById(contactId)
    if (!contact) return

    this._contactId = contact.id
    this.setMainFields()
    this._debounceLoadCustomFields(contact.id)
  }

  updateContactInfo = (contactId: number) => {
    this.init(contactId)
  }

  handleSaveOwner = async (id: number, contact: Contact) => {
    if (this._mainFields.owner?.id === id) return

    this._updatingFields.set(MainFiledTypes.owner, MainFiledTypes.owner)

    try {
      const { data: updatedContact } = await ContactsApi.updateContactsByIdFields(contact.id, {
        owner_id: id,
      })

      this._contactsDetailsStore.handleUpdateContact(updatedContact)
      this.refreshContactOwner()
      this.setAlert({
        type: 'infoBlue',
        desc: 'Contact updated',
      })
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<{ [key: string]: string }>(err)

      if (type === 'axios-error') {
        const data = error?.response?.data || {}
        const errorKey = Object.keys(data)[0]
        const errorData = Array.isArray(data[errorKey]) ? data[errorKey][0] : data[errorKey]

        if (errorData) {
          this._errors.set(MainFiledTypes.owner, errorData)
          showToast({
            title: errorData,
            type: 'error',
          })
        }
      }
      this.refreshContactOwner()
    } finally {
      this._updatingFields.delete(MainFiledTypes.owner)
    }
  }

  showAccessErrorModal = () => {
    modalStore.addModal({
      id: 'contactDetailsAccessError',
      type: ModalTypeList.FAIL,
      desc: 'You don’t have access to this contact. Contact your Admin to extend access.',
      infoModalTextAction: 'Close',
    })
  }

  handleSaveMainField = async (field: MainFiledTypes, contact: Contact) => {
    if (field === MainFiledTypes.owner) return

    if (
      contact[field] === this._mainFields[field] ||
      (contact[field] === null && this._mainFields[field] === '')
    ) {
      return
    }

    if (field === MainFiledTypes.number) {
      const { number } = this._mainFields

      if (!number) {
        this._errors.set(field, 'Phone number is required')

        const initialValue = await this.getInitialValue(field)

        if (initialValue) this.handleUpdateMainField(field, initialValue)

        return
      }
    }

    this._updatingFields.set(field, field)

    try {
      const { data: updatedContact } = await ContactsApi.updateContactsByIdFields(contact.id, {
        [field]: this._mainFields[field],
      })

      this._contactsDetailsStore.handleUpdateContact(updatedContact)
      this.setAlert({
        type: 'infoBlue',
        desc: 'Contact updated',
      })
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<{ [key: string]: string | number }>(err)

      if (type === 'axios-error') {
        const data = error?.response?.data || {}

        if (data.status === 404) this.showAccessErrorModal()

        const errorKey = Object.keys(data)[0]
        const errorData =
          typeof data === 'string'
            ? Array.isArray(data[errorKey])
              ? data[errorKey][0]
              : data[errorKey]
            : ''

        if (errorData) this._errors.set(field, errorData)
      }

      const initialValue = await this.getInitialValue(field)

      if (initialValue) this.handleUpdateMainField(field, initialValue)
    } finally {
      this._updatingFields.delete(field)
    }
  }

  handleSaveCustomField = async (field: IResponseCustomField, contact: Contact) => {
    const updatedField = this._customFields.get(field.field_key)
    if (!updatedField) return

    const initialCustomFieldValue = this._initialCustomFields.get(field.field_key)?.value?.value
    if (
      initialCustomFieldValue === updatedField.value?.value ||
      (initialCustomFieldValue === null && updatedField.value?.value === '')
    ) {
      return
    }

    this._updatingFields.set(field.field_key, field.field_key)

    try {
      const { data: updatedContact } = await ContactsApi.updateContactsByIdFields(contact.id, {
        customFields: {
          [field.field_key]: updatedField.value?.value || '',
        },
      })

      this._contactsDetailsStore.handleUpdateContact(updatedContact)
      this.setAlert({
        type: 'infoBlue',
        desc: 'Contact updated',
      })

      this._initialCustomFields.set(field.field_key, field)
    } catch (e) {
      const err = e as Error
      const { type, error } = await errorHandler<{
        [key: string]: { [key: string]: string }[] | string | number
      }>(err)

      if (type === 'axios-error') {
        const data = error?.response?.data || {}

        let errorMessage = ''

        Object.keys(data).map((key) => {
          const errors = data[key]
          if (!Array.isArray(errors)) return

          errors.forEach((error) => {
            Object.keys(error).map((key) => {
              errorMessage = `${error[key]}\n`
            })
          })
        })

        if (data.status === 404) this.showAccessErrorModal()

        if (errorMessage) {
          this._errors.set(field.field_key, errorMessage)
        }
      }

      this.loadCustomFields(contact.id)
    } finally {
      this._updatingFields.delete(field.field_key)
    }
  }

  resetFiledError = (field: string) => {
    if (this._errors.get(field)) this._errors.delete(field)
  }

  handleUpdateCustomField = (
    field: IResponseCustomField,
    value: string | number | null,
    resetError?: boolean
  ) => {
    let updatedField = this._customFields.get(field.field_key)
    if (!updatedField) return

    updatedField = {
      ...updatedField,
      value: {
        type: updatedField?.value?.type || updatedField?.type || CustomFieldTypes.text,
        value,
      },
    } as IResponseCustomField

    if (resetError) this.resetFiledError(field.field_key)

    this._customFields.set(field.field_key, updatedField)
  }

  handleUpdateCustomDateField = (
    field: IResponseCustomField,
    date: Date | null,
    resetError?: boolean
  ) => {
    let updatedField = this._customFields.get(field.field_key)
    if (!updatedField) return

    updatedField = {
      ...updatedField,
      value: {
        type: updatedField?.value?.type || updatedField?.type || CustomFieldTypes.text,
        value: date ? dayjs(date).format('YYYY-MM-DD') : '',
      },
    }

    if (resetError) this.resetFiledError(field.field_key)

    this._customFields.set(field.field_key, updatedField)
  }

  handleUpdateMainField = (field: MainFiledTypes, value: string, resetError?: boolean) => {
    if (resetError) this.resetFiledError(field)

    this._phoneFieldSearchStore.resetError()

    if (field !== MainFiledTypes.owner) this._mainFields[field] = value
  }

  reset = () => {
    this._mainFields = {
      first_name: '',
      last_name: '',
      number: '',
      email: '',
      owner: null,
    }
  }

  reactionOrganizationMembers = () => {
    this._disposeOrganizationMembers?.()
    this._disposeOrganizationMembers = reaction(
      () => usersStore.membersIdsList,
      () => {
        this.refreshContactOwner()
      }
    )
  }

  get alert() {
    return this._alert
  }

  setAlert = (item: IAlert | null) => {
    this._alert = item
    this._config?.onChangeAlert(item)
  }

  resetAlert = () => {
    this._alert = null
    this._config?.onChangeAlert(null)
  }

  reactionAlert = () => {
    this._disposeAlert?.()
    this._disposeAlert = reaction(
      () => this.alert,
      (alert) => {
        if (alert) {
          setTimeout(() => {
            runInAction(() => {
              this.resetAlert()
            })
          }, 5000)
        }
      }
    )
  }
}
