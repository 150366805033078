import slide1Logo from 'shared/assets/images/auth/slider/slide1_logo.png'
import slide2Logo from 'shared/assets/images/auth/slider/slide2_logo.png'
import slide3Logo from 'shared/assets/images/auth/slider/slide3_logo.png'
import slide4Logo from 'shared/assets/images/auth/slider/slide4_logo.png'
import slide1Avatar from 'shared/assets/images/auth/slider/slide1_avatar.png'
import slide2Avatar from 'shared/assets/images/auth/slider/slide2_avatar.png'
import slide3Avatar from 'shared/assets/images/auth/slider/slide3_avatar.png'
import slide4Avatar from 'shared/assets/images/auth/slider/slide4_avatar.png'
import { getCDNAssetURL } from 'shared/lib'

export const slides = [
  {
    title: 'Decreased\nno-shows by 45%',
    subtitle: 'no-show decrease',
    status: '45%',
    name: 'Greg Hickman',
    role: 'CEO',
    logo: getCDNAssetURL(slide1Logo),
    avatar: getCDNAssetURL(slide1Avatar),
  },
  {
    title: 'Cut no-shows 20% & added $90K MRR',
    subtitle: 'MRR increase',
    status: '$90K',
    name: 'Wendell Scott',
    role: 'Sales Manager',
    logo: getCDNAssetURL(slide2Logo),
    avatar: getCDNAssetURL(slide2Avatar),
  },
  {
    title: 'Instantly increased response rates\nby 52%',
    subtitle: 'response rate increase',
    status: '52%',
    name: 'Joe Ben-Zvi',
    role: 'COO',
    logo: getCDNAssetURL(slide3Logo),
    avatar: getCDNAssetURL(slide3Avatar),
  },
  {
    title: '15X ROI in less than an hour',
    subtitle: 'ROI increase',
    status: '1,500%',
    name: 'Sarah Bigby',
    role: 'Inside Sales',
    logo: getCDNAssetURL(slide4Logo),
    avatar: getCDNAssetURL(slide4Avatar),
  },
]
