import { FilterItem } from 'widgets/FilterEditor/model'

export const DateTypes = new Set(['created_at', 'date', 'date_without_checkbox', 'updated_created'])
export const DateTimeTypes = new Set(['date_time', 'date_time_shortcode'])
export const DatePickerTypes = new Set([...DateTypes.values(), ...DateTimeTypes.values()])

export const DateSingleOperators = new Set(['is_equal_to', 'is_after', 'is_before'])
export const DateRangeOperators = new Set(['is_between', 'is_not_between'])

export const isDateWidget = (filterItem: FilterItem) => DatePickerTypes.has(filterItem.config.type)
