import { observer } from 'mobx-react-lite'
import { useBusinessProfileStepsContext } from 'pages/settings/pages/compliance/pages/businessProfile/context/businessProfileStepsContext'
import { CommonBusinessInfo } from 'pages/settings/pages/compliance/ui/CommonBusinessInfo/CommonBusinessInfo'

export const BusinessInfo = observer(() => {
  const { businessInfoStore, stepLoading, errorMessage, businessInfoNextStepClick } =
    useBusinessProfileStepsContext()

  return (
    <CommonBusinessInfo
      businessInfoStore={businessInfoStore}
      stepLoading={stepLoading}
      errorMessage={errorMessage}
      nextStepClick={businessInfoNextStepClick}
    />
  )
})
