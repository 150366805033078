import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { apiOptionToDropdownItem } from 'shared/lib'
import { IDropdownItem } from 'shared/ui'
import {
  AdminComplianceApi,
  IResponseAdminTollFreeByID,
  IParamsUpdateTollFreeBusinessInfo,
} from 'entities/Admin/compliance'

class AdminTollFreeBusinessInfoStore {
  constructor() {
    makeAutoObservable(this)
  }

  number_id: number | null = null

  isEdit = false
  isSaveLoading = false

  errorsObj: { [key: string]: string[] } = {}

  get isForEdit() {
    return true
  }

  get paramsUpdateTenDLCBusinessProfile(): IParamsUpdateTollFreeBusinessInfo {
    return {
      number_id: this.number_id,
      business_name: this.edit_business_name,
      city: this.edit_city,
      state_id: this.edit_state?.id as string,
      country: this.edit_country as string,
      postal_code: this.edit_postal_code,
      website_url: this.edit_website_url,
      street_address_1: this.edit_street_address_1,
      street_address_2: this.edit_street_address_2,
    }
  }

  street_address_1: string | null = null
  edit_street_address_1: typeof this.street_address_1 = null
  get is_edit_street_address_1() {
    return this.street_address_1 !== this.edit_street_address_1
  }
  set_edit_street_address = (value: typeof this.edit_street_address_1) => {
    this.edit_street_address_1 = value
  }

  street_address_2: string | null = null
  edit_street_address_2: typeof this.street_address_2 = null
  get is_edit_street_address_2() {
    return this.street_address_2 !== this.edit_street_address_2
  }
  set_edit_street_address_2 = (value: typeof this.edit_street_address_2) => {
    this.edit_street_address_2 = value
  }

  city: string | null = null
  edit_city: typeof this.city = null
  get is_edit_city() {
    return this.city !== this.edit_city
  }
  set_edit_city = (value: typeof this.edit_city) => {
    this.edit_city = value
  }

  state: IDropdownItem | null = null
  edit_state: typeof this.state = null
  get is_edit_state() {
    return this.state?.id !== this.edit_state?.id
  }
  set_edit_state = (value: typeof this.edit_state) => {
    this.edit_state = value
  }

  postal_code: string | null = null
  edit_postal_code: typeof this.postal_code = null
  get is_edit_postal_code() {
    return this.postal_code !== this.edit_postal_code
  }
  set_edit_postal_code = (value: typeof this.edit_postal_code) => {
    this.edit_postal_code = value
  }

  country: string | null = null
  edit_country: typeof this.country = null
  get is_edit_country() {
    return this.country !== this.edit_country
  }
  set_edit_country = (value: typeof this.edit_country) => {
    this.edit_country = value
  }

  business_name: string | null = null
  edit_business_name: typeof this.business_name = null
  get is_edit_business_name() {
    return this.business_name !== this.edit_business_name
  }
  set_edit_business_name = (value: typeof this.edit_business_name) => {
    this.edit_business_name = value
  }

  website_url: string | null = null
  edit_website_url: typeof this.website_url = null
  get is_edit_website_url() {
    return this.website_url !== this.edit_website_url
  }
  set_edit_website_url = (value: typeof this.edit_website_url) => {
    this.edit_website_url = value
  }

  get hasChanges() {
    if (!this.isEdit) {
      return false
    }
    const changes = [
      this.is_edit_street_address_1,
      this.is_edit_street_address_2,
      this.is_edit_city,
      this.is_edit_state,
      this.is_edit_postal_code,
      this.is_edit_country,
      this.is_edit_business_name,
      this.is_edit_website_url,
    ]
    return changes.some((item) => item)
  }

  reset = () => {
    this.errorsObj = {}
    this.number_id = null
    this.business_name = null
    this.street_address_1 = null
    this.street_address_2 = null
    this.city = null
    this.state = null
    this.postal_code = null
    this.country = null
    this.website_url = null
    this.isEdit = false
  }

  setBusinessProfile = (number_id?: number, data?: IResponseAdminTollFreeByID['business_data']) => {
    this.number_id = number_id || null
    if (data) {
      this.street_address_1 = data.street_address_1
      this.street_address_2 = data.street_address_2
      this.city = data.city
      this.state = apiOptionToDropdownItem(data.state)
      this.country = data.country
      this.business_name = data.business_name
      this.postal_code = data.postal_code
      this.website_url = data.website_url
    }
  }

  onEdit = () => {
    this.edit_business_name = this.business_name
    this.edit_street_address_1 = this.street_address_1
    this.edit_city = this.city
    this.edit_country = this.country
    this.edit_state = this.state
    this.edit_postal_code = this.postal_code
    this.edit_website_url = this.website_url
    this.isEdit = true
  }

  onClose = () => {
    this.isEdit = false
  }

  onSave = async () => {
    try {
      runInAction(() => {
        this.isSaveLoading = true
      })
      return await AdminComplianceApi.updateTollFreeBusinessInfo(
        this.paramsUpdateTenDLCBusinessProfile
      )
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data) {
            this.errorsObj = e.response.data
          }
        }
      })
      throw e
    } finally {
      runInAction(() => {
        this.isSaveLoading = false
      })
    }
  }

  getError = (key: string) => {
    return this.errorsObj && this.errorsObj[key] && this.errorsObj[key][0]
  }
}

export const adminTollFreeBusinessInfoStore = new AdminTollFreeBusinessInfoStore()
