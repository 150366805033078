import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { EnumDropdownItemVariant, IDropdownItem, showToast } from 'shared/ui'
import { numbersStore, PhoneApi } from 'entities/Phone'
import { IResponseNumberSearch, IParamsNumberSearch } from 'entities/Phone/api/types'
import { IResponseBillingState, IResponseBillingAreaCode } from 'entities/Billing/api/types'
import { subscriptionStore } from 'entities/Subscription'
import { usersStore } from 'entities/Users'
import { billingStore } from 'entities/Billing'
import { organizationStore } from 'entities/Organization'
import { GetNumberModalActions, GetNumberModalContent } from 'widgets/GetNumberModal'

export enum ICountriesEnum {
  US = 'US',
  CA = 'CA',
}

export enum ITabsEnum {
  LOCAL = 'LOCAL',
  TOLL_FREE = 'TOLL_FREE',
}

export class GetNumberModalStore {
  idModal = 'getNumberModal'
  selectedNumber: IResponseNumberSearch | null = null
  numbersMap: Map<string, IResponseNumberSearch> = new Map()
  loading = false
  state: IResponseBillingState | null = null
  country: ICountriesEnum = ICountriesEnum.US
  areaCode: IResponseBillingAreaCode | null = null
  activeTab: ITabsEnum = ITabsEnum.TOLL_FREE

  constructor() {
    makeAutoObservable(this)

    this.reactionCountry()
    this.reactionState()
    this.reactionAreaCode()
    this.reactionActiveTab()
    this.reactionInit()
  }

  get canSearch() {
    if (this.loading) return false

    if (this.activeTab === ITabsEnum.LOCAL) {
      return Boolean(this.areaCode && this.country && this.state)
    }

    return Boolean(this.country)
  }

  get alertDescription() {
    return `${
      this.activeTab === ITabsEnum.LOCAL ? 'Local' : 'Toll-Free'
    } numbers will require verification of your business`
  }

  get statePlaceholder() {
    if (this.country === ICountriesEnum.CA) return 'Enter a province name'
    return 'Enter a state name'
  }

  get states(): IDropdownItem[] {
    return billingStore.states
      .filter((state) => state.country_code === this.country)
      .map((state) => ({
        label: `${state.state_code} - ${state.state_name}`,
        id: state.state_code,
        variant: EnumDropdownItemVariant.CheckedRight,
        customFields: state,
      }))
  }

  get areaCodes(): IDropdownItem[] {
    return billingStore.areaCodes
      .filter((areaCode) => areaCode.state_code === this.state?.state_code)
      .map((areaCode) => ({
        label: `${areaCode.state_name} - ${areaCode.area_code}`,
        id: areaCode.area_code,
        variant: EnumDropdownItemVariant.CheckedRight,
        customFields: areaCode,
      }))
  }

  get countries(): IDropdownItem[] {
    return [
      {
        label: 'United States',
        id: ICountriesEnum.US,
        iconL: 'flagUSA',
        variant: EnumDropdownItemVariant.CheckedRight,
      },
      {
        label: 'Canada',
        id: ICountriesEnum.CA,
        iconL: 'flagCanada',
        variant: EnumDropdownItemVariant.CheckedRight,
      },
    ]
  }

  get countryData() {
    switch (this.country) {
      case ICountriesEnum.US:
        return { flag: 'flagUSA', name: 'United States' }
      case ICountriesEnum.CA:
        return { flag: 'flagCanada', name: 'Canada' }
      default:
        return null
    }
  }

  get searchParams(): IParamsNumberSearch {
    return {
      country: this.country,
      state: this.state?.state_code || '',
      search: this.areaCode?.area_code || '',
      toll_free: this.activeTab === ITabsEnum.LOCAL ? '0' : '1',
    }
  }

  get numbersList() {
    return Array.from(this.numbersMap.values())
  }

  get noResults() {
    return this.numbersList.length === 0
  }

  setNumber = (number: IResponseNumberSearch) => {
    this.selectedNumber = number
  }

  setActiveTab = (activeTab: ITabsEnum) => {
    this.activeTab = activeTab
  }

  setState = (state: IResponseBillingState) => {
    this.state = state
  }

  setAreaCode = (areaCode: IResponseBillingAreaCode) => {
    this.areaCode = areaCode
  }

  setCountry = (country: ICountriesEnum) => {
    this.country = country
  }

  init = () => {
    if (
      usersStore.user.isRequireNumber &&
      !subscriptionStore.isAgencyWithoutSubscription &&
      !organizationStore.isTwilioNumberVendor &&
      !usersStore.user.inboxesCount
    ) {
      modalStore.addModal({
        id: this.idModal,
        showCloseIcon: false,
        showCloseButton: false,
        disabledOnAllClose: true,
        disabledOnEscClose: true,
        disabledOverlayClose: true,
        paddingContent: '8px 24px 0 24px',
        title: 'Choose number',
        width: 480,
        ModalActions: GetNumberModalActions,
        ModalContent: GetNumberModalContent,
        onClose: this.closeModal,
      })
    }
  }

  closeModal = () => {
    this.reset()
    modalStore.removeModal(this.idModal)
  }

  reset = () => {
    this.numbersMap.clear()
    this.selectedNumber = null
    this.loading = false
    this.state = null
    this.country = ICountriesEnum.US
    this.areaCode = null
    this.activeTab = ITabsEnum.TOLL_FREE
  }

  handleAddNumber = async () => {
    if (!this.selectedNumber) return

    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await PhoneApi.addNumber({ number: this.selectedNumber?.number })

      numbersStore.addItem(data)

      if (window.StoreVue?.refetchMe) window.StoreVue.refetchMe()

      await Promise.all([usersStore.fetchUsersMe(), numbersStore.fetchOrganizationNumber()])

      this.closeModal()
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.data.message[0] === 'Your card was declined.') {
          showToast({
            type: 'error',
            title: 'Your card was declined.',
          })
        } else {
          showToast({
            type: 'error',
            title: 'This number is already reserved for another account',
            desc: 'Please choose another number',
          })
        }
      }
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  refresh = async () => {
    if (!this.canSearch) return

    try {
      runInAction(() => {
        this.loading = true
        this.numbersMap.clear()
        this.selectedNumber = null
      })

      const { data } = await PhoneApi.getNumbersSearch(this.searchParams)

      this.addNumbers(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  load = async () => {
    if (!this.canSearch) return

    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await PhoneApi.getNumbersSearch(this.searchParams)

      this.addNumbers(data)
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  addNumbers = (numbers: IResponseNumberSearch[]) => {
    numbers.forEach((item) => {
      this.numbersMap.set(item.number, item)
    })
  }

  reactionActiveTab = () => {
    reaction(
      () => this.activeTab,
      () => {
        this.state = null
        this.areaCode = null
        this.selectedNumber = null
        this.numbersMap.clear()
        this.load()
      }
    )
  }

  reactionCountry = () => {
    reaction(
      () => this.country,
      () => {
        this.state = null
        this.areaCode = null
        this.selectedNumber = null
        this.numbersMap.clear()
        this.load()
      }
    )
  }

  reactionState = () => {
    reaction(
      () => this.state,
      () => {
        this.areaCode = null
        this.selectedNumber = null
        this.numbersMap.clear()
      }
    )
  }

  reactionInit = () => {
    reaction(
      () => [
        subscriptionStore.isAgencyWithoutSubscription,
        usersStore.user?.isViewOnlyRole,
        usersStore.user?.isRequireNumber,
      ],
      () => {
        this.selectedNumber = null
        this.numbersMap.clear()
        this.load()
      }
    )
  }

  reactionAreaCode = () => {
    reaction(
      () => this.areaCode,
      () => {
        this.selectedNumber = null
        this.numbersMap.clear()
        this.load()
      }
    )
  }
}

export const getNumberModalStore = new GetNumberModalStore()
