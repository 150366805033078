import React from 'react'
import { IIconsVector, Tooltip } from 'shared/ui'
import { BoxIcon } from 'shared/ui/Icon/BoxIcon'

const config = {
  sms: {
    icon: 'messageSmile' as IIconsVector,
    label: 'SMS',
  },
  ringless: {
    icon: 'voicemail2' as IIconsVector,
    label: 'Ringless',
  },
}

export type IBroadcastType = keyof typeof config

type IBroadcastTypeProps = { type: IBroadcastType }

export const BroadcastType = ({ type }: IBroadcastTypeProps) => {
  return (
    <Tooltip label={config[type]?.label} placement={'top'}>
      <BoxIcon icon={config[type]?.icon} tertiary />
    </Tooltip>
  )
}
