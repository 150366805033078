import { useEffect, useRef, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { IconButton } from 'shared/ui'
import { makeTextFieldAutoFocus, makeTextFieldClear, TextField } from 'shared/ui/TextField'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { RecentListHeader } from 'widgets/RecentlySent/ui/RecentListHeader/RecentListHeader'
import { RecentItem } from 'widgets/RecentlySent/ui/RecentItem/RecentItem'
import { recentStore } from 'widgets/RecentlySent'
import styles from './styles.module.scss'

type IRecentlySentContentProps = {
  reverse?: boolean
  openRecentContent: boolean
  onCloseMenu: () => void
  onCloseRecent: () => void
  dropdownId: string
  isCurrentAirCall?: boolean
}

export const RecentlySentContent: FC<IRecentlySentContentProps> = observer(
  ({ onCloseMenu, onCloseRecent, reverse, openRecentContent, dropdownId, isCurrentAirCall }) => {
    const {
      visibleAttachments,
      onShowMore,
      checkParamsAndLoadRecent,
      setDropdownId,
      reset,
      searchAttachments,
      loadingRecent,
      search,
    } = recentStore

    const listNodeRef = useRef<HTMLDivElement>(null)
    const messageFieldStore = useMessageFieldContext()

    const onSelectAttachment = (attachment: Attachment) => {
      messageFieldStore.addAttachment(attachment)
      onCloseMenu()
    }

    const handleListNodeScroll = ({ target }: { target: EventTarget }) => {
      const element = target as HTMLDivElement
      const isScrollEnd = element.offsetHeight + element.scrollTop + 100 >= element.scrollHeight

      if (!isScrollEnd) return

      onShowMore()
    }

    const onChangeSearch = (value: string) => {
      searchAttachments(value)
      listNodeRef.current?.scrollTo(0, 0)
    }

    useEffect(() => {
      checkParamsAndLoadRecent(isCurrentAirCall)
      setDropdownId(dropdownId)
      return () => {
        reset()
      }
    }, [openRecentContent])

    return (
      <div className={classNames(styles.wrap, { [styles.reverse]: reverse })}>
        <div className={styles.header}>
          <IconButton
            onClick={onCloseRecent}
            icon={'chevronLeft'}
            variant={'icon'}
            color={'secondary'}
            fontSize={16}
            ariaLabel='CloseRecentlySentContent'
          />
          <TextField
            className={styles.textField}
            value={search}
            onChange={onChangeSearch}
            disabled={loadingRecent}
            mainActions={[makeTextFieldAutoFocus({ withFocus: !loadingRecent })]}
            rightActions={[makeTextFieldClear()]}
            size='small'
            variant='integrated'
            InputProps={{
              placeholder: 'Search',
            }}
          />
        </div>
        <div className={styles.divider} />
        <div>
          <RecentListHeader isShowTitle={!reverse} />
          <div className={styles.wrapItems} ref={listNodeRef} onScroll={handleListNodeScroll}>
            {visibleAttachments.map((attachment) => (
              <RecentItem
                key={attachment.id}
                attachment={attachment}
                onSelectAttachment={onSelectAttachment}
              />
            ))}
            {!visibleAttachments.length && <div className={styles.noResult}>No results found</div>}
          </div>
        </div>
      </div>
    )
  }
)
