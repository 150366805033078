import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { usersStore } from 'entities/Users'
import { useConversationListContext } from 'widgets/ConversationList/context'

export const ReadAction = observer(() => {
  const user = usersStore.user
  const store = useConversationListContext()
  const { selectedHasReadConversation, readConversations, unreadConversations, selectedCount } =
    store

  const handleRead = () => {
    if (selectedHasReadConversation) {
      unreadConversations()
      return
    }
    readConversations()
  }

  if (user?.isViewOnlyRole) return null

  return (
    <IconButton
      icon={selectedHasReadConversation ? 'chatUnread' : 'chatRead'}
      tooltipProps={{
        placement: 'bottom',
        label: selectedHasReadConversation
          ? `Mark as unread (${selectedCount})`
          : `Mark as read (${selectedCount})`,
        disableInteractive: true,
      }}
      onClick={handleRead}
      variant={'icon'}
      color='secondary'
      size={'medium'}
      ariaLabel='ReadAction_mark_as'
    />
  )
})
