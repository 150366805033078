import { makeAutoObservable, reaction } from 'mobx'
import { inboxesStore } from 'entities/Inbox'
import { ChannelInbox, ChannelInboxConversation } from 'entities/WebSocket'

class ChannelsInboxes {
  constructor() {
    makeAutoObservable(this)
    reaction(() => inboxesStore.inboxesMap.size, this.subscribeChannels)
  }

  subscribersSet: Set<number> = new Set()

  subscribeChannels = () => {
    inboxesStore.inboxesMap.forEach((inbox) => {
      this.subscribeChannel(inbox.id)
    })
  }

  subscribeChannel = (id: number) => {
    if (this.subscribersSet.has(id)) return
    new ChannelInbox({ id: id })
    new ChannelInboxConversation({ id: id })
    this.subscribersSet.add(id)
  }
}

export const channelsInboxes = new ChannelsInboxes()
