import { makeAutoObservable } from 'mobx'
import { Conversion, ConversionsApi } from 'entities/Conversion'

class ConversionStore {
  constructor() {
    makeAutoObservable(this)
  }

  itemsMap: Map<number, Conversion> = new Map()

  setItem = (conversion: Conversion) => {
    this.itemsMap.set(conversion.id, conversion)
  }

  getItemById = async (id?: number | null) => {
    if (!id) return
    const item = this.itemsMap.get(id)
    if (item) return item
    try {
      const { data } = await ConversionsApi.getConversionById(id)
      const conversion = new Conversion(data)
      this.setItem(conversion)
      return conversion
    } catch (e) {
      console.error(e)
    }
  }
}

export const conversionStore = new ConversionStore()
