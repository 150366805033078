import { observer } from 'mobx-react-lite'
import { useRef, useState, useEffect } from 'react'
import { Label } from 'shared/ui/Label'
import { Icon, Typography } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { TimezoneContent } from 'shared/ui/Schedule/ui/TimezoneContent/TimezoneContent'
import { ReviewAlerts } from './alerts/ReviewAlerts'
import styles from './styles.module.scss'
import { IReviewBroadcastProps } from './type'

export const ReviewBroadcastModalContent = observer(({ store }: IReviewBroadcastProps) => {
  const { estimatedCount, is_contact_timezone, estimatedDateText, allTotal, skipped, isRingless } =
    store
  const ref = useRef<HTMLDivElement>(null)
  const [refElement, setRefElement] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    setRefElement(ref.current)
  }, [])

  return (
    <div className={styles.modalWrap}>
      <div ref={ref} className={styles.wrapTooltip} />
      <ReviewAlerts store={store} />
      <div className={styles.wrap}>
        {estimatedDateText && (
          <div className={styles.row}>
            <Label
              label={{
                text: 'Estimated completion',
              }}
            />
            <div className={styles.value}>
              <Icon icon={'calendar'} tertiary />
              <Typography variant={'text-input-field'} ariaLabel={'estimatedDateText'}>
                {estimatedDateText}
              </Typography>
              <TimezoneContent is_contact_timezone={is_contact_timezone} />
            </div>
          </div>
        )}

        <div className={styles.row}>
          <Label
            label={{
              text: 'Estimated credits',
            }}
          />
          <div className={styles.value}>
            <Icon icon={'starOutline'} tertiary />
            <Typography variant={'text-input-field'} ariaLabel={'Estimated credits'}>
              {numberFormat({ value: estimatedCount })}
            </Typography>
          </div>
        </div>

        <div className={styles.row}>
          <Label
            label={{
              text: 'Total contacts',
            }}
            questionTooltipProps={{
              placement: 'top',
              label: 'Total contacts that meet the filters criteria',
              width: 279,
              PopperProps: {
                popperOptions: {
                  modifiers: [
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: refElement,
                      },
                    },
                  ],
                },
              },
            }}
          />
          <div className={styles.value}>
            <Icon icon={'contacts'} tertiary />
            <Typography variant={'text-input-field'} ariaLabel={'Estimated credits'}>
              {numberFormat({ value: allTotal })}
            </Typography>
          </div>
        </div>

        <div className={styles.row}>
          <Label
            label={{
              text: 'Skipped contacts',
            }}
            questionTooltipProps={{
              type: 'description',
              placement: 'top',
              label: 'Filtered contacts who are invalid or unsubscribed',
              desc: 'Contacts will be checked for invalid numbers before sending',
              width: 359,
              maxWidth: 359,
              PopperProps: {
                popperOptions: {
                  modifiers: [
                    {
                      name: 'preventOverflow',
                      options: {
                        boundary: refElement,
                      },
                    },
                  ],
                },
              },
            }}
          />
          <div className={styles.value}>
            <Icon icon={'contacts'} tertiary />
            <Typography variant={'text-input-field'} ariaLabel={'Estimated credits'}>
              {numberFormat({ value: skipped })}
            </Typography>
          </div>
        </div>
      </div>
      {isRingless && (
        <Typography
          ariaLabel={'RinglessInfo'}
          variant={'body-sm-regular'}
          tag={'div'}
          className={styles.ringlessInfo}
          color={'tertiary'}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Ringless voicemails are sent between 8 AM - 9 PM based on the recipient's area code.
          Messages sent outside this time will be deferred to the next day.
        </Typography>
      )}
    </div>
  )
})
