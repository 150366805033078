import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IntegrationKey,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import { SectionTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/SectionTab/SectionTab'

type IAccountsProps = {
  integrationKey: IntegrationKey
  accounts: {
    id: string
    name: string
  }
  contact: Contact
  integrationLabel: string
}

export const Accounts: FC<IAccountsProps> = observer(
  ({ accounts, contact, integrationLabel, integrationKey }) => {
    const integration = integrationsStore.getIntegration(integrationKey)
    const linkProps = {
      key: integrationKey,
      contact: contact,
      integration: integration,
      type: IIntegrationLinkTypes.contact,
    }
    const link = integrationLink(linkProps)
    const item =
      accounts.id === '0'
        ? undefined
        : {
            title: accounts.name,
          }

    return (
      <SectionTab
        item={item}
        titleView={'View account'}
        titleEmpty={'No account found'}
        integrationLabel={integrationLabel}
        properties={[]}
        link={link}
      />
    )
  }
)
