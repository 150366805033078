import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import axios, { CancelTokenSource, isCancel } from 'axios'
import { toastStore } from 'shared/ui'
import { PageLayoutStore } from 'shared/layout'
import { logger } from 'shared/lib'
import { RequestCancelAxios } from 'shared/api/requestCancelAxios'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import {
  type IParamsCreateMessage,
  type IParamsMessagesGetContactsByIdTeamsById,
  type IParamsUpdateMessage,
  type IResponseMessage,
  type IResponseRecordTranscriptStatus,
  MessagesApi,
} from 'entities/Message'
import { attachmentStore } from 'entities/Attachment'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { integrationsStore } from 'entities/Integrations'
import {
  type IParamsCreateSuggestAnswer,
  type IParamsCreateSummaryAnswer,
} from 'entities/AIAssistant'
import type { IResponseActivity } from 'entities/Activity/api/types'
import { websocket } from 'entities/WebSocket'
import { usersStore } from 'entities/Users'
import type { IResponseEventTyping } from 'entities/Typing/api/types'
import { Activity } from 'entities/Activity/model/Activity'
import { Message } from 'entities/Message/model/Message'
import { Typing } from 'entities/Typing/model/Typing'
import { Integration } from 'entities/Integrations/model/Integration'
import { userSettingsStore } from 'entities/Settings'
import { organizationStore } from 'entities/Organization'
import { inboxesStore } from 'entities/Inbox'
import { Viewing } from 'entities/Viewing/model/Viewing'
import type { IResponseEventViewing, IViewingStatus } from 'entities/Viewing'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { IScheduledData } from 'widgets/ConversationSchedule/store/conversationScheduleStore'
import { CallModalStore } from 'widgets/CallModal'
import { ConversationListStore } from 'widgets/ConversationList'
import { ConversationSearchStore } from 'widgets/ConversationHeaderSearch'
import { ConversationNewSearchStore } from 'widgets/ConversationNew'
import { CallContentStore } from 'widgets/MessageCard/store'
import { IConversationMessagesStoreConfig } from 'widgets/ConversationMessages/store/types'
import { SavedRepliesStore } from 'widgets/SavedReplies'
import { ConversationMessageFieldStore } from 'widgets/ConversationMessages/store/conversationMessageFieldStore'
import { ConversationMessageFieldAccessStore } from 'widgets/ConversationMessages/store/conversationMessageFieldAccessStore'
import { getSendMessageAlert } from '../helpers/messageAlert'

export class ConversationMessagesStore {
  private _callModalStore: CallModalStore | null = null
  private _conversationListStore: ConversationListStore | null = null
  private _conversationSearchStore: ConversationSearchStore | null = null

  private _per_page = 20
  private _page = 1

  private _currentConversationId: number | null = null
  private _itemsMap: Map<string | number, Message | Activity> = new Map()
  private _sendingMessagesIdsMap: Map<number | string, string | number> = new Map()
  private _scrollBottomTrigger = ''

  private _loading = true
  private _loadingConversation = false
  private _loadingPrevious = false
  private _loadingMessages = false
  private _hasNext = true
  private _hasPrevious = false

  private _loadingBeforeId: string | number = 0
  private _isScheduleMessage = false
  private _isEditMessage = false
  private _handleReceiveNewScroll = true
  private _isShowActivity = userSettingsStore.isUserShowActivity
  private _isHideCalls = userSettingsStore.isHideCalls
  private _typingsMap: Map<number, Typing> = new Map()
  private _viewingsMap: Map<number, Viewing> = new Map()
  private _cancelTokenSource: CancelTokenSource | null = null
  private _isHideTrialAlertNotForOwner = false
  private _isClickToUndo = userSettingsStore.isClickToUndo
  private _warning: string | null = null
  private _isModeNoteObj = {
    value: false,
  }

  private _disposeIsUserShowActivity: IReactionDisposer | null = null
  private _disposeIsClickToUndo: IReactionDisposer | null = null
  private _disposeConversation: IReactionDisposer | null = null
  private _disposeActiveTab: IReactionDisposer | null = null

  private _callContentStore = new CallContentStore(this)
  private _conversationNewSearchStore: ConversationNewSearchStore | null = null
  private _startSendMessage: IConversationMessagesStoreConfig['startSendMessage'] | null = null
  private _finishSendMessage: IConversationMessagesStoreConfig['finishSendMessage'] | null = null
  private _enableTrialAlertLimitConversations: IConversationMessagesStoreConfig['enableTrialAlertLimitConversations'] =
    false
  private _isCheckInbox: IConversationMessagesStoreConfig['isCheckInbox'] = false
  private _isActiveTab = true
  private _canSendViewing = true
  private _throttleViewingTime = 30000
  private _onViewingTimerId: NodeJS.Timer | null = null
  private _savedRepliesStore = new SavedRepliesStore()
  private _conversationMessageFieldStore: ConversationMessageFieldStore
  private _conversationMessageFieldAccessStore: ConversationMessageFieldAccessStore
  private _isHideAvatars = false
  private _isBottomFixed = true
  private _messagesContentScrollHeight = 0
  private _cancelRequest = new RequestCancelAxios()

  constructor(private _pageLayoutStore?: PageLayoutStore) {
    makeAutoObservable(this)

    this._conversationMessageFieldStore = new ConversationMessageFieldStore(this)
    this._conversationMessageFieldAccessStore = new ConversationMessageFieldAccessStore(this)
  }

  get isBottomFixed() {
    return this._isBottomFixed
  }

  get messagesContentScrollHeight() {
    return this._messagesContentScrollHeight
  }

  get isHideAvatars() {
    return this._isHideAvatars
  }

  get conversationMessageFieldStore() {
    return this._conversationMessageFieldStore
  }

  get conversationMessageFieldAccessStore() {
    return this._conversationMessageFieldAccessStore
  }

  initReactions = () => {
    this._conversationMessageFieldStore.initReactions()

    this.reactionIsUserShowActivity()
    this.reactionIsClickToUndo()
    this.reactionConversation()
    this.reactionActiveTab()
  }

  reactionConversation = () => {
    this._disposeConversation?.()
    this._disposeConversation = reaction(
      () => this.conversation,
      (conversation) => {
        if (conversation?.id) {
          this.onViewing()
        }
      }
    )
  }

  reactionActiveTab = () => {
    this._disposeActiveTab?.()
    this._disposeActiveTab = reaction(
      () => this._isActiveTab,
      (status) => {
        if (status && this._canSendViewing) {
          this.onViewing()
        }
      }
    )
  }

  reactionIsClickToUndo = () => {
    this._disposeIsClickToUndo?.()
    this._disposeIsClickToUndo = reaction(
      () => userSettingsStore.isClickToUndo,
      (value) => {
        this._isClickToUndo = value
      }
    )
  }

  reactionIsUserShowActivity = () => {
    this._disposeIsUserShowActivity?.()
    this._disposeIsUserShowActivity = reaction(
      () => userSettingsStore.isUserShowActivity,
      (value) => {
        this._isShowActivity = value
      }
    )
  }

  setConfig = (config: IConversationMessagesStoreConfig) => {
    this._callModalStore = config.callModalStore || null
    this._conversationListStore = config.conversationListStore || null
    this._conversationSearchStore = config.conversationSearchStore || null
    this._conversationNewSearchStore = config.conversationNewSearchStore || null
    this._startSendMessage = config.startSendMessage || null
    this._finishSendMessage = config.finishSendMessage || null
    this._enableTrialAlertLimitConversations = config.enableTrialAlertLimitConversations || false
    this._isCheckInbox = config.isCheckInbox || false
  }

  setIsCheckInbox = (value: boolean) => {
    this._isCheckInbox = value
  }

  setMessagesContentScrollHeight = (value: number) => {
    this._messagesContentScrollHeight = value
  }

  setEnableTrialAlertLimitConversations = (value: boolean) => {
    this._enableTrialAlertLimitConversations = value
  }

  get savedRepliesStore() {
    return this._savedRepliesStore
  }

  get enableTrialAlertLimitConversations() {
    return this._enableTrialAlertLimitConversations
  }

  get callContentStore() {
    return this._callContentStore
  }

  get currentInbox() {
    if (this.isConversationNew) {
      return inboxesStore.getItem(this._conversationNewSearchStore?.activeInboxId)
    }

    return inboxesStore.currentInbox
  }

  get conversationId() {
    return this._currentConversationId || 0
  }

  get contact() {
    if (this.isConversationNew) return this._conversationNewSearchStore?.items[0] ?? null

    if (!this.conversation?.contact_id) return null

    return contactsStore.getItem(this.conversation.contact_id)
  }

  get integration(): Integration | null {
    if (!this.contact?.integration_key) return null

    // TODO: change key to salesmessage after back
    if (this.contact?.integration_key === 'salesmsg') return null

    return integrationsStore.getIntegration(this.contact.integration_key)
  }

  get conversation() {
    return conversationStore.getItem(Number(this.conversationId))
  }

  get isClosed() {
    return this.conversation?.isClosed
  }

  get isRead() {
    return this.conversation?.isRead
  }

  get isUnread() {
    return this.conversation?.isUnread
  }

  get allMessages() {
    const messages: Array<Message> = []
    Array.from(this._itemsMap.values()).forEach((item) => {
      if (item instanceof Message) {
        messages.push(item)
      }
    })
    return messages
  }

  get sendingMessages() {
    return this.allMessages
      .filter((message) => message.sending)
      .filter((message) => message.conversation_id === this.conversationId)
  }

  get beforeId() {
    return this.items[this.items.length - 1]?.id
  }

  get isGroup() {
    return this.conversation?.is_group
  }

  get items(): Array<Message | Activity> {
    return Array.from(this._itemsMap.values())
      .filter((message) => message instanceof Activity || !message.sending)
      .filter(
        (message) =>
          message instanceof Activity ||
          (message.type === 'call' ? message.record || message.body : true)
      )
      .sort((a, b) =>
        b.sortDateTime === a.sortDateTime
          ? Number(a.id) - Number(b.id)
          : a.sortDateTime - b.sortDateTime
      )
  }
  get scheduledItems() {
    return this.items.filter((message) => message instanceof Message && message.is_schedule)
  }

  get ordinaryItems() {
    return this.items.filter((message) => message instanceof Activity || !message.is_schedule)
  }

  get isEmpty(): boolean {
    return this.items.length === 0
  }

  get messages(): Array<Message> {
    const messages: Array<Message> = []
    this.items.forEach((item) => {
      if (item instanceof Message) {
        messages.push(item)
      }
    })
    return messages
  }

  get isConversationNew() {
    return this._currentConversationId === 0
  }

  get priority() {
    return this.conversation?.priority
  }

  get typings(): Typing[] {
    return Array.from(this._typingsMap.values())
  }

  get typingsCount() {
    return this.typings.length
  }

  get viewings(): Viewing[] {
    return Array.from(this._viewingsMap.values())
  }

  get showDoubleOptInButton() {
    return !this.contact?.isOptedIn && organizationStore.doubleOptInEnabled
  }

  get warningText() {
    return this._warning
  }

  onLoadMediaContent = () => {
    if (this.isBottomFixed) this.setScrollBottomTrigger()
  }

  setWarningText = (text: string | null) => {
    this._warning = text
  }

  getContactTZ = () => {
    return this.contact?.timezone || ''
  }

  initCancelTokenSource = () => {
    this._cancelTokenSource?.cancel()
    this._cancelTokenSource = axios.CancelToken.source()
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  reset = () => {
    this.setLoading(true)
    this._hasPrevious = false
    this._loadingMessages = false
    this._page = 1
    this._loadingBeforeId = 0
    this.resetMessage()
    this.setScheduleMessage(false)
    this._isEditMessage = false
    this._typingsMap.clear()
    this._viewingsMap.clear()
    this._canSendViewing = true
    this._cancelRequest.reset()

    this._conversationMessageFieldStore.dispose()
  }

  resetReactions = () => {
    this._conversationMessageFieldStore.disposeReactions()

    this._disposeIsUserShowActivity?.()
    this._disposeIsClickToUndo?.()
    this._disposeConversation?.()
    this._disposeActiveTab?.()
  }

  resetCurrentConversationId = () => {
    this._currentConversationId = null
  }

  startSendProcess = async (
    message: IParamsCreateMessage,
    scheduledData?: IScheduledData,
    conversationId?: number,
    withUndo?: boolean
  ) => {
    await this._startSendMessage?.()

    if (this.isConversationNew) {
      this._conversationNewSearchStore?.handleCreateConversation(
        scheduledData,
        false,
        message,
        withUndo
      )
    } else {
      if (this.messageFieldStore.promiseAttachmentLoading) {
        this.messageFieldStore.setLoading(true)
        await this.messageFieldStore.promiseAttachmentLoading
      }

      this.sendMessage({
        message,
        conversationId,
      })

      if (this._currentConversationId === conversationId || !conversationId) {
        this.messageFieldStore.reset()
      }

      this._finishSendMessage?.()
    }
  }

  showUndoProcess = (scheduledData?: IScheduledData) => {
    const id = parseInt(nanoid().replace(/\D/g, ''), 10)
    const toastId = String(id)
    const timer = 5000
    const message = this._conversationMessageFieldStore.getMessageToSend(scheduledData)

    const client_id = nanoid()
    const undoPendingMessage: Message = new Message(undefined, {
      requestMessage: {
        media_url: message.media_url,
        body: message.message,
        send_at: new Date().toISOString(),
        sent_at: new Date().toISOString(),
        type: 'sms',
        sending: false,
        undoPending: true,
        conversation_id: this.conversationId,
      },
      client_id,
    })

    this._itemsMap.set(client_id, undoPendingMessage)
    if (this._handleReceiveNewScroll) this.setReceivedNewMessageTrigger()

    const timeoutId = setTimeout(() => {
      this.startSendProcess(message, scheduledData, undoPendingMessage.conversation_id, true)
      this._itemsMap.delete(client_id)
      toastStore.remove(toastId)
    }, timer)

    toastStore.add({
      type: 'info',
      id: toastId,
      title: 'Sending in',
      withCountdown: true,
      timer,
      action: {
        text: 'Undo',
        onAction: () => {
          toastStore.remove(toastId)
          clearInterval(timeoutId)
          this._itemsMap.delete(client_id)
        },
      },
      secondAction: {
        text: 'Instant send',
        onAction: () => {
          this.startSendProcess(message, scheduledData, undoPendingMessage.conversation_id, true)
          this._itemsMap.delete(client_id)
          toastStore.remove(toastId)
          clearInterval(timeoutId)
        },
      },
    })

    this.messageFieldStore.reset()
  }

  onSendAction = async ({ scheduledData }: { scheduledData?: IScheduledData }) => {
    const checkError = this._isCheckInbox ? this._conversationNewSearchStore?.checkError() : false

    if (checkError) return
    if (this.messageFieldStore.disablesSend) return

    this._conversationMessageFieldStore.conversationMessageFieldDraftStore.handleResetDraft()

    const message = this._conversationMessageFieldStore.getMessageToSend(scheduledData)

    const scheduleMessage = message.send_at
    const noteMessage = message.type === 'note'
    const undoMessage = this._isClickToUndo && !scheduleMessage && !noteMessage

    undoMessage && !this.isConversationNew
      ? this.showUndoProcess(scheduledData)
      : this.startSendProcess(message, scheduledData, this.conversationId, undoMessage)
  }

  onCloseEdit = () => {
    this._isEditMessage = false
    this.setScheduleMessage(false)
    this.messageFieldStore.setEditMessage(null)
  }

  onSaveEditMessageAction = () => {
    if (!this.messageFieldStore.editMessage) {
      return
    }

    this?.updateMessage({
      message: this.messageFieldStore?.paramsUpdateMessage,
      messageId: this.messageFieldStore.editMessage?.id,
    })
    this.onCloseEdit()
  }

  getParamsCreateSuggestAnswer = (): IParamsCreateSuggestAnswer => {
    return {
      messages: this.messages
        .filter((message) => (message.type === 'sms' || message.type === 'mms') && message.body)
        .slice(-20)
        .map((message) => ({
          message: message.body,
          first_name: message.first_name,
          direction: message.isOutbound ? 'outbound' : 'inbound',
        })) as IParamsCreateSuggestAnswer['messages'],
    }
  }

  getParamsCreateSummaryAnswer = (): IParamsCreateSummaryAnswer => {
    return {
      messages: this.messages
        .filter(
          (message) =>
            (message.type === 'sms' ||
              message.type === 'mms' ||
              message.type === 'note' ||
              (message.type === 'call' && message.transcriptions)) &&
            message.body
        )
        .slice(-20)
        .map((message) => ({
          message: message.body.slice(0, 1600),
          first_name: message.first_name || message.phone_number,
          direction: message.isOutbound ? 'outbound' : 'inbound',
        })) as IParamsCreateSummaryAnswer['messages'],
    }
  }

  setConversationId = async (conversationId: number, isLoading = true) => {
    if (this._currentConversationId === conversationId) return
    this._currentConversationId = conversationId
    this._conversationMessageFieldStore.conversationMessageFieldDraftStore.handleSetDraft()

    if (isLoading) {
      this.loadData()
    }

    if (this.showDoubleOptInButton) {
      this.messageFieldStore.setDisabledMessageField(this.showDoubleOptInButton)
    }
  }

  resetMessage = () => {
    this._itemsMap.clear()
  }

  loadConversation = async () => {
    try {
      runInAction(() => {
        this._loadingConversation = true
      })

      const id = Number(this.conversationId)

      if (!id) return

      this._cancelRequest.init()

      const conversation = await conversationStore.getById({
        id,
        isModalError: true,
        isFetch: true,
        update: true,
        options: {
          cancelToken: this._cancelRequest.token,
        },
      })

      if (conversation) {
        conversationStore.addItemDraft(conversation.origin.draft_message)
      }
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loadingConversation = false
      })
    }
  }

  loadMessages = async (page?: number) => {
    try {
      if (!this.conversationId) {
        this.setLoading(false)
        return
      }
      if (this._loadingMessages) return
      runInAction(() => {
        this._loadingMessages = true
        this._page = page || 1
      })

      this.initCancelTokenSource()

      const response = await MessagesApi.getMessagesByConversationId(
        {
          conversationId: +this.conversationId,
          page: this._page,
          per_page: this._per_page,
          with_activity_logs: this._isShowActivity ? 1 : 0,
        },
        {
          cancelToken: this._cancelTokenSource?.token,
        }
      )

      if (response?.data) {
        runInAction(() => {
          this.setItems(response.data.data)
          this._hasPrevious = response.data.total > this.allMessages.length
          if (this._page === 1) this.setScrollBottomTrigger()
        })
      }

      runInAction(() => {
        this._loadingMessages = false
      })
      this.setLoading(false)
    } catch (e) {
      logger.error(e)

      if (isCancel(e) && !this.isConversationNew) {
        runInAction(() => {
          this._loadingMessages = true
        })
        this.setLoading(true)
      } else {
        runInAction(() => {
          this._loadingMessages = false
        })
        this.setLoading(false)
      }
    }
  }

  loadMessagesContact = async ({ before }: { before?: number | string } = {}) => {
    try {
      const selectedContacts = this._conversationNewSearchStore?.items || []
      const selectedInboxId = this._conversationNewSearchStore?.activeInboxId
      let messages: IResponseMessage[] = []
      let hasPrevious = false

      if (this.loading) return
      if (!selectedContacts.length) return
      if (!selectedInboxId) return

      this.setLoading(true)

      if (selectedContacts.length === 1) {
        const contact = selectedContacts[0]
        const params: IParamsMessagesGetContactsByIdTeamsById = {
          grouped_response: true,
          limit: 20,
        }

        if (before) {
          params.before = before
        }

        this.initCancelTokenSource()

        const { data } = await MessagesApi.getMessagesContactsByIdTeamsById(
          contact.id,
          selectedInboxId,
          params,
          {
            cancelToken: this._cancelTokenSource?.token,
          }
        )

        messages = data.messages
        hasPrevious = data.hasPrevious
      } else {
        const { data } = await MessagesApi.getMessagesContacts({
          contacts: selectedContacts.map((item) => item.id),
          teamId: selectedInboxId,
          limit: 20,
          before,
        })

        messages = data
        hasPrevious = data.length === 20
      }

      if (messages) {
        runInAction(() => {
          this.setItems(messages)

          this._hasNext = false
          this._hasPrevious = hasPrevious
        })
      }
    } catch (e) {
      logger.error(e)
    } finally {
      this.setLoading(false)
    }
  }

  loadData = async () => {
    if (this.isConversationNew) {
      this.initCancelTokenSource()
      this.setLoading(false)
    } else {
      await this.loadMessages()
    }
  }

  loadPreviousMessages = async (onSuccess?: () => void) => {
    if (!this.hasPrevious) return
    if (this._loadingPrevious) return

    runInAction(() => {
      this._loadingBeforeId = this.beforeId
      this._loadingPrevious = true
    })

    try {
      if (this.isConversationNew) {
        await this.loadMessagesContact({
          before: this._loadingBeforeId,
        })
      } else {
        await this.loadMessages(this._page + 1)
      }

      onSuccess?.()
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this._loadingPrevious = false
      })
    }
  }

  async sendMessage({
    message,
    conversationId,
  }: {
    message: IParamsCreateMessage
    conversationId?: number
  }) {
    const client_id = nanoid()
    const sendingMessage = new Message(undefined, {
      requestMessage: {
        media_url: message.media_url,
        body: message.message,
        send_at: message.send_at,
        type: message.type,
        conversation_id: conversationId || +this.conversationId,
      },
      client_id,
    })

    runInAction(() => {
      this._itemsMap.set(client_id, sendingMessage)
    })

    if (this._handleReceiveNewScroll) this.setReceivedNewMessageTrigger()

    try {
      const payload: IParamsCreateMessage = {
        ...message,
        client_id,
      }

      const { data } = await MessagesApi.createMessage(
        conversationId || +this.conversationId,
        payload
      )

      runInAction(() => {
        this._sendingMessagesIdsMap.set(data.id, client_id)
        this.messageFieldStore.clearSendAlert()
      })

      if (data) {
        const { data: updatedConversation } = await ConversationsApi.updateByIdRead(
          conversationId || this.conversationId
        )

        conversationStore.updateItem(updatedConversation)

        if (conversationStore.currentItemId === data.conversation_id) {
          this.setItems([{ ...data, client_id }])
        }
      }
    } catch (error) {
      runInAction(() => {
        const alert = getSendMessageAlert(error)
        this.messageFieldStore.setSendAlert(alert)
        this._itemsMap.delete(client_id)
        logger.error(error)
      })
    }
  }

  async updateMessage({
    message,
    messageId,
  }: {
    message: IParamsUpdateMessage
    messageId: number
  }) {
    try {
      runInAction(() => {
        const clientId = this._sendingMessagesIdsMap.get(messageId) || messageId
        const oldMessage = this._itemsMap.get(clientId)
        if (oldMessage && oldMessage instanceof Message) {
          oldMessage.sending = true
          this._itemsMap.set(clientId, oldMessage)
        }
      })
      const { data } = await MessagesApi.updateMessage(messageId, message)
      if (data) {
        this.setItems([data])
        this.setScrollBottomTrigger()
      }
    } catch (e) {
      logger.error(e)
    }
  }

  setItems = (items: Array<IResponseMessage | IResponseActivity>) => {
    if (items.length === 1 && items[0].type === 'call' && userSettingsStore.isHideCalls) return

    items.forEach((message) => {
      if (message.item_type === 'message' && message.client_id) {
        this._sendingMessagesIdsMap.set(message.id, message.client_id)
      }
    })

    const iItems = items.map((item) => {
      if (item.item_type === 'activity_log') {
        return new Activity(item)
      }
      const client_id = this._sendingMessagesIdsMap.get(item.id) || item.id
      return this.addItem(item, client_id)
    })

    let links: string[] = []

    try {
      iItems.forEach((item) => {
        if (item instanceof Message) {
          links = links.concat(item.links)
          this._itemsMap.set(item.client_id, item)
        } else {
          this._itemsMap.set(item.id, item)
        }
      })
    } catch (e) {
      logger.error(e)
    }

    if (this._handleReceiveNewScroll) {
      this.setReceivedNewMessageTrigger()
    }
    attachmentStore.checkPreviousAttachment(links)
  }

  setHandleReceiveNewScroll = (value: boolean) => {
    this._handleReceiveNewScroll = value
  }

  updateTranscript = (item: IResponseMessage) => {
    const message = this._itemsMap.get(item.id)
    if (message instanceof Message) {
      message.setTranscript(item)
    }
  }

  updateTranscriptStatus = (id: number, status: string) => {
    const message = this._itemsMap.get(id)
    if (message instanceof Message) {
      message.setTranscriptStatus(status)
    }
  }

  updateRecordTranscriptStatus = (id: number, status: IResponseRecordTranscriptStatus) => {
    const message = this._itemsMap.get(id)
    if (message instanceof Message) {
      message.setRecordTranscriptStatus(status)
    }
  }

  addItem = (item: IResponseMessage, client_id: string | number) => {
    const message = new Message({ ...item, client_id })

    if (Array.isArray(item.pending_mentions)) {
      item.pending_mentions.forEach((item) => {
        usersStore.addItem(item)
      })
    }

    return message
  }

  async retry(id: number | string) {
    try {
      const { data } = await MessagesApi.retry(id)
      this.setItems([data])
    } catch (e) {
      logger.error(e)
    }
  }

  async delete(id: number) {
    try {
      await MessagesApi.deleteMessage(id)
      const clientId = this._sendingMessagesIdsMap.get(id) || id
      this._itemsMap.delete(clientId)
      if (!this.isConversationNew) await this._conversationMessageFieldStore.handleRead()
    } catch (e) {
      logger.error(e)
    }
  }

  setScrollBottomTrigger = () => {
    this._scrollBottomTrigger = nanoid()
  }

  setReceivedNewMessageTrigger = () => {
    if (this.isBottomFixed) this.setScrollBottomTrigger()
  }

  setIsBottomFixed = (value: boolean) => {
    this._isBottomFixed = value
  }

  setScheduleMessage = (value: boolean) => {
    this._isScheduleMessage = value
  }

  setIsEditMessage = (isEditMessage: boolean) => {
    this._isEditMessage = isEditMessage
  }

  onToggleActivity = () => {
    this.reset()

    this._isShowActivity = !this._isShowActivity

    userSettingsStore.updateSetting({
      value: this._isShowActivity,
      featureKey: 'conversation',
      settingsKey: 'show_activity',
    })
    this.loadData()
  }

  onToggleHideCalls = async () => {
    try {
      runInAction(() => {
        this.reset()
        this._isHideCalls = !this._isHideCalls
      })

      await userSettingsStore.updateSetting({
        value: this._isHideCalls,
        featureKey: 'conversation',
        settingsKey: 'hide_calls',
      })
      this.loadData()
    } catch (e) {
      logger.error(e)

      runInAction(() => {
        this._isHideCalls = !this._isHideCalls
      })
    }
  }

  onToggleUndoMessage = () => {
    this._isClickToUndo = !this._isClickToUndo

    userSettingsStore.updateSetting({
      value: this._isClickToUndo,
      featureKey: 'message-form',
      settingsKey: 'click_to_undo',
    })
  }

  setIsActiveTab = (value: boolean) => {
    this._isActiveTab = value
  }

  onViewing = () => {
    if (this._onViewingTimerId) clearTimeout(this._onViewingTimerId)
    if (this._canSendViewing && this._isActiveTab && !!this.conversation?.id) {
      websocket.sendEvent(
        `private-inbox.${this.conversation?.inbox_id}.conversation`,
        'client-viewing',
        {
          id: usersStore.user?.id,
          first_name: usersStore.user?.first_name,
          last_name: usersStore.user?.last_name,
          conversation_id: this.conversation?.id,
        }
      )
      this._canSendViewing = false

      this._onViewingTimerId = setTimeout(() => {
        this._canSendViewing = true
        this.onViewing()
      }, this._throttleViewingTime)
    }
  }

  handleTypingEvent = (data: IResponseEventTyping) => {
    if (data.conversation_id !== Number(this.conversationId)) return

    const timerId = setTimeout(() => {
      this._typingsMap.delete(data.id)
      this.updateViewingItemStatus(data.id, 'viewing')
    }, 2000)
    const newTyping = new Typing(data, timerId)
    const oldTyping = this._typingsMap.get(newTyping.userId)
    if (oldTyping) {
      clearTimeout(oldTyping.timerId)
    }
    this._typingsMap.set(newTyping.userId, newTyping)
    this.updateViewingItemStatus(data.id, 'typing')
  }

  handleViewingEvent = (data: IResponseEventViewing) => {
    if (data.conversation_id !== Number(this.conversationId)) return

    const timerId = setTimeout(() => {
      this._viewingsMap.delete(data.id)
    }, 35000)
    const status: IViewingStatus = this._typingsMap.get(data.id) ? 'typing' : 'viewing'

    const newViewing = new Viewing(data, timerId, status)
    const oldViewing = this._viewingsMap.get(newViewing.userId)
    if (oldViewing) {
      clearTimeout(oldViewing.timerId)
    }
    this._viewingsMap.set(newViewing.userId, newViewing)
  }

  updateViewingItemStatus = (id: number, status: IViewingStatus) => {
    const current = this._viewingsMap.get(id)
    if (current) {
      current.status = status
    }
  }

  handleContactOptInRequest = async () => {
    try {
      this.messageFieldStore.setLoading(true)

      const conversationId = conversationStore.currentItem?.id

      if (!conversationId) return

      const { data } = await ContactsApi.updateContactOptInRequestById(conversationId)

      contactsStore.updateItem(data.contact)
      await this._conversationMessageFieldStore.handleRead()
    } catch (e) {
      logger.error(e)
    } finally {
      this.messageFieldStore.setLoading(false)
    }
  }

  get isScheduleMessage() {
    return this._isScheduleMessage
  }

  get disabledDoubleOptIn() {
    const number_id = this.conversation?.number_id

    return Boolean(
      this.loading ||
        !userPermissionsStore.getItem('can_manage_contacts') ||
        this.contact?.getReceivedOptInRequestFromNumber(number_id) ||
        this.contact?.opt_out
    )
  }

  get isSmallAirCallAttach() {
    return this.messageFieldStore.isSmallAirCallAttach
  }

  setHideTrialAlertNotForOwner = () => {
    this._isHideTrialAlertNotForOwner = true
  }

  get callModalStore() {
    return this._callModalStore
  }

  get conversationListStore() {
    return this._conversationListStore
  }

  get conversationSearchStore() {
    return this._conversationSearchStore
  }

  get conversationNewSearchStore() {
    return this._conversationNewSearchStore
  }

  get hasPrevious() {
    return this._hasPrevious
  }

  get loading() {
    return this._loading
  }

  get loadingPrevious() {
    return this._loadingPrevious
  }

  get loadingMessages() {
    return this._loadingMessages
  }

  get loadingConversation() {
    return this._loadingConversation
  }

  get messageFieldStore() {
    return this._conversationMessageFieldStore.messageFieldStore
  }

  get scrollBottomTrigger() {
    return this._scrollBottomTrigger
  }

  get isShowActivity() {
    return this._isShowActivity
  }

  get isHideCalls() {
    return this._isHideCalls
  }

  get isHideTrialAlertNotForOwner() {
    return this._isHideTrialAlertNotForOwner
  }

  get currentConversationId() {
    return this._currentConversationId
  }

  get loadingBeforeId() {
    return this._loadingBeforeId
  }

  get messageSignatureStore() {
    return this._conversationMessageFieldStore.messageSignatureStore
  }

  get isEditMessage() {
    return this._isEditMessage
  }

  get isClickToUndo() {
    return this._isClickToUndo
  }

  get isModeNoteObj() {
    return this._isModeNoteObj
  }

  setHideAvatars = (value: boolean) => {
    this._isHideAvatars = value
  }

  onBack = () => {
    if (this._conversationSearchStore?.hasSearchParams) {
      this._conversationSearchStore.setHide(false)
    }
    this._pageLayoutStore?.toStartContainer()
    this.messageFieldStore.setBlurMessageFieldTrigger()
  }
}
