// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oEg1ILCzkBPGZav7AKC3{width:fit-content;display:flex;gap:8px;padding-top:3px}.oEg1ILCzkBPGZav7AKC3>div:nth-last-child(2) [data-name=attachmentRemovable] button{right:-1px}.HausuHSGvXzQi9JHTojf .aOp5zCxgFZkU9cUIsmUF{display:none}.HausuHSGvXzQi9JHTojf [data-name=attachmentCard],.HausuHSGvXzQi9JHTojf [data-name=attachmentRemovable]{background-color:var(--background-neutral-default)}.HausuHSGvXzQi9JHTojf [data-name=attachmentRemovable]:after{display:none}.aOp5zCxgFZkU9cUIsmUF{width:4px}.ONrAgYmxeG6HZT_qLmm_{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageField/ui/AttachmentContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,OAAA,CACA,eAAA,CAKM,mFACE,UAAA,CAQR,4CACE,YAAA,CAGF,uGAEE,kDAAA,CAIA,4DACE,YAAA,CAKN,sBACE,SAAA,CAGF,sBACE,iBAAA","sourcesContent":[".inner {\n  width: fit-content;\n  display: flex;\n  gap: 8px;\n  padding-top: 3px;\n\n  & > div {\n    &:nth-last-child(2) {\n      [data-name='attachmentRemovable'] {\n        button {\n          right: -1px;\n        }\n      }\n    }\n  }\n}\n\n.isBroadcast {\n  .box4 {\n    display: none;\n  }\n\n  [data-name='attachmentCard'],\n  [data-name='attachmentRemovable'] {\n    background-color: var(--background-neutral-default);\n  }\n\n  [data-name='attachmentRemovable'] {\n    &:after {\n      display: none;\n    }\n  }\n}\n\n.box4 {\n  width: 4px;\n}\n\n.alertContainer {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "oEg1ILCzkBPGZav7AKC3",
	"isBroadcast": "HausuHSGvXzQi9JHTojf",
	"box4": "aOp5zCxgFZkU9cUIsmUF",
	"alertContainer": "ONrAgYmxeG6HZT_qLmm_"
};
export default ___CSS_LOADER_EXPORT___;
