export function placeCaretAtEnd(el: HTMLDivElement | Node) {
  if (el instanceof HTMLDivElement) {
    el.focus()
  }
  if (
    typeof window.getSelection != 'undefined' &&
    typeof window.document.createRange != 'undefined'
  ) {
    const range = window.document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    if (sel) {
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}
