import { observer } from 'mobx-react-lite'

import { FilterDropdown, IFilterDropdownProps } from './ui/FilterDropdown'

export type IFilterEditorAddProps = Omit<IFilterDropdownProps, 'onSelect'> & {
  onChange?: (key: string) => void
}

export const FilterEditorAdd = observer(({ store, onChange, ...props }: IFilterEditorAddProps) => {
  const createFilter = (key: string) => {
    store.groups.createGroup().createFilter(key)
    onChange?.(key)
  }

  return <FilterDropdown {...props} store={store} onSelect={createFilter} />
})
