import { FC } from 'react'
import { ITextErrorProps, TextError } from 'shared/ui/Error/ui/TextError/TextError'
import { ITooltipErrorProps, TooltipError } from 'shared/ui/Error/ui/TooltipError/TooltipError'

type IErrorProps = ({ type: 'text' } & ITextErrorProps) | ({ type: 'tooltip' } & ITooltipErrorProps)

export const Error: FC<IErrorProps> = ({ type, ...props }) => {
  if (type === 'text') {
    return <TextError {...props} />
  }
  if (type === 'tooltip') {
    return <TooltipError {...props} />
  }
  return null
}
