// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HRp33Z0GnVrvU5cJWF2A{background-color:var(--background-primary-Inverted-primary);border:1px solid var(--border-neutral-default);padding:16px 12px 12px 12px;max-width:540px;margin:12px 0 0 56px;border-radius:6px}.jT6XKP3AyeCfRCXhFK6g{color:var(--content-primary-tertiary);margin-bottom:15px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageCard/ui/Mentions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2DAAA,CACA,8CAAA,CACA,2BAAA,CACA,eAAA,CACA,oBAAA,CACA,iBAAA,CAIF,sBACE,qCAAA,CACA,kBAAA","sourcesContent":[".wrap {\n  background-color: var(--background-primary-Inverted-primary);\n  border: 1px solid var(--border-neutral-default);\n  padding: 16px 12px 12px 12px;\n  max-width: 540px;\n  margin: 12px 0 0 56px;\n  border-radius: 6px;\n\n}\n\n.title {\n  color: var(--content-primary-tertiary);\n  margin-bottom: 15px;\n}\n\n.section {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "HRp33Z0GnVrvU5cJWF2A",
	"title": "jT6XKP3AyeCfRCXhFK6g"
};
export default ___CSS_LOADER_EXPORT___;
