import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { useConversationListContext } from 'widgets/ConversationList/context'

export const CloseAction = observer(() => {
  const store = useConversationListContext()
  const { selectedHasOpenConversation, undoCloseConversations, reopenConversations } = store

  const handleClose = async () => {
    await (selectedHasOpenConversation ? undoCloseConversations : reopenConversations)()
  }

  return (
    <IconButton
      icon={selectedHasOpenConversation ? 'check1' : 'back'}
      tooltipProps={{
        placement: 'bottom',
        label: selectedHasOpenConversation ? 'Close conversation' : 'Reopen conversation',
        disableInteractive: true,
      }}
      onClick={handleClose}
      variant={'icon'}
      color='secondary'
      size={'medium'}
      ariaLabel='CloseAction_handleClose'
    />
  )
})
