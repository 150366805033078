// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wStsHC0oDLgfguIn6Lb0{display:flex;flex-direction:row;gap:8px;align-items:center}.K7Bv4CG2M2sPGFwGZ8d2{color:var(--content-primary-primary);font-size:13px;font-weight:400;cursor:pointer;display:flex;flex-direction:row;align-items:center;gap:8px}.N39Dz1vNDhpCv8iTtVBR{display:flex;justify-content:center;min-width:32px;max-width:32px;pointer-events:none}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotButtons/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CAGJ,sBACI,oCAAA,CACA,cAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAEA,sBACI,YAAA,CACA,sBAAA,CACA,cAAA,CACA,cAAA,CACA,mBAAA","sourcesContent":[".buttonGroup {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    align-items: center;\n}\n\n.publishButton {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    font-weight: 400;\n    cursor: pointer;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 8px;\n\n    &Control {\n        display: flex;\n        justify-content: center;\n        min-width: 32px;\n        max-width: 32px;\n        pointer-events: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": "wStsHC0oDLgfguIn6Lb0",
	"publishButton": "K7Bv4CG2M2sPGFwGZ8d2",
	"publishButtonControl": "N39Dz1vNDhpCv8iTtVBR"
};
export default ___CSS_LOADER_EXPORT___;
