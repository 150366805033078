import { contactTemplate } from 'entities/Contacts/templates/contactTemplate'
import { IResponseConversation } from 'entities/Conversation/api/types'

export const conversationTemplate = {
  id: 0,
  inbox_id: 0,
  contact_id: 0,
  closed_at: '',
  is_group: false,
  is_locked: true,
  last_message_at: '',
  last_inbound_at: '',
  last_read_at: '',
  group_name: null,
  participants: [],
  number_id: 0,
  user_id: 0,
  last_manual_message_at: '',
  has_tagging: false,
  tagging_last_message_at: '',
  tagging_last_read_at: '',
  started_at: '',
  name: null,
  reassign_logs: [],
  unread_count: 0,
  is_favorite: false,
  is_need_response: false,
  favorite_for_users: [],
  allow_call: true,
  contact: contactTemplate,
  draft_message: null,
} as IResponseConversation
