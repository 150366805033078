import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { AlertAddCredits, AlertAddTrialCredits, AlertMaxSegments } from 'widgets/Reminders'
import { TrialAlertTenLimitConversations } from 'widgets/ConversationMessages/ui/ConversationMessageFieldAlerts/TrialAlertTenLimitConversations'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { TrialAlertWatermark } from './TrialAlertWatermark'
import { ConversationChatbotAlerts } from './ConversationChatbotAlert'

export const ConversationMessageFieldAlerts = observer(() => {
  const store = useConversationMessagesContext()
  const {
    conversation,
    isMessageLocal,
    isStatusBlockedContact,
    isMessageAll,
    isStatusUnverified,
    isStatusDeclined,
    isStatusPending,
    isStatusNumberBlocked,
  } = store

  const renderAlertOptOut = () => {
    if (isMessageLocal) {
      return (
        <Alert
          item={{
            desc: 'This contact has been opted-out and will not receive messages',
            type: 'warning',
          }}
        />
      )
    }

    if (isMessageAll) {
      return (
        <Alert
          item={{
            desc: 'This contact has unsubscribed and will not receive messages',
            type: 'warning',
          }}
        />
      )
    }

    return null
  }

  const handleVerifyNumber = () => {
    uiStore.changeRoute({
      path: '/settings/organization/compliance',
      type: 'vue',
    })
  }

  const handleContactSupport = () => {
    window.open('https://help.salesmessage.com/en/', '_blank')
  }

  return (
    <>
      {store.enableTrialAlertLimitConversations && (
        <TrialAlertTenLimitConversations onCloseTrialAlert={store.setHideTrialAlertNotForOwner} />
      )}
      {renderAlertOptOut()}
      {isStatusUnverified && (
        <Alert
          item={{
            title: 'Ready to verify your number?',
            desc: (
              <>Verify your number to avoid carrier filtering and ensure maximum deliverability.</>
            ),
            type: 'infoBlue',
          }}
          actions={
            <Button
              text={'Verify now'}
              typeBtn={'outlined'}
              size={'small'}
              contained={'secondary'}
              onClick={handleVerifyNumber}
            />
          }
        />
      )}
      {isStatusPending && (
        <Alert
          item={{
            title: 'Verification in progress',
            desc: 'Your Toll-Free number verification is ongoing.',
            type: 'infoBlue',
          }}
        />
      )}
      {(isStatusNumberBlocked || isStatusDeclined) && (
        <Alert
          item={{
            title: 'Verification unsuccessful',
            desc: (
              <>
                Your Toll-Free number verification was not successful. Please contact support for
                further details.
              </>
            ),
            type: 'error',
          }}
          actions={
            <Button
              text={'Contact support'}
              typeBtn={'outlined'}
              size={'small'}
              contained={'secondary'}
              onClick={handleContactSupport}
            />
          }
        />
      )}
      {isStatusBlockedContact && (
        <Alert
          item={{
            desc: <>{'This contact has been blocked and you won’t receive any more messages'}</>,
            type: 'warning',
          }}
        />
      )}

      <AlertAddCredits />
      <AlertMaxSegments />
      <AlertAddTrialCredits />
      <TrialAlertWatermark
        isHideTrialAlert={store.isHideTrialAlertNotForOwner}
        onCloseTrialAlert={store.setHideTrialAlertNotForOwner}
      />
      <ConversationChatbotAlerts conversation={conversation} />
    </>
  )
})
