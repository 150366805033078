import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Select } from 'shared/ui/Select/Select'
import { EnumDropdownItemVariant, IDropdownItem } from 'shared/ui'
import { useFilterEditorContext } from 'widgets/FilterEditor/context'
import type { FilterItem } from 'widgets/FilterEditor/model'

import styles from './styles.module.scss'

export interface IWidgetOperatorsProps {
  filterItem: FilterItem
  parentsDropdownId?: string[]
}

export const WidgetOperators = observer(
  ({ filterItem, parentsDropdownId }: IWidgetOperatorsProps) => {
    const { viewOnly } = useFilterEditorContext()
    const dropdownItems = useMemo(
      () =>
        filterItem.operators.map(
          (operator) =>
            ({
              id: operator.key,
              value: operator.key,
              label: operator.name,
              activeValue: filterItem.operator?.key === operator.key,
              iconR: operator.allow_empty_checkbox ? 'chevronRight' : undefined,
              variant: EnumDropdownItemVariant.CheckedLeft,
              children: operator.child.map((item) => ({
                id: item.key,
                value: operator.key,
                variant: EnumDropdownItemVariant.CheckedLeft,
                label: item.name,
                activeValue:
                  filterItem.operator?.key === operator.key && operator.is_empty === item.key,
                onClick: () => {
                  operator.setIsEmpty(item.key)
                },
              })),
            } as IDropdownItem)
        ),
      [filterItem.operator?.key, filterItem.operator?.is_empty, filterItem.operators]
    )

    const operator = filterItem.operators.find(
      (operator) => filterItem.operator?.key === operator.key
    )
    if (!operator) return null

    return (
      <Select
        type='default'
        text={operator.name}
        disabled={viewOnly}
        items={dropdownItems}
        dropdownProps={{
          ariaLabel: 'FilterOperators',
          width: 215,
          parentsDropdownId: parentsDropdownId,
          childContainerWidth: 237,
          withSearch: false,
          className: styles.operatorDropdown,
          triggerComponent: () => (
            <div className={styles.selectTriggerComponent}>
              <span className={styles.nowrap}>{operator.name}</span>
              {!!filterItem.operator?.is_empty && (
                <>
                  <span className={styles.deliver} />
                  <span className={styles.nowrap}>include empty</span>
                </>
              )}
            </div>
          ),
          onChange: ({ value }) => !!value && filterItem.setOperator(value),
        }}
      />
    )
  }
)
