import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon, Typography } from 'shared/ui'
import { useScheduleContext } from 'shared/ui/Schedule'
import { TimezoneContent } from 'shared/ui/Schedule/ui/TimezoneContent/TimezoneContent'
import styles from './styles.module.scss'

type IScheduleTriggerProps = {
  notInteractive?: boolean
}

export const ScheduleTrigger = observer(({ notInteractive }: IScheduleTriggerProps) => {
  const {
    scheduleText,
    advancedText,
    repeatText,
    advancedTextTime,
    is_contact_timezone,
    isScheduleTimezoneContent,
  } = useScheduleContext()

  const timeZoneContent = <TimezoneContent is_contact_timezone={is_contact_timezone} />

  return (
    <div className={classNames(styles.trigger, { [styles.notInteractive]: notInteractive })}>
      {scheduleText && (
        <div className={styles.trigger__item}>
          <div className={styles.trigger__icon}>
            <Icon icon={'schedule'} fontSize={16} />
          </div>
          <Typography variant={'text-input-field'} ariaLabel={'scheduleText'}>
            {scheduleText}
          </Typography>
          {isScheduleTimezoneContent && timeZoneContent}
        </div>
      )}
      {repeatText && (
        <>
          <div className={styles.trigger__item}>
            <div className={styles.trigger__icon}>
              <Icon icon={'repeat'} fontSize={16} />
            </div>
            <div>
              <Typography
                className={styles.trigger__text}
                variant={'text-input-field'}
                ariaLabel={'repeatText'}
              >
                {repeatText}
              </Typography>
            </div>
          </div>
        </>
      )}
      {advancedText && (
        <>
          <div className={styles.trigger__item}>
            <div className={styles.trigger__icon}>
              <Icon icon={'sla2'} fontSize={16} />
            </div>
            <div>
              <Typography
                className={styles.trigger__text}
                variant={'text-input-field'}
                ariaLabel={'advancedText'}
              >
                {advancedText}
              </Typography>
              {advancedTextTime && (
                <div className={styles.trigger__row}>
                  <Typography
                    className={styles.trigger__text}
                    variant={'text-input-field'}
                    ariaLabel={'advancedTextTime'}
                  >
                    {advancedTextTime}
                  </Typography>
                  {timeZoneContent}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
})
