import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'

type IAudioCardProps = {
  headerLeft: ReactNode
  headerRight: ReactNode
  content: ReactNode
}

export const UiAudioCard: FC<IAudioCardProps> = ({ headerLeft, headerRight, content }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.left}>{headerLeft}</div>

        <div className={styles.right}>{headerRight}</div>
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  )
}
