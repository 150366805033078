import { makeAutoObservable } from 'mobx'
import type { IAvatarInfo } from 'shared/ui'
import type { IResponseIntegrationContactMatch } from 'entities/Integrations'

export class MatchedContact {
  private _integrationVendorId: string
  private _first_name: string | null
  private _last_name: string | null
  private _email: string | null
  private _full_name: string | null
  private _mobile_phone: string
  private _phone: string
  private _hubspot_team_id: string | null
  private _photo_url: string | null

  constructor(item: IResponseIntegrationContactMatch) {
    this._integrationVendorId = item.id
    this._first_name = item.first_name
    this._last_name = item.last_name
    this._email = item.email
    this._full_name = item.full_name
    this._mobile_phone = item.numbers['Mobile Phone'] || ''
    this._phone = item.numbers.Phone || ''
    this._hubspot_team_id = item.hubspot_team_id
    this._photo_url = item.photo_url

    makeAutoObservable(this)
  }

  get avatarInfo(): IAvatarInfo {
    return {
      firstName: this._first_name || this._email || this._phone || undefined,
      lastName: this._last_name || undefined,
      image: this._photo_url || undefined,
      color: this.generateColor,
      number: this._phone,
      icon: undefined,
    }
  }

  get generateColor() {
    const colors = ['#6372A5', '#1D95F2', '#10D0AD', '#E13466', '#FFC422', '#FF9423', '#A233E1']

    return colors[+this._integrationVendorId % colors.length]
  }

  get name() {
    if (this._first_name || this._last_name) {
      return `${this._first_name} ${this._last_name}`
    }

    return ''
  }

  get integrationVendorId() {
    return this._integrationVendorId
  }

  get mobile_phone() {
    return this._mobile_phone
  }

  get phone() {
    return this._phone
  }

  get first_name() {
    return this._first_name
  }

  get last_name() {
    return this._last_name
  }

  get email() {
    return this._email
  }
}
