import { FC } from 'react'
import classNames from 'classnames'
import { Icon, IconButton, IIconsVector } from 'shared/ui'

import styles from './styles.module.scss'

export type PropsSearchElement = {
  icon?: IIconsVector
  type: string
  warningContent?: boolean
  contentDefaultValue?: string
  paramContent?: React.ReactNode | string
  content?: React.ReactNode | string
  removeCallback: () => void
  isViewOnly?: boolean
}

export const SearchElement: FC<PropsSearchElement> = ({
  icon,
  type,
  warningContent = false,
  contentDefaultValue = '',
  paramContent = null,
  content = null,
  removeCallback,
  isViewOnly,
}) => {
  return (
    <div
      className={classNames(styles.element, {
        [styles.isViewOnly]: isViewOnly,
      })}
    >
      {!!icon && (
        <span className={styles.icon}>
          <Icon icon={icon} />
        </span>
      )}
      <span className={classNames(styles.labelWrapper, styles.hovered)}>
        <span className={styles.type}>{type}</span>
        {paramContent && <div className={styles.paramContentContainer}>{paramContent}</div>}

        <span
          className={classNames(styles.empty, styles.label, {
            [styles.warning]: warningContent,
          })}
        >
          {content || contentDefaultValue}
        </span>
      </span>

      {!isViewOnly && (
        <>
          <div className={styles.divider}></div>
          <span className={styles.icon}>
            <IconButton
              variant={'outlined'}
              icon={'close'}
              size={'medium'}
              tooltipProps={{
                label: 'Remove',
                placement: 'bottom',
              }}
              fontSize={16}
              onClick={removeCallback}
              ariaLabel={'SearchElement_remove'}
            />
          </span>
        </>
      )}
    </div>
  )
}
