import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { ActionItem, IActionsProps, Switch } from 'shared/ui'
import { ChatbotListItem } from 'entities/Chatbot'
import { useChatbotDetailsContext, useChatbotListContext } from 'pages/chatbot/context'
import { ChatbotRoute } from 'pages/chatbot'

import styles from './styles.module.scss'

export type IChatbotRow = {
  id: number
  payload: ChatbotListItem
}

export type IChatbotActionRow = IChatbotRow & {
  actionsProps: IActionsProps
}

export const useRowsWithActions: () => IChatbotActionRow[] = () => {
  const { items, updateStatus, deleteRow } = useChatbotListContext()
  const { showDetails } = useChatbotDetailsContext()
  const [updating, setUpdating] = useState(false)
  const navigate = useNavigate()

  const makeStatusAction = (item: ChatbotListItem): ActionItem | null => {
    if (item.isDraft) return null

    const dropdownItemComponent = () => (
      <div
        className={styles.activeAction}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()

          setUpdating(true)
          updateStatus(item.id, !item.isActive).finally(() => setUpdating(false))
        }}
      >
        <div className={styles.activeActionControl}>
          {updating ? (
            <CircularProgress size={12} />
          ) : (
            <Switch className={styles.activeActionSwitch} size='small' value={item.isActive} />
          )}
        </div>
        {item.isActive ? 'Active' : 'Disabled'}
      </div>
    )

    return { dropdownItemComponent }
  }

  const makeEditAction = (item: ChatbotListItem): ActionItem | null => {
    if (item.isActive) return null

    return {
      icon: 'edit',
      text: 'Edit',
      onAction: () => navigate(`/${ChatbotRoute.root}/${item.id}`),
    }
  }

  const makeDetailsAction = (item: ChatbotListItem): ActionItem | null => {
    return {
      icon: 'eye',
      text: 'Details',
      onAction: () => showDetails(item.id),
    }
  }

  const makeDeleteAction = (item: ChatbotListItem): ActionItem | null => {
    return {
      icon: 'delete',
      text: 'Delete',
      onAction: () => deleteRow(item.id),
    }
  }

  return items.map((item) => {
    const actions = [
      makeStatusAction(item),
      makeEditAction(item),
      makeDetailsAction(item),
      makeDeleteAction(item),
    ].filter((item) => !!item) as ActionItem[]

    return { id: item.id, payload: item, actionsProps: { actions } }
  })
}
