import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { ContactsApi } from 'entities/Contacts'
import { IResponseContactUnlinked } from 'entities/Contacts/api/types'

const defaultPagination = {
  page: 1,
  length: 10,
}

export class ContactsUnlinkedTableStore {
  itemsMap: Map<number, IResponseContactUnlinked> = new Map()
  loading = false
  total = 0
  paginationData = defaultPagination

  constructor() {
    makeAutoObservable(this)
    this.reactionPagination()
  }

  get noResults() {
    return this.total === 0
  }

  init = () => {
    this.load()
  }

  resetPaginationPage = () => {
    this.changePagination(1, this.paginationData.length)
  }

  reset = () => {
    this.paginationData = defaultPagination
    this.loading = false
    this.total = 0
    this.itemsMap.clear()
  }
  changePagination = (page: number, length: number) => {
    this.paginationData = {
      page,
      length,
    }
  }

  load = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await ContactsApi.getContactsUnlinked(this.paginationData)

      const results = data.data || []
      const total = data.total || 0

      this.addItems(results)

      runInAction(() => {
        this.total = total
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  get list() {
    return Array.from(this.itemsMap.values())
  }

  addItems = (contacts: IResponseContactUnlinked[]) => {
    this.itemsMap.clear()

    contacts.forEach((item) => {
      this.itemsMap.set(item.id, item)
    })
  }

  reactionPagination = () => {
    reaction(
      () => this.paginationData,
      () => {
        this.load()
      }
    )
  }
}

export const contactsUnlinkedTableStore = new ContactsUnlinkedTableStore()
