import { observer } from 'mobx-react-lite'
import { type DropResult, type DraggableProvided } from 'react-beautiful-dnd'
import { useEffect } from 'react'
import { useKeyGenerator } from 'shared/hooks'
import { Status, ToggleBlock } from 'shared/ui'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { ConversationInfo } from 'widgets/ContactsDetails/ui/ConversationInfo/ui/ConversationInfo'
import { ContactsGroupCard } from 'widgets/ContactsDetails/ui/ContactsGroupCard/ui/ContactsGroupCard'
import { ContactsInfoForm } from 'widgets/ContactsDetails/ui/ContactsInfoForm'
import { ContactsIntegrations } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/ContactsIntegrations'
import { ContactsTagsForm } from 'widgets/ContactsDetails/ui/ContactsTagsForm'
import { ContactsNotesForm } from 'widgets/ContactsDetails/ui/ContactsNotesForm'
import { ContactsQuickLinks } from 'widgets/ContactsDetails/ui/ContactsQuickLinks'

import { DragEndDropSection } from 'widgets/ContactsDetails/ui/DragEndDropSection/DragEndDropSection'
import styles from './styles.module.scss'

export const ContactsContentItems = observer(() => {
  const contactsDetailsStore = useContactsDetailsContext()
  const {
    isConversationGroup,
    isConversationNew,
    isGroupSelectContact,
    selectedContact,
    draggableItems,
    sectionsItems,
    initDraggableItems,
    reorderDraggableItems,
    isVariantCreateContactModal,
    conversationInfoStore,
    contactInfoFormStore,
    contactsTagsFormStore,
    contactNoteStore,
    contactsQuickLinksStore,
    contactsGroupCardStore,
    contactIntegrationsStore,
  } = contactsDetailsStore

  const getFieldKey = useKeyGenerator<string>()
  const createNewContactMode = selectedContact?.id === -1
  const handleReorder = (result: DropResult) => {
    if (result.destination) reorderDraggableItems(result.source.index, result.destination.index)
  }

  useEffect(() => {
    if (!draggableItems.length) {
      initDraggableItems()
    }

    if (!contactsDetailsGlobalStore.isOpenBlock('contactDetails')) {
      contactsDetailsGlobalStore.toggleBlockView('contactDetails')
    }
  }, [])

  const renderContentItem = (
    key: string,
    isDragging: boolean,
    dragHandleProps?: DraggableProvided['dragHandleProps']
  ) => {
    if (
      key === 'conversation_info' &&
      !isConversationNew &&
      !contactsDetailsStore.isVariantOpenConversations
    ) {
      return (
        <ToggleBlock
          borders={{ bottom: true }}
          isDragging={isDragging}
          dragHandleProps={dragHandleProps}
          title='Conversation'
          hideBottomMargin={true}
          hideDraggingIcon={isVariantCreateContactModal}
          isOpen={contactsDetailsGlobalStore.isOpenBlock('conversation')}
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('conversation')}
        >
          <ConversationInfo store={conversationInfoStore} />
        </ToggleBlock>
      )
    }

    if (key === 'contacts_group' && isConversationGroup) {
      return (
        <ToggleBlock
          borders={{ bottom: true }}
          isDragging={isDragging}
          dragHandleProps={dragHandleProps}
          disabled={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
          title='Contacts'
          padding={'0 11px 23px'}
          margin={'-3px 0 0'}
        >
          <ContactsGroupCard store={contactsGroupCardStore} />
        </ToggleBlock>
      )
    }

    if (key === 'contacts_info' && isGroupSelectContact) {
      return (
        <ToggleBlock
          ariaLabel='openConversationDetailsSelector'
          borders={{ bottom: true }}
          isDragging={isDragging}
          dragHandleProps={dragHandleProps}
          title='Contact details'
          hideBottomMargin={true}
          hideDraggingIcon={isVariantCreateContactModal}
          isOpen={contactsDetailsGlobalStore.isOpenBlock('contactDetails')}
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('contactDetails')}
        >
          <ContactsInfoForm store={contactInfoFormStore} isNewContactMode={createNewContactMode} />
        </ToggleBlock>
      )
    }

    if (key === 'contacts_integrations' && isGroupSelectContact) {
      return (
        <ContactsIntegrations
          store={contactIntegrationsStore}
          isDragging={isDragging}
          dragHandleProps={dragHandleProps}
          createNewContactMode={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
        />
      )
    }

    if (key === 'contacts_tags' && isGroupSelectContact) {
      return (
        <ToggleBlock
          isOpen={createNewContactMode ? false : contactsDetailsGlobalStore.isOpenBlock('tags')}
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('tags')}
          borders={{ bottom: true }}
          isDragging={isDragging}
          dragHandleProps={dragHandleProps}
          disabled={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
          title='Tags'
          ariaLabel='AddTag'
        >
          <ContactsTagsForm store={contactsTagsFormStore} />
        </ToggleBlock>
      )
    }

    if (key === 'contacts_notes' && isGroupSelectContact) {
      return (
        <ToggleBlock
          isOpen={createNewContactMode ? false : contactsDetailsGlobalStore.isOpenBlock('notes')}
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('notes')}
          borders={{ bottom: true }}
          isDragging={isDragging}
          dragHandleProps={dragHandleProps}
          disabled={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
          title='Notes'
          hideBottomMargin={true}
          padding={'0 0 23px'}
        >
          <ContactsNotesForm store={contactNoteStore} />
        </ToggleBlock>
      )
    }

    if (key === 'contacts_quick_links' && isGroupSelectContact) {
      return (
        <ToggleBlock
          isOpen={
            createNewContactMode ? false : contactsDetailsGlobalStore.isOpenBlock('quickLinks')
          }
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('quickLinks')}
          borders={{ bottom: true }}
          isDragging={isDragging}
          dragHandleProps={dragHandleProps}
          disabled={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
          title={
            <span className={styles.titleWithBadge}>
              Quick links
              <Status title={'New'} intent={'purple'} emphasis={'high'} size={'label'} />
            </span>
          }
          hideBottomMargin={true}
          padding={'0 0 23px 20px'}
        >
          <ContactsQuickLinks store={contactsQuickLinksStore} />
        </ToggleBlock>
      )
    }

    return null
  }

  if (isVariantCreateContactModal) {
    return (
      <>
        {sectionsItems.map((key) => (
          <div key={key}>{renderContentItem(key, false)}</div>
        ))}
      </>
    )
  }

  return (
    <DragEndDropSection
      droppableId={'contacts-details'}
      onDragEnd={(result) => {
        handleReorder(result)
      }}
      items={draggableItems.map((item) => ({ key: item }))}
      renderItem={(item, provided, snapshot) => {
        return renderContentItem(item.key, snapshot.isDragging, provided.dragHandleProps)
      }}
    />
  )
})
