import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { FieldDropdown, IFieldDropdownErrorProps } from 'shared/ui'
import type { SendFromDropdownStore } from 'widgets/SendFromDropdown'
import { SendFromDropdownContext } from '../context/sendFromDropdownContext'
import { SendFromTrigger } from './SendFromTrigger'
import { SendFromContent } from './SendFromContent'

type ISendFromDropdownProps = {
  store: SendFromDropdownStore
  error?: IFieldDropdownErrorProps
  onClearError?: () => void
}

export const SendFromDropdown: FC<ISendFromDropdownProps> = observer(
  ({ store, error, onClearError }) => {
    useEffect(() => {
      store.reactionParams()

      return () => {
        store.reset()
      }
    })

    return (
      <SendFromDropdownContext.Provider value={store}>
        <FieldDropdown
          offset={{ x: -4 }}
          labelText={'Send from'}
          error={error || store.error}
          onToggle={() => {
            onClearError?.()
            store.clearError?.()
          }}
          triggerComponent={<SendFromTrigger />}
          customComponent={(_, dropdownId) => <SendFromContent dropdownId={dropdownId} />}
        />
      </SendFromDropdownContext.Provider>
    )
  }
)
