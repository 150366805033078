import { Navigate, Route, Routes, useLocation } from 'react-router'
import { observer } from 'mobx-react-lite'
import { subscriptionStore } from 'entities/Subscription'
import { Compliance } from 'widgets/Compliance'
import { useSettingsContext } from '../context/settingsContext'
import { SettingsLayout } from '../layout/SettingsLayout'
import { SettingsRoutesEnum } from './type'

export const SettingsRoutesContent = observer(() => {
  const { settingsStep, settingsTotal, setTotal, increaseStep } = useSettingsContext()
  const { isCanceled } = subscriptionStore
  const { pathname } = useLocation()

  if (isCanceled && pathname === '/settings/billing/overview') {
    return <Navigate to='/settings/personal/settings/profile' />
  }

  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route index element={<div>Settings Home</div>} />
        <Route
          path={SettingsRoutesEnum.compliance}
          element={
            <Compliance
              settingsStep={settingsStep}
              settingsTotal={settingsTotal}
              setTotal={setTotal}
              increaseStep={increaseStep}
            />
          }
        />
        <Route path='*' element={<div>Page Not Found</div>} />
      </Route>
    </Routes>
  )
})
