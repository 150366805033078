import { makeAutoObservable } from 'mobx'
import {
  IResponseActiveCampaignDeal,
  IResponseActiveCampaignProperties,
  type IResponseIntegrationHubspotDeal,
  IResponseIntegrationsContactsActiveCampaignInfo,
} from 'entities/Integrations'
import type { IIntegrationsProperty } from 'entities/Integrations/model/types'
import { IntegrationDealProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationDealProperty'
import { IntegrationContactProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationContactProperty'

export class ContactIntegrationActiveCampaignInfo {
  private _dealsMap: Map<number, IntegrationDealProperty> = new Map()
  private _accounts: IResponseIntegrationsContactsActiveCampaignInfo['accounts'] | null = null
  private _properties = new IntegrationContactProperty([])

  constructor(item: IResponseIntegrationsContactsActiveCampaignInfo) {
    this._accounts = item.accounts || null

    this.addDeals(item.deals)
    this._properties = new IntegrationContactProperty(this.addProperties(item.properties))

    makeAutoObservable(this)
  }

  get properties() {
    return this._properties
  }

  get deals() {
    return Array.from(this._dealsMap.values())
  }

  get accounts() {
    return {
      id: this._accounts?.id || '0',
      name: this._accounts?.name || '',
    }
  }

  addProperties = (properties: IResponseActiveCampaignProperties) => {
    return Object.entries(properties).reduce<IIntegrationsProperty[]>((state, [key, value]) => {
      if (Array.isArray(value)) return state
      let label = ''

      if (key === 'email') label = 'Email'
      if (key === 'first_name') label = 'First name'
      if (key === 'last_name') label = 'Last name'

      state.push({
        key: key,
        label: label,
        value: value,
      })

      return state
    }, [])
  }

  addProperty = (item: IIntegrationsProperty) => {
    this._properties.addProperty(item)
  }

  addDeals = (deals: IResponseActiveCampaignDeal[]) => {
    const items = deals.map((deal) => {
      return {
        id: {
          value: 0,
          label: null,
        },
        dealname: {
          value: deal.title,
          label: null,
        },
        amount: {
          value: deal.value,
          label: null,
        },
        owner:
          {
            label: 'Owner',
            value:
              deal.owner.first_name || deal.owner.last_name
                ? `${deal.owner.first_name} ${deal.owner.last_name}`
                : '',
          } || null,
      }
    })

    items.forEach((deal) => {
      this.addDeal(deal)
    })
  }

  addDeal = (item: IResponseIntegrationHubspotDeal) => {
    const id = item.id.value || 0

    this._dealsMap.set(id, new IntegrationDealProperty(item))
  }
}
