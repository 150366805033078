// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YsfUpHKtvJcZRiwlGKRl{color:var(--content-negative-primary)}.kg8eIORdfSFXj0FvKUl2{display:block;width:8px;height:8px;border-radius:8px;margin:0 8px 0 8px;background-color:#3eb786}.LTaody5QKz1rkg0s_fkM{display:flex;align-items:center;height:100%}.oWAxCG7nKRSREUAWodTI{display:flex;align-items:center;margin:0 8px 0 8px;position:relative;width:18px}.oWAxCG7nKRSREUAWodTI .PfAzDActgFIjk3HtMO70{position:absolute;display:block;width:10px;height:10px;border-radius:8px;border:2px var(--background-primary-inverted-primary) solid;background-color:#3eb786}.vnVnvZTAWRa_0LfoGJyp{color:var(--content-primary-primary);line-height:normal;font-weight:400;font-size:13px}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/Widget/WidgetMultiUsers/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CAGF,sBACE,aAAA,CACA,SAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA,CACA,wBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,UAAA,CAEA,4CACE,iBAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,2DAAA,CACA,wBAAA,CAIJ,sBACE,oCAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".warning {\n  color: var(--content-negative-primary);\n}\n\n.color {\n  display: block;\n  width: 8px;\n  height: 8px;\n  border-radius: 8px;\n  margin: 0 8px 0 8px;\n  background-color: rgb(62, 183, 134);\n}\n\n.countLabelWrapper {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n\n.countColors {\n  display: flex;\n  align-items: center;\n  margin: 0 8px 0 8px;\n  position: relative;\n  width: 18px;\n\n  .countColor {\n    position: absolute;\n    display: block;\n    width: 10px;\n    height: 10px;\n    border-radius: 8px;\n    border: 2px var(--background-primary-inverted-primary) solid;\n    background-color: rgb(62, 183, 134);\n  }\n}\n\n.countLabel {\n  color: var(--content-primary-primary);\n  line-height: normal;\n  font-weight: 400;\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "YsfUpHKtvJcZRiwlGKRl",
	"color": "kg8eIORdfSFXj0FvKUl2",
	"countLabelWrapper": "LTaody5QKz1rkg0s_fkM",
	"countColors": "oWAxCG7nKRSREUAWodTI",
	"countColor": "PfAzDActgFIjk3HtMO70",
	"countLabel": "vnVnvZTAWRa_0LfoGJyp"
};
export default ___CSS_LOADER_EXPORT___;
