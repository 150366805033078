// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rX7d18TUOBy8UyxGW8wN{display:flex;align-items:center}.GSsWtc37OrbSLZokCPAE .D1PC2ZPRVF5CRefp1XNE{margin-left:8px}.GSsWtc37OrbSLZokCPAE .fNhq3IFVqo4AGJO7NHUW{margin-left:6px}.D1PC2ZPRVF5CRefp1XNE{width:8px;height:8px;border-radius:50%;margin:0 2px}.fNhq3IFVqo4AGJO7NHUW{color:var(--content-primary-primary);max-width:150px;margin-left:6px}.RZZ56jKz5JzsIHx1fPLr{color:var(--content-neutral-primary);margin-left:8px}.bYzzyJuFpahDz9ZUTktN{height:auto;min-height:32px}.hQVGFWyh5HTmi5vUnxAg{background:var(--background-brand-subtle-primary);color:var(--content-primary-tertiary)}.Uh01RJl00EdBW493Sm3R{color:var(--content-primary-primary)}", "",{"version":3,"sources":["webpack://./src/entities/Tags/ui/TagDropdownCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGE,4CACE,eAAA,CAGF,4CACE,eAAA,CAKN,sBACE,SAAA,CACA,UAAA,CACA,iBAAA,CACA,YAAA,CAGF,sBACE,oCAAA,CACA,eAAA,CACA,eAAA,CAGF,sBACE,oCAAA,CACA,eAAA,CAKF,sBACE,WAAA,CACA,eAAA,CAGF,sBACE,iDAAA,CACA,qCAAA,CAEA,sBACE,oCAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n\n  &--checkbox {\n    .color {\n      margin-left: 8px;\n    }\n\n    .title {\n      margin-left: 6px;\n    }\n  }\n}\n\n.color {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  margin: 0 2px;\n}\n\n.title {\n  color: var(--content-primary-primary);\n  max-width: 150px;\n  margin-left: 6px;\n}\n\n.count {\n  color: var(--content-neutral-primary);\n  margin-left: 8px;\n}\n\n.checkbox {}\n\n.dropdown {\n  height: auto;\n  min-height: 32px;\n}\n\n.highlighter {\n  background: var(--background-brand-subtle-primary);\n  color: var(--content-primary-tertiary);\n\n  &--label {\n    color: var(--content-primary-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "rX7d18TUOBy8UyxGW8wN",
	"wrap--checkbox": "GSsWtc37OrbSLZokCPAE",
	"color": "D1PC2ZPRVF5CRefp1XNE",
	"title": "fNhq3IFVqo4AGJO7NHUW",
	"count": "RZZ56jKz5JzsIHx1fPLr",
	"dropdown": "bYzzyJuFpahDz9ZUTktN",
	"highlighter": "hQVGFWyh5HTmi5vUnxAg",
	"highlighter--label": "Uh01RJl00EdBW493Sm3R"
};
export default ___CSS_LOADER_EXPORT___;
