import { makeAutoObservable, runInAction } from 'mobx'
import { OrganizationApi } from 'entities/Organization'
import { IResponseOrganization } from 'entities/Organization/api/types'

class MultiOrganizationStore {
  constructor() {
    makeAutoObservable(this)
  }

  loading = false
  multiOrganizationsMap: Map<number, IResponseOrganization> = new Map()

  fetchMultiOrganizations = async () => {
    try {
      this.loading = true
      const { data } = await OrganizationApi.getMultiOrganizationsList()

      runInAction(() => {
        this.setData(data.data)
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  get multiOrganizations() {
    return Array.from(this.multiOrganizationsMap.values())
  }

  setData = (data: IResponseOrganization[]) => {
    this.reset()
    this.setItems(data)
  }

  setItems = (items: IResponseOrganization[]) => {
    items.forEach((item) => this.setItem(item))
  }

  setItem = (item: IResponseOrganization) => {
    this.multiOrganizationsMap.set(item.id, item)
  }

  reset = () => {
    this.multiOrganizationsMap.clear()
  }
}

export const multiOrganizationStore = new MultiOrganizationStore()
