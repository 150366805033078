import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import { CallPopUp } from 'widgets/CallPopUp'
import CallPopUpLayoutLayout from 'app/layouts/callPopUpLayoutLayout'

const routersCallPopup = createRoutesFromElements(
  <Route element={<CallPopUpLayoutLayout />}>
    <Route path='/*' element={<CallPopUp />} />
  </Route>
)

export const appRouterCallPopup = createBrowserRouter(routersCallPopup)
