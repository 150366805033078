import { UIEvent, useEffect, useState, FC, Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { type IAvatarInfo, Scrollbar, SpinnerLoader, Typography } from 'shared/ui'
import { UserDropdownCard, usersStore } from 'entities/Users'
import { User } from 'entities/Users/model/User'
import { SearchDropdownAssigneeStore } from 'widgets/SearchDropdown'
import { MIN_COUNT_CHARTERS_FOR_SEARCH } from 'widgets/constants'
import styles from './styles.module.scss'

type IAssigneeDropdownContentProps = {
  store: SearchDropdownAssigneeStore
  onChange?: (item: User) => void
}

export const AssigneeDropdownContentItems: FC<IAssigneeDropdownContentProps> = observer(
  ({ store }) => {
    const { options, itemsListIds, search, loading, hasMore, loadMore, hasItems, init } = store
    const [selectedIds, setSelectedIds] = useState<number[]>([])

    const handleScroll = (e: UIEvent) => {
      const target = e.target as HTMLDivElement
      const scrollHeight = target.scrollHeight
      const scrollTop = target.scrollTop
      const offsetHeight = target.offsetHeight

      if (loading) return
      if (!hasMore) return
      if (scrollHeight <= scrollTop + offsetHeight + 100) {
        loadMore({ withoutSearch: search.length < MIN_COUNT_CHARTERS_FOR_SEARCH })
      }
    }

    const handleSelect = (item: User) => {
      store.selectTypeItem(item)
    }

    const isSelected = (id: number | undefined) => {
      if (id === undefined) return false

      return store.isSelected(id)
    }

    useEffect(() => {
      setSelectedIds(store.selectedItemsListIds)

      if (!loading) {
        init()
      }
    }, [])

    const unassignedItem = {
      id: 0,
      name: 'Unassigned',
      avatarInfo: {
        icon: 'userLine',
        color: 'white',
      } as IAvatarInfo,
    } as User

    const actualItemsListIds = selectedIds.includes(0) ? itemsListIds : [0, ...itemsListIds]

    const getUnassignedItem = () => {
      const isInList = !selectedIds.includes(0)

      if (isInList && search.length < MIN_COUNT_CHARTERS_FOR_SEARCH) return unassignedItem

      if (isInList && search && !unassignedItem.name.toLowerCase().includes(search.toLowerCase())) {
        return undefined
      }

      return unassignedItem
    }

    return (
      <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={290} onScroll={handleScroll}>
        <div className={styles.list}>
          {[...selectedIds, ...actualItemsListIds].map((id, index) => {
            const item =
              id === 0 ? getUnassignedItem() : (usersStore.getItem(id) as User | undefined)

            if (!item) return

            return (
              <Fragment key={item.id}>
                <UserDropdownCard
                  item={item}
                  InputCheckboxProps={
                    options?.selectedMode
                      ? {
                          checked: isSelected(id),
                          onChecked: () => handleSelect(item),
                        }
                      : undefined
                  }
                  onChange={handleSelect}
                />
                {index === selectedIds.length - 1 && <div className={styles.divider}></div>}
              </Fragment>
            )
          })}
          {!hasItems && !loading && (
            <div className={styles.epmtyList}>
              <Typography variant={'body-rg-regular'} ariaLabel={'NotFound'}>
                Not found
              </Typography>
            </div>
          )}
          {loading && (
            <div className={styles.spinner}>
              <SpinnerLoader />
            </div>
          )}
        </div>
      </Scrollbar>
    )
  }
)
