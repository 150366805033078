// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZqqEL9eLyqNxdTB4GPEQ{width:100%;height:100%;position:relative}.UbkT47iplH9Pwl90zPhL{border-radius:4px;padding:2px;border:1px solid var(--action-outlined-tertiary-border);background:var(--background-primary-inverted-primary)}.ZUQKREK1kUeIwu9OktoH{width:24px;height:24px;border-radius:6px;background:var(--background-neutral-default);display:flex;justify-content:center;align-items:center;color:var(--content-primary-primary);font-size:13px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/ContactsDuplicateTable/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CACA,WAAA,CACA,uDAAA,CACA,qDAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,iBAAA,CACA,4CAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,oCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".tableContainer {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n.icon {\n    border-radius: 4px;\n    padding: 2px;\n    border: 1px solid var(--action-outlined-tertiary-border);\n    background: var(--background-primary-inverted-primary);\n  }\n  \n.duplicatesCount {\n    width: 24px;\n    height: 24px;\n    border-radius: 6px;\n    background: var(--background-neutral-default);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "ZqqEL9eLyqNxdTB4GPEQ",
	"icon": "UbkT47iplH9Pwl90zPhL",
	"duplicatesCount": "ZUQKREK1kUeIwu9OktoH"
};
export default ___CSS_LOADER_EXPORT___;
