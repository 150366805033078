import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { InputCheckbox, Typography } from 'shared/ui'
import { PageDescription } from 'pages/settings/pages/compliance/ui/PageDescription/PageDescription'
import { NextButton } from 'pages/settings/pages/compliance/ui/NextButton/NextButton'
import { PeopleToContactStore } from 'pages/settings/pages/compliance/ui/CommonPeopleToContact/store/peopleToContactStore'
import { CommonErrorBanner } from 'pages/settings/pages/compliance/ui/CommonErrorBanner/CommonErrorBanner'
import { ContactForm } from './ContactForm'
import styles from './styles.module.scss'

export type ICommonPeopleToContactProps = {
  peopleToContactStore: PeopleToContactStore
  stepLoading: boolean
  errorMessage?: string | null
  nextStepClick: () => void
}

export const CommonPeopleToContact = observer(
  ({
    peopleToContactStore,
    stepLoading,
    errorMessage,
    nextStepClick,
  }: ICommonPeopleToContactProps) => {
    const {
      primaryContact,
      secondaryContact,
      jobPositionList,
      isSecondContact,
      contactsConfirmed,
      errorStore,
      isNextDisabled,
      toggleContactsConfirmed,
      setSecondContact,
    } = peopleToContactStore
    const { fieldsError, deleteErrorValue } = errorStore || {}

    return (
      <div className={styles.wrap}>
        <PageDescription>
          We will contact your authorized representative to verify your identities. Please ensure
          that they are contactable via email and phone.
        </PageDescription>

        <CommonErrorBanner errorMessage={errorMessage} />

        <Typography
          ariaLabel='AuthorizedUser_1_line'
          variant='body-lg-medium'
          marginBottom={24}
          marginTop={40}
          tag='div'
        >
          Authorized user #1
        </Typography>

        <ContactForm
          emailErrorMessage={
            (fieldsError && fieldsError['0.email']) || primaryContact.emailLocalError
          }
          phoneErrorMessage={fieldsError && fieldsError['0.phone_number']}
          isErrorBorderEmail={fieldsError ? Boolean(fieldsError['0.email']) : false}
          contact={primaryContact}
          jobPositionList={jobPositionList}
          similarityDomainsWarning={primaryContact.similarityDomainsWarning}
          setValueCallback={() => {
            deleteErrorValue?.('0.phone_number')
          }}
        />

        <InputCheckbox
          checked={isSecondContact}
          onChecked={() => setSecondContact(!isSecondContact)}
          color='var(--content-primary-tertiary)'
          label='I have a second authorized representative'
          variant='text-input-field-r'
          className={styles.secondContactCheckbox}
        />

        {isSecondContact && (
          <>
            <Typography
              ariaLabel='AuthorizedUser_2_line'
              variant='body-lg-medium'
              marginBottom={24}
              marginTop={40}
              tag='div'
            >
              Authorized user #2
            </Typography>

            <ContactForm
              isSecond
              isErrorBorderEmail={fieldsError ? Boolean(fieldsError['1.email']) : false}
              phoneErrorMessage={fieldsError && fieldsError['1.phone_number']}
              emailErrorMessage={
                (fieldsError && fieldsError['1.email']) || secondaryContact.emailLocalError
              }
              contact={secondaryContact}
              jobPositionList={jobPositionList}
              similarityDomainsWarning={secondaryContact.similarityDomainsWarning} // Chek
              setValueCallback={() => {
                deleteErrorValue?.('1.phone_number')
              }}
            />
          </>
        )}

        <InputCheckbox
          className={classNames(styles.confirmDesc, { [styles.confirmed]: isSecondContact })}
          checked={contactsConfirmed}
          onChecked={toggleContactsConfirmed}
          variant='text-input-field-r'
          color='var(--content-primary-tertiary)'
          label='I confirm that my nominated authorized representative agree to be contacted by Salesmsg'
        />

        <NextButton
          loading={stepLoading}
          disabled={isNextDisabled || stepLoading}
          text='Next'
          onClick={nextStepClick}
        />
      </div>
    )
  }
)
