import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { InfoImagePreview } from 'widgets/PresentationMode/ui/info/image/InfoImagePreview'
import { InfoVideoPreview } from 'widgets/PresentationMode/ui/info/video/InfoVideoPreview'
import { InfoAudioPreview } from 'widgets/PresentationMode/ui/info/audio/InfoAudioPreview'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import styles from './styles.module.scss'

export const InfoPreview = observer(() => {
  const { currentAttachment } = presentationStore
  const content = useMemo(() => {
    if (
      currentAttachment?.type === 'image' ||
      currentAttachment?.type === 'gif' ||
      currentAttachment?.type === 'previewGif'
    ) {
      return <InfoImagePreview />
    }
    if (currentAttachment?.type === 'video') {
      return <InfoVideoPreview />
    }
    if (currentAttachment?.type === 'audio') {
      return <InfoAudioPreview />
    }
    return <></>
  }, [currentAttachment])

  return <div className={styles.imageInfo}>{content}</div>
})
