import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar } from 'shared/ui'
import { IUser } from 'entities/Users'
import styles from './styles.module.scss'

type IConversationAssignItemProps = {
  item: IUser
  handleAddItem: (item: IUser) => void
}

export const ConversationAssignItem: FC<IConversationAssignItemProps> = observer(
  ({ item, handleAddItem }) => {
    return (
      <div onClick={() => handleAddItem(item)} key={item.id} className={styles.item}>
        <div className={styles.item__avatar}>
          <Avatar size={16} fontSize={10} info={item.avatarInfo} />
        </div>
        <div className={styles.item__name}>{item.name}</div>
      </div>
    )
  }
)
