// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IEOxFjjyACiKPkAuCUSy{max-width:432px;margin-top:-8px}.GHrSBxnJLXeOfec27fa5{display:flex;justify-content:space-between;margin-bottom:25px}.GHrSBxnJLXeOfec27fa5:last-of-type{margin-bottom:0}.o_Yknduird7B7o1lUi90{display:flex;align-items:center}.o_Yknduird7B7o1lUi90:nth-of-type(2){margin-right:6px}", "",{"version":3,"sources":["webpack://./src/widgets/CallModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,mCACE,eAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CAEA,qCACE,gBAAA","sourcesContent":[".wrap {\n  max-width: 432px;\n  margin-top: -8px;\n}\n\n.section {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 25px;\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n}\n\n.box {\n  display: flex;\n  align-items: center;\n\n  &:nth-of-type(2) {\n    margin-right: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "IEOxFjjyACiKPkAuCUSy",
	"section": "GHrSBxnJLXeOfec27fa5",
	"box": "o_Yknduird7B7o1lUi90"
};
export default ___CSS_LOADER_EXPORT___;
