export const isEmptyOrSpacesString = (str: string) => {
  if (typeof str === 'object') {
    return false
  }

  return str === null || str.match(/^ *$/) !== null
}

export const capitalizeWords = (str: string) => {
  return str
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(' ')
}
