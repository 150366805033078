import { makeObservable, observable } from 'mobx'
import { IInboxCombine } from 'entities/Inbox'

export class InboxTree {
  id: number | string
  parent: number | string
  droppable: boolean
  text: string
  data: IInboxCombine
  groupInbox?: IInboxCombine

  constructor(item: IInboxCombine, itemParent?: IInboxCombine, isCreate = false) {
    this.id = isCreate
      ? `${item.root_tree}_${item.id}`
      : itemParent
      ? `${item.root_tree}_${itemParent.id}_${item.id}`
      : item.idTree
    this.parent = itemParent ? `${item.root_tree}_${itemParent.id}` : item.root_tree
    this.droppable = item.isGroupInbox
    this.text = item.name
    this.data = item
    this.groupInbox = itemParent

    makeObservable(this, {
      id: observable,
      parent: observable,
      data: observable,
    })
  }
}
