// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CD1YjL7QrxwlEX8GaD7B{display:flex;align-items:center;justify-content:center;gap:8px}.CD1YjL7QrxwlEX8GaD7B .gETfdnl70GihtRJ262Rk{border-radius:4px;padding:2px;border:1px solid var(--action-outlined-tertiary-border);background:var(--background-primary-inverted-primary);width:max-content}.UzN34rHhFZeem319ejQg{width:100%}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsContainerHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,OAAA,CAEA,4CACE,iBAAA,CACA,WAAA,CACA,uDAAA,CACA,qDAAA,CACA,iBAAA,CAMJ,sBACE,UAAA","sourcesContent":[".customTitleWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n\n  .icon {\n    border-radius: 4px;\n    padding: 2px;\n    border: 1px solid var(--action-outlined-tertiary-border);\n    background: var(--background-primary-inverted-primary);\n    width: max-content;\n  }\n  .title {\n  }\n}\n\n.alert {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTitleWrapper": "CD1YjL7QrxwlEX8GaD7B",
	"icon": "gETfdnl70GihtRJ262Rk",
	"alert": "UzN34rHhFZeem319ejQg"
};
export default ___CSS_LOADER_EXPORT___;
