import { observer } from 'mobx-react-lite'
import { Alert, Typography } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { topicsItems, useNewCustomVoiceContext } from 'widgets/NewCustomVoiceModal'
import { RecordAudio } from 'widgets/RecordAudio'
import styles from './styles.module.scss'

export const NewCustomVoice = observer(() => {
  const { selectedTopic, onSelectTopic, topicText, recordAudioStore } = useNewCustomVoiceContext()

  const getContent = () => {
    if (recordAudioStore.recordStore.startLoading && recordAudioStore.recordStore.delayRecord) {
      return (
        <Typography
          variant={'heading-md'}
          ariaLabel={'RecordingStarting'}
          className={styles.infoText}
        >
          Recording starting in {recordAudioStore.recordStore.delayRecord}
        </Typography>
      )
    }
    if (recordAudioStore.recordStore.startRecord) {
      return (
        <Typography
          variant={'heading-md'}
          ariaLabel={'RecordingStarting'}
          className={styles.infoText}
        >
          Read the text below
        </Typography>
      )
    }
    return (
      <SelectField
        items={topicsItems}
        value={selectedTopic}
        setValue={onSelectTopic}
        labelText={'Choose your typical topic'}
        selectProps={{
          variant: 'stroke',
        }}
        dropdownProps={{
          withSearch: false,
        }}
      />
    )
  }
  return (
    <div className={styles.wrap}>
      <Alert
        item={{
          type: 'infoBlue',
          desc: 'Record an audio sample for training the AI voice',
        }}
        marginBottom={8}
      />
      <div className={styles.container}>{getContent()}</div>

      <div className={styles.wrapText}>
        <Typography
          ariaLabel={'topicText'}
          variant={'body-rg-regular'}
          tag={'div'}
          className={styles.text}
        >
          {topicText}
        </Typography>
      </div>
      <div className={styles.actions}>
        <RecordAudio
          store={recordAudioStore}
          cancelBtnType={'button'}
          cancelButtonProps={{ typeBtn: 'text' }}
        />
      </div>
    </div>
  )
})
