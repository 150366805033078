import React from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import { Icon, IIconsVector, NavLink, Typography } from 'shared/ui'
import styles from '../styles.module.scss'

export type IMenuItemNavProps = {
  type: 'nav'
  to: string
  label: JSX.Element | string
  icon: IIconsVector
  onClick?: () => void
  checkActive?: (pathname: string) => void
}

export const MenuItemNav = (item: IMenuItemNavProps) => {
  const { pathname } = useLocation()
  const { to, label, icon, checkActive } = item
  const handleActive = (isActive: boolean) => {
    if (checkActive) {
      return isActive && checkActive(pathname)
    }
    return isActive
  }
  return (
    <NavLink
      key={to}
      to={to}
      onClick={item.onClick}
      className={({ isActive }) =>
        classNames(styles.itemWrap, { [styles.active]: handleActive(isActive) }, styles.nav)
      }
    >
      <Icon icon={icon} fontSize={16} tertiary />
      {typeof label === 'string' ? (
        <Typography
          variant={'body-md-regular'}
          pointer
          className={classNames(styles.titleWrap)}
          ariaLabel={'label'}
        >
          {label}
        </Typography>
      ) : (
        label
      )}
    </NavLink>
  )
}
