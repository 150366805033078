import { FC, ReactNode } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { STRIPE_KEY } from 'shared/config'

const stripePromise = STRIPE_KEY && loadStripe(STRIPE_KEY)

export const StripeProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    {stripePromise ? (
      <Elements
        stripe={stripePromise}
        options={{
          locale: 'en',
          fonts: [
            {
              family: 'Inter',
              src: 'url(/assets/Inter-Regular.woff)',
              weight: '400',
              cssSrc: 'https://fonts.googleapis.com/css2?family=Inter&display=swap',
            },
          ],
        }}
      >
        {children}
      </Elements>
    ) : (
      children
    )}
  </>
)
