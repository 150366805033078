// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vENszfne0C5ybX6ReJuW{margin-right:auto;display:flex;align-items:center;padding:12px 24px 12px 12px}.vENszfne0C5ybX6ReJuW div.geThSTokzfLXhxDbd_9M{height:24px;font-size:12px;font-weight:500;border-radius:6px;background:var(--action-contained-secondary-normal);display:flex;justify-content:center;align-items:center}.vENszfne0C5ybX6ReJuW div.geThSTokzfLXhxDbd_9M:hover{background:var(--action-contained-secondary-hovered)}.vENszfne0C5ybX6ReJuW .ruayP4vZvEhPUqDy1sro{color:var(--content-primary-tertiary);font-size:12px;font-weight:500}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/Pagination/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,2BAAA,CAEA,+CACI,WAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,mDAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEJ,qDACI,oDAAA,CAEJ,4CACI,qCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".container {\n    margin-right: auto;\n    display: flex;\n    align-items: center;\n    padding: 12px 24px 12px 12px;\n\n    div.select {\n        height: 24px;\n        font-size: 12px;\n        font-weight: 500;\n        border-radius: 6px;\n        background: var(--action-contained-secondary-normal);\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n    div.select:hover {\n        background: var(--action-contained-secondary-hovered);\n    }\n    .allRowsCount {\n        color: var(--content-primary-tertiary);\n        font-size: 12px;\n        font-weight: 500;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vENszfne0C5ybX6ReJuW",
	"select": "geThSTokzfLXhxDbd_9M",
	"allRowsCount": "ruayP4vZvEhPUqDy1sro"
};
export default ___CSS_LOADER_EXPORT___;
