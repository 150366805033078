// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z2KzoSujZmOyT4QP4EbK{display:flex;flex-direction:column;flex:1 0 0;align-self:stretch;min-width:620px;padding:24px 80px 24px;min-height:100vh;overflow:auto}.eygA3ZDOJvSWD7abhJwt{padding-left:20px;display:flex;align-items:center;color:var(--content-primary-tertiary);gap:4px}.JoxRPDBH1pwBtZjb881h{height:20px;border-radius:4px;display:flex;gap:4px;padding:0 6px 0 4px;justify-content:center;align-items:center;color:var(--content-primary-tertiary);text-decoration:none}.JoxRPDBH1pwBtZjb881h span{line-height:15px}.JoxRPDBH1pwBtZjb881h:hover{background:var(--background-neutral-subtle)}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/customPrices/ui/CustomPriceLayout/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,gBAAA,CACA,aAAA,CAEF,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,qCAAA,CACA,OAAA,CAEF,sBACE,WAAA,CACA,iBAAA,CACA,YAAA,CACA,OAAA,CACA,mBAAA,CACA,sBAAA,CACA,kBAAA,CACA,qCAAA,CACA,oBAAA,CACA,2BACE,gBAAA,CAEF,4BACE,2CAAA","sourcesContent":[".rootLayout {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 0;\n  align-self: stretch;\n  min-width: 620px;\n  padding: 24px 80px 24px;\n  min-height: 100vh;\n  overflow: auto;\n}\n.wrapLink{\n  padding-left: 20px;\n  display: flex;\n  align-items: center;\n  color: var(--content-primary-tertiary);\n  gap: 4px;\n}\n.link{\n  height: 20px;\n  border-radius: 4px;\n  display: flex;\n  gap: 4px;\n  padding: 0 6px 0 4px;\n  justify-content: center;\n  align-items: center;\n  color: var(--content-primary-tertiary);\n  text-decoration: none;\n  span{\n    line-height: 15px;\n  }\n  &:hover{\n    background: var(--background-neutral-subtle);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootLayout": "Z2KzoSujZmOyT4QP4EbK",
	"wrapLink": "eygA3ZDOJvSWD7abhJwt",
	"link": "JoxRPDBH1pwBtZjb881h"
};
export default ___CSS_LOADER_EXPORT___;
