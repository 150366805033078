import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IBaseWorkflowData,
  INewWorkflowData,
  IParamsEnrollCheck,
  IParamsWorkflowList,
  IResponseEnrollCheck,
  IResponseWorkflowCreate,
  IResponseWorkflowDelete,
  IResponseWorkflowDisable,
  IResponseWorkflowEnable,
  IResponseWorkflowList,
  IResponseWorkflowUpdate,
  IWorkflowExecutionRequest,
  IWorkflowExecutionResponse,
  IWorkflowTerminateExecutionResponse,
} from './types'

class Api {
  getWorkflows(
    params?: IParamsWorkflowList,
    options?: Omit<AxiosOptions, 'params'>
  ): Promise<AxiosResponse<IResponseWorkflowList>> {
    return baseApi.get('workflows/workflows', { ...options, params })
  }

  createWorkflow(
    payload: INewWorkflowData | IBaseWorkflowData
  ): Promise<AxiosResponse<IResponseWorkflowCreate>> {
    return baseApi.post('workflows/workflows', payload)
  }

  updateWorkflow(
    id: string,
    payload: IBaseWorkflowData
  ): Promise<AxiosResponse<IResponseWorkflowUpdate>> {
    return baseApi.post(`workflows/workflows/${id}`, payload)
  }

  enableWorkflow(id: string): Promise<AxiosResponse<IResponseWorkflowEnable>> {
    return baseApi.post(`workflows/workflows/${id}/enable`)
  }

  disableWorkflow(id: string): Promise<AxiosResponse<IResponseWorkflowDisable>> {
    return baseApi.post(`workflows/workflows/${id}/disable`)
  }

  deleteWorkflow(id: string): Promise<AxiosResponse<IResponseWorkflowDelete>> {
    return baseApi.delete(`workflows/workflows/${id}`)
  }

  enrollCheck(
    params: IParamsEnrollCheck,
    id: string
  ): Promise<AxiosResponse<IResponseEnrollCheck>> {
    return baseApi.post(`workflows/workflows/${id}/enroll`, params)
  }

  getConversationExecutions(
    id: number,
    params: IWorkflowExecutionRequest,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IWorkflowExecutionResponse>> {
    return baseApi.get(`workflows/executions/conversation/${id}`, { ...options, params })
  }

  terminateExecution(
    executionId: string
  ): Promise<AxiosResponse<IWorkflowTerminateExecutionResponse>> {
    return baseApi.post(`workflows/executions/${executionId}/terminate`)
  }
}

export const WorkflowsApi = new Api()
