import { makeAutoObservable } from 'mobx'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { VideoControllerStore } from 'widgets/PresentationMode/store/VideoControllerStore'
import { ImageControllerStore } from 'widgets/PresentationMode/store/ImageControllerStore'
import { AudioControllerStore } from 'widgets/PresentationMode/store/AudioControllerStore'

class PresentationStore {
  imageControllerStore: ImageControllerStore = new ImageControllerStore()
  videoControllerStore: VideoControllerStore = new VideoControllerStore()
  audioControllerStore: AudioControllerStore = new AudioControllerStore()

  constructor() {
    makeAutoObservable(this)
  }

  attachments: Attachment[] = []
  currentIndex = 0

  disabledOverlayClick = false

  get isPrev() {
    return this.currentIndex > 0
  }

  get isNext() {
    return this.currentIndex < this.attachments.length - 1
  }

  get openPresentationMode() {
    return !!this.attachments.length
  }
  get currentAttachment(): Attachment | undefined {
    return this.attachments[this.currentIndex]
  }
  initControllers = () => {
    this.imageControllerStore = new ImageControllerStore()
    this.videoControllerStore = new VideoControllerStore()
    this.audioControllerStore = new AudioControllerStore()
  }
  setAttachments = (attachments: Attachment[], index?: number) => {
    this.currentIndex = index || 0
    this.attachments = attachments
  }
  onPrev = () => {
    this.initControllers()
    this.currentIndex -= 1
  }
  onNext = () => {
    this.initControllers()
    this.currentIndex += 1
  }

  setDisabledOverlayClick = (value: boolean) => {
    this.disabledOverlayClick = value
  }

  onClose = () => {
    this.initControllers()
    this.attachments = []
    this.currentIndex = 1
    this.disabledOverlayClick = false
  }
}

export default new PresentationStore()
