import { useEffect } from 'react'
import { websocket, WebsocketEvent } from 'entities/WebSocket'
import { IResponseMedia } from 'entities/Attachment/api/types'

type IHandlerEvent = (data: { attachment: IResponseMedia; space_used: number }) => void

export const EventAttachmentUploadSuccessful = {
  subscribe: (handler: IHandlerEvent) =>
    websocket.on(WebsocketEvent.AttachmentUploadSuccessful, handler),
  unsubscribe: (handler: IHandlerEvent) =>
    websocket.off(WebsocketEvent.AttachmentUploadSuccessful, handler),
}

export const useEventAttachmentUploadSuccessful = (handler: IHandlerEvent) =>
  useEffect(() => EventAttachmentUploadSuccessful.subscribe(handler), [])
