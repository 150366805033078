import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Dropdown, Icon } from 'shared/ui'
import { type IDeviceSettingsContentProps } from 'entities/DeviceSettings/ui/DeviceSettingsContent/DeviceSettingsContent'
import styles from './styles.module.scss'

export const VideoInputContent: FC<IDeviceSettingsContentProps> = observer(
  ({
    deviceSettingsStore,
    parentsDropdownId,
    dropdownProps = { ariaLabel: 'VideoInputContent' },
  }) => {
    const { device, videoInputsItems, setSelectedVideoInput } = deviceSettingsStore
    return (
      <div className={styles.section}>
        <div className={styles.box}>
          <div className={styles.title}>
            <div className={styles.icon}>
              <Icon icon={'video2'} fontSize={18} />
            </div>
            <div className={styles.label}>Camera</div>
          </div>
        </div>

        <div className={styles.dropdownWrap}>
          <div className={styles.dropdownBox}>
            <Dropdown
              zIndex={5001}
              placement={'bottom-start'}
              items={videoInputsItems}
              triggerComponent={() => (
                <div className={styles.dropdownBtn}>{device.videoInput?.label}</div>
              )}
              width={232}
              margin={-32}
              onChange={(item) => {
                setSelectedVideoInput(String(item.id))
              }}
              parentsDropdownId={parentsDropdownId}
              {...dropdownProps}
            />
          </div>
        </div>
      </div>
    )
  }
)
