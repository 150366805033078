import React from 'react'
import classnames from 'classnames'
import type { Property } from 'csstype'
import { Icon, IIconsVector } from 'shared/ui/Icon'
import { Typography } from 'shared/ui/Typography'

import styles from './styles.module.scss'

export enum EnumHelperText {
  Default = 'default',
  Alert = 'alert',
  Success = 'success',
  Error = 'error',
}

export type IHelperTextProps = {
  variant?: EnumHelperText
  icon?: IIconsVector
  text: string
  paddingBlock?: Property.PaddingBlock
}

export const HelperText: React.FC<IHelperTextProps> = ({ text, icon, paddingBlock, ...props }) => {
  const variant = props.variant ? props.variant : EnumHelperText.Default

  return (
    <div className={classnames(styles.wrap, styles[variant])} style={{ paddingBlock }}>
      {icon && (
        <div className={styles.icon}>
          <Icon icon={icon} />
        </div>
      )}
      <Typography variant={'text-input-helper'} className={styles.text} ariaLabel={'text'}>
        {text}
      </Typography>
    </div>
  )
}
