// Please, also update value in src\app\styles\zIndex.scss
// in case updating this file

export enum ZIndex {
  // Base levels
  BASE_MINUS_1 = -1,
  BASE_MINUS_100 = -100,
  BASE = 0,
  BASE_1 = 1,
  BASE_2 = 2,
  BASE_3 = 3,
  BASE_4 = 4,
  BASE_5 = 5,
  BASE_6 = 6,
  BASE_7 = 7,
  BASE_8 = 8,
  BASE_9 = 9,
  BASE_10 = 10,
  BASE_11 = 11,
  BASE_12 = 12,
  BASE_15 = 15,
  BASE_20 = 20,
  BASE_30 = 30,
  BASE_50 = 50,
  BASE_99 = 99,

  // UI level
  UI_100 = 100,
  UI_200 = 200,
  UI_300 = 300,

  // Tooltip, Dropdown, Modal level
  OVERLAY_BASE = 1000,
  OVERLAY_BASE_1 = 1001,

  // Tooltip, Dropdown default
  OVERLAY_BASE_500 = 1500,

  OVERLAY_MIDDLE = 2000,
  OVERLAY_MIDDLE_1 = 2001,
  OVERLAY_MIDDLE_100 = 2100,

  // Toast, PresentationMode level
  OVERLAY_MAIN = 3000,
  OVERLAY_MAIN_1 = 3001,
  OVERLAY_MAIN_2 = 3002,

  // Calling level
  OVERLAY_TOP = 5000,
  OVERLAY_TOP_1 = 5001,
  OVERLAY_TOP_100 = 5100,
  OVERLAY_TOP_110 = 5110,
  OVERLAY_TOP_120 = 5120,
}
