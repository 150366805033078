import { type IIconsVector } from 'shared/ui'
import { type IWorkflowTemplate } from 'widgets/CreateWorkflowModal/model'

export const TemplateName = {
  FromScratch: 'Start from Scratch',
  AutoResponder: 'Autoresponder',
  NurtureCampaign: 'Nurture Campaign',
  Reviews: 'Reviews',
  SendMessage: 'Send a Message',
  AppointmentReminder: 'Appointment Reminder',
  NPS: 'NPS',
  Integrations: 'Integrations',
  SmsReminder: 'SMS Reminder',
} as const

export type IWorkflowTemplateName = (typeof TemplateName)[keyof typeof TemplateName]

export type IWorkflowTemplateView = {
  icon: IIconsVector
  name: IWorkflowTemplateName
  description: string
  disabled?: true
}

export interface ICreateWorkflowProps {
  workflow: IWorkflowTemplate
  onCancel: () => void
  onNext: () => void
}
