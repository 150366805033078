// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JWKBMuHvq9f8MME_sqpr{display:flex;justify-content:center;align-items:center;width:100%;height:100%;gap:16px;flex-direction:column}.kFujX2o6YJOXInPmFDI7{display:flex;align-items:center;height:32px;padding-left:8px}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/SavedRepliesContent/LeftContent/SavedRepliesList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,qBAAA,CAIF,sBACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":[".empty{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  gap: 16px;\n  flex-direction: column;\n}\n\n\n.wrapLabel{\n  display: flex;\n  align-items: center;\n  height: 32px;\n  padding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "JWKBMuHvq9f8MME_sqpr",
	"wrapLabel": "kFujX2o6YJOXInPmFDI7"
};
export default ___CSS_LOADER_EXPORT___;
