import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IResponseContact } from 'entities/Contacts/api/types'
import { CompareVCardsContent } from 'entities/VCard/ui/CompareVCardsContent/CompareVCardsContent'
import { EditVCardContent } from 'entities/VCard/ui/EditVCardContent/EditVCardContent'
import { Contact } from 'entities/Contacts/model/Contact'
import { VCard } from 'entities/VCard/model/VCard'

class VCardStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''
  modalVCard: VCard | null = null
  currentVCard: VCard | null = null
  newVCard: VCard | null = null
  duplicateContacts: Contact[] = []
  isLoading = false

  isCurrentSelected = true

  openVCard = (vCard: VCard) => {
    this.modalId = nanoid()
    this.duplicateContacts = []
    this.modalVCard = vCard
    modalStore.addModal({
      id: this.modalId,
      pureContent: true,
      ModalContent: EditVCardContent,
      title: 'vCard',
      width: 400,
    })
  }

  openVCardContent = (vCard: VCard, duplicateContacts: IResponseContact[] = []) => {
    this.duplicateContacts = duplicateContacts.length
      ? duplicateContacts.map((contact) => new Contact(contact))
      : []
    this.modalVCard = duplicateContacts.length
      ? new VCard({
          phone: this.duplicateContacts[0].formatted_number,
          first_name: this.duplicateContacts[0].first_name,
          email: this.duplicateContacts[0].email,
          last_name: this.duplicateContacts[0].last_name,
          color: this.duplicateContacts[0].color,
        })
      : vCard
  }

  onCloseVCardContent = () => {
    this.duplicateContacts = []
    this.isCurrentSelected = true
  }

  openCompareContent = (newVCard: VCard, duplicateContacts: IResponseContact[]) => {
    this.onCloseModal()
    this.modalId = nanoid()
    this.isCurrentSelected = true
    this.newVCard = newVCard
    this.duplicateContacts = duplicateContacts.map((contact) => new Contact(contact))
    this.currentVCard =
      this.duplicateContacts[0] &&
      new VCard({
        phone: this.duplicateContacts[0].formatted_number,
        first_name: this.duplicateContacts[0].first_name,
        email: this.duplicateContacts[0].email,
        last_name: this.duplicateContacts[0].last_name,
        color: this.duplicateContacts[0].color,
      })
    modalStore.addModal({
      id: this.modalId,
      pureContent: true,
      ModalContent: CompareVCardsContent,
      title: 'vCard',
      width: 600,
    })
  }

  onCloseModal = () => {
    this.duplicateContacts = []
    this.isCurrentSelected = true
    modalStore.closeModal(this.modalId)
  }

  setCurrentSelected = (value: boolean) => {
    this.isCurrentSelected = value
  }
}

export default new VCardStore()
