import parse from 'html-react-parser'
import { IColumn, IconButton, IRow, Tooltip } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { getCellForNameDefault } from 'shared/helpers/getCellForNameDefault'
import { EmptyCell } from 'shared/ui/Table'
import { ContactStatistic } from 'entities/ContactStatistic'
import { IParamsGetBroadcastContacts } from 'entities/Broadcast/api/types'
import { filtersStore } from 'features/DropdownFilter'
import { type IOpenContactsDetails } from 'widgets/ContactsDetails'
import styles from './styles.module.scss'

const FailedReasonCell = ({ row }: { row: IRow<ContactStatistic> }) => {
  if (row.message_failed_reason) {
    return <div className={styles.reason}>{parse(row.message_failed_reason)}</div>
  }
  return <EmptyCell />
}

const getColumnsConfig = (onOpenContactsDetails: IOpenContactsDetails) => ({
  full_name: {
    field: 'full_name',
    name: 'Name',
    isSortable: true,
    renderRowCell: (row, _, config) => (
      <div className={styles.contactColumn}>
        <div className={styles.contactColumnName}>
          {getCellForNameDefault({
            fullName: row.full_name || '',
            avatarInfo: row.avatarInfo,
          })}
        </div>
        {row.conversation_id != null && (
          <Tooltip label='Conversation' placement='top'>
            <IconButton
              icon='chatRead'
              variant='icon'
              color='tertiary'
              transparentInactive
              className={config?.hidden}
              disabled={onOpenContactsDetails.disabled}
              onClick={(event) => {
                event.stopPropagation()
                filtersStore.setFilter('open', true)
                onOpenContactsDetails.open({
                  contactId: row.id,
                })
              }}
              ariaLabel='ContactRow_sendMessage'
            />
          </Tooltip>
        )}
      </div>
    ),
  } as IColumn<ContactStatistic>,
  formatted_number: {
    field: 'formatted_number',
    name: 'Number',
    isSortable: true,
  } as IColumn<ContactStatistic>,
  email: {
    field: 'email',
    name: 'Email',
    isSortable: true,
  } as IColumn<ContactStatistic>,
  message_sent_at: {
    field: 'message_sent_at',
    isSortable: true,
    name: 'Send time',
    renderRowCell: (row) =>
      row.message_sent_at &&
      uiStore.dayjs(row.message_sent_at).format(DayjsFormats.fullWithAtDash2),
  } as IColumn<ContactStatistic>,
  message_failed_reason: {
    field: 'message_failed_reason',
    name: 'Failed reason',
    renderRowCell: (row) => <FailedReasonCell row={row} />,
  } as IColumn<ContactStatistic>,
  message_replied_at: {
    field: 'message_replied_at',
    name: 'Replied at',
    renderRowCell: (row) =>
      row.message_replied_at &&
      uiStore.dayjs(row.message_replied_at).format(DayjsFormats.fullWithAtDash2),
  } as IColumn<ContactStatistic>,
  message_opted_out_at: {
    field: 'message_opted_out_at',
    name: 'Opt-out at',
    renderRowCell: (row) =>
      row.message_opted_out_at &&
      uiStore.dayjs(row.message_opted_out_at).format(DayjsFormats.fullWithAtDash2),
  } as IColumn<ContactStatistic>,
  short_url_last_clicked_at: {
    field: 'short_url_last_clicked_at',
    name: 'Clicked at',
    renderRowCell: (row) =>
      row.short_url_last_clicked_at &&
      uiStore.dayjs(row.short_url_last_clicked_at).format(DayjsFormats.fullWithAtDash2),
  } as IColumn<ContactStatistic>,
  last_converted_at: {
    field: 'last_converted_at',
    name: 'Converted at',
    renderRowCell: (row) =>
      row.last_converted_at &&
      uiStore.dayjs(row.last_converted_at).format(DayjsFormats.fullWithAtDash2),
  } as IColumn<ContactStatistic>,
})

export const useContactStatisticColumns = (
  filter: IParamsGetBroadcastContacts['filter'],
  onOpenContactsDetails: IOpenContactsDetails
): IColumn<ContactStatistic>[] => {
  const columnsMap = getColumnsConfig(onOpenContactsDetails)

  if (filter === 'failed') {
    return [
      columnsMap.full_name,
      columnsMap.formatted_number,
      columnsMap.email,
      columnsMap.message_sent_at,
      columnsMap.message_failed_reason,
    ]
  }
  if (filter === 'replied') {
    return [
      columnsMap.full_name,
      columnsMap.formatted_number,
      columnsMap.email,
      columnsMap.message_sent_at,
      // columnsMap.message_replied_at,
    ]
  }
  if (filter === 'opted_out') {
    return [
      columnsMap.full_name,
      columnsMap.formatted_number,
      columnsMap.email,
      columnsMap.message_sent_at,
      columnsMap.message_opted_out_at,
    ]
  }
  if (filter === 'clicked') {
    return [
      columnsMap.full_name,
      columnsMap.formatted_number,
      columnsMap.email,
      columnsMap.message_sent_at,
      columnsMap.short_url_last_clicked_at,
    ]
  }
  if (filter === 'conversions') {
    return [
      columnsMap.full_name,
      columnsMap.formatted_number,
      columnsMap.email,
      columnsMap.message_sent_at,
      columnsMap.last_converted_at,
    ]
  }
  return [
    columnsMap.full_name,
    columnsMap.formatted_number,
    columnsMap.email,
    columnsMap.message_sent_at,
  ]
}
