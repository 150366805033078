import React from 'react'
import { observer } from 'mobx-react-lite'
import { nanoid } from 'nanoid'
import { Button } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import {
  IResponseContactUnlinkedSource,
  IResponseContactUnlinkedConflictItem,
} from 'entities/Contacts/api/types'
import { IntegrationKey } from 'entities/Integrations'
import { LinkContactModalContent } from './LinkContactModalContent/LinkContactModalContent'
import { LinkContactModalActions } from './LinkContactModalActions/LinkContactModalActions'
import { linkContactStore } from '../store/linkContactStore'

type ILinkContactProps = {
  contact: IResponseContactUnlinkedSource
  matchedContacts: IResponseContactUnlinkedConflictItem[]
  integration: IntegrationKey | null
}

const LinkContact = observer(({ contact, matchedContacts, integration }: ILinkContactProps) => {
  const { reset, init } = linkContactStore

  const modalId = nanoid()

  const handleClose = () => {
    modalStore.removeModal(modalId)
    reset()
  }

  const handleLink = () => {
    init(contact, matchedContacts, integration, modalId)

    modalStore.addModal({
      id: modalId,
      showCloseButton: false,
      showCloseIcon: true,
      showHeader: true,
      title: 'Link contact',
      paddingTopModal: 60,
      paddingContent: '0px 5px 16px 24px',
      width: 600,
      onClose: handleClose,
      ModalContent: () => <LinkContactModalContent />,
      ModalActions: () => <LinkContactModalActions />,
    })
  }

  return <Button size={'medium'} contained={'tertiary'} text={'Link'} onClick={handleLink} />
})

export { LinkContact }
