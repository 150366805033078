// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Lgyu5APpzkKPic0wmQ73{display:flex;flex-direction:column}.Lgyu5APpzkKPic0wmQ73 .Y98q3Ww6ThPUCKpc8GNU{padding:16px;border-radius:8px;cursor:pointer;display:flex;flex-direction:row;align-items:center;justify-content:space-between;margin-bottom:12px;background:var(--background-neutral-default)}.Lgyu5APpzkKPic0wmQ73 .Y98q3Ww6ThPUCKpc8GNU .BNinePRuN2TDzLQQT9ew{display:flex;gap:12px;flex-direction:row;align-items:center}.Lgyu5APpzkKPic0wmQ73 .Y98q3Ww6ThPUCKpc8GNU *{user-select:none}.Lgyu5APpzkKPic0wmQ73 .OY2BFqwDuq8PUZ6YsPgy{display:flex;flex-direction:column;margin-bottom:16px}", "",{"version":3,"sources":["webpack://./src/pages/admin/ui/AdminManagePaymentPlan/PaymentPlanCollapse/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEA,4CACE,YAAA,CACA,iBAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CACA,4CAAA,CAEA,kEACE,YAAA,CACA,QAAA,CACA,kBAAA,CACA,kBAAA,CAGF,8CACE,gBAAA,CAIJ,4CACE,YAAA,CACA,qBAAA,CACA,kBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n\n  .header {\n    padding: 16px;\n    border-radius: 8px;\n    cursor: pointer;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 12px;\n    background: var(--background-neutral-default);\n\n    .title {\n      display: flex;\n      gap: 12px;\n      flex-direction: row;\n      align-items: center;\n    }\n\n    & * {\n      user-select: none;\n    }\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Lgyu5APpzkKPic0wmQ73",
	"header": "Y98q3Ww6ThPUCKpc8GNU",
	"title": "BNinePRuN2TDzLQQT9ew",
	"content": "OY2BFqwDuq8PUZ6YsPgy"
};
export default ___CSS_LOADER_EXPORT___;
