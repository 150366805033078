// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".abgz6TEes80kyUHRZ6MW{display:flex;flex-direction:column;border-radius:8px;background:var(--background-primary-inverted-primary);box-shadow:var(--menu-shadow)}.zjbM63gNfxbuNt7_xtql{display:grid;padding:4px;width:fit-content}.w9ciQ4m9lObjl_nuZIwY{display:flex}", "",{"version":3,"sources":["webpack://./src/shared/ui/Actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,qDAAA,CACA,6BAAA,CAEF,sBACE,YAAA,CACA,WAAA,CACA,iBAAA,CAEF,sBACE,YAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  border-radius: 8px;\n  background: var(--background-primary-inverted-primary);\n  box-shadow: var(--menu-shadow);\n}\n.content{\n  display: grid;\n  padding: 4px;\n  width: fit-content;\n}\n.wrapActions{\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "abgz6TEes80kyUHRZ6MW",
	"content": "zjbM63gNfxbuNt7_xtql",
	"wrapActions": "w9ciQ4m9lObjl_nuZIwY"
};
export default ___CSS_LOADER_EXPORT___;
