// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dcr8NzS27UFfyonjZyZu{padding:16px 24px 24px 24px;display:flex;flex-direction:column;flex-grow:1;gap:32px}.yhuBTNxNwhMlKdwDEF8m{display:flex;gap:8px;margin-top:12px;margin-bottom:24px}.M332E3n0HPc1qiFjYuUF{margin-top:-8px;margin-bottom:16px}.xPbh0SCesr8SSsRG3Ypn{flex-grow:1;align-content:flex-end;padding-right:24px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastActions/ui/BroadcastDetailModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CACA,QAAA,CAEF,sBACE,YAAA,CACA,OAAA,CACA,eAAA,CACA,kBAAA,CAEF,sBACE,eAAA,CACA,kBAAA,CAEF,sBACE,WAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  padding: 16px 24px 24px 24px;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  gap: 32px;\n}\n.info{\n  display: flex;\n  gap: 8px;\n  margin-top: 12px;\n  margin-bottom: 24px;\n}\n.alert{\n  margin-top: -8px;\n  margin-bottom: 16px;\n}\n.footer{\n  flex-grow: 1;\n  align-content: flex-end;\n  padding-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "dcr8NzS27UFfyonjZyZu",
	"info": "yhuBTNxNwhMlKdwDEF8m",
	"alert": "M332E3n0HPc1qiFjYuUF",
	"footer": "xPbh0SCesr8SSsRG3Ypn"
};
export default ___CSS_LOADER_EXPORT___;
