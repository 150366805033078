export interface unicodeInterface {
  [key: string]: string
}

const unicode: unicodeInterface = {
  '\u00A0': "'",
  '\u00AB': '"',
  '\u00B4': "'",
  '\u00BB': '"',
  '\u00BC': '1/4',
  '\u00BD': '1/2',
  '\u00BE': '3/4',
  '\u00F7': '/',
  '\u0000': '',
  '\u01C3': '!',
  '\u1D0A': 'J',
  '\u1D0B': 'K',
  '\u1D0D': 'M',
  '\u1D0F': 'O',
  '\u1D00': 'A',
  '\u1D1B': 'T',
  '\u1D1C': 'U',
  '\u1D04': 'C',
  '\u1D05': 'D',
  '\u1D07': 'E',
  '\u1D18': 'P',
  '\u1D20': 'V',
  '\u1D21': 'W',
  '\u1D22': 'Z',
  '\u1DCD': '^',
  '\u02B9': "'",
  '\u02BA': '"',
  '\u02BB': "'",
  '\u02BC': "'",
  '\u02BD': "'",
  '\u02C6': '^',
  '\u02C8': "'",
  '\u02CA': "'",
  '\u02CB': "'",
  '\u02D0': ':',
  '\u02D6': '+',
  '\u02DC': '~',
  '\u02EE': '"',
  '\u02F7': '~',
  '\u02F8': ':',
  '\u0003': '',
  '\u0004': '',
  '\u008D': '',
  '\u0009': '',
  '\u009B': '',
  '\u009F': '',
  '\u0010': '',
  '\u0011': '',
  '\u0012': '',
  '\u0013': '',
  '\u0014': '',
  '\u0017': '',
  '\u0019': '',
  '\u20D2': '|',
  '\u20D3': '|',
  '\u20E5': '\\',
  '\u23B8': '|',
  '\u23B9': '|',
  '\u23BC': '-',
  '\u23BD': '-',
  '\u23D0': '|',
  '\u026A': 'I',
  '\u27EE': '(',
  '\u27EF': ')',
  '\u028F': 'Y',
  '\u029C': 'H',
  '\u029F': 'L',
  '\u29C6': '*',
  '\u29F5': '\\',
  '\u29F8': '/',
  '\u29F9': '\\',
  '\u0060': "'",
  '\u0080': '',
  '\u0090': '',
  '\u200A': '',
  '\u200B': '',
  '\u201A': "'",
  '\u201B': "'",
  '\u201C': '"',
  '\u201D': '"',
  '\u201E': '"',
  '\u201F': '"',
  '\u202F': '',
  '\u203A': '<',
  '\u203C': '!!',
  '\u204E': '*',
  '\u204F': ';',
  '\u205F': '',
  '\u223C': '~',
  '\u229B': '*',
  '\u239C': '|',
  '\u239F': '|',
  '\u0262': 'G',
  '\u273A': '*',
  '\u273B': '*',
  '\u273C': '*',
  '\u273D': '*',
  '\u0274': 'N',
  '\u274A': '*',
  '\u274B': '*',
  '\u275B': "'",
  '\u275C': "'",
  '\u275D': '"',
  '\u275E': '"',
  '\u276A': '(',
  '\u276B': ')',
  '\u0280': 'R',
  '\u0299': 'B',
  '\u301D': '"',
  '\u301E': '"',
  '\u0302': '^',
  '\u0303': '~',
  '\u0313': "'",
  '\u0314': "'",
  '\u0326': ',',
  '\u0330': '~',
  '\u0332': '_',
  '\u0334': '~',
  '\u0337': '/',
  '\u0338': '/',
  '\u0347': '=',
  '\u2000': "'",
  '\u2001': '',
  '\u2002': '',
  '\u2003': '',
  '\u2004': '',
  '\u2005': '',
  '\u2006': '',
  '\u2007': '',
  '\u2008': '',
  '\u2009': '',
  '\u2010': '-',
  '\u2013': '-',
  '\u2014': '-',
  '\u2015': '-',
  '\u2017': '_',
  '\u2018': "'",
  '\u2019': "'",
  '\u2022': '-',
  '\u2026': '...',
  '\u2028': '',
  '\u2029': '',
  '\u2039': '>',
  '\u2043': '-',
  '\u2044': '/',
  '\u2060': '',
  '\u2215': '/',
  '\u2217': '*',
  '\u2223': '|',
  '\u2722': '*',
  '\u2723': '*',
  '\u2724': '*',
  '\u2725': '*',
  '\u2731': '*',
  '\u2732': '*',
  '\u2733': '*',
  '\u2743': '*',
  '\u2749': '*',
  '\u2768': '(',
  '\u2769': ')',
  '\u2774': '{',
  '\u2775': '}',
  '\u2982': ':',
  '\u2985': '(',
  '\u2986': ')',
  '\u3000': '',
  '\u3001': ',',
  '\u3002': '.',
  '\uA78A': '=',
  '\uA730': 'F',
  '\uA731': 'S',
  '\uA789': ':',
  '\uFE5A': ')',
  '\uFE5B': '{',
  '\uFE5C': '}',
  '\uFE5F': '#',
  '\uFE6A': '%',
  '\uFE6B': '@',
  '\uFE10': "'",
  '\uFE11': "'",
  '\uFE13': ':',
  '\uFE14': ';',
  '\uFE15': '!',
  '\uFE16': '?',
  '\uFE50': ',',
  '\uFE51': ',',
  '\uFE52': '.',
  '\uFE54': ';',
  '\uFE56': '?',
  '\uFE57': '!',
  '\uFE59': '(',
  '\uFE60': '&',
  '\uFE61': '*',
  '\uFE62': '+',
  '\uFE63': '-',
  '\uFE64': '<',
  '\uFE65': '>',
  '\uFE66': '=',
  '\uFE68': '\\',
  '\uFE69': '$',
  '\uFEFF': '',
  '\uFF0A': '*',
  '\uFF0B': '+',
  '\uFF0C': ',',
  '\uFF0D': '-',
  '\uFF0E': '.',
  '\uFF0F': '/',
  '\uFF01': '!',
  '\uFF1A': ':',
  '\uFF1B': ';',
  '\uFF1C': '<',
  '\uFF1D': '=',
  '\uFF1E': '>',
  '\uFF1F': '?',
  '\uFF02': '"',
  '\uFF2A': 'J',
  '\uFF2B': 'K',
  '\uFF2C': 'L',
  '\uFF2D': 'M',
  '\uFF2E': 'N',
  '\uFF2F': 'O',
  '\uFF03': '#',
  '\uFF3A': 'Z',
  '\uFF3B': '[',
  '\uFF3C': '\\',
  '\uFF3D': ']',
  '\uFF3E': '^',
  '\uFF3F': '_',
  '\uFF04': '$',
  '\uFF05': '%',
  '\uFF5B': '{',
  '\uFF5C': '|',
  '\uFF5D': '}',
  '\uFF5E': '~',
  '\uFF06': '&',
  '\uFF07': "'",
  '\uFF08': '(',
  '\uFF09': ')',
  '\uFF10': '0',
  '\uFF11': '1',
  '\uFF12': '2',
  '\uFF13': '3',
  '\uFF14': '4',
  '\uFF15': '5',
  '\uFF16': '6',
  '\uFF17': '7',
  '\uFF18': '8',
  '\uFF19': '9',
  '\uFF20': '@',
  '\uFF21': 'A',
  '\uFF22': 'B',
  '\uFF23': 'C',
  '\uFF24': 'D',
  '\uFF25': 'E',
  '\uFF26': 'F',
  '\uFF27': 'G',
  '\uFF28': 'H',
  '\uFF29': 'I',
  '\uFF30': 'P',
  '\uFF31': 'Q',
  '\uFF32': 'R',
  '\uFF33': 'S',
  '\uFF34': 'T',
  '\uFF35': 'U',
  '\uFF36': 'V',
  '\uFF37': 'W',
  '\uFF38': 'X',
  '\uFF39': 'Y',
  '\uFF61': '.',
  '\uFF64': ',',
}

export const unicodeToGSM = (string: string): string => {
  const stringArray = string.split('')

  for (const key in unicode) {
    const origin = unicode[key]
    const exist = stringArray.find((i) => i === key)

    if (exist) {
      string = string.replaceAll(key, origin)
    }
  }

  return string
}
