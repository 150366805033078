import { observer } from 'mobx-react-lite'
import { EmptyCell, IColumn, NameValueRecord, Typography } from 'shared/ui'

import { NumberCell } from 'shared/ui/Table/NumberCell/NumberCell'
import { PowerDialer } from 'entities/PowerDialer/model/PowerDialer'
import { PowerDialerStatusBadge } from 'widgets/PowerDialer'

export const columns: IColumn<PowerDialer>[] = [
  {
    field: 'name',
    name: 'Name',
    minWidth: '240px',
    width: '100%',
    isDisabledChangeVisible: true,
    renderRowCell: ({ name }) => <NameCell name={name} />,
  },
  {
    field: 'status',
    name: 'Status',
    minWidth: '160px',
    isDisabledChangeVisible: true,
    renderRowCell: ({ status }) => <PowerDialerStatusBadge status={status} />,
  },
  {
    field: 'enrolled',
    name: 'Enrolled',
    renderRowCell: ({ enrolled }) => <NumberCell value={enrolled} />,
  },
  {
    field: 'contacted',
    name: 'Contacted',
    renderRowCell: ({ contacted }) => <NumberCell value={contacted} />,
  },
  {
    field: 'not_contacted',
    name: 'Not contacted',
    renderRowCell: ({ noContacted }) => <NumberCell value={noContacted} />,
  },
  {
    field: 'answered',
    name: 'Answered',
    isHideByDefault: true,
    renderRowCell: ({ answered }) => <NumberCell value={answered} />,
  },
  {
    field: 'no_answer',
    name: 'No answer',
    isHideByDefault: true,
    renderRowCell: ({ noAnswered }) => <NumberCell value={noAnswered} />,
  },
  {
    field: 'skipped_by_user',
    name: 'Skipped by user',
    isHideByDefault: true,
    renderRowCell: ({ skipped }) => <NumberCell value={skipped} />,
  },
  {
    field: 'call_from',
    name: 'Call from',
    width: '250px',
    renderRowCell: (row) =>
      row.sendFromNumbersProps ? <NameValueRecord {...row.sendFromNumbersProps} /> : <EmptyCell />,
  },
]

const NameCell = observer(({ name }: { name: string }) => (
  <Typography
    variant={'body-md-regular'}
    ellipsis
    tooltipProps={{
      label: name,
    }}
    ariaLabel={'PowerDialerName'}
  >
    {name}
  </Typography>
))
