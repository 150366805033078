import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { PhoneApi } from 'entities/Phone'
import { NUMBER_TABLE_STORE_KEY_ASSIGNED } from 'pages/settings/pages/numbers/lib'
import { NumberTableStore } from 'pages/settings/pages/numbers/ui/NumbersTable/store/NumberTableStore'
import { NumbersTable } from 'pages/settings/pages/numbers/ui/NumbersTable/ui/NumbersTable'

export const AssignedNumbers = observer(() => {
  const store = useMemo(
    () => new NumberTableStore(NUMBER_TABLE_STORE_KEY_ASSIGNED, PhoneApi.getNumbers),
    []
  )

  return <NumbersTable numberTableStore={store} />
})
