// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RcfyBLN1_WMOUczJnB6u{display:flex;flex-direction:column;gap:4px}.RcfyBLN1_WMOUczJnB6u .ZtvlQ0T11F5p4vPpkjYo{margin-top:20px;padding:12px;font-weight:400;font-size:14px;line-height:20px}.RcfyBLN1_WMOUczJnB6u .ZtvlQ0T11F5p4vPpkjYo.wmyEg9QKf0OevTGiP4sX{margin-bottom:4px}.RcfyBLN1_WMOUczJnB6u .XkA75OhW94OjLzb7brSV{margin:14px 0px 3px}", "",{"version":3,"sources":["webpack://./src/widgets/Register/ui/Step4/ui/MaxMindModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,4CACE,eAAA,CACA,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,iEACE,iBAAA,CAIJ,4CACE,mBAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n  .textField {\n    margin-top: 20px;\n    padding: 12px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.error {\n      margin-bottom: 4px;\n    }\n  }\n\n  .wrapResend {\n    margin: 14px 0px 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "RcfyBLN1_WMOUczJnB6u",
	"textField": "ZtvlQ0T11F5p4vPpkjYo",
	"error": "wmyEg9QKf0OevTGiP4sX",
	"wrapResend": "XkA75OhW94OjLzb7brSV"
};
export default ___CSS_LOADER_EXPORT___;
