import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Button, IconButton, TextFieldWithLabel, Typography } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { formatBytesToNumberWithType } from 'shared/lib'
import { AttachmentCard } from 'entities/Attachment'
import { useComplianceSettingsContext } from '../context/complianceSettingsContext'

import styles from './styles.module.scss'
import { UploadDoc } from './UploadDoc'

export const ManuallyBusinessFind = observer(() => {
  const { einStore, states, openDoNotHaveEINModal, loadBusinessProfileStates } =
    useComplianceSettingsContext()
  const {
    einItem,
    einSearch,
    attachmentData,
    setAttachmentData,
    deleteIrsEinAttachment,
    setEinSearch,
    addZipManually,
    addCityManually,
    addBusinessNameManually,
    addBusinessAddressManually,
    addBusinessAddress2Manually,
    addBusinessStateManually,
  } = einStore

  useEffect(() => {
    loadBusinessProfileStates()
  }, [])

  return (
    <Box>
      <TextFieldWithLabel
        errorMessage={einStore.errorMessage}
        labelProps={{
          label: {
            text: 'Employer Identification Number (EIN)',
            rightText: 'Where can I find it?',
            rightLink: 'https://d37rfzzzole5ut.cloudfront.net/images/irsExample.png',
          },
        }}
        textFieldProps={{
          value: einSearch,
          onChange: setEinSearch,
          onBlur: einStore.checkEinNumber,
          InputProps: {
            placeholder: 'e.g. 12-3456789',
          },
        }}
      />
      <Button
        text='I don’t have a United States EIN number'
        typeBtn='informative'
        contained='secondary'
        onClick={openDoNotHaveEINModal}
        className={styles.noHaveEinButton}
      />
      <TextFieldWithLabel
        marginBottom={3}
        labelProps={{
          label: {
            text: 'Legal business name',
            rightText: 'Where can I find it?',
            rightLink: 'https://qa.salesmessage.com/',
          },
        }}
        textFieldProps={{
          value: einItem?.name || '',
          onChange: addBusinessNameManually,
          InputProps: {
            placeholder: 'e.g. Example LLC',
          },
        }}
        bottomLabelProps={{
          label: {
            text: 'Enter the exact legal business name, as registered with the EIN',
          },
        }}
      />
      <TextFieldWithLabel
        marginBottom={3}
        labelProps={{
          label: {
            text: 'Business address',
            rightText: 'Where can I find it?',
            rightLink: 'https://qa.salesmessage.com/',
          },
        }}
        textFieldProps={{
          value: einItem?.street1 || '',
          onChange: addBusinessAddressManually,
          InputProps: {
            placeholder: 'Search or enter manually your address',
          },
        }}
        bottomLabelProps={{
          label: {
            text: 'Enter the exact business address, as registered with the EIN',
          },
        }}
      />
      <TextFieldWithLabel
        marginBottom={3}
        labelProps={{
          label: {
            text: 'Business address (optional)',
          },
        }}
        textFieldProps={{
          value: einItem?.street2 || '',
          onChange: addBusinessAddress2Manually,
          InputProps: {
            placeholder: 'Search or enter manually your address',
          },
        }}
      />
      <TextFieldWithLabel
        marginBottom={3}
        labelProps={{
          label: {
            text: 'City',
          },
        }}
        textFieldProps={{
          value: einItem?.city || '',
          onChange: addCityManually,
          InputProps: {
            placeholder: 'Search or enter manually your address',
          },
        }}
      />

      <Box marginBottom={3} display='flex' justifyContent='space-between' gap={3}>
        <Box flex={1}>
          <SelectField
            value={einItem?.state}
            items={states}
            setValue={addBusinessStateManually}
            btnProps={{
              size: 'large',
            }}
            labelProps={{
              label: {
                text: 'State',
              },
            }}
          />
        </Box>
        <Box flex={1}>
          <TextFieldWithLabel
            marginBottom={0}
            labelProps={{
              label: {
                text: 'Zip code',
              },
            }}
            textFieldProps={{
              value: einItem?.zip,
              className: styles.numberOfCreditsInput,
              onChange: addZipManually,
              InputProps: {
                placeholder: 'e.g. 90011',
              },
            }}
          />
        </Box>
      </Box>

      <Label
        marginBottom={8}
        questionTooltipProps={{ label: '??' }}
        label={{
          text: 'IRS EIN document (optional)',
          rightText: <div className={styles.recommendedLabel}>Recommended</div>,
        }}
      />
      <UploadDoc />
      <Typography
        variant='text-input-helper'
        ariaLabel='textInputHelper'
        color='var(--content-primary-tertiary)'
      >
        Upload your company&apos;s IRS EIN document to increase your chances of getting approved
      </Typography>

      {attachmentData && (
        <Box display='flex' justifyContent={'space-between'} alignItems={'center'} marginTop={4}>
          <Box display='flex' gap={'12px'}>
            <AttachmentCard attachment={attachmentData} loading={attachmentData.loading} />
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography ariaLabel='attachmentName' variant='body-md-regular'>
                {attachmentData.name}
              </Typography>
              <Typography
                ariaLabel='attachmentName'
                variant='body-sm-regular'
                color='var(--content-primary-tertiary)'
              >
                {formatBytesToNumberWithType(attachmentData?.file?.size || 0)}
              </Typography>
            </Box>
          </Box>
          <IconButton
            icon='close'
            ariaLabel='delete_document'
            size='small'
            color='tertiary'
            transparentBackground={true}
            onClick={() => {
              setAttachmentData(null)
              deleteIrsEinAttachment()
            }}
          />
        </Box>
      )}
    </Box>
  )
})
