import { makeAutoObservable } from 'mobx'
import React from 'react'
import { ActionItem } from 'shared/ui'
import { ContactsApi } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'
import { IAIActionTypeEnum, IAILayoutEnum } from 'entities/AIAssistant'
import { EnumVariantMessageField, MessageFieldStore, EmojiAction } from 'widgets/MessageField'
import { AIAssistantAction, AiAssistantStore } from 'widgets/AIAssistant'
import type { ContactsDetailsStore, IViewTypes } from 'widgets/ContactsDetails'

export class ContactNoteStore {
  private _title = ''
  private _loading = false
  private _onSend: (() => void) | null = null

  private _messageFieldStore: MessageFieldStore
  private _aiAssistantStore: AiAssistantStore

  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)

    this._messageFieldStore = new MessageFieldStore({
      placeholder: 'Write internal note…',
      noCounter: true,
      showActionsItems: 2,
      textLimit: {
        maxLength: 1600,
        message: 'Note can contain max 1600 characters',
        showNotification: true,
      },
      variant: EnumVariantMessageField.Note,
    })
    this._aiAssistantStore = new AiAssistantStore(this._messageFieldStore, {
      actions: [IAIActionTypeEnum.SummarizeConversation],
      getParamsCreateSummaryAnswer:
        this._contactsDetailsStore?.config?.getParamsCreateSummaryAnswer,
      additionalCreateSummaryAnswerAction: () => {
        this._messageFieldStore.setNoteMode(true)
      },
    })
    this.setActions()
    this.setAILayout()
  }

  get selectedContact() {
    return this._contactsDetailsStore.selectedContact
  }

  get isViewTypeNotes() {
    return this._contactsDetailsStore.isViewTypeNotes
  }

  get messageFieldStore() {
    return this._messageFieldStore
  }

  openView = (value: IViewTypes) => {
    this._contactsDetailsStore.openView(value)
  }

  setActions = () => {
    this._messageFieldStore?.setActions(this.getMessageFieldActions())
  }

  setAILayout = () => {
    this._aiAssistantStore.setLayout(IAILayoutEnum.Column)
  }
  reset = () => {
    this._messageFieldStore.reset()
  }

  clear = () => {
    this._messageFieldStore.clear()
  }

  getMessageFieldActions = () => {
    const actions: (ActionItem | null)[] = []

    if (!this._contactsDetailsStore.notesProps?.hideMessageFieldActions.includes('emoji')) {
      actions.push({
        iconButtonComponent: <EmojiAction />,
      })
    }

    if (!this._contactsDetailsStore.notesProps?.hideMessageFieldActions.includes('ai_assistant')) {
      actions.push({
        iconButtonComponent: <AIAssistantAction aiAssistantStore={this._aiAssistantStore} />,
      })
    }

    return actions.filter((action) => !!action) as ActionItem[]
  }

  handleUpdate = async (contact?: Contact) => {
    const text = this._messageFieldStore.text

    if (!text || !contact || this._messageFieldStore.disabled || !this._messageFieldStore.editId) {
      return
    }

    try {
      this._messageFieldStore.setDisabledMessageField(true)
      const { data: newNote } = await ContactsApi.updateContactsByIdNotes(
        this._messageFieldStore.editId,
        {
          text,
        }
      )

      const filteredNotes = contact.notes.filter((note) => note.id !== newNote.id)

      contact.handleUpdateNotes(filteredNotes, [newNote])
    } catch (reqError) {
      console.log('LOG: update Note error: ', reqError)
    } finally {
      this.clear()
    }
  }

  handleCreate = async (contact?: Contact) => {
    const text = this._messageFieldStore.text

    if (!text || !contact || this._messageFieldStore.disabled) {
      return
    }

    try {
      this._messageFieldStore.setDisabledMessageField(true)
      const { data: newNote } = await ContactsApi.createContactsByIdNotes(contact.id, {
        text,
      })

      contact.handleUpdateNotes(contact.notes, [newNote])
    } catch (reqError) {
      console.log('LOG: create Note error: ', reqError)
    } finally {
      this.clear()
    }
  }
}
