import { FC } from 'react'
import { usersStore } from 'entities/Users'
import type { IHubspotPropertiesObjectType } from 'entities/Integrations'
import {
  NewPropertiesDropdown,
  type NewPropertiesDropdownStore,
} from 'widgets/NewPropertiesDropdown'

type IAddPropertyActionProps = {
  store: NewPropertiesDropdownStore
  objectType: IHubspotPropertiesObjectType
  objectId?: number
}

export const AddPropertyAction: FC<IAddPropertyActionProps> = ({ store, objectId, objectType }) => {
  const user = usersStore.user
  const isUserViewOnly = !!user?.isViewOnlyRole

  if (!objectId && objectType !== 'contact') return null
  if (isUserViewOnly) return null

  return <NewPropertiesDropdown store={store} objectType={objectType} objectId={objectId} />
}
