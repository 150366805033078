// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qVdNAmTDbOKj8n853QKH{color:var(--content-negative-primary)}.TdjGeMGGnrpi7nYcH0CJ{color:var(--content-primary-tertiary);font-size:13px;font-weight:400}.w6KLYv_gPXiDFUyhiQKh{background-color:#fff}.BfcnGdxzdA9NLyXve1oz{border-top:1px var(--border-neutral-default) solid;padding:5px 0}.BfcnGdxzdA9NLyXve1oz.FphseNGt3ooa1Vq7PWgO{display:none}.BfcnGdxzdA9NLyXve1oz .M5TdI440p13w9kiXHag0{display:flex;align-items:center;justify-content:space-between;padding:2px 4px 2px 8px}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/Widget/WidgetMultiText/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CAGF,sBACE,qCAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,kDAAA,CACA,aAAA,CAEA,2CACE,YAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,uBAAA","sourcesContent":[".warning {\n  color: var(--content-negative-primary);\n}\n\n.rightText {\n  color: var(--content-primary-tertiary);\n  font-size: 13px;\n  font-weight: 400;\n}\n\n.textField {\n  background-color: white;\n}\n\n.textValues {\n  border-top: 1px var(--border-neutral-default) solid;\n  padding: 5px 0;\n\n  &.emptyList {\n    display: none;\n  }\n\n  .value {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 2px 4px 2px 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "qVdNAmTDbOKj8n853QKH",
	"rightText": "TdjGeMGGnrpi7nYcH0CJ",
	"textField": "w6KLYv_gPXiDFUyhiQKh",
	"textValues": "BfcnGdxzdA9NLyXve1oz",
	"emptyList": "FphseNGt3ooa1Vq7PWgO",
	"value": "M5TdI440p13w9kiXHag0"
};
export default ___CSS_LOADER_EXPORT___;
