// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e_BJps4u8eRmA7hx0rli{padding-left:30px}.QOgWkpIrhQfU5SbWAlVa{margin-left:auto;display:none}@media(max-width: 992px){.QOgWkpIrhQfU5SbWAlVa{display:inline-flex}}.nnOVW6gfAYUjZsWjq6SB{position:absolute;left:20px}@media(min-width: 1200px){.nnOVW6gfAYUjZsWjq6SB{display:none}}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/pages/chatbotEdit/ui/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CAGF,sBACE,gBAAA,CAEA,YAAA,CACA,yBAJF,sBAKI,mBAAA,CAAA,CAIJ,sBAIE,iBAAA,CACA,SAAA,CAJA,0BADF,sBAEI,YAAA,CAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\n.headerLabel {\n  padding-left: 30px;\n}\n\n.headerButton {\n  margin-left: auto;\n\n  display: none;\n  @media (max-width: $breakpoint-s) {\n    display: inline-flex;\n  }\n}\n\n.wrapCollapseBtn {\n  @media (min-width: $breakpoint-m) {\n    display: none;\n  }\n  position: absolute;\n  left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerLabel": "e_BJps4u8eRmA7hx0rli",
	"headerButton": "QOgWkpIrhQfU5SbWAlVa",
	"wrapCollapseBtn": "nnOVW6gfAYUjZsWjq6SB"
};
export default ___CSS_LOADER_EXPORT___;
