import { observer } from 'mobx-react-lite'
import type { UIEvent } from 'react'
import { Button } from 'shared/ui'
import { AccordionSection, ContactsList } from 'widgets/CallPopUp'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import styles from 'widgets/CallPopUp/ui/styles.module.scss'

export const CallPowerDialerSection = observer(() => {
  const { callPopUpPowerDialerStoreV2, innerRefTooltipCurrent } = useCallPopUpDefaultContext()
  const {
    actualUnprocessedCount,
    isPause,
    isSaving,
    handleCheckCredits,
    togglePowerDialerPause,
    handlePowerDialerStop,
    handleSkipContact,
    contacts,
    currentPowerDialerName,
    handleSaveAndExit,
    fetchNextContacts,
    hasUnfetchedContacts,
    contactsLoading,
    isNonPowerDialerCallActive,
    isPreCallSetupInProgress,
  } = callPopUpPowerDialerStoreV2

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    const scrollHeight = target.scrollHeight
    const scrollTop = target.scrollTop
    const offsetHeight = target.offsetHeight

    if (contactsLoading) return
    if (!hasUnfetchedContacts) return
    if (scrollHeight <= scrollTop + offsetHeight + 20) {
      fetchNextContacts()
    }
  }

  return (
    <AccordionSection show count={actualUnprocessedCount} title={currentPowerDialerName}>
      <div className={styles.wrapBtn}>
        <Button
          contained={'secondary'}
          iconProps={{
            icon: isPause ? 'play' : 'pause',
          }}
          text={isPause ? 'Resume' : 'Pause'}
          fullWidth
          disabled={isNonPowerDialerCallActive || isPreCallSetupInProgress}
          onClick={() => {
            if (handleCheckCredits()) return

            togglePowerDialerPause()
          }}
        />
        <Button
          contained={'primary'}
          intent={'destructive'}
          iconProps={{
            icon: 'stop',
          }}
          text={'Stop'}
          fullWidth
          disabled={isPreCallSetupInProgress}
          onClick={handlePowerDialerStop}
        />
      </div>

      <ContactsList
        items={contacts}
        innerRefCurrent={innerRefTooltipCurrent || undefined}
        onDelete={(item) => handleSkipContact(item)}
        handleScroll={handleScroll}
        loading={contactsLoading}
      />
      {isPause && (
        <Button
          contained={'secondary'}
          text={'Save and exit'}
          loading={isSaving}
          fullWidth
          onClick={handleSaveAndExit}
          className={styles.saveAndExitButton}
        />
      )}
    </AccordionSection>
  )
})
