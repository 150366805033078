import { useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useChatbotDialogContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'
import { DialogHeader } from './DialogHeader'
import { DialogList } from './DialogList'
import { DialogEmpty } from './DialogEmpty'
import { MessageBox } from './MessageBox'

export const ChatbotDialog = observer(() => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { started, loading } = useChatbotDialogContext()

  useEffect(() => {
    if (!ref.current) return

    ref.current.scrollTo({
      top: ref.current.scrollHeight,
      behavior: 'smooth',
    })
  }, [loading])

  return (
    <div ref={ref} className={styles.dialog}>
      <DialogHeader />
      {started || loading ? (
        <>
          <DialogList />
          <MessageBox />
        </>
      ) : (
        <DialogEmpty />
      )}
    </div>
  )
})
