import { makeAutoObservable, reaction, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'

import {
  ChatbotApi,
  ChatbotListItem,
  ChatbotListParams,
  IParamsGetChatbotList,
  IResponseChatbotList,
} from 'entities/Chatbot'
import { chatbotOperation } from './chatbotOperationStore'

export class ChatbotListStore {
  constructor() {
    makeAutoObservable(this)

    reaction(() => this._chatbotListRequestParams, this.loadData, {
      delay: 500,
    })
  }

  page = 1
  total = 0
  limit = 10
  search = ''
  loading = true
  loadingScheduled = false
  initialLoading = true

  private _cancelTokenSource: CancelTokenSource | null = null
  private _listMap = new Map<number, ChatbotListItem>()
  private _updatingIds = new Set<number>()

  get items() {
    return Array.from(this._listMap.values())
  }

  get isEmpty() {
    if (this.initialLoading || this.loadingScheduled || this.loading || !!this.search.length)
      return false

    return !this._listMap.size
  }

  setSearch = (value: string) => {
    this.page = 1
    this.search = value
    this.loadingScheduled = true
  }

  loadData = async () => {
    this.loading = true
    this._initCancelPageSource()

    try {
      const { data } = await ChatbotApi.getChatbotList(this._chatbotListRequestParams, {
        ...(this._cancelTokenSource ? { cancelToken: this._cancelTokenSource.token } : null),
      })

      this._setData(data)

      runInAction(() => {
        this.loading = false
        this.initialLoading = false
      })
    } catch (error) {
      runInAction(() => {
        this.loading = error instanceof CanceledError
        this.loadingScheduled = false
      })

      console.error(error)
    }
  }

  updateStatus = async (id: number, isActive: boolean) => {
    if (this._updatingIds.has(id)) return
    this._updatingIds.add(id)

    try {
      const { data } = (await chatbotOperation.updateStatus(id, isActive)) ?? {}

      runInAction(() => {
        if (!data) return

        this._listMap.get(data.id)?.syncOrigin(data)
      })
    } catch (error) {
      console.error(error)
    } finally {
      this._updatingIds.delete(id)
    }
  }

  deleteRow = (id: number) =>
    chatbotOperation.delete(id).then((deleted) => {
      if (deleted) this.loadData()
    })

  changePaging = (page: number, limit: number) => {
    this.page = page
    this.limit = limit
    this.loadingScheduled = true
  }

  dispose = () => {
    this._cancelTokenSource?.cancel()
  }

  private get _chatbotListRequestParams(): IParamsGetChatbotList {
    return {
      page: this.page,
      limit: this.limit,
      [ChatbotListParams.Name]: this.search,
    }
  }

  private _initCancelPageSource = () => {
    this._cancelTokenSource?.cancel()

    this._cancelTokenSource = axios.CancelToken.source()
  }

  private _setData = ({ data, meta }: IResponseChatbotList) => {
    this._listMap.clear()
    data.forEach((item) => this._listMap.set(item.id, new ChatbotListItem(item)))

    this.page = meta.current_page
    this.total = meta.total
  }
}
