import React from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Avatar } from 'shared/ui'
import { Contact } from 'entities/Contacts/model/Contact'
import styles from './styles.module.scss'

type IContactCardProps = {
  item: Contact
  active: boolean
  onChange?: (item: Contact) => void
}

export const ContactCard: React.FC<IContactCardProps> = observer(
  ({ item, onChange = () => {}, active }) => {
    return (
      <div
        className={classnames(styles.wrap, {
          [styles.active]: active,
        })}
        onClick={() => {
          onChange(item)
        }}
      >
        <div className={styles.avatar}>
          <Avatar info={item.avatarInfo} size={32} />
        </div>

        <div className={styles.info}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.number}>{item.formatted_number}</div>
        </div>
      </div>
    )
  }
)
