import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { contactsMergeModalStore } from 'widgets/ContactsMergeModal'
import { contactsCleanupStore } from 'widgets/ContactsTable'

import styles from './styles.module.scss'

export const ContactsMergeModalActions = observer(() => {
  const {
    isLoading,
    loadingMergeClick,
    activeItemIdx,
    loadingDuplicateClick,
    nextItem,
    merge,
    changeLoadingDuplicateClick,
    changeMergeLoadingClick,
  } = contactsMergeModalStore
  const { total, successMergeHandler } = contactsCleanupStore.contactsDuplicateTable
  const { duplicateCount, subtractDuplicateCount } = contactsCleanupStore

  const successDuplicateMergeHandler = (id: number) => {
    successMergeHandler(id)
    subtractDuplicateCount()
  }

  return (
    <div className={styles.actionsWrapper}>
      <Typography
        variant={'body-md-regular'}
        color='var(--content-primary-disabled)'
        ariaLabel={'total'}
      >
        {`${activeItemIdx + 1} of ${total}`}
      </Typography>
      <div className={styles.actions}>
        <Button
          loading={loadingDuplicateClick}
          disabled={isLoading}
          size={'medium'}
          contained={'secondary'}
          text={'Keep both'}
          onClick={() => {
            nextItem()
            changeLoadingDuplicateClick(true)
          }}
        />

        <Button
          loading={loadingMergeClick}
          disabled={isLoading}
          size={'medium'}
          contained={'primary'}
          text={'Merge'}
          onClick={() => {
            merge(successDuplicateMergeHandler, duplicateCount)

            changeMergeLoadingClick(true)
          }}
        />
      </div>
    </div>
  )
})
