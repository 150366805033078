import { observer } from 'mobx-react-lite'
import { getAriaLabel } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { Activity } from 'entities/Activity/model/Activity'
import { Message } from 'entities/Message/model/Message'
import { DateContent } from 'widgets/DayContent/DateContent'
import { ActivityCard } from 'widgets/ActivityCard/ActivityCard'
import { MessageCard } from 'widgets/MessageCard'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import styles from './styles.module.scss'

type IDayContentProps = {
  day: string
  items: Array<Message | Activity>
  isScheduled?: boolean
  isLastDay?: boolean
  conversationMessagesStore?: ConversationMessagesStore | null
}

export const DayContent = observer(
  ({ day, items, isScheduled, isLastDay, conversationMessagesStore }: IDayContentProps) => {
    return (
      <div className={styles.wrap} aria-label={getAriaLabel('DayContent')}>
        <DateContent day={day} isScheduled={isScheduled} />
        <div className={styles.messagesWrap} aria-label={getAriaLabel('MessagesList')}>
          {items.map((item, index) => {
            const prevItem = items[index - 1]

            const isPrevItemMessage = prevItem instanceof Message
            const isPrevItemActivity = prevItem instanceof Activity
            const prevItemUserId = isPrevItemMessage
              ? prevItem.isOutbound
                ? prevItem.user_id
                : prevItem.contact_id
              : prevItem?.user.user_id

            if (item instanceof Message) {
              const currentItemUserId = item.isOutbound ? item.user_id : item.contact_id

              const hideTeamMessageCard =
                item.isConversationsHistory &&
                index > 0 &&
                item.teamId === prevItem.teamId &&
                currentItemUserId === prevItemUserId &&
                ((isPrevItemMessage &&
                  ((item.isOutbound && prevItem.isOutbound) ||
                    (!item.isOutbound && !prevItem.isOutbound))) ||
                  isPrevItemActivity)

              return (
                <MessageCard
                  key={item.client_id}
                  message={item}
                  isLast={index === items.length - 1 && isLastDay}
                  avatarInfo={
                    item.isOutbound
                      ? usersStore.usersMap.get(item.user_id || 0)?.avatarInfo
                      : contactsStore.getItem(item.contact_id)?.avatarInfo
                  }
                  hideTeam={hideTeamMessageCard}
                  conversationMessagesStore={conversationMessagesStore}
                  callContentStore={conversationMessagesStore?.callContentStore}
                />
              )
            }

            const currentItemUserId = item.user.user_id

            const hideTeamActivityCard =
              item.isConversationsHistory &&
              index > 0 &&
              item.teamId === prevItem.teamId &&
              currentItemUserId === prevItemUserId

            return (
              <ActivityCard
                key={item.id}
                activity={item}
                avatarInfo={usersStore.usersMap.get(item.user_id || 0)?.avatarInfo}
                hideTeam={hideTeamActivityCard}
              />
            )
          })}
        </div>
      </div>
    )
  }
)
