import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { toastStore } from 'shared/ui'
import { replaceLink } from 'shared/lib'
import { MAX_NAME_LENGTH } from 'shared/constants/maxNameLength'
import { IParamsCreateCannedMessages, CannedMessagesApi } from 'entities/CannedMessages'
import { ShortenLinkIconAction } from 'entities/ShortLink/ui/actions/ShortenLinkIconAction'
import { CannedMessage } from 'entities/CannedMessages/model/CannedMessage'
import { NewSavedReplyContent, savedRepliesStore } from 'widgets/SavedReplies'
import { MessageFieldStore } from 'widgets/MessageField/store/messageFieldStore'
import { MediaAction } from 'widgets/MessageField/ui/FieldActions/MediaAction/MediaAction'
import { EmojiAction } from 'widgets/MessageField/ui/FieldActions/EmojiAction'
import { MergeFieldsIconAction } from 'widgets/MergeField/ui/actions/MergeFieldsIconAction/MergeFieldsIconAction'
import { mergeFieldReplacer } from 'widgets/MessageField/lib'
import { MergeField } from 'widgets/MergeField/types/MergeField'

class NewSavedReplyStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalId = ''
  messageFieldStore = new MessageFieldStore({
    fromSavedReply: true,
    placeholder: 'Add a response (some details)',
    minHeight: 64,
    showActionsItems: 4,
    noBorder: true,
    makeActions: () => [
      {
        iconButtonComponent: <MediaAction />,
      },
      {
        iconButtonComponent: <EmojiAction />,
      },
      {
        iconButtonComponent: (
          <MergeFieldsIconAction
            onAddMergeField={(value) => this.onAddMergeField(value)}
            isAllIntegration
          />
        ),
      },
      {
        iconButtonComponent: (
          <ShortenLinkIconAction
            onAddShortLink={(link: string) =>
              this.messageFieldStore.addContent &&
              this.messageFieldStore.addContent(replaceLink(link) + '&nbsp;')
            }
          />
        ),
      },
    ],
  })

  title = ''
  isPublic = false
  is_favorite = false
  loading = false
  duplicate = false
  savedReplay: CannedMessage | null = null

  get isEdit() {
    return this.savedReplay && !this.duplicate
  }

  get disabled() {
    return (
      this.title.length > MAX_NAME_LENGTH ||
      this.loading ||
      this.messageFieldStore.loadingAttachment ||
      this.messageFieldStore.disablesSend ||
      !this.title.trim()
    )
  }

  get createSavedReplyRequest(): IParamsCreateCannedMessages {
    return {
      contact_id: savedRepliesStore.contact?.id,
      public: this.isPublic,
      is_favorite: this.is_favorite,
      media_url: this.messageFieldStore.messageRequestData.media_url,
      integration_id: null,
      title: this.title,
      message: this.messageFieldStore.messageRequestData.message,
    }
  }

  reset = () => {
    this.isPublic = false
    this.title = ''
    this.messageFieldStore.reset()
  }

  onAddMergeField = (mergeField: MergeField) => {
    if (this.messageFieldStore.addContent) {
      this.messageFieldStore.addContent(mergeFieldReplacer(mergeField.template) + '&nbsp;')
    }
  }

  handleNewSavedReply = (savedReplay?: CannedMessage | null, duplicate?: boolean) => {
    modalStore.setHideModalId(savedRepliesStore.modalId)
    this.reset()
    this.duplicate = duplicate || false
    this.savedReplay = savedReplay || null
    this.modalId = nanoid()

    if (savedReplay) {
      this.title = savedReplay.title + (duplicate ? ' (Copy)' : '')
      this.isPublic = savedRepliesStore.disabledVisibility ? false : savedReplay.public
      this.is_favorite = duplicate ? false : savedReplay.is_favorite
      this.messageFieldStore.setMessageData({
        attachments: savedReplay.attachments,
        message: savedReplay.message,
        isReset: false,
        isFocus: true,
        isReplace: true,
      })
    } else {
      this.isPublic = false
      this.is_favorite = false
    }

    const title = `${this.isEdit ? 'Edit' : 'New'} saved reply`

    modalStore.addModal({
      id: this.modalId,
      title,
      ModalContent: () => (
        <NewSavedReplyContent
          newSavedReplyStore={this}
          disabledVisibility={savedRepliesStore.disabledVisibility}
        />
      ),
      width: 720,
      pureContent: true,
      onClose: this.onCancel,
    })
  }

  setTitle = (title: string) => {
    this.title = title
  }

  setPublic = (isPublic: boolean) => {
    this.isPublic = isPublic
    toastStore.add({
      title: `Visibility changed to ${isPublic ? 'everyone' : 'personal'}`,
      type: 'info',
    })
  }

  onCancel = () => {
    this.reset()
    modalStore.removeModal(this.modalId)
    modalStore.removeHideModalId(savedRepliesStore.modalId)
  }

  onCreate = async () => {
    try {
      this.loading = true
      if (this.isEdit && this.savedReplay) {
        const { data } = await CannedMessagesApi.updateCannedMessagesById({
          ...this.createSavedReplyRequest,
          integration_id: this.savedReplay.integration_id,
          id: this.savedReplay.id,
        })
        savedRepliesStore.setSavedReplay(data)
        toastStore.add({
          title: 'Reply changes saved',
          type: 'success',
        })
      } else {
        await CannedMessagesApi.createCannedMessages(this.createSavedReplyRequest)
        toastStore.add({
          title: 'Saved reply created',
          type: 'success',
        })
        savedRepliesStore.loadData()
      }

      this.onCancel()
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }
}

export const newSavedReplyStore = new NewSavedReplyStore()
export type INewSavedReplyStore = typeof newSavedReplyStore
