// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GqmxQT69zpWdAp6HuFJw{display:flex;align-items:center;justify-content:center;padding:2.5px 3px;width:fit-content;border-radius:6px;border:1px solid var(--black-20);background:var(--background-primary-inverted-primary)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Icon/BoxIcon/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,gCAAA,CACA,qDAAA","sourcesContent":[".wrap{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 2.5px 3px;\n  width: fit-content;\n  border-radius: 6px;\n  border: 1px solid var(--black-20);\n  background: var(--background-primary-inverted-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "GqmxQT69zpWdAp6HuFJw"
};
export default ___CSS_LOADER_EXPORT___;
