import { makeAutoObservable } from 'mobx'
import { IRegisterCookies } from 'entities/Auth/store/types'
import { IParamsRegisterAnswersStep2 } from 'entities/Auth'
import { ICrm, CrmEnum } from './type'

export const MAX_CRM_LENGTH = 50

export class Step2Store {
  private _crm: ICrm | null = null
  private _contactsCount = ''

  constructor() {
    makeAutoObservable(this)
  }

  get payload() {
    return {
      answer_integration: this.crm?.name || '',
      answer_contactsCount: this._contactsCount,
    }
  }

  get answers(): IParamsRegisterAnswersStep2 {
    return {
      crm: this.crm?.name || '',
      contacts_number: this._contactsCount,
    }
  }

  get isCompletedStep() {
    return !!this.crm && !!this.contactsCount
  }

  initCookies = (data: IRegisterCookies) => {
    if (data.answer_integration) {
      const crm = this.crms.find((item) => item.name === data.answer_integration)
      if (crm) this._crm = crm

      if (crm) {
        this._crm = crm
      } else {
        this._crm = {
          id: CrmEnum.other,
          name: data.answer_integration,
          icon: 'lightning2',
        }
      }
    }

    if (data.answer_contactsCount) this._contactsCount = data.answer_contactsCount
  }

  get crm() {
    return this._crm
  }

  get crmLength() {
    if (!this._crm) return 0
    return this._crm.name.length
  }

  get contactsCount() {
    return this._contactsCount
  }

  get crms(): ICrm[] {
    return [
      {
        id: CrmEnum.hubSpot,
        name: 'HubSpot',
        icon: 'hubspot',
      },
      {
        id: CrmEnum.activeCampaign,
        name: 'ActiveCampaign',
        icon: 'activecampaign',
      },
      {
        id: CrmEnum.keap,
        name: 'Keap',
        icon: 'infusionsoft',
      },
      {
        id: CrmEnum.salesforce,
        name: 'Salesforce',
        icon: 'salesforce',
      },
      {
        id: CrmEnum.pipedrive,
        name: 'Pipedrive',
        icon: 'pipedrive',
      },
      {
        id: CrmEnum.none,
        name: 'None',
        icon: 'block',
      },
      {
        id: CrmEnum.other,
        name: 'Other',
        icon: 'lightning2',
      },
    ]
  }

  get contactsCounts(): string[] {
    return ['100 - 500', '501 - 1,000', '1,001 - 2,500', '2,501 - 10,000', '10,001 - 50,000']
  }

  get contactsCountsDropdown(): string[] {
    return ['50,000 - 100,000', '100,001 - 250,000', '250,001 - 500,000', '500,001 - 1M', '1M+']
  }

  get isSelectedContactsCountsDropdown() {
    return this.contactsCountsDropdown.includes(this._contactsCount)
  }

  get isNextDisabled() {
    return Boolean(!this._crm || this._crm.name.length > MAX_CRM_LENGTH || !this._contactsCount)
  }

  setContactsCount = (value: string) => {
    this._contactsCount = value
  }

  setCrm = (crm: ICrm | null) => {
    if (crm && crm.name.length > MAX_CRM_LENGTH) return
    this._crm = crm
  }
}
