import { makeAutoObservable } from 'mobx'
import { days, IDays, longShort2DayMap } from 'shared/lib'
import { IDropdownItem } from 'shared/ui'
import { getSendTextByDays } from 'shared/ui/Schedule'

export type IWeekDaysSelectorStoreProps = {
  days: IDays[]
}

export class WeekDaysSelectorStore {
  constructor(props?: IWeekDaysSelectorStoreProps) {
    if (props) {
      this.init(props)
    }
    makeAutoObservable(this)
  }

  daysMap: Map<IDays, boolean> = new Map(
    days.map((day, index) => [day, index !== 0 && index !== 6])
  )
  get props(): IWeekDaysSelectorStoreProps {
    return {
      days: this.selectedDays,
    }
  }
  get daysItems(): IDropdownItem[] {
    return Array.from(this.daysMap.entries()).map(([key, value]) => ({
      id: key,
      label: longShort2DayMap.get(key) as string,
      activeValue: value,
    }))
  }

  get selectedDaysItems() {
    return this.daysItems.filter((item) => item.activeValue)
  }

  get selectedDays() {
    return this.selectedDaysItems.map((item) => item.id as IDays)
  }

  get text() {
    return getSendTextByDays(this.selectedDays)
  }

  onToggleDay = (day: IDays) => {
    const prevValue = this.daysMap.get(day)
    if (prevValue && this.selectedDays.length === 1) return
    this.daysMap.set(day, !prevValue)
  }

  init = ({ days }: IWeekDaysSelectorStoreProps) => {
    this.daysMap.forEach((_, key) => {
      this.daysMap.set(key, days.includes(key))
    })
  }
}
