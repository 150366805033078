import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Button, Switch, CircularProgress } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { ChatbotInstructionType } from 'entities/Chatbot'
import { ChatbotPath } from 'entities/ExportRouters'
import { ChatbotRoutes } from 'pages/chatbot'
import { useChatbotContext, useChatbotDialogContext } from 'pages/chatbot/context'
import { CreateChatbotStore } from 'pages/chatbot/store/chatbotCreateStore'

import styles from './styles.module.scss'

export const ChatbotNewButton = observer(() => {
  const navigate = useNavigate()
  const store = useMemo(() => new CreateChatbotStore(), [])

  const handleClick = () => {
    store.create().then((payload) => {
      if (!payload) return
      const path =
        payload?.type === ChatbotInstructionType.BookAppointment
          ? `/${ChatbotPath.newBookAppointment}`
          : `/${ChatbotRoutes.root}/${ChatbotRoutes.new}`
      navigate(path, {
        state: payload,
      })
    })
  }

  useEffect(() => void store.init(), [])

  return (
    <Button
      text={featureFlagsStore.salesmsg_ai_agent_book_appointment_v2 ? 'New agent' : 'New Textbot'}
      iconProps={{ icon: 'add' }}
      size='medium'
      onClick={handleClick}
    />
  )
})

export const ChatbotCreateButton = observer(() => {
  const { dirty, createChatbot, message } = useChatbotContext()
  const { field } = message
  const [isCreating, setCreating] = useState(false)
  const navigate = useNavigate()

  const handleClick = () => {
    setCreating(true)

    createChatbot().then((id) => {
      setCreating(false)

      if (id != null) navigate(`/${ChatbotRoutes.root}/${id}`)
    })
  }

  const sandDisabled = !dirty || (field.isAirCallNoSupportMms && !featureFlagsStore.aircall_new_api)

  return (
    <Button
      text='Save'
      size='medium'
      disabled={sandDisabled}
      loading={isCreating}
      onClick={handleClick}
    />
  )
})

export const ChatbotCreateAndPublishButton = observer(() => {
  const [publishing, setPublish] = useState(false)
  const { createAndPublishChatbot } = useChatbotContext()
  const navigate = useNavigate()

  const handlePublish = () => {
    setPublish(true)

    createAndPublishChatbot().then((published) => {
      setPublish(false)

      if (published) navigate(`/${ChatbotRoutes.root}/${ChatbotRoutes.all}`)
    })
  }

  return (
    <div className={styles.publishButton} onClick={handlePublish}>
      <span>Draft</span>
      <span className={styles.publishButtonControl}>
        {publishing ? <CircularProgress size={12} /> : <Switch size='small' value={false} />}
      </span>
      <span>Publish</span>
    </div>
  )
})

export const ChatbotPublishButton = observer(() => {
  const [publishing, setPublish] = useState(false)
  const { publishChatbot } = useChatbotContext()
  const navigate = useNavigate()

  const handlePublish = () => {
    setPublish(true)

    publishChatbot().then((published) => {
      setPublish(false)

      if (published) navigate(`/${ChatbotRoutes.root}/${ChatbotRoutes.all}`)
    })
  }

  return (
    <div className={styles.publishButton} onClick={handlePublish}>
      <span>Draft</span>
      <span className={styles.publishButtonControl}>
        {publishing ? <CircularProgress size={12} /> : <Switch size='small' value={false} />}
      </span>
      <span>Publish</span>
    </div>
  )
})

export const ChatbotSaveButton = observer(() => {
  const [saving, setSaving] = useState(false)
  const { id, dirty, locked, updateChatbot } = useChatbotContext()
  const { invalidate } = useChatbotDialogContext()

  const saveDisabled = id == null || !dirty || locked

  const handleClick = () => {
    if (id == null) return

    setSaving(true)

    updateChatbot().then((updated) => {
      setSaving(false)

      if (updated) invalidate()
    })
  }

  return (
    <Button
      size='medium'
      text='Save changes'
      loading={saving}
      disabled={saveDisabled}
      onClick={handleClick}
    />
  )
})

export const ChatbotCancelButton = () => {
  const navigate = useNavigate()

  const handleClick = () => navigate(`/${ChatbotRoutes.root}/${ChatbotRoutes.all}`)

  return <Button text={'Cancel'} contained={'secondary'} size={'medium'} onClick={handleClick} />
}

export const ChatbotTestNewButton = observer(({ className }: { className?: string }) => {
  const navigate = useNavigate()
  const chatbot = useChatbotContext()

  const buttonDisabled = chatbot.locked || !chatbot.dirty
  const handleTestChatbot = async () => {
    const id = await chatbot.createChatbot(true)

    if (id != null) navigate(`/${ChatbotRoutes.root}/${id}`, { state: { test: true } })
  }

  return (
    <Button
      className={className}
      size='medium'
      contained='secondary'
      text='Test Textbot'
      disabled={buttonDisabled}
      onClick={handleTestChatbot}
    />
  )
})

export const ChatbotTestButton = observer(({ className }: { className?: string }) => {
  const [updating, setUpdating] = useState(false)
  const chatbot = useChatbotContext()
  const dialog = useChatbotDialogContext()

  const buttonText = dialog.started ? 'Restart Textbot' : 'Test Textbot'
  const buttonDisabled = updating || dialog.loading || chatbot.locked

  const handleSessionTrigger = async () => {
    if (chatbot.dirty) {
      setUpdating(true)
      const updated = await chatbot.updateChatbot(true)
      setUpdating(false)

      if (!updated) return
    }

    if (dialog.started) dialog.restart()
    else dialog.start()
  }

  return (
    <Button
      className={className}
      size='medium'
      contained='secondary'
      text={buttonText}
      disabled={buttonDisabled}
      onClick={handleSessionTrigger}
    />
  )
})

export const ChatbotButtonGroup = ({ children }: PropsWithChildren) => (
  <div className={styles.buttonGroup}>{children}</div>
)
