// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eHS1gLGUQ3Q7oGUsoxYS{display:flex;align-items:center;justify-content:space-between;width:100%;column-gap:24px}.inGflxxCGov3Cc4d3h_N{display:flex;align-items:center}.eAxaz4vl69O9hpJLCbrx{width:16px;text-align:center}.eTTJyW4WLn0bNpNe5uhI{margin-left:8px}.n8JSxcgqzvSHDAdKJTQ0>*:first-of-type{width:100%}.n8JSxcgqzvSHDAdKJTQ0>*:last-of-type{display:none}", "",{"version":3,"sources":["webpack://./src/widgets/PowerDialer/ui/Actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,eAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CAMA,sCACE,UAAA,CAEF,qCACE,YAAA","sourcesContent":[".dropdownCard {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  column-gap: 24px;\n\n  &__content {\n    display: flex;\n    align-items: center;\n  }\n\n  &__tooltip {\n    width: 16px;\n    text-align: center;\n  }\n\n  &__status {\n    margin-left: 8px;\n  }\n}\n\n.dropdown {\n  & > * {\n    &:first-of-type {\n      width: 100%;\n    }\n    &:last-of-type {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownCard": "eHS1gLGUQ3Q7oGUsoxYS",
	"dropdownCard__content": "inGflxxCGov3Cc4d3h_N",
	"dropdownCard__tooltip": "eAxaz4vl69O9hpJLCbrx",
	"dropdownCard__status": "eTTJyW4WLn0bNpNe5uhI",
	"dropdown": "n8JSxcgqzvSHDAdKJTQ0"
};
export default ___CSS_LOADER_EXPORT___;
