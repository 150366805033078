import { PropsWithChildren, createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ChatbotAnalyticStore } from 'pages/chatbot'

const ChatbotAnalyticContext = createContext<ChatbotAnalyticStore | null>(null)

export const useChatbotAnalyticContext = () => {
  const context = useContext(ChatbotAnalyticContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with ChatbotAnalyticProvider')

  return context
}

export const ChatbotAnalyticProvider = ({ children }: PropsWithChildren) => {
  const { chatbotId } = useParams()
  const store = useMemo(() => {
    if (chatbotId == null) throw new Error('Chatbot id is not defined')

    return new ChatbotAnalyticStore(+chatbotId)
  }, [])

  useEffect(() => store.dispose, [])

  return <ChatbotAnalyticContext.Provider value={store}>{children}</ChatbotAnalyticContext.Provider>
}
