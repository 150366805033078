import { makeAutoObservable } from 'mobx'
import { ReactNode } from 'react'
import { AxiosError } from 'axios'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { IDropdownItem } from 'shared/ui'
import { getCurrencySymbol } from 'shared/lib/currency'
import { Conversion, ConversionsApi, IParamsCreateConversion } from 'entities/Conversion'
import { NewConversationContent } from 'widgets/NewConversion'

export class NewConversionStore {
  onSuccessCreate: (conversion: Conversion) => void
  constructor({ onSuccessCreate }: { onSuccessCreate: (conversion: Conversion) => void }) {
    this.open()
    this.onSuccessCreate = onSuccessCreate
    makeAutoObservable(this)
  }
  id = 'newConversion'
  name = ''
  conversion_url = ''
  currency = 'USD'
  default_value = 0
  default_value_format = '$0.00'
  conversion_attribution = ''

  errorsMap: Map<keyof IParamsCreateConversion, ReactNode> = new Map()

  get params(): IParamsCreateConversion {
    return {
      name: this.name,
      conversion_url: this.conversion_url,
      currency: this.currency,
      default_value: this.default_value,
      default_value_format: this.default_value_format,
      conversion_attribution: this.conversion_attribution,
    }
  }

  get currencySymbol() {
    return getCurrencySymbol(this.currency)
  }

  get currencyItems(): IDropdownItem[] {
    return [
      {
        id: 'USD',
        label: 'USD - United States Dollar',
      },
      {
        id: 'CAD',
        label: 'CAD - Canadian Dollar',
      },
    ]
  }

  get conversionTypeItems(): IDropdownItem[] {
    return [
      {
        id: '2',
        label: 'Count every conversion',
      },
      {
        id: '1',
        label: 'Count a max of one conversion per contact',
      },
    ]
  }

  private open = () => {
    modalStore.addModal({
      id: this.id,
      title: 'New conversion',
      ModalContent: NewConversationContent,
      ModalContentProps: { store: this },
      primaryAction: {
        text: 'Create',
        onAction: this.onCreate,
      },
    })
  }

  setName = (value: string) => {
    this.name = value
  }
  setUrl = (value: string) => {
    this.conversion_url = value
  }
  setCurrency = (value: string) => {
    this.currency = value
  }

  setDefaultValue = (value: number, formated_value: string) => {
    this.default_value = value
    this.default_value_format = formated_value
  }

  setConversionType = (value: string) => {
    this.conversion_attribution = value
  }

  setError = (key: keyof IParamsCreateConversion, value: ReactNode) => {
    this.errorsMap.set(key, value)
  }

  onCreate = async () => {
    this.errorsMap.clear()
    if (!this.name.trim()) {
      this.setError('name', 'Please enter conversion name')
    }
    if (!this.conversion_url.trim()) {
      this.setError('conversion_url', 'Please enter a URL')
    }
    if (!this.conversion_attribution) {
      this.setError('conversion_attribution', 'Please select the conversion type')
    }
    if (!this.errorsMap.size) {
      try {
        const { data } = await ConversionsApi.createConversion(this.params)
        this.onSuccessCreate(data)
        modalStore.closeModal(this.id)
      } catch (e) {
        if (e instanceof AxiosError) {
          Object.entries(e.response?.data).forEach(([key, value]) => {
            this.setError(
              key as keyof IParamsCreateConversion,
              Array.isArray(value) ? value?.join(', ') : String(value)
            )
          })
        }
        console.error(e)
      }
    }
  }
}
