// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XoPDk_0NUHwuHqeRGCaA{display:flex;flex-direction:column;overflow:auto;height:100%;max-height:100vh;width:100%;position:relative}.Bghbafgn_T9_jePl3JEB{width:100%;margin:0 auto;max-width:1500px}.kRbc6Eeuj5RpOz4js2Wv{padding-inline:16px;padding-top:24px;display:grid;grid-template-columns:1fr 1fr;grid-template-rows:1fr;column-gap:10px;row-gap:24px;grid-template-areas:\"general stripe\" \"general custom\"}@media(max-width: 1200px){.kRbc6Eeuj5RpOz4js2Wv{grid-template-columns:1fr}}.Xcla96xWUf7Wh17M7kG0{padding:8px 20px 24px 20px;border-bottom:1px solid var(--border-neutral-default)}", "",{"version":3,"sources":["webpack://./src/pages/admin/ui/AdminDetailPage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA,CACA,iBAAA,CAEF,sBACE,UAAA,CACA,aAAA,CACA,gBAAA,CAEF,sBACE,mBAAA,CACA,gBAAA,CACA,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,eAAA,CACA,YAAA,CACA,qDACE,CAGF,0BAZF,sBAaI,yBAAA,CAAA,CAGJ,sBACE,0BAAA,CACA,qDAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  height: 100%;\n  max-height: 100vh;\n  width: 100%;\n  position: relative;\n}\n.wrapContent{\n  width: 100%;\n  margin: 0 auto;\n  max-width: 1500px;\n}\n.wrapInfo{\n  padding-inline:  16px;\n  padding-top: 24px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-template-rows: 1fr;\n  column-gap: 10px;\n  row-gap: 24px;\n  grid-template-areas: \n    \"general stripe\"\n    \"general custom\"; \n  \n  @media (max-width: 1200px) {\n    grid-template-columns: 1fr;\n  }\n}\n.wrapHeaderActions{\n  padding: 8px 20px 24px 20px;\n  border-bottom: 1px solid var(--border-neutral-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "XoPDk_0NUHwuHqeRGCaA",
	"wrapContent": "Bghbafgn_T9_jePl3JEB",
	"wrapInfo": "kRbc6Eeuj5RpOz4js2Wv",
	"wrapHeaderActions": "Xcla96xWUf7Wh17M7kG0"
};
export default ___CSS_LOADER_EXPORT___;
