import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'
import { ContactsMenuStore } from 'widgets/ContactsMenu'
import { ContactsTableStore } from 'widgets/ContactsTable'
import { SegmentModalStore } from 'widgets/ContactsMenu/ui/SegmentModal'
import { ContactsDetailsManageStore } from 'widgets/ContactsDetails/store'

export class ContactsPageStore {
  private _segmentModalStore = new SegmentModalStore()
  private _pageLayoutStore = new PageLayoutStore()
  private _contactsDetailsManageStore = new ContactsDetailsManageStore()
  private _contactsTableStore = new ContactsTableStore(
    this._pageLayoutStore,
    this._contactsDetailsManageStore
  )
  private _contactsMenuStore = new ContactsMenuStore(
    this._segmentModalStore,
    this._contactsTableStore,
    this._pageLayoutStore
  )

  constructor() {
    makeAutoObservable(this)

    this._contactsTableStore.setContactsMenuStore(this._contactsMenuStore)
    this._segmentModalStore.setContactsTableStore(this._contactsTableStore)

    this.init()
  }

  init = () => {
    this._contactsDetailsManageStore.init()
    this._contactsDetailsManageStore.reactionContactsDetailsStore(this._contactsTableStore)
  }

  reset = () => {
    this._contactsDetailsManageStore.reset()
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get contactsMenuStore() {
    return this._contactsMenuStore
  }

  get contactsTableStore() {
    return this._contactsTableStore
  }

  get segmentModalStore() {
    return this._segmentModalStore
  }

  get contactsDetailsStore() {
    return this._contactsDetailsManageStore.contactsDetailsStore
  }
}

export const contactsPageStore = new ContactsPageStore()
