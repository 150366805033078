import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Icon, IIconsVector, Status } from 'shared/ui'

import styles from './styles.module.scss'

export const PresetList = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div className={classNames(styles.list, className)}>{children}</div>
)

export interface IPresetListItemProps {
  icon: IIconsVector
  name: string
  description: string
  selected: boolean
  disabled?: boolean
  onClick: () => void
  isNew?: boolean
}

export const PresetListItem = ({
  icon,
  name,
  description,
  selected,
  disabled,
  onClick,
  isNew,
}: IPresetListItemProps) => (
  <section
    className={styles.listItem}
    data-disabled={!!disabled ? true : undefined}
    data-selected={!!selected ? true : undefined}
    onClick={onClick}
  >
    <div className={styles.listItemIcon}>
      <Icon icon={icon} />
    </div>
    <div className={styles.listItemContent}>
      <header className={styles.listItemContentTitle}>
        {name}
        {isNew && (
          <Status
            title={'New'}
            intent={'purple'}
            emphasis={'high'}
            size={'label'}
            className={styles.listItemContentNew}
          />
        )}
      </header>
      <p className={styles.listItemContentDescription}>{description}</p>
    </div>
  </section>
)
