import { Box } from '@mui/material'
import { FC } from 'react'
import { Typography } from 'shared/ui'
import { TriggerStatus, getStatusByKey, getConfigByKey } from 'entities/Trigger'

import styles from './styles.module.scss'

type IWidgetTriggerStatusTriggerProps = {
  items: number[]
}

export const WidgetTriggerStatusTrigger: FC<IWidgetTriggerStatusTriggerProps> = ({ items }) => {
  if (!items.length) return <>select</>

  return (
    <Box display='flex' gap={1} alignItems='center' height={32}>
      <div className={styles.group}>
        {items.map((key) => {
          return <TriggerStatus key={key} status={getStatusByKey(key)} onlyIcon emphasis='low' />
        })}
      </div>
      <Typography ariaLabel='TriggerStatus' variant='body-md-regular'>
        {items.length > 1 ? `${items.length} statuses` : getConfigByKey(items[0]).title}
      </Typography>
    </Box>
  )
}
