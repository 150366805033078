import { observer } from 'mobx-react-lite'
import { IconButton, SpeedMediaBtn, Tooltip } from 'shared/ui'
import { PlayBtn } from 'shared/ui/Button/ui/PlayBtn/PlayBtn'
import { InfoWrap } from 'widgets/PresentationMode/ui/info/ui/InfoWrap/InfoWrap'
import { DownloadPreviewBtn } from 'widgets/PresentationMode/ui/info/ui/buttons/DownloadPreviewBtn'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { VolumeBtn } from 'widgets/PresentationMode/ui/info/ui/buttons/VolumeBtn/VolumeBtn'
import { TimeMedia } from 'widgets/PresentationMode/ui/info/ui/TimeMedia/TimeMedia'
import { MediaProgressBar } from 'widgets/PresentationMode/ui/info/ui/MediaProgressBar/MediaProgressBar'

export const InfoVideoPreview = observer(() => {
  const { videoControllerStore } = presentationStore
  const { mediaControllerStore, isFull, onExpand } = videoControllerStore
  const { isPlay, currentTime, duration, togglePlay, speed, setSpeed } = mediaControllerStore

  return (
    <InfoWrap
      leftActions={
        <>
          <PlayBtn isPlay={isPlay} togglePlay={togglePlay} />
          <VolumeBtn mediaControllerStore={mediaControllerStore} />
          <TimeMedia currentTime={currentTime} duration={duration} />
        </>
      }
      middleContent={<MediaProgressBar mediaControllerStore={mediaControllerStore} />}
      rightActions={
        <>
          <SpeedMediaBtn speed={speed} setSpeed={setSpeed} />
          <DownloadPreviewBtn />
          <Tooltip label={isFull ? 'Exit full screen' : 'Full screen'}>
            <IconButton
              icon={isFull ? 'collapse' : 'expand'}
              color={'secondary'}
              variant={'icon'}
              onClick={onExpand}
              ariaLabel='InfoVideoPreview_fullscreen'
            />
          </Tooltip>
        </>
      }
    />
  )
})
