import { Status, IStatusProps } from 'shared/ui/Status'
import { ChatbotStatus } from 'entities/Chatbot'

type IStatusConfig = Pick<IStatusProps, 'icon' | 'title' | 'intent'>

const DEFAULT_CONFIG: IStatusConfig = {
  icon: 'question',
  title: 'Unknown',
  intent: 'neutral',
}

const STATUS_CONFIG: Record<ChatbotStatus, IStatusConfig> = {
  [ChatbotStatus.Active]: {
    icon: 'check2',
    title: 'Active',
    intent: 'positive',
  },
  [ChatbotStatus.Disabled]: {
    icon: 'minusCircle',
    title: 'Disabled',
    intent: 'neutral',
  },
  [ChatbotStatus.Draft]: {
    icon: 'draft',
    title: 'Draft',
    intent: 'neutral',
  },
}

const getStatusProps = (status: ChatbotStatus) => STATUS_CONFIG[status] ?? DEFAULT_CONFIG

export interface IChatbotStatusBadge {
  status: ChatbotStatus
}

export const ChatbotStatusBadge = ({ status }: IChatbotStatusBadge) => (
  <Status {...getStatusProps(status)} emphasis={'high'} />
)
