import { FC } from 'react'
import { RinglessMessageFieldContext } from 'widgets/MessageFormFields/RinglessMessageField/context/ringlessMessageFieldContext'
import type { RinglessMessageFieldStore } from 'widgets/MessageFormFields/RinglessMessageField/store/ringlessMessageFieldStore'
import { RinglessMessageFieldContent } from 'widgets/MessageFormFields/RinglessMessageField/ui/RinglessMessageFieldContent'

type IRinglessMessageField = {
  store: RinglessMessageFieldStore
}

export const RinglessMessageField: FC<IRinglessMessageField> = ({ store }) => {
  return (
    <RinglessMessageFieldContext.Provider value={store}>
      <RinglessMessageFieldContent />
    </RinglessMessageFieldContext.Provider>
  )
}
