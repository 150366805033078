import { useCallback, useEffect, useRef, useState } from 'react'
import type { ITextFieldMainAction } from 'shared/ui/TextField'

import coreStyles from 'shared/ui/TextField/styles.module.scss'

type ITextFieldHighlightLimit = {
  limit: number
  onChange?: (active: boolean) => void
}

const DEFAULT_TIMEOUT = 3_000

export const makeTextFieldHighlightLimit: (
  action: ITextFieldHighlightLimit
) => ITextFieldMainAction = (action) => {
  const useAction: ITextFieldMainAction = (control, children) => {
    const [active, setActive] = useState(() => (control.value?.length ?? 0) >= action.limit)
    const timer = useRef(-1)

    const deactivate = useCallback(() => {
      control.clearClassNames()
      window.clearTimeout(timer.current)
      setActive(false)
    }, [])

    const activate = useCallback(() => {
      deactivate()

      control.setClassNames(coreStyles.textFieldLabel_warning)
      setActive(true)

      timer.current = window.setTimeout(deactivate, DEFAULT_TIMEOUT)
    }, [])

    useEffect(() => action.onChange?.(active), [active, action.onChange])

    useEffect(() => {
      if ((control.value?.length ?? 0) >= action.limit) activate()
      else deactivate()
    }, [control.value])

    useEffect(() => {
      const input = control.inputRef?.current
      const container = control.containerRef?.current

      if (!input) return
      if (!container) return

      input.setAttribute('maxlength', `${action.limit}`)
      input.addEventListener('blur', deactivate)

      return () => {
        input.removeAttribute('maxlength')
        input.removeEventListener('blur', deactivate)

        deactivate()
      }
    }, [action.limit, action.onChange])

    return children
  }

  return useAction
}
