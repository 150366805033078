// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JvwgZXVXk7GBlGDkWC06{display:flex;align-items:center;justify-content:space-between;width:100%;padding:12px 12px 12px 16px;border-radius:6px;border:1px solid var(--border-brand-default);background:var(--light-blue-10)}.XHBWaLmschIPu2R8QXkt{display:flex;align-items:center;gap:12px}.v5BVr43MNviOqC34eFi9{display:flex;flex-direction:column;gap:6px}", "",{"version":3,"sources":["webpack://./src/widgets/CallModal/ui/CallModalActiveCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA,CACA,2BAAA,CACA,iBAAA,CACA,4CAAA,CACA,+BAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".card {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  padding: 12px 12px 12px 16px;\n  border-radius: 6px;\n  border: 1px solid var(--border-brand-default);\n  background: var(--light-blue-10);\n\n  &__box {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n  }\n\n  &__info {\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "JvwgZXVXk7GBlGDkWC06",
	"card__box": "XHBWaLmschIPu2R8QXkt",
	"card__info": "v5BVr43MNviOqC34eFi9"
};
export default ___CSS_LOADER_EXPORT___;
