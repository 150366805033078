import { Box } from '@mui/material'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { SwitchField } from 'shared/ui'
import { AdvancedScheduling } from 'shared/ui/Schedule/ui/AdvancedScheduling'
import { useImmediatelyContext } from '../context/immediatelyContext'

export const ImmediatelyContent = observer(() => {
  const { advancedSchedulingStore, toggleUseContactTimezone, isUseContactTimezone } =
    useImmediatelyContext()
  return (
    <>
      <AdvancedScheduling
        store={advancedSchedulingStore}
        expandedBoxProps={{
          borderBottom: '1px solid var(--border-neutral-default)',
        }}
      />
      {advancedSchedulingStore.isEnabled && (
        <Box
          padding={1}
          sx={{ background: 'var(--background-neutral-subtle)' }}
          borderRadius={'0 0 8px 8px'}
        >
          <SwitchField
            variant={'gray'}
            value={isUseContactTimezone}
            onClick={toggleUseContactTimezone}
            labelProps={{
              label: {
                leftIcon: 'userFilled',
                text: 'Use contact timezone',
              },
            }}
            buttonProps={{ paddingInline: '20px 16px' }}
          />
        </Box>
      )}
    </>
  )
})
