import { Base } from 'models'
import {
  IResponseIntegrationHubspotDeal,
  IResponseIntegrationHubspotCompany,
  IResponseIntegrationHubspotData,
  IResponseIntegrationHubspotPropertyFormatted,
} from 'entities/Integrations'

export class ContactIntegrationHubspotInfo extends Base {
  contactId: number
  companies: IResponseIntegrationHubspotCompany[]
  properties: IResponseIntegrationHubspotPropertyFormatted[]
  deals: IResponseIntegrationHubspotDeal[]

  constructor(item: IResponseIntegrationHubspotData) {
    super()

    this.contactId = item.contactId
    this.deals = item.deals
    this.companies = item.companies

    this.properties = Object.keys(item?.properties).map((key) => {
      return { ...item?.properties?.[key], key }
    })
  }
}
