import React, { useEffect, useState } from 'react'
import { ContainerHeader, EnumSpinnerLoaderPosition, SpinnerLoader } from 'shared/ui'
import { AdminStatusApi } from 'entities/Admin/status/api/adminStatus'
import { IResponseStatusService } from 'entities/Admin/status/api/type'
import { ServiceStatusItem } from 'pages/admin/pages/status/ui/ServiceStatusItem'
import styles from './styles.module.scss'

export const Status = () => {
  const [serviceList, setServiceList] = useState<IResponseStatusService[]>([])

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await AdminStatusApi.getStatusServices()
        const data = response.data.data
        setServiceList(data)
      } catch (error) {
        /* empty */
      }
    }

    fetchItems()
  }, [])

  return (
    <>
      {serviceList.length === 0 && <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />}
      <ContainerHeader
        title={'Status'}
        titleIconProps={{
          icon: 'settings',
          fontSize: 20,
          tertiary: true,
        }}
      />
      <div className={styles.wrap}>
        {serviceList.map((item) => (
          <ServiceStatusItem item={item} key={item.id} />
        ))}
      </div>
    </>
  )
}
