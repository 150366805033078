import { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { IconButton, Tooltip } from 'shared/ui'
import { TextFieldPlaceholder } from 'shared/ui/TextField'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotTextTooltip } from 'pages/chatbot/ui/ChatbotTooltip'

import styles from './styles.module.scss'

export const PointMaxAttempts = observer(() => {
  const {
    errors,
    model: { settings },
  } = useChatbotContext()
  const [errorIsVisible, setErrorVisibility] = useState(false)
  const ref = useRef<HTMLLabelElement | null>(null)

  const maxAttemptsError = errors.getError('settings.max_attempts.value')

  const handleDecrease = () => {
    settings.setMaxAttempts(settings.maxAttempts - 1)
    errors.removeError('settings.max_attempts')
  }
  const handleIncrease = () => {
    settings.setMaxAttempts(settings.maxAttempts + 1)
    errors.removeError('settings.max_attempts')
  }

  return (
    <div className={classNames(styles.section, styles.maxAttempts)}>
      <ChatbotTextTooltip tooltip='Choose the maximum number of automated messages your Textbot will send before ending the conversation with your fallback action'>
        <span className={styles.maxAttemptsLabel}>Set a message limit</span>
      </ChatbotTextTooltip>
      <div className={styles.maxAttemptsControl}>
        <IconButton
          icon='remove'
          variant='transparent'
          onClick={handleDecrease}
          ariaLabel='PointMaxAttempts_remove'
        />
        <TextFieldPlaceholder
          ref={ref}
          className={styles.maxAttemptsInput}
          size='medium'
          placeholder={settings.maxAttempts}
          error={maxAttemptsError}
          onMouseEnter={() => setErrorVisibility(true)}
          onMouseLeave={() => setErrorVisibility(false)}
        />
        {!!maxAttemptsError && (
          <Tooltip
            className={styles.maxAttemptsErrorTooltip}
            color='red'
            placement='bottom'
            open={errorIsVisible}
            label={maxAttemptsError}
            PopperProps={{
              anchorEl: () => ref.current as HTMLElement,
            }}
          />
        )}
        <IconButton
          icon='add'
          variant='transparent'
          onClick={handleIncrease}
          ariaLabel='PointMaxAttempts_add'
        />
      </div>
    </div>
  )
})
