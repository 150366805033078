import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Icon, ITypographyProps, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export type ITextErrorProps = {
  wrapClassName?: string
  error: ReactNode
  typographyProps?: Partial<ITypographyProps>
  size?: 'small' | 'medium'
}

export const TextError = ({
  wrapClassName = '',
  error,
  typographyProps,
  size = 'small',
}: ITextErrorProps) => {
  if (!error) {
    return null
  }
  return (
    <div className={classNames(styles.error, wrapClassName)}>
      <Icon icon={size === 'small' ? 'alertTriangleSmall' : 'alertTriangle'} fontSize={16} />
      <Typography
        ariaLabel={'error'}
        variant={size === 'small' ? 'text-input-helper' : 'body-sm-regular'}
        {...typographyProps}
        className={classNames(styles.text, typographyProps?.className)}
      >
        {error}
      </Typography>
    </div>
  )
}
