import { makeAutoObservable, reaction, runInAction, type IReactionDisposer } from 'mobx'
import React from 'react'
import { dropDownStore, type IDropdownItem } from 'shared/ui'
import { integrationsStore, IParamsIntegration } from 'entities/Integrations'
import { Integration, IntegrationKey } from 'entities/Integrations/model/Integration'
import { IIntegrationDropdownItem } from 'widgets/IntegrationDropdown/store/types'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import type { MergeField } from 'widgets/MergeField/types/MergeField'
import {
  CustomIntegrationLabel,
  IntegrationLabel,
} from 'widgets/IntegrationDropdown/ui/IntegrationLabel'

export class IntegrationDropdownStore {
  private _selectedIntegrationItem: IIntegrationDropdownItem | null = null
  private _search = ''
  private _loading = true
  private _integrationDropdownMap: Map<number, IIntegrationDropdownItem> = new Map()

  private _dropdownId: string | null = null

  private _phoneFieldDropdownMap: Map<string, IDropdownItem> = new Map()
  private _selectedPhoneField: IDropdownItem | null = null

  private _disposeIntegrationSelectedReaction: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([integrationsStore.fetchIntegrations(), allMergeFieldsStore.loadAllFields()])

    this.mapIntegrationsIntoDropdown()

    this.reactionIntegrationSelected()

    runInAction(() => {
      this._loading = false
    })
  }

  reset = () => {
    this._disposeIntegrationSelectedReaction?.()
  }

  reactionIntegrationSelected = () => {
    this._disposeIntegrationSelectedReaction?.()
    this._disposeIntegrationSelectedReaction = reaction(
      () => this._selectedIntegrationItem?.id,
      () => this.mapPhoneFieldsIntoDropdown()
    )
  }

  mapIntegrationsIntoDropdown = () => {
    integrationsStore.getListOfIntegrations(this._allowedIntegrationsForChoose).forEach((item) => {
      this.addIntegrationItemDropdown(item)
    })
  }

  addIntegrationItemDropdown = (integration: Integration) => {
    const dropdownItem: IIntegrationDropdownItem = {
      id: integration.id,
      label: integration.name,
      iconL: integration.key,
      labelRight: integration.is_connected ? '' : 'Reconnect',
      labelContent: <IntegrationLabel integration={integration} />,
      data: integration,
    }

    this._integrationDropdownMap.set(integration.id, dropdownItem)
  }

  setIntegration = (item: IIntegrationDropdownItem) => {
    this._selectedIntegrationItem = item

    this.hideDropDown()
  }

  setIntegrationSearch = (value: string) => {
    this._search = value
  }

  mapPhoneFieldsIntoDropdown = () => {
    this._phoneFieldDropdownMap = new Map()
    const mergeFieldsIntegrationsMap = allMergeFieldsStore?.mergeFieldsIntegrationsMap
    const selectedIntegration: Integration | null = this.selectedIntegrationItem?.data || null

    if (mergeFieldsIntegrationsMap && selectedIntegration) {
      const integrationMergeFields = mergeFieldsIntegrationsMap.get(selectedIntegration.key)

      if (integrationMergeFields) {
        Array.from(integrationMergeFields.values()).forEach((integrationMergeField) => {
          if (integrationMergeField.name.match(/phone/i)) {
            this.addPhoneFieldItemDropdown(integrationMergeField)
          }
        })
      }
    }
  }

  addPhoneFieldItemDropdown = (phoneField: MergeField) => {
    const dropdownItem: IDropdownItem = {
      id: phoneField.id,
      label: phoneField.label,
      value: phoneField.name,
    }

    this._phoneFieldDropdownMap.set(phoneField.id, dropdownItem)
  }

  setPhoneField = (item: IDropdownItem) => {
    this._selectedPhoneField = item

    this.hideDropDown()
  }

  setDropdownId = (id: string) => {
    this._dropdownId = id
  }

  hideDropDown = () => {
    if (this._dropdownId) {
      dropDownStore.hide(this._dropdownId)
    }
  }

  get itemsIntegrations(): IIntegrationDropdownItem[] {
    const customItem: IIntegrationDropdownItem = {
      id: 'custom',
      label: 'Custom',
      iconL: 'lightning2',
      labelContent: <CustomIntegrationLabel />,
    }
    return [...this.integrations, customItem].filter((item) => {
      return item.label.toLowerCase().includes(this._search.toLowerCase())
    })
  }

  get selectedIntegrationItem() {
    return this._selectedIntegrationItem
  }

  get integrations() {
    return Array.from(this._integrationDropdownMap.values())
  }

  get integrationItemsCount() {
    return this.integrations.length
  }

  get search() {
    return this._search
  }

  get loading() {
    return this._loading
  }

  get phoneFieldsDropdownItems() {
    return Array.from(this._phoneFieldDropdownMap.values())
  }

  get phoneFieldLoading() {
    return this._loading
  }

  get selectedPhoneField() {
    return this._selectedPhoneField
  }

  get _allowedIntegrationsForChoose(): Array<IntegrationKey> {
    return [
      IntegrationKey.activecampaign,
      IntegrationKey.hubspot,
      IntegrationKey.infusionsoft,
      IntegrationKey.salesforce,
    ]
  }

  get params(): IParamsIntegration {
    return {
      integration_id: this._selectedIntegrationItem?.id ?? null,
      service_property: this._selectedPhoneField?.value ?? null,
    }
  }
}
