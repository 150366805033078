import { FC, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Icon } from 'shared/ui'
import { secondsToTime } from 'shared/lib/secondsToTime'
import { AttachmentLoader } from 'shared/ui/Loader'
import { checkCDN } from 'shared/lib/checkCDN'
import { getAriaLabel } from 'shared/lib'
import { attachmentStore } from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import styles from './styles.module.scss'

type Props = {
  attachment?: Attachment
  file?: File
  loading?: boolean
  onCardClick?: () => void
  medium?: boolean
  noLink?: boolean
  hideVideo?: boolean
  fullWidth?: boolean
  thumbnail?: boolean
  onLoad?: () => void
}
export const VideoCard: FC<Props> = observer(
  ({ hideVideo, attachment, onCardClick, medium, fullWidth, thumbnail, file, loading, onLoad }) => {
    const ref = useRef<HTMLVideoElement>(null)

    const [duration, setDuration] = useState('')

    const onLoadedData = () => {
      setDuration(secondsToTime(ref.current?.duration || 0))
      onLoad && onLoad()
    }

    const attachmentVideo: Attachment | boolean | undefined | null =
      attachmentStore.previousAttachmentMap.get(attachment?.source_short || '')

    if (hideVideo && attachmentVideo?.media_preview) {
      return <></>
    }

    return (
      <div
        className={classNames(styles.wrap, {
          [styles.medium]: medium,
          [styles.fullWidth]: fullWidth,
          [styles.thumbnail]: thumbnail,
        })}
        onClick={onCardClick}
        aria-hidden={true}
        aria-label={getAriaLabel('VideoCard')}
      >
        {duration && !loading && !thumbnail && <div className={styles.duration}>{duration}</div>}
        {medium && (
          <div className={styles.play}>
            <Icon icon={'play1'} fontSize={12} />
          </div>
        )}
        <div className={styles.video}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            src={attachment ? attachment?.source : file && URL.createObjectURL(file)}
            ref={ref}
            onLoadedData={onLoadedData}
            {...(checkCDN(attachment?.source) ? { crossOrigin: 'use-credentials' } : null)}
          />
        </div>
        {loading && <AttachmentLoader />}
      </div>
    )
  }
)
