// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zZepUb0AEpMhtH90XyqW{width:100%;margin-top:12px;margin-left:16px}", "",{"version":3,"sources":["webpack://./src/pages/admin/ui/ManagePlan/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".alert {\n    width: 100%;\n    margin-top: 12px;\n    margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "zZepUb0AEpMhtH90XyqW"
};
export default ___CSS_LOADER_EXPORT___;
