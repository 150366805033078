import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { EmptyState, DropdownContent } from 'shared/ui'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { ConversationAssignDropdownStore } from 'widgets/ConversationAssignDropdown/store'
import { InboxMember } from './InboxMember'
import styles from './styles.module.scss'

export type ConversationAssignDropdownContentProps = {
  show?: boolean
  conversationId: number
  isBulk?: boolean
  onCloseMenu?: () => void
  store: ConversationAssignDropdownStore
}

export const ConversationAssignDropdownContent: FC<ConversationAssignDropdownContentProps> =
  observer(({ store, conversationId, onCloseMenu, show, isBulk }) => {
    const {
      search,
      isSearchEmpty,
      onSearch,
      setConversationId,
      filteredList,
      isEmptyFilteredList,
      setIsBulk,
      filteredListCount,
      refresh,
    } = store

    useEffect(() => {
      setConversationId(conversationId)
      setIsBulk(Boolean(isBulk))
    }, [conversationId, isBulk])

    useEffect(() => {
      refresh(conversationId)
    }, [show])

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation()
    }

    return (
      <DropdownContent noPadding>
        <div className={styles.dropdownWrapper} onClick={handleClick}>
          <div className={styles.searchWrapper}>
            <TextField
              size='small'
              variant='integrated'
              InputProps={{
                placeholder: 'Search',
              }}
              value={search}
              onChange={onSearch}
              mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
            />
          </div>
          <div
            className={classnames(styles.membersWrapper, {
              [styles.withScroll]: filteredListCount > 5,
            })}
          >
            {isEmptyFilteredList ? (
              <EmptyState noIcon />
            ) : (
              <>
                {isSearchEmpty && (
                  <InboxMember
                    store={store}
                    member={null}
                    onCloseMenu={onCloseMenu}
                    isBulk={isBulk}
                  />
                )}
                {isSearchEmpty && isBulk && (
                  <InboxMember store={store} member={null} isRoundRobin onCloseMenu={onCloseMenu} />
                )}
                {filteredList.map((item) => (
                  <InboxMember
                    key={item.id}
                    store={store}
                    member={item}
                    onCloseMenu={onCloseMenu}
                    isBulk={isBulk}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </DropdownContent>
    )
  })
