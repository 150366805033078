import { Base } from 'models'
import type {
  IResponseIntegrationPipedriveProperty,
  IResponseIntegrationPipedriveDeal,
  IDeal,
  IResponseIntegrationPipedriveOrganization,
  IResponseIntegrationPipedriveData,
} from 'entities/Integrations'

export class ContactIntegrationPipedriveInfo extends Base {
  contactId: number
  deals: IDeal[]
  properties: IResponseIntegrationPipedriveProperty | null
  organization: IResponseIntegrationPipedriveOrganization | null

  constructor(item: IResponseIntegrationPipedriveData) {
    super()

    this.contactId = item.contactId
    this.deals = item.deal?.map((deal: IResponseIntegrationPipedriveDeal) => {
      return {
        amount: {
          value: deal.value,
        },
        dealname: {
          value: deal.title,
        },
        pipeline: {
          value: deal.pipeline.name,
        },
        dealstage: {
          value: deal.stage.name,
        },
        owner: {
          value: deal.owner.name,
        },
        id: {
          value: null,
        },
      }
    })
    this.properties = item.properties || null
    this.organization = Array.isArray(item.organization) ? null : item.organization
  }
}
