import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Button, Space, showToast } from 'shared/ui'
import { contactsStore } from 'entities/Contacts'
import { conversationStore } from 'entities/Conversation'
import { MessageField } from 'widgets/MessageField'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { NoteCard } from 'widgets/ContactNote'

import styles from './styles.module.scss'

type IContactNotesFormProps = {
  fullView?: boolean
}

export const ContactsNotesForm: FC<IContactNotesFormProps> = observer(({ fullView }) => {
  const store = useContactsDetailsContext()
  const { messageFieldStore, clear, handleUpdate, handleCreate } = store.contactNoteStore
  const contact = contactsStore.getItem(store.selectedContact?.id)

  const handleNoteView = () => {
    clear()
    store.openView('notes')
  }

  const handleSave = () => {
    if (messageFieldStore.editId) {
      handleUpdate(contact)
    } else {
      handleCreate(contact)
    }
  }

  useEffect(() => {
    messageFieldStore?.setSendActionBtnProps({
      onClick: handleSave,
      ariaLabel: 'ContactsNotesForm_save',
    })
  }, [store.selectedContact])

  useEffect(() => {
    if (messageFieldStore.textLimitReached) {
      showToast({
        type: 'error',
        title: messageFieldStore.textLimit.message,
      })
    }
  }, [messageFieldStore.messageInnerText.length])

  useEffect(() => {
    messageFieldStore.clear()
    messageFieldStore.clearWindowSelection()
  }, [fullView, conversationStore.currentItemId])

  if (!contact) return null

  const notes = fullView ? contact.sortedNotes : contact.last3Notes

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.fullView]: fullView,
        [styles.open]: store.isViewTypeNotes,
      })}
    >
      <div className={styles.messageField}>
        <MessageField messageFieldStore={messageFieldStore} />
      </div>

      <div className={styles.notes}>
        {contact.hasNotes &&
          notes.map((note) => (
            <NoteCard note={note} key={note.id} messageFieldStore={messageFieldStore} />
          ))}
        {fullView && <Space height={60} />}
      </div>

      {!fullView && contact.hasMoreNotes && (
        <div className={styles.showMore}>
          <Button size='small' typeBtn='text' text='See all' onClick={handleNoteView} />
        </div>
      )}
    </div>
  )
})
