import { usersStore } from 'entities/Users'
import { organizationStore } from 'entities/Organization'
import { setPosthogSession } from 'entities/Auth/lib/setToken'

export const initPosthog = () => {
  if (!window.posthog) return
  if (!window.Config) return

  const { user } = usersStore
  const { name: organizationName, id: organizationId } = organizationStore

  if (!user) return

  window.posthog.identify(user.id.toString(), {
    userId: user?.id,
    organizationId: organizationId,
  })

  window.posthog.group(String(organizationName), String(organizationId), {})

  if (window.posthog.isFeatureEnabled('sessions_recording_limiter')) {
    window.posthog.startSessionRecording()
  }

  const sessionId = window.posthog?.get_session_id?.()

  if (sessionId) {
    const setPosthogStorage = (sessionId: string) => {
      setPosthogSession(sessionId)
    }
    window.posthog.onSessionId(setPosthogStorage)
    setPosthogStorage(sessionId)
  }
}

export const resetPosthog = () => {
  if (window.posthog) {
    window.posthog.reset(true)
  }
}
