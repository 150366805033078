import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'

import {
  ICustomFieldsResponse,
  INewCustomField,
  IParamsCustomFields,
  IResponseCreateCustomField,
} from './types'

class Api {
  getCustomFields(params?: IParamsCustomFields): Promise<AxiosResponse<ICustomFieldsResponse>> {
    return baseApi.get('custom-fields/fields', { params })
  }

  createCustomField(params: INewCustomField): Promise<AxiosResponse<IResponseCreateCustomField>> {
    return baseApi.post('custom-fields/field', params)
  }
}

export const CustomFieldApi = new Api()
