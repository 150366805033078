import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'
import {
  IParamsCreateConversion,
  IParamsGetConversions,
  IResponseConversion,
  IResponseConversions,
} from 'entities/Conversion/api/types'

class Api {
  getConversions(
    params: IParamsGetConversions,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IResponseConversions>> {
    return baseApi.get('conversions', {
      params,
      ...options,
    })
  }

  getConversionById(id: number): Promise<AxiosResponse<IResponseConversion>> {
    return baseApi.get(`conversions/${id}`)
  }

  createConversion(params: IParamsCreateConversion): Promise<AxiosResponse<IResponseConversion>> {
    return baseApi.post('conversions', params)
  }
}

export const ConversionsApi = new Api()
