export function downloadBlob(blob: Blob, nameProps?: string) {
  const name = nameProps
  const blobUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.download = name || ''
  a.href = blobUrl
  document.body.appendChild(a)
  a.click()
  a.remove()
}
