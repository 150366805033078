import { useState } from 'react'
import { Contact } from 'entities/Contacts/model/Contact'
import { CreateContactErrors, CreateContactHandler } from 'entities/Contacts/errorHandler'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { openConversationByContact } from 'features/OpenConversation'
import { ContactsDetailsStore } from 'widgets/ContactsDetails'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'

export const useContactsDetailsStore = (contactCreateModalStore: ContactCreateModalStore) => {
  const variant = 'create_contact_modal'
  const contact = contactCreateModalStore.contact

  const addNewContactLocallyAfterCreate = (contact: Contact, params: { isNew?: boolean } = {}) => {
    if (contact.isDisabled) {
      CreateContactHandler[CreateContactErrors.trialLimit]()
    } else {
      if (params?.isNew) {
        contactCreateModalStore.handleContactCreate(contact)
        const payload = {
          event_id: 'contact_created_conversations',
          action: `Contact Created '${contact.id}'`,
        }

        eventLogStore.logEvent('Contact_Created_Conversations', payload, {
          groupId: organizationStore.id,
        })
      } else {
        contactCreateModalStore.setContact(contact)
      }

      setTimeout(() => {
        contactCreateModalStore.setIsFirstTimeContactAdded()
      }, 100)
    }
  }

  const [store] = useState(
    () =>
      new ContactsDetailsStore({
        contactCreateModalStore,
        contact,
        profileCardProps: {
          onClickMessage: (contact: Contact) => {
            contactCreateModalStore?.setLoading(true)
            openConversationByContact(contact).then(() => {
              contactCreateModalStore?.closeModal()
              contactCreateModalStore?.emitOpenConversationSignal?.()
            })
          },
          enableCall: true,
          enableChat: true,
        },
        addNewContactLocallyAfterCreate,
        variant,
      })
  )

  return store
}
