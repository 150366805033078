import { makeAutoObservable, runInAction } from 'mobx'
import { callStore, ICallParticipantContact, IResponseCallQueueParticipant } from 'entities/Call'
import { CallContact } from 'entities/Call/model/CallContact'
import { InboxesApi } from 'entities/Inbox'

export class CallPopUpQueueStore {
  private _itemsMap: Map<number, CallContact> = new Map()
  private _loading = false
  private _item: IResponseCallQueueParticipant | null = null

  constructor() {
    makeAutoObservable(this)
  }

  addItem = (item: ICallParticipantContact) => {
    this._itemsMap.set(item.contact.id, new CallContact(item))
  }

  getItem = (id: number) => {
    return this._itemsMap.get(id)
  }

  deleteItem = (id: number) => {
    const item = this.getItem(id)

    if (item) {
      item.clearIncrementTime()
    }

    this._itemsMap.delete(id)
  }

  setItem = (item: IResponseCallQueueParticipant | null) => {
    this._item = item
  }

  handleCallQueue = async () => {
    if (!this._item) return

    try {
      runInAction(() => {
        callStore.setStartedCall(true)
      })

      const teamId = this._item.team.id
      const contact = this.items[0]

      if (contact) {
        await InboxesApi.getTeamsByIdQueueBySid(teamId, contact.sid)
      }
    } catch (e) {
      console.log(e)
    }
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get hasItems() {
    return Boolean(this._itemsMap.size)
  }

  get count() {
    return this.items.length
  }

  get loading() {
    return this._loading
  }

  get item() {
    return this._item
  }

  get hasItem() {
    return Boolean(this._item)
  }
}
