import { observer } from 'mobx-react-lite'
import { DropdownItem, EnumDropdownItemVariant } from 'shared/ui'
import { TagControl, ITagItem } from './TagControl'

interface ITagItemProps {
  tag: ITagItem
  control: TagControl
  onChange: (key: number) => void
}

export const TagItem = observer(({ control, tag, onChange }: ITagItemProps) => (
  <DropdownItem
    item={{
      id: tag.key,
      label: tag.label,
      variant: EnumDropdownItemVariant.Checkbox,
      isCardClick: true,
      activeValue: control.isSelected(tag.key),
    }}
    onChange={() => onChange(tag.key)}
  />
))
