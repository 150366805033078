import { FC } from 'react'
import { Icon } from 'shared/ui'

type IStatusIconProps = {
  isActive: boolean
}

export const StatusIcon: FC<IStatusIconProps> = ({ isActive }) => {
  if (isActive) return <Icon icon={'check2'} />
  return <Icon icon={'alertTriangleYellow'} />
}
