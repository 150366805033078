import { useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import parse from 'html-react-parser'
import classnames from 'classnames'
import { Time, Dropdown, EnumTimeStatus, EnumTimeMode, Typography, Avatar } from 'shared/ui'
import { INote, ContactsApi, contactsStore } from 'entities/Contacts'
import { textToHtml, MessageFieldStore } from 'widgets/MessageField'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import styles from './styles.module.scss'

type INoteCardProps = {
  note: INote
  messageFieldStore: MessageFieldStore
  className?: string
}

export const NoteCard: FC<INoteCardProps> = observer(({ note, className, messageFieldStore }) => {
  const [active, setActive] = useState(false)
  const [fullState, setFullState] = useState(false)

  const store = useContactsDetailsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)

  const hasLongNoteText = note.text.length > 160
  const text = !fullState && hasLongNoteText ? `${note.text.slice(0, 160)}...` : note.text

  const handleEdit = () => {
    messageFieldStore.clear()
    messageFieldStore.setMessageData({
      message: note.text,
      attachments: [],
      isFocus: true,
      isReplace: true,
    })
    messageFieldStore.setEditId(note.id)
  }

  const handleDelete = async () => {
    if (!contact) return

    try {
      messageFieldStore.setDisabledMessageField(true)
      await ContactsApi.deleteContactsByIdNotes(note.id)

      const filteredNotes = contact.notes.filter((item) => item.id !== note.id)

      contact.handleUpdateNotes(filteredNotes)
    } catch (reqError) {
      console.log('LOG: create Note error: ', reqError)
    } finally {
      messageFieldStore.clear()
    }
  }

  const { avatarInfo } = note
  const senderFullName = `${avatarInfo.firstName ?? ''} ${avatarInfo.lastName ?? ''}`

  return (
    <div
      className={classnames(styles.wrap, className, {
        [styles.active]: active,
      })}
    >
      <Avatar size={24} info={avatarInfo || {}} />
      <div className={styles.value}>
        <Typography
          variant={'body-md-medium'}
          color='var(--content-primary-primary)'
          ellipsis
          ariaLabel={'name'}
          width={'180px'}
          tooltipProps={{ label: senderFullName, placement: 'top' }}
        >
          {senderFullName}
        </Typography>

        <Typography variant={'body-md-medium'} ariaLabel={'message'}>
          {parse(textToHtml({ text: text, replaceNewRow: true }))}
        </Typography>

        {hasLongNoteText && (
          <Typography
            variant={'button-small'}
            color={'var(--content-primary-primary)'}
            tag={'div'}
            onClick={() => setFullState(!fullState)}
            ariaLabel={'label'}
          >
            {`See ${fullState ? 'less' : 'more'}`}
          </Typography>
        )}
      </div>
      <div className={styles.actionsWrapper}>
        <div className={styles.actions}>
          <Dropdown
            ariaLabel={'NoteCard'}
            onChangeOpen={setActive}
            icon={'moreHorizontal'}
            placement={'bottom-end'}
            tooltipProps={{
              label: 'Show actions',
              placement: 'top-end',
              disableInteractive: true,
            }}
            iconButtonProps={{
              transparentInactive: true,
              ariaLabel: 'NoteCard_showActions',
            }}
            items={[
              {
                id: 1,
                label: 'Edit',
                iconL: 'edit',
                onClick: () => handleEdit(),
              },
              {
                id: 2,
                label: 'Delete',
                iconL: 'delete',
                onClick: () => handleDelete(),
              },
            ]}
          />
        </div>
        <div className={styles.tag}>
          <Time date={note.updated_at} status={EnumTimeStatus.Read} mode={EnumTimeMode.Live} />
        </div>
      </div>
    </div>
  )
})
