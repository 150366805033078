import React from 'react'
import { capitalize } from 'lodash'
import { IColumn, Status } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { ICustomPrice } from 'entities/Admin'
import { intervalToMonth } from 'pages/admin/pages/organizations/pages/CustomPlan/lib/intervalToMonth'
import { AdminCustomPricesForm } from 'pages/admin/pages/customPrices/models/adminCustomPricesForm'

export const columns: IColumn<ICustomPrice>[] = [
  {
    field: 'id',
    name: 'ID',
    isDisabledChangeVisible: true,
    maxWidth: 85,
  },
  {
    field: 'name',
    name: 'Name',
    isDisabledChangeVisible: true,
    maxWidth: 260,
  },
  {
    field: 'price',
    name: 'Price',
    renderRowCell: (row) => numberFormat({ value: row.price / 100, currency: 'USD' }),
    isDisabledChangeVisible: true,
    maxWidth: 260,
  },
  {
    field: 'usage',
    name: 'Usage',
    renderRowCell: (row) => {
      if (row.status === 'draft') {
        return <Status title={'Draft'} intent={'neutral'} emphasis={'high'} />
      }

      return row.usage
    },
  },
  {
    field: 'term',
    name: 'Contract term',
    renderRowCell: (row) => {
      return capitalize(row.contract_term || row.interval)
    },
  },
  {
    field: 'interval',
    name: 'Billing frequency',
    renderRowCell: (row) => capitalize(row.interval),
  },
  {
    field: 'credits',
    name: 'Annual credits',
    renderRowCell: (row) =>
      numberFormat({ value: Number(row.interval_credits) * (12 / intervalToMonth(row.interval)) }),
  },
  {
    field: 'frequencyCredits',
    name: 'Billing frequency credits',
    renderRowCell: (row) => numberFormat({ value: row.interval_credits }),
  },
  {
    field: 'creditsPrice',
    name: 'Plan credit price',
    renderRowCell: (row) =>
      numberFormat({
        value: Number(row.credit_price),
        currency: 'USD',
        maximumFractionDigits: 3,
        minimumFractionDigits: 3,
      }),
  },
  {
    field: 'additional_credit_price',
    name: 'Additional Credit Price',
    renderRowCell: (row) => `$${row.additional_credit_price}`,
  },
  {
    field: 'percent',
    name: 'Rollover percent',
    renderRowCell: (row) => (row.percent ? `${Number(row.percent) * 100}%` : '-'),
  },

  {
    field: 'type',
    name: 'Rollover Period',
    renderRowCell: (row) =>
      row.type ? AdminCustomPricesForm.getLabelRolloverPeriod(row.type) : '-',
  },
]

export const pinnedColumnNames = ['id', 'name']
