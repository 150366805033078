import { Route, Routes } from 'react-router'
import {
  AgentBookAppointmentAccessLayout,
  AgentBookAppointmentStepsLayout,
  AgentBookAppointmentProviderLayout,
} from 'pages/chatbot/layout'
import { SettingAgent } from 'pages/chatbot/pages/agentBookAppointment'

import { AgentBookAppointmentRoutesSteps } from './type'

export const AgentBookAppointmentStepsRouter = () => {
  return (
    <Routes>
      <Route element={<AgentBookAppointmentProviderLayout />}>
        <Route element={<AgentBookAppointmentAccessLayout />}>
          <Route element={<AgentBookAppointmentStepsLayout />}>
            <Route path={AgentBookAppointmentRoutesSteps.settingAgent} element={<SettingAgent />} />
            <Route path={AgentBookAppointmentRoutesSteps.connectCalendar} element={<></>} />
            <Route path={AgentBookAppointmentRoutesSteps.actionsAgent} element={<></>} />
            <Route path={AgentBookAppointmentRoutesSteps.activateAgent} element={<></>} />
            <Route path='*' element={<div>Page Not Found</div>} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
