import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ContactCard } from 'entities/Contacts'
import { type ContactsGroupCardStore } from 'widgets/ContactsDetails/ui/ContactsGroupCard'
import styles from './styles.module.scss'

type IContactsGroupCardProps = {
  store: ContactsGroupCardStore
}

export const ContactsGroupCard: FC<IContactsGroupCardProps> = observer(({ store }) => {
  return (
    <div className={styles.wrap}>
      {store.contacts.map((contact) => {
        return (
          <ContactCard
            key={contact.id}
            item={contact}
            active={contact.id === store.currentContact?.id}
            onChange={(item) => {
              store.handleActiveContact(item)
            }}
          />
        )
      })}
    </div>
  )
})
