import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Alert, Button, toastStore } from 'shared/ui'
import { WorkflowExecution } from 'entities/Workflow'
import { Contact } from 'entities/Contacts/model/Contact'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { subscribeConversationStateChange } from 'entities/Conversation/events/EventConversationStateChanged'
import { ChatbotExecutionProvider, useChatbotExecutionContext } from './ChatbotExecutionContext'

import styles from './styles.module.scss'

interface IConversationChatbotAlertProps {
  contact?: Contact
  execution: WorkflowExecution
  terminate: (executionId: string) => Promise<void>
}

const ConversationChatbotAlert = observer(
  ({ contact, execution, terminate }: IConversationChatbotAlertProps) => {
    const [terminating, setTerminating] = useState(false)

    const contactName = contact?.fullName || (contact?.formatted_number ?? 'user')
    const workflowName = execution.workflowName

    const handleClick = () => {
      setTerminating(true)
      terminate(execution.id).then(() => setTerminating(false))
    }

    return (
      <Alert
        item={{
          type: 'infoLight',
          desc: `${workflowName} is currently chatting with ${contactName}`,
        }}
        rightAction={
          <Button
            size='small'
            typeBtn='outlined'
            contained='secondary'
            text='Claim conversation'
            className={styles.alertButton}
            disabled={terminating}
            onClick={handleClick}
          />
        }
      />
    )
  }
)

const ConversationChatbotAlertsBody = observer(
  ({ conversation }: IConversationChatbotAlertsProps) => {
    const { executions, dispose, syncLatestExecutions, terminateExecution } =
      useChatbotExecutionContext()

    const conversationId = conversation?.id
    const conversationLocked = !!conversation?.is_locked
    const conversationContact = conversation?.contact

    useEffect(
      () =>
        subscribeConversationStateChange((lockState) => {
          if (lockState.id === conversationId && !lockState.locked)
            toastStore.add({
              type: 'info',
              title: 'Conversation claimed',
              desc: 'You can now send messages manually',
            })
        }),
      [conversationId]
    )

    const handleTerminate = (executionId: string) =>
      terminateExecution(executionId).then((isEmpty) => {
        if (isEmpty && !!conversationId) syncLatestExecutions(conversationId)
      })

    useEffect(() => dispose(), [conversationId])

    useEffect(() => {
      if (conversationId == null) return dispose()

      if (conversationLocked) syncLatestExecutions(conversationId)
      else dispose()
    }, [conversationId, conversationLocked])

    if (conversation == null) return null

    return (
      <>
        {executions.map((execution) => (
          <ConversationChatbotAlert
            key={execution.id}
            execution={execution}
            contact={conversationContact}
            terminate={(executionId) => handleTerminate(executionId)}
          />
        ))}
      </>
    )
  }
)

export interface IConversationChatbotAlertsProps {
  conversation?: Conversation
}

export const ConversationChatbotAlerts = (props: IConversationChatbotAlertsProps) => (
  <ChatbotExecutionProvider>
    <ConversationChatbotAlertsBody {...props} />
  </ChatbotExecutionProvider>
)
