import React, { FC, ReactNode, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { uiStore } from 'shared/store/uiStore'
import { navLinkVueStore } from 'shared/ui/NavLinkVue/store/navLinkVueStore'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

type INavLinkProps = {
  to: string
  originalPath?: string
  children: ReactNode
  withoutStyles?: boolean
}

export const NavLinkVue: FC<INavLinkProps> = observer((props) => {
  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()

    uiStore.changeRoute({
      path: props.to,
      type: 'vue',
    })

    if (!uiStore.routeBlocker) {
      navLinkVueStore.changeRoute(props.to)
    }
  }

  useEffect(() => {
    navLinkVueStore.changeRoute(window.location.pathname)
  }, [])

  const className = props.withoutStyles
    ? ''
    : classnames(styles.button, {
        [styles.active]: navLinkVueStore.path.includes(props.originalPath || props.to),
      })

  return (
    <a
      className={className}
      href={props.to}
      onClick={onClick}
      aria-label={getAriaLabel('NavLinkVue')}
    >
      {props.children}
    </a>
  )
})
