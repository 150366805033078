import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader, Button } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { User } from 'entities/Users/model/User'
import { UserDropdownCard } from 'entities/Users'
import {
  SearchDropdownAssigneeStore,
  SearchTypesEnum,
  useSearchDropdownContext,
} from 'widgets/SearchDropdown'
import styles from './styles.module.scss'

type AssigneeSearchDropdownContentProps = {
  store: SearchDropdownAssigneeStore
  onCloseMenu?: () => void
  onSelect?: (item: User) => void
}

export const AssigneeDropdownContentSearch: FC<AssigneeSearchDropdownContentProps> = observer(
  ({ onCloseMenu, store, onSelect }) => {
    const { setIsShowMore, selectType, search, actionSearch, hasAssigneeType } =
      useSearchDropdownContext()
    const { selectTypeItem, options } = store

    const handleSelectTag = (item: User) => {
      actionSearch({
        value: '',
        exclude: 'assignee',
      })
      options?.handleSearch?.('')

      if (!hasAssigneeType) selectType(SearchTypesEnum.members)

      selectTypeItem(item)
      onSelect?.(item)
      onCloseMenu?.()
    }

    const handleShowAll = () => {
      actionSearch({
        value: '',
        exclude: 'assignee',
      })
      options?.handleSearch?.('')
      selectType(SearchTypesEnum.members)
      setIsShowMore(true)
    }

    return (
      <div className={styles.wrap} aria-label={getAriaLabel('searchAssigneesSelector')}>
        <p
          className={styles.searchTitle}
          aria-label={getAriaLabel('searchAssigneesHeaderSelector')}
        >{`Assignees (${store.total})`}</p>

        {store.loading && (
          <div className={styles.spinner}>
            <SpinnerLoader size={24} />
          </div>
        )}
        {!store.loading && store.hasItems && (
          <div className={styles.items}>
            {store.first3Items.map((item) => (
              <UserDropdownCard
                key={item.id}
                item={item}
                highlighter={search}
                onChange={handleSelectTag}
                ariaLabel='searchAssigneeItem'
              />
            ))}
          </div>
        )}

        {!store.loading && !store.hasItems && (
          <div className={styles.notFound}>No results found</div>
        )}
        {!hasAssigneeType && store.hasItems && (
          <div className={styles.showAll}>
            <Button
              disabled={store.loading}
              text={'Show all'}
              typeBtn={'informative'}
              onClick={handleShowAll}
            />
          </div>
        )}
      </div>
    )
  }
)
