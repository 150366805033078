import { observer } from 'mobx-react-lite'
import { Tabs, Typography } from 'shared/ui'

import styles from './styles.module.scss'
import { AutoBusinessFind } from './AutoBusinessFind'
import { ManuallyBusinessFind } from './ManuallyBusinessFind'
import { useComplianceSettingsContext } from '../context/complianceSettingsContext'

export const GeneralInfo = observer(() => {
  const { generalInfoActiveTab, changeGeneralInfoActiveTab } = useComplianceSettingsContext()

  return (
    <>
      <Typography
        variant='body-md-regular'
        ariaLabel='Business_info_line'
        color='var(--content-primary-tertiary)'
      >
        Make sure this information is an exact match to your official paperwork
      </Typography>

      <div className={styles.generalInfoTabsContainer}>
        <Tabs
          tabs={[
            { name: 'Find your business', key: 1 },
            { name: 'Enter manually', key: 2 },
          ]}
          activeTabKey={generalInfoActiveTab}
          handleSelectTab={(tab) => changeGeneralInfoActiveTab(tab.key)}
          margin={'0 0 24px 0'}
        />
        {generalInfoActiveTab === 1 && <AutoBusinessFind />}
        {generalInfoActiveTab === 2 && <ManuallyBusinessFind />}
      </div>
    </>
  )
})
