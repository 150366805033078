import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Icon, Typography } from 'shared/ui'
import {
  TextField,
  makeTextFieldActionTooltip,
  makeTextFieldAutoFocus,
  makeTextFieldBlurOnEnter,
  makeTextFieldTooltip,
  makeTextFieldLoading,
} from 'shared/ui/TextField'
import styles from './styles.module.scss'

interface IPhoneInputProps {
  value: string
  errorText?: string | null
  onBlurField: (value: string) => void
  onChangeValue: (value: string) => void
  isLoading: boolean
  modalView?: boolean
}

export const PhoneInput = ({
  value,
  errorText,
  onBlurField,
  onChangeValue,
  isLoading,
  modalView,
}: IPhoneInputProps) => {
  const [isError, setIsError] = useState<string | null>(null)

  useEffect(() => {
    setIsError(errorText || null)
  }, [errorText])

  const checkOnBlur = (value: string) => {
    if (!value.length) {
      setIsError('Phone number is required')
    } else {
      onBlurField(value)
    }
  }

  const checkOnChangeValue = (value: string) => {
    setIsError(null)
    onChangeValue(value)
  }

  return (
    <div className={styles.field}>
      <span className={styles.label}>
        <div className={styles.icon}>
          <Icon icon='phone' />
        </div>
        <Typography className={styles.name} variant='body-rg-regular' tag='div' ariaLabel={'Phone'}>
          Phone
        </Typography>
      </span>
      <TextField
        className={classnames(styles.value, {
          [styles.modalView]: modalView,
        })}
        size='small'
        variant='outline'
        value={value}
        onBlur={checkOnBlur}
        onChange={checkOnChangeValue}
        InputProps={{ placeholder: 'Enter number' }}
        rightActions={[makeTextFieldLoading({ loading: isLoading })]}
        mainActions={[
          makeTextFieldActionTooltip({
            color: 'red',
            placement: 'left',
            active: !!isError,
            message: isError ?? undefined,
            onClick: () => setIsError(''),
          }),
          makeTextFieldTooltip(),
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldBlurOnEnter(),
        ]}
      />
    </div>
  )
}
