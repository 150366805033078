import { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { MediaLibraryStore } from 'widgets/MediaLibrary/store/mediaLibraryStore'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import styles from './styles.module.scss'

type MediaLibraryButtonProps = {
  onClick: () => void
}

export const MediaLibraryButton: FC<MediaLibraryButtonProps> = observer(({ onClick }) => {
  const [store] = useState(() => new MediaLibraryStore())
  const messageFieldStore = useMessageFieldContext()

  if (!featureFlagsStore.media_library_v2) return null

  return (
    <Button
      text='View all'
      typeBtn='text'
      size='small'
      className={styles.accessLibraryButton}
      onClick={() => {
        store.handleOpenMediaLibraryModal(messageFieldStore)
        onClick()
      }}
    />
  )
})
