import { makeAutoObservable } from 'mobx'
import type { IResponseIntegrationHubspotCompany } from 'entities/Integrations'
import type { IIntegrationsCompany, IIntegrationsProperty } from 'entities/Integrations/model/types'
import { IntegrationProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationProperty'

export class IntegrationCompanyProperty {
  private _id: number | null = null
  private _name: string | null = null
  private _propertiesMap: Map<string, IntegrationProperty> = new Map()

  constructor(item: IIntegrationsCompany) {
    const id = Number(item.id.value)

    this._id = !isNaN(id) ? id : 0
    this._name = item.name.value || ''
    this.addProperties(item)

    makeAutoObservable(this)
  }

  get id() {
    return this._id || 0
  }

  get title() {
    return this._name || ''
  }

  get properties() {
    return Array.from(this._propertiesMap.values())
  }

  get propertiesKeys() {
    return this.properties.map((item) => item.key)
  }

  addProperties = (item: IIntegrationsCompany) => {
    Object.entries(item).forEach(([k, item]) => {
      const key = k as keyof IIntegrationsCompany

      if (key === 'name') return
      if (key === 'id') return

      this.addProperty({
        key: key,
        label: item.label || '',
        value: item.value ? String(item.value) : '',
      })
    })
  }

  addProperty = (item: IIntegrationsProperty) => {
    this._propertiesMap.set(item.key, new IntegrationProperty(item))
  }

  getProperty = (key: keyof IResponseIntegrationHubspotCompany) => {
    return this._propertiesMap.get(key)
  }

  removeProperty = (key: string) => {
    this._propertiesMap.delete(key)
  }
}
