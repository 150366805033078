import { createContext, useContext } from 'react'
import type { MediaLibraryStore } from 'widgets/MediaLibrary'

export const MediaLibraryContext = createContext<MediaLibraryStore | null>(null)

export function useMediaLibraryContext() {
  const context = useContext(MediaLibraryContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with MediaLibraryStoreProvider')
  }
  return context
}
