import * as React from 'react'
import classNames from 'classnames'
import { isEmail } from 'shared/lib'
import styles from './styles.module.scss'

export type ITooltipEmailDomainProps = {
  email: string
}

const TooltipEmailDomain: React.FC<ITooltipEmailDomainProps> = ({ email }) => {
  if (!isEmail(email))
    return (
      <div className={styles.wrap}>
        <div className={classNames(styles.label)}>{email}</div>
      </div>
    )

  const [name, domain] = email.split('@')

  return (
    <div className={styles.wrap}>
      <div className={classNames(styles.label)}>
        <span>{name}@</span>
        <a
          className={classNames(styles.domain)}
          href={`http://www.${domain}`}
          target='_blank'
          rel='noreferrer'
        >
          {domain}
        </a>
      </div>
    </div>
  )
}

export { TooltipEmailDomain }
