import { ZIndex } from 'shared/constants/zIndex'
import type { IRadioBoxProps } from 'shared/ui/Radio'

export type IPaymentMethodTypes = 'primary' | 'backup'

export const getPaymentMethodOptions = (zIndex?: ZIndex): IRadioBoxProps<IPaymentMethodTypes>[] => [
  {
    id: 'primary',
    value: 'primary',
    text: 'Set as Primary',
    tooltip: {
      TooltipProps: {
        placement: 'top',
        label: 'Use this card as your main payment method for all transactions',
        width: 238,
        zIndex: zIndex,
      },
    },
  },
  {
    id: 'backup',
    value: 'backup',
    text: 'Set as Backup',
    tooltip: {
      TooltipProps: {
        placement: 'top',
        label: 'Use this card if the primary one fails',
        width: 238,
        zIndex: zIndex,
      },
    },
  },
]
