import React, { useState } from 'react'
import { Dropdown, DropdownContent, DropdownGroup, IconButton, IDropdownItem } from 'shared/ui'
import { IResponseCallConferenceMessageParticipant } from 'entities/Message'
import { Message } from 'entities/Message/model/Message'
import styles from './styles.module.scss'

type ICallConferenceDropDownProps = {
  message: Message
}

export const ParticipantsDropDown: React.FC<ICallConferenceDropDownProps> = ({ message }) => {
  const [show, setShow] = useState(false)
  const { callConference, isOutbound } = message
  const participants: IResponseCallConferenceMessageParticipant[] = callConference
    ? callConference.participants
    : []
  const items: IDropdownItem[] = participants.map(
    (participant: IResponseCallConferenceMessageParticipant) => {
      const [key] = participant.label.split(':')

      const item: IDropdownItem = {
        id: participant.callSid,
        label: participant.name,
      }

      if (key === 'inbox_id') {
        item.iconL = 'inboxOpen'
      }

      if (key === 'contact_id' || key === 'number') {
        item.avatarProps = {
          size: 16,
          info: {
            firstName: participant.name,
            color: 'var(--yellow-70)',
          },
        }
      }

      return item
    }
  )

  return (
    <div className={styles.wrap}>
      <Dropdown
        ariaLabel={'ParticipantsDropDown'}
        show={show}
        triggerComponent={(open) => (
          <IconButton
            variant={'icon'}
            color={'secondary'}
            iconProps={{
              icon: 'contacts',
              fontSize: 20,
              padding: 6,
            }}
            tooltipProps={{
              label: open ? 'Hide participants' : 'Show participants',
            }}
            onClick={() => {
              setShow(!show)
            }}
            ariaLabel='ParticipantsDropDown_open'
          />
        )}
        customComponent={() => (
          <DropdownContent>
            <DropdownGroup
              title={'Participants'}
              items={items}
              stylesWrap={{
                padding: '2px',
              }}
            />
          </DropdownContent>
        )}
        placement={isOutbound ? 'top-end' : 'top-start'}
        onChangeOpen={(status) => {
          setShow(status)
        }}
        width={167}
      />
    </div>
  )
}
