import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Icon, Typography, Space } from 'shared/ui'
import { TextField, makeTextFieldClear, makeTextFieldIcon } from 'shared/ui/TextField'
import { integrationLink, IIntegrationLinkTypes, integrationsStore } from 'entities/Integrations'
import { contactsStore } from 'entities/Contacts'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import { Company } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/AllView/AllCompanies/Company'
import styles from '../styles.module.scss'

export const AllCompanies = observer(() => {
  const [search, setSearch] = useState('')
  const store = useContactsIntegrationsContext()
  const contact = contactsStore.getItem(store.selectedContact?.id)
  const integrationKey = store.viewIntegrationKey

  if (!integrationKey || !contact) return null

  const integration = integrationsStore.getIntegration(integrationKey)
  const linkProps = {
    key: integrationKey,
    contact: contact,
    integration: integration,
    type: IIntegrationLinkTypes.company,
  }

  const link = (id: number | null | undefined) => {
    return integrationLink({
      ...linkProps,
      id: id,
    })
  }
  const companies = store.viewAllData.companies.filter(
    (company) => company.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
  )

  const notFound = companies.length === 0

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.search}>
        <TextField
          size='small'
          variant='integrated'
          InputProps={{
            placeholder: 'Search',
          }}
          value={search}
          onChange={setSearch}
          leftActions={[makeTextFieldIcon({ icon: 'search' })]}
          rightActions={[makeTextFieldClear()]}
        />
      </div>
      <div className={styles.list}>
        {companies.map((company) => (
          <Company
            key={company.id}
            company={company}
            link={link(company.id)}
            contact={contact}
            integrationKey={integrationKey}
          />
        ))}
      </div>
      {notFound ? (
        <div className={styles.epmtyList}>
          <Icon icon='search' fontSize={24} />
          <Typography variant={'body-rg-regular'} ariaLabel={'NoResultsFound'}>
            No results found
          </Typography>
        </div>
      ) : (
        <Space height={60} />
      )}
    </div>
  )
})
