import { makeAutoObservable } from 'mobx'
import { ContactsApi } from 'entities/Contacts'
import { ContactsUnlinkedTableStore, ContactsDuplicateTableStore } from 'widgets/ContactsTable'

type TabTypes = 'unlink' | 'duplicate' | string

class ContactsCleanupStore {
  activeTab: TabTypes = 'duplicate'
  contactsUnlinkedTable: ContactsUnlinkedTableStore = new ContactsUnlinkedTableStore()
  contactsDuplicateTable: ContactsDuplicateTableStore = new ContactsDuplicateTableStore()
  changeToUnlinked = false
  duplicate_count = 0
  unlinked_count = 0

  constructor() {
    makeAutoObservable(this)
  }

  get isChangeToUnlinked() {
    return this.changeToUnlinked
  }

  get hasCleanupItems() {
    return !this.contactsDuplicateTable.noResults || !this.contactsUnlinkedTable.noResults
  }

  get cleanupTitle() {
    const getPlural = (value: number) => (value > 1 ? 'contacts' : 'contact')
    const duplicatesLength = this.duplicateCount
    const unlinkedLength = this.unlinkedCount
    const noDuplicates = this.contactsDuplicateTable.noResults
    const noLinked = this.contactsUnlinkedTable.noResults

    if (!noDuplicates && noLinked) {
      return `We found ${duplicatesLength} duplicate ${getPlural(duplicatesLength)}`
    }
    if (noDuplicates && !noLinked) {
      return `We found ${unlinkedLength} unlinked ${getPlural(unlinkedLength)}`
    }

    return `We found  ${duplicatesLength} duplicate and ${unlinkedLength} unlinked contacts`
  }

  get isExistOnlyUnlinked() {
    return this.contactsDuplicateTable.noResults && !this.contactsUnlinkedTable.noResults
  }

  get duplicateCount() {
    return this.duplicate_count
  }

  get unlinkedCount() {
    return this.unlinked_count
  }

  subtractDuplicateCount = () => {
    this.duplicate_count -= 1
  }

  loadDuplicateCount = async () => {
    const { data } = await ContactsApi.getContactsDuplicateCount()

    this.duplicate_count = data.duplicate_count
    this.unlinked_count = data.unlinked_count
  }

  init = () => {
    this.contactsDuplicateTable.init()
    this.contactsUnlinkedTable.init()
    this.loadDuplicateCount()
  }

  handleOpen = () => {
    if (!this.contactsDuplicateTable.noResults) {
      this.activeTab === 'duplicate'
      return
    }
  }

  handleActiveTab = (tab: TabTypes) => {
    this.activeTab = tab
  }

  setIsChangeToUnlinked = (value: boolean) => {
    this.changeToUnlinked = value
  }

  resetPaginationPage = () => {
    this.contactsDuplicateTable.resetPaginationPage()
    this.contactsUnlinkedTable.resetPaginationPage()
  }
}

export const contactsCleanupStore = new ContactsCleanupStore()
