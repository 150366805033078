import { useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown, DropdownContent } from 'shared/ui'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { filtersStore } from 'features/DropdownFilter'
import {
  ButtonClose,
  ButtonMarkAsUnread,
  ButtonMakePriority,
  ButtonOptInStatus,
  ButtonMarkAsPending,
} from 'widgets/ConversationCard'
import { ConversationAssignDropdownContent } from 'widgets/ConversationAssignDropdown'
import { useConversationListContext } from 'widgets/ConversationList/context'

type PropsDropDown = {
  conversation: Conversation
  onChangeOpen?: (isOpen: boolean) => void
}

export const ConversationDropdown: FC<PropsDropDown> = observer(
  ({ conversation, onChangeOpen }) => {
    const store = useConversationListContext()
    const [showAssignDropdown, setShowAssignDropdown] = useState(false)

    const onCloseAssignDropdown = () => setShowAssignDropdown(false)

    return (
      <Dropdown
        ariaLabel={'ConversationDropdown'}
        width={filtersStore.isPending ? 179 : 224}
        icon={'moreHorizontal'}
        iconButtonProps={{
          variant: 'contained',
          color: 'tertiary2',
          transparentInactive: true,
          ariaLabel: 'ConversationDropdown_trigger',
        }}
        placement={'bottom-start'}
        tooltipProps={{
          label: 'Show actions',
          placement: 'top-end',
        }}
        onChangeOpen={onChangeOpen}
        customComponent={(onCloseMenu, dropdownId) => (
          <DropdownContent>
            <ButtonClose
              conversation={conversation}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
            <ButtonMarkAsUnread
              conversation={conversation}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
            {!conversation.isGroup && (
              <Dropdown
                ariaLabel={'ConversationDropdownGroup'}
                show={showAssignDropdown}
                onChangeOpen={setShowAssignDropdown}
                parentsDropdownId={[dropdownId]}
                width={192}
                margin={-4}
                placement={'right-start'}
                triggerComponent={() => (
                  <Button
                    fontWeight={400}
                    typeBtn='menuItem'
                    icon='userLine'
                    text='Assign'
                    iconRightProps={{
                      icon: 'chevronRight',
                    }}
                    fullWidth
                    onMouseEnter={() => setShowAssignDropdown(true)}
                  />
                )}
                customComponent={(onCloseMenuChild) => (
                  <ConversationAssignDropdownContent
                    store={store.conversationAssignDropdownStore}
                    conversationId={conversation.id}
                    show={showAssignDropdown}
                    onCloseMenu={() => {
                      onCloseMenuChild()
                      onCloseMenu()
                    }}
                  />
                )}
              />
            )}
            <ButtonMakePriority
              conversation={conversation}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
            <ButtonOptInStatus
              contactsIds={[conversation.contact_id]}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
            <ButtonMarkAsPending
              conversation={conversation}
              clickCallback={onCloseMenu}
              onMouseEnter={onCloseAssignDropdown}
            />
          </DropdownContent>
        )}
      />
    )
  }
)
