import { makeAutoObservable } from 'mobx'
import { ITabItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { conversationStore } from 'entities/Conversation'
import { eventLogStore } from 'entities/EventLog'
import { organizationStore } from 'entities/Organization'
import { ITabTypes } from 'widgets/ConversationLayout/store/types'
import { ConversationSearchStore } from 'widgets/ConversationHeaderSearch/store'

export class ConversationLayoutStore {
  constructor(private _conversationSearchStore: ConversationSearchStore) {
    makeAutoObservable(this)
  }

  get activeTab(): ITabTypes {
    if (uiStore.pathName.includes('calls')) {
      return 'calls'
    }

    return 'chats'
  }

  get isShowChats() {
    return this.activeTab === 'chats'
  }
  get isShowCalls() {
    return this.activeTab === 'calls'
  }

  navigateToCalls = () => {
    if (uiStore.navigate) {
      uiStore.navigate('/conversations/calls')
    }
  }

  showCalls = () => {
    if (this._conversationSearchStore.isOpened) {
      this._conversationSearchStore.onClose()
      this._conversationSearchStore.clearFilters()
    }
    const payload = {
      event_id: 'call_history_used',
    }

    eventLogStore.logEvent('Call History Used', payload, { groupId: organizationStore.id })

    this.navigateToCalls()
  }
  showChats = () => {
    if (uiStore.navigate) {
      this._conversationSearchStore.reset()
      uiStore.navigate('/conversations')
      conversationStore.setCurrentItemId(null)
    }
  }

  selectTab = (tab: ITabItem) => {
    if (tab.key === 'calls') {
      this.showCalls()
    } else {
      this.showChats()
    }
  }
}
