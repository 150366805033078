import * as Sentry from '@sentry/react'
import { User } from '@sentry/types'
import {
  APP_ENV,
  SENTRY_DSN,
  SENTRY_PROFILES_SAMPLE_RATE,
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  SENTRY_TRACES_SAMPLE_RATE,
} from 'shared/config'

class SentryStore {
  init = () => {
    if (this.isNotSetup) return

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: this.environment,

      tracePropagationTargets: ['int/v5/core'],

      ...this.optionsSampleRate,

      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,

          networkDetailAllowUrls: ['salesmessage.com'],
          networkRequestHeaders: ['Cache-Control'],
          networkResponseHeaders: ['Referrer-Policy'],
        }),
        Sentry.replayCanvasIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
      ],
    })

    this.startReplaySession()
  }

  get optionsSampleRate() {
    return {
      replaysSessionSampleRate: Number(SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || 1.0,
      replaysOnErrorSampleRate: Number(SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) || 1.0,
      tracesSampleRate: Number(SENTRY_TRACES_SAMPLE_RATE) || 0.1,
      profilesSampleRate: Number(SENTRY_PROFILES_SAMPLE_RATE) || 0.1,
    }
  }

  get isNotSetup() {
    return !SENTRY_DSN && !APP_ENV
  }

  get environment() {
    return APP_ENV
  }

  startReplaySession = () => {
    Sentry.getReplay()?.start()
  }

  stopReplaySession = () => {
    Sentry.getReplay()?.stop()
  }

  setUser = (user: User) => {
    if (this.isNotSetup) return

    Sentry.setUser(user)
  }
}

const sentryStore = new SentryStore()

export const initSentry = sentryStore.init
export const setUserSentry = sentryStore.setUser
