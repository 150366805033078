// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C_G2T_6u_olqGlR45eyg{width:100%;display:flex;justify-content:space-between}.qX3GUXyJqAbXUpgWOJnb{margin-left:auto}.PvSIdziUM1xxd02vGdT1{margin-right:8px}.tXnRZZ3Bxlhdf5m7FMKA{height:264px;margin:16px 0;background-size:contain;border-radius:6px}", "",{"version":3,"sources":["webpack://./src/widgets/SelectContactsModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,YAAA,CACA,6BAAA,CAEJ,sBACI,gBAAA,CAEJ,sBACI,gBAAA,CAEJ,sBACI,YAAA,CACA,aAAA,CACA,uBAAA,CACA,iBAAA","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n.rightContent {\n    margin-left: auto;\n}\n.cancelButton {\n    margin-right: 8px;\n}\n.validateContentImg {\n    height: 264px;\n    margin: 16px 0;\n    background-size: contain;\n    border-radius: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "C_G2T_6u_olqGlR45eyg",
	"rightContent": "qX3GUXyJqAbXUpgWOJnb",
	"cancelButton": "PvSIdziUM1xxd02vGdT1",
	"validateContentImg": "tXnRZZ3Bxlhdf5m7FMKA"
};
export default ___CSS_LOADER_EXPORT___;
