import { observer } from 'mobx-react-lite'
import { Icon, SpinnerLoader, Typography } from 'shared/ui'
import { savedRepliesStore } from 'widgets/SavedReplies'
import { SavedReplySelectedItem } from 'widgets/SavedReplies/ui/SavedRepliesContent/RightContent/SavedReplySelectedItem/SavedReplySelectedItem'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import styles from './styles.module.scss'

export const RightContent = observer(() => {
  const { selectedSavedReply, loading } = savedRepliesStore

  if (allMergeFieldsStore.loading) {
    return (
      <div className={styles.empty}>
        <SpinnerLoader size={24} />
      </div>
    )
  }

  return selectedSavedReply ? (
    <SavedReplySelectedItem />
  ) : (
    <div className={styles.empty}>
      {loading ? (
        <SpinnerLoader size={24} />
      ) : (
        <div className={styles.emptyTitle}>
          <Icon icon={'savedReplies'} fontSize={24} color={'var(--content-primary-tertiary)'} />
          <Typography
            variant={'body-md-medium'}
            color={'var(--content-primary-tertiary)'}
            ariaLabel={'The preview'}
          >
            The preview will show up here
          </Typography>
        </div>
      )}
    </div>
  )
})
