import React from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Typography } from 'shared/ui'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import styles from './styles.module.scss'

export const MessageCounter = observer(() => {
  const messageFieldStore = useMessageFieldContext()
  return (
    <Typography
      className={classNames(styles.counter)}
      variant={'text-input-helper'}
      tag={'div'}
      ariaLabel={'message'}
    >
      <span>{messageFieldStore.messageRemainsInfo.messageCount}</span>
      <span>/</span>
      <span>{messageFieldStore.messageRemainsInfo.messageRemains}</span>
    </Typography>
  )
})
