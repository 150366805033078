import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { FullPageWrapper } from 'shared/ui'
import { BroadcastDetailHeader } from 'widgets/BroadcastDetail/BroadcastDetailHeader/BroadcastDetailHeader'
import { useBroadcastDetailOneTimeContext } from 'widgets/BroadcastDetail'
import { BroadcastContactStatistics } from 'widgets/BroadcastDetail/BroadcastContactStatistics/BroadcastContactStatistics'
import { BroadcastContactStatisticsSearch } from 'widgets/BroadcastDetail/BroadcastDetailOneTime/ui/BroadcastContactStatisticsSearch/BroadcastContactStatisticsSearch'
import { BroadcastContactStatisticsTable } from 'widgets/BroadcastDetail/BroadcastDetailOneTime/ui/BroadcastContactStatisticsTable/BroadcastContactStatisticsTable'
import { ContactsDetails } from 'widgets/ContactsDetails'

export const Content = observer(() => {
  const store = useBroadcastDetailOneTimeContext()

  useEffect(() => {
    store.init()

    return () => {
      store.reset()
    }
  }, [])

  return (
    <FullPageWrapper>
      <BroadcastDetailHeader store={store} />
      <BroadcastContactStatistics
        filters={store.filters}
        onActiveFilter={store.onActiveFilter}
        filter={store.filter}
      />
      <BroadcastContactStatisticsSearch />
      <BroadcastContactStatisticsTable />
      {store.contactsDetailsStore && <ContactsDetails store={store.contactsDetailsStore} />}
    </FullPageWrapper>
  )
})
