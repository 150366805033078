import { makeAutoObservable } from 'mobx'
import { Contact } from 'entities/Contacts/model/Contact'

export class CallSearchContactsStore {
  contact: Contact | null = null
  search = ''
  showDialpad = false
  loading = false

  constructor() {
    makeAutoObservable(this)
  }

  handleToggleShowDialpad = () => {
    this.showDialpad = !this.showDialpad
  }

  handleAddContact = (contact: Contact) => {
    this.contact = contact
    this.search = contact.national_number || ''
  }

  handleSearch = (value: string) => {
    this.contact = null
    this.search = value
  }

  handleDialpadSearch = (value: string | number) => {
    this.contact = null
    this.search = this.search + String(value)
  }

  handleChangeLoader = (status: boolean) => {
    this.loading = status
  }
}
