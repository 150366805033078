import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { useState } from 'react'
import { Divider } from '@mui/material'
import { Button, Typography, Tooltip } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'
import { Warning } from 'shared/ui/Warning'
import { RegisterStore } from 'widgets/Register'
import { CrmEnum } from 'widgets/Register/ui/Step2/store/type'
import { MAX_CRM_LENGTH } from 'widgets/Register/ui/Step2/store/step2Store'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterStore
  onClose: () => void
}

export const DropdownCrmContent = observer(({ store, onClose }: IFormProps) => {
  const {
    step2Store: { crm, setCrm, crms },
  } = store

  const [customCrmView, setCustomCrmView] = useState(crm?.id === CrmEnum.other)
  const [customCrm, setCustomCrm] = useState(crm?.id === CrmEnum.other ? crm.name : '')

  const handleCustomCrm = (value: string) => {
    if (value.length > MAX_CRM_LENGTH) return
    setCustomCrm(value)
  }

  const handleBack = () => setCustomCrmView(false)
  const handleConfirm = () => {
    setCrm({ id: CrmEnum.other, name: customCrm, icon: 'lightning2' })
    onClose()
  }

  if (customCrmView) {
    return (
      <div className={styles.wrap}>
        <Button
          className={styles.backButton}
          icon={'chevronLeft'}
          typeBtn='menuItem'
          type='button'
          onClick={handleBack}
          text={'Other'}
        />
        <Divider className={styles.divider} />
        <TextField
          className={classNames(styles.textField, styles.dropdown)}
          variant='integrated'
          size='medium'
          InputProps={{ placeholder: 'Enter CRM name' }}
          onChange={handleCustomCrm}
          value={customCrm}
          mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
        />
        <div className={styles.dropdownFooter}>
          <Tooltip label={`Limit: ${MAX_CRM_LENGTH} characters`} placement='top'>
            <>
              {customCrm.length !== MAX_CRM_LENGTH ? (
                <Typography
                  variant={'text-input-field-r'}
                  ariaLabel={getAriaLabel('customCrmLength')}
                >{`${customCrm.length} of ${MAX_CRM_LENGTH}`}</Typography>
              ) : (
                <Warning
                  text={
                    customCrm.length === MAX_CRM_LENGTH
                      ? `${MAX_CRM_LENGTH}-character limit reached`
                      : ''
                  }
                />
              )}
            </>
          </Tooltip>
          <Button
            className={styles.confirmButton}
            typeBtn='contained'
            type='button'
            onClick={handleConfirm}
            disabled={!customCrm.length || !(customCrm.length <= MAX_CRM_LENGTH)}
            text={'Confirm'}
            contained={'primary'}
            fullWidth
          />
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(styles.wrap, styles.list)}>
      {crms.map((item) => (
        <Button
          key={item.id}
          className={styles.selectItem}
          typeBtn='menuItem'
          type='button'
          icon={item.icon}
          onClick={() => {
            if (item.id !== CrmEnum.other) {
              setCrm(item)
              onClose()
              return
            }
            setCustomCrmView(true)
          }}
          iconRightProps={
            item.id === crm?.id
              ? {
                  color: 'var(--content-brand-primary)',
                  className: styles.selectCrmIcon,
                  icon: 'check1',
                }
              : undefined
          }
          text={item.name}
          fullWidth
        />
      ))}
    </div>
  )
})

export default DropdownCrmContent
