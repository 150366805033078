import { observer } from 'mobx-react-lite'

import styles from './styles.module.scss'
import { ChatbotTestNewButton } from '../ChatbotButtons'

export const AgentPlaceholder = observer(() => (
  <div className={styles.dialog}>
    <header className={styles.dialogHeader}>
      <ChatbotTestNewButton />
    </header>
  </div>
))
