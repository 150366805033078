import React, { useEffect, useRef, useState, UIEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { useMediaLibraryContext } from 'widgets/MediaLibrary/context/mediaLibraryContext'
import { MediaLibraryCard } from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryCard/MediaLibraryCard'
import styles from './styles.module.scss'

export const MediaLibraryGrid = observer(() => {
  const mediaLibraryStore = useMediaLibraryContext()
  const {
    activeTabAttachments,
    loadNextPage,
    isLoadingMore,
    isAttachmentInitListLoading,
    hasMore,
    isSingleSelectMode,
    toggleAttachmentSelection,
    onSingleModeSelect,
  } = mediaLibraryStore
  const listRef = useRef<HTMLDivElement | null>(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = (event: UIEvent) => {
    const target = event.target as HTMLDivElement
    const scrollHeight = target.scrollHeight
    const scrollTop = target.scrollTop
    const offsetHeight = target.offsetHeight

    if (isLoadingMore || isAttachmentInitListLoading) return
    if (!hasMore) return
    if (scrollHeight <= scrollTop + offsetHeight + 200) {
      if (listRef.current) {
        setScrollPosition(listRef.current.scrollTop + 200)
      }
      loadNextPage()
    }
  }

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = scrollPosition
    }
  }, [activeTabAttachments])

  const handleCardClick = (id: string | number) => {
    isSingleSelectMode ? onSingleModeSelect(id) : toggleAttachmentSelection(id)
  }

  return (
    <div className={styles.gridView} ref={listRef} onScroll={handleScroll}>
      {activeTabAttachments.map((attachment) => (
        <MediaLibraryCard
          key={attachment.id}
          attachment={attachment}
          attachments={activeTabAttachments}
          isLoading={attachment.loading}
          isSelected={mediaLibraryStore.isSelected(attachment.id)}
          handleCardClick={() => handleCardClick(attachment.id)}
        />
      ))}
      {isLoadingMore && (
        <div className={styles.spinnerMoreContainer}>
          <SpinnerLoader size={20} />
        </div>
      )}
    </div>
  )
})
