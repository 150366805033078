import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import React from 'react'
import { Typography } from 'shared/ui'
import { MonthDaysSelectorStore } from 'shared/ui/Schedule'
import styles from './styles.module.scss'

type IEachContentProps = {
  store: MonthDaysSelectorStore
}

export const EachContent = observer(({ store }: IEachContentProps) => {
  const { daysItems, onToggleDay } = store
  return (
    <div className={styles.wrapDays}>
      {daysItems.map((item) => (
        <button
          key={item.id}
          className={classNames(styles.day, { [styles.active]: item.activeValue })}
          onClick={() => onToggleDay(item.id as number)}
        >
          <Typography variant={'body-md-regular'} ariaLabel={'label'}>
            {item.label}
          </Typography>
        </button>
      ))}
    </div>
  )
})
