import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import { useEffect, MouseEvent } from 'react'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { Portal, getAriaLabel } from 'shared/lib'
import { ModalContent } from 'shared/ui/Modal/ModalContent'
import styles from './styles.module.scss'

export const Modal = observer(() => {
  const { pathname } = useLocation()
  const { isHoverModal, hideModalIdsSet, disabledOverlayClose } = modalStore
  const onOverlayClick = (e: MouseEvent<HTMLDivElement>, id: string) => {
    if (disabledOverlayClose(id)) return

    if (!isHoverModal) {
      e.stopPropagation()
      modalStore.closeModal(id)
    }
  }
  useEffect(() => {
    modalStore.closeAllModals()
  }, [pathname])

  return (
    <>
      {modalStore.modals.map((modal) => {
        const ariaLabelRoot = 'Modal'

        return (
          <Portal key={modal.id} zIndex={modal.zIndex}>
            <div
              className={styles.wrap}
              onClick={(e) => onOverlayClick(e, modal.id)}
              aria-label={getAriaLabel(ariaLabelRoot, String(modal.title || modal.ariaLabel))}
            >
              <ModalContent modal={modal} hide={hideModalIdsSet.has(modal.id)} />
            </div>
          </Portal>
        )
      })}
    </>
  )
})
