import { makeAutoObservable, reaction } from 'mobx'
import { IInboxCombine, inboxesStore } from 'entities/Inbox'

class SidebarStore {
  inboxesMap: Map<number, IInboxCombine> = new Map()
  reactionInboxes: ReturnType<typeof reaction> | null = null

  constructor() {
    makeAutoObservable(this)
  }

  reset() {
    if (this.reactionInboxes) this.reactionInboxes()

    this.inboxesMap.clear()
  }

  initInboxes = () => {
    inboxesStore.inboxesList.forEach((item) => {
      this.inboxesMap.set(item.id, item)
    })
  }

  reInitInboxes = () => {
    inboxesStore.reFetchInboxes()
    this.initInboxes()
  }

  get unread() {
    return Array.from(this.inboxesMap.values())
      .map((item) => item.unread)
      .filter((item) => item)
      .reduce((state, value) => state + value, 0)
  }
  get unreadMessagesCount() {
    return Array.from(this.inboxesMap.values())
      .map((item) => item.unread_messages_count)
      .filter((item) => item)
      .reduce((state, value) => state + value, 0)
  }

  handleReactionInboxes = () => {
    if (this.reactionInboxes) this.reactionInboxes()

    this.reactionInboxes = reaction(
      () => inboxesStore.inboxesList.length,
      () => {
        this.initInboxes()
      },
      {
        fireImmediately: true,
      }
    )
  }
}

export const sidebarStore = new SidebarStore()
