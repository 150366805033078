import { ContactsTagsDropdown } from 'widgets/ContactsTagsModal'
import styles from './styles.module.scss'

export const ApplyTagsModalContent = () => {
  return (
    <>
      <div className={styles.tagBlock}>
        <div className={styles.label}>Tags (Optional)</div>
        <ContactsTagsDropdown />
      </div>
    </>
  )
}
