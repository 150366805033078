// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xyqH5bbKFwUJrB50h83l{z-index:11;position:fixed;width:100%;height:100%;background-color:var(--black-30)}", "",{"version":3,"sources":["webpack://./src/shared/ui/ContentOverlay/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,gCAAA","sourcesContent":[".contentOverlay {\n    z-index: 11;\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background-color: var(--black-30);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentOverlay": "xyqH5bbKFwUJrB50h83l"
};
export default ___CSS_LOADER_EXPORT___;
