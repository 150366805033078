import { BillingInterval } from 'entities/Admin/customPrices/api/type'

const INTERVAL_TO_MONTH = {
  annual: 12,
  ['semi-annual']: 6,
  quarterly: 3,
  monthly: 1,
}

export function intervalToMonth(interval: BillingInterval) {
  return INTERVAL_TO_MONTH[interval]
}
