import { makeAutoObservable } from 'mobx'
import { IDropdownItem, ITabItem } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { getNumberWithOrdinal } from 'shared/lib'
import {
  dayOfWeekItemsMap,
  IDayOfWeek,
  IMonthDaySelectorType,
  IMonthOrdinal,
  monthOrdinalItemsMap,
} from 'shared/ui/Schedule'

export type IMonthDaysSelectorStoreProps = {
  days: number[]
  onTheOrdinal?: IMonthOrdinal | null
  onTheDayOfWeek?: IDayOfWeek | null
  type?: IMonthDaySelectorType
}

export class MonthDaysSelectorStore {
  constructor(props?: IMonthDaysSelectorStoreProps) {
    if (props) {
      this.init(props)
    }
    makeAutoObservable(this)
  }
  currentDay = uiStore.dayjs().get('date')
  type: IMonthDaySelectorType = 'each'

  onTheOrdinal: IMonthOrdinal = 'first'
  onTheDayOfWeek: IDayOfWeek = 'sunday'

  daysMap: Map<number, boolean> = new Map(
    Array(31)
      .fill(0)
      .map((_, index) => [index + 1, index + 1 === this.currentDay])
  )

  get text() {
    if (this.type === 'each') {
      return `on the ${this.days.map(getNumberWithOrdinal).join(', ')}`
    }
    if (this.type === 'onThe') {
      return `on the ${monthOrdinalItemsMap.get(this.onTheOrdinal)?.short_label} ${
        dayOfWeekItemsMap.get(this.onTheDayOfWeek)?.short_label
      }`
    }
    return ''
  }

  get props(): IMonthDaysSelectorStoreProps {
    if (this.type === 'each') {
      return {
        type: this.type,
        days: this.days,
        onTheOrdinal: null,
        onTheDayOfWeek: null,
      }
    }
    return {
      type: this.type,
      days: [],
      onTheOrdinal: this.onTheOrdinal,
      onTheDayOfWeek: this.onTheDayOfWeek,
    }
  }
  get daysItems(): IDropdownItem[] {
    return Array.from(this.daysMap.entries()).map(([key, value]) => ({
      id: key,
      label: String(key),
      activeValue: value,
    }))
  }

  get selectedDaysItems() {
    return this.daysItems.filter((item) => item.activeValue)
  }

  get days() {
    return this.selectedDaysItems.map((item) => item.id as number)
  }

  init = ({ days = [], onTheOrdinal, onTheDayOfWeek, type }: IMonthDaysSelectorStoreProps) => {
    this.daysMap.forEach((_, key) => {
      this.daysMap.set(key, days.includes(key))
    })
    if (onTheOrdinal) {
      this.onTheOrdinal = onTheOrdinal
    }
    if (onTheDayOfWeek) {
      this.onTheDayOfWeek = onTheDayOfWeek
    }
    if (type) {
      this.type = type
    }
  }

  onToggleDay = (day: number) => {
    const prevValue = this.daysMap.get(day)
    if (prevValue && this.selectedDaysItems.length === 1) return
    this.daysMap.set(day, !prevValue)
  }

  handleSelectTab = (tab: ITabItem<IMonthDaySelectorType>) => {
    this.type = tab.key
  }

  onSelectOnTheOrdinal = (value: IMonthOrdinal) => {
    this.onTheOrdinal = value
  }
  onSelectOnTheDayOfWeek = (value: IDayOfWeek) => {
    this.onTheDayOfWeek = value
  }
}
