import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation/store'
import type { IResponseConversation } from 'entities/Conversation/api/types'

const ConversationContactOptedIn = (data: IResponseConversation) => {
  conversationStore.updateItem(data)
}

export const useEventConversationContactOptedIn = () => {
  useEffect(() => {
    if (websocket.isConnected) {
      websocket.on(WebsocketEvent.ConversationContactOptedIn, ConversationContactOptedIn)
    }
  }, [websocket.isConnected])
}
