import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Tag } from 'entities/Tags/model/Tag'
import { TagCard } from 'entities/Tags'

import styles from './styles.module.scss'

interface IActionListProps {
  name: string
  placeholder: string
  futureTags?: Promise<Tag[]>
}

export const ActionTags = observer(({ name, placeholder, futureTags }: IActionListProps) => {
  const [tags, setTags] = useState<Tag[]>([])
  const [tagsReady, setTagsReady] = useState(false)

  useEffect(() => {
    if (!futureTags) return setTagsReady(true)

    futureTags.then((tags) => {
      setTags(tags)
      setTagsReady(true)
    })
  }, [])

  return (
    <div className={styles.actionCardRow}>
      <div className={styles.actionCardName}>{name}</div>
      {tagsReady &&
        (!!tags.length ? (
          <div className={styles.actionCardTags}>
            {tags.map((tag) => (
              <TagCard key={tag.id} tag={tag} />
            ))}
          </div>
        ) : (
          <div className={styles.actionCardValue}>
            <span className={styles.actionCardPlaceholder}>{placeholder}</span>
          </div>
        ))}
    </div>
  )
})
