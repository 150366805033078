import { IIconsVector } from 'shared/ui'

export type IMainDropdownContentItem = {
  id: number
  label: string
  type: SearchTypesEnum
  icon: IIconsVector
}

export type IDateDropdownContentItem = {
  id: number
  label: string
  type: SearchDateEnum
}

export enum SearchTypesEnum {
  tags = 'tags',
  contacts = 'contacts',
  members = 'members',
  teams = 'teams',
  main = 'main',
  date = 'date',
  segment = 'segment',
}

export enum SearchDateEnum {
  today = 'today',
  last30days = 'last30days',
  last90days = 'last90days',
  yesterday = 'yesterday',
  custom = 'custom',
}
