import React from 'react'
import { observer } from 'mobx-react-lite'
import { IMenuItemProps, Menu, PageLayout } from 'shared/ui'
import { BroadcastRoutesEnum, broadcastStore } from 'entities/Broadcast'

const items: IMenuItemProps[] = [
  {
    type: 'nav',
    to: BroadcastRoutesEnum.all,
    label: 'All broadcasts',
    icon: 'broadcasts',
  },
  {
    type: 'nav',
    to: `${BroadcastRoutesEnum.oneTime}`,
    label: 'One time',
    icon: 'arrowRightBold',
  },
  {
    type: 'nav',
    to: BroadcastRoutesEnum.recurring,
    label: 'Recurring',
    icon: 'repeatBold',
  },
]

export const BroadcastLayout = observer(() => {
  return (
    <PageLayout
      leftContent={<Menu title='Broadcasts' menuItems={items} />}
      store={broadcastStore.pageLayoutStore}
    />
  )
})
