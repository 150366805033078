import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

export const RecentListTitle = () => (
  <div className={styles.wrap}>
    <Typography variant={'body-md-regular'} ariaLabel={'RecentlySent'} color={'tertiary'}>
      Recently sent
    </Typography>
  </div>
)
