import { Loader } from './Loader'
import { ITextFieldBaseAsideAction } from '../../types'

type ITextFieldLoading = {
  loading?: boolean
}

export const makeTextFieldLoading: (action: ITextFieldLoading) => ITextFieldBaseAsideAction = (
  action
) => {
  const useAction: ITextFieldBaseAsideAction = () => <Loader show={!!action.loading} />

  return useAction
}
