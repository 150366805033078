import { makeAutoObservable } from 'mobx'
import type {
  IIntegrationsOrganization,
  IIntegrationsProperty,
} from 'entities/Integrations/model/types'
import { IntegrationProperty } from 'entities/Integrations/model'

export class IntegrationOrganizationProperty {
  private _propertiesMap: Map<string, IntegrationProperty> = new Map()

  constructor(items: IIntegrationsProperty<keyof IIntegrationsOrganization>[]) {
    this.addProperties(items)

    makeAutoObservable(this)
  }

  get title() {
    return this.getProperty('organization_name')?.value || ''
  }

  addProperties = (items: IIntegrationsProperty<keyof IIntegrationsOrganization>[]) => {
    items.forEach((item) => {
      if (item.key === 'organization_name') return

      this.addProperty(item)
    })
  }

  addProperty = (item: IIntegrationsProperty<keyof IIntegrationsOrganization>) => {
    this._propertiesMap.set(item.key, new IntegrationProperty(item))
  }

  getProperty = (key: keyof IIntegrationsOrganization) => {
    return this._propertiesMap.get(key)
  }

  removeProperty = (key: string) => {
    this._propertiesMap.delete(key)
  }

  get properties() {
    const removeKeys = ['organization_name']

    return Array.from(this._propertiesMap.values()).filter((item) => {
      return !removeKeys.includes(item.key)
    })
  }

  get propertiesKeys() {
    return this.properties.map((item) => item.key)
  }
}
