// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hIuLv9AYcYOBZHWBfVN3{display:flex;gap:2px;align-items:center}.h4kjDQyPGZJgKU7lQXAa{width:3px;height:3px;background:var(--content-neutral-primary);border-radius:50%;opacity:0;animation:vlaKxNqXmijUVwrLrgJ8 1s infinite}.h4kjDQyPGZJgKU7lQXAa:nth-child(1){animation-delay:0s}.h4kjDQyPGZJgKU7lQXAa:nth-child(2){animation-delay:.2s}.h4kjDQyPGZJgKU7lQXAa:nth-child(3){animation-delay:.4s}@keyframes vlaKxNqXmijUVwrLrgJ8{0%{opacity:.2}50%{opacity:1}100%{opacity:.2}}", "",{"version":3,"sources":["webpack://./src/shared/ui/TypingIndicator/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CAGF,sBACE,SAAA,CACA,UAAA,CACA,yCAAA,CACA,iBAAA,CACA,SAAA,CACA,0CAAA,CAGF,mCACE,kBAAA,CAGF,mCACE,mBAAA,CAGF,mCACE,mBAAA,CAGF,gCACE,GACE,UAAA,CAEF,IACE,SAAA,CAEF,KACE,UAAA,CAAA","sourcesContent":[".typing {\n  display: flex;\n  gap: 2px;\n  align-items: center;\n}\n\n.typing__dot {\n  width: 3px;\n  height: 3px;\n  background: var(--content-neutral-primary);\n  border-radius: 50%;\n  opacity: 0;\n  animation: loadingFade 1s infinite;\n}\n\n.typing__dot:nth-child(1) {\n  animation-delay: 0s;\n}\n\n.typing__dot:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.typing__dot:nth-child(3) {\n  animation-delay: 0.4s;\n}\n\n@keyframes loadingFade {\n  0% {\n    opacity: 0.2;\n  }\n  50% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0.2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typing": "hIuLv9AYcYOBZHWBfVN3",
	"typing__dot": "h4kjDQyPGZJgKU7lQXAa",
	"loadingFade": "vlaKxNqXmijUVwrLrgJ8"
};
export default ___CSS_LOADER_EXPORT___;
