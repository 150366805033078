import { Typography } from 'shared/ui'

import styles from './styles.module.scss'

interface IChatbotLabelProps {
  label: string
  className?: string
}

export const ChatbotLabel = ({ label, className }: IChatbotLabelProps) => (
  <div className={className}>
    <Typography className={styles.label} variant={'body-xs-regular'} ariaLabel={'label'}>
      {label}
    </Typography>
  </div>
)
