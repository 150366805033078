import { useRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Dropdown, DropdownContent, IconButton } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { tooltipText } from 'shared/constants/tooltipText'
import { callStore } from 'entities/Call'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { DeviceSettingsContent } from 'entities/DeviceSettings'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { remindersStore } from 'widgets/Reminders'
import { useContactsProfileCardContext } from 'widgets/ContactsDetails/ui/ContactsProfileCard/context'
import styles from './styles.module.scss'

export const CallButtonAction = observer(() => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const store = useContactsProfileCardContext()
  const {
    conversation,
    disabledNewConversationCall,
    isVariantCreateContactModal,
    handleConversationCall,
    creatingConversation,
    onClickCall,
    isMultipleContacts,
    contactCreateModalStore,
    contact,
    inbox,
  } = store

  const inteliquentCalling = featureFlagsStore.inteliquent_calling
  const user = usersStore.user
  const isAirCallInbox = Boolean(inbox?.isAircall)
  const isCallViaAirCall = Boolean(inbox?.isCallViaAircall && featureFlagsStore.call_via_aircall)
  const isOutOfMoney = remindersStore.creditsStore.isOutOfMoney

  const isCallOutboundDisabled = isAirCallInbox ? false : !inbox?.isNumberOutboundCalls
  const isPFTInbox = Boolean(inbox?.isPFTInbox)
  const isUserViewOnly = Boolean(user?.isViewOnlyRole)
  const isContactBlocked = contact?.is_blocked === true

  const isCallDisabled =
    isUserViewOnly ||
    isContactBlocked ||
    isMultipleContacts ||
    disabledNewConversationCall ||
    callStore.loading ||
    !callStore.isStatusClosed ||
    (isPFTInbox && !inteliquentCalling) ||
    (isOutOfMoney && !isAirCallInbox)

  const isCallButtonDisabled = isVariantCreateContactModal
    ? disabledNewConversationCall
    : isCallDisabled || isCallOutboundDisabled

  const isCallDropdownShow = isVariantCreateContactModal
    ? !callStore.disabled
    : !callStore.disabled && !inbox?.isAircall && !isCallViaAirCall

  const titleContent = (
    <div className={styles.wrap}>
      Call
      {isCallDropdownShow && (
        <>
          <span className={styles.divider} />
          <Dropdown
            ariaLabel={'ContactCallButton'}
            show={isDropdownOpen}
            PopperProps={{
              anchorEl: buttonRef.current,
              onClick: (event) => {
                event.stopPropagation()
                event.preventDefault()
              },
            }}
            triggerComponent={(isExpanded) => (
              <IconButton
                className={styles.icon}
                tag='span'
                variant={'icon'}
                color={'transparent'}
                size={'origin_icon'}
                onClick={() => setDropdownOpen(!isExpanded)}
                iconProps={{
                  icon: isExpanded ? 'chevronUp' : 'chevronDown',
                  fontSize: 14,
                }}
                ariaLabel='ContactCallButton_setDropdownOpen'
              />
            )}
            placement={'bottom-end'}
            width={246}
            margin={8}
            customComponent={(_, dropdownId) => (
              <DropdownContent>
                <DeviceSettingsContent
                  parentsDropdownId={[dropdownId]}
                  deviceSettingsStore={callStore.deviceSettingsStore}
                  testOutputDevice={callStore.testOutputDevice}
                />
              </DropdownContent>
            )}
            onToggle={(value) => {
              setDropdownOpen(value)
            }}
          />
        </>
      )}
    </div>
  )

  const getTooltipLabel = () => {
    if (isPFTInbox && !inteliquentCalling)
      return tooltipText.disabledCall(inboxesStore.nonPFTInboxNumberType)
    if (isOutOfMoney && !isAirCallInbox) return 'Your organization is out of message credits'
    if (isMultipleContacts) return 'Calling is disabled for group chats'
    if (isAirCallInbox || isCallViaAirCall) return 'Copy contact number'
    if (!isCallDisabled && isCallOutboundDisabled && !isVariantCreateContactModal) {
      if (user?.isAdminRole || user?.isOwnerRole || inbox?.ownerId === user?.id) {
        const inboxId = conversation?.inbox_id

        return (
          <>
            Outbound calling is disabled
            <br />
            <button
              onClick={(event) => {
                event.preventDefault()

                uiStore.changeRoute({
                  path: `/settings/organization/inboxes/all-inboxes/${inboxId}/calling`,
                  type: 'vue',
                })
              }}
            >
              Enable it here
            </button>
          </>
        )
      }

      return 'Outbound calling is disabled. Contact your admin to enable it.'
    }

    return ''
  }

  const handleCallClick = async () => {
    if (isVariantCreateContactModal && contactCreateModalStore?.callModalStore) {
      const value =
        contactCreateModalStore.contact?.formatted_number ||
        contactCreateModalStore.contact?.national_number ||
        ''
      contactCreateModalStore.callModalStore.openModal()
      contactCreateModalStore.callModalStore.handleChangeValue(
        value,
        contactCreateModalStore.contact
      )
      contactCreateModalStore.closeModal()

      return
    }

    handleConversationCall()
  }

  useEffect(() => {
    if (isDropdownOpen) callStore.deviceSettingsStore.syncDevicesStream()

    return () => callStore.deviceSettingsStore.removeStream()
  }, [isDropdownOpen])

  return (
    <Button
      ref={buttonRef}
      size='medium'
      typeBtn='contained'
      contained='secondary'
      icon='phone'
      aria-label={'ContactCallButton'}
      disabled={isCallButtonDisabled}
      tooltipProps={{
        placement: 'bottom-start',
        label: getTooltipLabel(),
      }}
      loading={creatingConversation}
      titleContent={titleContent}
      onClick={handleCallClick}
    />
  )
})
