import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { inboxesStore } from 'entities/Inbox'
import { conversationStore } from 'entities/Conversation'
import { tenDlcLimitsStore } from 'entities/TenDlc/store/TenDlcLimits'

export const AlertMaxSegments = observer(() => {
  const user = usersStore.user
  const inbox = inboxesStore.getItem(conversationStore.currentItem?.inbox_id)

  const isAircall = inbox?.type === 'inbox' && !!inbox?.isAircall
  const isTollFree = conversationStore?.conversationInboxNumber?.isTollFree

  if (isTollFree || !tenDlcLimitsStore.isOutOfSegments) return null

  const isRoleMember = !!user?.isMemberRole
  const isRoleAdmin = !!(user?.isOwnerRole ?? user?.isAdminRole ?? user?.isManagerRole)

  const isActionHelp = isRoleMember && !isTollFree
  const isActionSettings = isRoleAdmin && !isAircall

  const title = isRoleAdmin
    ? tenDlcLimitsStore.isPlanStarter
      ? 'You’re required to register for 10DLC compliance.'
      : 'You have exceeded the limits of your current 10DLC package.'
    : tenDlcLimitsStore.isPlanStarter
    ? 'Your company is required to register for 10DLC compliance.'
    : 'You have exceeded the limits of your current 10DLC package.'

  const description = isRoleAdmin
    ? tenDlcLimitsStore.isPlanStarter
      ? 'To avoid being blocked and paying carrier penalty fees, and message filtering'
      : 'Upgrade to avoid non-compliance fees and message filtering'
    : tenDlcLimitsStore.isPlanStarter
    ? 'To avoid being blocked and paying carrier penalty fees, alert your admin'
    : 'To avoid being blocked and paying carrier penalty fees, alert your admin'

  const action =
    (isActionHelp && (
      <Button
        tag='a'
        text={'Learn more'}
        typeBtn={'outlined'}
        size={'small'}
        contained={'secondary'}
        target='_blank'
        rel='noreferrer'
        href='https://help.salesmessage.com/en/articles/5205228-register-for-a2p-10dlc'
      />
    )) ||
    (isActionSettings && (
      <Button
        text={'Register here'}
        typeBtn={'outlined'}
        size={'small'}
        contained={'secondary'}
        onClick={() =>
          uiStore.changeRoute({ path: '/settings/organization/compliance', type: 'vue' })
        }
      />
    ))

  return <Alert item={{ title, desc: description, type: 'error' }} action={action} />
})
