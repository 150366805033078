import { dataLogStore } from 'entities/DataLog/store/dataLogStore'

const SAMPLE_RATE = 60000

export const eventHeartbeat = () => {
  dataLogStore.setItem({
    logLevel: 'info',
    message: 'Heartbeat',
  })

  setTimeout(eventHeartbeat, SAMPLE_RATE)
}
