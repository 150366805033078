// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".N14CrN50ci36CxIMJ4bS{color:var(--content-primary-disabled)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Table/EmptyCell/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA","sourcesContent":[".text{\n  color: var(--content-primary-disabled);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "N14CrN50ci36CxIMJ4bS"
};
export default ___CSS_LOADER_EXPORT___;
