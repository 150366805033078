import { observer } from 'mobx-react-lite'
import { Navigate } from 'react-router'
import { useEffect } from 'react'
import { PageLayout } from 'shared/layout'
import { IMenuItemProps, Menu, Typography } from 'shared/ui'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { integrationsStore } from 'entities/Integrations'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'
import { chatbotLayoutStore } from 'pages/chatbot'

import styles from './styles.module.scss'
import { ChatbotRoute } from '../route/type'

const menuItems: IMenuItemProps[] = [
  {
    type: 'nav',
    icon: 'chatBot',
    to: ChatbotRoute.all,
    label: (
      <Typography variant={'body-md-regular'} className={styles.label} ariaLabel={'All Textbots'}>
        All Textbots <span className={styles.badge}>Beta</span>
      </Typography>
    ),
  },
  {
    type: 'nav',
    icon: 'bulb',
    to: ChatbotRoute.knowledgeBase,
    label: (
      <Typography
        variant={'body-md-regular'}
        className={styles.label}
        ariaLabel={'AI knowledge base'}
      >
        AI knowledge base <span className={styles.badge}>Beta</span>
      </Typography>
    ),
  },
]

export const ChatbotLayout = observer(() => {
  const { chatbot, rag } = featureFlagsStore

  if (!chatbot) return <Navigate to={'/'} />

  const filteredMenuItems = menuItems.filter((item) => {
    if ('to' in item && item?.to === ChatbotRoute.knowledgeBase && !rag) {
      return false
    }
    return true
  })

  useEffect(() => {
    Promise.all([integrationsStore.fetchIntegrations(), allMergeFieldsStore.loadAllFields()])
  }, [])

  return (
    <PageLayout
      leftContent={<Menu title='AI Textbot' menuItems={filteredMenuItems} />}
      store={chatbotLayoutStore}
    />
  )
})
