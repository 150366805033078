import { makeAutoObservable, runInAction } from 'mobx'
import { uiStore } from 'shared/store/uiStore'
import { logger } from 'shared/lib'
import type { Conversation } from 'entities/Conversation/model/Conversation'
import type { Contact } from 'entities/Contacts/model/Contact'
import { callStore } from 'entities/Call'
import type { Inbox } from 'entities/Inbox/model/Inbox'
import { inboxesStore } from 'entities/Inbox'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { usersStore } from 'entities/Users'
import { integrationsStore } from 'entities/Integrations'
import type { IContactsProfileCardStoreConfig } from 'widgets/ContactsDetails/ui/ContactsProfileCard'
import type { ContactsDetailsStore, IContactsDetailsVariant } from 'widgets/ContactsDetails'

export class ContactsProfileCardStore {
  private _conversationId: number | null = null
  private _contactId: number | null = null
  private _inboxId: number | null = null
  private _contactsIds: number[] = []
  private _creatingConversation = false
  private _variant: IContactsDetailsVariant = 'conversation'
  private _config: IContactsProfileCardStoreConfig | null = null
  private _loading = false

  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)
  }

  setConfig = (config: IContactsProfileCardStoreConfig) => {
    this._contactId = typeof config.contactId === 'number' ? config.contactId : null
    this._contactsIds = config.contactsIds || []
    this._variant = config.variant || 'conversation'
    this._config = config
  }

  setConversation = (item: Conversation | null) => {
    this._conversationId = item ? item.id : null
  }

  setInboxId = (id: number | null) => {
    this._inboxId = id
  }

  setContactId = (id: number) => {
    this._contactId = id
  }

  setContactsIds = (ids: number[]) => {
    this._contactsIds = ids
  }

  init = async () => {
    runInAction(() => {
      this._loading = true
    })

    await Promise.all([usersStore.initPromise, integrationsStore.fetchIntegrations()])

    runInAction(() => {
      this._loading = false
    })
  }

  handleConversationCall = async () => {
    if (this._creatingConversation) return

    if (this.conversation && !this.conversation.isNew) {
      try {
        await callStore.connectTwilio(this.conversation.id)
      } catch (error) {
        logger.error(error)
      } finally {
        this._config?.onClickCall?.()
      }

      return
    }

    if (!this._contactId || !this._inboxId) return

    this._creatingConversation = true

    try {
      const conversation = await conversationStore.createConversation({
        contact_id: this._contactId,
        team_id: this._inboxId,
      })

      if (conversationStore.isNewConversation)
        await inboxesStore.handleUpdateTeamInbox(this._inboxId)

      if (conversation) {
        if (conversationStore.isNewConversation) {
          uiStore.changeRoute({
            path: `/conversations/${conversation.id}`,
          })
        }

        this._contactsDetailsStore.setConversationId(conversation.id)

        this._config?.onClickCall?.()
        await callStore.connectTwilio(conversation.id)
      }
    } catch (error) {
      logger.error(error)
    } finally {
      runInAction(() => {
        this._creatingConversation = false
      })
    }
  }

  onClickMessage = () => {
    if (!this.contact) return

    this._config?.onClickMessage?.(this.contact)
  }

  get loading() {
    return this._loading
  }

  get config() {
    return this._config
  }

  get inbox() {
    if (this.isVariantCreateContactModal) {
      if (inboxesStore.currentInbox?.type !== 'inbox') return null

      return inboxesStore.currentInbox
    }

    return inboxesStore.getItem(this._inboxId) as Inbox | null
  }

  get isMultipleContacts() {
    return this._contactsIds.length > 1
  }

  get isVariantCreateContactModal() {
    return this._variant === 'create_contact_modal'
  }

  get isVariantContacts() {
    return this._variant === 'contacts'
  }

  get disabledNewConversationCall(): boolean {
    if (!this.conversation?.isNew) return false

    if (this.isVariantCreateContactModal) {
      return !this.contact || this.contact.isNew
    }

    return !this.contact || this.contact.isNew || !this._inboxId
  }

  get creatingConversation() {
    return this._creatingConversation
  }

  get contact() {
    if (this._contactId === null) return

    return contactsStore.getItem(this._contactId)
  }

  get conversation() {
    if (this._conversationId === null) return

    return conversationStore.getItem(this._conversationId)
  }

  get contacts() {
    return this._contactsIds.reduce<Contact[]>((state, id) => {
      const contact = contactsStore.getItem(id)

      if (contact) state.push(contact)

      return state
    }, [])
  }

  get contactsAvatarInfo() {
    return this.contacts.map((item) => item.avatarInfo)
  }

  get currentContactsAvatarInfo() {
    if (this.contact) {
      return [this.contact.avatarInfo]
    }

    return this.contactsAvatarInfo
  }

  get hasContact() {
    return Boolean(this.contact)
  }

  get hasParticipants() {
    if (this.contact) return false

    return this._contactsIds.length > 1
  }

  get name() {
    return this.contact?.name || ''
  }

  get formattedNumber() {
    return this.contact?.formatted_number
  }

  get number() {
    return this.contact?.number
  }

  get isNameEqualsPhone() {
    return this.name === this.number
  }

  get timezone() {
    return this.contact?.timezone
  }

  get areaCodeInfo() {
    return this.contact?.area_code_info?.time_zone
  }

  get contactsCount() {
    return this._contactsIds?.length || 0
  }

  get openView() {
    return this._config?.openView
  }

  get enableChat() {
    return this._config?.enableChat
  }

  get enableCall() {
    return this._config?.enableCall
  }
}
