import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { DraggableWrapper } from 'shared/ui'
import {
  callPopUpGlobalStore,
  EventCallQueueParticipantJoin,
  EventCallQueueParticipantLeave,
} from 'widgets/CallPopUp'
import { CallPopUpDefault } from 'widgets/CallPopUp/ui/CallPopUpDefault'
import styles from 'widgets/CallPopUp/ui/styles.module.scss'

export const CallPopUp = observer(() => {
  const { itemsSort } = callPopUpGlobalStore
  const [isScroll, setIsScroll] = useState(false)

  useEffect(() => {
    EventCallQueueParticipantJoin.subscribe()
    EventCallQueueParticipantLeave.subscribe()

    return () => {
      EventCallQueueParticipantJoin.unsubscribe()
      EventCallQueueParticipantLeave.unsubscribe()
    }
  }, [])

  const popupWidth = 332
  const windowWidth = window.innerWidth
  const position = {
    x: Math.abs(windowWidth - popupWidth),
    y: 0,
  }

  if (!itemsSort.length) return null

  return (
    <DraggableWrapper
      handle={'handle'}
      defaultPosition={position}
      onChangeRef={(element) => {
        setIsScroll(element.offsetHeight < element.scrollHeight)
      }}
    >
      {(ref) => {
        return (
          <div
            ref={ref}
            className={classnames(styles.scroll, {
              [styles['scroll--enable']]: isScroll,
            })}
          >
            {itemsSort.map((item) => {
              return <CallPopUpDefault key={`${item.id}_${item.status}`} store={item.store} />
            })}
          </div>
        )
      }}
    </DraggableWrapper>
  )
})
