import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'
import { makeTextFieldClear, TextField } from 'shared/ui/TextField'
import { EnumHelperText, HelperText, monthItems, Switch, Typography, yearsItems } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { Label } from 'shared/ui/Label'
import { adminSendOptOutContactReportStore } from 'pages/admin/pages/organizations/modals/AdminSendOptOutContactReport/store/adminSendOptOutContactReportStore'

export const AdminSendOptOutContactReportContent = observer(() => {
  const {
    month,
    setMonth,
    year,
    setYear,
    email,
    setEmail,
    isOwner,
    setIsOwner,
    emailError,
    setEmailError,
    onCheckEmail,
  } = adminSendOptOutContactReportStore

  return (
    <>
      <Box display={'grid'} gap={'16px'}>
        <SelectField labelText={'Month'} value={month} setValue={setMonth} items={monthItems} />
        <SelectField labelText={'Year'} value={year} setValue={setYear} items={yearsItems} />
        <Box display={'flex'} gap={'8px'} alignItems={'center'}>
          <Typography variant={'body-md-regular'} ariaLabel={'CC Owner'}>
            CC Owner
          </Typography>
          <Switch value={isOwner} onChange={setIsOwner} />
        </Box>

        <Box display={'grid'} gap={'4px'}>
          <Label
            label={{
              text: 'Email',
            }}
          />
          <TextField
            variant={'stroke'}
            size={'small'}
            InputProps={{
              placeholder: 'someone@somewhere.com',
            }}
            value={email}
            onChange={setEmail}
            onFocus={() => setEmailError('')}
            onBlur={onCheckEmail}
            rightActions={[makeTextFieldClear()]}
          />

          {emailError && (
            <HelperText
              paddingBlock={'3.5px'}
              icon={'alertTriangleSmall'}
              variant={EnumHelperText.Error}
              text={emailError}
            />
          )}

          <HelperText
            paddingBlock={'3.5px'}
            text={
              'Enter an email that should receive this report. Use above to send to the Organization Owner.'
            }
          />
        </Box>
      </Box>
    </>
  )
})
