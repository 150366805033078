import { IntegrationKey, integrationsStore } from 'entities/Integrations'
import allMergeFieldsStore from 'widgets/MergeField/store/allMergeFieldsStore'

export const mergeFieldReplacer = (
  match: string,
  noMergeField?: boolean,
  isClearMergeField?: boolean
) => {
  if (!allMergeFieldsStore.isMergeField(match)) {
    return match
  }

  if (noMergeField) {
    return ''
  }

  const { mergeField, fallback, mergeFieldKey, integrationKey, fullMergeField } =
    allMergeFieldsStore.getMergeFieldInfoByTemplate(match)

  const fieldLabel = mergeField?.label

  if (isClearMergeField) return fieldLabel || mergeFieldKey

  const connectedIntegration = integrationsStore.getUserIntegration(integrationKey)

  const connected = connectedIntegration ? 'connected' : 'disconnected'

  const connectedSpan =
    integrationKey === IntegrationKey.salesmessage
      ? ''
      : `<span class='mergeField-integration-status mergeField-${connected}' data-readonly data-connected='${connected}' data-integration='${integrationKey}'></span>`

  const contentSpan = `<span class='mergeField-content mergeField-${integrationKey}' data-readonly data-key='${fullMergeField}' data-fallback='${
    fallback || ''
  }' data-fieldname='${mergeFieldKey}' data-fieldlabel='${fieldLabel}'>${
    fieldLabel || mergeFieldKey
  }</span>`

  return `<span class='mergeField-wrap' data-readonly>${contentSpan}${connectedSpan}</span>`
}
