import classnames from 'classnames'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import styles from './styles.module.scss'

type ITabsProps = {
  activeTab: EnumIntegrationTabs | string
  handleTab: (tab: EnumIntegrationTabs) => void
  count: {
    [EnumIntegrationTabs.Deals]: number
  }
}

const Tabs = ({ activeTab, handleTab, count }: ITabsProps) => {
  return (
    <div className={classnames(styles.tabs)}>
      <div
        className={classnames(styles.tab, {
          [styles.active]: activeTab === EnumIntegrationTabs.Contact,
        })}
        onClick={() => handleTab(EnumIntegrationTabs.Contact)}
      >
        Contact
      </div>
      <div
        className={classnames(styles.tab, {
          [styles.active]: activeTab === EnumIntegrationTabs.Account,
        })}
        onClick={() => handleTab(EnumIntegrationTabs.Account)}
      >
        Account
      </div>
      <div
        className={classnames(styles.tab, {
          [styles.active]: activeTab === EnumIntegrationTabs.Deals,
        })}
        onClick={() => handleTab(EnumIntegrationTabs.Deals)}
      >
        Deals<div className={styles.count}>{count[EnumIntegrationTabs.Deals]}</div>
      </div>
    </div>
  )
}

export { Tabs }
