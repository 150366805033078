import { FC, useState } from 'react'
import { Box } from '@mui/material'
import { Button } from 'shared/ui'

type IStopRecurringChildActionsProps = {
  onStopAll: () => Promise<void>
  onStopOnly: () => Promise<void>
  onCancel: () => void
}

export const StopRecurringChildActions: FC<IStopRecurringChildActionsProps> = ({
  onStopAll,
  onStopOnly,
  onCancel,
}) => {
  const [loadingStopALl, setLoadingStopALl] = useState(false)
  const [loadingStopOnly, setLoadingStopOnly] = useState(false)

  const disabled = loadingStopALl || loadingStopOnly

  const handleStopAll = async () => {
    try {
      setLoadingStopALl(true)
      await onStopAll()
      onCancel()
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingStopALl(false)
    }
  }
  const handleStopOnly = async () => {
    try {
      setLoadingStopOnly(true)
      await onStopOnly()
      onCancel()
    } catch (e) {
      console.error(e)
    } finally {
      setLoadingStopOnly(false)
    }
  }

  return (
    <Box display={'grid'} gap={1}>
      <Button
        typeBtn={'contained'}
        contained={'primary'}
        text={'Stop all future broadcasts'}
        disabled={disabled}
        loading={loadingStopALl}
        onClick={handleStopAll}
      />
      <Button
        typeBtn={'contained'}
        contained={'secondary'}
        text={'Stop only this broadcast'}
        disabled={disabled}
        loading={loadingStopOnly}
        onClick={handleStopOnly}
      />
      <div />
      <Button typeBtn={'contained'} contained={'secondary'} text={'Cancel'} onClick={onCancel} />
    </Box>
  )
}
