import { IReactionDisposer, makeAutoObservable, reaction } from 'mobx'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { Attachment } from 'entities/Attachment/model/Attachment'
import vCardStore from 'entities/VCard/store/vCardStore'
import { ContactsApi } from 'entities/Contacts'
import { VideoControllerStore } from 'widgets/PresentationMode/store/VideoControllerStore'
import { ImageControllerStore } from 'widgets/PresentationMode/store/ImageControllerStore'
import { AudioControllerStore } from 'widgets/PresentationMode/store/AudioControllerStore'
import { getAttachmentFlags } from 'widgets/MediaLibrary/utils'

class PresentationStore {
  imageControllerStore: ImageControllerStore = new ImageControllerStore()
  videoControllerStore: VideoControllerStore = new VideoControllerStore()
  audioControllerStore: AudioControllerStore = new AudioControllerStore()

  private _disposeOpenVCard: IReactionDisposer | null = null
  private _disposeDisabledOnEscCloseModal: IReactionDisposer | null = null

  constructor() {
    makeAutoObservable(this)

    this.reactionOpenVCard()
    this.reactionDisabledOnEscCloseModal()
  }

  attachments: Attachment[] = []
  currentIndex = 0

  disabledOverlayClick = false

  get isPrev() {
    return this.currentIndex > 0
  }

  get isNext() {
    return this.currentIndex < this.attachments.length - 1
  }

  get openPresentationMode() {
    return !!this.attachments.length
  }
  get currentAttachment(): Attachment | undefined {
    return this.attachments[this.currentIndex]
  }

  get hasShowPresentationMode() {
    return this.openPresentationMode && this.currentAttachment
  }

  initControllers = () => {
    this.imageControllerStore = new ImageControllerStore()
    this.videoControllerStore = new VideoControllerStore()
    this.audioControllerStore = new AudioControllerStore()
  }
  setAttachments = (attachments: Attachment[], index?: number) => {
    this.currentIndex = index || 0
    this.attachments = attachments
  }
  onPrev = () => {
    this.initControllers()
    this.currentIndex -= 1
  }
  onNext = () => {
    this.initControllers()
    this.currentIndex += 1
  }

  setDisabledOverlayClick = (value: boolean) => {
    this.disabledOverlayClick = value
  }

  onClose = () => {
    this.initControllers()
    this.attachments = []
    this.currentIndex = 1
    this.disabledOverlayClick = false
  }

  checkVCardIsSave = async (phone: string) => {
    vCardStore.isLoading = true
    const isAbsent = await this.isVCardContactAbsent(phone)
    vCardStore.isLoading = false

    if (vCardStore.modalVCard) {
      vCardStore.modalVCard.isSave = isAbsent
    }
  }

  isVCardContactAbsent = async (phone?: string) => {
    if (phone) {
      const { data: isValid } = await ContactsApi.validateNumber({
        number: phone,
      })
      const { data } = await ContactsApi.getContactsList({
        search: phone,
      })

      return isValid && data.total === 0
    }
  }

  openVCard = () => {
    if (vCardStore.modalVCard) {
      vCardStore.onCloseVCardContent()
    }

    if (this.currentAttachment?.vCard) {
      const { isVCardPresentationAvailable } = getAttachmentFlags(this.currentAttachment)

      if (isVCardPresentationAvailable && this.currentAttachment.vCard) {
        vCardStore.openVCardContent(this.currentAttachment.vCard)
        this.checkVCardIsSave(this.currentAttachment.vCard.phone)
      }
    }
  }

  reactionOpenVCard = () => {
    this._disposeOpenVCard?.()
    this._disposeOpenVCard = reaction(
      () => this.currentAttachment,
      () => {
        this.openVCard()
      }
    )
  }

  reactionDisabledOnEscCloseModal = () => {
    this._disposeDisabledOnEscCloseModal?.()
    this._disposeDisabledOnEscCloseModal = reaction(
      () => this.hasShowPresentationMode,
      (state) => {
        modalStore.setDisabledOnEscCloseModal(!!state)
      }
    )
  }
}

export default new PresentationStore()
