import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import styles from 'pages/admin/ui/AdminDetailCard/AdminDetailCardRow/styles.module.scss'

export type ITextareaEditValue = {
  typeEdit: 'textarea'
  value: string | number | null
  isUnsaved?: boolean
  editValue?: string | number | null
  onChange?: (value: string) => void
  editError?: string
}

export const TextareaEditValue = ({
  isUnsaved,
  editValue,
  onChange,
  editError,
}: ITextareaEditValue) => {
  const ref = useRef<HTMLDivElement>(null)
  const handleChange = () => {
    onChange && onChange(ref.current?.innerText || '')
  }
  useEffect(() => {
    if (ref.current) {
      ref.current.innerText = String(editValue || '')
    }
  }, [])
  return (
    <div
      ref={ref}
      className={classNames(
        styles.value,
        styles.editable,
        isUnsaved && styles.isUnsaved,
        editError && styles.editError
      )}
      contentEditable={true}
      onInput={handleChange}
    />
  )
}
