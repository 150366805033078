import React from 'react'
import { observer } from 'mobx-react-lite'
import { Tabs } from 'shared/ui'
import { EachContent } from 'shared/ui/Schedule/ui/MonthDaysSelector/ui/EachContent/EachContent'
import { OnTheContent } from 'shared/ui/Schedule/ui/MonthDaysSelector/ui/OnTheContent/OnTheContent'
import { type MonthDaysSelectorStore } from 'shared/ui/Schedule/ui/MonthDaysSelector/store/monthDaysSelectorStore'
import { monthDaySelectorTabs } from 'shared/ui/Schedule/ui/MonthDaysSelector/store/type'
import styles from './styles.module.scss'

type IDaysSelectorProps = {
  store: MonthDaysSelectorStore
  parentsDropdownId: string[]
}

export const MonthDaysSelector = observer(({ store, parentsDropdownId }: IDaysSelectorProps) => {
  const { type, handleSelectTab } = store
  return (
    <div className={styles.wrap}>
      <Tabs
        tabs={monthDaySelectorTabs}
        activeTabKey={type}
        variant={'simpleWithoutBorder'}
        handleSelectTab={handleSelectTab}
      />
      {type === 'each' && <EachContent store={store} />}
      {type === 'onThe' && <OnTheContent store={store} parentsDropdownId={parentsDropdownId} />}
    </div>
  )
})
