// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CiAWLlANjG8vVOUtiaGZ{margin-top:4px;display:flex;align-items:center;justify-content:space-between}.rSWgVqnJnWFPyg2x0wJ0{display:grid;grid-template-columns:1fr 1fr 1fr;height:32px;margin-bottom:4px;justify-content:space-between;align-items:center}.rSWgVqnJnWFPyg2x0wJ0 .a_Mhh5GuWFgRPTQBJUoa{justify-content:flex-start}.rSWgVqnJnWFPyg2x0wJ0 .IYZUXJjo9aRklbt_3AQw{justify-content:center}.rSWgVqnJnWFPyg2x0wJ0 .kzSzfDXsMXXcnU71kzpQ{justify-content:flex-end}.xF3ccML1MAUZ3yL6dQxt{display:flex;align-items:center}.lXKsqeEkFXXJIU1beZMb{margin-block:4px;width:100%;height:1px;background:var(--border-neutral-default)}.R_q5aL8rkJpxUlCt06ct{display:grid}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/info/ui/InfoWrap/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAEF,sBACE,YAAA,CACA,iCAAA,CACA,WAAA,CACA,iBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,4CACE,0BAAA,CAEF,4CACE,sBAAA,CAEF,4CACE,wBAAA,CAGJ,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,gBAAA,CACA,UAAA,CACA,UAAA,CACA,wCAAA,CAEF,sBACE,YAAA","sourcesContent":[".header{\n  margin-top: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.footer{\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  height: 32px;\n  margin-bottom: 4px;\n  justify-content: space-between;\n  align-items: center;\n\n  .left{\n    justify-content: flex-start;\n  }\n  .center{\n    justify-content: center;\n  }\n  .right{\n    justify-content: flex-end;\n  }\n}\n.flex{\n  display: flex;\n  align-items: center;\n}\n\n.divider{\n  margin-block: 4px;\n  width: 100%;\n  height: 1px;\n  background: var(--border-neutral-default);\n}\n.middleContent{\n  display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "CiAWLlANjG8vVOUtiaGZ",
	"footer": "rSWgVqnJnWFPyg2x0wJ0",
	"left": "a_Mhh5GuWFgRPTQBJUoa",
	"center": "IYZUXJjo9aRklbt_3AQw",
	"right": "kzSzfDXsMXXcnU71kzpQ",
	"flex": "xF3ccML1MAUZ3yL6dQxt",
	"divider": "lXKsqeEkFXXJIU1beZMb",
	"middleContent": "R_q5aL8rkJpxUlCt06ct"
};
export default ___CSS_LOADER_EXPORT___;
