import React from 'react'
import { IconButton, Tooltip } from 'shared/ui'
import { AudioPlayerProps } from 'shared/ui/AudioPlayer/ui/AudioPlayer'
import { downloadFile } from 'shared/lib'

export const AudioPlayerActions = ({
  url,
  setShowTranscription,
  showTranscription,
  transcript_status,
  is_auto_transcribe,
  fileName,
  retryTranscript,
  canTranscript,
  disableTranscript,
  setScrollBottomTrigger,
  hideTranscriptionButton,
}: AudioPlayerProps & {
  showTranscription: boolean
  hideTranscriptionButton?: boolean
  setShowTranscription: (value: boolean) => void
  setScrollBottomTrigger?: () => void
}) => {
  const toggleTranscription = () => {
    if (disableTranscript) return

    if (!is_auto_transcribe && transcript_status === null) {
      retryTranscript && retryTranscript()
    }

    if (canTranscript || transcript_status || is_auto_transcribe) {
      setShowTranscription(!showTranscription)
    }

    setScrollBottomTrigger?.()
  }
  return (
    <>
      {!hideTranscriptionButton && retryTranscript && (
        <Tooltip
          type={'description'}
          visibility={showTranscription || disableTranscript ? 'hidden' : 'visible'}
          label={'See transcript'}
          placement={'top'}
        >
          <IconButton
            icon={showTranscription ? 'chevronUp' : 'chevronDown'}
            active={showTranscription}
            fontSize={16}
            disabled={disableTranscript}
            color={'secondary'}
            variant={'icon'}
            size={'small'}
            onClick={toggleTranscription}
            ariaLabel='AudioPlayerTranscription'
          />
        </Tooltip>
      )}
      <Tooltip label={'Download'} placement={'top'}>
        <IconButton
          icon={'download'}
          fontSize={16}
          color={'secondary'}
          variant={'icon'}
          size={'small'}
          onClick={() => {
            url && downloadFile(url, fileName)
          }}
          ariaLabel='AudioPlayerDownload'
        />
      </Tooltip>
    </>
  )
}
