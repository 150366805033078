import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Actions } from 'shared/ui'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { useMediaLibraryContext } from 'widgets/MediaLibrary/context/mediaLibraryContext'
import { getMediaLibraryCardActions } from 'widgets/MediaLibrary/helpers/getMediaLibraryCardActions'

type MediaLibraryCardDropdownProps = {
  attachment: Attachment
}

export const MediaLibraryCardActions: FC<MediaLibraryCardDropdownProps> = observer(
  ({ attachment }) => {
    const mediaLibraryStore = useMediaLibraryContext()

    return (
      <Actions
        placementDropdown={'bottom-start'}
        noVisibleActions
        actions={getMediaLibraryCardActions(attachment, mediaLibraryStore)}
        noTooltip
        iconButtonProps={{
          variant: 'contained',
          color: 'lightSecondary',
          ariaLabel: 'MediaLibraryCardDropdown_trigger',
          fontSize: 16,
          size: 'small',
        }}
      />
    )
  }
)
