import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import {
  Typography,
  IconButton,
  Icon,
  SpinnerLoader,
  CopyWrapper,
  Dropdown,
  EmptyState,
  DropdownContent,
  Avatar,
  Tooltip,
} from 'shared/ui'
import { TextField, makeTextFieldAutoFocus } from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'
import { usersStore } from 'entities/Users'
import { type ConversationInfoStore } from 'widgets/ContactsDetails/ui/ConversationInfo'
import { InboxMember } from './InboxMember'
import styles from './styles.module.scss'

type IConversationInfoProps = {
  store: ConversationInfoStore
}

export const ConversationInfo: FC<IConversationInfoProps> = observer(({ store }) => {
  const conversation = store.conversation
  const inbox = store.inbox

  useEffect(() => {
    store.init()
  }, [])

  if (!inbox || !conversation || store.loading)
    return (
      <div className={classnames(styles.wrap)}>
        <SpinnerLoader />
      </div>
    )

  const number = store.number
  const filteredMembers = store.filteredMembers
  const assignedMember = usersStore.getItem(conversation?.user_id)
  const handleToggle = (open: boolean) => {
    store.setSearch('')
    store.setOpen(open)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.info}>
        <div className={styles.field}>
          <span className={styles.label}>
            <Typography
              className={styles.name}
              variant='body-md-regular'
              tag='div'
              ariaLabel={'Inbox'}
            >
              Inbox
            </Typography>
          </span>
          <div className={styles.valueWrapper}>
            <Tooltip
              label={inbox?.name.length > 15 ? inbox?.name : ''}
              placement='top'
              disableInteractive
            >
              <span className={styles.value}>
                <IconButton
                  icon={inbox?.icon}
                  size={'small'}
                  color={'transparent'}
                  fontSize={16}
                  ariaLabel='ConversationInfo'
                />
                <Typography
                  className={styles.name}
                  variant='body-md-regular'
                  tag='div'
                  ariaLabel={getAriaLabel('conversationDetailsInboxSelector')}
                >
                  {inbox?.name}
                </Typography>
              </span>
            </Tooltip>
          </div>
        </div>
        <div className={styles.field}>
          <span className={styles.label}>
            <Typography
              className={styles.name}
              variant='body-md-regular'
              tag='div'
              ariaLabel={'Inbox phone'}
            >
              Inbox phone
            </Typography>
          </span>
          <div className={styles.valueWrapper}>
            <CopyWrapper copyValue={number?.formatted_number || ''}>
              <span className={styles.value}>
                <div className={styles.icon}>
                  <Icon icon='phone' color='var(--content-primary-tertiary)' />
                </div>
                <Typography
                  className={styles.name}
                  variant='body-md-regular'
                  tag='div'
                  ariaLabel={getAriaLabel('conversationDetailsInboxPhoneSelector')}
                >
                  {number?.formatted_number}
                </Typography>
              </span>
            </CopyWrapper>
          </div>
        </div>
        {!conversation.isGroup && (
          <div
            className={classnames(styles.field, {
              [styles.dropdownAction]: open,
            })}
          >
            <span className={styles.label}>
              <Typography
                className={styles.name}
                variant='body-md-regular'
                tag='div'
                ariaLabel={'Assignee'}
              >
                Assignee
              </Typography>
            </span>
            <div className={styles.valueWrapper}>
              <Dropdown
                ariaLabel={getAriaLabel('conversationDetailsAssigneeSelector')}
                width={192}
                placement={'bottom'}
                show={store.open}
                onToggle={handleToggle}
                typeButton={'icon-button'}
                triggerComponent={(open) => {
                  if (open) return <></>

                  if (store.reassigning) {
                    return (
                      <span className={classnames(styles.value)}>
                        <div className={styles.icon}>
                          <SpinnerLoader size={16} />
                        </div>
                        <Typography
                          className={styles.name}
                          variant='body-md-regular'
                          pointer
                          ariaLabel={'Reassigning'}
                        >
                          Reassigning
                        </Typography>
                      </span>
                    )
                  }
                  return (
                    <CopyWrapper copyValue={null}>
                      <span className={classnames(styles.value, styles.action)}>
                        {assignedMember?.id ? (
                          <>
                            <div className={styles.icon}>
                              <Avatar info={assignedMember.avatarInfo} size={16} />
                            </div>
                            <Typography
                              className={styles.name}
                              variant='body-md-regular'
                              ellipsis
                              pointer
                              ariaLabel={'name'}
                            >
                              {assignedMember.name}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <div className={styles.icon}>
                              <Icon icon='userLine' color='var(--content-primary-tertiary)' />
                            </div>
                            <Typography
                              className={styles.name}
                              variant='body-md-regular'
                              pointer
                              ariaLabel={'Unassigned'}
                            >
                              Unassigned
                            </Typography>
                          </>
                        )}
                      </span>
                    </CopyWrapper>
                  )
                }}
                customComponent={(onCloseMenu) => (
                  <DropdownContent noPadding>
                    <div className={styles.dropdownWrapper}>
                      <div className={styles.searchWrapper}>
                        <TextField
                          size='small'
                          variant='integrated'
                          InputProps={{
                            placeholder: 'Search',
                          }}
                          value={store.search}
                          onChange={store.setSearch}
                          mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
                        />
                      </div>
                      <div
                        className={classnames(styles.membersWrapper, {
                          [styles.withScroll]: filteredMembers.length > 5,
                        })}
                      >
                        {filteredMembers.length ? (
                          <>
                            <InboxMember
                              onClose={onCloseMenu}
                              active={conversation.user_id === 0}
                              key={0}
                              member={null}
                              onReassign={store.onReassign}
                            />
                            {filteredMembers.map((member) => (
                              <InboxMember
                                onClose={onCloseMenu}
                                active={conversation.user_id === member.id}
                                key={member.id}
                                member={member}
                                ariaLabel={'assigneeListSelector'}
                                onReassign={store.onReassign}
                              />
                            ))}
                          </>
                        ) : (
                          <EmptyState noIcon />
                        )}
                      </div>
                    </div>
                  </DropdownContent>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
