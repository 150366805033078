import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { links } from 'shared/constants/links'
import { GuidelineAlert, GuidelineMemoryStore } from 'widgets/GuidelineAlert'
import { ChatbotLabel, ChatbotLayoutHead, ChatbotTestNewButton } from 'pages/chatbot/ui'
import { useChatbotLayoutContext } from 'pages/chatbot/context/chatbotLayoutContext'
import styles from './styles.module.scss'

export const ChatbotNewHead = observer(() => {
  const chatbotLayoutStore = useChatbotLayoutContext()
  const { titleIconProps, handleToggleCollapse } = chatbotLayoutStore.pageLayoutStore
  const guidelineMemoryStore = useMemo(() => new GuidelineMemoryStore(), [])

  return (
    <>
      <ChatbotLayoutHead>
        <div className={styles.wrapCollapseBtn}>
          <IconButton
            iconProps={{
              fontSize: titleIconProps.fontSize,
              icon: titleIconProps.icon,
            }}
            variant='icon'
            size='medium'
            color='secondary'
            onClick={handleToggleCollapse}
            ariaLabel='TextbotHeaderLayout'
          />
        </div>
        <ChatbotLabel className={styles.headerLabel} label='New Textbot' />
        <ChatbotTestNewButton className={styles.headerButton} />
      </ChatbotLayoutHead>
      <GuidelineAlert
        className={styles.guidelineAlert}
        store={guidelineMemoryStore}
        link={links.chatbotGuidelines}
        message='Review our Textbot guidelines'
      />
    </>
  )
})
