import { observer } from 'mobx-react-lite'
import { IconButton, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { useTextToSpeechContext } from 'widgets/TextToSpeech'
import { TextToSpeechActions } from 'widgets/TextToSpeech/ui/TextToSpeechActions/TextToSpeechActions'
import { TextToSpeechText } from 'widgets/TextToSpeech/ui/TextToSpeechText/TextToSpeechText'
import { TextToSpeechInfo } from 'widgets/TextToSpeech/ui/TextToSpeechInfo/TextToSpeechInfo'
import styles from './styles.module.scss'

export const TextToSpeechContent = observer(() => {
  const { onClose } = useTextToSpeechContext()
  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <Typography
            ariaLabel={getAriaLabel('TextToSpeech')}
            variant={'body-md-medium'}
            className={styles.title}
          >
            Text to speech
          </Typography>
          <IconButton
            ariaLabel={getAriaLabel('cancel')}
            icon={'close'}
            variant={'icon'}
            color={'tertiary'}
            size={'small'}
            onClick={onClose}
          />
        </div>
        <div className={styles.content}>
          <TextToSpeechText />
          <TextToSpeechInfo />
          <TextToSpeechActions />
        </div>
      </div>
    </>
  )
})
