import { makeObservable, observable, computed } from 'mobx'
import { Base } from 'models'
import {
  IResponseConversationDraftMessage,
  IResponseConversationDraftMessageContact,
  IResponseConversationDraftMessageData,
} from 'entities/Conversation/api/types'
import { Attachment } from 'entities/Attachment/model/Attachment'

export class ConversationDraftMessage extends Base {
  id: number
  conversation_id: number
  inbox_id: number
  inbox_type: number
  socketId: string
  message: IResponseConversationDraftMessageData
  contacts: IResponseConversationDraftMessageContact[]
  attachmentsMap: Map<string, Attachment> = new Map()

  constructor(item: IResponseConversationDraftMessage) {
    super()

    this.id = item.id
    this.conversation_id = item.conversation_id
    this.inbox_id = item.inbox_id
    this.inbox_type = item.inbox_type
    this.socketId = item.socketId
    this.message = item.message
    this.contacts = item.contacts

    this.handleInitAttachments()

    makeObservable(this, {
      attachmentsMap: observable,
      attachmentsIds: computed,
      isEmptyDraft: computed,
    })
  }

  handleInitAttachments = () => {
    const attachments = [
      ...this.message.audio_url,
      ...this.message.docs_url,
      ...this.message.images_url,
      ...this.message.video_url,
      ...this.message.media_url,
    ]

    attachments.forEach((attachment) => {
      const id = attachment.url || attachment.source

      if (id) this.attachmentsMap.set(id, new Attachment({ responseMediaShort: attachment }))
    })
  }

  get isEmptyDraft() {
    return Boolean(
      !this.message.audio_url.length &&
        !this.message.docs_url.length &&
        !this.message.images_url.length &&
        !this.message.video_url.length &&
        !this.message.media_url.length &&
        !this.message.message.length
    )
  }

  get body() {
    return this.message.message
  }

  get attachmentsIds() {
    return Array.from(this.attachmentsMap.keys())
  }

  get attachments() {
    return Array.from(this.attachmentsMap.values())
  }

  get attachment_type() {
    if (!this.attachments.length) return ''

    return this.attachments[0].content_type
  }

  get attachments_count() {
    return this.attachments.length
  }
}
