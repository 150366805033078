import { websocket } from 'entities/WebSocket/store'

interface IEventOrganizationProps {
  id: number | string
}

export class ChannelOrganization {
  presenceChannelName: string

  constructor(props: IEventOrganizationProps) {
    this.presenceChannelName = `presence-organization.${props.id}`

    this.subscribe()
  }

  subscribe() {
    websocket.subscribe(this.presenceChannelName)
  }
}
