import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { EnumHelperText, HelperText, toastStore } from 'shared/ui'
import { ChatbotTextTooltip } from 'pages/chatbot/ui/ChatbotTooltip'

import styles from './styles.module.scss'

export interface ICommonTextareaProps {
  name: string
  placeholder: string
  tooltip: string
  error?: string | null
  value: string
  limit?: number
  onChange: (value: string) => void
}

export const CommonTextarea = ({
  name,
  placeholder,
  tooltip,
  error,
  value,
  limit,
  onChange,
}: ICommonTextareaProps) => {
  const isDirty = useRef(false)
  const [limitReached, setLimitReached] = useState(false)

  const handleChange = (value: string) => {
    onChange(value)

    if (limit != null) setLimitReached(value.length > limit)

    isDirty.current = true
  }

  useEffect(() => {
    if (limit != null && isDirty.current && limitReached) {
      const limitView = new Intl.NumberFormat('en', { maximumSignificantDigits: 3 }).format(limit)
      const message = `"${name}" message should not be longer than ${limitView} characters`

      toastStore.add({
        title: message,
        type: 'error',
      })
    }
  }, [limitReached])

  return (
    <div className={styles.textarea}>
      <ChatbotTextTooltip tooltip={tooltip}>
        <span className={styles.textareaName}>{name}</span>
      </ChatbotTextTooltip>
      <textarea
        rows={4}
        className={classNames(styles.textareaContent, {
          [styles.textareaContentError]: !!error,
        })}
        placeholder={placeholder}
        value={value}
        onChange={({ target }) => handleChange(target.value)}
      />
      {!!error && (
        <HelperText icon={'alertTriangleSmall'} variant={EnumHelperText.Error} text={error} />
      )}
    </div>
  )
}
