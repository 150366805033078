import notificationPath from 'sounds/notification.mp3'
import notificationCallQueuePath from 'sounds/notification_call_queue.mp3'
import { getCDNAssetURL } from 'shared/lib'

const configSounds = {
  notification: getCDNAssetURL(notificationPath),
  notificationCallQueue: getCDNAssetURL(notificationCallQueuePath),
}

class AudioAsset {
  getAudio: (key: keyof typeof configSounds) => InstanceType<typeof Audio>

  constructor() {
    this.getAudio = (key: keyof typeof configSounds) => {
      const path = configSounds[key]

      return new Audio(path)
    }
  }
}

export const notification = new AudioAsset()
