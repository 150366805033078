import { makeAutoObservable } from 'mobx'
import { debounce } from 'lodash'
import { AxiosError } from 'axios'
import { EnumDropdownItemVariant, toastStore, type IDropdownItem } from 'shared/ui'
import type { IEinDocument, IEinItem } from 'entities/Compliance/api/type'
import { ComplianceApi } from 'entities/Compliance'
import { Attachment } from 'entities/Attachment/model/Attachment'
import styles from '../ui/styles.module.scss'
import { EinItem } from '../ui/EinItem'

const maxEinLength = 9
const maxZipLength = 5

export class EinStore {
  constructor() {
    makeAutoObservable(this)
    this._debouncedFetchEinData = debounce(this._fetchEinData, 300)
  }

  private _errorMessage = ''
  private _einItem: IEinItem | null = null
  private _einItems: IEinItem[] = []
  private _einSearch = ''
  private _irsEinAttachment: IEinDocument | null = null
  private _attachmentData: Attachment | null = null

  get einItem() {
    return this._einItem
  }

  get errorMessage() {
    return this._errorMessage
  }

  get einItemList(): IDropdownItem[] {
    if (this._einItems.length) {
      return [
        {
          id: 0,
          label: this._einItems[0].name,
          className: styles.einItem,
          variant: EnumDropdownItemVariant.Custom,
          renderOption: () => <EinItem einItem={this._einItems[0]} />,
        },
      ]
    }

    return []
  }

  get einSearch() {
    if (this._einSearch.length <= 2) {
      return this._einSearch
    }

    return `${this._einSearch.slice(0, 2)}-${this._einSearch.slice(2)}`
  }

  get irsEinAttachment() {
    return this._irsEinAttachment
  }

  get isManuallyEinItemFulled() {
    const keys = ['city', 'name', 'state', 'street1'] as const
    const isZipFulled = this._einItem?.zip?.length === maxZipLength
    const isEinFulled = this._einSearch.length === maxEinLength

    if (!isEinFulled || !isZipFulled || this._errorMessage) return false

    return keys.every((key) => this._einItem?.[key]?.length)
  }

  get attachmentData() {
    return this._attachmentData
  }

  setEinItem = (value: IEinItem | null) => {
    this._einItem = value
  }

  setFirstEinItem = () => {
    if (this._einItems.length) {
      this.setEinItem(this._einItems[0])
    }
  }

  addEinManually = (ein: string) => {
    this._einSearch = ein
  }

  addBusinessNameManually = (name: string) => {
    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }
    this._einItem.name = name
  }

  addBusinessAddressManually = (street: string) => {
    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }
    this._einItem.street1 = street
  }

  addBusinessAddress2Manually = (street: string) => {
    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }
    this._einItem.street2 = street
  }

  addBusinessStateManually = (state: string) => {
    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }

    this._einItem.state = state
  }

  addCityManually = (city: string) => {
    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }

    this._einItem.city = city
  }

  addZipManually = (zip: string) => {
    const clearedZip = zip.replace(/[^0-9]/g, '')
    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }

    if (clearedZip.length <= maxZipLength) {
      this._einItem.zip = clearedZip
    }
  }

  setAttachmentData = (file: Attachment | null) => {
    this._attachmentData = file
  }

  setIrsEinAttachment = (data: IEinDocument | null) => {
    this._irsEinAttachment = data
  }

  deleteIrsEinAttachment = async () => {
    try {
      await ComplianceApi.deleteBpBusinessInformationEinDocument()
    } catch (error) {
      console.log(error)
    }
  }

  uploadIrsEinAttachment = async (file: File | null) => {
    if (!file) return
    if (this._irsEinAttachment) {
      await this.deleteIrsEinAttachment()

      this.setIrsEinAttachment(null)
    }

    this.setAttachmentData(new Attachment({ file }))

    try {
      const { data } = await ComplianceApi.setBpBusinessInformationEinDocument(file)

      this.setIrsEinAttachment(data)

      toastStore.add({
        title: '1 file uploaded',
        type: 'success',
      })
    } catch (error) {
      console.log(error)
    }
  }

  checkEinNumber = () => {
    if (this._einSearch.length && !this._einItem?.ein) this._debouncedFetchEinData(this._einSearch)
  }

  setEinSearch = (search: string) => {
    const stringWithoutDashes = search.replace(/[^0-9]/g, '')

    if (stringWithoutDashes.length < maxEinLength) {
      this._einItems = []
    }

    this.setErrorMessage('')

    if (stringWithoutDashes.length > maxEinLength) return

    this._einSearch = stringWithoutDashes

    if (stringWithoutDashes.length === maxEinLength) {
      this.checkEinNumber()
    }
  }

  setErrorMessage = (message: string) => {
    this._errorMessage = message
  }

  private _fetchEinData = async (search: string) => {
    try {
      const { data } = await ComplianceApi.getEinData({ ein: search })

      this._addEin(data.data)
      this.setErrorMessage('')
    } catch (error) {
      if (error instanceof AxiosError) {
        this.setErrorMessage(error.response?.data.message || '')
      }
    }
  }

  private _debouncedFetchEinData: (search: string) => void

  private _addEin = (einList: IEinItem[]) => {
    this._einItems = einList
  }
}
