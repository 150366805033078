import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Schedule } from 'shared/ui/Schedule'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import { BroadcastScheduleStore } from 'widgets/BroadcastView/ui/BrooadcastSchedule'

export const ScheduleConfig = observer(() => {
  const broadcast = useBroadcastDetailModalContext()
  const [broadcastScheduleStore] = useState(() => new BroadcastScheduleStore())
  useEffect(() => {
    broadcastScheduleStore.init({
      response: broadcast.response,
    })
  }, [])
  return <Schedule scheduleStore={broadcastScheduleStore.scheduleStore} onlyView />
})
