import { CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

export class SettingsStore {
  pageLayoutStore = new PageLayoutStore()

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private title = 'Settings'
  private step = 1
  private total = 1

  get settingsTitle() {
    return this.title
  }

  get settingsStep() {
    return this.step
  }

  get settingsTotal() {
    return this.total
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setTitle = (title: string) => {
    this.title = title
  }

  increaseStep = () => {
    this.step = this.step + 1
  }

  decreaseStep = () => {
    this.step = this.step - 1 ? this.step - 1 : 1
  }

  setTotal = (total: number) => {
    this.total = total
  }

  constructor() {
    makeAutoObservable(this)
  }
}
