import { observer } from 'mobx-react-lite'
import { useChatbotContext } from 'pages/chatbot/context'

import { CommonTextarea } from './CommonTextarea'

export const PointBusinessInformation = observer(() => {
  const {
    errors,
    model: { settings },
  } = useChatbotContext()

  const businessInformationError = errors.getError('settings.business_information.value')

  const handleChange = (value: string) => {
    settings.setBusinessInformation(value)
    errors.removeError('settings.business_information')
  }

  return (
    <CommonTextarea
      limit={1000}
      name='Briefly describe your business:'
      tooltip='List things like your industry, size, and products or services'
      placeholder='We are a roofing company specializing in residential and commercial roofing services'
      error={businessInformationError}
      value={settings.businessInformation}
      onChange={handleChange}
    />
  )
})
