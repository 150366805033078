import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { errorLogger } from 'entities/EventLog'
import { ErrorBoundaryFallback } from 'app/layouts/ErrorBoundaryFallback'
import { appRouterCallPopup } from 'app/appvue/callPopup/router/router'

window.initCallPopup = () => {
  const appReact = createRoot(document.getElementById('portal') as HTMLElement)

  if (appReact) {
    appReact.render(
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorLogger}>
        <RouterProvider router={appRouterCallPopup} />
      </ErrorBoundary>
    )
  }
}
