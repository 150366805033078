// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stowr5itTCSEapPb7h_3{display:grid}.W_gtEzcrKJP4jAgBAYah{height:32px;display:flex;justify-content:space-between;align-items:center;margin-bottom:30px}.GYaQpUoFFmTiwq65MKuA{display:flex;gap:8px}.q9gTEJrn5EHfiRcf1KBu{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/admin/ui/AdminDetailCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEF,sBACE,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEF,sBACE,YAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,OAAA","sourcesContent":[".grid{\n  display: grid;\n}\n.header{\n  height: 32px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 30px;\n}\n.wrapTitle{\n  display: flex;\n  gap: 8px;\n\n}\n.actions{\n  display: flex;\n  gap: 8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "stowr5itTCSEapPb7h_3",
	"header": "W_gtEzcrKJP4jAgBAYah",
	"wrapTitle": "GYaQpUoFFmTiwq65MKuA",
	"actions": "q9gTEJrn5EHfiRcf1KBu"
};
export default ___CSS_LOADER_EXPORT___;
