import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { DropdownContent, DropdownGroup, type IDropdownItem } from 'shared/ui'
import { Phone } from 'entities/Phone/model/Phone'

type IMultiDropdownInboxItemProps = {
  inboxId: number
  setMultipleItem: (item: IDropdownItem) => void
  setSelectAllItems: (items: IDropdownItem[]) => void
  items: Map<number, Phone[]>
}

type INumberItem = 'tollFree' | 'local' | 'landline'

type IListItem = {
  key: INumberItem
  title: string
  items: IDropdownItem[]
}

export const MultiDropdownInboxItems = observer(
  ({ inboxId, setMultipleItem, setSelectAllItems, items }: IMultiDropdownInboxItemProps) => {
    const getDropdownItems = useCallback(() => {
      const list: IListItem[] = [
        { key: 'local', title: 'Local numbers', items: [] },
        { key: 'tollFree', title: 'Toll-free numbers', items: [] },
        { key: 'landline', title: 'Landline numbers', items: [] },
      ]

      items.get(inboxId)?.forEach((number) => {
        const item = {
          id: String(number?.id),
          label: number?.formatted_number,
          data: number,
          value: String(inboxId),
          iconL: number?.is_aircall ? 'aircall' : undefined,
          ariaLabel: 'MultiSelectDropdownValue',
        } as IDropdownItem

        if (number?.isTollFree) {
          list.find((data) => data.key === 'tollFree')?.items.push(item)
        }
        if (number?.isLocal || (number?.is_aircall && !number?.isTollFree)) {
          list.find((data) => data.key === 'local')?.items.push(item)
        }
        if (number?.isLandline) {
          list.find((data) => data.key === 'landline')?.items.push(item)
        }
      })

      return list
    }, [items])

    return (
      <DropdownContent className={'hasDropdownCardChild'}>
        {getDropdownItems().map((value) => {
          return (
            value.items.length >= 1 && (
              <DropdownGroup
                key={value.key}
                title={value.title}
                items={value.items}
                isMultiSelect
                onChange={(item) => setMultipleItem(item)}
                handleMultiSelect={(items) => setSelectAllItems(items)}
              />
            )
          )
        })}
      </DropdownContent>
    )
  }
)
