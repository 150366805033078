import React from 'react'
import { Button, Icon, IIconsVector, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IConversationMessageFieldFooterProps = {
  show: boolean
  icon: IIconsVector
  text: string
  disabled?: boolean
  onClick?: () => void
}

export const ConversationMessageFieldFooter: React.FC<IConversationMessageFieldFooterProps> = ({
  show,
  icon,
  text,
  disabled,
  onClick,
}) => {
  if (!show) return null

  return (
    <div className={styles.wrap}>
      <Icon icon={icon} tertiary fontSize={12} />
      <Typography
        variant={'text-input-helper'}
        color={'tertiary'}
        className={styles.text}
        ariaLabel={'text'}
      >
        {text}
      </Typography>
      {onClick && (
        <Button
          disabled={disabled}
          typeBtn={'link'}
          text={'View message'}
          size={'mini'}
          onClick={onClick}
        />
      )}
    </div>
  )
}
