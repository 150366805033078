import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { SpinnerLoader, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import {
  type IHubspotPropertiesObjectType,
  IntegrationKey,
  integrationsStore,
} from 'entities/Integrations'
import { SettingsRoutes } from 'entities/ExportRouters'
import { contactsStore } from 'entities/Contacts'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { Tabs } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Tabs'
import { Properties } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Entities/Properties'
import { Deals } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Entities/Deals'
import { Companies } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Entities/Companies'
import { Tickets } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Entities/Tickets/Tickets'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import styles from './styles.module.scss'

export const HubspotIntegration = observer(() => {
  const store = useContactsIntegrationsContext()
  const [activeTab, setActiveTab] = useState<EnumIntegrationTabs>(
    store.integrationTab || EnumIntegrationTabs.Contact
  )
  const contact = contactsStore.getItem(store.selectedContact?.id)

  const handleActiveTab = (tab: EnumIntegrationTabs) => {
    let objectType: IHubspotPropertiesObjectType = 'contact'
    setActiveTab(tab)
    store.handleIntegrationTab(tab)

    if (tab === EnumIntegrationTabs.Contact) objectType = 'contact'
    if (tab === EnumIntegrationTabs.Companies) objectType = 'company'
    if (tab === EnumIntegrationTabs.Deals) objectType = 'deal'
    if (tab === EnumIntegrationTabs.Tickets) objectType = 'ticket'

    store.setObjectType(objectType)
  }

  useEffect(() => {
    store.initDnD('hubspot', [
      {
        type: 'tickets',
        defaultItems: ['subject'],
      },
      {
        type: 'deals',
        defaultItems: ['dealname'],
      },
      {
        type: 'companies',
        defaultItems: ['name'],
      },
      {
        type: 'contacts',
        defaultItems: ['firstname', 'lastname'],
      },
    ])

    if (activeTab === EnumIntegrationTabs.Contact) {
      store.setObjectType('contact')
    }
  }, [])

  useEffect(() => {
    if (!contact) return

    store.initContactIntegrationHubspotInfoByType(Number(contact.integration_vendor_id))
  }, [store.objectType])

  if (!contact) return null

  const integration = integrationsStore.getIntegration(IntegrationKey.hubspot)

  if (!integration?.isConnected) {
    return (
      <div className={styles.reconnectWrapper}>
        <Button
          typeBtn={'contained'}
          size={'medium'}
          contained={'primary'}
          fullWidth
          text='Reconnect'
          onClick={() => {
            uiStore.changeRoute({
              path: `/${SettingsRoutes.root}/${SettingsRoutes.integrations}`,
            })
          }}
        />
      </div>
    )
  }

  const loading = store.loading || store.unlinking
  const info =
    integration.key === IntegrationKey.hubspot
      ? store.getContactIntegrationHubspotInfo(Number(contact.integration_vendor_id))
      : null

  if (loading) {
    return (
      <div className={classnames(styles.loaderWrap)}>
        <SpinnerLoader size={24} />
      </div>
    )
  }

  const ActiveTab = observer(() => {
    if (!info) return null

    if (activeTab === EnumIntegrationTabs.Deals) {
      return (
        <>
          <Deals
            integrationLabel={'HubSpot'}
            integrationKey={IntegrationKey.hubspot}
            integrationIcon={'hubspot'}
            contact={contact}
            deals={info.deals}
            dnd={store.getDnDItem('hubspot', 'deals')}
          />
        </>
      )
    }

    if (activeTab === EnumIntegrationTabs.Companies) {
      return (
        <Companies
          integrationLabel={'HubSpot'}
          integrationKey={IntegrationKey.hubspot}
          integrationIcon={'hubspot'}
          contact={contact}
          companies={info.companies}
          dnd={store.getDnDItem('hubspot', 'companies')}
        />
      )
    }

    if (activeTab === EnumIntegrationTabs.Contact) {
      return (
        <Properties
          integrationLabel={'HubSpot'}
          integrationKey={IntegrationKey.hubspot}
          property={info.properties || undefined}
          contact={contact}
          dnd={store.getDnDItem('hubspot', 'contacts')}
        />
      )
    }

    if (activeTab === EnumIntegrationTabs.Tickets) {
      return (
        <Tickets
          integrationLabel={'HubSpot'}
          integrationKey={IntegrationKey.hubspot}
          integrationIcon={'hubspot'}
          tickets={info.tickets}
          contact={contact}
          dnd={store.getDnDItem('hubspot', 'tickets')}
        />
      )
    }

    return null
  })

  return (
    <div className={classnames(styles.wrap)}>
      <Tabs
        handleTab={handleActiveTab}
        activeTab={activeTab}
        items={[
          {
            key: EnumIntegrationTabs.Contact,
            title: 'Contact',
          },
          {
            key: EnumIntegrationTabs.Companies,
            title: 'Companies',
            enableCount: activeTab === EnumIntegrationTabs.Companies,
            count: info?.companies?.length || 0,
          },
          {
            key: EnumIntegrationTabs.Deals,
            title: 'Deals',
            enableCount: activeTab === EnumIntegrationTabs.Deals,
            count: info?.deals?.length || 0,
          },
          {
            key: EnumIntegrationTabs.Tickets,
            title: 'Tickets',
            enableCount: activeTab === EnumIntegrationTabs.Tickets,
            count: info?.tickets?.length || 0,
          },
        ]}
      />
      <ActiveTab />
    </div>
  )
})
