// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RzkuYw2mu91YqbHKjOmV{display:grid;gap:4px}.VWzPV5PaGlB0dSsab8G3{padding-left:8px;display:grid;grid-template-columns:152px 1fr;gap:12px;height:32px;align-items:center}.VWzPV5PaGlB0dSsab8G3 .JAKyUCa1Ksk8tVoi7NAS{display:flex;align-items:center;gap:8px}.r4olEi1Ut_FUn3ClWfoc{position:relative}.ldx0oeoB9Mfu9R20L_xd{position:absolute;top:0;bottom:0;left:-16px;right:0;z-index:var(--zIndex-BASE-MINUS_1)}.IeVyWTsgm8wOxCwBaf0G{padding:24px 8px 0 8px}", "",{"version":3,"sources":["webpack://./src/widgets/BroadcastView/ui/ReviewBroadcast/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CAEF,sBACE,gBAAA,CACA,YAAA,CACA,+BAAA,CACA,QAAA,CACA,WAAA,CACA,kBAAA,CACA,4CACE,YAAA,CACA,kBAAA,CACA,OAAA,CAIJ,sBACE,iBAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,QAAA,CACA,UAAA,CACA,OAAA,CACA,kCAAA,CAEF,sBACE,sBAAA","sourcesContent":[".wrap {\n  display: grid;\n  gap: 4px;\n}\n.row {\n  padding-left: 8px;\n  display: grid;\n  grid-template-columns: 152px 1fr;\n  gap: 12px;\n  height: 32px;\n  align-items: center;\n  .value {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n}\n\n.modalWrap {\n  position: relative;\n}\n\n.wrapTooltip {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: -16px;\n  right: 0;\n  z-index: var(--zIndex-BASE-MINUS_1);\n}\n.ringlessInfo {\n  padding: 24px 8px 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "RzkuYw2mu91YqbHKjOmV",
	"row": "VWzPV5PaGlB0dSsab8G3",
	"value": "JAKyUCa1Ksk8tVoi7NAS",
	"modalWrap": "r4olEi1Ut_FUn3ClWfoc",
	"wrapTooltip": "ldx0oeoB9Mfu9R20L_xd",
	"ringlessInfo": "IeVyWTsgm8wOxCwBaf0G"
};
export default ___CSS_LOADER_EXPORT___;
