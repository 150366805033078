import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Tabs, type ITabItem, Alert, Scrollbar } from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type LinkContactStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/LinkContact/store/linkContactStore'
import { LinkManualTab } from './LinkManualTab/LinkManualTab'
import { LinkMatchingTab } from './LinkMatchingTab/LinkMatchingTab'
import styles from './styles.module.scss'

type ILinkContactModalContentProps = {
  contact: Contact
  linkContactStore: LinkContactStore
}

export const LinkContactModalContent: FC<ILinkContactModalContentProps> = observer(
  ({ contact, linkContactStore }) => {
    const { handleActiveTab, activeTab, hasItem, getItem } = linkContactStore
    const { isHoverModal } = modalStore

    const hasMatching = (id: number) => {
      return hasItem(id)
    }

    const matchedCount = (id: number) => {
      return getItem(id)?.length || 0
    }

    const tabs: ITabItem[] = [
      {
        name: 'Matching',
        key: 'matching',
        disabled: !hasMatching(contact.id),
      },
      {
        name: 'Link manually',
        key: 'manually',
      },
    ]

    useEffect(() => {
      if (hasMatching(contact.id)) {
        handleActiveTab('matching')
      }

      return () => {
        handleActiveTab('matching')
      }
    }, [])

    return (
      <Scrollbar
        hideTrack={!isHoverModal}
        autoHide={false}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={632}
      >
        <div className={classnames(styles.wrap)}>
          {hasMatching(contact.id) ? (
            <Alert
              item={{
                desc: `We found ${matchedCount(contact.id)} matching ${
                  matchedCount(contact.id) === 1 ? 'contact' : 'contacts'
                } in HubSpot with the same phone number`,
                type: 'infoLight',
              }}
            />
          ) : (
            <Alert
              item={{
                title: 'We didn’t find matching contacts in HubSpot with the same phone number',
                desc: 'You can link manually the contact',
                type: 'infoLight',
              }}
            />
          )}
          <div className={styles.tabs}>
            <Tabs
              tabs={tabs}
              activeTabKey={activeTab}
              handleSelectTab={(tab) => handleActiveTab(tab.key)}
            />
          </div>
          {activeTab === 'manually' && (
            <LinkManualTab linkContactStore={linkContactStore} contact={contact} />
          )}
          {activeTab === 'matching' && (
            <LinkMatchingTab linkContactStore={linkContactStore} contact={contact} />
          )}
        </div>
      </Scrollbar>
    )
  }
)
