import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { inboxesStore } from 'entities/Inbox'
import { IResponseConversationUnread } from 'entities/Conversation/api/types'

const ConversationUnread = {
  handle: (data: IResponseConversationUnread) => {
    const { inboxesMap } = inboxesStore
    const inbox = inboxesMap.get(data.inbox_id)

    if (inbox) inbox.handleUpdateUnreadCount(data)
  },
}

export const EventConversationUnread = {
  subscribe: () => websocket.on(WebsocketEvent.ConversationUnread, ConversationUnread.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.ConversationUnread, ConversationUnread.handle),
}
