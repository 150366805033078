import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { BreakpointsEnum } from 'shared/constants/breakpoints'
import { layoutStore } from 'shared/layout'
import { IContactsDetailsVariant } from './types'

const blockTypes = [
  'notes',
  'tags',
  'integration',
  'contactDetails',
  'conversation',
  'contactDetailsAllFields',
] as const

type IBlocksTypes = (typeof blockTypes)[number]

const openStatusStoreKey = 'contactDetailsOpenStatus'
type IOpenStatusStoreType = {
  [key in IBlocksTypes]?: boolean
}

export class ContactsDetailsGlobalStore {
  private _openStatus: Map<IBlocksTypes, boolean> = new Map(blockTypes.map((item) => [item, false]))
  private _collapse = true
  private _variant: IContactsDetailsVariant = 'conversation'
  private _openStatusTrigger = ''
  private _overlay = false

  constructor() {
    makeAutoObservable(this)

    this.openStatusReaction()
    this.checkStoredOpenStatus()
  }

  reset = () => {
    this._collapse = true
    this._overlay = false
    this._openStatusTrigger = ''
  }

  isOpenBlock = (blockType: IBlocksTypes) => {
    return this._openStatus.get(blockType)
  }

  toggleBlockView = (blockType: IBlocksTypes) => {
    const status = this._openStatus.get(blockType)
    this._openStatus.set(blockType, !status)
    this._openStatusTrigger = nanoid()
  }

  openBlockView = (blockType: IBlocksTypes) => {
    this._openStatus.set(blockType, true)
    this._openStatusTrigger = nanoid()
  }

  handleDocumentElementBlur = () => {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) activeElement.blur()
  }

  handleToggleCollapse = () => {
    this.handleDocumentElementBlur()
    this._collapse = !this._collapse
  }

  handleChangeCollapse = (status: boolean) => {
    this.handleDocumentElementBlur()
    this._collapse = status
    this._overlay = false
  }

  handleSetVariant = (type: IContactsDetailsVariant) => {
    runInAction(() => {
      this._variant = type
    })
  }

  storeOpenStatus = () => {
    const data: IOpenStatusStoreType = {}

    this.openStatusList.forEach(([key, status]) => {
      data[key] = status
    })

    localStorage.setItem(openStatusStoreKey, JSON.stringify(data))
  }

  checkStoredOpenStatus = () => {
    const dataJSON = localStorage.getItem(openStatusStoreKey)
    if (!dataJSON) return

    const data = JSON.parse(dataJSON) as IOpenStatusStoreType
    blockTypes.forEach((key) => {
      if (data[key]) {
        this._openStatus.set(key, !!data[key])
      }
    })
  }

  get isShowDetailsOverlayConversation() {
    if (this._overlay) return true

    const { activeBreakpoint } = layoutStore

    return (
      (activeBreakpoint === BreakpointsEnum.XS || activeBreakpoint === BreakpointsEnum.S) &&
      !this.isCollapse
    )
  }

  get isShowDetailsOverlayContacts() {
    if (this._overlay) return true

    const { activeBreakpoint } = layoutStore

    return (
      (activeBreakpoint === BreakpointsEnum.XS ||
        activeBreakpoint === BreakpointsEnum.S ||
        activeBreakpoint === BreakpointsEnum.M) &&
      !this.isCollapse
    )
  }

  get isShowContactDetailsOverlay() {
    return this._variant === 'contacts'
      ? this.isShowDetailsOverlayContacts
      : this.isShowDetailsOverlayConversation
  }

  get isCollapse() {
    return this._collapse
  }

  get openStatusList() {
    return Array.from(this._openStatus.entries())
  }

  openStatusReaction() {
    reaction(
      () => this._openStatusTrigger,
      () => this.storeOpenStatus()
    )
  }

  setOverlay = (value: boolean) => {
    this._overlay = value
  }
}

export const contactsDetailsGlobalStore = new ContactsDetailsGlobalStore()
