import { useEffect, RefObject, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Typography } from 'shared/ui'
import { numbersStore } from 'entities/Phone'
import { BaseContent, Head } from 'widgets/CallPopUp'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import styles from './../../styles.module.scss'

type ICallPowerDialerBaseContentProps = {
  refWrap: RefObject<HTMLDivElement>
}

export const CallPowerDialerBaseContent: FC<ICallPowerDialerBaseContentProps> = observer(
  ({ refWrap }) => {
    const { callPopUpPowerDialerStore, innerRefTooltipCurrent, contactFrom } =
      useCallPopUpDefaultContext()
    const store = callPopUpPowerDialerStore
    const number = numbersStore.getItem(contactFrom?.numberId)

    useEffect(() => {
      if (store.item) {
        store.restartTimer()
      } else {
        store.clearTimer()
      }

      return () => {
        store.clearTimer()
      }
    }, [store.item])

    if (!store.item) return null

    return (
      <div className={styles.section}>
        <div ref={refWrap} className={styles.tooltipWrap} />

        <Head
          isIncoming={false}
          wrapElement={innerRefTooltipCurrent}
          name={contactFrom?.name}
          phone={number?.formatted_number}
        />

        <BaseContent
          withPadding={true}
          avatarsInfo={[store.item.avatarProps.info]}
          name={store.item.name || store.item.number}
          number={store.item.number}
          desc={store.item.number}
          actionsBottom={
            <div className={styles.powerDialerActions}>
              <Typography
                className={styles.powerDialerActions__label}
                variant={'body-md-medium'}
                ariaLabel={'Next call'}
              >
                Next call in {store.timer} sec...
              </Typography>
              <div className={styles.powerDialerActions__buttons}>
                <Button
                  contained={'secondary'}
                  text={'Skip'}
                  onClick={store.handleSkip}
                  disabled={store.disabled}
                />
                <Button
                  intent={'positive'}
                  contained={'primary'}
                  icon={'phone'}
                  text={'Call'}
                  onClick={store.handleCall}
                  disabled={store.disabled}
                />
              </div>
            </div>
          }
        />
      </div>
    )
  }
)
