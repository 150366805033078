import axios, { CancelTokenSource } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { ContactsApi, IResponseCustomField } from 'entities/Contacts'

export class EnrollmentContactPreviewStore {
  private _customFieldsMap: Map<string, IResponseCustomField> = new Map()
  private _cancelToken: CancelTokenSource | null = null

  constructor() {
    makeAutoObservable(this)
  }

  fetchCustomFields = async (id: number) => {
    try {
      this._cancelToken = axios.CancelToken.source()

      const { data } = await ContactsApi.getContactsCustomFields(id, {
        cancelToken: this._cancelToken.token,
      })

      runInAction(() => {
        data.data.forEach((field) => {
          this._customFieldsMap.set(field.field_key, field)
        })
      })
    } catch (e) {
      console.log(e)
    }
  }

  reset = () => {
    this._customFieldsMap.clear()
    this.cancelRequest()
  }

  cancelRequest = () => {
    this._cancelToken?.cancel()
  }

  get customFields() {
    return Array.from(this._customFieldsMap.values())
  }
}
