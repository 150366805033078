import { INameValueRecordProps, NameValueRecord, Typography } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from '../styles.module.scss'

export const SendFromListContent = ({ list }: { list: INameValueRecordProps[] }) => (
  <div>
    <div className={styles.grid}>
      <Typography ariaLabel='Send' variant='text-input-field' className={styles.label}>
        Text in numbers
      </Typography>
      <div className={styles.listWrap}>
        {list.map((data, index) => {
          return (
            <div
              className={styles.listValue}
              key={index}
              aria-label={getAriaLabel('SendFromListContent')}
            >
              <NameValueRecord ariaLabel={'KeywordDetailsNumber'} {...data} />
            </div>
          )
        })}
      </div>
    </div>
  </div>
)
