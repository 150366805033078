import { makeAutoObservable, runInAction } from 'mobx'
import { showToast } from 'shared/ui'
import { BillingApi } from 'entities/Billing'
import { Source } from 'entities/Billing/model/Source'
import { CreditPrice } from 'entities/Billing/model/CreditPrice'
import { AutorechargeStore } from 'entities/Billing/store/autorechargeStore'
import {
  IParamsCreateSource,
  IParamsUpdateBillingCredits,
  IResponseBillingAreaCode,
  IResponseBillingSource,
  IResponseBillingState,
} from 'entities/Billing/api/types'

export class BillingStore {
  creditsPriceMap: Map<number, CreditPrice> = new Map()
  creditsPriceLoading = false
  statesMap: Map<string, IResponseBillingState> = new Map()
  areaCodesMap: Map<number, IResponseBillingAreaCode> = new Map()
  sourcesMap: Map<string, Source> = new Map()
  sourcesLoading = false
  autorecharge: AutorechargeStore | null = null
  autorechargeLoading = false
  buyCreditsLoading = false
  areaCodesLoading = false
  statesLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  fetchBillingAdditionalCreditPrice = async () => {
    try {
      runInAction(() => {
        this.creditsPriceLoading = true
      })

      const { data } = await BillingApi.getBillingAdditionalCreditPrice()

      runInAction(() => {
        data.forEach((item) => {
          this.creditsPriceMap.set(item.credits, new CreditPrice(item))
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.creditsPriceLoading = false
      })
    }
  }

  applyCoupon = async () => {
    try {
      const currentUrl = window.location.href
      const queryString = currentUrl.split('?')[1]
      const searchParams = new URLSearchParams(queryString)

      if (searchParams.has('c')) {
        const { data } = await BillingApi.applyCoupon(searchParams.get('c') ?? '')
        if (data.error) {
          showToast({
            type: 'error',
            title: data.error,
          })
        } else {
          showToast({
            type: 'success',
            title: 'Coupon successfully applied!',
          })
        }
      }
    } catch (e) {
      showToast({
        type: 'error',
        title: 'Error during coupon processing',
      })
    }
  }

  fetchBillingSources = async () => {
    try {
      runInAction(() => {
        this.sourcesLoading = true
      })

      const { data } = await BillingApi.getBillingSources()

      runInAction(() => {
        data.forEach((item) => {
          this.sourcesMap.set(item.id, new Source(item))
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.sourcesLoading = false
      })
    }
  }

  createSource = async (params: IParamsCreateSource) => {
    try {
      const { data } = await BillingApi.createSource(params)
      if (params.backup) {
        this.setBackupSource(data.id)
      }
      return this.addSource(data)
    } catch (e) {
      console.error(e)
    }
  }

  addSource = (sourceDate: IResponseBillingSource) => {
    const source = new Source(sourceDate)
    this.sourcesMap.set(source.id, source)
    if (source.primary) {
      this.setPrimarySource(source.id)
    }
    return source
  }

  fetchBillingAreaCodes = async () => {
    try {
      runInAction(() => {
        this.areaCodesLoading = true
      })

      const { data } = await BillingApi.getBillingAreaCodes()

      runInAction(() => {
        data.data.forEach((item) => {
          this.areaCodesMap.set(item.id, item)
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.areaCodesLoading = false
      })
    }
  }

  fetchBillingStates = async () => {
    try {
      runInAction(() => {
        this.statesLoading = true
      })

      const { data } = await BillingApi.getBillingStates()

      runInAction(() => {
        data.data.forEach((item) => {
          this.statesMap.set(item.state_code, item)
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.statesLoading = false
      })
    }
  }

  fetchAutorecharge = async () => {
    try {
      runInAction(() => {
        this.autorechargeLoading = true
      })

      const { data: autorecharge } = await BillingApi.getBillingAutoRecharge()

      runInAction(() => {
        this.autorecharge = new AutorechargeStore(autorecharge)
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.autorechargeLoading = false
      })
    }
  }

  handleBuyCredits = async (params: IParamsUpdateBillingCredits) => {
    try {
      runInAction(() => {
        this.buyCreditsLoading = true
      })

      await BillingApi.updateBillingCredits(params)
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.buyCreditsLoading = false
      })
    }
  }

  getCreditsPrice = (id: number) => {
    return this.creditsPriceMap.get(id) || null
  }

  getSources = (id: string) => {
    return this.sourcesMap.get(id) || null
  }

  setPrimarySource = (id: string) => {
    this.sourcesMap.forEach((source) => {
      source.setPrimary(id === source.id)
    })
  }

  setBackupSource = (id: string) => {
    if (this.autorecharge) {
      this.autorecharge.setBackup(id)
    }
  }

  get creditsPrice() {
    return Array.from(this.creditsPriceMap.values())
  }

  get sources() {
    return Array.from(this.sourcesMap.values()).sort((source) => (source.isPrimary ? -1 : 1))
  }

  get sourcesSimple() {
    return Array.from(this.sourcesMap.values()).filter((item) => item.object === 'card')
  }

  get sourcesAch() {
    return Array.from(this.sourcesMap.values()).filter((item) => item.object === 'payment_method')
  }

  get states() {
    return Array.from(this.statesMap.values())
  }

  get areaCodes() {
    return Array.from(this.areaCodesMap.values())
  }
}

export const billingStore = new BillingStore()
