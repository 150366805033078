import { ErrorBoundary } from 'react-error-boundary'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { errorLogger } from 'entities/EventLog'
import { ErrorBoundaryFallback } from 'app/layouts/ErrorBoundaryFallback'
import { appSettingsMenu } from 'app/appvue/settingsMenu/router/router'

window.initSettingsMenu = () => {
  const settingsMenu = createRoot(document.getElementById('settings-menu') as HTMLElement)

  if (settingsMenu) {
    window.resetSettingsMenu = () => {
      settingsMenu.unmount()
    }

    settingsMenu.render(
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorLogger}>
        <RouterProvider router={appSettingsMenu} />
      </ErrorBoundary>
    )
  }
}
