import { observer } from 'mobx-react-lite'

import { useDetailsContext } from '../detailsContext'
import { ConversationCard } from './ConversationCard'
import styles from './styles.module.scss'

export const TabConversation = observer(() => {
  const { data } = useDetailsContext()

  const maxAttempts = `${data.settings?.max_attempts?.value ?? '0'}`
  const aboutConversation = data.settings?.about_conversation?.value
  const businessInformation = data.settings?.business_information?.value
  const howToRespond = data.settings?.how_to_respond?.value

  return (
    <section className={styles.conversationCards}>
      <ConversationCard
        value={maxAttempts}
        name='Set a message limit'
        placeholder='Message limit'
      />
      <ConversationCard
        value={aboutConversation}
        name={`Explain the topic 
of the conversation`}
        placeholder='This conversation is about qualifying a lead by collecting their email, address, and required roofing services'
      />
      <ConversationCard
        value={businessInformation}
        name={`Briefly describe 
your business`}
        placeholder='We are a roofing company specializing in residential and commercial roofing services'
      />
      <ConversationCard
        value={howToRespond}
        name={`Define the style 
and tone of your 
messages`}
        placeholder='The Textbot should respond in a polite and professional manner, asking clear and direct questions'
      />
    </section>
  )
})
