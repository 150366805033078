// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WFFxRZz891Dj56lzhGNV audio{visibility:hidden}", "",{"version":3,"sources":["webpack://./src/widgets/PresentationMode/ui/content/audio/styles.module.scss"],"names":[],"mappings":"AACE,4BACE,iBAAA","sourcesContent":[".wrap{\n  audio{\n    visibility: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "WFFxRZz891Dj56lzhGNV"
};
export default ___CSS_LOADER_EXPORT___;
