import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { MessageField } from 'widgets/MessageField'
import { useChatbotContext, useChatbotDialogContext } from 'pages/chatbot/context'

import styles from './styles.module.scss'

const CompletedAlert = observer(() => {
  const chatbot = useChatbotContext()
  const dialog = useChatbotDialogContext()

  const isDisabled = chatbot.id == null || chatbot.locked || dialog.loading

  const completedReason = useMemo(() => {
    switch (dialog.status) {
      case 'completed':
        return 'Test completed. Success actions will be triggered for real contacts.'
      case 'abandoned':
      case 'terminated':
        return 'Test completed. Fallback actions will be triggered for real contacts.'
      default:
        return null
    }
  }, [dialog.status])

  const handleAlertAction = () => {
    if (chatbot.dirty) {
      chatbot.updateChatbot().then((updated) => {
        if (updated) dialog.restart()
      })
    } else {
      dialog.restart()
    }
  }

  if (!completedReason) return null

  return (
    <Alert
      item={{
        type: 'infoBlue',
        desc: completedReason,
      }}
      rightAction={
        <Button
          className={styles.alertButton}
          text='Restart'
          typeBtn='outlined'
          size='small'
          contained='secondary'
          disabled={isDisabled}
          onClick={handleAlertAction}
        />
      }
    />
  )
})

const SaveAndRestartAlert = observer(() => {
  const chatbot = useChatbotContext()
  const dialog = useChatbotDialogContext()

  const isDisabled = chatbot.id == null || chatbot.locked || dialog.completed || dialog.loading

  const handleAlertAction = () => {
    if (chatbot.dirty) {
      chatbot.updateChatbot().then((updated) => {
        if (updated) dialog.restart()
      })
    } else {
      dialog.restart()
    }
  }

  if (!chatbot.dirty && !dialog.invalid) return null
  if (dialog.completed) return null

  return (
    <Alert
      item={{
        type: 'infoBlue',
        desc: 'To test the Textbot, save and restart session',
      }}
      rightAction={
        <Button
          className={styles.alertButton}
          text='Save & Restart'
          typeBtn='outlined'
          size='small'
          contained='secondary'
          disabled={isDisabled}
          onClick={handleAlertAction}
        />
      }
    />
  )
})

export const MessageBox = observer(() => {
  const chatbot = useChatbotContext()
  const dialog = useChatbotDialogContext()

  const messageBoxDisabled = useMemo(
    () =>
      chatbot.dirty ||
      dialog.invalid ||
      !dialog.started ||
      dialog.completed ||
      chatbot.locked ||
      dialog.loading,
    [
      chatbot.dirty,
      dialog.invalid,
      dialog.started,
      dialog.completed,
      chatbot.locked,
      dialog.loading,
    ]
  )

  const sendButtonDisabled = useMemo(
    () => dialog.message.disablesSend || !dialog.started || dialog.loading || chatbot.locked,
    [dialog.message.disablesSend, dialog.started, dialog.loading, chatbot.locked]
  )

  useEffect(() => dialog.message.setDisabledMessageField(messageBoxDisabled), [messageBoxDisabled])

  useEffect(() => {
    const handleSend = () => {
      dialog.send(dialog.message.text).then((sent) => {
        if (sent) dialog.message.setMessageText('', '')
      })
    }

    dialog.message.setSendActionBtnProps({
      onClick: handleSend,
      disabled: sendButtonDisabled,
      ariaLabel: 'SendTestMessage',
    })
  }, [sendButtonDisabled])

  return (
    <footer className={styles.dialogFooter}>
      <CompletedAlert />
      <SaveAndRestartAlert />
      <MessageField messageFieldStore={dialog.message} />
    </footer>
  )
})
