import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { cloneDeep, isEqual } from 'lodash'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { showToast, toastStore } from 'shared/ui'
import { ContactsApi } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'
import { ContactOptIn } from 'entities/Contacts/model/ContactOptIn'
import { IParamsContactsOptOut } from 'entities/Contacts/api/types'
import { IResponseFilterSegment } from 'entities/Segment'
import { ContactsOptInModalActions, ContactsOptInModalContent } from 'widgets/ContactsOptInModal'

export type IContactsOptInModalLocalConfigOptionsStore = {
  reset?: () => void
  isBulkAll: boolean
  filtersList?: IResponseFilterSegment[]
  search?: string
  successHandler?: (opt_outs: IParamsContactsOptOut['opt_outs']) => void
}

export type IContactsOptInModalLocalConfigStore = {
  contacts: Contact[]
  id: string
  deleteModal: (id: string) => void
  options?: IContactsOptInModalLocalConfigOptionsStore
}

export class ContactsOptInModalLocalStore {
  idModal = 'contacts_opt_in_modal'
  contacts: Contact[] = []
  itemsOptInMap: Map<number | string, ContactOptIn> = new Map()
  id = ''
  deleteModal: (id: string) => void
  options: IContactsOptInModalLocalConfigOptionsStore | null = null
  cacheParams: IParamsContactsOptOut | null = null

  constructor(config: IContactsOptInModalLocalConfigStore) {
    makeAutoObservable(this)

    this.contacts = config.contacts
    this.id = config.id
    this.deleteModal = config.deleteModal
    this.options = config.options || null

    this.initItems()

    modalStore.addModal({
      id: this.idModal,
      title: 'Opt-in status',
      width: 360,
      ModalActions: ContactsOptInModalActions,
      ModalContent: ContactsOptInModalContent,
      paddingContent: '0 22px 14px 24px',
      onClose: this.closeModal,
    })
  }

  reset = () => {
    this.contacts = []
    this.itemsOptInMap.clear()
    this.deleteModal(this.id)
  }

  initItems = () => {
    this.contacts.forEach((contact) => {
      const items = contact.optItems

      items.forEach((item) => {
        this.itemsOptInMap.set(item.id, new ContactOptIn(item))
      })
    })

    this.cacheParams = cloneDeep(this.params)
  }

  closeModal = () => {
    modalStore.removeModal(this.idModal)
    this.deleteModal(this.id)
  }

  handleSaveUndo = () => {
    this.closeModal()

    const nanoId = nanoid()
    const id = setTimeout(() => {
      this.handleSave(nanoId)
    }, 5000)

    showToast({
      id: nanoId,
      type: 'success',
      title: 'Contact opt-in status changed',
      action: {
        text: 'Undo',
        onAction: () => {
          clearTimeout(id)
          this.reset()
          toastStore.remove(nanoId)
        },
      },
    })
  }

  handleSave = async (id?: string) => {
    if (!this.params) return

    if (id) toastStore.remove(id)

    try {
      await ContactsApi.updateContactsOptOut(this.params)

      this.options?.successHandler?.(this.params.opt_outs)
    } catch (e) {
      console.error(e)
    } finally {
      this.reset()
      this.options?.reset?.()
    }
  }

  get params(): IParamsContactsOptOut | null {
    if (!this.contacts.length) return null

    const contactsIds = this.contacts.map((item) => item.id)
    const local = this.itemsOptInMap.get('local')
    const shortcodes = this.items.reduce((state, item) => {
      if (item.id !== 'local') {
        return {
          ...state,
          [item.id]: item.value,
        }
      }

      return state
    }, {})

    if (!local) return null

    return {
      contacts: contactsIds,
      opt_outs: {
        toll_free: local.value,
        shortcodes: shortcodes,
      },
      type: 'opt-out',
      bulk_all: this.options?.isBulkAll || false,
      filtersList: this.options?.filtersList,
      search: this.options?.search,
    }
  }

  get items() {
    return Array.from(this.itemsOptInMap.values())
  }

  get disabled() {
    if (this.contacts.length !== 1) return false

    return isEqual(this.cacheParams, this.params)
  }
}
