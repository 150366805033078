// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T7enZ7bmZNpPBjVtDIO2{padding:12px;display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/widgets/TextToSpeech/ui/TextToSpeechActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,6BAAA","sourcesContent":[".wrap{\n  padding: 12px;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "T7enZ7bmZNpPBjVtDIO2"
};
export default ___CSS_LOADER_EXPORT___;
