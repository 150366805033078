import { useRef, useMemo, useState, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import {
  Avatar,
  Time,
  AvatarGroup,
  Icon,
  EnumTimeMode,
  Checkbox,
  AutomatedAvatar,
  EnumTimeStatus,
  Badge,
  layoutStore,
} from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { inboxesStore, TYPES_INBOX, InboxTag } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { contactsStore } from 'entities/Contacts'
import { numbersStore } from 'entities/Phone'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { ConversationDropdown, ScheduleCount } from 'widgets/ConversationCard'
import { ConversationMessage } from 'widgets/ConversationCard/ui/ConversationMessage/ConversationMessage'
import { useConversationListContext } from 'widgets/ConversationList/context'
import styles from './styles.module.scss'

export type IConversationCardProps = {
  conversation: Conversation
  isActive?: boolean
  onOpenAction?: (item: Conversation) => void
}

export const ConversationCard: FC<IConversationCardProps> = observer((props) => {
  const store = useConversationListContext()
  const { conversation, onOpenAction, isActive } = props
  const contact = contactsStore.getItem(conversation.contact_id)
  const user = usersStore.getItem(conversation.message?.user_id)
  const inbox = inboxesStore.getItem(conversation.inbox_id)
  const number = inbox?.type === 'inbox' ? numbersStore.getItem(inbox?.numberId) : undefined

  const isSent = conversation.message?.isOutbound
  const avatarInfo = user?.avatarInfo
  const isShowAvatar = isSent && !!avatarInfo

  const isAutomated = conversation.message?.isAutomated
  const [hover, setHover] = useState(false)
  const refAvatar = useRef<HTMLDivElement>(null)
  const contacts = conversation.contactsIds.map((id) => contactsStore.getItem(id))
  const contactsAvatarInfo = contacts.map((item) => item?.avatarInfo)
  const isOptOut = number?.isShortCode
    ? contact?.isOptOutCurrentInbox
    : contact?.isOptOut || contact?.isOptOutCurrentInbox
  const openConversation = (e: React.MouseEvent) => {
    if (refAvatar.current && !refAvatar.current.contains(e.target as Node)) {
      onOpenAction?.(conversation)
    }
  }

  const [isRightOptionsOpen, setIsRightOptionsOpen] = useState(false)
  const onMouseLeave = () => setHover(false)
  const onMouseEnter = () => setHover(true)

  const renderDate = () => {
    if (hover || isRightOptionsOpen) {
      return (
        <div
          className={classnames(styles.conversationDropdown, {
            [styles.hidedElem]: !isRightOptionsOpen && !hover,
            [styles.dropdownOpen]: isRightOptionsOpen,
          })}
        >
          <ConversationDropdown
            conversation={conversation}
            onChangeOpen={(isOpen: boolean) => setIsRightOptionsOpen(isOpen)}
          />
        </div>
      )
    }

    if (conversation.last_inbound_at || conversation.lastMessageAtValueOf) {
      const is_need_response = conversation.is_need_response && !store.isOutboundFilter
      return (
        <Time
          date={
            inbox?.color_coding_enabled && is_need_response
              ? conversation.last_inbound_at
              : conversation.lastMessageAtValueOf
          }
          position={'relative'}
          mode={EnumTimeMode.Live}
          status={
            conversation.isUnread && !store.isOutboundFilter
              ? EnumTimeStatus.Unread
              : EnumTimeStatus.Read
          }
          color_coding_enabled={inbox?.color_coding_enabled}
          is_need_response={is_need_response}
        />
      )
    }

    return null
  }

  const infoIcons = useMemo(() => {
    return (
      <>
        {conversation.message?.fromBroadcast && <Icon icon={'broadcasts'} fontSize={12} tertiary />}
        {conversation.priority && (
          <Icon icon={'flagFilled'} fontSize={12} color={'var(--content-attention-primary)'} />
        )}
      </>
    )
  }, [conversation.message, conversation.priority])

  const avatarMessageContent = useMemo(() => {
    if (isAutomated) {
      return (
        <div className={styles.avatarSmall}>
          <AutomatedAvatar size={'small'} />
        </div>
      )
    }
    return (
      isShowAvatar && (
        <div className={styles.avatarSmall}>
          <Avatar size={16} info={user.avatarInfo} />
        </div>
      )
    )
  }, [isShowAvatar, isAutomated, user])

  const showInboxName =
    inboxesStore.currentInboxType === TYPES_INBOX.GROUP ||
    inboxesStore.currentInboxId === 0 ||
    store.conversationSearchStore.hasSearchParams

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.active]: isActive,
        [styles.isOptOut]: isOptOut,
        [styles.selected]: conversation.checked,
        [styles.hoveredCard]: isRightOptionsOpen,
        [styles.closed]: conversation.isClosed && store.conversationSearchStore.hasSearchParams,
      })}
      onClick={openConversation}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      aria-label={getAriaLabel('ConversationCard')}
    >
      <div ref={refAvatar} className={styles.avatar}>
        <div className={styles.renderAvatar}>
          <AvatarGroup size={32} items={contactsAvatarInfo} />
        </div>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            checked={conversation.checked}
            onChangeValue={() => conversation.handleChecked()}
            size={32}
          />
        </div>
      </div>
      <div className={classnames(styles.info)}>
        <div className={styles.username} aria-label={getAriaLabel('ConversationCard_name')}>
          {conversation?.title}
        </div>

        <div className={styles.text} aria-label={getAriaLabel('ConversationCard_lastMessage')}>
          {avatarMessageContent}
          <div
            className={classnames(styles.indicator, {
              [styles.indicator__show]: conversation?.message?.isNote,
              [styles.indicator__isNote]: conversation?.message?.isNote,
            })}
          />
          <ConversationMessage item={conversation} />
        </div>
        {inbox && showInboxName && <InboxTag inbox={inbox} />}
        <ScheduleCount scheduleCount={conversation.scheduleCount} />
      </div>
      <div className={styles.date}>
        {renderDate()}
        <div className={styles.infoItems}>
          <div className={styles.infoIcon}>{infoIcons}</div>

          <div className={styles.count}>
            {conversation?.isUnreadManual && <Badge isEmpty />}
            {conversation?.isUnread && <Badge content={conversation?.unreadCount} />}
          </div>
        </div>
      </div>
    </div>
  )
})
