import { observer } from 'mobx-react-lite'
import { Alert } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { useBroadcastViewContext } from 'widgets/BroadcastView/context/broadcastViewContext'

export const DailyLimitAlert = observer(() => {
  const { number, broadcast_limits_package_count } = useBroadcastViewContext()

  if (!number) return null
  if (number.isShortCode) return null

  return (
    <Alert
      marginTop={16}
      item={{
        type: 'infoBlue',
        desc: (
          <>
            Based on the selected number, you can send the message <br />
            to up to {numberFormat({ value: broadcast_limits_package_count })} contacts per
            broadcast.
          </>
        ),
      }}
    />
  )
})
