import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import WaveSurfer from 'wavesurfer.js'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record'
import { Typography } from 'shared/ui'
import { secondsToTime } from 'shared/lib/secondsToTime'
import styles from 'widgets/Record/ui/RecordActions/RecordPlayer/styles.module.scss'
import { RecordStore } from 'widgets/Record'

export const RecordAudioContent = observer(({ recordStore }: { recordStore: RecordStore }) => {
  const {
    AUDIO_RECORD_LIMIT,
    currentAudioRecordDuration,
    deviceSettingsStore: { device },
  } = recordStore
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null)
  const waveformElementRef = useRef<HTMLDivElement>(null)
  const recordRef = useRef<RecordPlugin | null>(null)

  useEffect(() => {
    if (waveformElementRef.current) {
      if (wavesurfer) {
        wavesurfer.destroy()
      }
      //
      const w = WaveSurfer.create({
        barWidth: 2,
        barRadius: 2,
        barGap: 1,
        height: 24,
        cursorWidth: 0,
        cursorColor: '#068FF9',
        container: waveformElementRef.current,
        waveColor: '#068FF9',
        progressColor: '#ACB3B9',
      })

      setWavesurfer(w)

      // Initialize the Record plugin

      recordRef.current = w.registerPlugin(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        RecordPlugin.create({ scrollingWaveform: false, renderRecordedAudio: false })
      )

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      recordRef.current?.startRecording({ deviceId: device.audioInputId })
    }
  }, [waveformElementRef])

  useEffect(() => {
    return () => {
      recordRef.current?.stopMic()
      recordRef.current?.stopRecording()
    }
  }, [])

  return (
    <>
      <div ref={waveformElementRef} />
      <Typography
        className={styles.time}
        variant={'body-md-regular'}
        tag={'div'}
        ariaLabel={'time'}
      >
        {secondsToTime(currentAudioRecordDuration)} / {secondsToTime(AUDIO_RECORD_LIMIT)}
      </Typography>
    </>
  )
})
