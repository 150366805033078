import { observer } from 'mobx-react-lite'
import { useChatbotContext } from 'pages/chatbot/context'

import { CommonTextarea } from './CommonTextarea'

export const PointAboutConversation = observer(() => {
  const {
    errors,
    model: { settings },
  } = useChatbotContext()

  const aboutConversationError = errors.getError('settings.about_conversation.value')

  const handleChange = (value: string) => {
    settings.setAboutConversation(value)
    errors.removeError('settings.about_conversation')
  }

  return (
    <CommonTextarea
      limit={2333}
      name='Explain the topic of the conversation:'
      tooltip='Give a little context on the conversation and why it’s happening'
      placeholder='This conversation is about qualifying a lead by collecting their email, address, and required roofing services'
      error={aboutConversationError}
      value={settings.aboutConversation}
      onChange={handleChange}
    />
  )
})
