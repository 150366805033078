import { RefObject, useEffect, useState } from 'react'
import { MediaControllerStore } from 'widgets/PresentationMode/store/MediaControllerStore'

export function useMediaController({
  mediaControllerStore,
  setDisabledOverlayClick,
  ref,
}: {
  mediaControllerStore: MediaControllerStore
  setDisabledOverlayClick?: (value: boolean) => void
  ref: RefObject<HTMLAudioElement>
}) {
  const {
    isPlay,
    isLoaded,
    toTime,
    volume,
    speed,
    onEnded,
    setDuration,
    setCurrentTime,
    setLoaded,
  } = mediaControllerStore
  const [idInterval, setIdInterval] = useState<NodeJS.Timer | null>(null)

  const onLoadedData = () => {
    if (ref.current) {
      if (ref.current?.duration === Infinity) {
        ref.current.currentTime = Number.MAX_SAFE_INTEGER
        ref.current.ontimeupdate = () => {
          if (ref.current) {
            ref.current.ontimeupdate = null
            setDuration(ref.current.duration)
            setLoaded()
            ref.current.currentTime = 0
          }
        }
      } else {
        setDuration(ref.current.duration)
        setLoaded()
      }
    }
  }

  const onMouseEnter = () => {
    setDisabledOverlayClick && setDisabledOverlayClick(true)
  }
  const onMouseLeave = () => {
    setDisabledOverlayClick && setDisabledOverlayClick(false)
  }

  const onPlay = () => {
    const id = setInterval(() => {
      if (ref.current) {
        setCurrentTime(ref.current.currentTime)
      }
    }, 100)
    setIdInterval(id)
  }
  const onPause = () => {
    if (idInterval) {
      clearInterval(idInterval)
    }
  }

  useEffect(() => {
    if (ref.current && isLoaded) {
      if (isPlay) {
        ref.current.play()
        onPlay()
      } else {
        onPause()
        ref.current.pause()
      }
    }
  }, [isPlay, ref, isLoaded])

  const handelEnded = () => {
    onEnded()
  }

  useEffect(() => {
    if (toTime && ref.current) {
      ref.current.currentTime = toTime
    }
  }, [toTime])

  useEffect(() => {
    if (ref.current) {
      ref.current.volume = volume / 100
    }
  }, [volume])

  useEffect(() => {
    if (speed && ref.current) {
      ref.current.playbackRate = speed
    }
  }, [speed])

  return {
    handelEnded,
    onMouseEnter,
    onMouseLeave,
    onLoadedData,
  }
}
