import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Typography, Icon, Avatar } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { type IUser } from 'entities/Users'
import styles from './styles.module.scss'

export type IConversationInfoProps = {
  member: IUser | null
  active: boolean
  ariaLabel?: string
  onClose: () => void
  onReassign: (id: number | null) => void
}

export const InboxMember: FC<IConversationInfoProps> = observer(
  ({ member, active, ariaLabel, onClose, onReassign }) => {
    const ariaLabelInboxMember = ariaLabel ? getAriaLabel(ariaLabel) : ''
    const handleReassign = async (id: number | null) => {
      if (active) return

      onClose()
      onReassign(id)
    }

    if (!member) {
      return (
        <div
          className={classnames(styles.memberWrapper, {
            [styles.active]: active,
          })}
          aria-label={ariaLabelInboxMember}
          onClick={() => handleReassign(null)}
        >
          <div className={classnames(styles.info)}>
            <div className={styles.icon}>
              <Icon icon='userLine' color='var(--content-primary-tertiary)' />
            </div>
            <Typography
              className={styles.name}
              variant='body-md-regular'
              color='var(--content-primary-primary)'
              pointer
              ariaLabel={'Unassigned'}
            >
              Unassigned
            </Typography>
          </div>
          {active && <Icon icon='check1' color='var(--content-brand-primary)' />}
        </div>
      )
    }

    return (
      <div
        className={classnames(styles.memberWrapper, {
          [styles.active]: active,
        })}
        aria-label={ariaLabelInboxMember}
        onClick={() => handleReassign(member.id)}
      >
        <div className={classnames(styles.info)}>
          <Avatar info={member.avatarInfo} size={16} />
          <Typography
            className={styles.memberName}
            variant='body-md-regular'
            color='var(--content-primary-primary)'
            pointer
            ellipsis
            ariaLabel={'name'}
          >
            {member.name}
          </Typography>
        </div>
        {active && <Icon icon='check1' color='var(--content-brand-primary)' />}
      </div>
    )
  }
)
