// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CFDkZ64Tca7CYfB6yItv{display:flex;align-items:center;justify-content:center;width:44px;height:40px;border-radius:6px;margin-bottom:2px;font-size:18px;color:var(--content-primary-tertiary);cursor:pointer}.CFDkZ64Tca7CYfB6yItv.EIXpKouBpkhB3Mc8qNK9,.CFDkZ64Tca7CYfB6yItv:hover{background:var(--background-primary-inverted-primary);box-shadow:0 4px 12px -2px rgba(18,19,23,.03);color:var(--content-brand-primary)}.YwWeT6RuwwDidzd3Lfha{height:100%}.YwWeT6RuwwDidzd3Lfha .V5PprvyyIjk82u5ISdS8{border:none;height:100%}", "",{"version":3,"sources":["webpack://./src/widgets/Sidebar/ui/ProductUpdatesDropdown/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,qCAAA,CACA,cAAA,CAEA,uEAEE,qDAAA,CACA,6CAAA,CACA,kCAAA,CAIJ,sBACE,WAAA,CAEA,4CACE,WAAA,CACA,WAAA","sourcesContent":[".button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 44px;\n  height: 40px;\n  border-radius: 6px;\n  margin-bottom: 2px;\n  font-size: 18px;\n  color: var(--content-primary-tertiary);\n  cursor: pointer;\n\n  &.active,\n  &:hover {\n    background: var(--background-primary-inverted-primary);\n    box-shadow: 0 4px 12px -2px rgba(18, 19, 23, 0.03);\n    color: var(--content-brand-primary);\n  }\n}\n\n.iframeWrapper {\n  height: 100%;\n\n  .iframe {\n    border: none;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "CFDkZ64Tca7CYfB6yItv",
	"active": "EIXpKouBpkhB3Mc8qNK9",
	"iframeWrapper": "YwWeT6RuwwDidzd3Lfha",
	"iframe": "V5PprvyyIjk82u5ISdS8"
};
export default ___CSS_LOADER_EXPORT___;
