import type { IIconsVector } from 'shared/ui'
import type { IAlert } from 'shared/ui/Alert/types'
import type { Contact } from 'entities/Contacts/model/Contact'
import type { IParamsCreateSummaryAnswer } from 'entities/AIAssistant'
import type { IntegrationDealProperty } from 'entities/Integrations/model'
import type { IntegrationCompanyProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationCompanyProperty'
import type { IntegrationTicketProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationTicketProperty'
import type { IntegrationOpportunityProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationOpportunityProperty'

export type IContactProfileCard = {
  onClickMessage?: (contact: Contact) => void
  onClickCall?: () => void
  onActionCall?: () => void
  enableChat?: boolean
  enableCall?: boolean
  resetActiveAndCloseDetailsIsMobile?: () => void
  handleOptIn?: (contact: Contact) => void
}

export type IContactsDetailsVariant =
  | 'conversation'
  | 'contacts'
  | 'create_contact_modal'
  | 'open_conversation'

export type IContactsDetailsStoreConfig = {
  contactId?: number | null
  conversationId?: number | null
  inboxId?: number | null

  profileCardProps?: IContactProfileCard
  headerProps?: {
    iconShow?: IIconsVector
    iconHide?: IIconsVector
    textHide?: string
    textShow?: string
  }
  notesProps?: {
    hideMessageFieldActions: ('ai_assistant' | 'emoji')[]
  }
  onUpdateContact?: (contact: Contact) => void
  onCloseContactDetails?: () => void
  onStopCreateNewContact?: () => void
  emitOpenConversationSignal?: () => void
  onDeleteContactsDetails?: () => void
  addNewContactLocallyAfterCreate?: (contact: Contact, params?: { isNew?: boolean }) => void
  variant: IContactsDetailsVariant
  sub_source?: string
  resetAlert?: () => void
  onChangeIsFirstEditMode?: (value: boolean) => void
  resetActiveAndCloseDetailsIsMobile?: () => void
  handleOptIn?: (contact: Contact) => void
  getParamsCreateSummaryAnswer?: () => IParamsCreateSummaryAnswer
  onChangeAlert?: (item: IAlert | null) => void
}

export type IViewTypes =
  | 'notes'
  | 'deals'
  | 'main'
  | 'companies'
  | 'opportunities'
  | 'conversations-history'
  | 'quick-links'
  | 'tickets'

export enum EnumIntegrationTabs {
  Contact = 'contact',
  Account = 'account',
  Deals = 'deals',
  Companies = 'companies',
  Company = 'company',
  Opportunities = 'opportunities',
  Organization = 'organization',
  Tickets = 'tickets',
}

export type IViewAllData = {
  deals: IntegrationDealProperty[]
  companies: IntegrationCompanyProperty[]
  opportunities: IntegrationOpportunityProperty[]
  tickets: IntegrationTicketProperty[]
}

export type IViewAllDataProps = {
  deals?: IntegrationDealProperty[]
  companies?: IntegrationCompanyProperty[]
  opportunities?: IntegrationOpportunityProperty[]
  tickets?: IntegrationTicketProperty[]
}

export type IOpenContactsDetailsSource =
  | 'contacts'
  | 'call history'
  | 'textbot'
  | 'broadcast'
  | 'trigger'
  | 'keywords'
  | 'campaign'

export type IOpenContactsDetailsData = {
  contactId: number
  inboxId?: number
}

export type IOpenConversationsDetailsData = {
  contactId?: number
  inboxId?: number
  conversationId?: number
}

export type IOpenContactsDetails = {
  disabled: boolean
  open: (data: IOpenContactsDetailsData) => void
}

export type IOpenConversationDetails = {
  disabled: boolean
  open: (data: IOpenConversationsDetailsData) => void
}

export type IContactsDetailsManageStoreConfig = {
  emitOpenConversationSignal?: () => void
}
