import { makeAutoObservable, toJS } from 'mobx'

import { IChatbotField } from './type'

export class ChatbotFields {
  list: ChatbotField[] = []

  constructor(items?: IChatbotField[] | null) {
    if (!!items) this.syncOrigin(items)

    makeAutoObservable(this)
  }

  reorderFields(source: number, target: number) {
    const items = Array.from(this.list)
    const [item] = items.splice(source, 1)

    items.splice(target, 0, item)

    this.list = items
  }

  createField() {
    const field = new ChatbotField()

    this.list = [...this.list, field]
  }

  updateField(index: number, item: IChatbotField) {
    const items = Array.from(this.list)

    items[index]?.syncOrigin(item)

    this.list = items
  }

  removeField(index: number) {
    const items = Array.from(this.list)

    items.splice(index, 1)

    this.list = items
  }

  syncOrigin(items?: IChatbotField[] | null) {
    this.list = items?.map((item) => new ChatbotField(item)) ?? []
  }

  toJSON(): IChatbotField[] {
    return toJS(this.list.map((item) => item.toJSON()))
  }

  reset() {
    this.list = [new ChatbotField()]
  }
}

export class ChatbotField {
  static Create(item?: IChatbotField) {
    return new ChatbotField(item)
  }

  name = ''
  key = ''
  type = ''

  constructor(item?: IChatbotField) {
    if (!!item) this.syncOrigin(item)

    makeAutoObservable(this)
  }

  setKey(key: string) {
    this.key = key
  }

  setName(name: string) {
    this.name = name
  }

  syncOrigin(item?: Partial<IChatbotField> | null) {
    this.name = item?.name ?? this.name
    this.key = item?.key ?? this.key
    this.type = item?.type ?? this.type
  }

  toJSON(): IChatbotField {
    return toJS({
      name: this.name,
      key: this.key,
      type: this.type,
    })
  }

  reset() {
    this.key = ''
    this.name = ''
    this.type = ''
  }
}
