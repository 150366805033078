import React from 'react'

import { ComplianceTabs } from 'pages/admin/pages/compliance/ui/ComplianceTabs/ComplianceTabs'
import { TollFreeTable } from 'pages/admin/pages/compliance/pages/TollFree/ui/TollFreeTable/TollFreeTable'
import { TollFreeHeader } from 'pages/admin/pages/compliance/pages/TollFree/ui/TollFreeHeader/TollFreeHeader'
import { TollFreeSearch } from 'pages/admin/pages/compliance/pages/TollFree/ui/TollFreeSearch/TollFreeSearch'

export const TollFree = () => {
  return (
    <>
      <TollFreeHeader />
      <TollFreeSearch />
      <ComplianceTabs />
      <TollFreeTable />
    </>
  )
}
