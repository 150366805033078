import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Message } from 'entities/Message/model/Message'
import { MessageActionTooltip } from 'widgets/MessageCard/ui/MessageActionTooltip/MessageActionTooltip'
import { MessageSenderContent } from 'widgets/MessageCard/ui/MessageSenderContent/MessageSenderContent'
import { Mentions } from './../Mentions/Mentions'
import styles from './styles.module.scss'
import { IMessageCardType } from '../types'

type ConversationMessageCardItemProps = {
  message: Message
  type: IMessageCardType
  children: ReactNode
  className?: string | null
}

export const ConversationMessageCardItem: FC<ConversationMessageCardItemProps> = observer(
  ({ message, type, children, className }) => {
    const {
      isOutbound,
      is_schedule,
      isNote,
      undoPending,
      isConversationsHistory,
      isExternalConversation,
      isFailed,
    } = message

    return (
      <>
        <MessageActionTooltip message={message} type={type}>
          <div
            className={classNames(
              styles.message,
              {
                [styles.outbox]: isOutbound,
                [styles.schedule]: is_schedule,
                [styles.note]: isNote,
                [styles.undoPending]: undoPending,
                [styles.gifPreview]: type === 'gif-preview',
                [styles.conversationsHistoryView]: isConversationsHistory,
                [styles.external]: isExternalConversation && !isNote,
                [styles.failed]: isFailed,
              },
              className
            )}
            data-name={'message'}
          >
            <MessageSenderContent />
            {children}
          </div>
        </MessageActionTooltip>
        <Mentions message={message} />
      </>
    )
  }
)
