import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { conversationStore } from 'entities/Conversation'
import { IResponseConversationUnread } from 'entities/Conversation/api/types'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { Message } from 'entities/Message/model/Message'
import { inboxesStore } from 'entities/Inbox'
import { filtersStore } from 'features/DropdownFilter'
import { ConversationListStore } from 'widgets/ConversationList'

export const EventConversationUnread = {
  subscribe: (handler: (data: IResponseConversationUnread) => void) =>
    websocket.on(WebsocketEvent.ConversationUnread, handler),
  unsubscribe: (handler: (data: IResponseConversationUnread) => void) =>
    websocket.off(WebsocketEvent.ConversationUnread, handler),
}

export const useEventConversationUnread = (conversationListStore: ConversationListStore) => {
  const handler = (data: IResponseConversationUnread) => {
    const item = new Conversation(data.conversation)
    const message = new Message(data.conversation.recent_message)

    if (conversationListStore.conversationSearchStore.isActive) {
      const conversation = conversationStore.getItem(data.conversation_id)

      if (conversation) {
        conversationStore.updateItem(data.conversation)
      }

      return
    }
    if (
      inboxesStore.currentInbox?.type === 'inbox' &&
      item.inbox_id !== inboxesStore.currentInboxId
    )
      return
    if (!filtersStore.isShowConversation(item, message)) return

    const checked = Boolean(conversationStore.getItem(data.conversation_id)?.checked)
    conversationStore.updateItem(data.conversation)
    conversationStore.getItem(data.conversation_id)?.handleChecked(checked)
  }

  useEffect(() => {
    EventConversationUnread.subscribe(handler)

    return () => {
      EventConversationUnread.unsubscribe(handler)
    }
  }, [])
}
