import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import {
  IResponseNumber,
  IResponseNumberShortcodes,
  IResponseNumberSearch,
  IParamsNumberSearch,
  IParamsNumbers,
  IResponseNumbers,
  IParamsAddNumber,
  IParamsNumbersPool,
  IResponseNumbersPool,
  IParamsUpdateNumberPtf,
  IResponsePtfNumber,
  IResponseNumberVendorIntegrations,
} from 'entities/Phone/api/types'

class Api {
  getOrganizationNumber(): Promise<AxiosResponse<IResponseNumber>> {
    return baseApi.get('organization/number')
  }

  getNumberVendorIntegrations(): Promise<AxiosResponse<IResponseNumberVendorIntegrations>> {
    return baseApi.get('numbers/vendors/integration')
  }

  getNumbersShortcodes(): Promise<AxiosResponse<IResponseNumberShortcodes[]>> {
    return baseApi.get('numbers/shortcodes')
  }

  getNumbersSearch(params: IParamsNumberSearch): Promise<AxiosResponse<IResponseNumberSearch[]>> {
    return baseApi.post('numbers/search', params)
  }

  getNumbers(params: IParamsNumbers): Promise<AxiosResponse<IResponseNumbers>> {
    return baseApi.get('numbers', { params })
  }

  getNumbersFromPool(params: IParamsNumbersPool): Promise<AxiosResponse<IResponseNumbersPool>> {
    return baseApi.get('numbers/get-from-pool', { params })
  }

  addNumber(params: IParamsAddNumber): Promise<AxiosResponse<IResponseNumber>> {
    return baseApi.post('numbers', params)
  }

  updateNumberPtf(params: IParamsUpdateNumberPtf): Promise<AxiosResponse<IResponsePtfNumber>> {
    return baseApi.post('numbers/pft', params)
  }
}

export const PhoneApi = new Api()
