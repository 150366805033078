import { AxiosError } from 'axios'
import { throttle } from 'lodash'
import { uiStore } from 'shared/store/uiStore'
import { authStore } from 'entities/Auth'
import { AuthApi } from 'entities/Auth/api'
import {
  removeTokenLocalStorage,
  setTokenHeaderFromViaConfig,
  setTokenLocalStorage,
} from 'entities/Auth/lib/setToken'
import { baseApi } from 'entities/Auth/lib'
import { errorLogger } from 'entities/EventLog'

const maxAge = 10000
const handleRefreshToken = async () => {
  try {
    const { data } = await AuthApi.updateAuthRefresh()
    const isBoolean = typeof data.is_login === 'boolean'

    if (isBoolean && !data.is_login) {
      removeTokenLocalStorage(authStore.isAssume)
      await authStore.logout()
      uiStore.changeRoute({
        path: '/',
      })
      window.location.reload()
    }

    if (isBoolean && data.is_login) {
      setTokenLocalStorage(data.token.access_token, authStore.isAssume)
    }
  } catch (e) {
    console.error(e)
  }
}

const handleLogout = async () => {
  try {
    removeTokenLocalStorage(authStore.isAssume)
    await authStore.logout()
    uiStore.changeRoute({
      path: '/',
    })
    window.location.reload()
  } catch (e) {
    console.error(e)
  }
}

const throttleHandleRefreshToken = throttle(handleRefreshToken, maxAge, { trailing: false })
const throttleHandleLogout = throttle(handleLogout, maxAge, { trailing: false })

export const rejectRefreshToken = async (error: AxiosError<{ auth_required: boolean }>) => {
  const response = error.response
  const authRequired = response?.data?.auth_required
  const isRefresh = authRequired === true
  const isLogout = authRequired === false
  const config = error?.config

  if (!config?.url?.includes('segment/v1/events')) {
    errorLogger(error)
  }

  if (config?.url?.includes('logger/v1/logs')) {
    return Promise.reject(error)
  }

  if (isRefresh) {
    await throttleHandleRefreshToken()

    if (config) {
      setTokenHeaderFromViaConfig(config)
    }

    return config ? baseApi.request(config) : null
  }

  if (isLogout) {
    await throttleHandleLogout()
  }
  return Promise.reject(error)
}
