import React from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp'
import { CallPopUpDefaultStore } from 'widgets/CallPopUp/store/callPopUpDefaultStore'
import styles from './styles.module.scss'

export const CallTransferPopUpButton: React.FC = observer(() => {
  const store = useCallPopUpDefaultContext()
  const {
    isTransfer,
    isTransferDisabled,
    handleTransferShow,
    isTransferLoading,
    innerRefTooltipCurrent,
    isConferenceParticipants,
  } = store as CallPopUpDefaultStore

  return (
    <div className={styles.wrap}>
      <IconButton
        size={'large'}
        color={'blue'}
        variant={'custom'}
        active={isTransfer}
        disabled={isTransferDisabled || isConferenceParticipants}
        loading={isTransferLoading}
        onClick={() => {
          handleTransferShow()
        }}
        spinnerLoaderProps={{
          determinatecolor: 'var(--gray-30)',
          indeterminatecolor: 'var(--gray-70)',
        }}
        iconProps={{
          icon: 'phoneTransfer',
        }}
        ariaLabel='CallTransfer_popUp'
        tooltipProps={{
          label: isTransfer ? 'Cancel' : 'Transfer call',
          placement: 'top',
          zIndex: 5001,
          PopperProps: {
            popperOptions: {
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: innerRefTooltipCurrent,
                  },
                },
              ],
            },
          },
        }}
      />
    </div>
  )
})
