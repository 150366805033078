export enum ChatbotRoutes {
  root = 'chatbot',
  all = 'all',
  new = 'new',
  analytic = 'analytic',
  steps = 'steps',
}

export enum AgentBookAppointmentRoutesSteps {
  settingAgent = 'setting-agent',
  connectCalendar = 'connect-calendar',
  actionsAgent = 'actions-agent',
  activateAgent = 'activate-agent',
}
