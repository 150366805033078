import { MouseEventHandler } from 'react'
import { EnumIconButtonShape, IconButton, IIconButtonProps } from 'shared/ui'

export const PlayBtn = ({
  isPlay,
  togglePlay,
  color = 'secondary',
  transparentBackground,
  size,
  fontSize,
  large,
  className,
  withoutTooltip,
  disabled,
}: {
  isPlay: boolean
  large?: boolean
  togglePlay: MouseEventHandler<HTMLButtonElement>
  color?: IIconButtonProps['color']
  size?: IIconButtonProps['size']
  fontSize?: IIconButtonProps['fontSize']
  disabled?: IIconButtonProps['disabled']
  transparentBackground?: boolean
  className?: string
  withoutTooltip?: boolean
}) => {
  return (
    <IconButton
      className={className}
      icon={isPlay ? (large ? 'pause1' : 'pause') : large ? 'play1' : 'play'}
      tooltipProps={{ label: !withoutTooltip && (isPlay ? 'Pause' : 'Play'), placement: 'top' }}
      color={color}
      fontSize={large ? 12 : fontSize}
      transparentBackground={large ? undefined : transparentBackground}
      shape={EnumIconButtonShape.Rounded}
      variant={large ? 'contained' : 'icon'}
      onClick={togglePlay}
      size={size}
      ariaLabel={`PlayBtn_${isPlay ? 'pause' : 'play'}`}
      disabled={disabled}
    />
  )
}
