import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { MergeFieldContent, MergeFieldProps } from 'widgets/MergeField'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'

export const MergeFieldsDropdownItem: FC<MergeFieldProps> = observer((props) => {
  const messageFieldStore = useMessageFieldContext()
  const onOpenMergeFieldContent = () => {
    messageFieldStore.setMoreDropdownComponent((onCloseMenu) => (
      <MergeFieldContent onCloseMenu={onCloseMenu} {...props} />
    ))
  }

  return (
    <Button
      typeBtn={'menuItem'}
      text={'Merge fields'}
      icon={'user'}
      onClick={onOpenMergeFieldContent}
      disabled={!props.contact}
    />
  )
})
