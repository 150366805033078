export type INumberFormatProps = {
  value: number
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  currency?: 'USD' | 'CAD' | string
}

export const numberFormat = ({
  value,
  maximumFractionDigits = undefined,
  minimumFractionDigits = undefined,
  currency,
}: INumberFormatProps) =>
  new Intl.NumberFormat('en-US', {
    style: currency ? 'currency' : undefined,
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value)

// use as numberCompactFormatter.format(val)
export const numberCompactFormatter = new Intl.NumberFormat('en', {
  notation: 'compact',
})

export const clampNumber = (
  value: number,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER
) => Math.max(min, Math.min(value, max))
