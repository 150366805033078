export type INumbersInboxesListStoreConfig = {
  variant?: EnumNumbersInboxesDropDown
  excludeAircall?: boolean
  excludeSIPTrunk?: boolean
  autoSelectInbox?: boolean
}

export enum EnumNumbersInboxesDropDown {
  Conversation = 'conversation',
  Call = 'call',
}
