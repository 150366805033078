import { makeAutoObservable } from 'mobx'
import { toastStore } from 'shared/ui'
import { subscriptionStore } from 'entities/Subscription'
import { organizationStore } from 'entities/Organization'
import { billingStore } from 'entities/Billing'
import { MessagesApi } from 'entities/Message'
import { usersStore } from 'entities/Users'
import { BuyCreditsModalStore } from 'widgets/BuyCreditsModal'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export class CallContentStore {
  private _buyCreditsModalStore = new BuyCreditsModalStore()

  constructor(private _conversationMessagesStore: ConversationMessagesStore) {
    makeAutoObservable(this)
  }

  get isOrganizationOutOfMessage(): boolean {
    const trialCredits = organizationStore.trialCredits
    const accountCredits = organizationStore.accountCredits
    const isTrial = subscriptionStore.isTrial

    if (isTrial && trialCredits <= 0) {
      return true
    }

    if (!isTrial && accountCredits <= 0) {
      if (billingStore.autorecharge?.is_active) return false
      return true
    }

    return false
  }

  checkOrganizationOutOfCredits = (): boolean => {
    const trialCredits = organizationStore.trialCredits
    const accountCredits = organizationStore.accountCredits
    const isTrial = subscriptionStore.isTrial
    const can_manage_billing_settings = usersStore.getUserPermissions('can_manage_billing_settings')

    if (isTrial && trialCredits <= 0) {
      toastStore.add({
        title: 'Your organization is out of message credits',
        type: 'error',
      })

      return true
    }

    if (!isTrial && accountCredits <= 0) {
      if (billingStore.autorecharge?.is_active) return false
      if (!can_manage_billing_settings) {
        toastStore.add({
          title: 'Your organization is out of message credits',
          type: 'error',
        })

        return true
      }

      this._buyCreditsModalStore.openModal()

      return true
    }

    return false
  }

  retryTranscript = async (id: number) => {
    if (!this.checkOrganizationOutOfCredits()) {
      try {
        this._conversationMessagesStore.updateTranscriptStatus(id, 'record')
        const { data } = await MessagesApi.manualMessagesByIdTranscript(id)

        this._conversationMessagesStore.updateTranscriptStatus(id, data.status)
        if (data.record) {
          this._conversationMessagesStore.updateRecordTranscriptStatus(
            id,
            data.record.transcript_status
          )
        }
      } catch (e) {
        console.error(e)
        this._conversationMessagesStore.updateTranscriptStatus(id, '')
      }
    }
  }
}
