import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  EmptyState,
  EnumSpinnerLoaderPosition,
  IRow,
  SpinnerLoader,
  Table,
  variantActionsProps,
} from 'shared/ui'
import { BroadcastRecurring, BroadcastRoutesEnum, IBroadcast } from 'entities/Broadcast'
import { useEventBroadcastCanceled } from 'entities/Broadcast/events/EventBroadcastCanceled'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { useBroadcastsListContext } from 'widgets/BroacastList/context'
import { EmptyStateBroadcasts } from 'widgets/BroacastList/ui/EmptyStateBroadcasts'
import { getBroadcastActions } from 'widgets/BroadcastActions'
import { getColumns } from './getColumns'
import styles from './styles.module.scss'

export const BroadcastsTable = observer(() => {
  const {
    type,
    hideExpandColumnsIds,
    total,
    items,
    onUpdateItem,
    loadData,
    onPaginationModelChange,
    loading,
    limit,
    page,
    tableStore,
    isEmptyState,
    isNoBroadcasts,
    onDelete,
  } = useBroadcastsListContext()

  const getRowLink = (row: IRow<IBroadcast>) =>
    `/${BroadcastRoutesEnum.broadcasts}/${
      row.status === 'draft' || row.status === 'scheduled'
        ? BroadcastRoutesEnum.edit
        : BroadcastRoutesEnum.view
    }/${row.id}`

  useEffect(() => {
    loadData()
  }, [])

  useEventBroadcastCanceled(onUpdateItem)

  const getRows = () => {
    return items.map((item) => {
      item.setActionsProps({
        ...variantActionsProps.table,
        actions: getBroadcastActions({
          broadcast: item,
          onSuccessDelete: () => onDelete([item.id]),
        }),
      })
      if (item instanceof BroadcastRecurring) {
        item.rows.map((row) => {
          row.setActionsProps({
            ...variantActionsProps.table,
            actions: getBroadcastActions({
              broadcast: row,
              onSuccessDelete: () => item.onDelete([row.id]),
            }),
          })
          return row
        })
      }
      return item
    })
  }

  if (isEmptyState) {
    return <EmptyStateBroadcasts />
  }
  if (isNoBroadcasts) {
    return <EmptyState text={'No broadcasts found'} noIcon />
  }
  if (loading) {
    return <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
  }
  if (!items.length) {
    return <NoResultsFound />
  }
  return (
    <div className={styles.wrap}>
      <Table
        store={tableStore}
        columns={getColumns(type)}
        pinnedColumnNames={['expandedColumn', 'name']}
        rows={getRows()}
        getRowLink={getRowLink}
        rowsCount={total}
        isLoading={loading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        limit={limit}
        startPaginationSize={10}
        hideExpandColumnsIds={hideExpandColumnsIds}
      />
    </div>
  )
})
