import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import {
  makeTextFieldAutoFocus,
  makeTextFieldClear,
  TextField,
  InputWithPostfix,
} from 'shared/ui/TextField'
import { getAriaLabel } from 'shared/lib'
import { Button, Icon } from 'shared/ui'
import type { RenameModalStore } from 'widgets/RenameModal/store'

import styles from './styles.module.scss'

type IRenameModalContentProps = {
  store: RenameModalStore
  withPostfix?: boolean
}

export const RenameModalContent: FC<IRenameModalContentProps> = observer(
  ({ store, withPostfix = false }) => {
    const {
      isNameLengthError,
      name,
      setName,
      onCloseModal,
      disabledSave,
      handleSave,
      element,
      loadingSave,
      secondaryAction,
    } = store

    return (
      <div className={styles.wrap}>
        <div className={styles.wrapInput}>
          <TextField
            Input={withPostfix ? InputWithPostfix : undefined}
            ariaLabel={getAriaLabel('RenameModal')}
            size='medium'
            variant='stroke'
            error={isNameLengthError ? 'Error' : undefined}
            value={name}
            onChange={setName}
            rightActions={[makeTextFieldClear()]}
            mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
          />
        </div>
        {isNameLengthError && (
          <div className={styles.errorContainer}>
            <Icon icon='alertTriangle' color='var(--red-60)' />
            <span>
              Please limit your {element} name to {`${store.maxNameLength}`} characters
            </span>
          </div>
        )}

        <div className={styles.actions}>
          <Button
            text={secondaryAction?.text || 'Cancel'}
            typeBtn='contained'
            contained='secondary'
            onClick={secondaryAction?.onAction || onCloseModal}
          />
          <Button
            text={'Save'}
            disabled={disabledSave}
            typeBtn='contained'
            loading={loadingSave}
            onClick={handleSave}
          />
        </div>
      </div>
    )
  }
)
