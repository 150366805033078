import {
  IRecordAudioContentProps,
  RecordAudioContent,
} from 'widgets/RecordAudio/ui/RecordAudioContent'
import { RecordAudioStore } from 'widgets/RecordAudio/store/recordAudioStore'
import { RecordAudioContext } from './context/context'

type IRecordAudioProps = {
  store: RecordAudioStore
} & IRecordAudioContentProps

export const RecordAudio = ({ store, ...props }: IRecordAudioProps) => (
  <RecordAudioContext.Provider value={store}>
    <RecordAudioContent {...props} />
  </RecordAudioContext.Provider>
)
