import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Button, Dropdown } from 'shared/ui'
import { type IHubspotPropertiesObjectType } from 'entities/Integrations'
import { NewPropertiesDropdownContext } from 'widgets/NewPropertiesDropdown/context/newPropertiesDropdownContext'
import { NewPropertiesDropdownContent } from 'widgets/NewPropertiesDropdown/ui/NewPropertiesDropdownContent'
import { type NewPropertiesDropdownStore } from 'widgets/NewPropertiesDropdown'

type INewHubspotPropertiesDropdownProps = {
  store: NewPropertiesDropdownStore
  objectType?: IHubspotPropertiesObjectType
  objectId?: number
}

export const NewPropertiesDropdown: FC<INewHubspotPropertiesDropdownProps> = observer(
  ({ store, objectType, objectId }) => {
    useEffect(() => {
      return () => {
        store.reset()
      }
    })

    return (
      <NewPropertiesDropdownContext.Provider value={store}>
        <Dropdown
          margin={-25}
          width={260}
          placement='bottom-start'
          triggerComponent={() => (
            <Button
              text={'Add property'}
              icon='add'
              typeBtn='text'
              size={'small'}
              disabled={store.disabledAddButton}
            />
          )}
          customComponent={(_, dropdownId) => (
            <NewPropertiesDropdownContent
              dropdownId={dropdownId}
              objectType={objectType}
              objectId={objectId}
            />
          )}
        />
      </NewPropertiesDropdownContext.Provider>
    )
  }
)
