import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { integrationsStore } from 'entities/Integrations'
import { contactsCleanupStore, type ContactsTableStore } from 'widgets/ContactsTable'
import { ContactsDetails, contactsDetailsGlobalStore } from 'widgets/ContactsDetails'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import { importContactsStore } from 'widgets/ContactsTable/store/ImportContactsStore'
import { contactsHistoryStore } from 'widgets/ContactsHistory/store/ContactsHistoryStore'
import { type ContactsMenuStore } from 'widgets/ContactsMenu'
import { type SegmentModalStore } from 'widgets/ContactsMenu/ui/SegmentModal'
import { ContactsContent } from './ContactsContent/ContactsContent'
import styles from './styles.module.scss'
import { useContactsDetailsStore } from '../hooks/useContactsDetailsStore'

type IContactsProps = {
  contactsMenuStore: ContactsMenuStore
  contactsTableStore: ContactsTableStore
  segmentModalStore: SegmentModalStore
}

export const Contacts: FC<IContactsProps> = observer(
  ({ contactsMenuStore, contactsTableStore, segmentModalStore }) => {
    const { activeContact, tableStore } = contactsTableStore
    const { isImportAllowed, getStatusImportContact } = importContactsStore
    const contactsDetailsStore = useContactsDetailsStore(activeContact, contactsTableStore)

    useEffect(() => {
      const disposeContactsFilterStore = contactsFilterStore.watchChanges()
      contactsTableStore.initReactions()
      integrationsStore.fetchHubspotLists()
      contactsFilterStore.initPreselectFilters()

      if (!contactsFilterStore.segmentBox.segment) {
        contactsTableStore.loadContacts()
      }

      contactsCleanupStore.init()
      contactsDetailsGlobalStore.handleSetVariant('contacts')
      contactsDetailsGlobalStore.handleChangeCollapse(true)
      !isImportAllowed && getStatusImportContact()
      contactsHistoryStore.loadImportHistory()

      return () => {
        disposeContactsFilterStore()
        contactsTableStore.dispose()
        contactsTableStore.resetNewContactMode()
        tableStore.resetSelected()
        contactsFilterStore.clearSegment()
        contactsTableStore.resetSorting()
      }
    }, [])

    return (
      <div className={styles.container}>
        <ContactsContent
          contactsMenuStore={contactsMenuStore}
          contactsTableStore={contactsTableStore}
          segmentModalStore={segmentModalStore}
        />
        {contactsDetailsStore ? <ContactsDetails store={contactsDetailsStore} /> : null}
      </div>
    )
  }
)
