import { runInAction } from 'mobx'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseConversationUnread } from 'entities/Conversation/api/types'
import { sidebarStore } from 'widgets/Sidebar'

const ConversationUnread = {
  handle: (data: IResponseConversationUnread) => {
    const { inboxesMap } = sidebarStore
    const inbox = inboxesMap.get(data.inbox_id)

    if (inbox) {
      runInAction(() => {
        inbox.unread = data.unread
        inbox.unread_messages_count = data.unread_messages_count
      })
    }
  },
}

export const EventConversationUnread = {
  subscribe: () => websocket.on(WebsocketEvent.ConversationUnread, ConversationUnread.handle),
  unsubscribe: () => websocket.off(WebsocketEvent.ConversationUnread, ConversationUnread.handle),
}
