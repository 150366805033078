import React from 'react'
import { Avatar, IAvatarInfo } from 'shared/ui/Avatar'
import styles from './styles.module.scss'

type IAvatarGroupInLineProps = {
  items?: (IAvatarInfo | undefined)[]
  size?: number
  maxCount?: number
}

export const AvatarGroupInLine: React.FC<IAvatarGroupInLineProps> = ({
  items = [],
  size = 40,
  maxCount = 3,
}) => {
  const marginRight =
    items.length > 1
      ? items.length >= maxCount
        ? (size - 6) * maxCount - size + 6
        : (size - 6) * items.length - size + 6
      : 0

  return (
    <div
      className={styles.group}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        flex: `0 0 ${size}px`,
        marginRight: `${marginRight}px`,
      }}
    >
      {items.map((item, index) => {
        if (!item) return null
        if (index > maxCount - 1) return null
        return <Avatar key={index} info={item} size={size} is_full_width />
      })}
    </div>
  )
}
