// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X27Z2PoaOmbzrFETAalH{justify-content:flex-start;width:100%}.nADzaVxjHNreRUvk8oZv{padding:4px 8px;background-color:var(--background-primary-inverted-primary);border:1px var(--border-neutral-default) solid;border-radius:6px;max-width:100px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/entities/Tags/ui/CreateTagButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,0BAAA,CACA,UAAA,CAEA,sBACI,eAAA,CACA,2DAAA,CACA,8CAAA,CACA,iBAAA,CACA,eAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".createButton {\n    justify-content: flex-start;\n    width: 100%;\n\n    &Label {\n        padding: 4px 8px;\n        background-color: var(--background-primary-inverted-primary);\n        border: 1px var(--border-neutral-default) solid;\n        border-radius: 6px;\n        max-width: 100px;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createButton": "X27Z2PoaOmbzrFETAalH",
	"createButtonLabel": "nADzaVxjHNreRUvk8oZv"
};
export default ___CSS_LOADER_EXPORT___;
