import { observer } from 'mobx-react-lite'
import { PlayBtn } from 'shared/ui/Button/ui/PlayBtn/PlayBtn'
import { Slider, Typography } from 'shared/ui'
import { secondsToTime } from 'shared/lib/secondsToTime'
import styles from 'widgets/Record/ui/RecordActions/RecordPlayer/styles.module.scss'
import { RecordStore } from 'widgets/Record'

export const VideoContent = observer(({ recordStore }: { recordStore: RecordStore }) => {
  const { mediaControllerStore } = recordStore

  const { isPlay, currentTime, togglePlay, progress, setProgress, duration } = mediaControllerStore

  const handleChange = (event: Event, newValue: number | number[]) => {
    setProgress(newValue as number)
  }
  return (
    <>
      <PlayBtn
        isPlay={isPlay}
        togglePlay={togglePlay}
        color={'primary'}
        transparentBackground
        size={'small'}
        fontSize={16}
      />
      <Slider
        value={progress}
        onChange={handleChange}
        noThumb
        height={6}
        railBackgroundColor={'var(--black-20, rgba(0, 0, 0, 0.10))'}
      />
      <Typography
        variant={'body-md-regular'}
        className={styles.time}
        tag={'div'}
        ariaLabel={'time'}
      >
        {secondsToTime(isPlay ? currentTime : currentTime || duration)}
      </Typography>
    </>
  )
})
