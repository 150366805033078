// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nnOhWKYtueOUZC2q2fw9{display:flex;align-items:center}", "",{"version":3,"sources":["webpack://./src/shared/ui/TextField/ActionsMain/TextFieldActionTooltip/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA","sourcesContent":[".errorTooltip {\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorTooltip": "nnOhWKYtueOUZC2q2fw9"
};
export default ___CSS_LOADER_EXPORT___;
