import { FC, useState, ReactNode } from 'react'
import { Badge, Icon, Typography } from 'shared/ui'
import styles from './styles.module.scss'

type IAccordionSectionProps = {
  count: number
  title: string
  children: ReactNode
  renderButton?: ReactNode
  show?: boolean
}

export const AccordionSection: FC<IAccordionSectionProps> = ({
  count,
  title,
  children,
  renderButton,
  show,
}) => {
  const [open, setOpen] = useState(Boolean(show))
  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.section} onClick={handleToggle}>
        <div className={styles.box}>
          <Typography className={styles.title} variant={'body-md-medium'} ariaLabel={'Title'}>
            {title}
          </Typography>
          <Badge content={count} showAll />
        </div>
        <div className={styles.box}>
          {renderButton}

          <Icon
            icon={open ? 'chevronUp' : 'chevronDown'}
            color={'var(--content-primary-tertiary)'}
          />
        </div>
      </div>
      {open && children}
    </div>
  )
}
