import { makeAutoObservable, runInAction } from 'mobx'
import { logger, numberFormat } from 'shared/lib'
import {
  AdminOrganizationsApi,
  ICustomPayment,
  IResponseAdminOrganizationById,
  TeamMember,
} from 'entities/Admin/organizations'
import { AdminRoutes } from 'pages/admin'

class AdminOrganizationDetailStore {
  constructor() {
    makeAutoObservable(this)
  }

  id: number | null = null
  loading = false

  organization: IResponseAdminOrganizationById | null = null
  teamMembers: TeamMember[] = []
  customPayments: ICustomPayment[] = []

  get isBanned() {
    return this.organization?.status === 'banned'
  }

  get generalInfo() {
    return {
      id: this.organization?.id || '',
      ownerId: this.organization?.owner?.id || '',
      name: this.organization?.name || '',
      country: this.organization?.country?.label || '',
      status: this.organization?.status || '',
      is_contract: this.organization?.is_contract || false,
      is_agency: this.organization?.is_agency || false,
      is_franchise: this.organization?.is_franchise || false,
      is_messaging_canada_only: this.organization?.is_messaging_canada_only || false,
      is_10_dlc_exempt: this.organization?.is_10_dlc_exempt || false,
      master_plan: this.organization?.master_plan,
      agency_plan: this.organization?.agency_plan,
      totalCredits: this.organization?.credits?.account_credits || 0,
      trialCredits: this.organization?.credits?.trial_credits || 0,
      totalContacts: this.organization?.counts?.contacts_count || 0,
      totalUsers: this.organization?.counts?.users_count || 0,
      defaultNumberVendor: this.organization?.default_number_vendor,
      totalNumbers: this.totalNumbers,
      limits: this.limits,
      twilio: this.organization?.links?.twilio || '',
      stripe: this.organization?.links?.stripe || '',
      ip: this.organization?.ip || '',
    }
  }

  get customPlanUrl() {
    const planKey = this.organization?.stripe_data?.subscription?.stripe_plan?.key
    const customPlanId = Number.isInteger(planKey) ? planKey : undefined
    if (customPlanId) {
      return `/${AdminRoutes.admin}/${AdminRoutes.customPrices}/${customPlanId}`
    }
    return `/${AdminRoutes.admin}/${AdminRoutes.customPrices}`
  }

  get stripeInfo() {
    return {
      created_at: this.organization?.stripe_data?.subscription?.created_at || '',
      status: this.organization?.stripe_data?.subscription?.status,
      renewal_date: this.organization?.stripe_data?.subscription?.renewal_date,
      active_for: this.organization?.stripe_data?.subscription?.active_for || '',
      mrr: this.organization?.stripe_data?.mrr || 0,
      arr: this.organization?.stripe_data?.arr || 0,
      total_spend: this.organization?.stripe_data?.total_spend || 0,
      auto_recharge: this.organization?.stripe_data?.auto_recharge,
      plan: this.organization?.stripe_data?.subscription?.stripe_plan?.label || '',
      plan_interval: this.organization?.stripe_data?.subscription?.plan_interval || '',
      churned_date: this.organization?.stripe_data?.subscription?.churned_date || '',
      scheduled_plan: this.organization?.stripe_data?.subscription?.scheduled_plan || null,
      customPlanUrl: this.customPlanUrl,
    }
  }

  get renewalDate() {
    return this.stripeInfo.renewal_date
  }

  get isTrialOrganization() {
    return this.stripeInfo.status === 'trialing'
  }

  get isCanceledSubscription() {
    return this.stripeInfo.status === 'canceled'
  }

  get totalNumbers() {
    if (!this.organization) return ''
    const numbers = this.organization.numbers
    const total = this.organization.numbers?.total || 0
    if (!total) return 0
    const str = Object.entries(numbers)
      .filter(([key, value]) => key !== 'total' && value)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ')
    return `${total} (${str})`
  }

  get limits() {
    const longCode = this.organization?.broadcast_limits.broadcasts_long_code
      ? numberFormat({
          value: this.organization?.broadcast_limits.broadcasts_long_code,
        })
      : 'Default'
    const shortCode = this.organization?.broadcast_limits.broadcasts_short_code
      ? numberFormat({
          value: this.organization?.broadcast_limits.broadcasts_short_code,
        })
      : 'Default'
    const tollFree = this.organization?.broadcast_limits.broadcasts_toll_free
      ? numberFormat({
          value: this.organization?.broadcast_limits.broadcasts_toll_free,
        })
      : 'Default'
    const import_limits = this.organization?.import_limits
      ? numberFormat({
          value: this.organization?.import_limits,
        })
      : 'Default'
    return `broadcast: (long code: ${longCode}, short code: ${shortCode}, toll-free: ${tollFree}); contact import: ${import_limits}`
  }

  get autoRechargeInfo() {
    if (!this.organization?.stripe_data?.auto_recharge?.active) {
      return ''
    }
    const auto_recharge = this.organization?.stripe_data?.auto_recharge
    const additional_credit_price = Number(
      this.organization?.stripe_data?.subscription?.stripe_plan?.additional_credit_price || 0
    )
    return `-> (Below: ${auto_recharge?.threshold_in_credits} → Add: ${numberFormat({
      value: auto_recharge?.amount_in_credits || 0,
    })} / ${numberFormat({
      value: ((auto_recharge?.amount_in_credits || 0) * additional_credit_price) / 100,
      currency: 'USD',
    })})`
  }

  reset = () => {
    this.organization = null
    this.teamMembers = []
  }

  setId = (id: number) => {
    this.reset()
    this.id = id
    this.getData()
  }

  getData = async (isUpdate?: boolean) => {
    try {
      if (!this.id) {
        return
      }
      runInAction(() => {
        if (isUpdate) return
        this.loading = true
      })
      const { data } = await AdminOrganizationsApi.getOrganizationById(this.id)
      this.setOrganization(data)

      return data
    } catch (e) {
      logger.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  updateOrganization = () => this.getData(true)

  updateOrganizationWithPayments = () => {
    this.getData(true)
    this.fetchCustomPayments()
  }

  setOrganization = (organization: IResponseAdminOrganizationById | null) => {
    this.organization = organization
    if (organization?.users) {
      this.teamMembers = organization.users
        .sort((a) => (a.role === 'owner' ? -1 : 1))
        .map((user) => new TeamMember({ user }))
    }
  }

  fetchCustomPayments = async () => {
    try {
      const response = await AdminOrganizationsApi.getCustomPayments({ id: this.id! })

      runInAction(() => {
        this.customPayments = response.data.data
      })
    } catch (e) {
      logger.error(e)
    }
  }
}

export const adminOrganizationDetailStore = new AdminOrganizationDetailStore()
