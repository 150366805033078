// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hxvAc9_c0AioSTyYK2_P{display:flex;flex-direction:column}.hxvAc9_c0AioSTyYK2_P .q9WZ1huyO21iUqvzCYxn{display:flex;padding:16px 38px 16px 0;justify-content:flex-end;gap:8px;align-self:stretch;color:var(--content-primary-tertiary)}.hxvAc9_c0AioSTyYK2_P.ZGImHaK9XqfcoyDuMQOd{align-items:flex-end}.hxvAc9_c0AioSTyYK2_P.ZGImHaK9XqfcoyDuMQOd .q9WZ1huyO21iUqvzCYxn{padding:16px 0 16px 0}.hxvAc9_c0AioSTyYK2_P .MMSqPAChwYrZ3py0lX5O{display:flex;align-items:center}.hxvAc9_c0AioSTyYK2_P .fXWcvshzKi01oxb_PyDA{margin:0 6px 0 12px;color:var(--content-primary-tertiary)}.hxvAc9_c0AioSTyYK2_P .TZ0yssNCu3tLpQeZC5MU{margin-right:12px}.m9AMdrKxBD_LeRVVT6BH{text-align:right}.kG28umvoG94i_fKNoX0Q{white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/widgets/ActivityCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEA,4CACE,YAAA,CACA,wBAAA,CACA,wBAAA,CACA,OAAA,CACA,kBAAA,CACA,qCAAA,CAGF,2CACE,oBAAA,CAEA,iEACE,qBAAA,CAIJ,4CACE,YAAA,CACA,kBAAA,CAGF,4CACE,mBAAA,CACA,qCAAA,CAGF,4CACE,iBAAA,CAGJ,sBACE,gBAAA,CAEF,sBACE,kBAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n\n  .content {\n    display: flex;\n    padding: 16px 38px 16px 0;\n    justify-content: flex-end;\n    gap: 8px;\n    align-self: stretch;\n    color: var(--content-primary-tertiary);\n  }\n\n  &.conversationHistory {\n    align-items: flex-end;\n\n    .content {\n      padding: 16px 0 16px 0;\n    }\n  }\n\n  .avatarWrap {\n    display: flex;\n    align-items: center;\n  }\n\n  .icon {\n    margin: 0 6px 0 12px;\n    color: var(--content-primary-tertiary);\n  }\n\n  .name {\n    margin-right: 12px;\n  }\n}\n.text{\n  text-align: right;\n}\n.date{\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "hxvAc9_c0AioSTyYK2_P",
	"content": "q9WZ1huyO21iUqvzCYxn",
	"conversationHistory": "ZGImHaK9XqfcoyDuMQOd",
	"avatarWrap": "MMSqPAChwYrZ3py0lX5O",
	"icon": "fXWcvshzKi01oxb_PyDA",
	"name": "TZ0yssNCu3tLpQeZC5MU",
	"text": "m9AMdrKxBD_LeRVVT6BH",
	"date": "kG28umvoG94i_fKNoX0Q"
};
export default ___CSS_LOADER_EXPORT___;
