import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { Scrollbar } from 'shared/ui'
import { ISearchTypeItems, SearchTypesEnum, useSearchDropdownContext } from 'widgets/SearchDropdown'
import styles from './styles.module.scss'
import { TagsDropdownContentSearch } from './TagsDropdownContentSearch'
import { InboxesDropdownContentSearch } from './InboxesDropdownContentSearch'
import { AssigneeDropdownContentSearch } from './AssigneeDropdownContentSearch'
import { ContactsDropdownContentSearch } from './ContactsDropdownContentSearch'
import { SegmentsDropdownContentSearch } from './SegmentsDropdownContentSearch'

type IDropdownAllContentSearchItemsProps = {
  onCloseMenu?: () => void
  onSelect?: (item: ISearchTypeItems) => void
  maxHeight?: number
}

export const DropdownAllContentSearchItems: FC<IDropdownAllContentSearchItemsProps> = observer(
  ({ onCloseMenu, onSelect, maxHeight }) => {
    const {
      searchDropdownContactsStore,
      searchDropdownTagsStore,
      searchDropdownInboxesStore,
      searchDropdownAssigneeStore,
      searchDropdownSegmentStore,
      selectedTypes,
      includeItemsType,
    } = useSearchDropdownContext()
    const autoHeightMax = maxHeight ? maxHeight : 600

    return (
      <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={autoHeightMax}>
        <div
          className={classnames(styles.searchItems, {
            [styles['searchItems--disabled']]: searchDropdownContactsStore.creating,
          })}
        >
          {includeItemsType.map((key) => {
            const isVisible = (type: SearchTypesEnum) => {
              return key === type && !selectedTypes.includes(type)
            }

            if (isVisible(SearchTypesEnum.contacts)) {
              return (
                <ContactsDropdownContentSearch
                  key={key}
                  store={searchDropdownContactsStore}
                  onCloseMenu={onCloseMenu}
                  onSelect={onSelect}
                />
              )
            }

            if (isVisible(SearchTypesEnum.tags)) {
              return (
                <TagsDropdownContentSearch
                  key={key}
                  store={searchDropdownTagsStore}
                  onCloseMenu={onCloseMenu}
                  onSelect={onSelect}
                />
              )
            }

            if (isVisible(SearchTypesEnum.teams)) {
              return (
                <InboxesDropdownContentSearch
                  key={key}
                  store={searchDropdownInboxesStore}
                  onCloseMenu={onCloseMenu}
                  onSelect={onSelect}
                />
              )
            }

            if (isVisible(SearchTypesEnum.members)) {
              return (
                <AssigneeDropdownContentSearch
                  key={key}
                  store={searchDropdownAssigneeStore}
                  onCloseMenu={onCloseMenu}
                  onSelect={onSelect}
                />
              )
            }

            if (isVisible(SearchTypesEnum.segment)) {
              return (
                <SegmentsDropdownContentSearch
                  key={key}
                  store={searchDropdownSegmentStore}
                  onCloseMenu={onCloseMenu}
                  onSelect={onSelect}
                />
              )
            }
          })}
        </div>
      </Scrollbar>
    )
  }
)
