import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { AuthLayout } from 'shared/layout'
import { RegisterStore } from 'widgets/Register'
import { Step4Form } from './Step4Form/Step4Form'
import { Step4FormBottom } from './Step4FormBottom/Step4FormBottom'
import { RightContent } from './RightContent/RightContent'

type IStep1Props = {
  store: RegisterStore
}

export const Step4: FC<IStep1Props> = observer(({ store }) => {
  return (
    <AuthLayout
      title='Try Salesmsg for Free'
      subtitle={
        <>
          Enjoy a free 14-day trial, cancel anytime.
          <br />
          We’ll remind you before your trial ends.
        </>
      }
      form={<Step4Form store={store} />}
      formBottom={<Step4FormBottom />}
      rightContent={<RightContent />}
      onBack={store.handlePrevStep}
    />
  )
})

export default Step4
