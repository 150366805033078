import React from 'react'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import styles from '../styles.module.scss'

export const ConfirmationSend = observer(() => {
  const store = useConversationMessagesContext()
  const {
    isConversationNew,
    showDoubleOptInButton,
    handleContactOptInRequest,
    conversation,
    disabledDoubleOptIn,
    conversationNewSearchStore,
  } = store
  const disabled = Boolean(
    !conversation?.contactsIds?.filter((id) => id > 0).length || disabledDoubleOptIn
  )
  const onClick = async () => {
    if (isConversationNew) {
      await conversationNewSearchStore.handleCreateConversation({}, showDoubleOptInButton)
      await handleContactOptInRequest()
    } else {
      handleContactOptInRequest()
    }
  }

  return (
    <div className={styles.confirmationBtnWrap}>
      <Button text={'Send confirmation text'} disabled={disabled} onClick={onClick} />
    </div>
  )
})
