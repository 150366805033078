// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zojnw37WfP4KcnofUCJL{cursor:pointer;padding:8px;min-height:40px;display:flex;gap:8px;border-radius:6px;align-items:center}.Zojnw37WfP4KcnofUCJL.OJLZK6JwmK44S7FaCe4Y{background:var(--background-neutral-default)}.Zojnw37WfP4KcnofUCJL:hover{border-radius:6px;background:var(--background-neutral-subtle)}.Zojnw37WfP4KcnofUCJL .DvV6e1_k5NK6OeUV9XzP{width:100%;display:flex;align-items:center;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/shared/ui/InputRadio/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,OAAA,CACA,iBAAA,CACA,kBAAA,CAEA,2CACE,4CAAA,CAGF,4BACE,iBAAA,CACA,2CAAA,CAGF,4CACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":[".wrap {\n  cursor: pointer;\n  padding: 8px;\n  min-height: 40px;\n  display: flex;\n  gap: 8px;\n  border-radius: 6px;\n  align-items: center;\n\n  &.checked {\n    background: var(--background-neutral-default);\n  }\n\n  &:hover{\n    border-radius: 6px;\n    background: var(--background-neutral-subtle);\n  }\n\n  .label {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Zojnw37WfP4KcnofUCJL",
	"checked": "OJLZK6JwmK44S7FaCe4Y",
	"label": "DvV6e1_k5NK6OeUV9XzP"
};
export default ___CSS_LOADER_EXPORT___;
