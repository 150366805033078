import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Dropdown } from 'shared/ui'
import { Contact } from 'entities/Contacts/model/Contact'
import { type NumbersInboxesListStore } from 'widgets/NumbersInboxesList'
import {
  type IButtonProps,
  NumbersInboxesButton,
} from 'widgets/NumbersInboxesList/ui/NumbersInboxesButton'
import { NumbersInboxesList } from 'widgets/NumbersInboxesList/ui/NumbersInboxesList'
import styles from './styles.module.scss'

type INumbersInboxesDropDownContentProps = {
  store: NumbersInboxesListStore
  onChange?: (inboxId: number, numberId: number) => void
  contacts?: Contact[]
  buttonProps?: IButtonProps
}

export const NumbersInboxesDropDown: FC<INumbersInboxesDropDownContentProps> = observer(
  ({ contacts = [], onChange, buttonProps, store }) => {
    useEffect(() => {
      store.setPropertyOnChange((inboxId, numberId) => {
        onChange?.(inboxId, numberId)
      })
      store.reactionItems()
      store.fetchInboxes()

      return () => {
        store.reset()
      }
    }, [])

    useEffect(() => {
      store.setPropertyContacts(contacts)
    }, [contacts.length])

    return (
      <div className={styles.wrap}>
        <Dropdown
          ariaLabel={'NumbersInboxesDropDown'}
          placement={'bottom-start'}
          triggerComponent={() => <NumbersInboxesButton store={store} buttonProps={buttonProps} />}
          customComponent={() => <NumbersInboxesList store={store} />}
          width={297}
          margin={'5px'}
          onChangeOpen={store.setActive}
        />
      </div>
    )
  }
)
