import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { cloneDeep } from 'lodash'
import { logger } from 'shared/lib'
import { type IAlert } from 'shared/ui/Alert/types'
import { ContactsApi, contactsStore } from 'entities/Contacts'
import { type IResponseConversation } from 'entities/Conversation/api/types'
import { Contact } from 'entities/Contacts/model/Contact'
import { type IResponseContact } from 'entities/Contacts/api/types'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import { UsersApi } from 'entities/Users'
import { inboxesStore } from 'entities/Inbox'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { ContactsIntegrationsStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/store/contactsIntegrationsStore'
import { ContactNoteStore } from 'widgets/ContactsDetails/ui/ContactsNotesForm'
import { ContactInfoFormStore } from 'widgets/ContactsDetails/ui/ContactsInfoForm'
import {
  type IContactsDetailsStoreConfig,
  type IContactsDetailsVariant,
  type IViewTypes,
} from 'widgets/ContactsDetails/store/types'
import { ConversationsHistoryMessagesStore } from 'widgets/ConversationsHistoryMessages'
import { contactChoosePhoneModalStore } from 'widgets/ContactChoosePhoneModal'
import { ContactsConversationOpenStore } from 'widgets/ContactsDetails/ui/ContactsConversationOpen'
import { ContactsProfileCardStore } from 'widgets/ContactsDetails/ui/ContactsProfileCard'
import { ConversationInfoStore } from 'widgets/ContactsDetails/ui/ConversationInfo'
import { ContactsTagsFormStore } from 'widgets/ContactsDetails/ui/ContactsTagsForm'
import {
  contactsQuickLinksHeader,
  ContactsQuickLinksStore,
} from 'widgets/ContactsDetails/ui/ContactsQuickLinks'
import { ContactsGroupCardStore } from 'widgets/ContactsDetails/ui/ContactsGroupCard'
import { contactsDetailsGlobalStore } from './contactsDetailsGlobalStore'

const defaultDraggableItems = [
  'conversation_info',
  'contacts_group',
  'contacts_info',
  'contacts_integrations',
  'contacts_tags',
  'contacts_notes',
  'contacts_quick_links',
]

export class ContactsDetailsStore {
  private _id = nanoid()
  private _contactId: number | null = null
  private _conversationId: number | null = null
  private _inboxId: number | null = null

  private _variant: IContactsDetailsVariant = 'conversation'
  private _view: IViewTypes = 'main'

  private _contactsDetailsGlobalStore = contactsDetailsGlobalStore
  private _contactInfoFormStore = new ContactInfoFormStore(this)
  private _conversationsHistoryMessagesStore = new ConversationsHistoryMessagesStore(this)
  private _contactsConversationOpenStore = new ContactsConversationOpenStore(this)
  private _contactNoteStore = new ContactNoteStore(this)
  private _contactsProfileCardStore = new ContactsProfileCardStore(this)
  private _conversationInfoStore = new ConversationInfoStore(this)
  private _contactsTagsFormStore = new ContactsTagsFormStore(this)
  private _contactsQuickLinksStore = new ContactsQuickLinksStore(this)
  private _contactsGroupCardStore = new ContactsGroupCardStore(this)
  private _contactIntegrationsStore = new ContactsIntegrationsStore(this)

  private _disposeAlert: IReactionDisposer | null = null
  private _disposeContactsDetailsToggle: IReactionDisposer | null = null
  private _disposeContactUpdated: IReactionDisposer | null = null
  private _disposeConversation: IReactionDisposer | null = null
  private _disposeContacts: IReactionDisposer | null = null
  private _alert: IAlert | null = null
  private _config: IContactsDetailsStoreConfig | null = null
  private _draggableItems: string[] = defaultDraggableItems
  private _sectionsItems: string[] = defaultDraggableItems

  constructor(config: IContactsDetailsStoreConfig) {
    makeAutoObservable(this)

    this._contactId = typeof config.contactId === 'number' ? config.contactId : null
    this._conversationId = typeof config.conversationId === 'number' ? config.conversationId : null
    this._inboxId = config.inboxId || null
    this._variant = config.variant || 'conversation'
    this._view = 'main'
    this._config = config

    this._contactInfoFormStore.setConfig({
      onChangeAlert: (item) => {
        this.setAlert(item)
      },
      contactId: Number(config.contactId || config.conversationId),
      addNewContactLocallyAfterCreate: config.addNewContactLocallyAfterCreate,
    })

    contactChoosePhoneModalStore.setConfig({
      onChangeAlert: (item) => {
        this.setAlert(item)
      },
    })

    this._contactIntegrationsStore.setConfig({
      resetActiveAndCloseDetailsIsMobile: config.resetActiveAndCloseDetailsIsMobile,
    })

    this._contactsProfileCardStore.setConfig({
      contactId: this._contactId,
      contactsIds: this.contactsIds,
      variant: config.variant,
      resetActiveAndCloseDetailsIsMobile: config.resetActiveAndCloseDetailsIsMobile,
      handleOptIn: config.handleOptIn,
      openView: this.openView,
      enableChat: config.profileCardProps?.enableChat,
      enableCall: config.profileCardProps?.enableCall,
      onClickMessage: config.profileCardProps?.onClickMessage,
      onClickCall: config.profileCardProps?.onClickCall,
      onActionCall: config.profileCardProps?.onActionCall,
    })

    if (this._config?.sub_source) {
      this._contactsConversationOpenStore.setSubSource(this._config?.sub_source)
    }

    this.reactionConversation()
    this.reactionContacts()
    this.reactionContactsDetailsToggle()
    this.reactionContactUpdated()
    this.reactionAlert()
  }

  setConfig = (config: IContactsDetailsStoreConfig) => {
    this._config = config
  }

  get draggableItems() {
    return this._draggableItems
  }

  get sectionsItems() {
    return this._sectionsItems
  }

  get config() {
    return this._config
  }

  get notesProps() {
    return this._config?.notesProps
  }

  get headerProps() {
    return this._config?.headerProps
  }

  get profileCardProps() {
    return this._config?.profileCardProps
  }

  get contactsProfileCardStore() {
    return this._contactsProfileCardStore
  }

  get contactsTagsFormStore() {
    return this._contactsTagsFormStore
  }

  get conversationInfoStore() {
    return this._conversationInfoStore
  }

  get contactsQuickLinksStore() {
    return this._contactsQuickLinksStore
  }

  get contactsGroupCardStore() {
    return this._contactsGroupCardStore
  }

  get isVariantConversations() {
    return this._variant === 'conversation'
  }

  get isVariantContacts() {
    return this._variant === 'contacts'
  }

  get isVariantCreateContactModal() {
    return this._variant === 'create_contact_modal'
  }

  get isVariantOpenConversations() {
    return this._variant === 'open_conversation'
  }

  get isConversationNew() {
    if (!this.conversation) return true

    return this.conversation?.isNew
  }

  get isViewAll() {
    if (this._view === 'main') return false

    return (
      this._view === 'companies' ||
      this._view === 'deals' ||
      this._view === 'notes' ||
      this._view === 'opportunities' ||
      this._view === 'tickets'
    )
  }

  get conversation() {
    if (this._conversationId === null) return

    return conversationStore.getItem(this._conversationId)
  }

  get id() {
    return this._id
  }

  get contactNoteStore() {
    return this._contactNoteStore
  }

  get view() {
    return this._view
  }

  load = () => {
    if (this._conversationId) {
      this.loadConversation()
    } else {
      this.loadContact()
    }
  }

  loadContact = async () => {
    if (!this._contactId) return
    if (this._contactId <= 0) return

    try {
      let contact = contactsStore.getItem(this._contactId)

      if (!this._conversationId) {
        const inboxIds: number[] = []

        if (this._inboxId) {
          inboxIds.push(this._inboxId)
        }

        const conversationResponse = await ConversationsApi.getConversationsSearch({
          tags: [],
          teams: inboxIds,
          members: [],
          contacts: [this._contactId],
          page: 1,
        }).then((data) => data.data.results[0])

        if (conversationResponse) {
          this._conversationId = conversationResponse.id

          const conversation = conversationStore.addItem({
            ...conversationResponse,
            is_locked: false,
          })
          contact = contactsStore.getItem(conversationResponse.contact_id)

          this._contactsConversationOpenStore.setConversationId(conversation.id)
          this.setConversationId(conversation.id)
          conversationStore.setCurrentItemId(conversation.id)
        } else {
          if (inboxesStore.currentInbox?.type === 'inbox') {
            this._contactsConversationOpenStore.handleInbox(inboxesStore.currentInbox)
          } else if (inboxesStore.sharedInboxes.length === 1) {
            this._contactsConversationOpenStore.handleInbox(inboxesStore.sharedInboxes[0])
          }
        }
      }

      if (!contact) {
        const response = await ContactsApi.getContactById(this._contactId).then((data) => data.data)
        contact = contactsStore.addItem(response)
      }

      if (contact) {
        this.setContact(contact)
      }
    } catch (e) {
      console.log(e)
    }
  }

  loadConversation = async () => {
    if (!this._conversationId) return
    if (this._conversationId <= 0) return

    try {
      let conversation = conversationStore.getItem(this._conversationId)

      if (!conversation) {
        const response = await ConversationsApi.getById(this._conversationId).then(
          (data) => data.data
        )
        conversation = conversationStore.addItem(response)
      }

      if (conversation) {
        this._contactId = conversation.contact_id

        const contact = contactsStore.getItem(this._contactId)

        if (contact) {
          this.setContact(contact)
        }

        this._contactsConversationOpenStore.setConversationId(conversation.id)
        this.setConversationId(conversation.id)
      }
    } catch (e) {
      console.log(e)
    }
  }

  setConversationId = (conversationId: number | null) => {
    this._conversationId = conversationId
  }

  openView = (viewType: IViewTypes) => {
    this._view = viewType
  }

  closeView = () => {
    this._view = 'main'
  }

  setContact = (item: Contact) => {
    const contact = item

    this._contactId = contact.id
    this._contactsProfileCardStore.setContactId(contact.id)
    this._contactInfoFormStore.updateContactInfo(contact.id)
  }

  handleUpdateContact = (item: IResponseContact) => {
    contactsStore.updateItem(item)

    const contact = contactsStore.getItem(item.id)

    if (contact) {
      this._contactId = contact.id
      this._config?.onUpdateContact?.(contact)
    }
  }

  handleUpdateConversation = (item: IResponseConversation) => {
    const conversation = conversationStore.getItem(item.id)

    if (conversation) {
      this._conversationId = conversation.id
      this._contactInfoFormStore.syncFields(conversation.contact_id)
    }
  }

  reactionAlert = () => {
    this._disposeAlert?.()
    this._disposeAlert = reaction(
      () => this.alert,
      (alert) => {
        if (alert) {
          setTimeout(() => {
            runInAction(() => {
              this.resetAlert()
            })
          }, 5000)
        }
      }
    )
  }

  reactionContacts() {
    this._disposeContacts?.()
    this._disposeContacts = reaction(
      () => this.contactsIds,
      (ids) => {
        if (!ids) return

        this._contactsProfileCardStore.setContactsIds(ids)
      }
    )
  }

  reactionConversation() {
    this._disposeConversation?.()
    this._disposeConversation = reaction(
      () => this.conversation,
      (item) => {
        if (item) {
          this._contactInfoFormStore.syncFields(item.contact_id)
          this._contactsProfileCardStore.setConversation(item)
          this._contactsProfileCardStore.setInboxId(item.inbox_id)

          const inbox = inboxesStore.getItem(item.inbox_id) as Inbox | undefined

          this._contactsConversationOpenStore.setConversationId(item.id)

          if (inbox) {
            this._contactsConversationOpenStore.handleInbox(inbox)
          }
        } else {
          this._contactsProfileCardStore.setConversation(null)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionContactsDetailsToggle() {
    this._disposeContactsDetailsToggle?.()
    this._disposeContactsDetailsToggle = reaction(
      () => this._contactsDetailsGlobalStore.isCollapse,
      (isCollapse) => {
        if (isCollapse) {
          this._config?.onStopCreateNewContact?.()
          this._config?.onCloseContactDetails?.()
          this.closeView()
        }
      }
    )
  }

  reactionContactUpdated() {
    this._disposeContactUpdated?.()
    this._disposeContactUpdated = reaction(
      () => this.contact,
      (contact) => {
        if (!contact) return

        this._contactsProfileCardStore.setContactId(contact.id)
      }
    )
  }

  reorderDraggableItems = async (source: number, target: number) => {
    try {
      const items = cloneDeep(this._draggableItems)
      const [item] = items.splice(source, 1)

      items.splice(target, 0, item)

      runInAction(() => {
        this._draggableItems = items
      })

      await UsersApi.updateUsersUiSettings({
        source_type: 'contacts-details-draggable-items',
        items: items,
      })
    } catch (e) {
      logger.error(e)
    }
  }

  initDraggableItems = async () => {
    try {
      const { data } = await UsersApi.getUsersUiSettings('contacts-details-draggable-items')

      runInAction(() => {
        if (data.data?.items) {
          this._draggableItems = data.data.items
        } else {
          this._draggableItems = defaultDraggableItems
        }
      })
    } catch (e) {
      logger.error(e)

      runInAction(() => {
        this._draggableItems = defaultDraggableItems
      })
    }
  }

  get titleHeader() {
    if (this._view === 'notes') return 'Notes'
    if (this._view === 'deals') return 'Deals'
    if (this._view === 'opportunities') return 'Opportunities'
    if (this._view === 'companies') return 'Companies'
    if (this._view === 'conversations-history') return 'Conversations history'
    if (this._view === 'quick-links') return contactsQuickLinksHeader
    if (this._view === 'tickets') return 'Tickets'

    return this._contactsGroupCardStore.isCurrentContact ? 'Contact' : 'Details'
  }

  get isViewTypeNotes() {
    return this._view === 'notes'
  }

  get isViewMain() {
    return this._view === 'main'
  }

  get isViewTypeConversationsHistory() {
    return this._view === 'conversations-history'
  }

  get isViewTypeQuickLinks() {
    return this._view === 'quick-links'
  }

  get isConversationGroup() {
    return this.contactsIds.length > 1
  }

  get isGroupSelectContact() {
    if (!this.isConversationGroup) return true

    return Boolean(this.contact)
  }

  get contactInfoFormStore() {
    return this._contactInfoFormStore
  }

  get conversationsHistoryMessagesStore() {
    return this._conversationsHistoryMessagesStore
  }

  get conversationId() {
    return this._conversationId
  }

  setAlert = (item: IAlert | null) => {
    this._alert = item
  }

  get alert() {
    return this._alert
  }

  get hasAlert() {
    return Boolean(this._alert)
  }

  reset = () => {
    this.resetAlert()
    this.resetReactions()
    this._contactsConversationOpenStore.reset()
  }

  resetAlert = () => {
    this._config?.resetAlert?.()
    this.setAlert(null)
    this._contactInfoFormStore.resetAlert()
    contactChoosePhoneModalStore.resetAlert()
  }

  resetReactions = () => {
    this._disposeConversation?.()
    this._disposeAlert?.()
    this._disposeContactsDetailsToggle?.()
    this._disposeContactUpdated?.()
    this._disposeContacts?.()
  }

  get contactsConversationOpenStore() {
    return this._contactsConversationOpenStore
  }

  get contact() {
    if (this._contactId === null) return

    return contactsStore.getItem(this._contactId)
  }

  get selectedContact() {
    return this.contact
  }

  get contactId(): number | null {
    return this._contactId
  }

  get contactsIds() {
    if (this.conversation) {
      return this.conversation.contactsIds
    }

    return this._contactId ? [this._contactId] : []
  }

  get contacts() {
    return this.contactsIds.reduce<Contact[]>((state, id) => {
      const contact = contactsStore.getItem(id)

      if (contact) state.push(contact)

      return state
    }, [])
  }

  get inbox() {
    return inboxesStore.getItem(this._inboxId) as Inbox | undefined
  }

  get contactsDetailsGlobalStore() {
    return this._contactsDetailsGlobalStore
  }

  get contactIntegrationsStore() {
    return this._contactIntegrationsStore
  }
}
