import classNames from 'classnames'
import { Typography } from 'shared/ui'
import { checkCDN } from 'shared/lib/checkCDN'
import { MessageCardType, MessageProps } from 'widgets/MessageCard/ui/types'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { ConversationMessageCardItem } from 'widgets/MessageCard/ui/ConversationMessageCardItem/ConversationMessageCardItem'
import { DateContent } from 'widgets/MessageCard/ui/DateContent/DateContent'
import styles from './styles.module.scss'

export const ImagesContent = (props: MessageProps) => {
  const { images } = props.message

  const renderImages = images.length === 5 ? images.slice(0, 4) : images.slice(0, 6)
  const hiddenImages = images.length === 5 ? images.slice(4) : images.slice(6)

  const onOpenPresentation = (index: number) => () => {
    presentationStore.setAttachments(images, index)
  }

  if (!images.length) {
    return <></>
  }

  return (
    <ConversationMessageCardItem message={props.message} type={MessageCardType.Images}>
      <div
        className={classNames(
          styles.imagesWrap,
          styles[`with${images.length > 6 ? 'More' : images.length}`],
          {
            [styles.conversationsHistoryView]: props.message.isConversationsHistory,
          }
        )}
      >
        {renderImages.map((attachment, index) => {
          if (attachment.type === 'image' || attachment.type === 'gif') {
            return (
              <div
                className={classNames(styles.imgWrap, {
                  [styles.imgWrap__moreOne]: renderImages.length > 1,
                })}
                key={attachment.source}
                onClick={onOpenPresentation(index)}
              >
                <img
                  key={attachment.source}
                  src={attachment.source}
                  alt=''
                  {...(checkCDN(attachment.source) ? { crossOrigin: 'use-credentials' } : null)}
                />
                {!!hiddenImages.length && index === renderImages.length - 1 && (
                  <div className={styles.hiddenImages}>
                    <Typography variant={'heading-sm'} ariaLabel={'hiddenImages'}>
                      +{hiddenImages.length}
                    </Typography>
                  </div>
                )}
              </div>
            )
          }
          return <div key={attachment.id}>No type</div>
        })}
      </div>
      <DateContent {...props} />
    </ConversationMessageCardItem>
  )
}
