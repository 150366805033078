import React, { FC } from 'react'
import { Button, IButtonProps } from 'shared/ui'

type ITestSMSBtnProps = {
  onClick: IButtonProps['onClick']
  disabled: IButtonProps['disabled']
}

export const TestSMSBtn: FC<ITestSMSBtnProps> = ({ onClick, disabled }) => (
  <Button
    text={'Test'}
    icon={'testSMS'}
    onClick={onClick}
    contained={'secondary'}
    size={'medium'}
    disabled={disabled}
  />
)
