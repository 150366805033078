import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { manageCreditsStore } from 'pages/admin/pages/organizations'

export const ManageCreditsAction = observer(() => (
  <Button
    typeBtn={'contained'}
    text={'Submit'}
    onClick={manageCreditsStore.onSubmit}
    disabled={manageCreditsStore.disabledSubmit}
    loading={manageCreditsStore.loadingSubmit}
  />
))
