import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import classNames from 'classnames'
import { checkCDN } from 'shared/lib/checkCDN'
import { Icon } from 'shared/ui'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import { useMediaController } from 'widgets/PresentationMode/hooks/useMediaController'
import styles from './styles.module.scss'

export const PresentationAudio = observer(() => {
  const ref = useRef<HTMLAudioElement>(null)
  const { currentAttachment, setDisabledOverlayClick, audioControllerStore } = presentationStore
  const { mediaControllerStore } = audioControllerStore

  const { onMouseEnter, onMouseLeave, handelEnded, onLoadedData } = useMediaController({
    mediaControllerStore,
    ref,
    setDisabledOverlayClick,
  })
  const url = currentAttachment?.source || currentAttachment?.source_short || ''

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames(styles.wrap)}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        onLoadedData={onLoadedData}
        onEnded={handelEnded}
        src={url}
        ref={ref}
        {...(checkCDN(url) ? { crossOrigin: 'use-credentials' } : null)}
      />
    </div>
  )
})
