import { createContext, useContext } from 'react'
import type { ITrigger } from 'entities/Trigger/model/Trigger'

export const DetailsTriggerModalContext = createContext<ITrigger | null>(null)

export function useDetailsTriggerModalContext() {
  const context = useContext(DetailsTriggerModalContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with TriggerDetailsModalContext'
    )
  }
  return context
}
