import { IDropOptions, IInboxCombine, TreeRootInboxes } from 'entities/Inbox'
import { ConversationMenuStore } from 'widgets/ConversationMenu'

export const actionTeamsFavoriteDragDrop = async (
  options: IDropOptions<IInboxCombine>,
  dropRoot: string | number,
  store: ConversationMenuStore
) => {
  const { dropTargetId, dragSource } = options
  const currentDropTargetId = dragSource?.parent
  const dropTarget = store.getItemTree(dropTargetId)
  const dragTarget = store.getItemTree(currentDropTargetId)
  const inbox = dragSource?.data
  const groupInbox = dragTarget?.data
  const realDropTargetId = dropTargetId || dropTarget?.parent

  const is_favorite = Boolean(
    dropRoot !== TreeRootInboxes.SIMPLE && realDropTargetId !== TreeRootInboxes.SMART
  )

  if (inbox) {
    await store.handleUpdateTeamsFavorite({
      inbox: inbox,
      groupInbox: groupInbox,
      status: is_favorite,
    })
  }
}
