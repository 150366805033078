import { Icon, Tooltip, Typography } from 'shared/ui'
import styles from './styles.module.scss'

export const Placeholder = () => {
  return (
    <Typography
      ariaLabel={'Placeholder'}
      className={styles.wrap}
      variant={'body-md-regular'}
      tag={'div'}
    >
      Select the message that will go straight to voicemail without calling
      <Tooltip
        type='default'
        label='Make sure you audio file is between 15 seconds and 3 minutes to ensure deliverability.'
        placement='top'
        width={244}
        breakWord
      >
        <Icon icon='question' color='var(--content-neutral-primary)' />
      </Tooltip>
    </Typography>
  )
}
