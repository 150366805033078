import type { IParamsContactsOptOut } from 'entities/Contacts/api/types'
import type { Contact } from 'entities/Contacts/model/Contact'

export const successOpIinHandler = (
  contact: Contact,
  opt_outs: IParamsContactsOptOut['opt_outs']
) => {
  const updates = {
    opt_out: opt_outs.toll_free ? null : 'soft',
    shortcode_opt_outs: contact.origin.shortcode_opt_outs.map((item) =>
      opt_outs.shortcodes?.hasOwnProperty(item.id)
        ? { ...item, value: opt_outs.shortcodes[item.id as number] }
        : item
    ),
  }

  contact.syncOrigin({ ...contact.origin, ...updates })
}
