import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Avatar,
  EmptyState,
  EnumSpinnerLoaderPosition,
  type IColumn,
  IconButton,
  SpinnerLoader,
  Table,
  Tooltip,
  Typography,
} from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { ContactStatistic } from 'entities/ContactStatistic'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'

import { useTriggerDetailContext } from 'widgets/TriggerDetail/context/triggerDetailContext'
import { ContactStatisticBulkActions } from 'widgets/ContactStatistic/ui/ContactStatisticBulkActions/ContactStatisticBulkActions'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'
import styles from './styles.module.scss'

const getColumns = (onOpenContactsDetails: IOpenContactsDetails) => {
  const nameColumn: IColumn<ContactStatistic> = {
    field: 'full_name',
    name: 'Name',
    renderRowCell: (row, _, config) => {
      if (!row) return null

      return (
        <div className={styles.contactColumn}>
          <div className={styles.contactColumnName}>
            <Avatar info={row.avatarInfo} size={24} />
            <Typography
              variant={'body-md-regular'}
              ellipsis
              tooltipProps={{ label: row.full_name }}
              ariaLabel={'displayInfo'}
            >
              {row.full_name || '—'}
            </Typography>
          </div>
          <Tooltip label='Conversation' placement='top'>
            <IconButton
              icon='chatRead'
              variant='icon'
              color='tertiary'
              transparentInactive
              className={config?.hidden}
              disabled={onOpenContactsDetails.disabled}
              onClick={(event) => {
                event.stopPropagation()
                onOpenContactsDetails.open({
                  contactId: row.id,
                })
              }}
              ariaLabel='ContactRow_sendMessage'
            />
          </Tooltip>
        </div>
      )
    },
  }

  const numberColumn: IColumn<ContactStatistic> = {
    field: 'formatted_number',
    name: 'Number',
    isSortable: true,
  }

  const emailColumn: IColumn<ContactStatistic> = {
    field: 'email',
    name: 'Email',
    isSortable: true,
  }

  const targetTimeColumn: IColumn<ContactStatistic> = {
    field: 'message_sent_at',
    name: 'Send time',
    renderRowCell: (row) =>
      row.message_sent_at &&
      uiStore.dayjs(row.message_sent_at).format(DayjsFormats.fullWithAtDash2),
  }

  return [nameColumn, numberColumn, emailColumn, targetTimeColumn]
}

export const ContactStatisticsTable = observer(() => {
  const {
    contactStatisticsItems,
    isNoData,
    total,
    isLoading,
    onPaginationContactsTableChange,
    page,
    scrollToTopTrigger,
    limit,
    contactStatisticsTableStore,
    onOpenContactsDetails,
    search,
    onSuccessDelete,
  } = useTriggerDetailContext()
  const columns = useMemo(() => getColumns(onOpenContactsDetails), [onOpenContactsDetails])

  if (isNoData) {
    return (
      <EmptyState text={'No contacts found'} noIcon boxProps={{ flexGrow: 1, height: undefined }} />
    )
  }
  if (isLoading && page === 1) {
    return (
      <div className={styles.wrapTable}>
        <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />
      </div>
    )
  }

  if (!contactStatisticsItems.length) {
    return <NoResultsFound boxProps={{ flexGrow: 1, height: undefined }} />
  }

  return (
    <div className={styles.wrapTable}>
      <Table
        columns={columns}
        rows={contactStatisticsItems}
        rowsCount={total}
        isLoading={isLoading}
        onChangePagination={onPaginationContactsTableChange}
        initPage={page}
        limit={limit}
        startPaginationSize={10}
        sxTable={{ marginRight: 2, width: 'calc(100% - 16px)' }}
        scrollToTOpTrigger={scrollToTopTrigger}
        withCheckbox
        store={contactStatisticsTableStore}
        headerLeftContentProps={{
          actionsContent: (
            <ContactStatisticBulkActions
              search={search}
              selectedContactIds={contactStatisticsTableStore.selectedIds}
              onSuccessDelete={onSuccessDelete}
            />
          ),
        }}
      />
    </div>
  )
})
