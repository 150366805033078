import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Checkbox, IconButton, Typography } from 'shared/ui'
import { RecentListTitle } from 'widgets/RecentlySent/ui/RecentListHeader/RecentListTitle'
import { recentStore } from 'widgets/RecentlySent'
import styles from './styles.module.scss'

type RecentListHeaderProps = {
  isShowTitle: boolean
}

export const RecentListHeader: FC<RecentListHeaderProps> = observer(({ isShowTitle }) => {
  const { checkedRecentAttachmentIds, isCheckedMode, isCheckedAll, onCheckAll, onDeleteChecked } =
    recentStore
  if (isCheckedMode) {
    return (
      <div className={styles.wrap}>
        <div className={styles.checkedContent}>
          <div className={styles.wrapCheckbox}>
            <Checkbox
              isIndeterminate={!isCheckedAll}
              size={32}
              checked={isCheckedMode}
              onChange={onCheckAll}
            />
          </div>
          <Typography ariaLabel={'selectedCount'} variant={'button-medium'}>
            {checkedRecentAttachmentIds.size} selected
          </Typography>
          <div className={styles.right}>
            <IconButton
              icon={'delete'}
              ariaLabel={'delete'}
              variant={'icon'}
              size={'small'}
              color={'secondary'}
              onClick={onDeleteChecked}
            />
          </div>
        </div>
      </div>
    )
  }
  if (isShowTitle) {
    return <RecentListTitle />
  }

  return null
})
