// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YQBX6My8vrSTrXjB9Qix{gap:8px;padding-inline:8px;height:32px;display:grid;grid-template-columns:max-content 1fr max-content;align-items:center;justify-content:right;width:100%}.YQBX6My8vrSTrXjB9Qix.qC23YIub09SB_ieuCpxp{gap:0;grid-template-columns:1fr max-content}.YQBX6My8vrSTrXjB9Qix.TT8tk1E4VNhw_rJ2eI1t,.YQBX6My8vrSTrXjB9Qix.qC23YIub09SB_ieuCpxp{border-radius:4px;background:var(--background-neutral-default)}.cQb_UBd5M_2u5nSeLNyS{margin-inline:-4px}._9iMtohGd0hWb8JiYWwU{margin-left:4px;font-feature-settings:\"tnum\"}", "",{"version":3,"sources":["webpack://./src/widgets/Record/ui/RecordActions/RecordPlayer/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,OAAA,CACA,kBAAA,CACA,WAAA,CACA,YAAA,CACA,iDAAA,CACA,kBAAA,CACA,qBAAA,CACA,UAAA,CAEA,2CACE,KAAA,CACA,qCAAA,CAGF,sFACE,iBAAA,CACA,4CAAA,CAKJ,sBACE,kBAAA,CAGF,sBACE,eAAA,CACA,4BAAA","sourcesContent":[".player{\n  gap: 8px;\n  padding-inline: 8px;\n  height: 32px;\n  display: grid;\n  grid-template-columns: max-content 1fr max-content;\n  align-items: center;\n  justify-content: right;\n  width: 100%;\n\n  &.recordAudio{\n    gap: 0;\n    grid-template-columns: 1fr max-content;\n  }\n\n  &.visible,  &.recordAudio{\n    border-radius: 4px;\n    background: var(--background-neutral-default);\n\n  }\n}\n\n.playBtn{\n  margin-inline: -4px;\n}\n\n.time{\n  margin-left: 4px;\n  font-feature-settings: 'tnum';\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"player": "YQBX6My8vrSTrXjB9Qix",
	"recordAudio": "qC23YIub09SB_ieuCpxp",
	"visible": "TT8tk1E4VNhw_rJ2eI1t",
	"playBtn": "cQb_UBd5M_2u5nSeLNyS",
	"time": "_9iMtohGd0hWb8JiYWwU"
};
export default ___CSS_LOADER_EXPORT___;
