import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { conversationStore } from 'entities/Conversation'
import { type Contact } from 'entities/Contacts/model/Contact'
import { NumbersInboxesDropDown, type NumbersInboxesListStore } from 'widgets/NumbersInboxesList'
import styles from './styles.module.scss'

type IConversationChooseNumberProps = {
  numbersInboxesListStore: NumbersInboxesListStore
  items: Contact[]
}

export const ConversationChooseNumber: FC<IConversationChooseNumberProps> = observer(
  ({ numbersInboxesListStore, items }) => {
    const { updateItem, getItem } = conversationStore

    return (
      <div className={styles.wrap}>
        <div className={styles.label}>From:</div>
        <div className={styles.dropdown}>
          <NumbersInboxesDropDown
            store={numbersInboxesListStore}
            contacts={items}
            onChange={(inboxId, numberId) => {
              const current = getItem(0)

              if (current?.origin) {
                updateItem({ ...current?.origin, inbox_id: inboxId, number_id: numberId })
              }
            }}
          />
        </div>
      </div>
    )
  }
)
