// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LDfwNo4bSr5Ogta0XQkZ{background:var(--background-neutral-subtle);display:grid;grid-template-columns:max-content 1fr max-content;border-radius:6px;padding:16px 12px}.ZQ4vEKNcUgzEQJ_p757r{padding-left:8px;padding-right:20px}.ZQ4vEKNcUgzEQJ_p757r .uzx9Guswhs9fwTPISpfC{display:flex;flex-direction:column;gap:4px}.ZQ4vEKNcUgzEQJ_p757r .zqhWFqUWwiQxdoTHpLQY{position:relative;margin-top:12px}.unSoltbbC1JFcm57tDul{padding:4px}.f6bx_Oy4dtZgDT6O3uP0{border-top:1px solid var(--border-neutral-default)}", "",{"version":3,"sources":["webpack://./src/widgets/TrackConversions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,2CAAA,CACA,YAAA,CACA,iDAAA,CACA,iBAAA,CACA,iBAAA,CAEF,sBACE,gBAAA,CACA,kBAAA,CACA,4CACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEF,4CACE,iBAAA,CACA,eAAA,CAGJ,sBACE,WAAA,CAEF,sBACE,kDAAA","sourcesContent":[".wrap {\n  background: var(--background-neutral-subtle);\n  display: grid;\n  grid-template-columns: max-content 1fr max-content;\n  border-radius: 6px;\n  padding: 16px 12px;\n}\n.center {\n  padding-left: 8px;\n  padding-right: 20px;\n  .info {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n  }\n  .select {\n    position: relative;\n    margin-top: 12px;\n  }\n}\n.newBtnWrap {\n  padding: 4px;\n}\n.searchInput {\n  border-top: 1px solid var(--border-neutral-default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "LDfwNo4bSr5Ogta0XQkZ",
	"center": "ZQ4vEKNcUgzEQJ_p757r",
	"info": "uzx9Guswhs9fwTPISpfC",
	"select": "zqhWFqUWwiQxdoTHpLQY",
	"newBtnWrap": "unSoltbbC1JFcm57tDul",
	"searchInput": "f6bx_Oy4dtZgDT6O3uP0"
};
export default ___CSS_LOADER_EXPORT___;
