import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useChatbotContext } from 'pages/chatbot/context'
import { ChatbotCollapse } from 'pages/chatbot/ui/ChatbotCollapse'

import { ChatbotActions } from '../ChatbotActions'
import { FallbackSettings } from './FallbackSettings'

export const StepFallbackActions = observer(() => {
  const { errors, fallbackActions } = useChatbotContext()

  const error = errors.getError('fallback_actions')
  const isOwnError = errors.hasOwnError('fallback_actions')
  const isNestedError =
    errors.hasError('fallback_actions') || errors.hasError('settings.await_reply')

  const fallbackActionsError = isOwnError
    ? error
    : isNestedError
    ? 'Please complete all the fields'
    : null

  useEffect(() => {
    if (!!fallbackActions.list.length && isOwnError) errors.removeError('fallback_actions')
  }, [fallbackActions.list, isOwnError])

  return (
    <ChatbotCollapse
      icon='failed'
      title='Fallback actions'
      tooltip='These actions will happen if your Textbot doesn’t collect all the fields you set in your objective, or if the conversation exceeds your message limit'
      error={fallbackActionsError}
    >
      <FallbackSettings />
      <ChatbotActions actions={fallbackActions} />
    </ChatbotCollapse>
  )
})
