import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DropdownContent } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { Segment } from 'entities/Segment/model/Segment'
import { SearchDropdownSegmentStore } from 'widgets/SearchDropdown'
import { SegmentsDropdownContentItems } from 'widgets/SearchDropdown/ui'
import styles from './styles.module.scss'

type ISegmentsDropdownContentProps = {
  store: SearchDropdownSegmentStore
  onChange: (item: Segment) => void
  width?: number
}

export const SegmentsDropdownContent: FC<ISegmentsDropdownContentProps> = observer(
  ({ store, width, onChange }) => {
    const { init, search } = store

    const handleReset = () => {
      store.resetType()
      init()
    }

    return (
      <DropdownContent noPadding className={styles.wrap} width={width}>
        <div className={styles.searchWrap}>
          <TextField
            className={styles.search}
            variant='integrated'
            InputProps={{
              placeholder: 'Search segment',
            }}
            onChange={store.handleSearch}
            value={search}
            mainActions={[makeTextFieldAutoFocus({ withFocus: !search })]}
          />
        </div>

        <SegmentsDropdownContentItems store={store} onChange={onChange} />

        {store.hasSelectedItems && (
          <div className={styles.clear}>
            <Button typeBtn={'text'} text={'Clear'} onClick={handleReset} />
          </div>
        )}
      </DropdownContent>
    )
  }
)
