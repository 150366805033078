import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Button } from 'shared/ui'
import { UnassignedTableStore } from 'pages/settings/pages/numbers/store/UnassignedTableStore'
import { NumbersTable } from 'pages/settings/pages/numbers/ui/NumbersTable'

export const UnassignedNumbers = observer(() => {
  const { numberTableStore, onAssign } = useMemo(() => new UnassignedTableStore(), [])

  return (
    <NumbersTable
      numberTableStore={numberTableStore}
      hiddenColumns={['inboxName']}
      withCheckbox
      withSelectAll
      headerLeftContentProps={{
        actionsContent: (
          <Button
            text='Assign inbox'
            icon='inboxOpen'
            typeBtn='contained'
            contained='tertiary'
            size='small'
            onClick={onAssign}
          />
        ),
      }}
    />
  )
})
