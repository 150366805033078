import { makeAutoObservable } from 'mobx'
import { ReactNode } from 'react'
import { toastStore } from 'shared/ui'
import { sizeAlertCreator } from 'shared/lib/sizeAlertCreator'
import {
  attachmentStore,
  type ILimitSizeError,
  type IResponseEventAttachmentUploadError,
  type IResponseMedia,
  type IResponseMediaShort,
} from 'entities/Attachment'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { RequestAttachment } from 'entities/Attachment/model/RequestAttachment'
import { RecordAudioStore } from 'widgets/RecordAudio/store/recordAudioStore'
import { RenameModalStore } from 'widgets/RenameModal'
import { TextToSpeechWidgetStore } from 'widgets/TextToSpeech'
import { IRinglessMessageDataParams } from 'widgets/MessageFormFields/RinglessMessageField/store/type'

type IRinglessMessageFieldStoreProps = {
  clearParentError: () => void
}

export class RinglessMessageFieldStore {
  clearParentError: IRinglessMessageFieldStoreProps['clearParentError']
  constructor({ clearParentError }: IRinglessMessageFieldStoreProps) {
    makeAutoObservable(this)
    this.clearParentError = clearParentError
  }
  isRecordAudioState = false

  loadingAudio = false
  recordAudioStore: RecordAudioStore = new RecordAudioStore({
    AUDIO_RECORD_LIMIT: 180,
    isPauseOnLimit: true,
  })

  attachment: Attachment | null = null
  textToSpeechStore: TextToSpeechWidgetStore | null = null

  error: ReactNode = ''

  reset = () => {
    this.isRecordAudioState = false
    this.textToSpeechStore = null
  }

  get ringlessMessageDataParams(): IRinglessMessageDataParams {
    return {
      message: '',
      is_rvm: true,
      media_url: this.attachment ? [new RequestAttachment(this.attachment)] : [],
    }
  }

  init = (responseMediaUrl: IResponseMediaShort) => {
    this.attachment = new Attachment({ responseMediaShort: responseMediaUrl })
  }

  private setRecordAudioState = (value: boolean): void => {
    this.isRecordAudioState = value
  }

  private setLoadingAudio = (value: boolean): void => {
    this.loadingAudio = value
  }

  onCancelUploadingAudio = () => {
    this.setLoadingAudio(false)
  }

  private addAttachment = (attachment: Attachment): void => {
    this.attachment = attachment
  }

  private setExtError = (title: string, desc?: string) => {
    toastStore.add({
      type: 'error',
      title,
      desc,
    })
    this.onErrorUploadingAudio()
  }
  private setLimitSizeError = (limitError: ILimitSizeError) => {
    toastStore.add({
      type: 'error',
      ...sizeAlertCreator(limitError),
    })
    this.onErrorUploadingAudio()
  }

  private onErrorUploadingAudio = () => {
    this.setLoadingAudio(false)
    this.attachment = null
  }

  handleRecordAudio = () => {
    this.clearError()
    const handleClose = () => this.setRecordAudioState(false)
    const onAllow = () => this.setRecordAudioState(true)

    const handleFinish = (file: File) => {
      new RenameModalStore({
        title: 'Audio name',
        element: 'Audio',
        onSave: (name: string) => {
          const audio = new File([file], `${name}.mp3`, {
            type: file.type,
            lastModified: file.lastModified,
          })
          handleClose()
          this.onAddFiles([audio])
        },
      })
    }
    this.recordAudioStore.init({ handleFinish, onAllow, handleClose })
  }

  onAddFiles = (files: Array<File>) => {
    this.clearError()
    this.setLoadingAudio(true)
    const file = files.length && files[0]
    if (file) {
      if (file.type.includes('audio')) {
        attachmentStore.addMediaFiles({
          files: [file],
          addAttachment: this.addAttachment,
          setExtError: this.setExtError,
          setLimitSizeError: this.setLimitSizeError,
        })
      } else {
        this.setExtError('The file is not supported for upload.', 'Please upload only mp3')
      }
    }
  }

  onAddAttachmentAudio = (attachment: Attachment): void => {
    this.addAttachment(attachment)
  }

  onAttachmentUploadSuccessful = ({ attachment }: { attachment: IResponseMedia }) => {
    if (attachment.id === this.attachment?.id) {
      this.addAttachment(new Attachment({ responseMedia: attachment }))
      this.setLoadingAudio(false)
    }
  }

  onAttachmentUploadError = (data: IResponseEventAttachmentUploadError) => {
    if (data.attachment.id === this.attachment?.id) {
      this.onErrorUploadingAudio()
    }
  }

  onRemoveAudio = () => {
    this.attachment = null
  }

  handleTextToSpeech = () => {
    this.clearError()
    const onClose = () => {
      this.textToSpeechStore = null
    }
    const onCreateSpeech = (file: File) => {
      this.onAddFiles([file])
    }
    this.textToSpeechStore = new TextToSpeechWidgetStore({
      onClose,
      onCreateSpeech,
    })
  }

  setError = (error: ReactNode) => {
    this.error = error
  }
  clearError = () => {
    this.setError('')
    this.clearParentError()
  }
}
