import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { ContactsApi } from 'entities/Contacts'
import { IResponseContactDuplicate, IContactDuplicate } from 'entities/Contacts/api/types'

const defaultPagination = {
  page: 1,
  length: 10,
}

export class ContactsDuplicateTableStore {
  itemsMap: Map<number, IContactDuplicate> = new Map()
  loading = false
  total = 0
  paginationData = defaultPagination

  constructor() {
    makeAutoObservable(this)
    this.reactionPagination()
  }

  get noResults() {
    return this.total === 0
  }

  get list() {
    return Array.from(this.itemsMap.values())
  }

  init = () => {
    this.load()
  }

  resetPaginationPage = () => {
    this.changePagination(1, this.paginationData.length)
  }

  reset = () => {
    this.paginationData = defaultPagination
    this.loading = false
    this.total = 0
    this.itemsMap.clear()
  }

  changePagination = (page: number, length: number) => {
    this.paginationData = {
      page,
      length,
    }
  }

  changeTotal = (total: number) => {
    this.total = total
  }

  load = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const { data } = await ContactsApi.getContactsDuplicate(this.paginationData)

      const results = data.data || []
      const total = data.total || 0

      this.addItems(results)

      runInAction(() => {
        this.changeTotal(total)
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  deleteLocallyAfterMerge = (id: number) => {
    this.itemsMap.delete(id)
  }

  decreaseDuplicateCount = (item?: IContactDuplicate) => {
    item && this.addItem({ ...item, count: item?.count - 1 })
  }

  successMergeHandler = (id: number) => {
    const item = this.itemsMap.get(id)

    if (!item) return

    if (item.count - 1 === 1) {
      this.deleteLocallyAfterMerge(id)
      this.changeTotal(this.total - 1)
    } else {
      this.decreaseDuplicateCount(item)
    }
  }

  addItem = (item: IResponseContactDuplicate) => {
    const id = parseInt(item.formatted_number.replace(/\D/g, ''))
    this.itemsMap.set(id, { id, ...item })
  }

  addItems = (contacts: IResponseContactDuplicate[]) => {
    this.itemsMap.clear()

    contacts.forEach((item) => {
      this.addItem(item)
    })
  }

  reactionPagination = () => {
    reaction(
      () => this.paginationData,
      () => {
        this.load()
      }
    )
  }
}

export const contactsDuplicateTableStore = new ContactsDuplicateTableStore()
