import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'

export const SaveEditSend = observer(() => {
  const { messageFieldStore, onSaveEditMessageAction } = useConversationMessagesContext()
  const { loadingSend, disablesSend, setSendActionBtnProps } = messageFieldStore

  const onSave = () => {
    onSaveEditMessageAction()
  }
  useEffect(() => {
    setSendActionBtnProps({
      onClick: onSaveEditMessageAction,
      ariaLabel: 'SaveEditSend',
    })
  }, [])
  return (
    <IconButton
      color={'primary'}
      loading={loadingSend}
      variant={disablesSend ? 'icon' : 'contained'}
      disabled={disablesSend}
      icon={'check1'}
      onClick={onSave}
      ariaLabel='SaveEditSend'
    />
  )
})
