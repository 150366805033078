import { useState, useEffect } from 'react'
import { ConversationsPageStore } from 'pages/conversations/store'
import { ConversationsPageContext } from 'pages/conversations/context'
import { ConversationsLayoutContent } from './conversationsLayoutContent'

export const ConversationsLayout = () => {
  const [store] = useState(() => new ConversationsPageStore())

  useEffect(() => {
    return () => {
      store.reset()
    }
  })

  return (
    <ConversationsPageContext.Provider value={store}>
      <ConversationsLayoutContent />
    </ConversationsPageContext.Provider>
  )
}
