import { ITextFieldBaseAsideAction } from 'shared/ui/TextField'

type ITextFieldElement = {
  element?: JSX.Element | null
}

export const makeTextFieldElement: (action: ITextFieldElement) => ITextFieldBaseAsideAction = (
  action
) => {
  const useAction: ITextFieldBaseAsideAction = () => action.element ?? null

  return useAction
}
