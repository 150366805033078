// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dyhkD5zTwgaNXIXQPyev{width:256px;display:flex;justify-content:space-between;align-items:center;cursor:pointer;border-radius:6px;border:1px solid var(--interaction-neutral-normal);background:var(--background-primary-inverted-primary);padding:4px 8px 4px 12px;color:var(--content-primary-primary)}.dyhkD5zTwgaNXIXQPyev:hover{border:1px solid var(--interaction-neutral-hovered)}.ZAdX5XE0VqvQpH7l2YrT{width:209px}.X7KjwlY_Y2AcPhunUwd3{color:var(--content-neutral-primary)}.NW3JTBSEANYHqofOcrB8{display:flex;align-items:center}._K34imYRvxkgQ5YJz4S3{margin-right:8px}.XzGaUBADZT3Sk0N7hsl4{line-height:23px}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsTable/ui/DropdownTriggerComponent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,kDAAA,CACA,qDAAA,CACA,wBAAA,CACA,oCAAA,CAEA,4BACI,mDAAA,CAGR,sBACI,WAAA,CAEJ,sBACI,oCAAA,CAEJ,sBACI,YAAA,CACA,kBAAA,CAEJ,sBACI,gBAAA,CAEJ,sBACI,gBAAA","sourcesContent":[".container {\n    width: 256px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    cursor: pointer;\n    border-radius: 6px;\n    border: 1px solid var(--interaction-neutral-normal);\n    background: var(--background-primary-inverted-primary);\n    padding: 4px 8px 4px 12px;\n    color: var(--content-primary-primary);\n\n    &:hover {\n        border: 1px solid var(--interaction-neutral-hovered);\n    }\n}\n.containerXs {\n    width: 209px;\n}\n.default {\n    color: var(--content-neutral-primary);\n}\n.textBlock {\n    display: flex;\n    align-items: center;\n}\n.iconContainer {\n    margin-right: 8px;\n}\n.text {\n    line-height: 23px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "dyhkD5zTwgaNXIXQPyev",
	"containerXs": "ZAdX5XE0VqvQpH7l2YrT",
	"default": "X7KjwlY_Y2AcPhunUwd3",
	"textBlock": "NW3JTBSEANYHqofOcrB8",
	"iconContainer": "_K34imYRvxkgQ5YJz4S3",
	"text": "XzGaUBADZT3Sk0N7hsl4"
};
export default ___CSS_LOADER_EXPORT___;
