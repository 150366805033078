import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, UIEvent } from 'react'
import { IRow, SpinnerLoader, Table, variantActionsProps } from 'shared/ui'
import { SimpleFilterDropdown } from 'shared/ui/Table/SimpleFilterDropdown/SimpleFilterDropdown'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { useMediaLibraryContext } from 'widgets/MediaLibrary/context/mediaLibraryContext'
import { getMediaLibraryCardActions } from 'widgets/MediaLibrary/helpers/getMediaLibraryCardActions'
import { columns } from './columns'
import styles from './styles.module.scss'

export const MediaLibraryTable = observer(() => {
  const mediaLibraryStore = useMediaLibraryContext()
  const {
    selectedAttachments,
    allAttachments,
    visibleColumnsIds,
    setVisibleColumnsIds,
    activeTabAttachments,
    onOpenPresentationModal,
    tableStore,
    setSelectedIdsFromTable,
    loadNextPage,
    isLoadingMore,
    isAttachmentInitListLoading,
    hasMore,
  } = mediaLibraryStore

  const onRowClick = (row: Attachment) => {
    !row.loading && onOpenPresentationModal(row)
  }

  useEffect(() => {
    selectedAttachments.forEach(({ id }) => {
      const attachment = allAttachments.get(id)
      if (attachment) tableStore.toggleSelected(attachment, true)
    })
  }, [])

  useEffect(() => {
    setSelectedIdsFromTable(tableStore.selectedIds)
  }, [tableStore.selectedIds.length])

  useEffect(() => {
    if (!visibleColumnsIds.length) {
      setVisibleColumnsIds(
        columns.filter((item) => !item.isHideByDefault).map((column) => column.field)
      )
    }
  }, [])

  const visibleColumns = useMemo(() => {
    return columns.filter((column) => visibleColumnsIds.includes(column.field))
  }, [visibleColumnsIds])

  const getRows = () => {
    return activeTabAttachments.map((attachment): IRow<Attachment> => {
      attachment.setActionsProps({
        ...variantActionsProps.table,
        actions: getMediaLibraryCardActions(attachment, mediaLibraryStore),
      })

      const row = Object.create(attachment)
      row.isDisabled = attachment.loading
      return row
    })
  }

  const handleScroll = useCallback(
    (event: UIEvent) => {
      const target = event.target as HTMLDivElement
      const scrollHeight = target.scrollHeight
      const scrollTop = target.scrollTop
      const offsetHeight = target.offsetHeight

      if (isLoadingMore || isAttachmentInitListLoading) return
      if (!hasMore) return
      if (scrollHeight <= scrollTop + offsetHeight + 200) {
        loadNextPage()
      }
    },
    [isLoadingMore, isAttachmentInitListLoading, hasMore]
  )

  const tableLoading = useMemo(() => {
    return isLoadingMore ? (
      <tr>
        <td colSpan={visibleColumns.length}>
          <div className={styles.spinnerMoreContainer}>
            <SpinnerLoader size={20} />
          </div>
        </td>
      </tr>
    ) : null
  }, [isLoadingMore, visibleColumns.length])

  return (
    <div className={styles.tableView}>
      <div className={styles.tableContainer}>
        <Table
          customScrollEvent={handleScroll}
          store={tableStore}
          columns={visibleColumns}
          rows={getRows()}
          pinnedColumnNames={['name']}
          hideExpandColumnsIds={['uploadedBy']}
          onRowClick={onRowClick}
          rightHeaderContent={
            <SimpleFilterDropdown
              columns={columns}
              visibleColumnsIds={visibleColumnsIds}
              setVisibleColumnsIds={setVisibleColumnsIds}
              noSearch
            />
          }
          withCheckbox
          withoutSelectedCount
          noPagination
          bottomTableContent={tableLoading}
        />
      </div>
    </div>
  )
})
