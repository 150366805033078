import { IStatusProps } from 'shared/ui/Status'

export const tollFreeStatusConfig = {
  Unverified: {
    title: 'Unverified',
    icon: 'minusCircle',
    emphasis: 'high',
    intent: 'neutral',
  } as IStatusProps,
  Denied: {
    title: 'Denied',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  Blocked: {
    title: 'Blocked',
    icon: 'closeCircle',
    emphasis: 'high',
    intent: 'negative',
  } as IStatusProps,
  Verified: {
    title: 'Verified',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  } as IStatusProps,
  'Internal Review': {
    title: 'Internal review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'purple',
  } as IStatusProps,
  'Under Review': {
    title: 'Carrier review',
    icon: 'clock',
    emphasis: 'high',
    intent: 'informative',
  } as IStatusProps,
}
