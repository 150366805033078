// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QS6HZUxnLOcpX23Ka00Q{display:flex;flex-direction:column;gap:24px;align-content:center;justify-content:center;width:400px;height:100%;margin:0 auto}", "",{"version":3,"sources":["webpack://./src/entities/Auth/ui/LoginPage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,oBAAA,CACA,sBAAA,CACA,WAAA,CACA,WAAA,CACA,aAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  align-content: center;\n  justify-content: center;\n  width: 400px;\n  height: 100%;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "QS6HZUxnLOcpX23Ka00Q"
};
export default ___CSS_LOADER_EXPORT___;
