import { FC } from 'react'
import classnames from 'classnames'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { type ITabProps } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/Tabs/types/types'
import { Tab } from './Tab'
import styles from './styles.module.scss'

type ITabsProps = {
  activeTab: EnumIntegrationTabs
  handleTab: (tab: EnumIntegrationTabs) => void
  items: ITabProps[]
}

export const Tabs: FC<ITabsProps> = ({ activeTab, handleTab, items }) => {
  return (
    <div className={classnames(styles.tabs)}>
      {items.map((item) => {
        return (
          <Tab {...item} id={item.key} activeTab={activeTab} handleTab={handleTab} key={item.key} />
        )
      })}
    </div>
  )
}
