import { observer } from 'mobx-react-lite'
import { useState, useCallback, useEffect, FC } from 'react'
import { debounce } from 'lodash'
import classnames from 'classnames'
import {
  Button,
  Dropdown,
  DropdownContent,
  IconButton,
  Tooltip,
  Scrollbar,
  showToast,
} from 'shared/ui'
import { TextField, makeTextFieldAutoFocus, makeTextFieldLoading } from 'shared/ui/TextField'
import { logger } from 'shared/lib'
import { useContactsIntegrationsContext } from 'widgets/ContactsDetails/ui/ContactsIntegrations/context/contactsIntegrationsContext'
import styles from './styles.module.scss'

type ITag = {
  text: string
  value: string
  category: string | boolean
}

type IActionsProps = {
  id: string | number
}

export const Actions: FC<IActionsProps> = observer(({ id }) => {
  const store = useContactsIntegrationsContext()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null)
  const [tags, setTags] = useState<ITag[]>([])

  const [scrolling, setScrolling] = useState(false)

  const onApply = async () => {
    if (!selectedTag?.value) return

    const data = {
      tag: selectedTag.value,
      contact_integration_id: id,
    }

    try {
      setLoading(true)
      const toast = await store.createContactsIntegrationInfusionsoftTag(data)

      showToast({
        type: toast.status,
        title: 'Tag applied in Keap',
      })
    } catch (e) {
      logger.error(e)
    } finally {
      setOpen(false)
      setLoading(false)
    }
  }

  const handleDebounce = async (value: string) => {
    try {
      setLoading(true)
      const tags = await store.getContactsIntegrationInfusionsoftTags(value)
      setTags(tags)
    } catch (e) {
      setTags([])
    } finally {
      setLoading(false)
    }
  }

  const init = async () => {
    try {
      setLoading(true)
      const tags = await store.getContactsIntegrationInfusionsoftTags('')
      setTags(tags)
    } catch (e) {
      setTags([])
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (value: string) => {
    setValue(value)
    debounceSearch(value)
  }

  const debounceSearch = useCallback(debounce(handleDebounce, 1000), [])

  const handleClearTag = () => setSelectedTag(null)

  const reset = () => {
    if (value !== '') init()
    setScrolling(false)

    setTimeout(() => {
      setValue('')
      setSelectedTag(null)
    }, 100)
  }

  const handleDisableScroll = () => {
    setScrolling(false)
  }

  const debounceScroll = useCallback(debounce(handleDisableScroll, 100), [])

  const handleScroll = () => {
    if (!scrolling) setScrolling(true)
    debounceScroll()
  }

  const tooltipLabel = (category: string | boolean, name: string, type: 'list' | 'selected') => {
    if (type === 'list' && scrolling) return null

    const hasCategory = typeof category === 'string'

    const maxLengthFullLabel = type === 'list' ? 10 : 8
    const maxLengthSingleLabel = type === 'list' ? 26 : 14

    if (!hasCategory) {
      if (name.length > maxLengthSingleLabel) {
        return `${name}`
      }
      return null
    }

    if ((hasCategory && category.length > maxLengthFullLabel) || name.length > maxLengthFullLabel) {
      return `${category}: ${name}`
    }
    return null
  }

  useEffect(() => {
    if (!open) reset()
  }, [open])

  useEffect(() => {
    init()
  }, [])

  return (
    <Dropdown
      ariaLabel={'Actions'}
      show={open}
      onToggle={setOpen}
      margin={-36}
      typeButton='button'
      buttonProps={{
        size: 'small',
        text: 'Apply tag',
        typeBtn: 'text',
        contained: 'secondary',
        icon: undefined,
      }}
      placement={'bottom-start'}
      customComponent={() => (
        <DropdownContent>
          {!selectedTag ? (
            <TextField
              size='small'
              variant='integrated'
              value={value}
              onChange={handleChange}
              InputProps={{
                placeholder: 'Search',
              }}
              mainActions={[makeTextFieldAutoFocus({ withFocus: true })]}
              rightActions={[makeTextFieldLoading({ loading })]}
              disabled={loading}
            />
          ) : (
            <div className={styles.inputWrapper}>
              <div onClick={() => {}} className={styles.selectedTag}>
                <Tooltip
                  label={tooltipLabel(selectedTag.category, selectedTag.text, 'selected')}
                  disableInteractive
                  placement={'left'}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 22],
                        },
                      },
                    ],
                  }}
                >
                  <>
                    {selectedTag.category && (
                      <p className={styles.category}>{selectedTag.category}:</p>
                    )}
                    {selectedTag.text && (
                      <p
                        className={classnames(styles.text, {
                          [styles.fullText]: !selectedTag.category,
                        })}
                      >
                        {selectedTag.text}
                      </p>
                    )}
                  </>
                </Tooltip>
                <IconButton
                  onClick={handleClearTag}
                  icon='close'
                  variant='outlined'
                  size='small'
                  transparentBackground
                  ariaLabel={'ContactsIntegrationsActions_clearTag'}
                />
              </div>
            </div>
          )}
          {!selectedTag && <div className={styles.searchDivider} />}
          {!selectedTag && (
            <Scrollbar
              className={styles.searchWrapper}
              onScroll={handleScroll}
              autoHeightMax={160}
              autoHeightMin={20}
              autoHeight
            >
              {tags.length === 0 ? (
                <div>
                  <p className={styles.noResults}>No results found</p>
                </div>
              ) : (
                tags.map((tag) => (
                  <div
                    key={tag.value}
                    onClick={() => setSelectedTag(tag)}
                    className={styles.tagWrapper}
                  >
                    <Tooltip
                      label={tooltipLabel(tag.category, tag.text, 'list')}
                      placement={'left'}
                      disableInteractive
                      PopperProps={{
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, 4],
                            },
                          },
                        ],
                      }}
                    >
                      <div className={styles.tag}>
                        {tag.category && <p className={styles.category}>{tag.category}:</p>}
                        {tag.text && (
                          <p
                            className={classnames(styles.text, {
                              [styles.fullText]: !tag.category,
                            })}
                          >
                            {tag.text}
                          </p>
                        )}
                      </div>
                    </Tooltip>
                  </div>
                ))
              )}
            </Scrollbar>
          )}

          {selectedTag && (
            <Button
              onClick={onApply}
              disabled={loading}
              size={'medium'}
              text={'Apply tag'}
              typeBtn={'contained'}
              contained={'primary'}
              loading={loading}
              fullWidth
            />
          )}
        </DropdownContent>
      )}
    />
  )
})
