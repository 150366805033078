import { makeAutoObservable } from 'mobx'

import { IWorkflowExecution, IWorkflowExecutionState, IWorkflowSourceType } from '../api'

export class WorkflowExecution {
  id!: string
  state!: IWorkflowExecutionState
  conversationIds!: number[]
  workflowId!: string
  workflowName!: string
  workflowSourceType!: IWorkflowSourceType

  constructor(item: IWorkflowExecution) {
    this.syncOrigin(item)
    makeAutoObservable(this)
  }

  syncOrigin(item: IWorkflowExecution) {
    this.id = item.id
    this.state = item.state
    this.conversationIds = item.conversation_ids
    this.workflowId = item.workflow_id
    this.workflowName = item.workflow_name
    this.workflowSourceType = item.workflow_source_type
  }
}
