import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { InputPhone } from 'shared/ui/TextField'
import { complianceStore } from 'entities/Compliance/store/complianceStore'
import { AdminDetailCard, IAdminDetailCardRowProps } from 'pages/admin'
import { adminTollFreeBusinessContactStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails/ui/BusinessContact/store/adminTollFreeBusinessContactStore'
import { adminTollFreeDetailStore } from 'pages/admin/pages/compliance/pages/TollFreeDetails'

export const BusinessContact = observer(() => {
  const { setData } = adminTollFreeDetailStore

  const {
    isForEdit,
    getError,
    isSaveLoading,

    first_name,
    edit_first_name,
    is_edit_first_name,
    set_edit_first_name,

    last_name,
    edit_last_name,
    is_edit_last_name,
    set_edit_last_name,

    email,
    edit_email,
    is_edit_email,
    set_edit_email,

    business_title,
    edit_business_title,
    is_edit_business_title,
    set_edit_business_title,

    phone_number,
    edit_phone_number,
    is_edit_phone_number,
    set_edit_phone_number,

    job_position,
    edit_job_position,
    is_edit_job_position,
    set_edit_job_position,

    onEdit,
    isEdit,
    onSave,
    onClose,
  } = adminTollFreeBusinessContactStore

  const data: IAdminDetailCardRowProps[] = [
    {
      title: 'First name',
      value: first_name,
      editValue: edit_first_name,
      isUnsaved: is_edit_first_name,
      onChange: set_edit_first_name,
      editError: getError('contact_person.first_name'),
    },
    {
      title: 'Last name',
      value: last_name,
      editValue: edit_last_name,
      isUnsaved: is_edit_last_name,
      onChange: set_edit_last_name,
      editError: getError('contact_person.last_name'),
    },
    {
      title: 'Business email',
      value: email,
      editValue: edit_email,
      isUnsaved: is_edit_email,
      onChange: set_edit_email,
      editError: getError('contact_person.email'),
    },
    {
      title: 'Job title',
      value: business_title,
      editValue: edit_business_title,
      isUnsaved: is_edit_business_title,
      onChange: set_edit_business_title,
      editError: getError('contact_person.business_title'),
    },
    {
      Input: InputPhone,
      title: 'Phone number',
      value: phone_number,
      editValue: edit_phone_number,
      isUnsaved: is_edit_phone_number,
      onChange: set_edit_phone_number,
      editError: getError('contact_person.phone_number'),
    },
    {
      title: 'Job position',
      value: job_position,
      editValue: edit_job_position,
      isUnsaved: is_edit_job_position,
      onChange: set_edit_job_position,
      typeEdit: 'select',
      options: complianceStore.businessProfileJobPositions,
      loadOptions: complianceStore.loadBusinessProfileJobPositions,
      editError: getError('contact_person.job_position_id'),
    },
  ]

  const handleSave = async () => {
    try {
      const res = await onSave()
      if (res?.data) {
        setData(res?.data)
      }
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <AdminDetailCard
      title={'Business contact'}
      data={data}
      isEdit={isEdit}
      actions={
        isForEdit &&
        (!isEdit ? (
          <Button
            text={'Edit'}
            contained={'secondary'}
            size={'medium'}
            icon={'edit'}
            onClick={onEdit}
          />
        ) : (
          <>
            <Button text={'Cancel'} contained={'secondary'} size={'medium'} onClick={onClose} />
            <Button
              text={'Save'}
              contained={'primary'}
              size={'medium'}
              onClick={handleSave}
              disabled={isSaveLoading}
            />
          </>
        ))
      }
    />
  )
})
