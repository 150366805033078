// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QLFMIw4dZy12cjKGKceW{margin-top:24px;display:flex;padding:8px 8px 8px 12px;justify-content:center;align-items:center;color:var(--content-primary-tertiary);font-size:12px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/ui/shared/EmptyState/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,wBAAA,CACA,sBAAA,CACA,kBAAA,CACA,qCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".emptyData {\n  margin-top: 24px;\n  display: flex;\n  padding: 8px 8px 8px 12px;\n  justify-content: center;\n  align-items: center;\n  color: var(--content-primary-tertiary);\n  font-size: 12px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyData": "QLFMIw4dZy12cjKGKceW"
};
export default ___CSS_LOADER_EXPORT___;
