import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import classNames from 'classnames'
import { IconButton, Typography } from 'shared/ui'
import presentationStore from 'widgets/PresentationMode/store/PresentationStore'
import styles from './styles.module.scss'

type Props = {
  leftActions?: ReactNode
  centerActions?: ReactNode
  rightActions?: ReactNode
  middleContent?: ReactNode
}

export const InfoWrap = observer(
  ({ leftActions, centerActions, rightActions, middleContent }: Props) => {
    const onClose = () => {
      presentationStore.onClose()
    }

    const onMouseEnter = () => {
      presentationStore.setDisabledOverlayClick(true)
    }
    const onMouseLeave = () => {
      presentationStore.setDisabledOverlayClick(false)
    }
    return (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <div className={styles.header}>
          <Typography variant={'body-md-regular'} ellipsis ariaLabel={'name'}>
            {presentationStore.currentAttachment?.name}
          </Typography>
          <IconButton
            icon={'close'}
            color={'secondary'}
            variant={'icon'}
            onClick={onClose}
            ariaLabel='InfoWrap_close'
          />
        </div>
        {(middleContent && <div className={styles.middleContent}>{middleContent}</div>) || (
          <div className={styles.divider} />
        )}
        <div className={styles.footer}>
          <div className={classNames(styles.flex, styles.left)}>{leftActions}</div>
          <div className={classNames(styles.flex, styles.center)}>{centerActions}</div>
          <div className={classNames(styles.flex, styles.right)}>{rightActions}</div>
        </div>
      </div>
    )
  }
)
