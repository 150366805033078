import { makeAutoObservable } from 'mobx'
import { SegmentBox } from 'entities/Segment'
import { Segment } from 'entities/Segment/model/Segment'
import { EnrollmentItemType, type IEnrollmentItem } from 'widgets/ContactEnrollment/types'
import { FilterEditorStore } from 'widgets/FilterEditor'
import { type EnrollmentListStore } from 'widgets/ContactEnrollment/store/enrollmentList'

export class EnrollmentPreviewStore {
  private _active: IEnrollmentItem | null = null
  private _hover: IEnrollmentItem | null = null

  constructor(private _enrollmentList: EnrollmentListStore) {
    makeAutoObservable(this)
  }

  reset = () => {
    this._active = null
    this._hover = null
  }

  resetPreviewActive = (item: IEnrollmentItem) => {
    this._enrollmentList.removeItem(item)

    if (!this._active) {
      this._hover = null

      return
    }
    if (this._active.payload === item.payload) {
      this._active = null
    }
    if (this._hover?.payload === item.payload) {
      this._hover = null
    }
  }

  private _setPreviewSegment = (
    value: IEnrollmentItem,
    segmentBox: SegmentBox,
    filterEditorStore: FilterEditorStore
  ) => {
    if (value.type === EnrollmentItemType.Filter || value.type === EnrollmentItemType.Segment) {
      filterEditorStore.dispose()
      filterEditorStore.groups.populateSegment(value.payload)
      segmentBox.putSegment(value.payload)
    }

    if (value.type === EnrollmentItemType.Tag) {
      const segment = new Segment({
        id: -1,
        name: '',
        organization_id: -1,
        filters: {
          filtersList: [
            {
              filter_tags: [],
              custom_filters: [],
              filters: [
                {
                  key: 'tag',
                  operator: 'is_any',
                  value: [String(value.payload.id)],
                },
              ],
              integration_lists: [],
            },
          ],
        },
        is_favorite: false,
      })

      filterEditorStore.dispose()
      filterEditorStore.groups.populateSegment(segment)
      segmentBox.putSegment(segment)
    }
  }

  setItemActive = (
    value: IEnrollmentItem,
    segmentBox: SegmentBox,
    filterEditorStore: FilterEditorStore
  ) => {
    this._active = value

    this._setPreviewSegment(value, segmentBox, filterEditorStore)
  }

  setItemHover = (
    value: IEnrollmentItem,
    segmentBox: SegmentBox,
    filterEditorStore: FilterEditorStore
  ) => {
    if (this._active) return

    this._hover = value

    this._setPreviewSegment(value, segmentBox, filterEditorStore)
  }

  resetItemHover = (segmentBox: SegmentBox, filterEditorStore: FilterEditorStore) => {
    this._hover = null

    if (!this._active) {
      segmentBox.removeSegment()
      filterEditorStore.dispose()
    }
  }

  resetItemActive = (segmentBox: SegmentBox) => {
    this._active = null
    segmentBox.removeSegment()
  }

  removeItem = (item: IEnrollmentItem) => {
    this.resetPreviewActive(item)
    this._enrollmentList.removeItem(item)
  }

  get hasActive() {
    return Boolean(this._active)
  }

  get active() {
    return this._active || this._hover
  }
}
