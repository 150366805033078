import React from 'react'
import { NameValueRecord, Typography } from 'shared/ui'
import type { ISendFromNumbersProps } from 'entities/Inbox/api/types'
import styles from '../styles.module.scss'

type SendFromContentProps = {
  sendFromNumbersProps: ISendFromNumbersProps[] | null
}

export const SendFromContent = ({ sendFromNumbersProps }: SendFromContentProps) => {
  if (!sendFromNumbersProps || sendFromNumbersProps.length === 0) {
    return null
  }

  return (
    <div>
      {sendFromNumbersProps.map((item, index) => (
        <div className={styles.grid} key={item.id}>
          {index === 0 ? (
            <Typography ariaLabel='Send' variant='text-input-field' className={styles.label}>
              Send from
            </Typography>
          ) : (
            <div />
          )}
          <div className={styles.value}>
            <NameValueRecord {...item} />
          </div>
        </div>
      ))}
    </div>
  )
}
