// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vDjV6AzPmdf70RH0gIqY{margin:0 0}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsGroupCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA","sourcesContent":[".wrap {\n  margin: 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "vDjV6AzPmdf70RH0gIqY"
};
export default ___CSS_LOADER_EXPORT___;
