import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { openIntercom } from 'entities/Intercom'
import styles from './styles.module.scss'

type ICommonErrorBannerProps = {
  errorMessage?: string | null
}

export const CommonErrorBanner = observer(({ errorMessage }: ICommonErrorBannerProps) => {
  if (!errorMessage) return null

  return (
    <Alert
      item={{
        type: 'error',
        title: 'Your business profile application was rejected.',
        desc: errorMessage,
      }}
      actions={
        <Button
          text={'Learn more'}
          typeBtn={'outlined'}
          size={'small'}
          contained={'secondary'}
          onClick={() => {
            openIntercom()
          }}
        />
      }
      marginTop={20}
      className={styles.errorBanner}
    />
  )
})
