export const paymentPlanTypeItems = [
  {
    id: 'none',
    value: 'None',
    label: 'None',
  },
  {
    id: '2-payments',
    value: '2-payments',
    label: '2 payments 30 days apart',
  },
  {
    id: '3-payments',
    value: '3-payments',
    label: '3 payments 30 days apart',
  },
  {
    id: 'custom',
    value: 'custom',
    label: 'Custom',
  },
]
