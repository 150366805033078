import { observer } from 'mobx-react-lite'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useEffect } from 'react'
import { useKeyGenerator } from 'shared/hooks'
import { ToggleBlock } from 'shared/ui'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { ConversationInfo } from 'widgets/ContactsDetails/ui/ConversationInfo/ConversationInfo'
import { ContactsGroupCard } from 'widgets/ContactsDetails/ui/ContactsGroupCard/ContactsGroupCard'
import { ContactsInfoForm } from 'widgets/ContactsDetails/ui/ContactsInfoForm'
import { ContactsIntegrations } from 'widgets/ContactsDetails/ui/ContactsIntegrations/ContactsIntegrations'
import { ContactsTagsForm } from 'widgets/ContactsDetails/ui/ContactsTagsForm'
import { ContactsNotesForm } from 'widgets/ContactsDetails/ui/ContactsNotesForm'

export const ContactsContentItems = observer(() => {
  const contactsDetailsStore = useContactsDetailsContext()
  const {
    isConversationGroup,
    isConversationNew,
    isGroupSelectContact,
    selectedContact,
    draggableItems,
    defaultDraggableItems,
    initDraggableItems,
    reorderDraggableItems,
    isVariantCreateContactModal,
  } = contactsDetailsStore

  const getFieldKey = useKeyGenerator<string>()

  const createNewContactMode = selectedContact?.id === -1
  const handleReorder = (result: DropResult) => {
    if (result.destination) reorderDraggableItems(result.source.index, result.destination.index)
  }

  useEffect(() => {
    if (!draggableItems.length) {
      initDraggableItems()
    }
    if (!contactsDetailsGlobalStore.isOpenBlock('contactDetails')) {
      contactsDetailsGlobalStore.toggleBlockView('contactDetails')
    }
  }, [])

  const renderContentItem = (key: string, isDragging: boolean) => {
    if (
      key === 'conversation_info' &&
      !isConversationNew &&
      !contactsDetailsStore.isVariantOpenConversations
    ) {
      return (
        <ToggleBlock
          borders={{ bottom: true }}
          isDragging={isDragging}
          title='Conversation'
          hideBottomMargin={true}
          hideDraggingIcon={isVariantCreateContactModal}
          isOpen={contactsDetailsGlobalStore.isOpenBlock('conversation')}
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('conversation')}
        >
          <ConversationInfo />
        </ToggleBlock>
      )
    }

    if (key === 'contacts_group' && isConversationGroup) {
      return (
        <ContactsGroupCard
          isDragging={isDragging}
          createNewContactMode={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
        />
      )
    }

    if (key === 'contacts_info' && isGroupSelectContact) {
      return (
        <ToggleBlock
          ariaLabel='openConversationDetailsSelector'
          borders={{ bottom: true }}
          isDragging={isDragging}
          title='Contact details'
          hideBottomMargin={true}
          hideDraggingIcon={isVariantCreateContactModal}
          isOpen={contactsDetailsGlobalStore.isOpenBlock('contactDetails')}
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('contactDetails')}
        >
          <ContactsInfoForm isNewContactMode={createNewContactMode} />
        </ToggleBlock>
      )
    }

    if (key === 'contacts_integrations' && isGroupSelectContact) {
      return (
        <ContactsIntegrations
          isDragging={isDragging}
          createNewContactMode={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
        />
      )
    }

    if (key === 'contacts_tags' && isGroupSelectContact) {
      return (
        <ToggleBlock
          isOpen={createNewContactMode ? false : contactsDetailsGlobalStore.isOpenBlock('tags')}
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('tags')}
          borders={{ bottom: true }}
          isDragging={isDragging}
          disabled={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
          title='Tags'
          ariaLabel='AddTag'
        >
          <ContactsTagsForm />
        </ToggleBlock>
      )
    }

    if (key === 'contacts_notes' && isGroupSelectContact) {
      return (
        <ToggleBlock
          isOpen={createNewContactMode ? false : contactsDetailsGlobalStore.isOpenBlock('notes')}
          onToggle={() => contactsDetailsGlobalStore.toggleBlockView('notes')}
          borders={{ bottom: true }}
          isDragging={isDragging}
          disabled={createNewContactMode}
          hideDraggingIcon={isVariantCreateContactModal}
          title='Notes'
          hideBottomMargin={true}
          padding={'0 0 23px'}
        >
          <ContactsNotesForm />
        </ToggleBlock>
      )
    }

    return null
  }

  if (isVariantCreateContactModal) {
    return (
      <>
        {defaultDraggableItems.map((key) => (
          <div key={key}>{renderContentItem(key, false)}</div>
        ))}
      </>
    )
  }

  return (
    <DragDropContext onDragEnd={handleReorder}>
      <Droppable droppableId='contacts-details'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {draggableItems.map((key, index) => (
              <Draggable key={getFieldKey(key)} draggableId={getFieldKey(key)} index={index}>
                {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
                  <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
                    {renderContentItem(key, isDragging)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
})
