import { observer } from 'mobx-react-lite'
import { type MessageFieldStore } from 'widgets/MessageField/store/messageFieldStore'
import { MessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import {
  IMessageFieldContentProps,
  MessageFieldContent,
} from 'widgets/MessageField/ui/MessageFieldContent'

type IMessageFieldProps = IMessageFieldContentProps & {
  messageFieldStore: MessageFieldStore
  onDragHandler?: (condition: boolean) => void
}

export const MessageField = observer(({ messageFieldStore, ...props }: IMessageFieldProps) => {
  return (
    <MessageFieldContext.Provider value={messageFieldStore}>
      <MessageFieldContent {...props} />
    </MessageFieldContext.Provider>
  )
})
