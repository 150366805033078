import { CSSProperties, ReactNode } from 'react'
import { IIconsVector } from 'shared/ui'

export type IAlertProps = {
  item: IAlert
  className?: string
  onClose?: () => void
  action?: ReactNode
  rightAction?: ReactNode
  actions?: ReactNode
  marginTop?: CSSProperties['marginTop']
  marginBottom?: number
  marginInline?: number
  renderBottom?: (item: IAlert) => ReactNode
}

export type IAlert = {
  type: 'infoLight' | 'infoBlue' | 'error' | 'warning' | 'success' | 'alert'
  title?: string
  desc?: ReactNode
}

export const alertIconsByType: { [key in IAlert['type']]: IIconsVector } = {
  error: 'failed',
  warning: 'alertTriangleYellow',
  success: 'check2',
  infoLight: 'infoCircle',
  infoBlue: 'infoCircle',
  alert: 'alertTriangle',
}
