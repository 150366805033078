import { makeAutoObservable } from 'mobx'
import type {
  IResponseIntegrationHubspotDeal,
  IResponseIntegrationHubspotCompany,
  IResponseIntegrationHubspotData,
  IResponseIntegrationHubspotTicket,
  IResponseIntegrationHubspotProperties,
} from 'entities/Integrations'
import { type IIntegrationsProperty } from 'entities/Integrations/model/types'
import { IntegrationDealProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationDealProperty'
import { IntegrationContactProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationContactProperty'
import { IntegrationCompanyProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationCompanyProperty'
import { IntegrationTicketProperty } from 'entities/Integrations/model/IntegrationProperty/IntegrationTicketProperty'

export class ContactIntegrationHubspotInfo {
  private _properties: IntegrationContactProperty | null = null
  private _dealsMap: Map<number, IntegrationDealProperty> = new Map()
  private _companiesMap: Map<number, IntegrationCompanyProperty> = new Map()
  private _ticketsMap: Map<number, IntegrationTicketProperty> = new Map()
  private _origin: IResponseIntegrationHubspotData

  constructor(item: IResponseIntegrationHubspotData) {
    this.addCompanies(item.companies)
    this.addDeals(item.deals)
    this.addTickets(item.tickets)
    this.addProperties(item.properties)

    this._origin = item

    makeAutoObservable(this)
  }

  get origin() {
    return this._origin
  }

  get properties() {
    return this._properties
  }

  get deals() {
    return Array.from(this._dealsMap.values())
  }

  get companies() {
    return Array.from(this._companiesMap.values())
  }

  get tickets() {
    return Array.from(this._ticketsMap.values())
  }

  addProperties = (properties: IResponseIntegrationHubspotProperties) => {
    const data = Object.entries(properties).map(([key, item]) => {
      if (key === 'firstname') {
        key = 'first_name'
        item.label = 'First Name'
      }

      if (key === 'lastname') {
        key = 'last_name'
        item.label = 'Last Name'
      }

      if (key === 'email') {
        item.label = 'Email'
      }

      return {
        key: key,
        label: item.label,
        value: item.value,
      }
    })

    if (data.length) {
      this._properties = new IntegrationContactProperty(data)
    }
  }

  addContactProperty = (item: IIntegrationsProperty) => {
    this._properties?.addProperty(item)
  }

  addDeals = (deals: IResponseIntegrationHubspotDeal[]) => {
    deals.forEach((deal) => {
      const ID = Number(deal.id.value)
      const id = !isNaN(ID) ? ID : 0

      this._dealsMap.set(id, new IntegrationDealProperty(deal))
    })
  }

  addDealProperty = (item: IIntegrationsProperty) => {
    const deal = this.deals[0]

    if (deal) {
      deal.addProperty(item)
    }
  }

  addCompanies = (companies: IResponseIntegrationHubspotCompany[]) => {
    companies.forEach((company) => {
      const ID = Number(company.id.value)
      const id = !isNaN(ID) ? ID : 0

      this._companiesMap.set(id, new IntegrationCompanyProperty(company))
    })
  }

  addCompanyProperty = (item: IIntegrationsProperty) => {
    const company = this.companies[0]

    if (company) {
      company.addProperty(item)
    }
  }

  addTickets = (tickets: IResponseIntegrationHubspotTicket[]) => {
    tickets.forEach((ticket) => {
      const ID = Number(ticket.id.value)
      const id = !isNaN(ID) ? ID : 0

      this._ticketsMap.set(id, new IntegrationTicketProperty(ticket))
    })
  }

  addTicketProperty = (item: IIntegrationsProperty) => {
    const ticket = this.tickets[0]

    if (ticket) {
      ticket.addProperty(item)
    }
  }
}
