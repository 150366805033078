import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { apiOptionsToDropdownItems, apiOptionToDropdownItem } from 'shared/lib'
import { IDropdownItem } from 'shared/ui'
import {
  AdminComplianceApi,
  IParamsUpdateTenDLCBusinessProfile,
  IResponseAdminTenDLCById,
} from 'entities/Admin/compliance'
import { ITenDLCStatus } from 'entities/Compliance'

class AdminTenDLCBusinessProfileStore {
  constructor() {
    makeAutoObservable(this)
  }

  organization_id: number | null = null
  id: number | null = null

  isEdit = false
  isSaveLoading = false
  status: ITenDLCStatus | null = null
  rejectReason = ''

  errorsObj: { [key: string]: string[] } = {}

  get paramsUpdateTenDLCBusinessProfile(): IParamsUpdateTenDLCBusinessProfile {
    return {
      id: this.id,
      status: this.status,
      organization_id: this.organization_id,
      vendor_id: this.edit_vendor_id,
      business_name: this.edit_business_name,
      business_industry_id: this.edit_business_industry?.id as number,
      ein: this.edit_ein,
      contact_person: {
        first_name: this.edit_first_name,
        last_name: this.edit_last_name,
        phone_number: this.edit_phone_number,
        email: this.edit_email,
        business_title: this.edit_business_title,
        job_position_id: this.edit_job_position?.id as number,
      },
      city: this.edit_city,
      state_id: this.edit_state?.id as string,
      postal_code: this.edit_postal_code,
      country: this.edit_country,
      business_registration_type_id: this.edit_business_type?.id as number,
      business_region_ids: this.edit_business_region?.map((region) => region.id) as number[],
      website_url: this.edit_website_url,
      social_media_url: this.edit_social_media_url,
      street_address_1: this.edit_street_address_1,
      street_address_2: this.edit_street_address_2,
    }
  }

  get isRejected() {
    return Boolean(
      this.status &&
        ['failed', 'rejected_by_twilio', 'rejected_by_tcr', 'rejected_by_admin'].includes(
          this.status
        )
    )
  }
  get isForReview() {
    return this.isRejected || this.status === 'internal_review'
  }

  get isForEdit() {
    return this.isForReview || !this.status
  }

  vendor_id: string | null = ''
  edit_vendor_id: typeof this.vendor_id = null
  get is_edit_vendor_id() {
    return this.vendor_id !== this.edit_vendor_id
  }
  set_edit_vendor_id = (value: typeof this.vendor_id) => {
    this.edit_vendor_id = value
  }

  business_name: string | null = null
  edit_business_name: typeof this.business_name = null
  get is_edit_business_name() {
    return this.business_name !== this.edit_business_name
  }
  set_edit_business_name = (value: typeof this.edit_business_name) => {
    this.edit_business_name = value
  }

  business_industry: IDropdownItem | null = null
  edit_business_industry: typeof this.business_industry = null
  get is_edit_business_industry() {
    return this.business_industry?.id !== this.edit_business_industry?.id
  }
  set_edit_business_industry = (value: typeof this.edit_business_industry) => {
    this.edit_business_industry = value
  }

  ein: string | null = null
  edit_ein: typeof this.ein = null
  get is_edit_ein() {
    return this.ein !== this.edit_ein
  }
  set_edit_ein = (value: typeof this.edit_ein) => {
    this.edit_ein = value
  }

  first_name: string | null = null
  edit_first_name: typeof this.first_name = null
  get is_edit_first_name() {
    return this.first_name !== this.edit_first_name
  }
  set_edit_first_name = (value: typeof this.edit_first_name) => {
    this.edit_first_name = value
  }

  last_name: string | null = null
  edit_last_name: typeof this.last_name = null
  get is_edit_last_name() {
    return this.last_name !== this.edit_last_name
  }
  set_edit_last_name = (value: typeof this.edit_last_name) => {
    this.edit_last_name = value
  }

  phone_number: string | null = null
  edit_phone_number: typeof this.phone_number = null
  get is_edit_phone_number() {
    return this.phone_number !== this.edit_phone_number
  }
  set_edit_phone_number = (value: typeof this.edit_phone_number) => {
    this.edit_phone_number = value
  }

  email: string | null = null
  edit_email: typeof this.email = null
  get is_edit_email() {
    return this.email !== this.edit_email
  }
  set_edit_email = (value: typeof this.edit_email) => {
    this.edit_email = value
  }

  street_address_1: string | null = null
  edit_street_address_1: typeof this.street_address_1 = null
  get is_edit_street_address_1() {
    return this.street_address_1 !== this.edit_street_address_1
  }
  set_edit_street_address_1 = (value: typeof this.edit_street_address_1) => {
    this.edit_street_address_1 = value
  }

  street_address_2: string | null = null
  edit_street_address_2: typeof this.street_address_2 = null
  get is_edit_street_address_2() {
    return this.street_address_2 !== this.edit_street_address_2
  }
  set_edit_street_address_2 = (value: typeof this.edit_street_address_2) => {
    this.edit_street_address_2 = value
  }

  city: string | null = null
  edit_city: typeof this.city = null
  get is_edit_city() {
    return this.city !== this.edit_city
  }
  set_edit_city = (value: typeof this.edit_city) => {
    this.edit_city = value
  }

  state: IDropdownItem | null = null
  edit_state: typeof this.state = null
  get is_edit_state() {
    return this.state?.id !== this.edit_state?.id
  }
  set_edit_state = (value: typeof this.edit_state) => {
    this.edit_state = value
  }

  postal_code: string | null = null
  edit_postal_code: typeof this.postal_code = null
  get is_edit_postal_code() {
    return this.postal_code !== this.edit_postal_code
  }
  set_edit_postal_code = (value: typeof this.edit_postal_code) => {
    this.edit_postal_code = value
  }

  country: string | null = null
  edit_country: typeof this.country = null
  get is_edit_country() {
    return this.country !== this.edit_country
  }
  set_edit_country = (value: typeof this.edit_country) => {
    this.edit_country = value
  }

  business_type: IDropdownItem | null = null
  edit_business_type: typeof this.business_type = null
  get is_edit_business_type() {
    return this.business_type?.id !== this.edit_business_type?.id
  }
  set_edit_business_type = (value: typeof this.edit_business_type) => {
    this.edit_business_type = value
  }

  business_region: IDropdownItem[] | null = null
  edit_business_region: typeof this.business_region = null
  get is_edit_business_region() {
    return (
      this.business_region?.map((item) => item.id).toString() !==
      this.edit_business_region?.map((item) => item.id).toString()
    )
  }
  set_edit_edit_business_region = (value: typeof this.edit_business_region) => {
    this.edit_business_region = value
  }

  business_title: string | null = null
  edit_business_title: typeof this.business_title = null
  get is_edit_business_title() {
    return this.business_title !== this.edit_business_title
  }
  set_edit_business_title = (value: typeof this.edit_business_title) => {
    this.edit_business_title = value
  }

  job_position: IDropdownItem | null = null
  edit_job_position: typeof this.job_position = null
  get is_edit_job_position() {
    return this.job_position?.id !== this.job_position?.id
  }
  set_edit_job_position = (value: typeof this.edit_job_position) => {
    this.edit_job_position = value
  }

  website_url: string | null = null
  edit_website_url: typeof this.website_url = null
  get is_edit_website_url() {
    return this.website_url !== this.edit_website_url
  }
  set_edit_website_url = (value: typeof this.edit_website_url) => {
    this.edit_website_url = value
  }

  social_media_url: string | null = null
  edit_social_media_url: typeof this.social_media_url = null
  get is_edit_social_media_url() {
    return this.social_media_url !== this.edit_social_media_url
  }
  set_edit_social_media_url = (value: typeof this.edit_social_media_url) => {
    this.edit_social_media_url = value
  }

  get hasChanges() {
    if (!this.isEdit) {
      return false
    }
    const changes = [
      this.is_edit_vendor_id,
      this.is_edit_business_name,
      this.is_edit_business_industry,
      this.is_edit_ein,
      this.is_edit_first_name,
      this.is_edit_last_name,
      this.is_edit_phone_number,
      this.is_edit_email,
      this.is_edit_street_address_1,
      this.is_edit_street_address_2,
      this.is_edit_city,
      this.is_edit_state,
      this.is_edit_postal_code,
      this.is_edit_country,
      this.is_edit_business_type,
      this.is_edit_business_region,
      this.is_edit_business_title,
      this.is_edit_job_position,
      this.is_edit_website_url,
      this.is_edit_social_media_url,
    ]
    return changes.some((item) => item)
  }

  reset = () => {
    this.organization_id = null
    this.rejectReason = ''
    this.errorsObj = {}
    this.id = null
    this.status = null
    this.vendor_id = null
    this.business_name = null
    this.business_industry = null
    this.ein = null
    this.first_name = null
    this.last_name = null
    this.phone_number = null
    this.email = null
    this.street_address_1 = null
    this.street_address_2 = null
    this.city = null
    this.state = null
    this.postal_code = null
    this.country = null
    this.business_type = null
    this.business_region = null
    this.business_title = null
    this.job_position = null
    this.website_url = null
    this.social_media_url = null
    this.isEdit = false
  }

  setBusinessProfile = (
    organization_id: number,
    profile?: IResponseAdminTenDLCById['business_profile_info']
  ) => {
    this.organization_id = organization_id
    if (profile?.id) {
      this.id = profile.id
      this.rejectReason = profile.rejection_reasons?.map((reason) => reason.error_message).join(' ')
      this.status = profile.status
      this.vendor_id = profile.vendor_id
      this.business_name = profile.business_name
      this.business_industry = apiOptionToDropdownItem(profile.business_industry)
      this.ein = profile.ein
      this.first_name = profile.contact_person?.first_name
      this.last_name = profile.contact_person?.last_name
      this.phone_number = profile.contact_person?.phone_number
      this.email = profile.contact_person?.email
      this.street_address_1 = profile.street_address_1
      this.street_address_2 = profile.street_address_2
      this.city = profile.city
      this.state = apiOptionToDropdownItem(profile.state)
      this.postal_code = profile.postal_code
      this.country = profile.country
      this.business_type = apiOptionToDropdownItem(profile.business_registration_type)
      this.business_region = apiOptionsToDropdownItems(profile.business_region)
      this.business_title = profile.contact_person?.business_title
      this.job_position = apiOptionToDropdownItem(profile.contact_person?.job_position)
      this.website_url = profile.website_url
      this.social_media_url = profile.social_media_url
    }
  }

  onEdit = () => {
    this.edit_vendor_id = this.vendor_id
    this.edit_business_name = this.business_name
    this.edit_business_industry = this.business_industry
    this.edit_ein = this.ein
    this.edit_first_name = this.first_name
    this.edit_last_name = this.last_name
    this.edit_phone_number = this.phone_number
    this.edit_email = this.email
    this.edit_street_address_1 = this.street_address_1
    this.edit_street_address_2 = this.street_address_2
    this.edit_city = this.city
    this.edit_state = this.state
    this.edit_postal_code = this.postal_code
    this.edit_country = this.country
    this.edit_business_type = this.business_type
    this.edit_business_region = this.business_region
    this.edit_business_title = this.business_title
    this.edit_job_position = this.job_position
    this.edit_website_url = this.website_url
    this.edit_social_media_url = this.social_media_url
    this.isEdit = true
  }

  onClose = () => {
    this.isEdit = false
  }

  onSave = async () => {
    try {
      runInAction(() => {
        this.isSaveLoading = true
      })
      return await AdminComplianceApi.updateTenDLCBusinessProfile(
        this.paramsUpdateTenDLCBusinessProfile
      )
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data) {
            this.errorsObj = e.response.data
          }
        }
      })
      throw e
    } finally {
      runInAction(() => {
        this.isSaveLoading = false
      })
    }
  }

  getError = (key: string) => {
    return this.errorsObj && this.errorsObj[key] && this.errorsObj[key][0]
  }
}

export const adminTenDLCBusinessProfileStore = new AdminTenDLCBusinessProfileStore()
