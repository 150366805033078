import { createContext, useContext } from 'react'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'

export const ConversationMessagesContext = createContext<ConversationMessagesStore | null>(null)

export function useConversationMessagesContext() {
  const context = useContext(ConversationMessagesContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with ConversationMessagesStoreProvider'
    )
  }
  return context
}
