// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PIm_EznQG7PLJLM_SKMQ{display:flex;align-items:center}.idRoCE81m0eWAhvYSO9A .xKs4eKwFpIgvL2qWMvl9{margin-left:6px}._I7a0dUd1HeIcVbnHfMz{color:var(--black-30);margin-right:8px}.xKs4eKwFpIgvL2qWMvl9{color:var(--content-primary-primary);max-width:150px}.IlFYWJDknZ2mvfL3NIzn{color:var(--black-30);margin-left:8px}.WCMeW54q80aFknpMKH9N{background:var(--background-brand-subtle-primary);color:var(--content-primary-tertiary)}.NMmslZp7fsInZ7i8_n0g{color:var(--content-primary-primary)}", "",{"version":3,"sources":["webpack://./src/entities/Segment/ui/SegmentDropdownCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CAGE,4CACE,eAAA,CAKN,sBACE,qBAAA,CACA,gBAAA,CAGF,sBACE,oCAAA,CACA,eAAA,CAGF,sBACE,qBAAA,CACA,eAAA,CAKF,sBACE,iDAAA,CACA,qCAAA,CAEA,sBACE,oCAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n\n  &--checkbox {\n    .title {\n      margin-left: 6px;\n    }\n  }\n}\n\n.icon {\n  color: var(--black-30);\n  margin-right: 8px;\n}\n\n.title {\n  color: var(--content-primary-primary);\n  max-width: 150px;\n}\n\n.count {\n  color: var(--black-30);\n  margin-left: 8px;\n}\n\n.checkbox {}\n\n.highlighter {\n  background: var(--background-brand-subtle-primary);\n  color: var(--content-primary-tertiary);\n\n  &--label {\n    color: var(--content-primary-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "PIm_EznQG7PLJLM_SKMQ",
	"wrap--checkbox": "idRoCE81m0eWAhvYSO9A",
	"title": "xKs4eKwFpIgvL2qWMvl9",
	"icon": "_I7a0dUd1HeIcVbnHfMz",
	"count": "IlFYWJDknZ2mvfL3NIzn",
	"highlighter": "WCMeW54q80aFknpMKH9N",
	"highlighter--label": "NMmslZp7fsInZ7i8_n0g"
};
export default ___CSS_LOADER_EXPORT___;
