import { observer } from 'mobx-react-lite'
import { useState, FC } from 'react'
import { Button } from 'shared/ui'
import { AutoRechargeWidgetStore } from 'widgets/AutoRechargeModal'

type IAutoRechargeModalActionProps = {
  autoRechargeWidgetStore: AutoRechargeWidgetStore
}

export const AutoRechargeModalAction: FC<IAutoRechargeModalActionProps> = observer(
  ({ autoRechargeWidgetStore }) => {
    const [loading, setLoading] = useState(false)
    const { onEnableAutoRecharge, disabledAutoRecharge } = autoRechargeWidgetStore

    const handleClick = async () => {
      setLoading(true)
      await onEnableAutoRecharge()
      setLoading(false)
    }

    return (
      <Button
        disabled={disabledAutoRecharge}
        onClick={handleClick}
        text={'Enable Auto-Recharge'}
        loading={loading}
      />
    )
  }
)
