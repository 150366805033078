import React, { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import graphicBg from 'shared/assets/images/auth/graphicBg.png'
import { CDN_FRONT_URL } from 'shared/config'
import { StepFormLayout } from './StepFormLayout'

import styles from './styles.module.scss'

type IAuthLayoutProps = {
  form: ReactNode
  formBottom?: ReactNode
  rightContent: ReactNode
  title: string
  subtitle: ReactNode | string
  onBack?: () => void
}

export const AuthLayout: React.FC<IAuthLayoutProps> = observer(
  ({ form, rightContent, title, subtitle, formBottom, onBack }) => {
    return (
      <div className={styles.wrap}>
        <div className={styles.formContent}>
          <StepFormLayout form={form} title={title} subtitle={subtitle} onBack={onBack} />
          {formBottom && formBottom}
        </div>

        <div className={styles.rightContent}>
          <div className={styles.content}>{rightContent}</div>
          <img className={styles.graphicBg} alt='graphicBg' src={`${CDN_FRONT_URL}${graphicBg}`} />
        </div>
      </div>
    )
  }
)

export default AuthLayout
