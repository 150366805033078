import React from 'react'
import { Status } from 'shared/ui'
import { IStatusProps } from 'shared/ui/Status'
import { ITriggerStatus } from 'entities/Trigger/api/type'

const config: Record<ITriggerStatus, IStatusProps> = {
  active: {
    title: 'Active',
    icon: 'check2',
    emphasis: 'high',
    intent: 'positive',
  },
  disabled: {
    title: 'Disabled',
    icon: 'minusCircle',
    emphasis: 'high',
    intent: 'neutral',
  },
}

export const TriggerStatus = ({
  status,
  emphasis = 'high',
  onlyIcon,
}: {
  status: ITriggerStatus
  emphasis?: IStatusProps['emphasis']
  onlyIcon?: IStatusProps['onlyIcon']
}) => status && <Status {...config[status]} emphasis={emphasis} onlyIcon={onlyIcon} />
