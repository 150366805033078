import { NumberFormatBase, NumberFormatBaseProps, InputAttributes } from 'react-number-format'
import { numberFormat } from 'shared/lib'

export function InputFormatNumber(props: NumberFormatBaseProps<InputAttributes>) {
  const format = (numStr: string) => {
    if (numStr === '') return ''

    return numberFormat({ value: Number(numStr), maximumFractionDigits: 0 })
  }

  return <NumberFormatBase {...props} format={format} />
}
