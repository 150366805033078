import { PropsWithChildren, createContext, useContext } from 'react'

export interface IEnrollmentActionsProps {
  selectTitle: string
  selectDisabled: boolean
  onConfirm: () => void
  onCancel: () => void
}

const EnrollmentActionsContext = createContext<IEnrollmentActionsProps | null>(null)

export const useEnrollmentActionsContext = () => {
  const context = useContext(EnrollmentActionsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your component with EnrollmentActionsProvider')

  return context
}

export const EnrollmentActionsProvider = ({
  children,
  ...context
}: PropsWithChildren<IEnrollmentActionsProps>) => (
  <EnrollmentActionsContext.Provider value={context}>{children}</EnrollmentActionsContext.Provider>
)
