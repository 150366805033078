import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Button, EmptyState } from 'shared/ui'
import { TriggerRouts } from 'entities/Trigger'

export const EmptyStateTriggers = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const onClick = () => {
    navigate(`/${TriggerRouts.triggers}/${TriggerRouts.create}`)
  }

  const textInfo = useMemo(() => {
    switch (location.pathname) {
      case `/${TriggerRouts.triggers}/${TriggerRouts.all}`:
        return ''
      case `/${TriggerRouts.triggers}/${TriggerRouts.sms}`:
        return 'SMS '
      case `/${TriggerRouts.triggers}/${TriggerRouts.ringless}`:
        return 'ringless '
    }
  }, [location.pathname])

  return (
    <EmptyState
      iconProps={{ icon: 'lightning2', color: 'var(--content-neutral-primary)' }}
      title={`Create your first ${textInfo}trigger`}
      text={'Send instant messages via API or webhooks'}
      actions={
        <Button
          text={'New trigger'}
          onClick={onClick}
          iconProps={{ icon: 'add' }}
          size={'medium'}
        />
      }
    />
  )
})
