import { useState } from 'react'
import classNames from 'classnames'
import { Badge } from '../../Badge/ui/Badge'
import { IconButton } from '../../IconButton/ui/IconButton'
import styles from './styles.module.scss'
import { MenuSearch } from '../MenuSearch/MenuSearch'

type IMenuTitleProps = {
  count?: number
  title: string
  open?: boolean
  handleToggle?: () => void
  useSearch?: {
    handleSearch: (search: string) => void
    search: string
    placeholder?: string
    autofocus?: boolean
    variant?: 'simple' | 'transparent'
  }
}

function MenuTitle(props: IMenuTitleProps) {
  const { count, title, open = true, handleToggle, useSearch } = props
  const hasCount = Boolean(count)

  const [showSearch, setShowSearch] = useState(false)

  const handleBlur = () => setShowSearch(false)
  const handleSearch = () => setShowSearch(true)

  if (useSearch && (showSearch || useSearch.search !== '')) {
    return (
      <div className={styles.searchWrap}>
        <MenuSearch
          onSearch={useSearch.handleSearch}
          onBlur={handleBlur}
          search={useSearch.search}
          autofocus={useSearch.autofocus}
          placeholder={useSearch.placeholder || 'Search'}
          variant={useSearch.variant || 'simple'}
        />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <div
        className={classNames(styles.contentWrap, { [styles.clickable]: !!handleToggle })}
        onClick={handleToggle}
      >
        <div className={styles.titleWrap}>
          <div
            className={classNames(styles.boxTitle, {
              ['styles.boxTitle--hasCount']: hasCount,
            })}
          >
            {title}
          </div>
          {handleToggle && (
            <div className={styles.boxArrow}>
              <IconButton
                icon={open ? 'chevronDown' : 'chevronRight'}
                size={'small'}
                color={'transparent'}
                ariaLabel='MenuTitle_arrow'
              />
            </div>
          )}
        </div>

        {!open && handleToggle && (
          <div className={styles.boxBadge}>
            <Badge content={count} />
          </div>
        )}
      </div>
      {useSearch && (
        <div className={styles.searchIcon}>
          <IconButton
            icon={'search'}
            size={'small'}
            onClick={handleSearch}
            color={'secondary'}
            tooltipProps={{
              placement: 'top-end',
              label: useSearch.placeholder || 'Search',
            }}
            ariaLabel='MenuTitle_search'
          />
        </div>
      )}
    </div>
  )
}

export { MenuTitle }
