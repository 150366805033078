import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { cloneDeep } from 'lodash'
import { IIconsVector } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { IAlert } from 'shared/ui/Alert/types'
import { contactsStore } from 'entities/Contacts'
import { IResponseConversation } from 'entities/Conversation/api/types'
import { Contact } from 'entities/Contacts/model/Contact'
import { Conversation } from 'entities/Conversation/model/Conversation'
import { IntegrationKey } from 'entities/Integrations/model/Integration'
import { IResponseContact } from 'entities/Contacts/api/types'
import { conversationStore } from 'entities/Conversation'
import { UsersApi } from 'entities/Users'
import { ContactNoteStore } from 'widgets/ContactNote'
import { ContactsTableStore } from 'widgets/ContactsTable'
import { ContactInfoFormStore } from 'widgets/ContactsDetails/ui/ContactsInfoForm'
import {
  EnumIntegrationTabs,
  IContactsDetailsStoreConfig,
  IContactsDetailsVariant,
  IViewAllData,
  IViewAllDataProps,
  IViewTypes,
} from 'widgets/ContactsDetails/store/types'
import { type ConversationNewSearchStore } from 'widgets/ConversationNew'
import { ConversationsHistoryMessagesStore } from 'widgets/ConversationsHistoryMessages'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import { ContactProfileInfoStore } from 'widgets/ContactProfileInfo'
import { type ContactCreateModalStore } from 'widgets/ContactCreateModal'
import { contactChoosePhoneModalStore } from 'widgets/ContactChoosePhoneModal'
import { type ContactsDetailsGlobalStore } from './contactsDetailsGlobalStore'

const emptyViewAllData = {
  deals: [],
  companies: [],
  opportunities: [],
}

export class ContactsDetailsStore {
  private _id = nanoid()
  private _conversationId: number | null = null
  private _contact: Contact | null = null
  private _conversation: Conversation | null = null
  private _variant: IContactsDetailsVariant = 'conversation'
  private _currentContact: Contact | null = null
  private _view: IViewTypes = 'main'
  private _integrationTab: EnumIntegrationTabs = EnumIntegrationTabs.Contact
  private _viewIntegrationKey: IntegrationKey | null = null
  private _viewAllData: IViewAllData = emptyViewAllData
  private _customTitleIcon: IIconsVector | null = null

  private _reassigning = false
  private _creatingConversation = false
  private _contactsDetailsGlobalStore: ContactsDetailsGlobalStore | null = null
  private _contactNoteStore = new ContactNoteStore()
  private _conversationsHistoryMessagesStore = new ConversationsHistoryMessagesStore(this)
  private _contactsTableStore: ContactsTableStore | null = null
  private _conversationNewSearchStore: ConversationNewSearchStore | null = null
  private _contactInfoFormStore: ContactInfoFormStore
  private _conversationMessagesStore: ConversationMessagesStore | null = null
  private _contactProfileInfoStore: ContactProfileInfoStore | null = null
  private _contactCreateModalStore: ContactCreateModalStore | null = null
  private _disposeAlert: IReactionDisposer | null = null
  private _disposeContactsDetailsToggleReaction: IReactionDisposer | null = null
  private _disposeContactUpdatedReaction: IReactionDisposer | null = null
  private _disposeSetContactFromCreateModalReaction: IReactionDisposer | null = null

  onClickCall: ((id: number) => void) | null = null
  onClickMessage: ((contact: Contact) => void) | null = null
  onUpdateContact: ((contact: Contact) => void) | null = null
  onCloseContactDetails: (() => void) | null = null
  onStopCreateNewContact: (() => void) | null = null
  onUpdateConversation: ((conversation: Conversation) => void) | null = null
  addNewContactLocallyAfterCreate:
    | ((contact: Contact, params?: { isNew?: boolean }) => void)
    | null = null
  draggableItems: string[] = []
  defaultDraggableItems: string[] = [
    'conversation_info',
    'contacts_group',
    'contacts_info',
    'contacts_integrations',
    'contacts_tags',
    'contacts_notes',
  ]

  constructor(config: IContactsDetailsStoreConfig) {
    makeAutoObservable(this)

    this._reassigning = false
    this._creatingConversation = false
    this._contact = config.contact || null
    this._contactsDetailsGlobalStore = config.contactsDetailsGlobalStore || null
    this._conversation = config.conversation || null
    this._variant = config.variant || 'conversation'
    this._view = 'main'
    this._integrationTab = EnumIntegrationTabs.Contact
    this._viewIntegrationKey = null

    this.onClickCall = config.profileCardProps?.onClickCall || null
    this.onClickMessage = config.profileCardProps?.onClickMessage || null
    this.onUpdateContact = config.onUpdateContact || null
    this.onCloseContactDetails = config.onCloseContactDetails || null
    this.onStopCreateNewContact = config.onStopCreateNewContact || null
    this.onUpdateConversation = config.onUpdateConversation || null
    this.addNewContactLocallyAfterCreate = config.addNewContactLocallyAfterCreate || null

    this._conversationNewSearchStore = config.conversationNewSearchStore || null
    this._contactsTableStore = config.contactsTableStore || null
    this._contactInfoFormStore = new ContactInfoFormStore(
      config.contact?.id || config.conversation?.contact_id
    )
    this._conversationMessagesStore = config.conversationMessagesStore || null
    this._contactProfileInfoStore = new ContactProfileInfoStore({
      contact: this._contact,
      contacts: this.contacts,
    })
    this._contactCreateModalStore = config.contactCreateModalStore || null

    this.onContactsDetailsToggle()
    this.reactionContactUpdated()
    this.reactionSetContactFromCreateModal()
    this.reactionAlert()
  }

  get isVariantConversations() {
    return this._variant === 'conversation'
  }

  get isVariantContacts() {
    return this._variant === 'contacts'
  }

  get isVariantCreateContactModal() {
    return this._variant === 'create_contact_modal'
  }

  get isConversationNew() {
    if (!this._conversation) return true

    return this._conversation?.isNew
  }

  get contacts(): Contact[] {
    if (this._conversation) {
      return this._conversation.contactsIds.reduce<Contact[]>((state, current) => {
        const contact = contactsStore.getItem(current)

        return contact ? [...state, contact] : state
      }, [])
    }

    if (this._contact?.isNew) return [this._contact]

    const contact = contactsStore.getItem(this._contact?.id)

    return contact ? [contact] : []
  }

  get selectedContact(): Contact | null | undefined {
    if (this.contacts.length > 1) return this._currentContact || this.contacts[0]

    return this.contacts[0]
  }

  get disabledNewConversationCall(): boolean {
    if (this._conversation && !this._conversation.isNew) return false

    if (this.isVariantCreateContactModal) {
      return !this._contact || this._contact.isNew
    }

    return !this._contact || this._contact.isNew || !this._conversationNewSearchStore?.activeInboxId
  }

  get dealsCount() {
    return this._viewAllData.deals.length
  }

  get isMultipleContacts() {
    return this.contacts.length > 1
  }

  get isViewAll() {
    if (this._view === 'main') return false

    return (
      this._view === 'companies' ||
      this._view === 'deals' ||
      this._view === 'notes' ||
      this._view === 'opportunities'
    )
  }

  get viewAllCount() {
    if (this._view === 'companies') return this._viewAllData.companies.length
    if (this._view === 'deals') return this._viewAllData.deals.length
    if (this._view === 'opportunities') return this._viewAllData.opportunities.length

    return 0
  }

  get companiesCount() {
    return this._viewAllData.companies.length
  }

  get conversation() {
    return this._conversation
  }

  get integrationTab() {
    return this._integrationTab
  }

  get viewIntegrationKey() {
    return this._viewIntegrationKey
  }

  get currentContact() {
    return this._currentContact
  }

  get viewAllData() {
    return this._viewAllData
  }

  get customTitleIcon() {
    return this._customTitleIcon
  }

  get creatingConversation() {
    return this._creatingConversation
  }

  get id() {
    return this._id
  }

  get contactNoteStore() {
    return this._contactNoteStore
  }

  get view() {
    return this._view
  }

  get reassigning() {
    return this._reassigning
  }

  setConversationId = (conversationId?: number | string) => {
    const id =
      conversationId === 'null' || conversationId === undefined ? null : Number(conversationId)

    if (typeof id === 'number') {
      this._conversationId = id
    }
  }

  handleNewConversationCall = async () => {
    if (!this._contact || !this._conversationNewSearchStore?.activeInboxId) return

    try {
      runInAction(() => {
        this._creatingConversation = true
      })

      const newConversation = await conversationStore.createConversation({
        contact_id: this._contact.id,
        team_id: this._conversationNewSearchStore?.activeInboxId,
      })

      runInAction(() => {
        this._creatingConversation = false
      })

      if (newConversation) {
        uiStore.changeRoute({
          path: `/conversations/${newConversation.id}`,
        })
        this.onClickCall?.(newConversation.id)
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._creatingConversation = false
      })
    }
  }

  handleActiveContact = (item: Contact | null) => {
    this._currentContact = item
    if (item) {
      this._contactInfoFormStore = new ContactInfoFormStore(item.id)
    }
  }

  handleIntegrationTab = (tab: EnumIntegrationTabs) => {
    this._integrationTab = tab
  }

  openView = (
    viewType: IViewTypes,
    viewAllData?: IViewAllDataProps,
    customTitleIcon?: IIconsVector,
    viewIntegrationKey?: IntegrationKey
  ) => {
    this._view = viewType
    this._viewAllData = { ...this._viewAllData, ...viewAllData } || emptyViewAllData
    this._customTitleIcon = customTitleIcon || null
    this._viewIntegrationKey = viewIntegrationKey || null
  }

  closeView = () => {
    this._view = 'main'
    this._viewAllData = emptyViewAllData
    this._customTitleIcon = null
    this._viewIntegrationKey = null
  }

  handleChangeContact = (item: IResponseContact) => {
    const contact = new Contact(item)

    this._contact = contact
    this._contactInfoFormStore = new ContactInfoFormStore(contact.id)
  }

  setContact = (item: Contact) => {
    const contact = item

    this._contact = contact
    this._contactInfoFormStore.updateContactInfo(contact.id)
  }

  handleUpdateContact = (item: IResponseContact) => {
    const contact = new Contact(item)
    this._contact = contact
    this.onUpdateContact?.(contact)

    if (contact?.id !== -1) {
      this._contactCreateModalStore?.handleContactUpdate(contact)
    }
  }

  handleUpdateConversation = (item: IResponseConversation) => {
    const conversation = new Conversation(item)

    this.onUpdateConversation?.(conversation)
    this._conversation = conversation
    this._contactInfoFormStore = new ContactInfoFormStore(conversation.contact_id)
  }

  toggleReassigning = (status: boolean) => {
    this._reassigning = status
  }

  onContactsDetailsToggle() {
    this._disposeContactsDetailsToggleReaction?.()
    this._disposeContactsDetailsToggleReaction = reaction(
      () => this._contactsDetailsGlobalStore?.isCollapse,
      (isCollapse) => {
        if (isCollapse) {
          this.onStopCreateNewContact?.()
          this.onCloseContactDetails?.()
        }
      }
    )
  }

  reactionContactUpdated() {
    this._disposeContactUpdatedReaction?.()
    this._disposeContactUpdatedReaction = reaction(
      () => this._contact,
      (contact) => {
        this._contactProfileInfoStore?.setContact(contact)
      }
    )
  }

  reactionSetContactFromCreateModal() {
    this._disposeSetContactFromCreateModalReaction?.()
    this._disposeSetContactFromCreateModalReaction = reaction(
      () => this._contactCreateModalStore?.contact,
      (contact) => {
        if (contact && this._contactCreateModalStore?.isCanUpdateExternalContactInfo) {
          this.setContact(contact)
        }
      }
    )
  }

  reorderDraggableItems = async (source: number, target: number) => {
    try {
      const items = cloneDeep(this.draggableItems)
      const [item] = items.splice(source, 1)

      items.splice(target, 0, item)

      const { data } = await UsersApi.updateUsersUiSettings({
        source_type: 'contacts-details-draggable-items',
        items: items,
      })

      if (data?.data?.items) {
        runInAction(() => {
          this.draggableItems = data?.data?.items as string[]
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  initDraggableItems = async () => {
    try {
      const { data } = await UsersApi.getUsersUiSettings('contacts-details-draggable-items')

      if (data.data?.items.length) {
        runInAction(() => {
          this.draggableItems = data?.data?.items as string[]
        })
      } else {
        runInAction(() => {
          this.draggableItems = this.defaultDraggableItems
        })
      }
    } catch (e) {
      console.error(e)

      runInAction(() => {
        this.draggableItems = this.defaultDraggableItems
      })
    }
  }
  get titleHeader() {
    if (this._view === 'notes') return 'Notes'
    if (this._view === 'deals') return 'Deals'
    if (this._view === 'opportunities') return 'Opportunities'
    if (this._view === 'companies') return 'Companies'
    if (this._view === 'conversations-history') return 'Conversations history'

    return this.isCurrentContact ? 'Contact' : 'Details'
  }

  get isViewTypeNotes() {
    return this._view === 'notes'
  }

  get isViewMain() {
    return this._view === 'main'
  }

  get isViewTypeConversationsHistory() {
    return this._view === 'conversations-history'
  }

  get isCurrentContact() {
    return Boolean(this._currentContact)
  }

  get isConversationGroup() {
    return this.contacts.length > 1
  }

  get isGroupSelectContact() {
    if (!this.isConversationGroup) return true

    return Boolean(this._currentContact)
  }

  get contactInfoFormStore() {
    return this._contactInfoFormStore
  }

  get contactsTableStore() {
    return this._contactsTableStore
  }

  get conversationsHistoryMessagesStore() {
    return this._conversationsHistoryMessagesStore
  }

  get conversationId() {
    return this._conversationId
  }

  get conversationMessagesStore() {
    return this._conversationMessagesStore
  }

  get alert() {
    return (
      this._contactInfoFormStore?.alert ||
      this._contactsTableStore?.alert ||
      this._conversationNewSearchStore?.alert ||
      contactChoosePhoneModalStore?.alert
    )
  }

  resetAlert = () => {
    this._contactInfoFormStore?.resetAlert()
    this._contactsTableStore?.resetAlert()
    this._conversationNewSearchStore?.resetAlert()
    contactChoosePhoneModalStore?.resetAlert()
  }

  reactionAlert = () => {
    this._disposeAlert?.()
    this._disposeAlert = reaction(
      () => this.alert,
      (alert) => {
        if (alert) {
          if (this.isVariantCreateContactModal) {
            this._contactCreateModalStore?.setAlert(alert)
          }

          setTimeout(() => {
            runInAction(() => {
              this.resetAlert()
            })
          }, 5000)
        }
      }
    )
  }

  get contactProfileInfoStore() {
    return this._contactProfileInfoStore
  }

  get contactCreateModalStore() {
    return this._contactCreateModalStore
  }

  get isFirstEditMode() {
    return (
      this._contactCreateModalStore?.isFirstEditMode ||
      this._conversationNewSearchStore?.isFirstEditMode ||
      this._contactsTableStore?.isFirstEditMode
    )
  }

  setIsFirstEditMode = (condition: boolean) => {
    this._contactCreateModalStore?.setIsFirstEditMode(condition)
    this._conversationNewSearchStore?.setIsFirstEditMode(condition)
    this._contactsTableStore?.setIsFirstEditMode(condition)
  }
}
