import { observer } from 'mobx-react-lite'
import { IconButton, ITooltipProps, Typography } from 'shared/ui'
import { useConversationListContext } from 'widgets/ConversationList/context'
import styles from './styles.module.scss'

export const SelectAction = observer(() => {
  const store = useConversationListContext()
  const { isSelectedAll, selectedCount, handleResetSelect, handleSelectAll } = store

  const handleSelect = () => {
    if (isSelectedAll) {
      handleResetSelect()
      return
    }
    handleSelectAll()
  }

  const iconName = isSelectedAll ? 'checkbox_checked' : 'checkbox_mixed'
  const title = isSelectedAll ? 'Selected all' : `${selectedCount} selected`

  const tooltipProps: ITooltipProps = {
    placement: 'bottom',
    label: isSelectedAll ? 'Deselect all' : 'Select all',
    disableInteractive: true,
  }

  return (
    <div className={styles.wrap}>
      <IconButton
        icon={iconName}
        tooltipProps={tooltipProps}
        size='origin_icon'
        variant='icon'
        fontSize={18}
        onClick={handleSelect}
        ariaLabel='SelectAction_handleSelect'
      />
      <Typography variant='body-md-medium' ariaLabel={'title'}>
        {title}
      </Typography>
    </div>
  )
})
