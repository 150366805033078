// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K6FB5bhiV2PLbeOD99wL{line-height:24px}.D1IaIAoGIKJgXxzPCNwb{display:inline;margin-right:4px}", "",{"version":3,"sources":["webpack://./src/pages/admin/pages/organizations/pages/OrganizationDetails/ui/CustomPayments/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,cAAA,CACA,gBAAA","sourcesContent":[".container {\n  line-height: 24px;\n}\n\n.customPaymentStatus {\n  display: inline;\n  margin-right: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "K6FB5bhiV2PLbeOD99wL",
	"customPaymentStatus": "D1IaIAoGIKJgXxzPCNwb"
};
export default ___CSS_LOADER_EXPORT___;
