import { NavLink as NavLinkRouter, NavLinkProps } from 'react-router-dom'
import React, { FC } from 'react'
import { uiStore } from 'shared/store/uiStore'
import { getAriaLabel } from 'shared/lib'

export const NavLink: FC<NavLinkProps & React.RefAttributes<HTMLAnchorElement>> = ({
  ...props
}) => {
  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (props.onClick) {
      props.onClick(e)
    }

    setTimeout(() => {
      if (uiStore.routeBlocker?.state !== 'blocked') {
        uiStore.changeRoute({
          path: window.location.pathname,
          type: 'vue',
        })
      }
    })
  }

  return (
    <NavLinkRouter
      {...props}
      onClick={onClick}
      aria-label={getAriaLabel('NavLink', `${props.to}`)}
    />
  )
}
