import { IStatusProps, Status } from 'shared/ui/Status'

type IStatusConfig = Pick<IStatusProps, 'icon' | 'title' | 'intent'>

const ACTIVE_STATUS_CONFIG: IStatusConfig = {
  icon: 'check2',
  title: 'Active',
  intent: 'positive',
}

const DISABLED_STATUS_CONFIG: IStatusConfig = {
  icon: 'minusCircle',
  title: 'Disabled',
  intent: 'neutral',
}

export interface IWorkflowStatusBadge {
  active: boolean
}

export const WorkflowStatusBadge = ({ active }: IWorkflowStatusBadge) => (
  <Status {...(active ? ACTIVE_STATUS_CONFIG : DISABLED_STATUS_CONFIG)} emphasis={'high'} />
)
