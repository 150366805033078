import classnames from 'classnames'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import styles from './styles.module.scss'

type ITabsProps = {
  activeTab: EnumIntegrationTabs
  handleTab: (tab: EnumIntegrationTabs) => void
  count: {
    [EnumIntegrationTabs.Opportunities]: number
  }
  isLead?: boolean
}

const Tabs = ({ activeTab, handleTab, count, isLead }: ITabsProps) => {
  return (
    <div className={classnames(styles.tabs)}>
      <div
        className={classnames(styles.tab, {
          [styles.active]: activeTab === EnumIntegrationTabs.Contact,
        })}
        onClick={() => handleTab(EnumIntegrationTabs.Contact)}
      >
        Contact
      </div>
      {!isLead && (
        <div
          className={classnames(styles.tab, {
            [styles.active]: activeTab === EnumIntegrationTabs.Opportunities,
          })}
          onClick={() => handleTab(EnumIntegrationTabs.Opportunities)}
        >
          Opportunities
          <div className={styles.count}>{count[EnumIntegrationTabs.Opportunities]}</div>
        </div>
      )}
    </div>
  )
}

export { Tabs }
