// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ubq3_qDh8bV3UGG6Uv0Q{display:flex;flex-direction:column}.wNkwmyfx8mwXh4AS1w6J{display:block;margin-bottom:32px;border-radius:16px;overflow:hidden}.wNkwmyfx8mwXh4AS1w6J img{display:block;max-width:560px;width:100%;height:auto}._GE5O82TLcYRKrxAFx6Q{display:none}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/pages/knowledgeBaseList/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,aAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CAEA,0BACE,aAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CAIJ,sBACE,YAAA","sourcesContent":[".title {\n  display: flex;\n  flex-direction: column;\n}\n\n.previewImageContainer {\n  display: block;\n  margin-bottom: 32px;\n  border-radius: 16px;\n  overflow: hidden;\n\n  img {\n    display: block;\n    max-width: 560px;\n    width: 100%;\n    height: auto;\n  }\n}\n\n.fileInput {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Ubq3_qDh8bV3UGG6Uv0Q",
	"previewImageContainer": "wNkwmyfx8mwXh4AS1w6J",
	"fileInput": "_GE5O82TLcYRKrxAFx6Q"
};
export default ___CSS_LOADER_EXPORT___;
