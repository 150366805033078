import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ProgressBar, SpinnerLoader } from 'shared/ui'
import { RegisterStore } from 'widgets/Register/store/registerStore'
import { RegisterStepEnum } from 'widgets/Register/store/type'
import { StepSignUp } from './StepSignUp'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'

import styles from './styles.module.scss'

type IRegisterContentProps = {
  store: RegisterStore
}

const RegisterStep: FC<IRegisterContentProps> = observer(({ store }) => {
  switch (store.step) {
    case RegisterStepEnum.stepSignUp:
      return <StepSignUp store={store} />

    case RegisterStepEnum.step1:
      return <Step1 store={store} />

    case RegisterStepEnum.step2:
      return <Step2 store={store} />

    case RegisterStepEnum.step3:
      return <Step3 store={store} />

    case RegisterStepEnum.step4:
      return <Step4 store={store} />

    default:
      return <StepSignUp store={store} />
  }
})

const RegisterContent: FC<IRegisterContentProps> = observer(({ store }) => {
  if (store.preloading)
    return (
      <div className={styles.wrap}>
        <SpinnerLoader />
      </div>
    )

  return (
    <div className={styles.wrap}>
      <ProgressBar percent={store.progressPercent} />
      <RegisterStep store={store} />
    </div>
  )
})

export const Register = () => {
  const store = new RegisterStore()
  return <RegisterContent store={store} />
}

export default Register
