import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import { ISearchDropdownStoreOptions, SearchDateEnum } from 'widgets/SearchDropdown'

export class SearchDropdownDateStore {
  private _from: Date | null = null
  private _to: Date | null = null
  private _dateType: SearchDateEnum | null = null
  private _options: ISearchDropdownStoreOptions | null = null

  constructor() {
    makeAutoObservable(this)
  }

  get searchParams() {
    if (!this._from && !this._to) return

    return {
      from: dayjs(this._from).format('YYYY-MM-DD'),
      to: dayjs(this._to).format('YYYY-MM-DD'),
    }
  }

  get hasOneOfDate() {
    return this._from || this._to
  }

  get hasDate() {
    return this._from && this._to
  }

  initOptions = (options: ISearchDropdownStoreOptions) => {
    this._options = options
  }

  reset = () => {
    this._dateType = null
    this._from = null
    this._to = null
  }

  selectDate = (type: SearchDateEnum) => {
    switch (type) {
      case SearchDateEnum.today:
        this._from = dayjs().subtract(0, 'days').toDate()
        this._to = dayjs().subtract(0, 'days').toDate()
        break
      case SearchDateEnum.yesterday:
        this._from = dayjs().subtract(1, 'days').toDate()
        this._to = dayjs().subtract(1, 'days').toDate()
        break
      case SearchDateEnum.last30days:
        this._from = dayjs().subtract(30, 'days').toDate()
        this._to = dayjs().subtract(0, 'days').toDate()
        break
      case SearchDateEnum.last90days:
        this._from = dayjs().subtract(90, 'days').toDate()
        this._to = dayjs().subtract(0, 'days').toDate()
        break
      case SearchDateEnum.custom:
        this._from = null
        this._to = null
        break
    }

    this._dateType = type
    this._options?.resetSearch?.()
  }

  selectStartDate = (date: Date | null) => {
    this._from = date

    if (this._from && this._to) {
      this._options?.resetSearch?.()
    }
  }

  selectEndDate = (date: Date | null) => {
    this._to = date

    if (this._from && this._to) {
      this._options?.resetSearch?.()
    }
  }

  selectRangeDate = (from: Date | null, to: Date | null) => {
    this._from = from
    this._to = to

    if (this._from && this._to) {
      this._options?.resetSearch?.()
    }
  }

  get to() {
    return this._to
  }

  get from() {
    return this._from
  }

  get dateType() {
    return this._dateType
  }
  get options() {
    return this._options
  }
}
