import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MessageField } from 'widgets/MessageField'
import { useCallPopUpDefaultContext } from 'widgets/CallPopUp/context/callPopUpDefaultContext'
import { CallSection } from 'widgets/CallPopUp/ui/CallSection/CallSection'

export const CallMessagePopUpContent = observer(() => {
  const store = useCallPopUpDefaultContext()
  const { messageFieldStore, initMessageField } = store

  useEffect(() => {
    initMessageField()
  }, [])

  return (
    <CallSection title={'Message'}>
      <MessageField messageFieldStore={messageFieldStore} />
    </CallSection>
  )
})
