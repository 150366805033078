import { createContext, useContext } from 'react'
import { type FiltersAndSearchStore } from 'widgets/FiltersAndSearch'

export const FiltersAndSearchContext = createContext<FiltersAndSearchStore | null>(null)

export function useFiltersAndSearchContext() {
  const context = useContext(FiltersAndSearchContext)
  if (context === null) {
    throw new Error('You have forgotten to wrap your root component with FiltersAndSearchContext')
  }
  return context
}
