// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uva_CYiiHa_HErIrBbfo{box-shadow:0 0 0 1px var(--content-negative-primary),0 0 0 4px var(--red-20)}.uva_CYiiHa_HErIrBbfo:focus-within{box-shadow:0 0 0 1px var(--content-negative-primary),0 0 0 4px var(--red-20)}.WQ2lycBMQIFW2RzQtVCb{display:none !important}", "",{"version":3,"sources":["webpack://./src/shared/ui/TextField/ActionsAside/TextFieldError/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,4EACI,CAGJ,mCACI,4EACA,CAKR,sBACI,uBAAA","sourcesContent":[".textFieldError {\n    box-shadow:\n        0 0 0 1px var(--content-negative-primary),\n        0 0 0 4px var(--red-20);\n\n    &:focus-within {\n        box-shadow:\n        0 0 0 1px var(--content-negative-primary),\n        0 0 0 4px var(--red-20);\n    }\n}\n\n.tooltip {\n    display: none!important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textFieldError": "uva_CYiiHa_HErIrBbfo",
	"tooltip": "WQ2lycBMQIFW2RzQtVCb"
};
export default ___CSS_LOADER_EXPORT___;
