export enum ITriggerCreateFieldEnum {
  name = 'name',
  smsMessage = 'smsMessage',
  integration = 'integration',
}

export type ITriggerCreateField = `${ITriggerCreateFieldEnum}`

export enum EnumTypeTrigger {
  Sms = 'sms',
  Ringless = 'ringless',
}

export enum EnumModeTrigger {
  Create = 'create',
  Edit = 'edit',
}
