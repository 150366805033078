import { ReactNode, FC } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { type IDropdownProps } from 'shared/ui'
import { DeviceItemsEnum, type DeviceSettingsStore } from 'entities/DeviceSettings/store'
import styles from './styles.module.scss'
import { VideoInputContent } from './VideoInputContent'
import { AudioOutputContent } from './AudioOutputContent'
import { AudioInputContent } from './AudioInputContent'
import { RecordAutoContent } from './RecordAutoContent'

export enum DeviceSettingsVariantEnum {
  dropdown = 'dropdown',
  container = 'container',
}

export type IDeviceSettingsContentProps = {
  parentsDropdownId?: string[]
  deviceSettingsStore: DeviceSettingsStore
  testOutputDevice?: () => void
  variant?: DeviceSettingsVariantEnum
  dropdownProps?: IDropdownProps
  exclude?: DeviceItemsEnum[]
}

export const DeviceSettingsContent: FC<IDeviceSettingsContentProps> = observer((props) => {
  const { deviceSettingsStore, variant = DeviceSettingsVariantEnum.dropdown, exclude = [] } = props
  const { loading, items } = deviceSettingsStore

  const obj: {
    [key in DeviceItemsEnum]: ReactNode
  } = {
    [DeviceItemsEnum.audioInput]: <AudioInputContent key={DeviceItemsEnum.audioInput} {...props} />,
    [DeviceItemsEnum.videoInput]: <VideoInputContent key={DeviceItemsEnum.videoInput} {...props} />,
    [DeviceItemsEnum.audioOutput]: (
      <AudioOutputContent key={DeviceItemsEnum.audioOutput} {...props} />
    ),
    [DeviceItemsEnum.recordAuto]: <RecordAutoContent key={DeviceItemsEnum.recordAuto} {...props} />,
  }

  return (
    <div
      className={classnames(styles.content, styles[variant], {
        [styles.loading]: loading,
      })}
    >
      {items.map((item) => {
        if (exclude.includes(item)) return null

        return obj[item]
      })}
    </div>
  )
})
