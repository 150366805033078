import { makeObservable, observable, runInAction } from 'mobx'
import { IResponseShortcodeOptOut } from 'entities/Contacts/api/types'

export class ContactOptIn {
  id: number | string
  disabled: boolean
  name: string
  value: boolean
  formatted_number: string

  constructor(item: IResponseShortcodeOptOut) {
    this.id = item.id
    this.disabled = item.disabled
    this.name = item.name
    this.value = item.value
    this.formatted_number = item.formatted_number

    makeObservable(this, {
      value: observable,
    })
  }

  handleChangeValue = () => {
    runInAction(() => {
      this.value = !this.value
    })
  }
}
