// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QAuXEZs1p1dPViaZAmJ1{margin-bottom:-8px}.QAuXEZs1p1dPViaZAmJ1 .MWlwihlQ7kTIeRkJTOwh{color:var(--content-primary-primary);font-size:24px;height:24px;font-weight:500;padding:12px 8px 24px;box-sizing:content-box;text-align:center}", "",{"version":3,"sources":["webpack://./src/widgets/CallPopUp/ui/CallDialpadPopUp/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,oCAAA,CACA,cAAA,CACA,WAAA,CACA,eAAA,CACA,qBAAA,CACA,sBAAA,CACA,iBAAA","sourcesContent":[".wrap {\n  margin-bottom: -8px;\n\n  .label {\n    color: var(--content-primary-primary);\n    font-size: 24px;\n    height: 24px;\n    font-weight: 500;\n    padding: 12px 8px 24px;\n    box-sizing: content-box;\n    text-align: center;\n  }\n\n  .content {\n\n  }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "QAuXEZs1p1dPViaZAmJ1",
	"label": "MWlwihlQ7kTIeRkJTOwh"
};
export default ___CSS_LOADER_EXPORT___;
