import { makeAutoObservable, runInAction, reaction } from 'mobx'
import { ConversationsApi } from 'entities/Conversation'
import {
  IResponseConversationsOrder,
  IResponseConversationsOrderKey,
} from 'entities/Conversation/api/types'

type ISubscription = (order: string) => void

export class OrdersStore {
  private subscriptions = new Set<ISubscription>()

  constructor() {
    makeAutoObservable(this)
  }

  loading = false
  ordersMap: Map<IResponseConversationsOrderKey, IResponseConversationsOrder> = new Map()
  order: IResponseConversationsOrder = {
    key: 'newest',
    label: 'Newest',
  }
  reactionChange: ReturnType<typeof reaction> | null = null

  reset = () => {
    this.loading = false
  }

  loadOrders = async () => {
    if (this.ordersMap.size) return

    runInAction(() => {
      this.loading = true
    })

    const response = await ConversationsApi.getOrdersList()
    const orders = response.data

    this.addItems(orders)

    runInAction(() => {
      this.loading = false
    })
  }

  setItem = (id: IResponseConversationsOrderKey, silent = false) => {
    const order = this.getItem(id)

    if (order) {
      this.order = order

      if (!silent) this.subscriptions.forEach((subscription) => subscription(order.key))
    }
  }

  addItems = (items: IResponseConversationsOrder[]) => {
    items.forEach((item) => {
      this.ordersMap.set(item.key, item)
    })
  }

  getItem = (key: IResponseConversationsOrderKey) => {
    return this.ordersMap.get(key)
  }

  subscribeOrderChange(subscription: ISubscription) {
    this.subscriptions.add(subscription)
  }

  unsubscribeOrderChange(subscription: ISubscription) {
    this.subscriptions.delete(subscription)
  }

  get orders() {
    return Array.from(this.ordersMap.values())
  }
}

export const ordersStore = new OrdersStore()
