import { makeAutoObservable, IReactionDisposer, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import { isEmail } from 'shared/lib'
import { authStore } from 'entities/Auth'
import { TwoFaModalStore } from 'widgets/TwoFaModal'
export class LoginStore {
  private _email = ''
  private _password = ''
  private _loading = false
  private _error = ''
  private _alertErrors: string[] = []
  private _alertSuccess = ''

  // Email validation
  private _disposeEmailValidation: IReactionDisposer | null = null
  private _disposeEmailValidationTrigger: IReactionDisposer | null = null
  private _emailError: string | null = null
  private _emailValidationTrigger: string | null = null
  private _showConstantEmailError = false

  // Password validation
  private _disposePasswordValidation: IReactionDisposer | null = null
  private _disposePasswordValidationTrigger: IReactionDisposer | null = null
  private _passwordError: string | null = null
  private _passwordValidationTrigger: string | null = null
  private _showConstantPasswordError = false

  private _twoFaModalStore = new TwoFaModalStore()

  constructor() {
    makeAutoObservable(this)
    this.reactionEmailValidation()
    this.reactionPasswordValidation()
    this.reactionEmailValidationTrigger()
    this.reactionPasswordValidationTrigger()
  }

  get showConstantEmailError() {
    return this._showConstantEmailError
  }

  get showConstantPasswordError() {
    return this._showConstantPasswordError
  }

  get alertSuccess() {
    return this._alertSuccess
  }

  get isValidEmail() {
    return isEmail(this._email)
  }

  get hasAlertErrors() {
    return !!this.alertErrors.length
  }

  get alertErrors() {
    return this._alertErrors
  }

  get hasEmail() {
    return !!this.email
  }

  get hasPassword() {
    return !!this.password
  }

  get email() {
    return this._email
  }

  get password() {
    return this._password
  }

  get loading() {
    return this._loading
  }

  get emailError() {
    return this._emailError
  }

  get passwordError() {
    return this._passwordError
  }

  get error() {
    return this._error
  }

  showConstantErrors = () => {
    this._showConstantEmailError = true
    this._showConstantPasswordError = true
  }

  clearConstantErrors = () => {
    this._showConstantEmailError = false
    this._showConstantPasswordError = false
  }

  setAlertSuccess = (value: string) => {
    this._alertSuccess = value
  }

  clearAlertErrors = () => {
    if (this.hasAlertErrors) this._alertErrors = []
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  setEmail = (value: string) => {
    this.clearAlertErrors()
    this._email = value.trim()
  }

  setPassword = (value: string) => {
    this.clearAlertErrors()
    this._password = value
  }

  triggerEmailValidation = () => {
    this._emailValidationTrigger = nanoid()
  }

  triggerPasswordValidation = () => {
    this._passwordValidationTrigger = nanoid()
  }

  signIn = async (store?: LoginStore) => {
    this.clearAlertErrors()

    if (!this.hasPassword || !this.isValidEmail) {
      this.triggerEmailValidation()
      this.triggerPasswordValidation()
      return
    }

    try {
      this.clearConstantErrors()
      this.setLoading(true)

      const data = await authStore.login({
        email: this.email,
        password: this.password,
        remember: false,
      })

      if (data && store) {
        this._twoFaModalStore.openTwoFaModal(data, store._twoFaModalStore)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const data = error.response?.data
        if (!data) return

        const errorKeys = Object.keys(data)
        errorKeys.map((errorKey) => {
          const error = data[errorKey]?.[0] || data[errorKey]
          if (typeof error === 'string') {
            this._alertErrors.push(error)
          }
        })
      }
    } finally {
      this.setLoading(false)
    }
  }

  reactionEmailValidation = () => {
    this._disposeEmailValidation?.()
    this._disposeEmailValidation = reaction(
      () => [this._emailValidationTrigger, this.isValidEmail],
      ([trigger, isValid]) => {
        if (isValid) {
          this._emailError = null
          this._showConstantEmailError = false
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionEmailValidationTrigger = () => {
    this._disposeEmailValidationTrigger?.()
    this._disposeEmailValidationTrigger = reaction(
      () => this._emailValidationTrigger,
      (value) => {
        this._emailError =
          value && !this.isValidEmail
            ? this.hasEmail
              ? 'Please enter a valid email address.'
              : 'Please enter email address.'
            : null
        if (this.isValidEmail) {
          this._emailValidationTrigger = null
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPasswordValidation = () => {
    this._disposePasswordValidation?.()
    this._disposePasswordValidation = reaction(
      () => this.hasPassword,
      (value) => {
        if (value) {
          this._passwordError = null
          this._showConstantPasswordError = false
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPasswordValidationTrigger = () => {
    this._disposePasswordValidationTrigger?.()
    this._disposePasswordValidationTrigger = reaction(
      () => this._passwordValidationTrigger,
      (value) => {
        this._passwordError = value && !this.hasPassword ? 'Please enter password.' : null
        if (this.hasPassword) {
          this._passwordValidationTrigger = null
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }
}
