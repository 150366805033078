import React, { useEffect, useRef, useState } from 'react'
import { replaceLink } from 'shared/lib'
import { ShortenLinkTooltip } from 'entities/ShortLink/ui/ShortenLinkTooltip/ShortenLinkTooltip'
import { shortLinkStore } from '../store/shortLinkStore'

export function useShortenLink({
  trigger,
  updateTriggerDeps,
  setUpdateTriggerContent,
  addContent,
}: {
  updateTriggerDeps: Array<HTMLElement | HTMLDivElement | string | null>
  trigger: HTMLDivElement | null
  setUpdateTriggerContent: () => void
  addContent: (value: string) => void
}) {
  const refVisibleTooltip = useRef(false)

  const [currentLink, setCurrentLink] = useState<HTMLAnchorElement | undefined>(undefined)

  const onHoverLink = (link: HTMLAnchorElement) => () => {
    if (shortLinkStore.canShortUrl(link.href)) {
      refVisibleTooltip.current = true
      setCurrentLink(link)
    }
  }

  const onLeaveLink = () => {
    refVisibleTooltip.current = false
    setTimeout(() => {
      if (!refVisibleTooltip.current) {
        setCurrentLink(undefined)
      }
    }, 1000)
  }

  useEffect(() => {
    if (trigger) {
      const links: NodeListOf<HTMLAnchorElement> = trigger.querySelectorAll('a')
      links.forEach((link) => {
        if (shortLinkStore.canShortUrl(link.href)) {
          link.addEventListener('mouseenter', onHoverLink(link))
          link.addEventListener('mouseleave', onLeaveLink)
        } else {
          link.removeEventListener('mouseenter', onHoverLink(link))
          link.removeEventListener('mouseleave', onLeaveLink)
        }
      })
    }
  }, updateTriggerDeps)

  const onHideLinkTooltips = () => {
    setCurrentLink(undefined)
  }

  const onSuccess = () => {
    setUpdateTriggerContent()
    setCurrentLink(undefined)
  }

  return {
    onHideLinkTooltips,
    linkTooltipComponent: (
      <>
        <ShortenLinkTooltip
          trigger={currentLink}
          onLeaveTooltip={onLeaveLink}
          refVisibleTooltip={refVisibleTooltip}
          onSuccess={onSuccess}
          onAddShortLink={(link: string) => addContent && addContent(replaceLink(link) + '&nbsp;')}
          onHideLinkTooltips={onHideLinkTooltips}
        />
      </>
    ),
  }
}
