import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import classnames from 'classnames'
import { Alert, ITabItem, layoutStore, Tabs } from 'shared/ui'
import { SegmentBox } from 'entities/Segment'
import { type FilterEditorStore } from 'widgets/FilterEditor'
import { EnumEnrollmentMode } from 'widgets/ContactEnrollment/store/types'
import { type EnrollmentListStore } from '../store'
import { EnrollmentContactsTable } from './EnrollmentContactsTable'
import { EnrollmentInclude } from './EnrollmentInclude/EnrollmentInclude'
import styles from './styles.module.scss'
import { EnrollmentActionsProvider } from './EnrollmentActions'

export const getSelectTitle = (count: number) => {
  switch (true) {
    case count === 1:
      return 'Select contact'
    case count > 1:
      return `Select ${count} contacts`
    default:
      return 'Select contacts'
  }
}

export interface IEnrollmentModalProps {
  enrollmentListStore: EnrollmentListStore
  filterEditorStore: FilterEditorStore
  segmentBox: SegmentBox
  onConfirm: () => void
  onCancel: () => void
  mode: EnumEnrollmentMode
  allowEmptyCount?: boolean
}

export const EnrollmentModal = observer(
  ({
    mode,
    allowEmptyCount,
    enrollmentListStore,
    filterEditorStore,
    segmentBox,
    onConfirm,
    onCancel,
  }: IEnrollmentModalProps) => {
    const includeTab = useMemo<ITabItem<'include'>>(
      () => ({
        key: 'include',
        name: 'Include',
      }),
      []
    )
    const contactTab = useMemo<ITabItem<'contacts'>>(
      () => ({
        key: 'contacts',
        name: 'Contacts',
        count: enrollmentListStore.allTotalCount,
        isFullCount: true,
        disabled: enrollmentListStore.existHubspotFilter,
        tooltipProps: enrollmentListStore.existHubspotFilter
          ? {
              label: (
                <>
                  Up to {enrollmentListStore.allTotalCount} contacts will be <br /> enrolled in this
                  Broadcast
                </>
              ),
              placement: 'top',
            }
          : undefined,
      }),
      [enrollmentListStore.allTotalCount, enrollmentListStore.existHubspotFilter]
    )
    const [tabActive, setTabActive] = useState<'include' | 'contacts'>('include')
    const selectTitle = getSelectTitle(enrollmentListStore.allTotalCount)

    const isEmptyFilters = !enrollmentListStore.filters.allSize
    const isEmptyCount = allowEmptyCount ? false : !enrollmentListStore.allTotalCount
    const selectDisabled =
      isEmptyCount ||
      isEmptyFilters ||
      enrollmentListStore.loading ||
      enrollmentListStore.existOtherFiltersWithHubspotFilter

    return (
      <EnrollmentActionsProvider
        onCancel={onCancel}
        onConfirm={onConfirm}
        selectTitle={selectTitle}
        selectDisabled={selectDisabled}
      >
        {mode === EnumEnrollmentMode.ViewOnly ? (
          <div className={styles.rootContainer}>
            <EnrollmentInclude
              filterEditorStore={filterEditorStore}
              enrollmentListStore={enrollmentListStore}
              segmentBox={segmentBox}
              mode={mode}
              showList
            />
          </div>
        ) : (
          <div
            className={classnames(styles.rootContainer, {
              [styles.rootContainer__alert]: enrollmentListStore.existOtherFiltersWithHubspotFilter,
            })}
          >
            {enrollmentListStore.existOtherFiltersWithHubspotFilter && (
              <div className={styles.alert}>
                <Alert
                  item={{
                    type: 'alert',
                    desc: 'You cannot use HubSpot lists with other filters',
                  }}
                />
              </div>
            )}
            <Tabs
              tabs={[includeTab, contactTab]}
              activeTabKey={tabActive}
              marginInlineTab={24}
              variant={'divider'}
              handleSelectTab={(value) => setTabActive(value.key)}
            />
            {tabActive === 'include' && (
              <EnrollmentInclude
                filterEditorStore={filterEditorStore}
                enrollmentListStore={enrollmentListStore}
                segmentBox={segmentBox}
                mode={mode}
                showList
              />
            )}
            {tabActive === 'contacts' && (
              <EnrollmentContactsTable enrollmentListStore={enrollmentListStore} />
            )}
          </div>
        )}
      </EnrollmentActionsProvider>
    )
  }
)
