import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Icon } from 'shared/ui'

type IFileIconProps = {
  docType?:
    | 'pdf'
    | 'application/pdf'
    | 'csv'
    | 'text/csv'
    | 'text/plain'
    | 'txt'
    | 'xls'
    | 'application/vnd.ms-excel'
    | 'doc'
    | 'docx'
    | 'application/msword'
    | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    | string
  isAudio?: boolean
  isVCard?: boolean
  isImage?: boolean
  isVideo?: boolean
  insideBox?: boolean
  boxRounded?: boolean
  fontSize: number
}

export const FileIcon: FC<IFileIconProps> = observer(
  ({ docType = '', isAudio, isVCard, isImage, isVideo, insideBox, boxRounded, fontSize }) => {
    if (isAudio)
      return (
        <Icon
          fontSize={fontSize}
          insideBox={insideBox}
          boxRounded={boxRounded}
          padding={6}
          icon='audio'
          tertiary
        />
      )
    if (isVCard)
      return (
        <Icon
          fontSize={fontSize}
          insideBox={insideBox}
          boxRounded={boxRounded}
          padding={6}
          icon='personFilled'
          tertiary
        />
      )
    if (isImage)
      return (
        <Icon
          fontSize={fontSize}
          insideBox={insideBox}
          boxRounded={boxRounded}
          padding={6}
          icon='imageLine'
          tertiary
        />
      )
    if (isVideo)
      return (
        <Icon
          fontSize={20}
          insideBox={insideBox}
          boxRounded={boxRounded}
          padding={6}
          icon='video'
          tertiary
        />
      )

    switch (docType) {
      case 'pdf':
      case 'application/pdf':
        return <Icon fontSize={fontSize} icon='filePdf' />
      case 'csv':
      case 'text/csv':
      case 'text/plain':
      case 'txt':
        return <Icon fontSize={fontSize} icon='fileCsv' />
      case 'xls':
      case 'xlsx':
      case 'application/vnd.ms-excel':
        return <Icon fontSize={fontSize} icon='fileXls' />
      case 'doc':
      case 'docx':
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <Icon fontSize={fontSize} icon='fileDoc' />
      default:
        return null
    }
  }
)
