import { useEffect } from 'react'
import { WebsocketEvent, websocket } from 'entities/WebSocket'
import { IResponseEventMessage } from 'entities/Message'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { inboxesStore } from 'entities/Inbox'
import { CallHistoryStore } from 'widgets/CallHistory'

export const EventMessageUpdatedNew = {
  subscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.on(WebsocketEvent.MessageUpdatedNew, handler),
  unsubscribe: (handler: (data: IResponseEventMessage) => void) =>
    websocket.off(WebsocketEvent.MessageUpdatedNew, handler),
}

export const useEventMessageUpdatedNew = (callHistoryStore: CallHistoryStore) => {
  const handler = (data: IResponseEventMessage) => {
    const { call_history, conversation } = data
    if (!call_history || inboxesStore.currentInboxTypeId !== conversation.inbox_id) return

    const { call } = call_history
    const { type, outcome } = call

    if (type && outcome) {
      callHistoryStore.updateItem(new CallHistory(call_history))
    }
  }

  useEffect(() => {
    EventMessageUpdatedNew.subscribe(handler)

    return () => {
      EventMessageUpdatedNew.unsubscribe(handler)
    }
  }, [])
}
